import React, { Component } from "react";

import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import api from "../../../api/api";
import {
  TextField,
  Snackbar,
  Switch,
  Typography,
  Grid,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
} from "@material-ui/core";

import { Alert } from "@material-ui/lab";
import { withStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import EventIcon from "@material-ui/icons/Event";
import FileUpload from "../../common/FileUpload";
import { pad } from "../../common/common";
import Loader from "../../common/Loader";

const PurpleSwitch = withStyles({
  switchBase: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
    "&$checked + $track": {
      backgroundColor: config.themeColor,
    },
  },
  checked: {},
  track: {},
})(Switch);

export default class InsuranceUpdate extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      barge_id: null,
      insurance_level: "Barge",
      business_no_id: "",
      voyage_type: "",
      vessel_type: "",
      barge_vessel_name: "",
      imo_no: "",
      year_built: "",
      flag: "",
      grt: "",
      classification: "",
      owners_p_and_i_cover: "",
      charterparty: "",
      delivery_date: null,
      estimated_duration: "",
      time_or_voyage: "",
      cargo: "",
      charterers_p_and_i_liability: "No",
      charterers_fd_and_d_liability: "No",
      back_to_back_sublet: "No",
      charterers_b_l: "No",
      contract_type: "Per Year",
      p_and_i_value: "",
      fd_and_d_value: "",
      exchange_rate: "",
      cost_to_company: "",
      barges: [],
      other_expenses_data: [],
      activeStep: 0,
      isLoading: true,
      files: [],
      currencyData: [],
      costing_files: [],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    let features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (!(features.includes("346") || features.includes("97"))) {
      window.location.href = "/dashboard";
    }
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const insurance_id = this.props.insurance_id;
    await api
      .get_insurance_and_declaration(loginUserID, idToken, insurance_id)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.insurance_and_declaration) {
                this.setState({
                  insurance_level:
                    res.insurance_and_declaration.insurance_level,
                  business_no_id: res.insurance_and_declaration.business_no_id,
                  invoice_file: res.insurance_and_declaration.invoice_file,
                  barge_id: res.insurance_and_declaration.barge_id,
                  voyage_type: res.insurance_and_declaration.voyage_type,
                  vessel_type: res.insurance_and_declaration.vessel_type,
                  barge_vessel_name:
                    res.insurance_and_declaration.barge_vessel_name,
                  imo_no: res.insurance_and_declaration.imo_no,
                  year_built: res.insurance_and_declaration.year_built,
                  flag: res.insurance_and_declaration.flag,
                  grt: res.insurance_and_declaration.grt,
                  classification: res.insurance_and_declaration.classification,
                  owners_p_and_i_cover:
                    res.insurance_and_declaration.owners_p_and_i_cover,
                  charterparty: res.insurance_and_declaration.charterparty,
                  delivery_date: res.insurance_and_declaration.delivery_date,
                  estimated_duration:
                    res.insurance_and_declaration.estimated_duration,
                  time_or_voyage: res.insurance_and_declaration.time_or_voyage,
                  cargo: res.insurance_and_declaration.cargo,
                  charterers_p_and_i_liability:
                    res.insurance_and_declaration.charterers_p_and_i_liability,
                  charterers_fd_and_d_liability:
                    res.insurance_and_declaration.charterers_fd_and_d_liability,
                  back_to_back_sublet:
                    res.insurance_and_declaration.back_to_back_sublet,
                  charterers_b_l: res.insurance_and_declaration.charterers_b_l,
                  contract_type: res.insurance_and_declaration.contract_type,
                  p_and_i_value: res.insurance_and_declaration.p_and_i_value,
                  fd_and_d_value: res.insurance_and_declaration.fd_and_d_value,

                  exchange_rate: res.insurance_and_declaration.exchange_rate,
                  billing_currency:
                    res.insurance_and_declaration.billing_currency,
                  cost_to_company:
                    res.insurance_and_declaration.cost_to_company,
                  files: res.insurance_and_declaration.files,
                  isLoading: false,
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
    // for get_Currencies
    await api.getCurrencies(loginUserID, idToken).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
          if (res.code === "200") {
            if (res.Currency) {
              var currencies = [
                <MenuItem value="0" key={-1} disabled>
                  Select Currency
                </MenuItem>,
              ];
              for (var i of res.Currency) {
                currencies.push(
                  <MenuItem value={i.currency} key={[i.id]}>
                    {i.currency}
                  </MenuItem>
                );
              }
              this.setState({
                currencyData: currencies,
                isLoading: false,
              });
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          } else if (res.code === "601") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
  }

  submitHandler = () => {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();

    let {
      insurance_level,
      barge_id,
      business_no_id,
      voyage_type,
      vessel_type,
      barge_vessel_name,
      imo_no,
      year_built,
      flag,
      grt,
      classification,
      owners_p_and_i_cover,
      charterparty,
      delivery_date,
      estimated_duration,
      time_or_voyage,
      cargo,
      charterers_p_and_i_liability,
      charterers_fd_and_d_liability,
      back_to_back_sublet,
      charterers_b_l,
      contract_type,
      p_and_i_value,
      fd_and_d_value,
      exchange_rate,
      cost_to_company,
      files,
    } = this.state;
    /**
     *  Checking Mandatory files
     */
    var missing_fields = 0;
    let files_missing = 0;
    var row = this.state.files;
    for (var obj of row) {
      if (obj.file_type !== "" || obj.file_content !== "") {
        if (obj.file_content !== "") {
          if (obj.file_description === "") {
            missing_fields++;
            obj.file_description_error = true;
          } else {
            obj.file_description = obj.file_description.toUpperCase();
          }
        } else if (obj.file_description !== "") {
          if (obj.file_content === "") {
            files_missing++;
          }
        }
      }
    }
    this.setState({
      files: row,
    });

    if (missing_fields > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please fill mandatory fields.",
      });
      return;
    }
    if (files_missing > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please Select file.",
      });
      return;
    }
    var existing_file_ids = [];
    const existing_files = files.filter((e) => e.id);
    for (var i of existing_files) {
      existing_file_ids.push(i.id);
    }
    const new_files = files.filter((e) => !e.id);
    const content_files = new_files.filter((e) => e.file_content !== "");
    const costing_file = this.state.costing_files.filter(
      (e) => e.file_content !== "" && e.column === "invoice_file"
    );
    const insurance_id = this.props.insurance_id;

    api
      .update_insurance_and_declaration(
        loginUserID,
        idToken,
        insurance_id,
        insurance_level,
        barge_id,
        business_no_id,
        voyage_type,
        vessel_type,
        barge_vessel_name,
        imo_no,
        year_built,
        flag,
        grt,
        classification,
        owners_p_and_i_cover,
        charterparty,
        delivery_date,
        estimated_duration,
        time_or_voyage,
        cargo,
        charterers_p_and_i_liability,
        charterers_fd_and_d_liability,
        back_to_back_sublet,
        charterers_b_l,
        contract_type,
        p_and_i_value,
        fd_and_d_value,
        exchange_rate,
        cost_to_company,
        existing_file_ids,
        content_files,
        costing_file
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState(
                {
                  snackBarSuccessOpen: true,
                  successMsg: res.message,
                },
                () => {
                  this.props.onUpdateCosting(true);
                }
              );
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  };

  toLocaleString(value) {
    return value ? Number(value).toLocaleString() : null;
  }

  nextStepHandler = () => {
    if (this.state.activeStep === 0) {
      if (this.state.barge_id === null) {
        this.setState({
          snackBarErrorOpen: true,
          errorMsg: "Please select barge.",
        });
        return;
      }
    }

    if (this.state.activeStep === 1) {
      /**
       *  Checking Mandatory files
       */
      var missing_fields = 0;
      let files_missing = 0;
      var row = this.state.files;
      for (var obj of row) {
        if (obj.file_type !== "" || obj.file_content !== "") {
          if (obj.file_content !== "") {
            if (obj.file_description === "") {
              missing_fields++;
              obj.file_description_error = true;
            } else {
              obj.file_description = obj.file_description.toUpperCase();
            }
          } else if (obj.file_description !== "") {
            if (obj.file_content === "") {
              files_missing++;
            }
          }
        }
      }
      this.setState({
        files: row,
      });

      if (missing_fields > 0) {
        this.setState({
          snackBarErrorOpen: true,
          errorMsg: "Please fill mandatory fields.",
        });
        return;
      }
      if (files_missing > 0) {
        this.setState({
          snackBarErrorOpen: true,
          errorMsg: "Please Select file.",
        });
        return;
      }
    }

    this.setState({
      activeStep: this.state.activeStep + 1,
    });
  };

  previousStepHandler = () => {
    this.setState({
      activeStep: this.state.activeStep - 1,
    });
  };

  insuranceCalculations = () => {
    let total =
      Number(this.state.p_and_i_value) + Number(this.state.fd_and_d_value);
    let currancy_exchg_rate = this.state.currency_exchg_rate
      ? this.state.currency_exchg_rate
      : 1;
    if (
      (config.company === "SDAM" ||
        config.company === "SRK" ||
        config.company === "BTR") &&
      this.state.billing_currency === "IDR"
    ) {
      currancy_exchg_rate = 1;
    }
    if (
      (config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL") &&
      this.state.billing_currency === "USD"
    ) {
      currancy_exchg_rate = 1;
    }
    let cost_to_company = (Number(total) * Number(currancy_exchg_rate)).toFixed(
      2
    );
    this.setState({
      cost_to_company: cost_to_company,
    });
  };

  render() {
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div className="container bg-white p-0" style={{ minHeight: "100vh" }}>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <div className="row">
            <div className="col-lg-12 p-0 border-bottom">
              <h6 style={{ padding: "15px", marginBottom: 0 }}>
                Insurance & Declaration
              </h6>
            </div>
          </div>

          <div className="drawer-section">
            <div className="row mb-0 stepper">
              {this.props.barge_details && (
                <div className="row">
                  <div className="table-responsive">
                    <div className="table border-0">
                      <table className="table table-borderless border-0">
                        <thead className="border-0">
                          <tr>
                            <th
                              style={{ fontSize: 16 }}
                              nowrap="true"
                              className="table_header_barge_label text-uppercase border-0"
                            >
                              Barge ID
                            </th>
                            <th
                              style={{ fontSize: 16 }}
                              nowrap="true"
                              className="table_header_barge_label text-uppercase border-0"
                            >
                              Barge Nomination
                            </th>
                            <th
                              style={{ fontSize: 16 }}
                              nowrap="true"
                              className="table_header_barge_label text-uppercase border-0"
                            >
                              Business No
                            </th>
                            <th
                              style={{ fontSize: 16 }}
                              nowrap="true"
                              className="text-right table_header_barge_label text-uppercase border-0"
                            >
                              Total Payable
                            </th>
                            <th
                              style={{ fontSize: 16 }}
                              nowrap="true"
                              className="text-right table_header_barge_label text-uppercase border-0"
                            >
                              Paid
                            </th>
                            <th
                              style={{ fontSize: 16 }}
                              nowrap="true"
                              className="text-right table_header_barge_label text-uppercase border-0"
                            >
                              Remaining
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="rounded">
                            <td className="table_td text-left">
                              {pad(this.props.barge_details.barge_id)}
                            </td>
                            <td className="table_td text-left" nowrap="true">
                              {this.props.barge_details.barge_nomination}
                            </td>
                            <td className="table_td text-left" nowrap="true">
                              {this.props.barge_details.business_no}
                            </td>
                            <td className="table_td text-right" nowrap="true">
                              {this.toLocaleString(
                                this.props.barge_details.total_payable
                              )}
                            </td>
                            <td className="table_td text-right" nowrap="true">
                              {this.toLocaleString(
                                this.props.barge_details.paid
                              )}
                            </td>
                            <td className="table_td text-right" nowrap="true">
                              {this.toLocaleString(
                                this.props.barge_details.Remaining_amount
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="card p-0">
              <div className="card-body">
                <div className="p-3 border_dashed  rounded-lg">
                  <div className="row mb-3">
                    <div className="col-lg-12 p-0">
                      <div className="row">
                        <div className="col-lg-6">
                          <label className="form_label mb-0">Voyage Type</label>
                          <TextField
                            name="voyage_type"
                            margin="dense"
                            variant="outlined"
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                            value={this.state.voyage_type}
                            placeholder="Voyage Type"
                            fullWidth
                            onChange={(e) => {
                              this.setState({
                                voyage_type: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="col-lg-6">
                          <label className="form_label mb-0">
                            Barge & Tug Name
                          </label>
                          <TextField
                            name="barge_vessel_name"
                            margin="dense"
                            variant="outlined"
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                            value={this.state.barge_vessel_name}
                            placeholder="Barge & Tug Name"
                            fullWidth
                            onChange={(e) => {
                              this.setState({
                                barge_vessel_name: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="col-lg-6">
                          <label className="form_label mb-0">IMO No</label>
                          <TextField
                            name="imo_no"
                            margin="dense"
                            variant="outlined"
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                            value={this.state.imo_no}
                            placeholder="IMO No"
                            fullWidth
                            onChange={(e) => {
                              this.setState({
                                imo_no: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="col-lg-6">
                          <label className="form_label mb-0">Year Built</label>
                          <TextField
                            name="year_built"
                            margin="dense"
                            variant="outlined"
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                            value={this.state.year_built}
                            placeholder="Year Built"
                            fullWidth
                            onChange={(e) => {
                              this.setState({
                                year_built: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="col-lg-6">
                          <label className="form_label mb-0">Flag</label>
                          <TextField
                            name="flag"
                            margin="dense"
                            variant="outlined"
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                            value={this.state.flag}
                            placeholder="flag"
                            fullWidth
                            onChange={(e) => {
                              this.setState({
                                flag: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="col-lg-6">
                          <label className="form_label mb-0">Grt</label>
                          <TextField
                            name="grt"
                            margin="dense"
                            variant="outlined"
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                            value={this.state.grt}
                            placeholder="grt"
                            fullWidth
                            onChange={(e) => {
                              this.setState({
                                grt: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="col-lg-6">
                          <label className="form_label mb-0">
                            Classification
                          </label>
                          <TextField
                            name="classification"
                            margin="dense"
                            variant="outlined"
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                            value={this.state.classification}
                            placeholder="Classification"
                            fullWidth
                            onChange={(e) => {
                              this.setState({
                                classification: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="col-lg-6">
                          <label className="form_label mb-0">
                            Owners P&I Cover
                          </label>
                          <TextField
                            name="owners_p_and_i_cover"
                            margin="dense"
                            variant="outlined"
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                            value={this.state.owners_p_and_i_cover}
                            placeholder="Owners P&I Cover"
                            fullWidth
                            onChange={(e) => {
                              this.setState({
                                owners_p_and_i_cover: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="col-lg-6">
                          <label className="form_label mb-0">
                            Charterparty
                          </label>
                          <TextField
                            name="charterparty"
                            margin="dense"
                            variant="outlined"
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                            value={this.state.charterparty}
                            placeholder="Charterparty"
                            fullWidth
                            onChange={(e) => {
                              this.setState({
                                charterparty: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="col-lg-6">
                          <label className="form_label mb-0">
                            Delivery Date
                          </label>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                              autoOk={true}
                              inputVariant="outlined"
                              variant="inline"
                              margin="dense"
                              fullWidth
                              format="dd/MM/yyyy"
                              value={this.state.delivery_date}
                              onChange={(date) => {
                                this.setState({
                                  delivery_date: date,
                                });
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <IconButton>
                                      <EventIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                                className: "pl-0",
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                        <div className="col-lg-6">
                          <label className="form_label mb-0">
                            Estimated Duration
                          </label>
                          <TextField
                            name="estimated_duration"
                            margin="dense"
                            variant="outlined"
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                            value={this.state.estimated_duration}
                            placeholder="Estimated Duration"
                            fullWidth
                            onChange={(e) => {
                              this.setState({
                                estimated_duration: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="col-lg-6">
                          <label className="form_label mb-0">
                            Time or Voyage
                          </label>
                          <TextField
                            name="time_or_voyage"
                            margin="dense"
                            variant="outlined"
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                            value={this.state.time_or_voyage}
                            placeholder="Time or Voyage"
                            fullWidth
                            onChange={(e) => {
                              this.setState({
                                time_or_voyage: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="col-lg-6">
                          <label className="form_label mb-0">Cargo</label>
                          <TextField
                            name="cargo"
                            margin="dense"
                            variant="outlined"
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                            value={this.state.cargo}
                            placeholder="Cargo"
                            fullWidth
                            onChange={(e) => {
                              this.setState({
                                cargo: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-lg-6">
                      <label className="form_label mb-0">
                        Charterer's P&I Liability
                      </label>
                      <Typography component="div">
                        <Grid
                          component="label"
                          container
                          alignItems="center"
                          spacing={1}
                        >
                          <Grid item>No</Grid>
                          <Grid
                            item
                            style={{
                              position: "relative",
                              top: "3px",
                              paddingLeft: "20px",
                            }}
                          >
                            <FormControlLabel
                              control={
                                <PurpleSwitch
                                  checked={
                                    this.state.charterers_p_and_i_liability ===
                                    "Yes"
                                  }
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      this.setState({
                                        charterers_p_and_i_liability: "Yes",
                                      });
                                    } else {
                                      this.setState({
                                        charterers_p_and_i_liability: "No",
                                      });
                                    }
                                  }}
                                  name="checkedA"
                                />
                              }
                            />
                          </Grid>
                          <Grid item>Yes</Grid>
                        </Grid>
                      </Typography>
                    </div>
                    <div className="col-lg-6">
                      <label className="form_label mb-0">
                        Charterer's FD&D Liability
                      </label>
                      <Typography component="div">
                        <Grid
                          component="label"
                          container
                          alignItems="center"
                          spacing={1}
                        >
                          <Grid item>No</Grid>
                          <Grid
                            item
                            style={{
                              position: "relative",
                              top: "3px",
                              paddingLeft: "20px",
                            }}
                          >
                            <FormControlLabel
                              control={
                                <PurpleSwitch
                                  checked={
                                    this.state.charterers_fd_and_d_liability ===
                                    "Yes"
                                  }
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      this.setState({
                                        charterers_fd_and_d_liability: "Yes",
                                      });
                                    } else {
                                      this.setState({
                                        charterers_fd_and_d_liability: "No",
                                      });
                                    }
                                  }}
                                  name="checkedA"
                                />
                              }
                            />
                          </Grid>
                          <Grid item>Yes</Grid>
                        </Grid>
                      </Typography>
                    </div>
                    <div className="col-lg-6">
                      <label className="form_label mb-0">
                        Back to Back Sublet
                      </label>
                      <Typography component="div">
                        <Grid
                          component="label"
                          container
                          alignItems="center"
                          spacing={1}
                        >
                          <Grid item>No</Grid>
                          <Grid
                            item
                            style={{
                              position: "relative",
                              top: "3px",
                              paddingLeft: "20px",
                            }}
                          >
                            <FormControlLabel
                              control={
                                <PurpleSwitch
                                  checked={
                                    this.state.back_to_back_sublet === "Yes"
                                  }
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      this.setState({
                                        back_to_back_sublet: "Yes",
                                      });
                                    } else {
                                      this.setState({
                                        back_to_back_sublet: "No",
                                      });
                                    }
                                  }}
                                  name="checkedA"
                                />
                              }
                            />
                          </Grid>
                          <Grid item>Yes</Grid>
                        </Grid>
                      </Typography>
                    </div>
                    <div className="col-lg-6">
                      <label className="form_label mb-0">Charterer's B/L</label>
                      <Typography component="div">
                        <Grid
                          component="label"
                          container
                          alignItems="center"
                          spacing={1}
                        >
                          <Grid item>No</Grid>
                          <Grid
                            item
                            style={{
                              position: "relative",
                              top: "3px",
                              paddingLeft: "20px",
                            }}
                          >
                            <FormControlLabel
                              control={
                                <PurpleSwitch
                                  checked={this.state.charterers_b_l === "Yes"}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      this.setState({
                                        charterers_b_l: "Yes",
                                      });
                                    } else {
                                      this.setState({
                                        charterers_b_l: "No",
                                      });
                                    }
                                  }}
                                  name="checkedA"
                                />
                              }
                            />
                          </Grid>
                          <Grid item>Yes</Grid>
                        </Grid>
                      </Typography>
                    </div>
                    <div className="col-lg-12">
                      <label className="form_label mb-0">Contract Type</label>
                      <Typography component="div">
                        <Grid
                          component="label"
                          container
                          alignItems="center"
                          spacing={1}
                        >
                          <Grid item>Per Year</Grid>
                          <Grid
                            item
                            style={{
                              position: "relative",
                              top: "3px",
                              paddingLeft: "20px",
                            }}
                          >
                            <FormControlLabel
                              control={
                                <PurpleSwitch
                                  checked={
                                    this.state.contract_type === "Per Barge"
                                  }
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      this.setState({
                                        contract_type: "Per Barge",
                                      });
                                    } else {
                                      this.setState({
                                        contract_type: "Per Year",
                                      });
                                    }
                                  }}
                                  name="checkedA"
                                />
                              }
                            />
                          </Grid>
                          <Grid item>Per Barge</Grid>
                        </Grid>
                      </Typography>
                    </div>
                    {this.state.contract_type === "Per Barge" && (
                      <div className="col-lg-6">
                        <label className="form_label mb-0">P&I Value</label>
                        <TextField
                          name="p_and_i_value"
                          margin="dense"
                          variant="outlined"
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          value={this.state.p_and_i_value}
                          placeholder="Enter Value"
                          fullWidth
                          onChange={(e) => {
                            const pr = /^\d*(\.\d{0,4})?$/;
                            if (
                              e.target.value === "" ||
                              pr.test(e.target.value)
                            ) {
                              this.setState(
                                {
                                  p_and_i_value: e.target.value,
                                },
                                () => this.insuranceCalculations()
                              );
                            }
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                className="adornment_background"
                              >
                                USD
                              </InputAdornment>
                            ),
                            style: {
                              fontFamily: "Poppins",
                              fontSize: "14px",
                              padding: 0,
                            },
                          }}
                        />
                      </div>
                    )}
                    {this.state.contract_type === "Per Barge" && (
                      <div className="col-lg-6">
                        <label className="form_label mb-0">FD&D Value</label>
                        <TextField
                          name="fd_and_d_value"
                          margin="dense"
                          variant="outlined"
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          value={this.state.fd_and_d_value}
                          placeholder="Enter Value"
                          fullWidth
                          onChange={(e) => {
                            const pr = /^\d*(\.\d{0,4})?$/;
                            if (
                              e.target.value === "" ||
                              pr.test(e.target.value)
                            ) {
                              this.setState(
                                {
                                  fd_and_d_value: e.target.value,
                                },
                                () => this.insuranceCalculations()
                              );
                            }
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                className="adornment_background"
                              >
                                USD
                              </InputAdornment>
                            ),
                            style: {
                              fontFamily: "Poppins",
                              fontSize: "14px",
                              padding: 0,
                            },
                          }}
                        />
                      </div>
                    )}
                    {this.state.contract_type === "Per Barge" && (
                      <div className="col-lg-6">
                        <label className="form_label mb-0">Currency</label>
                        <TextField
                          name="billing_currency"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          select
                          InputProps={{
                            style: {
                              fontFamily: "Poppins",
                              fontSize: "14px",
                            },
                            disableUnderline: true,
                          }}
                          value={this.state.billing_currency}
                          onChange={(e) => {
                            this.setState(
                              {
                                billing_currency: e.target.value,
                              },
                              () => this.insuranceCalculations()
                            );
                          }}
                        >
                          {this.state.currencyData}
                        </TextField>
                      </div>
                    )}
                    {!(
                      config.company === "SRPL" ||
                      config.company === "AVS" ||
                      config.company === "PRPL"
                    ) &&
                      this.state.contract_type === "Per Barge" &&
                      this.state.billing_currency !== "IDR" &&
                      this.state.billing_currency !== "0" && (
                        <div className="col-lg-6">
                          <label className="form_label mb-0">
                            Exchange Rate {this.state.billing_currency} to IDR
                          </label>
                          <TextField
                            name="exchange_rate"
                            margin="dense"
                            variant="outlined"
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                            value={this.state.exchange_rate}
                            placeholder="Enter Value"
                            fullWidth
                            onChange={(e) => {
                              const pr = /^\d*(\.\d{0,4})?$/;
                              if (
                                e.target.value === "" ||
                                pr.test(e.target.value)
                              ) {
                                var value = e.target.value;
                                if (value === null || value === "") {
                                  value = 1;
                                }
                                if (
                                  config.company === "SRPL" ||
                                  config.company === "AVS" ||
                                  config.company === "PRPL"
                                ) {
                                  value = 1 / Number(value);
                                }
                                this.setState(
                                  {
                                    exchange_rate: e.target.value,
                                    currency_exchg_rate: value,
                                  },
                                  () => this.insuranceCalculations()
                                );
                              }
                            }}
                          />
                        </div>
                      )}
                    {(config.company === "SRPL" ||
                      config.company === "AVS" ||
                      config.company === "PRPL") &&
                      this.state.contract_type === "Per Barge" &&
                      this.state.billing_currency !== "USD" &&
                      this.state.billing_currency !== "0" && (
                        <div className="col-lg-6">
                          <label className="form_label mb-0">
                            Exchange Rate USD to {this.state.billing_currency}
                          </label>
                          <TextField
                            name="exchange_rate"
                            margin="dense"
                            variant="outlined"
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                            value={this.state.exchange_rate}
                            placeholder="Enter Value"
                            fullWidth
                            onChange={(e) => {
                              const pr = /^\d*(\.\d{0,4})?$/;
                              if (
                                e.target.value === "" ||
                                pr.test(e.target.value)
                              ) {
                                var value = e.target.value;
                                if (value === null || value === "") {
                                  value = 1;
                                }
                                if (
                                  config.company === "SRPL" ||
                                  config.company === "AVS" ||
                                  config.company === "PRPL"
                                ) {
                                  value = 1 / Number(value);
                                }
                                this.setState(
                                  {
                                    exchange_rate: e.target.value,
                                    currency_exchg_rate: value,
                                  },
                                  () => this.insuranceCalculations()
                                );
                              }
                            }}
                          />
                        </div>
                      )}
                    {this.state.contract_type === "Per Barge" && (
                      <div className="col-lg-6">
                        <label className="form_label mb-2">
                          Cost to Company
                        </label>
                        <div>
                          {this.state.cost_to_company
                            ? this.toLocaleString(this.state.cost_to_company)
                            : "-"}{" "}
                          {this.state.billing_currency !== "0"
                            ? this.state.billing_currency
                            : null}
                        </div>
                      </div>
                    )}
                  </div>

                  <hr />
                  {/* {(this.state.features.includes('362')  || this.state.features.includes('97') ) && */}

                  <FileUpload
                    files={this.state.files}
                    type="Costings"
                    invoice_file={this.state.invoice_file}
                    feature_name="Insurance_and_declaration"
                    callbackFn={(data, mandatoryFiles) => {
                      this.setState({
                        files: data,
                        costing_files: mandatoryFiles,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <footer className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button btn-light"
              name="submit"
              onClick={() => this.props.returnCallbackViewPage(true)}
            >
              CANCEL
            </button>
            <button
              type="button"
              className="drawer_text drawer_btn_success"
              name="Back"
              onClick={this.submitHandler}
            >
              UPDATE
            </button>
          </footer>
        </div>
      );
    }
  }
}
