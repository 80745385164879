import React, { Component } from "react";

import { Snackbar, Drawer } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import api from "../../../api/api";
import { toLocaleString } from "../../common/common";
import MvDocChargesAdd from "./Mv-Doc-Charges-Add";
import MvDocChargesView from "./Mv-Doc-Charges-View";
import MvDocChargesUpdate from "./Mv-Doc-Charges-Update";
import Loader from "../../common/Loader";

export default class MvDocChargesList extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      MVDocCharges: [],
      isLoading: true,
      updateDocuments: false,
      features: [],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (!(features.includes("275") || features.includes("97"))) {
      window.location.href = "/dashboard";
    }
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const businessNoID = this.props.businessNoID;
    //Get businessNumber API based on businessNumber
    await api
      .getBusinessNumber(businessNoID, loginUserID, idToken)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.business_number) {
                this.setState({
                  businessNo: res.business_number.business_no
                    ? res.business_number.business_no
                    : "",
                  mother_vessel_name: res.business_number.mother_vessel_name
                    ? res.business_number.mother_vessel_name
                    : "",
                  //isLoading: false
                });
              }
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                snackBarErrorOpen: true,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });

    await api
      .get_mv_pur_fin_doc_charges(loginUserID, idToken, businessNoID)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.mv_pur_fin_doc_charges) {
                var doc_total_paid_amount = 0;
                var laprn_total_paid_amount = 0;
                var others_total_paid_amount = 0;
                for (var i = 0; i < res.mv_pur_fin_doc_charges.length; i++) {
                  const filter_dc_payments = res.mv_pur_fin_doc_charges[
                    i
                  ].doc_charges_payments.filter(
                    (e, idx) => e.payment_posting_type === "doc"
                  );
                  res.mv_pur_fin_doc_charges[i].doc_payments =
                    filter_dc_payments;
                  const filter_laprn_payments = res.mv_pur_fin_doc_charges[
                    i
                  ].doc_charges_payments.filter(
                    (e, idx) => e.payment_posting_type === "laprn"
                  );
                  res.mv_pur_fin_doc_charges[i].laprn_payments =
                    filter_laprn_payments;
                  const filter_other_payments = res.mv_pur_fin_doc_charges[
                    i
                  ].doc_charges_payments.filter(
                    (e, idx) => e.payment_posting_type === "other"
                  );
                  res.mv_pur_fin_doc_charges[i].other_payments =
                    filter_other_payments;

                  res.mv_pur_fin_doc_charges[i].doc_remaining_amount = Number(
                    res.mv_pur_fin_doc_charges[i].dc_total
                  );
                  for (var j of filter_dc_payments) {
                    doc_total_paid_amount += Number(j.amount);
                    res.mv_pur_fin_doc_charges[i].doc_remaining_amount =
                      Number(res.mv_pur_fin_doc_charges[i].dc_total) -
                      Number(doc_total_paid_amount);
                    res.mv_pur_fin_doc_charges[i].doc_total_paid_amount =
                      doc_total_paid_amount;
                  }

                  res.mv_pur_fin_doc_charges[i].laprn_remaining_amount = Number(
                    res.mv_pur_fin_doc_charges[i].dc_laprn_surv_total
                  );
                  for (var k of filter_laprn_payments) {
                    laprn_total_paid_amount += Number(k.amount);
                    res.mv_pur_fin_doc_charges[i].laprn_remaining_amount =
                      Number(
                        res.mv_pur_fin_doc_charges[i].dc_laprn_surv_total
                      ) - Number(laprn_total_paid_amount);
                    res.mv_pur_fin_doc_charges[i].laprn_total_paid_amount =
                      laprn_total_paid_amount;
                  }
                  res.mv_pur_fin_doc_charges[i].others_remaining_amount = 0;
                  for (var key of filter_other_payments) {
                    others_total_paid_amount += Number(key.amount);
                  }
                  for (var obj of res.mv_pur_fin_doc_charges[i].other_charges) {
                    res.mv_pur_fin_doc_charges[i].others_remaining_amount =
                      Number(obj.other_total) -
                      Number(others_total_paid_amount);
                    res.mv_pur_fin_doc_charges[i].others_total_paid_amount =
                      others_total_paid_amount;
                  }
                }
                this.setState({
                  MVDocCharges: res.mv_pur_fin_doc_charges,
                  isLoading: false,
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                snackBarErrorOpen: true,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  }

  onAddResponse = (value) => {
    if (value.code === "200") {
      this.setState(
        {
          MvDocChargesAdd: false,
          MvDocChargesView: false,
          MvDocChargesUpdate: false,
          openDrawer: false,
          updateDocuments: false,
        },
        () => this.componentDidMount()
      );
    } else {
      this.setState({
        MvDocChargesAdd: false,
        MvDocChargesView:
          value.screen === "MvDoc" && value.message === "alreadyDataAvailable"
            ? true
            : false,
        MvDocChargesUpdate: false,
        openDrawer: true,
        updateDocuments: false,
      });
    }
  };

  CallbackDrawerClose = (childData) => {
    this.setState(
      {
        openDrawer: childData,
        MvDocChargesAdd: false,
        MvDocChargesView: false,
        MvDocChargesUpdate: false,
        updateDocuments: false,
      },
      () => this.componentDidMount()
    );
  };

  onUpdateResponse = (value) => {
    if (value) {
      this.setState(
        {
          MvDocChargesAdd: false,
          MvDocChargesView:
            value.code === "200" && value.screen === "MvDoc" ? true : false,
          MvDocChargesUpdate: false,
          updateDocuments: false,
        },
        () => this.componentDidMount()
      );
    }
  };

  CallbackViewPage = (childData) => {
    this.setState(
      {
        openDrawer: true,
        MvDocChargesUpdate: false,
        MvDocChargesAdd: false,
        MvDocChargesView: childData === "MvDoc" ? true : false,
        updateDocuments: false,
      },
      () => this.componentDidMount()
    );
  };

  editCallback = (childData) => {
    this.setState(
      {
        openDrawer: true,
        MvDocChargesUpdate: childData === "MvDoc" ? true : false,
        MvDocChargesAdd: false,
        MvDocChargesView: false,
        updateDocuments: false,
      },
      () => this.componentDidMount()
    );
  };

  deleteCharges = (data) => {
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    var finalData = data;
    finalData.mv_doc_charges_id = data.id;
    finalData.login_user_id = loginUserID;
    finalData.idtoken = idToken;
    finalData.status = "Deleted";
    // return;
    //Update
    api.update_mv_doc_charges(finalData).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.setState(
              {
                snackBarSuccessOpen: true,
                successMsg: res.message,
              },
              () => this.componentDidMount()
            );
          } else if (res.code === "601") {
            this.setState({ errorMsg: res.message, snackBarErrorOpen: true });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          } else if (res.code === "624") {
            this.setState({
              errorMsg: res.columns.length > 0 ? res.columns[0] : "",
              snackBarErrorOpen: true,
            });
          }
        });
      }
    });
  };

  render() {
    let drawerComponent;
    if (this.state.MvDocChargesAdd) {
      drawerComponent = (
        <MvDocChargesAdd
          businessNoID={this.props.businessNoID}
          onAddResponse={this.onAddResponse}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
        />
      );
    } else if (this.state.MvDocChargesView && this.state.businessNo) {
      drawerComponent = (
        <MvDocChargesView
          MVDocChargesID={this.state.MVDocChargesID}
          businessNoID={this.state.businessNo}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.editCallback}
          returnDocumentsHandler={() => {
            this.setState({
              showDrawer: true,
              MvDocChargesUpdate: true,
              MvDocChargesView: false,
              MvDocChargesAdd: false,
              updateDocuments: true,
            });
          }}
        />
      );
    } else if (this.state.MvDocChargesUpdate && this.state.businessNo) {
      drawerComponent = (
        <MvDocChargesUpdate
          MVDocChargesID={this.state.MVDocChargesID}
          businessNoID={this.state.businessNo}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.CallbackViewPage}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div>
          <div className="row clearfix content-header border-bottom">
            <h4 className="mt-2" style={{ padding: "4px" }}>
              Documentation Charges
            </h4>
            {(this.state.features.includes("274") ||
              this.state.features.includes("97")) && (
              <i
                className="fa fa-plus-circle ml-2 mt-3"
                aria-hidden="true"
                style={{
                  fontSize: 22,
                  color: config.themeColor,
                  cursor: "pointer",
                }}
                onClick={() => {
                  this.setState({
                    openDrawer: true,
                    MvDocChargesAdd: true,
                    MvDocChargesView: false,
                    MvDocChargesUpdate: false,
                  });
                }}
              />
            )}
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <Drawer
            anchor="right"
            open={this.state.openDrawer}
            variant="temporary"
            elevation={20}
            style={{ overflow: "initial" }}
          >
            <div className="row" style={{ width: 800 }}>
              {drawerComponent}
            </div>
          </Drawer>
          <div className="card pl-0 pr-0">
            <div
              className="card-body p-0"
              style={{ backgroundColor: "rgba(25,118,210,.01)" }}
              id="accordion"
            >
              <div className="col-lg-12 mb-2 p-0">
                <div className="row">
                  <div className="table-responsive">
                    <table className="table table-sm table-bordered">
                      <thead className="table-header">
                        <tr>
                          <th nowrap="true"> Costing ID </th>
                          <th nowrap="true"> Documentation by </th>
                          <th nowrap="true"> Quantity in MT </th>
                          <th nowrap="true"> Price Type</th>
                          <th nowrap="true">Price Per MT</th>
                          <th nowrap="true"> Payable to Vendor</th>
                          <th nowrap="true">Action</th>
                        </tr>
                      </thead>
                      {this.state.MVDocCharges?.length > 0 ? (
                        <tbody>
                          {this.state.MVDocCharges?.map((idr, indx) => (
                            <tr key={indx}>
                              <td className="allocation_table_value">
                                {(this.state.features.includes("275") ||
                                  this.state.features.includes("97")) && (
                                  <i
                                    aria-hidden="true"
                                    style={{
                                      fontStyle: "normal",
                                      color: config.themeColor,
                                      cursor: "pointer",
                                      whiteSpace: "nowrap",
                                    }}
                                    onClick={() =>
                                      this.setState({
                                        openDrawer: true,
                                        MvDocChargesAdd: false,
                                        MvDocChargesView: true,
                                        MvDocChargesUpdate: false,
                                        businessNo: idr.business_no_id,
                                        MVDocChargesID: idr.id,
                                      })
                                    }
                                  >
                                    {idr.costing_no}
                                  </i>
                                )}
                              </td>
                              <td
                                className="allocation_table_value"
                                nowrap="true"
                              >
                                {idr.dc_documentation_name}
                              </td>
                              <td
                                className="allocation_table_value text-right"
                                nowrap="true"
                              >
                                {toLocaleString(idr.dc_quantity_in_mt)}
                              </td>
                              <td
                                className="allocation_table_value"
                                nowrap="true"
                              >
                                {idr.dc_price_type}
                              </td>
                              <td
                                className="allocation_table_value text-right"
                                nowrap="true"
                              >
                                {`${toLocaleString(idr.dc_price_per_mt)} ${
                                  idr.billing_currency
                                }`}
                              </td>
                              <td
                                className="allocation_table_value text-right"
                                nowrap="true"
                              >
                                {`${toLocaleString(idr.dc_total)} ${
                                  config.company === "SRPL" ||
                                  config.company === "AVS" ||
                                  config.company === "PRPL"
                                    ? "USD"
                                    : "IDR"
                                }`}
                              </td>

                              <td
                                nowrap="true"
                                className="table_td text-left pl-4"
                              >
                                {(this.state.features.includes("276") ||
                                  this.state.features.includes("97")) && (
                                  <i
                                    className="fa fa-pencil"
                                    style={{
                                      fontSize: 14,
                                      color: config.themeColor,
                                      cursor: "pointer",
                                    }}
                                    data-toggle="modal"
                                    title="Edit floating crane charges"
                                    data-placement="bottom"
                                    onClick={() =>
                                      this.setState({
                                        businessNo: idr.business_no_id,
                                        MVDocChargesID: idr.id,
                                        openDrawer: true,
                                        MvDocChargesUpdate: true,
                                      })
                                    }
                                  ></i>
                                )}
                                {idr.payment_postings_availability === 0 &&
                                  (this.state.features.includes("277") ||
                                    this.state.features.includes("97")) &&
                                  idr.approval_status === "Pending" && (
                                    <i
                                      className="fa fa-trash-o"
                                      style={{
                                        paddingLeft: 10,
                                        fontSize: 14,
                                        color: config.themeColor,
                                        cursor: "pointer",
                                      }}
                                      onClick={() => this.deleteCharges(idr)}
                                    ></i>
                                  )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td colSpan="5" className="p-2">
                              No data available
                            </td>
                          </tr>{" "}
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
