import React from "react";
import Paper from "@material-ui/core/Paper";

import CookieHandler from "../common/CookieHandler";
import config from "../../config/config";
import api from "../../api/api";
import SideBar from "../common/SideBar";
import Header from "../common/Header";
import MaterialTable from "material-table";

import exportToExcel from "../Export_To_Excel";

class NewBusinessNumberList extends React.Component {
  constructor() {
    super();
    this.Cookie = new CookieHandler();
    this.state = {
      rawData: null,
      data: [],
      userRole: [],
      pages: null,
      loading: true,
      isLoading: false,
      errorMessage: "",
      successMessage: "",
      features: [],
    };
  }

  componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    } else {
      var features = this.Cookie.getCookie("features");
      this.setState({ features: features });
      // if(!(features.includes('37')  || features.includes('97')  )) {
      //   window.location.href='/dashboard';
      // }
      var loginUserID = this.Cookie.getCookie("loginUserId");
      var idToken = this.Cookie.getIdTokenCookie();
      api.getBusinessNumbers(loginUserID, idToken).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
              if (res.business_numbers) {
                this.setState({
                  businessData: res.business_numbers,
                  loading: false,
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
    }

    document.title = config.documentTitle + "Business Number List";
  }

  convertExcelHandler = (data, filename) => {
    var arrData = typeof data != "object" ? JSON.parse(data) : data;
    let Modified = arrData.map((obj) => {
      return {
        "Business No": obj.business_no,
        "Business type": obj.business_type,
        "Consignment Type": obj.consignment_type,
        "Mother Vessel Name": obj.mother_vessel_name,
        "Shipment Type": obj.shipment_type,
        "Buy Back Involved": obj.buy_back_involved,
        "Vessel Type": obj.vessel_type,
        "Stevedore Vendor": obj.stevedore_vendor,
        "Floating Crane Vendor": obj.floating_crane_vendor,
        "Floating Crane Name": obj.floating_crane_name,
        "Loading Anchorage": obj.loading_anchorage,
        "Load Port Agent": obj.load_port_agent,
        "Load Rate per MT/Day": obj.load_rate_per_day,
        "Vessel Freight": obj.vessel_freight,
        "Vessel Owner": obj.vessel_owner,
        "Vessel Freight Price": obj.vessel_freight_price,
        "Vessel Arrived": obj.vessel_arrived_on,
        "ETA / NOR": obj.eta_nor,
        "NOR Acceptance": obj.nor_acceptance,
        "Documents on-board": obj.documents_on_board,
        "Vessel Sailing": obj.vessel_sailing,
        "Demurrage Rate from Buyer": obj.demurrage_rate_from_buyer,
        "Demurrage Rate to Supplier": obj.demurrage_rate_to_supplier,
        "Stowage Plan in MT": obj.stowage_plan_in_mt,
        "Supply Tonnage in MT": obj.supply_tonnage_in_mt,
        Remarks: obj.remarks,
      };
    });
    exportToExcel(Modified, filename);
  };
  render() {
    return (
      <div id="wraper">
        <SideBar />
        <div className="content">
          <Header />
          <div className="clearfix content-header">
            <div className="row">
              <div className="col-sm text-left pl-0">
                <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                  Business Numbers
                </h4>
              </div>
              {(this.state.features.includes("37") ||
                this.state.features.includes("97")) && (
                <div className="col text-right float-sm-left">
                  <a href="/new-business">
                    <button
                      className="header_button header_button_text add_button_adjustment"
                      style={{ width: "auto", marginTop: "20px" }}
                    >
                      New Business Number
                    </button>
                  </a>
                </div>
              )}
            </div>
          </div>
          {this.state.isLoading && (
            <div className="lds-roller">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          )}
          {!this.state.isLoading && (
            <div className="container p-3">
              <div className="col-sm card p-0">
                {this.state.errorMessage && (
                  <div className="row">
                    <div className="col text-center">
                      <p className="error-class">{this.state.errorMessage}</p>
                    </div>
                  </div>
                )}
                <Paper>
                  <MaterialTable
                    columns={[
                      {
                        title: "Business No",
                        field: "business_no",
                        cellStyle: {
                          width: 180,
                        },
                        // id:'business_no',
                        render: (row) => {
                          if (
                            this.state.features.includes("38") ||
                            this.state.features.includes("97")
                          ) {
                            return (
                              <a
                                style={{
                                  textDecoration: "none",
                                  color: config.themeColor,
                                }}
                                href={"/view-business/" + btoa(row.id)}
                              >
                                {row.business_no}
                              </a>
                            );
                          } else {
                            return <span>{row.business_no}</span>;
                          }
                        },
                        style: { textAlign: "center" },
                      },
                      // {
                      //   title: "Old Business Number",
                      //   field: "old_business_no",
                      //   hidden: config.environment !== 'Production' ? false : true
                      // },
                      {
                        title: "Business Head",
                        field: "business_head_name",
                      },
                      {
                        title: "Consignment Type",
                        field: "consignment_type",
                      },
                      {
                        title: "Mother Vessel Name",
                        field: "mother_vessel_name",
                      },
                      // {
                      //   title: "Barge Name",
                      //   field: "barge_name",
                      // },
                      {
                        title: "Shipment Type",
                        field: "shipment_type",
                      },
                      // {
                      //   title: "Load Rate MT/Day",
                      //   field: "load_rate_per_day",
                      // },
                      {
                        title: "Stowage Plan in MT",
                        field: "stowage_plan_in_mt",
                      },
                      {
                        title: "Supply Tonnage in MT",
                        field: "supply_tonnage_in_mt",
                        headerStyle: {
                          minWidth: 220,
                        },
                      },
                      {
                        title: "Status",
                        field: "status",
                        render: (row) => {
                          return (
                            <span>
                              {row.status === "Active" ? (
                                <span className="active_status">
                                  <i
                                    className="fa fa-circle dot_align"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  {row.status}
                                </span>
                              ) : (
                                <span className="cancel_status">
                                  <i
                                    className="fa fa-circle dot_align"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  {row.status}
                                </span>
                              )}
                            </span>
                          );
                        },
                      },
                      {
                        title: "Action",
                        field: "id",
                        headerStyle: {
                          textAlign: "center",
                        },
                        sorting: false,
                        render: (row) => {
                          return (
                            <div
                              style={{
                                textAlign: "center",
                                marginTop: "-10px",
                                marginBottom: "-10px",
                              }}
                            >
                              {row.status === "Active" && (
                                <a
                                  href={"/update-business/" + btoa(row.id)}
                                  rel="noopener noreferrer"
                                  role="button"
                                >
                                  <i className="fa fa-pencil edit_icon" />
                                </a>
                              )}
                            </div>
                          );
                        },
                        hidden: !(
                          this.state.features.includes("39") ||
                          this.state.features.includes("97")
                        ),
                      },
                    ]}
                    title=""
                    data={this.state.businessData}
                    isLoading={this.state.loading}
                    style={{
                      minHeight: 200,
                      fontFamily: "Poppins",
                      overflowY: "auto",
                      boxShadow: "0 0 5px 0 rgba(0, 39, 77, 0.1)",
                    }}
                    options={{
                      paginationType: "stepped",
                      pageSize: 20,
                      pageSizeOptions: [5, 10, 20, 50, 100, 200],
                      headerStyle: {
                        backgroundColor: "#f9f9f9",
                        minWidth: 215,
                        fontFamily: "Poppins",
                        textTransform: "uppercase",
                        fontSize: "14px",
                        fontWeight: 500,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "0.86",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "rgba(72, 59, 52, 0.7)",
                      },
                      cellStyle: {
                        fontFamily: "Poppins",
                      },
                      // exportAllData: true,
                      // exportButton: true,
                      draggable: false,
                      exportFileName: "business-numbers",
                      // tableLayout: 'fixed'
                    }}
                    actions={[
                      {
                        icon: "download",
                        tooltip: "Download",
                        isFreeAction: true,
                        onClick: (event) =>
                          this.convertExcelHandler(
                            this.state.businessData,
                            "Business Number"
                          ),
                      },
                    ]}
                  />
                </Paper>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default NewBusinessNumberList;
