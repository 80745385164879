import React, { Component } from "react";

import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import api from "../../../api/api";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { localDateFormate, toLocaleString } from "../../common/common";
import ViewFiles from "../../common/ViewFiles";
import InvoiceForm from "../../common/InvoiceForm";
import PaymentPostings from "../../common/PaymentPostings";
import Loader from "../../common/Loader";
import InvoiceLink from "../../InvoicePdf/InvoiceLink";
import AuditInfo from "../../common/AuditInfo";

export default class MvOthersView extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      multiRows: [],
      isLoading: true,
      features: [],
      invoiceDialog: false,
      bargeDetails: [],
      sac_surveyor_vat_value: "",
      sac_surveyor_pph23_value: "",
      sac_other_vat_value: "",
      sac_other_pph23_value: "",
      sac_surveyor_currency_xchg_rate: "",
      mother_vessel_name: "",
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    } else {
      var features = this.Cookie.getCookie("features");
      this.setState({ features: features });
      if (
        !(
          features.includes("243") ||
          features.includes("419") ||
          features.includes("470") ||
          features.includes("97")
        )
      ) {
        window.location.href = "/dashboard";
      }
      var loginUserID = this.Cookie.getCookie("loginUserId");
      var idToken = this.Cookie.getIdTokenCookie();
      try {
        const MVSamplingChargesID = this.props.MVSamplingChargesID;
        //Get businessNumber API based on businessNumber
        await api
          .get_mv_sac_charges(loginUserID, idToken, MVSamplingChargesID)
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              response.json().then((res) => {
                if (res.code === "200") {
                  if (res.mv_pur_fin_sac_charges) {
                    for (var i of res.mv_pur_fin_sac_charges) {
                      var total_payable_vendor = 0;
                      var total_paid_to_vendor = 0;
                      var remaining_amount = 0;
                      total_payable_vendor += Number(
                        i.sac_surveyor_addl_days_total
                      );
                      for (var j of i.other_charges) {
                        total_payable_vendor += Number(j.sac_other_total);
                      }

                      for (var obj of i.mv_surveyor_charges) {
                        total_payable_vendor += Number(obj.sac_surveyor_total);
                      }

                      for (var k of i.sac_charges_payments) {
                        total_paid_to_vendor += Number(k.amount);
                      }
                      remaining_amount =
                        Number(total_payable_vendor) -
                        Number(total_paid_to_vendor);

                      i.total_payable_vendor = total_payable_vendor;
                      i.total_paid_to_vendor = total_paid_to_vendor;
                      i.remaining_amount = remaining_amount;
                    }
                    this.setState((prevState) => ({
                      ...prevState,
                      multiRows: res.mv_pur_fin_sac_charges,
                      isLoading: false,
                    }));
                  }
                  if (res.mv_pur_fin_sac_charges.length <= 0) {
                    this.setState({
                      errorMsg: "No Sampling & Analysis charges available",
                      snackBarErrorOpen: true,
                      isLoading: false,
                    });
                    setTimeout(
                      () => this.props.returnCallbackDrawerClose(false),
                      2000
                    );
                  }
                } else if (res.code === "601") {
                  this.setState({
                    errorMsg: res.message,
                    snackBarErrorOpen: true,
                    isLoading: false,
                  });
                } else if (res.code === "607") {
                  window.location.href = "/logout";
                }
              });
            }
          });
      } catch (e) {
        // window.location.href = '/business-number-list';
      }
    }
  }

  approveHandler = () => {
    this.setState({ isLoading: true });

    let request = {
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
      costing_id: this.props.MVSamplingChargesID,
      costing_type: "MV Sampling & Analaysis Charges",
      approval_status: "Approved",
    };
    api.approve_costing_api(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.setState(
              {
                snackBarSuccessOpen: true,
                successMsg: res.message,
              },
              () => this.componentDidMount()
            );
          } else if (res.code === "601") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
  };

  updateInvoice = (data) => {
    let request = {
      ...data,
      table_name: "mv_pur_fin_sac_charges",
    };
    api.update_invoice_details(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.componentDidMount();
            this.setState({
              invoiceDialog: false,
            });
          }
        });
      }
    });
  };

  renderPaymentPostings = (data) => {
    const {
      id,
      sac_charges_payments = [],
      invoice_no = null,
      remaining_amount = 0,
      sac_surveyor_currency_xchg_rate = 1,
      approval_status = "Pending",
    } = data;
    let payment_related_data = {
      paymentTitle: "MV Sampling & Analaysis Charges",
      payment_posting_type: "mv_sac",
      invoice_no: invoice_no,
      outstanding_amount: remaining_amount,
      billing_currency:
        config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL"
          ? "USD"
          : "IDR",
      currency_xchg_rate: sac_surveyor_currency_xchg_rate
        ? sac_surveyor_currency_xchg_rate
        : 1,
      costing_id: id,
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
    };
    let access_details = {
      costing_approval: approval_status,
      add_payment: "246",
      view_payment: "247",
      update_payment: "248",
      delete_payment: "249",
      approver: "420",
      approver2: "471",
    };

    return (
      <PaymentPostings
        access_details={access_details}
        payments={sac_charges_payments}
        costingType="MV Sampling & Analaysis Charges"
        data={payment_related_data}
        callbackFn={() => {
          this.componentDidMount();
        }}
      />
    );
  };

  renderInvoiceLink = () => {
    const { costing_no } = this.state;
    const surveyor_charges = [];
    const othercharges = [];
    for (var i of this.state.multiRows) {
      for (var j of i.mv_surveyor_charges) {
        // var total = i.mv_surveyor_charges.reduce((acc, item) => (Number(acc) + Number(item.sac_surveyor_total)), 0)
        var sacvatValue = i.mv_surveyor_charges.reduce(
          (acc, item) => Number(acc) + Number(item.sac_surveyor_vat_value),
          0
        );
        var sacPPhValue = i.mv_surveyor_charges.reduce(
          (acc, item) => Number(acc) + Number(item.sac_surveyor_pph23_value),
          0
        );
        // var sacQuantity = i.mv_surveyor_charges.reduce((acc, item) => (Number(acc) + Number(item.sac_quantity_in_mt)), 0)
        // var sacPricePMT = i.mv_surveyor_charges.reduce((acc, item) => (Number(acc) + Number(item.sac_price_per_mt)), 0)
        // var sacCurrencyExchg = i.mv_surveyor_charges.reduce((acc, item) => (Number(acc) + Number(item.sac_surveyor_currency_xchg_rate)), 0)
        surveyor_charges.push({
          qty: toLocaleString(j.sac_quantity_in_mt),
          adjustedPrice: toLocaleString(j.sac_price_per_mt),
          desc: `${
            j.sac_surveyor_currency_xchg_rate > 1
              ? `Sampling & Analysis(MV) @ ${parseFloat(
                  j.sac_surveyor_currency_xchg_rate
                ).toFixed(2)}`
              : "MV Sampling & Analysis(MV"
          }`,
          amount: j.sac_surveyor_total
            ? parseFloat(j.sac_surveyor_total).toFixed(2)
            : "",
          // amount: (total),
        });
      }
      for (var k of i.other_charges) {
        // var othertotal = i.other_charges.reduce((acc, item) => (Number(acc) + Number(item.sac_other_total)), 0)
        var otherVatValue = i.other_charges.reduce(
          (acc, item) => Number(acc) + Number(item.sac_other_vat_value),
          0
        );
        var otherpphValue = i.other_charges.reduce(
          (acc, item) => Number(acc) + Number(item.sac_other_pph23_value),
          0
        );
        othercharges.push(
          !!k.sac_other_total && {
            qty: "",
            adjustedPrice: k.sac_other_base_price
              ? parseFloat(k.sac_other_base_price).toFixed(2)
              : "",
            desc: `${
              k.sac_other_currency_xchg_rate > 1
                ? `Other Charges @ ${parseFloat(
                    k.sac_other_currency_xchg_rate
                  ).toFixed(2)}`
                : "Other Charges"
            }`,
            amount: k.sac_other_total,
          }
        );
      }
    }

    const Grand_total = parseFloat(
      this.state.multiRows[0].total_payable_vendor
        ? this.state.multiRows[0].total_payable_vendor
        : 0
    ).toFixed(2);

    const invoiceObject = {
      costing_id: this.state.multiRows[0].costing_no,
      to: this.state.mv_sac_charges_vendor_name,
      business_no: this.state.mother_vessel_name,
      costing_name: "MV Sampling & Analaysis Charges",
      invoice_no: this.state.multiRows[0].invoice_no,
      invoice_date: localDateFormate(this.state.multiRows[0].invoice_date),
      bargeDetails: [],
      billing_currency:
        config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL"
          ? "USD"
          : "IDR",
      total_payable_amount: toLocaleString(Grand_total),
      purchase_type: "FOB Barge",
      description: [
        //Sac_Charges
        ...surveyor_charges,

        //Other_Charges

        ...othercharges,

        ...(!!this.state.multiRows[0].sac_surveyor_addl_days
          ? [
              {
                qty: toLocaleString(
                  this.state.multiRows[0].sac_surveyor_addl_days
                ),
                adjustedPrice: toLocaleString(
                  this.state.multiRows[0].sac_surveyor_price_per_addl_day
                ),
                desc: `Additional Charges`,
                amount: this.state.multiRows[0].sac_surveyor_addl_days_total,
              },

              (sacvatValue ||
                otherVatValue ||
                this.state.multiRows[0].sac_surveyor_addl_days_vat_value) && {
                qty: "",
                adjustedPrice: " ",
                desc: "VAT",
                amount: (
                  Number(sacvatValue) +
                  Number(otherVatValue) +
                  Number(
                    this.state.multiRows[0].sac_surveyor_addl_days_vat_value
                  )
                ).toFixed(2),
              },
              !(
                config.company === "SRPL" ||
                config.company === "AVS" ||
                config.company === "PRPL"
              ) &&
                (sacPPhValue ||
                  otherpphValue ||
                  this.state.multiRows[0]
                    .sac_surveyor_addl_days_pph23_value) && {
                  qty: " ",
                  adjustedPrice: " ",
                  desc: "PPH",
                  amount: `(${(
                    Number(sacPPhValue) +
                    Number(otherpphValue) +
                    Number(
                      this.state.multiRows[0].sac_surveyor_addl_days_pph23_value
                    )
                  ).toFixed(2)})`,
                },
            ]
          : []),
      ],
    };
    return <InvoiceLink data={invoiceObject} fileName={costing_no} />;
  };

  render() {
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      for (var i of this.state.multiRows) {
        // for (var j of i.mv_surveyor_charges) {
        // var total = i.mv_surveyor_charges.reduce((acc, item) => (Number(acc) + Number(item.sac_surveyor_total)), 0)
        var sacvatValue = i.mv_surveyor_charges.reduce(
          (acc, item) => Number(acc) + Number(item.sac_surveyor_vat_value),
          0
        );
        var sacPPhValue = i.mv_surveyor_charges.reduce(
          (acc, item) => Number(acc) + Number(item.sac_surveyor_pph23_value),
          0
        );
        var sacQuantity = i.mv_surveyor_charges.reduce(
          (acc, item) => Number(acc) + Number(item.sac_quantity_in_mt),
          0
        );
        var sacPricePMT = i.mv_surveyor_charges.reduce(
          (acc, item) => Number(acc) + Number(item.sac_price_per_mt),
          0
        );
        // var sacCurrencyExchg = i.mv_surveyor_charges.reduce((acc, item) => (Number(acc) + Number(item.sac_surveyor_currency_xchg_rate)), 0)
        var sacBasePrice = i.mv_surveyor_charges.reduce(
          (acc, item) => Number(acc) + Number(item.sac_surveyor_base_price),
          0
        );
        // }
        // for (var k of i.other_charges) {
        // var othertotal = i.other_charges.reduce((acc, item) => (Number(acc) + Number(item.sac_other_total)), 0)
        var otherVatValue = i.other_charges.reduce(
          (acc, item) => Number(acc) + Number(item.sac_other_vat_value),
          0
        );
        var otherpphValue = i.other_charges.reduce(
          (acc, item) => Number(acc) + Number(item.sac_other_pph23_value),
          0
        );
        // var otherExchgRate = i.other_charges.reduce((acc, item) => (Number(acc) + Number(item.sac_other_currency_xchg_rate)), 0)
        // var otherBasePrice = i.other_charges.reduce((acc, item) => (Number(acc) + Number(item.sac_other_base_price)), 0)
        // }
      }
      return (
        <div
          className="container bg-white p-0"
          style={{ minHeight: "100vh", width: 850 }}
        >
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          {this.state.multiRows.map((e, idx) => (
            <div className="clearfix content-header border-bottom">
              <div className="row">
                <div className="col-sm text-left pl-0">
                  <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                    {`Sampling & Analysis Charges - ${e.costing_no}`}
                    <span
                      className={
                        e.approval_status_2 === "Pending" ? "type_2" : "type_1"
                      }
                      style={{ padding: 8, marginLeft: 16 }}
                    >
                      {e.approval_status_2}
                    </span>
                  </h4>
                </div>
                {e.approval_status === "Pending" &&
                  e.invoice_file &&
                  this.Cookie.getCookie("loginUserId") !== e.created_by &&
                  e.approved_by !== this.Cookie.getCookie("loginUserId") &&
                  (this.state.features.includes("419") ||
                    this.state.features.includes("470") ||
                    this.state.features.includes("97")) && (
                    <div
                      className="col text-right float-sm-left"
                      style={{ padding: "5px 20px" }}
                    >
                      <button
                        className="header_button header_button_text add_button_adjustment"
                        style={{ width: "auto", marginTop: "17px" }}
                        onClick={this.approveHandler}
                      >
                        Approve
                      </button>
                    </div>
                  )}
                <div className="col-lg-1 pl-0 float-right">
                  {this.renderInvoiceLink()}
                </div>
              </div>
            </div>
          ))}
          <div className="drawer-section">
            <div className="card p-3">
              <div
                className="card-body p-0"
                style={{ backgroundColor: "rgba(25,118,210,.01)" }}
                id="accordion"
              >
                {this.state.multiRows.map((e, idx) => (
                  <>
                    <div key={idx}>
                      <div className="row mb-3">
                        {/* {e.mv_surveyor_charges.map((obj, index) => ( */}
                        <>
                          <div className="row col-lg-12 p-0">
                            <h5>Surveyor Charges</h5>
                          </div>
                          <div className="table-responsive">
                            <table className="table table-sm table-bordered mb-0">
                              <thead className="table-header">
                                <tr>
                                  <th className="text-center">Description</th>
                                  <th className="text-right">Qty</th>
                                  <th className="text-right">Unit Price</th>
                                  <th className="text-right">Amount</th>
                                </tr>
                              </thead>
                              <tbody>
                                {e.mv_surveyor_charges.map((obj, index) => (
                                  <tr>
                                    <td>
                                      Surveyor Charges (MV)
                                      {obj.sac_surveyor_currency_xchg_rate >
                                        1 &&
                                        (config.company === "SDAM" ||
                                          config.company === "SRK" ||
                                          config.company === "BTR") &&
                                        this.state.billing_currency !==
                                          "IDR" && (
                                          <div>
                                            Exchange Rate @
                                            {obj.sac_surveyor_currency_xchg_rate
                                              ? parseFloat(
                                                  obj.sac_surveyor_currency_xchg_rate
                                                ).toFixed(2)
                                              : ""}
                                          </div>
                                        )}
                                      <div>
                                        {" "}
                                        Surveyor :
                                        {obj.mv_sac_charges_vendor_name
                                          ? obj.mv_sac_charges_vendor_name
                                          : "_"}
                                      </div>
                                    </td>
                                    <td className="text-right">
                                      {sacQuantity
                                        ? toLocaleString(obj.sac_quantity_in_mt)
                                        : ""}
                                    </td>
                                    <td className="text-right">
                                      {sacPricePMT
                                        ? toLocaleString(obj.sac_price_per_mt)
                                        : ""}
                                    </td>
                                    <td className="text-right">
                                      {sacBasePrice
                                        ? toLocaleString(
                                            obj.sac_surveyor_base_price
                                          )
                                        : ""}
                                    </td>
                                  </tr>
                                ))}
                                {e.other_charges.map(
                                  (p, indx) =>
                                    p.sac_other_base_price && (
                                      <tr>
                                        <td>
                                          Other Charges{" "}
                                          {p.sac_other_description
                                            ? `- ${p.sac_other_description}`
                                            : ""}
                                          {p.sac_other_currency_xchg_rate && (
                                            <div>
                                              Exchange Rate @{" "}
                                              {parseFloat(
                                                p.sac_other_currency_xchg_rate
                                              ).toFixed(2)}
                                            </div>
                                          )}
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td className="text-right">
                                          {toLocaleString(
                                            p.sac_other_base_price
                                          )}
                                        </td>
                                      </tr>
                                    )
                                )}
                                {e.sac_surveyor_addl_days && (
                                  <tr>
                                    <td>
                                      Additional Days
                                      {e.sac_surveyor_addl_days_currency_xchg_rate >
                                        1 &&
                                        !(
                                          config.company === "SRPL" ||
                                          config.company === "AVS" ||
                                          config.company === "PRPL"
                                        ) &&
                                        e.billing_currency !== "IDR" &&
                                        e.billing_currency !== "0" && (
                                          <div>
                                            Exchange Rate
                                            {parseFloat(
                                              e.sac_surveyor_addl_days_currency_xchg_rate
                                            ).toFixed(2)}
                                          </div>
                                        )}
                                    </td>
                                    <td className="text-right">
                                      {toLocaleString(e.sac_surveyor_addl_days)}
                                    </td>
                                    <td className="text-right">
                                      {toLocaleString(
                                        e.sac_surveyor_price_per_addl_day
                                      )}
                                    </td>
                                    <td className="text-right">
                                      {toLocaleString(
                                        e.sac_surveyor_addl_days_base_price
                                      )}
                                    </td>
                                  </tr>
                                )}
                                {/* {(this.state.vat_applicability === "Inclusive" ||
                                  config.company !== "SRPL" ||
                                  config.company !== "PRPL" ||
                                  config.company !== "AVS") &&
                                  e.sac_surveyor_addl_days_vat_value && (
                                    <tr>
                                      <td>VAT @ {toLocaleString(e.sac_surveyor_addl_days_vat_percent)} %</td>
                                      <td className="text-right"></td>
                                      <td className="text-right"></td>
                                      <td className="text-right">
                                        {toLocaleString(e.sac_surveyor_addl_days_vat_value)}
                                      </td>
                                    </tr>
                                  )}
                                {!(
                                  config.company === "SRPL" ||
                                  config.company === "AVS" ||
                                  config.company === "PRPL"
                                ) && e.sac_surveyor_addl_days_pph23_value && (
                                    <tr>
                                      <td>PPH23 @ {toLocaleString(e.sac_surveyor_addl_days_pph23_percent)} % </td>
                                      <td></td>
                                      <td></td>
                                      <td className="text-right">( {toLocaleString(e.sac_surveyor_addl_days_pph23_value)})</td>
                                    </tr>
                                  )} */}
                                <tr></tr>
                                {(this.state.vat_applicability ===
                                  "Inclusive" ||
                                  config.company !== "SRPL" ||
                                  config.company !== "PRPL" ||
                                  config.company !== "AVS") &&
                                  !!(
                                    sacvatValue ||
                                    otherVatValue ||
                                    e.sac_surveyor_addl_days_vat_value
                                  ) && (
                                    <tr>
                                      <td>VAT @ 10 %</td>
                                      <td className="text-right"></td>
                                      <td className="text-right"></td>
                                      <td className="text-right">
                                        {(
                                          Number(sacvatValue) +
                                          Number(otherVatValue) +
                                          Number(
                                            e.sac_surveyor_addl_days_vat_value
                                          )
                                        ).toFixed(2)}
                                      </td>
                                    </tr>
                                  )}
                                {!(
                                  config.company === "SRPL" ||
                                  config.company === "AVS" ||
                                  config.company === "PRPL"
                                ) &&
                                  !!(
                                    sacPPhValue ||
                                    otherpphValue ||
                                    e.sac_surveyor_addl_days_pph23_value
                                  ) && (
                                    <tr>
                                      <td>PPH23 @2% </td>
                                      <td></td>
                                      <td></td>
                                      <td className="text-right">
                                        (
                                        {(
                                          Number(sacPPhValue) +
                                          Number(otherpphValue) +
                                          Number(
                                            e.sac_surveyor_addl_days_pph23_value
                                          )
                                        ).toFixed(2)}
                                        )
                                      </td>
                                    </tr>
                                  )}
                                {/* <tr>
                                    <td colSpan={3} className="text-right ">
                                      {" "}
                                      Payable to Vendor :{" "}
                                    </td>
                                    <td className="text-right ">
                                      {`${config.company === "SRPL" ||
                                        config.company === "PRPL" ||
                                        config.company === "AVS"
                                        ? "USD"
                                        : "IDR"}
                                    ${parseFloat(total).toFixed(2)}`}
                                    </td>
                                  </tr> */}
                                {/* <tr>
                                      <td>
                                        Other Charges
                                        {otherExchgRate
                                          &&
                                          <div>
                                            Exchange Rate @ {parseFloat(otherExchgRate).toFixed(2)}
                                          </div>
                                        }
                                      </td>
                                      <td></td>
                                      <td></td>
                                      <td className="text-right">
                                        {toLocaleString(
                                          otherBasePrice
                                        )}
                                      </td>
                                    </tr> */}
                                {/* {otherVatValue && (
                                  <tr>
                                    <td>VAT @ 10 %</td>
                                    <td className="text-right"></td>
                                    <td className="text-right"></td>
                                    <td className="text-right">
                                      {toLocaleString(otherVatValue)}
                                    </td>
                                  </tr>
                                )}
                                {(config.company === "SDAM" ||
                                  config.company === "SRK" ||
                                  config.company === "BTR") && otherpphValue && (
                                    <tr>
                                      <td>PPH23 @ 2% </td>
                                      <td></td>
                                      <td></td>
                                      <td className="text-right">( {toLocaleString(otherpphValue)})</td>
                                    </tr>
                                  )} */}
                                <tr>
                                  {e.total_payable_vendor ? (
                                    <>
                                      <td colSpan={3} className="text-right">
                                        {" "}
                                        Payable to Vendor :{" "}
                                      </td>
                                      <td
                                        style={{ fontWeight: "bold" }}
                                        className="text-right "
                                      >
                                        {`${
                                          config.company === "SRPL" ||
                                          config.company === "PRPL" ||
                                          config.company === "AVS"
                                            ? "USD"
                                            : "IDR"
                                        } ${parseFloat(
                                          e.total_payable_vendor
                                        ).toFixed(2)}`}
                                      </td>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </tr>
                                <tr>
                                  <td colSpan={3} className="text-right">
                                    Paid to Vendor
                                  </td>
                                  <td
                                    style={{ fontWeight: "bold" }}
                                    className="text-right"
                                  >
                                    {" "}
                                    {config.company === "SRPL" ||
                                    config.company === "AVS" ||
                                    config.company === "PRPL"
                                      ? "USD"
                                      : "IDR"}{" "}
                                    {e.total_paid_to_vendor
                                      ? toLocaleString(e.total_paid_to_vendor)
                                      : 0}{" "}
                                  </td>
                                </tr>
                                <tr>
                                  {e.remaining_amount ? (
                                    <>
                                      <td colSpan={3} className="text-right">
                                        Remaining Amount
                                      </td>
                                      <td
                                        style={{ fontWeight: "bold" }}
                                        className="text-right"
                                      >
                                        {" "}
                                        {config.company === "SRPL" ||
                                        config.company === "AVS" ||
                                        config.company === "PRPL"
                                          ? "USD"
                                          : "IDR"}{" "}
                                        {toLocaleString(e.remaining_amount)}
                                      </td>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </>
                        {/* ))} */}
                      </div>

                      {/* <div className=""> */}
                      {/* {e.other_charges[0].sac_other_base_price !== null && */}
                      {/* <div className="row mb-3"> */}
                      {/* <div className="row col-lg-12 p-0">
                          <h5>Others</h5>
                        </div> */}
                      {/* <div className="row col-lg-12 p-0"> */}
                      {/* <div className="table-responsive"> */}
                      {/* <table className="table table-sm table-bordered mb-0"> */}
                      {/* <thead className="table-header">
                                <tr>
                                  <th className="text-center">Description</th>
                                  <th className="text-right">Qty</th>
                                  <th className="text-right">Unit Price</th>
                                  <th className="text-right">Amount</th>
                                </tr>
                              </thead> */}

                      {/* {!!e.other_charges && e.other_charges.map((p, index) => ( */}
                      {/* <tbody>
                                <>
                                  <tr>
                                    <td>
                                      Other Charges
                                      {otherExchgRate
                                        &&
                                        <div>
                                          Exchange Rate @ {parseFloat(otherExchgRate).toFixed(2)}
                                        </div>
                                      }
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td className="text-right">
                                      {toLocaleString(
                                        otherBasePrice
                                      )}
                                    </td>
                                  </tr>
                                  {otherVatValue && (
                                    <tr>
                                      <td>VAT @ 10 %</td>
                                      <td className="text-right"></td>
                                      <td className="text-right"></td>
                                      <td className="text-right">
                                        {toLocaleString(otherVatValue)}
                                      </td>
                                    </tr>
                                  )}
                                  {(config.company === "SDAM" ||
                                    config.company === "SRK" ||
                                    config.company === "BTR") && otherpphValue && (
                                      <tr>
                                        <td>PPH23 @ 2% </td>
                                        <td></td>
                                        <td></td>
                                        <td className="text-right">( {toLocaleString(otherpphValue)})</td>
                                      </tr>
                                    )}
                                  <tr>
                                    <td colSpan={3} className="text-right ">
                                      {" "}
                                      Payable to Vendor :{" "}
                                    </td>
                                    <td className="text-right ">
                                      {`${config.company === "SRPL" ||
                                        config.company === "PRPL" ||
                                        config.company === "AVS"
                                        ? "USD"
                                        : "IDR"
                                        } ${parseFloat(othertotal).toFixed(2)}`}
                                    </td>
                                  </tr>
                                </>
                              </tbody> */}
                      {/* ))} */}
                      {/* </table> */}
                      {/* </div> */}
                      {/* </div> */}
                      {/* </div> */}
                      {/* } */}
                      {/* </div> */}
                      {/* {!!e.sac_surveyor_addl_days &&
                        <>
                          <div className="row col-lg-12 p-0">
                            <h5>Additional Charges</h5>
                          </div>
                          <div className="table-responsive">
                            <table className="table table-sm table-bordered mb-0">
                              <thead className="table-header">
                                <tr>
                                  <th className="text-center">Description</th>
                                  <th className="text-right">Extra Days</th>
                                  <th className="text-right">Price per Day</th>
                                  <th className="text-right">Amount</th>
                                </tr>
                              </thead>
                              <tbody>
                                <>
                                  <tr>
                                    <td>
                                      Additional Days
                                      {e.sac_surveyor_addl_days_currency_xchg_rate > 1 && !(
                                        config.company === "SRPL" || config.company === "AVS" ||
                                        config.company === "PRPL"
                                      ) &&
                                        e.billing_currency !== "IDR" &&
                                        e.billing_currency !== "0" && (
                                          <div>
                                            Exchange Rate
                                            {parseFloat(e.sac_surveyor_addl_days_currency_xchg_rate).toFixed(2)}
                                          </div>
                                        )}
                                    </td>
                                    <td className="text-right">
                                      {toLocaleString(e.sac_surveyor_addl_days)}
                                    </td>
                                    <td className="text-right">
                                      {toLocaleString(e.sac_surveyor_price_per_addl_day)}
                                    </td>
                                    <td className="text-right">
                                      {toLocaleString(e.sac_surveyor_addl_days_base_price)}
                                    </td>

                                  </tr>
                                  {(this.state.vat_applicability === "Inclusive" ||
                                    config.company !== "SRPL" ||
                                    config.company !== "PRPL" ||
                                    config.company !== "AVS") &&
                                    e.sac_surveyor_addl_days_vat_value && (
                                      <tr>
                                        <td>VAT @ {toLocaleString(e.sac_surveyor_addl_days_vat_percent)} %</td>
                                        <td className="text-right"></td>
                                        <td className="text-right"></td>
                                        <td className="text-right">
                                          {toLocaleString(e.sac_surveyor_addl_days_vat_value)}
                                        </td>
                                      </tr>
                                    )}
                                  {!(
                                    config.company === "SRPL" ||
                                    config.company === "AVS" ||
                                    config.company === "PRPL"
                                  ) && e.sac_surveyor_addl_days_pph23_value && (
                                      <tr>
                                        <td>PPH23 @ {toLocaleString(e.sac_surveyor_addl_days_pph23_percent)} % </td>
                                        <td></td>
                                        <td></td>
                                        <td className="text-right">( {toLocaleString(e.sac_surveyor_addl_days_pph23_value)})</td>
                                      </tr>
                                    )}
                                  <tr>
                                    <td colSpan={3} className="text-right ">
                                      {" "}
                                      Payable to Vendor :{" "}
                                    </td>
                                    <td className="text-right ">
                                      {`${config.company === "SRPL" ||
                                        config.company === "PRPL" ||
                                        config.company === "AVS"
                                        ? "USD"
                                        : "IDR"
                                        }
                                        ${parseFloat(e.sac_surveyor_addl_days_total).toFixed(2)}`}
                                    </td>
                                  </tr>
                                </>
                              </tbody>
                            </table>
                          </div>
                        </>
                      } */}
                      <div className="row">
                        <div className="card col-lg-12 p-0">
                          <div className="card-body">
                            <InvoiceForm
                              open={this.state.invoiceDialog}
                              data={e}
                              costingID={e.id}
                              callbackFn={(data) => this.updateInvoice(data)}
                            />
                            {(this.state.features.includes("247") ||
                              this.state.features.includes("97")) && (
                              <div>
                                {this.renderPaymentPostings(e)}
                                {/* <div className="row">
                                  <div className="col-lg-12">
                                    <div className="clearfix">
                                      <div className="float-left">
                                        {!e.sac_charges_payments
                                          ? ""
                                          : e.sac_charges_payments.length <=
                                              0 && (
                                              <p style={{ fontWeight: "bold" }}>
                                                No payments made
                                              </p>
                                            )}
                                      </div>
                                      <div className="float-right">
                                        {(this.state.features.includes("246") >=
                                          0 ||
                                          this.state.features.includes("97") >=
                                            0) &&
                                          e.invoice_no && (
                                            <button
                                              className="header_button header_button_text  w-auto"
                                              onClick={() => {
                                                this.setState({
                                                  paymentTitle:
                                                    "Add Sampling & Anlaysis",
                                                  payment_posting_type:
                                                    "Pre_Shipment",
                                                  remainingAmount:
                                                    e.remaining_amount,
                                                  mv_sac_charges_id: e.id,
                                                  date_of_payment: null,
                                                  amount: "",
                                                  invoice_no: e.invoice_no,
                                                  description: "",
                                                  paymentErrorMsg: "",
                                                  date_of_paymentError: false,
                                                  amountError: false,
                                                  paymentMode: "Add",
                                                  invoice_noError: false,
                                                  paymentPostingsModal: true,
                                                });
                                              }}
                                            >
                                              Add Payment
                                            </button>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {!e.sac_charges_payments
                                  ? ""
                                  : e.sac_charges_payments.length > 0 && (
                                      <div className="row mt-2">
                                        <div className="col-lg p-0">
                                          <div className="table-responsive border-0">
                                            <table className="table table-borderless border-0">
                                              <thead className=" border-0">
                                                <tr>
                                                  <th
                                                    nowrap="true"
                                                    className="table_header_barge_label"
                                                    style={{ border: "none" }}
                                                  >
                                                    Date of Payment
                                                  </th>
                                                  <th
                                                    nowrap="true"
                                                    className="table_header_barge_label text-right"
                                                    style={{ border: "none" }}
                                                  >
                                                    Amount
                                                  </th>
                                                  <th
                                                    nowrap="true"
                                                    className="table_header_barge_label"
                                                    style={{ border: "none" }}
                                                  >
                                                    Invoice No
                                                  </th>
                                                  <th
                                                    nowrap="true"
                                                    className="table_header_barge_label"
                                                    style={{ border: "none" }}
                                                  >
                                                    Description
                                                  </th>
                                                  <th
                                                    nowrap="true"
                                                    className="table_header_barge_label"
                                                    style={{ border: "none" }}
                                                  >
                                                    Created Date
                                                  </th>
                                                  <th
                                                    nowrap="true"
                                                    className="table_header_barge_label"
                                                    style={{ border: "none" }}
                                                  >
                                                    1st Level Approval
                                                  </th>
                                                  <th
                                                    nowrap="true"
                                                    className="table_header_barge_label"
                                                    style={{ border: "none" }}
                                                  >
                                                    lst Level Approved on
                                                  </th>
                                                  <th
                                                    nowrap="true"
                                                    className="table_header_barge_label"
                                                    style={{ border: "none" }}
                                                  >
                                                    2nd Level Approval
                                                  </th>
                                                  <th
                                                    nowrap="true"
                                                    className="table_header_barge_label"
                                                    style={{ border: "none" }}
                                                  >
                                                    2nd Level Approved on
                                                  </th>
                                                  <th
                                                    nowrap="true"
                                                    className="table_header_barge_label text-center"
                                                    style={{ border: "none" }}
                                                  >
                                                    Action
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {e.sac_charges_payments.map(
                                                  (p, index) => (
                                                    <tr key={index}>
                                                      {/* <td nowrap='true' className='table_td border-0'>{p.date_of_payment ? localDateFormate(p.date_of_payment) : null}</td> *
                                                      <td
                                                        nowrap="true"
                                                        className="table_td"
                                                        style={{
                                                          minWidth: 170,
                                                        }}
                                                      >
                                                        {e.approval_status ===
                                                          "Approved" &&
                                                          p.approval_status ===
                                                            "Approved" &&
                                                          (p.approval_status_2 ===
                                                            "Optional" ||
                                                            p.approval_status_2 ===
                                                              "Approved") && (
                                                            <MuiPickersUtilsProvider
                                                              utils={
                                                                DateFnsUtils
                                                              }
                                                            >
                                                              <DatePicker
                                                                autoOk={true}
                                                                inputVariant="outlined"
                                                                variant="inline"
                                                                margin="dense"
                                                                fullWidth
                                                                format="dd/MM/yyyy"
                                                                value={
                                                                  p.date_of_payment
                                                                }
                                                                error={
                                                                  this.state
                                                                    .date_of_paymentError
                                                                }
                                                                onChange={(
                                                                  date
                                                                ) => {
                                                                  let row =
                                                                    this.state
                                                                      .multiRows;
                                                                  let rows =
                                                                    row[idx]
                                                                      .sac_charges_payments;
                                                                  rows[
                                                                    index
                                                                  ].date_of_payment =
                                                                    date;
                                                                  this.setState(
                                                                    {
                                                                      multiline:
                                                                        row,
                                                                      mv_sac_charges_id:
                                                                        e.id,
                                                                      sac_charges_payment_id:
                                                                        p.id,
                                                                      payment_posting_type:
                                                                        p.payment_posting_type,
                                                                      date_of_payment:
                                                                        date,
                                                                      amount:
                                                                        p.amount,
                                                                      invoice_no:
                                                                        e.invoice_no,
                                                                      description:
                                                                        p.description,
                                                                      date_of_paymentError: false,
                                                                    },
                                                                    () => {
                                                                      this.UpdatePaymentPostingsHandler();
                                                                    }
                                                                  );
                                                                }}
                                                                InputProps={{
                                                                  startAdornment:
                                                                    (
                                                                      <InputAdornment position="start">
                                                                        <IconButton>
                                                                          <EventIcon />
                                                                        </IconButton>
                                                                      </InputAdornment>
                                                                    ),
                                                                  className:
                                                                    "pl-0",
                                                                }}
                                                              />
                                                            </MuiPickersUtilsProvider>
                                                          )}
                                                      </td>
                                                      <td
                                                        nowrap="true"
                                                        className="table_td border-0 text-right"
                                                      >
                                                        {p.amount
                                                          ? toLocaleString(
                                                              p.amount
                                                            )
                                                          : ""}
                                                      </td>
                                                      <td
                                                        nowrap="true"
                                                        className="table_td border-0 text-uppercase"
                                                      >
                                                        {e.invoice_no}
                                                      </td>
                                                      <td
                                                        nowrap="true"
                                                        className="table_td border-0"
                                                      >
                                                        {p.description}
                                                      </td>
                                                      <td
                                                        nowrap="true"
                                                        className="table_td border-0"
                                                      >
                                                        {p.created_date
                                                          ? localDateFormate(
                                                              p.created_date
                                                            )
                                                          : null}
                                                      </td>
                                                      {e.approval_status ===
                                                        "Approved" &&
                                                      p.approval_status ===
                                                        "Pending" &&
                                                      this.Cookie.getCookie(
                                                        "loginUserId"
                                                      ) !== p.created_by &&
                                                      (this.state.features.includes(
                                                        "420"
                                                      )  ||
                                                        this.state.features.includes(
                                                          "97"
                                                        ) ) ? (
                                                        <td
                                                          nowrap="true"
                                                          className=" text-left"
                                                        >
                                                          <button
                                                            className="header_button header_button_text add_button_adjustment"
                                                            style={{
                                                              width: "auto",
                                                              marginTop: "17px",
                                                            }}
                                                            onClick={() =>
                                                              this.approvePaymentsHandler(
                                                                p.id
                                                              )
                                                            }
                                                          >
                                                            Approve
                                                          </button>
                                                        </td>
                                                      ) : (
                                                        <td
                                                          nowrap="true"
                                                          className=" text-left"
                                                        >
                                                          {p.approved_by_name
                                                            ? p.approved_by_name
                                                            : "-"}
                                                        </td>
                                                      )}
                                                      <td
                                                        nowrap="true"
                                                        className="table_td border-0"
                                                      >
                                                        {p.approved_date
                                                          ? localDateFormate(
                                                              p.approved_date
                                                            )
                                                          : "-"}
                                                      </td>
                                                      {e.approval_status ===
                                                        "Approved" &&
                                                      p.approval_status !==
                                                        "Pending" &&
                                                      p.approval_status_2 ===
                                                        "Pending" &&
                                                      this.Cookie.getCookie(
                                                        "loginUserId"
                                                      ) !== p.created_by &&
                                                      this.Cookie.getCookie(
                                                        "loginUserId"
                                                      ) !== p.approved_by &&
                                                      (this.state.features.includes(
                                                        "471"
                                                      )  ||
                                                        this.state.features.includes(
                                                          "97"
                                                        ) ) ? (
                                                        <td
                                                          nowrap="true"
                                                          className=" text-left"
                                                        >
                                                          <button
                                                            className="header_button header_button_text add_button_adjustment"
                                                            style={{
                                                              width: "auto",
                                                              marginTop: "17px",
                                                            }}
                                                            onClick={() =>
                                                              this.approvePaymentsHandler(
                                                                p.id
                                                              )
                                                            }
                                                          >
                                                            Approve
                                                          </button>
                                                        </td>
                                                      ) : (
                                                        <td
                                                          nowrap="true"
                                                          className=" text-left"
                                                        >
                                                          {p.approved_by_2_name
                                                            ? p.approved_by_2_name
                                                            : "-"}
                                                        </td>
                                                      )}
                                                      <td>
                                                        {p.approved_date_2
                                                          ? localDateFormate(
                                                              p.approved_date_2
                                                            )
                                                          : "-"}
                                                      </td>
                                                      <td
                                                        nowrap="true"
                                                        className="table_td border-0 text-center"
                                                      >
                                                        {(p.approval_status ===
                                                        "pending"
                                                          ? this.state.features.includes(
                                                              "284"
                                                            )  ||
                                                            this.state.features.includes(
                                                              "420"
                                                            )  ||
                                                            this.state.features.includes(
                                                              "97"
                                                            ) 
                                                          : p.approval_status_2 ===
                                                            "Pending"
                                                          ? this.Cookie.getCookie(
                                                              "loginUserId"
                                                            ) !==
                                                              p.approved_by ||
                                                            this.state.features.includes(
                                                              "471"
                                                            )  ||
                                                            this.state.features.includes(
                                                              "97"
                                                            ) 
                                                          : this.state.features.includes(
                                                              "97"
                                                            ) ) && (
                                                          <Tooltip
                                                            title="Edit"
                                                            arrow
                                                          >
                                                            <i
                                                              className="fa fa-pencil"
                                                              style={{
                                                                fontSize: 14,
                                                                color:
                                                                  config.themeColor,
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              data-toggle="modal"
                                                              title="Edit Coal Payment Posting"
                                                              data-placement="bottom"
                                                              onClick={() => {
                                                                this.setState({
                                                                  remainingAmount:
                                                                    e.remaining_amount,
                                                                });
                                                                this.getPaymentDetalis(
                                                                  p
                                                                );
                                                              }}
                                                            ></i>
                                                          </Tooltip>
                                                        )}
                                                        {(this.state.features.includes(
                                                          "249"
                                                        )  ||
                                                          this.state.features.includes(
                                                            "97"
                                                          ) ) &&
                                                          p.approval_status ===
                                                            "Pending" && (
                                                            <DeletePayments
                                                              data={p}
                                                              table={"mv_sac_charges_payment_postings"}
                                                              callbackFn={() =>
                                                                this.componentDidMount()
                                                              }
                                                            />
                                                          )}
                                                      </td>
                                                    </tr>
                                                  )
                                                )}
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    )} */}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {/* </div> */}

                      <ViewFiles
                        {...this.props}
                        files_data={e.files}
                        type="Costings"
                        invoice_file={e.invoice_file}
                      />

                      <AuditInfo info={e} />
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>

          <footer className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button btn-light"
              name="submit"
              onClick={() => {
                this.props.returnCallbackDrawerClose(false);
              }}
            >
              Close
            </button>
            <button
              type="button"
              className="drawer_text drawer_btn_success"
              name="Back"
              onClick={() => {
                this.props.returnEditCallback("MvSac");
              }}
            >
              Edit
            </button>
          </footer>
        </div>
      );
    }
  }
}
