export const costingTypes = [
  "Performa Coal Costing",
  "Commerical Coal Costing",
  "Chemical Spray",
  "Export Tax",
  "Documentation Charges",
  "Laporan Charges",
  "Sampling & Analysis Charges",
  "Pre Shipment Inspection",
  "Coal Procurement Charges",
  "Other Expenses",
  "Return Cargo Charges",
  "Floating Crance Charges",
  "Stevedore Costing",
  "JASA PNBP Charges",
  "MV Coal Procurement Charges",
  "MV Coal Sales Charges",
  "MV Sampling & Analysis Charges",
  "MV Pre Shipment Inspection",
  "MV Export Tax",
  "MV Documentation Charges",
  "MV Laporan Charges",
  "MV Dead Freight",
  "MV Freight Costing",
  "Others",
];
