import React, { Component } from "react";

import Header from "../common/Header";
import SideBar from "../common/SideBar";
import CookieHandler from "../common/CookieHandler";
import config from "../../config/config";
import api from "../../api/api";

import { Alert } from "@material-ui/lab";
import {
  Snackbar,
  Drawer,
  IconButton,
  InputAdornment,
  Tooltip,
  Tabs,
  Tab,
  Popper,
  Paper,
  TextField,
  MenuItem,
  Button,
  Checkbox,
  withStyles
} from "@material-ui/core";

import PaymentsModal from "../common/PaymentsModal";
import {
  dateFormateToDB,
  localDateFormate,
  toLocaleString,
  localTimeFormate
} from "../common/common";

import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import EventIcon from "@material-ui/icons/Event";
import DateFnsUtils from "@date-io/date-fns";
import exportToExcel from "../Export_To_Excel";

import Autocomplete from "@material-ui/lab/Autocomplete";

import BargeCoalCostingView from "../Barge-Purchase-Financials/CoalCosting/CoalCostingView";
import PerformaInvoiceCosting from '../Barge-Purchase-Financials/CoalCosting/PerformaInvoiceCosting';
import BargeCoalCostingUpdate from "../Barge-Purchase-Financials/CoalCosting/CoalCostingUpdate";

import BargeCostingView from "../Barge-Purchase-Financials/BargeCosting/BargeCostingView";
import BargeCostingUpdate from "../Barge-Purchase-Financials/BargeCosting/BargeCostingUpdate";

import ChemicalSprayView from "../Barge-Purchase-Financials/Chemical-Spray/Chemical-Spray-View";
import ChemicalSprayUpdate from "../Barge-Purchase-Financials/Chemical-Spray/Chemical-Spray-Update";

import BargeExportTaxView from "../Barge-Purchase-Financials/Export-Tax/Export-Tax-View";
import BargeExportTaxUpdate from "../Barge-Purchase-Financials/Export-Tax/Export-Tax-Update";

import DocumentationChargesView from "../Barge-Purchase-Financials/Documentation-Charges/Documentation-Charges-View";
import DocumentationChargesUpdate from "../Barge-Purchase-Financials/Documentation-Charges/Documentation-Charges-Update";

import LaporanChargesView from "../Barge-Purchase-Financials/LaporanCharges/LaporanChargesView";
import LaporanChargesUpdate from "../Barge-Purchase-Financials/LaporanCharges/LaporanChargesUpdate";

import ReturnCargoView from "../Barge-Purchase-Financials/Return-Cargo-Charges/ReturnCargoView";
import ReturnCargoUpdate from "../Barge-Purchase-Financials/Return-Cargo-Charges/ReturnCargoUpdate";

import BargeSacView from "../Barge-Purchase-Financials/Sampling&Analysis-Charges/Sac-View";
import BargeSacUpdate from "../Barge-Purchase-Financials/Sampling&Analysis-Charges/Sac-Update";

import CoalProcurementChargesView from "../Barge-Purchase-Financials/Coal-Procurement-Charges/Coal-Procurement-Charges-View";
import CoalProcurementChargesUpdate from "../Barge-Purchase-Financials/Coal-Procurement-Charges/Coal-Procurement-Charges-Update";
// Barge purchase Fin CPS
import BargeCpsView from "../Barge-Purchase-Financials/Coal-Procurement-Services/CPS-View";
import BargeCpsUpdate from "../Barge-Purchase-Financials/Coal-Procurement-Services/CPS-Update";

import BargePreShipmentView from "../Barge-Purchase-Financials/Pre-Shipmet-Inspection/Pre-Shipment-Inspection-View";
import PreShipmentInspectionUpdate from "../Barge-Purchase-Financials/Pre-Shipmet-Inspection/Pre-Shipment-Inspection-update";

import BargeOtherExpenseView from '../Barge-Purchase-Financials/Other-Expenses/Other-Expense-View';
import BargeOtherExpenseUpdate from '../Barge-Purchase-Financials/Other-Expenses/Other-Expense-Update';

// Barge Dmurrages
import BargeVendorDemurrageView from "../Barge-Purchase-Financials-Demurrages/Vendor-Demurrages/Vendor-Demurrage-View";
import BargeVendorDemurrageUpdate from "../Barge-Purchase-Financials-Demurrages/Vendor-Demurrages/Vendor-Demurrage-Update";

import BargeSupplierDemurrageView from "../Barge-Purchase-Financials-Demurrages/Supplier-Demurrages/Supplier-Demurrage-View";
import BargeSupplierDemurrageUpdate from "../Barge-Purchase-Financials-Demurrages/Supplier-Demurrages/Supplier-Demurrage-Update";

import BargeBuyerDemurrageView from "../Barge-Sales-Financial-Demurrages/Buyer-Demurrage-View";
import BuyerDemurrageUpdate from "../Barge-Sales-Financial-Demurrages/Buyer-Demurrage-Update";

import MvBuyerDemurrageView from "../BusinessNoMVDemurrage/Mv_Demurrage_Buyer/Mv_Buyer_DemurrageView";
import MvBuyerDemurrageUpdate from "../BusinessNoMVDemurrage/Mv_Demurrage_Buyer/Mv_Buyer_DemurrageUpdate";
import MvSupplierDemurrageView from "../BusinessNoMVDemurrage/Mv_Demurrage_Supplier/Mv_supplier_DemurrageView";
import MvSupplierDemurrageUpdate from "../BusinessNoMVDemurrage/Mv_Demurrage_Supplier/Mv_supplier_DemurrageUpdate";
// import mother vessel stevedore costing
import MvStevdoreCostingView from "../MvPurchaseFinancial/StevedoreCosting/StevedoreCostView";
import MVStevedorCostingUpdate from "../MvPurchaseFinancial/StevedoreCosting/StevedoreCostUpdate";
// import mother vessel floatig crane
import MvFloatingCraneView from "../MvPurchaseFinancial/FloatingCraneCharges/FloatingCraneChargesView";
import MvFloatingCraneUpdate from "../MvPurchaseFinancial/FloatingCraneCharges/FloatingCraneChargesUpdate";

// Barge sales Financials
import ViewSalesPI from "../BusinessNoBargeSalesFinancials/Sales-Performa-Coal-Costing/PI-View";
import UpdateSalesPI from "../BusinessNoBargeSalesFinancials/Sales-Performa-Coal-Costing/PI-Update";
import BargeSalesCommericalView from "../BusinessNoBargeSalesFinancials/Commercial-Sales-CoalCosting/BusinessNoSalesCoalCostingView";
import BargeSalesCommericalUpdate from "../BusinessNoBargeSalesFinancials/Commercial-Sales-CoalCosting/BusinessNoSalesCoalCostingUpdate";


// import mother vessel documentation charges
import MvDocChargesView from "../MvPurchaseFinancial/Mv-Documentation-Charges/Mv-Doc-Charges-View";
import MvDocChargesUpdate from "../MvPurchaseFinancial/Mv-Documentation-Charges/Mv-Doc-Charges-Update";

import MvLoparanChargesView from '../MvPurchaseFinancial/MvLaporanCharges/LaporanChargesView';
import MvLoparanChargesUpdate from '../MvPurchaseFinancial/MvLaporanCharges/LaporanChargesUpdate';
// Import Mother vessel sampling & analaysis charges
import MVSamplingChargesView from "../MvPurchaseFinancial/Mv-Pur-Fin-Sampling&Analysis_Charges/Mv-Sampling-Charges-View";
import MVSamplingChargesUpdate from "../MvPurchaseFinancial/Mv-Pur-Fin-Sampling&Analysis_Charges/Mv-Sampling-Charges-Update";

// import Mother vessel pre-shipment
import MvPreShipmentView from "../MvPurchaseFinancial/Mv-Pre-Shipment-Inspection/Mv-Pre-Shipment-Inspection-View";
import MVPreShipmentChargesUpdate from "../MvPurchaseFinancial/Mv-Pre-Shipment-Inspection/Mv-Pre-Shipment-Inspection-Update";

import PnbpChargesView from '../MvPurchaseFinancial/PNBP/PnbpChargesView';
import PnbpChargesUpdate from '../MvPurchaseFinancial/PNBP/PnbpChargesUpdate';
// import freight costing
// import FreightCostingView from '../MvPurchaseFinancial/Mv-Freight-Costing/Mv_Freight_Costing_View';

// import Mother vessel Export tax
import MVExportTaxView from "../MvPurchaseFinancial/Mv-Pur-Fin-Export-Tax/Export-Tax-View";
import MVExportTaxUpdate from "../MvPurchaseFinancial/Mv-Pur-Fin-Export-Tax/Export-Tax-Update";

// import mother vessel coal procurement charges
import MvProcurementChargesView from "../MvPurchaseFinancial/Coal_Procurement_Charges/MvProcurementChargesView";
import MvProcurementChargesUpdate from "../MvPurchaseFinancial/Coal_Procurement_Charges/MvProcurementChargesUpdate";

// import mother vessel coal sales charges
import MvCoalSalesChargesView from "../MvPurchaseFinancial/CoalSalesCharges/MvCoalSalesChargesView";
import MvCoalSalesChargesUpdate from "../MvPurchaseFinancial/CoalSalesCharges/MvCoalSalesChargesUpdate";
// import MVSamplingChargesView from '../MvPurchaseFinancial/CoalSalesCharges/MvCoalSalesChargesView';
import IncomingCreditNoteView from "../MvPurchaseFinancial/Credit-Notes/Incoming-Credit-Note/Incoming-Credit-Note-View";
import IncomingCreditNoteUpdate from "../MvPurchaseFinancial/Credit-Notes/Incoming-Credit-Note/Incoming-Credit-Note-Update";
import OutgoingCreditNoteView from "../MvPurchaseFinancial/Credit-Notes/Outgoing-Credit-Note/Outgoing-Credit-Note-View";
import OutgoingCreditNoteUpdate from "../MvPurchaseFinancial/Credit-Notes/Outgoing-Credit-Note/Outgoing-Credit-Note-Update";

import IncomingDebitNoteView from "../MvPurchaseFinancial/Debit-Notes/Incoming-Debit-Notes/Incoming-Debit-Note-View";
import IncomingDebitNoteUpdate from "../MvPurchaseFinancial/Debit-Notes/Incoming-Debit-Notes/Incoming-Debit-Note-Update";

import OutgoingDebitNoteView from "../MvPurchaseFinancial/Debit-Notes/Outgoing-Debit-Notes/Outgoing-Debit-Note-View";
import OutgoingDebitNoteUpdate from "../MvPurchaseFinancial/Debit-Notes/Outgoing-Debit-Notes/Outgoing-Debit-Note-Update";

import OtherExpenseView from "../MvPurchaseFinancial/Other-Expenses/Other-Expenses-View";
import OtherExpenseUpdate from "../MvPurchaseFinancial/Other-Expenses/Other-Expenses-Update";

import OtherIncomeView from "../MvPurchaseFinancial/Other-Incomes/Other-Incomes-View";
import OtherIncomeUpdate from "../MvPurchaseFinancial/Other-Incomes/Other-Incomes-Update";

import MvDeadFreightView from "../MvPurchaseFinancial/DeadFreight/MvDeadFreightView";
import MvDeadFreightUpdate from "../MvPurchaseFinancial/DeadFreight/MvDeadFreightUpdate";

import ViewService from "../serviceRequests/serviceRequestView";
import UpdateService from "../serviceRequests/serviceRequestUpdate";

import { PAYMENTS_INFO } from "../constants/constants";

import Loader from "../common/Loader";
// import FinancialTable from './FinancialTable';
import AccountReceivables from "./AccountReceivables";

const CheckBox = withStyles({
  root: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default class FinancialApprovals extends Component {
  constructor() {
    super();
    this.Cookie = new CookieHandler();
    this.state = {
      costing_data: [],
      payments_data: [],
      features: [],
      isLoading: true,
      mainTabIndex: 0,
      activeTabIndex: 0,
      updateDocuments: false,
      paymentsModal: false,
      costingDataCopy: null,
      approved_payments: [],
      openQuantityAllocation: false,
      anchorEl: null,
      costing_type_filter_values: [],
      filter_costing_type_value: 'All',
      costing_data_backup: [],
      vendorFilter: false,
      vendors: [],
      selected_vendors: [],
      selected_costing_types: [],
      payment_due_date_filter: false,
      payment_due_date_value: null,
      date_of_payment_filter: false,
      date_of_payment_value: null,
      GlobalSearchValue: '',
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    else {
      let features = this.Cookie.getCookie("features");
      this.setState({ features: features });
      if (!(features.includes("97") || features.includes("554"))) {
        window.location.href = "/";
      }

      const loginUserID = this.Cookie.getCookie("loginUserId");
      const idToken = this.Cookie.getIdTokenCookie();

      await api.getVendors(loginUserID, idToken, "Active").then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === '200') {
              // console.log(res);
              if (res.vendors) {
                this.setState({
                  vendors: res.vendors
                })
              }
            }
          })
        }
      })
      let reqBody = {
        login_user_id: loginUserID,
        idtoken: idToken,
      };


      api.get_pending_payment_approvals(reqBody).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.costing_data) {
                // Display records only from November
                const startDate = new Date('2021-11-01');
                const filter_from_nov_records = res.costing_data.filter(e => {
                  let created_date = new Date(e.costing_created_date);
                  return (created_date >= startDate);
                }).sort((a, b) => new Date(b.costing_approved_date) - new Date(a.costing_approved_date));

                // const data = JSON.parse(JSON.stringify(filter_from_nov_records));
                // const pending_outstanding_costings = data
                //   .map((element) => {
                //     element.payments = element.payments.filter((subElement) => {
                //       return subElement.payment_approval_status !== "Approved";
                //       // if (subElement.flag === "Optional") {
                //       //   return subElement.payment_approval_status !== "Approved";
                //       // } else {
                //       //   return subElement.payment_approval_status_2 !== "Approved";
                //       // }
                //     });
                //     return element;
                //   });

                this.setState(
                  {
                    // payments_data: res.costing_data,
                    payments_data: filter_from_nov_records,
                    isLoading: false,
                  },
                  () => {
                    if (this.state.activeTabIndex === 0) {
                      this.pendingFinancialApprovals()
                    }
                    else if (this.state.activeTabIndex === 1) {
                      this.pendingPaymentApprovals();
                    } else if (this.state.activeTabIndex === 2) {
                      this.approvedListHandler();
                    } else {
                      this.completedPayments();
                    }
                  }
                );
              }
            }
          });
        }
      });
    }
  }

  pendingFinancialApprovals = () => {
    const costingsCopy = JSON.parse(JSON.stringify(this.state.payments_data));

    const filter_pending_financial_approvals = costingsCopy.filter(e => e.approval_status_2 !== "Approved" || e.payments.length === 0 || parseFloat(e.outstanding_amount) > 1);
    this.setState({
      costing_data: filter_pending_financial_approvals,
      costing_data_backup: filter_pending_financial_approvals,
    }, () => this.GlobalSearch());

  }

  pendingPaymentApprovals = () => {
    const costingsCopy = JSON.parse(JSON.stringify(this.state.payments_data));
    // const filter_eligibile_approvals = costingsCopy.filter(e => e.outstanding_amount >= 10);

    const pending_outstanding_costings = [];

    for (var i of costingsCopy) {
      if (i.payments.length > 0) {

        for (var j of i.payments) {
          if (j.payment_approval_status !== "Approved") {
            pending_outstanding_costings.push(i);
          }
        }
      } else {
        //pending_outstanding_costings.push(i);

      }
    }

    // const pending_outstanding_costings = costingsCopy
    //   .map((element) => {
    //     element.payments = element.payments.filter((subElement) => {
    //       return subElement.payment_approval_status !== "Approved";
    //       // if (subElement.flag === "Optional") {
    //       //   return subElement.payment_approval_status !== "Approved";
    //       // } else {
    //       //   return subElement.payment_approval_status_2 !== "Approved";
    //       // }
    //     });
    //     return element;
    //   });

    this.setState(
      {
        costing_data: pending_outstanding_costings.filter((el, i, a) => el.approval_status_2 === "Approved" && a.findIndex((element) => element.costing_no === el.costing_no) === i),
        costing_data_backup: pending_outstanding_costings.filter((el, i, a) => el.approval_status_2 === "Approved" && a.findIndex((element) => element.costing_no === el.costing_no) === i),
      }, () => this.GlobalSearch()
    );
  };

  approvedListHandler = () => {
    const data = JSON.parse(JSON.stringify(this.state.payments_data));

    const approved_payments_not_paid_costings = data
      .filter((e) => e.payments.length > 0)
      .map((element) => {
        element.payments = element.payments.filter((subElement) => {
          return (subElement.payment_approval_status === "Approved" &&
            subElement.date_of_payment === null)
          // if (subElement.flag === "Optional") {
          //   return (
          //     subElement.payment_approval_status === "Approved" &&
          //     subElement.date_of_payment === null
          //   );
          // } else {
          //   return (
          //     subElement.payment_approval_status_2 === "Approved" &&
          //     subElement.date_of_payment === null
          //   );
          // }
        });
        return element;
      });

    const approved_payments_not_paid = approved_payments_not_paid_costings.filter(e => e.payments.length > 0);

    this.setState(
      {
        costing_data: approved_payments_not_paid,
        costing_data_backup: approved_payments_not_paid,
      }, () => this.GlobalSearch()
    );
  };

  completedPayments = () => {
    const data = JSON.parse(JSON.stringify(this.state.payments_data));

    const approved_payment_costings = data
      .filter((e) => e.payments.length > 0)
      .map((element) => {
        element.payments = element.payments.filter((subElement) => {
          return (
            subElement.payment_approval_status === "Approved" &&
            subElement.date_of_payment
          );
          // if (subElement.flag === "Optional") {
          //   return (
          //     subElement.payment_approval_status === "Approved" &&
          //     subElement.date_of_payment
          //   );
          // } else {
          //   return (
          //     subElement.payment_approval_status_2 === "Approved" &&
          //     subElement.date_of_payment
          //   );
          // }
        });
        return element;
      });

    const approved_payments = approved_payment_costings.filter(e => e.payments.length > 0)

    this.setState({
      costing_data: approved_payments,
      costing_data_backup: approved_payments,
    }, () => this.GlobalSearch());
  };

  onCancel = (value) => {
    this.setState(
      {
        CoalCostingUpdate: false,
        CoalCostingView: false,
        BargeCostingView: false,
        BargeCostingUpdate: false,
        ChemicalSprayView: false,
        ChemicalSprayUpdate: false,
        cpcView: false,
        cpcUpdate: false,
        cpsView: false,
        cpsUpdate: false,
        docView: false,
        docUpdate: false,
        laprnView: false,
        laprnUpdate: false,
        cargoView: false,
        cargoUpdate: false,
        BgOtherExpensUpdate: false,
        BgOtherExpensView: false,
        sacView: false,
        sacUpdate: false,
        showDrawer: false,
        preShipmentView: false,
        preShipmentUpdate: false,
        exportTaxView: false,
        exportTaxUpdate: false,
        serviceView: false,
        serviceUpdate: false,
        barge_dmr_to_vend: false,
        barge_dmr_to_vend_update: false,
        barge_dmr_supplier: false,
        barge_dmr_supplier_update: false,
        buyerDemurrageView: false,
        buyerDemurrageUpdate: false,

        MvFloatingCraneView: false,
        MvFloatingCraneUpdate: false,
        MvStevdoreCostingView: false,
        MvStevdoreCostingUpdate: false,
        MvCpcView: false,
        MvCpcUpdate: false,
        MvDocView: false,
        MvDocUpdate: false,
        MvCscView: false,
        MvCscUpdate: false,
        MvlaprnView: false,
        MvlaprnUpdate: false,
        MvPreShipmentView: false,
        MvPreShipmentUpdate: false,
        MVExportTaxView: false,
        MvExportTaxUpdate: false,
        MvSacView: false,
        MvSacUpdate: false,
        MvDeadFreightView: false,
        MvDeadFreightUpdate: false,
        MvBuyerDemView: false,
        MvBuyerDemUpdate: false,
        MvSupplierDemUpdate: false,
        MvSupplierDemView: false,
        pnbpView: false,
        pnbpUpdate: false,

        IncomingCreditNoteView: false,
        IncomingCreditNoteUpdate: false,
        OutgoingCreditNoteView: false,
        OutgoingCreditNoteUpdate: false,
        IncomingDebitNoteUpdate: false,
        IncomingDebitNoteView: false,
        OutgoingDebitNoteView: false,
        OutgoingDebitNoteUpdate: false,
        OtherExpenseView: false,
        OtherExpenseUpdate: false,
        OtherIncomeView: false,
        OtherIncomeUpdate: false,
      },
      () => {
        this.componentDidMount()
      }
    );
  };

  addDocuments = () => {
    this.setState({
      showDrawer: true,
      updateDocuments: true,
      BargeCostingView: false,
      BgOtherExpensView: false,
      BgOtherExpensUpdate: this.state.selected_costing === "Barge Purchase Other Expenses" ? true : false,
      BargeCostingUpdate:
        this.state.selected_costing === "Barge Costing" ? true : false,
      ChemicalSprayView: false,
      ChemicalSprayUpdate:
        this.state.selected_costing === "Chemical Spray" ? true : false,
      CoalCostingView: false,
      CoalCostingUpdate:
        this.state.selected_costing ===
          "Barge Purchase performa Coal Costing" ||
          this.state.selected_costing === "Barge Purchase Commercial Coal Costing"
          ? true
          : false,
      exportTaxView: false,
      exportTaxUpdate:
        this.state.selected_costing === "Export Tax" ? true : false,
      docView: false,
      serviceView: false,
      serviceUpdate: this.state.selected_costing === "Services Payments" ? true : false,
      docUpdate:
        this.state.selected_costing === "Docuementation Charges" ? true : false,
      laprnUpdate: this.state.selected_costing === "Laparon Survey Charges" ? true : false,
      laprnView: false,
      cargoView: false,
      cargoUpdate:
        this.state.selected_costing === "Return Cargo Charges" ? true : false,
      sacView: false,
      sacUpdate:
        this.state.selected_costing === "Sampling & Analaysis Charges"
          ? true
          : false,
      cpcView: false,
      cpcUpdate:
        this.state.selected_costing === "Coal Procurement Charges"
          ? true
          : false,
      cpsView: false,
      cpsUpdate:
        this.state.selected_costing === "Barge Coal Procurement Service"
          ? true
          : false,
      preShipmentView: false,
      preShipmentUpdate:
        this.state.selected_costing === "Pre Shipment Data" ? true : false,

      barge_dmr_to_vend: false,
      barge_dmr_to_vend_update:
        this.state.selected_costing === "Barge Demurrage to vendor"
          ? true
          : false,
      buyerDemurrageView: false,
      buyerDemurrageUpdate:
        this.state.selected_costing === "Barge Demurrage To Buyer" ||
          this.state.selected_costing === "Barge Demurrage From buyer"
          ? true
          : false,
      barge_dmr_supplier: false,
      barge_dmr_supplier_update:
        this.state.selected_costing === "Barge Demurrage to Supplier" ||
          this.state.selected_costing === "Barge Demurrage From Supplier"
          ? true
          : false,

      // Mother Veesel Financials
      MvFloatingCraneView: false,
      MvFloatingCraneUpdate:
        this.state.selected_costing === "MV Floating Crane" ? true : false,

      MvStevdoreCostingView: false,
      MvStevdoreCostingUpdate:
        this.state.selected_costing === "MV Stevedore Costing" ? true : false,

      MvCpcView: false,
      MvCpcUpdate:
        this.state.selected_costing === "MV Coal Procurement Charges"
          ? true
          : false,
      MvDocView: false,
      MvCscView: false,
      MvDocUpdate:
        this.state.selected_costing === "MV Docuementation Charges"
          ? true
          : false,
      MvCscUpdate:
        this.state.selected_costing === "MV Coal Sales Charges"
          ? true
          : false,
      MvlaprnView: false,
      MvlaprnUpdate: this.state.selected_costing === "MV Laparon Survey Charges" ? true : false,
      MvPreShipmentUpdate:
        this.state.selected_costing === "MV Pre Shipment" ? true : false,
      MvPreShipmentView: false,
      MvExportTaxUpdate:
        this.state.selected_costing === "MV Export Tax" ? true : false,
      MVExportTaxView: false,
      pnbpView: false,
      pnbpUpdate: this.state.selected_costing === "MV PNBP JASA PBM" ? true : false,
      MvSacView: false,
      MvSacUpdate:
        this.state.selected_costing === "MV Sampling & Analaysis Charges"
          ? true
          : false,
      MvDeadFreightView: false,
      MvDeadFreightUpdate: this.state.selected_costing === "MV Dead Freight"
        ? true
        : false,
      MvBuyerDemView: false,
      MvBuyerDemUpdate:
        this.state.selected_costing === "MV Buyer Demurrage" ? true : false,
      MvSupplierDemView: false,
      MvSupplierDemUpdate:
        this.state.selected_costing === "MV Supplier Demurrage" ? true : false,

      IncomingCreditNoteView: false,
      IncomingCreditNoteUpdate:
        this.state.selected_costing === "MV Incoming Credit Note"
          ? true
          : false,
      OutgoingCreditNoteView: false,
      OutgoingCreditNoteUpdate:
        this.state.selected_costing === "MV Outgoing Credit Note"
          ? true
          : false,
      IncomingDebitNoteView: false,
      IncomingDebitNoteUpdate:
        this.state.selected_costing === "MV Incoming Debit Note" ? true : false,
      OutgoingDebitNoteView: false,
      OutgoingDebitNoteUpdate:
        this.state.selected_costing === "MV Outgoing Debit Note" ? true : false,
      OtherExpenseView: false,
      OtherExpenseUpdate:
        this.state.selected_costing === "MV Other Expenses" ? true : false,
      OtherIncomeView: false,
      OtherIncomeUpdate:
        this.state.selected_costing === "MV Other Income" ? true : false,
    });
  };

  onClickCosting = (row) => {
    this.setState({ selected_costing: row.type });
    if (
      this.state.features.includes(row.view_costing_access_id) ||
      this.state.features.includes("97")
    ) {
      // if (row.type === "Vendor Advance Payments") {
      //   window.location.href = '/vendor-advance-view-contract/' + btoa(row.vendor_adv_contract_id);
      // }
      // if (row.type === "Purchase Contract Advance Payments") {
      //   window.location.href = '/view-purchase-contract/' + btoa(row.purchase_contract_id);
      // }
      if (row.type === "Barge Costing") {
        this.setState({
          showDrawer: true,
          BargeCostingView: true,
          comboID: row.id,
        });
      } else if (row.type === "Chemical Spray") {
        this.setState({
          showDrawer: true,
          ChemicalSprayView: true,
          comboID: row.id,
        });
      } else if (row.type === "Coal Procurement Charges") {
        this.setState({
          showDrawer: true,
          cpcView: true,
          comboID: row.id,
        });
      } else if (row.type === "Barge Purchase Other Expenses") {
        this.setState({
          showDrawer: true,
          BgOtherExpensUpdate: false,
          BgOtherExpensView: true,
          comboID: row.id,
        })
      }
      else if (row.type === "Barge Coal Procurement Service") {
        this.setState({
          showDrawer: true,
          cpsView: true,
          comboID: row.id,
          purchase_contract_id: row.purchase_contract_id,
        });
      } else if (row.type === "Pre Shipment Data") {
        this.setState({
          showDrawer: true,
          preShipmentView: true,
          comboID: row.id,
          // comboID:
          //   row.barge_ids.includes("0")
          //     ? row.id + "$#" + row.id
          //     : row.barge_group_id + "$#" + row.id,
          // pre_shipment_type:
          //   row.barge_ids.includes("0") ? "Without Barge" : "With Barge",
        });
      } else if (row.type === "Barge Purchase performa Coal Costing") {
        this.setState({
          showDrawer: true,
          CoalCostingView: true,
          comboID: row.id + "$#" + row.purchase_contract_id,
          barge_costing_type: "Performa",
        });
      } else if (row.type === "Barge Purchase Commercial Coal Costing") {
        this.setState({
          showDrawer: true,
          CoalCostingView: true,
          comboID: row.id + "$#" + row.purchase_contract_id,
          barge_costing_type: "Commercial",
        });
      } else if (row.type === "Export Tax") {
        this.setState({
          showDrawer: true,
          exportTaxView: true,
          comboID: row.id,
        });
      } else if (row.type === "Sampling & Analaysis Charges") {
        this.setState({
          showDrawer: true,
          sacView: true,
          // comboID: row.barge_group_id + "$#" + row.id,
          comboID: 0 + "$#" + row.id,
        });
      } else if (row.type === "Docuementation Charges") {
        this.setState({
          showDrawer: true,
          docView: true,
          comboID: row.id,
        });
      } else if (row.type === "Laparon Survey Charges") {
        this.setState({
          showDrawer: true,
          laprnView: true,
          comboID: row.id,
        })
      }
      else if (row.type === "Return Cargo Charges") {
        this.setState({
          showDrawer: true,
          cargoView: true,
          returnCargoID: row.id,
        });
      }
      else if (row.type === "Services Payments") {
        this.setState({
          showDrawer: true,
          serviceView: true,
          comboID: row.id
        });
      }

      // Barge Demurrrages
      if (row.type === "Barge Demurrage to vendor") {
        this.setState({
          showDrawer: true,
          barge_dmr_to_vend: true,
          comboID: row.id,
        });
      }
      if (row.type === "Barge Demurrage to Supplier") {
        this.setState({
          showDrawer: true,
          barge_dmr_supplier: true,
          dmr_type: "company_to_supplier",
          comboID: row.id,
        });
      } else if (row.type === "Barge Demurrage From Supplier") {
        this.setState({
          showDrawer: true,
          barge_dmr_supplier: true,
          dmr_type: "supplier_to_company",
          comboID: row.id,
        });
      } else if (row.type === "Barge Demurrage From buyer") {
        this.setState({
          showDrawer: true,
          buyerDemurrageView: true,
          business_no: row.business_no_id,
          dmr_type: "buyer_to_company",
          comboID: row.id + "$#" + row.barge_ids.toString(),
        });
      } else if (row.type === "Barge Demurrage To Buyer") {
        this.setState({
          showDrawer: true,
          buyerDemurrageView: true,
          business_no: row.business_no_id,
          dmr_type: "company_to_buyer",
          comboID: row.id + "$#" + row.barge_ids.toString(),
        });
      }
      // Sales Financials
      if (
        row.type ===
        "MV Sales Performa Coal Costing"
      ) {
        this.setState({
          showDrawer: true,
          salesPerformaView: true,
          comboID: row.id,
        });
      } else if (
        row.type ===
        "Barge Sales Commercial Coal Costing"
      ) {
        this.setState({
          showDrawer: true,
          salesCommercialView: true,
          comboID:
            row.id +
            "$#" +
            row.business_no_id +
            "$#" +
            row.business_allocation_id +
            "$#" +
            row.business_no_barge_id,
        });
      }

      if (row.type === "MV Floating Crane") {
        this.setState({
          showDrawer: true,
          MvFloatingCraneView: true,
          comboID: row.id,
          business_no: row.business_no_id,
        });
      } else if (row.type === "MV Stevedore Costing") {
        this.setState({
          showDrawer: true,
          MvStevdoreCostingView: true,
          comboID: row.id,
          business_no: row.business_no_id,
        });
      } else if (row.type === "MV Coal Procurement Charges") {
        this.setState({
          showDrawer: true,
          MvCpcView: true,
          comboID: row.id,
          business_no: row.business_no_id,
        });
      } else if (row.type === "MV Sampling & Analaysis Charges") {
        this.setState({
          showDrawer: true,
          MvSacView: true,
          comboID: row.id,
          business_no: row.business_no_id,
        });
      }
      else if (row.type === "MV Dead Freight") {
        this.setState({
          showDrawer: true,
          MvDeadFreightView: true,
          business_no: row.business_no_id
        })
      }
      else if (row.type === "MV Pre Shipment") {
        this.setState({
          showDrawer: true,
          MvPreShipmentView: true,
          comboID: row.id,
          business_no: row.business_no_id,
        });
      } else if (row.type === "MV Docuementation Charges") {
        this.setState({
          showDrawer: true,
          MvDocView: true,
          comboID: row.id,
          business_no: row.business_no_id,
        });
      } else if (
        row.type === "MV Coal Sales Charges"
      ) {
        this.setState({
          showDrawer: true,
          MvCscView: true,
          comboID: row.id,
          business_no: row.business_no_id,
        });
      } else if (row.type === "MV Laparon Survey Charges") {
        this.setState({
          showDrawer: true,
          MvlaprnView: true,
          comboID: row.id,
          business_no: row.business_no_id
        })

      } else if (row.type === "MV Export Tax") {
        this.setState({
          showDrawer: true,
          MVExportTaxView: true,
          comboID: row.id,
          business_no: row.business_no_id,
        });
      }
      if (row.type === "MV Buyer Demurrage") {
        this.setState({
          showDrawer: true,
          MvBuyerDemView: true,
          comboID: row.id,
          business_no: row.business_no_id,
        });
      }
      if (row.type === "MV Supplier Demurrage") {
        this.setState({
          showDrawer: true,
          MvSupplierDemView: true,
          comboID: row.id,
          business_no: row.business_no_id,
        });
      }
      if (row.type === 'MV PNBP JASA PBM') {
        this.setState({
          showDrawer: true,
          pnbpView: true,
          comboID: row.id,
          business_no: row.business_no_id
        })
      }
      if (row.type === "MV Incoming Credit Note") {
        this.setState({
          showDrawer: true,
          IncomingCreditNoteView: true,
          comboID: row.id,
          business_no: row.business_no_id,
        });
      }
      if (row.type === "MV Outgoing Credit Note") {
        this.setState({
          showDrawer: true,
          OutgoingCreditNoteView: true,
          comboID: row.id,
          business_no: row.business_no_id,
        });
      }
      if (row.type === "MV Incoming Debit Note") {
        this.setState({
          showDrawer: true,
          IncomingDebitNoteView: true,
          comboID: row.id,
          business_no: row.business_no_id,
        });
      }
      if (row.type === "MV Outgoing Debit Note") {
        this.setState({
          showDrawer: true,
          OutgoingDebitNoteView: true,
          comboID: row.id,
          business_no: row.business_no_id,
        });
      }
      if (row.type === "MV Other Expenses") {
        this.setState({
          showDrawer: true,
          OtherExpenseView: true,
          comboID: row.id,
          business_no: row.business_no_id,
        });
      }
      if (row.type === "MV Other Income") {
        this.setState({
          showDrawer: true,
          OtherIncomeView: true,
          comboID: row.id,
          business_no: row.business_no_id,
        });
      }
    }
  };

  onEdit = (value) => {
    this.setState({
      showDrawer: true,
      BargeCostingView: false,
      BargeCostingUpdate:
        this.state.selected_costing === "Barge Costing" ? true : false,
      ChemicalSprayView: false,
      ChemicalSprayUpdate:
        this.state.selected_costing === "Chemical Spray" ? true : false,
      CoalCostingView: false,
      CoalCostingUpdate:
        this.state.selected_costing ===
          "Barge Purchase performa Coal Costing" ||
          this.state.selected_costing === "Barge Purchase Commercial Coal Costing"
          ? true
          : false,
      exportTaxView: false,
      BgOtherExpensUpdate: this.state.selected_costing === "Barge Purchase Other Expenses" ? true : false,
      BgOtherExpensView: false,
      exportTaxUpdate:
        this.state.selected_costing === "Export Tax" ? true : false,
      docView: false,
      docUpdate:
        this.state.selected_costing === "Docuementation Charges" ? true : false,
      laprnUpdate: this.state.selected_costing === "Laparon Survey Charges" ? true : false,
      laprnView: false,
      cargoView: false,
      cargoUpdate:
        this.state.selected_costing === "Return Cargo Charges" ? true : false,
      sacView: false,
      sacUpdate:
        this.state.selected_costing === "Sampling & Analaysis Charges"
          ? true
          : false,
      cpcView: false,
      cpcUpdate:
        this.state.selected_costing === "Coal Procurement Charges"
          ? true
          : false,
      cpsView: false,
      cpsUpdate:
        this.state.selected_costing === "Barge Coal Procurement Service"
          ? true
          : false,
      preShipmentView: false,
      preShipmentUpdate:
        this.state.selected_costing === "Pre Shipment Data" ? true : false,
      serviceView: false,
      serviceUpdate: this.state.selected_costing === "Services Payments" ? true : false,
      barge_dmr_to_vend: false,
      barge_dmr_to_vend_update:
        this.state.selected_costing === "Barge Demurrage to vendor"
          ? true
          : false,
      buyerDemurrageView: false,
      buyerDemurrageUpdate:
        this.state.selected_costing === "Barge Demurrage To Buyer" ||
          this.state.selected_costing === "Barge Demurrage From buyer"
          ? true
          : false,
      barge_dmr_supplier: false,
      barge_dmr_supplier_update:
        this.state.selected_costing === "Barge Demurrage to Supplier" ||
          this.state.selected_costing === "Barge Demurrage From Supplier"
          ? true
          : false,

      // Mother Veesel Financials
      MvFloatingCraneView: false,
      MvFloatingCraneUpdate:
        this.state.selected_costing === "MV Floating Crane" ? true : false,

      MvStevdoreCostingView: false,
      MvStevdoreCostingUpdate:
        this.state.selected_costing === "MV Stevedore Costing" ? true : false,

      MvCpcView: false,
      MvCpcUpdate:
        this.state.selected_costing === "MV Coal Procurement Charges"
          ? true
          : false,
      MvDocView: false,
      MvCscView: false,
      MvCscUpdate: this.state.selected_costing === "MV Coal Sales Charges"
        ? true
        : false,
      MvDocUpdate:
        this.state.selected_costing === "MV Docuementation Charges"
          ? true
          : false,
      MvlaprnView: false,
      MvlaprnUpdate: this.state.selected_costing === 'MV Laparon Survey Charges' ? true : false,
      MvDeadFreightView: false,
      MvDeadFreightUpdate: this.state.selected_costing === "MV Dead Freight"
        ? true
        : false,

      MvPreShipmentUpdate:
        this.state.selected_costing === "MV Pre Shipment" ? true : false,
      MvPreShipmentView: false,
      MvExportTaxUpdate:
        this.state.selected_costing === "MV Export Tax" ? true : false,
      MVExportTaxView: false,
      pnbpView: false,
      pnbpUpdate: this.state.selected_costing === "MV PNBP JASA PBM" ? true : false,
      MvSacView: false,
      MvSacUpdate:
        this.state.selected_costing === "MV Sampling & Analaysis Charges"
          ? true
          : false,

      MvBuyerDemView: false,
      MvBuyerDemUpdate:
        this.state.selected_costing === "MV Buyer Demurrage" ? true : false,
      MvSupplierDemView: false,
      MvSupplierDemUpdate:
        this.state.selected_costing === "MV Supplier Demurrage" ? true : false,

      IncomingCreditNoteView: false,
      IncomingCreditNoteUpdate:
        this.state.selected_costing === "MV Incoming Credit Note"
          ? true
          : false,
      OutgoingCreditNoteView: false,
      OutgoingCreditNoteUpdate:
        this.state.selected_costing === "MV Outgoing Credit Note"
          ? true
          : false,
      IncomingDebitNoteView: false,
      IncomingDebitNoteUpdate:
        this.state.selected_costing === "MV Incoming Debit Note" ? true : false,
      OutgoingDebitNoteView: false,
      OutgoingDebitNoteUpdate:
        this.state.selected_costing === "MV Outgoing Debit Note" ? true : false,
      OtherExpenseView: false,
      OtherExpenseUpdate:
        this.state.selected_costing === "MV Other Expenses" ? true : false,
      OtherIncomeView: false,
      OtherIncomeUpdate:
        this.state.selected_costing === "MV Other Income" ? true : false,
    });
  };

  onUpdateResponse = (value) => {
    if (value) {
      this.setState({
        showDrawer: true,
        BargeCostingUpdate: false,
        BargeCostingView:
          this.state.selected_costing === "Barge Costing" ? true : false,
        ChemicalSprayUpdate: false,
        ChemicalSprayView:
          this.state.selected_costing === "Chemical Spray" ? true : false,
        CoalCostingUpdate: false,
        CoalCostingView:
          this.state.selected_costing ===
            "Barge Purchase performa Coal Costing" ||
            this.state.selected_costing ===
            "Barge Purchase Commercial Coal Costing"
            ? true
            : false,
        BgOtherExpensUpdate: false,
        BgOtherExpensView: this.state.selected_costing === 'Barge Purchase Other Expenses' ? true : false,
        exportTaxUpdate: false,
        exportTaxView:
          this.state.selected_costing === "Export Tax" ? true : false,
        docUpdate: false,
        laprnUpdate: false,
        laprnView: this.state.selected_costing === "Laparon Survey Charges" ? true : false,
        docView:
          this.state.selected_costing === "Docuementation Charges"
            ? true
            : false,
        cargoUpdate: false,
        cargoView:
          this.state.selected_costing === "Return Cargo Charges" ? true : false,
        sacUpdate: false,
        sacView:
          this.state.selected_costing === "Sampling & Analaysis Charges"
            ? true
            : false,
        cpcUpdate: false,
        cpcView:
          this.state.selected_costing === "Coal Procurement Charges"
            ? true
            : false,
        cpsUpdate: false,
        cpsView:
          this.state.selected_costing === "Barge Coal Procurement Service"
            ? true
            : false,
        serviceView: this.state.selected_costing === "Services Payments" ? true : false,
        serviceUpdate: false,
        preShipmentUpdate: false,
        preShipmentView:
          this.state.selected_costing === "Pre Shipment Data" ? true : false,

        barge_dmr_to_vend_update: false,
        barge_dmr_to_vend:
          this.state.selected_costing === "Barge Demurrage to vendor"
            ? true
            : false,
        barge_dmr_supplier_update: false,
        barge_dmr_supplier:
          this.state.selected_costing === "Barge Demurrage to Supplier" ||
            this.state.selected_costing === "Barge Demurrage From Supplier"
            ? true
            : false,
        buyerDemurrageUpdate: false,
        buyerDemurrageView:
          this.state.selected_costing === "Barge Demurrage From buyer" ||
            this.state.selected_costing === "Barge Demurrage To Buyer"
            ? true
            : false,

        MvFloatingCraneUpdate: false,
        MvFloatingCraneView:
          this.state.selected_costing === "MV Flaoting Crane" ? true : false,
        MvStevdoreCostingUpdate: false,
        MvStevdoreCostingView:
          this.state.selected_costing === "MV Stevedore Costing" ? true : false,
        MvCpcUpdate: false,
        MvCpcView:
          this.state.selected_costing === "MV Coal Procurement Charges"
            ? true
            : false,
        MvDocUpdate: false,

        MvDocView:
          this.state.selected_costing === "MV Docuementation Charges"
            ? true
            : false,
        MvCscView:
          this.state.selected_costing === "MV Coal Sales Charges"
            ? true
            : false,
        MvCscUpdate: false,
        MvlaprnView: this.state.selected_costing === "MV Laparon Survey Charges" ? true : false,
        MvPreShipmentUpdate: false,
        MvPreShipmentView:
          this.state.selected_costing === "MV Pre Shipment" ? true : false,
        MvExportTaxUpdate: false,
        MVExportTaxView:
          this.state.selected_costing === "MV Export Tax" ? true : false,
        pnbpView: this.state.selected_costing === "MV PNBP JASA PBM" ? true : false,
        pnbpUpdate: false,
        MvSacUpdate: false,
        MvSacView:
          this.state.selected_costing === "MV Sampling & Analaysis Charges"
            ? true
            : false,
        MvDeadFreightUpdate: false,
        MvDeadFreightView: this.state.selected_costing === "MV Dead Freight"
          ? true
          : false,
        MvBuyerDemUpdate: false,
        MvBuyerDemView:
          this.state.selected_costing === "MV Buyer Demurrage" ? true : false,
        MvSupplierDemView:
          this.state.selected_costing === "MV Supplier Demurrage"
            ? true
            : false,
        MvSupplierDemUpdate: false,
        IncomingCreditNoteUpdate: false,
        IncomingCreditNoteView:
          this.state.selected_costing === "MV Incoming Credit Note"
            ? true
            : false,
        OutgoingCreditNoteUpdate: false,
        OutgoingCreditNoteView:
          this.state.selected_costing === "MV Outgoing Credit Note"
            ? true
            : false,
        IncomingDebitNoteUpdate: false,
        IncomingDebitNoteView:
          this.state.selected_costing === "MV Incoming Debit Note"
            ? true
            : false,
        OutgoingDebitNoteUpdate: false,
        OutgoingDebitNoteView:
          this.state.selected_costing === "MV Outgoing Debit Note"
            ? true
            : false,
        OtherExpenseUpdate: false,
        OtherExpenseView:
          this.state.selected_costing === "MV Other Expenses" ? true : false,
        OtherIncomeUpdate: false,
        OtherIncomeView:
          this.state.selected_costing === "MV Other Income" ? true : false,
      });
    }
  };

  CallbackDrawerClose = (value) => {
    this.setState(
      {
        showDrawer: false,
        MvFloatingCraneView: false,
        MvStevdoreCostingView: false,
        MvSacView: false,
        MvDocView: false,
        MvCscView: false,
        MvlaprnView: false,
        MvCpcView: false,
        MVExportTaxView: false,
        MvPreShipmentView: false,
        serviceUpdate: false,
        serviceView: false,

        // Barge Demurrages
        barge_dmr_to_vend: false,
        barge_dmr_supplier: false,
        buyerDemurrageView: false,
        MvSupplierDemView: false,
        MvBuyerDemView: false,

        IncomingCreditNoteView: false,
        IncomingCreditNoteUpdate: false,
        OutgoingCreditNoteView: false,
        OutgoingCreditNoteUpdate: false,
        IncomingDebitNoteUpdate: false,
        IncomingDebitNoteView: false,
        OutgoingDebitNoteView: false,
        OutgoingDebitNoteUpdate: false,
        OtherExpenseView: false,
        OtherExpenseUpdate: false,
        OtherIncomeView: false,
        OtherIncomeUpdate: false,
      },
      () => {
        this.componentDidMount()

      }
    );
  };

  timeTakenForApprovalHandler = (costingDate, approvalDate) => {
    if (costingDate && approvalDate) {
      var g1 = new Date(costingDate);
      var g2 = new Date(approvalDate);
      var diff = g2.getTime() - g1.getTime();
      var mins = Math.round(diff / 60000);
      var hrs = Math.floor(mins / 60);
      var days = Math.floor(hrs / 24);
      mins = mins % 60;
      hrs = hrs % 24;
      if (0 > days) {
        days = 0;
      }
      var ageForCostingApproval = days;
      return ageForCostingApproval;
    }
  };

  timeTakenForPayment = (approvedDate, paymentDate) => {
    if (approvedDate && paymentDate) {
      var g1 = new Date(approvedDate);
      var g2 = new Date(paymentDate);
      var diff = g2.getTime() - g1.getTime();
      var mins = Math.round(diff / 60000);
      var hrs = Math.floor(mins / 60);
      var days = Math.floor(hrs / 24);
      mins = mins % 60;
      hrs = hrs % 24;
      if (0 > days) {
        days = 0;
      }
      var ageForCostingApproval = days;
      return ageForCostingApproval;
    }
  };

  approvePaymentsHandler = (id, type) => {
    this.setState({ isLoading: true });
    let request = {
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
      payment_id: id,
      costing_type: type,
      approval_status: "Approved",
    };
    api.approve_payment_postings_api(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.setState(
              {
                isLoading: false,
                snackBarSuccessOpen: true,
                successMsg: res.message,
              },
              () => this.componentDidMount()
            );
          } else if (res.code === "601") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
              isLoading: false,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
  };

  update_payment_date = (id, type, date) => {
    const filted_data = PAYMENTS_INFO.find(
      (item) => item.costing_type === type
    );
    const { payment_table_name } = filted_data || {};
    this.setState({ isLoading: true });
    let reqBody = {
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
      payment_table: payment_table_name,
      payment_id: id,
      date_of_payment: date,
    };
    api.update_payment_date(reqBody).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.setState(
              {
                isLoading: false,
                snackBarSuccessOpen: true,
                successMsg: res.message,
              },
              () => this.componentDidMount()
            );
          } else if (res.code === "601") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
              isLoading: false,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
  };

  update_payment_due_date = (id, type, date) => {
    const filted_data = PAYMENTS_INFO.find(
      (item) => item.costing_type === type
    );
    const { payment_table_name } = filted_data || {};
    this.setState({ isLoading: true });
    let reqBody = {
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
      payment_table: payment_table_name,
      payment_id: id,
      payment_due_date: date,
    };
    api.update_payment_due_date(reqBody).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.setState(
              {
                isLoading: false,
                snackBarSuccessOpen: true,
                successMsg: res.message,
              },
              () => this.componentDidMount()
            );
          } else if (res.code === "601") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
              isLoading: false,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
  };

  getColor = (row, type) => {
    if (row.payment_approved_date) {
      const payment_date = row.date_of_payment
        ? row.date_of_payment
        : new Date();
      const payment_approved_date =
        row.payment_approved_date;
      var age = 0;

      if (payment_approved_date && payment_date) {
        var g1 = new Date(payment_approved_date);
        var g2 = new Date(payment_date);
        var diff = g2.getTime() - g1.getTime();
        var mins = Math.round(diff / 60000);
        var hrs = Math.floor(mins / 60);
        var days = Math.floor(hrs / 24);
        mins = mins % 60;
        hrs = hrs % 24;
        if (0 > days) {
          days = 0;
        }
        age = days;
      }

      if (age === 0 || age === 1) {
        return (
          <div
            className="type_1"
            style={{ padding: 0, borderRadius: 50 }}
          >
            {age}
          </div>
        );
      } else if (age === 2 || age === 3) {
        // return "yellow";
        return (

          <div
            className="type_5"
            style={{ padding: 0, borderRadius: 50 }}
          >
            {age}
          </div>
        )
      } else if (4 < age) {
        // return "red";
        return (

          <div
            className="type_4"
            style={{ padding: 0, borderRadius: 50 }}
          >
            {age}
          </div>
        )
      } else {
        return (<div
          className="type_3"
          style={{ height: 'auto' }}
        >
          {age}
        </div>);
      }
    }

  };

  convertExcelHandler = (data, filename, ShowLabel) => {
    var arrData = typeof data != "object" ? JSON.parse(data) : data;
    const arrayCopy = [];

    for (var i of arrData) {
      for (var j of i.payments) {
        i["amount"] = j.amount;
        i["date_of_payment"] = j.date_of_payment;
        i["payment_approval_status"] = j.payment_approval_status;
        // i["payment_approval_status_2"] = j.payment_approval_status_2;
        i["payment_approved_by"] = j.payment_approved_by;
        i["payment_approved_by_2"] = j.payment_approved_by_2;
        i["payment_approved_date"] = j.payment_approved_date;
        // i["payment_approved_date_2"] = j.payment_approved_date_2;
        i["payment_created_by"] = j.payment_created_by;
        i["payment_created_date"] = j.payment_created_date;
        i["payment_due_date"] = j.payment_due_date;
        i["payment_seq_id"] = j.payment_seq_id;
      }
      arrayCopy.push(i);
    }
    let Modified = arrayCopy.map((obj) => {
      return {
        "Costing ID": obj.costing_no,
        "Costing Type": obj.type,
        "Mother Vessel": obj.business_numbers.toString(),
        "Vendor Name": obj.vendor_name,
        "Costing Approved On": localDateFormate(obj.costing_approved_date),
        "Payable Amount": toLocaleString(obj.payable_to_supplier),
        "Outstanding Amount": toLocaleString(obj.outstanding_amount),
        "Invoice No": obj.costing_invoice_no,
        "Financial Approval": obj.costing_approved_by_2,
        "Payment ID": obj.payment_seq_id,
        "Payment Due Date": localDateFormate(obj.payment_due_date),
        "Payment Amout": toLocaleString(obj.redraft_quantity),
        "1st level Approval":
          obj.payment_approval_status !== "Pending"
            ? obj.payment_approved_by
            : "Pending",
        "1st level Approved On": localDateFormate(obj.payment_approved_date),
        "2nd level Approval":
          obj.payment_approval_status_2 !== "Pending"
            ? obj.payment_approval_status_2
            : "Pending",
        "2nd level Approved On": localDateFormate(obj.payment_approved_date_2),
        "Time Taken for Approval": obj.payment_approved_date_2,
        "Time Taken for Payment": obj.payment_approved_date_2,
      };
    });

    exportToExcel(Modified, filename);
  };

  renderPaymentsModal = () => {
    if (this.state.costingDataCopy) {
      const {
        type,
        costing_invoice_no,
        id,
        costing_billing_currency,
        costing_currency_xchg_rate = 1,
        outstanding_amount = 0,
      } = this.state.costingDataCopy;

      const seleted_type = type === "Barge Purchase Other Expenses" ? "Barge Other Expenses" : type;

      let payment_details = PAYMENTS_INFO.find((e) => e.costing_type === seleted_type);

      let patment_info = {
        ...payment_details,
        invoice_no: costing_invoice_no,
        costing_id: id,
        paymentTitle: `Add ${type}`,
        billing_currency: costing_billing_currency
          ? costing_billing_currency
          : config.company === "SRPL" || config.company === "PRPL" || config.company === "AVS"
            ? "USD"
            : "IDR",
        currency_xchg_rate: costing_currency_xchg_rate
          ? costing_currency_xchg_rate
          : 1,
        outstanding_amount: outstanding_amount,
        login_user_id: this.Cookie.getCookie("loginUserId"),
        idtoken: this.Cookie.getIdTokenCookie(),
      };

      if (this.state.paymentsModal) {
        return (
          <PaymentsModal
            data={patment_info}
            open={true}
            close={() => this.setState({ paymentsModal: false })}
            callbackFn={(data) => {
              let request = data;
              api.add_payment_postings(request).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                  response.json().then((res) => {
                    if (res.code === "200") {
                      this.setState({ paymentsModal: false });
                      this.componentDidMount();
                    } else {
                    }
                  });
                }
              });
            }}
          />
        );
      }
    }
  };

  editFiledsHandlers = (value, type, costing_no, idx) => {
    let costingsDataCopy = JSON.parse(JSON.stringify(this.state.costing_data));
    for (var i = 0; i < costingsDataCopy.length; i++) {
      if (costingsDataCopy[i].costing_no === costing_no) {
        costingsDataCopy[i].payments[idx][type] = value;
        if (value === true) {
          costingsDataCopy[i].payments[idx]["date_of_payment1"] =
            costingsDataCopy[i].payments[idx].date_of_payment;
        } else if (value === null) {
          costingsDataCopy[i].payments[idx]["date_of_payment1"] = null;
        }
      }
    }

    this.setState({
      costing_data: costingsDataCopy,
      costing_data_backup: costingsDataCopy,
    });
  };

  approveHandler = (id, type) => {
    this.setState({ isLoading: true });
    let selected_type = type;
    if (type === "Barge Purchase Commercial Coal Costing") {
      selected_type = "Barge Purchase performa Coal Costing";
    }
    if (type === "Barge Purchase Other Expenses") {
      selected_type = "Barge Other Expenses";
    }
    if (type === "Services Payments") {
      selected_type = "payable_services";

    }
    let request = {
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
      costing_id: id,
      costing_type: selected_type,
      approval_status: "Approved",
    };
    api.approve_costing_api(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.setState(
              {
                isLoading: false,
                snackBarSuccessOpen: true,
                successMsg: res.message,
              },
              () => this.componentDidMount()
            );
          } else if (res.code === "601") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
              isLoading: false,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
  };

  GlobalSearch = (e) => {
    if (this.state.mainTabIndex === 0) {

      let input, filter, table, tr, td, i, j
      input = document.getElementById("search");
      filter = input.value.toUpperCase();
      table = document.getElementById("myTable");
      tr = table.getElementsByTagName("tr");
      for (i = 0; i < tr.length; i++) {
        td = tr[i].getElementsByTagName("td");
        for (j = 0; j < td.length; j++) {
          let tdata = td[j];
          if (tdata) {
            if (tdata.innerHTML.toUpperCase().indexOf(filter) > -1) {
              tr[i].style.display = "";
              break;
            } else {
              tr[i].style.display = "none";
            }
          }
        }
      }

      this.setState({
        GlobalSearchValue: input.value,
      })
    }

  }

  applyFiltersHandler = () => {
    // console.log("applyFiltersHandler");
    // const { costing_data_backup, selected_vendors, selected_costing_types, payment_due_date_value, date_of_payment_value } = this.state;
    const { costing_data_backup, selected_vendors, payment_due_date_value, date_of_payment_value } = this.state;

    const paymentFiltered = costing_data_backup.filter((el, idx) => {
      return selected_vendors.includes(el.vendor_name) && el.payments.some(e => localDateFormate(e.date_of_payment) === localDateFormate(date_of_payment_value) && localDateFormate(e.payment_due_date) === localDateFormate(payment_due_date_value))
    })

    // const apply_filter = this.state.filter_costing_type_value !== "All" ? this.state.costing_data_backup.filter((e) => e.type === this.state.filter_costing_type_value) : this.state.costing_data_backup;
    this.setState({
      costing_data: paymentFiltered,
      vendorFilter: false,
      date_of_payment_filter: false,
      payment_due_date_filter: false,
    })
  }

  render() {
    let drawerComponent;
    if (this.state.BargeCostingView && this.state.comboID !== null) {
      drawerComponent = (
        <BargeCostingView
          bargeCostingID={this.state.comboID}
          onCancel={this.onCancel}
          onEdit={this.onEdit}
          returnDocumentsHandler={this.addDocuments}
        />
      );
    } else if (this.state.BargeCostingUpdate && this.state.comboID !== null) {
      drawerComponent = (
        <BargeCostingUpdate
          bargeCostingID={this.state.comboID}
          onUpdateCosting={this.onUpdateResponse}
          onCancel={this.onCancel}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }
    if (this.state.ChemicalSprayView && this.state.comboID !== null) {
      drawerComponent = (
        <ChemicalSprayView
          chemicalSprayID={this.state.comboID}
          onCancel={this.onCancel}
          onEdit={this.onEdit}
          returnDocumentsHandler={this.addDocuments}
        />
      );
    } else if (this.state.ChemicalSprayUpdate && this.state.comboID !== null) {
      drawerComponent = (
        <ChemicalSprayUpdate
          chemicalSprayID={this.state.comboID}
          onUpdateCosting={this.onUpdateResponse}
          onCancel={this.onCancel}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }
    if (this.state.CoalCostingView && this.state.comboID !== null) {
      drawerComponent = (
        <BargeCoalCostingView
          comboID={this.state.comboID}
          onCancel={this.onCancel}
          costing_type={this.state.barge_costing_type}
          onEdit={this.onEdit}
          returnDocumentsHandler={this.addDocuments}
          invoiceCostingHandler={(data) => {
            this.setState({
              costingTopOfInvoice: data,
              invoiceCosting: true,
              showDrawer: true,
              comboID: null,
            });
          }}
        />
      );
    }
    else if (this.state.invoiceCosting && this.state.costingTopOfInvoice) {
      drawerComponent = (
        <PerformaInvoiceCosting
          anotherInfo={this.state.costingTopOfInvoice}
          costing_type="Performa"
          onAddResponse={this.onAddResponse}
          onCancel={this.onCancel}
        />
      );
    }
    else if (this.state.CoalCostingUpdate && this.state.comboID !== null) {
      drawerComponent = (
        <BargeCoalCostingUpdate
          comboID={this.state.comboID}
          onUpdateCosting={this.onUpdateResponse}
          costing_type={this.state.barge_costing_type}
          onBack={this.onCancel}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }
    if (this.state.exportTaxView && this.state.comboID !== null) {
      drawerComponent = (
        <BargeExportTaxView
          exportTaxID={this.state.comboID}
          onCancel={this.onCancel}
          onEdit={this.onEdit}
          returnDocumentsHandler={this.addDocuments}
        />
      );
    } else if (this.state.exportTaxUpdate && this.state.comboID !== null) {
      drawerComponent = (
        <BargeExportTaxUpdate
          exportTaxID={this.state.comboID}
          onUpdateCosting={this.onUpdateResponse}
          onCancel={this.onCancel}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }
    if (this.state.docView && this.state.comboID !== null) {
      drawerComponent = (
        <DocumentationChargesView
          docID={this.state.comboID}
          onCancel={this.onCancel}
          onEdit={this.onEdit}
          returnDocumentsHandler={this.addDocuments}
        />
      );
    } else if (this.state.docUpdate && this.state.comboID !== null) {
      drawerComponent = (
        <DocumentationChargesUpdate
          docID={this.state.comboID}
          onUpdateCosting={this.onUpdateResponse}
          onCancel={this.onCancel}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }

    if (this.state.laprnView && this.state.comboID) {
      drawerComponent = (
        <LaporanChargesView
          laparon_charges_id={this.state.comboID}
          onCancel={this.onCancel}
          onEdit={this.onEdit}
          returnDocumentsHandler={this.addDocuments}
        />
      )
    }
    else if (this.state.laprnUpdate && this.state.comboID) {
      drawerComponent = (
        <LaporanChargesUpdate
          laparon_charges_id={this.state.comboID}
          onUpdateCosting={this.onUpdateResponse}
          onCancel={this.onCancel}
          updateDocuments={this.state.updateDocuments}
        />
      )
    }
    if (this.state.cargoView && this.state.returnCargoID) {
      drawerComponent = (
        <ReturnCargoView
          returnCargoID={this.state.returnCargoID}
          onCancel={this.onCancel}
          onEdit={this.onEdit}
          returnDocumentsHandler={this.addDocuments}
        />
      );
    } else if (this.state.cargoUpdate && this.state.returnCargoID) {
      drawerComponent = (
        <ReturnCargoUpdate
          returnCargoID={this.state.returnCargoID}
          onUpdateCosting={this.onUpdateResponse}
          onCancel={this.onCancel}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }

    if (this.state.sacView && this.state.comboID !== null) {
      drawerComponent = (
        <BargeSacView
          comboID={this.state.comboID}
          onCancel={this.onCancel}
          onEdit={this.onEdit}
          returnDocumentsHandler={this.addDocuments}
        />
      );
    } else if (this.state.sacUpdate && this.state.comboID !== null) {
      drawerComponent = (
        <BargeSacUpdate
          comboID={this.state.comboID}
          onUpdateCosting={this.onUpdateResponse}
          onCancel={this.onCancel}
          updateDocuments={this.state.updateDocuments}
        />
      );
    } else if (this.state.cpcView && this.state.comboID !== null) {
      drawerComponent = (
        <CoalProcurementChargesView
          costing_id={this.state.comboID}
          onCancel={this.onCancel}
          onEdit={this.onEdit}
          returnDocumentsHandler={this.addDocuments}
        />
      );
    } else if (this.state.cpcUpdate && this.state.comboID !== null) {
      drawerComponent = (
        <CoalProcurementChargesUpdate
          costing_id={this.state.comboID}
          onUpdateCosting={this.onUpdateResponse}
          onCancel={this.onCancel}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }
    if (this.state.cpsView && this.state.comboID !== null) {
      drawerComponent = (
        <BargeCpsView
          invoiceID={this.state.comboID}
          onCancel={this.onCancel}
          onEdit={this.onEdit}
          returnDocumentsHandler={this.addDocuments}
        />
      );
    } else if (this.state.cpsUpdate && this.state.comboID !== null) {
      drawerComponent = (
        <BargeCpsUpdate
          invoiceID={this.state.comboID}
          purchase_contract_id={this.state.purchase_contract_id}
          onUpdateCosting={this.onUpdateResponse}
          onCancel={this.onCancel}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }
    if (this.state.preShipmentView && this.state.comboID !== null) {
      drawerComponent = (
        <BargePreShipmentView
          costing_id={this.state.comboID}
          onCancel={this.onCancel}
          onEdit={this.onEdit}
          returnDocumentsHandler={this.addDocuments}
        />
      );
    } else if (this.state.preShipmentUpdate && this.state.comboID !== null) {
      drawerComponent = (
        <PreShipmentInspectionUpdate
          costing_id={this.state.comboID}
          onUpdateCosting={this.onUpdateResponse}
          onCancel={this.onCancel}
          shipment_type={this.state.pre_shipment_type}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }
    else if (this.state.BgOtherExpensView && this.state.comboID) {
      drawerComponent = (
        <BargeOtherExpenseView
          costing_id={this.state.comboID}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.onEdit}
          returnDocumentsHandler={this.addDocuments}

        />
      )
    }
    else if (this.state.BgOtherExpensUpdate && this.state.comboID) {
      drawerComponent = (
        <BargeOtherExpenseUpdate
          costing_id={this.state.comboID}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.onCancel}
        />
      )
    }

    // Barge Demurrages
    if (this.state.barge_dmr_to_vend && this.state.comboID) {
      drawerComponent = (
        <BargeVendorDemurrageView
          comboID={this.state.comboID}
          returnCallbackDrawerClose={this.onCancel}
          returnEditCallback={this.onEdit}
          returnDocumentsHandler={this.addDocuments}
        />
      );
    } else if (this.state.barge_dmr_to_vend_update && this.state.comboID) {
      drawerComponent = (
        <BargeVendorDemurrageUpdate
          comboID={this.state.comboID}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.onCancel}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }
    if (this.state.barge_dmr_supplier && this.state.comboID) {
      drawerComponent = (
        <BargeSupplierDemurrageView
          demurrage_type={this.state.dmr_type}
          comboID={this.state.comboID}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.onEdit}
          returnDocumentsHandler={this.addDocuments}
        />
      );
    } else if (this.state.barge_dmr_supplier_update && this.state.comboID) {
      drawerComponent = (
        <BargeSupplierDemurrageUpdate
          demurrage_type={this.state.dmr_type}
          comboID={this.state.comboID}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.onCancel}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }
    if (this.state.buyerDemurrageView && this.state.comboID) {
      drawerComponent = (
        <BargeBuyerDemurrageView
          demurrage_type={this.state.dmr_type}
          comboID={this.state.comboID}
          businessNo={this.state.business_no}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.onEdit}
          returnDocumentsHandler={this.addDocuments}
        />
      );
    } else if (this.state.buyerDemurrageUpdate && this.state.comboID) {
      drawerComponent = (
        <BuyerDemurrageUpdate
          businessNo={this.state.business_no}
          demurrageData={this.state.demurrageData}
          demurrage_type={this.state.dmr_type}
          comboID={this.state.comboID}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.onCancel}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }
    else if (this.state.serviceView && this.state.comboID) {
      drawerComponent = (
        <ViewService
          serviceRequestId={this.state.comboID}
          returnDocumentsHandler={this.addDocuments}
          onCancel={this.onCancel}
          onEdit={this.onEdit}
        />
      )
    }
    else if (this.state.serviceUpdate && this.state.comboID) {
      drawerComponent = (
        <UpdateService
          serviceRequestId={this.state.comboID}
          onUpdateCosting={this.onUpdateResponse}
          onCancel={this.onCancel}
          updateDocuments={this.state.updateDocuments}
        />
      )
    }
    // Barge sales Fiancials
    if (this.state.salesPerformaView && this.state.comboID) {
      drawerComponent = (
        <ViewSalesPI
          mv_sales_costing_id={this.state.comboID}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          onEdit={this.onEdit}
        />
      );
    }
    if (this.state.salesPerformaUpdate && this.state.comboID) {
      drawerComponent = (
        <UpdateSalesPI
          mv_sales_costing_id={this.state.comboID}
          businessNoID={this.state.business_no}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.onCancel}
        />
      );
    }
    else if (this.state.pnbpView && this.state.business_no) {
      drawerComponent = (
        <PnbpChargesView
          costing_id={this.state.comboID}
          businessNoID={this.state.business_no}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.onEdit}
          returnDocumentsHandler={() => {
            this.setState({
              showDrawer: true,
              pnbpUpdate: true,
              pnbpView: false,
              PnbpChargesAdd: false,
              updateDocuments: true,
            });
          }}
        />
      );
    } else if (this.state.pnbpUpdate && this.state.business_no) {
      drawerComponent = (
        <PnbpChargesUpdate
          costing_id={this.state.comboID}
          businessNoID={this.state.business_no}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.onCancel}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }

    if (this.state.salesCommercialView && this.state.comboID) {
      drawerComponent = (
        <BargeSalesCommericalView
          comboID={this.state.comboID}
          onCancel={this.onCancel}
          onEdit={this.onEdit}
        />
      );
    } else if (
      this.state.salesCommercialUpdate &&
      this.state.comboID !== null
    ) {
      drawerComponent = (
        <BargeSalesCommericalUpdate
          comboID={this.state.comboID}
          onBack={this.onCancel}
        />
      );
    }

    // Mother vessel
    if (this.state.MvFloatingCraneView && this.state.business_no) {
      drawerComponent = (
        <MvFloatingCraneView
          floatingCraneID={this.state.comboID}
          businessNoID={this.state.business_no}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          onEdit={this.onEdit}
          returnDocumentsHandler={this.addDocuments}
        />
      );
    } else if (this.state.MvFloatingCraneUpdate && this.state.business_no) {
      drawerComponent = (
        <MvFloatingCraneUpdate
          floatingCraneID={this.state.comboID}
          businessNoID={this.state.business_no}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.onCancel}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }
    if (this.state.MvStevdoreCostingView && this.state.business_no) {
      drawerComponent = (
        <MvStevdoreCostingView
          stevedoreID={this.state.comboID}
          businessNoID={this.state.business_no}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.onEdit}
          returnDocumentsHandler={this.addDocuments}
        />
      );
    } else if (this.state.MvStevdoreCostingUpdate && this.state.business_no) {
      drawerComponent = (
        <MVStevedorCostingUpdate
          stevedoreID={this.state.comboID}
          businessNoID={this.state.business_no}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.onCancel}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }
    if (this.state.MvCpcView && this.state.business_no) {
      drawerComponent = (
        <MvProcurementChargesView
          coalProcurementChargesID={this.state.comboID}
          businessNoID={this.state.business_no}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.onEdit}
          returnDocumentsHandler={this.addDocuments}
        />
      );
    } else if (this.state.MvCpcUpdate && this.state.business_no) {
      drawerComponent = (
        <MvProcurementChargesUpdate
          coalProcurementChargesID={this.state.comboID}
          businessNoID={this.state.business_no}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.onCancel}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }
    if (this.state.MvDocView && this.state.business_no) {
      drawerComponent = (
        <MvDocChargesView
          MVDocChargesID={this.state.comboID}
          businessNoID={this.state.business_no}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.onEdit}
          returnDocumentsHandler={this.addDocuments}
        />
      );
    } else if (this.state.MvDocUpdate && this.state.business_no) {
      drawerComponent = (
        <MvDocChargesUpdate
          MVDocChargesID={this.state.comboID}
          businessNoID={this.state.business_no}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.onCancel}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }
    if (this.state.MvCscView && this.state.business_no) {
      drawerComponent = (
        <MvCoalSalesChargesView
          coalSalesChargesID={this.state.comboID}
          businessNoID={this.state.business_no}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.onEdit}
          returnDocumentsHandler={this.addDocuments}
        />
      );
    } else if (this.state.MvCscUpdate && this.state.business_no) {
      drawerComponent = (
        <MvCoalSalesChargesUpdate
          coalSalesChargesID={this.state.comboID}
          businessNoID={this.state.business_no}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.onCancel}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }
    if (this.state.MvlaprnView && this.state.business_no) {
      drawerComponent = (
        <MvLoparanChargesView
          costing_id={this.state.comboID}
          businessNoID={this.state.business_no}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.onEdit}
          returnDocumentsHandler={this.addDocuments}
        />
      );
    } else if (this.state.MvlaprnUpdate && this.state.business_no) {
      drawerComponent = (
        <MvLoparanChargesUpdate
          costing_id={this.state.comboID}
          businessNoID={this.state.business_no}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.onCancel}
          updateDocuments={this.state.updateDocuments}
        />
      );
    } else if (this.state.MvPreShipmentView && this.state.business_no) {
      drawerComponent = (
        <MvPreShipmentView
          preShipmentID={this.state.comboID}
          businessNoID={this.state.business_no}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.onEdit}
          returnDocumentsHandler={this.addDocuments}
        />
      );
    } else if (this.state.MvPreShipmentUpdate && this.state.business_no) {
      drawerComponent = (
        <MVPreShipmentChargesUpdate
          preShipmentID={this.state.comboID}
          businessNoID={this.state.business_no}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.onCancel}
          updateDocuments={this.state.updateDocuments}
        />
      );
    } else if (this.state.MVExportTaxView && this.state.business_no) {
      drawerComponent = (
        <MVExportTaxView
          ExportTaxID={this.state.comboID}
          businessNoID={this.state.business_no}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.onEdit}
          returnDocumentsHandler={this.addDocuments}
        />
      );
    } else if (this.state.MvExportTaxUpdate && this.state.business_no) {
      drawerComponent = (
        <MVExportTaxUpdate
          ExportTaxID={this.state.comboID}
          businessNoID={this.state.business_no}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.onCancel}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }
    if (this.state.MvSacView && this.state.business_no) {
      drawerComponent = (
        <MVSamplingChargesView
          MVSamplingChargesID={this.state.comboID}
          businessNoID={this.state.business_no}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.onEdit}
          returnDocumentsHandler={this.addDocuments}
        />
      );
    } else if (this.state.MvSacUpdate && this.state.business_no) {
      drawerComponent = (
        <MVSamplingChargesUpdate
          MVSamplingChargesID={this.state.comboID}
          businessNoID={this.state.business_no}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.onCancel}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }
    if (this.state.MvDeadFreightView && this.state.business_no) {
      drawerComponent = (
        <MvDeadFreightView
          businessNoID={this.state.business_no}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.onEdit}
        />
      );
    } else if (this.state.MvDeadFreightUpdate && this.state.business_no) {
      drawerComponent = (
        <MvDeadFreightUpdate
          businessNoID={this.state.business_no}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.onCancel}
        />
      );
    }
    // Mother vessel demurrage
    if (this.state.MvBuyerDemView && this.state.business_no) {
      drawerComponent = (
        <MvBuyerDemurrageView
          MvBuyerDemurrageID={this.state.comboID}
          businessNoID={this.state.business_no}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.onEdit}
          returnDocumentsHandler={this.addDocuments}
        />
      );
    } else if (this.state.MvBuyerDemUpdate && this.state.business_no) {
      drawerComponent = (
        <MvBuyerDemurrageUpdate
          MvBuyerDemurrageID={this.state.comboID}
          businessNoID={this.state.business_no}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.onCancel}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }
    if (this.state.MvSupplierDemView && this.state.business_no) {
      drawerComponent = (
        <MvSupplierDemurrageView
          MvSupplierDemurrageID={this.state.comboID}
          businessNoID={this.state.business_no}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.onEdit}
          returnDocumentsHandler={this.addDocuments}
        />
      );
    } else if (this.state.MvSupplierDemUpdate && this.state.business_no) {
      drawerComponent = (
        <MvSupplierDemurrageUpdate
          MvSupplierDemurrageID={this.state.comboID}
          businessNoID={this.state.business_no}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.onCancel}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }

    if (this.state.IncomingCreditNoteView && this.state.business_no) {
      drawerComponent = (
        <IncomingCreditNoteView
          creditNoteID={this.state.comboID}
          businessNoID={this.state.business_no}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.onEdit}
          returnDocumentsHandler={this.addDocuments}
        />
      );
    } else if (this.state.IncomingCreditNoteUpdate && this.state.business_no) {
      drawerComponent = (
        <IncomingCreditNoteUpdate
          creditNoteID={this.state.comboID}
          businessNoID={this.state.business_no}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.onCancel}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }
    if (this.state.OutgoingCreditNoteView && this.state.business_no) {
      drawerComponent = (
        <OutgoingCreditNoteView
          creditNoteID={this.state.comboID}
          businessNoID={this.state.business_no}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.onEdit}
          returnDocumentsHandler={this.addDocuments}
        />
      );
    } else if (this.state.OutgoingCreditNoteUpdate && this.state.business_no) {
      drawerComponent = (
        <OutgoingCreditNoteUpdate
          creditNoteID={this.state.comboID}
          businessNoID={this.state.business_no}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.onCancel}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }

    if (this.state.IncomingDebitNoteView && this.state.business_no) {
      drawerComponent = (
        <IncomingDebitNoteView
          debitNoteID={this.state.comboID}
          businessNoID={this.state.business_no}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.onEdit}
          returnDocumentsHandler={this.addDocuments}
        />
      );
    } else if (this.state.IncomingDebitNoteUpdate && this.state.business_no) {
      drawerComponent = (
        <IncomingDebitNoteUpdate
          debitNoteID={this.state.comboID}
          businessNoID={this.state.business_no}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.onCancel}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }
    if (this.state.OutgoingDebitNoteView && this.state.business_no) {
      drawerComponent = (
        <OutgoingDebitNoteView
          debitNoteID={this.state.comboID}
          businessNoID={this.state.business_no}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.onEdit}
          returnDocumentsHandler={this.addDocuments}
        />
      );
    } else if (this.state.OutgoingDebitNoteUpdate && this.state.business_no) {
      drawerComponent = (
        <OutgoingDebitNoteUpdate
          debitNoteID={this.state.comboID}
          businessNoID={this.state.business_no}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.onCancel}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }
    if (this.state.OtherExpenseView && this.state.business_no) {
      drawerComponent = (
        <OtherExpenseView
          OtherExpenseID={this.state.comboID}
          businessNoID={this.state.business_no}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.onEdit}
          returnDocumentsHandler={this.addDocuments}
        />
      );
    } else if (this.state.OtherExpenseUpdate && this.state.business_no) {
      drawerComponent = (
        <OtherExpenseUpdate
          OtherExpenseID={this.state.comboID}
          businessNoID={this.state.business_no}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.onCancel}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }
    if (this.state.OtherIncomeView && this.state.business_no) {
      drawerComponent = (
        <OtherIncomeView
          OtherIncomeID={this.state.comboID}
          businessNoID={this.state.business_no}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.onEdit}
          returnDocumentsHandler={this.addDocuments}
        />
      );
    } else if (this.state.OtherIncomeUpdate && this.state.business_no) {
      drawerComponent = (
        <OtherIncomeUpdate
          OtherIncomeID={this.state.comboID}
          businessNoID={this.state.business_no}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.onCancel}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }

    return (
      <div id="wraper">
        <SideBar />
        {this.renderPaymentsModal()}

        <div className="content">
          <Header />
          <Drawer
            anchor="right"
            open={this.state.showDrawer}
            variant="temporary"
            elevation={20}
            style={{ overflow: "initial" }}
          >
            <div className="row" style={{ width: 800 }}>
              {drawerComponent}
            </div>
          </Drawer>
          {this.state.isLoading && (
            <Loader />
          )}
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <div className="content-header">
            <div className="col-sm pl-0">
              <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                Financial Approvals
                <span style={{ color: "lightgrey", margin: "0px 5px" }}>
                  |
                </span>
                <span style={{ color: "#959cb6", fontSize: "15px" }}>
                  {this.state.costing_data.length}
                </span>
              </h4>
            </div>
          </div>
          <div className="container p-2">
            {!this.state.isLoading && (
              <div className="card p-0 " style={{ overflowY: "scroll" }}>
                <div className="row border-bottom">
                  <Tabs
                    value={this.state.mainTabIndex}
                    className='mr-auto m-0'
                    onChange={(event, value) => {
                      this.setState({
                        mainTabIndex: value,
                      },
                        () => {

                          if (this.state.activeTabIndex === 0) {
                            this.pendingFinancialApprovals()
                          }
                          // else if (this.state.activeTabIndex === 2) {
                          //   this.approvedListHandler();
                          // }
                          // else if (this.state.activeTabIndex === 3) {
                          //   this.completedPayments()
                          // }
                        });
                    }}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    style={{
                      backgroundColor: "#ffffff",
                      color: "#6c7293",
                      padding: 0,
                    }}
                    TabIndicatorProps={{
                      style: { background: config.themeColor },
                    }}
                  >
                    <Tab
                      label={
                        <h4
                          style={{
                            margin: "0px",
                            color:
                              this.state.mainTabIndex === 0
                                ? config.themeColor
                                : "#6c7293",
                            fontSize: "14px",
                            border: "0px",
                          }}
                        >
                          Account Payables
                        </h4>
                      }
                      value={0}
                      style={{
                        fontFamily: "Poppins",
                        textTransform: "capitalize",
                        color: "#6c7293",
                      }}
                    />
                    {(this.state.features.includes("46") ||
                      this.state.features.includes("97")) && (
                        <Tab
                          label={
                            <h4
                              style={{
                                margin: "0px",
                                color:
                                  this.state.mainTabIndex === 1
                                    ? config.themeColor
                                    : "#6c7293",
                                fontSize: "14px",
                                border: "0px",
                              }}
                            >
                              Account Receivables
                            </h4>
                          }
                          value={1}
                          style={{
                            fontFamily: "Poppins",
                            textTransform: "capitalize",
                            color: "#6c7293",
                          }}
                        />
                      )}


                  </Tabs>

                </div>
                {this.state.mainTabIndex === 0 &&
                  <>
                    <br />

                    <div className='d-flex pr-3 pb-3 '>
                      <div className='mr-auto pl-3'>
                        <Button
                          className="tab_button"
                          onClick={(e, value) => {
                            this.setState(
                              {
                                activeTabIndex: 0,
                              },
                              () => this.pendingFinancialApprovals()
                            );
                          }}
                          style={{
                            textTransform: "capitalize",
                            margin: "5px 0px",
                            padding: "5px 20px",
                            borderRadius: 35,
                            height: 30,
                            color:
                              this.state
                                .activeTabIndex === 0
                                ? config.themeColor
                                : "#483b34b3",
                            backgroundColor:
                              this.state
                                .activeTabIndex === 0
                                ? "#fff7f2"
                                : "#fff",
                            fontSize: "14px",
                            border: "0px",
                          }}
                        >
                          Financial Approvals
                        </Button>
                        <Button
                          className="tab_button"
                          onClick={(e, value) => {
                            this.setState(
                              {
                                activeTabIndex: 1,
                              },
                              () => this.pendingPaymentApprovals()
                            );
                          }}
                          style={{
                            textTransform: "capitalize",
                            margin: "5px 0px",
                            padding: "5px 20px",
                            borderRadius: 35,
                            height: 30,
                            color:
                              this.state
                                .activeTabIndex === 1
                                ? config.themeColor
                                : "#483b34b3",
                            backgroundColor:
                              this.state
                                .activeTabIndex === 1
                                ? "#fff7f2"
                                : "#fff",
                            fontSize: "14px",
                            border: "0px",
                          }}
                        >
                          Payment Approvals
                        </Button>
                        <Button
                          className="tab_button"
                          onClick={(e, value) => {
                            this.setState(
                              {
                                activeTabIndex: 2,
                              },
                              () => this.approvedListHandler()
                            );
                          }}
                          style={{
                            textTransform: "capitalize",
                            margin: "5px 0px",
                            padding: "5px 20px",
                            borderRadius: 35,
                            height: 30,
                            color:
                              this.state
                                .activeTabIndex === 2
                                ? config.themeColor
                                : "#483b34b3",
                            backgroundColor:
                              this.state
                                .activeTabIndex === 2
                                ? "#fff7f2"
                                : "#fff",
                            fontSize: "14px",
                            border: "0px",
                          }}
                        >
                          Approved
                        </Button>
                        <Button
                          className="tab_button"
                          onClick={(e, value) => {
                            this.setState(
                              {
                                activeTabIndex: 3,
                              },
                              () => this.completedPayments()
                            );
                          }}
                          style={{
                            textTransform: "capitalize",
                            margin: "5px 0px",
                            padding: "5px 20px",
                            borderRadius: 35,
                            height: 30,
                            color:
                              this.state
                                .activeTabIndex === 3
                                ? config.themeColor
                                : "#483b34b3",
                            backgroundColor:
                              this.state
                                .activeTabIndex === 3
                                ? "#fff7f2"
                                : "#fff",
                            fontSize: "14px",
                            border: "0px",
                          }}
                        >
                          Paid
                        </Button>
                      </div>
                      <div>
                        <input type="text" className="form-control form-control-sm mt-1" id="search" placeholder="Search...." name="search" value={this.state.GlobalSearchValue} onChange={this.GlobalSearch} />
                      </div>
                      <div>
                        <Tooltip title='Download' arrow>
                          <i className="fa fa-file-excel-o ml-2 mt-2 " aria-hidden="true" style={{ color: 'green', cursor: 'pointer', fontSize: 22 }}
                            onClick={() => this.convertExcelHandler(this.state.costing_data, "Financial Approvals")}
                          />
                        </Tooltip>
                      </div>
                    </div>

                    {/* <FinancialTable
                  rows={this.state.costing_data}
                  onClickCostingNo={(item) => this.onClickCosting(item)}

                /> */}

                    <div className='row col-lg-12 '>
                      <div className='table-responsive' style={{ height: 550, overflow: 'auto' }}>
                        <table className='table table-bordered table-sm' id='myTable'>
                          <thead className='table-header '>
                            <th nowrap="true" className='text-uppercase' style={{ position: 'sticky', top: 0, background: '#f7f3f0' }}>Costing ID</th>
                            <th nowrap="true" className='text-uppercase ' style={{ position: 'sticky', top: 0, background: '#f7f3f0', minWidth: 150 }}>Costing Type <i className='fa fa-filter text-right' style={{ fontSize: 18, cursor: 'pointer', color: config.themeColor, float: 'right' }} onClick={(event) => this.setState({
                              anchorEl: event.currentTarget,
                              openQuantityAllocation: true,
                            })
                            } />
                            </th>
                            <th nowrap="true" className='text-uppercase ' style={{ position: 'sticky', top: 0, background: '#f7f3f0' }}>Mother Vessel</th>
                            <th nowrap="true" className='text-uppercase ' style={{ position: 'sticky', top: 0, background: '#f7f3f0', minWidth: 150 }}>
                              vendor Name
                              {/* <i className='fa fa-filter text-right' style={{ fontSize: 18, cursor: 'pointer', color: config.themeColor, float: 'right', }} onClick={(event) => this.setState({
                                anchorEl: event.currentTarget,
                                vendorFilter: true,
                              })
                              } /> */}
                            </th>
                            <th nowrap="true" className='text-uppercase ' style={{ position: 'sticky', top: 0, background: '#f7f3f0' }}>Costing Approved On</th>
                            <th nowrap="true" className='text-uppercase ' style={{ position: 'sticky', top: 0, background: '#f7f3f0' }}>Payable</th>
                            <th nowrap="true" className='text-uppercase ' style={{ position: 'sticky', top: 0, background: '#f7f3f0' }}>Outstanding</th>
                            <th nowrap="true" className='text-uppercase ' style={{ position: 'sticky', top: 0, background: '#f7f3f0' }}>Financial Approve</th>
                            <th nowrap="true" className='text-uppercase ' style={{ position: 'sticky', top: 0, background: '#f7f3f0' }}>Invoice No</th>
                            <th nowrap="true" className='text-uppercase ' style={{ position: 'sticky', top: 0, background: '#f7f3f0' }}>Payment ID</th>
                            <th nowrap="true" className='text-uppercase ' style={{ position: 'sticky', top: 0, background: '#f7f3f0' }}>Payment Amount</th>
                            {(this.state.activeTabIndex !== 0 && this.state.activeTabIndex !== 1) &&
                              <th nowrap="true" className='text-uppercase ' style={{ position: 'sticky', top: 0, background: '#f7f3f0', minWidth: 300 }}>
                                Payment Due Date
                                {/* <i className='fa fa-filter text-right' style={{ fontSize: 18, cursor: 'pointer', color: config.themeColor, float: 'right' }} onClick={(event) => this.setState({
                                  anchorEl: event.currentTarget,
                                  payment_due_date_filter: true,
                                })
                                } /> */}
                              </th>
                            }
                            {(this.state.activeTabIndex !== 0 && this.state.activeTabIndex !== 1) &&
                              <th nowrap="true" className='text-uppercase ' style={{ position: 'sticky', top: 0, background: '#f7f3f0', minWidth: 320 }}>
                                Date of Payment
                                {/* <i className='fa fa-filter text-right' style={{ fontSize: 18, cursor: 'pointer', color: config.themeColor, float: 'right' }} onClick={(event) => this.setState({
                                  anchorEl: event.currentTarget,
                                  date_of_payment_filter: true,
                                })
                                } /> */}
                              </th>
                            }
                            <th nowrap="true" className='text-uppercase ' style={{ position: 'sticky', top: 0, background: '#f7f3f0' }}>Approval</th>
                            <th nowrap="true" className='text-uppercase ' style={{ position: 'sticky', top: 0, background: '#f7f3f0' }}>Approved ON</th>
                            <th nowrap="true" className='text-uppercase ' style={{ position: 'sticky', top: 0, background: '#f7f3f0' }}># Days taken for Approval</th>
                            <th nowrap="true" className='text-uppercase ' style={{ position: 'sticky', top: 0, background: '#f7f3f0' }}>Time Taken For Approval</th>
                            <th nowrap="true" className='text-uppercase ' style={{ position: 'sticky', top: 0, background: '#f7f3f0' }}>Time Taken For Payment</th>

                          </thead>

                          <tbody >

                            {this.state.costing_data?.map((item, index) => item.payments.length > 0 ?
                              item.payments.map((p, idx) => (
                                <tr>
                                  {idx === 0 &&
                                    <td nowrap="true" className="freeze-pane-left" rowSpan={item.payments.length} style={{ color: config.themeColor, cursor: 'pointer', backgroundColor: "#fff7f2", }}

                                    >

                                      {(item.type === "Vendor Advance Payments" || item.type === "Purchase Contract Advance Payments") ?
                                        <a rel="noopener noreferrer" href={item.type === "Vendor Advance Payments" ? '/vendor-advance-view-contract/' + btoa(item.vendor_adv_contract_id) : '/view-purchase-contract/' + btoa(item.purchase_contract_id)} target='_blank' style={{ color: config.themeColor }}>{item.costing_no}</a>

                                        :
                                        <span
                                          onClick={() => this.onClickCosting(item)}
                                          style={{ cursor: 'pointer' }}
                                        >

                                          {item.costing_no}
                                        </span>
                                      }
                                    </td>
                                  }
                                  {idx === 0 &&
                                    <td nowrap="true" rowSpan={item.payments.length}> {item.type}</td>
                                  }
                                  {idx === 0 &&
                                    <td nowrap="true" rowSpan={item.payments.length}>
                                      {item.business_numbers.toString()}
                                    </td>
                                  }
                                  {idx === 0 &&

                                    <td nowrap="true text-uppercase" rowSpan={item.payments.length}>{item.vendor_name}</td>
                                  }{idx === 0 &&
                                    <td nowrap="true" rowSpan={item.payments.length}>{localTimeFormate(item.costing_approved_date)}</td>
                                  }
                                  {idx === 0 &&
                                    <td nowrap="true" className='text-right' rowSpan={item.payments.length}>{toLocaleString(item.payable_to_supplier)}</td>
                                  }
                                  {idx === 0 &&
                                    <td nowrap="true" className='text-right' rowSpan={item.payments.length}>{`${toLocaleString(item.outstanding_amount)} ${config.company === "SRPL" || config.company === "PRPL" || config.company === "AVS" ? "USD" : "IDR"}`}</td>
                                  }
                                  {idx === 0 &&
                                    <td nowrap='true' rowSpan={item.payments.length}>
                                      {(item.approval_status_2 === "Pending") && this.Cookie.getCookie('loginUserId') !== item.costing_created_id && item.costing_approved_by_id !== this.Cookie.getCookie('loginUserId') && (this.state.features.includes(item.approval_access_id_2) || this.state.features.includes('97')) ?
                                        <button className='header_button header_button_text add_button_adjustment' style={{ width: 'auto', top: 0, marginTop: 0 }}
                                          onClick={() => this.approveHandler(item.id, item.type)}>Approve</button>
                                        :
                                        <span className='text-uppercase'>
                                          {item.costing_approved_by_2 ? item.costing_approved_by_2 : "Pending"}
                                        </span>
                                      }
                                    </td>
                                  }
                                  {idx === 0 &&
                                    <td nowrap='true' className='text-uppercase' rowSpan={item.payments.length}>{item.costing_invoice_no}</td>
                                  }
                                  <td nowrap='true'>
                                    {p.payment_seq_id}
                                    {item.costing_approved_by_2 && (parseFloat(item.outstanding_amount) > 1) && item.costing_invoice_no && this.state.activeTabIndex === 0 &&
                                      <p className='mb-0 text-center p-0'>
                                        <Tooltip title="Add Payments" arrow>
                                          <i
                                            className="fa fa-plus-circle"
                                            style={{
                                              color: config.themeColor,
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              this.setState({
                                                paymentsModal: true,
                                                costingDataCopy: item,
                                                invoice_no: item.costing_invoice_no,
                                              });
                                            }}
                                          />
                                        </Tooltip>
                                      </p>
                                    }
                                  </td>
                                  <td nowrap='true' className='text-right'>{`${toLocaleString(p.amount)} ${config.company === "SRPL" || config.company === "PRPL" || config.company === "AVS" ? "USD" : "IDR"}`}</td>

                                  {(this.state.activeTabIndex !== 0 && this.state.activeTabIndex !== 1) &&
                                    <td nowrap='true'>
                                      {p.edit_due_date &&
                                        this.state.activeTabIndex === 2 ? (
                                        <div className="row">
                                          <div className="col-lg-8 p-0">
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                              <DatePicker
                                                autoOk={true}
                                                inputVariant="outlined"
                                                variant="inline"
                                                margin="dense"
                                                fullWidth
                                                format="dd/MM/yyyy"
                                                value={p.payment_due_date1}
                                                onChange={(date) => {
                                                  let date_value = dateFormateToDB(date);
                                                  let costingsDataCopy =
                                                    this.state.costing_data;

                                                  for (
                                                    var i = 0;
                                                    i < costingsDataCopy.length;
                                                    i++
                                                  ) {
                                                    if (
                                                      costingsDataCopy[i].costing_no ===
                                                      item.costing_no
                                                    ) {
                                                      costingsDataCopy[i].payments[idx][
                                                        "payment_due_date1"
                                                      ] = date_value;
                                                    }
                                                  }
                                                  this.setState({
                                                    costing_data: costingsDataCopy,
                                                    costing_data_backup: costingsDataCopy,
                                                  });
                                                }}
                                                InputProps={{
                                                  startAdornment: (
                                                    <InputAdornment position="start">
                                                      <IconButton>
                                                        <EventIcon />
                                                      </IconButton>
                                                    </InputAdornment>
                                                  ),
                                                  className: "p-0",
                                                }}
                                              />
                                            </MuiPickersUtilsProvider>
                                          </div>
                                          <p>
                                            <Tooltip title="Save" arrow>
                                              <i
                                                className="fa fa-check fa-3x edit_icon"
                                                style={{
                                                  color: config.themeColor,
                                                  cursor: "pointer",
                                                  marginTop: 3,
                                                }}
                                                onClick={() => {
                                                  this.update_payment_due_date(
                                                    p.payment_id,
                                                    item.type,
                                                    p.payment_due_date1
                                                  );
                                                }}
                                              />
                                            </Tooltip>
                                          </p>
                                          <p style={{ lineHeight: 3 }}>
                                            <Tooltip title="Cancel" arrow>
                                              <i
                                                className="fa fa-times fa-3x edit_icon"
                                                style={{
                                                  color: config.themeColor,
                                                  cursor: "pointer",
                                                  marginTop: 3,
                                                }}
                                                onClick={() => {
                                                  let costingsDataCopy =
                                                    this.state.costing_data;

                                                  for (
                                                    var i = 0;
                                                    i < costingsDataCopy.length;
                                                    i++
                                                  ) {
                                                    if (
                                                      costingsDataCopy[i].costing_no ===
                                                      item.costing_no
                                                    ) {
                                                      costingsDataCopy[i].payments[idx][
                                                        "edit_due_date"
                                                      ] = false;
                                                    }
                                                  }
                                                  this.setState({
                                                    costing_data: costingsDataCopy,
                                                  });
                                                }}
                                              />
                                            </Tooltip>
                                          </p>
                                        </div>
                                      ) : (
                                        <p>
                                          {localDateFormate(p.payment_due_date)}
                                          {this.state.activeTabIndex === 2 && (
                                            <Tooltip title="Edit" arrow>
                                              <i
                                                className="fa fa-pencil edit_icon"
                                                style={{
                                                  color: config.themeColor,
                                                  cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                  let costingsDataCopy =
                                                    this.state.costing_data;

                                                  for (
                                                    var i = 0;
                                                    i < costingsDataCopy.length;
                                                    i++
                                                  ) {
                                                    if (
                                                      costingsDataCopy[i].costing_no ===
                                                      item.costing_no
                                                    ) {
                                                      costingsDataCopy[i].payments[idx][
                                                        "edit_due_date"
                                                      ] = true;
                                                      costingsDataCopy[i].payments[idx][
                                                        "payment_due_date1"
                                                      ] =
                                                        costingsDataCopy[i].payments[
                                                          idx
                                                        ].payment_due_date;
                                                    }
                                                  }
                                                  this.setState({
                                                    costing_data: costingsDataCopy,
                                                  });
                                                }}
                                              />
                                            </Tooltip>
                                          )}
                                        </p>
                                      )}
                                    </td>
                                  }
                                  {(this.state.activeTabIndex !== 0 && this.state.activeTabIndex !== 1) &&
                                    <td nowrap='true'>
                                      {p.edit && this.state.activeTabIndex === 2 ? (
                                        <div className="row">
                                          <div className="col-lg-8 p-0">
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                              <DatePicker
                                                autoOk={true}
                                                inputVariant="outlined"
                                                variant="inline"
                                                margin="dense"
                                                fullWidth
                                                format="dd/MM/yyyy"
                                                value={p.date_of_payment1}
                                                onChange={(date) => {
                                                  this.editFiledsHandlers(
                                                    dateFormateToDB(date),
                                                    "date_of_payment1",
                                                    item.costing_no,
                                                    idx
                                                  );
                                                }}
                                                InputProps={{
                                                  startAdornment: (
                                                    <InputAdornment position="start">
                                                      <IconButton>
                                                        <EventIcon />
                                                      </IconButton>
                                                    </InputAdornment>
                                                  ),
                                                  className: "p-0",
                                                }}
                                              />
                                            </MuiPickersUtilsProvider>
                                          </div>
                                          <p>
                                            <Tooltip title="Save" arrow>
                                              <i
                                                className="fa fa-check fa-3x edit_icon"
                                                style={{
                                                  color: config.themeColor,
                                                  cursor: "pointer",
                                                  marginTop: 3,
                                                }}
                                                onClick={() => {
                                                  this.update_payment_date(
                                                    p.payment_id,
                                                    item.type,
                                                    p.date_of_payment1
                                                  );
                                                }}
                                              />
                                            </Tooltip>
                                          </p>

                                          <p
                                          >
                                            <Tooltip title="Cancel" arrow>
                                              <i
                                                className="fa fa-times fa-3x edit_icon"
                                                style={{
                                                  color: config.themeColor,
                                                  cursor: "pointer",
                                                  marginTop: 3,
                                                }}
                                                onClick={() => {
                                                  this.editFiledsHandlers(
                                                    null,
                                                    "edit",
                                                    item.costing_no,
                                                    idx
                                                  );
                                                }}
                                              />
                                            </Tooltip>
                                          </p>
                                        </div>
                                      ) : (
                                        <p
                                        >
                                          {localDateFormate(p.date_of_payment)}
                                          {this.state.activeTabIndex === 2 && (
                                            <Tooltip title="Edit" arrow>
                                              <i
                                                className="fa fa-pencil edit_icon"
                                                style={{
                                                  color: config.themeColor,
                                                  cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                  this.editFiledsHandlers(
                                                    true,
                                                    "edit",
                                                    item.costing_no,
                                                    idx
                                                  );
                                                }}
                                              />
                                            </Tooltip>
                                          )}
                                        </p>
                                      )}
                                    </td>
                                  }
                                  <td nowrap='true' style={{ minWidth: 150 }}>
                                    {((p.payment_approval_status === "Pending" && this.Cookie.getCookie("loginUserId") !==
                                      p.payment_created_id) && (this.state.features.includes(
                                        item.payment_access_id_1
                                      ) ||
                                        this.state.features.includes("97"))) ?
                                      <button
                                        className="header_button header_button_text add_button_adjustment"
                                        style={{
                                          width: "auto",
                                          top: 0,
                                          marginTop: 0,
                                          borderRadius: 10,
                                        }}
                                        onClick={() =>
                                          this.approvePaymentsHandler(
                                            p.payment_id,
                                            p.payment_type
                                          )
                                        }
                                      >
                                        Approve
                                      </button>
                                      :
                                      <p className="mb-1">
                                        {p.payment_approved_by
                                          ? p.payment_approved_by
                                          : "Pending"}
                                      </p>
                                    }
                                  </td>
                                  <td nowrap='true'>{localDateFormate(p.payment_approved_date)}</td>
                                  {(this.state.activeTabIndex !== 0 || this.state.activeTabIndex !== 1) ?
                                    <td nowrap='true'>{this.timeTakenForApprovalHandler(
                                      item.costing_approved_date,
                                      dateFormateToDB(new Date())
                                    )}
                                    </td>
                                    :
                                    <td></td>
                                  }
                                  {item.costing_approved_date &&
                                    p.payment_approval_status === "Approved" &&
                                    p.payment_approved_date ?
                                    <td nowrap='true'>
                                      {this.timeTakenForApprovalHandler(
                                        item.costing_approved_date,
                                        p.payment_approved_date
                                      )}
                                    </td>
                                    :
                                    <td></td>
                                  }
                                  <td nowrap='true' className='text-center'>
                                    {this.getColor(p)}
                                  </td>
                                </tr>
                              ))
                              :
                              <tr>
                                <td nowrap="true" className="freeze-pane-left" style={{ color: config.themeColor, cursor: 'pointer', backgroundColor: "#fff7f2", }}
                                >
                                  {(item.type === "Vendor Advance Payments" || item.type === "Purchase Contract Advance Payments") ?
                                    <a rel="noopener noreferrer" href={item.type === "Vendor Advance Payments" ? '/vendor-advance-view-contract/' + btoa(item.vendor_adv_contract_id) : '/view-purchase-contract/' + btoa(item.purchase_contract_id)} target='_blank' style={{ color: config.themeColor }}>{item.costing_no}</a>

                                    :
                                    <span
                                      style={{ color: config.themeColor }}
                                      onClick={() => this.onClickCosting(item)}
                                    >

                                      {item.costing_no}
                                    </span>
                                  }
                                </td>
                                <td nowrap="true"> {item.type}</td>
                                <td nowrap="true">
                                  {item.business_numbers.toString()}
                                </td>
                                <td nowrap="true text-uppercase">{item.vendor_name}</td>
                                <td nowrap="true">{localTimeFormate(item.costing_approved_date)}</td>
                                <td nowrap="true" className='text-right'>{toLocaleString(item.payable_to_supplier)}</td>
                                <td nowrap="true" className='text-right'>{`${toLocaleString(item.outstanding_amount)} ${config.company === "SRPL" || config.company === "PRPL" || config.company === "AVS" ? "USD" : "IDR"}`}</td>
                                <td nowrap='true'>
                                  {(item.approval_status_2 === "Pending") && this.Cookie.getCookie('loginUserId') !== item.costing_created_id && item.costing_approved_by_id !== this.Cookie.getCookie('loginUserId') && (this.state.features.includes(item.approval_access_id_2) || this.state.features.includes('97')) ?
                                    <button className='header_button header_button_text add_button_adjustment' style={{ width: 'auto', top: 0, marginTop: 0 }}
                                      onClick={() => this.approveHandler(item.id, item.type)}>Approve</button>
                                    :
                                    <span className='text-uppercase'>
                                      {item.costing_approved_by_2 ? item.costing_approved_by_2 : "Pending"}
                                    </span>
                                  }
                                </td>
                                <td nowrap='true' className='text-uppercase'>{item.costing_invoice_no}</td>
                                <td nowrap='true'>
                                  {item.costing_approved_by_2 && item.costing_invoice_no &&


                                    <p className="mb-1 pt-1"> {`No Payments Created`} &nbsp;
                                      <Tooltip title="Add Payments" arrow>
                                        <i
                                          className="fa fa-plus-circle"
                                          style={{
                                            color: config.themeColor,
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            this.setState({
                                              paymentsModal: true,
                                              costingDataCopy: item,
                                              invoice_no: item.costing_invoice_no,
                                            });
                                          }}
                                        />
                                      </Tooltip>
                                    </p>
                                  }


                                </td>
                                <td nowrap='true'></td>
                                <td nowrap='true'></td>
                                <td nowrap='true'></td>
                                <td nowrap='true'></td>
                                {(this.state.activeTabIndex !== 0 && this.state.activeTabIndex !== 1) &&
                                  <td nowrap='true'></td>
                                }
                                {(this.state.activeTabIndex !== 0 && this.state.activeTabIndex !== 1) &&
                                  <td nowrap='true'></td>
                                }
                                <td nowrap='true'></td>
                                <td nowrap='true'></td>

                              </tr>
                            )}

                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                }

                {this.state.mainTabIndex === 1 &&
                  <>
                    <br />
                    <AccountReceivables
                      onClickCosting={(item) => this.onClickCosting(item)}
                      approveHandler={(id, type) => this.approveHandler(id, type)}
                      addPayments={(item) => this.setState({
                        paymentsModal: true,
                        costingDataCopy: item,
                        invoice_no: item.costing_invoice_no,
                      })}
                    />
                  </>
                }

              </div>

            )}
          </div>
        </div>

        <Popper
          open={
            this.state.openQuantityAllocation
          }
          anchorEl={this.state.anchorEl}
          placement={"bottom"}
          onClose={() => this.setState({ openQuantityAllocation: false, })}
          modifiers={{
            flip: {
              enabled: true,
            },
            arrow: {
              enabled: true,
              // element: arrowRef,
            },
          }}
          style={{ width: 350 }}
        >
          <Paper>
            <div className="col-lg-12 mb-4 mt-2 p-2">
              <label className="popper_label mb-1">
                Costing Type
              </label>
              <i
                className="fa fa-times mr-2"
                style={{
                  fontSize: 14,
                  float: "right",
                  color: config.themeColor,
                  cursor: "pointer",
                }}
                onClick={(l) => {
                  this.setState({
                    openQuantityAllocation: false,
                  });
                }}
              ></i>
              <TextField
                name="filter_costing_type_value"
                placeholder="Value"
                margin="dense"
                variant="outlined"
                type="text"
                select
                onChange={(e) => {
                  this.setState({
                    filter_costing_type_value: e.target.value
                  })
                }}
                value={
                  this.state.filter_costing_type_value
                }
                InputProps={{
                  style: {
                    fontFamily: "Poppins",
                    fontSize: "14px",
                  },
                }}
                autoComplete="off"
                className="col"
              >

                <MenuItem value={"All"}>All</MenuItem>
                {this.state.payments_data?.filter((el, i, a) => a.findIndex((element) => element.type === el.type) === i).map((item, index) => (
                  <MenuItem value={item.type}>{item.type}</MenuItem>
                ))}

              </TextField>
              {/* <span className="please-enter-the-increase-quantity-number">
                Please enter the difference
                Quantity
              </span> */}
              <div className="col-lg-12 pl-0 mt-4 text-center">
                <button
                  type="button"
                  className="drawer_button drawer_text mr-3"
                  onClick={() => {
                    const apply_filter = this.state.filter_costing_type_value !== "All" ? this.state.costing_data_backup.filter((e) => e.type === this.state.filter_costing_type_value) : this.state.costing_data_backup;
                    this.setState({
                      costing_data: apply_filter,
                      openQuantityAllocation: false,
                    })
                  }}
                >
                  Apply
                </button>
              </div>
            </div>
          </Paper>
        </Popper>
        <Popper
          open={
            this.state.vendorFilter
          }
          anchorEl={this.state.anchorEl}
          placement={"bottom"}
          onClose={() => this.setState({ vendorFilter: false, })}
          modifiers={{
            flip: {
              enabled: true,
            },
            arrow: {
              enabled: true,
              // element: arrowRef,
            },
          }}
          style={{ width: 350 }}
        >
          <Paper>
            <div className="col-lg-12 mb-4 mt-2 p-2">
              <i
                className="fa fa-times mr-2"
                style={{
                  fontSize: 14,
                  float: "right",
                  color: config.themeColor,
                  cursor: "pointer",
                }}
                onClick={(l) => {
                  this.setState({
                    vendorFilter: false,
                  });
                }}
              ></i>
              <Autocomplete
                multiple
                options={this.state.vendors}
                getOptionLabel={(option) => option.vendor_name}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <CheckBox
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.vendor_name}
                    {/* {option.name} {option.mother_vessel_name ? "( " + option.mother_vessel_name + " )" : null} */}
                  </React.Fragment>
                )}
                onChange={(event, value) => {
                  if (value) {
                    var data = [];
                    for (var i of value) {
                      data.push(i.vendor_name);
                    }
                    //var floating_crane_vendor = data.toString();
                    this.setState({
                      selected_vendors: data,
                      vendor_drop_value: value,
                    });
                  } else {
                    this.setState({
                      selected_vendors: [],
                    });
                  }
                }}
                disableCloseOnSelect
                name="Business_Number"
                size="small"
                limitTags={1}
                value={this.state.vendor_drop_value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Vendor"
                    label="Vendor"
                    variant="outlined"
                    style={{ top: 8 }}
                    fullWidth
                  />
                )}
                style={{ display: "contents" }}
              />
              {/* <span className="please-enter-the-increase-quantity-number">
                Please enter the difference
                Quantity
              </span> */}
              <div className="col-lg-12 pl-0 mt-4 text-center">
                <button
                  type="button"
                  className="drawer_button drawer_text mr-3"
                  onClick={this.applyFiltersHandler}
                >
                  Apply
                </button>
              </div>
            </div>
          </Paper>
        </Popper>
        <Popper
          open={
            this.state.payment_due_date_filter
          }
          anchorEl={this.state.anchorEl}
          placement={"bottom"}
          onClose={() => this.setState({ payment_due_date_filter: false, })}
          modifiers={{
            flip: {
              enabled: true,
            },
            arrow: {
              enabled: true,
              // element: arrowRef,
            },
          }}
          style={{ width: 350 }}
        >
          <Paper>
            <div className="col-lg-12 mb-4 mt-2 p-2">
              <i
                className="fa fa-times mr-2"
                style={{
                  fontSize: 14,
                  float: "right",
                  color: config.themeColor,
                  cursor: "pointer",
                }}
                onClick={(l) => {
                  this.setState({
                    payment_due_date_filter: false,
                  });
                }}
              />
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  autoOk={true}
                  inputVariant="outlined"
                  variant="inline"
                  margin="dense"
                  fullWidth
                  format="dd/MM/yyyy"
                  value={this.state.payment_due_date_value}
                  onChange={(date) => {
                    this.setState({
                      payment_due_date_value: dateFormateToDB(date)
                    });
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton>
                          <EventIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                    className: "p-0",
                  }}
                />
              </MuiPickersUtilsProvider>
              <div className="col-lg-12 pl-0 mt-4 text-center">
                <button
                  type="button"
                  className="drawer_button drawer_text mr-3"
                  onClick={this.applyFiltersHandler}
                >
                  Apply
                </button>
              </div>
            </div>
          </Paper>
        </Popper>
        <Popper
          open={
            this.state.date_of_payment_filter
          }
          anchorEl={this.state.anchorEl}
          placement={"bottom"}
          onClose={() => this.setState({ date_of_payment_filter: false, })}
          modifiers={{
            flip: {
              enabled: true,
            },
            arrow: {
              enabled: true,
              // element: arrowRef,
            },
          }}
          style={{ width: 350 }}
        >
          <Paper>
            <div className="col-lg-12 mb-4 mt-2 p-2">
              <i
                className="fa fa-times mr-2"
                style={{
                  fontSize: 14,
                  float: "right",
                  color: config.themeColor,
                  cursor: "pointer",
                }}
                onClick={(l) => {
                  this.setState({
                    date_of_payment_filter: false,
                  });
                }}
              />
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  autoOk={true}
                  inputVariant="outlined"
                  variant="inline"
                  margin="dense"
                  fullWidth
                  format="dd/MM/yyyy"
                  value={this.state.date_of_payment_value}
                  onChange={(date) => {

                    this.setState({
                      date_of_payment_value: dateFormateToDB(date)
                    });
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton>
                          <EventIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                    className: "p-0",
                  }}
                />
              </MuiPickersUtilsProvider>
              <div className="col-lg-12 pl-0 mt-4 text-center">
                <button
                  type="button"
                  className="drawer_button drawer_text mr-3"
                  onClick={this.applyFiltersHandler}
                >
                  Apply
                </button>
              </div>
            </div>
          </Paper>
        </Popper>
      </div>
    );
  }
}