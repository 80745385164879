import React, { Component } from 'react';
import CookieHandler from '../common/CookieHandler';
import config from '../../config/config';
import ExpenditureLevel from './ExpenditureLevel';
import AccountsLevel from './AccountsLevel';
import CostingCenterLevel from './CostingCenterLevel';

import { Button } from "@material-ui/core";

export default class ExpenditureHistory extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      activeTabIndex: 0
    }
  }

  render() {
    return (
      <div>
        <div className="row mb-2">
          <Button
            className="tab_button"
            onClick={(e, value) => {
              this.setState({
                activeTabIndex: 0
              });
            }}
            style={{
              textTransform: 'capitalize', 
              margin: '5px 0px',
              padding: '5px 20px', 
              // borderRadius: 35, 
              height: 30, 
              color: this.state.activeTabIndex === 0 ? config.themeColor : '#483b34b3', 
              backgroundColor: this.state.activeTabIndex === 0 ? '#fff7f2' : '#fff', 
              fontSize: '14px', 
              border: '0px' 
            }}
          >
            Expenditure Groups
          </Button>
          <Button
            className="tab_button"
            onClick={(e, value) => {
              this.setState({
                activeTabIndex: 1
              });
            }}
            style={{
              textTransform: 'capitalize', 
              margin: '5px 0px',
              padding: '5px 20px', 
              // borderRadius: 35, 
              height: 30, 
              color: this.state.activeTabIndex === 1 ? config.themeColor : '#483b34b3', 
              backgroundColor: this.state.activeTabIndex === 1 ? '#fff7f2' : '#fff', 
              fontSize: '14px', 
              border: '0px' 
            }}
          >
            Accounts
          </Button>
          <Button
            className="tab_button"
            onClick={(e, value) => {
              this.setState({
                activeTabIndex: 2
              });
            }}
            style={{
              textTransform: 'capitalize', 
              margin: '5px 0px',
              padding: '5px 20px', 
              // borderRadius: 35, 
              height: 30, 
              color: this.state.activeTabIndex === 2 ? config.themeColor : '#483b34b3', 
              backgroundColor: this.state.activeTabIndex === 2 ? '#fff7f2' : '#fff', 
              fontSize: '14px', 
              border: '0px' 
            }}
          >
            Cost Center
          </Button>

        </div>
        {this.state.activeTabIndex === 0 &&
          <ExpenditureLevel />
        }
        {this.state.activeTabIndex === 1 &&
          <AccountsLevel />
        }
        {this.state.activeTabIndex === 2 &&
          <CostingCenterLevel />
        }
      </div>
    )
  }
}