import React, { Component } from "react";
import CookieHandler from "../common/CookieHandler";
import config from "../../config/config";
import api from "../../api/api";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import EventIcon from "@material-ui/icons/Event";
import DateFnsUtils from "@date-io/date-fns";
import {
  TextField,
  InputAdornment,
  IconButton,
  Snackbar,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { localDateFormate, pad } from "../common/common";
import InvoiceForm from "../common/InvoiceForm";
import PaymentPostings from "../common/PaymentPostings";
import Loader from "../common/Loader";

export default class BuyerDemurrageView extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      activeStep: 0,
      selectedBargeId: null,

      dmr_to_buyer_allowed_for_discharge: "",
      dmr_to_buyer_barge_demurrage: "",
      dmr_to_buyer_barge_currency: "0",
      dmr_to_buyer_demurrage_in_days: "",
      dmr_to_buyer_demurrage_value: "",

      dmr_from_buyer_time_allowed_for_discharge: "",
      dmr_from_buyer_barge_detention: "",
      dmr_from_buyer_detention_in_days: "",
      dmr_from_buyer_detention_value: "",
      dmr_from_buyer_barge_currency: "0",

      dmr_to_buyer_currency_xchg_rate: "",
      dmr_to_buyer_pph23_account: "0",
      dmr_to_buyer_base_price: "",
      dmr_to_buyer_vat_percent: "10",
      dmr_to_buyer_vat_value: "",
      dmr_to_buyer_pph23_percent: "",
      dmr_to_buyer_pph23_value: "",
      dmr_to_buyer_total: "",
      dmr_to_buyer_ctc: "",
      dmr_to_buyer_ctc_in_local_currency: "",
      dmr_from_buyer_currency_xchg_rate: "",
      dmr_from_buyer_pph23_account: "0",
      dmr_from_buyer_base_price: "",
      dmr_from_buyer_vat_percent: "10",
      dmr_from_buyer_vat_value: "",
      dmr_from_buyer_pph23_percent: "",
      dmr_from_buyer_pph23_value: "",
      dmr_from_buyer_total: "",
      dmr_from_buyer_ctc: "",
      dmr_from_buyer_ctc_in_local_currency: "",

      isLoading: true,
      totalBarges: [],
      invoiceDetails: {
        invoice_no: "",
        invoice_date: null,
        ppn: "",
        ppn_date: null,
      },
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (
      !(
        features.includes("52") ||
        features.includes("150") ||
        features.includes("410") ||
        features.includes("461") ||
        features.includes("97")
      )
    ) {
      window.location.href = "/dashboard";
    }
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();

    const business_no_id = this.props.businessNo;
    //Get businessNumber API based on businessNumber
    await api
      .getBusinessNumber(business_no_id, loginUserID, idToken)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.business_number) {
                this.setState({
                  businessNo: res.business_number.business_no
                    ? res.business_number.business_no
                    : "",
                  mother_vessel_name: res.business_number.mother_vessel_name
                    ? res.business_number.mother_vessel_name
                    : "",
                  //isLoading: false
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
    const demurrage_type = this.props.demurrage_type;
    const comboID = this.props.comboID;
    const demurrage_to_buyer_id = comboID.split("$#")[0];
    const demurrage_from_buyer_id = comboID.split("$#")[0];
    if (demurrage_type === "company_to_buyer") {
      api
        .get_barge_demurrage_to_buyer(
          loginUserID,
          idToken,
          demurrage_to_buyer_id
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                if (res.barge_demurrage_to_buyer.length > 0) {
                  let days = "0";
                  let hours = "0";
                  let minutes = "0";
                  if (res.barge_demurrage_to_buyer[0].time_at_anchorage_days) {
                    days =
                      res.barge_demurrage_to_buyer[0].time_at_anchorage_days;
                  }
                  if (res.barge_demurrage_to_buyer[0].time_at_anchorage_hours) {
                    hours =
                      res.barge_demurrage_to_buyer[0].time_at_anchorage_hours;
                  }
                  if (
                    res.barge_demurrage_to_buyer[0].time_at_anchorage_minutes
                  ) {
                    minutes =
                      res.barge_demurrage_to_buyer[0].time_at_anchorage_minutes;
                  }
                  var dmr_to_buyer_total_paid = 0;
                  var dmr_to_buyer_remaining_amount = 0;
                  for (var i of res.barge_demurrage_to_buyer[0]
                    .dmr_to_buyer_payment_postings) {
                    dmr_to_buyer_total_paid += Number(i.amount);
                  }

                  dmr_to_buyer_remaining_amount =
                    Number(
                      res.barge_demurrage_to_buyer[0].dmr_to_buyer_total
                        ? res.barge_demurrage_to_buyer[0].dmr_to_buyer_total
                        : 0
                    ) - Number(dmr_to_buyer_total_paid);
                  let invoice_details = {
                    ...this.state.invoiceDetails,
                    invoice_no: res.barge_demurrage_to_buyer[0].invoice_no,
                    invoice_date: res.barge_demurrage_to_buyer[0].invoice_date,
                    ppn: res.barge_demurrage_to_buyer[0].ppn,
                    ppn_date: res.barge_demurrage_to_buyer[0].ppn_date,
                  };
                  this.setState({
                    invoiceDetails: invoice_details,
                    dmr_to_buyer_remaining_amount:
                      dmr_to_buyer_remaining_amount,
                    dmr_to_buyer_total_paid: dmr_to_buyer_total_paid,
                    remainingAmount: dmr_to_buyer_remaining_amount,

                    costing_no: res.barge_demurrage_to_buyer[0].costing_no,

                    created_by: res.barge_demurrage_to_buyer[0].created_by,
                    approval_status: res.barge_demurrage_to_buyer[0]
                      .approval_status
                      ? res.barge_demurrage_to_buyer[0].approval_status
                      : "Pending",
                    approved_by: res.barge_demurrage_to_buyer[0].approved_by,
                    approved_by_name:
                      res.barge_demurrage_to_buyer[0].approved_by_name,
                    approved_date:
                      res.barge_demurrage_to_buyer[0].approved_date,

                    dmr_to_buyer_allowed_for_discharge:
                      res.barge_demurrage_to_buyer[0]
                        .dmr_to_buyer_allowed_for_discharge,
                    dmr_to_buyer_barge_currency:
                      res.barge_demurrage_to_buyer[0]
                        .dmr_to_buyer_barge_currency,
                    dmr_to_buyer_barge_demurrage:
                      res.barge_demurrage_to_buyer[0]
                        .dmr_to_buyer_barge_demurrage,
                    dmr_to_buyer_base_price:
                      res.barge_demurrage_to_buyer[0].dmr_to_buyer_base_price,
                    dmr_to_buyer_ctc:
                      res.barge_demurrage_to_buyer[0].dmr_to_buyer_ctc,
                    dmr_to_buyer_ctc_in_local_currency:
                      res.barge_demurrage_to_buyer[0]
                        .dmr_to_buyer_ctc_in_local_currency,
                    dmr_to_buyer_currency_xchg_rate:
                      res.barge_demurrage_to_buyer[0]
                        .dmr_to_buyer_currency_xchg_rate,
                    dmr_to_buyer_demurrage_in_days:
                      res.barge_demurrage_to_buyer[0]
                        .dmr_to_buyer_demurrage_in_days,
                    dmr_to_buyer_demurrage_value:
                      res.barge_demurrage_to_buyer[0]
                        .dmr_to_buyer_demurrage_value,
                    dmr_to_buyer_pph23_account:
                      res.barge_demurrage_to_buyer[0]
                        .dmr_to_buyer_pph23_account,
                    dmr_to_buyer_pph23_percent:
                      res.barge_demurrage_to_buyer[0]
                        .dmr_to_buyer_pph23_percent,
                    dmr_to_buyer_pph23_value:
                      res.barge_demurrage_to_buyer[0].dmr_to_buyer_pph23_value,
                    dmr_to_buyer_total:
                      res.barge_demurrage_to_buyer[0].dmr_to_buyer_total,
                    dmr_to_buyer_vat_percent:
                      res.barge_demurrage_to_buyer[0].dmr_to_buyer_vat_percent,
                    dmr_to_buyer_vat_value:
                      res.barge_demurrage_to_buyer[0].dmr_to_buyer_vat_value,
                    NORAnchorageDate:
                      res.barge_demurrage_to_buyer[0].nor_anchorage,
                    completionDischargeDate:
                      res.barge_demurrage_to_buyer[0].completion_discharge,
                    dmr_to_buyer_payment_postings:
                      res.barge_demurrage_to_buyer[0]
                        .dmr_to_buyer_payment_postings,
                    timeAtAnchorage:
                      days +
                      " days, " +
                      hours +
                      " hours, " +
                      minutes +
                      " minutes",
                    timeAtAnchorageDays: days,
                    timeAtAnchorageHours: hours,
                    timeAtAnchorageMinutes: minutes,
                    isLoading: false,
                  });
                } else {
                  this.setState({
                    isLoading: false,
                  });
                }
              } else if (res.code === "601") {
                this.setState({
                  snackBarErrorOpen: true,
                  errorMsg: res.message,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    } else if (demurrage_type === "buyer_to_company") {
      api
        .get_barge_demurrage_from_buyer(
          loginUserID,
          idToken,
          demurrage_from_buyer_id
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                if (res.barge_demurrage_from_buyer.length > 0) {
                  let days = "0";
                  let hours = "0";
                  let minutes = "0";
                  if (
                    res.barge_demurrage_from_buyer[0].time_at_anchorage_days
                  ) {
                    days =
                      res.barge_demurrage_from_buyer[0].time_at_anchorage_days;
                  }
                  if (
                    res.barge_demurrage_from_buyer[0].time_at_anchorage_hours
                  ) {
                    hours =
                      res.barge_demurrage_to_buyer[0].time_at_anchorage_hours;
                  }
                  if (
                    res.barge_demurrage_from_buyer[0].time_at_anchorage_minutes
                  ) {
                    minutes =
                      res.barge_demurrage_from_buyer[0]
                        .time_at_anchorage_minutes;
                  }
                  var dmr_from_buyer_total_paid = 0;
                  var dmr_from_buyer_remaining_amount = 0;
                  for (var i of res.barge_demurrage_from_buyer[0]
                    .dmr_from_buyer_payment_postings) {
                    dmr_from_buyer_total_paid += Number(i.amount);
                  }

                  dmr_from_buyer_remaining_amount =
                    Number(
                      res.barge_demurrage_from_buyer[0].dmr_from_buyer_total
                        ? res.barge_demurrage_from_buyer[0].dmr_from_buyer_total
                        : 0
                    ) - Number(dmr_from_buyer_total_paid);
                  let invoice_details = {
                    ...this.state.invoiceDetails,
                    invoice_no: res.barge_demurrage_from_buyer[0].invoice_no,
                    invoice_date:
                      res.barge_demurrage_from_buyer[0].invoice_date,
                    ppn: res.barge_demurrage_from_buyer[0].ppn,
                    ppn_date: res.barge_demurrage_from_buyer[0].ppn_date,
                  };
                  this.setState({
                    invoiceDetails: invoice_details,
                    dmr_from_buyer_remaining_amount:
                      dmr_from_buyer_remaining_amount,
                    dmr_from_buyer_total_paid: dmr_from_buyer_total_paid,
                    remainingAmount: dmr_from_buyer_remaining_amount,

                    costing_no: res.barge_demurrage_from_buyer[0].costing_no,

                    created_by: res.barge_demurrage_from_buyer[0].created_by,
                    approval_status: res.barge_demurrage_from_buyer[0]
                      .approval_status
                      ? res.barge_demurrage_from_buyer[0].approval_status
                      : "Pending",
                    approved_by: res.barge_demurrage_from_buyer[0].approved_by,
                    approved_by_name:
                      res.barge_demurrage_from_buyer[0].approved_by_name,
                    approved_date:
                      res.barge_demurrage_from_buyer[0].approved_date,

                    dmr_from_buyer_time_allowed_for_discharge:
                      res.barge_demurrage_from_buyer[0]
                        .dmr_from_buyer_time_allowed_for_discharge,
                    dmr_from_buyer_barge_detention:
                      res.barge_demurrage_from_buyer[0]
                        .dmr_from_buyer_barge_detention,
                    dmr_from_buyer_detention_in_days:
                      res.barge_demurrage_from_buyer[0]
                        .dmr_from_buyer_detention_in_days,
                    dmr_from_buyer_detention_value:
                      res.barge_demurrage_from_buyer[0]
                        .dmr_from_buyer_detention_value,
                    dmr_from_buyer_barge_currency:
                      res.barge_demurrage_from_buyer[0]
                        .dmr_from_buyer_barge_currency,
                    dmr_from_buyer_currency_xchg_rate:
                      res.barge_demurrage_from_buyer[0]
                        .dmr_to_buyer_ctc_in_local_currency,
                    dmr_to_buyer_currency_xchg_rate:
                      res.barge_demurrage_from_buyer[0]
                        .dmr_to_buyer_currency_xchg_rate,
                    dmr_from_buyer_pph23_account:
                      res.barge_demurrage_from_buyer[0]
                        .dmr_from_buyer_pph23_account,
                    dmr_from_buyer_base_price:
                      res.barge_demurrage_from_buyer[0]
                        .dmr_from_buyer_base_price,
                    dmr_from_buyer_vat_percent:
                      res.barge_demurrage_from_buyer[0]
                        .dmr_from_buyer_vat_percent,
                    dmr_from_buyer_vat_value:
                      res.barge_demurrage_from_buyer[0]
                        .dmr_from_buyer_vat_value,
                    dmr_from_buyer_pph23_percent:
                      res.barge_demurrage_from_buyer[0]
                        .dmr_from_buyer_pph23_percent,
                    dmr_from_buyer_pph23_value:
                      res.barge_demurrage_from_buyer[0]
                        .dmr_from_buyer_pph23_value,
                    dmr_from_buyer_total:
                      res.barge_demurrage_from_buyer[0].dmr_from_buyer_total,
                    dmr_from_buyer_ctc:
                      res.barge_demurrage_from_buyer[0].dmr_from_buyer_ctc,
                    dmr_from_buyer_ctc_in_local_currency:
                      res.barge_demurrage_from_buyer[0]
                        .dmr_from_buyer_ctc_in_local_currency,
                    NORAnchorageDate:
                      res.barge_demurrage_from_buyer[0].nor_anchorage,
                    completionDischargeDate:
                      res.barge_demurrage_from_buyer[0].completion_discharge,
                    dmr_from_buyer_payment_postings:
                      res.barge_demurrage_from_buyer[0]
                        .dmr_from_buyer_payment_postings,
                    timeAtAnchorage:
                      days +
                      " days, " +
                      hours +
                      " hours, " +
                      minutes +
                      " minutes",
                    timeAtAnchorageDays: days,
                    timeAtAnchorageHours: hours,
                    timeAtAnchorageMinutes: minutes,
                    isLoading: false,
                  });
                } else {
                  this.setState({
                    isLoading: false,
                  });
                }
              } else if (res.code === "601") {
                this.setState({
                  snackBarErrorOpen: true,
                  errorMsg: res.message,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    }
  }

  AddPaymentPostingHandler = () => {
    this.setState({ submitting: true });
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    var {
      payment_posting_type,
      amount,
      date_of_payment,
      invoice_no,
      description,
    } = this.state;
    if (date_of_payment === null) {
      this.setState({
        date_of_paymentError: true,
        submitting: false,
      });
    }
    if (amount === "") {
      this.setState({ amountError: true, submitting: false });
    }
    if (invoice_no === "") {
      this.setState({ invoice_noError: true, submitting: false });
    }

    var remainingAmount = 0;
    remainingAmount = Number(this.state.remainingAmount);
    if (this.state.paymentMode === "Update") {
      remainingAmount =
        Number(this.state.remainingAmount) + Number(this.state.existingAmount);
    }

    if (Number(amount) > Math.ceil(Number(remainingAmount))) {
      this.setState({
        amountError: true,
        submitting: false,
        paymentErrorMsg: "Amount is exceeding to Payable to Buyer value",
      });
      return;
    }

    if (amount !== "" && invoice_no !== "") {
      let demurrage_type = this.props.demurrage_type;
      let comboID = this.props.comboID;
      const barge_to_buyer_demurrage_id = comboID.split("$#")[0];
      const barge_from_buyer_demurrage_id = comboID.split("$#")[0];
      if (demurrage_type === "company_to_buyer") {
        api
          .add_barge_dmr_to_buyer_payments(
            loginUserID,
            idToken,
            barge_to_buyer_demurrage_id,
            payment_posting_type,
            amount,
            date_of_payment,
            invoice_no,
            description
          )
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              response.json().then((res) => {
                if (res.code === "200") {
                  this.setState(
                    {
                      submitting: false,
                      date_of_payment: null,
                      invoice_no: "",
                      description: "",
                      date_of_paymentError: false,
                      amountError: false,
                      invoice_noError: false,
                      snackBarSuccessOpen: true,
                      successMsg: res.message,
                    },
                    () => {
                      window.$("#paymentPostingsModal").modal("hide");
                      this.componentDidMount();
                    }
                  );
                } else if (res.code === "601") {
                  this.setState({
                    errorMsg: res.message,
                    snackBarErrorOpen: true,
                  });
                } else if (res.code === "607") {
                  window.location.href = "/logout";
                }
              });
            }
          });
      } else if (demurrage_type === "buyer_to_company") {
        api
          .add_barge_dmr_from_buyer_payments(
            loginUserID,
            idToken,
            barge_from_buyer_demurrage_id,
            payment_posting_type,
            amount,
            date_of_payment,
            invoice_no,
            description
          )
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              response.json().then((res) => {
                if (res.code === "200") {
                  this.setState(
                    {
                      submitting: false,
                      date_of_payment: null,
                      invoice_no: "",
                      description: "",
                      date_of_paymentError: false,
                      amountError: false,
                      invoice_noError: false,
                      snackBarSuccessOpen: true,
                      successMsg: res.message,
                    },
                    () => {
                      window.$("#paymentPostingsModal").modal("hide");
                      this.componentDidMount();
                    }
                  );
                } else if (res.code === "601") {
                  this.setState({
                    errorMsg: res.message,
                    snackBarErrorOpen: true,
                  });
                } else if (res.code === "607") {
                  window.location.href = "/logout";
                }
              });
            }
          });
      }
    } else {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please fill mandatory fields.",
      });
    }
  };

  UpdatePaymentPostingsHandler = () => {
    this.setState({ submitting: true });
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    var {
      barge_to_buyer_payment_id,
      barge_from_buyer_payment_id,
      payment_posting_type,
      amount,
      date_of_payment,
      invoice_no,
      description,
    } = this.state;
    if (date_of_payment === null) {
      this.setState({
        date_of_paymentError: true,
        submitting: false,
      });
    }
    if (amount === "") {
      this.setState({ amountError: true, submitting: false });
    }
    if (invoice_no === "") {
      this.setState({ invoice_noError: true, submitting: false });
    }
    var remainingAmount = 0;
    remainingAmount = Number(this.state.remainingAmount);
    if (this.state.paymentMode === "Update") {
      remainingAmount =
        Number(this.state.remainingAmount) + Number(this.state.existingAmount);
    }

    if (Number(amount) > Math.ceil(Number(remainingAmount))) {
      this.setState({
        amountError: true,
        submitting: false,
        paymentErrorMsg: "Amount is exceeding to Payable to Buyer value",
      });
      return;
    }

    if (amount !== "" && invoice_no !== "") {
      let status = "Active";
      let comboID = this.props.comboID;
      let demurrage_type = this.props.demurrage_type;

      const barge_to_buyer_demurrage_id = comboID.split("$#")[0];
      const barge_from_buyer_demurrage_id = comboID.split("$#")[0];
      if (demurrage_type === "company_to_buyer") {
        api
          .update_barge_dmr_to_buyer_payments(
            loginUserID,
            idToken,
            barge_to_buyer_demurrage_id,
            barge_to_buyer_payment_id,
            payment_posting_type,
            amount,
            date_of_payment,
            invoice_no,
            description,
            status
          )
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              response.json().then((res) => {
                if (res.code === "200") {
                  this.setState(
                    {
                      submitting: false,
                      date_of_payment: null,
                      invoice_no: "",
                      description: "",
                      date_of_paymentError: false,
                      amountError: false,
                      invoice_noError: false,
                      snackBarSuccessOpen: true,
                      successMsg: res.message,
                    },
                    () => {
                      window.$("#paymentPostingsModal").modal("hide");
                      this.componentDidMount();
                    }
                  );
                } else if (res.code === "601") {
                  this.setState({
                    errorMsg: res.message,
                    snackBarErrorOpen: true,
                  });
                } else if (res.code === "607") {
                  window.location.href = "/logout";
                }
              });
            }
          });
      } else if (demurrage_type === "buyer_to_company") {
        api
          .update_barge_dmr_from_buyer_payments(
            loginUserID,
            idToken,
            barge_from_buyer_demurrage_id,
            barge_from_buyer_payment_id,
            payment_posting_type,
            amount,
            date_of_payment,
            invoice_no,
            description,
            status
          )
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              response.json().then((res) => {
                if (res.code === "200") {
                  this.setState(
                    {
                      submitting: false,
                      date_of_payment: null,
                      invoice_no: "",
                      description: "",
                      date_of_paymentError: false,
                      amountError: false,
                      invoice_noError: false,
                      snackBarSuccessOpen: true,
                      successMsg: res.message,
                    },
                    () => {
                      window.$("#paymentPostingsModal").modal("hide");
                      this.componentDidMount();
                    }
                  );
                } else if (res.code === "601") {
                  this.setState({
                    errorMsg: res.message,
                    snackBarErrorOpen: true,
                  });
                } else if (res.code === "607") {
                  window.location.href = "/logout";
                }
              });
            }
          });
      }
    } else {
      alert("Please fill mandatory fields.");
    }
  };

  getPaymentDetalis = (e) => {
    if (e) {
      // let paymentTitle = '';
      // if (this.props.demurrage_type === 'company_to_buyer') {
      //   paymentTitle = config.company + " to buyer";
      // } else {
      //   paymentTitle = "buyer to " + config.company;
      // }
      this.setState({
        paymentMode: "Update",
        //paymentTitle: "Update " + paymentTitle,
        paymentTitle: "Update ",
        barge_to_buyer_payment_id: e.id,
        barge_from_buyer_payment_id: e.id,
        date_of_payment: e.date_of_payment,
        amount: e.amount,
        existingAmount: e.amount,
        invoice_no: e.invoice_no,
        payment_posting_type: e.payment_posting_type,
        description: e.description,
      });
      window.$("#paymentPostingsModal").modal({
        backdrop: "static",
        keyboard: true,
        show: true,
      });
    }
  };

  toLocaleString(value) {
    return value ? Number(value).toLocaleString() : 0;
  }

  approveHandler = () => {
    this.setState({ isLoading: true });
    let comboID = this.props.comboID;
    let request = {
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
      costing_id: comboID.split("$#")[0],
      costing_type:
        this.props.demurrage_type === "company_to_buyer"
          ? "Barge Demurrage To Buyer"
          : "Barge Demurrage From buyer",
      approval_status: "Approved",
    };
    api.approve_costing_api(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.setState(
              {
                snackBarSuccessOpen: true,
                successMsg: res.message,
              },
              () => this.componentDidMount()
            );
          } else if (res.code === "601") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
  };

  approvePaymentsHandler = (id) => {
    this.setState({ isLoading: true });

    let request = {
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
      payment_id: id,
      costing_type:
        this.props.demurrage_type === "company_to_buyer"
          ? "barge_dmr_to_buyer_payment_postings_data"
          : "barge_dmr_from_buyer_payment_postings_data",
      approval_status: "Approved",
    };
    api.approve_payment_postings_api(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.setState(
              {
                snackBarSuccessOpen: true,
                successMsg: res.message,
              },
              () => this.componentDidMount()
            );
          } else if (res.code === "601") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
  };

  updateInvoice = (data) => {
    let request = {
      ...data,
      table_name:
        this.props.demurrage_type === "company_to_buyer"
          ? "barge_demurrage_to_buyer"
          : "barge_demurrage_from_buyer",
    };
    api.update_invoice_details(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.componentDidMount();
            this.setState({
              invoiceDialog: false,
            });
          }
        });
      }
    });
  };

  renderPaymentPostings = (data, payment_type) => {
    const {
      id,
      sac_charges_payment = [],
      remaining_amount = 0,
      sac_sampling_currency_xchg_rate = 1,
      approval_status = "Pending",
      invoiceDetails:{invoice_no = null}
    } = data;
    let payment_related_data = {
      paymentTitle:
        this.props.demurrage_type === "company_to_buyer"
          ? "Barge Demurrage To Buyer"
          : "Barge Demurrage From buyer",
      payment_posting_type: payment_type,
      invoice_no: invoice_no,
      outstanding_amount: remaining_amount,
      billing_currency:
        config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL" ? "USD" : "IDR",
      currency_xchg_rate: sac_sampling_currency_xchg_rate
        ? sac_sampling_currency_xchg_rate
        : 1,
      costing_id: id,
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
    };

    let access_details = {
      costing_approval: approval_status,
      add_payment: "154",
      view_payment: "155",
      update_payment: "156",
      delete_payment: "157",
      approver: "396",
      approver2: "446",
    };

    return (
      <PaymentPostings
        payments={sac_charges_payment}
        costingType={
          this.props.demurrage_type === "company_to_buyer"
            ? "Barge Demurrage To Buyer"
            : "Barge Demurrage From buyer"
        }
        access_details={access_details}
        data={payment_related_data}
        callbackFn={() => {
          this.componentDidMount();
        }}
      />
    );
  };

  render() {
    const comboID = this.props.comboID;
    const costing_id = comboID.split("$#")[0];
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div
          className="container bg-white p-0"
          style={{ minHeight: "100vh", width: 850 }}
        >
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <div className="clearfix content-header border-bottom">
            <div className="row">
              <div className="col-lg-10 text-left pl-0">
                <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                  {`View Barge Demurrage - ${this.state.costing_no}`}
                  <span
                    className={
                      this.state.approval_status === "Pending"
                        ? "type_2"
                        : "type_1"
                    }
                    style={{ padding: 8, marginLeft: 16 }}
                  >
                    {this.state.approval_status}
                  </span>
                </h4>
              </div>
              {this.state.approval_status === "Pending" &&
                this.Cookie.getCookie("loginUserId") !==
                  this.state.created_by &&
                this.state.approved_by !==
                  this.Cookie.getCookie("loginUserId") &&
                (this.state.features.includes("410") ||
                  this.state.features.includes("461") ||
                  this.state.features.includes("97")) && (
                  <div
                    className="col text-right float-sm-left"
                    style={{ padding: "5px 20px" }}
                  >
                    <button
                      className="header_button header_button_text add_button_adjustment"
                      style={{ width: "auto", marginTop: "17px" }}
                      onClick={this.approveHandler}
                    >
                      Approve
                    </button>
                  </div>
                )}
            </div>
          </div>
          <div className="drawer-section">
            <div className="card p-3">
              {this.props.demurrageData && (
                <div className="row">
                  <div className="table-responsive">
                    <table className="table table-sm table-bordered">
                      <thead className="table-header">
                        <tr>
                          <th nowrap="true">Barge ID</th>
                          <th nowrap="true">Barge Nomination</th>
                          <th nowrap="true">Buyer</th>
                          <th nowrap="true" className="text-right">
                            {this.props.demurrage_type === "company_to_buyer"
                              ? "Total Payable"
                              : "Receivable"}
                          </th>
                          <th nowrap="true" className="text-right">
                            {this.props.demurrage_type === "company_to_buyer"
                              ? "Paid"
                              : "Received"}
                          </th>
                          <th nowrap="true" className="text-right">
                            Remaining
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{pad(this.props.demurrageData.barge_id)}</td>
                          <td nowrap="true">
                            {this.props.demurrageData.barge_nomination}
                          </td>
                          <td nowrap="true">
                            {this.props.demurrageData.buyer_name
                              ? this.props.demurrageData.buyer_name
                              : "-"}
                          </td>
                          <td className="text-right" nowrap="true">
                            {this.toLocaleString(
                              this.props.demurrageData.total_payable
                            )}
                          </td>
                          <td className="text-right" nowrap="true">
                            {this.toLocaleString(this.props.demurrageData.paid)}
                          </td>
                          <td className="text-right" nowrap="true">
                            {this.toLocaleString(
                              this.props.demurrageData.Remaining_amount
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
              <div
                className="card-body p-0"
                style={{ backgroundColor: "rgba(25,118,210,.01)" }}
                id="accordion"
              >
                {this.state.activeStep === 0 && (
                  <div>
                    {this.props.demurrage_type === "buyer_to_company" && (
                      <div>
                        <div className="row mb-3">
                          <div className="col-lg-4 p-0 mb-3">
                            <label className="contract_display_header_label">
                              Approval{" "}
                              {this.state.approval_status === "Pending"
                                ? ""
                                : "Approved By"}
                            </label>
                            <div className="contract_display_header_value">
                              {" "}
                              {this.state.approval_status === "Pending"
                                ? this.state.approval_status
                                : this.state.approved_by_name}
                            </div>
                          </div>

                          <div className="col-lg-4 p-0 mb-3">
                            <label className="contract_display_header_label">
                              Approved On
                            </label>
                            <div className="contract_display_header_value">
                              {" "}
                              {this.state.approved_date
                                ? localDateFormate(this.state.approved_date)
                                : "-"}
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="row mb-3">
                          <div className="col-lg-4 p-0 mb-1">
                            <label className="contract_display_header_label mb-1">
                              NOR Anchorage
                            </label>
                            <div className="contract_display_header_value">
                              {this.state.NORAnchorageDate
                                ? localDateFormate(this.state.NORAnchorageDate)
                                : "-"}
                            </div>
                          </div>
                          <div className="col-lg-4 p-0 mb-1">
                            <label className="contract_display_header_label mb-1">
                              Completion Discharge
                            </label>
                            <div className="contract_display_header_value">
                              {this.state.completionDischargeDate
                                ? localDateFormate(
                                    this.state.completionDischargeDate
                                  )
                                : "-"}
                            </div>
                          </div>
                          <div className="col-lg-4 p-0 mb-1">
                            <label className="contract_display_header_label mb-1">
                              Time at Anchorage
                            </label>
                            <div className="contract_display_header_value">
                              {this.state.timeAtAnchorage}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-5 pl-0">
                            <div className="row">
                              <div className="col-lg-12 p-0">
                                <label className="contract_display_header_label mb-0">
                                  Time allowed for Discharge
                                </label>
                                <div className="contract_display_header_value">
                                  {this.state
                                    .dmr_from_buyer_time_allowed_for_discharge
                                    ? this.state
                                        .dmr_from_buyer_time_allowed_for_discharge
                                    : "-"}
                                </div>
                              </div>
                              <div className="col-lg-12 p-0">
                                <label className="contract_display_header_label mb-0">
                                  Barge Detention
                                </label>
                                <div className="contract_display_header_value">
                                  {this.toLocaleString(
                                    this.state.dmr_to_buyer_barge_demurrage
                                  )}{" "}
                                  {this.state.dmr_from_buyer_barge_currency}
                                </div>
                              </div>
                              <div className="col-lg-12 p-0">
                                <label className="contract_display_header_label mb-0">
                                  Detention in days
                                </label>
                                <div className="contract_display_header_value">
                                  {this.state.dmr_from_buyer_detention_in_days
                                    ? this.state
                                        .dmr_from_buyer_detention_in_days
                                    : "-"}{" "}
                                  USD
                                </div>
                              </div>

                              {(config.company === "SRPL" || config.company === "AVS"  ||
                                config.company === "PRPL") &&
                                this.state.dmr_from_buyer_barge_currency !==
                                  "USD" &&
                                this.state.dmr_from_buyer_barge_currency && (
                                  <div className="col-lg-12 p-0">
                                    <label className="contract_display_header_label mb-0">
                                      Exchange rate USD to{" "}
                                      {this.state.dmr_from_buyer_barge_currency}
                                    </label>
                                    <div className="contract_display_header_value">
                                      {this.toLocaleString(
                                        this.state
                                          .dmr_from_buyer_currency_xchg_rate
                                      )}
                                    </div>
                                  </div>
                                )}
                              {!(
                                config.company === "SRPL" || config.company === "AVS"  ||
                                config.company === "PRPL"
                              ) &&
                                this.state.dmr_from_buyer_barge_currency !==
                                  "IDR" &&
                                this.state.dmr_from_buyer_barge_currency !==
                                  "0" && (
                                  <div className="col-lg-12 p-0">
                                    <label className="contract_display_header_label mb-0">
                                      Exchange rate{" "}
                                      {this.state.dmr_from_buyer_barge_currency}{" "}
                                      to IDR
                                    </label>
                                    <div className="contract_display_header_value">
                                      {this.toLocaleString(
                                        this.state
                                          .dmr_from_buyer_currency_xchg_rate
                                      )}
                                    </div>
                                  </div>
                                )}
                              <div className="col-lg-12 p-0">
                                <label className="contract_display_header_label mb-0">
                                  Detention incurred
                                </label>
                                <div className="contract_display_header_value">
                                  {this.toLocaleString(
                                    this.state.dmr_from_buyer_detention_value
                                  )}
                                </div>
                              </div>
                              {!(
                                config.company === "SRPL" || config.company === "AVS"  ||
                                config.company === "PRPL"
                              ) && (
                                <div className="col-lg-12 p-0">
                                  <label className="contract_display_header_label mb-0">
                                    PPH23 Account
                                  </label>
                                  <div className="contract_display_header_value">
                                    {this.state.dmr_from_buyer_pph23_account}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div
                            className="col-lg-7 p-2 rounded"
                            style={{ backgroundColor: "#f7f3f0" }}
                          >
                            <div className="row">
                              <div className="row col-lg-12 p-0">
                                <h6>Actual Detention</h6>
                              </div>
                              {(config.company === "SRPL" || config.company === "AVS"  ||
                                config.company === "PRPL" ||
                                ((config.company === "SDAM" ||
                                  config.company === "SRK" ||
                                  config.company === "BTR") &&
                                  this.state.dmr_from_buyer_pph23_account ===
                                    "Buyer")) && (
                                <div className="row">
                                  <div className="col-lg-6 mb-2">
                                    <label
                                      className="contract_display_header_label"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Base price
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {this.state.dmr_from_buyer_base_price
                                        ? this.toLocaleString(
                                            this.state.dmr_from_buyer_base_price
                                          )
                                        : "-"}
                                    </div>
                                  </div>
                                  <div className="col-lg-6 mb-2">
                                    <label
                                      className="contract_display_header_label"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      VAT %
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {this.state.dmr_from_buyer_vat_percent
                                        ? this.toLocaleString(
                                            this.state
                                              .dmr_from_buyer_vat_percent
                                          )
                                        : "-"}
                                    </div>
                                  </div>

                                  <div className="col-lg-6 mb-2">
                                    <label
                                      className="contract_display_header_label"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      VAT
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {this.state.dmr_from_buyer_vat_value
                                        ? this.toLocaleString(
                                            this.state.dmr_from_buyer_vat_value
                                          )
                                        : "-"}
                                    </div>
                                  </div>
                                  {!(
                                    config.company === "SRPL" || config.company === "AVS"  ||
                                    config.company === "PRPL"
                                  ) &&
                                    this.state.dmr_to_buyer_pph23_account ===
                                      "Buyer" && (
                                      <div className="col-lg-6 mb-2">
                                        <label
                                          className="contract_display_header_label"
                                          style={{
                                            color: "rgba(72, 59, 52, 0.7)",
                                          }}
                                        >
                                          Includes PPH23 @{" "}
                                          {this.state
                                            .dmr_from_buyer_pph23_percent
                                            ? this.toLocaleString(
                                                this.state
                                                  .dmr_from_buyer_pph23_percent
                                              )
                                            : 0}
                                          %
                                        </label>
                                        <div
                                          className="contract_display_header_value"
                                          style={{ color: "#42352d" }}
                                        >
                                          {this.state.dmr_from_buyer_pph23_value
                                            ? this.toLocaleString(
                                                this.state
                                                  .dmr_from_buyer_pph23_value
                                              )
                                            : null}
                                        </div>
                                      </div>
                                    )}
                                  <div className="col-lg-6 ">
                                    <label
                                      className="contract_display_header_label"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Receivable
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {this.state.dmr_from_buyer_total
                                        ? this.toLocaleString(
                                            this.state.dmr_from_buyer_total
                                          )
                                        : 0}{" "}
                                      {config.company === "SRPL" || config.company === "AVS"  ||
                                      config.company === "PRPL"
                                        ? "USD"
                                        : "IDR"}
                                    </div>
                                  </div>
                                  <div className="col-lg-6 ">
                                    <label
                                      className="contract_display_header_label"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Cost to Company
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {this.state.dmr_from_buyer_ctc
                                        ? this.toLocaleString(
                                            this.state.dmr_from_buyer_ctc
                                          )
                                        : 0}{" "}
                                      {config.company === "SRPL" || config.company === "AVS"  ||
                                      config.company === "PRPL"
                                        ? "USD"
                                        : "IDR"}
                                    </div>
                                  </div>
                                </div>
                              )}

                              {this.state.dmr_to_buyer_pph23_account ===
                                "SDAM" && (
                                <div className="row">
                                  <div className="col-lg-6 mb-2">
                                    <label
                                      className="contract_display_header_label"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Base price
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {this.state.dmr_from_buyer_base_price
                                        ? this.toLocaleString(
                                            this.state.dmr_from_buyer_base_price
                                          )
                                        : "-"}
                                    </div>
                                  </div>

                                  <div className="col-lg-6 mb-2">
                                    <label
                                      className="contract_display_header_label"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Includes PPH23 @{" "}
                                      {this.state.dmr_from_buyer_pph23_percent
                                        ? this.toLocaleString(
                                            this.state
                                              .dmr_from_buyer_pph23_percent
                                          )
                                        : 0}
                                      %
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {this.state.dmr_from_buyer_pph23_value
                                        ? this.toLocaleString(
                                            this.state
                                              .dmr_from_buyer_pph23_value
                                          )
                                        : null}
                                    </div>
                                  </div>
                                  <div className="col-lg-6 ">
                                    <label
                                      className="contract_display_header_label"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Receivable
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {this.state.dmr_from_buyer_total
                                        ? this.toLocaleString(
                                            this.state.dmr_from_buyer_total
                                          )
                                        : 0}{" "}
                                      {config.company === "SRPL" || config.company === "AVS"  ||
                                      config.company === "PRPL"
                                        ? "USD"
                                        : "IDR"}
                                    </div>
                                  </div>
                                  <div className="col-lg-6 ">
                                    <label
                                      className="contract_display_header_label"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Cost to Company
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {this.state.dmr_from_buyer_ctc
                                        ? this.toLocaleString(
                                            this.state.dmr_from_buyer_ctc
                                          )
                                        : 0}{" "}
                                      {config.company === "SRPL" || config.company === "AVS"  ||
                                      config.company === "PRPL"
                                        ? "USD"
                                        : "IDR"}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="card p-0 border mt-3">
                          <div className="card-header section_header">
                            <h5>Payments</h5>
                          </div>
                          <div className="card-body">
                            <InvoiceForm
                              open={false}
                              costingID={costing_id}
                              data={this.state.invoiceDetails}
                              callbackFn={(data) => this.updateInvoice(data)}
                            />
                            <div className="row">
                              <div className="col-lg-4 mb-3">
                                <label className="contract_display_header_label">
                                  Total Receivable Amount
                                </label>
                                <div className="contract_display_header_value">
                                  {this.state.dmr_from_buyer_total
                                    ? this.toLocaleString(
                                        this.state.dmr_from_buyer_total
                                      )
                                    : 0}{" "}
                                  {config.company === "SRPL" || config.company === "AVS"  ||
                                  config.company === "PRPL"
                                    ? "USD"
                                    : "IDR"}
                                </div>
                              </div>
                              <div className="col-lg-4 mb-3">
                                <label className="contract_display_header_label">
                                  Amount Received
                                </label>
                                <div className="contract_display_header_value">
                                  {this.state.dmr_from_buyer_total_paid
                                    ? this.toLocaleString(
                                        this.state.dmr_from_buyer_total_paid
                                      )
                                    : 0}{" "}
                                  {config.company === "SRPL" || config.company === "AVS"  ||
                                  config.company === "PRPL"
                                    ? "USD"
                                    : "IDR"}
                                </div>
                              </div>
                              <div className="col-lg-4 mb-3">
                                <label className="contract_display_header_label">
                                  Remaining Amount
                                </label>
                                <div className="contract_display_header_value">
                                  {this.state.remainingAmount <= 0
                                    ? 0
                                    : this.toLocaleString(
                                        this.state.remainingAmount
                                      )}{" "}
                                  {config.company === "SRPL" || config.company === "AVS"  ||
                                  config.company === "PRPL"
                                    ? "USD"
                                    : "IDR"}
                                </div>
                              </div>
                            </div>
                            {(this.state.features.includes("155") ||
                              this.state.features.includes("97")) && (
                              <div>
                                {this.renderPaymentPostings(
                                  this.state,
                                  "buyer_to_company"
                                )}
                                {/* <div className="row">
                                  <div className="col-lg-12">
                                    <div className="clearfix">
                                      <div className="float-left">
                                        {!this.state
                                          .dmr_from_buyer_payment_postings
                                          ? ""
                                          : this.state
                                              .dmr_from_buyer_payment_postings
                                              .length <= 0 && (
                                              <p>No payments made</p>
                                            )}
                                      </div>
                                      <div className="float-right">
                                        {(this.state.features.includes("262") >=
                                          0 ||
                                          this.state.features.includes("97") >=
                                            0) &&
                                          this.state.invoiceDetails
                                            ?.invoice_no && (
                                            <button
                                              className="btn payment_button"
                                              data-toggle="modal"
                                              data-placement="bottom"
                                              data-keyboard="false"
                                              data-target="#paymentPostingsModal"
                                              data-backdrop="static"
                                              onClick={() => {
                                                this.setState({
                                                  paymentTitle: "Add",
                                                  payment_posting_type:
                                                    "buyer_to_company",
                                                  remainingAmount:
                                                    this.state
                                                      .dmr_from_buyer_remaining_amount,
                                                  date_of_payment: null,
                                                  amount: "",
                                                  invoice_no: "",
                                                  description: "",
                                                  paymentErrorMsg: "",
                                                  date_of_paymentError: false,
                                                  amountError: false,
                                                  paymentMode: "Add",
                                                  invoice_noError: false,
                                                });
                                              }}
                                            >
                                              Add Payment
                                            </button>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {!this.state.dmr_from_buyer_payment_postings
                                  ? ""
                                  : this.state.dmr_from_buyer_payment_postings
                                      .length > 0 && (
                                      <div className="row mt-2">
                                        <div className="col-lg table-responsive">
                                          <table className="table table-borderless border-0">
                                            <thead className=" border-0">
                                              <tr>
                                                <th
                                                  nowrap="true"
                                                  className="table_header_barge_label border-0"
                                                >
                                                  Date Received
                                                </th>
                                                <th
                                                  nowrap="true"
                                                  className="table_header_barge_label  border-0 text-right"
                                                >
                                                  Amount
                                                </th>
                                                <th
                                                  nowrap="true"
                                                  className="table_header_barge_label border-0"
                                                >
                                                  Invoice No
                                                </th>
                                                <th
                                                  className="table_header_barge_label border-0"
                                                  style={{
                                                    width: 300,
                                                    maxWidth: 250,
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  Description
                                                </th>
                                                <th
                                                  nowrap="true"
                                                  className="table_header_barge_label border-0"
                                                >
                                                  Created Date
                                                </th>
                                                <th
                                                  nowrap="true"
                                                  className="table_header_barge_label border-0"
                                                >
                                                  Action
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {this.state.dmr_from_buyer_payment_postings.map(
                                                (v, index) => (
                                                  <tr key={index}>
                                                    <td nowrap="true">
                                                      {v.date_of_payment
                                                        ? localDateFormate(
                                                            v.date_of_payment
                                                          )
                                                        : null}
                                                    </td>
                                                    <td
                                                      nowrap="true"
                                                      className="text-right"
                                                    >
                                                      {v.amount
                                                        ? this.toLocaleString(
                                                            v.amount
                                                          )
                                                        : ""}
                                                    </td>
                                                    <td nowrap="true">
                                                      {v.invoice_no}
                                                    </td>
                                                    <td
                                                      style={{
                                                        whiteSpace: "pre-line",
                                                      }}
                                                    >
                                                      {v.description}
                                                    </td>
                                                    <td nowrap="true">
                                                      {v.created_date
                                                        ? localDateFormate(
                                                            v.created_date
                                                          )
                                                        : null}
                                                    </td>

                                                    <td nowrap="true">
                                                      <Tooltip
                                                        title="Edit"
                                                        arrow
                                                      >
                                                        <i
                                                          className="fa fa-pencil mr-2"
                                                          style={{
                                                            fontSize: 20,
                                                            color:
                                                              config.themeColor,
                                                            cursor: "pointer",
                                                          }}
                                                          data-toggle="modal"
                                                          title="Edit Coal Payment Posting"
                                                          data-placement="bottom"
                                                          onClick={() => {
                                                            this.setState({
                                                              remainingAmount:
                                                                this.state
                                                                  .dmr_from_buyer_remaining_amount,
                                                            });
                                                            this.getPaymentDetalis(
                                                              v
                                                            );
                                                          }}
                                                        />
                                                      </Tooltip>
                                                      {(this.state.features.includes(
                                                        "265"
                                                      )  ||
                                                        this.state.features.includes(
                                                          "97"
                                                        ) ) &&
                                                        v.approval_status ===
                                                          "Pending" && (
                                                          <DeletePayments
                                                            data={v}
                                                            table={""}
                                                            callbackFn={() =>
                                                              this.componentDidMount()
                                                            }
                                                          />
                                                        )}
                                                    </td>
                                                  </tr>
                                                )
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    )} */}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    {this.props.demurrage_type === "company_to_buyer" && (
                      <div>
                        <div className="row mb-3">
                          <div className="col-lg-4 p-0 mb-3">
                            <label className="contract_display_header_label">
                              1Approval{" "}
                              {this.state.approval_status === "Pending"
                                ? ""
                                : "Approved By"}
                            </label>
                            <div className="contract_display_header_value">
                              {" "}
                              {this.state.approval_status === "Pending"
                                ? this.state.approval_status
                                : this.state.approved_by_name}
                            </div>
                          </div>

                          <div className="col-lg-4 p-0 mb-3">
                            <label className="contract_display_header_label">
                              Approved On
                            </label>
                            <div className="contract_display_header_value">
                              {" "}
                              {this.state.approved_date
                                ? localDateFormate(this.state.approved_date)
                                : "-"}
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="row mb-3">
                          <div className="col-lg-4 p-0 mb-1">
                            <label className="contract_display_header_label mb-1">
                              NOR Anchorage
                            </label>
                            <div className="contract_display_header_value">
                              {this.state.NORAnchorageDate
                                ? localDateFormate(this.state.NORAnchorageDate)
                                : "-"}
                            </div>
                          </div>
                          <div className="col-lg-4 p-0 mb-1">
                            <label className="contract_display_header_label mb-1">
                              Completion Discharge
                            </label>
                            <div className="contract_display_header_value">
                              {this.state.completionDischargeDate
                                ? localDateFormate(
                                    this.state.completionDischargeDate
                                  )
                                : "-"}
                            </div>
                          </div>
                          <div className="col-lg-4 p-0 mb-1">
                            <label className="contract_display_header_label mb-1">
                              Time at Anchorage
                            </label>
                            <div className="contract_display_header_value">
                              {this.state.timeAtAnchorage}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-5 pl-0">
                            <div className="row">
                              <div className="col-lg-12 p-0">
                                <label className="contract_display_header_label mb-0">
                                  Time allowed for Discharge
                                </label>
                                <div className="contract_display_header_value">
                                  {this.state.dmr_to_buyer_allowed_for_discharge
                                    ? this.state
                                        .dmr_to_buyer_allowed_for_discharge
                                    : "-"}
                                </div>
                              </div>
                              <div className="col-lg-12 p-0">
                                <label className="contract_display_header_label mb-0">
                                  Barge Demurrage
                                </label>
                                <div className="contract_display_header_value">
                                  {this.state.dmr_to_buyer_barge_demurrage
                                    ? this.toLocaleString(
                                        this.state.dmr_to_buyer_barge_demurrage
                                      )
                                    : "-"}{" "}
                                  {this.state.dmr_to_buyer_barge_currency}
                                </div>
                              </div>
                              <div className="col-lg-12 p-0 mb-1">
                                <label className="contract_display_header_label mb-0">
                                  Detention in days
                                </label>
                                <div className="contract_display_header_value">
                                  {this.state.dmr_to_buyer_demurrage_in_days}
                                </div>
                              </div>

                              {(config.company === "SRPL" || config.company === "AVS"  ||
                                config.company === "PRPL") &&
                                this.state.dmr_to_buyer_barge_currency !==
                                  "USD" &&
                                this.state.dmr_to_buyer_barge_currency !==
                                  "0" && (
                                  <div className="col-lg-12 p-0">
                                    <label className="contract_display_header_label mb-0">
                                      Exchange rate USD to{" "}
                                      {this.state.dmr_to_buyer_barge_currency}
                                    </label>
                                    <div className="contract_display_header_value">
                                      {this.state
                                        .dmr_to_buyer_currency_xchg_rate
                                        ? this.toLocaleString(
                                            this.state
                                              .dmr_to_buyer_currency_xchg_rate
                                          )
                                        : "-"}
                                    </div>
                                  </div>
                                )}
                              {!(
                                config.company === "SRPL" || config.company === "AVS"  ||
                                config.company === "PRPL"
                              ) &&
                                this.state.dmr_to_buyer_barge_currency !==
                                  "IDR" &&
                                this.state.dmr_to_buyer_barge_currency !==
                                  "0" && (
                                  <div className="col-lg-12 p-0">
                                    <label className="contract_display_header_label mb-0">
                                      Exchange rate{" "}
                                      {this.state.dmr_to_buyer_barge_currency}{" "}
                                      to IDR
                                    </label>
                                    <div className="contract_display_header_value">
                                      {this.state
                                        .dmr_to_buyer_currency_xchg_rate
                                        ? this.toLocaleString(
                                            this.state
                                              .dmr_to_buyer_currency_xchg_rate
                                          )
                                        : "-"}
                                    </div>
                                  </div>
                                )}
                              <div className="col-lg-12 p-0">
                                <label className="contract_display_header_label mb-0">
                                  Demurrage incurred
                                </label>
                                <div className="contract_display_header_value">
                                  {this.state.dmr_to_buyer_demurrage_value
                                    ? this.toLocaleString(
                                        this.state.dmr_to_buyer_demurrage_value
                                      )
                                    : "-"}
                                </div>
                              </div>
                              {!(
                                config.company === "SRPL" || config.company === "AVS"  ||
                                config.company === "PRPL"
                              ) && (
                                <div className="col-lg-12 p-0">
                                  <label className="contract_display_header_label mb-0">
                                    PPH23 Account
                                  </label>
                                  <div className="contract_display_header_value">
                                    {this.state.dmr_to_buyer_pph23_account}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div
                            className="col-lg-7 p-2 rounded"
                            style={{ backgroundColor: "#f7f3f0" }}
                          >
                            <div className="row">
                              <div className="row col-lg-12 p-0">
                                <h6>Actual Demurrage</h6>
                              </div>
                              {(config.company === "SRPL" || config.company === "AVS"  ||
                                config.company === "PRPL" ||
                                ((config.company === "SDAM" ||
                                  config.company === "SRK" ||
                                  config.company === "BTR") &&
                                  this.state.dmr_to_buyer_pph23_account ===
                                    "Buyer")) && (
                                <div className="row">
                                  <div className="col-lg-6 mb-2">
                                    <label
                                      className="contract_display_header_label"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Base price
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {this.state.dmr_to_buyer_base_price
                                        ? this.toLocaleString(
                                            this.state.dmr_to_buyer_base_price
                                          )
                                        : "-"}
                                    </div>
                                  </div>
                                  <div className="col-lg-6 mb-2">
                                    <label
                                      className="contract_display_header_label"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      VAT %
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {this.state.dmr_to_buyer_vat_percent
                                        ? this.state.dmr_to_buyer_vat_percent
                                        : "-"}
                                    </div>
                                  </div>

                                  <div className="col-lg-6 mb-2">
                                    <label
                                      className="contract_display_header_label"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      VAT
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {this.state.dmr_to_buyer_vat_value
                                        ? this.toLocaleString(
                                            this.state.dmr_to_buyer_vat_value
                                          )
                                        : "-"}
                                    </div>
                                  </div>
                                  {!(
                                    config.company === "SRPL" || config.company === "AVS"  ||
                                    config.company === "PRPL"
                                  ) &&
                                    this.state.dmr_to_buyer_pph23_account ===
                                      "Buyer" && (
                                      <div className="col-lg-6 mb-2">
                                        <label
                                          className="contract_display_header_label"
                                          style={{
                                            color: "rgba(72, 59, 52, 0.7)",
                                          }}
                                        >
                                          Includes PPH23 @{" "}
                                          {this.state.dmr_to_buyer_pph23_percent
                                            ? this.toLocaleString(
                                                this.state
                                                  .dmr_to_buyer_pph23_percent
                                              )
                                            : 0}
                                          %
                                        </label>
                                        <div
                                          className="contract_display_header_value"
                                          style={{ color: "#42352d" }}
                                        >
                                          {this.state.dmr_to_buyer_pph23_value
                                            ? this.toLocaleString(
                                                this.state
                                                  .dmr_to_buyer_pph23_value
                                              )
                                            : null}
                                        </div>
                                      </div>
                                    )}
                                  <div className="col-lg-6 ">
                                    <label
                                      className="contract_display_header_label"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Payable
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {this.state.dmr_to_buyer_total
                                        ? this.toLocaleString(
                                            this.state.dmr_to_buyer_total
                                          )
                                        : 0}{" "}
                                      {config.company === "SRPL" || config.company === "AVS"  ||
                                      config.company === "PRPL"
                                        ? "USD"
                                        : "IDR"}
                                    </div>
                                  </div>
                                  <div className="col-lg-6 ">
                                    <label
                                      className="contract_display_header_label"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Cost to Company
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {this.state.dmr_to_buyer_ctc
                                        ? this.toLocaleString(
                                            this.state.dmr_to_buyer_ctc
                                          )
                                        : 0}{" "}
                                      {config.company === "SRPL" || config.company === "AVS"  ||
                                      config.company === "PRPL"
                                        ? "USD"
                                        : "IDR"}
                                    </div>
                                  </div>
                                </div>
                              )}

                              {this.state.dmr_to_buyer_pph23_account ===
                                "SDAM" && (
                                <div className="row">
                                  <div className="col-lg-6 mb-2">
                                    <label
                                      className="contract_display_header_label"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Base price
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {this.state.dmr_to_buyer_base_price
                                        ? this.toLocaleString(
                                            this.state.dmr_to_buyer_base_price
                                          )
                                        : "-"}
                                    </div>
                                  </div>
                                  <div className="col-lg-6 mb-2">
                                    <label
                                      className="contract_display_header_label"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Includes PPH23 @{" "}
                                      {this.state.dmr_to_buyer_pph23_percent
                                        ? this.toLocaleString(
                                            this.state
                                              .dmr_to_buyer_pph23_percent
                                          )
                                        : 0}
                                      %
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {this.state.dmr_to_buyer_pph23_value
                                        ? this.toLocaleString(
                                            this.state.dmr_to_buyer_pph23_value
                                          )
                                        : null}
                                    </div>
                                  </div>
                                  <div className="col-lg-6 ">
                                    <label
                                      className="contract_display_header_label"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Payable to Buyer
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {this.state.dmr_to_buyer_total
                                        ? this.toLocaleString(
                                            this.state.dmr_to_buyer_total
                                          )
                                        : 0}{" "}
                                      {config.company === "SRPL" || config.company === "AVS"  ||
                                      config.company === "PRPL"
                                        ? "USD"
                                        : "IDR"}
                                    </div>
                                  </div>
                                  <div className="col-lg-6 ">
                                    <label
                                      className="contract_display_header_label"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Cost to Company
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {this.state.dmr_to_buyer_ctc
                                        ? this.toLocaleString(
                                            this.state.dmr_to_buyer_ctc
                                          )
                                        : 0}{" "}
                                      {config.company === "SRPL" || config.company === "AVS"  ||
                                      config.company === "PRPL"
                                        ? "USD"
                                        : "IDR"}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="card p-0 border mt-3">
                          <div className="card-header section_header">
                            <h5>Payments</h5>
                          </div>
                          <div className="card-body">
                            <InvoiceForm
                              open={false}
                              data={this.state.invoiceDetails}
                              costingID={costing_id}
                              callbackFn={(data) => this.updateInvoice(data)}
                            />
                            <div className="row">
                              <div className="col-lg-4 mb-3">
                                <label className="contract_display_header_label">
                                  Total Payable Amount
                                </label>
                                <div className="contract_display_header_value">
                                  {this.state.dmr_to_buyer_total
                                    ? this.toLocaleString(
                                        this.state.dmr_to_buyer_total
                                      )
                                    : 0}{" "}
                                  {config.company === "SRPL" || config.company === "AVS"  ||
                                  config.company === "PRPL"
                                    ? "USD"
                                    : "IDR"}
                                </div>
                              </div>
                              <div className="col-lg-4 mb-3">
                                <label className="contract_display_header_label">
                                  Amount Paid
                                </label>
                                <div className="contract_display_header_value">
                                  {this.state.dmr_to_buyer_total_paid
                                    ? this.toLocaleString(
                                        this.state.dmr_to_buyer_total_paid
                                      )
                                    : 0}{" "}
                                  {config.company === "SRPL" || config.company === "AVS"  ||
                                  config.company === "PRPL"
                                    ? "USD"
                                    : "IDR"}
                                </div>
                              </div>
                              <div className="col-lg-4 mb-3">
                                <label className="contract_display_header_label">
                                  Remaining Amount
                                </label>
                                <div className="contract_display_header_value">
                                  {this.state.remainingAmount <= 0
                                    ? 0
                                    : this.toLocaleString(
                                        this.state.remainingAmount
                                      )}{" "}
                                  {config.company === "SRPL" || config.company === "AVS"  ||
                                  config.company === "PRPL"
                                    ? "USD"
                                    : "IDR"}
                                </div>
                              </div>
                            </div>
                            {(this.state.features.includes("155") ||
                              this.state.features.includes("97")) && (
                              <div>
                                {this.renderPaymentPostings(
                                  this.state,
                                  "company_to_buyer"
                                )}
                                {/* <div className="row">
                                  <div className="col-lg-12">
                                    <div className="clearfix">
                                      <div className="float-left">
                                        {!this.state
                                          .dmr_to_buyer_payment_postings
                                          ? ""
                                          : this.state
                                              .dmr_to_buyer_payment_postings
                                              .length <= 0 && (
                                              <p>No payments made</p>
                                            )}
                                      </div>
                                      <div className="float-right">
                                        {(this.state.features.includes("262") >=
                                          0 ||
                                          this.state.features.includes("97") >=
                                            0) &&
                                          this.state.invoiceDetails
                                            ?.invoice_no && (
                                            <button
                                              className="btn payment_button"
                                              data-toggle="modal"
                                              data-placement="bottom"
                                              data-keyboard="false"
                                              data-target="#paymentPostingsModal"
                                              data-backdrop="static"
                                              onClick={() => {
                                                this.setState({
                                                  paymentTitle: "Add ",
                                                  payment_posting_type:
                                                    "company_to_buyer",
                                                  remainingAmount:
                                                    this.state
                                                      .dmr_to_buyer_remaining_amount,
                                                  date_of_payment: null,
                                                  amount: "",
                                                  invoice_no: "",
                                                  description: "",
                                                  paymentErrorMsg: "",
                                                  date_of_paymentError: false,
                                                  amountError: false,
                                                  paymentMode: "Add",
                                                  invoice_noError: false,
                                                });
                                              }}
                                            >
                                              Add Payment
                                            </button>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {!this.state.dmr_to_buyer_payment_postings
                                  ? ""
                                  : this.state.dmr_to_buyer_payment_postings
                                      .length > 0 && (
                                      <div className="row mt-2">
                                        <div className="col-lg table-responsive">
                                          <table className="table table-borderless border-0">
                                            <thead className=" border-0">
                                              <tr>
                                                <th
                                                  nowrap="true"
                                                  className="table_header_barge_label border-0"
                                                >
                                                  Date of Payment
                                                </th>
                                                <th
                                                  nowrap="true"
                                                  className="table_header_barge_label  border-0 text-right"
                                                >
                                                  Amount
                                                </th>
                                                <th
                                                  nowrap="true"
                                                  className="table_header_barge_label border-0"
                                                >
                                                  Invoice No
                                                </th>
                                                <th
                                                  className="table_header_barge_label border-0"
                                                  style={{
                                                    width: 300,
                                                    maxWidth: 250,
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  Description
                                                </th>
                                                <th
                                                  nowrap="true"
                                                  className="table_header_barge_label border-0"
                                                >
                                                  Created Date
                                                </th>
                                                <th
                                                  nowrap="true"
                                                  className="table_header_barge_label text-left border-0"
                                                >
                                                  1st Level Approval
                                                </th>
                                                <th
                                                  nowrap="true"
                                                  className="table_header_barge_label text-left border-0"
                                                >
                                                  1st Level Approved On
                                                </th>
                                                <th
                                                  nowrap="true"
                                                  className="table_header_barge_label text-left border-0"
                                                >
                                                  2nd Level Approval
                                                </th>
                                                <th
                                                  nowrap="true"
                                                  className="table_header_barge_label text-left border-0"
                                                >
                                                  2nd Level Approved On
                                                </th>
                                                <th
                                                  nowrap="true"
                                                  className="table_header_barge_label border-0"
                                                >
                                                  Action
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {this.state.dmr_to_buyer_payment_postings.map(
                                                (v, index) => (
                                                  <tr key={index}>
                                                    {/* <td nowrap='true'>{v.date_of_payment ? localDateFormate(v.date_of_payment) : null}</td> *
                                                    <td
                                                      nowrap="true"
                                                      className="table_td"
                                                      style={{ minWidth: 170 }}
                                                    >
                                                      {this.state
                                                        .approval_status ===
                                                        "Approved" &&
                                                        v.approval_status ===
                                                          "Approved" &&
                                                        (v.approval_status_2 ===
                                                          "Optional" ||
                                                          v.approval_status_2 ===
                                                            "Approved") && (
                                                          <MuiPickersUtilsProvider
                                                            utils={DateFnsUtils}
                                                          >
                                                            <DatePicker
                                                              autoOk={true}
                                                              inputVariant="outlined"
                                                              variant="inline"
                                                              margin="dense"
                                                              fullWidth
                                                              format="dd/MM/yyyy"
                                                              value={
                                                                v.date_of_payment
                                                              }
                                                              error={
                                                                this.state
                                                                  .date_of_paymentError
                                                              }
                                                              onChange={(
                                                                date
                                                              ) => {
                                                                let data =
                                                                  this.state
                                                                    .dmr_to_buyer_payment_postings;
                                                                data[
                                                                  index
                                                                ].date_of_payment =
                                                                  date;
                                                                this.setState(
                                                                  {
                                                                    dmr_to_buyer_payment_postings:
                                                                      data,
                                                                    barge_to_buyer_payment_id:
                                                                      v.id,
                                                                    payment_posting_type:
                                                                      v.payment_posting_type,
                                                                    date_of_payment:
                                                                      date,
                                                                    amount:
                                                                      v.amount,
                                                                    invoice_no:
                                                                      v.invoice_no,
                                                                    description:
                                                                      v.description,
                                                                    date_of_paymentError: false,
                                                                  },
                                                                  () => {
                                                                    this.UpdatePaymentPostingsHandler();
                                                                  }
                                                                );
                                                              }}
                                                              InputProps={{
                                                                startAdornment:
                                                                  (
                                                                    <InputAdornment position="start">
                                                                      <IconButton>
                                                                        <EventIcon />
                                                                      </IconButton>
                                                                    </InputAdornment>
                                                                  ),
                                                                className:
                                                                  "pl-0",
                                                              }}
                                                            />
                                                          </MuiPickersUtilsProvider>
                                                        )}
                                                    </td>
                                                    <td
                                                      nowrap="true"
                                                      className="text-right"
                                                    >
                                                      {v.amount
                                                        ? this.toLocaleString(
                                                            v.amount
                                                          )
                                                        : ""}
                                                    </td>
                                                    <td nowrap="true">
                                                      {v.invoice_no}
                                                    </td>
                                                    <td
                                                      style={{
                                                        whiteSpace: "pre-line",
                                                      }}
                                                    >
                                                      {v.description}
                                                    </td>
                                                    <td nowrap="true">
                                                      {v.created_date
                                                        ? localDateFormate(
                                                            v.created_date
                                                          )
                                                        : null}
                                                    </td>
                                                    {this.state
                                                      .approval_status ===
                                                      "Approved" &&
                                                    v.approval_status ===
                                                      "Pending" &&
                                                    this.Cookie.getCookie(
                                                      "loginUserId"
                                                    ) !== v.created_by &&
                                                    (this.state.features.includes(
                                                      "412"
                                                    )  ||
                                                      this.state.features.includes(
                                                        "97"
                                                      ) ) ? (
                                                      <td
                                                        nowrap="true"
                                                        className=" text-left"
                                                      >
                                                        <button
                                                          className="header_button header_button_text add_button_adjustment"
                                                          style={{
                                                            width: "auto",
                                                            marginTop: "17px",
                                                          }}
                                                          onClick={() =>
                                                            this.approvePaymentsHandler(
                                                              v.id
                                                            )
                                                          }
                                                        >
                                                          Approve
                                                        </button>
                                                      </td>
                                                    ) : (
                                                      <td
                                                        nowrap="true"
                                                        className=" text-left"
                                                      >
                                                        {v.approved_by_name
                                                          ? v.approved_by_name
                                                          : "-"}
                                                      </td>
                                                    )}
                                                    <td
                                                      nowrap="true"
                                                      className=" text-left"
                                                    >
                                                      {v.approved_date
                                                        ? localDateFormate(
                                                            v.approved_date
                                                          )
                                                        : "-"}
                                                    </td>
                                                    {this.state
                                                      .approval_status ===
                                                      "Approved" &&
                                                    v.approval_status_2 ===
                                                      "Pending" &&
                                                    this.Cookie.getCookie(
                                                      "loginUserId"
                                                    ) !== v.created_by &&
                                                    this.Cookie.getCookie(
                                                      "loginUserId"
                                                    ) !== v.approved_by &&
                                                    (this.state.features.includes(
                                                      "463"
                                                    )  ||
                                                      this.state.features.includes(
                                                        "97"
                                                      ) ) ? (
                                                      <td
                                                        nowrap="true"
                                                        className=" text-left"
                                                      >
                                                        <button
                                                          className="header_button header_button_text add_button_adjustment"
                                                          style={{
                                                            width: "auto",
                                                            marginTop: "17px",
                                                          }}
                                                          onClick={() =>
                                                            this.approvePaymentsHandler(
                                                              v.id
                                                            )
                                                          }
                                                        >
                                                          Approve
                                                        </button>
                                                      </td>
                                                    ) : (
                                                      <td
                                                        nowrap="true"
                                                        className=" text-left"
                                                      >
                                                        {v.approved_by_2_name
                                                          ? v.approved_by_2_name
                                                          : "-"}
                                                      </td>
                                                    )}
                                                    <td
                                                      nowrap="true"
                                                      className=" text-left"
                                                    >
                                                      {v.approved_date_2
                                                        ? localDateFormate(
                                                            v.approved_date_2
                                                          )
                                                        : "-"}
                                                    </td>
                                                    <td nowrap="true">
                                                      {(v.approval_status ===
                                                      "pending"
                                                        ? this.state.features.includes(
                                                            "264"
                                                          )  ||
                                                          this.state.features.includes(
                                                            "412"
                                                          )  ||
                                                          this.state.features.includes(
                                                            "97"
                                                          ) 
                                                        : v.approval_status_2 ===
                                                          "Pending"
                                                        ? this.Cookie.getCookie(
                                                            "loginUserId"
                                                          ) !== v.approved_by ||
                                                          this.state.features.includes(
                                                            "463"
                                                          )  ||
                                                          this.state.features.includes(
                                                            "97"
                                                          ) 
                                                        : this.state.features.includes(
                                                            "97"
                                                          ) ) && (
                                                        <Tooltip
                                                          title="Edit"
                                                          arrow
                                                        >
                                                          <i
                                                            className="fa fa-pencil mr-2"
                                                            style={{
                                                              fontSize: 20,
                                                              color:
                                                                config.themeColor,
                                                              cursor: "pointer",
                                                            }}
                                                            data-toggle="modal"
                                                            title="Edit Coal Payment Posting"
                                                            data-placement="bottom"
                                                            onClick={() => {
                                                              this.setState({
                                                                remainingAmount:
                                                                  this.state
                                                                    .dmr_to_buyer_remaining_amount,
                                                              });
                                                              this.getPaymentDetalis(
                                                                v
                                                              );
                                                            }}
                                                          />
                                                        </Tooltip>
                                                      )}

                                                      {(this.state.features.includes(
                                                        "265"
                                                      )  ||
                                                        this.state.features.includes(
                                                          "97"
                                                        ) ) &&
                                                        v.approval_status ===
                                                          "Pending" && (
                                                          <DeletePayments
                                                            data={v}
                                                            table={""}
                                                            callbackFn={() =>
                                                              this.componentDidCatch()
                                                            }
                                                          />
                                                        )}
                                                    </td>
                                                  </tr>
                                                )
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    )} */}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <footer className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button btn-light"
              name="submit"
              onClick={() => {
                this.props.returnCallbackDrawerClose(false);
              }}
            >
              Close
            </button>
            {(this.state.approval_status === "pending"
              ? this.state.features.includes("54") ||
                this.state.features.includes("152") ||
                this.state.features.includes("154") ||
                this.state.features.includes("410") ||
                this.state.features.includes("97")
              : this.state.approval_status_2 === "Pending"
              ? this.Cookie.getCookie("loginUserId") !==
                  this.state.approved_by ||
                this.state.features.includes("461") ||
                this.state.features.includes("97")
              : this.state.features.includes("97")) &&
              this.state.approval_status === "pending" && (
                <button
                  type="button"
                  className="drawer_text drawer_btn_success"
                  name="Back"
                  onClick={() => {
                    this.props.returnEditCallback(true);
                  }}
                >
                  Edit
                </button>
              )}
          </footer>

          {/*
           *
           * Barge Payements Posting Modals
           *
           */}
          <div
            className="modal fade"
            id="paymentPostingsModal"
            role="dialog"
            tabIndex="-1"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content payment-modal">
                <div
                  className="modal-header border-0"
                  style={{ backgroundColor: "#f1f1f1" }}
                >
                  <h6
                    className="modal-title"
                    style={{ border: "none", padding: 0 }}
                  >
                    {this.state.paymentTitle} Payment Posting
                  </h6>
                  {/* //<button type="button" className="close" data-dismiss="modal">&times;</button> */}
                </div>
                <div className="modal-body">
                  <div className="col-lg-12">
                    <div
                      style={{
                        fontSize: 14,
                        color: "red",
                        textTransform: "capitalize",
                        textAlign: "center",
                      }}
                    >
                      {this.state.paymentErrorMsg}
                    </div>
                    <div className="row">
                      {this.props.demurrage_type === "buyer_to_company" && (
                        <div className="col-lg-4 mb-2">
                          <label className="contract_display_header_label mb-0">
                            {" "}
                            Date Received{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                              autoOk={true}
                              inputVariant="outlined"
                              variant="inline"
                              margin="dense"
                              fullWidth
                              format="dd/MM/yyyy"
                              value={this.state.date_of_payment}
                              error={this.state.date_of_paymentError}
                              onChange={(date) => {
                                this.setState({
                                  date_of_payment: date,
                                  date_of_paymentError: false,
                                });
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton>
                                      <EventIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                                className: "pl-0",
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                      )}
                      <div
                        className={
                          this.props.demurrage_type === "buyer_to_company"
                            ? "col-lg-4 mb-2"
                            : "col-lg-6 mb-2"
                        }
                      >
                        <label className="contract_display_header_label mb-0">
                          Amount <span style={{ color: "red" }}>*</span>
                        </label>
                        <TextField
                          name="amount"
                          margin="dense"
                          variant="outlined"
                          placeholder="Amount"
                          required
                          value={this.state.amount}
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          fullWidth
                          error={this.state.amountError}
                          onChange={(e) => {
                            const pr = /^\d*(\.\d{0,4})?$/;
                            if (
                              e.target.value === "" ||
                              pr.test(e.target.value)
                            ) {
                              this.setState({
                                amount: e.target.value,
                                amountError: false,
                                paymentErrorMsg: "",
                              });
                              var remainingAmount = 0;
                              remainingAmount = Number(
                                this.state.remainingAmount
                              );
                              if (this.state.paymentMode === "Update") {
                                remainingAmount =
                                  Number(this.state.remainingAmount) +
                                  Number(this.state.existingAmount);
                              }
                              if (
                                Number(e.target.value) >
                                Math.ceil(Number(remainingAmount))
                              ) {
                                this.setState({
                                  amountError: true,
                                  submitting: false,
                                  paymentErrorMsg:
                                    "Amount is exceeding Payable to Buyer value",
                                });
                                return;
                              }
                            }
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                className="adornment_background"
                                position="end"
                              >
                                {config.company === "SRPL" || config.company === "AVS"  ||
                                config.company === "PRPL"
                                  ? "USD"
                                  : "IDR"}
                              </InputAdornment>
                            ),
                            style: {
                              fontFamily: "Poppins",
                              fontSize: "14px",
                              padding: 0,
                            },
                          }}
                        />
                      </div>
                      <div
                        className={
                          this.props.demurrage_type === "buyer_to_company"
                            ? "col-lg-4 mb-2"
                            : "col-lg-6 mb-2"
                        }
                      >
                        <label className="contract_display_header_label mb-0">
                          Invoice No <span style={{ color: "red" }}>*</span>
                        </label>
                        <TextField
                          name="invoice_no"
                          margin="dense"
                          variant="outlined"
                          placeholder="Invoice No"
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          error={this.state.invoice_noError}
                          value={this.state.invoice_no}
                          onChange={(e) =>
                            this.setState({
                              invoice_no: e.target.value,
                              invoice_noError: false,
                            })
                          }
                          fullWidth
                        />
                      </div>
                      <div className="col-lg-12 mb-2">
                        <label className="contract_display_header_label mb-0">
                          Description
                        </label>
                        <TextField
                          name="description"
                          margin="dense"
                          variant="outlined"
                          placeholder="Description"
                          multiline
                          rows={3}
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          value={this.state.description}
                          onChange={(e) =>
                            this.setState({ description: e.target.value })
                          }
                          fullWidth
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row bottom_buttons_section">
                  <button
                    type="button"
                    className="btn previous_button"
                    data-dismiss="modal"
                    onClick={() => {
                      this.setState({
                        paymentTitle: "",
                        payment_posting_type: "",
                        date_of_payment: null,
                        amount: "",
                        invoice_no: "",
                        description: "",
                        paymentErrorMsg: "",
                        date_of_paymentError: false,
                        amountError: false,
                        invoice_noError: false,
                        paymentMode: "",
                        submitting: false,
                      });
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn next_button"
                    onClick={
                      this.state.paymentMode === "Add"
                        ? this.AddPaymentPostingHandler
                        : this.UpdatePaymentPostingsHandler
                    }
                    disabled={this.state.submitting}
                  >
                    <span
                      className={
                        this.state.submitting
                          ? "spinner-grow spinner-grow-sm mr-2"
                          : ""
                      }
                    ></span>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
