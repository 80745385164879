import React, { Component } from "react";
import api from "../../api/api";
import config from "../../config/config";
import CookieHandler from "../common/CookieHandler";
import {
  TextField,
  FormControlLabel,
  Checkbox,
  Snackbar,
  MenuItem,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Alert } from "@material-ui/lab";

import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { pad } from "../common/common";
import Loader from "../common/Loader";

const CheckBox = withStyles({
  root: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const PurpleSwitch = withStyles({
  switchBase: {
    color: config.themeColor,
    "&$checked": {
      color: config.themeColor,
    },
    "&$checked + $track": {
      backgroundColor: config.themeColor,
    },
  },
  checked: {},
  track: {},
})(Switch);

export default class Barge_Mapping_Add extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      allocation_barges_dialogue: false,
      allocation_data: [
        {
          allocation_id: "",
          barges: [],
        },
      ],
      barges: [],
      allocationDataForMenu: [],
      mapping_data: [],
      slected_full_barges: [],
      isLoading: true,
      activeTabIndex: null,
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const idToken = this.Cookie.getIdTokenCookie();
    const loginUserID = this.Cookie.getCookie("loginUserId");
    try {
      // var businessNo = decodeURIComponent(window.atob(this.props.match.params.businessNoID));
      var businessNo = this.props.businessNoID;
      this.setState({ businessNo: businessNo });
    } catch (e) {
      window.location.href = "/business-number-list";
    }
    const get_business_no = await api.getBusinessNumber(
      businessNo,
      loginUserID,
      idToken
    );
    if (get_business_no.status >= 200 && get_business_no.status < 300) {
      get_business_no.json().then((res) => {
        if (res.code === "200") {
          if (res.business_number) {
            // filter only not buy back allocation

            const filterNotBuyBack =
              res.business_number.business_number_allocation.filter(
                (e, idx) => e.buy_back_alloction === "No"
              );
            const allocationIdMenu = [];
            for (var j of filterNotBuyBack) {
              allocationIdMenu.push({
                name: j.display_allocation_id,
                value: j.busuiness_allocation_id,
                pc_qualities_id: j.pc_qualities_id,
                pc_type_id: j.pc_type_id,
                pc_contract_id: j.pc_contract_id,
              });
            }
            this.setState({
              allocationDataForMenu: allocationIdMenu,
              business_number_allocation: filterNotBuyBack,
              business_number_allocation_length: filterNotBuyBack.length,
              business_number: res.business_number.business_no,
              mother_vessel_name: res.business_number.mother_vessel_name,
            });
          } else {
            alert("Unexpected error occured. Please contact administrator.");
          }
        } else if (res.code === "607") {
          window.location.href = "/logout";
        }
      });
    }

    // get mapping barges api
    const get_mapped_barges = await api.get_business_no_barge_mapping(
      loginUserID,
      idToken,
      businessNo
    );
    if (get_mapped_barges.status >= 200 && get_mapped_barges.status < 300) {
      get_mapped_barges.json().then((res) => {
        if (res.code === "200") {
          if (res.business_no_barge_mapping) {
            var mapping_barges = res.business_no_barge_mapping;
            for (var i of mapping_barges) {
              i.predefind_allocation_value = {
                name: i.display_allocation_id,
                value: i.allocation_id,
              };
              const avaiable_barges = [];
              for (var j of i.barges) {
                j.prev_total_mapped_quantity = j.barge_mapped_quantity;
                if (j.allocation_type === null) {
                  j.barge_allocation_type = "Full";
                } else {
                  j.barge_allocation_type = j.allocation_type;
                }

                let barge_quantity_in_mt = j.barge_quantity_in_mt
                  ? Number(j.barge_quantity_in_mt)
                  : null;
                let barge_planned_quantity_in_mt =
                  j.barge_planned_quantity_in_mt
                    ? Number(j.barge_planned_quantity_in_mt)
                    : null;
                let total_mapped_quantity = j.total_mapped_quantity
                  ? Number(j.total_mapped_quantity)
                  : 0;
                let barge_mapped_quantity = j.barge_mapped_quantity
                  ? Number(j.barge_mapped_quantity)
                  : 0;
                if (i.allocation_id === j.allocation_id) {
                  j.barge_checked = true;
                  if (j.allocation_type === "Full") {
                    this.setState({
                      slected_full_barges: [
                        ...this.state.slected_full_barges,
                        { id: j.id, allocation_type: j.allocation_type },
                      ],
                    });
                  }
                  if (barge_quantity_in_mt) {
                    if (
                      barge_quantity_in_mt >
                      Number(total_mapped_quantity) -
                        Number(barge_mapped_quantity)
                    ) {
                      j.available_quantity =
                        Number(barge_quantity_in_mt) -
                        Number(total_mapped_quantity) +
                        Number(barge_mapped_quantity);
                      j.remaining_partial_quantity =
                        Number(barge_quantity_in_mt) -
                        Number(total_mapped_quantity) +
                        Number(barge_mapped_quantity);
                      avaiable_barges.push(j);
                    }
                  } else if (barge_planned_quantity_in_mt) {
                    if (
                      barge_planned_quantity_in_mt >
                      Number(total_mapped_quantity) -
                        Number(barge_mapped_quantity)
                    ) {
                      j.available_quantity =
                        Number(barge_planned_quantity_in_mt) -
                        Number(total_mapped_quantity) +
                        Number(barge_mapped_quantity);
                      j.remaining_partial_quantity =
                        Number(barge_planned_quantity_in_mt) -
                        Number(total_mapped_quantity) +
                        Number(barge_mapped_quantity);
                      avaiable_barges.push(j);
                    }
                  }
                } else if (j.allocation_type !== "Full") {
                  j.barge_mapped_quantity = "";
                  if (barge_quantity_in_mt) {
                    if (barge_quantity_in_mt > Number(total_mapped_quantity)) {
                      j.available_quantity =
                        Number(barge_quantity_in_mt) -
                        Number(total_mapped_quantity);
                      j.remaining_partial_quantity =
                        Number(barge_quantity_in_mt) -
                        Number(total_mapped_quantity);
                      avaiable_barges.push(j);
                    }
                  } else if (barge_planned_quantity_in_mt) {
                    if (
                      barge_planned_quantity_in_mt >
                      Number(total_mapped_quantity)
                    ) {
                      j.available_quantity =
                        Number(barge_planned_quantity_in_mt) -
                        Number(total_mapped_quantity);
                      j.remaining_partial_quantity =
                        Number(barge_planned_quantity_in_mt) -
                        Number(total_mapped_quantity);
                      avaiable_barges.push(j);
                    }
                  }
                }
              }

              i.barges = avaiable_barges;
            }
            // for (let i = 0; i < row.length; i++) {
            //   row[i].predefind_allocation_value = { name: row[i].display_allocation_id, value: row[i].allocation_id };
            //   const filter_same_allocation_barges = row[i].barges.filter(e => e.allocation_id === row[i].allocation_id || e.allocation_id === null);
            //   console.log("filter_same_allocation_barges",filter_same_allocation_barges);
            //   row[i].barges = filter_same_allocation_barges;
            //   for (var j in filter_same_allocation_barges) {
            //     if (filter_same_allocation_barges[j].mapped_id && row[i].allocation_id === filter_same_allocation_barges[j].allocation_id) {
            //       filter_same_allocation_barges[j].barge_checked = true;
            //       // filter_same_allocation_barges[j].available_quantity = (Number(filter_same_allocation_barges[j].barge_quantity_in_mt ? filter_same_allocation_barges[j].barge_quantity_in_mt : filter_same_allocation_barges[j].barge_planned_quantity_in_mt ? filter_same_allocation_barges[j].barge_planned_quantity_in_mt : 0) - (Number(filter_same_allocation_barges[j].total_mapped_quantity) - Number(filter_same_allocation_barges[j].barge_mapped_quantity)));
            //       filter_same_allocation_barges[j].available_quantity = (Number(filter_same_allocation_barges[j].barge_quantity_in_mt ? filter_same_allocation_barges[j].barge_quantity_in_mt : filter_same_allocation_barges[j].barge_planned_quantity_in_mt ? filter_same_allocation_barges[j].barge_planned_quantity_in_mt : 0) - (Number(filter_same_allocation_barges[j].total_mapped_quantity)));
            //       if (filter_same_allocation_barges[j].allocation_type === "Full") {
            //         this.setState({
            //           slected_full_barges: [...this.state.slected_full_barges, { id: filter_same_allocation_barges[j].id, allocated_type: filter_same_allocation_barges[j].allocation_type }]
            //         });
            //       }
            //     }
            //     if (filter_same_allocation_barges[j].allocation_type === null) {
            //       filter_same_allocation_barges[j].barge_allocation_type = "Full";
            //     } else {
            //       filter_same_allocation_barges[j].barge_allocation_type = filter_same_allocation_barges[j].allocation_type;
            //     }

            //   }
            // }
            this.setState({
              allocation_data:
                res.business_no_barge_mapping.length > 0
                  ? mapping_barges
                  : [
                      {
                        allocation_id: "",
                        barges: [],
                      },
                    ],
              isLoading: false,
            });
          } else {
            alert("Unexpected error occured. Please contact administrator.");
          }
        } else if (res.code === "607") {
          window.location.href = "/logout";
        }
      });
    }
    document.title = config.documentTitle + "Barge Mapping";
  }

  MoreRowsHandler = () => {
    const item = {
      allocation_id: "",
      barges: [],
    };

    this.setState({
      allocation_data: [...this.state.allocation_data, item],
    });
  };

  RemoveMoreRowsHandler = (idx) => () => {
    const rows = this.state.allocation_data;
    rows.splice(idx, 1);
    this.setState({
      allocation_data: rows,
    });
  };

  dateFormat(date) {
    let dateValue = date.split(" ");
    let dateString = dateValue[0].split("-");
    var newDate = dateString[2] + "-" + dateString[1] + "-" + dateString[0];
    var time = dateValue[1];
    var finalDate = newDate + " " + time;
    return finalDate;
  }

  toLocaleString(value) {
    return value ? Number(value).toLocaleString() : 0;
  }

  submitHandler = () => {
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    var mapping_data = [];
    var row = this.state.allocation_data;
    for (var i = 0; i < row.length; i++) {
      const filter_selected_barges = row[i].barges.filter(
        (e) => e.barge_checked === true
      );
      for (var j of filter_selected_barges) {
        if (j.mapped_id) {
          mapping_data.push({
            id: j.mapped_id,
            allocation_id: row[i].allocation_id,
            barge_id: j.barge_id,
            allocation_type: j.barge_allocation_type,
            quantity:
              j.barge_allocation_type === "Full"
                ? j.barge_quantity_in_mt
                  ? j.barge_quantity_in_mt
                  : j.barge_planned_quantity_in_mt
                : j.barge_mapped_quantity,
            redraft_quantity: j.redraft_quantity,
            redraft_party: j.redraft_party,
            quantity_difference: j.quantity_difference,
            quantity_difference_account: j.quantity_difference_account,
          });
        } else {
          mapping_data.push({
            allocation_id: row[i].allocation_id,
            barge_id: j.barge_id,
            allocation_type: j.barge_allocation_type,
            quantity:
              j.barge_allocation_type === "Full"
                ? j.barge_quantity_in_mt
                  ? j.barge_quantity_in_mt
                  : j.barge_planned_quantity_in_mt
                : j.barge_mapped_quantity,
            redraft_quantity: j.redraft_quantity,
            redraft_party: j.redraft_party,
            quantity_difference: j.quantity_difference,
            quantity_difference_account: j.quantity_difference_account,
          });
        }
      }
    }

    if (mapping_data.length === 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please select atleast one barge to mapping",
      });
      return;
    }

    var count = 0;
    for (var m = 0; m < mapping_data.length; m++) {
      for (var n = 0; n < mapping_data.length; n++) {
        if (
          m !== n &&
          mapping_data[m].barge_id === mapping_data[n].barge_id &&
          mapping_data[m].allocation_type === "Full"
        ) {
          count++;
        }
      }
    }
    if (count > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Can't allocate full quantity for same barge",
      });
      return;
    }
    try {
      // var business_no_id = decodeURIComponent(window.atob(this.props.match.params.businessNoID));
      var business_no_id = this.props.businessNoID;
    } catch (e) {
      window.location.href = "/business-number-list";
    }
    api
      .add_business_no_barge_mapping(
        loginUserID,
        idToken,
        business_no_id,
        mapping_data
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState({
                snackBarSuccessOpen: true,
                successMessage: res.message,
              });
              this.props.onGetResponse(res.code);
              // window.location.href = '/view-business/' + btoa(business_no_id);
            } else if (res.code === "607") {
              window.location.href = "/logout";
            } else if (res.code === "624") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.columns,
              });
            }
          });
        }
      });
  };

  onAllocationChange = (idx, value, id) => {
    var row = this.state.allocation_data;
    if (value) {
      row[idx].allocation_id = value.value;
      row[idx].display_allocation_id = value.name;
      row[idx].predefind_allocation_value = {
        name: value.name,
        value: value.value,
      };
      row[idx].barges = [];
      if (value.pc_contract_id && value.pc_qualities_id && value.pc_type_id) {
        var loginUserID = this.Cookie.getCookie("loginUserId");
        var idToken = this.Cookie.getIdTokenCookie();
        var purchase_contract_id = value.pc_contract_id
          ? [value.pc_contract_id]
          : "";
        var pc_qualities_id = value.pc_qualities_id;
        var pc_purchase_type_id = value.pc_type_id;
        let financial_type = "barges";
        api
          .get_barges_for_mapping(
            loginUserID,
            idToken,
            financial_type,
            purchase_contract_id,
            pc_qualities_id,
            pc_purchase_type_id
          )
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              response.json().then((res) => {
                if (res.code === "200") {
                  if (res.barges) {
                    if (res.barges.length === 0) {
                      this.setState({
                        snackBarErrorOpen: true,
                        errorMsg: "No barges avaiable for this allocation.",
                      });
                    } else {
                      const filter_partial_barges = res.barges.filter(
                        (e) => e.allocation_type !== "Full"
                      );

                      for (var i in filter_partial_barges) {
                        // filter_partial_barges[i].available_quantity = (Number(filter_partial_barges[i].barge_quantity_in_mt ? filter_partial_barges[i].barge_quantity_in_mt : filter_partial_barges[i].barge_planned_quantity_in_mt ? filter_partial_barges[i].barge_planned_quantity_in_mt : 0) - (Number(filter_partial_barges[i].total_mapped_quantity) - Number(filter_partial_barges[i].quantity ? filter_partial_barges[i].quantity : 0)));
                        if (filter_partial_barges[i].allocation_type === null) {
                          filter_partial_barges[i].barge_allocation_type =
                            "Full";
                        } else {
                          filter_partial_barges[i].barge_allocation_type =
                            filter_partial_barges[i].allocation_type;
                        }
                        filter_partial_barges[i].redraft_quantity = null;
                        filter_partial_barges[i].redraft_party = null;
                        filter_partial_barges[i].quantity_difference = null;
                        filter_partial_barges[i].quantity_difference_account =
                          null;

                        let barge_quantity_in_mt = filter_partial_barges[i]
                          .barge_quantity_in_mt
                          ? Number(
                              filter_partial_barges[i].barge_quantity_in_mt
                            )
                          : null;
                        let barge_planned_quantity_in_mt =
                          filter_partial_barges[i].barge_planned_quantity_in_mt
                            ? Number(
                                filter_partial_barges[i]
                                  .barge_planned_quantity_in_mt
                              )
                            : null;
                        let total_mapped_quantity = filter_partial_barges[i]
                          .quantity
                          ? Number(filter_partial_barges[i].quantity)
                          : 0;
                        if (barge_quantity_in_mt) {
                          if (barge_quantity_in_mt > total_mapped_quantity) {
                            filter_partial_barges[i].available_quantity =
                              Number(barge_quantity_in_mt) -
                              Number(total_mapped_quantity);
                            filter_partial_barges[
                              i
                            ].remaining_partial_quantity =
                              Number(barge_quantity_in_mt) -
                              Number(total_mapped_quantity);
                          }
                        } else if (barge_planned_quantity_in_mt) {
                          if (
                            barge_planned_quantity_in_mt > total_mapped_quantity
                          ) {
                            filter_partial_barges[i].available_quantity =
                              Number(barge_planned_quantity_in_mt) -
                              Number(total_mapped_quantity);
                            filter_partial_barges[
                              i
                            ].remaining_partial_quantity =
                              Number(barge_planned_quantity_in_mt) -
                              Number(total_mapped_quantity);
                          }
                        }
                        for (var j of row) {
                          for (var k of j.barges) {
                            if (filter_partial_barges[i].id === k.barge_id) {
                              // filter_partial_barges[i].available_quantity = k.available_quantity;
                              // filter_partial_barges[i].remaining_partial_quantity = k.remaining_partial_quantity;
                              filter_partial_barges[i].available_quantity =
                                Number(
                                  k.available_quantity
                                    ? k.available_quantity
                                    : 0
                                ) -
                                Number(
                                  k.partial_quantity ? k.partial_quantity : 0
                                );
                              filter_partial_barges[
                                i
                              ].remaining_partial_quantity =
                                Number(
                                  k.remaining_partial_quantity
                                    ? k.remaining_partial_quantity
                                    : 0
                                ) -
                                Number(
                                  k.partial_quantity ? k.partial_quantity : 0
                                );
                            }
                          }
                        }
                        filter_partial_barges[i].allocation_id = value.value;
                        filter_partial_barges[i].barge_id =
                          filter_partial_barges[i].id;
                        filter_partial_barges[i].barge_mapped_quantity = "";

                        filter_partial_barges[i].barge_checked = false;
                      }
                      row[idx].barges = filter_partial_barges;

                      this.setState({
                        barges: res.barges,
                        //allocation_barges_dialogue: true
                      });
                    }
                  } else {
                    alert(
                      "Unexpected error occured. Please contact administrator."
                    );
                  }
                } else if (res.code === "607") {
                  window.location.href = "/logout";
                }
              });
            }
          });

        // get sales contract quality
      }
      let business_no_allocation_id = value.value;
      api
        .get_sales_contract_data_by_allocation_id(
          loginUserID,
          idToken,
          business_no_allocation_id
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                if (res.sc_qualities_data) {
                  var data = res.sc_qualities_data;
                  let sc_quantity =
                    (Number(data.sc_quantity_adjusted) *
                      Number(data.sc_tolerence)) /
                    100;
                  this.setState({
                    sc_quantity:
                      Number(data.sc_quantity_adjusted) +
                      Number(sc_quantity) -
                      Number(
                        data.total_quantity_delivered
                          ? data.total_quantity_delivered
                          : 0
                      ),
                  });
                } else {
                  alert(
                    "Unexpected error occured. Please contact administrator."
                  );
                }
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    } else {
      row[idx].barges = [];
      row[idx].allocation_id = "";
      row[idx].display_allocation_id = "";
      row[idx].activeTabIndex = id;
      row[idx].predefind_allocation_value = { name: null, value: null };

      this.setState({
        sc_quantity: "",
      });
    }

    this.setState({
      allocation_data: row,
    });
  };

  render() {
    // try {
    //   // var business_no_id = decodeURIComponent(window.atob(this.props.match.params.businessNoID));
    //   var business_no_id = this.props.businessNoID;
    // }
    // catch (e) {
    //   window.location.href = '/business-number-list';
    // }
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div
          className="container bg-white p-0"
          style={{ minHeight: "100vh", width: 850 }}
        >
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMessage}
            </Alert>
          </Snackbar>
          <div className="row clearfix content-header border-bottom">
            <div className="col-lg-12 pl-0">
              <h4 style={{ padding: "10px 20px" }}>
                Map Barges-{" "}
                {this.state.business_number ? this.state.business_number : null}
                {this.state.mother_vessel_name
                  ? " (" + this.state.mother_vessel_name + ")"
                  : null}
              </h4>
            </div>
          </div>
          <div className="drawer-section">
            <div className="card mt-2 p-0">
              <div className="card-body">
                <div className="col-lg-12 p-0 ">
                  {this.state.allocation_data.map((e, idx) => (
                    <div className="row mb-2" key={idx}>
                      <div className="col-lg-11 border_dashed rounded-lg p-2 mb-3">
                        <div className="row">
                          <div className="col-lg-12 label-text form_label pl-2">
                            Allocation ID
                          </div>
                          <Autocomplete
                            options={this.state.allocationDataForMenu}
                            getOptionLabel={(option) => option.name}
                            size="small"
                            onChange={(event, value) =>
                              this.onAllocationChange(idx, value)
                            }
                            name="allocation_id"
                            className="col-lg-6 pl-2"
                            getOptionDisabled={(option) =>
                              this.state.allocation_data.some(
                                (e) => e.allocation_id === option.value
                              )
                            }
                            value={e.predefind_allocation_value}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Allocation ID"
                                variant="outlined"
                                fullWidth
                              />
                            )}
                          />
                        </div>

                        {e.barges && e.barges.length > 0 && (
                          <div className="row mt-3">
                            <div className="table-responsive">
                              <table className="border-0 root">
                                <thead>
                                  <tr>
                                    <th
                                      className="allocation_table_header_label pb-2"
                                      nowrap="true"
                                    ></th>
                                    <th
                                      className="allocation_table_header_label pb-2"
                                      nowrap="true"
                                      style={{ minWidth: 120 }}
                                    >
                                      Barge ID
                                    </th>
                                    <th
                                      className="allocation_table_header_label pb-2"
                                      nowrap="true"
                                      style={{ minWidth: 180 }}
                                    >
                                      Barge Nomination
                                    </th>
                                    <th
                                      className="allocation_table_header_label pb-2"
                                      nowrap="true"
                                      style={{ minWidth: 130 }}
                                    >
                                      Barge Status
                                    </th>
                                    <th
                                      className="allocation_table_header_label pb-2"
                                      nowrap="true"
                                      style={{ minWidth: 130 }}
                                    >
                                      Loading Date
                                    </th>
                                    <th
                                      className="allocation_table_header_label pb-2"
                                      nowrap="true"
                                      style={{ minWidth: 180 }}
                                    >
                                      Purchase Contract
                                    </th>
                                    <th
                                      className="allocation_table_header_label pb-2"
                                      nowrap="true"
                                      style={{ minWidth: 130 }}
                                    >
                                      Purchase Type
                                    </th>
                                    <th
                                      className="allocation_table_header_label pb-2 text-right"
                                      nowrap="true"
                                      style={{ minWidth: 180 }}
                                    >
                                      Planned Quantity in MT
                                    </th>
                                    <th
                                      className="allocation_table_header_label pb-2 text-right"
                                      nowrap="true"
                                      style={{ minWidth: 150 }}
                                    >
                                      Quantity in MT
                                    </th>
                                  </tr>
                                </thead>

                                {!e.barges
                                  ? ""
                                  : e.barges.map((b, index) => (
                                      <tbody
                                        className="allocation_box_shadow"
                                        key={index}
                                      >
                                        <tr>
                                          <td className="allocation_table_value">
                                            <CheckBox
                                              color="secondary"
                                              value={b.id}
                                              checked={
                                                b.barge_checked === true
                                                  ? true
                                                  : false
                                              }
                                              className="collapsed"
                                              data-toggle="collapse"
                                              data-target={
                                                "#demo1" +
                                                b.id +
                                                e.allocation_id
                                              }
                                              aria-expanded="false"
                                              disabled={
                                                b.barge_checked &&
                                                b.sales_comm_coal_costing_id !==
                                                  null
                                              }
                                              aria-controls={"group-of-rows-1"}
                                              onChange={(e) => {
                                                var row =
                                                  this.state.allocation_data;
                                                var rows = row[idx].barges;
                                                if (e.target.checked) {
                                                  rows[
                                                    index
                                                  ].barge_checked = true;
                                                  rows[index].barge_id = b.id;
                                                  // if (rows[index].quantity) {
                                                  //   rows[index].prev_total_mapped_quantity = (Number(b.quantity ? b.quantity : 0) + Number(b.partial_quantity ? b.partial_quantity : 0));

                                                  // }
                                                  // if(rows[index].partial_quantity) {

                                                  //   rows[index].available_quantity = (Number(b.remaining_partial_quantity ? b.remaining_partial_quantity : 0) + Number(b.partial_quantity ? b.partial_quantity : 0));
                                                  //   rows[index].remaining_partial_quantity = (Number(b.remaining_partial_quantity ? b.remaining_partial_quantity : 0));

                                                  // }
                                                  this.setState({
                                                    allocation_data: row,
                                                    slected_full_barges: [
                                                      ...this.state
                                                        .slected_full_barges,
                                                      {
                                                        id: b.id,
                                                        allocation_type:
                                                          rows[index]
                                                            .barge_allocation_type,
                                                      },
                                                    ],
                                                  });
                                                } else {
                                                  rows[
                                                    index
                                                  ].barge_checked = false;
                                                  rows[index].barge_id = "";
                                                  let slected_full_barges =
                                                    this.state
                                                      .slected_full_barges;
                                                  let idx =
                                                    slected_full_barges.findIndex(
                                                      (x) => x.id === b.id
                                                    );
                                                  slected_full_barges.splice(
                                                    idx,
                                                    1
                                                  );
                                                  this.setState({
                                                    allocation_data: row,
                                                    slected_full_barges:
                                                      slected_full_barges,
                                                  });
                                                }
                                              }}
                                            />
                                          </td>
                                          <td className="allocation_table_value p-0">
                                            {pad(b.id)}
                                          </td>
                                          <td className="allocation_table_value p-0">
                                            {b.barge_nomination
                                              ? b.barge_nomination
                                              : "-"}
                                          </td>
                                          <td
                                            nowrap="true"
                                            className="allocation_table_value p-0"
                                          >
                                            {b.barge_status
                                              ? b.barge_status
                                              : "-"}
                                          </td>
                                          <td className="allocation_table_value p-0">
                                            {b.loading_date
                                              ? this.dateFormat(b.loading_date)
                                              : null}
                                          </td>
                                          <td className="allocation_table_value p-0">
                                            {b.purchase_contract_no
                                              ? b.purchase_contract_no
                                              : "-"}
                                          </td>
                                          <td className="allocation_table_value p-0">
                                            {b.purchase_type
                                              ? b.purchase_type
                                              : "-"}
                                          </td>
                                          <td className="allocation_table_value p-0 text-right">
                                            {b.barge_planned_quantity_in_mt
                                              ? this.toLocaleString(
                                                  b.barge_planned_quantity_in_mt
                                                )
                                              : "-"}
                                          </td>
                                          <td className="allocation_table_value p-0 text-right">
                                            {b.barge_quantity_in_mt
                                              ? this.toLocaleString(
                                                  b.barge_quantity_in_mt
                                                )
                                              : "-"}
                                          </td>
                                        </tr>
                                        <tr
                                          id={"demo1" + b.id + e.allocation_id}
                                          className={
                                            b.barge_checked === true
                                              ? "collapse show"
                                              : "collapse"
                                          }
                                        >
                                          <td colSpan={9}>
                                            <div className="card border rounded">
                                              <div className="row">
                                                <div className="col-lg-3">
                                                  <label className="form_label mb-0">
                                                    Allocation Type
                                                  </label>
                                                  <Typography component="div">
                                                    <Grid
                                                      component="label"
                                                      container
                                                      alignItems="center"
                                                      spacing={1}
                                                    >
                                                      <Grid item>Partial</Grid>
                                                      <Grid
                                                        item
                                                        style={{
                                                          position: "relative",
                                                          top: "3px",
                                                          paddingLeft: "20px",
                                                        }}
                                                      >
                                                        <FormControlLabel
                                                          control={
                                                            <PurpleSwitch
                                                              checked={
                                                                b.barge_allocation_type ===
                                                                "Full"
                                                              }
                                                              onChange={(e) => {
                                                                var row =
                                                                  this.state
                                                                    .allocation_data;
                                                                var rows =
                                                                  row[idx]
                                                                    .barges;
                                                                if (
                                                                  e.target
                                                                    .checked
                                                                ) {
                                                                  rows[
                                                                    index
                                                                  ].barge_allocation_type =
                                                                    "Full";
                                                                  rows[
                                                                    index
                                                                  ].barge_mapped_quantity =
                                                                    "";
                                                                  rows[
                                                                    index
                                                                  ].partial_quantity =
                                                                    "";
                                                                  rows[
                                                                    index
                                                                  ].partial_quantity_error = false;
                                                                  rows[
                                                                    index
                                                                  ].partial_quantity_error_msg =
                                                                    "";
                                                                  // let barge_quantity_in_mt = rows[index].barge_quantity_in_mt ? Number(rows[index].barge_quantity_in_mt) : null;
                                                                  // let barge_planned_quantity_in_mt = rows[index].barge_planned_quantity_in_mt ? Number(rows[index].barge_planned_quantity_in_mt) : null;
                                                                  // let total_mapped_quantity = rows[index].quantity ? Number(rows[index].quantity) : rows[index].total_mapped_quantity ? Number(rows[index].total_mapped_quantity) : 0;
                                                                  // rows[index].prev_total_mapped_quantity = (Number(total_mapped_quantity));
                                                                  // if (barge_quantity_in_mt) {
                                                                  //   if (barge_quantity_in_mt > total_mapped_quantity) {
                                                                  //     rows[index].available_quantity = (Number(barge_quantity_in_mt) - Number(total_mapped_quantity));
                                                                  //   }
                                                                  // } else if (barge_planned_quantity_in_mt) {
                                                                  //   if (barge_planned_quantity_in_mt > total_mapped_quantity) {
                                                                  //     rows[index].available_quantity = (Number(barge_planned_quantity_in_mt) - Number(total_mapped_quantity));
                                                                  //   }
                                                                  // }
                                                                  rows[
                                                                    index
                                                                  ].available_quantity =
                                                                    Number(
                                                                      rows[
                                                                        index
                                                                      ]
                                                                        .available_quantity
                                                                        ? rows[
                                                                            index
                                                                          ]
                                                                            .available_quantity
                                                                        : 0
                                                                    ) -
                                                                    Number(
                                                                      rows[
                                                                        index
                                                                      ]
                                                                        .partial_quantity
                                                                        ? rows[
                                                                            index
                                                                          ]
                                                                            .partial_quantity
                                                                        : 0
                                                                    );
                                                                  rows[
                                                                    index
                                                                  ].remaining_partial_quantity =
                                                                    Number(
                                                                      rows[
                                                                        index
                                                                      ]
                                                                        .remaining_partial_quantity
                                                                        ? rows[
                                                                            index
                                                                          ]
                                                                            .remaining_partial_quantity
                                                                        : 0
                                                                    ) -
                                                                    Number(
                                                                      rows[
                                                                        index
                                                                      ]
                                                                        .partial_quantity
                                                                        ? rows[
                                                                            index
                                                                          ]
                                                                            .partial_quantity
                                                                        : 0
                                                                    );
                                                                  this.setState(
                                                                    {
                                                                      slected_full_barges:
                                                                        [
                                                                          ...this
                                                                            .state
                                                                            .slected_full_barges,
                                                                          {
                                                                            id: b.id,
                                                                            allocation_type:
                                                                              e
                                                                                .target
                                                                                .value,
                                                                          },
                                                                        ],
                                                                    }
                                                                  );
                                                                } else {
                                                                  rows[
                                                                    index
                                                                  ].barge_allocation_type =
                                                                    "Partial";
                                                                  let slected_full_barges =
                                                                    this.state
                                                                      .slected_full_barges;
                                                                  let idx =
                                                                    slected_full_barges.findIndex(
                                                                      (x) =>
                                                                        x.id ===
                                                                        b.id
                                                                    );
                                                                  slected_full_barges.splice(
                                                                    idx,
                                                                    1
                                                                  );
                                                                  this.setState(
                                                                    {
                                                                      slected_full_barges:
                                                                        slected_full_barges,
                                                                    }
                                                                  );
                                                                }
                                                                this.setState(
                                                                  {
                                                                    allocation_data:
                                                                      row,
                                                                  },
                                                                  () => {
                                                                    const find_duplicate_allocation_type =
                                                                      this.state.slected_full_barges.filter(
                                                                        (x) =>
                                                                          x.id ===
                                                                          b.id
                                                                      );
                                                                    if (
                                                                      find_duplicate_allocation_type.length >
                                                                      1
                                                                    ) {
                                                                      this.setState(
                                                                        {
                                                                          snackBarErrorOpen: true,
                                                                          errorMsg:
                                                                            "Can't allocate full quantity for same barge",
                                                                          allocation_data:
                                                                            row,
                                                                        }
                                                                      );
                                                                    }
                                                                  }
                                                                );
                                                              }}
                                                              name="checkedA"
                                                              disabled={
                                                                b.allocation_type ===
                                                                  "Partial" &&
                                                                b.mapping_availability ===
                                                                  1
                                                              }
                                                            />
                                                          }
                                                          // label="Custom color"
                                                        />
                                                        {/* <AntSwitch checked={state.checkedC} onChange={handleChange} name="checkedC" /> */}
                                                      </Grid>
                                                      <Grid item>Full</Grid>
                                                    </Grid>
                                                  </Typography>
                                                  {/* <RadioGroup aria-label="gender" name="barge_allocation_type" onChange={(e) => {
                                              var row = this.state.allocation_data;
                                              var rows = row[idx].barges;
                                              rows[index].barge_allocation_type = e.target.value
                                              if (e.target.value === "Full") {
                                                rows[index].barge_mapped_quantity = '';
                                                rows[index].partial_quantity_error = false;
                                                rows[index].partial_quantity_error_msg = '';
                                                this.setState({
                                                  slected_full_barges: [...this.state.slected_full_barges, { "id": b.id, "allocated_type": e.target.value }]
                                                });
                                              }
                                              else {
                                                let slected_full_barges = this.state.slected_full_barges;
                                                let idx = slected_full_barges.findIndex(x => x.id === b.id);
                                                slected_full_barges.splice(idx, 1);
                                                this.setState({
                                                  slected_full_barges: slected_full_barges
                                                });
                                              }
                                              this.setState({
                                                allocation_data: row,
                                              }, () => {
                                                const find_duplicate_allocation_type = this.state.slected_full_barges.filter(x => x.id === b.id);
                                                if (find_duplicate_allocation_type.length > 1) {
                                                  this.setState({
                                                    snackBarErrorOpen: true,
                                                    errorMsg: "Can't allocate full quantity for same barge",
                                                    allocation_data: row,
                                                  });
                                                }
                                              });
                                            }} style={{ flexDirection: 'row' }}>
                                              <FormControlLabel value="Full" control={<Radio checked={b.barge_allocation_type === "Full"} disabled={b.allocation_type === "Partial" && b.mapping_availability === 1} />} label="Full" />
                                              <FormControlLabel value="Partial" control={<Radio checked={b.barge_allocation_type === "Partial"} />} label="Partial" />
                                            </RadioGroup> */}
                                                </div>
                                                {b.barge_allocation_type ===
                                                  "Partial" && (
                                                  <div className="col-lg-3">
                                                    <label className="form_label mb-0">
                                                      Quantity
                                                    </label>
                                                    <TextField
                                                      name="quantity"
                                                      margin="dense"
                                                      variant="outlined"
                                                      fullWidth
                                                      placeholder="Quantity"
                                                      value={
                                                        b.barge_mapped_quantity
                                                      }
                                                      onChange={(e) => {
                                                        const pr =
                                                          /^\d*(\.\d{0,3})?$/;
                                                        if (
                                                          e.target.value ===
                                                            "" ||
                                                          pr.test(
                                                            e.target.value
                                                          )
                                                        ) {
                                                          let row =
                                                            this.state
                                                              .allocation_data;
                                                          let rows =
                                                            row[idx].barges;
                                                          rows[
                                                            index
                                                          ].barge_mapped_quantity =
                                                            e.target.value;
                                                          rows[
                                                            index
                                                          ].partial_quantity =
                                                            e.target.value;
                                                          // rows[index].available_quantity = (Number(rows[index].available_quantity ? rows[index].available_quantity : 0) - Number(e.target.value));

                                                          rows[
                                                            index
                                                          ].partial_quantity_error = false;
                                                          // let barge_quantity_in_mt = rows[index].barge_quantity_in_mt ? Number(rows[index].barge_quantity_in_mt) : null;
                                                          // let barge_planned_quantity_in_mt = rows[index].barge_planned_quantity_in_mt ? Number(rows[index].barge_planned_quantity_in_mt) : null;
                                                          // let total_mapped_quantity = rows[index].prev_total_mapped_quantity ? Number(rows[index].prev_total_mapped_quantity) : 0;
                                                          // if (barge_quantity_in_mt) {
                                                          //   rows[index].available_quantity = (Number(barge_quantity_in_mt) - Number(total_mapped_quantity) - Number(e.target.value));
                                                          // } else if (barge_planned_quantity_in_mt) {
                                                          //   rows[index].available_quantity = (Number(barge_planned_quantity_in_mt) - Number(total_mapped_quantity) - Number(e.target.value));
                                                          // }
                                                          rows[
                                                            index
                                                          ].available_quantity =
                                                            Number(
                                                              rows[index]
                                                                .remaining_partial_quantity
                                                                ? rows[index]
                                                                    .remaining_partial_quantity
                                                                : 0
                                                            ) -
                                                            Number(
                                                              rows[index]
                                                                .partial_quantity
                                                                ? rows[index]
                                                                    .partial_quantity
                                                                : 0
                                                            );
                                                          //rows[index].remaining_partial_quantity = (Number(rows[index].remaining_partial_quantity ? rows[index].remaining_partial_quantity : 0) - Number(rows[index].partial_quantity ? rows[index].partial_quantity : 0));

                                                          const duplicate_barges =
                                                            [];
                                                          for (var i of row) {
                                                            for (var j of i.barges) {
                                                              if (
                                                                j.barge_id ===
                                                                  rows[index]
                                                                    .barge_id &&
                                                                j.barge_checked
                                                              ) {
                                                                duplicate_barges.push(
                                                                  j
                                                                );
                                                              }
                                                            }
                                                          }
                                                          var total_barge_mapped_quantity = 0;

                                                          for (var obj of duplicate_barges) {
                                                            total_barge_mapped_quantity +=
                                                              Number(
                                                                obj.barge_mapped_quantity
                                                                  ? obj.barge_mapped_quantity
                                                                  : 0
                                                              );
                                                            // if (Number(total_barge_mapped_quantity) > (Number(obj.barge_quantity_in_mt ? obj.barge_quantity_in_mt : obj.barge_planned_quantity_in_mt ? obj.barge_planned_quantity_in_mt : 0))) {
                                                            if (
                                                              Number(
                                                                total_barge_mapped_quantity
                                                              ) >
                                                              Number(
                                                                obj.remaining_partial_quantity
                                                                  ? obj.remaining_partial_quantity
                                                                  : 0
                                                              )
                                                            ) {
                                                              rows[
                                                                index
                                                              ].partial_quantity_error = true;
                                                              rows[
                                                                index
                                                              ].partial_quantity_error_msg =
                                                                "Quantity is exceeding to barge quantity";
                                                            }

                                                            if (
                                                              Number(
                                                                total_barge_mapped_quantity
                                                              ) >
                                                              (this.state
                                                                .sc_quantity &&
                                                                Number(
                                                                  this.state
                                                                    .sc_quantity
                                                                ))
                                                            ) {
                                                              rows[
                                                                index
                                                              ].partial_quantity_error = true;
                                                              rows[
                                                                index
                                                              ].partial_quantity_error_msg =
                                                                "Barge quantity is exceeding the sales contract quantity with tolerance";
                                                            }
                                                          }

                                                          this.setState({
                                                            allocation_data:
                                                              row,
                                                          });
                                                        }
                                                      }}
                                                      error={
                                                        b.partial_quantity_error
                                                          ? b.partial_quantity_error
                                                          : null
                                                      }
                                                    />
                                                    {b.partial_quantity_error && (
                                                      <p className="text-danger">
                                                        {
                                                          b.partial_quantity_error_msg
                                                        }
                                                      </p>
                                                    )}
                                                  </div>
                                                )}
                                                {b.barge_allocation_type ===
                                                  "Partial" && (
                                                  <div className="col-lg-3">
                                                    <label className="form_label mb-3">
                                                      Available Quantity
                                                    </label>
                                                    <div>
                                                      {this.toLocaleString(
                                                        b.available_quantity
                                                      )}
                                                    </div>
                                                  </div>
                                                )}
                                              </div>

                                              <div style={{ width: "70%" }}>
                                                <h5
                                                  style={{
                                                    padding: "10px 0px",
                                                    margin: "0px",
                                                  }}
                                                >
                                                  Redraft Details
                                                </h5>
                                              </div>

                                              <div className="row mb-2">
                                                <div className="col-lg-3">
                                                  <div className="row mb-2">
                                                    <label className="form_label mb-0">
                                                      Redraft Quantity
                                                    </label>
                                                    <TextField
                                                      placeholder="Enter Value"
                                                      // type="number"
                                                      fullWidth
                                                      onChange={(e) => {
                                                        const res =
                                                          /^\d*(\.\d{0,3})?$/;

                                                        if (
                                                          e.target.value ===
                                                            "" ||
                                                          res.test(
                                                            e.target.value
                                                          )
                                                        ) {
                                                          let value =
                                                            e.target.value;
                                                          var row =
                                                            this.state
                                                              .allocation_data;
                                                          var rows =
                                                            row[idx].barges;
                                                          rows[
                                                            index
                                                          ].redraft_quantity = value;
                                                          if (
                                                            rows[index]
                                                              .barge_allocation_type ===
                                                            "Full"
                                                          ) {
                                                            rows[
                                                              index
                                                            ].quantity_difference =
                                                              (
                                                                Number(
                                                                  value
                                                                    ? value
                                                                    : 0
                                                                ) -
                                                                Number(
                                                                  b.barge_quantity_in_mt
                                                                    ? b.barge_quantity_in_mt
                                                                    : 0
                                                                )
                                                              ).toFixed(3);
                                                          } else if (
                                                            rows[index]
                                                              .barge_allocation_type ===
                                                            "Partial"
                                                          ) {
                                                            rows[
                                                              index
                                                            ].quantity_difference =
                                                              (
                                                                Number(
                                                                  value
                                                                    ? value
                                                                    : 0
                                                                ) -
                                                                Number(
                                                                  b.barge_mapped_quantity
                                                                    ? b.barge_mapped_quantity
                                                                    : 0
                                                                )
                                                              ).toFixed(3);
                                                          }
                                                          this.setState({
                                                            allocation_data:
                                                              row,
                                                          });
                                                        }
                                                      }}
                                                      error={
                                                        b.redfraft_quantity_error
                                                      }
                                                      value={b.redraft_quantity}
                                                      margin="dense"
                                                      variant="outlined"
                                                    />
                                                    {b.redfraft_quantity_error_msg ? (
                                                      <p
                                                        className="text-danger"
                                                        style={{ fontSize: 10 }}
                                                      >
                                                        {
                                                          b.redfraft_quantity_error_msg
                                                        }
                                                      </p>
                                                    ) : null}
                                                  </div>
                                                </div>

                                                {b.redraft_quantity !== null &&
                                                  b.redraft_quantity !== "" && (
                                                    <div className="col-lg-3">
                                                      <div className="row mb-2">
                                                        <label className="form_label mb-2">
                                                          Redraft Party
                                                        </label>
                                                        <TextField
                                                          name="redraftParty"
                                                          margin="dense"
                                                          variant="outlined"
                                                          fullWidth
                                                          value={
                                                            b.redraft_party
                                                              ? b.redraft_party
                                                              : "0"
                                                          }
                                                          onChange={(e) => {
                                                            var row =
                                                              this.state
                                                                .allocation_data;
                                                            var rows =
                                                              row[idx].barges;
                                                            rows[
                                                              index
                                                            ].redraft_party =
                                                              e.target.value;
                                                            this.setState({
                                                              allocation_data:
                                                                row,
                                                            });
                                                          }}
                                                          select
                                                          style={{
                                                            top: 8,
                                                            display: "contents",
                                                          }}
                                                        >
                                                          <MenuItem value="0">
                                                            Please Select
                                                          </MenuItem>
                                                          <MenuItem value="Supplier Account">
                                                            Supplier Account
                                                          </MenuItem>
                                                          <MenuItem
                                                            value={
                                                              config.company +
                                                              " Account"
                                                            }
                                                          >
                                                            {config.company}{" "}
                                                            Account
                                                          </MenuItem>
                                                          <MenuItem value="Buyer">
                                                            Buyer
                                                          </MenuItem>
                                                        </TextField>
                                                      </div>
                                                    </div>
                                                  )}
                                              </div>

                                              <div className="row">
                                                {b.redraft_quantity !== null &&
                                                  b.redraft_quantity !== "" && (
                                                    <div className="col-lg-3">
                                                      <div className="row mb-2">
                                                        <label className="form_label mb-0">
                                                          Quantity Difference
                                                        </label>
                                                        <TextField
                                                          name="quantityDifference"
                                                          margin="dense"
                                                          variant="outlined"
                                                          fullWidth
                                                          value={
                                                            b.quantity_difference
                                                          }
                                                          //onChange={this.TextHandler}
                                                          placeholder="Quantity Difference"
                                                        />
                                                      </div>
                                                    </div>
                                                  )}

                                                {b.redraft_quantity !== null &&
                                                  b.redraft_quantity !== "" && (
                                                    <div className="col-lg-3">
                                                      <div className="row mb-2">
                                                        <label className="form_label mb-2">
                                                          Quantity Difference
                                                          Account
                                                        </label>
                                                        <TextField
                                                          name="quantityDifferenceAccount"
                                                          margin="dense"
                                                          variant="outlined"
                                                          fullWidth
                                                          value={
                                                            b.quantity_difference_account
                                                              ? b.quantity_difference_account
                                                              : "0"
                                                          }
                                                          onChange={(e) => {
                                                            var row =
                                                              this.state
                                                                .allocation_data;
                                                            var rows =
                                                              row[idx].barges;
                                                            rows[
                                                              index
                                                            ].quantity_difference_account =
                                                              e.target.value;
                                                            this.setState({
                                                              allocation_data:
                                                                row,
                                                            });
                                                          }}
                                                          // placeholder='Quantity Difference Account'
                                                          select
                                                          style={{
                                                            top: 8,
                                                            display: "contents",
                                                          }}
                                                        >
                                                          <MenuItem value="0">
                                                            Please Select
                                                          </MenuItem>
                                                          <MenuItem value="Supplier Account">
                                                            Supplier Account
                                                          </MenuItem>
                                                          <MenuItem
                                                            value={
                                                              config.company +
                                                              " Account"
                                                            }
                                                          >
                                                            {config.company}{" "}
                                                            Account
                                                          </MenuItem>
                                                        </TextField>
                                                      </div>
                                                    </div>
                                                  )}
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    ))}
                              </table>
                            </div>
                          </div>
                        )}

                        {e.barges.length === 0 && (
                          <div className="row col-lg-12 mt-3">
                            <p>No Barges to display</p>
                          </div>
                        )}
                      </div>
                      {this.state.allocation_data.length > 1 &&
                        e.costing_availability === false && (
                          <i
                            data-toggle="tooltip1"
                            title="Remove"
                            data-html="true"
                            data-placement="bottom"
                            className="fa fa-trash-o col-lg-1"
                            aria-hidden="true"
                            style={{
                              fontSize: 22,
                              color: config.themeColor,
                              cursor: "pointer",
                            }}
                            onClick={this.RemoveMoreRowsHandler(idx)}
                          ></i>
                        )}
                    </div>
                  ))}
                  {this.state.allocation_data.length <
                    this.state.business_number_allocation_length && (
                    <div className="row mt-3">
                      <div className="col-lg-6 pl-0">
                        <button
                          type="button"
                          onClick={this.MoreRowsHandler}
                          className="header_button header_button_text addrow_button_adjustment"
                          style={{ color: config.themeColor, width: 110 }}
                        >
                          Add More
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button btn-light"
              name="submit"
              onClick={() => {
                this.props.returnCallbackDrawerClose(false);
              }}
            >
              Close
            </button>
            <button
              type="button"
              className="drawer_text drawer_btn_success"
              name="Back"
              onClick={this.submitHandler}
            >
              Submit
            </button>
          </div>
        </div>
      );
    }
  }
}
