import React, { Component } from 'react';
import Header from '../common/Header';
import SideBar from '../common/SideBar';
import CookieHandler from '../common/CookieHandler';
import config from '../../config/config';
import api from '../../api/api';

import { Alert } from '@material-ui/lab';
import {
  MenuItem, TextField,
  InputAdornment, IconButton,
  Snackbar, Table, TableBody, TableCell,
  TableHead, TableRow, Paper, Tab, Tabs,
  TableContainer
} from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';

import Autocomplete from '@material-ui/lab/Autocomplete';
import DateFnsUtils from '@date-io/date-fns';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import Loader from '../common/Loader';

export default class CoalSupplierReports extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      coal_vendor: "",
      grade: '0',
      from_date: null,
      to_date: null,
      isLoading: true,
      service_types_list: [],
      currencyData: [],
      coal_vendors_list: [],
      customers_list: [],
      grades_list: [<MenuItem value='0' key={-1} >Please Select</MenuItem>],
      coal_vendor_reports: {
        purchase_coal_costing_data: [],
        sales_coal_costing_data: [],
      },
      activeTabIndex: 0,
      coal_vendor_error: false,
      submitting: false,
    }
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace('/logout');
    }
    let features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (!(features.includes('332')  || features.includes('97') )) {
      window.location.href = '/dashboard';
    }
    const loginUserID = this.Cookie.getCookie('loginUserId');
    const idToken = this.Cookie.getIdTokenCookie();
    // Get Customers
    const customers = await api.getCustomers(loginUserID, idToken);
    if (customers.status >= 200 && customers.status < 300) {
      customers.json().then((res) => {
        if (res.code === '200') {
          if (res.customers) {
            const customerData = [];
            for (var i of res.customers) {
              customerData.push({ name: i.customer_name, value: i.id, key: i.id })
            }
            this.setState({
              customers_list: customerData
            })
          } else {
            alert('Unexpected error occured. Please contact administrator.');
          }

        } else if (res.code === '601') {
          this.setState({
            snackBarErrorOpen: true,
            errorMsg: res.message
          });

        } else if (res.code === '607') {
          window.location.href = '/logout';
        }
      })
    }

    const get_exchg_rate = fetch('https://api.exchangeratesapi.io/2020-01-10?base=USD&symbols=IDR');
    get_exchg_rate.then((res) => {
      res.json().then((data) => {
        if (data.rates) {
          this.setState({
            exchg_rate: data.rates.IDR
          })
        }
      })
    })
    if (get_exchg_rate.status >= 200 && get_exchg_rate.status < 300) {
    }

    //Get vendors
    const vendors = await api.getVendors(loginUserID, idToken);
    if (vendors.status >= 200 && vendors.status < 300) {
      vendors.json().then((res) => {
        if (res.code === '200') {
          if (res.idtoken)
            this.Cookie.setIdTokenCookie(res.idtoken);

          if (res.vendors) {
            const vendors = [];
            const get_coal_vendors = res.vendors.filter(e => e.vendor_type === "Coal");
            for (var i of get_coal_vendors) {
              vendors.push({ name: i.vendor_name, value: i.id, key: i.id })
            }
            this.setState({
              coal_vendors_list: vendors,
              isLoading: false
            })
          } else {
            alert('Unexpected error occured. Please contact administrator.');
          }
        } else if (res.code === "607") {
          window.location.href = '/logout';
        }
      })
    }

  }

  getReportsHandler = () => {
    this.setState({ submitting: true });
    const loginUserID = this.Cookie.getCookie('loginUserId');
    const idToken = this.Cookie.getIdTokenCookie();
    let { coal_vendor, from_date, to_date, grade } = this.state;
    if (coal_vendor === '') {
      this.setState({
        coal_vendor_error: true,
        snackBarErrorOpen: true,
        submitting: false,
        errorMsg: "Please fill mandatory fileds."
      });
      return;
    }
    if (from_date === null) {
      this.setState({
        from_date_error: true,
        snackBarErrorOpen: true,
        submitting: false,
        errorMsg: "Please fill mandatory fileds."
      });
      return;
    }
    if (to_date === null) {
      this.setState({
        to_date_error: true,
        snackBarErrorOpen: true,
        submitting: false,
        errorMsg: "Please fill mandatory fileds."
      });
      return;
    }
    if(grade === "0") {
      grade='';
    }
    api.get_profit_loss_report(loginUserID, idToken, coal_vendor, from_date, to_date, grade).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === '200') {
            var row = res.Final_report;
            let coal_cost_amount_idr = 0;
            let coal_cost_amount_usd = 0;
            let sales_coal_cost_amount_idr = 0;
            let sales_coal_cost_amount_usd = 0;
            for (var cc of row.purchase_coal_costing_data) {
              cc.supplier_name = this.state.coal_supplier_name;
              coal_cost_amount_idr += cc.adjusted_coal_price_idr ? (Number(cc.adjusted_coal_price_idr)) : 0;
              coal_cost_amount_usd += cc.adjusted_coal_price_usd ? (Number(cc.adjusted_coal_price_usd)) : 0;

            }

            for (var sc of row.sales_coal_costing_data) {
              if (sc.billing_currency === "IDR") {
                sc.adjusted_coal_price_pmt_usd = (Number(sc.adjusted_coal_price_pmt_idr ? sc.adjusted_coal_price_pmt_idr : 0) / Number(this.state.exchg_rate ? this.state.exchg_rate : 1)).toFixed(2);
                sc.adjusted_coal_price_usd = (Number(sc.adjusted_coal_price_idr ? sc.adjusted_coal_price_idr : 0) / Number(this.state.exchg_rate ? this.state.exchg_rate : 1)).toFixed(2);
              }
              else if (sc.billing_currency === "USD") {
                sc.adjusted_coal_price_pmt_idr = (Number(sc.adjusted_coal_price_pmt_usd ? sc.adjusted_coal_price_pmt_usd : 0) * Number(this.state.exchg_rate ? this.state.exchg_rate : 1)).toFixed(2);
                sc.adjusted_coal_price_idr = (Number(sc.adjusted_coal_price_usd ? sc.adjusted_coal_price_usd : 0) * Number(this.state.exchg_rate ? this.state.exchg_rate : 1)).toFixed(2);
              }
              sc.exchg_rate = this.state.exchg_rate;
              sales_coal_cost_amount_idr += sc.adjusted_coal_price_idr ? (Number(sc.adjusted_coal_price_idr)) : 0;
              sales_coal_cost_amount_usd += sc.adjusted_coal_price_usd ? (Number(sc.adjusted_coal_price_usd)) : 0;
            }

            var purchase_grand_total_idr = Number(coal_cost_amount_idr);

            var purchase_grand_total_usd = Number(coal_cost_amount_usd);
            let sales_grand_total_idr = Number(sales_coal_cost_amount_idr);
            let sales_grand_total_usd = Number(sales_coal_cost_amount_usd);
            this.setState({
              purchase_grand_total_idr: purchase_grand_total_idr,
              purchase_grand_total_usd: purchase_grand_total_usd,
              sales_grand_total_idr: sales_grand_total_idr,
              sales_grand_total_usd: sales_grand_total_usd,
              sales_coal_cost_amount_idr: sales_coal_cost_amount_idr,
              sales_coal_cost_amount_usd: sales_coal_cost_amount_usd,
              coal_cost_amount_usd: coal_cost_amount_usd,
              coal_cost_amount_idr: coal_cost_amount_idr,
              coal_vendor_reports: row,
              submitting: false,
            })

          } else if (res.code === '601') {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message
            });
          } else if (res.code === '607') {
            window.location.href = '/logout';
          }
        })
      }
    });
  }

  toLocaleString(value, quantity) {
    const data = (value && quantity) ? (Number(value ? value : 0)).toFixed(3) : (Number(value ? value : 0)).toFixed(2);
    if (data) {
      return Number(data).toLocaleString(undefined, { minimumFractionDigits: quantity ? 3 : 2 });
    }
    else {
      return null;
    }
  }


  addZero(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  }

  dateFormat(date) {
    if (date) {

      let dateFormat = new Date(date);
      let day = this.addZero(dateFormat.getDate());
      let month = this.addZero(dateFormat.getMonth() + 1);
      let year = dateFormat.getFullYear();
      let newDate = year + '-' + month + '-' + day;
      //let hours = this.addZero(dateFormat.getHours());
      //let minutes = this.addZero(dateFormat.getMinutes());
      let finalDate = newDate;
      return finalDate;
    }
    else {
      return null;
    }
  }

  render() {
    var summary = this.state.coal_vendor_reports;
    return (
      <div id='wraper'>
        <SideBar />
        <div className='content'>
          <Header />
          {this.state.isLoading &&
           <Loader />
          }
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={this.state.snackBarErrorOpen} onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: '450px' }}
          >
            <Alert elevation={6} variant="filled" onClose={() => this.setState({ snackBarErrorOpen: false })} severity="error" style={{ width: '100%' }}>
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            style={{ width: '450px' }}
            open={this.state.snackBarSuccessOpen} autoHideDuration={100000} onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert elevation={6} variant="filled" onClose={() => this.setState({ snackBarSuccessOpen: false })} severity="success" style={{ width: '100%' }}>
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          {!this.state.isLoading &&
            <div className='content-header'>
              <div className='col-sm pl-0'>
                <h4 style={{ padding: '10px 20px', margin: '0px' }}> <a
                  href={'/reports'}
                  className="fa fa-arrow-left left_arrow_adjst"
                >
                </a>
                P/L Summary by Coal Supplier
                </h4>
              </div>
              <form className='container p-3'>
                <div className='col-lg-12 card' style={{ overflowY: 'scroll' }}>
                  <div className="section_block" style={{ width: '97%' }}>
                    <div style={{ width: '70%' }}>
                      <h5 style={{ padding: '10px 0px', margin: '0px' }}>Coal vendor Report details</h5>
                    </div>
                    <div className='row mb-2'>
                      <div className="row col-lg-3 p-0">
                        <label className='form_label mb-0'>From Date <span className='text-danger'>*</span></label>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DatePicker
                            disableToolbar
                            autoOk={true}
                            inputVariant="outlined"
                            variant="inline"
                            margin="dense"
                            fullWidth
                            format="dd/MM/yyyy"
                            value={this.state.from_date}
                            error={this.state.from_date_error}
                            onChange={(date) => {
                              this.setState({
                                from_date: this.dateFormat(date),
                                from_date_error: false
                              });
                            }}
                            placeholder='Start Date'
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton>
                                    <EventIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                      <div className='col-lg-3 pr-0'>
                        <label className='form_label mb-0'>To Date <span className='text-danger'>*</span></label>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DatePicker
                            disableToolbar
                            autoOk={true}
                            inputVariant="outlined"
                            variant="inline"
                            margin="dense"
                            fullWidth
                            format="dd/MM/yyyy"
                            value={this.state.to_date}
                            error={this.state.to_date_error}
                            onChange={(date) => {
                              this.setState({
                                to_date: this.dateFormat(date),
                                to_date_error: false
                              });
                            }}
                            placeholder='To Date'
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton>
                                    <EventIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                      <div className='col-lg-3 pr-0'>
                        <label className='form_label mb-0'>Coal Vendor <span className='text-danger'>*</span></label>
                        <Autocomplete
                          options={this.state.coal_vendors_list}
                          getOptionLabel={option => option.name}
                          onChange={(event, obj) => {
                            if (obj) {
                              this.setState({
                                coal_vendor: obj.value,
                                coal_supplier_name: obj.name,
                                coal_vendor_error: false,
                                vendor_drop_value: { name: obj.name, value: obj.value }
                              }, () => {
                                if (obj.value) {
                                  const loginUserID = this.Cookie.getCookie('loginUserId');
                                  const idToken = this.Cookie.getIdTokenCookie();
                                  const coal_vendor = obj.value;
                                  api.get_grades_based_on_coal_vendor(loginUserID, idToken, coal_vendor).then((response) => {
                                    if (response.status >= 200 && response.status < 300) {
                                      response.json().then((res) => {
                                        if (res.code === '200') {
                                          if (res.quality_data) {
                                            const grades = [<MenuItem value='0' key={-1}>Please Select</MenuItem>];
                                            for (var i of res.quality_data) {
                                              grades.push(<MenuItem key={i.id} value={i.grades}>{i.grades}</MenuItem>)
                                            }
                                            this.setState({
                                              grades_list: grades,
                                            })
                                          } else {
                                            alert('Unexpected error occured. Please contact administrator.');
                                          }
                                        } else if (res.code === '601') {
                                          this.setState({
                                            snackBarErrorOpen: true,
                                            errorMsg: res.message
                                          });
                                        } else if (res.code === '607') {
                                          window.location.href = '/logout';
                                        }
                                      })
                                    }
                                  })

                                }
                              });
                            }
                            else {
                              this.setState({
                                coal_vendor: "",
                                vendor_drop_value: { name: "", value: "" }
                              })
                            }
                          }}
                          InputProps={{
                            style: {
                              fontFamily: 'Poppins',
                              fontSize: '14px'
                            }
                          }}
                          size="small"
                          name='vendor'
                          value={this.state.vendor_drop_value}
                          error={this.state.coal_vendor_error}
                          renderOption={option => (
                            <React.Fragment>
                              {option.name}
                            </React.Fragment>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Please Select"
                              variant="outlined"
                              fullWidth
                              style={{ top: 8 }}
                            />
                          )}
                          sryle={{ display: 'contents' }}
                        />
                      </div>
                      <div className='col-lg-2 pr-0'>
                        <label className='form_label mb-0'>Grade</label>
                        <TextField
                          name='grade'
                          margin='dense'
                          variant='outlined'
                          fullWidth
                          onChange={(e) => {
                            this.setState({
                              grade: e.target.value,
                            });
                          }}
                          select
                          value={this.state.grade}
                        >
                          {this.state.grades_list}
                        </TextField>
                      </div>
                      <div className='col-lg-1'>
                        <button type='button' className='header_button header_button_text' style={{ width: 'auto', marginTop: '30px' }}
                          onClick={this.getReportsHandler}
                          disabled={this.state.submitting}
                        >
                          <span className={this.state.submitting ? "spinner-grow spinner-grow-sm mr-2" : ""}></span>Search
              </button>
                      </div>
                    </div>

                    <div className='row mb-2'>
                      <Tabs
                        value={this.state.activeTabIndex}
                        onChange={(event, value) => {
                          this.setState({
                            activeTabIndex: value
                          })
                        }}
                        indicatorColor="primary"
                        textColor="primary"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                        style={{ backgroundColor: '#ffffff', color: '#6c7293', padding: 0 }}
                        TabIndicatorProps={{ style: { background: config.themeColor } }}
                        className="border-bottom"
                      >
                        <Tab label={<h4 style={{ color: this.state.activeTabIndex === 0 ? config.themeColor : '#6c7293', fontSize: '16px', border: '0px' }}>Purchase</h4>} value={0} style={{ fontFamily: 'Poppins', textTransform: 'capitalize', color: '#6c7293' }} />
                        <Tab label={<h4 style={{ color: this.state.activeTabIndex === 1 ? config.themeColor : '#6c7293', fontSize: '16px', border: '0px' }}>Sales</h4>} value={1} style={{ fontFamily: 'Poppins', textTransform: 'capitalize', color: '#6c7293' }} />

                      </Tabs>
                    </div>
                    {this.state.activeTabIndex === 0 &&
                      <div className='row mb-2'>
                        <div className='col-lg m-auto '>
                          <TableContainer style={{ maxHeight: 500 }} >
                            <Table size="small" component={Paper} stickyHeader aria-label="sticky table">
                              <TableHead className='bg-light'>
                                <TableRow >
                                  <TableCell nowrap='true' >DESCRIPTION</TableCell>
                                  <TableCell nowrap='true'>GRADE</TableCell>
                                  <TableCell nowrap='true' align="right">QTY (MT)</TableCell>
                                  {(config.company === "SDAM" || config.company === "SRK" || config.company === "BTR") &&
                                    <TableCell nowrap='true' align="right">RATE (IDR)</TableCell>
                                  }
                                  <TableCell nowrap='true' align="right">RATE ($)</TableCell>
                                  {(config.company === "SDAM" || config.company === "SRK" || config.company === "BTR") &&
                                    <TableCell nowrap='true' align="right">AMOUNT (IDR)</TableCell>
                                  }
                                  <TableCell nowrap='true' align="right">AMOUNT ($)</TableCell>
                                </TableRow>
                              </TableHead>

                              {
                                /**
                                 * 
                                 * ========================================= COAL PURCHASE START'S ============================
                                 * 
                                 */
                              }
                              {summary.purchase_coal_costing_data.length > 0 &&
                                <TableBody>
                                  <TableRow>
                                    <TableCell colSpan={(config.company === "SDAM" || config.company === "SRK" || config.company === "BTR") ? 7 : 5} style={{ color: config.themeColor }}> Coal Purchase </TableCell>
                                  </TableRow>
                                  {summary.purchase_coal_costing_data.map((e, idx) =>
                                    <TableRow key={idx}>
                                      <TableCell nowrap='true'>{e.supplier_name}</TableCell>
                                      {/* <TableCell>{this.state.coal_supplier_name}</TableCell> */}
                                      <TableCell nowrap='true'>{e.quality}</TableCell>
                                      <TableCell className='text-right'>{this.toLocaleString(e.barge_quantity, "quantity")}</TableCell>
                                      {(config.company === "SDAM" || config.company === "SRK" || config.company === "BTR") &&
                                        <TableCell nowrap='true' className='text-right'>{this.toLocaleString(e.adjusted_coal_price_pmt_idr)}</TableCell>
                                      }
                                      <TableCell nowrap='true' className='text-right'>{this.toLocaleString(e.adjusted_coal_price_pmt_usd)}</TableCell>
                                      {(config.company === "SDAM" || config.company === "SRK" || config.company === "BTR") &&
                                        <TableCell nowrap='true' className='text-right'>{this.toLocaleString(e.adjusted_coal_price_idr)}</TableCell>
                                      }
                                      <TableCell nowrap='true' className='text-right'>{this.toLocaleString(e.adjusted_coal_price_usd)}</TableCell>
                                    </TableRow>

                                  )}
                                  <TableRow>
                                    <TableCell colSpan={(config.company === "SDAM" || config.company === "SRK" || config.company === "BTR") ? 5 : 4}></TableCell>
                                    {(config.company === "SDAM" || config.company === "SRK" || config.company === "BTR") &&
                                      <TableCell style={{ textAlign: 'right', fontWeight: 'bold' }}>{this.toLocaleString(this.state.coal_cost_amount_idr)}</TableCell>
                                    }
                                    <TableCell style={{ textAlign: 'right', fontWeight: 'bold' }}>{this.toLocaleString(this.state.coal_cost_amount_usd)}</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell colSpan={(config.company === "SDAM" || config.company === "SRK" || config.company === "BTR") ? 5 : 4}></TableCell>
                                    {(config.company === "SDAM" || config.company === "SRK" || config.company === "BTR") &&
                                      <TableCell style={{ textAlign: 'right', fontWeight: 'bold', whiteSpace: 'nowrap' }} className='text-success'>
                                        Grand Total :&emsp;

                                    {
                                          this.state.purchase_grand_total_idr ? this.toLocaleString(this.state.purchase_grand_total_idr) : 0
                                        }
                                      </TableCell>
                                    }
                                    <TableCell style={{ textAlign: 'right', fontWeight: 'bold', whiteSpace: 'nowrap' }} className='text-success'>
                                      Grand Total :&emsp;

                                  {
                                        this.state.purchase_grand_total_usd ? this.toLocaleString(this.state.purchase_grand_total_usd) : 0
                                      }

                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              }
                            </Table>
                          </TableContainer>
                        </div>
                      </div>

                    }
                    {this.state.activeTabIndex === 1 &&
                      <div className='row mb-2'>
                        <div className='col-lg m-auto '>
                          <TableContainer style={{ maxHeight: 500 }}>
                            <Table size="small" component={Paper} stickyHeader aria-label="sticky table">
                              <TableHead className='bg-light'>
                                <TableRow>
                                  <TableCell nowrap='true'>DESCRIPTION</TableCell>
                                  <TableCell nowrap='true'>GRADE</TableCell>
                                  <TableCell nowrap='true' align="right">QTY (MT)</TableCell>
                                  {(config.company === "SDAM" || config.company === "SRK" || config.company === "BTR") &&
                                    <TableCell nowrap='true' align="right">Exchange Rate</TableCell>
                                  }
                                  {(config.company === "SDAM" || config.company === "SRK" || config.company === "BTR") &&
                                    <TableCell nowrap='true' align="right">RATE (IDR)</TableCell>
                                  }
                                  <TableCell nowrap='true' align="right">RATE ($)</TableCell>
                                  {(config.company === "SDAM" || config.company === "SRK" || config.company === "BTR") &&
                                    <TableCell nowrap='true' align="right">AMOUNT (IDR)</TableCell>
                                  }
                                  <TableCell nowrap='true' align="right">AMOUNT ($)</TableCell>
                                </TableRow>
                              </TableHead>

                              {
                                /**
                                 * 
                                 * ========================================= COAL PURCHASE START'S ============================
                                 * 
                                 */
                              }
                              {summary.sales_coal_costing_data.length > 0 &&
                                <TableBody>
                                  <TableRow>
                                    <TableCell colSpan={(config.company === "SDAM" || config.company === "SRK" || config.company === "BTR") ? 8 : 5} style={{ color: config.themeColor }}> Coal Sales </TableCell>
                                  </TableRow>
                                  {summary.sales_coal_costing_data.map((e, idx) =>
                                    <TableRow key={idx}>
                                      <TableCell nowrap='true'>{e.buyer_name} {e.mother_vessel_name ? "(" + e.mother_vessel_name + ")" : ""}</TableCell>
                                      <TableCell nowrap='true'>{e.quality}</TableCell>
                                      <TableCell nowrap='true' className='text-right'>{this.toLocaleString(e.barge_quantity, "quantity")}</TableCell>
                                      {(config.company === "SDAM" || config.company === "SRK" || config.company === "BTR") &&
                                        <TableCell nowrap='true' className='text-right'>{this.toLocaleString(e.exchg_rate)}</TableCell>
                                      }
                                      {(config.company === "SDAM" || config.company === "SRK" || config.company === "BTR") &&
                                        <TableCell nowrap='true' className='text-right'>{this.toLocaleString(e.adjusted_coal_price_pmt_idr)}</TableCell>
                                      }
                                      <TableCell nowrap='true' className='text-right'>{this.toLocaleString(e.adjusted_coal_price_pmt_usd)}</TableCell>
                                      {(config.company === "SDAM" || config.company === "SRK" || config.company === "BTR") &&
                                        <TableCell nowrap='true' className='text-right'>{this.toLocaleString(e.adjusted_coal_price_idr)}</TableCell>
                                      }
                                      <TableCell nowrap='true' className='text-right'>{this.toLocaleString(e.adjusted_coal_price_usd)}</TableCell>
                                    </TableRow>

                                  )}
                                  <TableRow>
                                    <TableCell colSpan={(config.company === "SDAM" || config.company === "SRK" || config.company === "BTR") ? 6 : 4}></TableCell>
                                    {(config.company === "SDAM" || config.company === "SRK" || config.company === "BTR") &&
                                      <TableCell style={{ textAlign: 'right', fontWeight: 'bold' }}>{this.toLocaleString(this.state.sales_coal_cost_amount_idr)}</TableCell>
                                    }
                                    <TableCell style={{ textAlign: 'right', fontWeight: 'bold' }}>{this.toLocaleString(this.state.sales_coal_cost_amount_usd)}</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell colSpan={(config.company === "SDAM" || config.company === "SRK" || config.company === "BTR") ? 6 : 4}></TableCell>
                                    {(config.company === "SDAM" || config.company === "SRK" || config.company === "BTR") &&
                                      <TableCell style={{ textAlign: 'right', fontWeight: 'bold', whiteSpace: 'nowrap' }} className='text-success'>
                                        Grand Total :&emsp;

                                    {
                                          this.state.sales_grand_total_idr ? this.toLocaleString(this.state.sales_grand_total_idr) : 0
                                        }
                                      </TableCell>
                                    }
                                    <TableCell style={{ textAlign: 'right', fontWeight: 'bold', whiteSpace: 'nowrap' }} className='text-success'>
                                      Grand Total : &emsp;
                                  {
                                        this.state.sales_grand_total_usd ? this.toLocaleString(this.state.sales_grand_total_usd) : 0
                                      }

                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              }
                            </Table>
                          </TableContainer>
                        </div>
                      </div>

                    }


                  </div>
                </div>
                {/* <div className='row bottom_buttons_section'>
                  <button type='button' className='btn previous_button' name='submit' onClick={() => window.location.href = '/services'}>Cancel</button>
                  <button type='button' className='btn next_button' name='Back' onClick={this.submitHandler}>
                    {/* <span className={this.state.submitting ? "spinner-grow spinner-grow-sm mr-2" : ""}></span> *
Submit</button>
                </div> */}
              </form>
            </div>
          }

        </div>
      </div>
    )
  }
}