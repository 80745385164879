import React, { Component } from "react";
import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import api from "../../../api/api";
import { Alert } from "@material-ui/lab";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Snackbar,
} from "@material-ui/core";
import { localDateFormate, toLocaleString } from "../../common/common";
import ViewFiles from "../../common/ViewFiles";
import InvoiceForm from "../../common/InvoiceForm";
import PaymentPostings from "../../common/PaymentPostings";
import Loader from "../../common/Loader";
import BusinessApproveButton from "../../Approvals/BusinessApproveButton";
import InvoiceLink from "../../InvoicePdf/InvoiceLink";
export default class MVProcurementChargesView extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      multiRows: [
        {
          base_price: "",
          base_price_pmt: "",
          billing_currency: "",
          costing_id: "",
          currency_xchg_rate:"",
        },
      ],
      isLoading: true,
      stevedor_price_payments: [],
      features: [],
      closeDialog: false,
      invoiceDialog: false,
      mother_vessel_name:"",
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (
      !(
        features.includes("235") ||
        features.includes("415") ||
        features.includes("466") ||
        features.includes("97")
      )
    ) {
      window.location.href = "/dashboard";
    }
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const businessNoID = this.props.businessNoID;
    //Get businessNumber API based on businessNumber
    await api
      .getBusinessNumber(businessNoID, loginUserID, idToken)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.business_number) {
                var total_delivered_quantity = 0;
                for (var i of res.business_number.business_number_allocation) {
                  total_delivered_quantity += Number(i.bna_quantity_delivered);
                }
                this.setState({
                  businessNo: res.business_number.business_no
                    ? res.business_number.business_no
                    : "",
                  mother_vessel_name: res.business_number.mother_vessel_name
                    ? res.business_number.mother_vessel_name
                    : "",
                  quantity_in_mt: total_delivered_quantity,
                  //isLoading: false
                });
              }
            } else if (res.code === "601") {
              // this.props.response(res.code);
              this.setState({
                errorMsg: res.message,
                successMsg: "",
                closeDialog: true,
              });
              // window.$("#AlertMessageModal").modal({
              //   backdrop: 'static',
              //   keyboard: true,
              //   show: true
              // });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });

    const coalProcurementChargesID = this.props.coalProcurementChargesID;
    var data = [];
    //Get businessNumber API based on businessNumber
    await api
      .get_mv_pur_fin_cpc(loginUserID, idToken, coalProcurementChargesID)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            data = res.mv_pur_fin_cpc;
          });
        }
      });

    await api
      .get_mv_purchase_financials_cpc(loginUserID, idToken, businessNoID)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              var paid_to_vendor = 0;
              var remaining_amount = 0;
              for (var i = 0; i < data.length; i++) {
                if (data[i].cpc_payments.length > 0) {
                  for (var j of data[i].cpc_payments) {
                    if (j.date_of_payment) {
                      paid_to_vendor += Number(j.amount);
                    }
                    remaining_amount =
                      Number(data[i].payable_to_vendor) -
                      Number(paid_to_vendor);
                  }
                  data[i].paid_to_vendor = paid_to_vendor;
                  data[i].remaining_amount = remaining_amount;
                  paid_to_vendor = 0;
                  remaining_amount = 0;
                } else {
                  data[i].remaining_amount = Number(data[i].payable_to_vendor);
                }
              }
              if (data.length > 0) {
                this.setState({
                  multiRows: data,
                  base_price: data[0].base_price,
                  base_price_pmt: data[0].base_price_pmt,
                  billing_currency: data[0].billing_currency,
                  payee_name: data[0].payee_name,
                  costing_no: data[0].costing_no,
                  costing_id: data[0].costing_id,
                  isLoading: false,
                });
              } else if (data.length === 0) {
                // this.props.response(data.length);
                this.setState({
                  isLoading: false,
                  errorMsg: "No Purchase financials data available.",
                  successMsg: "",
                  closeDialog: true,
                });
                // window.$("#AlertMessageModal").modal({
                //   backdrop: 'static',
                //   keyboard: true,
                //   show: true
                // });
              }
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                successMsg: "",
                closeDialog: true,
              });
              // window.$("#AlertMessageModal").modal({
              //   backdrop: 'static',
              //   keyboard: true,
              //   show: true
              // });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            } else if (res.code === "624") {
              this.setState({
                isLoading: false,
                errorMsg: res.columns.length > 0 ? res.columns[0] : "",
                successMsg: "",
                closeDialog: true,
              });
              // window.$("#AlertMessageModal").modal({
              //   backdrop: 'static',
              //   keyboard: true,
              //   show: true
              // });
            }
          });
        }
      });
  }

  updateInvoice = (data) => {
    let request = {
      ...data,
      table_name: "mv_pur_fin_cpc",
    };
    api.update_invoice_details(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.componentDidMount();
            this.setState({
              invoiceDialog: false,
            });
          }
        });
      }
    });
  };

  renderPaymentPostings = (data) => {
    const {
      id,
      cpc_payments = [],
      invoice_no = null,
      remaining_amount = 0,
      currency_xchg_rate = 1,
      billing_currency,
      approval_status = "Pending",
    } = data;
    let payment_related_data = {
      paymentTitle: "MV Coal Procurement Charges",
      payment_posting_type: "cpc",
      invoice_no: invoice_no,
      outstanding_amount: remaining_amount,
      billing_currency: billing_currency
        ? billing_currency
        : config.company === "SRPL" || config.company === "AVS" || config.company === "PRPL"
          ? "USD"
          : "IDR",
      currency_xchg_rate: currency_xchg_rate ? currency_xchg_rate : 1,
      costing_id: id,
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
    };

    let access_details = {
      costing_approval: approval_status,
      add_payment: "234",
      view_payment: "235",
      update_payment: "236",
      delete_payment: "237",
      approver: "415",
      approver2: "446",
    };

    return (
      <PaymentPostings
        payments={cpc_payments}
        access_details={access_details}
        costingType="MV Coal Procurement Charges"
        data={payment_related_data}
        callbackFn={() => {
          this.componentDidMount();
        }}
      />
    );
  };

  renderInvoiceLink = () => {
    const { costing_no } = this.state;
    const Grand_total = (
      Number(
        this.state.base_price
          ? this.state.base_price
          : 0
      ) +
      Number(
        this.state.multiRows[0].vat_value
          ? this.state.multiRows[0].vat_value
          : 0
      ) -
      Number(
        this.state.multiRows[0].pph23_value
          ? this.state.multiRows[0].pph23_value
          : 0
      )
    ).toFixed(2);

    const invoiceObject = {
      costing_id: this.state.multiRows[0].costing_no,
      to: this.state.multiRows[0].payment_to_vendor_name
        ? this.state.multiRows[0].payment_to_vendor_name
        : this.state.multiRows[0].payee_name,
      business_no: this.state.mother_vessel_name,
      costing_name: "MV Caol Procurement",
      invoice_no: this.state.multiRows[0].invoice_no,
      invoice_date: localDateFormate(this.state.multiRows[0].invoice_date),
      bargeDetails: [],
      billing_currency: this.state.multiRows[0].billing_currency,
      total_payable_amount: toLocaleString(Grand_total),
      purchase_type: "FOB Barge",
      description: [
        {
          qty: toLocaleString(this.state.multiRows[0].barge_quantity),
          adjustedPrice: toLocaleString(this.state.multiRows[0].base_price_pmt),
          desc: `${this.state.multiRows[0].currency_xchg_rate > 1
            ? `Coal Procurement @ ${parseFloat(
              this.state.multiRows[0].currency_xchg_rate
            ).toFixed(2)}`
            : "Coal Procurement"
            }`,
          amount: this.state.base_price,
        },
        ...(this.state.pph15_account !== "SDAM" && !!this.state.multiRows[0].vat_value
          ? [
            {
              qty: " ",
              adjustedPrice: " ",
              desc: "VAT",
              amount: `${toLocaleString(this.state.multiRows[0].vat_value)}`,
            },
          ]
          : []),
        ...(!(config.company === "SRPL" || config.company === "AVS" || config.company === "PRPL") && !!this.state.multiRows[0].pph23_value
          ? [
            {
              qty: " ",
              adjustedPrice: " ",
              desc: "PPH",
              amount: `( ${toLocaleString(
                this.state.multiRows[0].pph23_value
              )} )`,
            },
          ]
          : []),
      ],
    };
    return <InvoiceLink data={invoiceObject} fileName={costing_no} />;
  };

  render() {
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div
          className="container bg-white p-0"
          style={{ minHeight: "100vh", width: 850 }}
        >
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>

          {this.state.multiRows.map((e, idx) => (
            <div className="clearfix content-header border-bottom">
              <div className="row">
                <div className="col-lg-10 text-left pl-0">
                  <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                    {`View Coal procurement Charges - ${e.costing_no}`}
                    <span
                      className={
                        e.approval_status === "Pending" ? "type_2" : "type_1"
                      }
                      style={{ padding: 8, marginLeft: 16 }}
                    >
                      {e.approval_status}
                    </span>
                  </h4>
                </div>
                {e.approval_status === "Pending" &&
                  this.Cookie.getCookie("loginUserId") !== e.created_by &&
                  e.approved_by !== this.Cookie.getCookie("loginUserId") &&
                  (this.state.features.includes("415") ||
                    this.state.features.includes("466") ||
                    this.state.features.includes("97")) && (
                    <BusinessApproveButton
                      costingID={e.id}
                      costingType={"MV Coal Procurement Charges"}
                      accessInfo={{
                        ...e,
                        approver_1: "415",
                        approver_2: "466",
                      }}
                      callbackFn={() => this.componentDidMount()}
                    />
                  )}
                <div className="col-lg-1 pl-0 float-right">
                  {this.renderInvoiceLink()}
                </div>
              </div>
            </div>
          ))}
          <div className="drawer-section">
            <div className="card p-3">
              {this.state.multiRows.map((e, idx) => (
                <><div className="row mb-3" key={idx}>
                  <div className="col-lg-12 p-2 border_dashed">
                    <div className="col shadow-sm p-0 mb-2 bg-white border">
                      {/* New Changes */}
                      <div className="table-responsive">
                        <table className="table table-sm table-bordered mb-0">
                          <thead className="table-header">
                            <tr>
                              <th className="text-center">Description</th>
                              <th className="text-right">Qty</th>
                              <th className="text-right">Unit Price</th>
                              <th className="text-right">Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.multiRows.map((e, index) => (
                              <>
                                <tr>
                                  <td>
                                    Coal Procurement
                                    {this.state.currency_xchg_rate > 1 &&
                                      <div>
                                        Exchange Rate @{parseFloat(this.state.currency_xchg_rate).toFixed(2)}
                                      </div>}
                                    <div>
                                      Payment To: {e.payment_to_vendor_name
                                        ? e.payment_to_vendor_name
                                        : "Others"}
                                    </div>
                                    {(e.payment_to === null || e.payee_name) &&
                                      <div>
                                        Payee Name: {e.payee_name}
                                      </div>}
                                  </td>
                                  <td className="text-right">
                                    {toLocaleString(e.barge_quantity)}
                                  </td>
                                  <td className="text-right">
                                    {toLocaleString(e.base_price_pmt)}
                                  </td>
                                  <td className="text-right">
                                    {toLocaleString(e.base_price)}
                                  </td>

                                </tr>
                                {(this.state.vat_applicability === "Inclusive" ||
                                  config.company !== "SRPL" ||
                                  config.company !== "PRPL" ||
                                  config.company !== "AVS") &&
                                  e.vat_value > 0 && (
                                    <tr>
                                      <td>VAT @ 10 %</td>
                                      <td className="text-right"></td>
                                      <td className="text-right"></td>
                                      <td className="text-right">
                                        {toLocaleString(e.vat_value)}
                                      </td>
                                    </tr>
                                  )}
                                {!(
                                  config.company === "SRPL" ||
                                  config.company === "AVS" ||
                                  config.company === "PRPL"
                                ) && e.pph23_account === "Supplier" && e.pph23_value && (
                                    <tr>
                                      <td>PPH23 @{toLocaleString(e.pph23_percent)} % </td>
                                      <td></td>
                                      <td></td>
                                      <td className="text-right">({toLocaleString(e.pph23_value)})</td>
                                    </tr>
                                  )}
                                {!(
                                  config.company === "SRPL" ||
                                  config.company === "AVS" ||
                                  config.company === "PRPL"
                                ) && e.pph23_account === "SDAM" && e.pph23_value && (
                                    <tr>
                                      <td>PPH23 @{toLocaleString(e.pph23_percent)} %</td>
                                      <td></td>
                                      <td></td>
                                      <td className="text-right">({toLocaleString(e.pph23_value)})</td>
                                    </tr>
                                  )}

                                <tr>
                                  <td colSpan={3} className="text-right ">
                                    {" "}
                                    Payable to Vendor :{" "}
                                  </td>
                                  <td className="text-right ">{`${config.company === "SRPL" ||
                                    config.company === "PRPL" ||
                                    config.company === "AVS"
                                    ? "USD"
                                    : "IDR"} ${toLocaleString(
                                      parseFloat(e.payable_to_vendor)
                                    )} `}</td>
                                </tr>
                              </>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="col-lg-12 mb-1 p-0">
                      <label className="drawer_label mb-1">Remarks</label>
                      <div
                        className="contract_display_header_value"
                        style={{ whiteSpace: "pre-line" }}
                      >
                        {e.remarks ? e.remarks : "-"}
                      </div>
                    </div>
                   {(this.state.features.includes("239") ||
                      this.state.features.includes("97")) && (
                        <div className="card p-0 border">
                          <div className="card-header section_header">
                            <h5>Coal Procurement Charges Payment Postings</h5>
                          </div>
                          <div className="card-body">
                            <InvoiceForm
                              open={this.state.invoiceDialog}
                              data={e}
                              costingID={e.id}
                              callbackFn={(data) => this.updateInvoice(data)} />
                            {this.renderPaymentPostings(e)}
                          </div>
                        </div>
                      )}
                  </div>

                  <ViewFiles {...this.props} files_data={e.files} />
                </div>
                  <div className="col shadow-sm p-0 mb-2 bg-white border">
                    <div className="table-responsive">
                      <table className="table table-sm table-bordered">
                        <thead className="table-header">
                          <tr>
                            <th className="text-center">Approval Type</th>
                            <th className="text-center">Approved by</th>
                            <th className="text-center">Approved On</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="text-center">Business</td>
                            <td className="text-center">
                              <div> {e.approved_by_name}</div>
                            </td>
                            <td className="text-center">
                              {localDateFormate(e.approved_date)}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-center">Financial</td>
                            <td className="text-center">
                              {e.approved_by_name_2}
                            </td>
                            <td className="text-center">
                              {localDateFormate(e.approved_date_2)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
          <footer className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button btn-light"
              name="submit"
              onClick={() => {
                this.props.returnCallbackDrawerClose(false);
              }}
            >
              Close
            </button>
            <button
              type="button"
              className="drawer_text drawer_btn_success"
              name="Back"
              onClick={() => {
                this.props.returnEditCallback("MvCpc");
              }}
            >
              Edit
            </button>
          </footer>

          {/**
           *
           * TODO // Alert Message Modal
           *
           */}

          <Dialog
            open={this.state.closeDialog}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              <i
                className="fa fa-exclamation-triangle"
                aria-hidden="true"
                style={{ color: "#e0a51e", marginBottom: "5px" }}
              ></i>
              &emsp; {this.state.errorMsg}
            </DialogTitle>
            <DialogActions>
              <button
                className="drawer_button drawer_text drawer_calculate_adjustment"
                onClick={() => {
                  this.props.returnCallbackDrawerClose(false);
                  this.setState({ closeDialog: false });
                }}
              >
                Ok
              </button>
            </DialogActions>
          </Dialog>
        </div>
      );
    }
  }
}
