import React, { Component } from "react";
import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import api from "../../../api/api";

import { Snackbar, Drawer, TextField } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Autocomplete from "@material-ui/lab/Autocomplete";

import BargeVendorDemurrageAdd from "./Vendor-Demurrage-Add";
import BargeVendorDemurrageView from "./Vendor-Demurrage-View";
import BargeVendorDemurrageUpdate from "./Vendor-Demurrage-Update";
import DeleteConfirmation from "../../common/DeleteConfirmation";
import { pad } from "../../common/common";
import Loader from "../../common/Loader";

export default class BargeVendorDemurrageList extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      showDrawer: false,
      vendorDemurrageAdd: false,
      vendorDemurrageView: false,
      vendorDemurrageUpdate: false,
      features: [],
      barge_vendor_demurrages: [],
      bargeVendorsData: [],
      demurrageData: null,
      isLoading: true,
      updateDocuments: false,
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (
      !(
        features.includes("53") ||
        features.includes("151") ||
        features.includes("97")
      )
    ) {
      window.location.href = "/dashboard";
    }
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    //Get vendors
    await api.getVendors(loginUserID, idToken).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);

            if (res.vendors) {
              const bargeVendorsData = [];
              for (var i of res.vendors) {
                if (i.vendor_type === "Barge") {
                  bargeVendorsData.push({
                    name: i.vendor_name,
                    value: i.id,
                    key: i,
                  });
                }
              }
              this.setState({
                bargeVendorsData: bargeVendorsData,
                isLoading: false,
              });
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
  }

  seachDemurragesHandler() {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const barge_vendor = this.state.bargeVendorName;
    api
      .get_barge_demurrage_to_barge_vendors(loginUserID, idToken, barge_vendor)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState({
                barge_vendor_demurrages: res.barge_demurrage_to_barge_vendor,
                isLoading: false,
              });
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                successMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  }

  CallbackDrawerClose = (childData) => {
    this.setState({
      showDrawer: childData,

      vendorDemurrageAdd: false,
      vendorDemurrageView: false,
      updateDocuments: false,
    });
  };

  onAddResponse = (value) => {
    this.setState(
      {
        vendorDemurrageAdd: false,
        showDrawer: false,
        updateDocuments: false,
      },
      () => {
        this.seachDemurragesHandler();
      }
    );
  };

  editCallback = (childData) => {
    this.setState({
      showDrawer: true,
      vendorDemurrageAdd: false,
      vendorDemurrageUpdate: true,
      vendorDemurrageView: false,
      updateDocuments: false,
    });
  };

  onUpdateResponse = (value) => {
    if (value) {
      this.setState({
        showDrawer: true,
        coalCostingAdd: false,
        vendorDemurrageView: true,
        vendorDemurrageUpdate: false,
        updateDocuments: false,
      });
    }
  };

  CallbackViewPage = (childData) => {
    this.setState({
      showDrawer: true,
      vendorDemurrageView: true,
      vendorDemurrageUpdate: false,
      updateDocuments: false,
    });
  };

  toLocaleString(value) {
    return value ? Number(value).toLocaleString() : 0;
  }

  deleteDemurrageHandler = (barge_vendor_demurrage_id) => {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    api
      .delete_barge_demurrage_to_barge_vendor(
        loginUserID,
        idToken,
        barge_vendor_demurrage_id
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState(
                {
                  deleteCosting: false,
                  snackBarSuccessOpen: true,
                  successMsg: res.message,
                },
                () => {
                  this.componentDidMount();
                }
              );
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            } else if (res.code === "651") {
              this.setState({
                deleteCosting: false,
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            }
          });
        }
      });
  };

  render() {
    let drawerComponent;
    if (this.state.vendorDemurrageAdd) {
      drawerComponent = (
        <BargeVendorDemurrageAdd
          onAddResponse={this.onAddResponse}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
        />
      );
    }
    if (this.state.vendorDemurrageView) {
      drawerComponent = (
        <BargeVendorDemurrageView
          demurrageData={this.state.demurrageData}
          comboID={this.state.comboID}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.editCallback}
          returnDocumentsHandler={() => {
            this.setState({
              showDrawer: true,
              vendorDemurrageUpdate: true,
              vendorDemurrageView: false,
              vendorDemurrageAdd: false,
              updateDocuments: true,
            });
          }}
        />
      );
    }
    if (this.state.vendorDemurrageUpdate) {
      drawerComponent = (
        <BargeVendorDemurrageUpdate
          demurrageData={this.state.demurrageData}
          comboID={this.state.comboID}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.CallbackViewPage}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }
    return (
      <div id="wraper">
        <div className="content">
          {this.state.isLoading && <Loader />}
          {!this.state.isLoading && (
            <div className="content-header">
              <Drawer
                anchor="right"
                open={this.state.showDrawer}
                variant="temporary"
                elevation={20}
                style={{ overflow: "initial" }}
              >
                <div className="row" style={{ width: 800 }}>
                  {drawerComponent}
                </div>
              </Drawer>
              <Snackbar
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                open={this.state.snackBarErrorOpen}
                onClose={() => this.setState({ snackBarErrorOpen: false })}
                style={{ width: "450px" }}
              >
                <Alert
                  elevation={6}
                  variant="filled"
                  onClose={() => this.setState({ snackBarErrorOpen: false })}
                  severity="error"
                  style={{ width: "100%" }}
                >
                  {this.state.errorMsg}
                </Alert>
              </Snackbar>
              <Snackbar
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                style={{ width: "450px" }}
                open={this.state.snackBarSuccessOpen}
                autoHideDuration={100000}
                onClose={() => this.setState({ snackBarSuccessOpen: false })}
              >
                <Alert
                  elevation={6}
                  variant="filled"
                  onClose={() => this.setState({ snackBarSuccessOpen: false })}
                  severity="success"
                  style={{ width: "100%" }}
                >
                  {this.state.successMsg}
                </Alert>
              </Snackbar>
              <div className="card p-4">
                <div className="row mb-3">
                  <div className="col-lg-4 p-0">
                    {/* <label className='form_label mb-0'>Barge Vendor</label> */}
                    <Autocomplete
                      options={this.state.bargeVendorsData}
                      getOptionLabel={(option) => option.name}
                      size="small"
                      onChange={(event, value) => {
                        value != null
                          ? this.setState({
                              bargeVendorName: value.value,
                              bargeVendorNameValue: value,
                              vendorTypeError: false,
                            })
                          : this.setState({
                              bargeVendorName: "",
                              vendorTypeError: false,
                            });
                      }}
                      name="bargeVendorName"
                      value={this.state.bargeVendorNameValue}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Search Barge Vendor"
                          placeholder="Search Barge Vendor"
                          variant="standard"
                          error={this.state.vendorTypeError}
                          fullWidth
                          style={{ top: 8 }}
                        />
                      )}
                      style={{ display: "contents" }}
                    />
                  </div>
                  <div className="col-lg-2">
                    <button
                      className="header_button header_button_text add_button_adjustment"
                      style={{ width: "auto", marginTop: "25px" }}
                      onClick={(event) => {
                        this.seachDemurragesHandler();
                      }}
                    >
                      Load
                    </button>
                  </div>
                  <div className="col text-right float-sm-left">
                    <button
                      className="header_button header_button_text add_button_adjustment"
                      style={{ width: "auto", marginTop: "25px" }}
                      onClick={(event) => {
                        this.setState({
                          vendorDemurrageAdd: true,
                          vendorDemurrageView: false,
                          vendorDemurrageUpdate: false,
                          showDrawer: true,
                        });
                      }}
                    >
                      New 
                    </button>
                  </div>
                </div>

                {this.state.barge_vendor_demurrages.length > 0 ? (
                  <div className="row">
                    <div className="table-responsive">
                      <table className="table table-sm table-bordered table-hover">
                        <thead className="table-header">
                          <tr>
                            <th nowrap="true" className="text-center">
                              {" "}
                              Action{" "}
                            </th>
                            <th nowrap="true"> Costing ID </th>
                            <th nowrap="true"> Barge ID </th>
                            <th nowrap="true"> Barge Nomination </th>
                            <th nowrap="true"> Barge Vendor </th>
                            <th nowrap="true" className="text-right">
                              {" "}
                              Total Payable
                            </th>
                            <th nowrap="true" className="text-right">
                              Paid
                            </th>
                            <th nowrap="true" className="text-right">
                              Remaining
                            </th>
                          </tr>
                        </thead>
                        {this.state.barge_vendor_demurrages.map((id, idx) => (
                          <tbody key={idx}>
                            <tr>
                              <td className="text-center">
                                <i
                                  className="fa fa-eye mr-2"
                                  aria-hidden="true"
                                  style={{
                                    fontSize: 20,
                                    color: config.themeColor,
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    this.setState({
                                      vendorDemurrageView: true,
                                      comboID: id.id + "$#" + id.barge_id,
                                      showDrawer: true,
                                      demurrageData: id,
                                    })
                                  }
                                ></i>
                                {(this.state.features.includes("129") ||
                                  this.state.features.includes("97")) &&
                                  id.approval_status === "Pending" && (
                                    <i
                                      className="fa fa-trash-o"
                                      style={{
                                        fontSize: 20,
                                        color: config.themeColor,
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        this.setState({
                                          deleteCosting: true,
                                          barge_vendor_demurrage_id: id.id,
                                          closeDialog: true,
                                        })
                                      }
                                    ></i>
                                  )}
                              </td>
                              <td nowrap="true">
                                {id.costing_no ? id.costing_no : "-"}
                              </td>
                              <td>{pad(id.barge_id)}</td>
                              <td nowrap="true">
                                {id.barge_nomination
                                  ? id.barge_nomination
                                  : "-"}
                              </td>
                              <td nowrap="true">
                                {id.barge_vendor_name
                                  ? id.barge_vendor_name
                                  : "-"}
                              </td>
                              <td className="text-right" nowrap="true">
                                {this.toLocaleString(id.total_payable)}
                              </td>
                              <td className="text-right" nowrap="true">
                                {this.toLocaleString(id.paid)}
                              </td>
                              <td className="text-right" nowrap="true">
                                {this.toLocaleString(id.Remaining_amount)}
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </table>
                    </div>
                  </div>
                ) : (
                  <div className="row">
                    <p>No records to display</p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        {/**
         *
         * Delete Payament Postings Conformation Modal
         *
         */}

        {this.state.deleteCosting && (
          <DeleteConfirmation
            open={this.state.deleteCosting}
            close={() => this.setState({ deleteCosting: false })}
            callbackFn={() =>
              this.deleteDemurrageHandler(this.state.barge_vendor_demurrage_id)
            }
          />
        )}
      </div>
    );
  }
}
