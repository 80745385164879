import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { pad } from '../common/common';

const borderColor = 'black'

const styles = StyleSheet.create({

  row: {
    flexDirection: 'row',
    // borderColor: borderColor,
    borderBottomWidth: 0.4,
    borderBottomColor: "black",
    borderTop: 0.4,
    borderTopColor: "black",
    alignItems: 'center',
    marginTop: 10,
    fontStyle: 'bold',
  },
  bargeName: {
    width: '15%',
    fontSize: 8,
    textAlign: 'center',

    padding: 4,
  },
  bargeName1: {
    width: '15%',
    fontSize: 8,
    textAlign: 'center',
    minHeight: 35,
    paddingTop: 8,

    alignSelf: 'center'
  },
  bargeNomination: {
    width: '25%',
    fontSize: 8,
    textAlign: 'center',
    borderRight: 0.4,
    borderLeft: 0.4,
    padding: 4,
    borderRightColor: "black",

  },
  bargeNomination1: {
    width: '25%',
    fontSize: 8,
    textAlign: 'center',
    borderRight: 0.4,
    borderLeft: 0.4,
    // padding: 4,
    borderRightColor: "black",
    // height: 10,
    minHeight: 35,
    paddingTop: 8,
    // padding: 4,

  },
  mvName: {
    width: '15%',
    fontSize: 8,
    textAlign: 'center',
    // borderRight: 0.4,
    padding: 4,
    borderRightColor: "black",
  },
  mvName1: {
    width: '15%',
    fontSize: 8,
    textAlign: 'center',
    // borderRight: 0.4,
    // padding: 4,
    borderRightColor: "black",
    // height: 10,
    minHeight: 35,
    paddingTop: 8,
    // padding: 4,

  },
  pcName: {
    width: '20%',
    fontSize: 8,
    textAlign: 'center',
    borderRight: 0.4,
    padding: 4,
    borderRightColor: "black",
    // borderColor: '#000000'
    borderTop: 0,
    borderTopColor: 'black',
    borderLeftColor: 'black',
    borderLeft: 0.4,
  },
  pcName1: {
    width: '20%',
    fontSize: 8,
    textAlign: 'center',
    borderRight: 0.4,
    // padding: 4,
    borderRightColor: "black",
    // borderColor: '#000000'
    borderTop: 0,
    borderTopColor: 'black',
    borderLeftColor: 'black',
    borderLeft: 0.4,
    minHeight: 35,
    paddingTop: 8,
    // padding: 4,

  },

  quality: {
    width: '10%',
    fontSize: 8,
    textAlign: 'center',
    borderRight: 0.4,
    padding: 4,
    borderRightColor: 'black',
    // borderColor: '#000000'
  },
  quality1: {
    width: '10%',
    fontSize: 8,
    textAlign: 'center',
    borderRight: 0.4,
    // padding: 4,
    borderRightColor: 'black',
    minHeight: 35,
    paddingTop: 8,
    // padding: 4,

  },
  vendor: {
    width: '20%',
    fontSize: 8,
    textAlign: 'center',
    borderRight: 0.4,
    padding: 4,
    borderRightColor: 'black',
    // borderColor: '#000000'
  },
  vendor1: {
    width: '20%',
    fontSize: 8,
    textAlign: 'center',
    borderRight: 0.4,
    // padding: 4,
    borderRightColor: 'black',
    minHeight: 35,
    paddingTop: 8,
    // padding: 4,
    // borderColor: '#000000'
  },
  grade: {
    width: '13%',
    fontSize: 8,
    textAlign: 'center',
    borderRight: 0.4,
    padding: 4,
    borderRightColor: borderColor,
  },
  grade1: {
    width: '13%',
    fontSize: 8,
    textAlign: 'center',
    borderRight: 0.4,
    // padding: 4,
    borderRightColor: borderColor,
    minHeight: 35,
    paddingTop: 8,
    // padding: 4,
  },
  rate: {
    width: '13%',
    fontSize: 8,
    textAlign: 'center',
    // borderRight: 0.4,
    padding: 4,
    // borderRightColor: borderColor,
  },
  rate1: {
    width: '13%',
    fontSize: 8,
    textAlign: 'center',
    minHeight: 35,
    paddingTop: 8,
    // padding: 4,

  },
});


const InvoiceTitle = ({ invoice }) => {
  const { purchase_type = null, purchase_index_linked = "No", costing_name } = invoice || {};
  const rows = invoice.bargeDetails.map(item =>

    <View style={{ flexDirection: 'row', alignItems: 'center', }} key={item.barge_id}>
      {(purchase_type === "FOB Barge" || purchase_type === "CPS FOB Barge") &&
        <Text style={styles.bargeName1}>{pad(item.barge_id ? item.barge_id : item.id)}</Text>
      }
      {(purchase_type === "FOB Barge" || purchase_type === "CPS FOB Barge") &&
        <Text style={styles.bargeNomination1}>{item.barge_nomination ? item.barge_nomination : "-"}</Text>
      }
      {(purchase_type === "FOB Barge" || purchase_type === "CPS FOB Barge") &&
        <Text style={styles.mvName1}>{item.loading_jetty ? item.loading_jetty : "-"}</Text>
      }
      <Text style={styles.pcName1}>{item.purchase_contract_no}</Text>

      {purchase_type === "CPS FOB Barge" && <Text style={styles.vendor1}>{item.payment_to_Vendor}</Text>}

      <Text style={styles.quality1}>{item.quality}</Text>
      <Text style={styles.grade1}>{item.purchase_type}</Text>
      {purchase_index_linked !== "Yes" ?
        costing_name !== "Export Tax" &&
        <Text style={styles.rate1}>
          {item.price_pmt ? item.price_pmt : ''}
          {item.price_per_mt && item.price_per_mt !== '' && item.price_per_mt !== null
            ? parseFloat(item.price_per_mt).toFixed(2) : ''}
          {purchase_type === "CPS FOB Barge" && item.base_price_pmt
            ? parseFloat(item.base_price_pmt).toFixed(2) : ''}
        </Text>
        :
        <Text style={styles.rate1}>Index Linked</Text>
      }

    </View>
  )

  return (

    <Fragment>
      <View style={styles.row}>
        {(purchase_type === "FOB Barge" || purchase_type === "CPS FOB Barge") &&
          <Text style={styles.bargeName}>Barge ID</Text>
        }
        {(purchase_type === "FOB Barge" || purchase_type === "CPS FOB Barge") &&
          <Text style={styles.bargeNomination}>Barge Name</Text>
        }
        {(purchase_type === "FOB Barge" || purchase_type === "CPS FOB Barge") &&
          <Text style={styles.mvName}>Loading Jetty</Text>
        }
        <Text style={styles.pcName}>Purchase Contract</Text>

        {purchase_type === "CPS FOB Barge" &&
          <Text style={styles.vendor}>Vendor Name</Text>}

        <Text style={styles.quality}>Grade</Text>
        <Text style={styles.grade}>Inco Term</Text>
        {costing_name !== "Export Tax" && <Text style={styles.rate}>Price PMT</Text>}
      </View>
      {rows}

      <Text style={{
        borderBottom: 0.4, borderBottomColor: '#000000'
      }} />
    </Fragment>

  )
}

export default InvoiceTitle