import React, { Component } from 'react';
import config from "../../../config/config";
import CookieHandler from "../../common/CookieHandler";
import {
    TableContainer, Table, TableHead, TableBody,
    Tooltip, Button, Dialog, DialogTitle, DialogActions, TextField
} from '@material-ui/core';
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";

import api from "../../../api/api";
import Loader from '../../common/Loader';


export default class SalesContainer extends Component {
    constructor(props) {
        super(props);
        this.Cookie = new CookieHandler();

        this.state = {
            features: [],
            purchaseSamplingData: [],
            filteredPurchaseSamplingData: [],
            isLoading: true,
            loadMore: 0,
            confirmDialogueBargeId: '',
            searchInput: ''
        }
    }

    componentDidMount() {
        if (!this.Cookie.isLoggedIn()) {
            this.props.history.replace("/logout");
        }
        var features = this.Cookie.getCookie("features");
        this.setState({ features: features });
        this.getSalesSamplingData(this.props.samplingType);
    }
    getSalesSamplingData = async (samplingType, loadMore) => {
        this.setState({ isLoading: true });
        const loginUserID = this.Cookie.getCookie("loginUserId");
        const idToken = this.Cookie.getIdTokenCookie();
        await api.getSalesSamplingData(loginUserID, idToken, samplingType, loadMore).then((response) => {
            if (response.status >= 200 && response.status < 300) {
                response.json().then((res) => {
                    if (res.code === "200") {
                        if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
                        if (res.data) {
                            this.setState({
                                purchaseSamplingData: res.data,
                                isLoading: false,
                            });
                        } else {
                            alert("Unexpected error occurred. Please contact administrator.");
                            this.setState({ isLoading: false });
                        }
                    } else if (res.code === "607") {
                        this.setState({ isLoading: false });
                        window.location.href = "/logout";
                    } else {
                        this.setState({ isLoading: false });
                    }
                }).catch(err => {
                    this.setState({ isLoading: false });
                    alert(err.message)
                })
            } else {
                this.setState({ isLoading: false });
                alert("Unexpected error occured. Please contact administrator.");
            }
        }).catch(err => {
            this.setState({ isLoading: false });
            alert(err.message)
        })
    }

    isEditable = (bargeSamplingData) => {
        return bargeSamplingData.some(data => data.isEditable)
    }
    deletePurchaseSamples = async (groupId) => {
        this.setState({ isLoading: true });
        const loginUserID = this.Cookie.getCookie("loginUserId");
        const idToken = this.Cookie.getIdTokenCookie();
        await api.deletePurchaseSamples(loginUserID, idToken, this.props.samplingType, groupId).then((response) => {
            if (response.status >= 200 && response.status < 300) {
                response.json().then((res) => {
                    if (res.code === "200") {
                        if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
                        if (res.data) {
                            this.setState({
                                isLoading: false,
                            });
                            this.getSalesSamplingData(this.props.samplingType);
                        } else {
                            alert("Unexpected error occured. Please contact administrator.");
                            this.setState({ isLoading: false });
                        }
                    } else if (res.code === "607") {
                        this.setState({ isLoading: false });
                        window.location.href = "/logout";
                    }
                });
            }
        });
    }
    dissolvePurchaseSamples = async (bargeID) => {
        this.setState({ isLoading: true });
        const loginUserID = this.Cookie.getCookie("loginUserId");
        const idToken = this.Cookie.getIdTokenCookie();
        await api.dissolvePurchaseSamples(loginUserID, idToken, this.props.samplingType, bargeID).then((response) => {
            if (response.status >= 200 && response.status < 300) {
                response.json().then((res) => {
                    if (res.code === "200") {
                        if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
                        if (res.data) {
                            this.setState({
                                isLoading: false,
                            });
                            this.getSalesSamplingData(this.props.samplingType);
                        } else {
                            alert("Unexpected error occured. Please contact administrator.");
                            this.setState({ isLoading: false });
                        }
                    } else if (res.code === "607") {
                        this.setState({ isLoading: false });
                        window.location.href = "/logout";
                    }
                });
            }
        });
    }
    handleClose = () => {
        this.setState({ confirmDialogueBargeId: '' })
    }

    handleSearch = () => {
        const { searchInput, purchaseSamplingData } = this.state;
        if (searchInput) {
            const filtered = purchaseSamplingData.filter(rec => {
                return rec.businessNo.toLocaleLowerCase().includes(searchInput.toLocaleLowerCase())
            })
            this.setState({ filteredPurchaseSamplingData: filtered });
        } else {
            this.setState({ filteredPurchaseSamplingData: purchaseSamplingData });
        }
    }
    render() {
        const { filteredPurchaseSamplingData, isLoading, confirmDialogueBargeId } = this.state;
        return (
            <div>
                <div className="row">
                    {this.state.isLoading && <Loader />}
                    <TableContainer className="table-responsive" style={{ maxHeight: "80vh", width: "90vw" }}>
                        <Button color="primary" variant="contained" onClick={() => {
                            this.setState(prevState => ({
                                loadMore: prevState.loadMore + 10
                            }), () => this.getSalesSamplingData(this.props.samplingType, this.state.loadMore))
                        }} style={{ position: 'sticky', left: 0 }} disabled={isLoading}>Load More</Button>
                        <TextField
                            style={{ position: 'sticky', float: 'right', marginBottom: '10px' }}
                            label="search Business number"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment>
                                        <IconButton>
                                            <SearchIcon onClick={this.handleSearch} />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            value={
                                this.state
                                    .searchInput
                            }
                            onChange={(e) => this.setState({ searchInput: e.target.value })}
                        />
                        <Table stickyHeader aria-label="sticky table" className="table table-bordered table-sm mb-0">
                            <TableHead className="table-header sticky-header-table">
                                <tr>
                                    <th nowrap="true" rowSpan={2} className="text-uppercase text-center sticky-head-cell-left">Action</th>
                                    <th nowrap="true" rowSpan={2} className="text-uppercase text-center sticky-header-table-barge">
                                        Barge ID
                                    </th>
                                    <th nowrap="true" rowSpan={2} className="text-uppercase text-center sticky-header-table-barge-name">
                                        Barge Name
                                    </th>
                                    <th nowrap="true" rowSpan={2} className="text-uppercase text-center sticky-header-table-business-number">
                                        Business Number
                                    </th>
                                    <th nowrap="true" rowSpan={2} className="text-uppercase text-center sticky-header-table-purchase-contract">
                                        Purchase Contract
                                    </th>
                                    <th nowrap="true" rowSpan={2} className="text-uppercase text-center ">
                                        Supplier
                                    </th>
                                    <th nowrap="true" rowSpan={2} className="text-uppercase text-center ">
                                        Method
                                    </th>
                                    <th nowrap="true" rowSpan={2} className="text-uppercase text-center ">
                                        Basis
                                    </th>
                                    <th nowrap="true" colSpan={3} className="text-uppercase text-center ">
                                        Gross Calorific Value
                                    </th>
                                    <th nowrap="true" colSpan={3} className="text-uppercase text-center ">
                                        Ash (%)
                                    </th>
                                    <th nowrap="true" colSpan={3} className="text-uppercase text-center ">
                                        Total Sulphur (%)
                                    </th>
                                    <th nowrap="true" colSpan={3} className="text-uppercase text-center ">
                                        Total Moisture (%)
                                    </th>
                                    <th
                                        nowrap="true"
                                        className="text-uppercase text-center"
                                        colSpan={3}
                                    >
                                        Inherent Moisture (%)
                                    </th>
                                    <th
                                        nowrap="true"
                                        className="text-uppercase text-center"
                                        colSpan={3}
                                    >
                                        Volatile Moisture (%)
                                    </th>
                                    <th
                                        nowrap="true"
                                        className="text-uppercase text-center"
                                        colSpan={3}
                                    >
                                        Fixed Carbon (%)
                                    </th>
                                    <th
                                        nowrap="true"
                                        className="text-uppercase text-center"
                                        colSpan={3}
                                    >
                                        Fuel Ratio(FC/VM)
                                    </th>
                                    <th
                                        nowrap="true"
                                        className="text-uppercase text-center"
                                        colSpan={3}
                                    >
                                        HGI
                                    </th>
                                </tr>
                                <tr>
                                    <th>Typical</th>
                                    <th>Rejection</th>
                                    <th>Actual</th>

                                    <th>Typical</th>
                                    <th>Rejection</th>
                                    <th>Actual</th>

                                    <th>Typical</th>
                                    <th>Rejection</th>
                                    <th>Actual</th>

                                    <th>Typical</th>
                                    <th>Rejection</th>
                                    <th>Actual</th>

                                    <th>Typical</th>
                                    <th>Rejection</th>
                                    <th>Actual</th>

                                    <th>Typical</th>
                                    <th>Rejection</th>
                                    <th>Actual</th>

                                    <th>Typical</th>
                                    <th>Rejection</th>
                                    <th>Actual</th>

                                    <th>Typical</th>
                                    <th>Rejection</th>
                                    <th>Actual</th>

                                    <th>Typical</th>
                                    <th>Rejection</th>
                                    <th>Actual</th>
                                </tr>
                            </TableHead>
                            {!this.state.isLoading && <TableBody className='fixed-left-column'>
                                {filteredPurchaseSamplingData.map((purchaseSample, index) => {
                                    const isEditable = this.isEditable(purchaseSample.bargeSamplingData);
                                    return purchaseSample.bargeSamplingData.map((bargeData, bargeIndex) => {
                                        return <tr key={bargeData.bargeId}>
                                            {bargeIndex === 0 && <td
                                                className="text-center sticky-cell-left"
                                                nowrap="true"
                                                rowSpan={purchaseSample.bargeSamplingData.length}
                                            >
                                                <Tooltip title="Edit" arrow><i
                                                    className="fa fa-eye"
                                                    aria-hidden="true"
                                                    style={{
                                                        fontSize: 22,
                                                        color: config.themeColor,
                                                        cursor: "pointer",
                                                    }}
                                                // onClick={() => {
                                                //     window.location.href =
                                                //         `/purchase-quality/view/${btoa(this.props.samplingType)}/${btoa(purchaseSample.barge_ids)}/${btoa(purchaseSample.groupId)}`;
                                                // }}
                                                ></i></Tooltip>
                                                {(this.state.features.includes("137") ||
                                                    this.state.features.includes("97")) && (
                                                        <><span style={{ color: "lightgrey", margin: "0px 5px" }}>
                                                            |
                                                        </span><Tooltip title="Delete" arrow><i
                                                            className={`fa fa-trash-o ${!isEditable ? 'disable-icons' : ''}`}
                                                            aria-hidden="true"
                                                            style={{
                                                                fontSize: 21,
                                                                color: isEditable ? config.themeColor : 'lightgrey',
                                                                cursor: "pointer",
                                                            }}
                                                            // onClick={() => {
                                                            //     this.deletePurchaseSamples(purchaseSample.groupId)
                                                            // }}
                                                            disabled={!isEditable}
                                                        ></i></Tooltip></>
                                                    )}
                                                {(this.state.features.includes("137") ||
                                                    this.state.features.includes("97")) && (
                                                        <><span style={{ color: "lightgrey", margin: "0px 5px" }}>
                                                            |
                                                        </span><span className={`${!isEditable ? 'disable-icons' : ''}`}><Tooltip title="Edit" arrow><i
                                                            className={`fa fa-pencil`}
                                                            aria-hidden="true"
                                                            style={{
                                                                fontSize: 21,
                                                                color: isEditable ? config.themeColor : 'lightgrey',
                                                                cursor: "pointer",
                                                            }}
                                                            // onClick={() => {
                                                            //     window.location.href =
                                                            //         `/purchase-quality/update/${btoa(this.props.samplingType)}/${btoa(purchaseSample.barge_ids)}/${btoa(purchaseSample.groupId)}`;
                                                            // }}
                                                            disabled={!isEditable}
                                                        ></i></Tooltip></span></>
                                                    )}
                                            </td>}
                                            <td className='fixed-left-column-barge'><a
                                                style={{
                                                    textDecoration: "none",
                                                    color: config.themeColor,
                                                }}
                                                href={"/view-barge/" + btoa(bargeData.bargeId)}
                                            >
                                                {bargeData.bargeId}
                                            </a> {bargeData.isEditable ?
                                                <><Dialog
                                                    open={confirmDialogueBargeId === bargeData.bargeId}
                                                    onClose={this.handleClose}
                                                    aria-labelledby="draggable-dialog-title"
                                                >
                                                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                                                        Are you Sure to dissolve this from present Group ?
                                                    </DialogTitle>
                                                    <DialogActions>
                                                        <Button autoFocus onClick={this.handleClose}>
                                                            No
                                                        </Button>
                                                        <Button onClick={() => this.dissolvePurchaseSamples(bargeData.bargeId)}>Yes</Button>
                                                    </DialogActions>
                                                </Dialog>
                                                    <span class="bg-success text-white rounded-circle span-commercial" onClick={() => {
                                                        this.setState({ confirmDialogueBargeId: bargeData.bargeId });
                                                    }}>N</span></> : <span class="bg-primary text-white rounded-circle span-commercial">C</span>}</td>
                                            <td className='fixed-left-column-barge-name'>{bargeData.bargeName}</td>
                                            <td className='fixed-left-column-business-number'>{bargeData.businessNo}</td>

                                            <td className='fixed-left-column-purchase-contract'>
                                                <a
                                                    style={{
                                                        textDecoration: "none",
                                                        color: config.themeColor,
                                                    }}
                                                    href={"/view-purchase-contract/" + btoa(bargeData.purchaseContractID)}
                                                >
                                                    {bargeData.purchaseContractNo}
                                                </a>
                                            </td>
                                            <td class="text-nowrap">{bargeData.supplierName}</td>
                                            <td>{bargeData.method}</td>
                                            <td>{bargeData.grossCalorificValue.value1.basic}</td>


                                            <td>{bargeData.grossCalorificValue.value1.typical}</td>
                                            <td>{bargeData.grossCalorificValue.value1.rejection}</td>
                                            <td>{bargeData.grossCalorificValue.value1.actual}</td>

                                            <td>{bargeData.ash.typical}</td>
                                            <td>{bargeData.ash.rejection}</td>
                                            <td>{bargeData.ash.actual}</td>

                                            <td>{bargeData.totalSulphur.typical}</td>
                                            <td>{bargeData.totalSulphur.rejection}</td>
                                            <td>{bargeData.totalSulphur.actual}</td>

                                            <td>{bargeData.totalMoisture.typical}</td>
                                            <td>{bargeData.totalMoisture.rejection}</td>
                                            <td>{bargeData.totalMoisture.actual}</td>

                                            <td>{bargeData.inherentMoisture.typical}</td>
                                            <td>{bargeData.inherentMoisture.rejection}</td>
                                            <td>{bargeData.inherentMoisture.actual}</td>

                                            <td>{bargeData.volatileMatter.typical}</td>
                                            <td>{bargeData.volatileMatter.rejection}</td>
                                            <td>{bargeData.volatileMatter.actual}</td>

                                            <td>{bargeData.fixedCarbon.typical}</td>
                                            <td>{bargeData.fixedCarbon.rejection}</td>
                                            <td>{bargeData.fixedCarbon.actual}</td>

                                            <td>{bargeData.fuelRatio.typical}</td>
                                            <td>{bargeData.fuelRatio.rejection}</td>
                                            <td>{bargeData.fuelRatio.actual}</td>

                                            <td>{bargeData.hgi.typical}</td>
                                            <td>{bargeData.hgi.rejection}</td>
                                            <td>{bargeData.hgi.actual}</td>
                                        </tr>
                                    })
                                })
                                }
                            </TableBody>}
                        </Table>
                    </TableContainer>
                </div>
            </div>
        )
    }
}
