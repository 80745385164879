import React, { Component } from "react";

import { Snackbar, Drawer } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import api from "../../../api/api";

// import mother vessel Coasl Procurement Charges
import MVPreShipmentChargesAdd from "./Mv-Pre-Shipment-Inspection-Add";
import MVPreShipmentChargesView from "./Mv-Pre-Shipment-Inspection-View";
import MVPreShipmentChargesUpdate from "./Mv-Pre-Shipment-Inspection-Update";
import Loader from "../../common/Loader";
import { toLocaleString } from "../../common/common";

export default class Mv_Pre_Shipment_List extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      MVPreShipmentCharges: [],
      features: [],
      isLoading: true,
      updateDocuments: false,
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    } else {
      var features = this.Cookie.getCookie("features");
      this.setState({ features: features });
      if (!(features.includes("251") || features.includes("97"))) {
        window.location.href = "/dashboard";
      }
      var loginUserID = this.Cookie.getCookie("loginUserId");
      var idToken = this.Cookie.getIdTokenCookie();
      try {
        //var businessNoID = decodeURIComponent(window.atob(this.props.match.params.businessNoID));
        var businessNoID = this.props.businessNoID;
        //Get businessNumber API based on businessNumber
        const getBusinessNumber = await api.getBusinessNumber(
          businessNoID,
          loginUserID,
          idToken
        );
        if (getBusinessNumber.status >= 200 && getBusinessNumber.status < 300) {
          getBusinessNumber.json().then((res) => {
            if (res.code === "200") {
              if (res.business_number) {
                this.setState({
                  businessNo: res.business_number.business_no
                    ? res.business_number.business_no
                    : "",
                  mother_vessel_name: res.business_number.mother_vessel_name
                    ? res.business_number.mother_vessel_name
                    : "",
                  //isLoading: false
                });
              }
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                snackBarErrorOpen: true,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }

        const get_mv_pre_shipment = await api.get_mv_pre_shipment(
          loginUserID,
          idToken,
          businessNoID
        );
        if (
          get_mv_pre_shipment.status >= 200 &&
          get_mv_pre_shipment.status < 300
        ) {
          get_mv_pre_shipment.json().then((res) => {
            if (res.code === "200") {
              if (res.mv_pre_shipment) {
                var paid_to_vendor = 0;
                var remaining_amount = 0;
                for (var i = 0; i < res.mv_pre_shipment.length; i++) {
                  if (
                    res.mv_pre_shipment[i].mv_pre_shipment_payments.length > 0
                  ) {
                    for (var j of res.mv_pre_shipment[i]
                      .mv_pre_shipment_payments) {
                      paid_to_vendor += Number(j.amount);
                      remaining_amount =
                        Number(res.mv_pre_shipment[i].sac_sampling_total) -
                        Number(paid_to_vendor);
                    }
                    res.mv_pre_shipment[i].paid_to_vendor = paid_to_vendor;
                    res.mv_pre_shipment[i].remaining_amount = remaining_amount;
                    paid_to_vendor = 0;
                    remaining_amount = 0;
                  } else {
                    res.mv_pre_shipment[i].remaining_amount = Number(
                      res.mv_pre_shipment[i].sac_sampling_total
                    );
                  }
                }
                this.setState({
                  MVPreShipmentCharges: res.mv_pre_shipment,
                  isLoading: false,
                });
              }
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                snackBarErrorOpen: true,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      } catch (e) {
        // window.location.href = '/business-number-list';
      }
    }
    //document.title = config.documentTitle + "Mv Pre Shipment Inspection";
  }

  onAddResponse = (value) => {
    if (value.code === "200") {
      this.setState(
        {
          MVPreShipmentChargesAdd: false,
          MVPreShipmentChargesView: false,
          MVPreShipmentChargesUpdate: false,
          openDrawer: false,
          updateDocuments: false,
        },
        () => this.componentDidMount()
      );
    } else {
      this.setState({
        MVPreShipmentChargesAdd: false,
        MVPreShipmentChargesView:
          value.screen === "MvPreShipment" &&
          value.message === "alreadyDataAvailable"
            ? true
            : false,
        MVPreShipmentChargesUpdate: false,
        openDrawer: true,
        updateDocuments: false,
      });
    }
  };

  CallbackDrawerClose = (childData) => {
    this.setState(
      {
        openDrawer: childData,
        MVPreShipmentChargesAdd: false,
        MVPreShipmentChargesView: false,
        MVPreShipmentChargesUpdate: false,
        updateDocuments: false,
      },
      () => this.componentDidMount()
    );
  };

  onUpdateResponse = (value) => {
    if (value) {
      this.setState(
        {
          MVPreShipmentChargesAdd: false,
          MVPreShipmentChargesView:
            value.code === "200" && value.screen === "MvPreShipment"
              ? true
              : false,
          MVPreShipmentChargesUpdate: false,
          updateDocuments: false,
        },
        () => this.componentDidMount()
      );
    }
  };

  CallbackViewPage = (childData) => {
    this.setState(
      {
        openDrawer: true,
        MVPreShipmentChargesUpdate: false,
        MVPreShipmentChargesAdd: false,
        MVPreShipmentChargesView: childData === "MvPreShipment" ? true : false,
        updateDocuments: false,
      },
      () => this.componentDidMount()
    );
  };

  editCallback = (childData) => {
    this.setState(
      {
        openDrawer: true,
        MVPreShipmentChargesUpdate:
          childData === "MvPreShipment" ? true : false,
        MVPreShipmentChargesAdd: false,
        MVPreShipmentChargesView: false,
        updateDocuments: false,
      },
      () => this.componentDidMount()
    );
  };

  deleteCharges = (data) => {
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    var finalData = data;
    finalData.mv_pre_shipment_id = data.id;
    finalData.login_user_id = loginUserID;
    finalData.idtoken = idToken;
    finalData.status = "Deleted";
    //Delete Stevedore costing charge
    api.update_mv_pur_fin_pre_shipment(finalData).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.setState(
              {
                snackBarSuccessOpen: true,
                successMsg: res.message,
              },
              () => this.componentDidMount()
            );
          } else if (res.code === "601") {
            this.setState({ errorMsg: res.message, snackBarErrorOpen: true });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          } else if (res.code === "624") {
            this.setState({
              errorMsg: res.columns.length > 0 ? res.columns[0] : "",
              snackBarErrorOpen: true,
            });
          }
        });
      }
    });
  };

  render() {
    let drawerComponent;
    if (this.state.MVPreShipmentChargesAdd) {
      drawerComponent = (
        <MVPreShipmentChargesAdd
          businessNoID={this.props.businessNoID}
          onAddResponse={this.onAddResponse}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
        />
      );
    } else if (this.state.MVPreShipmentChargesView && this.state.businessNo) {
      drawerComponent = (
        <MVPreShipmentChargesView
          preShipmentID={this.state.preShipmentID}
          businessNoID={this.state.businessNo}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.editCallback}
          returnDocumentsHandler={() => {
            this.setState({
              showDrawer: true,
              MVPreShipmentChargesUpdate: true,
              MVPreShipmentChargesView: false,
              MVPreShipmentChargesAdd: false,
              updateDocuments: true,
            });
          }}
        />
      );
    } else if (this.state.MVPreShipmentChargesUpdate && this.state.businessNo) {
      drawerComponent = (
        <MVPreShipmentChargesUpdate
          preShipmentID={this.state.preShipmentID}
          businessNoID={this.state.businessNo}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.CallbackViewPage}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div>
          <div className="row clearfix content-header border-bottom">
            <div className="row clearfix content-header border-bottom">
              <h4 className="mt-2" style={{ padding: "4px" }}>
                Pre-Shipment Inspection - {this.state.mother_vessel_name} {"(" + this.state.businessNo + ")"}
              </h4>
              {(this.state.features.includes("250") ||
                this.state.features.includes("97")) && (
                <i
                  className="fa fa-plus-circle ml-2 mt-3"
                  aria-hidden="true"
                  style={{
                    fontSize: 22,
                    color: config.themeColor,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    this.setState({
                      openDrawer: true,
                      MVPreShipmentChargesAdd: true,
                      MVPreShipmentChargesView: false,
                      MVPreShipmentChargesUpdate: false,
                    });
                  }}
                />
              )}
            </div>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <Drawer
            anchor="right"
            open={this.state.openDrawer}
            variant="temporary"
            elevation={20}
            style={{ overflow: "initial" }}
          >
            <div className="row" style={{ width: 800 }}>
              {drawerComponent}
            </div>
          </Drawer>
          <div className="card pl-0 pr-0">
            <div
              className="card-body p-0"
              style={{ backgroundColor: "rgba(25,118,210,.01)" }}
              id="accordion"
            >
              <div className="col-lg-12 mb-2 p-0">
                <div className="row">
                  <div className="table-responsive">
                    <table className="table">
                      <thead style={{ backgroundColor: "rgb(249, 249, 249)" }}>
                        <tr>
                          <th
                            nowrap="true"
                            className="allocation_table_header_label border-0"
                          >
                            Costing ID
                          </th>
                          <th
                            nowrap="true"
                            className="allocation_table_header_label border-0"
                          >
                            Sampling Surveyor
                          </th>
                          <th
                            nowrap="true"
                            className="allocation_table_header_label border-0 text-right"
                          >
                            Quantity in MT
                          </th>
                          <th
                            nowrap="true"
                            className="allocation_table_header_label border-0 text-right"
                          >
                            Base Price PMT
                          </th>
                          <th
                            nowrap="true"
                            className="allocation_table_header_label border-0 text-right"
                          >
                            Payable to Vendor
                          </th>
                          <th
                            nowrap="true"
                            className="allocation_table_header_label border-0 text-left pl-4"
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      {this.state.MVPreShipmentCharges.length > 0 ? (
                        <tbody>
                          {this.state.MVPreShipmentCharges.map((idr, indx) => (
                            <tr key={indx}>
                              <td className="allocation_table_value">
                                {(this.state.features.includes("251") ||
                                  this.state.features.includes("97")) && (
                                  <i
                                    aria-hidden="true"
                                    style={{
                                      fontStyle: "normal",
                                      color: config.themeColor,
                                      cursor: "pointer",
                                      whiteSpace: "nowrap",
                                    }}
                                    onClick={() =>
                                      this.setState({
                                        openDrawer: true,
                                        MVPreShipmentChargesAdd: false,
                                        MVPreShipmentChargesView: true,
                                        MVPreShipmentChargesUpdate: false,
                                        businessNo: idr.business_no_id,
                                        preShipmentID: idr.id,
                                      })
                                    }
                                  >
                                    {idr.costing_no}
                                  </i>
                                )}
                              </td>
                              <td
                                className="allocation_table_value"
                                nowrap="true"
                              >
                                {idr.sac_sampling_surveyor_name}
                              </td>
                              <td
                                className="allocation_table_value text-right"
                                nowrap="true"
                              >
                                {idr.sac_quantity_in_mt
                                  ? toLocaleString(idr.sac_quantity_in_mt)
                                  : 0}
                              </td>
                              <td
                                className="allocation_table_value text-right"
                                nowrap="true"
                              >
                                {idr.sac_sampling_price_per_mt
                                  ? toLocaleString(
                                      idr.sac_sampling_price_per_mt
                                    )
                                  : 0}{" "}
                                {config.company === "SRPL" || config.company === "AVS"  ||
                                config.company === "PRPL"
                                  ? "USD"
                                  : "IDR"}
                              </td>
                              <td
                                className="allocation_table_value text-right"
                                nowrap="true"
                              >
                                {idr.sac_sampling_total
                                  ? toLocaleString(idr.sac_sampling_total)
                                  : 0}{" "}
                                {config.company === "SRPL" || config.company === "AVS"  ||
                                config.company === "PRPL"
                                  ? "USD"
                                  : "IDR"}
                              </td>
                              <td
                                nowrap="true"
                                className="table_td text-left pl-4"
                              >
                                {(this.state.features.includes("252") ||
                                  this.state.features.includes("97")) && (
                                  <i
                                    className="fa fa-pencil"
                                    style={{
                                      fontSize: 14,
                                      color: config.themeColor,
                                      cursor: "pointer",
                                    }}
                                    data-toggle="modal"
                                    title="Edit floating crane charges"
                                    data-placement="bottom"
                                    onClick={() =>
                                      this.setState({
                                        businessNo: idr.business_no_id,
                                        preShipmentID: idr.id,
                                        openDrawer: true,
                                        MVPreShipmentChargesUpdate: true,
                                      })
                                    }
                                  ></i>
                                )}
                                {idr.payment_postings_availability === 0 &&
                                  (this.state.features.includes("253") ||
                                    this.state.features.includes("97")) &&
                                  idr.approval_status === "Pending" && (
                                    <i
                                      className="fa fa-trash-o"
                                      style={{
                                        paddingLeft: 10,
                                        fontSize: 14,
                                        color: config.themeColor,
                                        cursor: "pointer",
                                      }}
                                      onClick={() => this.deleteCharges(idr)}
                                    ></i>
                                  )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td colSpan="5" className="p-2">
                              No data available
                            </td>
                          </tr>{" "}
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
