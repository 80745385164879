import React, { Component } from "react";
import api from "../../../api/api";
import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import { Alert } from "@material-ui/lab";
import {
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
  Checkbox,
} from "@material-ui/core";
// import MaterialTable from 'material-table';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { withStyles } from "@material-ui/core/styles";
import FileUpload from "../../common/FileUpload";
import { localDateFormate, pad } from "../../common/common";
import Loader from "../../common/Loader";
const NewRadio = withStyles({
  root: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const CheckBox = withStyles({
  root: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default class ExportTaxAdd extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      export_tax_applicable: "Yes",
      quantity_for_export_tax: "",
      export_tax_per_mt: "",
      export_tax: "",
      billing_currency: "USD",
      export_tax_in_local_currency: "",
      export_currency_xchg_rate: "",
      isLoading: true,
      activeStep: 0,
      barges: [],
      barge_id: null,
      barge_nomination: "",
      jetty_name: "",
      business_no_drop_value: [],
      business_no_id: [],
      features: [],
      bargeList: [],
      files: [
        {
          file_description: "",
          file_name: "",
          file_extension: "",
          file_content: "",
          feature_name: "Barge_export_tax",
        },
      ],
      costing_files: [],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    var features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (!(features.includes("174") || features.includes("97"))) {
      window.location.href = "/dashboard";
    }
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    await api.getBusinessNumbers(loginUserID, idToken).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
            if (res.business_numbers) {
              let business_no_drop_value = [];
              for (var i of res.business_numbers) {
                business_no_drop_value.push({
                  name: i.business_no,
                  value: i.id,
                  mother_vessel_name: i.mother_vessel_name,
                });
              }
              this.setState({
                business_no_drop_value: business_no_drop_value,
                isLoading: false,
              });
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });

    document.title =
      config.documentTitle + "Barge Purchase Financial Export Tax";
  }

  onSelectBarge(barge_data) {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    let barge_id = barge_data.id;
    if (barge_data) {
      api
        .get_purchase_contract_data_by_barge_id(loginUserID, idToken, barge_id)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              var barge_quantity = "";
              if (res.purchase_contract_data) {
                barge_quantity = barge_data.barge_quantity_in_mt;
                if (barge_quantity === null || Number(barge_quantity) <= 0) {
                  barge_quantity = barge_data.barge_planned_quantity_in_mt;
                } else if (
                  barge_data.barge_planned_quantity_in_mt === null ||
                  Number(barge_data.barge_planned_quantity_in_mt) <= 0
                ) {
                  barge_quantity = barge_data.barge_quantity_in_mt;
                }

                var redraftQuantity = barge_data.barge_quantity_in_mt
                  ? barge_data.barge_quantity_in_mt
                  : "";
                if (
                  res.purchase_contract_data.purchase_type ===
                    "FAS Anchorage" &&
                  barge_data.redraft_quantity !== null
                ) {
                  redraftQuantity = barge_data.redraft_quantity;
                }
                this.setState({
                  quantity_for_export_tax:
                    res.purchase_contract_data.purchase_type === "FAS Anchorage"
                      ? redraftQuantity
                      : barge_quantity,
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            });
          }
        });
    }
  }

  nextStepHandler = () => {
    if (this.state.activeStep === 0) {
      if (!this.state.barge_id) {
        this.setState({
          snackBarErrorOpen: true,
          errorMsg: "Please select barge.",
        });
        return;
      }
    }

    this.setState({
      activeStep: this.state.activeStep + 1,
    });
  };

  previousStepHandler = () => {
    this.setState({
      activeStep: this.state.activeStep - 1,
    });
  };

  submitHandler = () => {
    let {
      export_tax_applicable,
      quantity_for_export_tax,
      export_tax_per_mt,
      export_tax,
      billing_currency,
      export_currency_xchg_rate,
      export_tax_in_local_currency,
    } = this.state;
    if (
      config.company === "SRPL" ||
      config.company === "AVS" ||
      config.company === "PRPL"
    ) {
      export_currency_xchg_rate = 1;
      export_tax_in_local_currency = export_tax;
    }
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    let barge_id = this.state.barge_id;
    /**
     *  Checking Mandatory files
     */
    var missing_fields = 0;
    let files_missing = 0;
    var row = this.state.files;
    for (var obj of row) {
      if (obj.file_type !== "" || obj.file_content !== "") {
        if (obj.file_content !== "") {
          if (obj.file_description === "") {
            missing_fields++;
            obj.file_description_error = true;
          } else {
            obj.file_description = obj.file_description.toUpperCase();
          }
        } else if (obj.file_description !== "") {
          if (obj.file_content === "") {
            files_missing++;
          }
        }
      }
    }
    this.setState({
      files: row,
    });

    if (missing_fields > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please fill mandatory fields.",
      });
      return;
    }
    if (files_missing > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please Select file.",
      });
      return;
    }
    const content_files = row.filter((e) => e.file_content !== "");
    const costing = this.state.costing_files?.filter(
      (e) => e.file_content !== "" && e.column === "invoice_file"
    );
    api
      .add_barge_export_tax(
        loginUserID,
        idToken,
        barge_id,
        export_tax_applicable,
        quantity_for_export_tax,
        export_tax_per_mt,
        export_tax,
        billing_currency,
        export_currency_xchg_rate,
        export_tax_in_local_currency,
        content_files,
        costing
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.props.onAddResponse(res.code);
              this.setState({
                snackBarSuccessOpen: true,
                successMsg: "Costing created successfully",
                errorMsg: "",
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            } else if (res.code === "624") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: "Record already exist for given barge",
              });
            }
          });
        }
      });
  };

  toLocaleString(value) {
    return value ? Number(value).toLocaleString() : 0;
  }

  async searchBargeHandler() {
    this.setState({ isLoading: true });
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    // let barge_name = this.state.barge_nomination;
    // let jetty_name = this.state.jetty_name;
    //let barge_id = this.state.barge_id;
    // if (name === '') {
    //   this.setState({
    //     barge_name_error: true,
    //     snackBarErrorOpen: true,
    //     errorMsg: "Please Fill Mandatory Fields",
    //     isLoading: false
    //   });
    //   return;
    // }
    let financial_type = "barge_export_tax";
    let business_no = this.state.business_no_id;
    const get_barges = await api.get_barges(
      loginUserID,
      idToken,
      financial_type,
      business_no
    );
    if (get_barges.status >= 200 && get_barges.status < 300) {
      get_barges.json().then((res) => {
        if (res.code === "200") {
          if (res.barges) {
            const filter_not_costing_barges = res.barges.filter(
              (e) => e.export_tax_costing === null && e.status === "Active"
            );
            if (filter_not_costing_barges.length === 0) {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: "No records to display",
                barges: filter_not_costing_barges,
                isLoading: false,
              });
            } else {
              this.setState({
                barges: filter_not_costing_barges,
                isLoading: false,
              });
            }
          } else {
            alert("Unexpected error occured. Please contact administrator.");
          }
        } else if (res.code === "601") {
          this.setState({
            snackBarErrorOpen: true,
            isLoading: false,
            errorMsg: res.message,
          });
        } else if (res.code === "607") {
          window.location.href = "/logout";
        }
      });
    }
  }

  render() {
    const quan = /^\d*(\.\d{0,3})?$/;
    const pr = /^\d*(\.\d{0,4})?$/;
    const re = /^\d*(\.\d{0,10})?$/;
    // const vat = /^\d*(\.\d{0,2})?$/;
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div className="container bg-white p-0" style={{ minHeight: "100vh" }}>
          <div className="clearfix content-header border-bottom">
            <div className="row">
              <div className="col-sm text-left pl-0">
                <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                  Barge Purchase Financial For Export Tax
                </h4>
              </div>
            </div>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.sucessMsg}
            </Alert>
          </Snackbar>
          <div className="drawer-section">
            <div className="row mb-0 stepper">
              <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                <i
                  className={
                    this.state.activeStep > 0
                      ? "fa fa-check-circle"
                      : "fa fa-check-circle-o"
                  }
                  aria-hidden="true"
                  style={{
                    fontSize: 20,
                    color:
                      this.state.activeStep > 0
                        ? "#31c197"
                        : this.state.activeStep === 0
                        ? config.themeColor
                        : "#ccc",
                  }}
                ></i>
                <span
                  className="stepper_text"
                  style={{
                    color:
                      this.state.activeStep > 0
                        ? "#31c197"
                        : this.state.activeStep === 0
                        ? config.themeColor
                        : "#ccc",
                  }}
                >
                  Barge Selection
                </span>
                <i
                  className="fa fa-chevron-right stepper_sub_icon stepper_arrow"
                  aria-hidden="true"
                  style={{
                    color:
                      this.state.activeStep > 0
                        ? "#31c197"
                        : this.state.activeStep === 0
                        ? config.themeColor
                        : "#ccc",
                  }}
                ></i>
              </span>
              <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                <i
                  className={
                    this.state.activeStep > 1
                      ? "fa fa-check-circle"
                      : "fa fa-check-circle-o"
                  }
                  aria-hidden="true"
                  style={{
                    fontSize: 20,
                    color:
                      this.state.activeStep > 1
                        ? "#31c197"
                        : this.state.activeStep === 1
                        ? config.themeColor
                        : "#ccc",
                  }}
                ></i>
                <span
                  className="stepper_text"
                  style={{
                    color:
                      this.state.activeStep > 1
                        ? "#31c197"
                        : this.state.activeStep === 1
                        ? config.themeColor
                        : "#ccc",
                  }}
                >
                  Costing
                </span>
                <i
                  className="fa fa-chevron-right stepper_sub_icon stepper_arrow"
                  aria-hidden="true"
                  style={{
                    color:
                      this.state.activeStep > 1
                        ? "#31c197"
                        : this.state.activeStep === 1
                        ? config.themeColor
                        : "#ccc",
                  }}
                ></i>
              </span>
            </div>
            <div className="card">
              <div className="card-body border_dashed">
                {this.state.activeStep === 0 && (
                  <div className="row">
                    <div className="col-lg-6 p-0">
                      <Autocomplete
                        multiple
                        options={this.state.business_no_drop_value}
                        getOptionLabel={(option) => option.mother_vessel_name}
                        renderOption={(option, { selected }) => (
                          <React.Fragment>
                            <CheckBox
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            <div className="col-lg p-0 m-0">
                              <label className="form_label mb-0">
                                {option.mother_vessel_name}
                              </label>
                              <label
                                className="contract_display_header_value m-0 pl-0"
                                style={{ fontSize: 10, paddingLeft: 5 }}
                              >
                                {"( " + option.name + " )"}
                              </label>
                            </div>
                          </React.Fragment>
                        )}
                        onChange={(event, value) => {
                          if (value) {
                            var data = [];
                            for (var i of value) {
                              data.push(i.value);
                            }
                            //var floating_crane_vendor = data.toString();
                            this.setState({
                              business_no_id: data,
                              selected_business_no_value: value,
                            });
                          } else {
                            this.setState({
                              business_no_id: [],
                              selected_business_no_value: {
                                name: "",
                                value: "",
                              },
                            });
                          }
                        }}
                        disableCloseOnSelect
                        name="Business_Number"
                        size="small"
                        value={this.state.selected_business_no_value}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Business No"
                            variant="outlined"
                            style={{ top: 8 }}
                            fullWidth
                          />
                        )}
                        style={{ display: "contents" }}
                      />
                    </div>
                    <div className="col-lg-2 pr-0 pt-2">
                      <button
                        className="btn btn-sm btn-info"
                        style={{
                          width: "auto",
                          marginTop: "5px",
                          border: "none",
                          backgroundColor: config.themeColor,
                        }}
                        onClick={() => this.searchBargeHandler()}
                      >
                        <i className="fa fa-search mr-1" aria-hidden="true" />
                        Search
                      </button>
                    </div>

                    {this.state.barges.length > 0 && (
                      <div className="table-responsive mt-3">
                        <div className="table border-0">
                          <table className="table table-borderless border-0">
                            <thead className="border-0">
                              <tr>
                                <th
                                  className="p-2 text-center border-0"
                                  nowrap="true"
                                >
                                  {/* <NewRadio
                                //value={this.state.totalBarges}
                                color='primary'
                                //checked={this.state.totalBarges.length !== 0 && (this.state.bargeList.length === this.state.totalBarges.filter(e => e.barge_pur_quality_results_id === null).length)}
                                // checked={this.state.barges.length !== 0 && (this.state.bargeList.length === this.state.barges.filter(e => e.barge_pur_quality_results_id !== null).length)}
                                //disabled={this.state.selectedPurchaseContractId === "" || p.purchase_contract_id !== this.state.selectedPurchaseContractId}
                                // onChange={this.onBargesCheck}
                                onChange={(e) => { this.onSelectAllBarges(e.target.checked) }}
                                className="p-0"
                              /> */}
                                </th>
                                <th
                                  style={{ fontSize: 16 }}
                                  className="table_header_barge_label text-uppercase border-0"
                                  nowrap="true"
                                >
                                  Barge ID
                                </th>
                                <th
                                  style={{ fontSize: 16 }}
                                  className="table_header_barge_label text-uppercase border-0"
                                  nowrap="true"
                                >
                                  Barge Nomination
                                </th>
                                <th
                                  style={{ fontSize: 16 }}
                                  className="table_header_barge_label text-uppercase border-0"
                                  nowrap="true"
                                >
                                  Jetty
                                </th>
                                <th
                                  style={{ fontSize: 16 }}
                                  className="table_header_barge_label text-uppercase border-0"
                                  nowrap="true"
                                >
                                  Loading Date
                                </th>
                                <th
                                  style={{ fontSize: 16 }}
                                  className="table_header_barge_label text-uppercase border-0"
                                  nowrap="true"
                                >
                                  Business No
                                </th>
                                <th
                                  style={{ fontSize: 16 }}
                                  className="table_header_barge_label text-uppercase border-0 text-right"
                                  nowrap="true"
                                >
                                  Quantity
                                </th>
                              </tr>
                            </thead>
                            {this.state.barges.map((p, index) => (
                              <tbody
                                key={index}
                                style={{
                                  border: this.state.bargeList.includes(p.id)
                                    ? "1px solid rgb(236, 120, 51)"
                                    : "none",
                                }}
                              >
                                <tr className="rounded">
                                  <td className="p-0 text-center">
                                    <NewRadio
                                      onClick={(e) => {
                                        this.setState({
                                          barge_id: p.id,
                                        });
                                      }}
                                      checked={p.id === this.state.barge_id}
                                      // value={e.id}
                                      // color='primary'
                                      // checked={this.state.bargeList.includes(e.id) }
                                      // onChange={(e) => { this.onBargesCheck(e.target.value, e.display_allocation_id, e.target.checked) }}
                                    />
                                  </td>
                                  <td>{pad(p.id)}</td>
                                  <td nowrap="true">{p.barge_nomination}</td>
                                  <td nowrap="true">{p.loading_jetty}</td>
                                  <td nowrap="true">
                                    {localDateFormate(p.loading_date)}
                                  </td>
                                  <td nowrap="true">{p.business_no}</td>
                                  <td nowrap="true" className="text-right">
                                    {this.toLocaleString(
                                      p.barge_quantity_in_mt
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            ))}
                          </table>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {this.state.activeStep === 1 && (
                  <div className="col-lg-12 p-0">
                    <div style={{ width: "70%" }}>
                      <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                        Add details to create costing
                      </h5>
                    </div>

                    <div className="row mb-0">
                      <div className="col-lg-6 p-0">
                        <label className="form_label mb-0">
                          Export Tax Applicable
                        </label>
                        <div className="mt-1">
                          <RadioGroup
                            aria-label="export_tax_applicable"
                            name="export_tax_applicable"
                            onChange={(e) =>
                              this.setState({
                                export_tax_applicable: e.target.value,
                              })
                            }
                            style={{ flexDirection: "row" }}
                          >
                            <FormControlLabel
                              value="Yes"
                              control={
                                <NewRadio
                                  checked={
                                    this.state.export_tax_applicable === "Yes"
                                  }
                                />
                              }
                              label="Yes"
                            />
                            <FormControlLabel
                              value="No"
                              control={
                                <NewRadio
                                  checked={
                                    this.state.export_tax_applicable === "No"
                                  }
                                />
                              }
                              label="No"
                            />
                          </RadioGroup>
                        </div>
                      </div>
                    </div>

                    {this.state.export_tax_applicable === "Yes" && (
                      <div className="row mb-0">
                        <div className="col-lg-6 p-0">
                          <div className="row mb-2">
                            <label className="form_label mb-0">
                              Quantity for Export Tax
                            </label>
                            <TextField
                              name="quantity_for_export_tax"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              InputProps={{
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                },
                              }}
                              value={this.state.quantity_for_export_tax}
                              placeholder="Quantity for Export Tax"
                              onChange={(e) => {
                                if (
                                  e.target.value === "" ||
                                  quan.test(e.target.value)
                                ) {
                                  let value = e.target.value;
                                  if (value === "") {
                                    value = 1;
                                  }
                                  var export_tax =
                                    (Number(value) *
                                      Number(this.state.export_tax_per_mt) *
                                      1.5) /
                                    100;
                                  this.setState({
                                    quantity_for_export_tax: e.target.value,
                                    export_tax: export_tax,
                                    export_tax_in_local_currency:
                                      config.company === "SDAM" ||
                                      config.company === "SRK" ||
                                      config.company === "BTR"
                                        ? Number(export_tax) *
                                          Number(
                                            this.state.export_currency_xchg_rate
                                              ? this.state
                                                  .export_currency_xchg_rate
                                              : 1
                                          )
                                        : "",

                                    //export_tax: Number((Number(this.state.export_tax_per_mt) * Number(value) * 1.5) / 100).toFixed(2)
                                  });
                                }
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-lg-6 pr-0">
                          <div className="row mb-2">
                            <label className="form_label mb-0">
                              Export Tax per MT
                            </label>
                            <TextField
                              name="export_tax_per_mt"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              value={this.state.export_tax_per_mt}
                              placeholder="Export Tax per MT"
                              onChange={(e) => {
                                if (
                                  e.target.value === "" ||
                                  pr.test(e.target.value)
                                ) {
                                  var value = e.target.value;
                                  if (value === "") {
                                    value = "1";
                                  }
                                  var export_tax =
                                    (Number(
                                      this.state.quantity_for_export_tax
                                    ) *
                                      Number(value) *
                                      1.5) /
                                    100;
                                  this.setState({
                                    export_tax_per_mt: e.target.value,
                                    export_tax: export_tax,
                                    export_tax_in_local_currency:
                                      config.company === "SDAM" ||
                                      config.company === "SRK" ||
                                      config.company === "BTR"
                                        ? Number(export_tax) *
                                          Number(
                                            this.state.export_currency_xchg_rate
                                              ? this.state
                                                  .export_currency_xchg_rate
                                              : 1
                                          )
                                        : "",

                                    //export_tax: Number(( Number(this.state.dc_quantity_in_mt) * Number(this.state.quantity_for_export_tax) * Number(value) * 1.5) / 100).toFixed(2)
                                  });
                                }
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    position="end"
                                    className="adornment_background"
                                  >
                                    USD
                                  </InputAdornment>
                                ),
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                  padding: 0,
                                },
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-lg-6 p-0">
                          <div className="row mb-2">
                            <label className="form_label mb-0">
                              Export Tax
                            </label>
                            <TextField
                              name="export_tax"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              value={this.state.export_tax}
                              placeholder="Export Tax"
                              onChange={(e) => {
                                if (
                                  e.target.value === "" ||
                                  pr.test(e.target.value)
                                ) {
                                  this.setState({
                                    export_tax: e.target.value,
                                    export_tax_in_local_currency:
                                      config.company === "SDAM" ||
                                      config.company === "SRK" ||
                                      config.company === "BTR"
                                        ? (
                                            Number(e.target.value) *
                                            Number(
                                              this.state
                                                .export_currency_xchg_rate
                                                ? this.state
                                                    .export_currency_xchg_rate
                                                : 1
                                            )
                                          ).toFixed(3)
                                        : "",
                                  });
                                }
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    position="end"
                                    className="adornment_background"
                                  >
                                    USD
                                  </InputAdornment>
                                ),
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                  padding: 0,
                                },
                              }}
                            />
                          </div>
                        </div>
                        {!(
                          config.company === "SRPL" ||
                          config.company === "AVS" ||
                          config.company === "PRPL"
                        ) && (
                          <div className="col-lg-6 pr-0">
                            <div className="row mb-2">
                              <label className="form_label mb-0">
                                Currency Exchange Rate
                              </label>
                              <TextField
                                name="export_currency_xchg_rate"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                  },
                                }}
                                value={this.state.export_currency_xchg_rate}
                                placeholder="Currency Exchange Rate USD to IDR"
                                onChange={(e) => {
                                  if (
                                    e.target.value === "" ||
                                    re.test(e.target.value)
                                  ) {
                                    var value = e.target.value;
                                    if (value === "") {
                                      value = 1;
                                    }
                                    this.setState({
                                      export_currency_xchg_rate: e.target.value,
                                      export_tax_in_local_currency: (
                                        Number(this.state.export_tax) *
                                        Number(value)
                                      ).toFixed(3),
                                    });
                                  }
                                }}
                              />
                            </div>
                          </div>
                        )}

                        {!(
                          config.company === "SRPL" ||
                          config.company === "AVS" ||
                          config.company === "PRPL"
                        ) && (
                          <div className="col-lg-6 p-0">
                            <div className="mb-2">
                              <div className="form_label mb-0">
                                Export Tax in IDR
                              </div>
                              <div className="mt-2 contract_display_header_value">
                                {this.state.export_tax_in_local_currency}{" "}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    {/* {(this.state.features.includes('369')  || this.state.features.includes('97') ) && */}
                    <FileUpload
                      files={this.state.files}
                      type="Costings"
                      feature_name="Barge_export_tax"
                      callbackFn={(data, mandatoryFiles) => {
                        this.setState({
                          files: data,
                          costing_files: mandatoryFiles,
                        });
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button"
              name="previous"
              onClick={
                this.state.activeStep === 0
                  ? () => this.props.onCancel(false)
                  : this.previousStepHandler
              }
            >
              {this.state.activeStep === 0 ? "CANCEL" : "PREVIOUS"}
            </button>
            <button
              type="button"
              className="btn next_button"
              name="next"
              onClick={
                this.state.activeStep === 1
                  ? this.submitHandler
                  : this.nextStepHandler
              }
            >
              {this.state.activeStep === 1 ? "SUBMIT" : "NEXT STEP"}
            </button>
          </div>
        </div>
      );
    }
  }
}
