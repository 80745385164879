
import React, { Component } from "react";
import SideBar from "../../common/NewSidebar";
import Header from "../../common/Header";
import CookieHandler from "../../common/CookieHandler";
import api from "../../../api/api";
import config from "../../../config/config";
import { Alert } from "@material-ui/lab";
import {
  TextField,
  Snackbar,
  Radio,
  RadioGroup,
  FormControlLabel,
  MenuItem,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import EventIcon from "@material-ui/icons/Event";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FileUpload from "../../common/FileUpload";
import Loader from "../../common/Loader";

const NewRadio = withStyles({
  root: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

class ProcurementServiceAddendum extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();

    this.state = {
      contract_date: null,
      purchaseContractId: null,
      purchase_contract_ids: [],
      cps_contract_mappping_data: [
        {
          pc_qualities_id: "0",
          pc_purchase_type_id: "0",
          price_type: "Lumpsum",
          quantity_in_mt: "",
          price_pmt: "",
          total_price: "",
          pc_types_menu: [],
        },
      ],
      purchaseContractList: [],
      purchaseDataForMenu: [],
      purchase_qualities: [],
      purchase_drop_values: { name: "", value: "" },
      pc_qualities: [],
      pc_quality_types: [],
      pc_types_menu: [],
      vendor_drop_value: { name: "", value: "" },
      isLoading: true,
      features: [],
      purchase_currency: "",
      files: [
        {
          file_description: "",
          file_name: "",
          file_extension: "",
          file_content: "",
          feature_name: "Cps_contract",
        },
      ],
      vendorDataForMenu: [],
      currencyData: [],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }

    const features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (!(features.includes("502") || features.includes("97"))) {
      window.location.href = "/dashboard";
    }
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    let status = "";
    const cps_contract_id = decodeURIComponent(
      window.atob(this.props.match.params.cps_contract_id)
    );

    // Get purchase contracts API
    await api
      .getPurchaseContracts(loginUserID, status, idToken)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
              if (res.purchase_contract) {
                let purchaseDataforMenu = [];
                const filteredPContracts = res.purchase_contract.filter(
                  (e, indx) => (e, indx) =>
                    e.status !== "Closed" && e.status !== "cancelled"
                );

                filteredPContracts.forEach((item) => {
                  purchaseDataforMenu.push({
                    name: item.contract_no,
                    value: item.id,
                    key: item.id,
                    vendor_name: item.vendor_name,
                  });
                });

                this.setState({
                  purchaseDataForMenu: purchaseDataforMenu,
                  purchaseContractList: filteredPContracts,
                  //isLoading: false
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
    // for get_Currencies
    await api.getCurrencies(loginUserID, idToken).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
          if (res.code === "200") {
            var currencies = [
              <MenuItem value="0" key={-1} disabled>
                Please Select
              </MenuItem>,
            ];
            for (var i in res.Currency) {
              currencies.push(
                <MenuItem value={res.Currency[i].currency} key={[i]}>
                  {res.Currency[i].currency}
                </MenuItem>
              );
            }
            this.setState({
              currencyData: currencies,
            });
          } else if (res.code === "601") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
    status = "Active";
    await api.getVendors(loginUserID, idToken, status).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
            if (res.vendors) {
              const vendors_menu = [];

              if (res.vendors) {
                res.vendors
                  .filter(
                    (e) =>
                      e.vendor_type === "Coal" || e.vendor_type === "Others"
                  )
                  .forEach((item) => {
                    vendors_menu.push({
                      name: item.vendor_name,
                      value: item.id,
                      key: item.id,
                    });
                  });
                // vendors_menu.push({ name: "Others", value: "Others" });

                this.setState({
                  vendorDataForMenu: vendors_menu,
                  vendors: res.vendors,
                  //isLoading: false
                });
              }
            }
          } else if (res.code === "601") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });

    await api
      .get_cps_contract(loginUserID, idToken, cps_contract_id)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.cps_contract_data) {
                var pc_data =
                  res.cps_contract_data.cps_contract_pc_mapping_data;
                if (pc_data.length === 1) {
                  this.handlePurchaseContract(
                    {
                      name: pc_data[0].contract_no,
                      value: pc_data[0].purchase_contract_id,
                    },
                    0
                  );
                  this.setState({
                    purchase_drop_values: {
                      name: pc_data[0].contract_no,
                      value: pc_data[0].purchase_contract_id,
                    },
                  });
                }
                let purchase_contract_ids = [];
                pc_data.forEach((item) => {
                  purchase_contract_ids.push(item.purchase_contract_id);
                });

                this.setState(
                  {
                    purchase_contract_ids: purchase_contract_ids,
                    contract_type: res.cps_contract_data.contract_type,
                    contract_date: res.cps_contract_data.contract_date,
                    payment_terms: res.cps_contract_data.payment_terms,
                    remarks: res.cps_contract_data.remarks,
                    purchase_contract: res.cps_contract_data.contract_no,
                    purchase_contract_id:
                      res.cps_contract_data.purchase_contract_id,
                    base_contract_id: res.cps_contract_data.id,
                    base_contract_no: res.cps_contract_data.cps_contract_no,
                    purchase_currency: res.cps_contract_data.cps_currency,
                    cps_contract_mappping_data:
                      res.cps_contract_data.cps_contract_mappping_data,
                    status: res.cps_contract_data.status,
                    files: res.cps_contract_data.files
                      ? res.cps_contract_data.files
                      : [],
                    vendor_drop_value:
                      res.cps_contract_data.vendor_id !== null
                        ? {
                          name: res.cps_contract_data.vendor_name,
                          value: res.cps_contract_data.vendor_id,
                        }
                        : { name: "Others", value: "Others" },
                    //pur_contract_drop_value: { name: res.cps_contract_data.contract_no, value: res.cps_contract_data.purchase_contract_id },
                    other_vendor_name: res.cps_contract_data.other_vendor_name,
                    vendor_id: res.cps_contract_data.vendor_id,
                    other_vendor_id: res.cps_contract_data.vendor_id,
                    currency: res.cps_contract_data.cps_currency,

                    isLoading: false,
                  },
                  () => {
                    if (
                      res.cps_contract_data.contract_no &&
                      res.cps_contract_data.purchase_contract_id
                    ) {
                      //this.handlePurchaseContract({ name: res.cps_contract_data.contract_no, value: res.cps_contract_data.purchase_contract_id }, 0)
                    }
                  }
                );
              }
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
                isLoading: false,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
    document.title = config.documentTitle + "Update Coal Procurement Contract";
  }

  handlePurchaseContract = (value, idx) => {
    if (value) {
      const loginUserID = this.Cookie.getCookie("loginUserId");
      const idToken = this.Cookie.getIdTokenCookie();
      const status = "";
      const contractNo = value.name;
      var filteredContract = this.state.purchaseContractList.filter(
        (e, index) => e.contract_no === contractNo
      );
      var purchaseContractId =
        filteredContract.length > 0 ? filteredContract[0].pcid : null;

      if (purchaseContractId) {
        api
          .getPurchaseContract(loginUserID, purchaseContractId, idToken, status)
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              response.json().then((res) => {
                if (res.code === "200") {
                  if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
                  if (res.purchase_contract) {
                    const pc_qualities = [
                      <MenuItem value="0" key={-1} disabled>
                        Please Select
                      </MenuItem>,
                    ];
                    if (res.purchase_contract.qualities.length > 0) {
                      for (var i of res.purchase_contract.qualities) {
                        pc_qualities.push(
                          <MenuItem value={i.id} key={i.id}>
                            {i.quality}
                          </MenuItem>
                        );
                      }
                    }
                    this.setState(
                      {
                        // purchase_drop_values: value,

                        purchase_contract_id: purchaseContractId,
                        vendor_drop_value:
                          idx !== 0
                            ? {
                              name: res.purchase_contract.vendor_name,
                              value: res.purchase_contract.vendor,
                            }
                            : this.state.vendor_drop_value,
                        vendor_id:
                          idx !== 0
                            ? res.purchase_contract.vendor
                            : this.state.vendor_id,
                        vendor_name: res.purchase_contract.vendor_name,
                        purchase_qualities: res.purchase_contract.qualities,
                        //purchase_currency: res.purchase_contract.currency,
                        currency:
                          idx !== 0
                            ? res.purchase_contract.currency
                            : this.state.currency,
                        pc_qualities: pc_qualities,
                        pc_qualities_id: "0",
                        purchase_type: null,
                        procurement_quality_standard: null,
                        pc_purchase_type_id: null,
                        isLoading: false,
                      },
                      () => {
                        let data = this.state.cps_contract_mappping_data;
                        for (let i = 0; i < data.length; i++) {
                          const filter_pc_types =
                            res.purchase_contract.qualities.filter(
                              (e) => e.id === data[i].pc_qualities_id
                            );
                          let pc_quality_types =
                            filter_pc_types.length > 0
                              ? filter_pc_types[0].purchase_types
                              : [];
                          const pc_types_menu = [
                            <MenuItem value="0" key={-1} disabled>
                              {" "}
                              Please Select
                            </MenuItem>,
                          ];
                          for (var j of pc_quality_types) {
                            pc_types_menu.push(
                              <MenuItem value={j.id} key={j.id}>
                                {j.purchase_type}
                              </MenuItem>
                            );
                          }
                          data[i].pc_types_menu = pc_types_menu;
                        }
                        this.setState({
                          cps_contract_mappping_data: data,
                        });
                      }
                    );
                  } else {
                    alert(
                      "Unexpected error occured. Please contact administrator."
                    );
                  }
                } else if (res.code === "607") {
                  window.location.href = "/logout";
                }
              });
            }
          });
      } else {
        this.setState({
          snackBarErrorOpen: true,
          errorMsg: "Invalid purchase contract",
        });
      }
    }
  };

  removeProcurementRowsHandler = (idx) => () => {
    const rows = this.state.cps_contract_mappping_data;
    rows.splice(idx, 1);
    this.setState({
      cps_contract_mappping_data: rows,
    });
  };

  submitHandler = (e) => {
    this.setState({ submitLoading: true });
    let {
      contract_date,
      cps_contract_mappping_data,
      payment_terms,
      files,
      vendor_id,
      contract_type,
      other_vendor_name,
      currency,
      remarks,
      purchase_contract_ids,
    } = this.state;
    // files validation
    var existing_file_ids = [];
    const existing_files = files.filter((e) => e.id);
    for (var obj1 of existing_files) {
      existing_file_ids.push(obj1.id);
    }
    const new_files = files.filter((e) => !e.id);
    let missing_fields = 0;
    let files_missing = 0;
    var files_data = new_files;
    for (var obj of files_data) {
      if (obj.file_type !== "" || obj.file_content !== "") {
        if (obj.file_content !== "") {
          if (obj.file_description === "") {
            missing_fields++;
            obj.file_description_error = true;
          } else {
            obj.file_description = obj.file_description.toUpperCase();
          }
        } else if (obj.file_description !== "") {
          if (obj.file_content === "") {
            files_missing++;
          }
        }
      }
    }
    this.setState({
      files: files_data,
    });

    if (missing_fields > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please fill mandatory fields.",
        submitLoading: false,
      });
      return;
    }
    if (files_missing > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please Select file.",
        submitLoading: false,
      });
      return;
    }
    const content_files = files_data.filter((e) => e.file_content !== "");

    // contract fileds validation
    if (contract_date === null) {
      this.setState({
        contract_date_error: true,
      });
    }
    // if (purchase_contract_id === '') {
    //   this.setState({
    //     purchase_contract_error: true,
    //   })
    // }

    if (contract_date === null) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please fill mandatory files.",
        submitLoading: false,
      });
      return;
    }

    // if (vendor_id === "0" || vendor_id === "Others") {
    //   vendor_id = null;
    // }
    // if (vendor_id === "Others") {
    //   if (other_vendor_name === "") {
    //     this.setState({
    //       vendor_name_error: true,
    //       snackBarErrorOpen: true,
    //       errorMsg: "Please enter vendor name",
    //     });
    //     return;
    //   }
    // }
    // let duplicates_data = cps_contract_mappping_data.filter(
    //   (v, i, a) =>
    //     a.findIndex(
    //       (t) =>
    //         t.pc_qualities_id === v.pc_qualities_id &&
    //         t.pc_purchase_type_id === v.pc_purchase_type_id
    //     ) !== i
    // );
    // if (duplicates_data.length > 0) {
    //   this.setState({
    //     pc_qualitie_error: true,
    //     pc_type_error: true,
    //     snackBarErrorOpen: true,
    //     errorMsg: "No duplicates allowed.",
    //     submitLoading: false,
    //   });
    //   return;
    // }

    let mappping_data = cps_contract_mappping_data;
    for (var i = 0; i < mappping_data.length; i++) {
      if (purchase_contract_ids.length === 0) {
        mappping_data[i].pc_qualities_id = null;
        mappping_data[i].pc_purchase_type_id = null;
      }
      if (mappping_data[i].pc_types_menu) {
        delete mappping_data[i].pc_types_menu;
      }
    }

    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const cps_contract_id = decodeURIComponent(
      window.atob(this.props.match.params.cps_contract_id)
    );

    const base_contract_id = this.state.base_contract_id;
    const base_contract_no = this.state.base_contract_no;
    const contract_kind = "Addendum";

    api
      .add_cps_contract(
        loginUserID,
        idToken,
        purchase_contract_ids,
        contract_date,
        contract_type,
        vendor_id,
        other_vendor_name,
        currency,
        mappping_data,
        payment_terms,
        remarks,
        contract_kind,
        base_contract_id,
        base_contract_no,
        content_files
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState(
                {
                  snackBarSuccessOpen: true,
                  successMsg: res.message,
                },
                () => {
                  window.location.href =
                    "/view-cps-contract/" + btoa(cps_contract_id);
                }
              );
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
                submitLoading: false,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            } else if (res.code === "624") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.columns.toString(),
                submitLoading: false,
              });
            }
          });
        }
      });
  };

  render() {
    const cps_contract_id = decodeURIComponent(
      window.atob(this.props.match.params.cps_contract_id)
    );
    return (
      <div id="wraper">
        <SideBar />
        <div className="content">
          <Header />
          <div className="content-header">
            <div className="col-sm text-left pl-0">
              <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                <a href={"/view-cps-contract/" + btoa(cps_contract_id)}>
                  <i className="fa fa-arrow-left left_arrow_adjst" />
                </a>{" "}
                Coal Procurment Service Contract
              </h4>
            </div>
          </div>
          {this.state.isLoading && <Loader />}
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          {!this.state.isLoading && (
            <section className="container p-3">
              <div className="card" style={{ overflowY: "scroll" }}>
                <div className="section_block" style={{ width: "85%" }}>
                  <div className="row mb-3">
                    <div className="col-lg-4  mb-2 p">
                      <label className="contract_display_header_label">
                        Contract Type
                      </label>
                      <div className="contract_display_header_value">
                        {this.state.contract_type
                          ? this.state.contract_type
                          : "-"}{" "}
                      </div>
                    </div>

                    <div className="col-lg-4 p-0">
                      <label className="form_label mb-0">
                        Contract Date <span style={{ color: "red" }}>*</span>
                      </label>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          autoOk={true}
                          inputVariant="outlined"
                          variant="inline"
                          margin="dense"
                          fullWidth
                          format="dd/MM/yyyy"
                          value={this.state.contract_date}
                          onChange={(date) => {
                            this.setState({
                              contract_date: date,
                              contract_date_error: false,
                            });
                          }}
                          error={this.state.contract_date_error}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <IconButton>
                                  <EventIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                            className: "pl-0",
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                    <div className="col-lg-4 pr-0">
                      <label className="form_label mb-0">
                        Purchase Contract
                      </label>
                      <Autocomplete
                        options={this.state.purchaseDataForMenu}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) => {
                          if (value) {
                            this.setState(
                              {
                                purchase_contract_ids: [value.value],
                                purchase_drop_values: value,
                              },
                              () => {
                                if (
                                  this.state.purchase_contract_ids.length === 1
                                ) {
                                  this.handlePurchaseContract(value);
                                }
                              }
                            );
                          } else {
                            this.setState({
                              purchase_contract_ids: [],
                              purchase_contract_id: null,
                              purchase_contract_no: "",
                              pc_qualities_id: "0",
                              purchase_type: "",
                              procurement_quality_standard: null,
                              purchase_drop_values: { name: "", value: "" },
                              barge_quantity_error_msg: "",
                              barge_planned_quantity_error_msg: "",
                              barge_redfraft_quantity_error_msg: "",
                              total_barge_quantity: "",
                              pc_total_quantity: "",
                              purchaseQualitySelection: null,
                              purchaseTypeSelection: null,
                            });
                          }
                        }}
                        InputProps={{
                          style: {
                            fontFamily: "Poppins",
                            fontSize: "14px",
                          },
                        }}
                        size="small"
                        name="purchaseContract"
                        disabled
                        value={this.state.purchase_drop_values}
                        renderOption={(option) => (
                          <React.Fragment>
                            <div className="col-lg p-0 m-0">
                              <label className="form_label mb-0">
                                {option.name}{" "}
                              </label>
                              <label
                                className="contract_display_header_value m-0 pl-0"
                                style={{ fontSize: 10, paddingLeft: 5 }}
                              >
                                {option.vendor_name
                                  ? "( " + option.vendor_name + " )"
                                  : null}
                              </label>
                            </div>
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Purchase Contract"
                            variant="outlined"
                            fullWidth
                            error={this.state.purchase_contract_error}
                            style={{ top: 8 }}
                          />
                        )}
                      />
                    </div>
                    <div className="col-lg-4 p-0">
                      <label className="form_label mb-0">
                        Vendor <span style={{ color: "red" }}>*</span>
                      </label>
                      <Autocomplete
                        options={this.state.vendorDataForMenu}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) => {
                          if (value) {
                            this.setState({
                              vendor_id: value.value,
                              vendor_name: value.name,
                              vendor_drop_value: {
                                name: value.name,
                                value: value.value,
                              },
                            });
                          } else {
                            this.setState({
                              vendor_id: "",
                              vendor_drop_value: { name: "", value: "" },
                            });
                          }
                        }}
                        InputProps={{
                          style: {
                            fontFamily: "Poppins",
                            fontSize: "14px",
                          },
                        }}
                        disabled
                        size="small"
                        name="purchaseContract"
                        value={this.state.vendor_drop_value}
                        renderOption={(option) => (
                          <React.Fragment>
                            {/* {option.name} ({option.vendor_name}) */}
                            <div className="col-lg-12 p-0 m-0">
                              <label className="form_label mb-0">
                                {option.name}
                              </label>
                              {/* <label className='contract_display_header_value m-0 pl-0' style={{ fontSize: 10, paddingLeft: 5 }}>{option.vendor_name ? "( " + option.vendor_name + " )" : null}</label> */}
                            </div>
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Vendors"
                            variant="outlined"
                            fullWidth
                            // error={this.state.purchase_contract_error}
                            style={{ top: 8 }}
                          />
                        )}
                      />
                    </div>
                    <div className="col-lg-4 pr-0">
                      <label className="form_label mb-0">
                        Currency <span style={{ color: "red" }}>*</span>
                      </label>
                      <TextField
                        name="currency"
                        variant="outlined"
                        margin="dense"
                        select
                        value={this.state.currency}
                        onChange={(e) => {
                          this.setState({
                            currency: e.target.value,
                          });
                        }}
                        error={this.state.currencyError}
                        fullWidth
                        inputProps={{ style: { textTransform: "uppercase" } }}
                      >
                        {this.state.currencyData}
                      </TextField>
                    </div>
                    {/* {(this.state.vendor_id === "Others" ||
                      this.state.vendor_id === null ||
                      this.state.vendor_id === "") && (
                        <div className="col-lg-4 pr-0">
                          <label className="form_label mb-0">
                            Vendor Name <span style={{ color: "red" }}>*</span>
                          </label>
                          <TextField
                            name="other_vendor_name"
                            variant="outlined"
                            margin="dense"
                            value={this.state.other_vendor_name}
                            fullWidth
                            onChange={(e) => {
                              this.setState({
                                other_vendor_name: e.target.value,
                              });
                            }}
                            inputProps={{ style: { textTransform: "uppercase" } }}
                          />
                        </div>
                      )} */}
                  </div>
                  {this.state.cps_contract_mappping_data.map((e, idx) => (
                    <div className="row mb-3" key={e.pc_qualities_id}>
                      <div className="col-lg-11 p-0">
                        <div className="card-body border_dashed">
                          <div className="row">
                            {this.state.purchase_contract_id &&
                              this.state.purchase_contract_ids.length <= 1 && (
                                <div className="col-lg-4 pr-0">
                                  <label className="form_label mb-0">
                                    Purchase Quality{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <TextField
                                    name="pc_quality"
                                    value={e.pc_qualities_id}
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth
                                    select
                                    error={this.state.pc_qualitie_error}
                                    onChange={(e) => {
                                      let row =
                                        this.state.cps_contract_mappping_data;
                                      row[idx].pc_qualities_id = e.target.value;
                                      let value = "";
                                      value = e.target.value;
                                      const filter_pc_types =
                                        this.state.purchase_qualities.filter(
                                          (e) => e.id === value
                                        );
                                      let pc_quality_types =
                                        filter_pc_types.length > 0
                                          ? filter_pc_types[0].purchase_types
                                          : [];
                                      row[idx].quantity_in_mt =
                                        filter_pc_types.length > 0
                                          ? filter_pc_types[0].quantity_in_mt
                                          : "";
                                      const pc_types_menu = [
                                        <MenuItem value="0" key={-1} disabled>
                                          {" "}
                                          Please Select
                                        </MenuItem>,
                                      ];
                                      for (var i of pc_quality_types) {
                                        pc_types_menu.push(
                                          <MenuItem value={i.id} key={i.id}>
                                            {i.purchase_type}
                                          </MenuItem>
                                        );
                                      }
                                      row[idx].pc_types_menu = pc_types_menu;
                                      this.setState({
                                        cps_contract_mappping_data: row,
                                        pc_quality_types: pc_quality_types,
                                        pc_types_menu: pc_types_menu,
                                        pc_qualitie_error: false,
                                      });
                                    }}
                                  >
                                    {this.state.pc_qualities}
                                  </TextField>
                                </div>
                              )}
                            {e.pc_qualities_id !== "0" &&
                              e.pc_qualities_id &&
                              this.state.purchase_contract_id &&
                              this.state.purchase_contract_ids.length <= 1 && (
                                <div className="col-lg-4 pr-0">
                                  <label className="form_label mb-0">
                                    Purchase Type{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <TextField
                                    name="pc_type"
                                    value={e.pc_purchase_type_id}
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth
                                    select
                                    error={this.state.pc_type_error}
                                    onChange={(e) => {
                                      let row =
                                        this.state.cps_contract_mappping_data;
                                      row[idx].pc_purchase_type_id =
                                        e.target.value;
                                      // let value = ''
                                      // console.log(e.target.value);
                                      // value = e.target.value;
                                      //const filter_pc_type = this.state.pc_quality_types.filter(e => e.id === value);
                                      //let purchase_price = filter_pc_type.length > 0 ? filter_pc_type[0].price_pmt : '';

                                      //row[idx].price_pmt = purchase_price;
                                      //row[idx].total_price = Number(row[idx].quantity_in_mt) * Number(row[idx].price_pmt)
                                      this.setState({
                                        cps_contract_mappping_data: row,
                                        pc_type_error: false,
                                      });
                                    }}
                                  >
                                    {e.pc_types_menu}
                                  </TextField>
                                </div>
                              )}
                            <div className="col-lg-4 pr-0">
                              <label className="form_label mb-0">
                                Price Type
                              </label>
                              <RadioGroup
                                aria-label="price_type"
                                name="price_type"
                                onChange={(e) => {
                                  let row =
                                    this.state.cps_contract_mappping_data;
                                  row[idx].price_type = e.target.value;
                                  this.setState({
                                    cps_contract_mappping_data: row,
                                  });
                                }}
                                style={{ flexDirection: "row" }}
                              >
                                <FormControlLabel
                                  value="Lumpsum"
                                  control={
                                    <NewRadio
                                      checked={e.price_type === "Lumpsum"}
                                    />
                                  }
                                  label="Lumpsum"
                                />
                                <FormControlLabel
                                  value="Per MT"
                                  control={
                                    <NewRadio
                                      checked={e.price_type === "Per MT"}
                                    />
                                  }
                                  label="Per MT"
                                />
                              </RadioGroup>
                            </div>
                            <div className="col-lg-4 pr-0">
                              <label className="form_label mb-0">
                                Quantity <span style={{ color: "red" }}>*</span>
                              </label>
                              <TextField
                                name="quantity_in_mt"
                                value={e.quantity_in_mt}
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                type="number"
                                placeholder="Enter Value"
                                onChange={(e) => {
                                  let row =
                                    this.state.cps_contract_mappping_data;
                                  row[idx].quantity_in_mt = e.target.value;
                                  row[idx].total_price =
                                    Number(row[idx].quantity_in_mt) *
                                    Number(row[idx].price_pmt);

                                  this.setState({
                                    cps_contract_mappping_data: row,
                                  });
                                }}
                              ></TextField>
                            </div>
                            {e.price_type === "Per MT" && (
                              <div className="col-lg-4 pr-0">
                                <label className="form_label mb-0">
                                  Price PMT
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <TextField
                                  name="price_pmt"
                                  value={e.price_pmt}
                                  margin="dense"
                                  variant="outlined"
                                  fullWidth
                                  type="number"
                                  placeholder="Enter Value"
                                  onChange={(e) => {
                                    let row =
                                      this.state.cps_contract_mappping_data;
                                    row[idx].price_pmt = e.target.value;
                                    row[idx].total_price =
                                      Number(
                                        row[idx].quantity_in_mt
                                          ? row[idx].quantity_in_mt
                                          : 0
                                      ) *
                                      Number(
                                        row[idx].price_pmt
                                          ? row[idx].price_pmt
                                          : 0
                                      );

                                    this.setState({
                                      cps_contract_mappping_data: row,
                                    });
                                  }}
                                  disabled={e.price_type === "Lumpsum"}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment
                                        position="end"
                                        className="adornment_background"
                                      >
                                        {this.state.currency}
                                      </InputAdornment>
                                    ),
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                      padding: 0,
                                    },
                                  }}
                                ></TextField>
                              </div>
                            )}
                            {e.price_type === "Per MT" ? (
                              <div className="col-lg-4">
                                <label className="form_label mb-3">
                                  Total Price
                                </label>
                                <div className="contract_display_header_value">
                                  {e.total_price}
                                </div>
                              </div>
                            ) : (
                              <div className="col-lg-4">
                                <label className="form_label mb-0">
                                  Total Price
                                </label>
                                <TextField
                                  name="total_price"
                                  value={e.total_price}
                                  margin="dense"
                                  variant="outlined"
                                  fullWidth
                                  type="number"
                                  placeholder="Enter Value"
                                  onChange={(e) => {
                                    let row =
                                      this.state.cps_contract_mappping_data;
                                    row[idx].total_price = e.target.value;
                                    this.setState({
                                      cps_contract_mappping_data: row,
                                    });
                                  }}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment
                                        position="end"
                                        className="adornment_background"
                                      >
                                        {this.state.currency}
                                      </InputAdornment>
                                    ),
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                      padding: 0,
                                    },
                                  }}
                                ></TextField>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {this.state.cps_contract_mappping_data.length > 1 && (
                        <div className="col-lg-1 d-flex justify-content-center m-auto">
                          <div className="icon_hover text-align-center">
                            <i
                              className="fa fa-trash-o m-auto"
                              onClick={this.removeProcurementRowsHandler(idx)}
                              style={{
                                fontSize: 24,
                                color: config.themeColor,
                                cursor: "pointer",
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                  <div className="row mt-2 mb-2">
                    <button
                      type="button"
                      onClick={() => {
                        const item = {
                          purchase_contract_id: "",
                          pc_qualities_id: "0",
                          pc_purchase_type_id: "0",
                          price_type: "Lumpsum",
                          quantity_in_mt: "",
                          price_pmt: "",
                          total_price: "",
                          pc_types_menu: [],
                        };

                        this.setState({
                          cps_contract_mappping_data: [
                            ...this.state.cps_contract_mappping_data,
                            item,
                          ],
                        });
                      }}
                      className="header_button header_button_text addrow_button_adjustment"
                      style={{ color: config.themeColor }}
                    >
                      Add More
                    </button>
                  </div>

                  <div className="row mb-3">
                    <div className="col-lg-12 p-0">
                      <label className="form_label mb-0">Remarks</label>
                      <TextField
                        name="remarks"
                        margin="dense"
                        variant="outlined"
                        multiline
                        rows={4}
                        placeholder="Payment Terms"
                        value={this.state.remarks}
                        onChange={(e) => {
                          this.setState({
                            remarks: e.target.value,
                          });
                        }}
                        fullWidth
                        inputProps={{ style: { textTransform: "uppercase" } }}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-lg-12 p-0">
                      <label className="form_label mb-0">Payment Terms </label>
                      <TextField
                        name="payment_terms"
                        margin="dense"
                        variant="outlined"
                        multiline
                        rows={4}
                        placeholder="Payment Terms"
                        value={this.state.payment_terms}
                        onChange={(e) => {
                          this.setState({
                            payment_terms: e.target.value,
                          });
                        }}
                        fullWidth
                        inputProps={{ style: { textTransform: "uppercase" } }}
                      />
                    </div>
                  </div>
                  {(this.state.features.includes("505") ||
                    this.state.features.includes("97")) && (
                      <FileUpload
                        files={this.state.files}
                        feature_name="Cps_contract"
                        callbackFn={(data) => this.setState({ files: data })}
                      />
                    )}
                </div>
              </div>
              <footer className="row bottom_buttons_section">
                <button
                  type="button"
                  className="btn previous_button"
                  name="submit"
                  onClick={() =>
                  (window.location.href =
                    "/view-cps-contract/" + btoa(cps_contract_id))
                  }
                >
                  CANCEL
                </button>
                <button
                  type="button"
                  className="btn next_button"
                  name="Back"
                  onClick={this.submitHandler}
                  disabled={this.state.submitLoading}
                >
                  <span
                    className={
                      this.state.submitLoading
                        ? "spinner-grow spinner-grow-sm mr-2"
                        : ""
                    }
                  ></span>
                  SUBMIT
                </button>
              </footer>
            </section>
          )}
        </div>
      </div>
    );
  }
}

export default ProcurementServiceAddendum;