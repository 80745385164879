import React,{Component} from 'react';
import CookieHandler from '../common/CookieHandler';
import SideBar from '../common/SideBar';
import TextField from '@material-ui/core/TextField';
import Header from '../common/Header';
import '../../App.css';
import api from '../../api/api';
import config from '../../config/config';


export default class AddUser extends Component {
  constructor(props){
    super(props);
    this.Cookie = new CookieHandler();
    this.state={
      userName :'',
      password : "",
      email : "",
      userNameError :false,
      passwordError :false,
      emailError :false,
      errorMessage :'',
      successMessage :''
    }
  }

  componentDidMount() {
    if(!this.Cookie.isLoggedIn()) {
      this.props.history.replace('/logout');
    }
    document.title = config.documentTitle +"Add User";
  }

  handleText= (e) =>{
    if(e.target.name === 'userName') {
      this.setState({
        userNameError : false,
        [e.target.name] : e.target.value
      })
    }
    else if(e.target.name === 'email') {
      this.setState({
        emailError : false,
        [e.target.name] : e.target.value
      })
    }
    else if(e.target.name === 'password') {
      this.setState({
        passwordError : false,
        [e.target.name] : e.target.value
      })
    }
    this.setState({
      [e.target.name] : e.target.value
    })
  }

  handleSubmit= (e) =>{
    e.preventDefault();
    var {userName, password, email} = this.state;
    var loginUserID = this.Cookie.getCookie('loginUserId');
    var idToken = this.Cookie.getIdTokenCookie();
    if(userName === '' && email === ''&& password==='') {
      this.setState({
        userNameError : true, 
        emailError : true, 
        passwordError : true
      })
    }
    if(userName === '') {
      this.setState({
        userNameError :true
      })
      return;
    }
    else {
      this.setState({
        userNameError :false
      })
    }
     if(email === '') {
      this.setState({
        emailError : true
      })
      return;
    }
    else if(password==='') {
      this.setState({
        passwordError : true
      })
      return;
    }
    api.userRegister(userName, password, email, loginUserID, idToken).then((Response)=>{
      if(Response.status >=200 && Response.status < 300) {
        Response.json().then((res)=>{
          if(res) {
            //this.Cookie.setIdTokenCookie(res.idtoken);TokenCookie(res.idtoken);
            this.setState({
              successMessage : res.message,
              errorMessage :"",
            })
          }
          if(res.id) {
            setTimeout(()=>{
              window.location.href='/view-user/'+btoa(res.id)
            })
          }
        })
      }
      else if(Response.status === 601) {
        Response.json().then((res)=>{
          this.setState({
            errorMessage : res.message,
            successMessage :''
          })
        })
      }
      else if(Response.status === 607) {
        Response.json().then((res)=>{
          this.setState({
            errorMessage : res.message,
            successMessage :''
          })
        })
        setTimeout(()=>{
          window.location.href='/logout'
        },2000)
      }
    })
  }

  render() {
    return(
      <div id='wraper'>
        <SideBar />
        <div className='content'>
        <Header />
          <div className='content-header'>
            <div className='col-sm text-left pl-0'>
              <h4 style={{padding: '10px 20px', margin: '0px'}}>Add User</h4>
            </div>
          </div>
          <form className='container p-3'>
          {this.state.errorMessage &&            
              <div className='row'>
                <div className='col-25'></div>
                <div className='col-75'>
                  <p className='error-class'>{this.state.errorMessage}</p>
                </div>
              </div>
            }
            {this.state.successMessage &&            
              <div className='row'>
                <div className='col-25'></div>
                <div className='col-75'>
                  <p className='success-class'>{this.state.successMessage}</p>
                </div>
              </div>
            }
            <div className='row'>
              <div className='col-25'>
                <label className='label-text'>User Name <span style={{color:'red'}}>*</span></label>
              </div>
              <div className='col-75'>
                <TextField
                  name='userName'
                  placeholder='User Name'
                  margin='dense'
                  variant='outlined'
                  value={this.state.userName}
                  error={this.state.userNameError}
                  onChange={this.handleText}
                  fullWidth
                >                  
                </TextField>
              </div>
            </div>
           
            <div className='row'>
              <div className='col-25'>
                <label className='label-text'>Email <span style={{color:'red'}}>*</span></label>
              </div>
              <div className='col-75'>
                <TextField
                  name='email'
                  placeholder='Email'
                  margin='dense'
                  variant='outlined'
                  value={this.state.email}
                  error={this.state.emailError}
                  onChange={this.handleText}
                  fullWidth
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-25'>
                <label className='label-text'>Password <span style={{color:'red'}}>*</span></label>
              </div>
              <div className='col-75'>
                <TextField
                  name='password'
                  placeholder='Password'
                  margin='dense'
                  type='password'
                  variant='outlined'
                  value={this.state.password}
                  error={this.state.passwordError}
                  onChange={this.handleText}
                  fullWidth
                />
              </div>
            </div> 
            <div className='row'>
              <div className='col-25'>
                <label className='label-text'>Confirm Password <span style={{color:'red'}}>*</span></label>
              </div>
              <div className='col-75'>
                <TextField
                  name='password'
                  placeholder='Confirm Password'
                  margin='dense'
                  type='password'
                  variant='outlined'
                  value={this.state.password}
                  // error={this.state.passwordError}
                  onChange={this.handleText}
                  fullWidth
                />
              </div>
            </div>               
            <br />
            <div className='row'>
              <div className='col-25'>
                <button type='button' className='btn btn-info btn-responsive mr-3' name='submit' onClick={this.handleSubmit}>Submit</button>
                <button type='button' className='btn btn-secondary btn-responsive' name='Back' onClick={()=>{window.location.href='/user-list'}}>Cancel</button>
              </div>
            </div>
          </form>
        </div>
      </div>     
    )
  }
}