import React, { useState, useEffect } from 'react';

import {
  Drawer,
} from '@material-ui/core';
import ViewCoalPriceIndexes from '../../CoalIndexPrices/coalPriceIndexView';

function IndexLinkPriceView({ open, coal_index, callbackFn, closeDrawerHandler }) {

  const [state, setState] = useState({
    coal_index_type: "Weekly",
    index_ids: [],
    average_coal_index_price: 0,
    prorata_gar: "0",
    prorata_coal_index_price: 0,
    bonus_coal_index_pmt: "",
    penality_coal_index_pmt: "",
    derivered_coal_index_pmt: "",
    final_coal_index_pmt: "",
    coal_index_remarks: null,
  })

  useEffect(() => {
    if (coal_index) {
      setState(coal_index)
    }
  }, [coal_index]);
  return (
    <div>
      <Drawer
        anchor="right"
        open={open}
        variant="temporary"
        elevation={20}
        style={{ overflow: "initial" }}
      // onClose={() => setPopover(false)}
      >
        <div className="container bg-white p-0" style={{ minHeight: "100vh", width: 700 }}>


          <div className="drawer-section">
            <div className="card">
              <ViewCoalPriceIndexes coal_index={state} />
            </div>
          </div>
          <div
            className="row col-lg-12 modal-footer justify-content-end bottom_buttons_section"
            style={{ bottom: 0, background: "white" }}
          >
            <button
              type="button"
              className="drawer_button drawer_text drawer_calculate_button drawer_calculate_adjustment"
              onClick={() => closeDrawerHandler(false)}
            >
              Cancel
            </button>
            <button
              type="button"
              className="drawer_button drawer_text"
              onClick={() => callbackFn(state)}
            >
              Save
            </button>
          </div>
        </div>
      </Drawer>

    </div>
  )
}

export default IndexLinkPriceView
