import React, { Component } from 'react';
import Header from '../common/Header';
import SideBar from '../common/SideBar';
import CookieHandler from '../common/CookieHandler';
import config from '../../config/config';
import api from '../../api/api';

import { Alert } from '@material-ui/lab';
import {
  Snackbar, TableContainer, Table, TableCell,
  TableHead, TableRow, TableBody, Paper, TextField, InputAdornment,
  Button
} from '@material-ui/core';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import EventIcon from '@material-ui/icons/Event';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DateFnsUtils from '@date-io/date-fns';
import MothlyCoalPrice from './MonthlyCoalPrices';
import CoalPriceIndexes from './coalPriceIndexes';

import { dateFormateToDB, localDateFormate } from '../common/common';
import Loader from '../common/Loader';

export default class WeeklyIndex extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      week_ending_date: null,
      gar_6500: '',
      gar_5800: '',
      gar_5000: '',
      gar_4200: '',
      gar_3400: '',
      weekly_coal_indexes: [],
      offset: 0,
      limit: 10,
      files: [
        {
          file_description: '',
          file_name: "",
          file_extension: "",
          file_content: "",
          feature_name: "Coal_indexes",
        }
      ],
      isLoading: true,
      showLoadMore: false,
      loadMore: false,
      weeklyShown: true,
      monthlyShown: false,
      calculationShown: false,
      coal_index: {
        coal_index_type: "Weekly",
        index_ids: [],
        average_coal_index_price: 0,
        prorata_gar: "0",
        prorata_coal_index_price: 0,
        bonus_coal_index_pmt: "",
        penality_coal_index_pmt: "",
        derivered_coal_index_pmt: "",
        final_coal_index_pmt: "",
      },
      pc_quality: '',
    }
  }

  componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace('/logout');
    }
    var features = this.Cookie.getCookie("features");
    if (!(features.includes('97') || features.includes('549'))) {
      window.location.href = '/'
    }
    this.setState({ features: features });
    this.get_weekly_coal_prices();
    document.title = config.documentTitle + "Weekly Coal Price Index";
  }

  get_weekly_coal_prices = () => {
    const { offset, limit } = this.state;
    const reqParams = {
      login_user_id: this.Cookie.getCookie('loginUserId'),
      idtoken: this.Cookie.getIdTokenCookie(),
      offset: offset,
      limit: limit
    }
    api.get_weekly_coal_indexes(reqParams)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json()
            .then((res) => {
              if (res.code === '200') {
                if (res.weekly_coal_indexes.length >= 5) {
                  this.setState({ showLoadMore: true })
                } else { this.setState({ showLoadMore: false }) }
                if (this.state.loadMore) {
                  this.setState({
                    weekly_coal_indexes: [...this.state.weekly_coal_indexes, ...res.weekly_coal_indexes],
                    submitting: false,
                  })
                }
                else {
                  this.setState({
                    weekly_coal_indexes: [...res.weekly_coal_indexes],
                    submitting: false,
                  });
                }
                this.setState({ isLoading: false, loadMore: false, submitting: false })
              }
            })
        }
      })
  }


  fileUploadHandler = (idx) => (event) => {
    let file = event.target.files[0];
    if (file) {

      let file_data = "";
      let fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onloadend = () => {
        let file_content = fileReader.result;
        let file_extension = file.name.substr(file.name.lastIndexOf(".") + 1);
        let file_name = file.name;
        let file_type = file.type;
        file_data = file_content.replace("data:" + file_type + ";base64,", "");

        var row = this.state.files ? this.state.files : [];
        row[idx].file_description = file_name;
        row[idx].file_name = file_name;
        row[idx].file_extension = file_extension;
        row[idx].file_type = file_type;
        row[idx].file_content = file_data;

        this.setState({
          files: row,
        });
      };
    }
  };

  addMoreFileHandler = () => {
    const data = {
      file_description: "",
      file_name: "",
      file_extension: "",
      file_content: "",
      feature_name: "Coal_indexes",
    };

    this.setState({
      files: [...this.state.files, data],
    });
  };

  submitHandler = async (e) => {
    e.preventDefault();
    this.setState({ submitting: true });

    const { week_ending_date, gar_6500, gar_5800, gar_5000, gar_4200, gar_3400, files } = this.state;

    let reqParams = {
      login_user_id: this.Cookie.getCookie('loginUserId'),
      idtoken: this.Cookie.getIdTokenCookie(),
      "week_ending_date": week_ending_date,
      "6500_gar": gar_6500,
      "5800_gar": gar_5800,
      "5000_gar": gar_5000,
      "4200_gar": gar_4200,
      "3400_gar": gar_3400,
      files: files.filter(e => e.file_content !== '')
    }


    api.add_weekly_coal_index(reqParams)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json()
            .then((res) => {
              if (res.code === '200') {
                this.setState({
                  week_ending_date: null,
                  gar_6500: '',
                  gar_5800: '',
                  gar_5000: '',
                  gar_4200: '',
                  gar_3400: '',
                  submitting: false,
                },
                  () => this.get_weekly_coal_prices())

              }
            })
        }
      })
  }

  render() {
    return (
      <div id='wraper'>
        <SideBar />
        <div className='content'>
          <Header />
          {this.state.isLoading &&
            <Loader />
          }
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={this.state.snackBarErrorOpen} onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: '450px' }}
          >
            <Alert elevation={6} variant="filled" onClose={() => this.setState({ snackBarErrorOpen: false })} severity="error" style={{ width: '100%' }}>
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            style={{ width: '450px' }}
            open={this.state.snackBarSuccessOpen} autoHideDuration={100000} onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert elevation={6} variant="filled" onClose={() => this.setState({ snackBarSuccessOpen: false })} severity="success" style={{ width: '100%' }}>
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <div className='content-header'>
            <div className='col-sm pl-0 row'>
              <h4 style={{ padding: '10px 20px', margin: '0px', }}
              >
                <a
                  href={'/reports'}
                  className="fa fa-arrow-left left_arrow_adjst"
                >
                </a>


                <Button
                  className="tab_button"
                  onClick={(e, value) => {
                    this.setState(
                      {
                        weeklyShown: true,
                        monthlyShown: false,
                        calculationShown: false,
                      });
                  }}
                  style={{
                    textTransform: "capitalize",
                    margin: "5px 0px",
                    padding: "5px 20px",
                    borderRadius: 35,
                    height: 30,
                    color:
                      this.state
                        .weeklyShown
                        ? config.themeColor
                        : "#483b34b3",
                    backgroundColor:
                      this.state
                        .weeklyShown
                        ? "#fff7f2"
                        : "#fff",
                    fontSize: "18px",
                    border: "0px",
                  }}
                >
                  Weekly Coal Index
                  {/* <span style={{ color: 'lightgrey', margin: '0px 5px' }}>|</span><span style={{ color: '#959cb6', fontSize: '15px' }}>{this.state.weekly_coal_indexes.length}</span> */}
                </Button>
                <Button
                  className="tab_button"
                  onClick={(e, value) => {
                    this.setState(
                      {
                        monthlyShown: true,
                        weeklyShown: false,
                        calculationShown: false,

                      });
                  }}
                  style={{
                    textTransform: "capitalize",
                    margin: "5px 0px",
                    padding: "5px 20px",
                    borderRadius: 35,
                    height: 30,
                    color:
                      this.state
                        .monthlyShown
                        ? config.themeColor
                        : "#483b34b3",
                    backgroundColor:
                      this.state
                        .monthlyShown
                        ? "#fff7f2"
                        : "#fff",
                    fontSize: "18px",
                    border: "0px",
                  }}
                >
                  Monthly Coal Index
                </Button>
                <Button
                  className="tab_button"
                  onClick={(e, value) => {
                    this.setState(
                      {
                        monthlyShown: false,
                        weeklyShown: false,
                        calculationShown: true
                      });
                  }}
                  style={{
                    textTransform: "capitalize",
                    margin: "5px 0px",
                    padding: "5px 20px",
                    borderRadius: 35,
                    height: 30,
                    color:
                      this.state
                        .calculationShown
                        ? config.themeColor
                        : "#483b34b3",
                    backgroundColor:
                      this.state
                        .calculationShown
                        ? "#fff7f2"
                        : "#fff",
                    fontSize: "18px",
                    border: "0px",
                  }}
                >
                  Calculations
                </Button>
              </h4>
            </div>
          </div>
          {!this.state.isLoading &&
            <div className='container p-2'>
              {this.state.weeklyShown &&

                <div className='card p-0'>
                  {config.company === "SRPL" &&

                    <div className='row'>
                      <div className='col-lg-12 p-3'>

                        <Paper>

                          <TableContainer>
                            <Table stickyHeader size="small" aria-label="sticky table">
                              <TableHead>
                                <TableRow>
                                  <TableCell nowrap='true' className='text-uppercase'> Week Ending </TableCell>
                                  <TableCell nowrap='true' className='text-uppercase'> 6500 GAR (6200 NAR)</TableCell>
                                  <TableCell nowrap='true' className='text-uppercase'> 5800 GAR (5500 NAR)</TableCell>
                                  <TableCell nowrap='true' className='text-uppercase'> 5000 GAR (4600 NAR)</TableCell>
                                  <TableCell nowrap='true' className='text-uppercase'> 4200 GAR (3800 NAR)</TableCell>
                                  <TableCell nowrap='true' className='text-uppercase'> 3400 GAR (3000 NAR)</TableCell>
                                  <TableCell nowrap='true' className='text-uppercase'> ICI FIle</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody  >

                                <TableRow >
                                  <TableCell nowrap='true' >
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                      <DatePicker
                                        autoOk={true}
                                        inputVariant="outlined"
                                        variant="inline"
                                        margin="dense"
                                        fullWidth
                                        format="dd/MM/yyyy"
                                        value={this.state.week_ending_date}
                                        error={this.state.date_of_paymentError}
                                        onChange={(date) => {
                                          this.setState({
                                            week_ending_date: dateFormateToDB(date),
                                            date_of_paymentError: false
                                          })
                                        }}
                                        shouldDisableDate={(date) => {
                                          return date.getDay() !== 5;
                                        }}
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment position="start">

                                              <EventIcon />

                                            </InputAdornment>
                                          ),
                                          className: 'pl-0'
                                        }}
                                      />
                                    </MuiPickersUtilsProvider>
                                  </TableCell>
                                  <TableCell nowrap='true' >
                                    <TextField
                                      name='upper_limit_usd'
                                      margin='dense'
                                      variant='outlined'
                                      placeholder='PMT in USD'
                                      required
                                      value={this.state.gar_6500}
                                      inputProps={{ style: { textTransform: 'uppercase' } }}
                                      fullWidth
                                      onChange={(e) => {
                                        const re = /^[0-9]*\.?[0-9]*$/;
                                        if (e.target.value === '' || re.test(e.target.value)) {
                                          this.setState({
                                            gar_6500: e.target.value
                                          });
                                        }
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell nowrap='true' >
                                    <TextField
                                      name='upper_limit_idr'
                                      margin='dense'
                                      variant='outlined'
                                      placeholder='PMT in USD'
                                      required
                                      value={this.state.gar_5800}
                                      inputProps={{ style: { textTransform: 'uppercase' } }}
                                      fullWidth
                                      onChange={(e) => {
                                        const re = /^[0-9]*\.?[0-9]*$/;
                                        if (e.target.value === '' || re.test(e.target.value)) {
                                          this.setState({
                                            gar_5800: e.target.value,
                                          });
                                        }
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell nowrap='true' >
                                    <TextField
                                      name='upper_limit_idr'
                                      margin='dense'
                                      variant='outlined'
                                      placeholder='PMT in USD'
                                      required
                                      value={this.state.gar_5000}
                                      inputProps={{ style: { textTransform: 'uppercase' } }}
                                      fullWidth
                                      onChange={(e) => {
                                        const re = /^[0-9]*\.?[0-9]*$/;
                                        if (e.target.value === '' || re.test(e.target.value)) {
                                          this.setState({
                                            gar_5000: e.target.value,
                                          });
                                        }
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell nowrap='true' >
                                    <TextField
                                      name='upper_limit_idr'
                                      margin='dense'
                                      variant='outlined'
                                      placeholder='PMT in USD'
                                      required
                                      value={this.state.gar_4200}
                                      inputProps={{ style: { textTransform: 'uppercase' } }}
                                      fullWidth
                                      onChange={(e) => {
                                        const re = /^[0-9]*\.?[0-9]*$/;
                                        if (e.target.value === '' || re.test(e.target.value)) {
                                          this.setState({
                                            gar_4200: e.target.value,
                                          });
                                        }
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell nowrap='true' >
                                    <TextField
                                      name='3400_gar'
                                      margin='dense'
                                      variant='outlined'
                                      placeholder='PMT in USD'
                                      required
                                      value={this.state.gar_3400}
                                      inputProps={{ style: { textTransform: 'uppercase' } }}
                                      fullWidth
                                      onChange={(e) => {
                                        const re = /^[0-9]*\.?[0-9]*$/;
                                        if (e.target.value === '' || re.test(e.target.value)) {
                                          this.setState({
                                            gar_3400: e.target.value,
                                          });
                                        }
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <input
                                      type="file"
                                      name="fileToUpload"
                                      id="fileToUpload"
                                      className="form-control border-0 pl-0"
                                      onChange={this.fileUploadHandler(0)}
                                    />
                                  </TableCell>

                                </TableRow>

                                <TableRow>
                                  <TableCell></TableCell>
                                  <TableCell colSpan={4} className='text-center'>
                                    <button
                                      type="button"
                                      className="header_button header_button_text mt-1"
                                      onClick={this.submitHandler}
                                      disabled={this.state.submitting}
                                    >
                                      Submit
                                      <span className={this.state.submitting ? "spinner-grow spinner-grow-sm mr-2" : ""}></span>
                                    </button>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Paper>
                      </div>
                    </div>
                  }

                  {this.state.weekly_coal_indexes.length > 0 &&
                    <div className='row'>
                      <div className='col-lg-12 p-3'>

                        <Paper>

                          <TableContainer style={{ maxHeight: 650 }}>
                            <Table stickyHeader size="small" aria-label="sticky table">
                              <TableHead>
                                <TableRow>
                                  <TableCell nowrap='true' className='text-uppercase'> Week Ending </TableCell>
                                  <TableCell nowrap='true' className='text-uppercase'> 6500 GAR  (6200 NAR)</TableCell>
                                  <TableCell nowrap='true' className='text-uppercase'> 5800 GAR  (5500 NAR)</TableCell>
                                  <TableCell nowrap='true' className='text-uppercase'> 5000 GAR  (4600 NAR)</TableCell>
                                  <TableCell nowrap='true' className='text-uppercase'> 4200 GAR  (3800 NAR)</TableCell>
                                  <TableCell nowrap='true' className='text-uppercase'> 3400 GAR  (3000 NAR)</TableCell>
                                  <TableCell>ICI File</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody >

                                {this.state.weekly_coal_indexes.map((e, idx) => (

                                  <TableRow key={idx}>

                                    <TableCell nowrap='true' align='right' >{localDateFormate(e.week_ending_date)}</TableCell>

                                    <TableCell nowrap='true' align='right'  > {e.gar_6500}
                                      {!!this.state.weekly_coal_indexes[idx + 1] && (parseFloat(this.state.weekly_coal_indexes[idx + 1].gar_6500) < parseFloat(this.state.weekly_coal_indexes[idx].gar_6500)) &&
                                        <ArrowUpwardIcon style={{ color: '#4E9F3D', width: 18, height: 18, margin: 5 }} />
                                      }
                                      {!!this.state.weekly_coal_indexes[idx + 1] && (parseFloat(this.state.weekly_coal_indexes[idx + 1].gar_6500) > parseFloat(this.state.weekly_coal_indexes[idx].gar_6500)) &&
                                        <ArrowDownwardIcon style={{ color: '#FF2626 ', width: 18, height: 18, margin: 5 }} />
                                      }
                                    </TableCell>

                                    <TableCell nowrap='true' align='right'  >{e.gar_5800}
                                      {!!this.state.weekly_coal_indexes[idx + 1] && (parseFloat(this.state.weekly_coal_indexes[idx + 1].gar_5800) < parseFloat(this.state.weekly_coal_indexes[idx].gar_5800)) &&
                                        <ArrowUpwardIcon style={{ color: '#4E9F3D', width: 18, height: 18, margin: 5 }} />
                                      }
                                      {!!this.state.weekly_coal_indexes[idx + 1] && (parseFloat(this.state.weekly_coal_indexes[idx + 1].gar_5800) > parseFloat(this.state.weekly_coal_indexes[idx].gar_5800)) &&
                                        <ArrowDownwardIcon style={{ color: '#FF2626 ', width: 18, height: 18, margin: 5 }} />
                                      }
                                    </TableCell>

                                    <TableCell nowrap='true' align='right'  >{e.gar_5000}
                                      {!!this.state.weekly_coal_indexes[idx + 1] && (parseFloat(this.state.weekly_coal_indexes[idx + 1].gar_5000) < parseFloat(this.state.weekly_coal_indexes[idx].gar_5000)) &&
                                        <ArrowUpwardIcon style={{ color: '#4E9F3D', width: 18, height: 18, margin: 5 }} />
                                      }
                                      {!!this.state.weekly_coal_indexes[idx + 1] && (parseFloat(this.state.weekly_coal_indexes[idx + 1].gar_5000) > parseFloat(this.state.weekly_coal_indexes[idx].gar_5000)) &&
                                        <ArrowDownwardIcon style={{ color: '#FF2626 ', width: 18, height: 18, margin: 5 }} />
                                      }
                                    </TableCell>

                                    <TableCell nowrap='true' align='right'  >{e.gar_4200}
                                      {!!this.state.weekly_coal_indexes[idx + 1] && (parseFloat(this.state.weekly_coal_indexes[idx + 1].gar_4200) < parseFloat(this.state.weekly_coal_indexes[idx].gar_4200)) &&
                                        <ArrowUpwardIcon style={{ color: '#4E9F3D', width: 18, height: 18, margin: 5 }} />
                                      }
                                      {!!this.state.weekly_coal_indexes[idx + 1] && (parseFloat(this.state.weekly_coal_indexes[idx + 1].gar_4200) > parseFloat(this.state.weekly_coal_indexes[idx].gar_4200)) &&
                                        <ArrowDownwardIcon style={{ color: '#FF2626 ', width: 18, height: 18, margin: 5 }} />
                                      }
                                    </TableCell>

                                    <TableCell nowrap='true' align='right'  >{e.gar_3400}
                                      {!!this.state.weekly_coal_indexes[idx + 1] && (parseFloat(this.state.weekly_coal_indexes[idx + 1].gar_3400) < parseFloat(this.state.weekly_coal_indexes[idx].gar_3400)) &&
                                        <ArrowUpwardIcon style={{ color: '#4E9F3D', width: 18, height: 18, margin: 5 }} />
                                      }
                                      {!!this.state.weekly_coal_indexes[idx + 1] && (parseFloat(this.state.weekly_coal_indexes[idx + 1].gar_3400) > parseFloat(this.state.weekly_coal_indexes[idx].gar_3400)) &&
                                        <ArrowDownwardIcon style={{ color: '#FF2626 ', width: 18, height: 18, margin: 5 }} />
                                      }
                                    </TableCell>

                                    <TableCell>
                                      {e.files.map((file) => (
                                        <span>

                                          {file.file_path &&

                                            <a
                                              href={config.apiDomain + "/" + file.file_path}
                                              role="button"
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              style={{ color: config.themeColor }}
                                            >
                                              {/* {file.file_description} */}
                                              {`View File`}
                                            </a>
                                          }
                                        </span>
                                      ))}
                                    </TableCell>
                                  </TableRow>
                                ))}

                                {this.state.showLoadMore && (

                                  <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell colSpan={3} className='text-center'>
                                      <button
                                        type="button"
                                        className="header_button header_button_text mt-1"
                                        style={{ width: 100, borderRadius: 18 }}
                                        onClick={() => {
                                          this.setState((state) => {
                                            return { offset: state.offset + 5, loadMore: true }
                                          },
                                            () => this.get_weekly_coal_prices())
                                        }}
                                        disabled={this.state.submitting}
                                      >
                                        Load More
                                        {/* <span className={this.state.submitting ? "spinner-grow spinner-grow-sm mr-2" : ""}></span> */}
                                      </button>
                                    </TableCell>
                                  </TableRow>
                                )}


                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Paper>
                      </div>
                    </div>
                  }

                </div>
              }
              {this.state.monthlyShown &&
                <MothlyCoalPrice />
              }

              {this.state.calculationShown &&
                <div className='container p-2'>
                  <div className='card'>

                    <CoalPriceIndexes
                      grade={"Calculations"}
                      coalPrices={(data) => { this.setState({ coal_index: data }) }}
                      coal_index={this.state.coal_index}
                    />
                  </div>
                </div>

              }

            </div>
          }
        </div>

      </div>

    )
  }
}