import React, { Component } from 'react';
import Loader from '../../common/Loader';
import CookieHandler from "../../common/CookieHandler";
import config from '../../../config/config';
import api from '../../../api/api';
import { toLocaleString } from '../../common/common';
// import InvoicesWithPayments from '../../FinancialPayments/InvoicesWithPayments';
import ViewFiles from '../../common/ViewFiles';
import InvoiceForm from '../../common/InvoiceForm';
import PaymentPostings from '../../common/PaymentPostings';
import BusinessApproveButton from '../../Approvals/BusinessApproveButton';

export default class MvDeadFreightView extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      isLoading: true,
      features: [],
      dead_freight_qty: '',
      currencyData: [],
      billing_currency: config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL" ? "USD" : "IDR",
      pph_account: "0",
      vat_percentage: 10,
      vat_amount: '',
      pph_percentage: 2,
      pph_amount: '',
      vat_applicability: "Yes",
      vat_type: "Exclusive",
      remarks: '',
      files: [],
      invoices: [],
    }
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (!(features.includes("563") || features.includes('566') || features.includes('567') || features.includes("97"))) {
      window.location.href = "/dashboard";
    }
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const business_no_id = this.props.businessNoID;

    let request = {
      login_user_id: loginUserID,
      idtoken: idToken,
      business_no_id: business_no_id
    }
    await api.get_mv_dead_freight_by_bno(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === '200') {
            if (res.mv_dead_freight_data) {
              let paid = 0
              for (var i of res.mv_dead_freight_data?.payments) {
                paid += parseFloat(i.amount);
              }
              this.setState(prevState => ({
                ...prevState,
                ...res.mv_dead_freight_data,
                remaining_amount: (parseFloat(res.mv_dead_freight_data?.payable_to_vendor) - parseFloat(paid)),
                isLoading: false
              }))
            }
          }
        })
      }
    })

  }

  updateInvoice = (data) => {
    let request = {
      ...data,
      table_name: "mv_dead_freight",
    };
    api.update_invoice_details(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.componentDidMount();
            this.setState({
              invoiceDialog: false,
            });
          }
        });
      }
    });
  };

  renderPaymentPostings = (data) => {
    const {
      id,
      payments = [],
      invoice_no = null,
      remaining_amount = 0,
      billing_currency,
      exchange_rate = 1,
      approval_status = "Pending",
    } = data;
    let payment_related_data = {
      paymentTitle: "MV Dead Freight",
      payment_posting_type: "mv_dead_freight",
      invoice_no: invoice_no,
      outstanding_amount: remaining_amount,
      billing_currency: billing_currency
        ? billing_currency
        : config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL"
          ? "USD"
          : "IDR",
      currency_xchg_rate: exchange_rate ? exchange_rate : 1,
      costing_id: id,
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
    };

    let access_details = {
      costing_approval: approval_status,
      add_payment: "568",
      view_payment: "569",
      update_payment: "570",
      delete_payment: "571",
      approver: "572",
      approver2: "573",
    };

    return (
      <PaymentPostings
        access_details={access_details}
        payments={payments}
        costingType="MV Dead Freight"
        data={payment_related_data}
        callbackFn={() => {
          this.componentDidMount();
        }}
      />
    );
  };

  render() {
    return (
      <>
        <div
          className="container bg-white p-0"
          style={{ minHeight: "100vh", width: 850 }}
        >
          <div className="row clearfix content-header border-bottom">
            <div className="col-lg-10 pl-0">
              <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                {`Dead Freight - ${this.state.costing_no}`}
                <span
                  className={
                    this.state.approval_status === "Pending" ? "type_2" : "type_1"
                  }
                  style={{ padding: 8, marginLeft: 16 }}
                >
                  {this.state.approval_status}
                </span>
              </h4>
            </div>
            {this.state.approval_status === "Pending" &&
              this.Cookie.getCookie("loginUserId") !== this.state.created_by &&
              this.state.approved_by !== this.Cookie.getCookie("loginUserId") &&
              (this.state.features.includes("566") ||
                this.state.features.includes("97")) && (
                <BusinessApproveButton
                  costingID={this.state.id}
                  costingType={"MV Dead Freight"}
                  accessInfo={{
                    ...this.state,
                    approver_1: "566",
                    approver_2: "574"
                  }}
                  callbackFn={() => this.componentDidMount()}
                />
              )}
          </div>
          <div className="drawer-section">
            {this.state.isLoading ? <Loader /> :
              <div className="card p-3">
                <div className="row">
                  <div className="row col-lg-12 border_dashed rounded-lg p-2 mb-3">
                    <div className="row col-lg-6 pl-0 mb-2 pr-2">
                      <div className="col-lg-6">
                        <label className="contract_display_header_label">Quantity</label>
                        <label className='contract_display_header_value'>{toLocaleString(this.state.dead_freight_qty)}</label>
                      </div>
                      <div className="col-lg-6">
                        <label className="contract_display_header_label mb-0">
                          Price PMT
                        </label>
                        <label className='contract_display_header_value'>{toLocaleString(this.state.price_pmt)} {this.state.billing_currency}</label>
                      </div>

                      {(((config.company === 'SRPL' || config.company === "PRPL" || config.company === "AVS" ) && this.state.billing_currency !== "USD") || ((config.company !== "SRPL" && config.company !== "PRPL") && this.state.billing_currency !== "IDR")) && (
                        <div className="col-lg-6">
                          <label
                            className="contract_display_header_label mb-0" >
                            Exchange Rate
                          </label>
                          <label className='contract_display_header_value'>{toLocaleString(this.state.exchange_rate)}</label>
                        </div>
                      )}

                      {(config.company !== "SRPL" &&
                        config.company !== "PRPL") && (
                          <div className="col-lg-6">
                            <label className="contract_display_header_label mb-0">
                              PPH Account
                            </label>
                            <label className='contract_display_header_value'>{this.state.pph_account}</label>
                          </div>
                        )}
                      <div className="col-lg-6">
                        <label className="contract_display_header_label mb-0">
                          VAT Applicability
                        </label>
                        <label className='contract_display_header_value'>{this.state.vat_applicability}</label>
                      </div>

                    </div>

                    <div
                      className="col-lg-6 p-1 rounded"
                      style={{ backgroundColor: "#f7f3f0" }}
                    >
                      <div className="row col-lg-12 p-1">
                        <h6>Dead Freight</h6>
                      </div>
                      {(config.company === "SRPL" || config.company === "AVS"  ||
                        config.company === "PRPL" ||
                        this.state.pph_account === "Supplier") && (
                          <div className="row pl-2">
                            <div className="col-lg-12  p-0">
                              <label className="contract_display_header_label mb-0">
                                VAT Type
                              </label>
                              <label className='contract_display_header_value'>{this.state.vat_type}</label>
                            </div>
                            <div className="col-lg-4 p-0 mb-2">
                              <label className="contract_display_header_label mb-0">
                                Base Price
                              </label>
                              <label
                                className="contract_display_header_value"
                                style={{ color: "#42352d" }}
                              >
                                {toLocaleString(this.state.base_price)}
                              </label>

                            </div>
                            <div className="col-lg-4 pr-0 mb-2">
                              <label className="contract_display_header_label mb-0">
                                VAT %
                              </label>
                              <label
                                className="contract_display_header_value"
                                style={{ color: "#42352d" }}
                              >
                                {toLocaleString(this.state.vat_percentage)}
                              </label>

                            </div>
                            <div className="col-lg-4 mb-2 pr-0">
                              <label
                                className="contract_display_header_label"
                                style={{ color: "rgba(72, 59, 52, 0.7)" }}
                              >
                                VAT
                              </label>
                              <label
                                className="contract_display_header_value"
                                style={{ color: "#42352d" }}
                              >
                                {toLocaleString(this.state.vat_amount)}
                              </label>
                            </div>
                            {!(
                              config.company === "SRPL" || config.company === "AVS"  ||
                              config.company === "PRPL"
                            ) && (
                                <div className="col-lg-6 mb-2 pl-0">
                                  <label
                                    className="contract_display_header_label"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    PPH23 %
                                  </label>
                                  <label
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {toLocaleString(this.state.pph_percent)}
                                  </label>

                                </div>
                              )}
                            {!(
                              config.company === "SRPL" || config.company === "AVS"  ||
                              config.company === "PRPL"
                            ) && (
                                <div className="col-lg-6 mb-2 pl-0">
                                  <label
                                    className="contract_display_header_label"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    PPH23 Value
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {toLocaleString(this.state.pph_amount)}
                                  </div>
                                </div>
                              )}
                            <div className="col-lg-6 mb-2 pl-0">
                              <label
                                className="contract_display_header_label"
                                style={{ color: "rgba(72, 59, 52, 0.7)" }}
                              >
                                Payable to Vendor
                              </label>
                              <div
                                className="contract_display_header_value"
                                style={{ color: "#42352d" }}
                              >
                                {toLocaleString(this.state.payable_to_vendor)}
                              </div>
                            </div>
                            <div className="col-lg-6 mb-2 p-0">
                              <label
                                className="contract_display_header_label"
                                style={{ color: "rgba(72, 59, 52, 0.7)" }}
                              >
                                Cost to Company
                              </label>
                              <div
                                className="contract_display_header_value"
                                style={{ color: "#42352d" }}
                              >
                                {toLocaleString(this.state.cost_to_company)}
                                {config.company === "SRPL" || config.company === "AVS"  ||
                                  config.company === "PRPL"
                                  ? " USD"
                                  : " IDR"}
                              </div>
                            </div>
                          </div>
                        )}

                      {this.state.pph_account === "SDAM" && (
                        <div className="row p-2">
                          <div className="col-lg-4 mb-2 p-0">
                            <label
                              className="contract_display_header_label"
                              style={{ color: "rgba(72, 59, 52, 0.7)" }}
                            >
                              Base Price
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ color: "#42352d" }}
                            >
                              {toLocaleString(this.state.base_price)}
                            </div>
                          </div>
                          <div className="col-lg-4 mb-2 pr-0">
                            <label
                              className="contract_display_header_label"
                              style={{ color: "rgba(72, 59, 52, 0.7)" }}
                            >
                              PPH23 %
                            </label>
                            <label
                              className="contract_display_header_value"
                              style={{ color: "#42352d" }}
                            >
                              {toLocaleString(this.state.pph_percentage)}
                            </label>
                          </div>
                          <div className="col-lg-4 mb-2 pr-0">
                            <label
                              className="contract_display_header_label"
                              style={{ color: "rgba(72, 59, 52, 0.7)" }}
                            >
                              PPH23
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ color: "#42352d" }}
                            >
                              {toLocaleString(this.state.pph_amount)}
                            </div>
                          </div>
                          <div className="col-lg-6 mb-2 p-0">
                            <label className="contract_display_header_label mb-0">
                              Payable To Vendor
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ color: "#42352d" }}
                            >
                              {toLocaleString(this.state.payable_to_vendor)}
                            </div>
                          </div>
                          <div className="col-lg-6 mb-2 pr-0">
                            <label
                              className="contract_display_header_label"
                              style={{ color: "rgba(72, 59, 52, 0.7)" }}
                            >
                              Cost to Company
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ color: "#42352d" }}
                            >
                              {toLocaleString(this.state.cost_to_company)}
                              {config.company === "SRPL" || config.company === "AVS"  ||
                                config.company === "PRPL"
                                ? "USD"
                                : "IDR"}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-lg-12 p-0">
                      <label className="contract_display_header_label mb-0">Remarks</label>
                      <label className="contract_display_header_value text-uppercase" style={{ whiteSpace: 'pre-line' }}>{this.state.remarks}</label>
                    </div>
                  </div>
                </div>

                <div className="card p-0 border">
                  <div className="card-header section_header">
                    <h5>Dead Freight Payment Postings</h5>
                  </div>
                  <div className="card-body">
                    <InvoiceForm
                      open={false}
                      data={{
                        invoice_no: this.state.invoice_no,
                        invoice_date: this.state.invoice_date ? this.state.invoice_date : null,
                        ppn: this.state.ppn,
                        ppn_date: this.state.ppn_date ? this.state.ppn_date : null,
                      }}
                      costingID={this.state.id}
                      callbackFn={(data) => this.updateInvoice(data)}
                    />
                    {this.renderPaymentPostings(this.state)}

                    {/* <InvoicesWithPayments
                        patmentsData={this.state.invoices}
                        accessDetails=''
                        paymentsHandler={() => { }}
                        invoicesHandler={() => { }}
                      /> */}
                  </div>
                </div>
                <br />
                <ViewFiles
                  {...this.props} files_data={this.state.files}
                />
              </div>
            }


          </div>
          <footer className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button btn-light"
              name="submit"
              onClick={() => this.props.returnCallbackDrawerClose(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="drawer_text drawer_btn_success"
              name="Back"
              onClick={() => this.props.returnEditCallback(true)}
            >
              Edit
            </button>
          </footer>
        </div>

      </>
    )
  }
}
