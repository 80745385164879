import React, { Component } from "react";

import { Snackbar, Drawer } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import api from "../../../api/api";

// import mother vessel floatig crane
import MvFloatingCraneAdd from "./FloatingCraneChargesAdd";
import MvFloatingCraneView from "./FloatingCraneChargesView";
import MvFloatingCraneUpdate from "./FloatingCraneChargesUpdate";
import Loader from "../../common/Loader";
import { toLocaleString } from "../../common/common";

export default class MvFloatingCraneChargesList extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      openDrawer: false,
      openUpdateDrawer: false,
      status: "",
      // Payment Postings
      stevedor_price_payments: [],
      floating_crane_vendor: [
        {
          floating_crane_vendor_id: "",
          floating_crane_vendor_name: "",
          floating_crane_charges: [
            {
              floating_crane_type: "Floating Crane",
              floating_crane_vendor_id: "",
              floating_crane_vendor_name: "",
              quantity: "",
              billing_currency: "0",
              floating_crane_price_pmt: "",
              currency_exchange_rate: "",
              local_currency: "",
              vat_applicability: "",
              floating_crane_base_price: "",
              floating_crane_vat_percent: 10,
              floating_crane_vat_value: "",
              floating_crane_total: "",
              floating_crane_pph23_percent: 2,
              floating_crane_pph23_value: "",
              floating_crane_payable_to_vendor: "",
              floating_crane_cost_to_company: "",

              floating_crane_price_per_addl_day: "",
              floating_crane_addl_days: "",
              floating_crane_addl_base_price: "",
              floating_crane_addl_vat_percent: 10,
              floating_crane_addl_vat_value: "",
              floating_crane_addl_pph23_percent: 2,
              floating_crane_addl_pph23_value: "",
              floating_crane_addl_total: "",
              floating_crane_addl_payable_to_vendor: "",
              floating_crane_addl_cost_to_company: "",
              pnbp_per_mt: "",
              pnbp: "",
              pnbp_company_to_billing: "",
              jasa_pbm_calculation: "",
              jasa_pbm_pmt: "",
              jasa_pbm_value: "",
              jasa_pbm_company_to_billing: "",
              status: "",
              wait_period_people: "",
              wait_period_hours: "",
              wait_period_price_per_hour: "",
              wait_period_total_price: "",
              others: [],
            },
          ],
        },
      ],
      isLoading: true,
      updateDocuments: false,
      features: [],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    } else {
      const features = this.Cookie.getCookie("features");
      this.setState({ features: features });
      if (
        !(
          features.includes("258") ||
          features.includes("259") ||
          features.includes("260") ||
          features.includes("97")
        )
      ) {
        window.location.href = "/dashboard";
      }
      const loginUserID = this.Cookie.getCookie("loginUserId");
      const idToken = this.Cookie.getIdTokenCookie();
      const businessNoID = this.props.businessNoID;

      await api
        .get_mv_pur_fin_floating_crane_charges(
          loginUserID,
          idToken,
          businessNoID
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                if (res.floating_crane_vendor) {
                  this.setState({
                    floating_crane_vendor: res.floating_crane_vendor,
                    isLoading: false,
                  });
                }
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  snackBarErrorOpen: true,
                });
              }
            });
          }
        });
    }

    document.title =
      config.documentTitle +
      "Mother Vessel Purchase Financial Floating Crane Charges";
  }

  onAddResponse = (value) => {
    if (value.code === "200") {
      this.setState(
        {
          MvFloatingCraneAdd: false,
          MvFloatingCraneView: false,
          MvFloatingCraneUpdate: false,
          openDrawer: false,
          updateDocuments: false,
        },
        () => this.componentDidMount()
      );
    } else {
      this.setState({
        MvFloatingCraneAdd: false,
        MvFloatingCraneView:
          value.screen === "MvFloatingCrane" &&
          value.message === "alreadyDataAvailable"
            ? true
            : false,
        MvFloatingCraneUpdate: false,
        openDrawer: true,
        updateDocuments: false,
      });
    }
  };

  CallbackDrawerClose = (childData) => {
    this.setState(
      {
        openDrawer: childData,
        MvFloatingCraneAdd: false,
        MvFloatingCraneView: false,
        MvFloatingCraneUpdate: false,
        updateDocuments: false,
      },
      () => this.componentDidMount()
    );
  };

  onUpdateResponse = (value) => {
    if (value) {
      this.setState(
        {
          MvFloatingCraneAdd: false,
          MvFloatingCraneView:
            value.code === "200" && value.screen === "MvFloatingCrane"
              ? true
              : false,
          MvFloatingCraneUpdate: false,
          updateDocuments: false,
        },
        () => this.componentDidMount()
      );
    }
  };

  CallbackViewPage = (childData) => {
    this.setState(
      {
        openDrawer: true,
        MvFloatingCraneUpdate: false,
        MvFloatingCraneAdd: false,
        MvFloatingCraneView: childData === "MvFloatingCrane" ? true : false,
        updateDocuments: false,
      },
      () => this.componentDidMount()
    );
  };

  onEdit = (childData) => {
    this.setState(
      {
        openDrawer: true,
        MvFloatingCraneUpdate: true,
        MvFloatingCraneAdd: false,
        MvFloatingCraneView: false,
        updateDocuments: false,
      },
      () => this.componentDidMount()
    );
  };

  deleteCharges = (data) => {
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    var finalData = data;
    finalData.floating_crane_id = data.id;
    finalData.login_user_id = loginUserID;
    finalData.idtoken = idToken;
    finalData.status = "Deleted";
    //delete floating crane charge
    api.delete_mv_floating_crane_charges(finalData).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.setState(
              {
                snackBarSuccessOpen: true,
                successMsg: res.message,
              },
              () => this.componentDidMount()
            );
          } else if (res.code === "601") {
            this.setState({ errorMsg: res.message, snackBarErrorOpen: true });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          } else if (res.code === "624") {
            this.setState({
              errorMsg: res.columns.length > 0 ? res.columns[0] : "",
              snackBarErrorOpen: true,
            });
          }
        });
      }
    });
  };

  render() {
    let drawerComponent;
    if (this.state.MvFloatingCraneAdd) {
      drawerComponent = (
        <MvFloatingCraneAdd
          businessNoID={this.props.businessNoID}
          onAddResponse={this.onAddResponse}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
        />
      );
    } else if (this.state.MvFloatingCraneView && this.state.businessNo) {
      drawerComponent = (
        <MvFloatingCraneView
          floatingCraneID={this.state.floatingCraneID}
          businessNoID={this.state.businessNo}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          onEdit={this.onEdit}
          returnDocumentsHandler={() => {
            this.setState({
              showDrawer: true,
              MvFloatingCraneUpdate: true,
              MvFloatingCraneView: false,
              MvFloatingCraneAdd: false,
              updateDocuments: true,
            });
          }}
        />
      );
    } else if (this.state.MvFloatingCraneUpdate && this.state.businessNo) {
      drawerComponent = (
        <MvFloatingCraneUpdate
          floatingCraneID={this.state.floatingCraneID}
          businessNoID={this.state.businessNo}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.CallbackViewPage}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div>
          <div className="row clearfix content-header border-bottom">
            <div className="row clearfix content-header border-bottom">
              <h4 className="mt-2" style={{ padding: "4px" }}>
                {` Floating Crane Charges - ${
                  this.props.mother_vessel_name
                    ? this.props.mother_vessel_name
                    : ""
                } ${this.props.name ? `( ${this.props.name} )` : ""}`}
              </h4>
              {(this.state.features.includes("258") ||
                this.state.features.includes("97")) && (
                <i
                  className="fa fa-plus-circle ml-2 mt-3"
                  aria-hidden="true"
                  style={{
                    fontSize: 22,
                    color: config.themeColor,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    this.setState({
                      openDrawer: true,
                      MvFloatingCraneAdd: true,
                      MvFloatingCraneView: false,
                      MvFloatingCraneUpdate: false,
                    });
                  }}
                />
              )}
            </div>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <Drawer
            anchor="right"
            open={this.state.openDrawer}
            variant="temporary"
            elevation={20}
            style={{ overflow: "initial" }}
          >
            <div className="row" style={{ width: 800 }}>
              {drawerComponent}
            </div>
          </Drawer>
          <div className="card pl-0 pr-0">
            <div
              className="card-body p-0"
              style={{ backgroundColor: "rgba(25,118,210,.01)" }}
              id="accordion"
            >
              <div className="col-lg-12 mb-2 p-0">
                <div className="table-responsive">
                  <table className="table table-bordered table-sm">
                    <thead className="table-header">
                      <tr>
                        <th nowrap="true"> Costing ID </th>
                        <th nowrap="true"> Vendor Name </th>
                        <th nowrap="true"> Type </th>
                        <th nowrap="true"> Quantity in MT </th>
                        <th nowrap="true"> Floating Crane Price </th>
                        <th nowrap="true"> Floating Crane Additional Price </th>
                        {/* <th nowrap="true"> PNBP </th>
                        <th nowrap="true"> JASA PBM </th> */}
                        <th nowrap="true"> Waiting Period </th>
                        <th nowrap="true"> Action </th>
                      </tr>
                    </thead>
                    {this.state.floating_crane_vendor.length > 0 ? (
                      <tbody>
                        {this.state.floating_crane_vendor.map((idr, idx) => (
                          <tr key={idx}>
                            <td className="allocation_table_value">
                              {(this.state.features.includes("259") ||
                                this.state.features.includes("97")) && (
                                <i
                                  aria-hidden="true"
                                  style={{
                                    fontStyle: "normal",
                                    color: config.themeColor,
                                    cursor: "pointer",
                                    whiteSpace: "nowrap",
                                  }}
                                  onClick={() =>
                                    this.setState({
                                      openDrawer: true,
                                      MvFloatingCraneAdd: false,
                                      MvFloatingCraneView: true,
                                      MvFloatingCraneUpdate: false,
                                      businessNo: idr.business_no_id,
                                      floatingCraneID: idr.id,
                                    })
                                  }
                                >
                                  {idr.costing_no}
                                </i>
                              )}
                            </td>
                            <td nowrap="true">
                              {idr.floating_crane_vendor_name}{" "}
                            </td>
                            <td nowrap="true">{idr.floating_crane_type}</td>
                            <td className="text-right" nowrap="true">
                              {toLocaleString(idr.quantity)}
                            </td>
                            <td className="text-right" nowrap="true">
                              {`${toLocaleString(
                                idr.floating_crane_payable_to_vendor
                              )} ${
                                config.company === "SRPL" ||
                                config.company === "AVS" ||
                                config.company === "PRPL"
                                  ? "USD"
                                  : "IDR"
                              }`}
                            </td>
                            <td className="text-right" nowrap="true">
                              {`${toLocaleString(
                                idr.floating_crane_addl_payable_to_vendor
                              )}
                              ${
                                config.company === "SRPL" ||
                                config.company === "AVS" ||
                                config.company === "PRPL"
                                  ? "USD"
                                  : "IDR"
                              }`}
                            </td>
                            {/* <td className="text-right" nowrap="true">
                              {`${toLocaleString(idr.pnbp)}
                              ${
                                config.company === "SRPL" ||
                                config.company === "AVS" ||
                                config.company === "PRPL"
                                  ? "USD"
                                  : "IDR"
                              }
                              ${
                                idr.pnbp_company_to_billing
                                  ? "(" +
                                    toLocaleString(
                                      idr.pnbp_company_to_billing
                                    ) +
                                    " " +
                                    idr.billing_currency +
                                    ")"
                                  : ""
                              }`}
                            </td>
                            <td className="text-right" nowrap="true">
                              {`${toLocaleString(idr.jasa_pbm_value)}
                              ${
                                idr.billing_currency !== "0"
                                  ? idr.billing_currency
                                  : ""
                              }`}
                            </td> */}
                            <td className="text-right" nowrap="true">
                              {`${toLocaleString(idr.wait_period_total_price)}
                              ${
                                config.company === "SRPL" ||
                                config.company === "AVS" ||
                                config.company === "PRPL"
                                  ? "USD"
                                  : "IDR"
                              }`}
                            </td>
                            <td
                              nowrap="true"
                              className="table_td text-left pl-4"
                            >
                              {(this.state.features.includes("260") ||
                                this.state.features.includes("97")) && (
                                <i
                                  className="fa fa-pencil"
                                  style={{
                                    fontSize: 14,
                                    color: config.themeColor,
                                    cursor: "pointer",
                                  }}
                                  data-toggle="modal"
                                  title="Edit floating crane charges"
                                  data-placement="bottom"
                                  onClick={() =>
                                    this.setState({
                                      businessNo: idr.business_no_id,
                                      floatingCraneID: idr.id,
                                      openDrawer: true,
                                      MvFloatingCraneUpdate: true,
                                    })
                                  }
                                ></i>
                              )}
                              {idr.payment_postings_availability === 0 &&
                                (this.state.features.includes("261") ||
                                  this.state.features.includes("97")) &&
                                idr.approval_status === "Pending" && (
                                  <i
                                    className="fa fa-trash-o"
                                    style={{
                                      paddingLeft: 10,
                                      fontSize: 14,
                                      color: config.themeColor,
                                      cursor: "pointer",
                                    }}
                                    onClick={() => this.deleteCharges(idr)}
                                  ></i>
                                )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td colSpan="8" className="p-2">
                            No data available
                          </td>
                        </tr>{" "}
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
