import React, { Component } from "react";
import api from "../../../api/api";
import CookieHandler from "../../common/CookieHandler";
import BargeCostingAdd from "./BargeCostingAdd";
import BargeCostingView from "./BargeCostingView";
import BargeCostingUpdate from "./BargeCostingUpdate";
import Loader from "../../common/Loader";
import config from "../../../config/config";
import {
  TextField,
  Snackbar,
  MenuItem,
  Checkbox,
  Drawer,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DeleteConfirmationModal from "../../common/DeleteConfirmation";
import { pad, toLocaleString } from "../../common/common";

export default class BargeCostingList extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      barge_costing_list: [],
      barge_vendor: "0",
      barge_nomination: "",
      jetty_name: "",
      isLoading: true,
      business_no_drop_value: [],
      business_no_id: [],
      features: [],
      barge_drop_value: [],
      barge_ids: [],
      showDrawer: false,
      BargeCostingAdd: false,
      BargeCostingView: false,
      BargeCostingUpdate: false,
      bargeCostingID: null,
      updateDocuments: false,
      deleteCosting: false,
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    } else {
      var features = this.Cookie.getCookie("features");
      this.setState({ features: features ? features : [] }, () => {
        if (
          !(
            this.state.features.includes("135") ||
            this.state.features.includes("97")
          )
        ) {
          window.location.href = "/dashboard";
        }
      });
      let loginUserID = this.Cookie.getCookie("loginUserId");
      let idToken = this.Cookie.getIdTokenCookie();
      api.getBusinessNumbers(loginUserID, idToken).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
              if (res.business_numbers) {
                let business_no_drop_value = [];
                for (var i of res.business_numbers) {
                  business_no_drop_value.push({
                    name: i.business_no,
                    value: i.id,
                    mother_vessel_name: i.mother_vessel_name ? i.mother_vessel_name : i.business_no,
                  });
                }
                this.setState({
                  business_no_drop_value: business_no_drop_value,
                  //loading: false,
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
      let financial_type = "barges";
      const get_barge_list = await api.get_barges(
        loginUserID,
        idToken,
        financial_type
      );
      if (get_barge_list.status >= 200 && get_barge_list.status < 300) {
        get_barge_list.json().then((res) => {
          if (res.code === "200") {
            if (res.barges) {
              const total_barges = [];
              for (var i of res.barges) {
                total_barges.push({ name: i.id, value: i.id, key: i.id });
              }
              this.setState({
                barge_drop_value: total_barges,
              });
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
      //Get vendors
      let status = "Active";
      const vendors = await api.getVendors(loginUserID, idToken, status);
      if (vendors.status >= 200 && vendors.status < 300) {
        vendors.json().then((res) => {
          if (res.code === "200") {
            if (res.vendors) {
              const barge_vendors = [
                <MenuItem value={0} key={0} disabled>
                  Please Select
                </MenuItem>,
              ];
              for (var i of res.vendors) {
                if (i.vendor_type === "Barge") {
                  barge_vendors.push(
                    <MenuItem value={i.id} key={i.id}>
                      {i.vendor_name}
                    </MenuItem>
                  );
                }
              }
              this.setState({
                barge_surveyors: barge_vendors,
                isLoading: false,
              });
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    }
  }

  searchBargeHandler() {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    let business_no = this.state.business_no_id;
    let barge_vendor =
      this.state.barge_vendor === "0" ? "" : this.state.barge_vendor;
    let barge_name = this.state.barge_nomination;
    let jetty_name = this.state.jetty_name;
    let barge_ids = this.state.barge_ids;
    // if (barge_vendor === '0') {
    //   this.setState({
    //     barge_vendor_error: true,
    //     snackBarErrorOpen: true,
    //     errorMsg: "Please fill mandatory fields"
    //   });
    //   return;
    // }
    api
      .get_barges_for_pur_fin_barging(
        loginUserID,
        idToken,
        business_no,
        barge_vendor,
        barge_name,
        jetty_name,
        barge_ids
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.barges) {
                let newData = [];
                var result = res.barges.reduce(function (r, a) {
                  r[a.id] = r[a.id] || [];
                  r[a.id].push(a);
                  return r;
                }, Object.create(null));
                for (let l = 0; l < Object.keys(result).length; l++) {
                  newData.push({ new_barges: result[Object.keys(result)[l]] });
                }
                let purchase_contracts_barges = newData;
                if (newData.length === 0) {
                  this.setState({
                    snackBarErrorOpen: true,
                    errorMsg: "No records to display",
                  });
                }
                this.setState({
                  barge_costing_list: purchase_contracts_barges.reverse(),
                  isLoading: false,
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  }

  deleteBargePurchaseFinancial = (data) => {
    this.setState({ isLoading: true });
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    var barge_pur_fin_id = data.id;
    api
      .delete_barge_pur_fin_barging(loginUserID, idToken, barge_pur_fin_id)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState({
                deleteCosting: false,
                successMsg: res.message,
                errorMsg: "",
                isLoading: false,
              });
              this.searchBargeHandler();
              window.$("#delete_confirmation").modal("hide");
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
                isLoading: false,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            } else if (res.code === "624") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
                isLoading: false,
              });
            } else if (res.code === "650") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg:
                  "Payments are available for this record. Please delete Payments before deleting this record.",
                deleteAlertMsg:
                  "Payments are available for this record. Please delete Payments before deleting this record.",
                // successMsg: "",
                // errorMsg: res.message,
                isLoading: false,
              });
              window.$("#delete_confirmation").modal("hide");
            }
          });
        }
      });
  };

  onUpdateResponse = (value) => {
    if (value === "200") {
      this.setState({
        BargeCostingUpdate: false,
        BargeCostingAdd: false,
        BargeCostingView: true,
      });
    }
  };

  onAddResponse = (value) => {
    if (value === "200") {
      this.setState({
        BargeCostingAdd: false,
        BargeCostingView: false,
        showDrawer: false,
      });
    }
  };

  onCancel = (value) => {
    if (value === false) {
      this.setState({
        BargeCostingUpdate: false,
        BargeCostingAdd: false,
        BargeCostingView: false,
        showDrawer: false,
        bargeCostingID: null,
      });
    }
  };

  addDocuments = () => {
    this.setState({
      showDrawer: true,
      BargeCostingUpdate: true,
      BargeCostingView: false,
      BargeCostingAdd: false,
      updateDocuments: true,
    });
  };

  onEdit = (value) => {
    if (value === true) {
      this.setState({
        BargeCostingUpdate: true,
        showDrawer: true,
        BargeCostingAdd: false,
        BargeCostingView: false,
        updateDocuments: false,
      });
    }
  };

  render() {
    let drawerComponent;
    if (this.state.BargeCostingAdd) {
      drawerComponent = (
        <BargeCostingAdd
          onAddResponse={this.onAddResponse}
          onCancel={this.onCancel}
        />
      );
    } else if (
      this.state.BargeCostingView &&
      this.state.bargeCostingID !== null
    ) {
      drawerComponent = (
        <BargeCostingView
          bargeCostingID={this.state.bargeCostingID}
          returnDocumentsHandler={this.addDocuments}
          onCancel={this.onCancel}
          onEdit={this.onEdit}
        />
      );
    } else if (
      this.state.BargeCostingUpdate &&
      this.state.bargeCostingID !== null
    ) {
      drawerComponent = (
        <BargeCostingUpdate
          bargeCostingID={this.state.bargeCostingID}
          onUpdateCosting={this.onUpdateResponse}
          onCancel={this.onCancel}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }
    return (
      <>
        {this.state.isLoading && <Loader />}
        {!this.state.isLoading && (
          <div className="card">
            <Drawer
              anchor="right"
              open={this.state.showDrawer}
              variant="temporary"
              elevation={20}
              style={{ overflow: "initial" }}
            >
              <div className="row" style={{ width: 800 }}>
                {drawerComponent}
              </div>
            </Drawer>
            <div className="row mb-1 p-2 ">
              <div className="col-lg-3 p-0">
                <Autocomplete
                  multiple
                  // options={this.state.business_no_drop_value.map(
                  //   (option) => option.name + "," + option.mother_vessel_name
                  // )}
                  options={this.state.business_no_drop_value}
                  getOptionLabel={(option) => option.mother_vessel_name}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox style={{ marginRight: 8 }} checked={selected} />
                      <div className="col-lg p-0 m-0">
                        <label className="form_label mb-0">
                          {/* {option.name} */}
                          {option.mother_vessel_name}
                        </label>
                        <label
                          className="contract_display_header_value m-0 pl-0"
                          style={{ fontSize: 10, paddingLeft: 5 }}
                        >
                          {"( " + option.name + " )"}
                        </label>
                      </div>
                    </React.Fragment>
                  )}
                  onChange={(event, value) => {
                    if (value) {
                      var data = [];
                      for (var i of value) {
                        data.push(i.value);
                      }
                      //var floating_crane_vendor = data.toString();
                      this.setState({ business_no_id: data });
                    } else {
                      this.setState({ business_no_id: [] });
                    }
                  }}
                  disableCloseOnSelect
                  name="Business_Number"
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Business No"
                      label="Business No"
                      variant="standard"
                      style={{ top: 8 }}
                      fullWidth
                    />
                  )}
                  style={{ display: "contents" }}
                />
              </div>
              <div className="col-lg-3 pr-0">
                <TextField
                  name="barge_vendor"
                  margin="dense"
                  variant="standard"
                  value={this.state.barge_vendor}
                  fullWidth
                  label="Barge Vendor"
                  InputProps={{
                    style: {
                      fontFamily: "Poppins",
                      fontSize: "14px",
                    },
                  }}
                  select
                  error={this.state.barge_vendor_error}
                  onChange={(e) => {
                    this.setState({
                      barge_vendor: e.target.value,
                      barge_vendor_error: false,
                    });
                  }}
                >
                  {this.state.barge_surveyors}
                </TextField>
              </div>
              <div className="col-lg-3 pt-1 mt-2">
                <TextField
                  name="barge_name"
                  margin="dense"
                  variant="standard"
                  placeholder="Barge Name"
                  fullWidth
                  onChange={(e) => {
                    this.setState({
                      barge_nomination: e.target.value,
                      barge_name_error: false,
                    });
                  }}
                  error={this.state.barge_name_error}
                  inputProps={{
                    style: {
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      textTransform: "uppercase",
                    },
                  }}
                />
              </div>
              <div className="col-lg-3 pr-0 pt-1 mt-2">
                <Autocomplete
                  multiple
                  options={this.state.barge_drop_value}
                  getOptionLabel={(option) => pad(option.name)}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox style={{ marginRight: 8 }} checked={selected} />
                      <div className="col-lg p-0 m-0">
                        <span className="form_label mb-0">
                          {pad(option.name)}
                        </span>
                      </div>
                    </React.Fragment>
                  )}
                  onChange={(event, value) => {
                    if (value) {
                      var data = [];
                      for (var i of value) {
                        data.push(i.value);
                      }
                      this.setState({ barge_ids: data });
                    } else {
                      this.setState({ barge_ids: [] });
                    }
                  }}
                  //disableCloseOnSelect
                  name="barge_id"
                  size="small"
                  //value={this.state.barge_value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Search Barge"
                      variant="standard"
                      style={{ top: 8 }}
                      fullWidth
                    />
                  )}
                  style={{ display: "contents" }}
                />
              </div>
              <div className="col-lg-2 p-0 pt-2">
                <button
                  onClick={() => this.searchBargeHandler()}
                  className="header_button header_button_text add_button_adjustment"
                  style={{ width: "auto", marginTop: "15px" }}
                >
                  Load
                </button>
              </div>
              {(this.state.features.includes("134") ||
                this.state.features.includes("97")) && (
                <div className="col text-right float-sm-left">
                  <button
                    className="header_button header_button_text add_button_adjustment"
                    style={{ width: "auto", marginTop: "25px" }}
                    onClick={() =>
                      this.setState({ BargeCostingAdd: true, showDrawer: true })
                    }
                  >
                    New
                  </button>
                </div>
              )}
            </div>

            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={this.state.snackBarErrorOpen}
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              style={{ width: "450px" }}
            >
              <Alert
                elevation={6}
                variant="filled"
                onClose={() => this.setState({ snackBarErrorOpen: false })}
                severity="error"
                style={{ width: "100%" }}
              >
                {this.state.errorMsg}
              </Alert>
            </Snackbar>
            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              style={{ width: "450px" }}
              open={this.state.snackBarSuccessOpen}
              autoHideDuration={100000}
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
            >
              <Alert
                elevation={6}
                variant="filled"
                onClose={() => this.setState({ snackBarSuccessOpen: false })}
                severity="success"
                style={{ width: "100%" }}
              >
                {this.state.sucessMsg}
              </Alert>
            </Snackbar>

            {this.state.barge_costing_list.length > 0 && (
              <div className="row">
                <div className="table-responsive">
                  <table className="table table-bordered table-sm mb-0">
                    <thead className="table-header">
                      <tr>
                        <th nowrap="true"></th>
                        <th nowrap="true" className=" text-uppercase">
                          Costing ID
                        </th>
                        <th nowrap="true" className=" text-uppercase">
                          Barge ID
                        </th>
                        <th nowrap="true" className=" text-uppercase ">
                          Barge Nomination
                        </th>
                        <th nowrap="true" className=" text-uppercase ">
                          Business No
                        </th>
                        <th nowrap="true" className=" text-uppercase ">
                          Barge Vendor
                        </th>
                        <th nowrap="true" className=" text-uppercase ">
                          Jetty
                        </th>
                        <th nowrap="true" className=" text-uppercase ">
                          Supplier
                        </th>
                        <th nowrap="true" className=" text-uppercase ">
                          Grade
                        </th>
                        <th nowrap="true" className=" text-uppercase ">
                          Quantity
                        </th>
                        <th
                          nowrap="true"
                          className=" text-uppercase  text-right"
                        >
                          Price PMT
                        </th>
                        <th
                          nowrap="true"
                          className=" text-uppercase  text-right"
                        >
                          Total Payable
                        </th>
                        <th
                          nowrap="true"
                          className=" text-uppercase  text-right"
                        >
                          Paid
                        </th>
                        <th
                          nowrap="true"
                          className=" text-uppercase  text-right"
                        >
                          Remaining
                        </th>
                      </tr>
                    </thead>
                    <tbody style={{ height: 500, overflowY: "auto" }}>
                      {this.state.barge_costing_list.map((p, index) =>
                        p.new_barges.map((idr, indx) => (
                          <tr key={indx} className="">
                            {indx === 0 && (
                              <td
                                className="text-center"
                                nowrap="true"
                                rowSpan={p.new_barges.length}
                                // style={{ borderRight: "1px solid #dee2e6" }}
                              >
                                <i
                                  className="fa fa-eye mr-2"
                                  aria-hidden="true"
                                  style={{
                                    fontSize: 22,
                                    color: config.themeColor,
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    this.setState({
                                      BargeCostingView: true,
                                      bargeCostingID: idr.id,
                                      showDrawer: true,
                                    })
                                  }
                                ></i>
                                {(this.state.features.includes("137") ||
                                  this.state.features.includes("97")) &&
                                  idr.approval_status === "Pending" && (
                                    <i
                                      className="fa fa-trash-o"
                                      aria-hidden="true"
                                      style={{
                                        fontSize: 21,
                                        color: config.themeColor,
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        this.setState({
                                          deleteCosting: true,
                                          barge_pur_fin_id: idr.id,
                                          deleteData: idr,
                                        })
                                      }
                                    ></i>
                                  )}
                              </td>
                            )}
                            {indx === 0 && (
                              <td
                                className=" text-left"
                                rowSpan={p.new_barges.length}
                              >
                                {idr.costing_no}
                              </td>
                            )}
                            <td>{pad(idr.barge_id)}</td>
                            <td nowrap="true">{idr.barge_nomination}</td>
                            <td nowrap="true">{idr.business_no}</td>
                            <td nowrap="true">{idr.barge_vendor}</td>
                            <td nowrap="true">{idr.loading_jetty}</td>
                            <td nowrap="true">{idr.supplier_name}</td>
                            <td nowrap="true">{idr.grade}</td>
                            {indx === 0 && (
                              <td
                                className="text-right"
                                nowrap="true"
                                rowSpan={p.new_barges.length}
                              >
                                {toLocaleString(idr.barge_quantity)}
                              </td>
                            )}
                            {indx === 0 && (
                              <td
                                className="text-right"
                                nowrap="true"
                                rowSpan={p.new_barges.length}
                              >
                                {toLocaleString(idr.price_per_mt)}
                              </td>
                            )}
                            {indx === 0 && (
                              <td
                                className="text-right"
                                nowrap="true"
                                rowSpan={p.new_barges.length}
                              >
                                {toLocaleString(idr.total_payable)}
                              </td>
                            )}
                            {indx === 0 && (
                              <td
                                className="text-right"
                                nowrap="true"
                                rowSpan={p.new_barges.length}
                              >
                                {toLocaleString(idr.paid)}
                              </td>
                            )}
                            {indx === 0 && (
                              <td
                                className="text-right"
                                nowrap="true"
                                rowSpan={p.new_barges.length}
                              >
                                {toLocaleString(idr.Remaining_amount)}
                              </td>
                            )}
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        )}

        {this.state.deleteCosting && (
          <DeleteConfirmationModal
            open={this.state.deleteCosting}
            close={() => this.setState({ deleteCosting: false })}
            callbackFn={() =>
              this.deleteBargePurchaseFinancial(this.state.deleteData)
            }
          />
        )}
      </>
    );
  }
}
