import React, { Component } from "react";

import { Snackbar, Drawer } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import api from "../../../api/api";

// import mother vessel Coasl Procurement Charges
import MVProcurementChargesAdd from "./MvProcurementChargesAdd";
import MVProcurementChargesView from "./MvProcurementChargesView";
import MVProcurementChargesUpdate from "./MvProcurementChargesUpdate";
import Loader from "../../common/Loader";
import { toLocaleString } from "../../common/common";
export default class MVProcurementChargesList extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      MVProcurementCharges: [],
      isLoading: true,
      stevedor_price_payments: [],
      features: [],
      closeDialog: false,
      deletePaymentDialog: false,
      updateDocuments: false,
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (!(features.includes("235") || features.includes("97"))) {
      window.location.href = "/dashboard";
    }
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const businessNoID = this.props.businessNoID;
    //Get businessNumber API based on businessNumber
    await api
      .getBusinessNumber(businessNoID, loginUserID, idToken)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.business_number) {
                var total_delivered_quantity = 0;
                for (var i of res.business_number.business_number_allocation) {
                  total_delivered_quantity += Number(i.bna_quantity_delivered);
                }
                this.setState({
                  businessNo: res.business_number.business_no
                    ? res.business_number.business_no
                    : "",
                  mother_vessel_name: res.business_number.mother_vessel_name
                    ? res.business_number.mother_vessel_name
                    : "",
                  quantity_in_mt: total_delivered_quantity,
                });
              }
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                successMsg: "",
                closeDialog: true,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });

    await api
      .get_mv_purchase_financials_cpc(loginUserID, idToken, businessNoID)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              var paid_to_vendor = 0;
              var remaining_amount = 0;
              for (var i = 0; i < res.mv_pur_fin_cpc.length; i++) {
                if (res.mv_pur_fin_cpc[i].cpc_payments.length > 0) {
                  for (var j of res.mv_pur_fin_cpc[i].cpc_payments) {
                    paid_to_vendor += Number(j.amount);
                    remaining_amount =
                      Number(res.mv_pur_fin_cpc[i].payable_to_vendor) -
                      Number(paid_to_vendor);
                  }
                  res.mv_pur_fin_cpc[i].paid_to_vendor = paid_to_vendor;
                  res.mv_pur_fin_cpc[i].remaining_amount = remaining_amount;
                  paid_to_vendor = 0;
                  remaining_amount = 0;
                } else {
                  res.mv_pur_fin_cpc[i].remaining_amount = Number(
                    res.mv_pur_fin_cpc[i].payable_to_vendor
                  );
                }
              }
              if (res.mv_pur_fin_cpc.length > 0) {
                this.setState({
                  MVProcurementCharges: res.mv_pur_fin_cpc,
                  isLoading: false,
                });
              } else if (res.mv_pur_fin_cpc.length === 0) {
                // this.props.response(res.mv_pur_fin_cpc.length);
                this.setState({
                  isLoading: false,
                  errorMsg: "No Purchase financials data available.",
                  successMsg: "",
                  closeDialog: true,
                });
              }
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                successMsg: "",
                closeDialog: true,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            } else if (res.code === "624") {
              this.setState({
                isLoading: false,
                errorMsg: res.columns.length > 0 ? res.columns[0] : "",
                successMsg: "",
                closeDialog: true,
              });
            }
          });
        }
      });
  }

  onAddResponse = (value) => {
    if (value.code === "200") {
      this.setState(
        {
          MVProcurementChargesAdd: false,
          MVProcurementChargesView: false,
          MVProcurementChargesUpdate: false,
          openDrawer: false,
          updateDocuments: false,
        },
        () => this.componentDidMount()
      );
    } else {
      this.setState({
        MVProcurementChargesAdd: false,
        MVProcurementChargesView:
          value.screen === "MvCpc" && value.message === "alreadyDataAvailable"
            ? true
            : false,
        MVProcurementChargesUpdate: false,
        openDrawer: true,
        updateDocuments: false,
      });
    }
  };

  CallbackDrawerClose = (childData) => {
    this.setState(
      {
        openDrawer: childData,
        MVProcurementChargesAdd: false,
        MVProcurementChargesView: false,
        MVProcurementChargesUpdate: false,
        updateDocuments: false,
      },
      () => this.componentDidMount()
    );
  };

  onUpdateResponse = (value) => {
    if (value) {
      this.setState(
        {
          MVProcurementChargesAdd: false,
          MVProcurementChargesView:
            value.code === "200" && value.screen === "MvCpc" ? true : false,
          MVProcurementChargesUpdate: false,
          updateDocuments: false,
        },
        () => this.componentDidMount()
      );
    }
  };

  CallbackViewPage = (childData) => {
    this.setState(
      {
        openDrawer: true,
        MVProcurementChargesUpdate: false,
        MVProcurementChargesAdd: false,
        MVProcurementChargesView: childData === "MvCpc" ? true : false,
        updateDocuments: false,
      },
      () => this.componentDidMount()
    );
  };

  editCallback = (childData) => {
    this.setState(
      {
        openDrawer: true,
        MVProcurementChargesUpdate: childData === "MvCpc" ? true : false,
        MVProcurementChargesAdd: false,
        MVProcurementChargesView: false,
        updateDocuments: false,
      },
      () => this.componentDidMount()
    );
  };

  deleteCharges = (data) => {
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    var finalData = data;
    finalData.mv_cpc_id = data.id;
    finalData.login_user_id = loginUserID;
    finalData.idtoken = idToken;
    finalData.status = "Deleted";
    //Delete Stevedore costing charge
    api.update_mv_pur_fin_cpc(finalData).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.setState(
              {
                snackBarSuccessOpen: true,
                successMsg: res.message,
              },
              () => this.componentDidMount()
            );
          } else if (res.code === "601") {
            this.setState({ errorMsg: res.message, snackBarErrorOpen: true });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          } else if (res.code === "624") {
            this.setState({
              errorMsg: res.columns.length > 0 ? res.columns[0] : "",
              snackBarErrorOpen: true,
            });
          }
        });
      }
    });
  };

  render() {
    let drawerComponent;
    if (this.state.MVProcurementChargesAdd) {
      drawerComponent = (
        <MVProcurementChargesAdd
          businessNoID={this.props.businessNoID}
          onAddResponse={this.onAddResponse}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
        />
      );
    } else if (this.state.MVProcurementChargesView && this.state.businessNo) {
      drawerComponent = (
        <MVProcurementChargesView
          coalProcurementChargesID={this.state.coalProcurementChargesID}
          businessNoID={this.state.businessNo}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.editCallback}
          returnDocumentsHandler={() => {
            this.setState({
              showDrawer: true,
              MVProcurementChargesUpdate: true,
              MVProcurementChargesView: false,
              MVProcurementChargesAdd: false,
              updateDocuments: true,
            });
          }}
        />
      );
    } else if (this.state.MVProcurementChargesUpdate && this.state.businessNo) {
      drawerComponent = (
        <MVProcurementChargesUpdate
          coalProcurementChargesID={this.state.coalProcurementChargesID}
          businessNoID={this.state.businessNo}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.CallbackViewPage}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div>
          <div className="row clearfix content-header border-bottom">
            <div className="row clearfix content-header border-bottom">
              <h4 className="mt-2" style={{ padding: "4px" }}>
                Coal Procurement Charges - {this.state.mother_vessel_name} {"(" + this.state.businessNo + ")"}
              </h4>
              {(this.state.features.includes("234") ||
                this.state.features.includes("97")) && (
                <i
                  className="fa fa-plus-circle ml-2 mt-3"
                  aria-hidden="true"
                  style={{
                    fontSize: 22,
                    color: config.themeColor,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    this.setState({
                      openDrawer: true,
                      MVProcurementChargesAdd: true,
                      MVProcurementChargesView: false,
                      MVProcurementChargesUpdate: false,
                    });
                  }}
                />
              )}
            </div>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <Drawer
            anchor="right"
            open={this.state.openDrawer}
            variant="temporary"
            elevation={20}
            style={{ overflow: "initial" }}
          >
            <div className="row" style={{ width: 800 }}>
              {drawerComponent}
            </div>
          </Drawer>
          <div className="card pl-0 pr-0">
            <div
              className="card-body p-0"
              style={{ backgroundColor: "rgba(25,118,210,.01)" }}
              id="accordion"
            >
              <div className="col-lg-12 mb-2 p-0">
                <div className="row">
                  <div className="table-responsive">
                    <table className="table table-sm table-bordered">
                      <thead className="table-header">
                        <tr>
                          <th nowrap="true">Costing ID</th>
                          <th nowrap="true"> Payment to </th>
                          <th nowrap="true">Quantity in MT </th>
                          <th nowrap="true"> Base Price PMT</th>
                          <th nowrap="true"> Payable to Vendor </th>
                          <th nowrap="true"> Action </th>
                        </tr>
                      </thead>
                      {this.state.MVProcurementCharges.length > 0 ? (
                        <tbody>
                          {this.state.MVProcurementCharges.map((idr, indx) => (
                            <tr key={indx}>
                              <td className="allocation_table_value">
                                {(this.state.features.includes("235") ||
                                  this.state.features.includes("97")) && (
                                  <i
                                    aria-hidden="true"
                                    style={{
                                      fontStyle: "normal",
                                      color: config.themeColor,
                                      cursor: "pointer",
                                      whiteSpace: "nowrap",
                                    }}
                                    onClick={() =>
                                      this.setState({
                                        openDrawer: true,
                                        MVProcurementChargesAdd: false,
                                        MVProcurementChargesView: true,
                                        MVProcurementChargesUpdate: false,
                                        businessNo: idr.business_no_id,
                                        coalProcurementChargesID: idr.id,
                                      })
                                    }
                                  >
                                    {idr.costing_no}
                                  </i>
                                )}
                              </td>
                              <td
                                className="allocation_table_value"
                                nowrap="true"
                              >
                                {idr.payment_to_vendor_name !== null
                                  ? idr.payment_to_vendor_name
                                  : idr.payee_name}
                              </td>
                              <td
                                className="allocation_table_value text-right"
                                nowrap="true"
                              >
                                {idr.barge_quantity
                                  ? toLocaleString(idr.barge_quantity)
                                  : 0}
                              </td>
                              <td
                                className="allocation_table_value text-right"
                                nowrap="true"
                              >
                                {idr.base_price_pmt
                                  ? toLocaleString(idr.base_price_pmt)
                                  : 0}{" "}
                                {config.company === "SRPL" || config.company === "AVS"  ||
                                config.company === "PRPL"
                                  ? "USD"
                                  : "IDR"}
                              </td>
                              <td
                                className="allocation_table_value text-right"
                                nowrap="true"
                              >
                                {idr.payable_to_vendor
                                  ? toLocaleString(idr.payable_to_vendor)
                                  : 0}{" "}
                                {config.company === "SRPL" || config.company === "AVS"  ||
                                config.company === "PRPL"
                                  ? "USD"
                                  : "IDR"}
                              </td>
                              <td
                                nowrap="true"
                                className="table_td text-left pl-4"
                              >
                                {(this.state.features.includes("236") ||
                                  this.state.features.includes("97")) && (
                                  <i
                                    className="fa fa-pencil"
                                    style={{
                                      fontSize: 14,
                                      color: config.themeColor,
                                      cursor: "pointer",
                                    }}
                                    data-toggle="modal"
                                    title="Edit floating crane charges"
                                    data-placement="bottom"
                                    onClick={() =>
                                      this.setState({
                                        businessNo: idr.business_no_id,
                                        coalProcurementChargesID: idr.id,
                                        openDrawer: true,
                                        MVProcurementChargesUpdate: true,
                                      })
                                    }
                                  ></i>
                                )}
                                {idr.payment_postings_availability === 0 &&
                                  (this.state.features.includes("237") ||
                                    this.state.features.includes("97")) &&
                                  idr.approval_status === "Pending" && (
                                    <i
                                      className="fa fa-trash-o"
                                      style={{
                                        paddingLeft: 10,
                                        fontSize: 14,
                                        color: config.themeColor,
                                        cursor: "pointer",
                                      }}
                                      onClick={() => this.deleteCharges(idr)}
                                    ></i>
                                  )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td colSpan="5" className="p-2">
                              No data available
                            </td>
                          </tr>{" "}
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
