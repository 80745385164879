import React, { Component } from 'react';

import config from '../../config/config';
import api from '../../api/api';
import CookieHandler from '../common/CookieHandler';

import {
  InputAdornment, MenuItem, TextField, Checkbox,
  RadioGroup, FormControlLabel, Radio
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { localDateFormate } from '../common/common';

const NewRadio = withStyles({
  root: {
    color: "#ccc",
    '&$checked': {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);
const CheckBox = withStyles({
  root: {
    color: '#ccc',
    '&$checked': {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default class CoalPriceIndexes extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();

    this.state = {
      coal_index_type: 'Weekly',
      index_ids: [],
      average_coal_index_price: 0,
      prorata_gar: '0',
      prorata_coal_index_price: 0,
      bonus_coal_index_pmt: '',
      penality_coal_index_pmt: '',
      derivered_coal_index_pmt: '',
      offset: 0,
      limit: 5,
      final_coal_index_pmt: 0,
      monthly_coal_indexes: [],
      weekly_coal_indexes: [],
      loadMore: false,
      grade_input: '',
    }
  }

  async componentDidMount() {
    await this.get_weekly_coal_prices();
    await this.get_monthly_coal_prices();
    const {
      coal_index_type = 'Weekly',
      index_ids = null,
      average_coal_index_price = 0,
      prorata_gar = '0',
      prorata_coal_index_price = 0,
      bonus_coal_index_pmt = '',
      penality_coal_index_pmt = '',
      derivered_coal_index_pmt = '',
      offset = 0,
      limit = 5,
      final_coal_index_pmt = 0,
    } = this.props.coal_index || {};

    this.setState({
      coal_index_type: coal_index_type,
      index_ids: Array.isArray(index_ids) ? index_ids : index_ids ? index_ids.split(',') : [],
      average_coal_index_price: average_coal_index_price,
      prorata_gar: prorata_gar,
      prorata_coal_index_price: prorata_coal_index_price,
      bonus_coal_index_pmt: bonus_coal_index_pmt,
      penality_coal_index_pmt: penality_coal_index_pmt,
      derivered_coal_index_pmt: derivered_coal_index_pmt,
      final_coal_index_pmt: final_coal_index_pmt,
      offset: offset,
      limit: limit,
    },
      // () => this.renderCalculations()
    );

  }

  get_weekly_coal_prices = () => {
    const { offset, limit } = this.state;
    const reqParams = {
      login_user_id: this.Cookie.getCookie('loginUserId'),
      idtoken: this.Cookie.getIdTokenCookie(),
      offset: offset,
      limit: limit
    }
    api.get_weekly_coal_indexes(reqParams)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json()
            .then((res) => {
              if (res.code === '200') {
                if (res.weekly_coal_indexes.length >= 5) {
                  this.setState({ showLoadMore: true })
                } else { this.setState({ showLoadMore: false }) }
                if (this.state.loadMore) {
                  this.setState({
                    weekly_coal_indexes: [...this.state.weekly_coal_indexes, ...res.weekly_coal_indexes],
                    submitting: false,
                  })
                }
                else {
                  this.setState({
                    weekly_coal_indexes: [...res.weekly_coal_indexes],
                    submitting: false,
                  })
                }

                this.setState({ isLoading: false, loadMore: false, submitting: false })

              }
            })
        }
      })
  }

  get_monthly_coal_prices = () => {
    const { offset, limit } = this.state;
    const reqParams = {
      login_user_id: this.Cookie.getCookie('loginUserId'),
      idtoken: this.Cookie.getIdTokenCookie(),
      offset: offset,
      limit: limit
    }
    api.get_monthly_coal_indexes(reqParams)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json()
            .then((res) => {
              if (res.code === '200') {
                if (res.monthly_coal_indexes.length >= 5) {
                  this.setState({ showLoadMore: true })
                }
                if (this.state.loadMore && this.state.coal_index_type === "Monthly") {
                  this.setState({
                    monthly_coal_indexes: [...this.state.monthly_coal_indexes, ...res.monthly_coal_indexes],
                  })
                } else {
                  this.setState({
                    monthly_coal_indexes: [...res.monthly_coal_indexes],
                  })
                }
                this.setState({ isLoading: false, submitting: false, loadMore: false })
              }
            })
        }
      })
  }

  onCoalIndexCheck = (checked, id) => {
    if (checked) {
      this.setState({
        index_ids: [...this.state.index_ids, id]
      },
        () => this.renderCalculations())
    }
    else {
      this.setState({
        index_ids: this.state.index_ids?.filter(e => e !== id),
      },
        () => this.renderCalculations())
    }
  }



  renderCalculations = () => {
    let { prorata_gar, index_ids, weekly_coal_indexes, monthly_coal_indexes, coal_index_type, bonus_coal_index_pmt = 0, penality_coal_index_pmt = 0,
      grade_input = 1, } = this.state;

    let items = index_ids?.length <= 0 ? 1 : index_ids?.length;
    if (prorata_gar !== '0' || this.props.grade === "Calculations") {

      let selected_index = coal_index_type === "Weekly" ? weekly_coal_indexes?.filter(e => index_ids?.find(obj => e.id === obj)) : monthly_coal_indexes?.filter(e => index_ids?.find(obj => e.id === obj));
      let prorata_gar_nar = prorata_gar === 'nar_6200' ? 'gar_6500'
        : prorata_gar === 'nar_5500' ? 'gar_5800'
          : prorata_gar === 'nar_4600' ? 'gar_5000'
            : prorata_gar === 'nar_3800' ? 'gar_4200'
              : prorata_gar === 'nar_3000' ? 'gar_3400'
                : prorata_gar
      let avg_coal_price = 0;
      for (var i of selected_index) {
        let value = Number(i[prorata_gar_nar]);
        avg_coal_price += (value / items);
      }

      let prorata = prorata_gar === 'gar_6500'
        ? 6500
        : prorata_gar === 'gar_5800'
          ? 5800
          : prorata_gar === 'gar_5000'
            ? 5000
            : prorata_gar === 'gar_4200'
              ? 4200
              : prorata_gar === 'gar_3400'
                ? 3400
                : prorata_gar === 'nar_6200'
                  ? 6200
                  : prorata_gar === 'nar_5500'
                    ? 5500
                    : prorata_gar === 'nar_4600'
                      ? 4600
                      : prorata_gar === 'nar_3800'
                        ? 3800
                        : 3000

      var acutal_grade = 1;
      if (this.props.grade && this.props.grade === "Calculations") {
        acutal_grade = grade_input !== '' || grade_input !== null ? grade_input : 1;
      }
      else {
        acutal_grade = this.props.grade ? parseInt(this.props.grade) : 1;
      }

      if (isNaN(acutal_grade)) {
        acutal_grade = 1;

      }

      let prorata_pmt = (Number(avg_coal_price * (acutal_grade) / prorata)).toFixed(2);
      let derived_coal_price = (Number(prorata_pmt) + Number(bonus_coal_index_pmt) - Number(penality_coal_index_pmt)).toFixed(2)

      this.setState({
        // prorata_gar: prorata,
        average_coal_index_price: (Number(avg_coal_price)).toFixed(2),
        prorata_coal_index_price: prorata_pmt,
        derivered_coal_index_pmt: derived_coal_price,
        final_coal_index_pmt: derived_coal_price
      },
        () => {
          this.props.coalPrices(this.state);
        })

    }

  }

  renderCoalPrices = () => {


    let { coal_index_type, monthly_coal_indexes, weekly_coal_indexes } = this.state;

    let coal_indexes = coal_index_type === "Weekly"
      ? weekly_coal_indexes
      : monthly_coal_indexes;

    return (
      <div className="table-responsive">
        <table className="table table-sm table-bordered mb-0">
          <thead className="table-header">
            <tr>
              <th className='text-center '></th>
              <th className='text-center ' nowrap="true">{coal_index_type === "Weekly" ? 'Week Ending' : "Month Ending"}</th>
              <th className='text-center ' nowrap="true">6500 GAR (6200 NAR) </th>
              <th className='text-center ' nowrap="true">5800 GAR (5500 NAR) </th>
              <th className='text-center ' nowrap="true">5000 GAR (4600 NAR) </th>
              <th className='text-center ' nowrap="true">4200 GAR (3800 NAR) </th>
              <th className='text-center ' nowrap="true">3400 GAR (3000 NAR) </th>
            </tr>
          </thead>
          <tbody>
            {coal_indexes.map((p, idx) => (
              <tr key={p.id}>
                <td className="p-0 text-center ">
                  <CheckBox
                    value={p.id}
                    color="primary"
                    checked={
                      this.state.index_ids?.includes(p.id)
                    }
                    onChange={(e) => {
                      this.onCoalIndexCheck(e.target.checked, p.id);
                    }}
                  />
                </td>
                <td nowrap="true" className='text-center '>{localDateFormate(coal_index_type === "Weekly" ? p.week_ending_date : p.month_year)}</td>
                <td className='text-center '>{p.gar_6500}</td>
                <td className='text-center '>{p.gar_5800}</td>
                <td className='text-center '>{p.gar_5000}</td>
                <td className='text-center '>{p.gar_4200}</td>
                <td className='text-center '>{p.gar_3400}</td>
              </tr>
            ))}
            {this.state.showLoadMore && (

              <tr>
                <td></td>
                <td colSpan={6} className='text-center'>
                  <button
                    type="button"
                    className="header_button header_button_text mt-1"
                    style={{ width: 100, borderRadius: 20, }}
                    onClick={() => {
                      this.setState((state) => {
                        return { offset: state.offset + 5, loadMore: true }
                      },
                        () => {
                          this.state.coal_index_type === "Weekly" ? this.get_weekly_coal_prices() : this.get_monthly_coal_prices();
                        })
                    }}
                    disabled={this.state.submitting}
                  >
                    Load More
                    <span className={this.state.submitting ? "spinner-grow spinner-grow-sm mr-2" : ""}></span>
                  </button>
                </td>
              </tr>
            )}
          </tbody>

        </table>
      </div>
    )
  }

  render() {
    const res = /^\d*(\.\d{0,4})?$/;

    return (
      <div className='card border'>
        <div className='row'>
          <div className='col-lg-4 p-0'>
            <label className='form_label pt-2'>Coal Index Type</label>
          </div>

          <div className='col-lg-6'>

            <RadioGroup aria-label="price_type" name="price_type" onChange={(e) => {
              this.setState({
                coal_index_type: e.target.value,
                index_ids: [],
              });
            }} style={{ flexDirection: 'row' }}>
              <FormControlLabel value="Weekly" control={<NewRadio checked={this.state.coal_index_type === "Weekly"} />} label="Weekly" />
              <FormControlLabel value="Monthly" control={<NewRadio checked={this.state.coal_index_type === "Monthly"} />} label="Monthly" />
            </RadioGroup>
          </div>
        </div>

        <div className='row mb-3'>
          {this.renderCoalPrices()}
        </div>

        {this.props.grade && this.props.grade === "Calculations" &&

          <div className='row'>
            <div className='col-lg-4 p-0'>
              <label className='form_label pt-3'>Actual Grade <span className='text-danger'>*</span></label>
            </div>
            <div className='col-lg-6 p-0'>
              <TextField
                name='grade_input'
                margin='dense'
                variant='outlined'
                fullWidth
                value={this.state.grade_input}
                placeholder='Actual Grade'
                type='number'
                onChange={(e) => {
                  this.setState({
                    grade_input: e.target.value
                  }, () => {
                    this.renderCalculations()
                  })
                }}
              >
              </TextField>
            </div>
          </div>
        }

        <div className='row'>
          <div className='col-lg-4 p-0'>
            <label className='form_label pt-3'>Prorata Grade to be considered</label>
          </div>
          <div className='col-lg-6 p-0'>
            <TextField
              name='prorata_gar'
              margin='dense'
              variant='outlined'
              fullWidth
              value={this.state.prorata_gar}
              placeholder='Grade'
              onChange={(e) => {
                this.setState({
                  prorata_gar: e.target.value
                }, () => {
                  this.renderCalculations()
                })
              }}
              select
            >
              <MenuItem value='0' disabled>Please Select</MenuItem>
              <MenuItem value='gar_6500'>6500 GAR</MenuItem>
              <MenuItem value='gar_5800'>5800 GAR</MenuItem>
              <MenuItem value='gar_5000'>5000 GAR</MenuItem>
              <MenuItem value='gar_4200'>4200 GAR</MenuItem>
              <MenuItem value='gar_3400'>3400 GAR</MenuItem>

              <MenuItem value='nar_6200'>6200 NAR</MenuItem>
              <MenuItem value='nar_5500'>5500 NAR</MenuItem>
              <MenuItem value='nar_4600'>4600 NAR</MenuItem>
              <MenuItem value='nar_3800'>3800 NAR</MenuItem>
              <MenuItem value='nar_3000'>3000 NAR</MenuItem>
            </TextField>
          </div>
        </div>

        <div className='row'>
          <div className='col-lg-4 p-0'>
            <label className='form_label pt-3'>Average Coal Base Price PMT</label>
          </div>
          <div className='col-lg-6 pt-3'>{this.state.average_coal_index_price} USD</div>
        </div>

        <div className='row'>
          <div className='col-lg-4 p-0'>
            <label className='form_label pt-3'>Prorata Coal Base Price PMT</label>
          </div>
          <div className='col-lg-6 pt-3'>{this.state.prorata_coal_index_price} USD</div>
        </div>

        <div className='row'>
          <div className='col-lg-4 p-0'>
            <label className='form_label pt-3'>Bonus Price PMT</label>
          </div>
          <div className='col-lg-6 p-0'>
            <TextField
              name='bonus'
              value={this.state.bonus_coal_index_pmt}
              margin='dense'
              variant='outlined'
              fullWidth
              placeholder='Bonus Price PMT'
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" className='adornment_background' >USD</InputAdornment>
                ),
                style: {
                  fontFamily: 'Poppins',
                  fontSize: '14px',
                  padding: 0
                }
              }}
              onChange={(e) => {
                if (e.target.value === '' || res.test(e.target.value)) {
                  this.setState(
                    {
                      bonus_coal_index_pmt: e.target.value
                    },
                    () => this.renderCalculations()
                  )
                }

              }}

            />
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-4 p-0'>
            <label className='form_label  pt-3'>Discount Price PMT</label>
          </div>
          <div className='col-lg-6 p-0'>
            <TextField
              name='bonus'
              value={this.state.penality_coal_index_pmt}
              margin='dense'
              variant='outlined'
              fullWidth
              placeholder='Penality Price PMT'
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" className='adornment_background' >USD</InputAdornment>
                ),
                style: {
                  fontFamily: 'Poppins',
                  fontSize: '14px',
                  padding: 0
                }
              }}
              onChange={(e) => {
                if (e.target.value === '' || res.test(e.target.value)) {
                  this.setState(
                    {
                      penality_coal_index_pmt: e.target.value
                    },
                    () => this.renderCalculations()
                  )
                }

              }}

            />
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-4 p-0'>
            <label className='form_label pt-3'>Derived Coal Base Price PMT</label>
          </div>
          <div className='col-lg-6 pl-0 pt-3'>{this.state.derivered_coal_index_pmt} USD</div>
        </div>

        <div className='row'>
          <div className='col-lg-4 p-0'>
            <label className='form_label pt-3'>Final Coal Base Price PMT</label>
          </div>
          <div className='col-lg-6 p-0'>
            <TextField
              name='bonus'
              value={this.state.final_coal_index_pmt}
              margin='dense'
              variant='outlined'
              fullWidth
              placeholder='Final Coal Base Price PMT'
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" className='adornment_background' >USD</InputAdornment>
                ),
                style: {
                  fontFamily: 'Poppins',
                  fontSize: '14px',
                  padding: 0
                }
              }}
              onChange={(e) => {
                if (e.target.value === '' || res.test(e.target.value)) {
                  this.setState(
                    {
                      final_coal_index_pmt: e.target.value
                    },
                    () => this.props.coalPrices(this.state)

                  )
                }
              }}
            />
          </div>
        </div>

      </div>
    )
  }
}