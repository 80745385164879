import React from "react";
import CookieHandler from "../../common/CookieHandler";
import api from "../../../api/api";
import config from "../../../config/config";
import SideBar from "../../common/SideBar";
import Header from "../../common/Header";

import MaterialTable from "material-table";
import Paper from "@material-ui/core/Paper";

import exportToExcel from "../../Export_To_Excel";
import Loader from "../../common/Loader";

class PurchaseContractList extends React.Component {
  constructor() {
    super();
    this.Cookie = new CookieHandler();
    this.state = {
      purchase_contracts: [],
      purchaseTerm: "0",
      data: [],
      pages: null,
      loading: true,
      isLoading: false,
      errorMessage: "",
      successMessage: "",
      userRole: [],
      features: [],
    };
  }

  componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    } else {
      const features = this.Cookie.getCookie("features");
      this.setState({ features: features });
      // if(!(features.includes('14')  || features.includes('97')  )) {
      //   window.location.href='/dashboard';
      // }
      const loginUserID = this.Cookie.getCookie("loginUserId");
      const idToken = this.Cookie.getIdTokenCookie();
      const status = "";
      api
        .getPurchaseContracts(loginUserID, status, idToken)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
                if (res.purchase_contract) {
                  this.setState({
                    purchase_contracts: res.purchase_contract,
                    loading: false,
                  });
                } else {
                  alert(
                    "Unexpected error occured. Please contact administrator."
                  );
                }
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    }
    document.title = config.documentTitle + "Purchase Contract List";
  }

  convertExcelHandler = (data, filename, ShowLabel) => {
    var arrData = typeof data != "object" ? JSON.parse(data) : data;
    let Modified = arrData.map((obj) => {
      return {
        "Contract No": obj.contract_no,
        "Vendor Name": obj.vendor_name,
        "Supplier Reference no": obj.supplier_ref_no,
        Currency: obj.currency,
        "Contract Date": obj.contract_date_formatted,
        Laycan1: obj.laycan_1,
        Laycan2: obj.laycan_2,
        Laycan3: obj.laycan_3,
        Laycan4: obj.laycan_4,
        Laycan5: obj.laycan_5,
        "Laycan Quantity1": obj.laycan_quantity_1,
        "Laycan Quantity2": obj.laycan_quantity_2,
        "Laycan Quantity3": obj.laycan_quantity_3,
        "Laycan Quantity4": obj.laycan_quantity_4,
        "Laycan Quantity5": obj.laycan_quantity_5,
        "Index Linked": obj.is_index_linked,
        "Supplier Surveyor Name": obj.supplier_surveyor_name,
        "Supplier Surveyor Analysis Type": obj.supplier_surveyor_analysis_type,
        "Internal Surveyor Name": obj.internal_surveyor_name,
        "Internal Surveyor Analysis Type": obj.internal_surveyor_analysis_type,
        "Joint Surveyor Name": obj.joint_surveyor_name,
        "Joint Surveyor Analysis Type": obj.joint_surveyor_analysis_type,
        "Total Price": obj.total_price,
        "Barge Price": obj.barging_price,
        Pnbp: obj.pnbp,
        "Stevedore Price": obj.stevedore_price,
        "Vessel Type": obj.vessel_type,
        "Loading Rate": obj.loading_rate,
        Pol: obj.pol,
        "Payment Adjustment": obj.payment_adjustment,
        "Payment Term1": obj.payment_terms_1,
        "Payment Term2": obj.payment_terms_2,
        "Share Point Doc Link": obj.share_point_doc_link,
        Remarks: obj.remarks,
      };
    });
    exportToExcel(Modified, filename);
  };

  render() {
    return (
      <div id="wraper">
        <SideBar />
        <div className="content">
          <Header />
          <div className="clearfix content-header">
            <div className="row">
              <div className="col-sm text-left pl-0">
                <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                  Purchase Contract
                  <span style={{ color: "lightgrey", margin: "0px 5px" }}>
                    |
                  </span>
                  <span style={{ color: "#959cb6", fontSize: "15px" }}>
                    {this.state.purchase_contracts.length}
                  </span>
                </h4>
              </div>
              <div className="col text-right float-sm-left">
                {(this.state.features.includes("7") ||
                  this.state.features.includes("97")) && (
                  <a href="/add-purchase">
                    <button
                      className="header_button header_button_text add_button_adjustment"
                      style={{ width: "auto", marginTop: "20px" }}
                    >
                      New Coal Purchase Contract
                    </button>
                  </a>
                )}
              </div>
            </div>
          </div>
          {this.state.isLoading && <Loader />}
          <div className="container p-3">
            {this.state.errorMessage && (
              <div className="col text-center">
                <p className="error-class">{this.state.errorMessage}</p>
              </div>
            )}
            {this.state.successMessage && (
              <div className="col text-center">
                <p className="success-class">{this.state.successMessage}</p>
              </div>
            )}
            {!this.state.isLoading && (
              <div className="col-md card p-0">
                <Paper>
                  <MaterialTable
                    columns={[
                      {
                        title: "Contract Number",
                        field: "contract_no",
                        render: (row) => {
                          if (
                            this.state.features.includes("8") ||
                            this.state.features.includes("97")
                          ) {
                            return (
                              <a
                                style={{
                                  textDecoration: "none",
                                  color: config.themeColor,
                                }}
                                href={
                                  "/view-purchase-contract/" + btoa(row.pcid)
                                }
                              >
                                {row.contract_no}
                              </a>
                            );
                          } else {
                            return <span>{row.contract_no}</span>;
                          }
                        },
                      },
                      {
                        title: "Vendor Name",
                        field: "vendor_name",
                      },
                      {
                        title: "Quality & Quantity",
                        field: "qualities",
                        render: (row) => {
                          return (
                            <span>
                              {row.qualities} - {row.quantities}
                            </span>
                          );
                        },
                        headerStyle: {
                          minWidth: 245,
                        },
                      },
                      {
                        title: "Status",
                        field: "status",
                        render: (row) => {
                          return (
                            <span>
                              {row.status === "Active" && (
                                <span className="active_status">
                                  <i
                                    className="fa fa-circle dot_align"
                                    aria-hidden="true"
                                  />
                                  {row.status}
                                </span>
                              )}
                              {row.status === "Draft" && (
                                <span className="freezed_status">
                                  <i
                                    className="fa fa-circle dot_align"
                                    aria-hidden="true"
                                  />
                                  {row.status}
                                </span>
                              )}
                              {row.status === "Closed" && (
                                <span className="completed_status">
                                  <i
                                    className="fa fa-circle dot_align"
                                    aria-hidden="true"
                                  />
                                  {row.status}
                                </span>
                              )}
                              {(row.status === "cancelled" ||
                                row.status === "Pending Approval") && (
                                <span className="cancel_status">
                                  <i
                                    className="fa fa-circle dot_align"
                                    aria-hidden="true"
                                  />
                                  {row.status}
                                </span>
                              )}
                            </span>
                          );
                        },
                        headerStyle: {
                          minWidth: 120,
                        },
                      },
                      {
                        title: "Action",
                        field: "pcid",
                        sortable: false,
                        filterable: false,
                        headerStyle: {
                          minWidth: 120,
                        },
                        render: (row) => {
                          return (
                            <div
                              style={{
                                textAlign: "left",
                                marginTop: "-10px",
                                marginBottom: "-10px",
                              }}
                            >
                              {(row.status === "Draft" ||
                                row.status === "Pending Approval") && (
                                <a
                                  href={
                                    "/update-purchase-contract/" +
                                    btoa(row.pcid)
                                  }
                                >
                                  {" "}
                                  <i
                                    className="fa fa-pencil edit_icon"
                                    style={{
                                      color: config.themeColor,
                                      cursor: "pointer",
                                    }}
                                  />
                                </a>
                              )}
                            </div>
                          );
                        },
                        hidden: !(
                          this.state.features.includes("9") ||
                          this.state.features.includes("97")
                        ),
                      },
                    ]}
                    title=""
                    data={this.state.purchase_contracts}
                    isLoading={this.state.loading}
                    style={{
                      minHeight: 200,
                      fontFamily: "Poppins",
                      overflowY: "auto",
                      boxShadow: "0 0 5px 0 rgba(0, 39, 77, 0.1)",
                    }}
                    options={{
                      paginationType: "stepped",
                      pageSize: 20,
                      pageSizeOptions: [5, 10, 20, 50, 100, 200],
                      headerStyle: {
                        backgroundColor: "#f9f9f9",
                        minWidth: 215,
                        fontFamily: "Poppins",
                        textTransform: "uppercase",
                        fontSize: "14px",
                        fontWeight: 500,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "0.86",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "rgba(72, 59, 52, 0.7)",
                      },
                      cellStyle: {
                        fontFamily: "Poppins",
                      },
                      // exportAllData: true,
                      // exportButton: true,
                      draggable: false,
                      exportFileName: "purchase-contracts",
                      // tableLayout: 'fixed'
                    }}
                    actions={[
                      {
                        icon: "download",
                        tooltip: "Download",
                        isFreeAction: true,
                        onClick: (event) =>
                          this.convertExcelHandler(
                            this.state.purchase_contracts,
                            "purchase-contracts"
                          ),
                      },
                    ]}
                  />
                </Paper>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default PurchaseContractList;
