import React, { Component } from "react";
import SideBar from "../common/SideBar";
import Header from "../common/Header";
import api from "../../api/api";
import config from "../../config/config";
import CookieHandler from "../common/CookieHandler";
import { Tabs, Tab, Snackbar, Drawer, Button } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import MaterialTable from "material-table";

import BargeCostingView from "../Barge-Purchase-Financials/BargeCosting/BargeCostingView";
import BargeCostingUpdate from "../Barge-Purchase-Financials/BargeCosting/BargeCostingUpdate";

import ChemicalSprayView from "../Barge-Purchase-Financials/Chemical-Spray/Chemical-Spray-View";
import ChemicalSprayUpdate from "../Barge-Purchase-Financials/Chemical-Spray/Chemical-Spray-Update";

import BargeCoalCostingView from "../Barge-Purchase-Financials/CoalCosting/CoalCostingView";
import PerformaInvoiceCosting from "../Barge-Purchase-Financials/CoalCosting/PerformaInvoiceCosting";
import BargeCoalCostingUpdate from "../Barge-Purchase-Financials/CoalCosting/CoalCostingUpdate";
import BargeExportTaxView from "../Barge-Purchase-Financials/Export-Tax/Export-Tax-View";
import BargeExportTaxUpdate from "../Barge-Purchase-Financials/Export-Tax/Export-Tax-Update";

import DocumentationChargesView from "../Barge-Purchase-Financials/Documentation-Charges/Documentation-Charges-View";
import DocumentationChargesUpdate from "../Barge-Purchase-Financials/Documentation-Charges/Documentation-Charges-Update";

import LaporanChargesView from "../Barge-Purchase-Financials/LaporanCharges/LaporanChargesView";
import LaporanChargesUpdate from "../Barge-Purchase-Financials/LaporanCharges/LaporanChargesUpdate";

import ReturnCargoView from "../Barge-Purchase-Financials/Return-Cargo-Charges/ReturnCargoView";
import ReturnCargoUpdate from "../Barge-Purchase-Financials/Return-Cargo-Charges/ReturnCargoUpdate";

import BargeOtherExpenseView from "../Barge-Purchase-Financials/Other-Expenses/Other-Expense-View";
import BargeOtherExpenseUpdate from "../Barge-Purchase-Financials/Other-Expenses/Other-Expense-Update";

import BargeSacView from "../Barge-Purchase-Financials/Sampling&Analysis-Charges/Sac-View";
import BargeSacUpdate from "../Barge-Purchase-Financials/Sampling&Analysis-Charges/Sac-Update";
import CoalProcurementChargesView from "../Barge-Purchase-Financials/Coal-Procurement-Charges/Coal-Procurement-Charges-View";
// Barge purchase Fin CPS
import BargeCpsView from "../Barge-Purchase-Financials/Coal-Procurement-Services/CPS-View";
import BargeCpsUpdate from "../Barge-Purchase-Financials/Coal-Procurement-Services/CPS-Update";

import CoalProcurementChargesUpdate from "../Barge-Purchase-Financials/Coal-Procurement-Charges/Coal-Procurement-Charges-Update";
import BargePreShipmentView from "../Barge-Purchase-Financials/Pre-Shipmet-Inspection/Pre-Shipment-Inspection-View";
import PreShipmentInspectionUpdate from "../Barge-Purchase-Financials/Pre-Shipmet-Inspection/Pre-Shipment-Inspection-update";

// Barge Dmurrages
import BargeVendorDemurrageView from "../Barge-Purchase-Financials-Demurrages/Vendor-Demurrages/Vendor-Demurrage-View";
import BargeVendorDemurrageUpdate from "../Barge-Purchase-Financials-Demurrages/Vendor-Demurrages/Vendor-Demurrage-Update";
import BargeSupplierDemurrageView from "../Barge-Purchase-Financials-Demurrages/Supplier-Demurrages/Supplier-Demurrage-View";
import BargeSupplierDemurrageUpdate from "../Barge-Purchase-Financials-Demurrages/Supplier-Demurrages/Supplier-Demurrage-Update";

import BargeBuyerDemurrageView from "../Barge-Sales-Financial-Demurrages/Buyer-Demurrage-View";
import BuyerDemurrageUpdate from "../Barge-Sales-Financial-Demurrages/Buyer-Demurrage-Update";
// Barge sales Financials
import ViewSalesPI from "../BusinessNoBargeSalesFinancials/Sales-Performa-Coal-Costing/PI-View";
import UpdateSalesPI from "../BusinessNoBargeSalesFinancials/Sales-Performa-Coal-Costing/PI-Update";
import BargeSalesCommericalView from "../BusinessNoBargeSalesFinancials/Commercial-Sales-CoalCosting/BusinessNoSalesCoalCostingView";
import BargeSalesCommericalUpdate from "../BusinessNoBargeSalesFinancials/Commercial-Sales-CoalCosting/BusinessNoSalesCoalCostingUpdate";

import MvBuyerDemurrageView from "../BusinessNoMVDemurrage/Mv_Demurrage_Buyer/Mv_Buyer_DemurrageView";
import MvBuyerDemurrageUpdate from "../BusinessNoMVDemurrage/Mv_Demurrage_Buyer/Mv_Buyer_DemurrageUpdate";
import MvSupplierDemurrageView from "../BusinessNoMVDemurrage/Mv_Demurrage_Supplier/Mv_supplier_DemurrageView";
import MvSupplierDemurrageUpdate from "../BusinessNoMVDemurrage/Mv_Demurrage_Supplier/Mv_supplier_DemurrageUpdate";
// import mother vessel stevedore costing
import MvStevdoreCostingView from "../MvPurchaseFinancial/StevedoreCosting/StevedoreCostView";
import MVStevedorCostingUpdate from "../MvPurchaseFinancial/StevedoreCosting/StevedoreCostUpdate";
// import mother vessel floatig crane
import MvFloatingCraneView from "../MvPurchaseFinancial/FloatingCraneCharges/FloatingCraneChargesView";
import MvFloatingCraneUpdate from "../MvPurchaseFinancial/FloatingCraneCharges/FloatingCraneChargesUpdate";

// import mother vessel documentation charges
import MvDocChargesView from "../MvPurchaseFinancial/Mv-Documentation-Charges/Mv-Doc-Charges-View";
import MvDocChargesUpdate from "../MvPurchaseFinancial/Mv-Documentation-Charges/Mv-Doc-Charges-Update";

// Import Mother vessel sampling & analaysis charges
import MVSamplingChargesView from "../MvPurchaseFinancial/Mv-Pur-Fin-Sampling&Analysis_Charges/Mv-Sampling-Charges-View";
import MVSamplingChargesUpdate from "../MvPurchaseFinancial/Mv-Pur-Fin-Sampling&Analysis_Charges/Mv-Sampling-Charges-Update";

// import Mother vessel pre-shipment
import MvPreShipmentView from "../MvPurchaseFinancial/Mv-Pre-Shipment-Inspection/Mv-Pre-Shipment-Inspection-View";
import MVPreShipmentChargesUpdate from "../MvPurchaseFinancial/Mv-Pre-Shipment-Inspection/Mv-Pre-Shipment-Inspection-Update";

// import freight costing
// import FreightCostingView from '../MvPurchaseFinancial/Mv-Freight-Costing/Mv_Freight_Costing_View';

// import Mother vessel Export tax
import MVExportTaxView from "../MvPurchaseFinancial/Mv-Pur-Fin-Export-Tax/Export-Tax-View";
import MVExportTaxUpdate from "../MvPurchaseFinancial/Mv-Pur-Fin-Export-Tax/Export-Tax-Update";

// import mother vessel coal procurement charges
import MvProcurementChargesView from "../MvPurchaseFinancial/Coal_Procurement_Charges/MvProcurementChargesView";
import MvProcurementChargesUpdate from "../MvPurchaseFinancial/Coal_Procurement_Charges/MvProcurementChargesUpdate";

// import mother vessel coal sales charges
import MvCoalSalesChargesView from "../MvPurchaseFinancial/CoalSalesCharges/MvCoalSalesChargesView";
import MvCoalSalesChargesUpdate from "../MvPurchaseFinancial/CoalSalesCharges/MvCoalSalesChargesUpdate";
// import MVSamplingChargesView from '../MvPurchaseFinancial/CoalSalesCharges/MvCoalSalesChargesView';
import IncomingCreditNoteView from "../MvPurchaseFinancial/Credit-Notes/Incoming-Credit-Note/Incoming-Credit-Note-View";
import IncomingCreditNoteUpdate from "../MvPurchaseFinancial/Credit-Notes/Incoming-Credit-Note/Incoming-Credit-Note-Update";
import OutgoingCreditNoteView from "../MvPurchaseFinancial/Credit-Notes/Outgoing-Credit-Note/Outgoing-Credit-Note-View";
import OutgoingCreditNoteUpdate from "../MvPurchaseFinancial/Credit-Notes/Outgoing-Credit-Note/Outgoing-Credit-Note-Update";

import IncomingDebitNoteView from "../MvPurchaseFinancial/Debit-Notes/Incoming-Debit-Notes/Incoming-Debit-Note-View";
import IncomingDebitNoteUpdate from "../MvPurchaseFinancial/Debit-Notes/Incoming-Debit-Notes/Incoming-Debit-Note-Update";

import OutgoingDebitNoteView from "../MvPurchaseFinancial/Debit-Notes/Outgoing-Debit-Notes/Outgoing-Debit-Note-View";
import OutgoingDebitNoteUpdate from "../MvPurchaseFinancial/Debit-Notes/Outgoing-Debit-Notes/Outgoing-Debit-Note-Update";

import OtherExpenseView from "../MvPurchaseFinancial/Other-Expenses/Other-Expenses-View";
import OtherExpenseUpdate from "../MvPurchaseFinancial/Other-Expenses/Other-Expenses-Update";

import OtherIncomeView from "../MvPurchaseFinancial/Other-Incomes/Other-Incomes-View";
import OtherIncomeUpdate from "../MvPurchaseFinancial/Other-Incomes/Other-Incomes-Update";
// import OtherExpenseUpdate from './Other-Expenses-Update';
// import exportToExcel from '../Export_To_Excel';

import MvDeadFreightUpdate from "../MvPurchaseFinancial/DeadFreight/MvDeadFreightUpdate";
import MvDeadFreightView from "../MvPurchaseFinancial/DeadFreight/MvDeadFreightView";

import { localDateFormate, pad } from "../common/common";
import Loader from "../common/Loader";

export default class CostingApprovals extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();

    this.state = {
      showDrawer: false,
      barges: [],
      activeTabIndex: 0,
      fin_ActiveTabIndex: 0,
      coal_costing_tabs_index: 0,
      isLoading: true,
      loading: true,
      activeStep: 0,
      business_no_filter_active_tab_index: 0,
      total_barges: [],
      sales_fin_active_tab_index: null,
      sales_coal_costing_tabs_index: null,
      barge_demurrage_tabs_index: null,
      financialMVDemurrageActiveTabIndex: null,
      financial_type: "barge_purchase_financials",
      payment_costing_type: "",
      costing_data: [],

      // Barge Purchase Financials
      BargeCostingView: false,
      BargeCostingUpdate: false,
      ChemicalSprayView: false,
      ChemicalSprayUpdate: false,

      // view costing
      cpcView: false,
      CoalCostingView: false,
      barge_costing_type: "Performa",
      exportTaxView: false,
      docView: false,
      sacView: false,
      comboID: null,

      // Mother Vessel
      business_no: null,
      MvFloatingCraneView: false,
      selected_costing: null,
      costingTopOfInvoice: null,
      features: [],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    var features = this.Cookie.getCookie("features");
    this.setState({ features: features }, () => {
      this.pendingApprovalsListHandler();
    });
    // if (!(features.includes('46') || features.includes('97'))) {
    //   window.location.href = '/dashboard';
    // }
    // const loginUserID = this.Cookie.getCookie('loginUserId');
    // const idToken = this.Cookie.getIdTokenCookie();

    document.title = config.documentTitle + "Costing Approvals";
  }

  TabsHandler = (event, value) => {
    this.setState(
      {
        activeTabIndex: value,
        business_no_filter_active_tab_index: 0,
        financial_type:
          value === 0
            ? "barge_purchase_financials"
            : value === 1
            ? "barge_sales_fiancials"
            : "mother_vessel",
      },
      () => this.pendingApprovalsListHandler()
    );
  };

  pendingApprovalsListHandler = () => {
    this.setState({ loading: true });
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const financial_type = this.state.financial_type;
    api
      .get_pending_approval_costing_list(loginUserID, idToken, financial_type)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.costing_data) {
                if (res.costing_data.length === 0) {
                  this.setState({
                    snackBarErrorOpen: true,
                    errorMsg: "No records to display.",
                  });
                }
                this.setState(
                  {
                    isLoading: false,
                    costing_data: res.costing_data,
                    loading: false,
                  },
                  () => this.filterDataByWhoHaveAccessHandler(res.costing_data)
                );
              }
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  };

  approvedListHandler = () => {
    this.setState({ loading: true });
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const financial_type = this.state.financial_type;
    api
      .get_approved_costing_list(loginUserID, idToken, financial_type)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.costing_data) {
                if (res.costing_data.length === 0) {
                  this.setState({
                    snackBarErrorOpen: true,
                    errorMsg: "No records to display.",
                  });
                }
                this.setState(
                  {
                    isLoading: false,
                    loading: false,
                    costing_data: res.costing_data,
                  },
                  () => this.filterDataByWhoHaveAccessHandler(res.costing_data)
                );
              }
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  };

  approveHandler = (id, type) => {
    this.setState({ isLoading: true });
    let request = {
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
      costing_id: id,
      costing_type:
        type === "Barge Purchase Commercial Coal Costing"
          ? "Barge Purchase performa Coal Costing"
          : type === "Barge Purchase Other Expenses"
          ? "Barge Other Expenses"
          : type,
      approval_status: "Approved",
    };
    api.approve_costing_api(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.setState(
              {
                isLoading: false,
                snackBarSuccessOpen: true,
                successMsg: res.message,
              },
              () => this.pendingApprovalsListHandler()
            );
          } else if (res.code === "601") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
              isLoading: false,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
  };

  filterDataByWhoHaveAccessHandler = (data) => {
    if (data) {
      let filtered_data = data;
      if (
        !(
          this.state.features.includes("127") ||
          this.state.features.includes("128") ||
          this.state.features.includes("388") ||
          this.state.features.includes("389") ||
          this.state.features.includes("439") ||
          this.state.features.includes("440")
        )
      ) {
        // Checking Who have Barge Coal Costing Access
        filtered_data = filtered_data.filter(
          (e) =>
            e.type !== "Barge Purchase performa Coal Costing" &&
            e.type !== "Barge Purchase Commercial Coal Costing"
        );
      }
      if (
        !(
          this.state.features.includes("131") ||
          this.state.features.includes("132") ||
          this.state.features.includes("390") ||
          this.state.features.includes("391") ||
          this.state.features.includes("441") ||
          this.state.features.includes("442")
        )
      ) {
        // Checking Who have Barge Costing Access
        filtered_data = filtered_data.filter((e) => e.type !== "Barge Costing");
      }
      if (
        !(
          this.state.features.includes("576") ||
          this.state.features.includes("579") ||
          this.state.features.includes("580") ||
          this.state.features.includes("586") ||
          this.state.features.includes("587")
        )
      ) {
        // Checking Who have Barge Costing Access
        filtered_data = filtered_data.filter(
          (e) => e.type !== "Chemical Spray"
        );
      }
      if (
        !(
          this.state.features.includes("175") ||
          this.state.features.includes("176") ||
          this.state.features.includes("400") ||
          this.state.features.includes("401") ||
          this.state.features.includes("451") ||
          this.state.features.includes("452")
        )
      ) {
        // Checking Who have Barge Export Tax Access
        filtered_data = filtered_data.filter((e) => e.type !== "Export Tax");
      }
      if (
        !(
          this.state.features.includes("183") ||
          this.state.features.includes("184") ||
          this.state.features.includes("402") ||
          this.state.features.includes("403") ||
          this.state.features.includes("453") ||
          this.state.features.includes("454")
        )
      ) {
        // Checking Who have Barge Documentation Charges Access
        filtered_data = filtered_data.filter(
          (e) => e.type !== "Docuementation Charges"
        );
      }
      if (!this.state.features.includes("603")) {
        // Checking Who have Barge Documentation Charges Access
        filtered_data = filtered_data.filter(
          (e) => e.type !== "Laparon Survey Charges"
        );
      }

      if (
        !(
          this.state.features.includes("159") ||
          this.state.features.includes("160") ||
          this.state.features.includes("396") ||
          this.state.features.includes("397") ||
          this.state.features.includes("447") ||
          this.state.features.includes("448")
        )
      ) {
        // Checking Who have Barge Sampling And Analaysis Access
        filtered_data = filtered_data.filter(
          (e) => e.type !== "Sampling & Analaysis Charges"
        );
      }
      if (
        !(
          this.state.features.includes("167") ||
          this.state.features.includes("168") ||
          this.state.features.includes("398") ||
          this.state.features.includes("399") ||
          this.state.features.includes("449") ||
          this.state.features.includes("450")
        )
      ) {
        // Checking Who have Barge Pre Shipment Access
        filtered_data = filtered_data.filter(
          (e) => e.type !== "Pre Shipment Data"
        );
      }

      if (
        !(
          this.state.features.includes("143") ||
          this.state.features.includes("144") ||
          this.state.features.includes("392") ||
          this.state.features.includes("393") ||
          this.state.features.includes("443") ||
          this.state.features.includes("444")
        )
      ) {
        // Checking Who have Barge Coal Procurment Charges Access
        filtered_data = filtered_data.filter(
          (e) => e.type !== "Coal Procurement Charges"
        );
      }
      if (
        !(
          this.state.features.includes("507") ||
          this.state.features.includes("508") ||
          this.state.features.includes("514") ||
          this.state.features.includes("516") ||
          this.state.features.includes("392") ||
          this.state.features.includes("444")
        )
      ) {
        // Checking Who have Barge Coal Procurment Charges Access
        filtered_data = filtered_data.filter(
          (e) => e.type !== "Barge Coal Procurement Service"
        );
      }
      if (
        !(
          this.state.features.includes("339") ||
          this.state.features.includes("340") ||
          this.state.features.includes("550") ||
          this.state.features.includes("551") ||
          this.state.features.includes("552") ||
          this.state.features.includes("553")
        )
      ) {
        // Checking Who have Barge Coal Procurment Charges Access
        filtered_data = filtered_data.filter(
          (e) => e.type !== "Barge Purchase Other Expenses"
        );
      }
      if (
        !(
          this.state.features.includes("537") ||
          this.state.features.includes("538") ||
          this.state.features.includes("540") ||
          this.state.features.includes("541") ||
          this.state.features.includes("547") ||
          this.state.features.includes("548")
        )
      ) {
        // Checking Who have Barge Coal Procurment Charges Access
        filtered_data = filtered_data.filter(
          (e) => e.type !== "Return Cargo Charges"
        );
      }

      if (
        !(
          this.state.features.includes("151") ||
          this.state.features.includes("152") ||
          this.state.features.includes("394") ||
          this.state.features.includes("395") ||
          this.state.features.includes("445") ||
          this.state.features.includes("446")
        )
      ) {
        // Checking Who have Barge Purchase Fin Demurrages Access
        filtered_data = filtered_data.filter(
          (e) =>
            e.type !== "Barge Demurrage From Supplier" &&
            e.type !== "Barge Demurrage to vendor" &&
            e.type !== "Barge Demurrage to Supplier"
        );
      }

      //  SALES FIANACIALS
      if (
        !(
          this.state.features.includes("191") ||
          this.state.features.includes("192") ||
          this.state.features.includes("404") ||
          this.state.features.includes("406") ||
          this.state.features.includes("455") ||
          this.state.features.includes("457")
        )
      ) {
        // Checking Who have Barge Sales Fin Coal Costing Access
        filtered_data = filtered_data.filter(
          (e) =>
            e.type !== "Barge Sales Performa Coal Costing" &&
            e.type !== "Barge Demurrage to vendor" &&
            e.type !== "Barge Sales Commercial Coal Costing"
        );
      }
      if (
        !(
          this.state.features.includes("203") ||
          this.state.features.includes("204") ||
          this.state.features.includes("407") ||
          this.state.features.includes("458") ||
          this.state.features.includes("409") ||
          this.state.features.includes("460")
        )
      ) {
        // Checking Who have Barge Sales Fin Coal Sales Charges Access
        filtered_data = filtered_data.filter(
          (e) => e.type !== "Coal Sales Charges"
        );
      }
      if (
        !(
          this.state.features.includes("410") ||
          this.state.features.includes("412") ||
          this.state.features.includes("461") ||
          this.state.features.includes("463")
        )
      ) {
        // Checking Who have Barge Sales Fin Demurrage Access
        filtered_data = filtered_data.filter(
          (e) =>
            e.type !== "Barge Demurrage To Buyer" &&
            e.type !== "Barge Demurrage From buyer"
        );
      }

      // Mother Vessel Financials
      if (
        !(
          this.state.features.includes("259") ||
          this.state.features.includes("260") ||
          this.state.features.includes("423") ||
          this.state.features.includes("424") ||
          this.state.features.includes("474") ||
          this.state.features.includes("475")
        )
      ) {
        // Checking Who have Mother Vessel Floating Crane Access
        filtered_data = filtered_data.filter(
          (e) => e.type !== "MV Floating Crane"
        );
      }
      if (
        !(
          this.state.features.includes("227") ||
          this.state.features.includes("228") ||
          this.state.features.includes("413") ||
          this.state.features.includes("414") ||
          this.state.features.includes("464") ||
          this.state.features.includes("465")
        )
      ) {
        // Checking Who have Mother Vessel Stevedore Costing Access
        filtered_data = filtered_data.filter(
          (e) => e.type !== "MV Stevedore Costing"
        );
      }
      if (
        !(
          this.state.features.includes("380") ||
          this.state.features.includes("381") ||
          this.state.features.includes("417") ||
          this.state.features.includes("418") ||
          this.state.features.includes("468") ||
          this.state.features.includes("469")
        )
      ) {
        // Checking Who have Mother Vessel Freight Costing Access
        filtered_data = filtered_data.filter(
          (e) => e.type !== "MV Freight Costing"
        );
      }

      if (
        !(
          this.state.features.includes("235") ||
          this.state.features.includes("236") ||
          this.state.features.includes("415") ||
          this.state.features.includes("416") ||
          this.state.features.includes("466") ||
          this.state.features.includes("467")
        )
      ) {
        // Checking Who have Mother Vessel CPC Access
        filtered_data = filtered_data.filter(
          (e) => e.type !== "MV Coal Procurement Charges"
        );
      }

      if (
        !(
          this.state.features.includes("283") ||
          this.state.features.includes("284") ||
          this.state.features.includes("429") ||
          this.state.features.includes("431") ||
          this.state.features.includes("480") ||
          this.state.features.includes("482")
        )
      ) {
        // Checking Who have Mother Vessel CSC Access
        filtered_data = filtered_data.filter(
          (e) => e.type !== "MV Coal Sales Charges"
        );
      }

      if (
        !(
          this.state.features.includes("243") ||
          this.state.features.includes("244") ||
          this.state.features.includes("419") ||
          this.state.features.includes("420") ||
          this.state.features.includes("470") ||
          this.state.features.includes("471")
        )
      ) {
        // Checking Who have Mother Vessel SAC Access
        filtered_data = filtered_data.filter(
          (e) => e.type !== "MV Sampling & Analaysis Charges"
        );
      }

      if (
        !(
          this.state.features.includes("251") ||
          this.state.features.includes("252") ||
          this.state.features.includes("421") ||
          this.state.features.includes("422") ||
          this.state.features.includes("472") ||
          this.state.features.includes("473")
        )
      ) {
        // Checking Who have Mother Vessel Pre Shipment Access
        filtered_data = filtered_data.filter(
          (e) => e.type !== "MV Pre Shipment"
        );
      }
      if (
        !(
          this.state.features.includes("267") ||
          this.state.features.includes("268") ||
          this.state.features.includes("424") ||
          this.state.features.includes("425") ||
          this.state.features.includes("475") ||
          this.state.features.includes("476")
        )
      ) {
        // Checking Who have Mother Vessel Pre Shipment Access
        filtered_data = filtered_data.filter((e) => e.type !== "MV Export Tax");
      }

      if (
        !(
          this.state.features.includes("275") ||
          this.state.features.includes("276") ||
          this.state.features.includes("427") ||
          this.state.features.includes("428") ||
          this.state.features.includes("478") ||
          this.state.features.includes("479")
        )
      ) {
        // Checking Who have Mother Vessel DOC Access
        filtered_data = filtered_data.filter(
          (e) => e.type !== "MV Docuementation Charges"
        );
      }

      if (
        !(
          (
            this.state.features.includes("563") ||
            this.state.features.includes("564") ||
            this.state.features.includes("566") ||
            this.state.features.includes("571") ||
            this.state.features.includes("572")
          )
          // this.state.features.includes("469")
        )
      ) {
        // Checking Who have Mother Vessel Dead Freight Costing Access
        filtered_data = filtered_data.filter(
          (e) => e.type !== "MV Dead Freight"
        );
      }
      if (
        !(
          this.state.features.includes("563") ||
          this.state.features.includes("564") ||
          this.state.features.includes("566") ||
          this.state.features.includes("571") ||
          this.state.features.includes("572")
        )
      ) {
        // Checking Who have Mother Vessel Dead Freight Costing Access
        filtered_data = filtered_data.filter(
          (e) => e.type !== "MV Dead Freight"
        );
      }

      if (
        !(
          this.state.features.includes("295") ||
          this.state.features.includes("296") ||
          this.state.features.includes("487") ||
          this.state.features.includes("488") ||
          this.state.features.includes("494")
        )
      ) {
        // Checking Who have Mother Vessel Dead Freight Costing Access
        filtered_data = filtered_data.filter(
          (e) => e.type !== "MV Other Expenses"
        );
      }

      if (
        !(
          this.state.features.includes("303") ||
          this.state.features.includes("304") ||
          this.state.features.includes("489") ||
          this.state.features.includes("490") ||
          this.state.features.includes("496")
        )
      ) {
        // Checking Who have Mother Vessel Dead Freight Costing Access
        filtered_data = filtered_data.filter(
          (e) => e.type !== "MV Other Income"
        );
      }

      if (
        !(
          this.state.features.includes("83") ||
          this.state.features.includes("84") ||
          this.state.features.includes("485") ||
          this.state.features.includes("486") ||
          this.state.features.includes("492")
        )
      ) {
        // Checking Who have Mother Vessel Dead Freight Costing Access
        filtered_data = filtered_data.filter(
          (e) => e.type !== "MV Debit Note" || e.type !== "MV Credit Note"
        );
      }

      if (
        !(
          this.state.features.includes("50") ||
          this.state.features.includes("51") ||
          this.state.features.includes("432") ||
          this.state.features.includes("437") ||
          this.state.features.includes("483") ||
          this.state.features.includes("484")
        )
      ) {
        // Checking Who have Mother Vessel Demurrages Access
        filtered_data = filtered_data.filter(
          (e) =>
            e.type !== "MV Supplier Demurrage" ||
            e.type !== "MV Buyer Demurrage"
        );
      }

      this.setState({
        costing_data: this.state.features.includes("97") ? data : filtered_data,
      });
    }
  };

  timeTakenForApprovalHandler = (costingDate, approvalDate) => {
    if (costingDate && approvalDate) {
      var g1 = new Date(costingDate);
      var g2 = new Date(approvalDate);
      var diff = g2.getTime() - g1.getTime();
      var mins = Math.round(diff / 60000);
      var hrs = Math.floor(mins / 60);
      var days = Math.floor(hrs / 24);
      mins = mins % 60;
      hrs = hrs % 24;
      if (0 > days) {
        days = 0;
      }
      var ageForCostingApproval = days + " Days";
      return ageForCostingApproval;
    }
  };

  onCancel = (value) => {
    this.setState(
      {
        CoalCostingUpdate: false,
        CoalCostingView: false,
        BargeCostingView: false,
        BargeCostingUpdate: false,
        ChemicalSprayView: false,
        ChemicalSprayUpdate: false,
        cpcView: false,
        cpcUpdate: false,
        cpsView: false,
        cpsUpdate: false,
        docView: false,
        docUpdate: false,
        laprnView: false,
        laprnUpdate: false,
        cargoView: false,
        cargoUpdate: false,

        sacView: false,
        sacUpdate: false,
        showDrawer: false,
        preShipmentView: false,
        preShipmentUpdate: false,
        exportTaxView: false,
        exportTaxUpdate: false,
        salesPerformaView: false,
        salesPerformaUpdate: false,
        salesCommercialView: false,
        salesCommercialUpdate: false,

        barge_dmr_to_vend: false,
        barge_dmr_to_vend_update: false,
        barge_dmr_supplier: false,
        barge_dmr_supplier_update: false,
        buyerDemurrageView: false,
        buyerDemurrageUpdate: false,

        MvFloatingCraneView: false,
        MvFloatingCraneUpdate: false,
        MvStevdoreCostingView: false,
        MvStevdoreCostingUpdate: false,
        MvCpcView: false,
        MvCpcUpdate: false,
        MvDocView: false,
        MvDocUpdate: false,
        MvPreShipmentView: false,
        MvPreShipmentUpdate: false,
        MVExportTaxView: false,
        MvExportTaxUpdate: false,
        MvSacView: false,
        MvSacUpdate: false,
        MvCscView: false,
        MvCscUpdate: false,
        MvDeadFreightUpdate: false,
        MvDeadFreightView: false,
        MvBuyerDemView: false,
        MvBuyerDemUpdate: false,
        MvSupplierDemUpdate: false,
        MvSupplierDemView: false,

        IncomingCreditNoteView: false,
        IncomingCreditNoteUpdate: false,
        OutgoingCreditNoteView: false,
        OutgoingCreditNoteUpdate: false,
        IncomingDebitNoteUpdate: false,
        IncomingDebitNoteView: false,
        OutgoingDebitNoteView: false,
        OutgoingDebitNoteUpdate: false,
        OtherExpenseView: false,
        OtherExpenseUpdate: false,
        OtherIncomeView: false,
        OtherIncomeUpdate: false,
      },
      () => {
        if (this.state.business_no_filter_active_tab_index === 0) {
          this.pendingApprovalsListHandler();
        } else if (this.state.business_no_filter_active_tab_index === 1) {
          this.approvedListHandler();
        }
      }
    );
  };

  onEdit = (value) => {
    this.setState({
      showDrawer: true,
      BargeCostingView: false,
      BargeCostingUpdate:
        this.state.selected_costing === "Barge Costing" ? true : false,
      ChemicalSprayView: false,
      ChemicalSprayUpdate:
        this.state.selected_costing === "Chemical Spray" ? true : false,
      CoalCostingView: false,
      CoalCostingUpdate:
        this.state.selected_costing ===
          "Barge Purchase performa Coal Costing" ||
        this.state.selected_costing === "Barge Purchase Commercial Coal Costing"
          ? true
          : false,
      exportTaxView: false,
      exportTaxUpdate:
        this.state.selected_costing === "Export Tax" ? true : false,
      docView: false,
      bargeOtherExpenseView: false,
      bargeOtherExpenseUpdate:
        this.state.selected_costing === "Barge Purchase Other Expenses"
          ? true
          : false,
      docUpdate:
        this.state.selected_costing === "Docuementation Charges" ? true : false,
      laprnUpdate:
        this.state.selected_costing === "Laparon Survey Charges" ? true : false,
      laprnView: false,
      cargoView: false,
      cargoUpdate:
        this.state.selected_costing === "Return Cargo Charges" ? true : false,
      sacView: false,
      sacUpdate:
        this.state.selected_costing === "Sampling & Analaysis Charges"
          ? true
          : false,
      cpcView: false,
      cpcUpdate:
        this.state.selected_costing === "Coal Procurement Charges"
          ? true
          : false,
      cpsView: false,
      cpsUpdate:
        this.state.selected_costing === "Barge Coal Procurement Service"
          ? true
          : false,
      preShipmentView: false,
      preShipmentUpdate:
        this.state.selected_costing === "Pre Shipment Data" ? true : false,

      salesPerformaView: false,
      salesPerformaUpdate:
        this.state.selected_costing === "Barge Sales Performa Coal Costing"
          ? true
          : false,
      salesCommercialView: false,
      salesCommercialUpdate:
        this.state.selected_costing === "Barge Sales Commercial Coal Costing"
          ? true
          : false,

      barge_dmr_to_vend: false,
      barge_dmr_to_vend_update:
        this.state.selected_costing === "Barge Demurrage to vendor"
          ? true
          : false,
      buyerDemurrageView: false,
      buyerDemurrageUpdate:
        this.state.selected_costing === "Barge Demurrage To Buyer" ||
        this.state.selected_costing === "Barge Demurrage From buyer"
          ? true
          : false,
      barge_dmr_supplier: false,
      barge_dmr_supplier_update:
        this.state.selected_costing === "Barge Demurrage to Supplier" ||
        this.state.selected_costing === "Barge Demurrage From Supplier"
          ? true
          : false,

      // Mother Veesel Financials
      MvFloatingCraneView: false,
      MvFloatingCraneUpdate:
        this.state.selected_costing === "MV Floating Crane" ? true : false,

      MvStevdoreCostingView: false,
      MvStevdoreCostingUpdate:
        this.state.selected_costing === "MV Stevedore Costing" ? true : false,

      MvCpcView: false,
      MvCpcUpdate:
        this.state.selected_costing === "MV Coal Procurement Charges"
          ? true
          : false,
      MvDocView: false,
      MvDocUpdate:
        this.state.selected_costing === "MV Docuementation Charges"
          ? true
          : false,
      MvPreShipmentUpdate:
        this.state.selected_costing === "MV Pre Shipment" ? true : false,
      MvPreShipmentView: false,
      MvExportTaxUpdate:
        this.state.selected_costing === "MV Export Tax" ? true : false,
      MVExportTaxView: false,
      MvSacView: false,
      MvSacUpdate:
        this.state.selected_costing === "MV Sampling & Analaysis Charges"
          ? true
          : false,
      MvCscView: false,
      MvCscUpdate:
        this.state.selected_costing === "MV Coal Sales Charges" ? true : false,
      MvBuyerDemView: false,
      MvDeadFreightUpdate:
        this.state.selected_costing === "MV Dead Freight" ? true : false,
      MvDeadFreightView: false,
      MvBuyerDemUpdate:
        this.state.selected_costing === "MV Buyer Demurrage" ? true : false,
      MvSupplierDemView: false,
      MvSupplierDemUpdate:
        this.state.selected_costing === "MV Supplier Demurrage" ? true : false,

      IncomingCreditNoteView: false,
      IncomingCreditNoteUpdate:
        this.state.selected_costing === "MV Incoming Credit Note"
          ? true
          : false,
      OutgoingCreditNoteView: false,
      OutgoingCreditNoteUpdate:
        this.state.selected_costing === "MV Outgoing Credit Note"
          ? true
          : false,
      IncomingDebitNoteView: false,
      IncomingDebitNoteUpdate:
        this.state.selected_costing === "MV Incoming Debit Note" ? true : false,
      OutgoingDebitNoteView: false,
      OutgoingDebitNoteUpdate:
        this.state.selected_costing === "MV Outgoing Debit Note" ? true : false,
      OtherExpenseView: false,
      OtherExpenseUpdate:
        this.state.selected_costing === "MV Other Expenses" ? true : false,
      OtherIncomeView: false,
      OtherIncomeUpdate:
        this.state.selected_costing === "MV Other Income" ? true : false,
    });
  };

  onUpdateResponse = (value) => {
    if (value) {
      this.setState({
        showDrawer: true,
        BargeCostingUpdate: false,
        BargeCostingView:
          this.state.selected_costing === "Barge Costing" ? true : false,
        ChemicalSprayUpdate: false,
        ChemicalSprayView:
          this.state.selected_costing === "Chemical Spray" ? true : false,
        CoalCostingUpdate: false,
        CoalCostingView:
          this.state.selected_costing ===
            "Barge Purchase performa Coal Costing" ||
          this.state.selected_costing ===
            "Barge Purchase Commercial Coal Costing"
            ? true
            : false,
        exportTaxUpdate: false,
        exportTaxView:
          this.state.selected_costing === "Export Tax" ? true : false,
        docUpdate: false,
        laprnUpdate: false,
        laprnView:
          this.state.selected_costing === "Laparon Survey Charges"
            ? true
            : false,
        bargeOtherExpenseView:
          this.state.selected_costing === "Barge Purchase Other Expenses"
            ? true
            : false,
        bargeOtherExpenseUpdate: false,
        docView:
          this.state.selected_costing === "Docuementation Charges"
            ? true
            : false,
        cargoUpdate: false,
        cargoView:
          this.state.selected_costing === "Return Cargo Charges" ? true : false,
        sacUpdate: false,
        sacView:
          this.state.selected_costing === "Sampling & Analaysis Charges"
            ? true
            : false,
        cpcUpdate: false,
        cpcView:
          this.state.selected_costing === "Coal Procurement Charges"
            ? true
            : false,
        cpsUpdate: false,
        cpsView:
          this.state.selected_costing === "Barge Coal Procurement Service"
            ? true
            : false,
        preShipmentUpdate: false,
        preShipmentView:
          this.state.selected_costing === "Pre Shipment Data" ? true : false,

        salesPerformaUpdate: false,
        salesPerformaView:
          this.state.selected_costing === "Barge Sales Performa Coal Costing"
            ? true
            : false,
        salesCommercialUpdate: false,
        salesCommercialView:
          this.state.selected_costing === "Barge Sales Commercial Coal Costing"
            ? true
            : false,
        barge_dmr_to_vend_update: false,
        barge_dmr_to_vend:
          this.state.selected_costing === "Barge Demurrage to vendor"
            ? true
            : false,
        barge_dmr_supplier_update: false,
        barge_dmr_supplier:
          this.state.selected_costing === "Barge Demurrage to Supplier" ||
          this.state.selected_costing === "Barge Demurrage From Supplier"
            ? true
            : false,
        buyerDemurrageUpdate: false,
        buyerDemurrageView:
          this.state.selected_costing === "Barge Demurrage From buyer" ||
          this.state.selected_costing === "Barge Demurrage To Buyer"
            ? true
            : false,

        MvFloatingCraneUpdate: false,
        MvFloatingCraneView:
          this.state.selected_costing === "MV Flaoting Crane" ? true : false,
        MvStevdoreCostingUpdate: false,
        MvStevdoreCostingView:
          this.state.selected_costing === "MV Stevedore Costing" ? true : false,
        MvCpcUpdate: false,
        MvCpcView:
          this.state.selected_costing === "MV Coal Procurement Charges"
            ? true
            : false,
        MvDocUpdate: false,
        MvDocView:
          this.state.selected_costing === "MV Docuementation Charges"
            ? true
            : false,
        MvPreShipmentUpdate: false,
        MvPreShipmentView:
          this.state.selected_costing === "MV Pre Shipment" ? true : false,
        MvExportTaxUpdate: false,
        MVExportTaxView:
          this.state.selected_costing === "MV Export Tax" ? true : false,
        MvSacUpdate: false,
        MvSacView:
          this.state.selected_costing === "MV Sampling & Analaysis Charges"
            ? true
            : false,
        MvCscUpdate: false,
        MvCscView:
          this.state.selected_costing === "MV Coal Sales Charges"
            ? true
            : false,
        MvDeadFreightUpdate: false,
        MvDeadFreightView:
          this.state.selected_costing === "MV Dead Freight" ? true : false,
        MvBuyerDemUpdate: false,
        MvBuyerDemView:
          this.state.selected_costing === "MV Buyer Demurrage" ? true : false,
        MvSupplierDemView:
          this.state.selected_costing === "MV Supplier Demurrage"
            ? true
            : false,
        MvSupplierDemUpdate: false,
        IncomingCreditNoteUpdate: false,
        IncomingCreditNoteView:
          this.state.selected_costing === "MV Incoming Credit Note"
            ? true
            : false,
        OutgoingCreditNoteUpdate: false,
        OutgoingCreditNoteView:
          this.state.selected_costing === "MV Outgoing Credit Note"
            ? true
            : false,
        IncomingDebitNoteUpdate: false,
        IncomingDebitNoteView:
          this.state.selected_costing === "MV Incoming Debit Note"
            ? true
            : false,
        OutgoingDebitNoteUpdate: false,
        OutgoingDebitNoteView:
          this.state.selected_costing === "MV Outgoing Debit Note"
            ? true
            : false,
        OtherExpenseUpdate: false,
        OtherExpenseView:
          this.state.selected_costing === "MV Other Expenses" ? true : false,
        OtherIncomeUpdate: false,
        OtherIncomeView:
          this.state.selected_costing === "MV Other Income" ? true : false,
      });
    }
  };

  CallbackDrawerClose = (value) => {
    this.setState(
      {
        showDrawer: false,
        MvFloatingCraneView: false,
        MvStevdoreCostingView: false,
        MvSacView: false,
        MvDocView: false,
        MvCpcView: false,
        MVExportTaxView: false,
        MvPreShipmentView: false,
        MvDeadFreightView: false,
        MvDeadFreightUpdate: false,

        // Barge Demurrages
        barge_dmr_to_vend: false,
        barge_dmr_supplier: false,
        buyerDemurrageView: false,
        // barge sales financials
        salesPerformaView: false,
        salesCommercialView: false,

        MvSupplierDemView: false,
        MvBuyerDemView: false,
        MvCscView: false,

        IncomingCreditNoteView: false,
        IncomingCreditNoteUpdate: false,
        OutgoingCreditNoteView: false,
        OutgoingCreditNoteUpdate: false,
        IncomingDebitNoteUpdate: false,
        IncomingDebitNoteView: false,
        OutgoingDebitNoteView: false,
        OutgoingDebitNoteUpdate: false,
        OtherExpenseView: false,
        OtherExpenseUpdate: false,
        OtherIncomeView: false,
        OtherIncomeUpdate: false,
      },
      () => {
        if (this.state.business_no_filter_active_tab_index === 0) {
          this.pendingApprovalsListHandler();
        } else if (this.state.business_no_filter_active_tab_index === 1) {
          this.approvedListHandler();
        }
      }
    );
  };

  render() {
    let drawerComponent;
    if (this.state.BargeCostingView && this.state.comboID !== null) {
      drawerComponent = (
        <BargeCostingView
          bargeCostingID={this.state.comboID}
          onCancel={this.onCancel}
          onEdit={this.onEdit}
        />
      );
    } else if (this.state.BargeCostingUpdate && this.state.comboID !== null) {
      drawerComponent = (
        <BargeCostingUpdate
          bargeCostingID={this.state.comboID}
          onUpdateCosting={this.onUpdateResponse}
          onCancel={this.onCancel}
        />
      );
    }
    if (this.state.ChemicalSprayView && this.state.comboID !== null) {
      drawerComponent = (
        <ChemicalSprayView
          chemicalSprayID={this.state.comboID}
          onCancel={this.onCancel}
          onEdit={this.onEdit}
        />
      );
    } else if (this.state.ChemicalSprayUpdate && this.state.comboID !== null) {
      drawerComponent = (
        <ChemicalSprayUpdate
          chemicalSprayID={this.state.comboID}
          onUpdateCosting={this.onUpdateResponse}
          onCancel={this.onCancel}
        />
      );
    }
    if (
      this.state.CoalCostingView &&
      this.state.comboID !== null &&
      this.state.barge_costing_type
    ) {
      drawerComponent = (
        <BargeCoalCostingView
          comboID={this.state.comboID}
          onCancel={this.onCancel}
          costing_type={this.state.barge_costing_type}
          onEdit={this.onEdit}
          invoiceCostingHandler={(data) => {
            this.setState({
              costingTopOfInvoice: data,
              invoiceCosting: true,
              showDrawer: true,
              comboID: null,
            });
          }}
        />
      );
    } else if (this.state.invoiceCosting && this.state.costingTopOfInvoice) {
      drawerComponent = (
        <PerformaInvoiceCosting
          anotherInfo={this.state.costingTopOfInvoice}
          costing_type="Performa"
          onAddResponse={this.onAddResponse}
          onCancel={this.onCancel}
        />
      );
    } else if (this.state.CoalCostingUpdate && this.state.comboID !== null) {
      drawerComponent = (
        <BargeCoalCostingUpdate
          comboID={this.state.comboID}
          onUpdateCosting={this.onUpdateResponse}
          onBack={this.onCancel}
        />
      );
    }
    if (this.state.exportTaxView && this.state.comboID !== null) {
      drawerComponent = (
        <BargeExportTaxView
          exportTaxID={this.state.comboID}
          onCancel={this.onCancel}
          onEdit={this.onEdit}
        />
      );
    } else if (this.state.exportTaxUpdate && this.state.comboID !== null) {
      drawerComponent = (
        <BargeExportTaxUpdate
          exportTaxID={this.state.comboID}
          onUpdateCosting={this.onUpdateResponse}
          onCancel={this.onCancel}
        />
      );
    }
    if (this.state.docView && this.state.comboID !== null) {
      drawerComponent = (
        <DocumentationChargesView
          docID={this.state.comboID}
          onCancel={this.onCancel}
          onEdit={this.onEdit}
        />
      );
    } else if (this.state.docUpdate && this.state.comboID !== null) {
      drawerComponent = (
        <DocumentationChargesUpdate
          docID={this.state.comboID}
          onUpdateCosting={this.onUpdateResponse}
          onCancel={this.onCancel}
        />
      );
    }

    if (this.state.laprnView && this.state.comboID) {
      drawerComponent = (
        <LaporanChargesView
          laparon_charges_id={this.state.comboID}
          onCancel={this.onCancel}
          onEdit={this.onEdit}
        />
      );
    } else if (this.state.laprnUpdate && this.state.comboID) {
      drawerComponent = (
        <LaporanChargesUpdate
          laparon_charges_id={this.state.comboID}
          onUpdateCosting={this.onUpdateResponse}
          onCancel={this.onCancel}
        />
      );
    }

    if (this.state.cargoView && this.state.returnCargoID) {
      drawerComponent = (
        <ReturnCargoView
          returnCargoID={this.state.returnCargoID}
          onCancel={this.onCancel}
          onEdit={this.onEdit}
        />
      );
    } else if (this.state.cargoUpdate && this.state.returnCargoID) {
      drawerComponent = (
        <ReturnCargoUpdate
          returnCargoID={this.state.returnCargoID}
          onUpdateCosting={this.onUpdateResponse}
          onCancel={this.onCancel}
        />
      );
    }

    if (this.state.sacView && this.state.comboID !== null) {
      drawerComponent = (
        <BargeSacView
          comboID={this.state.comboID}
          onCancel={this.onCancel}
          onEdit={this.onEdit}
        />
      );
    } else if (this.state.sacUpdate && this.state.comboID !== null) {
      drawerComponent = (
        <BargeSacUpdate
          comboID={this.state.comboID}
          onUpdateCosting={this.onUpdateResponse}
          onCancel={this.onCancel}
        />
      );
    } else if (this.state.cpcView && this.state.comboID !== null) {
      drawerComponent = (
        <CoalProcurementChargesView
          costing_id={this.state.comboID}
          onCancel={this.onCancel}
          onEdit={this.onEdit}
        />
      );
    } else if (this.state.cpcUpdate && this.state.comboID !== null) {
      drawerComponent = (
        <CoalProcurementChargesUpdate
          costing_id={this.state.comboID}
          onUpdateCosting={this.onUpdateResponse}
          onCancel={this.onCancel}
        />
      );
    }
    if (this.state.cpsView && this.state.comboID !== null) {
      drawerComponent = (
        <BargeCpsView
          invoiceID={this.state.comboID}
          onCancel={this.onCancel}
          onEdit={this.onEdit}
        />
      );
    } else if (this.state.cpsUpdate && this.state.comboID !== null) {
      drawerComponent = (
        <BargeCpsUpdate
          invoiceID={this.state.comboID}
          purchase_contract_id={this.state.purchase_contract_id}
          onUpdateCosting={this.onUpdateResponse}
          onCancel={this.onCancel}
        />
      );
    }
    if (this.state.preShipmentView && this.state.comboID !== null) {
      drawerComponent = (
        <BargePreShipmentView
          costing_id={this.state.comboID}
          onCancel={this.onCancel}
          onEdit={this.onEdit}
        />
      );
    } else if (this.state.preShipmentUpdate && this.state.comboID !== null) {
      drawerComponent = (
        <PreShipmentInspectionUpdate
          costing_id={this.state.comboID}
          onUpdateCosting={this.onUpdateResponse}
          onCancel={this.onCancel}
          shipment_type={this.state.pre_shipment_type}
        />
      );
    } else if (this.state.bargeOtherExpenseView && this.state.comboID) {
      drawerComponent = (
        <BargeOtherExpenseView
          costing_id={this.state.comboID}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.onEdit}
        />
      );
    } else if (this.state.bargeOtherExpenseUpdate && this.state.comboID) {
      drawerComponent = (
        <BargeOtherExpenseUpdate
          costing_id={this.state.comboID}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.onCancel}
        />
      );
    }

    // Barge sales Fiancials
    if (this.state.salesPerformaView && this.state.comboID) {
      drawerComponent = (
        <ViewSalesPI
          mv_sales_costing_id={this.state.comboID}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          onEdit={this.onEdit}
        />
      );
    }
    if (this.state.salesPerformaUpdate && this.state.comboID) {
      drawerComponent = (
        <UpdateSalesPI
          mv_sales_costing_id={this.state.comboID}
          businessNoID={this.state.business_no}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.onCancel}
        />
      );
    }
    // if (this.state.salesPerformaView && this.state.comboID) {
    //   drawerComponent = <BargeSalesPerformaView costing_type='Performa'
    //     comboID={this.state.comboID}
    //     returnCallbackDrawerClose={this.CallbackDrawerClose}
    //     onEdit={this.onEdit}
    //   />;
    // } if (this.state.salesPerformaUpdate && this.state.comboID) {
    //   drawerComponent = <BargeSalesPerformaUpdate costing_type='Performa' comboID={this.state.comboID}
    //     onUpdateCosting={this.onUpdateResponse}
    //     returnCallbackViewPage={this.onCancel} />;
    // }
    if (this.state.salesCommercialView && this.state.comboID) {
      drawerComponent = (
        <BargeSalesCommericalView
          comboID={this.state.comboID}
          onCancel={this.onCancel}
          onEdit={this.onEdit}
        />
      );
    } else if (
      this.state.salesCommercialUpdate &&
      this.state.comboID !== null
    ) {
      drawerComponent = (
        <BargeSalesCommericalUpdate
          comboID={this.state.comboID}
          onBack={this.onCancel}
        />
      );
    }

    // Barge Demurrages
    if (this.state.barge_dmr_to_vend && this.state.comboID) {
      drawerComponent = (
        <BargeVendorDemurrageView
          comboID={this.state.comboID}
          returnCallbackDrawerClose={this.onCancel}
          returnEditCallback={this.onEdit}
        />
      );
    } else if (this.state.barge_dmr_to_vend_update && this.state.comboID) {
      drawerComponent = (
        <BargeVendorDemurrageUpdate
          comboID={this.state.comboID}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.onCancel}
        />
      );
    }
    if (this.state.barge_dmr_supplier && this.state.comboID) {
      drawerComponent = (
        <BargeSupplierDemurrageView
          demurrage_type={this.state.dmr_type}
          comboID={this.state.comboID}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.onEdit}
        />
      );
    } else if (this.state.barge_dmr_supplier_update && this.state.comboID) {
      drawerComponent = (
        <BargeSupplierDemurrageUpdate
          demurrage_type={this.state.dmr_type}
          comboID={this.state.comboID}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.onCancel}
        />
      );
    }
    if (this.state.buyerDemurrageView && this.state.comboID) {
      drawerComponent = (
        <BargeBuyerDemurrageView
          demurrage_type={this.state.dmr_type}
          comboID={this.state.comboID}
          businessNo={this.state.business_no}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.onEdit}
        />
      );
    } else if (this.state.buyerDemurrageUpdate && this.state.comboID) {
      drawerComponent = (
        <BuyerDemurrageUpdate
          businessNo={this.state.business_no}
          demurrageData={this.state.demurrageData}
          demurrage_type={this.state.dmr_type}
          comboID={this.state.comboID}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.onCancel}
        />
      );
    }

    // Mother vessel
    if (this.state.MvFloatingCraneView && this.state.business_no) {
      drawerComponent = (
        <MvFloatingCraneView
          floatingCraneID={this.state.comboID}
          businessNoID={this.state.business_no}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          onEdit={this.onEdit}
        />
      );
    } else if (this.state.MvFloatingCraneUpdate && this.state.business_no) {
      drawerComponent = (
        <MvFloatingCraneUpdate
          floatingCraneID={this.state.comboID}
          businessNoID={this.state.business_no}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.onCancel}
        />
      );
    }
    if (this.state.MvStevdoreCostingView && this.state.business_no) {
      drawerComponent = (
        <MvStevdoreCostingView
          stevedoreID={this.state.comboID}
          businessNoID={this.state.business_no}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.onEdit}
        />
      );
    } else if (this.state.MvStevdoreCostingUpdate && this.state.business_no) {
      drawerComponent = (
        <MVStevedorCostingUpdate
          stevedoreID={this.state.comboID}
          businessNoID={this.state.business_no}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.onCancel}
        />
      );
    }
    if (this.state.MvCpcView && this.state.business_no) {
      drawerComponent = (
        <MvProcurementChargesView
          coalProcurementChargesID={this.state.comboID}
          businessNoID={this.state.business_no}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.onEdit}
        />
      );
    } else if (this.state.MvCpcUpdate && this.state.business_no) {
      drawerComponent = (
        <MvProcurementChargesUpdate
          coalProcurementChargesID={this.state.comboID}
          businessNoID={this.state.business_no}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.onCancel}
        />
      );
    }
    if (this.state.MvDocView && this.state.business_no) {
      drawerComponent = (
        <MvDocChargesView
          MVDocChargesID={this.state.comboID}
          businessNoID={this.state.business_no}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.onEdit}
        />
      );
    } else if (this.state.MvDocUpdate && this.state.business_no) {
      drawerComponent = (
        <MvDocChargesUpdate
          MVDocChargesID={this.state.comboID}
          businessNoID={this.state.business_no}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.onCancel}
        />
      );
    } else if (this.state.MvPreShipmentView && this.state.business_no) {
      drawerComponent = (
        <MvPreShipmentView
          preShipmentID={this.state.comboID}
          businessNoID={this.state.business_no}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.onEdit}
        />
      );
    } else if (this.state.MvPreShipmentUpdate && this.state.business_no) {
      drawerComponent = (
        <MVPreShipmentChargesUpdate
          preShipmentID={this.state.comboID}
          businessNoID={this.state.business_no}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.onCancel}
        />
      );
    } else if (this.state.MVExportTaxView && this.state.business_no) {
      drawerComponent = (
        <MVExportTaxView
          ExportTaxID={this.state.comboID}
          businessNoID={this.state.business_no}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.onEdit}
        />
      );
    } else if (this.state.MvExportTaxUpdate && this.state.business_no) {
      drawerComponent = (
        <MVExportTaxUpdate
          ExportTaxID={this.state.comboID}
          businessNoID={this.state.business_no}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.onCancel}
        />
      );
    }
    if (this.state.MvSacView && this.state.business_no) {
      drawerComponent = (
        <MVSamplingChargesView
          MVSamplingChargesID={this.state.comboID}
          businessNoID={this.state.business_no}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.onEdit}
        />
      );
    } else if (this.state.MvSacUpdate && this.state.business_no) {
      drawerComponent = (
        <MVSamplingChargesUpdate
          MVSamplingChargesID={this.state.comboID}
          businessNoID={this.state.business_no}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.onCancel}
        />
      );
    }

    if (this.state.MvCscView && this.state.business_no) {
      drawerComponent = (
        <MvCoalSalesChargesView
          coalSalesChargesID={this.state.comboID}
          businessNoID={this.state.business_no}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          onEdit={this.onEdit}
        />
      );
    } else if (this.state.MvCscUpdate && this.state.business_no) {
      drawerComponent = (
        <MvCoalSalesChargesUpdate
          coalSalesChargesID={this.state.comboID}
          businessNoID={this.state.business_no}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.onCancel}
        />
      );
    }

    if (this.state.MvDeadFreightView && this.state.business_no) {
      drawerComponent = (
        <MvDeadFreightView
          businessNoID={this.state.business_no}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.onEdit}
        />
      );
    } else if (this.state.MvDeadFreightUpdate && this.state.business_no) {
      drawerComponent = (
        <MvDeadFreightUpdate
          businessNoID={this.state.business_no}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.onCancel}
        />
      );
    }

    // Mother vessel demurrage
    if (this.state.MvBuyerDemView && this.state.business_no) {
      drawerComponent = (
        <MvBuyerDemurrageView
          MvBuyerDemurrageID={this.state.comboID}
          businessNoID={this.state.business_no}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.onEdit}
        />
      );
    } else if (this.state.MvBuyerDemUpdate && this.state.business_no) {
      drawerComponent = (
        <MvBuyerDemurrageUpdate
          MvBuyerDemurrageID={this.state.comboID}
          businessNoID={this.state.business_no}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.onCancel}
        />
      );
    }
    if (this.state.MvSupplierDemView && this.state.business_no) {
      drawerComponent = (
        <MvSupplierDemurrageView
          MvSupplierDemurrageID={this.state.comboID}
          businessNoID={this.state.business_no}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.onEdit}
        />
      );
    } else if (this.state.MvSupplierDemUpdate && this.state.business_no) {
      drawerComponent = (
        <MvSupplierDemurrageUpdate
          MvSupplierDemurrageID={this.state.comboID}
          businessNoID={this.state.business_no}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.onCancel}
        />
      );
    }

    if (this.state.IncomingCreditNoteView && this.state.business_no) {
      drawerComponent = (
        <IncomingCreditNoteView
          creditNoteID={this.state.comboID}
          businessNoID={this.state.business_no}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.onEdit}
        />
      );
    } else if (this.state.IncomingCreditNoteUpdate && this.state.business_no) {
      drawerComponent = (
        <IncomingCreditNoteUpdate
          creditNoteID={this.state.comboID}
          businessNoID={this.state.business_no}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.onCancel}
        />
      );
    }
    if (this.state.OutgoingCreditNoteView && this.state.business_no) {
      drawerComponent = (
        <OutgoingCreditNoteView
          creditNoteID={this.state.comboID}
          businessNoID={this.state.business_no}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.onEdit}
        />
      );
    } else if (this.state.OutgoingCreditNoteUpdate && this.state.business_no) {
      drawerComponent = (
        <OutgoingCreditNoteUpdate
          creditNoteID={this.state.comboID}
          businessNoID={this.state.business_no}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.onCancel}
        />
      );
    }

    if (this.state.IncomingDebitNoteView && this.state.business_no) {
      drawerComponent = (
        <IncomingDebitNoteView
          debitNoteID={this.state.comboID}
          businessNoID={this.state.business_no}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.onEdit}
        />
      );
    } else if (this.state.IncomingDebitNoteUpdate && this.state.business_no) {
      drawerComponent = (
        <IncomingDebitNoteUpdate
          debitNoteID={this.state.comboID}
          businessNoID={this.state.business_no}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.onCancel}
        />
      );
    }
    if (this.state.OutgoingDebitNoteView && this.state.business_no) {
      drawerComponent = (
        <OutgoingDebitNoteView
          debitNoteID={this.state.comboID}
          businessNoID={this.state.business_no}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.onEdit}
        />
      );
    } else if (this.state.OutgoingDebitNoteUpdate && this.state.business_no) {
      drawerComponent = (
        <OutgoingDebitNoteUpdate
          debitNoteID={this.state.comboID}
          businessNoID={this.state.business_no}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.onCancel}
        />
      );
    }
    if (this.state.OtherExpenseView && this.state.business_no) {
      drawerComponent = (
        <OtherExpenseView
          OtherExpenseID={this.state.comboID}
          businessNoID={this.state.business_no}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.onEdit}
        />
      );
    } else if (this.state.OtherExpenseUpdate && this.state.business_no) {
      drawerComponent = (
        <OtherExpenseUpdate
          OtherExpenseID={this.state.comboID}
          businessNoID={this.state.business_no}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.onCancel}
        />
      );
    }
    if (this.state.OtherIncomeView && this.state.business_no) {
      drawerComponent = (
        <OtherIncomeView
          OtherIncomeID={this.state.comboID}
          businessNoID={this.state.business_no}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.onEdit}
        />
      );
    } else if (this.state.OtherIncomeUpdate && this.state.business_no) {
      drawerComponent = (
        <OtherIncomeUpdate
          OtherIncomeID={this.state.comboID}
          businessNoID={this.state.business_no}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.onCancel}
        />
      );
    }
    return (
      <div id="wraper">
        <SideBar />
        <div className="content">
          <Header />
          <Drawer
            anchor="right"
            open={this.state.showDrawer}
            variant="temporary"
            elevation={20}
            style={{ overflow: "initial" }}
          >
            <div className="row" style={{ width: 800 }}>
              {drawerComponent}
            </div>
          </Drawer>
          <div className="clearfix content-header">
            <div className="row">
              <div className="col-sm text-left pl-0">
                <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                  Business Approvals{" "}
                  <span style={{ color: "lightgrey", margin: "0px 5px" }}>
                    |
                  </span>
                  <span style={{ color: "#959cb6", fontSize: "15px" }}>
                    {this.state.costing_data.length}
                  </span>
                </h4>
              </div>
            </div>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <div className="container p-2">
            {this.state.isLoading && <Loader />}
            {!this.state.isLoading && (
              <div className="card p-0" style={{ overflowY: "scroll" }}>
                <div className="row border-bottom">
                  <Tabs
                    value={this.state.activeTabIndex}
                    onChange={this.TabsHandler}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    style={{
                      backgroundColor: "#ffffff",
                      color: "#6c7293",
                      padding: 0,
                    }}
                    TabIndicatorProps={{
                      style: { background: config.themeColor },
                    }}
                  >
                    <Tab
                      label={
                        <h4
                          style={{
                            margin: "0px",
                            color:
                              this.state.activeTabIndex === 0
                                ? config.themeColor
                                : "#6c7293",
                            fontSize: "14px",
                            border: "0px",
                          }}
                        >
                          Barge Purchase Financial
                        </h4>
                      }
                      value={0}
                      style={{
                        fontFamily: "Poppins",
                        textTransform: "capitalize",
                        color: "#6c7293",
                      }}
                    />
                    <Tab
                      label={
                        <h4
                          style={{
                            margin: "0px",
                            color:
                              this.state.activeTabIndex === 1
                                ? config.themeColor
                                : "#6c7293",
                            fontSize: "14px",
                            border: "0px",
                          }}
                        >
                          Barge Sales Financial
                        </h4>
                      }
                      value={1}
                      style={{
                        fontFamily: "Poppins",
                        textTransform: "capitalize",
                        color: "#6c7293",
                      }}
                    />
                    <Tab
                      label={
                        <h4
                          style={{
                            margin: "0px",
                            color:
                              this.state.activeTabIndex === 2
                                ? config.themeColor
                                : "#6c7293",
                            fontSize: "14px",
                            border: "0px",
                          }}
                        >
                          Mother Vessel Financial
                        </h4>
                      }
                      value={2}
                      style={{
                        fontFamily: "Poppins",
                        textTransform: "capitalize",
                        color: "#6c7293",
                      }}
                    />
                  </Tabs>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <MaterialTable
                      className="MuiTableCell_root"
                      title={
                        <div className="row">
                          <div className="col-lg-12">
                            <Button
                              className="tab_button"
                              onClick={(e, value) => {
                                this.setState(
                                  {
                                    business_no_filter_active_tab_index: 0,
                                  },
                                  () => this.pendingApprovalsListHandler()
                                );
                              }}
                              style={{
                                textTransform: "capitalize",
                                margin: "5px 0px",
                                padding: "5px 20px",
                                borderRadius: 35,
                                height: 30,
                                color:
                                  this.state
                                    .business_no_filter_active_tab_index === 0
                                    ? config.themeColor
                                    : "#483b34b3",
                                backgroundColor:
                                  this.state
                                    .business_no_filter_active_tab_index === 0
                                    ? "#fff7f2"
                                    : "#fff",
                                fontSize: "14px",
                                border: "0px",
                              }}
                            >
                              Pending
                            </Button>
                            <Button
                              className="tab_button"
                              onClick={(e, value) => {
                                this.setState(
                                  {
                                    business_no_filter_active_tab_index: 1,
                                  },
                                  () => this.approvedListHandler()
                                );
                              }}
                              style={{
                                textTransform: "capitalize",
                                margin: "5px 0px",
                                padding: "5px 20px",
                                borderRadius: 35,
                                height: 30,
                                color:
                                  this.state
                                    .business_no_filter_active_tab_index === 1
                                    ? config.themeColor
                                    : "#483b34b3",
                                backgroundColor:
                                  this.state
                                    .business_no_filter_active_tab_index === 1
                                    ? "#fff7f2"
                                    : "#fff",
                                fontSize: "14px",
                                border: "0px",
                              }}
                            >
                              Approved
                            </Button>
                          </div>
                        </div>
                      }
                      columns={[
                        {
                          title: "",
                          field: "costing_type",
                          customSort: () => 0,
                          defaultGroupOrder: 0,
                        },
                        {
                          title: "Costing ID",
                          field: "costing_no",
                          width: 150,
                          headerStyle: {
                            minWidth: 170,
                          },
                          render: (row) => {
                            return (
                              <span
                                style={{
                                  cursor: "pointer",
                                  color:
                                    this.state.features.includes(
                                      row.view_costing_access_id
                                    ) || this.state.features.includes("97")
                                      ? config.themeColor
                                      : "",
                                  marginLeft: 50,
                                }}
                                onClick={() => {
                                  this.setState({ selected_costing: row.type });
                                  if (
                                    this.state.features.includes(
                                      row.view_costing_access_id
                                    ) ||
                                    this.state.features.includes("97")
                                  ) {
                                    if (row.type === "Barge Costing") {
                                      this.setState({
                                        showDrawer: true,
                                        BargeCostingView: true,
                                        comboID: row.id,
                                      });
                                    } else if (row.type === "Chemical Spray") {
                                      this.setState({
                                        showDrawer: true,
                                        ChemicalSprayView: true,
                                        comboID: row.id,
                                      });
                                    } else if (
                                      row.type === "Coal Procurement Charges"
                                    ) {
                                      this.setState({
                                        showDrawer: true,
                                        cpcView: true,
                                        comboID: row.id,
                                      });
                                    } else if (
                                      row.type ===
                                      "Barge Coal Procurement Service"
                                    ) {
                                      this.setState({
                                        showDrawer: true,
                                        cpsView: true,
                                        comboID: row.id,
                                      });
                                    } else if (
                                      row.type === "Pre Shipment Data"
                                    ) {
                                      this.setState({
                                        showDrawer: true,
                                        preShipmentView: true,
                                        comboID: row.id,
                                        pre_shipment_type:
                                          row.barge_ids.includes("0")
                                            ? "Without Barge"
                                            : "With Barge",
                                      });
                                    } else if (
                                      row.type ===
                                      "Barge Purchase performa Coal Costing"
                                    ) {
                                      this.setState({
                                        showDrawer: true,
                                        CoalCostingView: true,
                                        comboID:
                                          row.id +
                                          "$#" +
                                          row.purchase_contract_id,
                                        barge_costing_type: "Performa",
                                      });
                                    } else if (
                                      row.type ===
                                      "Barge Purchase Commercial Coal Costing"
                                    ) {
                                      this.setState({
                                        showDrawer: true,
                                        CoalCostingView: true,
                                        comboID:
                                          row.id +
                                          "$#" +
                                          row.purchase_contract_id,
                                        barge_costing_type: "Commercial",
                                      });
                                    } else if (row.type === "Export Tax") {
                                      this.setState({
                                        showDrawer: true,
                                        exportTaxView: true,
                                        comboID: row.id,
                                      });
                                    } else if (
                                      row.type ===
                                      "Sampling & Analaysis Charges"
                                    ) {
                                      this.setState({
                                        showDrawer: true,
                                        sacView: true,
                                        comboID:
                                          row.barge_group_id + "$#" + row.id,
                                      });
                                    } else if (
                                      row.type === "Docuementation Charges"
                                    ) {
                                      this.setState({
                                        showDrawer: true,
                                        docView: true,
                                        comboID: row.id,
                                      });
                                    } else if (
                                      row.type === "Laparon Survey Charges"
                                    ) {
                                      this.setState({
                                        showDrawer: true,
                                        laprnView: true,
                                        comboID: row.id,
                                      });
                                    } else if (
                                      row.type === "Return Cargo Charges"
                                    ) {
                                      this.setState({
                                        showDrawer: true,
                                        cargoView: true,
                                        returnCargoID: row.id,
                                      });
                                    } else if (
                                      row.type ===
                                      "Barge Purchase Other Expenses"
                                    ) {
                                      this.setState({
                                        showDrawer: true,
                                        bargeOtherExpenseView: true,
                                        comboID: row.id,
                                      });
                                    }

                                    // Sales Financials
                                    if (
                                      row.type ===
                                      "Barge Sales Performa Coal Costing"
                                    ) {
                                      this.setState({
                                        showDrawer: true,
                                        salesPerformaView: true,
                                        comboID: row.id,
                                      });
                                    } else if (
                                      row.type ===
                                      "Barge Sales Commercial Coal Costing"
                                    ) {
                                      this.setState({
                                        showDrawer: true,
                                        salesCommercialView: true,
                                        comboID:
                                          row.id +
                                          "$#" +
                                          row.business_no_id +
                                          "$#" +
                                          row.business_allocation_id +
                                          "$#" +
                                          row.business_no_barge_id,
                                      });
                                    }

                                    // Barge Demurrrages
                                    if (
                                      row.type === "Barge Demurrage to vendor"
                                    ) {
                                      this.setState({
                                        showDrawer: true,
                                        barge_dmr_to_vend: true,
                                        comboID: row.id,
                                      });
                                    }
                                    if (
                                      row.type === "Barge Demurrage to Supplier"
                                    ) {
                                      this.setState({
                                        showDrawer: true,
                                        barge_dmr_supplier: true,
                                        dmr_type: "company_to_supplier",
                                        comboID: row.id,
                                      });
                                    } else if (
                                      row.type ===
                                      "Barge Demurrage From Supplier"
                                    ) {
                                      this.setState({
                                        showDrawer: true,
                                        barge_dmr_supplier: true,
                                        dmr_type: "supplier_to_company",
                                        comboID: row.id,
                                      });
                                    } else if (
                                      row.type === "Barge Demurrage From buyer"
                                    ) {
                                      this.setState({
                                        showDrawer: true,
                                        buyerDemurrageView: true,
                                        business_no: row.business_no_id,
                                        dmr_type: "buyer_to_company",
                                        comboID:
                                          row.id +
                                          "$#" +
                                          row.barge_ids.toString(),
                                      });
                                    } else if (
                                      row.type === "Barge Demurrage To Buyer"
                                    ) {
                                      this.setState({
                                        showDrawer: true,
                                        buyerDemurrageView: true,
                                        business_no: row.business_no_id,
                                        dmr_type: "company_to_buyer",
                                        comboID:
                                          row.id +
                                          "$#" +
                                          row.barge_ids.toString(),
                                      });
                                    }

                                    if (row.type === "MV Floating Crane") {
                                      this.setState({
                                        showDrawer: true,
                                        MvFloatingCraneView: true,
                                        comboID: row.id,
                                        business_no: row.business_no_id,
                                      });
                                    } else if (
                                      row.type === "MV Stevedore Costing"
                                    ) {
                                      this.setState({
                                        showDrawer: true,
                                        MvStevdoreCostingView: true,
                                        comboID: row.id,
                                        business_no: row.business_no_id,
                                      });
                                    } else if (
                                      row.type === "MV Coal Procurement Charges"
                                    ) {
                                      this.setState({
                                        showDrawer: true,
                                        MvCpcView: true,
                                        comboID: row.id,
                                        business_no: row.business_no_id,
                                      });
                                    } else if (
                                      row.type ===
                                      "MV Sampling & Analaysis Charges"
                                    ) {
                                      this.setState({
                                        showDrawer: true,
                                        MvSacView: true,
                                        comboID: row.id,
                                        business_no: row.business_no_id,
                                      });
                                    } else if (row.type === "MV Pre Shipment") {
                                      this.setState({
                                        showDrawer: true,
                                        MvPreShipmentView: true,
                                        comboID: row.id,
                                        business_no: row.business_no_id,
                                      });
                                    } else if (
                                      row.type === "MV Docuementation Charges"
                                    ) {
                                      this.setState({
                                        showDrawer: true,
                                        MvDocView: true,
                                        comboID: row.id,
                                        business_no: row.business_no_id,
                                      });
                                    } else if (
                                      row.type === "MV Coal Sales Charges"
                                    ) {
                                      this.setState({
                                        showDrawer: true,
                                        MvCscView: true,
                                        comboID: row.id,
                                        business_no: row.business_no_id,
                                      });
                                    } else if (row.type === "MV Export Tax") {
                                      this.setState({
                                        showDrawer: true,
                                        MVExportTaxView: true,
                                        comboID: row.id,
                                        business_no: row.business_no_id,
                                      });
                                    }
                                    if (row.type === "MV Buyer Demurrage") {
                                      this.setState({
                                        showDrawer: true,
                                        MvBuyerDemView: true,
                                        comboID: row.id,
                                        business_no: row.business_no_id,
                                      });
                                    }
                                    if (row.type === "MV Supplier Demurrage") {
                                      this.setState({
                                        showDrawer: true,
                                        MvSupplierDemView: true,
                                        comboID: row.id,
                                        business_no: row.business_no_id,
                                      });
                                    }
                                    if (row.type === "MV Dead Freight") {
                                      this.setState({
                                        showDrawer: true,
                                        MvDeadFreightView: true,
                                        comboID: row.id,
                                        business_no: row.business_no_id,
                                      });
                                    }
                                    if (
                                      row.type === "MV Incoming Credit Note"
                                    ) {
                                      this.setState({
                                        showDrawer: true,
                                        IncomingCreditNoteView: true,
                                        comboID: row.id,
                                        business_no: row.business_no_id,
                                      });
                                    }
                                    if (
                                      row.type === "MV Outgoing Credit Note"
                                    ) {
                                      this.setState({
                                        showDrawer: true,
                                        OutgoingCreditNoteView: true,
                                        comboID: row.id,
                                        business_no: row.business_no_id,
                                      });
                                    }
                                    if (row.type === "MV Incoming Debit Note") {
                                      this.setState({
                                        showDrawer: true,
                                        IncomingDebitNoteView: true,
                                        comboID: row.id,
                                        business_no: row.business_no_id,
                                      });
                                    }
                                    if (row.type === "MV Outgoing Debit Note") {
                                      this.setState({
                                        showDrawer: true,
                                        OutgoingDebitNoteView: true,
                                        comboID: row.id,
                                        business_no: row.business_no_id,
                                      });
                                    }
                                    if (row.type === "MV Other Expenses") {
                                      this.setState({
                                        showDrawer: true,
                                        OtherExpenseView: true,
                                        comboID: row.id,
                                        business_no: row.business_no_id,
                                      });
                                    }
                                    if (row.type === "MV Other Income") {
                                      this.setState({
                                        showDrawer: true,
                                        OtherIncomeView: true,
                                        comboID: row.id,
                                        business_no: row.business_no_id,
                                      });
                                    }
                                  }
                                }}
                              >
                                {row.costing_no}
                              </span>
                            );
                          },
                        },
                        {
                          title: "Barge ID",
                          field: "barge_ids",
                          width: 150,
                          sorting: false,
                          headerStyle: {
                            textAlign: "left",
                            minWidth: 150,
                          },
                          cellStyle: {
                            textAlign: "left",
                          },
                          hidden:
                            this.state.activeTabIndex === 2 ? true : false,
                          render: (row) => {
                            if (row.barge_ids && row.barge_ids.length > 0) {
                              const finalData = row.barge_ids.map((value) => (
                                <p key={value} className="mb-1">
                                  {value !== "0" ? pad(value) : "Without Barge"}
                                </p>
                              ));
                              return finalData;
                            } else return null;
                          },
                        },
                        {
                          title: "Barge Name",
                          field: "barge_nominations",
                          width: 200,
                          sorting: false,
                          headerStyle: {
                            textAlign: "left",
                            minWidth: 200,
                          },
                          cellStyle: {
                            textAlign: "left",
                          },
                          hidden:
                            this.state.activeTabIndex === 2 ? true : false,

                          render: (row) => {
                            if (
                              row.barge_nominations &&
                              row.barge_nominations.length > 0
                            ) {
                              const finalData = row.barge_nominations.map(
                                (value) => (
                                  <p key={value} className="mb-1">
                                    {value}
                                  </p>
                                )
                              );
                              return finalData;
                            } else return null;
                          },
                        },
                        {
                          title: "Vendor Name",
                          field: "vendor_name",
                          width: 250,
                          sorting: true,
                          headerStyle: {
                            textAlign: "left",
                            minWidth: 200,
                          },
                          cellStyle: {
                            textAlign: "left",
                          },
                          hidden:
                            this.state.activeTabIndex === 1 ? true : false,
                        },
                        {
                          title: "Customer Name",
                          field: "customer_name",
                          width: 250,
                          sorting: true,
                          headerStyle: {
                            textAlign: "left",
                            minWidth: 200,
                          },
                          cellStyle: {
                            textAlign: "left",
                          },
                          hidden:
                            this.state.activeTabIndex !== 1 ? true : false,
                        },
                        {
                          title: "Business No",
                          field: "business_numbers",
                          sorting: true,
                          width: 350,
                          headerStyle: {
                            textAlign: "left",
                            minWidth: 300,
                          },
                          cellStyle: {
                            textAlign: "left",
                          },
                          render: (row) => {
                            if (
                              row.business_numbers &&
                              row.business_numbers.length > 0
                            ) {
                              const finalData = row.business_numbers.map(
                                (value) => (
                                  <p key={value} className="mb-1">
                                    {value}
                                  </p>
                                )
                              );
                              return finalData;
                            } else return null;
                          },
                        },
                        {
                          title: "Created By",
                          field: "costing_created_by",
                          sorting: true,
                          width: 200,
                          headerStyle: {
                            textAlign: "left",
                            minWidth: 200,
                          },
                          cellStyle: {
                            textAlign: "left",
                          },
                        },
                        {
                          title: "Created Date",
                          field: "costing_created_date",
                          width: 200,
                          sorting: false,
                          headerStyle: {
                            textAlign: "left",
                            minWidth: 250,
                          },
                          cellStyle: {
                            textAlign: "left",
                          },
                          render: (row) => {
                            if (row.costing_created_date) {
                              return localDateFormate(row.costing_created_date);
                            } else return null;
                          },
                        },
                        {
                          // title: "1st Level Approval",
                          title: "Approval",
                          field: "costing_approved_by",
                          sorting: true,
                          width: 300,
                          headerStyle: {
                            textAlign: "left",
                            minWidth: 250,
                          },
                          cellStyle: {
                            textAlign: "left",
                          },
                          render: (row) => {
                            if (
                              row.approval_status === "Pending" &&
                              this.Cookie.getCookie("loginUserId") !==
                                row.costing_created_id &&
                              (this.state.features.includes(
                                row.approval_access_id_1
                              ) ||
                                this.state.features.includes("97"))
                            ) {
                              return (
                                <button
                                  className="header_button header_button_text add_button_adjustment"
                                  style={{
                                    width: "auto",
                                    top: 0,
                                    marginTop: 0,
                                  }}
                                  onClick={() =>
                                    this.approveHandler(row.id, row.type)
                                  }
                                >
                                  Approve
                                </button>
                              );
                            } else {
                              return row.costing_approved_by;
                            }
                          },
                        },

                        {
                          // title: "1st Level Approved On",
                          title: "Approved On",
                          field: "costing_approved_date",
                          width: 300,
                          sorting: false,
                          headerStyle: {
                            textAlign: "left",
                            minWidth: 200,
                          },
                          cellStyle: {
                            textAlign: "left",
                          },
                          render: (row) => {
                            if (row.costing_approved_date) {
                              return localDateFormate(
                                row.costing_approved_date
                              );
                            } else return null;
                          },
                        },

                        {
                          title: "Time Taken For Approval",
                          field: "costing_created_date",
                          width: 350,
                          sorting: false,
                          headerStyle: {
                            textAlign: "left",
                            minWidth: 230,
                          },
                          hidden:
                            this.state.business_no_filter_active_tab_index === 0
                              ? true
                              : false,
                          cellStyle: {
                            textAlign: "left",
                          },
                          render: (row) => {
                            if (row.approval_status !== "Pending") {
                              return this.timeTakenForApprovalHandler(
                                row.costing_created_date,
                                row.costing_approved_date
                              );
                            } else {
                              return null;
                            }
                          },
                        },
                        {
                          title: "Payment Status",
                          field: "payable_to_supplier",
                          width: 250,
                          sorting: false,
                          headerStyle: {
                            textAlign: "left",
                            minWidth: 145,
                          },
                          cellStyle: {
                            textAlign: "left",
                          },
                          render: (row) => {
                            let payable_to_supplier = row.payable_to_supplier;
                            let paid = 0;

                            if (row.payments) {
                              for (var i of row.payments) {
                                if (i.date_of_payment) {
                                  paid += Number(i.amount);
                                }
                              }
                            }

                            if (Number(paid ? paid : 0) <= 0) {
                              return <span className="type_3">Pending</span>;
                            } else {
                              if (
                                Number(paid ? paid : 0) <=
                                Number(
                                  ((payable_to_supplier
                                    ? payable_to_supplier
                                    : 0) *
                                    99) /
                                    100
                                )
                              ) {
                                return (
                                  <span className="type_2">
                                    {" "}
                                    Partially Paid{" "}
                                  </span>
                                );
                              }
                              if (
                                Number(paid ? paid : 0) >=
                                  Number(
                                    ((payable_to_supplier
                                      ? payable_to_supplier
                                      : 0) *
                                      99) /
                                      100
                                  ) &&
                                Number(paid ? paid : 0) <=
                                  Number(
                                    ((payable_to_supplier
                                      ? payable_to_supplier
                                      : 0) *
                                      101) /
                                      100
                                  )
                              ) {
                                return <span className="type_1"> Paid </span>;
                              }
                              if (
                                Number(paid ? paid : 0) >=
                                Number(
                                  ((payable_to_supplier
                                    ? payable_to_supplier
                                    : 0) *
                                    101) /
                                    100
                                )
                              ) {
                                return (
                                  <span className="type_4"> Over Paid </span>
                                );
                              }
                            }
                          },
                        },
                      ]}
                      data={this.state.costing_data}
                      isLoading={this.state.loading}
                      style={{
                        minHeight: 180,
                        fontFamily: "Poppins",
                        overflowY: "auto",
                        boxShadow: "0 0 5px 0 rgba(0, 39, 77, 0.1)",
                      }}
                      options={{
                        paging: false,
                        defaultExpanded: false,
                        // grouping: true,
                        // paginationType: "stepped",
                        // pageSize: 10,
                        maxBodyHeight: 600,
                        headerStyle: {
                          backgroundColor: "#f9f9f9",
                          maxWidth: 200,
                          fontFamily: "Poppins",
                          fontSize: 14,
                          fontWeight: 400,
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: "0.65",
                          letterSpacing: "normal",
                          textAlign: "left",
                          color: "#42352d",
                        },
                        cellStyle: {
                          fontFamily: "Poppins",
                          textAlign: "left",
                          height: "20px",
                        },
                        rowStyle: {
                          maxHeight: 15,
                          cellStyle: {
                            paddingTop: "2px",
                          },
                        },
                        search: true,
                        draggable: false,
                        actionsColumnIndex: -1,
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
