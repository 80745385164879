import React from 'react';
import api from '../../api/api';
import CookieHandler from '../common/CookieHandler';

const Cookie = new CookieHandler();

export default function BusinessApproveButton({ costingID, costingType, callbackFn, }) {

  const approveHandler = () => {
    let request = {
      login_user_id: Cookie.getCookie("loginUserId"),
      idtoken: Cookie.getIdTokenCookie(),
      costing_id: costingID,
      costing_type: costingType,
      approval_status: "Approved",
    }

    api.approve_costing_api(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === '200') {
            callbackFn();
          }
          else if (res.code === '607') {
            window.location.href = '/logout';
          }
        })
      }
    })

  }


  // const {accessInfo} = accessInfo || {};

  return (
    <div
      className="col-lg-2 text-right float-sm-left"
      style={{ padding: "5px 20px" }}
    >
      <button
        className="header_button header_button_text add_button_adjustment"
        style={{ width: "auto", marginTop: "17px" }}
        onClick={approveHandler}
      >
        Approve
      </button>
    </div>
  )
}
