import React, { Component } from "react";

import SideBar from "../../common/SideBar";
import {
  MenuItem,
  TextField,
  FormControl,
  ListItemText,
  Select,
  Checkbox,
  Snackbar,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import CookieHandler from "../../common/CookieHandler";
import Header from "../../common/Header";
import AddCircle from "@material-ui/icons/AddCircle";
import api from "../../../api/api";
import config from "../../../config/config";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Alert } from "@material-ui/lab";
import FileUpload from "../../common/FileUpload";

import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import EventIcon from "@material-ui/icons/Event";
import { withStyles } from "@material-ui/core/styles";
import { dateFormateToDB, localDateFormate } from "../../common/common";
import QualityView from "../Qualities/QualitiesView";
import PurchaseQuality from "../Qualities/PurchaseQualities";
import Loader from "../../common/Loader";
import { SPECFICIATIONS } from "../constants";

const CheckBox = withStyles({
  root: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

/* <!-------------------------- Class Component  ----------------------------------->*/

export default class PurchaseAddendum extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      contract_date: null,
      vendor: "0",
      old_contract_no: "",
      stevedore_price: "",
      pnbp: "",
      barging_price: "",
      vendorsData: [],
      vendorsDataforMine: [],
      purchaseTypeRows: [
        {
          quantity_allocated: 0,
          barge_delivered_quantity: 0,
          quality: "",
          quantity_in_mt: "",
          tolerance: "",
          purchase_types: [
            {
              mine: "",
              purchase_type: "0",
              anchorage_name: "",
              price_pmt: "",
              is_index_linked: "No",
              purchase_typeError: false,
              price_pmtError: false,
              coal_index_type: "Weekly",
              index_ids: [],
              average_coal_index_price: 0,
              prorata_gar: "0",
              prorata_coal_index_price: 0,
              bonus_coal_index_pmt: "",
              penality_coal_index_pmt: "",
              derivered_coal_index_pmt: "",
              final_coal_index_pmt: "",
              coal_index_remarks: "",
            },
          ],
          qualityError: false,
          quantity_in_mtError: false,
          ToleranceError: false,
          anchorage_nameError: false,
          purchasing_term: "0",
          specification_standard: "0",
          astmClone: false,
          specifications: SPECFICIATIONS
        },
      ],
      LaycanData: [{ s_no: "1", laycan: "", laycan_quantity: "" }],
      purchasing_term: "0",
      analysisTypeValues: ["ASTM", "ISO"],
      supplierSurveyorName: [],
      supplierSurveyorAnalysisType: [],
      saiiSurveyorName: [],
      saiiSurveyorAnalysisType: [],
      jointSurveyorName: [],
      jointSurveyorAnalysisType: [],
      vendorsDataList: [],
      surveyorsData: [],
      currency: "0",
      pol: "",
      share_point_doc_link: "",
      vessel_type: "0",
      currencyData: [],
      clickedCurrency: "",
      newCurrency: "",
      vendorTypeError: false,
      currencyError: false,
      PolError: false,
      vesselTypeError: false,
      filteredVendor: [],

      activeTabIndex: false,
      ISOactiveTabIndex: false,
      isLoading: true,

      features: [],
      files: [
        {
          file_description: "",
          file_name: "",
          file_extension: "",
          file_content: "",
          feature_name: "Purchase_Contract",
        },
      ],
      activeStep: 0,
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (!(features.includes("9") || features.includes("97"))) {
      window.location.href = "/dashboard";
    }
    const idToken = this.Cookie.getIdTokenCookie();
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const status = "Active";
    // for get_Currencies
    await api.getCurrencies(loginUserID, idToken).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
            if (res.Currency) {
              var currencies = [
                <MenuItem value="0" key={-1} disabled>
                  Please Select
                </MenuItem>,
              ];
              for (var i in res.Currency) {
                currencies.push(
                  <MenuItem value={res.Currency[i].currency} key={[i]}>
                    {res.Currency[i].currency}
                  </MenuItem>
                );
              }
              this.setState({
                currencyData: currencies,
              });
            } else {
              alert(
                "Unexpected error occured. Please contact administrator."
              );
            }
          } else if (res.code === "601") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });

    await api.getVendors(loginUserID, idToken, status).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);

            if (res.vendors) {
              const vendors = [];
              const surveyors = [];
              var vendorsDataforMine = [];
              for (var i in res.vendors) {
                if (res.vendors[i].vendor_type === "Coal") {
                  vendors.push({
                    name: res.vendors[i].vendor_name,
                    value: res.vendors[i].id,
                    key: i,
                  });
                  vendorsDataforMine.push({
                    mine_name: res.vendors[i].vendor_name,
                    value: res.vendors[i].id,
                    key: i,
                  });
                }
                if (res.vendors[i].vendor_type === "Surveyor") {
                  surveyors.push({
                    name: res.vendors[i].vendor_name,
                    value: res.vendors[i].id,
                    key: i,
                  });
                }
              }
              this.setState({
                surveyorsData: surveyors,
                vendorsData: vendors,
                vendorsDataList: vendors,
                vendorsDataforMine: vendorsDataforMine,
                //isLoading: false
              });
              //this.getPurchaseContractId();
            } else {
              alert(
                "Unexpected error occured. Please contact administrator."
              );
            }
          } else if (res.code === "601") {
            this.setState({
              isLoading: false,
              snackBarErrorOpen: true,
              errorMsg: res.message,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });

    try {
      var purchase_contract_id = decodeURIComponent(
        window.atob(this.props.match.params.PurchaseID)
      );
      this.setState({ purchaseContractID: purchase_contract_id });
    } catch (e) {
      window.location.href = "/Pagenotfound";
    }
    await api
      .getPurchaseContract(loginUserID, purchase_contract_id, idToken, status)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
              if (res.purchase_contract) {
                // TODO: <!-------------- Filtering the Vendor name and Mine Name ---------------!>
                let filteredVendor = null;
                for (var i = 0; i < this.state.vendorsData.length; i++) {
                  if (
                    res.purchase_contract.vendor ===
                    this.state.vendorsData[i].value
                  ) {
                    // filteredVendor= {'name':this.state.vendorsData[i].name, 'value':this.state.vendorsData[i].value}
                    filteredVendor = {
                      name: this.state.vendorsData[i].name,
                      value: this.state.vendorsData[i].value,
                    };
                  }
                }
                //TODO: <!--------------------------- disabling the specification (ASTM) DropDown Duplicate values ------------!>

                for (var j of res.purchase_contract.qualities) {
                  if (j.quality_calorific_value_basis === "ARB") {
                    j.ARBitem = true;
                  } else if (j.quality_calorific_value_basis === "ADB") {
                    this.setState({ ADBitem: true });
                    j.ADBitem = true;
                  } else if (j.quality_calorific_value_basis === "DB") {
                    j.DBitem = true;
                  } else if (j.quality_calorific_value_basis === "DAF") {
                    j.DAFitem = true;
                  } else if (j.quality_calorific_value_basis === "NAR") {
                    j.NARitem = true;
                  }

                  if (j.quality_calorific_value2_basis === "ARB") {
                    j.ARBitem = true;
                  } else if (j.quality_calorific_value2_basis === "ADB") {
                    j.ADBitem = true;
                  } else if (j.quality_calorific_value2_basis === "DB") {
                    j.DBitem = true;
                  } else if (j.quality_calorific_value2_basis === "DAF") {
                    j.DBitem = true;
                  } else if (j.quality_calorific_value2_basis === "NAR") {
                    j.DBitem = true;
                  }

                  if (j.quality_calorific_value3_basis === "ARB") {
                    j.ARBitem = true;
                  } else if (j.quality_calorific_value3_basis === "ADB") {
                    j.ADBitem = true;
                  } else if (j.quality_calorific_value3_basis === "DB") {
                    j.DBitem = true;
                  } else if (j.quality_calorific_value3_basis === "DAF") {
                    j.DAFitem = true;
                  } else if (j.quality_calorific_value3_basis === "NAR") {
                    j.NARitem = true;
                  }
                  //TODO: <!--------------------------- disabling the specification (ISO) DropDown Duplicate values ------------!>

                  if (j.quality_calorific_value_basis_iso === "ARB") {
                    j.Iso_ARBitem = true;
                  } else if (j.quality_calorific_value_basis_iso === "ADB") {
                    j.Iso_ADBitem = true;
                  } else if (j.quality_calorific_value_basis_iso === "DB") {
                    j.Iso_DBitem = true;
                  } else if (j.quality_calorific_value_basis_iso === "DAF") {
                    j.Iso_DAFitem = true;
                  } else if (j.quality_calorific_value_basis_iso === "NAR") {
                    j.Iso_NARitem = true;
                  }

                  if (j.quality_calorific_value2_basis_iso === "ARB") {
                    j.Iso_ARBitem = true;
                  } else if (j.quality_calorific_value2_basis_iso === "ADB") {
                    j.Iso_DBitem = true;
                  } else if (j.quality_calorific_value2_basis_iso === "DB") {
                    j.Iso_DBitem = true;
                  } else if (j.quality_calorific_value2_basis_iso === "DAF") {
                    this.setState({ Iso_DAFitem: true });
                    j.Iso_DAFitem = true;
                  } else if (j.quality_calorific_value2_basis_iso === "NAR") {
                    j.Iso_NARitem = true;
                  }

                  if (j.quality_calorific_value3_basis_iso === "ARB") {
                    j.Iso_ARBitem = true;
                  } else if (j.quality_calorific_value3_basis_iso === "ADB") {
                    j.Iso_ADBitem = true;
                  } else if (j.quality_calorific_value3_basis_iso === "DB") {
                    j.Iso_DBitem = true;
                  } else if (j.quality_calorific_value3_basis_iso === "DAF") {
                    j.Iso_DAFitem = true;
                  } else if (j.quality_calorific_value3_basis_iso === "NAR") {
                    j.Iso_NARitem = true;
                  }
                }

                //TODO: <!--------------------------- Inserting the values into LaycanData ------------!>
                const LaycanDataRows = [];
                if (
                  res.purchase_contract.laycan_1 ||
                  res.purchase_contract.laycan_quantity_1
                ) {
                  LaycanDataRows.push({
                    s_no: "1",
                    laycan: res.purchase_contract.laycan_1,
                    laycan_quantity: res.purchase_contract.laycan_quantity_1,
                  });
                }
                if (
                  res.purchase_contract.laycan_2 ||
                  res.purchase_contract.laycan_quantity_2
                ) {
                  LaycanDataRows.push({
                    s_no: "2",
                    laycan: res.purchase_contract.laycan_2,
                    laycan_quantity: res.purchase_contract.laycan_quantity_2,
                  });
                }
                if (
                  res.purchase_contract.laycan_3 ||
                  res.purchase_contract.laycan_quantity_3
                ) {
                  LaycanDataRows.push({
                    s_no: "3",
                    laycan: res.purchase_contract.laycan_3,
                    laycan_quantity: res.purchase_contract.laycan_quantity_3,
                  });
                }
                if (
                  res.purchase_contract.laycan_4 ||
                  res.purchase_contract.laycan_quantity_4
                ) {
                  LaycanDataRows.push({
                    s_no: "4",
                    laycan: res.purchase_contract.laycan_4,
                    laycan_quantity: res.purchase_contract.laycan_quantity_4,
                  });
                }
                if (
                  res.purchase_contract.laycan_5 ||
                  res.purchase_contract.laycan_quantity_5
                ) {
                  LaycanDataRows.push({
                    s_no: "5",
                    laycan: res.purchase_contract.laycan_5,
                    laycan_quantity: res.purchase_contract.laycan_quantity_5,
                  });
                }
                if (LaycanDataRows.length === "0") {
                  LaycanDataRows.push({
                    s_no: "1",
                    laycan: "",
                    laycan_quantity: "",
                  });
                }

                // TODO :<!----------------- Filtering the supplier / SAII / Joint Surveyor Name -----------!>
                var filteredSaiiSurveyorName = [];
                if (res.purchase_contract.internal_surveyor) {
                  var resInternalsurveyorData =
                    res.purchase_contract.internal_surveyor;
                  let result = resInternalsurveyorData.split(",");
                  for (let i = 0; i < this.state.surveyorsData.length; i++) {
                    for (let j = 0; j < result.length; j++) {
                      if (result[j] === this.state.surveyorsData[i].value) {
                        filteredSaiiSurveyorName.push(
                          this.state.surveyorsData[i].name
                        );
                      }
                    }
                  }
                }
                var filteredSuppSurveyorName = [];
                if (res.purchase_contract.supplier_surveyor) {
                  var resSupplierSurveyorData =
                    res.purchase_contract.supplier_surveyor;
                  let result = resSupplierSurveyorData.split(",");
                  for (let i = 0; i < this.state.surveyorsData.length; i++) {
                    for (let j = 0; j < result.length; j++) {
                      if (result[j] === this.state.surveyorsData[i].value) {
                        filteredSuppSurveyorName.push(
                          this.state.surveyorsData[i].name
                        );
                      }
                    }
                  }
                }
                var filteredJointSurveyorName = [];
                if (res.purchase_contract.joint_surveyor) {
                  var resjointSurveyorData =
                    res.purchase_contract.joint_surveyor;
                  let result = resjointSurveyorData.split(",");
                  for (let i = 0; i < this.state.surveyorsData.length; i++) {
                    for (let j = 0; j < result.length; j++) {
                      if (result[j] === this.state.surveyorsData[i].value) {
                        filteredJointSurveyorName.push(
                          this.state.surveyorsData[i].name
                        );
                      }
                    }
                  }
                }
                // TODO :<!----------------- Filtering the supplier / SAII / Joint Surveyor Analysis Types  -----------!>
                if (res.purchase_contract.internal_surveyor_analysis_type) {
                  var value1 =
                    res.purchase_contract.internal_surveyor_analysis_type;
                  var data1 = value1.split(",");
                } else {
                  data1 = [];
                }
                if (res.purchase_contract.supplier_surveyor_analysis_type) {
                  var value2 =
                    res.purchase_contract.supplier_surveyor_analysis_type;
                  var data2 = value2.split(",");
                } else {
                  data2 = [];
                }
                if (res.purchase_contract.joint_surveyor_analysis_type) {
                  var value3 =
                    res.purchase_contract.joint_surveyor_analysis_type;
                  var data3 = value3.split(",");
                } else {
                  data3 = [];
                }
                for (var k in res.purchase_contract.qualities) {
                  res.purchase_contract.qualities[k].quantity_adjusted =
                    res.purchase_contract.qualities[k].quantity_in_mt;
                  res.purchase_contract.qualities[k].tolerance_adjusted =
                    res.purchase_contract.qualities[k].tolerance;
                }
                var specData = res.purchase_contract.qualities;
                for (let k = 0; k < specData.length; k++) {
                  if (specData[k].specifications === false) {
                    specData[k].specifications =
                      this.state.purchaseTypeRows[0].specifications;
                  } else {
                    Object.keys(specData[k].specifications).forEach(function (
                      key
                    ) {
                      if (
                        specData[k].specifications
                          .quality_calorific_value_basis === null
                      ) {
                        specData[
                          k
                        ].specifications.quality_calorific_value_basis = "0";
                      }
                      if (
                        specData[k].specifications
                          .quality_calorific_value2_basis === null
                      ) {
                        specData[
                          k
                        ].specifications.quality_calorific_value2_basis = "0";
                      }
                      if (
                        specData[k].specifications
                          .quality_calorific_value3_basis === null
                      ) {
                        specData[
                          k
                        ].specifications.quality_calorific_value3_basis = "0";
                      }
                      if (
                        specData[k].specifications
                          .quality_total_moisture_basis === null
                      ) {
                        specData[
                          k
                        ].specifications.quality_total_moisture_basis = "0";
                      }
                      if (
                        specData[k].specifications
                          .quality_inherent_moisture_basis === null
                      ) {
                        specData[
                          k
                        ].specifications.quality_inherent_moisture_basis =
                          "0";
                      }
                      if (
                        specData[k].specifications.quality_ash_basis === null
                      ) {
                        specData[k].specifications.quality_ash_basis = "0";
                      }
                      if (
                        specData[k].specifications
                          .quality_volatile_matter_basis === null
                      ) {
                        specData[
                          k
                        ].specifications.quality_volatile_matter_basis = "0";
                      }
                      if (
                        specData[k].specifications
                          .quality_fixed_carbon_basis === null
                      ) {
                        specData[
                          k
                        ].specifications.quality_fixed_carbon_basis = "0";
                      }
                      if (
                        specData[k].specifications
                          .quality_fuel_ratio_basis === null
                      ) {
                        specData[k].specifications.quality_fuel_ratio_basis =
                          "0";
                      }
                      if (
                        specData[k].specifications
                          .quality_total_sulphur_basis === null
                      ) {
                        specData[
                          k
                        ].specifications.quality_total_sulphur_basis = "0";
                      }
                      if (
                        specData[k].specifications.ultimate_carbon_basis ===
                        null
                      ) {
                        specData[k].specifications.ultimate_carbon_basis =
                          "0";
                      }
                      if (
                        specData[k].specifications.ultimate_hydrogen_basis ===
                        null
                      ) {
                        specData[k].specifications.ultimate_hydrogen_basis =
                          "0";
                      }
                      if (
                        specData[k].specifications.ultimate_nitrogen_basis ===
                        null
                      ) {
                        specData[k].specifications.ultimate_nitrogen_basis =
                          "0";
                      }
                      if (
                        specData[k].specifications.ash_sio2_basis === null
                      ) {
                        specData[k].specifications.ash_sio2_basis = "0";
                      }
                      if (
                        specData[k].specifications.ash_ai203_basis === null
                      ) {
                        specData[k].specifications.ash_ai203_basis = "0";
                      }
                      if (
                        specData[k].specifications.ash_sio2_basis === null
                      ) {
                        specData[k].specifications.ash_sio2_basis = "0";
                      }
                      if (
                        specData[k].specifications.ash_fe203_basis === null
                      ) {
                        specData[k].specifications.ash_fe203_basis = "0";
                      }
                      if (specData[k].specifications.ash_cao_basis === null) {
                        specData[k].specifications.ash_cao_basis = "0";
                      }
                      if (specData[k].specifications.ash_mgo_basis === null) {
                        specData[k].specifications.ash_mgo_basis = "0";
                      }
                      if (
                        specData[k].specifications.ash_na2o_basis === null
                      ) {
                        specData[k].specifications.ash_na2o_basis = "0";
                      }
                      if (specData[k].specifications.ash_k2o_basis === null) {
                        specData[k].specifications.ash_k2o_basis = "0";
                      }
                      if (
                        specData[k].specifications.ash_tio2_basis === null
                      ) {
                        specData[k].specifications.ash_tio2_basis = "0";
                      }
                      if (
                        specData[k].specifications.ash_mn3o4_basis === null
                      ) {
                        specData[k].specifications.ash_mn3o4_basis = "0";
                      }
                      if (specData[k].specifications.ash_so3_basis === null) {
                        specData[k].specifications.ash_so3_basis = "0";
                      }
                      if (
                        specData[k].specifications.ash_p2o5_basis === null
                      ) {
                        specData[k].specifications.ash_p2o5_basis = "0";
                      }
                      if (
                        specData[k].specifications.trace_arsenic_basis ===
                        null
                      ) {
                        specData[k].specifications.trace_arsenic_basis = "0";
                      }
                      if (
                        specData[k].specifications.trace_mercury_basis ===
                        null
                      ) {
                        specData[k].specifications.trace_mercury_basis = "0";
                      }
                      if (
                        specData[k].specifications.trace_selenium_basis ===
                        null
                      ) {
                        specData[k].specifications.trace_selenium_basis = "0";
                      }
                      if (
                        specData[k].specifications.trace_boron_basis === null
                      ) {
                        specData[k].specifications.trace_boron_basis = "0";
                      }
                      if (
                        specData[k].specifications.minor_ba_basis === null
                      ) {
                        specData[k].specifications.minor_ba_basis = "0";
                      }
                      if (
                        specData[k].specifications.minor_phosphorous_basis ===
                        null
                      ) {
                        specData[k].specifications.minor_phosphorous_basis =
                          "0";
                      }
                      if (
                        specData[k].specifications
                          .quality_calorific_value_basis_iso === null
                      ) {
                        specData[
                          k
                        ].specifications.quality_calorific_value_basis_iso =
                          "0";
                      }
                      if (
                        specData[k].specifications
                          .quality_calorific_value2_basis_iso === null
                      ) {
                        specData[
                          k
                        ].specifications.quality_calorific_value2_basis_iso =
                          "0";
                      }
                      if (
                        specData[k].specifications
                          .quality_calorific_value3_basis_iso === null
                      ) {
                        specData[
                          k
                        ].specifications.quality_calorific_value3_basis_iso =
                          "0";
                      }
                      if (
                        specData[k].specifications
                          .quality_total_moisture_basis_iso === null
                      ) {
                        specData[
                          k
                        ].specifications.quality_total_moisture_basis_iso =
                          "0";
                      }
                      if (
                        specData[k].specifications
                          .quality_inherent_moisture_basis_iso === null
                      ) {
                        specData[
                          k
                        ].specifications.quality_inherent_moisture_basis_iso =
                          "0";
                      }
                      if (
                        specData[k].specifications.quality_ash_basis_iso ===
                        null
                      ) {
                        specData[k].specifications.quality_ash_basis_iso =
                          "0";
                      }
                      if (
                        specData[k].specifications
                          .quality_volatile_matter_basis_iso === null
                      ) {
                        specData[
                          k
                        ].specifications.quality_volatile_matter_basis_iso =
                          "0";
                      }
                      if (
                        specData[k].specifications
                          .quality_fixed_carbon_basis_iso === null
                      ) {
                        specData[
                          k
                        ].specifications.quality_fixed_carbon_basis_iso = "0";
                      }
                      if (
                        specData[k].specifications
                          .quality_fuel_ratio_basis_iso === null
                      ) {
                        specData[
                          k
                        ].specifications.quality_fuel_ratio_basis_iso = "0";
                      }
                      if (
                        specData[k].specifications
                          .quality_total_sulphur_basis_iso === null
                      ) {
                        specData[
                          k
                        ].specifications.quality_total_sulphur_basis_iso =
                          "0";
                      }
                      if (
                        specData[k].specifications
                          .ultimate_carbon_basis_iso === null
                      ) {
                        specData[k].specifications.ultimate_carbon_basis_iso =
                          "0";
                      }
                      if (
                        specData[k].specifications
                          .ultimate_hydrogen_basis_iso === null
                      ) {
                        specData[
                          k
                        ].specifications.ultimate_hydrogen_basis_iso = "0";
                      }
                      if (
                        specData[k].specifications
                          .ultimate_nitrogen_basis_iso === null
                      ) {
                        specData[
                          k
                        ].specifications.ultimate_nitrogen_basis_iso = "0";
                      }
                      if (
                        specData[k].specifications.ash_sio2_basis_iso === null
                      ) {
                        specData[k].specifications.ash_sio2_basis_iso = "0";
                      }
                      if (
                        specData[k].specifications.ash_ai203_basis_iso ===
                        null
                      ) {
                        specData[k].specifications.ash_ai203_basis_iso = "0";
                      }
                      if (
                        specData[k].specifications.ash_sio2_basis_iso === null
                      ) {
                        specData[k].specifications.ash_sio2_basis_iso = "0";
                      }
                      if (
                        specData[k].specifications.ash_fe203_basis_iso ===
                        null
                      ) {
                        specData[k].specifications.ash_fe203_basis_iso = "0";
                      }
                      if (
                        specData[k].specifications.ash_cao_basis_iso === null
                      ) {
                        specData[k].specifications.ash_cao_basis_iso = "0";
                      }
                      if (
                        specData[k].specifications.ash_mgo_basis_iso === null
                      ) {
                        specData[k].specifications.ash_mgo_basis_iso = "0";
                      }
                      if (
                        specData[k].specifications.ash_na2o_basis_iso === null
                      ) {
                        specData[k].specifications.ash_na2o_basis_iso = "0";
                      }
                      if (
                        specData[k].specifications.ash_k2o_basis_iso === null
                      ) {
                        specData[k].specifications.ash_k2o_basis_iso = "0";
                      }
                      if (
                        specData[k].specifications.ash_tio2_basis_iso === null
                      ) {
                        specData[k].specifications.ash_tio2_basis_iso = "0";
                      }
                      if (
                        specData[k].specifications.ash_mn3o4_basis_iso ===
                        null
                      ) {
                        specData[k].specifications.ash_mn3o4_basis_iso = "0";
                      }
                      if (
                        specData[k].specifications.ash_so3_basis_iso === null
                      ) {
                        specData[k].specifications.ash_so3_basis_iso = "0";
                      }
                      if (
                        specData[k].specifications.ash_p2o5_basis_iso === null
                      ) {
                        specData[k].specifications.ash_p2o5_basis_iso = "0";
                      }
                      if (
                        specData[k].specifications.trace_arsenic_basis_iso ===
                        null
                      ) {
                        specData[k].specifications.trace_arsenic_basis_iso =
                          "0";
                      }
                      if (
                        specData[k].specifications.trace_mercury_basis_iso ===
                        null
                      ) {
                        specData[k].specifications.trace_mercury_basis_iso =
                          "0";
                      }
                      if (
                        specData[k].specifications
                          .trace_selenium_basis_iso === null
                      ) {
                        specData[k].specifications.trace_selenium_basis_iso =
                          "0";
                      }
                      if (
                        specData[k].specifications.trace_boron_basis_iso ===
                        null
                      ) {
                        specData[k].specifications.trace_boron_basis_iso =
                          "0";
                      }
                      if (
                        specData[k].specifications.minor_ba_basis_iso === null
                      ) {
                        specData[k].specifications.minor_ba_basis_iso = "0";
                      }
                      if (
                        specData[k].specifications
                          .minor_phosphorous_basis_iso === null
                      ) {
                        specData[
                          k
                        ].specifications.minor_phosphorous_basis_iso = "0";
                      }
                      if (specData[k].specifications[key] === null) {
                        specData[k].specifications[key] = "";
                      }
                      if (!specData[k].specifications.activeTabIndex) {
                        specData[k].specifications.activeTabIndex = false;
                      }
                      if (!specData[k].specifications.ISOactiveTabIndex) {
                        specData[k].specifications.ISOactiveTabIndex = false;
                      }
                      if (!specData[k].specifications.ARBitem) {
                        specData[k].specifications.ARBitem = false;
                      }
                      if (!specData[k].specifications.ADBitem) {
                        specData[k].specifications.ADBitem = false;
                      }
                      if (!specData[k].specifications.DAFitem) {
                        specData[k].specifications.DAFitem = false;
                      }
                      if (!specData[k].specifications.DBitem) {
                        specData[k].specifications.DBitem = false;
                      }
                      if (!specData[k].specifications.NARitem) {
                        specData[k].specifications.NARitem = false;
                      }
                      if (!specData[k].specifications.Iso_ARBitem) {
                        specData[k].specifications.Iso_ARBitem = false;
                      }
                      if (!specData[k].specifications.Iso_ADBitem) {
                        specData[k].specifications.Iso_ADBitem = false;
                      }
                      if (!specData[k].specifications.Iso_DAFitem) {
                        specData[k].specifications.Iso_DAFitem = false;
                      }
                      if (!specData[k].specifications.Iso_DBitem) {
                        specData[k].specifications.Iso_DBitem = false;
                      }
                      if (!specData[k].specifications.Iso_NARitem) {
                        specData[k].specifications.Iso_NARitem = false;
                      }
                    });
                  }
                }


                this.setState((prevState) => ({
                  ...prevState,
                  filteredVendor: filteredVendor,
                  supplierSurveyorName: filteredSuppSurveyorName,
                  supplierSurveyorAnalysisType: data2,
                  saiiSurveyorName: filteredSaiiSurveyorName,
                  saiiSurveyorAnalysisType: data1,
                  jointSurveyorName: filteredJointSurveyorName,
                  jointSurveyorAnalysisType: data3,
                  LaycanData: LaycanDataRows,
                  purchaseTypeRows: specData,
                  ...res.purchase_contract,
                  isLoading: false,
                }))

              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });

    document.title = config.documentTitle + "Purchase Contract Addendum";
  }

  handleAddCurrency = () => {
    var idToken = this.Cookie.getIdTokenCookie();
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var newCurrency = this.state.newCurrency;
    var clickedCurrency = this.state.clickedCurrency;
    var status = "Active";
    if (newCurrency === "") {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please enter Currency",
        modalCurrencyError: true,
      });
      return;
    }
    api
      .addCurrency(loginUserID, idToken, newCurrency, status)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState({
                successMsg: res.message,
                snackBarSuccessOpen: true,
                currencyError: false,
              });
              window.$("#myModal").modal("hide");
              if (res.id) {
                api.getCurrencies(loginUserID, idToken).then((response) => {
                  if (response.status >= 200 && response.status < 300) {
                    response.json().then((res) => {
                      if (res.code === "200") {
                        var currencies = [
                          <MenuItem value="0" key={-1} disabled>
                            Please Select
                          </MenuItem>,
                        ];
                        for (var i in res.Currency) {
                          currencies.push(
                            <MenuItem value={res.Currency[i].currency} key={i}>
                              {res.Currency[i].currency}
                            </MenuItem>
                          );
                        }
                        this.setState({
                          currencyData: currencies,
                        });
                        if (clickedCurrency === "Currency") {
                          this.setState({
                            currency: newCurrency.toUpperCase(),
                            newCurrency: "",
                          });
                        }
                      } else if (res.code === "607") {
                        window.location.href = "/logout";
                      }
                    });
                  }
                });
              }
            }
            if (res.code === "601") {
              this.setState({ errorMsg: res.message, snackBarErrorOpen: true });
            }
            if (res.code === "602") {
              //alert(res.message);
              this.setState({
                errorMsg: res.message,
                snackBarErrorOpen: true,
                modalCurrencyError: true,
              });
            }
            if (res.code === "607") {
              window.location.href = "/logout";
            }
            if (res.code === "624") {
              this.setState({
                errorMsg: "Currency is not valid.",
                snackBarErrorOpen: true,
                modalCurrencyError: true,
              });
            }
          });
        }
      });
  };

  handleDecimalField = (e) => {
    const re = /^\d*(\.\d{0,4})?$/;
    if (e.target.name === "stevedore_price") {
      if (e.target.value === "" || re.test(e.target.value)) {
        this.setState({
          [e.target.name]: e.target.value,
        });
      }
    }
    if (e.target.name === "pnbp") {
      if (e.target.value === "" || re.test(e.target.value)) {
        this.setState({
          [e.target.name]: e.target.value,
        });
      }
    }
    if (e.target.name === "barging_price") {
      if (e.target.value === "" || re.test(e.target.value)) {
        this.setState({
          [e.target.name]: e.target.value,
        });
      }
    }
  };

  handleTextChange = (e) => {
    if (e.target.name === "pol") {
      this.setState({
        PolError: false,
      });
    }
    if (e.target.name === "currency") {
      this.setState({
        currencyError: false,
        modalCurrencyError: false,
      });
    } else if (e.target.name === "newCurrency") {
      this.setState({
        [e.target.name]: e.target.value,
        modalCurrencyError: false,
      });
    }
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleText = (e, idx) => {
    var rows = this.state.purchaseTypeRows;
    if (rows[idx][e.target.name] === "purchasing_term") {
      rows[idx][e.target.name] = e.target.value;
      rows[idx].purchasingTermError = false;
      this.setState({ purchaseTypeRows: rows });
    } else if (rows[idx][e.target.name] === "currency") {
      rows[idx][e.target.name] = e.target.value;
      rows[idx].currencyError = false;
      this.setState({ purchaseTypeRows: rows });
    } else if (rows[idx][e.target.name] === "pol") {
      rows[idx][e.target.name] = e.target.value;
      rows[idx].PolError = false;
      this.setState({ purchaseTypeRows: rows });
    } else if (rows[idx][e.target.name] === "vessel_type") {
      rows[idx][e.target.name] = e.target.value;
      rows[idx].vesselTypeError = false;
      this.setState({ purchaseTypeRows: rows });
    } else if (rows[idx][e.target.name] === "newCurrency") {
      rows[idx][e.target.name] = e.target.value;
      rows[idx].modalCurrencyError = false;
      this.setState({ purchaseTypeRows: rows });
      this.setState({
        errorMsg: "",
      });
    } else {
      rows[idx].specifications[e.target.name] = e.target.value;
      this.setState({ purchaseTypeRows: rows });
    }
  };

  laycanTextHandler = (idx) => (e) => {
    var name = e.target.name;
    var rows = this.state.LaycanData;
    if (name === "laycan") {
      rows[idx].laycan = e.target.value;
    }
    if (name === "laycan_quantity") {
      const re = /^\d*(\.\d{0,3})?$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        rows[idx].laycan_quantity = e.target.value;
      }
    }
    this.setState({
      LaycanData: rows,
      laycan1Error: false,
    });
  };

  handleLaycanRows = () => {
    const items = {
      s_no: JSON.stringify(this.state.LaycanData + 1),
      laycan: "",
      laycan_quantity: "",
    };
    this.setState({
      LaycanData: [...this.state.LaycanData, items],
    });
  };

  handleRemoveLaycanRows = (idx) => () => {
    const rows = this.state.LaycanData;
    rows.splice(idx, 1);
    this.setState({
      LaycanData: rows,
    });
  };

  MultiSelectHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  submitHandler = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    var {
      vendor,
      old_contract_no,
      supplier_ref_no,
      contract_date,
      purchaseTypeRows,
      stevedore_price,
      pbnp,
      barging_price,
      currency,
      pol,
      vessel_type,
      payment_adjustment,
      payment_terms_1,
      payment_terms_2,
      remarks,
      share_point_doc_link,
      is_index_linked,
      shipping_terms,
    } = this.state;

    // TODO:<!------------------ Converting Array Supplier / SAII / Joint to , Seperated Values -------!>
    var supplierSurveyorNames = "";
    for (let i = 0; i < this.state.supplierSurveyorName.length; i++) {
      for (let j = 0; j < this.state.surveyorsData.length; j++) {
        if (
          this.state.supplierSurveyorName[i] ===
          this.state.surveyorsData[j].name
        ) {
          supplierSurveyorNames += this.state.surveyorsData[j].value + ",";
        }
      }
    }
    var supplierSurveyorName = supplierSurveyorNames.slice(0, -1);
    var supplierSurveyorAnalysisTypes = "";
    for (let i = 0; i < this.state.supplierSurveyorAnalysisType.length; i++) {
      supplierSurveyorAnalysisTypes +=
        this.state.supplierSurveyorAnalysisType[i] + ",";
    }
    var supplierSurveyorAnalysisType = supplierSurveyorAnalysisTypes.slice(
      0,
      -1
    );
    var saiiSurveyorNames = "";
    for (let i = 0; i < this.state.saiiSurveyorName.length; i++) {
      for (let j = 0; j < this.state.surveyorsData.length; j++) {
        if (
          this.state.saiiSurveyorName[i] === this.state.surveyorsData[j].name
        ) {
          saiiSurveyorNames += this.state.surveyorsData[j].value + ",";
        }
      }
    }
    var saiiSurveyorName = saiiSurveyorNames.slice(0, -1);
    var saiiSurveyorAnalysisTypes = "";
    for (let i = 0; i < this.state.saiiSurveyorAnalysisType.length; i++) {
      saiiSurveyorAnalysisTypes += this.state.saiiSurveyorAnalysisType[i] + ",";
    }
    var saiiSurveyorAnalysisType = saiiSurveyorAnalysisTypes.slice(0, -1);
    var jointSurveyorNames = "";
    for (let i = 0; i < this.state.jointSurveyorName.length; i++) {
      for (let j = 0; j < this.state.surveyorsData.length; j++) {
        if (
          this.state.jointSurveyorName[i] === this.state.surveyorsData[j].name
        ) {
          jointSurveyorNames += this.state.surveyorsData[j].value + ",";
        }
      }
    }
    var buyerSurveyorName = jointSurveyorNames.slice(0, -1);
    var jointSurveyorAnysTypes = "";
    for (let i = 0; i < this.state.jointSurveyorAnalysisType.length; i++) {
      jointSurveyorAnysTypes += this.state.jointSurveyorAnalysisType[i] + ",";
    }
    var buyerSurveyorAnalysisType = jointSurveyorAnysTypes.slice(0, -1);

    //TODO:<!-------------------- Accessing the LaycanData -------------------------!>
    var laycan1 = "",
      laycan2 = "",
      laycan3 = "",
      laycan4 = "",
      laycan5 = "";
    var laycanQuantity1 = "",
      laycanQuantity2 = "",
      laycanQuantity3 = "",
      laycanQuantity4 = "",
      laycanQuantity5 = "";
    for (var i in this.state.LaycanData) {
      if (i === "0") {
        laycan1 = this.state.LaycanData[i].laycan;
        laycanQuantity1 = this.state.LaycanData[i].laycan_quantity;
      }
      if (i === "1") {
        laycan2 = this.state.LaycanData[i].laycan;
        laycanQuantity2 = this.state.LaycanData[i].laycan_quantity;
      }
      if (i === "2") {
        laycan3 = this.state.LaycanData[i].laycan;
        laycanQuantity3 = this.state.LaycanData[i].laycan_quantity;
      }
      if (i === "3") {
        laycan4 = this.state.LaycanData[i].laycan;
        laycanQuantity4 = this.state.LaycanData[i].laycan_quantity;
      }
      if (i === "4") {
        laycan5 = this.state.LaycanData[i].laycan;
        laycanQuantity5 = this.state.LaycanData[i].laycan_quantity;
      }
    }
    var purchaseTypeData = purchaseTypeRows;

    /*
    !<!------------------------------- Checking Mandatory Fields ------------------!>
    */

    if (contract_date === null) {
      this.setState({ contractDateError: true, isLoading: false });
      alert("Please fill all mandatory fields.");
      return;
    }

    var rows = purchaseTypeRows;
    var purchase_typesAvailable = 0;
    for (var j in purchaseTypeRows) {
      if (purchaseTypeRows[j].purchasing_term === "Specs") {
        if (purchaseTypeRows[j].quality === "") {
          rows[j].qualityError = true;
          purchase_typesAvailable = purchase_typesAvailable + 1;
        } else {
          var quality = purchaseTypeRows[j].quality;
          purchaseTypeRows[j].quality = quality.toUpperCase();
        }
      }
      if (purchaseTypeRows[j].quantity_in_mt === "") {
        rows[j].quantity_in_mtError = true;
        purchase_typesAvailable = purchase_typesAvailable + 1;
      }
      if (purchaseTypeRows[j].tolerance === "") {
        rows[j].toleranceError = true;
        purchase_typesAvailable = purchase_typesAvailable + 1;
      }
      if (purchaseTypeRows[j].purchasing_term === "0") {
        rows[j].purchasingTermError = true;
        purchase_typesAvailable = purchase_typesAvailable + 1;
      } else {
        if (purchaseTypeRows[j].purchasing_term === "Specs") {
          if (purchaseTypeRows[j].specification_standard === "0") {
            rows[j].specification_standardError = true;
            purchase_typesAvailable = purchase_typesAvailable + 1;
          }
        }
      }

      for (var k in purchaseTypeRows[j].purchase_types) {
        if (purchaseTypeRows[j].purchase_types[k].purchase_type === "0") {
          rows[j].purchase_types[k].purchase_typeError = true;
          this.setState({ isLoading: false, purchaseTypeRows: rows });
          purchase_typesAvailable = purchase_typesAvailable + 1;
        }
        if (purchaseTypeRows[j].purchase_types[k].price_pmt === "") {
          rows[j].purchase_types[k].price_pmtError = true;
          this.setState({ isLoading: false, purchaseTypeRows: rows });
          purchase_typesAvailable = purchase_typesAvailable + 1;
        }
      }
    }

    this.setState({ purchaseTypeRows: rows });
    if (purchase_typesAvailable > 0) {
      this.setState({ isLoading: false });
      alert("Please fill all mandatory fields.");
      return;
    }

    let purchaseQualityRows = this.state.purchaseTypeRows;
    for (var p = 0; p < purchaseQualityRows.length; p++) {
      if (purchaseQualityRows[p].purchasing_term === "0") {
        purchaseQualityRows[p].purchasingTermError = true;
        this.setState({
          isLoading: false,
          purchaseTypeRows: purchaseQualityRows,
        });
        alert("Please fill all mandatory fields.");
        return;
      }
    }
    if (vendor === "") {
      this.setState({ vendorTypeError: true });
    }
    if (laycan1 === "") {
      this.setState({ laycan1Error: true });
    }
    if (currency === "0") {
      this.setState({ currencyError: true });
    }
    if (pol === "") {
      this.setState({ PolError: true });
    }
    if (vessel_type === "0") {
      this.setState({ vesselTypeError: true });
    }

    if (
      this.state.laycan1Error ||
      this.state.currencyError ||
      this.state.PolError ||
      this.state.vesselTypeError
    ) {
      this.setState({ isLoading: false });
      alert("Please fill all mandatory fields.");
      return;
    } else {
      //TODO:<!------------------- if purchase Term as Specs sending empty values ---------- !>
      for (i = 0; i < purchaseTypeData.length; i++) {
        if (
          purchaseTypeData[i].purchasing_term === "Specs" ||
          purchaseTypeData[i].purchasing_term === "Non Specs"
        ) {
          if (
            purchaseTypeData[i].specifications.quality_calorific_value_basis ===
            "0"
          ) {
            purchaseTypeData[i].specifications.quality_calorific_value_basis =
              "";
          }
          if (
            purchaseTypeData[i].specifications
              .quality_calorific_value2_basis === "0"
          ) {
            purchaseTypeData[i].specifications.quality_calorific_value2_basis =
              "";
          }
          if (
            purchaseTypeData[i].specifications
              .quality_calorific_value3_basis === "0"
          ) {
            purchaseTypeData[i].specifications.quality_calorific_value3_basis =
              "";
          }
          if (
            purchaseTypeData[i].specifications.quality_total_moisture_basis ===
            "0"
          ) {
            purchaseTypeData[i].specifications.quality_total_moisture_basis =
              "";
          }
          if (
            purchaseTypeData[i].specifications
              .quality_inherent_moisture_basis === "0"
          ) {
            purchaseTypeData[i].specifications.quality_inherent_moisture_basis =
              "";
          }
          if (purchaseTypeData[i].specifications.quality_ash_basis === "0") {
            purchaseTypeData[i].specifications.quality_ash_basis = "";
          }
          if (
            purchaseTypeData[i].specifications.quality_volatile_matter_basis ===
            "0"
          ) {
            purchaseTypeData[i].specifications.quality_volatile_matter_basis =
              "";
          }
          if (
            purchaseTypeData[i].specifications.quality_fixed_carbon_basis ===
            "0"
          ) {
            purchaseTypeData[i].specifications.quality_fixed_carbon_basis = "";
          }
          if (
            purchaseTypeData[i].specifications.quality_fuel_ratio_basis === "0"
          ) {
            purchaseTypeData[i].specifications.quality_fuel_ratio_basis = "";
          }
          if (
            purchaseTypeData[i].specifications.quality_total_sulphur_basis ===
            "0"
          ) {
            purchaseTypeData[i].specifications.quality_total_sulphur_basis = "";
          }
          if (
            purchaseTypeData[i].specifications.ultimate_carbon_basis === "0"
          ) {
            purchaseTypeData[i].specifications.ultimate_carbon_basis = "";
          }
          if (
            purchaseTypeData[i].specifications.ultimate_hydrogen_basis === "0"
          ) {
            purchaseTypeData[i].specifications.ultimate_hydrogen_basis = "";
          }
          if (
            purchaseTypeData[i].specifications.ultimate_nitrogen_basis === "0"
          ) {
            purchaseTypeData[i].specifications.ultimate_nitrogen_basis = "";
          }
          if (purchaseTypeData[i].specifications.ash_sio2_basis === "0") {
            purchaseTypeData[i].specifications.ash_sio2_basis = "";
          }
          if (purchaseTypeData[i].specifications.ash_ai203_basis === "0") {
            purchaseTypeData[i].specifications.ash_ai203_basis = "";
          }
          if (purchaseTypeData[i].specifications.ash_sio2_basis === "0") {
            purchaseTypeData[i].specifications.ash_sio2_basis = "";
          }
          if (purchaseTypeData[i].specifications.ash_fe203_basis === "0") {
            purchaseTypeData[i].specifications.ash_fe203_basis = "";
          }
          if (purchaseTypeData[i].specifications.ash_cao_basis === "0") {
            purchaseTypeData[i].specifications.ash_cao_basis = "";
          }
          if (purchaseTypeData[i].specifications.ash_mgo_basis === "0") {
            purchaseTypeData[i].specifications.ash_mgo_basis = "";
          }
          if (purchaseTypeData[i].specifications.ash_na2o_basis === "0") {
            purchaseTypeData[i].specifications.ash_na2o_basis = "";
          }
          if (purchaseTypeData[i].specifications.ash_k2o_basis === "0") {
            purchaseTypeData[i].specifications.ash_k2o_basis = "";
          }
          if (purchaseTypeData[i].specifications.ash_tio2_basis === "0") {
            purchaseTypeData[i].specifications.ash_tio2_basis = "";
          }
          if (purchaseTypeData[i].specifications.ash_mn3o4_basis === "0") {
            purchaseTypeData[i].specifications.ash_mn3o4_basis = "";
          }
          if (purchaseTypeData[i].specifications.ash_so3_basis === "0") {
            purchaseTypeData[i].specifications.ash_so3_basis = "";
          }
          if (purchaseTypeData[i].specifications.ash_p2o5_basis === "0") {
            purchaseTypeData[i].specifications.ash_p2o5_basis = "";
          }
          if (purchaseTypeData[i].specifications.trace_arsenic_basis === "0") {
            purchaseTypeData[i].specifications.trace_arsenic_basis = "";
          }
          if (purchaseTypeData[i].specifications.trace_mercury_basis === "0") {
            purchaseTypeData[i].specifications.trace_mercury_basis = "";
          }
          if (purchaseTypeData[i].specifications.trace_selenium_basis === "0") {
            purchaseTypeData[i].specifications.trace_selenium_basis = "";
          }
          if (purchaseTypeData[i].specifications.trace_boron_basis === "0") {
            purchaseTypeData[i].specifications.trace_boron_basis = "";
          }
          if (purchaseTypeData[i].specifications.minor_ba_basis === "0") {
            purchaseTypeData[i].specifications.minor_ba_basis = "";
          }
          if (
            purchaseTypeData[i].specifications.minor_phosphorous_basis === "0"
          ) {
            purchaseTypeData[i].specifications.minor_phosphorous_basis = "";
          }
          if (
            purchaseTypeData[i].specifications
              .quality_calorific_value_basis_iso === "0"
          ) {
            purchaseTypeData[
              i
            ].specifications.quality_calorific_value_basis_iso = "";
          }
          if (
            purchaseTypeData[i].specifications
              .quality_calorific_value2_basis_iso === "0"
          ) {
            purchaseTypeData[
              i
            ].specifications.quality_calorific_value2_basis_iso = "";
          }
          if (
            purchaseTypeData[i].specifications
              .quality_calorific_value3_basis_iso === "0"
          ) {
            purchaseTypeData[
              i
            ].specifications.quality_calorific_value3_basis_iso = "";
          }
          if (
            purchaseTypeData[i].specifications
              .quality_total_moisture_basis_iso === "0"
          ) {
            purchaseTypeData[
              i
            ].specifications.quality_total_moisture_basis_iso = "";
          }
          if (
            purchaseTypeData[i].specifications
              .quality_inherent_moisture_basis_iso === "0"
          ) {
            purchaseTypeData[
              i
            ].specifications.quality_inherent_moisture_basis_iso = "";
          }
          if (
            purchaseTypeData[i].specifications.quality_ash_basis_iso === "0"
          ) {
            purchaseTypeData[i].specifications.quality_ash_basis_iso = "";
          }
          if (
            purchaseTypeData[i].specifications
              .quality_volatile_matter_basis_iso === "0"
          ) {
            purchaseTypeData[
              i
            ].specifications.quality_volatile_matter_basis_iso = "";
          }
          if (
            purchaseTypeData[i].specifications
              .quality_fixed_carbon_basis_iso === "0"
          ) {
            purchaseTypeData[i].specifications.quality_fixed_carbon_basis_iso =
              "";
          }
          if (
            purchaseTypeData[i].specifications.quality_fuel_ratio_basis_iso ===
            "0"
          ) {
            purchaseTypeData[i].specifications.quality_fuel_ratio_basis_iso =
              "";
          }
          if (
            purchaseTypeData[i].specifications
              .quality_total_sulphur_basis_iso === "0"
          ) {
            purchaseTypeData[i].specifications.quality_total_sulphur_basis_iso =
              "";
          }
          if (
            purchaseTypeData[i].specifications.ultimate_carbon_basis_iso === "0"
          ) {
            purchaseTypeData[i].specifications.ultimate_carbon_basis_iso = "";
          }
          if (
            purchaseTypeData[i].specifications.ultimate_hydrogen_basis_iso ===
            "0"
          ) {
            purchaseTypeData[i].specifications.ultimate_hydrogen_basis_iso = "";
          }
          if (
            purchaseTypeData[i].specifications.ultimate_nitrogen_basis_iso ===
            "0"
          ) {
            purchaseTypeData[i].specifications.ultimate_nitrogen_basis_iso = "";
          }
          if (purchaseTypeData[i].specifications.ash_sio2_basis_iso === "0") {
            purchaseTypeData[i].specifications.ash_sio2_basis_iso = "";
          }
          if (purchaseTypeData[i].specifications.ash_ai203_basis_iso === "0") {
            purchaseTypeData[i].specifications.ash_ai203_basis_iso = "";
          }
          if (purchaseTypeData[i].specifications.ash_sio2_basis_iso === "0") {
            purchaseTypeData[i].specifications.ash_sio2_basis_iso = "";
          }
          if (purchaseTypeData[i].specifications.ash_fe203_basis_iso === "0") {
            purchaseTypeData[i].specifications.ash_fe203_basis_iso = "";
          }
          if (purchaseTypeData[i].specifications.ash_cao_basis_iso === "0") {
            purchaseTypeData[i].specifications.ash_cao_basis_iso = "";
          }
          if (purchaseTypeData[i].specifications.ash_mgo_basis_iso === "0") {
            purchaseTypeData[i].specifications.ash_mgo_basis_iso = "";
          }
          if (purchaseTypeData[i].specifications.ash_na2o_basis_iso === "0") {
            purchaseTypeData[i].specifications.ash_na2o_basis_iso = "";
          }
          if (purchaseTypeData[i].specifications.ash_k2o_basis_iso === "0") {
            purchaseTypeData[i].specifications.ash_k2o_basis_iso = "";
          }
          if (purchaseTypeData[i].specifications.ash_tio2_basis_iso === "0") {
            purchaseTypeData[i].specifications.ash_tio2_basis_iso = "";
          }
          if (purchaseTypeData[i].specifications.ash_mn3o4_basis_iso === "0") {
            purchaseTypeData[i].specifications.ash_mn3o4_basis_iso = "";
          }
          if (purchaseTypeData[i].specifications.ash_so3_basis_iso === "0") {
            purchaseTypeData[i].specifications.ash_so3_basis_iso = "";
          }
          if (purchaseTypeData[i].specifications.ash_p2o5_basis_iso === "0") {
            purchaseTypeData[i].specifications.ash_p2o5_basis_iso = "";
          }
          if (
            purchaseTypeData[i].specifications.trace_arsenic_basis_iso === "0"
          ) {
            purchaseTypeData[i].specifications.trace_arsenic_basis_iso = "";
          }
          if (
            purchaseTypeData[i].specifications.trace_mercury_basis_iso === "0"
          ) {
            purchaseTypeData[i].specifications.trace_mercury_basis_iso = "";
          }
          if (
            purchaseTypeData[i].specifications.trace_selenium_basis_iso === "0"
          ) {
            purchaseTypeData[i].specifications.trace_selenium_basis_iso = "";
          }
          if (
            purchaseTypeData[i].specifications.trace_boron_basis_iso === "0"
          ) {
            purchaseTypeData[i].specifications.trace_boron_basis_iso = "";
          }
          if (purchaseTypeData[i].specifications.minor_ba_basis_iso === "0") {
            purchaseTypeData[i].specifications.minor_ba_basis_iso = "";
          }
          if (
            purchaseTypeData[i].specifications.minor_phosphorous_basis_iso ===
            "0"
          ) {
            purchaseTypeData[i].specifications.minor_phosphorous_basis_iso = "";
          }
        }
      }
      // this.setState({purchaseTypeRows: purchaseTypeData});
      purchaseTypeRows = purchaseTypeData;
    }

    /**
     *  Checking Mandatory files
     */
    var missing_fields = 0;
    let files_missing = 0;
    var row = this.state.files;
    for (var obj of row) {
      if (obj.file_type !== "" || obj.file_content !== "") {
        if (obj.file_content !== "") {
          if (obj.file_description === "") {
            missing_fields++;
            obj.file_description_error = true;
          } else {
            obj.file_description = obj.file_description.toUpperCase();
          }
        } else if (obj.file_description !== "") {
          if (obj.file_content === "") {
            files_missing++;
          }
        }
      }
    }
    this.setState({
      files: row,
    });

    if (missing_fields > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please fill mandatory fields.",
      });
      return;
    }
    if (files_missing > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please Select file.",
      });
      return;
    }
    const content_files = row.filter((e) => e.file_content !== "");
    if (
      vendor !== "" &&
      contract_date !== null &&
      purchase_typesAvailable === 0 &&
      laycan1 !== "" &&
      currency !== "0" &&
      pol !== "" &&
      vessel_type !== "0"
    ) {
      // for Update-Purchase-Contract
      const loginUserID = this.Cookie.getCookie("loginUserId");
      const idToken = this.Cookie.getIdTokenCookie();
      const contractType = "Addendum";
      const baseContractID = this.state.pcid;
      const baseContractNo = this.state.contract_no;
      const addendumContractID = this.state.pcid;
      const addendumContractNo = this.state.contract_no;
      const base_currency = this.state.base_currency
        ? this.state.base_currency
        : "";
      const status = "Active";
      //Add purchase contract service
      api
        .addPurchaseContract(
          loginUserID,
          idToken,
          vendor,
          old_contract_no,
          supplier_ref_no,
          contract_date,
          purchaseTypeRows,
          stevedore_price,
          pbnp,
          barging_price,
          currency,
          laycan1,
          laycan2,
          laycan3,
          laycan4,
          laycan5,
          laycanQuantity1,
          laycanQuantity2,
          laycanQuantity3,
          laycanQuantity4,
          laycanQuantity5,
          supplierSurveyorName,
          supplierSurveyorAnalysisType,
          saiiSurveyorName,
          saiiSurveyorAnalysisType,
          buyerSurveyorName,
          buyerSurveyorAnalysisType,
          pol,
          vessel_type,
          payment_adjustment,
          payment_terms_1,
          payment_terms_2,
          remarks,
          share_point_doc_link,
          contractType,
          baseContractID,
          baseContractNo,
          base_currency,
          addendumContractID,
          addendumContractNo,
          status,
          is_index_linked,
          content_files,
          shipping_terms
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
              if (res) {
                if (res.code === "200") {
                  this.setState({
                    successMessage: res.message,
                    errorMessage: "",
                  });
                  if (res.purchase_contract_id) {
                    // window.location.href =
                    //   "/view-purchase-contract/" +
                    //   btoa(res.purchase_contract_id);
                    window.location.href =
                      "/view-purchase-contract/" +
                      btoa(baseContractID);
                  }
                }
                if (res.code === "601") {
                  this.setState({
                    errorMessage: res.message,
                    successMessage: "",
                    isLoading: false,
                  });
                }
                if (res.code === "624") {
                  this.setState({ isLoading: false });
                  for (var i = 0; i < res.columns.length; i++) {
                    if (res.columns[i] === "vendor_id") {
                      this.setState({ vendorTypeError: true });
                    }
                    if (res.columns[i] === "currency") {
                      this.setState({ currencyError: true });
                    }
                    if (res.columns[i] === "laycan_1") {
                      this.setState({ laycan1Error: true });
                    }
                    if (res.columns[i] === "purchasing_term") {
                      this.setState({ purchasingTermError: true });
                    }
                    if (res.columns[i] === "vessel_type") {
                      this.setState({ vesselTypeError: true });
                    }
                  }
                  this.setState({
                    isLoading: false,
                    errorMessage: res.message,
                    successMessage: "",
                  });
                  alert(res.message);
                  return;
                }
              } else {
                this.setState({ isLoading: false });
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            });
          }
        });
    } else {
      this.setState({ isLoading: false });
      alert("Please fill all mandatory fields.");
    }
  };

  getVendorvalue = (id) => {
    var vendorname = this.state.vendorsData.filter(
      (e, index) => e.value === this.state.vendor
    );
    return vendorname[0].name;
  };

  getMinevalue = (id) => {
    var mineName = this.state.vendorsDataforMine.filter(
      (e, index) => e.value === id
    );
    var mine_name = "";
    if (mineName.length > 0) {
      mine_name = mineName[0].mine_name;
    }
    return mine_name;
  };

  toLocaleString(value) {
    return Number(value).toLocaleString();
  }

  previousStepHandler = () => {
    this.setState({
      activeStep: this.state.activeStep - 1,
    });
  };

  nextStepHandler = () => {
    if (this.state.activeStep === 0) {
      var { vendor, contract_date, currency } = this.state;
      if (vendor === "") {
        this.setState({ vendorTypeError: true });
      }
      if (contract_date === null) {
        this.setState({ contractDateError: true, isLoading: false });
      }
      if (currency === "0") {
        this.setState({ isLoading: false, currencyError: true });
      }
      var laycan1 = "";
      for (var i in this.state.LaycanData) {
        if (i === "0") {
          laycan1 = this.state.LaycanData[i].laycan;
        }
      }
      if (laycan1 === "") {
        this.setState({ isLoading: false, laycan1Error: true });
      }
      if (
        vendor === "" ||
        contract_date === null ||
        currency === "0" ||
        laycan1 === ""
      ) {
        this.setState({
          snackBarErrorOpen: true,
          errorMsg: "Please fill mandatory fields.",
        });
        return;
      }
    }

    if (this.state.activeStep === 1) {
      var { purchaseTypeRows } = this.state;
      var rows = purchaseTypeRows;
      var purchase_typesAvailable = 0;
      for (var j in purchaseTypeRows) {
        if (purchaseTypeRows[j].purchasing_term === "Specs") {
          if (purchaseTypeRows[j].quality === "") {
            rows[j].qualityError = true;
            purchase_typesAvailable = purchase_typesAvailable + 1;
          } else {
            var quality = purchaseTypeRows[j].quality;
            purchaseTypeRows[j].quality = quality.toUpperCase();
          }
        }
        if (purchaseTypeRows[j].quantity_in_mt === "") {
          rows[j].quantity_in_mtError = true;
          purchase_typesAvailable = purchase_typesAvailable + 1;
        }
        if (purchaseTypeRows[j].tolerance === "") {
          rows[j].ToleranceError = true;
          purchase_typesAvailable = purchase_typesAvailable + 1;
        }
        if (purchaseTypeRows[j].purchasing_term === "0") {
          rows[j].purchasingTermError = true;
          purchase_typesAvailable = purchase_typesAvailable + 1;
        } else {
          if (purchaseTypeRows[j].purchasing_term === "Specs") {
            if (purchaseTypeRows[j].specification_standard === "0") {
              rows[j].specification_standardError = true;
              purchase_typesAvailable = purchase_typesAvailable + 1;
            }
          }
        }

        for (var k in purchaseTypeRows[j].purchase_types) {
          if (purchaseTypeRows[j].purchase_types[k].purchase_type === "0") {
            rows[j].purchase_types[k].purchase_typeError = true;
            this.setState({ isLoading: false, purchaseTypeRows: rows });
            purchase_typesAvailable = purchase_typesAvailable + 1;
          }
          if (purchaseTypeRows[j].purchase_types[k].price_pmt === "") {
            rows[j].purchase_types[k].price_pmtError = true;
            this.setState({ isLoading: false, purchaseTypeRows: rows });
            purchase_typesAvailable = purchase_typesAvailable + 1;
          }
        }
      }
      this.setState({ purchaseTypeRows: rows });
      if (purchase_typesAvailable > 0) {
        this.setState({
          isLoading: false,
          snackBarErrorOpen: true,
          errorMsg: "Please fill all mandatory fields.",
        });
        return;
      }

      let purchaseQualityRows = this.state.purchaseTypeRows;
      for (var p = 0; p < purchaseQualityRows.length; p++) {
        if (purchaseQualityRows[p].purchasing_term === "0") {
          purchaseQualityRows[p].purchasingTermError = true;
          this.setState({
            isLoading: false,
            purchaseTypeRows: purchaseQualityRows,
            snackBarErrorOpen: true,
            errorMsg: "Please fill all mandatory fields.",
          });
          return;
        }
        // else {
        //   this.setState({
        //     errorMsg: 'Please fill all mandatory fields.',
        //     snackBarErrorOpen: true
        //   });
        // }
      }
    }

    if (this.state.activeStep === 2) {
      let { pol, vessel_type } = this.state;

      if (vessel_type === "0") {
        this.setState({ isLoading: false, vesselTypeError: true });
      }
      if (pol === "") {
        this.setState({ isLoading: false, PolError: true });
      }
      if (vessel_type === "0" || pol === "") {
        this.setState({
          errorMsg: "Please fill mandatory fields.",
          snackBarErrorOpen: true,
        });
        return;
      }
    }
    if (this.state.activeStep === 3) {
      /**
       *  Checking Mandatory files
       */
      var missing_fields = 0;
      let files_missing = 0;
      var row = this.state.files;
      for (var obj of row) {
        if (obj.file_type !== "" || obj.file_content !== "") {
          if (obj.file_content !== "") {
            if (obj.file_description === "") {
              missing_fields++;
              obj.file_description_error = true;
            } else {
              obj.file_description = obj.file_description.toUpperCase();
            }
          } else if (obj.file_description !== "") {
            if (obj.file_content === "") {
              files_missing++;
            }
          }
        }
      }
      this.setState({
        files: row,
      });

      if (missing_fields > 0) {
        this.setState({
          snackBarErrorOpen: true,
          errorMsg: "Please fill mandatory fields.",
        });
        return;
      }
      if (files_missing > 0) {
        this.setState({
          snackBarErrorOpen: true,
          errorMsg: "Please Select file.",
        });
        return;
      }
    }

    this.setState({
      activeStep: this.state.activeStep + 1,
    });
  };

  render() {
    try {
      var purchase_contract_id = decodeURIComponent(
        window.atob(this.props.match.params.PurchaseID)
      );
    } catch (e) {
      window.location.href = "/purchase-contract-list";
    }

    return (
      <div id="wraper">
        <SideBar />
        <div className="content">
          <Header />
          <div className="content-header">
            <div className="col-sm text-left pl-0">
              <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                <a href={"/view-purchase-contract/" + btoa(purchase_contract_id)}>
                  <i className="fa fa-arrow-left left_arrow_adjst" />
                </a>
                Coal Purchase Contract Addendum - {this.state.contract_no}
              </h4>
            </div>
          </div>
          {this.state.isLoading && <Loader />}
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          {!this.state.isLoading && (
            <form className="container p-3">
              <div className="row mb-0 stepper">
                <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                  <i
                    className={
                      this.state.activeStep > 0
                        ? "fa fa-check-circle"
                        : "fa fa-check-circle-o"
                    }
                    aria-hidden="true"
                    style={{
                      fontSize: 20,
                      color:
                        this.state.activeStep > 0
                          ? "#31c197"
                          : this.state.activeStep === 0
                            ? config.themeColor
                            : "#ccc",
                    }}
                  />
                  <span
                    className="stepper_text"
                    style={{
                      color:
                        this.state.activeStep > 0
                          ? "#31c197"
                          : this.state.activeStep === 0
                            ? config.themeColor
                            : "#ccc",
                    }}
                  >
                    Contract Details
                  </span>
                  <i
                    className="fa fa-chevron-right stepper_sub_icon stepper_arrow"
                    aria-hidden="true"
                    style={{
                      color:
                        this.state.activeStep > 0
                          ? "#31c197"
                          : this.state.activeStep === 0
                            ? config.themeColor
                            : "#ccc",
                    }}
                  />
                </span>

                <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                  <i
                    className={
                      this.state.activeStep > 1
                        ? "fa fa-check-circle"
                        : "fa fa-check-circle-o"
                    }
                    aria-hidden="true"
                    style={{
                      fontSize: 20,
                      color:
                        this.state.activeStep > 1
                          ? "#31c197"
                          : this.state.activeStep === 1
                            ? config.themeColor
                            : "#ccc",
                    }}
                  />
                  <span
                    className="stepper_text"
                    style={{
                      color:
                        this.state.activeStep > 1
                          ? "#31c197"
                          : this.state.activeStep === 1
                            ? config.themeColor
                            : "#ccc",
                    }}
                  >
                    Add Quality
                  </span>
                  <i
                    className="fa fa-chevron-right stepper_sub_icon stepper_arrow"
                    aria-hidden="true"
                    style={{
                      color:
                        this.state.activeStep > 1
                          ? "#31c197"
                          : this.state.activeStep === 1
                            ? config.themeColor
                            : "#ccc",
                    }}
                  />
                </span>

                <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                  <i
                    className={
                      this.state.activeStep > 2
                        ? "fa fa-check-circle"
                        : "fa fa-check-circle-o"
                    }
                    aria-hidden="true"
                    style={{
                      fontSize: 20,
                      color:
                        this.state.activeStep > 2
                          ? "#31c197"
                          : this.state.activeStep === 2
                            ? config.themeColor
                            : "#ccc",
                    }}
                  />
                  <span
                    className="stepper_text"
                    style={{
                      color:
                        this.state.activeStep > 2
                          ? "#31c197"
                          : this.state.activeStep === 2
                            ? config.themeColor
                            : "#ccc",
                    }}
                  >
                    Surveyor Details
                  </span>
                  <i
                    className="fa fa-chevron-right stepper_sub_icon stepper_arrow"
                    aria-hidden="true"
                    style={{
                      color:
                        this.state.activeStep > 2
                          ? "#31c197"
                          : this.state.activeStep === 2
                            ? config.themeColor
                            : "#ccc",
                    }}
                  />
                </span>

                <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                  <i
                    className={
                      this.state.activeStep > 3
                        ? "fa fa-check-circle"
                        : "fa fa-check-circle-o"
                    }
                    aria-hidden="true"
                    style={{
                      fontSize: 20,
                      color:
                        this.state.activeStep > 3
                          ? "#31c197"
                          : this.state.activeStep === 3
                            ? config.themeColor
                            : "#ccc",
                    }}
                  />
                  <span
                    className="stepper_text"
                    style={{
                      color:
                        this.state.activeStep > 3
                          ? "#31c197"
                          : this.state.activeStep === 3
                            ? config.themeColor
                            : "#ccc",
                    }}
                  >
                    Payment Terms
                  </span>
                  <i
                    className="fa fa-chevron-right stepper_sub_icon stepper_arrow"
                    aria-hidden="true"
                    style={{
                      color:
                        this.state.activeStep > 3
                          ? "#31c197"
                          : this.state.activeStep === 3
                            ? config.themeColor
                            : "#ccc",
                    }}
                  />
                </span>

                <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                  <i
                    className={
                      this.state.activeStep > 4
                        ? "fa fa-check-circle"
                        : "fa fa-check-circle-o"
                    }
                    aria-hidden="true"
                    style={{
                      fontSize: 20,
                      color:
                        this.state.activeStep > 4
                          ? "#31c197"
                          : this.state.activeStep === 4
                            ? config.themeColor
                            : "#ccc",
                    }}
                  />
                  <span
                    className="stepper_text"
                    style={{
                      color:
                        this.state.activeStep > 4
                          ? "#31c197"
                          : this.state.activeStep === 4
                            ? config.themeColor
                            : "#ccc",
                    }}
                  >
                    Preview & Submit
                  </span>
                  <i
                    className="fa fa-chevron-right stepper_sub_icon stepper_arrow"
                    aria-hidden="true"
                    style={{
                      color:
                        this.state.activeStep > 4
                          ? "#31c197"
                          : this.state.activeStep === 4
                            ? config.themeColor
                            : "#ccc",
                    }}
                  />
                </span>
              </div>
              <div className="card" style={{ overflowY: "scroll" }}>
                {this.state.activeStep === 0 && (
                  <div className="section_block">
                    <div style={{ width: "70%" }}>
                      <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                        Add details to update Purchase Contract
                      </h5>
                    </div>
                    <div className="row mb-0">
                      <div className="col-lg-6 p-0">
                        <div className="row mb-2">
                          <label className="form_label mb-0">
                            Vendor <span style={{ color: "red" }}>*</span>
                          </label>
                          <Autocomplete
                            options={this.state.vendorsDataList}
                            getOptionLabel={(option) => option.name}
                            disabled
                            onChange={(event, value) => {
                              value != null
                                ? this.setState({
                                  vendor: value.value,
                                  filteredVendor: value,
                                  vendorTypeError: false,
                                })
                                : this.setState({
                                  filteredVendor: value,
                                  vendor: "",
                                  vendorTypeError: false,
                                });
                            }}
                            name="vendorName"
                            value={this.state.filteredVendor}
                            size="small"
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Search Vendor"
                                // label="Search Vendor"
                                variant="outlined"
                                error={this.state.vendorTypeError}
                                // inputProps={{style: {padding: 2}}}
                                style={{ top: 8 }}
                                fullWidth
                              />
                            )}
                            style={{ display: "contents" }}
                          // inputProps={{style: {padding: 2}}}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 pr-0">
                        <div className="row mb-2">
                          <label className="form_label mb-0">
                            Supplier Reference No
                          </label>
                          <TextField
                            name="supplier_ref_no"
                            margin="dense"
                            variant="outlined"
                            placeholder="Supplier Reference No"
                            value={this.state.supplier_ref_no}
                            onChange={this.handleTextChange}
                            fullWidth
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-lg-6 p-0">
                        <div className="row mb-2">
                          <label className="form_label mb-0">
                            Contract Date{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                              autoOk={true}
                              inputVariant="outlined"
                              variant="inline"
                              margin="dense"
                              fullWidth
                              format="dd/MM/yyyy"
                              value={this.state.contract_date}
                              onChange={(date) => {
                                this.setState({
                                  contract_date: date,
                                  contractDateError: false,
                                });
                              }}
                              error={this.state.contractDateError}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <IconButton>
                                      <EventIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                                className: "pl-0",
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                      </div>
                      <div className="col-lg-6 pr-0">
                        <div className="row mb-2">
                          <label className="form_label mb-0">
                            Currency <span style={{ color: "red" }}>*</span>
                          </label>
                          <TextField
                            name="currency"
                            variant="outlined"
                            margin="dense"
                            select
                            value={this.state.currency}
                            onChange={this.handleTextChange}
                            style={{ width: "87%" }}
                            error={this.state.currencyError}
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                          >
                            {this.state.currencyData}
                          </TextField>
                          <AddCircle
                            onClick={() =>
                              this.setState({ clickedCurrency: "Currency" })
                            }
                            style={{
                              marginTop: 15,
                              marginLeft: 5,
                              fontSize: 26,
                              color: config.themeColor,
                              cursor: "pointer",
                            }}
                            data-toggle="modal"
                            data-target="#myModal"
                          />
                        </div>
                      </div>
                    </div>
                    {this.state.LaycanData.map((e, idx) => (
                      <div className="row mb-2" key={idx}>
                        <div className="col-lg-6 p-0">
                          <label className="form_label mb-0">
                            Laycan
                            {idx === 0 && (
                              <span style={{ color: "red" }}>*</span>
                            )}
                          </label>
                          <TextField
                            name="laycan"
                            margin="dense"
                            // label='Laycan *'
                            variant="outlined"
                            placeholder={idx === 0 ? "Laycan *" : "Laycan"}
                            error={this.state.laycan1Error}
                            value={this.state.LaycanData[idx].laycan}
                            onChange={this.laycanTextHandler(idx)}
                            fullWidth
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                          />
                        </div>
                        <div
                          className={
                            this.state.LaycanData.length > 0
                              ? "col-lg-5 pr-0"
                              : "col-lg-6 pr-0"
                          }
                        >
                          <label
                            className="form_label mb-0"
                            style={{ visibility: "hidden" }}
                          >
                            None
                          </label>
                          <TextField
                            name="laycan_quantity"
                            margin="dense"
                            label="Quantity in MT"
                            variant="outlined"
                            placeholder="Quantity in MT"
                            value={this.state.LaycanData[idx].laycan_quantity}
                            onChange={this.laycanTextHandler(idx)}
                            fullWidth
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                          />
                        </div>
                        {this.state.LaycanData.length > 1 && (
                          <center className="col-lg-1 d-flex justify-content-center">
                            <i
                              className="fa fa-trash-o m-auto"
                              aria-hidden="true"
                              onClick={this.handleRemoveLaycanRows(idx)}
                              style={{
                                fontSize: 24,
                                color: config.themeColor,
                                cursor: "pointer",
                              }}
                            />
                          </center>
                        )}
                      </div>
                    ))}
                    <div className="row mb-3 mt-1">
                      <div className="col-lg-6 p-0">
                        {this.state.LaycanData.length < 5 && (
                          <button
                            type="button"
                            onClick={this.handleLaycanRows}
                            className="header_button header_button_text addrow_button_adjustment w-auto"
                            style={{ border: "none", color: config.themeColor }}
                          >
                            ADD ROW
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {this.state.activeStep === 1 && (
                  <div className="section_block">
                    <div style={{ width: "70%" }}>
                      <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                        Add Quality
                      </h5>
                    </div>

                    <PurchaseQuality
                      quality={this.state.purchaseTypeRows}
                      mines={this.state.vendorsDataforMine}
                      callbackFn={(qualities) => {
                        this.setState({
                          purchaseTypeRows: qualities,
                        });
                      }}
                    />

                    <div className="row">
                      <div className="col-lg-6 p-0">
                        <label className="form_label mb-0">
                          Quality Adjustment
                        </label>
                        <TextField
                          name="payment_adjustment"
                          margin="dense"
                          variant="outlined"
                          multiline
                          rows={3}
                          placeholder="Quality Adjustment"
                          value={this.state.payment_adjustment}
                          onChange={this.handleTextChange}
                          fullWidth
                          inputProps={{ style: { textTransform: "uppercase" } }}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12 p-0">
                        <label className="form_label mb-0">
                          Shipping Terms
                        </label>
                        <TextField
                          name="shipping_terms"
                          margin="dense"
                          multiline
                          rows={3}
                          variant="outlined"
                          placeholder="Shipping Terms"
                          value={this.state.shipping_terms}
                          onChange={this.handleText}
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          fullWidth
                        />
                      </div>
                    </div>

                  </div>
                )}
                {this.state.activeStep === 2 && (
                  <div className="section_block">
                    <div style={{ width: "70%" }}>
                      <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                        Surveyor
                      </h5>
                    </div>

                    <div className="row mb-2">
                      <div className="col-lg-6 p-0">
                        <label className="form_label mb-0">
                          Supplier Surveyor Name
                        </label>
                        <FormControl
                          margin="dense"
                          variant="outlined"
                          fullWidth
                        >
                          <Select
                            id="demo-mutiple-CheckBox"
                            multiple
                            fullWidth
                            name="supplierSurveyorName"
                            value={this.state.supplierSurveyorName.sort()}
                            onChange={this.MultiSelectHandler}
                            renderValue={(selected) => selected.join(", ")}
                          >
                            {this.state.surveyorsData.map((e, index) => (
                              <MenuItem value={e.name} key={index}>
                                <CheckBox
                                  checked={
                                    this.state.supplierSurveyorName.includes(
                                      e.name
                                    )
                                  }
                                />
                                <ListItemText primary={e.name} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="col-lg-6 pr-0">
                        <label className="form_label mb-0">
                          Supplier Surveyor Analysis Type
                        </label>
                        <FormControl
                          margin="dense"
                          variant="outlined"
                          fullWidth
                        >
                          <Select
                            id="demo1-mutiple-CheckBox"
                            multiple
                            name="supplierSurveyorAnalysisType"
                            fullWidth
                            value={this.state.supplierSurveyorAnalysisType.sort()}
                            onChange={this.MultiSelectHandler}
                            // input={<Input />}
                            renderValue={(selected) => selected.join(", ")}
                          //MenuProps={MenuProps}
                          >
                            {this.state.analysisTypeValues.map((e, index) => (
                              <MenuItem value={e} key={index}>
                                <CheckBox
                                  checked={
                                    this.state.supplierSurveyorAnalysisType.includes(
                                      e
                                    )
                                  }
                                />
                                <ListItemText primary={e} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>

                    <div className="row mb-2">
                      <div className="col-lg-6 p-0">
                        <label className="form_label mb-0">
                          {config.company} Surveyor Name
                        </label>
                        <FormControl
                          margin="dense"
                          variant="outlined"
                          fullWidth
                        >
                          <Select
                            id="demo2-mutiple-CheckBox"
                            multiple
                            fullWidth
                            name="saiiSurveyorName"
                            value={this.state.saiiSurveyorName.sort()}
                            onChange={this.MultiSelectHandler}
                            // input={<Input />}
                            renderValue={(selected) => selected.join(", ")}
                          //MenuProps={MenuProps}
                          >
                            {this.state.surveyorsData.map((e, index) => (
                              <MenuItem value={e.name} key={index}>
                                <CheckBox
                                  checked={
                                    this.state.saiiSurveyorName.includes(
                                      e.name
                                    )
                                  }
                                />
                                <ListItemText primary={e.name} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="col-lg-6 pr-0">
                        <label className="form_label mb-0">
                          {config.company} Surveyor Analysis Type
                        </label>
                        <FormControl
                          margin="dense"
                          variant="outlined"
                          fullWidth
                        >
                          <Select
                            id="demo3-mutiple-CheckBox"
                            multiple
                            name="saiiSurveyorAnalysisType"
                            fullWidth
                            value={this.state.saiiSurveyorAnalysisType.sort()}
                            onChange={this.MultiSelectHandler}
                            // input={<Input />}
                            renderValue={(selected) => selected.join(", ")}
                          //MenuProps={MenuProps}
                          >
                            {this.state.analysisTypeValues.map((e, index) => (
                              <MenuItem value={e} key={index}>
                                <CheckBox
                                  checked={
                                    this.state.saiiSurveyorAnalysisType.includes(
                                      e
                                    )
                                  }
                                />
                                <ListItemText primary={e} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>

                    <div className="row mb-2">
                      <div className="col-lg-6 p-0">
                        <label className="form_label mb-0">
                          Buyer/Additional Surveyor Name
                        </label>
                        <FormControl
                          margin="dense"
                          variant="outlined"
                          fullWidth
                        >
                          <Select
                            id="demo4-mutiple-CheckBox"
                            multiple
                            fullWidth
                            name="jointSurveyorName"
                            value={this.state.jointSurveyorName.sort()}
                            onChange={this.MultiSelectHandler}
                            // input={<Input />}
                            renderValue={(selected) => selected.join(", ")}
                          //MenuProps={MenuProps}
                          >
                            {this.state.surveyorsData.map((e, index) => (
                              <MenuItem value={e.name} key={index}>
                                <CheckBox
                                  checked={
                                    this.state.jointSurveyorName.includes(
                                      e.name
                                    )
                                  }
                                />
                                <ListItemText primary={e.name} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="col-lg-6 pr-0">
                        <label className="form_label mb-0">
                          Buyer/Additional Surveyor Analysis Type
                        </label>
                        <FormControl
                          margin="dense"
                          variant="outlined"
                          fullWidth
                        >
                          <Select
                            id="demo5-mutiple-CheckBox"
                            multiple
                            name="jointSurveyorAnalysisType"
                            fullWidth
                            value={this.state.jointSurveyorAnalysisType.sort()}
                            onChange={this.MultiSelectHandler}
                            // input={<Input />}
                            renderValue={(selected) => selected.join(", ")}
                          //MenuProps={MenuProps}
                          >
                            {this.state.analysisTypeValues.map((e, index) => (
                              <MenuItem value={e} key={index}>
                                <CheckBox
                                  checked={
                                    this.state.jointSurveyorAnalysisType.includes(
                                      e
                                    )
                                  }
                                />
                                <ListItemText primary={e} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>

                    {/* <div style={{ width: "70%" }}>
                      <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                        Loading
                      </h5>
                    </div>

                    <div className="row mb-2">
                      <div className="col-lg-6 p-0">
                        <label className="form_label mb-0">
                          Vessel Type <span style={{ color: "red" }}>*</span>
                        </label>
                        <TextField
                          name="vessel_type"
                          variant="outlined"
                          margin="dense"
                          value={this.state.vessel_type}
                          onChange={this.handleTextChange}
                          select
                          fullWidth
                          error={this.state.vesselTypeError}
                        >
                          <MenuItem value="0" disabled>
                            Please Select
                          </MenuItem>
                          <MenuItem value="Geared & Grabbed">
                            Geared & Grabbed
                          </MenuItem>
                          <MenuItem value="Gearless">Gearless</MenuItem>
                          <MenuItem value="NA">NA</MenuItem>
                        </TextField>
                      </div>
                      <div className="col-lg-6 pr-0">
                        <label className="form_label mb-0">
                          Port of Loading{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <TextField
                          name="pol"
                          variant="outlined"
                          margin="dense"
                          placeholder="Port of Loading"
                          value={this.state.pol}
                          onChange={this.handleTextChange}
                          error={this.state.PolError}
                          fullWidth
                          inputProps={{ style: { textTransform: "uppercase" } }}
                        />
                      </div>
                    </div> */}
                  </div>
                )}
                {this.state.activeStep === 3 && (
                  <div className="section_block">
                    <div style={{ width: "70%" }}>
                      <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                        Payment
                      </h5>
                    </div>
                    <div className="row mb-2">

                      <div className="col-lg-6 p-0">
                        <label className="form_label mb-0">
                          Payment Terms 1
                        </label>
                        <TextField
                          name="payment_terms_1"
                          margin="dense"
                          variant="outlined"
                          multiline
                          rows={3}
                          placeholder="Payment Terms 1"
                          value={this.state.payment_terms_1}
                          onChange={this.handleTextChange}
                          fullWidth
                          inputProps={{ style: { textTransform: "uppercase" } }}
                        />
                      </div>
                      <div className="col-lg-6 pr-0">
                        <label className="form_label mb-0">
                          Payment Terms 2
                        </label>
                        <TextField
                          name="payment_terms_2"
                          margin="dense"
                          variant="outlined"
                          multiline
                          rows={3}
                          placeholder="Payment Terms 2"
                          value={this.state.payment_terms_2}
                          onChange={this.handleTextChange}
                          fullWidth
                          inputProps={{ style: { textTransform: "uppercase" } }}
                        />
                      </div>
                      <div className="col-lg-6 p-0">
                        <label className="form_label mb-0">Remarks</label>
                        <TextField
                          name="remarks"
                          margin="dense"
                          variant="outlined"
                          multiline
                          rows={3}
                          placeholder="Remarks"
                          value={this.state.remarks}
                          onChange={this.handleTextChange}
                          fullWidth
                          inputProps={{ style: { textTransform: "uppercase" } }}
                        />
                      </div>
                      <div className="col-lg-6 pr-0">
                        <label className="form_label mb-0">
                          Share Point Document Link
                        </label>
                        <TextField
                          name="share_point_doc_link"
                          margin="dense"
                          variant="outlined"
                          placeholder="Share Point Document Link"
                          value={this.state.share_point_doc_link}
                          onChange={this.handleTextChange}
                          fullWidth
                          inputProps={{ style: { textTransform: "uppercase" } }}
                        />
                      </div>
                    </div>
                    {(this.state.features.includes("326") ||
                      this.state.features.includes("97")) && (
                        <FileUpload
                          files={this.state.files}
                          feature_name="Purchase_Contract"
                          callbackFn={(data) => this.setState({ files: data })}
                        />
                      )}
                  </div>
                )}

                {this.state.activeStep === 4 && (
                  <div className="section_block">
                    <div className="card p-0 border mb-3">
                      <div className="card-header section_header">
                        <h5>Contract Details</h5>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-lg-4 mb-3">
                            <label className="contract_display_header_label">
                              Vendor
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ textTransform: "uppercase" }}
                            >
                              {this.state.vendor
                                ? this.getVendorvalue(this.state.vendor)
                                : "-"}
                            </div>
                          </div>
                          <div className="col-lg-4 mb-3">
                            <label className="contract_display_header_label">
                              Supplier Reference No
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ textTransform: "uppercase" }}
                            >
                              {this.state.supplier_ref_no
                                ? this.state.supplier_ref_no
                                : "-"}{" "}
                            </div>
                          </div>
                          <div className="col-lg-4 mb-3">
                            <label className="contract_display_header_label">
                              Contract Date
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ textTransform: "uppercase" }}
                            >
                              {localDateFormate(
                                this.state.contract_date
                                  ? dateFormateToDB(this.state.contract_date)
                                  : null
                              )}
                            </div>
                          </div>
                          <div className="col-lg-4 mb-3">
                            <label className="contract_display_header_label">
                              Currency
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ textTransform: "uppercase" }}
                            >
                              {this.state.currency ? this.state.currency : "-"}{" "}
                            </div>
                          </div>

                          {/* <div className='col-lg-4 mb-3'>
                            <label className='contract_display_header_label'>Index Linked</label>
                            <div className='contract_display_header_value'>{this.state.is_index_linked ? this.state.is_index_linked : 'No'} </div>
                          </div> */}

                          {this.state.LaycanData.map((e, idx) => (
                            <div className="col-lg-4 mb-3" key={idx}>
                              <label className="contract_display_header_label">
                                {" "}
                                Laycan {idx + 1}
                              </label>
                              <div
                                className="contract_display_header_value"
                                style={{ textTransform: "uppercase" }}
                              >
                                {e.laycan ? e.laycan : "-"} ,{" "}
                                {e.laycan_quantity
                                  ? this.toLocaleString(e.laycan_quantity)
                                  : " -"}{" "}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div className="card p-0 border mb-3">
                      <div className="card-header section_header">
                        <h5>Quality</h5>
                      </div>
                      <div className="card-body">
                        <QualityView
                          contractType={"Purchase"}
                          qualities={this.state.purchaseTypeRows}
                          callbackFn={() => { }}

                        />
                      </div>
                    </div>

                    <div className="card p-0 border mb-3">
                      <div className="card-header section_header">
                        <h5>Surveyor Details</h5>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <span
                            className="col-lg-12 contract_display_header_value pl-0"
                            style={{ fontWeight: "bold" }}
                          >
                            Surveyor
                          </span>
                          <div className="col-lg-4 mb-3">
                            <label className="contract_display_header_label">
                              Supplier Surveyor Name
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ textTransform: "uppercase" }}
                            >
                              {this.state.supplierSurveyorName.toString()
                                ? this.state.supplierSurveyorName.toString()
                                : "-"}{" "}
                            </div>
                          </div>

                          <div className="col-lg-4 mb-3">
                            <label className="contract_display_header_label">
                              {config.company} Surveyor Name
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ textTransform: "uppercase" }}
                            >
                              {this.state.saiiSurveyorName.toString()
                                ? this.state.saiiSurveyorName.toString()
                                : "-"}{" "}
                            </div>
                          </div>

                          <div className="col-lg-4 mb-3">
                            <label className="contract_display_header_label">
                              Buyer/Additional Surveyor Name
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ textTransform: "uppercase" }}
                            >
                              {this.state.jointSurveyorName.toString()
                                ? this.state.jointSurveyorName.toString()
                                : "-"}{" "}
                            </div>
                          </div>

                          <div className="col-lg-4 mb-3">
                            <label className="contract_display_header_label">
                              Supplier Surveyor Analysis Type
                            </label>
                            <div className="contract_display_header_value">
                              {this.state.supplierSurveyorAnalysisType.toString()
                                ? this.state.supplierSurveyorAnalysisType.toString()
                                : "-"}{" "}
                            </div>
                          </div>
                          <div className="col-lg-4 mb-3">
                            <label className="contract_display_header_label">
                              {config.company} Surveyor Analysis Type
                            </label>
                            <div className="contract_display_header_value">
                              {this.state.saiiSurveyorAnalysisType.toString()
                                ? this.state.saiiSurveyorAnalysisType.toString()
                                : "-"}{" "}
                            </div>
                          </div>
                          <div className="col-lg-4 mb-3">
                            <label className="contract_display_header_label">
                              Buyer/Additional Surveyor Analysis Type
                            </label>
                            <div className="contract_display_header_value">
                              {this.state.jointSurveyorAnalysisType.toString()
                                ? this.state.jointSurveyorAnalysisType.toString()
                                : "-"}{" "}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <span
                            className="col-lg-12 contract_display_header_value pl-0"
                            style={{ fontWeight: "bold" }}
                          >
                            Price
                          </span>
                          <div className="col-lg-4 mb-3">
                            <label className="contract_display_header_label">
                              Stevedore Price
                            </label>
                            <div className="contract_display_header_value">
                              {this.state.stevedore_price
                                ? this.toLocaleString(this.state.stevedore_price)
                                : "-"}{" "}
                            </div>
                          </div>
                          <div className="col-lg-4 mb-3">
                            <label className="contract_display_header_label">
                              Barging Price
                            </label>
                            <div className="contract_display_header_value">
                              {this.state.barging_price
                                ? this.toLocaleString(this.state.barging_price)
                                : "-"}{" "}
                            </div>
                          </div>
                          <div className="col-lg-4 mb-3">
                            <label className="contract_display_header_label">
                              pnbp
                            </label>
                            <div className="contract_display_header_value">
                              {this.state.pnbp
                                ? this.toLocaleString(this.state.pnbp)
                                : "-"}{" "}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <span
                            className="col-lg-12 contract_display_header_value pl-0"
                            style={{ fontWeight: "bold" }}
                          >
                            Loading
                          </span>
                          <div className="col-lg-4 mb-3">
                            <label className="contract_display_header_label">
                              Vessel Type
                            </label>
                            <div className="contract_display_header_value">
                              {this.state.vessel_type
                                ? this.state.vessel_type
                                : "-"}{" "}
                            </div>
                          </div>
                          <div className="col-lg-4 mb-3">
                            <label className="contract_display_header_label">
                              Port of Loading
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ textTransform: "uppercase" }}
                            >
                              {this.state.pol ? this.state.pol : "-"}{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card p-0 border mb-3">
                      <div className="card-header section_header">
                        <h5>Quality And Shipping Terms</h5>
                      </div>
                      <div className="card-body">
                        <div className="row col-lg-12 p-2">
                          <label
                            className="col-lg-12 contract_display_header_value pl-0"
                            style={{ fontWeight: "bold" }}
                          >
                            Quality And Shipping Terms
                          </label>
                          <div className="col-lg-6">
                            <label className="contract_display_header_label">
                              Quality Adjustment
                            </label>
                            <label
                              className="contract_display_header_value"
                              style={{
                                whiteSpace: "pre-line",
                                textTransform: "uppercase",
                              }}
                            >
                              {this.state.payment_adjustment
                                ? this.state.payment_adjustment
                                : "-"}
                            </label>
                          </div>
                          <div className="col-lg-6">
                            <label className="contract_display_header_label">
                             Shipping Terms
                            </label>
                            <label
                              className="contract_display_header_value"
                              style={{
                                whiteSpace: "pre-line",
                                textTransform: "uppercase",
                              }}
                            >
                              {this.state.shipping_terms
                                ? this.state.shipping_terms
                                : "-"}
                            </label>
                          </div>

                        </div>
                      </div>
                    </div>

                    <div className="card p-0 border mb-3">
                      <div className="card-header section_header">
                        <h5>Payment Terms</h5>
                      </div>
                      <div className="card-body">
                        <div className="row col-lg-12 p-2">
                          <label
                            className="col-lg-12 contract_display_header_value pl-0"
                            style={{ fontWeight: "bold" }}
                          >
                            Payment
                          </label>
                          <div className="col-lg-6">
                            <label className="contract_display_header_label">
                              Payment Terms 1
                            </label>
                            <label
                              className="contract_display_header_value"
                              style={{
                                whiteSpace: "pre-line",
                                textTransform: "uppercase",
                              }}
                            >
                              {this.state.payment_terms_1
                                ? this.state.payment_terms_1
                                : "-"}
                            </label>
                          </div>
                          <div className="col-lg-6 mt-2">
                            <label className="contract_display_header_label">
                              Payment Terms 2
                            </label>
                            <label
                              className="contract_display_header_value"
                              style={{
                                whiteSpace: "pre-line",
                                textTransform: "uppercase",
                              }}
                            >
                              {this.state.payment_terms_2
                                ? this.state.payment_terms_2
                                : "-"}
                            </label>
                          </div>
                          <div className="col-lg-6 mt-2">
                            <label className="contract_display_header_label">
                              Remarks
                            </label>
                            <label
                              className="contract_display_header_value"
                              style={{
                                whiteSpace: "pre-line",
                                textTransform: "uppercase",
                              }}
                            >
                              {this.state.remarks ? this.state.remarks : "-"}
                            </label>
                          </div>
                          <div className="col-lg-6 mt-2">
                            <label className="contract_display_header_label">
                              Share Point Document Link
                            </label>
                            <label
                              className="contract_display_header_value"
                              style={{ textTransform: "uppercase" }}
                            >
                              {this.state.share_point_doc_link
                                ? this.state.share_point_doc_link
                                : "-"}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>  
                  </div>
                )}
              </div>
              <div className="row bottom_buttons_section">
                <button
                  type="button"
                  className="btn previous_button"
                  name="submit"
                  onClick={
                    this.state.activeStep === 0
                      ? () =>
                      (window.location.href =
                        "/view-purchase-contract/" +
                        btoa(purchase_contract_id))
                      : this.previousStepHandler
                  }
                >
                  {this.state.activeStep === 0 ? "BACK" : "PREVIOUS"}
                </button>
                <button
                  type="button"
                  className="btn next_button"
                  name="Back"
                  onClick={
                    this.state.activeStep === 4
                      ? this.submitHandler
                      : this.nextStepHandler
                  }
                >
                  {this.state.activeStep === 4 ? "SUBMIT" : "NEXT STEP"}
                </button>
              </div>
            </form>
          )}

          <div className="modal fade" id="myModal" role="dialog">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5
                    className="modal-title"
                    style={{ border: "none", padding: 0 }}
                  >
                    Add Currency
                  </h5>
                  <button type="button" className="close" data-dismiss="modal">
                    &times;
                  </button>
                </div>
                <div className="modal-body">
                  <TextField
                    name="newCurrency"
                    placeholder="Add New Currency *"
                    margin="dense"
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    variant="outlined"
                    onChange={this.handleTextChange}
                    value={this.state.newCurrency}
                    error={this.state.modalCurrencyError}
                  />
                </div>
                <div className="row bottom_buttons_section">
                  <button
                    type="button"
                    className="btn previous_button"
                    name="submit"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn  next_button"
                    onClick={this.handleAddCurrency}
                  >
                    Submit
                  </button>
                  &emsp;
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
