import React, { useEffect, useState } from 'react'
import Autocomplete from "@material-ui/lab/Autocomplete";
import CookieHandler from '../../common/CookieHandler';
import api from '../../../api/api';
import config from '../../../config/config';

import {
  TextField,
  InputAdornment,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { toLocaleString } from '../../common/common';


const Cookie = new CookieHandler();
const NewRadio = withStyles({
  root: {
    color: config.themeColor,
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const BargeAgreements = ({ vendors, agreement_info, currencyList, callbackFn }) => {
  const [state, setState] = useState(agreement_info);
  const [bargeVendors, setBargeVendors] = useState([]);
  const [currencies, setCurrencies] = useState([]);

  const [bargeSelectedVendor, setBargeSelectedVendor] = useState({ name: '', value: "" });
  const [bargeAgreements, setBargeAgreements] = useState([]);
  const [slecteAgreement, setSelectAgreement] = useState(agreement_info);
  const [slecteOthers, setSelectOthers] = useState(null);

  useEffect(() => {
    if (agreement_info) {
      const { price_list_id, barge_vendor_drop } = agreement_info || {};
      if (price_list_id == null) {
        setSelectOthers("Others");
      }
      if (barge_vendor_drop) {
        setBargeSelectedVendor(barge_vendor_drop)
      }
    }

  }, [agreement_info])


  useEffect(() => {
    setBargeVendors(vendors);
    setCurrencies(currencyList)
  }, [vendors, currencyList]);


  const vendorSelectionHandler = (value) => {
    let stateCopy = {
      ...state,
    }
    if (value) {
      stateCopy['barge_vendor'] = value.value;
      setBargeSelectedVendor(value)
      let request = {
        login_user_id: Cookie.getCookie('loginUserId'),
        idtoken: Cookie.getIdTokenCookie(),
        barge_vendor_id: value.value
      }
      api.get_barging_agreement_by_vendor(request).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === '200') {
              if (res.barging_agreement_price_list) {
                setBargeAgreements(res.barging_agreement_price_list);
                document.getElementById('modalOpen').click();
              }
            }
          })
        }
      })
    }
    else {
      setBargeSelectedVendor({ name: '', value: "" });
      setSelectAgreement(null);
      stateCopy['barging_agreement_price_list_id'] = null;
      stateCopy['barge_vendor'] = null;
      stateCopy['barging_base_price'] = null;
      stateCopy['barging_type'] = "Per MT";
      stateCopy['barging_base_price_currency'] = '';
      stateCopy['lumpsum_price_currency'] = '';
      stateCopy['lumpsum_price'] = "";
      stateCopy['vat_applicability'] = "Exclusive";
    }
    setState(stateCopy);
    callbackFn(stateCopy)
  }

  return (
    <>
      <button type='button' id='modalOpen' className='btn d-none' data-toggle="modal"
        data-placement="bottom"
        data-keyboard="true"
        data-target="#AgreementModal" />

      <div className='row'>
        <div className='col-lg-6 p-0 mb-2'>
          <label className="form_label mb-0">
            Barge Vendor
          </label>
          <Autocomplete
            options={bargeVendors}
            getOptionLabel={(option) => option.name}
            size="small"
            onChange={(event, value) => vendorSelectionHandler(value)}
            name="bargeVendorName"
            value={bargeSelectedVendor}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Search Barge Vendor"
                variant="outlined"
                fullWidth
                style={{ top: 8 }}
              />
            )}
            style={{ display: "contents" }}
          />
        </div>
        {(slecteAgreement?.price_list_id) &&
          <>
            <div className="col-lg-2 mb-2 pr-0">
              <label className="form_label mb-0">
                Barging Type
              </label>
              <div className='contract_display_header_value pt-2'>
                {slecteAgreement?.barging_type}
              </div>
            </div>
            <div className="col-lg-4 mb-2 pr-0">
              <label className="form_label mb-0">
                Barging Price
              </label>
              <div className='contract_display_header_value pt-2'>
                {`${toLocaleString(slecteAgreement?.price)} ${slecteAgreement?.currency}`}
              </div>
            </div>
          </>
        }

        {slecteOthers === "Others" &&
          <>
            <div className="col-lg-6 mb-2 pr-0">
              <label className="form_label mb-0">
                Barging Type
              </label>

              <RadioGroup
                aria-label="barging_type"
                name="barging_type"
                onChange={(e) => {
                  let stateCopy = {
                    ...state,
                    barging_type: e.target.value
                  }
                  setState(stateCopy)
                  callbackFn(stateCopy)

                }}
                style={{ flexDirection: "row" }}
              >
                <FormControlLabel
                  value="Per MT"
                  control={
                    <NewRadio
                      checked={
                        state?.barging_type === "Per MT"
                      }
                    />
                  }
                  label="Per MT"
                />
                <FormControlLabel
                  value="Lumpsum"
                  control={
                    <NewRadio
                      checked={
                        state?.barging_type === "Lumpsum"
                      }
                    />
                  }
                  label="Lumpsum"
                />
              </RadioGroup>
            </div>
            {state?.barging_type === "Per MT" && (
              <div className="col-lg-6 mb-2 p-0">
                <label className="form_label mb-0">
                  Barging Base Price per MT
                </label>
                <TextField
                  placeholder="Base Price"
                  type="number"
                  fullWidth
                  onChange={(e) => {
                    const pr = /^\d*(\.\d{0,4})?$/;
                    if (
                      e.target.value === "" ||
                      pr.test(e.target.value)
                    ) {

                      let stateCopy = {
                        ...state,
                        barging_base_price: e.target.value
                      }
                      setState(stateCopy);
                      callbackFn(stateCopy)


                    }
                  }}
                  value={state?.barging_base_price}
                  margin="dense"
                  variant="outlined"
                  InputProps={{
                    endAdornment:
                      config.company === "SRPL" || config.company === "AVS"  ||
                        config.company === "PRPL" ? (
                        <InputAdornment
                          position="end"
                          style={{
                            marginRight: "0px",
                            marginTop: "-4px",
                            width: "50%",
                          }}
                        >
                          <TextField
                            name="barging_base_price_currency"
                            placeholder="Enter value Price"
                            variant="outlined"
                            margin="dense"
                            value={
                              state?.barging_base_price_currency
                            }
                            fullWidth
                            onChange={(e) => {
                              let stateCopy = {
                                ...state,
                                barging_base_price_currency: e.target.value
                              }
                              setState(stateCopy);
                              callbackFn(stateCopy)

                            }}
                            select
                          >
                            {currencies}
                          </TextField>
                        </InputAdornment>
                      ) : (
                        <InputAdornment
                          position="end"
                          className="adornment_background"
                        >
                          IDR
                        </InputAdornment>
                      ),
                    style: {
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      padding: 0,
                    },
                  }}
                />
              </div>
            )}
            {state?.barging_type === "Lumpsum" && (
              <div className="col-lg-6 mb-2 p-0">
                <label className="form_label mb-0">
                  Lumpsum Base Price
                </label>
                <TextField
                  placeholder="Enter value"
                  type="number"
                  fullWidth
                  onChange={(e) => {
                    const pr = /^\d*(\.\d{0,4})?$/;
                    if (
                      e.target.value === "" ||
                      pr.test(e.target.value)
                    ) {
                      let stateCopy = {
                        ...state,
                        lumpsum_price: e.target.value
                      }
                      setState(stateCopy);
                      callbackFn(stateCopy)

                    }
                  }}
                  value={state?.lumpsum_price}
                  margin="dense"
                  variant="outlined"
                  InputProps={{
                    endAdornment:
                      config.company === "SRPL" || config.company === "AVS"  ||
                        config.company === "PRPL" ? (
                        <InputAdornment
                          position="end"
                          style={{
                            marginRight: "0px",
                            marginTop: "-4px",
                            width: "50%",
                          }}
                        >
                          <TextField
                            name="lumpsum_price_currency"
                            //placeholder="Base Price"
                            variant="outlined"
                            margin="dense"
                            value={
                              state?.lumpsum_price_currency
                            }
                            fullWidth
                            onChange={(e) => {
                              let stateCopy = {
                                ...state,
                                lumpsum_price_currency: e.target.value,
                              }
                              setState(stateCopy);
                              callbackFn(stateCopy)

                            }}
                            select
                          >
                            {currencies}
                          </TextField>
                        </InputAdornment>
                      ) : (
                        <InputAdornment
                          position="end"
                          className="adornment_background"
                        >
                          IDR
                        </InputAdornment>
                      ),
                    style: {
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      padding: 0,
                    },
                  }}
                />
              </div>
            )}

            {(state?.barging_base_price ||
              state?.lumpsum_price) && (
                <>

                  <div className="col-lg-6 mb-2 pr-0">
                    <label className="form_label mb-0">VAT Applicability</label>

                    <RadioGroup
                      aria-label="vat_applicability"
                      name="vat_applicability"
                      onChange={(e) => {

                        let stateCopy = {
                          ...state,
                          vat_applicability: e.target.value
                        }
                        setState(stateCopy)
                        callbackFn(stateCopy)

                      }}
                      style={{ flexDirection: "row" }}
                    >
                      <FormControlLabel
                        value="Exclusive"
                        control={
                          <NewRadio
                            checked={
                              state?.vat_applicability ===
                              "Exclusive"
                            }
                          />
                        }
                        label="Exclusive"
                      />
                      <FormControlLabel
                        value="Inclusive"
                        control={
                          <NewRadio
                            checked={
                              state?.vat_applicability ===
                              "Inclusive"
                            }
                          />
                        }
                        label="Inclusive"
                      />
                    </RadioGroup>
                  </div>

                </>
              )}
          </>
        }
      </div>


      <div className="modal fade" id="AgreementModal" role="dialog">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content payment-modal">
            <div
              className="modal-header border-0"
              style={{ backgroundColor: "#f1f1f1" }}
            >
              <h6
                className="modal-title"
                style={{ border: "none", padding: 0 }}
              >
                Barging Agreements
              </h6>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
              >
                &times;
              </button>
            </div>
            <div className="modal-body">

              {bargeAgreements?.length > 0 &&

                <div className="table-responsive">

                  <table className="table table-sm">
                    <thead className="table-header">
                      <tr>
                        <th className='text-center border ' nowrap="true"></th>
                        <th className='text-center border text-uppercase' nowrap="true">Area</th>
                        <th className='text-center border text-uppercase' nowrap="true">Price</th>
                        <th className='text-center border text-uppercase' nowrap="true">Currency</th>
                        <th className='text-center border text-uppercase' nowrap="true">Barging Type</th>
                        <th className='text-center border text-uppercase' nowrap="true">VAT Applicability</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bargeAgreements?.map((item, idx) => (
                        <tr key={item.price_list_id}>
                          <td className='text-center border'>
                            <NewRadio
                              onClick={() => {
                                let stateCopy = {
                                  ...state,
                                  ...item,
                                  barging_agreement_price_list_id: item.price_list_id,
                                  barging_base_price: item.barging_type === "Per MT" ? item.price : null,
                                  lumpsum_price: item.barging_type === "Lumpsum" ? item.price : null,
                                  barging_base_price_currency: item.barging_type === "Per MT" ? item.currency : null,
                                  lumpsum_price_currency: item.barging_type === "Lumpsum" ? item.currency : null,
                                  vat_applicability: item.vat_incl_or_excl
                                }
                                setSelectAgreement(item);
                                setState(stateCopy);
                                setSelectOthers(null)
                                callbackFn(stateCopy)
                              }}
                              checked={item.price_list_id === slecteAgreement?.price_list_id}
                              color="primary"
                              size="small"
                            />
                          </td>
                          <td className='text-center text-uppercase border'>{item.area}</td>
                          <td className='text-center border'>{toLocaleString(item.price)}</td>
                          <td className='text-center border'>{item.currency}</td>
                          <td className='text-center border'>{item.barging_type}</td>
                          <td className='text-center border'>{item.vat_incl_or_excl}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

              }

              {bargeAgreements?.length === 0 &&
                <p className='text-center'>No Barging Agreements for this vendor</p>
              }
              <RadioGroup
                aria-label="others"
                name="others"
                onChange={(e) => {
                  let stateCopy = {
                    ...state,
                    barging_agreement_price_list_id: null,
                    barging_base_price: null,
                    barging_type: "Per MT",
                    lumpsum_price: "",
                    vat_applicability: "Exclusive",
                    barging_base_price_currency: '0',
                    lumpsum_price_currency: '0'
                  }
                  setSelectOthers(e.target.value);
                  setState(stateCopy);
                  setSelectAgreement(null);
                }}
                style={{ flexDirection: "row" }}
              >
                <FormControlLabel
                  value="Others"
                  control={
                    <NewRadio
                      checked={
                        slecteOthers ===
                        "Others"
                      }
                    />
                  }
                  label="Others"
                />
              </RadioGroup>
            </div>
            <div className="row bottom_buttons_section">
              <button
                type="button"
                className="btn previous_button"
                name="submit"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                data-dismiss="modal"
                className="btn next_button"
                onClick={() => { }}
              >
                Submit
              </button>
            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export default BargeAgreements;