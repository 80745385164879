import React, { Component } from 'react';

import SideBar from './SideBar';
import Header from './Header';

import PostAddIcon from '@material-ui/icons/PostAdd';
import DescriptionIcon from '@material-ui/icons/Description';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import GroupRoundedIcon from '@material-ui/icons/GroupRounded';
import AccountCircle from '@material-ui/icons/AccountCircle';
import BusinessIcon from '@material-ui/icons/Business';

import '../../App.css';
import api from '../../api/api';
import config from '../../config/config';
import CookieHandler from './CookieHandler';
import FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.charts';
import ReactFC from 'react-fusioncharts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import Loader from './Loader';

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);

// import { PowerBIEmbed } from 'powerbi-client-react';

// import {models} from 'powerbi-client';


const chartConfigs = {
  type: 'column2d',
  width: 600,
  height: 400,
  dataFormat: 'json',
  dataSource: {
    "chart": {
      "caption": "Countries With Most Mines Reserves [2017-18]",
      "subCaption": "In MMbbl = One Million barrels",
      "xAxisName": "Country",
      "yAxisName": "Reserves (MMbbl)",
      "numberSuffix": "K",
      "theme": "fusion"
    },
    "data": [
      {
        "label": "Users",
        "value": "290"
      },
      {
        "label": "vendors",
        "value": "260"
      },
      {
        "label": "Customers",
        "value": "180"
      },
      {
        "label": "Purchase",
        "value": "140"
      },
      {
        "label": "Sales",
        "value": "115"
      },
      {
        "label": "Mines",
        "value": "100"
      },
      {
        "label": "Others",
        "value": "30"
      },

    ]
  }
}


export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      totalUsers: [],
      totalCustomers: [],
      totalVendors: [],
      totalPurchases: [],
      totalSales: [],
      businessNumbers: [],
      isLoading: true,
      errorMessage: "",
      successMessage: "",
    }
  }

  componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace('/logout');
    }
    else {
      var loginUserID = this.Cookie.getCookie('loginUserId');
      var idToken = this.Cookie.getIdTokenCookie();
      var status = 'Active';
      api.getUserList(loginUserID, idToken).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === '200') {
              if (res.idtoken)
                this.Cookie.setIdTokenCookie(res.idtoken);
              if (res.users) {
                this.setState({
                  totalUsers: res.users
                })
              }
            }
            else if (res.code === "607") {
              window.location.href = '/logout'
            }
          })
        }
      })

      api.getCustomers(loginUserID, idToken, status).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.idtoken) {
              this.Cookie.setIdTokenCookie(res.idtoken);
            }
            if (res.code === '200') {
              if (res.customers) {
                this.setState({
                  totalCustomers: res.customers
                })
              }
            }
            if (res.code === '607') {
              window.location.href = '/logout';
            }
          });
        }
      })
      api.getVendors(loginUserID, idToken, status).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.vendors)
              this.Cookie.setIdTokenCookie(res.idtoken);
            if (res.vendors) {
              this.setState({
                totalVendors: res.vendors
              })
            }
          })
        }
        else if (response.status === 607) {
          window.location.href = '/logout'
        }
      })

      api.getPurchaseContracts(loginUserID, status, idToken).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.idtoken)
              this.Cookie.setIdTokenCookie(res.idtoken);
            if (res.purchase_contract) {
              this.setState({
                totalPurchases: res.purchase_contract,
              })
            }
          })
        }
        else if (response.status === 607) {
          window.location.href = '/logout';
        }
      })

      api.getSalesContractList(loginUserID, idToken).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.idtoken)
              this.Cookie.setIdTokenCookie(res.idtoken);
            if (res.sale_contract) {
              this.setState({
                totalSales: res.sale_contract
              });
            }
            if (res.code === '607') {
              window.location.href = '/logout';
            }
          })
        }
      })

      api.getBusinessNumbers(loginUserID, idToken).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.idtoken)
              this.Cookie.setIdTokenCookie(res.idtoken);
            if (res) {
              if (res.code === '200') {
                this.setState({
                  businessNumbers: res.business_numbers,
                  isLoading: false,
                });
              }
              if (res.code === '607') {
                window.location.href = '/logout';
              }

            }
            else {
              alert('Unexpected error occured. Please contact administrator.');
            }
          })
        }
      })
    }

    document.title = config.documentTitle + "Dashboard";
  }

  render() {
    return (
      <div id='wraper'>
        <SideBar />
        <div className='content'>
          <Header />
          <div className='content-header p-2'>
            <h4 className="page_heading">Dashboard</h4>
          </div>
          {this.state.successMessage &&
            <div className='row'>
              <div className='col text-center'>
                <p className='success-class'>{this.state.successMessage}</p>
              </div>
            </div>
          }
          {this.state.errorMessage &&
            <div className='row'>
              <div className='col text-center'>
                <p className='error-class'>{this.state.errorMessage}</p>
              </div>
            </div>
          }
          {this.state.isLoading &&
           <Loader />
          }
          {!this.state.isLoading &&
            <div className='container' style={{ backgroundColor: "rgb(249, 249, 252)", boxShadow: 'none', padding: 0 }}>
              <div>
                <div className='row'>
                  <div className='col-sm dashboard-cards' >
                    <span style={{ float: 'right' }}><AccountCircle className='listIcons' style={{ fontSize: '45px', color: 'blue' }} /> </span>
                    <h4>{this.state.totalUsers.length}</h4>
                    <p>Users</p>
                  </div>
                  <div className='col-sm dashboard-cards'>
                    <span style={{ float: 'right' }}><SupervisedUserCircleIcon className='listIcons' style={{ fontSize: '45px', color: 'aqua' }} /> </span>
                    <h4>{this.state.totalVendors.length}</h4>
                    <p>Vendors</p>
                  </div>
                  <div className='col-sm dashboard-cards' >
                    <span style={{ float: 'right' }}><GroupRoundedIcon className='listIcons' style={{ fontSize: '45px', color: 'green' }} /> </span>
                    <h4>{this.state.totalCustomers.length}</h4>
                    <p> Customers</p>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm dashboard-cards' >
                    <span style={{ float: 'right' }}><PostAddIcon className='listIcons' style={{ fontSize: '45px', color: 'crimson' }} /> </span>
                    <h4>{this.state.totalPurchases.length}</h4>
                    <p> Purchase Contracts</p>
                  </div>
                  <div className='col-sm dashboard-cards'>
                    <span style={{ float: 'right' }}><DescriptionIcon className='listIcons' style={{ fontSize: '45px', color: 'orange' }} /> </span>
                    <h4>{this.state.totalSales.length}</h4>
                    <p> Sales Contracts</p>
                  </div>
                  <div className='col-sm dashboard-cards'>
                    <span style={{ float: 'right' }}><BusinessIcon className='listIcons' style={{ fontSize: '45px', color: '#2196f3' }} /> </span>
                    <h4>{this.state.businessNumbers.length}</h4>
                    <p> Business Numbers</p>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm dashboard-cards' style={{ overflow: 'auto' }}>
                    <ReactFC {...chartConfigs} />
                    {/* <PowerBIEmbed
                      embedConfig={{
                        type: 'report',   // Supported types: report, dashboard, tile, visual and qna
                        id: '<Report Id>',
                        embedUrl: '<Embed Url>',
                        accessToken: '<Access Token>',
                        tokenType: models.TokenType.Embed,
                        settings: {
                          panes: {
                            filters: {
                              expanded: false,
                              visible: false
                            }
                          },
                          background: models.BackgroundType.Transparent,
                        }
                      }}

                      eventHandlers={
                        new Map([
                          ['loaded', function () { console.log('Report loaded'); }],
                          ['rendered', function () { console.log('Report rendered'); }],
                          ['error', function (event) { console.log(event.detail); }]
                        ])
                      }

                      cssClassName={"report-style-class"}

                      getEmbeddedComponent={(embeddedReport) => {
                        window.report = embeddedReport;
                      }}
                    />
                     */}
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    )
  }
}