import React, { Component } from "react";
import CookieHandler from "../../common/CookieHandler";
import { Alert } from "@material-ui/lab";
import {
  MenuItem,
  TextField,
  Snackbar,
  LinearProgress,
  Radio,
  FormControlLabel,
  RadioGroup,
} from "@material-ui/core";
import config from "../../../config/config";
import api from "../../../api/api";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { withStyles } from "@material-ui/core/styles";
import FileUpload from "../../common/FileUpload";
import Loader from "../../common/Loader";
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 6,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#42352d",
  },
}))(LinearProgress);

const NewRadio = withStyles({
  root: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export default class UpdateSalesPI extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      activeStep: 1,
      isLoading: true,
      bargeList: [],
      salesTypes: [],
      sales_quality: "",
      sales_type: "",
      barges: [],
      selected_barges: [],
      performa_costing_barges: [],
      sales_index_linked: "No",
      coal_cost_billing_currency:
        config.company === "SRPL" ||
          config.company === "AVS" ||
          config.company === "PRPL"
          ? "USD"
          : "IDR",
      invoice_no: "",
      invoice_date: null,
      ppn: "",
      coal_cost_coal_price: "",
      coal_cost_gar_penality: "",
      coal_cost_ash_penality: "",
      coal_cost_sulphur_penality: "",
      coal_cost_gar_bonus: "",
      coal_cost_adjusted_coal_price: "",
      coal_cost_pph22_export: "Yes",
      coal_cost_pph22_export_amount: "",
      coal_cost_pph22_percent: "1.5",
      coal_cost_pph22_percent_val: "",
      coal_cost_adjusted_coal_price_pmt: "",
      coal_cost_pph22_export_percent: "1.5",
      coal_cost_pph22_export_value: "",
      vat_applicable: "Yes",
      vat_amount: "",
      vat_percent: "10",
      vat_value: "",
      coal_cost_currency_xchg_rate: "",

      coal_cost_adjusted_coal_price_in_local_currency: "",

      receivable_from_supplier: "",
      advance_adjustment: "No",
      adjustment_per_mt: "",
      advance_adjustment_value: "",
      //pph15_account :'0',
      coalPriceCurrency: null,

      advance_adjustment_barge_quantity: "",
      barge_quantity: "",
      coal_cost_coal_quantity: "",

      // Advance Adjustment Rows
      advance_adjustments: [
        {
          advance_adjustment: "No",
          vendor_adv_contract_id: "",
          advance_adjustment_value: "",
          advance_adjustment_barge_quantity: "",
          adjustment_per_mt: "",
          adjustment_from: "",
          sales_contract_id: "",
          remaining_cus_adv_payment: "",
        },
      ],

      coal_cost_pph22_export_value_USD: "",
      coal_cost_pph22_export_value_IDR: "",
      coal_cost_pph22_percent_val_IDR: "",

      sales_term: "",

      quality_calorific_value_typical: "",
      quality_calorific_value_rejection: "",
      quality_calorific_value_basis: "",
      quality_calorific_value2_typical: "",
      quality_calorific_value2_rejection: "",
      quality_calorific_value2_basis: "",
      quality_calorific_value3_typical: "",
      quality_calorific_value3_rejection: "",
      quality_calorific_value3_basis: "",

      quality_ash_typical: "",
      quality_ash_rejection: "",
      quality_ash_basis: "",

      quality_total_sulphur_typical: "",
      quality_total_sulphur_rejection: "",
      quality_total_sulphur_basis: "",

      quality_calorific_value_actual: "",
      quality_calorific_value2_actual: "",
      quality_calorific_value3_actual: "",
      quality_ash_actual: "",
      quality_total_sulphur_actual: "",

      // Advance Adjustment Value
      sales_contract_id: "",
      pc_adv_remaining_amount: "",
      expanded: true,

      features: [],
      salesBargeList: [],
      salesDataforMenu: [],
      selectedSalesContractId: "",
      coal_cost_coal_price_pmt_local_currency: "",
      total_barge_quantity: null,
      sales_price_pmt: 0,
      files: [
        {
          file_description: "",
          file_name: "",
          file_extension: "",
          file_content: "",
          feature_name: "Sales_fin_performa_coal_costing",
        },
      ],
      costing_files: [],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    var features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    const idToken = this.Cookie.getIdTokenCookie();
    const loginUserID = this.Cookie.getCookie("loginUserId");

    // get vat value api
    const tax_type = "vat";
    const get_vat = await api.get_tax_percentages(
      loginUserID,
      idToken,
      tax_type
    );
    if (get_vat.status >= 200 && get_vat.status < 300) {
      get_vat.json().then((res) => {
        if (res.code === "200") {
          if (res.tax_percentages) {
            this.setState({
              vat_percent:
                res.tax_percentages.length > 0
                  ? res.tax_percentages[0].tax_percentage
                  : 10,
            });
          } else {
            alert("Unexpected error occured. Please contact administrator.");
          }
        } else if (res.code === "601") {
          this.setState({
            snackBarErrorOpen: true,
            errorMsg: res.message,
          });
        } else if (res.code === "607") {
          window.location.href = "/logout";
        }
      });
    }

    const businessNoID = this.props.businessNoID;
    await api
      .get_sales_contracts_by_business_no(loginUserID, idToken, businessNoID)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.sales_contract_data) {
                this.setState({
                  salesDataforMenu: res.sales_contract_data,
                  //isLoading: false
                });
              }
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
    const mv_sales_costing_id = this.props.mv_sales_costing_id;

    await api
      .get_mv_sales_performa_coal_costing(
        loginUserID,
        idToken,
        mv_sales_costing_id
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.mv_sales_performa) {
                // var totalAdjustments = 0;
                // for (var i of res.mv_sales_performa.advance_adjustments) {
                //   totalAdjustments += Number(i.advance_adjustment_value);
                // }
                let coal_cost_coal_price_pmt_local_currency = (
                  Number(res.mv_sales_performa.coal_price_pmt) *
                  Number(res.mv_sales_performa.currency_xchg_rate)
                ).toFixed(2);
                if (
                  (config.company === "SDAM" ||
                    config.company === "SRK" ||
                    config.company === "BTR") &&
                  res.mv_sales_performa.billing_currency === "IDR"
                ) {
                  coal_cost_coal_price_pmt_local_currency = (
                    Number(res.mv_sales_performa.coal_price_pmt) *
                    Number(res.mv_sales_performa.currency_xchg_rate)
                  ).toFixed(0);
                }
                if (
                  config.company === "SRPL" ||
                  config.company === "AVS" ||
                  config.company === "PRPL"
                ) {
                  coal_cost_coal_price_pmt_local_currency = (
                    Number(res.mv_sales_performa.coal_price_pmt) /
                    Number(res.mv_sales_performa.currency_xchg_rate)
                  ).toFixed(2);
                }

                this.setState(
                  {
                    sales_contract_id: res.mv_sales_performa.sale_contract_id,
                    sc_qualities_id: res.mv_sales_performa.sc_qualities_id,
                    sc_sales_type_id: res.mv_sales_performa.sc_sales_type_id,
                    invoice_file: res.mv_sales_performa.invoice_file,
                    costing_no: res.mv_sales_performa.costing_no
                      ? res.mv_sales_performa.costing_no
                      : null,
                    invoice_no: res.mv_sales_performa.invoice_no
                      ? res.mv_sales_performa.invoice_no
                      : null,
                    invoice_date: res.mv_sales_performa.barge_quantity
                      ? res.mv_sales_performa.invoice_date
                      : null,
                    ppn: res.mv_sales_performa.ppn
                      ? res.mv_sales_performa.ppn
                      : "",
                    ppn_date: res.mv_sales_performa.ppn_date
                      ? res.mv_sales_performa.ppn_date
                      : null,
                    coal_cost_coal_quantity: res.mv_sales_performa
                      .barge_quantity
                      ? res.mv_sales_performa.barge_quantity
                      : 0,
                    barge_quantity: res.mv_sales_performa.barge_quantity
                      ? res.mv_sales_performa.barge_quantity
                      : 0,
                    coal_cost_coal_price_pmt: res.mv_sales_performa
                      .coal_price_pmt
                      ? res.mv_sales_performa.coal_price_pmt
                      : 0,
                    sales_price_pmt: res.mv_sales_performa.coal_price_pmt
                      ? res.mv_sales_performa.coal_price_pmt
                      : 0,
                    sales_index_linked:
                      res.mv_sales_performa.sales_index_linked,
                    sales_currency: res.mv_sales_performa.sales_currency,
                    coalPriceCurrency: res.mv_sales_performa.sales_currency,

                    coal_cost_coal_price_pmt_local_currency:
                      res.mv_sales_performa.coal_price_pmt &&
                        res.mv_sales_performa.currency_xchg_rate
                        ? coal_cost_coal_price_pmt_local_currency
                        : null,
                    coal_cost_coal_price: res.mv_sales_performa.coal_price
                      ? res.mv_sales_performa.coal_price
                      : "",
                    coal_cost_billing_currency: res.mv_sales_performa
                      .billing_currency
                      ? res.mv_sales_performa.billing_currency
                      : "",
                    coal_cost_penality_currency: res.mv_sales_performa
                      .penality_currency
                      ? res.mv_sales_performa.penality_currency
                      : "",
                    coal_cost_adjusted_coal_price_pmt: res.mv_sales_performa
                      .adjusted_coal_price_pmt
                      ? res.mv_sales_performa.adjusted_coal_price_pmt
                      : "",
                    coal_cost_adjusted_coal_price: res.mv_sales_performa
                      .adjusted_coal_price
                      ? res.mv_sales_performa.adjusted_coal_price
                      : "",
                    coal_cost_currency_xchg_rate: res.mv_sales_performa
                      .currency_xchg_rate
                      ? res.mv_sales_performa.currency_xchg_rate
                      : "",
                    currency_xchg_rate: res.mv_sales_performa.currency_xchg_rate
                      ? res.mv_sales_performa.currency_xchg_rate
                      : "",
                    coal_cost_adjusted_coal_price_in_local_currency: res
                      .mv_sales_performa.adjusted_coal_price_in_local_currency
                      ? res.mv_sales_performa
                        .adjusted_coal_price_in_local_currency
                      : "",
                    coal_cost_pph22_barge_quantity_in_mt: res.mv_sales_performa
                      .pph22_barge_quantity
                      ? res.mv_sales_performa.pph22_barge_quantity
                      : "",
                    coal_cost_pph22_coal_price_per_mt: res.mv_sales_performa
                      .pph22_coal_price_pmt
                      ? res.mv_sales_performa.pph22_coal_price_pmt
                      : "",
                    coal_cost_pph22_percent: res.mv_sales_performa.pph22_percent
                      ? res.mv_sales_performa.pph22_percent
                      : "",
                    coal_cost_pph22_percent_val: res.mv_sales_performa
                      .pph22_value
                      ? res.mv_sales_performa.pph22_value
                      : "",
                    coal_cost_pph22_export: res.mv_sales_performa
                      .pph22_export_applicable
                      ? res.mv_sales_performa.pph22_export_applicable
                      : "No",
                    coal_cost_pph22_export_amount: res.mv_sales_performa
                      .pph22_export_amount
                      ? res.mv_sales_performa.pph22_export_amount
                      : "",
                    coal_cost_pph22_export_percent: res.mv_sales_performa
                      .pph22_export_percent
                      ? res.mv_sales_performa.pph22_export_percent
                      : "",
                    coal_cost_pph22_export_value: res.mv_sales_performa
                      .pph22_export_value
                      ? res.mv_sales_performa.pph22_export_value
                      : "",
                    coal_cost_pph22_export_value_local_cur_new: res
                      .mv_sales_performa.pph22_export_value
                      ? res.mv_sales_performa.pph22_export_value
                      : "",
                    coal_cost_pph22_export_value_local_cur: res
                      .mv_sales_performa.pph22_export_value
                      ? res.mv_sales_performa.pph22_export_value
                      : "",
                    receivable_from_supplier: res.mv_sales_performa
                      .receivable_from_supplier
                      ? res.mv_sales_performa.receivable_from_supplier
                      : "",
                    selectedSalesContractId: res.mv_sales_performa
                      .sales_contract_id
                      ? res.mv_sales_performa.sales_contract_id
                      : "",
                    coalPricePerMT: res.mv_sales_performa.coal_price_pmt
                      ? res.mv_sales_performa.coal_price_pmt
                      : "",
                    advance_adjustments:
                      res.mv_sales_performa.advance_adjustments,
                    vat_applicable: res.mv_sales_performa.vat_applicable
                      ? res.mv_sales_performa.vat_applicable
                      : "Yes",
                    vat_amount: res.mv_sales_performa.vat_amount,
                    vat_percent: res.mv_sales_performa.vat_percent
                      ? res.mv_sales_performa.vat_percent
                      : 10,
                    vat_value: res.mv_sales_performa.vat_value,
                    files: res.mv_sales_performa.mv_performa_files
                      ? res.mv_sales_performa.mv_performa_files
                      : [],
                    sales_quality: res.mv_sales_performa.quality,
                    sales_type: res.mv_sales_performa.sales_type,
                    selected_sc: {
                      contract_no: res.mv_sales_performa.sales_contract_no,
                      sales_contract_id: res.mv_sales_performa.sale_contract_id,
                    },
                    isLoading: false,
                  },
                  () => {
                    if (res.mv_sales_performa.sale_contract_id) {
                      const salesContractId =
                        res.mv_sales_performa.sale_contract_id;
                      api
                        .getSalesContract(
                          loginUserID,
                          salesContractId,
                          idToken,
                          "Active"
                        )
                        .then((response) => {
                          if (response.status >= 200 && response.status < 300) {
                            response.json().then((sc_res) => {
                              if (sc_res.code === "200") {
                                if (sc_res.sales_contract) {
                                  this.setState(
                                    {
                                      sales_contract_no:
                                        sc_res.sales_contract.contract_no,
                                      salesTypes:
                                        sc_res.sales_contract.qualities,
                                      salesCurrency:
                                        sc_res.sales_contract.currency,
                                      coalPriceCurrency:
                                        sc_res.sales_contract.currency,
                                      //sales_currency: sc_res.sales_contract.currency,
                                      //sales_index_linked: sc_res.sales_contract.is_index_linked,
                                      //isLoading: false
                                    },
                                    () => {
                                      //this.callingAsync()
                                      // const filter_sales_quality = sc_res.sales_contract.qualities.filter(e => e.id === res.mv_sales_performa.sc_qualities_id);
                                      // const filter_sales_type = filter_sales_quality.length > 0 ? filter_sales_quality[0].sales_types.filter(e => e.id === res.mv_sales_performa.sc_sales_type_id) : [];
                                      // this.setState({
                                      //   sales_quality: filter_sales_quality.length > 0 ? filter_sales_quality[0].quality : '',
                                      //   sales_type: filter_sales_type.length > 0 ? filter_sales_type[0].sales_type : '',
                                      //   isLoading: false
                                      //coal_cost_coal_price_pmt: this.state.price_pmt,
                                      // })
                                    }
                                  );
                                  // window.$("#salesContractModal").modal({
                                  //   backdrop: 'static',
                                  //   keyboard: true,
                                  //   show: true
                                  // });
                                } else {
                                  alert(
                                    "Unexpected error occured. Please contact administrator."
                                  );
                                }
                              } else if (sc_res.code === "601") {
                                this.setState({
                                  snackBarErrorOpen: true,
                                  errorMsg: sc_res.message,
                                });
                              } else if (sc_res.code === "607") {
                                window.location.href = "/logout";
                              }
                            });
                          }
                        });
                    }
                  }
                );
              }
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  }

  async handleSalesContract(value) {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    if (value) {
      const salesContractId = value.sales_contract_id;
      const status = "";
      api
        .getSalesContract(loginUserID, salesContractId, idToken, status)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                if (res.sales_contract) {
                  this.setState(
                    {
                      salesTypes: res.sales_contract.qualities,
                      salesCurrency: res.sales_contract.currency,
                      sales_currency: res.sales_contract.currency,
                      coalPriceCurrency: res.sales_contract.currency,
                    },
                    () => {
                      window.$("#scModal").modal({
                        backdrop: "static",
                        keyboard: true,
                        show: true,
                      });
                    }
                  );
                } else {
                  alert(
                    "Unexpected error occured. Please contact administrator."
                  );
                }
              } else if (res.code === "601") {
                this.setState({
                  snackBarErrorOpen: true,
                  errorMsg: res.message,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    } else {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Invalid sales contract",
      });
    }
  }

  progressBarSize(data, type) {
    if (data) {
      if (type === "NSC") {
        var remainingSalesQuality =
          Number(
            Number(data.quantity_allocated) / Number(data.quantity_adjusted)
          ) * 100;
        return remainingSalesQuality;
      }
    }
  }

  _toBeDelivered(data) {
    if (data) {
      if (data.sale_contract_id) {
        var remainingSalesQuality = Number(
          Number(data.quantity_adjusted) - Number(data.quantity_allocated)
        );
        return remainingSalesQuality;
      }
    }
  }

  toLocaleString(value) {
    return value ? Number(value).toLocaleString() : 0;
  }

  async callingAsync(bargeId) {
    var sales_currency = this.state.sales_currency;
    let coal_cost_coal_price_pmt = this.state.sales_price_pmt;
    var barge_quantity = this.state.barge_quantity;

    var coal_cost_coal_price = "";
    if (barge_quantity !== null && barge_quantity !== 0) {
      coal_cost_coal_price = (
        Number(barge_quantity) * Number(this.state.sales_price_pmt)
      ).toFixed(2);
    }

    var coal_cost_currency_xchg_rate = this.state.coal_cost_currency_xchg_rate;
    if (coal_cost_currency_xchg_rate === "") {
      coal_cost_currency_xchg_rate = 1;
    }

    let sales_price_pmt = (
      Number(coal_cost_coal_price_pmt) * Number(coal_cost_currency_xchg_rate)
    ).toFixed(2);

    var coal_cost_adjusted_coal_price_pmt = Number(
      Number(sales_price_pmt)
    ).toFixed(2);

    // console.log('coal_cost_adjusted_coal_price_pmt', coal_cost_adjusted_coal_price_pmt);
    // var coal_cost_adjusted_coal_price_pmt = Number(res.sales_price_pmt) + Number(coal_cost_gar_bonus) - Number(coal_cost_gar_penality) - Number(coal_cost_ash_penality) - Number(coal_cost_sulphur_penality);
    var coal_cost_adjusted_coal_price = (
      Number(barge_quantity) * Number(coal_cost_adjusted_coal_price_pmt)
    ).toFixed(2);
    var coal_cost_adjusted_coal_price_in_local_currency = Number(
      coal_cost_adjusted_coal_price
    ).toFixed(0);

    var coal_cost_pph22_export_percent = 1.5;
    var coal_cost_pph22_export_value = Number(
      (Number(coal_cost_adjusted_coal_price_in_local_currency) *
        Number(coal_cost_pph22_export_percent)) /
      100
    ).toFixed(0);

    var coal_cost_pph22_percent_val = "";
    var vat_amount = Number(coal_cost_adjusted_coal_price_in_local_currency);
    var vat_percent = this.state.vat_percent ? this.state.vat_percent : 10;
    var vat_value =
      config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL"
        ? 0
        : (Number(vat_amount) * Number(this.state.vat_percent)) / 100;

    var total_advance_adjustment_value = 0;
    for (var i of this.state.advance_adjustments) {
      total_advance_adjustment_value += Number(i.advance_adjustment_value);
    }

    var receivable_from_supplier = (
      Number(barge_quantity) * Number(coal_cost_adjusted_coal_price_pmt) +
      Number(vat_value) -
      Number(coal_cost_pph22_percent_val) -
      Number(coal_cost_pph22_export_value) -
      Number(total_advance_adjustment_value)
    ).toFixed(2);
    var coal_cost_pph22_export_value_local_cur = "";
    var coal_cost_pph22_percent_val_local_cur = "";

    if (
      ((config.company === "SDAM" ||
        config.company === "SRK" ||
        config.company === "BTR") &&
        sales_currency !== "IDR") ||
      ((config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL") &&
        sales_currency !== "USD")
    ) {
      if (
        config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL"
      ) {
        coal_cost_pph22_export_value_local_cur = coal_cost_pph22_export_value;
        receivable_from_supplier = Number(
          Number(coal_cost_adjusted_coal_price_in_local_currency) +
          Number(vat_value) -
          Number(coal_cost_pph22_export_value_local_cur)
        ).toFixed(2);
      } else {
        let total_pph22_local_amount =
          Number(barge_quantity) * Number(coal_cost_adjusted_coal_price_pmt);
        coal_cost_pph22_percent_val = Number(
          (Number(total_pph22_local_amount) *
            Number(this.state.coal_cost_pph22_percent)) /
          100
        );
        coal_cost_pph22_percent_val_local_cur = Number(
          coal_cost_pph22_percent_val
        ).toFixed(0);

        coal_cost_pph22_export_value = Number(
          (Number(coal_cost_adjusted_coal_price) *
            Number(coal_cost_pph22_export_percent)) /
          100
        );
        coal_cost_pph22_export_value_local_cur = Number(
          coal_cost_pph22_export_value
        ).toFixed(0);

        receivable_from_supplier = (
          Number(coal_cost_adjusted_coal_price_in_local_currency) +
          Number(vat_value) -
          Number(coal_cost_pph22_percent_val_local_cur) -
          Number(coal_cost_pph22_export_value_local_cur)
        ).toFixed(2);
      }
    } else {
      if (
        config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL"
      ) {
        coal_cost_pph22_export_value_local_cur = coal_cost_pph22_export_value;
        receivable_from_supplier = Number(
          Number(coal_cost_adjusted_coal_price_in_local_currency) -
          Number(coal_cost_pph22_export_value_local_cur)
        ).toFixed(2);
      } else {
        let total_pph22_local_amount =
          Number(barge_quantity) * Number(coal_cost_adjusted_coal_price_pmt);
        coal_cost_pph22_percent_val = Number(
          (Number(total_pph22_local_amount) *
            Number(this.state.coal_cost_pph22_percent)) /
          100
        );
        coal_cost_pph22_percent_val_local_cur = Number(
          coal_cost_pph22_percent_val
        ).toFixed(0);

        coal_cost_pph22_export_value = Number(
          (Number(coal_cost_adjusted_coal_price) *
            Number(coal_cost_pph22_export_percent)) /
          100
        );
        coal_cost_pph22_export_value_local_cur = Number(
          coal_cost_pph22_export_value
        ).toFixed(0);

        receivable_from_supplier = (
          Number(coal_cost_adjusted_coal_price_in_local_currency) +
          Number(vat_value) -
          Number(coal_cost_pph22_percent_val_local_cur) -
          Number(coal_cost_pph22_export_value_local_cur)
        ).toFixed(0);
      }
    }
    let coal_cost_coal_price_pmt_local_currency = (
      Number(this.state.sales_price_pmt) * Number(coal_cost_currency_xchg_rate)
    ).toFixed(2);
    if (
      (config.company === "SDAM" ||
        config.company === "SRK" ||
        config.company === "BTR") &&
      this.state.coal_cost_billing_currency === "IDR"
    ) {
      coal_cost_coal_price_pmt_local_currency = (
        Number(this.state.sales_price_pmt) *
        Number(coal_cost_currency_xchg_rate)
      ).toFixed(0);
      coal_cost_adjusted_coal_price_pmt = Number(
        coal_cost_adjusted_coal_price_pmt
      ).toFixed(0);
      coal_cost_adjusted_coal_price = Number(
        coal_cost_adjusted_coal_price
      ).toFixed(0);
    }
    this.setState(
      {
        // sales_index_linked: res.sales_index_linked,
        // advance_adjustments: row,

        // sales_price_pmt: res.sales_price_pmt,
        // coal_cost_coal_quantity: barge_quantity ? barge_quantity : '',
        coal_cost_coal_price: Number(
          Number(coal_cost_coal_price) * Number(coal_cost_currency_xchg_rate)
        ).toFixed(2),
        coalPriceCurrency: this.state.sales_currency,
        coal_cost_coal_price_pmt_local_currency:
          coal_cost_coal_price_pmt_local_currency,
        sales_price_pmt: Number(this.state.sales_price_pmt).toFixed(0),
        coal_cost_coal_price_pmt: Number(this.state.sales_price_pmt).toFixed(2),
        coal_cost_currency_xchg_rate:
          coal_cost_currency_xchg_rate !== 1
            ? Number(coal_cost_currency_xchg_rate)
            : "",

        coal_cost_adjusted_coal_price: coal_cost_adjusted_coal_price,
        coal_cost_adjusted_coal_price_pmt: Number(
          coal_cost_adjusted_coal_price_pmt
        ),
        coal_cost_adjusted_coal_price_in_local_currency:
          coal_cost_adjusted_coal_price_in_local_currency,
        vat_amount: vat_amount,
        vat_percent: vat_percent,
        vat_value: vat_value,

        receivable_from_supplier: receivable_from_supplier,

        coal_cost_pph22_barge_quantity_in_mt: barge_quantity
          ? Number(barge_quantity)
          : "",
        coal_cost_pph22_coal_price_per_mt: Number(
          coal_cost_adjusted_coal_price_pmt
        ),
        coal_cost_pph22_percent_val: Number(
          coal_cost_pph22_percent_val
        ).toFixed(0),
        coal_cost_pph22_percent_val_local_cur:
          coal_cost_pph22_percent_val_local_cur,
        coal_cost_pph22_percent_val_local_cur_new:
          coal_cost_pph22_percent_val_local_cur,

        coal_cost_pph22_export_amount: Number(coal_cost_adjusted_coal_price),
        coal_cost_pph22_export_percent: coal_cost_pph22_export_percent,
        coal_cost_pph22_export_value: Number(
          coal_cost_pph22_export_value
        ).toFixed(0),
        coal_cost_pph22_export_value_local_cur: Number(
          coal_cost_pph22_export_value_local_cur
        ).toFixed(0),
        coal_cost_pph22_export_value_local_cur_new: Number(
          coal_cost_pph22_export_value_local_cur
        ).toFixed(0),
      },
      () => {
        this.onAdvanceAdjustmentsCal();
      }
    );
  }

  handleRadioButtons = (e) => {
    if (e.target.value === "No") {
      this.setState(
        {
          [e.target.name]: e.target.value,
          coal_cost_pph22_export_value: 0,
          coal_cost_pph22_export_value_local_cur: 0,
        },
        () => {
          this.onPph22ExportDataChange();
        }
      );
    } else {
      this.setState(
        {
          [e.target.name]: e.target.value,
        },
        () => {
          this.bargeQuantityChange();
        }
      );
    }
  };

  onAdvanceAdjustmentsCal = () => {
    var row = this.state.advance_adjustments;
    var total_advance_adjustment_value = 0;
    for (var i of row) {
      total_advance_adjustment_value += Number(i.advance_adjustment_value);
    }
    var {
      coal_cost_adjusted_coal_price_in_local_currency,
      vat_value,
      coal_cost_pph22_percent_val_local_cur,
      coal_cost_pph22_export_value_local_cur,
    } = this.state;

    var display_pph22 =
      Number(coal_cost_pph22_percent_val_local_cur) +
      Number(coal_cost_pph22_export_value_local_cur);
    if (
      config.company === "SRPL" ||
      config.company === "AVS" ||
      config.company === "PRPL"
    ) {
      display_pph22 = Number(coal_cost_pph22_export_value_local_cur);
    }

    var payable_to_supplier =
      Number(coal_cost_adjusted_coal_price_in_local_currency) +
      Number(vat_value) -
      Number(coal_cost_pph22_percent_val_local_cur) -
      Number(coal_cost_pph22_export_value_local_cur) -
      Number(total_advance_adjustment_value);
    if (
      config.company === "SRPL" ||
      config.company === "AVS" ||
      config.company === "PRPL"
    ) {
      payable_to_supplier =
        Number(coal_cost_adjusted_coal_price_in_local_currency) -
        Number(coal_cost_pph22_export_value_local_cur) -
        Number(total_advance_adjustment_value);
    }
    this.setState({
      receivable_from_supplier: Number(payable_to_supplier).toFixed(2),
      total_advance_adjustment_value: Number(
        total_advance_adjustment_value
      ).toFixed(0),
      display_pph22: Number(display_pph22).toFixed(0),
      advance_adjustments: row,
      isLoading: false,
    });
  };

  //Barge Data calculations
  bargeQuantityChange = () => {
    var {
      coal_cost_coal_quantity,
      coal_cost_adjusted_coal_price_pmt,
      coal_cost_currency_xchg_rate,
      coal_cost_pph22_percent,
      coal_cost_billing_currency,
      coal_cost_pph22_export_percent,
      coal_cost_pph22_export,
      coal_cost_gar_bonus,
      vat_applicable,
      vat_percent,
      coal_cost_gar_penality,
      coal_cost_ash_penality,
      coal_cost_sulphur_penality,
      coalPriceCurrency,
    } = this.state;
    if (
      coal_cost_currency_xchg_rate === null ||
      coal_cost_currency_xchg_rate === ""
    ) {
      coal_cost_currency_xchg_rate = 1;
    }
    if (coal_cost_coal_quantity === null || coal_cost_coal_quantity === "") {
      coal_cost_coal_quantity = 1;
    }

    var coal_cost_adjusted_coal_price = Number(
      Number(coal_cost_coal_quantity) *
      Number(coal_cost_adjusted_coal_price_pmt)
    ).toFixed(2);
    var coal_cost_adjusted_coal_price_in_local_currency = Number(
      coal_cost_adjusted_coal_price
    ).toFixed(2);

    var coal_cost_pph22_percent_val =
      Number(
        Number(coal_cost_coal_quantity) *
        Number(coal_cost_adjusted_coal_price_pmt) *
        Number(coal_cost_pph22_percent)
      ) / 100;
    var coal_cost_pph22_percent_val_local_cur = Number(
      coal_cost_pph22_percent_val
    ).toFixed(2);

    var coal_cost_pph22_export_value =
      Number(
        Number(coal_cost_adjusted_coal_price) *
        Number(coal_cost_pph22_export_percent)
      ) / 100;
    var coal_cost_pph22_export_value_local_cur = Number(
      coal_cost_pph22_export_value
    ).toFixed(2);

    var vat_value = (
      (Number(coal_cost_adjusted_coal_price) * Number(vat_percent)) /
      100
    ).toFixed(2);

    if (coal_cost_pph22_export === "No") {
      coal_cost_pph22_export_value_local_cur = 0;
    }
    if (vat_applicable === "No") {
      vat_value = 0;
    }

    let coal_cost_pph22_percent_val_new = "";
    let coal_cost_pph22_export_value_new = "";
    if (coalPriceCurrency !== coal_cost_billing_currency) {
      coal_cost_pph22_percent_val_new = coal_cost_pph22_percent_val_local_cur;
      coal_cost_pph22_export_value_new = coal_cost_pph22_export_value_local_cur;
    } else {
      // coal_cost_gar_bonus_new= Number(garBonus).toFixed(0);
      // coal_cost_gar_penality_new= Number(garPenality).toFixed(0);
      // coal_cost_ash_penality_new= Number(ashPenality).toFixed(0);
      // coal_cost_sulphur_penality_new= Number(sulphurPenality).toFixed(0);
      // coal_cost_coal_price_pmt= (Number(this.state.coal_cost_coal_price_pmt)*Number(coal_cost_currency_xchg_rate)).toFixed(0)
      // // console.log( coal_cost_gar_bonus_new, coal_cost_gar_penality_new, coal_cost_ash_penality_new, coal_cost_sulphur_penality_new );
      // // console.log('coal_cost_coal_price_pmt', coal_cost_coal_price_pmt)
      // let coal_cost_adjusted_coal_price_pmt_new= Number(Number(coal_cost_coal_price_pmt)+Number(coal_cost_gar_bonus_new)-Number(coal_cost_gar_penality_new)-Number(coal_cost_ash_penality_new)-Number(coal_cost_sulphur_penality_new));
      // // console.log(coal_cost_adjusted_coal_price_pmt_new)
      // // console.log('coal_cost_coal_quantity', coal_cost_coal_quantity);
      coal_cost_pph22_percent_val_new =
        Number(
          Number(coal_cost_coal_quantity) *
          Number(coal_cost_adjusted_coal_price_pmt) *
          Number(coal_cost_currency_xchg_rate) *
          Number(coal_cost_pph22_percent)
        ) / 100;
      coal_cost_pph22_export_value_new =
        Number(
          Number(coal_cost_coal_quantity) *
          Number(coal_cost_adjusted_coal_price_pmt) *
          Number(coal_cost_currency_xchg_rate) *
          Number(coal_cost_pph22_export_percent)
        ) / 100;
      vat_value =
        Number(
          Number(coal_cost_coal_quantity) *
          Number(coal_cost_adjusted_coal_price_pmt) *
          Number(coal_cost_currency_xchg_rate) *
          Number(vat_percent)
        ) / 100;
    }
    // console.log(coal_cost_pph22_percent_val_new)
    // console.log(coal_cost_pph22_export_value_new)
    if (
      coal_cost_billing_currency === "0" ||
      coalPriceCurrency === coal_cost_billing_currency
    ) {
      coal_cost_currency_xchg_rate = 1;
    }
    // let coal_cost_coal_price= Number(coal_cost_coal_quantity)*Number(this.state.coal_cost_coal_price_pmt);
    // console.log('coal_cost_coal_price', coal_cost_coal_price)
    let coal_cost_coal_price_pmt_local_currency = (
      Number(this.state.coal_cost_coal_price_pmt) *
      Number(coal_cost_currency_xchg_rate)
    ).toFixed(2);
    if (
      (config.company === "SDAM" ||
        config.company === "SRK" ||
        config.company === "BTR") &&
      this.state.coal_cost_billing_currency === "IDR"
    ) {
      coal_cost_coal_price_pmt_local_currency = (
        Number(this.state.coal_cost_coal_price_pmt) *
        Number(coal_cost_currency_xchg_rate)
      ).toFixed(0);
      coal_cost_adjusted_coal_price_in_local_currency = Number(
        coal_cost_adjusted_coal_price
      ).toFixed(0);
      coal_cost_pph22_percent_val_local_cur = Number(
        coal_cost_pph22_percent_val
      ).toFixed(0);
      coal_cost_pph22_export_value_local_cur = Number(
        coal_cost_pph22_export_value
      ).toFixed(0);
    }
    // console.log('coal_cost_coal_price_pmt_local_currency', coal_cost_coal_price_pmt_local_currency)
    // console.log('coal_cost_coal_quantity', coal_cost_coal_quantity)
    this.setState(
      {
        coal_cost_coal_price: Number(
          Number(coal_cost_coal_price_pmt_local_currency) *
          Number(coal_cost_coal_quantity)
        ).toFixed(2),
        coal_cost_adjusted_coal_price: coal_cost_adjusted_coal_price,
        coal_cost_coal_price_pmt_local_currency:
          coal_cost_coal_price_pmt_local_currency,
        coal_cost_adjusted_coal_price_in_local_currency:
          coal_cost_adjusted_coal_price_in_local_currency,

        coal_cost_gar_bonus: coal_cost_gar_bonus,
        coal_cost_gar_penality: coal_cost_gar_penality,
        coal_cost_ash_penality: coal_cost_ash_penality,
        coal_cost_sulphur_penality: coal_cost_sulphur_penality,

        // VAT
        vat_value: vat_value,

        //PPH22 Local
        coal_cost_pph22_barge_quantity_in_mt: Number(coal_cost_coal_quantity),
        coal_cost_pph22_coal_price_per_mt: coal_cost_adjusted_coal_price_pmt,
        coal_cost_pph22_percent_val: (
          Number(coal_cost_pph22_percent_val) /
          Number(coal_cost_currency_xchg_rate)
        ).toFixed(0),
        coal_cost_pph22_percent_val_local_cur:
          coal_cost_pph22_percent_val_local_cur,
        coal_cost_pph22_percent_val_local_cur_new:
          coal_cost_pph22_percent_val_new,

        //PPH22 Export
        coal_cost_pph22_export_amount: Number(
          coal_cost_adjusted_coal_price
        ).toFixed(2),
        coal_cost_pph22_export_value: coal_cost_pph22_export_value_local_cur,
        coal_cost_pph22_export_value_local_cur:
          coal_cost_pph22_export_value_local_cur,
        coal_cost_pph22_export_value_local_cur_new:
          coal_cost_pph22_export_value_new,

        isLoading: false,
      },
      () => {
        this.onAdvanceAdjustmentsCal();
      }
    );
  };

  //Only PPH22 Local Calculations
  onPph22LocalDataChange = () => {
    var {
      coal_cost_currency_xchg_rate,
      coal_cost_pph22_barge_quantity_in_mt,
      coal_cost_pph22_coal_price_per_mt,
      coal_cost_pph22_percent,
      coal_cost_coal_quantity,
    } = this.state;

    var coal_cost_pph22_percent_val =
      Number(
        Number(coal_cost_pph22_barge_quantity_in_mt) *
        Number(coal_cost_pph22_coal_price_per_mt) *
        Number(coal_cost_pph22_percent)
      ) / 100;
    if (
      coal_cost_currency_xchg_rate === null ||
      coal_cost_currency_xchg_rate === ""
    ) {
      coal_cost_currency_xchg_rate = 1;
    }
    if (coal_cost_coal_quantity === null || coal_cost_coal_quantity === "") {
      coal_cost_coal_quantity = 1;
    }

    var coal_cost_pph22_percent_val_local_cur = Number(
      coal_cost_pph22_percent_val
    ).toFixed(0);

    let coal_cost_pph22_percent_val_new = "";
    if (
      this.state.coal_cost_billing_currency !== this.state.coalPriceCurrency
    ) {
      coal_cost_pph22_percent_val_new = coal_cost_pph22_percent_val_local_cur;
    } else {
      let coal_cost_pph22_coal_price_per_mt_new = Number(
        Number(coal_cost_pph22_coal_price_per_mt) *
        Number(coal_cost_currency_xchg_rate)
      );
      coal_cost_pph22_percent_val_new =
        Number(
          Number(coal_cost_pph22_barge_quantity_in_mt) *
          Number(coal_cost_pph22_coal_price_per_mt_new) *
          Number(coal_cost_pph22_percent)
        ) / 100;
    }
    this.setState(
      {
        coal_cost_pph22_percent_val: (
          Number(coal_cost_pph22_percent_val) /
          Number(coal_cost_currency_xchg_rate)
        ).toFixed(0),
        coal_cost_pph22_percent_val_local_cur:
          coal_cost_pph22_percent_val.toFixed(2),
        coal_cost_pph22_percent_val_local_cur_new:
          coal_cost_pph22_percent_val_new,
      },
      () => {
        this.onAdvanceAdjustmentsCal();
      }
    );
  };

  onPph22LocalChange = (e) => {
    const quan = /^\d*(\.\d{0,4})?$/;
    if (e.target.value === "" || quan.test(e.target.value)) {
      var value = e.target.value;
      this.setState(
        {
          [e.target.name]: value,
        },
        () => {
          this.onPph22LocalDataChange();
        }
      );
    }
  };

  onPph22LocalCoalPriceChange = (e) => {
    const quan = /^\d*(\.\d{0,4})?$/;
    if (e.target.value === "" || quan.test(e.target.value)) {
      var value = e.target.value;
      this.setState(
        {
          [e.target.name]: value,
        },
        () => {
          this.onPph22LocalDataChange();
        }
      );
    }
  };

  //Only PPH22 Export Calculations
  onPph22ExportDataChange = () => {
    var {
      coal_cost_currency_xchg_rate,
      coal_cost_pph22_export_amount,
      coal_cost_pph22_export_percent,
      coal_cost_pph22_export,
      coal_cost_billing_currency,
      coalPriceCurrency,
    } = this.state;
    var coal_cost_pph22_export_value =
      Number(
        Number(coal_cost_pph22_export_amount) *
        Number(coal_cost_pph22_export_percent)
      ) / 100;
    if (
      coal_cost_currency_xchg_rate === null ||
      coal_cost_currency_xchg_rate === ""
    ) {
      coal_cost_currency_xchg_rate = 1;
    }
    if (
      coal_cost_billing_currency === "0" ||
      coalPriceCurrency === coal_cost_billing_currency
    ) {
      coal_cost_currency_xchg_rate = 1;
    }

    var coal_cost_pph22_export_value_local_cur = Number(
      coal_cost_pph22_export_value
    ).toFixed(0);
    if (coal_cost_pph22_export === "No") {
      coal_cost_pph22_export_value_local_cur = 0;
    }

    let coal_cost_pph22_export_value_new = "";
    if (
      this.state.coal_cost_billing_currency !== this.state.coalPriceCurrency
    ) {
      coal_cost_pph22_export_value_new = coal_cost_pph22_export_value_local_cur;
    } else {
      // let coal_cost_coal_price_pmt_new= Number(Number(coal_cost_coal_price_pmt)*Number(coal_cost_currency_xchg_rate));
      coal_cost_pph22_export_value_new =
        Number(
          Number(coal_cost_pph22_export_amount) *
          Number(coal_cost_currency_xchg_rate) *
          Number(coal_cost_pph22_export_percent)
        ) / 100;
    }
    // console.log('coal_cost_pph22_export_value_new',coal_cost_pph22_export_value_new);
    this.setState(
      {
        coal_cost_pph22_export_value: Number(
          coal_cost_pph22_export_value
        ).toFixed(2),
        coal_cost_pph22_export_value_local_cur:
          coal_cost_pph22_export_value_local_cur,
        coal_cost_pph22_export_value_local_cur_new:
          coal_cost_pph22_export_value_new,
      },
      () => {
        this.onAdvanceAdjustmentsCal();
      }
    );
  };

  onPph22ExportChange = (e) => {
    const quan = /^\d*(\.\d{0,4})?$/;
    if (e.target.value === "" || quan.test(e.target.value)) {
      var value = e.target.value;
      this.setState(
        {
          [e.target.name]: value,
        },
        () => {
          this.onPph22ExportDataChange();
        }
      );
    }
  };

  onVatChangeHandler = () => {
    var {
      coal_cost_currency_xchg_rate,
      vat_applicable,
      vat_amount,
      vat_percent,
      coal_cost_billing_currency,
      coalPriceCurrency,
    } = this.state;

    let vat_value = 0;
    if (vat_applicable === "No") {
      vat_value = 0;
      vat_amount = 0;
    }

    vat_value = Number(
      (Number(vat_amount) * Number(vat_percent ? vat_percent : 10)) / 100
    );
    if (
      coal_cost_currency_xchg_rate === null ||
      coal_cost_currency_xchg_rate === ""
    ) {
      coal_cost_currency_xchg_rate = 1;
    }
    if (
      coal_cost_billing_currency === "0" ||
      coalPriceCurrency === coal_cost_billing_currency
    ) {
      coal_cost_currency_xchg_rate = 1;
    }

    //var coal_cost_pph22_export_value_local_cur = Number(coal_cost_pph22_export_value).toFixed(0);

    //let coal_cost_pph22_export_value_new = '';
    if (
      this.state.coal_cost_billing_currency !== this.state.coalPriceCurrency
    ) {
      // coal_cost_pph22_export_value_new = coal_cost_pph22_export_value_local_cur
    } else {
      // let coal_cost_coal_price_pmt_new= Number(Number(coal_cost_coal_price_pmt)*Number(coal_cost_currency_xchg_rate));
      vat_value = Number(
        (Number(vat_amount) *
          Number(coal_cost_currency_xchg_rate) *
          Number(vat_percent ? vat_percent : 10)) /
        100
      );
    }

    this.setState(
      {
        vat_value: Number(vat_value).toFixed(2),
        vat_percent: this.state.vat_percent ? this.state.vat_percent : 10,
        //coal_cost_pph22_export_value_local_cur: coal_cost_pph22_export_value_local_cur,
        // coal_cost_pph22_export_value_local_cur_new: coal_cost_pph22_export_value_new
      },
      () => {
        this.onAdvanceAdjustmentsCal();
      }
    );
  };

  //Barge quantity changes
  onBargeChange = (e) => {
    const quan = /^\d*(\.\d{0,4})?$/;
    if (e.target.value === "" || quan.test(e.target.value)) {
      var value = e.target.value;
      this.setState(
        {
          [e.target.name]: value,
        },
        () => {
          this.bargeQuantityChange();
        }
      );
    }
  };

  //Barge quantity changes
  onCoalCostChange = (e) => {
    const quan = /^\d*(\.\d{0,4})?$/;
    var {
      coal_cost_currency_xchg_rate,
      coal_cost_penality_currency,
      coal_cost_billing_currency,
      sales_currency,
    } = this.state;
    if (e.target.value === "" || quan.test(e.target.value)) {
      var value = e.target.value;
      if (value === null || value === "") {
        value = 0;
      }
      if (
        coal_cost_currency_xchg_rate === null ||
        coal_cost_currency_xchg_rate === ""
      ) {
        coal_cost_currency_xchg_rate = 1;
      }
      let coal_cost_coal_price_pmt = (
        Number(value) * Number(coal_cost_currency_xchg_rate)
      ).toFixed(2);

      var garPenality = "";
      var garBonus = "";
      var ashPenality = "";
      var sulphurPenality = "";

      var coal_cost_gar_bonus = "";
      var coal_cost_gar_penality = "";
      var coal_cost_ash_penality = "";
      var coal_cost_sulphur_penality = "";
      if (garBonus !== "") {
        coal_cost_gar_bonus = garBonus.toFixed(2);
        if (
          (config.company === "SDAM" ||
            config.company === "SRK" ||
            config.company === "BTR") &&
          this.state.coal_cost_billing_currency === "IDR"
        ) {
          coal_cost_gar_bonus = garBonus.toFixed(0);
        }
      }
      if (garPenality !== "") {
        coal_cost_gar_penality = garPenality.toFixed(2);
        if (
          (config.company === "SDAM" ||
            config.company === "SRK" ||
            config.company === "BTR") &&
          this.state.coal_cost_billing_currency === "IDR"
        ) {
          coal_cost_gar_penality = garPenality.toFixed(0);
        }
      }
      if (ashPenality !== "") {
        coal_cost_ash_penality = ashPenality.toFixed(2);
        if (
          (config.company === "SDAM" ||
            config.company === "SRK" ||
            config.company === "BTR") &&
          this.state.coal_cost_billing_currency === "IDR"
        ) {
          coal_cost_ash_penality = ashPenality.toFixed(0);
        }
      }
      if (sulphurPenality !== "") {
        coal_cost_sulphur_penality = sulphurPenality.toFixed(2);
        if (
          (config.company === "SDAM" ||
            config.company === "SRK" ||
            config.company === "BTR") &&
          this.state.coal_cost_billing_currency === "IDR"
        ) {
          coal_cost_sulphur_penality = sulphurPenality.toFixed(0);
        }
      }
      let coal_cost_adjusted_coal_price_pmt = Number(
        Number(coal_cost_coal_price_pmt) +
        Number(coal_cost_gar_bonus) -
        Number(coal_cost_gar_penality) -
        Number(coal_cost_ash_penality) -
        Number(coal_cost_sulphur_penality)
      ).toFixed(2);
      // console.log('coal_cost_adjusted_coal_price_pmt',coal_cost_adjusted_coal_price_pmt);

      if (
        sales_currency !== coal_cost_billing_currency &&
        sales_currency === coal_cost_penality_currency
      ) {
        coal_cost_coal_price_pmt = Number(
          this.state.coal_cost_coal_price_pmt
        ).toFixed(2);
        let coal_cost_adjusted_coal_price_pmt_value = Number(
          Number(coal_cost_coal_price_pmt) +
          Number(coal_cost_gar_bonus) -
          Number(coal_cost_gar_penality) -
          Number(coal_cost_ash_penality) -
          Number(coal_cost_sulphur_penality)
        );
        coal_cost_adjusted_coal_price_pmt = (
          Number(coal_cost_adjusted_coal_price_pmt_value) *
          Number(this.state.coal_cost_currency_xchg_rate)
        ).toFixed(2);
      }
      // console.log('coal_cost_coal_price_pmt',coal_cost_coal_price_pmt);
      // console.log(coal_cost_gar_bonus, coal_cost_gar_penality, coal_cost_ash_penality, coal_cost_sulphur_penality);
      // console.log('coal_cost_adjusted_coal_price_pmt',coal_cost_adjusted_coal_price_pmt);
      this.setState(
        {
          [e.target.name]: e.target.value,
          coal_cost_gar_bonus: coal_cost_gar_bonus,
          coal_cost_gar_penality: coal_cost_gar_penality,
          coal_cost_ash_penality: coal_cost_ash_penality,
          coal_cost_sulphur_penality: coal_cost_sulphur_penality,
          coal_cost_adjusted_coal_price_pmt: coal_cost_adjusted_coal_price_pmt,
        },
        () => {
          this.bargeQuantityChange();
        }
      );

      // coal_cost_gar_bonus=(Number(coal_cost_gar_bonus)*Number(coal_cost_currency_xchg_rate)).toFixed(2);
      // coal_cost_gar_penality=(Number(coal_cost_gar_penality)*Number(coal_cost_currency_xchg_rate)).toFixed(2);
      // coal_cost_ash_penality=(Number(coal_cost_ash_penality)*Number(coal_cost_currency_xchg_rate)).toFixed(2);
      // coal_cost_sulphur_penality=(Number(coal_cost_sulphur_penality)*Number(coal_cost_currency_xchg_rate)).toFixed(2);
      // this.setState({
      //   [e.target.name]: e.target.value,
      //   coal_cost_adjusted_coal_price_pmt: Number(Number(coal_cost_coal_price_pmt)+Number(coal_cost_gar_bonus)-Number(coal_cost_gar_penality)-Number(coal_cost_ash_penality)-Number(coal_cost_sulphur_penality)).toFixed(2)
      // }, () => {
      //     this.bargeQuantityChange();
      // });
    }
  };

  onPenalityChanges() {
    var {
      coal_cost_currency_xchg_rate,
      coal_cost_billing_currency,
      coalPriceCurrency,
    } = this.state;
    if (
      coal_cost_currency_xchg_rate === null ||
      coal_cost_currency_xchg_rate === ""
    ) {
      coal_cost_currency_xchg_rate = 1;
    }
    if (
      coal_cost_billing_currency === "0" ||
      coalPriceCurrency === coal_cost_billing_currency
    ) {
      coal_cost_currency_xchg_rate = 1;
    }

    let coal_cost_coal_price_pmt = (
      Number(this.state.coal_cost_coal_price_pmt) *
      Number(coal_cost_currency_xchg_rate)
    ).toFixed(2);

    var garPenality = "";
    var garBonus = "";
    var ashPenality = "";
    var sulphurPenality = "";

    var coal_cost_gar_bonus = "";
    var coal_cost_gar_penality = "";
    var coal_cost_ash_penality = "";
    var coal_cost_sulphur_penality = "";
    if (garBonus !== "") {
      coal_cost_gar_bonus = garBonus.toFixed(2);
      if (
        (config.company === "SDAM" ||
          config.company === "SRK" ||
          config.company === "BTR") &&
        this.state.coal_cost_billing_currency === "IDR"
      ) {
        coal_cost_gar_bonus = garBonus.toFixed(0);
      }
    }
    if (garPenality !== "") {
      coal_cost_gar_penality = garPenality.toFixed(2);
      if (
        (config.company === "SDAM" ||
          config.company === "SRK" ||
          config.company === "BTR") &&
        this.state.coal_cost_billing_currency === "IDR"
      ) {
        coal_cost_gar_penality = garPenality.toFixed(0);
      }
    }
    if (ashPenality !== "") {
      coal_cost_ash_penality = ashPenality.toFixed(2);
      if (
        (config.company === "SDAM" ||
          config.company === "SRK" ||
          config.company === "BTR") &&
        this.state.coal_cost_billing_currency === "IDR"
      ) {
        coal_cost_ash_penality = ashPenality.toFixed(0);
      }
    }
    if (sulphurPenality !== "") {
      coal_cost_sulphur_penality = sulphurPenality.toFixed(2);
      if (
        (config.company === "SDAM" ||
          config.company === "SRK" ||
          config.company === "BTR") &&
        this.state.coal_cost_billing_currency === "IDR"
      ) {
        coal_cost_sulphur_penality = sulphurPenality.toFixed(0);
      }
    }
    let coal_cost_adjusted_coal_price_pmt = Number(
      Number(coal_cost_coal_price_pmt) +
      Number(coal_cost_gar_bonus) -
      Number(coal_cost_gar_penality) -
      Number(coal_cost_ash_penality) -
      Number(coal_cost_sulphur_penality)
    ).toFixed(2);
    if (
      (config.company === "SDAM" ||
        config.company === "SRK" ||
        config.company === "BTR") &&
      this.state.coal_cost_billing_currency === "IDR"
    ) {
      coal_cost_adjusted_coal_price_pmt = Number(
        Number(coal_cost_coal_price_pmt) +
        Number(coal_cost_gar_bonus) -
        Number(coal_cost_gar_penality) -
        Number(coal_cost_ash_penality) -
        Number(coal_cost_sulphur_penality)
      ).toFixed(0);
    }
    this.setState(
      {
        coal_cost_gar_bonus: coal_cost_gar_bonus,
        coal_cost_gar_penality: coal_cost_gar_penality,
        coal_cost_ash_penality: coal_cost_ash_penality,
        coal_cost_sulphur_penality: coal_cost_sulphur_penality,
        coal_cost_adjusted_coal_price_pmt: coal_cost_adjusted_coal_price_pmt,
      },
      () => {
        this.bargeQuantityChange();
      }
    );
  }

  //Exchange rate changes
  onExchangeRateChange = (e) => {
    const quan = /^\d*(\.\d{0,10})?$/;
    var {
      coalPriceCurrency,
      coal_cost_billing_currency,
      coal_cost_coal_price_pmt,
      coal_cost_gar_bonus,
      coal_cost_gar_penality,
      coal_cost_ash_penality,
      coal_cost_sulphur_penality,
    } = this.state;
    if (e.target.value === "" || quan.test(e.target.value)) {
      var value = e.target.value;
      if (value === null || value === "") {
        value = 1;
      }
      if (
        coal_cost_billing_currency === "0" ||
        coalPriceCurrency === coal_cost_billing_currency
      ) {
        value = 1;
      }
      if (
        config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL"
      ) {
        value = 1 / Number(value);
      }
      coal_cost_gar_bonus = (
        Number(coal_cost_gar_bonus) * Number(value)
      ).toFixed(2);
      coal_cost_gar_penality = (
        Number(coal_cost_gar_penality) * Number(value)
      ).toFixed(2);
      coal_cost_ash_penality = (
        Number(coal_cost_ash_penality) * Number(value)
      ).toFixed(2);
      coal_cost_sulphur_penality = (
        Number(coal_cost_sulphur_penality) * Number(value)
      ).toFixed(2);
      coal_cost_coal_price_pmt = (
        Number(coal_cost_coal_price_pmt) * Number(value)
      ).toFixed(2);
      if (
        (config.company === "SDAM" ||
          config.company === "SRK" ||
          config.company === "BTR") &&
        this.state.coal_cost_billing_currency === "IDR"
      ) {
        coal_cost_coal_price_pmt = (
          Number(coal_cost_coal_price_pmt) * Number(value)
        ).toFixed(0);
      }
      this.setState(
        {
          [e.target.name]: e.target.value,
          coal_cost_currency_xchg_rate: value,
          coal_cost_adjusted_coal_price_pmt: Number(
            Number(coal_cost_coal_price_pmt) +
            Number(coal_cost_gar_bonus) -
            Number(coal_cost_gar_penality) -
            Number(coal_cost_ash_penality) -
            Number(coal_cost_sulphur_penality)
          ).toFixed(2),
        },
        () => {
          this.onPenalityChanges();
        }
      );
    }
  };

  billingCurrencyChange = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        this.onPenalityChanges();
      }
    );
  };

  submitHandler = (e) => {
    this.setState({ isLoading: true });
    var {
      invoice_no,
      invoice_date,
      ppn,
      ppn_date,
      coal_cost_coal_quantity,
      coal_cost_coal_price,
      coal_cost_coal_price_pmt,
      coal_cost_gar_bonus,
      coal_cost_gar_penality,
      coal_cost_ash_penality,
      coal_cost_sulphur_penality,
      coal_cost_adjusted_coal_price_pmt,
      coal_cost_adjusted_coal_price,
      coal_cost_currency_xchg_rate,
      coal_cost_adjusted_coal_price_in_local_currency,
      coal_cost_pph22_barge_quantity_in_mt,
      coal_cost_pph22_coal_price_per_mt,
      coal_cost_pph22_percent,
      coal_cost_pph22_percent_val,
      coal_cost_pph22_export,
      coal_cost_pph22_export_amount,
      coal_cost_pph22_export_percent,
      coal_cost_pph22_export_value,
      advance_adjustments,
      receivable_from_supplier,
      sales_index_linked,
      coal_cost_billing_currency,
      sales_contract_id,
      sc_qualities_id,
      sc_sales_type_id,
      vat_applicable,
      vat_amount,
      vat_percent,
      vat_value,
    } = this.state;

    if (coal_cost_gar_bonus === "") {
      coal_cost_gar_bonus = null;
    }
    if (coal_cost_gar_penality === "") {
      coal_cost_gar_penality = null;
    }
    if (coal_cost_ash_penality === "") {
      coal_cost_ash_penality = null;
    }
    if (coal_cost_sulphur_penality === "") {
      coal_cost_sulphur_penality = null;
    }
    if (sales_index_linked === "Yes") {
      if (coal_cost_coal_price_pmt === "") {
        this.setState({ isLoading: false });
        alert("Coal Price per MT cannot be empty");
        return;
      }
    }
    if (
      coal_cost_currency_xchg_rate === "" ||
      coal_cost_currency_xchg_rate === null
    ) {
      coal_cost_currency_xchg_rate = "1";
      coal_cost_adjusted_coal_price_in_local_currency =
        coal_cost_adjusted_coal_price;
    }
    var row = advance_adjustments;
    var count = 0;
    for (var i in row) {
      row[i].sales_contract_id = this.state.sales_contract_id;
      row[i].sales_contract_no = this.state.sales_contract_no;
      if (row[i].advance_adjustment === "No") {
        row[i].sales_contract_id = "";
        row[i].sales_contract_no = "";
        row[i].advance_adjustment_value = "";
        row[i].advance_adjustment_barge_quantity = "";
        row[i].adjustment_per_mt = "";
        row[i].adjustment_currency = "";
      } else if (row[i].advance_adjustment === "Lumpsum") {
        if (row[i].advance_adjustment_value === "") {
          row[i].advance_adjustment_valueError = true;
          count++;
        }
        row[i].adjustment_currency = this.state.coal_cost_billing_currency;
      } else if (row[i].advance_adjustment === "Per MT") {
        if (row[i].advance_adjustment_barge_quantity === "") {
          row[i].advance_adjustment_barge_quantity_valueError = true;
          count++;
        }
        if (row[i].adjustment_per_mt === "") {
          row[i].adjustment_per_mt_valueError = true;
          count++;
        }
        row[i].adjustment_currency = this.state.coal_cost_billing_currency;
      }
    }

    this.setState({
      advance_adjustments: row,
    });

    if (count > 0) {
      alert("Please fill mandatory fileds.");
      this.setState({
        isLoading: false,
      });
      return;
    }

    if (
      config.company === "SRPL" ||
      config.company === "AVS" ||
      config.company === "PRPL"
    ) {
      coal_cost_pph22_export_value =
        this.state.coal_cost_pph22_export_value_local_cur;
      if (coal_cost_pph22_export === "No") {
        coal_cost_pph22_export_amount = "";
        coal_cost_pph22_export_percent = "";
        coal_cost_pph22_export_value = "";
      }
      coal_cost_pph22_barge_quantity_in_mt = "";
      coal_cost_pph22_coal_price_per_mt = "";
      coal_cost_pph22_percent_val = "";
      coal_cost_pph22_percent = "";
      vat_amount = "";
      //vat_percent = '';
      vat_value = "";
    } else {
      if (
        ((config.company === "SDAM" ||
          config.company === "SRK" ||
          config.company === "BTR") &&
          this.state.sales_currency !== "IDR") ||
        ((config.company === "SRPL" ||
          config.company === "AVS" ||
          config.company === "PRPL") &&
          this.state.sales_currency !== "USD")
      ) {
        coal_cost_pph22_export_value =
          this.state.coal_cost_pph22_export_value_local_cur;
        coal_cost_pph22_percent_val =
          this.state.coal_cost_pph22_percent_val_local_cur;
      }
      if (coal_cost_pph22_export === "No") {
        coal_cost_pph22_export_amount = "";
        coal_cost_pph22_export_percent = "";
        coal_cost_pph22_export_value = "";
      }
      if (vat_applicable === "No") {
        vat_amount = "";
        // vat_percent = '';
        vat_value = "";
      }
    }

    // Add barge purchase financial api
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    coal_cost_currency_xchg_rate = this.state.currency_xchg_rate;

    /**
     *  Checking Mandatory files
     */
    var missing_fields = 0;
    let files_missing = 0;
    var files_data = this.state.files;
    for (var obj of files_data) {
      if (obj.file_type !== "" || obj.file_content !== "") {
        if (obj.file_content !== "") {
          if (obj.file_description === "") {
            missing_fields++;
            obj.file_description_error = true;
          } else {
            obj.file_description = obj.file_description.toUpperCase();
          }
        } else if (obj.file_description !== "") {
          if (obj.file_content === "") {
            files_missing++;
          }
        }
      }
    }
    this.setState({
      files: files_data,
    });

    if (missing_fields > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please fill mandatory fields.",
      });
      return;
    }
    if (files_missing > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please Select file.",
      });
      return;
    }
    var existing_file_ids = [];
    const existing_files = files_data.filter((e) => e.id);
    for (var j of existing_files) {
      existing_file_ids.push(j.id);
    }
    const new_files = files_data.filter((e) => !e.id);
    const content_files = new_files.filter((e) => e.file_content !== "");
    const costing_file = this.state.costing_files?.filter(
      (e) => e.file_content !== ""
    );

    if (sales_contract_id && sc_qualities_id && sc_sales_type_id) {
      api
        .update_mv_sales_performa_coal_costing(
          loginUserID,
          idToken,
          this.props.businessNoID,
          this.props.mv_sales_costing_id,
          sales_contract_id,
          sc_qualities_id,
          sc_sales_type_id,
          invoice_no,
          invoice_date,
          ppn,
          ppn_date,
          coal_cost_coal_quantity,
          coal_cost_billing_currency,
          coal_cost_coal_price_pmt,
          coal_cost_coal_price,
          coal_cost_adjusted_coal_price_pmt,
          coal_cost_adjusted_coal_price,
          coal_cost_currency_xchg_rate,
          coal_cost_adjusted_coal_price_in_local_currency,
          vat_applicable,
          vat_amount,
          vat_percent,
          vat_value,
          coal_cost_pph22_barge_quantity_in_mt,
          coal_cost_pph22_coal_price_per_mt,
          coal_cost_pph22_percent,
          coal_cost_pph22_percent_val,
          coal_cost_pph22_export,
          coal_cost_pph22_export_amount,
          coal_cost_pph22_export_percent,
          coal_cost_pph22_export_value,
          advance_adjustments,
          receivable_from_supplier,
          existing_file_ids,
          content_files,
          costing_file
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                this.setState(
                  {
                    successMsg: res.message,
                    snackBarSuccessOpen: true,
                  },
                  () => {
                    this.props.onUpdateCosting(res.code);
                  }
                );
              } else if (res.code === "601") {
                this.setState({
                  isLoading: false,
                  errorMsg: res.message,
                  successMsg: "",
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              } else if (res.code === "624") {
                this.setState({
                  isLoading: false,
                  errorMsg: res.message,
                  successMsg: "",
                });
              }
            });
          }
        });
    } else {
      alert("Please select Sales Contract");
      this.setState({
        isLoading: false,
        errorMsg: "Please select sales contract",
        snackBarErrorOpen: true,
      });
    }
  };

  render() {
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div
          className="container bg-white p-0"
          style={{ minHeight: "100vh", width: 850 }}
        >
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <div className="row border-bottom">
            <div className="col-lg-12 pl-0">
              <h5
                style={{ padding: "15px", marginBottom: 0 }}
              >{`Sales Performa Coal Costing - ${this.state.costing_no}`}</h5>
            </div>
          </div>
          <div className="drawer-section">
            <div className="card p-0">
              <div
                className="card-body pt-2 pb-2"
                style={{ backgroundColor: "rgba(25,118,210,.01)" }}
                id="accordion"
              >
                <div className="row border-bottom pb-2">
                  <div className="col-sm">
                    <label className="form_label">Sales Contract</label>
                    <Autocomplete
                      options={this.state.salesDataforMenu}
                      getOptionLabel={(option) => option.contract_no}
                      onChange={(event, value) => {
                        if (value) {
                          this.setState(
                            {
                              sales_contract_id: value.sales_contract_id,
                              sales_contract_no: value.contract_no,
                              selected_sc: value,
                            },
                            () => {
                              this.handleSalesContract(value);
                            }
                          );
                        } else {
                          this.setState({
                            sales_contract_id: "",
                            sales_quality: "",
                            sc_qualities_id: "",
                            sales_type: "",
                            sc_sales_type_id: "",
                            sales_price_pmt: 0,
                            coal_cost_coal_price_pmt: 0,
                            coalPriceCurrency: "",
                            salesTypeSelection: "",
                            salesQualitySelection: "",
                            selected_sc: {
                              contract_no: "",
                              sales_contract_id: "",
                            },
                            salesTypes: [],
                          });
                        }
                      }}
                      value={this.state.selected_sc}
                      renderOption={(option) => (
                        <React.Fragment>
                          {option.contract_no} ({option.customer_name})
                        </React.Fragment>
                      )}
                      size="small"
                      name="sales_contract"
                      fullWidth
                      style={{ padding: "0px" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          // error={this.state.quantityRows[index].sales_contractError}
                          // inputProps={{ style:{padding: '2px'}}}
                          placeholder="Sales Contract *"
                          //label="Sales Contract *"
                          variant="outlined"
                          style={{ width: 230 }}
                        />
                      )}
                    />
                  </div>
                  <div className="col-sm">
                    <label className="form_label mb-3">Sales Quality</label>
                    <div className="contract_display_header_value">
                      {this.state.sales_quality}
                    </div>
                  </div>
                  <div className="col-sm">
                    <label className="form_label mb-3">Sales Type</label>
                    <div className="contract_display_header_value">
                      {this.state.sales_type}
                    </div>
                  </div>
                </div>

                {this.state.sales_contract_id &&
                  this.state.sc_qualities_id &&
                  this.state.sc_sales_type_id && (
                    <div className="col p-2 mb-2 mt-2">
                      <div className="row">
                        <div className="card p-0 border mb-3">
                          <div className="card-header section_header">
                            <h5>Coal Costing</h5>
                          </div>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-sm-4 mb-2">
                                <label className="form_label mb-0">
                                  Barge Quantity in MT
                                </label>
                                <TextField
                                  name="coal_cost_coal_quantity"
                                  margin="dense"
                                  variant="outlined"
                                  type="number"
                                  value={this.state.coal_cost_coal_quantity}
                                  fullWidth
                                  inputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: "12px",
                                    },
                                  }}
                                  placeholder="Barge Quantity in MT"
                                  onChange={this.onBargeChange}
                                />
                              </div>
                              {this.state.sales_index_linked === "Yes" && (
                                <div className="col-sm-4 mb-2">
                                  <label className="form_label mb-0">
                                    Coal Price per MT{" "}
                                    {this.state.sales_currency}
                                  </label>
                                  <TextField
                                    name="coal_cost_coal_price_pmt"
                                    margin="dense"
                                    variant="outlined"
                                    type="number"
                                    value={this.state.coal_cost_coal_price_pmt}
                                    fullWidth
                                    inputProps={{
                                      style: {
                                        fontFamily: "Poppins",
                                        fontSize: "12px",
                                      },
                                    }}
                                    placeholder="Coal Price per MT"
                                    onChange={this.onCoalCostChange}
                                  />
                                </div>
                              )}
                              {this.state.sales_index_linked === "No" && (
                                <div className="col-sm-4 mb-2">
                                  <label className="form_label mb-2">
                                    Coal Price per MT
                                  </label>
                                  <div className="contract_display_header_value">
                                    {this.toLocaleString(
                                      this.state.coal_cost_coal_price_pmt
                                    )}{" "}
                                    {this.state.coalPriceCurrency}
                                  </div>
                                </div>
                              )}
                              {(config.company === "SDAM" ||
                                config.company === "SRK" ||
                                config.company === "BTR") &&
                                this.state.coalPriceCurrency !== "IDR" && (
                                  <div className="col-sm-4 mb-2">
                                    <label className="form_label mb-0">
                                      Billing Currency
                                    </label>
                                    <TextField
                                      name="coal_cost_billing_currency"
                                      margin="dense"
                                      variant="outlined"
                                      //inputProps={{style:{textTransform:'uppercase'}}}
                                      value={
                                        this.state.coal_cost_billing_currency
                                      }
                                      fullWidth
                                      error={
                                        this.state
                                          .coal_cost_billing_currency_error
                                      }
                                      // onChange={this.handleTextChange}
                                      onChange={this.billingCurrencyChange}
                                      select
                                    >
                                      <MenuItem value="0" disabled>
                                        Select
                                      </MenuItem>
                                      <MenuItem
                                        value={
                                          config.company === "SRPL" ||
                                            config.company === "AVS" ||
                                            config.company === "PRPL"
                                            ? "USD"
                                            : "IDR"
                                        }
                                      >
                                        {config.company === "SRPL" ||
                                          config.company === "AVS" ||
                                          config.company === "PRPL"
                                          ? "USD"
                                          : "IDR"}
                                      </MenuItem>
                                      <MenuItem
                                        value={this.state.coalPriceCurrency}
                                      >
                                        {this.state.coalPriceCurrency}
                                      </MenuItem>
                                    </TextField>
                                  </div>
                                )}
                              {(config.company === "SRPL" ||
                                config.company === "AVS" ||
                                config.company === "PRPL") &&
                                this.state.coalPriceCurrency !== "USD" &&
                                this.state.coalPriceCurrency && (
                                  <div className="col-sm-4 mb-2">
                                    <label className="form_label mb-0">
                                      Billing Currency
                                    </label>
                                    <TextField
                                      name="coal_cost_billing_currency"
                                      margin="dense"
                                      variant="outlined"
                                      //inputProps={{style:{textTransform:'uppercase'}}}
                                      value={
                                        this.state.coal_cost_billing_currency
                                      }
                                      fullWidth
                                      error={
                                        this.state
                                          .coal_cost_billing_currency_error
                                      }
                                      // onChange={this.handleTextChange}
                                      onChange={this.billingCurrencyChange}
                                      select
                                    >
                                      <MenuItem value="0" disabled>
                                        Select
                                      </MenuItem>
                                      <MenuItem
                                        value={
                                          config.company === "SRPL" ||
                                            config.company === "AVS" ||
                                            config.company === "PRPL"
                                            ? "USD"
                                            : "IDR"
                                        }
                                      >
                                        {config.company === "SRPL" ||
                                          config.company === "AVS" ||
                                          config.company === "PRPL"
                                          ? "USD"
                                          : "IDR"}
                                      </MenuItem>
                                      <MenuItem
                                        value={this.state.coalPriceCurrency}
                                      >
                                        {this.state.coalPriceCurrency}
                                      </MenuItem>
                                    </TextField>
                                  </div>
                                )}
                              {(config.company === "SDAM" ||
                                config.company === "SRK" ||
                                config.company === "BTR") &&
                                this.state.coalPriceCurrency !== "IDR" &&
                                this.state.coalPriceCurrency &&
                                this.state.coal_cost_billing_currency !==
                                this.state.coalPriceCurrency && (
                                  <div className="col-sm-4 mb-2">
                                    <label className="form_label mb-0">
                                      Exchange rate{" "}
                                      {this.state.coalPriceCurrency} to IDR
                                    </label>
                                    <TextField
                                      name="currency_xchg_rate"
                                      margin="dense"
                                      variant="outlined"
                                      type="number"
                                      fullWidth
                                      value={this.state.currency_xchg_rate}
                                      placeholder="Exchange rate"
                                      inputProps={{
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                        },
                                      }}
                                      onChange={this.onExchangeRateChange}
                                    />
                                  </div>
                                )}
                              {(config.company === "SRPL" ||
                                config.company === "AVS" ||
                                config.company === "PRPL") &&
                                this.state.coalPriceCurrency !== "USD" &&
                                this.state.coalPriceCurrency &&
                                this.state.coal_cost_billing_currency !==
                                this.state.coalPriceCurrency && (
                                  <div className="col-sm-4 mb-2">
                                    <label className="form_label mb-0">
                                      Exchange rate USD to{" "}
                                      {this.state.coalPriceCurrency}
                                    </label>
                                    <TextField
                                      name="currency_xchg_rate"
                                      margin="dense"
                                      variant="outlined"
                                      type="number"
                                      fullWidth
                                      value={this.state.currency_xchg_rate}
                                      placeholder="Exchange rate"
                                      inputProps={{
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "12px",
                                        },
                                      }}
                                      onChange={this.onExchangeRateChange}
                                    />
                                  </div>
                                )}
                              {this.state.coal_cost_billing_currency !==
                                this.state.coalPriceCurrency &&
                                this.state.coal_cost_billing_currency !==
                                "0" && (
                                  <div className="col-sm-4 mb-2">
                                    <label className="form_label mb-2">
                                      Coal Price per MT in{" "}
                                      {this.state.coal_cost_billing_currency}
                                    </label>
                                    <div className="contract_display_header_value">
                                      {this.state
                                        .coal_cost_coal_price_pmt_local_currency
                                        ? this.toLocaleString(
                                          this.state
                                            .coal_cost_coal_price_pmt_local_currency
                                        ) +
                                        " " +
                                        this.state.coal_cost_billing_currency
                                        : ""}
                                    </div>
                                  </div>
                                )}

                              <div className="col-sm-4 mb-2">
                                <label className="form_label mb-2">
                                  Coal Price
                                </label>
                                <div className="contract_display_header_value">
                                  {this.toLocaleString(
                                    this.state.coal_cost_coal_price
                                  )}{" "}
                                  {this.state.coal_cost_billing_currency}
                                </div>
                              </div>
                              {(config.company === "SDAM" ||
                                config.company === "SRK" ||
                                config.company === "BTR") && (
                                  <div className="col-sm-4 mb-2">
                                    <label className="form_label mb-2">
                                      VAT Value
                                    </label>
                                    <div className="contract_display_header_value">
                                      {this.state.vat_value !== "0"
                                        ? "(" +
                                        this.toLocaleString(
                                          this.state.vat_value
                                        ) +
                                        ")"
                                        : "(0)"}{" "}
                                      {this.state.coal_cost_billing_currency}
                                    </div>
                                  </div>
                                )}
                              {(config.company === "SDAM" ||
                                config.company === "SRK" ||
                                config.company === "BTR") && (
                                  <div className="col-sm-4 mb-2">
                                    <label className="form_label mb-2">
                                      PPh22 Local
                                    </label>
                                    <div className="contract_display_header_value">
                                      {this.state
                                        .coal_cost_pph22_percent_val_local_cur !==
                                        "0"
                                        ? "(" +
                                        this.toLocaleString(
                                          this.state
                                            .coal_cost_pph22_percent_val_local_cur
                                        ) +
                                        ")"
                                        : "(0)"}{" "}
                                      {this.state.coal_cost_billing_currency}
                                    </div>
                                  </div>
                                )}

                              <div className="col-sm-4 mb-2">
                                <label className="form_label mb-2">
                                  PPh22 Export
                                </label>
                                <div className="contract_display_header_value">
                                  {this.state
                                    .coal_cost_pph22_export_value_local_cur !==
                                    "0"
                                    ? "(" +
                                    this.toLocaleString(
                                      this.state
                                        .coal_cost_pph22_export_value_local_cur
                                        ? this.state
                                          .coal_cost_pph22_export_value_local_cur
                                        : 0
                                    ) +
                                    ")"
                                    : "(0)"}{" "}
                                  {this.state.coal_cost_billing_currency}
                                </div>
                              </div>
                              <div className="col-sm-4 mb-2">
                                <label className="form_label mb-2">
                                  Advance Adjustments
                                </label>
                                <div className="contract_display_header_value">
                                  {this.state.total_advance_adjustment_value !==
                                    "0"
                                    ? "(" +
                                    this.toLocaleString(
                                      this.state
                                        .total_advance_adjustment_value
                                        ? this.state
                                          .total_advance_adjustment_value
                                        : 0
                                    ) +
                                    ")"
                                    : "(0)"}{" "}
                                  {this.state.coal_cost_billing_currency}
                                </div>
                              </div>
                              <div className="col-sm-4 mb-2">
                                <label className="form_label mb-2">
                                  Receivable from Buyer
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ color: config.themeColor }}
                                >
                                  {this.toLocaleString(
                                    this.state.receivable_from_supplier
                                      ? this.state.receivable_from_supplier
                                      : 0
                                  )}{" "}
                                  {this.state.coal_cost_billing_currency}
                                </div>
                              </div>
                            </div>
                            {(config.company === "SRPL" ||
                              config.company === "AVS" ||
                              config.company === "PRPL") && (
                                <div className="row mt-2">
                                  <div className="col-lg-6 p-0">
                                    <div className="row">
                                      <div className="col-lg-12">
                                        <label className="form_label mb-0">
                                          PPH22 Export Applicable
                                        </label>
                                        <RadioGroup
                                          aria-label="coal_cost_pph22_export"
                                          name="coal_cost_pph22_export"
                                          onChange={this.handleRadioButtons}
                                          style={{ flexDirection: "row" }}
                                        >
                                          <FormControlLabel
                                            value="Yes"
                                            control={
                                              <NewRadio
                                                checked={
                                                  this.state
                                                    .coal_cost_pph22_export ===
                                                  "Yes"
                                                }
                                              />
                                            }
                                            label="Yes"
                                          />
                                          <FormControlLabel
                                            value="No"
                                            control={
                                              <NewRadio
                                                checked={
                                                  this.state
                                                    .coal_cost_pph22_export ===
                                                  "No"
                                                }
                                              />
                                            }
                                            label="No"
                                          />
                                        </RadioGroup>
                                      </div>
                                    </div>
                                    {(config.company === "SRPL" ||
                                      config.company === "AVS" ||
                                      config.company === "PRPL") &&
                                      this.state.coal_cost_pph22_export ===
                                      "Yes" && (
                                        <div className="row">
                                          <div
                                            className="col-lg-12 rounded"
                                            style={{ backgroundColor: "#f7f3f0" }}
                                          >
                                            <h6>PPH22 Export</h6>
                                            <div className="row">
                                              <div className="col-lg-12 p-0">
                                                <label
                                                  className="contract_display_header_label"
                                                  style={{
                                                    color:
                                                      "rgba(72, 59, 52, 0.7)",
                                                  }}
                                                >
                                                  Amount
                                                </label>
                                                <TextField
                                                  name="coal_cost_pph22_export_amount"
                                                  margin="dense"
                                                  type="number"
                                                  variant="outlined"
                                                  fullWidth
                                                  value={
                                                    this.state
                                                      .coal_cost_pph22_export_amount
                                                  }
                                                  onChange={
                                                    this.onPph22ExportChange
                                                  }
                                                />
                                              </div>
                                              <div className="col-lg p-0">
                                                <label
                                                  className="contract_display_header_label"
                                                  style={{
                                                    color:
                                                      "rgba(72, 59, 52, 0.7)",
                                                  }}
                                                >
                                                  PPH22 Export %
                                                </label>
                                                <div
                                                  className="contract_display_header_value"
                                                  style={{ color: "#42352d" }}
                                                >
                                                  {this.state
                                                    .coal_cost_pph22_export_percent
                                                    ? this.toLocaleString(
                                                      this.state
                                                        .coal_cost_pph22_export_percent
                                                    )
                                                    : "-"}
                                                </div>
                                              </div>
                                              <div className="col-lg p-0">
                                                <label
                                                  className="contract_display_header_label"
                                                  style={{
                                                    color:
                                                      "rgba(72, 59, 52, 0.7)",
                                                  }}
                                                >
                                                  PPH22 Export
                                                </label>
                                                <div
                                                  className="contract_display_header_value"
                                                  style={{ color: "#42352d" }}
                                                >
                                                  {this.state
                                                    .coal_cost_pph22_export_value_local_cur
                                                    ? this.toLocaleString(
                                                      this.state
                                                        .coal_cost_pph22_export_value_local_cur
                                                    )
                                                    : "-"}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                  </div>
                                </div>
                              )}
                            {(config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") && (
                                <div className="row mt-2">
                                  <div className="col-lg-6 p-0 mb-2">
                                    <div className="row">
                                      <div className="col-lg-12">
                                        <label className="form_label mb-0">
                                          VAT Applicable
                                        </label>
                                        <RadioGroup
                                          aria-label="vat_applicable"
                                          name="vat_applicable"
                                          onChange={(e) => {
                                            this.setState(
                                              {
                                                vat_applicable: e.target.value,
                                              },
                                              () => {
                                                this.onVatChangeHandler();
                                              }
                                            );
                                          }}
                                          style={{ flexDirection: "row" }}
                                        >
                                          <FormControlLabel
                                            value="Yes"
                                            control={
                                              <NewRadio
                                                checked={
                                                  this.state.vat_applicable ===
                                                  "Yes"
                                                }
                                              />
                                            }
                                            label="Yes"
                                          />
                                          <FormControlLabel
                                            value="No"
                                            control={
                                              <NewRadio
                                                checked={
                                                  this.state.vat_applicable ===
                                                  "No"
                                                }
                                              />
                                            }
                                            label="No"
                                          />
                                        </RadioGroup>
                                      </div>
                                    </div>
                                    {this.state.vat_applicable === "Yes" && (
                                      <div className="row">
                                        <div
                                          className="col-lg-12 p-2 rounded"
                                          style={{ backgroundColor: "#f7f3f0" }}
                                        >
                                          <h6>VAT</h6>
                                          <div className="row">
                                            <div className="col-lg-12 p-0">
                                              <label
                                                className="contract_display_header_label"
                                                style={{
                                                  color: "rgba(72, 59, 52, 0.7)",
                                                }}
                                              >
                                                Amount
                                              </label>
                                              <TextField
                                                name="vat_amount"
                                                margin="dense"
                                                variant="outlined"
                                                type="number"
                                                value={this.state.vat_amount}
                                                // fullWidth
                                                inputProps={{
                                                  style: {
                                                    fontFamily: "Poppins",
                                                    fontSize: "12px",
                                                  },
                                                }}
                                                onChange={(e) => {
                                                  const quan =
                                                    /^\d*(\.\d{0,4})?$/;
                                                  if (
                                                    e.target.value === "" ||
                                                    quan.test(e.target.value)
                                                  ) {
                                                    var value = e.target.value;
                                                    this.setState(
                                                      {
                                                        vat_amount: value,
                                                      },
                                                      () => {
                                                        this.onVatChangeHandler();
                                                      }
                                                    );
                                                  }
                                                }}
                                              />
                                            </div>
                                            <div className="col-lg p-0">
                                              <label
                                                className="contract_display_header_label"
                                                style={{
                                                  color: "rgba(72, 59, 52, 0.7)",
                                                }}
                                              >
                                                VAT %
                                              </label>
                                              <div
                                                className="contract_display_header_value"
                                                style={{ color: "#42352d" }}
                                              >
                                                <TextField
                                                  name="vat_percent"
                                                  margin="dense"
                                                  type="number"
                                                  variant="standard"
                                                  fullWidth
                                                  inputProps={{
                                                    style: {
                                                      fontFamily: "Poppins",
                                                      fontSize: "14px",
                                                    },
                                                  }}
                                                  value={this.state.vat_percent}
                                                  onChange={(e) => {
                                                    const pr = /^\d*(\.\d{0,4})?$/;
                                                    if (
                                                      e.target.value === "" ||
                                                      pr.test(e.target.value)
                                                    ) {
                                                      var value = e.target.value;
                                                      this.setState(
                                                        {
                                                          vat_percent: value,
                                                        },
                                                        () => {
                                                          this.onVatChangeHandler();
                                                        }
                                                      );
                                                    }
                                                  }}
                                                />
                                              </div>
                                            </div>
                                            <div className="col-lg p-0">
                                              <label
                                                className="contract_display_header_label"
                                                style={{
                                                  color: "rgba(72, 59, 52, 0.7)",
                                                }}
                                              >
                                                VAT Value
                                              </label>
                                              <div
                                                className="contract_display_header_value"
                                                style={{ color: "#42352d" }}
                                              >
                                                {this.state.vat_value
                                                  ? this.toLocaleString(
                                                    this.state.vat_value
                                                  )
                                                  : "-"}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  <div className="col-lg-6 pr-0 mb-2">
                                    <div
                                      className="row"
                                      style={{ marginBottom: 72 }}
                                    >
                                      <div className="col-lg-12">
                                        {/* <label className='form_label mb-2'>PPH22 Local</label>  */}
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div
                                        className="col-lg-12  p-2 rounded"
                                        style={{ backgroundColor: "#f7f3f0" }}
                                      >
                                        <h6>PPH22 Local</h6>
                                        <div className="row">
                                          <div className="col-lg-6 p-0">
                                            <label
                                              className="contract_display_header_label"
                                              style={{
                                                color: "rgba(72, 59, 52, 0.7)",
                                              }}
                                            >
                                              Barge Quantity in MT
                                            </label>
                                            <TextField
                                              name="coal_cost_pph22_barge_quantity_in_mt"
                                              margin="dense"
                                              variant="outlined"
                                              type="number"
                                              value={
                                                this.state
                                                  .coal_cost_pph22_barge_quantity_in_mt
                                              }
                                              fullWidth
                                              inputProps={{
                                                style: {
                                                  fontFamily: "Poppins",
                                                  fontSize: "12px",
                                                },
                                              }}
                                              onChange={this.onPph22LocalChange}
                                            />
                                          </div>
                                          <div className="col-lg-6 pr-0">
                                            <label
                                              className="contract_display_header_label"
                                              style={{
                                                color: "rgba(72, 59, 52, 0.7)",
                                              }}
                                            >
                                              Coal Price per MT
                                            </label>
                                            <TextField
                                              name="coal_cost_pph22_coal_price_per_mt"
                                              margin="dense"
                                              type="number"
                                              value={
                                                this.state
                                                  .coal_cost_pph22_coal_price_per_mt
                                              }
                                              variant="outlined"
                                              fullWidth
                                              inputProps={{
                                                style: {
                                                  fontFamily: "Poppins",
                                                  fontSize: "12px",
                                                },
                                              }}
                                              onChange={
                                                this.onPph22LocalCoalPriceChange
                                              }
                                            />
                                          </div>
                                          <div className="col-lg p-0">
                                            <label
                                              className="contract_display_header_label"
                                              style={{
                                                color: "rgba(72, 59, 52, 0.7)",
                                              }}
                                            >
                                              PPH22 Local %
                                            </label>
                                            <div
                                              className="contract_display_header_value"
                                              style={{ color: "#42352d" }}
                                            >
                                              {this.state.coal_cost_pph22_percent
                                                ? this.toLocaleString(
                                                  this.state
                                                    .coal_cost_pph22_percent
                                                )
                                                : "-"}
                                            </div>
                                          </div>
                                          {this.state.coalPriceCurrency ===
                                            this.state
                                              .coal_cost_billing_currency && (
                                              <div className="col-lg p-0">
                                                <label
                                                  className="contract_display_header_label"
                                                  style={{
                                                    color: "rgba(72, 59, 52, 0.7)",
                                                  }}
                                                >
                                                  PPH22 Local in{" "}
                                                  {this.state.coalPriceCurrency}
                                                </label>
                                                <div
                                                  className="contract_display_header_value"
                                                  style={{ color: "#42352d" }}
                                                >
                                                  {this.state
                                                    .coal_cost_pph22_percent_val_local_cur
                                                    ? this.toLocaleString(
                                                      this.state
                                                        .coal_cost_pph22_percent_val_local_cur
                                                    )
                                                    : "-"}
                                                </div>
                                              </div>
                                            )}
                                          {(config.company === "SDAM" ||
                                            config.company === "SRK" ||
                                            config.company === "BTR") &&
                                            this.state.coalPriceCurrency !==
                                            "IDR" && (
                                              <div className="col-lg p-0">
                                                <label
                                                  className="contract_display_header_label"
                                                  style={{
                                                    color:
                                                      "rgba(72, 59, 52, 0.7)",
                                                  }}
                                                >
                                                  PPH22 Local in{" "}
                                                  {config.company === "SRPL" ||
                                                    config.company === "AVS" ||
                                                    config.company === "PRPL"
                                                    ? " USD"
                                                    : "IDR"}{" "}
                                                </label>
                                                <div
                                                  className="contract_display_header_value"
                                                  style={{ color: "#42352d" }}
                                                >
                                                  {this.state
                                                    .coal_cost_pph22_percent_val_local_cur_new
                                                    ? this.toLocaleString(
                                                      Number(
                                                        this.state
                                                          .coal_cost_pph22_percent_val_local_cur_new
                                                      ).toFixed(0)
                                                    )
                                                    : "-"}
                                                </div>
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 p-0">
                                    <div className="row">
                                      <div className="col-lg-12 p-0">
                                        <label className="form_label mb-0">
                                          PPH22 Export Applicable
                                        </label>
                                        <RadioGroup
                                          aria-label="coal_cost_pph22_export"
                                          name="coal_cost_pph22_export"
                                          onChange={this.handleRadioButtons}
                                          style={{ flexDirection: "row" }}
                                        >
                                          <FormControlLabel
                                            value="Yes"
                                            control={
                                              <NewRadio
                                                checked={
                                                  this.state
                                                    .coal_cost_pph22_export ===
                                                  "Yes"
                                                }
                                              />
                                            }
                                            label="Yes"
                                          />
                                          <FormControlLabel
                                            value="No"
                                            control={
                                              <NewRadio
                                                checked={
                                                  this.state
                                                    .coal_cost_pph22_export ===
                                                  "No"
                                                }
                                              />
                                            }
                                            label="No"
                                          />
                                        </RadioGroup>
                                      </div>
                                    </div>
                                    {this.state.coal_cost_pph22_export ===
                                      "Yes" && (
                                        <div className="row">
                                          <div
                                            className="col-lg-12  p-2 rounded"
                                            style={{ backgroundColor: "#f7f3f0" }}
                                          >
                                            <h6>PPH22 Export</h6>
                                            <div className="row">
                                              <div className="col-lg-12 p-0">
                                                <label
                                                  className="contract_display_header_label"
                                                  style={{
                                                    color: "rgba(72, 59, 52, 0.7)",
                                                  }}
                                                >
                                                  Amount
                                                </label>
                                                <TextField
                                                  name="coal_cost_pph22_export_amount"
                                                  margin="dense"
                                                  type="number"
                                                  variant="outlined"
                                                  //fullWidth
                                                  inputProps={{
                                                    style: {
                                                      fontFamily: "Poppins",
                                                      fontSize: "12px",
                                                    },
                                                  }}
                                                  value={
                                                    this.state
                                                      .coal_cost_pph22_export_amount
                                                  }
                                                  onChange={
                                                    this.onPph22ExportChange
                                                  }
                                                />
                                              </div>
                                              <div className="col-lg-6 p-0">
                                                <label
                                                  className="contract_display_header_label"
                                                  style={{
                                                    color: "rgba(72, 59, 52, 0.7)",
                                                  }}
                                                >
                                                  PPH22 Export %
                                                </label>
                                                <div
                                                  className="contract_display_header_value"
                                                  style={{ color: "#42352d" }}
                                                >
                                                  {this.state
                                                    .coal_cost_pph22_export_percent
                                                    ? this.toLocaleString(
                                                      this.state
                                                        .coal_cost_pph22_export_percent
                                                    )
                                                    : "-"}
                                                </div>
                                              </div>
                                              {this.state.coalPriceCurrency ===
                                                this.state
                                                  .coal_cost_billing_currency && (
                                                  <div className="col-lg mb-2 p-0">
                                                    <label
                                                      className="contract_display_header_label"
                                                      style={{
                                                        color:
                                                          "rgba(72, 59, 52, 0.7)",
                                                      }}
                                                    >
                                                      PPH22 Export in{" "}
                                                      {
                                                        this.state
                                                          .coal_cost_billing_currency
                                                      }
                                                    </label>
                                                    <div
                                                      className="contract_display_header_value"
                                                      style={{ color: "#42352d" }}
                                                    >
                                                      {this.state
                                                        .coal_cost_pph22_export_value_local_cur
                                                        ? this.toLocaleString(
                                                          Number(
                                                            this.state
                                                              .coal_cost_pph22_export_value_local_cur
                                                          )
                                                        )
                                                        : "-"}
                                                    </div>
                                                  </div>
                                                )}
                                              <div className="col-lg-6 p-0">
                                                <label
                                                  className="contract_display_header_label"
                                                  style={{
                                                    color: "rgba(72, 59, 52, 0.7)",
                                                  }}
                                                >
                                                  PPH22 Export in{" "}
                                                  {config.company === "SRPL" ||
                                                    config.company === "AVS" ||
                                                    config.company === "PRPL"
                                                    ? " USD"
                                                    : "IDR"}
                                                </label>
                                                <div
                                                  className="contract_display_header_value"
                                                  style={{ color: "#42352d" }}
                                                >
                                                  {" "}
                                                  {this.state.pph22_export_value
                                                    ? this.toLocaleString(
                                                      Number(
                                                        this.state
                                                          .pph22_export_value
                                                      ).toFixed(0)
                                                    )
                                                    : "-"}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>

                      <br />
                      {(this.state.features.includes("366") ||
                        this.state.features.includes("97")) && (
                          <FileUpload
                            files={this.state.files}
                            type="Costings"
                            invoice_no={this.state.invoice_file}
                            feature_name="Sales_fin_performa_coal_costing"
                            callbackFn={(data, mandatoryFiles) => {
                              this.setState({
                                files: data,
                                costing_files: mandatoryFiles,
                              });
                            }}
                          />
                        )}
                    </div>
                  )}
              </div>
            </div>
          </div>
          <footer className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button btn-light"
              name="submit"
              onClick={() => this.props.returnCallbackViewPage(true)}
            >
              {this.state.activeStep === 1 ? "CANCEL" : "PREVIOUS"}
            </button>
            <button
              type="button"
              className={
                this.state.activeStep === 1
                  ? "drawer_text drawer_btn_success"
                  : "btn next_button"
              }
              name="Back"
              onClick={
                this.state.activeStep === 1
                  ? this.submitHandler
                  : this.nextStepHandler
              }
            >
              {this.state.activeStep === 1 ? "SUBMIT" : "NEXT STEP"}
            </button>
          </footer>
          <div className="modal fade" id="scModal" role="dialog">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
              <div className="modal-content allocation_modal">
                <div className="modal-body">
                  <label className="form_label mb-2 mt-2">
                    Select Sales Quality
                  </label>
                  <button type="button" className="close" data-dismiss="modal">
                    &times;
                  </button>
                  <div className="row col-lg-12 pl-0">
                    {this.state.salesTypes.map((e, index) => (
                      <div className="col col-lg-6 p-1 mb-2" key={index}>
                        <div
                          className="row col-lg-12 rounded p-0"
                          style={{
                            border:
                              e.id === this.state.salesQualitySelection
                                ? "1px solid #ec7833"
                                : "1px solid #dee2e6",
                          }}
                        >
                          <div className="col-lg-1 p-0">
                            <NewRadio
                              checked={
                                e.id === this.state.salesQualitySelection
                              }
                              onChange={() => {
                                this.setState({
                                  sales_quality: e.quality,
                                  sc_qualities_id: e.id,
                                  salesQualitySelection: e.id,
                                  sales_index_linked: e.is_index_linked,
                                  coal_cost_coal_quantity: e.quantity_in_mt,
                                  barge_quantity: e.quantity_in_mt,
                                });
                              }}
                              value={e.id}
                              name="salesQualitySelection"
                              inputProps={{ "aria-label": "B" }}
                              className="collapsed"
                              data-toggle="collapse"
                              data-target={"#demo" + e.id}
                              aria-expanded="false"
                              aria-controls={"group-of-rows-1"}
                            />
                          </div>
                          <div className="col-lg-11 pr-0">
                            <div className="row col-lg-12 p-0">
                              <div className="col-lg-6 pl-0">
                                <label className="drawer_section_label mb-0">
                                  Quality
                                </label>
                                <label className="drawer_section_value mb-0">
                                  {e.quality}
                                </label>
                              </div>
                              <div className="col-lg-6 pr-0">
                                <label className="drawer_section_label mb-0">
                                  Tolerence
                                </label>
                                <label className="drawer_section_value mb-0">
                                  {e.tolerance_adjusted + "%"}
                                </label>
                              </div>
                            </div>
                            <div className="row col-lg-12 p-0">
                              <div className="col-lg-6 pl-0">
                                <label className="drawer_section_label mb-0">
                                  Quantity
                                </label>
                              </div>
                              <div className="col-lg-6 pr-0">
                                <label className="drawer_section_total mb-0">
                                  Total
                                </label>
                                <label className="drawer_section_total_value mb-0">
                                  {this.toLocaleString(
                                    Number(e.quantity_adjusted).toFixed(3)
                                  )}
                                </label>
                              </div>
                            </div>
                            <div style={{ width: "98%" }}>
                              <BorderLinearProgress
                                variant="determinate"
                                value={this.progressBarSize(e, "NSC")}
                                style={{ borderRadius: 5 }}
                              />
                            </div>
                            <div className="row col-lg-12 p-0 mb-2">
                              <div className="col-lg-4 pl-0">
                                <label className="drawer_section_value mb-0">
                                  {this.toLocaleString(
                                    Number(e.quantity_allocated).toFixed(3)
                                  )}
                                </label>
                                <label className="drawer_section_label mb-0">
                                  Allocated
                                </label>
                              </div>
                              <div className="col-lg-4 pl-0">
                                <label className="drawer_section_value mb-0">
                                  {this.toLocaleString(
                                    Number(e.sc_quantity_delivered).toFixed(3)
                                  )}
                                </label>
                                <label className="drawer_section_label mb-0">
                                  Delivered
                                </label>
                              </div>
                              <div className="col-lg-4 pl-0">
                                <label className="drawer_section_value mb-0">
                                  {this.toLocaleString(this._toBeDelivered(e))}
                                </label>
                                <label className="drawer_section_label mb-0">
                                  Remaining
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <label className="form_label mb-2 mt-2">
                    Select Sales Type
                  </label>
                  <div className="pl-0">
                    {this.state.salesTypes.map((e, index) => (
                      <div key={index}>
                        {e.id === this.state.salesQualitySelection && (
                          <table style={{ width: "100%" }}>
                            <thead className="thead-light">
                              <tr>
                                <th></th>
                                <th className="drawer_table_header_label">
                                  Mine Name
                                </th>
                                <th className="drawer_table_header_label">
                                  Sales Type
                                </th>
                                <th className="drawer_table_header_label">
                                  Anchorage Name
                                </th>
                                <th className="drawer_table_header_label">
                                  Coal Price
                                </th>
                              </tr>
                            </thead>
                            {e.sales_types.map((s, idx) => (
                              <tbody key={idx}>
                                <tr
                                  style={{
                                    border:
                                      s.id === this.state.salesTypeSelection
                                        ? "1px solid #ec7833"
                                        : "1px solid #dee2e6",
                                  }}
                                >
                                  <td style={{ verticalAlign: "middle" }}>
                                    <NewRadio
                                      checked={
                                        s.id === this.state.salesTypeSelection
                                      }
                                      onChange={(e) => {
                                        this.setState(
                                          {
                                            sales_type: s.sales_type,
                                            sc_sales_type_id: e.target.value,
                                            salesTypeSelection: e.target.value,
                                            coal_cost_coal_price_pmt:
                                              s.price_pmt,
                                            sales_price_pmt: s.price_pmt,
                                          },
                                          () => {
                                            window.$("#scModal").modal("hide");
                                            this.callingAsync();
                                          }
                                        );
                                      }}
                                      value={s.id}
                                      name="salesTypeSelection"
                                      inputProps={{ "aria-label": "B" }}
                                    />
                                  </td>
                                  <td className="drawer_table_value">
                                    {s.mine_name}
                                  </td>
                                  <td className="drawer_table_value">
                                    {s.sales_type}
                                  </td>
                                  <td className="drawer_table_value">
                                    {s.sales_type === "FAS Anchorage"
                                      ? s.anchorage_name
                                      : ""}
                                  </td>
                                  <td className="drawer_table_value">
                                    {s.price_pmt} {this.state.salesCurrency}
                                  </td>
                                </tr>
                              </tbody>
                            ))}
                          </table>
                        )}
                      </div>
                    ))}
                  </div>
                </div>

                <div className="row bottom_buttons_section">
                  {/* <button type='button' className='btn previous_button' onClick={() => this.salesContractModalClose(this.state.modalID)}>Cancel</button>
                <button type='button' className='btn next_button' onClick={() => this.salesTypeSelected(this.state.modalID)}>Close</button>&emsp; */}
                  <button
                    type="button"
                    className="btn next_button"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  &emsp;
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
