import React, { Component } from "react";
import SideBar from "../../common/SideBar";
import {
  MenuItem,
  TextField,
  FormControl,
  ListItemText,
  Select,
  Checkbox,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import CookieHandler from "../../common/CookieHandler";
import Header from "../../common/Header";
import AddCircle from "@material-ui/icons/AddCircle";
import api from "../../../api/api";
import config from "../../../config/config";
import Autocomplete from "@material-ui/lab/Autocomplete";

import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import EventIcon from "@material-ui/icons/Event";
import FileUpload from "../../common/FileUpload";
import { withStyles } from "@material-ui/core/styles";
import { dateFormateToDB, localDateFormate } from "../../common/common";
import Loader from "../../common/Loader";
import QualityView from "../Qualities/QualitiesView";
import SalesQualities from "../Qualities/SalesQualities";
import { SPECFICIATIONS } from "../constants";

const CheckBox = withStyles({
  root: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

/* <!--------------------------------     Class Component     --- ------------------------> */

export default class SalesAddendum extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      contract_no: "",
      old_contract_no: "",
      customer: "0",
      dialogText: "Please fill all mandatory fields.",
      contract_date: null,
      filteredCustomerData: [],
      customersDataList: [],
      salesTypeRows: [
        {
          quality: "",
          quantity_in_mt: "",
          tolerance: "",
          is_index_linked: "No",
          sales_types: [
            {
              mine: "",
              sales_type: "0",
              anchorage_name: "",
              price_pmt: "",
              purchase_typeError: false,
              price_pmtError: false,
              coal_index_type: "Weekly",
              index_ids: [],
              average_coal_index_price: 0,
              prorata_gar: "0",
              prorata_coal_index_price: 0,
              bonus_coal_index_pmt: "",
              penality_coal_index_pmt: "",
              derivered_coal_index_pmt: "",
              final_coal_index_pmt: "",
              coal_index_remarks: "",
            },
          ],
          qualityError: false,
          quantity_in_mtError: false,
          ToleranceError: false,
          anchorage_nameError: false,
          sales_term: "0",
          specification_standard: "ASTM/ISO",
          astmClone: false,
          specifications: SPECFICIATIONS,
        },
      ],
      salesType: "0",
      quality: "",
      quantity: "",
      tolerance: "0",
      LaycanData: [{ s_no: "1", laycan: "", laycan_quantity: "" }],
      loadingRate: "",
      sales_term: "0",
      is_index_linked: "No",
      analysisTypeValues: ["ASTM", "ISO"],
      supplierSurveyorName: [],
      supplierSurveyorAnalysisType: [],
      saiiSurveyorName: [],
      saiiSurveyorAnalysisType: [],
      buyerSurveyorName: [],
      buyerSurveyorAnalysisType: [],
      surveyorsData: [],
      vendorsDataList: [],
      portOfDischargeRows: [{ discharge_rate: "", portOf_discharge: "" }],
      price: "",
      currency: "0",
      pod: "",
      vessel_type: "0",
      payment_terms_1: "",
      loading_rate_for_geared: "",
      loading_rate_for_gearless: "",
      payment_terms_2: "",
      remarks: "",
      share_point_doc_link: "",
      currencyData: [],
      clickedCurrency: "",
      newCurrency: "",
      laycan1Error: false,
      currencyError: false,
      PodError: false,
      vesselTypeError: false,
      errorMessage: "",
      successMessage: "",
      isLoading: true,
      features: [],
      activeStep: 0,
      files: [
        {
          file_description: "",
          file_name: "",
          file_extension: "",
          file_content: "",
          feature_name: "Sales_Contract",
        },
      ],
      open: false,
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (!(features.includes("18") || features.includes("97"))) {
      window.location.href = "/dashboard";
    }
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const status = "Active";
    // for get_Currencies
    await api.getCurrencies(loginUserID, idToken).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
          if (res) {
            var currencies = [
              <MenuItem value="0" key={-1} disabled>
                Please Select
              </MenuItem>,
            ];
            for (var i in res.Currency) {
              currencies.push(
                <MenuItem value={res.Currency[i].currency} key={[i]}>
                  {res.Currency[i].currency}
                </MenuItem>
              );
            }
            this.setState({
              currencyData: currencies,
            });
          } else {
            alert("Unexpected error occured. Please contact administrator.");
          }
        });
      } else if (response.status === 607) {
        window.location.href = "/logout";
      }
    });
    const customerData = [];
    // fot get_customers
    await api.getCustomers(loginUserID, idToken, status).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
          if (res.customers) {
            for (let i = 0; i < res.customers.length; i++) {
              customerData.push({
                name: res.customers[i].customer_name,
                value: res.customers[i].id,
                key: i,
              });
            }
            this.setState({
              customersDataList: customerData,
            });
          } else {
            alert("Unexpected error occured. Please contact administrator.");
          }
        });
      } else if (response.status === 607) {
        window.location.href = "/logout";
      }
    });

    await api.getVendors(loginUserID, idToken, status).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
          if (res.vendors) {
            const surveyorsData = [];
            const vendorAsCoal = [];
            for (let i = 0; i < res.vendors.length; i++) {
              if (res.vendors[i].vendor_type === "Surveyor") {
                surveyorsData.push({
                  name: res.vendors[i].vendor_name,
                  value: res.vendors[i].id,
                  key: i,
                });
              }
              if (res.vendors[i].vendor_type === "Coal") {
                vendorAsCoal.push({
                  mine_name: res.vendors[i].vendor_name,
                  value: res.vendors[i].id,
                  key: i,
                });
              }
            }
            this.setState({
              surveyorsData: surveyorsData,
              vendorTypeAsCoalData: vendorAsCoal,
            });
          } else {
            alert("Unexpected error occured. Please contact administrator.");
          }
        });
      } else if (response.status === 607) {
        window.location.href = "/logout";
      }
    });

    await this.getSalesContractData();
    document.title = config.documentTitle + "Update Sales Contract";
  }

  getSalesContractData() {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const status = "Active";
    // for get SalesContract
    const salesContractId = decodeURIComponent(
      window.atob(this.props.match.params.salesContractID)
    );
    this.setState({ salesContractID: salesContractId });

    api
      .getSalesContract(loginUserID, salesContractId, idToken, status)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);

              if (res.sales_contract) {
                if (res.sales_contract.status !== "Active") {
                  // if contract status as ! Active directing to dashboard page
                  window.location.href = "/dashboard";
                }

                var specData = res.sales_contract.qualities;
                for (let k = 0; k < specData.length; k++) {
                  if (!specData[k].specifications) {
                    specData[k].specifications =
                      this.state.salesTypeRows[0].specifications;
                  } else {
                    Object.keys(specData[k].specifications).forEach(function (
                      key
                    ) {
                      if (
                        specData[k].specifications
                          .quality_calorific_value_basis === null
                      ) {
                        specData[
                          k
                        ].specifications.quality_calorific_value_basis = "0";
                      }
                      if (
                        specData[k].specifications
                          .quality_calorific_value2_basis === null
                      ) {
                        specData[
                          k
                        ].specifications.quality_calorific_value2_basis = "0";
                      }
                      if (
                        specData[k].specifications
                          .quality_calorific_value3_basis === null
                      ) {
                        specData[
                          k
                        ].specifications.quality_calorific_value3_basis = "0";
                      }
                      if (
                        specData[k].specifications
                          .quality_total_moisture_basis === null
                      ) {
                        specData[
                          k
                        ].specifications.quality_total_moisture_basis = "0";
                      }
                      if (
                        specData[k].specifications
                          .quality_inherent_moisture_basis === null
                      ) {
                        specData[
                          k
                        ].specifications.quality_inherent_moisture_basis =
                          "0";
                      }
                      if (
                        specData[k].specifications.quality_ash_basis === null
                      ) {
                        specData[k].specifications.quality_ash_basis = "0";
                      }
                      if (
                        specData[k].specifications
                          .quality_volatile_matter_basis === null
                      ) {
                        specData[
                          k
                        ].specifications.quality_volatile_matter_basis = "0";
                      }
                      if (
                        specData[k].specifications
                          .quality_fixed_carbon_basis === null
                      ) {
                        specData[
                          k
                        ].specifications.quality_fixed_carbon_basis = "0";
                      }
                      if (
                        specData[k].specifications
                          .quality_fuel_ratio_basis === null
                      ) {
                        specData[k].specifications.quality_fuel_ratio_basis =
                          "0";
                      }
                      if (
                        specData[k].specifications
                          .quality_total_sulphur_basis === null
                      ) {
                        specData[
                          k
                        ].specifications.quality_total_sulphur_basis = "0";
                      }
                      if (
                        specData[k].specifications.ultimate_carbon_basis ===
                        null
                      ) {
                        specData[k].specifications.ultimate_carbon_basis =
                          "0";
                      }
                      if (
                        specData[k].specifications.ultimate_hydrogen_basis ===
                        null
                      ) {
                        specData[k].specifications.ultimate_hydrogen_basis =
                          "0";
                      }
                      if (
                        specData[k].specifications.ultimate_nitrogen_basis ===
                        null
                      ) {
                        specData[k].specifications.ultimate_nitrogen_basis =
                          "0";
                      }
                      if (
                        specData[k].specifications.ash_sio2_basis === null
                      ) {
                        specData[k].specifications.ash_sio2_basis = "0";
                      }
                      if (
                        specData[k].specifications.ash_ai203_basis === null
                      ) {
                        specData[k].specifications.ash_ai203_basis = "0";
                      }
                      if (
                        specData[k].specifications.ash_sio2_basis === null
                      ) {
                        specData[k].specifications.ash_sio2_basis = "0";
                      }
                      if (
                        specData[k].specifications.ash_fe203_basis === null
                      ) {
                        specData[k].specifications.ash_fe203_basis = "0";
                      }
                      if (specData[k].specifications.ash_cao_basis === null) {
                        specData[k].specifications.ash_cao_basis = "0";
                      }
                      if (specData[k].specifications.ash_mgo_basis === null) {
                        specData[k].specifications.ash_mgo_basis = "0";
                      }
                      if (
                        specData[k].specifications.ash_na2o_basis === null
                      ) {
                        specData[k].specifications.ash_na2o_basis = "0";
                      }
                      if (specData[k].specifications.ash_k2o_basis === null) {
                        specData[k].specifications.ash_k2o_basis = "0";
                      }
                      if (
                        specData[k].specifications.ash_tio2_basis === null
                      ) {
                        specData[k].specifications.ash_tio2_basis = "0";
                      }
                      if (
                        specData[k].specifications.ash_mn3o4_basis === null
                      ) {
                        specData[k].specifications.ash_mn3o4_basis = "0";
                      }
                      if (specData[k].specifications.ash_so3_basis === null) {
                        specData[k].specifications.ash_so3_basis = "0";
                      }
                      if (
                        specData[k].specifications.ash_p2o5_basis === null
                      ) {
                        specData[k].specifications.ash_p2o5_basis = "0";
                      }
                      if (
                        specData[k].specifications.trace_arsenic_basis ===
                        null
                      ) {
                        specData[k].specifications.trace_arsenic_basis = "0";
                      }
                      if (
                        specData[k].specifications.trace_mercury_basis ===
                        null
                      ) {
                        specData[k].specifications.trace_mercury_basis = "0";
                      }
                      if (
                        specData[k].specifications.trace_selenium_basis ===
                        null
                      ) {
                        specData[k].specifications.trace_selenium_basis = "0";
                      }
                      if (
                        specData[k].specifications.trace_boron_basis === null
                      ) {
                        specData[k].specifications.trace_boron_basis = "0";
                      }
                      if (
                        specData[k].specifications.minor_ba_basis === null
                      ) {
                        specData[k].specifications.minor_ba_basis = "0";
                      }
                      if (
                        specData[k].specifications.minor_phosphorous_basis ===
                        null
                      ) {
                        specData[k].specifications.minor_phosphorous_basis =
                          "0";
                      }
                      if (
                        specData[k].specifications
                          .quality_calorific_value_basis_iso === null
                      ) {
                        specData[
                          k
                        ].specifications.quality_calorific_value_basis_iso =
                          "0";
                      }
                      if (
                        specData[k].specifications
                          .quality_calorific_value2_basis_iso === null
                      ) {
                        specData[
                          k
                        ].specifications.quality_calorific_value2_basis_iso =
                          "0";
                      }
                      if (
                        specData[k].specifications
                          .quality_calorific_value3_basis_iso === null
                      ) {
                        specData[
                          k
                        ].specifications.quality_calorific_value3_basis_iso =
                          "0";
                      }
                      if (
                        specData[k].specifications
                          .quality_total_moisture_basis_iso === null
                      ) {
                        specData[
                          k
                        ].specifications.quality_total_moisture_basis_iso =
                          "0";
                      }
                      if (
                        specData[k].specifications
                          .quality_inherent_moisture_basis_iso === null
                      ) {
                        specData[
                          k
                        ].specifications.quality_inherent_moisture_basis_iso =
                          "0";
                      }
                      if (
                        specData[k].specifications.quality_ash_basis_iso ===
                        null
                      ) {
                        specData[k].specifications.quality_ash_basis_iso =
                          "0";
                      }
                      if (
                        specData[k].specifications
                          .quality_volatile_matter_basis_iso === null
                      ) {
                        specData[
                          k
                        ].specifications.quality_volatile_matter_basis_iso =
                          "0";
                      }
                      if (
                        specData[k].specifications
                          .quality_fixed_carbon_basis_iso === null
                      ) {
                        specData[
                          k
                        ].specifications.quality_fixed_carbon_basis_iso = "0";
                      }
                      if (
                        specData[k].specifications
                          .quality_fuel_ratio_basis_iso === null
                      ) {
                        specData[
                          k
                        ].specifications.quality_fuel_ratio_basis_iso = "0";
                      }
                      if (
                        specData[k].specifications
                          .quality_total_sulphur_basis_iso === null
                      ) {
                        specData[
                          k
                        ].specifications.quality_total_sulphur_basis_iso =
                          "0";
                      }
                      if (
                        specData[k].specifications
                          .ultimate_carbon_basis_iso === null
                      ) {
                        specData[k].specifications.ultimate_carbon_basis_iso =
                          "0";
                      }
                      if (
                        specData[k].specifications
                          .ultimate_hydrogen_basis_iso === null
                      ) {
                        specData[
                          k
                        ].specifications.ultimate_hydrogen_basis_iso = "0";
                      }
                      if (
                        specData[k].specifications
                          .ultimate_nitrogen_basis_iso === null
                      ) {
                        specData[
                          k
                        ].specifications.ultimate_nitrogen_basis_iso = "0";
                      }
                      if (
                        specData[k].specifications.ash_sio2_basis_iso === null
                      ) {
                        specData[k].specifications.ash_sio2_basis_iso = "0";
                      }
                      if (
                        specData[k].specifications.ash_ai203_basis_iso ===
                        null
                      ) {
                        specData[k].specifications.ash_ai203_basis_iso = "0";
                      }
                      if (
                        specData[k].specifications.ash_sio2_basis_iso === null
                      ) {
                        specData[k].specifications.ash_sio2_basis_iso = "0";
                      }
                      if (
                        specData[k].specifications.ash_fe203_basis_iso ===
                        null
                      ) {
                        specData[k].specifications.ash_fe203_basis_iso = "0";
                      }
                      if (
                        specData[k].specifications.ash_cao_basis_iso === null
                      ) {
                        specData[k].specifications.ash_cao_basis_iso = "0";
                      }
                      if (
                        specData[k].specifications.ash_mgo_basis_iso === null
                      ) {
                        specData[k].specifications.ash_mgo_basis_iso = "0";
                      }
                      if (
                        specData[k].specifications.ash_na2o_basis_iso === null
                      ) {
                        specData[k].specifications.ash_na2o_basis_iso = "0";
                      }
                      if (
                        specData[k].specifications.ash_k2o_basis_iso === null
                      ) {
                        specData[k].specifications.ash_k2o_basis_iso = "0";
                      }
                      if (
                        specData[k].specifications.ash_tio2_basis_iso === null
                      ) {
                        specData[k].specifications.ash_tio2_basis_iso = "0";
                      }
                      if (
                        specData[k].specifications.ash_mn3o4_basis_iso ===
                        null
                      ) {
                        specData[k].specifications.ash_mn3o4_basis_iso = "0";
                      }
                      if (
                        specData[k].specifications.ash_so3_basis_iso === null
                      ) {
                        specData[k].specifications.ash_so3_basis_iso = "0";
                      }
                      if (
                        specData[k].specifications.ash_p2o5_basis_iso === null
                      ) {
                        specData[k].specifications.ash_p2o5_basis_iso = "0";
                      }
                      if (
                        specData[k].specifications.trace_arsenic_basis_iso ===
                        null
                      ) {
                        specData[k].specifications.trace_arsenic_basis_iso =
                          "0";
                      }
                      if (
                        specData[k].specifications.trace_mercury_basis_iso ===
                        null
                      ) {
                        specData[k].specifications.trace_mercury_basis_iso =
                          "0";
                      }
                      if (
                        specData[k].specifications
                          .trace_selenium_basis_iso === null
                      ) {
                        specData[k].specifications.trace_selenium_basis_iso =
                          "0";
                      }
                      if (
                        specData[k].specifications.trace_boron_basis_iso ===
                        null
                      ) {
                        specData[k].specifications.trace_boron_basis_iso =
                          "0";
                      }
                      if (
                        specData[k].specifications.minor_ba_basis_iso === null
                      ) {
                        specData[k].specifications.minor_ba_basis_iso = "0";
                      }
                      if (
                        specData[k].specifications
                          .minor_phosphorous_basis_iso === null
                      ) {
                        specData[
                          k
                        ].specifications.minor_phosphorous_basis_iso = "0";
                      }
                      if (specData[k].specifications[key] === null) {
                        specData[k].specifications[key] = "";
                      }
                      if (!specData[k].specifications.activeTabIndex) {
                        specData[k].specifications.activeTabIndex = false;
                      }
                      if (!specData[k].specifications.activeTabIndex) {
                        specData[k].specifications.ISOactiveTabIndex = false;
                      }
                      if (!specData[k].specifications.ARBitem) {
                        specData[k].specifications.ARBitem = false;
                      }
                      if (!specData[k].specifications.ADBitem) {
                        specData[k].specifications.ADBitem = false;
                      }
                      if (!specData[k].specifications.DAFitem) {
                        specData[k].specifications.DAFitem = false;
                      }
                      if (!specData[k].specifications.DBitem) {
                        specData[k].specifications.DBitem = false;
                      }
                      if (!specData[k].specifications.NARitem) {
                        specData[k].specifications.NARitem = false;
                      }
                      if (!specData[k].specifications.Iso_ARBitem) {
                        specData[k].specifications.Iso_ARBitem = false;
                      }
                      if (!specData[k].specifications.Iso_ADBitem) {
                        specData[k].specifications.Iso_ADBitem = false;
                      }
                      if (!specData[k].specifications.Iso_DAFitem) {
                        specData[k].specifications.Iso_DAFitem = false;
                      }
                      if (!specData[k].specifications.Iso_DBitem) {
                        specData[k].specifications.Iso_DBitem = false;
                      }
                      if (!specData[k].specifications.Iso_NARitem) {
                        specData[k].specifications.Iso_NARitem = false;
                      }
                    });
                  }
                }

                if (res.sales_contract.pc_status === "Freezed") {
                  window.location.href =
                    "/view-sales-contract/" + btoa(salesContractId);
                }
                //TODO: <!--------------------------- disabling the specification (ASTM) DropDown Duplicate values ------------!>

                for (var j of res.sales_contract.qualities) {
                  if (
                    j.specifications.quality_calorific_value_basis === "ARB"
                  ) {
                    j.specifications.ARBitem = true;
                  } else if (
                    j.specifications.quality_calorific_value_basis === "ADB"
                  ) {
                    j.specifications.ADBitem = true;
                  } else if (
                    j.specifications.quality_calorific_value_basis === "DB"
                  ) {
                    j.specifications.DBitem = true;
                  } else if (
                    j.specifications.quality_calorific_value_basis === "DAF"
                  ) {
                    j.specifications.DAFitem = true;
                  } else if (
                    j.specifications.quality_calorific_value_basis === "NAR"
                  ) {
                    j.specifications.NARitem = true;
                  }

                  if (
                    j.specifications.quality_calorific_value2_basis === "ARB"
                  ) {
                    j.specifications.ARBitem = true;
                  } else if (
                    j.specifications.quality_calorific_value2_basis === "ADB"
                  ) {
                    j.specifications.ADBitem = true;
                  } else if (
                    j.specifications.quality_calorific_value2_basis === "DB"
                  ) {
                    j.specifications.DBitem = true;
                  } else if (
                    j.specifications.quality_calorific_value2_basis === "DAF"
                  ) {
                    j.specifications.DBitem = true;
                  } else if (
                    j.specifications.quality_calorific_value2_basis === "NAR"
                  ) {
                    j.specifications.DBitem = true;
                  }

                  if (
                    j.specifications.quality_calorific_value3_basis === "ARB"
                  ) {
                    j.specifications.ARBitem = true;
                  } else if (
                    j.specifications.quality_calorific_value3_basis === "ADB"
                  ) {
                    j.specifications.ADBitem = true;
                  } else if (
                    j.specifications.quality_calorific_value3_basis === "DB"
                  ) {
                    j.specifications.DBitem = true;
                  } else if (
                    j.specifications.quality_calorific_value3_basis === "DAF"
                  ) {
                    j.specifications.DAFitem = true;
                  } else if (
                    j.specifications.quality_calorific_value3_basis === "NAR"
                  ) {
                    j.specifications.NARitem = true;
                  }
                  //TODO: <!--------------------------- disabling the specification (ISO) DropDown Duplicate values ------------!>

                  if (
                    j.specifications.quality_calorific_value_basis_iso ===
                    "ARB"
                  ) {
                    j.specifications.Iso_ARBitem = true;
                  } else if (
                    j.specifications.quality_calorific_value_basis_iso ===
                    "ADB"
                  ) {
                    j.specifications.Iso_ADBitem = true;
                  } else if (
                    j.specifications.quality_calorific_value_basis_iso ===
                    "DB"
                  ) {
                    j.specifications.Iso_DBitem = true;
                  } else if (
                    j.specifications.quality_calorific_value_basis_iso ===
                    "DAF"
                  ) {
                    j.specifications.Iso_DAFitem = true;
                  } else if (
                    j.specifications.quality_calorific_value_basis_iso ===
                    "NAR"
                  ) {
                    j.specifications.Iso_NARitem = true;
                  }

                  if (
                    j.specifications.quality_calorific_value2_basis_iso ===
                    "ARB"
                  ) {
                    j.specifications.Iso_ARBitem = true;
                  } else if (
                    j.specifications.quality_calorific_value2_basis_iso ===
                    "ADB"
                  ) {
                    j.specifications.Iso_DBitem = true;
                  } else if (
                    j.specifications.quality_calorific_value2_basis_iso ===
                    "DB"
                  ) {
                    j.specifications.Iso_DBitem = true;
                  } else if (
                    j.specifications.quality_calorific_value2_basis_iso ===
                    "DAF"
                  ) {
                    this.setState({ Iso_DAFitem: true });
                    j.specifications.Iso_DAFitem = true;
                  } else if (
                    j.specifications.quality_calorific_value2_basis_iso ===
                    "NAR"
                  ) {
                    j.specifications.Iso_NARitem = true;
                  }

                  if (
                    j.specifications.quality_calorific_value3_basis_iso ===
                    "ARB"
                  ) {
                    j.specifications.Iso_ARBitem = true;
                  } else if (
                    j.specifications.quality_calorific_value3_basis_iso ===
                    "ADB"
                  ) {
                    j.specifications.Iso_ADBitem = true;
                  } else if (
                    j.specifications.quality_calorific_value3_basis_iso ===
                    "DB"
                  ) {
                    j.specifications.Iso_DBitem = true;
                  } else if (
                    j.specifications.quality_calorific_value3_basis_iso ===
                    "DAF"
                  ) {
                    j.specifications.Iso_DAFitem = true;
                  } else if (
                    j.specifications.quality_calorific_value3_basis_iso ===
                    "NAR"
                  ) {
                    j.specifications.Iso_NARitem = true;
                  }
                }

                //TODO: <!--------------------------- Inserting the values into LaycanData ------------!>
                var portOfDischargeData = [];
                if (
                  res.sales_contract.discharge_rate_1 ||
                  res.sales_contract.port_of_discharge_1
                ) {
                  portOfDischargeData.push({
                    discharge_rate: res.sales_contract.discharge_rate_1
                      ? res.sales_contract.discharge_rate_1
                      : "",
                    portOf_discharge: res.sales_contract.port_of_discharge_1
                      ? res.sales_contract.port_of_discharge_1
                      : "",
                  });
                }
                if (
                  res.sales_contract.discharge_rate_2 ||
                  res.sales_contract.port_of_discharge_2
                ) {
                  portOfDischargeData.push({
                    discharge_rate: res.sales_contract.discharge_rate_2
                      ? res.sales_contract.discharge_rate_2
                      : "",
                    portOf_discharge: res.sales_contract.port_of_discharge_2
                      ? res.sales_contract.port_of_discharge_2
                      : "",
                  });
                }
                if (
                  res.sales_contract.discharge_rate_3 ||
                  res.sales_contract.port_of_discharge_3
                ) {
                  portOfDischargeData.push({
                    discharge_rate: res.sales_contract.discharge_rate_3
                      ? res.sales_contract.discharge_rate_3
                      : "",
                    portOf_discharge: res.sales_contract.port_of_discharge_3
                      ? res.sales_contract.port_of_discharge_3
                      : "",
                  });
                }

                if (portOfDischargeData.length <= 0) {
                  portOfDischargeData.push({
                    discharge_rate: "",
                    portOf_discharge: "",
                  });
                }

                //TODO: <!--------------------------- Inserting the values into LaycanData ------------!>
                const LaycanDataRows = [];
                if (
                  res.sales_contract.laycan_1 ||
                  res.sales_contract.laycan_quantity_1
                ) {
                  LaycanDataRows.push({
                    s_no: "1",
                    laycan: res.sales_contract.laycan_1,
                    laycan_quantity: res.sales_contract.laycan_quantity_1,
                  });
                }
                if (
                  res.sales_contract.laycan_2 ||
                  res.sales_contract.laycan_quantity_2
                ) {
                  LaycanDataRows.push({
                    s_no: "2",
                    laycan: res.sales_contract.laycan_2,
                    laycan_quantity: res.sales_contract.laycan_quantity_2,
                  });
                }
                if (
                  res.sales_contract.laycan_3 ||
                  res.sales_contract.laycan_quantity_3
                ) {
                  LaycanDataRows.push({
                    s_no: "3",
                    laycan: res.sales_contract.laycan_3,
                    laycan_quantity: res.sales_contract.laycan_quantity_3,
                  });
                }
                if (
                  res.sales_contract.laycan_4 ||
                  res.sales_contract.laycan_quantity_4
                ) {
                  LaycanDataRows.push({
                    s_no: "4",
                    laycan: res.sales_contract.laycan_4,
                    laycan_quantity: res.sales_contract.laycan_quantity_4,
                  });
                }
                if (
                  res.sales_contract.laycan_5 ||
                  res.sales_contract.laycan_quantity_5
                ) {
                  LaycanDataRows.push({
                    s_no: "5",
                    laycan: res.sales_contract.laycan_5,
                    laycan_quantity: res.sales_contract.laycan_quantity_5,
                  });
                }
                // TODO :<!----------------- Filtering the supplier / SAII / Joint Surveyor Name -----------!>
                var filteredSaiiSurveyorName =
                  res.sales_contract.internal_surveyor_names.split(",");
                // if (res.sales_contract.internal_surveyor) {
                //   var resInternalsurveyorData = res.sales_contract.internal_surveyor;
                //   let result = resInternalsurveyorData.split(',');
                //   for (let i = 0; i < this.state.surveyorsData.length; i++) {
                //     for (let j = 0; j < result.length; j++) {
                //       if (result[j] === this.state.surveyorsData[i].value) {
                //         filteredSaiiSurveyorName.push(this.state.surveyorsData[i].name)
                //       }
                //     }
                //   }
                // }
                var filteredSuppSurveyorName =
                  res.sales_contract.supplier_surveyor_names.split(",");
                // if (res.sales_contract.supplier_surveyor) {
                //   var resSupplierSurveyorData = res.sales_contract.supplier_surveyor;
                //   let result = resSupplierSurveyorData.split(',');
                //   for (let i = 0; i < this.state.surveyorsData.length; i++) {
                //     for (let j = 0; j < result.length; j++) {
                //       if (result[j] === this.state.surveyorsData[i].value) {
                //         filteredSuppSurveyorName.push(this.state.surveyorsData[i].name)
                //       }
                //     }
                //   }
                // }
                var filteredJointSurveyorName =
                  res.sales_contract.joint_surveyor_names.split(",");
                // if (res.sales_contract.joint_surveyor !== null) {
                //   var resjointSurveyorData = res.sales_contract.joint_surveyor;
                //   let result = resjointSurveyorData.split(',');
                //   for (let i = 0; i < this.state.surveyorsData.length; i++) {
                //     for (let j = 0; j < result.length; j++) {
                //       if (result[j] === this.state.surveyorsData[i].value) {
                //         filteredJointSurveyorName.push(this.state.surveyorsData[i].name)
                //       }
                //     }
                //   }
                // }
                // TODO :<!----------------- Filtering the supplier / SAII / Joint Surveyor Analysis Types  -----------!>
                if (res.sales_contract.internal_surveyor_analysis_type) {
                  var value1 =
                    res.sales_contract.internal_surveyor_analysis_type;
                  var data1 = value1.split(",");
                } else {
                  data1 = [];
                }
                if (res.sales_contract.supplier_surveyor_analysis_type) {
                  var value2 =
                    res.sales_contract.supplier_surveyor_analysis_type;
                  var data2 = value2.split(",");
                } else {
                  data2 = [];
                }
                if (res.sales_contract.joint_surveyor_analysis_type) {
                  var value3 =
                    res.sales_contract.joint_surveyor_analysis_type;
                  var data3 = value3.split(",");
                } else {
                  data3 = [];
                }
                for (var k in res.sales_contract.qualities) {
                  res.sales_contract.qualities[k].quantity_adjusted =
                    res.sales_contract.qualities[k].quantity_in_mt;
                  res.sales_contract.qualities[k].tolerance_adjusted =
                    res.sales_contract.qualities[k].tolerance;
                  for (
                    let n = 0;
                    n < res.sales_contract.qualities[k].sales_types.length;
                    n++
                  ) {
                    if (
                      res.sales_contract.qualities[k].sales_types[n]
                        .sales_type === "CFR DP" ||
                      res.sales_contract.qualities[k].sales_types[n]
                        .sales_type === "CIF DP"
                    ) {
                      this.setState({ dischargeRateField: true });
                    }
                  }
                }
                this.setState((prevState) => ({
                  ...prevState,
                  filteredCustomerData: {
                    name: res.sales_contract.customer_name,
                    value: res.sales_contract.customer,
                  },
                  portOfDischargeRows: portOfDischargeData,
                  buyer_ref_no: res.sales_contract.buyer_ref_no
                    ? res.sales_contract.buyer_ref_no
                    : "",
                  contract_date: res.sales_contract.contract_date
                    ? res.sales_contract.contract_date
                    : null,
                  supplierSurveyorName: filteredSuppSurveyorName,
                  supplierSurveyorAnalysisType: data2,
                  saiiSurveyorName: filteredSaiiSurveyorName,
                  saiiSurveyorAnalysisType: data1,
                  buyerSurveyorName: filteredJointSurveyorName,
                  buyerSurveyorAnalysisType: data3,
                  //salesTypeRows : res.sales_contract.qualities,
                  salesTypeRows: specData,

                  LaycanData: LaycanDataRows,
                  ...res.sales_contract,
                  isLoading: false

                }));
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            }
          });
        } else if (response.status === 607) {
          window.location.href = "/logout";
        } else if (response.status === 629) {
          response.json().then((res) => {
            if (res) {
              this.setState({
                errorMessage: res.message,
                successMessage: "",
              });
            }
          });
        }
      });
  }

  handleAddCurrency = () => {
    const idToken = this.Cookie.getIdTokenCookie();
    const loginUserID = this.Cookie.getCookie("loginUserId");
    var newCurrency = this.state.newCurrency;
    var clickedCurrency = this.state.clickedCurrency;
    const status = "Active";
    if (newCurrency === "") {
      alert("Please enter Currency");
      this.setState({ modalCurrencyError: true });
      return;
    }
    api
      .addCurrency(loginUserID, idToken, newCurrency, status)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState({
                successMessage: res.message,
                errorMessage: "",
                currencyError: false,
              });
              window.$("#myModal").modal("hide");
              if (res.id) {
                api.getCurrencies(loginUserID, idToken).then((response) => {
                  if (response.status >= 200 && response.status < 300) {
                    response.json().then((res) => {
                      if (res.idtoken)
                        this.Cookie.setIdTokenCookie(res.idtoken);
                      if (res) {
                        var currencies = [
                          <MenuItem value="0" key={-1} disabled>
                            Please Select
                          </MenuItem>,
                        ];
                        for (var i in res.Currency) {
                          currencies.push(
                            <MenuItem
                              value={res.Currency[i].currency}
                              key={[i]}
                            >
                              {res.Currency[i].currency}
                            </MenuItem>
                          );
                        }
                        this.setState({
                          currencyData: currencies,
                        });
                        if (clickedCurrency === "Currency") {
                          this.setState({
                            currency: newCurrency.toUpperCase(),
                            newCurrency: "",
                          });
                        }
                      }
                    });
                  } else if (response.status === 607) {
                    window.location.href = "/logout";
                  }
                });
              }
            }
            if (res.code === "601") {
              this.setState({ errorMessage: res.message, successMessage: "" });
            }
            if (res.code === "602") {
              alert(res.message);
              this.setState({
                errorMessage: res.message,
                successMessage: "",
                modalCurrencyError: true,
              });
            }
            if (res.code === "607") {
              window.location.href = "/logout";
            }
            if (res.code === "624") {
              this.setState({
                modalCurrencyError: true,
              });
            }
          });
        } else {
          alert("Unexpected error occured. Please contact administrator.");
        }
      });
  };

  handleDecimalField = (e) => {
    if (e.target.name === "stevedore_price") {
      const re = /^\d*(\.\d{0,4})?$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        this.setState({
          [e.target.name]: e.target.value,
        });
      }
    }
    if (e.target.name === "pnbp") {
      const re = /^\d*(\.\d{0,4})?$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        this.setState({
          [e.target.name]: e.target.value,
        });
      }
    }
  };

  handleText = (e) => {
    if (e.target.name === "currency") {
      this.setState({
        [e.target.name]: e.target.value,
        currencyError: false,
      });
    } else if (e.target.name === "newCurrency") {
      this.setState({
        [e.target.name]: e.target.value,
        modalCurrencyError: false,
      });
    }
    if (e.target.name === "pod") {
      this.setState({
        [e.target.name]: e.target.value,
        PodError: false,
      });
    }
    if (e.target.name === "vessel_type") {
      this.setState({
        [e.target.name]: e.target.value,
        vesselTypeError: false,
      });
    }
    if (e.target.name === "loading_rate_for_geared") {
      this.setState({
        [e.target.name]: e.target.value,
        loadingRateError: false,
        loadingRateforGearedError: false,
      });
    }
    if (e.target.name === "loading_rate_for_gearless") {
      this.setState({
        [e.target.name]: e.target.value,
        loadingRateError: false,
        loadingRateforGearLessError: false,
      });
    }

    this.setState({
      [e.target.name]: e.target.value,
      modalCurrencyError: false,
      gearlessError: false,
      gearedError: false,
    });
  };

  laycanTextHandler = (idx) => (e) => {
    var name = e.target.name;
    var rows = this.state.LaycanData;
    if (name === "laycan") {
      rows[idx].laycan = e.target.value;
      this.setState({ laycan1Error: false });
    }
    if (name === "laycan_quantity") {
      const re = /^\d*(\.\d{0,3})?$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        rows[idx].laycan_quantity = e.target.value;
      }
    }
    this.setState({
      LaycanData: rows,
    });
  };

  handleRemoveLaycanRows = (idx) => () => {
    const rows = this.state.LaycanData;
    rows.splice(idx, 1);
    this.setState({
      LaycanData: rows,
    });
  };

  handleLaycanRows = () => {
    const items = {
      s_no: JSON.stringify(this.state.LaycanData + 1),
      laycan: "",
      laycan_quantity: "",
    };
    this.setState({
      LaycanData: [...this.state.LaycanData, items],
    });
  };

  MultiSelectHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  AddportOfDischargeRowsHandler = () => {
    const item = { discharge_rate: "", portOf_discharge: "" };
    this.setState({
      portOfDischargeRows: [...this.state.portOfDischargeRows, item],
    });
  };

  removePortOfDischargeRows = (idx) => () => {
    const rows = this.state.portOfDischargeRows;
    rows.splice(idx, 1);

    this.setState({
      portOfDischargeRows: rows,
    });
  };

  portOfDischargeRowsTextHandler = (idx) => (e) => {
    const rows = this.state.portOfDischargeRows;
    if (e.target.name === "dischargeRate") {
      rows[idx].discharge_rate = e.target.value;
    }
    if (e.target.name === "portOfDischarge") {
      rows[idx].portOf_discharge = e.target.value;
    }

    this.setState({
      portOfDischargeRows: rows,
    });
  };

  contractDateHandler = (date) => {
    this.setState({
      contract_date: date,
      contractDateError: false,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      isLoading: true,
    });
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    var {
      customer,
      old_contract_no,
      buyer_ref_no,
      contract_date,
      salesTypeRows,
      stevedore_price,
      pnbp,
      currency,
      port_of_loading,
      vessel_type,
      payment_adjustment,
      payment_terms_1,
      payment_terms_2,
      remarks,
      share_point_doc_link,
      loading_rate_for_geared,
      loading_rate_for_gearless,
      is_index_linked,
      files,
      shipping_terms,
    } = this.state;

    var supplierSurveyorNames = "";
    for (let i = 0; i < this.state.supplierSurveyorName.length; i++) {
      for (let j = 0; j < this.state.surveyorsData.length; j++) {
        if (
          this.state.supplierSurveyorName[i] ===
          this.state.surveyorsData[j].name
        ) {
          supplierSurveyorNames += this.state.surveyorsData[j].value + ",";
        }
      }
    }
    var supplierSurveyorName = supplierSurveyorNames.slice(0, -1);
    var supplierSurveyorAnalysisTypes = "";
    for (let i = 0; i < this.state.supplierSurveyorAnalysisType.length; i++) {
      supplierSurveyorAnalysisTypes +=
        this.state.supplierSurveyorAnalysisType[i] + ",";
    }
    var supplierSurveyorAnalysisType = supplierSurveyorAnalysisTypes.slice(
      0,
      -1
    );
    var saiiSurveyorNames = "";
    for (let i = 0; i < this.state.saiiSurveyorName.length; i++) {
      for (let j = 0; j < this.state.surveyorsData.length; j++) {
        if (
          this.state.saiiSurveyorName[i] === this.state.surveyorsData[j].name
        ) {
          saiiSurveyorNames += this.state.surveyorsData[j].value + ",";
        }
      }
    }
    var saiiSurveyorName = saiiSurveyorNames.slice(0, -1);
    var saiiSurveyorAnalysisTypes = "";
    for (let i = 0; i < this.state.saiiSurveyorAnalysisType.length; i++) {
      saiiSurveyorAnalysisTypes += this.state.saiiSurveyorAnalysisType[i] + ",";
    }
    var saiiSurveyorAnalysisType = saiiSurveyorAnalysisTypes.slice(0, -1);
    var jointSurveyorNames = "";
    for (let i = 0; i < this.state.buyerSurveyorName.length; i++) {
      for (let j = 0; j < this.state.surveyorsData.length; j++) {
        if (
          this.state.buyerSurveyorName[i] === this.state.surveyorsData[j].name
        ) {
          jointSurveyorNames += this.state.surveyorsData[j].value + ",";
        }
      }
    }
    var buyerSurveyorName = jointSurveyorNames.slice(0, -1);
    var jointSurveyorAnysTypes = "";
    for (let i = 0; i < this.state.buyerSurveyorAnalysisType.length; i++) {
      jointSurveyorAnysTypes += this.state.buyerSurveyorAnalysisType[i] + ",";
    }
    var buyerSurveyorAnalysisType = jointSurveyorAnysTypes.slice(0, -1);
    //TODO:<!-------------------- Accessing the laycanData --------------------!>
    var laycan1 = "",
      laycan2 = "",
      laycan3 = "",
      laycan4 = "",
      laycan5 = "";
    var laycanQuantity1 = "",
      laycanQuantity2 = "",
      laycanQuantity3 = "",
      laycanQuantity4 = "",
      laycanQuantity5 = "";
    for (var k in this.state.LaycanData) {
      if (k === "0") {
        laycan1 = this.state.LaycanData[k].laycan;
        laycanQuantity1 = this.state.LaycanData[k].laycan_quantity;
      }
      if (k === "1") {
        laycan2 = this.state.LaycanData[k].laycan;
        laycanQuantity2 = this.state.LaycanData[k].laycan_quantity;
      }
      if (k === "2") {
        laycan3 = this.state.LaycanData[k].laycan;
        laycanQuantity3 = this.state.LaycanData[k].laycan_quantity;
      }
      if (k === "3") {
        laycan4 = this.state.LaycanData[k].laycan;
        laycanQuantity4 = this.state.LaycanData[k].laycan_quantity;
      }
      if (k === "4") {
        laycan5 = this.state.LaycanData[k].laycan;
        laycanQuantity5 = this.state.LaycanData[k].laycan_quantity;
      }
    }

    //TODO::<!----------------- Accessing the portOfDischargeRows Data ------!>

    var dischargeRate1 = "",
      portOfDischarge1 = "",
      dischargeRate2 = "",
      portOfDischarge2 = "",
      dischargeRate3 = "",
      portOfDischarge3 = "";
    for (var j in this.state.portOfDischargeRows) {
      if (j === "0") {
        dischargeRate1 = this.state.portOfDischargeRows[j].discharge_rate;
        portOfDischarge1 = this.state.portOfDischargeRows[j].portOf_discharge;
      }
      if (j === "1") {
        dischargeRate2 = this.state.portOfDischargeRows[j].discharge_rate;
        portOfDischarge2 = this.state.portOfDischargeRows[j].portOf_discharge;
      }
      if (j === "2") {
        dischargeRate3 = this.state.portOfDischargeRows[j].discharge_rate;
        portOfDischarge3 = this.state.portOfDischargeRows[j].portOf_discharge;
      }
    }

    var srows = this.state.salesTypeRows;
    for (var i in this.state.salesTypeRows) {
      // TODO: <!------------------- if sales Term as Specs sending empty values ---------- !>
      if (
        srows[i].sales_term === "Specs" ||
        srows[i].sales_term === "Non Specs"
      ) {
        if (srows[i].specifications.quality_calorific_value_basis === "0") {
          srows[i].specifications.quality_calorific_value_basis = "";
        }
        if (srows[i].specifications.quality_calorific_value2_basis === "0") {
          srows[i].specifications.quality_calorific_value2_basis = "";
        }
        if (srows[i].specifications.quality_calorific_value3_basis === "0") {
          srows[i].specifications.quality_calorific_value3_basis = "";
        }
        if (srows[i].specifications.quality_total_moisture_basis === "0") {
          srows[i].specifications.quality_total_moisture_basis = "";
        }
        if (srows[i].specifications.quality_inherent_moisture_basis === "0") {
          srows[i].specifications.quality_inherent_moisture_basis = "";
        }
        if (srows[i].specifications.quality_ash_basis === "0") {
          srows[i].specifications.quality_ash_basis = "";
        }
        if (srows[i].specifications.quality_volatile_matter_basis === "0") {
          srows[i].specifications.quality_volatile_matter_basis = "";
        }
        if (srows[i].specifications.quality_fixed_carbon_basis === "0") {
          srows[i].specifications.quality_fixed_carbon_basis = "";
        }
        if (srows[i].specifications.quality_fuel_ratio_basis === "0") {
          srows[i].specifications.quality_fuel_ratio_basis = "";
        }
        if (srows[i].specifications.quality_total_sulphur_basis === "0") {
          srows[i].specifications.quality_total_sulphur_basis = "";
        }
        if (srows[i].specifications.ultimate_carbon_basis === "0") {
          srows[i].specifications.ultimate_carbon_basis = "";
        }
        if (srows[i].specifications.ultimate_hydrogen_basis === "0") {
          srows[i].specifications.ultimate_hydrogen_basis = "";
        }
        if (srows[i].specifications.ultimate_nitrogen_basis === "0") {
          srows[i].specifications.ultimate_nitrogen_basis = "";
        }
        if (srows[i].specifications.ash_sio2_basis === "0") {
          srows[i].specifications.ash_sio2_basis = "";
        }
        if (srows[i].specifications.ash_ai203_basis === "0") {
          srows[i].specifications.ash_ai203_basis = "";
        }
        if (srows[i].specifications.ash_sio2_basis === "0") {
          srows[i].specifications.ash_sio2_basis = "";
        }
        if (srows[i].specifications.ash_fe203_basis === "0") {
          srows[i].specifications.ash_fe203_basis = "";
        }
        if (srows[i].specifications.ash_cao_basis === "0") {
          srows[i].specifications.ash_cao_basis = "";
        }
        if (srows[i].specifications.ash_mgo_basis === "0") {
          srows[i].specifications.ash_mgo_basis = "";
        }
        if (srows[i].specifications.ash_na2o_basis === "0") {
          srows[i].specifications.ash_na2o_basis = "";
        }
        if (srows[i].specifications.ash_k2o_basis === "0") {
          srows[i].specifications.ash_k2o_basis = "";
        }
        if (srows[i].specifications.ash_tio2_basis === "0") {
          srows[i].specifications.ash_tio2_basis = "";
        }
        if (srows[i].specifications.ash_mn3o4_basis === "0") {
          srows[i].specifications.ash_mn3o4_basis = "";
        }
        if (srows[i].specifications.ash_so3_basis === "0") {
          srows[i].specifications.ash_so3_basis = "";
        }
        if (srows[i].specifications.ash_p2o5_basis === "0") {
          srows[i].specifications.ash_p2o5_basis = "";
        }
        if (srows[i].specifications.trace_arsenic_basis === "0") {
          srows[i].specifications.trace_arsenic_basis = "";
        }
        if (srows[i].specifications.trace_mercury_basis === "0") {
          srows[i].specifications.trace_mercury_basis = "";
        }
        if (srows[i].specifications.trace_selenium_basis === "0") {
          srows[i].specifications.trace_selenium_basis = "";
        }
        if (srows[i].specifications.trace_boron_basis === "0") {
          srows[i].specifications.trace_boron_basis = "";
        }
        if (srows[i].specifications.minor_ba_basis === "0") {
          srows[i].specifications.minor_ba_basis = "";
        }
        if (srows[i].specifications.minor_phosphorous_basis === "0") {
          srows[i].specifications.minor_phosphorous_basis = "";
        }
        if (srows[i].specifications.quality_calorific_value_basis_iso === "0") {
          srows[i].specifications.quality_calorific_value_basis_iso = "";
        }
        if (
          srows[i].specifications.quality_calorific_value2_basis_iso === "0"
        ) {
          srows[i].specifications.quality_calorific_value2_basis_iso = "";
        }
        if (
          srows[i].specifications.quality_calorific_value3_basis_iso === "0"
        ) {
          srows[i].specifications.quality_calorific_value3_basis_iso = "";
        }
        if (srows[i].specifications.quality_total_moisture_basis_iso === "0") {
          srows[i].specifications.quality_total_moisture_basis_iso = "";
        }
        if (
          srows[i].specifications.quality_inherent_moisture_basis_iso === "0"
        ) {
          srows[i].specifications.quality_inherent_moisture_basis_iso = "";
        }
        if (srows[i].specifications.quality_ash_basis_iso === "0") {
          srows[i].specifications.quality_ash_basis_iso = "";
        }
        if (srows[i].specifications.quality_volatile_matter_basis_iso === "0") {
          srows[i].specifications.quality_volatile_matter_basis_iso = "";
        }
        if (srows[i].specifications.quality_fixed_carbon_basis_iso === "0") {
          srows[i].specifications.quality_fixed_carbon_basis_iso = "";
        }
        if (srows[i].specifications.quality_fuel_ratio_basis_iso === "0") {
          srows[i].specifications.quality_fuel_ratio_basis_iso = "";
        }
        if (srows[i].specifications.quality_total_sulphur_basis_iso === "0") {
          srows[i].specifications.quality_total_sulphur_basis_iso = "";
        }
        if (srows[i].specifications.ultimate_carbon_basis_iso === "0") {
          srows[i].specifications.ultimate_carbon_basis_iso = "";
        }
        if (srows[i].specifications.ultimate_hydrogen_basis_iso === "0") {
          srows[i].specifications.ultimate_hydrogen_basis_iso = "";
        }
        if (srows[i].specifications.ultimate_nitrogen_basis_iso === "0") {
          srows[i].specifications.ultimate_nitrogen_basis_iso = "";
        }
        if (srows[i].specifications.ash_sio2_basis_iso === "0") {
          srows[i].specifications.ash_sio2_basis_iso = "";
        }
        if (srows[i].specifications.ash_ai203_basis_iso === "0") {
          srows[i].specifications.ash_ai203_basis_iso = "";
        }
        if (srows[i].specifications.ash_sio2_basis_iso === "0") {
          srows[i].specifications.ash_sio2_basis_iso = "";
        }
        if (srows[i].specifications.ash_fe203_basis_iso === "0") {
          srows[i].specifications.ash_fe203_basis_iso = "";
        }
        if (srows[i].specifications.ash_cao_basis_iso === "0") {
          srows[i].specifications.ash_cao_basis_iso = "";
        }
        if (srows[i].specifications.ash_mgo_basis_iso === "0") {
          srows[i].specifications.ash_mgo_basis_iso = "";
        }
        if (srows[i].specifications.ash_na2o_basis_iso === "0") {
          srows[i].specifications.ash_na2o_basis_iso = "";
        }
        if (srows[i].specifications.ash_k2o_basis_iso === "0") {
          srows[i].specifications.ash_k2o_basis_iso = "";
        }
        if (srows[i].specifications.ash_tio2_basis_iso === "0") {
          srows[i].specifications.ash_tio2_basis_iso = "";
        }
        if (srows[i].specifications.ash_mn3o4_basis_iso === "0") {
          srows[i].specifications.ash_mn3o4_basis_iso = "";
        }
        if (srows[i].specifications.ash_so3_basis_iso === "0") {
          srows[i].specifications.ash_so3_basis_iso = "";
        }
        if (srows[i].specifications.ash_p2o5_basis_iso === "0") {
          srows[i].specifications.ash_p2o5_basis_iso = "";
        }
        if (srows[i].specifications.trace_arsenic_basis_iso === "0") {
          srows[i].specifications.trace_arsenic_basis_iso = "";
        }
        if (srows[i].specifications.trace_mercury_basis_iso === "0") {
          srows[i].specifications.trace_mercury_basis_iso = "";
        }
        if (srows[i].specifications.trace_selenium_basis_iso === "0") {
          srows[i].specifications.trace_selenium_basis_iso = "";
        }
        if (srows[i].specifications.trace_boron_basis_iso === "0") {
          srows[i].specifications.trace_boron_basis_iso = "";
        }
        if (srows[i].specifications.minor_ba_basis_iso === "0") {
          srows[i].specifications.minor_ba_basis_iso = "";
        }
        if (srows[i].specifications.minor_phosphorous_basis_iso === "0") {
          srows[i].specifications.minor_phosphorous_basis_iso = "";
        }
      }
    }
    this.setState({ salesTypeRows: srows });
    const content_files = files.filter((e) => e.file_content !== "");
    // }
    var status = "Active";
    if (customer !== "" && contract_date !== null) {
      const contractType = "Addendum";
      const baseContractID = this.state.scid;
      const baseContractNo = this.state.contract_no;
      const addendumContractID = this.state.scid;
      const addendumContractNo = this.state.contract_no;
      const base_currency = this.state.base_currency
      //Add sales contract service
      api
        .addSalesContract(
          loginUserID,
          idToken,
          customer,
          old_contract_no,
          buyer_ref_no,
          contract_date,
          salesTypeRows,
          stevedore_price,
          pnbp,
          currency,
          laycan1,
          laycan2,
          laycan3,
          laycan4,
          laycan5,
          laycanQuantity1,
          laycanQuantity2,
          laycanQuantity3,
          laycanQuantity4,
          laycanQuantity5,
          supplierSurveyorName,
          supplierSurveyorAnalysisType,
          saiiSurveyorName,
          saiiSurveyorAnalysisType,
          buyerSurveyorName,
          buyerSurveyorAnalysisType,
          port_of_loading,
          dischargeRate1,
          portOfDischarge1,
          dischargeRate2,
          portOfDischarge2,
          dischargeRate3,
          portOfDischarge3,
          loading_rate_for_geared,
          loading_rate_for_gearless,
          payment_adjustment,
          payment_terms_1,
          payment_terms_2,
          remarks,
          vessel_type,
          share_point_doc_link,
          contractType,
          baseContractID,
          baseContractNo,
          base_currency,
          addendumContractID,
          addendumContractNo,
          status,
          is_index_linked,
          content_files,
          shipping_terms
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
              if (res) {
                if (res.code === "200") {
                  this.setState({
                    successMessage: res.message,
                    errorMessage: "",
                  });
                  if (res.sale_contract_id) {
                    window.location.href =
                      "/sales-contract-view-addendum/" + btoa(res.sale_contract_id);
                  } else {
                    alert(
                      "Unexpected error occured. Please contact administrator."
                    );
                  }
                }
                if (res.code === "601") {
                  this.setState({
                    isLoading: false,
                    successMessage: "",
                    errorMessage: res.message,
                  });
                }
                if (res.code === "624") {
                  this.setState({ isLoading: false });
                  for (var i = 0; i < res.columns.length; i++) {
                    if (res.columns[i] === "customer_id") {
                      this.setState({ customerError: true });
                    }
                    if (res.columns[i] === "currency") {
                      this.setState({ currencyError: true });
                    }
                  }
                  this.setState({
                    errorMessage: res.message,
                    successMessage: "",
                  });
                  alert(res.message);
                  return;
                }
              } else {
                this.setState({ isLoading: false });
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            });
          }
        });
    } else {
      this.setState({ isLoading: false });
      alert("Please fill all mandatory fields.");
    }
  };

  getVendorvalue = (id) => {
    var vendorname = this.state.customersDataList.filter(
      (e, index) => e.value === this.state.customer
    );
    return vendorname.length > 0 ? vendorname[0].name : null;
  };

  getMinevalue = (id) => {
    var mineName = this.state.vendorTypeAsCoalData.filter(
      (e, index) => e.value === id
    );
    var mine_name = "";
    if (mineName.length > 0) {
      mine_name = mineName[0].mine_name;
    }
    return mine_name;
  };

  toLocaleString(value) {
    return Number(value).toLocaleString();
  }

  previousStepHandler = () => {
    this.setState({
      activeStep: this.state.activeStep - 1,
    });
  };

  nextStepHandler = () => {
    if (this.state.activeStep === 0) {
      var { customer, contract_date, currency } = this.state;
      if (customer === "") {
        this.setState({ customerError: true });
      }
      if (contract_date === null) {
        this.setState({ contractDateError: true });
      }
      if (currency === "0") {
        this.setState({ currencyError: true });
      }
      var laycan1 = "";
      for (var i in this.state.LaycanData) {
        if (i === "0") {
          laycan1 = this.state.LaycanData[i].laycan;
        }
      }
      if (laycan1 === "") {
        this.setState({ isLoading: false, laycan1Error: true });
      }

      if (
        customer === "" ||
        contract_date == null ||
        currency === "0" ||
        laycan1 === ""
      ) {
        this.setState({
          snackBarErrorOpen: true,
          errorMsg: "Please fill mandatory fields.",
        });
        return;
      }
    }

    if (this.state.activeStep === 1) {
      var { salesTypeRows } = this.state;
      var rows = salesTypeRows;
      var purchase_typesAvailable = 0;
      for (var j in salesTypeRows) {
        if (salesTypeRows[j].sales_term === "Specs") {
          if (salesTypeRows[j].quality === "") {
            rows[j].qualityError = true;
            purchase_typesAvailable = purchase_typesAvailable + 1;
          } else {
            var quality = salesTypeRows[j].quality;
            salesTypeRows[j].quality = quality.toUpperCase();
          }
        }
        if (salesTypeRows[j].quantity_in_mt === "") {
          rows[j].quantity_in_mtError = true;
          purchase_typesAvailable = purchase_typesAvailable + 1;
        }
        if (salesTypeRows[j].tolerance === "") {
          rows[j].toleranceError = true;
          purchase_typesAvailable = purchase_typesAvailable + 1;
        }

        for (var k in salesTypeRows[j].sales_types) {
          if (salesTypeRows[j].sales_types[k].sales_type === "0") {
            rows[j].sales_types[k].sales_typeError = true;
            this.setState({ isLoading: false, salesTypeRows: rows });
            purchase_typesAvailable = purchase_typesAvailable + 1;
          }
          if (salesTypeRows[j].sales_types[k].price_pmt === "") {
            rows[j].sales_types[k].price_pmtError = true;
            this.setState({ isLoading: false, salesTypeRows: rows });
            purchase_typesAvailable = purchase_typesAvailable + 1;
          }
        }
      }
      this.setState({ salesTypeRows: rows });
      if (purchase_typesAvailable > 0) {
        this.setState({
          snackBarErrorOpen: true,
          errorMsg: "Please fill all mandatory fields.",
        });
        return;
      }

      let purchaseQualityRows = this.state.salesTypeRows;
      for (var p = 0; p < purchaseQualityRows.length; p++) {
        if (purchaseQualityRows[p].sales_term === "0") {
          purchaseQualityRows[p].purchasingTermError = true;
          this.setState({
            isLoading: false,
            salesTypeRows: purchaseQualityRows,
            snackBarErrorOpen: true,
            errorMsg: "Please select purchasing term(Specs or Non Specs).",
          });
          return;
        } else {
          if (purchaseQualityRows[p].sales_term === "Specs") {
            if (purchaseQualityRows[p].specification_standard === "0") {
              purchaseQualityRows[p].specification_standardError = true;
              this.setState({
                isLoading: false,
                salesTypeRows: purchaseQualityRows,
                snackBarErrorOpen: true,
                errorMsg: "Please select specification standard(ASTM or ISO).",
              });
              return;
            }
          } else {
            this.setState({
              dialogText: "Please fill all mandatory fields.",
            });
          }
        }
      }
    }

    if (this.state.activeStep === 2) {
      var { vessel_type, loading_rate_for_geared, loading_rate_for_gearless } =
        this.state;

      if (vessel_type === "0") {
        this.setState({ isLoading: false, vesselTypeError: true, open: true });
        return;
      }
      if (vessel_type === "Geared & Grabbed") {
        if (loading_rate_for_geared === "") {
          this.setState({
            isLoading: false,
            open: true,
            loadingRateforGearedError: true,
            loadingRateError: false,
          });
          return;
        }
      }
      if (vessel_type === "Gearless") {
        if (loading_rate_for_gearless === "") {
          this.setState({
            isLoading: false,
            loadingRateforGearLessError: true,
            open: true,
            loadingRateError: false,
          });
          return;
        }
      }
    }

    if (this.state.activeStep === 3) {
      /**
       *  Checking Mandatory files
       */
      var missing_fields = 0;
      let files_missing = 0;
      var row = this.state.files;
      for (var obj of row) {
        if (obj.file_type !== "" || obj.file_content !== "") {
          if (obj.file_content !== "") {
            if (obj.file_description === "") {
              missing_fields++;
              obj.file_description_error = true;
            } else {
              obj.file_description = obj.file_description.toUpperCase();
            }
          } else if (obj.file_description !== "") {
            if (obj.file_content === "") {
              files_missing++;
            }
          }
        }
      }
      this.setState({
        files: row,
      });

      if (missing_fields > 0) {
        this.setState({
          snackBarErrorOpen: true,
          errorMsg: "Please fill mandatory fields.",
        });
        return;
      }
      if (files_missing > 0) {
        this.setState({
          snackBarErrorOpen: true,
          errorMsg: "Please Select file.",
        });
        return;
      }
    }

    this.setState({
      activeStep: this.state.activeStep + 1,
    });
  };

  render() {
    try {
      var salesContractId = decodeURIComponent(
        window.atob(this.props.match.params.salesContractID)
      );
    } catch (e) {
      window.location.href = "/sales-contract-list";
    }
    return (
      <div id="wraper">
        <SideBar />
        <div className="content">
          <Header />
          <div className="content-header">
            <div className="col-sm text-left pl-0">
              <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                <a href={"/view-sales-contract/" + btoa(salesContractId)}>
                  <i className="fa fa-arrow-left left_arrow_adjst" />
                </a>
                Coal Sales Contract Addendum - {this.state.contract_no}
              </h4>
            </div>
          </div>
          {this.state.isLoading && <Loader />}
          {!this.state.isLoading && (
            <form className="container p-3">
              {this.state.errorMessage && (
                <div className="row">
                  <div className="col text-center">
                    <p className="error-class">{this.state.errorMessage}</p>
                  </div>
                </div>
              )}
              {this.state.successMessage && (
                <div className="row">
                  <div className="col text-center">
                    <p className="success-class">{this.state.successMessage}</p>
                  </div>
                </div>
              )}
              <div className="row mb-0 stepper">
                <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                  <i
                    className={
                      this.state.activeStep > 0
                        ? "fa fa-check-circle"
                        : "fa fa-check-circle-o"
                    }
                    aria-hidden="true"
                    style={{
                      fontSize: 20,
                      color:
                        this.state.activeStep > 0
                          ? "#31c197"
                          : this.state.activeStep === 0
                            ? config.themeColor
                            : "#ccc",
                    }}
                  ></i>
                  <span
                    className="stepper_text"
                    style={{
                      color:
                        this.state.activeStep > 0
                          ? "#31c197"
                          : this.state.activeStep === 0
                            ? config.themeColor
                            : "#ccc",
                    }}
                  >
                    Contract Details
                  </span>
                  <i
                    className="fa fa-chevron-right stepper_sub_icon stepper_arrow"
                    aria-hidden="true"
                    style={{
                      color:
                        this.state.activeStep > 0
                          ? "#31c197"
                          : this.state.activeStep === 0
                            ? config.themeColor
                            : "#ccc",
                    }}
                  ></i>
                </span>
                <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                  <i
                    className={
                      this.state.activeStep > 1
                        ? "fa fa-check-circle"
                        : "fa fa-check-circle-o"
                    }
                    aria-hidden="true"
                    style={{
                      fontSize: 20,
                      color:
                        this.state.activeStep > 1
                          ? "#31c197"
                          : this.state.activeStep === 1
                            ? config.themeColor
                            : "#ccc",
                    }}
                  ></i>
                  <span
                    className="stepper_text"
                    style={{
                      color:
                        this.state.activeStep > 1
                          ? "#31c197"
                          : this.state.activeStep === 1
                            ? config.themeColor
                            : "#ccc",
                    }}
                  >
                    Add Quality
                  </span>
                  <i
                    className="fa fa-chevron-right stepper_sub_icon stepper_arrow"
                    aria-hidden="true"
                    style={{
                      color:
                        this.state.activeStep > 1
                          ? "#31c197"
                          : this.state.activeStep === 1
                            ? config.themeColor
                            : "#ccc",
                    }}
                  ></i>
                </span>
                <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                  <i
                    className={
                      this.state.activeStep > 2
                        ? "fa fa-check-circle"
                        : "fa fa-check-circle-o"
                    }
                    aria-hidden="true"
                    style={{
                      fontSize: 20,
                      color:
                        this.state.activeStep > 2
                          ? "#31c197"
                          : this.state.activeStep === 2
                            ? config.themeColor
                            : "#ccc",
                    }}
                  ></i>
                  <span
                    className="stepper_text"
                    style={{
                      color:
                        this.state.activeStep > 2
                          ? "#31c197"
                          : this.state.activeStep === 2
                            ? config.themeColor
                            : "#ccc",
                    }}
                  >
                    Surveyor Details
                  </span>
                  <i
                    className="fa fa-chevron-right stepper_sub_icon stepper_arrow"
                    aria-hidden="true"
                    style={{
                      color:
                        this.state.activeStep > 2
                          ? "#31c197"
                          : this.state.activeStep === 2
                            ? config.themeColor
                            : "#ccc",
                    }}
                  ></i>
                </span>
                <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                  <i
                    className={
                      this.state.activeStep > 3
                        ? "fa fa-check-circle"
                        : "fa fa-check-circle-o"
                    }
                    aria-hidden="true"
                    style={{
                      fontSize: 20,
                      color:
                        this.state.activeStep > 3
                          ? "#31c197"
                          : this.state.activeStep === 3
                            ? config.themeColor
                            : "#ccc",
                    }}
                  ></i>
                  <span
                    className="stepper_text"
                    style={{
                      color:
                        this.state.activeStep > 3
                          ? "#31c197"
                          : this.state.activeStep === 3
                            ? config.themeColor
                            : "#ccc",
                    }}
                  >
                    Payment Terms
                  </span>
                  <i
                    className="fa fa-chevron-right stepper_sub_icon stepper_arrow"
                    aria-hidden="true"
                    style={{
                      color:
                        this.state.activeStep > 3
                          ? "#31c197"
                          : this.state.activeStep === 3
                            ? config.themeColor
                            : "#ccc",
                    }}
                  ></i>
                </span>
                <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                  <i
                    className={
                      this.state.activeStep > 4
                        ? "fa fa-check-circle"
                        : "fa fa-check-circle-o"
                    }
                    aria-hidden="true"
                    style={{
                      fontSize: 20,
                      color:
                        this.state.activeStep > 2
                          ? "#31c197"
                          : this.state.activeStep === 4
                            ? config.themeColor
                            : "#ccc",
                    }}
                  ></i>
                  <span
                    className="stepper_text"
                    style={{
                      color:
                        this.state.activeStep === 4
                          ? config.themeColor
                          : "#ccc",
                    }}
                  >
                    Preview and Submit
                  </span>
                </span>
              </div>
              <div className="col-lg card" style={{ overflowY: "scroll" }}>
                {this.state.activeStep === 0 && (
                  <div className="section_block">
                    <div style={{ width: "70%" }}>
                      <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                        Add details to create Sales Contract
                      </h5>
                    </div>
                    <div className="row mb-0">
                      <div className="col-lg-6 p-0">
                        <div className="row mb-2">
                          <label className="form_label mb-0">
                            Customer <span style={{ color: "red" }}>*</span>
                          </label>
                          <Autocomplete
                            options={this.state.customersDataList}
                            getOptionLabel={(option) => option.name}
                            disabled
                            onChange={(event, value) => {
                              value != null
                                ? this.setState({
                                  filteredCustomerData: value,
                                  customer: value.value,
                                  customerError: false,
                                })
                                : this.setState({
                                  filteredCustomerData: value,
                                  customer: "",
                                  customerError: false,
                                });
                            }}
                            name="customer"
                            size="small"
                            value={this.state.filteredCustomerData}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Search Customer"
                                // label="Search Customer"
                                variant="outlined"
                                error={this.state.customerError}
                                style={{ top: 8 }}
                                fullWidth
                              />
                            )}
                            style={{ display: "contents" }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 pr-0">
                        <div className="row mb-2">
                          <label className="form_label mb-0">
                            Buyer Reference No
                          </label>
                          <TextField
                            name="buyer_ref_no"
                            margin="dense"
                            variant="outlined"
                            placeholder="Buyer Reference No"
                            value={this.state.buyer_ref_no}
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                            onChange={this.handleText}
                            fullWidth
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-lg-6 p-0">
                        <div className="row mb-2">
                          <label className="form_label mb-0">
                            Contract Date{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                              autoOk={true}
                              inputVariant="outlined"
                              variant="inline"
                              margin="dense"
                              fullWidth
                              format="dd/MM/yyyy"
                              value={this.state.contract_date}
                              onChange={(date) =>
                                this.contractDateHandler(date)
                              }
                              error={this.state.contractDateError}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <IconButton>
                                      <EventIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                                className: "pl-0",
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                      </div>
                      <div className="col-lg-6 pr-0">
                        <div className="row mb-2">
                          <label className="form_label mb-0">
                            Currency <span style={{ color: "red" }}>*</span>
                          </label>
                          <TextField
                            name="currency"
                            variant="outlined"
                            margin="dense"
                            select
                            value={this.state.currency}
                            onChange={this.handleText}
                            style={{ width: "87%" }}
                            error={this.state.currencyError}
                          >
                            {this.state.currencyData}
                          </TextField>
                          <AddCircle
                            onClick={() =>
                              this.setState({ clickedCurrency: "Currency" })
                            }
                            style={{
                              marginTop: 15,
                              marginLeft: 5,
                              color: config.themeColor,
                            }}
                            data-toggle="modal"
                            data-target="#myModal"
                          />
                        </div>
                      </div>
                    </div>
                    {this.state.LaycanData.map((e, idx) => (
                      <div className="row mb-2" key={idx}>
                        <div className="col-lg-6 p-0">
                          <label className="form_label mb-0">
                            Laycan{" "}
                            {idx === 0 && (
                              <span style={{ color: "red" }}>*</span>
                            )}
                          </label>
                          <TextField
                            name="laycan"
                            margin="dense"
                            // label='Laycan *'
                            variant="outlined"
                            placeholder="Laycan *"
                            error={this.state.laycan1Error}
                            value={this.state.LaycanData[idx].laycan}
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                            onChange={this.laycanTextHandler(idx)}
                            fullWidth
                          />
                        </div>
                        <div
                          className={
                            this.state.LaycanData.length > 0
                              ? "col-lg-5 pr-0"
                              : "col-lg-6 pr-0"
                          }
                        >
                          <label
                            className="form_label mb-0"
                            style={{ visibility: "hidden" }}
                          >
                            None
                          </label>
                          <TextField
                            name="laycan_quantity"
                            margin="dense"
                            label="Quantity in MT"
                            variant="outlined"
                            placeholder="Quantity in MT"
                            value={
                              this.state.LaycanData[idx].laycan_quantity
                                ? this.state.LaycanData[idx].laycan_quantity
                                : ""
                            }
                            onChange={this.laycanTextHandler(idx)}
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                            fullWidth
                          />
                        </div>
                        {this.state.LaycanData.length > 1 && (
                          <center className="col-lg-1 d-flex justify-content-center">
                            <i
                              className="fa fa-trash-o m-auto"
                              onClick={this.handleRemoveLaycanRows(idx)}
                              style={{
                                fontSize: 24,
                                color: config.themeColor,
                                cursor: "pointer",
                              }}
                            />
                          </center>
                        )}
                      </div>
                    ))}

                    <div className="row mb-3 mt-1">
                      <div className="col-lg-6 p-0">
                        {this.state.LaycanData.length < 5 && (
                          <button
                            type="button"
                            onClick={this.handleLaycanRows}
                            className="header_button header_button_text addrow_button_adjustment w-auto"
                            style={{ border: "none", color: config.themeColor }}
                          >
                            ADD ROW
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {this.state.activeStep === 1 && (
                  <div className="section_block">
                    <div style={{ width: "70%" }}>
                      <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                        Add Quality
                      </h5>
                    </div>
                    <SalesQualities
                      quality={this.state.salesTypeRows}
                      mines={this.state.vendorTypeAsCoalData}
                      callbackFn={(qualities) => {
                        this.setState({
                          salesTypeRows: qualities,
                        });
                      }}
                    />

                    <div className="row">
                      <div className="col-lg-12 p-0">
                        <label className="form_label mb-0">
                          Quality Adjustment
                        </label>
                        <TextField
                          name="payment_adjustment"
                          margin="dense"
                          multiline
                          rows={3}
                          variant="outlined"
                          placeholder="Quality Adjustment"
                          value={this.state.payment_adjustment}
                          onChange={this.handleText}
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          fullWidth
                        />
                      </div>
                    </div>  

                    <div className="row">
                      <div className="col-lg-12 p-0">
                        <label className="form_label mb-0">
                          Shipping Terms
                        </label>
                        <TextField
                          name="shipping_terms"
                          margin="dense"
                          multiline
                          rows={3}
                          variant="outlined"
                          placeholder="Shipping Terms"
                          value={this.state.shipping_terms}
                          onChange={this.handleText}
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          fullWidth
                        />
                      </div>
                    </div>

                  </div>
                )}

                {this.state.activeStep === 2 && (
                  <div className="section_block">
                    <div style={{ width: "70%" }}>
                      <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                        Surveyor
                      </h5>
                    </div>

                    <div className="row mb-2">
                      <div className="col-lg-6 p-0">
                        <label className="form_label mb-0">
                          Supplier Surveyor Name
                        </label>
                        <FormControl
                          margin="dense"
                          variant="outlined"
                          fullWidth
                        >
                          <Select
                            id="demo-mutiple-CheckBox"
                            multiple
                            fullWidth
                            name="supplierSurveyorName"
                            value={this.state.supplierSurveyorName.sort()}
                            onChange={this.MultiSelectHandler}
                            renderValue={(selected) => selected.join(", ")}
                          >
                            {this.state.surveyorsData.map((e, index) => (
                              <MenuItem value={e.name} key={index}>
                                <CheckBox
                                  checked={
                                    this.state.supplierSurveyorName.includes(
                                      e.name
                                    )
                                  }
                                />
                                <ListItemText primary={e.name} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="col-lg-6 pr-0">
                        <label className="form_label mb-0">
                          Supplier Surveyor Analysis Type
                        </label>
                        <FormControl
                          margin="dense"
                          variant="outlined"
                          fullWidth
                        >
                          <Select
                            id="demo1-mutiple-CheckBox"
                            multiple
                            name="supplierSurveyorAnalysisType"
                            fullWidth
                            value={this.state.supplierSurveyorAnalysisType.sort()}
                            onChange={this.MultiSelectHandler}
                            // input={<Input />}
                            renderValue={(selected) => selected.join(", ")}
                          //MenuProps={MenuProps}
                          >
                            {this.state.analysisTypeValues.map((e, index) => (
                              <MenuItem value={e} key={index}>
                                <CheckBox
                                  checked={
                                    this.state.supplierSurveyorAnalysisType.includes(
                                      e
                                    )
                                  }
                                />
                                <ListItemText primary={e} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>

                    <div className="row mb-2">
                      <div className="col-lg-6 p-0">
                        <label className="form_label mb-0">
                          {config.company} Surveyor Name
                        </label>
                        <FormControl
                          margin="dense"
                          variant="outlined"
                          fullWidth
                        >
                          <Select
                            id="demo2-mutiple-CheckBox"
                            multiple
                            fullWidth
                            name="saiiSurveyorName"
                            value={this.state.saiiSurveyorName.sort()}
                            onChange={this.MultiSelectHandler}
                            renderValue={(selected) => selected.join(", ")}
                          >
                            {this.state.surveyorsData.map((e, index) => (
                              <MenuItem value={e.name} key={index}>
                                <CheckBox
                                  checked={
                                    this.state.saiiSurveyorName.includes(
                                      e.name
                                    )
                                  }
                                />
                                <ListItemText primary={e.name} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="col-lg-6 pr-0">
                        <label className="form_label mb-0">
                          {config.company} Surveyor Analysis Type
                        </label>
                        <FormControl
                          margin="dense"
                          variant="outlined"
                          fullWidth
                        >
                          <Select
                            multiple
                            name="saiiSurveyorAnalysisType"
                            fullWidth
                            value={this.state.saiiSurveyorAnalysisType.sort()}
                            onChange={this.MultiSelectHandler}
                            renderValue={(selected) => selected.join(", ")}
                          >
                            {this.state.analysisTypeValues.map((e, index) => (
                              <MenuItem value={e} key={index}>
                                <CheckBox
                                  checked={
                                    this.state.saiiSurveyorAnalysisType.includes(
                                      e
                                    )
                                  }
                                />
                                <ListItemText primary={e} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>

                    <div className="row mb-2">
                      <div className="col-lg-6 p-0">
                        <label className="form_label mb-0">
                          Buyer/Additional Surveyor Name
                        </label>
                        <FormControl
                          margin="dense"
                          variant="outlined"
                          fullWidth
                        >
                          <Select
                            id="demo4-mutiple-CheckBox"
                            multiple
                            fullWidth
                            name="buyerSurveyorName"
                            value={this.state.buyerSurveyorName.sort()}
                            onChange={this.MultiSelectHandler}
                            renderValue={(selected) => selected.join(", ")}
                          >
                            {this.state.surveyorsData.map((e, index) => (
                              <MenuItem value={e.name} key={index}>
                                <CheckBox
                                  checked={
                                    this.state.buyerSurveyorName.includes(
                                      e.name
                                    )
                                  }
                                />
                                <ListItemText primary={e.name} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="col-lg-6 pr-0">
                        <label className="form_label mb-0">
                          Buyer/Additional Surveyor Analysis Type
                        </label>
                        <FormControl
                          margin="dense"
                          variant="outlined"
                          fullWidth
                        >
                          <Select
                            id="demo5-mutiple-CheckBox"
                            multiple
                            name="buyerSurveyorAnalysisType"
                            fullWidth
                            value={this.state.buyerSurveyorAnalysisType.sort()}
                            onChange={this.MultiSelectHandler}
                            renderValue={(selected) => selected.join(", ")}
                          >
                            {this.state.analysisTypeValues.map((e, index) => (
                              <MenuItem value={e} key={index}>
                                <CheckBox
                                  checked={
                                    this.state.buyerSurveyorAnalysisType.includes(
                                      e
                                    )
                                  }
                                />
                                <ListItemText primary={e} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>

                    {/* <div style={{ width: "70%" }}>
                      <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                        Price
                      </h5>
                    </div>

                    <div className="row mb-2">
                      <div className="col-lg-6 p-0">
                        <label className="form_label mb-0">
                          Stevedore Price
                        </label>
                        <TextField
                          name="stevedore_price"
                          placeholder="Stevedore Price"
                          margin="dense"
                          variant="outlined"
                          value={this.state.stevedore_price}
                          onChange={(e) => {
                            const pr = /^\d*(\.\d{0,4})?$/;
                            if (
                              e.target.value === "" ||
                              pr.test(e.target.value)
                            ) {
                              this.setState({
                                [e.target.name]: e.target.value,
                              });
                            }
                          }}
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          fullWidth
                        />
                      </div>
                      <div className="col-lg-6 pr-0">
                        <label className="form_label mb-0">pnbp</label>
                        <TextField
                          name="pnbp"
                          placeholder="pnbp"
                          margin="dense"
                          variant="outlined"
                          value={this.state.pnbp}
                          onChange={this.handleDecimalField}
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          fullWidth
                        />
                      </div>
                    </div> */}

                    <div style={{ width: "70%" }}>
                      <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                        Loading
                      </h5>
                    </div>

                    <div className="row mb-2">
                      <div className="col-lg-6 p-0">
                        <label className="form_label mb-0">
                          Vessel Type <span style={{ color: "red" }}>*</span>
                        </label>
                        <TextField
                          name="vessel_type"
                          variant="outlined"
                          margin="dense"
                          value={this.state.vessel_type}
                          onChange={this.handleText}
                          select
                          fullWidth
                          error={this.state.vesselTypeError}
                        >
                          <MenuItem value="0" disabled>
                            Please Select
                          </MenuItem>
                          <MenuItem value="Geared & Grabbed">
                            Geared & Grabbed
                          </MenuItem>
                          <MenuItem value="Gearless">Gearless</MenuItem>
                          <MenuItem value="NA">NA</MenuItem>
                        </TextField>
                      </div>
                      <div className="col-lg-6 pr-0">
                        <label className="form_label mb-0">
                          Loading Rate for Geared{" "}
                          {this.state.vessel_type === "Geared & Grabbed" ? (
                            <span style={{ color: "red" }}>*</span>
                          ) : (
                            ""
                          )}
                        </label>
                        <TextField
                          name="loading_rate_for_geared"
                          margin="dense"
                          variant="outlined"
                          placeholder="Loading Rate for Geared"
                          value={this.state.loading_rate_for_geared}
                          onChange={this.handleText}
                          error={this.state.loadingRateforGearedError}
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          fullWidth
                        />
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-lg-6 p-0">
                        <label className="form_label mb-0">
                          Loading Rate for Gearless{" "}
                          {this.state.vessel_type === "Gearless" ? (
                            <span style={{ color: "red" }}>*</span>
                          ) : (
                            ""
                          )}
                        </label>
                        <TextField
                          name="loading_rate_for_gearless"
                          margin="dense"
                          variant="outlined"
                          placeholder="Loading Rate for Gearless"
                          value={this.state.loading_rate_for_gearless}
                          error={this.state.loadingRateforGearLessError}
                          onChange={this.handleText}
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          fullWidth
                        />
                      </div>

                      <div className="col-lg-6 pr-0">
                        <label className="form_label mb-0">
                          Port of Loading{" "}
                        </label>
                        <TextField
                          name="port_of_loading"
                          variant="outlined"
                          margin="dense"
                          placeholder="Port of Loading"
                          value={this.state.port_of_loading}
                          onChange={this.handleText}
                          fullWidth
                          inputProps={{ style: { textTransform: "uppercase" } }}
                        />
                      </div>
                    </div>

                    {this.state.dischargeRateField && (
                      <span className="row">
                        <label className="form_label mb-0">
                          Port of Discharge
                        </label>
                        <div className="col-lg-12 p-0">
                          {this.state.portOfDischargeRows.map((e, idx) => (
                            <div className="row" key={idx}>
                              <div className="col-lg-6 p-0">
                                <TextField
                                  name="dischargeRate"
                                  margin="dense"
                                  fullWidth
                                  placeholder="Discharge Rate"
                                  variant="outlined"
                                  value={
                                    this.state.portOfDischargeRows[idx]
                                      .discharge_rate
                                  }
                                  onChange={this.portOfDischargeRowsTextHandler(
                                    idx
                                  )}
                                  inputProps={{
                                    style: { textTransform: "uppercase" },
                                  }}
                                />
                              </div>
                              <div className="col-lg-5 pr-0">
                                <TextField
                                  name="portOfDischarge"
                                  margin="dense"
                                  fullWidth
                                  placeholder="Port of Discharge"
                                  variant="outlined"
                                  onChange={this.portOfDischargeRowsTextHandler(
                                    idx
                                  )}
                                  value={
                                    this.state.portOfDischargeRows[idx]
                                      .portOf_discharge
                                  }
                                  inputProps={{
                                    style: { textTransform: "uppercase" },
                                  }}
                                />
                              </div>
                              {idx === 0 ? (
                                <div
                                  style={{ visibility: "hidden", padding: 10 }}
                                >
                                  <i
                                    className="fa fa-trash-o"
                                    onClick={this.removePortOfDischargeRows(
                                      idx
                                    )}
                                    style={{
                                      fontSize: 24,
                                      color: config.themeColor,
                                    }}
                                    aria-hidden="true"
                                  />
                                </div>
                              ) : (
                                <div style={{ padding: 10 }}>
                                  <i
                                    className="fa fa-trash-o"
                                    onClick={this.removePortOfDischargeRows(
                                      idx
                                    )}
                                    style={{
                                      fontSize: 24,
                                      color: config.themeColor,
                                    }}
                                    aria-hidden="true"
                                  />
                                </div>
                              )}
                            </div>
                          ))}
                          <div className="row mb-3 mt-1">
                            <div className="col-lg-6 p-0">
                              {this.state.portOfDischargeRows.length < 3 && (
                                <button
                                  type="button"
                                  onClick={this.AddportOfDischargeRowsHandler}
                                  className="header_button header_button_text addrow_button_adjustment"
                                  style={{
                                    border: "none",
                                    color: config.themeColor,
                                  }}
                                >
                                  ADD ROW
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </span>
                    )}
                  </div>
                )}

                {this.state.activeStep === 3 && (
                  <div className="section_block">
                    <div style={{ width: "70%" }}>
                      <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                        Payment
                      </h5>
                    </div>
                    <div className="row mb-2">

                      <div className="col-lg-6 p-0">
                        <label className="form_label mb-0">
                          Payment Terms 1
                        </label>
                        <TextField
                          name="payment_terms_1"
                          margin="dense"
                          multiline
                          rows={3}
                          variant="outlined"
                          placeholder="Payment Terms 1"
                          value={this.state.payment_terms_1}
                          onChange={this.handleText}
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          fullWidth
                        />
                      </div>
                      <div className="col-lg-6 pr-0">
                        <label className="form_label mb-0">
                          Payment Terms 2
                        </label>
                        <TextField
                          name="payment_terms_2"
                          margin="dense"
                          multiline
                          rows={3}
                          variant="outlined"
                          placeholder="Payment Terms 2"
                          value={this.state.payment_terms_2}
                          onChange={this.handleText}
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          fullWidth
                        />
                      </div>
                      <div className="col-lg-6 p-0">
                        <label className="form_label mb-0">Remarks</label>
                        <TextField
                          name="remarks"
                          margin="dense"
                          multiline
                          rows={3}
                          variant="outlined"
                          placeholder="Remarks"
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          value={this.state.remarks}
                          onChange={this.handleText}
                          fullWidth
                        />
                      </div>
                      <div className="col-lg-6 pr-0">
                        <label className="form_label mb-0">
                          Share Point Document Link
                        </label>
                        <TextField
                          name="share_point_doc_link"
                          margin="dense"
                          variant="outlined"
                          placeholder="Share Point Document Link"
                          value={this.state.share_point_doc_link}
                          onChange={this.handleText}
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          fullWidth
                        />
                      </div>
                    </div>
                    {(this.state.features.includes("326") ||
                      this.state.features.includes("97")) && (
                        <FileUpload
                          files={this.state.files}
                          feature_name="Sales_Contract"
                          callbackFn={(data) => this.setState({ files: data })}
                        />
                      )}
                  </div>
                )}

                {this.state.activeStep === 4 && (
                  <div className="section_block">
                    <div className="card p-0 border mb-3">
                      <div className="card-header section_header">
                        <h5>Contract Details</h5>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-lg-4 mb-3">
                            <label className="contract_display_header_label">
                              Customer
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ textTransform: "uppercase" }}
                            >
                              {this.state.customer
                                ? this.getVendorvalue(this.state.customer)
                                : "-"}
                            </div>
                          </div>
                          <div className="col-lg-4 mb-3">
                            <label className="contract_display_header_label">
                              Buyer Reference No
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ textTransform: "uppercase" }}
                            >
                              {this.state.buyer_ref_no
                                ? this.state.buyer_ref_no
                                : "-"}{" "}
                            </div>
                          </div>
                          <div className="col-lg-4 mb-3">
                            <label className="contract_display_header_label">
                              Contract Date
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ textTransform: "uppercase" }}
                            >
                              {localDateFormate(
                                this.state.contract_date
                                  ? dateFormateToDB(this.state.contract_date)
                                  : null
                              )}
                            </div>
                          </div>
                          <div className="col-lg-4 mb-3">
                            <label className="contract_display_header_label">
                              Currency
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ textTransform: "uppercase" }}
                            >
                              {this.state.currency ? this.state.currency : "-"}{" "}
                            </div>
                          </div>

                          {this.state.LaycanData.map((e, idx) => (
                            <div className="col-lg-4 mb-3" key={idx}>
                              <label className="contract_display_header_label">
                                {" "}
                                Laycan {idx + 1}
                              </label>
                              <div
                                className="contract_display_header_value"
                                style={{ textTransform: "uppercase" }}
                              >
                                {e.laycan ? e.laycan : "-"} ,{" "}
                                {e.laycan_quantity
                                  ? this.toLocaleString(e.laycan_quantity)
                                  : " -"}{" "}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div className="card p-0 border mb-3">
                      <div className="card-header section_header">
                        <h5>Quality</h5>
                      </div>
                      <div className="card-body">
                        <QualityView
                          contractType={"Sales"}
                          qualities={this.state.salesTypeRows}
                          callbackFn={() => { }}

                        />
                      </div>
                    </div>

                    <div className="card p-0 border mb-3">
                      <div className="card-header section_header">
                        <h5>Surveyor Details</h5>
                      </div>
                      <div className="card-body">
                        <div className="row col-lg-12 p-2">
                          <label
                            className="col-lg-12 contract_display_header_value pl-0"
                            style={{ fontWeight: "bold" }}
                          >
                            Surveyor
                          </label>
                          <div className="col-lg-6">
                            <label className="contract_display_header_label">
                              Supplier Surveyor Name
                            </label>
                            <label className="contract_display_header_value">
                              {this.state.supplierSurveyorName.toString()
                                ? this.state.supplierSurveyorName.toString()
                                : "-"}
                            </label>
                          </div>
                          <div className="col-lg-6">
                            <label className="contract_display_header_label">
                              Supplier Surveyor Analysis Type
                            </label>
                            <label className="contract_display_header_value">
                              {this.state.supplierSurveyorAnalysisType.toString()
                                ? this.state.supplierSurveyorAnalysisType.toString()
                                : "-"}
                            </label>
                          </div>

                          <div className="col-lg-6 mt-2">
                            <label className="contract_display_header_label">
                              {config.company} Surveyor Name
                            </label>
                            <label className="contract_display_header_value">
                              {this.state.saiiSurveyorName.toString()
                                ? this.state.saiiSurveyorName.toString()
                                : "-"}
                            </label>
                          </div>
                          <div className="col-lg-6 mt-2">
                            <label className="contract_display_header_label">
                              {config.company} Surveyor Analysis Type
                            </label>
                            <label className="contract_display_header_value">
                              {this.state.saiiSurveyorAnalysisType.toString()
                                ? this.state.saiiSurveyorAnalysisType.toString()
                                : "-"}
                            </label>
                          </div>
                          <div className="col-lg-6 mt-2">
                            <label className="contract_display_header_label">
                              Buyer/Additional Surveyor Name
                            </label>
                            <label className="contract_display_header_value">
                              {this.state.buyerSurveyorName.toString()
                                ? this.state.buyerSurveyorName.toString()
                                : "-"}
                            </label>
                          </div>
                          <div className="col-lg-6 mt-2">
                            <label className="contract_display_header_label">
                              Buyer/Additional Surveyor Analysis Type
                            </label>
                            <label className="contract_display_header_value">
                              {this.state.buyerSurveyorAnalysisType.toString()
                                ? this.state.buyerSurveyorAnalysisType.toString()
                                : "-"}
                            </label>
                          </div>
                          {/* <label
                            className="col-lg-12 contract_display_header_value pl-0"
                            style={{ fontWeight: "bold" }}
                          >
                            Price
                          </label>
                          <div className="col-lg-6">
                            <label className="contract_display_header_label">
                              Stevedore Price
                            </label>
                            <label className="contract_display_header_value">
                              {this.state.stevedore_price
                                ? this.toLocaleString(this.state.stevedore_price)
                                : "-"}
                            </label>
                          </div>
                          
                          <div className="col-lg-6">
                            <label className="contract_display_header_label">
                              pnbp
                            </label>
                            <label className="contract_display_header_value">
                              {this.state.pnbp ? this.state.pnbp : "-"}
                            </label>
                          </div> */}
                          <label
                            className="col-lg-12 contract_display_header_value pl-0"
                            style={{ fontWeight: "bold" }}
                          >
                            Loading
                          </label>
                          <div className="col-lg-6">
                            <label className="contract_display_header_label">
                              Vessel Type
                            </label>
                            <label className="contract_display_header_value">
                              {this.state.vessel_type
                                ? this.state.vessel_type
                                : "-"}
                            </label>
                          </div>
                          <div className="col-lg-6">
                            <label className="contract_display_header_label">
                              Loading Rate for Geared
                            </label>
                            <label className="contract_display_header_value">
                              {this.state.loading_rate_for_geared
                                ? this.state.loading_rate_for_geared
                                : "-"}
                            </label>
                          </div>
                          <div className="col-lg-6">
                            <label className="contract_display_header_label">
                              Loading Rate for Gearless
                            </label>
                            <label className="contract_display_header_value">
                              {this.state.loading_rate_for_gearless
                                ? this.state.loading_rate_for_gearless
                                : "-"}
                            </label>
                          </div>
                          <div className="col-lg-6">
                            <label className="contract_display_header_label">
                              Port of Loading
                            </label>
                            <label
                              className="contract_display_header_value"
                              style={{ textTransform: "uppercase" }}
                            >
                              {this.state.pod ? this.state.pod : "-"}
                            </label>
                          </div>
                          {this.state.dischargeRateField && (
                            <div>
                              <label
                                className="col-lg-12 contract_display_header_value pl-0"
                                style={{ fontWeight: "bold" }}
                              >
                                Port of Discharge
                              </label>
                              {this.state.portOfDischargeRows.map((e, idx) => (
                                <div className="col-lg-12 mt-2" key={idx}>
                                  <label className="contract_display_header_label">
                                    Port of Discharge {idx + 1}
                                  </label>
                                  <label
                                    className="contract_display_header_value"
                                    style={{ textTransform: "uppercase" }}
                                  >
                                    {
                                      this.state.portOfDischargeRows[idx]
                                        .discharge_rate
                                    }
                                    {this.state.portOfDischargeRows[idx]
                                      .portOf_discharge
                                      ? " - " +
                                      this.state.portOfDischargeRows[idx]
                                        .portOf_discharge
                                      : "-"}
                                  </label>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="card p-0 border mb-3">
                      <div className="card-header section_header">
                        <h5>Quality And Shipping Terms</h5>
                      </div>
                      <div className="card-body">
                        <div className="row col-lg-12 p-2">
                          <label
                            className="col-lg-12 contract_display_header_value pl-0"
                            style={{ fontWeight: "bold" }}
                          >
                            Quality And Shipping Terms
                          </label>
                          <div className="col-lg-6">
                            <label className="contract_display_header_label">
                              Quality Adjustment
                            </label>
                            <label
                              className="contract_display_header_value"
                              style={{
                                whiteSpace: "pre-line",
                                textTransform: "uppercase",
                              }}
                            >
                              {this.state.payment_adjustment
                                ? this.state.payment_adjustment
                                : "-"}
                            </label>
                          </div>
                          <div className="col-lg-6">
                            <label className="contract_display_header_label">
                             Shipping Terms
                            </label>
                            <label
                              className="contract_display_header_value"
                              style={{
                                whiteSpace: "pre-line",
                                textTransform: "uppercase",
                              }}
                            >
                              {this.state.shipping_terms
                                ? this.state.shipping_terms
                                : "-"}
                            </label>
                          </div>

                        </div>
                      </div>
                    </div>

                    <div className="card p-0 border mb-3">
                      <div className="card-header section_header">
                        <h5>Payment Terms</h5>
                      </div>
                      <div className="card-body">
                        <div className="row col-lg-12 p-2">
                          <label
                            className="col-lg-12 contract_display_header_value pl-0"
                            style={{ fontWeight: "bold" }}
                          >
                            Payment
                          </label>
                          <div className="col-lg-6">
                            <label className="contract_display_header_label">
                              Payment Terms 1
                            </label>
                            <label
                              className="contract_display_header_value"
                              style={{
                                whiteSpace: "pre-line",
                                textTransform: "uppercase",
                              }}
                            >
                              {this.state.payment_terms_1
                                ? this.state.payment_terms_1
                                : "-"}
                            </label>
                          </div>
                          <div className="col-lg-6 mt-2">
                            <label className="contract_display_header_label">
                              Payment Terms 2
                            </label>
                            <label
                              className="contract_display_header_value"
                              style={{
                                whiteSpace: "pre-line",
                                textTransform: "uppercase",
                              }}
                            >
                              {this.state.payment_terms_2
                                ? this.state.payment_terms_2
                                : "-"}
                            </label>
                          </div>
                          <div className="col-lg-6 mt-2">
                            <label className="contract_display_header_label">
                              Remarks
                            </label>
                            <label
                              className="contract_display_header_value"
                              style={{
                                whiteSpace: "pre-line",
                                textTransform: "uppercase",
                              }}
                            >
                              {this.state.remarks ? this.state.remarks : "-"}
                            </label>
                          </div>
                          <div className="col-lg-6 mt-2">
                            <label className="contract_display_header_label">
                              Share Point Document Link
                            </label>
                            <label
                              className="contract_display_header_value"
                              style={{ textTransform: "uppercase" }}
                            >
                              {this.state.share_point_doc_link
                                ? this.state.share_point_doc_link
                                : "-"}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>                  
                  </div>
                )}

              </div>

              <div className="row bottom_buttons_section">
                <button
                  type="button"
                  className="btn previous_button"
                  name="submit"
                  onClick={
                    this.state.activeStep === 0
                      ? () => (window.location.href = "/sales-contract-list")
                      : this.previousStepHandler
                  }
                >
                  {this.state.activeStep === 0 ? "BACK" : "PREVIOUS"}
                </button>
                <button
                  type="button"
                  className="btn next_button"
                  name="Back"
                  onClick={
                    this.state.activeStep === 4
                      ? this.handleSubmit
                      : this.nextStepHandler
                  }
                >
                  {this.state.activeStep === 4 ? "SUBMIT" : "NEXT STEP"}
                </button>
              </div>
            </form>
          )}

          <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="draggable-dialog-title"
          >
            <DialogContent>
              <DialogContentText>{this.state.dialogText}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary">
                Ok
              </Button>
            </DialogActions>
          </Dialog>
          <div className="modal fade" id="myModal" role="dialog">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h4
                    className="modal-title"
                    style={{ border: "none", padding: 0 }}
                  >
                    Add Currency
                  </h4>
                  <button type="button" className="close" data-dismiss="modal">
                    &times;
                  </button>
                </div>
                <div className="modal-body">
                  <TextField
                    name="newCurrency"
                    placeholder="Add New Currency *"
                    margin="dense"
                    variant="outlined"
                    onChange={this.handleText}
                    value={this.state.newCurrency}
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    error={this.state.modalCurrencyError}
                  />
                </div>
                <div className="row bottom_buttons_section">
                  <button
                    type="button"
                    className="btn previous_button"
                    name="submit"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn next_button"
                    name="Back"
                    onClick={this.handleAddCurrency}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
