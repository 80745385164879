import React, { Component } from "react";
import api from "../../../api/api";
import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import { localDateFormate, toLocaleString } from "../../common/common";
import ViewFiles from "../../common/ViewFiles";
import PaymentPostings from "../../common/PaymentPostings";
import Loader from "../../common/Loader";
import InvoiceForm from "../../common/InvoiceForm";
import BusinessApproveButton from "../../Approvals/BusinessApproveButton";
import InvoiceLink from "../../InvoicePdf/InvoiceLink";
export default class Other_Expenses_View extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();

    this.state = {
      multiRows: [
        {
          credit_type: "Incoming",
          vendor_or_customer: "Vendor",
          vendor_id: "",
          customer_id: "",
          expense_date: null,
          amount: "",
          remarks: "",
        },
      ],
      getBarges: [],
      invoiceDetails: {
        invoice_no: "",
        invoice_date: null,
        ppn: "",
        ppn_date: null,
      },
      isLoading: true,
      mother_vessel_name: "",
      features: [],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    if (
      !(
        features.includes("295") ||
        features.includes("487") ||
        features.includes("493") ||
        features.includes("97")
      )
    ) {
      window.location.href = "/dashboard";
    }
    this.setState({ features: features });
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const OtherExpenseID = this.props.OtherExpenseID;

    await api
      .get_buss_no_other_expenses(loginUserID, idToken, OtherExpenseID)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.business_no_other_expenses) {
                //var remaining_amount = 0;
                for (var i in res.business_no_other_expenses) {
                  var total_paid_amout = 0;
                  for (var j of res.business_no_other_expenses[i]
                    .other_expenses_payments) {
                    total_paid_amout += Number(j.amount);
                  }
                  res.business_no_other_expenses[i].total_paid_amout =
                    total_paid_amout;
                  res.business_no_other_expenses[i].remaining_amount =
                    Number(res.business_no_other_expenses[i].amount) -
                    Number(total_paid_amout);
                }
                let invoice_details = {
                  ...this.state.invoiceDetails,
                  invoice_no: res.business_no_other_expenses[0]?.invoice_no,
                  invoice_date: res.business_no_other_expenses[0]?.invoice_date,
                  ppn: res.business_no_other_expenses[0]?.ppn,
                  ppn_date: res.business_no_other_expenses[0]?.ppn_date,
                };
                this.setState({
                  invoiceDetails: invoice_details,
                  multiRows: res.business_no_other_expenses,
                  costing_no: res.business_no_other_expenses[0].costing_no,
                  exchange_rate:
                    res.business_no_other_expenses[0].exchange_rate,
                  vendor_name: res.business_no_other_expenses[0].vendor_name,
                  files:
                    res.business_no_other_expenses.length > 0
                      ? res.business_no_other_expenses[0].files
                      : [],
                  isLoading: false,
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
    const businessNoID = this.props.businessNoID;
    //Get businessNumber API based on businessNumber
    await api
      .getBusinessNumber(businessNoID, loginUserID, idToken)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.business_number) {
                this.setState({
                  businessNo: res.business_number.business_no
                    ? res.business_number.business_no
                    : "",
                  mother_vessel_name: res.business_number.mother_vessel_name
                    ? res.business_number.mother_vessel_name
                    : "",
                  isLoading: false,
                });
              }
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                sucuessMsg: "",
              });
              window.$("#AlertMessageModal").modal({
                backdrop: "static",
                keyboard: true,
                show: true,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });

    document.title = config.documentTitle + "View Other Expenses ";
  }

  renderPaymentPostings = (data) => {
    const {
      id,
      other_expenses_payments = [],
      invoice_no = null,
      remaining_amount = 0,
      billing_currency,
      exchange_rate = 1,
      approval_status = "Pending",
    } = data;
    let payment_related_data = {
      paymentTitle: "MV Other Expenses",
      payment_posting_type: "other_expenses",
      invoice_no: invoice_no,
      outstanding_amount: remaining_amount,
      billing_currency: billing_currency
        ? billing_currency
        : config.company === "SRPL" || config.company === "AVS" || config.company === "PRPL"
          ? "USD"
          : "IDR",
      currency_xchg_rate: exchange_rate ? exchange_rate : 1,
      costing_id: id,
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
    };
    let access_details = {
      costing_approval: approval_status,
      add_payment: "298",
      view_payment: "299",
      update_payment: "300",
      delete_payment: "301",
      approver: "488",
      approver2: "494",
    };

    return (
      <PaymentPostings
        access_details={access_details}
        payments={other_expenses_payments}
        costingType="MV Other Expenses"
        data={payment_related_data}
        callbackFn={() => {
          this.componentDidMount();
        }}
      />
    );
  };

  updateInvoice = (data) => {
    let request = {
      ...data,
      table_name: "business_no_other_expenses",
    };
    api.update_invoice_details(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.componentDidMount();
            this.setState({
              invoiceDialog: false,
            });
          }
        });
      }
    });
  };

  renderInvoiceLink = () => {
    const { invoiceDetails: { invoice_no, invoice_date } = {}, costing_no } =
      this.state;

    const Grand_total = this.state.multiRows[0].amount
      ? parseFloat(this.state.multiRows[0].amount).toFixed(2)
      : 0;

    const invoiceObject = {
      costing_id: this.state.multiRows[0].costing_no,
      to: this.state.vendor_name,
      business_no: this.state.mother_vessel_name,
      costing_name: "MV Other Expenses",
      invoice_no: invoice_no,
      invoice_date: localDateFormate(invoice_date),
      bargeDetails: [],
      billing_currency:
        config.company === "SRPL" || config.company === "AVS" || config.company === "PRPL" ? "USD" : "IDR",
      total_payable_amount: Grand_total,
      purchase_type: "FOB Barge",
      description: [
        (this.state.multiRows ?
          {
            qty: " ",
            adjustedPrice: " ",
            desc: `${this.state.exchange_rate > 1
              ? `MV Other Expenses @${parseFloat(this.state.exchange_rate).toFixed(
                2
              )}`
              : "MV Other Expenses"
              }`,
            amount: parseFloat(this.state.multiRows[0].amount).toFixed(2),
          } : []),
      ],
    };
    return <InvoiceLink data={invoiceObject} fileName={costing_no} />;
  };

  render() {
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div className="container bg-white p-0" style={{ minHeight: "100vh" }}>
          {this.state.multiRows.map((e, idx) => (
            <div className="clearfix content-header border-bottom">
              <div className="row">
                <div className="col-lg-10 text-left pl-0">
                  <h4 style={{ padding: "10px 20px" }}>
                    Other Expenses - {e.costing_no}
                    <span
                      className={
                        e.approval_status === "Pending" ? "type_2" : "type_1"
                      }
                      style={{ padding: 8, marginLeft: 16 }}
                    >
                      {e.approval_status}
                    </span>
                  </h4>
                </div>
                {e.approval_status === "Pending" &&
                  this.Cookie.getCookie("loginUserId") !== e.created_by &&
                  e.approved_by !== this.Cookie.getCookie("loginUserId") &&
                  (this.state.features.includes("487") ||
                    this.state.features.includes("493") ||
                    this.state.features.includes("97")) && (
                    <BusinessApproveButton
                      costingID={e.id}
                      costingType={"MV Other Expenses"}
                      accessInfo={{
                        ...e,
                        approver_1: "487",
                        approver_2: "493",
                      }}
                      callbackFn={() => this.componentDidMount()}
                    />
                  )}
                <div className="col-lg-1 pl-0 float-right">
                  {this.renderInvoiceLink()}
                </div>
              </div>
            </div>
          ))}

          <div className="drawer-section">
            <div className="card p-3">
              {this.state.multiRows.map((e, idx) => (
                <>
                  <div
                    className="row border_dashed rounded-lg p-2 mb-3"
                    key={idx}
                  >
                    <div className="col shadow-sm p-0 mb-2 bg-white border">
                      <div className="table-responsive">
                        <table className="table table-sm table-bordered mb-0">
                          <thead className="table-header">
                            <tr>
                              <th className="text-center">Description</th>
                              <th className="text-right">Qty</th>
                              <th className="text-right">Unit Price</th>
                              <th className="text-right">Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            <>
                              <tr>
                                <td>
                                  Other Expenses
                                  {(((config.company === "SRPL" || config.company === "AVS" ||
                                    config.company === "PRPL") &&
                                    e.billing_currency !== "USD" &&
                                    e.billing_currency) ||
                                    ((config.company === "SDAM" ||
                                      config.company === "SRK" ||
                                      config.company === "BTR") &&
                                      e.billing_currency !== "IDR" &&
                                      e.billing_currency)) &&
                                    e.exchange_rate > 1 && (
                                      <div>
                                        Exchange Rate @ {parseFloat(e.exchange_rate).toFixed(2)}
                                      </div>
                                    )}
                                  <div>To : {e.vendor_name ? e.vendor_name : "Others"} </div>
                                  <div>Date : {e.expense_date ? localDateFormate(e.expense_date) : "_"}</div>
                                </td>
                                <td className="text-right"></td>
                                <td className="text-right"></td>
                                <td className="text-right">{e.amount}</td>
                              </tr>
                              <tr>
                                <td colSpan={3} className="text-right ">
                                  {" "}
                                  Payable to Vendor :{" "}
                                </td>
                                {(((config.company === "SRPL" || config.company === "AVS" ||
                                  config.company === "PRPL") &&
                                  e.billing_currency !== "USD" &&
                                  e.billing_currency) ||
                                  ((config.company === "SDAM" ||
                                    config.company === "SRK" ||
                                    config.company === "BTR") &&
                                    e.billing_currency !== "IDR" &&
                                    e.billing_currency)) &&
                                  <td className="text-right">
                                    {`${config.company === "SRPL" ||
                                      config.company === "PRPL" ||
                                      config.company === "AVS"
                                      ? "USD"
                                      : "IDR"
                                      } ${e.local_currency_amount}`}
                                  </td>
                                }
                                {(config.company === "SRPL" || config.company === "AVS" ||
                                  config.company === "PRPL")
                                  &&
                                  (config.company !== "SDAM" || config.company !== "SRK" ||
                                    config.company !== "BTR") &&
                                  <td className="text-right">
                                    {`${config.company === "SRPL" ||
                                      config.company === "PRPL" ||
                                      config.company === "AVS"
                                      ? "USD"
                                      : "IDR"
                                      } ${e.amount}`}
                                  </td>
                                }
                              </tr>
                            </>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="row col-lg-12 pl-0 pr-0">
                      <div className="col-lg-12 mb-1 pl-0">
                        <label className="contract_display_header_label">
                          Remarks
                        </label>
                        <label
                          className="contract_display_header_value"
                          style={{
                            textTransform: "uppercase",
                            whiteSpace: "pre-line",
                          }}
                        >
                          {e.remarks ? e.remarks : "-"}
                        </label>
                      </div>
                    </div>

                    <div className="card col-lg-12 p-0 border mb-3">
                      <div className="card-header section_header">
                        <h5>Payment Postings</h5>
                      </div>
                      <div className="card-body p-2 pt-3">
                        <InvoiceForm
                          open={false}
                          data={this.state.invoiceDetails}
                          costingID={this.props.OtherExpenseID}
                          callbackFn={(data) => this.updateInvoice(data)}
                        />
                        {(this.state.features.includes("299") ||
                          this.state.features.includes("97")) && (
                            <div>
                              <div className="row">
                                <div className="col-lg-6 mb-1">
                                  <label className="contract_display_header_label">
                                    Total Paid to Vendor
                                  </label>
                                  <label className="contract_display_header_value">
                                    {toLocaleString(e.exchange_rate)}
                                    {config.company === "SRPL" || config.company === "AVS" ||
                                      config.company === "PRPL"
                                      ? " USD"
                                      : " IDR"}
                                  </label>
                                </div>
                                <div className="col-lg-6 mb-1 pl-0">
                                  <label className="contract_display_header_label">
                                    Remaining Amount
                                  </label>
                                  <label className="contract_display_header_value">
                                    {toLocaleString(e.remaining_amount)}
                                    {config.company === "SRPL" || config.company === "AVS" ||
                                      config.company === "PRPL"
                                      ? " USD"
                                      : " IDR"}
                                  </label>
                                </div>
                              </div>
                              {this.renderPaymentPostings(e)}
                            </div>
                          )}
                      </div>
                    </div>

                    <ViewFiles {...this.props} files_data={e.files} />
                  </div>
                  <div className="col shadow-sm p-0 mb-2 bg-white border">
                    <div className="table-responsive">
                      <table className="table table-sm table-bordered">
                        <thead className="table-header">
                          <tr>
                            <th className="text-center">Approval Type</th>
                            <th className="text-center">Approved by</th>
                            <th className="text-center">Approved On</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="text-center">Business</td>
                            <td className="text-center">
                              <div> {e.approved_by_name}</div>
                            </td>
                            <td className="text-center">
                              {localDateFormate(e.approved_date)}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-center">Financial</td>
                            <td className="text-center">
                              {e.approved_by_name_2}
                            </td>
                            <td className="text-center">
                              {localDateFormate(e.approved_date_2)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
          <footer className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button btn-light"
              name="submit"
              onClick={() => {
                this.props.returnCallbackDrawerClose(false);
              }}
            >
              Close
            </button>
            <button
              type="button"
              className="drawer_text drawer_btn_success"
              name="Back"
              onClick={() => {
                this.props.returnEditCallback("OtherExpense");
              }}
            >
              Edit
            </button>
          </footer>
        </div>
      );
    }
  }
}
