import React, { Component } from "react";

import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import api from "../../../api/api";
import {
  TextField,
  Snackbar,
  Radio,
  Switch,
  Typography,
  Grid,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
} from "@material-ui/core";

import { Alert } from "@material-ui/lab";
import { withStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import EventIcon from "@material-ui/icons/Event";
import FileUpload from "../../common/FileUpload";
import { localDateFormate, pad, toLocaleString } from "../../common/common";
import Loader from "../../common/Loader";

const NewRadio = withStyles({
  root: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const PurpleSwitch = withStyles({
  switchBase: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
    "&$checked + $track": {
      backgroundColor: config.themeColor,
    },
  },
  checked: {},
  track: {},
})(Switch);

export default class InsuranceAdd extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      barge_id: null,
      insurance_level: "Barge",
      business_no_id: "",
      voyage_type: "",
      vessel_type: "",
      barge_vessel_name: "",
      imo_no: "",
      year_built: "",
      flag: "",
      grt: "",
      classification: "",
      owners_p_and_i_cover: "",
      charterparty: "",
      delivery_date: null,
      estimated_duration: "",
      time_or_voyage: "",
      cargo: "",
      charterers_p_and_i_liability: "No",
      charterers_fd_and_d_liability: "No",
      back_to_back_sublet: "No",
      charterers_b_l: "No",
      contract_type: "Per Year",
      p_and_i_value: "",
      fd_and_d_value: "",
      exchange_rate: "",
      cost_to_company: 0,
      billing_currency:
        config.company === "SRPL" ||
        config.company === "PRPL" ||
        config.company === "AVS"
          ? "USD"
          : "IDR",
      barges: [],
      currencyData: [],
      other_expenses_data: [],
      activeStep: 0,
      files: [
        {
          file_description: "",
          file_name: "",
          file_extension: "",
          file_content: "",
          feature_name: "Insurance_and_declaration",
        },
      ],
      costing_files: [],
      isLoading: true,
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    let features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (!(features.includes("346") || features.includes("97"))) {
      window.location.href = "/dashboard";
    }
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    await api
      .get_barges_for_insurance_and_declaration(loginUserID, idToken)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState({
                barges: res.barges,
                isLoading: false,
              });

              if (res.barges.length === 0) {
                this.setState({
                  snackBarErrorOpen: true,
                  errorMsg: "No records to display",
                });
              }
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
    // for get_Currencies
    await api.getCurrencies(loginUserID, idToken).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
          if (res.Currency) {
            var currencies = [
              <MenuItem value="0" key={-1} disabled>
                Select Currency
              </MenuItem>,
            ];
            for (var i of res.Currency) {
              currencies.push(
                <MenuItem value={i.currency} key={[i.id]}>
                  {i.currency}
                </MenuItem>
              );
            }
            this.setState({
              currencyData: currencies,
              isLoading: false,
            });
          } else {
            alert("Unexpected error occured. Please contact administrator.");
          }
          if (res.code === "601") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
  }

  submitHandler = () => {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();

    let {
      insurance_level,
      barge_id,
      business_no_id,
      voyage_type,
      vessel_type,
      barge_vessel_name,
      imo_no,
      year_built,
      flag,
      grt,
      classification,
      owners_p_and_i_cover,
      charterparty,
      delivery_date,
      estimated_duration,
      time_or_voyage,
      cargo,
      charterers_p_and_i_liability,
      charterers_fd_and_d_liability,
      back_to_back_sublet,
      charterers_b_l,
      contract_type,
      p_and_i_value,
      fd_and_d_value,
      exchange_rate,
      cost_to_company,
      files,
      costing_files,
    } = this.state;
    /**
     *  Checking Mandatory files
     */
    var missing_fields = 0;
    let files_missing = 0;
    var row = this.state.files;
    for (var obj of row) {
      if (obj.file_type !== "" || obj.file_content !== "") {
        if (obj.file_content !== "") {
          if (obj.file_description === "") {
            missing_fields++;
            obj.file_description_error = true;
          } else {
            obj.file_description = obj.file_description.toUpperCase();
          }
        } else if (obj.file_description !== "") {
          if (obj.file_content === "") {
            files_missing++;
          }
        }
      }
    }
    this.setState({
      files: row,
    });

    if (missing_fields > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please fill mandatory fields.",
      });
      return;
    }
    if (files_missing > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please Select file.",
      });
      return;
    }

    const content_files = files.filter((e) => e.file_content !== "");
    const costing = costing_files.filter(
      (e) => e.file_content !== "" && e.column === "invoice_file"
    );
    api
      .add_insurance_and_declaration(
        loginUserID,
        idToken,
        insurance_level,
        barge_id,
        business_no_id,
        voyage_type,
        vessel_type,
        barge_vessel_name,
        imo_no,
        year_built,
        flag,
        grt,
        classification,
        owners_p_and_i_cover,
        charterparty,
        delivery_date,
        estimated_duration,
        time_or_voyage,
        cargo,
        charterers_p_and_i_liability,
        charterers_fd_and_d_liability,
        back_to_back_sublet,
        charterers_b_l,
        contract_type,
        p_and_i_value,
        fd_and_d_value,
        exchange_rate,
        cost_to_company,
        content_files,
        costing
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState(
                {
                  snackBarSuccessOpen: true,
                  successMsg: res.message,
                },
                () => {
                  this.props.onAddResponse(true);
                }
              );
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  };

  nextStepHandler = () => {
    if (this.state.activeStep === 0) {
      if (this.state.barge_id === null) {
        this.setState({
          snackBarErrorOpen: true,
          errorMsg: "Please select barge.",
        });
        return;
      }
    }

    this.setState({
      activeStep: this.state.activeStep + 1,
    });
  };

  previousStepHandler = () => {
    this.setState({
      activeStep: this.state.activeStep - 1,
    });
  };

  insuranceCalculations = () => {
    let total =
      Number(this.state.p_and_i_value) + Number(this.state.fd_and_d_value);
    let currancy_exchg_rate = this.state.currency_exchg_rate
      ? this.state.currency_exchg_rate
      : 1;
    if (
      (config.company === "SDAM" ||
        config.company === "SRK" ||
        config.company === "BTR") &&
      this.state.billing_currency === "IDR"
    ) {
      currancy_exchg_rate = 1;
    }
    if (
      (config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL") &&
      this.state.billing_currency === "USD"
    ) {
      currancy_exchg_rate = 1;
    }
    let cost_to_company = (Number(total) * Number(currancy_exchg_rate)).toFixed(
      2
    );
    this.setState({
      cost_to_company: cost_to_company,
    });
  };

  render() {
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div className="container bg-white p-0" style={{ minHeight: "100vh" }}>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <div className="row">
            <div className="col-lg-12 p-0 border-bottom">
              <h6 style={{ padding: "15px", marginBottom: 0 }}>
                Insurance & Declaration
              </h6>
            </div>
          </div>

          <div className="drawer-section">
            <div className="row mb-0 stepper">
              <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                <i
                  className={
                    this.state.activeStep > 0
                      ? "fa fa-check-circle"
                      : "fa fa-check-circle-o"
                  }
                  aria-hidden="true"
                  style={{
                    fontSize: 20,
                    color:
                      this.state.activeStep > 0
                        ? "#31c197"
                        : this.state.activeStep === 0
                        ? config.themeColor
                        : "#ccc",
                  }}
                />
                <span
                  className="stepper_text"
                  style={{
                    color:
                      this.state.activeStep > 0
                        ? "#31c197"
                        : this.state.activeStep === 0
                        ? config.themeColor
                        : "#ccc",
                  }}
                >
                  Barge Selection
                </span>
                <i
                  className="fa fa-chevron-right stepper_sub_icon stepper_arrow"
                  aria-hidden="true"
                  style={{
                    color:
                      this.state.activeStep > 0
                        ? "#31c197"
                        : this.state.activeStep === 0
                        ? config.themeColor
                        : "#ccc",
                  }}
                />
              </span>
              <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                <i
                  className={
                    this.state.activeStep > 1
                      ? "fa fa-check-circle"
                      : "fa fa-check-circle-o"
                  }
                  aria-hidden="true"
                  style={{
                    fontSize: 20,
                    color:
                      this.state.activeStep > 1
                        ? "#31c197"
                        : this.state.activeStep === 1
                        ? config.themeColor
                        : "#ccc",
                  }}
                />
                <span
                  className="stepper_text"
                  style={{
                    color:
                      this.state.activeStep > 1
                        ? "#31c197"
                        : this.state.activeStep === 1
                        ? config.themeColor
                        : "#ccc",
                  }}
                >
                  Declaration
                </span>
                <i
                  className="fa fa-chevron-right stepper_sub_icon stepper_arrow"
                  aria-hidden="true"
                  style={{
                    color:
                      this.state.activeStep > 1
                        ? "#31c197"
                        : this.state.activeStep === 1
                        ? config.themeColor
                        : "#ccc",
                  }}
                />
              </span>
            </div>
            {this.state.barges.length > 0 && this.state.activeStep === 0 && (
              <div className="row">
                <div className="table border-0">
                  <table className="table table-borderless border-0">
                    <thead className="border-0">
                      <tr>
                        <th
                          nowrap="true"
                          className="p-0 text-center pt-1 border-0"
                        ></th>
                        <th
                          style={{ fontSize: 16 }}
                          className="table_header_barge_label text-uppercase border-0"
                          nowrap="true"
                        >
                          Barge ID
                        </th>
                        <th
                          style={{ fontSize: 16 }}
                          className="table_header_barge_label text-uppercase border-0"
                          nowrap="true"
                        >
                          Barge Nomination
                        </th>
                        <th
                          style={{ fontSize: 16 }}
                          className="table_header_barge_label text-uppercase border-0"
                          nowrap="true"
                        >
                          Jetty
                        </th>
                        <th
                          style={{ fontSize: 16 }}
                          className="table_header_barge_label text-uppercase border-0"
                          nowrap="true"
                        >
                          Loading Date
                        </th>
                        <th
                          style={{ fontSize: 16 }}
                          className="table_header_barge_label text-uppercase border-0"
                          nowrap="true"
                        >
                          Business No
                        </th>
                        <th
                          style={{ fontSize: 16 }}
                          className="table_header_barge_label text-uppercase border-0 text-right"
                          nowrap="true"
                        >
                          Quantity
                        </th>
                      </tr>
                    </thead>
                    {this.state.barges.map((p, index) => (
                      <tbody key={index}>
                        <tr className="rounded">
                          <td className="p-0 text-center">
                            <NewRadio
                              onClick={() => {
                                this.setState({
                                  barge_id: p.id,
                                });
                              }}
                              checked={p.id === this.state.barge_id}
                              color="primary"
                              size="small"
                            />
                          </td>
                          <td>{pad(p.id)}</td>
                          <td nowrap="true">{p.barge_nomination}</td>
                          <td nowrap="true">{p.loading_jetty}</td>
                          <td nowrap="true">
                            {localDateFormate(p.loading_date)}
                          </td>
                          <td nowrap="true">{p.business_no}</td>
                          <td nowrap="true" className="text-right">
                            {toLocaleString(p.barge_quantity_in_mt)}
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </table>
                </div>
              </div>
            )}

            {this.state.activeStep === 1 && (
              <div className="card p-0">
                <div className="card-body">
                  <div className="p-3 border_dashed  rounded-lg">
                    <div className="row mb-3">
                      <div className="col-lg-12 p-0">
                        <div className="row">
                          <div className="col-lg-6">
                            <label className="form_label mb-0">
                              Voyage Type
                            </label>
                            <TextField
                              name="voyage_type"
                              margin="dense"
                              variant="outlined"
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                              value={this.state.voyage_type}
                              placeholder="Voyage Type"
                              fullWidth
                              onChange={(e) => {
                                this.setState({
                                  voyage_type: e.target.value,
                                });
                              }}
                            />
                          </div>
                          <div className="col-lg-6">
                            <label className="form_label mb-0">
                              Barge & Tug Name
                            </label>
                            <TextField
                              name="barge_vessel_name"
                              margin="dense"
                              variant="outlined"
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                              value={this.state.barge_vessel_name}
                              placeholder="Barge & Tug Name"
                              fullWidth
                              onChange={(e) => {
                                this.setState({
                                  barge_vessel_name: e.target.value,
                                });
                              }}
                            />
                          </div>
                          <div className="col-lg-6">
                            <label className="form_label mb-0">IMO No</label>
                            <TextField
                              name="imo_no"
                              margin="dense"
                              variant="outlined"
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                              value={this.state.imo_no}
                              placeholder="IMO No"
                              fullWidth
                              onChange={(e) => {
                                this.setState({
                                  imo_no: e.target.value,
                                });
                              }}
                            />
                          </div>
                          <div className="col-lg-6">
                            <label className="form_label mb-0">
                              Year Built
                            </label>
                            <TextField
                              name="year_built"
                              margin="dense"
                              variant="outlined"
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                              value={this.state.year_built}
                              placeholder="Year Built"
                              fullWidth
                              onChange={(e) => {
                                this.setState({
                                  year_built: e.target.value,
                                });
                              }}
                            />
                          </div>
                          <div className="col-lg-6">
                            <label className="form_label mb-0">Flag</label>
                            <TextField
                              name="flag"
                              margin="dense"
                              variant="outlined"
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                              value={this.state.flag}
                              placeholder="flag"
                              fullWidth
                              onChange={(e) => {
                                this.setState({
                                  flag: e.target.value,
                                });
                              }}
                            />
                          </div>
                          <div className="col-lg-6">
                            <label className="form_label mb-0">Grt</label>
                            <TextField
                              name="grt"
                              margin="dense"
                              variant="outlined"
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                              value={this.state.grt}
                              placeholder="grt"
                              fullWidth
                              onChange={(e) => {
                                this.setState({
                                  grt: e.target.value,
                                });
                              }}
                            />
                          </div>
                          <div className="col-lg-6">
                            <label className="form_label mb-0">
                              Classification
                            </label>
                            <TextField
                              name="classification"
                              margin="dense"
                              variant="outlined"
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                              value={this.state.classification}
                              placeholder="Classification"
                              fullWidth
                              onChange={(e) => {
                                this.setState({
                                  classification: e.target.value,
                                });
                              }}
                            />
                          </div>
                          <div className="col-lg-6">
                            <label className="form_label mb-0">
                              Owners P&I Cover
                            </label>
                            <TextField
                              name="owners_p_and_i_cover"
                              margin="dense"
                              variant="outlined"
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                              value={this.state.owners_p_and_i_cover}
                              placeholder="Owners P&I Cover"
                              fullWidth
                              onChange={(e) => {
                                this.setState({
                                  owners_p_and_i_cover: e.target.value,
                                });
                              }}
                            />
                          </div>
                          <div className="col-lg-6">
                            <label className="form_label mb-0">
                              Charterparty
                            </label>
                            <TextField
                              name="charterparty"
                              margin="dense"
                              variant="outlined"
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                              value={this.state.charterparty}
                              placeholder="Charterparty"
                              fullWidth
                              onChange={(e) => {
                                this.setState({
                                  charterparty: e.target.value,
                                });
                              }}
                            />
                          </div>
                          <div className="col-lg-6">
                            <label className="form_label mb-0">
                              Delivery Date
                            </label>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <DatePicker
                                autoOk={true}
                                inputVariant="outlined"
                                variant="inline"
                                margin="dense"
                                fullWidth
                                format="dd/MM/yyyy"
                                value={this.state.delivery_date}
                                onChange={(date) => {
                                  this.setState({
                                    delivery_date: date,
                                  });
                                }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <IconButton>
                                        <EventIcon />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                  className: "pl-0",
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          </div>
                          <div className="col-lg-6">
                            <label className="form_label mb-0">
                              Estimated Duration
                            </label>
                            <TextField
                              name="estimated_duration"
                              margin="dense"
                              variant="outlined"
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                              value={this.state.estimated_duration}
                              placeholder="Estimated Duration"
                              fullWidth
                              onChange={(e) => {
                                this.setState({
                                  estimated_duration: e.target.value,
                                });
                              }}
                            />
                          </div>
                          <div className="col-lg-6">
                            <label className="form_label mb-0">
                              Time or Voyage
                            </label>
                            <TextField
                              name="time_or_voyage"
                              margin="dense"
                              variant="outlined"
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                              value={this.state.time_or_voyage}
                              placeholder="Time or Voyage"
                              fullWidth
                              onChange={(e) => {
                                this.setState({
                                  time_or_voyage: e.target.value,
                                });
                              }}
                            />
                          </div>
                          <div className="col-lg-6">
                            <label className="form_label mb-0">Cargo</label>
                            <TextField
                              name="cargo"
                              margin="dense"
                              variant="outlined"
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                              value={this.state.cargo}
                              placeholder="Cargo"
                              fullWidth
                              onChange={(e) => {
                                this.setState({
                                  cargo: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-lg-6">
                        <label className="form_label mb-0">
                          Charterer's P&I Liability
                        </label>
                        <Typography component="div">
                          <Grid
                            component="label"
                            container
                            alignItems="center"
                            spacing={1}
                          >
                            <Grid item>No</Grid>
                            <Grid
                              item
                              style={{
                                position: "relative",
                                top: "3px",
                                paddingLeft: "20px",
                              }}
                            >
                              <FormControlLabel
                                control={
                                  <PurpleSwitch
                                    checked={
                                      this.state
                                        .charterers_p_and_i_liability === "Yes"
                                    }
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        this.setState({
                                          charterers_p_and_i_liability: "Yes",
                                        });
                                      } else {
                                        this.setState({
                                          charterers_p_and_i_liability: "No",
                                        });
                                      }
                                    }}
                                    name="checkedA"
                                  />
                                }
                              />
                            </Grid>
                            <Grid item>Yes</Grid>
                          </Grid>
                        </Typography>
                      </div>
                      <div className="col-lg-6">
                        <label className="form_label mb-0">
                          Charterer's FD&D Liability
                        </label>
                        <Typography component="div">
                          <Grid
                            component="label"
                            container
                            alignItems="center"
                            spacing={1}
                          >
                            <Grid item>No</Grid>
                            <Grid
                              item
                              style={{
                                position: "relative",
                                top: "3px",
                                paddingLeft: "20px",
                              }}
                            >
                              <FormControlLabel
                                control={
                                  <PurpleSwitch
                                    checked={
                                      this.state
                                        .charterers_fd_and_d_liability === "Yes"
                                    }
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        this.setState({
                                          charterers_fd_and_d_liability: "Yes",
                                        });
                                      } else {
                                        this.setState({
                                          charterers_fd_and_d_liability: "No",
                                        });
                                      }
                                    }}
                                    name="checkedA"
                                  />
                                }
                              />
                            </Grid>
                            <Grid item>Yes</Grid>
                          </Grid>
                        </Typography>
                      </div>
                      <div className="col-lg-6">
                        <label className="form_label mb-0">
                          Back to Back Sublet
                        </label>
                        <Typography component="div">
                          <Grid
                            component="label"
                            container
                            alignItems="center"
                            spacing={1}
                          >
                            <Grid item>No</Grid>
                            <Grid
                              item
                              style={{
                                position: "relative",
                                top: "3px",
                                paddingLeft: "20px",
                              }}
                            >
                              <FormControlLabel
                                control={
                                  <PurpleSwitch
                                    checked={
                                      this.state.back_to_back_sublet === "Yes"
                                    }
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        this.setState({
                                          back_to_back_sublet: "Yes",
                                        });
                                      } else {
                                        this.setState({
                                          back_to_back_sublet: "No",
                                        });
                                      }
                                    }}
                                    name="checkedA"
                                  />
                                }
                              />
                            </Grid>
                            <Grid item>Yes</Grid>
                          </Grid>
                        </Typography>
                      </div>
                      <div className="col-lg-6">
                        <label className="form_label mb-0">
                          Charterer's B/L
                        </label>
                        <Typography component="div">
                          <Grid
                            component="label"
                            container
                            alignItems="center"
                            spacing={1}
                          >
                            <Grid item>No</Grid>
                            <Grid
                              item
                              style={{
                                position: "relative",
                                top: "3px",
                                paddingLeft: "20px",
                              }}
                            >
                              <FormControlLabel
                                control={
                                  <PurpleSwitch
                                    checked={
                                      this.state.charterers_b_l === "Yes"
                                    }
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        this.setState({
                                          charterers_b_l: "Yes",
                                        });
                                      } else {
                                        this.setState({
                                          charterers_b_l: "No",
                                        });
                                      }
                                    }}
                                    name="checkedA"
                                  />
                                }
                              />
                            </Grid>
                            <Grid item>Yes</Grid>
                          </Grid>
                        </Typography>
                      </div>
                      <div className="col-lg-12">
                        <label className="form_label mb-0">Contract Type</label>
                        <Typography component="div">
                          <Grid
                            component="label"
                            container
                            alignItems="center"
                            spacing={1}
                          >
                            <Grid item>Per Year</Grid>
                            <Grid
                              item
                              style={{
                                position: "relative",
                                top: "3px",
                                paddingLeft: "20px",
                              }}
                            >
                              <FormControlLabel
                                control={
                                  <PurpleSwitch
                                    checked={
                                      this.state.contract_type === "Per Barge"
                                    }
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        this.setState({
                                          contract_type: "Per Barge",
                                        });
                                      } else {
                                        this.setState({
                                          contract_type: "Per Year",
                                        });
                                      }
                                    }}
                                    name="checkedA"
                                  />
                                }
                              />
                            </Grid>
                            <Grid item>Per Barge</Grid>
                          </Grid>
                        </Typography>
                      </div>
                      {this.state.contract_type === "Per Barge" && (
                        <div className="col-lg-6">
                          <label className="form_label mb-0">P&I Value</label>
                          <TextField
                            name="p_and_i_value"
                            margin="dense"
                            variant="outlined"
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                            value={this.state.p_and_i_value}
                            placeholder="Enter Value"
                            fullWidth
                            onChange={(e) => {
                              const pr = /^\d*(\.\d{0,4})?$/;
                              if (
                                e.target.value === "" ||
                                pr.test(e.target.value)
                              ) {
                                this.setState(
                                  {
                                    p_and_i_value: e.target.value,
                                  },
                                  () => this.insuranceCalculations()
                                );
                              }
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                  className="adornment_background"
                                >
                                  USD
                                </InputAdornment>
                              ),
                              style: {
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                padding: 0,
                              },
                            }}
                          />
                        </div>
                      )}
                      {this.state.contract_type === "Per Barge" && (
                        <div className="col-lg-6">
                          <label className="form_label mb-0">FD&D Value</label>
                          <TextField
                            name="fd_and_d_value"
                            margin="dense"
                            variant="outlined"
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                            value={this.state.fd_and_d_value}
                            placeholder="Enter Value"
                            fullWidth
                            onChange={(e) => {
                              const pr = /^\d*(\.\d{0,4})?$/;
                              if (
                                e.target.value === "" ||
                                pr.test(e.target.value)
                              ) {
                                this.setState(
                                  {
                                    fd_and_d_value: e.target.value,
                                  },
                                  () => this.insuranceCalculations()
                                );
                              }
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                  className="adornment_background"
                                >
                                  USD
                                </InputAdornment>
                              ),
                              style: {
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                padding: 0,
                              },
                            }}
                          />
                        </div>
                      )}
                      {this.state.contract_type === "Per Barge" && (
                        <div className="col-lg-6">
                          <label className="form_label mb-0">Currency</label>
                          <TextField
                            name="billing_currency"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            select
                            InputProps={{
                              style: {
                                fontFamily: "Poppins",
                                fontSize: "14px",
                              },
                              disableUnderline: true,
                            }}
                            value={this.state.billing_currency}
                            onChange={(e) => {
                              this.setState(
                                {
                                  billing_currency: e.target.value,
                                },
                                () => this.insuranceCalculations()
                              );
                            }}
                          >
                            {this.state.currencyData}
                          </TextField>
                        </div>
                      )}
                      {!(
                        config.company === "SRPL" ||
                        config.company === "AVS" ||
                        config.company === "PRPL"
                      ) &&
                        this.state.contract_type === "Per Barge" &&
                        this.state.billing_currency !== "IDR" &&
                        this.state.billing_currency !== "0" && (
                          <div className="col-lg-6">
                            <label className="form_label mb-0">
                              Exchange Rate {this.state.billing_currency} to IDR
                            </label>
                            <TextField
                              name="exchange_rate"
                              margin="dense"
                              variant="outlined"
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                              value={this.state.exchange_rate}
                              placeholder="Enter Value"
                              fullWidth
                              onChange={(e) => {
                                const pr = /^\d*(\.\d{0,4})?$/;
                                if (
                                  e.target.value === "" ||
                                  pr.test(e.target.value)
                                ) {
                                  var value = e.target.value;
                                  if (value === null || value === "") {
                                    value = 1;
                                  }
                                  if (
                                    config.company === "SRPL" ||
                                    config.company === "AVS" ||
                                    config.company === "PRPL"
                                  ) {
                                    value = 1 / Number(value);
                                  }
                                  this.setState(
                                    {
                                      exchange_rate: e.target.value,
                                      currency_exchg_rate: value,
                                    },
                                    () => this.insuranceCalculations()
                                  );
                                }
                              }}
                            />
                          </div>
                        )}
                      {(config.company === "SRPL" ||
                        config.company === "AVS" ||
                        config.company === "PRPL") &&
                        this.state.contract_type === "Per Barge" &&
                        this.state.billing_currency !== "USD" &&
                        this.state.billing_currency !== "0" && (
                          <div className="col-lg-6">
                            <label className="form_label mb-0">
                              Exchange Rate USD to {this.state.billing_currency}
                            </label>
                            <TextField
                              name="exchange_rate"
                              margin="dense"
                              variant="outlined"
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                              value={this.state.exchange_rate}
                              placeholder="Enter Value"
                              fullWidth
                              onChange={(e) => {
                                const pr = /^\d*(\.\d{0,4})?$/;
                                if (
                                  e.target.value === "" ||
                                  pr.test(e.target.value)
                                ) {
                                  var value = e.target.value;
                                  if (value === null || value === "") {
                                    value = 1;
                                  }
                                  if (
                                    config.company === "SRPL" ||
                                    config.company === "AVS" ||
                                    config.company === "PRPL"
                                  ) {
                                    value = 1 / Number(value);
                                  }
                                  this.setState(
                                    {
                                      exchange_rate: e.target.value,
                                      currency_exchg_rate: value,
                                    },
                                    () => this.insuranceCalculations()
                                  );
                                }
                              }}
                            />
                          </div>
                        )}
                      {this.state.contract_type === "Per Barge" && (
                        <div className="col-lg-6">
                          <label className="form_label mb-2">
                            Cost to Company
                          </label>
                          <div>
                            {toLocaleString(this.state.cost_to_company)}

                            {this.state.billing_currency !== "0"
                              ? this.state.billing_currency
                              : null}
                          </div>
                        </div>
                      )}
                    </div>
                    <hr />
                    {/* {(this.state.features.includes('362')  || this.state.features.includes('97') ) && */}

                    <FileUpload
                      files={this.state.files}
                      type="Costings"
                      feature_name="Insurance_and_declaration"
                      callbackFn={(data, mandatoryFiles) => {
                        this.setState({
                          files: data,
                          costing_files: mandatoryFiles,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>

          <footer className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button"
              name="previous"
              onClick={
                this.state.activeStep === 0
                  ? () => this.props.returnCallbackDrawerClose(false)
                  : this.previousStepHandler
              }
            >
              {this.state.activeStep === 0 ? "CANCEL" : "PREVIOUS"}
            </button>
            <button
              type="button"
              className="btn next_button"
              name="next"
              onClick={
                this.state.activeStep === 1
                  ? this.submitHandler
                  : this.nextStepHandler
              }
            >
              {this.state.activeStep === 1 ? "SUBMIT" : "NEXT STEP"}
            </button>
          </footer>
        </div>
      );
    }
  }
}
