import React, { Component } from "react";

import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import api from "../../../api/api";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { localDateFormate, toLocaleString } from "../../common/common";
import ViewFiles from "../../common/ViewFiles";

import InvoiceForm from "../../common/InvoiceForm";
import PaymentPostings from "../../common/PaymentPostings";
import Loader from "../../common/Loader";

import AuditInfo from "../../common/AuditInfo";
import InvoiceLink from "../../InvoicePdf/InvoiceLink";

export default class Mv_Floating_Crane_Charges_View extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      openDrawer: false,
      openUpdateDrawer: false,
      status: "",
      // Payment Postings
      stevedor_price_payments: [],
      floating_crane_vendor: [
        {
          floating_crane_vendor_id: "",
          floating_crane_vendor_name: "",
          costing_no: "",
          invoice_no: "",
          invoice_date: "",

          floating_crane_price_pmt: "",
          currency_exchange_rate: "",
          local_currency: "",
          vat_applicability: "",
          floating_crane_base_price: "",
          floating_crane_vat_percent: 10,
          floating_crane_vat_value: "",
          floating_crane_total: "",
          floating_crane_pph23_percent: 2,
          floating_crane_pph23_value: "",
          floating_crane_payable_to_vendor: "",
          floating_crane_cost_to_company: "",

          floating_crane_price_per_addl_day: "",
          floating_crane_addl_days: "",
          floating_crane_addl_base_price: "",
          floating_crane_addl_vat_percent: 10,
          floating_crane_addl_vat_value: "",
          floating_crane_addl_pph23_percent: 2,
          floating_crane_addl_pph23_value: "",
          floating_crane_addl_total: "",
          floating_crane_addl_payable_to_vendor: "",
          floating_crane_addl_cost_to_company: "",

          pnbp_per_mt: "",
          pnbp: "",
          pnbp_company_to_billing: "",
          jasa_pbm_calculation: "",
          jasa_pbm_pmt: "",
          jasa_pbm_value: "",
          jasa_pbm_company_to_billing: "",
          status: "",
          wait_period_people: "",
          wait_period_hours: "",
          wait_period_price_per_hour: "",
          wait_period_total_price: "",

          total_payable_to_vendor: "",
          others: [
            {
              mv_floating_crane_id: "90",
              price: "",
              remarks: "",
              status: "",
              updated_by: "",
              updated_date: "",
            },
          ],

          floating_crane_charges: [
            {
              floating_crane_type: "Floating Crane",
              floating_crane_vendor_id: "",
              // floating_crane_vendor_name: "",
              quantity: "",
              billing_currency: "0",

              // floating_crane_price_pmt: "",
              // currency_exchange_rate: "",
              // local_currency: "",
              // vat_applicability: "",
              // floating_crane_base_price: "",
              // floating_crane_vat_percent: 10,
              // floating_crane_vat_value: "",
              // floating_crane_total: "",
              // floating_crane_pph23_percent: 2,
              // floating_crane_pph23_value: "",
              // floating_crane_payable_to_vendor: "",
              // floating_crane_cost_to_company: "",

              // floating_crane_price_per_addl_day: "",
              // floating_crane_addl_days: "",
              // floating_crane_addl_base_price: "",
              // floating_crane_addl_vat_percent: 10,
              // floating_crane_addl_vat_value: "",
              // floating_crane_addl_pph23_percent: 2,
              // floating_crane_addl_pph23_value: "",
              // floating_crane_addl_total: "",
              // floating_crane_addl_payable_to_vendor: "",
              // floating_crane_addl_cost_to_company: "",

              // pnbp_per_mt: "",
              // pnbp: "",
              // pnbp_company_to_billing: "",
              // jasa_pbm_calculation: "",
              // jasa_pbm_pmt: "",
              // jasa_pbm_value: "",
              // jasa_pbm_company_to_billing: "",
              // status: "",
              // wait_period_people: "",
              // wait_period_hours: "",
              // wait_period_price_per_hour: "",
              // wait_period_total_price: "",
              // others: [],
            },
          ],
        },
      ],
      isLoading: true,
      features: [],
      payment_due_date: null,
      invoiceDialog: false,
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (
      !(
        features.includes("259") ||
        features.includes("423") ||
        features.includes("474") ||
        features.includes("97")
      )
    ) {
      window.location.href = "/dashboard";
    }
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const businessNoID = this.props.businessNoID;
    await api
      .getBusinessNumber(businessNoID, loginUserID, idToken)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.business_number) {
                this.setState({
                  businessNo: res.business_number.business_no
                    ? res.business_number.business_no
                    : "",
                  mother_vessel_name: res.business_number.mother_vessel_name
                    ? res.business_number.mother_vessel_name
                    : "",
                  //isLoading: false
                });
              }
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                snackBarErrorOpen: true,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });

    const floatingCraneID = this.props.floatingCraneID;
    let floating_crane_vendor_data = [];
    await api
      .getNewFloatingCraneCharges(loginUserID, idToken, floatingCraneID)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.floating_crane_vendor) {
              floating_crane_vendor_data = res.floating_crane_vendor;
              // this.setState({
              //   floating_crane_vendor: res.floating_crane_vendor
              // });
            }
          });
        }
      });

    await api
      .get_mv_pur_fin_floating_crane_charges(loginUserID, idToken, businessNoID)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.floating_crane_vendor) {
                var row = floating_crane_vendor_data;
                //var remaining_amount = 0;
                var total_payable_to_vendor = 0;
                var total_paid_to_vendor = 0;
                for (var i = 0; i < row.length; i++) {
                  total_payable_to_vendor += Number(
                    row[i].floating_crane_payable_to_vendor
                  );
                  total_payable_to_vendor += Number(
                    row[i].floating_crane_addl_payable_to_vendor
                  );
                  total_payable_to_vendor += Number(row[i].pnbp);
                  total_payable_to_vendor += Number(row[i].jasa_pbm_value);
                  total_payable_to_vendor += Number(
                    row[i].wait_period_total_price
                  );
                  for (var k of row[i].others) {
                    total_payable_to_vendor += Number(k.price);
                  }
                  if (
                    ((config.company === "SRPL" ||
                      config.company === "AVS" ||
                      config.company === "PRPL") &&
                      row[i].billing_currency === "IDR") ||
                    ((config.company === "SDAM" ||
                      config.company === "SRK" ||
                      config.company === "BTR") &&
                      row[i].billing_currency === "USD")
                  ) {
                    row[i].pnbp_company_to_billing = (
                      Number(row[i].quantity) * Number(row[i].pnbp_per_mt)
                    ).toFixed(2);
                    row[i].jasa_pbm_company_to_billing = (
                      (Number(row[i].quantity) * Number(row[i].jasa_pbm_pmt)) /
                      100
                    ).toFixed(2);

                    //total_payable_to_vendor += (Number(row[i].floating_crane_charges[j].quantity ? row[i].floating_crane_charges[j].quantity : 1) * Number(row[i].floating_crane_charges[j].pnbp_per_mt ? row[i].floating_crane_charges[j].pnbp_per_mt : 1)).toFixed(2);
                    //total_payable_to_vendor += ((Number(row[i].floating_crane_charges[j].quantity ? row[i].floating_crane_charges[j].quantity : 1) * Number(row[i].floating_crane_charges[j].jasa_pbm_pmt ? row[i].floating_crane_charges[j].jasa_pbm_pmt : 1)) / 100).toFixed(2);
                  } else {
                    row[i].pnbp_company_to_billing = "";
                    row[i].jasa_pbm_company_to_billing = "";

                    //total_payable_to_vendor += (Number(row[i].floating_crane_charges[j].pnbp));
                    //total_payable_to_vendor += (Number(row[i].floating_crane_charges[j].jasa_pbm_value));
                  }

                  for (var p of row[i].floating_crane_charges_payments) {
                    if (p.date_of_payment) {
                      total_paid_to_vendor += Number(p.amount);
                    }
                  }
                  row[i].total_payable_to_vendor = total_payable_to_vendor;
                  row[i].total_paid_to_vendor = total_paid_to_vendor;
                  row[i].remaining_amount =
                    Number(total_payable_to_vendor) -
                    Number(total_paid_to_vendor);
                  total_paid_to_vendor = 0;
                }
                this.setState({
                  floating_crane_vendor: row,
                  isLoading: false,
                });
              }
              if (res.floating_crane_vendor.length === 0) {
                this.setState({
                  errorMsg: "No floating crane charges available.",
                  snackBarErrorOpen: true,
                  isLoading: false,
                });
                setTimeout(
                  () => this.props.returnCallbackDrawerClose(false),
                  2000
                );
              }
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                snackBarErrorOpen: true,
              });
            }
          });
        }
      });
  }

  approveHandler = () => {
    this.setState({ isLoading: true });
    let request = {
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
      costing_id: this.props.floatingCraneID,
      costing_type: "MV Flaoting Crane",
      approval_status: "Approved",
    };
    api.approve_costing_api(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.setState(
              {
                snackBarSuccessOpen: true,
                successMsg: res.message,
              },
              () => this.componentDidMount()
            );
          } else if (res.code === "601") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
  };

  approvePaymentsHandler = (id) => {
    this.setState({ isLoading: true });
    let request = {
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
      payment_id: id,
      costing_type: "mv_floating_crane_charges_payment_postings_data",
      approval_status: "Approved",
    };
    api.approve_payment_postings_api(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.setState(
              {
                snackBarSuccessOpen: true,
                successMsg: res.message,
              },
              () => this.componentDidMount()
            );
          } else if (res.code === "601") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
  };

  updateInvoice = (data) => {
    let request = {
      ...data,
      table_name: "mv_pur_fin_floating_crane_charges",
    };
    api.update_invoice_details(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.componentDidMount();
            this.setState({
              invoiceDialog: false,
            });
          }
        });
      }
    });
  };
  renderPaymentPostings = (data) => {
    const {
      id,
      floating_crane_charges_payments = [],
      invoice_no = null,
      remaining_amount = 0,
      currency_exchange_rate = 1,
      approval_status = "Pending",
    } = data;
    let payment_related_data = {
      paymentTitle: "Floating Crane Vendor",
      payment_posting_type: "Floating_Vendor",
      invoice_no: invoice_no,
      outstanding_amount: remaining_amount,
      billing_currency:
        config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL"
          ? "USD"
          : "IDR",
      currency_xchg_rate: currency_exchange_rate ? currency_exchange_rate : 1,
      costing_id: id,
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
    };

    let access_details = {
      costing_approval: approval_status,
      add_payment: "262",
      view_payment: "263",
      update_payment: "264",
      delete_payment: "265",
      approver: "424",
      approver2: "475",
    };

    return (
      <PaymentPostings
        access_details={access_details}
        payments={floating_crane_charges_payments}
        costingType="MV Floating Crane"
        data={payment_related_data}
        callbackFn={() => {
          this.componentDidMount();
        }}
      />
    );
  };

  renderInvoiceLink = () => {
    let invoiceObject = {};

    for (var i of this.state.floating_crane_vendor) {
      invoiceObject["costing_id"] = i.costing_no;
      invoiceObject["to"] = i.floating_crane_vendor_name;
      invoiceObject["business_no"] = this.state.mother_vessel_name;
      invoiceObject["costing_name"] = "MV Floating Crane Charges";
      invoiceObject["invoice_no"] = i.invoice_no;
      invoiceObject["invoice_date"] = localDateFormate(i.invoice_date);
      invoiceObject["bargeDetails"] = [];
      invoiceObject["billing_currency"] =
        config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL"
          ? "USD"
          : "IDR";
      invoiceObject["total_payable_amount"] = i.total_payable_to_vendor;
      invoiceObject["purchase_type"] = "FOB Barge";
      invoiceObject["description"] = [
        {
          qty: toLocaleString(i.quantity),
          adjustedPrice: toLocaleString(i.floating_crane_price_pmt),
          desc: `${
            i.currency_exchange_rate > 1
              ? `Exchange rate @ ${i.currency_exchange_rate}`
              : ""
          }`,
          // amount: i.stevedore_total,
          amount: i.floating_crane_base_price,
        },
        {
          qty: toLocaleString(i.floating_crane_addl_days),
          adjustedPrice: toLocaleString(i.floating_crane_price_per_addl_day),
          desc: `Additional Charges`,
          amount: i.floating_crane_addl_base_price,
        },

        {
          qty: toLocaleString(i.wait_period_people),
          adjustedPrice: toLocaleString(i.wait_period_price_per_hour),
          desc: `Waiting Period`,
          amount: i.wait_period_total_price,
        },
        ...(i.others.length > 0
          ? i.others?.map((e, idx) => ({
              qty: " ",
              adjustedPrice: " ",
              desc: `Other Charges -  ${e.description}`,
              amount: `${toLocaleString(e.price ? e.price : 0)}`,
            }))
          : []),
        {
          qty: " ",
          adjustedPrice: " ",
          desc: "VAT",
          amount: `${toLocaleString(
            parseFloat(
              i.floating_crane_vat_value ? i.floating_crane_vat_value : 0
            ) +
              parseFloat(
                i.floating_crane_addl_vat_value
                  ? i.floating_crane_addl_vat_value
                  : 0
              )
          )}`,
        },
        ...(!(
          config.company === "SRPL" ||
          config.company === "AVS" ||
          config.company === "PRPL"
        )
          ? [
              {
                qty: " ",
                adjustedPrice: " ",
                desc: "PPH",
                amount: `( ${toLocaleString(
                  parseFloat(
                    i.floating_crane_addl_pph23_value
                      ? i.floating_crane_addl_pph23_value
                      : 0
                  ) +
                    parseFloat(
                      i.floating_crane_pph23_value
                        ? i.floating_crane_pph23_value
                        : 0
                    )
                )} )`,
              },
            ]
          : []),
      ];
    }
    return (
      <InvoiceLink data={invoiceObject} fileName={invoiceObject?.costing_id} />
    );
  };

  render() {
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div
          className="container bg-white p-0"
          style={{ minHeight: "100vh", width: 850 }}
        >
          {this.state.floating_crane_vendor.map((e, idx) => (
            <div className="clearfix content-header border-bottom" key={e.id}>
              <div className="row">
                <div className="col-sm text-left pl-0">
                  <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                    {`Floating Crane Charges - ${e.costing_no}`} &nbsp;
                    <span
                      className={
                        e.approval_status === "Pending" ? "type_2" : "type_1"
                      }
                      style={{ padding: 8, marginLeft: 16 }}
                    >
                      {e.approval_status}
                    </span>
                  </h4>
                </div>
                {e.approval_status === "Pending" &&
                  e.invoice_file &&
                  this.Cookie.getCookie("loginUserId") !== e.created_by &&
                  e.approved_by !== this.Cookie.getCookie("loginUserId") &&
                  (this.state.features.includes("423") ||
                    this.state.features.includes("474") ||
                    this.state.features.includes("97")) && (
                    <div
                      className="col text-right float-sm-left"
                      style={{ padding: "5px 20px" }}
                    >
                      <button
                        className="header_button header_button_text add_button_adjustment"
                        style={{ width: "auto", marginTop: "17px" }}
                        onClick={this.approveHandler}
                      >
                        Approve
                      </button>
                    </div>
                  )}
                <div className="col-lg-1 pl-0 float-right">
                  {this.renderInvoiceLink()}
                </div>
              </div>
            </div>
          ))}
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <div className="drawer-section">
            <div className="card">
              <div
                className="card-body p-0"
                style={{ backgroundColor: "rgba(25,118,210,.01)" }}
                id="accordion"
              >
                {this.state.floating_crane_vendor.map((e, idx) => (
                  <>
                    <div className="col-lg-12 mb-2 p-0" key={idx}>
                      <div className="table-responsive">
                        <table className="table table-bordered table-sm">
                          <thead className="table-header">
                            <tr>
                              <th className="text-center">Description</th>
                              <th className="text-right">Qty</th>
                              <th className="text-right">Unit Price</th>
                              <th className="text-right">Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <div>{`VAT Applicability : ${e.vat_applicability}`}</div>
                                <div>{`${
                                  e.currency_exchange_rate > 1
                                    ? `Exchange Rate @ ${toLocaleString(
                                        e.currency_exchange_rate
                                      )}`
                                    : ``
                                }`}</div>
                              </td>
                              <td className="text-right">
                                {" "}
                                {toLocaleString(e.quantity)}
                              </td>
                              <td className="text-right">
                                {" "}
                                {`${toLocaleString(
                                  e.floating_crane_price_pmt
                                )} ${e.billing_currency}`}
                              </td>
                              <td className="text-right">
                                {" "}
                                {toLocaleString(e.floating_crane_base_price)}
                              </td>
                            </tr>
                            {/* <tr>
                              <td>{`VAT @ ${e.floating_crane_vat_percent} `}</td>
                              <td className="text-right"></td>
                              <td className="text-right"></td>
                              <td className="text-right">
                                {toLocaleString(e.floating_crane_vat_value)}
                              </td>
                            </tr> */}
                            {/* {!(
                              config.company === "SRPL" ||
                              config.company === "PRPL" ||
                              config.company === "AVS"
                            ) && (
                              <tr>
                                <td>{`PPH23 @ 2 %`}</td>
                                <td className="text-right"></td>
                                <td className="text-right"></td>
                                <td className="text-right">{`( ${toLocaleString(
                                  e.floating_crane_pph23_value
                                )} )`}</td>
                              </tr>
                            )} */}
                            <tr>
                              <td>
                                <div>{`Additional Charges`}</div>
                                <div>{`VAT Applicability : ${e.addl_day_vat_applicability}`}</div>
                              </td>
                              <td className="text-right">
                                {" "}
                                {toLocaleString(e.floating_crane_addl_days)}
                              </td>
                              <td className="text-right">
                                {" "}
                                {`${toLocaleString(
                                  e.floating_crane_price_per_addl_day
                                )} ${e.billing_currency}`}
                              </td>
                              <td className="text-right">
                                {" "}
                                {toLocaleString(
                                  e.floating_crane_addl_base_price
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>{`VAT @ ${e.floating_crane_addl_vat_percent} `}</td>
                              <td className="text-right"></td>
                              <td className="text-right"></td>
                              <td className="text-right">
                                {toLocaleString(
                                  parseFloat(
                                    e.floating_crane_vat_value
                                      ? e.floating_crane_vat_value
                                      : 0
                                  ) +
                                    parseFloat(
                                      e.floating_crane_addl_vat_value
                                        ? e.floating_crane_addl_vat_value
                                        : 0
                                    )
                                )}
                              </td>
                            </tr>
                            {!(
                              config.company === "SRPL" ||
                              config.company === "PRPL" ||
                              config.company === "AVS"
                            ) && (
                              <tr>
                                <td>{`PPH23 @ 2 %`}</td>
                                <td className="text-right"></td>
                                <td className="text-right"></td>
                                <td className="text-right">{`( ${toLocaleString(
                                  parseFloat(
                                    e.floating_crane_pph23_value
                                      ? e.floating_crane_pph23_value
                                      : 0
                                  ) +
                                    parseFloat(
                                      e.floating_crane_addl_pph23_value
                                        ? e.floating_crane_addl_pph23_value
                                        : 0
                                    )
                                )} )`}</td>
                              </tr>
                            )}
                            {e.wait_period_total_price && (
                              <tr>
                                <td>
                                  <div>{`Waiting Period`}</div>
                                </td>
                                <td className="text-right">
                                  {toLocaleString(e.wait_period_hours)}
                                </td>
                                <td className="text-right">
                                  {" "}
                                  {`${toLocaleString(
                                    e.wait_period_price_per_hour
                                  )} ${e.billing_currency}`}
                                </td>
                                <td className="text-right">
                                  {" "}
                                  {toLocaleString(
                                    e.wait_period_total_price
                                  )}{" "}
                                </td>
                              </tr>
                            )}
                            {e.others?.length > 0 && (
                              <>
                                <tr>
                                  <td>
                                    {e.others?.map((obj) => (
                                      <div>
                                        Other Charges - {obj.description}
                                      </div>
                                    ))}
                                  </td>
                                  <td className="text-right"></td>
                                  <td className="text-right"></td>
                                  <td className="text-right">{`${toLocaleString(
                                    e.others?.reduce(
                                      (acc, item) =>
                                        Number(acc ? acc : 0) +
                                        Number(item.price ? item.price : 0),
                                      0
                                    )
                                  )}`}</td>
                                </tr>
                              </>
                            )}
                            <tr>
                              <td
                                colSpan={3}
                                className="text-right "
                                style={{ fontWeight: "bold" }}
                              >
                                {` Payable to ${e.floating_crane_vendor_name}`}
                              </td>
                              <td
                                className="text-right"
                                style={{ fontWeight: "bold" }}
                              >{`${toLocaleString(e.total_payable_to_vendor)} ${
                                config.company === "SRPL" ||
                                config.company === "PRPL" ||
                                config.company === "AVS"
                                  ? "USD"
                                  : "IDR"
                              }`}</td>
                            </tr>
                            <tr>
                              <td
                                colSpan={3}
                                className="text-right "
                                style={{ fontWeight: "bold" }}
                              >
                                {" "}
                                Paid
                              </td>
                              <td
                                className="text-right "
                                style={{ fontWeight: "bold" }}
                              >
                                {toLocaleString(e.total_paid_to_vendor)}
                              </td>
                            </tr>
                            <tr>
                              <td
                                colSpan={3}
                                className="text-right "
                                style={{ fontWeight: "bold" }}
                              >
                                {" "}
                                Remaing Amount
                              </td>
                              <td
                                className="text-right "
                                style={{ fontWeight: "bold" }}
                              >
                                {toLocaleString(e.remaining_amount)}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      {/* <div className="table-responsive p-2">
                        <table className="table table-sm table-bordered mb-0">
                          <thead className="table-header">
                            <tr>
                              <th className="text-center">Description</th>
                              <th className="text-right">Qty</th>
                              <th className="text-right">Unit Price</th>
                              <th className="text-right">Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            <>
                              <tr>
                                <td>
                                  MV Floating Crane Charges
                                  {e.currency_exchange_rate > 1 &&
                                    (config.company === "SDAM" ||
                                      config.company === "SRK" ||
                                      config.company === "BTR") &&
                                    e.currency_exchange_rate !== "IDR" && (
                                      <div>
                                        Exchange Rate
                                        {parseFloat(
                                          e.currency_exchange_rate
                                        ).toFixed(2)}
                                      </div>
                                    )}
                                  <div>
                                    Type:{" "}
                                    {e.floating_crane_type
                                      ? e.floating_crane_type
                                      : "_"}
                                  </div>
                                </td>
                                <td className="text-right">
                                  {toLocaleString(e.quantity)}
                                </td>
                                <td className="text-right">
                                  {toLocaleString(e.floating_crane_price_pmt)}
                                </td>
                                <td className="text-right">
                                  {toLocaleString(e.floating_crane_base_price)}
                                </td>
                              </tr>
                              {(e.vat_applicability === "Inclusive" ||
                                config.company !== "SRPL" ||
                                config.company !== "PRPL" ||
                                config.company !== "AVS") &&
                                e.floating_crane_vat_value && (
                                  <tr>
                                    <td>
                                      VAT @{" "}
                                      {toLocaleString(
                                        e.floating_crane_vat_percent
                                      )}{" "}
                                      %
                                    </td>
                                    <td className="text-right"></td>
                                    <td className="text-right"></td>
                                    <td className="text-right">
                                      {toLocaleString(
                                        e.floating_crane_vat_value
                                      )}
                                    </td>
                                  </tr>
                                )}
                              {!(
                                config.company === "SRPL" ||
                                config.company === "AVS" ||
                                config.company === "PRPL"
                              ) &&
                                e.floating_crane_pph23_value && (
                                  <tr>
                                    <td>PPH23 @2% </td>
                                    <td></td>
                                    <td></td>
                                    <td className="text-right">
                                      (
                                      {toLocaleString(
                                        e.floating_crane_pph23_value
                                      )}
                                      )
                                    </td>
                                  </tr>
                                )}
                              <tr>
                                <th colSpan={3} className="text-right ">
                                  {" "}
                                  Payable to Vendor :{" "}
                                </th>
                                <th className="text-right ">
                                  {`${
                                    config.company === "SRPL" ||
                                    config.company === "AVS" ||
                                    config.company === "PRPL"
                                      ? "USD"
                                      : "IDR"
                                  }
                                    ${parseFloat(
                                      e.floating_crane_payable_to_vendor
                                    ).toFixed(2)}`}
                                </th>
                              </tr>
                            </>
                          </tbody>
                        </table>
                      </div> */}

                      {/* <div className="row">
                        <div className="col-lg-12 border_dashed rounded-lg p-2 mb-2">
                          {/* <div className="row mb-3 "> *
                          {/* <div className="row col-lg-12 p-0">
                              <div className="col-lg-4 mb-3">
                                <label className="contract_display_header_label">
                                  Approval{" "}
                                  {e.approval_status === "Pending"
                                    ? ""
                                    : "Approved By"}
                                </label>
                                <div className="contract_display_header_value">
                                  {" "}
                                  {e.approval_status === "Pending"
                                    ? e.approval_status
                                    : e.approved_by_name}
                                </div>
                              </div>

                              <div className="col-lg-4 mb-3">
                                <label className="contract_display_header_label">
                                  Approved On
                                </label>
                                <div className="contract_display_header_value">
                                  {" "}
                                  {e.approved_date
                                    ? localDateFormate(e.approved_date)
                                    : "-"}
                                </div>
                              </div>

                              <hr />
                            </div> *

                          {/* <div className="col-lg-6 pl-0">
                              <div className="row">
                                <div className="col-lg-6  mb-3">
                                  <label className="contract_display_header_label">
                                    Type
                                  </label>
                                  <div className="contract_display_header_value">
                                    {e.floating_crane_type
                                      ? e.floating_crane_type
                                      : "-"}
                                  </div>
                                </div>
                                <div className="col-lg-6 mb-3">
                                  <label className="contract_display_header_label">
                                    Quantity in MT
                                  </label>
                                  <div className="contract_display_header_value">
                                    {toLocaleString(e.quantity)}
                                  </div>
                                </div>

                                <div className="col-lg-6 mb-3">
                                  <label className="contract_display_header_label">
                                    Billing Currency
                                  </label>
                                  <div className="contract_display_header_value">
                                    {e.billing_currency
                                      ? e.billing_currency
                                      : "-"}
                                  </div>
                                </div>

                                <div className="col-lg-6 mb-3 pr-0">
                                  <label className="contract_display_header_label">
                                    Price per MT
                                  </label>
                                  <div className="contract_display_header_value">
                                    {toLocaleString(e.floating_crane_price_pmt)}
                                    {e.billing_currency !== "0"
                                      ? e.billing_currency
                                      : ""}
                                  </div>
                                </div>
                                {(config.company === "SRPL" ||
                                  config.company === "AVS" ||
                                  config.company === "PRPL") &&
                                  e.billing_currency !== "USD" &&
                                  e.billing_currency && (
                                    <div className="col-lg-6 mb-3">
                                      <label className="contract_display_header_label">
                                        Exchange Rate
                                      </label>
                                      <div className="contract_display_header_value">
                                        {e.currency_exchange_rate
                                          ? e.currency_exchange_rate
                                          : "-"}
                                      </div>
                                    </div>
                                  )}

                                {(config.company === "SDAM" ||
                                  config.company === "SRK" ||
                                  config.company === "BTR") &&
                                  e.billing_currency !== "IDR" &&
                                  e.billing_currency && (
                                    <div className="col-lg-6 mb-3">
                                      <label className="contract_display_header_label">
                                        Exchange Rate
                                      </label>
                                      <div className="contract_display_header_value">
                                        {e.currency_exchange_rate
                                          ? e.currency_exchange_rate
                                          : "-"}
                                      </div>
                                    </div>
                                  )}
                                <div className="col-lg-6 mb-3">
                                  <label className="contract_display_header_label">
                                    VAT Applicability
                                  </label>
                                  <div className="contract_display_header_value">
                                    {e.vat_applicability
                                      ? e.vat_applicability
                                      : "-"}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="col-lg-6 p-2 rounded"
                              style={{ backgroundColor: "#f7f3f0" }}
                            >
                              <div className="row col-lg-12 p-0">
                                <h6>Floating Crane Price</h6>
                              </div>
                              <div className="row">
                                <div className="col-lg-4 p-0 mb-2">
                                  <label
                                    className="contract_display_header_label"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    Base price
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {toLocaleString(e.floating_crane_base_price)}
                                  </div>
                                </div>
                                <div className="col-lg-4 pr-0 mb-2">
                                  <label
                                    className="contract_display_header_label"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    VAT %
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {toLocaleString(e.floating_crane_vat_percent)}
                                  </div>
                                </div>
                                <div className="col-lg-4 p-0 mb-2">
                                  <label
                                    className="contract_display_header_label"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    VAT
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {toLocaleString(e.floating_crane_vat_value)}
                                  </div>
                                </div>
                              </div>
                              {!(
                                config.company === "SRPL" ||
                                config.company === "AVS" ||
                                config.company === "PRPL"
                              ) && (
                                  <div className="row mb-2">
                                    <div className="col-lg-4 p-0">
                                      <label
                                        className="contract_display_header_label"
                                        style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                      >
                                        Total
                                      </label>
                                      <div
                                        className="contract_display_header_value"
                                        style={{ color: "#42352d" }}
                                      >
                                        {toLocaleString(e.floating_crane_total)}
                                      </div>
                                    </div>

                                    <div className="col-lg ">
                                      <label
                                        className="contract_display_header_label"
                                        style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                      >
                                        Includes PPH23 @ 2%
                                      </label>
                                      <div
                                        className="contract_display_header_value"
                                        style={{ color: "#42352d" }}
                                      >
                                        {toLocaleString(
                                          e.floating_crane_pph23_value
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )}

                              <div className="row">
                                <div
                                  className={
                                    config.company === "SRPL" ||
                                      config.company === "AVS" ||
                                      config.company === "PRPL"
                                      ? "col-lg-6 p-0"
                                      : "col-lg-6 p-0"
                                  }
                                >
                                  <label
                                    className="contract_display_header_label"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    Payable to Vendor
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {toLocaleString(
                                      e.floating_crane_payable_to_vendor
                                    )}
                                    {config.company === "SRPL" ||
                                      config.company === "AVS" ||
                                      config.company === "PRPL"
                                      ? "USD"
                                      : "IDR"}
                                  </div>
                                </div>
                                <div
                                  className={
                                    config.company === "SRPL" ||
                                      config.company === "AVS" ||
                                      config.company === "PRPL"
                                      ? "col-lg-6 pr-0"
                                      : "col-lg-6 pr-0"
                                  }
                                >
                                  <label
                                    className="contract_display_header_label"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    Cost to Company
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {toLocaleString(
                                      e.floating_crane_cost_to_company
                                    )}
                                    {config.company === "SRPL" ||
                                      config.company === "AVS" ||
                                      config.company === "PRPL"
                                      ? "USD"
                                      : "IDR"}
                                  </div>
                                </div>
                              </div>
                            </div> *
                          {/* </div> *
                          {/* <div className="row mb-3 ">
                          <div className="row col-lg-12 p-0">
                            <h5>Additional Charges</h5>
                          </div>
                          <div className="table-responsive p-2">
                            <table className="table table-sm table-bordered mb-0">
                              <thead className="table-header">
                                <tr>
                                  <th className="text-center">Description</th>
                                  <th className="text-right">Qty</th>
                                  <th className="text-right">Unit Price</th>
                                  <th className="text-right">Amount</th>
                                </tr>
                              </thead>
                              <tbody>
                                <>
                                  <tr>
                                    <td>
                                      Additional Charges
                                      {e.currency_exchange_rate > 1 &&
                                        (config.company === "SDAM" ||
                                          config.company === "SRK" ||
                                          config.company === "BTR") &&
                                        e.currency_exchange_rate !== "IDR" && (
                                          <div>
                                            Exchange Rate
                                            {parseFloat(e.currency_exchange_rate).toFixed(2)}
                                          </div>
                                        )}
                                      <div>Type: {e.floating_crane_type ? e.floating_crane_type : "_"}</div>
                                    </td>
                                    <td className="text-right">
                                      {toLocaleString(e.floating_crane_addl_days)}
                                    </td>
                                    <td className="text-right">
                                      {toLocaleString(
                                        e.floating_crane_price_per_addl_day
                                      )}
                                    </td>
                                    <td className="text-right">
                                      {toLocaleString(
                                        e.floating_crane_addl_base_price
                                      )}
                                    </td>

                                  </tr>
                                  {(e.addl_day_vat_applicability === "Inclusive" ||
                                    config.company !== "SRPL" ||
                                    config.company !== "PRPL" ||
                                    config.company !== "AVS") &&
                                    e.floating_crane_vat_value && (
                                      <tr>
                                        <td>VAT @ {toLocaleString(e.floating_crane_addl_vat_percent)} %</td>
                                        <td className="text-right"></td>
                                        <td className="text-right"></td>
                                        <td className="text-right">
                                          {toLocaleString(e.floating_crane_addl_vat_value)}
                                        </td>
                                      </tr>
                                    )}
                                  {!(
                                    config.company === "SRPL" ||
                                    config.company === "AVS" ||
                                    config.company === "PRPL"
                                  ) && e.floating_crane_addl_pph23_value && (
                                      <tr>
                                        <td>PPH23 @2% </td>
                                        <td></td>
                                        <td></td>
                                        <td className="text-right">({toLocaleString(e.floating_crane_addl_pph23_value)})</td>
                                      </tr>
                                    )}
                                  <tr>
                                    <th colSpan={3} className="text-right ">
                                      {" "}
                                      Payable to Vendor :{" "}
                                    </th>
                                    <th className="text-right ">
                                      {`${config.company === "SRPL" ||
                                        config.company === "AVS" ||
                                        config.company === "PRPL"
                                        ? "USD"
                                        : "IDR"}
                                    ${parseFloat(e.floating_crane_addl_payable_to_vendor).toFixed(2)}`}
                                    </th>
                                  </tr>
                                </>

                              </tbody>
                            </table>
                          </div>
                          <div className="row col-lg-12 p-0">
                            <div className="col-lg-6 pl-0">
                              <div className="row">
                                <div className="col-lg-6 mb-3">
                                  <label className="contract_display_header_label">
                                    Additional Days
                                  </label>
                                  <div>
                                    {toLocaleString(e.floating_crane_addl_days)}
                                  </div>
                                </div>

                                <div className="col-lg-6  mb-3">
                                  <label className="contract_display_header_label">
                                    Price Per Day
                                  </label>
                                  <div className="contract_display_header_value">
                                    {toLocaleString(
                                      e.floating_crane_price_per_addl_day
                                    )}
                                    {e.billing_currency !== "0"
                                      ? e.billing_currency
                                      : ""}
                                  </div>
                                </div>

                                <div className="col-lg-12  mb-3 ">
                                  <label className="contract_display_header_label">
                                    VAT Applicability
                                  </label>
                                  <div className="contract_display_header_value">
                                    {e.addl_day_vat_applicability
                                      ? e.addl_day_vat_applicability
                                      : "-"}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="col-lg-6 p-2 rounded"
                              style={{ backgroundColor: "#f7f3f0" }}
                            >
                              <div className="row col-lg-12 p-0">
                                <h6>Floating Crane Additional Price</h6>
                              </div>
                              <div className="row">
                                <div className="col-lg-4 p-0 mb-2">
                                  <label
                                    className="contract_display_header_label"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    Base price
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {toLocaleString(
                                      e.floating_crane_addl_base_price
                                    )}
                                  </div>
                                </div>
                                <div className="col-lg-4 pr-0 mb-2">
                                  <label
                                    className="contract_display_header_label"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    VAT %
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {toLocaleString(
                                      e.floating_crane_addl_vat_percent
                                    )}
                                  </div>
                                </div>
                                <div className="col-lg-4 p-0 mb-2">
                                  <label
                                    className="contract_display_header_label"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    VAT
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {toLocaleString(
                                      e.floating_crane_addl_vat_value
                                    )}
                                  </div>
                                </div>
                              </div>
                              {!(
                                config.company === "SRPL" ||
                                config.company === "AVS" ||
                                config.company === "PRPL"
                              ) && (
                                  <div className="row mb-2">
                                    <div className="col-lg-4 p-0">
                                      <label
                                        className="contract_display_header_label"
                                        style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                      >
                                        Total
                                      </label>
                                      <div
                                        className="contract_display_header_value"
                                        style={{ color: "#42352d" }}
                                      >
                                        {toLocaleString(
                                          e.floating_crane_addl_total
                                        )}
                                      </div>
                                    </div>

                                    <div className="col-lg">
                                      <label
                                        className="contract_display_header_label"
                                        style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                      >
                                        Includes PPH23 @ 2%
                                      </label>
                                      <div
                                        className="contract_display_header_value"
                                        style={{ color: "#42352d" }}
                                      >
                                        {toLocaleString(
                                          e.floating_crane_addl_pph23_value
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )}

                              <div className="row">
                                <div
                                  className={
                                    config.company === "SRPL" ||
                                      config.company === "AVS" ||
                                      config.company === "PRPL"
                                      ? "col-lg-6 p-0"
                                      : "col-lg-6 p-0"
                                  }
                                >
                                  <label
                                    className="contract_display_header_label"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    Payable to Vendor
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {toLocaleString(
                                      e.floating_crane_addl_payable_to_vendor
                                    )}
                                    {config.company === "SRPL" ||
                                      config.company === "AVS" ||
                                      config.company === "PRPL"
                                      ? "USD"
                                      : "IDR"}
                                  </div>
                                </div>
                                <div
                                  className={
                                    config.company === "SRPL" ||
                                      config.company === "AVS" ||
                                      config.company === "PRPL"
                                      ? "col-lg-6 pr-0"
                                      : "col-lg-6 pr-0"
                                  }
                                >
                                  <label
                                    className="contract_display_header_label"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    Cost to Company
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {toLocaleString(
                                      e.floating_crane_addl_cost_to_company
                                    )}
                                    {config.company === "SRPL" ||
                                      config.company === "AVS" ||
                                      config.company === "PRPL"
                                      ? "USD"
                                      : "IDR"}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> *

                          <hr />

                          {/* <div className="row mb-3">
                            {e.wait_period_people && (
                              <>
                                <div className="row col-lg-12 p-0">
                                  <h5>Waiting Period</h5>
                                </div>
                                <div className="table-responsive p-2">
                                  <table className="table table-sm table-bordered mb-0">
                                    <thead className="table-header">
                                      <tr>
                                        <th className="text-center">
                                          Description
                                        </th>
                                        <th className="text-right">
                                          No of Hours
                                        </th>
                                        <th className="text-right">
                                          Proce Per Hour
                                        </th>
                                        <th className="text-right">Amount</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <>
                                        <tr>
                                          <td>
                                            Waiting Period
                                            <div>
                                              No of People :{" "}
                                              {toLocaleString(
                                                e.wait_period_people
                                              )}{" "}
                                            </div>
                                          </td>
                                          <td className="text-right">
                                            {toLocaleString(
                                              e.wait_period_hours
                                            )}
                                          </td>
                                          <td className="text-right">
                                            {toLocaleString(
                                              e.wait_period_price_per_hour
                                            )}
                                          </td>
                                          <td className="text-right">
                                            {config.company === "SRPL" ||
                                            config.company === "AVS" ||
                                            config.company === "PRPL"
                                              ? "USD"
                                              : "IDR"}{" "}
                                            {toLocaleString(
                                              e.wait_period_total_price
                                            )}
                                          </td>
                                        </tr>
                                      </>
                                    </tbody>
                                  </table>
                                </div>
                              </>
                            )}
                            {/* <div className="row col-lg-12 p-0">
                            <div className="col-lg-4 mb-3">
                              <label className="contract_display_header_label">
                                No Of People
                              </label>
                              <div className="contract_display_header_value">
                                {toLocaleString(e.wait_period_people)}
                              </div>
                            </div>

                            <div className="col-lg-4 mb-3">
                              <label className="contract_display_header_label">
                                No Of Hours
                              </label>
                              <div className="">
                                {toLocaleString(e.wait_period_hours)}
                              </div>
                            </div>

                            <div className="col-lg-4 mb-3">
                              <label className="contract_display_header_label">
                                Price Per Hour
                              </label>
                              <div className="contract_display_header_value">
                                {toLocaleString(e.wait_period_price_per_hour)}
                                {e.billing_currency}
                              </div>
                            </div>

                            <div className="col-lg-4 mb-3">
                              <label className="contract_display_header_label">
                                Total Price
                              </label>
                              <div className="contract_display_header_value">
                                {toLocaleString(e.wait_period_total_price)}
                                {config.company === "SRPL" ||
                                  config.company === "AVS" ||
                                  config.company === "PRPL"
                                  ? "USD"
                                  : "IDR"}
                              </div>
                            </div>
                          </div> *
                          </div> *

                          {/* {e.others.length > 0 && (
                            <div className="row mb-3">
                              <div className="row col-lg-12 p-0">
                                <h5>Others</h5>
                              </div>

                              {e.others.map((o, idex) => (
                                // <div
                                //   className="row p-2 col-lg-12 border"
                                //   key={idex}
                                // >
                                <>
                                  <div className="table-responsive p-2">
                                    <table className="table table-sm table-bordered mb-0">
                                      <thead className="table-header">
                                        <tr>
                                          <th className="text-left">
                                            Description
                                          </th>
                                          <th className="text-left">Remarks</th>
                                          <th className="text-right">Price</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <>
                                          <tr>
                                            <td
                                              className="text-left"
                                              style={{
                                                textTransform: "uppercase",
                                                whiteSpace: "pre-line",
                                              }}
                                            >
                                              {o.description
                                                ? o.description
                                                : "-"}
                                            </td>
                                            <td
                                              className="text-left"
                                              style={{
                                                textTransform: "uppercase",
                                                whiteSpace: "pre-line",
                                              }}
                                            >
                                              {o.remarks ? o.remarks : "-"}
                                            </td>
                                            <td className="text-right">
                                              {toLocaleString(o.price)}
                                              {config.company === "SRPL" ||
                                              config.company === "AVS" ||
                                              config.company === "PRPL"
                                                ? "USD"
                                                : "IDR"}
                                            </td>
                                          </tr>
                                        </>
                                      </tbody>
                                    </table>
                                  </div>
                                </>
                                // <div className="col-lg-6 mb-3">
                                //   <label className="contract_display_header_label">
                                //     Description
                                //   </label>
                                //   <div
                                //     className="contract_display_header_value"
                                //     style={{ textTransform: "uppercase" }}
                                //   >
                                //     {o.description ? o.description : "-"}
                                //   </div>
                                // </div>

                                // <div className="col-lg-6 mb-3">
                                //   <label className="contract_display_header_label">
                                //     Price
                                //   </label>
                                //   <div className="contract_display_header_value">
                                //     {toLocaleString(o.price)}
                                //     {config.company === "SRPL" ||
                                //       config.company === "AVS" ||
                                //       config.company === "PRPL"
                                //       ? "USD"
                                //       : "IDR"}
                                //   </div>
                                // </div>

                                // <div className="col-lg-12 mb-3">
                                //   <label className="contract_display_header_label">
                                //     Remarks
                                //   </label>
                                //   <div
                                //     className="contract_display_header_value"
                                //     style={{
                                //       textTransform: "uppercase",
                                //       whiteSpace: "pre-line",
                                //     }}
                                //   >
                                //     {o.remarks ? o.remarks : "-"}
                                //   </div>
                                // </div>
                                //  </div>
                              ))}
                            </div>
                          )} *
                        </div> */}

                      <div className="card col-lg-12 p-0 border mb-3">
                        <div className="card-body p-2">
                          <InvoiceForm
                            open={this.state.invoiceDialog}
                            data={e}
                            costingID={e.id}
                            callbackFn={(data) => this.updateInvoice(data)}
                          />

                          {(this.state.features.includes("263") ||
                            this.state.features.includes("97")) && (
                            <div>{this.renderPaymentPostings(e)}</div>
                          )}
                        </div>
                      </div>
                      {/* </div> */}

                      <ViewFiles
                        {...this.props}
                        files_data={e.files}
                        type="Costings"
                        invoice_file={e.invoice_file}
                      />
                    </div>
                    <AuditInfo
                      approved_by_name={e.approved_by_name}
                      approved_by_name_2={e.approved_by_name_2}
                      approved_date={e.approved_date}
                      approved_date_2={e.approved_date_2}
                    />
                  </>
                ))}
              </div>
            </div>
          </div>

          <footer className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button btn-light"
              name="submit"
              onClick={() => {
                this.setState(
                  {
                    openDrawer: false,
                  },
                  () => this.props.returnCallbackDrawerClose(false)
                );
              }}
            >
              Close
            </button>
            <button
              type="button"
              className="drawer_text drawer_btn_success"
              name="Back"
              onClick={() => {
                this.props.onEdit(true);
              }}
            >
              Edit
            </button>
          </footer>
        </div>
      );
    }
  }
}
