import React, { Component } from "react";
import CookieHandler from "../common/CookieHandler";
import SideBar from "../common/SideBar";
import Header from "../common/Header";
import ExpenditureHistory from "./ExpenditureHistory";

import api from "../../api/api";
import config from "../../config/config";

import { Alert } from "@material-ui/lab";
import {
  Snackbar,
  Button,
  TextField,
  Tabs,
  Tab,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Drawer,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import MaterialTable from "material-table";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import EventIcon from "@material-ui/icons/Event";
import AddCircle from "@material-ui/icons/AddCircle";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { dateFormateToDB, localDateFormate } from "../common/common";
import Loader from "../common/Loader";

export default class Expenditure extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      activeTabIndex: 0,
      account_name: "",
      expenditure_accounts: [],
      selected_account_id: null,
      expenditure_groups: [],
      expense_types: [],
      expenditure_group: "0",
      expense_type: "0",
      is_receivable: false,
      from_date: null,
      to_date: null,
      account_history: [],
      description: "",
      payment_date: null,
      expense_date: null,
      // Receipt
      amount: "",
      date_of_receipt: null,
      receipt_remarks: "",

      openExpenseDrawer: false,
      openReceiptDrawer: false,

      // Expenditure History
      expenditure_history: [],
      acc_name_drop_values: [],
      account_id: "",
      his_expenditure_group: "0",
      start_date: null,
      end_date: null,
      isLoading: true,
      open: false,
      costingCenterView: false,
      costingCenterUpdate: false,
      showExpensesError: false,
      costingCenterData: null,
      view_type: "Add",
      // cost center
      cost_center_drop_values: [],
      cost_center: "",
      expense_cost_center: [],
      approval_status: "",
      features: [],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (!(features.includes("311")  || features.includes("97") )) {
      window.location.href = "/dashboard";
    }
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const get_cost_centers = await api.get_indirect_expenditure_cost_centers(
      loginUserID,
      idToken
    );
    if (get_cost_centers.status >= 200 && get_cost_centers.status < 300) {
      get_cost_centers.json().then((res) => {
        if (res.code === "200") {
          if (res.indirect_expenditure_cost_centers) {
            const cost_center_drop_values = [];
            for (var i of res.indirect_expenditure_cost_centers) {
              cost_center_drop_values.push({
                name: i.cost_center,
                cost_center_id: i.id,
                base_price: "",
                vat: "",
                pph: "",
                invoice_date: null,
                invoice_no: "",
                expense_date: null,
                payment_date: null,
                remarks: "",
              });
            }
            this.setState({
              cost_center_drop_values: cost_center_drop_values,
            });
          } else {
            alert("Unexpected error occured. Please contact administrator.");
          }
        } else if (res.code === "601") {
          this.setState({
            isLoading: false,
            snackBarErrorOpen: true,
            errorMsg: res.message,
          });
        } else if (res.code === "607") {
          window.location.href = "/logout";
        }
      });
    }
    const get_exp_accounts = await api.get_indirect_expenditure_accounts(
      loginUserID,
      idToken
    );
    if (get_exp_accounts.status >= 200 && get_exp_accounts.status < 300) {
      get_exp_accounts.json().then((res) => {
        if (res.code === "200") {
          if (res.indirect_expenditure_accounts) {
            const acc_names = [];
            for (var i of res.indirect_expenditure_accounts) {
              acc_names.push({ value: i.id, name: i.account_name });
            }
            this.setState({
              expenditure_accounts: res.indirect_expenditure_accounts.filter(
                (e, indx) => e.expenditure_group !== ""
              ), //res.indirect_expenditure_accounts,
              acc_name_drop_values: acc_names,
              //isLoading: false
            });
          } else {
            alert("Unexpected error occured. Please contact administrator.");
          }
        } else if (res.code === "601") {
          this.setState({
            snackBarErrorOpen: true,
            errorMsg: res.message,
            isLoading: false,
          });
        } else if (res.code === "607") {
          window.location.href = "/logout";
        }
      });
    }

    const get_exp_groups = await api.get_indirect_expenditure_groups(
      loginUserID,
      idToken
    );
    if (get_exp_groups.status >= 200 && get_exp_groups.status < 300) {
      get_exp_groups.json().then((res) => {
        if (res.code === "200") {
          if (res.indirect_expenditure_groups) {
            var expenditure_groups = [
              <MenuItem value="0" key={-1} disabled>
                Please Select
              </MenuItem>,
            ];
            for (var i of res.indirect_expenditure_groups) {
              expenditure_groups.push(
                <MenuItem value={i.expenditure_group} key={[i]}>
                  {i.expenditure_group}
                </MenuItem>
              );
            }
            this.setState({
              isLoading: false,
              expenditure_groups: expenditure_groups,
            });
          } else {
            alert("Unexpected error occured. Please contact administrator.");
          }
        } else if (res.code === "601") {
          this.setState({
            isLoading: false,
            snackBarErrorOpen: true,
            errorMsg: res.message,
          });
        } else if (res.code === "607") {
          window.location.href = "/logout";
        }
      });
    }

    const get_expen_types = await api.get_indirect_expenditure_expense_types(
      loginUserID,
      idToken
    );
    if (get_expen_types.status >= 200 && get_expen_types.status < 300) {
      get_expen_types.json().then((res) => {
        if (res.code === "200") {
          if (res.indirect_expenditure_expense_types) {
            var expense_types = [
              <MenuItem value="0" key={-1} disabled>
                Please Select
              </MenuItem>,
            ];
            for (var i of res.indirect_expenditure_expense_types) {
              expense_types.push(
                <MenuItem value={i.expense_type} key={[i]}>
                  {i.expense_type}
                </MenuItem>
              );
            }
            this.setState({
              isLoading: false,
              expense_types: expense_types,
            });
          } else {
            alert("Unexpected error occured. Please contact administrator.");
          }
        } else if (res.code === "601") {
          this.setState({
            isLoading: false,
            snackBarErrorOpen: true,
            errorMsg: res.message,
          });
        } else if (res.code === "607") {
          window.location.href = "/logout";
        }
      });
    }

    document.title = config.documentTitle + "Indirect Expenditure";
  }

  saveAccountHandler = () => {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    let account_name = this.state.account_name;
    let expenditure_group = this.state.expenditure_group;
    api
      .add_indirect_expenditure_account(
        loginUserID,
        idToken,
        account_name,
        expenditure_group
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState(
                {
                  snackBarSuccessOpen: true,
                  expenditure_group: "0",
                  account_name: "",
                  successMsg: res.message,
                  open: false,
                },
                () => this.componentDidMount()
              );
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            } else if (res.code === "624") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: "Record already exist",
                account_name_error: true,
              });
            }
          });
        }
      });
  };

  saveExpensesHandler = () => {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();

    let { selected_account_id, description, remarks } = this.state;
    let cost_center = this.state.expense_cost_center;
    api
      .add_indirect_expenditure_expense(
        loginUserID,
        idToken,
        selected_account_id,
        description,
        cost_center,
        remarks
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState({
                snackBarSuccessOpen: true,
                successMsg: res.message,
                isLoading: false,
                openExpenseDrawer: false,
                expense_cost_center: [],
                description: "",
              });
            } else if (res.code === "601") {
              this.setState({
                isLoading: false,
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  };

  updateExpensesHandler = () => {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    let {
      expense_id,
      selected_account_id,
      expenditure_group,
      expense_type,
      is_receivable,
      description,
      invoice_date,
      invoice_no,
      ppn,
      base_price,
      vat,
      pph,
      payment_date,
      expense_date,
      remarks,
    } = this.state;

    api
      .update_indirect_expenditure_expense(
        loginUserID,
        idToken,
        expense_id,
        selected_account_id,
        expenditure_group,
        expense_type,
        is_receivable,
        description,
        invoice_date,
        invoice_no,
        ppn,
        base_price,
        vat,
        pph,
        expense_date,
        payment_date,
        remarks
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState(
                {
                  snackBarSuccessOpen: true,
                  successMsg: res.message,
                  isLoading: false,
                  openExpenseDrawer: false,
                  expenditure_group: "0",
                  expense_type: "0",
                  is_receivable: false,
                  description: "",
                  base_price: "",
                  invoice_no: "",
                  invoice_date: null,
                  ppn: "",
                  vat: "",
                  pph: "",
                  payment_date: null,
                  expense_date: null,
                  remarks: "",
                },
                () => {
                  this.componentDidMount();
                  this.searchExpenditureHisHandler();
                }
              );
            } else if (res.code === "601") {
              this.setState({
                isLoading: false,
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  };

  updateCostingCenterHandler = () => {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    let data = {
      login_user_id: loginUserID,
      idtoken: idToken,
      cost_center_expense_id:
        this.state.costingCenterData.cost_center_expense_id,
      expenses_id: this.state.costingCenterData.expenses_id,
      cost_center_id: this.state.costingCenterData.cost_center_id,

      invoice_no: this.state.costingCenterData.invoice_no,
      invoice_date: this.state.costingCenterData.invoice_date,
      ppn: this.state.costingCenterData.ppn,
      base_price: this.state.costingCenterData.base_price,
      vat: this.state.costingCenterData.vat,
      pph: this.state.costingCenterData.pph,
      expense_date: this.state.costingCenterData.expense_date,
      payment_date: this.state.costingCenterData.payment_date,
      remarks: this.state.costingCenterData.remarks,
    };

    api
      .update_indirect_expenditure_cost_center_expenses(data)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState(
                {
                  displayList: true,
                  costingCenterView: false,
                  costingCenterUpdate: false,
                  isLoading: false,
                },
                () => {
                  this.componentDidMount();
                  this.get_acc_detailsHandler();
                }
              );
            } else if (res.code === "601") {
              this.setState({
                isLoading: false,
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  };

  searchExpenditureHisHandler = () => {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    let { account_id, his_expenditure_group, start_date, end_date } =
      this.state;
    let report_type = "";
    if (his_expenditure_group === "0") {
      his_expenditure_group = "";
    }
    api
      .get_history_for_indirect_expenditure(
        loginUserID,
        idToken,
        account_id,
        report_type,
        his_expenditure_group,
        start_date,
        end_date
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.indirect_expenditure_data) {
                if (res.indirect_expenditure_data.length === 0) {
                  this.setState({
                    snackBarErrorOpen: true,
                    errorMsg: "No records to display",
                  });
                }
                this.setState({
                  expenditure_history: res.indirect_expenditure_data,
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  };

  toLocaleString(value) {
    return value ? Number(value).toLocaleString() : 0;
  }

  getExpenditureDetails = (id, type) => {
    if (id && type) {
      const loginUserID = this.Cookie.getCookie("loginUserId");
      const idToken = this.Cookie.getIdTokenCookie();
      api
        .get_indirect_expenditure_data(loginUserID, idToken, id, type)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                if (res.indirect_expenditure_data) {
                  if (type === "Expense") {
                    this.setState({
                      expense_id: res.indirect_expenditure_data[0].id,
                      selected_account_id:
                        res.indirect_expenditure_data[0].account_id,
                      selected_account_name:
                        res.indirect_expenditure_data[0].account_name,
                      expenditure_group:
                        res.indirect_expenditure_data[0].expenditure_group,
                      expense_type:
                        res.indirect_expenditure_data[0].expense_type,
                      is_receivable:
                        res.indirect_expenditure_data[0].is_receivable === "1"
                          ? true
                          : false,
                      description: res.indirect_expenditure_data[0].description,
                      invoice_date:
                        res.indirect_expenditure_data[0].invoice_date,
                      invoice_no: res.indirect_expenditure_data[0].invoice_no,
                      base_price: res.indirect_expenditure_data[0].base_price,
                      vat: res.indirect_expenditure_data[0].vat,
                      pph: res.indirect_expenditure_data[0].pph,
                      payment_date:
                        res.indirect_expenditure_data[0].payment_date,
                      expense_date:
                        res.indirect_expenditure_data[0].expense_date,
                      remarks: res.indirect_expenditure_data[0].remarks,
                      openExpenseDrawer: true,
                      //openReceiptDrawer: type === "Receipt" ? true : false,
                      view_type: "View",
                    });
                  } else if (type === "Receipt") {
                    this.setState({
                      receipt_id: res.indirect_expenditure_data[0].id,
                      selected_account_id:
                        res.indirect_expenditure_data[0].account_id,
                      selected_account_name:
                        res.indirect_expenditure_data[0].account_name,
                      amount: res.indirect_expenditure_data[0].amount,
                      date_of_receipt:
                        res.indirect_expenditure_data[0].date_of_receipt,
                      receipt_remarks: res.indirect_expenditure_data[0].remarks,
                      openReceiptDrawer: true,
                      view_type: "View",
                    });
                  }
                } else {
                  alert(
                    "Unexpected error occured. Please contact administrator."
                  );
                }
              } else if (res.code === "601") {
                this.setState({
                  snackBarErrorOpen: true,
                  errorMsg: res.message,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    }
  };

  addCostCenterHandler = () => {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    let cost_center = this.state.cost_center;
    api
      .add_indirect_expenditure_cost_center(loginUserID, idToken, cost_center)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState(
                {
                  snackBarSuccessOpen: true,
                  successMsg: res.message,
                  cost_center_modal: false,
                  cost_center: "",
                },
                () => this.componentDidMount()
              );
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            } else if (res.code === "624") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: "Record already exist",
                cost_center_name_error: true,
              });
            }
          });
        }
      });
  };

  get_acc_detailsHandler = () => {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    let account_id = this.state.selected_account_id;
    let start_date = "";
    if (this.state.from_date !== null) {
      start_date = this.state.from_date;
    }
    let end_date = "";
    if (this.state.from_date !== null) {
      end_date = this.state.to_date;
    }
    let approval_status = this.state.approval_status;
    // let end_date = this.state.to_date.split('T')[0];
    api
      .get_indirect_expenditure_for_account_id(
        loginUserID,
        idToken,
        account_id,
        start_date,
        end_date,
        approval_status
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.indirect_expenditure_expenses) {
                if (res.indirect_expenditure_expenses.length === 0) {
                  this.setState({
                    snackBarErrorOpen: true,
                    showExpensesError: true,
                    errorMsg: "No records to display",
                  });
                }
                this.setState({
                  account_history: res.indirect_expenditure_expenses,
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
                isLoading: false,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  };

  removeRowsHandler = (index) => () => {
    const rows = this.state.expense_cost_center;
    rows.splice(index, 1);
    this.setState({ expense_cost_center: rows });
  };

  openAccountsData = (row) => {
    this.setState(
      {
        selected_account_id: row.id,
        selected_account_name: row.account_name,
        viewAccDetailsDrawer: true,
        displayList: true,
      },
      () => this.get_acc_detailsHandler()
    );
  };

  approve_cost_center_expenses = (id) => {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    let cost_center_expense_id = id;
    api
      .approve_cost_center_expenses(
        loginUserID,
        idToken,
        cost_center_expense_id
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState(
                {
                  snackBarSuccessOpen: true,
                  successMsg: "Approved sucessfully",
                },
                () => {
                  this.get_acc_detailsHandler();
                  this.componentDidMount();
                }
              );
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  };

  render() {
    const pr = /^\d*(\.\d{0,4})?$/;
    return (
      <div id="wraper">
        <SideBar />
        <div className="content">
          <Header />
          <div className="clearfix content-header">
            <div className="row">
              <div className="col-sm text-left pl-0">
                <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                  Indirect Expenditure{" "}
                  <span style={{ color: "lightgrey", margin: "0px 5px" }}>
                    |
                  </span>
                  <span style={{ color: "#959cb6", fontSize: "15px" }}>
                    {this.state.expenditure_accounts.length}
                  </span>
                </h4>
              </div>
              <div className="col text-right float-sm-left">
                {(this.state.features.includes("310")  ||
                  this.state.features.includes("97") ) && (
                  <button
                    className="header_button header_button_text add_button_adjustment"
                    style={{ width: "auto", marginTop: "18px" }}
                    onClick={() => this.setState({ open: true })}
                  >
                    New Account
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="container p-2">
            {this.state.isLoading && (
             <Loader />
            )}
            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={this.state.snackBarErrorOpen}
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              style={{ width: "450px" }}
            >
              <Alert
                elevation={6}
                variant="filled"
                onClose={() => this.setState({ snackBarErrorOpen: false })}
                severity="error"
                style={{ width: "100%" }}
              >
                {this.state.errorMsg}
              </Alert>
            </Snackbar>
            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              style={{ width: "450px" }}
              open={this.state.snackBarSuccessOpen}
              autoHideDuration={1000}
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
            >
              <Alert
                elevation={6}
                variant="filled"
                onClose={() => this.setState({ snackBarSuccessOpen: false })}
                severity="success"
                style={{ width: "100%" }}
              >
                {this.state.successMsg}
              </Alert>
            </Snackbar>
            {!this.state.isLoading && (
              <div className="card" style={{ overflowY: "scroll" }}>
                <div className="section_block" style={{ width: "90%" }}>
                  <div className="row mb-2">
                    <Tabs
                      value={this.state.activeTabIndex}
                      onChange={(event, value) => {
                        this.setState({
                          activeTabIndex: value,
                        });
                      }}
                      indicatorColor="primary"
                      textColor="primary"
                      variant="scrollable"
                      scrollButtons="auto"
                      aria-label="scrollable auto tabs example"
                      style={{
                        backgroundColor: "#ffffff",
                        color: "#6c7293",
                        padding: 0,
                      }}
                      TabIndicatorProps={{
                        style: { background: config.themeColor },
                      }}
                    >
                      <Tab
                        label={
                          <h4
                            style={{
                              margin: "0px",
                              color:
                                this.state.activeTabIndex === 0
                                  ? config.themeColor
                                  : "#6c7293",
                              fontSize: "14px",
                              border: "0px",
                            }}
                          >
                            Accounts
                          </h4>
                        }
                        value={0}
                        style={{
                          fontFamily: "Poppins",
                          textTransform: "capitalize",
                          color: "#6c7293",
                        }}
                      />
                      <Tab
                        label={
                          <h4
                            style={{
                              margin: "0px",
                              color:
                                this.state.activeTabIndex === 1
                                  ? config.themeColor
                                  : "#6c7293",
                              fontSize: "14px",
                              border: "0px",
                            }}
                          >
                            Expenditure History
                          </h4>
                        }
                        value={1}
                        style={{
                          fontFamily: "Poppins",
                          textTransform: "capitalize",
                          color: "#6c7293",
                        }}
                      />
                    </Tabs>
                  </div>

                  {this.state.activeTabIndex === 0 && (
                    <div>
                      <MaterialTable
                        className="MuiTableCell_root"
                        title=""
                        columns={[
                          {
                            title: "",
                            field: "expenditure_group",
                            defaultGroupOrder: 0,
                            //hidden: true
                          },
                          {
                            title: "Account Name",
                            field: "account_name",
                            width: 520,
                            headerStyle: {
                              minWidth: 170,
                            },
                            render: (row) => {
                              return (
                                <span
                                  style={{
                                    cursor: "pointer",
                                    color: config.themeColor,
                                    marginLeft: 50,
                                  }}
                                  onClick={() => this.openAccountsData(row)}
                                >
                                  {row.account_name}
                                </span>
                              );
                            },
                          },
                          {
                            title: "Expense",
                            field: "total_expense",
                            sorting: false,
                            headerStyle: {
                              textAlign: "right",
                              minWidth: 170,
                            },
                            cellStyle: {
                              textAlign: "right",
                            },

                            render: (row) => {
                              return this.toLocaleString(row.total_expense);
                            },
                          },
                          {
                            title: "Total Pending Approvals",
                            field: "cnt",
                            sorting: false,
                            headerStyle: {
                              textAlign: "left",
                              minWidth: 250,
                            },
                            cellStyle: {
                              textAlign: "left",
                            },
                            render: (row) => {
                              return this.toLocaleString(row.cnt);
                            },
                          },
                          {
                            title: "Actions",
                            headerStyle: {
                              textAlign: "left",
                              minWidth: 100,
                            },
                            field: "expense_type",
                            cellStyle: {
                              textAlign: "left",
                            },
                            width: 100,
                            render: (row) => {
                              return (
                                <div>
                                  {(this.state.features.includes("310")  ||
                                    this.state.features.includes("97") ) && (
                                    // <button
                                    //   className='header_button header_button_text mr-3' style={{ width: 'auto' }}
                                    //   onClick={() => {
                                    //     row.opendrawer = true;
                                    //     this.setState({
                                    //       openExpenseDrawer: true,
                                    //       selected_account_id: row.id,
                                    //       selected_account_name: row.account_name,
                                    //       view_type: "Add"
                                    //     });
                                    //   }}
                                    // >
                                    //   New Expense
                                    // </button>
                                    <i
                                      className="fa fa-plus"
                                      style={{
                                        fontSize: 20,
                                        color: config.themeColor,
                                        cursor: "pointer",
                                      }}
                                      data-toggle="modal"
                                      title="New Expense"
                                      data-placement="bottom"
                                      data-keyboard="false"
                                      data-backdrop="static"
                                      onClick={() => {
                                        row.opendrawer = true;
                                        this.setState({
                                          openExpenseDrawer: true,
                                          selected_account_id: row.id,
                                          selected_account_name:
                                            row.account_name,
                                          view_type: "Add",
                                        });
                                      }}
                                    ></i>
                                  )}
                                </div>
                              );
                            },
                          },
                        ]}
                        data={this.state.expenditure_accounts}
                        // parentChildData={(row, rows) => rows.find(a => a.expenditure_group === row.expenditure_group)}
                        isLoading={this.state.loading}
                        style={{
                          minHeight: 180,
                          fontFamily: "Poppins",
                          overflowY: "auto",
                          boxShadow: "0 0 5px 0 rgba(0, 39, 77, 0.1)",
                        }}
                        options={{
                          paging: false,
                          defaultExpanded: true,
                          pageSize: 100,
                          pageSizeOptions: [5, 10, 20, 100, 200],
                          // grouping: true,
                          // paginationType: "stepped",
                          // pageSize: 10,
                          headerStyle: {
                            backgroundColor: "#f9f9f9",
                            maxWidth: 200,
                            fontFamily: "Poppins",
                            fontSize: 14,
                            fontWeight: 400,
                            fontStretch: "normal",
                            fontStyle: "normal",
                            lineHeight: "0.65",
                            letterSpacing: "normal",
                            textAlign: "left",
                            color: "#42352d",
                          },
                          cellStyle: {
                            fontFamily: "Poppins",
                            textAlign: "left",
                            height: "20px",
                          },
                          rowStyle: {
                            maxHeight: 15,
                            cellStyle: {
                              paddingTop: "2px",
                            },
                          },
                          search: true,
                          draggable: false,
                          actionsColumnIndex: -1,
                        }}
                      />
                    </div>
                  )}

                  {this.state.activeTabIndex === 1 && <ExpenditureHistory />}
                </div>
              </div>
            )}
          </div>
        </div>

        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogContent>
            <DialogContentText>
              <div className="row">
                <div className="col-lg-11">
                  <label className="form_label mb-0">Expenditure Group</label>
                  <TextField
                    name="expenditure_group"
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    onChange={(e) => {
                      this.setState({
                        expenditure_group: e.target.value,
                      });
                    }}
                    value={this.state.expenditure_group}
                    select
                    //inputProps={{ style: { textTransform: 'uppercase' } }}
                  >
                    {this.state.expenditure_groups}
                  </TextField>
                </div>
                <div className="col-lg-11">
                  <label className="form_label mb-0">Account Name</label>
                  <TextField
                    name="account_name"
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    error={this.state.account_name_error}
                    onChange={(e) => {
                      this.setState({
                        account_name: e.target.value,
                        account_name_error: false,
                      });
                    }}
                    value={this.state.account_name}
                    placeholder="Account Name"
                    inputProps={{ style: { textTransform: "uppercase" } }}
                  />
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({ open: false })}
              color="primary"
            >
              Cancel
            </Button>
            <button
              type="button"
              className="drawer_button drawer_text"
              onClick={this.saveAccountHandler}
            >
              Save
            </button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.cost_center_modal}
          // onClose={this.handleClose}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogContent>
            <DialogContentText>
              <div className="row">
                <div className="col-lg-11">
                  <label className="form_label mb-0">New Cost Center</label>
                  <TextField
                    name="cost_center"
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    placeholder="New Cost Center"
                    error={this.state.cost_center_name_error}
                    onChange={(e) => {
                      this.setState({
                        cost_center: e.target.value,
                        cost_center_name_error: false,
                      });
                    }}
                    autoFocus={true}
                    value={this.state.cost_center}
                    inputProps={{ style: { textTransform: "uppercase" } }}
                  />
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({ cost_center_modal: false })}
              color="primary"
            >
              Cancel
            </Button>
            <button
              type="button"
              className="drawer_button drawer_text"
              onClick={this.addCostCenterHandler}
            >
              Save
            </button>
          </DialogActions>
        </Dialog>

        <Drawer
          anchor="right"
          open={this.state.openExpenseDrawer}
          variant="temporary"
          elevation="20"
          style={{ overflow: "hidden" }}
          onClose={() => {
            this.setState({
              openExpenseDrawer: false,
              expense_cost_center: [],
            });
          }}
        >
          <div className="p-2" style={{ minHeight: "100vh", width: 850 }}>
            <div className="col-lg-12 p-0 border-bottom">
              <h5
                className="label-text pt-0 mb-0"
                style={{ color: config.themeColor }}
              >
                New Expense For - {this.state.selected_account_name}
              </h5>
            </div>

            <div className=" card border rounded-lg mt-2">
              {(this.state.view_type === "Add" ||
                this.state.view_type === "Edit") && (
                <div className="col-lg-12 p-0">
                  <div className="row mb-3">
                    <div className="col-lg-6 p-0">
                      <label className="form_label mb-1">Costing Center</label>
                      <Autocomplete
                        multiple
                        options={this.state.cost_center_drop_values}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) => {
                          if (value.length > 0) {
                            // for (let i = 0; i < value.length; i++) {
                            //   value[i].base_price = '';
                            //   value[i].vat = '';
                            //   value[i].pph = '';
                            //   value[i].expense_date = null;
                            //   value[i].payment_date = null;
                            //   value[i].remarks = '';
                            // }
                            this.setState({
                              expense_cost_center: value,
                            });
                            // this.setState({ account_id: value.value, selected_acc_drop_value: value });
                          } else {
                            this.setState({
                              expense_cost_center: [],
                            });
                          }
                        }}
                        //disableCloseOnSelect
                        name="Cost Center"
                        size="small"
                        //value={this.state.selected_acc_drop_value}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // placeholder="Cost Center"
                            variant="outlined"
                            //style={{ top: 8 }}
                            fullWidth
                          />
                        )}
                        style={{ display: "contents" }}
                      />
                    </div>

                    <div className="col-lg-6 pr-0">
                      <label style={{ paddingTop: "30px" }}>
                        <AddCircle
                          style={{ color: config.themeColor }}
                          onClick={() =>
                            this.setState({
                              cost_center_modal: true,
                              cursor: "pointer",
                            })
                          }
                        />
                      </label>
                    </div>
                  </div>

                  {this.state.expense_cost_center.map((e, idx) => (
                    <fieldset
                      className="col-lg-12 border rounded pt-2 pb-2 mb-2"
                      key={idx}
                    >
                      <legend
                        className="w-auto m-0 rounded"
                        style={{
                          fontSize: 14,
                          paddingLeft: 10,
                          paddingTop: 3,
                          paddingBottom: 5,
                          paddingRight: 10,
                          color: config.themeColor,
                        }}
                      >
                        {e.name}
                      </legend>
                      <div className="row">
                        <div className="col-lg-6 p-0">
                          <label className="form_label mb-0">Invoice No</label>
                          <TextField
                            name="invoice_no"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            onChange={(e) => {
                              let row = this.state.expense_cost_center;
                              row[idx].invoice_no = e.target.value;
                              this.setState({
                                expense_cost_center: row,
                              });
                            }}
                            value={e.invoice_no}
                            placeholder="Invoice No"
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                          />
                        </div>
                        <div className="col-lg-6 pr-0">
                          <label className="form_label mb-0">
                            Invoice Date
                          </label>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                              autoOk={true}
                              inputVariant="outlined"
                              variant="inline"
                              margin="dense"
                              fullWidth
                              format="dd/MM/yyyy"
                              value={e.invoice_date}
                              onChange={(date) => {
                                let row = this.state.expense_cost_center;
                                row[idx].invoice_date = dateFormateToDB(date);
                                this.setState({
                                  expense_cost_center: row,
                                });
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton>
                                      <EventIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                        <div className="col-lg-6 p-0">
                          <label className="form_label mb-0">Base Price</label>
                          <TextField
                            name="base_price"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            onChange={(e) => {
                              const reg = /^[\d./-]+$/;
                              if (
                                e.target.value === "" ||
                                reg.test(e.target.value)
                              ) {
                                let row = this.state.expense_cost_center;
                                row[idx].base_price = e.target.value;
                                this.setState({
                                  expense_cost_center: row,
                                });
                              }
                            }}
                            value={e.base_price}
                            placeholder="Base Price"
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  {config.company === "SRPL" || config.company === "AVS"  ||
                                  config.company === "PRPL"
                                    ? "USD"
                                    : "IDR"}
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                        <div
                          className={
                            config.company === "SRPL" || config.company === "AVS"  ||
                            config.company === "PRPL"
                              ? "col-lg-6 pr-0"
                              : "col-lg-3 pr-0"
                          }
                        >
                          <label className="form_label mb-0">VAT</label>
                          <TextField
                            name="vat"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            onChange={(e) => {
                              if (
                                e.target.value === "" ||
                                pr.test(e.target.value)
                              ) {
                                let row = this.state.expense_cost_center;
                                row[idx].vat = e.target.value;
                                this.setState({
                                  expense_cost_center: row,
                                });
                              }
                            }}
                            value={e.vat}
                            placeholder="VAT"
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                          />
                        </div>
                        {(config.company === "SDAM" ||
                          config.company === "SRK" ||
                          config.company === "BTR") && (
                          <div className="col-lg-3 pr-0">
                            <label className="form_label mb-0">PPH</label>
                            <TextField
                              name="pph"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              onChange={(e) => {
                                if (
                                  e.target.value === "" ||
                                  pr.test(e.target.value)
                                ) {
                                  let row = this.state.expense_cost_center;
                                  row[idx].pph = e.target.value;
                                  this.setState({
                                    expense_cost_center: row,
                                  });
                                }
                              }}
                              value={e.pph}
                              placeholder="PPH"
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                            />
                          </div>
                        )}
                      </div>
                      <div className="row">
                        <div className="col-lg-6 p-0">
                          <label className="form_label mb-0">
                            Date of Expense
                          </label>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                              autoOk={true}
                              inputVariant="outlined"
                              variant="inline"
                              margin="dense"
                              fullWidth
                              format="dd/MM/yyyy"
                              value={e.expense_date}
                              onChange={(date) => {
                                let row = this.state.expense_cost_center;
                                row[idx].expense_date = dateFormateToDB(date);
                                this.setState({
                                  expense_cost_center: row,
                                });
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton>
                                      <EventIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </div>

                        <div className="col-lg-6 pr-0">
                          <label className="form_label mb-0">
                            Date of Payment
                          </label>

                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                              autoOk={true}
                              inputVariant="outlined"
                              variant="inline"
                              margin="dense"
                              fullWidth
                              format="dd/MM/yyyy"
                              value={e.payment_date}
                              onChange={(date) => {
                                let row = this.state.expense_cost_center;
                                row[idx].payment_date = dateFormateToDB(date);
                                this.setState({
                                  expense_cost_center: row,
                                });
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton>
                                      <EventIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-12 p-0">
                          <label className="form_label mb-0">Remarks</label>
                          <TextField
                            name="remarks"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={3}
                            onChange={(e) => {
                              let row = this.state.expense_cost_center;
                              row[idx].remarks = e.target.value;
                              this.setState({
                                expense_cost_center: row,
                              });
                            }}
                            value={e.remarks}
                            placeholder="Remarks"
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                          />
                        </div>
                      </div>
                      {/* <DeleteIcon style={{ color: 'red' }} onClick={this.removeRowsHandler(idx)} /> */}
                    </fieldset>
                  ))}
                </div>
              )}

              {this.state.view_type === "View" && (
                <div className="col-lg-12 p-0">
                  <div className="row">
                    <div className="col-lg-6 p-0 mb-1">
                      <label className="form_label mb-0">
                        Expenditure Group
                      </label>
                      <div>{this.state.expenditure_group}</div>
                    </div>

                    <div className="col-lg-6 pr-0 mb-1">
                      <label className="form_label mb-0">Type of Expense</label>
                      <div>{this.state.expense_type}</div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 p-0 mb-1">
                      <label className="form_label mb-0">Receivable</label>
                      <div>{this.state.is_receivable ? "True" : "False"}</div>
                    </div>

                    <div className="col-lg-6 pr-0 mb-1">
                      <label className="form_label mb-0">Description</label>
                      <div>{this.state.description}</div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 p-0 mb-1">
                      <label className="form_label mb-0">Base Price</label>
                      <div>{this.toLocaleString(this.state.base_price)}</div>
                    </div>

                    <div className="col-lg-6 pr-0 mb-1">
                      <label className="form_label mb-0">VAT</label>
                      <div>{this.toLocaleString(this.state.vat)}</div>
                    </div>
                  </div>

                  <div className="row">
                    {(config.company === "SDAM" ||
                      config.company === "SRK" ||
                      config.company === "BTR") && (
                      <div className="col-lg-6 p-0 mb-1">
                        <label className="form_label mb-0">PPH</label>
                        <div>{this.toLocaleString(this.state.vat)}</div>
                      </div>
                    )}
                    <div
                      className={
                        config.company === "SDAM" ||
                        config.company === "SRK" ||
                        config.company === "BTR"
                          ? "col-lg-6 pr-0   mb-1"
                          : "col-lg-6 p-0  mb-1"
                      }
                    >
                      <label className="form_label mb-0">Date of Expense</label>
                      <div>{localDateFormate(this.state.expense_date)}</div>
                    </div>

                    <div
                      className={
                        config.company === "SDAM" ||
                        config.company === "SRK" ||
                        config.company === "BTR"
                          ? "col-lg-6 p-0  mb-1"
                          : "col-lg-6 pr-0 mb-1"
                      }
                    >
                      <label className="form_label mb-0">Date of Payment</label>
                      <div>{localDateFormate(this.state.payment_date)}</div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12 p-0 mb-1">
                      <label className="form_label mb-0">Remarks</label>
                      <div style={{ whiteSpace: "pre-line" }}>
                        {this.state.remarks}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="row float-right p-3">
              <button
                type="button"
                className="btn btn-light mr-3"
                onClick={() => {
                  this.setState({
                    openExpenseDrawer: false,
                    selected_account_name: "",
                    selected_account_id: null,
                    expense_cost_center: [],
                  });
                }}
              >
                {this.state.view_type === "View" ? "Close" : "Cancel"}
              </button>
              {(this.state.features.includes("310")  ||
                this.state.features.includes("97") ) &&
                this.state.view_type === "Add" && (
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={
                      this.state.view_type === "View"
                        ? () => this.setState({ view_type: "Edit" })
                        : this.saveExpensesHandler
                    }
                  >
                    {this.state.view_type === "View" ? "Edit" : "Save"}
                  </button>
                )}

              {this.state.view_type === "View" && (
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={
                    this.state.view_type === "View"
                      ? () => this.setState({ view_type: "Edit" })
                      : null
                  }
                >
                  {this.state.view_type === "View" && "Edit"}
                </button>
              )}

              {(this.state.features.includes("312")  ||
                this.state.features.includes("97") ) &&
                this.state.view_type === "Edit" && (
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={
                      this.state.view_type === "View"
                        ? () => this.setState({ view_type: "Edit" })
                        : this.updateExpensesHandler
                    }
                  >
                    {this.state.view_type === "View" ? "Edit" : "Update"}
                  </button>
                )}
            </div>
          </div>
        </Drawer>

        <Drawer
          anchor="right"
          open={this.state.viewAccDetailsDrawer}
          variant="temporary"
          elevation="20"
          style={{ overflow: "hidden" }}
        >
          <div
            className="container bg-white p-0"
            style={{ minHeight: "92vh", width: 850 }}
          >
            {this.state.displayList && (
              <div
                className="card"
                style={{
                  overflowY: "scroll",
                  height: "inherit",
                  minHeight: "90vh",
                }}
              >
                <div className="row">
                  <div className="col pr-0">
                    <label className="form_label mb-0">From Date</label>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        disableToolbar
                        autoOk={true}
                        inputVariant="outlined"
                        variant="inline"
                        margin="dense"
                        fullWidth
                        size="small"
                        format="dd/MM/yyyy"
                        value={this.state.from_date}
                        onChange={(date) => {
                          this.setState({
                            from_date: dateFormateToDB(date),
                          });
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton>
                                <EventIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>

                  <div className="col pr-0">
                    <label className="form_label mb-0">To Date</label>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        disableToolbar
                        autoOk={true}
                        inputVariant="outlined"
                        variant="inline"
                        margin="dense"
                        fullWidth
                        format="dd/MM/yyyy"
                        size="small"
                        value={this.state.to_date}
                        minDate={new Date(this.state.from_date)}
                        onChange={(date) => {
                          this.setState({
                            to_date: dateFormateToDB(date),
                          });
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton>
                                <EventIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>

                  <FormControlLabel
                    style={{ padding: "27px 2px 0px 10px" }}
                    control={
                      <Checkbox
                        checked={this.state.approval_status === "Pending"}
                        //size="small"
                        onChange={(e) => {
                          if (e.target.checked) {
                            this.setState({
                              approval_status: "Pending",
                            });
                          } else {
                            this.setState({
                              approval_status: null,
                            });
                          }
                        }}
                        color="primary"
                      />
                    }
                    label="Pending Approvals"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                  <div className="col-lg-2">
                    <button
                      className="header_button header_button_text add_button_adjustment"
                      style={{ width: "auto", marginTop: "44px" }}
                      onClick={this.get_acc_detailsHandler}
                    >
                      Search
                    </button>
                  </div>
                </div>

                <div className="row mt-2">
                  {this.state.account_history.length === 0 &&
                    !this.state.showExpensesError && (
                      <Loader />
                    )}
                  {this.state.account_history.length === 0 &&
                    this.state.showExpensesError && (
                      <span className="contract_display_header_value pt-2">
                        No expenses made
                      </span>
                    )}
                  {this.state.account_history.length > 0 && (
                    <MaterialTable
                      title=""
                      columns={[
                        {
                          title: "Cost Center",
                          field: "cost_center_name",
                          headerStyle: {
                            minWidth: 200,
                          },
                          render: (row) => {
                            return (
                              <span
                                style={{
                                  cursor: "pointer",
                                  color: config.themeColor,
                                }}
                                // onClick={()=>this.openAccountsData(row)}
                                onClick={(e) =>
                                  this.setState({
                                    displayList: false,
                                    costingCenterView: true,
                                    costingCenterData: row,
                                  })
                                }
                              >
                                {row.cost_center_name}
                              </span>
                            );
                          },
                        },
                        // {
                        //   title: "Account Name",
                        //   field: 'account_name',
                        //   headerStyle: {
                        //     minWidth: 180,
                        //   },
                        // },
                        {
                          title:
                            config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR"
                              ? "Base Price (IDR)"
                              : "Base Price (USD)",
                          field: "base_price",
                          sorting: false,
                          headerStyle: {
                            textAlign: "right",
                            minWidth: 180,
                          },
                          cellStyle: {
                            textAlign: "right",
                          },
                          render: (row) => {
                            return this.toLocaleString(row.base_price);
                          },
                        },
                        {
                          title: "Expense Date",
                          field: "expense_date",
                          headerStyle: {
                            minWidth: 170,
                          },
                          render: (row) => {
                            return localDateFormate(row.expense_date);
                          },
                        },
                        {
                          title: "Created By",
                          field: "created_name",
                          headerStyle: {
                            minWidth: 170,
                          },
                        },
                        {
                          title: "Created Date",
                          field: "created_date",
                          headerStyle: {
                            minWidth: 170,
                          },
                          render: (row) => {
                            return localDateFormate(row.created_date);
                          },
                        },
                        {
                          title: "Approved By",
                          field: "approval_name",
                          headerStyle: {
                            minWidth: 170,
                          },
                          render: (row) => {
                            return row.approval_status !== "Pending"
                              ? row.approval_name
                              : null;
                          },
                        },
                        {
                          title: "Approved Date",
                          field: "approved_date",
                          headerStyle: {
                            minWidth: 170,
                          },
                          render: (row) => {
                            return row.approval_status !== "Pending"
                              ? localDateFormate(row.approved_date)
                              : null;
                          },
                        },
                        {
                          title: "Reference Id",
                          field: "reference_id",
                          headerStyle: {
                            minWidth: 170,
                          },
                          render: (row) => {
                            return row.approval_status !== "Pending"
                              ? row.reference_id
                              : null;
                          },
                        },
                        {
                          title: "Action",
                          field: "approval_status",
                          sorting: false,
                          render: (row) => {
                            if (
                              row.approval_status === "Pending" &&
                              this.Cookie.getCookie("loginUserId") !==
                                row.created_by &&
                              this.state.features.includes("328") 
                            ) {
                              return (
                                <button
                                  type="button"
                                  className="header_button header_button_text add_button_adjustment w-auto mt-2"
                                  onClick={() =>
                                    this.approve_cost_center_expenses(row.id)
                                  }
                                >
                                  Approve
                                </button>
                              );
                            } else {
                              return null;
                            }
                          },
                        },
                      ]}
                      data={this.state.account_history}
                      isLoading={this.state.loading}
                      style={{
                        minHeight: 180,
                        fontFamily: "Poppins",
                        overflowY: "auto",
                        boxShadow: "0 0 5px 0 rgba(0, 39, 77, 0.1)",
                      }}
                      options={{
                        paginationType: "stepped",
                        paging: false,
                        pageSize: 100,
                        pageSizeOptions: [5, 10, 20, 50, 100, 200],
                        headerStyle: {
                          backgroundColor: "#f9f9f9",
                          // maxWidth: 200,
                          fontFamily: "Poppins",
                          fontSize: 14,
                          fontWeight: 400,
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: "0.65",
                          letterSpacing: "normal",
                          textAlign: "left",
                          color: "#42352d",
                        },
                        cellStyle: {
                          textAlign: "left",
                          fontFamily: "Poppins",
                        },
                        search: true,
                        draggable: false,
                        actionsColumnIndex: -1,
                      }}
                    />
                  )}
                </div>
              </div>
            )}
            {this.state.costingCenterView && (
              <div
                className="card"
                style={{
                  overflowY: "scroll",
                  height: "inherit",
                  minHeight: "90vh",
                }}
              >
                <fieldset className="col-lg-12 border rounded pt-2 pb-2 mb-2">
                  <legend
                    className="w-auto m-0 rounded"
                    style={{
                      fontSize: 14,
                      paddingLeft: 10,
                      paddingTop: 3,
                      paddingBottom: 5,
                      paddingRight: 10,
                      color: config.themeColor,
                    }}
                  >
                    {this.state.costingCenterData.cost_center_name}
                  </legend>
                  <div className="row">
                    <div className="col-lg-6 p-0 mb-1">
                      <label className="form_label mb-0">Invoice No</label>
                      <div>
                        {this.state.costingCenterData.invoice_no
                          ? this.state.costingCenterData.invoice_no
                          : "-"}
                      </div>
                    </div>
                    <div className="col-lg-6 pr-0 mb-1">
                      <label className="form_label mb-0">Invoice Date</label>
                      <div>
                        {localDateFormate(
                          this.state.costingCenterUpdate.invoice_date
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 p-0 mb-1">
                      <label className="form_label mb-0">
                        Fakthur Pazak No
                      </label>
                      <div>
                        {this.state.costingCenterData.ppn
                          ? this.state.costingCenterData.ppn
                          : "-"}
                      </div>
                    </div>
                    <div className="col-lg-6 pr-0 mb-1">
                      <label className="form_label mb-0">
                        {config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL"
                          ? "Base Price (USD)"
                          : "Base Price (IDR)"}
                      </label>
                      <div>{this.state.costingCenterData.base_price}</div>
                    </div>
                    <div
                      className={
                        config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL"
                          ? "col-lg-6 pl-0 mb-1"
                          : "col-lg-3 pl-0 mb-1"
                      }
                    >
                      <label className="form_label mb-0">VAT</label>
                      <div>{this.state.costingCenterData.vat}</div>
                    </div>
                    {(config.company === "SDAM" ||
                      config.company === "SRK" ||
                      config.company === "BTR") && (
                      <div className="col-lg-3 pl-0 mb-1">
                        <label className="form_label mb-0">PPH</label>
                        <div>{this.state.costingCenterData.pph}</div>
                      </div>
                    )}
                  </div>

                  <div className="row">
                    <div className="col-lg-6 pr-0 mb-1">
                      <label className="form_label mb-0">Date of Expense</label>
                      <div>
                        {localDateFormate(
                          this.state.costingCenterData.expense_date
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 p-0 mb-1">
                      <label className="form_label mb-0">Date of Payment</label>
                      <div>
                        {localDateFormate(
                          this.state.costingCenterData.payment_date
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12 mb-1">
                      <label className="form_label mb-0">Remarks</label>
                      <div style={{ whiteSpace: "pre-line" }}>
                        {this.state.costingCenterData.remarks}
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
            )}
            {this.state.costingCenterUpdate && (
              <div
                className="card"
                style={{
                  overflowY: "scroll",
                  height: "inherit",
                  minHeight: "90vh",
                }}
              >
                <fieldset className="col-lg-12 border rounded pt-2 pb-2 mb-2">
                  <legend
                    className="w-auto m-0 rounded"
                    style={{
                      fontSize: 14,
                      paddingLeft: 10,
                      paddingTop: 3,
                      paddingBottom: 5,
                      paddingRight: 10,
                      color: config.themeColor,
                    }}
                  >
                    {this.state.costingCenterData.cost_center_name}
                  </legend>
                  <div className="row">
                    <div className="col-lg-4 p-0">
                      <label className="form_label mb-0">Invoice No</label>

                      <TextField
                        name="invoice_no"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        onChange={(e) => {
                          let row = this.state.costingCenterData;
                          row.invoice_no = e.target.value;
                          this.setState({
                            costingCenterData: row,
                          });
                        }}
                        value={this.state.costingCenterData.invoice_no}
                        placeholder="Invoice No"
                        inputProps={{ style: { textTransform: "uppercase" } }}
                      />
                    </div>

                    <div className="col-lg-4 pr-0">
                      <label className="form_label mb-0">Date of Payment</label>

                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          autoOk={true}
                          inputVariant="outlined"
                          variant="inline"
                          margin="dense"
                          fullWidth
                          format="dd/MM/yyyy"
                          value={this.state.costingCenterData.invoice_date}
                          onChange={(date) => {
                            let row = this.state.costingCenterData;
                            row.invoice_date = dateFormateToDB(date);
                            this.setState({
                              costingCenterData: row,
                            });
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton>
                                  <EventIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                    <div className="col-lg-4 pr-0">
                      <label className="form_label mb-0">
                        Fakthur Pazak No
                      </label>

                      <TextField
                        name="ppn"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        onChange={(e) => {
                          let row = this.state.costingCenterData;
                          row.ppn = e.target.value;
                          this.setState({
                            costingCenterData: row,
                          });
                        }}
                        value={this.state.costingCenterData.ppn}
                        placeholder="Fakthur Pazak No"
                        inputProps={{ style: { textTransform: "uppercase" } }}
                      />
                    </div>

                    <div className="col-lg-6 p-0">
                      <label className="form_label mb-0">Base Price</label>
                      <TextField
                        name="base_price"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        onChange={(e) => {
                          const reg = /^[\d./-]+$/;

                          if (
                            e.target.value === "" ||
                            reg.test(e.target.value)
                          ) {
                            let row = this.state.costingCenterData;
                            row.base_price = e.target.value;
                            this.setState({
                              costingCenterData: row,
                            });
                          }
                        }}
                        value={this.state.costingCenterData.base_price}
                        placeholder="Base Price"
                        inputProps={{ style: { textTransform: "uppercase" } }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {config.company === "SRPL" || config.company === "AVS"  ||
                              config.company === "PRPL"
                                ? "USD"
                                : "IDR"}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <div
                      className={
                        config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL"
                          ? "col-lg-6 pr-0"
                          : "col-lg-3 pr-0"
                      }
                    >
                      <label className="form_label mb-0">VAT</label>
                      <TextField
                        name="vat"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        onChange={(e) => {
                          if (
                            e.target.value === "" ||
                            pr.test(e.target.value)
                          ) {
                            let row = this.state.costingCenterData;
                            row.vat = e.target.value;
                            this.setState({
                              costingCenterData: row,
                            });
                          }
                        }}
                        value={this.state.costingCenterData.vat}
                        placeholder="VAT"
                        inputProps={{ style: { textTransform: "uppercase" } }}
                      />
                    </div>
                    {(config.company === "SDAM" ||
                      config.company === "SRK" ||
                      config.company === "BTR") && (
                      <div className="col-lg-3 pr-0">
                        <label className="form_label mb-0">PPH</label>
                        <TextField
                          name="pph"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          onChange={(e) => {
                            if (
                              e.target.value === "" ||
                              pr.test(e.target.value)
                            ) {
                              let row = this.state.costingCenterData;
                              row.pph = e.target.value;
                              this.setState({
                                costingCenterData: row,
                              });
                            }
                          }}
                          value={this.state.costingCenterData.pph}
                          placeholder="PPH"
                          inputProps={{ style: { textTransform: "uppercase" } }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-lg-6 p-0">
                      <label className="form_label mb-0">Date of Expense</label>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          autoOk={true}
                          inputVariant="outlined"
                          variant="inline"
                          margin="dense"
                          fullWidth
                          format="dd/MM/yyyy"
                          value={this.state.costingCenterData.expense_date}
                          onChange={(date) => {
                            let row = this.state.costingCenterData;
                            row.expense_date = dateFormateToDB(date);
                            this.setState({
                              costingCenterData: row,
                            });
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton>
                                  <EventIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </div>

                    <div className="col-lg-6 pr-0">
                      <label className="form_label mb-0">Date of Payment</label>

                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          autoOk={true}
                          inputVariant="outlined"
                          variant="inline"
                          margin="dense"
                          fullWidth
                          format="dd/MM/yyyy"
                          value={this.state.costingCenterData.payment_date}
                          onChange={(date) => {
                            let row = this.state.costingCenterData;
                            row.payment_date = dateFormateToDB(date);
                            this.setState({
                              costingCenterData: row,
                            });
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton>
                                  <EventIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12 p-0">
                      <label className="form_label mb-0">Remarks</label>
                      <TextField
                        name="remarks"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={3}
                        onChange={(e) => {
                          let row = this.state.costingCenterData;
                          row.remarks = e.target.value;
                          this.setState({
                            costingCenterData: row,
                          });
                        }}
                        value={this.state.costingCenterData.remarks}
                        placeholder="Remarks"
                        inputProps={{ style: { textTransform: "uppercase" } }}
                      />
                    </div>
                  </div>
                  {/* <DeleteIcon style={{ color: 'red' }} onClick={this.removeRowsHandler(idx)} /> */}
                </fieldset>
              </div>
            )}
          </div>
          {this.state.costingCenterView && (
            <div className="row bottom_buttons_section">
              <button
                type="button"
                className="btn previous_button btn-light"
                onClick={() =>
                  this.setState({
                    costingCenterView: false,
                    displayList: false,
                    costingCenterUpdate: true,
                  })
                }
              >
                Edit
              </button>
              <button
                type="button"
                className="drawer_text drawer_btn_success"
                onClick={() =>
                  this.setState({
                    costingCenterView: false,
                    displayList: true,
                    costingCenterUpdate: false,
                  })
                }
              >
                Back
              </button>
            </div>
          )}
          {this.state.costingCenterUpdate && (
            <div className="row bottom_buttons_section">
              <button
                type="button"
                className="btn previous_button btn-light"
                onClick={this.updateCostingCenterHandler}
              >
                Update
              </button>
              <button
                type="button"
                className="drawer_text drawer_btn_success"
                onClick={() =>
                  this.setState({
                    costingCenterView: true,
                    displayList: false,
                    costingCenterUpdate: false,
                  })
                }
              >
                Cancel
              </button>
            </div>
          )}
          {this.state.displayList && (
            <div className="row bottom_buttons_section">
              <button
                type="button"
                className="drawer_text drawer_btn_success"
                onClick={() =>
                  this.setState({
                    viewAccDetailsDrawer: false,
                    displayList: false,
                    costingCenterView: false,
                    costingCenterUpdate: false,
                    showExpensesError: false,
                    expense_cost_center: [],
                    account_history: [],
                    from_date: null,
                    to_date: null,
                  })
                }
              >
                Back
              </button>
            </div>
          )}
        </Drawer>
      </div>
    );
  }
}
