import React, { Component } from "react";
import api from "../../../api/api";
import CookieHandler from "../../common/CookieHandler";
import { Alert } from "@material-ui/lab";
import { Snackbar, Tooltip } from "@material-ui/core";
import config from "../../../config/config";
import Loader from "../../common/Loader";
export default class BargeCoalSalesChargesView extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      isLoading: true,
      multiRows: [],
      features: [],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const idToken = this.Cookie.getIdTokenCookie();
    const loginUserID = this.Cookie.getCookie("loginUserId");
    var comboID = this.props.comboID;
    const barge_id = comboID.split("$#")[0];
    // var sale_contract_id = comboID.split('$#')[1];
    const get_csc = await api.get_barge_sales_fin_csc(
      loginUserID,
      idToken,
      barge_id
    );

    if (get_csc.status >= 200 && get_csc.status < 300) {
      get_csc.json().then((res) => {
        if (res.code === "200") {
          this.setState({
            multiRows: res.financials,
            isLoading: false,
          });
        } else if (res.code === "601") {
          this.setState({
            snackBarErrorOpen: true,
            errorMsg: res.message,
          });
        } else if (res.code === "607") {
          window.location.href = "/logout";
        }
      });
    }
  }

  getLocalDate(date) {
    if (date) {
      let dateValue = date.split(" ");
      let dateString = dateValue[0].split("-");
      var newDate = dateString[2] + "/" + dateString[1] + "/" + dateString[0];
      var time = dateValue[1];
      var finalDate = newDate + " " + time;
      return finalDate;
    } else return null;
  }

  toLocaleString(value) {
    return value ? Number(value).toLocaleString() : 0;
  }

  render() {
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div
          className="container bg-white p-0"
          style={{ minHeight: "100vh", width: 850 }}
        >
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <div className="clearfix content-header border-bottom">
            <div className="row">
              <div className="col-lg-10 text-left pl-0">
                <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                  Coal Sales Charges{" "}
                </h4>
              </div>
            </div>
          </div>
          <div className="drawer-section">
            <div className="card p-3">
              <div
                className="card-body p-0"
                style={{ backgroundColor: "rgba(25,118,210,.01)" }}
                id="accordion"
              >
                {this.state.multiRows.map((e, idx) => (
                  <div className="row" key={idx}>
                    <div className="col-lg-12 border_dashed rounded-lg p-2 mb-3">
                      <div className="row">
                        <div className="col-lg-4  mb-3">
                          <label className="contract_display_header_label mb-1">
                            Invoice No
                          </label>
                          <div className="contract_display_header_value text-uppercase">
                            {e.invoice_no ? e.invoice_no : "-"}
                          </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label className="contract_display_header_label mb-1">
                            Invoice Date
                          </label>
                          <div className="contract_display_header_value">
                            {e.invoice_date
                              ? this.getLocalDate(e.invoice_date)
                              : "-"}
                          </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label className="contract_display_header_label mb-1">
                            Fakthur Pazak No
                          </label>
                          <div className="contract_display_header_value text-uppercase">
                            {e.ppn ? e.ppn : "-"}
                          </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label className="contract_display_header_label mb-1">
                            Fakthur Pazak Date
                          </label>
                          <div className="contract_display_header_value text-uppercase">
                            {e.ppn_date ? this.getLocalDate(e.ppn_date) : "-"}
                          </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label className="contract_display_header_label">
                            1st Level Approval{" "}
                            {e.approval_status === "Pending"
                              ? ""
                              : "Approved By"}
                          </label>
                          <div className="contract_display_header_value">
                            {" "}
                            {e.approval_status === "Pending"
                              ? e.approval_status
                              : e.approved_by_name}
                          </div>
                        </div>

                        <div className="col-lg-4 mb-3">
                          <label className="contract_display_header_label">
                            1st Level Approved On
                          </label>
                          <div className="contract_display_header_value">
                            {" "}
                            {e.approved_date
                              ? this.getLocalDate(e.approved_date)
                              : "-"}
                          </div>
                        </div>

                        <div className="col-lg-4 mb-3">
                          <label className="contract_display_header_label">
                            2nd Level Approval{" "}
                            {e.approval_status_2 === "Pending"
                              ? ""
                              : "Approved By"}
                          </label>
                          <div className="contract_display_header_value">
                            {" "}
                            {e.approval_status_2 === "Pending"
                              ? e.approval_status_2
                              : e.approved_by_name_2}
                          </div>
                        </div>

                        <div className="col-lg-4 mb-3">
                          <label className="contract_display_header_label">
                            2nd Level Approved On
                          </label>
                          <div className="contract_display_header_value">
                            {" "}
                            {e.approved_date_2
                              ? this.getLocalDate(e.approved_date_2)
                              : "-"}
                          </div>
                        </div>
                        {e.approval_status === "Pending" &&
                          this.Cookie.getCookie("loginUserId") !==
                            e.created_by &&
                          e.approved_by !==
                            this.Cookie.getCookie("loginUserId") &&
                          (this.state.features.includes("429")  ||
                            this.state.features.includes("480")  ||
                            this.state.features.includes("97") ) && (
                            <div
                              className="col-lg text-right float-sm-left"
                              style={{ padding: "5px 20px" }}
                            >
                              <button
                                className="header_button header_button_text add_button_adjustment"
                                style={{ width: "auto", marginTop: "17px" }}
                                onClick={() => this.approveHandler(e.id)}
                              >
                                Approve
                              </button>
                            </div>
                          )}
                      </div>
                      <div className="row mb-3 ">
                        <div className="col-lg-5 pl-0">
                          <div className="row">
                            <div className="col-lg-12  mb-3 p-0">
                              <label className="contract_display_header_label">
                                Payment to
                              </label>
                              <div className="">
                                {e.payment_to_vendor_name}
                              </div>
                            </div>
                            {(e.payment_to === null || e.payee_name) && (
                              <div className="col-lg-12 mb-1 p-0">
                                <label className="contract_display_header_label mb-1">
                                  Payee Name
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ textTransform: "uppercase" }}
                                >
                                  {e.payee_name}
                                </div>
                              </div>
                            )}

                            <div className="col-lg-6 mb-1 p-0">
                              <label className="contract_display_header_label mb-1">
                                Quantity in MT
                              </label>
                              <div className="contract_display_header_value">
                                {e.barge_quantity
                                  ? this.toLocaleString(e.barge_quantity)
                                  : "-"}
                              </div>
                            </div>

                            <div className="col-lg-6 mb-1 p-0">
                              <label className="contract_display_header_label mb-1">
                                Base Price PMT
                              </label>
                              <div className="contract_display_header_value">
                                {e.base_price_pmt
                                  ? this.toLocaleString(e.base_price_pmt)
                                  : 0}{" "}
                                {config.company === "SRPL" || config.company === "AVS"  ||
                                config.company === "PRPL"
                                  ? "USD"
                                  : "IDR"}
                              </div>
                            </div>

                            {(config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") && (
                              <div className="col-lg-12 mb-1 p-0">
                                <label className="contract_display_header_label mb-1">
                                  PPH23 Account
                                </label>
                                <div className="contract_display_header_value">
                                  {e.pph23_account}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>

                        {(config.company === "SDAM" ||
                          config.company === "SRK" ||
                          config.company === "BTR") &&
                          e.pph23_account &&
                          e.pph23_account === "Supplier" && (
                            <div
                              className="col-lg-7 p-2 rounded"
                              style={{ backgroundColor: "#f7f3f0" }}
                            >
                              <div className="row col-lg-12 p-0">
                                <h6>Price</h6>
                              </div>
                              <div className="row">
                                <div className="col-4 p-0 mb-2">
                                  <label
                                    className="contract_display_header_label"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    Base price
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {e.base_price
                                      ? this.toLocaleString(e.base_price)
                                      : "-"}
                                  </div>
                                </div>
                                <div className="col-4 pr-0 mb-2">
                                  <label
                                    className="contract_display_header_label"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    VAT %
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {e.vat_percent
                                      ? this.toLocaleString(e.vat_percent)
                                      : "-"}
                                  </div>
                                </div>
                                <div className="col-4 p-0 mb-2">
                                  <label
                                    className="contract_display_header_label"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    VAT
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {e.vat_value
                                      ? this.toLocaleString(e.vat_value)
                                      : "-"}
                                  </div>
                                </div>
                                {/* <div className='col-lg-6 mb-3'>
                              <label className="contract_display_header_label" style={{ color: 'rgba(72, 59, 52, 0.7)' }}> PPH23 @ {e.pph23_percent ? (e.pph23_percent + ' %') : null}</label>
                              <div className='contract_display_header_value' style={{ color: '#42352d' }}>{e.pph23_value ? this.toLocaleString(e.pph23_value) : "-"}</div>
                              
                            </div> */}
                                <div className="col-lg-6 p-0 mb-3">
                                  <label
                                    className="contract_display_header_label"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    Includes PPH23 @{" "}
                                    {e.pph23_percent
                                      ? e.pph23_percent + " %"
                                      : null}
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {e.pph23_value
                                      ? this.toLocaleString(e.pph23_value)
                                      : "-"}
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-lg-6 p-0">
                                  <label
                                    className="contract_display_header_label"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    Payable to Vendor
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {e.payable_to_vendor
                                      ? this.toLocaleString(e.payable_to_vendor)
                                      : 0}{" "}
                                    {config.company === "SRPL" || config.company === "AVS"  ||
                                    config.company === "PRPL"
                                      ? "USD"
                                      : "IDR"}
                                  </div>
                                </div>
                                <div className="col-lg-6 pr-0">
                                  <label
                                    className="contract_display_header_label"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    Cost to Company
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {e.cost_to_company
                                      ? this.toLocaleString(e.cost_to_company)
                                      : 0}{" "}
                                    {config.company === "SRPL" || config.company === "AVS"  ||
                                    config.company === "PRPL"
                                      ? "USD"
                                      : "IDR"}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        {e.pph23_account === "SDAM" && (
                          <div
                            className="col-lg-7 p-2 rounded"
                            style={{ backgroundColor: "#f7f3f0" }}
                          >
                            <div className="row col-lg-12 p-0">
                              <h6>Price</h6>
                            </div>
                            <div className="row">
                              <div className="col-6 p-0 mb-2">
                                <label
                                  className="contract_display_header_label"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  Base price
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {e.base_price
                                    ? this.toLocaleString(e.base_price)
                                    : "-"}
                                </div>
                              </div>
                              {/* <div className='col-6 pr-0 mb-2'>
                                <label className="contract_display_header_label" style={{ color: 'rgba(72, 59, 52, 0.7)' }}>VAT %</label>
                                <div className='contract_display_header_value' style={{ color: '#42352d' }}>{p.vat_percent ? this.toLocaleString(p.vat_percent) : "-"}</div>
                              </div>
                              <div className='col-6 p-0 mb-2'>
                                <label className="contract_display_header_label" style={{ color: 'rgba(72, 59, 52, 0.7)' }}>VAT</label>
                                <div className='contract_display_header_value' style={{ color: '#42352d' }}>{e.vat_value ? this.toLocaleString(e.vat_value) : "-"}</div>
                              </div> */}
                              <div className="col-lg-6 mb-3">
                                <label
                                  className="contract_display_header_label"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  Includes PPH23 @{" "}
                                  {e.pph23_percent
                                    ? e.pph23_percent + " %"
                                    : null}
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {e.pph23_value
                                    ? this.toLocaleString(e.pph23_value)
                                    : "-"}
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-lg-6 p-0">
                                <label
                                  className="contract_display_header_label"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  Payable to Vendor
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {e.payable_to_vendor
                                    ? this.toLocaleString(e.payable_to_vendor)
                                    : 0}{" "}
                                  {config.company === "SRPL" || config.company === "AVS"  ||
                                  config.company === "PRPL"
                                    ? "USD"
                                    : "IDR"}
                                </div>
                              </div>
                              <div className="col-lg-6 pr-0">
                                <label
                                  className="contract_display_header_label"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  Cost to Company
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {e.cost_to_company
                                    ? this.toLocaleString(e.cost_to_company)
                                    : 0}{" "}
                                  {config.company === "SRPL" || config.company === "AVS"  ||
                                  config.company === "PRPL"
                                    ? "USD"
                                    : "IDR"}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {(config.company === "SRPL" || config.company === "AVS"  ||
                          config.company === "PRPL") && (
                          <div
                            className="col-lg-7 p-3 rounded"
                            style={{ backgroundColor: "#f7f3f0" }}
                          >
                            <div className="row col-lg-12 p-0">
                              <h6>Price</h6>
                            </div>
                            <div className="row">
                              <div className="col-4 p-0 mb-2">
                                <label
                                  className="contract_display_header_label"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  Base price
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {e.base_price
                                    ? this.toLocaleString(e.base_price)
                                    : "-"}
                                </div>
                              </div>
                              <div className="col-4 pr-0 mb-2">
                                <label
                                  className="contract_display_header_label"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  VAT %
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {e.vat_percent
                                    ? this.toLocaleString(e.vat_percent)
                                    : "-"}
                                </div>
                              </div>
                              <div className="col-4 p-0 mb-2">
                                <label
                                  className="contract_display_header_label"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  VAT
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {e.vat_value
                                    ? this.toLocaleString(e.vat_value)
                                    : "-"}
                                </div>
                              </div>
                              {/* <div className='col-lg-6 mb-3'>
                              <label className="contract_display_header_label" style={{ color: 'rgba(72, 59, 52, 0.7)' }}>Includes PPH23 @ {e.pph23_percent ? (e.pph23_percent + ' %') : null}</label>
                              <div className='contract_display_header_value' style={{ color: '#42352d' }}>{e.pph23_value ? this.toLocaleString(e.pph23_value) : "-"}</div>
                            </div> */}
                            </div>

                            <div className="row">
                              <div className="col-lg-6 p-0">
                                <label
                                  className="contract_display_header_label"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  Payable to Vendor
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {e.payable_to_vendor
                                    ? this.toLocaleString(e.payable_to_vendor)
                                    : 0}{" "}
                                  {config.company === "SRPL" || config.company === "AVS"  ||
                                  config.company === "PRPL"
                                    ? "USD"
                                    : "IDR"}
                                </div>
                              </div>
                              <div className="col-lg-6 pr-0">
                                <label
                                  className="contract_display_header_label"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  Cost to Company
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {e.cost_to_company
                                    ? this.toLocaleString(e.cost_to_company)
                                    : 0}{" "}
                                  {config.company === "SRPL" || config.company === "AVS"  ||
                                  config.company === "PRPL"
                                    ? "USD"
                                    : "IDR"}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="row mb-3">
                        <div className="col-lg-12 p-0">
                          <label className="contract_display_header_label">
                            Remarks
                          </label>
                          <div
                            className=""
                            style={{
                              textTransform: "uppercase",
                              whiteSpace: "pre-line",
                            }}
                          >
                            {e.remarks ? e.remarks : "-"}
                          </div>
                        </div>
                      </div>

                      <div className="card col-lg-12 p-0 border">
                        <div className="card-header section_header">
                          <h5>Payment Postings</h5>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-lg-4 mb-2">
                              <label className="contract_display_header_label">
                                Paid To vendor
                              </label>
                              <div className="contract_display_header_value">
                                {" "}
                                {e.paid_to_vendor
                                  ? this.toLocaleString(e.paid_to_vendor)
                                  : 0}{" "}
                                {config.company === "SRPL" || config.company === "AVS"  ||
                                config.company === "PRPL"
                                  ? "USD"
                                  : "IDR"}
                              </div>
                            </div>
                            <div className="col-lg-4 mb-2">
                              <label className="contract_display_header_label">
                                Remaining Amount
                              </label>
                              <div className="contract_display_header_value">
                                {" "}
                                {e.remaining_amount
                                  ? this.toLocaleString(e.remaining_amount)
                                  : 0}{" "}
                                {config.company === "SRPL" || config.company === "AVS"  ||
                                config.company === "PRPL"
                                  ? "USD"
                                  : "IDR"}
                              </div>
                            </div>
                          </div>

                          {(this.state.features.includes("291")  ||
                            this.state.features.includes("97") ) && (
                            // <fieldset className='border p-2 m-2 rounded'>
                            //   <legend className='w-auto text-white bg-dark rounded' style={{ fontSize: 14, paddingLeft: 10, paddingTop: 3, paddingBottom: 5, paddingRight: 10 }}>Coal Sales Charges Payment Postings</legend>
                            <div>
                              <div className="row">
                                <div className="col-lg-12">
                                  <div className="clearfix">
                                    <div className="float-left">
                                      {!e.csc_payments
                                        ? ""
                                        : e.csc_payments.length <= 0 && (
                                            <p style={{ fontWeight: "bold" }}>
                                              No payments made
                                            </p>
                                          )}
                                    </div>
                                    <div className="float-right">
                                      {(this.state.features.includes("290") >=
                                        0 ||
                                        this.state.features.includes("97") >=
                                          0) && (
                                        <button
                                          className="btn header_button header_button_text w-auto"
                                          onClick={() => {
                                            this.setState({
                                              paymentTitle:
                                                "Add Coal Sales Charges",
                                              payment_posting_type: "cpc",
                                              remainingAmount:
                                                e.remaining_amount,
                                              mv_sales_financials_id: e.id,
                                              date_of_payment: null,
                                              paymentPostingsModal: true,
                                              amount: "",
                                              invoice_no: "",
                                              description: "",
                                              paymentErrorMsg: "",
                                              date_of_paymentError: false,
                                              amountError: false,
                                              paymentMode: "Add",
                                              invoice_noError: false,
                                            });
                                          }}
                                        >
                                          Add Payment
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {!e.csc_payments
                                ? ""
                                : e.csc_payments.length > 0 && (
                                    <div className="row mt-2">
                                      <div className="col-lg">
                                        <div className="table-responsive">
                                          <table className="table table-borderless border-0">
                                            <thead>
                                              <tr>
                                                <th
                                                  className="table_header_barge_label"
                                                  nowrap="true"
                                                >
                                                  Date Received
                                                </th>
                                                <th
                                                  className="table_header_barge_label text-right"
                                                  nowrap="true"
                                                >
                                                  Amount
                                                </th>
                                                <th
                                                  className="table_header_barge_label"
                                                  nowrap="true"
                                                >
                                                  Invoice No
                                                </th>
                                                <th
                                                  className="table_header_barge_label"
                                                  style={{
                                                    width: 300,
                                                    maxWidth: 250,
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  Description
                                                </th>
                                                <th
                                                  className="table_header_barge_label"
                                                  nowrap="true"
                                                >
                                                  Created Date
                                                </th>
                                                {/* <th className="table_header_barge_label" nowrap='true'>Approved By</th>
                                          <th className="table_header_barge_label" nowrap='true'>Approved Date</th> */}
                                                <th
                                                  className="table_header_barge_label"
                                                  nowrap="true"
                                                >
                                                  Action
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {e.csc_payments.map(
                                                (p, index) => (
                                                  <tr key={index}>
                                                    <td
                                                      className="table_td border-0"
                                                      nowrap="true"
                                                    >
                                                      {p.date_of_payment
                                                        ? this.getLocalDate(
                                                            p.date_of_payment
                                                          )
                                                        : null}
                                                    </td>
                                                    <td
                                                      className="table_td border-0 text-right"
                                                      nowrap="true"
                                                    >
                                                      {p.amount
                                                        ? this.toLocaleString(
                                                            p.amount
                                                          )
                                                        : ""}
                                                    </td>
                                                    <td
                                                      className="table_td border-0"
                                                      nowrap="true"
                                                    >
                                                      {p.invoice_no}
                                                    </td>
                                                    <td className="table_td border-0">
                                                      {p.description}
                                                    </td>
                                                    <td
                                                      className="table_td border-0"
                                                      nowrap="true"
                                                    >
                                                      {p.created_date
                                                        ? this.getLocalDate(
                                                            p.created_date
                                                          )
                                                        : null}
                                                    </td>
                                                    {/* <td className='table_td border-0' nowrap='true'>{p.approved_by_name ? p.approved_by_name : '-'}</td>
                                            <td className='table_td border-0' nowrap='true'>{p.approved_date ? this.dateFormat(p.approved_date) : null}</td> */}
                                                    <td
                                                      className="table_td border-0"
                                                      nowrap="true"
                                                    >
                                                      {/* {(p.approval_status === "Pending" && this.Cookie.getCookie('loginUserId') !== p.created_by && (this.state.features.includes('431')  || this.state.features.includes('97') )) &&

                                                <Tooltip title="Approve" arrow>

                                                  <i className="fa fa-check-circle mr-2"
                                                    style={{ fontSize: 14, color: config.themeColor, cursor: 'pointer' }} data-toggle="modal"
                                                    title="Edit" data-placement="bottom"
                                                    onClick={() => this.approvePaymentsHandler(p.id)}>

                                                  </i>
                                                </Tooltip>
                                              } */}

                                                      {(this.state.features.includes(
                                                        "292"
                                                      )  ||
                                                        this.state.features.includes(
                                                          "97"
                                                        ) ) && (
                                                        <Tooltip
                                                          title="Edit"
                                                          arrow
                                                        >
                                                          <i
                                                            className="fa fa-pencil mr-2"
                                                            style={{
                                                              fontSize: 14,
                                                              color:
                                                                config.themeColor,
                                                              cursor: "pointer",
                                                            }}
                                                            data-toggle="modal"
                                                            title="Edit Coal Payment Posting"
                                                            data-placement="bottom"
                                                            onClick={() => {
                                                              this.setState({
                                                                payment_id:
                                                                  p.id,
                                                                remainingAmount:
                                                                  e.remaining_amount,
                                                                paymentPostingsModal: false,
                                                              });
                                                              this.getPaymentDetalis(
                                                                p
                                                              );
                                                            }}
                                                          ></i>
                                                        </Tooltip>
                                                      )}
                                                      {(this.state.features.includes(
                                                        "293"
                                                      )  ||
                                                        this.state.features.includes(
                                                          "97"
                                                        ) ) && (
                                                        <Tooltip
                                                          title="Delete"
                                                          arrow
                                                        >
                                                          <i
                                                            className="fa fa-trash-o"
                                                            style={{
                                                              paddingLeft: 10,
                                                              fontSize: 14,
                                                              color:
                                                                config.themeColor,
                                                              cursor: "pointer",
                                                            }}
                                                            data-toggle="modal"
                                                            title="Delete Coal Payment Posting"
                                                            data-placement="bottom"
                                                            onClick={() =>
                                                              this.setState({
                                                                deletePaymentData:
                                                                  p,
                                                                deleteConformationModal: true,
                                                              })
                                                            }
                                                          ></i>
                                                        </Tooltip>
                                                      )}
                                                    </td>
                                                  </tr>
                                                )
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                              {/* </fieldset> */}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {(this.state.features.includes("525")  ||
                      this.state.features.includes("97") ) && (
                      <div className="card col-lg-12 border mb-3">
                        <span
                          className="col-lg-12 contract_display_header_value pl-0 mb-2"
                          style={{ fontWeight: "bold" }}
                        >
                          Uploaded Documents
                        </span>
                        {e.files.length > 0 ? (
                          <div className="row">
                            {e.files.map((f, index) => (
                              <div
                                className={
                                  f.file_path ? "col-lg-12 mb-3" : "col-lg-12"
                                }
                                key={index}
                              >
                                {f.file_path && (
                                  <div className="col-lg-12 p-0">
                                    <a
                                      href={
                                        config.apiDomain + "/" + f.file_path
                                      }
                                      role="button"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      style={{ color: config.themeColor }}
                                    >
                                      {f.file_description}
                                    </a>
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div className="row col-lg-12">
                            <p>No files to show</p>
                          </div>
                        )}
                        {this.props.returnDocumentsHandler && (
                          <p className="row col-lg-12">
                            Add Documents &nbsp;
                            <i
                              className="fa fa-plus-circle"
                              style={{
                                color: config.themeColor,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                this.props.returnDocumentsHandler(true);
                              }}
                            />
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <footer className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button btn-light"
              name="submit"
              onClick={() => {
                this.props.returnCallbackDrawerClose(false);
              }}
            >
              Close
            </button>
            <button
              type="button"
              className="drawer_text drawer_btn_success"
              name="Back"
              onClick={() => {
                this.props.onEdit(true);
              }}
            >
              Edit
            </button>
          </footer>
        </div>
      );
    }
  }
}
