

import React, { useEffect, useState } from 'react'
import config from '../../config/config';
import {
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Slide
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import api from '../../api/api';
import { toLocaleString } from '../common/common';
import CookieHandler from '../common/CookieHandler';
const NewRadio = withStyles({
  root: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Cookie = new CookieHandler();

export default function SalesAdvanceAdjustments(props) {

  const { open, adjustments = [], costing_id, table_name, callbackFn, closeModal } = props || {};
  const { info: { sales_contract_id, sales_contract_no, remaining_cus_adv_payment } = {} } = props;

  const [advance_adjustments, setAdvanceAdjustments] = useState([{
    advance_adjustment: "No",
    vendor_adv_contract_id: null,
    advance_adjustment_value: "",
    advance_adjustment_barge_quantity: "",
    adjustment_per_mt: "",
    adjustment_from: "",
    vendor_contract_no: "",
    sales_contract_id: sales_contract_id,
    sales_contract_no: sales_contract_no,
    remaining_cus_adv_payment: remaining_cus_adv_payment,
  }]);

  useEffect(() => {
    setAdvanceAdjustments(adjustments)
  }, [adjustments]);


  const quan = /^\d*(\.\d{0,4})?$/;
  const pr = /^\d*(\.\d{0,4})?$/;

  const AddMoreRowsHandler = () => {
    const item = {
      advance_adjustment: "No",
      vendor_adv_contract_id: null,
      advance_adjustment_value: "",
      advance_adjustment_barge_quantity: "",
      adjustment_per_mt: "",
      adjustment_from: "",
      pc_id: null,
      sales_contract_id: sales_contract_id,
      sales_contract_no: sales_contract_no,
      remaining_cus_adv_payment: remaining_cus_adv_payment,
      pc_remaining_amt: "",
    };

    setAdvanceAdjustments([...advance_adjustments, item]);
  }

  const RemoveRowsHandler = (idx) => {
    const row = advance_adjustments;
    row.splice(idx, 1);
    setAdvanceAdjustments([...row]);
    // callbackFn(row);
  };

  const handleAdvanceAdjustmentHandler = (idx, name, value) => {
    const row = advance_adjustments;
    row[idx][name] = value;
    if (!row[idx].sales_contract_id) {
      row[idx].sales_contract_id = sales_contract_id;
    }
    setAdvanceAdjustments([...row]);
    // callbackFn(row);
  }

  // const AdjustementFromHandler = (idx, name, value) => {
  //   const filter_contracts = vendorAdvContracts?.filter(e => e.contract_type === value);
  //   const vendorDrop = []
  //   filter_contracts.forEach(item => {
  //     vendorDrop.push({
  //       vendor_adv_contract_id: item.id,
  //       vendor_contract_no: item.contract_no,
  //       vendor_contract_name: item.vendor_contract_name,
  //     })
  //   })

  //   setAdvContracts(vendorDrop)
  //   const row = [...advance_adjustments];

  //   row[idx][name] = value;
  //   if (value === "Purchase Contract") {
  //     row[idx]['purchase_contract_id'] = pcDetails?.purchase_contract_id;
  //     row[idx]['pc_remaining_amt'] = pcDetails?.remaining_amt;
  //   }

  //   setAdvanceAdjustments([...row]);
  // }

  const calculations = (name, idx, value) => {
    var row = [...advance_adjustments];
    if (
      value === "" ||
      quan.test(value)
    ) {
      row[
        idx
      ][name] =
        value;

      var advance_adjustment_value = (
        parseFloat(row[idx].advance_adjustment_barge_quantity) *
        parseFloat(row[idx].adjustment_per_mt)
      ).toFixed(2);
      row[idx].advance_adjustment_value =
        advance_adjustment_value;
      row[
        idx
      ].advance_adjustment_barge_quantity_valueError = false;
      row[
        idx
      ].adjustment_per_mt_valueError = false;
      row[idx].pc_AmountError = "";
      if (row[idx].adjustment_from === "") {
        alert(
          "Please select adjustment from"
        );
        return;
      }

      // var totalAdvance_ContractValue = 0;
      // var totalPC_advance_adjustment = 0;
      // if (
      //   row[idx].adjustment_from ===
      //   "Purchase Contract"
      // ) {
      //   const filterPC = row.filter(
      //     (e) =>
      //       e.adjustment_from ===
      //       "Purchase Contract"
      //   );
      //   for (var i of filterPC) {
      //     totalPC_advance_adjustment +=
      //       Number(
      //         i.advance_adjustment_value
      //       );
      //   }
      //   if (
      //     Number(
      //       pcDetails?.pc_remaining_amt
      //     ) <
      //     Number(totalPC_advance_adjustment)
      //   ) {
      //     row[
      //       idx
      //     ].advance_adjustment_barge_quantity_valueError = true;
      //     row[
      //       idx
      //     ].adjustment_per_mt_valueError = true;
      //     row[idx].pc_AmountError =
      //       "Amount Is Exceeding Advance Purchase Contract Amount";
      //   }
      // }
      // if (
      //   row[idx].adjustment_from ===
      //   "Advance Contract" || row[idx].adjustment_from ===
      //   "Down Payment"
      // ) {
      //   if (
      //     row[idx].vendor_adv_contract_id ===
      //     "" || row[idx].vendor_adv_contract_id ===
      //     null
      //   ) {
      //     row[
      //       idx
      //     ].advanceContractNoError = true;
      //     alert(
      //       "Please select contract No"
      //     );
      //     setAdvanceAdjustments(row)
      //     // callbackFn(row);
      //     return;
      //   } else {
      //     row[
      //       idx
      //     ].advance_adjustment_valueError = false;
      //     row[idx].pc_AmountError = "";
      //     var filterRemaining_advance_amount =
      //       vendorAdvContracts.filter(
      //         (e) =>
      //           e.id ===
      //           row[idx]
      //             .vendor_adv_contract_id
      //       );
      //     var Remaining_advance_amount = 0;
      //     if (
      //       filterRemaining_advance_amount.length >
      //       0
      //     ) {
      //       Remaining_advance_amount =
      //         filterRemaining_advance_amount[0]
      //           .remaining_amt;
      //     }

      //     const filterAdvanceContract =
      //       row.filter(
      //         (e) =>
      //           e.vendor_adv_contract_id ===
      //           row[idx]
      //             .vendor_adv_contract_id
      //       );

      //     for (var j of filterAdvanceContract) {
      //       totalAdvance_ContractValue +=
      //         Number(
      //           j.advance_adjustment_value
      //         );
      //     }

      //     if (
      //       Number(Remaining_advance_amount) <
      //       Number(totalAdvance_ContractValue)
      //     ) {
      //       row[
      //         idx
      //       ].advance_adjustment_valueError = true;
      //       row[idx].pc_AmountError =
      //         "Amount Is Exceeding Vendor Advance Contract Amount";
      //     }
      //   }
      // }
      setAdvanceAdjustments(row);
      // callbackFn(row);
    }

  }

  const onSaveHandler = () => {
    let missing_fields = 0;
    var row = advance_adjustments;

    for (var i in row) {
      if (row[i].advance_adjustment === "No") {
        row[i].adjustment_from = "";
        row[i].vendor_adv_contract_id = "";
        row[i].advance_adjustment_value = "";
        row[i].advance_adjustment_barge_quantity = "";
        row[i].adjustment_per_mt = "";
        row[i].adjustment_currency = "";
        row[i].sales_contract_id = '';
        row[i].sales_contract_no = '';
      }
      else {

        if (row[i].adjustment_from === null || row[i].adjustment_from === '') {
          missing_fields++;
        }
        if (row[i].advance_adjustment === "Lumpsum") {
          if (row[i].adjustment_from === "") {
            alert("Please Select Adjustment From");
            return;
          }
          if (row[i].advance_adjustment_value === "") {
            row[i].advance_adjustment_valueError = true;
            missing_fields++;
          }
          // row[i].adjustment_currency = this.state.coal_cost_billing_currency;
        }
        if (row[i].advance_adjustment === "Per MT") {
          if (row[i].adjustment_from === "") {
            alert("Please Select Adjustment From");
            return;
          }
          if (row[i].advance_adjustment_barge_quantity === "") {
            row[i].advance_adjustment_barge_quantity_valueError = true;
            missing_fields++;
          }
          if (row[i].adjustment_per_mt === "") {
            row[i].adjustment_per_mt_valueError = true;
            missing_fields++;
          }
          // row[i].adjustment_currency = this.state.coal_cost_billing_currency;
        }
      }
    }

    setAdvanceAdjustments(row);
    if (missing_fields > 0) {
      return;
    }

    let request = {
      login_user_id: Cookie.getCookie('loginUserId'),
      idtoken: Cookie.getIdTokenCookie(),
      costing_id: costing_id,
      table_name: table_name,
      //payable_to_supplier: payable_to_supplier,
      advance_adjustments: row,
    }
    api.add_sales_adv_adjustments(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === '200') {
            callbackFn();
          }
        })
      }

    })

  }

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      fullWidth={true}
    >
      <DialogTitle id="responsive-dialog-title" style={{ backgroundColor: "#f1f1f1", padding: "8px 10px" }}>
        <h6
          className="modal-title"
          style={{ border: "none", padding: 0 }}
        >
          Advance Adjustment
        </h6>
      </DialogTitle>
      <DialogContent style={{ padding: "8px 10px" }}>
        {advance_adjustments.map((e, idx) => (
          <div key={idx} className="row pl-3 pr-3">
            <div className="col-lg-10 border_dashed rounded-lg mb-1">
              <div className="row">
                <div className="col-lg p-0">
                  <label className="form_label pl-2">
                    Advance Adjustment
                  </label>
                  <RadioGroup
                    aria-label="advance_adjustment"
                    name="advance_adjustment"
                    onChange={(e) => handleAdvanceAdjustmentHandler(idx, "advance_adjustment", e.target.value)}
                    style={{ flexDirection: "row" }}
                  >
                    <FormControlLabel
                      value="No"
                      control={
                        <NewRadio
                          checked={e.advance_adjustment === "No"}
                        />
                      }
                      label="No"
                    />
                    <FormControlLabel
                      value="Lumpsum"
                      control={
                        <NewRadio
                          checked={
                            e.advance_adjustment === "Lumpsum"
                          }
                        />
                      }
                      label="Lumpsum"
                    />
                    <FormControlLabel
                      value="Per MT"
                      control={
                        <NewRadio
                          checked={
                            e.advance_adjustment === "Per MT"
                          }
                        />
                      }
                      label="Per MT"
                    />
                  </RadioGroup>
                </div>
              </div>

              {e.advance_adjustment !== "No" &&
                <>
                  {/* <div className="row">
                    <div className="col-lg pl-0">
                      <label className="form_label pl-2">
                        Adjustment From
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <RadioGroup
                        aria-label="adjustment_from"
                        name="adjustment_from"
                        style={{ flexDirection: "row" }}
                        onChange={(e) => AdjustementFromHandler(
                          idx, "adjustment_from", e.target.value
                        )}
                      >
                        <FormControlLabel
                          value="Advance Contract"
                          control={
                            <NewRadio
                              checked={
                                e.adjustment_from ===
                                "Advance Contract"
                              }
                            />
                          }
                          label="Advance Contract"
                        />
                      </RadioGroup>
                    </div>
                  </div> */}
                  {/* {(e.adjustment_from === "Advance Contract" || e.adjustment_from === "Down Payment") && (
                    <div className="row">
                      <label className="form_label pl-2 col-lg-5">
                        {e.adjustment_from === "Advance Contract" ? "Advance Contract No" : "Down Payment"}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <div className="col-lg pl-0">
                        <Autocomplete
                          options={advContracts}
                          getOptionLabel={(option) =>
                            option.vendor_contract_no
                          }
                          size="small"
                          renderOption={(option) => (
                            <React.Fragment>
                              <div className="col-lg p-0 m-0">
                                <label className="form_label mb-0">
                                  {option.vendor_contract_no}
                                </label>
                                <label
                                  className="contract_display_header_value m-0 pl-0"
                                  style={{
                                    fontSize: 10,
                                    paddingLeft: 5,
                                  }}
                                >
                                  {option.vendor_contract_name
                                    ? "( " +
                                    option.vendor_contract_name +
                                    " )"
                                    : null}
                                </label>
                              </div>
                            </React.Fragment>
                          )}
                          onChange={(event, value) => {
                            var row =
                              advance_adjustments;
                            if (value) {
                              row[idx].vendor_adv_contract_id =
                                value.vendor_adv_contract_id;
                              row[idx].vendor_contract_no =
                                value.vendor_contract_no;
                              row[
                                idx
                              ].advanceContractNoError = false;
                            } else {
                              row[idx].vendor_adv_contract_id = null;
                              row[idx].vendor_contract_no = null;
                            }

                            setAdvanceAdjustments(row);
                            // callbackFn(row);
                          }}
                          name="vendorName"
                          value={e}
                          error={
                            e.advanceContractNoError
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder={e.adjustment_from === "Advance Contract" ? "Search Advance Contract" : "Search Down Payment"}
                              label={e.adjustment_from === "Advance Contract" ? "Search Advance Contract" : "Search Down Payment"}
                              variant="outlined"
                              error={
                                e.advanceContractNoError
                              }
                              fullWidth
                            />
                          )}
                        />
                      </div>
                    </div>
                  )} */}

                  <div className="row">
                    <label className=" form_label pl-2 col-lg-5">
                      Sales Contract
                    </label>
                    <label className="col-lg pl-0 ">
                      {sales_contract_no}
                    </label>
                  </div>

                  {e.advance_adjustment === "Per MT" &&
                    <>
                      <div className="row">
                        <label className="form_label pl-2 col-lg-5">
                          Barge Quantity in MT
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="col-lg pl-0">
                          <TextField
                            name="advance_adjustment_barge_quantity"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            type="number"
                            placeholder="Barge Quantity in MT"
                            inputProps={{
                              style: {
                                fontFamily: "Poppins",
                                fontSize: "12px",
                              },
                            }}
                            value={e.advance_adjustment_barge_quantity}
                            error={
                              e.advance_adjustment_barge_quantity_valueError
                            }
                            onChange={(e) => {
                              calculations(e.target.name, idx, e.target.value);

                            }}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <label className="form_label pl-2 col-lg-5">
                          Adjustment per MT{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="col-lg pl-0">
                          <TextField
                            name="adjustment_per_mt"
                            margin="dense"
                            type="number"
                            variant="outlined"
                            fullWidth
                            placeholder="Adjustment per MT"
                            value={e.adjustment_per_mt}
                            error={e.adjustment_per_mt_valueError}
                            onChange={(e) => {
                              calculations(e.target.name, idx, e.target.value)
                            }}

                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                  className="adornment_background"
                                >
                                  {config.company === "SRPL" || config.company === "AVS" || config.company === "PRPL" ? "USD" : "IDR"}
                                </InputAdornment>
                              ),
                              style: {
                                fontFamily: "Poppins",
                                fontSize: "12px",
                                padding: 0,
                              },
                            }}
                          />
                          <span style={{ color: "red", fontSize: 9 }}>
                            {e.pc_AmountError ? e.pc_AmountError : ""}
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <label className=" pl-2 col-lg-5">
                          Advance Adjustment Value
                        </label>
                        <label className=" col-lg-5">
                          {toLocaleString(e.advance_adjustment_value)}
                        </label>
                      </div>
                    </>
                  }
                  {e.advance_adjustment === "Lumpsum" && (
                    <div className="row">
                      <label className="form_label pl-2 col-lg-5">
                        Advance Adjustment Value
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <div className="col-lg pl-0">
                        <TextField
                          name="advance_adjustment_value"
                          margin="dense"
                          type="number"
                          variant="outlined"
                          value={e.advance_adjustment_value}
                          fullWidth
                          error={e.advance_adjustment_valueError}
                          placeholder="Advance Adjustment Value"
                          onChange={(e) => {
                            var row = [...advance_adjustments];
                            if (
                              e.target.value === "" ||
                              pr.test(e.target.value)
                            ) {
                              row[idx].advance_adjustment_value =
                                e.target.value;
                              row[
                                idx
                              ].advance_adjustment_valueError = false;
                              row[idx].pc_AmountError = "";
                              if (row[idx].adjustment_from === "") {
                                alert(
                                  "Please select adjustment from"
                                );
                                return;
                              }

                              // var totalAdvance_ContractValue = 0;
                              // var totalPC_advance_adjustment = 0;
                              // if (
                              //   row[idx].adjustment_from ===
                              //   "Purchase Contract"
                              // ) {
                              //   const filterPC = row.filter(
                              //     (e) =>
                              //       e.adjustment_from ===
                              //       "Purchase Contract"
                              //   );
                              //   for (var i of filterPC) {
                              //     totalPC_advance_adjustment +=
                              //       Number(
                              //         i.advance_adjustment_value
                              //       );
                              //   }
                              //   // if (
                              //   //   Number(
                              //   //     pcDetails?.pc_remaining_amt
                              //   //   ) <
                              //   //   Number(totalPC_advance_adjustment)
                              //   // ) {
                              //   //   row[
                              //   //     idx
                              //   //   ].advance_adjustment_valueError = true;
                              //   //   row[idx].pc_AmountError =
                              //   //     "Amount Is Exceeding Advance Purchase Contract Amount";
                              //   // }
                              // }
                              // if (
                              //   row[idx].adjustment_from ===
                              //   "Advance Contract" || row[idx].adjustment_from ===
                              //   "Down Payment"
                              // ) {
                              //   if (
                              //     row[idx].vendor_adv_contract_id ===
                              //     "" || row[idx].vendor_adv_contract_id ===
                              //     null
                              //   ) {
                              //     row[
                              //       idx
                              //     ].advanceContractNoError = true;
                              //     setAdvanceAdjustments(row)
                              //     // callbackFn(row);
                              //     alert(
                              //       "Please select advance contract No"
                              //     );
                              //     return;
                              //   } else {
                              //     row[
                              //       idx
                              //     ].advance_adjustment_valueError = false;
                              //     row[idx].pc_AmountError = "";
                              //     var filterRemaining_advance_amount =
                              //       vendorAdvContracts.filter(
                              //         (e) =>
                              //           e.id ===
                              //           row[idx]
                              //             .vendor_adv_contract_id
                              //       );
                              //     var Remaining_advance_amount = 0;
                              //     if (
                              //       filterRemaining_advance_amount.length >
                              //       0
                              //     ) {
                              //       Remaining_advance_amount =
                              //         filterRemaining_advance_amount[0]
                              //           .remaining_amt;
                              //     }

                              //     const filterAdvanceContract =
                              //       row.filter(
                              //         (e) =>
                              //           e.vendor_adv_contract_id ===
                              //           row[idx]
                              //             .vendor_adv_contract_id
                              //       );

                              //     for (var j of filterAdvanceContract) {
                              //       totalAdvance_ContractValue +=
                              //         Number(
                              //           j.advance_adjustment_value
                              //         );
                              //     }

                              //     if (
                              //       Number(Remaining_advance_amount) <
                              //       Number(totalAdvance_ContractValue)
                              //     ) {
                              //       row[
                              //         idx
                              //       ].advance_adjustment_valueError = true;
                              //       row[idx].pc_AmountError =
                              //         "Amount Is Exceeding Vendor Advance Contract Amount";
                              //     }
                              //   }
                              // }
                              setAdvanceAdjustments(row);
                              // callbackFn(row);
                            }
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                className="adornment_background"
                              >
                                {/* {
                              this.state
                                .coal_cost_billing_currency
                            } */}
                              </InputAdornment>
                            ),
                            style: {
                              fontFamily: "Poppins",
                              fontSize: "12px",
                              padding: 0,
                            },
                          }}
                        />
                        <span style={{ color: "red", fontSize: 10 }}>
                          {e.pc_AmountError ? e.pc_AmountError : ""}
                        </span>
                      </div>
                    </div>
                  )}
                </>
              }
            </div>

            {advance_adjustments.length > 1 && (
              <i
                data-toggle="tooltip1"
                title="Remove"
                data-html="true"
                data-placement="bottom"
                className="fa fa-trash-o col-lg-1"
                aria-hidden="true"
                style={{
                  fontSize: 22,
                  color: config.themeColor,
                  cursor: "pointer",
                }}
                onClick={() => RemoveRowsHandler(idx)}
              />
            )}
          </div>
        ))}
        <br />
        <button
          type="button"
          style={{ width: 110, color: config.themeColor }}
          className="header_button_text header_button addrow_button_adjustment"
          onClick={AddMoreRowsHandler}
        >
          Add Row
        </button>
        <br />
      </DialogContent>

      <DialogActions>
        <div
          className="row col-lg-12 modal-footer justify-content-end bottom_buttons_section"
          style={{ bottom: 0, background: "white" }}
        >
          <button
            type="button"
            className="drawer_button drawer_text drawer_calculate_button drawer_calculate_adjustment"
            onClick={() => closeModal(false)}
          >
            Cancel
          </button>

          <button
            type="button"
            className="drawer_button drawer_text"
            onClick={onSaveHandler}
          >
            Save
          </button>
        </div>
      </DialogActions>
    </Dialog>
  )
}