import React, { Component } from "react";

import { MenuItem, TextField, Snackbar } from "@material-ui/core";
import CookieHandler from "../../common/CookieHandler";
import AddCircle from "@material-ui/icons/AddCircle";

import Header from "../../common/Header";
import SideBar from "../../common/SideBar";

import api from "../../../api/api";
import config from "../../../config/config";
import { Alert } from "@material-ui/lab";
import FileUpload from "../../common/FileUpload";
import Loader from "../../common/Loader";
export default class Vendor extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      vendorType: "0",
      otherVendor: "",
      vendorName: "",
      vendorNo: "",
      vendorAddress: "",
      vendorCountry: "0",
      textAreaMaxCount: 2000,
      countries: [],
      contactDetails: [],
      vendorKYC: [
        {
          s_no: "1",
          bankAcHolderName: "",
          bankName: "",
          address: "",
          bankACNo: "",
          currency: "0",
          swiftCode: "",
          IBAN_Num: "",
          currencyError: false,
        },
      ],
      taxId: "",
      shareDocLink: "",
      newCurrency: "",
      currencyData: [],
      currencyNotManData: [],
      clickedCurrency: "",
      vendorTypeError: false,
      otherVendorError: false,
      vendorNameError: false,
      vendorAddressError: false,
      vendorCountryError: false,
      bankName1Error: false,
      address1Error: false,
      bankACNo1Error: false,
      Currency1Error: false,
      taxIDError: false,
      swiftCode1Error: false,
      phone0: true,
      errorMsg: "",
      successMsg: "",
      isLoading: true,
      features: [],
      files: [
        {
          file_description: "",
          file_name: "",
          file_extension: "",
          file_content: "",
          feature_name: "Vendor",
        },
      ],
      activeStep: 0,
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    var features = this.Cookie.getCookie("features");
    this.setState({ features: features ? features : [] });
    // for get_Currencies
    const idToken = this.Cookie.getIdTokenCookie();
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const get_currencies = await api.getCurrencies(loginUserID, idToken);
    if (get_currencies.status >= 200 && get_currencies.status < 300) {
      get_currencies.json().then((res) => {
        if (res.code === "200") {
          if (res.Currency) {
            var currencies = [
              <MenuItem value="0" key={-1}>
                Please Select
              </MenuItem>,
            ];
            var currency = [
              <MenuItem value="0" key={-1}>
                Please Select
              </MenuItem>,
            ];
            res.Currency.forEach((item) => {
              currencies.push(
                <MenuItem value={item.currency} key={[item.id]}>
                  {item.currency}
                </MenuItem>
              );
              currency.push(
                <MenuItem value={item.currency} key={[item.id]}>
                  {item.currency}
                </MenuItem>
              );
            });
            this.setState({
              currencyData: currencies,
              currencyNotManData: currency,
              //isLoading: false
            });
          } else {
            alert("Unexpected error occured. Please contact administrator.");
          }
        } else if (res.code === "601") {
          this.setState({
            snackBarErrorOpen: true,
            errorMsg: res.message,
          });
        } else if (res.code === "607") {
          window.location.href = "/logout";
        }
      });
    }

    const get_countries = await api.getCountries(loginUserID, idToken);
    if (get_countries.status >= 200 && get_countries.status < 300) {
      get_countries.json().then((res) => {
        if (res.code === "200") {
          if (res.countries) {
            const Country = [
              <MenuItem value="0" disabled key={0}>
                Please Select
              </MenuItem>,
            ];
            res.countries.forEach((item) => {
              Country.push(
                <MenuItem value={item.country_name} key={item.id}>
                  {item.country_name}
                </MenuItem>
              );
            });
            this.setState({
              countries: Country,
              isLoading: false,
            });
          } else {
            alert("Unexpected error occured. Please contact administrator.");
          }
        } else if (res.code === "601") {
          this.setState({
            snackBarErrorOpen: true,
            errorMsg: res.message,
          });
        } else if (res.code === "607") {
          window.location.href = "/logout";
        }
      });
    }
    document.title = config.documentTitle + "Add Vendor";
  }

  handleAddCurrency = () => {
    var idToken = this.Cookie.getIdTokenCookie();
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var newCurrency = this.state.newCurrency;
    var status = "Active";
    if (newCurrency === "") {
      alert("Please enter Currency");
      this.setState({ modalCurrencyError: true });
      return;
    }
    api
      .addCurrency(loginUserID, idToken, newCurrency, status)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
            if (res) {
              if (res.code === "200") {
                this.setState({ errorMsg: "" });
                window.$("#myModal").modal("hide");
                if (res.id) {
                  api.getCurrencies(loginUserID, idToken).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                      response.json().then((res) => {
                        if (res.idtoken)
                          this.Cookie.setIdTokenCookie(res.idtoken);
                        if (res.Currency) {
                          var currencies = [
                            <MenuItem value="0" key={-1}>
                              {" "}
                              Please Select Currency
                            </MenuItem>,
                          ];
                          res.Currency.forEach((item) => {
                            currencies.push(
                              <MenuItem value={item.currency} key={[item.id]}>
                                {item.currency}
                              </MenuItem>
                            );
                          });
                          this.setState({
                            currencyData: currencies,
                            currencyNotManData: currencies,
                          });
                          let data = this.state.vendorKYC;
                          data[this.state.clickedCurrency].currency =
                            newCurrency.toUpperCase();
                          this.setState({
                            clickedCurrency: "",
                            vendorKYC: data,
                            modalCurrencyError: false,
                          });
                        } else {
                          alert(
                            "Unexpected error occured. Please contact administrator."
                          );
                        }
                      });
                    }
                  });
                }
              }
              if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  snackBarErrorOpen: true,
                });
              }
              if (res.code === "602") {
                alert(res.message);
                this.setState({
                  errorMsg: res.message,
                  snackBarErrorOpen: true,
                  modalCurrencyError: true,
                });
              }
              if (res.code === "624") {
                this.setState({
                  modalCurrencyError: true,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          });
        } else {
          alert("Unexpected error occured. Please contact administrator.");
        }
      });
  };

  handleText = (e) => {
    if (e.target.name === "vendorType") {
      this.setState({
        [e.target.name]: e.target.value,
        vendorTypeError: false,
      });
    } else if (e.target.name === "otherVendor") {
      this.setState({
        [e.target.name]: e.target.value,
        otherVendorError: false,
      });
    } else if (e.target.name === "vendorName") {
      this.setState({
        [e.target.name]: e.target.value,
        vendorNameError: false,
      });
    } else if (e.target.name === "vendorAddress") {
      if (e.target.value.length <= this.state.textAreaMaxCount) {
        this.setState({
          [e.target.name]: e.target.value,
          vendorAddressError: false,
        });
      }
    } else if (e.target.name === "vendorCountry") {
      this.setState({
        [e.target.name]: e.target.value,
        vendorCountryError: false,
      });
    } else if (e.target.name === "taxId") {
      this.setState({
        [e.target.name]: e.target.value,
        taxIDError: false,
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
        modalCurrencyError: false,
      });
    }
  };

  handleContactRows = () => {
    const items = {
      s_no: JSON.stringify(this.state.contactDetails.length + 1),
      contactPsn: "",
      phone: "",
      handPhone: "",
      email: "",
      phoneError: false,
      handPhoneError: false,
      emailError: false,
    };

    this.setState({
      contactDetails: [...this.state.contactDetails, items],
    });
  };

  handleChange = (idx) => (e) => {
    var name = e.target.name;
    var rows = this.state.contactDetails;
    if (name === "contactPsn") {
      rows[idx].contactPsn = e.target.value;
    }
    // if(name === 'phone') {
    //   rows[idx].phone = e.target.value;
    // }
    // if(name === 'handPhone') {
    //   rows[idx].handPhone = e.target.value;
    // }
    if (name === "phone") {
      const re = /^\d*(\.\d{0,3})?$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        rows[idx].phone = e.target.value;
        rows[idx].phoneError = false;
      }
    }
    if (name === "handPhone") {
      const re = /^\d*(\.\d{0,3})?$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        rows[idx].handPhone = e.target.value;
        rows[idx].handPhoneError = false;
      }
    }
    if (name === "email") {
      rows[idx].email = e.target.value;
      rows[idx].emailError = false;
    }

    this.setState({
      contactDetails: rows,
    });
  };

  handleRemoveContactRow = (idx) => () => {
    const rows = this.state.contactDetails;
    rows.splice(idx, 1);
    this.setState({
      contactDetails: rows,
    });
  };

  handleKYCRows = () => {
    const items = {
      s_no: JSON.stringify(this.state.vendorKYC.length + 1),
      bankAcHolderName: "",
      bankName: "",
      address: "",
      bankACNo: "",
      currency: "0",
      swiftCode: "",
      IBAN_Num: "",
      currencyError: false,
    };
    this.setState({
      vendorKYC: [...this.state.vendorKYC, items],
    });
  };

  handleVenodrKYC = (idx) => (e) => {
    var name = e.target.name;
    var rows = this.state.vendorKYC;
    if (name === "bankAcHolderName") {
      rows[idx].bankAcHolderName = e.target.value;
      this.setState({ bankAcHolderName1Error: false });
    }
    if (name === "bankName") {
      rows[idx].bankName = e.target.value;
      this.setState({ bankName1Error: false });
    }
    if (name === "address") {
      rows[idx].address = e.target.value;
      this.setState({ address1Error: false });
    }
    if (name === "bankACNo") {
      rows[idx].bankACNo = e.target.value;
      this.setState({ bankACNo1Error: false });
    }
    if (name === "currency") {
      rows[idx].currency = e.target.value;
      this.setState({ currency1Error: false });
    }
    if (name === "swiftCode") {
      rows[idx].swiftCode = e.target.value;
      this.setState({ swiftCode1Error: false });
    }
    if (name === "IBAN_Num") {
      rows[idx].IBAN_Num = e.target.value;
      this.setState({ IBAN_Num1Error: false });
    }
    this.setState({
      rows,
    });
  };

  handleRemoveKycRows = (idx) => () => {
    const rows = this.state.vendorKYC;
    rows.splice(idx, 1);
    this.setState({ vendorKYC: rows });
  };

  handleSubmit = (e) => {
    this.setState({
      isLoading: true,
    });
    e.preventDefault();
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    var {
      vendorType,
      otherVendor,
      vendorName,
      vendorAddress,
      vendorCountry,
      taxId,
      shareDocLink,
      files,
    } = this.state;
    // TODO://<!--------------------- Accessing the ContactDetails Array Object values --------------------------- !>
    var contactPsn1 = "",
      contactPsnPhone1 = "",
      contactPsnHandPhone1 = "",
      contactPsnEmail1 = "",
      contactPsn2 = "",
      contactPsnPhone2 = "",
      contactPsnHandPhone2 = "",
      contactPsnEmail2 = "",
      contactPsn3 = "",
      contactPsnPhone3 = "",
      contactPsnHandPhone3 = "",
      contactPsnEmail3 = "",
      contactPsn4 = "",
      contactPsnPhone4 = "",
      contactPsnHandPhone4 = "",
      contactPsnEmail4 = "",
      contactPsn5 = "",
      contactPsnPhone5 = "",
      contactPsnHandPhone5 = "",
      contactPsnEmail5 = "";
    for (var i in this.state.contactDetails) {
      if (i === "0") {
        contactPsn1 = this.state.contactDetails[i].contactPsn;
        contactPsnPhone1 = this.state.contactDetails[i].phone;
        contactPsnHandPhone1 = this.state.contactDetails[i].handPhone;
        contactPsnEmail1 = this.state.contactDetails[i].email;
      } else if (i === "1") {
        contactPsn2 = this.state.contactDetails[i].contactPsn;
        contactPsnPhone2 = this.state.contactDetails[i].phone;
        contactPsnHandPhone2 = this.state.contactDetails[i].handPhone;
        contactPsnEmail2 = this.state.contactDetails[i].email;
      } else if (i === "2") {
        contactPsn3 = this.state.contactDetails[i].contactPsn;
        contactPsnPhone3 = this.state.contactDetails[i].phone;
        contactPsnHandPhone3 = this.state.contactDetails[i].handPhone;
        contactPsnEmail3 = this.state.contactDetails[i].email;
      } else if (i === "3") {
        contactPsn4 = this.state.contactDetails[i].contactPsn;
        contactPsnPhone4 = this.state.contactDetails[i].phone;
        contactPsnHandPhone4 = this.state.contactDetails[i].handPhone;
        contactPsnEmail4 = this.state.contactDetails[i].email;
      } else if (i === "4") {
        contactPsn5 = this.state.contactDetails[i].contactPsn;
        contactPsnPhone5 = this.state.contactDetails[i].phone;
        contactPsnHandPhone5 = this.state.contactDetails[i].handPhone;
        contactPsnEmail5 = this.state.contactDetails[i].email;
      }
    }
    //TODO://<!--------------------- Accessing the VendorKYC Array Object values --------------------------- !>
    var bankAcHolderName1 = "",
      bankName1 = "",
      address1 = "",
      bankACNo1 = "",
      Currency1 = "",
      swiftCode1 = "",
      IBAN_NUM1 = "",
      bankAcHolderName2 = "",
      bankName2 = "",
      address2 = "",
      bankACNo2 = "",
      Currency2 = "",
      swiftCode2 = "",
      IBAN_NUM2 = "",
      bankAcHolderName3 = "",
      bankName3 = "",
      address3 = "",
      bankACNo3 = "",
      Currency3 = "",
      swiftCode3 = "",
      IBAN_NUM3 = "",
      bankAcHolderName4 = "",
      bankName4 = "",
      address4 = "",
      bankACNo4 = "",
      Currency4 = "",
      swiftCode4 = "",
      IBAN_NUM4 = "",
      bankAcHolderName5 = "",
      bankName5 = "",
      address5 = "",
      bankACNo5 = "",
      Currency5 = "",
      swiftCode5 = "",
      IBAN_NUM5 = "";
    for (var j in this.state.vendorKYC) {
      if (j === "0") {
        bankAcHolderName1 = this.state.vendorKYC[j].bankAcHolderName;
        bankName1 = this.state.vendorKYC[j].bankName;
        address1 = this.state.vendorKYC[j].address;
        bankACNo1 = this.state.vendorKYC[j].bankACNo;
        Currency1 = this.state.vendorKYC[j].currency;
        if (Currency1 === "0") {
          Currency1 = "";
        }
        swiftCode1 = this.state.vendorKYC[j].swiftCode;
        IBAN_NUM1 = this.state.vendorKYC[j].IBAN_Num;
      } else if (j === "1") {
        bankAcHolderName2 = this.state.vendorKYC[j].bankAcHolderName;
        bankName2 = this.state.vendorKYC[j].bankName;
        address2 = this.state.vendorKYC[j].address;
        bankACNo2 = this.state.vendorKYC[j].bankACNo;
        Currency2 = this.state.vendorKYC[j].currency;
        if (Currency2 === "0") {
          Currency2 = "";
        }
        swiftCode2 = this.state.vendorKYC[j].swiftCode;
        IBAN_NUM2 = this.state.vendorKYC[j].IBAN_Num;
      } else if (j === "2") {
        bankAcHolderName3 = this.state.vendorKYC[j].bankAcHolderName;
        bankName3 = this.state.vendorKYC[j].bankName;
        address3 = this.state.vendorKYC[j].address;
        bankACNo3 = this.state.vendorKYC[j].bankACNo;
        Currency3 = this.state.vendorKYC[j].currency;
        if (Currency3 === "0") {
          Currency3 = "";
        }
        swiftCode3 = this.state.vendorKYC[j].swiftCode;
        IBAN_NUM3 = this.state.vendorKYC[j].IBAN_Num;
      } else if (j === "3") {
        bankAcHolderName4 = this.state.vendorKYC[j].bankAcHolderName;
        bankName4 = this.state.vendorKYC[j].bankName;
        address4 = this.state.vendorKYC[j].address;
        bankACNo4 = this.state.vendorKYC[j].bankACNo;
        Currency4 = this.state.vendorKYC[j].currency;
        if (Currency4 === "0") {
          Currency4 = "";
        }
        swiftCode4 = this.state.vendorKYC[j].swiftCode;
        IBAN_NUM4 = this.state.vendorKYC[j].IBAN_Num;
      } else if (j === "4") {
        bankAcHolderName5 = this.state.vendorKYC[j].bankAcHolderName;
        bankName5 = this.state.vendorKYC[j].bankName;
        address5 = this.state.vendorKYC[j].address;
        bankACNo5 = this.state.vendorKYC[j].bankACNo;
        Currency5 = this.state.vendorKYC[j].currency;
        if (Currency5 === "0") {
          Currency5 = "";
        }
        swiftCode5 = this.state.vendorKYC[j].swiftCode;
        IBAN_NUM5 = this.state.vendorKYC[j].IBAN_Num;
      }
    }
    /*
      !<!------------------- Checking the Mandatory Filed's ------------------------!>
    */

    if (vendorType === "Others") {
      if (otherVendor === "") {
        alert("Please fill all mandatory fields.");
        this.setState({ otherVendorError: true });
        return;
      }
    }
    if (vendorType === "0") {
      this.setState({ vendorTypeError: true });
    }
    if (vendorName === "") {
      this.setState({ vendorNameError: true });
    }
    if (vendorAddress === "") {
      this.setState({ vendorAddressError: true });
    }
    if (vendorCountry === "0") {
      this.setState({ vendorCountryError: true });
    }
    if (bankAcHolderName1 === "") {
      this.setState({ bankAcHolderName1Error: true });
    }
    if (bankName1 === "") {
      this.setState({ bankName1Error: true });
    }
    if (address1 === "") {
      this.setState({ address1Error: true });
    }
    if (bankACNo1 === "") {
      this.setState({ bankACNo1Error: true });
    }
    if (Currency1 === "") {
      var row = this.state.vendorKYC;
      row[0].currencyError = true;
      this.setState({ vendorKYC: row });
    }
    if (swiftCode1 === "") {
      this.setState({ swiftCode1Error: true });
    }
    var status = "Active";
    const content_files = files.filter((e) => e.file_content !== "");

    if (
      vendorType !== "0" &&
      vendorName !== "" &&
      vendorAddress !== "" &&
      vendorCountry !== "0" &&
      bankAcHolderName1 !== "" &&
      bankName1 !== "" &&
      address1 !== "" &&
      bankACNo1 !== "" &&
      Currency1 !== "" &&
      swiftCode1 !== ""
    ) {
      // Creating a Vendor
      api
        .addVendor(
          loginUserID,
          idToken,
          vendorType,
          otherVendor,
          vendorName,
          vendorAddress,
          vendorCountry,
          contactPsn1,
          contactPsnPhone1,
          contactPsnHandPhone1,
          contactPsnEmail1,
          contactPsn2,
          contactPsnPhone2,
          contactPsnHandPhone2,
          contactPsnEmail2,
          contactPsn3,
          contactPsnPhone3,
          contactPsnHandPhone3,
          contactPsnEmail3,
          contactPsn4,
          contactPsnPhone4,
          contactPsnHandPhone4,
          contactPsnEmail4,
          contactPsn5,
          contactPsnPhone5,
          contactPsnHandPhone5,
          contactPsnEmail5,
          bankAcHolderName1,
          bankName1,
          address1,
          bankACNo1,
          Currency1,
          swiftCode1,
          IBAN_NUM1,
          bankAcHolderName2,
          bankName2,
          address2,
          bankACNo2,
          Currency2,
          swiftCode2,
          IBAN_NUM2,
          bankAcHolderName3,
          bankName3,
          address3,
          bankACNo3,
          Currency3,
          swiftCode3,
          IBAN_NUM3,
          bankAcHolderName4,
          bankName4,
          address4,
          bankACNo4,
          Currency4,
          swiftCode4,
          IBAN_NUM4,
          bankAcHolderName5,
          bankName5,
          address5,
          bankACNo5,
          Currency5,
          swiftCode5,
          IBAN_NUM5,
          taxId,
          shareDocLink,
          status,
          content_files
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res) {
                if (res.code === "200") {
                  this.setState({
                    successMsg: res.message,
                    snackBarSuccessOpen: true,
                  });
                  if (res.id) {
                    window.location.href = "/business-partner";
                  } else {
                    alert(
                      "Unexpected error occured. Please contact administrator."
                    );
                  }
                }
                if (res.code === "601") {
                  this.setState({
                    errorMsg: res.message,
                    snackBarErrorOpen: true,
                    isLoading: false,
                  });
                }
                if (res.code === "642") {
                  alert(res.message);
                  this.setState({
                    vendorNameError: true,
                    errorMsg: res.message,
                    snackBarErrorOpen: true,
                    isLoading: false,
                  });
                }
                if (res.code === "624") {
                  var rows = this.state.contactDetails;
                  var kycRows = this.state.vendorKYC;
                  for (var i = 0; i < res.columns.length; i++) {
                    if (res.columns[i] === "contact_person_email_1") {
                      rows[0].emailError = true;
                    } else if (res.columns[i] === "contact_person_email_2") {
                      rows[1].emailError = true;
                    } else if (res.columns[i] === "contact_person_email_3") {
                      rows[2].emailError = true;
                    } else if (res.columns[i] === "contact_person_email_4") {
                      rows[3].emailError = true;
                    } else if (res.columns[i] === "contact_person_email_5") {
                      rows[4].emailError = true;
                    } else if (res.columns[i] === "currency_1") {
                      kycRows[0].currencyError = true;
                    } else if (res.columns[i] === "currency_2") {
                      kycRows[1].currencyError = true;
                    } else if (res.columns[i] === "currency_3") {
                      kycRows[2].currencyError = true;
                    } else if (res.columns[i] === "currency_4") {
                      kycRows[3].currencyError = true;
                    } else if (res.columns[i] === "currency_5") {
                      kycRows[4].currencyError = true;
                    } else if (res.columns[i] === "vendor_type") {
                      this.setState({ vendorTypeError: true });
                    }
                  }
                  this.setState({
                    contactDetails: rows,
                    errorMsg: res.message,
                    successMsg: "",
                    isLoading: false,
                  });
                  alert(res.message);
                  return;
                }
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            });
          }
        });
    } else {
      alert("Please fill all mandatory fields.");
    }
  };

  firstActiveSection = () => {
    var { vendorType, otherVendor, vendorName, vendorAddress, vendorCountry } =
      this.state;
    if (vendorType === "Others") {
      if (otherVendor === "") {
        this.setState({ otherVendorError: true });
        return;
      }
    }
    if (vendorType === "0") {
      this.setState({ vendorTypeError: true });
    }
    if (vendorName === "") {
      this.setState({ vendorNameError: true });
    }
    if (vendorAddress === "") {
      this.setState({ vendorAddressError: true });
    }
    if (vendorCountry === "0") {
      this.setState({ vendorCountryError: true });
    }
    if (
      vendorType !== "0" &&
      vendorName !== "" &&
      vendorAddress !== "" &&
      vendorCountry !== "0"
    ) {
      this.setState({
        activeStep: 1,
      });
    } else {
      alert("Please fill mandatory fields.");
    }
  };

  secondActiveSection = () => {
    var { vendorKYC } = this.state;
    if (vendorKYC[0].bankName === "") {
      this.setState({ bankName1Error: true });
    }
    if (vendorKYC[0].bankAcHolderName === "") {
      this.setState({ bankAcHolderName1Error: true });
    }
    if (vendorKYC[0].address === "") {
      this.setState({ address1Error: true });
    }
    if (vendorKYC[0].bankACNo === "") {
      this.setState({ bankACNo1Error: true });
    }
    if (vendorKYC[0].currency === "0") {
      this.setState({ currency1Error: true });
    }
    if (vendorKYC[0].swiftCode === "") {
      this.setState({ swiftCode1Error: true });
    }

    // files validation
    let missing_fields = 0;
    let files_missing = 0;
    var files_data = this.state.files;
    for (var obj of files_data) {
      if (obj.file_type !== "" || obj.file_content !== "") {
        if (obj.file_content !== "") {
          if (obj.file_description === "") {
            missing_fields++;
            obj.file_description_error = true;
          } else {
            obj.file_description = obj.file_description.toUpperCase();
          }
        } else if (obj.file_description !== "") {
          if (obj.file_content === "") {
            files_missing++;
          }
        }
      }
    }
    this.setState({
      files: files_data,
    });

    if (missing_fields > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please fill mandatory fields.",
        submitLoading: false,
      });
      return;
    }
    if (files_missing > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please Select file.",
        submitLoading: false,
      });
      return;
    }
    //  const content_files = files_data.filter(e => e.file_content !== '');

    if (
      vendorKYC[0].bankName !== "" &&
      vendorKYC[0].bankAcHolderName !== "" &&
      vendorKYC[0].address !== "" &&
      vendorKYC[0].bankACNo !== "" &&
      vendorKYC[0].currency !== "0" &&
      vendorKYC[0].swiftCode !== ""
    ) {
      this.setState({
        activeStep: 2,
      });
    } else {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please fill mandatory fields.",
      });
    }
  };

  render() {
    return (
      <div id="wraper">
        <SideBar />
        <div className="content">
          <Header />
          <div className="content-header">
            <div className="col">
              <h4 style={{ padding: "10px 0px", margin: "0px" }}>
                Add Business Partner(Vendor)
              </h4>
            </div>
          </div>
          {this.state.isLoading && <Loader />}
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          {!this.state.isLoading && (
            <form className="container p-3">
              <div className="row mb-0 stepper">
                <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                  <i
                    className={
                      this.state.activeStep > 0
                        ? "fa fa-check-circle"
                        : "fa fa-check-circle-o"
                    }
                    aria-hidden="true"
                    style={{
                      fontSize: 20,
                      color:
                        this.state.activeStep > 0
                          ? "#31c197"
                          : this.state.activeStep === 0
                          ? config.themeColor
                          : "#ccc",
                    }}
                  ></i>
                  <span
                    className="stepper_text"
                    style={{
                      color:
                        this.state.activeStep > 0
                          ? "#31c197"
                          : this.state.activeStep === 0
                          ? config.themeColor
                          : "#ccc",
                    }}
                  >
                    Add Business Partner
                  </span>
                  <i
                    className="fa fa-chevron-right stepper_sub_icon stepper_arrow"
                    aria-hidden="true"
                    style={{
                      color:
                        this.state.activeStep > 0
                          ? "#31c197"
                          : this.state.activeStep === 0
                          ? config.themeColor
                          : "#ccc",
                    }}
                  ></i>
                </span>
                <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                  <i
                    className={
                      this.state.activeStep > 1
                        ? "fa fa-check-circle"
                        : "fa fa-check-circle-o"
                    }
                    aria-hidden="true"
                    style={{
                      fontSize: 20,
                      color:
                        this.state.activeStep > 1
                          ? "#31c197"
                          : this.state.activeStep === 1
                          ? config.themeColor
                          : "#ccc",
                    }}
                  ></i>
                  <span
                    className="stepper_text"
                    style={{
                      color:
                        this.state.activeStep > 1
                          ? "#31c197"
                          : this.state.activeStep === 1
                          ? config.themeColor
                          : "#ccc",
                    }}
                  >
                    Contact and KYC
                  </span>
                  <i
                    className="fa fa-chevron-right stepper_sub_icon stepper_arrow"
                    aria-hidden="true"
                    style={{
                      color:
                        this.state.activeStep > 1
                          ? "#31c197"
                          : this.state.activeStep === 1
                          ? config.themeColor
                          : "#ccc",
                    }}
                  ></i>
                </span>
                <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                  <i
                    className={
                      this.state.activeStep > 2
                        ? "fa fa-check-circle"
                        : "fa fa-check-circle-o"
                    }
                    aria-hidden="true"
                    style={{
                      fontSize: 20,
                      color:
                        this.state.activeStep > 2
                          ? "#31c197"
                          : this.state.activeStep === 2
                          ? config.themeColor
                          : "#ccc",
                    }}
                  ></i>
                  <span
                    className="stepper_text"
                    style={{
                      color:
                        this.state.activeStep === 2
                          ? config.themeColor
                          : "#ccc",
                    }}
                  >
                    Preview and Submit
                  </span>
                </span>
              </div>
              <div className="col-lg card" style={{ overflowY: "scroll" }}>
                {this.state.activeStep === 0 && (
                  <div className="section_block">
                    <div style={{ width: "70%" }}>
                      <h4 style={{ padding: "10px 0px", margin: "0px" }}>
                        Add details to create new vendor
                      </h4>
                    </div>
                    <div className="row mb-2">
                      <div className="col-lg-5 pl-0">
                        <label className="form_label mb-0">
                          Vendor Type <span style={{ color: "red" }}>*</span>
                        </label>
                        <TextField
                          name="vendorType"
                          placeholder="Vendor Type"
                          margin="dense"
                          variant="outlined"
                          select
                          // inputProps={{style:{textTransform:'uppercase'}}}
                          value={this.state.vendorType}
                          error={this.state.vendorTypeError}
                          onChange={this.handleText}
                          fullWidth
                          InputProps={{
                            style: {
                              fontFamily: "Poppins",
                              fontSize: "14px",
                            },
                          }}
                          style={{ marginTop: 0 }}
                        >
                          <MenuItem value="0" disabled>
                            Please Select
                          </MenuItem>
                          <MenuItem value="Coal">Coal</MenuItem>
                          <MenuItem value="Barge">Barge</MenuItem>
                          <MenuItem value="Stevedore">Stevedore</MenuItem>
                          <MenuItem value="Surveyor">Surveyor</MenuItem>
                          <MenuItem value="Vessel Agent">Vessel Agent</MenuItem>
                          <MenuItem value="Floating Crane">
                            Floating Crane
                          </MenuItem>
                          <MenuItem value="Vessel Owner">Vessel Owner</MenuItem>
                          <MenuItem value="Others">Others</MenuItem>
                        </TextField>
                      </div>
                      <div className="col-lg-7 p-0">
                        <label className="form_label mb-0">
                          Vendor Name <span style={{ color: "red" }}>*</span>
                        </label>
                        <TextField
                          name="vendorName"
                          placeholder="Vendor Name"
                          margin="dense"
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          variant="outlined"
                          value={this.state.vendorName}
                          error={this.state.vendorNameError}
                          onChange={this.handleText}
                          fullWidth
                          InputProps={{
                            style: {
                              fontFamily: "Poppins",
                              fontSize: "14px",
                            },
                          }}
                          style={{ marginTop: 0 }}
                        />
                      </div>
                    </div>
                    {this.state.vendorType === "Others" && (
                      <div className="row mb-2">
                        <label className="form_label mb-0">
                          Other Vendor Type{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <TextField
                          name="otherVendor"
                          placeholder="Other Vendor Type"
                          margin="dense"
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          variant="outlined"
                          value={this.state.otherVendor}
                          error={this.state.otherVendorError}
                          onChange={this.handleText}
                          fullWidth
                          InputProps={{
                            style: {
                              fontFamily: "Poppins",
                              fontSize: "14px",
                            },
                          }}
                          style={{ marginTop: 0 }}
                        />
                      </div>
                    )}
                    <div className="row mb-2">
                      <div className="col-lg-5 pl-0">
                        <label className="form_label mb-0">
                          Vendor Country <span style={{ color: "red" }}>*</span>
                        </label>
                        <TextField
                          name="vendorCountry"
                          placeholder="Vendor Country"
                          margin="dense"
                          variant="outlined"
                          select
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          value={this.state.vendorCountry}
                          error={this.state.vendorCountryError}
                          onChange={this.handleText}
                          fullWidth
                          InputProps={{
                            style: {
                              fontFamily: "Poppins",
                              fontSize: "14px",
                            },
                          }}
                          style={{ marginTop: 0 }}
                        >
                          {this.state.countries}
                        </TextField>
                      </div>
                      <div className="col-lg-7 p-0">
                        <label className="form_label mb-0">
                          Vendor Address <span style={{ color: "red" }}>*</span>
                        </label>
                        <TextField
                          name="vendorAddress"
                          placeholder="Vendor Address"
                          margin="dense"
                          variant="outlined"
                          multiline
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          rows={3}
                          value={this.state.vendorAddress}
                          error={this.state.vendorAddressError}
                          onChange={this.handleText}
                          fullWidth
                          InputProps={{
                            style: {
                              fontFamily: "Poppins",
                              fontSize: "14px",
                            },
                          }}
                          style={{ marginTop: 0 }}
                        />
                        <span
                          style={{
                            fontSize: 14,
                            color: "#93bce0",
                            float: "right",
                            border: "#93bce0 0.5px solid",
                            padding: "0 5px",
                          }}
                        >
                          {this.state.textAreaMaxCount -
                            this.state.vendorAddress.length}
                        </span>
                        <span
                          style={{
                            fontSize: 14,
                            color: "#93bce0",
                            float: "right",
                            marginRight: 5,
                          }}
                        >
                          Remaining characters{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {this.state.activeStep === 1 && (
                  <div className="section_block">
                    <div className="row">
                      <label className="form_heading_label mb-0">Contact</label>
                    </div>
                    {this.state.contactDetails.map((e, idx) => (
                      <div className="row" key={idx}>
                        <div className="row col-lg-11 pb-4 mb-2 section_background">
                          <div className="col-lg-6 mb-2 p-0">
                            <label className="form_label">Contact Person</label>
                            <TextField
                              name="contactPsn"
                              placeholder="Contact Person"
                              margin="dense"
                              // label='Contact Person'
                              variant="outlined"
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                              value={this.state.contactDetails[idx].contactPsn}
                              onChange={this.handleChange(idx)}
                              fullWidth
                              InputProps={{
                                style: {
                                  fontFamily: "Poppins",
                                  backgroundColor: "white",
                                  fontSize: "14px",
                                },
                              }}
                              style={{ marginTop: 0 }}
                            />
                          </div>
                          <div className="col-lg-6 mb-2 pr-0">
                            <label className="form_label">Phone Number</label>
                            <TextField
                              name="phone"
                              placeholder="Phone Number"
                              margin="dense"
                              variant="outlined"
                              // label='Phone Number'
                              value={this.state.contactDetails[idx].phone}
                              error={this.state.contactDetails[idx].phoneError}
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                              onChange={this.handleChange(idx)}
                              fullWidth
                              InputProps={{
                                style: {
                                  fontFamily: "Poppins",
                                  backgroundColor: "white",
                                  fontSize: "14px",
                                },
                              }}
                              style={{ marginTop: 0 }}
                            />
                          </div>
                          <div className="col-lg-6 mb-2 p-0">
                            <label className="form_label">
                              Hand Phone Number
                            </label>
                            <TextField
                              name="handPhone"
                              placeholder="Hand Phone Number"
                              margin="dense"
                              variant="outlined"
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                              // label='Hand Phone Number'
                              value={this.state.contactDetails[idx].handPhone}
                              error={
                                this.state.contactDetails[idx].handPhoneError
                              }
                              onChange={this.handleChange(idx)}
                              fullWidth
                              InputProps={{
                                style: {
                                  fontFamily: "Poppins",
                                  backgroundColor: "white",
                                  fontSize: "14px",
                                },
                              }}
                              style={{ marginTop: 0 }}
                            />
                          </div>
                          <div className="col-lg-6 mb-2 pr-0">
                            <label className="form_label">Email Address</label>
                            <TextField
                              name="email"
                              placeholder="Email Address"
                              margin="dense"
                              // label='Email Address'
                              variant="outlined"
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                              value={this.state.contactDetails[idx].email}
                              error={this.state.contactDetails[idx].emailError}
                              onChange={this.handleChange(idx)}
                              fullWidth
                              InputProps={{
                                style: {
                                  fontFamily: "Poppins",
                                  backgroundColor: "white",
                                  fontSize: "14px",
                                },
                              }}
                              style={{ marginTop: 0 }}
                            />
                          </div>
                        </div>
                        {this.state.contactDetails.length === 1 ? (
                          <div></div>
                        ) : (
                          <div style={{ margin: "auto" }}>
                            <div className="icon_hover text-align-center">
                              <i
                                className="fa fa-trash-o"
                                onClick={this.handleRemoveContactRow(idx)}
                                style={{
                                  fontSize: 24,
                                  color: config.themeColor,
                                }}
                                aria-hidden="true"
                              />
                            </div>
                            {/* <div className="icon_hover text-align-center">
                              <RemoveIcon onClick={this.handleRemoveContactRow(idx)} style={{fontSize:24, color: config.themeColor}}/>
                            </div> */}
                          </div>
                        )}
                      </div>
                    ))}

                    <div className="row mb-2">
                      <div className="col-lg pt-2 pl-0">
                        {
                          this.state.contactDetails.length < 5 && (
                            <div
                              onClick={this.handleContactRows}
                              className="section_add_contact"
                            >
                              <span>
                                <AddCircle
                                  style={{
                                    fontSize: 24,
                                    color: config.themeColor,
                                  }}
                                />
                              </span>
                              <span className="section_add_contact_name">
                                Add New Contact
                              </span>
                            </div>
                          )
                          // <button type='button' onClick={this.handleContactRows} className='btn btn-primary btn-responsive' style={{padding:4, backgroundColor:'#303F9F', border:'none'}}><AddCircle style={{fontSize:24, marginRight: 10}} />Add Row</button>
                        }
                      </div>
                    </div>

                    <div className="row">
                      <div className="row">
                        <label className="form_heading_label mb-0">
                          Vendor KYC
                        </label>
                      </div>
                    </div>
                    {this.state.vendorKYC.map((e, idx) => (
                      <div key={idx}>
                        {idx === 0 ? (
                          <div className="row" key={idx}>
                            <div className="row col-lg-11 pb-4 mb-2 section_background">
                              <div className="col-lg-4 mb-2 p-0">
                                <label className="form_label">
                                  Bank Name{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <TextField
                                  name="bankName"
                                  placeholder="Bank Name *"
                                  margin="dense"
                                  variant="outlined"
                                  inputProps={{
                                    style: { textTransform: "uppercase" },
                                  }}
                                  // label='Bank Name *'
                                  value={this.state.vendorKYC[idx].bankName}
                                  onChange={this.handleVenodrKYC(idx)}
                                  error={this.state.bankName1Error}
                                  fullWidth
                                  InputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      backgroundColor: "white",
                                      fontSize: "14px",
                                    },
                                  }}
                                  style={{ marginTop: 0 }}
                                />
                              </div>
                              <div className="col-lg-4 mb-2 pr-0">
                                <label className="form_label">
                                  Account Holder Name{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <TextField
                                  name="bankAcHolderName"
                                  placeholder="Account Holder Name *"
                                  margin="dense"
                                  variant="outlined"
                                  inputProps={{
                                    style: { textTransform: "uppercase" },
                                  }}
                                  // label='Bank A/c Holder Name *'
                                  value={
                                    this.state.vendorKYC[idx].bankAcHolderName
                                  }
                                  onChange={this.handleVenodrKYC(idx)}
                                  error={this.state.bankAcHolderName1Error}
                                  fullWidth
                                  InputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      backgroundColor: "white",
                                      fontSize: "14px",
                                    },
                                  }}
                                  style={{ marginTop: 0 }}
                                />
                              </div>
                              <div className="col-lg-4 mb-2 pr-0">
                                <label className="form_label">
                                  Account Number{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <TextField
                                  name="bankACNo"
                                  placeholder="Account Number *"
                                  margin="dense"
                                  variant="outlined"
                                  inputProps={{
                                    style: { textTransform: "uppercase" },
                                  }}
                                  // label='Bank A/c No *'
                                  value={this.state.vendorKYC[idx].bankACNo}
                                  onChange={this.handleVenodrKYC(idx)}
                                  error={this.state.bankACNo1Error}
                                  fullWidth
                                  InputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      backgroundColor: "white",
                                      fontSize: "14px",
                                    },
                                  }}
                                  style={{ marginTop: 0 }}
                                />
                              </div>
                              <div className="col-lg-4 mb-2 p-0">
                                <label className="form_label">
                                  Swift Code{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <TextField
                                  name="swiftCode"
                                  placeholder="Swift Code *"
                                  margin="dense"
                                  // label='Swift Code *'
                                  variant="outlined"
                                  inputProps={{
                                    style: { textTransform: "uppercase" },
                                  }}
                                  value={this.state.vendorKYC[idx].swiftCode}
                                  onChange={this.handleVenodrKYC(idx)}
                                  error={this.state.swiftCode1Error}
                                  fullWidth
                                  InputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      backgroundColor: "white",
                                      fontSize: "14px",
                                    },
                                  }}
                                  style={{ marginTop: 0 }}
                                />
                              </div>
                              <div className="col-lg-4 mb-2 pr-0">
                                <label className="form_label">
                                  Address{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <TextField
                                  name="address"
                                  placeholder="Address *"
                                  margin="dense"
                                  inputProps={{
                                    style: { textTransform: "uppercase" },
                                  }}
                                  variant="outlined"
                                  // label='Address *'
                                  value={this.state.vendorKYC[idx].address}
                                  onChange={this.handleVenodrKYC(idx)}
                                  error={this.state.address1Error}
                                  fullWidth
                                  InputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      backgroundColor: "white",
                                      fontSize: "14px",
                                    },
                                  }}
                                  style={{ marginTop: 0 }}
                                />
                              </div>
                              <div className="col-lg-4 mb-2 pr-0">
                                <label className="form_label">
                                  Currency{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <TextField
                                  name="currency"
                                  margin="dense"
                                  variant="outlined"
                                  // label='Currency *'
                                  value={this.state.vendorKYC[idx].currency}
                                  onChange={this.handleVenodrKYC(idx)}
                                  error={this.state.currency1Error}
                                  style={{ width: "85%", marginTop: 0 }}
                                  inputProps={{
                                    style: { textTransform: "uppercase" },
                                  }}
                                  select
                                  InputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      backgroundColor: "white",
                                      minWidth: "165px",
                                      fontSize: "14px",
                                    },
                                  }}
                                >
                                  {this.state.currencyData}
                                </TextField>
                                <span>
                                  <AddCircle
                                    onClick={() =>
                                      this.setState({ clickedCurrency: idx })
                                    }
                                    data-toggle="modal"
                                    data-target="#myModal"
                                    style={{
                                      fontSize: 24,
                                      marginTop: 6,
                                      marginLeft: 3,
                                      color: config.themeColor,
                                    }}
                                  />
                                </span>
                              </div>
                              <div className="col-lg-4 p-0">
                                <label className="form_label">
                                  IBAN Number
                                </label>
                                <TextField
                                  name="IBAN_Num"
                                  placeholder="IBAN No"
                                  margin="dense"
                                  // label='IBAN No'
                                  variant="outlined"
                                  inputProps={{
                                    style: { textTransform: "uppercase" },
                                  }}
                                  value={this.state.vendorKYC[idx].IBAN_Num}
                                  error={this.state.IBAN_Num1Error}
                                  onChange={this.handleVenodrKYC(idx)}
                                  fullWidth
                                  InputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      backgroundColor: "white",
                                      fontSize: "14px",
                                    },
                                  }}
                                  style={{ marginTop: 0 }}
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="row" key={idx}>
                            <div className="row col-lg-11 pb-4 mb-2 section_background">
                              <div className="col-lg-4 mb-2 p-0">
                                <label className="form_label">Bank Name</label>
                                <TextField
                                  name="bankName"
                                  placeholder="Bank Name"
                                  margin="dense"
                                  variant="outlined"
                                  inputProps={{
                                    style: { textTransform: "uppercase" },
                                  }}
                                  // label='Bank Name *'
                                  value={this.state.vendorKYC[idx].bankName}
                                  onChange={this.handleVenodrKYC(idx)}
                                  // error={this.state.bankName1Error}
                                  fullWidth
                                  InputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      backgroundColor: "white",
                                      fontSize: "14px",
                                    },
                                  }}
                                  style={{ marginTop: 0 }}
                                />
                              </div>
                              <div className="col-lg-4 mb-2 pr-0">
                                <label className="form_label">
                                  Account Holder Name
                                </label>
                                <TextField
                                  name="bankAcHolderName"
                                  placeholder="Account Holder Name"
                                  margin="dense"
                                  variant="outlined"
                                  inputProps={{
                                    style: { textTransform: "uppercase" },
                                  }}
                                  // label='Bank A/c Holder Name *'
                                  value={
                                    this.state.vendorKYC[idx].bankAcHolderName
                                  }
                                  onChange={this.handleVenodrKYC(idx)}
                                  // error={this.state.bankAcHolderName1Error}
                                  fullWidth
                                  InputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      backgroundColor: "white",
                                      fontSize: "14px",
                                    },
                                  }}
                                  style={{ marginTop: 0 }}
                                />
                              </div>
                              <div className="col-lg-4 mb-2 pr-0">
                                <label className="form_label">
                                  Account Number
                                </label>
                                <TextField
                                  name="bankACNo"
                                  placeholder="Account Number"
                                  margin="dense"
                                  variant="outlined"
                                  inputProps={{
                                    style: { textTransform: "uppercase" },
                                  }}
                                  // label='Bank A/c No *'
                                  value={this.state.vendorKYC[idx].bankACNo}
                                  onChange={this.handleVenodrKYC(idx)}
                                  // error={this.state.bankACNo1Error}
                                  fullWidth
                                  InputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      backgroundColor: "white",
                                      fontSize: "14px",
                                    },
                                  }}
                                  style={{ marginTop: 0 }}
                                />
                              </div>
                              <div className="col-lg-4 mb-2 p-0">
                                <label className="form_label">Swift Code</label>
                                <TextField
                                  name="swiftCode"
                                  placeholder="Swift Code"
                                  margin="dense"
                                  // label='Swift Code *'
                                  variant="outlined"
                                  inputProps={{
                                    style: { textTransform: "uppercase" },
                                  }}
                                  value={this.state.vendorKYC[idx].swiftCode}
                                  onChange={this.handleVenodrKYC(idx)}
                                  // error={this.state.swiftCode1Error}
                                  fullWidth
                                  InputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      backgroundColor: "white",
                                      fontSize: "14px",
                                    },
                                  }}
                                  style={{ marginTop: 0 }}
                                />
                              </div>
                              <div className="col-lg-4 mb-2 pr-0">
                                <label className="form_label">Address</label>
                                <TextField
                                  name="address"
                                  placeholder="Address"
                                  margin="dense"
                                  inputProps={{
                                    style: { textTransform: "uppercase" },
                                  }}
                                  variant="outlined"
                                  // label='Address *'
                                  value={this.state.vendorKYC[idx].address}
                                  onChange={this.handleVenodrKYC(idx)}
                                  // error={this.state.address1Error}
                                  fullWidth
                                  InputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      backgroundColor: "white",
                                      fontSize: "14px",
                                    },
                                  }}
                                  style={{ marginTop: 0 }}
                                />
                              </div>
                              <div className="col-lg-4 mb-2 pr-0">
                                <label className="form_label">Currency</label>
                                <TextField
                                  name="currency"
                                  margin="dense"
                                  variant="outlined"
                                  // label='Currency *'
                                  value={this.state.vendorKYC[idx].currency}
                                  onChange={this.handleVenodrKYC(idx)}
                                  // error={this.state.vendorKYC[idx].currencyError}
                                  style={{ width: "85%", marginTop: 0 }}
                                  inputProps={{
                                    style: { textTransform: "uppercase" },
                                  }}
                                  select
                                  InputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      backgroundColor: "white",
                                      minWidth: "165px",
                                      fontSize: "14px",
                                    },
                                  }}
                                >
                                  {this.state.currencyData}
                                </TextField>
                                <span>
                                  <AddCircle
                                    onClick={() =>
                                      this.setState({ clickedCurrency: idx })
                                    }
                                    data-toggle="modal"
                                    data-target="#myModal"
                                    style={{
                                      fontSize: 24,
                                      marginTop: 6,
                                      marginLeft: 3,
                                      color: config.themeColor,
                                    }}
                                  />
                                </span>
                              </div>
                              <div className="col-lg-4 p-0">
                                <label className="form_label">
                                  IBAN Number
                                </label>
                                <TextField
                                  name="IBAN_Num"
                                  placeholder="IBAN No"
                                  margin="dense"
                                  // label='IBAN No'
                                  variant="outlined"
                                  inputProps={{
                                    style: { textTransform: "uppercase" },
                                  }}
                                  value={this.state.vendorKYC[idx].IBAN_Num}
                                  onChange={this.handleVenodrKYC(idx)}
                                  fullWidth
                                  InputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      backgroundColor: "white",
                                      fontSize: "14px",
                                    },
                                  }}
                                  style={{ marginTop: 0 }}
                                />
                              </div>
                            </div>
                            {this.state.vendorKYC.length === 1 ? (
                              <span></span>
                            ) : (
                              <div style={{ margin: "auto" }}>
                                <div className="icon_hover text-align-center">
                                  <i
                                    className="fa fa-trash-o"
                                    onClick={this.handleRemoveKycRows(idx)}
                                    style={{
                                      fontSize: 24,
                                      color: config.themeColor,
                                    }}
                                    aria-hidden="true"
                                  />
                                </div>
                                {/* <div className="icon_hover text-align-center">
                                  <RemoveIcon className="icon_hover" onClick={this.handleRemoveKycRows(idx)} style={{fontSize:24, color: config.themeColor}}/>
                                </div> */}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    ))}
                    <div className="row">
                      <div className="col-lg pt-2 pl-0 mb-2">
                        {this.state.vendorKYC.length < 5 && (
                          // <button onClick={this.openDailog} className='header_button header_button_text add_button_adjustment'>ADD</button>
                          <button
                            type="button"
                            onClick={this.handleKYCRows}
                            className="header_button header_button_text addrow_button_adjustment"
                            style={{ color: config.themeColor }}
                          >
                            Add New KYC
                          </button>
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-4 pl-0 mb-2">
                        <label className="form_label">Tax ID</label>
                        <TextField
                          name="taxId"
                          placeholder="Tax ID"
                          margin="dense"
                          variant="outlined"
                          value={this.state.taxId}
                          error={this.state.taxIDError}
                          onChange={this.handleText}
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          fullWidth
                          InputProps={{
                            style: {
                              fontFamily: "Poppins",
                              fontSize: "14px",
                            },
                          }}
                          style={{ marginTop: 0 }}
                        />
                      </div>
                      <div className="col-lg-8 pl-0 mb-2">
                        <label className="form_label">
                          Share Point Document Link
                        </label>
                        <TextField
                          name="shareDocLink"
                          placeholder="Share Point Document Link"
                          margin="dense"
                          variant="outlined"
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          value={this.state.shareDocLink}
                          onChange={this.handleText}
                          fullWidth
                          InputProps={{
                            style: {
                              fontFamily: "Poppins",
                              fontSize: "14px",
                            },
                          }}
                          style={{ marginTop: 0 }}
                        />
                      </div>
                    </div>
                    {(this.state.features.includes("324") ||
                      this.state.features.includes("97")) && (
                      <FileUpload
                        files={this.state.files}
                        feature_name="Vendor"
                        callbackFn={(data) => this.setState({ files: data })}
                      />
                    )}
                  </div>
                )}
                {this.state.activeStep === 2 && (
                  <div className="section_block">
                    <div className="section_block_seperator p-0">
                      <h5 className="mb-0 p-2 section_header">
                        Vendor Details
                      </h5>
                      <div className="row col-lg-12 mt-2">
                        <div className="col-lg-4 p-0">
                          <div
                            className="row mb-0"
                            style={{ display: "inline-block" }}
                          >
                            <label className="contract_display_header_label">
                              Name
                            </label>
                          </div>
                          <div>
                            <label className="contract_display_header_value">
                              {this.state.vendorName === ""
                                ? "-"
                                : this.state.vendorName}
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-4 pr-0">
                          <div
                            className="row mb-0"
                            style={{ display: "inline-block" }}
                          >
                            <label className="contract_display_header_label">
                              Type
                            </label>
                          </div>
                          <div>
                            <label className="contract_display_header_value">
                              {this.state.vendorType === ""
                                ? "-"
                                : this.state.vendorType}{" "}
                              {this.state.vendorType === "Others"
                                ? " (" + this.state.otherVendor + ")"
                                : null}
                            </label>
                          </div>
                          {/* {this.state.vendorType === "others" ?
                          <label className="contract_display_header_value">{' ('+this.state.otherVendor+')'}</label>
                          :
                          null
                          } */}
                        </div>
                        <div className="col-lg-4 pr-0">
                          <div
                            className="row mb-0"
                            style={{ display: "inline-block" }}
                          >
                            <label className="contract_display_header_label">
                              Country
                            </label>
                          </div>
                          <div>
                            <label className="contract_display_header_value">
                              {this.state.vendorCountry === ""
                                ? "-"
                                : this.state.vendorCountry}
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-4 mt-2 p-0">
                          <div
                            className="row mb-0"
                            style={{ display: "inline-block" }}
                          >
                            <label className="contract_display_header_label">
                              Address
                            </label>
                          </div>
                          <div>
                            <label className="contract_display_header_value">
                              {this.state.vendorAddress === ""
                                ? "-"
                                : this.state.vendorAddress}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row col-lg-12">
                        <div className="col-lg-4 mt-2 p-0">
                          <div
                            className="row mb-0"
                            style={{ display: "inline-block" }}
                          >
                            <label className="contract_display_header_label">
                              Tax ID
                            </label>
                          </div>
                          <div>
                            <label className="contract_display_header_value">
                              {this.state.taxId === "" ? "-" : this.state.taxId}
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-8 mt-2 pr-0">
                          <div
                            className="row mb-0"
                            style={{ display: "inline-block" }}
                          >
                            <label className="contract_display_header_label">
                              Share Point Document Link
                            </label>
                          </div>
                          <div>
                            <label
                              className="contract_display_header_value"
                              style={{ textTransform: "uppercase" }}
                            >
                              {this.state.shareDocLink === ""
                                ? "-"
                                : this.state.shareDocLink}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    {this.state.contactDetails.length > 0 && (
                      <div className="section_block_seperator p-0">
                        <h5 className="mb-0 p-2 section_header">Contact</h5>
                        <div className="row">
                          {this.state.contactDetails.map((row, index) => (
                            <div
                              key={index}
                              className="col-lg-5 m-3 section_block_seperator"
                              style={{
                                flexDirection: "row",
                                display: "inline-flex",
                              }}
                            >
                              <span className="contact_short_icon">
                                {row.contactPsn.charAt(0)}
                              </span>
                              <span className="contact_details_name_view">
                                {row.contactPsn}
                                <br />
                                <span className="contact_details_view">
                                  {row.email}
                                  <br />
                                  {/* {row.phone}<br /> */}
                                  {row.handPhone}
                                </span>
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    <div className="section_block_seperator p-0">
                      <h5 className="mb-0 p-2 section_header">Vendor KYC</h5>
                      <div className="row">
                        {this.state.vendorKYC.map((row, index) => (
                          <div
                            key={index}
                            className="col-lg-5 m-3 section_block_seperator"
                            style={{
                              flexDirection: "row",
                              display: "inline-flex",
                            }}
                          >
                            <span className="contact_details_name_view">
                              {row.bankAcHolderName}
                              <br />
                              <span
                                className="contact_details_view"
                                style={{
                                  fontSize: 12,
                                  display: "block",
                                  marginBottom: 10,
                                }}
                              >
                                {"A/c No: " + row.bankACNo}
                              </span>
                              <span className="contact_details_view">
                                {row.bankName}
                                <br />
                                {row.IBAN_Num}
                                <br />
                                {row.address}
                                <br />
                                <div className="row">
                                  <span style={{ float: "left" }}>
                                    Swift Code: {row.swiftCode}
                                  </span>
                                  <span
                                    style={{
                                      float: "right",
                                      marginLeft: "17px",
                                    }}
                                  >
                                    Currency: {row.currency}
                                  </span>
                                </div>
                              </span>
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                    {/* <br />
                    <div className='row'>
                      <button type='button' className='btn btn-info btn-responsive mr-3' name='submit' onClick={this.handleSubmit}>Submit</button>
                      <button type='button' className='btn btn-secondary btn-responsive' name='Back' onClick={()=>{window.location.href='/vendor-list'}}>Cancel</button>
                    </div> */}
                  </div>
                )}
              </div>
              {this.state.activeStep === 0 && (
                <div className="row bottom_buttons_section">
                  <button
                    type="button"
                    className="btn previous_button"
                    onClick={() => (window.location.href = "/business-partner")}
                    name="submit"
                  >
                    Back
                  </button>
                  <button
                    type="button"
                    className="btn next_button"
                    name="Back"
                    onClick={this.firstActiveSection}
                  >
                    NEXT STEP
                  </button>
                </div>
              )}
              {this.state.activeStep === 1 && (
                <div className="row bottom_buttons_section">
                  <button
                    type="button"
                    className="btn previous_button"
                    name="submit"
                    onClick={() => this.setState({ activeStep: 0 })}
                  >
                    PREVIOUS
                  </button>
                  <button
                    type="button"
                    className="btn next_button"
                    name="Back"
                    onClick={this.secondActiveSection}
                  >
                    NEXT STEP
                  </button>
                </div>
              )}
              {this.state.activeStep === 2 && (
                <div className="row bottom_buttons_section">
                  <button
                    type="button"
                    className="btn previous_button"
                    name="submit"
                    onClick={() => this.setState({ activeStep: 1 })}
                  >
                    PREVIOUS
                  </button>
                  <button
                    type="button"
                    className="btn next_button"
                    name="Back"
                    onClick={this.handleSubmit}
                  >
                    SUBMIT
                  </button>
                </div>
              )}
            </form>
          )}
        </div>

        <div className="modal fade" id="myModal" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4
                  className="modal-title"
                  style={{ border: "none", padding: 0 }}
                >
                  Add Currency{" "}
                </h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <TextField
                  name="newCurrency"
                  placeholder={
                    this.state.vendorKYC.length === "0"
                      ? "Add New Currency *"
                      : "Add New Currency"
                  }
                  margin="dense"
                  variant="outlined"
                  inputProps={{ style: { textTransform: "uppercase" } }}
                  onChange={this.handleText}
                  value={this.state.newCurrency}
                  error={this.state.modalCurrencyError}
                />
              </div>
              <div className="row bottom_buttons_section">
                <button
                  type="button"
                  className="btn previous_button"
                  name="submit"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn next_button"
                  name="Back"
                  onClick={this.handleAddCurrency}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
