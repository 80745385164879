import React, { Component } from "react";

import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import api from "../../../api/api";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import EventIcon from "@material-ui/icons/Event";
import DateFnsUtils from "@date-io/date-fns";
import {
  TextField,
  IconButton,
  InputAdornment,
  Snackbar,
  Drawer,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import VesselInsuranceAdd from "./Vessel-Insurance-Add";
import VesselInsuranceUpdate from "./Vessel-Insurance-Update";
import ViewFiles from "../../common/ViewFiles";
import PaymentPostings from "../../common/PaymentPostings";

import DeleteConfirmation from "../../common/DeleteConfirmation";
import { localDateFormate, toLocaleString } from "../../common/common";
import Loader from "../../common/Loader";
import InvoiceForm from "../../common/InvoiceForm";

export default class VesselInsuranceView extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      showDrawer: false,
      insuranceAdd: false,
      insuranceUpdate: false,
      vessel_owner: "",
      quantity: "",
      price_pmt: "",
      total: "",
      remarks: "",
      isLoading: true,
      features: [],
      insurance_payments: [],
      files: [],
      invoiceDetails: {
        invoice_no: "",
        invoice_date: null,
        ppn: "",
        ppn_date: null,
      },
      vessel_insurance: false,
      closeDialog: false,
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    } else {
      var features = this.Cookie.getCookie("features");
      this.setState({ features: features });
      if (!(features.includes("355") || features.includes("97"))) {
        window.location.href = "/dashboard";
      }
      const loginUserID = this.Cookie.getCookie("loginUserId");
      const idToken = this.Cookie.getIdTokenCookie();
      // var businessNoID = decodeURIComponent(window.atob(this.props.match.params.businessNoID));
      const businessNoID = this.props.businessNoID;
      if (businessNoID) {
        //Get businessNumber API based on businessNumber
        await api
          .getBusinessNumber(businessNoID, loginUserID, idToken)
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              response.json().then((res) => {
                if (res.code === "200") {
                  if (res.business_number) {
                    this.setState({
                      businessNo: res.business_number.business_no
                        ? res.business_number.business_no
                        : "",
                      mother_vessel_name: res.business_number.mother_vessel_name
                        ? res.business_number.mother_vessel_name
                        : "",
                      // isLoading: false
                    });
                  }
                } else if (res.code === "601") {
                  this.setState({
                    errorMsg: res.message,
                    snackBarErrorOpen: true,
                  });
                } else if (res.code === "607") {
                  window.location.href = "/logout";
                }
              });
            }
          });

        const insurance_id = "";
        await api
          .get_insurance_and_declaration(
            loginUserID,
            idToken,
            insurance_id,
            businessNoID
          )
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              response.json().then((res) => {
                if (res.code === "200") {
                  if (res.insurance_and_declaration) {
                    var total_payment_amount = 0;
                    var remaining_amount = 0;
                    let total_payable =
                      Number(
                        res.insurance_and_declaration.p_and_i_value
                          ? res.insurance_and_declaration.p_and_i_value
                          : 0
                      ) +
                      Number(
                        res.insurance_and_declaration.fd_and_d_value
                          ? res.insurance_and_declaration.fd_and_d_value
                          : 0
                      );
                    for (var i of res.insurance_and_declaration
                      .insurance_and_declaration_payments) {
                      total_payment_amount += Number(i.amount);
                    }
                    remaining_amount =
                      Number(total_payable) - Number(total_payment_amount);
                    let invoice_details = {
                      ...this.state.invoiceDetails,
                      invoice_no: res.insurance_and_declaration[0]?.invoice_no,
                      invoice_date:
                        res.insurance_and_declaration[0]?.invoice_date,
                      ppn: res.insurance_and_declaration[0]?.ppn,
                      ppn_date: res.insurance_and_declaration[0]?.ppn_date,
                    };
                    this.setState({
                      invoiceDetails: invoice_details,
                      vessel_insurance: true,
                      remaining_amount: remaining_amount,
                      total_payment_amount: total_payment_amount,
                      total_payable: total_payable,
                      insurance_id: res.insurance_and_declaration.id,
                      insurance_level:
                        res.insurance_and_declaration.insurance_level,
                      business_no_id:
                        res.insurance_and_declaration.business_no_id,
                      voyage_type: res.insurance_and_declaration.voyage_type,
                      vessel_type: res.insurance_and_declaration.vessel_type,
                      barge_vessel_name:
                        res.insurance_and_declaration.barge_vessel_name,
                      imo_no: res.insurance_and_declaration.imo_no,
                      year_built: res.insurance_and_declaration.year_built,
                      flag: res.insurance_and_declaration.flag,
                      grt: res.insurance_and_declaration.grt,
                      classification:
                        res.insurance_and_declaration.classification,
                      owners_p_and_i_cover:
                        res.insurance_and_declaration.owners_p_and_i_cover,
                      charterparty: res.insurance_and_declaration.charterparty,
                      delivery_date:
                        res.insurance_and_declaration.delivery_date,
                      estimated_duration:
                        res.insurance_and_declaration.estimated_duration,
                      time_or_voyage:
                        res.insurance_and_declaration.time_or_voyage,
                      cargo: res.insurance_and_declaration.cargo,
                      charterers_p_and_i_liability:
                        res.insurance_and_declaration
                          .charterers_p_and_i_liability,
                      charterers_fd_and_d_liability:
                        res.insurance_and_declaration
                          .charterers_fd_and_d_liability,
                      back_to_back_sublet:
                        res.insurance_and_declaration.back_to_back_sublet,
                      charterers_b_l:
                        res.insurance_and_declaration.charterers_b_l,
                      contract_type:
                        res.insurance_and_declaration.contract_type,
                      p_and_i_value:
                        res.insurance_and_declaration.p_and_i_value,
                      fd_and_d_value:
                        res.insurance_and_declaration.fd_and_d_value,
                      exchange_rate:
                        res.insurance_and_declaration.exchange_rate,
                      currency: res.insurance_and_declaration.currency,
                      cost_to_company:
                        res.insurance_and_declaration.cost_to_company,
                      insurance_payments:
                        res.insurance_and_declaration
                          .insurance_and_declaration_payments,
                      files: res.insurance_and_declaration.files,
                      isLoading: false,
                    });
                  } else {
                    this.setState({
                      isLoading: false,
                      vessel_insurance: false,
                    });
                  }
                } else if (res.code === "601") {
                  this.setState({
                    snackBarErrorOpen: true,
                    errorMsg: res.message,
                  });
                } else if (res.code === "607") {
                  window.location.href = "/logout";
                }
              });
            }
          });
      } else {
        this.setState({
          snackBarErrorOpen: true,
          errorMsg: "Invalid business number",
        });
      }
    }

    // document.title = config.documentTitle + "Mother Vessel Purchase Financial Floating Crane Costing";
  }

  PaymentPostingsHandler = () => {
    this.setState({ submitting: true });
    var {
      payment_posting_type,
      date_of_payment,
      amount,
      invoice_no,
      description,
    } = this.state;
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    //var status = "Active";

    if (date_of_payment === null) {
      this.setState({
        date_of_paymentError: true,
        submitting: false,
      });
    }
    if (amount === "") {
      this.setState({
        amountError: true,
        submitting: false,
      });
    }
    if (invoice_no === "") {
      this.setState({
        invoice_noError: true,
        submitting: false,
      });
    }

    // Doing Amount calculation
    var remainingAmount = 0;

    remainingAmount = Number(this.state.remainingAmount);
    if (Number(amount) > Math.ceil(Number(remainingAmount))) {
      this.setState({
        amountError: true,
        submitting: false,
        paymentErrorMsg: "Amount is exceeding Cost to company",
      });
      return;
    }
    const insurance_id = this.state.insurance_id;
    if (date_of_payment !== null && amount !== "" && invoice_no !== "") {
      // return;
      api
        .add_insurance_and_declaration_payments(
          loginUserID,
          idToken,
          insurance_id,
          payment_posting_type,
          amount,
          date_of_payment,
          invoice_no,
          description
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                this.setState({
                  paymentTitle: "",
                  payment_posting_type: "",
                  amount: "",
                  date_of_payment: null,
                  invoice_no: "",
                  description: "",
                  date_of_paymentError: false,
                  amountError: false,
                  invoice_noError: false,
                  paymentErrorMsg: "",
                  submitting: false,
                });
                window.$("#bargePurchasePaymentPostingModal").modal("hide");
                this.componentDidMount();
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  snackBarErrorOpen: true,
                  submitting: false,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    } else {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please fill mandatory fields.",
        submitting: false,
      });
    }
  };

  getBargePurchasePaymentPosting = (e) => {
    if (e) {
      var paymentTitle = "";
      if (e.payment_posting_type === "insurance") {
        paymentTitle = "Update ";
      }

      this.setState({
        existingAmount: e.amount,
        payment_posting_id: e.id,
        paymentTitle: paymentTitle,
        payment_posting_type: e.payment_posting_type,
        amount: e.amount,
        date_of_payment: e.date_of_payment,
        invoice_no: e.invoice_no ? e.invoice_no : "",
        description: e.description ? e.description : "",
        date_of_paymentError: false,
        amountError: false,
        paymentErrorMsg: "",
        paymentMode: "Update",
      });
      window.$("#bargePurchasePaymentPostingModal").modal({
        backdrop: "static",
        keyboard: true,
        show: true,
      });
    }
  };

  updatePaymentPostingHandler = () => {
    this.setState({
      submitting: true,
    });
    var {
      payment_posting_type,
      date_of_payment,
      amount,
      invoice_no,
      description,
    } = this.state;
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    var insurance_payment_id = this.state.payment_posting_id;
    var status = "Active";

    if (date_of_payment === null) {
      this.setState({
        date_of_paymentError: true,
        submitting: false,
      });
    }
    if (amount === "") {
      this.setState({
        amountError: true,
        submitting: false,
      });
    }
    if (invoice_no === "") {
      this.setState({
        invoice_noError: true,
        submitting: false,
      });
    }
    // Doing Amount calculation
    var remainingAmount = 0;

    remainingAmount = Number(this.state.remainingAmount);
    if (this.state.paymentMode === "Update") {
      remainingAmount =
        Number(this.state.remainingAmount) + Number(this.state.existingAmount);
    }
    if (Number(amount) > Math.ceil(Number(remainingAmount))) {
      this.setState({
        amountError: true,
        submitting: false,
        paymentErrorMsg: "Amount is exceeding Cost to company",
      });
      return;
    }
    const insurance_id = this.state.insurance_id;
    if (date_of_payment !== null && amount !== "" && invoice_no !== "") {
      api
        .update_insurance_and_declaration_payments(
          loginUserID,
          idToken,
          insurance_id,
          insurance_payment_id,
          payment_posting_type,
          amount,
          date_of_payment,
          invoice_no,
          description,
          status
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                this.setState({
                  paymentTitle: "",
                  payment_posting_type: "",
                  amount: "",
                  date_of_payment: null,
                  invoice_no: "",
                  description: "",
                  date_of_paymentError: false,
                  amountError: false,
                  invoice_noError: false,
                  paymentErrorMsg: "",
                  submitting: false,
                });
                window.$("#bargePurchasePaymentPostingModal").modal("hide");
                this.componentDidMount();
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  succesMsg: "",
                  submitting: false,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    } else {
      this.setState({
        submitting: false,
        snackBarErrorOpen: true,
        errorMsg: "Please fill mandatory fields.",
      });
    }
  };

  deletePaymentPostingHandler = (e) => {
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    var insurance_id = this.state.insurance_id;
    var insurance_payment_id = e.id;
    var payment_posting_type = e.payment_posting_type;
    var amount = e.amount;
    var date_of_payment = e.date_of_payment;
    var invoice_no = e.invoice_no ? e.invoice_no : "";
    var description = e.description ? e.description : "";
    var status = "Deleted";
    api
      .update_insurance_and_declaration_payments(
        loginUserID,
        idToken,
        insurance_id,
        insurance_payment_id,
        payment_posting_type,
        amount,
        date_of_payment,
        invoice_no,
        description,
        status
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState({ deletePayment: false });
              this.componentDidMount();
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                succesMsg: "",
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  };

  onAddResponse = (value) => {
    this.setState({ insuranceAdd: false, showDrawer: false }, () => {
      this.componentDidMount();
    });
  };

  onUpdateResponse = (value) => {
    if (value) {
      this.setState(
        {
          showDrawer: false,
          insuranceAdd: false,
          insuranceUpdate: false,
        },
        () => this.componentDidMount()
      );
    }
  };

  CallbackDrawerClose = (childData) => {
    this.setState({
      showDrawer: childData,
      insuranceAdd: false,
      insuranceUpdate: false,
    });
  };

  renderPaymentPostings = (data) => {
    const {
      id,
      insurance_payments = [],
      invoice_no = null,
      remaining_amount = 0,
      sac_sampling_currency_xchg_rate = 1,
      approval_status = "Pending",
    } = data;
    let payment_related_data = {
      paymentTitle: "Pre Shipment",
      payment_posting_type: "Pre_Shipment",
      invoice_no: invoice_no,
      outstanding_amount: remaining_amount,
      billing_currency:
        config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL" ? "USD" : "IDR",
      currency_xchg_rate: sac_sampling_currency_xchg_rate,
      costing_id: id,
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
    };

    let access_details = {
      costing_approval: approval_status,
      add_payment: "358",
      view_payment: "359",
      update_payment: "360",
      delete_payment: "361",
      approver: "390",
      approver2: "441",
    };

    return (
      <PaymentPostings
        access_details={access_details}
        payments={insurance_payments}
        costingType="Export Tax"
        data={payment_related_data}
        callbackFn={() => {
          this.componentDidMount();
        }}
      />
    );
  };

  updateInvoice = (data) => {
    let request = {
      ...data,
      table_name: "mv_pur_fin_export_tax",
    };
    api.update_invoice_details(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.componentDidMount();
            this.setState({
              invoiceDialog: false,
            });
          }
        });
      }
    });
  };

  render() {
    let drawerComponent;

    if (
      !this.state.vessel_insurance &&
      this.state.insuranceAdd &&
      this.props.businessNoID
    ) {
      drawerComponent = (
        <VesselInsuranceAdd
          businessNo={this.props.businessNoID}
          onAddResponse={this.onAddResponse}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
        />
      );
    }
    if (
      this.state.vessel_insurance &&
      this.state.insuranceUpdate &&
      this.props.businessNoID
    ) {
      drawerComponent = (
        <VesselInsuranceUpdate
          businessNo={this.props.businessNoID}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
        />
      );
    }

    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div className="bg-white p-0">
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <Drawer
            anchor="right"
            open={this.state.showDrawer}
            variant="temporary"
            elevation={20}
            style={{ overflow: "initial" }}
          >
            <div className="row p-0" style={{ width: 800 }}>
              {drawerComponent}
            </div>
          </Drawer>
          <div className="row clearfix content-header border-bottom">
            <h4 style={{ padding: "4px 20px" }}>Insurance & Declaration</h4>
            {!this.state.vessel_insurance &&
              (this.state.features.includes("258") ||
                this.state.features.includes("97")) && (
                <i
                  className="fa fa-plus-circle ml-2 mt-2"
                  aria-hidden="true"
                  style={{
                    fontSize: 22,
                    color: config.themeColor,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    //this.props.returnAddCallBack(true);
                    this.setState({
                      showDrawer: true,
                      insuranceAdd: true,
                      insuranceUpdate: false,
                    });
                  }}
                />
              )}
            {this.state.vessel_insurance &&
              (this.state.features.includes("258") ||
                this.state.features.includes("97")) && (
                <i
                  className="fa fa-pencil mt-2 ml-2"
                  aria-hidden="true"
                  style={{
                    fontSize: 20,
                    color: config.themeColor,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    //this.props.returnEditCallback("MvFreightCosting");
                    this.setState({
                      showDrawer: true,
                      insuranceAdd: false,
                      insuranceUpdate: true,
                    });
                  }}
                />
              )}
          </div>
          <div
            className="card p-3"
            style={{
              overflowY: "scroll",
              height: "inherit",
              display: this.state.vessel_insurance ? "" : "none",
            }}
          >
            <div
              className="card-body p-0"
              style={{ backgroundColor: "rgba(25,118,210,.01)" }}
              id="accordion"
            >
              <div className="row">
                <div className="col-lg-12  border_dashed  rounded-lg">
                  <div className="card p-0 border mt-4 mb-3">
                    <div className="card-header section_header">
                      <h5> Insurance & Declaration</h5>
                    </div>
                    <div className="card-body">
                      <div className="row col-lg-12 p-0">
                        <div className="col-lg-4 mb-3">
                          <label className="contract_display_header_label">
                            Vessel Type
                          </label>
                          <div className="contract_display_header_value">
                            {this.state.voyage_type
                              ? this.state.voyage_type
                              : "-"}
                          </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label className="contract_display_header_label">
                            Vessel Name
                          </label>
                          <div className="contract_display_header_value">
                            {this.state.barge_vessel_name
                              ? this.state.barge_vessel_name
                              : "-"}
                          </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label className="contract_display_header_label">
                            IMO No
                          </label>
                          <div className="contract_display_header_value">
                            {this.state.imo_no ? this.state.imo_no : "-"}
                          </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label className="contract_display_header_label">
                            Year Built
                          </label>
                          <div className="contract_display_header_value">
                            {this.state.year_built
                              ? this.state.year_built
                              : "-"}
                          </div>
                        </div>
                        <div className="col-lg-4  mb-3">
                          <label className="contract_display_header_label">
                            Flag
                          </label>
                          <div className="contract_display_header_value">
                            {this.state.flag ? this.state.flag : "-"}
                          </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label className="contract_display_header_label">
                            Grt
                          </label>
                          <div className="contract_display_header_value">
                            {this.state.grt ? this.state.grt : "-"}
                          </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label className="contract_display_header_label">
                            Classification
                          </label>
                          <div className="contract_display_header_value">
                            {this.state.classification
                              ? this.state.classification
                              : "-"}
                          </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label className="contract_display_header_label">
                            Owners P&I Cover
                          </label>
                          <div className="contract_display_header_value">
                            {this.state.owners_p_and_i_cover
                              ? this.state.owners_p_and_i_cover
                              : "-"}
                          </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label className="contract_display_header_label">
                            Charterparty
                          </label>
                          <div className="contract_display_header_value">
                            {this.state.charterparty
                              ? this.state.charterparty
                              : "-"}
                          </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label className="contract_display_header_label">
                            Delivery Date
                          </label>
                          <div className="contract_display_header_value">
                            {localDateFormate(this.state.delivery_date)}
                          </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label className="contract_display_header_label">
                            Estimated Duration
                          </label>
                          <div className="contract_display_header_value">
                            {this.state.estimated_duration
                              ? this.state.estimated_duration
                              : "-"}
                          </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label className="contract_display_header_label">
                            Voyage
                          </label>
                          <div className="contract_display_header_value">
                            {this.state.time_or_voyage
                              ? this.state.time_or_voyage
                              : "-"}
                          </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label className="contract_display_header_label">
                            Cargo
                          </label>
                          <div className="contract_display_header_value">
                            {this.state.cargo ? this.state.cargo : "-"}
                          </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label className="contract_display_header_label">
                            Charterers' P&I Liability
                          </label>
                          <div className="contract_display_header_value">
                            {this.state.charterers_p_and_i_liability
                              ? this.state.charterers_p_and_i_liability
                              : "-"}
                          </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label className="contract_display_header_label">
                            Charterers' FD&D Liability
                          </label>
                          <div className="contract_display_header_value">
                            {this.state.charterers_fd_and_d_liability
                              ? this.state.charterers_fd_and_d_liability
                              : "-"}
                          </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label className="contract_display_header_label">
                            Back to Back Sublet
                          </label>
                          <div className="contract_display_header_value">
                            {this.state.back_to_back_sublet
                              ? this.state.back_to_back_sublet
                              : "-"}
                          </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label className="contract_display_header_label">
                            Charterer's B/L
                          </label>
                          <div className="contract_display_header_value">
                            {this.state.charterers_b_l
                              ? this.state.charterers_b_l
                              : "-"}
                          </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label className="contract_display_header_label">
                            Contract Type
                          </label>
                          <div className="contract_display_header_value">
                            {this.state.contract_type
                              ? this.state.contract_type
                              : "-"}
                          </div>
                        </div>
                        {this.state.contract_type === "Per Vessel" && (
                          <div className="col-lg-4 mb-3">
                            <label className="contract_display_header_label">
                              P&I Value
                            </label>
                            <div className="contract_display_header_value">
                              {this.state.p_and_i_value
                                ? toLocaleString(this.state.p_and_i_value)
                                : "-"}{" "}
                              USD
                            </div>
                          </div>
                        )}
                        {this.state.contract_type === "Per Vessel" && (
                          <div className="col-lg-4 mb-3">
                            <label className="contract_display_header_label">
                              FD&D Value
                            </label>
                            <div className="contract_display_header_value">
                              {this.state.fd_and_d_value
                                ? toLocaleString(this.state.fd_and_d_value)
                                : "-"}{" "}
                              USD
                            </div>
                          </div>
                        )}
                        {this.state.contract_type === "Per Vessel" && (
                          <div className="col-lg-4 mb-3">
                            <label className="contract_display_header_label">
                              Currency
                            </label>
                            <div className="contract_display_header_value">
                              {this.state.currency ? this.state.currency : "-"}
                            </div>
                          </div>
                        )}
                        {(config.company === "SDAM" ||
                          config.company === "SRK" ||
                          config.company === "BTR") &&
                          this.state.contract_type === "Per Vessel" &&
                          this.state.currency !== "IDR" &&
                          this.state.currency !== "0" && (
                            <div className="col-lg-4 mb-3">
                              <label className="contract_display_header_label">
                                Exchange Rate {this.state.currency} to IDR
                              </label>
                              <div className="contract_display_header_value">
                                {this.state.exchange_rate
                                  ? toLocaleString(this.state.exchange_rate)
                                  : "-"}
                              </div>
                            </div>
                          )}
                        {(config.company === "SRPL" || config.company === "AVS"  ||
                          config.company === "PRPL") &&
                          this.state.contract_type === "Per Vessel" &&
                          this.state.currency !== "USD" &&
                          this.state.currency !== "0" && (
                            <div className="col-lg-4 mb-3">
                              <label className="contract_display_header_label">
                                Exchange Rate USD to {this.state.currency}
                              </label>
                              <div className="contract_display_header_value">
                                {this.state.exchange_rate
                                  ? toLocaleString(this.state.exchange_rate)
                                  : "-"}
                              </div>
                            </div>
                          )}
                        {this.state.contract_type === "Per Vessel" && (
                          <div className="col-lg-4 mb-3">
                            <label className="contract_display_header_label">
                              Cost to company
                            </label>
                            <div className="contract_display_header_value">
                              {this.state.cost_to_company
                                ? toLocaleString(this.state.cost_to_company)
                                : "-"}{" "}
                              {this.state.currency}
                            </div>
                          </div>
                        )}
                      </div>

                      <ViewFiles
                        {...this.props}
                        files_data={this.state.files}
                      />
                    </div>
                  </div>
                  {(this.state.features.includes("359") ||
                    this.state.features.includes("97")) &&
                    this.state.contract_type === "Per Vessel" && (
                      <div className="card p-0 col-lg-12 border mt-3 mb-3">
                        <div className="card-header section_header">
                          <h5>Payment Postings</h5>
                        </div>
                        <div className="card-body pl-0">
                          <InvoiceForm
                            open={false}
                            data={this.state.invoiceDetails}
                            costingID={this.state.id}
                            callbackFn={(data) => this.updateInvoice(data)}
                          />
                          <div className="row">
                            <div className="col-lg-4 mb-3">
                              <label className="contract_display_header_label">
                                Total Payable
                              </label>
                              <div className="contract_display_header_value">
                                {this.state.total_payable
                                  ? toLocaleString(this.state.total_payable)
                                  : "-"}
                              </div>
                            </div>
                            <div className="col-lg-4 mb-3">
                              <label className="contract_display_header_label">
                                Total Paid
                              </label>
                              <div className="contract_display_header_value">
                                {this.state.total_payment_amount
                                  ? toLocaleString(
                                      this.state.total_payment_amount
                                    )
                                  : "-"}
                              </div>
                            </div>
                            <div className="col-lg-4 mb-3">
                              <label className="contract_display_header_label">
                                Remaining Amount
                              </label>
                              <div className="contract_display_header_value">
                                {this.state.remaining_amount
                                  ? toLocaleString(this.state.remaining_amount)
                                  : "-"}
                              </div>
                            </div>
                          </div>
                          {this.renderPaymentPostings(this.state)}

                          {/* <div className="clearfix mt-1">
                            <div className="float-left p-2">
                              {this.state.insurance_payments.length <= 0 && (
                                <h5>No payments made</h5>
                              )}
                            </div>
                            {(this.state.features.includes("358")  ||
                              this.state.features.includes("97") ) && (
                              <div className="float-right p-2">
                                <button
                                  className="btn payment_button"
                                  type="button"
                                  data-toggle="modal"
                                  title="Barge Payment Postings"
                                  data-placement="bottom"
                                  data-keyboard="false"
                                  data-target="#bargePurchasePaymentPostingModal"
                                  data-backdrop="static"
                                  onClick={() => {
                                    this.setState({
                                      remainingAmount:
                                        this.state.remaining_amount,
                                      paymentTitle: "Add ",
                                      payment_posting_type: "insurance",
                                      date_of_payment: null,
                                      amount: "",
                                      invoice_no: "",
                                      description: "",
                                      paymentErrorMsg: "",
                                      date_of_paymentError: false,
                                      amountError: false,
                                      paymentMode: "Add",
                                      invoice_noError: false,
                                    });
                                  }}
                                >
                                  Add Payment
                                </button>
                              </div>
                            )}
                          </div>

                          {this.state.insurance_payments.length > 0 && (
                            <div className="row">
                              <div className="col-lg p-0">
                                <div className="table-responsive border-0">
                                  <table className="table table-borderless border-0">
                                    <thead>
                                      <tr>
                                        <th
                                          nowrap="true"
                                          className="table_header_barge_label border-0"
                                        >
                                          Date of Payment
                                        </th>
                                        <th
                                          nowrap="true"
                                          className="table_header_barge_label text-right border-0"
                                        >
                                          Amount
                                        </th>
                                        <th
                                          nowrap="true"
                                          className="table_header_barge_label text-left border-0"
                                        >
                                          Invoice No
                                        </th>
                                        <th
                                          className="table_header_barge_label text-left border-0"
                                          style={{ width: 300, maxWidth: 250 }}
                                        >
                                          Description
                                        </th>
                                        <th
                                          nowrap="true"
                                          className="table_header_barge_label text-left border-0"
                                        >
                                          Created Date
                                        </th>
                                        <th
                                          nowrap="true"
                                          className="table_header_barge_label text-left border-0"
                                        >
                                          Action
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.insurance_payments.map(
                                        (p, index) => (
                                          <tr key={index}>
                                            <td
                                              nowrap="true"
                                              className="table_td"
                                            >
                                              {p.date_of_payment
                                                ? this.dateFormate(
                                                    p.date_of_payment
                                                  )
                                                : null}
                                            </td>
                                            <td
                                              nowrap="true"
                                              className="table_td text-right"
                                            >
                                              {p.amount
                                                ? toLocaleString(p.amount)
                                                : ""}
                                            </td>
                                            <td
                                              nowrap="true"
                                              className="table_td text-left"
                                            >
                                              {p.invoice_no}
                                            </td>
                                            <td className="table_td text-left">
                                              {p.description}
                                            </td>
                                            <td
                                              nowrap="true"
                                              className="table_td text-left"
                                            >
                                              {p.created_date
                                                ? this.dateFormate(
                                                    p.created_date
                                                  )
                                                : null}
                                            </td>
                                            <td
                                              nowrap="true"
                                              className="table_td text-left"
                                            >
                                              {(this.state.features.includes(
                                                "360"
                                              )  ||
                                                this.state.features.includes(
                                                  "97"
                                                ) ) && (
                                                <i
                                                  className="fa fa-pencil edit_icon mr-3"
                                                  style={{
                                                    fontSize: 20,
                                                    color: config.themeColor,
                                                    cursor: "pointer",
                                                  }}
                                                  data-toggle="modal"
                                                  title="Edit Coal Payment Posting"
                                                  data-placement="bottom"
                                                  onClick={() => {
                                                    this.setState({
                                                      insurance_id:
                                                        p.insurance_id,
                                                      remainingAmount:
                                                        this.state
                                                          .remaining_amount,
                                                    });
                                                    this.getBargePurchasePaymentPosting(
                                                      p
                                                    );
                                                  }}
                                                ></i>
                                              )}
                                              {(this.state.features.includes(
                                                "361"
                                              )  ||
                                                this.state.features.includes(
                                                  "97"
                                                ) ) && (
                                                <i
                                                  className="fa fa-trash-o"
                                                  style={{
                                                    fontSize: 20,
                                                    color: "red",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    this.setState({
                                                      deletePayment: true,
                                                      deleteData: p,
                                                      insurance_id:
                                                        p.insurance_id,
                                                    })
                                                  }
                                                ></i>
                                              )}
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          )} */}
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
          {/*
           *
           * Barge Payements Posting Modals
           *
           */}

          <div
            className="modal fade"
            id="bargePurchasePaymentPostingModal"
            role="dialog"
            tabIndex="-1"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content  payment-modal">
                <div
                  className="modal-header border-0"
                  style={{ backgroundColor: "#f1f1f1" }}
                >
                  <h6
                    className="modal-title"
                    style={{ border: "none", padding: 0 }}
                  >
                    {this.state.paymentTitle} Payment Posting
                  </h6>
                  <button type="button" className="close" data-dismiss="modal">
                    &times;
                  </button>
                </div>
                <div className="modal-body p-0 mt-2">
                  <div className="col-lg-12">
                    <div
                      style={{
                        fontSize: 14,
                        color: "red",
                        textTransform: "capitalize",
                        textAlign: "center",
                      }}
                    >
                      {this.state.paymentErrorMsg}
                    </div>

                    <div className="row">
                      <div className="col-lg-6 mb-2">
                        <label className="form_label mb-0">
                          Date of Payment{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DatePicker
                            autoOk={true}
                            inputVariant="outlined"
                            variant="inline"
                            margin="dense"
                            fullWidth
                            format="dd/MM/yyyy"
                            value={this.state.date_of_payment}
                            error={this.state.date_of_paymentError}
                            onChange={(date) => {
                              this.setState({
                                date_of_payment: date,
                                date_of_paymentError: false,
                              });
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <IconButton>
                                    <EventIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                              className: "pl-0",
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </div>

                      <div className="col-lg-6 mb-2">
                        <label className="form_label mb-0">
                          Amount <span style={{ color: "red" }}>*</span>
                        </label>
                        <TextField
                          name="amount"
                          margin="dense"
                          variant="outlined"
                          placeholder="Amount"
                          required
                          value={this.state.amount}
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          fullWidth
                          error={this.state.amountError}
                          onChange={(e) => {
                            const pr = /^\d*(\.\d{0,4})?$/;
                            if (
                              e.target.value === "" ||
                              pr.test(e.target.value)
                            ) {
                              this.setState({
                                amount: e.target.value,
                                amountError: false,
                                paymentErrorMsg: "",
                              });
                              var remainingAmount = 0;
                              remainingAmount = Number(
                                this.state.remainingAmount
                              );
                              if (this.state.paymentMode === "Update") {
                                remainingAmount =
                                  Number(this.state.remainingAmount) +
                                  Number(this.state.existingAmount);
                              }
                              if (
                                Number(e.target.value) >
                                Math.ceil(Number(remainingAmount))
                              ) {
                                this.setState({
                                  amountError: true,
                                  submitting: false,
                                  paymentErrorMsg:
                                    "Amount is exceeding Cost to company",
                                });
                                return;
                              }
                            }
                          }}
                        />
                      </div>

                      <div className="col-lg-6 mb-2">
                        <label className="form_label mb-0">
                          Invoice No <span style={{ color: "red" }}>*</span>
                        </label>
                        <TextField
                          name="invoice_no"
                          margin="dense"
                          variant="outlined"
                          placeholder="Invoice No"
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          error={this.state.invoice_noError}
                          value={this.state.invoice_no}
                          onChange={(e) =>
                            this.setState({
                              invoice_no: e.target.value,
                              invoice_noError: false,
                            })
                          }
                          fullWidth
                        />
                      </div>

                      <div className="col-lg-6 mb-2">
                        <label className="form_label mb-0">Description</label>
                        <TextField
                          name="description"
                          margin="dense"
                          variant="outlined"
                          placeholder="Description"
                          multiline
                          rows={3}
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          value={this.state.description}
                          onChange={(e) =>
                            this.setState({ description: e.target.value })
                          }
                          fullWidth
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row bottom_buttons_section">
                    <button
                      type="button"
                      className="btn previous_button"
                      name="submit"
                      data-dismiss="modal"
                      onClick={() => {
                        this.setState({
                          paymentTitle: "",
                          payment_posting_type: "",
                          date_of_payment: null,
                          amount: "",
                          invoice_no: "",
                          description: "",
                          paymentErrorMsg: "",
                          date_of_paymentError: false,
                          amountError: false,
                          invoice_noError: false,
                          paymentMode: "",
                          submitting: false,
                        });
                      }}
                    >
                      Cancel
                    </button>
                    {this.state.paymentMode === "Add" ? (
                      <button
                        type="button"
                        className="btn next_button"
                        onClick={this.PaymentPostingsHandler}
                      >
                        Submit
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn next_button"
                        onClick={this.updatePaymentPostingHandler}
                      >
                        Update
                      </button>
                    )}
                    {/* <button type='button' className='btn next_button' name='Back' onClick={this.addAdvancePaymentHandler}>Submit</button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/**
           *
           *  Delete Payment Postings Modal
           *
           */}

          {this.state.deletePayment && (
            <DeleteConfirmation
              open={this.state.deletePayment}
              close={() => this.setState({ deletePayment: false })}
              callbackFn={() =>
                this.deletePaymentPostingHandler(this.state.deleteData)
              }
            />
          )}
        </div>
      );
    }
  }
}
