import React, { Component } from "react";
import api from "../../../../api/api";
import CookieHandler from "../../../common/CookieHandler";
import config from "../../../../config/config";
import {
  TextField,
  InputAdornment,
  Dialog,
  DialogTitle,
} from "@material-ui/core";
import { localDateFormate } from "../../../common/common";
import ViewFiles from "../../../common/ViewFiles";
import PaymentPostings from "../../../common/PaymentPostings";
import Loader from "../../../common/Loader";
import InvoiceForm from "../../../common/InvoiceForm";
import InvoiceLink from "../../../InvoicePdf/InvoiceLink";
export default class Outgoing_Credit_Note_View extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();

    this.state = {
      multiRows: [
        {
          credit_type: "Outgoing",
          vendor_or_customer: "Vendor",
          vendor_id: "",
          customer_id: "",
          date_of_creation: null,
          amount: "",
          remarks: "",
        },
      ],
      customers: [],
      vendors: [],
      invoiceDetails: {
        invoice_no: "",
        invoice_date: null,
        ppn: "",
        ppn_date: null,
      },
      isLoading: true,
      mother_vessel_name: "",
      features: [],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    } else {
      var features = this.Cookie.getCookie("features");
      this.setState({ features: features });
      if (
        !(
          features.includes("83") ||
          features.includes("485") ||
          features.includes("491") ||
          features.includes("97")
        )
      ) {
        window.location.href = "/dashboard";
      }
      var loginUserID = this.Cookie.getCookie("loginUserId");
      var idToken = this.Cookie.getIdTokenCookie();
      try {
        var businessNoID = this.props.businessNoID;
        var creditNoteID = this.props.creditNoteID;
      } catch (e) {
        window.location.href = "/business-number-list";
      }
      await api
        .get_buss_no_credit_note(loginUserID, idToken, creditNoteID)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                if (res.business_no_credit_note) {
                  const filter_outgoing_credit_notes =
                    res.business_no_credit_note.filter(
                      (e, idx) => e.credit_type === "Outgoing"
                    );
                  for (var i in filter_outgoing_credit_notes) {
                    var total_paid_amout = 0;
                    for (var j of filter_outgoing_credit_notes[i]
                      .Outgoing_payments) {
                      total_paid_amout += Number(j.amount);
                    }
                    filter_outgoing_credit_notes[i].total_paid_amout =
                      total_paid_amout;
                    filter_outgoing_credit_notes[i].remaining_amount =
                      Number(filter_outgoing_credit_notes[i].amount) -
                      Number(total_paid_amout);
                  }
                  let invoice_details = {
                    ...this.state.invoiceDetails,
                    invoice_no: filter_outgoing_credit_notes[0]?.invoice_no,
                    invoice_date: filter_outgoing_credit_notes[0]?.invoice_date,
                    ppn: filter_outgoing_credit_notes[0]?.ppn,
                    ppn_date: filter_outgoing_credit_notes[0]?.ppn_date,
                  };
                  this.setState({
                    invoiceDetails: invoice_details,
                    multiRows: filter_outgoing_credit_notes,
                    billing_currency:
                      filter_outgoing_credit_notes[0]?.billing_currency,
                    costing_no: filter_outgoing_credit_notes[0]?.costing_no,
                    vendor_or_customer:
                      filter_outgoing_credit_notes[0]?.vendor_or_customer,
                    // ppn_date: filter_outgoing_credit_notes[0]?.ppn_date,
                  });
                  if (filter_outgoing_credit_notes.length <= 0) {
                    window.$("#AlertMessageModal").modal({
                      backdrop: "static",
                      keyboard: true,
                      show: true,
                    });
                    this.setState({
                      errorMsg: "No outgoing credit data available",
                    });
                  }
                } else {
                  alert(
                    "Unexpected error occured. Please contact administrator."
                  );
                }
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });

      //Get businessNumber API based on businessNumber
      await api
        .getBusinessNumber(businessNoID, loginUserID, idToken)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                if (res.business_number) {
                  this.setState({
                    businessNo: res.business_number.business_no
                      ? res.business_number.business_no
                      : "",
                    mother_vessel_name: res.business_number.mother_vessel_name
                      ? res.business_number.mother_vessel_name
                      : "",
                    isLoading: false,
                  });
                }
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  sucuessMsg: "",
                });
                window.$("#AlertMessageModal").modal({
                  backdrop: "static",
                  keyboard: true,
                  show: true,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    }
    document.title = config.documentTitle + "View Outgoing Credit Note ";
  }

  AddPaymentPostingHandler = () => {
    this.setState({ submitting: true });
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    var {
      credit_note_id,
      payment_posting_type,
      amount,
      date_of_payment,
      invoice_no,
      description,
    } = this.state;
    if (date_of_payment === null) {
      this.setState({
        date_of_paymentError: true,
        submitting: false,
      });
    }
    if (amount === "") {
      this.setState({ amountError: true, submitting: false });
    }
    if (invoice_no === "") {
      this.setState({ invoice_noError: true, submitting: false });
    }

    var remainingAmount = 0;
    remainingAmount = Number(this.state.remainingAmount);
    if (this.state.paymentMode === "Update") {
      remainingAmount =
        Number(this.state.remainingAmount) + Number(this.state.existingAmount);
    }
    if (Number(amount) > Math.ceil(Number(remainingAmount))) {
      this.setState({
        amountError: true,
        submitting: false,
        paymentErrorMsg: "Amount is exceeding vendor / customer value",
      });
      return;
    }

    if (credit_note_id && amount !== "" && invoice_no !== "") {
      var business_no_id = this.props.businessNoID;
      var credit_type = "Outgoing";
      api
        .add_business_no_credit_note_payments(
          loginUserID,
          idToken,
          business_no_id,
          credit_note_id,
          credit_type,
          payment_posting_type,
          amount,
          date_of_payment,
          invoice_no,
          description
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                this.setState({
                  submitting: false,
                  date_of_payment: null,
                  invoice_no: "",
                  description: "",
                  date_of_paymentError: false,
                  amountError: false,
                  invoice_noError: false,
                  paymentPostingsModal: false,
                });
                window.$("#paymentPostingsModal").modal("hide");
                this.componentDidMount();
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: "",
                  successMsg: "",
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    } else {
      alert("Please fill mandatory fields.");
    }
  };

  UpdatePaymentPostingsHandler = () => {
    this.setState({ submitting: true });
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    var {
      credit_note_payment_id,
      credit_note_id,
      payment_posting_type,
      amount,
      date_of_payment,
      invoice_no,
      description,
    } = this.state;
    if (date_of_payment === null) {
      this.setState({
        date_of_paymentError: true,
        submitting: false,
      });
    }
    if (amount === "") {
      this.setState({ amountError: true, submitting: false });
    }
    if (invoice_no === "") {
      this.setState({ invoice_noError: true, submitting: false });
    }
    var remainingAmount = 0;
    remainingAmount = Number(this.state.remainingAmount);
    if (this.state.paymentMode === "Update") {
      remainingAmount =
        Number(this.state.remainingAmount) + Number(this.state.existingAmount);
    }
    if (Number(amount) > Math.ceil(Number(remainingAmount))) {
      this.setState({
        amountError: true,
        submitting: false,
        paymentErrorMsg: "Amount is exceeding vendor / customer value",
      });
      return;
    }

    if (credit_note_id && amount !== "" && invoice_no !== "") {
      let status = "Active";
      let credit_type = "Outgoing";
      var business_no_id = this.props.businessNoID;

      api
        .update_business_no_credit_note_payments(
          loginUserID,
          idToken,
          business_no_id,
          credit_note_id,
          credit_note_payment_id,
          credit_type,
          payment_posting_type,
          amount,
          date_of_payment,
          invoice_no,
          description,
          status
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                this.setState({
                  submitting: false,
                  date_of_payment: null,
                  invoice_no: "",
                  description: "",
                  date_of_paymentError: false,
                  amountError: false,
                  invoice_noError: false,
                  paymentPostingsModal: false,
                });
                window.$("#paymentPostingsModal").modal("hide");
                this.componentDidMount();
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: "",
                  successMsg: "",
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    } else {
      alert("Please fill mandatory fields.");
    }
  };

  getPaymentDetalis = (e) => {
    if (e) {
      this.setState({
        paymentMode: "Update",
        paymentTitle: "Update Outgoing Credit Note",
        credit_note_payment_id: e.id,
        credit_note_id: e.credit_note_id,
        date_of_payment: e.date_of_payment,
        amount: e.amount,
        existingAmount: e.amount,
        invoice_no: e.invoice_no,
        payment_posting_type: e.payment_posting_type,
        description: e.description,
        paymentPostingsModal: false,
      });
      window.$("#paymentPostingsModal").modal({
        backdrop: "static",
        keyboard: true,
        show: true,
      });
    }
  };

  toLocaleString(value) {
    return Number(value).toLocaleString();
  }

  approveHandler = () => {
    this.setState({ isLoading: true });
    let request = {
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
      costing_id: this.props.creditNoteID,
      costing_type: "MV Outgoing Credit Note",
      approval_status: "Approved",
    };
    api.approve_costing_api(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.setState(
              {
                snackBarSuccessOpen: true,
                successMsg: res.message,
              },
              () => this.componentDidMount()
            );
          } else if (res.code === "601") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
  };

  approvePaymentsHandler = (id) => {
    this.setState({ isLoading: true });
    let request = {
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
      payment_id: id,
      costing_type: "mv_credit_note_payment_data",
      approval_status: "Approved",
    };
    api.approve_payment_postings_api(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.setState(
              {
                snackBarSuccessOpen: true,
                successMsg: res.message,
              },
              () => this.componentDidMount()
            );
          } else if (res.code === "601") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
  };

  renderPaymentPostings = (data) => {
    const {
      id,
      Outgoing_payments = [],
      invoice_no = null,
      remaining_amount = 0,
      billing_currency,
      currency_xchg_rate = 1,
      approval_status = "Pending",
    } = data;
    let payment_related_data = {
      paymentTitle: "Outgoing Credit Note",
      payment_posting_type: "outgoing_credit_note",
      invoice_no: invoice_no,
      outstanding_amount: remaining_amount,
      billing_currency: billing_currency
        ? billing_currency
        : config.company === "SRPL" || config.company === "AVS" || config.company === "PRPL"
          ? "USD"
          : "IDR",
      currency_xchg_rate: currency_xchg_rate ? currency_xchg_rate : 1,
      costing_id: id,
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
    };

    let access_details = {
      costing_approval: approval_status,
      add_payment: "85",
      view_payment: "86",
      update_payment: "87",
      delete_payment: "498",
      approver: "486",
      approver2: "492",
    };

    return (
      <PaymentPostings
        access_details={access_details}
        payments={Outgoing_payments}
        costingType="MV Incoming Credit Note"
        data={payment_related_data}
        callbackFn={() => {
          this.componentDidMount();
        }}
      />
    );
  };

  updateInvoice = (data) => {
    let request = {
      ...data,
      table_name: "business_no_credit_note",
    };
    api.update_invoice_details(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.componentDidMount();
            this.setState({
              invoiceDialog: false,
            });
          }
        });
      }
    });
  };
  renderInvoiceLink = () => {
    const {
      costing_no,
      vendor_or_customer = "",
      invoiceDetails: { invoice_no = "", invoice_date = "" } = {},
    } = this.state;

    const Grand_total = this.state.multiRows[0].amount
      ? parseFloat(this.state.multiRows[0].amount).toFixed(2)
      : 0;

    const invoiceObject = {
      costing_id: this.state.multiRows[0].costing_no,
      to: vendor_or_customer,
      business_no: this.state.mother_vessel_name,
      costing_name: "MV Outgoing Credit Note",
      invoice_no: invoice_no,
      invoice_date: localDateFormate(invoice_date),
      bargeDetails: [],
      billing_currency:
        config.company === "SRPL" || config.company === "AVS" || config.company === "PRPL" ? "USD" : "IDR",
      total_payable_amount: Grand_total,
      purchase_type: "FOB Barge",
      description: [
        {
          qty: " ",
          adjustedPrice: " ",
          desc: `${this.state.currency_xchg_rate > 1
            ? `Outgoing Credit Note @${parseFloat(
              this.state.currency_xchg_rate
            ).toFixed(2)}`
            : "Outgoing Credit Note"
            }`,
          amount: this.state.multiRows[0].amount,
        },
      ],
    };
    return <InvoiceLink data={invoiceObject} fileName={costing_no} />;
  };
  render() {
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div
          className="container bg-white p-0"
          style={{ minHeight: "100vh", width: 850 }}
        >
          {this.state.multiRows.map((e, idx) => (
            <div className="clearfix content-header border-bottom">
              <div className="row">
                <div className="col-lg-10 text-left pl-0">
                  <h4 style={{ padding: "10px 20px" }}>
                    {`Outgoing Credit Notes - ${e.costing_no}`}
                    <span
                      className={
                        e.approval_status === "Pending" ? "type_2" : "type_1"
                      }
                      style={{ padding: 8, marginLeft: 16 }}
                    >
                      {e.approval_status}
                    </span>
                  </h4>
                </div>
                {e.approval_status === "Pending" &&
                  e.invoice_file &&
                  this.Cookie.getCookie("loginUserId") !== e.created_by &&
                  e.approved_by !== this.Cookie.getCookie("loginUserId") &&
                  (this.state.features.includes("485") ||
                    this.state.features.includes("491") ||
                    this.state.features.includes("97")) && (
                    <div
                      className="col text-right float-sm-left"
                      style={{ padding: "5px 20px" }}
                    >
                      <button
                        className="header_button header_button_text add_button_adjustment"
                        style={{ width: "auto", marginTop: "17px" }}
                        onClick={this.approveHandler}
                      >
                        Approve
                      </button>
                    </div>
                  )}
                <div className="col-lg-1 pl-0 float-right">
                  {this.renderInvoiceLink()}
                </div>
              </div>
            </div>
          ))}

          <div className="drawer-section" style={{ minHeight: "86vh" }}>
            <div className="card p-3">
              <div
                className="card-body p-0"
                style={{ backgroundColor: "rgba(25,118,210,.01)" }}
                id="accordion"
              >
                {this.state.multiRows.map((e, idx) => (
                  <>
                    <div
                      className="row border_dashed rounded-lg p-2 mb-3"
                      key={idx}
                    >
                      <div className="col shadow-sm p-0 mb-2 bg-white border">
                        <div className="table-responsive">
                          <table className="table table-sm table-bordered mb-0">
                            <thead className="table-header">
                              <tr>
                                <th className="text-center">Description</th>
                                <th className="text-right">Qty</th>
                                <th className="text-right">Unit Price </th>
                                <th className="text-right">Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              <>
                                <tr>
                                  <td>
                                    Outgoing Credit Note
                                    {this.state.currency_xchg_rate > 1 &&
                                      <div>
                                        Exchange Rate @ {parseFloat(this.state.currency_xchg_rate).toFixed(2)}
                                      </div>
                                    }
                                    <div>
                                      To : {e.vendor_or_customer}
                                    </div>
                                    {
                                      e.vendor_or_customer === "Vendor" &&
                                      <>
                                        {e.vendor_name}
                                      </>
                                    }
                                    {
                                      e.vendor_or_customer = "Customer" &&
                                      <>
                                        {e.customer_name && (
                                          <div>
                                            Payee Name : {e.customer_name}
                                          </div>
                                        )}
                                        <div>{e.other_customer_name !== null && e.other_customer_name}</div>
                                      </>
                                    }
                                    {e.date_of_creation &&
                                      <div>
                                        Date Of Creation : {localDateFormate(e.date_of_creation)}
                                      </div>
                                    }
                                  </td>
                                  <td className="text-right"></td>
                                  <td className="text-right"></td>
                                  <td className="text-right">{this.toLocaleString(e.amount)}</td>
                                </tr>
                                <tr>
                                  <td colSpan={3} className="text-right ">
                                    {" "}
                                    Payable to Vendor :{" "}
                                  </td>
                                  <td className="text-right ">
                                    {config.company === "SRPL" || config.company === "AVS" ||
                                      config.company === "PRPL"
                                      ? " USD"
                                      : " IDR"} {this.toLocaleString(e.amount)}
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan={3} className="text-right ">
                                    Paid to Vendor
                                  </td>
                                  <td>{config.company === "SRPL" || config.company === "AVS" ||
                                    config.company === "PRPL"
                                    ? " USD"
                                    : " IDR"} {e.total_paid_amout ? toLocaleString(e.total_paid_amout) : 0} </td>
                                </tr>
                                <tr>
                                  {e.remaining_amount ?
                                    <>
                                      <td colSpan={3} className="text-right ">
                                        Remaining Amount
                                      </td>
                                      <td style={{ fontWeight: 'bold' }} className="text-right ">{config.company === "SRPL" || config.company === "AVS" ||
                                        config.company === "PRPL"
                                        ? " USD"
                                        : " IDR"} {toLocaleString(e.remaining_amount)} </td>
                                    </>
                                    : ""}
                                </tr>
                              </>

                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="row col-lg-12 pl-0 pr-0">
                        <div className="col-lg-12 mb-1 pl-0">
                          <label className="contract_display_header_label">
                            Remarks
                          </label>
                          <label
                            className="contract_display_header_value"
                            style={{
                              textTransform: "uppercase",
                              whiteSpace: "pre-line",
                            }}
                          >
                            {e.remarks}
                          </label>
                        </div>
                      </div>

                      <div className="card col-lg-12 p-0 border mb-3">
                        <div className="card-header section_header">
                          <h5>Payment Postings</h5>
                        </div>
                        <div className="card-body p-2 pt-3">
                          <InvoiceForm
                            open={false}
                            data={this.state.invoiceDetails}
                            costingID={this.props.creditNoteID}
                            callbackFn={(data) => this.updateInvoice(data)}
                          />
                          {(this.state.features.includes("86") ||
                            this.state.features.includes("97")) && (
                              <div>
                                {/* <div className="row">
                                  <div className="col-lg-6 mb-1">
                                    <label className="contract_display_header_label">
                                      Total Paid
                                    </label>
                                    <label className="contract_display_header_value">
                                      {e.total_paid_amout
                                        ? this.toLocaleString(e.total_paid_amout)
                                        : 0}{" "}
                                      {config.company === "SRPL" || config.company === "AVS" ||
                                        config.company === "PRPL"
                                        ? " USD"
                                        : " IDR"}
                                    </label>
                                  </div>
                                  <div className="col-lg-6 mb-1">
                                    <label className="contract_display_header_label">
                                      Remaining Amount
                                    </label>
                                    <label className="contract_display_header_value">
                                      {e.remaining_amount
                                        ? this.toLocaleString(e.remaining_amount)
                                        : 0}{" "}
                                      {config.company === "SRPL" || config.company === "AVS" ||
                                        config.company === "PRPL"
                                        ? " USD"
                                        : " IDR"}
                                    </label>
                                  </div>
                                </div> */}
                                {this.renderPaymentPostings(e)}
                                {/* <div className="col-lg-12">
                                <div className="clearfix">
                                  <div className="float-left">
                                    {!e.Outgoing_payments
                                      ? ""
                                      : e.Outgoing_payments.length <= 0 && (
                                          <p style={{ fontWeight: "bold" }}>
                                            No payments made
                                          </p>
                                        )}
                                  </div>
                                  <div className="float-right">
                                    {(this.state.features.includes("298")  ||
                                      this.state.features.includes("97") >=
                                        0) && (
                                      <button
                                        className="header_button header_button_text w-auto"
                                        onClick={() => {
                                          this.setState({
                                            paymentTitle:
                                              "Add Outgoing Credit Note",
                                            payment_posting_type:
                                              "outgoing_credit_note",
                                            remainingAmount: e.remaining_amount,
                                            credit_note_id: e.id,
                                            date_of_payment: null,
                                            amount: "",
                                            invoice_no: "",
                                            description: "",
                                            paymentErrorMsg: "",
                                            date_of_paymentError: false,
                                            amountError: false,
                                            paymentMode: "Add",
                                            invoice_noError: false,
                                            paymentPostingsModal: true,
                                          });
                                        }}
                                      >
                                        Add Payment
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>

                            {!e.Outgoing_payments
                              ? ""
                              : e.Outgoing_payments.length > 0 && (
                                  <div className="row mt-2">
                                    <div className="col-lg p-0">
                                      <div className="table-responsive">
                                        <table className="table table-borderless border-0">
                                          <thead>
                                            <tr>
                                              <th
                                                className="table_header_barge_label"
                                                nowrap="true"
                                              >
                                                Date of Payment
                                              </th>
                                              <th
                                                className="table_header_barge_label text-right"
                                                nowrap="true"
                                              >
                                                Amount
                                              </th>
                                              <th
                                                className="table_header_barge_label"
                                                nowrap="true"
                                              >
                                                Invoice No
                                              </th>
                                              <th
                                                className="table_header_barge_label"
                                                style={{
                                                  width: 300,
                                                  maxWidth: 250,
                                                }}
                                              >
                                                Description
                                              </th>
                                              <th
                                                className="table_header_barge_label"
                                                nowrap="true"
                                              >
                                                Created Date
                                              </th>
                                              <th
                                                nowrap="true"
                                                className="table_header_barge_label text-left border-0"
                                              >
                                                1st Level Approval
                                              </th>
                                              <th
                                                nowrap="true"
                                                className="table_header_barge_label text-left border-0"
                                              >
                                                1st Level Approved On
                                              </th>
                                              <th
                                                nowrap="true"
                                                className="table_header_barge_label text-left border-0"
                                              >
                                                2nd Level Approval
                                              </th>
                                              <th
                                                nowrap="true"
                                                className="table_header_barge_label text-left border-0"
                                              >
                                                2nd Level Approved On
                                              </th>
                                              <th
                                                className="table_header_barge_label"
                                                nowrap="true"
                                              >
                                                Action
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {e.Outgoing_payments.map(
                                              (v, index) => (
                                                <tr key={index}>
                                                  {/* <td className='table_td border-0' nowrap='true' >{v.date_of_payment ? localDateFormate(v.date_of_payment) : null}</td> *
                                                  <td
                                                    nowrap="true"
                                                    className="table_td"
                                                    style={{ minWidth: 170 }}
                                                  >
                                                    {e.approval_status ===
                                                      "Approved" &&
                                                      v.approval_status ===
                                                        "Approved" &&
                                                      (v.approval_status_2 ===
                                                        "Optional" ||
                                                        v.approval_status_2 ===
                                                          "Approved") && (
                                                        <MuiPickersUtilsProvider
                                                          utils={DateFnsUtils}
                                                        >
                                                          <DatePicker
                                                            autoOk={true}
                                                            inputVariant="outlined"
                                                            variant="inline"
                                                            margin="dense"
                                                            fullWidth
                                                            format="dd/MM/yyyy"
                                                            value={
                                                              v.date_of_payment
                                                            }
                                                            error={
                                                              this.state
                                                                .date_of_paymentError
                                                            }
                                                            onChange={(
                                                              date
                                                            ) => {
                                                              this.setState(
                                                                {
                                                                  credit_note_id:
                                                                    e.id,
                                                                  credit_note_payment_id:
                                                                    v.id,
                                                                  payment_posting_type:
                                                                    v.payment_posting_type,
                                                                  date_of_payment:
                                                                    date,
                                                                  amount:
                                                                    v.amount,
                                                                  invoice_no:
                                                                    v.invoice_no,
                                                                  description:
                                                                    v.description,
                                                                  date_of_paymentError: false,
                                                                },
                                                                () => {
                                                                  this.UpdatePaymentPostingsHandler();
                                                                }
                                                              );
                                                            }}
                                                            InputProps={{
                                                              startAdornment: (
                                                                <InputAdornment position="start">
                                                                  <IconButton>
                                                                    <EventIcon />
                                                                  </IconButton>
                                                                </InputAdornment>
                                                              ),
                                                              className: "pl-0",
                                                            }}
                                                          />
                                                        </MuiPickersUtilsProvider>
                                                      )}
                                                  </td>
                                                  <td
                                                    className="table_td border-0 text-right"
                                                    nowrap="true"
                                                  >
                                                    {v.amount
                                                      ? this.toLocaleString(
                                                          v.amount
                                                        )
                                                      : ""}
                                                  </td>
                                                  <td
                                                    className="table_td border-0"
                                                    nowrap="true"
                                                  >
                                                    {v.invoice_no}
                                                  </td>
                                                  <td
                                                    className="table_td border-0"
                                                    style={{
                                                      whiteSpace: "pre-line",
                                                    }}
                                                  >
                                                    {v.description}
                                                  </td>
                                                  <td
                                                    className="table_td border-0"
                                                    nowrap="true"
                                                  >
                                                    {v.created_date
                                                      ? localDateFormate(
                                                          v.created_date
                                                        )
                                                      : null}
                                                  </td>
                                                  {v.approval_status ===
                                                    "Approved" &&
                                                  v.approval_status ===
                                                    "Pending" &&
                                                  this.Cookie.getCookie(
                                                    "loginUserId"
                                                  ) !== v.created_by &&
                                                  (this.state.features.includes(
                                                    "486"
                                                  )  ||
                                                    this.state.features.includes(
                                                      "97"
                                                    ) ) ? (
                                                    <td
                                                      nowrap="true"
                                                      className=" text-left"
                                                    >
                                                      <button
                                                        className="header_button header_button_text add_button_adjustment"
                                                        style={{
                                                          width: "auto",
                                                          marginTop: "17px",
                                                        }}
                                                        onClick={() =>
                                                          this.approvePaymentsHandler(
                                                            v.id
                                                          )
                                                        }
                                                      >
                                                        Approve
                                                      </button>
                                                    </td>
                                                  ) : (
                                                    <td
                                                      nowrap="true"
                                                      className=" text-left"
                                                    >
                                                      {v.approved_by_name
                                                        ? v.approved_by_name
                                                        : "-"}
                                                    </td>
                                                  )}
                                                  <td
                                                    nowrap="true"
                                                    className=" text-left"
                                                  >
                                                    {v.approved_date
                                                      ? localDateFormate(
                                                          v.approved_date
                                                        )
                                                      : "-"}
                                                  </td>
                                                  {v.approval_status ===
                                                    "Approved" &&
                                                  v.approval_status_2 ===
                                                    "Pending" &&
                                                  this.Cookie.getCookie(
                                                    "loginUserId"
                                                  ) !== v.created_by &&
                                                  this.Cookie.getCookie(
                                                    "loginUserId"
                                                  ) !== v.approved_by &&
                                                  (this.state.features.includes(
                                                    "492"
                                                  )  ||
                                                    this.state.features.includes(
                                                      "97"
                                                    ) ) ? (
                                                    <td
                                                      nowrap="true"
                                                      className=" text-left"
                                                    >
                                                      <button
                                                        className="header_button header_button_text add_button_adjustment"
                                                        style={{
                                                          width: "auto",
                                                          marginTop: "17px",
                                                        }}
                                                        onClick={() =>
                                                          this.approvePaymentsHandler(
                                                            v.id
                                                          )
                                                        }
                                                      >
                                                        Approve
                                                      </button>
                                                    </td>
                                                  ) : (
                                                    <td
                                                      nowrap="true"
                                                      className=" text-left"
                                                    >
                                                      {v.approved_by_2_name
                                                        ? v.approved_by_2_name
                                                        : "-"}
                                                    </td>
                                                  )}
                                                  <td
                                                    nowrap="true"
                                                    className=" text-left"
                                                  >
                                                    {v.approved_date_2
                                                      ? localDateFormate(
                                                          v.approved_date_2
                                                        )
                                                      : "-"}
                                                  </td>
                                                  <td
                                                    nowrap="true"
                                                    className="table_td border-0 text-center"
                                                  >
                                                    {(v.approval_status ===
                                                    "pending"
                                                      ? this.state.features.includes(
                                                          "296"
                                                        )  ||
                                                        this.state.features.includes(
                                                          "486"
                                                        )  ||
                                                        this.state.features.includes(
                                                          "97"
                                                        ) 
                                                      : v.approval_status_2 ===
                                                        "Pending"
                                                      ? this.Cookie.getCookie(
                                                          "loginUserId"
                                                        ) !== v.approved_by ||
                                                        this.state.features.includes(
                                                          "492"
                                                        )  ||
                                                        this.state.features.includes(
                                                          "97"
                                                        ) 
                                                      : this.state.features.includes(
                                                          "97"
                                                        ) ) && (
                                                      <Tooltip
                                                        title="Edit"
                                                        arrow
                                                      >
                                                        <i
                                                          className="fa fa-pencil"
                                                          style={{
                                                            fontSize: 14,
                                                            color:
                                                              config.themeColor,
                                                            cursor: "pointer",
                                                          }}
                                                          data-toggle="modal"
                                                          title="Edit Coal Payment Posting"
                                                          data-placement="bottom"
                                                          onClick={() => {
                                                            this.setState({
                                                              payment_id: v.id,
                                                              remainingAmount:
                                                                e.remaining_amount,
                                                            });
                                                            this.getPaymentDetalis(
                                                              v
                                                            );
                                                          }}
                                                        ></i>
                                                      </Tooltip>
                                                    )}
                                                    {(this.state.features.includes(
                                                      "498"
                                                    )  ||
                                                      this.state.features.includes(
                                                        "97"
                                                      ) ) &&
                                                      v.approval_status ===
                                                        "Pending" && (
                                                        <DeletePayments
                                                          data={v}
                                                          table={
                                                            "business_no_credit_note_payment_postings"
                                                          }
                                                          callbackFn={() =>
                                                            this.componentDidMount()
                                                          }
                                                        />
                                                      )}
                                                  </td>
                                                </tr>
                                              )
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                )} */}
                              </div>
                            )}
                        </div>
                      </div>

                      <ViewFiles
                        {...this.props}
                        files_data={e.files}
                        type="Costings"
                        invoice_file={e.invoice_file}
                      />
                    </div>
                    <div className="col shadow-sm p-0 mb-2 bg-white border">
                      <div className="table-responsive">
                        <table className="table table-sm table-bordered">
                          <thead className="table-header">
                            <tr>
                              <th className="text-center">Approval Type</th>
                              <th className="text-center">Approved by</th>
                              <th className="text-center">Approved On</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="text-center">Business</td>
                              <td className="text-center">
                                <div> {e.approved_by_name}</div>
                              </td>
                              <td className="text-center">
                                {localDateFormate(e.approved_date)}
                              </td>
                            </tr>
                            <tr>
                              <td className="text-center">Financial</td>
                              <td className="text-center">
                                {e.approved_by_name_2}
                              </td>
                              <td className="text-center">
                                {localDateFormate(e.approved_date_2)}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
          <footer className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button btn-light"
              name="submit"
              onClick={() => {
                this.props.returnCallbackDrawerClose(false);
              }}
            >
              Close
            </button>
            <button
              type="button"
              className="drawer_text drawer_btn_success"
              name="Back"
              onClick={() => {
                this.props.returnEditCallback("CreditNote");
              }}
            >
              Edit
            </button>
          </footer>

          {/*
           *
           * Barge Payements Posting Modals
           *
           */}
          <Dialog
            open={this.state.paymentPostingsModal}
            aria-labelledby="responsive-dialog-title"
            className="payment-modal"
          >
            <DialogTitle id="responsive-dialog-title">
              {this.state.paymentTitle} Payment Posting
            </DialogTitle>
            <div className="row col-lg-12 card border">
              <div
                style={{
                  fontSize: 14,
                  color: "red",
                  textTransform: "capitalize",
                  textAlign: "center",
                }}
              >
                {this.state.paymentErrorMsg}
              </div>

              <div className="row">
                {/* <div className='col-lg-6 mb-2'>
                  <label className='contract_display_header_label'>Date of Payment <span style={{ color: 'red' }}>*</span></label>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      autoOk={true}
                      inputVariant="outlined"
                      variant="inline"
                      margin="dense"
                      fullWidth
                      format="dd/MM/yyyy"
                      value={this.state.date_of_payment}
                      error={this.state.date_of_paymentError}
                      onChange={(date) => {
                        this.setState({
                          date_of_payment: date,
                          date_of_paymentError: false
                        })
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton>
                              <EventIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div> */}

                <div className="col-lg-6 mb-2">
                  <label className="contract_display_header_label">
                    Amount <span style={{ color: "red" }}>*</span>
                  </label>
                  <TextField
                    name="amount"
                    margin="dense"
                    variant="outlined"
                    placeholder="Amount"
                    required
                    value={this.state.amount}
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    fullWidth
                    error={this.state.amountError}
                    onChange={(e) => {
                      const pr = /^\d*(\.\d{0,4})?$/;
                      if (e.target.value === "" || pr.test(e.target.value)) {
                        this.setState({
                          amount: e.target.value,
                          amountError: false,
                          paymentErrorMsg: "",
                        });
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {config.company === "SRPL" || config.company === "AVS" ||
                            config.company === "PRPL"
                            ? "USD"
                            : "IDR"}
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>

                <div className="col-lg-6 mb-2">
                  <label className="contract_display_header_label">
                    Invoice No <span style={{ color: "red" }}>*</span>
                  </label>

                  <TextField
                    name="invoice_no"
                    margin="dense"
                    variant="outlined"
                    placeholder="Invoice No"
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    error={this.state.invoice_noError}
                    value={this.state.invoice_no}
                    onChange={(e) =>
                      this.setState({
                        invoice_no: e.target.value,
                        invoice_noError: false,
                      })
                    }
                    fullWidth
                  />
                </div>

                <div className="col-lg-6 mb-2">
                  <label className="contract_display_header_label">
                    Description
                  </label>

                  <TextField
                    name="description"
                    margin="dense"
                    variant="outlined"
                    placeholder="Description"
                    multiline
                    rows={3}
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    value={this.state.description}
                    onChange={(e) =>
                      this.setState({ description: e.target.value })
                    }
                    fullWidth
                  />
                </div>
              </div>
            </div>
            <div className="row bottom_buttons_section">
              <button
                type="button"
                className="btn previous_button"
                onClick={() => this.setState({ paymentPostingsModal: false })}
              >
                Cancel
              </button>
              {/* <button type="button" className="btn next_button" onClick={() => window.location.href = '/view-purchase-contract/' + btoa(this.state.clonedPC_contract_id)}>OK</button> */}
              {this.state.paymentMode === "Add" && (
                <button
                  type="button"
                  className="btn next_button"
                  onClick={this.AddPaymentPostingHandler}
                  disabled={this.state.submitting}
                >
                  <span
                    className={
                      this.state.submitting
                        ? "spinner-grow spinner-grow-sm mr-2"
                        : ""
                    }
                  ></span>
                  Submit
                </button>
              )}

              {this.state.paymentMode === "Update" && (
                <button
                  type="button"
                  className="btn next_button"
                  onClick={this.UpdatePaymentPostingsHandler}
                  disabled={this.state.submitting}
                >
                  <span
                    className={
                      this.state.submitting
                        ? "spinner-grow spinner-grow-sm mr-2"
                        : ""
                    }
                  ></span>
                  Update
                </button>
              )}
            </div>
          </Dialog>
        </div>
      );
    }
  }
}
