import React from 'react';

import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import MaterialTable from 'material-table';

import CookieHandler from '../common/CookieHandler';
import api from '../../api/api';
import '../../App.css';
import config from '../../config/config';
import Header from '../common/Header';
import SideBar from '../common/SideBar';

const styles = theme => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
});

class UserList extends React.Component {
  constructor() {
    super();
    this.Cookie = new CookieHandler();
    this.state = {
      rawData: [],
      data: [],
      pages: null,
      loading: true,
      //roles: [],
      open: false,
      cloud: '',
      server: '',
      isLoading: false,
      errorMessage: '',
      successMessage: '',
    }
  }

  componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace('/logout');
    }
    else {
      var loginUserID = this.Cookie.getCookie('loginUserId');
      var idToken = this.Cookie.getIdTokenCookie();
      api.getUserList(loginUserID, idToken).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.users) {
              //this.Cookie.setIdTokenCookie(res.idtoken);TokenCookie(res.idtoken);
              this.setState({
                rawData: res.users,
                loading: false,
              });
            }
          })
        }
        else if (response.status === 601) {
          response.json().then((res) => {
            this.setState({
              errorMessage: res.message,
              successMessage: ''
            })
          })
        }
        else if (response.status === 607) {
          response.json().then((res) => {
            this.setState({
              errorMessage: res.message,
              successMessage: ''
            })
          })
          setTimeout(() => {
            window.location.href = '/logout'
          }, 2000)
        }
      })
    }
    document.title = config.documentTitle + "User List";
  }

  render() {
    return (
      <div id='wraper'>
        <SideBar />
        <div className='content'>
          <Header />
          <div className='content-header'>
            <div className='row'>
              <div className='col-sm text-left pl-0'>
                <h4 style={{ padding: '10px 20px', margin: '0px' }}>User List</h4>
              </div>
              <div className='col text-right float-sm-left'>
                <a href="/add-user"><button className='header_button header_button_text add_button_adjustment' style={{ top: 10, width: 'auto' }}>Add User</button></a>
              </div>
            </div>
          </div>
          {!this.state.isLoading &&
            <div className='container p-3'>
              <div className='row'>
                <MaterialTable
                  columns={[
                    {
                      title: "id",
                      field: "id",
                      hidden: true
                    },
                    {
                      title: "User Name",
                      id: 'username',
                      render: row => { return (<a style={{ textDecoration: 'none', color: '#0066c0' }} href={'/view-user/' + btoa(row.id)}>{row.user_name}</a>) },
                    },
                    {
                      title: "Email",
                      field: "email",
                    },
                    {
                      title: "Status",
                      field: "status",
                    },
                    {
                      title: "Created By",
                      field: "created_by",
                    },
                    {
                      title: "Created Date",
                      field: "created_date",
                    },

                    {
                      title: "Action",
                      sortable: false,
                      headerStyle: {
                        textAlign: 'center'
                      },
                      render: row => {
                        return (
                          <div style={{ textAlign: 'center', marginTop: '-10px', marginBottom: '-10px' }}>
                            <Button href={'/update-user/' + btoa(row.id)} id={row.id} variant="contained" style={{ textTransform: "capitalize", minHeight: "2px", height: "30px", marginTop: "8px", marginBottom: "8px", color: "#0066c0", padding: 4 }}><EditIcon />Edit</Button>
                          </div>
                        )
                      },
                    },
                  ]}
                  title=""
                  data={this.state.rawData}
                  isLoading={this.state.loading}
                  style={{ minHeight: 200, fontFamily: 'Poppins', overflowY: 'auto', boxShadow: '0 0 5px 0 rgba(0, 39, 77, 0.1)' }}
                  options={{
                    paginationType: "stepped",
                    pageSize: 100,
                    pageSizeOptions: [5, 10, 20, 50, 100, 200],
                    headerStyle: {
                      backgroundColor: '#f9f9f9',
                      minWidth: 215,
                      fontFamily: 'Poppins'
                    },
                    cellStyle: {
                      fontFamily: 'Poppins'
                    },
                    exportAllData: true,
                    exportButton: true,
                    draggable: false,
                    // tableLayout: 'fixed'
                  }}
                />
              </div>

            </div>
          }
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(UserList);