import React, { Component } from "react";
// import SideBar from '../Common/SideBar';
// import Header from '../Common/Header';
import {
  MenuItem,
  TextField,
  IconButton,
  InputAdornment,
  Snackbar,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DateFnsUtils from "@date-io/date-fns";
import EventIcon from "@material-ui/icons/Event";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { FormControl, ListItemText, Select, Checkbox } from "@material-ui/core";
import CookieHandler from "../common/CookieHandler";
import api from "../../api/api";
import "../../App.css";
import Config from "../../config/config";
import { Alert } from "@material-ui/lab";
import Loader from "../common/Loader";

export default class BusinessNoOpMVUpdate extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      loadingCommencement: null,
      loadingCompletion: null,
      documentsOnBoard: null,
      vesselSailing: null,
      dischargePort: "",
      loadingType: "0",
      loadingRatePerDay: "",
      deadFreightIncurred: "",
      deadFreightAccount: "0",
      supplierSurveyorName: "0",
      supplierSurveyorAnalysisType: "0",
      SDAMSAIISurveyorName: "0",
      SDAMSAIISurveyorAnalysisType: "0",
      buyerAdditionalSurveyorName: "0",
      surveyorBasePrice: "",
      surveyorVAT: "",
      surveyorTotalPrice: "",
      surveyorCurrencyType: "0",
      surveyorAdditionalDays: "",
      stevedore_vendor: "0",
      stevedoreBasePrice: "",
      stevedoreVAT: "",
      stevedoreTotalPrice: "",
      stevedoreCurrencyType: "0",
      stevedoreAdditionalDays: "",
      floatingCraneName: "",
      floating_crane_additional_days: "",
      floatingCraneDemurrageDespatch: "",
      floatingCraneDemurrageDespatchCurrencyType: "0",
      documentationAgent: "0",
      surveyorsData: [],
      stevedoreData: [],
      othersData: [],
      currencyData: [],
      supplierSurveyorNameValue: "",
      SDAMSAIISurveyorNameValue: "",
      buyerAdditionalSurveyorNameValue: "",
      stevedoreOnMVValue: "",
      documentationAgentValue: "",
      businessNumber: "",
      businessName: "",
      supplierDocumentationAgent: "",
      SDAM_DocumentationAgent: "",
      buyerDocumentationAgent: "",
      remarks: "",
      sumOfBargeQuantities: "",
      vessel_final_quantity_in_mt: "",
      cargo_gain_loss_in_mt: "",
      errorMsg: "",
      successMsg: "",

      // Multi checkboxes

      supplierServeyorNames: [],
      internalServeyorNames: [],
      jointServeyorNames: [],
      vessel_arrived_on: null,
      nor_acceptance: null,
      vessel_type: "0",
      load_port_agent: "",
      eta_nor: null,
      stevedore_vendor_array: [],
      isLoading: true,
      features: [],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    } else {
      var features = this.Cookie.getCookie("features");
      this.setState({ features: features });
      if (!(features.includes("44") || features.includes("97"))) {
        window.location.href = "/dashboard";
      }
      const loginUserID = this.Cookie.getCookie("loginUserId");
      const idToken = this.Cookie.getIdTokenCookie();
      const status = "Active";
      // currencies api
      const currenciesData = await api.getCurrencies(loginUserID, idToken);

      if (currenciesData.status >= 200 && currenciesData.status < 300) {
        currenciesData.json().then((res) => {
          if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
          if (res.code === "200") {
            if (res.Currency) {
              var currencies = [
                <MenuItem value="0" key={-1}>
                  {" "}
                  Please Select
                </MenuItem>,
              ];
              for (var i in res.Currency) {
                currencies.push(
                  <MenuItem value={res.Currency[i].currency} key={[i]}>
                    {res.Currency[i].currency}
                  </MenuItem>
                );
              }
              this.setState({
                currencyData: currencies,
              });
              if (res.code === "607") {
                window.location.href = "/logout";
              }
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          } else if (res.code === "601") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }

      // get vendors api

      const vendorsData = await api.getVendors(loginUserID, idToken, status);

      if (vendorsData.status >= 200 && vendorsData.status < 300) {
        vendorsData.json().then((res) => {
          if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
          if (res.code === "200") {
            if (res.vendors) {
              const surveyorsData = [];
              const stevedoreData = [];
              const othersData = [];
              for (let i = 0; i < res.vendors.length; i++) {
                if (res.vendors[i].vendor_type === "Surveyor") {
                  surveyorsData.push({
                    name: res.vendors[i].vendor_name,
                    value: res.vendors[i].id,
                    key: i,
                  });
                }
                if (res.vendors[i].vendor_type === "Stevedore") {
                  stevedoreData.push({
                    name: res.vendors[i].vendor_name,
                    value: res.vendors[i].id,
                    key: i,
                  });
                }
                if (
                  res.vendors[i].vendor_type === "Others" ||
                  res.vendors[i].vendor_type === "Stevedore" ||
                  res.vendors[i].vendor_type === "Floating Crane" ||
                  res.vendors[i].vendor_type === "Vessel Agent"
                ) {
                  othersData.push({
                    name: res.vendors[i].vendor_name,
                    value: res.vendors[i].id,
                    key: i,
                  });
                }
              }
              this.setState({
                surveyorsData: surveyorsData,
                stevedoreData: stevedoreData,
                othersData: othersData,
              });
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          }
          if (res.code === "601") {
            this.setState({
              errorMsg: res.message,
              snackBarErrorOpen: true,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
      try {
        // var motherVesselID = decodeURIComponent(window.atob(this.props.match.params.motherVesselID));
        var motherVesselID = this.props.motherVesselID;
      } catch (e) {
        window.location.href = "/pagenotfound";
      }
      const MotherVessel = await api.getBusinessNoOpMVDetails(
        motherVesselID,
        loginUserID,
        idToken
      );

      if (MotherVessel.status >= 200 && MotherVessel.status < 300) {
        MotherVessel.json().then((res) => {
          if (res.code === "200") {
            if (res.business_no_MV) {
              if (res.business_no_MV.supplier_surveyor_names) {
                let supplier_surveyor =
                  res.business_no_MV.supplier_surveyor_names;
                let result = supplier_surveyor.split(",");
                this.setState({
                  supplierServeyorNames: result,
                });
              }

              if (res.business_no_MV.internal_surveyor_names) {
                let internal_surveyor =
                  res.business_no_MV.internal_surveyor_names;
                let result = internal_surveyor.split(",");
                this.setState({
                  internalServeyorNames: result,
                });
              }

              if (res.business_no_MV.joint_surveyor_names) {
                let supplier_surveyor = res.business_no_MV.joint_surveyor_names;
                let result = supplier_surveyor.split(",");
                this.setState({
                  jointServeyorNames: result,
                });
              }

              var businessNo = res.business_no_MV.business_no_id;
              //
              api
                .getBusinessNumber(businessNo, loginUserID, idToken)
                .then((response) => {
                  if (response.status >= 200 && response.status < 300) {
                    response.json().then((res) => {
                      if (res.business_number) {
                        this.setState({
                          businessNumber: res.business_number.business_no,
                          businessNumberID: businessNo,
                          businessName: res.business_number.mother_vessel_name,
                        });
                      } else {
                        alert(
                          "Unexpected error occured. Please contact administrator."
                        );
                      }
                    });
                  }
                });
              var businessNumberID = businessNo;
              this.setState(
                {
                  businessNo: res.business_no_MV.business_no_id,
                  loadingCommencement: res.business_no_MV.loading_commencement
                    ? res.business_no_MV.loading_commencement
                    : null,
                  vessel_final_quantity_in_mt:
                    res.business_no_MV.vessel_final_quantity_in_mt,
                  //cargo_gain_loss_in_mt: (Number(res.business_no_MV.vessel_final_quantity_in_mt)-Number(sumOfBargeQuantities)),
                  businessNumberID: res.business_no_MV.business_no_id
                    ? res.business_no_MV.business_no_id
                    : "",
                  loadingCompletion: res.business_no_MV.loading_completion
                    ? res.business_no_MV.loading_completion
                    : null,
                  documentsOnBoard: res.business_no_MV.documents_on_board
                    ? res.business_no_MV.documents_on_board
                    : null,
                  vesselSailing: res.business_no_MV.vessel_sailing
                    ? res.business_no_MV.vessel_sailing
                    : null,
                  supplierSurveyorNameValue:
                    res.business_no_MV.supplier_surveyor === null
                      ? ""
                      : {
                          name: res.business_no_MV.supplier_surveyor_names,
                          value: res.business_no_MV.supplier_surveyor,
                        },
                  SDAMSAIISurveyorNameValue:
                    res.business_no_MV.internal_surveyor === null
                      ? ""
                      : {
                          name: res.business_no_MV.internal_surveyor_names,
                          value: res.business_no_MV.internal_surveyor,
                        },
                  buyerAdditionalSurveyorNameValue:
                    res.business_no_MV.joint_surveyor === null
                      ? ""
                      : {
                          name: res.business_no_MV.joint_surveyor_names,
                          value: res.business_no_MV.joint_surveyor,
                        },
                  stevedoreOnMVValue:
                    res.business_no_MV.stevedore_on_mv === null
                      ? ""
                      : {
                          name: res.business_no_MV.stevedore_name,
                          value: res.business_no_MV.stevedore_on_mv,
                        },
                  //documentationAgentValue : res.business_no_MV.supplier_documentation_agent === null ? '' : {name: res.business_no_MV.supplier_documentation_agent_name, value: res.business_no_MV.supplier_documentation_agent},
                  supplierDocumentationAgent:
                    res.business_no_MV.supplier_documentation_agent === null
                      ? ""
                      : res.business_no_MV.supplier_documentation_agent,
                  //SDAM_DocumentationAgentValue : res.business_no_MV.internal_documentation_agent === null ? '' : {name: res.business_no_MV.internal_documentation_agent_name, value: res.business_no_MV.internal_documentation_agent},
                  SDAM_DocumentationAgent:
                    res.business_no_MV.internal_documentation_agent === null
                      ? ""
                      : res.business_no_MV.internal_documentation_agent,
                  //buyer_DocumentationAgentValue : res.business_no_MV.joint_documentation_agent === null ? '' : {name: res.business_no_MV.joint_documentation_agent_name, value: res.business_no_MV.joint_documentation_agent},
                  buyerDocumentationAgent:
                    res.business_no_MV.joint_documentation_agent === null
                      ? ""
                      : res.business_no_MV.joint_documentation_agent,
                  remarks:
                    res.business_no_MV.remarks === null
                      ? ""
                      : res.business_no_MV.remarks,
                  dischargePort:
                    res.business_no_MV.discharge_port === null
                      ? ""
                      : res.business_no_MV.discharge_port,
                  loadingType:
                    res.business_no_MV.loading_type === null
                      ? "0"
                      : res.business_no_MV.loading_type,
                  loadingRatePerDay:
                    res.business_no_MV.load_rate_per_day === null
                      ? ""
                      : res.business_no_MV.load_rate_per_day,
                  deadFreightIncurred:
                    res.business_no_MV.dead_freight_incurred === null
                      ? ""
                      : res.business_no_MV.dead_freight_incurred,
                  deadFreightAccount:
                    res.business_no_MV.dead_freight_account === null
                      ? "0"
                      : res.business_no_MV.dead_freight_account,
                  supplierSurveyorName:
                    res.business_no_MV.supplier_surveyor === null
                      ? "0"
                      : res.business_no_MV.supplier_surveyor,
                  supplierSurveyorAnalysisType:
                    res.business_no_MV.supplier_surveyor_analysis_type === null
                      ? "0"
                      : res.business_no_MV.supplier_surveyor_analysis_type,
                  SDAMSAIISurveyorName:
                    res.business_no_MV.internal_surveyor === null
                      ? "0"
                      : res.business_no_MV.internal_surveyor,
                  SDAMSAIISurveyorAnalysisType:
                    res.business_no_MV.internal_surveyor_analysis_type === null
                      ? "0"
                      : res.business_no_MV.internal_surveyor_analysis_type,
                  buyerAdditionalSurveyorName:
                    res.business_no_MV.joint_surveyor === null
                      ? "0"
                      : res.business_no_MV.joint_surveyor,
                  surveyorBasePrice:
                    res.business_no_MV.surveyor_base_price === null
                      ? ""
                      : res.business_no_MV.surveyor_base_price,
                  surveyorVAT:
                    res.business_no_MV.surveyor_vat_price === null
                      ? ""
                      : res.business_no_MV.surveyor_vat_price,
                  surveyorTotalPrice:
                    res.business_no_MV.surveyor_total_price === null
                      ? ""
                      : res.business_no_MV.surveyor_total_price,
                  surveyorCurrencyType:
                    res.business_no_MV.surveyor_currency === null
                      ? "0"
                      : res.business_no_MV.surveyor_currency,
                  surveyorAdditionalDays:
                    res.business_no_MV.surveyor_additional_days === null
                      ? ""
                      : res.business_no_MV.surveyor_additional_days,
                  stevedore_on_mv:
                    res.business_no_MV.stevedore_on_mv === null
                      ? "0"
                      : res.business_no_MV.stevedore_on_mv,
                  stevedore_vendor: res.business_no_MV.stevedore_vendor
                    ? res.business_no_MV.stevedore_vendor
                    : "",
                  floating_crane_vendor: res.business_no_MV
                    .floating_crane_vendor
                    ? res.business_no_MV.floating_crane_vendor
                    : "",
                  stevedore_vendor_array: res.business_no_MV.stevedore_vendor
                    ? res.business_no_MV.stevedore_vendor.split(",")
                    : [],
                  floating_crane_vendor_array: res.business_no_MV
                    .floating_crane_vendor
                    ? res.business_no_MV.floating_crane_vendor.split(",")
                    : [],
                  stevedoreAdditionalDays:
                    res.business_no_MV.stevedore_additional_days === null
                      ? ""
                      : res.business_no_MV.stevedore_additional_days,
                  floating_crane_additional_days:
                    res.business_no_MV.floating_crane_additional_days === null
                      ? ""
                      : res.business_no_MV.floating_crane_additional_days,
                  floatingCraneName:
                    res.business_no_MV.floating_crane_name === null
                      ? ""
                      : res.business_no_MV.floating_crane_name,
                  floatingCraneBasePrice:
                    res.business_no_MV.floating_crane_base_price === null
                      ? ""
                      : res.business_no_MV.floating_crane_base_price,
                  floatingCraneVAT:
                    res.business_no_MV.floating_crane_vat_price === null
                      ? ""
                      : res.business_no_MV.floating_crane_vat_price,
                  floatingCraneTotalPrice:
                    res.business_no_MV.floating_crane_total_price === null
                      ? ""
                      : res.business_no_MV.floating_crane_total_price,
                  floatingCraneCurrencyType:
                    res.business_no_MV.floating_crane_currency === null
                      ? "0"
                      : res.business_no_MV.floating_crane_currency,
                  floatingCraneDemurrageDespatch:
                    res.business_no_MV.floating_crane_despatch === null
                      ? ""
                      : res.business_no_MV.floating_crane_despatch,
                  floatingCraneDemurrageDespatchCurrencyType:
                    res.business_no_MV.floating_crane_despatch_currency === null
                      ? "0"
                      : res.business_no_MV.floating_crane_despatch_currency,
                  documentationAgent:
                    res.business_no_MV.documentation_agent === null
                      ? "0"
                      : res.business_no_MV.documentation_agent,

                  vessel_arrived_on: res.business_no_MV.vessel_arrived_on
                    ? res.business_no_MV.vessel_arrived_on
                    : null,
                  nor_acceptance: res.business_no_MV.nor_acceptance
                    ? res.business_no_MV.nor_acceptance
                    : null,
                  vessel_type: res.business_no_MV.vessel_type
                    ? res.business_no_MV.vessel_type
                    : "0",
                  load_port_agent: res.business_no_MV.load_port_agent
                    ? res.business_no_MV.load_port_agent
                    : "",
                  eta_nor: res.business_no_MV.eta_nor
                    ? res.business_no_MV.eta_nor
                    : null,
                },
                () => {
                  api
                    .getTotalBargeQuantities(
                      loginUserID,
                      idToken,
                      businessNumberID
                    )
                    .then((response) => {
                      if (response.status >= 200 && response.status < 300) {
                        response.json().then((res) => {
                          if (res.total_barges_in_mt) {
                            this.setState({
                              sumOfBargeQuantities: res.total_barges_in_mt,
                              cargo_gain_loss_in_mt:
                                Number(
                                  this.state.vessel_final_quantity_in_mt
                                    ? this.state.vessel_final_quantity_in_mt
                                    : 1
                                ) -
                                Number(
                                  res.total_barges_in_mt
                                    ? res.total_barges_in_mt
                                    : 1
                                ),
                              isLoading: false,
                            });
                          } else {
                            this.setState({
                              sumOfBargeQuantities: "",
                              isLoading: false,
                            });
                          }
                        });
                      }
                    });
                }
              );

              /**
               * Calling Get Total Barge quanatities API ! End
               *
               */
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          } else if (res.code === "601") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    }

    document.title = Config.documentTitle + "Mother Vessel for New Business";
  }

  submitHandler = () => {
    this.setState({ isLoading: true });
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    try {
      // var motherVesselID = decodeURIComponent(window.atob(this.props.match.params.motherVesselID));
      var motherVesselID = this.props.motherVesselID;
    } catch (e) {
      window.location.href = "/pagenotfound";
    }

    var {
      loadingCommencement,
      loadingCompletion,
      documentsOnBoard,
      vesselSailing,
      vessel_final_quantity_in_mt,
      cargo_gain_loss_in_mt,
      dischargePort,
      loadingType,
      loadingRatePerDay,
      deadFreightAccount,
      deadFreightIncurred,
      supplierSurveyorName,
      supplierSurveyorAnalysisType,
      SDAMSAIISurveyorName,
      SDAMSAIISurveyorAnalysisType,
      buyerAdditionalSurveyorName,
      surveyorBasePrice,
      surveyorVAT,
      surveyorTotalPrice,
      surveyorCurrencyType,
      surveyorAdditionalDays,
      stevedore_vendor,
      stevedoreAdditionalDays,
      floating_crane_vendor,
      floatingCraneName,
      floating_crane_additional_days,
      floatingCraneDemurrageDespatch,
      floatingCraneDemurrageDespatchCurrencyType,
      documentationAgent,
      businessNumberID,
      supplierDocumentationAgent,
      SDAM_DocumentationAgent,
      buyerDocumentationAgent,
      vessel_arrived_on,
      nor_acceptance,
      vessel_type,
      load_port_agent,
      eta_nor,
      remarks,
    } = this.state;
    if (loadingCommencement === null) {
      loadingCommencement = "";
    }
    if (loadingCompletion === null) {
      loadingCompletion = "";
    }
    if (documentsOnBoard === null) {
      documentsOnBoard = "";
    }
    if (vesselSailing === null) {
      vesselSailing = "";
    }
    if (loadingType === "0") {
      loadingType = "";
    }
    if (deadFreightAccount === "0") {
      deadFreightAccount = "";
    }
    if (deadFreightAccount === "0") {
      deadFreightAccount = "";
    }
    if (supplierSurveyorName === "0") {
      supplierSurveyorName = "";
    }
    if (supplierSurveyorAnalysisType === "0") {
      supplierSurveyorAnalysisType = "";
    }
    if (SDAMSAIISurveyorName === "0") {
      SDAMSAIISurveyorName = "";
    }
    if (SDAMSAIISurveyorAnalysisType === "0") {
      SDAMSAIISurveyorAnalysisType = "";
    }
    if (buyerAdditionalSurveyorName === "0") {
      buyerAdditionalSurveyorName = "";
    }
    if (surveyorCurrencyType === "0") {
      surveyorCurrencyType = "";
    }
    if (stevedore_vendor === "0") {
      stevedore_vendor = "";
    }

    if (floatingCraneDemurrageDespatchCurrencyType === "0") {
      floatingCraneDemurrageDespatchCurrencyType = "";
    }
    if (loadingType === "Vessel grabs") {
      floatingCraneName = "";
      floatingCraneDemurrageDespatch = "";
      floatingCraneDemurrageDespatchCurrencyType = "";
    }
    if (loadingType === "Floating crane") {
      stevedore_vendor = "";
      stevedoreAdditionalDays = "";
    }
    if (documentationAgent === "0") {
      documentationAgent = "";
    }

    var supplierSurveyorNames = "";
    for (let i = 0; i < this.state.supplierServeyorNames.length; i++) {
      for (let j = 0; j < this.state.surveyorsData.length; j++) {
        if (
          this.state.supplierServeyorNames[i] ===
          this.state.surveyorsData[j].name
        ) {
          supplierSurveyorNames += this.state.surveyorsData[j].value + ",";
        }
      }
    }
    var supplier_surveyor = supplierSurveyorNames.slice(0, -1);

    var internalSurveyorNames = "";
    for (let i = 0; i < this.state.internalServeyorNames.length; i++) {
      for (let j = 0; j < this.state.surveyorsData.length; j++) {
        if (
          this.state.internalServeyorNames[i] ===
          this.state.surveyorsData[j].name
        ) {
          internalSurveyorNames += this.state.surveyorsData[j].value + ",";
        }
      }
    }
    var internal_surveyor = internalSurveyorNames.slice(0, -1);

    var jointSurveyorNames = "";
    for (let i = 0; i < this.state.jointServeyorNames.length; i++) {
      for (let j = 0; j < this.state.surveyorsData.length; j++) {
        if (
          this.state.jointServeyorNames[i] === this.state.surveyorsData[j].name
        ) {
          jointSurveyorNames += this.state.surveyorsData[j].value + ",";
        }
      }
    }
    var joint_surveyor = jointSurveyorNames.slice(0, -1);
    if (vessel_type === "0") {
      vessel_type = "";
    }
    //New Business Number Operational MV
    api
      .updateBusinessNoOpMV(
        loginUserID,
        idToken,
        motherVesselID,
        businessNumberID,
        loadingCommencement,
        loadingCompletion,
        documentsOnBoard,
        vesselSailing,
        vessel_final_quantity_in_mt,
        cargo_gain_loss_in_mt,
        dischargePort,
        loadingType,
        loadingRatePerDay,
        deadFreightAccount,
        deadFreightIncurred,
        supplier_surveyor,
        supplierSurveyorAnalysisType,
        internal_surveyor,
        SDAMSAIISurveyorAnalysisType,
        joint_surveyor,
        surveyorBasePrice,
        surveyorVAT,
        surveyorTotalPrice,
        surveyorCurrencyType,
        surveyorAdditionalDays,
        stevedore_vendor,
        stevedoreAdditionalDays,
        floating_crane_vendor,
        floatingCraneName,
        floating_crane_additional_days,
        floatingCraneDemurrageDespatch,
        floatingCraneDemurrageDespatchCurrencyType,
        documentationAgent,
        supplierDocumentationAgent,
        SDAM_DocumentationAgent,
        buyerDocumentationAgent,
        vessel_arrived_on,
        nor_acceptance,
        vessel_type,
        load_port_agent,
        eta_nor,
        remarks
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState({
                snackBarSuccessOpen: true,
                successMsg: res.message,
              });
              this.props.onGetResponse(res.code);
            } else if (res.code === "601") {
              this.setState({
                isLoading: false,
                errorMsg: "Missing mandatory fields",
                snackBarErrorOpen: true,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            } else {
              this.setState({
                isLoading: false,
              });
              alert("Unexpected error occured. Please contact administrator.");
            }
          });
        }
      });
  };

  TextHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleDecimalField = (e) => {
    // const re = /^[0-9]*\.?[0-9]*$/;
    const re = /^\d*(\.\d{0,3})?$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };

  MultiSelectHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  toLocaleString(value) {
    return Number(value).toLocaleString();
  }

  render() {
    // const re = /^[0-9]*\.?[0-9]*$/;
    const re = /^\d*(\.\d{0,2})?$/;
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div className="container p-0" style={{ minHeight: "100vh" }}>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            autoHideDuration={10000}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={10000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <div className="clearfix content-header border-bottom">
            <div className="row">
              <div className="col-sm text-left pl-0">
                <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                  Mother Vessel Data for {this.state.businessNumber}
                  {this.state.businessName
                    ? " (" + this.state.businessName + ")"
                    : ""}
                </h4>
              </div>
            </div>
          </div>
          <div className="card" style={{ overflowY: "scroll" }}>
            <div className="section_block w-100 pl-4 pr-4">
              <div style={{ width: "70%" }}>
                <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                  Mother Vessel Data
                </h5>
              </div>

              <div className="row">
                <div className="col-lg-6 p-0">
                  <label className="form_label mb-0">
                    Loading Commencement
                  </label>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      autoOk
                      hideTabs
                      variant="inline"
                      inputVariant="outlined"
                      margin="dense"
                      fullWidth
                      value={this.state.loadingCommencement}
                      onChange={(date) => {
                        this.setState({
                          loadingCommencement: date,
                        });
                      }}
                      allowKeyboardControl={false}
                      ampm={false}
                      format="dd/MM/yyyy HH:mm"
                      minDate={new Date("2018-01-01")}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton>
                              <EventIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                        className: "pl-0",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>

                <div className="col-lg-6 pr-0">
                  <label className="form_label mb-0">Loading Completion</label>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      autoOk
                      hideTabs
                      variant="inline"
                      inputVariant="outlined"
                      margin="dense"
                      fullWidth
                      value={this.state.loadingCompletion}
                      onChange={(date) => {
                        this.setState({
                          loadingCompletion: date,
                        });
                      }}
                      allowKeyboardControl={false}
                      ampm={false}
                      format="dd/MM/yyyy HH:mm"
                      minDate={new Date("2018-01-01")}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton>
                              <EventIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                        className: "pl-0",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 p-0">
                  <label className="form_label mb-0">Documents on-board</label>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      autoOk
                      hideTabs
                      variant="inline"
                      inputVariant="outlined"
                      margin="dense"
                      fullWidth
                      value={this.state.documentsOnBoard}
                      onChange={(date) => {
                        this.setState({
                          documentsOnBoard: date,
                        });
                      }}
                      allowKeyboardControl={false}
                      ampm={false}
                      format="dd/MM/yyyy HH:mm"
                      minDate={new Date("2018-01-01")}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton>
                              <EventIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                        className: "pl-0",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>

                <div className="col-lg-6 pr-0">
                  <label className="form_label mb-0">Vessel Sailing</label>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      autoOk
                      hideTabs
                      variant="inline"
                      inputVariant="outlined"
                      margin="dense"
                      fullWidth
                      value={this.state.vesselSailing}
                      onChange={(date) => {
                        this.setState({
                          vesselSailing: date,
                        });
                      }}
                      allowKeyboardControl={false}
                      ampm={false}
                      format="dd/MM/yyyy HH:mm"
                      minDate={new Date("2018-01-01")}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton>
                              <EventIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                        className: "pl-0",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 p-0">
                  <label className="form_label mb-0">Vessel Arrived</label>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      autoOk
                      hideTabs
                      inputVariant="outlined"
                      variant="inline"
                      margin="dense"
                      fullWidth
                      ampm={false}
                      format="dd/MM/yyyy HH:mm"
                      value={this.state.vessel_arrived_on}
                      onChange={(date) => {
                        this.setState({
                          vessel_arrived_on: date,
                        });
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton>
                              <EventIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                        className: "pl-0",
                        style: {
                          fontFamily: "Poppins",
                          fontSize: "14px",
                        },
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>

                <div className="col-lg-6 pr-0">
                  <label className="form_label mb-0">NOR Acceptance</label>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      autoOk
                      hideTabs
                      inputVariant="outlined"
                      variant="inline"
                      margin="dense"
                      fullWidth
                      ampm={false}
                      format="dd/MM/yyyy HH:mm"
                      value={this.state.nor_acceptance}
                      onChange={(date) => {
                        this.setState({
                          nor_acceptance: date,
                        });
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton>
                              <EventIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                        className: "pl-0",
                        style: {
                          fontFamily: "Poppins",
                          fontSize: "14px",
                        },
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 p-0">
                  <label className="form_label mb-0">Vessel Type</label>
                  <TextField
                    name="vessel_type"
                    placeholder="Mother Vessel Name"
                    margin="dense"
                    variant="outlined"
                    value={this.state.vessel_type}
                    onChange={(e) => {
                      this.setState({
                        vessel_type: e.target.value,
                      });
                    }}
                    fullWidth
                    select
                    InputProps={{
                      style: {
                        fontFamily: "Poppins",
                        fontSize: "14px",
                      },
                    }}
                  >
                    <MenuItem value="0">Please Select</MenuItem>
                    <MenuItem value="Geared">Geared</MenuItem>
                    <MenuItem value="Gearless">Gearless</MenuItem>
                  </TextField>
                </div>

                <div className="col-lg-6 pr-0">
                  <label className="form_label mb-0">Load Port Agent </label>
                  <TextField
                    name="load_port_agent"
                    placeholder="Load Port Agent"
                    margin="dense"
                    variant="outlined"
                    value={this.state.load_port_agent}
                    onChange={(e) => {
                      this.setState({
                        load_port_agent: e.target.value,
                      });
                    }}
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    fullWidth
                    InputProps={{
                      style: {
                        fontFamily: "Poppins",
                        fontSize: "14px",
                      },
                    }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 p-0">
                  <label className="form_label mb-0">ETA / NOR </label>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      autoOk
                      hideTabs
                      inputVariant="outlined"
                      variant="inline"
                      margin="dense"
                      fullWidth
                      ampm={false}
                      format="dd/MM/yyyy HH:mm"
                      value={this.state.eta_nor}
                      onChange={(date) => {
                        this.setState({
                          eta_nor: date,
                        });
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton>
                              <EventIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                        className: "pl-0",
                        style: {
                          fontFamily: "Poppins",
                          fontSize: "14px",
                        },
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>

                <div className="col-lg-6 pr-0">
                  <label className="form_label mb-0">
                    Vessel Final Quantity in MT
                  </label>
                  <TextField
                    name="vessel_final_quantity_in_mt"
                    margin="dense"
                    variant="outlined"
                    placeholder="Vessel Final Quantity in MT"
                    value={this.state.vessel_final_quantity_in_mt}
                    fullWidth
                    //type='number'
                    onChange={(e) => {
                      const reg = /^\d*(\.\d{0,3})?$/;
                      if (e.target.value === "" || reg.test(e.target.value)) {
                        this.setState({
                          vessel_final_quantity_in_mt: e.target.value,
                          cargo_gain_loss_in_mt:
                            Number(e.target.value) -
                            Number(
                              this.state.sumOfBargeQuantities
                                ? this.state.sumOfBargeQuantities
                                : 1
                            ),
                        });
                      }
                    }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 p-0">
                  <label className="form_label mb-0">
                    Cargo Gain/Loss in MT
                  </label>
                  {this.state.cargo_gain_loss_in_mt < 0 ? (
                    <h4 className="label-text text-danger">
                      {this.state.cargo_gain_loss_in_mt
                        ? "(" +
                          this.toLocaleString(
                            Math.abs(this.state.cargo_gain_loss_in_mt)
                          ) +
                          ")"
                        : null}
                    </h4>
                  ) : (
                    <h4 className="label-text">
                      {this.state.cargo_gain_loss_in_mt
                        ? this.state.cargo_gain_loss_in_mt
                        : null}
                    </h4>
                  )}
                </div>

                <div className="col-lg-6 pr-0">
                  <label className="form_label mb-0">Discharge Port</label>
                  <TextField
                    name="dischargePort"
                    margin="dense"
                    variant="outlined"
                    placeholder="Discharge Port"
                    // onChange={(value)=>(this.setState({dischargePort: value}))}
                    onChange={this.TextHandler}
                    value={this.state.dischargePort.toUpperCase()}
                    fullWidth
                    inputProps={{ style: { textTransform: "uppercase" } }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 p-0">
                  <label className="form_label mb-0">Loading Type</label>
                  <TextField
                    name="loadingType"
                    margin="dense"
                    variant="outlined"
                    onChange={this.TextHandler}
                    value={this.state.loadingType}
                    fullWidth
                    select
                  >
                    <MenuItem value="0">Please Select</MenuItem>
                    <MenuItem value="Floating crane">Floating crane</MenuItem>
                    <MenuItem value="Vessel grabs">Vessel grabs</MenuItem>
                  </TextField>
                </div>

                <div className="col-lg-6 pr-0">
                  <label className="form_label mb-0">
                    Loading rate per day
                  </label>
                  <TextField
                    name="loadingRatePerDay"
                    placeholder="Loading rate per day"
                    // label='Loading rate per day'
                    margin="dense"
                    variant="outlined"
                    value={this.state.loadingRatePerDay}
                    onChange={this.handleDecimalField}
                    fullWidth
                    // inputProps={{style:{textTransform:'uppercase'}}}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 p-0">
                  <label className="form_label mb-0">
                    Dead Freight Incurred
                  </label>
                  <TextField
                    name="deadFreightIncurred"
                    placeholder="Dead Freight Incurred"
                    // label='Dead Freight Incurred'
                    margin="dense"
                    variant="outlined"
                    value={this.state.deadFreightIncurred}
                    onChange={this.handleDecimalField}
                    fullWidth
                    // inputProps={{style:{textTransform:'uppercase'}}}
                  />
                </div>

                <div className="col-lg-6 pr-0">
                  <label className="form_label mb-0">
                    Dead Freight Account
                  </label>
                  <TextField
                    name="deadFreightAccount"
                    margin="dense"
                    variant="outlined"
                    onChange={this.TextHandler}
                    value={this.state.deadFreightAccount}
                    fullWidth
                    select
                  >
                    <MenuItem value="0">Please Select</MenuItem>
                    <MenuItem value="Supplier Account">
                      Supplier Account
                    </MenuItem>
                    <MenuItem value={Config.company + " Account"}>
                      {Config.company + " Account"}
                    </MenuItem>
                    <MenuItem value="Buyer">Buyer</MenuItem>
                  </TextField>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 p-0">
                  <label className="form_label mb-0">
                    Supplier Surveyor Name
                  </label>
                  <FormControl margin="dense" variant="outlined" fullWidth>
                    <Select
                      id="demo2-mutiple-checkbox"
                      multiple
                      fullWidth
                      name="supplierServeyorNames"
                      value={this.state.supplierServeyorNames.sort()}
                      onChange={this.MultiSelectHandler}
                      // input={<Input />}
                      renderValue={(selected) => selected.join(", ")}
                      //MenuProps={MenuProps}
                    >
                      {this.state.surveyorsData.map((e, index) => (
                        <MenuItem value={e.name} key={index}>
                          <Checkbox
                            checked={this.state.supplierServeyorNames.includes(
                              e.name
                            )}
                          />
                          <ListItemText primary={e.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div className="col-lg-6 pr-0">
                  <label className="form_label mb-0">
                    Supplier Surveyor Analysis Type
                  </label>
                  <TextField
                    name="supplierSurveyorAnalysisType"
                    margin="dense"
                    variant="outlined"
                    onChange={this.TextHandler}
                    value={this.state.supplierSurveyorAnalysisType}
                    fullWidth
                    select
                  >
                    <MenuItem value="0">Please Select</MenuItem>
                    <MenuItem value="ASTM">ASTM</MenuItem>
                    <MenuItem value="ISO">ISO</MenuItem>
                  </TextField>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 p-0">
                  <label className="form_label mb-0">
                    {Config.company} Surveyor Name
                  </label>
                  <FormControl margin="dense" variant="outlined" fullWidth>
                    <Select
                      id="demo2-mutiple-checkbox"
                      multiple
                      fullWidth
                      name="internalServeyorNames"
                      value={this.state.internalServeyorNames.sort()}
                      onChange={this.MultiSelectHandler}
                      // input={<Input />}
                      renderValue={(selected) => selected.join(", ")}
                      //MenuProps={MenuProps}
                    >
                      {this.state.surveyorsData.map((e, index) => (
                        <MenuItem value={e.name} key={index}>
                          <Checkbox
                            checked={this.state.internalServeyorNames.includes(
                              e.name
                            )}
                          />
                          <ListItemText primary={e.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div className="col-lg-6 pr-0">
                  <label className="form_label mb-0">
                    {Config.company} Surveyor Analysis Type
                  </label>
                  <TextField
                    name="SDAMSAIISurveyorAnalysisType"
                    margin="dense"
                    variant="outlined"
                    onChange={this.TextHandler}
                    value={this.state.SDAMSAIISurveyorAnalysisType}
                    fullWidth
                    select
                  >
                    <MenuItem value="0">Please Select</MenuItem>
                    <MenuItem value="ASTM">ASTM</MenuItem>
                    <MenuItem value="ISO">ISO</MenuItem>
                  </TextField>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 p-0">
                  <label className="form_label mb-0">
                    Buyer/Additional Surveyor Name
                  </label>
                  <FormControl margin="dense" variant="outlined" fullWidth>
                    <Select
                      id="demo2-mutiple-checkbox"
                      multiple
                      fullWidth
                      name="jointServeyorNames"
                      value={this.state.jointServeyorNames.sort()}
                      onChange={this.MultiSelectHandler}
                      renderValue={(selected) => selected.join(", ")}
                    >
                      {this.state.surveyorsData.map((e, index) => (
                        <MenuItem value={e.name} key={index}>
                          <Checkbox
                            checked={this.state.jointServeyorNames.includes(
                              e.name
                            )}
                          />
                          <ListItemText primary={e.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>

              <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                Surveyor Price
              </h5>

              <div className="row">
                <div className="col-lg-6 p-0">
                  <label className="form_label mb-0">Surveyor Price</label>
                  <TextField
                    name="surveyorBasePrice"
                    margin="dense"
                    variant="outlined"
                    type="number"
                    label="Base Price"
                    placeholder="Base Price"
                    onChange={(e) => {
                      const pr = /^\d*(\.\d{0,4})?$/;
                      if (e.target.value === "" || pr.test(e.target.value)) {
                        let value = e.target.value;
                        this.setState({
                          surveyorBasePrice: value,
                          surveyorTotalPrice:
                            this.state.surveyorVAT !== ""
                              ? (
                                  Number(value) + Number(this.state.surveyorVAT)
                                ).toFixed(2)
                              : Number(value).toFixed(2),
                        });
                      }
                    }}
                    // InputLabelProps={{
                    //   shrink: true,
                    // }}
                    value={this.state.surveyorBasePrice}
                    fullWidth
                  />
                </div>

                <div className="col-lg-6 pr-0">
                  <label className="form_label mb-0">VAT</label>
                  <TextField
                    name="surveyorVAT"
                    margin="dense"
                    variant="outlined"
                    type="number"
                    label="VAT"
                    placeholder="VAT"
                    onChange={(e) => {
                      if (e.target.value === "" || re.test(e.target.value)) {
                        let value = e.target.value;
                        this.setState({
                          surveyorVAT: value,
                          surveyorTotalPrice:
                            this.state.surveyorBasePrice !== ""
                              ? (
                                  Number(value) +
                                  Number(this.state.surveyorBasePrice)
                                ).toFixed(2)
                              : Number(value).toFixed(2),
                        });
                      }
                    }}
                    // InputLabelProps={{
                    //   shrink: true,
                    // }}
                    value={this.state.surveyorVAT}
                    fullWidth
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 p-0">
                  <label className="form_label mb-0">Total</label>
                  <TextField
                    placeholder="Total"
                    fullWidth
                    disabled
                    value={this.state.surveyorTotalPrice}
                    margin="dense"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                  />
                </div>

                <div className="col-lg-6 pr-0">
                  <label className="form_label mb-0">Currency</label>
                  <TextField
                    name="surveyorCurrencyType"
                    margin="dense"
                    variant="outlined"
                    onChange={this.TextHandler}
                    value={this.state.surveyorCurrencyType}
                    fullWidth
                    select
                  >
                    {this.state.currencyData}
                  </TextField>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 p-0">
                  <label className="form_label mb-0">
                    Surveyor Additional days{" "}
                  </label>
                  <TextField
                    name="surveyorAdditionalDays"
                    placeholder="Surveyor Additional days "
                    // label='Surveyor Additional days '
                    margin="dense"
                    variant="outlined"
                    value={this.state.surveyorAdditionalDays}
                    onChange={this.handleDecimalField}
                    fullWidth
                    // inputProps={{style:{textTransform:'uppercase'}}}
                  />
                </div>
              </div>

              {this.state.loadingType === "Vessel grabs" && (
                <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                  Vessel grabs
                </h5>
              )}

              {this.state.loadingType === "Vessel grabs" && (
                <div className="row">
                  <div className="col-lg-6 p-0">
                    <label className="form_label mb-2">Stevedore on MV</label>
                    <Autocomplete
                      multiple
                      size="small"
                      options={this.state.othersData}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, value) => {
                        if (value) {
                          var data = [];
                          for (var i = 0; i < value.length; i++) {
                            data.push(value[i].value);
                          }
                          var stevedore_vendor = data.toString();
                          this.setState({
                            stevedore_vendor: stevedore_vendor,
                            stevedoreOnMVValue: value,
                          });
                        } else {
                          this.setState({
                            stevedore_vendor: "",
                            stevedoreOnMVValue: "",
                          });
                        }
                      }}
                      name="stevedore_vendor"
                      disableCloseOnSelect
                      renderOption={(option, { selected }) => (
                        <React.Fragment>
                          <Checkbox
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.name}
                        </React.Fragment>
                      )}
                      defaultValue={this.state.othersData.filter((e, idx) =>
                        this.state.stevedore_vendor_array.find(
                          (v, index) => v === e.value
                        )
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Search Stevedore"
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                  </div>
                  <div className="col-lg-6 pr-0">
                    <label className="form_label mb-0">
                      Stevedore Additional days{" "}
                    </label>
                    <TextField
                      name="stevedoreAdditionalDays"
                      placeholder="Stevedore Additional days "
                      margin="dense"
                      variant="outlined"
                      value={this.state.stevedoreAdditionalDays}
                      onChange={this.handleDecimalField}
                      fullWidth
                      // inputProps={{style:{textTransform:'uppercase'}}}
                    />
                  </div>
                </div>
              )}
              {this.state.loadingType === "Floating crane" && (
                <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                  Floating crane
                </h5>
              )}

              {this.state.loadingType === "Floating crane" && (
                <div className="row">
                  <div className="col-lg-6 p-0">
                    <label className="form_label mb-2">
                      Floating Crane Vendor
                    </label>
                    <Autocomplete
                      multiple
                      size="small"
                      options={this.state.othersData}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, value) => {
                        if (value) {
                          var data = [];
                          for (var i = 0; i < value.length; i++) {
                            data.push(value[i].value);
                          }
                          var floating_crane_vendor = data.toString();
                          this.setState({
                            floating_crane_vendor: floating_crane_vendor,
                            floatingCrane_vendor_value: value,
                          });
                        } else {
                          this.setState({
                            floating_crane_vendor: "",
                            floatingCrane_vendor_value: "",
                          });
                        }
                      }}
                      name="vendorName"
                      disableCloseOnSelect
                      renderOption={(option, { selected }) => (
                        <React.Fragment>
                          <Checkbox
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.name}
                        </React.Fragment>
                      )}
                      defaultValue={this.state.othersData.filter((e, idx) =>
                        this.state.floating_crane_vendor_array.find(
                          (v, index) => v === e.value
                        )
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Search Floating Crane Vendor"
                          variant="outlined"
                          error={this.state.vendorTypeError}
                          fullWidth
                        />
                      )}
                    />
                  </div>
                  <div className="col-lg-6 pr-0">
                    <label className="form_label mb-0">
                      Floating Crane Name
                    </label>
                    <TextField
                      name="floatingCraneName"
                      margin="dense"
                      variant="outlined"
                      placeholder="Floating Crane Name"
                      onChange={this.TextHandler}
                      value={this.state.floatingCraneName}
                      fullWidth
                      inputProps={{ style: { textTransform: "uppercase" } }}
                    />
                  </div>

                  <div className="row">
                    <div className="col-lg-6 p-0">
                      <label className="form_label mb-0">
                        Floating Crane Additional days{" "}
                      </label>
                      <TextField
                        name="floating_crane_additional_days"
                        placeholder="Floating Crane Additional days "
                        margin="dense"
                        variant="outlined"
                        value={this.state.floating_crane_additional_days}
                        onChange={this.handleDecimalField}
                        fullWidth
                        // inputProps={{style:{textTransform:'uppercase'}}}
                      />
                    </div>

                    <div className="col-lg-6 pr-0">
                      <label className="form_label mb-0">
                        Floating Crane Demurrage / Despatch
                      </label>
                      <TextField
                        name="floatingCraneDemurrageDespatch"
                        placeholder="Demurrage / Despatch"
                        label="Demurrage / Despatch"
                        margin="dense"
                        type="number"
                        variant="outlined"
                        value={this.state.floatingCraneDemurrageDespatch}
                        onChange={this.handleDecimalField}
                        fullWidth
                        // inputProps={{style:{textTransform:'uppercase'}}}
                      />
                    </div>

                    <div className="col-lg-6 p-0">
                      <label className="form_label mb-0">Currency</label>
                      <TextField
                        name="floatingCraneDemurrageDespatchCurrencyType"
                        margin="dense"
                        variant="outlined"
                        onChange={this.TextHandler}
                        value={
                          this.state.floatingCraneDemurrageDespatchCurrencyType
                        }
                        fullWidth
                        select
                      >
                        {this.state.currencyData}
                      </TextField>
                    </div>
                  </div>
                </div>
              )}

              <div className="row">
                <div className="col-lg-6 p-0">
                  <label className="form_label mb-0">
                    Supplier Documentation Agent
                  </label>
                  <TextField
                    name="supplierDocumentationAgent"
                    margin="dense"
                    variant="outlined"
                    placeholder="Supplier Documentation Agent"
                    fullWidth
                    value={this.state.supplierDocumentationAgent}
                    onChange={this.TextHandler}
                    inputProps={{ style: { textTransform: "uppercase" } }}
                  />
                </div>

                <div className="col-lg-6 pr-0">
                  <label className="form_label mb-0">
                    {Config.company} Documentation Agent
                  </label>
                  <TextField
                    name="SDAM_DocumentationAgent"
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    value={this.state.SDAM_DocumentationAgent}
                    placeholder={Config.company + " Documentation Agent"}
                    onChange={this.TextHandler}
                    inputProps={{ style: { textTransform: "uppercase" } }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 p-0">
                  <label className="form_label mb-0">
                    Buyer Documentation Agent
                  </label>
                  <TextField
                    name="buyerDocumentationAgent"
                    margin="dense"
                    variant="outlined"
                    value={this.state.buyerDocumentationAgent}
                    fullWidth
                    onChange={this.TextHandler}
                    placeholder="Buyer Documentation Agent"
                    inputProps={{ style: { textTransform: "uppercase" } }}
                  />
                </div>
                <div className="col-lg-6 pr-0">
                  <label className="form_label mb-0">Remarks</label>
                  <TextField
                    name="remarks"
                    margin="dense"
                    variant="outlined"
                    value={this.state.remarks}
                    placeholder="Remarks"
                    multiline
                    rows={3}
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    onChange={this.TextHandler}
                    fullWidth
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row bottom_buttons_section">
            {/* <button type='button' className='btn previous_button' onClick={() => { window.location.href = '/view-business/' + btoa(this.state.businessNumberID) }}>Back</button> */}
            <button
              type="button"
              className="btn previous_button"
              onClick={() => this.props.returnCallbackDrawerClose(false)}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn next_button"
              onClick={this.submitHandler}
            >
              Update
            </button>
            &emsp;
          </div>
        </div>
      );
    }
  }
}
