import React, { Component } from "react";
import SideBar from "../common/SideBar";
import Header from "../common/Header";
import CookieHandler from "../common/CookieHandler";
import {
  MenuItem,
  TextField,
  IconButton,
  InputAdornment,
  Checkbox,
} from "@material-ui/core";
import EventIcon from "@material-ui/icons/Event";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import api from "../../api/api";
import config from "../../config/config";
import Autocomplete from "@material-ui/lab/Autocomplete";

import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert } from "@material-ui/lab";
import { withStyles } from "@material-ui/core/styles";
import { dateFormateToDB, localDateFormate } from "../common/common";
import Loader from "../common/Loader";

const NewRadio = withStyles({
  root: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const CheckBox = withStyles({
  root: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default class UpdateBusinessNumber extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      businessType: "0",
      consignmentType: "0",
      motherVesselName: "",
      oldBusinessNo: "",
      bargeName: "",
      shipmentType: "0",
      vessel_Type: "0",
      stevedore_vendor: "",
      floating_crane_vendor: "",
      floatingCrane_name: "",
      loading_Anchorage: "",
      loadingPort_agent: "",
      loading_rate: "",
      vessel_freight: "",
      vessel_freight_price: "",
      shipmentLaycan: "",
      vessel_arrived_on: null,
      ETA: null,
      nor_acceptance: null,
      Documents_boardDate: null,
      vessel_sailingDate: null,
      stowagePlan: "",
      supplyTonnage: "",
      businessFlow: "",
      supplierReferenceNumber: "",
      remarks: "",
      motherVesselDropdown: [
        <MenuItem value="FAS MV" key="0">
          FAS MV
        </MenuItem>,
        <MenuItem value="FOB MV" key="1">
          FOB MV
        </MenuItem>,
        <MenuItem value="CFR MV" key="2">
          CFR MV
        </MenuItem>,
        <MenuItem value="CIF MV" key="3">
          CIF MV
        </MenuItem>,
      ],
      bargeDropdown: [
        <MenuItem value="FOB Barge" key="0">
          FOB Barge
        </MenuItem>,
        <MenuItem value="CFR Barge" key="1">
          CFR Barge
        </MenuItem>,
        <MenuItem value="CIF Barge" key="2">
          CIF Barge
        </MenuItem>,
      ],

      vessel_owner_selected: { name: "", value: "" },

      vendorsAsStevedors: [],
      vendorsAsOther: [],
      vesselOwner: [],
      filteredVendorAsStevedor: [],
      filteredvendorsAsOther: [],
      vendorsData: [],
      stevedore_vendor_array: [],
      floating_crane_vendor_array: [],

      isLoading: false,
      features: [],
      activeStep: 0,
      textAreaMaxCount: 2000,
      users_list_menu: [],
      selected_business_head: {},
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    } else {
      var features = this.Cookie.getCookie("features");
      this.setState({ features: features });
      if (!(features.includes("39") || features.includes("97"))) {
        window.location.href = "/dashboard";
      }
      //calling get_purchase_contracts API
      var loginUserID = this.Cookie.getCookie("loginUserId");
      var idToken = this.Cookie.getIdTokenCookie();
      var status = "Active";
      var vendorTypeOtherData = [];
      const vesselOwner = [];
      const getVendors = await api.getVendors(loginUserID, idToken, status);
      if (getVendors.status >= 200 && getVendors.status < 300) {
        getVendors.json().then((res) => {
          if (res.code === "200") {
            if (res.vendors) {
              for (var i in res.vendors) {
                if (
                  res.vendors[i].vendor_type === "Others" ||
                  res.vendors[i].vendor_type === "Stevedore" ||
                  res.vendors[i].vendor_type === "Floating Crane" ||
                  res.vendors[i].vendor_type === "Vessel Agent"
                ) {
                  vendorTypeOtherData.push({
                    name: res.vendors[i].vendor_name,
                    value: res.vendors[i].id,
                    key: i,
                  });
                }
                if (
                  res.vendors[i].vendor_type === "Vessel Owner" ||
                  res.vendors[i].vendor_type === "Vessel Agent"
                ) {
                  vesselOwner.push({
                    name: res.vendors[i].vendor_name,
                    value: res.vendors[i].id,
                    key: i,
                  });
                }
              }
              this.setState({
                vendorsAsOther: vendorTypeOtherData,
                vesselOwner: vesselOwner,
                vendorsData: res.vendors,
              });
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
            if (res.code === "607") {
              window.location.href = "/logout";
            }
          }
        });
      }
      await api.getUserList(loginUserID, idToken, status).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.users) {
              let usersList = [];
              res.users.forEach((item) => {
                return usersList.push({
                  name: item.user_name,
                  value: item.user_id,
                });
              });
              this.setState({ users_list_menu: usersList });
            }
          });
        }
      });

      try {
        var businessNo = decodeURIComponent(
          window.atob(this.props.match.params.businessID)
        );
        const getBusinessNumber = await api.getBusinessNumber(
          businessNo,
          loginUserID,
          idToken
        );
        if (getBusinessNumber.status >= 200 && getBusinessNumber.status < 300) {
          getBusinessNumber.json().then((res) => {
            if (res.code === "200") {
              if (res.business_number) {
                if (res.business_number.status !== "Active") {
                  window.location.href = "/business-number-list";
                }
                this.setState({
                  businessType: res.business_number.business_type
                    ? res.business_number.business_type
                    : "0",
                  businessNo: res.business_number.business_no
                    ? res.business_number.business_no
                    : "",
                  oldBusinessNo: res.business_number.old_business_no
                    ? res.business_number.old_business_no
                    : "",
                  business_no_id: res.business_number.id
                    ? res.business_number.id
                    : "",
                  businessFlow: res.business_number.business_flow
                    ? res.business_number.business_flow
                    : "",
                  supplierReferenceNumber: res.business_number
                    .supplier_reference_number
                    ? res.business_number.supplier_reference_number
                    : "",
                  ship: res.business_number.business_flow
                    ? res.business_number.business_flow
                    : "",
                  consignmentType: res.business_number.consignment_type
                    ? res.business_number.consignment_type
                    : "0",
                  motherVesselName: res.business_number.mother_vessel_name
                    ? res.business_number.mother_vessel_name
                    : "",
                  bargeName: res.business_number.barge_name
                    ? res.business_number.barge_name
                    : "",
                  shipmentType: res.business_number.shipment_type
                    ? res.business_number.shipment_type
                    : "0",
                  vessel_Type: res.business_number.vessel_type
                    ? res.business_number.vessel_type
                    : "0",
                  stevedore_vendor: res.business_number.stevedore_vendor
                    ? res.business_number.stevedore_vendor
                    : "",
                  stevedore_vendor_array: res.business_number.stevedore_vendor
                    ? res.business_number.stevedore_vendor.split(",")
                    : [],
                  floating_crane_vendor: res.business_number
                    .floating_crane_vendor
                    ? res.business_number.floating_crane_vendor
                    : "",
                  floating_crane_vendor_array: res.business_number
                    .floating_crane_vendor
                    ? res.business_number.floating_crane_vendor.split(",")
                    : [],
                  floatingCrane_name: res.business_number.floating_crane_name
                    ? res.business_number.floating_crane_name
                    : "",
                  loading_Anchorage: res.business_number.loading_anchorage
                    ? res.business_number.loading_anchorage
                    : "",
                  loadingPort_agent: res.business_number.load_port_agent
                    ? res.business_number.load_port_agent
                    : "",
                  loading_rate: res.business_number.load_rate_per_day
                    ? res.business_number.load_rate_per_day
                    : "",
                  vessel_freight: res.business_number.vessel_freight
                    ? res.business_number.vessel_freight
                    : "",
                  vessel_freight_price: res.business_number.vessel_freight_price
                    ? res.business_number.vessel_freight_price
                    : "",
                  shipmentLaycan: res.business_number.shipment_laycan
                    ? res.business_number.shipment_laycan
                    : "",
                  vessel_arrived_on: res.business_number.vessel_arrived_on
                    ? res.business_number.vessel_arrived_on
                    : null,
                  ETA: res.business_number.eta_nor
                    ? res.business_number.eta_nor
                    : null,
                  nor_acceptance: res.business_number.nor_acceptance
                    ? res.business_number.nor_acceptance
                    : null,
                  Documents_boardDate: res.business_number.documents_on_board
                    ? res.business_number.documents_on_board
                    : null,
                  vessel_sailingDate: res.business_number.vessel_sailing
                    ? res.business_number.vessel_sailing
                    : null,
                  stowagePlan: res.business_number.stowage_plan_in_mt
                    ? res.business_number.stowage_plan_in_mt
                    : "",
                  supplyTonnage: res.business_number.supply_tonnage_in_mt
                    ? res.business_number.supply_tonnage_in_mt
                    : "",
                  demurrage_rate_from_buyer: res.business_number
                    .demurrage_rate_from_buyer
                    ? res.business_number.demurrage_rate_from_buyer
                    : "",
                  demurrage_rate_to_supplier: res.business_number
                    .demurrage_rate_to_supplier
                    ? res.business_number.demurrage_rate_to_supplier
                    : "",
                  remarks: res.business_number.remarks
                    ? res.business_number.remarks
                    : "",
                  buy_back_involved: res.business_number.buy_back_involved
                    ? res.business_number.buy_back_involved
                    : "No",
                  business_number_allocation: res.business_number
                    .business_number_allocation
                    ? res.business_number.business_number_allocation
                    : [],
                  vessel_owner_selected: res.business_number.vessel_owner
                    ? {
                        name: res.business_number.vessel_owner_name,
                        value: res.business_number.vessel_owner,
                      }
                    : { name: "", value: "" },
                  business_head_name: res.business_number.business_head_name,
                  business_head: res.business_number.business_head,
                  selected_business_head: {
                    name: res.business_number.business_head_name,
                    value: res.business_number.business_head,
                  },

                  isLoading: false,
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            }
            if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      } catch (e) {
        window.location.href = "/pagenotfound";
      }
    }

    document.title = config.documentTitle + "Update Business Number";
  }

  ETADateHandler = (date) => {
    this.setState({
      ETA: dateFormateToDB(date),
    });
  };
  Documentson_boardDateHandler = (date) => {
    this.setState({
      Documents_boardDate: dateFormateToDB(date),
    });
  };

  Vessel_SailingDateHandler = (date) => {
    this.setState({
      vessel_sailingDate: dateFormateToDB(date),
    });
  };

  handleText = (e) => {
    if (e.target.name === "businessType") {
      this.setState({
        [e.target.name]: e.target.value,
        businessTypeError: false,
      });
    } else if (e.target.name === "consignmentType") {
      this.setState({
        [e.target.name]: e.target.value,
        shipmentType: "0",
        consignmentTypeError: false,
      });
    } else if (e.target.name === "shipmentType") {
      this.setState({
        [e.target.name]: e.target.value,
        shipmentTypeError: false,
      });
    } else if (e.target.name === "businessFlow") {
      this.setState({
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "supplierReferenceNumber") {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleDecimalField = (e) => {
    if (e.target.name === "vessel_freight") {
      const re = /^\d*(\.\d{0,3})?$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        this.setState({
          [e.target.name]: e.target.value,
        });
      }
    }
    if (e.target.name === "stowagePlan") {
      const re = /^\d*(\.\d{0,3})?$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        this.setState({
          [e.target.name]: e.target.value,
        });
      }
    }
    if (e.target.name === "supplyTonnage") {
      const re = /^\d*(\.\d{0,3})?$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        this.setState({
          [e.target.name]: e.target.value,
        });
      }
    }
    if (e.target.name === "loading_rate") {
      const re = /^\d*(\.\d{0,3})?$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        this.setState({
          [e.target.name]: e.target.value,
        });
      }
    }
  };

  checkboxHandleChange = (e) => {
    if (e.target.name === "buy_back_involved") {
      this.setState({
        buy_back_involved: e.target.value,
      });
    }
  };

  submitHandler = () => {
    var {
      businessType,
      consignmentType,
      motherVesselName,
      oldBusinessNo,
      business_head,
      bargeName,
      shipmentType,
      vessel_Type,
      stevedore_vendor,
      floating_crane_vendor,
      floatingCrane_name,
      loading_Anchorage,
      Documents_boardDate,
      shipmentLaycan,
      loadingPort_agent,
      loading_rate,
      vessel_freight,
      vessel_owner,
      vessel_freight_price,
      vessel_arrived_on,
      nor_acceptance,
      ETA,
      vessel_sailingDate,
      stowagePlan,
      supplyTonnage,
      remarks,
      businessFlow,
      supplierReferenceNumber,
      buy_back_involved,
    } = this.state;
    if (businessType === "0") {
      this.setState({ businessTypeError: true });
    }
    if (consignmentType === "0") {
      this.setState({ consignmentTypeError: true });
    }
    if (shipmentType === "0") {
      this.setState({ shipmentTypeError: true });
    }

    if (consignmentType === "Mother Vessel") {
      bargeName = "";
    }
    if (consignmentType === "Barge") {
      motherVesselName = "";
    }
    if (vessel_Type === "0") {
      vessel_Type = "";
    }
    if (
      businessType !== "0" &&
      shipmentType !== "0" &&
      consignmentType !== "0"
    ) {
      var loginUserID = this.Cookie.getCookie("loginUserId");
      var idToken = this.Cookie.getIdTokenCookie();
      try {
        var businessNo = decodeURIComponent(
          window.atob(this.props.match.params.businessID)
        );
      } catch (e) {
        window.location.href = "/pagenotfound";
      }
      var demurrage_rate_from_buyer = this.state.demurrage_rate_from_buyer
        ? this.state.demurrage_rate_from_buyer
        : "";
      var demurrage_rate_to_supplier = this.state.demurrage_rate_to_supplier
        ? this.state.demurrage_rate_to_supplier
        : "";
      api
        .updateNewBusinessNumber(
          businessNo,
          loginUserID,
          idToken,
          business_head,
          oldBusinessNo,
          businessType,
          consignmentType,
          motherVesselName,
          bargeName,
          shipmentType,
          vessel_Type,
          stevedore_vendor,
          floating_crane_vendor,
          floatingCrane_name,
          loading_Anchorage,
          Documents_boardDate,
          shipmentLaycan,
          loadingPort_agent,
          loading_rate,
          vessel_freight,
          vessel_owner,
          vessel_freight_price,
          vessel_arrived_on,
          ETA,
          nor_acceptance,
          vessel_sailingDate,
          stowagePlan,
          supplyTonnage,
          demurrage_rate_from_buyer,
          demurrage_rate_to_supplier,
          remarks,
          businessFlow,
          supplierReferenceNumber,
          buy_back_involved
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res) {
                if (res.code === "200") {
                  this.setState({ successMsg: res.message, errorMsg: "" });
                  window.location.href = "/view-business/" + btoa(businessNo);
                }
                if (res.code === "601") {
                  this.setState({
                    errorMsg: res.message,
                    successMsg: "",
                  });
                }
                if (res.code === "607") {
                  window.location.href = "/logout";
                }
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            });
          }
        });
    } else {
      alert("Please fill all mandatory fields.");
    }
  };

  activeStepOne = () => {
    var { businessType, consignmentType, shipmentType } = this.state;
    let error = 0;
    if (businessType === "0") {
      this.setState({ businessTypeError: true });
      error = error + 1;
    }
    if (consignmentType === "0") {
      this.setState({ consignmentTypeError: true });
      error = error + 1;
    }
    if (shipmentType === "0") {
      this.setState({ shipmentTypeError: true });
      error = error + 1;
    }
    if (error > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMessage: "Please select mandatory fields",
      });
      return;
    } else {
      this.setState({
        activeStep: 1,
      });
    }
  };

  render() {
    var businessNo = this.props.match.params.businessID;
    return (
      <div id="wraper">
        <SideBar />
        <div className="content">
          <Header />
          <div className="clearfix content-header">
            <div className="row">
              <div className="col-sm text-left pl-0">
                <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                  Update Business Number - {this.state.businessNo}
                </h4>
              </div>
            </div>
          </div>
          {this.state.isLoading && <Loader />}
          {!this.state.isLoading && (
            <form className="container p-3">
              {this.state.errorMsg && (
                <div className="row">
                  <div className="col text-center">
                    <p className="error-class">{this.state.errorMsg}</p>
                  </div>
                </div>
              )}
              {this.state.successMsg && (
                <div className="row">
                  <div className="col text-center">
                    <p className="success-class">{this.state.successMsg}</p>
                  </div>
                </div>
              )}
              <Snackbar
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                open={this.state.snackBarErrorOpen}
                autoHideDuration={10000}
                onClose={this.handleErrorClose}
                style={{ width: "450px" }}
              >
                <Alert
                  elevation={6}
                  variant="filled"
                  onClose={this.handleErrorClose}
                  severity="error"
                  style={{ width: "100%" }}
                >
                  {this.state.errorMessage}
                </Alert>
              </Snackbar>
              <Snackbar
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                style={{ width: "450px" }}
                open={this.state.snackBarSuccessOpen}
                autoHideDuration={10000}
                onClose={this.handleSuccessClose}
              >
                <Alert
                  elevation={6}
                  variant="filled"
                  onClose={this.handleSuccessClose}
                  severity="success"
                  style={{ width: "100%" }}
                >
                  {this.state.successMessage}
                </Alert>
              </Snackbar>
              <div className="row mb-0 stepper">
                <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                  <i
                    className={
                      this.state.activeStep > 0
                        ? "fa fa-check-circle"
                        : "fa fa-check-circle-o"
                    }
                    aria-hidden="true"
                    style={{
                      fontSize: 20,
                      color:
                        this.state.activeStep > 0
                          ? "#31c197"
                          : this.state.activeStep === 0
                          ? config.themeColor
                          : "#ccc",
                    }}
                  ></i>
                  <span
                    className="stepper_text"
                    style={{
                      color:
                        this.state.activeStep > 0
                          ? "#31c197"
                          : this.state.activeStep === 0
                          ? config.themeColor
                          : "#ccc",
                    }}
                  >
                    Shipment Details
                  </span>
                  <i
                    className="fa fa-chevron-right stepper_sub_icon stepper_arrow"
                    aria-hidden="true"
                    style={{
                      color:
                        this.state.activeStep > 0
                          ? "#31c197"
                          : this.state.activeStep === 0
                          ? config.themeColor
                          : "#ccc",
                    }}
                  ></i>
                </span>
                <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                  <i
                    className={
                      this.state.activeStep > 1
                        ? "fa fa-check-circle"
                        : "fa fa-check-circle-o"
                    }
                    aria-hidden="true"
                    style={{
                      fontSize: 20,
                      color:
                        this.state.activeStep > 1
                          ? "#31c197"
                          : this.state.activeStep === 1
                          ? config.themeColor
                          : "#ccc",
                    }}
                  ></i>
                  <span
                    className="stepper_text"
                    style={{
                      color:
                        this.state.activeStep > 1
                          ? "#31c197"
                          : this.state.activeStep === 1
                          ? config.themeColor
                          : "#ccc",
                    }}
                  >
                    Load Details
                  </span>
                  <i
                    className="fa fa-chevron-right stepper_sub_icon stepper_arrow"
                    aria-hidden="true"
                    style={{
                      color:
                        this.state.activeStep > 1
                          ? "#31c197"
                          : this.state.activeStep === 1
                          ? config.themeColor
                          : "#ccc",
                    }}
                  ></i>
                </span>
                <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                  <i
                    className="fa fa-check-circle-o"
                    aria-hidden="true"
                    style={{
                      fontSize: 20,
                      color:
                        this.state.activeStep === 2
                          ? config.themeColor
                          : "#ccc",
                    }}
                  ></i>
                  <span
                    className="stepper_text"
                    style={{
                      color:
                        this.state.activeStep === 2
                          ? config.themeColor
                          : "#ccc",
                    }}
                  >
                    Preview & Submit
                  </span>
                </span>
              </div>
              <div className="col-lg card" style={{ overflowY: "scroll" }}>
                {this.state.activeStep === 0 && (
                  <div className="section_block">
                    <div style={{ width: "70%" }}>
                      <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                        Add details to Update Business Number
                      </h5>
                    </div>
                    <div className="row mb-2">
                      <div className="col-lg-6 p-0">
                        <label className="form_label mb-2">Business Head</label>
                        <Autocomplete
                          options={this.state.users_list_menu}
                          getOptionLabel={(option) => option.name}
                          onChange={(event, value) => {
                            if (value) {
                              this.setState({
                                business_head: value.value,
                                selected_business_head: value,
                              });
                            } else
                              this.setState({
                                business_head: null,
                                selected_business_head: {},
                              });
                          }}
                          InputProps={{
                            style: {
                              fontFamily: "Poppins",
                              fontSize: "14px",
                            },
                          }}
                          size="small"
                          name="users"
                          renderOption={(option) => (
                            <React.Fragment>{option.name}</React.Fragment>
                          )}
                          value={this.state.selected_business_head}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Users"
                              variant="outlined"
                              fullWidth
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="row mb-0">
                      <div className="col-lg-6 p-0">
                        <div className="row mb-2">
                          <label className="form_label mb-0">
                            Business Type{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <TextField
                            name="businessType"
                            placeholder="Business Type"
                            margin="dense"
                            variant="outlined"
                            value={this.state.businessType}
                            error={this.state.businessTypeError}
                            onChange={this.handleText}
                            fullWidth
                            select
                          >
                            <MenuItem value="0" disabled key={-1}>
                              Please Select
                            </MenuItem>
                            <MenuItem value="Export Sales">
                              Export Sales
                            </MenuItem>
                            <MenuItem value="Local Sales">Local Sales</MenuItem>
                          </TextField>
                        </div>
                      </div>
                      <div className="col-lg-6 pr-0">
                        <div className="row mb-2">
                          <label className="form_label mb-0">
                            Consignment Type{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <TextField
                            name="consignmentType"
                            placeholder="Consignment Type"
                            margin="dense"
                            variant="outlined"
                            value={this.state.consignmentType}
                            error={this.state.consignmentTypeError}
                            onChange={this.handleText}
                            fullWidth
                            select
                          >
                            <MenuItem value="0" disabled key={-1}>
                              Please Select
                            </MenuItem>
                            <MenuItem value="Mother Vessel">
                              Mother Vessel
                            </MenuItem>
                            <MenuItem value="Barge">Barge</MenuItem>
                          </TextField>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-0">
                      {this.state.consignmentType === "Mother Vessel" && (
                        <div className="col-lg-6 p-0">
                          <div className="row mb-2">
                            <label className="form_label mb-0">
                              Mother Vessel Name
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <TextField
                              name="motherVesselName"
                              placeholder="Mother Vessel Name"
                              margin="dense"
                              variant="outlined"
                              value={this.state.motherVesselName}
                              onChange={this.handleText}
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                              fullWidth
                            />
                          </div>
                        </div>
                      )}
                      {this.state.consignmentType === "Barge" && (
                        <div className="col-lg-6 p-0">
                          <div className="row mb-2">
                            <label className="form_label mb-0">
                              Barge Name
                            </label>
                            <TextField
                              name="bargeName"
                              placeholder="Barge Name"
                              margin="dense"
                              variant="outlined"
                              value={this.state.bargeName}
                              onChange={this.handleText}
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                              fullWidth
                            />
                          </div>
                        </div>
                      )}
                      <div
                        className={
                          this.state.consignmentType === "0"
                            ? "col-lg-6 p-0"
                            : "col-lg-6 pr-0"
                        }
                      >
                        <div className="row mb-2">
                          <label className="form_label mb-0">
                            Shipment Type{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <TextField
                            name="shipmentType"
                            placeholder="Shipment Type"
                            margin="dense"
                            variant="outlined"
                            value={this.state.shipmentType}
                            error={this.state.shipmentTypeError}
                            onChange={this.handleText}
                            fullWidth
                            select
                          >
                            <MenuItem value="0" disabled key={-1}>
                              Please Select
                            </MenuItem>
                            {this.state.consignmentType === "Barge"
                              ? this.state.bargeDropdown
                              : ""}
                            {this.state.consignmentType === "Mother Vessel"
                              ? this.state.motherVesselDropdown
                              : ""}
                          </TextField>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-0">
                      <div className="col-lg-6 p-0">
                        <div className="row mb-2">
                          <label className="form_label mb-0">
                            Business Flow{" "}
                            {/* <span style={{ color: "red" }}>*</span> */}
                          </label>
                          <TextField
                            name="businessFlow"
                            placeholder="Business Flow"
                            margin="dense"
                            variant="outlined"
                            value={this.state.businessFlow}
                            // error={this.state.businessTypeError}
                            onChange={this.handleText}
                            fullWidth
                            // select
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                          ></TextField>
                        </div>
                      </div>
                      <div className="col-lg-6 pr-0">
                        <div className="row mb-2">
                          <label className="form_label mb-0">
                            Supplier Referene No{" "}
                            {/* <span style={{ color: "red" }}>*</span> */}
                          </label>
                          <TextField
                            name="supplierReferenceNumber"
                            placeholder="Supplier Referene No"
                            margin="dense"
                            variant="outlined"
                            value={this.state.supplierReferenceNumber.toUpperCase()}
                            // error={this.state.consignmentTypeError}
                            onChange={this.handleText}
                            fullWidth
                            // select
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                          ></TextField>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-lg-6 p-0">
                        <label className="form_label mb-0">
                          Buy Back Involved
                        </label>
                        <RadioGroup
                          name="buy_back_involved"
                          value={this.state.buy_back_involved}
                          onChange={this.checkboxHandleChange}
                        >
                          <div>
                            <FormControlLabel
                              value="Yes"
                              control={
                                <NewRadio
                                  checked={
                                    this.state.buy_back_involved === "Yes"
                                  }
                                />
                              }
                              label="Yes"
                            />
                            <FormControlLabel
                              value="No"
                              control={
                                <NewRadio
                                  checked={
                                    this.state.buy_back_involved === "No"
                                  }
                                />
                              }
                              label="No"
                            />
                          </div>
                        </RadioGroup>
                      </div>
                    </div>
                  </div>
                )}

                {this.state.activeStep === 1 && (
                  <div className="section_block" style={{ width: "85%" }}>
                    {this.state.consignmentType === "Mother Vessel" && (
                      <div>
                        <div className="row mb-0">
                          <div className="col-lg-6 p-0">
                            <div className="row mb-2">
                              <label className="form_label mb-0">
                                Vessel Type
                              </label>
                              <TextField
                                name="vessel_Type"
                                placeholder="Mother Vessel Name"
                                margin="dense"
                                variant="outlined"
                                value={this.state.vessel_Type}
                                onChange={this.handleText}
                                fullWidth
                                select
                                InputProps={{
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                  },
                                }}
                              >
                                <MenuItem value="0">Please Select</MenuItem>
                                <MenuItem value="Geared">Geared</MenuItem>
                                <MenuItem value="Gearless">Gearless</MenuItem>
                              </TextField>
                            </div>
                          </div>
                          <div className="col-lg-6 pr-0">
                            <div className="row mb-2">
                              <label className="form_label mb-0">
                                Floating Crane Name
                              </label>
                              <TextField
                                name="floatingCrane_name"
                                placeholder="Floating Crane Name"
                                margin="dense"
                                variant="outlined"
                                value={this.state.floatingCrane_name}
                                onChange={this.handleText}
                                inputProps={{
                                  style: { textTransform: "uppercase" },
                                }}
                                fullWidth
                                InputProps={{
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                  },
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        {this.state.shipmentType !== "FAS MV" && (
                          <span>
                            <div className="row mb-0">
                              <div className="col-lg-6 p-0">
                                <div className="row mb-2">
                                  <label className="form_label mb-0">
                                    Stevedore Vendor
                                  </label>
                                  <Autocomplete
                                    multiple
                                    options={this.state.vendorsAsOther}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(event, value) => {
                                      if (value) {
                                        var data = [];
                                        for (var i = 0; i < value.length; i++) {
                                          data.push(value[i].value);
                                        }
                                        var stevedore_vendor = data.toString();
                                        this.setState({
                                          stevedore_vendor: stevedore_vendor,
                                          stevedore_vendor_value: value,
                                        });
                                      } else {
                                        this.setState({
                                          stevedore_vendor: "",
                                          stevedore_vendor_value: "",
                                        });
                                      }
                                    }}
                                    name="vendorName"
                                    size="small"
                                    disableCloseOnSelect
                                    renderOption={(option, { selected }) => (
                                      <React.Fragment>
                                        <CheckBox
                                          style={{ marginRight: 8 }}
                                          checked={selected}
                                        />
                                        {option.name}
                                      </React.Fragment>
                                    )}
                                    defaultValue={this.state.vendorsAsOther.filter(
                                      (e, idx) =>
                                        this.state.stevedore_vendor_array.find(
                                          (v, index) => v === e.value
                                        )
                                    )}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="Search Stevedore"
                                        variant="outlined"
                                        fullWidth
                                      />
                                    )}
                                    style={{ display: "contents" }}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6 pr-0">
                                <div className="row mb-2">
                                  <label className="form_label mb-0">
                                    Floating Crane Vendor
                                  </label>
                                  <Autocomplete
                                    multiple
                                    options={this.state.vendorsAsOther}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(event, value) => {
                                      if (value) {
                                        var data = [];
                                        for (var i = 0; i < value.length; i++) {
                                          data.push(value[i].value);
                                        }
                                        var floating_crane_vendor =
                                          data.toString();
                                        this.setState({
                                          floating_crane_vendor:
                                            floating_crane_vendor,
                                          floating_crane_vendor_value: value,
                                        });
                                      } else {
                                        this.setState({
                                          floating_crane_vendor: "",
                                          floating_crane_vendor_value: "",
                                        });
                                      }
                                    }}
                                    name="vendorName"
                                    size="small"
                                    defaultValue={this.state.vendorsAsOther.filter(
                                      (e, idx) =>
                                        this.state.floating_crane_vendor_array.find(
                                          (v, index) => v === e.value
                                        )
                                    )}
                                    disableCloseOnSelect
                                    renderOption={(option, { selected }) => (
                                      <React.Fragment>
                                        <CheckBox
                                          style={{ marginRight: 8 }}
                                          checked={selected}
                                        />
                                        {option.name}
                                      </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="Search Floating Crane Vendor"
                                        variant="outlined"
                                        error={this.state.vendorTypeError}
                                        fullWidth
                                      />
                                    )}
                                    style={{ display: "contents" }}
                                  />
                                </div>
                              </div>
                            </div>
                          </span>
                        )}
                        <div className="row mb-0 mt-2">
                          <div className="col-lg-6 p-0">
                            <div className="row mb-2">
                              <label className="form_label mb-0">
                                Loading Anchorage
                              </label>
                              <TextField
                                name="loading_Anchorage"
                                placeholder="Loading Anchorage"
                                margin="dense"
                                variant="outlined"
                                value={this.state.loading_Anchorage}
                                onChange={this.handleText}
                                inputProps={{
                                  style: { textTransform: "uppercase" },
                                }}
                                fullWidth
                                InputProps={{
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                  },
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 pr-0">
                            <div className="row mb-2">
                              <label className="form_label mb-0">
                                Load Port Agent
                              </label>
                              <TextField
                                name="loadingPort_agent"
                                placeholder="Load Port Agent"
                                margin="dense"
                                variant="outlined"
                                value={this.state.loadingPort_agent}
                                onChange={this.handleText}
                                inputProps={{
                                  style: { textTransform: "uppercase" },
                                }}
                                fullWidth
                                InputProps={{
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                  },
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="row mb-0">
                      <div className="col-lg-6 p-0">
                        <div className="row mb-2">
                          <label className="form_label mb-0">
                            Load Rate per MT/Day
                          </label>
                          <TextField
                            name="loading_rate"
                            placeholder="Load Rate per MT/Day"
                            margin="dense"
                            variant="outlined"
                            value={this.state.loading_rate}
                            onChange={this.handleDecimalField}
                            fullWidth
                            InputProps={{
                              style: {
                                fontFamily: "Poppins",
                                fontSize: "14px",
                              },
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 pr-0">
                        <div className="row mb-2">
                          <label className="form_label mb-0">
                            Vessel Freight
                          </label>
                          <TextField
                            name="vessel_freight"
                            placeholder="Vessel Freight"
                            margin="dense"
                            variant="outlined"
                            value={this.state.vessel_freight}
                            onChange={this.handleDecimalField}
                            fullWidth
                            InputProps={{
                              style: {
                                fontFamily: "Poppins",
                                fontSize: "14px",
                              },
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    {(this.state.shipmentType === "CFR MV" ||
                      this.state.shipmentType === "CIF MV") && (
                      <div className="row mb-0">
                        <div className="col-lg-6 p-0">
                          <div className="mb-2">
                            <label className="form_label mb-2">
                              Vessel Owner
                            </label>
                            <Autocomplete
                              options={this.state.vesselOwner}
                              getOptionLabel={(option) => option.name}
                              onChange={(event, value) => {
                                value != null
                                  ? this.setState({ vessel_owner: value.value })
                                  : this.setState({ vessel_owner: "" });
                              }}
                              name="VesselOwner"
                              size="small"
                              value={this.state.vessel_owner_selected}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Search Vessel Owner"
                                  variant="outlined"
                                  fullWidth
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 pr-0">
                          <div className="row mb-2">
                            <label className="form_label mb-0">
                              Vessel Freight Price
                            </label>
                            <TextField
                              name="vessel_freight_price"
                              placeholder="Vessel Freight Price"
                              margin="dense"
                              variant="outlined"
                              value={this.state.vessel_freight_price}
                              onChange={(e) => {
                                const re = /^\d*(\.\d{0,4})?$/;
                                if (
                                  e.target.value === "" ||
                                  re.test(e.target.value)
                                ) {
                                  this.setState({
                                    vessel_freight_price: e.target.value,
                                  });
                                }
                              }}
                              fullWidth
                              InputProps={{
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="row mb-0">
                      <div className="col-lg-6 p-0">
                        <div className="row mb-2">
                          <label className="form_label mb-0">
                            Vessel Arrived
                          </label>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DateTimePicker
                              autoOk
                              hideTabs
                              inputVariant="outlined"
                              variant="inline"
                              margin="dense"
                              fullWidth
                              ampm={false}
                              format="dd/MM/yyyy HH:mm"
                              value={this.state.vessel_arrived_on}
                              onChange={(date) => {
                                this.setState({
                                  vessel_arrived_on: dateFormateToDB(date),
                                });
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <IconButton>
                                      <EventIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                                className: "pl-0",
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                      </div>
                      <div className="col-lg-6 pr-0">
                        <div className="row mb-2">
                          <label className="form_label mb-0">ETA / NOR</label>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DateTimePicker
                              autoOk
                              hideTabs
                              inputVariant="outlined"
                              variant="inline"
                              margin="dense"
                              fullWidth
                              ampm={false}
                              format="dd/MM/yyyy HH:mm"
                              value={this.state.ETA}
                              onChange={(date) => this.ETADateHandler(date)}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <IconButton>
                                      <EventIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                                className: "pl-0",
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                      </div>
                    </div>

                    <div className="row mb-0">
                      <div className="col-lg-6 p-0">
                        <div className="row mb-2">
                          <label className="form_label mb-0">
                            NOR Acceptance
                          </label>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DateTimePicker
                              autoOk
                              hideTabs
                              inputVariant="outlined"
                              variant="inline"
                              margin="dense"
                              fullWidth
                              ampm={false}
                              format="dd/MM/yyyy HH:mm"
                              value={this.state.nor_acceptance}
                              onChange={(date) => {
                                this.setState({
                                  nor_acceptance: dateFormateToDB(date),
                                });
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <IconButton>
                                      <EventIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                                className: "pl-0",
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                      </div>
                      <div className="col-lg-6 pr-0">
                        <div className="row mb-2">
                          <label className="form_label mb-0">
                            Documents on-board
                          </label>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DateTimePicker
                              autoOk
                              hideTabs
                              inputVariant="outlined"
                              variant="inline"
                              margin="dense"
                              fullWidth
                              ampm={false}
                              format="dd/MM/yyyy HH:mm"
                              value={this.state.Documents_boardDate}
                              onChange={(date) =>
                                this.Documentson_boardDateHandler(date)
                              }
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <IconButton>
                                      <EventIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                                className: "pl-0",
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                      </div>
                    </div>

                    <div className="row mb-0">
                      <div className="col-lg-6 p-0">
                        <div className="row mb-2">
                          <label className="form_label mb-0">
                            Vessel Sailing
                          </label>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DateTimePicker
                              autoOk
                              hideTabs
                              inputVariant="outlined"
                              variant="inline"
                              margin="dense"
                              fullWidth
                              ampm={false}
                              format="dd/MM/yyyy HH:mm"
                              value={this.state.vessel_sailingDate}
                              onChange={(date) =>
                                this.Vessel_SailingDateHandler(date)
                              }
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <IconButton>
                                      <EventIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                                className: "pl-0",
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                      </div>
                      <div className="col-lg-3 pr-0">
                        <div className="row mt-0">
                          <label className="form_label mb-0">
                            Demurrage Rate from Buyer
                          </label>
                          <TextField
                            name="demurrage_rate_from_buyer"
                            placeholder="Demurrage Rate from Buyer"
                            margin="dense"
                            variant="outlined"
                            value={this.state.demurrage_rate_from_buyer}
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                            onChange={(e) => {
                              this.setState({
                                demurrage_rate_from_buyer: e.target.value,
                              });
                            }}
                            fullWidth
                            InputProps={{
                              style: {
                                fontFamily: "Poppins",
                                fontSize: "14px",
                              },
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 pr-0 pl-1">
                        <div className="row mt-0">
                          <label className="form_label mb-0">
                            Demurrage Rate to Supplier
                          </label>
                          <TextField
                            name="demurrage_rate_to_supplier"
                            placeholder="Demurrage Rate to Supplier"
                            margin="dense"
                            variant="outlined"
                            value={this.state.demurrage_rate_to_supplier}
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                            onChange={(e) => {
                              this.setState({
                                demurrage_rate_to_supplier: e.target.value,
                              });
                            }}
                            fullWidth
                            InputProps={{
                              style: {
                                fontFamily: "Poppins",
                                fontSize: "14px",
                              },
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mb-0">
                      <div className="col-lg-6 p-0">
                        <div className="row mb-2">
                          <label className="form_label mb-0">
                            Stowage Plan in MT
                          </label>
                          <TextField
                            name="stowagePlan"
                            placeholder="Stowage Plan in MT"
                            margin="dense"
                            variant="outlined"
                            value={this.state.stowagePlan}
                            onChange={this.handleDecimalField}
                            fullWidth
                            InputProps={{
                              style: {
                                fontFamily: "Poppins",
                                fontSize: "14px",
                              },
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 pr-0">
                        <div className="row mb-2">
                          <label className="form_label mb-0">
                            Supply Tonnage in MT
                          </label>
                          <TextField
                            name="supplyTonnage"
                            placeholder="Supply Tonnage in MT"
                            margin="dense"
                            variant="outlined"
                            value={this.state.supplyTonnage}
                            onChange={this.handleDecimalField}
                            fullWidth
                            InputProps={{
                              style: {
                                fontFamily: "Poppins",
                                fontSize: "14px",
                              },
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mb-0">
                      <div className="col-lg-12 p-0 mb-2">
                        <div className="row">
                          <label className="form_label mb-0">Remarks</label>
                          <TextField
                            name="remarks"
                            placeholder="Remarks"
                            margin="dense"
                            multiline
                            rows={3}
                            variant="outlined"
                            value={this.state.remarks}
                            onChange={this.handleText}
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                            fullWidth
                            InputProps={{
                              style: {
                                fontFamily: "Poppins",
                                fontSize: "14px",
                              },
                            }}
                          />
                        </div>
                        <div>
                          <span
                            style={{
                              fontSize: 14,
                              color: "#93bce0",
                              float: "right",
                              border: "#93bce0 0.5px solid",
                              padding: "0 5px",
                            }}
                          >
                            {this.state.textAreaMaxCount -
                              this.state.remarks.length}
                          </span>
                          <span
                            style={{
                              fontSize: 14,
                              color: "#93bce0",
                              float: "right",
                              marginRight: 5,
                            }}
                          >
                            Remaining characters{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {this.state.activeStep === 2 && (
                  <div className="section_block">
                    <div className="shadow-sm mb-2 border">
                      <h5 className="bg-light mb-0 p-2 section_header">
                        Shipment Details
                      </h5>
                      <div className="row col-lg-12 p-2">
                        <div className="col-lg-4">
                          <span className="contract_display_header_label">
                            Business Type
                          </span>
                          <span className="contract_display_header_value">
                            {this.state.businessType}
                          </span>
                        </div>
                        <div className="col-lg-4">
                          <span className="contract_display_header_label">
                            Consignment Type
                          </span>
                          <span className="contract_display_header_value">
                            {this.state.consignmentType}
                          </span>
                        </div>
                        {this.state.consignmentType === "Barge" ? (
                          <div className="col-lg-4">
                            <span className="contract_display_header_label">
                              Barge Name
                            </span>
                            <span
                              className="contract_display_header_value"
                              style={{ textTransform: "uppercase" }}
                            >
                              {this.state.bargeName}
                            </span>
                          </div>
                        ) : (
                          <div className="col-lg-4">
                            <span className="contract_display_header_label">
                              Mother Vessel Name
                            </span>
                            <span
                              className="contract_display_header_value"
                              style={{ textTransform: "uppercase" }}
                            >
                              {this.state.motherVesselName}
                            </span>
                          </div>
                        )}

                        <div className="col-lg-4 mt-2">
                          <span className="contract_display_header_label">
                            Shipment Type
                          </span>
                          <span className="contract_display_header_value">
                            {this.state.shipmentType}
                          </span>
                        </div>
                        <div className="col-lg-4 mt-2">
                          <span className="contract_display_header_label">
                            Buy Back Involved
                          </span>
                          <span className="contract_display_header_value">
                            {this.state.buy_back_involved}
                          </span>
                        </div>
                        <div className="col-lg-4 mt-2">
                          <span className="contract_display_header_label">
                            Business Flow
                          </span>
                          <span className="contract_display_header_value">
                            {this.state.businessFlow}
                          </span>
                        </div>
                        <div className="col-lg-4 mt-2">
                          <span className="contract_display_header_label">
                            Supplier Referene No
                          </span>
                          <span className="contract_display_header_value">
                            {this.state.supplierReferenceNumber}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="shadow-sm mb-2 border">
                      <h5 className="bg-light mb-0 p-2 section_header">
                        Load Details
                      </h5>

                      {this.state.consignmentType === "Mother Vessel" && (
                        <div>
                          <div className="row mb-0 pl-2">
                            <div className="col-lg-4 p-0">
                              <span className="contract_display_header_label">
                                Vessel Type
                              </span>
                              <span className="contract_display_header_value">
                                {this.state.vessel_Type === "0"
                                  ? "-"
                                  : this.state.vessel_Type}
                              </span>
                            </div>
                            <div className="col-lg-4 pr-0">
                              <span className="contract_display_header_label">
                                Floating Crane Name
                              </span>
                              <span
                                className="contract_display_header_value"
                                style={{ textTransform: "uppercase" }}
                              >
                                {this.state.floatingCrane_name === ""
                                  ? "-"
                                  : this.state.floatingCrane_name}
                              </span>
                            </div>
                          </div>
                          {this.state.shipmentType !== "FAS MV" && (
                            <span>
                              <div className="row mb-0 mt-1 pl-2">
                                <div className="col-lg-4 p-0">
                                  <span className="contract_display_header_label">
                                    Stevedore Vendor
                                  </span>
                                  <span
                                    className="contract_display_header_value"
                                    style={{ textTransform: "uppercase" }}
                                  >
                                    {this.state.stevedore_values === ""
                                      ? "-"
                                      : this.state.stevedore_values}
                                  </span>
                                </div>
                                <div className="col-lg-4 pr-0">
                                  <span className="contract_display_header_label">
                                    Floating Crane Vendor
                                  </span>
                                  <span
                                    className="contract_display_header_value"
                                    style={{ textTransform: "uppercase" }}
                                  >
                                    {this.state.floating_crane_vendor_values ===
                                    ""
                                      ? "-"
                                      : this.state.floating_crane_vendor_values}
                                  </span>
                                </div>
                              </div>
                            </span>
                          )}
                          <div className="row mb-0 mt-1 pl-2">
                            <div className="col-lg-4 p-0">
                              <span className="contract_display_header_label">
                                Loading Anchorage
                              </span>
                              <span
                                className="contract_display_header_value"
                                style={{ textTransform: "uppercase" }}
                              >
                                {this.state.loading_Anchorage === ""
                                  ? "-"
                                  : this.state.loading_Anchorage}
                              </span>
                            </div>
                            <div className="col-lg-4 pr-0">
                              <span className="contract_display_header_label">
                                Load Port Agent
                              </span>
                              <span
                                className="contract_display_header_value"
                                style={{ textTransform: "uppercase" }}
                              >
                                {this.state.loadingPort_agent === ""
                                  ? "-"
                                  : this.state.loadingPort_agent}
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="row mb-0 mt-1 pl-2">
                        <div className="col-lg-4 p-0">
                          <span className="contract_display_header_label">
                            Load Rate per MT/Day
                          </span>
                          <span
                            className="contract_display_header_value"
                            style={{ textTransform: "uppercase" }}
                          >
                            {this.state.loading_rate === ""
                              ? "-"
                              : this.state.loading_rate}
                          </span>
                        </div>
                        <div className="col-lg-4 pr-0">
                          <span className="contract_display_header_label">
                            Vessel Freight
                          </span>
                          <span
                            className="contract_display_header_value"
                            style={{ textTransform: "uppercase" }}
                          >
                            {this.state.vessel_freight === ""
                              ? "-"
                              : this.state.vessel_freight}
                          </span>
                        </div>
                      </div>
                      {(this.state.shipmentType === "CFR MV" ||
                        this.state.shipmentType === "CIF MV") && (
                        <div className="row mb-0 mt-1 pl-2">
                          <div className="col-lg-4 p-0">
                            <span className="contract_display_header_label">
                              Vessel Owner
                            </span>
                            <span
                              className="contract_display_header_value"
                              style={{ textTransform: "uppercase" }}
                            >
                              {this.state.vessel_owner_value === ""
                                ? "-"
                                : this.state.vessel_owner_value}
                            </span>
                          </div>
                          <div className="col-lg-4 pr-0">
                            <span className="contract_display_header_label">
                              Vessel Freight Price
                            </span>
                            <span
                              className="contract_display_header_value"
                              style={{ textTransform: "uppercase" }}
                            >
                              {this.state.vessel_freight_price === ""
                                ? "-"
                                : this.state.vessel_freight_price}
                            </span>
                          </div>
                        </div>
                      )}

                      <div className="row mb-0 mt-1 pl-2">
                        <div className="col-lg-4 p-0">
                          <span className="contract_display_header_label">
                            Vessel Arrived
                          </span>
                          <span
                            className="contract_display_header_value"
                            style={{ textTransform: "uppercase" }}
                          >
                            {localDateFormate(this.state.vessel_arrived_on)}
                          </span>
                        </div>
                        <div className="col-lg-4 pr-0">
                          <span className="contract_display_header_label">
                            ETA / NOR
                          </span>
                          <span
                            className="contract_display_header_value"
                            style={{ textTransform: "uppercase" }}
                          >
                            {localDateFormate(this.state.ETA)}
                          </span>
                        </div>
                      </div>

                      <div className="row mb-0 mt-1 pl-2">
                        <div className="col-lg-4 p-0">
                          <span className="contract_display_header_label">
                            NOR Acceptance
                          </span>
                          <span
                            className="contract_display_header_value"
                            style={{ textTransform: "uppercase" }}
                          >
                            {localDateFormate(this.state.nor_acceptance)}
                          </span>
                        </div>
                        <div className="col-lg-4 pr-0">
                          <span className="contract_display_header_label">
                            Documents on-board
                          </span>
                          <span
                            className="contract_display_header_value"
                            style={{ textTransform: "uppercase" }}
                          >
                            {localDateFormate(this.state.Documents_boardDate)}
                          </span>
                        </div>
                      </div>

                      <div className="row mb-0 mt-1 pl-2">
                        <div className="col-lg-4 p-0">
                          <span className="contract_display_header_label">
                            Vessel Sailing
                          </span>
                          <span
                            className="contract_display_header_value"
                            style={{ textTransform: "uppercase" }}
                          >
                            {localDateFormate(this.state.vessel_sailingDate)}
                          </span>
                        </div>
                        <div className="col-lg-4 pr-0">
                          <span className="contract_display_header_label">
                            Demurrage Rate from Buyer
                          </span>
                          <span
                            className="contract_display_header_value"
                            style={{ textTransform: "uppercase" }}
                          >
                            {this.state.demurrage_rate_from_buyer === ""
                              ? "-"
                              : this.state.demurrage_rate_from_buyer}
                          </span>
                        </div>
                        <div className="col-lg-4 pr-0">
                          <span className="contract_display_header_label">
                            Demurrage Rate to Supplier
                          </span>
                          <span
                            className="contract_display_header_value"
                            style={{ textTransform: "uppercase" }}
                          >
                            {this.state.demurrage_rate_to_supplier === ""
                              ? "-"
                              : this.state.demurrage_rate_to_supplier}
                          </span>
                        </div>
                      </div>

                      <div className="row mb-0 mt-1 pl-2">
                        <div className="col-lg-4 p-0">
                          <span className="contract_display_header_label">
                            Stowage Plan in MT
                          </span>
                          <span
                            className="contract_display_header_value"
                            style={{ textTransform: "uppercase" }}
                          >
                            {this.state.stowagePlan === ""
                              ? "-"
                              : this.state.stowagePlan}
                          </span>
                        </div>
                        <div className="col-lg-4 pr-0">
                          <span className="contract_display_header_label">
                            Supply Tonnage in MT
                          </span>
                          <span
                            className="contract_display_header_value"
                            style={{ textTransform: "uppercase" }}
                          >
                            {this.state.supplyTonnage === ""
                              ? "-"
                              : this.state.supplyTonnage}
                          </span>
                        </div>
                      </div>

                      <div className="row mb-0 mt-1 pl-2">
                        <div className="col-lg-12 p-0">
                          <span className="contract_display_header_label">
                            Remarks
                          </span>
                          <span
                            className="contract_display_header_value"
                            style={{
                              textTransform: "uppercase",
                              whiteSpace: "pre-line",
                            }}
                          >
                            {this.state.remarks === ""
                              ? "-"
                              : this.state.remarks}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {this.state.activeStep === 0 && (
                <div className="row bottom_buttons_section">
                  <button
                    type="button"
                    className="btn previous_button"
                    name="submit"
                    onClick={() =>
                      (window.location.href = "/view-business/" + businessNo)
                    }
                  >
                    BACK
                  </button>
                  <button
                    type="button"
                    className="btn next_button"
                    name="Back"
                    onClick={this.activeStepOne}
                  >
                    NEXT STEP
                  </button>
                </div>
              )}
              {this.state.activeStep === 1 && (
                <div className="row bottom_buttons_section">
                  <button
                    type="button"
                    className="btn previous_button"
                    name="submit"
                    onClick={() => this.setState({ activeStep: 0 })}
                  >
                    PREVIOUS
                  </button>
                  <button
                    type="button"
                    className="btn next_button"
                    name="Back"
                    onClick={() => this.setState({ activeStep: 2 })}
                  >
                    NEXT STEP
                  </button>
                </div>
              )}
              {this.state.activeStep === 2 && (
                <div className="row bottom_buttons_section">
                  <button
                    type="button"
                    className="btn previous_button"
                    name="submit"
                    onClick={() => this.setState({ activeStep: 1 })}
                  >
                    PREVIOUS
                  </button>
                  <button
                    type="button"
                    className="btn next_button"
                    name="Back"
                    onClick={this.submitHandler}
                  >
                    SUBMIT
                  </button>
                </div>
              )}
            </form>
          )}
        </div>
      </div>
    );
  }
}
