import React from "react";

import CookieHandler from "../../common/CookieHandler";
import api from "../../../api/api";
import config from "../../../config/config";
import Header from "../../common/Header";
import SideBar from "../../common/SideBar";
import Paper from "@material-ui/core/Paper";
import MaterialTable from "material-table";

import exportToExcel from "../../Export_To_Excel";
import Loader from "../../common/Loader";

class SalesContractList extends React.Component {
  constructor() {
    super();
    this.Cookie = new CookieHandler();
    this.state = {
      sales_contracts: [],
      userRole: [],
      salesTerm: "0",
      data: [],
      pages: null,
      loading: true,
      isLoading: false,
      errorMessage: "",
      successMessage: "",
      features: [],
    };
  }

  componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    } else {
      const features = this.Cookie.getCookie("features");
      this.setState({ features: features });
      // if(!(features.includes('37')  || features.includes('97')  )) {
      //   window.location.href='/dashboard';
      // }
      const loginUserID = this.Cookie.getCookie("loginUserId");
      const idToken = this.Cookie.getIdTokenCookie();
      const status = "";
      api
        .getSalesContractList(loginUserID, idToken, status)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
                if (res.sale_contract) {
                  this.setState({
                    sales_contracts: res.sale_contract,
                    loading: false,
                  });
                } else {
                  alert(
                    "Unexpected error occured. Please contact administrator."
                  );
                }
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    }
    document.title = config.documentTitle + "Sales Contract List";
  }

  convertExcelHandler = (data, filename) => {
    var arrData = typeof data != "object" ? JSON.parse(data) : data;
    let Modified = arrData.map((obj) => {
      return {
        "Contract Name": obj.contract_no,
        "Customer Name": obj.customer_name,
        "Buyer Refernce No": obj.buyer_ref_no,
        Currency: obj.currency,
        "Contract Date": obj.contract_date_formatted,
        Laycan1: obj.laycan_1,
        Laycan2: obj.laycan_2,
        Laycan3: obj.laycan_3,
        Laycan4: obj.laycan_4,
        Laycan5: obj.laycan_5,
        "Laycan Quantity1": obj.laycan_quantity_1,
        "Laycan Quantity2": obj.laycan_quantity_2,
        "Laycan Quantity3": obj.laycan_quantity_3,
        "Laycan Quantity4": obj.laycan_quantity_4,
        "Laycan Quantity5": obj.laycan_quantity_5,
        "Index Linked": obj.is_index_linked,
        "Supplier surveyor Name": obj.supplier_surveyor_name,
        "Supplier surveyor Analysis Type": obj.supplier_surveyor_analysis_type,
        "Internal Surveyor Name": obj.internal_surveyor_name,
        "Internal Surveyor Analysis Type": obj.internal_surveyor_analysis_type,
        "Joint Surveyor Name": obj.joint_surveyor_name,
        "Joint Surveyor Analysis Type": obj.joint_surveyor_analysis_type,
        "Total Price": obj.total_price,
        Pnbp: obj.pnbp,
        "Stevedore Price": obj.stevedore_price,
        Pod: obj.pod,
        "Vessel Type": obj.vessel_type,
        "Loading Rate": obj.loading_rate,
        "Port of Dischare1": obj.port_of_discharge_1,
        "Port of Dischare2": obj.port_of_discharge_2,
        "Port of Dischare3": obj.port_of_discharge_3,
        "Port of Loading": obj.port_of_loading,
        "Payment Adjustment": obj.payment_adjustment,
        "Payment Term1": obj.payment_terms_1,
        "Payment Term2": obj.payment_terms_2,
        "Share Point Doc Link": obj.share_point_doc_link,
        Remarks: obj.remarks,
      };
    });
    exportToExcel(Modified, filename);
  };

  render() {
    return (
      <div id="wraper">
        <SideBar />
        <div className="content">
          <Header />
          <div className="clearfix content-header">
            <div className="row">
              <div className="col-sm text-left pl-0">
                <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                  Sales Contract
                  <span style={{ color: "lightgrey", margin: "0px 5px" }}>
                    |
                  </span>
                  <span style={{ color: "#959cb6", fontSize: "15px" }}>
                    {this.state.sales_contracts.length}
                  </span>
                </h4>
              </div>
              <div className="col-md text-right float-sm-left">
                {(this.state.features.includes("16") ||
                  this.state.features.includes("97")) && (
                  <a href="/add-sales-contract">
                    <button
                      className="header_button header_button_text add_button_adjustment"
                      style={{ width: "auto", marginTop: "20px" }}
                    >
                      New Coal Sales Contract
                    </button>
                  </a>
                )}
              </div>
            </div>
          </div>
          {this.state.isLoading && <Loader />}
          {!this.state.isLoading && (
            <div className="container p-3">
              <div className="col-sm card p-0">
                {this.state.errorMessage && (
                  <div className="row">
                    <div className="col text-center">
                      <p className="error-class">{this.state.errorMessage}</p>
                    </div>
                  </div>
                )}
                <Paper>
                  <MaterialTable
                    columns={[
                      {
                        title: "Contract Number",
                        field: "contract_no",
                        render: (row) => {
                          if (
                            this.state.features.includes("17") ||
                            this.state.features.includes("97")
                          ) {
                            return (
                              <a
                                style={{
                                  textDecoration: "none",
                                  color: config.themeColor,
                                }}
                                href={"/view-sales-contract/" + btoa(row.scid)}
                              >
                                {row.contract_no}
                              </a>
                            );
                          } else {
                            return <span>{row.contract_no}</span>;
                          }
                        },
                      },

                      {
                        title: "Customer",
                        field: "customer_name",
                      },
                      {
                        title: "Quality & Quantity",
                        field: "qualities",
                        render: (row) => {
                          return (
                            <span>
                              {row.qualities} - {row.quantities}
                            </span>
                          );
                        },
                        headerStyle: {
                          minWidth: 245,
                        },
                      },
                      {
                        title: "Status",
                        field: "status",
                        render: (row) => {
                          return (
                            <span>
                              {row.status === "Active" && (
                                <span className="active_status">
                                  <i
                                    className="fa fa-circle dot_align"
                                    aria-hidden="true"
                                  />
                                  {row.status}
                                </span>
                              )}
                              {row.status === "Draft" && (
                                <span className="freezed_status">
                                  <i
                                    className="fa fa-circle dot_align"
                                    aria-hidden="true"
                                  />
                                  {row.status}
                                </span>
                              )}
                              {row.status === "Closed" && (
                                <span className="completed_status">
                                  <i
                                    className="fa fa-circle dot_align"
                                    aria-hidden="true"
                                  />
                                  {row.status}
                                </span>
                              )}
                              {(row.status === "cancelled" ||
                                row.status === "Pending Approval") && (
                                <span className="cancel_status">
                                  <i
                                    className="fa fa-circle dot_align"
                                    aria-hidden="true"
                                  />
                                  {row.status}
                                </span>
                              )}
                            </span>
                          );
                        },
                        headerStyle: {
                          minWidth: 120,
                        },
                      },
                      {
                        title: "Action",
                        field: "scid",
                        sortable: false,
                        filterable: false,
                        headerStyle: {
                          minWidth: 120,
                        },
                        render: (row) => {
                          return (
                            <div
                              style={{
                                textAlign: "left",
                                marginTop: "-10px",
                                marginBottom: "-10px",
                              }}
                            >
                              {(row.status === "Draft" ||
                                row.status === "Pending Approval") && (
                                <i
                                  className="fa fa-pencil edit_icon"
                                  style={{
                                    color: config.themeColor,
                                    cursor: "pointer",
                                  }}
                                  data-toggle="modal"
                                  title="Update Sales Contract"
                                  data-placement="bottom"
                                  data-keyboard="false"
                                  onClick={() =>
                                    (window.location.href =
                                      "/update-sales-contract/" +
                                      btoa(row.scid))
                                  }
                                ></i>
                              )}
                            </div>
                          );
                        },
                        hidden: !(
                          this.state.features.includes("18") ||
                          this.state.features.includes("97")
                        ),
                      },
                    ]}
                    title=""
                    data={this.state.sales_contracts}
                    isLoading={this.state.loading}
                    style={{
                      minHeight: 200,
                      fontFamily: "Poppins",
                      overflowY: "auto",
                      boxShadow: "0 0 5px 0 rgba(0, 39, 77, 0.1)",
                    }}
                    options={{
                      paginationType: "stepped",
                      pageSize: 20,
                      pageSizeOptions: [5, 10, 20, 50, 100, 200],
                      headerStyle: {
                        backgroundColor: "#f9f9f9",
                        minWidth: 215,
                        fontFamily: "Poppins",
                        textTransform: "uppercase",
                        fontSize: "14px",
                        fontWeight: 500,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "0.86",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "rgba(72, 59, 52, 0.7)",
                      },
                      cellStyle: {
                        fontFamily: "Poppins",
                      },
                      // exportAllData: true,
                      // exportButton: true,

                      draggable: false,
                      exportFileName: "sales-contracts",
                      // tableLayout: 'fixed'
                    }}
                    actions={[
                      {
                        icon: "download",
                        tooltip: "Download",
                        isFreeAction: true,
                        onClick: (event) =>
                          this.convertExcelHandler(
                            this.state.sales_contracts,
                            "sales-contracts"
                          ),
                      },
                    ]}
                  />
                </Paper>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default SalesContractList;
