import React, { Component } from "react";
import CookieHandler from "../common/CookieHandler";
import SideBar from "../common/SideBar";
import Header from "../common/Header";
import api from "../../api/api";
import config from "../../config/config";
import { Alert } from "@material-ui/lab";

import {
  Snackbar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  InputAdornment,
  IconButton,
  TextField,
} from "@material-ui/core";
import EventIcon from "@material-ui/icons/Event";

import exportToExcel from "../Export_To_Excel";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Loader from "../common/Loader";
export default class VatInputReports extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      from_date: new Date(),
      to_date: new Date(),
      costing_types: {
        // Purchase
        barge_pur_fin_performa_coal_costing_data: [],
        barge_pur_fin_commercial_coal_costing_data: [],
        barge_costing_data: [],
        coal_costing_data: [],
        chemical_spray_data: [],
        cpc_costing_data: [],
        pre_shipment_data: [],
        export_tax_data: [],
        sac_charges_data: [],
        doc_charges_data: [],
        laparon_charges_data: [],
        barge_demurrage_to_barge_vendor_data: [],
        barge_demurrage_to_supplier_data: [],
        barge_demurrage_to_buyer_data: [],
        //sales
        sales_performa_coal_costing_data: [],
        sales_commercial_coal_costing_data: [],
        csc_costing_data: [],
        barge_demurrage_from_supplier_data: [],
        barge_demurrage_from_buyer_data: [],

        // Mother vessel purchase financials
        mv_stevedore_costing_data: [],
        mv_floating_crane_charges_data: [],
        mv_freight_costing_data: [],
        mv_sac_charges_data: [],
        mv_pre_shipment_data: [],
        mv_doc_charges_data: [],
        mv_buyer_demurrage: [],
        mv_supplier_demurrage: [],
      },
      activeTabIndex: 0,
      isLoading: true,
      features: [],
    };
  }

  addZero(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    var features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (!(features.includes("387") || features.includes("97"))) {
      window.location.href = "/dashboard";
    }
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const date = new Date();

    let month = this.addZero(date.getMonth() + 1);
    let year = date.getFullYear();
    await api
      .get_vat_input_report(loginUserID, idToken, month, year)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              //console.log(res);
              if (res.costing_types) {
                this.setState(
                  {
                    costing_types: res.costing_types,
                    isLoading: false,
                  },
                  () => {
                    this.calculateExpenses();
                  }
                );
              }
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });

    document.title = config.documentTitle + "Vat Input Summary";
  }

  toLocaleString(value, name) {
    const quantity = value ? value.toString() : "0";
    const data =
      quantity && name
        ? Number(quantity ? quantity : 0).toFixed(3)
        : Number(quantity ? quantity : 0).toFixed(2);
    if (data) {
      return Number(data).toLocaleString(undefined, {
        minimumFractionDigits: name ? 3 : 2,
      });
    } else {
      return null;
    }
  }

  calculateExpenses = () => {
    let data = this.state.costing_types;
    let expenses_avaialable = 0;
    let barge_performa_invoice_value = 0;
    let barge_performa_vat_value = 0;
    for (var i of data.barge_pur_fin_performa_coal_costing_data) {
      barge_performa_invoice_value += Number(i.invoice_value);
      barge_performa_vat_value += Number(i.vat_value);
    }
    let barge_commercial_invoice_value = 0;
    let barge_commercial_vat_value = 0;
    for (var barge_comm of data.barge_pur_fin_commercial_coal_costing_data) {
      expenses_avaialable++;
      barge_commercial_invoice_value += Number(barge_comm.invoice_value);
      barge_commercial_vat_value += Number(barge_comm.vat_value);
    }
    let barging_invoice_value = 0;
    let barging_vat_value = 0;
    for (var barging of data.barge_costing_data) {
      expenses_avaialable++;

      barging_invoice_value += Number(barging.invoice_value);
      barging_vat_value += Number(barging.vat_value);
    }
    let barge_doc_invoice_value = 0;
    let barge_doc_vat_value = 0;
    for (var barge_doc of data.doc_charges_data) {
      expenses_avaialable++;

      barge_doc_invoice_value += Number(barge_doc.invoice_value);
      barge_doc_vat_value += Number(barge_doc.vat_value);
    }

    let barge_laprn_invoice_value = 0;
    let barge_laprn_vat_value = 0;
    for (var barge_laprn of data.laparon_charges_data) {
      expenses_avaialable++;

      barge_laprn_invoice_value += Number(barge_laprn.invoice_value);
      barge_laprn_vat_value += Number(barge_laprn.vat_value);
    }

    let barge_cpc_invoice_value = 0;
    let barge_cpc_vat_value = 0;
    for (var barge_cpc of data.cpc_costing_data) {
      expenses_avaialable++;

      barge_cpc_invoice_value += Number(barge_cpc.invoice_value);
      barge_cpc_vat_value += Number(barge_cpc.vat_value);
    }
    let barge_cps_invoice_value = 0;
    let barge_cps_vat_value = 0;
    for (var barge_cps of data.cps_costing_data) {
      expenses_avaialable++;

      barge_cps_invoice_value += Number(barge_cps.invoice_value);
      barge_cps_vat_value += Number(barge_cps.vat_value);
    }
    let barge_pre_invoice_value = 0;
    let barge_pre_vat_value = 0;
    for (var barge_pre of data.pre_shipment_data) {
      expenses_avaialable++;

      barge_pre_invoice_value += Number(barge_pre.invoice_value);
      barge_pre_vat_value += Number(barge_pre.vat_value);
    }
    let barge_sac_invoice_value = 0;
    let barge_sac_vat_value = 0;
    for (var barge_sac of data.sac_charges_data) {
      barge_sac_invoice_value += Number(barge_sac.invoice_value);
      barge_sac_vat_value += Number(barge_sac.vat_value);
    }
    let barge_csc_invoice_value = 0;
    let barge_csc_vat_value = 0;
    for (var barge_csc of data.csc_costing_data) {
      expenses_avaialable++;

      barge_csc_invoice_value += Number(barge_csc.invoice_value);
      barge_csc_vat_value += Number(barge_csc.vat_value);
    }
    let dmr_to_vend_invoice_value = 0;
    let dmr_to_vend_vat_value = 0;
    for (var dmr_to_vend of data.barge_demurrage_to_barge_vendor_data) {
      expenses_avaialable++;

      dmr_to_vend_invoice_value += Number(dmr_to_vend.invoice_value);
      dmr_to_vend_vat_value += Number(dmr_to_vend.vat_value);
    }
    let dmr_to_sup_invoice_value = 0;
    let dmr_to_sup_vat_value = 0;
    for (var dmr_to_sup of data.barge_demurrage_to_supplier_data) {
      expenses_avaialable++;

      dmr_to_sup_invoice_value += Number(dmr_to_sup.invoice_value);
      dmr_to_sup_vat_value += Number(dmr_to_sup.vat_value);
    }
    let dmr_to_buyer_invoice_value = 0;
    let dmr_to_buyer_vat_value = 0;
    for (var dmr_to_buyer of data.barge_demurrage_to_buyer_data) {
      expenses_avaialable++;

      dmr_to_buyer_invoice_value += Number(dmr_to_buyer.invoice_value);
      dmr_to_buyer_vat_value += Number(dmr_to_buyer.vat_value);
    }

    let mv_stevedore_invoice_value = 0;
    let mv_stevedore_vat_value = 0;
    for (var mv_stevedore of data.mv_stevedore_costing_data) {
      expenses_avaialable++;

      mv_stevedore_invoice_value += Number(mv_stevedore.invoice_value);
      mv_stevedore_vat_value += Number(mv_stevedore.vat_value);
    }
    let mv_floating_invoice_value = 0;
    let mv_floating_vat_value = 0;
    for (var mv_floating of data.mv_floating_crane_charges_data) {
      expenses_avaialable++;

      mv_floating_invoice_value += Number(mv_floating.invoice_value);
      mv_floating_vat_value += Number(mv_floating.vat_value);
    }
    let mv_freight_invoice_value = 0;
    let mv_freight_vat_value = 0;
    for (var mv_freight of data.mv_freight_costing_data) {
      expenses_avaialable++;

      mv_freight_invoice_value += Number(mv_freight.invoice_value);
      mv_freight_vat_value += Number(mv_freight.vat_value);
    }
    let mv_cpc_invoice_value = 0;
    let mv_cpc_vat_value = 0;
    for (var mv_cpc of data.mv_pur_fin_cpc_data) {
      expenses_avaialable++;

      mv_cpc_invoice_value += Number(mv_cpc.invoice_value);
      mv_cpc_vat_value += Number(mv_cpc.vat_value);
    }
    let mv_sac_invoice_value = 0;
    let mv_sac_vat_value = 0;
    for (var mv_sac of data.mv_sac_charges_data) {
      expenses_avaialable++;

      mv_sac_invoice_value += Number(mv_sac.invoice_value);
      mv_sac_vat_value += Number(mv_sac.vat_value);
    }
    let mv_pre_invoice_value = 0;
    let mv_pre_vat_value = 0;
    for (var mv_pre of data.mv_pre_shipment_data) {
      expenses_avaialable++;

      mv_pre_invoice_value += Number(mv_pre.invoice_value);
      mv_pre_vat_value += Number(mv_pre.vat_value);
    }
    let mv_doc_invoice_value = 0;
    let mv_doc_vat_value = 0;
    for (var mv_doc of data.mv_doc_charges_data) {
      expenses_avaialable++;

      mv_doc_invoice_value += Number(mv_doc.invoice_value);
      mv_doc_vat_value += Number(mv_doc.vat_value);
    }
    let mv_csc_invoice_value = 0;
    let mv_csc_vat_value = 0;
    for (var mv_csc of data.mv_sales_fin_csc) {
      expenses_avaialable++;

      mv_csc_invoice_value += Number(mv_csc.invoice_value);
      mv_csc_vat_value += Number(mv_csc.vat_value);
    }
    let payable_services_invoice_value = 0;
    let payable_services_vat_value = 0;
    for (var payable of data.payable_services) {
      expenses_avaialable++;

      payable_services_invoice_value += Number(payable.invoice_value);
      payable_services_vat_value += Number(payable.vat_value);
    }
    let indirect_exp_invoice_value = 0;
    let indirect_exp_vat_value = 0;
    for (var in_exp of data.indirect_expenditure_cost_center_expenses) {
      expenses_avaialable++;

      indirect_exp_invoice_value += Number(in_exp.invoice_value);
      indirect_exp_vat_value += Number(in_exp.vat_value);
    }

    // Mother Vessel Demurrage
    const filter_sup_dem_despatch = data.mv_supplier_demurrage.filter(
      (e) => e.demurrage_or_despatch === "Despatch"
    );
    var mv_sup_dem_invoice_value = 0;
    var mv_sup_dem_vat_value = 0;

    for (var sup of filter_sup_dem_despatch) {
      expenses_avaialable++;

      mv_sup_dem_invoice_value += sup.invoice_value
        ? Number(sup.invoice_value)
        : 0;
      mv_sup_dem_vat_value += sup.vat_value ? Number(sup.vat_value) : 0;
    }

    const filter_buy_dem = data.mv_buyer_demurrage.filter(
      (e) => e.demurrage_or_despatch === "Demurrage"
    );

    var mv_buy_dem_invoice_value = 0;
    var mv_buy_dem_vat_value = 0;
    for (var buy of filter_buy_dem) {
      expenses_avaialable++;

      mv_buy_dem_invoice_value += buy.invoice_value
        ? Number(buy.invoice_value)
        : 0;
      mv_buy_dem_vat_value += buy.vat_value ? Number(buy.vat_value) : 0;
    }

    var total_expense_invoice_value = Number(
      barge_performa_invoice_value +
        barge_commercial_invoice_value +
        barging_invoice_value +
        barge_doc_invoice_value +
        +barge_laprn_invoice_value +
        barge_cpc_invoice_value +
        barge_cps_invoice_value +
        barge_pre_invoice_value +
        barge_sac_invoice_value +
        barge_csc_invoice_value +
        dmr_to_vend_invoice_value +
        dmr_to_sup_invoice_value +
        dmr_to_buyer_invoice_value +
        mv_stevedore_invoice_value +
        mv_floating_invoice_value +
        mv_freight_invoice_value +
        mv_cpc_invoice_value +
        mv_sac_invoice_value +
        mv_pre_invoice_value +
        mv_doc_invoice_value +
        mv_csc_invoice_value +
        payable_services_invoice_value +
        indirect_exp_invoice_value +
        mv_sup_dem_invoice_value +
        mv_buy_dem_invoice_value
    );

    var total_expense_vat_value = Number(
      barge_performa_vat_value +
        barge_commercial_vat_value +
        barging_vat_value +
        barge_doc_vat_value +
        barge_laprn_vat_value +
        barge_cpc_vat_value +
        barge_cps_vat_value +
        barge_pre_vat_value +
        barge_sac_vat_value +
        barge_csc_vat_value +
        dmr_to_vend_vat_value +
        dmr_to_sup_vat_value +
        dmr_to_buyer_vat_value +
        mv_stevedore_vat_value +
        mv_floating_vat_value +
        mv_freight_vat_value +
        mv_cpc_vat_value +
        mv_sac_vat_value +
        mv_pre_vat_value +
        mv_doc_vat_value +
        mv_csc_vat_value +
        payable_services_vat_value +
        indirect_exp_vat_value +
        mv_sup_dem_vat_value +
        mv_buy_dem_vat_value
    );

    this.setState(
      {
        barge_performa_invoice_value: barge_performa_invoice_value,
        barge_performa_vat_value: barge_performa_vat_value,
        barge_commercial_invoice_value: barge_commercial_invoice_value,
        barge_commercial_vat_value: barge_commercial_vat_value,
        barging_invoice_value: barging_invoice_value,
        barging_vat_value: barging_vat_value,
        barge_doc_invoice_value: barge_doc_invoice_value,
        barge_doc_vat_value: barge_doc_vat_value,
        barge_laprn_invoice_value: barge_laprn_invoice_value,
        barge_laprn_vat_value: barge_laprn_vat_value,
        barge_cpc_invoice_value: barge_cpc_invoice_value,
        barge_cpc_vat_value: barge_cpc_vat_value,
        barge_cps_invoice_value: barge_cps_invoice_value,
        barge_cps_vat_value: barge_cps_vat_value,
        barge_pre_invoice_value: barge_pre_invoice_value,
        barge_pre_vat_value: barge_pre_vat_value,
        barge_sac_invoice_value: barge_sac_invoice_value,
        barge_sac_vat_value: barge_sac_vat_value,
        barge_csc_invoice_value: barge_csc_invoice_value,
        barge_csc_vat_value: barge_csc_vat_value,

        // demurrage
        dmr_to_vend_invoice_value: dmr_to_vend_invoice_value,
        dmr_to_vend_vat_value: dmr_to_vend_vat_value,
        dmr_to_sup_invoice_value: dmr_to_sup_invoice_value,
        dmr_to_sup_vat_value: dmr_to_sup_vat_value,
        dmr_to_buyer_invoice_value: dmr_to_buyer_invoice_value,
        dmr_to_buyer_vat_value: dmr_to_buyer_vat_value,

        // Mother Vessel
        mv_stevedore_invoice_value: mv_stevedore_invoice_value,
        mv_stevedore_vat_value: mv_stevedore_vat_value,
        mv_floating_invoice_value: mv_floating_invoice_value,
        mv_floating_vat_value: mv_floating_vat_value,
        mv_freight_invoice_value: mv_freight_invoice_value,
        mv_freight_vat_value: mv_freight_vat_value,
        mv_cpc_invoice_value: mv_cpc_invoice_value,
        mv_cpc_vat_value: mv_cpc_vat_value,
        mv_sac_invoice_value: mv_sac_invoice_value,
        mv_sac_vat_value: mv_sac_vat_value,
        mv_pre_invoice_value: mv_pre_invoice_value,
        mv_pre_vat_value: mv_pre_vat_value,
        mv_doc_invoice_value: mv_doc_invoice_value,
        mv_doc_vat_value: mv_doc_vat_value,
        mv_csc_invoice_value: mv_csc_invoice_value,
        mv_csc_vat_value: mv_csc_vat_value,
        payable_services_invoice_value: payable_services_invoice_value,
        payable_services_vat_value: payable_services_vat_value,
        indirect_exp_invoice_value: indirect_exp_invoice_value,
        indirect_exp_vat_value: indirect_exp_vat_value,
        // mother vessel demurrage
        mv_sup_dem_invoice_value: mv_sup_dem_invoice_value,
        mv_sup_dem_vat_value: mv_sup_dem_vat_value,
        mv_buy_dem_invoice_value: mv_buy_dem_invoice_value,
        mv_buy_dem_vat_value: mv_buy_dem_vat_value,
        total_expense_invoice_value: total_expense_invoice_value,
        total_expense_vat_value: total_expense_vat_value,
        expenses_avaialable: expenses_avaialable,
      },
      () => this.calculateIncome()
    );
  };

  calculateIncome = () => {
    var row = this.state.costing_types;
    // Sales coal Costing
    let income_avaialable = 0;
    var sales_performa_coal_cost_invoice_value = 0;
    var sales_performa_coal_cost_vat_value = 0;
    for (var scs of row.sales_performa_coal_costing_data) {
      income_avaialable++;
      sales_performa_coal_cost_invoice_value += scs.invoice_value
        ? Number(scs.invoice_value)
        : 0;
      sales_performa_coal_cost_vat_value += scs.vat_value
        ? Number(scs.vat_value)
        : 0;
    }
    var sales_com_coal_cost_invoice_value = 0;
    var sales_com_coal_cost_vat_value = 0;
    for (var sales_com of row.sales_commercial_coal_costing_data) {
      income_avaialable++;

      sales_com_coal_cost_invoice_value += sales_com.invoice_value
        ? Number(sales_com.invoice_value)
        : 0;
      sales_com_coal_cost_vat_value += sales_com.vat_value
        ? Number(sales_com.vat_value)
        : 0;
    }

    var receivable_services_invoice_value = 0;
    var receivable_services_vat_value = 0;
    for (var rec_serv of row.receivable_services) {
      income_avaialable++;

      receivable_services_invoice_value += rec_serv.invoice_value
        ? Number(rec_serv.invoice_value)
        : 0;
      receivable_services_vat_value += rec_serv.vat_value
        ? Number(rec_serv.vat_value)
        : 0;
    }

    const filter_sup_dem = row.mv_supplier_demurrage.filter(
      (e) => e.demurrage_or_despatch === "Demurrage"
    );
    var mv_sup_dem_sales_invoice_value = 0;
    var mv_sup_dem_sales_vat_value = 0;
    for (var dem_sales of filter_sup_dem) {
      income_avaialable++;

      mv_sup_dem_sales_invoice_value += dem_sales.invoice_value
        ? Number(dem_sales.invoice_value)
        : 0;
      mv_sup_dem_sales_vat_value += dem_sales.vat_value
        ? Number(dem_sales.vat_value)
        : 0;
    }

    const filter_buy_dem_sales = row.mv_buyer_demurrage.filter(
      (e) => e.demurrage_or_despatch === "Despatch"
    );
    var mv_buy_dem_sales_invoice_value = 0;
    var mv_buy_dem_sales_vat_value = 0;
    for (var buy_sales of filter_buy_dem_sales) {
      income_avaialable++;

      mv_buy_dem_sales_invoice_value += buy_sales.invoice_value
        ? Number(buy_sales.invoice_value)
        : 0;
      mv_buy_dem_sales_vat_value += buy_sales.vat_value
        ? Number(buy_sales.vat_value)
        : 0;
    }
    // barge Demurrages

    var dmr_from_supplier_invoice_value = 0;
    var dmr_from_supplier_vat_value = 0;
    var dmr_from_buyer_invoice_value = 0;
    var dmr_from_buyer_vat_value = 0;

    for (var dmr_from_supplier of row.barge_demurrage_from_supplier_data) {
      income_avaialable++;

      dmr_from_supplier_invoice_value += dmr_from_supplier.invoice_value
        ? Number(dmr_from_supplier.invoice_value)
        : 0;
      dmr_from_supplier_vat_value += dmr_from_supplier.vat_value
        ? Number(dmr_from_supplier.vat_value)
        : 0;
    }

    for (var dmr_from_buyer of row.barge_demurrage_from_buyer_data) {
      income_avaialable++;

      dmr_from_buyer_invoice_value += dmr_from_buyer.invoice_value
        ? Number(dmr_from_buyer.invoice_value)
        : 0;
      dmr_from_buyer_vat_value += dmr_from_buyer.vat_value
        ? Number(dmr_from_buyer.vat_value)
        : 0;
    }

    var sales_total_invoice_value = Number(
      sales_performa_coal_cost_invoice_value +
        sales_com_coal_cost_invoice_value +
        mv_sup_dem_sales_invoice_value +
        mv_buy_dem_sales_invoice_value +
        dmr_from_supplier_invoice_value +
        dmr_from_buyer_invoice_value +
        receivable_services_invoice_value
    );

    var sales_total_vat_value = Number(
      sales_performa_coal_cost_vat_value +
        sales_com_coal_cost_vat_value +
        mv_sup_dem_sales_vat_value +
        mv_buy_dem_sales_vat_value +
        dmr_from_supplier_vat_value +
        dmr_from_buyer_vat_value +
        receivable_services_vat_value
    );

    const total_profit_or_loss_amount =
      Number(sales_total_vat_value) -
      Number(this.state.total_expense_vat_value);
    this.setState({
      sales_performa_coal_cost_invoice_value:
        sales_performa_coal_cost_invoice_value,
      sales_com_coal_cost_invoice_value: sales_com_coal_cost_invoice_value,
      mv_sup_dem_sales_invoice_value: mv_sup_dem_sales_invoice_value,
      mv_buy_dem_sales_invoice_value: mv_buy_dem_sales_invoice_value,
      dmr_from_supplier_invoice_value: dmr_from_supplier_invoice_value,
      dmr_from_buyer_invoice_value: dmr_from_buyer_invoice_value,
      receivable_services_invoice_value: receivable_services_invoice_value,
      receivable_services_vat_value: receivable_services_vat_value,
      //
      sales_performa_coal_cost_vat_value: sales_performa_coal_cost_vat_value,
      sales_com_coal_cost_vat_value: sales_com_coal_cost_vat_value,
      mv_sup_dem_sales_vat_value: mv_sup_dem_sales_vat_value,
      mv_buy_dem_sales_vat_value: mv_buy_dem_sales_vat_value,
      dmr_from_supplier_vat_value: dmr_from_supplier_vat_value,
      dmr_from_buyer_vat_value: dmr_from_buyer_vat_value,
      sales_total_invoice_value: sales_total_invoice_value,
      sales_total_vat_value: sales_total_vat_value,
      total_profit_or_loss_amount: total_profit_or_loss_amount,
      income_avaialable: income_avaialable,
    });
  };

  getReportsHandler = () => {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();

    const month_date = new Date(this.state.from_date);
    const year_date = new Date(this.state.to_date);

    let month = this.addZero(month_date.getMonth() + 1);
    let year = year_date.getFullYear();
    api
      .get_vat_input_report(loginUserID, idToken, month, year)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              //console.log(res);
              if (res.costing_types) {
                this.setState(
                  {
                    costing_types: res.costing_types,
                    isLoading: false,
                  },
                  () => this.calculateExpenses()
                );
              }
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  };

  convertExcelHandler = (data, filename) => {
    exportToExcel(data, filename);
  };

  getLocalDate(date) {
    if (date) {
      let dateValue = date.split(" ");
      let dateString = dateValue[0].split("-");
      var newDate = dateString[2] + "-" + dateString[1] + "-" + dateString[0];
      var finalDate = newDate;
      return finalDate;
    }
  }

  dateFormat(date) {
    let dateFormat = new Date(date);
    let day = this.addZero(dateFormat.getDate());
    let month = this.addZero(dateFormat.getMonth() + 1);
    let year = dateFormat.getFullYear();
    let newDate = year + "-" + month + "-" + day;
    let finalDate = newDate;
    return finalDate;
  }

  bulkUpdateHandler = () => {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const costing_types = this.state.costing_types;
    api
      .bulk_update_ppn_value(loginUserID, idToken, costing_types)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            //console.log(res);
            if (res.code === "200") {
              this.setState(
                {
                  snackBarSuccessOpen: true,
                  successMsg: res.message,
                },
                () => this.getReportsHandler()
              );
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  };

  render() {
    var summary = this.state.costing_types;
    return (
      <div id="wraper">
        <SideBar />
        <div className="content">
          <Header />
          <div className="clearfix content-header">
            <div className="row">
              <div className="col-sm text-left pl-0">
                <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                  {" "}
                  <a href={"/reports"}>
                    <i className="fa fa-arrow-left left_arrow_adjst" />
                  </a>
                  VAT Input Summary
                </h4>
              </div>
              <div className="col-sm-3 text-right pl-0">
                <i
                  className="fa fa-file-excel-o"
                  aria-hidden="true"
                  style={{
                    position: "relative",
                    top: 0,
                    margin: 5,
                    fontSize: 30,
                    color: "#0abb87",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    this.convertExcelHandler(
                      this.state.costing_types,
                      "Profit And Loss"
                    )
                  }
                />
              </div>
            </div>
          </div>
          <div className="container p-2">
            {this.state.isLoading && <Loader />}
            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={this.state.snackBarErrorOpen}
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              style={{ width: "450px" }}
            >
              <Alert
                elevation={6}
                variant="filled"
                onClose={() => this.setState({ snackBarErrorOpen: false })}
                severity="error"
                style={{ width: "100%" }}
              >
                {this.state.errorMsg}
              </Alert>
            </Snackbar>
            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              style={{ width: "450px" }}
              open={this.state.snackBarSuccessOpen}
              autoHideDuration={1000}
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
            >
              <Alert
                elevation={6}
                variant="filled"
                onClose={() => this.setState({ snackBarSuccessOpen: false })}
                severity="success"
                style={{ width: "100%" }}
              >
                {this.state.successMsg}
              </Alert>
            </Snackbar>
            {!this.state.isLoading && (
              <div className="card">
                <div className="row mb-2">
                  <div className="col-lg-3">
                    <div className="form_label mb-0">
                      Month <span className="text-danger">*</span>
                    </div>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        disableToolbar
                        autoOk={true}
                        inputVariant="outlined"
                        variant="inline"
                        margin="dense"
                        //fullWidth
                        views={["month"]}
                        labelFunc={(date) => {
                          const monthNames = [
                            "January",
                            "February",
                            "March",
                            "April",
                            "May",
                            "June",
                            "July",
                            "August",
                            "September",
                            "October",
                            "November",
                            "December",
                          ];
                          const d = new Date(date);
                          return monthNames[d.getMonth()];
                        }}
                        format="MM"
                        value={this.state.from_date}
                        error={this.state.from_date_error}
                        onChange={(date) => {
                          this.setState({
                            from_date: date,
                            from_date_error: false,
                          });
                        }}
                        placeholder="Start Date"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton>
                                <EventIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  <div className="col-lg-3 pr-0">
                    <div className="form_label mb-0">
                      Year <span className="text-danger">*</span>
                    </div>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        disableToolbar
                        autoOk={true}
                        inputVariant="outlined"
                        variant="inline"
                        margin="dense"
                        //fullWidth
                        views={["year"]}
                        format="yyyy"
                        value={this.state.to_date}
                        error={this.state.to_date_error}
                        onChange={(date) => {
                          this.setState({
                            to_date: date,
                            to_date_error: false,
                          });
                        }}
                        placeholder="To Date"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton>
                                <EventIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>

                  <div className="col-lg-1">
                    <button
                      type="button"
                      className="header_button header_button_text"
                      style={{ width: "auto", marginTop: "30px" }}
                      onClick={this.getReportsHandler}
                      disabled={this.state.submitting}
                    >
                      <span
                        className={
                          this.state.submitting
                            ? "spinner-grow spinner-grow-sm mr-2"
                            : ""
                        }
                      ></span>
                      Submit
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 m-auto pl-3 pr-3 border-bottom">
                    <Button
                      className="tab_button"
                      onClick={(e, value) => {
                        this.setState({
                          activeTabIndex: 0,
                        });
                      }}
                      style={{
                        textTransform: "capitalize",
                        margin: "5px 0px",
                        padding: "5px 20px",
                        borderRadius: 35,
                        height: 30,
                        color:
                          this.state.activeTabIndex === 0
                            ? config.themeColor
                            : "#483b34b3",
                        backgroundColor:
                          this.state.activeTabIndex === 0 ? "#fff7f2" : "#fff",
                        fontSize: "14px",
                        border: "0px",
                      }}
                    >
                      Income
                    </Button>
                    <Button
                      className="tab_button"
                      onClick={(e, value) => {
                        this.setState({
                          activeTabIndex: 1,
                        });
                      }}
                      style={{
                        textTransform: "capitalize",
                        margin: "5px 0px",
                        padding: "5px 20px",
                        borderRadius: 35,
                        height: 30,
                        color:
                          this.state.activeTabIndex === 1
                            ? config.themeColor
                            : "#483b34b3",
                        backgroundColor:
                          this.state.activeTabIndex === 1 ? "#fff7f2" : "#fff",
                        fontSize: "14px",
                        border: "0px",
                      }}
                    >
                      Expenses
                    </Button>

                    <Button
                      className="tab_button"
                      onClick={(e, value) => {
                        this.setState({
                          activeTabIndex: 2,
                        });
                      }}
                      style={{
                        textTransform: "capitalize",
                        margin: "5px 0px",
                        padding: "5px 20px",
                        borderRadius: 35,
                        height: 30,
                        color:
                          this.state.activeTabIndex === 2
                            ? config.themeColor
                            : "#483b34b3",
                        backgroundColor:
                          this.state.activeTabIndex === 2 ? "#fff7f2" : "#fff",
                        fontSize: "14px",
                        border: "0px",
                      }}
                    >
                      Summary
                    </Button>
                  </div>
                </div>
                {this.state.activeTabIndex === 0 && (
                  <div className="row mt-2">
                    <div className="col-lg m-auto ">
                      <TableContainer style={{ maxHeight: 500 }}>
                        <Table
                          size="small"
                          component={Paper}
                          stickyHeader
                          aria-label="sticky table"
                        >
                          <TableHead className="bg-light">
                            <TableRow>
                              <TableCell
                                nowrap="true"
                                style={{
                                  position: "sticky",
                                  top: 0,
                                  fontSize: 10,
                                }}
                                className="text-uppercase"
                              >
                                Customer
                              </TableCell>
                              <TableCell
                                nowrap="true"
                                style={{
                                  position: "sticky",
                                  top: 0,
                                  fontSize: 10,
                                }}
                                className="text-uppercase"
                              >
                                NPWP NO
                              </TableCell>
                              <TableCell
                                nowrap="true"
                                style={{
                                  position: "sticky",
                                  top: 0,
                                  fontSize: 10,
                                }}
                                className="text-uppercase"
                              >
                                Costing Type
                              </TableCell>
                              <TableCell
                                nowrap="true"
                                style={{
                                  position: "sticky",
                                  top: 0,
                                  fontSize: 10,
                                }}
                                className="text-uppercase"
                              >
                                Invoice No
                              </TableCell>
                              <TableCell
                                nowrap="true"
                                style={{
                                  position: "sticky",
                                  top: 0,
                                  fontSize: 10,
                                }}
                                className="text-uppercase"
                              >
                                Invoice Date
                              </TableCell>
                              <TableCell
                                nowrap="true"
                                style={{
                                  position: "sticky",
                                  top: 0,
                                  fontSize: 10,
                                }}
                                className="text-uppercase text-right"
                              >
                                Invoice Value
                              </TableCell>
                              <TableCell
                                nowrap="true"
                                style={{
                                  position: "sticky",
                                  top: 0,
                                  fontSize: 10,
                                }}
                                className="text-uppercase text-right"
                              >
                                VAT Value
                              </TableCell>
                              <TableCell
                                nowrap="true"
                                style={{
                                  position: "sticky",
                                  top: 0,
                                  fontSize: 10,
                                }}
                                className="text-uppercase"
                              >
                                Fakthur Pazak No
                              </TableCell>
                              <TableCell
                                nowrap="true"
                                style={{
                                  position: "sticky",
                                  top: 0,
                                  fontSize: 10,
                                  minWidth: 200,
                                }}
                                className="text-uppercase"
                              >
                                Fakthur Pazak Date
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          {/**
                           *
                           * ========================================= COAL SALES START'S ============================
                           *
                           */}
                          {summary.sales_performa_coal_costing_data.length >
                            0 && (
                            <TableBody>
                              {/* <TableRow><TableCell colSpan={8} style={{ color: config.themeColor }}>Coal Sales Performa</TableCell></TableRow> */}
                              {summary.sales_performa_coal_costing_data.map(
                                (e, idx) => (
                                  <TableRow key={idx}>
                                    <TableCell
                                      nowrap="true"
                                      className="text-uppercase"
                                    >
                                      {e.buyer_name ? e.buyer_name : "-"}
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      className="text-uppercase"
                                    >
                                      {e.tax_id ? e.tax_id : "-"}
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      className="text-uppercase"
                                    >
                                      Sales Performa
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      className="text-uppercase"
                                    >
                                      {e.invoice_no ? e.invoice_no : "-"}
                                    </TableCell>
                                    <TableCell>
                                      {e.invoice_date
                                        ? this.getLocalDate(e.invoice_date)
                                        : "-"}
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      className="text-right"
                                    >
                                      {this.toLocaleString(e.invoice_value)}
                                    </TableCell>
                                    <TableCell className="text-right">
                                      {this.toLocaleString(e.vat_value)}
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      className="text-uppercase"
                                    >
                                      {e.ppn_editable || e.ppn === null ? (
                                        <TextField
                                          name="ppn"
                                          margin="dense"
                                          variant="outlined"
                                          //fullWidth
                                          onChange={(e) => {
                                            let row = this.state.costing_types;
                                            let rows =
                                              row.sales_performa_coal_costing_data;
                                            rows[idx].ppn = e.target.value;
                                            rows[idx].ppn_editable = true;

                                            this.setState({
                                              costing_types: row,
                                            });
                                          }}
                                          value={e.ppn}
                                          placeholder="Fakthur Pazak No"
                                          InputProps={{
                                            style: {
                                              textTransform: "uppercase",
                                            },
                                          }}
                                        />
                                      ) : (
                                        <div>
                                          {e.ppn}
                                          <i
                                            className="fa fa-pencil edit_icon text-right"
                                            style={{ float: "right" }}
                                            onClick={() => {
                                              let row =
                                                this.state.costing_types;
                                              let rows =
                                                row.sales_performa_coal_costing_data;
                                              rows[idx].ppn_editable = true;
                                              this.setState({
                                                costing_types: row,
                                              });
                                            }}
                                          />
                                        </div>
                                      )}
                                    </TableCell>
                                    <TableCell>
                                      <MuiPickersUtilsProvider
                                        utils={DateFnsUtils}
                                      >
                                        <DatePicker
                                          autoOk={true}
                                          inputVariant="outlined"
                                          variant="inline"
                                          margin="dense"
                                          fullWidth
                                          format="dd/MM/yyyy"
                                          value={e.ppn_date}
                                          error={
                                            this.state.date_of_paymentError
                                          }
                                          onChange={(date) => {
                                            let row = this.state.costing_types;
                                            let rows =
                                              row.sales_performa_coal_costing_data;
                                            rows[idx].ppn_date =
                                              this.dateFormat(date);
                                            this.setState({
                                              stevedore_costing: row,
                                            });
                                          }}
                                          InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <IconButton>
                                                  <EventIcon />
                                                </IconButton>
                                              </InputAdornment>
                                            ),
                                            className: "pl-0",
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </TableCell>
                                  </TableRow>
                                )
                              )}
                              <TableRow>
                                <TableCell colSpan={5}></TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.toLocaleString(
                                    this.state
                                      .sales_performa_coal_cost_invoice_value
                                  )}
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.toLocaleString(
                                    this.state
                                      .sales_performa_coal_cost_vat_value
                                  )}
                                </TableCell>
                                <TableCell colSpan={2} />
                              </TableRow>
                            </TableBody>
                          )}
                          {summary.sales_commercial_coal_costing_data.length >
                            0 && (
                            <TableBody>
                              {/* <TableRow><TableCell colSpan={8} style={{ color: config.themeColor }}>Coal Sales Commercial</TableCell></TableRow> */}
                              {summary.sales_commercial_coal_costing_data.map(
                                (e, idx) => (
                                  <TableRow key={idx}>
                                    <TableCell
                                      nowrap="true"
                                      className="text-uppercase"
                                    >
                                      {e.buyer_name ? e.buyer_name : "-"}
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      className="text-uppercase"
                                    >
                                      {e.tax_id ? e.tax_id : "-"}
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      className="text-uppercase"
                                    >
                                      Sales Commercial
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      className="text-uppercase"
                                    >
                                      {e.invoice_no ? e.invoice_no : "-"}
                                    </TableCell>
                                    <TableCell className="text-uppercase">
                                      {e.invoice_date
                                        ? this.getLocalDate(e.invoice_date)
                                        : "-"}
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      className="text-right"
                                    >
                                      {this.toLocaleString(e.invoice_value)}
                                    </TableCell>
                                    <TableCell className="text-right">
                                      {this.toLocaleString(e.vat_value)}
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      style={{ minWidth: 160 }}
                                      className="text-uppercase"
                                    >
                                      {e.ppn_editable || e.ppn === null ? (
                                        <div className="row">
                                          <TextField
                                            name="ppn"
                                            margin="dense"
                                            variant="outlined"
                                            //fullWidth
                                            onChange={(e) => {
                                              let row =
                                                this.state.costing_types;
                                              let rows =
                                                row.sales_commercial_coal_costing_data;
                                              rows[idx].ppn = e.target.value;
                                              rows[idx].ppn_editable = true;

                                              this.setState({
                                                costing_types: row,
                                              });
                                            }}
                                            value={e.ppn}
                                            placeholder="Fakthur Pazak No"
                                            InputProps={{
                                              style: {
                                                textTransform: "uppercase",
                                              },
                                            }}
                                          />
                                        </div>
                                      ) : (
                                        <div>
                                          {e.ppn}
                                          <i
                                            className="fa fa-pencil edit_icon text-right"
                                            style={{ float: "right" }}
                                            onClick={() => {
                                              let row =
                                                this.state.costing_types;
                                              let rows =
                                                row.sales_commercial_coal_costing_data;
                                              rows[idx].ppn_editable = true;
                                              this.setState({
                                                costing_types: row,
                                              });
                                            }}
                                          />
                                        </div>
                                      )}
                                    </TableCell>
                                    <TableCell>
                                      <MuiPickersUtilsProvider
                                        utils={DateFnsUtils}
                                      >
                                        <DatePicker
                                          autoOk={true}
                                          inputVariant="outlined"
                                          variant="inline"
                                          margin="dense"
                                          fullWidth
                                          format="dd/MM/yyyy"
                                          value={e.ppn_date}
                                          error={
                                            this.state.date_of_paymentError
                                          }
                                          onChange={(date) => {
                                            let row = this.state.costing_types;
                                            let rows =
                                              row.sales_commercial_coal_costing_data;
                                            rows[idx].ppn_date =
                                              this.dateFormat(date);
                                            this.setState({
                                              stevedore_costing: row,
                                            });
                                          }}
                                          InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <IconButton>
                                                  <EventIcon />
                                                </IconButton>
                                              </InputAdornment>
                                            ),
                                            className: "pl-0",
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </TableCell>
                                  </TableRow>
                                )
                              )}
                              <TableRow>
                                <TableCell colSpan={5}></TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.toLocaleString(
                                    this.state.sales_com_coal_cost_invoice_value
                                  )}
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.toLocaleString(
                                    this.state.sales_com_coal_cost_vat_value
                                  )}
                                </TableCell>
                                <TableCell colSpan={2} />
                              </TableRow>
                            </TableBody>
                          )}

                          {summary.receivable_services.length > 0 && (
                            <TableBody>
                              {/* <TableRow><TableCell colSpan={8} style={{ color: config.themeColor }}>Other Services</TableCell></TableRow> */}
                              {summary.receivable_services.map((e, idx) => (
                                <TableRow key={idx}>
                                  <TableCell
                                    nowrap="true"
                                    className="text-uppercase"
                                  >
                                    {e.buyer_name ? e.buyer_name : "-"}
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    className="text-uppercase"
                                  >
                                    {e.tax_id ? e.tax_id : "-"}
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    className="text-uppercase"
                                  >
                                    Other Services
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    className="text-uppercase"
                                  >
                                    {e.invoice_no ? e.invoice_no : "-"}
                                  </TableCell>
                                  <TableCell>
                                    {e.invoice_date
                                      ? this.getLocalDate(e.invoice_date)
                                      : "-"}
                                  </TableCell>
                                  <TableCell className="text-right">
                                    {this.toLocaleString(e.invoice_value)}
                                  </TableCell>
                                  <TableCell className="text-right">
                                    {this.toLocaleString(e.vat_value)}
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    style={{ minWidth: 160 }}
                                    className="text-uppercase"
                                  >
                                    {e.ppn_editable || e.ppn === null ? (
                                      <div className="row">
                                        <TextField
                                          name="ppn"
                                          margin="dense"
                                          variant="outlined"
                                          //fullWidth
                                          onChange={(e) => {
                                            let row = this.state.costing_types;
                                            let rows = row.receivable_services;
                                            rows[idx].ppn = e.target.value;
                                            rows[idx].ppn_editable = true;

                                            this.setState({
                                              costing_types: row,
                                            });
                                          }}
                                          value={e.ppn}
                                          placeholder="Fakthur Pazak No"
                                          InputProps={{
                                            style: {
                                              textTransform: "uppercase",
                                            },
                                          }}
                                        />
                                      </div>
                                    ) : (
                                      <div>
                                        {e.ppn}
                                        <i
                                          className="fa fa-pencil edit_icon text-right"
                                          style={{ float: "right" }}
                                          onClick={() => {
                                            let row = this.state.costing_types;
                                            let rows = row.receivable_services;
                                            rows[idx].ppn_editable = true;
                                            this.setState({
                                              costing_types: row,
                                            });
                                          }}
                                        />
                                      </div>
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    <MuiPickersUtilsProvider
                                      utils={DateFnsUtils}
                                    >
                                      <DatePicker
                                        autoOk={true}
                                        inputVariant="outlined"
                                        variant="inline"
                                        margin="dense"
                                        fullWidth
                                        format="dd/MM/yyyy"
                                        value={e.ppn_date}
                                        error={this.state.date_of_paymentError}
                                        onChange={(date) => {
                                          let row = this.state.costing_types;
                                          let rows = row.receivable_services;
                                          rows[idx].ppn_date =
                                            this.dateFormat(date);
                                          this.setState({
                                            stevedore_costing: row,
                                          });
                                        }}
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              <IconButton>
                                                <EventIcon />
                                              </IconButton>
                                            </InputAdornment>
                                          ),
                                          className: "pl-0",
                                        }}
                                      />
                                    </MuiPickersUtilsProvider>
                                  </TableCell>
                                </TableRow>
                              ))}
                              <TableRow>
                                <TableCell colSpan={5}></TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.toLocaleString(
                                    this.state.receivable_services_invoice_value
                                  )}
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.toLocaleString(
                                    this.state.receivable_services_vat_value
                                  )}
                                </TableCell>
                                <TableCell colSpan={2} />
                              </TableRow>
                            </TableBody>
                          )}
                          {/**
                           *
                           * ============================== MOTHER VESSEL SUPPLIER DEMURRAGE (demurrage_or_despatch = Demurrage) START'S =============================
                           *
                           */}
                          {summary.mv_supplier_demurrage.filter(
                            (i) => i.demurrage_or_despatch === "Demurrage"
                          ).length > 0 && (
                            <TableBody>
                              {/* <TableRow><TableCell colSpan={8} style={{ color: config.themeColor }}>Mother Vessel Supplier Demurrage</TableCell></TableRow> */}
                              {summary.mv_supplier_demurrage
                                .filter(
                                  (i) => i.demurrage_or_despatch === "Demurrage"
                                )
                                .map((e, idx) => (
                                  <TableRow key={idx}>
                                    <TableCell
                                      nowrap="true"
                                      className="text-uppercase"
                                    >
                                      {e.vendor_name ? e.vendor_name : "-"}
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      className="text-uppercase"
                                    >
                                      {e.tax_id ? e.tax_id : "-"}
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      className="text-uppercase"
                                    >
                                      MV Supplier Demurrage
                                    </TableCell>
                                    <TableCell className="text-uppercase">
                                      {e.invoice_no ? e.invoice_no : "-"}
                                    </TableCell>
                                    <TableCell>
                                      {e.invoice_date
                                        ? this.getLocalDate(e.invoice_date)
                                        : "-"}
                                    </TableCell>
                                    <TableCell className="text-right">
                                      {this.toLocaleString(e.invoice_value)}
                                    </TableCell>
                                    <TableCell className="text-right">
                                      {this.toLocaleString(e.vat_value)}
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      style={{ minWidth: 160 }}
                                      className="text-uppercase"
                                    >
                                      {e.ppn_editable || e.ppn === null ? (
                                        <div className="row">
                                          <TextField
                                            name="ppn"
                                            margin="dense"
                                            variant="outlined"
                                            //fullWidth
                                            onChange={(e) => {
                                              let row =
                                                this.state.costing_types;
                                              let rows =
                                                row.mv_supplier_demurrage.filter(
                                                  (i) =>
                                                    i.demurrage_or_despatch ===
                                                    "Demurrage"
                                                );
                                              rows[idx].ppn = e.target.value;
                                              rows[idx].ppn_editable = true;

                                              this.setState({
                                                costing_types: row,
                                              });
                                            }}
                                            value={e.ppn}
                                            placeholder="Fakthur Pazak No"
                                            InputProps={{
                                              style: {
                                                textTransform: "uppercase",
                                              },
                                            }}
                                          />
                                        </div>
                                      ) : (
                                        <div>
                                          {e.ppn}
                                          <i
                                            className="fa fa-pencil edit_icon text-right"
                                            style={{ float: "right" }}
                                            onClick={() => {
                                              let row =
                                                this.state.costing_types;
                                              let rows =
                                                row.mv_supplier_demurrage.filter(
                                                  (i) =>
                                                    i.demurrage_or_despatch ===
                                                    "Demurrage"
                                                );
                                              rows[idx].ppn_editable = true;
                                              this.setState({
                                                costing_types: row,
                                              });
                                            }}
                                          />
                                        </div>
                                      )}
                                    </TableCell>
                                    <TableCell>
                                      <MuiPickersUtilsProvider
                                        utils={DateFnsUtils}
                                      >
                                        <DatePicker
                                          autoOk={true}
                                          inputVariant="outlined"
                                          variant="inline"
                                          margin="dense"
                                          fullWidth
                                          format="dd/MM/yyyy"
                                          value={e.ppn_date}
                                          error={
                                            this.state.date_of_paymentError
                                          }
                                          onChange={(date) => {
                                            let row = this.state.costing_types;
                                            let rows =
                                              row.mv_supplier_demurrage.filter(
                                                (i) =>
                                                  i.demurrage_or_despatch ===
                                                  "Demurrage"
                                              );
                                            rows[idx].ppn_date =
                                              this.dateFormat(date);
                                            this.setState({
                                              stevedore_costing: row,
                                            });
                                          }}
                                          InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <IconButton>
                                                  <EventIcon />
                                                </IconButton>
                                              </InputAdornment>
                                            ),
                                            className: "pl-0",
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              <TableRow>
                                <TableCell colSpan={5}></TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.toLocaleString(
                                    this.state.mv_sup_dem_sales_invoice_value
                                  )}
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.toLocaleString(
                                    this.state.mv_sup_dem_sales_vat_value
                                  )}
                                </TableCell>
                                <TableCell colSpan={2} />
                              </TableRow>
                            </TableBody>
                          )}
                          {/**
                           *
                           *====================================== MOTHER VESSEL BUYER DEMURRAGE (demurrage_or_despatch = Despatch) START ===================
                           *
                           */}
                          {summary.mv_buyer_demurrage.filter(
                            (i) => i.demurrage_or_despatch === "Despatch"
                          ).length > 0 && (
                            <TableBody>
                              {/* <TableRow><TableCell colSpan={8} style={{ color: config.themeColor }}>Mother Vessel Buyer Despatch</TableCell></TableRow> */}
                              {summary.mv_buyer_demurrage
                                .filter(
                                  (i) => i.demurrage_or_despatch === "Despatch"
                                )
                                .map((e, idx) => (
                                  <TableRow key={idx}>
                                    <TableCell
                                      nowrap="true"
                                      className="text-uppercase"
                                    >
                                      {e.vendor_name ? e.vendor_name : "-"}
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      className="text-uppercase"
                                    >
                                      {e.tax_id ? e.tax_id : "-"}
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      className="text-uppercase"
                                    >
                                      MV buyer Despatch
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      className="text-uppercase"
                                    >
                                      {e.invoice_no ? e.invoice_no : "-"}
                                    </TableCell>
                                    <TableCell>
                                      {e.invoice_date
                                        ? this.getLocalDate(e.invoice_date)
                                        : "-"}
                                    </TableCell>
                                    <TableCell className="text-right">
                                      {this.toLocaleString(e.invoice_value)}
                                    </TableCell>
                                    <TableCell className="text-right">
                                      {this.toLocaleString(e.vat_value)}
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      style={{ minWidth: 160 }}
                                      className="text-uppercase"
                                    >
                                      {e.ppn_editable || e.ppn === null ? (
                                        <div className="row">
                                          <TextField
                                            name="ppn"
                                            margin="dense"
                                            variant="outlined"
                                            //fullWidth
                                            onChange={(e) => {
                                              let row =
                                                this.state.costing_types;
                                              let rows =
                                                row.mv_buyer_demurrage.filter(
                                                  (i) =>
                                                    i.demurrage_or_despatch ===
                                                    "Despatch"
                                                );
                                              rows[idx].ppn = e.target.value;
                                              rows[idx].ppn_editable = true;

                                              this.setState({
                                                costing_types: row,
                                              });
                                            }}
                                            value={e.ppn}
                                            placeholder="Fakthur Pazak No"
                                            InputProps={{
                                              style: {
                                                textTransform: "uppercase",
                                              },
                                            }}
                                          />
                                        </div>
                                      ) : (
                                        <div>
                                          {e.ppn}
                                          <i
                                            className="fa fa-pencil edit_icon text-right"
                                            style={{ float: "right" }}
                                            onClick={() => {
                                              let row =
                                                this.state.costing_types;
                                              let rows =
                                                row.mv_buyer_demurrage.filter(
                                                  (i) =>
                                                    i.demurrage_or_despatch ===
                                                    "Despatch"
                                                );
                                              rows[idx].ppn_editable = true;
                                              this.setState({
                                                costing_types: row,
                                              });
                                            }}
                                          />
                                        </div>
                                      )}
                                    </TableCell>
                                    <TableCell>
                                      <MuiPickersUtilsProvider
                                        utils={DateFnsUtils}
                                      >
                                        <DatePicker
                                          autoOk={true}
                                          inputVariant="outlined"
                                          variant="inline"
                                          margin="dense"
                                          fullWidth
                                          format="dd/MM/yyyy"
                                          value={e.ppn_date}
                                          error={
                                            this.state.date_of_paymentError
                                          }
                                          onChange={(date) => {
                                            let row = this.state.costing_types;
                                            let rows =
                                              row.mv_buyer_demurrage.filter(
                                                (i) =>
                                                  i.demurrage_or_despatch ===
                                                  "Despatch"
                                              );
                                            rows[idx].ppn_date =
                                              this.dateFormat(date);
                                            this.setState({
                                              stevedore_costing: row,
                                            });
                                          }}
                                          InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <IconButton>
                                                  <EventIcon />
                                                </IconButton>
                                              </InputAdornment>
                                            ),
                                            className: "pl-0",
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              <TableRow>
                                <TableCell colSpan={5}></TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.toLocaleString(
                                    this.state.mv_buy_dem_sales_invoice_value
                                  )}
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.toLocaleString(
                                    this.state.mv_buy_dem_sales_vat_value
                                  )}
                                </TableCell>
                                <TableCell colSpan={2} />
                              </TableRow>
                            </TableBody>
                          )}

                          {/**
                           *
                           *  ===================================== BARGE DEMURRAGE ==============================
                           *
                           */}

                          {(summary.barge_demurrage_from_supplier_data.length >
                            0 ||
                            summary.barge_demurrage_from_buyer_data.length >
                              0) && (
                            <TableBody>
                              {/* <TableRow><TableCell colSpan={8} style={{ color: config.themeColor }}>Barge Demurrage</TableCell></TableRow> */}
                              {summary.barge_demurrage_from_supplier_data.map(
                                (e, idx) => (
                                  <TableRow key={idx}>
                                    <TableCell
                                      nowrap="true"
                                      className="text-uppercase"
                                    >
                                      Supplier to {config.company} -{" "}
                                      {e.vendor_name}
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      className="text-uppercase"
                                    >
                                      {e.tax_id ? e.tax_id : "-"}
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      className="text-uppercase"
                                    >
                                      Buyer Demurrage
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      className="text-uppercase"
                                    >
                                      {e.invoice_no ? e.invoice_no : "-"}
                                    </TableCell>
                                    <TableCell>
                                      {e.invoice_date
                                        ? this.getLocalDate(e.invoice_date)
                                        : "-"}
                                    </TableCell>
                                    <TableCell className="text-right">
                                      {this.toLocaleString(e.invoice_value)}
                                    </TableCell>
                                    <TableCell className="text-right">
                                      {this.toLocaleString(e.vat_value)}
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      style={{ minWidth: 160 }}
                                      className="text-right"
                                    >
                                      {e.ppn_editable || e.ppn === null ? (
                                        <div className="row">
                                          <TextField
                                            name="ppn"
                                            margin="dense"
                                            variant="outlined"
                                            //fullWidth
                                            onChange={(e) => {
                                              let row =
                                                this.state.costing_types;
                                              let rows =
                                                row.barge_demurrage_from_supplier_data;
                                              rows[idx].ppn = e.target.value;
                                              rows[idx].ppn_editable = true;

                                              this.setState({
                                                costing_types: row,
                                              });
                                            }}
                                            value={e.ppn}
                                            placeholder="Fakthur Pazak No"
                                            InputProps={{
                                              style: {
                                                textTransform: "uppercase",
                                              },
                                            }}
                                          />
                                        </div>
                                      ) : (
                                        <div>
                                          {e.ppn}
                                          <i
                                            className="fa fa-pencil edit_icon text-right"
                                            style={{ float: "right" }}
                                            onClick={() => {
                                              let row =
                                                this.state.costing_types;
                                              let rows =
                                                row.barge_demurrage_from_supplier_data;
                                              rows[idx].ppn_editable = true;
                                              this.setState({
                                                costing_types: row,
                                              });
                                            }}
                                          />
                                        </div>
                                      )}
                                    </TableCell>
                                    <TableCell>
                                      <MuiPickersUtilsProvider
                                        utils={DateFnsUtils}
                                      >
                                        <DatePicker
                                          autoOk={true}
                                          inputVariant="outlined"
                                          variant="inline"
                                          margin="dense"
                                          fullWidth
                                          format="dd/MM/yyyy"
                                          value={e.ppn_date}
                                          error={
                                            this.state.date_of_paymentError
                                          }
                                          onChange={(date) => {
                                            let row = this.state.costing_types;
                                            let rows =
                                              row.barge_demurrage_from_supplier_data;
                                            rows[idx].ppn_date =
                                              this.dateFormat(date);
                                            this.setState({
                                              stevedore_costing: row,
                                            });
                                          }}
                                          InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <IconButton>
                                                  <EventIcon />
                                                </IconButton>
                                              </InputAdornment>
                                            ),
                                            className: "pl-0",
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </TableCell>
                                  </TableRow>
                                )
                              )}
                              {summary.barge_demurrage_from_supplier_data
                                .length > 0 && (
                                <TableRow>
                                  <TableCell colSpan={5}></TableCell>
                                  <TableCell
                                    style={{
                                      textAlign: "right",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {this.toLocaleString(
                                      this.state.dmr_from_supplier_invoice_value
                                    )}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      textAlign: "right",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {this.toLocaleString(
                                      this.state.dmr_from_supplier_vat_value
                                    )}
                                  </TableCell>
                                  <TableCell colSpan={2} />
                                </TableRow>
                              )}
                              {summary.barge_demurrage_from_buyer_data.map(
                                (e, idx) => (
                                  <TableRow key={idx}>
                                    <TableCell
                                      nowrap="true"
                                      className="text-uppercase"
                                    >
                                      {e.buyer_name}
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      className="text-uppercase"
                                    >
                                      {e.tax_id ? e.tax_id : "-"}
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      className="text-uppercase"
                                    >
                                      Buyer to {config.company}
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      className="text-uppercase"
                                    >
                                      {e.invoice_no ? e.invoice_no : "-"}
                                    </TableCell>
                                    <TableCell>
                                      {e.invoice_date
                                        ? this.getLocalDate(e.invoice_date)
                                        : "-"}
                                    </TableCell>
                                    <TableCell className="text-right">
                                      {this.toLocaleString(e.invoice_value)}
                                    </TableCell>
                                    <TableCell className="text-right">
                                      {this.toLocaleString(e.vat_value)}
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      style={{ minWidth: 160 }}
                                      className="text-uppercase"
                                    >
                                      {e.ppn_editable || e.ppn === null ? (
                                        <div className="row">
                                          <TextField
                                            name="ppn"
                                            margin="dense"
                                            variant="outlined"
                                            //fullWidth
                                            onChange={(e) => {
                                              let row =
                                                this.state.costing_types;
                                              let rows =
                                                row.barge_demurrage_from_buyer_data;
                                              rows[idx].ppn = e.target.value;
                                              rows[idx].ppn_editable = true;

                                              this.setState({
                                                costing_types: row,
                                              });
                                            }}
                                            value={e.ppn}
                                            placeholder="Fakthur Pazak No"
                                            InputProps={{
                                              style: {
                                                textTransform: "uppercase",
                                              },
                                            }}
                                          />
                                        </div>
                                      ) : (
                                        <div>
                                          {e.ppn}
                                          <i
                                            className="fa fa-pencil edit_icon text-right"
                                            style={{ float: "right" }}
                                            onClick={() => {
                                              let row =
                                                this.state.costing_types;
                                              let rows =
                                                row.barge_demurrage_from_buyer_data;
                                              rows[idx].ppn_editable = true;
                                              this.setState({
                                                costing_types: row,
                                              });
                                            }}
                                          />
                                        </div>
                                      )}
                                    </TableCell>
                                    <TableCell>
                                      <MuiPickersUtilsProvider
                                        utils={DateFnsUtils}
                                      >
                                        <DatePicker
                                          autoOk={true}
                                          inputVariant="outlined"
                                          variant="inline"
                                          margin="dense"
                                          fullWidth
                                          format="dd/MM/yyyy"
                                          value={e.ppn_date}
                                          error={
                                            this.state.date_of_paymentError
                                          }
                                          onChange={(date) => {
                                            let row = this.state.costing_types;
                                            let rows =
                                              row.barge_demurrage_from_buyer_data;
                                            rows[idx].ppn_date =
                                              this.dateFormat(date);
                                            this.setState({
                                              stevedore_costing: row,
                                            });
                                          }}
                                          InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <IconButton>
                                                  <EventIcon />
                                                </IconButton>
                                              </InputAdornment>
                                            ),
                                            className: "pl-0",
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </TableCell>
                                  </TableRow>
                                )
                              )}
                              {summary.barge_demurrage_from_buyer_data.length >
                                0 && (
                                <TableRow>
                                  <TableCell colSpan={5}></TableCell>
                                  <TableCell
                                    style={{
                                      textAlign: "right",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {this.toLocaleString(
                                      this.state.dmr_from_buyer_invoice_value
                                    )}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      textAlign: "right",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {this.toLocaleString(
                                      this.state.dmr_from_buyer_vat_value
                                    )}
                                  </TableCell>
                                  <TableCell colSpan={2} />
                                </TableRow>
                              )}
                            </TableBody>
                          )}
                          <TableBody>
                            <TableRow>
                              <TableCell colSpan={5}></TableCell>
                              <TableCell
                                style={{
                                  textAlign: "right",
                                  fontWeight: "bold",
                                }}
                                className="text-success"
                              >
                                {this.state.sales_total_invoice_value
                                  ? this.toLocaleString(
                                      this.state.sales_total_invoice_value
                                    )
                                  : 0}
                              </TableCell>
                              <TableCell
                                style={{
                                  textAlign: "right",
                                  fontWeight: "bold",
                                }}
                                className="text-success"
                              >
                                {this.state.sales_total_vat_value
                                  ? this.toLocaleString(
                                      this.state.sales_total_vat_value
                                    )
                                  : 0}
                              </TableCell>
                              <TableCell colSpan={2} className="text-center">
                                {this.state.income_avaialable > 0 && (
                                  <button
                                    className="header_button header_button_text mt-1"
                                    onClick={this.bulkUpdateHandler}
                                  >
                                    Update
                                  </button>
                                )}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>
                )}
                {this.state.activeTabIndex === 1 && (
                  <div className="row mt-2">
                    <div className="col-lg m-auto ">
                      <TableContainer style={{ maxHeight: 500 }}>
                        <Table
                          size="small"
                          component={Paper}
                          stickyHeader
                          aria-label="sticky table"
                        >
                          <TableHead className="bg-light">
                            <TableRow style={{ position: "sticky", top: 0 }}>
                              <TableCell
                                nowrap="true"
                                style={{
                                  position: "sticky",
                                  top: 0,
                                  fontSize: 10,
                                }}
                                className="text-uppercase"
                              >
                                Vendor
                              </TableCell>
                              <TableCell
                                nowrap="true"
                                style={{
                                  position: "sticky",
                                  top: 0,
                                  fontSize: 10,
                                }}
                                className="text-uppercase"
                              >
                                NPWP NO
                              </TableCell>
                              <TableCell
                                nowrap="true"
                                style={{
                                  position: "sticky",
                                  top: 0,
                                  fontSize: 10,
                                }}
                                className="text-uppercase"
                              >
                                Costing TYPE
                              </TableCell>
                              <TableCell
                                nowrap="true"
                                style={{
                                  position: "sticky",
                                  top: 0,
                                  fontSize: 10,
                                }}
                                className="text-uppercase"
                              >
                                Invoice No
                              </TableCell>
                              <TableCell
                                nowrap="true"
                                style={{
                                  position: "sticky",
                                  top: 0,
                                  fontSize: 10,
                                }}
                                className="text-uppercase"
                              >
                                Invoice Date
                              </TableCell>
                              <TableCell
                                nowrap="true"
                                style={{
                                  position: "sticky",
                                  top: 0,
                                  fontSize: 10,
                                }}
                                className="text-uppercase text-right"
                              >
                                Invoice Value
                              </TableCell>
                              <TableCell
                                nowrap="true"
                                style={{
                                  position: "sticky",
                                  top: 0,
                                  fontSize: 10,
                                }}
                                className="text-uppercase text-right"
                              >
                                VAT Value
                              </TableCell>
                              <TableCell
                                nowrap="true"
                                style={{
                                  position: "sticky",
                                  top: 0,
                                  fontSize: 10,
                                }}
                                className="text-uppercase"
                              >
                                Fakthur Pazak No
                              </TableCell>
                              <TableCell
                                nowrap="true"
                                style={{
                                  position: "sticky",
                                  top: 0,
                                  fontSize: 10,
                                  minWidth: 200,
                                }}
                                className="text-uppercase"
                              >
                                Fakthur Pazak Date
                              </TableCell>
                            </TableRow>
                          </TableHead>

                          {summary.barge_pur_fin_performa_coal_costing_data
                            .length > 0 && (
                            <TableBody>
                              {/* <TableRow>
                              <TableCell colSpan={8} style={{ color: config.themeColor }}> Coal Purchase Performa </TableCell>
                            </TableRow> */}
                              {summary.barge_pur_fin_performa_coal_costing_data.map(
                                (e, idx) => (
                                  <TableRow key={idx}>
                                    <TableCell
                                      nowrap="true"
                                      className="text-uppercase"
                                    >
                                      {e.vendor_name ? e.vendor_name : "-"}
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      className="text-uppercase"
                                    >
                                      {e.tax_id ? e.tax_id : "-"}
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      className="text-uppercase"
                                    >
                                      Purchase Performa
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      className="text-uppercase"
                                    >
                                      {e.invoice_no ? e.invoice_no : "-"}
                                    </TableCell>
                                    <TableCell>
                                      {e.invoice_date
                                        ? this.getLocalDate(e.invoice_date)
                                        : "-"}
                                    </TableCell>
                                    <TableCell className="text-right">
                                      {this.toLocaleString(e.invoice_value)}
                                    </TableCell>
                                    <TableCell className="text-right">
                                      {this.toLocaleString(e.vat_value)}
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      style={{ minWidth: 160 }}
                                      className="text-uppercase"
                                    >
                                      {e.ppn_editable || e.ppn === null ? (
                                        <div className="row">
                                          <TextField
                                            name="ppn"
                                            margin="dense"
                                            variant="outlined"
                                            //fullWidth
                                            onChange={(e) => {
                                              let row =
                                                this.state.costing_types;
                                              let rows =
                                                row.barge_pur_fin_performa_coal_costing_data;
                                              rows[idx].ppn = e.target.value;
                                              rows[idx].ppn_editable = true;

                                              this.setState({
                                                costing_types: row,
                                              });
                                            }}
                                            value={e.ppn}
                                            placeholder="Fakthur Pazak No"
                                            InputProps={{
                                              style: {
                                                textTransform: "uppercase",
                                              },
                                            }}
                                          />
                                        </div>
                                      ) : (
                                        <div>
                                          {e.ppn}
                                          <i
                                            className="fa fa-pencil edit_icon text-right"
                                            style={{ float: "right" }}
                                            onClick={() => {
                                              let row =
                                                this.state.costing_types;
                                              let rows =
                                                row.barge_pur_fin_performa_coal_costing_data;
                                              rows[idx].ppn_editable = true;
                                              this.setState({
                                                costing_types: row,
                                              });
                                            }}
                                          />
                                        </div>
                                      )}
                                    </TableCell>
                                    <TableCell>
                                      <MuiPickersUtilsProvider
                                        utils={DateFnsUtils}
                                      >
                                        <DatePicker
                                          autoOk={true}
                                          inputVariant="outlined"
                                          variant="inline"
                                          margin="dense"
                                          fullWidth
                                          format="dd/MM/yyyy"
                                          value={e.ppn_date}
                                          error={
                                            this.state.date_of_paymentError
                                          }
                                          onChange={(date) => {
                                            let row = this.state.costing_types;
                                            let rows =
                                              row.barge_pur_fin_performa_coal_costing_data;
                                            rows[idx].ppn_date =
                                              this.dateFormat(date);
                                            this.setState({
                                              stevedore_costing: row,
                                            });
                                          }}
                                          InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <IconButton>
                                                  <EventIcon />
                                                </IconButton>
                                              </InputAdornment>
                                            ),
                                            className: "pl-0",
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </TableCell>
                                  </TableRow>
                                )
                              )}
                              <TableRow>
                                <TableCell colSpan={5}></TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.toLocaleString(
                                    this.state.barge_performa_invoice_value
                                  )}
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.toLocaleString(
                                    this.state.barge_performa_vat_value
                                  )}
                                </TableCell>
                                <TableCell colSpan={2} />
                              </TableRow>
                            </TableBody>
                          )}
                          {summary.barge_pur_fin_commercial_coal_costing_data
                            .length > 0 && (
                            <TableBody>
                              {/* <TableRow>
                              <TableCell colSpan={8} style={{ color: config.themeColor }}> Coal Purchase Commercial</TableCell>
                            </TableRow> */}
                              {summary.barge_pur_fin_commercial_coal_costing_data.map(
                                (e, idx) => (
                                  <TableRow key={idx}>
                                    <TableCell
                                      nowrap="true"
                                      className="text-uppercase"
                                    >
                                      {e.vendor_name ? e.vendor_name : "-"}
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      className="text-uppercase"
                                    >
                                      {e.tax_id ? e.tax_id : "-"}
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      className="text-uppercase"
                                    >
                                      Purchase Commercial
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      className="text-uppercase"
                                    >
                                      {e.invoice_no ? e.invoice_no : "-"}
                                    </TableCell>
                                    <TableCell>
                                      {e.invoice_date
                                        ? this.getLocalDate(e.invoice_date)
                                        : "-"}
                                    </TableCell>
                                    <TableCell className="text-right">
                                      {this.toLocaleString(e.invoice_value)}
                                    </TableCell>
                                    <TableCell className="text-right">
                                      {this.toLocaleString(e.vat_value)}
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      style={{ minWidth: 160 }}
                                      className="text-uppercase"
                                    >
                                      {e.ppn_editable || e.ppn === null ? (
                                        <div className="row">
                                          <TextField
                                            name="ppn"
                                            margin="dense"
                                            variant="outlined"
                                            //fullWidth
                                            onChange={(e) => {
                                              let row =
                                                this.state.costing_types;
                                              let rows =
                                                row.barge_pur_fin_commercial_coal_costing_data;
                                              rows[idx].ppn = e.target.value;
                                              rows[idx].ppn_editable = true;

                                              this.setState({
                                                costing_types: row,
                                              });
                                            }}
                                            value={e.ppn}
                                            placeholder="Fakthur Pazak No"
                                            InputProps={{
                                              style: {
                                                textTransform: "uppercase",
                                              },
                                            }}
                                          />
                                        </div>
                                      ) : (
                                        <div>
                                          {e.ppn}
                                          <i
                                            className="fa fa-pencil edit_icon text-right"
                                            style={{ float: "right" }}
                                            onClick={() => {
                                              let row =
                                                this.state.costing_types;
                                              let rows =
                                                row.barge_pur_fin_commercial_coal_costing_data;
                                              rows[idx].ppn_editable = true;
                                              this.setState({
                                                costing_types: row,
                                              });
                                            }}
                                          />
                                        </div>
                                      )}
                                    </TableCell>
                                    <TableCell>
                                      <MuiPickersUtilsProvider
                                        utils={DateFnsUtils}
                                      >
                                        <DatePicker
                                          autoOk={true}
                                          inputVariant="outlined"
                                          variant="inline"
                                          margin="dense"
                                          fullWidth
                                          format="dd/MM/yyyy"
                                          value={e.ppn_date}
                                          error={
                                            this.state.date_of_paymentError
                                          }
                                          onChange={(date) => {
                                            let row = this.state.costing_types;
                                            let rows =
                                              row.barge_pur_fin_commercial_coal_costing_data;
                                            rows[idx].ppn_date =
                                              this.dateFormat(date);
                                            this.setState({
                                              stevedore_costing: row,
                                            });
                                          }}
                                          InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <IconButton>
                                                  <EventIcon />
                                                </IconButton>
                                              </InputAdornment>
                                            ),
                                            className: "pl-0",
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </TableCell>
                                  </TableRow>
                                )
                              )}
                              <TableRow>
                                <TableCell colSpan={5}></TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.toLocaleString(
                                    this.state.barge_commercial_invoice_value
                                  )}
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.toLocaleString(
                                    this.state.barge_commercial_vat_value
                                  )}
                                </TableCell>
                                <TableCell colSpan={2} />
                              </TableRow>
                            </TableBody>
                          )}

                          {summary.barge_costing_data.length > 0 && (
                            <TableBody>
                              {/* <TableRow><TableCell colSpan={8} style={{ color: config.themeColor }}>Barging</TableCell></TableRow> */}
                              {summary.barge_costing_data.map((e, idx) => (
                                <TableRow key={idx}>
                                  <TableCell
                                    nowrap="true"
                                    className="text-uppercase"
                                  >
                                    {e.vendor_name ? e.vendor_name : "-"}
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    className="text-uppercase"
                                  >
                                    {e.tax_id ? e.tax_id : "-"}
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    className="text-uppercase"
                                  >
                                    Barging
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    className="text-uppercase"
                                  >
                                    {e.invoice_no ? e.invoice_no : "-"}
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    className="text-uppercase"
                                  >
                                    {e.invoice_date
                                      ? this.getLocalDate(e.invoice_date)
                                      : "-"}
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    className="text-right"
                                  >
                                    {this.toLocaleString(e.invoice_value)}
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    className="text-right"
                                  >
                                    {this.toLocaleString(e.vat_value)}
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    className="text-uppercase"
                                  >
                                    {e.ppn_editable || e.ppn === null ? (
                                      <div className="row">
                                        <TextField
                                          name="ppn"
                                          margin="dense"
                                          variant="outlined"
                                          //fullWidth
                                          onChange={(e) => {
                                            let row = this.state.costing_types;
                                            let rows = row.barge_costing_data;
                                            rows[idx].ppn = e.target.value;
                                            rows[idx].ppn_editable = true;
                                            this.setState({
                                              costing_types: row,
                                            });
                                          }}
                                          value={e.ppn}
                                          placeholder="Fakthur Pazak No"
                                          InputProps={{
                                            style: {
                                              textTransform: "uppercase",
                                            },
                                          }}
                                        />
                                      </div>
                                    ) : (
                                      <div>
                                        {e.ppn}
                                        <i
                                          className="fa fa-pencil edit_icon text-right"
                                          style={{ float: "right" }}
                                          onClick={() => {
                                            let row = this.state.costing_types;
                                            let rows = row.barge_costing_data;
                                            rows[idx].ppn_editable = true;
                                            this.setState({
                                              costing_types: row,
                                            });
                                          }}
                                        />
                                      </div>
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    <MuiPickersUtilsProvider
                                      utils={DateFnsUtils}
                                    >
                                      <DatePicker
                                        autoOk={true}
                                        inputVariant="outlined"
                                        variant="inline"
                                        margin="dense"
                                        fullWidth
                                        format="dd/MM/yyyy"
                                        value={e.ppn_date}
                                        error={this.state.date_of_paymentError}
                                        onChange={(date) => {
                                          let row = this.state.costing_types;
                                          let rows = row.barge_costing_data;
                                          rows[idx].ppn_date =
                                            this.dateFormat(date);
                                          this.setState({
                                            stevedore_costing: row,
                                          });
                                        }}
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              <IconButton>
                                                <EventIcon />
                                              </IconButton>
                                            </InputAdornment>
                                          ),
                                          className: "pl-0",
                                        }}
                                      />
                                    </MuiPickersUtilsProvider>
                                  </TableCell>
                                </TableRow>
                              ))}
                              <TableRow>
                                <TableCell colSpan={5}></TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.toLocaleString(
                                    this.state.barging_invoice_value
                                  )}
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.toLocaleString(
                                    this.state.barging_vat_value
                                  )}
                                </TableCell>
                                <TableCell colSpan={2} />
                              </TableRow>
                            </TableBody>
                          )}

                          {/* {summary.doc_charges_data.length > 0 &&
                          <TableBody>
                            <TableRow><TableCell colSpan={8} style={{ color: config.themeColor }}>Barge Documentation Charges</TableCell></TableRow>
                          </TableBody>
                        } */}

                          {summary.doc_charges_data.map((e, idx) => (
                            <TableBody key={idx}>
                              <TableRow>
                                <TableCell
                                  nowrap="true"
                                  className="text-uppercase"
                                >
                                  {e.vendor_name ? e.vendor_name : "-"}
                                </TableCell>
                                <TableCell
                                  nowrap="true"
                                  className="text-uppercase"
                                >
                                  {e.tax_id ? e.tax_id : "-"}
                                </TableCell>
                                <TableCell
                                  nowrap="true"
                                  className="text-uppercase"
                                >
                                  Documentation Charges
                                </TableCell>
                                <TableCell
                                  nowrap="true"
                                  className="text-uppercase"
                                >
                                  {e.invoice_no ? e.invoice_no : "-"}
                                </TableCell>
                                <TableCell nowrap="true">
                                  {e.invoice_date
                                    ? this.getLocalDate(e.invoice_date)
                                    : "-"}
                                </TableCell>
                                <TableCell nowrap="true" className="text-right">
                                  {this.toLocaleString(e.invoice_value)}
                                </TableCell>
                                <TableCell nowrap="true" className="text-right">
                                  {this.toLocaleString(e.vat_value)}
                                </TableCell>
                                <TableCell
                                  nowrap="true"
                                  className="text-uppercase"
                                >
                                  {e.ppn_editable || e.ppn === null ? (
                                    <div className="row">
                                      <TextField
                                        name="ppn"
                                        margin="dense"
                                        variant="outlined"
                                        //fullWidth
                                        onChange={(e) => {
                                          let row = this.state.costing_types;
                                          let rows = row.doc_charges_data;
                                          rows[idx].ppn = e.target.value;
                                          rows[idx].ppn_editable = true;

                                          this.setState({
                                            costing_types: row,
                                          });
                                        }}
                                        value={e.ppn}
                                        placeholder="Fakthur Pazak No"
                                        InputProps={{
                                          style: { textTransform: "uppercase" },
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <div>
                                      {e.ppn}
                                      <i
                                        className="fa fa-pencil edit_icon text-right"
                                        style={{ float: "right" }}
                                        onClick={() => {
                                          let row = this.state.costing_types;
                                          let rows = row.doc_charges_data;
                                          rows[idx].ppn_editable = true;
                                          this.setState({ costing_types: row });
                                        }}
                                      />
                                    </div>
                                  )}
                                </TableCell>
                                <TableCell>
                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                      autoOk={true}
                                      inputVariant="outlined"
                                      variant="inline"
                                      margin="dense"
                                      fullWidth
                                      format="dd/MM/yyyy"
                                      value={e.ppn_date}
                                      error={this.state.date_of_paymentError}
                                      onChange={(date) => {
                                        let row = this.state.costing_types;
                                        let rows = row.doc_charges_data;
                                        rows[idx].ppn_date =
                                          this.dateFormat(date);
                                        this.setState({
                                          stevedore_costing: row,
                                        });
                                      }}
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <IconButton>
                                              <EventIcon />
                                            </IconButton>
                                          </InputAdornment>
                                        ),
                                        className: "pl-0",
                                      }}
                                    />
                                  </MuiPickersUtilsProvider>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          ))}
                          {summary.doc_charges_data.length > 0 && (
                            <TableBody>
                              <TableRow>
                                <TableCell colSpan={5}></TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.toLocaleString(
                                    this.state.barge_doc_invoice_value
                                  )}
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.toLocaleString(
                                    this.state.barge_doc_vat_value
                                  )}
                                </TableCell>
                                <TableCell colSpan={2} />
                              </TableRow>
                            </TableBody>
                          )}

                          {summary.laparon_charges_data?.map((e, idx) => (
                            <TableBody key={idx}>
                              <TableRow>
                                <TableCell
                                  nowrap="true"
                                  className="text-uppercase"
                                >
                                  {e.vendor_name ? e.vendor_name : "-"}
                                </TableCell>
                                <TableCell
                                  nowrap="true"
                                  className="text-uppercase"
                                >
                                  {e.tax_id ? e.tax_id : "-"}
                                </TableCell>
                                <TableCell
                                  nowrap="true"
                                  className="text-uppercase"
                                >
                                  Laporan Survey Charges
                                </TableCell>
                                <TableCell
                                  nowrap="true"
                                  className="text-uppercase"
                                >
                                  {e.invoice_no ? e.invoice_no : "-"}
                                </TableCell>
                                <TableCell nowrap="true">
                                  {e.invoice_date
                                    ? this.getLocalDate(e.invoice_date)
                                    : "-"}
                                </TableCell>
                                <TableCell nowrap="true" className="text-right">
                                  {this.toLocaleString(e.invoice_value)}
                                </TableCell>
                                <TableCell nowrap="true" className="text-right">
                                  {this.toLocaleString(e.vat_value)}
                                </TableCell>
                                <TableCell
                                  nowrap="true"
                                  className="text-uppercase"
                                >
                                  {e.ppn_editable || e.ppn === null ? (
                                    <div className="row">
                                      <TextField
                                        name="ppn"
                                        margin="dense"
                                        variant="outlined"
                                        //fullWidth
                                        onChange={(e) => {
                                          let row = this.state.costing_types;
                                          let rows = row.laparon_charges_data;
                                          rows[idx].ppn = e.target.value;
                                          rows[idx].ppn_editable = true;

                                          this.setState({
                                            costing_types: row,
                                          });
                                        }}
                                        value={e.ppn}
                                        placeholder="Fakthur Pazak No"
                                        InputProps={{
                                          style: { textTransform: "uppercase" },
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <div>
                                      {e.ppn}
                                      <i
                                        className="fa fa-pencil edit_icon text-right"
                                        style={{ float: "right" }}
                                        onClick={() => {
                                          let row = this.state.costing_types;
                                          let rows = row.laparon_charges_data;
                                          rows[idx].ppn_editable = true;
                                          this.setState({ costing_types: row });
                                        }}
                                      />
                                    </div>
                                  )}
                                </TableCell>
                                <TableCell>
                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                      autoOk={true}
                                      inputVariant="outlined"
                                      variant="inline"
                                      margin="dense"
                                      fullWidth
                                      format="dd/MM/yyyy"
                                      value={e.ppn_date}
                                      error={this.state.date_of_paymentError}
                                      onChange={(date) => {
                                        let row = this.state.costing_types;
                                        let rows = row.doc_charges_data;
                                        rows[idx].ppn_date =
                                          this.dateFormat(date);
                                        this.setState({
                                          stevedore_costing: row,
                                        });
                                      }}
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <IconButton>
                                              <EventIcon />
                                            </IconButton>
                                          </InputAdornment>
                                        ),
                                        className: "pl-0",
                                      }}
                                    />
                                  </MuiPickersUtilsProvider>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          ))}
                          {summary.laparon_charges_data?.length > 0 && (
                            <TableBody>
                              <TableRow>
                                <TableCell colSpan={5}></TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.toLocaleString(
                                    this.state.barge_laprn_invoice_value
                                  )}
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.toLocaleString(
                                    this.state.barge_laprn_vat_value
                                  )}
                                </TableCell>
                                <TableCell colSpan={2} />
                              </TableRow>
                            </TableBody>
                          )}

                          {/* {summary.sac_charges_data.length > 0 &&
                          <TableBody>
                            <TableRow><TableCell colSpan={8} style={{ color: config.themeColor }}>Barge Sampling & Anlaysis</TableCell></TableRow>
                          </TableBody>
                        } */}
                          {summary.sac_charges_data.map((e, idx) => (
                            <TableBody key={idx}>
                              <TableRow>
                                <TableCell
                                  nowrap="true"
                                  className="text-uppercase"
                                >
                                  {e.vendor_name ? e.vendor_name : "-"}
                                </TableCell>
                                <TableCell
                                  nowrap="true"
                                  className="text-uppercase"
                                >
                                  {e.tax_id ? e.tax_id : "-"}
                                </TableCell>
                                <TableCell
                                  nowrap="true"
                                  className="text-uppercase"
                                >
                                  Sampling & Anlaysis
                                </TableCell>
                                <TableCell
                                  nowrap="true"
                                  className="text-uppercase"
                                >
                                  {e.invoice_no ? e.invoice_no : "-"}
                                </TableCell>
                                <TableCell nowrap="true">
                                  {e.invoice_date
                                    ? this.getLocalDate(e.invoice_date)
                                    : "-"}
                                </TableCell>
                                <TableCell nowrap="true" className="text-right">
                                  {this.toLocaleString(e.invoice_value)}
                                </TableCell>
                                <TableCell nowrap="true" className="text-right">
                                  {this.toLocaleString(e.vat_value)}
                                </TableCell>
                                <TableCell nowrap="true" className="text-right">
                                  {e.ppn_editable || e.ppn === null ? (
                                    <div className="row">
                                      <TextField
                                        name="ppn"
                                        margin="dense"
                                        variant="outlined"
                                        //fullWidth
                                        onChange={(e) => {
                                          let row = this.state.costing_types;
                                          let rows = row.sac_charges_data;
                                          rows[idx].ppn = e.target.value;
                                          rows[idx].ppn_editable = true;

                                          this.setState({
                                            costing_types: row,
                                          });
                                        }}
                                        value={e.ppn}
                                        placeholder="Fakthur Pazak No"
                                        InputProps={{
                                          style: { textTransform: "uppercase" },
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <div>
                                      {e.ppn}
                                      <i
                                        className="fa fa-pencil edit_icon text-right"
                                        style={{ float: "right" }}
                                        onClick={() => {
                                          let row = this.state.costing_types;
                                          let rows = row.sac_charges_data;
                                          rows[idx].ppn_editable = true;
                                          this.setState({ costing_types: row });
                                        }}
                                      />
                                    </div>
                                  )}
                                </TableCell>
                                <TableCell>
                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                      autoOk={true}
                                      inputVariant="outlined"
                                      variant="inline"
                                      margin="dense"
                                      fullWidth
                                      format="dd/MM/yyyy"
                                      value={e.ppn_date}
                                      error={this.state.date_of_paymentError}
                                      onChange={(date) => {
                                        let row = this.state.costing_types;
                                        let rows = row.sac_charges_data;
                                        rows[idx].ppn_date =
                                          this.dateFormat(date);
                                        this.setState({
                                          stevedore_costing: row,
                                        });
                                      }}
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <IconButton>
                                              <EventIcon />
                                            </IconButton>
                                          </InputAdornment>
                                        ),
                                        className: "pl-0",
                                      }}
                                    />
                                  </MuiPickersUtilsProvider>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          ))}
                          {summary.sac_charges_data.length > 0 && (
                            <TableBody>
                              <TableRow>
                                <TableCell colSpan={5}></TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.toLocaleString(
                                    this.state.barge_sac_invoice_value
                                  )}
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.toLocaleString(
                                    this.state.barge_sac_vat_value
                                  )}
                                </TableCell>
                                <TableCell colSpan={2} />
                              </TableRow>
                            </TableBody>
                          )}

                          {summary.pre_shipment_data.length > 0 && (
                            <TableBody>
                              {/* <TableRow><TableCell colSpan={8} style={{ color: config.themeColor }}>Barge Pre Shipment</TableCell></TableRow> */}
                              {summary.pre_shipment_data.map((e, idx) => (
                                <TableRow key={idx}>
                                  <TableCell
                                    nowrap="true"
                                    className="text-uppercase"
                                  >
                                    {e.vendor_name ? e.vendor_name : "-"}
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    className="text-uppercase"
                                  >
                                    {e.tax_id ? e.tax_id : "-"}
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    className="text-uppercase"
                                  >
                                    Pre Shipment
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    className="text-uppercase"
                                  >
                                    {e.invoice_no ? e.invoice_no : "-"}
                                  </TableCell>
                                  <TableCell nowrap="true">
                                    {e.invoice_date
                                      ? this.getLocalDate(e.invoice_date)
                                      : "-"}
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    className="text-right"
                                  >
                                    {this.toLocaleString(e.invoice_value)}
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    className="text-right"
                                  >
                                    {this.toLocaleString(e.vat_value)}
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    style={{ minWidth: 160 }}
                                    className="text-uppercase"
                                  >
                                    {e.ppn_editable || e.ppn === null ? (
                                      <div className="row">
                                        <TextField
                                          name="ppn"
                                          margin="dense"
                                          variant="outlined"
                                          //fullWidth
                                          onChange={(e) => {
                                            let row = this.state.costing_types;
                                            let rows = row.pre_shipment_data;
                                            rows[idx].ppn = e.target.value;
                                            rows[idx].ppn_editable = true;

                                            this.setState({
                                              costing_types: row,
                                            });
                                          }}
                                          value={e.ppn}
                                          placeholder="Fakthur Pazak No"
                                          InputProps={{
                                            style: {
                                              textTransform: "uppercase",
                                            },
                                          }}
                                        />
                                      </div>
                                    ) : (
                                      <div>
                                        {e.ppn}
                                        <i
                                          className="fa fa-pencil edit_icon text-right"
                                          style={{ float: "right" }}
                                          onClick={() => {
                                            let row = this.state.costing_types;
                                            let rows = row.pre_shipment_data;
                                            rows[idx].ppn_editable = true;
                                            this.setState({
                                              costing_types: row,
                                            });
                                          }}
                                        />
                                      </div>
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    <MuiPickersUtilsProvider
                                      utils={DateFnsUtils}
                                    >
                                      <DatePicker
                                        autoOk={true}
                                        inputVariant="outlined"
                                        variant="inline"
                                        margin="dense"
                                        fullWidth
                                        format="dd/MM/yyyy"
                                        value={e.ppn_date}
                                        error={this.state.date_of_paymentError}
                                        onChange={(date) => {
                                          let row = this.state.costing_types;
                                          let rows = row.pre_shipment_data;
                                          rows[idx].ppn_date =
                                            this.dateFormat(date);
                                          this.setState({
                                            stevedore_costing: row,
                                          });
                                        }}
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              <IconButton>
                                                <EventIcon />
                                              </IconButton>
                                            </InputAdornment>
                                          ),
                                          className: "pl-0",
                                        }}
                                      />
                                    </MuiPickersUtilsProvider>
                                  </TableCell>
                                </TableRow>
                              ))}
                              <TableRow>
                                <TableCell colSpan={5}></TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.toLocaleString(
                                    this.state.barge_pre_invoice_value
                                  )}
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.toLocaleString(
                                    this.state.barge_pre_vat_value
                                  )}
                                </TableCell>
                                <TableCell colSpan={2} />
                              </TableRow>
                            </TableBody>
                          )}

                          {summary.cpc_costing_data.length > 0 && (
                            <TableBody>
                              {/* <TableRow><TableCell colSpan={8} style={{ color: config.themeColor }}>Barge Coal Procurement Charges</TableCell></TableRow> */}
                              {summary.cpc_costing_data.map((e, idx) => (
                                <TableRow key={idx}>
                                  <TableCell
                                    nowrap="true"
                                    className="text-uppercase"
                                  >
                                    {e.vendor_name ? e.vendor_name : "-"}
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    className="text-uppercase"
                                  >
                                    {e.tax_id ? e.tax_id : "-"}
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    className="text-uppercase"
                                  >
                                    Coal Procurement Charges
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    className="text-uppercase"
                                  >
                                    {e.invoice_no ? e.invoice_no : "-"}
                                  </TableCell>
                                  <TableCell nowrap="true">
                                    {e.invoice_date
                                      ? this.getLocalDate(e.invoice_date)
                                      : "-"}
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    className="text-right"
                                  >
                                    {this.toLocaleString(e.invoice_value)}
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    className="text-right"
                                  >
                                    {this.toLocaleString(e.vat_value)}
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    style={{ minWidth: 160 }}
                                    className="text-uppercase"
                                  >
                                    {e.ppn_editable || e.ppn === null ? (
                                      <div className="row">
                                        <TextField
                                          name="ppn"
                                          margin="dense"
                                          variant="outlined"
                                          //fullWidth
                                          onChange={(e) => {
                                            let row = this.state.costing_types;
                                            let rows = row.cpc_costing_data;
                                            rows[idx].ppn = e.target.value;
                                            rows[idx].ppn_editable = true;

                                            this.setState({
                                              costing_types: row,
                                            });
                                          }}
                                          value={e.ppn}
                                          placeholder="Fakthur Pazak No"
                                          InputProps={{
                                            style: {
                                              textTransform: "uppercase",
                                            },
                                          }}
                                        />
                                      </div>
                                    ) : (
                                      <div>
                                        {e.ppn}
                                        <i
                                          className="fa fa-pencil edit_icon text-right"
                                          style={{ float: "right" }}
                                          onClick={() => {
                                            let row = this.state.costing_types;
                                            let rows = row.cpc_costing_data;
                                            rows[idx].ppn_editable = true;
                                            this.setState({
                                              costing_types: row,
                                            });
                                          }}
                                        />
                                      </div>
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    <MuiPickersUtilsProvider
                                      utils={DateFnsUtils}
                                    >
                                      <DatePicker
                                        autoOk={true}
                                        inputVariant="outlined"
                                        variant="inline"
                                        margin="dense"
                                        fullWidth
                                        format="dd/MM/yyyy"
                                        value={e.ppn_date}
                                        error={this.state.date_of_paymentError}
                                        onChange={(date) => {
                                          let row = this.state.costing_types;
                                          let rows = row.cpc_costing_data;
                                          rows[idx].ppn_date =
                                            this.dateFormat(date);
                                          this.setState({
                                            stevedore_costing: row,
                                          });
                                        }}
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              <IconButton>
                                                <EventIcon />
                                              </IconButton>
                                            </InputAdornment>
                                          ),
                                          className: "pl-0",
                                        }}
                                      />
                                    </MuiPickersUtilsProvider>
                                  </TableCell>
                                </TableRow>
                              ))}
                              <TableRow>
                                <TableCell colSpan={5}></TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.toLocaleString(
                                    this.state.barge_cpc_invoice_value
                                  )}
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.toLocaleString(
                                    this.state.barge_cpc_vat_value
                                  )}
                                </TableCell>
                                <TableCell colSpan={2} />
                              </TableRow>
                            </TableBody>
                          )}
                          {summary.cps_costing_data.length > 0 && (
                            <TableBody>
                              {/* <TableRow><TableCell colSpan={8} style={{ color: config.themeColor }}>Barge Coal Procurement Service</TableCell></TableRow> */}
                              {summary.cps_costing_data.map((e, idx) => (
                                <TableRow key={idx}>
                                  <TableCell
                                    nowrap="true"
                                    className="text-uppercase"
                                  >
                                    {e.vendor_name ? e.vendor_name : "-"}
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    className="text-uppercase"
                                  >
                                    {e.tax_id ? e.tax_id : "-"}
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    className="text-uppercase"
                                  >
                                    Coal Procurement Service
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    className="text-uppercase"
                                  >
                                    {e.invoice_no ? e.invoice_no : "-"}
                                  </TableCell>
                                  <TableCell nowrap="true">
                                    {e.invoice_date
                                      ? this.getLocalDate(e.invoice_date)
                                      : "-"}
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    className="text-right"
                                  >
                                    {this.toLocaleString(e.invoice_value)}
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    className="text-right"
                                  >
                                    {this.toLocaleString(e.vat_value)}
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    style={{ minWidth: 160 }}
                                    className="text-uppercase"
                                  >
                                    {e.ppn_editable || e.ppn === null ? (
                                      <div className="row">
                                        <TextField
                                          name="ppn"
                                          margin="dense"
                                          variant="outlined"
                                          //fullWidth
                                          onChange={(e) => {
                                            let row = this.state.costing_types;
                                            let rows = row.cps_costing_data;
                                            rows[idx].ppn = e.target.value;
                                            rows[idx].ppn_editable = true;

                                            this.setState({
                                              costing_types: row,
                                            });
                                          }}
                                          value={e.ppn}
                                          placeholder="Fakthur Pazak No"
                                          InputProps={{
                                            style: {
                                              textTransform: "uppercase",
                                            },
                                          }}
                                        />
                                      </div>
                                    ) : (
                                      <div>
                                        {e.ppn}
                                        <i
                                          className="fa fa-pencil edit_icon text-right"
                                          style={{ float: "right" }}
                                          onClick={() => {
                                            let row = this.state.costing_types;
                                            let rows = row.cps_costing_data;
                                            rows[idx].ppn_editable = true;
                                            this.setState({
                                              costing_types: row,
                                            });
                                          }}
                                        />
                                      </div>
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    <MuiPickersUtilsProvider
                                      utils={DateFnsUtils}
                                    >
                                      <DatePicker
                                        autoOk={true}
                                        inputVariant="outlined"
                                        variant="inline"
                                        margin="dense"
                                        fullWidth
                                        format="dd/MM/yyyy"
                                        value={e.ppn_date}
                                        error={this.state.date_of_paymentError}
                                        onChange={(date) => {
                                          let row = this.state.costing_types;
                                          let rows = row.cps_costing_data;
                                          rows[idx].ppn_date =
                                            this.dateFormat(date);
                                          this.setState({
                                            stevedore_costing: row,
                                          });
                                        }}
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              <IconButton>
                                                <EventIcon />
                                              </IconButton>
                                            </InputAdornment>
                                          ),
                                          className: "pl-0",
                                        }}
                                      />
                                    </MuiPickersUtilsProvider>
                                  </TableCell>
                                </TableRow>
                              ))}
                              <TableRow>
                                <TableCell colSpan={5}></TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.toLocaleString(
                                    this.state.barge_cps_invoice_value
                                  )}
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.toLocaleString(
                                    this.state.barge_cps_vat_value
                                  )}
                                </TableCell>
                                <TableCell colSpan={2} />
                              </TableRow>
                            </TableBody>
                          )}

                          {summary.csc_costing_data.length > 0 && (
                            <TableBody>
                              <TableRow>
                                <TableCell
                                  colSpan={8}
                                  style={{ color: config.themeColor }}
                                >
                                  Barge Coal Sales Charges
                                </TableCell>
                              </TableRow>
                              {summary.csc_costing_data.map((e, idx) => (
                                <TableRow key={idx}>
                                  <TableCell
                                    nowrap="true"
                                    className="text-uppercase"
                                  >
                                    {e.vendor_name ? e.vendor_name : "-"}
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    className="text-uppercase"
                                  >
                                    {e.tax_id ? e.tax_id : "-"}
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    className="text-uppercase"
                                  >
                                    Coal Sales Charges
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    className="text-uppercase"
                                  >
                                    {e.invoice_no ? e.invoice_no : "-"}
                                  </TableCell>
                                  <TableCell nowrap="true">
                                    {e.invoice_date
                                      ? this.getLocalDate(e.invoice_date)
                                      : "-"}
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    className="text-right"
                                  >
                                    {this.toLocaleString(e.invoice_value)}
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    className="text-right"
                                  >
                                    {this.toLocaleString(e.vat_value)}
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    style={{ minWidth: 160 }}
                                    className="text-uppercase"
                                  >
                                    {e.ppn_editable || e.ppn === null ? (
                                      <div className="row">
                                        <TextField
                                          name="ppn"
                                          margin="dense"
                                          variant="outlined"
                                          //fullWidth
                                          onChange={(e) => {
                                            let row = this.state.costing_types;
                                            let rows = row.csc_costing_data;
                                            rows[idx].ppn = e.target.value;
                                            rows[idx].ppn_editable = true;

                                            this.setState({
                                              costing_types: row,
                                            });
                                          }}
                                          value={e.ppn}
                                          placeholder="Fakthur Pazak No"
                                          InputProps={{
                                            style: {
                                              textTransform: "uppercase",
                                            },
                                          }}
                                        />
                                      </div>
                                    ) : (
                                      <div>
                                        {e.ppn}
                                        <i
                                          className="fa fa-pencil edit_icon text-right"
                                          style={{ float: "right" }}
                                          onClick={() => {
                                            let row = this.state.costing_types;
                                            let rows = row.csc_costing_data;
                                            rows[idx].ppn_editable = true;
                                            this.setState({
                                              costing_types: row,
                                            });
                                          }}
                                        />
                                      </div>
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    <MuiPickersUtilsProvider
                                      utils={DateFnsUtils}
                                    >
                                      <DatePicker
                                        autoOk={true}
                                        inputVariant="outlined"
                                        variant="inline"
                                        margin="dense"
                                        fullWidth
                                        format="dd/MM/yyyy"
                                        value={e.ppn_date}
                                        error={this.state.date_of_paymentError}
                                        onChange={(date) => {
                                          let row = this.state.costing_types;
                                          let rows = row.csc_costing_data;
                                          rows[idx].ppn_date =
                                            this.dateFormat(date);
                                          this.setState({
                                            stevedore_costing: row,
                                          });
                                        }}
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              <IconButton>
                                                <EventIcon />
                                              </IconButton>
                                            </InputAdornment>
                                          ),
                                          className: "pl-0",
                                        }}
                                      />
                                    </MuiPickersUtilsProvider>
                                  </TableCell>
                                </TableRow>
                              ))}
                              <TableRow>
                                <TableCell colSpan={5}></TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.toLocaleString(
                                    this.state.barge_csc_invoice_value
                                  )}
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.toLocaleString(
                                    this.state.barge_csc_vat_value
                                  )}
                                </TableCell>
                                <TableCell colSpan={2} />
                              </TableRow>
                            </TableBody>
                          )}
                          {/* 
                        {summary.mv_stevedore_costing_data.length > 0 &&
                          <TableBody>
                            <TableRow><TableCell colSpan={8} style={{ color: config.themeColor }}>Stevedore Costing</TableCell></TableRow>
                          </TableBody>
                        } */}

                          {summary.mv_stevedore_costing_data.map((e, idx) => (
                            <TableBody key={idx}>
                              <TableRow>
                                <TableCell
                                  nowrap="true"
                                  className="text-uppercase"
                                >
                                  {e.vendor_name ? e.vendor_name : "-"}
                                </TableCell>
                                <TableCell
                                  nowrap="true"
                                  className="text-uppercase"
                                >
                                  {e.tax_id ? e.tax_id : "-"}
                                </TableCell>
                                <TableCell
                                  nowrap="true"
                                  className="text-uppercase"
                                >
                                  Stevedore Costing
                                </TableCell>
                                <TableCell
                                  nowrap="true"
                                  className="text-uppercase"
                                >
                                  {e.invoice_no ? e.invoice_no : "-"}
                                </TableCell>
                                <TableCell nowrap="true">
                                  {e.invoice_date
                                    ? this.getLocalDate(e.invoice_date)
                                    : "-"}
                                </TableCell>
                                <TableCell nowrap="true" className="text-right">
                                  {this.toLocaleString(e.invoice_value)}
                                </TableCell>
                                <TableCell nowrap="true" className="text-right">
                                  {this.toLocaleString(e.vat_value)}
                                </TableCell>
                                <TableCell
                                  nowrap="true"
                                  className="text-uppercase"
                                >
                                  {e.ppn_editable || e.ppn === null ? (
                                    <div className="row">
                                      <TextField
                                        name="ppn"
                                        margin="dense"
                                        variant="outlined"
                                        //fullWidth
                                        onChange={(e) => {
                                          let row = this.state.costing_types;
                                          let rows =
                                            row.mv_stevedore_costing_data;
                                          rows[idx].ppn = e.target.value;
                                          rows[idx].ppn_editable = true;

                                          this.setState({
                                            costing_types: row,
                                          });
                                        }}
                                        value={e.ppn}
                                        placeholder="Fakthur Pazak No"
                                        InputProps={{
                                          style: { textTransform: "uppercase" },
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <div>
                                      {e.ppn}
                                      <i
                                        className="fa fa-pencil edit_icon text-right"
                                        style={{ float: "right" }}
                                        onClick={() => {
                                          let row = this.state.costing_types;
                                          let rows =
                                            row.mv_stevedore_costing_data;
                                          rows[idx].ppn_editable = true;
                                          this.setState({ costing_types: row });
                                        }}
                                      />
                                    </div>
                                  )}
                                </TableCell>
                                <TableCell>
                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                      autoOk={true}
                                      inputVariant="outlined"
                                      variant="inline"
                                      margin="dense"
                                      fullWidth
                                      format="dd/MM/yyyy"
                                      value={e.ppn_date}
                                      error={this.state.date_of_paymentError}
                                      onChange={(date) => {
                                        let row = this.state.costing_types;
                                        let rows =
                                          row.mv_stevedore_costing_data;
                                        rows[idx].ppn_date =
                                          this.dateFormat(date);
                                        this.setState({
                                          stevedore_costing: row,
                                        });
                                      }}
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <IconButton>
                                              <EventIcon />
                                            </IconButton>
                                          </InputAdornment>
                                        ),
                                        className: "pl-0",
                                      }}
                                    />
                                  </MuiPickersUtilsProvider>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          ))}

                          {summary.mv_stevedore_costing_data.length > 0 && (
                            <TableBody>
                              <TableRow>
                                <TableCell colSpan={5}></TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.toLocaleString(
                                    this.state.mv_stevedore_invoice_value
                                  )}
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.toLocaleString(
                                    this.state.mv_stevedore_vat_value
                                  )}
                                </TableCell>
                                <TableCell colSpan={2} />
                              </TableRow>
                            </TableBody>
                          )}
                          {/* {summary.mv_floating_crane_charges_data.length > 0 &&
                          <TableBody>
                            <TableRow><TableCell colSpan={8} style={{ color: config.themeColor }}>Floating Crane Charges</TableCell></TableRow>

                          </TableBody>
                        } */}
                          {summary.mv_floating_crane_charges_data.map(
                            (e, idx) => (
                              <TableBody key={idx}>
                                <TableRow>
                                  <TableCell
                                    nowrap="true"
                                    className="text-uppercase"
                                  >
                                    {e.vendor_name ? e.vendor_name : "-"}
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    className="text-uppercase"
                                  >
                                    {e.tax_id ? e.tax_id : "-"}
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    className="text-uppercase"
                                  >
                                    Floating Crane
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    className="text-uppercase"
                                  >
                                    {e.invoice_no ? e.invoice_no : "-"}
                                  </TableCell>
                                  <TableCell nowrap="true">
                                    {e.invoice_date
                                      ? this.getLocalDate(e.invoice_date)
                                      : "-"}
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    className="text-right"
                                  >
                                    {this.toLocaleString(e.invoice_value)}
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    className="text-right"
                                  >
                                    {this.toLocaleString(e.vat_value)}
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    className="text-uppercase"
                                  >
                                    {e.ppn === null || e.ppn_editable ? (
                                      <div className="row">
                                        <TextField
                                          name="ppn"
                                          margin="dense"
                                          variant="outlined"
                                          //fullWidth
                                          onChange={(e) => {
                                            let row = this.state.costing_types;
                                            let rows =
                                              row.mv_floating_crane_charges_data;
                                            rows[idx].ppn = e.target.value;
                                            this.setState({
                                              costing_types: row,
                                            });
                                          }}
                                          value={e.ppn}
                                          placeholder="Fakthur Pazak No"
                                          InputProps={{
                                            style: {
                                              textTransform: "uppercase",
                                            },
                                          }}
                                        />
                                      </div>
                                    ) : (
                                      <div>
                                        {e.ppn}
                                        <i
                                          className="fa fa-pencil edit_icon text-right"
                                          style={{ float: "right" }}
                                          onClick={() => {
                                            let row = this.state.costing_types;
                                            let rows =
                                              row.mv_floating_crane_charges_data;
                                            rows[idx].ppn_editable = true;
                                            this.setState({
                                              costing_types: row,
                                            });
                                          }}
                                        />
                                      </div>
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    <MuiPickersUtilsProvider
                                      utils={DateFnsUtils}
                                    >
                                      <DatePicker
                                        autoOk={true}
                                        inputVariant="outlined"
                                        variant="inline"
                                        margin="dense"
                                        fullWidth
                                        format="dd/MM/yyyy"
                                        value={e.ppn_date}
                                        error={this.state.date_of_paymentError}
                                        onChange={(date) => {
                                          let row = this.state.costing_types;
                                          let rows =
                                            row.mv_floating_crane_charges_data;
                                          rows[idx].ppn_date =
                                            this.dateFormat(date);
                                          this.setState({
                                            stevedore_costing: row,
                                          });
                                        }}
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              <IconButton>
                                                <EventIcon />
                                              </IconButton>
                                            </InputAdornment>
                                          ),
                                          className: "pl-0",
                                        }}
                                      />
                                    </MuiPickersUtilsProvider>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            )
                          )}
                          {summary.mv_floating_crane_charges_data.length >
                            0 && (
                            <TableBody>
                              <TableRow>
                                <TableCell colSpan={5}></TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.toLocaleString(
                                    this.state.mv_floating_invoice_value
                                  )}
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.toLocaleString(
                                    this.state.mv_floating_vat_value
                                  )}
                                </TableCell>
                                <TableCell colSpan={2} />
                              </TableRow>
                            </TableBody>
                          )}

                          {summary.mv_freight_costing_data.length > 0 && (
                            <TableBody>
                              {/* <TableRow><TableCell colSpan={8} style={{ color: config.themeColor }}>Freight Costing</TableCell></TableRow> */}
                              {summary.mv_freight_costing_data.map((e, idx) => (
                                <TableRow key={idx}>
                                  <TableCell
                                    nowrap="true"
                                    className="text-uppercase"
                                  >
                                    {e.vendor_name}
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    className="text-uppercase"
                                  >
                                    {e.tax_id ? e.tax_id : "-"}
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    className="text-uppercase"
                                  >
                                    Freight Costing
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    className="text-uppercase"
                                  >
                                    {e.invoice_no ? e.invoice_no : "-"}
                                  </TableCell>
                                  <TableCell nowrap="true">
                                    {e.invoice_date
                                      ? this.getLocalDate(e.invoice_date)
                                      : "-"}
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    className="text-right"
                                  >
                                    {this.toLocaleString(e.invoice_value)}
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    className="text-right"
                                  >
                                    {this.toLocaleString(e.vat_value)}
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    style={{ minWidth: 160 }}
                                    className="text-uppercase"
                                  >
                                    {e.ppn_editable || e.ppn === null ? (
                                      <div className="row">
                                        <TextField
                                          name="ppn"
                                          margin="dense"
                                          variant="outlined"
                                          //fullWidth
                                          onChange={(e) => {
                                            let row = this.state.costing_types;
                                            let rows =
                                              row.mv_freight_costing_data;
                                            rows[idx].ppn = e.target.value;
                                            rows[idx].ppn_editable = true;

                                            this.setState({
                                              costing_types: row,
                                            });
                                          }}
                                          value={e.ppn}
                                          placeholder="Fakthur Pazak No"
                                          InputProps={{
                                            style: {
                                              textTransform: "uppercase",
                                            },
                                          }}
                                        />
                                      </div>
                                    ) : (
                                      <div>
                                        {e.ppn}
                                        <i
                                          className="fa fa-pencil edit_icon text-right"
                                          style={{ float: "right" }}
                                          onClick={() => {
                                            let row = this.state.costing_types;
                                            let rows =
                                              row.mv_freight_costing_data;
                                            rows[idx].ppn_editable = true;
                                            this.setState({
                                              costing_types: row,
                                            });
                                          }}
                                        />
                                      </div>
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    <MuiPickersUtilsProvider
                                      utils={DateFnsUtils}
                                    >
                                      <DatePicker
                                        autoOk={true}
                                        inputVariant="outlined"
                                        variant="inline"
                                        margin="dense"
                                        fullWidth
                                        format="dd/MM/yyyy"
                                        value={e.ppn_date}
                                        error={this.state.date_of_paymentError}
                                        onChange={(date) => {
                                          let row = this.state.costing_types;
                                          let rows =
                                            row.mv_freight_costing_data;
                                          rows[idx].ppn_date =
                                            this.dateFormat(date);
                                          this.setState({
                                            stevedore_costing: row,
                                          });
                                        }}
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              <IconButton>
                                                <EventIcon />
                                              </IconButton>
                                            </InputAdornment>
                                          ),
                                          className: "pl-0",
                                        }}
                                      />
                                    </MuiPickersUtilsProvider>
                                  </TableCell>
                                </TableRow>
                              ))}
                              <TableRow>
                                <TableCell colSpan={5}></TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.toLocaleString(
                                    this.state.mv_freight_invoice_value
                                  )}
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.toLocaleString(
                                    this.state.mv_freight_vat_value
                                  )}
                                </TableCell>
                                <TableCell colSpan={2} />
                              </TableRow>
                            </TableBody>
                          )}

                          {summary.mv_pur_fin_cpc_data.length > 0 && (
                            <TableBody>
                              {/* <TableRow><TableCell colSpan={8} style={{ color: config.themeColor }}>Mother Vessel Coal Procurement</TableCell></TableRow> */}
                              {summary.mv_pur_fin_cpc_data.map((e, idx) => (
                                <TableRow key={idx}>
                                  <TableCell
                                    nowrap="true"
                                    className="text-uppercase"
                                  >
                                    {e.vendor_name ? e.vendor_name : "-"}
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    className="text-uppercase"
                                  >
                                    {e.tax_id ? e.tax_id : "-"}
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    className="text-uppercase"
                                  >
                                    MV Coal Procurement
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    className="text-uppercase"
                                  >
                                    {e.invoice_no ? e.invoice_no : "-"}
                                  </TableCell>
                                  <TableCell nowrap="true">
                                    {e.invoice_date
                                      ? this.getLocalDate(e.invoice_date)
                                      : "-"}
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    className="text-right"
                                  >
                                    {this.toLocaleString(e.invoice_value)}
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    className="text-right"
                                  >
                                    {this.toLocaleString(e.vat_value)}
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    style={{ minWidth: 160 }}
                                    className="text-uppercase"
                                  >
                                    {e.ppn_editable || e.ppn === null ? (
                                      <div className="row">
                                        <TextField
                                          name="ppn"
                                          margin="dense"
                                          variant="outlined"
                                          //fullWidth
                                          onChange={(e) => {
                                            let row = this.state.costing_types;
                                            let rows = row.mv_pur_fin_cpc_data;
                                            rows[idx].ppn = e.target.value;
                                            rows[idx].ppn_editable = true;

                                            this.setState({
                                              costing_types: row,
                                            });
                                          }}
                                          value={e.ppn}
                                          placeholder="Fakthur Pazak No"
                                          InputProps={{
                                            style: {
                                              textTransform: "uppercase",
                                            },
                                          }}
                                        />
                                      </div>
                                    ) : (
                                      <div>
                                        {e.ppn}
                                        <i
                                          className="fa fa-pencil edit_icon text-right"
                                          style={{ float: "right" }}
                                          onClick={() => {
                                            let row = this.state.costing_types;
                                            let rows = row.mv_pur_fin_cpc_data;
                                            rows[idx].ppn_editable = true;
                                            this.setState({
                                              costing_types: row,
                                            });
                                          }}
                                        />
                                      </div>
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    <MuiPickersUtilsProvider
                                      utils={DateFnsUtils}
                                    >
                                      <DatePicker
                                        autoOk={true}
                                        inputVariant="outlined"
                                        variant="inline"
                                        margin="dense"
                                        fullWidth
                                        format="dd/MM/yyyy"
                                        value={e.ppn_date}
                                        error={this.state.date_of_paymentError}
                                        onChange={(date) => {
                                          let row = this.state.costing_types;
                                          let rows = row.mv_pur_fin_cpc_data;
                                          rows[idx].ppn_date =
                                            this.dateFormat(date);
                                          this.setState({
                                            stevedore_costing: row,
                                          });
                                        }}
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              <IconButton>
                                                <EventIcon />
                                              </IconButton>
                                            </InputAdornment>
                                          ),
                                          className: "pl-0",
                                        }}
                                      />
                                    </MuiPickersUtilsProvider>
                                  </TableCell>
                                </TableRow>
                              ))}
                              <TableRow>
                                <TableCell colSpan={5}></TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.toLocaleString(
                                    this.state.mv_cpc_invoice_value
                                  )}
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.toLocaleString(
                                    this.state.mv_cpc_vat_value
                                  )}
                                </TableCell>
                                <TableCell colSpan={2} />
                              </TableRow>
                            </TableBody>
                          )}
                          {/* 
                        {summary.mv_sac_charges_data.length > 0 &&
                          <TableBody>
                            <TableRow><TableCell colSpan={8} style={{ color: config.themeColor }}>Mother Vessel Sampling & Analaysis</TableCell></TableRow>
                          </TableBody>
                        } */}

                          {summary.mv_sac_charges_data.map((e, idx) => (
                            <TableBody key={idx}>
                              <TableRow>
                                <TableCell
                                  nowrap="true"
                                  className="text-uppercase"
                                >
                                  {e.vendor_name ? e.vendor_name : "-"}
                                </TableCell>
                                <TableCell
                                  nowrap="true"
                                  className="text-uppercase"
                                >
                                  {e.tax_id ? e.tax_id : "-"}
                                </TableCell>
                                <TableCell
                                  nowrap="true"
                                  className="text-uppercase"
                                >
                                  MV Sampling & Analaysis
                                </TableCell>
                                <TableCell
                                  nowrap="true"
                                  className="text-uppercase"
                                >
                                  {e.invoice_no ? e.invoice_no : "-"}
                                </TableCell>
                                <TableCell nowrap="true">
                                  {e.invoice_date
                                    ? this.getLocalDate(e.invoice_date)
                                    : "-"}
                                </TableCell>
                                <TableCell nowrap="true" className="text-right">
                                  {this.toLocaleString(e.invoice_value)}
                                </TableCell>
                                <TableCell nowrap="true" className="text-right">
                                  {this.toLocaleString(e.vat_value)}
                                </TableCell>
                                <TableCell
                                  nowrap="true"
                                  className="text-uppercase"
                                >
                                  {e.ppn_editable || e.ppn === null ? (
                                    <div className="row">
                                      <TextField
                                        name="ppn"
                                        margin="dense"
                                        variant="outlined"
                                        //fullWidth
                                        onChange={(e) => {
                                          let row = this.state.costing_types;
                                          let rows = row.mv_sac_charges_data;
                                          rows[idx].ppn = e.target.value;
                                          rows[idx].ppn_editable = true;

                                          this.setState({
                                            costing_types: row,
                                          });
                                        }}
                                        value={e.ppn}
                                        placeholder="Fakthur Pazak No"
                                        InputProps={{
                                          style: { textTransform: "uppercase" },
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <div>
                                      {e.ppn}
                                      <i
                                        className="fa fa-pencil edit_icon text-right"
                                        style={{ float: "right" }}
                                        onClick={() => {
                                          let row = this.state.costing_types;
                                          let rows = row.mv_sac_charges_data;
                                          rows[idx].ppn_editable = true;
                                          this.setState({ costing_types: row });
                                        }}
                                      />
                                    </div>
                                  )}
                                </TableCell>
                                <TableCell>
                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                      autoOk={true}
                                      inputVariant="outlined"
                                      variant="inline"
                                      margin="dense"
                                      fullWidth
                                      format="dd/MM/yyyy"
                                      value={e.ppn_date}
                                      error={this.state.date_of_paymentError}
                                      onChange={(date) => {
                                        let row = this.state.costing_types;
                                        let rows = row.mv_sac_charges_data;
                                        rows[idx].ppn_date =
                                          this.dateFormat(date);
                                        this.setState({
                                          stevedore_costing: row,
                                        });
                                      }}
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <IconButton>
                                              <EventIcon />
                                            </IconButton>
                                          </InputAdornment>
                                        ),
                                        className: "pl-0",
                                      }}
                                    />
                                  </MuiPickersUtilsProvider>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          ))}
                          {summary.mv_sac_charges_data.length > 0 && (
                            <TableBody>
                              <TableRow>
                                <TableCell colSpan={5}></TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.toLocaleString(
                                    this.state.mv_sac_invoice_value
                                  )}
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.toLocaleString(
                                    this.state.mv_sac_vat_value
                                  )}
                                </TableCell>
                                <TableCell colSpan={2} />
                              </TableRow>
                            </TableBody>
                          )}

                          {summary.mv_pre_shipment_data.length > 0 && (
                            <TableBody>
                              {/* <TableRow><TableCell colSpan={8} style={{ color: config.themeColor }}>Mother Vessel Pre Shipment</TableCell></TableRow> */}
                              {summary.mv_pre_shipment_data.map((e, idx) => (
                                <TableRow key={idx}>
                                  <TableCell
                                    nowrap="true"
                                    className="text-uppercase"
                                  >
                                    {e.vendor_name ? e.vendor_name : "-"}
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    className="text-uppercase"
                                  >
                                    {e.tax_id ? e.tax_id : "-"}
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    className="text-uppercase"
                                  >
                                    MV Pre Shipment
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    className="text-uppercase"
                                  >
                                    {e.invoice_no ? e.invoice_no : "-"}
                                  </TableCell>
                                  <TableCell nowrap="true">
                                    {e.invoice_date
                                      ? this.getLocalDate(e.invoice_date)
                                      : "-"}
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    className="text-right"
                                  >
                                    {this.toLocaleString(e.invoice_value)}
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    className="text-right"
                                  >
                                    {this.toLocaleString(e.vat_value)}
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    style={{ minWidth: 160 }}
                                    className="text-uppercase"
                                  >
                                    {e.ppn_editable || e.ppn === null ? (
                                      <div className="row">
                                        <TextField
                                          name="ppn"
                                          margin="dense"
                                          variant="outlined"
                                          //fullWidth
                                          onChange={(e) => {
                                            let row = this.state.costing_types;
                                            let rows = row.mv_pre_shipment_data;
                                            rows[idx].ppn = e.target.value;
                                            rows[idx].ppn_editable = true;

                                            this.setState({
                                              costing_types: row,
                                            });
                                          }}
                                          value={e.ppn}
                                          placeholder="Fakthur Pazak No"
                                          InputProps={{
                                            style: {
                                              textTransform: "uppercase",
                                            },
                                          }}
                                        />
                                      </div>
                                    ) : (
                                      <div>
                                        {e.ppn}
                                        <i
                                          className="fa fa-pencil edit_icon text-right"
                                          style={{ float: "right" }}
                                          onClick={() => {
                                            let row = this.state.costing_types;
                                            let rows = row.mv_pre_shipment_data;
                                            rows[idx].ppn_editable = true;
                                            this.setState({
                                              costing_types: row,
                                            });
                                          }}
                                        />
                                      </div>
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    <MuiPickersUtilsProvider
                                      utils={DateFnsUtils}
                                    >
                                      <DatePicker
                                        autoOk={true}
                                        inputVariant="outlined"
                                        variant="inline"
                                        margin="dense"
                                        fullWidth
                                        format="dd/MM/yyyy"
                                        value={e.ppn_date}
                                        error={this.state.date_of_paymentError}
                                        onChange={(date) => {
                                          let row = this.state.costing_types;
                                          let rows = row.mv_pre_shipment_data;
                                          rows[idx].ppn_date =
                                            this.dateFormat(date);
                                          this.setState({
                                            stevedore_costing: row,
                                          });
                                        }}
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              <IconButton>
                                                <EventIcon />
                                              </IconButton>
                                            </InputAdornment>
                                          ),
                                          className: "pl-0",
                                        }}
                                      />
                                    </MuiPickersUtilsProvider>
                                  </TableCell>
                                </TableRow>
                              ))}
                              <TableRow>
                                <TableCell colSpan={5}></TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.toLocaleString(
                                    this.state.mv_pre_invoice_value
                                  )}
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.toLocaleString(
                                    this.state.mv_pre_vat_value
                                  )}
                                </TableCell>
                                <TableCell colSpan={2} />
                              </TableRow>
                            </TableBody>
                          )}

                          {/* {summary.mv_doc_charges_data.length > 0 &&
                          <TableBody>
                            <TableRow><TableCell colSpan={8} style={{ color: config.themeColor }}>Mother Vessel Documentation Charges</TableCell></TableRow>
                          </TableBody>
                        } */}

                          {summary.mv_doc_charges_data.map((e, idx) => (
                            <TableBody key={idx}>
                              <TableRow>
                                <TableCell
                                  nowrap="true"
                                  className="text-uppercase"
                                >
                                  {e.vendor_name ? e.vendor_name : "-"}
                                </TableCell>
                                <TableCell
                                  nowrap="true"
                                  className="text-uppercase"
                                >
                                  {e.tax_id ? e.tax_id : "-"}
                                </TableCell>
                                <TableCell
                                  nowrap="true"
                                  className="text-uppercase"
                                >
                                  MV Documentation Charges
                                </TableCell>
                                <TableCell
                                  nowrap="true"
                                  className="text-uppercase"
                                >
                                  {e.invoice_no ? e.invoice_no : "-"}
                                </TableCell>
                                <TableCell nowrap="true">
                                  {e.invoice_date
                                    ? this.getLocalDate(e.invoice_date)
                                    : "-"}
                                </TableCell>
                                <TableCell nowrap="true" className="text-right">
                                  {this.toLocaleString(e.invoice_value)}
                                </TableCell>
                                <TableCell nowrap="true" className="text-right">
                                  {this.toLocaleString(e.vat_value)}
                                </TableCell>
                                <TableCell
                                  nowrap="true"
                                  className="text-uppercase"
                                >
                                  {e.ppn_editable || e.ppn === null ? (
                                    <div className="row">
                                      <TextField
                                        name="ppn"
                                        margin="dense"
                                        variant="outlined"
                                        //fullWidth
                                        onChange={(e) => {
                                          let row = this.state.costing_types;
                                          let rows = row.mv_doc_charges_data;
                                          rows[idx].ppn = e.target.value;
                                          rows[idx].ppn_editable = true;

                                          this.setState({
                                            costing_types: row,
                                          });
                                        }}
                                        value={e.ppn}
                                        placeholder="Fakthur Pazak No"
                                        InputProps={{
                                          style: { textTransform: "uppercase" },
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <div>
                                      {e.ppn}
                                      <i
                                        className="fa fa-pencil edit_icon text-right"
                                        style={{ float: "right" }}
                                        onClick={() => {
                                          let row = this.state.costing_types;
                                          let rows = row.mv_doc_charges_data;
                                          rows[idx].ppn_editable = true;
                                          this.setState({ costing_types: row });
                                        }}
                                      />
                                    </div>
                                  )}
                                </TableCell>
                                <TableCell>
                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                      autoOk={true}
                                      inputVariant="outlined"
                                      variant="inline"
                                      margin="dense"
                                      fullWidth
                                      format="dd/MM/yyyy"
                                      value={e.ppn_date}
                                      error={this.state.date_of_paymentError}
                                      onChange={(date) => {
                                        let row = this.state.costing_types;
                                        let rows = row.mv_doc_charges_data;
                                        rows[idx].ppn_date =
                                          this.dateFormat(date);
                                        this.setState({
                                          stevedore_costing: row,
                                        });
                                      }}
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <IconButton>
                                              <EventIcon />
                                            </IconButton>
                                          </InputAdornment>
                                        ),
                                        className: "pl-0",
                                      }}
                                    />
                                  </MuiPickersUtilsProvider>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          ))}
                          {summary.mv_doc_charges_data.length > 0 && (
                            <TableBody>
                              <TableRow>
                                <TableCell colSpan={5}></TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.toLocaleString(
                                    this.state.mv_doc_invoice_value
                                  )}
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.toLocaleString(
                                    this.state.mv_doc_vat_value
                                  )}
                                </TableCell>
                                <TableCell colSpan={2} />
                              </TableRow>
                            </TableBody>
                          )}
                          {summary.mv_supplier_demurrage.length > 0 && (
                            <TableBody>
                              {/* <TableRow><TableCell colSpan={8} style={{ color: config.themeColor }}>Mother Vessel Supplier Despatch</TableCell></TableRow> */}
                              {summary.mv_supplier_demurrage
                                .filter(
                                  (i) => i.demurrage_or_despatch === "Despatch"
                                )
                                .map((e, idx) => (
                                  <TableRow key={idx}>
                                    <TableCell
                                      nowrap="true"
                                      className="text-uppercase"
                                    >
                                      {e.vendor_name ? e.vendor_name : "-"}
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      className="text-uppercase"
                                    >
                                      {e.tax_id ? e.tax_id : "-"}
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      className="text-uppercase"
                                    >
                                      Mv Supplier Demurrage
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      className="text-uppercase"
                                    >
                                      {e.invoice_no ? e.invoice_no : "-"}
                                    </TableCell>
                                    <TableCell nowrap="true">
                                      {e.invoice_date
                                        ? this.getLocalDate(e.invoice_date)
                                        : "-"}
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      className="text-right"
                                    >
                                      {this.toLocaleString(e.invoice_value)}
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      className="text-right"
                                    >
                                      {this.toLocaleString(e.vat_value)}
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      style={{ minWidth: 160 }}
                                      className="text-uppercase"
                                    >
                                      {e.ppn_editable || e.ppn === null ? (
                                        <div className="row">
                                          <TextField
                                            name="ppn"
                                            margin="dense"
                                            variant="outlined"
                                            //fullWidth
                                            onChange={(e) => {
                                              let row =
                                                this.state.costing_types;
                                              let rows =
                                                row.mv_supplier_demurrage.filter(
                                                  (i) =>
                                                    i.demurrage_or_despatch ===
                                                    "Despatch"
                                                );
                                              rows[idx].ppn = e.target.value;
                                              rows[idx].ppn_editable = true;

                                              this.setState({
                                                costing_types: row,
                                              });
                                            }}
                                            value={e.ppn}
                                            placeholder="Fakthur Pazak No"
                                            InputProps={{
                                              style: {
                                                textTransform: "uppercase",
                                              },
                                            }}
                                          />
                                        </div>
                                      ) : (
                                        <div>
                                          {e.ppn}
                                          <i
                                            className="fa fa-pencil edit_icon text-right"
                                            style={{ float: "right" }}
                                            onClick={() => {
                                              let row =
                                                this.state.costing_types;
                                              let rows =
                                                row.mv_supplier_demurrage.filter(
                                                  (i) =>
                                                    i.demurrage_or_despatch ===
                                                    "Despatch"
                                                );
                                              rows[idx].ppn_editable = true;
                                              this.setState({
                                                costing_types: row,
                                              });
                                            }}
                                          />
                                        </div>
                                      )}
                                    </TableCell>
                                    <TableCell>
                                      <MuiPickersUtilsProvider
                                        utils={DateFnsUtils}
                                      >
                                        <DatePicker
                                          autoOk={true}
                                          inputVariant="outlined"
                                          variant="inline"
                                          margin="dense"
                                          fullWidth
                                          format="dd/MM/yyyy"
                                          value={e.ppn_date}
                                          error={
                                            this.state.date_of_paymentError
                                          }
                                          onChange={(date) => {
                                            let row = this.state.costing_types;
                                            let rows =
                                              row.mv_supplier_demurrage.filter(
                                                (i) =>
                                                  i.demurrage_or_despatch ===
                                                  "Despatch"
                                              );
                                            rows[idx].ppn_date =
                                              this.dateFormat(date);
                                            this.setState({
                                              stevedore_costing: row,
                                            });
                                          }}
                                          InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <IconButton>
                                                  <EventIcon />
                                                </IconButton>
                                              </InputAdornment>
                                            ),
                                            className: "pl-0",
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              <TableRow>
                                <TableCell colSpan={5}></TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.toLocaleString(
                                    this.state.mv_sup_dem_invoice_value
                                  )}
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.toLocaleString(
                                    this.state.mv_sup_dem_vat_value
                                  )}
                                </TableCell>
                                <TableCell colSpan={2} />
                              </TableRow>
                            </TableBody>
                          )}
                          {summary.mv_buyer_demurrage.filter(
                            (i) => i.demurrage_or_despatch === "Demurrage"
                          ).length > 0 && (
                            <TableBody>
                              {/* <TableRow><TableCell colSpan={8} style={{ color: config.themeColor }}>Mother Vessel Buyer Demurrage</TableCell></TableRow> */}
                              {summary.mv_buyer_demurrage
                                .filter(
                                  (i) => i.demurrage_or_despatch === "Demurrage"
                                )
                                .map((e, idx) => (
                                  <TableRow key={idx}>
                                    <TableCell
                                      nowrap="true"
                                      className="text-uppercase"
                                    >
                                      {e.vendor_name ? e.vendor_name : "-"}
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      className="text-uppercase"
                                    >
                                      {e.tax_id ? e.tax_id : "-"}
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      className="text-uppercase"
                                    >
                                      MV Buyer Demurrage
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      className="text-uppercase"
                                    >
                                      {e.invoice_no ? e.invoice_no : "-"}
                                    </TableCell>
                                    <TableCell nowrap="true">
                                      {e.invoice_date
                                        ? this.getLocalDate(e.invoice_date)
                                        : "-"}
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      className="text-right"
                                    >
                                      {this.toLocaleString(e.invoice_value)}
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      className="text-right"
                                    >
                                      {this.toLocaleString(e.vat_value)}
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      style={{ minWidth: 160 }}
                                      className="text-uppercase"
                                    >
                                      {e.ppn_editable || e.ppn === null ? (
                                        <div className="row">
                                          <TextField
                                            name="ppn"
                                            margin="dense"
                                            variant="outlined"
                                            //fullWidth
                                            onChange={(e) => {
                                              let row =
                                                this.state.costing_types;
                                              let rows =
                                                row.mv_buyer_demurrage.filter(
                                                  (i) =>
                                                    i.demurrage_or_despatch ===
                                                    "Demurrage"
                                                );
                                              rows[idx].ppn = e.target.value;
                                              rows[idx].ppn_editable = true;

                                              this.setState({
                                                costing_types: row,
                                              });
                                            }}
                                            value={e.ppn}
                                            placeholder="Fakthur Pazak No"
                                            InputProps={{
                                              style: {
                                                textTransform: "uppercase",
                                              },
                                            }}
                                          />
                                        </div>
                                      ) : (
                                        <div>
                                          {e.ppn}
                                          <i
                                            className="fa fa-pencil edit_icon text-right"
                                            style={{ float: "right" }}
                                            onClick={() => {
                                              let row =
                                                this.state.costing_types;
                                              let rows =
                                                row.mv_buyer_demurrage.filter(
                                                  (i) =>
                                                    i.demurrage_or_despatch ===
                                                    "Demurrage"
                                                );
                                              rows[idx].ppn_editable = true;
                                              this.setState({
                                                costing_types: row,
                                              });
                                            }}
                                          />
                                        </div>
                                      )}
                                    </TableCell>
                                    <TableCell>
                                      <MuiPickersUtilsProvider
                                        utils={DateFnsUtils}
                                      >
                                        <DatePicker
                                          autoOk={true}
                                          inputVariant="outlined"
                                          variant="inline"
                                          margin="dense"
                                          fullWidth
                                          format="dd/MM/yyyy"
                                          value={e.ppn_date}
                                          error={
                                            this.state.date_of_paymentError
                                          }
                                          onChange={(date) => {
                                            let row = this.state.costing_types;
                                            let rows =
                                              row.mv_buyer_demurrage.filter(
                                                (i) =>
                                                  i.demurrage_or_despatch ===
                                                  "Demurrage"
                                              );
                                            rows[idx].ppn_date =
                                              this.dateFormat(date);
                                            this.setState({
                                              stevedore_costing: row,
                                            });
                                          }}
                                          InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <IconButton>
                                                  <EventIcon />
                                                </IconButton>
                                              </InputAdornment>
                                            ),
                                            className: "pl-0",
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              <TableRow>
                                <TableCell colSpan={5}></TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.toLocaleString(
                                    this.state.mv_buy_dem_invoice_value
                                  )}
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.toLocaleString(
                                    this.state.mv_buy_dem_vat_value
                                  )}
                                </TableCell>
                                <TableCell colSpan={2} />
                              </TableRow>
                            </TableBody>
                          )}
                          {/* {(summary.barge_demurrage_to_barge_vendor_data.length > 0 || summary.barge_demurrage_to_supplier_data.length > 0 || summary.barge_demurrage_to_buyer_data.length > 0) &&
                          <TableBody>
                            <TableRow><TableCell colSpan={8} style={{ color: config.themeColor }}>Barge Demurrage</TableCell></TableRow>

                          </TableBody>
                        } */}
                          {summary.barge_demurrage_to_barge_vendor_data.length >
                            0 && (
                            <TableBody>
                              {summary.barge_demurrage_to_barge_vendor_data.map(
                                (e, idx) => (
                                  <TableRow key={idx}>
                                    <TableCell
                                      nowrap="true"
                                      className="text-uppercase"
                                    >
                                      {e.vendor_name ? e.vendor_name : "-"}
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      className="text-uppercase"
                                    >
                                      {e.tax_id ? e.tax_id : "-"}
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      className="text-uppercase"
                                    >
                                      Barge Demurrage
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      className="text-uppercase"
                                    >
                                      {e.invoice_no ? e.invoice_no : "-"}
                                    </TableCell>
                                    <TableCell nowrap="true">
                                      {e.invoice_date
                                        ? this.getLocalDate(e.invoice_date)
                                        : "-"}
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      className="text-right"
                                    >
                                      {this.toLocaleString(e.invoice_value)}
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      className="text-right"
                                    >
                                      {this.toLocaleString(e.vat_value)}
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      style={{ minWidth: 160 }}
                                      className="text-uppercase"
                                    >
                                      {e.ppn_editable || e.ppn === null ? (
                                        <div className="row">
                                          <TextField
                                            name="ppn"
                                            margin="dense"
                                            variant="outlined"
                                            //fullWidth
                                            onChange={(e) => {
                                              let row =
                                                this.state.costing_types;
                                              let rows =
                                                row.barge_demurrage_to_barge_vendor_data;
                                              rows[idx].ppn = e.target.value;
                                              rows[idx].ppn_editable = true;

                                              this.setState({
                                                costing_types: row,
                                              });
                                            }}
                                            value={e.ppn}
                                            placeholder="Fakthur Pazak No"
                                            InputProps={{
                                              style: {
                                                textTransform: "uppercase",
                                              },
                                            }}
                                          />
                                        </div>
                                      ) : (
                                        <div>
                                          {e.ppn}
                                          <i
                                            className="fa fa-pencil edit_icon text-right"
                                            style={{ float: "right" }}
                                            onClick={() => {
                                              let row =
                                                this.state.costing_types;
                                              let rows =
                                                row.barge_demurrage_to_barge_vendor_data;
                                              rows[idx].ppn_editable = true;
                                              this.setState({
                                                costing_types: row,
                                              });
                                            }}
                                          />
                                        </div>
                                      )}
                                    </TableCell>
                                    <TableCell>
                                      <MuiPickersUtilsProvider
                                        utils={DateFnsUtils}
                                      >
                                        <DatePicker
                                          autoOk={true}
                                          inputVariant="outlined"
                                          variant="inline"
                                          margin="dense"
                                          fullWidth
                                          format="dd/MM/yyyy"
                                          value={e.ppn_date}
                                          error={
                                            this.state.date_of_paymentError
                                          }
                                          onChange={(date) => {
                                            let row = this.state.costing_types;
                                            let rows =
                                              row.barge_demurrage_to_barge_vendor_data;
                                            rows[idx].ppn_date =
                                              this.dateFormat(date);
                                            this.setState({
                                              stevedore_costing: row,
                                            });
                                          }}
                                          InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <IconButton>
                                                  <EventIcon />
                                                </IconButton>
                                              </InputAdornment>
                                            ),
                                            className: "pl-0",
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </TableCell>
                                  </TableRow>
                                )
                              )}
                              <TableRow>
                                <TableCell colSpan={5}></TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.toLocaleString(
                                    this.state.dmr_to_vend_invoice_value
                                  )}
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.toLocaleString(
                                    this.state.dmr_to_vend_vat_value
                                  )}
                                </TableCell>
                                <TableCell colSpan={2} />
                              </TableRow>
                            </TableBody>
                          )}
                          {summary.barge_demurrage_to_supplier_data.length >
                            0 && (
                            <TableBody>
                              {summary.barge_demurrage_to_supplier_data.map(
                                (e, idx) => (
                                  <TableRow key={idx}>
                                    {/* <TableCell >{config.company} to Supplier - {e.supplier_name}</TableCell> */}
                                    <TableCell
                                      nowrap="true"
                                      className="text-uppercase"
                                    >
                                      {e.vendor_name ? e.vendor_name : "-"}
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      className="text-uppercase"
                                    >
                                      {e.tax_id ? e.tax_id : "-"}
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      className="text-uppercase"
                                    >
                                      {config.company} to Supplier{" "}
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      className="text-uppercase"
                                    >
                                      {e.invoice_no ? e.invoice_no : "-"}
                                    </TableCell>
                                    <TableCell nowrap="true">
                                      {e.invoice_date
                                        ? this.getLocalDate(e.invoice_date)
                                        : "-"}
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      className="text-right"
                                    >
                                      {this.toLocaleString(e.invoice_value)}
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      className="text-right"
                                    >
                                      {this.toLocaleString(e.vat_value)}
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      style={{ minWidth: 160 }}
                                      className="text-uppercase"
                                    >
                                      {e.ppn_editable || e.ppn === null ? (
                                        <div className="row">
                                          <TextField
                                            name="ppn"
                                            margin="dense"
                                            variant="outlined"
                                            //fullWidth
                                            onChange={(e) => {
                                              let row =
                                                this.state.costing_types;
                                              let rows =
                                                row.barge_demurrage_to_supplier_data;
                                              rows[idx].ppn = e.target.value;
                                              rows[idx].ppn_editable = true;

                                              this.setState({
                                                costing_types: row,
                                              });
                                            }}
                                            value={e.ppn}
                                            placeholder="Fakthur Pazak No"
                                            InputProps={{
                                              style: {
                                                textTransform: "uppercase",
                                              },
                                            }}
                                          />
                                        </div>
                                      ) : (
                                        <div>
                                          {e.ppn}
                                          <i
                                            className="fa fa-pencil edit_icon text-right"
                                            style={{ float: "right" }}
                                            onClick={() => {
                                              let row =
                                                this.state.costing_types;
                                              let rows =
                                                row.barge_demurrage_to_supplier_data;
                                              rows[idx].ppn_editable = true;
                                              this.setState({
                                                costing_types: row,
                                              });
                                            }}
                                          />
                                        </div>
                                      )}
                                    </TableCell>
                                    <TableCell>
                                      <MuiPickersUtilsProvider
                                        utils={DateFnsUtils}
                                      >
                                        <DatePicker
                                          autoOk={true}
                                          inputVariant="outlined"
                                          variant="inline"
                                          margin="dense"
                                          fullWidth
                                          format="dd/MM/yyyy"
                                          value={e.ppn_date}
                                          error={
                                            this.state.date_of_paymentError
                                          }
                                          onChange={(date) => {
                                            let row = this.state.costing_types;
                                            let rows =
                                              row.barge_demurrage_to_supplier_data;
                                            rows[idx].ppn_date =
                                              this.dateFormat(date);
                                            this.setState({
                                              stevedore_costing: row,
                                            });
                                          }}
                                          InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <IconButton>
                                                  <EventIcon />
                                                </IconButton>
                                              </InputAdornment>
                                            ),
                                            className: "pl-0",
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </TableCell>
                                  </TableRow>
                                )
                              )}
                              <TableRow>
                                <TableCell colSpan={5}></TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.toLocaleString(
                                    this.state.dmr_to_sup_invoice_value
                                  )}
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.toLocaleString(
                                    this.state.dmr_to_sup_vat_value
                                  )}
                                </TableCell>
                                <TableCell colSpan={2} />
                              </TableRow>
                            </TableBody>
                          )}
                          {summary.barge_demurrage_to_buyer_data.length > 0 && (
                            <TableBody>
                              {summary.barge_demurrage_to_buyer_data.map(
                                (e, idx) => (
                                  <TableRow key={idx}>
                                    {/* <TableCell >{config.company} to Buyer - {e.supplier_name}</TableCell> */}
                                    <TableCell
                                      nowrap="true"
                                      className="text-uppercase"
                                    >
                                      {config.company} to Buyer{" "}
                                      {e.vendor_name ? e.vendor_name : "-"}
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      className="text-uppercase"
                                    >
                                      {e.tax_id ? e.tax_id : "-"}
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      className="text-uppercase"
                                    >
                                      {config.company} to Buyer
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      className="text-uppercase"
                                    >
                                      {e.invoice_no ? e.invoice_no : "-"}
                                    </TableCell>
                                    <TableCell nowrap="true">
                                      {e.invoice_date
                                        ? this.getLocalDate(e.invoice_date)
                                        : "-"}
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      className="text-right"
                                    >
                                      {this.toLocaleString(e.invoice_value)}
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      className="text-right"
                                    >
                                      {this.toLocaleString(e.vat_value)}
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      style={{ minWidth: 160 }}
                                      className="text-uppercase"
                                    >
                                      {e.ppn_editable || e.ppn === null ? (
                                        <div className="row">
                                          <TextField
                                            name="ppn"
                                            margin="dense"
                                            variant="outlined"
                                            //fullWidth
                                            onChange={(e) => {
                                              let row =
                                                this.state.costing_types;
                                              let rows =
                                                row.barge_demurrage_to_buyer_data;
                                              rows[idx].ppn = e.target.value;
                                              rows[idx].ppn_editable = true;

                                              this.setState({
                                                costing_types: row,
                                              });
                                            }}
                                            value={e.ppn}
                                            placeholder="Fakthur Pazak No"
                                            InputProps={{
                                              style: {
                                                textTransform: "uppercase",
                                              },
                                            }}
                                          />
                                        </div>
                                      ) : (
                                        <div>
                                          {e.ppn}
                                          <i
                                            className="fa fa-pencil edit_icon text-right"
                                            style={{ float: "right" }}
                                            onClick={() => {
                                              let row =
                                                this.state.costing_types;
                                              let rows =
                                                row.barge_demurrage_to_buyer_data;
                                              rows[idx].ppn_editable = true;
                                              this.setState({
                                                costing_types: row,
                                              });
                                            }}
                                          />
                                        </div>
                                      )}
                                    </TableCell>
                                    <TableCell>
                                      <MuiPickersUtilsProvider
                                        utils={DateFnsUtils}
                                      >
                                        <DatePicker
                                          autoOk={true}
                                          inputVariant="outlined"
                                          variant="inline"
                                          margin="dense"
                                          fullWidth
                                          format="dd/MM/yyyy"
                                          value={e.ppn_date}
                                          error={
                                            this.state.date_of_paymentError
                                          }
                                          onChange={(date) => {
                                            let row = this.state.costing_types;
                                            let rows =
                                              row.barge_demurrage_to_buyer_data;
                                            rows[idx].ppn_date =
                                              this.dateFormat(date);
                                            this.setState({
                                              stevedore_costing: row,
                                            });
                                          }}
                                          InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <IconButton>
                                                  <EventIcon />
                                                </IconButton>
                                              </InputAdornment>
                                            ),
                                            className: "pl-0",
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </TableCell>
                                  </TableRow>
                                )
                              )}
                              <TableRow>
                                <TableCell colSpan={5}></TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.toLocaleString(
                                    this.state.dmr_to_buyer_invoice_value
                                  )}
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.toLocaleString(
                                    this.state.dmr_to_buyer_vat_value
                                  )}
                                </TableCell>
                                <TableCell colSpan={2} />
                              </TableRow>
                            </TableBody>
                          )}
                          {summary.payable_services.length > 0 && (
                            <TableBody>
                              {/* <TableRow><TableCell colSpan={8} style={{ color: config.themeColor }}>Other Services</TableCell></TableRow> */}
                              {summary.payable_services.map((e, idx) => (
                                <TableRow key={idx}>
                                  <TableCell
                                    nowrap="true"
                                    className="text-uppercase"
                                  >
                                    {e.vendor_name ? e.vendor_name : "-"}
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    className="text-uppercase"
                                  >
                                    {e.tax_id ? e.tax_id : "-"}
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    className="text-uppercase"
                                  >
                                    Other Services
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    className="text-uppercase"
                                  >
                                    {e.invoice_no ? e.invoice_no : "-"}
                                  </TableCell>
                                  <TableCell nowrap="true">
                                    {e.invoice_date
                                      ? this.getLocalDate(e.invoice_date)
                                      : "-"}
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    className="text-right"
                                  >
                                    {this.toLocaleString(e.invoice_value)}
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    className="text-right"
                                  >
                                    {this.toLocaleString(e.vat_value)}
                                  </TableCell>
                                  <TableCell
                                    nowrap="true"
                                    style={{ minWidth: 160 }}
                                    className="text-uppercase"
                                  >
                                    {e.ppn_editable || e.ppn === null ? (
                                      <div className="row">
                                        <TextField
                                          name="ppn"
                                          margin="dense"
                                          variant="outlined"
                                          //fullWidth
                                          onChange={(e) => {
                                            let row = this.state.costing_types;
                                            let rows = row.payable_services;
                                            rows[idx].ppn = e.target.value;
                                            rows[idx].ppn_editable = true;

                                            this.setState({
                                              costing_types: row,
                                            });
                                          }}
                                          value={e.ppn}
                                          placeholder="Fakthur Pazak No"
                                          InputProps={{
                                            style: {
                                              textTransform: "uppercase",
                                            },
                                          }}
                                        />
                                      </div>
                                    ) : (
                                      <div>
                                        {e.ppn}
                                        <i
                                          className="fa fa-pencil edit_icon text-right"
                                          style={{ float: "right" }}
                                          onClick={() => {
                                            let row = this.state.costing_types;
                                            let rows = row.payable_services;
                                            rows[idx].ppn_editable = true;
                                            this.setState({
                                              costing_types: row,
                                            });
                                          }}
                                        />
                                      </div>
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    <MuiPickersUtilsProvider
                                      utils={DateFnsUtils}
                                    >
                                      <DatePicker
                                        autoOk={true}
                                        inputVariant="outlined"
                                        variant="inline"
                                        margin="dense"
                                        fullWidth
                                        format="dd/MM/yyyy"
                                        value={e.ppn_date}
                                        error={this.state.date_of_paymentError}
                                        onChange={(date) => {
                                          let row = this.state.costing_types;
                                          let rows = row.payable_services;
                                          rows[idx].ppn_date =
                                            this.dateFormat(date);
                                          this.setState({
                                            stevedore_costing: row,
                                          });
                                        }}
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              <IconButton>
                                                <EventIcon />
                                              </IconButton>
                                            </InputAdornment>
                                          ),
                                          className: "pl-0",
                                        }}
                                      />
                                    </MuiPickersUtilsProvider>
                                  </TableCell>
                                </TableRow>
                              ))}
                              <TableRow>
                                <TableCell colSpan={5}></TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.toLocaleString(
                                    this.state.payable_services_invoice_value
                                  )}
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.toLocaleString(
                                    this.state.payable_services_vat_value
                                  )}
                                </TableCell>
                                <TableCell colSpan={2} />
                              </TableRow>
                            </TableBody>
                          )}
                          {summary.indirect_expenditure_cost_center_expenses
                            .length > 0 && (
                            <TableBody>
                              {/* <TableRow><TableCell colSpan={8} style={{ color: config.themeColor }}>Indirect Expenditure</TableCell></TableRow> */}
                              {summary.indirect_expenditure_cost_center_expenses.map(
                                (e, idx) => (
                                  <TableRow key={idx}>
                                    <TableCell
                                      nowrap="true"
                                      className="text-uppercase"
                                    >
                                      {e.vendor_name ? e.vendor_name : "-"}
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      className="text-uppercase"
                                    >
                                      {e.tax_id ? e.tax_id : "-"}
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      className="text-uppercase"
                                    >
                                      Indirect Expenditure
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      className="text-uppercase"
                                    >
                                      {e.invoice_no ? e.invoice_no : "-"}
                                    </TableCell>
                                    <TableCell nowrap="true">
                                      {e.invoice_date
                                        ? this.getLocalDate(e.invoice_date)
                                        : "-"}
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      className="text-right"
                                    >
                                      {this.toLocaleString(e.invoice_value)}
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      className="text-right"
                                    >
                                      {this.toLocaleString(e.vat_value)}
                                    </TableCell>
                                    <TableCell
                                      nowrap="true"
                                      style={{ minWidth: 160 }}
                                      className="text-uppercase"
                                    >
                                      {e.ppn_editable || e.ppn === null ? (
                                        <div className="row">
                                          <TextField
                                            name="ppn"
                                            margin="dense"
                                            variant="outlined"
                                            //fullWidth
                                            onChange={(e) => {
                                              let row =
                                                this.state.costing_types;
                                              let rows =
                                                row.indirect_expenditure_cost_center_expenses;
                                              rows[idx].ppn = e.target.value;
                                              rows[idx].ppn_editable = true;

                                              this.setState({
                                                costing_types: row,
                                              });
                                            }}
                                            value={e.ppn}
                                            placeholder="Fakthur Pazak No"
                                            InputProps={{
                                              style: {
                                                textTransform: "uppercase",
                                              },
                                            }}
                                          />
                                        </div>
                                      ) : (
                                        <div>
                                          {e.ppn}
                                          <i
                                            className="fa fa-pencil edit_icon text-right"
                                            style={{ float: "right" }}
                                            onClick={() => {
                                              let row =
                                                this.state.costing_types;
                                              let rows =
                                                row.indirect_expenditure_cost_center_expenses;
                                              rows[idx].ppn_editable = true;
                                              this.setState({
                                                costing_types: row,
                                              });
                                            }}
                                          />
                                        </div>
                                      )}
                                    </TableCell>
                                    <TableCell>
                                      <MuiPickersUtilsProvider
                                        utils={DateFnsUtils}
                                      >
                                        <DatePicker
                                          autoOk={true}
                                          inputVariant="outlined"
                                          variant="inline"
                                          margin="dense"
                                          fullWidth
                                          format="dd/MM/yyyy"
                                          value={e.ppn_date}
                                          error={
                                            this.state.date_of_paymentError
                                          }
                                          onChange={(date) => {
                                            let row = this.state.costing_types;
                                            let rows =
                                              row.indirect_expenditure_cost_center_expenses;
                                            rows[idx].ppn_date =
                                              this.dateFormat(date);
                                            this.setState({
                                              stevedore_costing: row,
                                            });
                                          }}
                                          InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <IconButton>
                                                  <EventIcon />
                                                </IconButton>
                                              </InputAdornment>
                                            ),
                                            className: "pl-0",
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </TableCell>
                                  </TableRow>
                                )
                              )}
                              <TableRow>
                                <TableCell colSpan={5}></TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.toLocaleString(
                                    this.state.indirect_exp_invoice_value
                                  )}
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {this.toLocaleString(
                                    this.state.indirect_exp_vat_value
                                  )}
                                </TableCell>
                                <TableCell colSpan={2}></TableCell>
                              </TableRow>
                            </TableBody>
                          )}

                          <TableBody>
                            <TableRow>
                              <TableCell colSpan={5}></TableCell>
                              <TableCell
                                style={{
                                  textAlign: "right",
                                  fontWeight: "bold",
                                  whiteSpace: "nowrap",
                                }}
                                className="text-success"
                              >
                                {this.state.total_expense_invoice_value
                                  ? this.toLocaleString(
                                      this.state.total_expense_invoice_value
                                    )
                                  : 0}
                              </TableCell>
                              <TableCell
                                style={{
                                  textAlign: "right",
                                  fontWeight: "bold",
                                  whiteSpace: "nowrap",
                                }}
                                className="text-success"
                              >
                                {this.state.total_expense_vat_value
                                  ? this.toLocaleString(
                                      this.state.total_expense_vat_value
                                    )
                                  : 0}
                              </TableCell>
                              <TableCell colSpan={2} className="text-center">
                                {this.state.expenses_avaialable > 0 && (
                                  <button
                                    className="header_button header_button_text mt-1"
                                    onClick={this.bulkUpdateHandler}
                                  >
                                    Update
                                  </button>
                                )}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>
                )}

                {/* {this.state.activeTabIndex === 2 &&
                  <div className='row mt-4'>
                    <div className='col-lg-12'>

                      <TableContainer component={Paper}>
                        <Table size="medium" aria-label="a dense table" component={Paper}>
                          <TableHead className='bg-light'>
                            <TableRow>
                              <TableCell></TableCell>
                              <TableCell className='text-right'>VAT VALUE</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell>Expenses</TableCell>
                              <TableCell className='text-right'>{this.toLocaleString(this.state.total_expense_vat_value)}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Income</TableCell>
                              <TableCell className='text-right'>{this.toLocaleString(this.state.sales_total_vat_value)}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell style={{ fontWeight: 'bold' }}>VAT Input</TableCell>

                              {this.state.total_profit_or_loss_amount < 0 ?
                                <TableCell className="text-danger text-right text-bold">{this.state.total_profit_or_loss_amount ? '( ' + this.toLocaleString(Math.abs(this.state.total_profit_or_loss_amount)) + ' )' : 0}</TableCell>
                                :
                                <TableCell className='text-success text-right text-bold'>{this.state.total_profit_or_loss_amount ? this.toLocaleString(this.state.total_profit_or_loss_amount) : 0}</TableCell>
                              }
                              <TableCell />
                            </TableRow>
                          </TableBody>
                        </Table>

                      </TableContainer>

                    </div>
                  </div>
                } */}
                {this.state.activeTabIndex === 2 && (
                  <div className="row mt-4">
                    <div className="col-lg m-auto">
                      <TableContainer component={Paper}>
                        <Table size="" aria-label="a dense table">
                          <TableHead className="bg-light">
                            <TableRow>
                              <TableCell></TableCell>
                              <TableCell className="text-right">
                                VAT Value
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell>Expenses</TableCell>
                              <TableCell className="text-right">
                                {this.toLocaleString(
                                  this.state.total_expense_vat_value
                                )}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Income</TableCell>
                              <TableCell className="text-right">
                                {this.toLocaleString(
                                  this.state.sales_total_vat_value
                                )}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell style={{ fontWeight: "bold" }}>
                                VAT Input
                              </TableCell>

                              {this.state.total_profit_or_loss_amount < 0 ? (
                                <TableCell className="text-danger text-right text-bold">
                                  {this.state.total_profit_or_loss_amount
                                    ? "( " +
                                      this.toLocaleString(
                                        Math.abs(
                                          this.state.total_profit_or_loss_amount
                                        )
                                      ) +
                                      " )"
                                    : 0}
                                </TableCell>
                              ) : (
                                <TableCell className="text-success text-right text-bold">
                                  {this.state.total_profit_or_loss_amount
                                    ? this.toLocaleString(
                                        this.state.total_profit_or_loss_amount
                                      )
                                    : 0}
                                </TableCell>
                              )}
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
