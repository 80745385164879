import React, { Component } from "react";

import SideBar from "../../common/NewSidebar";
import Header from "../../common/Header";
import CookieHandler from "../../common/CookieHandler";
import api from "../../../api/api";
import config from "../../../config/config";
import MaterialTable from "material-table";
import { Paper, Tooltip, Button } from "@material-ui/core";
import { localDateFormate } from "../../common/common";
import Loader from "../../common/Loader";

export default class CpsContractList extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();

    this.state = {
      cps_list: [],
      isLoading: false,
      features: [],
      cps_contract_data: [],
      loading: true,
      contract_type: "Coal Service Fees",
      contract_filters: 0,
    };
  }

  componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    var features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    api.get_cps_contracts(loginUserID, idToken).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            if (res.cps_contract_data) {
              this.setState({
                cps_contract_data: res.cps_contract_data,
                cps_list: res.cps_contract_data.filter(
                  (e) => e.contract_type === "Coal Service Fees"
                ),
                loading: false,
              });
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          } else if (res.code === "601") {
            this.setState({});
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
    document.title = config.documentTitle + "Coal Procurement Contracts";
  }

  render() {
    return (
      <div id="wraper">
        <SideBar />
        <div className="content">
          <Header />
          <div className="clearfix content-header">
            <div className="row">
              <div className="col-sm text-left pl-0">
                <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                  Coal Procurement Service Contract
                  <span style={{ color: "lightgrey", margin: "0px 5px" }}>
                    |
                  </span>
                  <span style={{ color: "#959cb6", fontSize: "15px" }}>
                    {this.state.cps_list.length}
                  </span>
                </h4>
              </div>
              <div className="col text-right float-sm-left">
                {(this.state.features.includes("7") ||
                  this.state.features.includes("97")) && (
                  <a href="/new-cps-contract">
                    <button
                      className="header_button header_button_text add_button_adjustment"
                      style={{ width: "auto", marginTop: "20px" }}
                    >
                      New Procurement Service Contract
                    </button>
                  </a>
                )}
              </div>
            </div>
          </div>
          {this.state.isLoading && <Loader />}

          <div className="container p-3">
            {!this.state.isLoading && (
              <div className="col-md card p-0">
                <Paper>
                  <MaterialTable
                    title={
                      <div className="row">
                        <div className="col-lg-12">
                          <Button
                            className="tab_button"
                            onClick={(e, value) => {
                              this.setState({
                                contract_filters: 0,
                                cps_list: this.state.cps_contract_data.filter(
                                  (e) => e.contract_type === "Coal Service Fees"
                                ),
                              });
                            }}
                            style={{
                              textTransform: "capitalize",
                              margin: "5px 0px",
                              padding: "5px 20px",
                              borderRadius: 35,
                              height: 30,
                              color:
                                this.state.contract_filters === 0
                                  ? config.themeColor
                                  : "#483b34b3",
                              backgroundColor:
                                this.state.contract_filters === 0
                                  ? "#fff7f2"
                                  : "#fff",
                              fontSize: "14px",
                              border: "0px",
                            }}
                          >
                            Coal Service Fees
                          </Button>
                          <Button
                            className="tab_button"
                            onClick={(e, value) => {
                              this.setState({
                                contract_filters: 1,
                                cps_list: this.state.cps_contract_data.filter(
                                  (e) => e.contract_type !== "Coal Service Fees"
                                ),
                              });
                            }}
                            style={{
                              textTransform: "capitalize",
                              margin: "5px 0px",
                              padding: "5px 20px",
                              borderRadius: 35,
                              height: 30,
                              color:
                                this.state.contract_filters === 1
                                  ? config.themeColor
                                  : "#483b34b3",
                              backgroundColor:
                                this.state.contract_filters === 1
                                  ? "#fff7f2"
                                  : "#fff",
                              fontSize: "14px",
                              border: "0px",
                            }}
                          >
                            Procurement Fees
                          </Button>
                        </div>
                      </div>
                    }
                    columns={[
                      {
                        title: "Contract Number",
                        field: "cps_contract_no",
                        render: (row) => {
                          if (
                            this.state.features.includes("501") ||
                            this.state.features.includes("97")
                          ) {
                            return (
                              <a
                                rel="noopener noreferrer"
                                style={{
                                  textDecoration: "none",
                                  color: config.themeColor,
                                }}
                                target="_blank"
                                href={"/view-cps-contract/" + btoa(row.cps_id)}
                              >
                                {row.cps_contract_no}
                              </a>
                            );
                          } else return <span>{row.cps_contract_no}</span>;
                        },
                      },
                      {
                        title: "Contract Date",
                        field: "contract_date",
                        render: (row) => {
                          if (row.contract_date) {
                            return localDateFormate(row.contract_date);
                          } else {
                            return null;
                          }
                        },
                      },
                      {
                        title: "Vendor Name",
                        field: "vendor_name",
                        render: (row) => {
                          if (row.vendor_id) {
                            return row.vendor_name;
                          } else {
                            return row.other_vendor_name;
                          }
                        },
                      },
                      {
                        title: "Status",
                        field: "status",
                        render: (row) => {
                          return (
                            <span>
                              {row.status === "Active" && (
                                <span className="active_status">
                                  <i
                                    className="fa fa-circle dot_align"
                                    aria-hidden="true"
                                  />
                                  {row.status}
                                </span>
                              )}
                              {row.status === "Draft" && (
                                <span className="freezed_status">
                                  <i
                                    className="fa fa-circle dot_align"
                                    aria-hidden="true"
                                  />
                                  {row.status}
                                </span>
                              )}
                              {row.status === "Closed" && (
                                <span className="completed_status">
                                  <i
                                    className="fa fa-circle dot_align"
                                    aria-hidden="true"
                                  />
                                  {row.status}
                                </span>
                              )}
                              {(row.status === "cancelled" ||
                                row.status === "Pending Approval") && (
                                <span className="cancel_status">
                                  <i
                                    className="fa fa-circle dot_align"
                                    aria-hidden="true"
                                  />
                                  {row.status}
                                </span>
                              )}
                            </span>
                          );
                        },
                      },
                      {
                        title: "Action",
                        field: "cps_id",
                        sortable: false,
                        filterable: false,
                        render: (row) => {
                          return (
                            <div
                              style={{
                                textAlign: "left",
                                marginTop: "-10px",
                                marginBottom: "-10px",
                              }}
                            >
                              {row.status === "Active" &&
                                (this.state.features.includes("502") ||
                                  this.state.features.includes("97")) && (
                                  <Tooltip title="Update" arrow>
                                    <a
                                      href={
                                        "/update-cps-contract/" +
                                        btoa(row.cps_id)
                                      }
                                    >
                                      {" "}
                                      <i
                                        data-html="true"
                                        className="fa fa-pencil edit_icon"
                                        aria-hidden="true"
                                        style={{
                                          color: config.themeColor,
                                          cursor: "pointer",
                                        }}
                                      />
                                    </a>
                                  </Tooltip>
                                )}
                            </div>
                          );
                        },
                        hidden:
                          this.state.features.includes("502") ||
                          this.state.features.includes("97"),
                      },
                    ]}
                    data={this.state.cps_list}
                    isLoading={this.state.loading}
                    style={{
                      minHeight: 200,
                      fontFamily: "Poppins",
                      overflowY: "auto",
                      boxShadow: "0 0 5px 0 rgba(0, 39, 77, 0.1)",
                    }}
                    options={{
                      paginationType: "stepped",
                      pageSize: 20,
                      pageSizeOptions: [5, 10, 20, 50, 100, 200],
                      headerStyle: {
                        backgroundColor: "#f9f9f9",
                        // minWidth: 215,
                        fontFamily: "Poppins",
                        textTransform: "uppercase",
                        fontSize: "14px",
                        fontWeight: 500,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "0.86",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "rgba(72, 59, 52, 0.7)",
                      },
                      cellStyle: {
                        fontFamily: "Poppins",
                      },
                      // exportAllData: true,
                      // exportButton: true,
                      draggable: false,
                      exportFileName: "coal-procurement-contracts",
                      // tableLayout: 'fixed'
                    }}
                  />
                </Paper>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
