import React, { Component } from 'react';

import { Snackbar, Drawer, Tooltip } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import api from '../../../api/api';
import config from '../../../config/config';
import CookieHandler from "../../common/CookieHandler";

import Loader from '../../common/Loader';
import MvDeadFreightAdd from './MvDeadFreightAdd';
import MvDeadFreightUpdate from './MvDeadFreightUpdate';
import MvDeadFreightView from './MvDeadFreightView';
import { toLocaleString } from '../../common/common';

export default class MvDeadFreightList extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      isLoading: true,
      openDrawer: false,
      deadFreightAdd: false,
      deadFreightView: false,
      deadFreightUpdate: false,
      snackBarSuccessOpen: false,
      snackBarErrorOpen: false,
      features: [],
      mv_dead_freight_data: null,
    }
  }

  componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    const business_no_id = this.props.businessNoID;

    let request = {
      login_user_id: this.Cookie.getCookie('loginUserId'),
      idtoken: this.Cookie.getIdTokenCookie(),
      business_no_id: business_no_id
    }
    api.get_mv_dead_freight_by_bno(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === '200') {
            // if (res.mv_dead_freight_data) {
            this.setState({
              mv_dead_freight_data: res.mv_dead_freight_data,
              isLoading: false,
            })
            // }
          }
        })
      }
    })

  }

  onAddResponse = (value) => {
    this.setState(
      {
        deadFreightAdd: false,
        deadFreightView: true,
        deadFreightUpdate: false,
        openDrawer: true,
        updateDocuments: false,
      },
      () => this.componentDidMount()
    );
  };

  CallbackDrawerClose = (childData) => {
    this.setState(
      {
        openDrawer: false,
        deadFreightAdd: false,
        deadFreightView: false,
        deadFreightUpdate: false,
        updateDocuments: false,
      },
      () => this.componentDidMount()
    );
  };

  onUpdateResponse = (value) => {
    if (value) {
      this.setState(
        {
          deadFreightAdd: false,
          deadFreightView: true,
          openDrawer: true,
          deadFreightUpdate: false,
          updateDocuments: false,
        },
        () => this.componentDidMount()
      );
    }
  };

  CallbackViewPage = (childData) => {
    this.setState(
      {
        openDrawer: true,
        deadFreightUpdate: false,
        deadFreightAdd: false,
        deadFreightView: true,
        updateDocuments: false,
      },
      () => this.componentDidMount()
    );
  };

  editCallback = (childData) => {
    this.setState(
      {
        openDrawer: true,
        deadFreightUpdate: true,
        deadFreightAdd: false,
        deadFreightView: false,
        updateDocuments: false,
      },
      () => this.componentDidMount()
    );
  };
  render() {

    const { businessNoID, deadFreightValue } = this.props || {};

    let drawerComponent;
    if (this.state.deadFreightAdd && businessNoID) {
      drawerComponent = (
        <MvDeadFreightAdd
          businessNoID={businessNoID}
          deadFreightValue={deadFreightValue}
          onAddResponse={this.onAddResponse}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
        />
      )
    }
    else if (this.state.deadFreightView && businessNoID) {
      drawerComponent = (
        <MvDeadFreightView
          businessNoID={businessNoID}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.editCallback}
          returnDocumentsHandler={() => {
            this.setState({
              showDrawer: true,
              deadFreightUpdate: true,
              deadFreightView: false,
              deadFreightAdd: false,
              updateDocuments: true,
            });
          }}
        />
      )
    }
    else if (this.state.deadFreightUpdate && businessNoID) {
      drawerComponent = (
        <MvDeadFreightUpdate
          businessNoID={businessNoID}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.CallbackViewPage}
          updateDocuments={this.state.updateDocuments}
        />
      )
    }

    if (this.state.isLoading) {
      return <Loader />
    }
    else {


      const { business_no, costing_no, dead_freight_qty, billing_currency, payable_to_vendor, price_pmt, approval_status } = this.state.mv_dead_freight_data || {};

      return (
        <div>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <Drawer
            anchor="right"
            open={this.state.openDrawer}
            variant="temporary"
            elevation={20}
            style={{ overflow: "initial" }}
          >
            <div className="row" style={{ width: 800 }}>
              {drawerComponent}
            </div>
          </Drawer>

          {!this.state.mv_dead_freight_data &&
            <div className="row clearfix content-header border-bottom">
              <div className="row clearfix content-header border-bottom">
                <h4 className="mt-2" style={{ padding: "4px" }}>
                  {`Mother Vessel Dead Freight - ${this.props.mother_vessel_name ? this.props.mother_vessel_name : ''} ${this.props.name ? `( ${this.props.name} )` : ''}`}

                </h4>
                {(this.state.features.includes("234") ||
                  this.state.features.includes("97")) && (
                    <i
                      className="fa fa-plus-circle ml-2 mt-3"
                      aria-hidden="true"
                      style={{
                        fontSize: 22,
                        color: config.themeColor,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        this.setState({
                          openDrawer: true,
                          deadFreightAdd: true,
                          deadFreightView: false,
                          deadFreightUpdate: false,
                        });
                      }}
                    />
                  )}
              </div>
            </div>
          }

          <br />

          <div className="row">
            <div className="table-responsive">
              <table className="table table-bordered table-sm">
                <thead className='table-header'>
                  <tr>
                    <th nowrap="true">  Costing ID  </th>
                    <th nowrap="true" >Business No </th>
                    <th nowrap="true" className="text-right"> Dead Freight</th>
                    <th nowrap="true" className="text-right" > Price PMT </th>
                    <th nowrap="true" className="text-right" > Billing Currency </th>
                    <th nowrap="true" className=" text-right">  Payable to Vendor </th>
                    <th nowrap="true" className="pl-4"> Action </th>
                  </tr>
                </thead>
                {this.state.mv_dead_freight_data ? (
                  <tbody>
                    <tr>
                      <td className="allocation_table_value">
                        {(this.state.features.includes("235") ||
                          this.state.features.includes("97")) && (
                            <i
                              aria-hidden="true"
                              style={{
                                fontStyle: "normal",
                                color: config.themeColor,
                                cursor: "pointer",
                                whiteSpace: "nowrap",
                              }}
                              onClick={() =>
                                this.setState({
                                  openDrawer: true,
                                  deadFreightAdd: false,
                                  deadFreightView: true,
                                  deadFreightUpdate: false,
                                })
                              }
                            >
                              {costing_no}
                            </i>
                          )}
                      </td>
                      <td
                        className="allocation_table_value"
                        nowrap="true"
                      >
                        {business_no}
                      </td>
                      <td
                        className="allocation_table_value text-right"
                        nowrap="true"
                      >
                        {toLocaleString(dead_freight_qty)}
                      </td>
                      <td
                        className="allocation_table_value text-right"
                        nowrap="true"
                      >
                        {toLocaleString(price_pmt)}
                      </td>
                      <td
                        className="allocation_table_value text-right"
                        nowrap="true"
                      >
                        {billing_currency}
                      </td>
                      <td
                        className="allocation_table_value text-right"
                        nowrap="true"
                      >
                        {toLocaleString(payable_to_vendor)}
                      </td>
                      <td
                        nowrap="true"
                        className="table_td text-left pl-4"
                      >
                        {(this.state.features.includes("236") ||
                          this.state.features.includes("97")) && (
                            <Tooltip title="Edit" arrow>

                              <i
                                className="fa fa-pencil"
                                style={{
                                  fontSize: 14,
                                  color: config.themeColor,
                                  cursor: "pointer",
                                }}
                                data-toggle="modal"
                                title="Edit floating crane charges"
                                data-placement="bottom"
                                onClick={() =>
                                  this.setState({
                                    openDrawer: true,
                                    deadFreightUpdate: true,
                                  })
                                }
                              />
                            </Tooltip>
                          )}
                        {(this.state.features.includes("237") ||
                          this.state.features.includes("97")) &&
                          approval_status === "Pending" && (
                            <Tooltip title="Delete" arrow>

                              <i
                                className="fa fa-trash-o"
                                style={{
                                  paddingLeft: 10,
                                  fontSize: 14,
                                  color: config.themeColor,
                                  cursor: "pointer",
                                }}
                                onClick={() => this.deleteCharges()}
                              />
                            </Tooltip>
                          )}
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan="7" className="p-2">
                        No data available
                      </td>
                    </tr>{" "}
                  </tbody>
                )}
              </table>
            </div>
          </div>


        </div>
      )
    }
  }
}
