import React, { Component } from 'react';

import CookieHandler from '../common/CookieHandler';
import config from '../../config/config';
import SideBar from '../common/SideBar';
import Header from '../common/Header';
import api from '../../api/api';
import exportToExcel from '../Export_To_Excel';


import { Alert } from '@material-ui/lab';
import {
  Snackbar, TextField, MenuItem, Table, TableBody, TableCell,
  TableHead, TableRow, Paper,
  TableContainer,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Loader from '../common/Loader';

export default class PurchaseAvailableQuantityResports extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      coal_vendors_list: [],
      pc_available_quantity_data: [],
      isLoading: true,
      vendor_id: null,
      purchaseDataForMenu: [],
      purchase_contract_id: null,
      duration: '4 Months'
    }
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace('/logout');
    }
    let features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (!(features.includes('535')  || features.includes('97') )) {
      window.location.href = '/dashboard';
    }
    const loginUserID = this.Cookie.getCookie('loginUserId');
    const idToken = this.Cookie.getIdTokenCookie();
    const status = '';
    const get_pc = await api.getPurchaseContracts(loginUserID, status, idToken);
    if (get_pc.status >= 200 && get_pc.status < 300) {
      get_pc.json().then((res) => {
        if (res.code === '200') {
          if (res.idtoken)
            this.Cookie.setIdTokenCookie(res.idtoken);
          if (res.purchase_contract) {
            let purchaseDataforMenu = [];
            res.purchase_contract.filter((e, indx) => e.status === "Active" || e.status === "Freezed")
              .forEach(item => {
                purchaseDataforMenu.push({ name: item.contract_no, value: item.id, key: item.id, vendor_name: item.vendor_name });
              });
            this.setState({
              purchaseDataForMenu: purchaseDataforMenu,
              //isLoading: false
            });
          } else {
            alert('Unexpected error occured. Please contact administrator.');
          }
        } else if (res.code === '607') {
          window.location.href = '/logout';
        }
      })
    }
    //Get vendors
    const vendors = await api.getVendors(loginUserID, idToken);
    if (vendors.status >= 200 && vendors.status < 300) {
      vendors.json().then((res) => {
        if (res.code === '200') {
          if (res.idtoken)
            this.Cookie.setIdTokenCookie(res.idtoken);
          if (res.vendors) {
            const vendors = [];
            res.vendors.filter(e => e.vendor_type === "Coal")
              .forEach(item => {
                vendors.push({ name: item.vendor_name, value: item.id, key: item.id });
              });
            this.setState({
              coal_vendors_list: vendors,
              //isLoading: false
            }, () => this.getReportsHandler())
          } else {
            alert('Unexpected error occured. Please contact administrator.');
          }
        } else if (res.code === "607") {
          window.location.href = '/logout';
        }
      })
    }

    document.title = config.documentTitle + "Available  Quantity Report";
  }

  toLocaleString(value) {
    if (value) {
      if (Number(value) > 0) {
        return Number(value).toLocaleString()
      } else return "( " + (Number(value) * -1).toLocaleString() + " )"
    } else return 0;
  }

  getReportsHandler = () => {
    this.setState({ submitting: true });
    const loginUserID = this.Cookie.getCookie('loginUserId');
    const idToken = this.Cookie.getIdTokenCookie();
    let { vendor_id, purchase_contract_id, duration } = this.state;
    // if (vendor_id === null || this.state.vendor_id === '') {
    //   this.setState({
    //     coal_vendor_error: true,
    //     submitting: false
    //   });
    //   return;
    // }
    api.get_pc_available_quantity_report(loginUserID, idToken, vendor_id, purchase_contract_id, duration).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === '200') {
            this.setState({
              pc_available_quantity_data: res.pc_available_quantity_data,
              submitting: false,
              isLoading: false,
            });
          } else if (res.code === '601') {
            this.setState({
              submitting: false,
              snackBarErrorOpen: true,
              errorMsg: res.message
            });
          } else if (res.code === '607') {
            window.location.href = '/logout';
          }
        })
      }
    })
  }

  // TODO Excel method handler
  convertExcelHandler = (data) => {
    var arrData = typeof data != 'object' ? JSON.parse(data) : data;
    let Modified = arrData.map(
      obj => {
        return {
          "Vendor Name": obj.vendor_name,
          "Contract No": obj.contract_no,
          "Grade": obj.grade,
          "Quantity": obj.quantity ? Number(obj.quantity) : 0,
          "Tolerance": obj.tolerance ? Number(obj.tolerance) : 0,
          "Qty With Tolerance": obj.qty_with_tolerance ? Number(obj.qty_with_tolerance) : 0,
          "Delivered Quantity": obj.delivered_quantity ? Number(obj.delivered_quantity) : 0,
          "Remaining Qty": obj.remaining_quantity ? Number(obj.remaining_quantity) : 0,
          "Remaining Qty With Tolerance": obj.remaining_qty_with_tolerance ? Number(obj.remaining_qty_with_tolerance) : 0,
          "% Remaining Qty": obj.remaining_qty_percentage ? Number(obj.remaining_qty_percentage) : 0,
          "% Remaining Qty With Tolerance": obj.remaining_qty_with_tolerance_percentage ? Number(obj.remaining_qty_with_tolerance_percentage) : 0,
        }
      }
    );
    exportToExcel(Modified, "PC Available Qty Report");
  }

  render() {
    return (
      <div id='wraper'>
        <SideBar />
        <div className='content'>
          <Header />
          {this.state.isLoading &&
           <Loader />
          }
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={this.state.snackBarErrorOpen} onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: '450px' }}
          >
            <Alert elevation={6} variant="filled" onClose={() => this.setState({ snackBarErrorOpen: false })} severity="error" style={{ width: '100%' }}>
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            style={{ width: '450px' }}
            open={this.state.snackBarSuccessOpen} autoHideDuration={100000} onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert elevation={6} variant="filled" onClose={() => this.setState({ snackBarSuccessOpen: false })} severity="success" style={{ width: '100%' }}>
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          {!this.state.isLoading &&
            <div className='content-header'>
              <div className='col-sm pl-0'>
                <h4 style={{ padding: '10px 20px', margin: '0px' }}> <a
                  href={'/reports'}
                  className="fa fa-arrow-left left_arrow_adjst"
                >
                </a>
                  Purchase Contracts Available Quantity
                </h4>
              </div>
              <section className='container p-3'>
                <div className='col-lg-12 card'>
                  <div className="section_block" style={{ width: '97%' }}>
                    <div style={{ width: '70%' }}>
                      <h5 style={{ padding: '10px 0px', margin: '0px' }}>Purchase Contract Available  Quantity Report details</h5>
                    </div>
                    <div className='row mb-3'>
                      <div className='col-lg-3'>
                        <label className='form_label mb-0'>Vendor</label>
                        <Autocomplete
                          options={this.state.coal_vendors_list}
                          getOptionLabel={option => option.name}
                          renderOption={option => (
                            <React.Fragment>
                              {option.name}
                            </React.Fragment>
                          )}
                          onChange={(event, obj) => {
                            if (obj) {
                              this.setState({
                                vendor_id: obj.value,
                                coal_vendor_error: false,
                                // vendor_drop_value: obj
                              });
                            } else {
                              this.setState({
                                vendor_id: null,
                                vendor_drop_value: { name: "", value: "" }
                              })
                            }
                          }}
                          size="small"
                          name='vendor'
                          value={this.state.vendor_drop_value}

                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={this.state.coal_vendor_error}
                              placeholder="Please Select"
                              variant="outlined"
                              fullWidth
                              style={{ top: 8 }}
                            />
                          )}
                          sryle={{ display: 'contents' }}
                        />
                      </div>
                      <div className='col-lg-3'>
                        <label className='form_label mb-0'>Purchase Contract</label>
                        <Autocomplete
                          options={this.state.purchaseDataForMenu}
                          getOptionLabel={option => option.name}
                          renderOption={option => (
                            <React.Fragment>
                              <div className='col-lg p-0 m-0'>
                                <label className='form_label mb-0'>{option.name}</label>
                                <label className='contract_display_header_value m-0 pl-0' style={{ fontSize: 10, paddingLeft: 5 }}>{option.vendor_name ? "( " + option.vendor_name + " )" : null}</label>
                              </div>
                            </React.Fragment>
                          )}
                          onChange={(event, obj) => {
                            if (obj) {
                              this.setState({
                                purchase_contract_id: obj.value,
                                //coal_vendor_error: false,
                              });
                            } else {
                              this.setState({
                                purchase_contract_id: null,
                                pc_drop_value: { name: "", value: "" }
                              })
                            }
                          }}
                          size="small"
                          name='vendor'
                          value={this.state.pc_drop_value}

                          renderInput={(params) => (
                            <TextField
                              {...params}
                              //error={this.state.coal_vendor_error}
                              placeholder="Please Select Contract"
                              variant="outlined"
                              fullWidth
                              style={{ top: 8 }}
                            />
                          )}
                          sryle={{ display: 'contents' }}
                        />
                      </div>
                      <div className='col-lg-3'>
                        <label className='form_label mb-0'>Duration</label>
                        <TextField
                          name='duration'
                          margin='dense'
                          variant='outlined'
                          fullWidth
                          onChange={(e) => {
                            this.setState({
                              duration: e.target.value,
                            });
                          }}
                          select
                          value={this.state.duration}
                        >
                          <MenuItem value='0' key={-1} disabled>Please Select</MenuItem>
                          <MenuItem value='4 Months' key={1}>4 Months</MenuItem>
                          <MenuItem value='6 Months' key={2}>6 Months</MenuItem>
                          <MenuItem value='All' key={3}>All</MenuItem>
                        </TextField>
                      </div>
                      <div className='col'>
                        <button type='button' className='header_button header_button_text' style={{ width: 'auto', marginTop: '30px' }}
                          onClick={this.getReportsHandler}
                          disabled={this.state.submitting}
                        >
                          <span className={this.state.submitting ? "spinner-grow spinner-grow-sm mr-2" : ""}></span>Search
                        </button>
                      </div>
                    </div>

                    <div className='row'>
                      {this.state.pc_available_quantity_data.length > 0 &&

                        <div className='col text-right mb-3'>
                          <i className="fa fa-file-excel-o" aria-hidden="true" style={{ color: 'green', cursor: 'pointer' }}
                            onClick={() => this.convertExcelHandler(this.state.pc_available_quantity_data)}
                          />

                        </div>
                      }
                      <div className='col-lg-12 m-auto '>
                        {this.state.pc_available_quantity_data.length > 0 &&
                          <TableContainer style={{ maxHeight: 500 }} >
                            <Table size="small" component={Paper} stickyHeader aria-label="sticky table">
                              <TableHead className='bg-light'>
                                <TableRow >
                                  <TableCell style={{ fontSize: 10 }} >VENDOR</TableCell>
                                  <TableCell style={{ fontSize: 10 }} nowrap='true'>CONTRACT NUMBER</TableCell>
                                  <TableCell style={{ fontSize: 10 }} nowrap='true'>GRADE</TableCell>
                                  <TableCell style={{ fontSize: 10 }} nowrap='true' align="right">QUANTITY</TableCell>
                                  <TableCell style={{ fontSize: 10 }} nowrap='true' align="right" >TOLERANCE</TableCell>
                                  <TableCell style={{ fontSize: 10 }} nowrap='true' align="right">QTY WITH TOLERANCE</TableCell>
                                  <TableCell style={{ fontSize: 10 }} nowrap='true' align="right">DELIVERED QTY</TableCell>
                                  <TableCell style={{ fontSize: 10 }} nowrap='true' align="right">REMAINING QTY</TableCell>
                                  <TableCell style={{ fontSize: 10 }} nowrap='true' align="right">REMAINING QTY WITH TOLERANCE</TableCell>
                                  <TableCell style={{ fontSize: 10 }} nowrap='true' align="right">% REMAINING QTY</TableCell>
                                  <TableCell style={{ fontSize: 10 }} nowrap='true' align="right">% REMAINING QTY WITH TOLERANCE</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {this.state.pc_available_quantity_data.map((e, idx) => (
                                  <TableRow key={idx}>
                                    <TableCell style={{ fontSize: 10 }} nowrap='true'>{e.vendor_name ? e.vendor_name : '-'}</TableCell>
                                    <TableCell style={{ fontSize: 10 }} > <a href={'/view-purchase-contract/' + btoa(e.purchase_contract_id)} role="button" style={{ color: config.themeColor }}>{e.contract_no}</a></TableCell>
                                    <TableCell style={{ fontSize: 10 }} nowrap='true'>{e.grade ? e.grade : '-'}</TableCell>
                                    <TableCell style={{ fontSize: 10 }} align="right">{this.toLocaleString(e.quantity)}</TableCell>
                                    <TableCell style={{ fontSize: 10 }} nowrap='true'>{e.tolerance ? e.tolerance : '-'}</TableCell>
                                    <TableCell style={{ fontSize: 10 }} align="right">{this.toLocaleString(e.qty_with_tolerance)}</TableCell>
                                    <TableCell style={{ fontSize: 10 }} align="right">{this.toLocaleString(e.delivered_quantity)}</TableCell>
                                    <TableCell style={{ fontSize: 10 }} align="right">{this.toLocaleString(e.remaining_quantity)}</TableCell>
                                    <TableCell style={{ fontSize: 10 }} align="right">{this.toLocaleString(e.remaining_qty_with_tolerance)}</TableCell>
                                    <TableCell style={{ fontSize: 10 }} align="right">{this.toLocaleString(e.remaining_qty_percentage)} %</TableCell>
                                    <TableCell style={{ fontSize: 10 }} align="right">{this.toLocaleString(e.remaining_qty_with_tolerance_percentage)} %</TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        }
                        {this.state.pc_available_quantity_data.length === 0 &&
                          <p>No records to display</p>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          }
        </div>
      </div>
    )

  }
}