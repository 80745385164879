import React, { Component } from "react";

import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import api from "../../../api/api";
import {
  TextField,
  InputAdornment,
  RadioGroup,
  FormControlLabel,
  Radio,
  MenuItem,
  Snackbar,
} from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { Alert } from "@material-ui/lab";

import { withStyles } from "@material-ui/core/styles";
import FileUpload from "../../common/FileUpload";
import { toLocaleString } from "../../common/common";
import Loader from "../../common/Loader";
const NewRadio = withStyles({
  root: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);
export default class MvDocChargesUpdate extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      multiRows: [
        {
          dc_applicable: "No",
          dc_quantity_in_mt: "",
          dc_documentation_party: "",
          dc_documentation_by: "",
          dc_price_per_mt: "",
          billing_currency: "0",
          dc_currency_xchg_rate: "",
          dc_ctc_in_local_currency: "",
          dc_pph23_account: "0",
          dc_base_price: "",
          dc_vat_percent: "10",
          dc_vat_value: "",
          dc_ctc: "",
          dc_total: "",
          dc_pph23_percent: "2",
          dc_pph23_value: "",
          other_charges: [
            {
              other_base_price: "",
              other_description: "",
              other_vat_percent: "10",
              other_vat_value: "",
              other_pph23_percent: "2",
              other_pph23_value: "",
              other_total: "",
              other_ctc: "",
              other_currency: "0",
              other_currency_xchg_rate: "",
              other_ctc_in_local_currency: "",
            },
          ],
        },
      ],
      isLoading: true,
      vendorTypeAsOthers: [],
      vendorTypeAsSurveyors: [],
      features: [],
      files: [
        {
          file_description: "",
          file_name: "",
          file_extension: "",
          file_content: "",
          feature_name: "Mv_Pur_Fin_Doc_Charges",
        },
      ],
      costing_files: [],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (!(features.includes("276") || features.includes("97"))) {
      window.location.href = "/dashboard";
    }
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const status = "Active";
    // for get_Currencies
    await api.getCurrencies(loginUserID, idToken).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            if (res.Currency) {
              var currencies = [
                <MenuItem
                  style={{ fontFamily: "Poppins", fontSize: "14px" }}
                  value="0"
                  key={-1}
                  disabled
                >
                  Please Select
                </MenuItem>,
              ];
              for (var i in res.Currency) {
                currencies.push(
                  <MenuItem
                    style={{ fontFamily: "Poppins", fontSize: "14px" }}
                    value={res.Currency[i].currency}
                    key={[i]}
                  >
                    {res.Currency[i].currency}
                  </MenuItem>
                );
              }
              this.setState({
                currencyData: currencies,
              });
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          } else if (res.code === "601") {
            this.setState({
              errorMsg: res.message,
              snackBarErrorOpen: true,
              isLoading: false,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
    // calling Vendors Api
    await api.getVendors(loginUserID, idToken, status).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            if (res.vendors) {
              const vendorTypeAsOther = [
                <MenuItem
                  style={{ fontFamily: "Poppins", fontSize: "14px" }}
                  value="0"
                  key={-1}
                >
                  {" "}
                  Please Select
                </MenuItem>,
              ];
              const surveyors = [];
              const vendorAsOthers = [];
              for (var i of res.vendors) {
                if (i.vendor_type === "Others") {
                  vendorTypeAsOther.push(
                    <MenuItem
                      style={{ fontFamily: "Poppins", fontSize: "14px" }}
                      value={i.id}
                      key={[i.id]}
                    >
                      {i.vendor_name}
                    </MenuItem>
                  );
                }
                if (i.vendor_type === "Surveyor") {
                  surveyors.push({
                    dc_laprn_surv_name: i.vendor_name,
                    value: i.id,
                    key: i,
                  });
                }
                if (i.vendor_type === "Others" || i.vendor_type === "Coal") {
                  vendorAsOthers.push({
                    dc_documentation_name: i.vendor_name,
                    value: i.id,
                    key: i,
                  });
                }
              }
              this.setState({
                vendorTypeAsOtherMenu: vendorTypeAsOther,
                vendorTypeAsSurveyors: surveyors,
                vendorTypeAsOthers: vendorAsOthers,
              });
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });

    const businessNoID = this.props.businessNoID;
    //Get businessNumber API based on businessNumber
    await api
      .getBusinessNumber(businessNoID, loginUserID, idToken)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.business_number) {
                var total_delivered_quantity = 0;
                for (var i of res.business_number.business_number_allocation) {
                  total_delivered_quantity += Number(i.bna_quantity_delivered);
                }
                this.setState({
                  quantity_in_mt: total_delivered_quantity,
                  sac_quantity_in_mt: total_delivered_quantity,
                  dc_quantity_in_mt: total_delivered_quantity,
                  dc_quantity_for_export_tax: total_delivered_quantity,
                  //isLoading: false
                });
              }
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                snackBarErrorOpen: true,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });

    const MVDocChargesID = this.props.MVDocChargesID;
    await api
      .get_mv_doc_charges(loginUserID, idToken, MVDocChargesID)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.mv_pur_fin_doc_charges) {
                this.setState({
                  costing_id: res.mv_pur_fin_doc_charges[0]?.costing_no,
                  invoice_file: res.mv_pur_fin_doc_charges[0]?.invoice_file,
                  multiRows: res.mv_pur_fin_doc_charges,
                  files:
                    res.mv_pur_fin_doc_charges.length > 0
                      ? res.mv_pur_fin_doc_charges[0].files
                      : [],
                  isLoading: false,
                });

                if (res.mv_pur_fin_doc_charges.length <= 0) {
                  this.setState({
                    errorMsg: "No Purchase Financial Data Available.",
                    snackBarErrorOpen: true,
                  });
                }
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                snackBarErrorOpen: true,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  }

  documentationChargesCalculations = (idx, value) => {
    var row = this.state.multiRows;
    let dc_currency_xchg_rate = row[idx].dc_currency_xchg_rate
      ? row[idx].dc_currency_xchg_rate
      : 1;
    let dc_base_price = (
      Number(row[idx].dc_quantity_in_mt) *
      Number(row[idx].dc_price_per_mt ? row[idx].dc_price_per_mt : 1)
    ).toFixed(2);
    if (
      (config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL") &&
      row[idx].billing_currency !== "0" &&
      row[idx].billing_currency !== "USD" &&
      row[idx].dc_documentation_party !== "Internal"
    ) {
      dc_base_price = (
        (Number(row[idx].dc_quantity_in_mt) *
          Number(row[idx].dc_price_per_mt ? row[idx].dc_price_per_mt : 1)) /
        Number(dc_currency_xchg_rate)
      ).toFixed(2);
    }
    if (row[idx].dc_documentation_party === "Internal") {
      dc_base_price = Number(
        row[idx].dc_quantity_in_mt ? row[idx].dc_quantity_in_mt : 1
      );
    }
    if (value === "base_price") {
      dc_base_price = Number(row[idx].dc_base_price);
    }
    var SDAM_dc_base_price = dc_base_price;

    let dc_vat_value = (
      (Number(dc_base_price) * Number(row[idx].dc_vat_percent)) /
      100
    ).toFixed(0);
    if (value === "vat") {
      dc_vat_value = Number(row[idx].dc_vat_value);
    }
    let dc_pph23_value = (
      (Number(dc_base_price) * Number(row[idx].dc_pph23_percent)) /
      100
    ).toFixed(0);
    if (
      config.company === "SRPL" ||
      config.company === "AVS" ||
      config.company === "PRPL"
    ) {
      dc_pph23_value = 0;
    }
    let dc_total = (
      Number(dc_base_price) +
      Number(dc_vat_value) -
      Number(dc_pph23_value)
    ).toFixed(2);
    let dc_ctc = (Number(dc_base_price) + Number(dc_vat_value)).toFixed(2);

    if (row[idx].dc_documentation_party === "Internal") {
      if (
        (config.company === "SRPL" ||
          config.company === "AVS" ||
          config.company === "PRPL") &&
        row[idx].billing_currency !== "0" &&
        row[idx].billing_currency !== "USD"
      ) {
        dc_total = (
          (Number(dc_base_price) +
            Number(dc_vat_value) -
            Number(dc_pph23_value)) /
          Number(dc_currency_xchg_rate)
        ).toFixed(2);
        dc_ctc = (
          (Number(dc_base_price) + Number(dc_vat_value)) /
          Number(dc_currency_xchg_rate)
        ).toFixed(2);
      }
    }

    row[idx].dc_base_price = dc_base_price;
    row[idx].dc_vat_value = dc_vat_value;
    row[idx].dc_pph23_value = dc_pph23_value;
    row[idx].dc_total = dc_total;
    row[idx].dc_ctc = dc_ctc;
    this.setState({
      multiRows: row,
    });

    if (
      (config.company === "SDAM" ||
        config.company === "SRK" ||
        config.company === "BTR") &&
      row[idx].dc_pph23_account === "SDAM"
    ) {
      let dc_total = SDAM_dc_base_price;
      let dc_base_price = Number((dc_total * 100) / 98).toFixed(2);
      let dc_pph23_percent = 2;
      let dc_pph23_value = Number(((dc_base_price * 2) / 100).toFixed(2));
      let dc_ctc = Number(dc_base_price).toFixed(0);
      row[idx].dc_base_price = dc_base_price;
      row[idx].dc_pph23_percent = dc_pph23_percent;
      row[idx].dc_pph23_value = dc_pph23_value;
      row[idx].dc_total = dc_total;
      row[idx].dc_ctc = dc_ctc;
      this.setState({
        multiRows: row,
      });
    }
  };

  laporanSurveyCalculations = (idx, value) => {
    var row = this.state.multiRows;

    var dc_laprn_quantity_in_mt = row[idx].dc_laprn_quantity_in_mt
      ? row[idx].dc_laprn_quantity_in_mt
      : 1;
    var dc_laprn_surv_price_per_mt = row[idx].dc_laprn_surv_price_per_mt
      ? row[idx].dc_laprn_surv_price_per_mt
      : 0;
    var dc_laprn_surv_currency_xchg_rate = row[idx]
      .dc_laprn_surv_currency_xchg_rate
      ? row[idx].dc_laprn_surv_currency_xchg_rate
      : 1;
    let dc_laprn_surv_base_price = (
      (Number(dc_laprn_quantity_in_mt) * Number(dc_laprn_surv_price_per_mt)) /
      dc_laprn_surv_currency_xchg_rate
    ).toFixed(4);
    if (value === "base_price") {
      dc_laprn_surv_base_price = Number(row[idx].dc_laprn_surv_base_price);
    }
    var SDAM_dc_laprn_surv_base_price = dc_laprn_surv_base_price;
    let dc_laprn_surv_vat_value = (
      (Number(dc_laprn_surv_base_price) * Number(row[idx].dc_vat_percent)) /
      100
    ).toFixed(0);
    if (value === "vat") {
      dc_laprn_surv_vat_value = Number(row[idx].dc_laprn_surv_vat_value);
    }
    let dc_laprn_surv_pph23_value = (
      (Number(dc_laprn_surv_base_price) * Number(row[idx].dc_pph23_percent)) /
      100
    ).toFixed(0);
    if (
      config.company === "SRPL" ||
      config.company === "AVS" ||
      config.company === "PRPL"
    ) {
      dc_laprn_surv_pph23_value = 0;
    }
    let dc_laprn_surv_total = (
      Number(dc_laprn_surv_base_price) +
      Number(dc_laprn_surv_vat_value) -
      Number(dc_laprn_surv_pph23_value)
    ).toFixed(2);
    let dc_laprn_surv_ctc = (
      Number(dc_laprn_surv_base_price) + Number(dc_laprn_surv_vat_value)
    ).toFixed(2);
    row[idx].dc_laprn_surv_base_price = dc_laprn_surv_base_price;
    row[idx].dc_laprn_surv_vat_value = dc_laprn_surv_vat_value;
    row[idx].dc_laprn_surv_pph23_value = dc_laprn_surv_pph23_value;
    row[idx].dc_laprn_surv_total = dc_laprn_surv_total;
    row[idx].dc_laprn_surv_ctc = dc_laprn_surv_ctc;
    this.setState({
      multiRows: row,
    });

    if (
      (config.company === "SDAM" ||
        config.company === "SRK" ||
        config.company === "BTR") &&
      row[idx].dc_pph23_account === "SDAM"
    ) {
      let dc_laprn_surv_total = SDAM_dc_laprn_surv_base_price;
      let dc_laprn_surv_base_price = Number(
        (dc_laprn_surv_total * 100) / 98
      ).toFixed(2);
      let dc_laprn_surv_pph23_percent = 2;
      let dc_laprn_surv_pph23_value = Number(
        ((dc_laprn_surv_base_price * 2) / 100).toFixed(2)
      );
      let dc_laprn_surv_ctc = Number(dc_laprn_surv_base_price).toFixed(0);
      row[idx].dc_laprn_surv_base_price = dc_laprn_surv_base_price;
      row[idx].dc_laprn_surv_pph23_percent = dc_laprn_surv_pph23_percent;
      row[idx].dc_laprn_surv_pph23_value = dc_laprn_surv_pph23_value;
      row[idx].dc_laprn_surv_total = dc_laprn_surv_total;
      row[idx].dc_laprn_surv_ctc = dc_laprn_surv_ctc;
      this.setState({
        multiRows: row,
      });
    }
  };

  otherChargesCalculations = (idx, index, name) => {
    let row = this.state.multiRows;
    let sub_row = row[idx].other_charges;
    let base_price = Number(
      sub_row[index].other_base_price ? sub_row[index].other_base_price : 1
    );
    let vat_percent = Number(
      sub_row[index].other_vat_percent ? sub_row[index].other_vat_percent : 10
    );

    let other_vat_value = (
      (Number(base_price) * Number(vat_percent)) /
      100
    ).toFixed(0);
    if (name === "vat") {
      other_vat_value = Number(sub_row[index].other_vat_value);
    }
    let other_pph23_value = (
      (Number(base_price) * Number(sub_row[index].other_pph23_percent)) /
      100
    ).toFixed(2);
    if (
      config.company === "SRPL" ||
      config.company === "AVS" ||
      config.company === "PRPL"
    ) {
      other_pph23_value = "";
    }
    let exchange_rate = Number(
      sub_row[index].other_currency_xchg_rate
        ? sub_row[index].other_currency_xchg_rate
        : 1
    );

    let other_total = (
      Number(base_price) +
      Number(other_vat_value) -
      Number(other_pph23_value)
    ).toFixed(2);
    let other_ctc = (Number(base_price) + Number(other_vat_value)).toFixed(2);
    if (
      (config.company === "SDAM" ||
        config.company === "SRK" ||
        config.company === "BTR") &&
      row[idx].other_currency !== "0" &&
      row[idx].other_currency !== "IDR"
    ) {
      other_total = (
        (Number(base_price) +
          Number(other_vat_value) -
          Number(other_pph23_value)) *
        Number(exchange_rate)
      ).toFixed(2);
      other_ctc = (
        (Number(base_price) + Number(other_vat_value)) *
        Number(exchange_rate)
      ).toFixed(2);
    }

    sub_row[index].other_vat_value = other_vat_value;
    sub_row[index].other_pph23_value = other_pph23_value;
    sub_row[index].other_total = other_total;
    sub_row[index].other_ctc = other_ctc;

    this.setState({
      multiRows: row,
    });
  };

  removeOtherChargesRowsHandler = (idx, index) => () => {
    const row = this.state.multiRows[idx].other_charges;
    row.splice(index, 1);
    this.setState({
      multiRows: this.state.multiRows,
    });
  };

  addMoreOtherChargesRowsHandler = (idx) => {
    const item = {
      other_base_price: "",
      other_description: "",
      other_vat_percent: "10",
      other_vat_value: "",
      other_pph23_percent: "2",
      other_pph23_value: "",
      other_total: "",
      other_ctc: "",
      other_currency: "0",
      other_currency_xchg_rate: "",
      other_ctc_in_local_currency: "",
    };
    var otherRows = this.state.multiRows[idx].other_charges;
    let data = [...otherRows, item];
    //this.state.purchaseTypeRows[idx].purchase_types = data;
    let newState = Object.assign({}, this.state);
    newState.multiRows[idx].other_charges = data;
    this.setState({
      newState,
    });
  };

  submitHandler = () => {
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    var multiRows = this.state.multiRows;
    const costing_file = this.state.costing_files?.filter(
      (e) => e.file_content !== "" && e.column === "invoice_file"
    );

    for (var i in multiRows) {
      multiRows[i].costing_files = costing_file;
      if (
        multiRows[i].dc_documentation_by === "" ||
        multiRows[i].dc_documentation_by === null
      ) {
        multiRows[i].dc_documentation_by_error = true;
        this.setState({
          multiRows: multiRows,
        });
        alert("Please fill mandatoey fields");
        return;
      }
    }
    /**
     *  Checking Mandatory files
     */
    var missing_fields = 0;
    let files_missing = 0;
    var files_data = this.state.files;
    for (var obj of files_data) {
      if (obj.file_type !== "" || obj.file_content !== "") {
        if (obj.file_content !== "") {
          if (obj.file_description === "") {
            missing_fields++;
            obj.file_description_error = true;
          } else {
            obj.file_description = obj.file_description.toUpperCase();
          }
        } else if (obj.file_description !== "") {
          if (obj.file_content === "") {
            files_missing++;
          }
        }
      }
    }
    this.setState({
      files: files_data,
    });

    if (missing_fields > 0) {
      this.setState({
        snackBarErrorOpen: true,
        submitLoading: false,
        errorMsg: "Please fill mandatory fields.",
      });
      return;
    }
    if (files_missing > 0) {
      this.setState({
        snackBarErrorOpen: true,
        submitLoading: false,
        errorMsg: "Please Select file.",
      });
      return;
    }
    var existing_file_ids = [];
    const existing_files = files_data.filter((e) => e.id);
    for (var j of existing_files) {
      existing_file_ids.push(j.id);
    }
    const new_files = files_data.filter((e) => !e.id);
    const content_files = new_files.filter((e) => e.file_content !== "");
    var finalData = multiRows[0];
    finalData.mv_doc_charges_id = this.props.MVDocChargesID;
    finalData.login_user_id = loginUserID;
    finalData.idtoken = idToken;
    finalData.existing_file_ids = existing_file_ids;
    finalData.files = content_files;
    //Update
    api.update_mv_doc_charges(finalData).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.setState(
              {
                successMsg: res.message,
                snackBarSuccessOpen: true,
              },
              () => {
                setTimeout(() => {
                  this.props.onUpdateCosting({
                    screen: "MvDoc",
                    code: res.code,
                    message: "",
                  });
                }, 2000);
              }
            );
          } else if (res.code === "601") {
            this.setState({
              errorMsg: res.message,
              snackBarErrorOpen: true,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
  };

  render() {
    const re = /^[0-9]*\.?[0-9]*$/;
    const quan = /^\d*(\.\d{0,3})?$/;
    const pr = /^\d*(\.\d{0,4})?$/;
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div
          className="container bg-white p-0"
          style={{ minHeight: "100vh", width: 850 }}
        >
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <div className="row clearfix content-header border-bottom">
            <div className="col-lg-12 pl-0">
              <h4
                style={{ padding: "10px 20px" }}
              >{`Update Documentation Charges - ${this.state.costing_no}`}</h4>
            </div>
          </div>
          <div className="drawer-section">
            <div className="card p-3">
              <div className="card-body p-0">
                {!this.props.updateDocuments && (
                  <div>
                    {this.state.multiRows.map((e, idx) => (
                      <div className="row" key={idx}>
                        <div
                          className={
                            this.state.multiRows.length > 1
                              ? "col-lg-11 border_dashed rounded-lg p-2 mb-3"
                              : "col-lg-12 border_dashed rounded-lg p-2 mb-3"
                          }
                        >
                          <div className="row mb-3 ">
                            <div className="col-lg-5 pl-0">
                              <div className="row">
                                <div className="col-lg-12 mb-1 p-0">
                                  <label className="form_label mb-0">
                                    Documentation by{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <Autocomplete
                                    options={this.state.vendorTypeAsOthers}
                                    getOptionLabel={(option) =>
                                      option.dc_documentation_name
                                    }
                                    onChange={(event, value) => {
                                      var row = this.state.multiRows;
                                      if (value !== null) {
                                        row[idx].dc_documentation_by =
                                          value.value;
                                        row[
                                          idx
                                        ].dc_documentation_by_error = false;
                                      }
                                      this.setState({
                                        multiRows: row,
                                      });
                                    }}
                                    value={e}
                                    name="dc_documentation_by"
                                    size="small"
                                    error={e.dc_documentation_by_error}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="Search Vendor"
                                        variant="outlined"
                                        error={e.dc_documentation_by_error}
                                        style={{ top: 8 }}
                                        fullWidth
                                      />
                                    )}
                                  />
                                </div>
                                <div className="col-lg-12 mb-1 mt-1 p-0">
                                  <label className="form_label mb-0">
                                    Quantity in MT
                                  </label>
                                  <TextField
                                    name="dc_quantity_in_mt"
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth
                                    type="number"
                                    InputProps={{
                                      style: {
                                        fontFamily: "Poppins",
                                        fontSize: "14px",
                                      },
                                    }}
                                    value={e.dc_quantity_in_mt}
                                    placeholder="Enter Value"
                                    onChange={(e) => {
                                      if (
                                        e.target.value === "" ||
                                        quan.test(e.target.value)
                                      ) {
                                        var value = e.target.value;
                                        if (value === "") {
                                          value = "1";
                                        }
                                        var row = this.state.multiRows;
                                        row[idx].dc_quantity_in_mt =
                                          e.target.value;
                                        this.setState(
                                          {
                                            multiRows: row,
                                          },
                                          () =>
                                            this.documentationChargesCalculations(
                                              idx
                                            )
                                        );
                                      }
                                    }}
                                  />
                                </div>

                                <div className="col-lg-12 mb-1 p-0">
                                  <label className="form_label mb-0">
                                    Documentation Party
                                  </label>
                                  <RadioGroup
                                    aria-label="dc_documentation_party"
                                    name="dc_documentation_party"
                                    style={{ flexDirection: "row" }}
                                    onChange={(e) => {
                                      var row = this.state.multiRows;
                                      row[idx].dc_documentation_party =
                                        e.target.value;
                                      this.setState(
                                        {
                                          multiRows: row,
                                        },
                                        () =>
                                          this.documentationChargesCalculations(
                                            idx
                                          )
                                      );
                                    }}
                                  >
                                    <FormControlLabel
                                      value="Third Party"
                                      control={
                                        <NewRadio
                                          checked={
                                            e.dc_documentation_party ===
                                            "Third Party"
                                          }
                                        />
                                      }
                                      label="Third Party"
                                    />
                                    <FormControlLabel
                                      value="Internal"
                                      control={
                                        <NewRadio
                                          checked={
                                            e.dc_documentation_party ===
                                            "Internal"
                                          }
                                        />
                                      }
                                      label="Internal"
                                    />
                                  </RadioGroup>
                                </div>
                                {e.dc_documentation_party === "Third Party" && (
                                  <div className="col-lg-12 mb-1 p-0">
                                    <label className="form_label mb-0">
                                      Price per MT
                                    </label>
                                    <TextField
                                      name="dc_price_per_mt"
                                      margin="dense"
                                      variant="outlined"
                                      fullWidth
                                      value={e.dc_price_per_mt}
                                      placeholder="Enter Value"
                                      onChange={(e) => {
                                        if (
                                          e.target.value === "" ||
                                          pr.test(e.target.value)
                                        ) {
                                          var value = e.target.value;
                                          if (value === "") {
                                            value = "1";
                                          }
                                          var row = this.state.multiRows;
                                          row[idx].dc_price_per_mt =
                                            e.target.value;
                                          this.setState(
                                            {
                                              multiRows: row,
                                            },
                                            () =>
                                              this.documentationChargesCalculations(
                                                idx
                                              )
                                          );
                                        }
                                      }}
                                      InputProps={{
                                        endAdornment: (config.company ===
                                          "SRPL" ||
                                          config.company === "PRPL" ||
                                          config.company === "AVS") && (
                                            <InputAdornment
                                              // variant="outlined"
                                              position="end"
                                              style={{
                                                marginRight: "-14px",
                                                marginTop: "-4px",
                                                width: "50%",
                                              }}
                                            >
                                              <TextField
                                                name="billing_currency"
                                                margin="dense"
                                                variant="outlined"
                                                fullWidth
                                                select
                                                InputProps={{
                                                  style: {
                                                    fontFamily: "Poppins",
                                                    fontSize: "14px",
                                                  },
                                                }}
                                                value={e.billing_currency}
                                                onChange={(e) => {
                                                  var row = this.state.multiRows;
                                                  row[idx].billing_currency =
                                                    e.target.value;
                                                  this.setState(
                                                    {
                                                      multiRows: row,
                                                    },
                                                    () =>
                                                      this.documentationChargesCalculations(
                                                        idx
                                                      )
                                                  );
                                                }}
                                              >
                                                {this.state.currencyData}
                                              </TextField>
                                            </InputAdornment>
                                          ),
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                        },
                                      }}
                                    />
                                  </div>
                                )}
                                {(config.company === "SRPL" ||
                                  config.company === "AVS" ||
                                  config.company === "PRPL") &&
                                  e.dc_documentation_party === "Internal" && (
                                    <div className="col-lg-12 mb-1 p-0">
                                      <label className="form_label mb-0">
                                        Currency
                                      </label>
                                      <TextField
                                        name="billing_currency"
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth
                                        value={e.billing_currency}
                                        select
                                        InputProps={{
                                          style: {
                                            fontFamily: "Poppins",
                                            fontSize: "14px",
                                          },
                                        }}
                                        onChange={(e) => {
                                          var row = this.state.multiRows;
                                          row[idx].billing_currency =
                                            e.target.value;
                                          this.setState(
                                            {
                                              multiRows: row,
                                            },
                                            () =>
                                              this.documentationChargesCalculations(
                                                idx
                                              )
                                          );
                                        }}
                                      >
                                        {this.state.currencyData}
                                      </TextField>
                                    </div>
                                  )}

                                {(config.company === "SRPL" ||
                                  config.company === "AVS" ||
                                  config.company === "PRPL") &&
                                  e.billing_currency !== "USD" &&
                                  e.billing_currency !== "0" && (
                                    <div className="col-lg-12 mb-1 p-0">
                                      <label className="form_label mb-0">
                                        Exchange Rate USD to{" "}
                                        {e.billing_currency}
                                      </label>
                                      <TextField
                                        name="dc_currency_xchg_rate"
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth
                                        placeholder="Enter Value"
                                        value={e.dc_currency_xchg_rate}
                                        type="number"
                                        InputProps={{
                                          style: {
                                            fontFamily: "Poppins",
                                            fontSize: "14px",
                                          },
                                        }}
                                        onChange={(e) => {
                                          if (
                                            e.target.value === "" ||
                                            re.test(e.target.value)
                                          ) {
                                            var value = e.target.value;
                                            if (value === "") {
                                              value = "1";
                                            }
                                            var row = this.state.multiRows;
                                            row[idx].dc_currency_xchg_rate =
                                              e.target.value;
                                            this.setState(
                                              {
                                                multiRows: row,
                                              },
                                              () =>
                                                this.documentationChargesCalculations(
                                                  idx
                                                )
                                            );
                                          }
                                        }}
                                      />
                                    </div>
                                  )}
                                {(config.company === "SDAM" ||
                                  config.company === "SRK" ||
                                  config.company === "BTR") && (
                                    <div className="col-lg-12 mb-1 p-0">
                                      <label className="form_label mb-0">
                                        PPH23 Account
                                      </label>
                                      <TextField
                                        name="pph15_account"
                                        margin="dense"
                                        variant="outlined"
                                        value={e.dc_pph23_account}
                                        select
                                        fullWidth
                                        InputProps={{
                                          style: {
                                            fontFamily: "Poppins",
                                            fontSize: "14px",
                                          },
                                        }}
                                        onChange={(e) => {
                                          var row = this.state.multiRows;
                                          row[idx].dc_pph23_account =
                                            e.target.value;
                                          this.setState(
                                            {
                                              multiRows: row,
                                            },
                                            () => {
                                              this.documentationChargesCalculations(
                                                idx
                                              );
                                              this.laporanSurveyCalculations(idx);
                                            }
                                          );
                                        }}
                                      >
                                        <MenuItem value="0">
                                          Please Select
                                        </MenuItem>
                                        <MenuItem value="Vendor">Vendor</MenuItem>
                                        <MenuItem value="SDAM">SDAM</MenuItem>
                                      </TextField>
                                    </div>
                                  )}
                              </div>
                            </div>
                            {(config.company === "SRPL" ||
                              config.company === "AVS" ||
                              config.company === "PRPL" ||
                              ((config.company === "SDAM" ||
                                config.company === "SRK" ||
                                config.company === "BTR") &&
                                e.dc_pph23_account === "Vendor")) && (
                                <div
                                  className="col-lg-7 p-2 rounded"
                                  style={{ backgroundColor: "#f7f3f0" }}
                                >
                                  <div className="row col-lg-12 p-0">
                                    <h6>Documentation Charges</h6>
                                  </div>
                                  <div className="row">
                                    <div className="col-6 p-0 mb-2">
                                      <label
                                        className="form_label mb-0"
                                        style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                      >
                                        Base price
                                      </label>
                                      {/* <div className='contract_display_header_value' style={{ color: '#42352d' }}>{e.dc_base_price ? toLocaleString(e.dc_base_price) : "-"}</div> */}
                                      <TextField
                                        name="dc_base_price"
                                        margin="dense"
                                        //variant='outlined'
                                        fullWidth
                                        value={e.dc_base_price}
                                        onChange={(e) => {
                                          if (
                                            e.target.value === "" ||
                                            pr.test(e.target.value)
                                          ) {
                                            var row = this.state.multiRows;
                                            row[idx].dc_base_price =
                                              e.target.value;
                                            this.setState(
                                              {
                                                multiRows: row,
                                              },
                                              () =>
                                                this.documentationChargesCalculations(
                                                  idx,
                                                  "base_price"
                                                )
                                            );
                                          }
                                        }}
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment
                                              // variant="outlined"
                                              position="end"
                                            >
                                              {(config.company === "SRPL" ||
                                                config.company === "AVS" ||
                                                config.company === "PRPL") &&
                                                e.billing_currency !== "0" ? (
                                                <span>{e.billing_currency}</span>
                                              ) : config.company === "SDAM" ||
                                                config.company === "SRK" ||
                                                config.company === "BTR" ? (
                                                "IDR"
                                              ) : (
                                                "USD"
                                              )}
                                            </InputAdornment>
                                          ),
                                          style: {
                                            fontFamily: "Poppins",
                                            fontSize: "14px",
                                          },
                                        }}
                                      />
                                    </div>
                                    <div className="col-6 pr-0 mb-2">
                                      <label
                                        className="form_label mb-0"
                                        style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                      >
                                        VAT %
                                      </label>
                                      <div
                                        className="contract_display_header_value"
                                        style={{ color: "#42352d" }}
                                      >
                                        {/* {e.dc_vat_percent
                                        ? toLocaleString(e.dc_vat_percent)
                                        : "-"} */}
                                        <TextField
                                          name="dc_vat_percent"
                                          margin="dense"
                                          // variant="outlined"
                                          InputProps={{
                                            style: {
                                              fontFamily: "Poppins",
                                              fontSize: "14px",
                                            },
                                          }}
                                          //fullWidth
                                          value={e.dc_vat_percent}
                                          onChange={(e) => {
                                            if (
                                              e.target.value === "" ||
                                              pr.test(e.target.value)
                                            ) {
                                              var row = this.state.multiRows;
                                              row[idx].dc_vat_percent =
                                                e.target.value;

                                              this.setState(
                                                {
                                                  multiRows: row,
                                                },
                                                () =>
                                                  this.documentationChargesCalculations(
                                                    idx
                                                  )
                                              );
                                            }
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-6 p-0 mb-2">
                                      <label
                                        className="form_label mb-0"
                                        style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                      >
                                        VAT
                                      </label>
                                      <TextField
                                        name="dc_vat_value"
                                        margin="dense"
                                        // variant='outlined'
                                        fullWidth
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment
                                              // variant="outlined"
                                              position="end"
                                            >
                                              {(config.company === "SRPL" ||
                                                config.company === "AVS" ||
                                                config.company === "PRPL") &&
                                                e.billing_currency !== "0" ? (
                                                <span>{e.billing_currency}</span>
                                              ) : config.company === "SDAM" ||
                                                config.company === "SRK" ||
                                                config.company === "BTR" ? (
                                                "IDR"
                                              ) : (
                                                "USD"
                                              )}
                                            </InputAdornment>
                                          ),
                                          style: {
                                            fontFamily: "Poppins",
                                            fontSize: "14px",
                                          },
                                        }}
                                        value={e.dc_vat_value}
                                        onChange={(e) => {
                                          if (
                                            e.target.value === "" ||
                                            pr.test(e.target.value)
                                          ) {
                                            var row = this.state.multiRows;
                                            row[idx].dc_vat_value =
                                              e.target.value;
                                            this.setState(
                                              {
                                                multiRows: row,
                                              },
                                              () =>
                                                this.documentationChargesCalculations(
                                                  idx,
                                                  "vat"
                                                )
                                            );
                                          }
                                        }}
                                      />
                                    </div>
                                    {(config.company === "SDAM" ||
                                      config.company === "SRK" ||
                                      config.company === "BTR") && (
                                        <div className="col-lg-6 pr-0 mb-3">
                                          <label
                                            className="form_label mb-0"
                                            style={{
                                              color: "rgba(72, 59, 52, 0.7)",
                                            }}
                                          >
                                            Includes PPH23 @ 2%
                                          </label>
                                          <div
                                            className="contract_display_header_value"
                                            style={{ color: "#42352d" }}
                                          >
                                            {e.dc_pph23_value
                                              ? toLocaleString(e.dc_pph23_value)
                                              : "-"}
                                          </div>
                                        </div>
                                      )}
                                  </div>

                                  <div className="row">
                                    <div
                                      className={
                                        config.company === "SRPL" ||
                                          config.company === "AVS" ||
                                          config.company === "PRPL"
                                          ? "col-lg-6 p-0"
                                          : "col-lg-6 p-0"
                                      }
                                    >
                                      <label
                                        className="form_label mb-0"
                                        style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                      >
                                        Payable to Vendor
                                      </label>
                                      <div
                                        className="contract_display_header_value"
                                        style={{ color: "#42352d" }}
                                      >
                                        {e.dc_total
                                          ? toLocaleString(e.dc_total)
                                          : 0}{" "}
                                        {config.company === "SRPL" ||
                                          config.company === "AVS" ||
                                          config.company === "PRPL"
                                          ? "USD"
                                          : "IDR"}
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        config.company === "SRPL" ||
                                          config.company === "AVS" ||
                                          config.company === "PRPL"
                                          ? "col-lg-6 pr-0"
                                          : "col-lg-6 pr-0"
                                      }
                                    >
                                      <label
                                        className="form_label mb-0"
                                        style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                      >
                                        Cost to Company
                                      </label>
                                      <div
                                        className="contract_display_header_value"
                                        style={{ color: "#42352d" }}
                                      >
                                        {e.dc_ctc ? toLocaleString(e.dc_ctc) : 0}{" "}
                                        {config.company === "SRPL" ||
                                          config.company === "AVS" ||
                                          config.company === "PRPL"
                                          ? "USD"
                                          : "IDR"}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            {(config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") &&
                              e.dc_pph23_account === "SDAM" && (
                                <div
                                  className="col-lg-7 p-2 rounded"
                                  style={{ backgroundColor: "#f7f3f0" }}
                                >
                                  <div className="row col-lg-12 p-0">
                                    <h6>Documentation Charges</h6>
                                  </div>
                                  <div className="row">
                                    <div className="col-6 p-0 mb-2">
                                      <label
                                        className="form_label mb-0"
                                        style={{
                                          color: "rgba(72, 59, 52, 0.7)",
                                        }}
                                      >
                                        Base price
                                      </label>
                                      <div
                                        className="contract_display_header_value"
                                        style={{ color: "#42352d" }}
                                      >
                                        {e.dc_base_price
                                          ? toLocaleString(e.dc_base_price)
                                          : "-"}
                                      </div>
                                    </div>
                                    {(config.company === "SDAM" ||
                                      config.company === "SRK" ||
                                      config.company === "BTR") && (
                                        <div className="col-lg-6 mb-3">
                                          <label
                                            className="form_label mb-0"
                                            style={{
                                              color: "rgba(72, 59, 52, 0.7)",
                                            }}
                                          >
                                            Includes PPH23 @ 2%
                                          </label>
                                          <div
                                            className="contract_display_header_value"
                                            style={{ color: "#42352d" }}
                                          >
                                            {e.dc_pph23_value
                                              ? toLocaleString(e.dc_pph23_value)
                                              : "-"}
                                          </div>
                                        </div>
                                      )}
                                  </div>

                                  <div className="row">
                                    <div
                                      className={
                                        config.company === "SRPL" ||
                                          config.company === "AVS" ||
                                          config.company === "PRPL"
                                          ? "col-lg-6 p-0"
                                          : "col-lg-6 p-0"
                                      }
                                    >
                                      <label
                                        className="form_label mb-0"
                                        style={{
                                          color: "rgba(72, 59, 52, 0.7)",
                                        }}
                                      >
                                        Payable to Vendor
                                      </label>
                                      {/* <div className='contract_display_header_value' style={{ color: '#42352d' }}>{e.dc_total ? toLocaleString(e.dc_total) : "-"}</div> */}
                                      <TextField
                                        name="dc_total"
                                        margin="dense"
                                        //variant='outlined'
                                        InputProps={{
                                          style: {
                                            fontFamily: "Poppins",
                                            fontSize: "14px",
                                          },
                                        }}
                                        //fullWidth
                                        value={e.dc_total}
                                        onChange={(e) => {
                                          if (
                                            e.target.value === "" ||
                                            pr.test(e.target.value)
                                          ) {
                                            var value = e.target.value;
                                            if (value === "") {
                                              value = "1";
                                            }

                                            var row = this.state.multiRows;
                                            let dc_total = value;
                                            let dc_base_price = Number(
                                              (dc_total * 100) / 98
                                            ).toFixed(2);
                                            let dc_pph23_percent = 2;
                                            let dc_pph23_value = Number(
                                              (
                                                (dc_base_price * 2) /
                                                100
                                              ).toFixed(2)
                                            );
                                            let dc_ctc =
                                              Number(dc_base_price).toFixed(0);

                                            row[idx].dc_base_price =
                                              dc_base_price;
                                            row[idx].dc_total = e.target.value;
                                            row[idx].dc_pph23_percent =
                                              dc_pph23_percent;
                                            row[idx].dc_pph23_value =
                                              dc_pph23_value;
                                            row[idx].dc_ctc = dc_ctc;

                                            this.setState({
                                              multiRows: row,
                                            });
                                          }
                                        }}
                                      />
                                    </div>
                                    <div
                                      className={
                                        config.company === "SRPL" ||
                                          config.company === "AVS" ||
                                          config.company === "PRPL"
                                          ? "col-lg-6 pr-0"
                                          : "col-lg-6 pr-0"
                                      }
                                    >
                                      <label
                                        className="form_label mb-0"
                                        style={{
                                          color: "rgba(72, 59, 52, 0.7)",
                                        }}
                                      >
                                        Cost to Company
                                      </label>
                                      <div
                                        className="contract_display_header_value"
                                        style={{ color: "#42352d" }}
                                      >
                                        {e.dc_ctc
                                          ? toLocaleString(e.dc_ctc)
                                          : "-"}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                          </div>

                          <hr />
                          {/**
                           *
                           *
                           *  TODO ???? Laporan Survey Charges
                           *
                           */}
                          {/* <div className="row mb-3 ">
                          <div className="row col-lg-12 p-0">
                            <h5>Laporan Survey Charges</h5>
                          </div>
                          <div className="row">
                            <div className="col-lg-5 pl-0">
                              <div className="row">
                                <div className="col-lg-12 mb-1 p-0">
                                  <label className="form_label mb-0">
                                    Laporan Survey Party
                                  </label>
                                  <RadioGroup
                                    aria-label="dc_laprn_surv_party"
                                    name="dc_laprn_surv_party"
                                    style={{ flexDirection: "row" }}
                                    onChange={(e) => {
                                      var row = this.state.multiRows;
                                      row[idx].dc_laprn_surv_party =
                                        e.target.value;
                                      this.setState(
                                        {
                                          multiRows: row,
                                        },
                                        () =>
                                          this.laporanSurveyCalculations(idx)
                                      );
                                    }}
                                  >
                                    <FormControlLabel
                                      value="Third Party"
                                      control={
                                        <NewRadio
                                          checked={
                                            e.dc_laprn_surv_party ===
                                            "Third Party"
                                          }
                                        />
                                      }
                                      label="Third Party"
                                    />
                                    <FormControlLabel
                                      value="Internal"
                                      control={
                                        <NewRadio
                                          checked={
                                            e.dc_laprn_surv_party === "Internal"
                                          }
                                        />
                                      }
                                      label="Internal"
                                    />
                                  </RadioGroup>
                                </div>

                                <div className="col-lg-12 mb-1 p-0">
                                  <label className="form_label mb-0">
                                    Laporan Survey by
                                  </label>
                                  <Autocomplete
                                    options={this.state.vendorTypeAsSurveyors}
                                    getOptionLabel={(option) =>
                                      option.dc_laprn_surv_name
                                    }
                                    onChange={(event, value) => {
                                      var row = this.state.multiRows;
                                      if (value !== null) {
                                        row[idx].dc_laprn_surv_by = value.value;
                                      }
                                      this.setState({
                                        multiRows: row,
                                      });
                                    }}
                                    value={e}
                                    name="dc_laprn_surv_by"
                                    size="small"
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="Search Vendor"
                                        variant="outlined"
                                        error={this.state.vendorTypeError}
                                        style={{ top: 8 }}
                                        fullWidth
                                      />
                                    )}
                                  />
                                </div>

                                <div className="col-lg-12 mb-1 mt-1 p-0">
                                  <label className="form_label mb-0">
                                    Quantity in MT
                                  </label>
                                  <TextField
                                    name="dc_laprn_quantity_in_mt"
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth
                                    type="number"
                                    InputProps={{
                                      style: {
                                        fontFamily: "Poppins",
                                        fontSize: "14px",
                                      },
                                    }}
                                    value={e.dc_laprn_quantity_in_mt}
                                    placeholder="Enter value"
                                    onChange={(e) => {
                                      if (
                                        e.target.value === "" ||
                                        quan.test(e.target.value)
                                      ) {
                                        var value = e.target.value;
                                        if (value === "") {
                                          value = "1";
                                        }
                                        var row = this.state.multiRows;
                                        row[idx].dc_laprn_quantity_in_mt =
                                          e.target.value;
                                        this.setState(
                                          {
                                            multiRows: row,
                                          },
                                          () =>
                                            this.laporanSurveyCalculations(idx)
                                        );
                                      }
                                    }}
                                  />
                                </div>
                                {(e.dc_laprn_surv_party === "Third Party" ||
                                  e.dc_laprn_surv_party === "Internal") && (
                                  <div className="col-lg-12 mb-1 p-0">
                                    <label className="form_label mb-0">
                                      Price Per MT
                                    </label>
                                    <TextField
                                      name="dc_laprn_surv_price_per_mt"
                                      margin="dense"
                                      variant="outlined"
                                      fullWidth
                                      value={e.dc_laprn_surv_price_per_mt}
                                      placeholder="Enter Value"
                                      onChange={(e) => {
                                        if (
                                          e.target.value === "" ||
                                          pr.test(e.target.value)
                                        ) {
                                          var value = e.target.value;
                                          if (value === "") {
                                            value = "1";
                                          }
                                          var row = this.state.multiRows;
                                          row[idx].dc_laprn_surv_price_per_mt =
                                            e.target.value;

                                          this.setState(
                                            {
                                              multiRows: row,
                                            },
                                            () =>
                                              this.laporanSurveyCalculations(
                                                idx
                                              )
                                          );
                                        }
                                      }}
                                      InputProps={{
                                        endAdornment: (config.company ===
                                          "SRPL" ||
                                          config.company === "PRPL" || config.company === "AVS" ) && (
                                          <InputAdornment
                                            // variant="outlined"
                                            position="end"
                                            style={{
                                              marginRight: "-14px",
                                              marginTop: "-4px",
                                              width: "50%",
                                            }}
                                          >
                                            <TextField
                                              name="dc_laprn_surv_currency"
                                              margin="dense"
                                              variant="outlined"
                                              fullWidth
                                              placeholder="Please Select"
                                              select
                                              InputProps={{
                                                style: {
                                                  fontFamily: "Poppins",
                                                  fontSize: "14px",
                                                },
                                              }}
                                              value={e.dc_laprn_surv_currency}
                                              onChange={(e) => {
                                                var row = this.state.multiRows;
                                                row[
                                                  idx
                                                ].dc_laprn_surv_currency =
                                                  e.target.value;
                                                this.setState(
                                                  {
                                                    multiRows: row,
                                                  },
                                                  () =>
                                                    this.laporanSurveyCalculations(
                                                      idx
                                                    )
                                                );
                                              }}
                                            >
                                              {this.state.currencyData}
                                            </TextField>
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  </div>
                                )}
                                {(config.company === "SRPL" || config.company === "AVS"  ||
                                  config.company === "PRPL") &&
                                  e.dc_laprn_surv_currency !== "USD" &&
                                  e.dc_laprn_surv_currency !== "0" && (
                                    <div className="col-lg-12 mb-1 p-0">
                                      <label className="form_label mb-0">
                                        {" "}
                                        Exchange Rate USD to{" "}
                                        {e.dc_laprn_surv_currency}
                                      </label>
                                      <TextField
                                        name="dc_laprn_surv_currency_xchg_rate"
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth
                                        InputProps={{
                                          style: {
                                            fontFamily: "Poppins",
                                            fontSize: "14px",
                                          },
                                        }}
                                        placeholder="Enter Value"
                                        value={
                                          e.dc_laprn_surv_currency_xchg_rate
                                        }
                                        type="number"
                                        onChange={(e) => {
                                          if (
                                            e.target.value === "" ||
                                            re.test(e.target.value)
                                          ) {
                                            var value = e.target.value;
                                            if (value === "") {
                                              value = "1";
                                            }
                                            var row = this.state.multiRows;
                                            row[
                                              idx
                                            ].dc_laprn_surv_currency_xchg_rate =
                                              e.target.value;
                                            this.setState(
                                              {
                                                multiRows: row,
                                              },
                                              () =>
                                                this.laporanSurveyCalculations(
                                                  idx
                                                )
                                            );
                                          }
                                        }}
                                      />
                                    </div>
                                  )}
                                {(config.company === "SDAM" ||
                                  config.company === "SRK" ||
                                  config.company === "BTR") && (
                                  <div className="col-lg-12 mb-1 p-0">
                                    <label className="form_label mb-0">
                                      Cek Fisic
                                    </label>
                                    <TextField
                                      name="dc_cek_fisic"
                                      margin="dense"
                                      variant="outlined"
                                      fullWidth
                                      value={e.dc_cek_fisic}
                                      placeholder="Enter Value"
                                      onChange={(e) => {
                                        if (
                                          e.target.value === "" ||
                                          pr.test(e.target.value)
                                        ) {
                                          var row = this.state.multiRows;
                                          row[idx].dc_cek_fisic =
                                            e.target.value;
                                          this.setState({
                                            multiRows: row,
                                          });
                                        }
                                      }}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            IDR
                                          </InputAdornment>
                                        ),
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                        },
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                            {(config.company === "SRPL" || config.company === "AVS"  ||
                              config.company === "PRPL" ||
                              ((config.company === "SDAM" ||
                                config.company === "SRK" ||
                                config.company === "BTR") &&
                                e.dc_pph23_account === "Vendor")) && (
                              <div
                                className="col-lg-7 p-2 rounded"
                                style={{ backgroundColor: "#f7f3f0" }}
                              >
                                <div className="row col-lg-12 p-0">
                                  <h6>Laporan Survey Charges</h6>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6 p-0 mb-2">
                                    <label
                                      className="form_label mb-0"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Base price
                                    </label>
                                    <TextField
                                      name="dc_laprn_surv_base_price"
                                      margin="dense"
                                      // variant='outlined'
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment
                                            // variant="outlined"
                                            position="end"
                                          >
                                            {(config.company === "SRPL" || config.company === "AVS"  ||
                                              config.company === "PRPL") &&
                                            e.dc_laprn_surv_currency !== "0" ? (
                                              <span>
                                                {e.dc_laprn_surv_currency}
                                              </span>
                                            ) : config.company === "SDAM" ||
                                              config.company === "SRK" ||
                                              config.company === "BTR" ? (
                                              "IDR"
                                            ) : (
                                              "USD"
                                            )}
                                          </InputAdornment>
                                        ),
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                        },
                                      }}
                                      fullWidth
                                      value={e.dc_laprn_surv_base_price}
                                      onChange={(e) => {
                                        if (
                                          e.target.value === "" ||
                                          pr.test(e.target.value)
                                        ) {
                                          var row = this.state.multiRows;
                                          row[idx].dc_laprn_surv_base_price =
                                            e.target.value;
                                          this.setState(
                                            {
                                              multiRows: row,
                                            },
                                            () =>
                                              this.laporanSurveyCalculations(
                                                idx,
                                                "base_price"
                                              )
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                  <div className="col-lg-6 pr-0 mb-2">
                                    <label
                                      className="form_label mb-0"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      VAT %
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {e.dc_laprn_surv_vat_percent
                                        ? toLocaleString(
                                            e.dc_laprn_surv_vat_percent
                                          )
                                        : "-"}
                                    </div>
                                  </div>
                                  <div className="col-lg-6 p-0 mb-2">
                                    <label
                                      className="form_label mb-0"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      VAT
                                    </label>
                                    <TextField
                                      name="dc_laprn_surv_vat_value"
                                      margin="dense"
                                      //variant='outlined'
                                      fullWidth
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment
                                            // variant="outlined"
                                            position="end"
                                          >
                                            {(config.company === "SRPL" || config.company === "AVS"  ||
                                              config.company === "PRPL") &&
                                            e.dc_laprn_surv_currency !== "0" ? (
                                              <span>
                                                {e.dc_laprn_surv_currency}
                                              </span>
                                            ) : config.company === "SDAM" ||
                                              config.company === "SRK" ||
                                              config.company === "BTR" ? (
                                              "IDR"
                                            ) : (
                                              "USD"
                                            )}
                                          </InputAdornment>
                                        ),
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                        },
                                      }}
                                      value={e.dc_laprn_surv_vat_value}
                                      onChange={(e) => {
                                        if (
                                          e.target.value === "" ||
                                          pr.test(e.target.value)
                                        ) {
                                          var row = this.state.multiRows;
                                          row[idx].dc_laprn_surv_vat_value =
                                            e.target.value;
                                          this.setState(
                                            {
                                              multiRows: row,
                                            },
                                            () =>
                                              this.laporanSurveyCalculations(
                                                idx,
                                                "vat"
                                              )
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                  {(config.company === "SDAM" ||
                                    config.company === "SRK" ||
                                    config.company === "BTR") && (
                                    <div className="col-lg-6 pr-0 mb-3">
                                      <label
                                        className="form_label mb-0"
                                        style={{
                                          color: "rgba(72, 59, 52, 0.7)",
                                        }}
                                      >
                                        Includes PPH23 @ 2%
                                      </label>
                                      <div
                                        className="contract_display_header_value"
                                        style={{ color: "#42352d" }}
                                      >
                                        {e.dc_laprn_surv_pph23_value
                                          ? toLocaleString(
                                              e.dc_laprn_surv_pph23_value
                                            )
                                          : "-"}
                                      </div>
                                    </div>
                                  )}
                                </div>

                                <div className="row">
                                  <div
                                    className={
                                      config.company === "SRPL" || config.company === "AVS"  ||
                                      config.company === "PRPL"
                                        ? "col-lg-6 p-0"
                                        : "col-lg-6 p-0"
                                    }
                                  >
                                    <label
                                      className="form_label mb-0"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Payable to Vendor
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {e.dc_laprn_surv_total
                                        ? toLocaleString(e.dc_laprn_surv_total)
                                        : 0}{" "}
                                      {config.company === "SRPL" || config.company === "AVS"  ||
                                      config.company === "PRPL"
                                        ? "USD"
                                        : "IDR"}
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      config.company === "SRPL" || config.company === "AVS"  ||
                                      config.company === "PRPL"
                                        ? "col-lg-6 pr-0"
                                        : "col-lg-6 pr-0"
                                    }
                                  >
                                    <label
                                      className="form_label mb-0"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Cost to Company
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {e.dc_laprn_surv_ctc
                                        ? toLocaleString(e.dc_laprn_surv_ctc)
                                        : 0}{" "}
                                      {config.company === "SRPL" || config.company === "AVS"  ||
                                      config.company === "PRPL"
                                        ? "USD"
                                        : "IDR"}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            {(config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") &&
                              e.dc_pph23_account === "SDAM" && (
                                <div
                                  className="col-lg-7 p-2 rounded"
                                  style={{ backgroundColor: "#f7f3f0" }}
                                >
                                  <div className="row col-lg-12 p-0">
                                    <h6>Laporan Survey Charges</h6>
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-6 p-0 mb-2">
                                      <label
                                        className="form_label mb-0"
                                        style={{
                                          color: "rgba(72, 59, 52, 0.7)",
                                        }}
                                      >
                                        Base price
                                      </label>
                                      <div
                                        className="contract_display_header_value"
                                        style={{ color: "#42352d" }}
                                      >
                                        {e.dc_laprn_surv_base_price
                                          ? toLocaleString(
                                              e.dc_laprn_surv_base_price
                                            )
                                          : "-"}
                                      </div>
                                    </div>
                                    {/* <div className='col-lg-6 pr-0 mb-2'>
                                  <label className="form_label mb-0" style={{ color: 'rgba(72, 59, 52, 0.7)' }}>VAT %</label>
                                  <div className='contract_display_header_value' style={{ color: '#42352d' }}>{p.dc_laprn_surv_vat_percent ? toLocaleString(p.dc_laprn_surv_vat_percent) : "-"}</div>
                                </div>
                                <div className='col-lg-6 p-0 mb-2'>
                                  <label className="form_label mb-0" style={{ color: 'rgba(72, 59, 52, 0.7)' }}>VAT</label>
                                  <div className='contract_display_header_value' style={{ color: '#42352d' }}>{p.dc_laprn_surv_vat_value ? toLocaleString(p.dc_laprn_surv_vat_value) : "-"}</div>
                                </div> *
                                    {(config.company === "SDAM" ||
                                      config.company === "SRK" ||
                                      config.company === "BTR") && (
                                      <div className="col-lg-6 mb-3">
                                        <label
                                          className="form_label mb-0"
                                          style={{
                                            color: "rgba(72, 59, 52, 0.7)",
                                          }}
                                        >
                                          Includes PPH23 @ 2%
                                        </label>
                                        <div
                                          className="contract_display_header_value"
                                          style={{ color: "#42352d" }}
                                        >
                                          {e.dc_laprn_surv_pph23_value
                                            ? toLocaleString(
                                                e.dc_laprn_surv_pph23_value
                                              )
                                            : "-"}
                                        </div>
                                      </div>
                                    )}
                                  </div>

                                  <div className="row">
                                    <div
                                      className={
                                        config.company === "SRPL" || config.company === "AVS"  ||
                                        config.company === "PRPL"
                                          ? "col-lg-6 p-0"
                                          : "col-lg-6 p-0"
                                      }
                                    >
                                      <label
                                        className="form_label mb-0"
                                        style={{
                                          color: "rgba(72, 59, 52, 0.7)",
                                        }}
                                      >
                                        Payable to Vendor
                                      </label>
                                      <TextField
                                        name="dc_laprn_surv_total"
                                        margin="dense"
                                        // variant='outlined'
                                        InputProps={{
                                          style: {
                                            fontFamily: "Poppins",
                                            fontSize: "14px",
                                          },
                                        }}
                                        //fullWidth
                                        value={e.dc_laprn_surv_total}
                                        onChange={(e) => {
                                          if (
                                            e.target.value === "" ||
                                            pr.test(e.target.value)
                                          ) {
                                            var value = e.target.value;
                                            if (value === "") {
                                              value = "1";
                                            }
                                            var row = this.state.multiRows;
                                            let dc_laprn_surv_total = value;
                                            let dc_laprn_surv_base_price =
                                              Number(
                                                (dc_laprn_surv_total * 100) / 98
                                              ).toFixed(2);
                                            let dc_laprn_surv_pph23_percent = 2;
                                            let dc_laprn_surv_pph23_value =
                                              Number(
                                                (
                                                  (dc_laprn_surv_base_price *
                                                    2) /
                                                  100
                                                ).toFixed(2)
                                              );
                                            let dc_laprn_surv_ctc = Number(
                                              dc_laprn_surv_base_price
                                            ).toFixed(0);
                                            row[idx].dc_laprn_surv_base_price =
                                              dc_laprn_surv_base_price;
                                            row[
                                              idx
                                            ].dc_laprn_surv_pph23_percent = dc_laprn_surv_pph23_percent;
                                            row[idx].dc_laprn_surv_pph23_value =
                                              dc_laprn_surv_pph23_value;
                                            row[idx].dc_laprn_surv_total =
                                              dc_laprn_surv_total;
                                            row[idx].dc_laprn_surv_ctc =
                                              dc_laprn_surv_ctc;

                                            this.setState({
                                              multiRows: row,
                                            });
                                          }
                                        }}
                                      />
                                    </div>
                                    <div
                                      className={
                                        config.company === "SRPL" || config.company === "AVS"  ||
                                        config.company === "PRPL"
                                          ? "col-lg-6 pr-0"
                                          : "col-lg-6 pr-0"
                                      }
                                    >
                                      <label
                                        className="form_label mb-0"
                                        style={{
                                          color: "rgba(72, 59, 52, 0.7)",
                                        }}
                                      >
                                        Cost to Company
                                      </label>
                                      <div
                                        className="contract_display_header_value"
                                        style={{ color: "#42352d" }}
                                      >
                                        {e.dc_laprn_surv_ctc
                                          ? toLocaleString(e.dc_laprn_surv_ctc)
                                          : 0}{" "}
                                        {config.company === "SRPL" || config.company === "AVS"  ||
                                        config.company === "PRPL"
                                          ? "USD"
                                          : "IDR"}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                        <hr /> */}
                          <div className="">
                            <div className="row mb-3">
                              <div className="row col-lg-12 p-0">
                                <h5>Others</h5>
                              </div>

                              {e.other_charges.map((p, index) => (
                                <div className="row" key={index}>
                                  <div
                                    className={
                                      e.other_charges.length > 1
                                        ? "col-lg-11 border p-2 mb-3"
                                        : "col-lg-12 border p-2 mb-3"
                                    }
                                  >
                                    <div className="row">
                                      <div className="col-lg-5 pl-0">
                                        <div className="row">
                                          <div className="col-lg-12 mb-1 p-0">
                                            <label className="form_label mb-0">
                                              Base Price
                                            </label>
                                            <TextField
                                              name="other_base_price"
                                              margin="dense"
                                              variant="outlined"
                                              //fullWidth
                                              value={p.other_base_price}
                                              onChange={(e) => {
                                                if (
                                                  e.target.value === "" ||
                                                  pr.test(e.target.value)
                                                ) {
                                                  var rows =
                                                    this.state.multiRows;
                                                  var row =
                                                    rows[idx].other_charges;

                                                  row[index].other_base_price =
                                                    e.target.value;
                                                  this.setState(
                                                    {
                                                      multiRows: rows,
                                                    },
                                                    () =>
                                                      this.otherChargesCalculations(
                                                        idx,
                                                        index
                                                      )
                                                  );
                                                }
                                              }}
                                              className="rounded-left"
                                              style={{
                                                borderTopRightRadius: 0,
                                                borderBottomRightRadius: 0,
                                              }}
                                              fullWidth
                                              InputProps={{
                                                endAdornment: (
                                                  <InputAdornment
                                                    // variant="outlined"
                                                    position="end"
                                                    style={
                                                      config.company ===
                                                        "SDAM" ||
                                                        config.company ===
                                                        "SRK" ||
                                                        config.company === "BTR"
                                                        ? {
                                                          marginRight:
                                                            "-14px",
                                                          marginTop: "-4px",
                                                          width: "50%",
                                                        }
                                                        : {}
                                                    }
                                                  >
                                                    {config.company ===
                                                      "SDAM" ||
                                                      config.company === "SRK" ||
                                                      config.company === "BTR" ? (
                                                      <TextField
                                                        name="other_currency"
                                                        margin="dense"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={p.other_currency}
                                                        select
                                                        InputProps={{
                                                          style: {
                                                            fontFamily:
                                                              "Poppins",
                                                            fontSize: "14px",
                                                          },
                                                        }}
                                                        onChange={(e) => {
                                                          var rows =
                                                            this.state
                                                              .multiRows;
                                                          var row =
                                                            rows[idx]
                                                              .other_charges;
                                                          row[
                                                            index
                                                          ].other_currency =
                                                            e.target.value;
                                                          this.setState(
                                                            {
                                                              multiRows: rows,
                                                            },
                                                            () =>
                                                              this.otherChargesCalculations(
                                                                idx,
                                                                index
                                                              )
                                                          );
                                                        }}
                                                      >
                                                        {
                                                          this.state
                                                            .currencyData
                                                        }
                                                      </TextField>
                                                    ) : (
                                                      "USD"
                                                    )}
                                                  </InputAdornment>
                                                ),
                                                style: {
                                                  fontFamily: "Poppins",
                                                  fontSize: "14px",
                                                },
                                              }}
                                            />
                                          </div>
                                          {(config.company === "SDAM" ||
                                            config.company === "SRK" ||
                                            config.company === "BTR") &&
                                            p.other_currency !== "IDR" &&
                                            p.other_currency !== "0" && (
                                              <div className="col-lg-12 mb-1 p-0">
                                                <label className="form_label mb-0">
                                                  Exchange Rate
                                                </label>
                                                <TextField
                                                  name="other_currency_xchg_rate"
                                                  margin="dense"
                                                  variant="outlined"
                                                  fullWidth
                                                  InputProps={{
                                                    style: {
                                                      fontFamily: "Poppins",
                                                      fontSize: "14px",
                                                    },
                                                  }}
                                                  placeholder="Enter Value"
                                                  value={
                                                    p.other_currency_xchg_rate
                                                  }
                                                  onChange={(e) => {
                                                    if (
                                                      e.target.value === "" ||
                                                      re.test(e.target.value)
                                                    ) {
                                                      var value =
                                                        e.target.value;
                                                      if (value === "") {
                                                        value = 1;
                                                      }
                                                      var rows =
                                                        this.state.multiRows;
                                                      var row =
                                                        rows[idx].other_charges;
                                                      row[
                                                        index
                                                      ].other_currency_xchg_rate =
                                                        e.target.value;
                                                      row[
                                                        index
                                                      ].other_ctc_in_local_currency =
                                                        (
                                                          Number(value) *
                                                          Number(
                                                            row[index].other_ctc
                                                          )
                                                        ).toFixed(2);
                                                      this.setState(
                                                        {
                                                          multiRows: rows,
                                                        },
                                                        () =>
                                                          this.otherChargesCalculations(
                                                            idx,
                                                            index
                                                          )
                                                      );
                                                    }
                                                  }}
                                                />
                                              </div>
                                            )}
                                          <div className="col-lg-6 mb-1 p-0">
                                            <label className="form_label mb-0">
                                              VAT %
                                            </label>
                                            <TextField
                                              name="other_vat_percent"
                                              margin="dense"
                                              variant="outlined"
                                              InputProps={{
                                                style: {
                                                  fontFamily: "Poppins",
                                                  fontSize: "14px",
                                                },
                                              }}
                                              //fullWidth
                                              value={p.other_vat_percent}
                                              onChange={(e) => {
                                                if (
                                                  e.target.value === "" ||
                                                  pr.test(e.target.value)
                                                ) {
                                                  var rows =
                                                    this.state.multiRows;
                                                  var row =
                                                    rows[idx].other_charges;
                                                  row[index].other_vat_percent =
                                                    e.target.value;

                                                  this.setState(
                                                    {
                                                      multiRows: rows,
                                                    },
                                                    () =>
                                                      this.otherChargesCalculations(
                                                        idx,
                                                        index
                                                      )
                                                  );
                                                }
                                              }}
                                            />
                                          </div>
                                          <div className="col-lg-6 mb-1 pr-0">
                                            <label className="form_label mb-0">
                                              VAT
                                            </label>
                                            <TextField
                                              name="other_vat_value"
                                              margin="dense"
                                              variant="outlined"
                                              InputProps={{
                                                style: {
                                                  fontFamily: "Poppins",
                                                  fontSize: "14px",
                                                },
                                              }}
                                              //fullWidth
                                              value={p.other_vat_value}
                                              onChange={(e) => {
                                                if (
                                                  e.target.value === "" ||
                                                  pr.test(e.target.value)
                                                ) {
                                                  var rows =
                                                    this.state.multiRows;
                                                  var row =
                                                    rows[idx].other_charges;
                                                  row[index].other_vat_value =
                                                    e.target.value;
                                                  this.setState(
                                                    {
                                                      multiRows: rows,
                                                    },
                                                    () =>
                                                      this.otherChargesCalculations(
                                                        idx,
                                                        index,
                                                        "vat"
                                                      )
                                                  );
                                                }
                                              }}
                                            />
                                          </div>

                                          <div className="col-lg-12 pl-0">
                                            <label className="form_label mb-0">
                                              Description
                                            </label>
                                            <TextField
                                              name="other_description"
                                              margin="dense"
                                              variant="outlined"
                                              fullWidth
                                              InputProps={{
                                                style: {
                                                  fontFamily: "Poppins",
                                                  fontSize: "14px",
                                                },
                                              }}
                                              value={p.other_description}
                                              inputProps={{
                                                style: {
                                                  textTransform: "uppercase",
                                                },
                                              }}
                                              onChange={(e) => {
                                                var rows = this.state.multiRows;
                                                var row =
                                                  rows[idx].other_charges;
                                                row[index].other_description =
                                                  e.target.value;
                                                this.setState({
                                                  multiRows: rows,
                                                });
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="col-lg-7 p-2 rounded"
                                        style={{ backgroundColor: "#f7f3f0" }}
                                      >
                                        {/* <div className='row col-lg-12 p-0'>
                              <h6>OtherCharges</h6>
                            </div> */}
                                        <div className="row">
                                          {(config.company === "SDAM" ||
                                            config.company === "SRK" ||
                                            config.company === "BTR") && (
                                              <div className="col-lg-12 mb-3">
                                                <label
                                                  className="form_label mb-0"
                                                  style={{
                                                    color:
                                                      "rgba(72, 59, 52, 0.7)",
                                                  }}
                                                >
                                                  Includes PPH23 @ 2%
                                                </label>
                                                <div
                                                  className="contract_display_header_value"
                                                  style={{ color: "#42352d" }}
                                                >
                                                  {p.other_pph23_value
                                                    ? toLocaleString(
                                                      p.other_pph23_value
                                                    )
                                                    : "-"}
                                                </div>
                                              </div>
                                            )}

                                          <div className="col-lg-12 mb-3">
                                            <label
                                              className="form_label mb-0"
                                              style={{
                                                color: "rgba(72, 59, 52, 0.7)",
                                              }}
                                            >
                                              Payable to Vendor
                                            </label>
                                            <div
                                              className="contract_display_header_value"
                                              style={{ color: "#42352d" }}
                                            >
                                              {p.other_total
                                                ? toLocaleString(p.other_total)
                                                : 0}{" "}
                                              {config.company === "SRPL" ||
                                                config.company === "AVS" ||
                                                config.company === "PRPL"
                                                ? "USD"
                                                : "IDR"}
                                            </div>
                                          </div>
                                          <div className="col-lg-12 mb-3">
                                            <label
                                              className="form_label mb-0"
                                              style={{
                                                color: "rgba(72, 59, 52, 0.7)",
                                              }}
                                            >
                                              Cost to Company
                                            </label>
                                            <div
                                              className="contract_display_header_value"
                                              style={{ color: "#42352d" }}
                                            >
                                              {p.other_ctc
                                                ? toLocaleString(p.other_ctc)
                                                : 0}{" "}
                                              {config.company === "SRPL" ||
                                                config.company === "AVS" ||
                                                config.company === "PRPL"
                                                ? "USD"
                                                : "IDR"}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {e.other_charges.length > 1 && (
                                    <center className="col-lg-1 d-flex justify-content-center">
                                      <i
                                        className="fa fa-trash-o"
                                        onClick={this.removeOtherChargesRowsHandler(
                                          idx,
                                          index
                                        )}
                                        style={{
                                          paddingLeft: 10,
                                          fontSize: 20,
                                          color: config.themeColor,
                                          cursor: "pointer",
                                        }}
                                      />
                                    </center>
                                  )}
                                </div>
                              ))}
                              <div className="row">
                                <button
                                  type="button"
                                  onClick={() =>
                                    this.addMoreOtherChargesRowsHandler(idx)
                                  }
                                  className="header_button header_button_text addrow_button_adjustment w-auto"
                                  style={{
                                    border: "none",
                                    color: config.themeColor,
                                  }}
                                >
                                  ADD ROW
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                {/* {(this.state.features.includes('524')  || this.state.features.includes('97') ) && */}

                <FileUpload
                  files={this.state.files}
                  type="Costings"
                  invoice_file={this.state.invoice_file}
                  feature_name="Mv_Pur_Fin_Doc_Charges"
                  callbackFn={(data, mandatoryFiles) => {
                    this.setState({
                      files: data,
                      costing_files: mandatoryFiles,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <footer className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button btn-light"
              name="submit"
              onClick={() => {
                this.props.returnCallbackViewPage("MvDoc");
              }}
            >
              Close
            </button>
            <button
              type="button"
              className="drawer_text drawer_btn_success"
              name="Back"
              onClick={this.submitHandler}
            >
              Update
            </button>
          </footer>
        </div>
      );
    }
  }
}
