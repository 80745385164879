import React, { Component } from "react";
import CookieHandler from "../common/CookieHandler";
import api from "../../api/api";
import config from "../../config/config";
import { TextField, Checkbox, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { withStyles } from "@material-ui/core/styles";
import { pad } from "../common/common";
import Loader from "../common/Loader";

const CheckBox = withStyles({
  root: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);
export default class SalesQualityResult extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      businessNoBargeID: "",
      quality_calorific_value_actual: "",
      quality_calorific_value2_actual: "",
      quality_calorific_value3_actual: "",
      quality_total_moisture_actual: "",
      quality_inherent_moisture_actual: "",
      quality_ash_actual: "",
      quality_volatile_matter_actual: "",
      quality_fixed_carbon_actual: "",
      quality_fuel_ratio_actual: "",
      quality_total_sulphur_actual: "",
      quality_hgi_actual: "",

      barge_pur_quality_id: "",
      bargePurchaseQualityResultsAvailable: true,

      errorMsg: "",
      successMsg: "",
      isLoading: true,
      BTRDataAvailable: false,
      features: [],
      totalBarges: [],
      bargeList: [],
      barge_allocations: [],
      files: [
        {
          file_description: "",
          file_name: "",
          file_extension: "",
          file_content: "",
          feature_name: "Sales_Fin_Quality_Results",
        },
      ],
      activeStep: 0,
    };
  }

  componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    } else {
      var features = this.Cookie.getCookie("features");
      this.setState({ features: features });
      if (
        !(
          features.indexOf("55") >= 0 ||
          features.indexOf("57") >= 0 ||
          features.indexOf("97") >= 0
        )
      ) {
        window.location.href = "/dashboard";
      }
      try {
        //var businessNoID = decodeURIComponent(window.atob(this.props.match.params.businessNo));
        var businessNoID = this.props.businessNoID;
      } catch (e) {
        window.location.href = "/pagenotfound";
      }
      const idToken = this.Cookie.getIdTokenCookie();
      const loginUserID = this.Cookie.getCookie("loginUserId");

      api
        .get_barges_for_sales_fin_quality_results(
          loginUserID,
          idToken,
          businessNoID
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                if (res.barges) {
                  if (res.barges.length === 0) {
                    this.setState({
                      snackBarErrorOpen: true,
                      errorMsg: "No barges to display",
                    });
                  }
                  this.setState({
                    totalBarges: res.barges,
                    isLoading: false,
                  });
                } else {
                  alert(
                    "Unexpected error occured. Please contact administrator."
                  );
                }
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    }
    document.title = config.documentTitle + "Sales Quality Results";
  }

  TextHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  submitHandler = () => {
    this.setState({ isLoading: true });
    var idToken = this.Cookie.getIdTokenCookie();
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var {
      quality_calorific_value_actual,
      quality_calorific_value2_actual,
      quality_calorific_value3_actual,
      quality_total_moisture_actual,
      quality_inherent_moisture_actual,
      quality_ash_actual,
      quality_volatile_matter_actual,
      quality_fixed_carbon_actual,
      quality_fuel_ratio_actual,
      quality_total_sulphur_actual,
      quality_hgi_actual,
      files,
    } = this.state;
    var status = "Active";

    /**
     *  Checking Mandatory files
     */
    var missing_fields = 0;
    let files_missing = 0;
    var row = files;
    for (var obj of row) {
      if (obj.file_type !== "" || obj.file_content !== "") {
        if (obj.file_content !== "") {
          if (obj.file_description === "") {
            missing_fields++;
            obj.file_description_error = true;
          } else {
            obj.file_description = obj.file_description.toUpperCase();
          }
        } else if (obj.file_description !== "") {
          if (obj.file_content === "") {
            files_missing++;
          }
        }
      }
    }
    this.setState({
      files: row,
    });

    if (missing_fields > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please fill mandatory fields.",
        isLoading: false,
      });
      return;
    }
    if (files_missing > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please Select file.",
        isLoading: false,
      });
      return;
    }

    const content_files = files.filter((e) => e.file_content !== "");

    if (this.state.bargeList.length > 0) {
      const business_no_id = this.props.businessNoID;
      const business_allocation_id = this.state.barge_allocations.toString();
      const barge_id = this.state.bargeList.toString();
      // Add Barge Sales Quality Results
      api
        .add_barge_sales_fin_quality_result(
          loginUserID,
          idToken,
          business_no_id,
          business_allocation_id,
          barge_id,
          quality_calorific_value_actual,
          quality_calorific_value2_actual,
          quality_calorific_value3_actual,
          quality_total_moisture_actual,
          quality_inherent_moisture_actual,
          quality_ash_actual,
          quality_volatile_matter_actual,
          quality_fixed_carbon_actual,
          quality_fuel_ratio_actual,
          quality_total_sulphur_actual,
          quality_hgi_actual,
          status,
          content_files
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                this.setState({
                  succesMsg: res.message,
                  snackBarSuccessOpen: true,
                });
                window.location.href = "/view-business/" + btoa(business_no_id);
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  snackBarErrorOpen: true,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    } else {
      this.setState({ isLoading: false });
      alert("Please select alteast one barge to submit.");
    }
  };

  onBargesCheck = (value, checked, allocationID, barge) => {
    if (checked) {
      let bargeList = [...this.state.bargeList, value];
      let barge_allocations = [...this.state.barge_allocations, allocationID];
      if (bargeList.length === 1) {
        this.getSpecsData(barge);
        this.setState({
          isLoading: true,
          bargeList: bargeList,
          barge_allocations: barge_allocations,
        });
      } else {
        this.setState({
          bargeList: bargeList,
          barge_allocations: barge_allocations,
        });
      }
    } else {
      var data = this.state.bargeList;
      let allocation = this.state.barge_allocations;

      if (data.indexOf(value) >= 0) {
        let idx = data.indexOf(value);
        data.splice(idx, 1);
      }
      if (allocation.indexOf(allocationID) >= 0) {
        let index = allocation.indexOf(value);
        allocation.splice(index, 1);
      }
      if (data.length === 1) {
        this.getSpecsData(barge);
        this.setState({
          isLoading: true,
          bargeList: data,
          barge_allocations: allocation,
        });
      } else {
        this.setState({
          bargeList: data,
          barge_allocations: allocation,
        });
      }
    }
  };

  getSpecsData = (barge) => {
    const idToken = this.Cookie.getIdTokenCookie();
    const loginUserID = this.Cookie.getCookie("loginUserId");
    if (barge) {
      const business_no_id = barge.business_no_id;
      const business_allocation_id = barge.business_allocation_id;
      const bargeID = barge.barge_id;
      api
        .getAllSalesSpecByBargeID(
          loginUserID,
          idToken,
          business_no_id,
          business_allocation_id,
          bargeID
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                if (res.scq_typical_specs) {
                  this.setState({
                    BTRDataAvailable: true,
                    quality_calorific_value_typical:
                      res.scq_typical_specs.quality_calorific_value_typical,
                    quality_calorific_value_rejection:
                      res.scq_typical_specs.quality_calorific_value_rejection,
                    quality_calorific_value_basis:
                      res.scq_typical_specs.quality_calorific_value_basis,
                    quality_calorific_value2_typical:
                      res.scq_typical_specs.quality_calorific_value2_typical,
                    quality_calorific_value2_rejection:
                      res.scq_typical_specs.quality_calorific_value2_rejection,
                    quality_calorific_value2_basis:
                      res.scq_typical_specs.quality_calorific_value2_basis,
                    quality_calorific_value3_typical:
                      res.scq_typical_specs.quality_calorific_value3_typical,
                    quality_calorific_value3_rejection:
                      res.scq_typical_specs.quality_calorific_value3_rejection,
                    quality_calorific_value3_basis:
                      res.scq_typical_specs.quality_calorific_value3_basis,
                    quality_total_moisture_typical:
                      res.scq_typical_specs.quality_total_moisture_typical,
                    quality_total_moisture_rejection:
                      res.scq_typical_specs.quality_total_moisture_rejection,
                    quality_total_moisture_basis:
                      res.scq_typical_specs.quality_total_moisture_basis,
                    quality_inherent_moisture_typical:
                      res.scq_typical_specs.quality_inherent_moisture_typical,
                    quality_inherent_moisture_rejection:
                      res.scq_typical_specs.quality_inherent_moisture_rejection,
                    quality_inherent_moisture_basis:
                      res.scq_typical_specs.quality_inherent_moisture_basis,
                    quality_ash_typical:
                      res.scq_typical_specs.quality_ash_typical,
                    quality_ash_rejection:
                      res.scq_typical_specs.quality_ash_rejection,
                    quality_ash_basis: res.scq_typical_specs.quality_ash_basis,
                    quality_volatile_matter_typical:
                      res.scq_typical_specs.quality_volatile_matter_typical,
                    quality_volatile_matter_rejection:
                      res.scq_typical_specs.quality_volatile_matter_rejection,
                    quality_volatile_matter_basis:
                      res.scq_typical_specs.quality_volatile_matter_basis,
                    quality_fixed_carbon_typical:
                      res.scq_typical_specs.quality_fixed_carbon_typical,
                    quality_fixed_carbon_rejection:
                      res.scq_typical_specs.quality_fixed_carbon_rejection,
                    quality_fixed_carbon_basis:
                      res.scq_typical_specs.quality_fixed_carbon_basis,
                    quality_fuel_ratio_typical:
                      res.scq_typical_specs.quality_fuel_ratio_typical,
                    quality_fuel_ratio_rejection:
                      res.scq_typical_specs.quality_fuel_ratio_rejection,
                    quality_fuel_ratio_basis:
                      res.scq_typical_specs.quality_fuel_ratio_basis,
                    quality_total_sulphur_typical:
                      res.scq_typical_specs.quality_total_sulphur_typical,
                    quality_total_sulphur_rejection:
                      res.scq_typical_specs.quality_total_sulphur_rejection,
                    quality_total_sulphur_basis:
                      res.scq_typical_specs.quality_total_sulphur_basis,
                    quality_hgi_typical:
                      res.scq_typical_specs.quality_hgi_typical,
                    quality_hgi_rejection:
                      res.scq_typical_specs.quality_hgi_rejection,
                    isLoading: false,
                  });
                } else {
                  this.setState({
                    isLoading: false,
                  });
                }
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  isLoading: false,
                  snackBarErrorOpen: true,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    }
  };

  onSelectAllBarges = (checked) => {
    this.setState({ isLoading: true });
    var totalBarges = this.state.totalBarges;
    if (checked) {
      var bargeList = [];
      let barge_allocations = [];
      for (var i of totalBarges) {
        bargeList.push(i.barge_id);
        barge_allocations.push(i.business_allocation_id);
      }
      this.setState(
        {
          bargeList: bargeList,
          barge_allocations: barge_allocations,
        },
        () => this.getSpecsData(totalBarges.length > 0 ? totalBarges[0] : null)
      );
    } else {
      this.setState({
        bargeList: [],
        barge_allocations: [],
        BTRDataAvailable: false,
        isLoading: false,
      });
    }
  };

  nextStepHandler = () => {
    if (this.state.activeStep === 0) {
      if (this.state.bargeList.length === 0) {
        this.setState({
          snackBarErrorOpen: true,
          errorMsg: "Please Select Barge",
        });
        return;
      }
    }

    this.setState({
      activeStep: this.state.activeStep + 1,
    });
  };

  previousStepHandler = () => {
    this.setState({
      activeStep: this.state.activeStep - 1,
    });
  };

  toLocaleString(value) {
    return Number(value).toLocaleString();
  }

  fileUploadHandler = (idx) => (event) => {
    let file = event.target.files[0];
    let file_data = "";
    let fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onloadend = () => {
      let file_content = fileReader.result;
      let file_extension = file.name.substr(file.name.lastIndexOf(".") + 1);
      let file_name = file.name;
      let file_type = file.type;
      file_data = file_content.replace("data:" + file_type + ";base64,", "");

      var row = this.state.files ? this.state.files : [];
      row[idx].file_name = file_name;
      row[idx].file_extension = file_extension;
      row[idx].file_type = file_type;
      row[idx].file_content = file_data;

      this.setState({
        files: row,
      });
    };
  };
  addMoreFileHandler = () => {
    const data = {
      file_description: "",
      file_name: "",
      file_extension: "",
      file_content: "",
      feature_name: "Sales_Fin_Quality_Results",
    };

    this.setState({
      files: [...this.state.files, data],
    });
  };

  render() {
    const re = /^[0-9]*\.?[0-9]*$/;

    return (
      <div>
        {this.state.isLoading && <Loader />}
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={this.state.snackBarErrorOpen}
          onClose={() => this.setState({ snackBarErrorOpen: false })}
          style={{ width: "450px" }}
        >
          <Alert
            elevation={6}
            variant="filled"
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            severity="error"
            style={{ width: "100%" }}
          >
            {this.state.errorMsg}
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          style={{ width: "450px" }}
          open={this.state.snackBarSuccessOpen}
          autoHideDuration={100000}
          onClose={() => this.setState({ snackBarSuccessOpen: false })}
        >
          <Alert
            elevation={6}
            variant="filled"
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
            severity="success"
            style={{ width: "100%" }}
          >
            {this.state.successMsg}
          </Alert>
        </Snackbar>
        {!this.state.isLoading && (
          <div
            className="container bg-white p-0"
            style={{ minHeight: "100vh" }}
          >
            <div className="row">
              <div className="col-lg-12 p-0 border-bottom ">
                <h6 style={{ padding: "15px", marginBottom: 0 }}>
                  Barge Sales Quality Results
                </h6>
              </div>
            </div>

            <div className="drawer-section">
              <div className="row mb-0 stepper">
                <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                  <i
                    className={
                      this.state.activeStep > 0
                        ? "fa fa-check-circle"
                        : "fa fa-check-circle-o"
                    }
                    aria-hidden="true"
                    style={{
                      fontSize: 20,
                      color:
                        this.state.activeStep > 0
                          ? "#31c197"
                          : this.state.activeStep === 0
                          ? config.themeColor
                          : "#ccc",
                    }}
                  ></i>
                  <span
                    className="stepper_text"
                    style={{
                      color:
                        this.state.activeStep > 0
                          ? "#31c197"
                          : this.state.activeStep === 0
                          ? config.themeColor
                          : "#ccc",
                    }}
                  >
                    Barge Selection
                  </span>
                  <i
                    className="fa fa-chevron-right stepper_sub_icon stepper_arrow"
                    aria-hidden="true"
                    style={{
                      color:
                        this.state.activeStep > 0
                          ? "#31c197"
                          : this.state.activeStep === 0
                          ? config.themeColor
                          : "#ccc",
                    }}
                  ></i>
                </span>

                <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                  <i
                    className={
                      this.state.activeStep > 1
                        ? "fa fa-check-circle"
                        : "fa fa-check-circle-o"
                    }
                    aria-hidden="true"
                    style={{
                      fontSize: 20,
                      color:
                        this.state.activeStep > 1
                          ? "#31c197"
                          : this.state.activeStep === 1
                          ? config.themeColor
                          : "#ccc",
                    }}
                  ></i>
                  <span
                    className="stepper_text"
                    style={{
                      color:
                        this.state.activeStep > 1
                          ? "#31c197"
                          : this.state.activeStep === 1
                          ? config.themeColor
                          : "#ccc",
                    }}
                  >
                    Quality Results
                  </span>
                  <i
                    className="fa fa-chevron-right stepper_sub_icon stepper_arrow"
                    aria-hidden="true"
                    style={{
                      color:
                        this.state.activeStep > 1
                          ? "#31c197"
                          : this.state.activeStep === 1
                          ? config.themeColor
                          : "#ccc",
                    }}
                  ></i>
                </span>
              </div>
              <div className="card p-0">
                <div className="card-body">
                  {this.state.activeStep === 0 && (
                    <div className="section_block w-100">
                      <div style={{ width: "70%" }}>
                        <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                          Select Barge to create Quality
                        </h5>
                      </div>
                      {this.state.totalBarges.length > 0 && (
                        <div className="row">
                          <div className="table-responsive">
                            <table className="table table-hover border-0">
                              <thead className="">
                                <tr>
                                  <th
                                    className="p-0 text-center border-0"
                                    nowrap="true"
                                  >
                                    <CheckBox
                                      //value={this.state.totalBarges}
                                      color="primary"
                                      checked={
                                        this.state.totalBarges.length !== 0 &&
                                        this.state.bargeList.length ===
                                          this.state.totalBarges.length
                                      }
                                      //disabled={this.state.selectedPurchaseContractId === "" || p.purchase_contract_id !== this.state.selectedPurchaseContractId}
                                      // onChange={this.onBargesCheck}
                                      onChange={(e) => {
                                        this.onSelectAllBarges(
                                          e.target.checked
                                        );
                                      }}
                                    />
                                  </th>
                                  <th
                                    nowrap="true"
                                    style={{ fontSize: 16 }}
                                    className="table_header_barge_label text-uppercase border-0"
                                  >
                                    Barge ID
                                  </th>
                                  <th
                                    nowrap="true"
                                    style={{ fontSize: 16 }}
                                    className="table_header_barge_label text-uppercase border-0"
                                  >
                                    Allocation ID
                                  </th>
                                  <th
                                    nowrap="true"
                                    style={{ fontSize: 16 }}
                                    className="table_header_barge_label text-uppercase border-0"
                                  >
                                    Barge Nomination
                                  </th>
                                  <th
                                    nowrap="true"
                                    style={{ fontSize: 16 }}
                                    className="table_header_barge_label text-uppercase border-0"
                                  >
                                    Supplier
                                  </th>
                                  <th
                                    nowrap="true"
                                    style={{ fontSize: 16 }}
                                    className="table_header_barge_label text-uppercase border-0"
                                  >
                                    Sales Quality
                                  </th>
                                  <th
                                    nowrap="true"
                                    style={{ fontSize: 16 }}
                                    className="table_header_barge_label text-uppercase border-0 text-right"
                                  >
                                    Barge Quantity
                                  </th>
                                </tr>
                              </thead>
                              {this.state.totalBarges.map((obj, index) => (
                                <tbody key={index}>
                                  <tr>
                                    <td>
                                      <CheckBox
                                        value={obj.barge_id}
                                        color="primary"
                                        checked={
                                          this.state.bargeList.indexOf(
                                            obj.barge_id
                                          ) >= 0 &&
                                          this.state.barge_allocations.indexOf(
                                            obj.business_allocation_id
                                          ) >= 0
                                        }
                                        //disabled={e.barge_sales_quality_results_id !== null}
                                        onChange={(e) => {
                                          this.onBargesCheck(
                                            e.target.value,
                                            e.target.checked,
                                            obj.business_allocation_id,
                                            obj
                                          );
                                        }}
                                      />
                                    </td>
                                    <td>{pad(obj.barge_id)}</td>
                                    <td>{obj.display_allocation_id}</td>
                                    <td nowrap="true">
                                      {obj.barge_nomination}
                                    </td>
                                    <td nowrap="true">
                                      {obj.coal_vendor_name}
                                    </td>
                                    <td nowrap="true">{obj.sc_quality}</td>
                                    <td nowrap="true" className="text-right">
                                      {this.toLocaleString(obj.barge_quantity)}
                                    </td>
                                  </tr>
                                </tbody>
                              ))}
                            </table>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {this.state.activeStep === 1 &&
                    this.state.bargeList.length > 0 && (
                      <div className="row">
                        <div className="table-responsive mb-3">
                          <table className="table table-bordered mb-0">
                            <thead className="table-header">
                              <tr>
                                <th>Typical Analysis</th>
                                <th>Units</th>
                                <th>Basis</th>
                                {this.state.BTRDataAvailable && (
                                  <th>Typical</th>
                                )}
                                {this.state.BTRDataAvailable && (
                                  <th>Rejection</th>
                                )}
                                {this.state.BTRDataAvailable && <th>Actual</th>}
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td style={{ verticalAlign: "middle" }}>
                                  Gross Calorific Value
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                  Kcal/kg
                                </td>
                                {this.state.BTRDataAvailable && (
                                  <td>
                                    {this.state.quality_calorific_value_basis}
                                  </td>
                                )}
                                {this.state.BTRDataAvailable && (
                                  <td>
                                    {this.state.quality_calorific_value_typical}
                                  </td>
                                )}
                                {this.state.BTRDataAvailable && (
                                  <td>
                                    {
                                      this.state
                                        .quality_calorific_value_rejection
                                    }
                                  </td>
                                )}
                                <td>
                                  <TextField
                                    name="quality_calorific_value_actual"
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth
                                    value={
                                      this.state.quality_calorific_value_actual
                                    }
                                    //onChange={this.TextHandler}
                                    onChange={(e) => {
                                      if (
                                        e.target.value === "" ||
                                        re.test(e.target.value)
                                      ) {
                                        this.setState({
                                          [e.target.name]: e.target.value,
                                        });
                                      }
                                    }}
                                    inputProps={{
                                      style: { textTransform: "uppercase" },
                                    }}
                                  />
                                </td>
                              </tr>

                              <tr>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    borderTop: "none",
                                  }}
                                ></td>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    borderTop: "none",
                                  }}
                                >
                                  Kcal/kg
                                </td>
                                {this.state.BTRDataAvailable && (
                                  <td style={{ borderTop: "none" }}>
                                    {this.state.quality_calorific_value2_basis}
                                  </td>
                                )}
                                {this.state.BTRDataAvailable && (
                                  <td style={{ borderTop: "none" }}>
                                    {
                                      this.state
                                        .quality_calorific_value2_typical
                                    }
                                  </td>
                                )}
                                {this.state.BTRDataAvailable && (
                                  <td style={{ borderTop: "none" }}>
                                    {
                                      this.state
                                        .quality_calorific_value2_rejection
                                    }
                                  </td>
                                )}
                                <td>
                                  <TextField
                                    name="quality_calorific_value2_actual"
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth
                                    value={
                                      this.state.quality_calorific_value2_actual
                                    }
                                    //onChange={this.TextHandler}
                                    onChange={(e) => {
                                      if (
                                        e.target.value === "" ||
                                        re.test(e.target.value)
                                      ) {
                                        this.setState({
                                          [e.target.name]: e.target.value,
                                        });
                                      }
                                    }}
                                    inputProps={{
                                      style: { textTransform: "uppercase" },
                                    }}
                                  />
                                </td>
                              </tr>

                              <tr>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    borderTop: "none",
                                  }}
                                ></td>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    borderTop: "none",
                                  }}
                                >
                                  Kcal/kg
                                </td>
                                {this.state.BTRDataAvailable && (
                                  <td style={{ borderTop: "none" }}>
                                    {this.state.quality_calorific_value3_basis}
                                  </td>
                                )}
                                {this.state.BTRDataAvailable && (
                                  <td style={{ borderTop: "none" }}>
                                    {
                                      this.state
                                        .quality_calorific_value3_typical
                                    }
                                  </td>
                                )}
                                {this.state.BTRDataAvailable && (
                                  <td style={{ borderTop: "none" }}>
                                    {
                                      this.state
                                        .quality_calorific_value3_rejection
                                    }
                                  </td>
                                )}
                                <td>
                                  <TextField
                                    name="quality_calorific_value3_actual"
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth
                                    value={
                                      this.state.quality_calorific_value3_actual
                                    }
                                    //onChange={this.TextHandler}
                                    onChange={(e) => {
                                      if (
                                        e.target.value === "" ||
                                        re.test(e.target.value)
                                      ) {
                                        this.setState({
                                          [e.target.name]: e.target.value,
                                        });
                                      }
                                    }}
                                    inputProps={{
                                      style: { textTransform: "uppercase" },
                                    }}
                                  />
                                </td>
                              </tr>

                              <tr>
                                <td style={{ verticalAlign: "middle" }}>
                                  Total Moisture
                                </td>
                                <td style={{ verticalAlign: "middle" }}>%</td>
                                {this.state.BTRDataAvailable && (
                                  <td>
                                    {this.state.quality_total_moisture_basis}
                                  </td>
                                )}
                                {this.state.BTRDataAvailable && (
                                  <td>
                                    {this.state.quality_total_moisture_typical}
                                  </td>
                                )}
                                {this.state.BTRDataAvailable && (
                                  <td>
                                    {
                                      this.state
                                        .quality_total_moisture_rejection
                                    }
                                  </td>
                                )}
                                <td>
                                  <TextField
                                    name="quality_total_moisture_actual"
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth
                                    value={
                                      this.state.quality_total_moisture_actual
                                    }
                                    onChange={this.TextHandler}
                                    inputProps={{
                                      style: { textTransform: "uppercase" },
                                    }}
                                  />
                                </td>
                              </tr>

                              <tr>
                                <td style={{ verticalAlign: "middle" }}>
                                  Inherent Moisture
                                </td>
                                <td style={{ verticalAlign: "middle" }}>%</td>
                                {this.state.BTRDataAvailable && (
                                  <td>
                                    {this.state.quality_inherent_moisture_basis}
                                  </td>
                                )}
                                {this.state.BTRDataAvailable && (
                                  <td>
                                    {
                                      this.state
                                        .quality_inherent_moisture_typical
                                    }
                                  </td>
                                )}
                                {this.state.BTRDataAvailable && (
                                  <td>
                                    {
                                      this.state
                                        .quality_inherent_moisture_rejection
                                    }
                                  </td>
                                )}
                                <td>
                                  <TextField
                                    name="quality_inherent_moisture_actual"
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth
                                    value={
                                      this.state
                                        .quality_inherent_moisture_actual
                                    }
                                    onChange={this.TextHandler}
                                    inputProps={{
                                      style: { textTransform: "uppercase" },
                                    }}
                                  />
                                </td>
                              </tr>

                              <tr>
                                <td style={{ verticalAlign: "middle" }}>Ash</td>
                                <td style={{ verticalAlign: "middle" }}>%</td>
                                {this.state.BTRDataAvailable && (
                                  <td>{this.state.quality_ash_basis}</td>
                                )}
                                {this.state.BTRDataAvailable && (
                                  <td>{this.state.quality_ash_typical}</td>
                                )}
                                {this.state.BTRDataAvailable && (
                                  <td>{this.state.quality_ash_rejection}</td>
                                )}
                                <td>
                                  <TextField
                                    name="quality_ash_actual"
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth
                                    value={this.state.quality_ash_actual}
                                    //onChange={this.TextHandler}
                                    onChange={(e) => {
                                      if (
                                        e.target.value === "" ||
                                        re.test(e.target.value)
                                      ) {
                                        this.setState({
                                          [e.target.name]: e.target.value,
                                        });
                                      }
                                    }}
                                    inputProps={{
                                      style: { textTransform: "uppercase" },
                                    }}
                                  />
                                </td>
                              </tr>

                              <tr>
                                <td style={{ verticalAlign: "middle" }}>
                                  Volatile Matter(VM)
                                </td>
                                <td style={{ verticalAlign: "middle" }}>%</td>
                                {this.state.BTRDataAvailable && (
                                  <td>
                                    {this.state.quality_volatile_matter_basis}
                                  </td>
                                )}
                                {this.state.BTRDataAvailable && (
                                  <td>
                                    {this.state.quality_volatile_matter_typical}
                                  </td>
                                )}
                                {this.state.BTRDataAvailable && (
                                  <td>
                                    {
                                      this.state
                                        .quality_volatile_matter_rejection
                                    }
                                  </td>
                                )}
                                <td>
                                  <TextField
                                    name="quality_volatile_matter_actual"
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth
                                    value={
                                      this.state.quality_volatile_matter_actual
                                    }
                                    onChange={this.TextHandler}
                                    inputProps={{
                                      style: { textTransform: "uppercase" },
                                    }}
                                  />
                                </td>
                              </tr>

                              <tr>
                                <td style={{ verticalAlign: "middle" }}>
                                  Fixed Carbon(FC)
                                </td>
                                <td style={{ verticalAlign: "middle" }}>%</td>
                                {this.state.BTRDataAvailable && (
                                  <td>
                                    {this.state.quality_fixed_carbon_basis}
                                  </td>
                                )}
                                {this.state.BTRDataAvailable && (
                                  <td>
                                    {this.state.quality_fixed_carbon_typical}
                                  </td>
                                )}
                                {this.state.BTRDataAvailable && (
                                  <td>
                                    {this.state.quality_fixed_carbon_rejection}
                                  </td>
                                )}
                                <td>
                                  <TextField
                                    name="quality_fixed_carbon_actual"
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth
                                    value={
                                      this.state.quality_fixed_carbon_actual
                                    }
                                    onChange={this.TextHandler}
                                    inputProps={{
                                      style: { textTransform: "uppercase" },
                                    }}
                                  />
                                </td>
                              </tr>

                              <tr>
                                <td style={{ verticalAlign: "middle" }}>
                                  Fuel Ratio(FC/VM)
                                </td>
                                <td style={{ verticalAlign: "middle" }}>%</td>
                                {this.state.BTRDataAvailable && (
                                  <td>{this.state.quality_fuel_ratio_basis}</td>
                                )}
                                {this.state.BTRDataAvailable && (
                                  <td>
                                    {this.state.quality_fuel_ratio_typical}
                                  </td>
                                )}
                                {this.state.BTRDataAvailable && (
                                  <td>
                                    {this.state.quality_fuel_ratio_rejection}
                                  </td>
                                )}
                                <td>
                                  <TextField
                                    name="quality_fuel_ratio_actual"
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth
                                    value={this.state.quality_fuel_ratio_actual}
                                    onChange={this.TextHandler}
                                    inputProps={{
                                      style: { textTransform: "uppercase" },
                                    }}
                                  />
                                </td>
                              </tr>

                              <tr>
                                <td style={{ verticalAlign: "middle" }}>
                                  Total Sulphur
                                </td>
                                <td style={{ verticalAlign: "middle" }}>%</td>
                                {this.state.BTRDataAvailable && (
                                  <td>
                                    {this.state.quality_total_sulphur_basis}
                                  </td>
                                )}
                                {this.state.BTRDataAvailable && (
                                  <td>
                                    {this.state.quality_total_sulphur_typical}
                                  </td>
                                )}
                                {this.state.BTRDataAvailable && (
                                  <td>
                                    {this.state.quality_total_sulphur_rejection}
                                  </td>
                                )}
                                <td>
                                  <TextField
                                    name="quality_total_sulphur_actual"
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth
                                    value={
                                      this.state.quality_total_sulphur_actual
                                    }
                                    //onChange={this.TextHandler}
                                    onChange={(e) => {
                                      if (
                                        e.target.value === "" ||
                                        re.test(e.target.value)
                                      ) {
                                        this.setState({
                                          [e.target.name]: e.target.value,
                                        });
                                      }
                                    }}
                                    inputProps={{
                                      style: { textTransform: "uppercase" },
                                    }}
                                  />
                                </td>
                              </tr>

                              <tr>
                                <td style={{ verticalAlign: "middle" }}>HGI</td>
                                <td style={{ verticalAlign: "middle" }}></td>
                                {this.state.BTRDataAvailable && <td></td>}
                                {this.state.BTRDataAvailable && (
                                  <td>{this.state.quality_hgi_typical}</td>
                                )}
                                {this.state.BTRDataAvailable && (
                                  <td>{this.state.quality_hgi_rejection}</td>
                                )}
                                <td>
                                  <TextField
                                    name="quality_hgi_actual"
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth
                                    value={this.state.quality_hgi_actual}
                                    onChange={this.TextHandler}
                                    inputProps={{
                                      style: { textTransform: "uppercase" },
                                    }}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        {/* {(this.state.features.indexOf('327') >= 0 || this.state.features.indexOf('97') >= 0) && */}

                        <div className="card col-lg-12 p-0 border mb-3">
                          <div className="card-header section_header">
                            <h5>File Upload Details</h5>
                          </div>
                          <div className="card-body">
                            {this.state.files.map((e, index) => (
                              <div className="row mb-3" key={index}>
                                <div className="col-lg-4 p-0">
                                  <label className="form_label mb-0">
                                    File Description
                                  </label>
                                  <TextField
                                    name="file_description"
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    inputProps={{
                                      style: { textTransform: "uppercase" },
                                    }}
                                    value={e.file_description}
                                    error={e.file_description_error}
                                    onChange={(e) => {
                                      var row = this.state.files;
                                      row[index].file_description =
                                        e.target.value;
                                      row[index].file_description_error = false;
                                      this.setState({
                                        files: row,
                                      });
                                    }}
                                  />
                                </div>
                                <div className="col-lg-6 pr-0">
                                  <label className="form_label mb-2">
                                    Select file to upload
                                  </label>
                                  <input
                                    type="file"
                                    name="fileToUpload"
                                    id="fileToUpload"
                                    className="form-control border-0 pl-0"
                                    onChange={this.fileUploadHandler(index)}
                                  />
                                </div>
                                <center className="col-lg-1 d-flex justify-content-center">
                                  <i
                                    className="fa fa-trash-o m-auto"
                                    onClick={(e) => {
                                      const row = this.state.files;
                                      row.splice(index, 1);
                                      this.setState({
                                        files: row,
                                      });
                                    }}
                                    style={{
                                      fontSize: 24,
                                      color: config.themeColor,
                                      cursor: "pointer",
                                    }}
                                  />
                                </center>
                              </div>
                            ))}
                            <button
                              type="button"
                              style={{ color: config.themeColor, width: 110 }}
                              className="header_button header_button_text addrow_button_adjustment"
                              onClick={this.addMoreFileHandler}
                            >
                              Add Row
                            </button>
                          </div>
                        </div>
                        {/* } */}
                      </div>
                    )}
                </div>
              </div>
            </div>

            <footer className="row bottom_buttons_section">
              <button
                type="button"
                className="btn previous_button btn-light"
                name="submit"
                onClick={
                  this.state.activeStep === 0
                    ? () => this.props.returnCallbackDrawerClose(false)
                    : this.previousStepHandler
                }
              >
                {this.state.activeStep === 0 ? "Close" : "Previous"}
              </button>
              <button
                type="button"
                className={
                  this.state.activeStep === 0
                    ? "btn next_button"
                    : "drawer_text drawer_btn_success"
                }
                name="Back"
                onClick={
                  this.state.activeStep === 0
                    ? this.nextStepHandler
                    : this.submitHandler
                }
              >
                {this.state.activeStep === 0 ? "Next Step" : "Submit"}
              </button>
            </footer>
          </div>
        )}
      </div>
    );
  }
}
