import React, { Component } from 'react';
import Header from '../common/Header';
import SideBar from '../common/SideBar';
import CookieHandler from "../common/CookieHandler";
import api from '../../api/api';
import Loader from '../common/Loader';
import config from '../../config/config';
import { costingTypes } from './constant';
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  TextField,
  MenuItem,
  Snackbar,
  Checkbox,
  withStyles,
  Tooltip
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { toLocaleString } from '../common/common';


const CheckBox = withStyles({
  root: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);
class BudgetAndCostingUpdate extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();

    this.state = {
      isLoading: true,
      laycan: '',
      vessel_name: '',
      budget_currency: '0',
      exchange_rate: '',
      currency_exchg_rate: '',
      total_cost_of_purchase: '',
      total_cost_of_purchase_pmt: '',
      total_cost_of_sales: '',
      total_cost_of_sales_pmt: '',
      realization_pmt: '',
      coal_sales_data: [{
        buyer: "",
        sales_contract: "",
        quality_id: "",
        quality_input: "",
        sales_type_id: "",
        sales_type_input: "0",
        quantity: "",
        price_pmt: "",
        currency: "0",
        total: ""
      }],
      coal_purchase_data: [{
        supplier: "",
        purchase_contract: "",
        quality_id: "",
        quality_input: "",
        purchase_type_id: "",
        purchase_type_input: "0",
        quantity: "",
        price_pmt: "",
        currency: "0",
        total: ""
      }],
      business_numbers: [],
      csf_mapped_data: [],
      csf_manual_data: [
        {

          vendor_id: '',
          quality: "",
          price_type: "Per MT",
          quantity: "",
          price: "",
          currency: "0",
          total: ""
        }
      ],
      cpf_mapped_data: [],
      cpf_manual_data: [{

        vendor_id: '',
        quality: "",
        price_type: "Per MT",
        quantity: "",
        price: "",
        currency: "0",
        total: ""
      }
      ],
      barge_cost_data: [
        {
          vendor_id: '',
          jetty: "",
          price_type: "Per MT",
          quantity: "",
          price: "",
          currency: "0",
          total: ""
        }
      ],
      misc_expenses_data: [
        {
          costing_type: "Export Tax",
          price_type: "Per MT",
          quantity: "",
          price: "",
          currency: "0",
          total: ""
        }
      ],
      qualityTypes: [
        <MenuItem value="0" key={-1} disabled>
          Please Select
        </MenuItem>,
        <MenuItem value="FOB Barge" key={1}>
          FOB Barge
        </MenuItem>,
        <MenuItem value="FAS Anchorage" key={2}>
          FAS Anchorage
        </MenuItem>,
        <MenuItem value="FOB Mother Vessel" key={3}>
          FOB Mother Vessel
        </MenuItem>,
        <MenuItem value="CFR DP" key={4}>
          CFR DP
        </MenuItem>,
        <MenuItem value="CIF DP" key={5}>
          CIF DP
        </MenuItem>,
      ],
      cps_contracts_data: [],
      currencies: [],
      features: [],
      customers: [],
      vendors: [],
      sales_contracts: [],
      purchase_contracts: [],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    this.setState({ features: features });

    if (!(features.includes('642') || features.includes('97'))) {
      window.location.href = '/';
    }
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    await api.getCurrencies(loginUserID, idToken).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            const currencies = [
              <MenuItem value="0" key={-1} disabled>
                Please Select
              </MenuItem>,
            ];
            for (var i in res.Currency) {
              currencies.push(
                <MenuItem value={res.Currency[i].currency} key={[i]}>
                  {res.Currency[i].currency}
                </MenuItem>
              );
            }
            this.setState({
              currencies: currencies,
            });
          }
          else if (res.code === '607') {
            window.location.href = '/logout';
          }
        })
      }
    });

    await api.getCustomers(loginUserID, idToken, 'Active').then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.idtoken)
            this.Cookie.setIdTokenCookie(res.idtoken);
          if (res.code === '200') {
            if (res.customers) {
              this.setState({
                customers: res.customers
              })
            }

          } else if (res.code === '607') {
            window.location.href = '/logout';
          }
        })
      }
    });

    await api.getSalesContractList(loginUserID, idToken, '').then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === '200') {
            if (res.sale_contract) {
              this.setState({
                sales_contracts: res.sale_contract
              })
            }
          }
          else if (res.code === '607') {
            window.location.href = '/logout';
          }
        })
      }
    });

    await api.getVendors(loginUserID, idToken, 'Active').then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === '200') {
            if (res.vendors) {
              this.setState({
                vendors: res.vendors
              })
            }
          } else if (res.code === '607') {
            window.location.href = '/logout';
          }
        })
      }
    });

    await api.getBusinessNumbers(loginUserID, idToken).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === '200') {
            if (res.business_numbers) {
              this.setState({
                business_numbers: res.business_numbers
              });
            }
          } else if (res.code === '607') {
            window.location.href = '/logout';
          }
        })
      }
    })

    await api.getPurchaseContracts(loginUserID, "", idToken).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === '200') {
            if (res.purchase_contract) {
              this.setState({
                purchase_contracts: res.purchase_contract?.filter(e => e.status !== "Closed" && e.status !== "cancelled"),
              })
            }

          } else if (res.code === '607') {
            window.location.href = '/logout';
          }
        })
      }
    })
    const request = {
      login_user_id: this.Cookie.getCookie('loginUserId'),
      idtoken: this.Cookie.getIdTokenCookie(),
      budget_costing_id: decodeURIComponent(
        window.atob(this.props.match.params.budgetID)
      ),
    }
    await api.get_budget_costing(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === '200') {
            if (res.budget_costing) {
              this.setState((prev) => ({
                ...prev,
                ...res.budget_costing,
                // isLoading: false,
              }), () => {
                const pc_contracts = [];
                for (var i of this.state.coal_purchase_data) {
                  pc_contracts.push({ purchase_contract_id: i.purchase_contract, pc_qualities_id: i.quality_id, pc_purchase_type_id: i.purchase_type_id })
                }
                const request = {
                  login_user_id: this.Cookie.getCookie('loginUserId'),
                  idtoken: this.Cookie.getIdTokenCookie(),
                  purchase_contracts: pc_contracts,
                }
                api.get_cps_contracts_for_budget_costing(request).then((response) => {
                  if (response.status >= 200 && response.status < 300) {
                    response.json().then((res) => {
                      if (res.code === '200') {
                        const csf_data = [];
                        const cpf_data = [];
                        for (var i of res.cps_contracts_data) {
                          for (var j of this.state.csf_mapped_data) {
                            if (i.cps_contract_mappping_id === j.cps_contract_mappping_id) {
                              j.checked = true;
                              csf_data.push(j);
                            }
                          }
                          for (var k of this.state.cpf_mapped_data) {
                            if (i.cps_contract_mappping_id === k.cps_contract_mappping_id) {
                              k.checked = true;
                              cpf_data.push(k);
                            }
                          }
                          if (i.contract_type === "Coal Service Fees") {
                            csf_data.push({
                              cps_contract_mappping_id: i.cps_contract_mappping_id,
                              checked: false,
                              vendor_name: i.vendor_name,
                              contract_no: i.contract_no,
                              quality: i.quality,
                              purchase_type: i.purchase_type,
                              price_type: i.price_type,
                              quantity: i.quantity,
                              price: i.price_type === "Lumpsum" ? i.price_lumpsum : i.price_pmt,
                              currency: i.currency,
                              total: 0,
                            });
                          }
                          else {
                            cpf_data.push({
                              cps_contract_mappping_id: i.cps_contract_mappping_id,
                              checked: false,
                              vendor_name: i.vendor_name,
                              contract_no: i.contract_no,
                              quality: i.quality,
                              purchase_type: i.purchase_type,
                              price_type: i.price_type,
                              quantity: i.quantity,
                              price: i.price_type === "Lumpsum" ? i.price_lumpsum : i.price_pmt,
                              currency: i.currency,
                              total: 0,
                            });

                          }
                        }
                        this.setState({
                          cps_contracts_data: res.cps_contracts_data,
                          csf_mapped_data: csf_data.filter((el, i, a) => a.findIndex((element) => element.cps_contract_mappping_id === el.cps_contract_mappping_id) === i),
                          cpf_mapped_data: cpf_data.filter((el, i, a) => a.findIndex((element) => element.cps_contract_mappping_id === el.cps_contract_mappping_id) === i),
                        }, () => this.ExchangeRateHandler())

                      } else if (res.code === '601') {
                        this.setState({
                          snackBarErrorOpen: true,
                          errorMsg: res.message
                        });
                      } else if (res.code === '607') {
                        window.location.href = '/logout';
                      }
                    })
                  }
                })
              })
            }

          } else if (res.code === '601') {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message
            })

          } else if (res.code === '607') {
            window.location.href = '/logout';
          }
        })
      }
    })
  }


  CoalSalesTextHandler = (e, idx, type) => {
    let rows = this.state.coal_sales_data;
    if (type === "text") {
      rows[idx][e.target.name] = e.target.value;
      rows[idx].currency_error = false;
    }
    else {
      const pr = /^\d*(\.\d{0,4})?$/;
      if (
        e.target.value === "" ||
        pr.test(e.target.value)
      ) {
        rows[idx][e.target.name] = e.target.value;
        rows[idx].quantity_error = false;
        rows[idx].price_pmt_error = false;
      }
    }
    this.setState({
      coal_sales_data: rows,
    }, () => this.ExchangeRateHandler())

  }

  CoalPurchaseTextHandler = (e, idx, type) => {
    let rows = this.state.coal_purchase_data;
    if (type === "text") {
      rows[idx][e.target.name] = e.target.value;
      rows[idx].currency_error = false;
    }
    else {
      const pr = /^\d*(\.\d{0,4})?$/;
      if (e.target.value === "" || pr.test(e.target.value)) {
        rows[idx][e.target.name] = e.target.value;
        rows[idx].quantity_error = false;
        rows[idx].price_pmt_error = false;
      }
    }
    this.setState({
      coal_purchase_data: rows
    }, () => {
      this.ExchangeRateHandler();
      const pc_contracts = [];
      for (var i of this.state.coal_purchase_data) {
        pc_contracts.push({ purchase_contract_id: i.purchase_contract, pc_qualities_id: i.quality_id, pc_purchase_type_id: i.purchase_type_id })
      }
      const request = {
        login_user_id: this.Cookie.getCookie('loginUserId'),
        idtoken: this.Cookie.getIdTokenCookie(),
        purchase_contracts: pc_contracts,
      }
      api.get_cps_contracts_for_budget_costing(request).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === '200') {


              const csf_data = [...this.state.csf_mapped_data];
              const cpf_data = [...this.state.cpf_mapped_data];
              for (var i of res.cps_contracts_data) {
                if (i.contract_type === "Coal Service Fees") {
                  csf_data.push({
                    cps_contract_mappping_id: i.cps_contract_mappping_id,
                    checked: false,
                    vendor_name: i.vendor_name,
                    contract_no: i.contract_no,
                    quality: i.quality,
                    purchase_type: i.purchase_type,
                    price_type: i.price_type,
                    quantity: i.quantity,
                    price: i.price_type === "Lumpsum" ? i.price_lumpsum : i.price_pmt,
                    currency: i.currency,
                    total: 0,
                  });
                }
                else {
                  cpf_data.push({
                    cps_contract_mappping_id: i.cps_contract_mappping_id,
                    checked: false,
                    vendor_name: i.vendor_name,
                    contract_no: i.contract_no,
                    quality: i.quality,
                    purchase_type: i.purchase_type,
                    price_type: i.price_type,
                    quantity: i.quantity,
                    price: i.price_type === "Lumpsum" ? i.price_lumpsum : i.price_pmt,
                    currency: i.currency,
                    total: 0,
                  });

                }
              }
              this.setState({
                cps_contracts_data: res.cps_contracts_data,
                csf_mapped_data: csf_data.filter((el, i, a) => a.findIndex((element) => element.cps_contract_mappping_id === el.cps_contract_mappping_id) === i),
                cpf_mapped_data: cpf_data.filter((el, i, a) => a.findIndex((element) => element.cps_contract_mappping_id === el.cps_contract_mappping_id) === i),
              }, () => this.ExchangeRateHandler())

            } else if (res.code === '601') {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message
              });
            } else if (res.code === '607') {
              window.location.href = '/logout';
            }
          })
        }
      });
    })
  }

  TotalPurchaseAndSales = () => {
    const total_purchase = (
      this.state.coal_purchase_data?.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.total ? curr.total : 0), 0)
      + this.state.csf_mapped_data?.filter(e => e.checked === true)?.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.total ? curr.total : 0), 0)
      + this.state.csf_manual_data?.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.total ? curr.total : 0), 0)
      + this.state.cpf_manual_data?.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.total ? curr.total : 0), 0)
      + this.state.cpf_mapped_data?.filter(e => e.checked === true)?.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.total ? curr.total : 0), 0)
      + this.state.barge_cost_data?.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.total ? curr.total : 0), 0)
      + this.state.misc_expenses_data?.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.total ? curr.total : 0), 0)
    );
    const total_purchase_qty = this.state.coal_purchase_data?.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.quantity ? curr.quantity : 0), 0);
    const total_purchase_pmt = total_purchase / total_purchase_qty;

    const total_sales = this.state.coal_sales_data?.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.total ? curr.total : 0), 0);
    const total_sales_qty = this.state.coal_sales_data?.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.quantity ? curr.quantity : 0), 0);
    const total_sales_pmt = total_sales / total_sales_qty;
    const realization_pmt = parseFloat(total_sales_pmt ? total_sales_pmt : 0) - parseFloat(total_purchase_pmt ? total_purchase_pmt : 0);

    this.setState({
      total_cost_of_purchase: total_purchase,
      total_cost_of_purchase_pmt: total_purchase_pmt,
      total_cost_of_sales: total_sales,
      total_cost_of_sales_pmt: total_sales_pmt,
      realization_pmt: realization_pmt,
      isLoading: false
    })

  }

  BargeCostTextHandler = (e, idx, type) => {
    let rows = this.state.barge_cost_data;
    if (type === "text") {
      rows[idx][e.target.name] = e.target.value;
      rows[idx].currency_error = false;
    }
    else {
      const pr = /^\d*(\.\d{0,4})?$/;
      if (
        e.target.value === "" ||
        pr.test(e.target.value)
      ) {
        rows[idx][e.target.name] = e.target.value;
        rows[idx].quantity_error = false;
        rows[idx].price_pmt_error = false;
      }
    }

    this.setState({
      barge_cost_data: rows
    }, () => this.ExchangeRateHandler());

  }

  MiscExpensesTextHandler = (e, idx, type) => {
    let rows = this.state.misc_expenses_data;
    if (type === "text") {
      rows[idx][e.target.name] = e.target.value;
      rows[idx].currency_error = false;
    }
    else {
      const pr = /^\d*(\.\d{0,4})?$/;
      if (
        e.target.value === "" ||
        pr.test(e.target.value)
      ) {
        rows[idx][e.target.name] = e.target.value;
        rows[idx].quantity_error = false;
        rows[idx].price_pmt_error = false;
      }
    }
    this.setState({
      misc_expenses_data: rows
    }, () => this.ExchangeRateHandler())

  }

  ServiceFeeTextHandler = (e, idx, type) => {
    let rows = this.state.csf_manual_data;
    if (type === "text") {
      rows[idx][e.target.name] = e.target.value;
      rows[idx].currency_error = false;
    }
    else {
      const pr = /^\d*(\.\d{0,4})?$/;
      if (
        e.target.value === "" ||
        pr.test(e.target.value)
      ) {
        rows[idx][e.target.name] = e.target.value;
        rows[idx].quantity_error = false;
        rows[idx].price_pmt_error = false;
      }
    }

    this.setState({
      csf_manual_data: rows
    }, () => this.ExchangeRateHandler())
  }

  ProcurementFeeTextHandler = (e, idx, type) => {
    let rows = this.state.cpf_manual_data;
    if (type === "text") {
      rows[idx][e.target.name] = e.target.value;
      rows[idx].currency_error = false;
    }
    else {
      const pr = /^\d*(\.\d{0,4})?$/;
      if (
        e.target.value === "" ||
        pr.test(e.target.value)
      ) {
        rows[idx][e.target.name] = e.target.value;
        rows[idx].quantity_error = false;
        rows[idx].price_pmt_error = false;
      }
    }

    this.setState({
      cpf_manual_data: rows
    }, () => this.ExchangeRateHandler())
  }


  SalesContractHandler = (id, idx) => {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    api.getSalesContract(loginUserID, id, idToken, "Active").then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === '200') {
            if (res.sales_contract) {
              let rows = this.state.coal_sales_data;
              rows[idx]['currency'] = res.sales_contract.currency;
              rows[idx]['quality_id'] = '0';
              rows[idx]['qualities'] = res.sales_contract.qualities;
              this.setState({
                coal_sales_data: rows
              });

            }
          } else if (res.code === '601') {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message
            })

          } else if (res.code === '607') {
            window.location.href = '/logout';
          }
        })
      }
    })
  }

  PurchaseContractHandler = (id, idx) => {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    api.getPurchaseContract(loginUserID, id, idToken, '').then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === '200') {
            if (res.purchase_contract) {

              let rows = this.state.coal_purchase_data;
              rows[idx]['currency'] = res.purchase_contract.currency;
              rows[idx]['quality_id'] = '0';
              rows[idx]['qualities'] = res.purchase_contract.qualities;

              this.setState({
                coal_purchase_data: rows
              });

            }
          } else if (res.code === '601') {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message
            });
          } else if (res.code === '607') {
            window.location.href = '/logout';
          }
        })
      }
    })
  }



  ExchangeRateValue = (currency) => {
    let exchg_rate = this.state.exchange_rate ? this.state.exchange_rate : 1;

    if (!currency) {
      exchg_rate = 1;
    }
    if (this.state.budget_currency === "USD" && currency === "IDR") {
      exchg_rate = (1 / parseFloat(exchg_rate));
    } else if (this.state.budget_currency === currency) {
      exchg_rate = 1;
    }

    return exchg_rate;

  }


  ExchangeRateHandler = () => {
    const { coal_purchase_data, coal_sales_data, csf_mapped_data, csf_manual_data, cpf_manual_data, cpf_mapped_data, misc_expenses_data,
      barge_cost_data } = this.state;

    for (var i of coal_purchase_data) {
      const exchg_rate = this.ExchangeRateValue(i.currency);
      i['total'] = ((parseFloat(i.quantity ? i.quantity : 0) * parseFloat(i.price_pmt ? i.price_pmt : 0)) * (parseFloat(exchg_rate ? exchg_rate : 1))).toFixed(2);
    }
    for (var j of coal_sales_data) {
      const exchg_rate = this.ExchangeRateValue(j.currency);
      j['total'] = ((parseFloat(j.quantity ? j.quantity : 0) * parseFloat(j.price_pmt ? j.price_pmt : 0)) * (parseFloat(exchg_rate ? exchg_rate : 1))).toFixed(2);
    }
    for (var obj of csf_mapped_data) {
      if (obj.checked) {
        const exchg_rate = this.ExchangeRateValue(obj.currency);
        if (obj.price_type === "Per MT") {
          obj['total'] = ((parseFloat(obj.quantity ? obj.quantity : 0) * parseFloat(obj.price ? obj.price : 0)) * (parseFloat(exchg_rate ? exchg_rate : 1))).toFixed(2);
        } else {
          obj['total'] = (parseFloat(obj.price ? obj.price : 0) * parseFloat(exchg_rate ? exchg_rate : 1)).toFixed(2);
        }
      }
    }

    for (var el of cpf_mapped_data) {
      if (el.checked) {

        const exchg_rate = this.ExchangeRateValue(el.currency);
        if (el.price_type === "Per MT") {
          el['total'] = ((parseFloat(el.quantity ? el.quantity : 0) * parseFloat(el.price ? el.price : 0)) * (parseFloat(exchg_rate ? exchg_rate : 1))).toFixed(2);
        } else {
          el['total'] = (parseFloat(el.price ? el.price : 0) * parseFloat(exchg_rate ? exchg_rate : 1)).toFixed(2);
        }
      }
    }

    for (var k of csf_manual_data) {
      const exchg_rate = this.ExchangeRateValue(k.currency);

      if (k.price_type === "Per MT") {
        k['total'] = ((parseFloat(k.quantity ? k.quantity : 0) * parseFloat(k.price ? k.price : 0)) * (parseFloat(exchg_rate ? exchg_rate : 1))).toFixed(2);
      } else {
        k['total'] = (parseFloat(k.price ? k.price : 0) * parseFloat(exchg_rate ? exchg_rate : 1)).toFixed(2);
      }
    }
    for (var l of cpf_manual_data) {
      const exchg_rate = this.ExchangeRateValue(l.currency);

      if (l.price_type === "Per MT") {
        l['total'] = ((parseFloat(l.quantity ? l.quantity : 0) * parseFloat(l.price ? l.price : 0)) * (parseFloat(exchg_rate ? exchg_rate : 1))).toFixed(2);
      } else {
        l['total'] = (parseFloat(l.price ? l.price : 0) * parseFloat(exchg_rate ? exchg_rate : 1)).toFixed(2);
      }
    }
    for (var m of barge_cost_data) {
      const exchg_rate = this.ExchangeRateValue(m.currency);

      if (m.price_type === "Per MT") {
        m['total'] = ((parseFloat(m.quantity ? m.quantity : 0) * parseFloat(m.price ? m.price : 0)) * (parseFloat(exchg_rate ? exchg_rate : 1))).toFixed(2);
      } else {
        m['total'] = (parseFloat(m.price ? m.price : 0) * parseFloat(exchg_rate ? exchg_rate : 1)).toFixed(2);
      }
    }
    for (var n of misc_expenses_data) {
      const exchg_rate = this.ExchangeRateValue(n.currency);

      if (n.price_type === "Per MT") {
        n['total'] = ((parseFloat(n.quantity ? n.quantity : 0) * parseFloat(n.price ? n.price : 0)) * (parseFloat(exchg_rate ? exchg_rate : 1))).toFixed(2);
      } else {
        n['total'] = (parseFloat(n.price ? n.price : 0) * parseFloat(exchg_rate ? exchg_rate : 1)).toFixed(2);
      }
    }

    this.setState({
      coal_purchase_data: coal_purchase_data,
      coal_sales_data: coal_sales_data,
      csf_manual_data: csf_manual_data,
      csf_mapped_data: csf_mapped_data,
      cpf_manual_data: cpf_manual_data,
      cpf_mapped_data: cpf_mapped_data,
      barge_cost_data: barge_cost_data,
      misc_expenses_data: misc_expenses_data,

    }, () => this.TotalPurchaseAndSales())

  }

  OnSubmitHandler = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });

    const { laycan, business_no_id, vessel_name, budget_currency, exchange_rate, total_cost_of_purchase, total_cost_of_purchase_pmt, total_cost_of_sales,
      total_cost_of_sales_pmt, realization_pmt, coal_purchase_data, coal_sales_data, csf_mapped_data, csf_manual_data, cpf_manual_data, cpf_mapped_data, misc_expenses_data,
      barge_cost_data } = this.state;
    let mandatory_filelds = 0;

    if (laycan === '') {
      mandatory_filelds++;
      this.setState({ laycan_error: true });
    }
    if (vessel_name === '' || !vessel_name) {
      mandatory_filelds++;
      this.setState({
        vessel_name_error: true
      });
    }
    if (budget_currency === "0" || !budget_currency) {
      mandatory_filelds++;
      this.setState({
        budget_currency_error: true,
      });
    }

    let sales = coal_sales_data;
    for (var sale of sales) {
      if (sale.buyer === '' || !sale.buyer) {
        sale.buyer_error = true;
        mandatory_filelds++;

      }
      if (sale.quantity === '' || !sale.quantity) {
        sale.quantity_error = true;
        mandatory_filelds++;

      }
      if (sale.price_pmt === '' || !sale.price_pmt) {
        sale.price_pmt_error = true;
        mandatory_filelds++;

      }
      if (sale.currency === '0' || !sale.currency) {
        sale.currency_error = true;
        mandatory_filelds++;

      }
    }

    this.setState({ coal_sales_data: sales });

    let purchase = coal_purchase_data;
    for (var pur of purchase) {
      if (pur.supplier === '' || !pur.supplier) {
        pur.buyer_error = true;
        mandatory_filelds++;

      }
      if (pur.quantity === '' || !pur.quantity) {
        pur.quantity_error = true;
        mandatory_filelds++;

      }
      if (pur.price_pmt === '' || !pur.price_pmt) {
        pur.price_pmt_error = true;
        mandatory_filelds++;

      }
      if (pur.currency === '0' || !pur.currency) {
        pur.currency_error = true;
        mandatory_filelds++;

      }
    }

    this.setState({ coal_purchase_data: purchase });

    // let ServiceFee = csf_manual_data;
    // for (var csf of ServiceFee) {
    //   if (csf.vendor_id === '' || !csf.vendor_id) {
    //     csf.buyer_error = true;
    //     mandatory_filelds++;
    //   }
    //   if (csf.price_type !== "Lumpsum") {
    //     if (csf.quantity === '' || !csf.quantity) {
    //       csf.quantity_error = true;
    //       mandatory_filelds++;
    //     }
    //   }
    //   if (csf.price === '' || !csf.price) {
    //     csf.price_pmt_error = true;
    //     mandatory_filelds++;
    //   }
    //   if (csf.currency === '0' || !csf.currency) {
    //     csf.currency_error = true;
    //     mandatory_filelds++;

    //   }
    // }

    // this.setState({ csf_manual_data: ServiceFee });

    // let ProcurementFee = cpf_manual_data;
    // for (var cpf of ProcurementFee) {
    //   if (cpf.vendor_id === '' || !cpf.vendor_id) {
    //     cpf.buyer_error = true;
    //     mandatory_filelds++;
    //   }
    //   if (cpf.price_type !== "Lumpsum") {

    //     if (cpf.quantity === '' || !cpf.quantity) {
    //       csf.quantity_error = true;
    //       mandatory_filelds++;
    //     }
    //   }
    //   if (cpf.price === '' || !cpf.price) {
    //     cpf.price_pmt_error = true;
    //     mandatory_filelds++;
    //   }
    //   if (cpf.currency === '0' || !cpf.currency) {
    //     cpf.currency_error = true;
    //     mandatory_filelds++;

    //   }
    // }

    // this.setState({ cpf_manual_data: ProcurementFee });

    // let BargeCost = barge_cost_data;

    // for (var bgc of BargeCost) {
    //   if (bgc.vendor_id === '' || !bgc.vendor_id) {
    //     bgc.buyer_error = true;
    //     mandatory_filelds++;
    //   }

    //   if (bgc.price_type !== "Lumpsum") {

    //     if (bgc.quantity === '' || !bgc.quantity) {
    //       csf.quantity_error = true;
    //       mandatory_filelds++;
    //     }
    //   }
    //   if (bgc.price === '' || !bgc.price) {
    //     bgc.price_pmt_error = true;
    //     mandatory_filelds++;
    //   }
    //   if (bgc.currency === '0' || !bgc.currency) {
    //     bgc.currency_error = true;
    //     mandatory_filelds++;

    //   }
    // }
    // this.setState({ barge_cost_data: BargeCost });

    // let miscExpense = misc_expenses_data;

    // for (var mse of miscExpense) {
    //   if (mse.costing_type === '' || !mse.costing_type) {
    //     mse.buyer_error = true;
    //     mandatory_filelds++;
    //   }
    //   if (mse.price_type !== "Lumpsum") {

    //     if (mse.quantity === '' || !mse.quantity) {
    //       mse.quantity_error = true;
    //       mandatory_filelds++;
    //     }
    //   }
    //   if (mse.price === '' || !mse.price) {
    //     mse.price_pmt_error = true;
    //     mandatory_filelds++;
    //   }
    //   if (mse.currency === '0' || !mse.currency) {
    //     mse.currency_error = true;
    //     mandatory_filelds++;

    //   }
    // }

    // this.setState({ misc_expenses_data: miscExpense });


    if (mandatory_filelds > 0) {
      this.setState({ isLoading: false });
      return;
    }


    const budget_costing_id = decodeURIComponent(
      window.atob(this.props.match.params.budgetID)
    );

    const request = {
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
      budget_costing_data: {
        budget_costing_id: budget_costing_id,
        laycan: laycan,
        business_no_id: business_no_id,
        vessel_name: vessel_name,
        budget_currency: budget_currency,
        exchange_rate: exchange_rate,
        total_cost_of_purchase: total_cost_of_purchase,
        total_cost_of_purchase_pmt: total_cost_of_purchase_pmt,
        total_cost_of_sales: total_cost_of_sales,
        total_cost_of_sales_pmt: total_cost_of_sales_pmt,
        realization_pmt: realization_pmt,
        coal_sales_data: coal_sales_data,
        coal_purchase_data: coal_purchase_data,
        csf_mapped_data: csf_mapped_data?.filter(e => e.checked === true || e.status === "Deleted"),
        csf_manual_data: csf_manual_data,
        cpf_mapped_data: cpf_mapped_data?.filter(e => e.checked === true || e.status === "Deleted"),
        cpf_manual_data: cpf_manual_data,
        barge_cost_data: barge_cost_data,
        misc_expenses_data: misc_expenses_data,
      }
    }

    api.update_budget_costing(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === '200') {
            this.setState({
              snackBarSuccessOpen: true,
              successMsg: res.message,
              isLoading: false,
            }, () => window.location.href = '/budget-costing')

          } else if (res.code === '601') {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message
            });
          } else if (res.code === '607') {
            window.location.href = '/logout';
          }
        })
      }
    })
  }
  render() {
    return (
      <div id="wraper">
        <SideBar />
        <div className="content">
          <Header />
          <div className="content-header">
            <div className="col-sm text-left pl-0">
              <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                {`Budget And Costing - ${this.state.budget_costing_no}`}
              </h4>
            </div>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          {this.state.isLoading && <Loader />}

          {!this.state.isLoading &&

            <form className="container p-3">
              <div className="card" style={{ overflowY: "scroll" }}>
                <div className="section_block w-100" >
                  <div style={{ width: "70%" }}>
                    <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                      Add details to create Budget & Costing
                    </h5>
                  </div>
                  <div className="row mb-2">
                    <div className="col-lg-3">
                      <label className="form_label mb-0">
                        Laycan <span style={{ color: "red" }}>*</span>
                      </label>
                      <TextField
                        name="laycan"
                        margin="dense"
                        variant="standard"
                        placeholder="Laycan *"
                        value={this.state.laycan}
                        onChange={(e) => this.setState({ laycan: e.target.value, laycan_error: false })}
                        fullWidth
                        error={this.state.laycan_error}
                        inputProps={{ style: { textTransform: "uppercase" } }}
                      />

                    </div>
                    <div className="col-lg-3">
                      <label className="form_label mb-0">
                        Business No <span style={{ color: "red" }}>*</span>
                      </label>
                      <Autocomplete
                        options={this.state.business_numbers}
                        getOptionLabel={(option) => option.mother_vessel_name}
                        renderOption={(option, { selected }) => (
                          <React.Fragment>
                            <div className="col-lg p-0 m-0">
                              <label className="form_label mb-0">
                                {option.mother_vessel_name}
                              </label>
                              <label
                                className="contract_display_header_value m-0 pl-0"
                                style={{ fontSize: 10, paddingLeft: 5 }}
                              >
                                {"( " + option.business_no + " )"}
                              </label>
                            </div>
                          </React.Fragment>
                        )}
                        onChange={(event, value) => {
                          this.setState({
                            business_no_id: value.id,
                            vessel_name: value.mother_vessel_name,
                            vessel_name_error: false,
                          });
                        }}
                        name="vessel_name"
                        size="small"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Search Business"
                            variant="standard"
                            error={this.state.vessel_name_error}

                            // style={{ top: 8 }}
                            fullWidth
                          />
                        )}
                        style={{ display: "contents" }}
                      />
                    </div>
                    <div className="col-lg-3">
                      <label className="form_label mb-0">
                        Vessel Name <span style={{ color: "red" }}>*</span>
                      </label>
                      <TextField
                        name="vessel_name"
                        margin="dense"
                        variant="standard"
                        placeholder="Vessel Name *"
                        value={this.state.vessel_name}
                        onChange={(e) => this.setState({ vessel_name: e.target.value, vessel_name_error: false })}
                        error={this.state.vessel_name_error}
                        fullWidth
                        inputProps={{ style: { textTransform: "uppercase" } }}
                      />
                    </div>
                    <div className="col-lg-3">
                      <label className="form_label mb-0">
                        Currency <span style={{ color: "red" }}>*</span>
                      </label>
                      <TextField
                        name="budget_currency"
                        variant="standard"
                        margin="dense"
                        select
                        fullWidth
                        value={this.state.budget_currency}
                        error={this.state.budget_currency_error}
                        onChange={(e) => {
                          this.setState({
                            budget_currency: e.target.value,
                            budget_currency_error: false,
                          }, () => this.ExchangeRateHandler())
                        }}
                        inputProps={{
                          style: { textTransform: "uppercase" },
                        }}
                      >
                        {this.state.currencies}
                      </TextField>

                    </div>

                    <div className="col-lg-3">
                      <label className="form_label mb-0">
                        Exchange Rate <span style={{ color: "red" }}>*</span>
                      </label>
                      <TextField
                        name="exchange_rate"
                        margin="dense"
                        variant="standard"
                        placeholder="Exchange Rate*"
                        //required
                        type="number"
                        value={this.state.exchange_rate}
                        onChange={(e) => {
                          const pr = /^\d*(\.\d{0,4})?$/;
                          if (
                            e.target.value === "" ||
                            pr.test(e.target.value)
                          ) {
                            this.setState({
                              [e.target.name]: e.target.value,
                            }, () => this.ExchangeRateHandler());
                          }
                        }}
                        fullWidth
                      />
                    </div>
                  </div>


                  <div className="table-responsive">
                    <table className='table table-sm table-bordered'>
                      <thead className='table-header'>
                        <tr>
                          <th className='text-center' style={{ minWidth: 300 }} nowrap="true">Supplier / Buyer <span style={{ color: "red" }}>*</span></th>
                          <th className='text-center' style={{ minWidth: 300 }} nowrap="true">Contract</th>
                          <th className='text-center' style={{ minWidth: 150 }} nowrap="true">Quality <span style={{ color: "red" }}>*</span></th>
                          <th className='text-center' style={{ minWidth: 200 }} nowrap="true">Sales / Purchase Type</th>
                          <th className='text-center' style={{ minWidth: 150 }} nowrap="true">Price Type</th>
                          <th style={{ minWidth: 150 }} className='text-right' nowrap="true">Quantity <span style={{ color: "red" }}>*</span></th>
                          <th style={{ minWidth: 150 }} className='text-right' nowrap="true">Price <span style={{ color: "red" }}>*</span></th>
                          <th className='text-center' style={{ minWidth: 150 }} nowrap="true">Currency <span style={{ color: "red" }}>*</span></th>
                          <th style={{ minWidth: 150 }} className='text-right' nowrap="true">Total </th>
                          <th style={{ minWidth: 100 }} nowrap="true" className='text-center'>Action</th>
                        </tr>

                      </thead>
                      <tbody>
                        <tr>
                          <th colSpan={10}>Coal Sales</th>
                        </tr>
                        {this.state.coal_sales_data?.map((e, idx) => (
                          <tr key={e.buyer}>
                            <td nowrap="true">
                              <Autocomplete
                                options={this.state.customers}
                                getOptionLabel={(option) => option.customer_name}
                                onChange={(event, value) => {
                                  if (value) {

                                    let rows = this.state.coal_sales_data;
                                    if (value) {
                                      rows[idx].buyer = value.id;
                                      rows[idx].customer_name = value.customer_name;
                                      rows[idx].buyer_error = false;
                                    }
                                    else {
                                      rows[idx].buyer = null;
                                      rows[idx].customer_name = null;
                                      rows[idx].sales_contract = null;
                                      rows[idx].contract_no = null;
                                      rows[idx].quality_id = null;
                                    }

                                    this.setState({
                                      coal_sales_data: rows
                                    })
                                  }
                                }}
                                name="buyer"
                                size="small"
                                value={e}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    error={e.buyer_error}
                                    placeholder="Search Customer"
                                    variant="standard"
                                    // style={{ top: 8 }}
                                    fullWidth
                                  />
                                )}
                                style={{ display: "contents" }}
                              />
                            </td>
                            <td nowrap="true">
                              <Autocomplete
                                options={e.buyer ? this.state.sales_contracts?.filter(obj => obj.customer === e.buyer) : this.state.sales_contracts}
                                getOptionLabel={(option) => option.contract_no}
                                onChange={(event, value) => {
                                  let rows = this.state.coal_sales_data;

                                  if (value) {

                                    rows[idx].sales_contract = value.id;
                                    rows[idx].contract_no = value.contract_no;
                                    this.setState({
                                      coal_sales_data: rows
                                    }, () => this.SalesContractHandler(value.id, idx));
                                  }
                                  else {
                                    rows[idx].sales_contract = null;
                                    rows[idx].quality_id = null;
                                    rows[idx].contract_no = null;
                                    this.setState({ coal_sales_data: rows })

                                  }

                                }}
                                value={e}
                                name="sales_contract"
                                size="small"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="Search Contract"
                                    variant="standard"
                                    // style={{ top: 8 }}
                                    fullWidth
                                  />
                                )}
                                style={{ display: "contents" }}
                              />
                            </td>
                            <td nowrap="true">
                              {e.sales_contract ?

                                <TextField
                                  name="quality_id"
                                  variant="standard"
                                  margin="dense"
                                  select
                                  fullWidth
                                  value={e.quality_id}
                                  onChange={(event) => this.CoalSalesTextHandler(event, idx, "text")}
                                  inputProps={{
                                    style: { textTransform: "uppercase" },
                                  }}
                                >
                                  <MenuItem value={'0'} disabled>Please Select</MenuItem>

                                  {e.qualities?.map((obj) => (
                                    <MenuItem value={obj.id}>{obj.quality}</MenuItem>
                                  ))}
                                </TextField>
                                :
                                <TextField
                                  name="quality_input"
                                  variant="standard"
                                  margin="dense"
                                  fullWidth
                                  placeholder='Enter Value'
                                  value={e.quality_input}
                                  onChange={(event) => this.CoalSalesTextHandler(event, idx, "text")}
                                  inputProps={{
                                    style: { textTransform: "uppercase" },
                                  }}
                                />
                              }
                            </td>
                            <td nowrap="true">
                              {(e.quality_id && e.quality_id !== "0") ?
                                <TextField
                                  name="sales_type_id"
                                  variant="standard"
                                  margin="dense"
                                  select
                                  fullWidth
                                  value={e.sales_type_id}
                                  onChange={(event) => {
                                    let rows = this.state.coal_sales_data;
                                    rows[idx].sales_type_id = event.target.value;
                                    let price_pmt = e.qualities?.filter(el => el.id === e.quality_id)[0]?.sales_types?.find(el => el.id === event.target.value)?.price_pmt;
                                    rows[idx].price_pmt = price_pmt;
                                    this.setState({
                                      coal_sales_data: rows
                                    }, () => this.CoalSalesTextHandler(event, idx, "text"));

                                  }}
                                  inputProps={{
                                    style: { textTransform: "uppercase" },
                                  }}
                                >

                                  {e.qualities?.filter(el => el.id === e.quality_id)[0]?.sales_types?.map((obj) => (
                                    <MenuItem value={obj.id}>{obj.sales_type}</MenuItem>
                                  ))}
                                </TextField>
                                :
                                <TextField
                                  name="sales_type_input"
                                  variant="standard"
                                  margin="dense"
                                  select
                                  fullWidth
                                  value={e.sales_type_input}
                                  onChange={(event) => this.CoalSalesTextHandler(event, idx, "text")}
                                  inputProps={{
                                    style: { textTransform: "uppercase" },
                                  }}
                                >
                                  {this.state.qualityTypes}
                                </TextField>
                              }
                            </td>
                            <td nowrap="true"> Per MT</td>
                            <td nowrap="true">
                              <TextField
                                name="quantity"
                                margin="dense"
                                variant="standard"
                                placeholder='Enter Value'
                                //required
                                error={e.quantity_error}
                                type="number"
                                value={e.quantity}
                                onChange={(event) => this.CoalSalesTextHandler(event, idx, "number")}

                                fullWidth
                              />
                            </td>
                            <td nowrap="true">
                              <TextField
                                name="price_pmt"
                                margin="dense"
                                variant="standard"
                                //required
                                placeholder='Enter Value'
                                error={e.price_pmt_error}
                                type="number"
                                value={e.price_pmt}
                                onChange={(event) => this.CoalSalesTextHandler(event, idx, "number")}
                                fullWidth
                              />
                            </td>
                            <td nowrap="true">
                              <TextField
                                name="currency"
                                variant="standard"
                                margin="dense"
                                select
                                fullWidth
                                value={e.currency}
                                error={e.currency_error}
                                onChange={(event) => this.CoalSalesTextHandler(event, idx, "text")}
                                inputProps={{
                                  style: { textTransform: "uppercase" },
                                }}
                              >
                                {this.state.currencies}
                              </TextField>
                            </td>
                            <td nowrap="true" className='text-right'>{toLocaleString(e.total)}</td>
                            <td nowrap="true" className='text-center'>
                              {idx === this.state.coal_sales_data?.length - 1 &&

                                <Tooltip title="ADD" arrow>
                                  <i
                                    className="fa fa-plus-circle mr-3"
                                    style={{
                                      fontSize: 20,
                                      color: config.themeColor,
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      const item = {
                                        buyer: "",
                                        sales_contract: "",
                                        quality_id: "",
                                        quality_input: "",
                                        sales_type_id: "",
                                        sales_type_input: "0",
                                        quantity: "",
                                        price_pmt: "",
                                        currency: "0",
                                        total: ""

                                      }
                                      this.setState({
                                        coal_sales_data: [...this.state.coal_sales_data, item]
                                      })
                                    }}
                                  ></i>
                                </Tooltip>
                              }
                              {this.state.coal_sales_data?.length > 1 &&

                                <Tooltip title="REMOVE" arrow>
                                  <i
                                    className="fa fa-trash-o"
                                    style={{
                                      fontSize: 20,
                                      color: config.themeColor,
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      const rows = this.state.coal_sales_data;
                                      if (rows[idx].id) {
                                        rows[idx].status = "Deleted";
                                      }
                                      else {
                                        rows.splice(idx, 1);
                                      }
                                      this.setState({
                                        coal_sales_data: rows
                                      }, () => this.TotalPurchaseAndSales())
                                    }
                                    }
                                  ></i>
                                </Tooltip>
                              }
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td colSpan={5}></td>
                          <td className='text-right' style={{ fontWeight: 'bold' }}>{this.state.coal_sales_data?.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.quantity ? curr.quantity : 0), 0)}</td>
                          <td colSpan={2}></td>
                          <td className='text-right' style={{ fontWeight: 'bold' }} >{this.state.coal_sales_data?.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.total ? curr.total : 0), 0)}</td>
                          <td></td>
                        </tr>
                        <tr>
                          <th colSpan={10}>Coal Purchase</th>
                        </tr>
                        {this.state.coal_purchase_data?.map((e, idx) => (
                          <tr key={e.buyer}>
                            <td nowrap="true">
                              <Autocomplete
                                options={this.state.vendors}
                                getOptionLabel={(option) => option.vendor_name}
                                onChange={(event, value) => {
                                  if (value) {

                                    let rows = this.state.coal_purchase_data;
                                    if (value) {
                                      rows[idx].supplier = value.id;
                                      rows[idx].vendor_name = value.vendor_name;
                                      rows[idx].buyer_error = false;
                                    }
                                    else {
                                      rows[idx].supplier = null;
                                      rows[idx].vendor_name = null;
                                      rows[idx].purchase_contract = null;
                                      rows[idx].contract_no = null;
                                      rows[idx].quality_id = null;
                                    }
                                    this.setState({
                                      coal_purchase_data: rows
                                    })
                                  }
                                }}
                                name="buyer"
                                size="small"
                                value={e}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="Search Vendor"
                                    variant="standard"
                                    error={e.buyer_error}
                                    // style={{ top: 8 }}
                                    fullWidth
                                  />
                                )}
                                style={{ display: "contents" }}
                              />
                            </td>
                            <td nowrap="true">
                              <Autocomplete
                                options={e.supplier ? this.state.purchase_contracts?.filter(obj => obj.vendor === e.supplier) : this.state.purchase_contracts}
                                getOptionLabel={(option) => option.contract_no}
                                onChange={(event, value) => {

                                  let rows = this.state.coal_purchase_data;
                                  if (value) {

                                    rows[idx].purchase_contract = value.id;
                                    rows[idx].contract_no = value.contract_no;
                                    this.setState({
                                      coal_purchase_data: rows
                                    }, () => this.PurchaseContractHandler(value.id, idx));
                                  }
                                  else {
                                    rows[idx].purchase_contract = null;
                                    rows[idx].quality_id = null;
                                    rows[idx].quality_input = null;
                                    rows[idx].purchase_type_id = null;
                                    rows[idx].purchase_type_input = "0";
                                    rows[idx].contract_no = null;
                                    this.setState({ coal_purchase_data: rows })
                                  }
                                }}
                                value={e}
                                name="purchase_contract"
                                size="small"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="Search Contract"
                                    variant="standard"
                                    // style={{ top: 8 }}
                                    fullWidth
                                  />
                                )}
                                style={{ display: "contents" }}
                              />
                            </td>
                            <td nowrap="true">
                              {e.purchase_contract ?

                                <TextField
                                  name="quality_id"
                                  variant="standard"
                                  margin="dense"
                                  select
                                  fullWidth
                                  value={e.quality_id}
                                  onChange={(event) => this.CoalPurchaseTextHandler(event, idx, "text")}
                                  inputProps={{
                                    style: { textTransform: "uppercase" },
                                  }}
                                >
                                  <MenuItem value={'0'} disabled>Please Select</MenuItem>

                                  {e.qualities?.map((obj) => (
                                    <MenuItem value={obj.id}>{obj.quality}</MenuItem>
                                  ))}
                                </TextField>
                                :
                                <TextField
                                  name="quality_input"
                                  variant="standard"
                                  margin="dense"
                                  fullWidth
                                  placeholder='Enter Value'
                                  value={e.quality_input}
                                  onChange={(event) => this.CoalPurchaseTextHandler(event, idx, "text")}
                                  inputProps={{
                                    style: { textTransform: "uppercase" },
                                  }}
                                />
                              }
                            </td>
                            <td nowrap="true">
                              {(e.quality_id && e.quality_id !== "0") ?
                                <TextField
                                  name="purchase_type_id"
                                  variant="standard"
                                  margin="dense"
                                  select
                                  fullWidth
                                  value={e.purchase_type_id}
                                  onChange={(event) => {
                                    let rows = this.state.coal_purchase_data;
                                    rows[idx].purchase_type_id = event.target.value;
                                    let price_pmt = e.qualities?.filter(el => el.id === e.quality_id)[0]?.purchase_types?.find(el => el.id === event.target.value)?.price_pmt;
                                    rows[idx].price_pmt = price_pmt;
                                    this.setState({
                                      coal_purchase_data: rows
                                    }, () => this.CoalPurchaseTextHandler(event, idx, 'text'));

                                  }}
                                  inputProps={{
                                    style: { textTransform: "uppercase" },
                                  }}
                                >
                                  <MenuItem value={'0'} disabled>Please Select</MenuItem>
                                  {e.qualities?.filter(el => el.id === e.quality_id)[0]?.purchase_types?.map((obj) => (
                                    <MenuItem value={obj.id}>{obj.purchase_type}</MenuItem>
                                  ))}
                                </TextField>
                                :

                                <TextField
                                  name="purchase_type_input"
                                  variant="standard"
                                  margin="dense"
                                  select
                                  fullWidth
                                  value={e.purchase_type_input}
                                  onChange={(event) => this.CoalPurchaseTextHandler(event, idx, "text")}
                                  inputProps={{
                                    style: { textTransform: "uppercase" },
                                  }}
                                >
                                  {this.state.qualityTypes}
                                </TextField>
                              }
                            </td>
                            <td nowrap="true"> Per MT</td>
                            <td nowrap="true">
                              <TextField
                                name="quantity"
                                margin="dense"
                                variant="standard"
                                placeholder='Enter Value'
                                //required
                                type="number"
                                error={e.quantity_error}
                                value={e.quantity}
                                onChange={(event) => this.CoalPurchaseTextHandler(event, idx, "number")}

                                fullWidth
                              />
                            </td>
                            <td nowrap="true">
                              <TextField
                                name="price_pmt"
                                margin="dense"
                                variant="standard"
                                //required
                                placeholder='Enter Value'
                                error={e.price_pmt_error}
                                type="number"
                                value={e.price_pmt}
                                onChange={(event) => this.CoalPurchaseTextHandler(event, idx, "number")}
                                fullWidth
                              />
                            </td>
                            <td nowrap="true">
                              <TextField
                                name="currency"
                                variant="standard"
                                margin="dense"
                                select
                                fullWidth
                                value={e.currency}
                                error={e.currency_error}
                                onChange={(event) => this.CoalPurchaseTextHandler(event, idx, "text")}
                                inputProps={{
                                  style: { textTransform: "uppercase" },
                                }}
                              >
                                {this.state.currencies}
                              </TextField>
                            </td>
                            <td nowrap="true" className='text-right'>{toLocaleString(e.total)}</td>
                            <td nowrap="true" className='text-center'>
                              {idx === this.state.coal_purchase_data?.length - 1 &&

                                <Tooltip title="ADD" arrow>
                                  <i
                                    className="fa fa-plus-circle mr-3"
                                    style={{
                                      fontSize: 20,
                                      color: config.themeColor,
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      const item = {
                                        supplier: "",
                                        purchase_contract: "",
                                        quality_id: "",
                                        quality_input: "",
                                        purchase_type_id: "",
                                        purchase_type_input: "0",
                                        quantity: "",
                                        price_pmt: "",
                                        currency: "0",
                                        total: ""

                                      }
                                      this.setState({
                                        coal_purchase_data: [...this.state.coal_purchase_data, item]
                                      })
                                    }}
                                  ></i>
                                </Tooltip>
                              }
                              {this.state.coal_purchase_data?.length > 1 &&

                                <Tooltip title="REMOVE" arrow>
                                  <i
                                    className="fa fa-trash-o"
                                    style={{
                                      fontSize: 20,
                                      color: config.themeColor,
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      const rows = this.state.coal_purchase_data;
                                      if (rows[idx].id) {
                                        rows[idx].status = "Deleted";
                                      }
                                      else {
                                        rows.splice(idx, 1);
                                      } this.setState({
                                        coal_purchase_data: rows
                                      }, () => this.TotalPurchaseAndSales())
                                    }
                                    }
                                  ></i>
                                </Tooltip>
                              }
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td colSpan={5}></td>
                          <td className='text-right' style={{ fontWeight: 'bold' }}>{toLocaleString(this.state.coal_purchase_data?.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.quantity ? curr.quantity : 0), 0))}</td>
                          <td colSpan={2}></td>
                          <td className='text-right' style={{ fontWeight: 'bold' }}>{toLocaleString(this.state.coal_purchase_data?.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.total ? curr.total : 0), 0))}</td>
                          <td></td>
                        </tr>
                        <tr>
                          <th colSpan={10}>Service Fee</th>
                        </tr>
                        {this.state.csf_mapped_data?.map((e, idx) => (
                          <tr key={e.contract_no}>
                            <td nowrap="true">
                              <CheckBox
                                value={e.cps_contract_mappping_id}
                                color="primary"
                                checked={e.checked}
                                onChange={(event) => {
                                  let rows = this.state.csf_mapped_data;
                                  rows[idx].checked = event.target.checked;

                                  this.setState({
                                    csf_mapped_data: rows
                                  }, () => this.ExchangeRateHandler());
                                }}
                              /> {e.vendor_name}</td>
                            <td className='text-center' nowrap="true">{e.contract_no}</td>
                            <td className='text-center' nowrap="true">{e.quality}</td>
                            <td className='text-center' nowrap="true">{e.purchase_type}</td>
                            <td className='text-center' nowrap="true">{e.price_type}</td>

                            <td className='text-right' nowrap="true">
                              <TextField
                                name="quantity"
                                margin="dense"
                                variant="standard"
                                placeholder='Enter Value'
                                //required
                                error={e.quantity_error}
                                type="number"
                                value={e.quantity}
                                onChange={(event) => {
                                  let rows = this.state.csf_mapped_data;
                                  const pr = /^\d*(\.\d{0,4})?$/;
                                  if (event.target.value === "" || pr.test(event.target.value)) {
                                    rows[idx]['quantity'] = event.target.value;
                                  }
                                  this.setState({
                                    csf_mapped_data: rows
                                  }, () => this.ExchangeRateHandler())
                                }}

                                fullWidth
                              />
                            </td>
                            <td className='text-right' nowrap="true">{toLocaleString(e.price)}</td>
                            <td className='text-center' nowrap="true">{e.currency}</td>
                            <td className='text-right'>{toLocaleString(e.total)}</td>
                            <td></td>
                          </tr>
                        ))}

                        {this.state.csf_manual_data?.map((e, idx) => (
                          <tr key={idx}>
                            <td nowrap="true">
                              <Autocomplete
                                options={this.state.vendors}
                                getOptionLabel={(option) => option.vendor_name}
                                onChange={(event, value) => {
                                  let rows = this.state.csf_manual_data;
                                  if (value) {

                                    rows[idx].vendor_id = value.id;
                                    rows[idx].vendor_name = value.vendor_name;
                                    rows[idx].buyer_error = false;

                                  } else {
                                    rows[idx].vendor_id = null;
                                    rows[idx].vendor_name = null;
                                  }
                                  this.setState({
                                    csf_manual_data: rows
                                  })
                                }}
                                name="vendor_id"
                                size="small"
                                value={e}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="Search Vendor"
                                    variant="standard"
                                    error={e.buyer_error}
                                    // style={{ top: 8 }}
                                    fullWidth
                                  />
                                )}
                                style={{ display: "contents" }}
                              />
                            </td>
                            <td className='text-center'>-</td>
                            <td>
                              <TextField
                                name="quality"
                                margin="dense"
                                variant="standard"
                                placeholder='Enter Value'
                                //required
                                type="text"
                                value={e.quality}
                                onChange={(event) => this.ServiceFeeTextHandler(event, idx, "text")}

                                fullWidth
                              />
                            </td>
                            <td className='text-center'>-</td>
                            <td nowrap="true">
                              <TextField
                                name="price_type"
                                margin="dense"
                                variant="standard"
                                placeholder='Enter Value'
                                //required
                                type="text"
                                select
                                value={e.price_type}
                                onChange={(event) => this.ServiceFeeTextHandler(event, idx, "text")}

                                fullWidth
                              >
                                <MenuItem value='Per MT'>Per MT</MenuItem>
                                <MenuItem value="Lumpsum">Lumpsum</MenuItem>
                              </TextField>
                            </td>
                            <td nowrap="true">
                              <TextField
                                name="quantity"
                                margin="dense"
                                variant="standard"
                                placeholder='Enter Value'
                                //required
                                error={e.quantity_error}
                                type="number"
                                value={e.quantity}
                                onChange={(event) => this.ServiceFeeTextHandler(event, idx, "number")}

                                fullWidth
                              />
                            </td>
                            <td nowrap="true">
                              <TextField
                                name="price"
                                margin="dense"
                                variant="standard"
                                //required
                                placeholder='Enter Value'
                                error={e.price_pmt_error}
                                type="number"
                                value={e.price}
                                onChange={(event) => this.ServiceFeeTextHandler(event, idx, "number")}
                                fullWidth
                              />
                            </td>
                            <td nowrap="true">
                              <TextField
                                name="currency"
                                variant="standard"
                                margin="dense"
                                select
                                fullWidth
                                value={e.currency}
                                error={e.currency_error}
                                onChange={(event) => this.ServiceFeeTextHandler(event, idx, "text")}
                                inputProps={{
                                  style: { textTransform: "uppercase" },
                                }}
                              >
                                {this.state.currencies}
                              </TextField>
                            </td>
                            <td nowrap="true" className='text-right'>{toLocaleString(e.total)}</td>
                            <td nowrap="true" className='text-center'>
                              {idx === this.state.csf_manual_data?.length - 1 &&

                                <Tooltip title="ADD" arrow>
                                  <i
                                    className="fa fa-plus-circle mr-3"
                                    style={{
                                      fontSize: 20,
                                      color: config.themeColor,
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      const item = {
                                        vendor_id: '',
                                        quality: "",
                                        price_type: "Per MT",
                                        quantity: "",
                                        price: "",
                                        currency: "0",
                                        total: ""

                                      }
                                      this.setState({
                                        csf_manual_data: [...this.state.csf_manual_data, item]
                                      })
                                    }}
                                  ></i>
                                </Tooltip>
                              }
                              {this.state.csf_manual_data?.length > 1 &&

                                <Tooltip title="REMOVE" arrow>
                                  <i
                                    className="fa fa-trash-o"
                                    style={{
                                      fontSize: 20,
                                      color: config.themeColor,
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      const rows = this.state.csf_manual_data;
                                      if (rows[idx].id) {
                                        rows[idx].status = "Deleted";
                                      }
                                      else {
                                        rows.splice(idx, 1);
                                      } this.setState({
                                        csf_manual_data: rows
                                      }, () => this.TotalPurchaseAndSales())
                                    }
                                    }
                                  ></i>
                                </Tooltip>
                              }
                            </td>
                          </tr>
                        ))}

                        <tr>
                          <td colSpan={5}></td>
                          <td className='text-right' style={{ fontWeight: 'bold' }}>{toLocaleString(parseFloat(this.state.csf_manual_data?.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.quantity ? curr.quantity : 0), 0)) + parseFloat(this.state.csf_mapped_data?.filter(e => e.checked === true)?.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.quantity ? curr.quantity : 0), 0)))}</td>
                          <td colSpan={2}></td>
                          <td className='text-right' style={{ fontWeight: 'bold' }}>{toLocaleString(parseFloat(this.state.csf_manual_data?.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.total ? curr.total : 0), 0)) + parseFloat(this.state.csf_mapped_data?.filter(e => e.checked === true)?.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.total ? curr.total : 0), 0)))}</td>
                          <td></td>
                        </tr>
                        <tr>
                          <th colSpan={10}>Procurement Fee</th>
                        </tr>
                        {this.state.cpf_mapped_data?.map((e, idx) => (
                          <tr key={e.contract_no}>
                            <td nowrap="true">
                              <CheckBox
                                value={e.cps_contract_mappping_id}
                                color="primary"
                                checked={e.checked}
                                onChange={(event) => {
                                  let rows = this.state.cpf_mapped_data;
                                  rows[idx]['checked'] = event.target.checked;
                                  this.setState({
                                    cpf_mapped_data: rows
                                  }, () => this.ExchangeRateHandler())
                                }}
                              /> {e.vendor_name}</td>
                            <td className='text-center' nowrap="true">{e.contract_no}</td>
                            <td className='text-center' nowrap="true">{e.quality}</td>
                            <td className='text-center' nowrap="true">{e.purchase_type}</td>
                            <td className='text-center' nowrap="true">{e.price_type}</td>

                            <td className='text-right' nowrap="true">
                              <TextField
                                name="quantity"
                                margin="dense"
                                variant="standard"
                                placeholder='Enter Value'
                                //required
                                error={e.quantity_error}
                                type="number"
                                value={e.quantity}
                                onChange={(event) => {
                                  let rows = this.state.cpf_mapped_data;
                                  const pr = /^\d*(\.\d{0,4})?$/;
                                  if (event.target.value === "" || pr.test(event.target.value)) {
                                    rows[idx]['quantity'] = event.target.value;
                                  }
                                  this.setState({
                                    cpf_mapped_data: rows
                                  }, () => this.ExchangeRateHandler())
                                }}

                                fullWidth
                              />
                            </td>
                            <td className='text-right' nowrap="true">{toLocaleString(e.price)}</td>
                            <td className='text-center' nowrap="true">{e.currency}</td>
                            <td className='text-right'>{toLocaleString(e.total)}</td>
                            <td></td>
                          </tr>
                        ))}

                        {this.state.cpf_manual_data?.map((e, idx) => (
                          <tr key={idx}>
                            <td nowrap="true">
                              <Autocomplete
                                options={this.state.vendors}
                                getOptionLabel={(option) => option.vendor_name}
                                onChange={(event, value) => {
                                  let rows = this.state.cpf_manual_data;
                                  if (value) {

                                    rows[idx].vendor_id = value.id;
                                    rows[idx].vendor_name = value.vendor_name;
                                    rows[idx].buyer_error = false;
                                  } else {
                                    rows[idx].vendor_id = null;
                                    rows[idx].vendor_name = null;
                                  }
                                  this.setState({
                                    cpf_manual_data: rows
                                  })
                                }}
                                name="vendor_id"
                                size="small"
                                value={e}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    error={e.buyer_error}
                                    placeholder="Search Vendor"
                                    variant="standard"
                                    // style={{ top: 8 }}
                                    fullWidth
                                  />
                                )}
                                style={{ display: "contents" }}
                              />
                            </td>
                            <td className='text-center'>-</td>
                            <td>
                              <TextField
                                name="quality"
                                margin="dense"
                                variant="standard"
                                placeholder='Enter Value'
                                //required
                                type="text"
                                value={e.quality}
                                onChange={(event) => this.ProcurementFeeTextHandler(event, idx, "text")}

                                fullWidth
                              />
                            </td>
                            <td className='text-center'>-</td>
                            <td nowrap="true">
                              <TextField
                                name="price_type"
                                margin="dense"
                                variant="standard"
                                placeholder='Enter Value'
                                //required
                                type="text"
                                select
                                value={e.price_type}
                                onChange={(event) => this.ProcurementFeeTextHandler(event, idx, "text")}

                                fullWidth
                              >
                                <MenuItem value='Per MT'>Per MT</MenuItem>
                                <MenuItem value="Lumpsum">Lumpsum</MenuItem>
                              </TextField>
                            </td>
                            <td nowrap="true">
                              <TextField
                                name="quantity"
                                margin="dense"
                                variant="standard"
                                placeholder='Enter Value'
                                //required
                                error={e.quantity_error}
                                type="number"
                                value={e.quantity}
                                onChange={(event) => this.ProcurementFeeTextHandler(event, idx, "number")}

                                fullWidth
                              />
                            </td>
                            <td nowrap="true">
                              <TextField
                                name="price"
                                margin="dense"
                                variant="standard"
                                //required
                                placeholder='Enter Value'
                                error={e.price_pmt_error}
                                type="number"
                                value={e.price}
                                onChange={(event) => this.ProcurementFeeTextHandler(event, idx, "number")}
                                fullWidth
                              />
                            </td>
                            <td nowrap="true">
                              <TextField
                                name="currency"
                                variant="standard"
                                margin="dense"
                                select
                                fullWidth
                                value={e.currency}
                                error={e.currency_error}
                                onChange={(event) => this.ProcurementFeeTextHandler(event, idx, "text")}
                                inputProps={{
                                  style: { textTransform: "uppercase" },
                                }}
                              >
                                {this.state.currencies}
                              </TextField>
                            </td>
                            <td nowrap="true" className='text-right'>{toLocaleString(e.total)}</td>
                            <td nowrap="true" className='text-center'>
                              {idx === this.state.cpf_manual_data?.length - 1 &&

                                <Tooltip title="ADD" arrow>
                                  <i
                                    className="fa fa-plus-circle mr-3"
                                    style={{
                                      fontSize: 20,
                                      color: config.themeColor,
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      const item = {
                                        vendor_id: '',
                                        quality: "",
                                        price_type: "Per MT",
                                        quantity: "",
                                        price: "",
                                        currency: "0",
                                        total: ""
                                      }
                                      this.setState({
                                        cpf_manual_data: [...this.state.cpf_manual_data, item]
                                      })
                                    }}
                                  ></i>
                                </Tooltip>
                              }
                              {this.state.cpf_manual_data?.length > 1 &&

                                <Tooltip title="REMOVE" arrow>
                                  <i
                                    className="fa fa-trash-o"
                                    style={{
                                      fontSize: 20,
                                      color: config.themeColor,
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      const rows = this.state.cpf_manual_data;
                                      if (rows[idx].id) {
                                        rows[idx].status = "Deleted";
                                      }
                                      else {
                                        rows.splice(idx, 1);
                                      } this.setState({
                                        cpf_manual_data: rows
                                      }, () => this.TotalPurchaseAndSales())
                                    }
                                    }
                                  ></i>
                                </Tooltip>
                              }
                            </td>
                          </tr>
                        ))}

                        <tr>
                          <td colSpan={5}></td>
                          <td className='text-right' style={{ fontWeight: 'bold' }}>{toLocaleString(parseFloat(this.state.cpf_manual_data?.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.quantity ? curr.quantity : 0), 0)) + parseFloat(this.state.cpf_mapped_data?.filter(e => e.checked === true)?.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.quantity ? curr.quantity : 0), 0)))}</td>

                          <td colSpan={2}></td>
                          <td className='text-right' style={{ fontWeight: 'bold' }}>{toLocaleString(parseFloat(this.state.cpf_manual_data?.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.total ? curr.total : 0), 0)) + parseFloat(this.state.cpf_mapped_data?.filter(e => e.checked === true)?.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.total ? curr.total : 0), 0)))}</td>
                          <td></td>
                        </tr>

                        <tr>
                          <th >Barge Cost</th>
                        </tr>
                        {this.state.barge_cost_data?.map((e, idx) => (
                          <tr key={e.vendor_id}>
                            <td nowrap="true">
                              <Autocomplete
                                options={this.state.vendors}
                                getOptionLabel={(option) => option.vendor_name}
                                onChange={(event, value) => {
                                  let rows = this.state.barge_cost_data;
                                  if (value) {

                                    rows[idx].vendor_id = value.id;
                                    rows[idx].vendor_name = value.vendor_name;
                                    rows[idx].buyer_error = false;

                                  } else {
                                    rows[idx].vendor_id = null;
                                    rows[idx].vendor_name = null;
                                  }
                                  this.setState({
                                    barge_cost_data: rows
                                  })
                                }}
                                name="vendor_id"
                                size="small"
                                value={e}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="Search Vendor"
                                    variant="standard"
                                    error={e.buyer_error}
                                    // style={{ top: 8 }}
                                    fullWidth
                                  />
                                )}
                                style={{ display: "contents" }}
                              />
                            </td>
                            <td nowrap="true">
                              <TextField
                                name="jetty"
                                variant="standard"
                                margin="dense"
                                fullWidth
                                placeholder='Jetty'
                                label='Jetty'
                                value={e.jetty}
                                onChange={(event) => this.BargeCostTextHandler(event, idx, "text")}
                                inputProps={{
                                  style: { textTransform: "uppercase" },
                                }}
                              />
                            </td>
                            <td nowrap="true" colSpan={2} className='text-center'>-</td>
                            <td nowrap="true">
                              <TextField
                                name="price_type"
                                margin="dense"
                                variant="standard"
                                placeholder='Enter Value'
                                //required
                                type="text"
                                select
                                value={e.price_type}
                                onChange={(event) => this.BargeCostTextHandler(event, idx, "text")}

                                fullWidth
                              >
                                <MenuItem value='Per MT'>Per MT</MenuItem>
                                <MenuItem value="Lumpsum">Lumpsum</MenuItem>
                              </TextField>
                            </td>
                            <td nowrap="true">
                              <TextField
                                name="quantity"
                                margin="dense"
                                variant="standard"
                                placeholder='Enter Value'
                                //required
                                error={e.quantity_error}
                                type="number"
                                value={e.quantity}
                                onChange={(event) => this.BargeCostTextHandler(event, idx, "number")}

                                fullWidth
                              />
                            </td>
                            <td nowrap="true">
                              <TextField
                                name="price"
                                margin="dense"
                                variant="standard"
                                //required
                                placeholder='Enter Value'
                                error={e.price_pmt_error}
                                type="number"
                                value={e.price}
                                onChange={(event) => this.BargeCostTextHandler(event, idx, "number")}
                                fullWidth
                              />
                            </td>
                            <td nowrap="true">
                              <TextField
                                name="currency"
                                variant="standard"
                                margin="dense"
                                select
                                fullWidth
                                error={e.currency_error}
                                value={e.currency}
                                onChange={(event) => this.BargeCostTextHandler(event, idx, "text")}
                                inputProps={{
                                  style: { textTransform: "uppercase" },
                                }}
                              >
                                {this.state.currencies}
                              </TextField>
                            </td>
                            <td nowrap="true" className='text-right'>{toLocaleString(e.total)}</td>
                            <td nowrap="true" className='text-center'>
                              {idx === this.state.barge_cost_data?.length - 1 &&

                                <Tooltip title="ADD" arrow>
                                  <i
                                    className="fa fa-plus-circle mr-3"
                                    style={{
                                      fontSize: 20,
                                      color: config.themeColor,
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      const item = {
                                        vendor_id: '',
                                        jetty: "''",
                                        price_type: "Per MT",
                                        quantity: "",
                                        price: "",
                                        currency: "0",
                                        total: ""

                                      }
                                      this.setState({
                                        barge_cost_data: [...this.state.barge_cost_data, item]
                                      })
                                    }}
                                  ></i>
                                </Tooltip>
                              }
                              {this.state.barge_cost_data?.length > 1 &&

                                <Tooltip title="REMOVE" arrow>
                                  <i
                                    className="fa fa-trash-o"
                                    style={{
                                      fontSize: 20,
                                      color: config.themeColor,
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      const rows = this.state.barge_cost_data;
                                      if (rows[idx].id) {
                                        rows[idx].status = "Deleted";
                                      }
                                      else {
                                        rows.splice(idx, 1);
                                      } this.setState({
                                        barge_cost_data: rows
                                      }, () => this.TotalPurchaseAndSales())
                                    }
                                    }
                                  ></i>
                                </Tooltip>
                              }
                            </td>
                          </tr>
                        ))}

                        <tr>
                          <td colSpan={5}></td>
                          <td className='text-right' style={{ fontWeight: 'bold' }}>{toLocaleString(this.state.barge_cost_data?.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.quantity ? curr.quantity : 0), 0))}</td>
                          <td colSpan={2}></td>
                          <td className='text-right' style={{ fontWeight: 'bold' }}>{toLocaleString(this.state.barge_cost_data?.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.total ? curr.total : 0), 0))}</td>
                          <td></td>
                        </tr>

                        <tr>
                          <th colSpan={10}>Misc Expenses</th>
                        </tr>
                        {this.state.misc_expenses_data?.map((e, idx) => (
                          <tr key={idx}>
                            <td>
                              <Autocomplete
                                options={costingTypes}
                                getOptionLabel={(option) => option}
                                onChange={(event, value) => {
                                  let rows = this.state.misc_expenses_data;
                                  if (value) {

                                    rows[idx].costing_type = value;
                                    rows[idx].buyer_error = false;

                                  } else {
                                    rows[idx].costing_type = null;
                                  }
                                  this.setState({
                                    misc_expenses_data: rows
                                  })
                                }}
                                name="costing_type"
                                size="small"
                                // value={e}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="Costing Type"
                                    variant="standard"
                                    error={e.buyer_error}
                                    // style={{ top: 8 }}
                                    fullWidth
                                  />
                                )}
                                style={{ display: "contents" }}
                              />
                            </td>
                            <td colSpan={3} className='text-center'>-</td>
                            <td>
                              <TextField
                                name="price_type"
                                margin="dense"
                                variant="standard"
                                placeholder='Enter Value'
                                //required
                                type="text"
                                select
                                value={e.price_type}
                                onChange={(event) => this.MiscExpensesTextHandler(event, idx, "text")}

                                fullWidth
                              >
                                <MenuItem value='Per MT'>Per MT</MenuItem>
                                <MenuItem value="Lumpsum">Lumpsum</MenuItem>
                              </TextField>
                            </td>
                            <td>
                              <TextField
                                name="quantity"
                                margin="dense"
                                variant="standard"
                                placeholder='Enter Value'
                                //required
                                error={e.quantity_error}
                                type="number"
                                value={e.quantity}
                                onChange={(event) => this.MiscExpensesTextHandler(event, idx, "number")}

                                fullWidth
                              />
                            </td>
                            <td>
                              <TextField
                                name="price"
                                margin="dense"
                                variant="standard"
                                //required
                                placeholder='Enter Value'
                                error={e.price_pmt_error}
                                type="number"
                                value={e.price}
                                onChange={(event) => this.MiscExpensesTextHandler(event, idx, "number")}
                                fullWidth
                              />
                            </td>
                            <td>
                              <TextField
                                name="currency"
                                variant="standard"
                                margin="dense"
                                select
                                fullWidth
                                value={e.currency}
                                error={e.currency_error}
                                onChange={(event) => this.MiscExpensesTextHandler(event, idx, "text")}
                                inputProps={{
                                  style: { textTransform: "uppercase" },
                                }}
                              >
                                {this.state.currencies}
                              </TextField>
                            </td>
                            <td className='text-right'>{toLocaleString(e.total)}</td>
                            <td className='text-center'>
                              {idx === this.state.misc_expenses_data?.length - 1 &&

                                <Tooltip title="ADD" arrow>
                                  <i
                                    className="fa fa-plus-circle mr-3"
                                    style={{
                                      fontSize: 20,
                                      color: config.themeColor,
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      const item = {
                                        costing_type: "",
                                        price_type: "Per MT",
                                        quantity: "",
                                        price: "",
                                        currency: "0",
                                        total: ""

                                      }
                                      this.setState({
                                        misc_expenses_data: [...this.state.misc_expenses_data, item]
                                      })
                                    }}
                                  ></i>
                                </Tooltip>
                              }
                              {this.state.misc_expenses_data?.length > 1 &&

                                <Tooltip title="REMOVE" arrow>
                                  <i
                                    className="fa fa-trash-o"
                                    style={{
                                      fontSize: 20,
                                      color: config.themeColor,
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      const rows = this.state.misc_expenses_data;
                                      if (rows[idx].id) {
                                        rows[idx].status = "Deleted";
                                      }
                                      else {
                                        rows.splice(idx, 1);
                                      } this.setState({
                                        misc_expenses_data: rows
                                      }, () => this.TotalPurchaseAndSales())
                                    }
                                    }
                                  ></i>
                                </Tooltip>
                              }
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td colSpan={5}></td>
                          <td className='text-right' style={{ fontWeight: 'bold' }}>{toLocaleString(this.state.misc_expenses_data?.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.quantity ? curr.quantity : 0), 0))}</td>
                          <td colSpan={2}></td>
                          <td className='text-right' style={{ fontWeight: 'bold' }}>{toLocaleString(this.state.misc_expenses_data?.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.total ? curr.total : 0), 0))}</td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className='row'>
                    <table className='table table-sm table-bordered'>
                      <thead className='table-header'>
                        <tr>
                          <th className='text-right' nowrap="true">Total Cost of Purchase</th>
                          <th className='text-right' nowrap="true">Total Cost of Purchase / MT</th>
                          <th className='text-right' nowrap="true">Total Cost of Sales</th>
                          <th className='text-right' nowrap="true">Total Cost of Sales / MT</th>
                          <th className='text-right' nowrap="true">Realization / MT</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td nowrap="true" className='text-right' style={{ fontWeight: 'bold' }}>{toLocaleString(this.state.total_cost_of_purchase)}</td>
                          <td nowrap="true" className='text-right' style={{ fontWeight: 'bold' }}>{toLocaleString(this.state.total_cost_of_purchase_pmt)}</td>
                          <td nowrap="true" className='text-right' style={{ fontWeight: 'bold' }}>{toLocaleString(this.state.total_cost_of_sales)}</td>
                          <td nowrap="true" className='text-right' style={{ fontWeight: 'bold' }}>{toLocaleString(this.state.total_cost_of_sales_pmt)}</td>
                          <td nowrap="true" className='text-right' style={{ fontWeight: 'bold' }}>{toLocaleString(this.state.realization_pmt)}</td>
                        </tr>
                      </tbody>

                    </table>
                  </div>
                </div>
              </div>
              <div className="row bottom_buttons_section">
                <button
                  type="button"
                  className="btn previous_button"
                  name="submit"
                  onClick={() => (window.location.href = "/budget-costing")}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn next_button"
                  name="Back"
                  onClick={this.OnSubmitHandler}
                >
                  Submit
                </button>
              </div>
            </form>
          }

        </div>
      </div>
    );
  }
}

export default BudgetAndCostingUpdate;