import React, { Fragment, useEffect, useState } from 'react';
import {
  Tooltip,
  InputAdornment,
  IconButton
} from "@material-ui/core";

import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import EventIcon from "@material-ui/icons/Event";
import DateFnsUtils from "@date-io/date-fns";

import { dateFormateToDB, localDateFormate, toLocaleString } from './common';
import config from '../../config/config';
import DeletePayments from './DeletePayments';
import PaymentsModal from './PaymentsModal';
import api from '../../api/api';

import CookieHandler from './CookieHandler';

import { PAYMENTS_INFO } from '../constants/constants';

const Cookie = new CookieHandler();
const PaymentPostings = ({ payments, costingType, data, access_details, callbackFn }) => {

  const [userRoles, setUserRoles] = useState(Cookie.getCookie("features") ? Cookie.getCookie("features") : []);

  useEffect(() => {
    setUserRoles(Cookie.getCookie("features"))
  }, [])

  const [paymentMode, setPaymentMode] = useState("Add");
  const [showPaymentsModal, setShowPaymentsModal] = useState(false);
  const [state, setState] = useState(data);

  const renderDeletePayments = (item) => {

    const filted_data = PAYMENTS_INFO.find(item => item.costing_type === costingType);
    const { payment_table_name = null } = filted_data || {};
    return (
      <DeletePayments
        data={item}
        table={payment_table_name}
        callbackFn={() => callbackFn()}
      />
    )
  }

  const updatePaymentDate = (id, date) => {
    const filted_data = PAYMENTS_INFO.find(item => item.costing_type === costingType);
    const { payment_table_name } = filted_data || {};
    let reqBody = {
      login_user_id: Cookie.getCookie("loginUserId"),
      idtoken: Cookie.getIdTokenCookie(),
      payment_table: payment_table_name,
      payment_id: id,
      date_of_payment: dateFormateToDB(date),
    };

    api.update_payment_date(reqBody).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            setShowPaymentsModal(false);
            callbackFn();
          } else {
          }
        });
      }
    });
  }

  const updatePaymentPostingsHandler = (request) => {
    api.update_payment_postings(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            setShowPaymentsModal(false);
            callbackFn();
          } else {
          }
        });
      }
    });
  }

  const approvePaymentsHandler = (id) => {
    const filted_data = PAYMENTS_INFO.find(item => item.costing_type === costingType);
    const { payment_costing_type = null } = filted_data || {};
    let request = {
      login_user_id: Cookie.getCookie("loginUserId"),
      idtoken: Cookie.getIdTokenCookie(),
      payment_id: id,
      costing_type: payment_costing_type,
      approval_status: "Approved",
    };
    api.approve_payment_postings_api(request)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === '200') {
              callbackFn();
            }
          })
        }
      })
  }

  const { costing_approval, add_payment = '', update_payment = '', view_payment = '', approver = '' } = access_details || {};
  const login_user_id = Cookie.getCookie("loginUserId");
  const { invoice_no = null } = data || {};

  return (
    <Fragment>
      {(userRoles?.includes(view_payment) || userRoles?.includes("97")) &&
        <div className='card'>
          <div className=''>
            <div className="clearfix">
              {payments.length <= 0 && <h5 className='float-left p-2'>No payments made</h5>}
              {((userRoles?.includes(add_payment) || userRoles?.includes("97")) && invoice_no) &&

                <div className="float-right p-2">
                  <button className='header_button header_button_text payment_button_adjustment'
                    onClick={() => {
                      setPaymentMode("Add");
                      const filted_data = PAYMENTS_INFO.find(item => item.costing_type === costingType);
                      const stateCopy = {
                        ...filted_data,
                        ...data
                      }
                      setState(stateCopy);
                      setShowPaymentsModal(true);

                    }}
                  >Add Payment</button>
                </div>
              }
            </div>
          </div>


          {payments?.length > 0 && (

            <div className=''>

              <div className="table-responsive border-0">
                <table className="table table-borderless border-0">
                  <thead>
                    <tr>
                      <th className='table_header_barge_label border-0' nowrap="true">Payment ID</th>
                      <th className='table_header_barge_label border-0' nowrap="true">Date of Payment</th>
                      <th className='table_header_barge_label border-0' nowrap="true">Payment Due Date</th>
                      <th className='table_header_barge_label border-0' nowrap="true">Amount</th>
                      {/* <th className='table_header_barge_label border-0' nowrap="true">Invoice No</th> */}
                      <th className='table_header_barge_label border-0' nowrap="true">Description</th>
                      <th className='table_header_barge_label border-0' nowrap="true">Created Date</th>
                      <th className='table_header_barge_label border-0' nowrap="true">Approval</th>
                      <th className='table_header_barge_label border-0' nowrap="true">Approved On</th>
                      {/* <th className='table_header_barge_label border-0' nowrap="true">1st Level Approval</th>
                      <th className='table_header_barge_label border-0' nowrap="true">1st Level Approved On</th> */}
                      {/* <th className='table_header_barge_label border-0' nowrap="true">2nd Level Approval</th>
                      <th className='table_header_barge_label border-0' nowrap="true">2nd Level Approved On</th> */}
                      <th className='table_header_barge_label border-0' nowrap="true"> Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {payments?.map(item => (
                      <tr key={item.id}>
                        <td nowrap="true" className="table_td">{item.payment_id}</td>
                        <td nowrap="true" style={{ minWidth: "190px" }}>

                          {(item.date_of_payment && !userRoles.includes('97')) ?

                            <>
                              {item.date_of_payment}
                            </>

                            :
                            <>
                              {(item.approval_status === "Approved" && userRoles.includes('97')) && (
                                <MuiPickersUtilsProvider
                                  utils={DateFnsUtils}
                                >
                                  <DatePicker
                                    autoOk={true}
                                    inputVariant="outlined"
                                    variant="inline"
                                    margin="dense"
                                    fullWidth
                                    format="dd/MM/yyyy"
                                    value={
                                      item.date_of_payment
                                    }

                                    onChange={(date) => {

                                      updatePaymentDate(item.id, date);

                                    }}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <IconButton>
                                            <EventIcon />
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                      className: "pl-0",
                                    }}
                                  />
                                </MuiPickersUtilsProvider>
                              )}
                            </>

                          }

                        </td>
                        <td nowrap="true" className="table_td">{localDateFormate(item.payment_due_date)}</td>
                        <td nowrap="true" className="table_td">{toLocaleString(item.amount)}</td>
                        {/* <td nowrap="true" className="table_td text-uppercase">{item.invoice_no}</td> */}
                        <td nowrap="true" className="table_td text-uppercase" style={{
                          whiteSpace: "pre-line",
                        }}>{item.description}</td>
                        <td nowrap="true" className="table_td">{localDateFormate(item.created_date)}</td>

                        {(costing_approval === "Approved" && item.approval_status === "Pending" && login_user_id !== item.created_by && (userRoles.includes(
                          approver
                        ) ||
                          userRoles.includes(
                            "97"
                          )))
                          ?
                          <td nowrap="true" className="table_td">
                            <button
                              className="header_button header_button_text add_button_adjustment"
                              style={{
                                width: "auto",
                                marginTop: "17px",
                              }}
                              onClick={() =>
                                approvePaymentsHandler(
                                  item.id
                                )
                              }
                            >
                              Approve
                            </button>
                          </td>
                          :
                          <td nowrap="true" className="table_td">
                            {item.approved_by_name
                              ? item.approved_by_name
                              : "-"}
                          </td>
                        }

                        <td nowrap="true" className="table_td">{localDateFormate(item.approved_date)}</td>
                        {/* {costing_approval === "Approved" && item.approval_status === "Approved" &&
                          item.approval_status_2 === "Pending" && login_user_id !== item.created_by && login_user_id !== item.approved_by && (userRoles?.includes(
                            approver2
                          ) ||
                            userRoles.includes(
                              "97"
                            ))
                          ?
                          <td nowrap="true" className="table_td">


                            <button
                              className="header_button header_button_text add_button_adjustment"
                              style={{
                                width: "auto",
                                marginTop: "17px",
                              }}
                              onClick={() =>
                                approvePaymentsHandler(
                                  item.id
                                )
                              }
                            >
                              Approve
                            </button>
                          </td>
                          :
                          <td nowrap="true" className="table_td">
                            {item.approved_by_2_name
                              ? item.approved_by_2_name
                              : "-"}
                          </td>
                        } */}
                        {/* <td nowrap="true" className="table_td">{localDateFormate(item.approved_date_2)}</td> */}

                        <td nowrap='true' className='table_td'>

                          {(item.approval_status === "Pending" && (userRoles?.includes('97') || userRoles?.includes(update_payment)) &&


                            <Tooltip title="Edit" arrow>
                              <i
                                className="fa fa-pencil mr-2"
                                style={{
                                  fontSize: 20,
                                  color:
                                    config.themeColor,
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setPaymentMode("Update");
                                  const filted_data = PAYMENTS_INFO.find(item => item.costing_type === costingType);
                                  const { outstanding_amount = 0 } = data;
                                  const stateCopy = {
                                    ...filted_data,
                                    ...item,
                                    ...data,
                                    payment_id: item.id,
                                    outstanding_amount: outstanding_amount + item.amount
                                  }
                                  setState(stateCopy);
                                  setShowPaymentsModal(true);
                                }}
                              ></i>
                            </Tooltip>
                          )}

                          {!item.date_of_payment &&

                            (
                              renderDeletePayments(item)
                            )
                          }

                        </td>

                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

          )}

        </div>
      }

      {/**
             * 
             *  ADD || UDATE PAYMENTS
             * 
             */}

      <PaymentsModal
        open={showPaymentsModal}
        close={() => setShowPaymentsModal(false)}
        data={state}
        callbackFn={(request) => {
          if (paymentMode === "Add") {
            api.add_payment_postings(request).then((response) => {
              if (response.status >= 200 && response.status < 300) {
                response.json().then((res) => {
                  if (res.code === "200") {
                    setShowPaymentsModal(false);
                    callbackFn();
                    //   this.setState({ paymentsModal: false });
                    //   this.componentDidMount();
                  } else {
                  }
                });
              }
            });
          }
          else if (paymentMode === "Update") {
            updatePaymentPostingsHandler(request);
          }
        }}
      />

    </Fragment>
  )

}


export default PaymentPostings;