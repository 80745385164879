import React, { Component } from "react";
import Header from "../common/Header";
import SideBar from "../common/SideBar";
import CookieHandler from "../common/CookieHandler";
import config from "../../config/config";

import { Alert } from "@material-ui/lab";
import { Snackbar } from "@material-ui/core";
import Loader from "../common/Loader";

export default class Reports extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = { features: [] };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    let features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    // if (!(features.includes('332')  || features.includes('97') )) {
    //   window.location.href = '/dashboard';
    // }

    this.setState({ isLoading: false });
    document.title = config.documentTitle + "reports";
  }

  render() {
    return (
      <div id="wraper">
        <SideBar />
        <div className="content">
          <Header />
          {this.state.isLoading && <Loader />}
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          {!this.state.isLoading && (
            <div className="content-header">
              <div className="col-sm pl-0">
                <h4 style={{ padding: "10px 20px", margin: "0px" }}>reports</h4>
              </div>
              <form className="container p-3">
                <div className="col-lg-12 card">
                  <div className="section_block" style={{ width: "80%" }}>
                    {(this.state.features.includes("332")  ||
                      this.state.features.includes("97") ) && (
                      <div>
                        <div style={{ width: "70%" }}>
                          <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                            Profit / Loss reports
                          </h5>
                        </div>

                        <div className="row">
                          <ul className="pl-0 mb-0">
                            <li className="list-group-item border-0">
                              <a
                                href={"/reports/coal-summary"}
                                style={{ color: config.themeColor }}
                              >
                                <i
                                  className="fa fa-star-o"
                                  aria-hidden="true"
                                ></i>
                                &emsp;P/L Summary by Coal Supplier
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    )}
                    {(this.state.features.includes("387")  ||
                      this.state.features.includes("97") ) &&
                      (config.company === "SDAM" ||
                        config.company === "SRK" ||
                        config.company === "BTR") && (
                        <div>
                          <div style={{ width: "70%" }}>
                            <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                              Monthly reports
                            </h5>
                          </div>
                          <div className="row">
                            <ul className="pl-0 mb-0">
                              <li className="list-group-item border-0">
                                <a
                                  href={"/reports/vat-input-summary"}
                                  style={{ color: config.themeColor }}
                                >
                                  <i
                                    className="fa fa-star-o"
                                    aria-hidden="true"
                                  ></i>
                                  &emsp;VAT Input Summary
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      )}
                    {this.state.features.includes("97")  && (
                      <div>
                        <div style={{ width: "70%" }}>
                          <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                            Implementation
                          </h5>
                        </div>
                        <div className="row">
                          <ul className="pl-0 mb-0">
                            <li className="list-group-item border-0">
                              <a
                                href={"/reports/vat-implementation"}
                                style={{ color: config.themeColor }}
                              >
                                <i
                                  className="fa fa-star-o"
                                  aria-hidden="true"
                                ></i>
                                &emsp;VAT Implementation
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    )}
                    {(this.state.features.includes("97")  ||
                      this.state.features.includes("535")) && (
                      <div>
                        <div style={{ width: "70%" }}>
                          <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                            {" "}
                            Available Quantity reports
                          </h5>
                        </div>
                        <div className="row">
                          <ul className="pl-0 mb-0">
                            <li className="list-group-item border-0">
                              <a
                                href={"/reports/pc-quantity-reports"}
                                style={{ color: config.themeColor }}
                              >
                                <i
                                  className="fa fa-star-o"
                                  aria-hidden="true"
                                ></i>
                                &emsp;Purchase Contracts
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    )}

                    {/* {((this.state.features.includes('332')  || this.state.features.includes('97') )) && */}
                    <div>
                      <div style={{ width: "70%" }}>
                        <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                          Approvals
                        </h5>
                      </div>

                      <div className="row">
                        <ul className="pl-0 mb-0">
                          <li className="list-group-item border-0 pb-0">
                            <a
                              href={"/business-approvals"}
                              style={{ color: config.themeColor }}
                            >
                              <i
                                className="fa fa-star-o"
                                aria-hidden="true"
                              ></i>
                              &emsp;Business Approvals
                            </a>
                          </li>
                          {(this.state.features.includes("97")  ||
                            this.state.features.includes("554") ) && (
                            <li className="list-group-item border-0 pb-0">
                              <a
                                href={"/financial-approvals"}
                                style={{ color: config.themeColor }}
                              >
                                <i
                                  className="fa fa-star-o"
                                  aria-hidden="true"
                                ></i>
                                &emsp;Financial Approvals
                              </a>
                            </li>
                          )}
                          {/* <li className="list-group-item border-0 pb-0">
                            <a
                              href={"/financial-approvals"}
                              style={{ color: config.themeColor }}
                            >
                              <i
                                className="fa fa-star-o"
                                aria-hidden="true"
                              ></i>
                              &emsp;Incoming Fund
                            </a>
                          </li> */}
                          <li className="list-group-item border-0 pb-0">
                            <a
                              href={
                                "/reports/payment-limits-for-second-approval"
                              }
                              style={{ color: config.themeColor }}
                            >
                              <i
                                className="fa fa-star-o"
                                aria-hidden="true"
                              ></i>
                              &emsp;Payment Limits for 2nd Level Approval
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* } */}
                    {(this.state.features.includes("549")  ||
                      this.state.features.includes("97") ) && (
                      <div>
                        <div style={{ width: "70%" }}>
                          <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                            Coal Price Index
                          </h5>
                        </div>
                        <div className="row">
                          <ul className="pl-0 mb-0">
                            <li className="list-group-item border-0">
                              <a
                                href={"/weekly-coal-index"}
                                style={{ color: config.themeColor }}
                              >
                                <i
                                  className="fa fa-star-o"
                                  aria-hidden="true"
                                ></i>
                                &emsp;Coal Price Indexes
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    )}
                    {this.state.features.includes("97")  && (
                      <div>
                        <div style={{ width: "70%" }}>
                          <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                            Other
                          </h5>
                        </div>

                        <div className="row">
                          <ul className="pl-0 mb-0">
                            <li className="list-group-item border-0 pb-0">
                              <a
                                href={"/emailConfig"}
                                style={{ color: config.themeColor }}
                              >
                                <i
                                  className="fa fa-star-o"
                                  aria-hidden="true"
                                ></i>
                                &emsp;Email Config
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    );
  }
}
