import React, { Component } from "react";
import CookieHandler from "../common/CookieHandler";
import api from "../../api/api";
import config from "../../config/config";
import { Alert } from "@material-ui/lab";
import MaterialTable from "material-table";

import {
  Snackbar,
  Button,
  Drawer,
  TextField,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { IconButton, InputAdornment } from "@material-ui/core";
import EventIcon from "@material-ui/icons/Event";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { localDateFormate } from "../common/common";

export default class Profit_And_Loss_Avg_Xchg_Rate extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();

    this.state = {
      avg_xchg_data: [],
      activeTabIndex: 0,
      customer_drop_values: [],
      features: [],
      // avg_xchg_rate
      customer_id: "",
      amount_in_usd: "",
      transfer_date: null,
      exchange_rate: "",
      amount_in_idr: "",
      // Conversion Data
      conv_amount_in_usd: "",
      conv_amount_in_idr: "",
      conversion_date: null,
      isLoading: true,
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    var features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (!(features.includes("314")  || features.includes("97") )) {
      window.location.href = "/dashboard";
    }
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();

    const status = "Active";

    await api.getCustomers(loginUserID, idToken, status).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
          if (res.code === "200") {
            if (res.customers) {
              const customerData = [];
              for (var i of res.customers) {
                customerData.push({
                  name: i.customer_name,
                  value: i.id,
                  key: i,
                });
              }
              this.setState({
                customer_drop_values: customerData,
              });
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
    try {
      var business_no_id = this.props.business_no_id;
    } catch (e) {
      window.location.href = "/business-number-list";
    }
    await api
      .get_profit_loss_avg_exchange_list(loginUserID, idToken, business_no_id)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.profit_or_loss_data) {
                var number_of_items = 0;
                var sum_of_avg_exchg_rate = 0;
                for (var i of res.profit_or_loss_data) {
                  for (var j of i.exchange_conversion_data) {
                    sum_of_avg_exchg_rate += Number(
                      j.exchange_rate ? j.exchange_rate : 0
                    );
                    number_of_items++;
                  }
                }
                const avg_xchg_rate = (
                  sum_of_avg_exchg_rate / number_of_items
                ).toFixed(2);

                this.setState({
                  avg_xchg_data: res.profit_or_loss_data,
                  sum_of_avg_exchg_rate: sum_of_avg_exchg_rate,
                  number_of_items: number_of_items,
                  avg_xchg_rate: avg_xchg_rate,
                  isLoading: false,
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
                isLoading: false,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  }

  toLocaleString(value) {
    if (value) {
      const data = Number(value ? value : 0).toFixed(2);
      return Number(data).toLocaleString(undefined, {
        minimumFractionDigits: 2,
      });
    } else {
      return null;
    }
  }

  add_avg_xchg_rate_handler = () => {
    var {
      customer_id,
      amount_in_usd,
      transfer_date,
      exchange_rate,
      amount_in_idr,
    } = this.state;
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const business_no_id = this.props.business_no_id;
    if (customer_id === "" || !customer_id) {
      this.setState({
        customer_error: true,
        snackBarErrorOpen: true,
        errorMsg: "Please fill mandatory fields.",
      });
      return;
    }
    if (this.state.exchange_id && this.state.type === "Update") {
      api
        .update_profit_loss_avg_exchange(
          loginUserID,
          idToken,
          business_no_id,
          this.state.exchange_id,
          customer_id,
          amount_in_usd,
          transfer_date,
          exchange_rate,
          amount_in_idr
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                this.setState(
                  {
                    snackBarSuccessOpen: true,
                    successMsg: res.message,
                    isLoading: false,
                    // avg_xchg_rate
                    open_avg_xchg_drawer: false,
                    customer_id: "",
                    amount_in_usd: "",
                    transfer_date: null,
                    selected_customer_drop_value: { name: "", value: "" },
                    exchange_rate: "",
                    amount_in_idr: "",
                  },
                  () => this.componentDidMount()
                );
              } else if (res.code === "601") {
                this.setState({
                  snackBarErrorOpen: true,
                  errorMsg: res.message,
                  isLoading: false,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    } else {
      api
        .add_profit_loss_avg_exchange(
          loginUserID,
          idToken,
          business_no_id,
          customer_id,
          amount_in_usd,
          transfer_date,
          exchange_rate,
          amount_in_idr
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                this.setState(
                  {
                    snackBarSuccessOpen: true,
                    successMsg: res.message,
                    isLoading: false,
                    // avg_xchg_rate
                    open_avg_xchg_drawer: false,
                    customer_id: "",
                    amount_in_usd: "",
                    transfer_date: null,
                    selected_customer_drop_value: { name: "", value: "" },
                    exchange_rate: "",
                    amount_in_idr: "",
                  },
                  () => this.componentDidMount()
                );
              } else if (res.code === "601") {
                this.setState({
                  snackBarErrorOpen: true,
                  errorMsg: res.message,
                  isLoading: false,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    }
  };

  delete_avg_xchg_handler = (data) => {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    if (data) {
      let business_no_id = data.business_no_id;
      let exchange_id = data.id;
      let customer_id = data.customer_id;
      let amount_in_usd = data.amount_in_usd;
      let transfer_date = data.transfer_date;
      let exchange_rate = data.exchange_rate;
      let amount_in_idr = data.amount_in_idr;
      let status = "Deleted";
      api
        .update_profit_loss_avg_exchange(
          loginUserID,
          idToken,
          business_no_id,
          exchange_id,
          customer_id,
          amount_in_usd,
          transfer_date,
          exchange_rate,
          amount_in_idr,
          status
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                window.$("#delete_conformation_modal").modal("hide");

                this.setState(
                  {
                    snackBarSuccessOpen: true,
                    successMsg: res.message,
                    isLoading: false,
                    // avg_xchg_rate
                    open_avg_xchg_drawer: false,
                    customer_id: "",
                    amount_in_usd: "",
                    transfer_date: null,
                    exchange_rate: "",
                    amount_in_idr: "",
                  },
                  () => this.componentDidMount()
                );
              } else if (res.code === "601") {
                window.$("#delete_conformation_modal").modal("hide");
                this.setState({
                  snackBarErrorOpen: true,
                  errorMsg: res.message,
                  isLoading: false,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    }
  };

  add_exchange_conversion_handler = () => {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();

    let transfer_id = this.state.transfer_id;
    let amount_in_usd = this.state.conv_amount_in_usd;
    let conversion_date = this.state.conversion_date;
    let exchange_rate = this.state.conv_xchg_rate;
    let amount_in_idr = this.state.conv_amount_in_idr;
    api
      .add_profit_loss_avg_exchange_conversion(
        loginUserID,
        idToken,
        transfer_id,
        amount_in_usd,
        conversion_date,
        exchange_rate,
        amount_in_idr
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState(
                {
                  snackBarSuccessOpen: true,
                  successMsg: res.message,
                  open_pur_xchg_modal: false,
                  transfer_id: "",
                  conv_amount_in_usd: "",
                  conversion_date: null,
                  conv_xchg_rate: "",
                  conv_amount_in_idr: "",
                },
                () => this.componentDidMount()
              );
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  };

  update_exchange_conversion_handler = () => {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();

    let exchange_conversion_id = this.state.exchange_conversion_id;
    let transfer_id = this.state.transfer_id;
    let amount_in_usd = this.state.conv_amount_in_usd;
    let conversion_date = this.state.conversion_date;
    let exchange_rate = this.state.conv_xchg_rate;
    let amount_in_idr = this.state.conv_amount_in_idr;
    let status = "Active";
    api
      .update_profit_loss_avg_exchange_conversion(
        loginUserID,
        idToken,
        exchange_conversion_id,
        transfer_id,
        amount_in_usd,
        conversion_date,
        exchange_rate,
        amount_in_idr,
        status
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState(
                {
                  snackBarSuccessOpen: true,
                  successMsg: res.message,
                  open_pur_xchg_modal: false,
                  transfer_id: "",
                  conv_amount_in_usd: "",
                  conversion_date: null,
                  conv_xchg_rate: "",
                  conv_amount_in_idr: "",
                },
                () => this.componentDidMount()
              );
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  };

  delete_xchg_conv_handler = (data) => {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    if (data) {
      let exchange_conversion_id = data.id;
      let transfer_id = data.transfer_id;
      let amount_in_usd = data.amount_in_usd;
      let conversion_date = data.conversion_date;
      let exchange_rate = data.exchange_rate;
      let amount_in_idr = data.amount_in_idr;
      let status = "Deleted";
      api
        .update_profit_loss_avg_exchange_conversion(
          loginUserID,
          idToken,
          exchange_conversion_id,
          transfer_id,
          amount_in_usd,
          conversion_date,
          exchange_rate,
          amount_in_idr,
          status
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                this.setState(
                  {
                    snackBarSuccessOpen: true,
                    successMsg: res.message,
                    open_pur_xchg_modal: false,
                    transfer_id: "",
                    conv_amount_in_usd: "",
                    conversion_date: null,
                    conv_xchg_rate: "",
                    conv_amount_in_idr: "",
                    delete_xchg_convertion: false,
                  },
                  () => {
                    this.componentDidMount();
                    window.$("#delete_conformation_modal").modal("hide");
                  }
                );
              } else if (res.code === "601") {
                this.setState(
                  {
                    snackBarErrorOpen: true,
                    errorMsg: res.message,
                  },
                  () => {
                    window.$("#delete_conformation_modal").modal("hide");
                  }
                );
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    }
  };

  render() {
    return (
      <div>
        {!this.state.isLoading && (
          <div className="col-lg-12 p-0">
            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={this.state.snackBarErrorOpen}
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              style={{ width: "450px" }}
            >
              <Alert
                elevation={6}
                variant="filled"
                onClose={() => this.setState({ snackBarErrorOpen: false })}
                severity="error"
                style={{ width: "100%" }}
              >
                {this.state.errorMsg}
              </Alert>
            </Snackbar>
            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              style={{ width: "450px" }}
              open={this.state.snackBarSuccessOpen}
              autoHideDuration={1000}
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
            >
              <Alert
                elevation={6}
                variant="filled"
                onClose={() => this.setState({ snackBarSuccessOpen: false })}
                severity="success"
                style={{ width: "100%" }}
              >
                {this.state.successMsg}
              </Alert>
            </Snackbar>

            <div className="row mt-2">
              <div className="col-lg-11 m-auto">
                <MaterialTable
                  className="MuiTableCell_root"
                  title={
                    <div>
                      {this.state.number_of_items > 0 && (
                        <p style={{ fontWeight: "bold" }}>
                          Average Exchange Rate : &nbsp;
                          {this.toLocaleString(this.state.avg_xchg_rate)}
                        </p>
                      )}
                    </div>
                  }
                  columns={[
                    {
                      title: "Customer",
                      field: "customer_name",
                    },
                    {
                      title: "Amount in USD",
                      field: "amount_in_usd",
                      headerStyle: {
                        textAlign: "right",
                      },
                      cellStyle: {
                        textAlign: "right",
                      },
                      render: (row) => {
                        return this.toLocaleString(row.amount_in_usd);
                      },
                    },

                    {
                      title: "Transfer Date",
                      field: "transfer_date",
                      headerStyle: {},
                      render: (row) => {
                        return localDateFormate(row.transfer_date);
                      },
                    },
                    // {
                    //   title: "Exchange Rate",
                    //   field: 'exchange_rate',
                    //   cellStyle: {
                    //     textAlign: 'right'
                    //   },
                    //   headerStyle: {
                    //     minWidth: 170,
                    //     textAlign: 'right'
                    //   },
                    //   render: row => {
                    //     return this.toLocaleString(row.exchange_rate);
                    //   }
                    // },
                    // {
                    //   title: "Amount in IDR",
                    //   field: 'amount_in_idr',
                    //   headerStyle: {
                    //     minWidth: 160,
                    //     textAlign: 'right'
                    //   },
                    //   cellStyle: {
                    //     textAlign: 'right'
                    //   },
                    //   render: row => {
                    //     return this.toLocaleString(row.amount_in_idr);
                    //   }
                    // },
                    {
                      title: "Action",
                      field: "id",
                      render: (row) => {
                        return (
                          <div>
                            <i
                              className="fa fa-pencil-square-o mr-3"
                              aria-hidden="true"
                              style={{
                                color: config.themeColor,
                                fontSize: 14,
                                fontWeight: 400,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                this.setState({
                                  exchange_id: row.id,
                                  customer_id: row.customer_id,
                                  amount_in_usd: row.amount_in_usd,
                                  transfer_date: row.transfer_date,
                                  exchange_rate: row.exchange_rate,
                                  amount_in_idr: row.amount_in_idr,
                                  selected_customer_drop_value: {
                                    name: row.customer_name,
                                    value: row.customer_id,
                                  },
                                  open_avg_xchg_drawer: true,
                                  type: "Update",
                                });
                              }}
                            />

                            <i
                              className="fa fa-trash-o"
                              aria-hidden="true"
                              style={{
                                color: "red",
                                fontSize: 14,
                                fontWeight: 400,
                                cursor: "pointer",
                              }}
                              data-toggle="modal"
                              data-keyboard="false"
                              data-target="#delete_conformation_modal"
                              data-backdrop="static"
                              onClick={() => this.setState({ deleteData: row })}
                            />
                          </div>
                        );
                      },
                    },
                  ]}
                  detailPanel={(rowData) => {
                    return (
                      <div className="card p-3 m-1 border shadow-sm p-4 mb-2 bg-white">
                        <div className="row">
                          <div className="clearfix col-lg-12">
                            <div className="float-left">
                              {rowData.exchange_conversion_data.length ===
                                0 && <p>No records to display</p>}
                            </div>
                            <div className="float-right">
                              <button
                                className="btn btn-sm btn-info"
                                data-toggle="modal"
                                data-placement="bottom"
                                data-keyboard="false"
                                data-target="#paymentPostingsModal"
                                data-backdrop="static"
                                onClick={() => {
                                  this.setState({
                                    open_pur_xchg_modal: true,
                                    transfer_id: rowData.id,
                                  });
                                }}
                              >
                                Add Convertion Data
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-12">
                          {rowData.exchange_conversion_data.length > 0 && (
                            <Table
                              className="border mt-2"
                              aria-label="simple table"
                            >
                              <TableHead>
                                <TableRow className="bg-light">
                                  <TableCell>Amount in USD</TableCell>
                                  <TableCell>Conversion Date</TableCell>
                                  <TableCell>Exchange Rate</TableCell>
                                  <TableCell>Amount in IDR</TableCell>
                                  <TableCell>Actions</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {rowData.exchange_conversion_data.map(
                                  (e, idx) => (
                                    <TableRow>
                                      <TableCell>
                                        {this.toLocaleString(e.amount_in_usd)}
                                      </TableCell>
                                      <TableCell>
                                        {localDateFormate(e.conversion_date)}
                                      </TableCell>
                                      <TableCell>
                                        {this.toLocaleString(e.exchange_rate)}
                                      </TableCell>
                                      <TableCell>
                                        {this.toLocaleString(e.amount_in_idr)}
                                      </TableCell>
                                      <TableCell>
                                        <i
                                          className="fa fa-pencil-square-o mr-3"
                                          aria-hidden="true"
                                          style={{
                                            color: config.themeColor,
                                            fontSize: 14,
                                            fontWeight: 400,
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            this.setState({
                                              exchange_conversion_id: e.id,
                                              transfer_id: e.transfer_id,
                                              conv_amount_in_usd:
                                                e.amount_in_usd,
                                              conversion_date:
                                                e.conversion_date,
                                              conv_xchg_rate: e.exchange_rate,
                                              conv_amount_in_idr:
                                                e.amount_in_idr,
                                              open_pur_xchg_modal: true,
                                              conversion_type: "Update",
                                            });
                                          }}
                                        />

                                        <i
                                          className="fa fa-trash-o"
                                          aria-hidden="true"
                                          style={{
                                            color: "red",
                                            fontSize: 14,
                                            fontWeight: 400,
                                            cursor: "pointer",
                                          }}
                                          data-toggle="modal"
                                          data-keyboard="false"
                                          data-target="#delete_conformation_modal"
                                          data-backdrop="static"
                                          onClick={() =>
                                            this.setState({
                                              deleteData: e,
                                              delete_xchg_convertion: true,
                                            })
                                          }
                                        />
                                      </TableCell>
                                    </TableRow>
                                  )
                                )}
                              </TableBody>
                            </Table>
                          )}
                        </div>
                      </div>
                    );
                  }}
                  onRowClick={(event, rowData, togglePanel) => togglePanel()}
                  data={this.state.avg_xchg_data}
                  isLoading={this.state.loading}
                  style={{
                    minHeight: 180,
                    fontFamily: "Poppins",
                    overflowY: "auto",
                    boxShadow: "0 0 5px 0 rgba(0, 39, 77, 0.1)",
                  }}
                  options={{
                    paginationType: "stepped",
                    defaultExpanded: true,

                    pageSize: 100,
                    pageSizeOptions: [5, 10, 20, 50, 100, 200],
                    headerStyle: {
                      backgroundColor: "#f9f9f9",
                      maxWidth: 200,
                      fontFamily: "Poppins",
                      fontSize: 14,
                      fontWeight: 400,
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "0.65",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#42352d",
                    },
                    cellStyle: {
                      fontFamily: "Poppins",
                    },
                    search: true,
                    draggable: false,
                    actionsColumnIndex: -1,
                  }}
                  actions={[
                    {
                      icon: "add",
                      tooltip: "Add Transfer Data",
                      isFreeAction: true,
                      iconProps: { style: { color: config.themeColor } },
                      onClick: (event, rowData) =>
                        this.setState({
                          open_avg_xchg_drawer: true,
                          selected_customer_drop_value: { name: "", value: "" },
                        }),
                    },
                  ]}
                />
              </div>
            </div>
            <Drawer
              anchor="right"
              open={this.state.open_avg_xchg_drawer}
              variant="temporary"
              elevation="20"
            >
              <div className="p-2" style={{ width: 600 }}>
                <div className="col-lg-12 p-1 border-bottom">
                  <h5
                    className="label-text pt-0 mb-0"
                    style={{ color: config.themeColor }}
                  >
                    Average Exchange Rate
                  </h5>
                </div>

                <div className=" card border  p-1 rounded-lg mt-2">
                  <div className="row mb-1">
                    <div className="col">
                      <label className="form_label mb-0">Customer</label>
                      <Autocomplete
                        //multiple
                        options={this.state.customer_drop_values}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) => {
                          if (value) {
                            this.setState({
                              customer_id: value.value,
                              selected_customer_drop_value: value,
                              customer_error: false,
                            });
                          } else {
                            this.setState({
                              customer_id: "",
                              selected_customer_drop_value: {
                                value: "",
                                name: "",
                              },
                            });
                          }
                        }}
                        //disableCloseOnSelect
                        name="Customer Names"
                        size="small"
                        value={this.state.selected_customer_drop_value}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Customer Name"
                            variant="outlined"
                            style={{ top: 8 }}
                            fullWidth
                            error={this.state.customer_error}
                          />
                        )}
                        style={{ display: "contents" }}
                      />
                    </div>

                    <div className="col">
                      <label className="form_label mb-0">Amount in USD</label>
                      <TextField
                        name="amount_in_usd"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        onChange={(e) => {
                          const pr = /^\d*(\.\d{0,4})?$/;
                          if (
                            e.target.value === "" ||
                            pr.test(e.target.value)
                          ) {
                            this.setState({
                              amount_in_usd: e.target.value,
                              amount_in_idr: (
                                Number(e.target.value) *
                                Number(this.state.exchange_rate)
                              ).toFixed(3),
                            });
                          }
                        }}
                        value={this.state.amount_in_usd}
                        placeholder="Amount in USD"
                        inputProps={{ style: { textTransform: "uppercase" } }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">USD</InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </div>

                  <div className="row mb-1">
                    <div className="col">
                      <label className="form_label mb-0">Transfer Date</label>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          disableToolbar
                          autoOk={true}
                          inputVariant="outlined"
                          variant="inline"
                          margin="dense"
                          fullWidth
                          format="dd/MM/yyyy"
                          value={this.state.transfer_date}
                          onChange={(date) => {
                            this.setState({
                              transfer_date: date,
                            });
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton>
                                  <EventIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                    {/* <div className='col'>
                    <label className='form_label mb-0'>Exchange Rate From USD to IDR</label>
                    <TextField
                      name='vat'
                      margin='dense'
                      variant='outlined'
                      fullWidth
                      onChange={(e) => {
                        const pr = /^\d*(\.\d{0,9})?$/;
                        if (e.target.value === '' || pr.test(e.target.value)) {
                          this.setState({
                            exchange_rate: e.target.value,
                            amount_in_idr: (Number(this.state.amount_in_usd) * Number(e.target.value)).toFixed(3),
                          });
                        }
                      }}
                      value={this.state.exchange_rate}
                      placeholder='Exchange Rate From USD to IDR'
                      inputProps={{ style: { textTransform: 'uppercase' } }}
                    />
                  </div> */}
                  </div>

                  {/* {(this.state.amount_in_usd && this.state.exchange_rate) &&
  
                  <div className='row'>
                    <div className='col-lg mb-1'>
                      <label className='form_label mb-0'>Amount in IDR</label>
                      <div>{this.toLocaleString(this.state.amount_in_idr)}</div>
                    </div>
                  </div>
                } */}
                </div>

                <div className="row float-right p-3">
                  <button
                    type="button"
                    className="btn btn-light mr-3"
                    onClick={() => {
                      this.setState({
                        open_avg_xchg_drawer: false,
                        // avg_xchg_rate
                        customer_id: "",
                        amount_in_usd: "",
                        transfer_date: null,
                        exchange_rate: "",
                        amount_in_idr: "",
                        selected_customer_drop_value: { name: "", value: "" },
                      });
                    }}
                  >
                    Cancel
                  </button>

                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={this.add_avg_xchg_rate_handler}
                  >
                    Save
                  </button>
                </div>
              </div>
            </Drawer>

            <Dialog
              open={this.state.open_pur_xchg_modal}
              //onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Exchange Conversion"}
              </DialogTitle>
              <DialogContent>
                <div className="row p-2 border rounded">
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-lg-6">
                        <label className="form_label mb-0">Amount in USD</label>
                        <TextField
                          name="conv_amount_in_usd"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          onChange={(e) => {
                            const pr = /^\d*(\.\d{0,4})?$/;
                            if (
                              e.target.value === "" ||
                              pr.test(e.target.value)
                            ) {
                              this.setState({
                                conv_amount_in_usd: e.target.value,
                                conv_amount_in_idr: (
                                  Number(e.target.value) *
                                  Number(this.state.conv_xchg_rate)
                                ).toFixed(3),
                              });
                            }
                          }}
                          value={this.state.conv_amount_in_usd}
                          placeholder="Amount in USD"
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                USD
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                      <div className="col-lg-6">
                        <label className="form_label mb-0">
                          Conversion Date
                        </label>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DatePicker
                            disableToolbar
                            autoOk={true}
                            inputVariant="outlined"
                            variant="inline"
                            margin="dense"
                            fullWidth
                            format="dd/MM/yyyy"
                            value={this.state.conversion_date}
                            onChange={(date) => {
                              this.setState({
                                conversion_date: date,
                              });
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton>
                                    <EventIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6">
                        <label className="form_label mb-0">Exchange Rate</label>
                        <TextField
                          name="conv_xchg_rate"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          onChange={(e) => {
                            const pr = /^\d*(\.\d{0,10})?$/;
                            if (
                              e.target.value === "" ||
                              pr.test(e.target.value)
                            ) {
                              this.setState({
                                conv_xchg_rate: e.target.value,
                                conv_amount_in_idr: (
                                  Number(e.target.value) *
                                  Number(this.state.conv_amount_in_usd)
                                ).toFixed(2),
                              });
                            }
                          }}
                          value={this.state.conv_xchg_rate}
                          placeholder="Exchange Rate"
                          inputProps={{ style: { textTransform: "uppercase" } }}
                        />
                      </div>

                      {this.state.conv_amount_in_usd &&
                        this.state.conv_xchg_rate && (
                          <div className="col-lg-6 mb-1 mt-2">
                            <label className="form_label mb-2">
                              Amount in IDR
                            </label>
                            <div>
                              {this.toLocaleString(
                                this.state.conv_amount_in_idr
                              )}
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() =>
                    this.setState({
                      open_pur_xchg_modal: false,
                      selected_customer_drop_value: { name: "", value: "" },
                      amount_in_usd: "",
                      transfer_date: null,
                    })
                  }
                  color="primary"
                >
                  Cancel
                </Button>
                <Button
                  onClick={
                    this.state.conversion_type === "Update"
                      ? this.update_exchange_conversion_handler
                      : this.add_exchange_conversion_handler
                  }
                  color="secondary"
                  autoFocus
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
            {/**
             *
             *  Delete Barge Costing Alert Message Modal
             *
             */}

            <div
              className="modal fade"
              id="delete_conformation_modal"
              role="dialog"
              tabIndex="-1"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div
                    className="modal-header border-0"
                    style={{ backgroundColor: "#f1f1f1" }}
                  >
                    <h6
                      className="modal-title"
                      style={{ border: "none", padding: 0 }}
                    >
                      <i
                        className="fa fa-exclamation-triangle pr-3"
                        aria-hidden="true"
                        style={{ color: "#e0a51e", marginBottom: "5px" }}
                      />
                      Are you sure to delete ?
                    </h6>
                  </div>
                  <div className="modal-footer border-0">
                    <button
                      className="btn btn-danger mr-3"
                      onClick={() => {
                        this.state.delete_xchg_convertion
                          ? this.delete_xchg_conv_handler(this.state.deleteData)
                          : this.delete_avg_xchg_handler(this.state.deleteData);
                      }}
                    >
                      Delete
                    </button>
                    <button className="btn btn-secondary" data-dismiss="modal">
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
