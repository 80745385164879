import React, { Component } from "react";
import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import api from "../../../api/api";

import { Alert } from "@material-ui/lab";
import { Snackbar } from "@material-ui/core";
import { localDateFormate, pad, toLocaleString } from "../../common/common";
import ViewFiles from "../../common/ViewFiles";
import InvoiceLink from "../../InvoicePdf/InvoiceLink";
import InvoiceForm from "../../common/InvoiceForm";
import PaymentPostings from "../../common/PaymentPostings";
import Loader from "../../common/Loader";
import BusinessApproveButton from "../../Approvals/BusinessApproveButton";

export default class BargeSupplierDemurrageView extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      activeStep: 0,
      selectedBargeId: null,

      demurrage_or_despatch: "Demurrage",

      dmr_from_supplier_barge_demurrage: "",
      billing_currency: "0",
      dmr_from_supplier_demurrage_in_days: "",
      dmr_from_supplier_demurrage_value: "",
      dmr_from_supplier_currency_xchg_rate: "",
      dmr_from_supplier_pph23_account: "0",
      dmr_from_supplier_base_price: "",
      dmr_from_supplier_vat_percent: "10",
      dmr_from_supplier_vat_value: "",
      dmr_from_supplier_pph23_percent: "",
      dmr_from_supplier_pph23_value: "",
      dmr_from_supplier_total: "",
      dmr_from_supplier_ctc: "",
      dmr_from_supplier_ctc_in_local_currency: "",

      NORAnchorageDate: null,

      dmr_to_supplier_time_allowed_for_discharge: "",
      dmr_to_supplier_barge_detention: "",
      dmr_to_supplier_detention_in_days: "",
      dmr_to_supplier_detention_value: "",
      dmr_to_supplier_currency_xchg_rate: "",
      dmr_to_supplier_pph23_account: "0",
      dmr_to_supplier_base_price: "",
      // billing_currency: "0",
      dmr_to_supplier_vat_percent: "10",
      dmr_to_supplier_vat_value: "",
      dmr_to_supplier_pph23_percent: "",
      dmr_to_supplier_pph23_value: "",
      dmr_to_supplier_total: "",
      dmr_to_supplier_ctc: "",
      dmr_to_supplier_ctc_in_local_currency: "",

      currencyData: [],
      totalBarges: [],
      dmr_to_supplier_payment_postings: [],
      dmr_from_supplier_payment_postings: [],
      isLoading: true,
      barge_id: "",
      files: [],
      features: [],
      invoiceDetails: {
        invoice_no: "",
        invoice_date: null,
        ppn: "",
        ppn_date: null,
      },
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (
      !(
        features.includes("134") ||
        features.includes("394") ||
        features.includes("445") ||
        features.includes("97")
      )
    ) {
      window.location.href = "/dashboard";
    }

    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const demurrage_type = this.props.demurrage_type;
    const comboID = this.props.comboID;
    const demurrage_to_supplier_id = comboID.split("$#")[0];

    if (demurrage_type === "company_to_supplier") {
      api
        .get_barge_demurrage_to_supplier(
          loginUserID,
          idToken,
          demurrage_to_supplier_id
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                if (res.barge_demurrage_to_supplier.length > 0) {
                  let days = "0";
                  let hours = "0";
                  let minutes = "0";
                  if (
                    res.barge_demurrage_to_supplier[0].time_at_anchorage_days
                  ) {
                    days =
                      res.barge_demurrage_to_supplier[0].time_at_anchorage_days;
                  }
                  if (
                    res.barge_demurrage_to_supplier[0].time_at_anchorage_hours
                  ) {
                    hours =
                      res.barge_demurrage_to_supplier[0]
                        .time_at_anchorage_hours;
                  }
                  if (
                    res.barge_demurrage_to_supplier[0].time_at_anchorage_minutes
                  ) {
                    minutes =
                      res.barge_demurrage_to_supplier[0]
                        .time_at_anchorage_minutes;
                  }
                  var dmr_to_supplier_total_paid = 0;
                  var dmr_to_supplier_remaining_amount = 0;
                  for (var i of res.barge_demurrage_to_supplier[0]
                    .dmr_to_supplier_payment_postings) {
                    if (i.date_of_payment) {
                      dmr_to_supplier_total_paid += Number(i.amount);
                    }
                  }

                  dmr_to_supplier_remaining_amount =
                    Number(
                      res.barge_demurrage_to_supplier[0].dmr_to_supplier_total
                        ? res.barge_demurrage_to_supplier[0]
                            .dmr_to_supplier_total
                        : 0
                    ) - Number(dmr_to_supplier_total_paid);
                  let invoice_details = {
                    ...this.state.invoiceDetails,
                    invoice_no: res.barge_demurrage_to_supplier[0].invoice_no,
                    invoice_date:
                      res.barge_demurrage_to_supplier[0].invoice_date,
                    ppn: res.barge_demurrage_to_supplier[0].ppn,
                    ppn_date: res.barge_demurrage_to_supplier[0].ppn_date,
                  };
                  this.setState({
                    invoiceDetails: invoice_details,
                    dmr_to_supplier_remaining_amount:
                      dmr_to_supplier_remaining_amount,
                    dmr_to_supplier_total_paid: dmr_to_supplier_total_paid,
                    remainingAmount: dmr_to_supplier_remaining_amount,

                    id: res.barge_demurrage_to_supplier[0].id,
                    costing_no: res.barge_demurrage_to_supplier[0].costing_no,
                    barge_id: res.barge_demurrage_to_supplier[0].barge_id,
                    invoice_file:
                      res.barge_demurrage_to_supplier[0].invoice_file,
                    dmr_to_supplier_barge_detention:
                      res.barge_demurrage_to_supplier[0]
                        .dmr_to_supplier_barge_detention,
                    dmr_to_supplier_base_price:
                      res.barge_demurrage_to_supplier[0]
                        .dmr_to_supplier_base_price,
                    dmr_to_supplier_ctc:
                      res.barge_demurrage_to_supplier[0].dmr_to_supplier_ctc,
                    dmr_to_supplier_ctc_in_local_currency:
                      res.barge_demurrage_to_supplier[0]
                        .dmr_to_supplier_ctc_in_local_currency,
                    dmr_to_supplier_currency_xchg_rate:
                      res.barge_demurrage_to_supplier[0]
                        .dmr_to_supplier_currency_xchg_rate,
                    dmr_to_supplier_detention_in_days:
                      res.barge_demurrage_to_supplier[0]
                        .dmr_to_supplier_detention_in_days,
                    dmr_to_supplier_detention_value:
                      res.barge_demurrage_to_supplier[0]
                        .dmr_to_supplier_detention_value,
                    dmr_to_supplier_pph23_account:
                      res.barge_demurrage_to_supplier[0]
                        .dmr_to_supplier_pph23_account,
                    dmr_to_supplier_pph23_percent:
                      res.barge_demurrage_to_supplier[0]
                        .dmr_to_supplier_pph23_percent,
                    dmr_to_supplier_pph23_value:
                      res.barge_demurrage_to_supplier[0]
                        .dmr_to_supplier_pph23_value,
                    dmr_to_supplier_time_allowed_for_discharge:
                      res.barge_demurrage_to_supplier[0]
                        .dmr_to_supplier_time_allowed_for_discharge,
                    dmr_to_supplier_total:
                      res.barge_demurrage_to_supplier[0].dmr_to_supplier_total,
                    dmr_to_supplier_vat_percent:
                      res.barge_demurrage_to_supplier[0]
                        .dmr_to_supplier_vat_percent,
                    dmr_to_supplier_vat_value:
                      res.barge_demurrage_to_supplier[0]
                        .dmr_to_supplier_vat_value,
                    NORAnchorageDate:
                      res.barge_demurrage_to_supplier[0].nor_anchorage,
                    completionDischargeDate:
                      res.barge_demurrage_to_supplier[0].completion_discharge,
                    dmr_to_supplier_payment_postings:
                      res.barge_demurrage_to_supplier[0]
                        .dmr_to_supplier_payment_postings,

                    created_by: res.barge_demurrage_to_supplier[0].created_by,
                    approval_status: res.barge_demurrage_to_supplier[0]
                      .approval_status
                      ? res.barge_demurrage_to_supplier[0].approval_status
                      : "Pending",
                    approved_by: res.barge_demurrage_to_supplier[0].approved_by,
                    approved_by_name:
                      res.barge_demurrage_to_supplier[0].approved_by_name,
                    approved_date:
                      res.barge_demurrage_to_supplier[0].approved_date,
                    approval_status_2: res.barge_demurrage_to_supplier[0]
                      .approval_status_2
                      ? res.barge_demurrage_to_supplier[0].approval_status_2
                      : "Pending",
                    approved_by_2:
                      res.barge_demurrage_to_supplier[0].approved_by_2,
                    approved_by_name_2:
                      res.barge_demurrage_to_supplier[0].approved_by_name_2,
                    approved_date_2:
                      res.barge_demurrage_to_supplier[0].approved_date_2,

                    files: res.barge_demurrage_to_supplier[0].files
                      ? res.barge_demurrage_to_supplier[0].files
                      : [],

                    timeAtAnchorage:
                      days +
                      " days, " +
                      hours +
                      " hours, " +
                      minutes +
                      " minutes",
                    timeAtAnchorageDays: days,
                    timeAtAnchorageHours: hours,
                    timeAtAnchorageMinutes: minutes,
                    isLoading: false,
                  });
                  if (this.state.barge_id > 0) {
                    const barge_id = this.state.barge_id;

                    let request = {
                      login_user_id: this.Cookie.getCookie("loginUserId"),
                      idtoken: this.Cookie.getIdTokenCookie(),
                      barge_id: barge_id,
                      vendor_type: "Barge",
                    };
                    api
                      .get_business_no_info_for_barge(request)
                      .then((response) => {
                        if (response.status >= 200 && response.status < 300) {
                          response.json().then((res) => {
                            if (res.code === "200") {
                              this.setState({
                                vendor_name: res.vendor_name,
                                mv_name: res.mv_name,
                              });
                            }
                          });
                        }
                      });
                  }
                } else {
                  this.setState({
                    isLoading: false,
                  });
                }
              } else if (res.code === "601") {
                this.setState({
                  snackBarErrorOpen: true,
                  errorMsg: res.message,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    }
    // else if (demurrage_type === "supplier_to_company") {
    //   api
    //     .get_barge_demurrage_from_supplier(
    //       loginUserID,
    //       idToken,
    //       demurrage_from_supplier_id
    //     )
    //     .then((response) => {
    //       if (response.status >= 200 && response.status < 300) {
    //         response.json().then((res) => {
    //           if (res.code === "200") {
    //             if (res.barge_demurrage_from_supplier.length > 0) {
    //               var dmr_from_supplier_total_paid = 0;
    //               var dmr_from_supplier_remaining_amount = 0;
    //               for (var i of res.barge_demurrage_from_supplier[0]
    //                 .dmr_from_supplier_payment_postings) {
    //                 if (i.date_of_payment) {
    //                   dmr_from_supplier_total_paid += Number(i.amount);
    //                 }
    //               }
    //               dmr_from_supplier_remaining_amount =
    //                 Number(
    //                   res.barge_demurrage_from_supplier[0]
    //                     .dmr_from_supplier_total
    //                     ? res.barge_demurrage_from_supplier[0]
    //                       .dmr_from_supplier_total
    //                     : 0
    //                 ) - Number(dmr_from_supplier_total_paid);
    //               let invoice_details = {
    //                 ...this.state.invoiceDetails,
    //                 invoice_no: res.barge_demurrage_from_supplier[0].invoice_no,
    //                 invoice_date:
    //                   res.barge_demurrage_from_supplier[0].invoice_date,
    //                 ppn: res.barge_demurrage_from_supplier[0].ppn,
    //                 ppn_date: res.barge_demurrage_from_supplier[0].ppn_date,
    //               };
    //               this.setState({
    //                 invoiceDetails: invoice_details,
    //                 dmr_from_supplier_remaining_amount:
    //                   dmr_from_supplier_remaining_amount,
    //                 dmr_from_supplier_total_paid: dmr_from_supplier_total_paid,
    //                 remainingAmount: dmr_from_supplier_remaining_amount,
    //                 id: res.barge_demurrage_from_supplier[0].id,
    //                 costing_no: res.barge_demurrage_from_supplier[0].costing_no,
    //                 barge_id: res.barge_demurrage_from_supplier[0].barge_id,
    //                 invoice_file:
    //                   res.barge_demurrage_from_supplier[0].invoice_file,
    //                 billing_currency:
    //                   res.barge_demurrage_from_supplier[0].billing_currency,
    //                 dmr_from_supplier_barge_demurrage:
    //                   res.barge_demurrage_from_supplier[0]
    //                     .dmr_from_supplier_barge_demurrage,
    //                 dmr_from_supplier_base_price:
    //                   res.barge_demurrage_from_supplier[0]
    //                     .dmr_from_supplier_base_price,
    //                 dmr_from_supplier_ctc:
    //                   res.barge_demurrage_from_supplier[0]
    //                     .dmr_from_supplier_ctc,
    //                 dmr_from_supplier_ctc_in_local_currency:
    //                   res.barge_demurrage_from_supplier[0]
    //                     .dmr_from_supplier_ctc_in_local_currency,
    //                 dmr_from_supplier_currency_xchg_rate:
    //                   res.barge_demurrage_from_supplier[0]
    //                     .dmr_from_supplier_currency_xchg_rate,
    //                 dmr_from_supplier_demurrage_in_days:
    //                   res.barge_demurrage_from_supplier[0]
    //                     .dmr_from_supplier_demurrage_in_days,
    //                 dmr_from_supplier_demurrage_value:
    //                   res.barge_demurrage_from_supplier[0]
    //                     .dmr_from_supplier_demurrage_value,
    //                 dmr_from_supplier_pph23_account:
    //                   res.barge_demurrage_from_supplier[0]
    //                     .dmr_from_supplier_pph23_account,
    //                 dmr_from_supplier_pph23_percent:
    //                   res.barge_demurrage_from_supplier[0]
    //                     .dmr_from_supplier_pph23_percent,
    //                 dmr_from_supplier_pph23_value:
    //                   res.barge_demurrage_from_supplier[0]
    //                     .dmr_from_supplier_pph23_value,
    //                 dmr_from_supplier_total:
    //                   res.barge_demurrage_from_supplier[0]
    //                     .dmr_from_supplier_total,
    //                 dmr_from_supplier_vat_percent:
    //                   res.barge_demurrage_from_supplier[0]
    //                     .dmr_from_supplier_vat_percent,
    //                 dmr_from_supplier_vat_value:
    //                   res.barge_demurrage_from_supplier[0]
    //                     .dmr_from_supplier_vat_value,
    //                 dmr_from_supplier_payment_postings:
    //                   res.barge_demurrage_from_supplier[0]
    //                     .dmr_from_supplier_payment_postings,

    //                 created_by: res.barge_demurrage_from_supplier[0].created_by,
    //                 approval_status: res.barge_demurrage_from_supplier[0]
    //                   .approval_status
    //                   ? res.barge_demurrage_from_supplier[0].approval_status
    //                   : "Pending",
    //                 approved_by:
    //                   res.barge_demurrage_from_supplier[0].approved_by,
    //                 approved_by_name:
    //                   res.barge_demurrage_from_supplier[0].approved_by_name,
    //                 approved_date:
    //                   res.barge_demurrage_from_supplier[0].approved_date,
    //                 approval_status_2: res.barge_demurrage_from_supplier[0]
    //                   .approval_status_2
    //                   ? res.barge_demurrage_from_supplier[0].approval_status_2
    //                   : "Pending",
    //                 approved_by_2:
    //                   res.barge_demurrage_from_supplier[0].approved_by_2,
    //                 approved_by_name_2:
    //                   res.barge_demurrage_from_supplier[0].approved_by_name_2,
    //                 approved_date_2:
    //                   res.barge_demurrage_from_supplier[0].approved_date_2,
    //                 invoice_date:
    //                   res.barge_demurrage_from_supplier[0].invoice_date,
    //                 invoice_no: res.barge_demurrage_from_supplier[0].invoice_no,
    //                 files: res.barge_demurrage_from_supplier[0].files
    //                   ? res.barge_demurrage_from_supplier[0].files
    //                   : [],
    //                 isLoading: false,
    //               });
    //               const barge_id = this.state.barge_id;
    //               let request = {
    //                 login_user_id: this.Cookie.getCookie("loginUserId"),
    //                 idtoken: this.Cookie.getIdTokenCookie(),
    //                 barge_id: barge_id,
    //                 vendor_type: "Barge",
    //               };
    //               api
    //                 .get_business_no_info_for_barge(request)
    //                 .then((response) => {
    //                   if (response.status >= 200 && response.status < 300) {
    //                     response.json().then((res) => {
    //                       if (res.code === "200") {
    //                         this.setState({
    //                           vendor_name: res.vendor_name,
    //                           mv_name: res.mv_name,
    //                         });
    //                       }
    //                     });
    //                   }
    //                 });
    //             } else {
    //               this.setState({
    //                 isLoading: false,
    //               });
    //             }
    //           } else if (res.code === "601") {
    //             this.setState({
    //               snackBarErrorOpen: true,
    //               errorMsg: res.message,
    //             });
    //           } else if (res.code === "607") {
    //             window.location.href = "/logout";
    //           }
    //         });
    //       }
    //     });

    // }
    await api.get_barges(loginUserID, idToken).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            if (res.barges) {
              let newData = [];
              var result = res.barges.reduce(function (r, a) {
                r[a.id] = r[a.id] || [];
                r[a.id].push(a);
                return r;
              }, Object.create(null));
              for (let l = 0; l < Object.keys(result).length; l++) {
                newData.push({
                  new_barges: result[Object.keys(result)[l]],
                });
              }
              let purchase_contracts_barges = newData;
              if (newData.length === 0) {
                this.setState({
                  snackBarErrorOpen: true,
                  errorMsg: "No records to display",
                });
              }
              this.setState({
                totalBarges: purchase_contracts_barges,
                id: res.barges[0].id,
                //isLoading: false
              });
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          } else if (res.code === "601") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
  }

  toLocaleString(value) {
    return value ? Number(value).toLocaleString() : 0;
  }

  updateInvoice = (data) => {
    let request = {
      ...data,
      table_name:
        this.props.demurrage_type === "company_to_supplier"
          ? "barge_demurrage_to_supplier"
          : "barge_demurrage_from_supplier",
    };
    api.update_invoice_details(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.componentDidMount();
            this.setState({
              invoiceDialog: false,
            });
          }
        });
      }
    });
  };

  renderPaymentPostings = (data, dmr_type) => {
    const {
      id,
      dmr_from_supplier_payment_postings = [],
      dmr_to_supplier_payment_postings = [],
      dmr_from_supplier_remaining_amount = 0,
      dmr_to_supplier_remaining_amount = 0,
      dmr_from_supplier_currency_xchg_rate = 1,
      dmr_to_supplier_currency_xchg_rate = 1,
      approval_status = "Pending",
      invoiceDetails: { invoice_no = null } = {},
    } = data;

    let payment_related_data = {
      paymentTitle: dmr_type,
      payment_posting_type: dmr_type,
      invoice_no: invoice_no,
      outstanding_amount:
        this.props.demurrage_type === "company_to_supplier"
          ? dmr_to_supplier_remaining_amount
          : dmr_from_supplier_remaining_amount,
      billing_currency:
        config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL"
          ? "USD"
          : "IDR",
      currency_xchg_rate:
        this.props.demurrage_type === "company_to_supplier"
          ? dmr_to_supplier_currency_xchg_rate
          : dmr_from_supplier_currency_xchg_rate,
      costing_id: id,
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
    };

    let access_details = {
      costing_approval: approval_status,
      add_payment: "154",
      view_payment: "155",
      update_payment: "156",
      delete_payment: "157",
      approver: "396",
      approver2: "446",
    };

    return (
      <PaymentPostings
        payments={
          this.props.demurrage_type === "company_to_supplier"
            ? dmr_to_supplier_payment_postings
            : dmr_from_supplier_payment_postings
        }
        costingType={
          this.props.demurrage_type === "company_to_supplier"
            ? "Barge Demurrage To Supplier"
            : "Barge Demurrage From Supplier"
        }
        data={payment_related_data}
        access_details={access_details}
        callbackFn={() => {
          this.componentDidMount();
        }}
      />
    );
  };

  renderInvoiceLink = () => {
    const data = this.state.totalBarges.map((e) =>
      e.new_barges.filter((obj) => obj.id === this.state.barge_id)
    );
    const { costing_no, vendor_name, mv_name } = this.state;

    const Grand_total = (
      parseFloat(
        this.state.dmr_from_supplier_total
          ? this.state.dmr_from_supplier_total
          : this.state.dmr_to_supplier_total
          ? this.state.dmr_to_supplier_total
          : 0
      ) +
      parseFloat(
        this.state.dmr_to_supplier_pph23_value
          ? this.state.dmr_to_supplier_pph23_value
          : this.state.dmr_from_supplier_pph23_value
          ? this.state.dmr_from_supplier_pph23_value
          : 0
      )
    ).toFixed(2);
    const invoiceObject = {
      costing_id: costing_no,
      to: vendor_name,
      business_no: mv_name,
      costing_name: "Supplier Demurrage",
      invoice_no: this.state.invoiceDetails.invoice_no,
      invoice_date: localDateFormate(this.state.invoiceDetails.invoice_date),
      bargeDetails: data ? data.flat() : [],
      billing_currency: this.state.billing_currency,
      total_payable_amount: Grand_total,
      purchase_type: "FOB Barge",
      description: [
        {
          qty: this.state.dmr_to_supplier_time_allowed_for_discharge
            ? this.state.dmr_to_supplier_time_allowed_for_discharge
            : this.state.dmr_from_supplier_demurrage_in_days
            ? this.state.dmr_from_supplier_demurrage_in_days
            : 0,

          adjustedPrice: this.state.dmr_to_supplier_barge_detention
            ? this.state.dmr_to_supplier_barge_detention
            : this.state.dmr_from_supplier_barge_demurrage
            ? this.state.dmr_from_supplier_barge_demurrage
            : 0,

          desc: `${
            this.state.dmr_to_supplier_currency_xchg_rate > 1
              ? `Vendor Demurrage @ ${parseFloat(
                  this.state.dmr_to_supplier_currency_xchg_rate
                )}`
              : this.state.dmr_from_supplier_currency_xchg_rate > 1
              ? `Supplier Demurrage @ ${parseFloat(
                  this.state.dmr_from_supplier_currency_xchg_rate
                )}`
              : " "
          }`,
          amount: this.state.dmr_to_supplier_base_price
            ? this.state.dmr_to_supplier_base_price
            : this.state.dmr_from_supplier_base_price
            ? this.state.dmr_from_supplier_base_price
            : 0,
        },
        ...(!(
          config.company === "SRPL" ||
          config.company === "AVS" ||
          config.company === "PRPL"
        ) &&
        (this.state.dmr_to_supplier_pph23_value ||
          this.state.dmr_from_supplier_pph23_value)
          ? [
              {
                qty: " ",
                adjustedPrice: " ",
                desc: "PPH",
                amount: `( ${
                  this.state.dmr_to_supplier_pph23_value
                    ? this.state.dmr_to_supplier_pph23_value
                    : this.state.dmr_from_supplier_pph23_value
                } )`,
              },
            ]
          : []),
        {
          qty: " ",
          adjustedPrice: " ",
          desc: "VAT",
          amount: `${
            this.state.dmr_to_supplier_vat_value
              ? this.state.dmr_to_supplier_vat_value
              : this.state.dmr_from_supplier_vat_value
              ? this.state.dmr_from_supplier_vat_value
              : 0
          }`,
        },
      ],
    };
    return (
      <InvoiceLink
        data={invoiceObject}
        fileName={costing_no}
        demurrage_name={this.state.demurrage_or_despatch}
      />
    );
  };

  render() {
    const comboID = this.props.comboID;
    const costing_id = comboID.split("$#")[0];
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div className="container bg-white p-0" style={{ minHeight: "100vh" }}>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <div className="clearfix content-header border-bottom">
            <div className="row">
              <div className="col-lg-10 text-left pl-0">
                <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                  {`Barge Demurrage - ${this.state.costing_no}`}
                  <span
                    className={
                      this.state.approval_status === "Pending"
                        ? "type_2"
                        : "type_1"
                    }
                    style={{ padding: 8, marginLeft: 16 }}
                  >
                    {this.state.approval_status}
                  </span>
                </h4>
              </div>
              {this.state.approval_status === "Pending" &&
                this.Cookie.getCookie("loginUserId") !==
                  this.state.created_by &&
                this.state.approved_by !==
                  this.Cookie.getCookie("loginUserId") &&
                (this.state.features.includes("394") ||
                  this.state.features.includes("445") ||
                  this.state.features.includes("97")) && (
                  <BusinessApproveButton
                    costingID={costing_id}
                    costingType={
                      this.props.demurrage_type === "company_to_supplier"
                        ? "Barge Demurrage to Supplier"
                        : "Barge Demurrage From Supplier"
                    }
                    accessInfo={{
                      ...this.state,
                      approver_1: "394",
                      approver_2: "445",
                    }}
                    callbackFn={() => this.componentDidMount()}
                  />
                )}
              <div className="col-lg-1 pl-0 float-right">
                {this.renderInvoiceLink()}
              </div>
            </div>
          </div>
          <div className="drawer-section">
            <div className="card p-3">
              {this.props.demurrageData && (
                <div className="row">
                  <div className="table-responsive">
                    <table className="table table-bordered table-sm">
                      <thead className="table-header">
                        <tr>
                          <th nowrap="true">Barge ID</th>
                          <th nowrap="true">Barge Nomination</th>
                          <th nowrap="true">Supplier</th>
                          <th nowrap="true" className="text-right">
                            {this.props.demurrage_type === "company_to_supplier"
                              ? "Total Payable"
                              : "Receivable"}
                          </th>
                          <th nowrap="true" className="text-right">
                            {this.props.demurrage_type === "company_to_supplier"
                              ? "Paid"
                              : "Received"}
                          </th>
                          <th nowrap="true" className="text-right">
                            Remaining
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <a
                              href={
                                "/view-barge/" +
                                btoa(this.props.demurrageData.barge_id)
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ color: config.themeColor }}
                            >
                              {pad(this.props.demurrageData.barge_id)}
                            </a>
                          </td>
                          <td nowrap="true">
                            {this.props.demurrageData.barge_nomination}
                          </td>
                          <td nowrap="true">
                            {this.props.demurrageData.supplier_name}
                          </td>
                          <td className="text-right" nowrap="true">
                            {toLocaleString(
                              this.props.demurrageData.total_payable
                            )}
                          </td>
                          <td className="text-right" nowrap="true">
                            {this.toLocaleString(this.props.demurrageData.paid)}
                          </td>
                          <td className="text-right" nowrap="true">
                            {this.toLocaleString(
                              this.props.demurrageData.Remaining_amount
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              )}

              {/* {this.props.demurrage_type === "supplier_to_company" && (
                <>
                  <div>
                    <hr />
                    <div className="col shadow-sm p-0 mb-2 bg-white border">
                      <div className="table-responsive">
                        <table className="table table-sm table-bordered mb-0">
                          <thead className="table-header">
                            <tr>
                              <th className="text-center">Description</th>
                              <th className="text-right">Demurrage in days</th>
                              <th className="text-right">Unit Price</th>
                              <th className="text-right">Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            <>
                              <tr>
                                <td>
                                  Supplier Demurrage
                                  {!(
                                    config.company === "SRPL" ||
                                    config.company === "AVS" ||
                                    config.company === "PRPL"
                                  ) && this.state.billing_currency !== "IDR" &&
                                    this.state.dmr_from_supplier_currency_xchg_rate > 1 && (
                                      <div>
                                        Exchange Rate @ {parseFloat(this.state.dmr_from_supplier_currency_xchg_rate).toFixed(2)}
                                      </div>
                                    )}
                                </td>
                                <td className="text-right">  {this.state.dmr_from_supplier_demurrage_in_days
                                  ? (this.state.dmr_from_supplier_demurrage_in_days)
                                  : "-"}</td>
                                <td className="text-right">
                                  {this.state.dmr_from_supplier_barge_demurrage
                                    ? (
                                      this.state.dmr_from_supplier_barge_demurrage
                                    )
                                    : "-"}{" "}
                                </td>
                                <td className="text-right">{this.state.dmr_from_supplier_base_price ?
                                  (this.state.dmr_from_supplier_base_price) : "_"
                                }</td>
                              </tr>
                              {this.state.dmr_from_supplier_vat_value
                                &&
                                <tr>
                                  <td>VAT @ {this.state.dmr_from_supplier_vat_percent
                                    ? this.state.dmr_from_supplier_vat_percent
                                    : "-"} %</td>
                                  <td></td>
                                  <td></td>
                                  <td className="text-right">{this.state.dmr_from_supplier_vat_value
                                    ? (
                                      this.state.dmr_from_supplier_vat_value
                                    )
                                    : "-"}</td>
                                </tr>}

                              {!(
                                config.company === "SRPL" ||
                                config.company === "AVS" ||
                                config.company === "PRPL"
                              ) && this.state.dmr_from_supplier_pph23_value
                                &&
                                <tr>
                                  <td>PPH23 @ {this.state.dmr_from_supplier_pph23_percent ? (
                                    this.state.dmr_from_supplier_pph23_percent) : 0} %</td>
                                  <td></td>
                                  <td></td>
                                  <td className="text-right">{this.state.dmr_from_supplier_pph23_value
                                    ? (
                                      this.state
                                        .dmr_from_supplier_pph23_value
                                    )
                                    : "-"}</td>
                                </tr>
                              }
                              <tr>
                                <td colSpan={3} className="text-right ">
                                  {" "}
                                  Payable to Vendor :{" "}
                                </td>
                                <td style={{ fontWeight: "bold" }} className="text-right">
                                  {`${config.company === "SRPL" ||
                                    config.company === "PRPL" ||
                                    config.company === "AVS"
                                    ? "USD"
                                    : "IDR"
                                    } ${this.state.dmr_from_supplier_total
                                      ? (
                                        this.state.dmr_from_supplier_total
                                      )
                                      : 0}`}
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={3} className="text-right">
                                  Total Receivable
                                </td>
                                <td style={{ fontWeight: 'bold' }} className="text-right">
                                  {config.company === "SRPL" || config.company === "AVS" || config.company === "PRPL"
                                    ? "USD"
                                    : "IDR"} {this.state.dmr_from_supplier_total ? (this.state.dmr_from_supplier_total) : ""}
                                </td>
                              </tr>
                              <tr>
                                {this.state.dmr_from_supplier_total_paid ?
                                  <>
                                    <td colSpan={3} className="text-right">
                                      Amount Received
                                    </td>
                                    <td style={{ fontWeight: 'bold' }} className="text-right">
                                      {config.company === "SRPL" || config.company === "AVS" || config.company === "PRPL"
                                        ? "USD"
                                        : "IDR"} {toLocaleString(this.state.dmr_from_supplier_total_paid)}
                                    </td>
                                  </> : ""}
                              </tr>
                            </>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="card p-0 border mt-3">
                      <div className="card-header section_header">
                        <h5>Payments</h5>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-lg-4 mb-3">
                            <label className="contract_display_header_label">
                              Total Receivable Amount
                            </label>
                            <div className="contract_display_header_value">
                              {this.state.dmr_from_supplier_total
                                ? this.toLocaleString(
                                  this.state.dmr_from_supplier_total
                                )
                                : 0}{" "}
                              {config.company === "SRPL" || config.company === "AVS" ||
                                config.company === "PRPL"
                                ? "USD"
                                : "IDR"}
                            </div>
                          </div>
                          <div className="col-lg-4 mb-3">
                            <label className="contract_display_header_label">
                              Amount Received
                            </label>
                            <div className="contract_display_header_value">
                              {this.state.dmr_from_supplier_total_paid
                                ? this.toLocaleString(
                                  this.state.dmr_from_supplier_total_paid
                                )
                                : 0}{" "}
                              {config.company === "SRPL" || config.company === "AVS" ||
                                config.company === "PRPL"
                                ? "USD"
                                : "IDR"}
                            </div>
                          </div>
                          <div className="col-lg-4 mb-3">
                            <label className="contract_display_header_label">
                              Remaining Amount
                            </label>
                            <div className="contract_display_header_value">
                              {this.state.remainingAmount <= 0
                                ? 0
                                : this.toLocaleString(
                                  this.state.remainingAmount
                                )}{" "}
                              {config.company === "SRPL" || config.company === "AVS" ||
                                config.company === "PRPL"
                                ? "USD"
                                : "IDR"}
                            </div>
                          </div>
                        </div>
                        <InvoiceForm
                          open={false}
                          costingID={costing_id}
                          data={this.state.invoiceDetails}
                          callbackFn={(data) => this.updateInvoice(data)}
                        />

                        {(this.state.features.includes("263") ||
                          this.state.features.includes("97")) && (
                            <div>
                              {this.renderPaymentPostings(
                                this.state,
                                "Barge Demurrage From Supplier"
                              )}
                            </div>
                          )}
                      </div>
                      <ViewFiles
                        {...this.props}
                        type="Costings"
                        invoice_file={this.state.invoice_file}
                        files_data={this.state.files}
                      />
                    </div>
                  </div>
                  <div className="col shadow-sm p-0 mb-2 bg-white border">
                    <div className="table-responsive">
                      <table className="table table-sm table-bordered">
                        <thead className="table-header">
                          <tr>
                            <th className="text-center">Approval Type</th>
                            <th className="text-center">Approved by</th>
                            <th className="text-center">Approved On</th>
                          </tr>
                        </thead>
                        <tbody>
                          <>
                            <tr>
                              <td className="text-center">Business</td>
                              <td className="text-center">
                                <div> {this.state.approved_by_name}</div>
                              </td>
                              <td className="text-center">
                                {localDateFormate(this.state.approved_date)}
                              </td>
                            </tr>
                            <tr>
                              <td className="text-center">Financial</td>
                              <td className="text-center">
                                {this.state.approved_by_name_2}
                              </td>
                              <td className="text-center">
                                {localDateFormate(this.state.approved_date_2)}
                              </td>
                            </tr>
                          </>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              )} */}

              {this.props.demurrage_type === "company_to_supplier" && (
                <>
                  <div>
                    <div className="col shadow-sm p-0 mb-2 bg-white border">
                      <div className="table-responsive">
                        <table className="table table-sm table-bordered mb-0">
                          <thead className="table-header">
                            <tr>
                              <th className="text-center">Description</th>
                              <th className="text-center">Supplier Name</th>
                              <th className="text-right">Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            <>
                              <tr>
                                <td> Completion Discharge</td>
                                <td></td>
                                <td className="text-right">
                                  {this.state.completionDischargeDate
                                    ? localDateFormate(
                                        this.state.completionDischargeDate
                                      )
                                    : ""}
                                </td>
                              </tr>

                              <tr>
                                <td> NOR Anchorage</td>
                                <td></td>
                                <td className="text-right">
                                  {this.state.NORAnchorageDate
                                    ? localDateFormate(
                                        this.state.NORAnchorageDate
                                      )
                                    : ""}
                                </td>
                              </tr>

                              <tr>
                                <td> Loading Jetty</td>
                                <td></td>
                                <td className="text-right">
                                  {this.state.loading_jetty}
                                </td>
                              </tr>

                              <tr>
                                <td> Time at Anchorage</td>
                                <td></td>
                                <td className="text-right">
                                  {this.state.timeAtAnchorage}
                                </td>
                              </tr>
                              <tr>
                                <td> Time allowed for Discharge</td>
                                <td></td>
                                <td className="text-right">
                                  {
                                    this.state
                                      .dmr_to_supplier_time_allowed_for_discharge
                                  }
                                </td>
                              </tr>
                            </>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <hr />
                    <div className="col shadow-sm p-0 mb-2 bg-white border">
                      <div className="table-responsive">
                        <table className="table table-sm table-bordered mb-0">
                          <thead className="table-header">
                            <tr>
                              <th className="text-center">Description</th>
                              <th className="text-right">Demurrage in Days</th>
                              <th className="text-right">Unit Price</th>
                              <th className="text-right">Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            <>
                              <tr>
                                <td>
                                  Barge Supplier Demurrage
                                  {!(
                                    config.company === "SRPL" ||
                                    config.company === "AVS" ||
                                    config.company === "PRPL"
                                  ) &&
                                    !this.state.billing_currency !== "IDR" &&
                                    this.state
                                      .dmr_to_supplier_currency_xchg_rate >
                                      1 && (
                                      <div>
                                        Exchange Rate @
                                        {parseFloat(
                                          this.state
                                            .dmr_to_supplier_currency_xchg_rate
                                        ).toFixed(2)}
                                      </div>
                                    )}
                                </td>
                                <td className="text-right">
                                  {parseFloat(
                                    this.state.dmr_to_supplier_detention_in_days
                                  ).toFixed(2)}
                                </td>
                                <td className="text-right">
                                  {" "}
                                  {this.toLocaleString(
                                    this.state.dmr_to_supplier_barge_detention
                                  )}{" "}
                                </td>
                                <td className="text-right">
                                  {parseFloat(
                                    this.state.dmr_to_supplier_base_price
                                  ).toFixed(2)}
                                </td>
                              </tr>
                              {this.state.dmr_to_supplier_vat_value && (
                                <tr>
                                  <td>
                                    VAT @{" "}
                                    {this.state.dmr_to_supplier_vat_percent} %
                                  </td>
                                  <td></td>
                                  <td></td>
                                  <td className="text-right">
                                    {this.state.dmr_to_supplier_vat_value}
                                  </td>
                                </tr>
                              )}

                              {!(
                                config.company === "SRPL" ||
                                config.company === "AVS" ||
                                config.company === "PRPL"
                              ) &&
                                parseFloat(
                                  this.state.dmr_to_supplier_pph23_value
                                ) !== 0 && (
                                  <tr>
                                    <td>
                                      PPH23 @{" "}
                                      {this.state.dmr_to_supplier_pph23_percent}{" "}
                                      %
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td className="text-right">
                                      {parseFloat(
                                        this.state.dmr_to_supplier_pph23_value
                                      ).toFixed(2)}
                                    </td>
                                  </tr>
                                )}
                              <tr>
                                <td
                                  colSpan={3}
                                  style={{ fontWeight: "bold" }}
                                  className="text-right "
                                >
                                  {" "}
                                  Payable to Vendor :{" "}
                                </td>
                                <td
                                  style={{ fontWeight: "bold" }}
                                  className="text-right"
                                >
                                  {`${
                                    config.company === "SRPL" ||
                                    config.company === "PRPL" ||
                                    config.company === "AVS"
                                      ? "USD"
                                      : "IDR"
                                  } ${
                                    this.state.dmr_to_supplier_total
                                      ? this.state.dmr_to_supplier_total
                                      : "_"
                                  }`}
                                </td>
                              </tr>
                            </>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="card p-0 border mt-3">
                      <div className="card-header section_header">
                        <h5>Payments</h5>
                      </div>
                      <div className="card-body">
                        <InvoiceForm
                          open={false}
                          costingID={costing_id}
                          data={this.state.invoiceDetails}
                          callbackFn={(data) => this.updateInvoice(data)}
                        />
                        <div className="row">
                          <div className="col-lg-4 mb-3">
                            <label className="contract_display_header_label">
                              Total Payable Amount
                            </label>
                            <div className="contract_display_header_value">
                              {this.state.dmr_to_supplier_total
                                ? this.toLocaleString(
                                    this.state.dmr_to_supplier_total
                                  )
                                : 0}{" "}
                              {config.company === "SRPL" ||
                              config.company === "AVS" ||
                              config.company === "PRPL"
                                ? "USD"
                                : "IDR"}
                            </div>
                          </div>
                          <div className="col-lg-4 mb-3">
                            <label className="contract_display_header_label">
                              Amount Paid
                            </label>
                            <div className="contract_display_header_value">
                              {this.state.dmr_to_supplier_total_paid
                                ? this.toLocaleString(
                                    this.state.dmr_to_supplier_total_paid
                                  )
                                : 0}{" "}
                              {config.company === "SRPL" ||
                              config.company === "AVS" ||
                              config.company === "PRPL"
                                ? "USD"
                                : "IDR"}
                            </div>
                          </div>
                          <div className="col-lg-4 mb-3">
                            <label className="contract_display_header_label">
                              Remaining Amount
                            </label>
                            <div className="contract_display_header_value">
                              {this.state.remainingAmount <= 0
                                ? 0
                                : this.toLocaleString(
                                    this.state.remainingAmount
                                  )}{" "}
                              {config.company === "SRPL" ||
                              config.company === "AVS" ||
                              config.company === "PRPL"
                                ? "USD"
                                : "IDR"}
                            </div>
                          </div>
                        </div>
                        {(this.state.features.includes("263") ||
                          this.state.features.includes("97")) && (
                          <div>
                            {this.renderPaymentPostings(
                              this.state,
                              "Barge Demurrage To Supplier"
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    {(this.state.features.includes("530") ||
                      this.state.features.includes("97")) && (
                      <div className="card col-lg-12 border mt-3 mb-3">
                        <ViewFiles
                          {...this.props}
                          type="Costings"
                          invoice_file={this.state.invoice_file}
                          files_data={this.state.files}
                        />
                      </div>
                    )}
                  </div>
                  <div className="col shadow-sm p-0 mb-2 bg-white border">
                    <div className="table-responsive">
                      <table className="table table-sm table-bordered">
                        <thead className="table-header">
                          <tr>
                            <th className="text-center">Approval Type</th>
                            <th className="text-center">Approved by</th>
                            <th className="text-center">Approved On</th>
                          </tr>
                        </thead>
                        <tbody>
                          <>
                            <tr>
                              <td className="text-center">Business</td>
                              <td className="text-center">
                                <div> {this.state.approved_by_name}</div>
                              </td>
                              <td className="text-center">
                                {localDateFormate(this.state.approved_date)}
                              </td>
                            </tr>
                            <tr>
                              <td className="text-center">Financial</td>
                              <td className="text-center">
                                {this.state.approved_by_name_2}
                              </td>
                              <td className="text-center">
                                {localDateFormate(this.state.approved_date_2)}
                              </td>
                            </tr>
                          </>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <footer className="row  modal-footer justify-content-end bottom_buttons_section">
            <button
              type="button"
              className="drawer_button drawer_text drawer_calculate_button drawer_calculate_adjustment"
              name="submit"
              onClick={() => {
                this.props.returnCallbackDrawerClose(false);
              }}
            >
              Close
            </button>
            {(this.state.approval_status === "pending"
              ? this.state.features.includes("134") ||
                this.state.features.includes("394") ||
                this.state.features.includes("97")
              : this.state.approval_status_2 === "Pending"
              ? this.Cookie.getCookie("loginUserId") !==
                  this.state.approved_by ||
                this.state.features.includes("445") ||
                this.state.features.includes("97")
              : this.state.features.includes("97")) &&
              this.state.approval_status === "pending" && (
                <button
                  type="button"
                  className="drawer_button drawer_text"
                  onClick={() => this.props.returnEditCallback(true)}
                >
                  Edit
                </button>
              )}
          </footer>
        </div>
      );
    }
  }
}
