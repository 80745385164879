import React from "react";

import Header from "../../common/Header";
import CookieHandler from "../../common/CookieHandler";

import api from "../../../api/api";
import config from "../../../config/config";
import SideBar from "../../common/SideBar";

import exportToExcel from "../../Export_To_Excel";
import MaterialTable from "material-table";
import VendorAdvance from "../../../images/vendor-advance.png";
import downPayment from "../../../images/down-payment.png";
import { Alert } from "@material-ui/lab";

import {
  Snackbar,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  Slide,
  Button,
} from "@material-ui/core";
import { toLocaleString } from "../../common/common";
import Loader from "../../common/Loader";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class VendorAdvanceContractList extends React.Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      vendorType: "0",
      vendorAdvanceContracts: [],
      data: [],
      loading: true,
      isLoading: false,
      errorMessage: "",
      successMessage: "",
      features: [],
      advanceContracts: [],
      filters: 0,
    };
  }

  componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }

    const features = this.Cookie.getCookie("features");
    this.setState({ features: features ? features : [] }, () => {
      if (
        !(
          this.state.features.includes("2") ||
          this.state.features.includes("97")
        )
      ) {
        window.location.href = "/dashboard";
      }
    });
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const status = "Active";
    api
      .getVendorsAdvanceContracts(loginUserID, idToken, status)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.vendor_adv_contracts) {
                this.setState(
                  {
                    vendorAdvanceContracts: res.vendor_adv_contracts,
                    loading: false,
                  },
                  () => this.constractFilters()
                );
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
                isLoading: false,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
    document.title = config.documentTitle + "Vendor Advance Contract List";
  }

  constractFilters = () => {
    const { vendorAdvanceContracts, filters } = this.state;

    if (filters === 0) {
      this.setState({
        advanceContracts: vendorAdvanceContracts.filter(
          (e) => e.contract_type !== "Down Payment"
        ),
      });
    } else {
      this.setState({
        advanceContracts: vendorAdvanceContracts.filter(
          (e) => e.contract_type === "Down Payment"
        ),
      });
    }
  };

  convertExcelHandler = (data, filename) => {
    var arrData = typeof data != "object" ? JSON.parse(data) : data;
    let Modified = arrData.map((obj) => {
      return {
        "Contract No": obj.contract_no,
        "Vendor Name": obj.vendor_contract_name,
        "Supplier Ref No": obj.supplier_ref_no,
        "Advance Amount": obj.advance_amount,
        "Contract Date": obj.contract_date_formatted,
      };
    });
    exportToExcel(Modified, filename);
  };

  render() {
    return (
      <div id="wraper">
        <SideBar />
        <div className="content">
          <Header />
          <div className="clearfix content-header">
            <div className="row">
              <div className="col-sm text-left pl-0">
                <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                  Vendor Advance Contracts
                </h4>
              </div>
              {!this.state.isLoading && (
                <div className="col-md text-right float-sm-left">
                  {(this.state.features.includes("1") ||
                    this.state.features.includes("97")) && (
                    <button
                      className="header_button header_button_text add_button_adjustment"
                      style={{ width: "auto", marginTop: "20px" }}
                      onClick={() => this.setState({ open: true })}
                    >
                      Add
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>

          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          {this.state.isLoading && <Loader />}
          {!this.state.isLoading && (
            <div className="container p-3">
              <div className="col-sm card">
                <MaterialTable
                  title={
                    <div className="row">
                      <div className="col-lg-12">
                        <Button
                          className="tab_button"
                          onClick={(e, value) => {
                            this.setState(
                              {
                                filters: 0,
                              },
                              () => this.constractFilters()
                            );
                          }}
                          style={{
                            textTransform: "capitalize",
                            margin: "5px 0px",
                            padding: "5px 20px",
                            borderRadius: 35,
                            height: 30,
                            color:
                              this.state.filters === 0
                                ? config.themeColor
                                : "#483b34b3",
                            backgroundColor:
                              this.state.filters === 0 ? "#fff7f2" : "#fff",
                            fontSize: "14px",
                            border: "0px",
                          }}
                        >
                          Vendor Advance
                        </Button>
                        <Button
                          className="tab_button"
                          onClick={(e, value) => {
                            this.setState(
                              {
                                filters: 1,
                              },
                              () => this.constractFilters()
                            );
                          }}
                          style={{
                            textTransform: "capitalize",
                            margin: "5px 0px",
                            padding: "5px 20px",
                            borderRadius: 35,
                            height: 30,
                            color:
                              this.state.filters === 1
                                ? config.themeColor
                                : "#483b34b3",
                            backgroundColor:
                              this.state.filters === 1 ? "#fff7f2" : "#fff",
                            fontSize: "14px",
                            border: "0px",
                          }}
                        >
                          Down Payments
                        </Button>
                      </div>
                    </div>
                  }
                  columns={[
                    {
                      title: "Contract Number",
                      field: "contract_no",
                      render: (row) => {
                        if (
                          this.state.features.includes("2") ||
                          this.state.features.includes("97")
                        ) {
                          return (
                            <a
                              style={{
                                textDecoration: "none",
                                color: config.themeColor,
                              }}
                              href={
                                "/vendor-advance-view-contract/" + btoa(row.id)
                              }
                            >
                              {row.contract_no}
                            </a>
                          );
                        } else {
                          return row.contract_no;
                        }
                      },
                    },
                    {
                      title: "Vendor Name",
                      field: "vendor_contract_name",
                    },
                    {
                      title: "Supplier Reference No",
                      field: "supplier_ref_no",
                      headerStyle: {
                        minWidth: 220,
                      },
                    },
                    {
                      title: "Advance Amount",
                      field: "qualities",
                      render: (row) => {
                        return (
                          <span>
                            {toLocaleString(row.advance_amount)}{" "}
                            {row.billing_currency}
                          </span>
                        );
                      },
                      headerStyle: {
                        minWidth: 245,
                        textAlign: "right",
                      },
                      cellStyle: {
                        textAlign: "right",
                      },
                      hidden: this.state.filters === 1,
                    },
                    {
                      title: "Down Payment Amount",
                      field: "qualities",
                      render: (row) => {
                        return (
                          <span>
                            {toLocaleString(row.advance_amount)}{" "}
                            {row.billing_currency}
                          </span>
                        );
                      },
                      headerStyle: {
                        minWidth: 245,
                        textAlign: "right",
                      },
                      cellStyle: {
                        textAlign: "right",
                      },
                      hidden: this.state.filters === 0,
                    },
                    {
                      title: "Contract Date",
                      field: "contract_date_formatted",
                    },
                    {
                      title: "Action",
                      sortable: false,
                      filterable: false,
                      headerStyle: {
                        minWidth: 120,
                      },
                      cellStyle: {
                        textAlign: "center",
                      },
                      render: (row) => {
                        return (
                          <div
                            style={{
                              textAlign: "left",
                              marginTop: "-10px",
                              marginBottom: "-10px",
                            }}
                          >
                            {(this.state.features.includes("3") ||
                              this.state.features.includes("97")) && (
                              <i
                                className="fa fa-pencil"
                                style={{
                                  fontSize: 20,
                                  color: config.themeColor,
                                  cursor: "pointer",
                                  marginRight: "10px",
                                }}
                                onClick={() =>
                                  (window.location.href =
                                    "/vendor-advance-contract/" + btoa(row.id))
                                }
                              ></i>
                            )}
                          </div>
                        );
                      },
                      hidden: !(
                        this.state.features.includes("3") ||
                        this.state.features.includes("97")
                      ),
                    },
                  ]}
                  data={this.state.advanceContracts}
                  isLoading={this.state.isLoading}
                  style={{
                    minHeight: 200,
                    fontFamily: "Poppins",
                    overflowY: "auto",
                    boxShadow: "0 0 5px 0 rgba(0, 39, 77, 0.1)",
                  }}
                  options={{
                    paginationType: "stepped",
                    pageSize: 20,
                    pageSizeOptions: [5, 10, 20, 50, 100, 200],
                    headerStyle: {
                      backgroundColor: "#f9f9f9",
                      minWidth: 215,
                      fontFamily: "Poppins",
                      textTransform: "uppercase",
                      fontSize: "14px",
                      fontWeight: 500,
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "0.86",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "rgba(72, 59, 52, 0.7)",
                    },
                    cellStyle: {
                      fontFamily: "Poppins",
                    },
                    // exportAllData: true,
                    // exportButton: true,
                    draggable: false,
                    // exportFileName: 'sales-contracts'
                    // tableLayout: 'fixed'
                  }}
                  actions={[
                    {
                      icon: "download",
                      tooltip: "Download",
                      isFreeAction: true,
                      onClick: (event) =>
                        this.convertExcelHandler(
                          this.state.advanceContracts,
                          "advance-payments"
                        ),
                    },
                  ]}
                />
              </div>
            </div>
          )}
        </div>
        <Dialog
          onClose={this.handleClose}
          open={this.state.open}
          TransitionComponent={Transition}
          keepMounted
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle style={{ width: "600px" }}>
            <span className="popup_title">Select type of Contract</span>
          </DialogTitle>
          <DialogContent style={{ margin: "10px auto 25px" }}>
            <div style={{ flexDirection: "row", display: "inline-flex" }}>
              <a href="/new-vendor-advance-contract" className="link">
                <Paper className="popup_block" style={{ width: 250 }}>
                  <img
                    src={VendorAdvance}
                    alt="Vendor Icon"
                    className="popup_block_image"
                    style={{ width: 200, height: 100 }}
                  />
                  <span className="popup_name">Vendor Advance Payment</span>
                </Paper>
              </a>
              <a href="/vendor-down-payment">
                <Paper className="popup_block" style={{ width: 250 }}>
                  <img
                    src={downPayment}
                    alt="Customer Icon"
                    className="popup_block_image"
                    style={{ width: 200, height: 100 }}
                  />
                  <span className="popup_name">Down Payment</span>
                </Paper>
              </a>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default VendorAdvanceContractList;
