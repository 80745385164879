import React, { Component } from "react";

import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import api from "../../../api/api";

import {
  TextField,
  InputAdornment,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
  Snackbar,
} from "@material-ui/core";

import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import EventIcon from "@material-ui/icons/Event";
import { Alert } from "@material-ui/lab";
import FileUpload from "../../common/FileUpload";
import { dateFormateToDB, toLocaleString } from "../../common/common";
import Loader from "../../common/Loader";

export default class MvExportTaxAdd extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      exportRows: [
        {
          billing_currency: "USD",
          quantity_for_export_tax: "",
          peb_no: "",
          peb_date: null,
          id_biling: "",
          export_tax_per_mt: "",
          export_tax: "",
          export_currency_xchg_rate: "1",
          export_tax_in_local_currency: "",
          reported_to_bi: "No",
          reported_date: null,
        },
      ],
      isLoading: true,
      features: [],
      files: [
        {
          file_description: "",
          file_name: "",
          file_extension: "",
          file_content: "",
          feature_name: "Mv_Pur_Fin_Export_Tax",
        },
      ],
      costing_files: [],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    } else {
      var features = this.Cookie.getCookie("features");
      this.setState({ features: features });
      if (!(features.includes("266") || features.includes("97"))) {
        window.location.href = "/dashboard";
      }
      var loginUserID = this.Cookie.getCookie("loginUserId");
      var idToken = this.Cookie.getIdTokenCookie();
      try {
        // var businessNoID = decodeURIComponent(window.atob(this.props.match.params.businessNoID));
        var businessNoID = this.props.businessNoID;
        //Get businessNumber API based on businessNumber
        await api
          .getBusinessNumber(businessNoID, loginUserID, idToken)
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              response.json().then((res) => {
                if (res.code === "200") {
                  if (res.business_number) {
                    var total_delivered_quantity = 0;
                    for (var i of res.business_number
                      .business_number_allocation) {
                      total_delivered_quantity += Number(
                        i.bna_quantity_delivered
                      );
                    }
                    var row = this.state.exportRows;
                    row[0].quantity_for_export_tax = total_delivered_quantity;
                    this.setState({
                      businessNo: res.business_number.business_no
                        ? res.business_number.business_no
                        : "",
                      mother_vessel_name: res.business_number.mother_vessel_name
                        ? res.business_number.mother_vessel_name
                        : "",
                      quantity_in_mt: total_delivered_quantity,
                      sac_quantity_in_mt: total_delivered_quantity,
                      dc_quantity_in_mt: total_delivered_quantity,
                      dc_quantity_for_export_tax: total_delivered_quantity,
                      exportRows: row,
                      isLoading: false,
                    });
                  }
                } else if (res.code === "601") {
                  this.setState({
                    errorMsg: res.message,
                    snackBarErrorOpen: true,
                  });
                } else if (res.code === "607") {
                  window.location.href = "/logout";
                }
              });
            }
          });
      } catch (e) {
        window.location.href = "/business-number-list";
      }
    }
  }

  addMoreExportRowsHandler = () => {
    const item = {
      billing_currency: "USD",
      quantity_for_export_tax: this.state.quantity_in_mt,
      peb_no: "",
      peb_date: null,
      id_biling: "",
      export_tax_per_mt: "",
      export_tax: "",
      export_currency_xchg_rate: "1",
      export_tax_in_local_currency: "",
      reported_to_bi: "No",
      reported_date: null,
    };

    this.setState({
      exportRows: [...this.state.exportRows, item],
    });
  };

  removeExportRowsHandler = (idx) => () => {
    const rows = this.state.exportRows;
    rows.splice(idx, 1);
    this.setState({
      exportRows: rows,
    });
  };

  submitHandler = () => {
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    var exportRows = this.state.exportRows;

    let costing_file = this.state.costing_files?.filter(
      (e) => e.file_content !== "" && e.column === "invoice_file"
    );

    // Checking Mandatory fields.
    for (var i in exportRows) {
      exportRows[i].costing_files = costing_file;
      if (config.config === "SDAM" && exportRows[i].reported_to_bi === "Yes") {
        if (exportRows[i].reported_date === null) {
          exportRows[i].reported_date_error = true;
          this.setState({
            exportRows: exportRows,
          });
          alert("Please fill mandatory fileds.");
          return;
        }
      }
      if (
        (config.company === "SDAM" ||
          config.company === "SRK" ||
          config.company === "BTR") &&
        exportRows[i].export_currency_xchg_rate === ""
      ) {
        exportRows[i].export_currency_xchg_rate = 1;
        exportRows[i].export_tax_in_local_currency = exportRows[i].export_tax;
      }
    }
    /**
     *  Checking Mandatory files
     */
    var missing_fields = 0;
    let files_missing = 0;
    var row = this.state.files;
    for (var obj of row) {
      if (obj.file_type !== "" || obj.file_content !== "") {
        if (obj.file_content !== "") {
          if (obj.file_description === "") {
            missing_fields++;
            obj.file_description_error = true;
          } else {
            obj.file_description = obj.file_description.toUpperCase();
          }
        } else if (obj.file_description !== "") {
          if (obj.file_content === "") {
            files_missing++;
          }
        }
      }
    }
    this.setState({
      files: row,
    });

    if (missing_fields > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please fill mandatory fields.",
      });
      return;
    }
    if (files_missing > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please Select file.",
      });
      return;
    }

    const content_files = row.filter((e) => e.file_content !== "");

    try {
      //var businessNoID = decodeURIComponent(window.atob(this.props.match.params.businessNoID));
      var businessNoID = this.props.businessNoID;
      api
        .add_mv_pur_fin_export_tax(
          loginUserID,
          idToken,
          businessNoID,
          exportRows,
          content_files
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                this.setState(
                  {
                    snackBarSuccessOpen: true,
                    successMsg: res.message,
                  },
                  () => {
                    setTimeout(() => {
                      this.props.onAddResponse({
                        screen: "MvExportTax",
                        code: res.code,
                        message: "",
                      });
                    }, 2000);
                  }
                );
                //window.location.href = '/mv-view-export-tax/' + btoa(businessNoID)
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  snackBarErrorOpen: true,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    } catch (e) {
      window.location.href = "/business-number-list";
    }
  };

  render() {
    const re = /^[0-9]*\.?[0-9]*$/;
    const quan = /^\d*(\.\d{0,3})?$/;
    const pr = /^\d*(\.\d{0,4})?$/;
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div
          className="container bg-white p-0"
          style={{ minHeight: "100vh", width: 850 }}
        >
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <div className="row clearfix content-header border-bottom">
            <div className="col-lg-12 pl-0">
              <h4 style={{ padding: "10px 20px" }}>Add Export Tax</h4>
            </div>
          </div>
          <div className="drawer-section">
            <div className="card p-3">
              <div
                className="card-body p-0"
                style={{ backgroundColor: "rgba(25,118,210,.01)" }}
                id="accordion"
              >
                {this.state.exportRows.map((e, idx) => (
                  <div className="row" key={idx}>
                    <div className="col-lg-12 border_dashed rounded-lg p-2 mb-3">
                      <div className="row mb-3 ">
                        <div className="col-lg-12 p-0">
                          <div className="row">
                            <div className="col-lg-4 mb-1">
                              <label className="form_label mb-1">PEB No</label>
                              <TextField
                                name="peb_no"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                  },
                                }}
                                inputProps={{
                                  style: { textTransform: "uppercase" },
                                }}
                                value={e.peb_no}
                                placeholder="Enter Value"
                                onChange={(e) => {
                                  var row = this.state.exportRows;
                                  row[idx].peb_no = e.target.value;

                                  this.setState({
                                    exportRows: row,
                                  });
                                }}
                              />
                            </div>

                            <div className="col-lg-4 mb-1">
                              <label className="form_label mb-0">
                                PEB Date
                              </label>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                  autoOk={true}
                                  inputVariant="outlined"
                                  variant="inline"
                                  margin="dense"
                                  fullWidth
                                  format="dd/MM/yyyy"
                                  value={e.peb_date}
                                  //onChange={(date) => this.contractDateHandler(date)}
                                  onChange={(date) => {
                                    var row = this.state.exportRows;
                                    row[idx].peb_date = dateFormateToDB(date);
                                    this.setState({
                                      exportRows: row,
                                    });
                                  }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <IconButton>
                                          <EventIcon />
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                    className: "pl-0",
                                  }}
                                />
                              </MuiPickersUtilsProvider>
                            </div>

                            <div className="col-lg-4 mb-1">
                              <label className="form_label mb-0">
                                ID Billing
                              </label>
                              <TextField
                                name="id_biling"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                  },
                                }}
                                inputProps={{
                                  style: { textTransform: "uppercase" },
                                }}
                                value={e.id_biling}
                                placeholder="ID Billing"
                                onChange={(e) => {
                                  var row = this.state.exportRows;

                                  row[idx].id_biling = e.target.value;
                                  this.setState({
                                    exportRows: row,
                                  });
                                }}
                              />
                            </div>

                            <div className="col-lg-4 mb-1">
                              <label className="form_label mb-0">
                                Quantity For Export Tax
                              </label>
                              <TextField
                                name="dc_quantity_for_export_tax"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                  },
                                }}
                                value={e.quantity_for_export_tax}
                                placeholder="Enter Value"
                                onChange={(e) => {
                                  if (
                                    e.target.value === "" ||
                                    quan.test(e.target.value)
                                  ) {
                                    let value = e.target.value;
                                    if (value === "") {
                                      value = 1;
                                    }
                                    var row = this.state.exportRows;
                                    var export_tax = (
                                      (Number(value) *
                                        Number(row[idx].export_tax_per_mt) *
                                        1.5) /
                                      100
                                    ).toFixed(2);
                                    row[idx].quantity_for_export_tax =
                                      e.target.value;
                                    row[idx].export_tax = export_tax;
                                    if (
                                      config.company === "SDAM" ||
                                      config.company === "SRK" ||
                                      config.company === "BTR"
                                    ) {
                                      var export_tax_in_local_currency = (
                                        Number(export_tax) *
                                        Number(
                                          row[idx].export_currency_xchg_rate
                                            ? row[idx].export_currency_xchg_rate
                                            : 1
                                        )
                                      ).toFixed(4);
                                      row[idx].export_tax_in_local_currency =
                                        export_tax_in_local_currency;
                                    }
                                    this.setState({
                                      exportRows: row,
                                    });
                                  }
                                }}
                              />
                            </div>

                            <div className="col-lg-4 mb-1">
                              <label className="form_label mb-0">
                                Export Tax Per MT
                              </label>
                              <TextField
                                name="dc_export_tax_per_mt"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                value={e.export_tax_per_mt}
                                placeholder="Enter Value"
                                onChange={(e) => {
                                  if (
                                    e.target.value === "" ||
                                    pr.test(e.target.value)
                                  ) {
                                    var value = e.target.value;
                                    if (value === "") {
                                      value = "1";
                                    }
                                    var row = this.state.exportRows;
                                    var export_tax = (
                                      (Number(
                                        row[idx].quantity_for_export_tax
                                      ) *
                                        Number(value) *
                                        1.5) /
                                      100
                                    ).toFixed(2);
                                    row[idx].export_tax_per_mt = e.target.value;
                                    row[idx].export_tax = export_tax;
                                    if (
                                      config.company === "SDAM" ||
                                      config.company === "SRK" ||
                                      config.company === "BTR"
                                    ) {
                                      var export_tax_in_local_currency = (
                                        Number(export_tax) *
                                        Number(
                                          row[idx].export_currency_xchg_rate
                                            ? row[idx].export_currency_xchg_rate
                                            : 1
                                        )
                                      ).toFixed(4);
                                      row[idx].export_tax_in_local_currency =
                                        export_tax_in_local_currency;
                                    }
                                    this.setState({
                                      exportRows: row,
                                    });
                                  }
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      USD
                                    </InputAdornment>
                                  ),
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                  },
                                }}
                              />
                            </div>

                            <div className="col-lg-4 mb-1">
                              <label className="form_label mb-0">
                                Export Tax
                              </label>
                              <TextField
                                name="export_tax"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                value={e.export_tax}
                                placeholder="Enter Value"
                                onChange={(e) => {
                                  if (
                                    e.target.value === "" ||
                                    pr.test(e.target.value)
                                  ) {
                                    var row = this.state.exportRows;
                                    row[idx].export_tax = e.target.value;
                                    if (
                                      config.company === "SDAM" ||
                                      config.company === "SRK" ||
                                      config.company === "BTR"
                                    ) {
                                      var export_tax_in_local_currency = (
                                        Number(e.target.value) *
                                        Number(
                                          row[idx].export_currency_xchg_rate
                                            ? row[idx].export_currency_xchg_rate
                                            : 1
                                        )
                                      ).toFixed(4);
                                      row[idx].export_tax_in_local_currency =
                                        export_tax_in_local_currency;
                                    }
                                    this.setState({
                                      exportRows: row,
                                    });
                                  }
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      USD
                                    </InputAdornment>
                                  ),
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                  },
                                }}
                              />
                            </div>
                            {(config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") && (
                              <div className="col-lg-4 mb-1">
                                <label className="form_label mb-0">
                                  Tax in IDR
                                </label>
                                <p className="contract_display_header_value">
                                  : &emsp;
                                  {e.export_tax_in_local_currency
                                    ? toLocaleString(
                                        e.export_tax_in_local_currency
                                      )
                                    : "-"}{" "}
                                </p>
                              </div>
                            )}
                            {(config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") && (
                              <div className="col-lg-4 mb-1">
                                <label className="form_label mb-0">
                                  Exchange Rate
                                </label>
                                <TextField
                                  name="export_currency_xchg_rate"
                                  margin="dense"
                                  variant="outlined"
                                  fullWidth
                                  InputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                    },
                                  }}
                                  value={e.export_currency_xchg_rate}
                                  placeholder="Currency Exchange"
                                  onChange={(e) => {
                                    if (
                                      e.target.value === "" ||
                                      re.test(e.target.value)
                                    ) {
                                      var value = e.target.value;
                                      if (value === "") {
                                        value = 1;
                                      }
                                      var row = this.state.exportRows;
                                      row[idx].export_currency_xchg_rate =
                                        e.target.value;
                                      row[idx].export_tax_in_local_currency = (
                                        Number(row[idx].export_tax) *
                                        Number(value)
                                      ).toFixed(2);

                                      this.setState({
                                        exportRows: row,
                                      });
                                    }
                                  }}
                                />
                              </div>
                            )}
                            <div className="col-lg-4 mb-1">
                              <label className="form_label mb-0">
                                Reported to BI
                              </label>
                              <RadioGroup
                                aria-label="reported_to_bi"
                                name="reported_to_bi"
                                style={{ flexDirection: "row" }}
                                onChange={(e) => {
                                  var row = this.state.exportRows;
                                  row[idx].reported_to_bi = e.target.value;
                                  this.setState({
                                    exportRows: row,
                                  });
                                }}
                              >
                                <FormControlLabel
                                  value="Yes"
                                  control={
                                    <Radio
                                      checked={e.reported_to_bi === "Yes"}
                                    />
                                  }
                                  label="Yes"
                                />
                                <FormControlLabel
                                  value="No"
                                  control={
                                    <Radio
                                      checked={e.reported_to_bi === "No"}
                                    />
                                  }
                                  label="No"
                                />
                              </RadioGroup>
                            </div>
                            {e.reported_to_bi === "Yes" && (
                              <div className="col-lg-4 mb-1">
                                <label className="form_label mb-0">
                                  Reported Date{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <DatePicker
                                    autoOk={true}
                                    inputVariant="outlined"
                                    variant="inline"
                                    margin="dense"
                                    fullWidth
                                    format="dd/MM/yyyy"
                                    value={e.reported_date}
                                    error={e.reported_date_error}
                                    onChange={(date) => {
                                      var row = this.state.exportRows;
                                      row[idx].reported_date =
                                        dateFormateToDB(date);
                                      row[idx].reported_date_error = false;
                                      this.setState({
                                        exportRows: row,
                                      });
                                    }}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <IconButton>
                                            <EventIcon />
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                      className: "pl-0",
                                    }}
                                  />
                                </MuiPickersUtilsProvider>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                {/* {(this.state.features.includes('523')  || this.state.features.includes('97') ) && */}

                <FileUpload
                  files={this.state.files}
                  type="Costings"
                  feature_name="Mv_Pur_Fin_Export_Tax"
                  callbackFn={(data, mandatoryFiles) => {
                    this.setState({
                      files: data,
                      costing_files: mandatoryFiles,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <footer className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button btn-light"
              name="submit"
              onClick={() => {
                this.props.returnCallbackDrawerClose(false);
              }}
            >
              Close
            </button>
            <button
              type="button"
              className="drawer_text drawer_btn_success"
              name="Back"
              onClick={this.submitHandler}
            >
              Submit
            </button>
          </footer>
        </div>
      );
    }
  }
}
