import React, { Component } from "react";
import api from "../../../api/api";
import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import { Alert } from "@material-ui/lab";
import { Snackbar } from "@material-ui/core";
import { localDateFormate, toLocaleString } from "../../common/common";
import ViewFiles from "../../common/ViewFiles";
import InvoiceForm from "../../common/InvoiceForm";
import InvoiceLink from "../../InvoicePdf/InvoiceLink";
import PaymentPostings from "../../common/PaymentPostings";
import Loader from "../../common/Loader";
import BusinessApproveButton from "../../Approvals/BusinessApproveButton";

export default class Barge_Purchase_Fin_Export_Tax_View extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      vendor_name: "",
      mv_name: "",
      barge_id: "",
      export_tax_applicable: "Yes",
      quantity_for_export_tax: "",
      export_tax_per_mt: "",
      export_tax: "",
      export_tax_in_local_currency: "",
      export_currency_xchg_rate: "",
      isLoading: true,
      activeStep: 0,
      export_tax_payments: [],
      Barges: [],
      approval_status: "Pending",
      features: [],
      files: [],
      payment_due_date: null,
      invoiceDetails: {
        invoice_no: "",
        invoice_date: null,
        ppn: "",
        ppn_date: null,
      },
      paymentDetails: {
        paymentTitle: "Add Export Tax",
        payment_posting_type: "export_tax",
      },
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (
      !(
        features.includes("175") ||
        features.includes("400") ||
        features.includes("451") ||
        features.includes("97")
      )
    ) {
      window.location.href = "/dashboard";
    }
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const export_tax_id = this.props.exportTaxID;

    await api.get_barges(loginUserID, idToken).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            if (res.barges) {
              this.setState({
                Barges: res.barges,
                //isLoading: false
              });
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          } else if (res.code === "601") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });

    await api
      .get_barge_export_tax(loginUserID, idToken, export_tax_id)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.barge_export_tax) {
                let invoice_details = {
                  ...this.state.invoiceDetails,
                  invoice_no: res.barge_export_tax[0].invoice_no,
                  invoice_date: res.barge_export_tax[0].invoice_date,
                  ppn: res.barge_export_tax[0].ppn,
                  ppn_date: res.barge_export_tax[0].ppn_date,
                };
                this.setState(
                  {
                    invoiceDetails: invoice_details,
                    invoice_file: res.barge_export_tax[0].invoice_file,
                    costing_no: res.barge_export_tax[0].costing_no
                      ? res.barge_export_tax[0].costing_no
                      : "",
                    barge_id: res.barge_export_tax[0].barge_id
                      ? res.barge_export_tax[0].barge_id
                      : "",
                    export_tax_applicable: res.barge_export_tax[0]
                      .export_tax_applicable
                      ? res.barge_export_tax[0].export_tax_applicable
                      : "",
                    barge_nomination: res.barge_export_tax[0].barge_nomination
                      ? res.barge_export_tax[0].barge_nomination
                      : "",
                    quantity_for_export_tax: res.barge_export_tax[0]
                      .quantity_for_export_tax
                      ? res.barge_export_tax[0].quantity_for_export_tax
                      : "",
                    export_tax_per_mt: res.barge_export_tax[0].export_tax_per_mt
                      ? res.barge_export_tax[0].export_tax_per_mt
                      : "",
                    export_tax: res.barge_export_tax[0].export_tax
                      ? res.barge_export_tax[0].export_tax
                      : "",
                    export_tax_in_local_currency: res.barge_export_tax[0]
                      .export_tax_in_local_currency
                      ? res.barge_export_tax[0].export_tax_in_local_currency
                      : "",
                    export_currency_xchg_rate: res.barge_export_tax[0]
                      .export_currency_xchg_rate
                      ? res.barge_export_tax[0].export_currency_xchg_rate
                      : "",
                    files: res.barge_export_tax[0].files
                      ? res.barge_export_tax[0].files
                      : [],

                    created_by: res.barge_export_tax[0].created_by
                      ? res.barge_export_tax[0].created_by
                      : null,
                    approval_status: res.barge_export_tax[0].approval_status
                      ? res.barge_export_tax[0].approval_status
                      : "Pending",
                    approved_by: res.barge_export_tax[0].approved_by
                      ? res.barge_export_tax[0].approved_by
                      : null,
                    approved_by_name: res.barge_export_tax[0].approved_by_name
                      ? res.barge_export_tax[0].approved_by_name
                      : "",
                    approved_date: res.barge_export_tax[0].approved_date
                      ? res.barge_export_tax[0].approved_date
                      : null,
                    approval_status_2: res.barge_export_tax[0].approval_status_2
                      ? res.barge_export_tax[0].approval_status_2
                      : "Pending",
                    approved_by_2: res.barge_export_tax[0].approved_by_2
                      ? res.barge_export_tax[0].approved_by_2
                      : null,
                    approved_by_name_2: res.barge_export_tax[0]
                      .approved_by_name_2
                      ? res.barge_export_tax[0].approved_by_name_2
                      : "",
                    approved_date_2: res.barge_export_tax[0].approved_date_2
                      ? res.barge_export_tax[0].approved_date_2
                      : null,
                    // isLoading: false,
                  },
                  () => {
                    if (res.barge_export_tax.length > 0) {
                      var paid_to_vendor = 0;
                      var remaining_amount = 0;
                      for (var i of res.barge_export_tax[0]
                        .export_tax_payments) {
                        if (i.date_of_payment) {
                          paid_to_vendor += Number(i.amount);
                        }
                      }
                      remaining_amount =
                        Number(this.state.export_tax) - Number(paid_to_vendor);
                      if (
                        config.company === "SDAM" ||
                        config.company === "SRK" ||
                        config.company === "BTR"
                      ) {
                        remaining_amount =
                          Number(this.state.export_tax_in_local_currency) -
                          Number(paid_to_vendor);
                      }
                      this.setState(
                        {
                          export_tax_payments:
                            res.barge_export_tax[0].export_tax_payments,
                          paid_to_vendor: paid_to_vendor,
                          remaining_amount: remaining_amount,
                          isLoading: false,
                        },
                        () => {
                          if (this.state.barge_id > 0) {
                            const barge_id = this.state.barge_id;

                            let request = {
                              login_user_id:
                                this.Cookie.getCookie("loginUserId"),
                              idtoken: this.Cookie.getIdTokenCookie(),
                              barge_id: barge_id,
                              vendor_type: "Barge",
                            };
                            api
                              .get_business_no_info_for_barge(request)
                              .then((response) => {
                                if (
                                  response.status >= 200 &&
                                  response.status < 300
                                ) {
                                  response.json().then((res) => {
                                    if (res.code === "200") {
                                      this.setState({
                                        vendor_name: res.vendor_name,
                                        mv_name: res.mv_name,
                                      });
                                    }
                                  });
                                }
                              });
                          }
                        }
                      );
                    }
                  }
                );
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  }

  toLocaleString(value) {
    return value ? Number(value).toLocaleString() : 0;
  }

  updateInvoice = (data) => {
    let request = {
      ...data,
      table_name: "barge_export_tax",
    };
    api.update_invoice_details(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.componentDidMount();
            this.setState({
              invoiceDialog: false,
            });
          }
        });
      }
    });
  };

  renderPaymentPostings = (data) => {
    const {
      export_tax_payments = [],
      remaining_amount = 0,
      export_currency_xchg_rate = 1,
      approval_status = "Pending",
    } = data;
    const { invoiceDetails: { invoice_no } = {} } = data;
    let payment_related_data = {
      paymentTitle: "Export Tax",
      payment_posting_type: "export_tax",
      invoice_no: invoice_no,
      outstanding_amount: remaining_amount,
      billing_currency: "USD",
      currency_xchg_rate: export_currency_xchg_rate
        ? export_currency_xchg_rate
        : 1,
      costing_id: this.props.exportTaxID,
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
    };
    let access_details = {
      costing_approval: approval_status,
      add_payment: "178",
      view_payment: "179",
      update_payment: "180",
      delete_payment: "181",
      approver: "401",
      approver2: "452",
    };

    return (
      <PaymentPostings
        payments={export_tax_payments}
        costingType="Export Tax"
        data={payment_related_data}
        access_details={access_details}
        callbackFn={() => {
          this.componentDidMount();
        }}
      />
    );
  };

  renderInvoiceLink = () => {
    const data = this.state.Barges?.filter(
      (obj) => obj.id === this.state.barge_id
    );
    const {
      invoiceDetails: { invoice_no, invoice_date } = {},
      costing_no,
      // currency_xchg_rate = 1,
      export_tax = 0,
      vendor_name,
      mv_name,
      quantity_for_export_tax = 0,
      export_tax_per_mt = 0,
    } = this.state;

    const Grand_total = parseFloat(export_tax ? export_tax : 0).toFixed(2);
    const invoiceObject = {
      costing_id: costing_no,
      to: vendor_name,
      business_no: mv_name,
      costing_name: "Export Tax",
      invoice_no: invoice_no,
      invoice_date: localDateFormate(invoice_date),
      bargeDetails: data ? data.flat() : [],
      billing_currency:
        config.company === "SRPL" || config.company === "AVS" || config.company === "PRPL" ? "USD" : "IDR",
      total_payable_amount: toLocaleString(Grand_total),
      purchase_type: "FOB Barge",
      description: [
        {
          qty: toLocaleString(quantity_for_export_tax),
          adjustedPrice: toLocaleString(export_tax_per_mt),
          desc: `${this.state.export_currency_xchg_rate > 1
            ? `Export Tax @ ${parseFloat(this.state.export_currency_xchg_rate).toFixed(2)}`
            : "Export Tax"
            }`,
          amount: export_tax,
        },
      ],
    };
    return <InvoiceLink data={invoiceObject} fileName={costing_no} costing_name={"Export Tax"} />;
  };

  render() {
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div className="container bg-white p-0" style={{ minHeight: "100vh" }}>
          <div className="clearfix content-header border-bottom">
            <div className="row">
              <div className="col-lg-10 text-left pl-0">
                <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                  {`Export Tax - ${this.state.costing_no}`}
                  <span
                    className={
                      this.state.approval_status === "Pending"
                        ? "type_2"
                        : "type_1"
                    }
                    style={{ padding: 8, marginLeft: 16 }}
                  >
                    {this.state.approval_status}
                  </span>
                </h4>
              </div>
              {this.state.approval_status === "Pending" &&
                this.state.invoice_file &&
                this.Cookie.getCookie("loginUserId") !==
                this.state.created_by &&
                this.state.approved_by !==
                this.Cookie.getCookie("loginUserId") &&
                (this.state.features.includes("400") ||
                  this.state.features.includes("451") ||
                  this.state.features.includes("97")) && (
                  <BusinessApproveButton
                    costingID={this.props.exportTaxID}
                    costingType={"Export Tax"}
                    accessInfo={{
                      ...this.state,
                      approver_1: "400",
                      approver_2: "451",
                    }}
                  />
                )}
              <div className="col-lg-1 pl-0 float-right">
                {this.renderInvoiceLink()}
              </div>
            </div>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMessage}
            </Alert>
          </Snackbar>
          <div className="col shadow-sm p-2 mb-2 bg-white border">
            <div className="table-responsive">
              <table className="table table-sm table-bordered mb-0">
                <thead className="table-header">
                  <tr>
                    <th className="text-center">Description</th>
                    <th className="text-right">Qty</th>
                    <th className="text-right">Unit Price</th>
                    <th className="text-right">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <>
                    <tr>
                      <td>
                        Export Tax
                        {!(
                          config.company === "SRPL" ||
                          config.company === "AVS" ||
                          config.company === "PRPL"
                        ) &&
                          this.state.export_currency_xchg_rate > 1 && (
                            <div>
                              Exchange Rate @ {toLocaleString(this.state.export_currency_xchg_rate)}
                            </div>
                          )}
                      </td>
                      <td className="text-right"> {this.state.quantity_for_export_tax
                        ? toLocaleString(this.state.quantity_for_export_tax)
                        : null}</td>
                      <td className="text-right">{this.state.export_tax_per_mt
                        ? this.toLocaleString(this.state.export_tax_per_mt)
                        : null}{" "}</td>
                      <td className="text-right">
                        {this.state.export_tax
                          ? toLocaleString(this.state.export_tax)
                          : null}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3} className="text-right">
                        {" "}
                        Payable to Vendor :{" "}
                      </td>
                      {(config.company === "SRPL" ||
                        config.company === "PRPL" ||
                        config.company === "AVS") &&
                        <td style={{ fontWeight: "bold" }} className="text-right">
                          {`${config.company === "SRPL" ||
                            config.company === "PRPL" ||
                            config.company === "AVS"
                            ? "USD"
                            : "IDR"
                            } ${this.state.export_tax
                              ? this.toLocaleString(this.state.export_tax)
                              : null}`}
                        </td>}
                      {!(config.company === "SRPL" ||
                        config.company === "PRPL" ||
                        config.company === "AVS") &&
                        <td style={{ fontWeight: "bold" }} className="text-right">
                          {config.company === "SRPL" ||
                            config.company === "PRPL" ||
                            config.company === "AVS"
                            ? "USD"
                            : "IDR"}  {this.toLocaleString(this.state.export_tax_in_local_currency)}
                        </td>
                      }
                    </tr>
                  </>
                </tbody>
              </table>
            </div>
          </div>
          <div className="drawer-section">
            <div className="card">
              <div className="card-body p-2 rounded">
                {this.state.export_tax_applicable === "Yes" && (
                  <div className="row col-lg-12 p-0 mb-1">
                    <InvoiceForm
                      open={false}
                      data={this.state.invoiceDetails}
                      costingID={this.props.exportTaxID}
                      callbackFn={(data) => this.updateInvoice(data)}
                    />
                  </div>
                )}
                <ViewFiles
                  {...this.props}
                  files_data={this.state.files}
                  type="Costings"
                  invoice_file={this.state.invoice_file}
                />
              </div>
              <div className="col shadow-sm p-0 mb-2 bg-white border">
                <div className="table-responsive">
                  <table className="table table-sm table-bordered">
                    <thead className="table-header">
                      <tr>
                        <th className="text-center">Approval Type</th>
                        <th className="text-center">Approved by</th>
                        <th className="text-center">Approved On</th>
                      </tr>
                    </thead>
                    <tbody>
                      <>
                        <tr>
                          <td className="text-center">Business</td>
                          <td className="text-center">
                            <div> {this.state.approved_by_name}</div>
                          </td>
                          <td className="text-center">
                            {localDateFormate(this.state.approved_date)}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-center">Financial</td>
                          <td className="text-center">
                            {this.state.approved_by_name_2}
                          </td>
                          <td className="text-center">
                            {localDateFormate(this.state.approved_date_2)}
                          </td>
                        </tr>
                      </>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div
            className="row col-lg-12 modal-footer justify-content-end bottom_buttons_section"
            style={{ bottom: 0, background: "white" }}
          >
            <button
              type="button"
              className="drawer_button drawer_text drawer_calculate_button drawer_calculate_adjustment"
              onClick={() => this.props.onCancel(false)}
            >
              Cancel
            </button>

            {(this.state.approval_status === "Pending"
              ? this.state.features.includes("176") ||
              this.state.features.includes("400") ||
              this.state.features.includes("97")
              : this.state.features.includes("97")) && (
                <button
                  type="button"
                  className="drawer_button drawer_text"
                  onClick={() => this.props.onEdit(true)}
                >
                  Edit
                </button>
              )}
          </div>
        </div>
      );
    }
  }
}
