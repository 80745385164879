import cookie from 'react-cookies'

import config from '../../config/config';

export default class CookieHandler {

  // TODO::check if browser cookies or enabled. Show error message if disabled and ask user to enable.
  setCookie(key, value) {
    var currentCookie = cookie.load(config.cookieName);
    if(currentCookie !== undefined) {
      let cookieData = JSON.parse(atob(currentCookie));
      cookieData[key] = value;
      currentCookie= cookieData;
    }
    else {
      currentCookie = { [key]: value };
    }
    // TODO::encrypt cookie data
    cookie.save(config.cookieName, btoa(JSON.stringify(currentCookie)), {path: "/", maxAge: 3000000});
  }

  getCookie(key) {
    // TODO::decrypt cookie data
    if(cookie.load(config.cookieName)) {
      var currentCookie = JSON.parse(atob(cookie.load(config.cookieName)));
      if(currentCookie !== undefined) {
        return currentCookie[key];
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  removeCookie() {
    cookie.remove(config.cookieName);
  }

  deleteCookieKey(key) {
    var currentCookie = JSON.parse(atob(cookie.load(config.cookieName)));;
    if(currentCookie !== undefined) {
      return null;
    }
    else {
      delete currentCookie[key];
      // TODO::encrypt cookie data
      cookie.save(config.cookieName, JSON.stringify(currentCookie), {path: "/", maxAge: 3000000});
    }
  }

  getIdTokenCookie() {
    return cookie.load('idToken');
  }

  setIdTokenCookie(value) {
    cookie.save('idToken', value, {path: "/", maxAge: 3000000});
  }

  removeIdTokenCookie() {
    cookie.remove('idToken');
  }

  isLoggedIn() {
    var idTokenCookie = cookie.load('idToken');
    if(idTokenCookie !== undefined) {
      return true;
    }
    else {
      return false;
    }
  }

}