import React, { Component } from "react";
import CookieHandler from "../common/CookieHandler";
// import SideBar from '../Common/SideBar';
// import Header from '../Common/Header';
// import config from '../../config/config';
import api from "../../api/api";
import exportToExcel from "../Export_To_Excel";

import { Snackbar, TextField } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { IconButton, InputAdornment } from "@material-ui/core";
import EventIcon from "@material-ui/icons/Event";
import Autocomplete from "@material-ui/lab/Autocomplete";

import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { dateFormateToDB } from "../common/common";
import Loader from "../common/Loader";

export default class CostingCenterLevel extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      activeTabIndex: 0,
      account_name: "",
      expenditure_accounts: [],
      indirect_expenditure_months: [],
      expenditure_history_data: [],
      selected_account_id: null,
      expenditure_groups: [],
      expense_types: [],
      expenditure_group: "0",
      expense_type: "0",
      is_receivable: false,
      from_date: null,
      to_date: null,
      account_history: [],
      description: "",
      payment_date: null,
      expense_date: null,
      // Receipt
      amount: "",
      date_of_receipt: null,
      receipt_remarks: "",

      openExpenseDrawer: false,
      openReceiptDrawer: false,

      // Expenditure History
      expenditure_history: [],
      acc_name_drop_values: [],
      acc_name_drop_data: [],
      account_id: [],
      accounts: [],
      his_expenditure_group: [],
      start_date: null,
      end_date: null,
      isLoading: true,
      open: false,
      costingCenterView: false,
      costingCenterUpdate: false,
      showExpensesError: false,
      costingCenterData: null,
      getData: false,
      view_type: "Add",
      // cost center
      cost_center_drop_values: [],
      cost_center: [],
      expense_cost_center: [],
      features: [],
      expenditure_grouped_data: [],
      expenditure_grouping_data: [],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (!(features.includes("311")  || features.includes("97") )) {
      window.location.href = "/dashboard";
    }
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();

    const get_cost_centers = await api.get_indirect_expenditure_cost_centers(
      loginUserID,
      idToken
    );
    if (get_cost_centers.status >= 200 && get_cost_centers.status < 300) {
      get_cost_centers.json().then((res) => {
        if (res.code === "200") {
          if (res.indirect_expenditure_cost_centers) {
            const cost_center_drop_values = [];
            for (var i of res.indirect_expenditure_cost_centers) {
              cost_center_drop_values.push({ name: i.cost_center, id: i.id });
            }
            this.setState({
              cost_center_drop_values: cost_center_drop_values,
            });
          } else {
            alert("Unexpected error occured. Please contact administrator.");
          }
        } else if (res.code === "601") {
          this.setState({
            isLoading: false,
            snackBarErrorOpen: true,
            errorMsg: res.message,
          });
        } else if (res.code === "607") {
          window.location.href = "/logout";
        }
      });
    }
    const get_exp_accounts = await api.get_indirect_expenditure_accounts(
      loginUserID,
      idToken
    );
    if (get_exp_accounts.status >= 200 && get_exp_accounts.status < 300) {
      get_exp_accounts.json().then((res) => {
        if (res.code === "200") {
          if (res.indirect_expenditure_accounts) {
            const acc_names = [];
            for (var i of res.indirect_expenditure_accounts) {
              acc_names.push({
                name: i.account_name,
                id: i.id,
                expenditure_group: i.expenditure_group,
              });
            }
            this.setState({
              expenditure_accounts: res.indirect_expenditure_accounts.filter(
                (e, indx) => e.expenditure_group !== ""
              ), //res.indirect_expenditure_accounts,
              acc_name_drop_values: acc_names,
              acc_name_drop_data: acc_names,
              //isLoading: false
            });
          } else {
            alert("Unexpected error occured. Please contact administrator.");
          }
        } else if (res.code === "601") {
          this.setState({
            snackBarErrorOpen: true,
            errorMsg: res.message,
            // isLoading: false
          });
        } else if (res.code === "607") {
          window.location.href = "/logout";
        }
      });
    }
    const get_exp_groups = await api.get_indirect_expenditure_groups(
      loginUserID,
      idToken
    );
    if (get_exp_groups.status >= 200 && get_exp_groups.status < 300) {
      get_exp_groups.json().then((res) => {
        if (res.code === "200") {
          if (res.indirect_expenditure_groups) {
            const expenditure_groups = [];
            for (var i of res.indirect_expenditure_groups) {
              expenditure_groups.push({
                name: i.expenditure_group,
                cost_center_id: i.id,
              });
            }
            this.setState({
              isLoading: false,
              expenditure_groups: expenditure_groups,
            });
          } else {
            alert("Unexpected error occured. Please contact administrator.");
          }
        } else if (res.code === "601") {
          this.setState({
            isLoading: false,
            snackBarErrorOpen: true,
            errorMsg: res.message,
          });
        } else if (res.code === "607") {
          window.location.href = "/logout";
        }
      });
    }
  }

  searchExpenditureHisHandler = () => {
    this.setState({ getData: true });
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    let {
      account_id,
      accounts,
      his_expenditure_group,
      start_date,
      end_date,
      cost_center,
    } = this.state;
    let report_type = "cost_center";
    if (his_expenditure_group === "0") {
      his_expenditure_group = "";
    }
    if (start_date === null) {
      start_date = "";
    }
    if (end_date === null) {
      end_date = "";
    }
    if (start_date === "" || end_date === "") {
      this.setState({
        snackBarErrorOpen: true,
        getData: false,
        errorMsg: "Please select dates to filter!",
      });
      return;
    } else {
      //
      api
        .get_history_for_indirect_expenditure(
          loginUserID,
          idToken,
          account_id,
          cost_center,
          report_type,
          his_expenditure_group,
          start_date,
          end_date
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                if (res.indirect_expenditure_data) {
                  if (res.indirect_expenditure_data.length === 0) {
                    this.setState({
                      snackBarErrorOpen: true,
                      getData: false,
                      errorMsg: "No records to display",
                    });
                  } else {
                    let expenditure_months = Object.keys(
                      res.indirect_expenditure_data
                    );
                    const arr = Object.keys(res.indirect_expenditure_data);
                    const newObj = arr.reduce(
                      (a, b) => ({ ...a, [b]: "0.00" }),
                      {}
                    );
                    let newData = [];
                    let cost_center_new_data = [];
                    if (this.state.cost_center.length > 0) {
                      for (let c = 0; c < this.state.cost_center.length; c++) {
                        // for (let d=0; d< this.state.cost_center_drop_values.length; d++) {
                        // }
                        for (
                          let v = 0;
                          v < this.state.cost_center_drop_values.length;
                          v++
                        ) {
                          if (
                            this.state.cost_center[c] ===
                            this.state.cost_center_drop_values[v].id
                          ) {
                            cost_center_new_data.push({
                              name: this.state.cost_center_drop_values[v].name,
                            });
                          }
                        }
                      }
                    } else {
                      for (
                        let e = 0;
                        e < this.state.cost_center_drop_values.length;
                        e++
                      ) {
                        cost_center_new_data.push({
                          name: this.state.cost_center_drop_values[e].name,
                        });
                      }
                    }
                    for (
                      let n = 0;
                      n < this.state.expenditure_accounts.length;
                      n++
                    ) {
                      for (let m = 0; m < cost_center_new_data.length; m++) {
                        newData.push({
                          name: this.state.expenditure_accounts[n]
                            .expenditure_group,
                          account_name:
                            this.state.expenditure_accounts[n].account_name,
                          costing_center: cost_center_new_data[m].name,
                          ...newObj,
                          total: 0,
                          percentage: 0,
                        });
                      }
                    }
                    for (let a = 0; a < newData.length; a++) {
                      for (let k = 0; k < expenditure_months.length; k++) {
                        let expenditure_month = expenditure_months[k];
                        let monthData =
                          res.indirect_expenditure_data[expenditure_month];
                        if (monthData.length > 0) {
                          for (let b = 0; b < monthData.length; b++) {
                            if (
                              newData[a].account_name ===
                                monthData[b].account_name &&
                              newData[a].costing_center ===
                                monthData[b].cost_center_name
                            ) {
                              newData[a][expenditure_month] = Number(
                                monthData[b].total_expense
                              ).toFixed(2);
                              newData[a].total += Number(
                                newData[a][expenditure_month]
                              );
                            }
                          }
                        }
                      }
                    }

                    let filteredData = [];
                    if (accounts.length === 0) {
                      filteredData = newData.filter((e, indx) => e.total !== 0);
                    } else {
                      for (let j = 0; j < accounts.length; j++) {
                        for (let l = 0; l < newData.length; l++) {
                          if (accounts[j] === newData[l].account_name) {
                            filteredData.push(newData[l]);
                          }
                        }
                      }
                    }
                    let newFilteredData = [];
                    if (his_expenditure_group.length === 0) {
                      newFilteredData = filteredData.filter(
                        (e, indx) => e.total !== 0
                      );
                    } else {
                      for (let j = 0; j < his_expenditure_group.length; j++) {
                        for (let l = 0; l < filteredData.length; l++) {
                          if (
                            his_expenditure_group[j] === filteredData[l].name
                          ) {
                            newFilteredData.push(filteredData[l]);
                          }
                        }
                      }
                    }
                    newFilteredData.sort((a, b) => {
                      if (a.name < b.name) return -1;
                      if (a.name > b.name) return 1;
                      return 0;
                    });

                    let grandTotal = this.getFinalTotal(
                      newFilteredData,
                      "total"
                    );
                    let grandTotalRow = {
                      name: "Total",
                      account_name: "",
                      costing_center: "",
                      total: this.getFinalTotal(newFilteredData, "total"),
                      percentage: "100%",
                    };
                    for (let u = 0; u < expenditure_months.length; u++) {
                      let month = expenditure_months[u];
                      grandTotalRow[month] = this.getFinalTotal(
                        filteredData,
                        month
                      );
                    }
                    let finalRow = {
                      name: "Total",
                      account_name: "",
                      costing_center: "",
                      total: this.getFinalTotal(newFilteredData, "total"),
                      percentage: "100%",
                    };
                    for (let u = 0; u < expenditure_months.length; u++) {
                      let month = expenditure_months[u];
                      finalRow[month] = this.getFinalTotal(filteredData, month);
                    }
                    for (let uc = 0; uc < newFilteredData.length; uc++) {
                      newFilteredData[uc].percentage = (
                        (Number(newFilteredData[uc].total) /
                          Number(grandTotal)) *
                        100
                      ).toFixed(2);
                    }
                    let groupedData = [];
                    let groupData = [];
                    var result = newFilteredData.reduce(function (r, a) {
                      r[a.name] = r[a.name] || [];
                      r[a.name].push(a);
                      return r;
                    }, Object.create(null));
                    for (let l = 0; l < Object.keys(result).length; l++) {
                      groupedData.push({
                        name: Object.keys(result)[l],
                        groupedData: result[Object.keys(result)[l]],
                      });
                      var groupResult = result[Object.keys(result)[l]].reduce(
                        function (r, a) {
                          r[a.account_name] = r[a.account_name] || [];
                          r[a.account_name].push(a);
                          return r;
                        },
                        Object.create(null)
                      );
                      for (
                        let ml = 0;
                        ml < Object.keys(groupResult).length;
                        ml++
                      ) {
                        let grandTotal = this.getFinalTotal(
                          groupResult[Object.keys(groupResult)[ml]],
                          "total"
                        );
                        let totalRow = {
                          name: "",
                          account_name: "",
                          costing_center: "Total",
                          total: grandTotal,
                          percentage: "100%",
                        };
                        for (let u = 0; u < expenditure_months.length; u++) {
                          let month = expenditure_months[u];
                          totalRow[month] = this.getFinalTotal(
                            groupResult[Object.keys(groupResult)[ml]],
                            month
                          );
                        }
                        let groupTotal = this.getFinalTotal(
                          result[Object.keys(result)[l]],
                          "total"
                        );
                        let groupTotalRow = {
                          name: ml === 0 ? Object.keys(result)[l] : "",
                          account_name: Object.keys(groupResult)[ml],
                          costing_center: "Group Total",
                          total: groupTotal,
                          percentage: "100%",
                        };
                        for (let u = 0; u < expenditure_months.length; u++) {
                          let month = expenditure_months[u];
                          groupTotalRow[month] = this.getFinalTotal(
                            result[Object.keys(result)[l]],
                            month
                          );
                        }
                        for (
                          let uc = 0;
                          uc < groupResult[Object.keys(groupResult)[ml]].length;
                          uc++
                        ) {
                          groupResult[Object.keys(groupResult)[ml]][
                            uc
                          ].percentage = (
                            (Number(
                              groupResult[Object.keys(groupResult)[ml]][uc]
                                .total
                            ) /
                              Number(grandTotal)) *
                            100
                          ).toFixed(2);
                          if (uc !== 0) {
                            groupResult[Object.keys(groupResult)[ml]][
                              uc
                            ].account_name = "";
                            groupResult[Object.keys(groupResult)[ml]][uc].name =
                              "";
                          }
                        }
                        groupData.push({
                          ...groupTotalRow,
                          groupedData: [
                            ...groupResult[Object.keys(groupResult)[ml]],
                            totalRow,
                          ],
                        });
                      }
                    }
                    let groupingData = [];
                    var groupingResult = groupData.reduce(function (r, a) {
                      r[a.name] = r[a.name] || [];
                      r[a.name].push(a);
                      return r;
                    }, Object.create(null));
                    for (
                      let l = 0;
                      l < Object.keys(groupingResult).length;
                      l++
                    ) {
                      groupingData.push({
                        name: Object.keys(groupingResult)[l],
                        groupedData:
                          groupingResult[Object.keys(groupingResult)[l]],
                      });
                    }
                    var newGroupingData = groupingData;
                    let expert_data = [];
                    for (let u = 0; u < newGroupingData.length; u++) {
                      for (
                        let uc = 0;
                        uc < newGroupingData[u].groupedData.length;
                        uc++
                      ) {
                        expert_data = [
                          ...expert_data,
                          ...newGroupingData[u].groupedData[uc].groupedData,
                        ];
                      }
                    }
                    this.setState({
                      expenditure_history: res.indirect_expenditure_data,
                      expenditure_history_data: newData,
                      expenditure_grouped_data: groupedData,
                      expenditure_grouping_data: groupingData,
                      excelData: expert_data,
                      finalRow: finalRow,
                      getData: false,
                      indirect_expenditure_months: expenditure_months,
                    });
                  }
                } else {
                  alert(
                    "Unexpected error occured. Please contact administrator."
                  );
                }
              } else if (res.code === "601") {
                this.setState({
                  snackBarErrorOpen: true,
                  getData: false,
                  errorMsg: res.message,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    }
  };

  toLocaleString(value) {
    if (value) {
      return Number(value).toLocaleString();
    } else {
      return null;
    }
  }

  getGrandTotal = (column) => {
    let sum = 0;
    for (let c = 0; c < this.state.expenditure_grouping_data.length; c++) {
      for (
        let cv = 0;
        cv < this.state.expenditure_grouping_data[c].groupedData.length;
        cv++
      ) {
        for (
          let v = 0;
          v <
          this.state.expenditure_grouping_data[c].groupedData[cv].groupedData
            .length;
          v++
        ) {
          sum += Number(
            this.state.expenditure_grouping_data[c].groupedData[cv].groupedData[
              v
            ][column]
          );
        }
      }
    }
    return sum.toFixed(2);
  };

  getTotal = (column, data) => {
    let sum = 0;
    for (let c = 0; c < data.length; c++) {
      for (let cv = 0; cv < data[c].groupedData.length; cv++) {
        sum += Number(data[c].groupedData[cv][column]);
      }
    }
    return sum.toFixed(2);
  };

  getSubTotal = (column, data) => {
    let sum = 0;
    for (let c = 0; c < data.length; c++) {
      sum += Number(data[c][column]);
    }
    return sum.toFixed(2);
  };

  getFinalTotal = (data, column) => {
    let sum = 0;
    for (let c = 0; c < data.length; c++) {
      sum += Number(data[c][column]);
    }
    return sum.toFixed(2);
  };

  convertExcelHandler = (data, filename) => {
    let newData = [...data, this.state.finalRow];
    // var arrData = typeof data != 'object' ? JSON.parse(newData) : newData;
    // let Modified = arrData.map(
    //   obj => {
    //     return {
    //       "Business No": obj.business_no,
    //       "Business type": obj.business_type,
    //       "Consignment Type": obj.consignment_type,
    //       "Mother Vessel Name": obj.mother_vessel_name,
    //       "Shipment Type": obj.shipment_type,
    //       "Buy Back Involved": obj.buy_back_involved,
    //       "Vessel Type": obj.vessel_type,
    //       "Stevedore Vendor": obj.stevedore_vendor,
    //       "Floating Crane Vendor": obj.floating_crane_vendor,
    //       "Floating Crane Name": obj.floating_crane_name,
    //       "Loading Anchorage": obj.loading_anchorage,
    //       "Load Port Agent": obj.load_port_agent,
    //       "Load Rate per MT/Day": obj.load_rate_per_day,
    //       "Vessel Freight": obj.vessel_freight,
    //       "Vessel Owner": obj.vessel_owner,
    //       "Vessel Freight Price": obj.vessel_freight_price,
    //       "Vessel Arrived": obj.vessel_arrived_on,
    //       "ETA / NOR": obj.eta_nor,
    //       "NOR Acceptance": obj.nor_acceptance,
    //       "Documents on-board": obj.documents_on_board,
    //       "Vessel Sailing": obj.vessel_sailing,
    //       "Demurrage Rate from Buyer": obj.demurrage_rate_from_buyer,
    //       "Demurrage Rate to Supplier": obj.demurrage_rate_to_supplier,
    //       "Stowage Plan in MT": obj.stowage_plan_in_mt,
    //       "Supply Tonnage in MT": obj.supply_tonnage_in_mt,
    //       "Remarks": obj.remarks,
    //     }
    //   }
    // );
    exportToExcel(newData, filename);
  };

  render() {
    if (this.state.isLoading) {
      return<Loader />
    } else {
      return (
        <div className="card">
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={1000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <div className="row mb-2">
            <div className="col-lg-3 p-0">
              <label className="form_label">Expenditure Group</label>
              <Autocomplete
                multiple
                limitTags={1}
                options={this.state.expenditure_groups}
                getOptionLabel={(option) => option.name}
                onChange={(event, value) => {
                  let values = [];
                  let accValues = [];
                  if (value.length > 0) {
                    for (let i = 0; i < value.length; i++) {
                      values.push(value[i].name);
                      for (
                        let c = 0;
                        c < this.state.acc_name_drop_data.length;
                        c++
                      ) {
                        if (
                          value[i].name ===
                          this.state.acc_name_drop_data[c].expenditure_group
                        ) {
                          accValues.push(this.state.acc_name_drop_data[c]);
                        }
                      }
                    }
                  } else {
                    for (let i = 0; i < value.length; i++) {
                      values.push(value[i].name);
                    }
                    accValues = this.state.acc_name_drop_data;
                  }
                  this.setState({
                    his_expenditure_group: values,
                    acc_name_drop_values: accValues,
                  });
                }}
                name="Cost Center"
                size="small"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={
                      this.state.his_expenditure_group.length === 0
                        ? "Expenditure Group"
                        : ""
                    }
                    variant="outlined"
                    //style={{ top: 8 }}
                    fullWidth
                  />
                )}
                style={{ display: "contents" }}
              />
            </div>
            <div className="col-lg-3 pr-0">
              <label className="form_label mb-0">Account Name</label>
              <Autocomplete
                multiple
                limitTags={1}
                options={this.state.acc_name_drop_values}
                getOptionLabel={(option) => option.name}
                onChange={(event, value) => {
                  let values = [];
                  let accounts = [];
                  for (let i = 0; i < value.length; i++) {
                    values.push(value[i].id);
                    accounts.push(value[i].name);
                  }
                  this.setState({ account_id: values, accounts: accounts });
                }}
                name="Account Names"
                size="small"
                // value={this.state.selected_acc_drop_value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={
                      this.state.account_id.length === 0 ? "Account Name" : ""
                    }
                    variant="outlined"
                    style={{ top: 8 }}
                    fullWidth
                  />
                )}
                style={{ display: "contents" }}
              />
            </div>
            <div className="col-lg-3 pr-0">
              <label className="form_label mb-0">Cost Center</label>
              <Autocomplete
                multiple
                limitTags={1}
                options={this.state.cost_center_drop_values}
                getOptionLabel={(option) => option.name}
                onChange={(event, value) => {
                  let values = [];
                  for (let i = 0; i < value.length; i++) {
                    values.push(value[i].id);
                  }
                  this.setState({ cost_center: values });
                }}
                name="Account Names"
                size="small"
                // value={this.state.selected_acc_drop_value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={
                      this.state.cost_center.length === 0 ? "Cost Center" : ""
                    }
                    variant="outlined"
                    style={{ top: 8 }}
                    fullWidth
                  />
                )}
                style={{ display: "contents" }}
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-3 p-0">
              <label className="form_label mb-0">
                Start Date <span style={{ color: "red" }}>*</span>
              </label>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  disableToolbar
                  autoOk={true}
                  inputVariant="outlined"
                  variant="inline"
                  margin="dense"
                  fullWidth
                  format="dd/MM/yyyy"
                  value={this.state.start_date}
                  onChange={(date) => {
                    this.setState({
                      start_date: dateFormateToDB(date),
                    });
                  }}
                  placeholder="Start Date"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <EventIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div className="col-lg-3 pr-0">
              <label className="form_label mb-0">
                End Date <span style={{ color: "red" }}>*</span>
              </label>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  disableToolbar
                  autoOk={true}
                  inputVariant="outlined"
                  variant="inline"
                  margin="dense"
                  fullWidth
                  format="dd/MM/yyyy"
                  value={this.state.end_date}
                  minDate={new Date(this.state.start_date)}
                  onChange={(date) => {
                    this.setState({
                      end_date: dateFormateToDB(date),
                    });
                  }}
                  placeholder="End Date"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <EventIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>

            <div className="col-lg-1">
              <button
                className="header_button header_button_text"
                style={{ width: "auto", marginTop: "30px" }}
                onClick={this.searchExpenditureHisHandler}
              >
                Search
              </button>
            </div>
            {this.state.expenditure_grouping_data.length > 0 && (
              <span>
                <FontAwesomeIcon
                  icon={faFileExcel}
                  style={{
                    position: "relative",
                    top: 30,
                    margin: 5,
                    fontSize: 30,
                    color: "#0abb87",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    this.convertExcelHandler(
                      this.state.excelData,
                      "Cost Center"
                    )
                  }
                />
              </span>
            )}
          </div>

          <div className="row">
            {this.state.getData && (
              <Loader />
            )}
            {this.state.expenditure_grouping_data.length > 0 && (
              <div className="col p-0">
                {this.state.expenditure_grouping_data.map((data, index) => (
                  <TableContainer component={Paper}>
                    <TableBody style={{ display: index === 0 ? "" : "none" }}>
                      <TableRow style={{ backgroundColor: "whitesmoke" }}>
                        <TableCell align="left" id="table_header"></TableCell>
                        {this.state.indirect_expenditure_months.map((row) => (
                          <TableCell align="right" style={{ minWidth: 100 }}>
                            {row}
                          </TableCell>
                        ))}
                        <TableCell align="right">Total</TableCell>
                        <TableCell align="right">Percentage</TableCell>
                      </TableRow>
                    </TableBody>
                    {data.groupedData.map((tata, hexa) => (
                      <TableBody>
                        <TableRow
                          style={{
                            backgroundColor: "whitesmoke",
                            display: hexa === 0 ? "" : "none",
                          }}
                        >
                          <TableCell align="left" id="table_header">
                            {data.name}
                          </TableCell>
                          {this.state.indirect_expenditure_months.map((row) => (
                            <TableCell
                              align="right"
                              style={{ minWidth: 100 }}
                            ></TableCell>
                          ))}
                          <TableCell align="right"></TableCell>
                          <TableCell align="right"></TableCell>
                        </TableRow>
                        <TableRow style={{ backgroundColor: "whitesmoke" }}>
                          <TableCell align="left" id="table_sub_header">
                            {tata.account_name}
                          </TableCell>
                          {this.state.indirect_expenditure_months.map((row) => (
                            <TableCell
                              align="right"
                              style={{ minWidth: 100 }}
                            ></TableCell>
                          ))}
                          <TableCell align="right"></TableCell>
                          <TableCell align="right"></TableCell>
                        </TableRow>
                        {tata.groupedData.map((id, idx) => (
                          <TableRow
                            key={idx}
                            style={{
                              backgroundColor:
                                id.costing_center === "Total"
                                  ? "aliceblue"
                                  : "",
                            }}
                          >
                            {/* <TableCell style={{textAlign: 'right'}} rowSpan={1}></TableCell> */}
                            <TableCell align="left">
                              {id.costing_center}
                            </TableCell>
                            {this.state.indirect_expenditure_months.map(
                              (row) => (
                                <TableCell align="right">
                                  {Number(id[row]).toFixed(2)}
                                </TableCell>
                              )
                            )}
                            <TableCell align="right">
                              {Number(id.total).toFixed(2)}
                            </TableCell>
                            <TableCell align="right">
                              {Number(
                                id.costing_center !== "Total"
                                  ? id.percentage
                                  : "100.00"
                              ).toFixed(2)}
                              %
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow
                          style={{
                            backgroundColor:
                              tata.costing_center === "Group Total"
                                ? "antiquewhite"
                                : "",
                            display:
                              hexa === data.groupedData.length - 1
                                ? ""
                                : "none",
                          }}
                        >
                          <TableCell style={{ textAlign: "left" }}>
                            Group Total
                          </TableCell>
                          {this.state.indirect_expenditure_months.map((row) => (
                            <TableCell style={{ textAlign: "right" }}>
                              {Number(tata[row]).toFixed(2)}
                            </TableCell>
                          ))}
                          <TableCell style={{ textAlign: "right" }}>
                            {Number(tata.total).toFixed(2)}
                          </TableCell>
                          <TableCell style={{ textAlign: "right" }}></TableCell>
                        </TableRow>
                      </TableBody>
                    ))}
                    {/* <TableBody>
                      <TableRow style={{backgroundColor: 'antiquewhite'}}>
                        <TableCell style={{textAlign: 'left'}}>Group Total</TableCell>
                        {this.state.indirect_expenditure_months.map((row) => (
                          <TableCell style={{textAlign: 'right'}}>{this.getTotal(row, data.groupedData)}</TableCell>
                        ))}
                        <TableCell style={{textAlign: 'right'}}>{this.getTotal('total', data.groupedData)}</TableCell>
                        <TableCell style={{textAlign: 'right'}}></TableCell>
                      </TableRow>
                    </TableBody> */}
                    <TableBody
                      style={{
                        display:
                          index ===
                          this.state.expenditure_grouping_data.length - 1
                            ? ""
                            : "none",
                      }}
                    >
                      <TableRow style={{ backgroundColor: "burlywood" }}>
                        <TableCell style={{ textAlign: "left" }}>
                          Grand Total
                        </TableCell>
                        {this.state.indirect_expenditure_months.map((row) => (
                          <TableCell style={{ textAlign: "right" }}>
                            {this.state.finalRow[row]}
                          </TableCell>
                        ))}
                        <TableCell style={{ textAlign: "right" }}>
                          {this.state.finalRow["total"]}
                        </TableCell>
                        <TableCell style={{ textAlign: "right" }}></TableCell>
                      </TableRow>
                    </TableBody>
                  </TableContainer>
                ))}
              </div>
            )}
          </div>
        </div>
      );
    }
  }
}
