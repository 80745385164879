import React, { Component } from "react";
import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import api from "../../../api/api";

import { Alert } from "@material-ui/lab";
import {
  MenuItem,
  TextField,
  InputAdornment,
  Snackbar,
} from "@material-ui/core";
import { localDateFormate, pad } from "../../common/common";
import FileUpload from "../../common/FileUpload";
import Loader from "../../common/Loader";
export default class BargeSupplierDemurrageUpdate extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      activeStep: 0,
      selectedBargeId: null,
      invoice_no: "",
      invoice_date: null,
      ppn: "",
      ppn_date: null,
      dmr_from_supplier_barge_demurrage: "",
      dmr_from_supplier_barge_currency: "0",
      dmr_from_supplier_demurrage_in_days: "",
      dmr_from_supplier_demurrage_value: "",
      dmr_from_supplier_currency_xchg_rate: "",
      dmr_from_supplier_pph23_account: "0",
      dmr_from_supplier_base_price: "",
      dmr_from_supplier_vat_percent: "10",
      dmr_from_supplier_vat_value: "",
      dmr_from_supplier_pph23_percent: "",
      dmr_from_supplier_pph23_value: "",
      dmr_from_supplier_total: "",
      dmr_from_supplier_ctc: "",
      dmr_from_supplier_ctc_in_local_currency: "",

      NORAnchorageDate: null,

      dmr_to_supplier_time_allowed_for_discharge: "",
      dmr_to_supplier_barge_detention: "",
      dmr_to_supplier_detention_in_days: "",
      dmr_to_supplier_detention_value: "",
      dmr_to_supplier_currency_xchg_rate: "",
      dmr_to_supplier_pph23_account: "0",
      dmr_to_supplier_base_price: "",
      dmr_to_supplier_barge_currency: "0",
      dmr_to_supplier_vat_percent: "10",
      dmr_to_supplier_vat_value: "",
      dmr_to_supplier_pph23_percent: "",
      dmr_to_supplier_pph23_value: "",
      dmr_to_supplier_total: "",
      dmr_to_supplier_ctc: "",
      dmr_to_supplier_ctc_in_local_currency: "",

      currencyData: [],
      totalBarges: [],
      files: [
        {
          file_description: "",
          file_name: "",
          file_extension: "",
          file_content: "",
          feature_name:
            this.props.demurrage_type === "company_to_supplier"
              ? "Barge_Demurrage_To_Supplier"
              : "Barge_Demurrage_From_Supplier",
        },
      ],
      features: [],
      isLoading: true,
      costing_files: [],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    let features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    // if (!(features.includes('134')  || features.includes('97') )) {
    //   window.location.href = '/dashboard';
    // }

    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const getCurrencies = await api.getCurrencies(loginUserID, idToken);
    if (getCurrencies.status >= 200 && getCurrencies.status < 300) {
      getCurrencies.json().then((res) => {
        if (res.code === "200") {
          if (res.Currency) {
            var currencies = [
              <MenuItem
                style={{ fontFamily: "Poppins", fontSize: "14px" }}
                value="0"
                key={-1}
                disabled
              >
                Please Select
              </MenuItem>,
            ];
            for (var i in res.Currency) {
              currencies.push(
                <MenuItem
                  style={{ fontFamily: "Poppins", fontSize: "14px" }}
                  value={res.Currency[i].currency}
                  key={[i]}
                >
                  {res.Currency[i].currency}
                </MenuItem>
              );
            }
            this.setState({
              currencyData: currencies,
            });
          } else {
            alert("Unexpected error occured. Please contact administrator.");
          }
        } else if (res.code === "601") {
          this.setState({
            errorMsg: res.message,
            snackBarErrorOpen: true,
          });
        } else if (res.code === "607") {
          window.location.href = "/logout";
        }
      });
    }

    let demurrage_type = this.props.demurrage_type;
    let comboID = this.props.comboID;
    const demurrage_to_supplier_id = comboID.split("$#")[0];
    if (demurrage_type === "company_to_supplier") {
      api
        .get_barge_demurrage_to_supplier(
          loginUserID,
          idToken,
          demurrage_to_supplier_id
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                if (res.barge_demurrage_to_supplier.length > 0) {
                  let days = "0";
                  let hours = "0";
                  let minutes = "0";
                  if (
                    res.barge_demurrage_to_supplier[0].time_at_anchorage_days
                  ) {
                    days =
                      res.barge_demurrage_to_supplier[0].time_at_anchorage_days;
                  }
                  if (
                    res.barge_demurrage_to_supplier[0].time_at_anchorage_hours
                  ) {
                    hours =
                      res.barge_demurrage_to_supplier[0]
                        .time_at_anchorage_hours;
                  }
                  if (
                    res.barge_demurrage_to_supplier[0].time_at_anchorage_minutes
                  ) {
                    minutes =
                      res.barge_demurrage_to_supplier[0]
                        .time_at_anchorage_minutes;
                  }
                  this.setState({
                    selectedBargeId:
                      res.barge_demurrage_to_supplier[0].barge_id,
                    costing_no: res.barge_demurrage_to_supplier[0].costing_no,
                    invoice_file:
                      res.barge_demurrage_to_supplier[0].invoice_file,
                    invoice_no: res.barge_demurrage_to_supplier[0].invoice_no,
                    invoice_date:
                      res.barge_demurrage_to_supplier[0].invoice_date,
                    ppn: res.barge_demurrage_to_supplier[0].ppn,
                    ppn_date: res.barge_demurrage_to_supplier[0].ppn_date,
                    dmr_to_supplier_barge_detention:
                      res.barge_demurrage_to_supplier[0]
                        .dmr_to_supplier_barge_detention,
                    dmr_to_supplier_base_price:
                      res.barge_demurrage_to_supplier[0]
                        .dmr_to_supplier_base_price,
                    dmr_to_supplier_ctc:
                      res.barge_demurrage_to_supplier[0].dmr_to_supplier_ctc,
                    dmr_to_supplier_ctc_in_local_currency:
                      res.barge_demurrage_to_supplier[0]
                        .dmr_to_supplier_ctc_in_local_currency,
                    dmr_to_supplier_currency_xchg_rate:
                      res.barge_demurrage_to_supplier[0]
                        .dmr_to_supplier_currency_xchg_rate,
                    dmr_to_supplier_detention_in_days:
                      res.barge_demurrage_to_supplier[0]
                        .dmr_to_supplier_detention_in_days,
                    dmr_to_supplier_detention_value:
                      res.barge_demurrage_to_supplier[0]
                        .dmr_to_supplier_detention_value,
                    dmr_to_supplier_pph23_account:
                      res.barge_demurrage_to_supplier[0]
                        .dmr_to_supplier_pph23_account,
                    dmr_to_supplier_pph23_percent:
                      res.barge_demurrage_to_supplier[0]
                        .dmr_to_supplier_pph23_percent,
                    dmr_to_supplier_pph23_value:
                      res.barge_demurrage_to_supplier[0]
                        .dmr_to_supplier_pph23_value,
                    dmr_to_supplier_time_allowed_for_discharge:
                      res.barge_demurrage_to_supplier[0]
                        .dmr_to_supplier_time_allowed_for_discharge,
                    dmr_to_supplier_total:
                      res.barge_demurrage_to_supplier[0].dmr_to_supplier_total,
                    dmr_to_supplier_vat_percent:
                      res.barge_demurrage_to_supplier[0]
                        .dmr_to_supplier_vat_percent,
                    dmr_to_supplier_vat_value:
                      res.barge_demurrage_to_supplier[0]
                        .dmr_to_supplier_vat_value,
                    NORAnchorageDate:
                      res.barge_demurrage_to_supplier[0].nor_anchorage,
                    files: res.barge_demurrage_to_supplier[0].files
                      ? res.barge_demurrage_to_supplier[0].files
                      : [],
                    completionDischargeDate:
                      res.barge_demurrage_to_supplier[0].completion_discharge,
                    timeAtAnchorage:
                      days +
                      " days, " +
                      hours +
                      " hours, " +
                      minutes +
                      " minutes",
                    timeAtAnchorageDays: days,
                    timeAtAnchorageHours: hours,
                    timeAtAnchorageMinutes: minutes,
                    //isLoading: false,
                  });
                } else {
                  this.setState({
                    isLoading: false,
                  });
                }
              } else if (res.code === "601") {
                this.setState({
                  snackBarErrorOpen: true,
                  errorMsg: res.message,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    }
    // else if (demurrage_type === "supplier_to_company") {
    //   api
    //     .get_barge_demurrage_from_supplier(
    //       loginUserID,
    //       idToken,
    //       demurrage_from_supplier_id
    //     )
    //     .then((response) => {
    //       if (response.status >= 200 && response.status < 300) {
    //         response.json().then((res) => {
    //           if (res.code === "200") {
    //             if (res.barge_demurrage_from_supplier.length > 0) {
    //               this.setState({
    //                 costing_no: res.barge_demurrage_from_supplier[0].costing_no,
    //                 invoice_file:
    //                   res.barge_demurrage_from_supplier[0].invoice_file,
    //                 selectedBargeId:
    //                   res.barge_demurrage_from_supplier[0].barge_id,
    //                 dmr_from_supplier_barge_currency:
    //                   res.barge_demurrage_from_supplier[0]
    //                     .dmr_from_supplier_barge_currency,
    //                 dmr_from_supplier_barge_demurrage:
    //                   res.barge_demurrage_from_supplier[0]
    //                     .dmr_from_supplier_barge_demurrage,
    //                 dmr_from_supplier_base_price:
    //                   res.barge_demurrage_from_supplier[0]
    //                     .dmr_from_supplier_base_price,
    //                 dmr_from_supplier_ctc:
    //                   res.barge_demurrage_from_supplier[0]
    //                     .dmr_from_supplier_ctc,
    //                 dmr_from_supplier_ctc_in_local_currency:
    //                   res.barge_demurrage_from_supplier[0]
    //                     .dmr_from_supplier_ctc_in_local_currency,
    //                 dmr_from_supplier_currency_xchg_rate:
    //                   res.barge_demurrage_from_supplier[0]
    //                     .dmr_from_supplier_currency_xchg_rate,
    //                 dmr_from_supplier_demurrage_in_days:
    //                   res.barge_demurrage_from_supplier[0]
    //                     .dmr_from_supplier_demurrage_in_days,
    //                 dmr_from_supplier_demurrage_value:
    //                   res.barge_demurrage_from_supplier[0]
    //                     .dmr_from_supplier_demurrage_value,
    //                 dmr_from_supplier_pph23_account:
    //                   res.barge_demurrage_from_supplier[0]
    //                     .dmr_from_supplier_pph23_account,
    //                 dmr_from_supplier_pph23_percent:
    //                   res.barge_demurrage_from_supplier[0]
    //                     .dmr_from_supplier_pph23_percent,
    //                 dmr_from_supplier_pph23_value:
    //                   res.barge_demurrage_from_supplier[0]
    //                     .dmr_from_supplier_pph23_value,
    //                 dmr_from_supplier_total:
    //                   res.barge_demurrage_from_supplier[0]
    //                     .dmr_from_supplier_total,
    //                 dmr_from_supplier_vat_percent:
    //                   res.barge_demurrage_from_supplier[0]
    //                     .dmr_from_supplier_vat_percent,
    //                 dmr_from_supplier_vat_value:
    //                   res.barge_demurrage_from_supplier[0]
    //                     .dmr_from_supplier_vat_value,
    //                 invoice_date:
    //                   res.barge_demurrage_from_supplier[0].invoice_date,
    //                 ppn: res.barge_demurrage_from_supplier[0].ppn,
    //                 ppn_date: res.barge_demurrage_from_supplier[0].ppn_date,
    //                 invoice_no: res.barge_demurrage_from_supplier[0].invoice_no,
    //                 files: res.barge_demurrage_from_supplier[0].files
    //                   ? res.barge_demurrage_from_supplier[0].files
    //                   : [],
    //                 //isLoading: false,
    //               });
    //             } else {
    //               this.setState({
    //                 isLoading: false,
    //               });
    //             }
    //           } else if (res.code === "601") {
    //             this.setState({
    //               snackBarErrorOpen: true,
    //               errorMsg: res.message,
    //             });
    //           } else if (res.code === "607") {
    //             window.location.href = "/logout";
    //           }
    //         });
    //       }
    //     });
    // }
    const get_barges = await api.get_barges_for_barge_fin_demurrage(
      loginUserID,
      idToken,
      demurrage_type
    );
    if (get_barges.status >= 200 && get_barges.status < 300) {
      get_barges.json().then((res) => {
        if (res.code === "200") {
          let barges = [];
          if (this.props.demurrage_type === "company_to_supplier") {
            barges = res.barges.filter(
              (e) =>
                e.barge_to_suppiler_demurrage_id === null ||
                e.barge_to_suppiler_demurrage_id === demurrage_to_supplier_id
            );
          }
          // else if (this.props.demurrage_type === "supplier_to_company") {
          //   barges = res.barges.filter(
          //     (e) =>
          //       e.barge_from_suppiler_demurrage_id === null ||
          //       e.barge_from_suppiler_demurrage_id ===
          //         demurrage_from_supplier_id
          //   );
          // }
          this.setState({
            totalBarges: barges,
            isLoading: false,
          });
        } else if (res.code === "601") {
          this.setState({
            snackBarErrorOpen: true,
            errorMsg: res.message,
          });
        } else if (res.code === "607") {
          window.location.href = "/logout";
        }
      });
    }
  }

  onBargesCheck = (bargeId, checked, barge) => {
    if (checked) {
      this.setState(
        {
          selectedBargeId: bargeId,
          //isLoading: true
        },
        () => {
          //this.callingAsync(bargeId);
          let days = "0";
          let hours = "0";
          let minutes = "0";
          if (barge.time_at_anchorage_days) {
            days = barge.time_at_anchorage_days;
          }
          if (barge.time_at_anchorage_hours) {
            hours = barge.time_at_anchorage_hours;
          }
          if (barge.time_at_anchorage_minutes) {
            minutes = barge.time_at_anchorage_minutes;
          }
          this.setState({
            // sales_type: res.business_no_barge.sales_type,
            purchase_type: barge.purchase_type,
            //dmr_to_buyer_barge_currency: res.business_no_barge.barging_base_price_currency === null ? '0' : res.business_no_barge.barging_base_price_currency,
            loadingJetty:
              barge.loading_jetty === null ? "" : barge.loading_jetty,
            completionTime:
              barge.completion_time === "" ? null : barge.completion_time,
            NORAnchorageDate:
              barge.nor_anchorage === "" ? null : barge.nor_anchorage,
            completionDischargeDate:
              barge.completion_discharge === ""
                ? null
                : barge.completion_discharge,
            timeAtAnchorage:
              days + " days, " + hours + " hours, " + minutes + " minutes",
            timeAtAnchorageDays: days,
            timeAtAnchorageHours: hours,
            timeAtAnchorageMinutes: minutes,
            dmr_to_supplier_detention_in_days: days,
          });
        }
      );
    } else {
      this.setState({
        selectedBargeId: null,
      });
    }
  };

  dmr_from_supplier_calculations = (value) => {
    var dmr_from_supplier_barge_demurrage = this.state
      .dmr_from_supplier_barge_demurrage
      ? this.state.dmr_from_supplier_barge_demurrage
      : 1;
    var dmr_from_supplier_demurrage_in_days = this.state
      .dmr_from_supplier_demurrage_in_days
      ? this.state.dmr_from_supplier_demurrage_in_days
      : 0;
    var dmr_from_supplier_currency_xchg_rate = this.state
      .dmr_from_supplier_currency_xchg_rate
      ? this.state.dmr_from_supplier_currency_xchg_rate
      : 1;

    if (
      ((config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL") &&
        this.state.dmr_from_supplier_barge_currency === "USD") ||
      ((config.company === "SDAM" ||
        config.company === "SRK" ||
        config.company === "BTR") &&
        this.state.dmr_from_supplier_barge_currency === "IDR")
    ) {
      dmr_from_supplier_currency_xchg_rate = 1;
    }
    let dmr_from_supplier_demurrage_value = (
      (Number(dmr_from_supplier_barge_demurrage) *
        Number(dmr_from_supplier_demurrage_in_days)) /
      dmr_from_supplier_currency_xchg_rate
    ).toFixed(2);
    if (
      (config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL") &&
      this.state.dmr_to_bg_vend_barge_currency !== "USD"
    ) {
      dmr_from_supplier_demurrage_value = (
        (Number(dmr_from_supplier_barge_demurrage) *
          Number(dmr_from_supplier_demurrage_in_days)) /
        dmr_from_supplier_currency_xchg_rate
      ).toFixed(2);
    } else if (
      (config.company === "SDAM" ||
        config.company === "SRK" ||
        config.company === "BTR") &&
      this.state.dmr_to_bg_vend_barge_currency !== "IDR"
    ) {
      dmr_from_supplier_demurrage_value = (
        Number(dmr_from_supplier_barge_demurrage) *
        Number(dmr_from_supplier_demurrage_in_days) *
        Number(dmr_from_supplier_currency_xchg_rate)
      ).toFixed(2);
    }
    if (value) {
      dmr_from_supplier_demurrage_value = this.state
        .dmr_from_supplier_demurrage_value
        ? this.state.dmr_from_supplier_demurrage_value
        : 1;
    }

    let dmr_from_supplier_base_price = Number(
      dmr_from_supplier_demurrage_value
    ).toFixed(2);
    let dmr_from_supplier_vat_percent = 10;
    let dmr_from_supplier_vat_value = (
      (Number(dmr_from_supplier_base_price) * 10) /
      100
    ).toFixed(2);
    let dmr_from_supplier_pph23_percent = 2;
    let dmr_from_supplier_pph23_value = Number(
      (dmr_from_supplier_base_price * 2) / 100
    ).toFixed(0);
    let dmr_from_supplier_total = (
      Number(dmr_from_supplier_base_price) +
      Number(dmr_from_supplier_vat_value) -
      Number(dmr_from_supplier_pph23_value)
    ).toFixed(2);
    if (
      config.company === "SRPL" ||
      config.company === "AVS" ||
      config.company === "PRPL"
    ) {
      dmr_from_supplier_total = (
        Number(dmr_from_supplier_base_price) +
        Number(dmr_from_supplier_vat_value)
      ).toFixed(2);
    }
    let dmr_from_supplier_ctc = Number(
      Number(dmr_from_supplier_base_price) + Number(dmr_from_supplier_vat_value)
    ).toFixed(2);

    this.setState({
      dmr_from_supplier_demurrage_value: dmr_from_supplier_demurrage_value,
      dmr_from_supplier_base_price: dmr_from_supplier_base_price,
      dmr_from_supplier_vat_percent: dmr_from_supplier_vat_percent,
      dmr_from_supplier_vat_value: dmr_from_supplier_vat_value,
      dmr_from_supplier_pph23_percent: dmr_from_supplier_pph23_percent,
      dmr_from_supplier_pph23_value: dmr_from_supplier_pph23_value,
      dmr_from_supplier_total: dmr_from_supplier_total,
      dmr_from_supplier_ctc: dmr_from_supplier_ctc,
    });

    if (this.state.dmr_from_supplier_pph23_account === "SDAM") {
      let dmr_from_supplier_base_price = Number(
        (dmr_from_supplier_demurrage_value * 100) / 98
      ).toFixed(2);
      let dmr_from_supplier_pph23_percent = 2;
      let dmr_from_supplier_pph23_value = Number(
        ((dmr_from_supplier_base_price * 2) / 100).toFixed(2)
      );
      let dmr_from_supplier_total = Number(
        dmr_from_supplier_demurrage_value
      ).toFixed(2);
      let dmr_from_supplier_ctc = Number(dmr_from_supplier_base_price).toFixed(
        2
      );

      this.setState({
        dmr_from_supplier_base_price: dmr_from_supplier_base_price,
        dmr_from_supplier_pph23_percent: dmr_from_supplier_pph23_percent,
        dmr_from_supplier_pph23_value: dmr_from_supplier_pph23_value,
        dmr_from_supplier_total: dmr_from_supplier_total,
        dmr_from_supplier_ctc: dmr_from_supplier_ctc,
        dmr_from_supplier_vat_percent: "",
        dmr_from_supplier_vat_value: "",
      });
    }
  };

  dmr_to_supplier_calculations = (value) => {
    var dmr_to_supplier_barge_detention = this.state
      .dmr_to_supplier_barge_detention
      ? this.state.dmr_to_supplier_barge_detention
      : 1;
    var dmr_to_supplier_detention_in_days = this.state
      .dmr_to_supplier_detention_in_days
      ? this.state.dmr_to_supplier_detention_in_days
      : 0;
    var dmr_to_supplier_currency_xchg_rate = this.state
      .dmr_to_supplier_currency_xchg_rate
      ? this.state.dmr_to_supplier_currency_xchg_rate
      : 1;
    if (
      ((config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL") &&
        this.state.dmr_to_supplier_barge_currency === "USD") ||
      ((config.company === "SDAM" ||
        config.company === "SRK" ||
        config.company === "BTR") &&
        this.state.dmr_to_supplier_barge_currency === "IDR")
    ) {
      dmr_to_supplier_currency_xchg_rate = 1;
    }
    let dmr_to_supplier_detention_value = (
      (Number(dmr_to_supplier_barge_detention) *
        Number(dmr_to_supplier_detention_in_days)) /
      dmr_to_supplier_currency_xchg_rate
    ).toFixed(2);
    if (
      (config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL") &&
      this.state.dmr_to_bg_vend_barge_currency !== "USD"
    ) {
      dmr_to_supplier_detention_value = (
        (Number(dmr_to_supplier_barge_detention) *
          Number(dmr_to_supplier_detention_in_days)) /
        dmr_to_supplier_currency_xchg_rate
      ).toFixed(2);
    } else if (
      (config.company === "SDAM" ||
        config.company === "SRK" ||
        config.company === "BTR") &&
      this.state.dmr_to_bg_vend_barge_currency !== "IDR"
    ) {
      dmr_to_supplier_detention_value = (
        Number(dmr_to_supplier_barge_detention) *
        Number(dmr_to_supplier_detention_in_days) *
        Number(dmr_to_supplier_currency_xchg_rate)
      ).toFixed(2);
    }
    if (value) {
      dmr_to_supplier_detention_value = this.state
        .dmr_to_supplier_detention_value
        ? this.state.dmr_to_supplier_detention_value
        : 1;
    }
    let dmr_to_supplier_base_price = Number(
      dmr_to_supplier_detention_value
    ).toFixed(2);
    let dmr_to_supplier_vat_percent = 10;
    let dmr_to_supplier_vat_value = (
      (Number(dmr_to_supplier_base_price) * 10) /
      100
    ).toFixed(2);
    let dmr_to_supplier_pph23_percent = 2;
    let dmr_to_supplier_pph23_value = Number(
      (dmr_to_supplier_base_price * 2) / 100
    ).toFixed(0);
    let dmr_to_supplier_total = (
      Number(dmr_to_supplier_base_price) +
      Number(dmr_to_supplier_vat_value) -
      Number(dmr_to_supplier_pph23_value)
    ).toFixed(2);
    if (
      config.company === "SRPL" ||
      config.company === "AVS" ||
      config.company === "PRPL"
    ) {
      dmr_to_supplier_total = (
        Number(dmr_to_supplier_base_price) + Number(dmr_to_supplier_vat_value)
      ).toFixed(2);
    }
    let dmr_to_supplier_ctc = Number(
      Number(dmr_to_supplier_base_price) + Number(dmr_to_supplier_vat_value)
    ).toFixed(2);

    this.setState({
      dmr_to_supplier_detention_value: dmr_to_supplier_detention_value,
      dmr_to_supplier_base_price: dmr_to_supplier_base_price,
      dmr_to_supplier_vat_percent: dmr_to_supplier_vat_percent,
      dmr_to_supplier_vat_value: dmr_to_supplier_vat_value,
      dmr_to_supplier_pph23_percent: dmr_to_supplier_pph23_percent,
      dmr_to_supplier_pph23_value: dmr_to_supplier_pph23_value,
      dmr_to_supplier_total: dmr_to_supplier_total,
      dmr_to_supplier_ctc: dmr_to_supplier_ctc,
    });

    if (this.state.dmr_to_supplier_pph23_account === "SDAM") {
      let dmr_to_supplier_base_price = Number(
        (dmr_to_supplier_detention_value * 100) / 98
      ).toFixed(2);
      let dmr_to_supplier_pph23_percent = 2;
      let dmr_to_supplier_pph23_value = Number(
        ((dmr_to_supplier_base_price * 2) / 100).toFixed(2)
      );
      let dmr_to_supplier_total = Number(
        dmr_to_supplier_detention_value
      ).toFixed(2);
      let dmr_to_supplier_ctc = Number(dmr_to_supplier_base_price).toFixed(2);

      this.setState({
        dmr_to_supplier_base_price: dmr_to_supplier_base_price,
        dmr_to_supplier_pph23_percent: dmr_to_supplier_pph23_percent,
        dmr_to_supplier_pph23_value: dmr_to_supplier_pph23_value,
        dmr_to_supplier_total: dmr_to_supplier_total,
        dmr_to_supplier_ctc: dmr_to_supplier_ctc,
        dmr_to_supplier_vat_percent: "",
        dmr_to_supplier_vat_value: "",
      });
    }
  };

  nextStepHandler = () => {
    if (this.state.activeStep === 0) {
      if (!this.state.selectedBargeId) {
        this.setState({
          errorMsg: "Please Select Barge",
          snackBarErrorOpen: true,
        });
        return;
      }
    }
    this.setState({
      activeStep: this.state.activeStep + 1,
    });
  };

  previousStepHandler = () => {
    this.setState({
      activeStep: this.state.activeStep - 1,
    });
  };

  toLocaleString(value) {
    return value ? Number(value).toLocaleString() : 0;
  }

  submitHandler = () => {
    var {
      invoice_no,
      invoice_date,
      ppn,
      ppn_date,

      dmr_to_supplier_time_allowed_for_discharge,
      dmr_to_supplier_barge_detention,
      dmr_to_supplier_detention_in_days,
      dmr_to_supplier_detention_value,

      dmr_to_supplier_currency_xchg_rate,
      dmr_to_supplier_pph23_account,
      dmr_to_supplier_base_price,
      dmr_to_supplier_vat_percent,
      dmr_to_supplier_vat_value,
      dmr_to_supplier_pph23_percent,
      dmr_to_supplier_pph23_value,
      dmr_to_supplier_total,
      dmr_to_supplier_ctc,
      dmr_to_supplier_ctc_in_local_currency,
    } = this.state;

    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();

    if (!this.state.selectedBargeId) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please select barge",
      });
      return;
    }
    /**
     *  Checking Mandatory files
     */
    var missing_fields = 0;
    let files_missing = 0;
    var files_data = this.state.files;
    for (var obj of files_data) {
      if (obj.file_type !== "" || obj.file_content !== "") {
        if (obj.file_content !== "") {
          if (obj.file_description === "") {
            missing_fields++;
            obj.file_description_error = true;
          } else {
            obj.file_description = obj.file_description.toUpperCase();
          }
        } else if (obj.file_description !== "") {
          if (obj.file_content === "") {
            files_missing++;
          }
        }
      }
    }
    this.setState({
      files: files_data,
    });

    if (missing_fields > 0) {
      this.setState({
        snackBarErrorOpen: true,
        isLoading: false,
        errorMsg: "Please fill mandatory fields.",
      });
      return;
    }
    if (files_missing > 0) {
      this.setState({
        snackBarErrorOpen: true,
        isLoading: false,
        errorMsg: "Please Select file.",
      });
      return;
    }
    var existing_file_ids = [];
    const existing_files = files_data.filter((e) => e.id);
    for (var j of existing_files) {
      existing_file_ids.push(j.id);
    }
    const new_files = files_data.filter((e) => !e.id);
    const content_files = new_files.filter((e) => e.file_content !== "");
    const barge_id = this.state.selectedBargeId;
    const costing_file = this.state.costing_files?.filter(
      (e) => e.file_content !== "" && e.column === "invoice_file"
    );

    let comboID = this.props.comboID;
    const demurrage_to_supplier_id = comboID.split("$#")[0];
    if (this.props.demurrage_type === "company_to_supplier") {
      api
        .update_barge_demurrage_to_supplier(
          loginUserID,
          idToken,
          barge_id,
          demurrage_to_supplier_id,
          invoice_no,
          invoice_date,
          ppn,
          ppn_date,
          dmr_to_supplier_time_allowed_for_discharge,
          dmr_to_supplier_barge_detention,
          dmr_to_supplier_detention_in_days,
          dmr_to_supplier_detention_value,
          dmr_to_supplier_currency_xchg_rate,
          dmr_to_supplier_pph23_account,
          dmr_to_supplier_base_price,
          dmr_to_supplier_vat_percent,
          dmr_to_supplier_vat_value,
          dmr_to_supplier_pph23_percent,
          dmr_to_supplier_pph23_value,
          dmr_to_supplier_total,
          dmr_to_supplier_ctc,
          dmr_to_supplier_ctc_in_local_currency,
          existing_file_ids,
          content_files,
          costing_file
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                this.setState(
                  {
                    snackBarSuccessOpen: true,
                    successMsg: res.message,
                  },
                  () => {
                    this.props.onUpdateCosting(true);
                  }
                );
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  snackBarErrorOpen: true,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    }
    // else if (this.props.demurrage_type === "supplier_to_company") {
    //   api
    //     .update_barge_demurrage_from_supplier(
    //       loginUserID,
    //       idToken,
    //       barge_id,
    //       demurrage_from_supplier_id,
    //       invoice_no,
    //       invoice_date,
    //       ppn,
    //       ppn_date,

    //       dmr_from_supplier_barge_demurrage,
    //       dmr_from_supplier_barge_currency,
    //       dmr_from_supplier_ctc_in_local_currency,
    //       dmr_from_supplier_demurrage_in_days,
    //       dmr_from_supplier_demurrage_value,
    //       dmr_from_supplier_currency_xchg_rate,
    //       dmr_from_supplier_pph23_account,
    //       dmr_from_supplier_base_price,
    //       dmr_from_supplier_vat_percent,
    //       dmr_from_supplier_vat_value,
    //       dmr_from_supplier_pph23_percent,
    //       dmr_from_supplier_pph23_value,
    //       dmr_from_supplier_total,
    //       dmr_from_supplier_ctc,
    //       existing_file_ids,
    //       content_files,
    //       costing_file
    //     )
    //     .then((response) => {
    //       if (response.status >= 200 && response.status < 300) {
    //         response.json().then((res) => {
    //           if (res.code === "200") {
    //             this.setState(
    //               {
    //                 snackBarSuccessOpen: true,
    //                 successMsg: res.message,
    //               },
    //               () => {
    //                 this.props.onUpdateCosting(true);
    //               }
    //             );
    //           } else if (res.code === "601") {
    //             this.setState({
    //               errorMsg: res.message,
    //               snackBarErrorOpen: true,
    //             });
    //           } else if (res.code === "607") {
    //             window.location.href = "/logout";
    //           }
    //         });
    //       }
    //     });
    // }
    // else {
    //   alert("Please select barge");
    // }
  };

  render() {
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      const re = /^\d*(\.\d{0,10})?$/;
      const pr = /^\d*(\.\d{0,4})?$/;
      return (
        <div
          className="container bg-white p-0"
          style={{ minHeight: "100vh", width: 850 }}
        >
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>

          <div className="row border-bottom">
            <div className="col-lg-12 p-2">
              {/* <h5 style={{ padding: '15px', marginBottom: 0 }}>Barge Purchase Financial Demurrage</h5> */}
              {this.props.demurrageData && (
                <div className="row">
                  <div className="table-responsive">
                    <table className="table">
                      <thead style={{ backgroundColor: "rgb(249, 249, 249)" }}>
                        <tr>
                          <th nowrap="true">Barge ID</th>
                          <th nowrap="true">Barge Nomination</th>
                          <th nowrap="true">Supplier</th>
                          <th nowrap="true" className="text-right">
                            {this.props.demurrage_type === "company_to_supplier"
                              ? "Total Payable"
                              : "Receivable"}
                          </th>
                          <th nowrap="true" className="text-right">
                            {this.props.demurrage_type === "company_to_supplier"
                              ? "Paid"
                              : "Received"}
                          </th>
                          <th nowrap="true" className="text-right">
                            Remaining
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{pad(this.props.demurrageData.barge_id)}</td>
                          <td nowrap="true">
                            {this.props.demurrageData.barge_nomination}
                          </td>
                          <td nowrap="true">
                            {this.props.demurrageData.supplier_name}
                          </td>
                          <td className="text-right" nowrap="true">
                            {this.toLocaleString(
                              this.props.demurrageData.total_payable
                            )}
                          </td>
                          <td className="text-right" nowrap="true">
                            {this.toLocaleString(this.props.demurrageData.paid)}
                          </td>
                          <td className="text-right" nowrap="true">
                            {this.toLocaleString(
                              this.props.demurrageData.Remaining_amount
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="drawer-section">
            <div className="card p-3">
              <div
                className="card-body p-0"
                style={{ backgroundColor: "rgba(25,118,210,.01)" }}
                id="accordion"
              >
                {this.state.activeStep === 0 && (
                  <div>
                    {this.props.demurrage_type === "supplier_to_company" && (
                      <div className="row">
                        <div className="col-lg-5 pl-0">
                          <div className="row">
                            <div className="col-lg-12 p-0">
                              <label className="form_label mb-0">
                                Barge Demurrage
                              </label>
                              <TextField
                                name="dmr_from_supplier_barge_demurrage"
                                variant="outlined"
                                margin="dense"
                                placeholder="Barge Demurrage"
                                value={
                                  this.state.dmr_from_supplier_barge_demurrage
                                }
                                type="number"
                                inputProps={{
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                  },
                                }}
                                onChange={(e) => {
                                  if (
                                    e.target.value === "" ||
                                    pr.test(e.target.value)
                                  ) {
                                    this.setState(
                                      {
                                        dmr_from_supplier_barge_demurrage:
                                          e.target.value,
                                      },
                                      () =>
                                        this.dmr_from_supplier_calculations()
                                    );
                                  }
                                }}
                                className="rounded-left"
                                style={{
                                  borderTopRightRadius: 0,
                                  borderBottomRightRadius: 0,
                                }}
                                fullWidth
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment
                                      // variant="outlined"
                                      position="end"
                                      style={{
                                        marginRight: "-14px",
                                        marginTop: "-4px",
                                        width: "50%",
                                      }}
                                    >
                                      <TextField
                                        name="dmr_from_supplier_barge_currency"
                                        variant="outlined"
                                        margin="dense"
                                        value={
                                          this.state
                                            .dmr_from_supplier_barge_currency
                                        }
                                        fullWidth
                                        onChange={(e) => {
                                          this.setState(
                                            {
                                              dmr_from_supplier_barge_currency:
                                                e.target.value,
                                            },
                                            () =>
                                              this.dmr_from_supplier_calculations()
                                          );
                                        }}
                                        select
                                      >
                                        {this.state.currencyData}
                                      </TextField>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </div>
                            <div className="col-lg-12 p-0">
                              <label className="form_label mb-0">
                                Demurrage in days
                              </label>
                              <TextField
                                name="dmr_from_supplier_demurrage_in_days"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                placeholder="Demurrage in days"
                                type="number"
                                inputProps={{
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                  },
                                }}
                                value={
                                  this.state.dmr_from_supplier_demurrage_in_days
                                }
                                onChange={(e) => {
                                  if (
                                    e.target.value === "" ||
                                    pr.test(e.target.value)
                                  ) {
                                    this.setState(
                                      {
                                        dmr_from_supplier_demurrage_in_days:
                                          e.target.value,
                                      },
                                      () =>
                                        this.dmr_from_supplier_calculations()
                                    );
                                  }
                                }}
                              />
                            </div>
                            {(config.company === "SRPL" ||
                              config.company === "AVS" ||
                              config.company === "PRPL") &&
                              this.state.dmr_from_supplier_barge_currency !==
                                "USD" &&
                              this.state.dmr_from_supplier_barge_currency !==
                                "0" && (
                                <div className="col-lg-12 p-0">
                                  <label className="form_label mb-0">
                                    Exchange rate USD to{" "}
                                    {
                                      this.state
                                        .dmr_from_supplier_barge_currency
                                    }
                                  </label>
                                  <TextField
                                    name="dmr_from_supplier_currency_xchg_rate"
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth
                                    value={
                                      this.state
                                        .dmr_from_supplier_currency_xchg_rate
                                    }
                                    type="number"
                                    inputProps={{
                                      style: {
                                        fontFamily: "Poppins",
                                        fontSize: "14px",
                                      },
                                    }}
                                    placeholder="Exchange rate"
                                    onChange={(e) => {
                                      if (
                                        e.target.value === "" ||
                                        re.test(e.target.value)
                                      ) {
                                        this.setState(
                                          {
                                            dmr_from_supplier_currency_xchg_rate:
                                              e.target.value,
                                            edit_dmr_from_supplier_demurrage_value: false,
                                          },
                                          () =>
                                            this.dmr_from_supplier_calculations()
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              )}
                            {(config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") &&
                              this.state.dmr_from_supplier_barge_currency !==
                                "IDR" &&
                              (this.state.dmr_from_supplier_barge_currency ||
                                this.state.dmr_from_supplier_barge_currency !==
                                  "0") && (
                                <div className="col-lg-12 p-0">
                                  <label className="form_label mb-0">
                                    Exchange rate{" "}
                                    {
                                      this.state
                                        .dmr_from_supplier_barge_currency
                                    }{" "}
                                    to IDR
                                  </label>
                                  <TextField
                                    name="dmr_from_supplier_currency_xchg_rate"
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth
                                    value={
                                      this.state
                                        .dmr_from_supplier_currency_xchg_rate
                                    }
                                    type="number"
                                    inputProps={{
                                      style: {
                                        fontFamily: "Poppins",
                                        fontSize: "14px",
                                      },
                                    }}
                                    placeholder="Exchange rate"
                                    onChange={(e) => {
                                      if (
                                        e.target.value === "" ||
                                        re.test(e.target.value)
                                      ) {
                                        this.setState(
                                          {
                                            dmr_from_supplier_currency_xchg_rate:
                                              e.target.value,
                                            edit_dmr_from_supplier_demurrage_value: false,
                                          },
                                          () =>
                                            this.dmr_from_supplier_calculations()
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              )}
                            <div className="col-lg-12 p-0">
                              <label className="form_label mb-0">
                                Demurrage incurred
                              </label>
                              <TextField
                                name="dmr_from_supplier_demurrage_value"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                type="number"
                                inputProps={{
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                  },
                                }}
                                placeholder="Demurrage incurred"
                                value={
                                  this.state.dmr_from_supplier_demurrage_value
                                }
                                onChange={(e) => {
                                  if (
                                    e.target.value === "" ||
                                    pr.test(e.target.value)
                                  ) {
                                    this.setState(
                                      {
                                        dmr_from_supplier_demurrage_value:
                                          e.target.value,
                                      },
                                      () =>
                                        this.dmr_from_supplier_calculations(
                                          "dmr_from_supplier_demurrage_value"
                                        )
                                    );
                                  }
                                }}
                              />
                            </div>
                            {(config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") && (
                              <div className="col-lg-12 p-0">
                                <label className="form_label mb-0">
                                  PPH23 Account
                                </label>
                                <TextField
                                  name="dmr_from_supplier_pph23_account"
                                  margin="dense"
                                  variant="outlined"
                                  value={
                                    this.state.dmr_from_supplier_pph23_account
                                  }
                                  select
                                  fullWidth
                                  inputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                    },
                                  }}
                                  onChange={(e) => {
                                    this.setState(
                                      {
                                        dmr_from_supplier_pph23_account:
                                          e.target.value,
                                        edit_dmr_from_supplier_demurrage_value: false,
                                      },
                                      () =>
                                        this.dmr_from_supplier_calculations()
                                    );
                                  }}
                                >
                                  <MenuItem value="0">Please Select</MenuItem>
                                  <MenuItem value="Supplier">Supplier</MenuItem>
                                  <MenuItem value="SDAM">SDAM</MenuItem>
                                </TextField>
                              </div>
                            )}
                          </div>
                        </div>
                        <div
                          className="col-lg-7 p-2 rounded"
                          style={{ backgroundColor: "#f7f3f0" }}
                        >
                          <div className="row">
                            <div className="row col-lg-12 p-0">
                              <h6>Actual Demurrage</h6>
                            </div>
                            {(config.company === "SRPL" ||
                              config.company === "AVS" ||
                              config.company === "PRPL" ||
                              ((config.company === "SDAM" ||
                                config.company === "SRK" ||
                                config.company === "BTR") &&
                                this.state.dmr_from_supplier_pph23_account ===
                                  "Supplier")) && (
                              <div className="row">
                                <div className="col-lg-6 mb-2">
                                  <label
                                    className="form_label mb-0"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    Base price
                                  </label>
                                  <TextField
                                    name="dmr_from_supplier_base_price"
                                    margin="dense"
                                    type="number"
                                    variant="outlined"
                                    inputProps={{
                                      style: {
                                        fontFamily: "Poppins",
                                        fontSize: "14px",
                                      },
                                    }}
                                    value={
                                      this.state.dmr_from_supplier_base_price
                                    }
                                    onChange={(e) => {
                                      if (
                                        e.target.value === "" ||
                                        pr.test(e.target.value)
                                      ) {
                                        var dmr_from_supplier_vat_value = (
                                          (Number(e.target.value) * 10) /
                                          100
                                        ).toFixed(2);
                                        let dmr_from_supplier_pph23_value =
                                          Number(
                                            (e.target.value * 2) / 100
                                          ).toFixed(2);
                                        let dmr_from_supplier_total = (
                                          Number(e.target.value) +
                                          Number(dmr_from_supplier_vat_value) -
                                          Number(dmr_from_supplier_pph23_value)
                                        ).toFixed(2);
                                        let dmr_from_supplier_ctc = (
                                          Number(e.target.value) +
                                          Number(dmr_from_supplier_vat_value)
                                        ).toFixed(2);
                                        this.setState({
                                          [e.target.name]: e.target.value,
                                          dmr_from_supplier_vat_value:
                                            dmr_from_supplier_vat_value,
                                          dmr_from_supplier_pph23_value:
                                            dmr_from_supplier_pph23_value,
                                          dmr_from_supplier_total:
                                            dmr_from_supplier_total,
                                          dmr_from_supplier_ctc:
                                            dmr_from_supplier_ctc,
                                        });
                                      }
                                    }}
                                    fullWidth
                                  />
                                </div>
                                <div className="col-lg-6 mb-2">
                                  <label
                                    className="form_label mb-2"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    VAT %
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {this.state.dmr_from_supplier_vat_percent
                                      ? this.state.dmr_from_supplier_vat_percent
                                      : "-"}
                                  </div>
                                </div>

                                <div className="col-lg-6 mb-2">
                                  <label
                                    className="form_label mb-0"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    VAT
                                  </label>
                                  <TextField
                                    name="dmr_from_supplier_vat_value"
                                    margin="dense"
                                    type="number"
                                    variant="outlined"
                                    inputProps={{
                                      style: {
                                        fontFamily: "Poppins",
                                        fontSize: "14px",
                                      },
                                    }}
                                    value={
                                      this.state.dmr_from_supplier_vat_value
                                    }
                                    onChange={(e) => {
                                      if (
                                        e.target.value === "" ||
                                        pr.test(e.target.value)
                                      ) {
                                        let dmr_from_supplier_total = (
                                          Number(
                                            this.state
                                              .dmr_from_supplier_base_price
                                          ) +
                                          Number(e.target.value) -
                                          Number(
                                            this.state
                                              .dmr_from_supplier_pph23_value
                                          )
                                        ).toFixed(2);
                                        let dmr_from_supplier_ctc = (
                                          Number(
                                            this.state
                                              .dmr_from_supplier_base_price
                                          ) + Number(e.target.value)
                                        ).toFixed(2);
                                        this.setState({
                                          [e.target.name]: e.target.value,
                                          dmr_from_supplier_total:
                                            dmr_from_supplier_total,
                                          dmr_from_supplier_ctc:
                                            dmr_from_supplier_ctc,
                                        });
                                      }
                                    }}
                                    fullWidth
                                  />
                                </div>
                                {(config.company === "SDAM" ||
                                  config.company === "SRK" ||
                                  config.company === "BTR") &&
                                  this.state.dmr_from_supplier_pph23_account ===
                                    "Supplier" && (
                                    <div className="col-lg-6 mb-2">
                                      <label
                                        className="form_label mb-0"
                                        style={{
                                          color: "rgba(72, 59, 52, 0.7)",
                                        }}
                                      >
                                        Includes PPH23 @{" "}
                                        {this.state
                                          .dmr_from_supplier_pph23_percent
                                          ? this.toLocaleString(
                                              this.state
                                                .dmr_from_supplier_pph23_percent
                                            )
                                          : 0}
                                        %
                                      </label>
                                      {/* <div className='contract_display_header_value' style={{ color: '#42352d' }}>{this.state.dmr_from_supplier_pph23_value ? this.toLocaleString(this.state.dmr_from_supplier_pph23_value) : null}</div> */}
                                      <TextField
                                        name="dmr_from_supplier_pph23_value"
                                        margin="dense"
                                        type="number"
                                        variant="outlined"
                                        inputProps={{
                                          style: {
                                            fontFamily: "Poppins",
                                            fontSize: "14px",
                                          },
                                        }}
                                        value={
                                          this.state
                                            .dmr_from_supplier_pph23_value
                                        }
                                        onChange={(e) => {
                                          if (
                                            e.target.value === "" ||
                                            pr.test(e.target.value)
                                          ) {
                                            let dmr_from_supplier_total = (
                                              Number(
                                                this.state
                                                  .dmr_from_supplier_base_price
                                              ) +
                                              Number(
                                                this.state
                                                  .dmr_from_supplier_vat_value
                                              ) -
                                              Number(e.target.value)
                                            ).toFixed(2);
                                            let dmr_from_supplier_ctc = (
                                              Number(
                                                this.state
                                                  .dmr_from_supplier_base_price
                                              ) +
                                              Number(
                                                this.state
                                                  .dmr_from_supplier_vat_value
                                              )
                                            ).toFixed(2);
                                            this.setState({
                                              [e.target.name]: e.target.value,
                                              dmr_from_supplier_total:
                                                dmr_from_supplier_total,
                                              dmr_from_supplier_ctc:
                                                dmr_from_supplier_ctc,
                                            });
                                          }
                                        }}
                                        fullWidth
                                      />
                                    </div>
                                  )}
                                <div className="col-lg-6 ">
                                  <label
                                    className="form_label mb-2"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    Receivable
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {this.state.dmr_from_supplier_total
                                      ? this.toLocaleString(
                                          this.state.dmr_from_supplier_total
                                        )
                                      : 0}{" "}
                                    {config.company === "SRPL" ||
                                    config.company === "AVS" ||
                                    config.company === "PRPL"
                                      ? "USD"
                                      : "IDR"}
                                  </div>
                                </div>
                                <div className="col-lg-6 ">
                                  <label
                                    className="form_label mb-0"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    Cost to Company
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {this.state.dmr_from_supplier_ctc
                                      ? this.toLocaleString(
                                          this.state.dmr_from_supplier_ctc
                                        )
                                      : 0}{" "}
                                    {config.company === "SRPL" ||
                                    config.company === "AVS" ||
                                    config.company === "PRPL"
                                      ? "USD"
                                      : "IDR"}
                                  </div>
                                </div>
                              </div>
                            )}

                            {this.state.dmr_from_supplier_pph23_account ===
                              "SDAM" && (
                              <div className="row">
                                <div className="col-lg-6 mb-2">
                                  <label
                                    className="form_label mb-0"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    Base price
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {this.state.dmr_from_supplier_base_price
                                      ? this.toLocaleString(
                                          this.state
                                            .dmr_from_supplier_base_price
                                        )
                                      : "-"}
                                  </div>
                                </div>

                                <div className="col-lg-6 mb-3">
                                  <label
                                    className="form_label mb-0"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    Includes PPH23 @{" "}
                                    {this.state.dmr_from_supplier_pph23_percent
                                      ? this.toLocaleString(
                                          this.state
                                            .dmr_from_supplier_pph23_percent
                                        )
                                      : 0}
                                    %
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {this.state.dmr_from_supplier_pph23_value
                                      ? this.toLocaleString(
                                          this.state
                                            .dmr_from_supplier_pph23_value
                                        )
                                      : null}
                                  </div>
                                </div>
                                <div className="col-lg-6 ">
                                  <label
                                    className="form_label mb-0"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    Payable to Vendor
                                  </label>
                                  {/* <div className='contract_display_header_value' style={{ color: '#42352d' }}>{this.state.barge_vendor_total_value ? this.toLocaleString(this.state.barge_vendor_total_value) : 0} {(config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL") ? "USD" : "IDR"}</div> */}
                                  <TextField
                                    name="dmr_from_supplier_total"
                                    margin="dense"
                                    type="number"
                                    variant="outlined"
                                    inputProps={{
                                      style: {
                                        fontFamily: "Poppins",
                                        fontSize: "14px",
                                      },
                                    }}
                                    value={this.state.dmr_from_supplier_total}
                                    onChange={(e) => {
                                      if (
                                        e.target.value === "" ||
                                        pr.test(e.target.value)
                                      ) {
                                        let dmr_from_supplier_base_price =
                                          Number(
                                            (e.target.value * 100) / 98
                                          ).toFixed(2);
                                        let dmr_from_supplier_pph23_value =
                                          Number(
                                            (dmr_from_supplier_base_price * 2) /
                                              100
                                          ).toFixed(2);
                                        let dmr_from_supplier_ctc =
                                          dmr_from_supplier_base_price;
                                        this.setState({
                                          [e.target.name]: e.target.value,
                                          dmr_from_supplier_base_price:
                                            dmr_from_supplier_base_price,
                                          dmr_from_supplier_pph23_value:
                                            dmr_from_supplier_pph23_value,
                                          dmr_from_supplier_ctc:
                                            dmr_from_supplier_ctc,
                                        });
                                      }
                                    }}
                                    fullWidth
                                  />
                                </div>
                                <div className="col-lg-6 ">
                                  <label
                                    className="form_label mb-2"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    Cost to Company
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {this.state.dmr_from_supplier_ctc
                                      ? this.toLocaleString(
                                          this.state.dmr_from_supplier_ctc
                                        )
                                      : 0}{" "}
                                    {config.company === "SRPL" ||
                                    config.company === "AVS" ||
                                    config.company === "PRPL"
                                      ? "USD"
                                      : "IDR"}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        {(this.state.features.indexOf("530") >= 0 ||
                          this.state.features.indexOf("97") >= 0) && (
                          <div className="card col-lg-12 p-0 border mt-3">
                            <FileUpload
                              files={this.state.files}
                              type="Costings"
                              invoice_file={this.state.invoice_file}
                              feature_name={
                                this.props.demurrage_type ===
                                "company_to_supplier"
                                  ? "Barge_Demurrage_To_Supplier"
                                  : "Barge_Demurrage_From_Supplier"
                              }
                              callbackFn={(data, mandatoryFiles) => {
                                this.setState({
                                  files: data,
                                  costing_files: mandatoryFiles,
                                });
                              }}
                            />
                          </div>
                        )}
                      </div>
                    )}

                    {this.props.demurrage_type === "company_to_supplier" && (
                      <div>
                        <div className="row mb-3">
                          <div className="col-lg-4 p-0 mb-1">
                            <label className="form_label mb-1">
                              NOR Anchorage
                            </label>
                            <div className="">
                              {this.state.NORAnchorageDate
                                ? localDateFormate(this.state.NORAnchorageDate)
                                : ""}
                            </div>
                          </div>
                          <div className="col-lg-4 p-0 mb-1">
                            <label className="form_label mb-1">
                              Completion Discharge
                            </label>
                            <div className="">
                              {this.state.completionDischargeDate
                                ? localDateFormate(
                                    this.state.completionDischargeDate
                                  )
                                : ""}
                            </div>
                          </div>
                          <div className="col-lg-4 p-0 mb-1">
                            <label className="form_label mb-1">
                              Time at Anchorage
                            </label>
                            <div className="">{this.state.timeAtAnchorage}</div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-5 pl-0">
                            <div className="row">
                              <div className="col-lg-12 p-0">
                                <label className="form_label mb-0">
                                  Time allowed for Discharge
                                </label>
                                <TextField
                                  name="dmr_to_supplier_time_allowed_for_discharge"
                                  variant="outlined"
                                  margin="dense"
                                  placeholder="Time allowed for Discharge"
                                  value={
                                    this.state
                                      .dmr_to_supplier_time_allowed_for_discharge
                                  }
                                  onChange={(e) => {
                                    if (
                                      e.target.value === "" ||
                                      re.test(e.target.value)
                                    ) {
                                      var value = e.target.value;
                                      if (value === "") {
                                        value = "0";
                                      }
                                      let anchorageDays = Number(
                                        Number(this.state.timeAtAnchorageDays) +
                                          (Number(
                                            this.state.timeAtAnchorageHours
                                          ) +
                                            Number(
                                              this.state.timeAtAnchorageMinutes
                                            ) /
                                              60) /
                                            24
                                      ).toFixed(0);
                                      if (
                                        Number(e.target.value) >
                                        Number(anchorageDays)
                                      ) {
                                        this.setState({
                                          dmr_to_supplier_time_allowed_for_dischargeError: true,
                                        });
                                      }
                                      let dmr_to_supplier_detention_in_days = (
                                        Number(anchorageDays) - Number(value)
                                      ).toFixed(2);
                                      let dmr_to_supplier_detention_value = "";
                                      if (
                                        this.state
                                          .dmr_to_supplier_barge_detention !==
                                        ""
                                      ) {
                                        dmr_to_supplier_detention_value = (
                                          Number(
                                            this.state
                                              .dmr_to_supplier_barge_detention
                                          ) * dmr_to_supplier_detention_in_days
                                        ).toFixed(2);
                                      }
                                      this.setState(
                                        {
                                          [e.target.name]: e.target.value,
                                          dmr_to_supplier_detention_in_days:
                                            dmr_to_supplier_detention_in_days,
                                          dmr_to_supplier_detention_value:
                                            dmr_to_supplier_detention_value,
                                          to_supplier_time_allowed_for_dischargeError: false,
                                        },
                                        () =>
                                          this.dmr_to_supplier_calculations()
                                      );
                                    }
                                  }}
                                  fullWidth
                                  error={
                                    this.state
                                      .to_supplier_time_allowed_for_dischargeError
                                  }
                                />
                                {this.state
                                  .to_supplier_time_allowed_for_dischargeError ? (
                                  <span
                                    style={{ fontSize: "10px", color: "red" }}
                                  >
                                    Time allowed for Discharge is more than Time
                                    at Anchorage
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="col-lg-12 p-0">
                                <label className="form_label mb-0">
                                  Barge Detention
                                </label>
                                <TextField
                                  name="dmr_to_supplier_barge_detention"
                                  variant="outlined"
                                  margin="dense"
                                  type="number"
                                  placeholder="Barge Detention"
                                  value={
                                    this.state.dmr_to_supplier_barge_detention
                                  }
                                  onChange={(e) => {
                                    if (
                                      e.target.value === "" ||
                                      re.test(e.target.value)
                                    ) {
                                      this.setState(
                                        {
                                          dmr_to_supplier_barge_detention:
                                            e.target.value,
                                        },
                                        () =>
                                          this.dmr_to_supplier_calculations()
                                      );
                                    }
                                  }}
                                  fullWidth
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment
                                        // variant="outlined"
                                        position="end"
                                        style={{
                                          marginRight: "-14px",
                                          marginTop: "-4px",
                                          width: "50%",
                                        }}
                                      >
                                        <TextField
                                          name="dmr_to_supplier_barge_currency"
                                          variant="outlined"
                                          margin="dense"
                                          value={
                                            this.state
                                              .dmr_to_supplier_barge_currency
                                          }
                                          fullWidth
                                          onChange={(e) => {
                                            this.setState(
                                              {
                                                dmr_to_supplier_barge_currency:
                                                  e.target.value,
                                              },
                                              () => {
                                                this.dmr_to_supplier_calculations();
                                              }
                                            );
                                          }}
                                          select
                                        >
                                          {this.state.currencyData}
                                        </TextField>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </div>
                              <div className="col-lg-12 p-0 mb-1">
                                <label className="form_label mb-0">
                                  Detention in days
                                </label>
                                <div>
                                  {this.state.dmr_to_supplier_detention_in_days}
                                </div>
                              </div>
                              {(config.company === "SRPL" ||
                                config.company === "AVS" ||
                                config.company === "PRPL") &&
                                this.state.dmr_to_supplier_barge_currency !==
                                  "USD" &&
                                this.state.dmr_to_supplier_barge_currency !==
                                  "0" && (
                                  <div className="col-lg-12 p-0">
                                    <label className="form_label mb-0">
                                      Exchange rate USD to{" "}
                                      {
                                        this.state
                                          .dmr_to_supplier_barge_currency
                                      }
                                    </label>
                                    <TextField
                                      name="dmr_to_supplier_currency_xchg_rate"
                                      margin="dense"
                                      variant="outlined"
                                      fullWidth
                                      value={
                                        this.state
                                          .dmr_to_supplier_currency_xchg_rate
                                      }
                                      type="number"
                                      inputProps={{
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                        },
                                      }}
                                      placeholder="Enter Value"
                                      onChange={(e) => {
                                        if (
                                          e.target.value === "" ||
                                          re.test(e.target.value)
                                        ) {
                                          this.setState(
                                            {
                                              dmr_to_supplier_currency_xchg_rate:
                                                e.target.value,
                                              edit_dmr_from_supplier_demurrage_value: false,
                                            },
                                            () =>
                                              this.dmr_to_supplier_calculations()
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                )}
                              {(config.company === "SRPL" ||
                                config.company === "AVS" ||
                                config.company === "PRPL") &&
                                this.state.dmr_to_supplier_barge_currency !==
                                  "USD" &&
                                this.state.dmr_to_supplier_barge_currency !==
                                  "0" && (
                                  <div className="col-lg-12 p-0">
                                    <label className="form_label mb-0">
                                      Exchange rate{" "}
                                      {
                                        this.state
                                          .dmr_to_supplier_barge_currency
                                      }{" "}
                                      to IDR
                                    </label>
                                    <TextField
                                      name="dmr_to_supplier_currency_xchg_rate"
                                      margin="dense"
                                      variant="outlined"
                                      fullWidth
                                      value={
                                        this.state
                                          .dmr_to_supplier_currency_xchg_rate
                                      }
                                      type="number"
                                      inputProps={{
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                        },
                                      }}
                                      placeholder="Enter Value"
                                      onChange={(e) => {
                                        if (
                                          e.target.value === "" ||
                                          re.test(e.target.value)
                                        ) {
                                          this.setState(
                                            {
                                              dmr_to_supplier_currency_xchg_rate:
                                                e.target.value,
                                              edit_dmr_from_supplier_demurrage_value: false,
                                            },
                                            () =>
                                              this.dmr_to_supplier_calculations()
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                )}
                              <div className="col-lg-12 p-0">
                                <label className="form_label mb-0">
                                  Detention incurred
                                </label>
                                <TextField
                                  name="dmr_to_supplier_detention_value"
                                  margin="dense"
                                  variant="outlined"
                                  fullWidth
                                  inputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: "12px",
                                    },
                                  }}
                                  placeholder="Demurrage incurred"
                                  value={
                                    this.state.dmr_to_supplier_detention_value
                                  }
                                  onChange={(e) => {
                                    if (
                                      e.target.value === "" ||
                                      pr.test(e.target.value)
                                    ) {
                                      // let dmr_to_supplier_base_price = Number(e.target.value).toFixed(2);
                                      // let dmr_to_supplier_vat_percent = 10;
                                      // let dmr_to_supplier_vat_value = (Number(dmr_to_supplier_base_price) * 10 / 100).toFixed(2);
                                      // let dmr_to_supplier_pph23_percent = 2;
                                      // let dmr_to_supplier_pph23_value = Number((dmr_to_supplier_base_price * 2 / 100)).toFixed(0);
                                      // let dmr_to_supplier_total = (Number(dmr_to_supplier_base_price) + Number(dmr_to_supplier_vat_value) - Number(dmr_to_supplier_pph23_value)).toFixed(2);
                                      // if ((config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL")) {
                                      //   dmr_to_supplier_total = (Number(dmr_to_supplier_base_price) + Number(dmr_to_supplier_vat_value)).toFixed(2);
                                      // }
                                      // let dmr_to_supplier_ctc = Number(Number(dmr_to_supplier_base_price) + Number(dmr_to_supplier_vat_value)).toFixed(2);

                                      this.setState(
                                        {
                                          dmr_to_supplier_detention_value:
                                            e.target.value,
                                          // dmr_to_supplier_base_price: dmr_to_supplier_base_price,
                                          // dmr_to_supplier_vat_percent: dmr_to_supplier_vat_percent,
                                          // dmr_to_supplier_vat_value: dmr_to_supplier_vat_value,
                                          // dmr_to_supplier_pph23_percent: dmr_to_supplier_pph23_percent,
                                          // dmr_to_supplier_pph23_value: dmr_to_supplier_pph23_value,
                                          // dmr_to_supplier_total: dmr_to_supplier_total,
                                          // dmr_to_supplier_ctc: dmr_to_supplier_ctc
                                        },
                                        () => {
                                          this.dmr_to_supplier_calculations(
                                            "dmr_to_supplier_detention_value"
                                          );
                                        }
                                      );
                                    }
                                  }}
                                />
                              </div>
                              {(config.company === "SDAM" ||
                                config.company === "SRK" ||
                                config.company === "BTR") && (
                                <div className="col-lg-12 p-0">
                                  <label className="form_label mb-0">
                                    PPH23 Account
                                  </label>
                                  <TextField
                                    name="dmr_to_supplier_pph23_account"
                                    margin="dense"
                                    inputProps={{
                                      style: {
                                        fontFamily: "Poppins",
                                        fontSize: "14px",
                                      },
                                    }}
                                    variant="outlined"
                                    value={
                                      this.state.dmr_to_supplier_pph23_account
                                    }
                                    select
                                    fullWidth
                                    onChange={(e) => {
                                      this.setState(
                                        {
                                          dmr_to_supplier_pph23_account:
                                            e.target.value,
                                        },
                                        () =>
                                          this.dmr_to_supplier_calculations()
                                      );
                                    }}
                                  >
                                    <MenuItem value="0">Please Select</MenuItem>
                                    <MenuItem value="Supplier">
                                      Supplier
                                    </MenuItem>
                                    <MenuItem value="SDAM">SDAM</MenuItem>
                                  </TextField>
                                </div>
                              )}
                            </div>
                          </div>
                          <div
                            className="col-lg-7 p-2 rounded"
                            style={{ backgroundColor: "#f7f3f0" }}
                          >
                            <div className="row">
                              <div className="row col-lg-12 p-0">
                                <h6>Actual Detention</h6>
                              </div>
                              {(config.company === "SRPL" ||
                                config.company === "AVS" ||
                                config.company === "PRPL" ||
                                ((config.company === "SDAM" ||
                                  config.company === "SRK" ||
                                  config.company === "BTR") &&
                                  this.state.dmr_to_supplier_pph23_account ===
                                    "Supplier")) && (
                                <div className="row">
                                  <div className="col-lg-6 mb-2">
                                    <label
                                      className="form_label mb-0"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Base price
                                    </label>
                                    <TextField
                                      name="dmr_to_supplier_base_price"
                                      margin="dense"
                                      type="number"
                                      inputProps={{
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                        },
                                      }}
                                      variant="outlined"
                                      value={
                                        this.state.dmr_to_supplier_base_price
                                      }
                                      onChange={(e) => {
                                        if (
                                          e.target.value === "" ||
                                          pr.test(e.target.value)
                                        ) {
                                          var dmr_to_supplier_vat_value = (
                                            (Number(e.target.value) * 10) /
                                            100
                                          ).toFixed(2);
                                          let dmr_to_supplier_pph23_value =
                                            Number(
                                              (e.target.value * 2) / 100
                                            ).toFixed(2);
                                          let dmr_to_supplier_total = (
                                            Number(e.target.value) +
                                            Number(dmr_to_supplier_vat_value)
                                          ).toFixed(2);
                                          let dmr_to_supplier_ctc = (
                                            Number(e.target.value) +
                                            Number(dmr_to_supplier_vat_value)
                                          ).toFixed(2);
                                          this.setState({
                                            [e.target.name]: e.target.value,
                                            dmr_to_supplier_vat_value:
                                              dmr_to_supplier_vat_value,
                                            dmr_to_supplier_pph23_value:
                                              dmr_to_supplier_pph23_value,
                                            dmr_to_supplier_total:
                                              dmr_to_supplier_total,
                                            dmr_to_supplier_ctc:
                                              dmr_to_supplier_ctc,
                                          });
                                        }
                                      }}
                                      fullWidth
                                    />
                                  </div>
                                  <div className="col-lg-6 mb-2">
                                    <label
                                      className="form_label mb-2"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      VAT %
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {this.state.dmr_to_supplier_vat_percent
                                        ? this.state.dmr_to_supplier_vat_percent
                                        : "-"}
                                    </div>
                                  </div>

                                  <div className="col-lg-6 mb-2">
                                    <label
                                      className="form_label mb-0"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      VAT
                                    </label>
                                    <TextField
                                      name="dmr_to_supplier_vat_value"
                                      margin="dense"
                                      type="number"
                                      inputProps={{
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                        },
                                      }}
                                      variant="outlined"
                                      value={
                                        this.state.dmr_to_supplier_vat_value
                                      }
                                      onChange={(e) => {
                                        if (
                                          e.target.value === "" ||
                                          pr.test(e.target.value)
                                        ) {
                                          let dmr_to_supplier_total = (
                                            Number(
                                              this.state
                                                .dmr_to_supplier_base_price
                                            ) +
                                            Number(e.target.value) -
                                            Number(
                                              this.state
                                                .dmr_to_supplier_pph23_value
                                            )
                                          ).toFixed(2);
                                          let dmr_to_supplier_ctc = (
                                            Number(
                                              this.state
                                                .dmr_to_supplier_base_price
                                            ) + Number(e.target.value)
                                          ).toFixed(2);
                                          this.setState({
                                            [e.target.name]: e.target.value,
                                            dmr_to_supplier_total:
                                              dmr_to_supplier_total,
                                            dmr_to_supplier_ctc:
                                              dmr_to_supplier_ctc,
                                          });
                                        }
                                      }}
                                      fullWidth
                                    />
                                  </div>
                                  {(config.company === "SDAM" ||
                                    config.company === "SRK" ||
                                    config.company === "BTR") &&
                                    this.state.dmr_to_supplier_pph23_account ===
                                      "Supplier" && (
                                      <div className="col-lg-6 mb-2">
                                        <label
                                          className="form_label mb-0"
                                          style={{
                                            color: "rgba(72, 59, 52, 0.7)",
                                          }}
                                        >
                                          Includes PPH23 @{" "}
                                          {this.state
                                            .dmr_to_supplier_pph23_percent
                                            ? this.toLocaleString(
                                                this.state
                                                  .dmr_to_supplier_pph23_percent
                                              )
                                            : 0}
                                          %
                                        </label>
                                        {/* <div className='contract_display_header_value' style={{ color: '#42352d' }}>{this.state.dmr_to_supplier_pph23_value ? this.toLocaleString(this.state.dmr_to_supplier_pph23_value) : null}</div> */}
                                        <TextField
                                          name="dmr_to_supplier_pph23_value"
                                          margin="dense"
                                          type="number"
                                          variant="outlined"
                                          inputProps={{
                                            style: {
                                              fontFamily: "Poppins",
                                              fontSize: "14px",
                                            },
                                          }}
                                          value={
                                            this.state
                                              .dmr_to_supplier_pph23_value
                                          }
                                          onChange={(e) => {
                                            if (
                                              e.target.value === "" ||
                                              pr.test(e.target.value)
                                            ) {
                                              let dmr_to_supplier_total = (
                                                Number(
                                                  this.state
                                                    .dmr_to_supplier_base_price
                                                ) +
                                                Number(
                                                  this.state
                                                    .dmr_to_supplier_vat_value
                                                ) -
                                                Number(e.target.value)
                                              ).toFixed(2);
                                              let dmr_to_supplier_ctc = (
                                                Number(
                                                  this.state
                                                    .dmr_to_supplier_base_price
                                                ) +
                                                Number(
                                                  this.state
                                                    .dmr_to_supplier_vat_value
                                                )
                                              ).toFixed(2);
                                              this.setState({
                                                [e.target.name]: e.target.value,
                                                dmr_to_supplier_total:
                                                  dmr_to_supplier_total,
                                                dmr_to_supplier_ctc:
                                                  dmr_to_supplier_ctc,
                                              });
                                            }
                                          }}
                                          fullWidth
                                        />
                                      </div>
                                    )}
                                  <div className="col-lg-6 ">
                                    <label
                                      className="form_label mb-2"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Payable
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {this.state.dmr_to_supplier_total
                                        ? this.toLocaleString(
                                            this.state.dmr_to_supplier_total
                                          )
                                        : 0}{" "}
                                      {config.company === "SRPL" ||
                                      config.company === "AVS" ||
                                      config.company === "PRPL"
                                        ? "USD"
                                        : "IDR"}
                                    </div>
                                  </div>
                                  <div className="col-lg-6 ">
                                    <label
                                      className="form_label mb-0"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Cost to Company
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {this.state.dmr_to_supplier_ctc
                                        ? this.toLocaleString(
                                            this.state.dmr_to_supplier_ctc
                                          )
                                        : 0}{" "}
                                      {config.company === "SRPL" ||
                                      config.company === "AVS" ||
                                      config.company === "PRPL"
                                        ? "USD"
                                        : "IDR"}
                                    </div>
                                  </div>
                                </div>
                              )}

                              {this.state.dmr_to_supplier_pph23_account ===
                                "SDAM" && (
                                <div className="row">
                                  <div className="col-lg-6 mb-2">
                                    <label
                                      className="form_label mb-0"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Base price
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {this.state.dmr_to_supplier_base_price
                                        ? this.toLocaleString(
                                            this.state
                                              .dmr_to_supplier_base_price
                                          )
                                        : "-"}
                                    </div>
                                  </div>

                                  <div className="col-lg-6 mb-2">
                                    <label
                                      className="form_label mb-0"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Includes PPH23 @{" "}
                                      {this.state.dmr_to_supplier_pph23_percent
                                        ? this.toLocaleString(
                                            this.state
                                              .dmr_to_supplier_pph23_percent
                                          )
                                        : 0}
                                      %
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {this.state.dmr_to_supplier_pph23_value
                                        ? this.toLocaleString(
                                            this.state
                                              .dmr_to_supplier_pph23_value
                                          )
                                        : null}
                                    </div>
                                  </div>
                                  <div className="col-lg-6 ">
                                    <label
                                      className="form_label mb-0"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Payable to Vendor
                                    </label>
                                    {/* <div className='contract_display_header_value' style={{ color: '#42352d' }}>{this.state.barge_vendor_total_value ? this.toLocaleString(this.state.barge_vendor_total_value) : 0} {(config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL") ? "USD" : "IDR"}</div> */}
                                    <TextField
                                      name="dmr_to_supplier_total"
                                      margin="dense"
                                      type="number"
                                      inputProps={{
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                        },
                                      }}
                                      variant="outlined"
                                      value={this.state.dmr_to_supplier_total}
                                      onChange={(e) => {
                                        if (
                                          e.target.value === "" ||
                                          pr.test(e.target.value)
                                        ) {
                                          let dmr_to_supplier_base_price =
                                            Number(
                                              (e.target.value * 100) / 98
                                            ).toFixed(2);
                                          let dmr_to_supplier_pph23_value =
                                            Number(
                                              (dmr_to_supplier_base_price * 2) /
                                                100
                                            ).toFixed(2);
                                          let dmr_to_supplier_ctc =
                                            dmr_to_supplier_base_price;
                                          this.setState({
                                            [e.target.name]: e.target.value,
                                            dmr_to_supplier_base_price:
                                              dmr_to_supplier_base_price,
                                            dmr_to_supplier_pph23_value:
                                              dmr_to_supplier_pph23_value,
                                            dmr_to_supplier_ctc:
                                              dmr_to_supplier_ctc,
                                          });
                                        }
                                      }}
                                      fullWidth
                                    />
                                  </div>
                                  <div className="col-lg-6 ">
                                    <label
                                      className="form_label mb-2"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Cost to Company
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {this.state.dmr_to_supplier_ctc
                                        ? this.toLocaleString(
                                            this.state.dmr_to_supplier_ctc
                                          )
                                        : 0}{" "}
                                      {config.company === "SRPL" ||
                                      config.company === "AVS" ||
                                      config.company === "PRPL"
                                        ? "USD"
                                        : "IDR"}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {(this.state.features.indexOf("530") >= 0 ||
                          this.state.features.indexOf("97") >= 0) && (
                          <>
                            <div className="card p-0">
                              <FileUpload
                                files={this.state.files}
                                type="Costings"
                                invoice_file={this.state.invoice_file}
                                feature_name={
                                  this.props.demurrage_type ===
                                  "company_to_supplier"
                                    ? "Barge_Demurrage_To_Supplier"
                                    : "Barge_Demurrage_From_Supplier"
                                }
                                callbackFn={(data, mandatoryFiles) => {
                                  this.setState({
                                    files: data,
                                    costing_files: mandatoryFiles,
                                  });
                                }}
                              />
                            </div>
                          </>
                          // <div className="card col-lg-12 p-0 border mt-3">
                          //   <div className="card-header section_header">
                          //     <h5>File Upload Details</h5>
                          //   </div>
                          //   <div className="card-body">
                          //     {this.state.files.map((e, index) => (
                          //       <div className="" key={index}>
                          //         {e.file_path ? (
                          //           <div className="row mb-3" key={index}>
                          //             <div className="col-lg-10 p-0">
                          //               <a
                          //                 href={
                          //                   config.apiDomain + "/" + e.file_path
                          //                 }
                          //                 role="button"
                          //                 target="_blank"
                          //                 rel="noopener noreferrer"
                          //                 style={{ color: config.themeColor }}
                          //               >
                          //                 {e.file_description}
                          //               </a>
                          //             </div>
                          //             <center className="col-lg-1 d-flex justify-content-center">
                          //               <i
                          //                 className="fa fa-trash-o m-auto"
                          //                 onClick={(e) => {
                          //                   const row = this.state.files;
                          //                   row.splice(index, 1);
                          //                   this.setState({
                          //                     files: row,
                          //                   });
                          //                 }}
                          //                 style={{
                          //                   fontSize: 24,
                          //                   color: config.themeColor,
                          //                   cursor: "pointer",
                          //                 }}
                          //               />
                          //             </center>
                          //           </div>
                          //         ) : (
                          //           <div className="row mb-3" key={index}>
                          //             <div className="col-lg-4 p-0">
                          //               <label className="form_label mb-0">
                          //                 File Description
                          //               </label>
                          //               <TextField
                          //                 name="file_description"
                          //                 variant="outlined"
                          //                 margin="dense"
                          //                 fullWidth
                          //                 inputProps={{
                          //                   style: {
                          //                     textTransform: "uppercase",
                          //                   },
                          //                 }}
                          //                 value={e.file_description}
                          //                 error={e.file_description_error}
                          //                 onChange={(e) => {
                          //                   var row = this.state.files;
                          //                   row[index].file_description =
                          //                     e.target.value;
                          //                   row[
                          //                     index
                          //                   ].file_description_error = false;
                          //                   this.setState({
                          //                     files: row,
                          //                   });
                          //                 }}
                          //               />
                          //             </div>
                          //             <div className="col-lg-6 pr-0">
                          //               <label className="form_label mb-2">
                          //                 Select file to upload
                          //               </label>
                          //               <input
                          //                 type="file"
                          //                 name="fileToUpload"
                          //                 id="fileToUpload"
                          //                 className="form-control border-0 pl-0"
                          //                 onChange={this.fileUploadHandler(
                          //                   index
                          //                 )}
                          //               />
                          //             </div>

                          //             <center className="col-lg-1 d-flex justify-content-center">
                          //               <i
                          //                 className="fa fa-trash-o m-auto"
                          //                 onClick={(e) => {
                          //                   const row = this.state.files;
                          //                   row.splice(index, 1);
                          //                   this.setState({
                          //                     files: row,
                          //                   });
                          //                 }}
                          //                 style={{
                          //                   fontSize: 24,
                          //                   color: config.themeColor,
                          //                   cursor: "pointer",
                          //                 }}
                          //               />
                          //             </center>
                          //           </div>
                          //         )}
                          //       </div>
                          //     ))}
                          //     <button
                          //       type="button"
                          //       style={{ color: config.themeColor, width: 110 }}
                          //       className="header_button header_button_text addrow_button_adjustment"
                          //       onClick={this.addMoreFileHandler}
                          //     >
                          //       Add More
                          //     </button>
                          //   </div>
                          // </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <footer className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button btn-light"
              name="submit"
              onClick={
                this.state.activeStep === 0
                  ? () => this.props.returnCallbackViewPage(true)
                  : this.previousStepHandler
              }
            >
              {this.state.activeStep === 0 ? "CANCEL" : "PREVIOUS"}
            </button>
            <button
              type="button"
              className={
                this.state.activeStep === 0
                  ? "drawer_text drawer_btn_success"
                  : "btn next_button"
              }
              name="Back"
              onClick={
                this.state.activeStep === 0
                  ? this.submitHandler
                  : this.nextStepHandler
              }
            >
              {this.state.activeStep === 0 ? "UPDATE" : "NEXT STEP"}
            </button>
          </footer>
        </div>
      );
    }
  }
}
