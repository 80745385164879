import React, { Component } from "react";
import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import {
  TextField,
  MenuItem,
  InputAdornment,
  Snackbar,
} from "@material-ui/core";
import api from "../../../api/api";
import { Alert } from "@material-ui/lab";
import FileUpload from "../../common/FileUpload";
import Loader from "../../common/Loader";

export default class MVProcurementChargesUpdate extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      vendorTypeAsOtherMenu: [
        <MenuItem
          style={{ fontFamily: "Poppins", fontSize: "14px" }}
          value="0"
          key={-1}
        >
          Please Select
        </MenuItem>,
      ],

      multiRows: [
        {
          billing_currency:
            config.company === "SRPL" ||
            config.company === "AVS" ||
            config.company === "PRPL"
              ? "USD"
              : "IDR",
          currency_xchg_rate: "1",
          payment_to: "0",
          payee_name: "",
          pph23_account: "0",
          barge_quantity: "",
          base_price_pmt: "",
          base_price: "",
          vat_percent: "10",
          vat_value: "",
          pph23_percent: "2",
          pph23_value: "",
          payable_to_vendor: "",
          cost_to_company: "",
          remarks: "",
        },
      ],
      isLoading: true,
      features: [],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    } else {
      var features = this.Cookie.getCookie("features");
      this.setState({ features: features });
      if (
        !(
          features.includes("236") ||
          features.includes("415") ||
          features.includes("466") ||
          features.includes("97")
        )
      ) {
        window.location.href = "/dashboard";
      } else {
        var loginUserID = this.Cookie.getCookie("loginUserId");
        var idToken = this.Cookie.getIdTokenCookie();
        try {
          // var businessNoID = decodeURIComponent(window.atob(this.props.match.params.businessNoID));
          var businessNoID = this.props.businessNoID;

          //Get businessNumber API based on businessNumber
          const getBusinessNumber = await api.getBusinessNumber(
            businessNoID,
            loginUserID,
            idToken
          );
          if (
            getBusinessNumber.status >= 200 &&
            getBusinessNumber.status < 300
          ) {
            getBusinessNumber.json().then((res) => {
              if (res.code === "200") {
                if (res.business_number) {
                  if (
                    res.business_number.consignment_type === "Mother Vessel"
                  ) {
                    // if consignment_type === Mother Vessel check for this businessNumber mother Vessel data is avilabe or not
                    //Get Business No Operational Mother Vessel list
                    api
                      .getBusinessNumberMotherVessel(
                        businessNoID,
                        loginUserID,
                        idToken
                      )
                      .then((response) => {
                        if (response.status >= 200 && response.status < 300) {
                          response.json().then((res) => {
                            if (res.idtoken)
                              this.Cookie.setIdTokenCookie(res.idtoken);
                            if (res.code === "200") {
                              if (res.business_no_MV.length > 0) {
                                var motherVesselID = res.business_no_MV[0].id;
                                //Get Business No Operational Mother Vessel Details
                                api
                                  .getBusinessNoOpMVDetails(
                                    motherVesselID,
                                    loginUserID,
                                    idToken
                                  )
                                  .then((response) => {
                                    if (
                                      response.status >= 200 &&
                                      response.status < 300
                                    ) {
                                      response.json().then((res) => {
                                        if (res.business_no_MV) {
                                          this.setState({
                                            motherVesselID: motherVesselID,
                                            vessel_type: res.business_no_MV
                                              .vessel_type
                                              ? res.business_no_MV.vessel_type
                                              : "",
                                            vessel_final_quantity_in_mt: res
                                              .business_no_MV
                                              .vessel_final_quantity_in_mt
                                              ? res.business_no_MV
                                                  .vessel_final_quantity_in_mt
                                              : "",
                                          });
                                        } else {
                                          alert(
                                            "Unexpected error occured. Please contact administrator."
                                          );
                                        }
                                      });
                                    }
                                  });
                              }
                            } else {
                              alert(
                                "Unexpected error occured. Please contact administrator."
                              );
                            }
                          });
                        }
                      });
                  }
                  this.setState({
                    businessNo: res.business_number.business_no
                      ? res.business_number.business_no
                      : "",
                    mother_vessel_name: res.business_number.mother_vessel_name
                      ? res.business_number.mother_vessel_name
                      : "",
                    //isLoading: false
                  });
                }
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  snackBarErrorOpen: true,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
          // calling Vendors Api
          var status = "Active";
          const vendorsDataResponse = await api.getVendors(
            loginUserID,
            idToken,
            status
          );
          if (
            vendorsDataResponse.status >= 200 &&
            vendorsDataResponse.status < 300
          ) {
            vendorsDataResponse.json().then((res) => {
              if (res.code === "200") {
                if (res.vendors) {
                  const vendorTypeAsOther = this.state.vendorTypeAsOtherMenu;
                  const surveyors = [];
                  const vendorAsOthers = [];
                  for (var i of res.vendors) {
                    if (i.vendor_type === "Others") {
                      vendorTypeAsOther.push(
                        <MenuItem
                          style={{ fontFamily: "Poppins", fontSize: "14px" }}
                          value={i.id}
                          key={[i.id]}
                        >
                          {i.vendor_name}
                        </MenuItem>
                      );
                    }
                    if (i.vendor_type === "Surveyor") {
                      surveyors.push({
                        name: i.vendor_name,
                        value: i.id,
                        key: i,
                      });
                    }
                    if (i.vendor_type === "Others") {
                      vendorAsOthers.push({
                        name: i.vendor_name,
                        value: i.id,
                        key: i,
                      });
                    }
                  }
                  this.setState({
                    vendorTypeAsOtherMenu: vendorTypeAsOther,
                    vendorTypeAsSurveyors: surveyors,
                    vendorTypeAsOthers: vendorAsOthers,
                  });
                } else {
                  alert(
                    "Unexpected error occured. Please contact administrator."
                  );
                }
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }

          var coalProcurementChargesID = this.props.coalProcurementChargesID;
          //Get get_mv_pur_fin_cpc API based on coalProcurementChargesID
          const get_mv_pur_fin_cpc = await api.get_mv_pur_fin_cpc(
            loginUserID,
            idToken,
            coalProcurementChargesID
          );
          if (
            get_mv_pur_fin_cpc.status >= 200 &&
            get_mv_pur_fin_cpc.status < 300
          ) {
            get_mv_pur_fin_cpc.json().then((res) => {
              if (res.code === "200") {
                this.setState({
                  costing_no: res.mv_pur_fin_cpc[0]?.costing_no,
                  multiRows: res.mv_pur_fin_cpc,
                  files:
                    res.mv_pur_fin_cpc.length > 0
                      ? res.mv_pur_fin_cpc[0].files
                      : [],
                  isLoading: false,
                });
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  snackBarErrorOpen: true,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        } catch (e) {
          window.location.href = "/business-number-list";
        }
      }
    }

    document.title =
      config.documentTitle +
      "Mother Vessel Purchase Financial Coal Procurement Charges";
  }

  onPriceCalculation = (id) => {
    var row = this.state.multiRows;
    var barge_quantity = row[id].barge_quantity;
    var base_price_pmt = row[id].base_price_pmt;
    var totalBasePrice = Number(barge_quantity) * Number(base_price_pmt);
    var base_price = Number(totalBasePrice).toFixed(4);
    var vat_percent = row[id].vat_percent;
    var vat_value = ((Number(base_price) * Number(vat_percent)) / 100).toFixed(
      2
    );
    var pph23_percent = row[id].pph23_percent;
    var pph23_value = (
      (Number(base_price) * Number(pph23_percent)) /
      100
    ).toFixed(2);
    var cost_to_company = 0;
    var payable_to_vendor = (Number(base_price) + Number(vat_value)).toFixed(2);
    if (
      config.company === "SRPL" ||
      config.company === "AVS" ||
      config.company === "PRPL"
    ) {
      cost_to_company = (Number(base_price) + Number(vat_value)).toFixed(2);
    } else {
      if (row[id].pph23_account === "Supplier") {
        payable_to_vendor = (
          Number(base_price) +
          Number(vat_value) -
          Number(pph23_value)
        ).toFixed(2);
        cost_to_company = (Number(base_price) + Number(vat_value)).toFixed(2);
      } else if (row[id].pph23_account === "SDAM") {
        payable_to_vendor = Number(totalBasePrice).toFixed(2);
        base_price = (Number(payable_to_vendor * 100) / 98).toFixed(2);
        pph23_value = (
          (Number(base_price) * Number(pph23_percent)) /
          100
        ).toFixed(2);
        cost_to_company = (Number(base_price) + Number(pph23_value)).toFixed(2);
      }
    }

    row[id].base_price = base_price;
    row[id].vat_percent = vat_percent;
    row[id].vat_value = vat_value;
    row[id].pph23_percent = pph23_percent;
    row[id].pph23_value = pph23_value;
    row[id].payable_to_vendor = payable_to_vendor;
    row[id].cost_to_company = cost_to_company;

    this.setState({
      multiRows: row,
    });
  };

  addMoreProcurementRowsHandler = () => {
    const item = {
      billing_currency:
        config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL"
          ? "USD"
          : "IDR",
      currency_xchg_rate: "1",
      payment_to: "0",
      payee_name: "",
      invoice_no: "",
      invoice_date: null,
      ppn: "",
      ppn_date: null,
      pph23_account: "0",
      barge_quantity: this.state.vessel_final_quantity_in_mt,
      base_price_pmt: "",
      base_price: "",
      vat_percent: "10",
      vat_value: "",
      pph23_percent: "2",
      pph23_value: "",
      payable_to_vendor: "",
      cost_to_company: "",
      remarks: "",
      removeOption: true,
    };

    this.setState({
      multiRows: [...this.state.multiRows, item],
      removeRows: true,
    });
  };

  removeProcurementRowsHandler = (idx) => () => {
    const rows = this.state.multiRows;
    rows.splice(idx, 1);
    this.setState({
      multiRows: rows,
    });
  };

  toLocaleString(value) {
    return Number(value).toLocaleString();
  }

  submitHandler = (e) => {
    this.setState({ isLoading: true });
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    var { multiRows } = this.state;

    // for (var i in multiRows) {
    //   if (multiRows[i].payment_to === "Others") {
    //     multiRows[i].payment_to = "";
    //   }
    // }
    /**
     *  Checking Mandatory files
     */
    var missing_fields = 0;
    let files_missing = 0;
    var files_data = this.state.files;
    for (var obj of files_data) {
      if (obj.file_type !== "" || obj.file_content !== "") {
        if (obj.file_content !== "") {
          if (obj.file_description === "") {
            missing_fields++;
            obj.file_description_error = true;
          } else {
            obj.file_description = obj.file_description.toUpperCase();
          }
        } else if (obj.file_description !== "") {
          if (obj.file_content === "") {
            files_missing++;
          }
        }
      }
    }
    this.setState({
      files: files_data,
    });

    if (missing_fields > 0) {
      this.setState({
        snackBarErrorOpen: true,
        isLoading: false,
        errorMsg: "Please fill mandatory fields.",
      });
      return;
    }
    if (files_missing > 0) {
      this.setState({
        snackBarErrorOpen: true,
        isLoading: false,
        errorMsg: "Please Select file.",
      });
      return;
    }
    var existing_file_ids = [];
    const existing_files = files_data.filter((e) => e.id);
    for (var j of existing_files) {
      existing_file_ids.push(j.id);
    }
    const new_files = files_data.filter((e) => !e.id);
    const content_files = new_files.filter((e) => e.file_content !== "");
    var finalData = multiRows[0];
    finalData.mv_cpc_id = this.props.coalProcurementChargesID;
    finalData.login_user_id = loginUserID;
    finalData.idtoken = idToken;
    finalData.existing_file_ids = existing_file_ids;
    finalData.files = content_files;
    // Add barge purchase financial coal procurement charge api
    var coalProcurementChargesID = this.props.coalProcurementChargesID;
    if (coalProcurementChargesID) {
      api.update_mv_pur_fin_cpc(finalData).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState(
                {
                  successMsg: res.message,
                  snackBarSuccessOpen: true,
                  //isLoading: false,
                },
                () => {
                  setTimeout(() => {
                    this.props.onUpdateCosting({
                      screen: "MvCpc",
                      code: res.code,
                      message: "",
                    });
                  }, 2000);
                }
              );
              // window.location.href = '/mv-purchase-financial-view-coal-procurement-charges/' + btoa(businessNoID);
            } else if (res.code === "601") {
              this.setState({
                isLoading: false,
                errorMsg: res.message,
                snackBarErrorOpen: true,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            } else if (res.code === "624") {
              this.setState({
                isLoading: false,
                errorMsg: res.message,
                snackBarErrorOpen: true,
              });
            }
          });
        }
      });
    } else {
      this.setState({
        isLoading: false,
        errorMsg: "Invalid business no id",
        snackBarErrorOpen: true,
      });
    }
  };

  render() {
    const quan = /^\d*(\.\d{0,3})?$/;
    const pr = /^\d*(\.\d{0,4})?$/;

    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div
          className="container bg-white p-0"
          style={{ minHeight: "100vh", width: 850 }}
        >
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <div className="row clearfix content-header border-bottom">
            <div className="col-lg-12 pl-0">
              <h4
                style={{ padding: "10px 20px" }}
              >{`Update Coal Procurement Charges - ${this.state.costing_no}`}</h4>
            </div>
          </div>
          <div className="drawer-section">
            <div className="card p-3">
              <div className="card-body col-lg-12 p-0">
                {!this.props.updateDocuments && (
                  <div>
                    {this.state.multiRows.map((e, idx) => (
                      <div className="row mb-3" key={idx}>
                        <div
                          className={
                            this.state.multiRows.length > 1
                              ? "col-lg-11 border_view"
                              : "col-lg-12 border_view"
                          }
                        >
                          <div className="row">
                            <div className="col-lg-6 pl-0">
                              <div className="row">
                                <div className="col-lg-12 p-0">
                                  <label className="drawer_label mb-0">
                                    Payment to
                                  </label>
                                  <TextField
                                    name="payment_to"
                                    margin="dense"
                                    variant="outlined"
                                    value={
                                      e.payment_to ? e.payment_to : "Others"
                                    }
                                    onChange={(e) => {
                                      var row = this.state.multiRows;
                                      row[idx].payment_to = e.target.value;
                                      this.setState({
                                        multiRows: row,
                                      });
                                    }}
                                    select
                                    fullWidth
                                    InputProps={{
                                      style: {
                                        fontFamily: "Poppins",
                                        fontSize: "14px",
                                      },
                                    }}
                                  >
                                    {this.state.vendorTypeAsOtherMenu}
                                    {/* <MenuItem value="Others">Others</MenuItem> */}
                                  </TextField>
                                </div>
                                {/* {(e.payment_to === "Others" ||
                                  e.payment_to === null) && (
                                  <div className="col-lg-12 p-0">
                                    <label className="drawer_label mb-0">
                                      Payee Name
                                    </label>
                                    <TextField
                                      name="payee_name"
                                      margin="dense"
                                      variant="outlined"
                                      fullWidth
                                      placeholder="Payee Name"
                                      value={e.payee_name}
                                      onChange={(e) => {
                                        var row = this.state.multiRows;
                                        row[idx].payee_name = e.target.value;
                                        this.setState({
                                          multiRows: row,
                                        });
                                      }}
                                      inputProps={{
                                        style: { textTransform: "uppercase" },
                                      }}
                                    />
                                  </div>
                                )} */}

                                <div className="col-lg-6 p-0">
                                  <label className="drawer_label  mb-0">
                                    Quantity in MT
                                  </label>
                                  <TextField
                                    name="barge_quantity"
                                    margin="dense"
                                    type="number"
                                    variant="outlined"
                                    fullWidth
                                    InputProps={{
                                      style: {
                                        fontFamily: "Poppins",
                                        fontSize: "14px",
                                      },
                                    }}
                                    placeholder="Quantity in MT"
                                    value={e.barge_quantity}
                                    onChange={(e) => {
                                      if (
                                        e.target.value === "" ||
                                        quan.test(e.target.value)
                                      ) {
                                        var row = this.state.multiRows;
                                        row[idx].barge_quantity =
                                          e.target.value;
                                        this.setState(
                                          {
                                            multiRows: row,
                                          },
                                          () => this.onPriceCalculation(idx)
                                        );
                                      }
                                    }}
                                  />
                                </div>

                                <div className="col-lg-6 pr-0">
                                  <label className="drawer_label  mb-0">
                                    Base Price PMT
                                  </label>
                                  <TextField
                                    name="base_price_pmt"
                                    margin="dense"
                                    type="number"
                                    variant="outlined"
                                    fullWidth
                                    placeholder="Base Price PMT"
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          {config.company === "SRPL" ||
                                          config.company === "AVS" ||
                                          config.company === "PRPL"
                                            ? "USD"
                                            : "IDR"}
                                        </InputAdornment>
                                      ),
                                      style: {
                                        fontFamily: "Poppins",
                                        fontSize: "14px",
                                      },
                                    }}
                                    value={e.base_price_pmt}
                                    onChange={(e) => {
                                      if (
                                        e.target.value === "" ||
                                        pr.test(e.target.value)
                                      ) {
                                        var row = this.state.multiRows;
                                        row[idx].base_price_pmt =
                                          e.target.value;
                                        this.setState(
                                          {
                                            multiRows: row,
                                          },
                                          () => this.onPriceCalculation(idx)
                                        );
                                      }
                                    }}
                                  />
                                </div>

                                {(config.company === "SDAM" ||
                                  config.company === "SRK" ||
                                  config.company === "BTR") && (
                                  <div className="col-lg-12 mb-1 p-0">
                                    <label className="drawer_label mb-0">
                                      PPH23 Account
                                    </label>
                                    <TextField
                                      name="pph23_account"
                                      margin="dense"
                                      variant="outlined"
                                      fullWidth
                                      select
                                      InputProps={{
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                        },
                                      }}
                                      value={e.pph23_account}
                                      onChange={(e) => {
                                        var row = this.state.multiRows;
                                        row[idx].pph23_account = e.target.value;
                                        row[idx].pph23_percent =
                                          e.target.value === "SDAM" ? 2 : 2;
                                        this.setState(
                                          {
                                            multiRows: row,
                                          },
                                          () => this.onPriceCalculation(idx)
                                        );
                                      }}
                                    >
                                      <MenuItem value="0">
                                        Please Select
                                      </MenuItem>
                                      <MenuItem value="Supplier">
                                        Supplier
                                      </MenuItem>
                                      <MenuItem value="SDAM">SDAM</MenuItem>
                                    </TextField>{" "}
                                  </div>
                                )}
                                <div className="col-lg-12 mb-1 p-0">
                                  <label className="drawer_label mb-0">
                                    Remarks
                                  </label>
                                  <TextField
                                    name="remarks"
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth
                                    placeholder="Remarks"
                                    value={e.remarks}
                                    multiline
                                    rows={3}
                                    onChange={(e) => {
                                      var row = this.state.multiRows;
                                      row[idx].remarks = e.target.value;
                                      this.setState({
                                        multiRows: row,
                                      });
                                    }}
                                    inputProps={{
                                      style: { textTransform: "uppercase" },
                                    }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div
                              className="col-lg-6 p-2 rounded"
                              style={{ backgroundColor: "#f7f3f0" }}
                            >
                              <div className="row">
                                {(config.company === "SDAM" ||
                                  config.company === "SRK" ||
                                  config.company === "BTR") &&
                                  e.pph23_account !== "0" &&
                                  e.pph23_account === "SDAM" && (
                                    <div className="col-lg-4 p-0 mb-2">
                                      <label
                                        className="contract_display_header_label"
                                        style={{
                                          color: "rgba(72, 59, 52, 0.7)",
                                        }}
                                      >
                                        Base Price
                                      </label>
                                      <div
                                        className="contract_display_header_value"
                                        style={{ color: "#42352d" }}
                                      >
                                        {e.base_price
                                          ? this.toLocaleString(e.base_price)
                                          : "-"}
                                      </div>
                                    </div>
                                  )}
                                {(config.company === "SRPL" ||
                                  config.company === "AVS" ||
                                  config.company === "PRPL" ||
                                  ((config.company === "SDAM" ||
                                    config.company === "SRK" ||
                                    config.company === "BTR") &&
                                    e.pph23_account !== "0" &&
                                    e.pph23_account === "Supplier")) && (
                                  <div className="col-lg-4 p-0 mb-2">
                                    <label
                                      className="contract_display_header_label"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Base Price
                                    </label>
                                    <TextField
                                      name="base_price"
                                      margin="dense"
                                      type="number"
                                      // variant='outlined'
                                      fullWidth
                                      InputProps={{
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                        },
                                      }}
                                      value={e.base_price}
                                      onChange={(e) => {
                                        if (
                                          e.target.value === "" ||
                                          pr.test(e.target.value)
                                        ) {
                                          var row = this.state.multiRows;
                                          var vat_value = Number(
                                            (Number(e.target.value) *
                                              Number(row[idx].vat_percent)) /
                                              100
                                          ).toFixed(2);
                                          var cost_to_company = (
                                            Number(e.target.value) +
                                            Number(vat_value)
                                          ).toFixed(2);
                                          row[idx].base_price = e.target.value;
                                          row[idx].vat_value = vat_value;
                                          row[idx].cost_to_company =
                                            cost_to_company;
                                          row[idx].payable_to_vendor =
                                            cost_to_company;
                                          this.setState({
                                            multiRows: row,
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                )}
                                {(config.company === "SRPL" ||
                                  config.company === "AVS" ||
                                  config.company === "PRPL" ||
                                  ((config.company === "SDAM" ||
                                    config.company === "SRK" ||
                                    config.company === "BTR") &&
                                    e.pph23_account !== "0" &&
                                    e.pph23_account === "Supplier")) && (
                                  <div className="col-lg-4 pr-0 mb-2">
                                    <label
                                      className="contract_display_header_label"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      VAT %
                                    </label>
                                    <TextField
                                      name="vat_percent"
                                      margin="dense"
                                      type="number"
                                      // variant='outlined'
                                      fullWidth
                                      InputProps={{
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                        },
                                      }}
                                      value={e.vat_percent}
                                      onChange={(e) => {
                                        if (
                                          e.target.value === "" ||
                                          pr.test(e.target.value)
                                        ) {
                                          var row = this.state.multiRows;
                                          var vat_value = Number(
                                            (Number(row[idx].base_price) *
                                              Number(e.target.value)) /
                                              100
                                          ).toFixed(2);
                                          var cost_to_company = (
                                            Number(row[idx].base_price) +
                                            Number(vat_value)
                                          ).toFixed(0);
                                          row[idx].vat_percent = e.target.value;
                                          row[idx].vat_value = vat_value;
                                          row[idx].cost_to_company =
                                            cost_to_company;
                                          row[idx].payable_to_vendor =
                                            cost_to_company;
                                          this.setState({
                                            multiRows: row,
                                          });
                                        }
                                      }}
                                    />{" "}
                                  </div>
                                )}
                                {(config.company === "SRPL" ||
                                  config.company === "AVS" ||
                                  config.company === "PRPL" ||
                                  ((config.company === "SDAM" ||
                                    config.company === "SRK" ||
                                    config.company === "BTR") &&
                                    e.pph23_account !== "0" &&
                                    e.pph23_account === "Supplier")) && (
                                  <div className="col-lg-4 p-0 mb-2">
                                    <label
                                      className="contract_display_header_label"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      VAT
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {e.vat_value
                                        ? this.toLocaleString(e.vat_value)
                                        : "-"}
                                    </div>
                                  </div>
                                )}
                                {(config.company === "SDAM" ||
                                  config.company === "SRK" ||
                                  config.company === "BTR") &&
                                  e.pph23_account !== "0" &&
                                  e.pph23_account === "Supplier" && (
                                    <div className="col-lg-6 p-0 mb-2">
                                      <label
                                        className="contract_display_header_label"
                                        style={{
                                          color: "rgba(72, 59, 52, 0.7)",
                                        }}
                                      >
                                        PPH23 %
                                      </label>
                                      <TextField
                                        name="pph23_percent"
                                        margin="dense"
                                        type="number"
                                        // variant='outlined'
                                        fullWidth
                                        InputProps={{
                                          style: {
                                            fontFamily: "Poppins",
                                            fontSize: "14px",
                                          },
                                        }}
                                        value={e.pph23_percent}
                                        onChange={(e) => {
                                          if (
                                            e.target.value === "" ||
                                            pr.test(e.target.value)
                                          ) {
                                            var row = this.state.multiRows;
                                            let pph23_value = Number(
                                              (
                                                (Number(row[idx].base_price) *
                                                  Number(e.target.value)) /
                                                100
                                              ).toFixed(2)
                                            );
                                            let payable_to_vendor = (
                                              Number(row[idx].base_price) +
                                              Number(row[idx].vat_value) -
                                              Number(pph23_value)
                                            ).toFixed(2);
                                            let cost_to_company = (
                                              Number(row[idx].base_price) +
                                              Number(row[idx].vat_value)
                                            ).toFixed(2);
                                            row[idx].pph23_percent =
                                              e.target.value;
                                            row[idx].pph23_value = pph23_value;
                                            row[idx].cost_to_company =
                                              cost_to_company;
                                            row[idx].payable_to_vendor =
                                              payable_to_vendor;
                                            this.setState({
                                              multiRows: row,
                                            });
                                          }
                                        }}
                                      />{" "}
                                    </div>
                                  )}
                                {(config.company === "SDAM" ||
                                  config.company === "SRK" ||
                                  config.company === "BTR") &&
                                  e.pph23_account !== "0" &&
                                  (e.pph23_account === "Supplier" ||
                                    e.pph23_account === "SDAM") && (
                                    <div className="col-lg-6 p-0 mb-2">
                                      <label
                                        className="contract_display_header_label"
                                        style={{
                                          color: "rgba(72, 59, 52, 0.7)",
                                        }}
                                      >
                                        Includes PPH23 @ {e.pph23_percent} %
                                      </label>
                                      <div
                                        className="contract_display_header_value"
                                        style={{ color: "#42352d" }}
                                      >
                                        {e.pph23_value
                                          ? this.toLocaleString(e.pph23_value)
                                          : "-"}
                                      </div>
                                    </div>
                                  )}
                                <div className="row col-lg-12 p-0">
                                  {(config.company === "SRPL" ||
                                    config.company === "AVS" ||
                                    config.company === "PRPL" ||
                                    ((config.company === "SDAM" ||
                                      config.company === "SRK" ||
                                      config.company === "BTR") &&
                                      e.pph23_account !== "0" &&
                                      e.pph23_account === "Supplier")) && (
                                    <div className="col-lg-6 p-0">
                                      <label
                                        className="contract_display_header_label"
                                        style={{
                                          color: "rgba(72, 59, 52, 0.7)",
                                        }}
                                      >
                                        Payable to Vendor
                                      </label>
                                      <div
                                        className="contract_display_header_value"
                                        style={{ color: "#42352d" }}
                                      >
                                        {e.payable_to_vendor
                                          ? this.toLocaleString(
                                              e.payable_to_vendor
                                            )
                                          : 0}{" "}
                                        {config.company === "SRPL" ||
                                        config.company === "AVS" ||
                                        config.company === "PRPL"
                                          ? "USD"
                                          : "IDR"}
                                      </div>
                                    </div>
                                  )}
                                  {(config.company === "SDAM" ||
                                    config.company === "SRK" ||
                                    config.company === "BTR") &&
                                    e.pph23_account !== "0" &&
                                    e.pph23_account === "SDAM" && (
                                      <div className="col-lg-6 p-0">
                                        <label
                                          className="contract_display_header_label mb-0"
                                          style={{
                                            color: "rgba(72, 59, 52, 0.7)",
                                          }}
                                        >
                                          Payable to Vendor
                                        </label>
                                        <TextField
                                          name="payable_to_vendor"
                                          margin="dense"
                                          type="number"
                                          variant="outlined"
                                          fullWidth
                                          InputProps={{
                                            style: {
                                              fontFamily: "Poppins",
                                              fontSize: "14px",
                                            },
                                          }}
                                          value={e.payable_to_vendor}
                                          onChange={(e) => {
                                            if (
                                              e.target.value === "" ||
                                              pr.test(e.target.value)
                                            ) {
                                              var row = this.state.multiRows;
                                              let base_price = (
                                                (Number(e.target.value) * 100) /
                                                98
                                              ).toFixed(2);
                                              let pph23_value = (
                                                (Number(base_price) *
                                                  Number(
                                                    row[idx].pph23_percent
                                                  )) /
                                                100
                                              ).toFixed(2);
                                              var cost_to_company = (
                                                Number(base_price) +
                                                Number(pph23_value)
                                              ).toFixed(2);
                                              row[idx].payable_to_vendor =
                                                e.target.value;
                                              row[idx].base_price = base_price;
                                              row[idx].pph23_value =
                                                pph23_value;
                                              row[idx].cost_to_company =
                                                cost_to_company;
                                              this.setState({
                                                multiRows: row,
                                              });
                                            }
                                          }}
                                        />
                                      </div>
                                    )}

                                  <div className="col-lg-6 pr-0">
                                    <label
                                      className="contract_display_header_label"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Cost to Company
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {e.cost_to_company
                                        ? this.toLocaleString(e.cost_to_company)
                                        : 0}{" "}
                                      {config.company === "SRPL" ||
                                      config.company === "AVS" ||
                                      config.company === "PRPL"
                                        ? "USD"
                                        : "IDR"}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                {/* {(this.state.features.includes('519')  || this.state.features.includes('97') ) && */}

                <FileUpload
                  files={this.state.files}
                  feature_name="Mv_Pur_Fin_Cpc"
                  callbackFn={(data) => this.setState({ files: data })}
                />
              </div>
            </div>
          </div>
          <footer className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button btn-light"
              name="submit"
              onClick={() => {
                this.props.returnCallbackViewPage("MvCpc");
              }}
            >
              Back
            </button>
            <button
              type="button"
              className="drawer_text drawer_btn_success"
              name="Back"
              onClick={this.submitHandler}
            >
              Update
            </button>
          </footer>
        </div>
      );
    }
  }
}
