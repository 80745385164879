import React, { Component } from "react";
import CookieHandler from "../common/CookieHandler";
// import SideBar from '../Common/SideBar';
// import Header from '../Common/Header';
import api from "../../api/api";
import config from "../../config/config";
import { Alert } from "@material-ui/lab";

import {
  Snackbar,
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";

import exportToExcel from "../Export_To_Excel";
import AvgXchgRate from "./Business-No-P&L-Avg-Xchg-Rate";
import Loader from "../common/Loader";

export default class Business_Profit_Loss_Summary extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      business_no_summary: {
        coal_costing_data: [],
        barge_costing_data: [],
        chemical_spray_data: [],
        cpc_costing_data: [],
        pre_shipment_data: [],
        export_tax_data: [],
        sac_charges_data: [],
        doc_charges_data: [],
      },
      avg_xchg_data: [],
      activeTabIndex: 0,
      isLoading: true,
      features: [],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    var features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (!(features.includes("314") || features.includes("97"))) {
      window.location.href = "/dashboard";
    }
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();

    try {
      // var business_no_id = decodeURIComponent(window.atob(this.props.match.params.businessNo));
      var business_no_id = this.props.businessNoID;
    } catch (e) {
      window.location.href = "/business-number-list";
    }
    await api
      .get_business_no_profit_loss_summary(loginUserID, idToken, business_no_id)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.business_no_summary) {
                var row = res.business_no_summary;
                var coal_cost_amount_idr = 0;
                var coal_cost_amount_usd = 0;
                var doc_amount_idr = 0;
                var doc_amount_usd = 0;

                let laprn_amount_idr = 0;
                let laprn_amount_usd = 0;

                var sac_amount_idr = 0;
                var sac_amount_usd = 0;
                var pre_shipment_amount_idr = 0;
                var pre_shipment_amount_usd = 0;
                var export_tax_amount_idr = 0;
                var export_tax_amount_usd = 0;

                // barge costing
                var barge_cost_amount_idr = 0;
                var barge_cost_amount_usd = 0;

                // Chemical Spray
                var chemical_cost_amount_idr = 0;
                var chemical_cost_amount_usd = 0;

                // Coal Procurement charges
                var cpc_amount_idr = 0;
                var cpc_amount_usd = 0;
                // Coal Procurement Service
                var cps_amount_idr = 0;
                var cps_amount_usd = 0;

                var inc_debit_amount_idr = 0;
                var inc_debit_amount_usd = 0;
                for (var i of row.business_no_debit_note_data) {
                  if (i.debit_type === "Incoming") {
                    inc_debit_amount_idr += i.amount_idr
                      ? Number(i.amount_idr)
                      : 0;
                    inc_debit_amount_usd += i.amount_usd
                      ? Number(i.amount_usd)
                      : 0;
                  }
                }
                // for (var i of row.business_no_debit_note_data) {
                //   if (i.debit_type === "Outgoing") {
                //     out_debit_amount_idr += i.amount_idr ? (Number(i.amount_idr)) : 0;
                //     out_debit_amount_usd += i.amount_usd ? (Number(i.amount_usd)) : 0;
                //   }
                // }

                var out_credit_amount_idr = 0;
                var out_credit_amount_usd = 0;
                for (var c of row.business_no_credit_note_data) {
                  if (c.credit_type === "Outgoing") {
                    out_credit_amount_idr += c.amount_idr
                      ? Number(c.amount_idr)
                      : 0;
                    out_credit_amount_usd += c.amount_usd
                      ? Number(c.amount_usd)
                      : 0;
                  }
                }

                this.setState({
                  inc_debit_amount_idr: inc_debit_amount_idr,
                  inc_debit_amount_usd: inc_debit_amount_usd,

                  out_credit_amount_idr: out_credit_amount_idr,
                  out_credit_amount_usd: out_credit_amount_usd,
                });

                for (var cc of row.coal_costing_data) {
                  coal_cost_amount_idr += cc.adjusted_coal_price_idr
                    ? Number(cc.adjusted_coal_price_idr)
                    : 0;
                  coal_cost_amount_usd += cc.adjusted_coal_price_usd
                    ? Number(cc.adjusted_coal_price_usd)
                    : 0;
                }
                for (var bc of row.barge_costing_data) {
                  barge_cost_amount_idr += bc.barge_ctc_idr
                    ? Number(bc.barge_ctc_idr)
                    : 0;
                  barge_cost_amount_usd += bc.barge_ctc_usd
                    ? Number(bc.barge_ctc_usd)
                    : 0;
                }
                for (var doc of row.doc_charges_data) {
                  doc_amount_idr += doc.dc_ctc_idr ? Number(doc.dc_ctc_idr) : 0;
                  doc_amount_idr += doc.dc_laprn_surv_ctc_idr
                    ? Number(doc.dc_laprn_surv_ctc_idr)
                    : 0;
                  doc_amount_usd += doc.dc_ctc_usd ? Number(doc.dc_ctc_usd) : 0;
                  doc_amount_usd += doc.dc_laprn_surv_ctc_usd
                    ? Number(doc.dc_laprn_surv_ctc_usd)
                    : 0;
                }
                for (var laprn of row.laparon_charges_data) {
                  laprn_amount_idr += laprn.dc_ctc_idr
                    ? Number(laprn.dc_ctc_idr)
                    : 0;
                  laprn_amount_idr += laprn.dc_laprn_surv_ctc_idr
                    ? Number(laprn.dc_laprn_surv_ctc_idr)
                    : 0;
                  laprn_amount_usd += laprn.dc_ctc_usd
                    ? Number(laprn.dc_ctc_usd)
                    : 0;
                  laprn_amount_usd += laprn.dc_laprn_surv_ctc_usd
                    ? Number(laprn.dc_laprn_surv_ctc_usd)
                    : 0;
                }
                for (var sac of row.sac_charges_data) {
                  sac_amount_idr += sac.sac_surveyor_ctc_idr
                    ? Number(sac.sac_surveyor_ctc_idr)
                    : 0;
                  sac_amount_usd += sac.sac_surveyor_ctc_usd
                    ? Number(sac.sac_surveyor_ctc_usd)
                    : 0;
                  sac_amount_idr += sac.sac_other_ctc_idr
                    ? Number(sac.sac_other_ctc_idr)
                    : 0;
                  sac_amount_usd += sac.sac_other_ctc_usd
                    ? Number(sac.sac_other_ctc_usd)
                    : 0;
                  sac_amount_idr += sac.sac_surveyor_addl_days_ctc_idr
                    ? Number(sac.sac_surveyor_addl_days_ctc_idr)
                    : 0;
                  sac_amount_usd += sac.sac_surveyor_addl_days_ctc_usd
                    ? Number(sac.sac_surveyor_addl_days_ctc_usd)
                    : 0;
                }
                for (var pre of row.pre_shipment_data) {
                  pre_shipment_amount_idr += pre.pre_shipment_ctc_idr
                    ? Number(pre.pre_shipment_ctc_idr)
                    : 0;
                  pre_shipment_amount_usd += pre.pre_shipment_ctc_usd
                    ? Number(pre.pre_shipment_ctc_usd)
                    : 0;
                }
                for (var exp of row.export_tax_data) {
                  export_tax_amount_idr += exp.export_tax_idr
                    ? Number(exp.export_tax_idr)
                    : 0;
                  export_tax_amount_usd += exp.export_tax_usd
                    ? Number(exp.export_tax_usd)
                    : 0;
                }
                for (var cs of row.chemical_spray_data) {
                  chemical_cost_amount_idr += cs.chemical_spray_price_idr
                    ? Number(cs.chemical_spray_price_idr)
                    : 0;
                  chemical_cost_amount_usd += cs.chemical_spray_price_usd
                    ? Number(cs.chemical_spray_price_usd)
                    : 0;
                }
                for (var cpc of row.cpc_costing_data) {
                  cpc_amount_idr += cpc.cost_to_company_idr
                    ? Number(cpc.cost_to_company_idr)
                    : 0;
                  cpc_amount_usd += cpc.cost_to_company_usd
                    ? Number(cpc.cost_to_company_usd)
                    : 0;
                }
                for (var cps of row.cps_costing_data) {
                  cps_amount_idr += cps.cost_to_company_idr
                    ? Number(cps.cost_to_company_idr)
                    : 0;
                  cps_amount_usd += cps.cost_to_company_usd
                    ? Number(cps.cost_to_company_usd)
                    : 0;
                }
                // Return Cargo Charges
                var return_cargo_amount_idr = 0;
                var return_cargo_amount_usd = 0;
                for (var cargo of row.return_cargo_charges_data) {
                  return_cargo_amount_idr += cargo.dc_ctc_idr
                    ? Number(cargo.dc_ctc_idr)
                    : 0;
                  return_cargo_amount_idr += cargo.et_ctc_idr
                    ? Number(cargo.et_ctc_idr)
                    : 0;
                  return_cargo_amount_idr += cargo.ls_ctc_idr
                    ? Number(cargo.ls_ctc_idr)
                    : 0;

                  return_cargo_amount_usd += cargo.dc_ctc_usd
                    ? Number(cargo.dc_ctc_usd)
                    : 0;
                  return_cargo_amount_usd += cargo.et_ctc_usd
                    ? Number(cargo.et_ctc_usd)
                    : 0;
                  return_cargo_amount_usd += cargo.ls_ctc_usd
                    ? Number(cargo.ls_ctc_usd)
                    : 0;
                }
                /* Sales finanacials */
                // coal sales charges
                var csc_amount_idr = 0;
                var csc_amount_usd = 0;

                for (var csc of row.csc_costing_data) {
                  csc_amount_idr += csc.cost_to_company_idr
                    ? Number(csc.cost_to_company_idr)
                    : 0;
                  csc_amount_usd += csc.cost_to_company_usd
                    ? Number(csc.cost_to_company_usd)
                    : 0;
                }

                // Mother Vessel Purchase fianancials
                var sc_amount_idr = 0;
                var sc_amount_usd = 0;
                for (var sc of row.mv_stevedore_costing_data) {
                  sc_amount_idr += sc.stevedore_cost_to_company_idr
                    ? Number(sc.stevedore_cost_to_company_idr)
                    : 0;
                  sc_amount_idr += sc.pnbp_idr ? Number(sc.pnbp_idr) : 0;
                  sc_amount_idr += sc.jasa_pbm_value_idr
                    ? Number(sc.jasa_pbm_value_idr)
                    : 0;
                  sc_amount_idr += sc.stevedore_addl_cost_to_company_idr
                    ? Number(sc.stevedore_addl_cost_to_company_idr)
                    : 0;
                  sc_amount_idr += sc.wait_period_total_price_idr
                    ? Number(sc.wait_period_total_price_idr)
                    : 0;
                  sc_amount_idr += sc.total_price_idr
                    ? Number(sc.total_price_idr)
                    : 0;

                  sc_amount_usd += sc.stevedore_cost_to_company_usd
                    ? Number(sc.stevedore_cost_to_company_usd)
                    : 0;
                  sc_amount_usd += sc.pnbp_usd ? Number(sc.pnbp_usd) : 0;
                  sc_amount_usd += sc.jasa_pbm_value_usd
                    ? Number(sc.jasa_pbm_value_usd)
                    : 0;
                  sc_amount_usd += sc.stevedore_addl_cost_to_company_usd
                    ? Number(sc.stevedore_addl_cost_to_company_usd)
                    : 0;
                  sc_amount_usd += sc.wait_period_total_price_usd
                    ? Number(sc.wait_period_total_price_usd)
                    : 0;
                  sc_amount_usd += sc.total_price_usd
                    ? Number(sc.total_price_usd)
                    : 0;
                }

                var fc_amount_idr = 0;
                var fc_amount_usd = 0;
                for (var fc of row.mv_floating_crane_charges_data) {
                  fc_amount_idr += fc.floating_crane_cost_to_company_idr
                    ? Number(fc.floating_crane_cost_to_company_idr)
                    : 0;
                  fc_amount_idr += fc.pnbp_idr ? Number(fc.pnbp_idr) : 0;
                  fc_amount_idr += fc.jasa_pbm_value_idr
                    ? Number(fc.jasa_pbm_value_idr)
                    : 0;
                  fc_amount_idr += fc.floating_crane_addl_cost_to_company_idr
                    ? Number(fc.floating_crane_addl_cost_to_company_idr)
                    : 0;
                  fc_amount_idr += fc.wait_period_total_price_idr
                    ? Number(fc.wait_period_total_price_idr)
                    : 0;
                  fc_amount_idr += fc.total_price_idr
                    ? Number(fc.total_price_idr)
                    : 0;

                  fc_amount_usd += fc.floating_crane_cost_to_company_usd
                    ? Number(fc.floating_crane_cost_to_company_usd)
                    : 0;
                  fc_amount_usd += fc.pnbp_usd ? Number(fc.pnbp_usd) : 0;
                  fc_amount_usd += fc.jasa_pbm_value_usd
                    ? Number(fc.jasa_pbm_value_usd)
                    : 0;
                  fc_amount_usd += fc.floating_crane_addl_cost_to_company_usd
                    ? Number(fc.floating_crane_addl_cost_to_company_usd)
                    : 0;
                  fc_amount_usd += fc.wait_period_total_price_usd
                    ? Number(fc.wait_period_total_price_usd)
                    : 0;
                  fc_amount_usd += fc.total_price_usd
                    ? Number(fc.total_price_usd)
                    : 0;
                }

                var mv_freight_amount_idr = 0;
                var mv_freight_amount_usd = 0;
                for (var fright of row.mv_freight_costing_data) {
                  mv_freight_amount_idr += fright.amount_idr
                    ? Number(fright.amount_idr)
                    : 0;
                  mv_freight_amount_usd += fright.amount_usd
                    ? Number(fright.amount_usd)
                    : 0;
                }

                var mv_cpc_amount_idr = 0;
                var mv_cpc_amount_usd = 0;
                for (var mv_cpc of row.mv_pur_fin_cpc_data) {
                  mv_cpc_amount_idr += mv_cpc.cost_to_company_idr
                    ? Number(mv_cpc.cost_to_company_idr)
                    : 0;
                  mv_cpc_amount_usd += mv_cpc.cost_to_company_usd
                    ? Number(mv_cpc.cost_to_company_usd)
                    : 0;
                }

                var mv_sac_amount_idr = 0;
                var mv_sac_amount_usd = 0;
                for (var mv_sac of row.mv_sac_charges_data) {
                  mv_sac_amount_idr += mv_sac.sac_surveyor_ctc_idr
                    ? Number(mv_sac.sac_surveyor_ctc_idr)
                    : 0;
                  mv_sac_amount_usd += mv_sac.sac_surveyor_ctc_usd
                    ? Number(mv_sac.sac_surveyor_ctc_usd)
                    : 0;
                  mv_sac_amount_idr += mv_sac.sac_other_ctc_idr
                    ? Number(mv_sac.sac_other_ctc_idr)
                    : 0;
                  mv_sac_amount_usd += mv_sac.sac_other_ctc_usd
                    ? Number(mv_sac.sac_other_ctc_usd)
                    : 0;
                  mv_sac_amount_idr += mv_sac.sac_surveyor_addl_days_ctc_idr
                    ? Number(mv_sac.sac_surveyor_addl_days_ctc_idr)
                    : 0;
                  mv_sac_amount_usd += mv_sac.sac_surveyor_addl_days_ctc_usd
                    ? Number(mv_sac.sac_surveyor_addl_days_ctc_usd)
                    : 0;
                }
                var mv_export_tax_amount_idr = 0;
                var mv_export_tax_amount_usd = 0;
                for (var mv_exp of row.mv_export_tax_data) {
                  mv_export_tax_amount_idr += mv_exp.export_tax_idr
                    ? Number(mv_exp.export_tax_idr)
                    : 0;
                  mv_export_tax_amount_usd += mv_exp.export_tax
                    ? Number(mv_exp.export_tax_usd)
                    : 0;
                }
                var mv_pre_amount_idr = 0;
                var mv_pre_amount_usd = 0;
                for (var mv_pre of row.mv_pre_shipment_data) {
                  mv_pre_amount_idr += mv_pre.pre_shipment_ctc_idr
                    ? Number(mv_pre.pre_shipment_ctc_idr)
                    : 0;
                  mv_pre_amount_usd += mv_pre.pre_shipment_ctc_usd
                    ? Number(mv_pre.pre_shipment_ctc_usd)
                    : 0;
                }
                var mv_doc_amount_idr = 0;
                var mv_doc_amount_usd = 0;
                for (var mv_doc of row.mv_doc_charges_data) {
                  mv_doc_amount_idr += mv_doc.dc_ctc_idr
                    ? Number(mv_doc.dc_ctc_idr)
                    : 0;
                  mv_doc_amount_idr += mv_doc.dc_laprn_surv_ctc_idr
                    ? Number(mv_doc.dc_laprn_surv_ctc_idr)
                    : 0;
                  mv_doc_amount_usd += mv_doc.dc_ctc_usd
                    ? Number(mv_doc.dc_ctc_usd)
                    : 0;
                  mv_doc_amount_usd += mv_doc.dc_laprn_surv_ctc_usd
                    ? Number(mv_doc.dc_laprn_surv_ctc_usd)
                    : 0;
                  for (var obj of mv_doc.other_charges) {
                    mv_doc_amount_usd += obj.doc_other_ctc_usd
                      ? Number(obj.doc_other_ctc_usd)
                      : 0;
                    mv_doc_amount_usd += obj.doc_other_ctc_idr
                      ? Number(obj.doc_other_ctc_idr)
                      : 0;
                  }
                }
                var mv_csc_amount_idr = 0;
                var mv_csc_amount_usd = 0;
                for (var mv_csc of row.mv_sales_fin_csc) {
                  mv_csc_amount_idr += mv_csc.cost_to_company_idr
                    ? Number(mv_csc.cost_to_company_idr)
                    : 0;
                  mv_csc_amount_usd += mv_csc.cost_to_company_usd
                    ? Number(mv_csc.cost_to_company_usd)
                    : 0;
                }

                // Mother Vessel Demurrage
                const filter_sup_dem_despatch =
                  row.mv_supplier_demurrage.filter(
                    (e) => e.demurrage_or_despatch === "Despatch"
                  );
                var mv_sup_dem_amount_idr = 0;
                var mv_sup_dem_amount_usd = 0;

                for (var sup of filter_sup_dem_despatch) {
                  mv_sup_dem_amount_idr += sup.supplier_ctc_idr
                    ? Number(sup.supplier_ctc_idr)
                    : 0;
                  mv_sup_dem_amount_usd += sup.supplier_ctc_usd
                    ? Number(sup.supplier_ctc_usd)
                    : 0;
                }

                const filter_buy_dem = row.mv_buyer_demurrage.filter(
                  (e) => e.demurrage_or_despatch === "Demurrage"
                );

                var mv_buy_dem_amount_idr = 0;
                var mv_buy_dem_amount_usd = 0;
                for (var buy of filter_buy_dem) {
                  mv_buy_dem_amount_idr += buy.buyer_ctc_idr
                    ? Number(buy.buyer_ctc_idr)
                    : 0;
                  mv_buy_dem_amount_usd += buy.buyer_ctc_usd
                    ? Number(buy.buyer_ctc_usd)
                    : 0;
                }

                this.setState({
                  // purchase
                  mv_sup_dem_amount_idr: mv_sup_dem_amount_idr,
                  mv_sup_dem_amount_usd: mv_sup_dem_amount_usd,
                  mv_buy_dem_amount_idr: mv_buy_dem_amount_idr,
                  mv_buy_dem_amount_usd: mv_buy_dem_amount_usd,
                });

                // others

                var other_expense_amount_idr = 0;
                var other_expense_amount_usd = 0;
                for (var expense of row.business_no_other_expenses_data) {
                  other_expense_amount_idr += expense.amount_idr
                    ? Number(expense.amount_idr)
                    : 0;
                  other_expense_amount_usd += expense.amount_usd
                    ? Number(expense.amount_usd)
                    : 0;
                }

                // barge Demurrages
                // Purchase

                var dmr_to_bg_vend_idr = 0;
                var dmr_to_bg_vend_usd = 0;
                var dmr_to_supplier_idr = 0;
                var dmr_to_supplier_usd = 0;
                var dmr_to_buyer_idr = 0;
                var dmr_to_buyer_usd = 0;

                for (var dmr_to_bg_vend of row.barge_demurrage_to_barge_vendor_data) {
                  // Purchase
                  dmr_to_bg_vend_idr += dmr_to_bg_vend.dmr_to_bg_vend_ctc_idr
                    ? Number(dmr_to_bg_vend.dmr_to_bg_vend_ctc_idr)
                    : 0;
                  dmr_to_bg_vend_usd += dmr_to_bg_vend.dmr_to_bg_vend_ctc_usd
                    ? Number(dmr_to_bg_vend.dmr_to_bg_vend_ctc_usd)
                    : 0;
                }
                for (var dmr_to_supplier of row.barge_demurrage_to_supplier_data) {
                  dmr_to_supplier_idr += dmr_to_supplier.dmr_to_supplier_ctc_idr
                    ? Number(dmr_to_supplier.dmr_to_supplier_ctc_idr)
                    : 0;
                  dmr_to_supplier_usd += dmr_to_supplier.dmr_to_supplier_ctc_usd
                    ? Number(dmr_to_supplier.dmr_to_supplier_ctc_usd)
                    : 0;
                }
                for (var dmr_to_buyer of row.barge_demurrage_to_buyer_data) {
                  dmr_to_buyer_idr += dmr_to_buyer.dmr_to_buyer_ctc_idr
                    ? Number(dmr_to_buyer.dmr_to_buyer_ctc_idr)
                    : 0;
                  dmr_to_buyer_usd += dmr_to_buyer.dmr_to_buyer_ctc_usd
                    ? Number(dmr_to_buyer.dmr_to_buyer_ctc_usd)
                    : 0;
                }

                this.setState({
                  // Purchase
                  dmr_to_bg_vend_idr: dmr_to_bg_vend_idr,
                  dmr_to_bg_vend_usd: dmr_to_bg_vend_usd,
                  dmr_to_supplier_idr: dmr_to_supplier_idr,
                  dmr_to_supplier_usd: dmr_to_supplier_usd,
                  dmr_to_buyer_idr: dmr_to_buyer_idr,
                  dmr_to_buyer_usd: dmr_to_buyer_usd,
                });

                var purchase_grand_total_idr = Number(
                  coal_cost_amount_idr +
                    barge_cost_amount_idr +
                    chemical_cost_amount_idr +
                    export_tax_amount_idr +
                    doc_amount_idr +
                    laprn_amount_idr +
                    sac_amount_idr +
                    pre_shipment_amount_idr +
                    return_cargo_amount_idr +
                    +cpc_amount_idr +
                    cps_amount_idr +
                    csc_amount_idr +
                    mv_csc_amount_idr +
                    sc_amount_idr +
                    fc_amount_idr +
                    mv_freight_amount_idr +
                    mv_cpc_amount_idr +
                    mv_sac_amount_idr +
                    mv_pre_amount_idr +
                    mv_export_tax_amount_idr +
                    +mv_doc_amount_idr +
                    mv_buy_dem_amount_idr +
                    mv_sup_dem_amount_idr +
                    other_expense_amount_idr +
                    inc_debit_amount_idr +
                    out_credit_amount_idr +
                    dmr_to_bg_vend_idr +
                    dmr_to_supplier_idr +
                    dmr_to_buyer_idr
                );

                var purchase_grand_total_usd = Number(
                  coal_cost_amount_usd +
                    barge_cost_amount_usd +
                    chemical_cost_amount_usd +
                    export_tax_amount_usd +
                    doc_amount_usd +
                    sac_amount_usd +
                    pre_shipment_amount_usd +
                    return_cargo_amount_usd +
                    +cpc_amount_usd +
                    cps_amount_usd +
                    csc_amount_usd +
                    mv_csc_amount_usd +
                    sc_amount_usd +
                    fc_amount_usd +
                    mv_freight_amount_usd +
                    mv_cpc_amount_usd +
                    mv_sac_amount_usd +
                    mv_pre_amount_usd +
                    mv_export_tax_amount_usd +
                    mv_doc_amount_usd +
                    mv_buy_dem_amount_usd +
                    mv_sup_dem_amount_usd +
                    other_expense_amount_usd +
                    inc_debit_amount_usd +
                    out_credit_amount_usd +
                    dmr_to_bg_vend_usd +
                    dmr_to_supplier_usd +
                    dmr_to_buyer_usd
                );

                this.setState(
                  {
                    business_no_summary: row,
                    purchase_grand_total_idr: purchase_grand_total_idr,
                    purchase_grand_total_usd: purchase_grand_total_usd,
                    mother_vessel_name:
                      res.business_no_summary.mother_vessel_name,
                    business_no: res.business_no_summary.business_no,
                    // Coal Costing
                    coal_cost_amount_usd: coal_cost_amount_usd,
                    coal_cost_amount_idr: coal_cost_amount_idr,

                    // Barge costing
                    barge_cost_amount_idr: barge_cost_amount_idr,
                    barge_cost_amount_usd: barge_cost_amount_usd,
                    //doc
                    doc_amount_idr: doc_amount_idr,
                    doc_amount_usd: doc_amount_usd,
                    //laprn
                    laprn_amount_idr: laprn_amount_idr,
                    laprn_amount_usd: laprn_amount_usd,
                    //sac
                    sac_amount_idr: sac_amount_idr,
                    sac_amount_usd: sac_amount_usd,
                    // Pre shipment
                    pre_shipment_amount_idr: pre_shipment_amount_idr,
                    pre_shipment_amount_usd: pre_shipment_amount_usd,
                    // Export Tax
                    export_tax_amount_idr: export_tax_amount_idr,
                    export_tax_amount_usd: export_tax_amount_usd,
                    // Chemical Spray
                    chemical_cost_amount_idr: chemical_cost_amount_idr,
                    chemical_cost_amount_usd: chemical_cost_amount_usd,
                    // Coal Procurement Charges
                    cpc_amount_usd: cpc_amount_usd,
                    cpc_amount_idr: cpc_amount_idr,
                    // Coal Procurement Charges
                    cps_amount_usd: cps_amount_usd,
                    cps_amount_idr: cps_amount_idr,

                    return_cargo_amount_idr: return_cargo_amount_idr,
                    return_cargo_amount_usd: return_cargo_amount_usd,
                    // Saless Financials Data
                    csc_amount_idr: csc_amount_idr,
                    csc_amount_usd: csc_amount_usd,

                    // Mother vessel purchase financials
                    fc_amount_idr: fc_amount_idr,
                    fc_amount_usd: fc_amount_usd,

                    mv_export_tax_amount_idr: mv_export_tax_amount_idr,
                    mv_export_tax_amount_usd: mv_export_tax_amount_usd,
                    sc_amount_idr: sc_amount_idr,

                    sc_amount_usd: sc_amount_usd,

                    mv_freight_amount_idr: mv_freight_amount_idr,
                    mv_freight_amount_usd: mv_freight_amount_usd,

                    mv_cpc_amount_idr: mv_cpc_amount_idr,
                    mv_cpc_amount_usd: mv_cpc_amount_usd,

                    mv_sac_amount_idr: mv_sac_amount_idr,
                    mv_sac_amount_usd: mv_sac_amount_usd,

                    mv_pre_amount_idr: mv_pre_amount_idr,
                    mv_pre_amount_usd: mv_pre_amount_usd,

                    mv_doc_amount_idr: mv_doc_amount_idr,
                    mv_doc_amount_usd: mv_doc_amount_usd,

                    // sales
                    mv_csc_amount_idr: mv_csc_amount_idr,
                    mv_csc_amount_usd: mv_csc_amount_usd,

                    // Others
                    other_expense_amount_idr: other_expense_amount_idr,
                    other_expense_amount_usd: other_expense_amount_usd,
                    isLoading: false,
                  },
                  () => this.get_sales_total_calculations()
                );
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });

    document.title = config.documentTitle + "Profit And Loss Summary";
  }

  toLocaleString(value, name) {
    const quantity = value ? value.toString() : "0";
    const data =
      quantity && name
        ? Number(quantity ? quantity : 0).toFixed(3)
        : Number(quantity ? quantity : 0).toFixed(2);
    if (data) {
      return Number(data).toLocaleString(undefined, {
        minimumFractionDigits: name ? 3 : 2,
      });
    } else {
      return null;
    }
  }

  convertExcelHandler = (data, filename) => {
    exportToExcel(data, filename);
  };

  sales_Xchg_rate_handler = () => {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    try {
      // var business_no_id = decodeURIComponent(window.atob(this.props.match.params.businessNo));
      var business_no_id = this.props.businessNoID;
    } catch (e) {
      window.location.href = "/business-number-list";
    }
    let sales_exchange_rate_data = [];
    var row = this.state.business_no_summary;
    for (var i of row.sales_coal_costing_data) {
      sales_exchange_rate_data.push({
        costing_id: i.id,
        costing_type: "sales_coal_costing",
        exchange_rate: i.exchange_rate,
      });
    }
    const mv_supplier_demurrage = row.mv_supplier_demurrage.filter(
      (i) => i.demurrage_or_despatch === "Demurrage"
    );
    for (var mv_sup of mv_supplier_demurrage) {
      sales_exchange_rate_data.push({
        costing_id: mv_sup.id,
        costing_type: "mother_vessel_supplier_demurrage",
        exchange_rate: mv_sup.exchange_rate,
      });
    }
    const mv_buyer_demurrage = row.mv_buyer_demurrage.filter(
      (i) => i.demurrage_or_despatch === "Despatch"
    );
    for (var mv_buy of mv_buyer_demurrage) {
      sales_exchange_rate_data.push({
        costing_id: mv_buy.id,
        costing_type: "mother_vessel_buyer_despatch",
        exchange_rate: mv_buy.exchange_rate,
      });
    }
    const outgoing_debit_note = row.business_no_debit_note_data.filter(
      (e) => e.debit_type === "Outgoing"
    );
    for (var dn of outgoing_debit_note) {
      sales_exchange_rate_data.push({
        costing_id: dn.id,
        costing_type: "outgoing_debit_note",
        exchange_rate: dn.exchange_rate,
      });
    }
    const incoming_credit_note = row.business_no_credit_note_data.filter(
      (e) => e.credit_type === "Incoming"
    );
    for (var cn of incoming_credit_note) {
      sales_exchange_rate_data.push({
        costing_id: cn.id,
        costing_type: "incoming_credit_note",
        exchange_rate: cn.exchange_rate,
      });
    }

    for (var obj of row.barge_demurrage_from_supplier_data) {
      sales_exchange_rate_data.push({
        costing_id: obj.id,
        costing_type: "supplier_to_company_demurrage",
        exchange_rate: obj.supplier_exchange_rate,
      });
    }
    for (var j of row.barge_demurrage_from_buyer_data) {
      sales_exchange_rate_data.push({
        costing_id: j.id,
        costing_type: "buyer_to_company_demurrage",
        exchange_rate: j.buyer_exchange_rate,
      });
    }
    api
      .add_profit_loss_sales_exchange_rate(
        loginUserID,
        idToken,
        business_no_id,
        sales_exchange_rate_data
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState(
                {
                  snackBarSuccessOpen: true,
                  successMsg: res.message,
                },
                () => {
                  this.componentDidMount();
                }
              );
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  };

  get_sales_total_calculations = () => {
    var row = this.state.business_no_summary;
    // Sales coal Costing
    var sales_coal_cost_amount_idr = 0;
    var sales_coal_cost_amount_usd = 0;
    for (var scs of row.sales_coal_costing_data) {
      sales_coal_cost_amount_idr += scs.adjusted_coal_price_idr
        ? Number(scs.adjusted_coal_price_idr)
        : 0;
      sales_coal_cost_amount_usd += scs.adjusted_coal_price_usd
        ? Number(scs.adjusted_coal_price_usd)
        : 0;
    }
    const filter_sup_dem = row.mv_supplier_demurrage.filter(
      (e) => e.demurrage_or_despatch === "Demurrage"
    );
    var mv_sup_dem_sales_amount_idr = 0;
    var mv_sup_dem_sales_amount_usd = 0;
    for (var dem_sales of filter_sup_dem) {
      mv_sup_dem_sales_amount_idr += dem_sales.supplier_ctc_idr
        ? Number(dem_sales.supplier_ctc_idr)
        : 0;
      mv_sup_dem_sales_amount_usd += dem_sales.supplier_ctc_usd
        ? Number(dem_sales.supplier_ctc_usd)
        : 0;
    }

    const filter_buy_dem_sales = row.mv_buyer_demurrage.filter(
      (e) => e.demurrage_or_despatch === "Despatch"
    );
    var mv_buy_dem_sales_amount_idr = 0;
    var mv_buy_dem_sales_amount_usd = 0;
    for (var buy_sales of filter_buy_dem_sales) {
      mv_buy_dem_sales_amount_idr += buy_sales.buyer_ctc_idr
        ? Number(buy_sales.buyer_ctc_idr)
        : 0;
      mv_buy_dem_sales_amount_usd += buy_sales.buyer_ctc_usd
        ? Number(buy_sales.buyer_ctc_usd)
        : 0;
    }
    // Debit and Credit Notes
    var out_debit_amount_idr = 0;
    var out_debit_amount_usd = 0;
    for (var i of row.business_no_debit_note_data) {
      if (i.debit_type === "Outgoing") {
        out_debit_amount_idr += i.amount_idr ? Number(i.amount_idr) : 0;
        out_debit_amount_usd += i.amount_usd ? Number(i.amount_usd) : 0;
      }
    }
    var inc_credit_amount_idr = 0;
    var inc_credit_amount_usd = 0;
    for (var c of row.business_no_credit_note_data) {
      if (c.credit_type === "Incoming") {
        inc_credit_amount_idr += c.amount_idr ? Number(c.amount_idr) : 0;
        inc_credit_amount_usd += c.amount_usd ? Number(c.amount_usd) : 0;
      }
    }
    // barge Demurrages

    var dmr_from_supplier_idr = 0;
    var dmr_from_supplier_usd = 0;
    var dmr_from_buyer_idr = 0;
    var dmr_from_buyer_usd = 0;

    for (var dmr_from_supplier of row.barge_demurrage_from_supplier_data) {
      dmr_from_supplier_idr += dmr_from_supplier.dmr_from_supplier_ctc_idr
        ? Number(dmr_from_supplier.dmr_from_supplier_ctc_idr)
        : 0;
      dmr_from_supplier_usd += dmr_from_supplier.dmr_from_supplier_ctc_usd
        ? Number(dmr_from_supplier.dmr_from_supplier_ctc_usd)
        : 0;
    }

    for (var dmr_from_buyer of row.barge_demurrage_from_buyer_data) {
      dmr_from_buyer_idr += dmr_from_buyer.dmr_from_buyer_ctc_idr
        ? Number(dmr_from_buyer.dmr_from_buyer_ctc_idr)
        : 0;
      dmr_from_buyer_usd += dmr_from_buyer.dmr_from_buyer_ctc_usd
        ? Number(dmr_from_buyer.dmr_from_buyer_ctc_usd)
        : 0;
    }

    var other_income_amount_idr = 0;
    var other_income_amount_usd = 0;
    for (var inc of row.business_no_other_income_data) {
      other_income_amount_idr += inc.amount_idr ? Number(inc.amount_idr) : 0;
      other_income_amount_usd += inc.amount_usd ? Number(inc.amount_usd) : 0;
    }

    var sales_grand_total_idr = Number(
      sales_coal_cost_amount_idr +
        mv_sup_dem_sales_amount_idr +
        mv_buy_dem_sales_amount_idr +
        out_debit_amount_idr +
        inc_credit_amount_idr +
        dmr_from_supplier_idr +
        dmr_from_buyer_idr +
        other_income_amount_idr
    );

    var sales_grand_total_usd = Number(
      sales_coal_cost_amount_usd +
        mv_sup_dem_sales_amount_usd +
        mv_buy_dem_sales_amount_usd +
        out_debit_amount_usd +
        inc_credit_amount_usd +
        dmr_from_supplier_usd +
        dmr_from_buyer_usd +
        other_income_amount_usd
    );

    const total_profit_or_loss_amount =
      Number(sales_grand_total_usd) -
      Number(this.state.purchase_grand_total_usd);
    const total_profit_or_loss_amount_idr =
      Number(sales_grand_total_idr) -
      Number(this.state.purchase_grand_total_idr);
    this.setState({
      sales_coal_cost_amount_idr: sales_coal_cost_amount_idr,
      sales_coal_cost_amount_usd: sales_coal_cost_amount_usd,

      mv_sup_dem_sales_amount_idr: mv_sup_dem_sales_amount_idr,
      mv_sup_dem_sales_amount_usd: mv_sup_dem_sales_amount_usd,

      mv_buy_dem_sales_amount_idr: mv_buy_dem_sales_amount_idr,
      mv_buy_dem_sales_amount_usd: mv_buy_dem_sales_amount_usd,

      out_debit_amount_idr: out_debit_amount_idr,
      out_debit_amount_usd: out_debit_amount_usd,

      inc_credit_amount_idr: inc_credit_amount_idr,
      inc_credit_amount_usd: inc_credit_amount_usd,

      dmr_from_supplier_idr: dmr_from_supplier_idr,
      dmr_from_supplier_usd: dmr_from_supplier_usd,
      dmr_from_buyer_idr: dmr_from_buyer_idr,
      dmr_from_buyer_usd: dmr_from_buyer_usd,
      sales_grand_total_idr: sales_grand_total_idr,
      sales_grand_total_usd: sales_grand_total_usd,

      other_income_amount_idr: other_income_amount_idr,
      other_income_amount_usd: other_income_amount_usd,

      total_profit_or_loss_amount: total_profit_or_loss_amount,
      total_profit_or_loss_amount_idr: total_profit_or_loss_amount_idr,
    });
  };

  render() {
    var summary = this.state.business_no_summary;
    try {
      // var business_no_id = decodeURIComponent(window.atob(this.props.match.params.businessNo));
      var business_no_id = this.props.businessNoID;
    } catch (e) {
      window.location.href = "/business-number-list";
    }
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div className="container p-0">
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={1000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <div className="card">
            <div className="row">
              <div className="col-lg m-auto pl-3 pr-3">
                <Button
                  className="tab_button"
                  onClick={(e, value) => {
                    this.setState({
                      activeTabIndex: 0,
                    });
                  }}
                  style={{
                    textTransform: "capitalize",
                    margin: "5px 0px",
                    padding: "5px 20px",
                    borderRadius: 35,
                    height: 30,
                    color:
                      this.state.activeTabIndex === 0
                        ? config.themeColor
                        : "#483b34b3",
                    backgroundColor:
                      this.state.activeTabIndex === 0 ? "#fff7f2" : "#fff",
                    fontSize: "14px",
                    border: "0px",
                  }}
                >
                  Purchase
                </Button>
                <Button
                  className="tab_button"
                  onClick={(e, value) => {
                    this.setState({
                      activeTabIndex: 1,
                    });
                  }}
                  style={{
                    textTransform: "capitalize",
                    margin: "5px 0px",
                    padding: "5px 20px",
                    borderRadius: 35,
                    height: 30,
                    color:
                      this.state.activeTabIndex === 1
                        ? config.themeColor
                        : "#483b34b3",
                    backgroundColor:
                      this.state.activeTabIndex === 1 ? "#fff7f2" : "#fff",
                    fontSize: "14px",
                    border: "0px",
                  }}
                >
                  Sales
                </Button>

                <Button
                  className="tab_button"
                  onClick={(e, value) => {
                    this.setState({
                      activeTabIndex: 2,
                    });
                  }}
                  style={{
                    textTransform: "capitalize",
                    margin: "5px 0px",
                    padding: "5px 20px",
                    borderRadius: 35,
                    height: 30,
                    color:
                      this.state.activeTabIndex === 2
                        ? config.themeColor
                        : "#483b34b3",
                    backgroundColor:
                      this.state.activeTabIndex === 2 ? "#fff7f2" : "#fff",
                    fontSize: "14px",
                    border: "0px",
                  }}
                >
                  Purchase And Sales summary
                </Button>

                <Button
                  className="tab_button"
                  onClick={(e, value) => {
                    this.setState({
                      activeTabIndex: 3,
                    });
                  }}
                  style={{
                    textTransform: "capitalize",
                    margin: "5px 0px",
                    padding: "5px 20px",
                    borderRadius: 35,
                    height: 30,
                    color:
                      this.state.activeTabIndex === 3
                        ? config.themeColor
                        : "#483b34b3",
                    backgroundColor:
                      this.state.activeTabIndex === 3 ? "#fff7f2" : "#fff",
                    fontSize: "14px",
                    border: "0px",
                  }}
                >
                  Average Exchange Rate
                </Button>
              </div>
            </div>

            {this.state.activeTabIndex === 0 && (
              <div className="row mt-2">
                <div className="col-lg m-auto ">
                  {/* <TableContainer> */}
                  <Table
                    size="small"
                    component={Paper}
                    stickyHeader
                    aria-label="sticky table"
                  >
                    <TableHead className="bg-light">
                      <TableRow style={{ position: "sticky", top: 0 }}>
                        <TableCell style={{ position: "sticky", top: 0 }}>
                          DESCRIPTION
                        </TableCell>
                        <TableCell style={{ position: "sticky", top: 0 }}>
                          GRADE
                        </TableCell>
                        <TableCell
                          style={{ position: "sticky", top: 0 }}
                          align="right"
                        >
                          QTY (MT)
                        </TableCell>
                        {(config.company === "SDAM" ||
                          config.company === "SRK" ||
                          config.company === "BTR") && (
                          <TableCell
                            style={{ position: "sticky", top: 0 }}
                            align="right"
                          >
                            RATE (IDR)
                          </TableCell>
                        )}
                        <TableCell
                          style={{ position: "sticky", top: 0 }}
                          align="right"
                        >
                          RATE ($)
                        </TableCell>
                        {(config.company === "SDAM" ||
                          config.company === "SRK" ||
                          config.company === "BTR") && (
                          <TableCell
                            style={{ position: "sticky", top: 0 }}
                            align="right"
                          >
                            AMOUNT (IDR)
                          </TableCell>
                        )}
                        <TableCell
                          style={{ position: "sticky", top: 0 }}
                          align="right"
                        >
                          AMOUNT ($)
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    {summary.coal_costing_data.length > 0 && (
                      <TableBody>
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 7
                                : 5
                            }
                            style={{ color: config.themeColor }}
                          >
                            {" "}
                            Coal Purchase{" "}
                          </TableCell>
                        </TableRow>
                        {summary.coal_costing_data.map((e, idx) => (
                          <TableRow key={idx}>
                            <TableCell>{e.supplier_name}</TableCell>
                            <TableCell>{e.quality}</TableCell>
                            <TableCell className="text-right">
                              {this.toLocaleString(
                                e.coal_costing_barge_quantity,
                                "quantity"
                              )}
                            </TableCell>
                            {(config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") && (
                              <TableCell className="text-right">
                                {this.toLocaleString(
                                  e.adjusted_coal_price_pmt_idr
                                )}
                              </TableCell>
                            )}
                            <TableCell className="text-right">
                              {this.toLocaleString(
                                e.adjusted_coal_price_pmt_usd
                              )}
                            </TableCell>
                            {(config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") && (
                              <TableCell className="text-right">
                                {this.toLocaleString(e.adjusted_coal_price_idr)}
                              </TableCell>
                            )}
                            <TableCell className="text-right">
                              {this.toLocaleString(e.adjusted_coal_price_usd)}
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 5
                                : 4
                            }
                          ></TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell
                              style={{ textAlign: "right", fontWeight: "bold" }}
                            >
                              {this.toLocaleString(
                                this.state.coal_cost_amount_idr
                              )}
                            </TableCell>
                          )}
                          <TableCell
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.toLocaleString(
                              this.state.coal_cost_amount_usd
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}

                    {summary.barge_costing_data.length > 0 && (
                      <TableBody>
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 7
                                : 5
                            }
                            style={{ color: config.themeColor }}
                          >
                            Barging
                          </TableCell>
                        </TableRow>
                        {summary.barge_costing_data.map((e, idx) => (
                          <TableRow key={idx}>
                            <TableCell>{e.barge_vendor_name}</TableCell>
                            <TableCell>{e.quality}</TableCell>
                            <TableCell className="text-right">
                              {this.toLocaleString(
                                e.barging_quantity,
                                "quantity"
                              )}
                            </TableCell>
                            {(config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") && (
                              <TableCell className="text-right">
                                {this.toLocaleString(e.barging_base_price_idr)}
                              </TableCell>
                            )}
                            <TableCell className="text-right">
                              {this.toLocaleString(e.barging_base_price_usd)}
                            </TableCell>
                            {(config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") && (
                              <TableCell className="text-right">
                                {this.toLocaleString(e.barge_ctc_idr)}
                              </TableCell>
                            )}
                            <TableCell className="text-right">
                              {this.toLocaleString(e.barge_ctc_usd)}
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 5
                                : 4
                            }
                          ></TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell
                              style={{ textAlign: "right", fontWeight: "bold" }}
                            >
                              {this.toLocaleString(
                                this.state.barge_cost_amount_idr
                              )}
                            </TableCell>
                          )}
                          <TableCell
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.toLocaleString(
                              this.state.barge_cost_amount_usd
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}

                    {summary.chemical_spray_data.length > 0 && (
                      <TableBody>
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 7
                                : 5
                            }
                            style={{ color: config.themeColor }}
                          >
                            Chemical
                          </TableCell>
                        </TableRow>
                        {summary.chemical_spray_data.map((e, idx) => (
                          <TableRow key={idx}>
                            <TableCell>Chemical</TableCell>
                            <TableCell>{e.quality}</TableCell>
                            <TableCell className="text-right">-</TableCell>
                            {(config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") && (
                              <TableCell className="text-right">-</TableCell>
                            )}
                            <TableCell className="text-right">-</TableCell>
                            {(config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") && (
                              <TableCell className="text-right">
                                {this.toLocaleString(
                                  e.chemical_spray_price_idr
                                )}
                              </TableCell>
                            )}
                            <TableCell className="text-right">
                              {this.toLocaleString(e.chemical_spray_price_usd)}
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 5
                                : 4
                            }
                          ></TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell
                              style={{ textAlign: "right", fontWeight: "bold" }}
                            >
                              {this.toLocaleString(
                                this.state.chemical_cost_amount_idr
                              )}
                            </TableCell>
                          )}
                          <TableCell
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.toLocaleString(
                              this.state.chemical_cost_amount_usd
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}

                    {summary.export_tax_data.length > 0 && (
                      <TableBody>
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 7
                                : 5
                            }
                            style={{ color: config.themeColor }}
                          >
                            Barge Export Tax
                          </TableCell>
                        </TableRow>
                        {summary.export_tax_data.map((e, idx) => (
                          <TableRow key={idx}>
                            <TableCell>Export Tax</TableCell>
                            <TableCell>{e.quality}</TableCell>
                            <TableCell className="text-right">
                              {this.toLocaleString(
                                e.quantity_for_export_tax,
                                "quantity"
                              )}
                            </TableCell>
                            {(config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") && (
                              <TableCell className="text-right">
                                {this.toLocaleString(e.export_tax_per_mt_idr)}
                              </TableCell>
                            )}
                            <TableCell className="text-right">
                              {this.toLocaleString(e.export_tax_per_mt_usd)}
                            </TableCell>
                            {(config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") && (
                              <TableCell className="text-right">
                                {this.toLocaleString(e.export_tax_idr)}
                              </TableCell>
                            )}
                            <TableCell className="text-right">
                              {this.toLocaleString(e.export_tax_usd)}
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 5
                                : 4
                            }
                          ></TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell
                              style={{ textAlign: "right", fontWeight: "bold" }}
                            >
                              {this.toLocaleString(
                                this.state.export_tax_amount_idr
                              )}
                            </TableCell>
                          )}
                          <TableCell
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.toLocaleString(
                              this.state.export_tax_amount_usd
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}

                    {summary.doc_charges_data.length > 0 && (
                      <TableBody>
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 7
                                : 5
                            }
                            style={{ color: config.themeColor }}
                          >
                            Barge Documentation Charges
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}

                    {summary.doc_charges_data.map((e, idx) => (
                      <TableBody key={idx}>
                        <TableRow>
                          <TableCell>Documentation Charges</TableCell>
                          <TableCell>{e.quality}</TableCell>
                          <TableCell className="text-right">
                            {this.toLocaleString(
                              e.dc_quantity_in_mt,
                              "quantity"
                            )}
                          </TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell className="text-right">
                              {this.toLocaleString(e.dc_price_per_mt_idr)}
                            </TableCell>
                          )}
                          <TableCell className="text-right">
                            {this.toLocaleString(e.dc_price_per_mt_usd)}
                          </TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell className="text-right">
                              {this.toLocaleString(e.dc_ctc_idr)}
                            </TableCell>
                          )}
                          <TableCell className="text-right">
                            {this.toLocaleString(e.dc_ctc_usd)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))}
                    {summary.doc_charges_data.length > 0 && (
                      <TableBody>
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 5
                                : 4
                            }
                          ></TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell
                              style={{ textAlign: "right", fontWeight: "bold" }}
                            >
                              {this.toLocaleString(this.state.doc_amount_idr)}
                            </TableCell>
                          )}
                          <TableCell
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.toLocaleString(this.state.doc_amount_usd)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}

                    {summary.laparon_charges_data.length > 0 && (
                      <TableBody>
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 7
                                : 5
                            }
                            style={{ color: config.themeColor }}
                          >
                            Barge Laporan Survey Charges
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}

                    {summary.laparon_charges_data?.map((e, idx) => (
                      <TableBody key={idx}>
                        <TableRow>
                          <TableCell>Laporan Charges</TableCell>
                          <TableCell>{e.quality}</TableCell>
                          <TableCell className="text-right">
                            {this.toLocaleString(
                              e.dc_laprn_quantity_in_mt,
                              "quantity"
                            )}
                          </TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell className="text-right">
                              {this.toLocaleString(
                                e.dc_laprn_surv_price_per_mt_idr
                              )}
                            </TableCell>
                          )}
                          <TableCell className="text-right">
                            {this.toLocaleString(
                              e.dc_laprn_surv_price_per_mt_usd
                            )}
                          </TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell className="text-right">
                              {this.toLocaleString(e.dc_laprn_surv_ctc_idr)}
                            </TableCell>
                          )}
                          <TableCell className="text-right">
                            {this.toLocaleString(e.dc_laprn_surv_ctc_usd)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))}
                    {summary.laparon_charges_data?.length > 0 && (
                      <TableBody>
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 5
                                : 4
                            }
                          ></TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell
                              style={{ textAlign: "right", fontWeight: "bold" }}
                            >
                              {this.toLocaleString(this.state.laprn_amount_idr)}
                            </TableCell>
                          )}
                          <TableCell
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.toLocaleString(this.state.laprn_amount_usd)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}

                    {summary.sac_charges_data?.length > 0 && (
                      <TableBody>
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 7
                                : 5
                            }
                            style={{ color: config.themeColor }}
                          >
                            Barge Sampling & Anlaysis
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                    {summary.sac_charges_data.map((e, idx) => (
                      <TableBody key={idx}>
                        <TableRow>
                          <TableCell>Sampling & Anlaysis</TableCell>
                          <TableCell>{e.quality}</TableCell>
                          <TableCell className="text-right">
                            {this.toLocaleString(
                              e.sac_quantity_in_mt,
                              "quantity"
                            )}
                          </TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell className="text-right">
                              {this.toLocaleString(e.sac_price_per_mt_idr)}
                            </TableCell>
                          )}
                          <TableCell className="text-right">
                            {this.toLocaleString(e.sac_price_per_mt_usd)}
                          </TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell className="text-right">
                              {this.toLocaleString(e.sac_surveyor_ctc_idr)}
                            </TableCell>
                          )}
                          <TableCell className="text-right">
                            {this.toLocaleString(e.sac_surveyor_ctc_usd)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Other Charges</TableCell>
                          <TableCell>{e.quality}</TableCell>
                          <TableCell className="text-right">
                            {this.toLocaleString(
                              e.sac_quantity_in_mt,
                              "quantity"
                            )}
                          </TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell className="text-right">
                              {this.toLocaleString(e.sac_price_per_mt_idr)}
                            </TableCell>
                          )}
                          <TableCell className="text-right">
                            {this.toLocaleString(e.sac_price_per_mt_usd)}
                          </TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell className="text-right">
                              {this.toLocaleString(e.sac_other_ctc_idr)}
                            </TableCell>
                          )}
                          <TableCell className="text-right">
                            {this.toLocaleString(e.sac_other_ctc_usd)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Additional Charges</TableCell>
                          <TableCell>{e.quality}</TableCell>
                          <TableCell className="text-right">
                            {this.toLocaleString(
                              e.sac_quantity_in_mt,
                              "quantity"
                            )}
                          </TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell className="text-right">
                              {this.toLocaleString(
                                e.sac_surveyor_price_per_addl_day_idr
                              )}
                            </TableCell>
                          )}
                          <TableCell className="text-right">
                            {this.toLocaleString(
                              e.sac_surveyor_price_per_addl_day_usd
                            )}
                          </TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell className="text-right">
                              {this.toLocaleString(
                                e.sac_surveyor_addl_days_ctc_idr
                              )}
                            </TableCell>
                          )}
                          <TableCell className="text-right">
                            {this.toLocaleString(
                              e.sac_surveyor_addl_days_ctc_usd
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))}
                    {summary.sac_charges_data.length > 0 && (
                      <TableBody>
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 5
                                : 4
                            }
                          ></TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell
                              style={{ textAlign: "right", fontWeight: "bold" }}
                            >
                              {this.toLocaleString(this.state.sac_amount_idr)}
                            </TableCell>
                          )}
                          <TableCell
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.toLocaleString(this.state.sac_amount_usd)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}

                    {summary.pre_shipment_data.length > 0 && (
                      <TableBody>
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 7
                                : 5
                            }
                            style={{ color: config.themeColor }}
                          >
                            Barge Pre Shipment
                          </TableCell>
                        </TableRow>
                        {summary.pre_shipment_data.map((e, idx) => (
                          <TableRow key={idx}>
                            <TableCell>Pre Shipment</TableCell>
                            <TableCell>{e.quality}</TableCell>
                            <TableCell className="text-right">
                              {this.toLocaleString(
                                e.pre_shipment_quantity_in_mt,
                                "quantity"
                              )}
                            </TableCell>
                            {(config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") && (
                              <TableCell className="text-right">
                                {this.toLocaleString(
                                  e.pre_shipment_price_per_mt_idr
                                )}
                              </TableCell>
                            )}
                            <TableCell className="text-right">
                              {this.toLocaleString(
                                e.pre_shipment_price_per_mt_usd
                              )}
                            </TableCell>
                            {(config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") && (
                              <TableCell className="text-right">
                                {this.toLocaleString(e.pre_shipment_ctc_idr)}
                              </TableCell>
                            )}
                            <TableCell className="text-right">
                              {this.toLocaleString(e.pre_shipment_ctc_usd)}
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 5
                                : 4
                            }
                          ></TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell
                              style={{ textAlign: "right", fontWeight: "bold" }}
                            >
                              {this.toLocaleString(
                                this.state.pre_shipment_amount_idr
                              )}
                            </TableCell>
                          )}
                          <TableCell
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.toLocaleString(
                              this.state.pre_shipment_amount_usd
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}

                    {summary.cpc_costing_data.length > 0 && (
                      <TableBody>
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 7
                                : 5
                            }
                            style={{ color: config.themeColor }}
                          >
                            Barge Coal Procurement Charges
                          </TableCell>
                        </TableRow>
                        {summary.cpc_costing_data.map((e, idx) => (
                          <TableRow key={idx}>
                            <TableCell>Coal Procurement Charges</TableCell>
                            <TableCell>{e.quality}</TableCell>
                            <TableCell className="text-right">
                              {this.toLocaleString(
                                e.barge_cpc_quantity,
                                "quantity"
                              )}
                            </TableCell>
                            {(config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") && (
                              <TableCell className="text-right">
                                {this.toLocaleString(e.base_price_pmt_idr)}
                              </TableCell>
                            )}
                            <TableCell className="text-right">
                              {this.toLocaleString(e.base_price_pmt_usd)}
                            </TableCell>
                            {(config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") && (
                              <TableCell className="text-right">
                                {this.toLocaleString(e.cost_to_company_idr)}
                              </TableCell>
                            )}
                            <TableCell className="text-right">
                              {this.toLocaleString(e.cost_to_company_usd)}
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 5
                                : 4
                            }
                          ></TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell
                              style={{ textAlign: "right", fontWeight: "bold" }}
                            >
                              {this.toLocaleString(this.state.cpc_amount_idr)}
                            </TableCell>
                          )}
                          <TableCell
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.toLocaleString(this.state.cpc_amount_usd)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                    {summary.cps_costing_data.length > 0 && (
                      <TableBody>
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 7
                                : 5
                            }
                            style={{ color: config.themeColor }}
                          >
                            Barge Coal Procurement Service
                          </TableCell>
                        </TableRow>
                        {summary.cps_costing_data.map((e, idx) => (
                          <TableRow key={idx}>
                            <TableCell>Coal Procurement Service</TableCell>
                            <TableCell>{e.quality}</TableCell>
                            <TableCell className="text-right">
                              {this.toLocaleString(
                                e.barge_quantity,
                                "quantity"
                              )}
                            </TableCell>
                            {(config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") && (
                              <TableCell className="text-right">
                                {this.toLocaleString(e.base_price_pmt_idr)}
                              </TableCell>
                            )}
                            <TableCell className="text-right">
                              {this.toLocaleString(e.base_price_pmt_usd)}
                            </TableCell>
                            {(config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") && (
                              <TableCell className="text-right">
                                {this.toLocaleString(e.cost_to_company_idr)}
                              </TableCell>
                            )}
                            <TableCell className="text-right">
                              {this.toLocaleString(e.cost_to_company_usd)}
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 5
                                : 4
                            }
                          ></TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell
                              style={{ textAlign: "right", fontWeight: "bold" }}
                            >
                              {this.toLocaleString(this.state.cps_amount_idr)}
                            </TableCell>
                          )}
                          <TableCell
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.toLocaleString(this.state.cps_amount_usd)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}

                    {summary.return_cargo_charges_data.length > 0 && (
                      <TableBody>
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 7
                                : 5
                            }
                            style={{ color: config.themeColor }}
                          >
                            Return Cargo Charges
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                    {summary.return_cargo_charges_data.map((e, idx) => (
                      <TableBody key={idx}>
                        <TableRow>
                          <TableCell>
                            Documentation Charges ( {e.vendor_name} )
                          </TableCell>
                          <TableCell>{e.quality}</TableCell>
                          <TableCell className="text-right">
                            {this.toLocaleString(
                              e.dc_quantity_in_mt,
                              "quantity"
                            )}
                          </TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell className="text-right">
                              {this.toLocaleString(e.dc_price_per_mt_idr)}
                            </TableCell>
                          )}
                          <TableCell className="text-right">
                            {this.toLocaleString(e.dc_price_per_mt_usd)}
                          </TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell className="text-right">
                              {this.toLocaleString(e.dc_ctc_idr)}
                            </TableCell>
                          )}
                          <TableCell className="text-right">
                            {this.toLocaleString(e.dc_ctc_usd)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Export Tax</TableCell>
                          <TableCell>{e.quality}</TableCell>
                          <TableCell className="text-right">
                            {this.toLocaleString(
                              e.et_quantity_in_mt,
                              "quantity"
                            )}
                          </TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell className="text-right">
                              {this.toLocaleString(e.et_price_per_mt_idr)}
                            </TableCell>
                          )}
                          <TableCell className="text-right">
                            {this.toLocaleString(e.et_price_per_mt_usd)}
                          </TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell className="text-right">
                              {this.toLocaleString(e.et_ctc_idr)}
                            </TableCell>
                          )}
                          <TableCell className="text-right">
                            {this.toLocaleString(e.et_ctc_usd)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Laproan Charges</TableCell>
                          <TableCell>{e.quality}</TableCell>
                          <TableCell className="text-right">
                            {this.toLocaleString(
                              e.ls_quantity_in_mt,
                              "quantity"
                            )}
                          </TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell className="text-right">
                              {this.toLocaleString(e.ls_price_per_mt_idr)}
                            </TableCell>
                          )}
                          <TableCell className="text-right">
                            {this.toLocaleString(e.ls_price_per_mt_usd)}
                          </TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell className="text-right">
                              {this.toLocaleString(e.ls_ctc_idr)}
                            </TableCell>
                          )}
                          <TableCell className="text-right">
                            {this.toLocaleString(e.ls_ctc_usd)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))}
                    {summary.return_cargo_charges_data.length > 0 && (
                      <TableBody>
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 5
                                : 4
                            }
                          ></TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell
                              style={{ textAlign: "right", fontWeight: "bold" }}
                            >
                              {this.toLocaleString(
                                this.state.return_cargo_amount_idr
                              )}
                            </TableCell>
                          )}
                          <TableCell
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.toLocaleString(
                              this.state.return_cargo_amount_usd
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}

                    {summary.csc_costing_data.length > 0 && (
                      <TableBody>
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 7
                                : 5
                            }
                            style={{ color: config.themeColor }}
                          >
                            Barge Coal Sales Charges
                          </TableCell>
                        </TableRow>
                        {summary.csc_costing_data.map((e, idx) => (
                          <TableRow key={idx}>
                            <TableCell>Coal Sales Charges</TableCell>
                            <TableCell>{e.quality}</TableCell>
                            <TableCell className="text-right">
                              {this.toLocaleString(
                                e.barge_quantity,
                                "quantity"
                              )}
                            </TableCell>
                            {(config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") && (
                              <TableCell className="text-right">
                                {this.toLocaleString(e.base_price_pmt_idr)}
                              </TableCell>
                            )}
                            <TableCell className="text-right">
                              {this.toLocaleString(e.base_price_pmt_usd)}
                            </TableCell>
                            {(config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") && (
                              <TableCell className="text-right">
                                {this.toLocaleString(e.cost_to_company_idr)}
                              </TableCell>
                            )}
                            <TableCell className="text-right">
                              {this.toLocaleString(e.cost_to_company_usd)}
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 5
                                : 4
                            }
                          ></TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell
                              style={{ textAlign: "right", fontWeight: "bold" }}
                            >
                              {this.toLocaleString(this.state.csc_amount_idr)}
                            </TableCell>
                          )}
                          <TableCell
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.toLocaleString(this.state.csc_amount_usd)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                    {summary.mv_sales_fin_csc.length > 0 && (
                      <TableBody>
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 7
                                : 5
                            }
                            style={{ color: config.themeColor }}
                          >
                            {" "}
                            Mother Vessel Coal Sales Charges
                          </TableCell>
                        </TableRow>
                        {summary.mv_sales_fin_csc.map((e, idx) => (
                          <TableRow key={idx}>
                            <TableCell>
                              Mother Vessel Coal Sales Charges
                            </TableCell>
                            <TableCell>{e.quality}</TableCell>
                            <TableCell className="text-right">
                              {this.toLocaleString(
                                e.barge_quantity,
                                "quantity"
                              )}
                            </TableCell>
                            {(config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") && (
                              <TableCell className="text-right">
                                {this.toLocaleString(e.base_price_pmt_idr)}
                              </TableCell>
                            )}
                            <TableCell className="text-right">
                              {this.toLocaleString(e.base_price_pmt_usd)}
                            </TableCell>
                            {(config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") && (
                              <TableCell className="text-right">
                                {this.toLocaleString(e.cost_to_company_idr)}
                              </TableCell>
                            )}
                            <TableCell className="text-right">
                              {this.toLocaleString(e.cost_to_company_usd)}
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 5
                                : 4
                            }
                          ></TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell
                              style={{ textAlign: "right", fontWeight: "bold" }}
                            >
                              {this.toLocaleString(
                                this.state.mv_csc_amount_idr
                              )}
                            </TableCell>
                          )}
                          <TableCell
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.toLocaleString(this.state.mv_csc_amount_usd)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}

                    {summary.mv_stevedore_costing_data.length > 0 && (
                      <TableBody>
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 7
                                : 5
                            }
                            style={{ color: config.themeColor }}
                          >
                            Stevedore Costing
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}

                    {summary.mv_stevedore_costing_data.map((e, idx) => (
                      <TableBody key={idx}>
                        <TableRow>
                          <TableCell>{e.stevedore_vendor_name}</TableCell>
                          <TableCell>-</TableCell>
                          <TableCell className="text-right">
                            {this.toLocaleString(e.quantity, "quantity")}
                          </TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell className="text-right">
                              {this.toLocaleString(e.stevedore_price_pmt_idr)}
                            </TableCell>
                          )}
                          <TableCell className="text-right">
                            {this.toLocaleString(e.stevedore_price_pmt_usd)}
                          </TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell className="text-right">
                              {this.toLocaleString(
                                e.stevedore_cost_to_company_idr
                              )}
                            </TableCell>
                          )}
                          <TableCell className="text-right">
                            {this.toLocaleString(
                              e.stevedore_cost_to_company_usd
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>PNBP</TableCell>
                          <TableCell>-</TableCell>
                          <TableCell className="text-right">
                            {this.toLocaleString(e.quantity, "quantity")}
                          </TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell className="text-right">
                              {this.toLocaleString(e.pnbp_per_mt_idr)}
                            </TableCell>
                          )}
                          <TableCell className="text-right">
                            {this.toLocaleString(e.pnbp_per_mt_usd)}
                          </TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell className="text-right">
                              {this.toLocaleString(e.pnbp_idr)}
                            </TableCell>
                          )}
                          <TableCell className="text-right">
                            {this.toLocaleString(e.pnbp_usd)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>PBM</TableCell>
                          <TableCell>-</TableCell>
                          <TableCell className="text-right">
                            {this.toLocaleString(e.quantity, "quantity")}
                          </TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell className="text-right">
                              {this.toLocaleString(e.jasa_pbm_pmt_idr)}
                            </TableCell>
                          )}
                          <TableCell className="text-right">
                            {this.toLocaleString(e.jasa_pbm_pmt_usd)}
                          </TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell className="text-right">
                              {this.toLocaleString(e.jasa_pbm_value_idr)}
                            </TableCell>
                          )}
                          <TableCell className="text-right">
                            {this.toLocaleString(e.jasa_pbm_value_usd)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Additional Days</TableCell>
                          <TableCell>-</TableCell>
                          <TableCell className="text-right">
                            {this.toLocaleString(e.quantity, "quantity")}
                          </TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell className="text-right">
                              {this.toLocaleString(
                                e.stevedore_price_per_addl_day_idr
                              )}
                            </TableCell>
                          )}
                          <TableCell className="text-right">
                            {this.toLocaleString(
                              e.stevedore_price_per_addl_day_usd
                            )}
                          </TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell className="text-right">
                              {this.toLocaleString(
                                e.stevedore_addl_cost_to_company_idr
                              )}
                            </TableCell>
                          )}
                          <TableCell className="text-right">
                            {this.toLocaleString(
                              e.stevedore_addl_cost_to_company_usd
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Waiting Period</TableCell>
                          <TableCell>-</TableCell>
                          <TableCell className="text-right">
                            {this.toLocaleString(e.quantity, "quantity")}
                          </TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell className="text-right">
                              {this.toLocaleString(
                                e.wait_period_price_per_hour_idr
                              )}
                            </TableCell>
                          )}
                          <TableCell className="text-right">
                            {this.toLocaleString(
                              e.wait_period_price_per_hour_usd
                            )}
                          </TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell className="text-right">
                              {this.toLocaleString(
                                e.wait_period_total_price_idr
                              )}
                            </TableCell>
                          )}
                          <TableCell className="text-right">
                            {this.toLocaleString(e.wait_period_total_price_usd)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Others</TableCell>
                          <TableCell>-</TableCell>
                          <TableCell className="text-right">
                            {this.toLocaleString(e.quantity, "quantity")}
                          </TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell className="text-right">-</TableCell>
                          )}
                          <TableCell className="text-right">-</TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell className="text-right">
                              {this.toLocaleString(e.total_price_idr)}
                            </TableCell>
                          )}
                          <TableCell className="text-right">
                            {this.toLocaleString(e.total_price_usd)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))}

                    {summary.mv_stevedore_costing_data.length > 0 && (
                      <TableBody>
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 5
                                : 4
                            }
                          ></TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell
                              style={{ textAlign: "right", fontWeight: "bold" }}
                            >
                              {this.toLocaleString(this.state.sc_amount_idr)}
                            </TableCell>
                          )}
                          <TableCell
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.toLocaleString(this.state.sc_amount_usd)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                    {summary.mv_floating_crane_charges_data.length > 0 && (
                      <TableBody>
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 7
                                : 5
                            }
                            style={{ color: config.themeColor }}
                          >
                            Floating Crane Charges
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                    {summary.mv_floating_crane_charges_data.map((e, idx) => (
                      <TableBody key={idx}>
                        <TableRow>
                          <TableCell>{e.floating_crane_vendor_name}</TableCell>
                          <TableCell>-</TableCell>
                          <TableCell className="text-right">
                            {this.toLocaleString(e.quantity, "quantity")}
                          </TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell className="text-right">
                              {this.toLocaleString(
                                e.floating_crane_price_pmt_idr
                              )}
                            </TableCell>
                          )}
                          <TableCell className="text-right">
                            {this.toLocaleString(
                              e.floating_crane_price_pmt_usd
                            )}
                          </TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell className="text-right">
                              {this.toLocaleString(
                                e.floating_crane_cost_to_company_idr
                              )}
                            </TableCell>
                          )}
                          <TableCell className="text-right">
                            {this.toLocaleString(
                              e.floating_crane_cost_to_company_usd
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>PNBP</TableCell>
                          <TableCell>-</TableCell>
                          <TableCell className="text-right">
                            {this.toLocaleString(e.quantity, "quantity")}
                          </TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell className="text-right">
                              {this.toLocaleString(e.pnbp_per_mt_idr)}
                            </TableCell>
                          )}
                          <TableCell className="text-right">
                            {this.toLocaleString(e.pnbp_per_mt_usd)}
                          </TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell className="text-right">
                              {this.toLocaleString(e.pnbp_idr)}
                            </TableCell>
                          )}
                          <TableCell className="text-right">
                            {this.toLocaleString(e.pnbp_usd)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>PBM</TableCell>
                          <TableCell>-</TableCell>
                          <TableCell className="text-right">
                            {this.toLocaleString(e.quantity, "quantity")}
                          </TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell className="text-right">
                              {this.toLocaleString(e.jasa_pbm_pmt_idr)}
                            </TableCell>
                          )}
                          <TableCell className="text-right">
                            {this.toLocaleString(e.jasa_pbm_pmt_usd)}
                          </TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell className="text-right">
                              {this.toLocaleString(e.jasa_pbm_value_idr)}
                            </TableCell>
                          )}
                          <TableCell className="text-right">
                            {this.toLocaleString(e.jasa_pbm_value_usd)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Additional Days</TableCell>
                          <TableCell>-</TableCell>
                          <TableCell className="text-right">
                            {this.toLocaleString(e.quantity, "quantity")}
                          </TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell className="text-right">
                              {this.toLocaleString(
                                e.floating_crane_price_per_addl_day_idr
                              )}
                            </TableCell>
                          )}
                          <TableCell className="text-right">
                            {this.toLocaleString(
                              e.floating_crane_price_per_addl_day_usd
                            )}
                          </TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell className="text-right">
                              {this.toLocaleString(
                                e.floating_crane_addl_cost_to_company_idr
                              )}
                            </TableCell>
                          )}
                          <TableCell className="text-right">
                            {this.toLocaleString(
                              e.floating_crane_addl_cost_to_company_usd
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Waiting Period</TableCell>
                          <TableCell>-</TableCell>
                          <TableCell className="text-right">
                            {this.toLocaleString(e.quantity, "quantity")}
                          </TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell className="text-right">
                              {this.toLocaleString(
                                e.wait_period_price_per_hour_idr
                              )}
                            </TableCell>
                          )}
                          <TableCell className="text-right">
                            {this.toLocaleString(
                              e.wait_period_price_per_hour_usd
                            )}
                          </TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell className="text-right">
                              {this.toLocaleString(
                                e.wait_period_total_price_idr
                              )}
                            </TableCell>
                          )}
                          <TableCell className="text-right">
                            {this.toLocaleString(e.wait_period_total_price_usd)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Others</TableCell>
                          <TableCell>-</TableCell>
                          <TableCell className="text-right">
                            {this.toLocaleString(e.quantity, "quantity")}
                          </TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell className="text-right">-</TableCell>
                          )}
                          <TableCell className="text-right">-</TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell className="text-right">
                              {this.toLocaleString(e.total_price_idr)}
                            </TableCell>
                          )}
                          <TableCell className="text-right">
                            {this.toLocaleString(e.total_price_usd)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))}
                    {summary.mv_floating_crane_charges_data.length > 0 && (
                      <TableBody>
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 5
                                : 4
                            }
                          ></TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell
                              style={{ textAlign: "right", fontWeight: "bold" }}
                            >
                              {this.toLocaleString(this.state.fc_amount_idr)}
                            </TableCell>
                          )}
                          <TableCell
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.toLocaleString(this.state.fc_amount_usd)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}

                    {summary.mv_freight_costing_data.length > 0 && (
                      <TableBody>
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 7
                                : 5
                            }
                            style={{ color: config.themeColor }}
                          >
                            Freight
                          </TableCell>
                        </TableRow>
                        {summary.mv_freight_costing_data.map((e, idx) => (
                          <TableRow key={idx}>
                            <TableCell>
                              Freight - {e.vessel_owner_name}
                            </TableCell>
                            <TableCell>-</TableCell>
                            <TableCell className="text-right">
                              {this.toLocaleString(e.quantity, "quantity")}
                            </TableCell>
                            {(config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") && (
                              <TableCell className="text-right">
                                {this.toLocaleString(e.price_pmt_idr)}
                              </TableCell>
                            )}
                            <TableCell className="text-right">
                              {this.toLocaleString(e.price_pmt_usd)}
                            </TableCell>
                            {(config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") && (
                              <TableCell className="text-right">
                                {this.toLocaleString(e.amount_idr)}
                              </TableCell>
                            )}
                            <TableCell className="text-right">
                              {this.toLocaleString(e.amount_usd)}
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 5
                                : 4
                            }
                          ></TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell
                              style={{ textAlign: "right", fontWeight: "bold" }}
                            >
                              {this.toLocaleString(
                                this.state.mv_freight_amount_idr
                              )}
                            </TableCell>
                          )}
                          <TableCell
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.toLocaleString(
                              this.state.mv_freight_amount_usd
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}

                    {summary.mv_pur_fin_cpc_data.length > 0 && (
                      <TableBody>
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 7
                                : 5
                            }
                            style={{ color: config.themeColor }}
                          >
                            Mother Vessel Coal Procurement
                          </TableCell>
                        </TableRow>
                        {summary.mv_pur_fin_cpc_data.map((e, idx) => (
                          <TableRow key={idx}>
                            <TableCell>Coal Procurement</TableCell>
                            <TableCell>-</TableCell>
                            <TableCell className="text-right">
                              {this.toLocaleString(
                                e.barge_quantity,
                                "quantity"
                              )}
                            </TableCell>
                            {(config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") && (
                              <TableCell className="text-right">
                                {this.toLocaleString(e.base_price_pmt_idr)}
                              </TableCell>
                            )}
                            <TableCell className="text-right">
                              {this.toLocaleString(e.base_price_pmt_usd)}
                            </TableCell>
                            {(config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") && (
                              <TableCell className="text-right">
                                {this.toLocaleString(e.cost_to_company_idr)}
                              </TableCell>
                            )}
                            <TableCell className="text-right">
                              {this.toLocaleString(e.cost_to_company_usd)}
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 5
                                : 4
                            }
                          ></TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell
                              style={{ textAlign: "right", fontWeight: "bold" }}
                            >
                              {this.toLocaleString(
                                this.state.mv_cpc_amount_idr
                              )}
                            </TableCell>
                          )}
                          <TableCell
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.toLocaleString(this.state.mv_cpc_amount_usd)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}

                    {summary.mv_sac_charges_data.length > 0 && (
                      <TableBody>
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 7
                                : 5
                            }
                            style={{ color: config.themeColor }}
                          >
                            Mother Vessel Sampling & Analaysis
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}

                    {summary.mv_sac_charges_data.map((e, idx) => (
                      <TableBody key={idx}>
                        <TableRow>
                          <TableCell>Sampling & Analaysis</TableCell>
                          <TableCell>-</TableCell>
                          <TableCell className="text-right">
                            {this.toLocaleString(
                              e.sac_quantity_in_mt,
                              "quantity"
                            )}
                          </TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell className="text-right">
                              {this.toLocaleString(e.sac_price_per_mt_idr)}
                            </TableCell>
                          )}
                          <TableCell className="text-right">
                            {this.toLocaleString(e.sac_price_per_mt_usd)}
                          </TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell className="text-right">
                              {this.toLocaleString(e.sac_surveyor_ctc_idr)}
                            </TableCell>
                          )}
                          <TableCell className="text-right">
                            {this.toLocaleString(e.sac_surveyor_ctc_usd)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Other Charges</TableCell>
                          <TableCell>-</TableCell>
                          <TableCell className="text-right">
                            {this.toLocaleString(
                              e.sac_quantity_in_mt,
                              "quantity"
                            )}
                          </TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell className="text-right">
                              {this.toLocaleString(e.sac_price_per_mt_idr)}
                            </TableCell>
                          )}
                          <TableCell className="text-right">
                            {this.toLocaleString(e.sac_price_per_mt_usd)}
                          </TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell className="text-right">
                              {this.toLocaleString(e.sac_other_ctc_idr)}
                            </TableCell>
                          )}
                          <TableCell className="text-right">
                            {this.toLocaleString(e.sac_other_ctc_usd)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Additional Charges</TableCell>
                          <TableCell>-</TableCell>
                          <TableCell className="text-right">
                            {this.toLocaleString(
                              e.sac_surveyor_addl_days,
                              "quantity"
                            )}
                          </TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell className="text-right">
                              {this.toLocaleString(
                                e.sac_surveyor_price_per_addl_day_idr
                              )}
                            </TableCell>
                          )}
                          <TableCell className="text-right">
                            {this.toLocaleString(
                              e.sac_surveyor_price_per_addl_day_usd
                            )}
                          </TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell className="text-right">
                              {this.toLocaleString(
                                e.sac_surveyor_addl_days_ctc_idr
                              )}
                            </TableCell>
                          )}
                          <TableCell className="text-right">
                            {this.toLocaleString(
                              e.sac_surveyor_addl_days_ctc_usd
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))}
                    {summary.mv_sac_charges_data.length > 0 && (
                      <TableBody>
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 5
                                : 4
                            }
                          ></TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell
                              style={{ textAlign: "right", fontWeight: "bold" }}
                            >
                              {this.toLocaleString(
                                this.state.mv_sac_amount_idr
                              )}
                            </TableCell>
                          )}
                          <TableCell
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.toLocaleString(this.state.mv_sac_amount_usd)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}

                    {summary.mv_pre_shipment_data.length > 0 && (
                      <TableBody>
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 7
                                : 5
                            }
                            style={{ color: config.themeColor }}
                          >
                            Mother Vessel Pre Shipment
                          </TableCell>
                        </TableRow>
                        {summary.mv_pre_shipment_data.map((e, idx) => (
                          <TableRow key={idx}>
                            <TableCell>Pre Shipment</TableCell>
                            <TableCell>-</TableCell>
                            <TableCell className="text-right">
                              {this.toLocaleString(
                                e.sac_quantity_in_mt,
                                "quantity"
                              )}
                            </TableCell>
                            {(config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") && (
                              <TableCell className="text-right">
                                {this.toLocaleString(
                                  e.pre_shipment_price_per_mt_idr
                                )}
                              </TableCell>
                            )}
                            <TableCell className="text-right">
                              {this.toLocaleString(
                                e.pre_shipment_price_per_mt_usd
                              )}
                            </TableCell>
                            {(config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") && (
                              <TableCell className="text-right">
                                {this.toLocaleString(e.pre_shipment_ctc_idr)}
                              </TableCell>
                            )}
                            <TableCell className="text-right">
                              {this.toLocaleString(e.pre_shipment_ctc_usd)}
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 5
                                : 4
                            }
                          ></TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell
                              style={{ textAlign: "right", fontWeight: "bold" }}
                            >
                              {this.toLocaleString(
                                this.state.mv_pre_amount_idr
                              )}
                            </TableCell>
                          )}
                          <TableCell
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.toLocaleString(this.state.mv_pre_amount_usd)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}

                    {summary.mv_export_tax_data.length > 0 && (
                      <TableBody>
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 7
                                : 5
                            }
                            style={{ color: config.themeColor }}
                          >
                            Mother Vessel Export Tax
                          </TableCell>
                        </TableRow>
                        {summary.mv_export_tax_data.map((e, idx) => (
                          <TableRow key={idx}>
                            <TableCell>Export Tax</TableCell>
                            <TableCell>{e.quality}</TableCell>
                            <TableCell className="text-right">
                              {this.toLocaleString(
                                e.quantity_for_export_tax,
                                "quantity"
                              )}
                            </TableCell>
                            {(config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") && (
                              <TableCell className="text-right">
                                {this.toLocaleString(e.export_tax_per_mt_idr)}
                              </TableCell>
                            )}
                            <TableCell className="text-right">
                              {this.toLocaleString(e.export_tax_per_mt_usd)}
                            </TableCell>
                            {(config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") && (
                              <TableCell className="text-right">
                                {this.toLocaleString(e.export_tax_idr)}
                              </TableCell>
                            )}
                            <TableCell className="text-right">
                              {this.toLocaleString(e.export_tax_usd)}
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 5
                                : 4
                            }
                          ></TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell
                              style={{ textAlign: "right", fontWeight: "bold" }}
                            >
                              {this.toLocaleString(
                                this.state.mv_export_tax_amount_idr
                              )}
                            </TableCell>
                          )}
                          <TableCell
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.toLocaleString(
                              this.state.mv_export_tax_amount_usd
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}

                    {summary.mv_doc_charges_data.length > 0 && (
                      <TableBody>
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 7
                                : 5
                            }
                            style={{ color: config.themeColor }}
                          >
                            Mother Vessel Documentation Charges
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}

                    {summary.mv_doc_charges_data.map((e, idx) => (
                      <TableBody key={idx}>
                        <TableRow>
                          <TableCell>Documentation Charges</TableCell>
                          <TableCell>-</TableCell>
                          <TableCell className="text-right">
                            {this.toLocaleString(
                              e.dc_quantity_in_mt,
                              "quantity"
                            )}
                          </TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell className="text-right">
                              {this.toLocaleString(e.dc_price_per_mt_idr)}
                            </TableCell>
                          )}
                          <TableCell className="text-right">
                            {this.toLocaleString(e.dc_price_per_mt_usd)}
                          </TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell className="text-right">
                              {this.toLocaleString(e.dc_ctc_idr)}
                            </TableCell>
                          )}
                          <TableCell className="text-right">
                            {this.toLocaleString(e.dc_ctc_usd)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Laporan Charges</TableCell>
                          <TableCell>{e.quality}</TableCell>
                          <TableCell className="text-right">
                            {this.toLocaleString(
                              e.dc_quantity_in_mt,
                              "quantity"
                            )}
                          </TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell className="text-right">
                              {this.toLocaleString(
                                e.dc_laprn_surv_price_per_mt_idr
                              )}
                            </TableCell>
                          )}
                          <TableCell className="text-right">
                            {this.toLocaleString(
                              e.dc_laprn_surv_price_per_mt_usd
                            )}
                          </TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell className="text-right">
                              {this.toLocaleString(e.dc_laprn_surv_ctc_idr)}
                            </TableCell>
                          )}
                          <TableCell className="text-right">
                            {this.toLocaleString(e.dc_laprn_surv_ctc_usd)}
                          </TableCell>
                        </TableRow>
                        {e.other_charges.map((obj, idx) => (
                          <TableRow key={idx}>
                            <TableCell>Other Charges</TableCell>
                            <TableCell>{e.quality}</TableCell>
                            <TableCell className="text-right">
                              {this.toLocaleString(
                                e.dc_quantity_in_mt,
                                "quantity"
                              )}
                            </TableCell>
                            {(config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") && (
                              <TableCell className="text-right">
                                {this.toLocaleString(
                                  obj.doc_other_base_price_idr
                                )}
                              </TableCell>
                            )}
                            <TableCell className="text-right">
                              {this.toLocaleString(
                                obj.doc_other_base_price_usd
                              )}
                            </TableCell>
                            {(config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") && (
                              <TableCell className="text-right">
                                {this.toLocaleString(obj.doc_other_ctc_idr)}
                              </TableCell>
                            )}
                            <TableCell className="text-right">
                              {this.toLocaleString(obj.doc_other_ctc_usd)}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    ))}
                    {summary.mv_doc_charges_data.length > 0 && (
                      <TableBody>
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 5
                                : 4
                            }
                          ></TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell
                              style={{ textAlign: "right", fontWeight: "bold" }}
                            >
                              {this.toLocaleString(
                                this.state.mv_doc_amount_idr
                              )}
                            </TableCell>
                          )}
                          <TableCell
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.toLocaleString(this.state.mv_doc_amount_usd)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                    {summary.mv_supplier_demurrage.length > 0 && (
                      <TableBody>
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 7
                                : 5
                            }
                            style={{ color: config.themeColor }}
                          >
                            Mother Vessel Supplier Despatch
                          </TableCell>
                        </TableRow>
                        {summary.mv_supplier_demurrage
                          .filter((i) => i.demurrage_or_despatch === "Despatch")
                          .map((e, idx) => (
                            <TableRow key={idx}>
                              <TableCell>{e.supplier_name}</TableCell>
                              <TableCell>-</TableCell>
                              <TableCell className="text-right">-</TableCell>
                              {(config.company === "SDAM" ||
                                config.company === "SRK" ||
                                config.company === "BTR") && (
                                <TableCell className="text-right">
                                  {this.toLocaleString(e.despatch_per_day_idr)}
                                </TableCell>
                              )}
                              <TableCell className="text-right">
                                {this.toLocaleString(e.despatch_per_day_usd)}
                              </TableCell>
                              {(config.company === "SDAM" ||
                                config.company === "SRK" ||
                                config.company === "BTR") && (
                                <TableCell className="text-right">
                                  {this.toLocaleString(e.supplier_ctc_idr)}
                                </TableCell>
                              )}
                              <TableCell className="text-right">
                                {this.toLocaleString(e.supplier_ctc_usd)}
                              </TableCell>
                            </TableRow>
                          ))}
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 5
                                : 4
                            }
                          ></TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell
                              style={{ textAlign: "right", fontWeight: "bold" }}
                            >
                              {this.toLocaleString(
                                this.state.mv_sup_dem_amount_idr
                              )}
                            </TableCell>
                          )}
                          <TableCell
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.toLocaleString(
                              this.state.mv_sup_dem_amount_usd
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                    {summary.mv_buyer_demurrage.filter(
                      (i) => i.demurrage_or_despatch === "Demurrage"
                    ).length > 0 && (
                      <TableBody>
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 7
                                : 5
                            }
                            style={{ color: config.themeColor }}
                          >
                            Mother Vessel Buyer Demurrage
                          </TableCell>
                        </TableRow>
                        {summary.mv_buyer_demurrage
                          .filter(
                            (i) => i.demurrage_or_despatch === "Demurrage"
                          )
                          .map((e, idx) => (
                            <TableRow key={idx}>
                              <TableCell>{e.buyer_name}</TableCell>
                              <TableCell>-</TableCell>
                              <TableCell className="text-right">-</TableCell>
                              {(config.company === "SDAM" ||
                                config.company === "SRK" ||
                                config.company === "BTR") && (
                                <TableCell className="text-right">
                                  {this.toLocaleString(e.buyer_base_price_idr)}
                                </TableCell>
                              )}
                              <TableCell className="text-right">
                                {this.toLocaleString(e.buyer_base_price_usd)}
                              </TableCell>
                              {(config.company === "SDAM" ||
                                config.company === "SRK" ||
                                config.company === "BTR") && (
                                <TableCell className="text-right">
                                  {this.toLocaleString(e.buyer_ctc_idr)}
                                </TableCell>
                              )}
                              <TableCell className="text-right">
                                {this.toLocaleString(e.buyer_ctc_usd)}
                              </TableCell>
                            </TableRow>
                          ))}
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 5
                                : 4
                            }
                          ></TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell
                              style={{ textAlign: "right", fontWeight: "bold" }}
                            >
                              {this.toLocaleString(
                                this.state.mv_buy_dem_amount_idr
                              )}
                            </TableCell>
                          )}
                          <TableCell
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.toLocaleString(
                              this.state.mv_buy_dem_amount_usd
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                    {(summary.barge_demurrage_to_barge_vendor_data.length > 0 ||
                      summary.barge_demurrage_to_supplier_data.length > 0 ||
                      summary.barge_demurrage_to_buyer_data.length > 0) && (
                      <TableBody>
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 7
                                : 5
                            }
                            style={{ color: config.themeColor }}
                          >
                            Barge Demurrage
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                    {summary.barge_demurrage_to_barge_vendor_data.length >
                      0 && (
                      <TableBody>
                        {summary.barge_demurrage_to_barge_vendor_data.map(
                          (e, idx) => (
                            <TableRow key={idx}>
                              <TableCell>
                                Barge Vendor - {e.barge_vendor_name}
                              </TableCell>
                              <TableCell>-</TableCell>
                              <TableCell className="text-right">-</TableCell>
                              {(config.company === "SDAM" ||
                                config.company === "SRK" ||
                                config.company === "BTR") && (
                                <TableCell className="text-right">-</TableCell>
                              )}
                              <TableCell className="text-right">-</TableCell>
                              {(config.company === "SDAM" ||
                                config.company === "SRK" ||
                                config.company === "BTR") && (
                                <TableCell className="text-right">
                                  {this.toLocaleString(
                                    e.dmr_to_bg_vend_ctc_idr
                                  )}
                                </TableCell>
                              )}
                              <TableCell className="text-right">
                                {this.toLocaleString(e.dmr_to_bg_vend_ctc_usd)}
                              </TableCell>
                            </TableRow>
                          )
                        )}
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 5
                                : 4
                            }
                          ></TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell
                              style={{ textAlign: "right", fontWeight: "bold" }}
                            >
                              {this.toLocaleString(
                                this.state.dmr_to_bg_vend_idr
                              )}
                            </TableCell>
                          )}
                          <TableCell
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.toLocaleString(this.state.dmr_to_bg_vend_usd)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                    {summary.barge_demurrage_to_supplier_data.length > 0 && (
                      <TableBody>
                        {summary.barge_demurrage_to_supplier_data.map(
                          (e, idx) => (
                            <TableRow key={idx}>
                              <TableCell>
                                {config.company} to Supplier - {e.supplier_name}
                              </TableCell>
                              <TableCell>-</TableCell>
                              <TableCell className="text-right">-</TableCell>
                              {(config.company === "SDAM" ||
                                config.company === "SRK" ||
                                config.company === "BTR") && (
                                <TableCell className="text-right">-</TableCell>
                              )}
                              <TableCell className="text-right">-</TableCell>
                              {(config.company === "SDAM" ||
                                config.company === "SRK" ||
                                config.company === "BTR") && (
                                <TableCell className="text-right">
                                  {this.toLocaleString(
                                    e.dmr_to_supplier_ctc_idr
                                  )}
                                </TableCell>
                              )}
                              <TableCell className="text-right">
                                {this.toLocaleString(e.dmr_to_supplier_ctc_usd)}
                              </TableCell>
                            </TableRow>
                          )
                        )}
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 5
                                : 4
                            }
                          ></TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell
                              style={{ textAlign: "right", fontWeight: "bold" }}
                            >
                              {this.toLocaleString(
                                this.state.dmr_to_supplier_idr
                              )}
                            </TableCell>
                          )}
                          <TableCell
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.toLocaleString(
                              this.state.dmr_to_supplier_usd
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                    {summary.barge_demurrage_to_buyer_data.length > 0 && (
                      <TableBody>
                        {summary.barge_demurrage_to_buyer_data.map((e, idx) => (
                          <TableRow key={idx}>
                            <TableCell>
                              {config.company} to Buyer - {e.supplier_name}
                            </TableCell>
                            <TableCell>-</TableCell>
                            <TableCell className="text-right">-</TableCell>
                            {(config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") && (
                              <TableCell className="text-right">-</TableCell>
                            )}
                            <TableCell className="text-right">-</TableCell>
                            {(config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") && (
                              <TableCell className="text-right">
                                {this.toLocaleString(e.dmr_to_buyer_ctc_idr)}
                              </TableCell>
                            )}
                            <TableCell className="text-right">
                              {this.toLocaleString(e.dmr_to_buyer_ctc_usd)}
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 5
                                : 4
                            }
                          ></TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell
                              style={{ textAlign: "right", fontWeight: "bold" }}
                            >
                              {this.toLocaleString(this.state.dmr_to_buyer_idr)}
                            </TableCell>
                          )}
                          <TableCell
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.toLocaleString(this.state.dmr_to_buyer_usd)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}

                    {summary.business_no_other_expenses_data.length > 0 && (
                      <TableBody>
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 7
                                : 5
                            }
                            style={{ color: config.themeColor }}
                          >
                            Other Expenses
                          </TableCell>
                        </TableRow>
                        {summary.business_no_other_expenses_data.map(
                          (e, idx) => (
                            <TableRow key={idx}>
                              <TableCell style={{ textTransform: "uppercase" }}>
                                {e.vendor_name ? e.vendor_name : e.payee_name}
                              </TableCell>
                              <TableCell>-</TableCell>
                              <TableCell className="text-right">-</TableCell>
                              {(config.company === "SDAM" ||
                                config.company === "SRK" ||
                                config.company === "BTR") && (
                                <TableCell className="text-right">-</TableCell>
                              )}
                              <TableCell className="text-right">-</TableCell>
                              {(config.company === "SDAM" ||
                                config.company === "SRK" ||
                                config.company === "BTR") && (
                                <TableCell className="text-right">
                                  {this.toLocaleString(e.amount_idr)}
                                </TableCell>
                              )}
                              <TableCell className="text-right">
                                {this.toLocaleString(e.amount_usd)}
                              </TableCell>
                            </TableRow>
                          )
                        )}
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 5
                                : 4
                            }
                          ></TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell
                              style={{ textAlign: "right", fontWeight: "bold" }}
                            >
                              {this.toLocaleString(
                                this.state.other_expense_amount_idr
                              )}
                            </TableCell>
                          )}
                          <TableCell
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.toLocaleString(
                              this.state.other_expense_amount_usd
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                    {summary.business_no_debit_note_data.filter(
                      (e) => e.debit_type === "Incoming"
                    ).length > 0 && (
                      <TableBody>
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 7
                                : 5
                            }
                            style={{ color: config.themeColor }}
                          >
                            Incoming Debit Note
                          </TableCell>
                        </TableRow>
                        {summary.business_no_debit_note_data
                          .filter((e) => e.debit_type === "Incoming")
                          .map((e, idx) => (
                            <TableRow key={idx}>
                              <TableCell>
                                {e.vendor_name
                                  ? e.vendor_name
                                  : e.customer_name
                                  ? e.customer_name
                                  : e.other_customer_name}
                              </TableCell>
                              <TableCell>-</TableCell>
                              <TableCell className="text-right">-</TableCell>
                              {(config.company === "SDAM" ||
                                config.company === "SRK" ||
                                config.company === "BTR") && (
                                <TableCell className="text-right">-</TableCell>
                              )}
                              <TableCell className="text-right">-</TableCell>
                              {(config.company === "SDAM" ||
                                config.company === "SRK" ||
                                config.company === "BTR") && (
                                <TableCell className="text-right">
                                  {this.toLocaleString(e.amount_idr)}
                                </TableCell>
                              )}
                              <TableCell className="text-right">
                                {this.toLocaleString(e.amount_usd)}
                              </TableCell>
                            </TableRow>
                          ))}
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 5
                                : 4
                            }
                          ></TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell
                              style={{ textAlign: "right", fontWeight: "bold" }}
                            >
                              {this.toLocaleString(
                                this.state.inc_debit_amount_idr
                              )}
                            </TableCell>
                          )}
                          <TableCell
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.toLocaleString(
                              this.state.inc_debit_amount_usd
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}

                    {summary.business_no_credit_note_data.filter(
                      (e) => e.credit_type === "Outgoing"
                    ).length > 0 && (
                      <TableBody>
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 7
                                : 5
                            }
                            style={{ color: config.themeColor }}
                          >
                            Outgoing Credit Note
                          </TableCell>
                        </TableRow>
                        {summary.business_no_credit_note_data
                          .filter((e) => e.credit_type === "Outgoing")
                          .map((e, idx) => (
                            <TableRow key={idx}>
                              <TableCell>
                                {e.vendor_name
                                  ? e.vendor_name
                                  : e.customer_name
                                  ? e.customer_name
                                  : e.other_customer_name}
                              </TableCell>
                              <TableCell>-</TableCell>
                              <TableCell className="text-right">-</TableCell>
                              {(config.company === "SDAM" ||
                                config.company === "SRK" ||
                                config.company === "BTR") && (
                                <TableCell className="text-right">-</TableCell>
                              )}
                              <TableCell className="text-right">-</TableCell>
                              {(config.company === "SDAM" ||
                                config.company === "SRK" ||
                                config.company === "BTR") && (
                                <TableCell className="text-right">
                                  {this.toLocaleString(e.amount_idr)}
                                </TableCell>
                              )}
                              <TableCell className="text-right">
                                {this.toLocaleString(e.amount_usd)}
                              </TableCell>
                            </TableRow>
                          ))}

                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 5
                                : 4
                            }
                          ></TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell
                              style={{ textAlign: "right", fontWeight: "bold" }}
                            >
                              {this.toLocaleString(
                                this.state.out_credit_amount_idr
                              )}
                            </TableCell>
                          )}
                          <TableCell
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.toLocaleString(
                              this.state.out_credit_amount_usd
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}

                    <TableBody>
                      <TableRow>
                        <TableCell
                          colSpan={
                            config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR"
                              ? 5
                              : 4
                          }
                        ></TableCell>
                        {(config.company === "SDAM" ||
                          config.company === "SRK" ||
                          config.company === "BTR") && (
                          <TableCell
                            style={{
                              textAlign: "right",
                              fontWeight: "bold",
                              whiteSpace: "nowrap",
                            }}
                            className="text-success"
                          >
                            {this.state.purchase_grand_total_idr
                              ? this.toLocaleString(
                                  this.state.purchase_grand_total_idr
                                )
                              : 0}
                          </TableCell>
                        )}
                        <TableCell
                          style={{
                            textAlign: "right",
                            fontWeight: "bold",
                            whiteSpace: "nowrap",
                          }}
                          className="text-success"
                        >
                          {this.state.purchase_grand_total_usd
                            ? this.toLocaleString(
                                this.state.purchase_grand_total_usd
                              )
                            : 0}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  {/* </TableContainer> */}
                </div>
              </div>
            )}

            {this.state.activeTabIndex === 1 && (
              <div className="row mt-2">
                <div className="col-lg m-auto ">
                  {/* <TableContainer > */}
                  <Table
                    size="small"
                    component={Paper}
                    stickyHeader
                    aria-label="sticky table"
                  >
                    <TableHead className="bg-light">
                      <TableRow>
                        <TableCell style={{ position: "sticky", top: 0 }}>
                          DESCRIPTION
                        </TableCell>
                        <TableCell style={{ position: "sticky", top: 0 }}>
                          GRADE
                        </TableCell>
                        <TableCell
                          className="text-right"
                          style={{ position: "sticky", top: 0 }}
                        >
                          QTY (MT)
                        </TableCell>
                        {(config.company === "SDAM" ||
                          config.company === "SRK" ||
                          config.company === "BTR") && (
                          <TableCell style={{ position: "sticky", top: 0 }}>
                            Exchange Rate
                          </TableCell>
                        )}
                        {(config.company === "SDAM" ||
                          config.company === "SRK" ||
                          config.company === "BTR") && (
                          <TableCell
                            className="text-right"
                            style={{ position: "sticky", top: 0 }}
                          >
                            RATE (IDR)
                          </TableCell>
                        )}
                        <TableCell
                          className="text-right"
                          style={{ position: "sticky", top: 0 }}
                        >
                          RATE ($)
                        </TableCell>
                        {(config.company === "SDAM" ||
                          config.company === "SRK" ||
                          config.company === "BTR") && (
                          <TableCell
                            className="text-right"
                            style={{ position: "sticky", top: 0 }}
                          >
                            AMOUNT (IDR)
                          </TableCell>
                        )}
                        <TableCell
                          className="text-right"
                          style={{ position: "sticky", top: 0 }}
                        >
                          AMOUNT ($)
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {/**
                     *
                     * ========================================= COAL SALES START'S ============================
                     *
                     */}
                    {summary.sales_coal_costing_data.length > 0 && (
                      <TableBody>
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 8
                                : 6
                            }
                            style={{ color: config.themeColor }}
                          >
                            Coal Sales{" "}
                          </TableCell>
                        </TableRow>
                        {summary.sales_coal_costing_data.map((e, idx) => (
                          <TableRow key={idx}>
                            <TableCell>{e.buyer_name}</TableCell>
                            <TableCell>{e.quality}</TableCell>
                            <TableCell className="text-right">
                              {this.toLocaleString(
                                e.barge_quantity,
                                "quantity"
                              )}
                            </TableCell>
                            {(config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") && (
                              <TableCell>
                                {e.xchg_rate_editable ? (
                                  <TextField
                                    name="exchange_rate"
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth
                                    onChange={(e) => {
                                      const pr = /^\d*(\.\d{0,10})?$/;
                                      if (
                                        e.target.value === "" ||
                                        pr.test(e.target.value)
                                      ) {
                                        let row =
                                          this.state.business_no_summary;
                                        let rows = row.sales_coal_costing_data;
                                        rows[idx].exchange_rate =
                                          e.target.value;
                                        let value = e.target.value;
                                        if (value === "") {
                                          value = 1;
                                        }
                                        if (
                                          rows[idx].billing_currency === "IDR"
                                        ) {
                                          rows[
                                            idx
                                          ].adjusted_coal_price_pmt_usd = (
                                            Number(
                                              rows[idx]
                                                .adjusted_coal_price_pmt_idr
                                                ? rows[idx]
                                                    .adjusted_coal_price_pmt_idr
                                                : 0
                                            ) / Number(value)
                                          ).toFixed(2);
                                          rows[idx].adjusted_coal_price_usd = (
                                            Number(
                                              rows[idx].adjusted_coal_price_idr
                                                ? rows[idx]
                                                    .adjusted_coal_price_idr
                                                : 0
                                            ) / Number(value)
                                          ).toFixed(2);
                                        } else if (
                                          rows[idx].billing_currency === "USD"
                                        ) {
                                          rows[
                                            idx
                                          ].adjusted_coal_price_pmt_idr = (
                                            Number(
                                              rows[idx]
                                                .adjusted_coal_price_pmt_usd
                                                ? rows[idx]
                                                    .adjusted_coal_price_pmt_usd
                                                : 0
                                            ) * Number(value)
                                          ).toFixed(2);
                                          rows[idx].adjusted_coal_price_idr = (
                                            Number(
                                              rows[idx].adjusted_coal_price_usd
                                                ? rows[idx]
                                                    .adjusted_coal_price_usd
                                                : 0
                                            ) * Number(value)
                                          ).toFixed(2);
                                        }
                                        this.setState(
                                          {
                                            business_no_summary: row,
                                          },
                                          () =>
                                            this.get_sales_total_calculations()
                                        );
                                      }
                                    }}
                                    value={e.exchange_rate}
                                    placeholder="Exchange Rate"
                                    inputProps={{
                                      style: { textTransform: "uppercase" },
                                    }}
                                  />
                                ) : (
                                  <div>
                                    {e.exchange_rate}
                                    <i
                                      className="fa fa-pencil edit_icon text-right"
                                      style={{ float: "right" }}
                                      onClick={() => {
                                        let row =
                                          this.state.business_no_summary;
                                        let rows = row.sales_coal_costing_data;
                                        rows[idx].xchg_rate_editable = true;
                                        this.setState({
                                          business_no_summary: row,
                                        });
                                      }}
                                    />
                                  </div>
                                )}
                              </TableCell>
                            )}
                            {(config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") && (
                              <TableCell className="text-right">
                                {this.toLocaleString(
                                  e.adjusted_coal_price_pmt_idr
                                )}
                              </TableCell>
                            )}
                            <TableCell className="text-right">
                              {this.toLocaleString(
                                e.adjusted_coal_price_pmt_usd
                              )}
                            </TableCell>
                            {(config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") && (
                              <TableCell className="text-right">
                                {this.toLocaleString(e.adjusted_coal_price_idr)}
                              </TableCell>
                            )}
                            <TableCell className="text-right">
                              {this.toLocaleString(e.adjusted_coal_price_usd)}
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 6
                                : 4
                            }
                          ></TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell
                              style={{ textAlign: "right", fontWeight: "bold" }}
                            >
                              {this.toLocaleString(
                                this.state.sales_coal_cost_amount_idr
                              )}
                            </TableCell>
                          )}
                          <TableCell
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.toLocaleString(
                              this.state.sales_coal_cost_amount_usd
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                    {/**
                     *
                     * ============================== MOTHER VESSEL SUPPLIER DEMURRAGE (demurrage_or_despatch = Demurrage) START'S =============================
                     *
                     */}
                    {summary.mv_supplier_demurrage.filter(
                      (i) => i.demurrage_or_despatch === "Demurrage"
                    ).length > 0 && (
                      <TableBody>
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 8
                                : 5
                            }
                            style={{ color: config.themeColor }}
                          >
                            Mother Vessel Supplier Demurrage
                          </TableCell>
                        </TableRow>
                        {summary.mv_supplier_demurrage
                          .filter(
                            (i) => i.demurrage_or_despatch === "Demurrage"
                          )
                          .map((e, idx) => (
                            <TableRow key={idx}>
                              <TableCell>{e.supplier_name}</TableCell>
                              <TableCell>-</TableCell>
                              <TableCell className="text-right">-</TableCell>
                              {(config.company === "SDAM" ||
                                config.company === "SRK" ||
                                config.company === "BTR") && (
                                <TableCell>
                                  {e.xchg_rate_editable ? (
                                    <TextField
                                      name="exchange_rate"
                                      margin="dense"
                                      variant="outlined"
                                      fullWidth
                                      onChange={(e) => {
                                        const pr = /^\d*(\.\d{0,10})?$/;
                                        if (
                                          e.target.value === "" ||
                                          pr.test(e.target.value)
                                        ) {
                                          let row =
                                            this.state.business_no_summary;
                                          let rows =
                                            row.mv_supplier_demurrage.filter(
                                              (i) =>
                                                i.demurrage_or_despatch ===
                                                "Demurrage"
                                            );
                                          rows[idx].exchange_rate =
                                            e.target.value;
                                          let value = e.target.value;
                                          if (value === "") {
                                            value = 1;
                                          }
                                          if (
                                            rows[idx].billing_currency === "IDR"
                                          ) {
                                            //rows[idx].despatch_per_day_usd = (Number(rows[idx].despatch_per_day_idr ? rows[idx].despatch_per_day_idr : 0) / Number(value)).toFixed(2);
                                            rows[idx].supplier_ctc_usd = (
                                              Number(
                                                rows[idx].supplier_ctc_idr
                                                  ? rows[idx].supplier_ctc_idr
                                                  : 0
                                              ) / Number(value)
                                            ).toFixed(2);
                                          } else if (
                                            rows[idx].billing_currency === "USD"
                                          ) {
                                            // rows[idx].despatch_per_day_idr = (Number(rows[idx].despatch_per_day_usd ? rows[idx].despatch_per_day_usd : 0) * Number(value)).toFixed(2);
                                            rows[idx].supplier_ctc_usd = (
                                              Number(
                                                rows[idx].supplier_ctc_idr
                                                  ? rows[idx].supplier_ctc_idr
                                                  : 0
                                              ) / Number(value)
                                            ).toFixed(2);
                                          }
                                          this.setState(
                                            {
                                              business_no_summary: row,
                                            },
                                            () =>
                                              this.get_sales_total_calculations()
                                          );
                                        }
                                      }}
                                      value={e.exchange_rate}
                                      placeholder="Exchange Rate"
                                      inputProps={{
                                        style: { textTransform: "uppercase" },
                                      }}
                                    />
                                  ) : (
                                    <div>
                                      {e.exchange_rate}
                                      <i
                                        className="fa fa-pencil edit_icon text-right"
                                        style={{ float: "right" }}
                                        onClick={() => {
                                          let row =
                                            this.state.business_no_summary;
                                          let rows =
                                            row.mv_supplier_demurrage.filter(
                                              (i) =>
                                                i.demurrage_or_despatch ===
                                                "Demurrage"
                                            );
                                          rows[idx].xchg_rate_editable = true;
                                          this.setState({
                                            business_no_summary: row,
                                          });
                                        }}
                                      />
                                    </div>
                                  )}
                                </TableCell>
                              )}
                              {(config.company === "SDAM" ||
                                config.company === "SRK" ||
                                config.company === "BTR") && (
                                <TableCell className="text-right">
                                  {this.toLocaleString(e.despatch_per_day_idr)}
                                </TableCell>
                              )}
                              <TableCell className="text-right">
                                {this.toLocaleString(e.despatch_per_day_usd)}
                              </TableCell>
                              {(config.company === "SDAM" ||
                                config.company === "SRK" ||
                                config.company === "BTR") && (
                                <TableCell className="text-right">
                                  {this.toLocaleString(e.supplier_ctc_idr)}
                                </TableCell>
                              )}
                              <TableCell className="text-right">
                                {this.toLocaleString(e.supplier_ctc_usd)}
                              </TableCell>
                            </TableRow>
                          ))}
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 6
                                : 4
                            }
                          ></TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell
                              style={{ textAlign: "right", fontWeight: "bold" }}
                            >
                              {this.toLocaleString(
                                this.state.mv_sup_dem_sales_amount_idr
                              )}
                            </TableCell>
                          )}
                          <TableCell
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.toLocaleString(
                              this.state.mv_sup_dem_sales_amount_usd
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                    {/**
                     *
                     *====================================== MOTHER VESSEL BUYER DEMURRAGE (demurrage_or_despatch = Despatch) START ===================
                     *
                     */}
                    {summary.mv_buyer_demurrage.filter(
                      (i) => i.demurrage_or_despatch === "Despatch"
                    ).length > 0 && (
                      <TableBody>
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 8
                                : 5
                            }
                            style={{ color: config.themeColor }}
                          >
                            Mother Vessel Buyer Despatch
                          </TableCell>
                        </TableRow>
                        {summary.mv_buyer_demurrage
                          .filter((i) => i.demurrage_or_despatch === "Despatch")
                          .map((e, idx) => (
                            <TableRow key={idx}>
                              <TableCell>
                                Buyer Demurrage - {e.buyer_name}
                              </TableCell>
                              <TableCell>-</TableCell>
                              <TableCell className="text-right">-</TableCell>
                              {(config.company === "SDAM" ||
                                config.company === "SRK" ||
                                config.company === "BTR") && (
                                <TableCell>
                                  {e.xchg_rate_editable ? (
                                    <TextField
                                      name="exchange_rate"
                                      margin="dense"
                                      variant="outlined"
                                      fullWidth
                                      onChange={(e) => {
                                        const pr = /^\d*(\.\d{0,10})?$/;
                                        if (
                                          e.target.value === "" ||
                                          pr.test(e.target.value)
                                        ) {
                                          let row =
                                            this.state.business_no_summary;
                                          let rows =
                                            row.mv_buyer_demurrage.filter(
                                              (i) =>
                                                i.demurrage_or_despatch ===
                                                "Despatch"
                                            );
                                          rows[idx].exchange_rate =
                                            e.target.value;
                                          let value = e.target.value;
                                          if (value === "") {
                                            value = 1;
                                          }
                                          if (
                                            rows[idx].despatch_currency ===
                                            "IDR"
                                          ) {
                                            //rows[idx].despatch_per_day_usd = (Number(rows[idx].despatch_per_day_idr ? rows[idx].despatch_per_day_idr : 0) / Number(value)).toFixed(2);
                                            rows[idx].buyer_ctc_usd = (
                                              Number(
                                                rows[idx].buyer_ctc_idr
                                                  ? rows[idx].buyer_ctc_idr
                                                  : 0
                                              ) / Number(value)
                                            ).toFixed(2);
                                          } else if (
                                            rows[idx].despatch_currency ===
                                            "USD"
                                          ) {
                                            // rows[idx].despatch_per_day_idr = (Number(rows[idx].despatch_per_day_usd ? rows[idx].despatch_per_day_usd : 0) * Number(value)).toFixed(2);
                                            rows[idx].buyer_ctc_usd = (
                                              Number(
                                                rows[idx].buyer_ctc_idr
                                                  ? rows[idx].buyer_ctc_idr
                                                  : 0
                                              ) / Number(value)
                                            ).toFixed(2);
                                          }
                                          this.setState(
                                            {
                                              business_no_summary: row,
                                            },
                                            () =>
                                              this.get_sales_total_calculations()
                                          );
                                        }
                                      }}
                                      value={e.exchange_rate}
                                      placeholder="Exchange Rate"
                                      inputProps={{
                                        style: { textTransform: "uppercase" },
                                      }}
                                    />
                                  ) : (
                                    <div>
                                      {e.exchange_rate}
                                      <i
                                        className="fa fa-pencil edit_icon text-right"
                                        style={{ float: "right" }}
                                        onClick={() => {
                                          let row =
                                            this.state.business_no_summary;
                                          let rows =
                                            row.mv_buyer_demurrage.filter(
                                              (i) =>
                                                i.demurrage_or_despatch ===
                                                "Despatch"
                                            );
                                          rows[idx].xchg_rate_editable = true;
                                          this.setState({
                                            business_no_summary: row,
                                          });
                                        }}
                                      />
                                    </div>
                                  )}
                                </TableCell>
                              )}
                              {(config.company === "SDAM" ||
                                config.company === "SRK" ||
                                config.company === "BTR") && (
                                <TableCell className="text-right">
                                  {this.toLocaleString(e.despatch_per_day_idr)}
                                </TableCell>
                              )}
                              <TableCell className="text-right">
                                {this.toLocaleString(e.despatch_per_day_usd)}
                              </TableCell>
                              {(config.company === "SDAM" ||
                                config.company === "SRK" ||
                                config.company === "BTR") && (
                                <TableCell className="text-right">
                                  {this.toLocaleString(e.buyer_ctc_idr)}
                                </TableCell>
                              )}
                              <TableCell className="text-right">
                                {this.toLocaleString(e.buyer_ctc_usd)}
                              </TableCell>
                            </TableRow>
                          ))}
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 6
                                : 4
                            }
                          ></TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell
                              style={{ textAlign: "right", fontWeight: "bold" }}
                            >
                              {this.toLocaleString(
                                this.state.mv_buy_dem_sales_amount_idr
                              )}
                            </TableCell>
                          )}
                          <TableCell
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.toLocaleString(
                              this.state.mv_buy_dem_sales_amount_usd
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}

                    {summary.business_no_other_income_data.length > 0 && (
                      <TableBody>
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 8
                                : 5
                            }
                            style={{ color: config.themeColor }}
                          >
                            Other Income
                          </TableCell>
                        </TableRow>
                        {summary.business_no_other_income_data.map((e, idx) => (
                          <TableRow key={idx}>
                            <TableCell style={{ textTransform: "uppercase" }}>
                              {e.customer_name ? e.customer_name : e.payee_name}
                            </TableCell>
                            <TableCell>-</TableCell>
                            <TableCell className="text-right">-</TableCell>
                            {(config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") && (
                              <TableCell className="text-right">-</TableCell>
                            )}
                            {(config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") && (
                              <TableCell className="text-right">-</TableCell>
                            )}
                            <TableCell className="text-right">-</TableCell>
                            {(config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") && (
                              <TableCell className="text-right">
                                {this.toLocaleString(e.amount_idr)}
                              </TableCell>
                            )}
                            <TableCell className="text-right">
                              {this.toLocaleString(e.amount_usd)}
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 6
                                : 4
                            }
                          ></TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell
                              style={{ textAlign: "right", fontWeight: "bold" }}
                            >
                              {this.toLocaleString(
                                this.state.other_income_amount_idr
                              )}
                            </TableCell>
                          )}
                          <TableCell
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.toLocaleString(
                              this.state.other_income_amount_usd
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                    {/**
                     *
                     *  ============================== OUTGOING DEBIT NOTE (debit_type = 'Outgoing') START ========================
                     *
                     */}
                    {summary.business_no_debit_note_data.filter(
                      (e) => e.debit_type === "Outgoing"
                    ).length > 0 && (
                      <TableBody>
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 8
                                : 5
                            }
                            style={{ color: config.themeColor }}
                          >
                            Outgoing Debit Note
                          </TableCell>
                        </TableRow>
                        {summary.business_no_debit_note_data
                          .filter((e) => e.debit_type === "Outgoing")
                          .map((e, idx) => (
                            <TableRow key={idx}>
                              <TableCell>
                                {e.vendor_name
                                  ? e.vendor_name
                                  : e.customer_name
                                  ? e.customer_name
                                  : e.other_customer_name}
                              </TableCell>
                              <TableCell>-</TableCell>
                              <TableCell className="text-right">-</TableCell>
                              {(config.company === "SDAM" ||
                                config.company === "SRK" ||
                                config.company === "BTR") && (
                                <TableCell>
                                  {e.xchg_rate_editable ? (
                                    <TextField
                                      name="exchange_rate"
                                      margin="dense"
                                      variant="outlined"
                                      fullWidth
                                      onChange={(e) => {
                                        const pr = /^\d*(\.\d{0,10})?$/;
                                        if (
                                          e.target.value === "" ||
                                          pr.test(e.target.value)
                                        ) {
                                          let row =
                                            this.state.business_no_summary;
                                          let rows =
                                            row.business_no_debit_note_data.filter(
                                              (e) => e.debit_type === "Outgoing"
                                            );
                                          rows[idx].exchange_rate =
                                            e.target.value;
                                          let value = e.target.value;
                                          if (value === "") {
                                            value = 1;
                                          }
                                          rows[idx].amount_usd = (
                                            Number(
                                              rows[idx].amount_idr
                                                ? rows[idx].amount_idr
                                                : 0
                                            ) / Number(value)
                                          ).toFixed(2);
                                          this.setState(
                                            {
                                              business_no_summary: row,
                                            },
                                            () =>
                                              this.get_sales_total_calculations()
                                          );
                                        }
                                      }}
                                      value={e.exchange_rate}
                                      placeholder="Exchange Rate"
                                      inputProps={{
                                        style: { textTransform: "uppercase" },
                                      }}
                                    />
                                  ) : (
                                    <div>
                                      {e.exchange_rate}
                                      <i
                                        className="fa fa-pencil edit_icon text-right"
                                        style={{ float: "right" }}
                                        onClick={() => {
                                          let row =
                                            this.state.business_no_summary;
                                          let rows =
                                            row.business_no_debit_note_data.filter(
                                              (e) => e.debit_type === "Outgoing"
                                            );
                                          rows[idx].xchg_rate_editable = true;
                                          this.setState({
                                            business_no_summary: row,
                                          });
                                        }}
                                      />
                                    </div>
                                  )}
                                </TableCell>
                              )}
                              {(config.company === "SDAM" ||
                                config.company === "SRK" ||
                                config.company === "BTR") && (
                                <TableCell className="text-right">-</TableCell>
                              )}
                              <TableCell className="text-right">-</TableCell>
                              {(config.company === "SDAM" ||
                                config.company === "SRK" ||
                                config.company === "BTR") && (
                                <TableCell className="text-right">
                                  {this.toLocaleString(e.amount_idr)}
                                </TableCell>
                              )}
                              <TableCell className="text-right">
                                {this.toLocaleString(e.amount_usd)}
                              </TableCell>
                            </TableRow>
                          ))}
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 6
                                : 4
                            }
                          ></TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell
                              style={{ textAlign: "right", fontWeight: "bold" }}
                            >
                              {this.toLocaleString(
                                this.state.out_debit_amount_idr
                              )}
                            </TableCell>
                          )}
                          <TableCell
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.toLocaleString(
                              this.state.out_debit_amount_usd
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                    {/**
                     *
                     *  ====================== INCOMING CREDIT NOTE (credit_type = 'Incoming')  =======================
                     *
                     */}
                    {summary.business_no_credit_note_data.filter(
                      (e) => e.credit_type === "Incoming"
                    ).length > 0 && (
                      <TableBody>
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 8
                                : 5
                            }
                            style={{ color: config.themeColor }}
                          >
                            Incoming Credit Note
                          </TableCell>
                        </TableRow>
                        {summary.business_no_credit_note_data
                          .filter((e) => e.credit_type === "Incoming")
                          .map((e, idx) => (
                            <TableRow key={idx}>
                              <TableCell>
                                {e.vendor_name
                                  ? e.vendor_name
                                  : e.customer_name
                                  ? e.customer_name
                                  : e.other_customer_name}
                              </TableCell>
                              <TableCell>-</TableCell>
                              <TableCell className="text-right">-</TableCell>
                              {(config.company === "SDAM" ||
                                config.company === "SRK" ||
                                config.company === "BTR") && (
                                <TableCell>
                                  {e.xchg_rate_editable ? (
                                    <TextField
                                      name="exchange_rate"
                                      margin="dense"
                                      variant="outlined"
                                      fullWidth
                                      onChange={(e) => {
                                        const pr = /^\d*(\.\d{0,10})?$/;
                                        if (
                                          e.target.value === "" ||
                                          pr.test(e.target.value)
                                        ) {
                                          let row =
                                            this.state.business_no_summary;
                                          let rows =
                                            row.business_no_credit_note_data.filter(
                                              (e) =>
                                                e.credit_type === "Incoming"
                                            );
                                          rows[idx].exchange_rate =
                                            e.target.value;
                                          let value = e.target.value;
                                          if (value === "") {
                                            value = 1;
                                          }
                                          rows[idx].amount_usd = (
                                            Number(
                                              rows[idx].amount_idr
                                                ? rows[idx].amount_idr
                                                : 0
                                            ) / Number(value)
                                          ).toFixed(2);
                                          this.setState(
                                            {
                                              business_no_summary: row,
                                            },
                                            () =>
                                              this.get_sales_total_calculations()
                                          );
                                        }
                                      }}
                                      value={e.exchange_rate}
                                      placeholder="Exchange Rate"
                                      inputProps={{
                                        style: { textTransform: "uppercase" },
                                      }}
                                    />
                                  ) : (
                                    <div>
                                      {e.exchange_rate}
                                      <i
                                        className="fa fa-pencil edit_icon text-right"
                                        style={{ float: "right" }}
                                        onClick={() => {
                                          let row =
                                            this.state.business_no_summary;
                                          let rows =
                                            row.business_no_credit_note_data.filter(
                                              (e) =>
                                                e.credit_type === "Incoming"
                                            );
                                          rows[idx].xchg_rate_editable = true;
                                          this.setState({
                                            business_no_summary: row,
                                          });
                                        }}
                                      />
                                    </div>
                                  )}
                                </TableCell>
                              )}
                              {(config.company === "SDAM" ||
                                config.company === "SRK" ||
                                config.company === "BTR") && (
                                <TableCell className="text-right">-</TableCell>
                              )}
                              <TableCell className="text-right">-</TableCell>
                              {(config.company === "SDAM" ||
                                config.company === "SRK" ||
                                config.company === "BTR") && (
                                <TableCell className="text-right">
                                  {this.toLocaleString(e.amount_idr)}
                                </TableCell>
                              )}
                              <TableCell className="text-right">
                                {this.toLocaleString(e.amount_usd)}
                              </TableCell>
                            </TableRow>
                          ))}
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 6
                                : 4
                            }
                          ></TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell
                              style={{ textAlign: "right", fontWeight: "bold" }}
                            >
                              {this.toLocaleString(
                                this.state.inc_credit_amount_idr
                              )}
                            </TableCell>
                          )}
                          <TableCell
                            style={{ textAlign: "right", fontWeight: "bold" }}
                          >
                            {this.toLocaleString(
                              this.state.inc_credit_amount_usd
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}

                    {/**
                     *
                     *  ===================================== BARGE DEMURRAGE ==============================
                     *
                     */}
                    {(summary.barge_demurrage_from_supplier_data.length > 0 ||
                      summary.barge_demurrage_from_buyer_data.length > 0) && (
                      <TableBody>
                        <TableRow>
                          <TableCell
                            colSpan={
                              config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR"
                                ? 8
                                : 5
                            }
                            style={{ color: config.themeColor }}
                          >
                            Barge Demurrage
                          </TableCell>
                        </TableRow>
                        {summary.barge_demurrage_from_supplier_data.map(
                          (e, idx) => (
                            <TableRow key={idx}>
                              <TableCell>
                                Supplier to {config.company} - {e.buyer_name}
                              </TableCell>
                              <TableCell>-</TableCell>
                              <TableCell className="text-right">-</TableCell>
                              {(config.company === "SDAM" ||
                                config.company === "SRK" ||
                                config.company === "BTR") && (
                                <TableCell>
                                  {e.supplier_xchg_rate_editable ? (
                                    <TextField
                                      name="exchange_rate"
                                      margin="dense"
                                      variant="outlined"
                                      fullWidth
                                      onChange={(e) => {
                                        const pr = /^\d*(\.\d{0,10})?$/;
                                        if (
                                          e.target.value === "" ||
                                          pr.test(e.target.value)
                                        ) {
                                          let row =
                                            this.state.business_no_summary;
                                          let rows =
                                            row.barge_demurrage_from_supplier_data;
                                          rows[idx].supplier_exchange_rate =
                                            e.target.value;
                                          rows[idx].sales_costing_type =
                                            "supplier_to_company_demurrage";
                                          let value = e.target.value;
                                          if (value === "") {
                                            value = 1;
                                          }
                                          rows[idx].dmr_from_supplier_ctc_usd =
                                            (
                                              Number(
                                                rows[idx]
                                                  .dmr_from_supplier_ctc_idr
                                                  ? rows[idx]
                                                      .dmr_from_supplier_ctc_idr
                                                  : 0
                                              ) / Number(value)
                                            ).toFixed(2);
                                          this.setState(
                                            {
                                              business_no_summary: row,
                                            },
                                            () =>
                                              this.get_sales_total_calculations()
                                          );
                                        }
                                      }}
                                      value={e.supplier_exchange_rate}
                                      placeholder="Exchange Rate"
                                      inputProps={{
                                        style: { textTransform: "uppercase" },
                                      }}
                                    />
                                  ) : (
                                    <div>
                                      {e.supplier_exchange_rate}
                                      <i
                                        className="fa fa-pencil edit_icon text-right"
                                        style={{ float: "right" }}
                                        onClick={() => {
                                          let row =
                                            this.state.business_no_summary;
                                          let rows =
                                            row.barge_demurrage_from_supplier_data;
                                          rows[
                                            idx
                                          ].supplier_xchg_rate_editable = true;
                                          this.setState({
                                            business_no_summary: row,
                                          });
                                        }}
                                      />
                                    </div>
                                  )}
                                </TableCell>
                              )}
                              {(config.company === "SDAM" ||
                                config.company === "SRK" ||
                                config.company === "BTR") && (
                                <TableCell className="text-right">-</TableCell>
                              )}
                              <TableCell className="text-right">-</TableCell>
                              {(config.company === "SDAM" ||
                                config.company === "SRK" ||
                                config.company === "BTR") && (
                                <TableCell className="text-right">
                                  {this.toLocaleString(
                                    e.dmr_from_supplier_ctc_idr
                                  )}
                                </TableCell>
                              )}
                              <TableCell className="text-right">
                                {this.toLocaleString(
                                  e.dmr_from_supplier_ctc_usd
                                )}
                              </TableCell>
                            </TableRow>
                          )
                        )}
                        {summary.barge_demurrage_from_supplier_data.length >
                          0 && (
                          <TableRow>
                            <TableCell
                              colSpan={
                                config.company === "SDAM" ||
                                config.company === "SRK" ||
                                config.company === "BTR"
                                  ? 6
                                  : 4
                              }
                            ></TableCell>
                            {(config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") && (
                              <TableCell
                                style={{
                                  textAlign: "right",
                                  fontWeight: "bold",
                                }}
                              >
                                {this.toLocaleString(
                                  this.state.dmr_from_supplier_idr
                                )}
                              </TableCell>
                            )}
                            <TableCell
                              style={{ textAlign: "right", fontWeight: "bold" }}
                            >
                              {this.toLocaleString(
                                this.state.dmr_from_supplier_usd
                              )}
                            </TableCell>
                          </TableRow>
                        )}
                        {summary.barge_demurrage_from_buyer_data.map(
                          (e, idx) => (
                            <TableRow key={idx}>
                              <TableCell>
                                Buyer to {config.company} - {e.buyer_name}
                              </TableCell>
                              <TableCell>-</TableCell>
                              <TableCell className="text-right">-</TableCell>
                              {(config.company === "SDAM" ||
                                config.company === "SRK" ||
                                config.company === "BTR") && (
                                <TableCell>
                                  {e.xchg_rate_editable ? (
                                    <TextField
                                      name="exchange_rate"
                                      margin="dense"
                                      variant="outlined"
                                      fullWidth
                                      onChange={(e) => {
                                        const pr = /^\d*(\.\d{0,10})?$/;
                                        if (
                                          e.target.value === "" ||
                                          pr.test(e.target.value)
                                        ) {
                                          let row =
                                            this.state.business_no_summary;
                                          let rows =
                                            row.barge_demurrage_from_buyer_data;
                                          rows[idx].buyer_exchange_rate =
                                            e.target.value;
                                          rows[idx].sales_costing_type =
                                            "buyer_to_company_demurrage";
                                          let value = e.target.value;
                                          if (value === "") {
                                            value = 1;
                                          }
                                          rows[idx].dmr_from_buyer_ctc_usd = (
                                            Number(
                                              rows[idx].dmr_from_buyer_ctc_idr
                                                ? rows[idx]
                                                    .dmr_from_buyer_ctc_idr
                                                : 0
                                            ) / Number(value)
                                          ).toFixed(2);
                                          this.setState(
                                            {
                                              business_no_summary: row,
                                            },
                                            () =>
                                              this.get_sales_total_calculations()
                                          );
                                        }
                                      }}
                                      value={e.buyer_exchange_rate}
                                      placeholder="Exchange Rate"
                                      inputProps={{
                                        style: { textTransform: "uppercase" },
                                      }}
                                    />
                                  ) : (
                                    <div>
                                      {e.buyer_exchange_rate}
                                      <i
                                        className="fa fa-pencil edit_icon text-right"
                                        style={{ float: "right" }}
                                        onClick={() => {
                                          let row =
                                            this.state.business_no_summary;
                                          let rows =
                                            row.barge_demurrage_from_buyer_data;
                                          rows[idx].xchg_rate_editable = true;
                                          this.setState({
                                            business_no_summary: row,
                                          });
                                        }}
                                      />
                                    </div>
                                  )}
                                </TableCell>
                              )}
                              {(config.company === "SDAM" ||
                                config.company === "SRK" ||
                                config.company === "BTR") && (
                                <TableCell className="text-right">-</TableCell>
                              )}
                              <TableCell className="text-right">-</TableCell>
                              {(config.company === "SDAM" ||
                                config.company === "SRK" ||
                                config.company === "BTR") && (
                                <TableCell className="text-right">
                                  {this.toLocaleString(
                                    e.dmr_from_buyer_ctc_idr
                                  )}
                                </TableCell>
                              )}
                              <TableCell className="text-right">
                                {this.toLocaleString(e.dmr_from_buyer_ctc_usd)}
                              </TableCell>
                            </TableRow>
                          )
                        )}
                        {summary.barge_demurrage_from_buyer_data.length > 0 && (
                          <TableRow>
                            <TableCell
                              colSpan={
                                config.company === "SDAM" ||
                                config.company === "SRK" ||
                                config.company === "BTR"
                                  ? 6
                                  : 4
                              }
                            ></TableCell>
                            {(config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") && (
                              <TableCell
                                style={{
                                  textAlign: "right",
                                  fontWeight: "bold",
                                }}
                              >
                                {this.toLocaleString(
                                  this.state.dmr_from_buyer_idr
                                )}
                              </TableCell>
                            )}
                            <TableCell
                              style={{ textAlign: "right", fontWeight: "bold" }}
                            >
                              {this.toLocaleString(
                                this.state.dmr_from_buyer_usd
                              )}
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    )}
                    <TableBody>
                      <TableRow>
                        <TableCell
                          colSpan={
                            config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR"
                              ? 3
                              : 4
                          }
                        ></TableCell>
                        {(config.company === "SDAM" ||
                          config.company === "SRK" ||
                          config.company === "BTR") && (
                          <TableCell>
                            <button
                              className="header_button header_button_text mt-1"
                              onClick={this.sales_Xchg_rate_handler}
                            >
                              Save
                            </button>
                          </TableCell>
                        )}
                        {(config.company === "SDAM" ||
                          config.company === "SRK" ||
                          config.company === "BTR") && (
                          <TableCell colSpan={2}></TableCell>
                        )}
                        {(config.company === "SDAM" ||
                          config.company === "SRK" ||
                          config.company === "BTR") && (
                          <TableCell
                            style={{ textAlign: "right", fontWeight: "bold" }}
                            className="text-success"
                          >
                            {this.state.sales_grand_total_idr
                              ? this.toLocaleString(
                                  this.state.sales_grand_total_idr
                                )
                              : 0}
                          </TableCell>
                        )}
                        <TableCell
                          style={{ textAlign: "right", fontWeight: "bold" }}
                          className="text-success"
                        >
                          {this.state.sales_grand_total_usd
                            ? this.toLocaleString(
                                this.state.sales_grand_total_usd
                              )
                            : 0}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  {/* </TableContainer> */}
                </div>
              </div>
            )}

            {this.state.activeTabIndex === 2 && (
              <div className="row mt-4">
                <div className="col-lg m-auto">
                  <TableContainer component={Paper}>
                    <Table size="" aria-label="a dense table">
                      <TableHead className="bg-light">
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell className="text-right">
                            Amount ($)
                          </TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell className="text-right">
                              Amount (IDR)
                            </TableCell>
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>Total Expenses</TableCell>
                          <TableCell className="text-right">
                            {this.toLocaleString(
                              this.state.purchase_grand_total_usd
                            )}
                          </TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell className="text-right">
                              {this.toLocaleString(
                                this.state.purchase_grand_total_idr
                              )}
                            </TableCell>
                          )}
                        </TableRow>
                        <TableRow>
                          <TableCell>Total Income</TableCell>
                          <TableCell className="text-right">
                            {this.toLocaleString(
                              this.state.sales_grand_total_usd
                            )}
                          </TableCell>
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell className="text-right">
                              {this.toLocaleString(
                                this.state.sales_grand_total_idr
                              )}
                            </TableCell>
                          )}
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ fontWeight: "bold" }}>
                            Profit / Loss
                          </TableCell>

                          {this.state.total_profit_or_loss_amount < 0 ? (
                            <TableCell className="text-danger text-right text-bold">
                              {this.state.total_profit_or_loss_amount
                                ? "( " +
                                  this.toLocaleString(
                                    Math.abs(
                                      this.state.total_profit_or_loss_amount
                                    )
                                  ) +
                                  " )"
                                : 0}
                            </TableCell>
                          ) : (
                            <TableCell className="text-success text-right text-bold">
                              {this.state.total_profit_or_loss_amount
                                ? this.toLocaleString(
                                    this.state.total_profit_or_loss_amount
                                  )
                                : 0}
                            </TableCell>
                          )}
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <TableCell
                              className={
                                this.state.total_profit_or_loss_amount_idr < 0
                                  ? "text-danger text-right text-bold"
                                  : "text-success text-right text-bold"
                              }
                            >
                              {this.state.total_profit_or_loss_amount_idr < 0
                                ? "( " +
                                  this.toLocaleString(
                                    Math.abs(
                                      this.state.total_profit_or_loss_amount_idr
                                    )
                                  ) +
                                  " )"
                                : this.toLocaleString(
                                    this.state.total_profit_or_loss_amount_idr
                                  )}
                            </TableCell>
                          )}
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            )}

            {this.state.activeTabIndex === 3 && (
              <div className="row mt-2">
                <div className="col-lg m-auto">
                  <AvgXchgRate business_no_id={business_no_id} />
                </div>
              </div>
            )}
            {/* 
            <div className='row mt-2'>
              <div className='col'>
                <button className='btn btn-sm btn-secondary' onClick={() => window.location.href = '/view-business/' + btoa(business_no_id)}>Back</button>
              </div>
            </div> */}
          </div>
        </div>
      );
    }
  }
}
