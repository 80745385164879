import React, { Fragment } from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import logo from '../../images/SDAM_Logo.png'
import config from '../../config/config';
const styles = StyleSheet.create({
  container: {
    borderBottomColor: 'black',
    borderBottomWidth: 0.4,
    flexDirection: 'row',

  },
  left: {
    width: '55%',
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  right: {
    width: "45%",
    flexDirection: 'column',
    borderLeft: 0.4,
    borderLefttColor: "black",
  },
  logo: {
    width: 160,
    height: 80,
    justifyContent: 'flex-start',

  },
  companyText: {
    borderRadius: 5,
    padding: 5,
    fontSize: 14,
    fontStyle: 'bold'
  },
  row: {
    flexDirection: 'row',
    borderBottomWidth: 0.4,
    borderBottomColor: 'black',
  },
  label: {
    width: "25%",
    padding: 5,
    fontSize: 8,
    fontWeight: "bold"
  },
  value: {
    fontSize: 8,
    padding: 5,
    textTransform: 'uppercase',
  }

});


const InvoiceNo = ({ invoice }) => {

  return (

    <Fragment>

      <View style={styles.container}>
        <View style={styles.left}>
          {config.company === "SDAM" ?

            <Image style={styles.logo} src={logo} />

            :

            <Text style={styles.companyText}>{
              config.company === "SRPL" ? `SAII RESOURCES PTE LTD`
                : config.company === "PRPL" ? `PRAKURTHI RESOURCES`
                  : config.company === 'SRK' ? `SUMBER REZEKI KALTIM`
                    : config.company === "AVS" ? `AVS RESOURCES`
                      : `BUMI TAMBANG RESOURCES`
            }</Text>
          }
        </View>
        <View style={styles.right}>
          <Text style={{ padding: 6, fontSize: 10, fontWeight: 'bold', textAlign: 'center', alignContent: 'center', alignItems: 'center', borderBottomWidth: 0.4, borderBottomColor: 'black' }}>{`${invoice?.costing_name} Invoice`}</Text>
          <View style={styles.row}>
            <Text style={styles.label}>Costing ID </Text>
            <Text style={styles.value}> {` : ${invoice?.costing_id}`}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.label}>To</Text>
            <Text style={styles.value}> {` : ${invoice?.to}`}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.label}>Business No</Text>
            <Text style={styles.value}> {` : ${invoice?.business_no}`}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.label}>Invoice No</Text>
            <Text style={styles.value}> {` : ${invoice?.invoice_no}`}</Text>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <Text style={styles.label}>Invoice Date</Text>
            <Text style={styles.value}> {`: ${invoice?.invoice_date}`}</Text>
          </View>
        </View>
      </View>
    </Fragment>
  )
}

export default InvoiceNo