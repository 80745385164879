import React, { Component } from "react";

import { Snackbar, Drawer } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import api from "../../../api/api";

import OtherIncomeAdd from "./Other-Incomes-Add";
import OtherIncomeView from "./Other-Incomes-View";
import OtherIncomeUpdate from "./Other-Incomes-Update";
import { localDateFormate, toLocaleString } from "../../common/common";
import Loader from "../../common/Loader";

export default class Other_Income_List extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      OtherIncomeData: [],
      isLoading: true,
      stevedor_price_payments: [],
      features: [],
      closeDialog: false,
      deletePaymentDialog: false,
      updateDocuments: false,
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (
      !(
        features.includes("302") ||
        features.includes("303") ||
        features.includes("304") ||
        features.includes("305") ||
        features.includes("97")
      )
    ) {
      window.location.href = "/dashboard";
    }
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const businessNoID = this.props.businessNoID;

    await api
      .get_business_no_other_income(loginUserID, idToken, businessNoID)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.business_no_other_income) {
                for (var i in res.business_no_other_income) {
                  var total_paid_amout = 0;
                  for (var j of res.business_no_other_income[i]
                    .other_income_payments) {
                    total_paid_amout += Number(j.amount);
                  }
                  res.business_no_other_income[i].total_paid_amout =
                    total_paid_amout;
                  res.business_no_other_income[i].remaining_amount =
                    Number(res.business_no_other_income[i].amount) -
                    Number(total_paid_amout);
                }
                this.setState({
                  OtherIncomeData: res.business_no_other_income,
                  isLoading: false,
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                snackBarErrorOpen: true,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  }

  onAddResponse = (value) => {
    if (value.code === "200") {
      this.setState(
        {
          OtherIncomeAdd: false,
          OtherIncomeView: false,
          OtherIncomeUpdate: false,
          openDrawer: false,
          updateDocuments: false,
        },
        () => this.componentDidMount()
      );
    } else {
      this.setState({
        OtherIncomeAdd: false,
        OtherIncomeView:
          value.screen === "OtherIncome" &&
          value.message === "alreadyDataAvailable"
            ? true
            : false,
        OtherIncomeUpdate: false,
        openDrawer: true,
        updateDocuments: false,
      });
    }
  };

  CallbackDrawerClose = (childData) => {
    this.setState(
      {
        openDrawer: childData,
        OtherIncomeAdd: false,
        OtherIncomeView: false,
        OtherIncomeUpdate: false,
        updateDocuments: false,
      },
      () => this.componentDidMount()
    );
  };

  onUpdateResponse = (value) => {
    if (value) {
      this.setState(
        {
          OtherIncomeAdd: false,
          OtherIncomeView:
            value.code === "200" && value.screen === "OtherIncome"
              ? true
              : false,
          OtherIncomeUpdate: false,
          updateDocuments: false,
        },
        () => this.componentDidMount()
      );
    }
  };

  CallbackViewPage = (childData) => {
    this.setState(
      {
        openDrawer: true,
        OtherIncomeUpdate: false,
        OtherIncomeAdd: false,
        OtherIncomeView: childData === "OtherIncome" ? true : false,
        updateDocuments: false,
      },
      () => this.componentDidMount()
    );
  };

  editCallback = (childData) => {
    this.setState(
      {
        openDrawer: true,
        OtherIncomeUpdate: childData === "OtherIncome" ? true : false,
        OtherIncomeAdd: false,
        OtherIncomeView: false,
        updateDocuments: false,
      },
      () => this.componentDidMount()
    );
  };

  deleteCharges = (data) => {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    var finalData = data;
    finalData.other_income_id = data.id;
    finalData.login_user_id = loginUserID;
    finalData.idtoken = idToken;
    finalData.status = "Deleted";
    //Delete Stevedore costing charge
    api.update_buss_no_other_income(finalData).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.setState(
              {
                snackBarSuccessOpen: true,
                successMsg: res.message,
              },
              () => this.componentDidMount()
            );
          } else if (res.code === "601") {
            this.setState({ errorMsg: res.message, snackBarErrorOpen: true });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          } else if (res.code === "624") {
            this.setState({
              errorMsg: res.columns.length > 0 ? res.columns[0] : "",
              snackBarErrorOpen: true,
            });
          }
        });
      }
    });
  };

  render() {
    let drawerComponent;
    if (this.state.OtherIncomeAdd) {
      drawerComponent = (
        <OtherIncomeAdd
          businessNoID={this.props.businessNoID}
          onAddResponse={this.onAddResponse}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
        />
      );
    } else if (this.state.OtherIncomeView && this.state.businessNo) {
      drawerComponent = (
        <OtherIncomeView
          OtherIncomeID={this.state.OtherIncomeID}
          businessNoID={this.state.businessNo}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.editCallback}
          returnDocumentsHandler={() => {
            this.setState({
              showDrawer: true,
              OtherIncomeUpdate: true,
              OtherIncomeView: false,
              OtherIncomeAdd: false,
              updateDocuments: true,
            });
          }}
        />
      );
    } else if (this.state.OtherIncomeUpdate && this.state.businessNo) {
      drawerComponent = (
        <OtherIncomeUpdate
          OtherIncomeID={this.state.OtherIncomeID}
          businessNoID={this.state.businessNo}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.CallbackViewPage}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div>
          <div className="row clearfix content-header border-bottom">
            <div className="row clearfix content-header border-bottom">
              <h4 className="mt-2" style={{ padding: "4px" }}>
                {` Other Income  - ${
                  this.props.mother_vessel_name
                    ? this.props.mother_vessel_name
                    : ""
                } ${this.props.name ? `( ${this.props.name} )` : ""}`}
              </h4>
              {(this.state.features.includes("302") ||
                this.state.features.includes("97")) && (
                <i
                  className="fa fa-plus-circle ml-2 mt-3"
                  aria-hidden="true"
                  style={{
                    fontSize: 22,
                    color: config.themeColor,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    this.setState({
                      openDrawer: true,
                      OtherIncomeAdd: true,
                      OtherIncomeView: false,
                      OtherIncomeUpdate: false,
                    });
                  }}
                />
              )}
            </div>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <Drawer
            anchor="right"
            open={this.state.openDrawer}
            variant="temporary"
            elevation={20}
            style={{ overflow: "initial" }}
          >
            <div className="row" style={{ width: 800 }}>
              {drawerComponent}
            </div>
          </Drawer>
          <div className="card pl-0 pr-0">
            <div
              className="card-body p-0"
              style={{ backgroundColor: "rgba(25,118,210,.01)" }}
              id="accordion"
            >
              <div className="col-lg-12 mb-2 p-0">
                <div className="row">
                  <div className="table-responsive">
                    <table className="table table-sm table-bordered">
                      <thead className="table-header">
                        <tr>
                          <th nowrap="true"> Costing ID </th>
                          <th nowrap="true"> From</th>
                          <th nowrap="true"> Date</th>
                          <th nowrap="true" className="text-right">
                            {" "}
                            Amount{" "}
                          </th>
                          <th nowrap="true"> Currency</th>
                          <th nowrap="true" className="text-right">
                            Amount in{" "}
                            {config.company === "SRPL" ||
                            config.company === "AVS" ||
                            config.company === "PRPL"
                              ? "USD"
                              : "IDR"}
                          </th>
                          <th nowrap="true" className=" pl-4">
                            {" "}
                            Action
                          </th>
                        </tr>
                      </thead>
                      {this.state.OtherIncomeData.length > 0 ? (
                        <tbody>
                          {this.state.OtherIncomeData.map((idr, indx) => (
                            <tr key={indx}>
                              <td className="allocation_table_value">
                                {(this.state.features.includes("295") ||
                                  this.state.features.includes("97")) && (
                                  <i
                                    aria-hidden="true"
                                    style={{
                                      fontStyle: "normal",
                                      color: config.themeColor,
                                      cursor: "pointer",
                                      whiteSpace: "nowrap",
                                    }}
                                    onClick={() =>
                                      this.setState({
                                        openDrawer: true,
                                        OtherIncomeAdd: false,
                                        OtherIncomeView: true,
                                        OtherIncomeUpdate: false,
                                        businessNo: idr.business_no_id,
                                        OtherIncomeID: idr.id,
                                      })
                                    }
                                  >
                                    {idr.costing_no}
                                  </i>
                                )}
                              </td>
                              <td className="text-uppercase" nowrap="true">
                                {idr.vendor_name !== null
                                  ? idr.vendor_name
                                  : idr.payee_name}
                              </td>
                              <td
                                className="allocation_table_value"
                                nowrap="true"
                              >
                                {localDateFormate(idr.income_date)}
                              </td>
                              <td className="text-right" nowrap="true">
                                {toLocaleString(idr.amount)}
                              </td>
                              <td className="text-right" nowrap="true">
                                {idr.currency}
                              </td>
                              <td className="text-right" nowrap="true">
                                {toLocaleString(idr.local_currency_amount)}
                              </td>
                              <td
                                nowrap="true"
                                className="table_td text-left pl-4"
                              >
                                {(this.state.features.includes("236") ||
                                  this.state.features.includes("97")) && (
                                  <i
                                    className="fa fa-pencil"
                                    style={{
                                      fontSize: 14,
                                      color: config.themeColor,
                                      cursor: "pointer",
                                    }}
                                    data-toggle="modal"
                                    title="Edit floating crane charges"
                                    data-placement="bottom"
                                    onClick={() =>
                                      this.setState({
                                        businessNo: idr.business_no_id,
                                        OtherIncomeID: idr.id,
                                        openDrawer: true,
                                        OtherIncomeUpdate: true,
                                      })
                                    }
                                  ></i>
                                )}
                                {idr.payment_postings_availability === 0 &&
                                  (this.state.features.includes("237") ||
                                    this.state.features.includes("97")) && (
                                    <i
                                      className="fa fa-trash-o"
                                      style={{
                                        paddingLeft: 10,
                                        fontSize: 14,
                                        color: config.themeColor,
                                        cursor: "pointer",
                                      }}
                                      data-toggle="modal"
                                      title="Delete floating crane charges"
                                      data-placement="bottom"
                                      data-keyboard="false"
                                      data-target="#deleteConformationModal"
                                      data-backdrop="static"
                                      onClick={() => this.deleteCharges(idr)}
                                    ></i>
                                  )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td colSpan="5" className="p-2">
                              No data available
                            </td>
                          </tr>{" "}
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
