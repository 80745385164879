import React, { Component } from "react";
import SideBar from "../../common/SideBar";
import Header from "../../common/Header";
import api from "../../../api/api";
import config from "../../../config/config";

import CookieHandler from "../../common/CookieHandler";
import { Snackbar, Popover, MenuItem, Drawer } from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/Dashboard";
import WarningIcon from "@material-ui/icons/Warning";

import { Alert } from "@material-ui/lab";
import DocumentsUpload from "../../DocumentsUpload";
import AdvancePaymentPostings from "../AdvancePaymentPostings";
import { localDateFormate } from "../../common/common";
import Loader from "../../common/Loader";
import QualityView from "../Qualities/QualitiesView";

export default class ViewSalesContract extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      salesContractNo: "",
      oldContractNo: "",
      customer: "0",
      salesType: "0",
      quality: "",
      quantity: "",
      tolerance: "0",
      loading_rate: "",
      sales_term: "0",
      //qualities :[],
      supplier_surveyor_names: "",
      supplier_surveyor_analysis_type: "",
      internal_surveyor_names: "",
      internal_surveyor_analysis_type: "",
      joint_surveyor_names: "",
      joint_surveyor_analysis_type: "",
      surveyorType: "",
      surveyorName: "",
      analysisType: "",
      qualities: [],
      price: "",
      currency: "0",
      port_of_loading: "",
      vessel_type: "0",
      share_point_doc_link: "",
      errorMsg: "",
      successMsg: "",

      businessNoData: [],
      unassigned_business_no_data: [],
      unassigned_partial_quantity_data: [],
      portOfDischargeRows: [],
      dischargeRateField: false,

      activeTabIndex: false,
      ISOactiveTabIndex: false,
      salesContractId: "",
      adjustTolerance: "",

      specsAstmClickedRow: 0,
      specsIsoClickedRow: 0,
      sales_contract_invoices: [],
      isLoading: true,

      features: [],
      sales_adv_contract: [],
      payment_currency: "0",
      currency_xchg_rate: "",
      amount_in_payment_currency: "",
      // Customer Advance Payments
      payment_date: null,
      amount: "",
      addPaymentRemarks: "",
      paymentErrorMsg: "",
      openDocumentsDrawer: false,
      addDocuments: false,
      files: [],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    } else {
      var features = this.Cookie.getCookie("features");
      this.setState({ features: features });
      if (!(features.includes("17") || features.includes("97"))) {
        window.location.href = "/dashboard";
      }
      const idToken = this.Cookie.getIdTokenCookie();
      const loginUserID = this.Cookie.getCookie("loginUserId");
      const status = "Active";
      try {
        var salesContractId = decodeURIComponent(
          window.atob(this.props.match.params.salesContractID)
        );
        this.setState({ salesContractID: salesContractId });
      } catch (e) {
        window.location.href = "/Pagenotfound";
      }
      // Get Business Number By Sales contract
      await api
        .getBusinessBySC(loginUserID, salesContractId, idToken)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                var sumOfAllocated_quantity = 0;
                var sumOfDelivered_quantity = 0;
                for (var i of res.business_no_data) {
                  sumOfAllocated_quantity += Number(i.allocated_quantity);
                  sumOfDelivered_quantity += Number(i.delivered_quantity);
                }
                for (var j of res.unassigned_business_no_data) {
                  sumOfDelivered_quantity += Number(j.barge_quantity);
                }
                for (var k of res.unassigned_partial_quantity_data) {
                  sumOfDelivered_quantity += Number(
                    k.remaining_partial_quantity
                  );
                }
                this.setState({
                  businessNoData: res.business_no_data
                    ? res.business_no_data
                    : [],
                  unassigned_business_no_data: res.unassigned_business_no_data
                    ? res.unassigned_business_no_data
                    : [],
                  unassigned_partial_quantity_data:
                    res.unassigned_partial_quantity_data
                      ? res.unassigned_partial_quantity_data
                      : [],
                  sumOfAllocated_quantity: sumOfAllocated_quantity,
                  sumOfDelivered_quantity: sumOfDelivered_quantity,
                });
                // this.setState({
                //   businessNoData : res.business_no_data ? res.business_no_data :[] ,
                //   //totalQuantityAllocated : res.total_quantity_allocated
                // });
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  snackBarErrorOpen: true,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });

      await api
        .get_adv_adjustments_by_sales_contract_id(
          loginUserID,
          idToken,
          salesContractId
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                if (res.sales_adv_contract) {
                  var sumOfAdvance_adjustment = 0;
                  for (var i of res.sales_adv_contract) {
                    sumOfAdvance_adjustment += Number(i.advance_adjustment);
                  }
                  this.setState({
                    sales_adv_contract: res.sales_adv_contract,
                    sumOfAdvance_adjustment: sumOfAdvance_adjustment,
                    //isLoading: false
                  });
                } else {
                  alert(
                    "Unexpected error occured. Please contact administrator."
                  );
                }
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  isLoading: false,
                  successMsg: "",
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });

      const request = {
        login_user_id: loginUserID,
        idtoken: idToken,
        sales_contract_id: salesContractId,
      };

      await api.get_sc_adv_invoices_by_sc(request).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState({
                sales_contract_invoices: res.sales_contract_invoices,
              });
            }
          });
        }
      });

      // get sales contract API
      await api
        .getSalesContract(loginUserID, salesContractId, idToken, status)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
              if (res.code === "200") {
                if (res.sales_contract) {
                  //TODO: <!--------------------------- Inserting the values into LaycanData ------------!>
                  var portOfDischargeData = [];
                  if (
                    res.sales_contract.discharge_rate_1 ||
                    res.sales_contract.port_of_discharge_1
                  ) {
                    portOfDischargeData.push({
                      discharge_rate: res.sales_contract.discharge_rate_1
                        ? res.sales_contract.discharge_rate_1
                        : "",
                      portOf_discharge: res.sales_contract.port_of_discharge_1
                        ? res.sales_contract.port_of_discharge_1
                        : "",
                    });
                  }
                  if (
                    res.sales_contract.discharge_rate_2 ||
                    res.sales_contract.port_of_discharge_2
                  ) {
                    portOfDischargeData.push({
                      discharge_rate: res.sales_contract.discharge_rate_2
                        ? res.sales_contract.discharge_rate_2
                        : "",
                      portOf_discharge: res.sales_contract.port_of_discharge_2
                        ? res.sales_contract.port_of_discharge_2
                        : "",
                    });
                  }
                  if (
                    res.sales_contract.discharge_rate_3 ||
                    res.sales_contract.port_of_discharge_3
                  ) {
                    portOfDischargeData.push({
                      discharge_rate: res.sales_contract.discharge_rate_3
                        ? res.sales_contract.discharge_rate_3
                        : "",
                      portOf_discharge: res.sales_contract.port_of_discharge_3
                        ? res.sales_contract.port_of_discharge_3
                        : "",
                    });
                  }
                  for (var k in res.sales_contract.qualities) {
                    for (
                      let n = 0;
                      n < res.sales_contract.qualities[k].sales_types.length;
                      n++
                    ) {
                      if (
                        res.sales_contract.qualities[k].sales_types[n]
                          .sales_type === "CFR DP" ||
                        res.sales_contract.qualities[k].sales_types[n]
                          .sales_type === "CIF DP"
                      ) {
                        this.setState({ dischargeRateField: true });
                      }
                    }
                  }
                  this.setState((prevState) => ({
                    portOfDischargeRows: portOfDischargeData,
                    salesContractId: salesContractId,
                    ...prevState,
                    ...res.sales_contract,
                    isLoading: false,
                  }));
                } else {
                  alert(
                    "Unexpected error occured. Please contact administrator."
                  );
                }
              } else if (res.code === "607") {
                window.location.href = "/logout";
              } else if (res.code === "629") {
                this.setState({
                  successMsg: "",
                  errorMsg: res.message,
                  snackBarErrorOpen: true,
                });
              }
            });
          }
        });
    }

    document.title = config.documentTitle + "View Sales Contract";
  }

  addCustomerAdvancePayament = () => {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const salesContractId = decodeURIComponent(
      window.atob(this.props.match.params.salesContractID)
    );
    const paymentDate = this.state.payment_date;
    var amount = this.state.amount;
    var remarks = this.state.addPaymentRemarks;
    var invoice = this.state.invoice ? this.state.invoice : "";

    var payment_currency = this.state.payment_currency
      ? this.state.payment_currency
      : "";
    var currency_xchg_rate = this.state.currency_xchg_rate
      ? this.state.currency_xchg_rate
      : "";
    var amount_in_payment_currency = this.state.amount_in_payment_currency
      ? this.state.amount_in_payment_currency
      : "";
    var status = "Active";
    if (paymentDate === null) {
      this.setState({
        paymentDateError: true,
      });
    }
    if (amount === "") {
      this.setState({
        amountError: true,
      });
    }
    if (payment_currency === "0") {
      this.setState({
        payment_currencyError: true,
      });
    }
    if (
      ((config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL") &&
        this.state.payment_currency === "USD") ||
      ((config.company === "SDAM" ||
        config.company === "SRK" ||
        config.company === "BTR") &&
        this.state.payment_currency === "IDR")
    ) {
      currency_xchg_rate = 1;
    }
    if (paymentDate !== null && amount !== "") {
      // Add Customer Advance Payement
      api
        .addCustomerAdvancePayament(
          loginUserID,
          idToken,
          salesContractId,
          paymentDate,
          amount,
          payment_currency,
          currency_xchg_rate,
          amount_in_payment_currency,
          invoice,
          remarks,
          status
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                this.setState(
                  {
                    amount: "",
                    payment_date: null,
                    addPaymentRemarks: "",
                    paymentDateError: false,
                    amountError: false,
                    paymentErrorMsg: "",
                    invoice: "",
                    snackBarSuccessOpen: true,
                    successMsg: res.message,
                  },
                  () => {
                    window.$("#addAdvancePayment").modal("hide");
                    this.componentDidMount();
                  }
                );
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  snackBarErrorOpen: true,
                });
              } else if (res.code === "624") {
                this.setState({
                  paymentErrorMsg: res.columns[0],
                  sucessMsg: "",
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    } else {
      alert("Please fill mandatory fields.");
    }
  };

  updateCustomerAdvancePayment = (data) => {
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    var customerAdvancePaymentID = data.id;
    var paymentDate = this.state.payment_date;
    var amount = this.state.amount;
    var invoice = this.state.invoice ? this.state.invoice : "";

    var payment_currency = this.state.payment_currency
      ? this.state.payment_currency
      : "";
    var currency_xchg_rate = this.state.currency_xchg_rate
      ? this.state.currency_xchg_rate
      : "";
    var amount_in_payment_currency = this.state.amount_in_payment_currency
      ? this.state.amount_in_payment_currency
      : "";
    var remarks = this.state.addPaymentRemarks
      ? this.state.addPaymentRemarks
      : "";
    var status = "Active";
    if (paymentDate === null) {
      this.setState({
        paymentDateError: true,
      });
    }
    if (amount === "") {
      this.setState({
        amountError: true,
      });
    }
    if (payment_currency === "0") {
      this.setState({
        payment_currencyError: true,
      });
    }
    if (
      ((config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL") &&
        this.state.payment_currency === "USD") ||
      ((config.company === "SDAM" ||
        config.company === "SRK" ||
        config.company === "BTR") &&
        this.state.payment_currency === "IDR")
    ) {
      currency_xchg_rate = 1;
    }
    if (paymentDate !== null && amount !== "" && payment_currency !== "0") {
      api
        .updateCustomerAdvancePayment(
          loginUserID,
          idToken,
          customerAdvancePaymentID,
          paymentDate,
          amount,
          payment_currency,
          currency_xchg_rate,
          amount_in_payment_currency,
          invoice,
          remarks,
          status
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                this.setState(
                  {
                    amount: "",
                    payment_date: null,
                    addPaymentRemarks: "",
                    paymentDateError: false,
                    amountError: false,
                    paymentErrorMsg: "",
                    invoice: "",
                    snackBarSuccessOpen: true,
                    successMsg: res.message,
                  },
                  () => {
                    window.$("#updateAdvancePayment").modal("hide");
                    this.componentDidMount();
                  }
                );
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  snackBarErrorOpen: true,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              } else if (res.code === "624") {
                this.setState({
                  paymentErrorMsg: res.columns[0],
                  sucessMsg: "",
                });
              }
            });
          }
        });
    } else {
      alert("Please fill mandatory fields.");
    }
  };

  AddPaymentPostingHandler = (data) => {
    let request = {
      ...data,
      invoice_id: data?.costing_id,
      payment_date: data?.date_of_payment,
      sales_contract_id: decodeURIComponent(
        window.atob(this.props.match.params.salesContractID)
      ),
    };

    api.add_customer_adv_payment(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            window.$("#addAdvancePayment").modal("hide");
            this.componentDidMount();
          } else if (res.code === "601") {
            this.setState({
              errorMsg: res.message,
              snackBarErrorOpen: true,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
  };

  UpdatePaymentPostingHandler = (data) => {
    let request = {
      ...data,
      sales_contract_id: decodeURIComponent(
        window.atob(this.props.match.params.salesContractID)
      ),
      customer_adv_payment_id: data?.id,
    };

    api.update_customer_adv_payment(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            window.$("#addAdvancePayment").modal("hide");
            this.componentDidMount();
          } else if (res.code === "601") {
            this.setState({
              errorMsg: res.message,
              snackBarErrorOpen: true,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
  };

  deleteAdvancePayment = (deleteData) => {
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    var customerAdvancePaymentID = deleteData.id;
    var paymentDate = deleteData.payment_date;
    var amount = deleteData.amount;
    var invoice = deleteData.invoice ? deleteData.invoice : "";
    var remarks = deleteData.remarks ? deleteData.remarks : "";
    var status = "Deleted";

    var payment_currency = this.state.payment_currency
      ? this.state.payment_currency
      : "";
    var currency_xchg_rate = this.state.currency_xchg_rate
      ? this.state.currency_xchg_rate
      : "";
    var amount_in_payment_currency = this.state.amount_in_payment_currency
      ? this.state.amount_in_payment_currency
      : "";
    api
      .updateCustomerAdvancePayment(
        loginUserID,
        idToken,
        customerAdvancePaymentID,
        paymentDate,
        amount,
        payment_currency,
        currency_xchg_rate,
        amount_in_payment_currency,
        remarks,
        invoice,
        status
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState(
                {
                  amount: "",
                  payment_date: null,
                  addPaymentRemarks: "",
                  paymentDateError: false,
                  amountError: false,
                  snackBarSuccessOpen: true,
                  successMsg: res.message,
                },
                () => {
                  window.$("#deletePaymentModal").modal("hide");
                  this.componentDidMount();
                }
              );
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                snackBarErrorOpen: true,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            } else if (res.code === "624") {
              this.setState({
                paymentErrorMsg: res.columns[0],
                sucessMsg: "",
              });
            }
          });
        }
      });
  };

  toLocaleString(value) {
    return Number(value).toLocaleString();
  }

  cloneHandler = () => {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    this.setState({ isLoading: true });
    try {
      var salesContractId = decodeURIComponent(
        window.atob(this.props.match.params.salesContractID)
      );
    } catch (e) {
      window.location.href = "/Pagenotfound";
    }
    api
      .clone_sale_contract(loginUserID, idToken, salesContractId)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.sale_contract_id) {
                this.setState({
                  clonedSC_contract_id: res.sale_contract_id,
                  addButtonOpen: false,
                  isLoading: false,
                });
                window.$("#clonedSuccessModal").modal({
                  backdrop: "static",
                  keyboard: true,
                  show: true,
                });
                //window.location.href = '/view-sales-contract/' + btoa(res.sale_contract_id);
              }
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                snackBarErrorOpen: true,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  };

  addButtonOpen = (event) => {
    event.preventDefault();
    this.setState({
      addButtonOpen: true,
      openDrawer: false,
      anchorEn: event.currentTarget,
    });
  };

  addButtonClose = () => {
    this.setState({
      addButtonOpen: false,
    });
  };

  onLinkClick(link) {
    var links = link.split("://");
    if (links.length === 1) {
      return "http://" + link;
    } else {
      return link;
    }
  }

  onAddResponse = (value) => {
    this.setState({ addDocuments: false, openDocumentsDrawer: false }, () => {
      this.componentDidMount();
    });
  };

  CallbackDrawerClose = (childData) => {
    this.setState({
      openDocumentsDrawer: false,
      addDocuments: false,
    });
  };

  AddInvoicesHandler = (invoiceDetails) => {
    let request = {
      ...invoiceDetails,
      sales_contract_id: decodeURIComponent(
        window.atob(this.props.match.params.salesContractID)
      ),
    };
    api.add_sc_adv_invoice(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.componentDidMount();
            window.$("#invoicesPostingModal").modal("hide");
          } else {
          }
        });
      }
    });
  };

  UpdateInvoicesHandler = (invoiceDetails) => {
    let request = {
      ...invoiceDetails,
      sc_adv_invoice_id: invoiceDetails?.id,
      sales_contract_id: decodeURIComponent(
        window.atob(this.props.match.params.salesContractID)
      ),
    };

    api.update_sc_adv_invoice(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.componentDidMount();
            window.$("#invoicesPostingModal").modal("hide");
          } else {
          }
        });
      }
    });
  };

  ContractApproveHandler = (status) => {
    let request = {
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
      sale_contract_id: decodeURIComponent(
        window.atob(this.props.match.params.salesContractID)
      ),
      sales_contract_id: decodeURIComponent(
        window.atob(this.props.match.params.salesContractID)
      ),
      status: status,
    };

    if (status === "Approved") {
      api.approve_sales_contract(request).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState({
                successMsg: "Contract has been update successfully",
                snackBarSuccessOpen: true,
              });
              this.componentDidMount();
            }
          });
        }
      });
    } else {
      api.updateSaleContractStatus(request).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res) {
              if (res.code === "200") {
                this.setState({
                  successMsg: "Contract has been update successfully",
                  snackBarSuccessOpen: true,
                });
                this.componentDidMount();
              }
              if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  snackBarErrorOpen: true,
                });
                if (res.code === "607") {
                  window.location.href = "/logout";
                }
              }
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          });
        }
      });
    }
  };

  render() {
    const salesContractId = decodeURIComponent(
      window.atob(this.props.match.params.salesContractID)
    );
    let drawerComponent;
    if (this.state.addDocuments && salesContractId) {
      drawerComponent = (
        <DocumentsUpload
          feature_name={"Sales_Contract"}
          feature_seq_id={salesContractId}
          onAddResponse={this.onAddResponse}
          documents={this.state.files}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
        />
      );
    }
    return (
      <div id="wraper">
        <SideBar />
        <div className="content">
          <Header />
          <div className="clearfix content-header">
            <div className="row">
              <div className="col-sm text-left pl-0">
                <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                  {" "}
                  <a href={"/sales-contract-list"}>
                    <i className="fa fa-arrow-left left_arrow_adjst" />
                  </a>{" "}
                  Coal Sales Contract - {this.state.contract_no} &nbsp;
                  <span
                    className={
                      this.state.status === "Active"
                        ? "type_1 Freezed"
                        : "type_2"
                    }
                    style={{ padding: 8, marginLeft: 16 }}
                  >
                    {this.state.status}
                  </span>
                </h4>
              </div>
              {this.state.status === "Draft" && (
                <div
                  className="col-lg-3 text-right float-sm-left"
                  style={{ padding: "5px 20px" }}
                >
                  <button
                    className="header_button header_button_text add_button_adjustment"
                    style={{ width: "auto", marginTop: "17px" }}
                    onClick={() =>
                      this.ContractApproveHandler("Pending Approval")
                    }
                  >
                    Send for Approval
                  </button>
                </div>
              )}
              {this.state.status === "Pending Approval" &&
                (this.state.features?.includes("21") ||
                  this.state.features?.includes("97")) &&
                this.state.created_by !==
                  this.Cookie.getCookie("loginUserId") && (
                  <div
                    className="col-lg-3 text-right float-sm-left"
                    style={{ padding: "5px 20px" }}
                  >
                    <button
                      className="header_button header_button_text add_button_adjustment"
                      style={{ width: "auto", marginTop: "17px" }}
                      onClick={() => this.ContractApproveHandler("Approved")}
                    >
                      Approve
                    </button>
                  </div>
                )}
              <div className="col-sm-2 text-right float-sm-left">
                <span
                  className="mr-1"
                  data-toggle="tooltip"
                  title="Action"
                  data-placement="bottom"
                >
                  <DashboardIcon
                    style={{
                      fontSize: 30,
                      marginTop: 10,
                      color: "#138496",
                      cursor: "pointer",
                    }}
                    onClick={this.addButtonOpen}
                  />
                </span>
              </div>
            </div>
          </div>
          {this.state.isLoading && <Loader />}
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <Drawer
            anchor="right"
            open={this.state.openDocumentsDrawer}
            variant="temporary"
            elevation={20}
            style={{ overflow: "initial" }}
          >
            <div className="row p-0" style={{ width: 800 }}>
              {drawerComponent}
            </div>
          </Drawer>
          {!this.state.isLoading && (
            <div className="container p-3" id="pdf">
              <div className="col-lg card" style={{ overflowY: "scroll" }}>
                <div className="row">
                  <div className="table-responsive">
                    <table className="table table-bordered table-sm">
                      {/* <tr>
                        <th className="text-center" colSpan={5}>
                          Contract Details
                        </th>
                      </tr> */}
                      <tr className="table-header">
                        <th>Customer</th>
                        <th>Buyer Reference No</th>
                        <th>Contract Date</th>
                        <th>Currency</th>
                        <th>Laycan</th>
                        {this.state.addendums.length > 0 && <th>Addendums</th>}
                      </tr>
                      <tr>
                        <td rowSpan={this.state.addendums.length}>
                          <a
                            href={"/view-customer/" + btoa(this.state.customer)}
                            rel="noopener noreferrer"
                            role="button"
                            style={{ color: config.themeColor }}
                          >
                            {this.state.customer_name}
                          </a>
                        </td>
                        <td rowSpan={this.state.addendums.length}>
                          {this.state.buyer_ref_no
                            ? this.state.buyer_ref_no
                            : "-"}
                        </td>
                        <td rowSpan={this.state.addendums.length}>
                          {localDateFormate(this.state.contract_date)}
                        </td>
                        <td rowSpan={this.state.addendums.length}>
                          {this.state.currency ? this.state.currency : "-"}{" "}
                        </td>
                        <td>
                          {(this.state.laycan_1 ||
                            this.state.laycan_quantity_1) && (
                            <div>
                              {this.state.laycan_1} -{" "}
                              {this.toLocaleString(
                                this.state.laycan_quantity_1
                              )}
                            </div>
                          )}
                          {(this.state.laycan_2 ||
                            this.state.laycan_quantity_2) && (
                            <div>
                              {this.state.laycan_2} -{" "}
                              {this.toLocaleString(
                                this.state.laycan_quantity_2
                              )}
                            </div>
                          )}
                          {(this.state.laycan_3 ||
                            this.state.laycan_quantity_3) && (
                            <div>
                              {this.state.laycan_3} -{" "}
                              {this.toLocaleString(
                                this.state.laycan_quantity_3
                              )}
                            </div>
                          )}
                          {(this.state.laycan_4 ||
                            this.state.laycan_quantity_4) && (
                            <div>
                              {this.state.laycan_4} -{" "}
                              {this.toLocaleString(
                                this.state.laycan_quantity_4
                              )}
                            </div>
                          )}
                          {(this.state.laycan_5 ||
                            this.state.laycan_quantity_5) && (
                            <div>
                              {this.state.laycan_5} -{" "}
                              {this.toLocaleString(
                                this.state.laycan_quantity_5
                              )}
                            </div>
                          )}
                        </td>
                        {this.state.addendums.length > 0 && (
                          <td>
                            {this.state.addendums?.map((e, idx) => (
                              <div>
                                <a
                                  rel="noopener noreferrer"
                                  href={
                                    "/sales-contract-view-addendum/" +
                                    btoa(e.addendum_contract_id)
                                  }
                                  target="_blank"
                                  style={{ color: config.themeColor }}
                                >
                                  {e.addendum_contract_no}
                                </a>
                              </div>
                            ))}
                          </td>
                        )}
                      </tr>
                    </table>
                  </div>
                </div>

                <QualityView
                  contractType={"Sales"}
                  qualities={this.state.qualities}
                  callbackFn={() => {}}
                />

                <table className="table table-bordered table-sm">
                  <tr>
                    <th className="table-header">Quality Adjustment</th>
                    <th className="table-header">Shipping Terms</th>
                  </tr>
                  <tr>
                    <td>
                      {this.state.payment_adjustment
                        ? this.state.payment_adjustment
                        : "-"}
                    </td>
                    <td>
                      {this.state.shipping_terms
                        ? this.state.shipping_terms
                        : "-"}
                    </td>
                  </tr>
                </table>
                <div className="row">
                  <div className="table-responsive">
                    <table className="table table-bordered table-sm">
                      {/* <tr>
                        <th className="text-center" colSpan={4}>
                          Surveyor Details
                        </th>
                      </tr> */}

                      <tr className="table-header">
                        <th></th>
                        <th>Supplier</th>
                        <th>{config.company} Surveyor</th>
                        <th>Buyer/Additional </th>
                      </tr>
                      <tr>
                        <th>Surveyor Name</th>
                        <td>
                          {this.state.supplier_surveyor_names
                            ? this.state.supplier_surveyor_names
                            : "-"}
                        </td>
                        <td>
                          {this.state.internal_surveyor_names
                            ? this.state.internal_surveyor_names
                            : "-"}
                        </td>
                        <td>
                          {this.state.internal_surveyor_analysis_type
                            ? this.state.internal_surveyor_analysis_type
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <th>Analysis Type</th>
                        <td>
                          {this.state.supplier_surveyor_analysis_type
                            ? this.state.supplier_surveyor_analysis_type
                            : "-"}
                        </td>
                        <td>
                          {this.state.internal_surveyor_analysis_type
                            ? this.state.internal_surveyor_analysis_type
                            : "-"}
                        </td>
                        <td>
                          {this.state.joint_surveyor_analysis_type
                            ? this.state.joint_surveyor_analysis_type
                            : "-"}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>

                <div className="row">
                  <div className="table-responsive">
                    <table className="table table-bordered table-sm">
                      <tr className="table-header">
                        <th>Vessel Type</th>
                        <th>Loading Rate for Geared</th>
                        <th>Loading Rate for Gearless</th>
                        <th> Port of Loading </th>

                        {(this.state.discharge_rate_1 ||
                          this.state.port_of_discharge_1 ||
                          this.state.discharge_rate_2 ||
                          this.state.port_of_discharge_2 ||
                          this.state.discharge_rate_3 ||
                          this.state.port_of_discharge_3) &&
                          this.state.dischargeRateField && (
                            <th>Port of Discharge</th>
                          )}
                      </tr>
                      <tr>
                        <td>
                          {this.state.vessel_type
                            ? this.state.vessel_type
                            : "-"}
                        </td>
                        <td>
                          {this.state.loading_rate_for_geared
                            ? this.state.loading_rate_for_geared
                            : "-"}
                        </td>
                        <td>
                          {this.state.loading_rate_for_gearless
                            ? this.state.loading_rate_for_gearless
                            : "-"}
                        </td>
                        <td>
                          {this.state.port_of_loading
                            ? this.state.port_of_loading
                            : "-"}
                        </td>
                        {(this.state.discharge_rate_1 ||
                          this.state.port_of_discharge_2 ||
                          this.state.discharge_rate_2 ||
                          this.state.port_of_discharge_2 ||
                          this.state.discharge_rate_3 ||
                          this.state.port_of_discharge_3) &&
                          this.state.dischargeRateField && (
                            <td>
                              <div>
                                {this.state.discharge_rate_1}-
                                {this.state.port_of_discharge_1}
                              </div>

                              <div>
                                {this.state.discharge_rate_2}-
                                {this.state.port_of_discharge_2}
                              </div>

                              <div>
                                {this.state.discharge_rate_3}-
                                {this.state.port_of_discharge_3}
                              </div>
                            </td>
                          )}
                      </tr>
                    </table>
                  </div>
                </div>

                <div className="row">
                  <div className="table-responsive">
                    <table className="table table-bordered table-sm">
                      <thead className="table-header">
                        <tr>
                          <th nowrap="true">Payment Terms 1</th>
                          <th nowrap="true">Payment Terms 2</th>
                          <th nowrap="true">Remarks</th>
                          <th nowrap="true">Share Point Document Link</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td
                            nowrap="true"
                            className="text-uppercase"
                            style={{ whiteSpace: "pre-line" }}
                          >
                            {this.state.payment_terms_1
                              ? this.state.payment_terms_1
                              : "-"}
                          </td>
                          <td
                            nowrap="true"
                            className="text-uppercase"
                            style={{ whiteSpace: "pre-line" }}
                          >
                            {this.state.payment_terms_2
                              ? this.state.payment_terms_2
                              : "-"}
                          </td>
                          <td
                            nowrap="true"
                            className="text-uppercase"
                            style={{ whiteSpace: "pre-line" }}
                          >
                            {this.state.remarks ? this.state.remarks : "-"}
                          </td>
                          <td>
                            {this.state.share_point_doc_link ? (
                              <div
                                className="contract_display_header_value"
                                style={{
                                  textTransform: "uppercase",
                                  whiteSpace: "pre-line",
                                }}
                              >
                                <a
                                  rel="noopener noreferrer"
                                  href={this.onLinkClick(
                                    this.state.share_point_doc_link
                                  )}
                                  target="_blank"
                                  style={{ color: config.themeColor }}
                                >
                                  {this.state.share_point_doc_link}
                                </a>
                              </div>
                            ) : (
                              <div className="contract_display_header_value">
                                {"-"}
                              </div>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                {(this.state.features.includes("327") ||
                  this.state.features.includes("97")) && (
                  <div className="card border mb-2">
                    <div className="row">
                      <span
                        className="col-lg-12 contract_display_header_value pl-0 mb-2"
                        style={{ fontWeight: "bold" }}
                      >
                        Uploaded Documents
                      </span>
                      {this.state.files.length > 0 ? (
                        <div className="row">
                          {this.state.files.map((e, index) => (
                            <div
                              className={
                                e.file_path ? "col-lg-12 mb-3" : "col-lg-12"
                              }
                              key={index}
                            >
                              {e.file_path && (
                                <div className="col-lg-12 p-0">
                                  <a
                                    href={config.apiDomain + "/" + e.file_path}
                                    role="button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: config.themeColor }}
                                  >
                                    {e.file_description}
                                  </a>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="row col-lg-12">
                          <p>No files to show</p>
                        </div>
                      )}
                      <p className="row col-lg-12">
                        Add Documents &nbsp;
                        <i
                          className="fa fa-plus-circle"
                          style={{
                            color: config.themeColor,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            this.setState({
                              openDocumentsDrawer: true,
                              addDocuments: true,
                            });
                          }}
                        />
                      </p>
                    </div>
                  </div>
                )}

                {(this.state.businessNoData.length > 0 ||
                  this.state.unassigned_partial_quantity_data.length > 0 ||
                  this.state.unassigned_business_no_data.lenght > 0) && (
                  <div className="card p-0 border mb-3">
                    <div className="card-header section_header">
                      <h5>Associated Business Numbers</h5>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive border-0">
                        <table className="table table-borderless  border-0">
                          <thead>
                            <tr>
                              <th className="table_header_barge_label text-left border-0">
                                Business No
                              </th>
                              <th className="table_header_barge_label text-left border-0">
                                Quality
                              </th>
                              <th className="table_header_barge_label text-right border-0">
                                Contract Quantity
                              </th>
                              <th className="table_header_barge_label text-right border-0">
                                Quantity Allocated
                              </th>
                              <th className="table_header_barge_label text-right border-0">
                                Quantity Delivered
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.businessNoData.map((e, index) => (
                              <tr key={index}>
                                <td className="text-left">
                                  <a
                                    href={
                                      "/view-business/" + btoa(e.business_no_id)
                                    }
                                    rel="noopener noreferrer"
                                    role="button"
                                    style={{ color: config.themeColor }}
                                  >
                                    {e.business_no}{" "}
                                    {e.mother_vessel_name
                                      ? "( " + e.mother_vessel_name + " )"
                                      : ""}
                                  </a>
                                </td>
                                <td className="text-left">{e.quality}</td>
                                <td className="text-right">
                                  {e.contract_quantity
                                    ? this.toLocaleString(e.contract_quantity)
                                    : ""}
                                </td>
                                <td className="text-right">
                                  {e.allocated_quantity
                                    ? this.toLocaleString(e.allocated_quantity)
                                    : ""}
                                </td>
                                <td className="text-right">
                                  {e.delivered_quantity
                                    ? this.toLocaleString(e.delivered_quantity)
                                    : ""}
                                </td>
                              </tr>
                            ))}
                            {this.state.unassigned_partial_quantity_data.map(
                              (e, index) => (
                                <tr key={index}>
                                  <td className="text-left">
                                    Unassigned Partial Quantity
                                  </td>
                                  <td className="text-left">{e.quality}</td>
                                  <td className="text-right">
                                    {e.contract_quantity
                                      ? this.toLocaleString(e.contract_quantity)
                                      : ""}
                                  </td>
                                  {/* <td className='text-right'>{e.allocated_quantity ? this.toLocaleString(e.allocated_quantity) : ""}</td> */}
                                  <td className="text-right">-</td>
                                  <td className="text-right">
                                    {e.remaining_partial_quantity
                                      ? this.toLocaleString(
                                          e.remaining_partial_quantity
                                        )
                                      : 0}
                                  </td>
                                </tr>
                              )
                            )}
                            {this.state.unassigned_business_no_data.map(
                              (e, index) => (
                                <tr key={index}>
                                  <td className="text-left">Unassigned</td>
                                  <td className="text-left">{e.quality}</td>
                                  <td className="text-right">
                                    {e.contract_quantity
                                      ? this.toLocaleString(e.contract_quantity)
                                      : ""}
                                  </td>
                                  {/* <td className='text-right'>{e.allocated_quantity ? this.toLocaleString(e.allocated_quantity) : ""}</td> */}
                                  <td className="text-right">-</td>
                                  <td className="text-right">
                                    {e.barge_quantity
                                      ? this.toLocaleString(e.barge_quantity)
                                      : 0}
                                  </td>
                                </tr>
                              )
                            )}
                            {(this.state.businessNoData.length > 0 ||
                              this.state.unassigned_business_no_data.length >
                                0 ||
                              this.state.unassigned_partial_quantity_data
                                .length > 0) && (
                              <tr>
                                <td colSpan={3}></td>
                                <td
                                  className="text-right"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Total :{" "}
                                  {this.state.sumOfAllocated_quantity
                                    ? this.toLocaleString(
                                        this.state.sumOfAllocated_quantity
                                      )
                                    : 0}
                                </td>
                                <td
                                  className="text-right"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Total :{" "}
                                  {this.state.sumOfDelivered_quantity
                                    ? this.toLocaleString(
                                        this.state.sumOfDelivered_quantity
                                      )
                                    : 0}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}

                {(this.state.features.includes("108") ||
                  this.state.features.includes("97")) && (
                  <div className="card p-0 border mb-3">
                    <div className="card-header section_header">
                      <h5>Customer Advance Payments</h5>
                    </div>
                    <div className="card-body">
                      <AdvancePaymentPostings
                        patmentsData={this.state.sales_contract_invoices}
                        // costingType={"Purchase Contract Advance Payments"}
                        // paymentApprType={"pc_adv_payments_data"}
                        paymentsHandler={(paymentMode, data) => {
                          if (paymentMode === "ADD") {
                            this.AddPaymentPostingHandler(data);
                          } else {
                            this.UpdatePaymentPostingHandler(data);
                          }
                        }}
                        invoicesHandler={(invoiceMode, data) => {
                          if (invoiceMode === "ADD") {
                            this.AddInvoicesHandler(data);
                          } else {
                            this.UpdateInvoicesHandler(data);
                          }
                        }}
                      />
                    </div>
                  </div>
                )}

                {this.state.sales_adv_contract.length > 0 && (
                  <div className="card p-0 border mb-3">
                    <div className="card-header section_header">
                      <h5>Advance Adjustments:</h5>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table">
                          <thead className="table-header">
                            <tr>
                              <th className="table_header_barge_label">
                                Business No
                              </th>
                              <th
                                className="table_header_barge_label"
                                style={{ textAlign: "right" }}
                              >
                                Advance Adjustment
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.sales_adv_contract.map((e, index) => (
                              <tr key={index}>
                                {/* <td className='table_td'><a href={'/view-business/' + btoa(e.business_number_id)}  rel="noopener noreferrer" role="button">{e.business_no}</a></td> */}
                                <td>
                                  <a
                                    href={
                                      "/view-business/" +
                                      btoa(e.business_number_id)
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    role="button"
                                    style={{ color: config.themeColor }}
                                  >
                                    {e.business_no ? e.business_no : ""}
                                    {e.mother_vessel_name
                                      ? " (" + e.mother_vessel_name + ")"
                                      : e.barge_name
                                      ? " (" + e.barge_name + ")"
                                      : ""}{" "}
                                    {e.barge_nomination
                                      ? "- " + e.barge_nomination
                                      : ""}{" "}
                                  </a>
                                </td>
                                <td className="text-right">
                                  {e.advance_adjustment
                                    ? this.toLocaleString(e.advance_adjustment)
                                    : ""}
                                </td>
                              </tr>
                            ))}
                            {this.state.sales_adv_contract.length > 0 && (
                              <tr>
                                <td></td>
                                <td
                                  className="text-right"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Total :{" "}
                                  {this.state.sumOfAdvance_adjustment
                                    ? this.toLocaleString(
                                        this.state.sumOfAdvance_adjustment
                                      )
                                    : ""}
                                </td>
                              </tr>
                            )}
                            {this.state.sales_adv_contract.length > 0 && (
                              <tr>
                                <td></td>
                                <td
                                  className="text-right text-success"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Remaining Advance :{" "}
                                  {this.toLocaleString(
                                    Number(
                                      this.state.total_adv_payments
                                        ? this.state.total_adv_payments
                                        : 0
                                    ) -
                                      Number(
                                        this.state.sumOfAdvance_adjustment
                                          ? this.state.sumOfAdvance_adjustment
                                          : 0
                                      )
                                  )}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="row bottom_buttons_section">
                <button
                  type="button"
                  className="btn previous_button"
                  name="submit"
                  onClick={() =>
                    (window.location.href = "/sales-contract-list")
                  }
                >
                  Back
                </button>
                {(this.state.features.includes("3") ||
                  this.state.features.includes("97")) &&
                  this.state.status !== "Active" && (
                    <button
                      type="button"
                      className="btn next_button"
                      name="submit"
                      onClick={() =>
                        (window.location.href =
                          "/update-sales-contract/" + btoa(salesContractId))
                      }
                    >
                      Edit
                    </button>
                  )}
              </div>
            </div>
          )}

          <div
            className="modal fade"
            id="deletePaymentModal"
            role="dialog"
            tabIndex="-1"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div
                  className="modal-header border-0"
                  style={{ backgroundColor: "#f1f1f1" }}
                >
                  <h6
                    className="modal-title"
                    style={{ border: "none", padding: 0 }}
                  >
                    {" "}
                    <WarningIcon
                      style={{ color: "#e0a51e", marginBottom: "5px" }}
                    />{" "}
                    Are you sure to delete ?
                  </h6>
                </div>
                <div className="modal-footer border-0">
                  <button
                    className="btn btn-danger mr-3"
                    onClick={() =>
                      this.deleteAdvancePayment(this.state.deletePayment)
                    }
                  >
                    Delete
                  </button>
                  <button className="btn btn-secondary" data-dismiss="modal">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="clonedSuccessModal"
            role="dialog"
            tabIndex="-1"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div
                  className="modal-header border-0"
                  style={{ backgroundColor: "#f1f1f1" }}
                >
                  <h6
                    className="modal-title"
                    style={{ border: "none", padding: 0 }}
                  >
                    {" "}
                    Sales Contract Cloned Sucessfully
                  </h6>
                </div>
                <div className="modal-footer border-0">
                  <button
                    className="btn btn-warning"
                    onClick={() =>
                      (window.location.href =
                        "/view-sales-contract/" +
                        btoa(this.state.clonedSC_contract_id))
                    }
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </div>

          <Popover
            open={this.state.addButtonOpen}
            anchorEl={this.state.anchorEn}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            targetOrigin={{ horizontal: "left", vertical: "top" }}
            onClose={this.addButtonClose}
          >
            <div className="card border">
              <MenuItem
                style={{ fontFamily: "Poppins" }}
                onClick={this.cloneHandler}
              >
                <i
                  className="fa fa-clone mr-3"
                  aria-hidden="true"
                  style={{
                    fontSize: 25,
                    color: "#3256a8",
                    opacity: "0.5",
                    margin: "0px 3px",
                    width: "20px",
                  }}
                />
                Clone
              </MenuItem>

              {this.state.sc_status === "Active" && (
                <MenuItem style={{ fontFamily: "Poppins" }}>
                  <i
                    className="fa fa-plus-circle mr-4 ml-0"
                    style={{
                      fontSize: 26,
                      color: "#3256a8",
                      opacity: "0.5",
                      margin: "0px 6px",
                      width: "16px",
                    }}
                  />
                  <a href={"/sales-contract-addendum/" + btoa(salesContractId)}>
                    Create Addendum
                  </a>
                </MenuItem>
              )}
              <MenuItem
                style={{ fontFamily: "Poppins" }}
                onClick={() => this.ContractApproveHandler("Closed")}
              >
                <i
                  className="fa fa-times mr-4 ml-0"
                  style={{
                    fontSize: 26,
                    color: "#3256a8",
                    opacity: "0.5",
                    margin: "0px 6px",
                    width: "16px",
                  }}
                />
                Close
              </MenuItem>
              {this.state.status !== "Active" &&
                (this.state.features.includes("101") ||
                  this.state.features.includes("97")) && (
                  <MenuItem
                    style={{ fontFamily: "Poppins" }}
                    onClick={() => this.ContractApproveHandler("cancelled")}
                  >
                    <i
                      className="fa fa-minus-square-o mr-3"
                      aria-hidden="true"
                      style={{
                        fontSize: 26,
                        color: "#3256a8",
                        opacity: "0.5",
                        margin: "0px 3px",
                        width: "20px",
                      }}
                    />
                    Cancel
                  </MenuItem>
                )}
            </div>
          </Popover>
        </div>
      </div>
    );
  }
}
