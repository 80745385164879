import React, { Component } from "react";
import CookieHandler from "../common/CookieHandler";
import config from "../../config/config";
import api from "../../api/api";

import { Alert } from "@material-ui/lab";
import {
  TextField,
  Tabs,
  Tab,
  Snackbar,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import EventIcon from "@material-ui/icons/Event";

import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { localDateFormate, pad } from "../common/common";
import Loader from "../common/Loader";
import InvoiceForm from "../common/InvoiceForm";
import PaymentPostings from "../common/PaymentPostings";

export default class ViewService extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      service_type: "0",
      mother_vessel_name: "",
      barge_name: "",
      vendor_id: "",
      payable_type: "",
      payable_quantity_in_mt: "",
      payable_price_per_mt: "",
      payable_base_price: "",
      payable_currency: "0",
      payable_pph_percent: 2,
      payable_pph_value: "",
      payable_vat_percent: "",
      payable_vat_value: "",
      payable_total_value: "",
      payable_ctc: "",
      debit_note_no: "",
      payable_remarks: "",

      customer_id: "",
      receivable_type: "",
      receivable_quantity_in_mt: "",
      receivable_price_per_mt: "",
      receivable_base_price: "",
      receivable_currency: "0",
      receivable_pph_percent: 2,
      receivable_pph_value: "",
      receivable_vat_percent: "",
      receivable_vat_value: "",
      receivable_total_value: "",
      receivable_ctc: "",
      credit_note_no: "",
      receivable_remarks: "",
      add_service_type: null,
      activeTabIndex: 0,
      isLoading: true,
      payable_files: [],
      receivable_files: [],
      payable_payments: [],
      receivable_payments: [],
      features: [],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (!(features.includes("316") || features.includes("97"))) {
      window.location.href = "/dashboard";
    }
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();

    const serviceID = this.props.serviceRequestId;

    let payable_paid_amount = 0,
      receivable_paid_amount = 0;
    await api
      .get_services_payments(loginUserID, idToken, serviceID)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.services_payments) {
                const filter_payable_payments = res.services_payments.filter(
                  (e) => e.payment_posting_type === "Payable"
                );
                for (var i of filter_payable_payments) {
                  payable_paid_amount += Number(i.amount);
                }
                const filter_receivable_payments = res.services_payments.filter(
                  (e) => e.payment_posting_type === "Receivable"
                );
                for (var obj of filter_receivable_payments) {
                  receivable_paid_amount += Number(obj.amount);
                }
                this.setState({
                  payable_payments: filter_payable_payments,
                  receivable_payments: filter_receivable_payments,
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                snackBarErrorOpen: true,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });

    await api.get_service(loginUserID, idToken, serviceID).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            if (res.services) {
              if (res.services.length > 0) {
                this.setState({
                  costing_no: res.services[0].costing_no,
                  invoice_number: res.services[0].invoice_no,
                  invoice_date: res.services[0].invoice_date,
                  ppn: res.services[0].ppn,
                  business_no: res.services[0].business_no,
                  barge_id: res.services[0].barge_id,
                  service_type: res.services[0].service_type,
                  service_id: res.services[0].service_id,
                  mother_vessel_name: res.services[0].mother_vessel_name,
                  barge_name: res.services[0].barge_name,
                  vendor_name: res.services[0].vendor_name,
                  vendor_id: res.services[0].vendor_id,
                  payable_type: res.services[0].payable_type,
                  payable_quantity_in_mt:
                    res.services[0].payable_quantity_in_mt,
                  payable_price_per_mt: res.services[0].payable_price_per_mt,
                  payable_base_price: res.services[0].payable_base_price,
                  payable_currency: res.services[0].payable_currency,
                  payable_pph_percent: res.services[0].payable_pph_percent,
                  payable_pph_value: res.services[0].payable_pph_value,
                  payable_vat_percent: res.services[0].payable_vat_percent,
                  payable_vat_value: res.services[0].payable_vat_value,
                  payable_total_value: res.services[0].payable_total_value,
                  payable_ctc: res.services[0].payable_ctc,
                  payable_exchg_rate: res.services[0].payable_exchg_rate,
                  debit_note_no: res.services[0].debit_note_no,
                  payable_remarks: res.services[0].payable_remarks,

                  customer_name: res.services[0].customer_name,
                  customer_id: res.services[0].customer_id,
                  receivable_type: res.services[0].receivable_type,
                  receivable_quantity_in_mt:
                    res.services[0].receivable_quantity_in_mt,
                  receivable_price_per_mt:
                    res.services[0].receivable_price_per_mt,
                  receivable_base_price: res.services[0].receivable_base_price,
                  receivable_currency: res.services[0].receivable_currency,
                  receivable_pph_percent:
                    res.services[0].receivable_pph_percent,
                  receivable_pph_value: res.services[0].receivable_pph_value,
                  receivable_vat_percent:
                    res.services[0].receivable_vat_percent,
                  receivable_vat_value: res.services[0].receivable_vat_value,
                  receivable_total_value:
                    res.services[0].receivable_total_value,
                  receivable_ctc: res.services[0].receivable_ctc,
                  receivable_exchg_rate: res.services[0].receivable_exchg_rate,
                  credit_note_no: res.services[0].credit_note_no,
                  receivable_remarks: res.services[0].receivable_remarks,
                  payable_files: res.services[0].payable_files,
                  receivable_files: res.services[0].receivable_files,
                  payable_remaining_amount:
                    Number(res.services[0].payable_total_value) -
                    Number(payable_paid_amount),
                  payable_paid_amount: payable_paid_amount,
                  receivable_paid_amount: receivable_paid_amount,
                  receivable_remaining_amount:
                    Number(res.services[0].receivable_total_value) -
                    Number(receivable_paid_amount),
                  //payable_exchg_rate: (1/Number(res.services[0].payable_exchg_rate? res.services[0].payable_exchg_rate: 1)),
                  isLoading: false,
                });
              } else {
                this.setState({
                  isLoading: false,
                });
              }
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          } else if (res.code === "601") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
  }
  AddPaymentPostingHandler = () => {
    this.setState({ submitting: true });
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    var {
      payment_posting_type,
      amount,
      date_of_payment,
      invoice_no,
      description,
    } = this.state;
    if (date_of_payment === null) {
      this.setState({
        date_of_paymentError: true,
        submitting: false,
      });
    }
    if (amount === "") {
      this.setState({ amountError: true, submitting: false });
    }
    if (invoice_no === "") {
      this.setState({ invoice_noError: true, submitting: false });
    }
    var remainingAmount = 0;

    // if (payment_posting_type === "Payable") {
    remainingAmount = Number(this.state.RemainingAmount);
    if (this.state.paymentMode === "Update") {
      remainingAmount =
        Number(this.state.RemainingAmount) + Number(this.state.existingAmount);
      // }
      if (Number(amount) > Math.ceil(Number(remainingAmount))) {
        this.setState({
          amountError: true,
          submitting: false,
          paymentErrorMsg:
            payment_posting_type === "Payable"
              ? "Amount is exceeding Pyable value"
              : "Amount is exceeding Receivable value",
        });
        return;
      }
    }

    try {
      var serviceID = this.props.serviceRequestId;
    } catch (e) {
      window.location.href = "/Pagenotfound";
    }
    if (date_of_payment !== null && amount !== "" && invoice_no !== "") {
      api
        .add_services_payment(
          loginUserID,
          idToken,
          serviceID,
          payment_posting_type,
          amount,
          date_of_payment,
          invoice_no,
          description
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                this.setState({
                  submitting: false,
                  date_of_payment: null,
                  invoice_no: "",
                  description: "",
                  date_of_paymentError: false,
                  amountError: false,
                  invoice_noError: false,
                });
                window.$("#paymentPostingsModal").modal("hide");
                this.componentDidMount();
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  snackBarErrorOpen: true,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    } else {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please fill mandatory fileds.",
      });
    }
  };
  getPaymentDetalis = (e) => {
    if (e) {
      this.setState({
        paymentMode: "Update",
        paymentTitle: "Update",
        service_payment_id: e.id,
        date_of_payment: e.date_of_payment,
        amount: e.amount,
        existingAmount: e.amount,
        invoice_no: e.invoice_no,
        payment_posting_type: e.payment_posting_type,
        description: e.description,
      });
      window.$("#paymentPostingsModal").modal({
        backdrop: "static",
        keyboard: true,
        show: true,
      });
    }
  };

  UpdatePaymentPostingsHandler = () => {
    this.setState({ submitting: true });
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    var {
      payment_posting_type,
      amount,
      date_of_payment,
      invoice_no,
      description,
      service_payment_id,
    } = this.state;
    if (date_of_payment === null) {
      this.setState({
        date_of_paymentError: true,
        submitting: false,
      });
    }
    if (amount === "") {
      this.setState({ amountError: true, submitting: false });
    }
    if (invoice_no === "") {
      this.setState({ invoice_noError: true, submitting: false });
    }
    var remainingAmount = 0;

    // if (payment_posting_type === "Payable") {
    remainingAmount = Number(this.state.RemainingAmount);
    if (this.state.paymentMode === "Update") {
      remainingAmount =
        Number(this.state.RemainingAmount) + Number(this.state.existingAmount);
      // }
      if (Number(amount) > Math.ceil(Number(remainingAmount))) {
        this.setState({
          amountError: true,
          submitting: false,
          paymentErrorMsg:
            payment_posting_type === "Payable"
              ? "Amount is exceeding Pyable value"
              : "Amount is exceeding Receivable value",
        });
        return;
      }
    }

    try {
      var serviceID = this.props.serviceRequestId;
    } catch (e) {
      window.location.href = "/Pagenotfound";
    }
    if (
      date_of_payment !== null &&
      amount !== "" &&
      invoice_no !== "" &&
      service_payment_id
    ) {
      api
        .update_services_payment(
          loginUserID,
          idToken,
          serviceID,
          service_payment_id,
          payment_posting_type,
          amount,
          date_of_payment,
          invoice_no,
          description,
          "Active"
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                this.setState({
                  submitting: false,
                  date_of_payment: null,
                  invoice_no: "",
                  description: "",
                  date_of_paymentError: false,
                  amountError: false,
                  invoice_noError: false,
                });
                window.$("#paymentPostingsModal").modal("hide");
                this.componentDidMount();
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  snackBarErrorOpen: true,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    } else {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please fill mandatory fileds.",
      });
    }
  };

  deletePaymentsHandler = (e) => {
    if (e) {
      this.setState({ submitting: true });
      const loginUserID = this.Cookie.getCookie("loginUserId");
      const idToken = this.Cookie.getIdTokenCookie();
      const service_payment_id = e.id;
      const payment_posting_type = e.payment_posting_type;
      const amount = e.amount;
      const date_of_payment = e.date_of_payment;
      const invoice_no = e.invoice_no;
      const description = e.description;
      const serviceID = e.service_id;

      if (date_of_payment !== null && amount !== "" && invoice_no !== "") {
        let status = "Deleted";
        api
          .update_services_payment(
            loginUserID,
            idToken,
            serviceID,
            service_payment_id,
            payment_posting_type,
            amount,
            date_of_payment,
            invoice_no,
            description,
            status
          )
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              response.json().then((res) => {
                if (res.code === "200") {
                  this.setState({
                    submitting: false,
                    date_of_payment: null,
                    invoice_no: "",
                    description: "",
                    date_of_paymentError: false,
                    amountError: false,
                    invoice_noError: false,
                  });
                  window.$("#deleteConformationModal").modal("hide");
                  this.componentDidMount();
                } else if (res.code === "601") {
                  this.setState({
                    errorMsg: res.message,
                    snackBarErrorOpen: true,
                  });
                } else if (res.code === "607") {
                  window.location.href = "/logout";
                }
              });
            }
          });
      } else {
        alert("Please fill mandatory fields.");
      }
    }
  };

  toLocaleString(value) {
    return value ? Number(value).toLocaleString() : 0;
  }

  updateInvoice = (data) => {
    let request = {
      ...data,
      table_name: "services",
    };
    api.update_invoice_details(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.componentDidMount();
            this.setState({
              invoiceDialog: false,
            });
          }
        });
      }
    });
  };

  renderPaymentPostings = (data) => {
    const {
      payable_payments = [],
      invoice_number = null,
      payable_remaining_amount = 0,
      exchange_rate = 1,
      approval_status = "Pending",
    } = data;
    let payment_related_data = {
      paymentTitle: "Service Request",
      payment_posting_type: "Payable",
      invoice_no: invoice_number,
      outstanding_amount: payable_remaining_amount,
      billing_currency:
        config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL" ? "USD" : "IDR",
      currency_xchg_rate: exchange_rate ? exchange_rate : 1,
      costing_id: this.props.serviceRequestId,
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
    };

    let access_details = {
      costing_approval: approval_status,
      add_payment: "319",
      view_payment: "320",
      update_payment: "321",
      delete_payment: "322",
      approver: "598",
      approver2: "599",
    };

    return (
      <PaymentPostings
        access_details={access_details}
        payments={payable_payments}
        costingType="Services Payments"
        data={payment_related_data}
        callbackFn={() => {
          this.componentDidMount();
        }}
      />
    );
  };

  render() {
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div className="container bg-white p-0" style={{ minHeight: "100vh" }}>
          <div className="clearfix content-header">
            <div className="row">
              <div className="col-sm text-left pl-0">
                <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                  Service Request - {this.state.costing_no}
                </h4>
              </div>
            </div>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <div
            className="drawer-section"
            ref={(el) => (this.componentRef = el)}
          >
            <div className="card">
              <div className="row mb-2 ">
                <div className="col-lg-4">
                  <label className="contract_display_header_label mb-1">
                    Service Type
                  </label>
                  <div className="contract_display_header_value">
                    {this.state.service_type}
                  </div>
                </div>
                <div className="col-lg-4">
                  <label className="contract_display_header_label mb-1">
                    Business No
                  </label>
                  <div className="contract_display_header_value">
                    {this.state.business_no ? this.state.business_no : "-"}
                  </div>
                </div>
                <div className="col-lg-4">
                  <label className="contract_display_header_label mb-1">
                    Barge
                  </label>
                  <div className="contract_display_header_value">
                    {this.state.barge_id ? pad(this.state.barge_id) : "-"}
                  </div>
                </div>
              </div>

              <div className="row mb-2">
                <Tabs
                  value={this.state.activeTabIndex}
                  onChange={(event, value) => {
                    this.setState({
                      activeTabIndex: value,
                    });
                  }}
                  indicatorColor="primary"
                  textColor="primary"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                  style={{
                    backgroundColor: "#ffffff",
                    color: "#6c7293",
                    padding: 0,
                  }}
                  TabIndicatorProps={{
                    style: { background: config.themeColor },
                  }}
                  className="border-bottom"
                >
                  <Tab
                    label={
                      <h4
                        style={{
                          color:
                            this.state.activeTabIndex === 0
                              ? config.themeColor
                              : "#6c7293",
                          fontSize: "16px",
                          border: "0px",
                        }}
                      >
                        Payable
                      </h4>
                    }
                    value={0}
                    style={{
                      fontFamily: "Poppins",
                      textTransform: "capitalize",
                      color: "#6c7293",
                    }}
                  />
                  <Tab
                    label={
                      <h4
                        style={{
                          color:
                            this.state.activeTabIndex === 1
                              ? config.themeColor
                              : "#6c7293",
                          fontSize: "16px",
                          border: "0px",
                        }}
                      >
                        Receivable
                      </h4>
                    }
                    value={1}
                    style={{
                      fontFamily: "Poppins",
                      textTransform: "capitalize",
                      color: "#6c7293",
                    }}
                  />
                </Tabs>
              </div>
              {this.state.activeTabIndex === 0 && (
                <div className="card border_dashed mb-3">
                  <div className="row mb-3">
                    <div className="col-lg-5 p-2">
                      <div className="row">
                        <div className="col-lg-12 p-0 mb-2">
                          <label className="contract_display_header_label mb-1">
                            Vendor
                          </label>
                          <div className="contract_display_header_value">
                            {this.state.vendor_name
                              ? this.state.vendor_name
                              : "-"}
                          </div>
                        </div>
                        <div className="col-lg-12 p-0 mb-2">
                          <label className="contract_display_header_label mb-1">
                            Type
                          </label>
                          <div className="contract_display_header_value">
                            {this.state.payable_type}
                          </div>
                        </div>
                        <div className="col-lg-12 p-0 mb-2">
                          <label className="contract_display_header_label mb-1">
                            Debit Note No
                          </label>
                          <div className="contract_display_header_value">
                            {this.state.debit_note_no
                              ? this.state.debit_note_no
                              : "-"}
                          </div>
                        </div>
                        <div className="col-lg-12 p-0 mb-2">
                          <label className="contract_display_header_label mb-1">
                            Remarks
                          </label>
                          <div
                            className="contract_display_header_value"
                            style={{ whiteSpace: "pre-line" }}
                          >
                            {this.state.payable_remarks
                              ? this.state.payable_remarks
                              : "-"}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-lg p-2 rounded"
                      style={{ backgroundColor: "#f7f3f0" }}
                    >
                      <div className="row col-lg-12 p-1">
                        <h6>Payable</h6>
                      </div>
                      {this.state.payable_type === "Per MT" && (
                        <div className="row">
                          <div className="col-lg-4 mb-2">
                            <label className="contract_display_header_label">
                              Quantity in MT
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ color: "#42352d" }}
                            >
                              {this.toLocaleString(
                                this.state.payable_quantity_in_mt
                              )}
                            </div>
                          </div>
                          <div className="col-lg-4 mb-2">
                            <label className="contract_display_header_label">
                              Base Price per MT
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ color: "#42352d" }}
                            >
                              {this.toLocaleString(
                                this.state.payable_price_per_mt
                              )}{" "}
                              {this.state.payable_currency}
                            </div>
                          </div>
                          <div className="col-lg-4 mb-2">
                            <label
                              className="contract_display_header_label"
                              style={{ color: "rgba(72, 59, 52, 0.7)" }}
                            >
                              Base Price
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ color: "#42352d" }}
                            >
                              {this.toLocaleString(
                                this.state.payable_base_price
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      {this.state.payable_type === "Lumpsum" && (
                        <div className="row">
                          <div className="col-lg-12 mb-2">
                            <label className="contract_display_header_label mb-0">
                              Base Price
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ color: "#42352d" }}
                            >
                              {this.toLocaleString(
                                this.state.payable_base_price
                              )}{" "}
                              {this.state.payable_currency}
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="row">
                        <div className="col-lg-6 mb-2">
                          <label
                            className="contract_display_header_label"
                            style={{ color: "rgba(72, 59, 52, 0.7)" }}
                          >
                            VAT %
                          </label>
                          <div
                            className="contract_display_header_value"
                            style={{ color: "#42352d" }}
                          >
                            {this.state.payable_vat_percent}
                          </div>
                        </div>
                        <div className="col-lg-6 mb-2">
                          <label className="contract_display_header_label">
                            VAT
                          </label>
                          <div
                            className="contract_display_header_value"
                            style={{ color: "#42352d" }}
                          >
                            {this.toLocaleString(this.state.payable_vat_value)}
                          </div>
                        </div>
                      </div>
                      {(config.company === "SDAM" ||
                        config.company === "SRK" ||
                        config.company === "BTR") && (
                        <div className="row">
                          <div className="col-lg-6 mb-2">
                            <label className="contract_display_header_label">
                              PPH %
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ color: "#42352d" }}
                            >
                              {this.toLocaleString(
                                this.state.payable_pph_percent
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6 mb-2">
                            <label className="contract_display_header_label mb-0">
                              PPH
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ color: "#42352d" }}
                            >
                              {this.toLocaleString(
                                this.state.payable_pph_value
                              )}
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="row">
                        <div className="col-lg-6 mb-2">
                          <label
                            className="contract_display_header_label"
                            style={{ color: "rgba(72, 59, 52, 0.7)" }}
                          >
                            Total Payable
                          </label>
                          <div
                            className="contract_display_header_value"
                            style={{ color: "#42352d" }}
                          >
                            {this.toLocaleString(
                              this.state.payable_total_value
                            )}
                          </div>
                        </div>
                        {(config.company === "SDAM" ||
                          config.company === "SRK" ||
                          config.company === "BTR") &&
                          this.state.payable_currency !== "IDR" &&
                          this.state.payable_currency && (
                            <div className="col-lg-6 mb-2">
                              <label className="contract_display_header_label mb-0">
                                Exchange Rate {this.state.payable_currency} to
                                IDR
                              </label>
                              <div
                                className="contract_display_header_value"
                                style={{ color: "#42352d" }}
                              >
                                {this.toLocaleString(
                                  this.state.payable_exchg_rate
                                )}
                              </div>
                            </div>
                          )}
                        {(config.company === "SRPL" || config.company === "AVS"  ||
                          config.company === "PRPL") &&
                          this.state.payable_currency !== "USD" &&
                          this.state.payable_currency !== "0" && (
                            <div className="col-lg-6 mb-2">
                              <label className="contract_display_header_label mb-0">
                                Exchange Rate USD to{" "}
                                {this.state.payable_currency}
                              </label>
                              <div
                                className="contract_display_header_value"
                                style={{ color: "#42352d" }}
                              >
                                {this.toLocaleString(
                                  this.state.payable_exchg_rate
                                )}
                              </div>
                            </div>
                          )}
                        <div className="col-lg-6 mb-2">
                          <label
                            className="contract_display_header_label"
                            style={{ color: "rgba(72, 59, 52, 0.7)" }}
                          >
                            Cost to Company
                          </label>
                          <div
                            className="contract_display_header_value"
                            style={{ color: "#42352d" }}
                          >
                            {this.toLocaleString(this.state.payable_ctc)}{" "}
                            {this.state.payable_currency !== "0" ||
                            this.state.payable_currency
                              ? this.state.payable_currency
                              : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {(this.state.features.includes("323") ||
                    this.state.features.includes("97")) && (
                    <div className="card p-0 border mb-3">
                      <div className="card-header section_header">
                        <h5>Uploaded Files</h5>
                      </div>
                      <div className="card-body">
                        {this.state.payable_files.length > 0 ? (
                          <div>
                            {this.state.payable_files.map((e, index) => (
                              <div className="row mb-3" key={index}>
                                <div className="col-lg-4 p-0">
                                  {/* <label className='contract_display_header_label mb-2'>File Description</label> */}
                                  <div className="contract_display_header_value">
                                    <a
                                      href={
                                        config.apiDomain + "/" + e.file_path
                                      }
                                      role="button"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      style={{ color: config.themeColor }}
                                    >
                                      {e.file_description}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div className="col">No files to show</div>
                        )}
                      </div>
                    </div>
                  )}

                  {(this.state.features.includes("320") ||
                    this.state.features.includes("97")) && (
                    <div className="card p-0 border mb-3">
                      <div className="card-header section_header">
                        <h5>Payment Postings</h5>
                      </div>
                      <div className="card-body">
                        <InvoiceForm
                          open={false}
                          data={{
                            invoice_no: this.state.invoice_number,
                            invoice_date: this.state.invoice_date,
                            ppn: this.state.ppn,
                            ppn_date: this.state.ppn_date,
                          }}
                          costingID={this.props.serviceRequestId}
                          callbackFn={(data) => this.updateInvoice(data)}
                        />
                        <div className="row">
                          <div className="col-lg-4 mb-2">
                            <label className="contract_display_header_label mb-2">
                              Total Payable
                            </label>
                            <div>
                              {this.state.payable_total_value
                                ? this.toLocaleString(
                                    this.state.payable_total_value
                                  )
                                : 0}
                            </div>
                          </div>
                          <div className="col-lg-4 mb-2">
                            <label className="contract_display_header_label mb-2">
                              Total Paid
                            </label>
                            <div>
                              {this.state.payable_paid_amount
                                ? this.toLocaleString(
                                    this.state.payable_paid_amount
                                  )
                                : 0}
                            </div>
                          </div>
                          <div className="col-lg-4 mb-2">
                            <label className="contract_display_header_label mb-2">
                              Remaining Amount
                            </label>
                            <div>
                              {this.state.payable_remaining_amount
                                ? this.toLocaleString(
                                    this.state.payable_remaining_amount
                                  )
                                : 0}
                            </div>
                          </div>
                        </div>
                        {this.renderPaymentPostings(this.state)}

                        {/* <div className="row">
                          <div className="col">
                            <div className="clearfix">
                              <div className="float-left p-2">
                                {!this.state.payable_payments
                                  ? ""
                                  : this.state.payable_payments.length <= 0 && (
                                      <p>No payments made</p>
                                    )}
                              </div>
                              <div className="float-right">
                                {(this.state.features.includes("319") ||
                                  this.state.features.includes("97")) && (
                                  <button
                                    className="btn payment_button"
                                    type="button"
                                    data-toggle="modal"
                                    data-placement="bottom"
                                    data-keyboard="false"
                                    data-target="#paymentPostingsModal"
                                    data-backdrop="static"
                                    onClick={() => {
                                      this.setState({
                                        paymentTitle: "Add",
                                        payment_posting_type: "Payable",
                                        RemainingAmount:
                                          this.state.payable_remaining_amount,
                                        date_of_payment: null,
                                        amount: "",
                                        invoice_no: "",
                                        description: "",
                                        paymentErrorMsg: "",
                                        date_of_paymentError: false,
                                        amountError: false,
                                        paymentMode: "Add",
                                        invoice_noError: false,
                                      });
                                    }}
                                  >
                                    Add Payment
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        {!this.state.payable_payments
                          ? ""
                          : this.state.payable_payments.length > 0 && (
                              <div className="row mt-2">
                                <div className="col-lg">
                                  <div className="table-responsive border-0">
                                    <table className="table table-borderless border-0">
                                      <thead>
                                        <tr>
                                          <th
                                            nowrap="true"
                                            className="table_header_barge_label text-left border-0"
                                          >
                                            Date of Payment
                                          </th>
                                          <th
                                            nowrap="true"
                                            className="table_header_barge_label text-right border-0"
                                          >
                                            Amount
                                          </th>
                                          <th
                                            nowrap="true"
                                            className="table_header_barge_label text-left border-0"
                                          >
                                            Invoice No
                                          </th>
                                          <th
                                            className="table_header_barge_label text-left border-0"
                                            style={{
                                              width: 300,
                                              maxWidth: 250,
                                            }}
                                          >
                                            Description
                                          </th>
                                          <th
                                            nowrap="true"
                                            className="table_header_barge_label text-left border-0"
                                          >
                                            Created Date
                                          </th>
                                          <th
                                            nowrap="true"
                                            className="table_header_barge_label text-left border-0"
                                          >
                                            Action
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {this.state.payable_payments.map(
                                          (p, index) => (
                                            <tr key={index}>
                                              <td
                                                nowrap="true"
                                                className=" text-left"
                                              >
                                                {localDateFormate(
                                                  p.date_of_payment
                                                )}
                                              </td>
                                              <td
                                                nowrap="true"
                                                className=" text-right"
                                              >
                                                {p.amount
                                                  ? this.toLocaleString(
                                                      p.amount
                                                    )
                                                  : ""}
                                              </td>
                                              <td
                                                nowrap="true"
                                                className=" text-left"
                                              >
                                                {p.invoice_no}
                                              </td>
                                              <td
                                                className=" text-left"
                                                style={{
                                                  whiteSpace: "pre-line",
                                                }}
                                              >
                                                {p.description}
                                              </td>
                                              <td
                                                nowrap="true"
                                                className=" text-left"
                                              >
                                                {localDateFormate(
                                                  p.created_date
                                                )}
                                              </td>
                                              <td
                                                nowrap="true"
                                                className=" text-left"
                                              >
                                                {(this.state.features.includes(
                                                  "321"
                                                ) ||
                                                  this.state.features.includes(
                                                    "97"
                                                  )) && (
                                                  <i
                                                    className="fa fa-pencil edit_icon mr-2"
                                                    style={{
                                                      fontSize: 20,
                                                      color: config.themeColor,
                                                      cursor: "pointer",
                                                    }}
                                                    data-toggle="modal"
                                                    title="Edit Coal Payment Posting"
                                                    data-placement="bottom"
                                                    onClick={() => {
                                                      this.setState({
                                                        RemainingAmount:
                                                          this.state
                                                            .payable_remaining_amount,
                                                      });
                                                      this.getPaymentDetalis(p);
                                                    }}
                                                  ></i>
                                                )}

                                                {(this.state.features.includes(
                                                  "322"
                                                ) ||
                                                  this.state.features.includes(
                                                    "97"
                                                  )) && (
                                                  <span>
                                                    <i
                                                      className="fa fa-trash-o"
                                                      style={{
                                                        fontSize: 20,
                                                        color: "red",
                                                        cursor: "pointer",
                                                      }}
                                                      data-toggle="modal"
                                                      title="Delete Coal Payment Posting"
                                                      data-placement="bottom"
                                                      data-keyboard="false"
                                                      data-target="#deleteConformationModal"
                                                      data-backdrop="static"
                                                      onClick={() =>
                                                        this.setState({
                                                          deletePaymentData: p,
                                                        })
                                                      }
                                                    ></i>
                                                  </span>
                                                )}
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            )} */}
                      </div>
                    </div>
                  )}
                </div>
              )}
              {this.state.activeTabIndex === 1 && (
                <div className="card border_dashed mb-3">
                  <div className="row mb-3">
                    <div className="col-lg-5 p-2">
                      <div className="row">
                        <div className="col-lg-12 p-0 mb-2">
                          <label className="contract_display_header_label mb-1">
                            Customer
                          </label>
                          <div className="contract_display_header_value">
                            {this.state.customer_name
                              ? this.state.customer_name
                              : "-"}
                          </div>
                        </div>
                        <div className="col-lg-12 p-0 mb-2">
                          <label className="contract_display_header_label mb-1">
                            Type
                          </label>
                          <div className="contract_display_header_value">
                            {this.state.receivable_type
                              ? this.state.receivable_type
                              : "-"}
                          </div>
                        </div>
                        <div className="col-lg-12 p-0 mb-2">
                          <label className="contract_display_header_label mb-1">
                            Credit Note No
                          </label>
                          <div className="contract_display_header_value">
                            {this.state.credit_note_no
                              ? this.state.credit_note_no
                              : "-"}
                          </div>
                        </div>
                        <div className="col-lg-12 p-0 mb-2">
                          <label className="contract_display_header_label mb-1">
                            Remarks
                          </label>
                          <div
                            className="contract_display_header_value"
                            style={{ whiteSpace: "pre-line" }}
                          >
                            {this.state.receivable_remarks
                              ? this.state.receivable_remarks
                              : "-"}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-lg p-2 rounded"
                      style={{ backgroundColor: "#f7f3f0" }}
                    >
                      <div className="row col-lg-12 p-1">
                        <h6>Receivable</h6>
                      </div>
                      {this.state.receivable_type === "Per MT" && (
                        <div className="row">
                          <div className="col-lg-4 mb-2">
                            <label className="contract_display_header_label">
                              Quantity in MT
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ color: "#42352d" }}
                            >
                              {this.toLocaleString(
                                this.state.receivable_quantity_in_mt
                              )}
                            </div>
                          </div>
                          <div className="col-lg-4 mb-2">
                            <label className="contract_display_header_label">
                              Base Price per MT
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ color: "#42352d" }}
                            >
                              {this.toLocaleString(
                                this.state.receivable_price_per_mt
                              )}{" "}
                              {this.state.receivable_currency}
                            </div>
                          </div>
                          <div className="col-lg-4 mb-2">
                            <label
                              className="contract_display_header_label"
                              style={{ color: "rgba(72, 59, 52, 0.7)" }}
                            >
                              Base Price
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ color: "#42352d" }}
                            >
                              {this.toLocaleString(
                                this.state.receivable_base_price
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      {this.state.receivable_type === "Lumpsum" && (
                        <div className="row">
                          <div className="col-lg-12 mb-2">
                            <label className="contract_display_header_label">
                              Base Price
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ color: "#42352d" }}
                            >
                              {this.toLocaleString(
                                this.state.receivable_base_price
                              )}{" "}
                              {this.state.receivable_currency}
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="row">
                        <div className="col-lg-6 mb-2">
                          <label
                            className="contract_display_header_label"
                            style={{ color: "rgba(72, 59, 52, 0.7)" }}
                          >
                            VAT %
                          </label>
                          <div
                            className="contract_display_header_value"
                            style={{ color: "#42352d" }}
                          >
                            {this.state.receivable_vat_percent}
                          </div>
                        </div>
                        <div className="col-lg-6 mb-2">
                          <label className="contract_display_header_label">
                            VAT
                          </label>
                          <div
                            className="contract_display_header_value"
                            style={{ color: "#42352d" }}
                          >
                            {this.toLocaleString(
                              this.state.receivable_vat_value
                            )}
                          </div>
                        </div>
                      </div>
                      {(config.company === "SDAM" ||
                        config.company === "SRK" ||
                        config.company === "BTR") && (
                        <div className="row">
                          <div className="col-lg-6 mb-2">
                            <label className="contract_display_header_label">
                              PPH %
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ color: "#42352d" }}
                            >
                              {this.toLocaleString(
                                this.state.receivable_pph_percent
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6 mb-2">
                            <label className="contract_display_header_label">
                              PPH
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ color: "#42352d" }}
                            >
                              {this.toLocaleString(
                                this.state.receivable_pph_value
                              )}
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="row">
                        <div className="col-lg-6 mb-2">
                          <label
                            className="contract_display_header_label"
                            style={{ color: "rgba(72, 59, 52, 0.7)" }}
                          >
                            Total Receivable
                          </label>
                          <div
                            className="contract_display_header_value"
                            style={{ color: "#42352d" }}
                          >
                            {this.toLocaleString(
                              this.state.receivable_total_value
                            )}
                          </div>
                        </div>
                        {(config.company === "SDAM" ||
                          config.company === "SRK" ||
                          config.company === "BTR") &&
                          this.state.receivable_currency !== "IDR" &&
                          this.state.receivable_currency && (
                            <div className="col-lg-162 mb-2">
                              <label className="contract_display_header_label mb-0">
                                Exchange Rate {this.state.receivable_currency}{" "}
                                to IDR
                              </label>
                              <div
                                className="contract_display_header_value"
                                style={{ color: "#42352d" }}
                              >
                                {this.toLocaleString(
                                  this.state.receivable_exchg_rate
                                )}
                              </div>
                            </div>
                          )}
                        {(config.company === "SRPL" || config.company === "AVS"  ||
                          config.company === "PRPL") &&
                          this.state.receivable_currency !== "USD" &&
                          this.state.receivable_currency && (
                            <div className="col-lg-6 mb-2">
                              <label className="contract_display_header_label mb-0">
                                Exchange Rate USD to{" "}
                                {this.state.payable_currency}
                              </label>
                              <div
                                className="contract_display_header_value"
                                style={{ color: "#42352d" }}
                              >
                                {this.toLocaleString(
                                  this.state.receivable_exchg_rate
                                )}
                              </div>
                            </div>
                          )}
                        <div className="col-lg-6 mb-2">
                          <label
                            className="contract_display_header_label"
                            style={{ color: "rgba(72, 59, 52, 0.7)" }}
                          >
                            Cost to Company
                          </label>
                          <div
                            className="contract_display_header_value"
                            style={{ color: "#42352d" }}
                          >
                            {this.toLocaleString(this.state.receivable_ctc)}{" "}
                            {this.state.receivable_currency !== "0" ||
                            this.state.receivable_currency
                              ? this.state.receivable_currency
                              : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {(this.state.features.includes("323") ||
                    this.state.features.includes("97")) && (
                    <div className="card p-0 border mb-3">
                      <div className="card-header section_header">
                        <h5>Uploaded Files</h5>
                      </div>
                      <div className="card-body">
                        {this.state.receivable_files.length > 0 ? (
                          <div>
                            {this.state.receivable_files.map((e, index) => (
                              <div className="row mb-3" key={index}>
                                <div className="col-lg-4 p-0">
                                  {/* <label className='contract_display_header_label mb-2'>File Description</label> */}
                                  <div className="contract_display_header_value">
                                    <a
                                      href={
                                        config.apiDomain + "/" + e.file_path
                                      }
                                      role="button"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      style={{ color: config.themeColor }}
                                    >
                                      {e.file_description}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div className="col-lg-12">No files to show</div>
                        )}
                      </div>
                    </div>
                  )}

                  {(this.state.features.includes("320") ||
                    this.state.features.includes("97")) && (
                    <div className="card p-0 border mb-3">
                      <div className="card-header section_header">
                        <h5>Payment Postings</h5>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-lg-3 mb-2">
                            <label className="contract_display_header_label mb-2">
                              Total Receivable
                            </label>
                            <div>
                              {this.state.receivable_total_value
                                ? this.toLocaleString(
                                    this.state.receivable_total_value
                                  )
                                : 0}
                            </div>
                          </div>
                          <div className="col-lg-3  mb-2">
                            <label className="contract_display_header_label mb-2">
                              Total Received
                            </label>
                            <div>
                              {this.state.receivable_paid_amount
                                ? this.toLocaleString(
                                    this.state.receivable_paid_amount
                                  )
                                : 0}
                            </div>
                          </div>
                          <div className="col-lg-3 mb-2">
                            <label className="contract_display_header_label mb-2">
                              Remaining Amount
                            </label>
                            <div>
                              {this.state.receivable_remaining_amount
                                ? this.toLocaleString(
                                    this.state.receivable_remaining_amount
                                  )
                                : 0}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <div className="clearfix">
                              <div className="float-left p-2">
                                {!this.state.receivable_payments
                                  ? ""
                                  : this.state.receivable_payments.length <=
                                      0 && <p>No payments Received</p>}
                              </div>
                              <div className="float-right">
                                {(this.state.features.includes("319") ||
                                  this.state.features.includes("97")) && (
                                  <button
                                    className="btn payment_button"
                                    type="button"
                                    data-toggle="modal"
                                    data-placement="bottom"
                                    data-keyboard="false"
                                    data-target="#paymentPostingsModal"
                                    data-backdrop="static"
                                    onClick={() => {
                                      this.setState({
                                        paymentTitle: "Add",
                                        payment_posting_type: "Receivable",
                                        RemainingAmount:
                                          this.state
                                            .receivable_remaining_amount,
                                        date_of_payment: null,
                                        amount: "",
                                        invoice_no: "",
                                        description: "",
                                        paymentErrorMsg: "",
                                        date_of_paymentError: false,
                                        amountError: false,
                                        paymentMode: "Add",
                                        invoice_noError: false,
                                      });
                                    }}
                                  >
                                    Add Payment
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        {!this.state.receivable_payments
                          ? ""
                          : this.state.receivable_payments.length > 0 && (
                              <div className="row mt-2">
                                <div className="col-lg">
                                  <div className="table-responsive border-0">
                                    <table className="table table-borderless border-0">
                                      <thead>
                                        <tr>
                                          <th
                                            nowrap="true"
                                            className="table_header_barge_label text-left border-0"
                                          >
                                            Date Received
                                          </th>
                                          <th
                                            nowrap="true"
                                            className="table_header_barge_label text-right border-0"
                                          >
                                            Amount
                                          </th>
                                          <th
                                            nowrap="true"
                                            className="table_header_barge_label text-left border-0"
                                          >
                                            Invoice No
                                          </th>
                                          <th
                                            className="table_header_barge_label text-left border-0"
                                            style={{
                                              width: 300,
                                              maxWidth: 250,
                                            }}
                                          >
                                            Description
                                          </th>
                                          <th
                                            nowrap="true"
                                            className="table_header_barge_label text-left border-0"
                                          >
                                            Created Date
                                          </th>
                                          <th
                                            nowrap="true"
                                            className="table_header_barge_label text-left border-0"
                                          >
                                            Action
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {this.state.receivable_payments.map(
                                          (p, index) => (
                                            <tr key={index}>
                                              <td
                                                nowrap="true"
                                                className=" text-left"
                                              >
                                                {localDateFormate(
                                                  p.date_of_payment
                                                )}
                                              </td>
                                              <td
                                                nowrap="true"
                                                className=" text-right"
                                              >
                                                {p.amount
                                                  ? this.toLocaleString(
                                                      p.amount
                                                    )
                                                  : ""}
                                              </td>
                                              <td
                                                nowrap="true"
                                                className=" text-left"
                                              >
                                                {p.invoice_no}
                                              </td>
                                              <td
                                                className=" text-left"
                                                style={{
                                                  whiteSpace: "pre-line",
                                                }}
                                              >
                                                {p.description}
                                              </td>
                                              <td
                                                nowrap="true"
                                                className=" text-left"
                                              >
                                                {localDateFormate(
                                                  p.created_date
                                                )}
                                              </td>
                                              <td
                                                nowrap="true"
                                                className=" text-left"
                                              >
                                                {(this.state.features.includes(
                                                  "321"
                                                ) ||
                                                  this.state.features.includes(
                                                    "97"
                                                  )) && (
                                                  <i
                                                    className="fa fa-pencil edit_icon mr-2"
                                                    style={{
                                                      fontSize: 20,
                                                      color: config.themeColor,
                                                      cursor: "pointer",
                                                    }}
                                                    data-toggle="modal"
                                                    title="Edit Coal Payment Posting"
                                                    data-placement="bottom"
                                                    onClick={() => {
                                                      this.setState({
                                                        RemainingAmount:
                                                          this.state
                                                            .receivable_remaining_amount,
                                                      });
                                                      this.getPaymentDetalis(p);
                                                    }}
                                                  ></i>
                                                )}

                                                {(this.state.features.includes(
                                                  "322"
                                                ) ||
                                                  this.state.features.includes(
                                                    "97"
                                                  )) && (
                                                  <span>
                                                    <i
                                                      className="fa fa-trash-o"
                                                      style={{
                                                        fontSize: 20,
                                                        color: "red",
                                                        cursor: "pointer",
                                                      }}
                                                      data-toggle="modal"
                                                      title="Delete Coal Payment Posting"
                                                      data-placement="bottom"
                                                      data-keyboard="false"
                                                      data-target="#deleteConformationModal"
                                                      data-backdrop="static"
                                                      onClick={() =>
                                                        this.setState({
                                                          deletePaymentData: p,
                                                        })
                                                      }
                                                    ></i>
                                                  </span>
                                                )}
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            )}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div
            className="row col-lg-12 modal-footer justify-content-end bottom_buttons_section"
            style={{ bottom: 0, background: "white" }}
          >
            <button
              type="button"
              className="drawer_button drawer_text drawer_calculate_button drawer_calculate_adjustment"
              onClick={() => this.props.onCancel(false)}
            >
              Cancel
            </button>
            {this.props.onEdit && (
              <button
                type="button"
                className="drawer_button drawer_text"
                onClick={() => this.props.onEdit(true)}
              >
                Edit
              </button>
            )}
          </div>
          {/**
           *
           * Delete Payament Postings Conformation Modal
           *
           */}
          <div
            className="modal fade"
            id="deleteConformationModal"
            role="dialog"
            tabIndex="-1"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div
                  className="modal-header border-0"
                  style={{ backgroundColor: "#f1f1f1" }}
                >
                  <h6
                    className="modal-title"
                    style={{ border: "none", padding: 0 }}
                  >
                    <i
                      className="fa fa-exclamation-triangle"
                      aria-hidden="true"
                      style={{ color: "#e0a51e", marginBottom: "5px" }}
                    ></i>
                    &emsp; Are you sure to delete ?
                  </h6>
                </div>
                <div className="modal-footer border-0">
                  <button
                    className="btn btn-sm btn-warning mr-3"
                    disabled={this.state.submitting}
                    onClick={() =>
                      this.deletePaymentsHandler(this.state.deletePaymentData)
                    }
                  >
                    <span
                      className={
                        this.state.submitting
                          ? "spinner-grow spinner-grow-sm mr-2"
                          : ""
                      }
                    />
                    Delete
                  </button>
                  <button
                    className="btn btn-sm btn-secondary"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="myModal" role="dialog">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5
                    className="modal-title"
                    style={{ border: "none", padding: 0 }}
                  >
                    Add Service Type
                  </h5>
                  <button type="button" className="close" data-dismiss="modal">
                    &times;
                  </button>
                </div>
                <div className="modal-body">
                  <TextField
                    name="new_service_type"
                    placeholder="Add Service Type *"
                    margin="dense"
                    variant="outlined"
                    onChange={(e) => {
                      this.setState({
                        new_service_type: e.target.value,
                        new_service_type_error: false,
                      });
                    }}
                    value={this.state.new_service_type}
                    error={this.state.new_service_type_error}
                  />
                </div>
                <div className="row bottom_buttons_section">
                  <button
                    type="button"
                    className="btn previous_button"
                    name="submit"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn  next_button"
                    onClick={this.addNewServiceTypeHandler}
                  >
                    Submit
                  </button>
                  &emsp;
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="paymentPostingsModal"
            role="dialog"
            tabIndex="-1"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content  payment-modal">
                <div
                  className="modal-header border-0"
                  style={{ backgroundColor: "#f1f1f1" }}
                >
                  <h6
                    className="modal-title"
                    style={{ border: "none", padding: 0 }}
                  >
                    {this.state.paymentTitle} Payment Posting
                  </h6>
                  <button type="button" className="close" data-dismiss="modal">
                    &times;
                  </button>
                </div>
                <div className="modal-body p-0 mt-2">
                  <div className="col-lg-12">
                    <div
                      style={{
                        fontSize: 14,
                        color: "red",
                        textTransform: "capitalize",
                        textAlign: "center",
                      }}
                    >
                      {this.state.paymentErrorMsg}
                    </div>

                    <div className="row">
                      <div className="col-lg-6 mb-2">
                        <label className="contract_display_header_label mb-0">
                          {this.state.activeTabIndex === 0
                            ? "Date of Payment"
                            : "Date Received"}{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DatePicker
                            autoOk={true}
                            inputVariant="outlined"
                            variant="inline"
                            margin="dense"
                            fullWidth
                            format="dd/MM/yyyy"
                            value={this.state.date_of_payment}
                            error={this.state.date_of_paymentError}
                            onChange={(date) => {
                              this.setState({
                                date_of_payment: date,
                                date_of_paymentError: false,
                              });
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <IconButton>
                                    <EventIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                              className: "pl-0",
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </div>

                      <div className="col-lg-6 mb-2">
                        <label className="contract_display_header_label mb-0">
                          Amount <span style={{ color: "red" }}>*</span>
                        </label>
                        <TextField
                          name="amount"
                          margin="dense"
                          variant="outlined"
                          placeholder="Amount"
                          required
                          value={this.state.amount}
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          fullWidth
                          error={this.state.amountError}
                          onChange={(e) => {
                            const pr = /^\d*(\.\d{0,4})?$/;
                            if (
                              e.target.value === "" ||
                              pr.test(e.target.value)
                            ) {
                              this.setState({
                                amount: e.target.value,
                                amountError: false,
                                paymentErrorMsg: "",
                              });
                              var remainingAmount = 0;
                              remainingAmount = Number(
                                this.state.RemainingAmount
                              );
                              if (this.state.paymentMode === "Update") {
                                remainingAmount =
                                  Number(this.state.RemainingAmount) +
                                  Number(this.state.existingAmount);
                              }
                              if (
                                Number(e.target.value) >
                                Math.ceil(Number(remainingAmount))
                              ) {
                                this.setState({
                                  amountError: true,
                                  submitting: false,
                                  paymentErrorMsg:
                                    this.state.payment_posting_type ===
                                    "Payable"
                                      ? "Amount is exceeding Payable value"
                                      : "Amount is exceeding Receivable value",
                                });
                                return;
                              }
                            }
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                className="adornment_background"
                              >
                                {config.company === "SRPL" || config.company === "AVS"  ||
                                config.company === "PRPL"
                                  ? "USD"
                                  : "IDR"}
                              </InputAdornment>
                            ),
                            className: "p-0",
                          }}
                        />
                      </div>

                      <div className="col-lg-6 mb-2">
                        <label className="contract_display_header_label mb-0">
                          Invoice No <span style={{ color: "red" }}>*</span>
                        </label>
                        <TextField
                          name="invoice_no"
                          margin="dense"
                          variant="outlined"
                          placeholder="Invoice No"
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          error={this.state.invoice_noError}
                          value={this.state.invoice_no}
                          onChange={(e) =>
                            this.setState({
                              invoice_no: e.target.value,
                              invoice_noError: false,
                            })
                          }
                          fullWidth
                        />
                      </div>

                      <div className="col-lg-6 mb-2">
                        <label className="contract_display_header_label mb-0">
                          Description
                        </label>
                        <TextField
                          name="description"
                          margin="dense"
                          variant="outlined"
                          placeholder="Description"
                          multiline
                          rows={3}
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          value={this.state.description}
                          onChange={(e) =>
                            this.setState({ description: e.target.value })
                          }
                          fullWidth
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row bottom_buttons_section">
                    <button
                      type="button"
                      className="btn previous_button"
                      name="submit"
                      data-dismiss="modal"
                      onClick={() => {
                        this.setState({
                          paymentTitle: "",
                          mv_sales_financials_id: "",
                          payment_posting_type: "",
                          date_of_payment: null,
                          amount: "",
                          invoice_no: "",
                          description: "",
                          paymentErrorMsg: "",
                          date_of_paymentError: false,
                          amountError: false,
                          invoice_noError: false,
                          paymentMode: "",
                          submitting: false,
                        });
                      }}
                    >
                      Cancel
                    </button>
                    {this.state.paymentMode === "Add" ? (
                      <button
                        type="button"
                        className="btn next_button"
                        onClick={this.AddPaymentPostingHandler}
                        disabled={this.state.submitting}
                      >
                        <span
                          className={
                            this.state.submitting
                              ? "spinner-grow spinner-grow-sm mr-2"
                              : ""
                          }
                        ></span>
                        Submit
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn next_button"
                        onClick={this.UpdatePaymentPostingsHandler}
                        disabled={this.state.submitting}
                      >
                        <span
                          className={
                            this.state.submitting
                              ? "spinner-grow spinner-grow-sm mr-2"
                              : ""
                          }
                        ></span>
                        Update
                      </button>
                    )}
                    {/* <button type='button' className='btn next_button' name='Back' onClick={this.addAdvancePaymentHandler}>Submit</button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
