import React, { Component } from "react";
import api from "../../../api/api";
import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import { Alert } from "@material-ui/lab";
import {
  TextField,
  Snackbar,
  InputAdornment,
  MenuItem,
  Checkbox,
  Accordion,
  AccordionSummary,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { withStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { localDateFormate, pad } from "../../common/common";
import FileUpload from "../../common/FileUpload";
import Loader from "../../common/Loader";

const CheckBox = withStyles({
  root: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);
const NewRadio = withStyles({
  root: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);
var total_barge_quantity = 0;

export default class PreShipmentInspectionUpdate extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      vendorTypeAsOtherMenu: [
        <MenuItem
          style={{ fontFamily: "Poppins", fontSize: "14px" }}
          value="0"
          key={-1}
        >
          Please Select
        </MenuItem>,
      ],

      multiRows: [
        {
          invoice_date: null,
          ppn: "",
          sac_quantity_in_mt: "",
          sac_sampling_surveyor: "",
          sac_sampling_price_per_mt: "",
          sac_sampling_base_price: "",
          sac_sampling_vat_percent: "10",
          sac_sampling_vat_value: "",
          sac_sampling_pph23_percent: "2",
          sac_sampling_pph23_value: "",
          sac_sampling_total: "",
          sac_sampling_ctc: "",
          billing_currency:
            config.company === "SRPL" ||
            config.company === "AVS" ||
            config.company === "PRPL"
              ? "USD"
              : "0",
          sac_sampling_currency_xchg_rate: "",
          sac_sampling_ctc_in_local_currency: "",
        },
      ],
      isLoading: true,
      activeStep: 0,
      barges: [],
      barge_id: null,
      barge_ids: [],
      totalBarges: [],
      bargeList: [],
      business_no_drop_value: [],
      sampling_surveyors_drop_value: [],
      vendorTypeAsSurveyors: [],
      barge_nomination: "",
      jetty_name: "",
      features: [],
      files: [],
      shipment_type: this.props.shipment_type,
      expanded: false,
      costing_files: [],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (
      !(
        features.indexOf("168") >= 0 ||
        features.indexOf("398") >= 0 ||
        features.indexOf("449") >= 0 ||
        features.indexOf("97") >= 0
      )
    ) {
      window.location.href = "/dashboard";
    }
    // try {
    //   let comboID = this.props.comboID;
    //   var barge_id = comboID.split("$#")[0];
    //   var pre_shipment_costing_id = comboID.split("$#")[1];
    //   if (this.props.shipment_type === "Without Barge") {
    //     barge_id = null;
    //     pre_shipment_costing_id = comboID.split("$#")[0];
    //   }
    // } catch (e) {
    //   window.location.href = "/barge-list";
    // }

    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const financial_type = "barge_pur_fin_pre_shipment";
    await api
      .get_barges(loginUserID, idToken, financial_type)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.barges) {
                let barges = res.barges;
                barges.sort(function (a, b) {
                  return (
                    (a.pre_shipment_inspection_costing === null) -
                    (b.pre_shipment_inspection_costing === null)
                  );
                });
                res.barges = barges;
                const filter_not_costing_barges = res.barges.filter(
                  (e) =>
                    (e.pre_shipment_inspection_costing === null ||
                      e.pre_shipment_inspection_costing ===
                        pre_shipment_costing_id) &&
                    e.status === "Active"
                );
                if (filter_not_costing_barges.length === 0) {
                  this.setState({
                    snackBarErrorOpen: true,
                    errorMsg: "No records to display",
                  });
                }
                this.setState({
                  barges: filter_not_costing_barges,
                  totalBarges: filter_not_costing_barges,
                  //isLoading: false
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });

    const status = "Active";
    await api.getVendors(loginUserID, idToken, status).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            if (res.vendors) {
              const surveyors = [];
              const sampling_surveyors = [];
              for (var i of res.vendors) {
                if (i.vendor_type === "Surveyor") {
                  surveyors.push({
                    sac_sampling_surveyor_name: i.vendor_name,
                    value: i.id,
                    key: i,
                  });
                  sampling_surveyors.push({ name: i.vendor_name, value: i.id });
                }
              }
              this.setState({
                vendorTypeAsSurveyors: surveyors,
                sampling_surveyors_drop_value: sampling_surveyors,
              });
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
    // for get_Currencies
    await api.getCurrencies(loginUserID, idToken).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            if (res.Currency) {
              var currencies = [
                <MenuItem
                  style={{ fontFamily: "Poppins", fontSize: "14px" }}
                  value="0"
                  key={-1}
                  disabled
                >
                  Please Select
                </MenuItem>,
              ];
              for (var i in res.Currency) {
                currencies.push(
                  <MenuItem
                    style={{ fontFamily: "Poppins", fontSize: "14px" }}
                    value={res.Currency[i].currency}
                    key={[i]}
                  >
                    {res.Currency[i].currency}
                  </MenuItem>
                );
              }
              this.setState({
                currencyData: currencies,
              });
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          } else if (res.code === "601") {
            this.setState({
              errorMsg: res.message,
              successMsg: "",
              isLoading: false,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });

    await api.getBusinessNumbers(loginUserID, idToken).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
            if (res.business_numbers) {
              let business_no_drop_value = [];
              for (var i of res.business_numbers) {
                business_no_drop_value.push({
                  name: i.business_no,
                  value: i.id,
                  mother_vessel_name: i.mother_vessel_name ? i.mother_vessel_name : i.business_no,
                });
              }
              this.setState({
                business_no_drop_value: business_no_drop_value,
              });
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });

    const barge_id = null;
    const pre_shipment_costing_id = this.props.costing_id;

    // get pre shipment inspection data
    await api
      .get_barge_pur_fin_pre_shipment(
        loginUserID,
        idToken,
        barge_id,
        pre_shipment_costing_id
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.barge_pur_fin_pre_shipment) {
                this.setState({
                  costing_no: res.barge_pur_fin_pre_shipment[0]?.costing_no,
                  invoice_file: res.barge_pur_fin_pre_shipment[0]?.invoice_file,
                  multiRows: res.barge_pur_fin_pre_shipment,
                  files: res.files ? res.files : [],
                  bargeList:
                    res.barge_pur_fin_pre_shipment.length > 0
                      ? res.barge_pur_fin_pre_shipment[0].barge_group_id !==
                          "0" &&
                        res.barge_pur_fin_pre_shipment[0].barge_group_id !==
                          null
                        ? res.barge_pur_fin_pre_shipment[0].barge_group_id.split(
                            ","
                          )
                        : []
                      : [],
                  isLoading: false,
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  }

  nextStepHandler = () => {
    if (
      this.state.activeStep === 0 &&
      this.state.shipment_type === "With Barge"
    ) {
      if (this.state.bargeList.length <= 0) {
        this.setState({
          snackBarErrorOpen: true,
          errorMsg: "Please select barge.",
        });
        return;
      }
    }

    this.setState({
      activeStep: this.state.activeStep + 1,
    });
  };

  previousStepHandler = () => {
    this.setState({
      activeStep: this.state.activeStep - 1,
    });
  };

  async callingAsync(barge_data) {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    try {
      let barge_id = barge_data.id;
      if (barge_data) {
        api
          .get_purchase_contract_data_by_barge_id(
            loginUserID,
            idToken,
            barge_id
          )
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              response.json().then((res) => {
                if (res.code === "200") {
                  let barge_quantity = "";
                  if (res.purchase_contract_data) {
                    barge_quantity = barge_data.barge_quantity_in_mt;
                    if (
                      barge_quantity === null ||
                      Number(barge_quantity) <= 0
                    ) {
                      barge_quantity = barge_data.barge_planned_quantity_in_mt;
                    } else if (
                      barge_data.barge_planned_quantity_in_mt === null ||
                      Number(barge_data.barge_planned_quantity_in_mt) <= 0
                    ) {
                      barge_quantity = barge_data.barge_quantity_in_mt;
                    }

                    let redraftQuantity = barge_data.barge_quantity_in_mt
                      ? barge_data.barge_quantity_in_mt
                      : "";
                    if (
                      res.purchase_contract_data.purchase_type ===
                        "FAS Anchorage" &&
                      barge_data.redraft_quantity !== null
                    ) {
                      redraftQuantity = barge_data.redraft_quantity;
                    }

                    // updating Global variable
                    total_barge_quantity = Number(barge_quantity);

                    let row = this.state.multiRows;
                    if (row.length > 0) {
                      row[0].sac_quantity_in_mt = barge_quantity;
                      //row[0].sac_sampling_price_per_mt = res.purchase_contract_data.purchase_price_pmt;
                      row[0].sac_sampling_price_per_mt = "";
                    }
                    this.setState(
                      {
                        //quantity_for_export_tax: res.purchase_contract_data.purchase_type === "FAS Anchorage" ?
                        redraftQuantity: redraftQuantity,
                        multiRows: row,
                        quantity_in_mt: barge_quantity,
                        isLoading: false,
                        bargeNomination: res.purchase_contract_data
                          .barge_nomination
                          ? res.purchase_contract_data.barge_nomination
                          : "",
                      },
                      () => this.samplingSurveyorCalculations(0)
                    );
                  } else {
                    alert(
                      "Unexpected error occured. Please contact administrator."
                    );
                  }
                }
              });
            }
          });
      }
    } catch (e) {
      window.location.href = "/dashboard";
    }
  }

  onBargesCheck = (bargeId, checked, barge) => {
    var bargeQuantity = "";
    if (
      barge.barge_quantity_in_mt === null ||
      barge.barge_quantity_in_mt <= 0
    ) {
      bargeQuantity = barge.barge_planned_quantity_in_mt;
    } else {
      bargeQuantity = barge.barge_quantity_in_mt;
    }
    if (!!checked) {
      total_barge_quantity += Number(bargeQuantity);
      let bargeList = [...this.state.bargeList, bargeId];
      let row = this.state.multiRows;
      if (row.length > 0) {
        row[0].sac_quantity_in_mt = total_barge_quantity;
      }
      this.setState({
        bargeList: bargeList,
        multiRows: row,
        isLoading: true,
      });
      if (this.state.bargeList.length === 0) {
        this.callingAsync(barge);
      } else if (this.state.bargeList.length > 0) {
        let row = this.state.multiRows;
        if (row.length > 0) {
          row[0].barge_quantity = total_barge_quantity;
        }
        this.setState(
          {
            multiRows: row,
            barge_quantity: total_barge_quantity,
            isLoading: false,
          },
          () => this.samplingSurveyorCalculations(0)
        );
      } else {
        this.setState({
          isLoading: false,
        });
      }
    } else {
      var data = this.state.bargeList;
      total_barge_quantity -= Number(bargeQuantity);
      if (data.indexOf(bargeId) >= 0) {
        let idx = data.indexOf(bargeId);
        data.splice(idx, 1);
      }
      this.setState({
        bargeList: data,
        barge_quantity: total_barge_quantity,
        isLoading: true,
      });
      if (this.state.bargeList.length !== 0) {
        var row = this.state.multiRows;
        if (row.length > 0) {
          row[0].sac_quantity_in_mt = total_barge_quantity;
        }
        this.setState(
          {
            multiRows: row,
            isLoading: false,
          },
          () => this.samplingSurveyorCalculations(0)
        );
      } else {
        this.setState({
          isLoading: false,
        });
      }
    }
  };

  onSelectAllBarges = (checked) => {
    this.setState({ isLoading: true });
    let totalBarges = this.state.totalBarges;
    let row = this.state.multiRows;
    if (checked) {
      const loginUserID = this.Cookie.getCookie("loginUserId");
      const idToken = this.Cookie.getIdTokenCookie();
      let barge_id = totalBarges.length > 0 ? totalBarges[0].id : null;
      if (barge_id) {
        api
          .get_purchase_contract_data_by_barge_id(
            loginUserID,
            idToken,
            barge_id
          )
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              response.json().then((res) => {
                if (res.code === "200") {
                  if (res.purchase_contract_data) {
                    let bargeList = [];
                    let all_barge_quantity = 0;
                    for (var i of totalBarges) {
                      bargeList.push(i.id);
                      if (i.barge_quantity === null) {
                        all_barge_quantity += Number(
                          i.barge_planned_quantity_in_mt
                        );
                      } else {
                        all_barge_quantity += Number(i.barge_quantity_in_mt);
                      }
                    }
                    total_barge_quantity = all_barge_quantity;
                    row[0].sac_quantity_in_mt = all_barge_quantity;
                    row[0].sac_sampling_price_per_mt = res.purchase_price_pmt;
                    this.setState(
                      {
                        bargeList: bargeList,
                        multiRows: row,
                        barge_quantity: total_barge_quantity,
                        isLoading: false,
                      },
                      () => this.samplingSurveyorCalculations(0)
                    );
                  } else {
                    alert(
                      "Unexpected error occured. Please contact administrator."
                    );
                  }
                } else if (res.code === "607") {
                  window.location.href = "/logout";
                }
              });
            }
          });
      }
    } else {
      row[0].sac_quantity_in_mt = "";
      row[0].base_price_pmt = "";
      this.setState(
        {
          multiRows: row,
          bargeList: [],
          barge_quantity: "",
          purchase_price_pmt: "",
          base_price_pmt: "",
          sales_type: "",
          purchase_type: "",
          isLoading: false,
        },
        () => this.samplingSurveyorCalculations(0)
      );
    }
  };

  addMoreRowsHandler = () => {
    const item = {
      sac_quantity_in_mt: this.state.quantity_in_mt,
      invoice_date: null,
      ppn: "",
      sac_sampling_surveyor: "",
      sac_sampling_price_per_mt: this.state.purchase_price_pmt,
      sac_sampling_base_price: "",
      sac_sampling_vat_percent: 10,
      sac_sampling_vat_value: "",
      sac_sampling_pph23_percent: 2,
      sac_sampling_pph23_value: "",
      sac_sampling_total: "",
      sac_sampling_ctc: "",
      billing_currency:
        config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL"
          ? "USD"
          : "0",
      sac_sampling_currency_xchg_rate: "",
      sac_sampling_ctc_in_local_currency: "",
    };
    this.setState({
      multiRows: [...this.state.multiRows, item],
    });
  };

  removeMultiRowsHandler = (idx) => () => {
    const rows = this.state.multiRows;
    rows.splice(idx, 1);
    this.setState({
      multiRows: rows,
    });
  };

  samplingSurveyorCalculations = (idx) => {
    let row = this.state.multiRows;
    let currecny_xchg_rate = row[idx].sac_sampling_currency_xchg_rate
      ? row[idx].sac_sampling_currency_xchg_rate
      : 1;
    let price_per_mt = row[idx].sac_sampling_price_per_mt
      ? row[idx].sac_sampling_price_per_mt
      : 0;
    let sac_sampling_base_price = (
      (Number(row[idx].sac_quantity_in_mt) * Number(price_per_mt)) /
      Number(currecny_xchg_rate)
    ).toFixed(4);

    let sac_sampling_vat_percent = row[idx]?.sac_sampling_vat_percent ? Number(row[idx].sac_sampling_vat_percent) : 10;
    let sac_sampling_pph23_percent = row[idx]?.sac_sampling_pph23_percent ? Number(row[idx].sac_sampling_pph23_percent) : 2;

    let sac_sampling_vat_value = (
      (Number(sac_sampling_base_price) * sac_sampling_vat_percent) /
      100
    ).toFixed(2);
    let sac_sampling_pph23_value = (
      (Number(sac_sampling_base_price) * sac_sampling_pph23_percent) /
      100
    ).toFixed(2);
    if (
      config.company === "SRPL" ||
      config.company === "AVS" ||
      config.company === "PRPL"
    ) {
      sac_sampling_pph23_value = 0;
    }
    let sac_sampling_total = (
      Number(sac_sampling_base_price) +
      Number(sac_sampling_vat_value) -
      Number(sac_sampling_pph23_value)
    ).toFixed(2);
    let sac_sampling_ctc = (
      Number(sac_sampling_base_price) + Number(sac_sampling_vat_value)
    ).toFixed(2);

    row[idx].sac_sampling_base_price = sac_sampling_base_price;
    row[idx].sac_sampling_vat_percent = sac_sampling_vat_percent;
    row[idx].sac_sampling_vat_value = sac_sampling_vat_value;
    row[idx].sac_sampling_pph23_percent = sac_sampling_pph23_percent;
    row[idx].sac_sampling_pph23_value = sac_sampling_pph23_value;
    row[idx].sac_sampling_total = sac_sampling_total;
    row[idx].sac_sampling_ctc = sac_sampling_ctc;

    this.setState({
      multiRows: row,
    });
  };

  submitHandler = () => {
    this.setState({ isLoading: true });
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    let barge_id = this.state.bargeList.toString();
    let barge_pre_shipment_data = this.state.multiRows;
    let shipment_type = this.state.shipment_type;
    let costing_file = this.state.costing_files?.filter(
      (e) => e.file_content !== "" && e.column === "invoice_file"
    );
    for (var i of barge_pre_shipment_data) {
      i["costing_files"] = costing_file;
    }
    /**
     *  Checking Mandatory files
     */
    var missing_fields = 0;
    let files_missing = 0;
    var files_data = this.state.files;
    for (var obj of files_data) {
      if (obj.file_type !== "" || obj.file_content !== "") {
        if (obj.file_content !== "") {
          if (obj.file_description === "") {
            missing_fields++;
            obj.file_description_error = true;
          } else {
            obj.file_description = obj.file_description.toUpperCase();
          }
        } else if (obj.file_description !== "") {
          if (obj.file_content === "") {
            files_missing++;
          }
        }
      }
    }
    this.setState({
      files: files_data,
    });

    if (missing_fields > 0) {
      this.setState({
        snackBarErrorOpen: true,
        isLoading: false,
        errorMsg: "Please fill mandatory fields.",
      });
      return;
    }
    if (files_missing > 0) {
      this.setState({
        snackBarErrorOpen: true,
        isLoading: false,
        errorMsg: "Please Select file.",
      });
      return;
    }
    var existing_file_ids = [];
    const existing_files = files_data.filter((e) => e.id);
    for (var j of existing_files) {
      existing_file_ids.push(j.id);
    }
    const new_files = files_data.filter((e) => !e.id);
    const content_files = new_files.filter((e) => e.file_content !== "");
    if (shipment_type === "With Barge" && !barge_id) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Invalid Barge Id",
      });
    }

    api
      .update_barge_pur_fin_pre_shipment(
        loginUserID,
        idToken,
        shipment_type,
        barge_id,
        barge_pre_shipment_data,
        existing_file_ids,
        content_files
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.props.onUpdateCosting(res.code);
              this.setState({
                snackBarSuccessOpen: true,
                sucessMsg: "Costing created sucessfully.",
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            } else if (res.code === "624") {
              alert();
              this.setState({
                snackBarErrorOpen: true,
                isLoading: false,
                errorMsg: "Record already exist for given barge",
              });
            }
          });
        }
      });
  };

  toLocaleString(value) {
    return value ? Number(value).toLocaleString() : 0;
  }

  searchBargesHandler = () => {
    const pre_shipment_costing_id = this.props.costing_id;
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const purchase_contract_id = "",
      barge_vendor = "",
      financial_type = "barge_pur_fin_pre_shipment",
      costing_type = "",
      barge_name = "",
      jetty_name = "";
    const business_no = this.state.business_no_id;
    const sampling_surveyor = this.state.sampling_surveyors;
    api
      .get_barges(
        loginUserID,
        idToken,
        financial_type,
        business_no,
        barge_vendor,
        purchase_contract_id,
        barge_name,
        costing_type,
        jetty_name,
        sampling_surveyor
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.barges) {
                const filter_not_costing_barges = res.barges.filter(
                  (e) =>
                    (e.pre_shipment_inspection_costing === null ||
                      e.pre_shipment_inspection_costing ===
                        pre_shipment_costing_id) &&
                    e.status === "Active"
                );
                if (filter_not_costing_barges.length === 0) {
                  this.setState({
                    snackBarErrorOpen: true,
                    errorMsg: "No records to display",
                  });
                }
                this.setState({
                  totalBarges: filter_not_costing_barges,
                  barges: filter_not_costing_barges,
                  isLoading: false,
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  };

  render() {
    const pr = /^\d*(\.\d{0,4})?$/;
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div className="container bg-white p-0" style={{ minHeight: "100vh" }}>
          <div className="clearfix content-header border-bottom">
            <div className="row">
              <div className="col-sm text-left pl-0">
                <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                  {" "}
                  {`Pre Shipment Inspection - ${this.state.costing_no}`}
                </h4>
              </div>
            </div>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.sucessMsg}
            </Alert>
          </Snackbar>
          <div className="drawer-section">
            <div className="card">
              {!this.props.updateDocuments && (
                <div className=" w-100">
                  <div className="row">
                    <div className="col-lg-5 pl-0">
                      <label className="form_label mb-0">
                        Pre Shipment Type
                      </label>
                      <RadioGroup
                        aria-label="shipment_type"
                        name="shipment_type"
                        onChange={(e) => {
                          this.setState({ shipment_type: e.target.value });
                        }}
                        style={{ flexDirection: "row" }}
                      >
                        <FormControlLabel
                          value="With Barge"
                          control={
                            <NewRadio
                              checked={
                                this.state.shipment_type === "With Barge"
                              }
                            />
                          }
                          label="With Barge"
                        />
                        <FormControlLabel
                          value="Without Barge"
                          control={
                            <NewRadio
                              checked={
                                this.state.shipment_type === "Without Barge"
                              }
                            />
                          }
                          label="Without Barge"
                        />
                      </RadioGroup>
                    </div>
                  </div>
                  {this.state.shipment_type === "With Barge" && (
                    <div>
                      <div className="row mb-1 p-2 w-100">
                        <div className="col-lg-6 p-0">
                          <Autocomplete
                            multiple
                            options={this.state.business_no_drop_value}
                            getOptionLabel={(option) =>
                              option.mother_vessel_name
                            }
                            renderOption={(option, { selected }) => (
                              <React.Fragment>
                                <CheckBox
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                <div className="col-lg p-0 m-0">
                                  <label className="form_label mb-0">
                                    {option.mother_vessel_name}
                                  </label>
                                  <label
                                    className="contract_display_header_value m-0 pl-0"
                                    style={{ fontSize: 10, paddingLeft: 5 }}
                                  >
                                    {"( " + option.name + " )"}
                                  </label>
                                </div>
                              </React.Fragment>
                            )}
                            onChange={(event, value) => {
                              if (value) {
                                var data = [];
                                for (var i of value) {
                                  data.push(i.value);
                                }
                                //var floating_crane_vendor = data.toString();
                                this.setState({ business_no_id: data });
                              } else {
                                this.setState({ business_no_id: [] });
                              }
                            }}
                            disableCloseOnSelect
                            name="Business_Number"
                            size="small"
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Business No"
                                label="Business No"
                                variant="outlined"
                                style={{ top: 8 }}
                                fullWidth
                              />
                            )}
                            style={{ display: "contents" }}
                          />
                        </div>
                        <div className="col-lg-6 pr-0">
                          <Autocomplete
                            multiple
                            options={this.state.sampling_surveyors_drop_value}
                            getOptionLabel={(option) => option.name}
                            renderOption={(option, { selected }) => (
                              <React.Fragment>
                                <CheckBox
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option.name}
                              </React.Fragment>
                            )}
                            onChange={(event, value) => {
                              if (value) {
                                var data = [];
                                for (var i of value) {
                                  data.push(i.value);
                                }
                                //var floating_crane_vendor = data.toString();
                                this.setState({ sampling_surveyors: data });
                              } else {
                                this.setState({ sampling_surveyors: [] });
                              }
                            }}
                            disableCloseOnSelect
                            name="Sampling surveyors"
                            size="small"
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Sampling surveyors"
                                label="Surveyor"
                                variant="outlined"
                                style={{ top: 8 }}
                                fullWidth
                              />
                            )}
                            style={{ display: "contents" }}
                          />
                        </div>

                        <div className="col p-0">
                          <button
                            className="header_button header_button_text add_button_adjustment"
                            style={{ width: "auto", marginTop: "28px" }}
                            onClick={() => this.searchBargesHandler()}
                          >
                            <i
                              className="fa fa-search mr-1"
                              aria-hidden="true"
                            />
                            Search
                          </button>
                        </div>
                      </div>
                      <Accordion
                        expanded={this.state.expanded}
                        className="mt-3 mb-2"
                        style={{
                          border: "#f7f3f0 1px solid",
                          borderTopLeftRadius: "4px",
                          borderTopRightRadius: "4px",
                        }}
                      >
                        <AccordionSummary
                          expandIcon={
                            <ExpandMoreIcon style={{ color: "#483b34" }} />
                          }
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          style={{
                            height: 15,
                            backgroundColor: "#f7f3f0",
                            borderTopLeftRadius: "3px",
                            borderTopRightRadius: "3px",
                          }}
                          className="text-white pl-1"
                          onClick={() =>
                            this.setState({ expanded: !this.state.expanded })
                          }
                        >
                          <h5 className="mb-0 pl-1">Select Barges</h5>
                        </AccordionSummary>
                        <div className="row">
                          <div className="table-responsive">
                            <div className="table border-0">
                              <table className="table table-borderless border-0">
                                <thead className="border-0">
                                  <tr>
                                    <th
                                      nowrap="true"
                                      className="p-0 text-center pt-1 border-0"
                                    >
                                      <CheckBox
                                        color="primary"
                                        checked={
                                          this.state.totalBarges.length !== 0 &&
                                          this.state.bargeList.length ===
                                            this.state.totalBarges.length
                                        }
                                        onChange={(e) => {
                                          this.onSelectAllBarges(
                                            e.target.checked
                                          );
                                        }}
                                      />
                                    </th>
                                    <th
                                      style={{ fontSize: 16 }}
                                      className="table_header_barge_label text-uppercase border-0"
                                      nowrap="true"
                                    >
                                      Barge ID
                                    </th>
                                    <th
                                      style={{ fontSize: 16 }}
                                      className="table_header_barge_label text-uppercase border-0"
                                      nowrap="true"
                                    >
                                      Barge Nomination
                                    </th>
                                    <th
                                      style={{ fontSize: 16 }}
                                      className="table_header_barge_label text-uppercase border-0"
                                      nowrap="true"
                                    >
                                      Jetty
                                    </th>
                                    <th
                                      style={{ fontSize: 16 }}
                                      className="table_header_barge_label text-uppercase border-0"
                                      nowrap="true"
                                    >
                                      Loading Date
                                    </th>
                                    <th
                                      style={{ fontSize: 16 }}
                                      className="table_header_barge_label text-uppercase border-0"
                                      nowrap="true"
                                    >
                                      Business No
                                    </th>
                                    <th
                                      style={{ fontSize: 16 }}
                                      className="table_header_barge_label text-uppercase border-0 text-right"
                                      nowrap="true"
                                    >
                                      Quantity
                                    </th>
                                  </tr>
                                </thead>
                                {this.state.totalBarges.map((p, index) => (
                                  <tbody key={index}>
                                    <tr className="rounded">
                                      <td className="p-0 text-center">
                                        <CheckBox
                                          value={p.id}
                                          color="primary"
                                          checked={
                                            this.state.bargeList.indexOf(
                                              p.id
                                            ) >= 0
                                          }
                                          //disabled={p.barge_pur_fin_id !== null}
                                          onChange={(e) => {
                                            this.onBargesCheck(
                                              e.target.value,
                                              e.target.checked,
                                              p
                                            );
                                          }}
                                        />
                                      </td>
                                      <td>{pad(p.id)}</td>
                                      <td nowrap="true">
                                        {p.barge_nomination}
                                      </td>
                                      <td nowrap="true">{p.loading_jetty}</td>
                                      <td nowrap="true">
                                        {localDateFormate(p.loading_date)}
                                      </td>
                                      <td nowrap="true">{p.business_no}</td>
                                      <td nowrap="true" className="text-right">
                                        {this.toLocaleString(
                                          p.barge_quantity_in_mt
                                        )}
                                      </td>
                                    </tr>
                                  </tbody>
                                ))}
                              </table>
                            </div>
                          </div>
                        </div>
                      </Accordion>
                    </div>
                  )}
                  <div className="" style={{ width: "100%" }}>
                    <div style={{ width: "70%" }}>
                      <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                        {" "}
                        Add details to Update costing
                      </h5>
                    </div>

                    {this.state.multiRows.map((e, idx) => (
                      <div className="row">
                        <div className="col-lg-11 rounded-lg border_dashed p-2 mt-2 mb-3">
                          <div className="row mb-3">
                            <div className="col-lg-6 pl-0">
                              <div className="row">
                                <div className="col-lg-12 p-0 mb-3">
                                  <label className="form_label mb-2">
                                    Sampling Surveyor
                                  </label>
                                  <Autocomplete
                                    size="small"
                                    options={this.state.vendorTypeAsSurveyors}
                                    getOptionLabel={(option) =>
                                      option.sac_sampling_surveyor_name
                                    }
                                    onChange={(event, value) => {
                                      if (value) {
                                        var row = this.state.multiRows;
                                        row[idx].sac_sampling_surveyor =
                                          value.value;
                                        row[idx].sac_sampling_surveyor_name =
                                          value.sac_sampling_surveyor_name;
                                        this.setState({
                                          multiRows: row,
                                        });
                                      }
                                    }}
                                    value={e}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="Search Vendor"
                                        variant="outlined"
                                        //error={this.state.vendorTypeError}
                                        fullWidth
                                      />
                                    )}
                                  />
                                </div>
                                <div className="col-lg-6 p-0 mb-3">
                                  <label className="form_label mb-0">
                                    Quantity in MT
                                  </label>
                                  <TextField
                                    name="sac_quantity_in_mt"
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth
                                    placeholder="Quantity in MT"
                                    value={e.sac_quantity_in_mt}
                                    inputProps={{
                                      style: {
                                        fontFamily: "Poppins",
                                        // fontSize: '14px'
                                      },
                                    }}
                                    onChange={(e) => {
                                      const quan = /^\d*(\.\d{0,3})?$/;
                                      if (
                                        e.target.value === "" ||
                                        quan.test(e.target.value)
                                      ) {
                                        var row = this.state.multiRows;
                                        row[idx].sac_quantity_in_mt =
                                          e.target.value;
                                        this.setState(
                                          {
                                            multiRows: row,
                                          },
                                          () =>
                                            this.samplingSurveyorCalculations(
                                              idx
                                            )
                                        );
                                      }
                                    }}
                                  />
                                </div>

                                <div className="col-lg-6 pr-0 mb-3">
                                  <div className="row">
                                    <label className="form_label mb-0">
                                      Price per MT
                                    </label>
                                    <TextField
                                      name="sac_sampling_price_per_mt"
                                      margin="dense"
                                      variant="outlined"
                                      value={e.sac_sampling_price_per_mt}
                                      placeholder="Price per MT"
                                      fullWidth
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment
                                            position="end"
                                            className="adornment_background"
                                          >
                                            {config.company === "SRPL" ||
                                            config.company === "AVS" ||
                                            config.company === "PRPL"
                                              ? "USD"
                                              : e.billing_currency !== "0"
                                              ? e.billing_currency
                                              : ""}
                                          </InputAdornment>
                                        ),
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                          padding: 0,
                                        },
                                      }}
                                      onChange={(e) => {
                                        if (
                                          e.target.value === "" ||
                                          pr.test(e.target.value)
                                        ) {
                                          var row = this.state.multiRows;
                                          row[idx].sac_sampling_price_per_mt =
                                            e.target.value;
                                          this.setState(
                                            {
                                              multiRows: row,
                                            },
                                            () =>
                                              this.samplingSurveyorCalculations(
                                                idx
                                              )
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                </div>

                                {!(
                                  config.company === "SRPL" ||
                                  config.company === "AVS" ||
                                  config.company === "PRPL"
                                ) && (
                                  <div className="col-lg-12 p-0 mb-2">
                                    <div className="row">
                                      <label className="form_label mb-0">
                                        Currency
                                      </label>
                                      <TextField
                                        name="billing_currency"
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth
                                        placeholder="Currency"
                                        label="Currency"
                                        value={e.billing_currency}
                                        select
                                        inputProps={{
                                          style: {
                                            fontFamily: "Poppins",
                                            fontSize: "14px",
                                          },
                                        }}
                                        onChange={(e) => {
                                          var row = this.state.multiRows;
                                          row[idx].billing_currency =
                                            e.target.value;
                                          this.setState({
                                            multiRows: row,
                                          });
                                        }}
                                      >
                                        {this.state.currencyData}
                                      </TextField>
                                    </div>
                                  </div>
                                )}

                                {!(
                                  config.company === "SRPL" ||
                                  config.company === "AVS" ||
                                  config.company === "PRPL"
                                ) &&
                                  e.billing_currency !== "IDR" &&
                                  e.billing_currency !== "0" && (
                                    <div className="col-lg-12 p-0">
                                      <div className="row mb-0">
                                        <label className="form_label mb-2">
                                          Exchange rate
                                        </label>
                                        <TextField
                                          name="sac_sampling_currency_xchg_rate"
                                          margin="dense"
                                          variant="outlined"
                                          fullWidth
                                          inputProps={{
                                            style: {
                                              fontFamily: "Poppins",
                                              fontSize: "14px",
                                            },
                                          }}
                                          placeholder="Exchange rate"
                                          value={
                                            e.sac_sampling_currency_xchg_rate
                                          }
                                          onChange={(e) => {
                                            const re = /^\d*(\.\d{0,10})?$/;

                                            if (
                                              e.target.value === "" ||
                                              re.test(e.target.value)
                                            ) {
                                              var row = this.state.multiRows;
                                              row[
                                                idx
                                              ].sac_sampling_currency_xchg_rate =
                                                e.target.value;
                                              this.setState(
                                                {
                                                  multiRows: row,
                                                },
                                                () =>
                                                  this.samplingSurveyorCalculations(
                                                    idx
                                                  )
                                              );
                                            }
                                          }}
                                        />
                                      </div>
                                    </div>
                                  )}
                              </div>
                            </div>
                            <div
                              className="col-lg-6 p-1 rounded"
                              style={{ backgroundColor: "#f7f3f0" }}
                            >
                              <div>
                                <div className="row col-lg-12 p-1">
                                  <h6>Surveyor Charges</h6>
                                </div>
                                <div className="row pl-2">
                                  <div className="col-lg-4 p-0 mb-2">
                                    <label className="form_label mb-0">
                                      Base Price
                                    </label>
                                    <TextField
                                      name="sac_sampling_base_price"
                                      margin="dense"
                                      type="number"
                                      variant="standard"
                                      fullWidth
                                      inputProps={{
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                        },
                                      }}
                                      value={e.sac_sampling_base_price}
                                      onChange={(e) => {
                                        if (
                                          e.target.value === "" ||
                                          pr.test(e.target.value)
                                        ) {
                                          var row = this.state.multiRows;
                                          let sac_sampling_vat_value = Number(
                                            (Number(e.target.value) *
                                              Number(row[idx].vat_percent)) /
                                              100
                                          ).toFixed(2);
                                          let sac_sampling_pph23_value = Number(
                                            (
                                              (Number(e.target.value) *
                                                Number(
                                                  row[idx].pph23_percent
                                                )) /
                                              100
                                            ).toFixed(2)
                                          );
                                          let sac_sampling_total = (
                                            Number(e.target.value) +
                                            Number(sac_sampling_vat_value) -
                                            Number(sac_sampling_pph23_value)
                                          ).toFixed(2);
                                          let sac_sampling_ctc = (
                                            Number(e.target.value) +
                                            Number(sac_sampling_vat_value)
                                          ).toFixed(2);
                                          row[idx].sac_sampling_base_price =
                                            e.target.value;
                                          row[idx].sac_sampling_vat_value =
                                            sac_sampling_vat_value;
                                          row[idx].sac_sampling_pph23_value =
                                            sac_sampling_pph23_value;
                                          row[idx].sac_sampling_total =
                                            sac_sampling_total;
                                          row[idx].sac_sampling_ctc =
                                            sac_sampling_ctc;
                                          this.setState({
                                            multiRows: row,
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                  <div className="col-lg-4 mb-2 pr-0">
                                    <label
                                      className="form_label mb-0"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      VAT %
                                    </label>
                                    <TextField
                                      name="sac_sampling_vat_percent"
                                      margin="dense"
                                      // variant='outlined'
                                      //fullWidth
                                      value={e.sac_sampling_vat_percent}
                                      onChange={(e) => {
                                        if (
                                          e.target.value === "" ||
                                          pr.test(e.target.value)
                                        ) {
                                          var row = this.state.multiRows;

                                          let sac_sampling_vat_value = (
                                            (Number(
                                              row[idx].sac_sampling_base_price
                                            ) *
                                              Number(e.target.value)) /
                                            100
                                          ).toFixed(2);
                                          let sac_sampling_pph23_value = (
                                            (Number(
                                              row[idx].sac_sampling_base_price
                                            ) *
                                              Number(
                                                row[idx]
                                                  .sac_sampling_pph23_percent
                                              )) /
                                            100
                                          ).toFixed(2);
                                          if (
                                            config.company === "SRPL" ||
                                            config.company === "AVS" ||
                                            config.company === "PRPL"
                                          ) {
                                            sac_sampling_pph23_value = 0;
                                          }
                                          let sac_sampling_total = (
                                            Number(
                                              row[idx].sac_sampling_base_price
                                            ) +
                                            Number(sac_sampling_vat_value) -
                                            Number(sac_sampling_pph23_value)
                                          ).toFixed(2);
                                          let sac_sampling_ctc = (
                                            Number(
                                              row[idx].sac_sampling_base_price
                                            ) + Number(sac_sampling_vat_value)
                                          ).toFixed(2);

                                          row[idx].sac_sampling_vat_percent =
                                            e.target.value;
                                          row[idx].sac_sampling_vat_value =
                                            sac_sampling_vat_value;
                                          row[idx].sac_sampling_pph23_value =
                                            sac_sampling_pph23_value;
                                          row[idx].sac_sampling_total =
                                            sac_sampling_total;
                                          row[idx].sac_sampling_ctc =
                                            sac_sampling_ctc;
                                          this.setState({
                                            multiRows: row,
                                          });
                                        }
                                      }}
                                      inputProps={{
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                        },
                                      }}
                                    />
                                  </div>
                                  <div className="col-lg-4 pr-0 mb-2">
                                    <label className="form_label mb-0">
                                      VAT
                                    </label>
                                    <TextField
                                      name="sac_sampling_vat_value"
                                      margin="dense"
                                      // variant='outlined'
                                      fullWidth
                                      inputProps={{
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                        },
                                      }}
                                      value={e.sac_sampling_vat_value}
                                      onChange={(e) => {
                                        if (
                                          e.target.value === "" ||
                                          pr.test(e.target.value)
                                        ) {
                                          var row = this.state.multiRows;
                                          row[idx].sac_sampling_vat_value =
                                            e.target.value;
                                          let sac_sampling_pph23_value = (
                                            (Number(
                                              row[idx].sac_sampling_base_price
                                            ) *
                                              Number(
                                                row[idx]
                                                  .sac_sampling_pph23_percent
                                              )) /
                                            100
                                          ).toFixed(2);
                                          if (
                                            config.company === "SRPL" ||
                                            config.company === "AVS" ||
                                            config.company === "PRPL"
                                          ) {
                                            sac_sampling_pph23_value = 0;
                                          }
                                          let sac_sampling_total = (
                                            Number(
                                              row[idx].sac_sampling_base_price
                                            ) +
                                            Number(e.target.value) -
                                            Number(sac_sampling_pph23_value)
                                          ).toFixed(2);
                                          let sac_sampling_ctc = (
                                            Number(
                                              row[idx].sac_sampling_base_price
                                            ) + Number(e.target.value)
                                          ).toFixed(2);

                                          row[idx].sac_sampling_pph23_value =
                                            sac_sampling_pph23_value;
                                          row[idx].sac_sampling_total =
                                            sac_sampling_total;
                                          row[idx].sac_sampling_ctc =
                                            sac_sampling_ctc;
                                          this.setState({
                                            multiRows: row,
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                  {!(
                                    config.company === "SRPL" ||
                                    config.company === "AVS" ||
                                    config.company === "PRPL"
                                  ) && (
                                    <div className="col-lg-4 mb-2 p-0">
                                      <label
                                        className="contract_display_header_label"
                                        style={{
                                          color: "rgba(72, 59, 52, 0.7)",
                                        }}
                                      >
                                        PPH23 %
                                      </label>
                                      <div
                                        className="contract_display_header_value"
                                        style={{ color: "#42352d" }}
                                      >
                                        {e.sac_sampling_pph23_percent
                                          ? this.toLocaleString(
                                              e.sac_sampling_pph23_percent
                                            )
                                          : null}
                                      </div>
                                    </div>
                                  )}
                                  {!(
                                    config.company === "SRPL" ||
                                    config.company === "AVS" ||
                                    config.company === "PRPL"
                                  ) && (
                                    <div className="col-lg-4 mb-2 pr-0">
                                      <label
                                        className="contract_display_header_label"
                                        style={{
                                          color: "rgba(72, 59, 52, 0.7)",
                                        }}
                                      >
                                        PPH23
                                      </label>
                                      <div
                                        className="contract_display_header_value"
                                        style={{ color: "#42352d" }}
                                      >
                                        {e.sac_sampling_pph23_value
                                          ? this.toLocaleString(
                                              e.sac_sampling_pph23_value
                                            )
                                          : null}
                                      </div>
                                    </div>
                                  )}
                                  <div className="col-lg-6 mb-2 p-0">
                                    <label
                                      className="contract_display_header_label"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Payable to Vendor
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {e.sac_sampling_total
                                        ? this.toLocaleString(
                                            e.sac_sampling_total
                                          )
                                        : null}
                                    </div>
                                  </div>
                                  <div className="col-lg-6 mb-2 pr-0">
                                    <label
                                      className="contract_display_header_label"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Cost to Company
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {e.sac_sampling_ctc
                                        ? this.toLocaleString(
                                            e.sac_sampling_ctc
                                          )
                                        : 0}{" "}
                                      {config.company === "SRPL" ||
                                      config.company === "AVS" ||
                                      config.company === "PRPL"
                                        ? "USD"
                                        : "IDR"}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {this.state.multiRows.length > 1 && (
                          <div className="col-lg-1 d-flex justify-content-center m-auto">
                            <div className="icon_hover text-align-center">
                              <i
                                className="fa fa-trash-o m-auto"
                                onClick={this.removeMultiRowsHandler(idx)}
                                style={{
                                  fontSize: 24,
                                  color: config.themeColor,
                                  cursor: "pointer",
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                    <div className="row mt-2 mb-2">
                      <button
                        type="button"
                        onClick={() => this.addMoreRowsHandler()}
                        className="header_button header_button_text addrow_button_adjustment"
                        style={{ color: config.themeColor }}
                      >
                        Add More
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {/* {(this.state.features.indexOf('530') >= 0 || this.state.features.indexOf('97') >= 0) && */}

              <FileUpload
                files={this.state.files}
                feature_name="Barge_cpc_costing"
                type="Costings"
                invoice_file={this.state.invoice_file}
                callbackFn={(data, mandatoryFiles) => {
                  this.setState({
                    files: data,
                    costing_files: mandatoryFiles,
                  });
                }}
              />
            </div>
          </div>
          <div className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button"
              name="submit"
              onClick={() => this.props.onCancel(false)}
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn next_button"
              name="Back"
              onClick={this.submitHandler}
            >
              Submit
            </button>
          </div>
        </div>
      );
    }
  }
}
