import React, { Component } from "react";
import api from "../../../api/api";
import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import { Alert } from "@material-ui/lab";
import {
  TextField,
  Snackbar,
  InputAdornment,
  MenuItem,
  Checkbox,
  Accordion,
  AccordionSummary,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { withStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import FileUpload from "../../common/FileUpload";
import { localDateFormate, pad, toLocaleString } from "../../common/common";
// import AdvanceAdjustments from "../../contracts/AdvanceAdjustments";
import Loader from "../../common/Loader";
import QualitySelection from "./QualitySelection";
import { CurrencyToCompanyCodes, CurrencyCodesEnum } from '../../../common/enums';

const CheckBox = withStyles({
  root: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);
const NewRadio = withStyles({
  root: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export default class CPSUpdate extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      multiRows: [
        {
          payment_to: "0",
          invoice_date: null,
          ppn: "",
          ppn_date: null,
          payee_name: "",
          pph23_account: "0",
          barge_quantity: "",
          base_price_pmt: "",
          base_price: "",
          vat_percent: "10",
          vat_value: "",
          pph23_percent: "2",
          pph23_value: "",
          payable_to_vendor: "",
          cost_to_company: "",
          remarks: "",
        },
      ],
      isLoading: true,
      activeStep: 0,
      barges: [],
      bargeList: [],
      totalBarges: [],
      barge_id: 1,
      cps_contract_drop_value: [],
      business_no_ids: [],
      pc_contrcts_drop_value: [],
      pc_contract_ids: [],
      features: [],
      files: [],
      purchase_contracts: [],
      cps_contract_id: null,
      qualities: [],
      cps_contracts_list: [],
      selected_cps: { name: "", value: "" },
      expanded: false,
      costing_files: [],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    this.setState({ features: features ? features : [] }, () => {
      if (
        !(
          this.state.features.includes("508") ||
          this.state.features.includes("514") ||
          this.state.features.includes("515") ||
          this.state.features.includes("97")
        )
      ) {
        window.location.href = "/dashboard";
      }
    });

    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    await api
      .getPurchaseContracts(loginUserID, "", idToken)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
              if (res.purchase_contract) {
                let purchaseDataforMenu = [];
                res.purchase_contract
                  .filter(
                    (e, indx) =>
                      e.status !== "cancelled" && e.status !== "Closed"
                  )
                  .forEach((item) => {
                    purchaseDataforMenu.push({
                      name: item.contract_no,
                      value: item.id,
                      key: item.id,
                      vendor_name: item.vendor_name,
                      vendor: item.vendor,
                    });
                  });

                this.setState({
                  purchase_contracts: res.purchase_contract.filter(
                    (e, indx) =>
                      e.status !== "cancelled" && e.status !== "Closed"
                  ),
                  pc_contrcts_drop_value: purchaseDataforMenu,
                  // isLoading: false,
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
    const status = "Active";
    await api
      .get_cps_contracts(loginUserID, idToken, status)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
              if (res.cps_contract_data) {
                this.setState({
                  cps_contracts_list: res.cps_contract_data?.filter(
                    (e) => e.status !== "cancelled"
                  ),
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });

    try {
      var invoiceID = this.props.invoiceID;
    } catch (e) {
      window.location.href = "/barge-list";
    }
    const purchase_contract_id = "",
      cps_contract_id = "";
    await api
      .get_barges_for_cps_costing(
        loginUserID,
        idToken,
        purchase_contract_id,
        cps_contract_id,
        invoiceID
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.barges) {
                let barges = res.barges;
                barges.sort(function (a, b) {
                  return (
                    (a.cps_costing_id === null) - (b.cps_costing_id === null)
                  );
                });
                res.barges = barges;
                const filter_not_costing_barges = res.barges.filter(
                  (e) =>
                    (e.cps_costing_id === null ||
                      e.cps_costing_id === invoiceID) &&
                    e.status === "Active"
                );
                if (filter_not_costing_barges.length === 0) {
                  this.setState({
                    snackBarErrorOpen: true,
                    errorMsg: "No records to display",
                  });
                }
                this.setState({
                  barges: res.barges,
                  totalBarges: filter_not_costing_barges,
                  //isLoading: false
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
    await api
      .get_barge_cps_costing(loginUserID, idToken, invoiceID)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.barge_cps_costing_data) {
                this.setState(
                  {
                    cps_contract_id: res.barge_cps_costing_data.cps_contract_id,
                    invoice_file: res.barge_cps_costing_data.invoice_file,
                    costing_type: res.barge_cps_costing_data.costing_type,
                    costing_no: res.barge_cps_costing_data.costing_no,
                    multiRows: [res.barge_cps_costing_data],
                    barge_cps_costing_data: res.barge_cps_costing_data,
                    bargeList: res.barge_cps_costing_data.barge_group_id
                      ? res.barge_cps_costing_data.barge_group_id.split(",")
                      : [],
                    files: res.barge_cps_costing_data.files
                      ? res.barge_cps_costing_data.files
                      : [],
                    // isLoading: false,
                  },
                  () => {
                    this.getCpsData(res.barge_cps_costing_data.cps_contract_id);
                    this.contractTypeHandler();
                  }
                );
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                successMsg: "",
                isLoading: false,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });

    document.title =
      config.documentTitle +
      "Barge Purchase Financial Coal Procurement Charges";
  }

  contractTypeHandler = () => {
    const { cps_contracts_list = [], costing_type } = this.state || {};
    var filter_costings = [];

    if (costing_type === "Coal Service Fees") {
      filter_costings = cps_contracts_list?.filter(
        (e) => e.contract_type === "Coal Service Fees"
      );
    } else {
      filter_costings = cps_contracts_list?.filter(
        (e) => e.contract_type !== "Coal Service Fees"
      );
    }
    let cps_contract_drop_value = [];
    filter_costings.forEach((item) => {
      cps_contract_drop_value.push({
        name: item.cps_contract_no,
        value: item.cps_id,
        contract_no: item.contract_no,
      });
    });
    this.setState({
      cps_contract_drop_value: cps_contract_drop_value,
    });
  };

  async getCpsContractData(barge_id) {
    // const loginUserID = this.Cookie.getCookie("loginUserId");
    // const idToken = this.Cookie.getIdTokenCookie();
    // api
    //   .get_cps_contract_data_based_on_barge_id(loginUserID, idToken, barge_id)
    //   .then((response) => {
    //     if (response.status >= 200 && response.status < 300) {
    //       response.json().then((res) => {
    //         if (res.code === "200") {
    //           if (res.cps_contract_data) {
    //             var data = this.state.multiRows;
    //             for (var i of data) {
    //               if (res.cps_contract_data.vendor_id) {
    //                 i.payment_to_vendor_name = res.cps_contract_data.vendor_name
    //                   ? res.cps_contract_data.vendor_name
    //                   : "";
    //                 // i.payment_to = res.cps_contract_data.vendor_id ? res.cps_contract_data.vendor_id : '';
    //               } else {
    //                 i.payment_to_vendor_name = res.cps_contract_data
    //                   .other_vendor_name
    //                   ? res.cps_contract_data.other_vendor_name
    //                   : "";
    //                 // i.payment_to = null;
    //               }
    //             }
    //             this.setState({
    //               billing_currency: res.cps_contract_data.cps_currency,
    //               purchase_currency: res.cps_contract_data.cps_currency,
    //               cps_quantity: res.cps_contract_data.quantity_in_mt,
    //               cps_price_type: res.cps_contract_data.price_type,
    //               cps_price:
    //                 res.cps_contract_data.price_type === "Per MT"
    //                   ? res.cps_contract_data.price_pmt
    //                   : "",
    //               cps_total_price:
    //                 res.cps_contract_data.price_type !== "Per MT"
    //                   ? res.cps_contract_data.total_price
    //                   : "",
    //               multiRows: data,
    //               isLoading: false,
    //             });
    //           } else {
    //             alert(
    //               "Unexpected error occured. Please contact administrator."
    //             );
    //           }
    //         } else if (res.code === "601") {
    //           this.setState({
    //             snackBarErrorOpen: true,
    //             errorMsg: res.message,
    //             isLoading: false,
    //           });
    //         } else if (res.code === "607") {
    //           window.location.href = "/logout";
    //         }
    //       });
    //     }
    //   });
  }

  submitHandler = () => {
    this.setState({ isLoading: true });
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    let barge_id = this.state.bargeList.toString();
    let cps_contract_id = this.state.cps_contract_id;
    let cps_data = this.state.multiRows;
    const costing_file = this.state.costing_files?.filter(
      (e) => e.file_content !== "" && e.column === "invoice_file"
    );

    for (var i in cps_data) {
      cps_data[i].costing_type = this.state.costing_type;
      cps_data[i].costing_files = costing_file;
      if (cps_data[i].invoice_no && cps_data[i].invoice_no !== "") {
        cps_data[i].invoice_no = cps_data[0].invoice_no.toUpperCase();
      }
      if (cps_data[i].remarks && cps_data[i].remarks !== "") {
        cps_data[i].remarks = cps_data[0].remarks.toUpperCase();
      }

      this.setState({
        multiRows: cps_data,
      });
    }
    // files validation
    let missing_fields = 0;
    let files_missing = 0;
    var files_data = this.state.files;
    for (var obj of files_data) {
      if (obj.file_type !== "" || obj.file_content !== "") {
        if (obj.file_content !== "") {
          if (obj.file_description === "") {
            missing_fields++;
            obj.file_description_error = true;
          } else {
            obj.file_description = obj.file_description.toUpperCase();
          }
        } else if (obj.file_description !== "") {
          if (obj.file_content === "") {
            files_missing++;
          }
        }
      }
    }
    this.setState({
      files: files_data,
    });

    if (missing_fields > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please fill mandatory fields.",
        submitLoading: false,
      });
      return;
    }
    if (files_missing > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please Select file.",
        submitLoading: false,
      });
      return;
    }
    var existing_file_ids = [];
    this.state.files
      .filter((e) => e.id)
      .forEach((item) => {
        existing_file_ids.push(item.id);
      });
    const new_files = this.state.files.filter((e) => !e.id);
    const content_files = new_files.filter((e) => e.file_content !== "");
    api
      .update_barge_cps_costing(
        loginUserID,
        idToken,
        barge_id,
        cps_contract_id,
        cps_data,
        content_files,
        existing_file_ids
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.props.onUpdateCosting(res.code);
              this.setState({
                snackBarSuccessOpen: true,
                sucessMsg: "Costing created sucessfully.",
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            } else if (res.code === "624") {
              this.setState({
                snackBarErrorOpen: true,
                isLoading: false,
                errorMsg: "Record already exist for given barge",
              });
            }
          });
        }
      });
  };

  onPriceCalculation = (idx) => {
    var row = this.state.multiRows;
    const currency_exchange_rate = parseFloat(
      row[idx].xchg_rate ? row[idx].xchg_rate : 1
    );
    var total_price = 0;
    if (row[idx].price_type === "Per MT") {
      const qty = parseFloat(
        row[idx].barge_quantity ? row[idx].barge_quantity : 0
      ).toFixed(2);
      const price = parseFloat(
        row[idx].base_price_pmt ? row[idx].base_price_pmt : 0
      ).toFixed(2);
      total_price = parseFloat(qty * price * currency_exchange_rate).toFixed(2);
    } else {
      total_price = (
        Number(row[idx].lumpsum_price ? row[idx].lumpsum_price : 0) *
        Number(currency_exchange_rate)
      ).toFixed(2);
    }
    var vat_percent = row[idx].vat_percent ? Number(row[idx].vat_percent) : 10;
    var pph23_percent = row[idx].pph23_percent
      ? Number(row[idx].pph23_percent)
      : 2;
    if (row[idx].pph23_account === "SDAM") {
      let payable_to_vendor = Number(total_price).toFixed(2);
      let base_price = (
        Number(payable_to_vendor * 100) /
        (100 - Number(row[idx].pph23_percent ? row[idx].pph23_percent : 2))
      ).toFixed(2);
      let pph23_value = (
        (Number(base_price) * Number(pph23_percent)) /
        100
      ).toFixed(2);
      let cost_to_company = (Number(base_price) + Number(pph23_value)).toFixed(
        2
      );
      row[idx].base_price = base_price;
      row[idx].pph23_value = pph23_value;
      row[idx].payable_to_vendor = payable_to_vendor;
      row[idx].cost_to_company = cost_to_company;
      this.setState({
        multiRows: row,
      });
    }

    if (row[idx].vat_applicability === "Yes") {
      if (row[idx].vat_type === "Exclusive") {
        let vat_value = ((Number(total_price) * vat_percent) / 100).toFixed(2);
        if (row[idx].vat_applicability !== "Yes") {
          vat_value = 0;
        }
        let price_total = (Number(total_price) + Number(vat_value)).toFixed(2);
        let pph23_value = ((Number(total_price) * pph23_percent) / 100).toFixed(
          2
        );
        let payable_to_vendor = (
          Number(price_total) - Number(pph23_value)
        ).toFixed(2);
        let cost_to_company = price_total;
        if (CurrencyToCompanyCodes[CurrencyCodesEnum.USD].includes(config.company)) {
          payable_to_vendor = price_total;
          pph23_value = "";
        }

        row[idx].base_price = total_price;
        //row[idx].vat_percent = vat_percent;
        row[idx].vat_value = vat_value;
        //row[idx].pph23_percent = pph23_percent;
        row[idx].pph23_value = pph23_value;
        row[idx].payable_to_vendor = payable_to_vendor;
        row[idx].cost_to_company = cost_to_company;

        this.setState({
          multiRows: row,
        });
      } else if (row[idx].vat_type === "Inclusive") {
        let base_price = (
          (Number(total_price) * 100) /
          (100 + Number(row[idx].vat_percent ? row[idx].vat_percent : 10))
        ).toFixed(2);
        let vat_value = ((Number(base_price) * vat_percent) / 100).toFixed(2);
        if (row[idx].vat_applicability !== "Yes") {
          vat_value = 0;
        }
        let pph23_value = ((Number(base_price) * pph23_percent) / 100).toFixed(
          2
        );
        let price_total = (Number(base_price) + Number(vat_value)).toFixed(2);
        let payable_to_vendor = (
          Number(price_total) - Number(pph23_value)
        ).toFixed(2);
        let cost_to_company = price_total;
        if (CurrencyToCompanyCodes[CurrencyCodesEnum.USD].includes(config.company)) {
          payable_to_vendor = price_total;
          pph23_value = "";
        }
        row[idx].base_price = base_price;
        //row[idx].vat_percent = vat_percent;
        row[idx].vat_value = vat_value;
        row[idx].price_total = price_total;
        row[idx].pph23_value = pph23_value;
        row[idx].payable_to_vendor = payable_to_vendor;
        row[idx].cost_to_company = cost_to_company;
        this.setState({
          multiRows: row,
        });
      }
    } else {
      let vat_value = ((Number(total_price) * vat_percent) / 100).toFixed(2);
      if (row[idx].vat_applicability !== "Yes") {
        vat_value = 0;
      }
      let price_total = (Number(total_price) + Number(vat_value)).toFixed(2);
      let pph23_value = ((Number(total_price) * pph23_percent) / 100).toFixed(
        2
      );
      let payable_to_vendor = (
        Number(price_total) - Number(pph23_value)
      ).toFixed(2);
      let cost_to_company = price_total;
      if (CurrencyToCompanyCodes[CurrencyCodesEnum.USD].includes(config.company)) {
        payable_to_vendor = price_total;
        pph23_value = "";
      }
      row[idx].base_price = total_price;
      //row[idx].vat_percent = vat_percent;
      row[idx].vat_value = vat_value;
      //row[idx].pph23_percent = pph23_percent;
      row[idx].pph23_value = pph23_value;
      row[idx].payable_to_vendor = payable_to_vendor;
      row[idx].cost_to_company = cost_to_company;

      this.setState({
        multiRows: row,
      });
    }
  };

  async searchBargeHandler() {
    var comboID = this.props.comboID;
    //let barge_id = comboID.split('$#')[0];
    let costing_id = comboID.split("$#")[1];

    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const purchase_contract_id = this.state.pc_contract_ids;
    const cps_contract_id = this.state.cps_contract_id;
    if (cps_contract_id === null || cps_contract_id === "") {
      this.setState({
        // pc_error: true,
        snackBarErrorOpen: true,
        errorMsg: "Please Select Service Contract",
      });
      return;
    }

    await api
      .get_barges_for_cps_costing(
        loginUserID,
        idToken,
        purchase_contract_id,
        cps_contract_id
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.barges) {
                const filter_not_costing_barges = res.barges.filter(
                  (e) =>
                    (e.cps_costing_id === null ||
                      e.cps_costing_id === costing_id) &&
                    e.status === "Active"
                );
                if (filter_not_costing_barges.length === 0) {
                  this.setState({
                    snackBarErrorOpen: true,
                    errorMsg: "No records to display",
                  });
                }
                this.setState(
                  {
                    totalBarges: filter_not_costing_barges,
                    //isLoading: false
                  },
                  async () => {
                    this.getCpsData(cps_contract_id);
                  }
                );
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  }

  getCpsData = async (contractID) => {
    if (contractID) {
      const loginUserID = this.Cookie.getCookie("loginUserId");
      const idToken = this.Cookie.getIdTokenCookie();
      await api
        .get_cps_contract(loginUserID, idToken, contractID)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                if (res.cps_contract_data) {
                  var data = this.state.multiRows;
                  for (var i of data) {
                    i.billing_currency = res.cps_contract_data.cps_currency
                      ? res.cps_contract_data.cps_currency
                      : "0";
                    if (res.cps_contract_data.vendor_id) {
                      i.vendor_name = res.cps_contract_data.vendor_name
                        ? res.cps_contract_data.vendor_name
                        : "";
                      i.payment_to = res.cps_contract_data.vendor_id
                        ? res.cps_contract_data.vendor_id
                        : "";
                    } else {
                      i.vendor_name = res.cps_contract_data.other_vendor_name
                        ? res.cps_contract_data.other_vendor_name
                        : "";
                      i.payment_to = null;
                    }
                  }
                  this.setState({
                    billing_currency: res.cps_contract_data.cps_currency,
                    purchase_currency: res.cps_contract_data.cps_currency,
                    multiRows: data,
                    vendor_name: res.cps_contract_data.vendor_name,
                    other_vendor_name: res.cps_contract_data.other_vendor_name,
                    qualities: res.cps_contract_data.cps_contract_mappping_data,
                    isLoading: false,
                  });
                }
              } else if (res.code === "601") {
                this.setState({
                  snackBarErrorOpen: true,
                  errorMsg: res.message,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    } else {
      this.setState({ isLoading: false });
    }
  };

  onBargesCheck = (id, barge, checked) => {
    this.setState({ isLoading: true });
    if (checked) {
      let bargeList = this.state.bargeList;

      if (bargeList.length === 0) {
        this.getCpsContractData(id);
        const { quality } = this.state.multiRows[0] || {};

        const find_seleted_quality = this.state.qualities.find(
          (e) => e.id === quality
        );
        let row = this.state.multiRows;
        for (var i in row) {
          row[i].quality = quality;
          row[i].barge_quantity = barge?.barge_quantity_in_mt;
          row[i].price_type = find_seleted_quality?.price_type;
          row[i].base_price_pmt =
            row[i].price_type === "Per MT"
              ? find_seleted_quality?.price_pmt
              : 0;
          row[i].lumpsum_price =
            row[i].price_type !== "Per MT"
              ? find_seleted_quality?.total_price
              : 0;
        }

        this.setState(
          {
            multiRows: row,
          },
          () => this.onPriceCalculation(0)
        );
      }
      bargeList.push(id);
      this.setState({
        bargeList: bargeList,
        isLoading: false,
      });
    } else {
      let data = this.state.bargeList;
      if (data.indexOf(id) >= 0) {
        let idx = data.indexOf(id);
        data.splice(idx, 1);
      }
      if (data.length > 0) {
        this.getCpsContractData(data[0]);
      }

      this.setState({
        bargeList: data,
        isLoading: false,
      });
    }
  };

  onSelectAllBarges = (checked) => {
    this.setState({ isLoading: true });
    let rows = this.state.totalBarges;
    if (checked) {
      let bargeList = [];
      for (var j of rows) {
        bargeList.push(j.id);
      }
      if (bargeList.length > 0) {
        this.getCpsContractData(bargeList[0]);
        let row = this.state.multiRows;
        const { quality } = row[0] || {};
        const find_seleted_quality = this.state.qualities.find(
          (e) => e.id === quality
        );
        for (var i in row) {
          row[i].quality = quality;
          row[i].barge_quantity = find_seleted_quality?.quantity_in_mt;
          row[i].price_type = find_seleted_quality?.price_type;
          row[i].base_price_pmt =
            row[i].price_type === "Per MT"
              ? find_seleted_quality?.price_pmt
              : 0;
          row[i].lumpsum_price =
            row[i].price_type !== "Per MT"
              ? find_seleted_quality?.total_price
              : 0;
        }

        this.setState(
          {
            multiRows: row,
          },
          () => this.onPriceCalculation(0)
        );
      }
      this.setState({
        bargeList: bargeList,
        isLoading: false,
      });
    } else {
      this.setState({
        bargeList: [],
        isLoading: false,
      });
    }
  };

  render() {
    const pr = /^\d*(\.\d{0,4})?$/;
    const quan = /^\d*(\.\d{0,3})?$/;
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div className="container bg-white p-0" style={{ minHeight: "100vh" }}>
          <div className="clearfix content-header border-bottom">
            <div className="row">
              <div className="col-sm text-left pl-0">
                <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                  {`Coal Procurement Service Fee - ${this.state.costing_no}`}
                </h4>
              </div>
            </div>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.sucessMsg}
            </Alert>
          </Snackbar>
          <div className="drawer-section">
            <div className="card">
              {!this.props.updateDocuments && (
                <div>
                  <div style={{ width: "100%" }}>
                    <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                      Select Barge to create costing
                    </h5>
                  </div>
                  {this.state.cps_contract_id && (
                    <div className="row p-2">
                      <div className="col-lg-4 p-0">
                        <Autocomplete
                          options={this.state.cps_contract_drop_value}
                          getOptionLabel={(option) => option.name}
                          renderOption={(option, { selected }) => (
                            <React.Fragment>
                              <div className="col-lg p-0 m-0">
                                <label className="form_label mb-0">
                                  {option.name}
                                </label>
                                <label
                                  className="contract_display_header_value m-0 pl-0"
                                  style={{ fontSize: 10, paddingLeft: 5 }}
                                >
                                  {option.mother_vessel_name
                                    ? "( " + option.mother_vessel_name + " )"
                                    : null}
                                </label>
                              </div>
                            </React.Fragment>
                          )}
                          onChange={(event, value) => {
                            if (value) {
                              //var floating_crane_vendor = data.toString();
                              this.setState({
                                cps_contract_id: value.value,
                                selected_cps: value,
                              });
                            } else {
                              this.setState({
                                cps_contract_id: null,
                                selected_cps: { name: "", value: "" },
                              });
                            }
                          }}
                          name="cpf_contract"
                          size="small"
                          value={this.state.selected_cps}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="CPF Contract No"
                              label="CPF Contract No"
                              variant="outlined"
                              style={{ top: 8 }}
                              fullWidth
                            />
                          )}
                          style={{ display: "contents" }}
                        />
                      </div>
                      <div className="col-lg-6 pr-0 mb-2">
                        <Autocomplete
                          // multiple
                          options={this.state.pc_contrcts_drop_value}
                          getOptionLabel={(option) => option.name}
                          renderOption={(option, { selected }) => (
                            <React.Fragment>
                              <CheckBox
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              <div className="col-lg p-0 m-0">
                                <label className="form_label mb-0">
                                  {option.name}
                                </label>
                                <label
                                  className="contract_display_header_value m-0 pl-0"
                                  style={{ fontSize: 10, paddingLeft: 5 }}
                                >
                                  {option.vendor_name
                                    ? "( " + option.vendor_name + " )"
                                    : null}
                                </label>
                              </div>
                            </React.Fragment>
                          )}
                          onChange={(event, value) => {
                            if (value) {
                              this.setState({
                                pc_contract_ids: value.value,
                                pc_error: false,
                              });
                            } else {
                              this.setState({ pc_contract_ids: null });
                            }
                          }}
                          name="Purchase Contract"
                          size="small"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Purchase Contract"
                              label="Purchase Contract"
                              variant="outlined"
                              style={{ top: 8 }}
                              error={this.state.pc_error}
                              fullWidth
                            />
                          )}
                          style={{ display: "contents" }}
                        />
                      </div>

                      <div className="col-lg pr-0">
                        <button
                          className="header_button header_button_text add_button_adjustment"
                          style={{ width: "auto", marginTop: "28px" }}
                          onClick={() => this.searchBargeHandler()}
                        >
                          <i
                            className="fa fa-search mr-1"
                            aria-hidden="true"
                          ></i>
                          Search
                        </button>
                      </div>
                    </div>
                  )}
                  {this.state.cps_contract_id && (
                    <Accordion
                      expanded={this.state.expanded}
                      className="mt-3 mb-2"
                      style={{
                        border: "#f7f3f0 1px solid",
                        borderTopLeftRadius: "4px",
                        borderTopRightRadius: "4px",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon style={{ color: "#483b34" }} />
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{
                          height: 15,
                          backgroundColor: "#f7f3f0",
                          borderTopLeftRadius: "3px",
                          borderTopRightRadius: "3px",
                        }}
                        className="text-white pl-1"
                        onClick={() =>
                          this.setState({ expanded: !this.state.expanded })
                        }
                      >
                        <h5 className="mb-0 pl-1">Select Barges</h5>
                      </AccordionSummary>
                      <div className="row">
                        <div className="table-responsive">
                          <div className="table border-0">
                            <table className="table table-borderless border-0">
                              <thead className="border-0">
                                <tr>
                                  <th
                                    nowrap="true"
                                    className="p-0 text-center pt-1 border-0"
                                  >
                                    <CheckBox
                                      //value={this.state.totalBarges}
                                      color="primary"
                                      //checked={this.state.totalBarges.length !== 0 && (this.state.bargeList.length === this.state.totalBarges.filter(e => e.barge_pur_quality_results_id === null).length)}
                                      checked={
                                        this.state.totalBarges.length !== 0 &&
                                        this.state.bargeList.length ===
                                        this.state.totalBarges.filter(
                                          (e) =>
                                            e.barge_pur_quality_results_id !==
                                            null
                                        ).length
                                      }
                                      //disabled={this.state.selectedPurchaseContractId === "" || p.purchase_contract_id !== this.state.selectedPurchaseContractId}
                                      // onChange={this.onBargesCheck}
                                      onChange={(e) => {
                                        this.onSelectAllBarges(
                                          e.target.checked
                                        );
                                      }}
                                      className="p-0"
                                    />
                                  </th>
                                  <th
                                    style={{ fontSize: 16 }}
                                    className="table_header_barge_label text-uppercase border-0"
                                    nowrap="true"
                                  >
                                    Barge ID
                                  </th>
                                  <th
                                    style={{ fontSize: 16 }}
                                    className="table_header_barge_label text-uppercase border-0"
                                    nowrap="true"
                                  >
                                    Barge Nomination
                                  </th>
                                  <th
                                    style={{ fontSize: 16 }}
                                    className="table_header_barge_label text-uppercase border-0"
                                    nowrap="true"
                                  >
                                    Jetty
                                  </th>
                                  <th
                                    style={{ fontSize: 16 }}
                                    className="table_header_barge_label text-uppercase border-0"
                                    nowrap="true"
                                  >
                                    Loading Date
                                  </th>
                                  <th
                                    style={{ fontSize: 16 }}
                                    className="table_header_barge_label text-uppercase border-0"
                                    nowrap="true"
                                  >
                                    Business No
                                  </th>
                                  <th
                                    style={{ fontSize: 16 }}
                                    className="table_header_barge_label text-uppercase border-0 text-right"
                                    nowrap="true"
                                  >
                                    Quantity
                                  </th>
                                </tr>
                              </thead>
                              {this.state.totalBarges.map((item, index) => (
                                <tbody key={index}>
                                  <tr className="rounded">
                                    <td className="p-0 text-center">
                                      <CheckBox
                                        value={item.id}
                                        color="primary"
                                        checked={this.state.bargeList.includes(
                                          item.id
                                        )}
                                        onChange={(e) => {
                                          this.onBargesCheck(
                                            e.target.value,
                                            item,
                                            e.target.checked
                                          );
                                        }}
                                      />
                                    </td>
                                    <td>{pad(item.id)}</td>
                                    <td nowrap="true">
                                      {item.barge_nomination}
                                    </td>
                                    <td nowrap="true">{item.loading_jetty}</td>
                                    <td nowrap="true">
                                      {localDateFormate(item.loading_date)}
                                    </td>
                                    <td nowrap="true">{item.loading_jetty}</td>
                                    <td nowrap="true">
                                      {localDateFormate(item.loading_date)}
                                    </td>
                                    <td nowrap="true">{item.business_no}</td>
                                    <td nowrap="true" className="text-right">
                                      {toLocaleString(
                                        item.barge_quantity_in_mt
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                              ))}
                            </table>
                          </div>
                        </div>
                      </div>
                    </Accordion>
                  )}

                  <div style={{ width: "100%" }}>
                    <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                      Add details to create costing
                    </h5>
                  </div>
                  {this.state.multiRows.map((e, idx) => (
                    <div className="row" key={idx}>
                      <div className="col-lg-11 rounded-lg border_dashed p-2 mb-3">
                        <div className="row mb-3">
                          <div className="col-lg-6 pl-0">
                            <div className="row">
                              <div className="col-lg-12 p-0 mb-3">
                                <label className="form_label mb-0">
                                  Payment to
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {e.payment_to_vendor_name
                                    ? e.payment_to_vendor_name
                                    : "-"}
                                </div>
                              </div>
                              <div className="col-lg-12 p-0 mb-3">
                                <label className="form_label mb-0">
                                  Quantity
                                </label>
                                <TextField
                                  name="barge_quantity"
                                  margin="dense"
                                  type="number"
                                  variant="outlined"
                                  fullWidth
                                  inputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                    },
                                  }}
                                  placeholder="Quantity in MT"
                                  value={e.barge_quantity}
                                  onChange={(e) => {
                                    if (
                                      e.target.value === "" ||
                                      quan.test(e.target.value)
                                    ) {
                                      var row = this.state.multiRows;
                                      row[idx].barge_quantity = e.target.value;
                                      this.setState(
                                        {
                                          multiRows: row,
                                        },
                                        () => this.onPriceCalculation(idx)
                                      );
                                    }
                                  }}
                                />
                              </div>
                              <div className="col-lg-12 p-0">
                                <label className="form_label mb-0">
                                  Price Type
                                </label>
                                {this.state.bargeList.length > 0 ? (
                                  <div className="pt-2">{e.price_type}</div>
                                ) : (
                                  <RadioGroup
                                    aria-label="price_type"
                                    name="price_type"
                                    onChange={(e) => {
                                      let row = this.state.multiRows;
                                      row[idx].price_type = e.target.value;
                                      this.setState({
                                        multiRows: row,
                                      });
                                    }}
                                    style={{ flexDirection: "row" }}
                                  >
                                    <FormControlLabel
                                      value="Lumpsum"
                                      control={
                                        <NewRadio
                                          checked={e.price_type === "Lumpsum"}
                                        />
                                      }
                                      label="Lumpsum"
                                    />
                                    <FormControlLabel
                                      value="Per MT"
                                      control={
                                        <NewRadio
                                          checked={e.price_type === "Per MT"}
                                        />
                                      }
                                      label="Per MT"
                                    />
                                  </RadioGroup>
                                )}
                              </div>
                              {e.price_type === "Per MT" ? (
                                <div className="col-lg-6 pl-0 mb-3">
                                  <label className="form_label mb-0">
                                    Base Price PMT
                                  </label>
                                  {this.state.bargeList.length > 0 ? (
                                    <div className="pt-2">
                                      {toLocaleString(e.base_price_pmt)}
                                    </div>
                                  ) : (
                                    <TextField
                                      name="base_price_pmt"
                                      margin="dense"
                                      type="number"
                                      variant="outlined"
                                      fullWidth
                                      placeholder="Base Price PMT"
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment
                                            position="end"
                                            className="adornment_background"
                                          >
                                            {e.billing_currency
                                              ? e.billing_currency
                                              : config.company === "SRPL" ||
                                                config.company === "AVS" ||
                                                config.company === "PRPL"
                                                ? "USD"
                                                : "IDR"}
                                          </InputAdornment>
                                        ),
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                          padding: 0,
                                        },
                                      }}
                                      value={e.base_price_pmt}
                                      onChange={(e) => {
                                        if (
                                          e.target.value === "" ||
                                          pr.test(e.target.value)
                                        ) {
                                          var row = this.state.multiRows;
                                          row[idx].base_price_pmt =
                                            e.target.value;
                                          this.setState(
                                            {
                                              multiRows: row,
                                            },
                                            () => this.onPriceCalculation(idx)
                                          );
                                        }
                                      }}
                                    />
                                  )}
                                </div>
                              ) : (
                                <div className="col-lg-6 pl-0 mb-3">
                                  <label className="form_label mb-0">
                                    Lumpsum Price
                                  </label>
                                  {this.state.bargeList.length > 0 ? (
                                    <div className="pt-2">
                                      {toLocaleString(e.lumpsum_price)}
                                    </div>
                                  ) : (
                                    <TextField
                                      name="lumpsum_price"
                                      margin="dense"
                                      type="number"
                                      variant="outlined"
                                      fullWidth
                                      placeholder="Lumpsum Price"
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment
                                            position="end"
                                            className="adornment_background"
                                          >
                                            {e.billing_currency
                                              ? e.billing_currency
                                              : config.company === "SRPL" ||
                                                config.company === "AVS" ||
                                                config.company === "PRPL"
                                                ? "USD"
                                                : "IDR"}
                                          </InputAdornment>
                                        ),
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                          padding: 0,
                                        },
                                      }}
                                      value={e.lumpsum_price}
                                      onChange={(e) => {
                                        if (
                                          e.target.value === "" ||
                                          pr.test(e.target.value)
                                        ) {
                                          var row = this.state.multiRows;
                                          row[idx].lumpsum_price =
                                            e.target.value;
                                          this.setState(
                                            {
                                              multiRows: row,
                                            },
                                            () => this.onPriceCalculation(idx)
                                          );
                                        }
                                      }}
                                    />
                                  )}
                                </div>
                              )}
                              <div className="col-lg-6 p-0 mb-3">
                                <label className="form_label mb-0">
                                  Billing Currency
                                </label>
                                <TextField
                                  name="billing_currency"
                                  margin="dense"
                                  type="number"
                                  variant="outlined"
                                  fullWidth
                                  placeholder="Billing Currency"
                                  value={e.billing_currency}
                                  onChange={(e) => {
                                    var row = this.state.multiRows;
                                    row[idx].billing_currency = e.target.value;
                                    let stateToUpdate = {};
                                    if (e.target.value === this.state.purchase_currency) {
                                      row[idx].exchange_rate = 1;
                                      row[idx].xchg_rate = 1;
                                      stateToUpdate = {
                                        exchange_rate: 1,
                                        xchg_rate: 1
                                      }
                                    }
                                    this.setState({
                                      ...stateToUpdate,
                                      multiRows: row,
                                    },
                                      () => this.onPriceCalculation(idx));

                                  }}
                                  select
                                >
                                  <MenuItem value="0" disabled>
                                    Select
                                  </MenuItem>
                                  <MenuItem
                                    value={CurrencyToCompanyCodes[CurrencyCodesEnum.USD].includes(config.company) ? CurrencyCodesEnum.USD : CurrencyCodesEnum.IDR}
                                  >
                                    {CurrencyToCompanyCodes[CurrencyCodesEnum.USD].includes(config.company) ? CurrencyCodesEnum.USD : CurrencyCodesEnum.IDR}
                                  </MenuItem>
                                  {CurrencyToCompanyCodes[CurrencyCodesEnum.USD].includes(config.company) && this.state.purchase_currency !== CurrencyCodesEnum.USD && (
                                    <MenuItem
                                      value={CurrencyCodesEnum.IDR}
                                    >
                                      {CurrencyCodesEnum.IDR}
                                    </MenuItem>
                                  )}
                                </TextField>
                              </div>
                              {
                                this.state.purchase_currency !== e.billing_currency ? (<div className="col-lg-6 pr-0 mb-3">
                                  <label className="form_label mb-0">
                                    {`Exchange Rate ${this.state.purchase_currency} to 
                                  ${e.billing_currency}`}
                                  </label>
                                  <TextField
                                    name="exchange_rate"
                                    margin="dense"
                                    variant="outlined"
                                    type="number"
                                    fullWidth
                                    value={e.exchange_rate}
                                    placeholder="Exchange rate"
                                    inputProps={{
                                      style: {
                                        fontFamily: "Poppins",
                                        fontSize: "12px",
                                      },
                                    }}
                                    onChange={(e) => {
                                      const quan = /^\d*(\.\d{0,10})?$/;
                                      if (
                                        e.target.value === "" ||
                                        quan.test(e.target.value)
                                      ) {
                                        var value = e.target.value;
                                        if (value === null || value === "") {
                                          value = 1;
                                        }
                                        if (this.state.purchase_currency === CurrencyCodesEnum.USD) {
                                          value = Number(value);
                                        } else {
                                          value = 1 / Number(value);
                                        }
                                        if (
                                          e.billing_currency === "0" ||
                                          this.state.purchase_currency ===
                                          e.billing_currency
                                        ) {
                                          value = 1;
                                        }
                                        let row = this.state.multiRows;
                                        row[idx].exchange_rate =
                                          e.target.value;
                                        row[idx].xchg_rate = value;
                                        this.setState(
                                          {
                                            exchange_rate: e.target.value,
                                            xchg_rate: value,
                                            multiRows: row,
                                          },
                                          () => this.onPriceCalculation(idx)
                                        );
                                      }
                                    }}
                                  />
                                </div>) : <></>}
                              {config.company !== "SRPL" &&
                                config.company !== "PRPL" && (
                                  <div className="row">
                                    <div className="col-lg-12 p-0 mb-2">
                                      <label className="form_label mb-0">
                                        PPH23 Account
                                      </label>
                                      <TextField
                                        name="pph23_account"
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth
                                        select
                                        inputProps={{
                                          style: {
                                            fontFamily: "Poppins",
                                            fontSize: "14px",
                                          },
                                        }}
                                        value={e.pph23_account}
                                        onChange={(e) => {
                                          var row = this.state.multiRows;
                                          row[idx].pph23_account =
                                            e.target.value;
                                          row[idx].pph23_percent =
                                            e.target.value === "SDAM" ? 2 : 2;
                                          this.setState(
                                            {
                                              multiRows: row,
                                            },
                                            () => this.onPriceCalculation(idx)
                                          );
                                        }}
                                      >
                                        <MenuItem value="0">
                                          Please Select
                                        </MenuItem>
                                        <MenuItem value="Supplier">
                                          Supplier
                                        </MenuItem>
                                        <MenuItem value="SDAM">SDAM</MenuItem>
                                      </TextField>
                                    </div>
                                  </div>
                                )}
                            </div>
                          </div>
                          <div
                            className="col-lg-6 p-1 rounded"
                            style={{ backgroundColor: "#f7f3f0" }}
                          >
                            <div className="row col-lg-12 p-1">
                              <h6>Price</h6>
                              {(config.company === "SRPL" ||
                                config.company === "AVS" ||
                                config.company === "PRPL" ||
                                e.pph23_account === "Supplier") && (
                                  <div className="col-lg-12  p-0">
                                    <label className="form_label mb-0">
                                      VAT Applicability
                                    </label>
                                    <RadioGroup
                                      aria-label="vat_applicability"
                                      name="vat_applicability"
                                      onChange={(e) => {
                                        var row = this.state.multiRows;
                                        row[idx].vat_applicability =
                                          e.target.value;
                                        this.setState(
                                          {
                                            stevedore_costing: row,
                                          },
                                          () => {
                                            this.onPriceCalculation(idx);
                                          }
                                        );
                                      }}
                                      style={{ flexDirection: "row" }}
                                    >
                                      <FormControlLabel
                                        value="Yes"
                                        control={
                                          <NewRadio
                                            checked={
                                              e.vat_applicability === "Yes"
                                            }
                                          />
                                        }
                                        label="Yes"
                                      />
                                      <FormControlLabel
                                        value="No"
                                        control={
                                          <NewRadio
                                            checked={e.vat_applicability === "No"}
                                          />
                                        }
                                        label="No"
                                      />
                                    </RadioGroup>
                                  </div>
                                )}
                              {e.vat_applicability === "Yes" && (
                                <div className="col-lg-12  p-0">
                                  <label className="form_label mb-0">
                                    VAT Consideration
                                  </label>
                                  <RadioGroup
                                    aria-label="vat_type"
                                    name="vat_type"
                                    onChange={(e) => {
                                      var row = this.state.multiRows;
                                      row[idx].vat_type = e.target.value;
                                      this.setState(
                                        {
                                          stevedore_costing: row,
                                        },
                                        () => {
                                          this.onPriceCalculation(idx);
                                        }
                                      );
                                    }}
                                    style={{ flexDirection: "row" }}
                                  >
                                    <FormControlLabel
                                      value="Inclusive"
                                      control={
                                        <NewRadio
                                          checked={e.vat_type === "Inclusive"}
                                        />
                                      }
                                      label="Inclusive"
                                    />
                                    <FormControlLabel
                                      value="Exclusive"
                                      control={
                                        <NewRadio
                                          checked={e.vat_type === "Exclusive"}
                                        />
                                      }
                                      label="Exclusive"
                                    />
                                  </RadioGroup>
                                </div>
                              )}
                            </div>
                            {(config.company === "SRPL" ||
                              config.company === "AVS" ||
                              config.company === "PRPL" ||
                              e.pph23_account === "Supplier") && (
                                <div className="row pl-2">
                                  <div className="col-lg-4 p-0 mb-2">
                                    <label className="form_label mb-0">
                                      Base Price
                                    </label>
                                    <TextField
                                      name="base_price"
                                      margin="dense"
                                      type="number"
                                      variant="standard"
                                      fullWidth
                                      inputProps={{
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                        },
                                      }}
                                      value={e.base_price}
                                      onChange={(e) => {
                                        if (
                                          e.target.value === "" ||
                                          pr.test(e.target.value)
                                        ) {
                                          let row = this.state.multiRows;
                                          let vat_value = Number(
                                            (Number(e.target.value) *
                                              Number(row[idx].vat_percent)) /
                                            100
                                          ).toFixed(2);
                                          if (
                                            row[idx].vat_applicability !== "Yes"
                                          ) {
                                            vat_value = 0;
                                          }
                                          let pph23_value = (
                                            (Number(e.target.value) *
                                              Number(row[idx].pph23_percent)) /
                                            100
                                          ).toFixed(2);
                                          let payable_to_vendor = (
                                            Number(e.target.value) +
                                            Number(vat_value) -
                                            Number(pph23_value)
                                          ).toFixed(2);
                                          let cost_to_company = (
                                            Number(e.target.value) +
                                            Number(vat_value)
                                          ).toFixed(2);
                                          if (
                                            config.company === "SRPL" ||
                                            config.company === "AVS" ||
                                            config.company === "PRPL"
                                          ) {
                                            payable_to_vendor = cost_to_company;
                                            pph23_value = "";
                                          }
                                          row[idx].base_price = e.target.value;
                                          row[idx].vat_value = vat_value;
                                          row[idx].pph23_value = pph23_value;
                                          row[idx].payable_to_vendor =
                                            payable_to_vendor;
                                          row[idx].cost_to_company =
                                            cost_to_company;
                                          this.setState({
                                            multiRows: row,
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                  <div className="col-lg-4 pr-0 mb-2">
                                    <label className="form_label mb-0">
                                      VAT %
                                    </label>

                                    {e.vat_applicability === "Yes" ? (
                                      <TextField
                                        name="vat_percent"
                                        margin="dense"
                                        type="number"
                                        variant="standard"
                                        fullWidth
                                        inputProps={{
                                          style: {
                                            fontFamily: "Poppins",
                                            fontSize: "14px",
                                          },
                                        }}
                                        value={e.vat_percent}
                                        onChange={(e) => {
                                          if (
                                            e.target.value === "" ||
                                            pr.test(e.target.value)
                                          ) {
                                            var row = this.state.multiRows;
                                            // var vat_value = Number((Number(row[idx].base_price) * Number(e.target.value)) / 100).toFixed(2);
                                            // var cost_to_company = (Number(row[idx].base_price) + Number(vat_value)).toFixed(0);
                                            row[idx].vat_percent = e.target.value;
                                            // row[idx].vat_value = vat_value;
                                            // row[idx].cost_to_company = cost_to_company;
                                            // row[idx].payable_to_vendor = cost_to_company;
                                            this.setState(
                                              {
                                                multiRows: row,
                                              },
                                              () => this.onPriceCalculation(idx)
                                            );
                                          }
                                        }}
                                      />
                                    ) : (
                                      <label
                                        className="contract_display_header_value"
                                        style={{ color: "#42352d" }}
                                      >
                                        {e.vat_percent
                                          ? toLocaleString(e.vat_percent)
                                          : null}
                                      </label>
                                    )}
                                  </div>
                                  <div className="col-lg-4 mb-2 pr-0">
                                    <label
                                      className="contract_display_header_label"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      VAT
                                    </label>
                                    <label
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {toLocaleString(e.vat_value)}
                                    </label>
                                  </div>
                                  {!(
                                    config.company === "SRPL" ||
                                    config.company === "AVS" ||
                                    config.company === "PRPL"
                                  ) && (
                                      <div className="col-lg-6 mb-2 pl-0">
                                        <label
                                          className="contract_display_header_label"
                                          style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                        >
                                          PPH23 %
                                        </label>
                                        <TextField
                                          name="pph_percent"
                                          margin="dense"
                                          type="number"
                                          variant="standard"
                                          fullWidth
                                          inputProps={{
                                            style: {
                                              fontFamily: "Poppins",
                                              fontSize: "14px",
                                            },
                                          }}
                                          value={e.pph23_percent}
                                          onChange={(e) => {
                                            if (
                                              e.target.value === "" ||
                                              pr.test(e.target.value)
                                            ) {
                                              var row = this.state.multiRows;
                                              // var vat_value = Number((Number(row[idx].base_price) * Number(e.target.value)) / 100).toFixed(2);
                                              // let pph23_value = Number((Number(row[idx].base_price) * Number(e.target.value)) / 100).toFixed(2);
                                              // var payable_to_vendor = (Number(row[idx].base_price) + Number(row[idx].vat_value) - Number(pph23_value)).toFixed(0);
                                              // if ((config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL")) {
                                              //   payable_to_vendor = (Number(row[idx].base_price) + Number(row[idx].vat_value)).toFixed(0);

                                              // }
                                              // var cost_to_company = (Number(row[idx].base_price) + Number(row[idx].vat_value)).toFixed(0);
                                              row[idx].pph23_percent =
                                                e.target.value;
                                              // row[idx].payable_to_vendor = payable_to_vendor;
                                              // row[idx].cost_to_company = cost_to_company;
                                              this.setState(
                                                {
                                                  multiRows: row,
                                                },
                                                () => this.onPriceCalculation(idx)
                                              );
                                            }
                                          }}
                                        />
                                      </div>
                                    )}
                                  {!(
                                    config.company === "SRPL" ||
                                    config.company === "AVS" ||
                                    config.company === "PRPL"
                                  ) && (
                                      <div className="col-lg-6 mb-2 pl-0">
                                        <label
                                          className="contract_display_header_label"
                                          style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                        >
                                          PPH23 Value
                                        </label>
                                        <div
                                          className="contract_display_header_value"
                                          style={{ color: "#42352d" }}
                                        >
                                          {toLocaleString(e.pph23_value)}
                                        </div>
                                      </div>
                                    )}
                                  <div className="col-lg-6 mb-2 pl-0">
                                    <label
                                      className="contract_display_header_label"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Payable to Vendor
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {toLocaleString(e.payable_to_vendor)}
                                    </div>
                                  </div>
                                  <div className="col-lg-6 mb-2 p-0">
                                    <label
                                      className="contract_display_header_label"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Cost to Company
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {toLocaleString(e.cost_to_company)}
                                      {config.company === "SRPL" ||
                                        config.company === "AVS" ||
                                        config.company === "PRPL"
                                        ? "USD"
                                        : "IDR"}
                                    </div>
                                  </div>
                                </div>
                              )}

                            {e.pph23_account === "SDAM" && (
                              <div className="row p-2">
                                <div className="col-lg-4 mb-2 p-0">
                                  <label
                                    className="contract_display_header_label"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    Base Price
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {toLocaleString(e.base_price)}
                                  </div>
                                </div>
                                <div className="col-lg-4 mb-2 pr-0">
                                  <label
                                    className="contract_display_header_label"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    PPH23 %
                                  </label>
                                  {/* <div className='contract_display_header_value' style={{ color: '#42352d' }}>{e.pph23_percent}</div> */}
                                  <TextField
                                    name="pph_percent"
                                    margin="dense"
                                    type="number"
                                    variant="standard"
                                    fullWidth
                                    inputProps={{
                                      style: {
                                        fontFamily: "Poppins",
                                        fontSize: "14px",
                                      },
                                    }}
                                    value={e.pph23_percent}
                                    onChange={(e) => {
                                      if (
                                        e.target.value === "" ||
                                        pr.test(e.target.value)
                                      ) {
                                        var row = this.state.multiRows;
                                        // var vat_value = Number((Number(row[idx].base_price) * Number(e.target.value)) / 100).toFixed(2);
                                        // let pph23_value = Number((Number(row[idx].base_price) * Number(e.target.value)) / 100).toFixed(2);
                                        // var payable_to_vendor = (Number(row[idx].base_price) + Number(row[idx].vat_value) - Number(pph23_value)).toFixed(0);
                                        // if ((config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL")) {
                                        //   payable_to_vendor = (Number(row[idx].base_price) + Number(row[idx].vat_value)).toFixed(0);

                                        // }
                                        // var cost_to_company = (Number(row[idx].base_price) + Number(row[idx].vat_value)).toFixed(0);
                                        row[idx].pph23_percent = e.target.value;
                                        // row[idx].payable_to_vendor = payable_to_vendor;
                                        // row[idx].cost_to_company = cost_to_company;
                                        this.setState(
                                          {
                                            multiRows: row,
                                          },
                                          () => this.onPriceCalculation(idx)
                                        );
                                      }
                                    }}
                                  />
                                </div>
                                <div className="col-lg-4 mb-2 pr-0">
                                  <label
                                    className="contract_display_header_label"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    PPH23
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {toLocaleString(e.pph23_value)}
                                  </div>
                                </div>
                                <div className="col-lg-6 mb-2 p-0">
                                  <label className="form_label mb-0">
                                    Payable To Vendor
                                  </label>
                                  <TextField
                                    name="payable_to_vendor"
                                    margin="dense"
                                    type="number"
                                    //variant='outlined'
                                    fullWidth
                                    inputProps={{
                                      style: {
                                        fontFamily: "Poppins",
                                        fontSize: "14px",
                                      },
                                    }}
                                    value={e.payable_to_vendor}
                                    onChange={(e) => {
                                      if (
                                        e.target.value === "" ||
                                        pr.test(e.target.value)
                                      ) {
                                        var row = this.state.multiRows;
                                        let base_price = (
                                          (Number(e.target.value) * 100) /
                                          (100 -
                                            Number(
                                              row[idx].pph23_percent
                                                ? row[idx].pph23_percent
                                                : 2
                                            ))
                                        ).toFixed(2);
                                        let pph23_value = (
                                          (Number(base_price) *
                                            Number(row[idx].pph23_percent)) /
                                          100
                                        ).toFixed(2);
                                        var cost_to_company = (
                                          Number(base_price) +
                                          Number(pph23_value)
                                        ).toFixed(2);
                                        row[idx].payable_to_vendor =
                                          e.target.value;
                                        row[idx].base_price = base_price;
                                        row[idx].pph23_value = pph23_value;
                                        row[idx].cost_to_company =
                                          cost_to_company;
                                        this.setState({
                                          multiRows: row,
                                        });
                                      }
                                    }}
                                  />
                                </div>
                                <div className="col-lg-6 mb-2 pr-0">
                                  <label
                                    className="contract_display_header_label"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    Cost to Company
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {toLocaleString(e.cost_to_company)}
                                    {config.company === "SRPL" ||
                                      config.company === "AVS" ||
                                      config.company === "PRPL"
                                      ? "USD"
                                      : "IDR"}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="row mb-0">
                          <div className="col-lg-12 p-0">
                            <label className="form_label mb-0">Remarks</label>
                            <TextField
                              name="remarks"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              placeholder="Remarks"
                              value={e.remarks}
                              multiline
                              rows={3}
                              onChange={(e) => {
                                var row = this.state.multiRows;
                                row[idx].remarks = e.target.value;
                                this.setState({
                                  multiRows: row,
                                });
                              }}
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                            />
                          </div>
                        </div>
                        {/* <AdvanceAdjustments
                          adjustments={e.advance_adjustments}
                          vendorType="Coal"
                          bargeID={this.state.bargeList[0]}
                          callbackFn={(data) => {
                            let stateCopy = this.state.multiRows;
                            stateCopy[idx].advance_adjustments = data;
                            this.setState({ multiRows: stateCopy });
                          }}
                        /> */}
                      </div>
                      {this.state.multiRows.length > 1 && (
                        <div className="col-lg-1 d-flex justify-content-center m-auto">
                          <div className="icon_hover text-align-center">
                            <i
                              className="fa fa-trash-o m-auto"
                              onClick={this.removeProcurementRowsHandler(idx)}
                              style={{
                                fontSize: 24,
                                color: config.themeColor,
                                cursor: "pointer",
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}

              {/* {(this.state.features.indexOf("518") >= 0 ||
                this.state.features.indexOf("97") >= 0) && ( */}
              <FileUpload
                files={this.state.files}
                type="Costings"
                invoice_file={this.state.invoice_file}
                feature_name="Barge_cps_costing"
                callbackFn={(data, mandatoryFiles) => {
                  this.setState({
                    files: data,
                    costing_files: mandatoryFiles,
                  });
                }}
              />
            </div>
          </div>
          <div className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button"
              name="submit"
              onClick={() => this.props.onCancel(false)}
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn next_button"
              name="Back"
              onClick={this.submitHandler}
            >
              Submit
            </button>
          </div>
          <QualitySelection
            bargeAgreements={this.state.qualities}
            callbackFn={(qualityInfo) => {
              const { id } = qualityInfo || {};
              this.setState({ selected_quality: id });
            }}
          />
        </div>
      );
    }
  }
}
