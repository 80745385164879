import React, { Component } from 'react'
import api from "../../../api/api";
import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import { Alert } from "@material-ui/lab";
import {
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { withStyles } from "@material-ui/core/styles";
import FileUpload from "../../common/FileUpload";
import Loader from "../../common/Loader";
import { toLocaleString } from '../../common/common';

const NewRadio = withStyles({
  root: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);
export default class LaporanChargesUpdate extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      invoice_date: null,
      ppn: "",
      billing_currency:
        config.company === "SRPL" || config.company === "AVS" || config.company === "PRPL" ? "USD" : "IDR",
      dc_laprn_surv_party: "Third Party",
      dc_laprn_surv_by: "",
      dc_laprn_surv_currency: "0",
      dc_laprn_surv_currency_xchg_rate: "",
      dc_laprn_surv_ctc_in_local_currency: "",
      dc_laprn_surv_price_per_mt: "",
      dc_laprn_surv_base_price: "",
      dc_laprn_surv_vat_percent: "10",
      dc_laprn_surv_vat_value: "",
      dc_laprn_pph23_account:'',
      dc_laprn_surv_pph23_percent: "2",
      dc_laprn_surv_pph23_value: "",
      dc_laprn_surv_price_type:"Per MT",
      dc_laprn_surv_total: "",
      dc_laprn_surv_ctc: "",
      dc_cek_fisic: "",
      isLoading: true,
      features: [],
      files: [],
      costing_files: [],
      barge_id:''
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    // if (!(features.includes('184')  || features.includes('97') )) {
    //   window.location.href = '/dashboard';
    // }
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    // for get_Currencies
    await api.getCurrencies(loginUserID, idToken).then((response) => {

      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            if (res.Currency) {
              var currencies = [
                <MenuItem
                  style={{ fontFamily: "Poppins", fontSize: "14px" }}
                  value="0"
                  key={-1}
                  disabled
                >
                  Please Select
                </MenuItem>,
              ];
              for (var i in res.Currency) {
                currencies.push(
                  <MenuItem
                    style={{ fontFamily: "Poppins", fontSize: "14px" }}
                    value={res.Currency[i].currency}
                    key={[i]}
                  >
                    {res.Currency[i].currency}
                  </MenuItem>
                );
              }
              this.setState({
                currencyData: currencies,
              });
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          } else if (res.code === "601") {
            this.setState({
              errorMsg: res.message,
              sucessMsg: "",
              // isLoading: false
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });

    // calling Vendors Api
    await api.getVendors(loginUserID, idToken, "Active").then((response) => {

      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            if (res.vendors) {
              const surveyors = [];
              const vendorAsOthers = [];
              for (var i of res.vendors) {
                if (i.vendor_type === "Surveyor") {
                  surveyors.push({ name: i.vendor_name, value: i.id, key: i });
                }
                if (i.vendor_type === "Others" || i.vendor_type === "Coal") {
                  vendorAsOthers.push({
                    name: i.vendor_name,
                    value: i.id,
                    key: i,
                  });
                }
              }
              this.setState({
                vendorTypeAsSurveyors: surveyors,
                documentation_agent_drop_value: vendorAsOthers,
              });
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });

    const laparon_charges_id = this.props.laparon_charges_id;
    await api
      .get_barge_laparon_survey_charges(loginUserID, idToken, laparon_charges_id)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.barge_laparon_survey_charges) {
                let invoice_details = {

                  ...this.state.invoiceDetails,

                  invoice_no: res.barge_laparon_survey_charges[0].invoice_no,
                  invoice_date: res.barge_laparon_survey_charges[0].invoice_date,
                  ppn: res.barge_laparon_survey_charges[0].ppn,
                  ppn_date: res.barge_laparon_survey_charges[0].ppn_date,
                };


                this.setState((prevState) => ({
                  ...prevState,
                  invoiceDetails: invoice_details,
                  ...res.barge_laparon_survey_charges[0],
                  isLoading: false,
                }))

              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });

    document.title =
      config.documentTitle + "Barge Purchase Financial Laporan Charges";
  }

  lapranCalculationHandler() {
    var Quantity = this.state.dc_laprn_quantity_in_mt
      ? this.state.dc_laprn_quantity_in_mt
      : 1;
    var laprn_price_pmt = this.state.dc_laprn_surv_price_per_mt
      ? this.state.dc_laprn_surv_price_per_mt
      : 0;
    var exchange_rate = this.state.dc_laprn_surv_currency_xchg_rate
      ? this.state.dc_laprn_surv_currency_xchg_rate
      : 1;

    let dc_laprn_surv_base_price = (
      (Number(Quantity) * Number(laprn_price_pmt)) /
      exchange_rate
    ).toFixed(4);
    if (this.state.dc_laprn_surv_price_type === "Lumpsum") {
      dc_laprn_surv_base_price = (
        (Number(laprn_price_pmt)) /
        exchange_rate
      ).toFixed(4);
    }
    let dc_laprn_surv_vat_percent = this.state.dc_laprn_surv_vat_percent ? this.state.dc_laprn_surv_vat_percent : 10;
    let dc_laprn_surv_pph23_percent = this.state.dc_laprn_surv_pph23_percent ? this.state.dc_laprn_surv_pph23_percent : 2;

    let dc_laprn_surv_vat_value = (
      (Number(dc_laprn_surv_base_price) * dc_laprn_surv_vat_percent) /
      100
    ).toFixed(2);
    let dc_laprn_surv_pph23_value = (
      (Number(dc_laprn_surv_base_price) * dc_laprn_surv_pph23_percent) /
      100
    ).toFixed(2);
    if (config.company === "SRPL" || config.company === "AVS" || config.company === "PRPL") {
      dc_laprn_surv_pph23_value = 0;
    }
    let dc_laprn_surv_total = (
      Number(dc_laprn_surv_base_price) +
      Number(dc_laprn_surv_vat_value) -
      Number(dc_laprn_surv_pph23_value)
    ).toFixed(2);
    let dc_laprn_surv_ctc = (
      Number(dc_laprn_surv_base_price) + Number(dc_laprn_surv_vat_value)
    ).toFixed(2);

    this.setState({
      dc_laprn_surv_base_price: dc_laprn_surv_base_price,
      dc_laprn_surv_vat_percent: dc_laprn_surv_vat_percent,
      dc_laprn_surv_vat_value: dc_laprn_surv_vat_value,
      dc_laprn_surv_pph23_percent: dc_laprn_surv_pph23_percent,
      dc_laprn_surv_pph23_value: dc_laprn_surv_pph23_value,
      dc_laprn_surv_total: dc_laprn_surv_total,
      dc_laprn_surv_ctc: dc_laprn_surv_ctc,
    });
  }

  submitHandler = () => {
    this.setState({ isLoading: true });
    let {
      invoice_no,
      invoice_date,
      ppn,
      billing_currency,
      dc_laprn_surv_party,
      dc_laprn_surv_by,
      dc_laprn_quantity_in_mt,
      dc_laprn_surv_price_per_mt,
      dc_laprn_surv_base_price,
      dc_laprn_surv_vat_percent,
      dc_laprn_surv_vat_value,
      dc_laprn_surv_pph23_percent,
      dc_laprn_surv_pph23_value,
      dc_laprn_surv_total,
      dc_laprn_surv_ctc,
      dc_laprn_surv_currency,
      dc_laprn_surv_currency_xchg_rate,
      dc_laprn_surv_ctc_in_local_currency,
      dc_cek_fisic,
      files,
      costing_files,
      dc_laprn_pph23_account,
      dc_laprn_surv_price_type,
      barge_id
    } = this.state;

    if (billing_currency === "USD" && (config.company === "SRPL" || config.company === "PRPL" || config.company === "AVS")) {
      dc_laprn_surv_currency_xchg_rate = 1;
    }

    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    /**
     *  Checking Mandatory files
     */
    let missing_fields = 0;
    let files_missing = 0;
    var files_data = this.state.files;
    for (var obj of files_data) {
      if (obj.file_type !== "" || obj.file_content !== "") {
        if (obj.file_content !== "") {
          if (obj.file_description === "") {
            missing_fields++;
            obj.file_description_error = true;
          } else {
            obj.file_description = obj.file_description.toUpperCase();
          }
        } else if (obj.file_description !== "") {
          if (obj.file_content === "") {
            files_missing++;
          }
        }
      }
    }
    this.setState({
      files: files_data,
    });

    if (missing_fields > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please fill mandatory fields.",
      });
      return;
    }
    if (files_missing > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please Select file.",
      });
      return;
    }
    const existing_file_ids = [];
    const existing_files = files_data.filter((e) => e.id);
    for (var j of existing_files) {
      existing_file_ids.push(j.id);
    }
    const new_files = files_data.filter((e) => !e.id);
    const content_files = new_files.filter((e) => e.file_content !== "");
    var doc_charges_id = this.props.docID;
    const costing = this.state.costing_files?.filter(
      (e) => e.file_content !== "" && e.column === "invoice_file"
    );
    const laparon_charges_id = this.props.laparon_charges_id;
    api
      .update_barge_laparon_survey_charges(
        loginUserID,
        idToken,
        barge_id,
        laparon_charges_id,
        invoice_no,
        invoice_date,
        ppn,
        dc_laprn_surv_party,
        dc_laprn_surv_by,
        dc_laprn_quantity_in_mt,
        dc_laprn_surv_price_type,
        dc_laprn_surv_price_per_mt,
        dc_laprn_pph23_account,
        dc_laprn_surv_base_price,
        dc_laprn_surv_vat_percent,
        dc_laprn_surv_vat_value,
        dc_laprn_surv_pph23_percent,
        dc_laprn_surv_pph23_value,
        dc_laprn_surv_total,
        dc_laprn_surv_ctc,
        billing_currency,
        dc_laprn_surv_currency_xchg_rate,
        dc_laprn_surv_ctc_in_local_currency,
        dc_cek_fisic,
        files,
        existing_file_ids,
        costing_files
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            this.props.onUpdateCosting(res.code);
            if (res.code === "200") {
              this.setState({
                snackBarSuccessOpen: true,
                sucessMsg: "Costing Updated sucessfully.",
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  };

  render() {
    const pr = /^\d*(\.\d{0,4})?$/;
    const quan = /^\d*(\.\d{0,3})?$/;
    const re = /^[0-9]*\.?[0-9]*$/;
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div className="container bg-white p-0" style={{ minHeight: "100vh" }}>
          <div className="clearfix content-header border-bottom">
            <div className="row">
              <div className="col-sm text-left pl-0">
                <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                  {`Laporan Charges - ${this.state.costing_no}`}{" "}
                </h4>
              </div>
            </div>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.sucessMsg}
            </Alert>
          </Snackbar>
          {this.props.updateDocuments ? (
            <div className="drawer-section">
              <div className="row">
                <div className="col-12">
                  <FileUpload
                    files={this.state.files}
                    type="Costings"
                    invoice_file={this.state.invoice_file}
                    feature_name="Barge_doc_charges"
                    callbackFn={(data, mandatoryFiles) => {
                      this.setState({
                        files: data,
                        costing_files: mandatoryFiles,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="drawer-section">
              <div className="card">

                <div className="" style={{ width: "100%" }}>
                  <div style={{ width: "100%" }}>
                    <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                      Add details to create Laporan Charges costing
                    </h5>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 border_dashed rounded_lg p-2 mb-3">
                      <div className="row mb-3">
                        <div className="col-lg-6 pl-0">
                          <div className="row">
                            <div className="col-lg-12 pl-0">
                              <label className="form_label mb-0">
                                Laporan Survey Party
                              </label>
                              <RadioGroup
                                aria-label="dc_laprn_surv_party"
                                name="dc_laprn_surv_party"
                                onChange={
                                  this.handleLaporanSurveyByadioButtons
                                }
                                style={{ flexDirection: "row" }}
                              >
                                <FormControlLabel
                                  value="Third Party"
                                  control={
                                    <NewRadio
                                      checked={
                                        this.state.dc_laprn_surv_party ===
                                        "Third Party"
                                      }
                                    />
                                  }
                                  label="Third Party"
                                />
                                <FormControlLabel
                                  value="Internal"
                                  control={
                                    <NewRadio
                                      checked={
                                        this.state.dc_laprn_surv_party ===
                                        "Internal"
                                      }
                                    />
                                  }
                                  label="Internal"
                                />
                              </RadioGroup>
                            </div>
                            <div className="col-lg-6 p-0 mb-3">
                              <label className="form_label mb-2">
                                Laporan Survey by
                              </label>
                              <Autocomplete
                                options={this.state.vendorTypeAsSurveyors}
                                getOptionLabel={(option) => option.name}
                                size="small"
                                onChange={(event, value) => {
                                  if (value) {
                                    this.setState({
                                      dc_laprn_surv_by: value.value,
                                      dc_laprn_surv_name: value,
                                    });
                                  } else {
                                    this.setState({
                                      dc_laprn_surv_by: "",
                                      dc_laprn_surv_name: {
                                        name: "",
                                        value: "",
                                      },
                                    });
                                  }
                                }}
                                name="vendorName"
                                value={this.state.dc_laprn_surv_name}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="Search Vendor"
                                    variant="outlined"
                                    //error={this.state.vendorTypeError}
                                    fullWidth
                                  />
                                )}
                              />
                            </div>
                            <div className="col-lg-6 pr-0 mb-2">
                              <label className="form_label mb-0">
                                Quantity in MT
                              </label>
                              <TextField
                                name="dc_laprn_quantity_in_mt"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                type="number"
                                inputProps={{
                                  style: {
                                    fontFamily: "Poppins",
                                    // fontSize: '14px '
                                  },
                                }}
                                value={this.state.dc_laprn_quantity_in_mt}
                                placeholder="Quantity in MT"
                                onChange={(e) => {
                                  if (
                                    e.target.value === "" ||
                                    quan.test(e.target.value)
                                  ) {
                                    this.setState(
                                      {
                                        dc_laprn_quantity_in_mt:
                                          e.target.value,
                                      },
                                      () => this.lapranCalculationHandler()
                                    );
                                  }
                                }}
                              />
                            </div>
                            <div className="col-lg-12 pl-0">
                              <label className="form_label mb-0">
                                Price Type
                              </label>
                              <RadioGroup
                                aria-label="dc_laprn_surv_price_type"
                                name="dc_laprn_surv_price_type"
                                onChange={(e) => {
                                  this.setState({ dc_laprn_surv_price_type: e.target.value },
                                    () => this.lapranCalculationHandler())
                                }}
                                style={{ flexDirection: "row" }}
                              >
                                <FormControlLabel
                                  value="Per MT"
                                  control={
                                    <NewRadio
                                      checked={
                                        this.state.dc_laprn_surv_price_type ===
                                        "Per MT"
                                      }
                                    />
                                  }
                                  label="Per MT"
                                />
                                <FormControlLabel
                                  value="Lumpsum"
                                  control={
                                    <NewRadio
                                      checked={
                                        this.state.dc_laprn_surv_price_type ===
                                        "Lumpsum"
                                      }
                                    />
                                  }
                                  label="Lumpsum"
                                />
                              </RadioGroup>
                            </div>
                            {(this.state.dc_laprn_surv_party ===
                              "Third Party" ||
                              this.state.dc_laprn_surv_party ===
                              "Internal") && (
                                <div className="col-lg-12 p-0 mb-2">
                                  <label className="form_label mb-0">
                                    {this.state.dc_laprn_surv_price_type === "Per MT" ? "Price" : "Lumpsum"}
                                  </label>
                                  <TextField
                                    name="dc_laprn_surv_price_per_mt"
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth
                                    value={
                                      this.state.dc_laprn_surv_price_per_mt
                                    }
                                    placeholder="Laporan Survey Price per MT"
                                    onChange={(e) => {
                                      if (
                                        e.target.value === "" ||
                                        pr.test(e.target.value)
                                      ) {
                                        this.setState(
                                          {
                                            dc_laprn_surv_price_per_mt:
                                              e.target.value,
                                          },
                                          () => this.lapranCalculationHandler()
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              )}
                            {(config.company === "SRPL" || config.company === "AVS" ||
                              config.company === "PRPL") &&
                              this.state.dc_laprn_surv_currency !== "USD" &&
                              this.state.dc_laprn_surv_currency !== "0" && (
                                <div className="col p-0 mb-2">
                                  <label className="form_label mb-0">
                                    Exchange rate from USD to{" "}
                                    {this.state.dc_laprn_surv_currency}
                                  </label>
                                  <TextField
                                    name="dc_laprn_surv_currency_xchg_rate"
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth
                                    inputProps={{
                                      style: {
                                        fontFamily: "Poppins",
                                        fontSize: "14px",
                                      },
                                    }}
                                    placeholder="Exchange rate to USD"
                                    value={
                                      this.state
                                        .dc_laprn_surv_currency_xchg_rate
                                    }
                                    type="number"
                                    onChange={(e) => {
                                      if (
                                        e.target.value === "" ||
                                        re.test(e.target.value)
                                      ) {
                                        var value = e.target.value;
                                        if (value === "") {
                                          value = "1";
                                        }
                                        this.setState(
                                          {
                                            dc_laprn_surv_currency_xchg_rate:
                                              e.target.value,
                                          },
                                          () =>
                                            this.lapranCalculationHandler()
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              )}
                            {(config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") && (
                                <div className="row">
                                  <div className="col-lg-6 p-0">
                                    <label className="form_label mb-0">
                                      Cek Fisic
                                    </label>
                                    <TextField
                                      name="dc_cek_fisic"
                                      margin="dense"
                                      variant="outlined"
                                      fullWidth
                                      value={this.state.dc_cek_fisic}
                                      placeholder="Cek Fisic"
                                      onChange={(e) => {
                                        if (
                                          e.target.value === "" ||
                                          pr.test(e.target.value)
                                        ) {
                                          this.setState({
                                            dc_cek_fisic: e.target.value,
                                          });
                                        }
                                      }}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            IDR
                                          </InputAdornment>
                                        ),
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                        },
                                      }}
                                    />
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                        <div
                          className="col-lg-6 p-2 rounder"
                          style={{ backgroundColor: "#f7f3f0" }}
                        >
                          <div className="row col-lg-12 p-1">
                            <h6>Laporan Survey Charges</h6>
                          </div>
                          <div className="row pl-2">
                            <div className="col-lg-4 p-0 mb-2">
                              <label className="form_label mb-0">
                                Base Price
                              </label>
                              <TextField
                                name="dc_laprn_surv_base_price"
                                margin="dense"
                                variant="standard"
                                inputProps={{
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                  },
                                }}
                                //fullWidth
                                value={this.state.dc_laprn_surv_base_price}
                                onChange={(e) => {
                                  if (
                                    e.target.value === "" ||
                                    pr.test(e.target.value)
                                  ) {
                                    var value = e.target.value;
                                    if (value === "") {
                                      value = "1";
                                    }
                                    let dc_laprn_surv_vat_value = (
                                      (Number(value) *
                                        Number(this.state.dc_laprn_surv_vat_percent)) /
                                      100
                                    ).toFixed(2);
                                    let dc_laprn_surv_pph23_value = (
                                      (Number(value) *
                                        Number(this.state.dc_pph23_percent)) /
                                      100
                                    ).toFixed(2);
                                    if (
                                      config.company === "SRPL" || config.company === "AVS" ||
                                      config.company === "PRPL"
                                    ) {
                                      dc_laprn_surv_pph23_value = 0;
                                    }
                                    this.setState({
                                      dc_laprn_surv_base_price:
                                        e.target.value,
                                      dc_laprn_surv_vat_value:
                                        dc_laprn_surv_vat_value,
                                      dc_laprn_surv_pph23_value:
                                        dc_laprn_surv_pph23_value,
                                      dc_laprn_surv_total: (
                                        Number(e.target.value) +
                                        Number(dc_laprn_surv_vat_value) -
                                        Number(dc_laprn_surv_pph23_value)
                                      ).toFixed(2),
                                      dc_laprn_surv_ctc: (
                                        Number(e.target.value) +
                                        Number(dc_laprn_surv_vat_value)
                                      ).toFixed(2),
                                    });
                                  }
                                }}
                              />
                            </div>
                            <div className="col-lg-4 mb-2 pr-0">
                              <label
                                className="form_label mb-0"
                                style={{ color: "rgba(72, 59, 52, 0.7)" }}
                              >
                                VAT %
                              </label>
                              <TextField
                                name="dc_laprn_surv_vat_percent"
                                margin="dense"
                                variant="standard"
                                fullWidth
                                inputProps={{
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                  },
                                }}
                                value={this.state.dc_laprn_surv_vat_percent}
                                onChange={(e) => {
                                  if (
                                    e.target.value === "" ||
                                    pr.test(e.target.value)
                                  ) {
                                    this.setState(
                                      {
                                        dc_laprn_surv_vat_percent: e.target.value,
                                      },
                                      () => {
                                        this.lapranCalculationHandler()
                                      }
                                    );
                                  }
                                }}
                              />
                              {/* <label
                                className="contract_display_header_label"
                                style={{ color: "rgba(72, 59, 52, 0.7)" }}
                              >
                                VAT %
                              </label>
                              <div
                                className="contract_display_header_value"
                                style={{ color: "#42352d" }}
                              >
                                {this.state.dc_laprn_surv_vat_percent}
                              </div> */}
                            </div>
                            <div className="col-lg-4 mb-2 pr-0">
                              <label className="form_label mb-0">VAT</label>
                              <TextField
                                name="dc_laprn_surv_vat_value"
                                margin="dense"
                                variant="standard"
                                fullWidth
                                inputProps={{
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                  },
                                }}
                                value={this.state.dc_laprn_surv_vat_value}
                                onChange={(e) => {
                                  if (
                                    e.target.value === "" ||
                                    pr.test(e.target.value)
                                  ) {
                                    this.setState({
                                      dc_laprn_surv_vat_value: e.target.value,
                                      dc_laprn_surv_total: (
                                        Number(
                                          this.state.dc_laprn_surv_base_price
                                        ) +
                                        Number(e.target.value) -
                                        Number(
                                          this.state.dc_laprn_surv_pph23_value
                                        )
                                      ).toFixed(2),
                                      dc_laprn_surv_ctc: (
                                        Number(
                                          this.state.dc_laprn_surv_base_price
                                        ) + Number(e.target.value)
                                      ).toFixed(2),
                                    });
                                  }
                                }}
                              />
                            </div>
                            {(config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") && (
                                <div className="col-lg-6 mb-2 p-0">
                                  <label
                                    className="contract_display_header_label"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    PPH23 %
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {this.state.dc_laprn_surv_pph23_percent}
                                  </div>
                                </div>
                              )}
                            {(config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") && (
                                <div className="col-lg-6 mb-2 pr-0">
                                  <label
                                    className="contract_display_header_label"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    PPH23
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {toLocaleString(
                                      this.state.dc_laprn_surv_pph23_value
                                    )}
                                  </div>
                                </div>
                              )}
                            <div className="col-lg-6 mb-2 p-0">
                              <label
                                className="contract_display_header_label"
                                style={{ color: "rgba(72, 59, 52, 0.7)" }}
                              >
                                Payable to Vendor
                              </label>
                              <div
                                className="contract_display_header_value"
                                style={{ color: "#42352d" }}
                              >
                                {toLocaleString(this.state.dc_laprn_surv_total)}
                              </div>
                            </div>
                            <div className="col-lg-6 mb-2 pr-0">
                              <label
                                className="contract_display_header_label"
                                style={{ color: "rgba(72, 59, 52, 0.7)" }}
                              >
                                Cost to Company
                              </label>
                              <div
                                className="contract_display_header_value"
                                style={{ color: "#42352d" }}
                              >
                                {toLocaleString(
                                  this.state.dc_laprn_surv_ctc
                                )}
                                {config.company === "SRPL" || config.company === "AVS" ||
                                  config.company === "PRPL"
                                  ? "USD"
                                  : "IDR"}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* {(this.state.features.includes("368")  ||
                          this.state.features.includes("97") ) && ( */}
                  <FileUpload
                    files={this.state.files}
                    invoice_file={this.state.invoice_file}
                    type="Costings"
                    feature_name="Barge_doc_charges"
                    callbackFn={(data, mandatoryFiles) => {
                      this.setState({
                        files: data,
                        costing_files: mandatoryFiles,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button"
              name="previous"
              onClick={() => this.props.onCancel(false)}
            >
              CANCEL
            </button>
            <button
              type="button"
              className="btn next_button"
              name="next"
              onClick={this.submitHandler}
            >
              SUBMIT
            </button>
          </div>
        </div>
      );
    }
  }
}
