import React, { Component } from "react";
import SideBar from "../common/SideBar";
import Header from "../common/Header";
import api from "../../api/api";
import config from "../../config/config";
import CookieHandler from "../common/CookieHandler";
import { Alert } from "@material-ui/lab";
// import { Snackbar, Popover, MenuItem, Drawer } from "@material-ui/core";
import {
  Tabs,
  Tab,
  MenuItem,
  Snackbar,
  Popover,
  Drawer,
} from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/Dashboard";
import DocumentsUpload from "../DocumentsUpload";

import BargeCoalCostingView from "../Barge-Purchase-Financials/CoalCosting/CoalCostingView";
import PerformaInvoiceCosting from "../Barge-Purchase-Financials/CoalCosting/PerformaInvoiceCosting";
import BargeCoalCostingUpdate from "../Barge-Purchase-Financials/CoalCosting/CoalCostingUpdate";

import BargeCostingView from "../Barge-Purchase-Financials/BargeCosting/BargeCostingView";
import BargeCostingUpdate from "../Barge-Purchase-Financials/BargeCosting/BargeCostingUpdate";

import ChemicalSprayView from "../Barge-Purchase-Financials/Chemical-Spray/Chemical-Spray-View";
import ChemicalSprayUpdate from "../Barge-Purchase-Financials/Chemical-Spray/Chemical-Spray-Update";

import BargeExportTaxView from "../Barge-Purchase-Financials/Export-Tax/Export-Tax-View";
import BargeExportTaxUpdate from "../Barge-Purchase-Financials/Export-Tax/Export-Tax-Update";

import DocumentationChargesView from "../Barge-Purchase-Financials/Documentation-Charges/Documentation-Charges-View";
import DocumentationChargesUpdate from "../Barge-Purchase-Financials/Documentation-Charges/Documentation-Charges-Update";

import BargeSacView from "../Barge-Purchase-Financials/Sampling&Analysis-Charges/Sac-View";
import BargeSacUpdate from "../Barge-Purchase-Financials/Sampling&Analysis-Charges/Sac-Update";

import BargePreShipmentView from "../Barge-Purchase-Financials/Pre-Shipmet-Inspection/Pre-Shipment-Inspection-View";
import PreShipmentInspectionUpdate from "../Barge-Purchase-Financials/Pre-Shipmet-Inspection/Pre-Shipment-Inspection-update";

import CoalProcurementChargesView from "../Barge-Purchase-Financials/Coal-Procurement-Charges/Coal-Procurement-Charges-View";
import CoalProcurementChargesUpdate from "../Barge-Purchase-Financials/Coal-Procurement-Charges/Coal-Procurement-Charges-Update";
// Barge purchase Fin CPS
import BargeCpsView from "../Barge-Purchase-Financials/Coal-Procurement-Services/CPS-View";
import BargeCpsUpdate from "../Barge-Purchase-Financials/Coal-Procurement-Services/CPS-Update";

// Barge Dmurrages
import BargeVendorDemurrageView from "../Barge-Purchase-Financials-Demurrages/Vendor-Demurrages/Vendor-Demurrage-View";
import BargeVendorDemurrageUpdate from "../Barge-Purchase-Financials-Demurrages/Vendor-Demurrages/Vendor-Demurrage-Update";

import BargeSupplierDemurrageView from "../Barge-Purchase-Financials-Demurrages/Supplier-Demurrages/Supplier-Demurrage-View";
import BargeSupplierDemurrageUpdate from "../Barge-Purchase-Financials-Demurrages/Supplier-Demurrages/Supplier-Demurrage-Update";

import BargeBuyerDemurrageView from "../Barge-Sales-Financial-Demurrages/Buyer-Demurrage-View";
import BuyerDemurrageUpdate from "../Barge-Sales-Financial-Demurrages/Buyer-Demurrage-Update";

import BargeOtherExpenseView from "../Barge-Purchase-Financials/Other-Expenses/Other-Expense-View";
import BargeOtherExpenseUpdate from "../Barge-Purchase-Financials/Other-Expenses/Other-Expense-Update";

import ReturnCargoView from "../Barge-Purchase-Financials/Return-Cargo-Charges/ReturnCargoView";
import ReturnCargoUpdate from "../Barge-Purchase-Financials/Return-Cargo-Charges/ReturnCargoUpdate";

// import MvBuyerDemurrageView from "../BusinessNoMVDemurrage/Mv_Demurrage_Buyer/Mv_Buyer_DemurrageView";
// import MvBuyerDemurrageUpdate from "../BusinessNoMVDemurrage/Mv_Demurrage_Buyer/Mv_Buyer_DemurrageUpdate";
// import MvSupplierDemurrageView from "../BusinessNoMVDemurrage/Mv_Demurrage_Supplier/Mv_supplier_DemurrageView";
// import MvSupplierDemurrageUpdate from "../BusinessNoMVDemurrage/Mv_Demurrage_Supplier/Mv_supplier_DemurrageUpdate";

import ViewSalesQualityResult from "../QualityResults/SalesQualityView";
import { localDateFormate, pad, toLocaleString } from "../common/common";
import Loader from "../common/Loader";
export default class Barge_View extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      showDrawer: false,
      purchase_contracts: [],
      isLoading: true,
      business_no: null,
      costing_availability: true,
      purchase_quality_results: false,
      sales_quality_results: [],
      features: [],
      files: [],
      barge_status_data: [],
      viewSalesQualityResults: false,
      addDocuments: false,
      activeTabIndex: 0,
      costing_no: "",
      costing_id: "",
      costing_type: "",
      outstanding_amount: "",
      paid: "",
      payable: "",
      updateDocuments: false,
      getBargeFinancials: [],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    var features = this.Cookie.getCookie("features");
    this.setState({ features: features ? features : [] }, () => {
      if (
        !(
          this.state.features.includes("46") ||
          this.state.features.includes("97")
        )
      ) {
        window.location.href = "/dashboard";
      }
    });
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    try {
      var barge_id = decodeURIComponent(
        window.atob(this.props.match.params.bargeID)
      );
    } catch (e) {
      window.location.href = "/barge-list";
    }
    await api.get_barge(loginUserID, idToken, barge_id).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            if (res.barges) {
              let days = "0";
              let hours = "0";
              let minutes = "0";
              if (res.barges.time_at_anchorage_days) {
                days = res.barges.time_at_anchorage_days;
              }
              if (res.barges.time_at_anchorage_hours) {
                hours = res.barges.time_at_anchorage_hours;
              }
              if (res.barges.time_at_anchorage_minutes) {
                minutes = res.barges.time_at_anchorage_minutes;
              }
              this.setState({
                bargeID: barge_id,
                bargeNomination: res.barges.barge_nomination,
                barge_captain_phone_no: res.barges.barge_captain_phone_no,
                barge_vendor_name: res.barges.barge_vendor_name,
                barge_vendor: res.barges.barge_vendor,
                barging_type: res.barges.barging_type,
                lumpsum_price: res.barges.lumpsum_price,
                lumpsum_price_currency: res.barges.lumpsum_price_currency,
                barging_base_price: res.barges.barging_base_price,
                barging_base_price_currency:
                  res.barges.barging_base_price_currency,
                vat_incl_or_excl: res.barges.vat_incl_or_excl,
                vat_applicability: res.barges.vat_applicability,
                // barging_base_price_currency: res.barges.barging_base_price_currency === null ? '0' : res.barges.barging_base_price_currency,
                barge_planned_quantity_in_mt:
                  res.barges.barge_planned_quantity_in_mt,
                barge_quantity_in_mt: res.barges.barge_quantity_in_mt,
                return_cargo_quantity_in_mt:
                  res.barges.return_cargo_quantity_in_mt,
                previousBargeQuantityInMT: res.barges.barge_quantity_in_mt,
                dead_freight: res.barges.dead_freight,
                loading_jetty: res.barges.loading_jetty,
                barge_status: res.barges.barge_status,
                supplier_barge_window: res.barges.supplier_barge_window,
                buyer_barge_window: res.barges.buyer_barge_window,
                loading_date: res.barges.loading_date,
                completion_time: res.barges.completion_time,
                supplier_dead_freight_incurred:
                  res.barges.supplier_dead_freight_incurred,
                supplier_dead_freight_value:
                  res.barges.supplier_dead_freight_value,
                internal_dead_freight_incurred:
                  res.barges.internal_dead_freight_incurred,
                internal_dead_freight_value:
                  res.barges.internal_dead_freight_value,
                nor_anchorage: res.barges.nor_anchorage,
                commence_discharge: res.barges.commence_discharge,
                completion_discharge: res.barges.completion_discharge,
                timeAtAnchorage:
                  days + " days, " + hours + " hours, " + minutes + " minutes",
                timeAtAnchorageDays: res.barges.time_at_anchorage_days,
                timeAtAnchorageHours: res.barges.time_at_anchorage_hours,
                timeAtAnchorageMinutes: res.barges.time_at_anchorage_minutes,
                supplierSurveyorName: res.barges.supplier_surveyor,
                supplier_surveyor: res.barges.supplier_surveyor,
                supplierSurveyorAnalysisType:
                  res.barges.supplier_surveyor_analysis_type,
                saiiSurveyorName: res.barges.internal_surveyor,
                internal_surveyor: res.barges.internal_surveyor,
                internal_surveyor_analysis_type:
                  res.barges.internal_surveyor_analysis_type,
                witnessSurveyorName: res.barges.joint_surveyor,
                joint_surveyor: res.barges.joint_surveyor,
                joint_surveyor_analysis_type:
                  res.barges.joint_surveyor_analysis_type,
                chemical_spray: res.barges.chemical_spray
                  ? res.barges.chemical_spray
                  : "No",
                chemical_spray_price: res.barges.chemical_spray_price,
                redraft_quantity: res.barges.redraft_quantity,
                redraft_party: res.barges.redraft_party,
                quantity_difference: res.barges.quantity_difference,
                quantity_difference_account:
                  res.barges.quantity_difference_account,
                remarks: res.barges.remarks,
                purchase_contract_id: res.barges.purchase_contract_id,
                pc_qualities_id: res.barges.pc_qualities_id,
                pc_purchase_type_id: res.barges.pc_purchase_type_id,
                purchase_contract_no: res.barges.purchase_contract_no,
                purchase_type: res.barges.purchase_type,
                internal_surveyor_name: res.barges.internal_surveyor_names,
                joint_surveyor_name: res.barges.joint_surveyor_names,
                supplier_surveyor_name: res.barges.supplier_surveyor_names,
                supplier_surveyor_analysis_type:
                  res.barges.supplier_surveyor_analysis_type,
                pc_quality: res.barges.pc_quality,
                status: res.barges.status,
                business_no: res.barges.business_no,
                costing_availability: res.barges.costing_availability,
                procurement_quality_standard:
                  res.barges.procurement_quality_standard,
                purchase_quality_results: res.barges.purchase_quality_results,
                sales_quality_results: res.barges.sales_quality_results,
                barge_status_data: res.barges.barge_status_data,
                files: res.barges.files ? res.barges.files : [],
                isLoading: false,
              });
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          } else if (res.code === "601") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          } else if (res.code === "639") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
            });
          }
        });
      }
    });

    await api
      .get_barge_financials(loginUserID, idToken, barge_id)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.payables.length > 0) {
                this.setState({
                  getBargeFinancials: res.payables,
                });
              }
            }
          });
        }
      });

    document.title = config.documentTitle + "View Barge";
  }

  BargeCancelHandler = () => {
    this.setState({ isLoading: true });
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    var barge_id = decodeURIComponent(
      window.atob(this.props.match.params.bargeID)
    );
    api.cancel_barge(loginUserID, idToken, barge_id).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.setState(
              {
                bargeStatusOpenMenu: false,
                isLoading: false,
                snackBarSuccessOpen: true,
                successMsg: res.message,
              },
              () => this.componentDidMount()
            );
          } else if (res.code === "601") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
              isLoading: false,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
  };

  // CallbackDrawerClose = (childData) => {
  //   this.setState({
  //     showDrawer: false,
  //     viewSalesQualityResults: false,
  //     addDocuments: false,
  //     CoalCostingView: false,
  //   });
  // };

  // onCancel = (childData) => {
  //   this.setState({
  //     showDrawer: false,
  //     CoalCostingView: false
  //   });
  // };

  editCallback = (childData) => {
    this.setState({
      showDrawer: false,
      viewSalesQualityResults: false,
      CoalCostingUpdate: true,
    });
  };

  onAddResponse = (value) => {
    this.setState({ addDocuments: false, showDrawer: false }, () => {
      this.componentDidMount();
    });
  };

  timeAtAnchorage(date) {
    if (date) {
      if (this.state.nor_anchorage && date) {
        var g1 = new Date(this.state.nor_anchorage);
        var g2 = new Date(date);
        var diff = g2.getTime() - g1.getTime();
        var mins = Math.round(diff / 60000);
        var hrs = Math.floor(mins / 60);
        var days = Math.floor(hrs / 24);
        mins = mins % 60;
        hrs = hrs % 24;
        var timeAtAnchorage =
          days + " days, " + hrs + " hours, " + mins + " minutes";
        return timeAtAnchorage;
      } else return null;
    }
  }

  TabsHandler = (event, value) => {
    this.setState({
      activeTabIndex: value,
    });
  };
  // onUpdateResponse = (value) => {
  //   if (value) {
  //     this.setState(
  //       {
  //         CoalCostingView: true,
  //         CoalCostingUpdate: false,
  //         updateDocuments: false,
  //       },
  //       () => this.componentDidMount()
  //     );
  //   }
  // };
  CallbackViewPage = (childData) => {
    this.setState(
      {
        openDrawer: true,
        CoalCostingUpdate: false,
        CoalCostingView: true,
        updateDocuments: false,
      },
      () => this.componentDidMount()
    );
  };

  onCancel = (value) => {
    this.setState(
      {
        CoalCostingUpdate: false,
        CoalCostingView: false,
        BargeCostingView: false,
        BargeCostingUpdate: false,
        ChemicalSprayView: false,
        ChemicalSprayUpdate: false,
        cpcView: false,
        cpcUpdate: false,
        cpsView: false,
        cpsUpdate: false,
        docView: false,
        docUpdate: false,
        cargoView: false,
        cargoUpdate: false,
        BgOtherExpensUpdate: false,
        BgOtherExpensView: false,
        sacView: false,
        sacUpdate: false,
        showDrawer: false,
        preShipmentView: false,
        preShipmentUpdate: false,
        exportTaxView: false,
        exportTaxUpdate: false,
        serviceView: false,
        serviceUpdate: false,
        barge_dmr_to_vend: false,
        barge_dmr_to_vend_update: false,
        barge_dmr_supplier: false,
        barge_dmr_supplier_update: false,
        buyerDemurrageView: false,
        buyerDemurrageUpdate: false,

        MvFloatingCraneView: false,
        MvFloatingCraneUpdate: false,
        MvStevdoreCostingView: false,
        MvStevdoreCostingUpdate: false,
        MvCpcView: false,
        MvCpcUpdate: false,
        MvDocView: false,
        MvDocUpdate: false,
        MvPreShipmentView: false,
        MvPreShipmentUpdate: false,
        MVExportTaxView: false,
        MvExportTaxUpdate: false,
        MvSacView: false,
        MvSacUpdate: false,
        MvDeadFreightView: false,
        MvDeadFreightUpdate: false,
        MvBuyerDemView: false,
        MvBuyerDemUpdate: false,
        MvSupplierDemUpdate: false,
        MvSupplierDemView: false,

        IncomingCreditNoteView: false,
        IncomingCreditNoteUpdate: false,
        OutgoingCreditNoteView: false,
        OutgoingCreditNoteUpdate: false,
        IncomingDebitNoteUpdate: false,
        IncomingDebitNoteView: false,
        OutgoingDebitNoteView: false,
        OutgoingDebitNoteUpdate: false,
        OtherExpenseView: false,
        OtherExpenseUpdate: false,
        OtherIncomeView: false,
        OtherIncomeUpdate: false,
      },
      () => {
        this.componentDidMount();
      }
    );
  };

  addDocuments = () => {
    this.setState({
      showDrawer: true,
      updateDocuments: true,
      BargeCostingView: false,
      BgOtherExpensView: false,
      BgOtherExpensUpdate:
        this.state.selected_costing === "Barge Purchase Other Expenses"
          ? true
          : false,
      BargeCostingUpdate:
        this.state.selected_costing === "Barge Costing" ? true : false,
      ChemicalSprayView: false,
      ChemicalSprayUpdate:
        this.state.selected_costing === "Chemical Spray" ? true : false,
      CoalCostingView: false,
      CoalCostingUpdate:
        this.state.selected_costing === "Performa Coal Costing" ||
        this.state.selected_costing === "Commercial Coal Costing"
          ? true
          : false,
      exportTaxView: false,
      exportTaxUpdate:
        this.state.selected_costing === "Export Tax" ? true : false,
      docView: false,
      serviceView: false,
      serviceUpdate:
        this.state.selected_costing === "Services Payments" ? true : false,
      docUpdate:
        this.state.selected_costing === "Documentation Charges" ? true : false,
      cargoView: false,
      cargoUpdate:
        this.state.selected_costing === "Return Cargo Charges" ? true : false,
      sacView: false,
      sacUpdate:
        this.state.selected_costing === "Sampling & Analaysis Charges"
          ? true
          : false,
      cpcView: false,
      cpcUpdate:
        this.state.selected_costing === "Coal Procurement Charges"
          ? true
          : false,
      cpsView: false,
      cpsUpdate:
        this.state.selected_costing === "Barge Coal Procurement Service"
          ? true
          : false,
      preShipmentView: false,
      preShipmentUpdate:
        this.state.selected_costing === "Pre Shipment Data" ? true : false,

      barge_dmr_to_vend: false,
      barge_dmr_to_vend_update:
        this.state.selected_costing === "Barge Demurrage to vendor"
          ? true
          : false,
      buyerDemurrageView: false,
      buyerDemurrageUpdate:
        this.state.selected_costing === "Barge Demurrage To Buyer" ||
        this.state.selected_costing === "Barge Demurrage From buyer"
          ? true
          : false,
      barge_dmr_supplier: false,
      barge_dmr_supplier_update:
        this.state.selected_costing === "Barge Demurrage to Supplier" ||
        this.state.selected_costing === "Barge Demurrage From Supplier"
          ? true
          : false,

      // Mother Veesel Financials
      MvFloatingCraneView: false,
      MvFloatingCraneUpdate:
        this.state.selected_costing === "MV Floating Crane" ? true : false,

      MvStevdoreCostingView: false,
      MvStevdoreCostingUpdate:
        this.state.selected_costing === "MV Stevedore Costing" ? true : false,

      MvCpcView: false,
      MvCpcUpdate:
        this.state.selected_costing === "MV Coal Procurement Charges"
          ? true
          : false,
      MvDocView: false,
      MvDocUpdate:
        this.state.selected_costing === "MV Documentation Charges"
          ? true
          : false,
      MvPreShipmentUpdate:
        this.state.selected_costing === "MV Pre Shipment" ? true : false,
      MvPreShipmentView: false,
      MvExportTaxUpdate:
        this.state.selected_costing === "MV Export Tax" ? true : false,
      MVExportTaxView: false,
      MvSacView: false,
      MvSacUpdate:
        this.state.selected_costing === "MV Sampling & Analaysis Charges"
          ? true
          : false,
      MvDeadFreightView: false,
      MvDeadFreightUpdate:
        this.state.selected_costing === "MV Dead Freight" ? true : false,
      MvBuyerDemView: false,
      MvBuyerDemUpdate:
        this.state.selected_costing === "MV Buyer Demurrage" ? true : false,
      MvSupplierDemView: false,
      MvSupplierDemUpdate:
        this.state.selected_costing === "MV Supplier Demurrage" ? true : false,

      IncomingCreditNoteView: false,
      IncomingCreditNoteUpdate:
        this.state.selected_costing === "MV Incoming Credit Note"
          ? true
          : false,
      OutgoingCreditNoteView: false,
      OutgoingCreditNoteUpdate:
        this.state.selected_costing === "MV Outgoing Credit Note"
          ? true
          : false,
      IncomingDebitNoteView: false,
      IncomingDebitNoteUpdate:
        this.state.selected_costing === "MV Incoming Debit Note" ? true : false,
      OutgoingDebitNoteView: false,
      OutgoingDebitNoteUpdate:
        this.state.selected_costing === "MV Outgoing Debit Note" ? true : false,
      OtherExpenseView: false,
      OtherExpenseUpdate:
        this.state.selected_costing === "MV Other Expenses" ? true : false,
      OtherIncomeView: false,
      OtherIncomeUpdate:
        this.state.selected_costing === "MV Other Income" ? true : false,
    });
  };

  onClickCosting = (row) => {
    this.setState({ selected_costing: row.costing_type });
    if (
      this.state.features.includes(row.view_costing_access_id) ||
      this.state.features.includes("97")
    ) {
      if (row.costing_type === "Barge Costing") {
        this.setState({
          showDrawer: true,
          BargeCostingView: true,
          comboID: row.costing_id,
        });
      } else if (row.costing_type === "Chemical Spray") {
        this.setState({
          showDrawer: true,
          ChemicalSprayView: true,
          comboID: row.costing_id,
        });
      } else if (row.costing_type === "Coal Procurement Charges") {
        this.setState({
          showDrawer: true,
          cpcView: true,
          comboID: row.costing_id,
          business_no: row.business_no_id,
        });
      } else if (row.costing_type === "Barge Purchase Other Expenses") {
        this.setState({
          showDrawer: true,
          BgOtherExpensUpdate: false,
          BgOtherExpensView: true,
          // comboID: row.barge_group_id + "$#" + row.costing_id,
          comboID: row.costing_id,
        });
      } else if (row.costing_type === "Barge Coal Procurement Service") {
        this.setState({
          showDrawer: true,
          cpsView: true,
          comboID: row.costing_id,
          purchase_contract_id: row.purchase_contract_id,
        });
      } else if (row.costing_type === "Pre Shipment Data") {
        this.setState({
          showDrawer: true,
          preShipmentView: true,
          comboID: this.state.bargeID + "$#" + row.costing_id,
        });
      } else if (row.costing_type === "Performa Coal Costing") {
        this.setState({
          showDrawer: true,
          CoalCostingView: true,
          comboID: row.costing_id + "$#" + row.purchase_contract_id,
          barge_costing_type: "Performa",
        });
      } else if (row.costing_type === "Commercial Coal Costing") {
        this.setState({
          showDrawer: true,
          CoalCostingView: true,
          comboID: row.costing_id + "$#" + row.purchase_contract_id,
          barge_costing_type: "Commercial",
        });
      } else if (row.costing_type === "Export Tax") {
        this.setState({
          showDrawer: true,
          exportTaxView: true,
          comboID: row.costing_id,
        });
      } else if (row.costing_type === "Sampling & Analaysis Charges") {
        this.setState({
          showDrawer: true,
          sacView: true,
          // comboID: row.barge_group_id + "$#" + row.id,
          comboID: 0 + "$#" + row.costing_id,
        });
      } else if (row.costing_type === "Documentation Charges") {
        this.setState({
          showDrawer: true,
          docView: true,
          comboID: row.costing_id,
        });
      } else if (row.costing_type === "Return Cargo Charges") {
        this.setState({
          showDrawer: true,
          cargoView: true,
          returnCargoID: row.costing_id,
        });
      } else if (row.costing_type === "Services Payments") {
        this.setState({
          showDrawer: true,
          serviceView: true,
          comboID: row.costing_id,
        });
      }

      // Barge Demurrrages
      if (row.costing_type === "Barge Demurrage to vendor") {
        this.setState({
          showDrawer: true,
          barge_dmr_to_vend: true,
          comboID: row.costing_id,
        });
      }
      if (row.costing_type === "Barge Demurrage to Supplier") {
        this.setState({
          showDrawer: true,
          barge_dmr_supplier: true,
          dmr_type: "company_to_supplier",
          comboID: row.costing_id,
        });
      } else if (row.costing_type === "Barge Demurrage From Supplier") {
        this.setState({
          showDrawer: true,
          barge_dmr_supplier: true,
          dmr_type: "supplier_to_company",
          comboID: row.costing_id,
        });
      } else if (row.costing_type === "Barge Demurrage From buyer") {
        this.setState({
          showDrawer: true,
          buyerDemurrageView: true,
          business_no: row.business_no_id,
          dmr_type: "buyer_to_company",
          comboID: row.costing_id + "$#" + row.barge_ids.toString(),
        });
      } else if (row.costing_type === "Barge Demurrage To Buyer") {
        this.setState({
          showDrawer: true,
          buyerDemurrageView: true,
          business_no: row.business_no_id,
          dmr_type: "company_to_buyer",
          comboID: row.costing_id + "$#" + row.barge_ids.toString(),
        });
      }

      if (row.costing_type === "MV Floating Crane") {
        this.setState({
          showDrawer: true,
          MvFloatingCraneView: true,
          comboID: row.costing_id,
          business_no: row.business_no_id,
        });
      } else if (row.costing_type === "MV Stevedore Costing") {
        this.setState({
          showDrawer: true,
          MvStevdoreCostingView: true,
          comboID: row.costing_id,
          business_no: row.business_no_id,
        });
      } else if (row.costing_type === "MV Coal Procurement Charges") {
        this.setState({
          showDrawer: true,
          MvCpcView: true,
          comboID: row.costing_id,
          business_no: row.business_no_id,
        });
      } else if (row.costing_type === "MV Sampling & Analaysis Charges") {
        this.setState({
          showDrawer: true,
          MvSacView: true,
          comboID: row.costing_id,
          business_no: row.business_no_id,
        });
      } else if (row.costing_type === "MV Dead Freight") {
        this.setState({
          showDrawer: true,
          MvDeadFreightView: true,
          business_no: row.business_no_id,
        });
      } else if (row.costing_type === "MV Pre Shipment") {
        this.setState({
          showDrawer: true,
          MvPreShipmentView: true,
          comboID: row.id,
          business_no: row.business_no_id,
        });
      } else if (row.costing_type === "MV Documentation Charges") {
        this.setState({
          showDrawer: true,
          MvDocView: true,
          comboID: row.id,
          business_no: row.business_no_id,
        });
      } else if (row.costing_type === "MV Export Tax") {
        this.setState({
          showDrawer: true,
          MVExportTaxView: true,
          comboID: row.id,
          business_no: row.business_no_id,
        });
      }
      if (row.costing_type === "MV Buyer Demurrage") {
        this.setState({
          showDrawer: true,
          MvBuyerDemView: true,
          comboID: row.id,
          business_no: row.business_no_id,
        });
      }
      if (row.costing_type === "MV Supplier Demurrage") {
        this.setState({
          showDrawer: true,
          MvSupplierDemView: true,
          comboID: row.id,
          business_no: row.business_no_id,
        });
      }
      if (row.costing_type === "MV Incoming Credit Note") {
        this.setState({
          showDrawer: true,
          IncomingCreditNoteView: true,
          comboID: row.id,
          business_no: row.business_no_id,
        });
      }
      if (row.costing_type === "MV Outgoing Credit Note") {
        this.setState({
          showDrawer: true,
          OutgoingCreditNoteView: true,
          comboID: row.id,
          business_no: row.business_no_id,
        });
      }
      if (row.costing_type === "MV Incoming Debit Note") {
        this.setState({
          showDrawer: true,
          IncomingDebitNoteView: true,
          comboID: row.id,
          business_no: row.business_no_id,
        });
      }
      if (row.costing_type === "MV Outgoing Debit Note") {
        this.setState({
          showDrawer: true,
          OutgoingDebitNoteView: true,
          comboID: row.id,
          business_no: row.business_no_id,
        });
      }
      if (row.costing_type === "MV Other Expenses") {
        this.setState({
          showDrawer: true,
          OtherExpenseView: true,
          comboID: row.id,
          business_no: row.business_no_id,
        });
      }
      if (row.costing_type === "MV Other Income") {
        this.setState({
          showDrawer: true,
          OtherIncomeView: true,
          comboID: row.id,
          business_no: row.business_no_id,
        });
      }
    }
  };

  onEdit = (value) => {
    this.setState({
      showDrawer: true,
      BargeCostingView: false,
      BargeCostingUpdate:
        this.state.selected_costing === "Barge Costing" ? true : false,
      ChemicalSprayView: false,
      ChemicalSprayUpdate:
        this.state.selected_costing === "Chemical Spray" ? true : false,
      CoalCostingView: false,
      CoalCostingUpdate:
        this.state.selected_costing === "Performa Coal Costing" ||
        this.state.selected_costing === "Commercial Coal Costing"
          ? true
          : false,
      exportTaxView: false,
      BgOtherExpensUpdate:
        this.state.selected_costing === "Barge Purchase Other Expenses"
          ? true
          : false,
      BgOtherExpensView: false,
      exportTaxUpdate:
        this.state.selected_costing === "Export Tax" ? true : false,
      docView: false,
      docUpdate:
        this.state.selected_costing === "Documentation Charges" ? true : false,
      cargoView: false,
      cargoUpdate:
        this.state.selected_costing === "Return Cargo Charges" ? true : false,
      sacView: false,
      sacUpdate:
        this.state.selected_costing === "Sampling & Analaysis Charges"
          ? true
          : false,
      cpcView: false,
      cpcUpdate:
        this.state.selected_costing === "Coal Procurement Charges"
          ? true
          : false,
      cpsView: false,
      cpsUpdate:
        this.state.selected_costing === "Barge Coal Procurement Service"
          ? true
          : false,
      preShipmentView: false,
      preShipmentUpdate:
        this.state.selected_costing === "Pre Shipment Data" ? true : false,
      serviceView: false,
      serviceUpdate:
        this.state.selected_costing === "Services Payments" ? true : false,
      barge_dmr_to_vend: false,
      barge_dmr_to_vend_update:
        this.state.selected_costing === "Barge Demurrage to vendor"
          ? true
          : false,
      buyerDemurrageView: false,
      buyerDemurrageUpdate:
        this.state.selected_costing === "Barge Demurrage To Buyer" ||
        this.state.selected_costing === "Barge Demurrage From buyer"
          ? true
          : false,
      barge_dmr_supplier: false,
      barge_dmr_supplier_update:
        this.state.selected_costing === "Barge Demurrage to Supplier" ||
        this.state.selected_costing === "Barge Demurrage From Supplier"
          ? true
          : false,

      // Mother Veesel Financials
      MvFloatingCraneView: false,
      MvFloatingCraneUpdate:
        this.state.selected_costing === "MV Floating Crane" ? true : false,

      MvStevdoreCostingView: false,
      MvStevdoreCostingUpdate:
        this.state.selected_costing === "MV Stevedore Costing" ? true : false,

      MvCpcView: false,
      MvCpcUpdate:
        this.state.selected_costing === "MV Coal Procurement Charges"
          ? true
          : false,
      MvDocView: false,
      MvDocUpdate:
        this.state.selected_costing === "MV Documentation Charges"
          ? true
          : false,
      MvDeadFreightView: false,
      MvDeadFreightUpdate:
        this.state.selected_costing === "MV Dead Freight" ? true : false,

      MvPreShipmentUpdate:
        this.state.selected_costing === "MV Pre Shipment" ? true : false,
      MvPreShipmentView: false,
      MvExportTaxUpdate:
        this.state.selected_costing === "MV Export Tax" ? true : false,
      MVExportTaxView: false,
      MvSacView: false,
      MvSacUpdate:
        this.state.selected_costing === "MV Sampling & Analaysis Charges"
          ? true
          : false,

      MvBuyerDemView: false,
      MvBuyerDemUpdate:
        this.state.selected_costing === "MV Buyer Demurrage" ? true : false,
      MvSupplierDemView: false,
      MvSupplierDemUpdate:
        this.state.selected_costing === "MV Supplier Demurrage" ? true : false,

      IncomingCreditNoteView: false,
      IncomingCreditNoteUpdate:
        this.state.selected_costing === "MV Incoming Credit Note"
          ? true
          : false,
      OutgoingCreditNoteView: false,
      OutgoingCreditNoteUpdate:
        this.state.selected_costing === "MV Outgoing Credit Note"
          ? true
          : false,
      IncomingDebitNoteView: false,
      IncomingDebitNoteUpdate:
        this.state.selected_costing === "MV Incoming Debit Note" ? true : false,
      OutgoingDebitNoteView: false,
      OutgoingDebitNoteUpdate:
        this.state.selected_costing === "MV Outgoing Debit Note" ? true : false,
      OtherExpenseView: false,
      OtherExpenseUpdate:
        this.state.selected_costing === "MV Other Expenses" ? true : false,
      OtherIncomeView: false,
      OtherIncomeUpdate:
        this.state.selected_costing === "MV Other Income" ? true : false,
    });
  };

  onUpdateResponse = (value) => {
    if (value) {
      this.setState({
        showDrawer: true,
        BargeCostingUpdate: false,
        BargeCostingView:
          this.state.selected_costing === "Barge Costing" ? true : false,
        ChemicalSprayUpdate: false,
        ChemicalSprayView:
          this.state.selected_costing === "Chemical Spray" ? true : false,
        CoalCostingUpdate: false,
        CoalCostingView:
          this.state.selected_costing === "Performa Coal Costing" ||
          this.state.selected_costing === "Commercial Coal Costing"
            ? true
            : false,
        BgOtherExpensUpdate: false,
        BgOtherExpensView:
          this.state.selected_costing === "Barge Purchase Other Expenses"
            ? true
            : false,
        exportTaxUpdate: false,
        exportTaxView:
          this.state.selected_costing === "Export Tax" ? true : false,
        docUpdate: false,
        docView:
          this.state.selected_costing === "Documentation Charges"
            ? true
            : false,
        cargoUpdate: false,
        cargoView:
          this.state.selected_costing === "Return Cargo Charges" ? true : false,
        sacUpdate: false,
        sacView:
          this.state.selected_costing === "Sampling & Analaysis Charges"
            ? true
            : false,
        cpcUpdate: false,
        cpcView:
          this.state.selected_costing === "Coal Procurement Charges"
            ? true
            : false,
        cpsUpdate: false,
        cpsView:
          this.state.selected_costing === "Barge Coal Procurement Service"
            ? true
            : false,
        serviceView:
          this.state.selected_costing === "Services Payments" ? true : false,
        serviceUpdate: false,
        preShipmentUpdate: false,
        preShipmentView:
          this.state.selected_costing === "Pre Shipment Data" ? true : false,

        barge_dmr_to_vend_update: false,
        barge_dmr_to_vend:
          this.state.selected_costing === "Barge Demurrage to vendor"
            ? true
            : false,
        barge_dmr_supplier_update: false,
        barge_dmr_supplier:
          this.state.selected_costing === "Barge Demurrage to Supplier" ||
          this.state.selected_costing === "Barge Demurrage From Supplier"
            ? true
            : false,
        buyerDemurrageUpdate: false,
        buyerDemurrageView:
          this.state.selected_costing === "Barge Demurrage From buyer" ||
          this.state.selected_costing === "Barge Demurrage To Buyer"
            ? true
            : false,

        MvFloatingCraneUpdate: false,
        MvFloatingCraneView:
          this.state.selected_costing === "MV Flaoting Crane" ? true : false,
        MvStevdoreCostingUpdate: false,
        MvStevdoreCostingView:
          this.state.selected_costing === "MV Stevedore Costing" ? true : false,
        MvCpcUpdate: false,
        MvCpcView:
          this.state.selected_costing === "MV Coal Procurement Charges"
            ? true
            : false,
        MvDocUpdate: false,
        MvDocView:
          this.state.selected_costing === "MV Documentation Charges"
            ? true
            : false,
        MvPreShipmentUpdate: false,
        MvPreShipmentView:
          this.state.selected_costing === "MV Pre Shipment" ? true : false,
        MvExportTaxUpdate: false,
        MVExportTaxView:
          this.state.selected_costing === "MV Export Tax" ? true : false,
        MvSacUpdate: false,
        MvSacView:
          this.state.selected_costing === "MV Sampling & Analaysis Charges"
            ? true
            : false,
        MvDeadFreightUpdate: false,
        MvDeadFreightView:
          this.state.selected_costing === "MV Dead Freight" ? true : false,
        MvBuyerDemUpdate: false,
        MvBuyerDemView:
          this.state.selected_costing === "MV Buyer Demurrage" ? true : false,
        MvSupplierDemView:
          this.state.selected_costing === "MV Supplier Demurrage"
            ? true
            : false,
        MvSupplierDemUpdate: false,
        IncomingCreditNoteUpdate: false,
        IncomingCreditNoteView:
          this.state.selected_costing === "MV Incoming Credit Note"
            ? true
            : false,
        OutgoingCreditNoteUpdate: false,
        OutgoingCreditNoteView:
          this.state.selected_costing === "MV Outgoing Credit Note"
            ? true
            : false,
        IncomingDebitNoteUpdate: false,
        IncomingDebitNoteView:
          this.state.selected_costing === "MV Incoming Debit Note"
            ? true
            : false,
        OutgoingDebitNoteUpdate: false,
        OutgoingDebitNoteView:
          this.state.selected_costing === "MV Outgoing Debit Note"
            ? true
            : false,
        OtherExpenseUpdate: false,
        OtherExpenseView:
          this.state.selected_costing === "MV Other Expenses" ? true : false,
        OtherIncomeUpdate: false,
        OtherIncomeView:
          this.state.selected_costing === "MV Other Income" ? true : false,
      });
    }
  };

  CallbackDrawerClose = (value) => {
    this.setState(
      {
        showDrawer: false,
        MvFloatingCraneView: false,
        MvStevdoreCostingView: false,
        MvSacView: false,
        MvDocView: false,
        MvCpcView: false,
        MVExportTaxView: false,
        MvPreShipmentView: false,
        serviceUpdate: false,
        serviceView: false,

        // Barge Demurrages
        barge_dmr_to_vend: false,
        barge_dmr_supplier: false,
        buyerDemurrageView: false,
        MvSupplierDemView: false,
        MvBuyerDemView: false,

        IncomingCreditNoteView: false,
        IncomingCreditNoteUpdate: false,
        OutgoingCreditNoteView: false,
        OutgoingCreditNoteUpdate: false,
        IncomingDebitNoteUpdate: false,
        IncomingDebitNoteView: false,
        OutgoingDebitNoteView: false,
        OutgoingDebitNoteUpdate: false,
        OtherExpenseView: false,
        OtherExpenseUpdate: false,
        OtherIncomeView: false,
        OtherIncomeUpdate: false,
      },
      () => {
        this.componentDidMount();
      }
    );
  };

  render() {
    try {
      var bargeID = decodeURIComponent(
        window.atob(this.props.match.params.bargeID)
      );
    } catch (e) {
      window.location.href = "/barge-list";
    }
    let drawerComponent;
    if (this.state.viewSalesQualityResults && this.state.comboID) {
      drawerComponent = (
        <ViewSalesQualityResult
          comboID={this.state.comboID}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.editCallback}
        />
      );
    }
    if (this.state.addDocuments && bargeID) {
      drawerComponent = (
        <DocumentsUpload
          feature_name={"Barges"}
          feature_seq_id={bargeID}
          onAddResponse={this.onAddResponse}
          documents={this.state.files}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
        />
      );
    }

    if (this.state.BargeCostingView && this.state.comboID !== null) {
      drawerComponent = (
        <BargeCostingView
          bargeCostingID={this.state.comboID}
          onCancel={this.onCancel}
          onEdit={this.onEdit}
          returnDocumentsHandler={this.addDocuments}
        />
      );
    } else if (this.state.BargeCostingUpdate && this.state.comboID !== null) {
      drawerComponent = (
        <BargeCostingUpdate
          bargeCostingID={this.state.comboID}
          onUpdateCosting={this.onUpdateResponse}
          onCancel={this.onCancel}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }

    if (this.state.CoalCostingView && this.state.comboID !== null) {
      drawerComponent = (
        <BargeCoalCostingView
          comboID={this.state.comboID}
          onCancel={this.onCancel}
          costing_type={this.state.barge_costing_type}
          onEdit={this.onEdit}
          returnDocumentsHandler={this.addDocuments}
          invoiceCostingHandler={(data) => {
            this.setState({
              costingTopOfInvoice: data,
              invoiceCosting: true,
              showDrawer: true,
              comboID: null,
            });
          }}
        />
      );
    } else if (this.state.invoiceCosting && this.state.costingTopOfInvoice) {
      drawerComponent = (
        <PerformaInvoiceCosting
          anotherInfo={this.state.costingTopOfInvoice}
          costing_type={this.state.barge_costing_type}
          onAddResponse={this.onAddResponse}
          onCancel={this.onCancel}
        />
      );
    } else if (this.state.CoalCostingUpdate && this.state.comboID !== null) {
      drawerComponent = (
        <BargeCoalCostingUpdate
          comboID={this.state.comboID}
          onUpdateCosting={this.onUpdateResponse}
          costing_type={this.state.barge_costing_type}
          onBack={this.onCancel}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }

    if (this.state.cpsView && this.state.comboID !== null) {
      drawerComponent = (
        <BargeCpsView
          invoiceID={this.state.comboID}
          onCancel={this.onCancel}
          onEdit={this.onEdit}
          returnDocumentsHandler={this.addDocuments}
        />
      );
    } else if (this.state.cpsUpdate && this.state.comboID !== null) {
      drawerComponent = (
        <BargeCpsUpdate
          invoiceID={this.state.comboID}
          purchase_contract_id={this.state.purchase_contract_id}
          onUpdateCosting={this.onUpdateResponse}
          onCancel={this.onCancel}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }

    if (this.state.ChemicalSprayView && this.state.comboID !== null) {
      drawerComponent = (
        <ChemicalSprayView
          chemicalSprayID={this.state.comboID}
          onCancel={this.onCancel}
          onEdit={this.onEdit}
          returnDocumentsHandler={this.addDocuments}
        />
      );
    } else if (this.state.ChemicalSprayUpdate && this.state.comboID !== null) {
      drawerComponent = (
        <ChemicalSprayUpdate
          chemicalSprayID={this.state.comboID}
          onUpdateCosting={this.onUpdateResponse}
          onCancel={this.onCancel}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }

    if (this.state.exportTaxView && this.state.comboID !== null) {
      drawerComponent = (
        <BargeExportTaxView
          exportTaxID={this.state.comboID}
          onCancel={this.onCancel}
          onEdit={this.onEdit}
          returnDocumentsHandler={this.addDocuments}
        />
      );
    } else if (this.state.exportTaxUpdate && this.state.comboID !== null) {
      drawerComponent = (
        <BargeExportTaxUpdate
          exportTaxID={this.state.comboID}
          onUpdateCosting={this.onUpdateResponse}
          onCancel={this.onCancel}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }

    if (this.state.docView && this.state.comboID !== null) {
      drawerComponent = (
        <DocumentationChargesView
          docID={this.state.comboID}
          onCancel={this.onCancel}
          onEdit={this.onEdit}
          returnDocumentsHandler={this.addDocuments}
        />
      );
    } else if (this.state.docUpdate && this.state.comboID !== null) {
      drawerComponent = (
        <DocumentationChargesUpdate
          docID={this.state.comboID}
          onUpdateCosting={this.onUpdateResponse}
          onCancel={this.onCancel}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }

    if (this.state.sacView && this.state.comboID !== null) {
      drawerComponent = (
        <BargeSacView
          comboID={this.state.comboID}
          onCancel={this.onCancel}
          onEdit={this.onEdit}
          returnDocumentsHandler={this.addDocuments}
        />
      );
    } else if (this.state.sacUpdate && this.state.comboID !== null) {
      drawerComponent = (
        <BargeSacUpdate
          comboID={this.state.comboID}
          onUpdateCosting={this.onUpdateResponse}
          onCancel={this.onCancel}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }

    if (this.state.preShipmentView && this.state.comboID !== null) {
      drawerComponent = (
        <BargePreShipmentView
          costing_id={this.state.comboID}
          onCancel={this.onCancel}
          onEdit={this.onEdit}
          returnDocumentsHandler={this.addDocuments}
        />
      );
    } else if (this.state.preShipmentUpdate && this.state.comboID !== null) {
      drawerComponent = (
        <PreShipmentInspectionUpdate
          costing_id={this.state.comboID}
          onUpdateCosting={this.onUpdateResponse}
          onCancel={this.onCancel}
          shipment_type={this.state.pre_shipment_type}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }

    if (this.state.cpcView && this.state.comboID !== null) {
      drawerComponent = (
        <CoalProcurementChargesView
          comboID={this.state.comboID}
          onCancel={this.onCancel}
          onEdit={this.onEdit}
          returnDocumentsHandler={this.addDocuments}
        />
      );
    } else if (this.state.cpcUpdate && this.state.comboID !== null) {
      drawerComponent = (
        <CoalProcurementChargesUpdate
          comboID={this.state.comboID}
          onUpdateCosting={this.onUpdateResponse}
          onCancel={this.onCancel}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }

    // Barge Demurrages
    if (this.state.barge_dmr_to_vend && this.state.comboID) {
      drawerComponent = (
        <BargeVendorDemurrageView
          comboID={this.state.comboID}
          returnCallbackDrawerClose={this.onCancel}
          returnEditCallback={this.onEdit}
          returnDocumentsHandler={this.addDocuments}
        />
      );
    } else if (this.state.barge_dmr_to_vend_update && this.state.comboID) {
      drawerComponent = (
        <BargeVendorDemurrageUpdate
          comboID={this.state.comboID}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.onCancel}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }

    if (this.state.barge_dmr_supplier && this.state.comboID) {
      drawerComponent = (
        <BargeSupplierDemurrageView
          demurrage_type={this.state.dmr_type}
          comboID={this.state.comboID}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.onEdit}
          returnDocumentsHandler={this.addDocuments}
        />
      );
    } else if (this.state.barge_dmr_supplier_update && this.state.comboID) {
      drawerComponent = (
        <BargeSupplierDemurrageUpdate
          demurrage_type={this.state.dmr_type}
          comboID={this.state.comboID}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.onCancel}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }
    if (this.state.buyerDemurrageView && this.state.comboID) {
      drawerComponent = (
        <BargeBuyerDemurrageView
          demurrage_type={this.state.dmr_type}
          comboID={this.state.comboID}
          businessNo={this.state.business_no}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.onEdit}
          returnDocumentsHandler={this.addDocuments}
        />
      );
    } else if (this.state.buyerDemurrageUpdate && this.state.comboID) {
      drawerComponent = (
        <BuyerDemurrageUpdate
          businessNo={this.state.business_no}
          demurrageData={this.state.demurrageData}
          demurrage_type={this.state.dmr_type}
          comboID={this.state.comboID}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.onCancel}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }
    if (this.state.BgOtherExpensView && this.state.comboID) {
      drawerComponent = (
        <BargeOtherExpenseView
          costing_id={this.state.comboID}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.onEdit}
          returnDocumentsHandler={this.addDocuments}
        />
      );
    } else if (this.state.BgOtherExpensUpdate && this.state.comboID) {
      drawerComponent = (
        <BargeOtherExpenseUpdate
          costing_id={this.state.comboID}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.onCancel}
        />
      );
    }
    if (this.state.cargoView && this.state.returnCargoID) {
      drawerComponent = (
        <ReturnCargoView
          returnCargoID={this.state.returnCargoID}
          onCancel={this.onCancel}
          onEdit={this.onEdit}
          returnDocumentsHandler={this.addDocuments}
        />
      );
    } else if (this.state.cargoUpdate && this.state.returnCargoID) {
      drawerComponent = (
        <ReturnCargoUpdate
          returnCargoID={this.state.returnCargoID}
          onUpdateCosting={this.onUpdateResponse}
          onCancel={this.onCancel}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }

    return (
      <div id="wraper">
        <SideBar />
        <div className="content">
          <Header />
          {this.state.isLoading && <Loader />}
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <Drawer
            anchor="right"
            open={this.state.showDrawer}
            variant="temporary"
            elevation={20}
            style={{ overflow: "initial" }}
          >
            <div className="row" style={{ width: 800 }}>
              {drawerComponent}
            </div>
          </Drawer>
          {!this.state.isLoading && (
            <div className="content-header">
              <div className="clearfix row content-header">
                <div className="col-lg-8 text-left pl-0">
                  <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                    <a href={"/barge-list"}>
                      <i className="fa fa-arrow-left left_arrow_adjst" />
                    </a>
                    Barge - {pad(bargeID)}
                    <span
                      className={
                        this.state.status === "Active" ? "type_1" : "type_2"
                      }
                      style={{ padding: 8, marginLeft: 16 }}
                    >
                      {this.state.status}
                    </span>
                  </h4>
                </div>
                {/* {(this.state.status === "Active" && this.state.business_no === null && !this.state.costing_availability) && */}
                <div className="col-lg text-right float-sm-left pt-4">
                  <label
                    className="mr-1"
                    data-toggle="tooltip"
                    title="Action"
                    data-placement="bottom"
                  >
                    <DashboardIcon
                      style={{
                        fontSize: 30,
                        color: "#138496",
                        cursor: "pointer",
                        marginTop: "-25px",
                      }}
                      onClick={(event) =>
                        this.setState({
                          bargeStatusOpenMenu: true,
                          anchorEl: event.currentTarget,
                        })
                      }
                    />
                  </label>
                </div>
                {/* } */}
              </div>
            </div>
          )}
          <div className="card p-0" style={{ overflowY: "scroll" }}>
            <div className="row border-bottom">
              <Tabs
                value={this.state.activeTabIndex}
                onChange={this.TabsHandler}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                style={{
                  backgroundColor: "#ffffff",
                  color: "#6c7293",
                  padding: 0,
                }}
                TabIndicatorProps={{
                  style: { background: config.themeColor },
                }}
              >
                <Tab
                  label={
                    <h4
                      style={{
                        margin: "0px",
                        color:
                          this.state.activeTabIndex === 0
                            ? config.themeColor
                            : "#6c7293",
                        fontSize: "14px",
                        border: "0px",
                      }}
                    >
                      Operational
                    </h4>
                  }
                  value={0}
                  style={{
                    fontFamily: "Poppins",
                    textTransform: "capitalize",
                    color: "#6c7293",
                  }}
                />
                {(this.state.features.includes("612") ||
                  this.state.features.includes("97")) && (
                  <Tab
                    label={
                      <h4
                        style={{
                          margin: "0px",
                          color:
                            this.state.activeTabIndex === 1
                              ? config.themeColor
                              : "#6c7293",
                          fontSize: "14px",
                          border: "0px",
                        }}
                      >
                        Financials
                      </h4>
                    }
                    value={1}
                    style={{
                      fontFamily: "Poppins",
                      textTransform: "capitalize",
                      color: "#6c7293",
                    }}
                  />
                )}
              </Tabs>
            </div>
          </div>
          {!this.state.isLoading && this.state.activeTabIndex === 0 && (
            <div className="container">
              <div className="col-lg card" style={{ overflowY: "scroll" }}>
                <div className="card p-0 border mb-3">
                  <div className="card-header section_header">
                    <h5> Barge Details</h5>
                  </div>
                  <div className="card-body">
                    <div className="row col-lg-12 p-0">
                      <div className="col-lg-4 mb-3">
                        <label className="contract_display_header_label">
                          Barge Nomination
                        </label>
                        <div
                          className="contract_display_header_value"
                          style={{ textTransform: "upperCase" }}
                        >
                          {this.state.bargeNomination
                            ? this.state.bargeNomination
                            : "-"}
                        </div>
                      </div>
                      <div className="col-sm-4 mb-3">
                        <label className="contract_display_header_label">
                          {" "}
                          Purchase Contract{" "}
                        </label>
                        {this.state.purchase_contract_id && (
                          <a
                            href={
                              "/view-purchase-contract/" +
                              btoa(this.state.purchase_contract_id)
                            }
                            rel="noopener noreferrer"
                            role="button"
                            style={{ color: config.themeColor }}
                          >
                            {this.state.purchase_contract_no}
                          </a>
                        )}
                      </div>
                      <div className="col-sm-4 mb-3">
                        <label className="contract_display_header_label">
                          Purchase Quality{" "}
                        </label>
                        <div
                          className="contract_display_header_value"
                          style={{ textTransform: "upperCase" }}
                        >
                          {this.state.pc_quality ? this.state.pc_quality : "-"}
                        </div>
                      </div>
                      <div className="col-sm-4 mb-3">
                        <label className="contract_display_header_label">
                          Purchase Quality Standard
                        </label>
                        <div
                          className="contract_display_header_value"
                          style={{ textTransform: "upperCase" }}
                        >
                          {this.state.procurement_quality_standard
                            ? this.state.procurement_quality_standard
                            : "-"}
                        </div>
                      </div>
                      <div className="col-sm-4  mb-3">
                        <label className="contract_display_header_label">
                          Purchase Type
                        </label>
                        <div
                          className="contract_display_header_value"
                          style={{ textTransform: "upperCase" }}
                        >
                          {this.state.purchase_type
                            ? this.state.purchase_type
                            : "-"}
                        </div>
                      </div>
                      <div className="col-sm-4 mb-3">
                        <label className="contract_display_header_label">
                          {" "}
                          Barge Captain Phone No{" "}
                        </label>
                        <div
                          className="contract_display_header_value"
                          style={{ textTransform: "upperCase" }}
                        >
                          {this.state.barge_captain_phone_no
                            ? this.state.barge_captain_phone_no
                            : "-"}
                        </div>
                      </div>
                    </div>

                    {this.state.purchase_type !== "FAS Anchorage" &&
                      this.state.purchase_type !== "FOB Mother Vessel" && (
                        <div className="row">
                          <div className="col-sm-4 mb-3">
                            <label className="contract_display_header_label">
                              {" "}
                              Barge Vendor
                            </label>
                            {this.state.barge_vendor_name ? (
                              <a
                                href={
                                  "/view-vendor/" +
                                  btoa(this.state.barge_vendor)
                                }
                                rel="noopener noreferrer"
                                role="button"
                                style={{ color: config.themeColor }}
                              >
                                {this.state.barge_vendor_name}
                              </a>
                            ) : (
                              "-"
                            )}
                          </div>

                          <div className="col-sm-4 mb-3">
                            <label className="contract_display_header_label">
                              Barging Type
                            </label>
                            <div className="contract_display_header_value">
                              {this.state.barging_type}
                            </div>
                          </div>
                          {this.state.barging_type === "Per MT" && (
                            <div className="col-sm-4 mb-3">
                              <label className="contract_display_header_label">
                                Base Price Per MT{" "}
                              </label>
                              <div
                                className="contract_display_header_value"
                                style={{ textTransform: "upperCase" }}
                              >
                                {`${toLocaleString(
                                  this.state.barging_base_price
                                )} ${this.state.barging_base_price_currency}`}
                              </div>
                            </div>
                          )}
                          {this.state.barging_type === "Lumpsum" && (
                            <div className="col-sm-4 mb-3">
                              <label className="contract_display_header_label">
                                Lumpsum Base Price{" "}
                              </label>
                              <div className="contract_display_header_value">
                                {`${toLocaleString(this.state.lumpsum_price)} ${
                                  this.state.lumpsum_price_currency
                                }`}
                              </div>
                            </div>
                          )}

                          {(this.state.barging_base_price ||
                            this.state.lumpsum_price) && (
                            <>
                              <div className="col-sm-4 mb-3">
                                <label className="contract_display_header_label">
                                  VAT Applicability
                                </label>
                                <div className="contract_display_header_value">
                                  {this.state.vat_applicability}
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      )}

                    <div className="row col-lg-12 p-0">
                      <div className="col-sm-4 mb-3">
                        <label className="contract_display_header_label">
                          Barge Planned Quantity in MT{" "}
                        </label>
                        <div className="contract_display_header_value">
                          {toLocaleString(
                            this.state.barge_planned_quantity_in_mt
                          )}
                        </div>
                      </div>
                      <div className="col-sm-4 mb-3">
                        <label className="contract_display_header_label">
                          Barge Quantity in MT{" "}
                        </label>
                        <div className="contract_display_header_value">
                          {toLocaleString(this.state.barge_quantity_in_mt)}
                        </div>
                      </div>
                      <div className="col-sm-4 mb-3">
                        <label className="contract_display_header_label">
                          Return Cargo Quantity in MT{" "}
                        </label>
                        <div className="contract_display_header_value">
                          {toLocaleString(
                            this.state.return_cargo_quantity_in_mt
                          )}
                        </div>
                      </div>
                      <div className="col-sm-4 mb-3">
                        <label className="contract_display_header_label">
                          {" "}
                          Loading Jetty
                        </label>
                        <div
                          className="contract_display_header_value"
                          style={{ textTransform: "upperCase" }}
                        >
                          {this.state.loading_jetty
                            ? this.state.loading_jetty
                            : "-"}
                        </div>
                      </div>
                      <div className="col-sm-4 mb-3">
                        <label className="contract_display_header_label">
                          Supplier Barge Window{" "}
                        </label>
                        <div
                          className="contract_display_header_value"
                          style={{ textTransform: "upperCase" }}
                        >
                          {this.state.supplier_barge_window
                            ? this.state.supplier_barge_window
                            : "-"}
                        </div>
                      </div>
                      <div className="col-sm-4 mb-3">
                        <label className="contract_display_header_label">
                          {" "}
                          Buyer Barge Window
                        </label>
                        <div
                          className="contract_display_header_value"
                          style={{ textTransform: "upperCase" }}
                        >
                          {this.state.buyer_barge_window
                            ? this.state.buyer_barge_window
                            : "-"}
                        </div>
                      </div>
                    </div>
                    {this.state.purchase_type === "FOB Barge" && (
                      <div className="row col-lg-12 p-0">
                        <div style={{ width: "70%" }}>
                          <h5
                            style={{
                              padding: "10px 2px",
                              margin: "0px",
                              paddingLeft: "16px",
                            }}
                          >
                            Dead Freight Values
                          </h5>
                        </div>
                        <div className="col-sm-4 mb-3">
                          <label className="contract_display_header_label">
                            {" "}
                            Dead Freight{" "}
                          </label>
                          <div
                            className="contract_display_header_value"
                            style={{ textTransform: "upperCase" }}
                          >
                            {toLocaleString(this.state.dead_freight)}
                          </div>
                        </div>
                        {this.state.supplier_dead_freight_incurred > 0 &&
                          this.state.barge_quantity_in_mt && (
                            <div className="col-sm-4 mb-3">
                              <label className="contract_display_header_label">
                                Supplier Dead Freight Incurred
                              </label>
                              <div
                                className="contract_display_header_value"
                                style={{ textTransform: "upperCase" }}
                              >
                                {toLocaleString(
                                  this.state.supplier_dead_freight_incurred
                                )}
                              </div>
                            </div>
                          )}
                        {this.state.supplier_dead_freight_value > 0 &&
                          this.state.barge_quantity_in_mt && (
                            <div className="col-sm-4 mb-3">
                              <label className="contract_display_header_label">
                                Supplier Dead Freight Value
                              </label>
                              <div
                                className="contract_display_header_value"
                                style={{ textTransform: "upperCase" }}
                              >
                                {toLocaleString(
                                  this.state.supplier_dead_freight_value
                                )}
                              </div>
                            </div>
                          )}
                        {this.state.internal_dead_freight_incurred > 0 &&
                          this.state.barge_quantity_in_mt && (
                            <div className="col-sm-4 mb-3">
                              <label className="contract_display_header_label">
                                {config.company} Dead Freight Incurred
                              </label>
                              <div
                                className="contract_display_header_value"
                                style={{ textTransform: "upperCase" }}
                              >
                                {toLocaleString(
                                  this.state.internal_dead_freight_incurred
                                )}
                              </div>
                            </div>
                          )}
                        {this.state.internal_dead_freight_value > 0 &&
                          this.state.barge_quantity_in_mt && (
                            <div className="col-sm-4 mb-3">
                              <label className="contract_display_header_label">
                                {config.company} Dead Freight Value
                              </label>
                              <div
                                className="contract_display_header_value"
                                style={{ textTransform: "upperCase" }}
                              >
                                {toLocaleString(
                                  this.state.internal_dead_freight_value
                                )}
                              </div>
                            </div>
                          )}
                      </div>
                    )}
                    {this.state.barge_status_data.filter(
                      (obj) => obj.redraft_quantity !== null
                    ).length > 0 && (
                      <div className="row col-lg-12 p-0">
                        <div style={{ width: "70%" }}>
                          <h5
                            style={{
                              padding: "10px 2px",
                              margin: "0px",
                              paddingLeft: "16px",
                            }}
                          >
                            Redraft Details
                          </h5>
                        </div>
                        <div className="table-responsive border-0">
                          <table className="table table-borderless border-0">
                            <thead>
                              <tr>
                                <th className="table_header_barge_label border-0">
                                  Business No
                                </th>
                                <th className="table_header_barge_label border-0">
                                  Allocation
                                </th>
                                <th className="table_header_barge_label border-0">
                                  Redraft Quantity
                                </th>
                                <th className="table_header_barge_label  border-0">
                                  Redraft Party
                                </th>
                                <th className="table_header_barge_label border-0">
                                  Quantity Difference
                                </th>
                                <th className="table_header_barge_label border-0">
                                  Quantity Difference Account
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.barge_status_data
                                .filter((obj) => obj.redraft_quantity !== null)
                                .map((e, index) => (
                                  <tr key={index}>
                                    <td>
                                      <a
                                        href={
                                          "/view-business/" +
                                          btoa(e.business_no_id)
                                        }
                                        rel="noopener noreferrer"
                                        role="button"
                                        style={{ color: config.themeColor }}
                                      >
                                        {e.business_number}
                                      </a>
                                    </td>
                                    <td>{e.display_allocation_id}</td>
                                    <td>
                                      {toLocaleString(e.redraft_quantity)}
                                    </td>
                                    <td>
                                      {e.redraft_party ? e.redraft_party : "-"}
                                    </td>
                                    <td>
                                      {toLocaleString(e.quantity_difference)}
                                    </td>
                                    <td>
                                      {e.quantity_difference_account
                                        ? e.quantity_difference_account
                                        : "-"}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="card p-0 border mb-3">
                  <div className="card-header section_header">
                    <h5>Barge Timing</h5>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-4 mb-3">
                        <label className="contract_display_header_label">
                          Loading Date
                        </label>
                        <div className="contract_display_header_value">
                          {localDateFormate(this.state.loading_date)}
                        </div>
                      </div>
                      <div className="col-sm-4 mb-3">
                        <label className="contract_display_header_label">
                          Loading Completion Time
                        </label>
                        <div className="contract_display_header_value">
                          {localDateFormate(this.state.completion_time)}
                        </div>
                      </div>
                      <div className="col-sm-4 mb-3">
                        <label className="contract_display_header_label">
                          NOR Anchorage
                        </label>
                        <div className="contract_display_header_value">
                          {localDateFormate(this.state.nor_anchorage)}
                        </div>
                      </div>
                    </div>
                    {this.state.barge_status_data.length > 0 && (
                      <div className="table-responsive border-0">
                        <table className="table table-borderless border-0">
                          <thead>
                            <tr>
                              <th className="table_header_barge_label border-0">
                                Business No
                              </th>
                              <th className="table_header_barge_label border-0">
                                Allocation
                              </th>
                              <th className="table_header_barge_label border-0">
                                Barge Status
                              </th>
                              <th className="table_header_barge_label  border-0">
                                Commence Discharging
                              </th>
                              <th className="table_header_barge_label border-0">
                                Completed Discharging
                              </th>
                              <th className="table_header_barge_label border-0">
                                Time at Anchorage
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.barge_status_data.map((e, index) => (
                              <tr key={index}>
                                <td>
                                  <a
                                    href={
                                      "/view-business/" + btoa(e.business_no_id)
                                    }
                                    rel="noopener noreferrer"
                                    role="button"
                                    style={{ color: config.themeColor }}
                                  >
                                    {e.business_number}
                                  </a>
                                </td>
                                <td>{e.display_allocation_id}</td>
                                <td>{e.barge_status ? e.barge_status : "-"}</td>
                                <td>
                                  {localDateFormate(e.completion_discharge)}
                                </td>
                                <td>
                                  {localDateFormate(e.completion_discharge)}
                                </td>
                                <td>
                                  {e.completion_discharge &&
                                  this.state.nor_anchorage
                                    ? this.timeAtAnchorage(
                                        e.completion_discharge
                                      )
                                    : "-"}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </div>

                <div className="card p-0 border mb-3">
                  <div className="card-header section_header">
                    <h5>Surveyor Details</h5>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-4 mb-3">
                        <label className="contract_display_header_label">
                          Supplier Surveyor
                        </label>
                        {/* <label className="contract_display_header_value">{this.state.supplier_surveyor_name}</label> */}
                        {this.state.supplier_surveyor ? (
                          <a
                            href={
                              "/view-vendor/" +
                              btoa(this.state.supplier_surveyor)
                            }
                            rel="noopener noreferrer"
                            role="button"
                            style={{ color: config.themeColor }}
                          >
                            {this.state.supplier_surveyor_name === " " ||
                            this.state.supplier_surveyor_name === null
                              ? "-"
                              : this.state.supplier_surveyor_name}
                          </a>
                        ) : (
                          "-"
                        )}
                      </div>
                      <div className="col-sm-4 mb-3">
                        <label className="contract_display_header_label">
                          {config.company} Surveyor
                        </label>
                        {/* <label className="contract_display_header_value">{this.state.internal_surveyor_name}</label> */}
                        {this.state.internal_surveyor ? (
                          <a
                            href={
                              "/view-vendor/" +
                              btoa(this.state.internal_surveyor)
                            }
                            rel="noopener noreferrer"
                            role="button"
                            style={{ color: config.themeColor }}
                          >
                            {this.state.internal_surveyor_name === "0"
                              ? "-"
                              : this.state.internal_surveyor_name}
                          </a>
                        ) : (
                          "-"
                        )}
                      </div>
                      <div className="col-sm-4 mb-3">
                        <label className="contract_display_header_label">
                          Witness Surveyor
                        </label>
                        {/* <label className="contract_display_header_value">{this.state.joint_surveyor_name}</label> */}
                        {this.state.joint_surveyor ? (
                          <a
                            href={
                              "/view-vendor/" + btoa(this.state.joint_surveyor)
                            }
                            rel="noopener noreferrer"
                            role="button"
                            style={{ color: config.themeColor }}
                          >
                            {this.state.joint_surveyor_name === "0"
                              ? "-"
                              : this.state.joint_surveyor_name}
                          </a>
                        ) : (
                          "-"
                        )}
                      </div>
                      <div className="col-sm-4 mb-3">
                        <label className="contract_display_header_label">
                          Supplier Surveyor Analysis type
                        </label>
                        <div className="contract_display_header_value">
                          {this.state.supplier_surveyor_analysis_type
                            ? this.state.supplier_surveyor_analysis_type
                            : "-"}
                        </div>
                      </div>
                      <div className="col-sm-4 mb-3">
                        <label className="contract_display_header_label">
                          {config.company} Surveyor Analysis Type
                        </label>
                        <div className="contract_display_header_value">
                          {this.state.internal_surveyor_analysis_type
                            ? this.state.internal_surveyor_analysis_type
                            : "-"}
                        </div>
                      </div>
                      <div className="col-sm-4 mb-3">
                        <label className="contract_display_header_label">
                          Witness Surveyor Analysis Type
                        </label>
                        <div className="contract_display_header_value">
                          {this.state.joint_surveyor_analysis_type
                            ? this.state.joint_surveyor_analysis_type
                            : "-"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card p-0 border mb-3">
                  <div className="card-header section_header">
                    <h5>Others Details</h5>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-4 mb-3">
                        <label className="contract_display_header_label">
                          Chemical Spray
                        </label>
                        <div className="contract_display_header_value">
                          {this.state.chemical_spray}
                        </div>
                      </div>
                      {this.state.chemical_spray === "Yes" && (
                        <div className="col-sm-4 mb-3">
                          <label className="contract_display_header_label">
                            Chemical Spray Value
                          </label>
                          <div className="contract_display_header_value">
                            {this.state.chemical_spray_price
                              ? toLocaleString(
                                  this.state.chemical_spray_price
                                ) + " IDR"
                              : null}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="row">
                      <div className="col-sm-8 mb-3">
                        <label className="contract_display_header_label">
                          Remarks
                        </label>
                        <div
                          className="contract_display_header_value"
                          style={{ whiteSpace: "pre-line" }}
                        >
                          {this.state.remarks ? this.state.remarks : "-"}
                        </div>
                      </div>
                    </div>
                    {(this.state.features.includes("375") ||
                      this.state.features.includes("97")) && (
                      <div className="row">
                        <span
                          className="col-lg-12 contract_display_header_value pl-0 mb-2"
                          style={{ fontWeight: "bold" }}
                        >
                          Uploaded Documents
                        </span>
                        {this.state.files.map((e, index) => (
                          <div
                            className={
                              e.file_path
                                ? "row col-lg-12 mb-3"
                                : "row col-lg-12"
                            }
                            key={index}
                          >
                            {e.file_path && (
                              <div className="col-lg-4 p-0">
                                <div className="">
                                  <a
                                    href={config.apiDomain + "/" + e.file_path}
                                    role="button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: config.themeColor }}
                                  >
                                    {e.file_description}
                                  </a>
                                </div>
                              </div>
                            )}
                          </div>
                        ))}

                        <p>
                          Add Documents{" "}
                          <i
                            className="fa fa-plus-circle"
                            style={{
                              color: config.themeColor,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              this.setState({
                                showDrawer: true,
                                addDocuments: true,
                              });
                            }}
                          />
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                {(this.state.purchase_quality_results ||
                  this.state.sales_quality_results.length > 0) && (
                    <></>
                  // <div className="card p-0 border mb-3">
                  //   <div className="card-header section_header">
                  //     <h5>Quality Results</h5>
                  //   </div>
                  //   <div className="card-body">
                  //     <div className="row">
                  //       {this.state.purchase_quality_results && (
                  //         <div className="col-lg-12 mb-3">
                  //           <label className="contract_display_header_label">
                  //             Purchase Quality Results &emsp;
                  //             <i
                  //               className="fa fa-eye mr-2"
                  //               aria-hidden="true"
                  //               style={{
                  //                 fontSize: 22,
                  //                 color: config.themeColor,
                  //                 cursor: "pointer",
                  //               }}
                  //               onClick={() =>
                  //                 (window.location.href =
                  //                   "/view-purchase-quality-results/" +
                  //                   btoa(bargeID))
                  //               }
                  //             ></i>
                  //           </label>
                  //         </div>
                  //       )}

                  //       {this.state.sales_quality_results.length > 1 ? (
                  //         <div>
                  //           <label
                  //             className="contract_display_header_label"
                  //             style={{ fontWeight: "bold" }}
                  //           >
                  //             Sales Quality Results{" "}
                  //           </label>
                  //           {this.state.sales_quality_results.map((e, idx) => (
                  //             <div
                  //               className="col-lg-12 mb-3 contract_display_header_label"
                  //               key={idx}
                  //             >
                  //               {e.business_number} - {e.display_allocation_id}{" "}
                  //               &emsp;
                  //               <i
                  //                 className="fa fa-eye mr-2"
                  //                 aria-hidden="true"
                  //                 style={{
                  //                   fontSize: 22,
                  //                   color: config.themeColor,
                  //                   cursor: "pointer",
                  //                 }}
                  //                 onClick={() =>
                  //                   this.setState({
                  //                     showDrawer: true,
                  //                     viewSalesQualityResults: true,
                  //                     comboID:
                  //                       e.business_no_id +
                  //                       "$#" +
                  //                       e.business_allocation_id +
                  //                       "$#" +
                  //                       bargeID,
                  //                   })
                  //                 }
                  //               ></i>
                  //             </div>
                  //           ))}
                  //         </div>
                  //       ) : (
                  //         <div className="col-lg-12 mb-3">
                  //           {this.state.sales_quality_results.length === 1 && (
                  //             <label className="contract_display_header_label">
                  //               Sales Quality Results &emsp;
                  //               <i
                  //                 className="fa fa-eye mr-2"
                  //                 aria-hidden="true"
                  //                 style={{
                  //                   fontSize: 22,
                  //                   color: config.themeColor,
                  //                   cursor: "pointer",
                  //                 }}
                  //                 onClick={() =>
                  //                   this.setState({
                  //                     showDrawer: true,
                  //                     viewSalesQualityResults: true,
                  //                     comboID:
                  //                       this.state.sales_quality_results[0]
                  //                         .business_no_id +
                  //                       "$#" +
                  //                       this.state.sales_quality_results[0]
                  //                         .business_allocation_id +
                  //                       "$#" +
                  //                       bargeID,
                  //                   })
                  //                 }
                  //               ></i>
                  //             </label>
                  //           )}
                  //         </div>
                  //       )}
                  //     </div>
                  //   </div>
                  // </div>
                )}
              </div>

              <div className="row bottom_buttons_section">
                <button
                  type="button"
                  className="btn previous_button"
                  name="submit"
                  onClick={() => (window.location.href = "/barge-list")}
                >
                  Back
                </button>
                {this.state.status !== "Cancelled" && (
                  <button
                    type="button"
                    className="btn next_button"
                    name="submit"
                    onClick={() =>
                      (window.location.href = "/update-barge/" + btoa(bargeID))
                    }
                  >
                    Edit
                  </button>
                )}
              </div>
            </div>
          )}
          {this.state.activeTabIndex === 1 && (
            <div className="card p-4 mb-3">
              <div className="col shadow-sm p-0 mb-2 bg-white">
                <div className="table-responsive">
                  <table className="table table-sm table-bordered mb-0">
                    <thead className="table-header">
                      <tr>
                        <th className="text-left" nowrap="true">
                          Costing No
                        </th>
                        <th className="text-left" nowrap="true">
                          Costing Type
                        </th>
                        <th className="text-right" nowrap="true">
                          Quantity
                        </th>
                        <th className="text-right" nowrap="true">
                          Price PMT
                        </th>
                        <th className="text-right" nowrap="true">
                          Payable
                        </th>
                        <th className="text-right" nowrap="true">
                          Paid
                        </th>{" "}
                        <th className="text-right" nowrap="true">
                          Outstanding Amount
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {this.state.getBargeFinancials.map((item, id) => (
                        <tr>
                          <td className="text-left" nowrap="true">
                            <i
                              aria-hidden="true"
                              style={{
                                fontStyle: "normal",
                                color: config.themeColor,
                                cursor: "pointer",
                                whiteSpace: "nowrap",
                              }}
                              onClick={() => this.onClickCosting(item)}
                            >
                              {item.costing_no}
                            </i>
                          </td>
                          <td className="text-left" nowrap="true">
                            {item.costing_description
                              ? item.costing_description
                              : ""}
                          </td>
                          <td className="text-right" nowrap="true">
                            {item.barge_quantity
                              ? parseFloat(item.barge_quantity).toFixed(2)
                              : ""}
                          </td>
                          <td className="text-right" nowrap="true">
                            {item.price_pmt !== "" ? item.price_pmt : "0"}
                          </td>
                          <td className="text-right" nowrap="true">
                            {item.payable
                              ? parseFloat(item.payable).toFixed(2)
                              : 0}
                          </td>
                          <td className="text-right" nowrap="true">
                            {item.paid ? parseFloat(item.paid).toFixed(2) : 0}
                          </td>
                          <td className="text-right" nowrap="true">
                            {item.outstanding_amount > 0
                              ? parseFloat(item.outstanding_amount).toFixed(2)
                              : ""}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
        <Popover
          open={this.state.bargeStatusOpenMenu}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          targetorigin={{ horizontal: "left", vertical: "top" }}
          onClose={() => this.setState({ bargeStatusOpenMenu: false })}
        >
          <div className="card border" style={{ width: "200px" }}>
            {this.state.status === "Active" &&
              this.state.business_no === null &&
              !this.state.costing_availability && (
                <MenuItem
                  className=""
                  style={{
                    fontSize: "17px",
                    color:
                      config.company === "SRPL" ||
                      config.company === "AVS" ||
                      config.company === "PRPL"
                        ? "#918176"
                        : "rgb(169, 126, 103)",
                  }}
                  onClick={this.BargeCancelHandler}
                >
                  <i
                    className="fa fa-minus-square-o mr-3"
                    aria-hidden="true"
                    style={{
                      fontSize: 26,
                      color: "#3256a8",
                      opacity: "0.5",
                      margin: "0px 3px",
                      width: "20px",
                    }}
                  ></i>
                  Cancel
                </MenuItem>
              )}
            <MenuItem style={{ fontFamily: "Poppins" }}>
              <a href={"/clone-barge/" + btoa(bargeID)}>
                <i
                  className="fa fa-clone mr-3"
                  style={{
                    fontSize: 26,
                    color: "#3256a8",
                    opacity: "0.5",
                    margin: "0px 6px",
                    width: "16px",
                  }}
                ></i>
                Clone
              </a>
            </MenuItem>
          </div>
        </Popover>
      </div>
    );
  }
}
