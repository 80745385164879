import React, { Component } from "react";
import api from "../../../api/api";
import CookieHandler from "../../common/CookieHandler";
import { Alert } from "@material-ui/lab";
import { Snackbar, TextField, Checkbox, Drawer } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ChemicalSprayAdd from "./Chemical-Spray-Add";
import ChemicalSprayView from "./Chemical-Spray-View";
import ChemicalSprayUpdate from "./Chemical-Spray-Update";
import config from "../../../config/config";
import { withStyles } from "@material-ui/core/styles";
import DeleteConfirmation from "../../common/DeleteConfirmation";
import { pad, toLocaleString } from "../../common/common";
import Loader from "../../common/Loader";
const CheckBox = withStyles({
  root: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default class ChemicalSprayList extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      chemical_spray_costing_barges: [],
      snackBarErrorOpen: false,
      snackBarSuccessOpen: false,
      isLoading: true,
      barge_nomination: "",
      jetty_name: "",
      business_no_drop_value: [],
      business_no_id: [],
      features: [],
      ChemicalSprayAdd: false,
      ChemicalSprayView: false,
      ChemicalSprayUpdate: false,
      chemicalSprayID: null,
      updateDocuments: false,
    };
  }

  componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    } else {
      var features = this.Cookie.getCookie("features");
      this.setState({ features: features });
      var loginUserID = this.Cookie.getCookie("loginUserId");
      var idToken = this.Cookie.getIdTokenCookie();
      api.getBusinessNumbers(loginUserID, idToken).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
              if (res.business_numbers) {
                let business_no_drop_value = [];
                for (var i of res.business_numbers) {
                  business_no_drop_value.push({
                    name: i.business_no,
                    value: i.id,
                    mother_vessel_name: i.mother_vessel_name,
                  });
                }
                this.setState({
                  business_no_drop_value: business_no_drop_value,
                  isLoading: false,
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
    }
  }

  searchBargeHandler() {
    this.setState({ isLoading: true });
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    //this.state.barge_nomination, this.state.jetty_name
    var barge_name = this.state.barge_nomination;
    var jetty_name = this.state.jetty_name;
    let business_no = this.state.business_no_id;
    let barge_id = this.state.barge_id;
    //if (business_no.length === 0) {
    // if (barge_name === "") {
    //   this.setState({
    //     barge_name_error: true,
    //     snackBarErrorOpen: true,
    //     isLoading: false,
    //     errorMsg: "Please fill mandatory fields"
    //   });
    //   return;
    // }
    api
      .get_barges_for_chemical_spray(
        loginUserID,
        idToken,
        business_no,
        barge_id,
        barge_name,
        jetty_name
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.barges) {
                let newData = [];
                var result = res.barges.reduce(function (r, a) {
                  r[a.id] = r[a.id] || [];
                  r[a.id].push(a);
                  return r;
                }, Object.create(null));
                for (let l = 0; l < Object.keys(result).length; l++) {
                  newData.push({ new_barges: result[Object.keys(result)[l]] });
                }
                let purchase_contracts_barges = newData;
                if (newData.length === 0) {
                  this.setState({
                    snackBarErrorOpen: true,
                    errorMsg: "No records to display",
                  });
                }
                this.setState({
                  chemical_spray_costing_barges: purchase_contracts_barges,
                  isLoading: false,
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
                isLoading: false,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  }

  deleteBargePurchaseFinancial = (data) => {
    this.setState({ isLoading: true });
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    var chemical_spray_id = data.id;
    api
      .delete_barge_chemical_spray(loginUserID, idToken, chemical_spray_id)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState({
                deleteCosting: false,
                successMsg: res.message,
                errorMsg: "",
                isLoading: false,
              });
              this.searchBargeHandler(
                this.state.barge_nomination,
                this.state.jetty_name
              );
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
                isLoading: false,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            } else if (res.code === "624") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
                isLoading: false,
              });
            } else if (res.code === "650") {
              this.setState({
                deleteCosting: false,
                snackBarErrorOpen: true,
                errorMsg:
                  "Payments are available for this record. Please delete Payments before deleting this record.",
                deleteAlertMsg:
                  "Payments are available for this record. Please delete Payments before deleting this record.",

                isLoading: false,
              });
            }
          });
        }
      });
  };

  onUpdateResponse = (value) => {
    if (value === "200") {
      this.setState({
        ChemicalSprayUpdate: false,
        ChemicalSprayAdd: false,
        ChemicalSprayView: true,
        updateDocuments: false,
      });
    }
  };

  onAddResponse = (value) => {
    if (value === "200") {
      this.setState({
        ChemicalSprayAdd: false,
        ChemicalSprayView: false,
        showDrawer: false,
      });
    }
  };

  onCancel = (value) => {
    if (value === false) {
      this.setState({
        ChemicalSprayUpdate: false,
        ChemicalSprayAdd: false,
        ChemicalSprayView: false,
        showDrawer: false,
        chemicalSprayID: null,
        updateDocuments: false,
      });
    }
  };

  onEdit = (value) => {
    if (value === true) {
      this.setState({
        ChemicalSprayUpdate: true,
        showDrawer: true,
        ChemicalSprayAdd: false,
        ChemicalSprayView: false,
        updateDocuments: false,
      });
    }
  };

  addDocuments = () => {
    this.setState({
      showDrawer: true,
      ChemicalSprayUpdate: true,
      ChemicalSprayView: false,
      ChemicalSprayAdd: false,
      updateDocuments: true,
    });
  };

  render() {
    let drawerComponent;
    if (this.state.ChemicalSprayAdd) {
      drawerComponent = (
        <ChemicalSprayAdd
          onAddResponse={this.onAddResponse}
          onCancel={this.onCancel}
        />
      );
    } else if (
      this.state.ChemicalSprayView &&
      this.state.chemicalSprayID !== null
    ) {
      drawerComponent = (
        <ChemicalSprayView
          chemicalSprayID={this.state.chemicalSprayID}
          returnDocumentsHandler={this.addDocuments}
          onCancel={this.onCancel}
          onEdit={this.onEdit}
        />
      );
    } else if (
      this.state.ChemicalSprayUpdate &&
      this.state.chemicalSprayID !== null
    ) {
      drawerComponent = (
        <ChemicalSprayUpdate
          chemicalSprayID={this.state.chemicalSprayID}
          onUpdateCosting={this.onUpdateResponse}
          onCancel={this.onCancel}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }

    return (
      <>
        {this.state.isLoading && <Loader />}

        {!this.state.isLoading && (
          <div className="card">
            <Drawer
              anchor="right"
              open={this.state.showDrawer}
              variant="temporary"
              elevation={20}
              style={{ overflow: "initial" }}
            >
              <div className="row" style={{ width: 800 }}>
                {drawerComponent}
              </div>
            </Drawer>
            <div className="row mb-1 p-2">
              <div className="col-lg-3 p-0">
                <Autocomplete
                  multiple
                  options={this.state.business_no_drop_value}
                  getOptionLabel={(option) => option.mother_vessel_name}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <CheckBox style={{ marginRight: 8 }} checked={selected} />
                      <div className="col-lg p-0 m-0">
                        <label className="form_label mb-0">
                          {option.mother_vessel_name}
                        </label>
                        <label
                          className="contract_display_header_value m-0 pl-0"
                          style={{ fontSize: 10, paddingLeft: 5 }}
                        >
                          {"( " + option.name + " )"}
                        </label>
                      </div>
                    </React.Fragment>
                  )}
                  onChange={(event, value) => {
                    if (value) {
                      var data = [];
                      for (var i of value) {
                        data.push(i.value);
                      }
                      //var floating_crane_vendor = data.toString();
                      this.setState({ business_no_id: data });
                    } else {
                      this.setState({ business_no_id: [] });
                    }
                  }}
                  disableCloseOnSelect
                  name="Business_Number"
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Business No"
                      variant="standard"
                      style={{ top: 8 }}
                      fullWidth
                    />
                  )}
                  style={{ display: "contents" }}
                />
              </div>
              <div className="col-lg-2 pr-0 pt-2">
                <button
                  onClick={() => this.searchBargeHandler()}
                  className="header_button header_button_text add_button_adjustment"
                  style={{ width: "auto", marginTop: "15px" }}
                >
                  Load
                </button>
              </div>
              <div className="col text-right float-sm-left">
                <button
                  className="header_button header_button_text add_button_adjustment"
                  style={{ width: "auto", marginTop: "25px" }}
                  onClick={() =>
                    this.setState({ ChemicalSprayAdd: true, showDrawer: true })
                  }
                >
                  New
                </button>
              </div>
            </div>

            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={this.state.snackBarErrorOpen}
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              style={{ width: "450px" }}
            >
              <Alert
                elevation={6}
                variant="filled"
                onClose={() => this.setState({ snackBarErrorOpen: false })}
                severity="error"
                style={{ width: "100%" }}
              >
                {this.state.errorMsg}
              </Alert>
            </Snackbar>
            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              style={{ width: "450px" }}
              open={this.state.snackBarSuccessOpen}
              autoHideDuration={100000}
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
            >
              <Alert
                elevation={6}
                variant="filled"
                onClose={() => this.setState({ snackBarSuccessOpen: false })}
                severity="success"
                style={{ width: "100%" }}
              >
                {this.state.sucessMsg}
              </Alert>
            </Snackbar>

            {this.state.chemical_spray_costing_barges.length > 0 && (
              <div className="table-responsive">
                <table className="table table-bordered table-sm mb-0">
                  <thead className="table-header">
                    <tr>
                      <th className=" text-uppercase " nowrap="true"></th>
                      <th nowrap="true" className=" text-uppercase ">
                        Cositng ID
                      </th>
                      <th nowrap="true" className=" text-uppercase ">
                        Barge ID
                      </th>
                      <th nowrap="true" className=" text-uppercase ">
                        Barge Nomination
                      </th>
                      <th nowrap="true" className=" text-uppercase ">
                        Business No
                      </th>
                      <th nowrap="true" className="text-right  text-uppercase ">
                        Total Payable
                      </th>
                      <th nowrap="true" className="text-right  text-uppercase ">
                        Paid
                      </th>
                      <th nowrap="true" className="text-right  text-uppercase ">
                        Remaining
                      </th>
                    </tr>
                  </thead>
                  {this.state.chemical_spray_costing_barges.map((p, index) => (
                    <tbody key={index}>
                      {p.new_barges.map((idr, indx) => (
                        <tr key={indx} className="rounded">
                          {indx === 0 && (
                            <td
                              className="text-center"
                              nowrap="true"
                              rowSpan={p.new_barges.length}
                            >
                              <i
                                className="fa fa-eye mr-2"
                                aria-hidden="true"
                                style={{
                                  fontSize: 22,
                                  color: config.themeColor,
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  this.setState({
                                    ChemicalSprayView: true,
                                    chemicalSprayID: idr.id,
                                    showDrawer: true,
                                  })
                                }
                              ></i>
                              <i
                                className="fa fa-trash-o"
                                aria-hidden="true"
                                style={{
                                  fontSize: 21,
                                  color: config.themeColor,
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  this.setState({
                                    deleteCosting: true,
                                    barge_pur_fin_id: idr.id,
                                    deleteData: idr,
                                  })
                                }
                              ></i>
                            </td>
                          )}
                          <td className=" text-left" nowrap="true">
                            {idr.costing_no}
                          </td>
                          <td className=" text-left">{pad(idr.barge_id)}</td>
                          <td className=" text-left" nowrap="true">
                            {idr.barge_nomination}
                          </td>
                          <td className=" text-left" nowrap="true">
                            {idr.business_no}
                          </td>
                          {indx === 0 && (
                            <td
                              className=" text-right"
                              nowrap="true"
                              rowSpan={p.new_barges.length}
                            >
                              {toLocaleString(idr.total_payable)}
                            </td>
                          )}
                          {indx === 0 && (
                            <td
                              className=" text-right"
                              nowrap="true"
                              rowSpan={p.new_barges.length}
                            >
                              {toLocaleString(idr.paid)}
                            </td>
                          )}
                          {indx === 0 && (
                            <td
                              className=" text-right"
                              nowrap="true"
                              rowSpan={p.new_barges.length}
                            >
                              {toLocaleString(idr.Remaining_amount)}
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  ))}
                </table>
              </div>
            )}

            {this.state.deleteCosting && (
              <DeleteConfirmation
                open={this.state.deleteCosting}
                close={() => this.setState({ deleteCosting: false })}
                callbackFn={() =>
                  this.deleteBargePurchaseFinancial(this.state.deleteData)
                }
              />
            )}
          </div>
        )}
      </>
    );
  }
}
