import React, { Component } from "react";
import SideBar from "../common/SideBar";
import Header from "../common/Header";

import api from "../../api/api";
import config from "../../config/config";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CookieHandler from "../common/CookieHandler";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import EventIcon from "@material-ui/icons/Event";

import {
  MenuItem,
  TextField,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  FormControlLabel,
  Snackbar,
  LinearProgress,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { withStyles } from "@material-ui/core/styles";
import FileUpload from "../common/FileUpload";
import {
  dateFormateToDB,
  localDateFormate,
  pad,
  progressBarSize,
  _toBeDelivered,
} from "../common/common";
import BargeAgreements from "../contracts/BargeAgreements/BargeAgreements";
import Loader from "../common/Loader";

const NewRadio = withStyles({
  root: {
    color: config.themeColor,
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 6,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#42352d",
  },
}))(LinearProgress);

export default class Barge_Update extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      purchase_contracts: [],
      purchase_type: null,

      bargeNomination: "",
      barge_captain_phone_no: "",
      barging_type: "Per MT",
      lumpsum_price: "",
      lumpsum_price_currency:
        config.company === "SDAM" ||
        config.company === "SRK" ||
        config.company === "BTR"
          ? "IDR"
          : "0",
      barge_vendor: "",
      barging_base_price: "",
      barging_base_price_currency:
        config.company === "SDAM" ||
        config.company === "SRK" ||
        config.company === "BTR"
          ? "IDR"
          : "0",
      barge_planned_quantity_in_mt: "",
      bargeQuantityInMT: "",
      return_cargo_quantity_in_mt: "",
      deadFreight: "",
      loadingJetty: "",
      barge_status: "0",
      pc_qualities_id: "0",
      supplier_barge_window: "",
      buyer_barge_window: "",
      loadingDate: null,
      completionTime: null,
      supplierDeadFreightIncurred: "",
      supplierDeadFreightValue: "",
      SDAMSAIIDeadFreightIncurred: "",
      SDAMSAIIDeadFreightValue: "",
      NORAnchorageDate: null,
      commenceDischargeDate: null,
      completionDischargeDate: null,
      timeAtAnchorage: "",
      timeAtAnchorageDays: "",
      timeAtAnchorageHours: "",
      timeAtAnchorageMinutes: "",
      supplierSurveyorName: "",
      supplierSurveyorAnalysisType: "0",
      saiiSurveyorName: "",
      saiiSurveyorAnalysisType: "0",
      witnessSurveyorName: "",
      witnessSurveyorAnalysisType: "0",
      chemicalSpray: "No",
      price: "",
      redraftQuantity: "",
      redraftParty: "0",
      procurement_quality_standard: null,
      quantityDifference: "",
      quantityDifferenceAccount: "0",
      bargeQuantityInMTErrorStatus: false,
      pc_qualities: [
        <MenuItem value="0" key={-1}>
          {" "}
          Please Select
        </MenuItem>,
      ],
      activeStep: 0,
      purchase_contract_id: null,
      purchase_contract_value: null,
      purchase_qualities: [],
      purchaseDataForMenu: [],
      purchase_types: [],
      bargeVendors: [],
      filter_selected_pc_qulaity: [],
      isLoading: true,
      mandatory_fields: false,
      // existing barge data
      existing_barge_quan: 0,
      existing_pc_contract: "",
      existing_pc_quality: "",
      barge_agreement_info: {
        barge_vendor: null,
        barging_base_price: null,
        barging_type: "Per MT",
        barging_base_price_currency: "",
        lumpsum_price_currency: "",
        lumpsum_price: "",
        vat_applicability: "Exclusive",
      },
      purchaseQualitySelection: null,
      purchaseTypeSelection: null,
      features: [],
      files: [],
      barging_agreement_price_list_id: null,
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    var features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (!(features.includes("47") || features.includes("97"))) {
      window.location.href = "/dashboard";
    }
    try {
      var barge_id = decodeURIComponent(
        window.atob(this.props.match.params.bargeID)
      );
    } catch (e) {
      window.location.href = "/barge-list";
    }
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    // for get_Currencies
    await api.getCurrencies(loginUserID, idToken).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
          if (res) {
            var currencies = [
              <MenuItem value="0" key={-1}>
                {" "}
                Please Select
              </MenuItem>,
            ];
            for (var i in res.Currency) {
              currencies.push(
                <MenuItem value={res.Currency[i].currency} key={[i]}>
                  {res.Currency[i].currency}
                </MenuItem>
              );
            }
            this.setState({
              currencyData: currencies,
            });
          } else {
            alert("Unexpected error occured. Please contact administrator.");
          }
        });
      } else if (response.status === 607) {
        window.location.href = "/logout";
      }
    });
    var status = "";
    var filteredPContracts = [];
    // Get purchase contracts API
    const pc_contracts = await api.getPurchaseContracts(
      loginUserID,
      status,
      idToken
    );
    if (pc_contracts.status >= 200 && pc_contracts.status < 300) {
      pc_contracts.json().then((res) => {
        if (res.code === "200") {
          if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
          if (res.purchase_contract) {
            let purchaseDataforMenu = [];
            filteredPContracts = res.purchase_contract.filter(
              (e, indx) => e.status !== "Closed" && e.status !== "cancelled"
            );
            for (let i = 0; i < filteredPContracts.length; i++) {
              purchaseDataforMenu.push({
                name: filteredPContracts[i].contract_no,
                value: filteredPContracts[i].id,
                key: i,
                vendor_name: filteredPContracts[i].vendor_name,
              });
            }
            this.setState({
              purchaseDataForMenu: purchaseDataforMenu,
              //purchaseContractList: res.purchase_contract,
              purchaseContractList: filteredPContracts,
            });
          } else {
            alert("Unexpected error occured. Please contact administrator.");
          }
        } else if (res.code === "607") {
          window.location.href = "/logout";
        }
      });
    }

    //Get vendors
    const vendors = await api.getVendors(loginUserID, idToken, status);
    if (vendors.status >= 200 && vendors.status < 300) {
      vendors.json().then((res) => {
        if (res.code === "200") {
          if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);

          if (res.vendors) {
            const surveyors = [];
            const bargeVendors = [];
            for (var i in res.vendors) {
              if (res.vendors[i].vendor_type === "Surveyor") {
                surveyors.push({
                  name: res.vendors[i].vendor_name,
                  value: res.vendors[i].id,
                  key: i,
                });
              }
              if (res.vendors[i].vendor_type === "Barge") {
                bargeVendors.push({
                  name: res.vendors[i].vendor_name,
                  value: res.vendors[i].id,
                  key: i,
                });
              }
            }
            this.setState({
              surveyorsData: surveyors,
              bargeVendors: bargeVendors,
              //isLoading: false
            });
          } else {
            alert("Unexpected error occured. Please contact administrator.");
          }
        } else if (res.code === "607") {
          window.location.href = "/logout";
        }
      });
    }
    await api.get_barge(loginUserID, idToken, barge_id).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            if (res.barges) {
              let days = "0";
              let hours = "0";
              let minutes = "0";
              if (res.barges.time_at_anchorage_days) {
                days = res.barges.time_at_anchorage_days;
              }
              if (res.barges.time_at_anchorage_hours) {
                hours = res.barges.time_at_anchorage_hours;
              }
              if (res.barges.time_at_anchorage_minutes) {
                minutes = res.barges.time_at_anchorage_minutes;
              }
              var filteredData = this.state.purchaseDataForMenu.filter(
                (e, indx) => e.name === res.barges.purchase_contract_no
              );
              let copyAgreement = {
                ...this.state.barge_agreement_info,
                barging_base_price: res.barges.barging_base_price,
                barging_type: res.barges.barging_type,
                lumpsum_price: res.barges.lumpsum_price,
                lumpsum_price_currency: res.barges.lumpsum_price_currency,
                barging_base_price_currency:
                  res.barges.barging_base_price_currency,
                vat_applicability: res.barges.vat_applicability,
                barging_agreement_price_list_id:
                  res.barges.barging_agreement_price_list_id,
                price_list_id: res.barges.barging_agreement_price_list_id,
                price:
                  res.barges.barging_type === "Lumpsum"
                    ? res.barges.lumpsum_price
                    : res.barges.barging_base_price,
                currency:
                  res.barges.barging_type === "Lumpsum"
                    ? res.barges.lumpsum_price_currency
                    : res.barges.barging_base_price_currency,
                barge_vendor_drop: res.barges.barge_vendor
                  ? {
                      name: res.barges.barge_vendor_name,
                      value: res.barges.barge_vendor,
                    }
                  : null,
              };
              this.setState(
                {
                  barge_agreement_info: copyAgreement,
                  bargeNomination:
                    res.barges.barge_nomination === null
                      ? ""
                      : res.barges.barge_nomination,
                  barge_captain_phone_no:
                    res.barges.barge_captain_phone_no === null
                      ? ""
                      : res.barges.barge_captain_phone_no,
                  bargeVendorNameValue: res.barges.barge_vendor
                    ? {
                        name: res.barges.barge_vendor_name,
                        value: res.barges.barge_vendor,
                      }
                    : "",
                  barge_vendor:
                    res.barges.barge_vendor === null
                      ? ""
                      : res.barges.barge_vendor,
                  barging_agreement_price_list_id:
                    res.barging_agreement_price_list_id,
                  barging_base_price:
                    res.barges.barging_base_price === null
                      ? ""
                      : res.barges.barging_base_price,
                  barging_type: res.barges.barging_type,
                  lumpsum_price: res.barges.lumpsum_price
                    ? res.barges.lumpsum_price
                    : "",
                  lumpsum_price_currency: res.barges.lumpsum_price_currency
                    ? res.barges.lumpsum_price_currency
                    : "0",
                  barging_base_price_currency:
                    res.barges.barging_base_price_currency === null
                      ? "0"
                      : res.barges.barging_base_price_currency,
                  vat_incl_or_excl: res.barges.vat_incl_or_excl,
                  vat_applicability: res.barges.vat_applicability,
                  // barging_base_price_currency: res.barges.barging_base_price_currency === null ? '0' : res.barges.barging_base_price_currency,
                  barge_planned_quantity_in_mt:
                    res.barges.barge_planned_quantity_in_mt === null
                      ? ""
                      : res.barges.barge_planned_quantity_in_mt,
                  bargeQuantityInMT:
                    res.barges.barge_quantity_in_mt === null
                      ? ""
                      : res.barges.barge_quantity_in_mt,
                  return_cargo_quantity_in_mt:
                    res.barges.return_cargo_quantity_in_mt === null
                      ? ""
                      : res.barges.return_cargo_quantity_in_mt,
                  previousBargeQuantityInMT:
                    res.barges.barge_quantity_in_mt === null
                      ? ""
                      : res.barges.barge_quantity_in_mt,
                  deadFreight:
                    res.barges.dead_freight === null
                      ? ""
                      : res.barges.dead_freight,
                  loadingJetty:
                    res.barges.loading_jetty === null
                      ? ""
                      : res.barges.loading_jetty,
                  barge_status:
                    res.barges.barge_status === null
                      ? "0"
                      : res.barges.barge_status,
                  supplier_barge_window:
                    res.barges.supplier_barge_window === null
                      ? ""
                      : res.barges.supplier_barge_window,
                  buyer_barge_window:
                    res.barges.buyer_barge_window === null
                      ? ""
                      : res.barges.buyer_barge_window,
                  loadingDate:
                    res.barges.loading_date === ""
                      ? null
                      : res.barges.loading_date,
                  completionTime:
                    res.barges.completion_time === ""
                      ? null
                      : res.barges.completion_time,
                  supplierDeadFreightIncurred:
                    res.barges.supplier_dead_freight_incurred === null
                      ? "0"
                      : res.barges.supplier_dead_freight_incurred,
                  supplierDeadFreightValue:
                    res.barges.supplier_dead_freight_value === null
                      ? "0"
                      : res.barges.supplier_dead_freight_value,
                  SDAMSAIIDeadFreightIncurred:
                    res.barges.internal_dead_freight_incurred === null
                      ? "0"
                      : res.barges.internal_dead_freight_incurred,
                  SDAMSAIIDeadFreightValue:
                    res.barges.internal_dead_freight_value === null
                      ? "0"
                      : res.barges.internal_dead_freight_value,
                  NORAnchorageDate:
                    res.barges.nor_anchorage === ""
                      ? null
                      : res.barges.nor_anchorage,
                  commenceDischargeDate:
                    res.barges.commence_discharge === ""
                      ? null
                      : res.barges.commence_discharge,
                  completionDischargeDate:
                    res.barges.completion_discharge === ""
                      ? null
                      : res.barges.completion_discharge,
                  timeAtAnchorage:
                    days +
                    " days, " +
                    hours +
                    " hours, " +
                    minutes +
                    " minutes",
                  timeAtAnchorageDays: res.barges.time_at_anchorage_days
                    ? res.barges.time_at_anchorage_days
                    : "",
                  timeAtAnchorageHours: res.barges.time_at_anchorage_hours
                    ? res.barges.time_at_anchorage_hours
                    : "",
                  timeAtAnchorageMinutes: res.barges.time_at_anchorage_minutes
                    ? res.barges.time_at_anchorage_minutes
                    : "",
                  supplierSurveyorNameValue: res.barges.supplier_surveyor
                    ? {
                        name: res.barges.supplier_surveyor_names,
                        value: res.barges.supplier_surveyor,
                      }
                    : "",
                  supplier_surveyor_name: res.barges.supplier_surveyor_names
                    ? res.barges.supplier_surveyor_names
                    : "",
                  supplierSurveyorName: res.barges.supplier_surveyor
                    ? res.barges.supplier_surveyor
                    : null,
                  supplierSurveyorAnalysisType: res.barges
                    .supplier_surveyor_analysis_type
                    ? res.barges.supplier_surveyor_analysis_type
                    : "0",
                  saiiSurveyorNameValue: res.barges.internal_surveyor
                    ? {
                        name: res.barges.internal_surveyor_names,
                        value: res.barges.internal_surveyor,
                      }
                    : "",
                  saiiSurveyorName: res.barges.internal_surveyor
                    ? res.barges.internal_surveyor
                    : null,
                  internal_surveyor_name: res.barges.internal_surveyor_names
                    ? res.barges.internal_surveyor_names
                    : null,
                  saiiSurveyorAnalysisType: res.barges
                    .internal_surveyor_analysis_type
                    ? res.barges.internal_surveyor_analysis_type
                    : "0",
                  witnessSurveyorNameValue: res.barges.joint_surveyor
                    ? {
                        name: res.barges.joint_surveyor_names,
                        value: res.barges.joint_surveyor,
                      }
                    : "",
                  witnessSurveyorName: res.barges.joint_surveyor
                    ? res.barges.joint_surveyor
                    : null,
                  joint_surveyor_name: res.barges.joint_surveyor_names
                    ? res.barges.joint_surveyor_names
                    : null,
                  witnessSurveyorAnalysisType: res.barges
                    .joint_surveyor_analysis_type
                    ? res.barges.joint_surveyor_analysis_type
                    : "0",
                  chemicalSpray: res.barges.chemical_spray
                    ? res.barges.chemical_spray
                    : "No",
                  price: res.barges.chemical_spray_price
                    ? res.barges.chemical_spray_price
                    : "",
                  redraftQuantity:
                    res.barges.redraft_quantity === null
                      ? ""
                      : res.barges.redraft_quantity,
                  redraftParty: res.barges.redraft_party
                    ? res.barges.redraft_party
                    : "0",
                  quantityDifference: res.barges.quantity_difference
                    ? res.barges.quantity_difference
                    : "",
                  quantityDifferenceAccount: res.barges
                    .quantity_difference_account
                    ? res.barges.quantity_difference_account
                    : "0",
                  remarks: res.barges.remarks ? res.barges.remarks : "",
                  purchase_contract_value: res.barges.purchase_contract_id
                    ? filteredData[0]
                    : { name: "", value: "", vendor_name: "" },
                  purchase_contract_id: res.barges.purchase_contract_id
                    ? res.barges.purchase_contract_id
                    : "",
                  pc_qualities_id: res.barges.pc_qualities_id
                    ? res.barges.pc_qualities_id
                    : "",
                  pc_quality: res.barges.pc_quality
                    ? res.barges.pc_quality
                    : null,
                  pc_purchase_type_id: res.barges.pc_purchase_type_id
                    ? res.barges.pc_purchase_type_id
                    : null,
                  purchase_type: res.barges.purchase_type
                    ? res.barges.purchase_type
                    : null,
                  purchase_contract_no: res.barges.purchase_contract_no
                    ? res.barges.purchase_contract_no
                    : null,
                  procurement_quality_standard: res.barges
                    .procurement_quality_standard
                    ? res.barges.procurement_quality_standard
                    : null,
                  files:
                    res.barges.files.length > 0
                      ? res.barges.files
                      : [
                          {
                            file_description: "",
                            file_name: "",
                            file_extension: "",
                            file_content: "",
                            feature_name: "Barges",
                          },
                        ],
                  //isLoading: false,
                },
                () => {
                  if (res.barges.purchase_contract_id) {
                    var purchase_contract_id = res.barges.purchase_contract_id;
                    var pc_qualities_id = res.barges.pc_qualities_id
                      ? res.barges.pc_qualities_id
                      : "";
                    api
                      .getPurchaseContract(
                        loginUserID,
                        res.barges.purchase_contract_id,
                        idToken,
                        status
                      )
                      .then((response) => {
                        if (response.status >= 200 && response.status < 300) {
                          response.json().then((res) => {
                            if (res.code === "200") {
                              if (res.idtoken)
                                this.Cookie.setIdTokenCookie(res.idtoken);
                              if (res.purchase_contract) {
                                const pc_qualities = [
                                  <MenuItem value="0" key={-1} disabled>
                                    {" "}
                                    Please Select
                                  </MenuItem>,
                                ];
                                if (
                                  res.purchase_contract.qualities.length > 0
                                ) {
                                  for (var i of res.purchase_contract
                                    .qualities) {
                                    pc_qualities.push(
                                      <MenuItem value={i.id} key={i.id}>
                                        {i.quality}
                                      </MenuItem>
                                    );
                                  }
                                }
                                this.setState(
                                  {
                                    purchase_qualities:
                                      res.purchase_contract.qualities,
                                    pc_qualities: pc_qualities,
                                    existing_pc_contract: purchase_contract_id,
                                    existing_pc_quality: pc_qualities_id,
                                    isLoading: false,
                                  },
                                  () => {
                                    api
                                      .get_barges_qty_by_purchase_contract(
                                        loginUserID,
                                        idToken,
                                        purchase_contract_id,
                                        pc_qualities_id
                                      )
                                      .then((response) => {
                                        if (
                                          response.status >= 200 &&
                                          response.status < 300
                                        ) {
                                          response.json().then((barges_qty) => {
                                            if (barges_qty.code === "200") {
                                              let barge_consider_quantity = "";
                                              if (
                                                this.state.redraftQuantity &&
                                                this.state
                                                  .quantityDifferenceAccount ===
                                                  "Supplier Account"
                                              ) {
                                                barge_consider_quantity =
                                                  this.state.redraftQuantity;
                                              } else if (
                                                this.state
                                                  .barge_planned_quantity_in_mt
                                              ) {
                                                barge_consider_quantity =
                                                  this.state
                                                    .barge_planned_quantity_in_mt;
                                              } else {
                                                barge_consider_quantity =
                                                  this.state.bargeQuantityInMT;
                                              }
                                              this.setState(
                                                {
                                                  total_barge_quantity:
                                                    Number(
                                                      barges_qty.total_barge_quantity
                                                    ) -
                                                    Number(
                                                      barge_consider_quantity
                                                    ),
                                                },
                                                () => {
                                                  // calculating purchase quality - quantity (including tolerance);
                                                  const filter_selected_qulaity =
                                                    this.state.purchase_qualities.filter(
                                                      (e, idx) =>
                                                        e.id === pc_qualities_id
                                                    );

                                                  if (
                                                    filter_selected_qulaity.length >
                                                    0
                                                  ) {
                                                    let pc_tolerance_quantity =
                                                      (Number(
                                                        filter_selected_qulaity[0]
                                                          .quantity_adjusted
                                                      ) *
                                                        Number(
                                                          filter_selected_qulaity[0]
                                                            .tolerance
                                                        )) /
                                                      100;
                                                    let pc_total_quantity =
                                                      Number(
                                                        filter_selected_qulaity[0]
                                                          .quantity_adjusted
                                                      ) +
                                                      Number(
                                                        pc_tolerance_quantity
                                                      );
                                                    this.setState({
                                                      pc_total_quantity:
                                                        pc_total_quantity,
                                                      existing_barge_quan:
                                                        barge_consider_quantity,
                                                    });
                                                  }
                                                }
                                              );
                                            } else if (
                                              barges_qty.code === "607"
                                            ) {
                                              window.location.href = "/logout";
                                            }
                                          });
                                        }
                                      });
                                  }
                                );
                              } else {
                                alert(
                                  "Unexpected error occured. Please contact administrator."
                                );
                              }
                            } else if (res.code === "607") {
                              window.location.href = "/logout";
                            }
                          });
                        }
                      });
                  } else {
                    this.setState({
                      isLoading: false,
                    });
                  }
                }
              );
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          } else if (res.code === "601") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          } else if (res.code === "639") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
            });
          }
        });
      }
    });

    document.title = config.documentTitle + "Update Barge";
  }

  NORAnchorageDateHandler = (date) => {
    var timeAtAnchorage = "";
    var timeAtAnchorageDays = "";
    var timeAtAnchorageHours = "";
    var timeAtAnchorageMinutes = "";
    if (this.state.completionDischargeDate) {
      var g1 = new Date(date);
      var g2 = new Date(this.state.completionDischargeDate);
      var diff = g2.getTime() - g1.getTime();
      var mins = Math.round(diff / 60000);
      var hrs = Math.floor(mins / 60);
      var days = Math.floor(hrs / 24);
      mins = mins % 60;
      hrs = hrs % 24;
      timeAtAnchorage = days + " days, " + hrs + " hours, " + mins + " minutes";
      timeAtAnchorageDays = days;
      timeAtAnchorageHours = hrs;
      timeAtAnchorageMinutes = mins;
    }
    this.setState({
      NORAnchorageDate: date,
      timeAtAnchorage: timeAtAnchorage,
      timeAtAnchorageDays: timeAtAnchorageDays,
      timeAtAnchorageHours: timeAtAnchorageHours,
      timeAtAnchorageMinutes: timeAtAnchorageMinutes,
    });
  };

  completionDischargeDateHandler = (date) => {
    var timeAtAnchorage = "";
    var timeAtAnchorageDays = "";
    var timeAtAnchorageHours = "";
    var timeAtAnchorageMinutes = "";
    if (this.state.NORAnchorageDate) {
      var g1 = new Date(this.state.NORAnchorageDate);
      var g2 = new Date(date);
      var diff = g2.getTime() - g1.getTime();
      var mins = Math.round(diff / 60000);
      var hrs = Math.floor(mins / 60);
      var days = Math.floor(hrs / 24);
      mins = mins % 60;
      hrs = hrs % 24;
      timeAtAnchorage = days + " days, " + hrs + " hours, " + mins + " minutes";
      timeAtAnchorageDays = days;
      timeAtAnchorageHours = hrs;
      timeAtAnchorageMinutes = mins;
    }
    this.setState({
      completionDischargeDate: date,
      timeAtAnchorage: timeAtAnchorage,
      timeAtAnchorageDays: timeAtAnchorageDays,
      timeAtAnchorageHours: timeAtAnchorageHours,
      timeAtAnchorageMinutes: timeAtAnchorageMinutes,
    });
  };

  handlePurchaseContract = (value) => {
    if (value) {
      var loginUserID = this.Cookie.getCookie("loginUserId");
      var idToken = this.Cookie.getIdTokenCookie();
      var status = "";
      var contractNo = value.name;
      var filteredContract = this.state.purchaseContractList.filter(
        (e, index) => e.contract_no === contractNo
      );
      var purchaseContractId =
        filteredContract.length > 0 ? filteredContract[0].pcid : null;

      if (purchaseContractId) {
        api
          .getPurchaseContract(loginUserID, purchaseContractId, idToken, status)
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              response.json().then((res) => {
                if (res.code === "200") {
                  if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
                  if (res.purchase_contract) {
                    const pc_qualities = [
                      <MenuItem value="0" key={-1} disabled>
                        {" "}
                        Please Select
                      </MenuItem>,
                    ];
                    if (res.purchase_contract.qualities.length > 0) {
                      for (var i of res.purchase_contract.qualities) {
                        pc_qualities.push(
                          <MenuItem value={i.id} key={i.id}>
                            {i.quality}
                          </MenuItem>
                        );
                      }
                    }
                    this.setState(
                      {
                        purchase_qualities: res.purchase_contract.qualities,
                        pc_qualities: pc_qualities,
                        pc_qualities_id: "0",
                        procurement_quality_standard: null,
                        purchase_type: null,
                        pc_purchase_type_id: null,
                        isLoading: false,
                      },
                      () => {
                        window.$("#myModal").modal({
                          backdrop: "static",
                          keyboard: true,
                          show: true,
                        });
                      }
                    );
                  } else {
                    alert(
                      "Unexpected error occured. Please contact administrator."
                    );
                  }
                } else if (res.code === "607") {
                  window.location.href = "/logout";
                }
              });
            }
          });
      } else {
        alert("Invaild purchase contract");
      }
    }
  };

  toLocaleString(value) {
    return Number(value).toLocaleString();
  }

  nextStepHandler = () => {
    if (this.state.activeStep === 0) {
      if (this.state.purchase_type === "FOB Barge") {
        if (this.state.bargeNomination === "") {
          this.setState({
            bargeNominationError: true,
            errorMsg: "Please fill mandatory fields.",
            snackBarErrorOpen: true,
          });
          return;
        }
        if (this.state.barge_vendor === "") {
          this.setState({
            barge_vendor_error: true,
            errorMsg: "Please fill mandatory fields.",
            snackBarErrorOpen: true,
          });
          return;
        }
      }
    }
    if (this.state.activeStep === 3 && this.state.chemicalSpray === "Yes") {
      if (this.state.price === "" || this.state.price === null) {
        this.setState({
          chemical_spray_error: true,
          snackBarErrorOpen: true,
          errorMsg: "Please enter chemcial spray value",
        });
        return;
      }
    }

    if (this.state.activeStep === 3) {
      /**
       *  Checking Mandatory files
       */
      var missing_fields = 0;
      let files_missing = 0;
      var row = this.state.files;
      for (var obj of row) {
        if (obj.file_type !== "" || obj.file_content !== "") {
          if (obj.file_content !== "") {
            if (obj.file_description === "") {
              missing_fields++;
              obj.file_description_error = true;
            } else {
              obj.file_description = obj.file_description.toUpperCase();
            }
          } else if (obj.file_description !== "") {
            if (obj.file_content === "") {
              files_missing++;
            }
          }
        }
      }
      this.setState({
        files: row,
      });

      if (missing_fields > 0) {
        this.setState({
          snackBarErrorOpen: true,
          errorMsg: "Please fill mandatory fields.",
        });
        return;
      }
      if (files_missing > 0) {
        this.setState({
          snackBarErrorOpen: true,
          errorMsg: "Please Select file.",
        });
        return;
      }
    }

    this.setState({
      activeStep: this.state.activeStep + 1,
    });
  };

  previousStepHandler = () => {
    this.setState({
      activeStep: this.state.activeStep - 1,
    });
  };

  submitHandler = () => {
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    var {
      procurement_quality_standard,
      businessAllocationID,
      bargeNomination,
      barge_captain_phone_no,
      barge_vendor,
      barging_base_price,
      barging_base_price_currency,
      barge_planned_quantity_in_mt,
      bargeQuantityInMT,
      return_cargo_quantity_in_mt,
      deadFreight,
      loadingJetty,
      barge_status,
      supplier_barge_window,
      buyer_barge_window,
      loadingDate,
      completionTime,
      NORAnchorageDate,
      commenceDischargeDate,
      completionDischargeDate,
      timeAtAnchorageDays,
      timeAtAnchorageHours,
      supplierDeadFreightIncurred,
      supplierDeadFreightValue,
      SDAMSAIIDeadFreightIncurred,
      SDAMSAIIDeadFreightValue,
      timeAtAnchorageMinutes,
      supplierSurveyorName,
      supplierSurveyorAnalysisType,
      saiiSurveyorName,
      saiiSurveyorAnalysisType,
      witnessSurveyorName,
      witnessSurveyorAnalysisType,
      chemicalSpray,
      price,
      redraftQuantity,
      redraftParty,
      quantityDifference,
      quantityDifferenceAccount,
      purchase_contract_id,
      pc_qualities_id,
      pc_purchase_type_id,
      barging_type,
      lumpsum_price,
      lumpsum_price_currency,
      vat_incl_or_excl,
      vat_applicability,
      barging_agreement_price_list_id,
    } = this.state;

    if (loadingDate === null) {
      loadingDate = "";
    }
    if (completionTime === null) {
      completionTime = "";
    }
    if (NORAnchorageDate === null) {
      NORAnchorageDate = "";
    }
    if (commenceDischargeDate === null) {
      commenceDischargeDate = "";
    }
    if (completionDischargeDate === null) {
      completionDischargeDate = "";
    }
    if (chemicalSpray === null) {
      chemicalSpray = "";
    }
    if (barging_base_price_currency === "0") {
      barging_base_price_currency = "";
    }
    if (supplierSurveyorAnalysisType === "0") {
      supplierSurveyorAnalysisType = "";
    }
    if (saiiSurveyorAnalysisType === "0") {
      saiiSurveyorAnalysisType = "";
    }
    if (witnessSurveyorAnalysisType === "0") {
      witnessSurveyorAnalysisType = "";
    }
    if (redraftParty === "0") {
      redraftParty = "";
    }
    if (quantityDifferenceAccount === "0") {
      quantityDifferenceAccount = "";
    }
    if (supplierDeadFreightIncurred < 1) {
      supplierDeadFreightIncurred = "";
      supplierDeadFreightValue = "";
      SDAMSAIIDeadFreightIncurred = "";
      SDAMSAIIDeadFreightValue = "";
    }
    if (chemicalSpray === "No") {
      price = "";
    }
    if (redraftQuantity === "") {
      redraftParty = "";
      quantityDifference = "";
      quantityDifferenceAccount = "";
    }

    if (businessAllocationID === "0") {
      this.setState({
        businessAllocationError: true,
      });
    }
    if (pc_qualities_id === "0") {
      pc_qualities_id = null;
    }

    var remarks = this.state.remarks ? this.state.remarks : "";
    if (bargeQuantityInMT === "") {
      supplierDeadFreightIncurred = "";
      supplierDeadFreightValue = "";
      SDAMSAIIDeadFreightIncurred = "";
      SDAMSAIIDeadFreightValue = "";
    }
    if (barging_type === "Per MT") {
      lumpsum_price = "";
      barging_base_price_currency =
        config.company === "SDAM" ||
        config.company === "SRK" ||
        config.company === "BTR"
          ? "IDR"
          : barging_base_price_currency;
      lumpsum_price_currency = "";
    } else if (barging_type === "Lumpsum") {
      barging_base_price = "";
      barging_base_price_currency = "";
      lumpsum_price_currency =
        config.company === "SDAM" ||
        config.company === "SRK" ||
        config.company === "BTR"
          ? "IDR"
          : lumpsum_price_currency;
    }
    var existing_file_ids = [];
    const existing_files = this.state.files.filter((e) => e.id);
    for (var obj of existing_files) {
      existing_file_ids.push(obj.id);
    }
    const non_exist_files = this.state.files.filter((e) => !e.id);
    const new_files = non_exist_files.filter((e) => e.file_content !== "");
    //New Business Number Operational MV
    try {
      var barge_id = decodeURIComponent(
        window.atob(this.props.match.params.bargeID)
      );
      api
        .update_barge(
          loginUserID,
          idToken,
          barge_id,
          purchase_contract_id,
          pc_qualities_id,
          pc_purchase_type_id,
          procurement_quality_standard,
          bargeNomination,
          barge_captain_phone_no,
          barge_vendor,
          barging_base_price,
          barge_planned_quantity_in_mt,
          barging_type,
          lumpsum_price,
          lumpsum_price_currency,
          vat_incl_or_excl,
          vat_applicability,
          bargeQuantityInMT,
          return_cargo_quantity_in_mt,
          barging_base_price_currency,
          deadFreight,
          loadingJetty,
          loadingDate,
          barge_status,
          supplier_barge_window,
          buyer_barge_window,
          completionTime,
          NORAnchorageDate,
          commenceDischargeDate,
          completionDischargeDate,
          supplierDeadFreightIncurred,
          supplierDeadFreightValue,
          SDAMSAIIDeadFreightIncurred,
          SDAMSAIIDeadFreightValue,
          timeAtAnchorageDays,
          timeAtAnchorageHours,
          timeAtAnchorageMinutes,
          supplierSurveyorName,
          supplierSurveyorAnalysisType,
          saiiSurveyorName,
          saiiSurveyorAnalysisType,
          witnessSurveyorName,
          witnessSurveyorAnalysisType,
          chemicalSpray,
          price,
          redraftQuantity,
          redraftParty,
          quantityDifference,
          quantityDifferenceAccount,
          remarks,
          barging_agreement_price_list_id,
          existing_file_ids,
          new_files
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                this.setState({
                  successMsg: res.message,
                });
                window.location.href = "/view-barge/" + btoa(barge_id);
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: "Missing mandatory fields",
                  successMsg: "",
                  isLoading: false,
                });
              } else if (res.code === "641") {
                this.setState({
                  errorMsg:
                    "Quantity cannot be greater than contract value with tolerance. Max allowed quantity is " +
                    Number(res.allowed_quantity).toFixed(3) +
                    ".",
                  bargeQuantityInMTErrorStatus: true,
                  successMsg: "",
                  isLoading: false,
                });
                alert(
                  "Quantity cannot be greater than contract value with tolerance. Max allowed quantity is " +
                    Number(res.allowed_quantity).toFixed(3) +
                    "."
                );
              } else if (res.code === "607") {
                window.location.href = "/logout";
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
                this.setState({ isLoading: false });
              }
            });
          }
        });
    } catch (e) {
      window.location.href = "/barge-list";
    }
  };

  getVendorvalue = (id) => {
    var vendorname = this.state.bargeVendors.filter(
      (e, index) => e.value === id
    );
    if (vendorname.length > 0) {
      return vendorname[0].name;
    } else {
      return null;
    }
  };

  bargeAgreementsHandler = (data) => {
    this.setState({
      ...this.state,
      ...data,
    });
  };

  render() {
    const res = /^\d*(\.\d{0,3})?$/;
    try {
      var bargeID = decodeURIComponent(
        window.atob(this.props.match.params.bargeID)
      );
    } catch (e) {
      window.location.href = "/barge-list";
    }
    return (
      <div id="wraper">
        <SideBar />
        <div className="content">
          <Header />
          {this.state.isLoading && <Loader />}
          {!this.state.isLoading && (
            <div className="content-header">
              <div className="col-sm pl-0">
                <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                  <a href={"/view-barge/" + btoa(bargeID)}>
                    <i className="fa fa-arrow-left left_arrow_adjst" />
                  </a>
                  Update Barge - {pad(bargeID)}
                </h4>
              </div>
            </div>
          )}
          {!this.state.isLoading && (
            <form className="container">
              <div className="row mb-0 stepper">
                <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                  <i
                    className={
                      this.state.activeStep > 0
                        ? "fa fa-check-circle"
                        : "fa fa-check-circle-o"
                    }
                    aria-hidden="true"
                    style={{
                      fontSize: 20,
                      color:
                        this.state.activeStep > 0
                          ? "#31c197"
                          : this.state.activeStep === 0
                          ? config.themeColor
                          : "#ccc",
                    }}
                  ></i>
                  <span
                    className="stepper_text"
                    style={{
                      color:
                        this.state.activeStep > 0
                          ? "#31c197"
                          : this.state.activeStep === 0
                          ? config.themeColor
                          : "#ccc",
                    }}
                  >
                    Barge Details
                  </span>
                  <i
                    className="fa fa-chevron-right stepper_sub_icon stepper_arrow"
                    aria-hidden="true"
                    style={{
                      color:
                        this.state.activeStep > 0
                          ? "#31c197"
                          : this.state.activeStep === 0
                          ? config.themeColor
                          : "#ccc",
                    }}
                  ></i>
                </span>
                <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                  <i
                    className={
                      this.state.activeStep > 1
                        ? "fa fa-check-circle"
                        : "fa fa-check-circle-o"
                    }
                    aria-hidden="true"
                    style={{
                      fontSize: 20,
                      color:
                        this.state.activeStep > 1
                          ? "#31c197"
                          : this.state.activeStep === 1
                          ? config.themeColor
                          : "#ccc",
                    }}
                  ></i>
                  <span
                    className="stepper_text"
                    style={{
                      color:
                        this.state.activeStep > 1
                          ? "#31c197"
                          : this.state.activeStep === 1
                          ? config.themeColor
                          : "#ccc",
                    }}
                  >
                    Barge Timings
                  </span>
                  <i
                    className="fa fa-chevron-right stepper_sub_icon stepper_arrow"
                    aria-hidden="true"
                    style={{
                      color:
                        this.state.activeStep > 1
                          ? "#31c197"
                          : this.state.activeStep === 1
                          ? config.themeColor
                          : "#ccc",
                    }}
                  ></i>
                </span>
                <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                  <i
                    className={
                      this.state.activeStep > 2
                        ? "fa fa-check-circle"
                        : "fa fa-check-circle-o"
                    }
                    aria-hidden="true"
                    style={{
                      fontSize: 20,
                      color:
                        this.state.activeStep > 2
                          ? "#31c197"
                          : this.state.activeStep === 2
                          ? config.themeColor
                          : "#ccc",
                    }}
                  ></i>
                  <span
                    className="stepper_text"
                    style={{
                      color:
                        this.state.activeStep > 2
                          ? "#31c197"
                          : this.state.activeStep === 2
                          ? config.themeColor
                          : "#ccc",
                    }}
                  >
                    Surveyor Details
                  </span>
                  <i
                    className="fa fa-chevron-right stepper_sub_icon stepper_arrow"
                    aria-hidden="true"
                    style={{
                      color:
                        this.state.activeStep > 2
                          ? "#31c197"
                          : this.state.activeStep === 2
                          ? config.themeColor
                          : "#ccc",
                    }}
                  ></i>
                </span>
                <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                  <i
                    className={
                      this.state.activeStep > 3
                        ? "fa fa-check-circle"
                        : "fa fa-check-circle-o"
                    }
                    aria-hidden="true"
                    style={{
                      fontSize: 20,
                      color:
                        this.state.activeStep > 3
                          ? "#31c197"
                          : this.state.activeStep === 3
                          ? config.themeColor
                          : "#ccc",
                    }}
                  ></i>
                  <span
                    className="stepper_text"
                    style={{
                      color:
                        this.state.activeStep > 3
                          ? "#31c197"
                          : this.state.activeStep === 3
                          ? config.themeColor
                          : "#ccc",
                    }}
                  >
                    Others
                  </span>
                  <i
                    className="fa fa-chevron-right stepper_sub_icon stepper_arrow"
                    aria-hidden="true"
                    style={{
                      color:
                        this.state.activeStep > 3
                          ? "#31c197"
                          : this.state.activeStep === 3
                          ? config.themeColor
                          : "#ccc",
                    }}
                  ></i>
                </span>
                <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                  <i
                    className={
                      this.state.activeStep > 4
                        ? "fa fa-check-circle"
                        : "fa fa-check-circle-o"
                    }
                    aria-hidden="true"
                    style={{
                      fontSize: 20,
                      color:
                        this.state.activeStep > 4
                          ? "#31c197"
                          : this.state.activeStep === 4
                          ? config.themeColor
                          : "#ccc",
                    }}
                  ></i>
                  <span
                    className="stepper_text"
                    style={{
                      color:
                        this.state.activeStep === 4
                          ? config.themeColor
                          : "#ccc",
                    }}
                  >
                    Preview and Submit
                  </span>
                </span>
              </div>

              <Snackbar
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                open={this.state.snackBarErrorOpen}
                onClose={() => this.setState({ snackBarErrorOpen: false })}
                style={{ width: "450px" }}
              >
                <Alert
                  elevation={6}
                  variant="filled"
                  onClose={() => this.setState({ snackBarErrorOpen: false })}
                  severity="error"
                  style={{ width: "100%" }}
                >
                  {this.state.errorMsg}
                </Alert>
              </Snackbar>
              <Snackbar
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                style={{ width: "450px" }}
                open={this.state.snackBarSuccessOpen}
                autoHideDuration={100000}
                onClose={() => this.setState({ snackBarSuccessOpen: false })}
              >
                <Alert
                  elevation={6}
                  variant="filled"
                  onClose={() => this.setState({ snackBarSuccessOpen: false })}
                  severity="success"
                  style={{ width: "100%" }}
                >
                  {this.state.successMessage}
                </Alert>
              </Snackbar>

              <div className="col-lg card" style={{ overflowY: "scroll" }}>
                {this.state.activeStep === 0 && (
                  <div className="section_block">
                    <div style={{ width: "70%" }}>
                      <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                        Add details to update barge
                      </h5>
                    </div>

                    <div className="row mb-0">
                      <div className="col-lg-6 p-0">
                        <div className="row mb-2">
                          <label className="form_label mb-0">
                            Barge Nomination{" "}
                            {this.state.purchase_type === "FOB Barge" && (
                              <span style={{ color: "red" }}>*</span>
                            )}
                          </label>
                          <TextField
                            name="bargeNomination"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            onChange={(e) => {
                              this.setState({
                                bargeNomination: e.target.value,
                                bargeNominationError: false,
                              });
                            }}
                            value={this.state.bargeNomination}
                            error={this.state.bargeNominationError}
                            placeholder="Barge Nomination"
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 pr-0">
                        <label className="form_label mb-0">
                          Purchase Contract
                        </label>
                        <Autocomplete
                          options={this.state.purchaseDataForMenu}
                          getOptionLabel={(option) => option.name}
                          onChange={(event, value) => {
                            if (value) {
                              this.setState(
                                {
                                  purchase_contract_id: value.value,
                                  purchase_contract_no: value.name,
                                  purchase_contract_value: {
                                    name: value.name,
                                    value: value.value,
                                  },
                                },
                                () => this.handlePurchaseContract(value)
                              );
                            } else {
                              this.setState({
                                purchase_contract_value: {
                                  name: "",
                                  value: "",
                                },
                                purchase_contract_id: "",
                                purchase_contract_no: "",
                                pc_qualities_id: "0",
                                procurement_quality_standard: "",
                                barge_quantity_error_msg: "",
                                barge_planned_quantity_error_msg: "",
                                barge_redfraft_quantity_error_msg: "",
                                purchase_type: "",
                                total_barge_quantity: "",
                                pc_total_quantity: "",

                                purchaseQualitySelection: null,
                                purchaseTypeSelection: null,
                              });
                            }
                          }}
                          // InputProps={{
                          //   style: {
                          //     fontFamily: 'Poppins',
                          //     fontSize: '14px'
                          //   }
                          // }}
                          size="small"
                          name="purchaseContract"
                          value={this.state.purchase_contract_value}
                          renderOption={(option) => (
                            <React.Fragment>
                              {option.name} ({option.vendor_name})
                            </React.Fragment>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Purchase Contract"
                              variant="outlined"
                              fullWidth
                              style={{ top: 8 }}
                            />
                          )}
                          sryle={{ display: "contents" }}
                        />
                      </div>
                    </div>

                    {this.state.purchase_contract_id && (
                      <div className="row mb-2">
                        <div className="col-lg-6 p-0">
                          <label className="form_label mb-1">
                            Purchase Quality
                          </label>
                          <div className="mt-0">{this.state.pc_quality}</div>
                        </div>
                        <div className="col-lg-3 pr-0">
                          <span className="form_label mb-0">
                            Purchase Quality Standard
                          </span>
                          <div className="mt-0">
                            {this.state.procurement_quality_standard}
                          </div>
                        </div>
                        <div className="col-lg-3 p-0">
                          <span className="form_label mb-0">Purchase Type</span>
                          <div className="mt-0">{this.state.purchase_type}</div>
                        </div>
                      </div>
                    )}

                    {this.state.purchase_type !== "FAS Anchorage" &&
                      this.state.purchase_type !== "FOB Mother Vessel" && (
                        <BargeAgreements
                          vendors={this.state.bargeVendors}
                          agreement_info={this.state.barge_agreement_info}
                          currencyList={this.state.currencyData}
                          callbackFn={(data) =>
                            this.bargeAgreementsHandler(data)
                          }
                        />
                        //                       <div className="row mb-2">
                        //                         <div className="col-lg-6 p-0">
                        //                           <label className="form_label mb-0">
                        //                             Barge Vendor
                        //                           </label>
                        //                           <Autocomplete
                        //                             options={this.state.bargeVendors}
                        //                             getOptionLabel={(option) => option.name}
                        //                             size="small"
                        //                             onChange={(event, value) => {
                        //                               value != null
                        //                                 ? this.setState({
                        //                                     barge_vendor: value.value,
                        //                                     barge_vendor_error: false,
                        //                                     bargeVendorNameValue: value,
                        //                                     vendorTypeError: false,
                        //                                   })
                        //                                 : this.setState({
                        //                                     barge_vendor: "",
                        //                                     vendorTypeError: false,
                        //                                   });
                        //                             }}
                        //                             name="barge_vendor"
                        //                             value={this.state.bargeVendorNameValue}
                        //                             error={this.state.barge_vendor_error}
                        //                             renderInput={(params) => (
                        //                               <TextField
                        //                                 {...params}
                        //                                 placeholder="Search Barge Vendor"
                        //                                 variant="outlined"
                        //                                 error={this.state.vendorTypeError}
                        //                                 fullWidth
                        //                                 style={{ top: 8 }}
                        //                               />
                        //                             )}
                        //                             style={{ display: "contents" }}
                        //                           />
                        //                         </div>
                        //                         <div className="col-lg-6 mb-2 pr-0">
                        //                           <label className="form_label mb-0">
                        //                             Barging Type
                        //                           </label>

                        //                           <RadioGroup
                        //                             aria-label="barging_type"
                        //                             name="barging_type"
                        //                             onChange={(e) => {
                        //                               this.setState({
                        //                                 barging_type: e.target.value,
                        //                               });
                        //                             }}
                        //                             style={{ flexDirection: "row" }}
                        //                           >
                        //                             <FormControlLabel
                        //                               value="Per MT"
                        //                               control={
                        //                                 <NewRadio
                        //                                   checked={
                        //                                     this.state.barging_type === "Per MT"
                        //                                   }
                        //                                 />
                        //                               }
                        //                               label="Per MT"
                        //                             />
                        //                             <FormControlLabel
                        //                               value="Lumpsum"
                        //                               control={
                        //                                 <NewRadio
                        //                                   checked={
                        //                                     this.state.barging_type === "Lumpsum"
                        //                                   }
                        //                                 />
                        //                               }
                        //                               label="Lumpsum"
                        //                             />
                        //                           </RadioGroup>
                        //                         </div>
                        //                         {this.state.barging_type === "Per MT" && (
                        //                           <div className="col-lg-6 mb-2 p-0">
                        //                             <label className="form_label mb-0">
                        //                               Barging Base Price per MT
                        //                             </label>
                        //                             <TextField
                        //                               placeholder="Base Price"
                        //                               // type="number"
                        //                               fullWidth
                        //                               onChange={(e) => {
                        //                                 const pr = /^\d*(\.\d{0,4})?$/;
                        //                                 if (
                        //                                   e.target.value === "" ||
                        //                                   pr.test(e.target.value)
                        //                                 ) {
                        //                                   let value = e.target.value;
                        //                                   this.setState({
                        //                                     barging_base_price: value,
                        //                                     supplierDeadFreightValue: (
                        //                                       Number(
                        //                                         this.state.supplierDeadFreightIncurred
                        //                                       ) * Number(value)
                        //                                     ).toFixed(2),
                        //                                     SDAMSAIIDeadFreightValue: (
                        //                                       Number(
                        //                                         this.state.supplierDeadFreightIncurred
                        //                                       ) * Number(value)
                        //                                     ).toFixed(2),
                        //                                   });
                        //                                 }
                        //                               }}
                        //                               value={this.state.barging_base_price}
                        //                               margin="dense"
                        //                               variant="outlined"
                        //                               InputProps={{
                        //                                 endAdornment:
                        //                                   config.company === "SRPL" ||
                        //                                   config.company === "PRPL" ? (
                        //                                     <InputAdornment
                        //                                       position="end"
                        //                                       style={{
                        //                                         marginRight: "0px",
                        //                                         marginTop: "-4px",
                        //                                         width: "50%",
                        //                                       }}
                        //                                     >
                        //                                       <TextField
                        //                                         name="barging_base_price_currency"
                        //                                         placeholder="Enter value Price"
                        //                                         variant="outlined"
                        //                                         margin="dense"
                        //                                         value={
                        //                                           this.state
                        //                                             .barging_base_price_currency
                        //                                         }
                        //                                         fullWidth
                        //                                         onChange={(e) => {
                        //                                           this.setState({
                        //                                             barging_base_price_currency:
                        //                                               e.target.value,
                        //                                           });
                        //                                         }}
                        //                                         select
                        //                                       >
                        //                                         {this.state.currencyData}
                        //                                       </TextField>
                        //                                     </InputAdornment>
                        //                                   ) : (
                        //                                     <InputAdornment
                        //                                       position="end"
                        //                                       className="adornment_background"
                        //                                     >
                        //                                       IDR
                        //                                     </InputAdornment>
                        //                                   ),
                        //                                 style: {
                        //                                   fontFamily: "Poppins",
                        //                                   fontSize: "14px",
                        //                                   padding: 0,
                        //                                 },
                        //                               }}
                        //                             />
                        //                           </div>
                        //                         )}
                        //                         {this.state.barging_type === "Lumpsum" && (
                        //                           <div className="col-lg-6 mb-2 p-0">
                        //                             <label className="form_label mb-0">
                        //                               Lumpsum Base Price
                        //                             </label>
                        //                             <TextField
                        //                               placeholder="Enter value"
                        //                               // type="number"
                        //                               fullWidth
                        //                               onChange={(e) => {
                        //                                 const pr = /^\d*(\.\d{0,4})?$/;
                        //                                 if (
                        //                                   e.target.value === "" ||
                        //                                   pr.test(e.target.value)
                        //                                 ) {
                        //                                   let value = e.target.value;
                        //                                   this.setState({
                        //                                     lumpsum_price: value,
                        //                                     supplierDeadFreightValue: (
                        //                                       Number(
                        //                                         this.state.supplierDeadFreightIncurred
                        //                                       ) * Number(value)
                        //                                     ).toFixed(2),
                        //                                     SDAMSAIIDeadFreightValue: (
                        //                                       Number(
                        //                                         this.state.supplierDeadFreightIncurred
                        //                                       ) * Number(value)
                        //                                     ).toFixed(2),
                        //                                   });
                        //                                 }
                        //                               }}
                        //                               value={this.state.lumpsum_price}
                        //                               margin="dense"
                        //                               variant="outlined"
                        //                               InputProps={{
                        //                                 endAdornment:
                        //                                   config.company === "SRPL" ||
                        //                                   config.company === "PRPL" ? (
                        //                                     <InputAdornment
                        //                                       position="end"
                        //                                       style={{
                        //                                         marginRight: "0px",
                        //                                         marginTop: "-4px",
                        //                                         width: "50%",
                        //                                       }}
                        //                                     >
                        //                                       <TextField
                        //                                         name="lumpsum_price_currency"
                        //                                         //placeholder="Base Price"
                        //                                         variant="outlined"
                        //                                         margin="dense"
                        //                                         value={
                        //                                           this.state.lumpsum_price_currency
                        //                                         }
                        //                                         fullWidth
                        //                                         onChange={(e) => {
                        //                                           this.setState({
                        //                                             lumpsum_price_currency:
                        //                                               e.target.value,
                        //                                           });
                        //                                         }}
                        //                                         select
                        //                                       >
                        //                                         {this.state.currencyData}
                        //                                       </TextField>
                        //                                     </InputAdornment>
                        //                                   ) : (
                        //                                     <InputAdornment
                        //                                       position="end"
                        //                                       className="adornment_background"
                        //                                     >
                        //                                       IDR
                        //                                     </InputAdornment>
                        //                                   ),
                        //                                 style: {
                        //                                   fontFamily: "Poppins",
                        //                                   fontSize: "14px",
                        //                                   padding: 0,
                        //                                 },
                        //                               }}
                        //                             />
                        //                           </div>
                        //                         )}

                        //                         {(this.state.barging_base_price ||
                        //                           this.state.lumpsum_price) && (
                        //                           <>
                        //                             <div className="col-lg-6 mb-2 pr-0">
                        //                               <label className="form_label mb-0">
                        //                                 VAT Applicability
                        //                               </label>

                        //                               <RadioGroup
                        //                                 aria-label="vat_applicability"
                        //                                 name="vat_applicability"
                        //                                 onChange={(e) => {
                        //                                   this.setState({
                        //                                     vat_applicability: e.target.value,
                        //                                   });
                        //                                 }}
                        //                                 style={{ flexDirection: "row" }}
                        //                               >
                        //                                 <FormControlLabel
                        //                                   value="Yes"
                        //                                   control={
                        //                                     <NewRadio
                        //                                       checked={
                        //                                         this.state.vat_applicability === "Yes"
                        //                                       }
                        //                                     />
                        //                                   }
                        //                                   label="Yes"
                        //                                 />
                        //                                 <FormControlLabel
                        //                                   value="No"
                        //                                   control={
                        //                                     <NewRadio
                        //                                       checked={
                        //                                         this.state.vat_applicability === "No"
                        //                                       }
                        //                                     />
                        //                                   }
                        //                                   label="No"
                        //                                 />
                        //                               </RadioGroup>
                        //                             </div>
                        //                             {/* {this.state.vat_applicability === "Yes" &&

                        //                             <div className="col-lg-6 mb-2 pr-0">
                        //                               <label className="form_label mb-0">VAT Consideration</label>

                        //                               <RadioGroup
                        //                                 aria-label="vat_incl_or_excl"
                        //                                 name="vat_incl_or_excl"
                        //                                 onChange={(e) => {
                        //                                   this.setState({
                        //                                     vat_incl_or_excl: e.target.value,
                        //                                   });
                        //                                 }}
                        //                                 style={{ flexDirection: "row" }}
                        //                               >
                        //                                 <FormControlLabel
                        //                                   value="Inclusive"
                        //                                   control={
                        //                                     <NewRadio
                        //                                       checked={
                        //                                         this.state.vat_incl_or_excl ===
                        //                                         "Inclusive"
                        //                                       }
                        //                                     />
                        //                                   }
                        //                                   label="Inclusive"
                        //                                 />
                        //                                 <FormControlLabel
                        //                                   value="Exclusive"
                        //                                   control={
                        //                                     <NewRadio
                        //                                       checked={
                        //                                         this.state.vat_incl_or_excl ===
                        //                                         "Exclusive"
                        //                                       }
                        //                                     />
                        //                                   }
                        //                                   label="Exclusive"
                        //                                 />
                        //                               </RadioGroup>
                        //                             </div>
                        // } */}
                        //                           </>
                        //                         )}
                        //                       </div>
                      )}

                    <div className="row mb-2">
                      <div className="col-lg-6 p-0">
                        <label className="form_label mb-0">
                          Barge Captain Phone No
                        </label>
                        <TextField
                          name="barge_captain_phone_no"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          onChange={(e) => {
                            this.setState({
                              barge_captain_phone_no: e.target.value,
                            });
                          }}
                          value={this.state.barge_captain_phone_no}
                          placeholder="Barge Captain Phone No"
                          inputProps={{ style: { textTransform: "uppercase" } }}
                        />
                      </div>
                      <div className="col-lg-6 pr-0">
                        <label className="form_label mb-0">
                          Barge Planned Quantity in MT
                        </label>
                        <TextField
                          placeholder="Barge Planned Quantity in MT"
                          type="number"
                          fullWidth
                          value={this.state.barge_planned_quantity_in_mt}
                          //error={this.state.barge_planned_quantity_in_mtError}
                          margin="dense"
                          variant="outlined"
                          onChange={(e) => {
                            if (
                              e.target.value === "" ||
                              res.test(e.target.value)
                            ) {
                              if (
                                this.state.purchase_contract_id &&
                                this.state.pc_qualities_id &&
                                this.state.pc_purchase_type_id &&
                                Number(
                                  this.state.pc_total_quantity
                                    ? this.state.pc_total_quantity
                                    : 0
                                ) <
                                  Number(e.target.value) +
                                    Number(
                                      this.state.total_barge_quantity
                                        ? this.state.total_barge_quantity
                                        : 0
                                    )
                              ) {
                                this.setState({
                                  barge_planned_quantity_error: true,
                                  barge_planned_quantity_error_msg:
                                    "Barge quantity is exceeding the purchase contract quantity with tolerance",
                                });
                              } else {
                                this.setState({
                                  barge_planned_quantity_error: false,
                                  barge_planned_quantity_error_msg: "",
                                });
                              }
                              this.setState({
                                barge_planned_quantity_in_mt: e.target.value,
                              });
                            }
                          }}
                          error={this.state.barge_planned_quantity_error}
                        />
                        {this.state.barge_planned_quantity_error_msg ? (
                          <p className="text-danger" style={{ fontSize: 10 }}>
                            {this.state.barge_planned_quantity_error_msg}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="row mb-2">
                      <div className="col-lg-6 p-0">
                        <label className="form_label mb-0">
                          Barge Quantity in MT
                        </label>
                        <TextField
                          // label="Barge Quantity in MT"
                          placeholder="Barge Quantity in MT"
                          // type="number"
                          fullWidth
                          onChange={(e) => {
                            if (
                              e.target.value === "" ||
                              res.test(e.target.value)
                            ) {
                              let value = e.target.value;
                              if (
                                this.state.purchase_contract_id &&
                                this.state.pc_qualities_id &&
                                this.state.pc_purchase_type_id &&
                                Number(
                                  this.state.pc_total_quantity
                                    ? this.state.pc_total_quantity
                                    : 0
                                ) <
                                  Number(e.target.value) +
                                    Number(
                                      this.state.total_barge_quantity
                                        ? this.state.total_barge_quantity
                                        : 0
                                    )
                              ) {
                                this.setState({
                                  barge_quantity_error: true,
                                  barge_quantity_error_msg:
                                    "Barge quantity is exceeding the purchase contract quantity with tolerance",
                                });
                              } else {
                                this.setState({
                                  barge_quantity_error: false,
                                  barge_quantity_error_msg: "",
                                });
                              }
                              let diffValue = (
                                Number(this.state.deadFreight) - Number(value)
                              ).toFixed(3);
                              this.setState({
                                bargeQuantityInMT: value,
                                supplierDeadFreightIncurred:
                                  this.state.bargeQuantityInMT === ""
                                    ? ""
                                    : this.state.deadFreight !== ""
                                    ? (
                                        Number(this.state.deadFreight) -
                                        Number(value)
                                      ).toFixed(3)
                                    : 0 - value,
                                supplierDeadFreightValue:
                                  this.state.bargeQuantityInMT === ""
                                    ? ""
                                    : (
                                        Number(diffValue) *
                                        Number(
                                          this.state.barging_type === "Per MT"
                                            ? this.state.barging_base_price
                                            : this.state.lumpsum_price
                                        )
                                      ).toFixed(2),
                                SDAMSAIIDeadFreightIncurred:
                                  this.state.deadFreight !== ""
                                    ? (
                                        Number(this.state.deadFreight) -
                                        Number(value)
                                      ).toFixed(3)
                                    : 0 - value,
                                SDAMSAIIDeadFreightValue: (
                                  Number(diffValue) *
                                  Number(this.state.barging_base_price)
                                ).toFixed(2),
                                quantityDifference:
                                  this.state.redraftQuantity !== ""
                                    ? (
                                        Number(this.state.redraftQuantity) -
                                        Number(value)
                                      ).toFixed(3)
                                    : "",
                                bargeQuantityInMTErrorStatus: false,
                              });
                            }
                          }}
                          value={this.state.bargeQuantityInMT}
                          error={this.state.barge_quantity_error}
                          margin="dense"
                          variant="outlined"
                        />
                        {this.state.barge_quantity_error_msg ? (
                          <p className="text-danger" style={{ fontSize: 10 }}>
                            {this.state.barge_quantity_error_msg}
                          </p>
                        ) : null}
                      </div>

                      <div className="col-lg-6 pr-0">
                        <label className="form_label mb-0">
                          Return Cargo Quantity in MT
                        </label>
                        <TextField
                          placeholder="Enter Value"
                          type="number"
                          fullWidth
                          value={this.state.return_cargo_quantity_in_mt}
                          margin="dense"
                          variant="outlined"
                          onChange={(e) => {
                            if (
                              e.target.value === "" ||
                              res.test(e.target.value)
                            ) {
                              if (
                                this.state.purchase_contract_id &&
                                this.state.pc_qualities_id &&
                                this.state.pc_purchase_type_id &&
                                Number(
                                  this.state.pc_total_quantity
                                    ? this.state.pc_total_quantity
                                    : 0
                                ) <
                                  Number(e.target.value) +
                                    Number(
                                      this.state.total_barge_quantity
                                        ? this.state.total_barge_quantity
                                        : 0
                                    )
                              ) {
                                this.setState({
                                  cargo_quantity_error: true,
                                  cargo_quantity_error_msg:
                                    "Barge quantity is exceeding the purchase contract quantity with tolerance",
                                });
                              } else {
                                this.setState({
                                  cargo_quantity_error: false,
                                  cargo_quantity_error_msg: "",
                                });
                              }
                              this.setState({
                                return_cargo_quantity_in_mt: e.target.value,
                              });
                            }
                          }}
                          error={this.state.cargo_quantity_error}
                        />
                        {this.state.cargo_quantity_error_msg ? (
                          <p className="text-danger" style={{ fontSize: 10 }}>
                            {this.state.cargo_quantity_error_msg}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="row mb-2">
                      <div className="col-lg-6 p-0">
                        <label className="form_label mb-0">Loading Jetty</label>
                        <TextField
                          name="loadingJetty"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          onChange={(e) => {
                            this.setState({
                              loadingJetty: e.target.value,
                            });
                          }}
                          value={this.state.loadingJetty}
                          placeholder="Loading Jetty"
                          inputProps={{ style: { textTransform: "uppercase" } }}
                        />
                      </div>
                      <div className="col-lg-6 pr-0">
                        <label className="form_label mb-0">
                          Supplier Barge Window
                        </label>
                        <TextField
                          name="supplier_barge_window"
                          margin="dense"
                          variant="outlined"
                          value={this.state.supplier_barge_window}
                          fullWidth
                          placeholder="Supplier Barge Window"
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          onChange={(e) => {
                            this.setState({
                              supplier_barge_window: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>

                    <div className="row mb-2">
                      <div className="col-lg-6 p-0">
                        <label className="form_label mb-0">
                          Buyer Barge Window
                        </label>
                        <TextField
                          name="buyer_barge_window"
                          margin="dense"
                          variant="outlined"
                          value={this.state.buyer_barge_window}
                          fullWidth
                          placeholder="Buyer Barge Window"
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          onChange={(e) => {
                            this.setState({
                              buyer_barge_window: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>

                    {this.state.purchase_type === "FOB Barge" && (
                      <div>
                        <div style={{ width: "70%" }}>
                          <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                            Dead Freight Values
                          </h5>
                        </div>

                        <div className="row mb-2">
                          <div className="col-lg-6 p-0">
                            <label className="form_label mb-0">
                              Dead Freight
                            </label>
                            <TextField
                              // label="Dead Freight"
                              placeholder="Dead Freight"
                              // type="number"
                              fullWidth
                              onChange={(e) => {
                                if (
                                  e.target.value === "" ||
                                  res.test(e.target.value)
                                ) {
                                  let value = e.target.value;
                                  let diffValue = (
                                    Number(value) -
                                    Number(this.state.bargeQuantityInMT)
                                  ).toFixed(2);
                                  this.setState({
                                    deadFreight: value,
                                    supplierDeadFreightIncurred:
                                      this.state.bargeQuantityInMT === ""
                                        ? ""
                                        : this.state.bargeQuantityInMT !== ""
                                        ? (
                                            Number(value) -
                                            Number(this.state.bargeQuantityInMT)
                                          ).toFixed(3)
                                        : value - 0,
                                    supplierDeadFreightValue:
                                      this.state.bargeQuantityInMT === ""
                                        ? ""
                                        : (
                                            Number(diffValue) *
                                            Number(
                                              this.state.barging_type ===
                                                "Per MT"
                                                ? this.state.barging_base_price
                                                : this.state.lumpsum_price
                                            )
                                          ).toFixed(2),
                                    SDAMSAIIDeadFreightIncurred:
                                      this.state.bargeQuantityInMT === ""
                                        ? ""
                                        : this.state.bargeQuantityInMT !== ""
                                        ? (
                                            Number(value) -
                                            Number(this.state.bargeQuantityInMT)
                                          ).toFixed(3)
                                        : value - 0,
                                    SDAMSAIIDeadFreightValue:
                                      this.state.bargeQuantityInMT === ""
                                        ? ""
                                        : (
                                            Number(diffValue) *
                                            Number(
                                              this.state.barging_base_price
                                            )
                                          ).toFixed(2),
                                  });
                                }
                              }}
                              value={this.state.deadFreight}
                              margin="dense"
                              variant="outlined"
                            />
                          </div>

                          {this.state.supplierDeadFreightIncurred > 0 &&
                            this.state.bargeQuantityInMT !== "" && (
                              <div className="col-lg-6 pr-0">
                                <span className="form_label">
                                  Supplier Dead Freight Incurred
                                </span>
                                <div className="mt-3">
                                  {" "}
                                  {this.state.supplierDeadFreightIncurred
                                    ? this.toLocaleString(
                                        this.state.supplierDeadFreightIncurred
                                      )
                                    : null}
                                </div>
                              </div>
                            )}

                          {this.state.supplierDeadFreightValue > 0 &&
                            this.state.bargeQuantityInMT !== "" && (
                              <div className="col-lg-6 p-0 mt-2 mb-2">
                                <span className="form_label">
                                  Supplier Dead Freight Value
                                </span>
                                <div className="mt-2">
                                  {this.state.supplierDeadFreightValue
                                    ? this.toLocaleString(
                                        this.state.supplierDeadFreightValue
                                      )
                                    : null}
                                </div>
                              </div>
                            )}

                          {this.state.SDAMSAIIDeadFreightIncurred > 0 &&
                            this.state.bargeQuantityInMT !== "" && (
                              <div className="col-lg-6 pr-0 mt-2 mb-2">
                                <span className="form_label">
                                  {config.company} Dead Freight Incurred
                                </span>
                                <div className="mt-2">
                                  {this.state.SDAMSAIIDeadFreightIncurred
                                    ? this.toLocaleString(
                                        this.state.SDAMSAIIDeadFreightIncurred
                                      )
                                    : null}
                                </div>
                              </div>
                            )}
                          {this.state.SDAMSAIIDeadFreightValue > 0 &&
                            this.state.bargeQuantityInMT !== "" && (
                              <div className="col-lg-6 p-0 mb-2">
                                <span className="form_label">
                                  {config.company} Dead Freight Value
                                </span>
                                <div className="mt-2">
                                  {this.state.SDAMSAIIDeadFreightValue
                                    ? this.toLocaleString(
                                        this.state.SDAMSAIIDeadFreightValue
                                      )
                                    : null}
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {this.state.activeStep === 1 && (
                  <div className="section_block">
                    <div style={{ width: "70%" }}>
                      <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                        Barge Timings
                      </h5>
                    </div>
                    <div className="row mb-2">
                      <div className="col-lg-6 p-0">
                        <label className="form_label mb-0">Loading Date</label>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DateTimePicker
                            autoOk
                            hideTabs
                            inputVariant="outlined"
                            variant="inline"
                            margin="dense"
                            fullWidth
                            ampm={false}
                            format="dd/MM/yyyy HH:mm"
                            value={this.state.loadingDate}
                            onChange={(date) => {
                              this.setState({
                                loadingDate: date,
                              });
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <IconButton>
                                    <EventIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                              className: "pl-0",
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                      <div className="col-lg-6 pr-0">
                        <label className="form_label mb-0">
                          Loading Completion Time
                        </label>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DateTimePicker
                            autoOk
                            hideTabs
                            inputVariant="outlined"
                            variant="inline"
                            margin="dense"
                            fullWidth
                            ampm={false}
                            format="dd/MM/yyyy HH:mm"
                            value={this.state.completionTime}
                            onChange={(date) => {
                              this.setState({
                                completionTime: date,
                              });
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <IconButton>
                                    <EventIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                              className: "pl-0",
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </div>

                    <div className="row mb-2">
                      <div className="col-lg-6 p-0">
                        <label className="form_label mb-0">NOR Anchorage</label>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DateTimePicker
                            autoOk
                            hideTabs
                            inputVariant="outlined"
                            variant="inline"
                            margin="dense"
                            fullWidth
                            ampm={false}
                            format="dd/MM/yyyy HH:mm"
                            value={this.state.NORAnchorageDate}
                            onChange={(date) =>
                              this.NORAnchorageDateHandler(date)
                            }
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <IconButton>
                                    <EventIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                              className: "pl-0",
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </div>
                  </div>
                )}

                {this.state.activeStep === 2 && (
                  <div className="section_block">
                    <div style={{ width: "70%" }}>
                      <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                        Surveyor
                      </h5>
                    </div>
                    <div className="row">
                      <div className="col-lg-4 p-0">
                        <label className="form_label mb-2">
                          Supplier Surveyor
                        </label>
                        <Autocomplete
                          options={this.state.surveyorsData}
                          getOptionLabel={(option) => option.name}
                          size="small"
                          onChange={(event, value) => {
                            value != null
                              ? this.setState({
                                  supplierSurveyorName: value.value,
                                  supplierSurveyorNameValue: value,
                                  supplier_surveyor_name: value.name,
                                })
                              : this.setState({ supplierSurveyorName: "" });
                          }}
                          // name='supplierSurveyorName'
                          value={this.state.supplierSurveyorNameValue}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Search Surveyor"
                              variant="outlined"
                              fullWidth
                            />
                          )}
                        />
                      </div>
                      <div className="col-lg-4 pr-0">
                        <label className="form_label mb-2">
                          {config.company} Surveyor
                        </label>
                        <Autocomplete
                          options={this.state.surveyorsData}
                          getOptionLabel={(option) => option.name}
                          size="small"
                          onChange={(event, value) => {
                            value != null
                              ? this.setState({
                                  saiiSurveyorName: value.value,
                                  saiiSurveyorNameValue: value,
                                  internal_surveyor_name: value.name,
                                })
                              : this.setState({ saiiSurveyorName: "" });
                          }}
                          name="saiiSurveyorName"
                          value={this.state.saiiSurveyorNameValue}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Search Surveyor"
                              variant="outlined"
                              fullWidth
                            />
                          )}
                        />
                      </div>

                      <div className="col-lg-4 pr-0">
                        <label className="form_label mb-2">
                          Witness Surveyor
                        </label>
                        <Autocomplete
                          options={this.state.surveyorsData}
                          getOptionLabel={(option) => option.name}
                          size="small"
                          onChange={(event, value) => {
                            value != null
                              ? this.setState({
                                  witnessSurveyorName: value.value,
                                  witnessSurveyorNameValue: value,
                                  joint_surveyor_name: value.name,
                                })
                              : this.setState({ witnessSurveyorName: "" });
                          }}
                          name="witnessSurveyorName"
                          value={this.state.witnessSurveyorNameValue}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Search Surveyor"
                              variant="outlined"
                              fullWidth
                            />
                          )}
                        />
                      </div>

                      <div className="col-lg-4 p-0">
                        <label className="form_label mb-0">
                          Supplier Surveyor Analysis type
                        </label>
                        <TextField
                          name="supplierSurveyorAnalysisType"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          value={this.state.supplierSurveyorAnalysisType}
                          placeholder="Supplier Surveyor Analysis type"
                          onChange={(e) => {
                            this.setState({
                              supplierSurveyorAnalysisType: e.target.value,
                            });
                          }}
                          select
                        >
                          <MenuItem value="0">Please Select</MenuItem>
                          <MenuItem value="ASTM">ASTM</MenuItem>
                          <MenuItem value="ISO">ISO</MenuItem>
                        </TextField>
                      </div>

                      <div className="col-lg-4 pr-0">
                        <label className="form_label mb-0">
                          Surveyor Analysis Type
                        </label>
                        <TextField
                          name="saiiSurveyorAnalysisType"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          select
                          value={this.state.saiiSurveyorAnalysisType}
                          onChange={(e) => {
                            this.setState({
                              saiiSurveyorAnalysisType: e.target.value,
                            });
                          }}
                          placeholder="SAII Surveyor Analysis Type"
                        >
                          <MenuItem value="0">Please Select</MenuItem>
                          <MenuItem value="ASTM">ASTM</MenuItem>
                          <MenuItem value="ISO">ISO</MenuItem>
                        </TextField>
                      </div>
                      <div className="col-lg-4 pr-0">
                        <label className="form_label mb-0">
                          Witness Surveyor Analysis Type
                        </label>
                        <TextField
                          name="witnessSurveyorAnalysisType"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          value={this.state.witnessSurveyorAnalysisType}
                          onChange={(e) => {
                            this.setState({
                              witnessSurveyorAnalysisType: e.target.value,
                            });
                          }}
                          select
                          placeholder="Witness Surveyor Analysis Type"
                        >
                          <MenuItem value="0">Please Select</MenuItem>
                          <MenuItem value="ASTM">ASTM</MenuItem>
                          <MenuItem value="ISO">ISO</MenuItem>
                        </TextField>
                      </div>
                    </div>
                  </div>
                )}

                {this.state.activeStep === 3 && (
                  <div className="section_block">
                    <div style={{ width: "70%" }}>
                      <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                        Others
                      </h5>
                    </div>

                    <div className="row mb-2">
                      <div className="col-lg-4 p-0">
                        <label className="form_label mb-0">
                          Chemical Spray
                        </label>
                        <RadioGroup
                          aria-label="gender"
                          name="ChemicalSpray"
                          onChange={(e) => {
                            this.setState({
                              chemicalSpray: e.target.value,
                            });
                          }}
                          style={{ flexDirection: "row" }}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={
                              <NewRadio
                                checked={this.state.chemicalSpray === "Yes"}
                              />
                            }
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={
                              <NewRadio
                                checked={this.state.chemicalSpray === "No"}
                              />
                            }
                            label="No"
                          />
                        </RadioGroup>
                      </div>

                      {this.state.chemicalSpray === "Yes" && (
                        <div className="col-lg-4 pr-0">
                          <div className="row mb-2">
                            <label className="form_label mb-0">
                              Chemical Spray Value{" "}
                              <span style={{ color: "red" }}> *</span>
                            </label>
                            <TextField
                              name="price"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              placeholder="Price in IDR *"
                              value={this.state.price}
                              error={this.state.chemical_spray_error}
                              onChange={(e) => {
                                const pr = /^\d*(\.\d{0,4})?$/;
                                if (
                                  e.target.value === "" ||
                                  pr.test(e.target.value)
                                ) {
                                  let value = e.target.value;
                                  this.setState({
                                    price: value,
                                    chemical_spray_error: false,
                                  });
                                }
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="row mb-2">
                      <div className="col-lg-10 p-0">
                        <label className="form_label mb-0">Remarks</label>
                        <TextField
                          name="remarks"
                          margin="dense"
                          variant="outlined"
                          placeholder="Remarks"
                          multiline
                          rows={3}
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          value={this.state.remarks}
                          onChange={(e) =>
                            this.setState({ remarks: e.target.value })
                          }
                          fullWidth
                        />
                      </div>
                    </div>

                    {(this.state.features.includes("375") ||
                      this.state.features.includes("97")) && (
                      <FileUpload
                        files={this.state.files}
                        feature_name="Barges"
                        callbackFn={(data) => this.setState({ files: data })}
                      />
                    )}
                  </div>
                )}

                {this.state.activeStep === 4 && (
                  <div className="section_block">
                    <div className="card p-0 border mb-3">
                      <div className="card-header section_header">
                        <h5> Barge Details </h5>
                      </div>
                      <div className="card-body">
                        <div className="row col-lg-12 p-0 pt-2">
                          <div className="col-lg-4">
                            <label className="contract_display_header_label">
                              Barge Nomination
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ textTransform: "upperCase" }}
                            >
                              {this.state.bargeNomination
                                ? this.state.bargeNomination
                                : "-"}
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <label className="contract_display_header_label">
                              {" "}
                              Purchase Contract{" "}
                            </label>
                            {/* {this.state.purchase_contract_id &&
                              <a href={'/view-purchase-contract/' + btoa(this.state.purchase_contract_id)} rel="noopener noreferrer" role="button" style={{ color: config.themeColor }}>{this.state.purchase_contract_no}</a>
                            } */}
                            <div
                              className="contract_display_header_value"
                              style={{ textTransform: "upperCase" }}
                            >
                              {this.state.purchase_contract_no
                                ? this.state.purchase_contract_no
                                : "-"}
                            </div>

                            {/* <label className="contract_display_header_value">{this.state.purchase_contract_no}</label> */}
                          </div>
                          <div className="col-lg-4">
                            <label className="contract_display_header_label">
                              Purchase Quality{" "}
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ textTransform: "upperCase" }}
                            >
                              {this.state.pc_quality
                                ? this.state.pc_quality
                                : "-"}
                            </div>
                          </div>
                        </div>

                        <div className="row col-lg-12 p-0 pt-2">
                          <div className="col-lg-4 mt-1">
                            <label className="contract_display_header_label">
                              Purchase Quality Standard
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ textTransform: "upperCase" }}
                            >
                              {this.state.procurement_quality_standard
                                ? this.state.procurement_quality_standard
                                : "-"}
                            </div>
                          </div>
                          <div className="col-lg-4 mt-1">
                            <label className="contract_display_header_label">
                              Purchase Type
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ textTransform: "upperCase" }}
                            >
                              {this.state.purchase_type
                                ? this.state.purchase_type
                                : "-"}
                            </div>
                          </div>
                          <div className="col-lg-4 mt-1">
                            <label className="contract_display_header_label">
                              {" "}
                              Barge Captain Phone No{" "}
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ textTransform: "upperCase" }}
                            >
                              {this.state.barge_captain_phone_no
                                ? this.state.barge_captain_phone_no
                                : "-"}
                            </div>
                          </div>
                        </div>
                        {this.state.purchase_type !== "FAS Anchorage" &&
                          this.state.purchase_type !== "FOB Mother Vessel" && (
                            <div className="row col-lg-12 p-0 pt-2">
                              <div className="col-lg-4 mt-1">
                                <label className="contract_display_header_label">
                                  {" "}
                                  Barge Vendor
                                </label>
                                {this.state.barge_vendor ? (
                                  <div
                                    className="contract_display_header_value"
                                    style={{ textTransform: "upperCase" }}
                                  >
                                    {this.state.barge_vendor
                                      ? this.getVendorvalue(
                                          this.state.barge_vendor
                                        )
                                      : "-"}
                                  </div>
                                ) : (
                                  "-"
                                )}
                              </div>
                              <div className="col-lg-4 mt-1">
                                <label className="contract_display_header_label">
                                  Barging Type
                                </label>
                                <div className="contract_display_header_value">
                                  {this.state.barging_type}
                                </div>
                              </div>
                              {this.state.barging_type === "Per MT" && (
                                <div className="col-lg-4 mt-1">
                                  <label className="contract_display_header_label">
                                    Base Price{" "}
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ textTransform: "upperCase" }}
                                  >
                                    {this.state.barging_base_price
                                      ? this.toLocaleString(
                                          this.state.barging_base_price
                                        )
                                      : "-"}{" "}
                                    {this.state.barging_base_price_currency !==
                                    "0"
                                      ? this.state.barging_base_price_currency
                                      : ""}
                                  </div>
                                </div>
                              )}
                              {this.state.barging_type === "Lumpsum" && (
                                <div className="col-lg-4 mt-1">
                                  <label className="contract_display_header_label">
                                    Lumpsum Base Price
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ textTransform: "upperCase" }}
                                  >
                                    {this.state.lumpsum_price
                                      ? this.toLocaleString(
                                          this.state.lumpsum_price
                                        )
                                      : "-"}{" "}
                                    {this.state.lumpsum_price_currency
                                      ? this.state.lumpsum_price_currency
                                      : ""}
                                  </div>
                                </div>
                              )}
                            </div>
                          )}

                        <div className="row col-lg-12 p-0 pt-2">
                          <div className="col-lg-4 mt-1">
                            <label className="contract_display_header_label">
                              {" "}
                              Barge Planned Quantity in MT{" "}
                            </label>
                            <div className="contract_display_header_value">
                              {this.state.barge_planned_quantity_in_mt
                                ? this.toLocaleString(
                                    this.state.barge_planned_quantity_in_mt
                                  )
                                : "-"}
                            </div>
                          </div>
                          <div className="col-lg-4 mt-1">
                            <label className="contract_display_header_label">
                              {" "}
                              Barge Quantity in MT{" "}
                            </label>
                            <div className="contract_display_header_value">
                              {this.state.bargeQuantityInMT
                                ? this.toLocaleString(
                                    this.state.bargeQuantityInMT
                                  )
                                : "-"}
                            </div>
                          </div>
                          <div className="col-lg-4 mt-1">
                            <label className="contract_display_header_label">
                              Return Cargo Quantity in MT{" "}
                            </label>
                            <div className="contract_display_header_value">
                              {this.state.return_cargo_quantity_in_mt
                                ? this.toLocaleString(
                                    this.state.return_cargo_quantity_in_mt
                                  )
                                : "-"}
                            </div>
                          </div>
                          <div className="col-lg-4 mt-1">
                            <label className="contract_display_header_label">
                              {" "}
                              Loading Jetty
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ textTransform: "upperCase" }}
                            >
                              {this.state.loadingJetty
                                ? this.state.loadingJetty
                                : "-"}
                            </div>
                          </div>
                          <div className="col-lg-4 mt-1">
                            <label className="contract_display_header_label">
                              Supplier Barge Window{" "}
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ textTransform: "upperCase" }}
                            >
                              {this.state.supplier_barge_window
                                ? this.state.supplier_barge_window
                                : "-"}
                            </div>
                          </div>
                          <div className="col-lg-4 mt-1">
                            <label className="contract_display_header_label">
                              {" "}
                              Buyer Barge Window
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ textTransform: "upperCase" }}
                            >
                              {this.state.buyer_barge_window
                                ? this.state.buyer_barge_window
                                : "-"}
                            </div>
                          </div>
                        </div>

                        {this.state.purchase_type === "FOB Barge" && (
                          <div className="row col-lg-12 p-0 pt-2">
                            <div style={{ width: "70%" }}>
                              <h5
                                style={{
                                  padding: "10px 2px",
                                  margin: "0px",
                                  paddingLeft: "16px",
                                }}
                              >
                                Dead Freight Values
                              </h5>
                            </div>
                            <div className="col-lg-4">
                              <label className="contract_display_header_label">
                                {" "}
                                Dead Freight{" "}
                              </label>
                              <div
                                className="contract_display_header_value"
                                style={{ textTransform: "upperCase" }}
                              >
                                {this.state.deadFreight
                                  ? this.toLocaleString(this.state.deadFreight)
                                  : "-"}
                              </div>
                            </div>
                            {this.state.supplierDeadFreightIncurred > 0 &&
                              this.state.bargeQuantityInMT !== "" && (
                                <div className="col-lg-4">
                                  <label className="contract_display_header_label">
                                    Supplier Dead Freight Incurred
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ textTransform: "upperCase" }}
                                  >
                                    {this.state.supplierDeadFreightIncurred
                                      ? this.toLocaleString(
                                          this.state.supplierDeadFreightIncurred
                                        )
                                      : "-"}
                                  </div>
                                </div>
                              )}
                            {this.state.supplierDeadFreightValue > 0 &&
                              this.state.bargeQuantityInMT !== "" && (
                                <div className="col-lg-4">
                                  <label className="contract_display_header_label">
                                    Supplier Dead Freight Value
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ textTransform: "upperCase" }}
                                  >
                                    {this.state.supplierDeadFreightValue
                                      ? this.toLocaleString(
                                          this.state.supplierDeadFreightValue
                                        )
                                      : "-"}
                                  </div>
                                </div>
                              )}
                            {this.state.SDAMSAIIDeadFreightIncurred > 0 &&
                              this.state.bargeQuantityInMT !== "" && (
                                <div className="col-lg-4 mt-1">
                                  <label className="contract_display_header_label">
                                    {config.company} Dead Freight Incurred
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ textTransform: "upperCase" }}
                                  >
                                    {this.state.SDAMSAIIDeadFreightIncurred
                                      ? this.toLocaleString(
                                          this.state.SDAMSAIIDeadFreightIncurred
                                        )
                                      : "-"}
                                  </div>
                                </div>
                              )}
                            {this.state.SDAMSAIIDeadFreightValue > 0 &&
                              this.state.bargeQuantityInMT !== "" && (
                                <div className="col-lg-4 mt-1">
                                  <label className="contract_display_header_label">
                                    {config.company} Dead Freight Value
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ textTransform: "upperCase" }}
                                  >
                                    {this.state.SDAMSAIIDeadFreightValue
                                      ? this.toLocaleString(
                                          this.state.SDAMSAIIDeadFreightValue
                                        )
                                      : "-"}
                                  </div>
                                </div>
                              )}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="card p-0 border mb-3">
                      <div className="card-header section_header">
                        <h5>Barge Timing</h5>
                      </div>
                      <div className="card-body">
                        <div className="row col-lg-12p-0 pt-2">
                          <div className="col-lg-4">
                            <label className="contract_display_header_label">
                              Loading Date
                            </label>
                            <div className="contract_display_header_value">
                              {localDateFormate(
                                dateFormateToDB(this.state.loadingDate)
                              )}
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <label className="contract_display_header_label">
                              Loading Completion Time
                            </label>
                            <div className="contract_display_header_value">
                              {localDateFormate(
                                dateFormateToDB(this.state.completionTime)
                              )}
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <label className="contract_display_header_label">
                              NOR Anchorage
                            </label>
                            <div className="contract_display_header_value">
                              {localDateFormate(
                                dateFormateToDB(this.state.NORAnchorageDate)
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card p-0 border mb-3">
                      <div className="card-header section_header">
                        <h5>Surveyor Details</h5>
                      </div>
                      <div className="card-body">
                        <div className="row col-lg-12 p-0 pt-2">
                          <div className="col-lg-4">
                            <label className="contract_display_header_label">
                              Supplier Surveyor
                            </label>
                            <div className="contract_display_header_value">
                              {this.state.supplier_surveyor_name
                                ? this.state.supplier_surveyor_name
                                : "-"}
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <label className="contract_display_header_label">
                              {config.company} Surveyor
                            </label>
                            <div className="contract_display_header_value">
                              {this.state.internal_surveyor_name
                                ? this.state.internal_surveyor_name
                                : "-"}
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <label className="contract_display_header_label">
                              Witness Surveyor
                            </label>
                            <div className="contract_display_header_value">
                              {this.state.joint_surveyor_name
                                ? this.state.joint_surveyor_name
                                : "-"}
                            </div>
                          </div>
                        </div>

                        <div className="row col-lg-12 p-0 pt-2">
                          <div className="col-lg-4 mt-1">
                            <label className="contract_display_header_label">
                              Supplier Surveyor Analysis type
                            </label>
                            <div className="contract_display_header_value">
                              {this.state.supplierSurveyorAnalysisType === "0"
                                ? "-"
                                : this.state.supplierSurveyorAnalysisType}
                            </div>
                          </div>
                          <div className="col-lg-4 mt-1">
                            <label className="contract_display_header_label">
                              {config.company} Surveyor Analysis Type
                            </label>
                            <div className="contract_display_header_value">
                              {this.state.saiiSurveyorAnalysisType === "0"
                                ? "-"
                                : this.state.saiiSurveyorAnalysisType}
                            </div>
                          </div>
                          <div className="col-lg-4 mt-1">
                            <label className="contract_display_header_label">
                              Witness Surveyor Analysis Type
                            </label>
                            <div className="contract_display_header_value">
                              {this.state.witnessSurveyorAnalysisType === "0"
                                ? "-"
                                : this.state.witnessSurveyorAnalysisType}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card p-0 border mb-3">
                      <div className="card-header section_header">
                        <h5>Others</h5>
                      </div>
                      <div className="card-body">
                        <div className="row col-lg-12 p-0 pt-2">
                          <div className="col-lg-4">
                            <label className="contract_display_header_label">
                              Chemical Spray
                            </label>
                            <div className="contract_display_header_value">
                              {this.state.chemicalSpray}
                            </div>
                          </div>
                          {this.state.chemicalSpray === "Yes" && (
                            <div className="col-lg-4">
                              <label className="contract_display_header_label">
                                Chemical Spray Value
                              </label>
                              <div className="contract_display_header_value">
                                {this.state.price
                                  ? this.toLocaleString(this.state.price) +
                                    " IDR"
                                  : "-"}{" "}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="row col-lg-12 p-0 pt-2">
                          <div className="col-lg-8 mt-1">
                            <label className="contract_display_header_label">
                              Remarks
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{
                                whiteSpace: "pre-line",
                                textTransform: "upperCase",
                              }}
                            >
                              {this.state.remarks ? this.state.remarks : "-"}
                            </div>
                          </div>
                        </div>
                        {(this.state.features.includes("326") ||
                          this.state.features.includes("97")) && (
                          <div>
                            <span
                              className="col-lg-12 contract_display_header_value pl-0"
                              style={{ fontWeight: "bold" }}
                            >
                              Selected Files
                            </span>
                            {this.state.files.map((e, index) => (
                              <div className="row mt-2">
                                {e.file_path ? (
                                  <div
                                    className="row col-lg-12 mb-3"
                                    key={index}
                                  >
                                    <div className="col-lg-10 p-0">
                                      <a
                                        href={
                                          config.apiDomain + "/" + e.file_path
                                        }
                                        role="button"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ color: config.themeColor }}
                                      >
                                        {e.file_description}
                                      </a>
                                    </div>
                                  </div>
                                ) : (
                                  <div
                                    className="row col-lg-12 mb-3"
                                    key={index}
                                  >
                                    <div className="col-lg-4 p-0">
                                      <label className="contract_display_header_label">
                                        File Description
                                      </label>
                                      <div
                                        className="contract_display_header_value"
                                        style={{ textTransform: "uppercase" }}
                                      >
                                        {e.file_description
                                          ? e.file_description
                                          : "-"}
                                      </div>
                                    </div>
                                    <div className="col-lg-6 pr-0">
                                      <label className="contract_display_header_label">
                                        Selected file
                                      </label>
                                      <div className="contract_display_header_value">
                                        {e.file_name ? e.file_name : "-"}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="row bottom_buttons_section">
                <button
                  type="button"
                  className="btn previous_button"
                  name="submit"
                  onClick={
                    this.state.activeStep === 0
                      ? () =>
                          (window.location.href =
                            "/view-barge/" + btoa(bargeID))
                      : this.previousStepHandler
                  }
                >
                  {this.state.activeStep === 0 ? "CANCEL" : "PREVIOUS"}
                </button>
                <button
                  type="button"
                  className="btn next_button"
                  name="Back"
                  onClick={
                    this.state.activeStep === 4
                      ? this.submitHandler
                      : this.nextStepHandler
                  }
                >
                  {this.state.activeStep === 4 ? "SUBMIT" : "NEXT STEP"}
                </button>
              </div>
            </form>
          )}
        </div>

        <div className="modal fade" id="myModal" role="dialog">
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
            <div className="modal-content">
              <div className="modal-header bg-light">
                <h4
                  className="modal-title"
                  style={{ border: "none", padding: 0 }}
                >
                  Quality Selection
                </h4>
                {/* <button type="button" className="close" data-dismiss="modal">&times;</button> */}
              </div>
              <div className="modal-body">
                <div className="card">
                  <label className="form_label mb-2">
                    Select Purchase Quality
                  </label>
                  <div className="row col-lg-12 p-0">
                    {this.state.purchase_qualities.map((e, index) => (
                      <div className="col col-lg-6 p-1 mb-2" key={index}>
                        <div
                          className="row col-lg-12 rounded p-0"
                          style={{
                            border:
                              e.id === this.state.purchaseQualitySelection
                                ? "1px solid #ec7833"
                                : "1px solid #dee2e6",
                          }}
                        >
                          <div className="col-lg-1 p-0">
                            <NewRadio
                              checked={
                                e.id === this.state.purchaseQualitySelection
                              }
                              onChange={(e) => {
                                var row = this.state.purchase_qualities;
                                this.setState(
                                  {
                                    purchaseQualitySelection: e.target.value,
                                    purchase_contract_id:
                                      row[index].purchase_contract_id,
                                    pc_qualities_id: row[index].id,
                                    pc_quality: row[index].quality,
                                    procurement_quality_standard:
                                      row[index].purchasing_term === "Specs" &&
                                      row[index].specification_standard ===
                                        "ASTM/ISO"
                                        ? "0"
                                        : row[index].purchasing_term ===
                                          "Non Specs"
                                        ? row[index].purchasing_term
                                        : row[index].specification_standard,
                                  },
                                  () => {
                                    const loginUserID =
                                      this.Cookie.getCookie("loginUserId");
                                    const idToken =
                                      this.Cookie.getIdTokenCookie();
                                    api
                                      .get_barges_qty_by_purchase_contract(
                                        loginUserID,
                                        idToken,
                                        row[index].purchase_contract_id,
                                        row[index].id
                                      )
                                      .then((response) => {
                                        if (
                                          response.status >= 200 &&
                                          response.status < 300
                                        ) {
                                          response.json().then((res) => {
                                            if (res.code === "200") {
                                              this.setState(
                                                {
                                                  total_barge_quantity:
                                                    res.total_barge_quantity,
                                                },
                                                () => {
                                                  // calculating purchase quality - quantity (including tolerance);
                                                  if (
                                                    row[index].id ===
                                                    this.state
                                                      .purchaseQualitySelection
                                                  ) {
                                                    let pc_tolerance_quantity =
                                                      (Number(
                                                        row[index]
                                                          .quantity_adjusted
                                                      ) *
                                                        Number(
                                                          row[index]
                                                            .tolerance_adjusted
                                                        )) /
                                                      100;
                                                    let pc_total_quantity =
                                                      Number(
                                                        row[index]
                                                          .quantity_adjusted
                                                      ) +
                                                      Number(
                                                        pc_tolerance_quantity
                                                      );

                                                    if (
                                                      Number(
                                                        pc_total_quantity
                                                      ) <
                                                      Number(
                                                        this.state
                                                          .bargeQuantityInMT
                                                      ) +
                                                        Number(
                                                          res.total_barge_quantity
                                                        )
                                                    ) {
                                                      this.setState({
                                                        barge_quantity_error: true,
                                                        barge_quantity_error_msg:
                                                          "Barge quantity is exceeding the purchase contract quantity with tolerance",
                                                      });
                                                    } else {
                                                      this.setState({
                                                        barge_quantity_error: false,
                                                        barge_quantity_error_msg:
                                                          "",
                                                      });
                                                    }
                                                    this.setState({
                                                      pc_total_quantity:
                                                        pc_total_quantity,
                                                    });
                                                  }
                                                }
                                              );
                                            } else if (res.code === "607") {
                                              window.location.href = "/logout";
                                            }
                                          });
                                        }
                                      });
                                  }
                                );
                              }}
                              value={e.id}
                              name="purchaseQualitySelection"
                              inputProps={{ "aria-label": "B" }}
                              className="collapsed"
                              data-toggle="collapse"
                              data-target={"#demo1" + e.id}
                              aria-expanded="false"
                              aria-controls={"group-of-rows-1"}
                            />
                          </div>
                          <div className="col-lg-11 pr-0">
                            <div className="row col-lg-12 p-0">
                              <div className="col-lg-6 pl-0">
                                <label className="drawer_section_label mb-0">
                                  Quality
                                </label>
                                <label className="drawer_section_value mb-0">
                                  {e.quality}
                                </label>
                              </div>
                              <div className="col-lg-6 pr-0">
                                <label className="drawer_section_label mb-0">
                                  Tolerence
                                </label>
                                <label className="drawer_section_value mb-0">
                                  {e.tolerance_adjusted + "%"}
                                </label>
                              </div>
                            </div>
                            <div className="row col-lg-12 p-0">
                              <div className="col-lg-6 pl-0">
                                <label className="drawer_section_label mb-0">
                                  Quantity
                                </label>
                              </div>
                              <div className="col-lg-6 pr-0">
                                <label className="drawer_section_total mb-0">
                                  Total
                                </label>
                                <label className="drawer_section_total_value mb-0">
                                  {this.toLocaleString(
                                    Number(e.quantity_adjusted).toFixed(3)
                                  )}
                                </label>
                              </div>
                            </div>
                            <div style={{ width: "98%" }}>
                              <BorderLinearProgress
                                variant="determinate"
                                value={progressBarSize(e, "NPC")}
                                style={{ borderRadius: 5 }}
                              />
                            </div>
                            <div className="row col-lg-12 p-0 mb-2">
                              <div className="col-lg pl-0">
                                <label className="drawer_section_value mb-0">
                                  {this.toLocaleString(
                                    Number(e.barge_delivered_quantity).toFixed(
                                      3
                                    )
                                  )}
                                </label>
                                <label className="drawer_section_label mb-0">
                                  Delivered
                                </label>
                              </div>
                              <div className="col-lg pr-0">
                                <label className="drawer_section_value mb-0">
                                  {this.toLocaleString(_toBeDelivered(e))}
                                </label>
                                <label className="drawer_section_label mb-0">
                                  Remaining
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="pl-0">
                    {this.state.purchase_qualities.map((e, index) => (
                      <div key={index}>
                        {e.id === this.state.purchaseQualitySelection && (
                          <div>
                            <div className="row">
                              <div className="col-lg-3 form_label label-text pl-0">
                                Quality Standard{" "}
                                <span style={{ color: "red" }}>*</span>
                              </div>
                              <div className="label-text">:</div>
                              <div className="col-lg-5">
                                {e.purchasing_term === "Specs" &&
                                e.specification_standard === "ASTM/ISO" ? (
                                  <TextField
                                    name="purchaseProcurement"
                                    margin="dense"
                                    variant="outlined"
                                    //label='Procurement Quality Standard *'
                                    fullWidth
                                    value={
                                      this.state.procurement_quality_standard
                                    }
                                    error={this.state.purchase_procurementError}
                                    onChange={(e) => {
                                      this.setState({
                                        procurement_quality_standard:
                                          e.target.value,
                                        purchase_procurementError: false,
                                      });
                                    }}
                                    select
                                  >
                                    <MenuItem value="0">Please Select</MenuItem>
                                    <MenuItem value="ASTM">ASTM</MenuItem>
                                    <MenuItem value="ISO">ISO</MenuItem>
                                  </TextField>
                                ) : (
                                  <div className="label-text">
                                    {e.purchasing_term === "Specs"
                                      ? e.specification_standard
                                      : e.purchasing_term}
                                  </div>
                                )}
                              </div>
                            </div>
                            <hr className="mt-3" />
                            <label className="form_label mb-2 mt-2">
                              Select Purchase Type
                            </label>

                            <table style={{ width: "100%" }}>
                              <thead className="thead-light">
                                <tr>
                                  <th></th>
                                  <th className="drawer_table_header_label">
                                    Mine Name
                                  </th>
                                  <th className="drawer_table_header_label">
                                    Purchase Type
                                  </th>
                                  <th className="drawer_table_header_label">
                                    Anchorage Name
                                  </th>
                                  <th className="drawer_table_header_label">
                                    Coal Price
                                  </th>
                                </tr>
                              </thead>
                              {e.purchase_types.map((p, idx) => (
                                <tbody key={idx}>
                                  <tr
                                    style={{
                                      border:
                                        p.id ===
                                        this.state.purchaseTypeSelection
                                          ? "1px solid #ec7833"
                                          : "1px solid #dee2e6",
                                    }}
                                  >
                                    <td style={{ verticalAlign: "middle" }}>
                                      <NewRadio
                                        checked={
                                          p.id ===
                                          this.state.purchaseTypeSelection
                                        }
                                        onChange={(e) => {
                                          this.setState({
                                            purchaseTypeSelection:
                                              e.target.value,
                                            pc_purchase_type_id: p.id,
                                            purchase_type: p.purchase_type,
                                          });
                                        }}
                                        value={p.id}
                                        name="purchaseTypeSelection"
                                        inputProps={{ "aria-label": "B" }}
                                      />
                                    </td>
                                    <td className="drawer_table_value">
                                      {p.mine_name}
                                    </td>
                                    <td className="drawer_table_value">
                                      {p.purchase_type}
                                    </td>
                                    <td className="drawer_table_value">
                                      {p.purchase_type === "FAS Anchorage"
                                        ? p.anchorage_name
                                        : ""}
                                    </td>
                                    <td className="drawer_table_value">
                                      {p.price_pmt}{" "}
                                      {this.state.purchaseCurrency}
                                    </td>
                                  </tr>
                                </tbody>
                              ))}
                            </table>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="row bottom_buttons_section">
                <button
                  type="button"
                  className="btn previous_button"
                  name="submit"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn next_button"
                  onClick={() => {
                    if (this.state.procurement_quality_standard === "0") {
                      this.setState({
                        snackBarErrorOpen: true,
                        purchase_procurementError: true,
                        errorMsg: "Please Select Specification Standard",
                      });
                      return;
                    }
                    window.$("#myModal").modal("hide");
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
