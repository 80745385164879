import React, { Component } from "react";
import api from "../../../api/api";
import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import { Alert } from "@material-ui/lab";
import { Snackbar } from "@material-ui/core";
import { localDateFormate } from "../../common/common";
import ViewFiles from "../../common/ViewFiles";
import Loader from "../../common/Loader";
import InvoiceLink from "../../InvoicePdf/InvoiceLink";
import InvoiceForm from "../../common/InvoiceForm";
import BusinessApproveButton from "../../Approvals/BusinessApproveButton";
import PaymentPostings from "../../common/PaymentPostings";

export default class MvCoalSalesChargesView extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();

    this.state = {
      multiRows: [
        {
          payment_to: "0",
          payee_name: "",
          pph23_account: "0",
          barge_quantity: "",
          base_price_pmt: "",
          base_price: "",
          vat_percent: "10",
          vat_value: "",
          pph23_percent: "2",
          pph23_value: "",
          payable_to_vendor: "",
          cost_to_company: "",
          remarks: "",
          remaining_amount: "",
          costing_no: "",
          billing_currency: "IDR",
        },
      ],
      mother_vessel_name: "",
      getBarges: [],
      isLoading: true,
      features: [],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    } else {
      const features = this.Cookie.getCookie("features");
      this.setState({ features: features });
      if (
        !(
          features.includes("283") ||
          features.includes("429") ||
          features.includes("480") ||
          features.includes("97")
        )
      ) {
        window.location.href = "/dashboard";
      } else {
        const loginUserID = this.Cookie.getCookie("loginUserId");
        const idToken = this.Cookie.getIdTokenCookie();
        const businessNoID = this.props.businessNoID;

        //Get businessNumber API based on businessNumber
        await api
          .getBusinessNumber(businessNoID, loginUserID, idToken)
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              response.json().then((res) => {
                if (res.code === "200") {
                  if (res.business_number) {
                    var total_delivered_quantity = 0;
                    for (var i of res.business_number
                      .business_number_allocation) {
                      total_delivered_quantity += Number(
                        i.bna_quantity_delivered
                      );
                    }
                    var row = this.state.multiRows;
                    if (row.length > 0) {
                      row[0].barge_quantity = total_delivered_quantity;
                    }
                    this.setState({
                      businessNo: res.business_number.business_no
                        ? res.business_number.business_no
                        : "",
                      mother_vessel_name: res.business_number.mother_vessel_name
                        ? res.business_number.mother_vessel_name
                        : "",
                    });
                  }
                } else if (res.code === "601") {
                  this.setState({
                    errorMsg: res.message,
                    snackBarErrorOpen: true,
                  });
                } else if (res.code === "607") {
                  window.location.href = "/logout";
                }
              });
            }
          });

        const coalSalesChargesID = this.props.coalSalesChargesID;
        //Get mv_sales_fin_csc API based on coalSalesChargesID
        await api
          .get_mv_sales_csc(loginUserID, idToken, coalSalesChargesID)
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              response.json().then((res) => {
                if (res.code === "200") {
                  if (res.mv_sales_fin_csc.length <= 0) {
                    this.setState({
                      errorMsg: "No Sales Financial data available.",
                      snackBarErrorOpen: true,
                    });
                  }
                  var paid_to_vendor = 0;
                  var remaining_amount = 0;
                  for (var i = 0; i < res.mv_sales_fin_csc.length; i++) {
                    if (res.mv_sales_fin_csc[i].csc_payments.length > 0) {
                      for (var j of res.mv_sales_fin_csc[i].csc_payments) {
                        if (j.date_of_payment) {
                          paid_to_vendor += Number(j.amount);
                        }
                        remaining_amount =
                          Number(res.mv_sales_fin_csc[i].payable_to_vendor) -
                          Number(paid_to_vendor);
                      }
                      res.mv_sales_fin_csc[i].paid_to_vendor = paid_to_vendor;
                      res.mv_sales_fin_csc[i].remaining_amount =
                        remaining_amount;
                      paid_to_vendor = 0;
                      remaining_amount = 0;
                    } else {
                      res.mv_sales_fin_csc[i].remaining_amount = Number(
                        res.mv_sales_fin_csc[i].payable_to_vendor
                      );
                    }
                  }
                  this.setState({
                    multiRows: res.mv_sales_fin_csc,
                    isLoading: false,
                  });
                } else if (res.code === "601") {
                  this.setState({
                    errorMsg: res.message,
                    snackBarErrorOpen: true,
                  });
                } else if (res.code === "607") {
                  window.location.href = "/logout";
                }
              });
            }
          });
      }
    }
  }

  renderPaymentPostings = (data) => {
    const {
      id,
      csc_payments = [],
      invoice_no = null,
      remaining_amount = 0,
      currency_xchg_rate = 1,
      billing_currency,
      approval_status = "Pending",
    } = data;
    let payment_related_data = {
      paymentTitle: "MV Coal Sales Charges",
      payment_posting_type: "csc",
      invoice_no: invoice_no,
      outstanding_amount: remaining_amount,
      billing_currency: billing_currency
        ? billing_currency
        : config.company === "SRPL" ||
          config.company === "AVS" ||
          config.company === "PRPL"
        ? "USD"
        : "IDR",
      currency_xchg_rate: currency_xchg_rate ? currency_xchg_rate : 1,
      costing_id: id,
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
    };

    let access_details = {
      costing_approval: approval_status,
      add_payment: "290",
      view_payment: "291",
      update_payment: "292",
      delete_payment: "293",
      approver: "431",
      approver2: "482",
    };

    return (
      <PaymentPostings
        payments={csc_payments}
        access_details={access_details}
        costingType="MV Coal Sales Charges"
        data={payment_related_data}
        callbackFn={() => {
          this.componentDidMount();
        }}
      />
    );
  };

  renderInvoiceLink = (data) => {
    const {
      costing_no,
      payment_to_name,
      invoice_no,
      invoice_date,
      billing_currency,
      base_price,
      barge_quantity,
      base_price_pmt,
      currency_xchg_rate,
      vat_value,
      pph23_value,
      pph15_account,
      payment_to_customer_name,
      business_partner_type,
      payable_to_vendor = 0,
    } = data;

    const Grand_total = parseFloat(payable_to_vendor).toFixed(2);

    const invoiceObject = {
      costing_id: costing_no,
      to:
        business_partner_type === "Vendor"
          ? payment_to_name
          : payment_to_customer_name,
      business_no: this.state.mother_vessel_name,
      costing_name: "MV Coal Sales Charges",
      invoice_no: invoice_no,
      invoice_date: localDateFormate(invoice_date),
      bargeDetails: [],
      billing_currency: billing_currency
        ? billing_currency
        : config.company === "SRPL" ||
          config.company === "PRPL" ||
          config.company === "AVS"
        ? "USD"
        : "IDR",
      total_payable_amount: Grand_total,
      purchase_type: "FOB Barge",
      description: [
        base_price
          ? {
              qty: barge_quantity,
              adjustedPrice: base_price_pmt,
              desc: `${
                currency_xchg_rate > 1
                  ? `MV Coal Sales Charges @ ${parseFloat(
                      currency_xchg_rate
                    ).toFixed(2)}`
                  : "MV Coal Sales Charges"
              }`,
              amount: parseFloat(base_price).toFixed(2),
            }
          : [],
        ...(pph15_account !== "SDAM" && vat_value
          ? [
              {
                qty: " ",
                adjustedPrice: " ",
                desc: "VAT",
                amount: `${vat_value}`,
              },
            ]
          : []),
        ...(!(
          config.company === "SRPL" ||
          config.company === "AVS" ||
          config.company === "PRPL"
        ) && pph23_value
          ? [
              {
                qty: " ",
                adjustedPrice: " ",
                desc: "PPH",
                amount: `( ${pph23_value} )`,
              },
            ]
          : []),
      ],
    };
    return <InvoiceLink data={invoiceObject} fileName={costing_no} />;
  };

  updateInvoice = (data) => {
    let request = {
      ...data,
      table_name: "mv_sales_fin_csc",
    };
    api.update_invoice_details(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.componentDidMount();
            this.setState({
              invoiceDialog: false,
            });
          }
        });
      }
    });
  };

  render() {
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div
          className="container bg-white p-0"
          style={{ minHeight: "100vh", width: 850 }}
        >
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          {this.state.multiRows.map((e, idx) => (
            <div className="clearfix content-header border-bottom">
              <div className="row">
                <div className="col-lg-10 text-left pl-0">
                  <h4 style={{ padding: "10px 20px" }}>
                    {` Coal Sales Charges - ${e.costing_no}`}
                    <span
                      className={
                        e.approval_status_2 === "Pending" ? "type_2" : "type_1"
                      }
                      style={{ padding: 8, marginLeft: 16 }}
                    >
                      {e.approval_status_2}
                    </span>
                  </h4>
                </div>
                {e.approval_status === "Pending" &&
                  e.invoice_file &&
                  this.Cookie.getCookie("loginUserId") !== e.created_by &&
                  e.approved_by !== this.Cookie.getCookie("loginUserId") &&
                  (this.state.features.includes("429") ||
                    this.state.features.includes("480") ||
                    this.state.features.includes("97")) && (
                    <BusinessApproveButton
                      costingID={e.id}
                      costingType={"MV Coal Sales Charges"}
                      accessInfo={{
                        ...e,
                        approver_1: "429",
                        approver_2: "480",
                      }}
                      callbackFn={() => this.componentDidMount()}
                    />
                  )}
                <div className="col-lg-1 pl-0 float-right">
                  {this.renderInvoiceLink(e)}
                </div>
              </div>
            </div>
          ))}
          <div
            className="card p-3"
            style={{ overflowY: "scroll", minHeight: "86vh" }}
          >
            <div className="card-body p-0">
              {this.state.multiRows.map((e, idx) => (
                <div className="row" key={idx}>
                  <div className="col shadow-sm p-0 mb-2 bg-white border">
                    {/* New Changes */}
                    <div className="table-responsive">
                      <table className="table table-sm table-bordered mb-0">
                        <thead className="table-header">
                          <tr>
                            <th className="text-center">Description</th>
                            <th className="text-right">Qty</th>
                            <th className="text-right">Unit Price</th>
                            <th className="text-right">Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          <>
                            <tr>
                              <td>
                                Coal Procurement
                                {e.currency_xchg_rate > 1 && (
                                  <div>
                                    Exchange Rate @
                                    {parseFloat(e.currency_xchg_rate).toFixed(
                                      2
                                    )}
                                  </div>
                                )}
                                {(config.company === "SDAM" ||
                                  config.company === "SRK" ||
                                  config.company === "BTR") &&
                                  e.pph23_account && (
                                    <div>PPH23 Account : {e.pph23_account}</div>
                                  )}
                              </td>
                              <td className="text-right">{e.barge_quantity}</td>
                              <td className="text-right">{e.base_price_pmt}</td>
                              <td className="text-right">{e.base_price}</td>
                            </tr>
                            {(this.state.vat_applicability === "Inclusive" ||
                              config.company !== "SRPL" ||
                              config.company !== "PRPL" ||
                              config.company !== "AVS") &&
                              e.vat_value > 0 && (
                                <tr>
                                  <td>VAT @ 10 %</td>
                                  <td className="text-right"></td>
                                  <td className="text-right"></td>
                                  <td className="text-right">{e.vat_value}</td>
                                </tr>
                              )}
                            {!(
                              config.company === "SRPL" ||
                              config.company === "AVS" ||
                              config.company === "PRPL"
                            ) &&
                              e.pph23_account === "Supplier" &&
                              e.pph23_value && (
                                <tr>
                                  <td>PPH23 @{e.pph23_percent} % </td>
                                  <td></td>
                                  <td></td>
                                  <td className="text-right">
                                    ({e.pph23_value})
                                  </td>
                                </tr>
                              )}
                            {!(
                              config.company === "SRPL" ||
                              config.company === "AVS" ||
                              config.company === "PRPL"
                            ) &&
                              e.pph23_account === "SDAM" &&
                              e.pph23_value && (
                                <tr>
                                  <td>PPH23 @{e.pph23_percent} %</td>
                                  <td></td>
                                  <td></td>
                                  <td className="text-right">
                                    ({e.pph23_value})
                                  </td>
                                </tr>
                              )}

                            <tr>
                              <td
                                colSpan={3}
                                style={{ fontWeight: "bold" }}
                                className="text-right "
                              >
                                {`Payable to ${
                                  e.business_partner_type === "Vendor"
                                    ? e.payment_to_name
                                    : e.payment_to_customer_name
                                }`}
                              </td>
                              <td
                                style={{ fontWeight: "bold" }}
                                className="text-right "
                              >{`${
                                config.company === "SRPL" ||
                                config.company === "PRPL" ||
                                config.company === "AVS"
                                  ? "USD"
                                  : "IDR"
                              } ${parseFloat(e.payable_to_vendor)} `}</td>
                            </tr>
                            <tr>
                              <td
                                colSpan={3}
                                style={{ fontWeight: "bold" }}
                                className="text-right "
                              >
                                {`Paid`}
                              </td>
                              <td
                                style={{ fontWeight: "bold" }}
                                className="text-right "
                              >
                                {parseFloat(
                                  e.paid_to_vendor ? e.paid_to_vendor : 0
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td
                                colSpan={3}
                                style={{ fontWeight: "bold" }}
                                className="text-right "
                              >
                                {`Remaining Amount`}
                              </td>
                              <td
                                style={{ fontWeight: "bold" }}
                                className="text-right "
                              >
                                {parseFloat(e.remaining_amount)}
                              </td>
                            </tr>
                          </>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="col-lg-12 border_dashed rounded-lg p-2 mb-3">
                    <InvoiceForm
                      open={false}
                      data={e}
                      costingID={e.id}
                      callbackFn={(data) => this.updateInvoice(data)}
                    />
                    {this.renderPaymentPostings(e)}
                  </div>

                  <ViewFiles {...this.props} files_data={e.files} />
                  <div className="col shadow-sm p-0 mb-2 bg-white border">
                    <div className="table-responsive">
                      <table className="table table-sm table-bordered">
                        <thead className="table-header">
                          <tr>
                            <th className="text-center">Approval Type</th>
                            <th className="text-center">Approved by</th>
                            <th className="text-center">Approved On</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="text-center">Business</td>
                            <td className="text-center">
                              <div> {e.approved_by_name}</div>
                            </td>
                            <td className="text-center">
                              {localDateFormate(e.approved_date)}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-center">Financial</td>
                            <td className="text-center">
                              {e.approved_by_name_2}
                            </td>
                            <td className="text-center">
                              {localDateFormate(e.approved_date_2)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <footer className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button btn-light"
              name="submit"
              onClick={() => {
                this.props.returnCallbackDrawerClose(false);
              }}
            >
              Close
            </button>
            <button
              type="button"
              className="drawer_text drawer_btn_success"
              name="Back"
              onClick={() => {
                this.props.onEdit(true);
              }}
            >
              Edit
            </button>
          </footer>
        </div>
      );
    }
  }
}
