import React, { Component } from "react";

import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import api from "../../../api/api";
import { Snackbar } from "@material-ui/core";

import { Alert } from "@material-ui/lab";
import { localDateFormate, pad, toLocaleString } from "../../common/common";
import ViewFiles from "../../common/ViewFiles";
import InvoiceLink from "../../InvoicePdf/InvoiceLink";
import DeleteConfirmation from "../../common/DeleteConfirmation";
import PaymentPostings from "../../common/PaymentPostings";
import Loader from "../../common/Loader";
import InvoiceForm from "../../common/InvoiceForm";
export default class InsuranceView extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      barge_id: null,
      insurance_level: "Barge",
      business_no_id: "",
      voyage_type: "",
      vessel_type: "",
      barge_vessel_name: "",
      imo_no: "",
      year_built: "",
      flag: "",
      grt: "",
      classification: "",
      owners_p_and_i_cover: "",
      charterparty: "",
      delivery_date: null,
      estimated_duration: "",
      time_or_voyage: "",
      cargo: "",
      charterers_p_and_i_liability: "No",
      charterers_fd_and_d_liability: "No",
      back_to_back_sublet: "No",
      charterers_b_l: "No",
      contract_type: "Per Year",
      p_and_i_value: "",
      fd_and_d_value: "",
      exchange_rate: "",
      barges: [],
      insurance_payments: [],
      files: [],
      activeStep: 0,
      isLoading: true,
      invoiceDetails: {
        invoice_no: "",
        invoice_date: null,
        ppn: "",
        ppn_date: null,
      },
      getBarges: [],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    let features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (!(features.includes("347") || features.includes("97"))) {
      window.location.href = "/dashboard";
    }
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const insurance_id = this.props.insurance_id;
    await api
      .get_insurance_and_declaration(loginUserID, idToken, insurance_id)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.insurance_and_declaration) {
                var total_payment_amount = 0;
                var remaining_amount = 0;
                let total_payable =
                  Number(
                    res.insurance_and_declaration.p_and_i_value
                      ? res.insurance_and_declaration.p_and_i_value
                      : 0
                  ) +
                  Number(
                    res.insurance_and_declaration.fd_and_d_value
                      ? res.insurance_and_declaration.fd_and_d_value
                      : 0
                  );
                for (var i of res.insurance_and_declaration
                  .insurance_and_declaration_payments) {
                  total_payment_amount += Number(i.amount);
                }
                remaining_amount =
                  Number(total_payable) - Number(total_payment_amount);

                const invoiceStateCopy = {
                  ...this.state.invoiceDetails,
                  invoice_no: res.insurance_and_declaration.invoice_no,
                  invoice_date: res.insurance_and_declaration.invoice_date,
                  ppn: res.insurance_and_declaration.ppn,
                  ppn_date: res.insurance_and_declaration.ppn_date,
                };
                this.setState(
                  {
                    remaining_amount: remaining_amount,
                    //cost_to_company: total_payable,
                    invoiceDetails: invoiceStateCopy,
                    total_payment_amount: total_payment_amount,
                    total_payable: total_payable,
                    insurance_level:
                      res.insurance_and_declaration.insurance_level,
                    business_no_id:
                      res.insurance_and_declaration.business_no_id,
                    costing_no: res.insurance_and_declaration.costing_no,
                    barge_id: res.insurance_and_declaration.barge_id,
                    invoice_file: res.insurance_and_declaration.invoice_file,
                    voyage_type: res.insurance_and_declaration.voyage_type,
                    vessel_type: res.insurance_and_declaration.vessel_type,
                    barge_vessel_name:
                      res.insurance_and_declaration.barge_vessel_name,
                    imo_no: res.insurance_and_declaration.imo_no,
                    year_built: res.insurance_and_declaration.year_built,
                    flag: res.insurance_and_declaration.flag,
                    grt: res.insurance_and_declaration.grt,
                    classification:
                      res.insurance_and_declaration.classification,
                    owners_p_and_i_cover:
                      res.insurance_and_declaration.owners_p_and_i_cover,
                    charterparty: res.insurance_and_declaration.charterparty,
                    delivery_date: res.insurance_and_declaration.delivery_date,
                    estimated_duration:
                      res.insurance_and_declaration.estimated_duration,
                    time_or_voyage:
                      res.insurance_and_declaration.time_or_voyage,
                    cargo: res.insurance_and_declaration.cargo,
                    charterers_p_and_i_liability:
                      res.insurance_and_declaration
                        .charterers_p_and_i_liability,
                    charterers_fd_and_d_liability:
                      res.insurance_and_declaration
                        .charterers_fd_and_d_liability,
                    back_to_back_sublet:
                      res.insurance_and_declaration.back_to_back_sublet,
                    charterers_b_l:
                      res.insurance_and_declaration.charterers_b_l,
                    contract_type: res.insurance_and_declaration.contract_type,
                    p_and_i_value: res.insurance_and_declaration.p_and_i_value,
                    fd_and_d_value:
                      res.insurance_and_declaration.fd_and_d_value,

                    exchange_rate: res.insurance_and_declaration.exchange_rate,
                    billing_currency:
                      res.insurance_and_declaration.billing_currency,
                    cost_to_company:
                      res.insurance_and_declaration.cost_to_company,
                    insurance_payments:
                      res.insurance_and_declaration
                        .insurance_and_declaration_payments,
                    files: res.insurance_and_declaration.files,

                    isLoading: false,
                  },

                  () => {
                    const barge_id = this.state.barge_id;
                    let request = {
                      login_user_id: this.Cookie.getCookie("loginUserId"),
                      idtoken: this.Cookie.getIdTokenCookie(),
                      barge_id: barge_id,
                      vendor_type: "Barge",
                    };
                    api
                      .get_business_no_info_for_barge(request)
                      .then((response) => {
                        if (response.status >= 200 && response.status < 300) {
                          response.json().then((res) => {
                            if (res.code === "200") {
                              this.setState({
                                vendor_name: res.vendor_name,
                                mv_name: res.mv_name,
                              });
                            }
                          });
                        }
                      });
                  }
                );
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
    await api.get_barges(loginUserID, idToken).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            if (res.barges) {
              let newData = [];
              var result = res.barges.reduce(function (r, a) {
                r[a.id] = r[a.id] || [];
                r[a.id].push(a);
                return r;
              }, Object.create(null));
              for (let l = 0; l < Object.keys(result).length; l++) {
                newData.push({
                  new_barges: result[Object.keys(result)[l]],
                });
              }
              let purchase_contracts_barges = newData;
              if (newData.length === 0) {
                this.setState({
                  snackBarErrorOpen: true,
                  errorMsg: "No records to display",
                });
              }
              this.setState({
                getBarges: purchase_contracts_barges,
                id: res.barges[0].id,
                //isLoading: false
              });
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          } else if (res.code === "601") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
  }

  deletePaymentPostingHandler = (e) => {
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    const insurance_id = this.props.insurance_id;
    var insurance_payment_id = e.id;
    var payment_posting_type = e.payment_posting_type;
    var amount = e.amount;
    var date_of_payment = e.date_of_payment;
    var invoice_no = e.invoice_no ? e.invoice_no : "";
    var description = e.description ? e.description : "";
    var status = "Deleted";
    api
      .update_insurance_and_declaration_payments(
        loginUserID,
        idToken,
        insurance_id,
        insurance_payment_id,
        payment_posting_type,
        amount,
        date_of_payment,
        invoice_no,
        description,
        status
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState({ deletePayment: false });
              this.componentDidMount();
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                succesMsg: "",
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  };

  renderPaymentPostings = (data) => {
    const {
      id,
      insurance_payments = [],
      invoice_no = null,
      remaining_amount = 0,
      sac_sampling_currency_xchg_rate = 1,
      approval_status = "Pending",
    } = data;
    let payment_related_data = {
      paymentTitle: "Procurement Service Fees",
      payment_posting_type: "Pre_Shipment",
      invoice_no: invoice_no,
      outstanding_amount: remaining_amount,
      billing_currency:
        config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL"
          ? "USD"
          : "IDR",
      currency_xchg_rate: sac_sampling_currency_xchg_rate,
      costing_id: id,
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
    };
    let access_details = {
      costing_approval: approval_status,
      add_payment: "350",
      view_payment: "351",
      update_payment: "352",
      delete_payment: "353",
      approver: "390",
      approver2: "441",
    };

    return (
      <PaymentPostings
        payments={insurance_payments}
        costingType="Export Tax"
        data={payment_related_data}
        access_details={access_details}
        callbackFn={() => {
          this.componentDidMount();
        }}
      />
    );
  };

  updateInvoice = (data) => {
    let request = {
      ...data,
      table_name: "insurance_and_declaration",
    };
    api.update_invoice_details(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.componentDidMount();
            this.setState({
              invoiceDialog: false,
            });
          }
        });
      }
    });
  };

  renderInvoiceLink = () => {
    const data = this.state.getBarges.map((e) =>
      e.new_barges.filter((obj) => obj.id === this.state.barge_id)
    );
    const {
      invoiceDetails: { invoice_no, invoice_date } = {},
      costing_no,
      barge_quantity = 0,
      cost_to_company = 0,
      currency_xchg_rate = 1,
      total_payable,
      billing_currency,
      vendor_name,
    } = this.state;

    let total_adjustment = 0;

    const Grand_total = (
      parseFloat(total_payable ? total_payable : 0) +
      parseFloat(total_adjustment ? total_adjustment : 0)
    ).toFixed(2);
    const invoiceObject = {
      costing_id: costing_no,
      to: vendor_name,
      business_no: this.state.mv_name,
      costing_name: "Insurance",
      invoice_no: invoice_no,
      invoice_date: localDateFormate(invoice_date),
      bargeDetails: data ? data.flat() : [],
      billing_currency: billing_currency,
      total_payable_amount: toLocaleString(Grand_total),
      purchase_type: "FOB Barge",
      description: [
        {
          qty: toLocaleString(barge_quantity),
          adjustedPrice: toLocaleString(cost_to_company),
          desc: `${
            currency_xchg_rate > 1
              ? `Exchange rate @ ${parseFloat(currency_xchg_rate)}`
              : ""
          }`,
          amount: total_payable,
        },
        // ...advanceAdjustments,
        ...(this.state.pph15_account !== "SDAM"
          ? [
              {
                qty: " ",
                adjustedPrice: " ",
                desc: "VAT",
                amount: `${toLocaleString(this.state.barge_vat_value)}`,
              },
            ]
          : []),
        ...(!(
          config.company === "SRPL" ||
          config.company === "AVS" ||
          config.company === "PRPL"
        )
          ? [
              {
                qty: " ",
                adjustedPrice: " ",
                desc: "PPH",
                amount: `( ${toLocaleString(this.state.barge_pph15_value)} )`,
              },
            ]
          : []),
      ],
    };
    return <InvoiceLink data={invoiceObject} fileName={costing_no} />;
  };

  render() {
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div className="container bg-white p-0" style={{ minHeight: "100vh" }}>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <div className="row">
            <div className="col-lg-10 p-0 border-bottom">
              <h6 style={{ padding: "15px", marginBottom: 0 }}>
                Insurance & Declaration
              </h6>
            </div>
            <div className="col-lg-1 pl-0 float-right">
              {this.renderInvoiceLink()}
            </div>
          </div>

          <div className="drawer-section">
            <div className="row mb-0 stepper">
              {this.props.barge_details && (
                <div className="row">
                  <div className="table-responsive">
                    <div className="table border-0">
                      <table className="table table-borderless border-0">
                        <thead className="border-0">
                          <tr>
                            <th
                              style={{ fontSize: 16 }}
                              nowrap="true"
                              className="table_header_barge_label text-uppercase border-0"
                            >
                              Barge ID
                            </th>
                            <th
                              style={{ fontSize: 16 }}
                              nowrap="true"
                              className="table_header_barge_label text-uppercase border-0"
                            >
                              Barge Nomination
                            </th>
                            <th
                              style={{ fontSize: 16 }}
                              nowrap="true"
                              className="table_header_barge_label text-uppercase border-0"
                            >
                              Business No
                            </th>
                            <th
                              style={{ fontSize: 16 }}
                              nowrap="true"
                              className="text-right table_header_barge_label text-uppercase border-0"
                            >
                              Total Payable
                            </th>
                            <th
                              style={{ fontSize: 16 }}
                              nowrap="true"
                              className="text-right table_header_barge_label text-uppercase border-0"
                            >
                              Paid
                            </th>
                            <th
                              style={{ fontSize: 16 }}
                              nowrap="true"
                              className="text-right table_header_barge_label text-uppercase border-0"
                            >
                              Remaining
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="rounded">
                            <td className="table_td text-left">
                              {pad(this.props.barge_details.barge_id)}
                            </td>
                            <td className="table_td text-left" nowrap="true">
                              {this.props.barge_details.barge_nomination}
                            </td>
                            <td className="table_td text-left" nowrap="true">
                              {this.props.barge_details.business_no}
                            </td>
                            <td className="table_td text-right" nowrap="true">
                              {toLocaleString(
                                this.props.barge_details.total_payable
                              )}
                            </td>
                            <td className="table_td text-right" nowrap="true">
                              {toLocaleString(this.props.barge_details.paid)}
                            </td>
                            <td className="table_td text-right" nowrap="true">
                              {toLocaleString(
                                this.props.barge_details.Remaining_amount
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="col-lg-12">
              <div className="card p-0 border mt-4 mb-3">
                <div className="card-header section_header">
                  <h5> Insurance & Declaration</h5>
                </div>
                <div className="card-body">
                  <div className="row col-lg-12 p-0">
                    <div className="col-lg-4 mb-3">
                      <label className="contract_display_header_label">
                        Voyage Type
                      </label>
                      <div className="contract_display_header_value">
                        {this.state.voyage_type ? this.state.voyage_type : "-"}
                      </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label className="contract_display_header_label">
                        Barge & Tug Name
                      </label>
                      <div className="contract_display_header_value">
                        {this.state.barge_vessel_name
                          ? this.state.barge_vessel_name
                          : "-"}
                      </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label className="contract_display_header_label">
                        IMO No
                      </label>
                      <div className="contract_display_header_value">
                        {this.state.imo_no ? this.state.imo_no : "-"}
                      </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label className="contract_display_header_label">
                        Year Built
                      </label>
                      <div className="contract_display_header_value">
                        {this.state.year_built ? this.state.year_built : "-"}
                      </div>
                    </div>
                    <div className="col-lg-4  mb-3">
                      <label className="contract_display_header_label">
                        Flag
                      </label>
                      <div className="contract_display_header_value">
                        {this.state.flag ? this.state.flag : "-"}
                      </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label className="contract_display_header_label">
                        Grt
                      </label>
                      <div className="contract_display_header_value">
                        {this.state.grt ? this.state.grt : "-"}
                      </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label className="contract_display_header_label">
                        Classification
                      </label>
                      <div className="contract_display_header_value">
                        {this.state.classification
                          ? this.state.classification
                          : "-"}
                      </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label className="contract_display_header_label">
                        Owners P&I Cover
                      </label>
                      <div className="contract_display_header_value">
                        {this.state.owners_p_and_i_cover
                          ? this.state.owners_p_and_i_cover
                          : "-"}
                      </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label className="contract_display_header_label">
                        Charterparty
                      </label>
                      <div className="contract_display_header_value">
                        {this.state.charterparty
                          ? this.state.charterparty
                          : "-"}
                      </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label className="contract_display_header_label">
                        Delivery Date
                      </label>
                      <div className="contract_display_header_value">
                        {this.state.delivery_date
                          ? localDateFormate(this.state.delivery_date)
                          : "-"}
                      </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label className="contract_display_header_label">
                        Estimated Duration
                      </label>
                      <div className="contract_display_header_value">
                        {this.state.estimated_duration
                          ? this.state.estimated_duration
                          : "-"}
                      </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label className="contract_display_header_label">
                        Time or Voyage
                      </label>
                      <div className="contract_display_header_value">
                        {this.state.time_or_voyage
                          ? this.state.time_or_voyage
                          : "-"}
                      </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label className="contract_display_header_label">
                        Cargo
                      </label>
                      <div className="contract_display_header_value">
                        {this.state.cargo ? this.state.cargo : "-"}
                      </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label className="contract_display_header_label">
                        Charterers' P&I Liability
                      </label>
                      <div className="contract_display_header_value">
                        {this.state.charterers_p_and_i_liability
                          ? this.state.charterers_p_and_i_liability
                          : "-"}
                      </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label className="contract_display_header_label">
                        Charterers' FD&D Liability
                      </label>
                      <div className="contract_display_header_value">
                        {this.state.charterers_fd_and_d_liability
                          ? this.state.charterers_fd_and_d_liability
                          : "-"}
                      </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label className="contract_display_header_label">
                        Back to Back Sublet
                      </label>
                      <div className="contract_display_header_value">
                        {this.state.back_to_back_sublet
                          ? this.state.back_to_back_sublet
                          : "-"}
                      </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label className="contract_display_header_label">
                        Charterer's B/L
                      </label>
                      <div className="contract_display_header_value">
                        {this.state.charterers_b_l
                          ? this.state.charterers_b_l
                          : "-"}
                      </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label className="contract_display_header_label">
                        Contract Type
                      </label>
                      <div className="contract_display_header_value">
                        {this.state.contract_type
                          ? this.state.contract_type
                          : "-"}
                      </div>
                    </div>
                    {this.state.contract_type === "Per Barge" && (
                      <div className="col-lg-4 mb-3">
                        <label className="contract_display_header_label">
                          P&I Value
                        </label>
                        <div className="contract_display_header_value">
                          {this.state.p_and_i_value
                            ? toLocaleString(this.state.p_and_i_value)
                            : "-"}{" "}
                          USD
                        </div>
                      </div>
                    )}
                    {this.state.contract_type === "Per Barge" && (
                      <div className="col-lg-4 mb-3">
                        <label className="contract_display_header_label">
                          FD&D Value
                        </label>
                        <div className="contract_display_header_value">
                          {this.state.fd_and_d_value
                            ? toLocaleString(this.state.fd_and_d_value)
                            : "-"}{" "}
                          USD
                        </div>
                      </div>
                    )}
                    {this.state.contract_type === "Per Vessel" && (
                      <div className="col-lg-4 mb-3">
                        <label className="contract_display_header_label">
                          Currency
                        </label>
                        <div className="contract_display_header_value">
                          {this.state.billing_currency
                            ? this.state.billing_currency
                            : "-"}
                        </div>
                      </div>
                    )}
                    {!(
                      config.company === "SRPL" ||
                      config.company === "AVS" ||
                      config.company === "PRPL"
                    ) &&
                      this.state.contract_type === "Per Vessel" &&
                      this.state.billing_currency !== "IDR" &&
                      this.state.billing_currency !== "0" && (
                        <div className="col-lg-4 mb-3">
                          <label className="contract_display_header_label">
                            Exchange Rate {this.state.billing_currency} to IDR
                          </label>
                          <div className="contract_display_header_value">
                            {this.state.exchange_rate
                              ? toLocaleString(this.state.exchange_rate)
                              : "-"}
                          </div>
                        </div>
                      )}
                    {(config.company === "SRPL" ||
                      config.company === "AVS" ||
                      config.company === "PRPL") &&
                      this.state.contract_type === "Per Vessel" &&
                      this.state.billing_currency !== "USD" &&
                      this.state.billing_currency && (
                        <div className="col-lg-4 mb-3">
                          <label className="contract_display_header_label">
                            Exchange Rate USD to {this.state.billing_currency}
                          </label>
                          <div className="contract_display_header_value">
                            {this.state.exchange_rate
                              ? toLocaleString(this.state.exchange_rate)
                              : "-"}
                          </div>
                        </div>
                      )}
                    {this.state.contract_type === "Per Vessel" && (
                      <div className="col-lg-4 mb-3">
                        <label className="contract_display_header_label">
                          Cost to company
                        </label>
                        <div className="contract_display_header_value">
                          {this.state.cost_to_company
                            ? toLocaleString(this.state.cost_to_company)
                            : "-"}{" "}
                          {this.state.billing_currency}
                        </div>
                      </div>
                    )}
                  </div>
                  <ViewFiles
                    {...this.props}
                    files_data={this.state.files}
                    type="Costings"
                    invoice_file={this.state.invoice_file}
                  />
                </div>
              </div>
              {(this.state.features.includes("351") ||
                this.state.features.includes("97")) &&
                this.state.contract_type === "Per Barge" && (
                  <div className="card p-0 col-lg-12 border mt-3 mb-3">
                    <div className="card-header section_header">
                      <h5>Payment Postings</h5>
                    </div>
                    <div className="card-body pl-0">
                      <InvoiceForm
                        open={this.state.invoiceDialog}
                        data={this.state.invoiceDetails}
                        costingID={this.props.insurance_id}
                        costing_type={"Barge Insurance"}
                        callbackFn={(data) => this.updateInvoice(data)}
                      />
                      <div className="row">
                        <div className="col-lg-4 mb-3">
                          <label className="contract_display_header_label">
                            Total Payable
                          </label>
                          <div className="contract_display_header_value">
                            {this.state.total_payable
                              ? toLocaleString(this.state.total_payable)
                              : "-"}
                          </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label className="contract_display_header_label">
                            Total Paid
                          </label>
                          <div className="contract_display_header_value">
                            {this.state.total_payment_amount
                              ? toLocaleString(this.state.total_payment_amount)
                              : "-"}
                          </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label className="contract_display_header_label">
                            Remaining Amount
                          </label>
                          <div className="contract_display_header_value">
                            {this.state.remaining_amount
                              ? toLocaleString(this.state.remaining_amount)
                              : "-"}
                          </div>
                        </div>
                      </div>

                      {this.renderPaymentPostings(this.state)}
                    </div>
                  </div>
                )}
            </div>
          </div>

          <footer className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button btn-light"
              name="submit"
              onClick={() => {
                this.props.returnCallbackDrawerClose(false);
              }}
            >
              Close
            </button>
            <button
              type="button"
              className="drawer_text drawer_btn_success"
              name="Back"
              onClick={() => {
                this.props.returnEditCallback(true);
              }}
            >
              Edit
            </button>
          </footer>

          {/**
           *
           *  Delete Payment Postings Modal
           *
           */}

          {this.state.deletePayment && (
            <DeleteConfirmation
              open={this.state.deletePayment}
              close={() => this.setState({ deletePayment: false })}
              callbackFn={() =>
                this.deletePaymentPostingHandler(this.state.deleteData)
              }
            />
          )}
        </div>
      );
    }
  }
}
