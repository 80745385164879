import React, { Component } from "react";
import { Alert } from "@material-ui/lab";
import { Snackbar, Drawer } from "@material-ui/core";

import api from "../../../api/api";
import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import BusinessNoSalesCoalCostingAdd from "./BusinessNoSalesCoalCostingAdd";
import BusinessNoSalesCoalCostingView from "./BusinessNoSalesCoalCostingView";
import BusinessNoSalesCoalCostingUpdate from "./BusinessNoSalesCoalCostingUpdate";
import { pad } from "../../common/common";
import Loader from "../../common/Loader";

export default class BusinessNoSalesCoalCostingList extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      isLoading: true,
      features: [],
      showDrawer: false,
      BusinessNoSalesCoalCostingAdd: false,
      BusinessNoSalesCoalCostingView: false,
      BusinessNoSalesCoalCostingUpdate: false,
      purchaseBargeList: [],
      comboID: null,
      updateDocuments: false,
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    } else {
      const features = this.Cookie.getCookie("features");
      this.setState({ features: features });
      if (!(features.includes("191") || features.includes("97"))) {
        window.location.href = "/dashboard";
      } else {
        const loginUserID = this.Cookie.getCookie("loginUserId");
        const idToken = this.Cookie.getIdTokenCookie();
        const businessNoID = this.props.businessNoID;
        const costing_type = this.props.costing_type;
        // Get Purchase contract based Barges by Business number ID
        await api
          .get_sc_barge_coal_list(
            loginUserID,
            idToken,
            businessNoID,
            costing_type
          )
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              response.json().then((res) => {
                if (res.code === "200") {
                  if (res.sales_contracts) {
                    var sales_contracts = res.sales_contracts;
                    // let listCount = 0;
                    for (let i = 0; i < sales_contracts.length; i++) {
                      let barges = [];
                      let purchase_contracts_barges = sales_contracts[i].barges;
                      let newData = [];
                      for (
                        let k = 0;
                        k < purchase_contracts_barges.length;
                        k++
                      ) {
                        let id = purchase_contracts_barges[k].com_sales_fin_id;
                        if (id !== null) {
                          barges.push(purchase_contracts_barges[k]);
                        }
                      }
                      var result = barges.reduce(function (r, a) {
                        r[a.com_sales_fin_id] = r[a.com_sales_fin_id] || [];
                        r[a.com_sales_fin_id].push(a);
                        return r;
                      }, Object.create(null));
                      for (let l = 0; l < Object.keys(result).length; l++) {
                        newData.push({
                          new_barges: result[Object.keys(result)[l]],
                        });
                      }
                      sales_contracts[i].new_barges = newData;
                      // listCount += newData.length;
                    }
                    // if (listCount === 0) {
                    //   alert('No sales coal costings made');
                    //   // window.location.href = '/view-business/' + btoa(businessNoID);
                    // }
                    this.setState({
                      purchaseBargeList: sales_contracts,
                      isLoading: false,
                    });
                  } else {
                    this.setState({
                      isLoading: false,
                    });
                  }
                } else if (res.code === "601") {
                  this.setState({
                    snackBarErrorOpen: true,
                    errorMsg: res.message,
                  });
                } else if (res.code === "607") {
                  window.location.href = "/logout";
                }
              });
            } else {
              this.setState({
                isLoading: false,
              });
            }
          });
      }
      // this.callingAsync();
    }

    document.title =
      config.documentTitle + "Barge Sales Financial Coal Costing List";
  }

  toLocaleString(value) {
    return value ? Number(value).toLocaleString() : 0;
  }

  onSalesFinDelete = (com_sales_fin_id) => {
    this.setState({ isLoading: true });
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    //Delete Barge sales fiancials group/individual
    api
      .delete_barge_sales_financials_coal(
        loginUserID,
        idToken,
        com_sales_fin_id
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState(
                {
                  snackBarSuccessOpen: true,
                  succesMsg: "Barge Sales Financial data deleted successfully",
                },
                () => this.componentDidMount()
              );
              //alert('Barge Sales Financial data deleted successfully');
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            } else if (res.code === "651") {
              //alert(res.message);
              this.setState({
                isLoading: false,
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  };

  onAddResponse = (value) => {
    this.setState(
      {
        BusinessNoSalesCoalCostingAdd: false,
        comboID: null,
        showDrawer: false,
      },
      () => this.componentDidMount()
    );
  };

  onCancel = (value) => {
    if (value === false) {
      this.setState(
        {
          BusinessNoSalesCoalCostingAdd: false,
          BusinessNoSalesCoalCostingView: false,
          BusinessNoSalesCoalCostingUpdate: false,
          comboID: null,
          showDrawer: false,
          updateDocuments: false,
        },
        () => this.componentDidMount()
      );
    }
  };

  onBack = (value) => {
    if (value === false) {
      this.setState({
        BusinessNoSalesCoalCostingUpdate: false,
        BusinessNoSalesCoalCostingAdd: false,
        BusinessNoSalesCoalCostingView: true,
        updateDocuments: false,
      });
    }
  };

  onEdit = (value) => {
    if (value === true) {
      this.setState({
        BusinessNoSalesCoalCostingView: false,
        BusinessNoSalesCoalCostingAdd: false,
        BusinessNoSalesCoalCostingUpdate: true,
        showDrawer: true,
      });
    }
  };

  addDocuments = () => {
    this.setState({
      showDrawer: true,
      BusinessNoSalesCoalCostingUpdate: true,
      BusinessNoSalesCoalCostingView: false,
      BusinessNoSalesCoalCostingAdd: false,
      updateDocuments: true,
    });
  };

  render() {
    const businessNoID = this.props.businessNoID;
    const costing_type = this.props.costing_type;

    let drawerComponent;
    if (this.state.BusinessNoSalesCoalCostingAdd) {
      drawerComponent = (
        <BusinessNoSalesCoalCostingAdd
          businessNoID={businessNoID}
          costing_type={costing_type}
          onAddResponse={this.onAddResponse}
          onCancel={this.onCancel}
        />
      );
    } else if (
      this.state.BusinessNoSalesCoalCostingView &&
      this.state.comboID !== null
    ) {
      drawerComponent = (
        <BusinessNoSalesCoalCostingView
          comboID={this.state.comboID}
          onEdit={this.onEdit}
          onCancel={this.onCancel}
          returnDocumentsHandler={this.addDocuments}
        />
      );
    } else if (
      this.state.BusinessNoSalesCoalCostingUpdate &&
      this.state.comboID !== null
    ) {
      drawerComponent = (
        <BusinessNoSalesCoalCostingUpdate
          comboID={this.state.comboID}
          onBack={this.onCancel}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }
    return (
      <div id="" className="col-lg row p-2">
        <div className="content">
          {this.state.isLoading && <Loader />}
          <div
            className="content-header"
            style={{ width: "-webkit-fill-available" }}
          >
            <div className="row col-sm text-left pl-0">
              <h6 className="p-2">{`Barge Sales Commercial Coal Costing - ${
                this.props.mother_vessel_name
                  ? this.props.mother_vessel_name
                  : ""
              } ${this.props.name ? `( ${this.props.name} )` : ""}`}</h6>
              {(this.state.features.includes("190") ||
                this.state.features.includes("97")) && (
                <i
                  onClick={() =>
                    this.setState({
                      showDrawer: true,
                      BusinessNoSalesCoalCostingAdd: true,
                    })
                  }
                  className="fa fa-plus-circle mr-2 mt-2"
                  aria-hidden="true"
                  style={{
                    fontSize: 22,
                    color: config.themeColor,
                    cursor: "pointer",
                  }}
                />
              )}
            </div>
            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={this.state.snackBarErrorOpen}
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              style={{ width: "450px" }}
            >
              <Alert
                elevation={6}
                variant="filled"
                onClose={() => this.setState({ snackBarErrorOpen: false })}
                severity="error"
                style={{ width: "100%" }}
              >
                {this.state.errorMsg}
              </Alert>
            </Snackbar>
            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              style={{ width: "450px" }}
              open={this.state.snackBarSuccessOpen}
              autoHideDuration={100000}
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
            >
              <Alert
                elevation={6}
                variant="filled"
                onClose={() => this.setState({ snackBarSuccessOpen: false })}
                severity="success"
                style={{ width: "100%" }}
              >
                {this.state.succesMsg}
              </Alert>
            </Snackbar>
            <Drawer
              anchor="right"
              open={this.state.showDrawer}
              variant="temporary"
              elevation={20}
              style={{ overflow: "initial" }}
            >
              <div className="row" style={{ width: 800 }}>
                {drawerComponent}
              </div>
            </Drawer>
            <div className="container p-0">
              <div className="card p-2">
                {this.state.purchaseBargeList.map((p, index) => (
                  <div key={index}>
                    {p.new_barges.length > 0 && (
                      <h6 className="section_header bg-white">
                        {p.sales_contract_no} -{" "}
                        {p.quality ? "(" + p.quality + ")" : ""} -{" "}
                        {p.sales_type} - {p.buyer_name}
                      </h6>
                    )}
                    {p.new_barges.length > 0 && (
                      <div className="row">
                        <div className="table-responsive">
                          <table className="table table-bordered table-sm">
                            <thead className="table-header">
                              <tr>
                                <th nowrap="true"></th>
                                <th nowrap="true">Costing ID </th>
                                <th nowrap="true"> Barge ID </th>
                                <th nowrap="true"> Allocation ID </th>
                                <th nowrap="true">Barge Nomination </th>
                                <th nowrap="true" className="text-right">
                                  {" "}
                                  Quantity
                                </th>
                                <th nowrap="true" className="text-right">
                                  {" "}
                                  Price PMT
                                </th>
                                <th nowrap="true" className="text-right">
                                  Adjusted Coal Price
                                </th>
                                <th nowrap="true"> VAT </th>
                                <th nowrap="true"> PPH22 </th>
                                <th nowrap="true"> Advance Adjustement </th>
                                <th nowrap="true"> Total Receivable </th>
                                <th nowrap="true">
                                  {" "}
                                  Received against Performa{" "}
                                </th>
                                <th nowrap="true"> Total Invoiced </th>
                                <th nowrap="true"> Received </th>
                                <th nowrap="true"> Remaining</th>
                              </tr>
                            </thead>
                            {p.new_barges.map((id, idx) => (
                              <tbody key={idx}>
                                {id.new_barges.map((idr, indx) => (
                                  <tr key={indx}>
                                    {indx === 0 && (
                                      <td
                                        nowrap="true"
                                        rowSpan={id.new_barges.length}
                                        style={{
                                          borderRight: "1px solid #dee2e6",
                                          borderLeft: "1px solid #dee2e6",
                                        }}
                                      >
                                        {
                                          (this.state.features.includes(
                                            "191"
                                          ) >= 0 ||
                                            this.state.features.includes(
                                              "97"
                                            ) >= 0) && (
                                            <i
                                              className="fa fa-eye mr-2"
                                              aria-hidden="true"
                                              style={{
                                                fontSize: 22,
                                                color: config.themeColor,
                                                cursor: "pointer",
                                              }}
                                              onClick={() =>
                                                this.setState({
                                                  BusinessNoSalesCoalCostingView: true,
                                                  comboID:
                                                    idr.com_sales_fin_id +
                                                    "$#" +
                                                    businessNoID +
                                                    "$#" +
                                                    idr.business_allocation_id +
                                                    "$#" +
                                                    idr.business_no_barge_id,
                                                  showDrawer: true,
                                                })
                                              }
                                            ></i>
                                          )
                                          // <a href={"/business-number-sales-coal-costing-view/" + btoa(idr.com_sales_fin_id + '$#' + businessNoID + '$#' + idr.business_allocation_id + '$#' + idr.business_no_barge_id)}>
                                          //   <i className="fa fa-eye mr-2" aria-hidden="true"
                                          //     style={{ fontSize: 22, color: '#3256a8', cursor: 'pointer' }}></i>
                                          // </a>
                                        }
                                        {(this.state.features.includes("193") >=
                                          0 ||
                                          this.state.features.includes("97") >=
                                            0) && (
                                          <i
                                            className="fa fa-trash-o"
                                            style={{
                                              fontSize: 20,
                                              color: config.themeColor,
                                              cursor: "pointer",
                                            }}
                                            data-toggle="modal"
                                            title="Delete"
                                            data-placement="bottom"
                                            data-keyboard="false"
                                            data-backdrop="static"
                                            onClick={() =>
                                              this.onSalesFinDelete(
                                                idr.com_sales_fin_id
                                              )
                                            }
                                          ></i>
                                        )}
                                      </td>
                                    )}
                                    {indx === 0 && (
                                      <td rowSpan={id.new_barges.length}>
                                        {idr.com_sales_fin_seq_id}
                                      </td>
                                    )}
                                    <td>{pad(idr.business_no_barge_id)}</td>
                                    <td>{idr.display_allocation_id}</td>
                                    <td nowrap="true">
                                      {idr.barge_nomination}
                                    </td>
                                    {indx === 0 && (
                                      <td
                                        className="allocation_table_value text-right"
                                        rowSpan={id.new_barges.length}
                                        style={{
                                          borderRight: "1px solid #dee2e6",
                                          borderLeft: "1px solid #dee2e6",
                                        }}
                                        nowrap="true"
                                      >
                                        {this.toLocaleString(
                                          idr.coal_costing_quantity
                                        )}
                                      </td>
                                    )}
                                    {indx === 0 && (
                                      <td
                                        className="allocation_table_value text-right"
                                        rowSpan={id.new_barges.length}
                                        style={{
                                          borderRight: "1px solid #dee2e6",
                                          borderLeft: "1px solid #dee2e6",
                                        }}
                                        nowrap="true"
                                      >
                                        {this.toLocaleString(
                                          idr.adjusted_coal_price_pmt
                                        )}
                                      </td>
                                    )}
                                    {indx === 0 && (
                                      <td
                                        className="allocation_table_value text-right"
                                        rowSpan={id.new_barges.length}
                                        style={{
                                          borderRight: "1px solid #dee2e6",
                                          borderLeft: "1px solid #dee2e6",
                                        }}
                                        nowrap="true"
                                      >
                                        {this.toLocaleString(
                                          idr.adjusted_coal_price
                                        )}
                                      </td>
                                    )}
                                    {indx === 0 && (
                                      <td
                                        className="allocation_table_value text-right"
                                        rowSpan={id.new_barges.length}
                                        style={{
                                          borderRight: "1px solid #dee2e6",
                                          borderLeft: "1px solid #dee2e6",
                                        }}
                                        nowrap="true"
                                      >
                                        {this.toLocaleString(idr.vat_value)}
                                      </td>
                                    )}
                                    {indx === 0 && (
                                      <td
                                        className="allocation_table_value text-right"
                                        rowSpan={id.new_barges.length}
                                        style={{
                                          borderRight: "1px solid #dee2e6",
                                          borderLeft: "1px solid #dee2e6",
                                        }}
                                        nowrap="true"
                                      >
                                        {this.toLocaleString(idr.pph_value)}
                                      </td>
                                    )}
                                    {indx === 0 && (
                                      <td
                                        className="allocation_table_value text-right"
                                        rowSpan={id.new_barges.length}
                                        style={{
                                          borderRight: "1px solid #dee2e6",
                                          borderLeft: "1px solid #dee2e6",
                                        }}
                                        nowrap="true"
                                      >
                                        {this.toLocaleString(
                                          idr.adv_adjustments
                                        )}
                                      </td>
                                    )}
                                    {indx === 0 && (
                                      <td
                                        className="allocation_table_value text-right"
                                        rowSpan={id.new_barges.length}
                                        style={{
                                          borderRight: "1px solid #dee2e6",
                                          borderLeft: "1px solid #dee2e6",
                                        }}
                                        nowrap="true"
                                      >
                                        {this.toLocaleString(idr.total_payable)}
                                      </td>
                                    )}
                                    {indx === 0 && (
                                      <td
                                        className="allocation_table_value text-right"
                                        rowSpan={id.new_barges.length}
                                        style={{
                                          borderRight: "1px solid #dee2e6",
                                          borderLeft: "1px solid #dee2e6",
                                        }}
                                        nowrap="true"
                                      >
                                        {this.toLocaleString(
                                          idr.performa_payments_amount
                                        )}
                                      </td>
                                    )}
                                    {indx === 0 && (
                                      <td
                                        className="allocation_table_value text-right"
                                        rowSpan={id.new_barges.length}
                                        style={{
                                          borderRight: "1px solid #dee2e6",
                                          borderLeft: "1px solid #dee2e6",
                                        }}
                                        nowrap="true"
                                      >
                                        {this.toLocaleString(
                                          idr.total_invoiced
                                        )}
                                      </td>
                                    )}
                                    {indx === 0 && (
                                      <td
                                        className="allocation_table_value text-right"
                                        rowSpan={id.new_barges.length}
                                        style={{
                                          borderRight: "1px solid #dee2e6",
                                          borderLeft: "1px solid #dee2e6",
                                        }}
                                        nowrap="true"
                                      >
                                        {this.toLocaleString(idr.paid)}
                                      </td>
                                    )}
                                    {indx === 0 && (
                                      <td
                                        className="allocation_table_value text-right"
                                        rowSpan={id.new_barges.length}
                                        style={{
                                          borderRight: "1px solid #dee2e6",
                                          borderLeft: "1px solid #dee2e6",
                                        }}
                                        nowrap="true"
                                      >
                                        {this.toLocaleString(
                                          idr.remaining_amount
                                        )}
                                      </td>
                                    )}
                                  </tr>
                                ))}
                              </tbody>
                            ))}
                          </table>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
