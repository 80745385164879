import React, { Component } from "react";
import CookieHandler from "../common/CookieHandler";
import config from "../../config/config";
import api from "../../api/api";
import { Popover, Snackbar, Drawer, Button } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import BuyerDemurrageAdd from "./Buyer-Demurrage-Add";
import BuyerDemurrageUpdate from "./Buyer-Demurrage-Update";
import BuyerDemurrageView from "./Buyer-Demurrage-View";
import DeleteConfirmation from "../common/DeleteConfirmation";
import { pad } from "../common/common";
import Loader from "../common/Loader";

export default class BargeBuyerDemurrageList extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      showDrawer: false,
      isLoading: true,
      features: [],
      purchaseBargeList: [],
      buyer_demurrages: [],
      demurrage_type: "company_to_buyer",
    };
  }

  componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (
      !(
        features.includes("53") ||
        features.includes("151") ||
        features.includes("97")
      )
    ) {
      window.location.href = "/dashboard";
    } else {
      this.companyToBuyerDemurrages();
    }
  }

  companyToBuyerDemurrages = () => {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const business_no_id = this.props.businessNo;
    api
      .get_barge_demurrage_to_buyers(loginUserID, idToken, business_no_id)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState({
                buyer_demurrages: res.barge_demurrage_to_buyer,
                isLoading: false,
              });
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                successMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  };

  buyerToCompanyDemurrages = () => {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const business_no_id = this.props.businessNo;

    api
      .get_barge_demurrage_from_buyers(loginUserID, idToken, business_no_id)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState({
                buyer_demurrages: res.barge_demurrage_from_buyer,
                isLoading: false,
              });
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                successMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  };

  deleteDemurrageHandler = (demurrage_id) => {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();

    if (this.state.demurrage_type === "buyer_to_company") {
      api
        .delete_barge_demurrage_from_buyer(loginUserID, idToken, demurrage_id)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                this.setState(
                  {
                    deleteCosting: false,
                    snackBarSuccessOpen: true,
                    successMsg: res.message,
                  },
                  () => {
                    this.buyerToCompanyDemurrages();
                  }
                );
              } else if (res.code === "601") {
                this.setState({
                  snackBarErrorOpen: true,
                  errorMsg: res.message,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              } else if (res.code === "650") {
                this.setState({
                  deleteCosting: false,
                  snackBarErrorOpen: true,
                  errorMsg: res.message,
                });
              }
            });
          }
        });
    } else if (this.state.demurrage_type === "company_to_buyer") {
      api
        .delete_barge_demurrage_to_buyer(loginUserID, idToken, demurrage_id)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                this.setState(
                  {
                    deleteCosting: false,
                    snackBarSuccessOpen: true,
                    successMsg: res.message,
                  },
                  () => {
                    this.companyToBuyerDemurrages();
                  }
                );
              } else if (res.code === "601") {
                this.setState({
                  snackBarErrorOpen: true,
                  errorMsg: res.message,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              } else if (res.code === "650") {
                this.setState({
                  deleteCosting: false,
                  snackBarErrorOpen: true,
                  errorMsg: res.message,
                });
              }
            });
          }
        });
    }
  };

  onAddResponse = (value) => {
    this.setState({ buyerDemurrageAdd: false, showDrawer: false }, () => {
      if (this.state.demurrage_type === "company_to_buyer") {
        this.companyToBuyerDemurrages();
      } else if (this.state.demurrage_type === "buyer_to_company") {
        this.buyerToCompanyDemurrages();
      }
    });
  };

  editCallback = (childData) => {
    this.setState({
      showDrawer: true,
      buyerDemurrageAdd: false,
      buyerDemurrageUpdate: true,
      buyerDemurrageView: false,
    });
  };

  onUpdateResponse = (value) => {
    if (value) {
      this.setState({
        showDrawer: true,
        buyerDemurrageAdd: false,
        buyerDemurrageView: true,
        buyerDemurrageUpdate: false,
      });
    }
  };

  CallbackViewPage = (childData) => {
    this.setState({
      showDrawer: true,
      buyerDemurrageView: true,
      buyerDemurrageUpdate: false,
    });
  };

  CallbackDrawerClose = (childData) => {
    this.setState({
      showDrawer: childData,

      buyerDemurrageAdd: false,
      supplierDemurrageView: false,
    });
  };

  toLocaleString(value) {
    return value ? Number(value).toLocaleString() : 0;
  }

  render() {
    let drawerComponent;
    if (this.state.buyerDemurrageAdd && this.props.businessNo) {
      drawerComponent = (
        <BuyerDemurrageAdd
          businessNo={this.props.businessNo}
          demurrage_type={this.state.demurrage_type}
          onAddResponse={this.onAddResponse}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
        />
      );
    }
    if (this.state.buyerDemurrageView) {
      drawerComponent = (
        <BuyerDemurrageView
          businessNo={this.props.businessNo}
          demurrageData={this.state.demurrageData}
          demurrage_type={this.state.demurrage_type}
          comboID={this.state.comboID}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.editCallback}
        />
      );
    }
    if (this.state.buyerDemurrageUpdate) {
      drawerComponent = (
        <BuyerDemurrageUpdate
          businessNo={this.props.businessNo}
          demurrageData={this.state.demurrageData}
          demurrage_type={this.state.demurrage_type}
          comboID={this.state.comboID}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.CallbackViewPage}
        />
      );
    }
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div className="row w-100">
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>

          <Drawer
            anchor="right"
            open={this.state.showDrawer}
            variant="temporary"
            elevation={20}
            style={{ overflow: "initial" }}
          >
            <div className="row" style={{ width: 800 }}>
              {drawerComponent}
            </div>
          </Drawer>
          <div className="card mt-2 p-2 w-100">
            <div className="row">
              <div className="col pl-0">
                <Button
                  className="tab_button"
                  onClick={(e, value) => {
                    this.setState(
                      {
                        demurrage_type: "company_to_buyer",
                      },
                      () => this.companyToBuyerDemurrages()
                    );
                  }}
                  style={{
                    textTransform: "capitalize",
                    margin: "5px 0px",
                    padding: "5px 20px",
                    //borderRadius: 35,
                    height: 30,
                    color:
                      this.state.demurrage_type === "company_to_buyer"
                        ? config.themeColor
                        : "#483b34b3",
                    backgroundColor:
                      this.state.demurrage_type === "company_to_buyer"
                        ? "#fff7f2"
                        : "#fff",
                    fontSize: "14px",
                    border: "0px",
                  }}
                >
                  {config.company} To Buyer
                </Button>
                <Button
                  className="tab_button"
                  onClick={(e, value) => {
                    this.setState(
                      {
                        demurrage_type: "buyer_to_company",
                      },
                      () => this.buyerToCompanyDemurrages()
                    );
                  }}
                  style={{
                    textTransform: "capitalize",
                    margin: "5px 0px",
                    padding: "5px 20px",
                    //borderRadius: 35,
                    height: 30,
                    color:
                      this.state.demurrage_type === "buyer_to_company"
                        ? config.themeColor
                        : "#483b34b3",
                    backgroundColor:
                      this.state.demurrage_type === "buyer_to_company"
                        ? "#fff7f2"
                        : "#fff",
                    fontSize: "14px",
                    border: "0px",
                  }}
                >
                  Buyer To {config.company}
                </Button>
              </div>
              <div className="col text-right float-sm-left pr-0">
                <button
                  className="header_button header_button_text add_button_adjustment"
                  style={{ width: "auto", marginTop: "25px" }}
                  onClick={(event) =>
                    this.setState({
                      menuForDemurrage: true,
                      anchorEl: event.currentTarget,
                    })
                  }
                >
                  New Demurrage
                </button>
              </div>
            </div>
            {this.state.buyer_demurrages.length > 0 ? (
              <div className="row">
                <div className="pt-3 lr-3 table-responsive ">
                  <table className="table table-sm table-bordered">
                    <thead className='table-header'>
                      <tr>
                        <th nowrap="true"> Costing ID </th>
                        <th nowrap="true"> Barge ID</th>
                        <th nowrap="true"> Barge Nomination </th>
                        <th nowrap="true"> Buyer </th>
                        <th nowrap="true">
                          {this.state.demurrage_type === "company_to_buyer"
                            ? "Total Payable"
                            : "Receivable"}
                        </th>
                        <th nowrap="true">
                          {this.state.demurrage_type === "company_to_buyer"
                            ? "Paid"
                            : "Received"}
                        </th>
                        <th nowrap="true"> Remaining </th>
                        <th nowrap="true"> Action </th>
                      </tr>
                    </thead>
                    {this.state.buyer_demurrages.map((id, idx) => (
                      <tbody key={idx}>
                        <tr>
                          <td nowrap="true">{id.costing_no}</td>
                          <td>{pad(id.barge_id)}</td>
                          <td nowrap="true">
                            {id.barge_nomination ? id.barge_nomination : "-"}
                          </td>
                          <td nowrap="true">
                            {id.supplier_name ? id.supplier_name : "-"}
                          </td>
                          <td className="text-right" nowrap="true">
                            {this.toLocaleString(id.total_payable)}
                          </td>
                          <td className="text-right" nowrap="true">
                            {this.toLocaleString(id.paid)}
                          </td>
                          <td className="text-right" nowrap="true">
                            {this.toLocaleString(id.Remaining_amount)}
                          </td>
                          <td className="text-right">
                            <i
                              className="fa fa-eye mr-2"
                              aria-hidden="true"
                              style={{
                                fontSize: 22,
                                color: config.themeColor,
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                this.setState({
                                  buyerDemurrageView: true,
                                  comboID: id.id + "$#" + id.barge_id,
                                  showDrawer: true,
                                  demurrageData: id,
                                })
                              }
                            ></i>
                            {(this.state.features.includes("129") ||
                              this.state.features.includes("97")) &&
                              id.approval_status === "Pending" && (
                                <i
                                  className="fa fa-trash-o"
                                  style={{
                                    fontSize: 20,
                                    color: config.themeColor,
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    this.setState({
                                      deleteCosting: true,
                                      demurrage_id: id.id,
                                      closeDialog: true,
                                    })
                                  }
                                ></i>
                              )}
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </table>
                </div>
              </div>
            ) : (
              <div>No records to display</div>
            )}
          </div>

          <Popover
            open={this.state.menuForDemurrage}
            anchorEl={this.state.anchorEl}
            anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
            targetorigin={{ horizontal: "left", vertical: "top" }}
            onClose={() =>
              this.setState({ menuForDemurrage: false, showDrawer: false })
            }
          >
            <div className="card border">
              <Button
                className="mr-1 mb-3 specs_button"
                onClick={() => {
                  this.setState({
                    buyerDemurrageAdd: true,
                    buyerDemurrageView: false,
                    buyerDemurrageUpdate: false,
                    showDrawer: true,
                    menuForDemurrage: false,
                    demurrage_type: "company_to_buyer",
                  });
                }}
                style={{
                  width: "100%",
                  textTransform: "capitalize",
                  height: 30,
                  color:
                    this.state.demurrage_type === "company_to_buyer"
                      ? config.themeColor
                      : "#483b34b3",
                  backgroundColor: "#eee",
                  fontSize: "14px",
                  border: "0px",
                }}
              >
                {config.company} to Buyer
              </Button>
              <Button
                className="mr-1 specs_button"
                onClick={() => {
                  this.setState({
                    buyerDemurrageAdd: true,
                    buyerDemurrageView: false,
                    buyerDemurrageUpdate: false,
                    showDrawer: true,
                    menuForDemurrage: false,
                    demurrage_type: "buyer_to_company",
                  });
                }}
                style={{
                  width: "100%",
                  textTransform: "capitalize",
                  height: 30,
                  color:
                    this.state.demurrage_type === "buyer_to_company"
                      ? config.themeColor
                      : "#483b34b3",
                  backgroundColor: "#eee",
                  fontSize: "14px",
                  border: "0px",
                }}
              >
                Buyer to {config.company}
              </Button>
            </div>
          </Popover>
          {/**
           *
           * Delete Payament Postings Conformation Modal
           *
           */}

          {this.state.deleteCosting && (
            <DeleteConfirmation
              open={this.state.deleteCosting}
              close={() => this.setState({ deleteCosting: false })}
              callbackFn={() =>
                this.deleteDemurrageHandler(this.state.demurrage_id)
              }
            />
          )}
        </div>
      );
    }
  }
}
