import React, { Component } from "react";
import SideBar from "../common/SideBar";
import Header from "../common/Header";
import CookieHandler from "../common/CookieHandler";
import {
  MenuItem,
  TextField,
  IconButton,
  InputAdornment,
  Radio,
  Checkbox,
} from "@material-ui/core";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import EventIcon from "@material-ui/icons/Event";
import EditIcon from "@material-ui/icons/Edit";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import api from "../../api/api";
import config from "../../config/config";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DeleteIcon from "@material-ui/icons/Delete";
import LinearProgress from "@material-ui/core/LinearProgress";
import CircularProgress from "@material-ui/core/CircularProgress";
import Drawer from "@material-ui/core/Drawer";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert } from "@material-ui/lab";
import { withStyles } from "@material-ui/core/styles";
import { dateFormateToDB, localDateFormate } from "../common/common";
import Loader from "../common/Loader";

const CheckBox = withStyles({
  root: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const NewRadio = withStyles({
  root: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 6,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#42352d",
  },
}))(LinearProgress);

export default class NewBusinessNumber extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      businessType: "0",
      activeStep: 0,
      consignmentType: "0",
      motherVesselName: "",
      oldBusinessNo: "",
      PC_specification_standard: "",
      bargeName: "",
      shipmentType: "0",
      textAreaMaxCount: 2000,
      indexLinked: "No",
      vessel_Type: "0",
      stevedore_vendor: "",
      floating_crane_vendor: "",
      floatingCrane_name: "",
      loading_Anchorage: "",
      loadingPort_agent: "",
      loading_rate: "",
      vessel_freight: "",
      vessel_freight_price: "",
      shipmentLaycan: "",
      vessel_arrived_on: null,
      ETA: null,
      nor_acceptance: null,
      Documents_boardDate: null,
      vessel_sailingDate: null,
      demurrage_rate_from_buyer: "",
      demurrage_rate_to_supplier: "",
      stowagePlan: "",
      supplyTonnage: "",
      stevedore_values: "",
      floating_crane_vendor_values: "",
      remarks: "",
      popperID: null,
      businessFlow: "",
      supplierReferenceNumber: "",
      buy_back_involved: "No",
      quantityRows: [],
      purchaseTypeData: [],
      salesTypeData: [],
      purchaseContractNo: null,
      salesContractNo: null,
      business_head: null,
      // quantityRows: [
      //   {
      //     s_no: '0', purchase_contract: '', purchase_contractID: '', purchase_contractError: false, purchase_quality: [], purchase_types: [],
      //     purchase_procurement: '0', purchase_procurementError: false, purchase_quantityAllocating: '',
      //     sales_contract: '', sales_contractID: '', sales_contractError: false, sales_quality: [], sales_types: [],
      //     sales_sellingQuality: '0', quantityTobe_Delivered: '', quantityAllocationError: false, quantity_deliveredError: false
      //   }
      // ],

      motherVesselDropdown: [
        <MenuItem value="FAS MV" key="0">
          FAS MV
        </MenuItem>,
        <MenuItem value="FOB MV" key="1">
          FOB MV
        </MenuItem>,
        <MenuItem value="CFR MV" key="2">
          CFR MV
        </MenuItem>,
        <MenuItem value="CIF MV" key="3">
          CIF MV
        </MenuItem>,
      ],
      bargeDropdown: [
        <MenuItem value="FOB Barge" key="0">
          FOB Barge
        </MenuItem>,
        <MenuItem value="CFR Barge" key="1">
          CFR Barge
        </MenuItem>,
        <MenuItem value="CIF Barge" key="2">
          CIF Barge
        </MenuItem>,
      ],
      purchaseDataForMenu: [],
      purchaseContractList: [],
      purchaseTypes: [],
      purchaseTypeSelection: null,
      anchorEl: null,
      purchaseTypeDataShow: false,
      modalID: "",
      editingIndex: 0,
      enableEditingDrawer: false,

      salesDataforMenu: [],
      salesContractsList: [],
      salesTypes: [],
      salesTypeSelection: null,
      addRowEnable: false,
      salesTypeDataShow: false,
      purchaseQualityLoading: false,
      salesQualityLoading: false,
      displayQuntityAllocations: false,
      quantityAllocationHelperText: "",

      isLoading: true,

      businessTypeError: false,
      consignmentTypeError: false,
      shipmentTypeError: false,
      purchaseContractEnable: false,
      salesContractEnable: false,
      openQuantityAllocation: false,
      snackBarSuccessOpen: false,
      snackBarErrorOpen: false,
      errorMsg: "",
      successMsg: "",

      features: [],
      users_list_menu: [],
    };
  }

  handleSuccessClose = () => {
    this.setState({ snackBarSuccessOpen: false });
  };

  handleErrorClose = () => {
    this.setState({ snackBarErrorOpen: false });
  };

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    } else {
      var features = this.Cookie.getCookie("features");
      this.setState({ features: features });
      if (!(features.includes("37") || features.includes("97"))) {
        window.location.href = "/dashboard";
      }
      //calling get_purchase_contracts API
      var loginUserID = this.Cookie.getCookie("loginUserId");
      var idToken = this.Cookie.getIdTokenCookie();
      var status = "";
      // Get venedors API
      await api.getVendors(loginUserID, idToken, status).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.vendors) {
                const vendorTypeOtherData = [];
                const vesselOwner = [];
                for (var i in res.vendors) {
                  if (
                    res.vendors[i].vendor_type === "Others" ||
                    res.vendors[i].vendor_type === "Stevedore" ||
                    res.vendors[i].vendor_type === "Floating Crane" ||
                    res.vendors[i].vendor_type === "Vessel Agent"
                  ) {
                    vendorTypeOtherData.push({
                      name: res.vendors[i].vendor_name,
                      value: res.vendors[i].id,
                      key: i,
                    });
                  }
                  if (
                    res.vendors[i].vendor_type === "Vessel Owner" ||
                    res.vendors[i].vendor_type === "Vessel Agent"
                  ) {
                    vesselOwner.push({
                      name: res.vendors[i].vendor_name,
                      value: res.vendors[i].id,
                      key: i,
                    });
                  }
                }
                this.setState({
                  vendorsAsOther: vendorTypeOtherData,
                  vesselOwner: vesselOwner,
                });
              }
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
      // Get purchase contracts API
      await api
        .getPurchaseContracts(loginUserID, status, idToken)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
              if (res.purchase_contract) {
                let purchaseDataforMenu = [];
                const filteredPContracts = res.purchase_contract.filter(
                  (e, indx) => e.status !== "Closed" && e.status !== "cancelled"
                );
                for (let i = 0; i < filteredPContracts.length; i++) {
                  purchaseDataforMenu.push({
                    name: filteredPContracts[i].contract_no,
                    value: filteredPContracts[i].contract_no,
                    key: i,
                    vendor_name: filteredPContracts[i].vendor_name,
                  });
                }

                this.setState({
                  purchaseDataForMenu: purchaseDataforMenu,
                  //purchaseContractList: res.purchase_contract,
                  purchaseContractList: filteredPContracts,
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            });
          } else if (response.status === 607) {
            window.location.href = "/logout";
          }
        });

      await api.getUserList(loginUserID, idToken, status).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.users) {
              let usersList = [];
              res.users.forEach((item) => {
                return usersList.push({
                  name: item.user_name,
                  value: item.user_id,
                });
              });
              this.setState({ users_list_menu: usersList });
            }
          });
        }
      });
      // Get sales contracts API
      await api
        .getSalesContractList(loginUserID, idToken, status)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
              if (res.sale_contract) {
                const salesDataforMenu = [];

                const filteredSContracts = res.sale_contract.filter(
                  (e, indx) => (e.status !== "Closed" && e.status !== "cancelled" )               );
                for (let i = 0; i < filteredSContracts.length; i++) {
                  salesDataforMenu.push({
                    name: filteredSContracts[i].contract_no,
                    value: filteredSContracts[i].contract_no,
                    key: i,
                    customer_name: filteredSContracts[i].customer_name,
                  });
                }
                this.setState({
                  salesDataforMenu: salesDataforMenu,
                  //salesContractsList: res.sale_contract,
                  salesContractsList: filteredSContracts,
                  isLoading: false,
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            });
          }
        });
    }

    document.title = config.documentTitle + "Business Creation";
  }

  ETADateHandler = (date) => {
    this.setState({
      ETA: dateFormateToDB(date),
    });
  };

  handleDecimalField = (e) => {
    if (e.target.name === "vessel_freight") {
      const re = /^\d*(\.\d{0,3})?$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        this.setState({
          [e.target.name]: e.target.value,
        });
      }
    }
    if (e.target.name === "stowagePlan") {
      const re = /^\d*(\.\d{0,3})?$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        this.setState({
          [e.target.name]: e.target.value,
        });
      }
    }
    if (e.target.name === "supplyTonnage") {
      const re = /^\d*(\.\d{0,3})?$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        this.setState({
          [e.target.name]: e.target.value,
        });
      }
    }
    if (e.target.name === "loading_rate") {
      const re = /^\d*(\.\d{0,3})?$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        this.setState({
          [e.target.name]: e.target.value,
        });
      }
    }
  };

  handleText = (e) => {
    if (e.target.name === "businessType") {
      this.setState({
        [e.target.name]: e.target.value,
        businessTypeError: false,
      });
    } else if (e.target.name === "consignmentType") {
      this.setState({
        [e.target.name]: e.target.value,
        shipmentType: "0",
        consignmentTypeError: false,
      });
    } else if (e.target.name === "shipmentType") {
      this.setState({
        [e.target.name]: e.target.value,
        shipmentTypeError: false,
      });
    } else if (e.target.name === "businessFlow") {
      this.setState({
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "supplierReferenceNumber") {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  addMoreQuantityRows = () => {
    const item = {
      purchase_contract: "",
      purchase_contractID: "",
      purchase_contractError: false,
      purchase_quality: [],
      purchase_types: [],
      purchase_procurement: "0",
      purchase_quality_standard: "0",
      purchase_procurementError: false,
      purchase_quantityAllocating: "",
      sales_contract: "",
      sales_contractID: "",
      sales_contractError: false,
      sales_quality: [],
      sales_types: [],
      sales_sellingQuality: "0",
      sales_quality_standard: "0",
      quantityTobe_Delivered: "",
      quantityAllocationError: false,
      quantity_deliveredError: false,
    };
    let quantityRows = [...this.state.quantityRows, item];
    this.setState({
      quantityRows: quantityRows,
      openDrawer: true,
      purchaseContractNo: null,
      purchaseContractEnable: true,
      quantityAllocationEnable: false,
      modalID: quantityRows.length - 1,
      addRowEnable: false,
    });
  };

  removeQuantityRowsHandler = (index) => () => {
    const rows = this.state.quantityRows;
    rows.splice(index, 1);
    this.setState({ quantityRows: rows });
  };

  inlineHandlePurchaseContract = (index, value) => {
    this.setState(
      {
        purchaseTypeSelection: null,
        qualitySelection: null,
      },
      () => {
        this.handlePurchaseContract(index, value);
      }
    );
  };

  handlePurchaseContract = (index, value) => {
    var row = this.state.quantityRows;
    if (value) {
      this.setState({ modalID: index, purchaseQualityLoading: true });
      var loginUserID = this.Cookie.getCookie("loginUserId");
      var idToken = this.Cookie.getIdTokenCookie();
      var status = "";
      window.$("#demo" + this.state.qualitySelection).collapse("hide");
      row[index].purchase_contract = value.name;
      row[index].purchase_contractError = false;
      var contractNo = value.value;
      var filteredContract = this.state.purchaseContractList.filter(
        (e, index) => e.contract_no === contractNo
      );
      var purchaseContractId = filteredContract[0].pcid;
      row[index].purchase_contractID = purchaseContractId;
      api
        .getPurchaseContract(loginUserID, purchaseContractId, idToken, status)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
              if (res.purchase_contract) {
                // window.$("#myModal").modal({
                //   backdrop: 'static',
                //   keyboard: true,
                //   show: true
                // });
                this.setState({
                  purchaseContractNo: contractNo,
                  purchaseTypes: res.purchase_contract.qualities,
                  purchaseTypeData: res.purchase_contract.qualities,
                  purchaseCurrency: res.purchase_contract.currency,
                  purchaseQualityLoading: false,
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            });
          }
        });
    } else {
      row[index].purchase_contract = "";
      row[index].purchase_types = [];
      row[index].purchase_quality = [];
    }
    this.setState({
      quantityRows: row,
    });
  };

  inlineHandleSalesContract = (index, value) => {
    this.setState(
      {
        salesTypeSelection: null,
        salesQualitySelection: null,
      },
      () => {
        this.handleSalesContract(index, value);
      }
    );
  };

  handleSalesContract = (index, value) => {
    var row = this.state.quantityRows;
    if (value) {
      this.setState({ modalID: index, salesQualityLoading: true });
      var loginUserID = this.Cookie.getCookie("loginUserId");
      var idToken = this.Cookie.getIdTokenCookie();
      var status = "Active";
      window.$("#sales" + this.state.salesQualitySelection).collapse("hide");
      row[index].sales_contract = value.value;
      row[index].sales_contractError = false;
      let contractNo = value.value;
      var filteredSalesContract = this.state.salesContractsList.filter(
        (e, index) => e.contract_no === contractNo
      );
      var salesContractId = filteredSalesContract[0].scid;
      row[index].sales_contractID = salesContractId;
      api
        .getSalesContract(loginUserID, salesContractId, idToken, status)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
              if (res.sales_contract) {
                this.setState({
                  salesContractNo: contractNo,
                  salesTypes: res.sales_contract.qualities,
                  salesTypeData: res.sales_contract.qualities,
                  salesCurrency: res.sales_contract.currency,
                  salesQualityLoading: false,
                });
                window.$("#salesContractModal").modal({
                  backdrop: "static",
                  keyboard: true,
                  show: true,
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            });
          }
        });
    } else {
      row[index].sales_contract = "";
      row[index].sales_types = [];
      row[index].sales_quality = [];
    }
    this.setState({
      quantityRows: row,
    });
  };

  handleQuantityRowsTextHandler = (index) => (e) => {
    this.setState({ modalID: index });
    var row = this.state.quantityRows;
    if (e.target.name === "purchaseProcurement") {
      row[index].purchase_procurement = e.target.value;
      row[index].purchase_procurementError = false;
    }
    if (e.target.name === "purchase_quantityAllocating") {
      const re = /^\d*(\.\d{0,3})?$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        var value = e.target.value;
        var purchaseValue =
          row[index].purchase_quality[0].quantity_adjusted -
          row[index].purchase_quality[0].quantity_allocated;
        var salesValue =
          row[index].sales_quality[0].quantity_adjusted -
          row[index].sales_quality[0].quantity_allocated;
        if (value > purchaseValue || value > salesValue) {
          row[index].purchase_quantityAllocating = e.target.value;
          row[index].quantityAllocationError = true;
          this.setState({
            quantityAllocationHelperText:
              "Quantity allocating is greater than available quantity",
          });
        } else {
          row[index].purchase_quantityAllocating = e.target.value;
          row[index].quantityAllocationError = false;
          this.setState({
            quantityAllocationHelperText: "",
          });
        }
      }
    }
    if (e.target.name === "quantityTobe_Delivered") {
      const re = /^[0-9]*\.?[0-9]*$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        row[index].quantityTobe_Delivered = e.target.value;
        row[index].quantity_deliveredError = false;
      }
    }
    if (e.target.name === "saleSellingQuality") {
      row[index].sales_sellingQuality = e.target.value;
      row[index].sales_sellingQualityError = false;
    }

    this.setState({
      quantityRows: row,
    });
  };

  Documentson_boardDateHandler = (date) => {
    this.setState({
      Documents_boardDate: dateFormateToDB(date),
    });
  };

  Vessel_SailingDateHandler = (date) => {
    this.setState({
      vessel_sailingDate: dateFormateToDB(date),
    });
  };

  purchaseContractModalClose = (modalID) => {
    //TODO: if modal can close to set sales_contract value ='0'
    var row = this.state.quantityRows;
    row[modalID].purchase_contract = "";
    this.setState({
      quantityRows: row,
      openDrawer: false,
      purchaseTypeData: [],
    });
    window.$("#myModal").modal("hide");
  };

  drawerClose = (modalID) => {
    const rows = this.state.quantityRows;
    rows.splice(modalID, 1);
    this.setState({
      quantityRows: rows,
      openDrawer: false,
      purchaseTypeData: [],
      purchaseContractNo: null,
      salesTypeData: [],
      salesContractNo: null,
    });
  };

  handlePurchaseTypesRadioButtons = (e) => {
    if (e.target.name === "purchaseTypeSelection") {
      this.setState({
        purchaseTypeSelection: e.target.value,
      });
    }
    if (e.target.name === "qualitySelection") {
      this.setState({
        qualitySelection: e.target.value,
        purchaseTypeSelection: null,
      });
    }
  };

  handleSalesTypesRadioButtons = (e) => {
    if (e.target.name === "salesQualitySelection") {
      this.setState({
        salesQualitySelection: e.target.value,
        salesTypeSelection: null,
      });
    }
    if (e.target.name === "salesTypeSelection") {
      this.setState({
        salesTypeSelection: e.target.value,
      });
    }
  };

  handlePurchaseTypesRadioButtonsEdit = (e) => {
    if (e.target.name === "purchaseTypeSelection") {
      this.setState({
        purchaseTypeSelection: e.target.value,
      });

      var modalID = this.state.editingIndex;
      var selectedQuality = this.state.qualitySelection;
      var selectedPurchaseType = e.target.value;
      var filterSelectedQuality = this.state.purchaseTypes.filter(
        (e, index) => e.id === selectedQuality
      );
      if (filterSelectedQuality.length > 0) {
        //TODO : check purchase Type is available or not in Quality Row
        var filterPurchaseType = filterSelectedQuality[0].purchase_types.filter(
          (p, idx) => p.id === selectedPurchaseType
        );
        if (filterPurchaseType.length === 0) {
          if (selectedQuality) {
            window.$(".collapse").collapse("show");
          }
          this.setState({
            snackBarErrorOpen: true,
            errorMessage:
              "Purchase Type Selection Invalid. please select valid purchase type",
          });
          // alert("Purchase Type Selection Invalid. please select valid purchase type");
          return;
        }
      } else {
        this.setState({
          snackBarErrorOpen: true,
          errorMessage: "Please Select Quality",
        });
        // alert("Please Select Quality");
        return;
      }
      var row = this.state.quantityRows;
      row[modalID].purchase_types = filterPurchaseType;
      row[modalID].purchase_quality = filterSelectedQuality;
      if (filterSelectedQuality.length > 0) {
        if (filterSelectedQuality[0].specification_standard === "ASTM/ISO") {
          row[modalID].purchase_procurement = "0";
          row[modalID].purchase_quality_standard = "0";
        } else if (filterSelectedQuality[0].purchasing_term === "Non Specs") {
          row[modalID].purchase_procurement =
            filterSelectedQuality[0].purchasing_term;
          row[modalID].purchase_quality_standard =
            filterSelectedQuality[0].purchasing_term;
          filterSelectedQuality[0].specification_standard =
            filterSelectedQuality[0].purchasing_term;
        } else {
          row[modalID].purchase_procurement =
            filterSelectedQuality[0].specification_standard;
          row[modalID].purchase_quality_standard =
            filterSelectedQuality[0].specification_standard;
        }
        this.setState({
          PC_specification_standard:
            filterSelectedQuality[0].specification_standard,
        });
      }
      this.setState({
        quantityRows: row,
      });
    }
    if (e.target.name === "qualitySelection") {
      this.setState({
        qualitySelection: e.target.value,
        purchaseTypeSelection: null,
      });
    }
  };

  handleSalesTypesRadioButtonsEdit = (e) => {
    if (e.target.name === "salesQualitySelection") {
      this.setState({
        salesQualitySelection: e.target.value,
        salesTypeSelection: null,
      });
    }
    if (e.target.name === "salesTypeSelection") {
      this.setState({
        salesTypeSelection: e.target.value,
      });
      //
      var modalID = this.state.editingIndex;
      var selectedSalesQuality = this.state.salesQualitySelection;
      var selectedSalesType = e.target.value;
      var filterSalesQuality = this.state.salesTypes.filter(
        (e, index) => e.id === selectedSalesQuality
      );
      if (filterSalesQuality.length > 0) {
        //TODO : check Sales Type is available or not in Quality Row
        var filterSalesType = filterSalesQuality[0].sales_types.filter(
          (s, idx) => s.id === selectedSalesType
        );
        if (filterSalesType.length === 0) {
          window.$(".collapse").collapse("show");
          this.setState({
            snackBarErrorOpen: true,
            errorMessage:
              "Sales Type Selection Invalid. please select valid sales type",
          });
          // alert("Sales Type Selection Invalid. please select valid sales type");
          return;
        }
      } else {
        this.setState({
          snackBarErrorOpen: true,
          errorMessage: "Please Select Quality",
        });
        // alert("Please Select Quality");
        return;
      }
      var row = this.state.quantityRows;
      row[modalID].sales_types = filterSalesType;
      row[modalID].sales_quality = filterSalesQuality;
      if (filterSalesQuality.length > 0) {
        if (filterSalesQuality[0].specification_standard === "ASTM/ISO") {
          row[modalID].sales_sellingQuality = "0";
          row[modalID].sales_quality_standard = "0";
        } else if (filterSalesQuality[0].sales_term === "Non Specs") {
          row[modalID].sales_sellingQuality = filterSalesQuality[0].sales_term;
          row[modalID].sales_quality_standard =
            filterSalesQuality[0].sales_term;
          filterSalesQuality[0].specification_standard =
            filterSalesQuality[0].sales_term;
        } else {
          row[modalID].sales_sellingQuality =
            filterSalesQuality[0].specification_standard;
          row[modalID].sales_quality_standard =
            filterSalesQuality[0].specification_standard;
        }
        this.setState({
          SC_specification_standard:
            filterSalesQuality[0].specification_standard,
        });
      }
      this.setState({
        quantityRows: row,
      });
    }
  };

  //! Purchase Contract On Select Quality
  purchaseTypeSelected = (modalID) => {
    var selectedQuality = this.state.qualitySelection;
    var selectedPurchaseType = this.state.purchaseTypeSelection;
    var filterSelectedQuality = this.state.purchaseTypes.filter(
      (e, index) => e.id === selectedQuality
    );
    if (filterSelectedQuality.length > 0) {
      //TODO : check purchase Type is available or not in Quality Row
      var filterPurchaseType = filterSelectedQuality[0].purchase_types.filter(
        (p, idx) => p.id === selectedPurchaseType
      );
      if (filterPurchaseType.length === 0) {
        if (selectedQuality) {
          window.$(".collapse").collapse("show");
        }
        this.setState({
          snackBarErrorOpen: true,
          errorMessage:
            "Purchase Type Selection Invalid. please select valid purchase type",
        });
        // alert("Purchase Type Selection Invalid. please select valid purchase type");
        return;
      }
    } else {
      this.setState({
        snackBarErrorOpen: true,
        errorMessage: "Please Select Quality",
      });
      // alert("Please Select Quality");
      return;
    }
    var row = this.state.quantityRows;
    row[modalID].purchase_types = filterPurchaseType;
    row[modalID].purchase_quality = filterSelectedQuality;
    if (filterSelectedQuality.length > 0) {
      if (filterSelectedQuality[0].specification_standard === "ASTM/ISO") {
        row[modalID].purchase_procurement = "0";
        row[modalID].purchase_quality_standard = "0";
      } else if (filterSelectedQuality[0].purchasing_term === "Non Specs") {
        row[modalID].purchase_procurement =
          filterSelectedQuality[0].purchasing_term;
        row[modalID].purchase_quality_standard =
          filterSelectedQuality[0].purchasing_term;
        filterSelectedQuality[0].specification_standard =
          filterSelectedQuality[0].purchasing_term;
      } else {
        row[modalID].purchase_procurement =
          filterSelectedQuality[0].specification_standard;
        row[modalID].purchase_quality_standard =
          filterSelectedQuality[0].specification_standard;
      }
      this.setState({
        PC_specification_standard:
          filterSelectedQuality[0].specification_standard,
      });
    }
    this.setState({
      quantityRows: row,
      purchaseContractEnable: !this.state.purchaseContractEnable,
      salesContractEnable: !this.state.salesContractEnable,
      purchaseTypeData: [],
      purchaseContractNo: "",
      purchaseTypeSelection: "",
      qualitySelection: "",
    });
    window.$("#myModal").modal("hide");
  };

  //! Sales Contract on Select Quality & Sales Type
  salesTypeSelected = (modalID) => {
    var selectedSalesQuality = this.state.salesQualitySelection;
    var selectedSalesType = this.state.salesTypeSelection;
    var filterSalesQuality = this.state.salesTypes.filter(
      (e, index) => e.id === selectedSalesQuality
    );
    if (filterSalesQuality.length > 0) {
      //TODO : check Sales Type is available or not in Quality Row
      var filterSalesType = filterSalesQuality[0].sales_types.filter(
        (s, idx) => s.id === selectedSalesType
      );
      if (filterSalesType.length === 0) {
        window.$(".collapse").collapse("show");
        this.setState({
          snackBarErrorOpen: true,
          errorMessage:
            "Sales Type Selection Invalid. please select valid sales type",
        });
        // alert("Sales Type Selection Invalid. please select valid sales type");
        return;
      }
    } else {
      this.setState({
        snackBarErrorOpen: true,
        errorMessage: "Please Select Quality",
      });
      // alert("Please Select Quality");
      return;
    }
    var row = this.state.quantityRows;
    row[modalID].sales_types = filterSalesType;
    row[modalID].sales_quality = filterSalesQuality;
    if (filterSalesQuality.length > 0) {
      if (filterSalesQuality[0].specification_standard === "ASTM/ISO") {
        row[modalID].sales_sellingQuality = "0";
        row[modalID].sales_quality_standard = "0";
      } else if (filterSalesQuality[0].sales_term === "Non Specs") {
        row[modalID].sales_sellingQuality = filterSalesQuality[0].sales_term;
        row[modalID].sales_quality_standard = filterSalesQuality[0].sales_term;
        filterSalesQuality[0].specification_standard =
          filterSalesQuality[0].sales_term;
      } else {
        row[modalID].sales_sellingQuality =
          filterSalesQuality[0].specification_standard;
        row[modalID].sales_quality_standard =
          filterSalesQuality[0].specification_standard;
      }
      this.setState({
        SC_specification_standard: filterSalesQuality[0].specification_standard,
      });
    }
    this.setState({
      quantityRows: row,
      purchaseTypeData: [],
      purchaseContractNo: null,
      salesTypeData: [],
      salesContractNo: null,
      salesContractEnable: false,
      quantityAllocationEnable: true,
      salesTypeSelection: "",
      salesQualitySelection: "",
    });
    window.$("#salesContractModal").modal("hide");
  };

  salesContractModalClose = (modalID) => {
    //TODO: if modal can close to set purchase_contract value ='0'
    var row = this.state.quantityRows;
    row[modalID].sales_contract = "";
    this.setState({ quantityRows: row });
    window.$("#salesContractModal").modal("hide");
  };

  calculateHandler = () => {
    var data = this.state.quantityRows;
    var mandatoryFields = false;
    var allocationData = false;
    for (var k = 0; k < data.length; k++) {
      data[k].quantityAllocationError = false;
    }

    for (var i = 0; i < data.length; i++) {
      var totalAllocatedData = 0;
      var totalSalesAllocatedData = 0;
      //Checking mandatory fields;
      if (data[i].purchase_contract === "") {
        data[i].purchase_contractError = true;
        mandatoryFields = true;
        this.setState({
          quantityRows: data,
        });
        this.setState({
          snackBarErrorOpen: true,
          errorMessage: "Please select purchase contract with types.",
        });
        // alert("Please select purchase contract with types.");
        return;
      }
      if (data[i].purchase_quantityAllocating === "") {
        data[i].quantityAllocationError = true;
        mandatoryFields = true;
      }
      if (data[i].sales_contract === "") {
        data[i].sales_contractError = true;
        mandatoryFields = true;
        this.setState({
          quantityRows: data,
        });
        this.setState({
          snackBarErrorOpen: true,
          errorMessage: "Please select sales contract with types.",
        });
        // alert("Please select sales contract with types.");
        return;
      }
      // if (data[i].purchase_procurement === '0') {
      //   data[i].purchase_procurementError = true;
      //   mandatoryFields = true;
      // }
      // if (data[i].sales_sellingQuality === '0') {
      //   data[i].sales_sellingQualityError = true;
      //   mandatoryFields = true;
      // }

      if (
        data[i].purchase_types.length >= 1 &&
        data[i].sales_types.length >= 1 &&
        data[i].purchase_quantityAllocating
      ) {
        var ID = data[i].purchase_quality[0].id;
        var salesID = data[i].sales_quality[0].id;
        var purchaseQuantityAvailable = this._toBeDelivered(
          data[i].purchase_quality[0]
        );
        var salesQuantityAvailable = this._toBeDelivered(
          data[i].sales_quality[0]
        );
        var qunatityAllocated = Number(data[i].purchase_quantityAllocating);
        if (
          qunatityAllocated > purchaseQuantityAvailable ||
          qunatityAllocated > salesQuantityAvailable
        ) {
          data[i].quantityAllocationError = true;
          allocationData = true;
        }
      }

      for (var j = 0; j < data.length; j++) {
        if (
          data[j].purchase_types.length >= 1 &&
          data[i].purchase_types.length >= 1 &&
          data[i].sales_types.length >= 1 &&
          data[j].sales_types.length >= 1
        ) {
          purchaseQuantityAvailable = Number(
            this._toBeDelivered(data[i].purchase_quality[0])
          ).toFixed(3);
          salesQuantityAvailable = Number(
            this._toBeDelivered(data[i].sales_quality[0])
          ).toFixed(3);
          qunatityAllocated = Number(data[i].purchase_quantityAllocating);
        }
        if (data[j].purchase_contract === "") {
          data[j].purchase_contractError = true;
          mandatoryFields = true;
        }
        if (data[j].purchase_quantityAllocating === "") {
          data[j].quantityAllocationError = true;
          mandatoryFields = true;
        }
        if (data[j].sales_contract === "") {
          data[j].sales_contractError = true;
          mandatoryFields = true;
        }
        // if (data[j].purchase_procurement === '0') {
        //   data[j].purchase_procurementError = true;
        //   mandatoryFields = true;
        // }
        // if (data[j].sales_sellingQuality === '0') {
        //   data[j].sales_sellingQualityError = true;
        //   mandatoryFields = true;
        // }
        if (!mandatoryFields) {
          if (i !== j && ID === data[j].purchase_quality[0].id) {
            totalAllocatedData =
              totalAllocatedData + Number(data[j].purchase_quantityAllocating);
            var allocated = (
              Number(data[i].purchase_quantityAllocating) + totalAllocatedData
            ).toFixed(3);
            if (Number(allocated) > Number(purchaseQuantityAvailable)) {
              data[j].quantityAllocationError = true;
              data[i].quantityAllocationError = true;
              allocationData = true;
            }
          }
          if (i !== j && salesID === data[j].sales_quality[0].id) {
            totalSalesAllocatedData =
              totalSalesAllocatedData +
              Number(data[j].purchase_quantityAllocating);
            var salesAllocated = (
              Number(data[i].purchase_quantityAllocating) +
              totalSalesAllocatedData
            ).toFixed(3);
            if (Number(salesAllocated) > Number(salesQuantityAvailable)) {
              data[j].quantityAllocationError = true;
              data[i].quantityAllocationError = true;
              allocationData = true;
            }
          }
        }
      }

      // if(data[i].purchase_types.length >=1 && data[i].sales_types.length >=1 && data[i].purchase_quantityAllocating) {
      //   var ID = data[i].purchase_quality[0].id;
      //   var salesID = data[i].sales_quality[0].id;
      //   var purchaseQuantityAvailable = (Number(data[i].purchase_quality[0].quantity_adjusted)-Number(data[i].purchase_quality[0].quantity_allocated)).toFixed(3);
      //   var salesQuantityAvailable = (Number(data[i].sales_quality[0].quantity_adjusted)-Number(data[i].sales_quality[0].quantity_allocated)).toFixed(3);
      //   var qunatityAllocated = Number(data[i].purchase_quantityAllocating);
      //   if(qunatityAllocated > purchaseQuantityAvailable || qunatityAllocated > salesQuantityAvailable) {
      //     data[i].quantityAllocationError = true;
      //     allocationData = true;
      //   }
      // }
      // for(var j=0;j<data.length;j++) {
      //   if(data[j].purchase_types.length >=1 && data[i].purchase_types.length >=1 && data[i].sales_types.length >=1 && data[j].sales_types.length >=1) {
      //     purchaseQuantityAvailable = (Number(data[j].purchase_quality[0].quantity_adjusted)-Number(data[j].purchase_quality[0].quantity_allocated)).toFixed(3);
      //     salesQuantityAvailable = (Number(data[j].sales_quality[0].quantity_adjusted)-Number(data[j].sales_quality[0].quantity_allocated)).toFixed(3);
      //     qunatityAllocated = Number(data[j].purchase_quantityAllocating);
      //     if(i !==j && ID === data[j].purchase_quality[0].id) {
      //       totalAllocatedData = totalAllocatedData+Number(data[j].purchase_quantityAllocating);
      //       var allocated = Number(data[i].purchase_quantityAllocating)+totalAllocatedData;
      //       if(allocated > purchaseQuantityAvailable) {
      //         data[j].quantityAllocationError = true;
      //         data[i].quantityAllocationError = true;
      //         allocationData = true
      //       }
      //     }
      //     if(i !==j && salesID === data[j].sales_quality[0].id) {
      //       totalSalesAllocatedData = totalSalesAllocatedData+Number(data[j].purchase_quantityAllocating);
      //       var salesAllocated = Number(data[i].purchase_quantityAllocating)+totalSalesAllocatedData;
      //       if(salesAllocated > salesQuantityAvailable) {
      //         data[j].quantityAllocationError = true;
      //         data[i].quantityAllocationError = true;
      //         allocationData = true
      //       }
      //     }
      //   }

      // }
    }
    this.setState({
      quantityRows: data,
    });

    // if (mandatoryFields) {
    //   alert("Please fill mandatory fields");
    //   this.setState({ quantityRows: data })
    //   return;
    // }
    if (allocationData) {
      this.setState({
        snackBarErrorOpen: true,
        errorMessage:
          "Quantity allocating is greater than available/required quantity.",
      });
      // alert('Quantity allocating is greater than available/required quantity');
    } else {
      this.setState({
        snackBarSuccessOpen: true,
        successMessage: "No allocation issues. You can proceed to submit",
      });
      // alert("No allocation issues. You can proceed to submit");
    }
  };

  checkboxHandleChange = (e) => {
    if (e.target.name === "buy_back_involved") {
      this.setState({
        buy_back_involved: e.target.value,
      });
    }
  };

  activeStepOne = () => {
    var { businessType, consignmentType, shipmentType } = this.state;
    let error = 0;
    if (businessType === "0") {
      this.setState({ businessTypeError: true });
      error = error + 1;
    }
    if (consignmentType === "0") {
      this.setState({ consignmentTypeError: true });
      error = error + 1;
    }
    if (shipmentType === "0") {
      this.setState({ shipmentTypeError: true });
      error = error + 1;
    }
    if (error > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMessage: "Please select mandatory fields",
      });
      return;
    } else {
      this.setState({
        activeStep: 1,
      });
    }
  };

  submitHandler = () => {
    this.setState({
      isLoading: true,
    });
    var {
      businessType,
      consignmentType,
      oldBusinessNo,
      motherVesselName,
      bargeName,
      shipmentType,
      vessel_Type,
      stevedore_vendor,
      floating_crane_vendor,
      floatingCrane_name,
      loading_Anchorage,
      loadingPort_agent,
      loading_rate,
      vessel_freight,
      shipmentLaycan,
      vessel_arrived_on,
      ETA,
      nor_acceptance,
      Documents_boardDate,
      vessel_sailingDate,
      stowagePlan,
      supplyTonnage,
      remarks,
      businessFlow,
      supplierReferenceNumber,
      buy_back_involved,
      vessel_owner,
      vessel_freight_price,
      business_head,
    } = this.state;
    if (businessType === "0") {
      this.setState({ businessTypeError: true });
    }
    if (consignmentType === "0") {
      this.setState({ consignmentTypeError: true });
    }
    if (shipmentType === "0") {
      this.setState({ shipmentTypeError: true });
    }

    if (consignmentType === "Mother Vessel") {
      bargeName = "";
    }
    if (consignmentType === "Barge") {
      motherVesselName = "";
    }
    if (vessel_Type === "0") {
      vessel_Type = "";
    }

    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    var business_no_allocation = [];

    var data = this.state.quantityRows;
    var mandatoryFields = false;
    var allocationData = false;
    for (var p = 0; p < data.length; p++) {
      data[p].quantityAllocationError = false;
    }

    for (var i = 0; i < data.length; i++) {
      var totalAllocatedData = 0;
      var totalSalesAllocatedData = 0;
      //Checking mandatory fields;
      if (data[i].purchase_contract === "") {
        data[i].purchase_contractError = true;
        mandatoryFields = true;
        this.setState({
          quantityRows: data,
        });
        // alert("Please select purchase contract with types.");
        // return;
      }
      if (data[i].purchase_quantityAllocating === "") {
        data[i].quantityAllocationError = true;
        mandatoryFields = true;
      }
      if (data[i].sales_contract === "") {
        data[i].sales_contractError = true;
        mandatoryFields = true;
        this.setState({
          quantityRows: data,
        });
        // alert("Please select sales contract with types.");
        // return;
      }
      if (data[i].purchase_procurement === "0") {
        data[i].purchase_procurementError = true;
        mandatoryFields = true;
      }
      if (data[i].sales_sellingQuality === "0") {
        data[i].sales_sellingQualityError = true;
        mandatoryFields = true;
      }

      if (
        data[i].purchase_types.length >= 1 &&
        data[i].sales_types.length >= 1 &&
        data[i].purchase_quantityAllocating
      ) {
        var ID = data[i].purchase_quality[0].id;
        var salesID = data[i].sales_quality[0].id;
        var purchaseQuantityAvailable = this._toBeDelivered(
          data[i].purchase_quality[0]
        );
        var salesQuantityAvailable = this._toBeDelivered(
          data[i].sales_quality[0]
        );
        var qunatityAllocated = Number(data[i].purchase_quantityAllocating);
        if (
          qunatityAllocated > purchaseQuantityAvailable ||
          qunatityAllocated > salesQuantityAvailable
        ) {
          data[i].quantityAllocationError = true;
          allocationData = true;
        }
      }

      for (var j = 0; j < data.length; j++) {
        if (
          data[j].purchase_types.length >= 1 &&
          data[i].purchase_types.length >= 1 &&
          data[i].sales_types.length >= 1 &&
          data[j].sales_types.length >= 1
        ) {
          purchaseQuantityAvailable = Number(
            this._toBeDelivered(data[i].purchase_quality[0])
          ).toFixed(3);
          salesQuantityAvailable = Number(
            this._toBeDelivered(data[i].sales_quality[0])
          ).toFixed(3);
          qunatityAllocated = Number(data[i].purchase_quantityAllocating);
        }
        if (data[j].purchase_contract === "") {
          data[j].purchase_contractError = true;
          mandatoryFields = true;
        }
        if (data[j].purchase_quantityAllocating === "") {
          data[j].quantityAllocationError = true;
          mandatoryFields = true;
        }
        if (data[j].sales_contract === "") {
          data[j].sales_contractError = true;
          mandatoryFields = true;
        }
        if (data[j].purchase_procurement === "0") {
          data[j].purchase_procurementError = true;
          mandatoryFields = true;
        }
        if (data[j].sales_sellingQuality === "0") {
          data[j].sales_sellingQualityError = true;
          mandatoryFields = true;
        }
        if (!mandatoryFields) {
          if (i !== j && ID === data[j].purchase_quality[0].id) {
            totalAllocatedData =
              totalAllocatedData + Number(data[j].purchase_quantityAllocating);
            var allocated = (
              Number(data[i].purchase_quantityAllocating) + totalAllocatedData
            ).toFixed(3);
            if (Number(allocated) > Number(purchaseQuantityAvailable)) {
              data[j].quantityAllocationError = true;
              data[i].quantityAllocationError = true;
              allocationData = true;
            }
          }
          if (i !== j && salesID === data[j].sales_quality[0].id) {
            totalSalesAllocatedData =
              totalSalesAllocatedData +
              Number(data[j].purchase_quantityAllocating);
            var salesAllocated = (
              Number(data[i].purchase_quantityAllocating) +
              totalSalesAllocatedData
            ).toFixed(3);
            if (Number(salesAllocated) > Number(salesQuantityAvailable)) {
              data[j].quantityAllocationError = true;
              data[i].quantityAllocationError = true;
              allocationData = true;
            }
          }
        }
      }
    }
    this.setState({
      quantityRows: data,
    });

    if (mandatoryFields) {
      this.setState({
        snackBarErrorOpen: true,
        errorMessage: "Please fill mandatory fields.",
        quantityRows: data,
        isLoading: false,
      });
      // alert("please fill mandatory fields");
      return;
    }
    if (allocationData) {
      this.setState({
        snackBarErrorOpen: true,
        errorMessage:
          "Quantity allocating is greater than available/required quantity",
        isLoading: false,
      });
      // alert('Quantity allocating is greater than available/required quantity');
      return;
    } else {
      //alert("No allocation issues. You can proceed to submit");
    }

    for (var k in data) {
      if (
        data[k].purchase_types.length >= 1 &&
        data[k].sales_types.length >= 1
      ) {
        business_no_allocation.push({
          pc_purchase_type_id: data[k].purchase_types[0].id,
          pc_qualities_id: data[k].purchase_quality[0].id,
          purchase_contract_id: data[k].purchase_contractID,
          purchase_contract_quality: data[k].quantity_in_mt,
          procurement_quality_standard: data[k].purchase_procurement,
          quantity_allocated: data[k].purchase_quantityAllocating,
          sale_contract_id: data[k].sales_contractID,
          sc_sale_type_id: data[k].sales_types[0].id,
          sc_qualities_id: data[k].sales_quality[0].id,
          selling_quality_standard: data[k].sales_sellingQuality,
        });
      }
    }

    if (businessType === "0") {
      this.setState({ isLoading: false, businessTypeError: true });
    }
    if (consignmentType === "0") {
      this.setState({ isLoading: false, consignmentTypeError: true });
    }
    if (shipmentType === "0") {
      this.setState({ isLoading: false, shipmentTypeError: true });
    }
    if (vessel_Type === "0") {
      vessel_Type = "";
    }
    var demurrage_rate_from_buyer = this.state.demurrage_rate_from_buyer
      ? this.state.demurrage_rate_from_buyer
      : "";
    var demurrage_rate_to_supplier = this.state.demurrage_rate_to_supplier
      ? this.state.demurrage_rate_to_supplier
      : "";

    if (
      businessType !== "0" &&
      consignmentType !== "0" &&
      shipmentType !== "0"
    ) {
      api
        .newBusinessNumber(
          loginUserID,
          idToken,
          business_head,
          oldBusinessNo,
          businessType,
          consignmentType,
          motherVesselName,
          bargeName,
          shipmentType,
          vessel_Type,
          stevedore_vendor,
          floating_crane_vendor,
          floatingCrane_name,
          loading_Anchorage,
          Documents_boardDate,
          shipmentLaycan,
          loadingPort_agent,
          loading_rate,
          vessel_freight,
          vessel_owner,
          vessel_freight_price,
          vessel_arrived_on,
          ETA,
          nor_acceptance,
          vessel_sailingDate,
          stowagePlan,
          supplyTonnage,
          business_no_allocation,
          demurrage_rate_from_buyer,
          demurrage_rate_to_supplier,
          remarks,
          businessFlow,
          supplierReferenceNumber,
          buy_back_involved
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
              if (res) {
                if (res.code === "200") {
                  this.setState({ successMsg: res.message, errorMsg: "" });
                  window.location.href = "/view-business/" + btoa(res.id);
                }
                if (res.code === "601") {
                  this.setState({
                    isLoading: false,
                    successMsg: "",
                    errorMsg: res.message,
                  });
                }
                if (res.code === "624") {
                  this.setState({
                    isLoading: false,
                    errorMsg: res.message,
                    successMsg: "",
                    snackBarErrorOpen: true,
                    errorMessage: res.message,
                  });
                  // alert(res.message);
                  return;
                }
              } else {
                this.setState({
                  snackBarErrorOpen: true,
                  errorMessage:
                    "Unexpected error occured. Please contact administrator.",
                });
                // alert('Unexpected error occured. Please contact administrator.');
              }
            });
          } else if (response.status === 601) {
            response.json().then((res) => {
              if (res) {
                this.setState({
                  isLoading: false,
                  errorMsg: res.message,
                  successMsg: "",
                });
              }
            });
          }
        });
    } else {
      this.setState({
        snackBarErrorOpen: true,
        errorMessage: "Please fill all mandatory fields.",
        isLoading: false,
      });
      // alert("Please fill all mandatory fields.");
    }
  };

  _toBeDelivered(data) {
    if (data) {
      if (data.purchase_contract_id) {
        var remainingPurchaseQuality = Number(
          Number(data.quantity_adjusted) - Number(data.quantity_allocated)
        );
        return remainingPurchaseQuality;
      } else if (data.sale_contract_id) {
        var remainingSalesQuality = Number(
          Number(data.quantity_adjusted) - Number(data.quantity_allocated)
        );
        return remainingSalesQuality;
      }
    }
  }

  toLocaleString(value) {
    return Number(value).toLocaleString();
  }

  progressBarSize(data) {
    if (data) {
      if (data.purchase_contract_id) {
        var remainingPurchaseQuality =
          Number(
            Number(data.quantity_allocated) / Number(data.quantity_adjusted)
          ) * 100;
        return remainingPurchaseQuality;
      } else if (data.sale_contract_id) {
        var remainingSalesQuality =
          Number(
            Number(data.quantity_allocated) / Number(data.quantity_adjusted)
          ) * 100;
        return remainingSalesQuality;
      }
    }
  }

  handleAllocationOpen = (e, index) => {
    e.preventDefault();
    this.setState({
      popperID: index,
      openQuantityAllocation: true,
      anchorEl: e.currentTarget,
    });
  };

  handleClose = (event) => {
    this.setState({
      openQuantityAllocation: false,
    });
  };

  updateIndividualAllocation = (index) => () => {
    var quantityRows = this.state.quantityRows[index];
    var purchase_contract = {
      name: quantityRows.purchase_contract,
      value: quantityRows.purchase_contract,
    };
    this.handlePurchaseContract(index, purchase_contract);
    var sales_contract = {
      name: quantityRows.sales_contract,
      value: quantityRows.sales_contract,
    };
    this.handleSalesContract(index, sales_contract);
    this.setState({
      editingIndex: index,
      qualitySelection: quantityRows.purchase_quality[0].id,
      purchaseTypeSelection: quantityRows.purchase_types[0].id,
      salesQualitySelection: quantityRows.sales_quality[0].id,
      salesTypeSelection: quantityRows.sales_types[0].id,
      enableEditingDrawer: true,
      purchaseContractEnable: true,
      salesContractEnable: true,
    });
  };

  onNextStep = () => {
    var data = this.state.quantityRows;
    if (data.length === 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMessage:
          "Allocations are empty, please select atleast one allocation to proceed.",
      });
      // alert('Allocations are empty, please select atleast one allocation to proceed.');
      return;
    } else {
      var mandatoryFields = false;
      var allocationData = false;
      for (var k = 0; k < data.length; k++) {
        data[k].quantityAllocationError = false;
      }

      for (var i = 0; i < data.length; i++) {
        var totalAllocatedData = 0;
        var totalSalesAllocatedData = 0;
        //Checking mandatory fields;
        if (data[i].purchase_contract === "") {
          data[i].purchase_contractError = true;
          mandatoryFields = true;
          this.setState({
            quantityRows: data,
            snackBarErrorOpen: true,
            errorMessage: "Please select purchase contract with types.",
          });
          // alert("Please select purchase contract with types.");
          return;
        }
        if (data[i].purchase_quantityAllocating === "") {
          data[i].quantityAllocationError = true;
          mandatoryFields = true;
        }
        if (data[i].sales_contract === "") {
          data[i].sales_contractError = true;
          mandatoryFields = true;
          this.setState({
            quantityRows: data,
            snackBarErrorOpen: true,
            errorMessage: "Please select sales contract with types.",
          });
          alert("Please select sales contract with types.");
          return;
        }
        if (data[i].purchase_procurement === "0") {
          data[i].purchase_procurementError = true;
          mandatoryFields = true;
        }
        if (data[i].sales_sellingQuality === "0") {
          data[i].sales_sellingQualityError = true;
          mandatoryFields = true;
        }

        if (
          data[i].purchase_types.length >= 1 &&
          data[i].sales_types.length >= 1 &&
          data[i].purchase_quantityAllocating
        ) {
          var ID = data[i].purchase_quality[0].id;
          var salesID = data[i].sales_quality[0].id;
          var purchaseQuantityAvailable = this._toBeDelivered(
            data[i].purchase_quality[0]
          );
          var salesQuantityAvailable = this._toBeDelivered(
            data[i].sales_quality[0]
          );
          var qunatityAllocated = Number(data[i].purchase_quantityAllocating);
          if (
            qunatityAllocated > purchaseQuantityAvailable ||
            qunatityAllocated > salesQuantityAvailable
          ) {
            data[i].quantityAllocationError = true;
            allocationData = true;
          }
        }

        for (var j = 0; j < data.length; j++) {
          if (
            data[j].purchase_types.length >= 1 &&
            data[i].purchase_types.length >= 1 &&
            data[i].sales_types.length >= 1 &&
            data[j].sales_types.length >= 1
          ) {
            purchaseQuantityAvailable = Number(
              this._toBeDelivered(data[i].purchase_quality[0])
            ).toFixed(3);
            salesQuantityAvailable = Number(
              this._toBeDelivered(data[i].sales_quality[0])
            ).toFixed(3);
            qunatityAllocated = Number(data[i].purchase_quantityAllocating);
          }
          if (data[j].purchase_contract === "") {
            data[j].purchase_contractError = true;
            mandatoryFields = true;
          }
          if (data[j].purchase_quantityAllocating === "") {
            data[j].quantityAllocationError = true;
            mandatoryFields = true;
          }
          if (data[j].sales_contract === "") {
            data[j].sales_contractError = true;
            mandatoryFields = true;
          }
          if (data[j].purchase_procurement === "0") {
            data[j].purchase_procurementError = true;
            mandatoryFields = true;
          }
          if (data[j].sales_sellingQuality === "0") {
            data[j].sales_sellingQualityError = true;
            mandatoryFields = true;
          }
          if (!mandatoryFields) {
            if (i !== j && ID === data[j].purchase_quality[0].id) {
              totalAllocatedData =
                totalAllocatedData +
                Number(data[j].purchase_quantityAllocating);
              var allocated = (
                Number(data[i].purchase_quantityAllocating) + totalAllocatedData
              ).toFixed(3);
              if (Number(allocated) > Number(purchaseQuantityAvailable)) {
                data[j].quantityAllocationError = true;
                data[i].quantityAllocationError = true;
                allocationData = true;
              }
            }
            if (i !== j && salesID === data[j].sales_quality[0].id) {
              totalSalesAllocatedData =
                totalSalesAllocatedData +
                Number(data[j].purchase_quantityAllocating);
              var salesAllocated = (
                Number(data[i].purchase_quantityAllocating) +
                totalSalesAllocatedData
              ).toFixed(3);
              if (Number(salesAllocated) > Number(salesQuantityAvailable)) {
                data[j].quantityAllocationError = true;
                data[i].quantityAllocationError = true;
                allocationData = true;
              }
            }
          }
        }
      }
      this.setState({
        quantityRows: data,
      });
      if (mandatoryFields) {
        this.setState({
          quantityRows: data,
          snackBarErrorOpen: true,
          errorMessage: "Please fill mandatory fields.",
        });
        // alert("Please fill mandatory fields");
        return;
      }
      if (allocationData) {
        this.setState({
          snackBarErrorOpen: true,
          errorMessage:
            "Quantity allocating is greater than available/required quantity",
        });
        // alert('Quantity allocating is greater than available/required quantity');
        return;
      } else {
        this.setState({
          activeStep: 2,
        });
      }
    }
  };

  onSave = () => {
    var {
      qualitySelection,
      purchaseTypeSelection,
      salesTypeSelection,
      salesQualitySelection,
    } = this.state;
    if (!qualitySelection) {
      this.setState({
        snackBarErrorOpen: true,
        errorMessage: "Please select purchase quality to proceed.",
      });
      // alert('Please select purchase quality to proceed.');
      return;
    }
    if (!purchaseTypeSelection) {
      this.setState({
        snackBarErrorOpen: true,
        errorMessage: "Please select purchase type to proceed.",
      });
      // alert('Please select purchase type to proceed.');
      return;
    }
    if (!salesQualitySelection) {
      this.setState({
        snackBarErrorOpen: true,
        errorMessage: "Please select sales quality to proceed.",
      });
      // alert('Please select sales quality to proceed.');
      return;
    }
    if (!salesTypeSelection) {
      this.setState({
        snackBarErrorOpen: true,
        errorMessage: "Please select sales type to proceed.",
      });
      // alert('Please select sales type to proceed.');
      return;
    } else {
      this.setState({
        quantityAllocationEnable: false,
        enableEditingDrawer: false,
        purchaseContractEnable: false,
        salesContractEnable: false,
        purchaseTypeData: [],
        salesTypeData: [],
        purchaseTypeSelection: null,
        qualitySelection: null,
        salesTypeSelection: null,
        salesQualitySelection: null,
      });
    }
  };

  render() {
    return (
      <div id="wraper">
        <SideBar />
        <div className="content">
          <Header />
          <div className="clearfix content-header">
            <div className="row">
              <div className="col-sm text-left pl-0">
                <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                  New Business Number
                </h4>
              </div>
            </div>
          </div>
          {this.state.isLoading && <Loader />}
          {!this.state.isLoading && (
            <form className="container p-3">
              {this.state.errorMsg && (
                <div className="row">
                  <div className="col text-center">
                    <p className="error-class">{this.state.errorMsg}</p>
                  </div>
                </div>
              )}
              {this.state.successMsg && (
                <div className="row">
                  <div className="col text-center">
                    <p className="success-class">{this.state.successMsg}</p>
                  </div>
                </div>
              )}
              <Snackbar
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                open={this.state.snackBarErrorOpen}
                autoHideDuration={10000}
                onClose={this.handleErrorClose}
                style={{ width: "450px" }}
              >
                <Alert
                  elevation={6}
                  variant="filled"
                  onClose={this.handleErrorClose}
                  severity="error"
                  style={{ width: "100%" }}
                >
                  {this.state.errorMessage}
                </Alert>
              </Snackbar>
              <Snackbar
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                style={{ width: "450px" }}
                open={this.state.snackBarSuccessOpen}
                autoHideDuration={10000}
                onClose={this.handleSuccessClose}
              >
                <Alert
                  elevation={6}
                  variant="filled"
                  onClose={this.handleSuccessClose}
                  severity="success"
                  style={{ width: "100%" }}
                >
                  {this.state.successMessage}
                </Alert>
              </Snackbar>
              <div className="row mb-0 stepper">
                <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                  <i
                    className={
                      this.state.activeStep > 0
                        ? "fa fa-check-circle"
                        : "fa fa-check-circle-o"
                    }
                    aria-hidden="true"
                    style={{
                      fontSize: 20,
                      color:
                        this.state.activeStep > 0
                          ? "#31c197"
                          : this.state.activeStep === 0
                          ? config.themeColor
                          : "#ccc",
                    }}
                  ></i>
                  <span
                    className="stepper_text"
                    style={{
                      color:
                        this.state.activeStep > 0
                          ? "#31c197"
                          : this.state.activeStep === 0
                          ? config.themeColor
                          : "#ccc",
                    }}
                  >
                    Shipment Details
                  </span>
                  <i
                    className="fa fa-chevron-right stepper_sub_icon stepper_arrow"
                    aria-hidden="true"
                    style={{
                      color:
                        this.state.activeStep > 0
                          ? "#31c197"
                          : this.state.activeStep === 0
                          ? config.themeColor
                          : "#ccc",
                    }}
                  ></i>
                </span>
                <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                  <i
                    className={
                      this.state.activeStep > 1
                        ? "fa fa-check-circle"
                        : "fa fa-check-circle-o"
                    }
                    aria-hidden="true"
                    style={{
                      fontSize: 20,
                      color:
                        this.state.activeStep > 1
                          ? "#31c197"
                          : this.state.activeStep === 1
                          ? config.themeColor
                          : "#ccc",
                    }}
                  ></i>
                  <span
                    className="stepper_text"
                    style={{
                      color:
                        this.state.activeStep > 1
                          ? "#31c197"
                          : this.state.activeStep === 1
                          ? config.themeColor
                          : "#ccc",
                    }}
                  >
                    Quantity Allocation
                  </span>
                  <i
                    className="fa fa-chevron-right stepper_sub_icon stepper_arrow"
                    aria-hidden="true"
                    style={{
                      color:
                        this.state.activeStep > 1
                          ? "#31c197"
                          : this.state.activeStep === 1
                          ? config.themeColor
                          : "#ccc",
                    }}
                  ></i>
                </span>
                <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                  <i
                    className={
                      this.state.activeStep > 2
                        ? "fa fa-check-circle"
                        : "fa fa-check-circle-o"
                    }
                    aria-hidden="true"
                    style={{
                      fontSize: 20,
                      color:
                        this.state.activeStep > 2
                          ? "#31c197"
                          : this.state.activeStep === 2
                          ? config.themeColor
                          : "#ccc",
                    }}
                  ></i>
                  <span
                    className="stepper_text"
                    style={{
                      color:
                        this.state.activeStep > 2
                          ? "#31c197"
                          : this.state.activeStep === 2
                          ? config.themeColor
                          : "#ccc",
                    }}
                  >
                    Load Details
                  </span>
                  <i
                    className="fa fa-chevron-right stepper_sub_icon stepper_arrow"
                    aria-hidden="true"
                    style={{
                      color:
                        this.state.activeStep > 2
                          ? "#31c197"
                          : this.state.activeStep === 2
                          ? config.themeColor
                          : "#ccc",
                    }}
                  ></i>
                </span>
                <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                  <i
                    className="fa fa-check-circle-o"
                    aria-hidden="true"
                    style={{
                      fontSize: 20,
                      color:
                        this.state.activeStep === 3
                          ? config.themeColor
                          : "#ccc",
                    }}
                  ></i>
                  <span
                    className="stepper_text"
                    style={{
                      color:
                        this.state.activeStep === 3
                          ? config.themeColor
                          : "#ccc",
                    }}
                  >
                    Preview & Submit
                  </span>
                </span>
              </div>
              <div className="col-lg card" style={{ overflowY: "scroll" }}>
                {this.state.activeStep === 0 && (
                  <div className="section_block">
                    <div style={{ width: "70%" }}>
                      <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                        Add details to create Business Number
                      </h5>
                    </div>

                    <div className="row mb-2">
                      <div className="col-lg-6 p-0">
                        <label className="form_label mb-2">Business Head</label>
                        <Autocomplete
                          options={this.state.users_list_menu}
                          getOptionLabel={(option) => option.name}
                          onChange={(event, value) => {
                            if (value) {
                              this.setState({
                                business_head: value.value,
                              });
                            } else this.setState({ business_head: null });
                          }}
                          InputProps={{
                            style: {
                              fontFamily: "Poppins",
                              fontSize: "14px",
                            },
                          }}
                          size="small"
                          name="users"
                          renderOption={(option) => (
                            <React.Fragment>{option.name}</React.Fragment>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Users"
                              variant="outlined"
                              fullWidth
                            />
                          )}
                        />
                      </div>
                    </div>

                    <div className="row mb-0">
                      <div className="col-lg-6 p-0">
                        <div className="row mb-2">
                          <label className="form_label mb-0">
                            Business Type{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <TextField
                            name="businessType"
                            placeholder="Business Type"
                            margin="dense"
                            variant="outlined"
                            value={this.state.businessType}
                            error={this.state.businessTypeError}
                            onChange={this.handleText}
                            fullWidth
                            select
                            InputProps={{
                              style: {
                                fontFamily: "Poppins",
                                fontSize: "14px",
                              },
                            }}
                          >
                            <MenuItem value="0" disabled key={-1}>
                              Please Select
                            </MenuItem>
                            <MenuItem value="Export Sales">
                              Export Sales
                            </MenuItem>
                            <MenuItem value="Local Sales">Local Sales</MenuItem>
                          </TextField>
                        </div>
                      </div>
                      <div className="col-lg-6 pr-0">
                        <div className="row mb-2">
                          <label className="form_label mb-0">
                            Consignment Type{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <TextField
                            name="consignmentType"
                            placeholder="Consignment Type"
                            margin="dense"
                            variant="outlined"
                            value={this.state.consignmentType}
                            error={this.state.consignmentTypeError}
                            onChange={this.handleText}
                            fullWidth
                            select
                            InputProps={{
                              style: {
                                fontFamily: "Poppins",
                                fontSize: "14px",
                              },
                            }}
                          >
                            <MenuItem value="0" disabled key={-1}>
                              Please Select
                            </MenuItem>
                            <MenuItem value="Mother Vessel">
                              Mother Vessel
                            </MenuItem>
                            <MenuItem value="Barge">Barge</MenuItem>
                          </TextField>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-0">
                      {this.state.consignmentType === "Mother Vessel" && (
                        <div className="col-lg-6 p-0">
                          <div className="row mb-2">
                            <label className="form_label mb-0">
                              Mother Vessel Name
                            </label>
                            <TextField
                              name="motherVesselName"
                              placeholder="Mother Vessel Name"
                              margin="dense"
                              variant="outlined"
                              value={this.state.motherVesselName}
                              onChange={this.handleText}
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                              fullWidth
                              autoComplete={false}
                              InputProps={{
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                },
                              }}
                            />
                          </div>
                        </div>
                      )}
                      {this.state.consignmentType === "Barge" && (
                        <div className="col-lg-6 p-0">
                          <div className="row mb-2">
                            <label className="form_label mb-0">
                              Barge Name
                            </label>
                            <TextField
                              name="bargeName"
                              placeholder="Barge Name"
                              margin="dense"
                              variant="outlined"
                              value={this.state.bargeName}
                              onChange={this.handleText}
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                              fullWidth
                              autoComplete={false}
                              InputProps={{
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                },
                              }}
                            />
                          </div>
                        </div>
                      )}
                      <div
                        className={
                          this.state.consignmentType === "0"
                            ? "col-lg-6 p-0"
                            : "col-lg-6 pr-0"
                        }
                      >
                        <div className="row mb-2">
                          <label className="form_label mb-0">
                            Shipment Type{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <TextField
                            name="shipmentType"
                            placeholder="Shipment Type"
                            margin="dense"
                            variant="outlined"
                            value={this.state.shipmentType}
                            error={this.state.shipmentTypeError}
                            onChange={this.handleText}
                            fullWidth
                            select
                            InputProps={{
                              style: {
                                fontFamily: "Poppins",
                                fontSize: "14px",
                              },
                            }}
                          >
                            <MenuItem value="0" disabled key={-1}>
                              Please Select
                            </MenuItem>
                            {this.state.consignmentType === "Barge"
                              ? this.state.bargeDropdown
                              : ""}
                            {this.state.consignmentType === "Mother Vessel"
                              ? this.state.motherVesselDropdown
                              : ""}
                          </TextField>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-0">
                      <div className="col-lg-6 p-0">
                        <div className="row mb-2">
                          <label className="form_label mb-0">
                            Business Flow{" "}
                            {/* <span style={{ color: "red" }}>*</span> */}
                          </label>
                          <TextField
                            name="businessFlow"
                            placeholder="Business Flow"
                            margin="dense"
                            variant="outlined"
                            value={this.state.businessFlow}
                            // error={this.state.businessTypeError}
                            onChange={this.handleText}
                            fullWidth
                            // select
                            InputProps={{
                              style: {
                                fontFamily: "Poppins",
                                fontSize: "14px",
                              },
                            }}
                          ></TextField>
                        </div>
                      </div>
                      <div className="col-lg-6 pr-0">
                        <div className="row mb-2">
                          <label className="form_label mb-0">
                            Supplier Referene No{" "}
                            {/* <span style={{ color: "red" }}>*</span> */}
                          </label>
                          <TextField
                            name="supplierReferenceNumber"
                            placeholder="Supplier Referene No"
                            margin="dense"
                            variant="outlined"
                            value={this.state.supplierReferenceNumber}
                            error={this.state.consignmentTypeError}
                            onChange={this.handleText}
                            fullWidth
                            // select
                            InputProps={{
                              style: {
                                fontFamily: "Poppins",
                                fontSize: "14px",
                              },
                            }}
                          ></TextField>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-lg-6 p-0">
                        <label className="form_label mb-0">
                          Buy Back Involved
                        </label>
                        <RadioGroup
                          name="buy_back_involved"
                          value={this.state.buy_back_involved}
                          onChange={this.checkboxHandleChange}
                        >
                          <div>
                            <FormControlLabel
                              value="Yes"
                              control={
                                <NewRadio
                                  checked={
                                    this.state.buy_back_involved === "Yes"
                                  }
                                />
                              }
                              label="Yes"
                            />
                            <FormControlLabel
                              value="No"
                              control={
                                <NewRadio
                                  checked={
                                    this.state.buy_back_involved === "No"
                                  }
                                />
                              }
                              label="No"
                            />
                          </div>
                        </RadioGroup>
                      </div>
                    </div>
                  </div>
                )}

                {this.state.activeStep === 1 && (
                  <div className="section_block_adjusted">
                    <div style={{ width: "70%" }}>
                      <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                        Quantity Allocation
                      </h5>
                    </div>
                    {/* <IconButton onClick={this.handleAllocationOpen}>
                                            <EditIcon style={{ fontSize: 'small', verticalAlign: 'middle', color: '#32c197', marginLeft: 5 }} />
                                          </IconButton> */}
                    {this.state.displayQuntityAllocations && (
                      <div>
                        <table
                          className="table-responsive border-0 root"
                          style={{ padding: 1 }}
                        >
                          <thead className="thead-light">
                            <tr>
                              <th
                                className="table_header_label"
                                style={{ minWidth: 225 }}
                              >
                                Contract No
                              </th>
                              <th
                                className="table_header_label"
                                style={{ minWidth: 100 }}
                              >
                                Quality
                              </th>
                              {/* <th className="table_header_label" style={{ minWidth: 160, fontSize: 12 }}>Quantity Allocation</th> */}
                              <th
                                className="table_header_label"
                                style={{ minWidth: 100 }}
                              >
                                Tolerance
                              </th>
                              <th
                                className="table_header_label"
                                style={{ minWidth: 130 }}
                                nowrap="true"
                              >
                                Inco Terms
                              </th>
                              <th
                                className="table_header_label"
                                style={{ minWidth: 150 }}
                              >
                                Quality Standard
                              </th>
                              <th
                                className="table_header_label"
                                style={{ minWidth: 100 }}
                              >
                                Quantity
                              </th>
                              <th
                                className="table_header_label"
                                style={{ minWidth: 100 }}
                              >
                                Remaining
                              </th>
                              <th className="table_header_label">Action</th>
                            </tr>
                          </thead>
                          {this.state.quantityRows.map((e, index) => (
                            <tbody className="border" key={index}>
                              <tr>
                                <td className="table_value">
                                  <span
                                    className="quantity_allocation"
                                    style={{
                                      border: e.quantityAllocationError
                                        ? "1px solid red"
                                        : "none",
                                    }}
                                  >
                                    Quantity Allocation:{" "}
                                    {e.purchase_quantityAllocating}
                                    <IconButton
                                      // onClick={(e)=>this.handleAllocationOpen(e, index)}
                                      onClick={(e) => {
                                        this.setState({
                                          popperID: index,
                                          openQuantityAllocation: true,
                                          anchorEl: e.currentTarget,
                                        });
                                      }}
                                      style={{ padding: 5 }}
                                    >
                                      <EditIcon
                                        style={{
                                          fontSize: "small",
                                          verticalAlign: "middle",
                                          color: "#32c197",
                                          marginLeft: 5,
                                        }}
                                      />
                                    </IconButton>
                                    <Popper
                                      open={this.state.openQuantityAllocation}
                                      anchorEl={this.state.anchorEl}
                                      placement={"bottom"}
                                      onClose={this.handleClose}
                                      modifiers={{
                                        flip: {
                                          enabled: true,
                                        },
                                        arrow: {
                                          enabled: true,
                                          // element: arrowRef,
                                        },
                                      }}
                                      style={{ width: 350 }}
                                    >
                                      <Paper>
                                        <div className="col-lg-12 mb-4 mt-2 p-2">
                                          <label className="form_label mb-1">
                                            Quantity Allocation
                                          </label>
                                          <TextField
                                            name="purchase_quantityAllocating"
                                            margin="dense"
                                            variant="outlined"
                                            fullWidth
                                            placeholder="Value"
                                            value={
                                              this.state.popperID !== null
                                                ? this.state.quantityRows[
                                                    this.state.popperID
                                                  ].purchase_quantityAllocating
                                                : ""
                                            }
                                            error={
                                              this.state.popperID !== null
                                                ? this.state.quantityRows[
                                                    this.state.popperID
                                                  ].quantityAllocationError
                                                : false
                                            }
                                            onChange={this.handleQuantityRowsTextHandler(
                                              this.state.popperID
                                            )}
                                            helperText={
                                              this.state
                                                .quantityAllocationHelperText
                                            }
                                            InputProps={{
                                              style: {
                                                fontFamily: "Poppins",
                                                fontSize: "14px",
                                              },
                                            }}
                                            autoComplete="off"
                                          />
                                          <div className="row col-lg-12 pl-0 mt-2">
                                            <button
                                              type="button"
                                              className="drawer_button drawer_text mr-3"
                                              onClick={() =>
                                                this.setState({
                                                  openQuantityAllocation: false,
                                                })
                                              }
                                            >
                                              Save
                                            </button>
                                          </div>
                                        </div>
                                      </Paper>
                                    </Popper>
                                  </span>
                                  <span className="purchase_contract">
                                    {e.purchase_contract}
                                  </span>
                                </td>
                                {e.purchase_quality.length > 0 ? (
                                  <td className="table_value">
                                    {e.purchase_quality[0].quality}
                                  </td>
                                ) : (
                                  <td></td>
                                )}
                                {/* <td className="table_value" rowSpan={2}>{e.purchase_quantityAllocating}</td> */}
                                {e.purchase_quality.length > 0 ? (
                                  <td className="table_value">
                                    {e.purchase_quality[0].tolerance_adjusted +
                                      " %"}
                                  </td>
                                ) : (
                                  <td></td>
                                )}
                                {e.purchase_types.length > 0 ? (
                                  <td className="table_value" nowrap="true">
                                    {e.purchase_types[0].purchase_type}
                                  </td>
                                ) : (
                                  <td></td>
                                )}

                                <td style={{ padding: "0px 10px" }}>
                                  {this.state.quantityRows[index]
                                    .purchase_quality_standard === "ASTM/ISO" ||
                                  this.state.quantityRows[index]
                                    .purchase_quality_standard === "0" ? (
                                    <TextField
                                      name="purchaseProcurement"
                                      margin="dense"
                                      variant="outlined"
                                      // label='Procurement Quality Standard *'
                                      fullWidth
                                      value={
                                        this.state.quantityRows[index]
                                          .purchase_procurement
                                      }
                                      error={
                                        this.state.quantityRows[index]
                                          .purchase_procurementError
                                      }
                                      onChange={this.handleQuantityRowsTextHandler(
                                        index
                                      )}
                                      select
                                      size="small"
                                      InputProps={{
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                        },
                                      }}
                                    >
                                      <MenuItem value="0" disabled>
                                        Please Select
                                      </MenuItem>
                                      <MenuItem value="ASTM">ASTM</MenuItem>
                                      <MenuItem value="ISO">ISO</MenuItem>
                                    </TextField>
                                  ) : (
                                    this.state.quantityRows[index]
                                      .purchase_procurement
                                  )}
                                </td>
                                {e.purchase_quality.length > 0 ? (
                                  <td className="table_value">
                                    {this.toLocaleString(
                                      e.purchase_quality[0].quantity_adjusted
                                    )}
                                  </td>
                                ) : (
                                  <td></td>
                                )}
                                {e.purchase_quality.length > 0 ? (
                                  <td className="table_value">
                                    {this.toLocaleString(
                                      this._toBeDelivered(e.purchase_quality[0])
                                    )}
                                  </td>
                                ) : (
                                  <td></td>
                                )}
                                {this.state.quantityRows.length === 1 ? (
                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                      textAlign: "center",
                                    }}
                                    rowSpan={2}
                                  >
                                    <EditIcon
                                      onClick={this.updateIndividualAllocation(
                                        index
                                      )}
                                      style={{
                                        fontSize: "medium",
                                        verticalAlign: "middle",
                                        color: "#32c197",
                                        marginLeft: 5,
                                      }}
                                    />
                                  </td>
                                ) : (
                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                      textAlign: "center",
                                    }}
                                    rowSpan={2}
                                  >
                                    <DeleteIcon
                                      style={{ color: "red" }}
                                      onClick={this.removeQuantityRowsHandler(
                                        index
                                      )}
                                    />
                                    <EditIcon
                                      onClick={this.updateIndividualAllocation(
                                        index
                                      )}
                                      style={{
                                        fontSize: "medium",
                                        verticalAlign: "middle",
                                        color: "#32c197",
                                        marginLeft: 5,
                                      }}
                                    />
                                  </td>
                                )}
                              </tr>
                              <tr
                                style={{
                                  display: e.sales_contract ? "" : "none",
                                }}
                              >
                                <td className="table_value">
                                  {e.sales_contract}
                                </td>
                                {e.sales_quality.length > 0 ? (
                                  <td className="table_value">
                                    {e.sales_quality[0].quality}
                                  </td>
                                ) : (
                                  <td></td>
                                )}
                                {e.sales_quality.length > 0 ? (
                                  <td className="table_value">
                                    {e.sales_quality[0].tolerance_adjusted +
                                      " %"}
                                  </td>
                                ) : (
                                  <td></td>
                                )}
                                {e.sales_types.length > 0 ? (
                                  <td className="table_value" nowrap="true">
                                    {e.sales_types[0].sales_type}
                                  </td>
                                ) : (
                                  <td></td>
                                )}
                                <td style={{ padding: "0px 10px" }}>
                                  {this.state.quantityRows[index]
                                    .sales_quality_standard === "ASTM/ISO" ||
                                  this.state.quantityRows[index]
                                    .sales_quality_standard === "0" ? (
                                    <TextField
                                      name="saleSellingQuality"
                                      margin="dense"
                                      variant="outlined"
                                      // label='Selling Quality Standard *'
                                      placeholder="Selling Quality Standard *"
                                      fullWidth
                                      value={
                                        this.state.quantityRows[index]
                                          .sales_sellingQuality
                                      }
                                      error={
                                        this.state.quantityRows[index]
                                          .sales_sellingQualityError
                                      }
                                      onChange={this.handleQuantityRowsTextHandler(
                                        index
                                      )}
                                      select
                                      size="small"
                                      InputProps={{
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                        },
                                      }}
                                    >
                                      <MenuItem value="0" disabled>
                                        Please Select
                                      </MenuItem>
                                      <MenuItem value="ASTM">ASTM</MenuItem>
                                      <MenuItem value="ISO">ISO</MenuItem>
                                    </TextField>
                                  ) : (
                                    this.state.quantityRows[index]
                                      .sales_sellingQuality
                                  )}
                                </td>
                                {e.sales_quality.length > 0 ? (
                                  <td className="table_value">
                                    {this.toLocaleString(
                                      e.sales_quality[0].quantity_adjusted
                                    )}
                                  </td>
                                ) : (
                                  <td></td>
                                )}
                                {e.sales_quality.length > 0 ? (
                                  <td className="table_value">
                                    {this.toLocaleString(
                                      this._toBeDelivered(e.sales_quality[0])
                                    )}
                                  </td>
                                ) : (
                                  <td></td>
                                )}
                              </tr>
                            </tbody>
                          ))}
                        </table>
                      </div>
                    )}
                    <br />
                    {/* <button type='button' onClick={this.addMoreQuantityRows} className='btn btn-primary btn-responsive' style={{ padding: 4, backgroundColor: '#303F9F', border: 'none' }}>Add Row</button> */}
                    {/* {this.state.displayQuntityAllocations &&
                      <button type='button' className='drawer_button drawer_text mr-3' onClick={this.calculateHandler}>Calculate</button>
                    } */}
                    <button
                      type="button"
                      onClick={this.addMoreQuantityRows}
                      className="header_button header_button_text addrow_button_adjustment"
                      style={{ color: config.themeColor, width: 110 }}
                    >
                      Add Row
                    </button>
                    {this.state.quantityRows.length > 0 && (
                      <button
                        type="button"
                        className="drawer_button drawer_text drawer_calculate_adjustment ml-2"
                        onClick={this.calculateHandler}
                      >
                        Calculate
                      </button>
                    )}
                    <Drawer
                      anchor="right"
                      open={this.state.openDrawer}
                      variant="temporary"
                      elevation={20}
                      style={{ overflow: "initial" }}
                    >
                      <div className="row" style={{ width: 800 }}>
                        <div className="col-lg-12 pt-4 border-bottom">
                          <label className="label-text pt-0 mb-0">
                            Add Quantity Allocation
                          </label>
                        </div>
                        {this.state.quantityRows.length > 0 && (
                          <div className="p-3 lr-3 table-responsive ">
                            <table className="border-0 root">
                              <thead className="thead-light">
                                <tr>
                                  <th
                                    className="table_header_label"
                                    style={{ minWidth: 240 }}
                                  >
                                    Contract No
                                  </th>
                                  <th
                                    className="table_header_label"
                                    style={{ minWidth: 100 }}
                                  >
                                    Quality
                                  </th>
                                  <th
                                    className="table_header_label"
                                    style={{ minWidth: 100 }}
                                  >
                                    Tolerance
                                  </th>
                                  <th
                                    className="table_header_label"
                                    style={{ minWidth: 130 }}
                                    nowrap="true"
                                  >
                                    Inco Terms
                                  </th>
                                  <th
                                    className="table_header_label"
                                    style={{ minWidth: 100 }}
                                  >
                                    Quantity
                                  </th>
                                  <th
                                    className="table_header_label"
                                    style={{ minWidth: 100 }}
                                  >
                                    Remaining
                                  </th>
                                </tr>
                              </thead>
                              {this.state.quantityRows.map((e, index) => (
                                <tbody
                                  className="border"
                                  key={index}
                                  style={{
                                    display:
                                      e.purchase_types.length > 0 ? "" : "none",
                                  }}
                                >
                                  <tr>
                                    <td className="table_value">
                                      {e.purchase_quantityAllocating && (
                                        <span className="drawer_quantity_allocation">
                                          Quantity Allocation:{" "}
                                          {e.purchase_quantityAllocating}
                                        </span>
                                      )}
                                      {e.purchase_contract}
                                    </td>
                                    {e.purchase_quality.length > 0 ? (
                                      <td className="table_value">
                                        {e.purchase_quality[0].quality}
                                      </td>
                                    ) : (
                                      <td></td>
                                    )}
                                    {e.purchase_quality.length > 0 ? (
                                      <td className="table_value">
                                        {e.purchase_quality[0]
                                          .tolerance_adjusted + " %"}
                                      </td>
                                    ) : (
                                      <td></td>
                                    )}
                                    {e.purchase_types.length > 0 ? (
                                      <td className="table_value" nowrap="true">
                                        {e.purchase_types[0].purchase_type}
                                      </td>
                                    ) : (
                                      <td></td>
                                    )}
                                    {e.purchase_quality.length > 0 ? (
                                      <td className="table_value">
                                        {this.toLocaleString(
                                          e.purchase_quality[0]
                                            .quantity_adjusted
                                        )}
                                      </td>
                                    ) : (
                                      <td></td>
                                    )}
                                    {e.purchase_quality.length > 0 ? (
                                      <td className="table_value">
                                        {this.toLocaleString(
                                          this._toBeDelivered(
                                            e.purchase_quality[0]
                                          )
                                        )}
                                      </td>
                                    ) : (
                                      <td></td>
                                    )}
                                  </tr>
                                  {e.sales_types.length > 0 && (
                                    <tr>
                                      <td className="table_value">
                                        {e.sales_contract}
                                      </td>
                                      {e.sales_quality.length > 0 ? (
                                        <td className="table_value">
                                          {e.sales_quality[0].quality}
                                        </td>
                                      ) : (
                                        <td></td>
                                      )}
                                      {e.sales_quality.length > 0 ? (
                                        <td className="table_value">
                                          {e.sales_quality[0]
                                            .tolerance_adjusted + " %"}
                                        </td>
                                      ) : (
                                        <td></td>
                                      )}
                                      {e.sales_types.length > 0 ? (
                                        <td
                                          className="table_value"
                                          nowrap="true"
                                        >
                                          {e.sales_types[0].sales_type}
                                        </td>
                                      ) : (
                                        <td></td>
                                      )}
                                      {e.sales_quality.length > 0 ? (
                                        <td className="table_value">
                                          {this.toLocaleString(
                                            e.sales_quality[0].quantity_adjusted
                                          )}
                                        </td>
                                      ) : (
                                        <td></td>
                                      )}
                                      {e.sales_quality.length > 0 ? (
                                        <td className="table_value">
                                          {this.toLocaleString(
                                            this._toBeDelivered(
                                              e.sales_quality[0]
                                            )
                                          )}
                                        </td>
                                      ) : (
                                        <td></td>
                                      )}
                                    </tr>
                                  )}
                                </tbody>
                              ))}
                            </table>
                          </div>
                        )}
                        {this.state.purchaseContractEnable && (
                          <div className="col-lg-12 mb-2 mt-2">
                            <div className="row">
                              <div className="col-lg-4 pl-0">
                                <label className="form_label mb-1">
                                  Purchase Contract
                                </label>
                                <Autocomplete
                                  options={this.state.purchaseDataForMenu}
                                  getOptionLabel={(option) => option.name}
                                  onChange={(event, value) =>
                                    this.handlePurchaseContract(
                                      this.state.quantityRows.length - 1,
                                      value
                                    )
                                  }
                                  InputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                    },
                                  }}
                                  size="small"
                                  name="purchaseContract"
                                  renderOption={(option) => (
                                    <React.Fragment>
                                      {option.name} ({option.vendor_name})
                                    </React.Fragment>
                                  )}
                                  // value= {{name: this.state.quantityRows[this.state.modalID].purchase_contract, value: this.state.quantityRows[this.state.modalID].purchase_contract}}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder="Select Contract"
                                      // label="Purchase Contract *"
                                      // error={this.state.quantityRows[this.state.modalID].purchase_contractError}
                                      variant="outlined"
                                      fullWidth
                                    />
                                  )}
                                />
                              </div>
                              {this.state.purchaseQualityLoading && (
                                <div
                                  className="col-lg-1 pl-0"
                                  style={{ position: "relative", top: 25 }}
                                >
                                  <CircularProgress size={35} />
                                </div>
                              )}
                            </div>
                            {this.state.purchaseContractNo && (
                              <label className="form_label mb-2 mt-2">
                                Select Purchase Quality
                              </label>
                            )}
                            <div className="row col-lg-12 p-0">
                              {this.state.purchaseTypeData.map((e, index) => (
                                <div
                                  className="col col-lg-4 p-1 mb-2"
                                  key={index}
                                >
                                  <div
                                    className="row col-lg-12 rounded p-0"
                                    style={{
                                      border:
                                        e.id === this.state.qualitySelection
                                          ? "1px solid #ec7833"
                                          : "1px solid #dee2e6",
                                    }}
                                  >
                                    <div className="col-lg-1 p-0">
                                      <NewRadio
                                        checked={
                                          e.id === this.state.qualitySelection
                                        }
                                        onChange={
                                          this.handlePurchaseTypesRadioButtons
                                        }
                                        value={e.id}
                                        name="qualitySelection"
                                        inputProps={{ "aria-label": "B" }}
                                        className="collapsed"
                                        data-toggle="collapse"
                                        data-target={"#demo" + e.id}
                                        aria-expanded="true"
                                        aria-controls={"group-of-rows-1"}
                                      />
                                    </div>
                                    <div className="col-lg-11 pr-0">
                                      <div className="row col-lg-12 p-0">
                                        <div className="col-lg-6 pl-0">
                                          <label className="drawer_section_label mb-0">
                                            Quality
                                          </label>
                                          <label className="drawer_section_value mb-0">
                                            {e.quality}
                                          </label>
                                        </div>
                                        <div className="col-lg-6 pr-0">
                                          <label className="drawer_section_label mb-0">
                                            Tolerence
                                          </label>
                                          <label className="drawer_section_value mb-0">
                                            {e.tolerance_adjusted + "%"}
                                          </label>
                                        </div>
                                      </div>
                                      <div className="row col-lg-12 p-0">
                                        <div className="col-lg-6 pl-0">
                                          <label className="drawer_section_label mb-0">
                                            Quantity
                                          </label>
                                        </div>
                                        <div className="col-lg-6 pr-0">
                                          <label className="drawer_section_total mb-0">
                                            Total
                                          </label>
                                          <label className="drawer_section_total_value mb-0">
                                            {this.toLocaleString(
                                              Number(
                                                e.quantity_adjusted
                                              ).toFixed(3)
                                            )}
                                          </label>
                                        </div>
                                      </div>
                                      <div style={{ width: "98%" }}>
                                        <BorderLinearProgress
                                          variant="determinate"
                                          value={this.progressBarSize(e)}
                                          style={{ borderRadius: 5 }}
                                        />
                                      </div>
                                      <div className="row col-lg-12 p-0 mb-2">
                                        <div className="col-lg-4 pl-0">
                                          <label className="drawer_section_value mb-0">
                                            {this.toLocaleString(
                                              Number(
                                                e.quantity_allocated
                                              ).toFixed(3)
                                            )}
                                          </label>
                                          <label className="drawer_section_label mb-0">
                                            Allocated
                                          </label>
                                        </div>
                                        <div className="col-lg-4 pl-0">
                                          <label className="drawer_section_value mb-0">
                                            {this.toLocaleString(
                                              Number(
                                                e.pc_quantity_delivered
                                              ).toFixed(3)
                                            )}
                                          </label>
                                          <label className="drawer_section_label mb-0">
                                            Delivered
                                          </label>
                                        </div>
                                        <div className="col-lg-4 pl-0">
                                          <label className="drawer_section_value mb-0">
                                            {this.toLocaleString(
                                              this._toBeDelivered(e)
                                            )}
                                          </label>
                                          <label className="drawer_section_label mb-0">
                                            Remaining
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                            {this.state.qualitySelection && (
                              <label className="form_label mb-2 mt-2">
                                Select Purchase Type
                              </label>
                            )}
                            <div className="pl-0">
                              {this.state.purchaseTypeData.map((e, index) => (
                                <div key={index}>
                                  {e.id === this.state.qualitySelection && (
                                    <table style={{ width: "100%" }}>
                                      <thead className="thead-light">
                                        <tr>
                                          <th></th>
                                          <th className="drawer_table_header_label">
                                            Mine Name
                                          </th>
                                          <th className="drawer_table_header_label">
                                            Purchase Type
                                          </th>
                                          <th className="drawer_table_header_label">
                                            Anchorage Name
                                          </th>
                                          <th className="drawer_table_header_label">
                                            Coal Price
                                          </th>
                                        </tr>
                                      </thead>
                                      {e.purchase_types.map((p, idx) => (
                                        <tbody key={idx}>
                                          <tr
                                            style={{
                                              border:
                                                p.id ===
                                                this.state.purchaseTypeSelection
                                                  ? "1px solid #ec7833"
                                                  : "1px solid #dee2e6",
                                            }}
                                          >
                                            <td
                                              style={{
                                                verticalAlign: "middle",
                                              }}
                                            >
                                              <NewRadio
                                                checked={
                                                  p.id ===
                                                  this.state
                                                    .purchaseTypeSelection
                                                }
                                                onChange={
                                                  this
                                                    .handlePurchaseTypesRadioButtons
                                                }
                                                value={p.id}
                                                name="purchaseTypeSelection"
                                                inputProps={{
                                                  "aria-label": "B",
                                                }}
                                              />
                                            </td>
                                            <td className="drawer_table_value">
                                              {p.mine_name}
                                            </td>
                                            <td className="drawer_table_value">
                                              {p.purchase_type}
                                            </td>
                                            <td className="drawer_table_value">
                                              {p.purchase_type ===
                                              "FAS Anchorage"
                                                ? p.anchorage_name
                                                : ""}
                                            </td>
                                            <td className="drawer_table_value">
                                              {p.price_pmt}{" "}
                                              {this.state.purchaseCurrency}
                                            </td>
                                          </tr>
                                        </tbody>
                                      ))}
                                    </table>
                                  )}
                                </div>
                              ))}
                            </div>
                            {this.state.purchaseTypeSelection && (
                              <div className="row col-lg-12 pl-0 mt-2">
                                <button
                                  type="button"
                                  className="drawer_button drawer_text"
                                  onClick={() =>
                                    this.purchaseTypeSelected(
                                      this.state.modalID
                                    )
                                  }
                                >
                                  Next
                                </button>
                              </div>
                            )}
                          </div>
                        )}
                        {this.state.salesContractEnable && (
                          <div className="col-lg-12 mb-2 mt-2">
                            <div className="row">
                              <div className="col-lg-4 pl-0">
                                <label className="form_label mb-1">
                                  Sales Contract
                                </label>
                                <Autocomplete
                                  options={this.state.salesDataforMenu}
                                  getOptionLabel={(option) => option.name}
                                  onChange={(event, value) =>
                                    this.handleSalesContract(
                                      this.state.quantityRows.length - 1,
                                      value
                                    )
                                  }
                                  name="purchaseContract"
                                  renderOption={(option) => (
                                    <React.Fragment>
                                      {option.name} ({option.customer_name})
                                    </React.Fragment>
                                  )}
                                  // value= {{name: this.state.quantityRows[index].sales_contract, value: this.state.quantityRows[index].sales_contract}}
                                  size="small"
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      // error={this.state.quantityRows[index].sales_contractError}
                                      placeholder="Select Contract"
                                      // label="Sales Contract *"
                                      variant="outlined"
                                      fullWidth
                                    />
                                  )}
                                />
                              </div>
                              {this.state.salesQualityLoading && (
                                <div
                                  className="col-lg-1 pl-0"
                                  style={{ position: "relative", top: 25 }}
                                >
                                  <CircularProgress size={35} />
                                </div>
                              )}
                            </div>
                            {this.state.salesContractNo && (
                              <label className="form_label mb-2 mt-2">
                                Select Sales Quality
                              </label>
                            )}
                            <div className="row col-lg-12 pl-0">
                              {this.state.salesTypeData.map((e, index) => (
                                <div
                                  className="col col-lg-4 p-1 mb-2"
                                  key={index}
                                >
                                  <div
                                    className="row col-lg-12 rounded p-0"
                                    style={{
                                      border:
                                        e.id ===
                                        this.state.salesQualitySelection
                                          ? "1px solid #ec7833"
                                          : "1px solid #dee2e6",
                                    }}
                                  >
                                    <div className="col-lg-1 p-0">
                                      <NewRadio
                                        checked={
                                          e.id ===
                                          this.state.salesQualitySelection
                                        }
                                        onChange={
                                          this.handleSalesTypesRadioButtons
                                        }
                                        value={e.id}
                                        name="salesQualitySelection"
                                        inputProps={{ "aria-label": "B" }}
                                        className="collapsed"
                                        data-toggle="collapse"
                                        data-target={"#sales" + e.id}
                                        aria-expanded="false"
                                        aria-controls={"group-of-rows-1"}
                                      />
                                    </div>
                                    <div className="col-lg-11 pr-0">
                                      <div className="row col-lg-12 p-0">
                                        <div className="col-lg-6 pl-0">
                                          <label className="drawer_section_label mb-0">
                                            Quality
                                          </label>
                                          <label className="drawer_section_value mb-0">
                                            {e.quality}
                                          </label>
                                        </div>
                                        <div className="col-lg-6 pr-0">
                                          <label className="drawer_section_label mb-0">
                                            Tolerence
                                          </label>
                                          <label className="drawer_section_value mb-0">
                                            {e.tolerance_adjusted + "%"}
                                          </label>
                                        </div>
                                      </div>
                                      <div className="row col-lg-12 p-0">
                                        <div className="col-lg-6 pl-0">
                                          <label className="drawer_section_label mb-0">
                                            Quantity
                                          </label>
                                        </div>
                                        <div className="col-lg-6 pr-0">
                                          <label className="drawer_section_total mb-0">
                                            Total
                                          </label>
                                          <label className="drawer_section_total_value mb-0">
                                            {this.toLocaleString(
                                              Number(
                                                e.quantity_adjusted
                                              ).toFixed(3)
                                            )}
                                          </label>
                                        </div>
                                      </div>
                                      <div style={{ width: "98%" }}>
                                        <BorderLinearProgress
                                          variant="determinate"
                                          value={this.progressBarSize(e)}
                                          style={{ borderRadius: 5 }}
                                        />
                                      </div>
                                      <div className="row col-lg-12 p-0 mb-2">
                                        <div className="col-lg-4 pl-0">
                                          <label className="drawer_section_value mb-0">
                                            {this.toLocaleString(
                                              Number(
                                                e.quantity_allocated
                                              ).toFixed(3)
                                            )}
                                          </label>
                                          <label className="drawer_section_label mb-0">
                                            Allocated
                                          </label>
                                        </div>
                                        <div className="col-lg-4 pl-0">
                                          <label className="drawer_section_value mb-0">
                                            {this.toLocaleString(
                                              Number(
                                                e.sc_quantity_delivered
                                              ).toFixed(3)
                                            )}
                                          </label>
                                          <label className="drawer_section_label mb-0">
                                            Delivered
                                          </label>
                                        </div>
                                        <div className="col-lg-4 pl-0">
                                          <label className="drawer_section_value mb-0">
                                            {this.toLocaleString(
                                              this._toBeDelivered(e)
                                            )}
                                          </label>
                                          <label className="drawer_section_label mb-0">
                                            Remaining
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                            {this.state.salesQualitySelection && (
                              <label className="form_label mb-2 mt-2">
                                Select Sales Type
                              </label>
                            )}
                            <div className="pl-0">
                              {this.state.salesTypeData.map((e, index) => (
                                <div key={index}>
                                  {e.id ===
                                    this.state.salesQualitySelection && (
                                    <table style={{ width: "100%" }}>
                                      <thead className="thead-light">
                                        <tr>
                                          <th></th>
                                          <th className="drawer_table_header_label">
                                            Mine Name
                                          </th>
                                          <th className="drawer_table_header_label">
                                            Purchase Type
                                          </th>
                                          <th className="drawer_table_header_label">
                                            Anchorage Name
                                          </th>
                                          <th className="drawer_table_header_label">
                                            Coal Price
                                          </th>
                                        </tr>
                                      </thead>
                                      {e.sales_types.map((s, idx) => (
                                        <tbody key={idx}>
                                          <tr
                                            style={{
                                              border:
                                                s.id ===
                                                this.state.salesTypeSelection
                                                  ? "1px solid #ec7833"
                                                  : "1px solid #dee2e6",
                                            }}
                                          >
                                            <td
                                              style={{
                                                verticalAlign: "middle",
                                              }}
                                            >
                                              <NewRadio
                                                checked={
                                                  s.id ===
                                                  this.state.salesTypeSelection
                                                }
                                                onChange={
                                                  this
                                                    .handleSalesTypesRadioButtons
                                                }
                                                value={s.id}
                                                name="salesTypeSelection"
                                                inputProps={{
                                                  "aria-label": "B",
                                                }}
                                              />
                                            </td>
                                            <td className="drawer_table_value">
                                              {s.mine_name}
                                            </td>
                                            <td className="drawer_table_value">
                                              {s.sales_type}
                                            </td>
                                            <td className="drawer_table_value">
                                              {s.sales_type === "FAS Anchorage"
                                                ? s.anchorage_name
                                                : ""}
                                            </td>
                                            <td className="drawer_table_value">
                                              {s.price_pmt}{" "}
                                              {this.state.purchaseCurrency}
                                            </td>
                                          </tr>
                                        </tbody>
                                      ))}
                                    </table>
                                  )}
                                </div>
                              ))}
                            </div>
                            {this.state.salesTypeSelection && (
                              <div className="row col-lg-12 pl-0 mt-2">
                                <button
                                  type="button"
                                  className="drawer_button drawer_text mr-3"
                                  onClick={() =>
                                    this.salesTypeSelected(this.state.modalID)
                                  }
                                >
                                  Next
                                </button>
                              </div>
                            )}
                          </div>
                        )}
                        {this.state.quantityAllocationEnable && (
                          <div className="col-lg-6 mb-4 mt-2">
                            <label className="form_label mb-1">
                              Quantity Allocation
                            </label>
                            <TextField
                              name="purchase_quantityAllocating"
                              margin="dense"
                              variant="outlined"
                              // label='Quantity Allocation *'
                              fullWidth
                              autoComplete="off"
                              placeholder="Value"
                              value={
                                this.state.quantityRows.length > 0
                                  ? this.state.quantityRows[
                                      this.state.quantityRows.length - 1
                                    ].purchase_quantityAllocating
                                  : ""
                              }
                              error={
                                this.state.quantityRows.length > 0
                                  ? this.state.quantityRows[
                                      this.state.quantityRows.length - 1
                                    ].quantityAllocationError
                                  : false
                              }
                              onChange={this.handleQuantityRowsTextHandler(
                                this.state.quantityRows.length - 1
                              )}
                              helperText={
                                this.state.quantityAllocationHelperText
                              }
                              InputProps={{
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                },
                              }}
                            />
                            {this.state.quantityRows.length > 0 &&
                              this.state.quantityRows[
                                this.state.quantityRows.length - 1
                              ].purchase_quantityAllocating !== "" && (
                                <div className="row col-lg-12 pl-0 mt-2">
                                  <button
                                    type="button"
                                    className="drawer_button drawer_text mr-3"
                                    onClick={() =>
                                      this.setState({
                                        quantityAllocationEnable: false,
                                        addRowEnable: true,
                                        quantityAllocationHelperText: "",
                                      })
                                    }
                                  >
                                    Save
                                  </button>
                                </div>
                              )}
                          </div>
                        )}
                        {this.state.addRowEnable && (
                          <div className="row col-lg-12 pl-0 ml-3 mt-2 mb-2">
                            <button
                              type="button"
                              onClick={this.addMoreQuantityRows}
                              className="header_button header_button_text addrow_button_adjustment"
                              style={{ color: config.themeColor, width: 110 }}
                            >
                              Add Row
                            </button>
                          </div>
                        )}
                        <div
                          className="row col-lg-12 modal-footer justify-content-end"
                          style={{
                            position: "sticky",
                            bottom: 0,
                            background: "white",
                          }}
                        >
                          <button
                            type="button"
                            className="drawer_button drawer_text drawer_calculate_button drawer_calculate_adjustment"
                            style={{
                              opacity: !this.state.addRowEnable ? "0.5" : "1",
                            }}
                            disabled={!this.state.addRowEnable}
                            onClick={this.calculateHandler}
                          >
                            Calculate
                          </button>
                          <button
                            type="button"
                            className="drawer_cancel_button drawer_cancel_text mr-1"
                            style={{
                              opacity: this.state.addRowEnable ? "0.5" : "1",
                            }}
                            disabled={this.state.addRowEnable}
                            onClick={() => this.drawerClose(this.state.modalID)}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="drawer_button drawer_text"
                            style={{
                              opacity: !this.state.addRowEnable ? "0.5" : "1",
                            }}
                            disabled={!this.state.addRowEnable}
                            onClick={() =>
                              this.setState({
                                quantityAllocationEnable: false,
                                openDrawer: false,
                                displayQuntityAllocations: true,
                              })
                            }
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </Drawer>

                    <Drawer
                      anchor="right"
                      open={this.state.enableEditingDrawer}
                      variant="temporary"
                      elevation={20}
                      style={{ overflow: "initial" }}
                    >
                      <div className="row" style={{ width: 800 }}>
                        <div className="col-lg-12 pt-4 border-bottom">
                          <label className="label-text pt-0 mb-0">
                            Add Quantity Allocation
                          </label>
                        </div>
                        {this.state.purchaseContractEnable && (
                          <div className="col-lg-12 mb-2 mt-2">
                            <div className="row">
                              <div className="col-lg-4 pl-0">
                                <label className="form_label mb-1">
                                  Purchase Contract
                                </label>
                                <Autocomplete
                                  options={this.state.purchaseDataForMenu}
                                  getOptionLabel={(option) => option.name}
                                  onChange={(event, value) =>
                                    this.inlineHandlePurchaseContract(
                                      this.state.editingIndex,
                                      value
                                    )
                                  }
                                  InputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                    },
                                  }}
                                  size="small"
                                  name="purchaseContract"
                                  renderOption={(option) => (
                                    <React.Fragment>
                                      {option.name} ({option.vendor_name})
                                    </React.Fragment>
                                  )}
                                  value={
                                    this.state.quantityRows.length > 0
                                      ? {
                                          name: this.state.quantityRows[
                                            this.state.editingIndex
                                          ].purchase_contract,
                                          value:
                                            this.state.quantityRows[
                                              this.state.editingIndex
                                            ].purchase_contract,
                                        }
                                      : null
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder="Select Contract"
                                      // label="Purchase Contract *"
                                      // error={this.state.quantityRows[this.state.modalID].purchase_contractError}
                                      variant="outlined"
                                      fullWidth
                                    />
                                  )}
                                />
                              </div>
                              {this.state.purchaseQualityLoading && (
                                <div
                                  className="col-lg-1 pl-0"
                                  style={{ position: "relative", top: 25 }}
                                >
                                  <CircularProgress size={35} />
                                </div>
                              )}
                            </div>
                            <label className="form_label mb-2 mt-2">
                              Select Purchase Quality
                            </label>
                            <div className="row col-lg-12 p-0">
                              {this.state.purchaseTypeData.map((e, index) => (
                                <div
                                  className="col col-lg-4 p-1 mb-2"
                                  key={index}
                                >
                                  <div
                                    className="row col-lg-12 rounded p-0"
                                    style={{
                                      border:
                                        e.id === this.state.qualitySelection
                                          ? "1px solid #ec7833"
                                          : "1px solid #dee2e6",
                                    }}
                                  >
                                    <div className="col-lg-1 p-0">
                                      <NewRadio
                                        checked={
                                          e.id === this.state.qualitySelection
                                        }
                                        onChange={
                                          this
                                            .handlePurchaseTypesRadioButtonsEdit
                                        }
                                        value={e.id}
                                        name="qualitySelection"
                                        inputProps={{ "aria-label": "B" }}
                                        className="collapsed"
                                        data-toggle="collapse"
                                        data-target={"#demo" + e.id}
                                        aria-expanded="true"
                                        aria-controls={"group-of-rows-1"}
                                      />
                                    </div>
                                    <div className="col-lg-11 pr-0">
                                      <div className="row col-lg-12 p-0">
                                        <div className="col-lg-6 pl-0">
                                          <label className="drawer_section_label mb-0">
                                            Quality
                                          </label>
                                          <label className="drawer_section_value mb-0">
                                            {e.quality}
                                          </label>
                                        </div>
                                        <div className="col-lg-6 pr-0">
                                          <label className="drawer_section_label mb-0">
                                            Tolerence
                                          </label>
                                          <label className="drawer_section_value mb-0">
                                            {e.tolerance_adjusted + "%"}
                                          </label>
                                        </div>
                                      </div>
                                      <div className="row col-lg-12 p-0">
                                        <div className="col-lg-6 pl-0">
                                          <label className="drawer_section_label mb-0">
                                            Quantity
                                          </label>
                                        </div>
                                        <div className="col-lg-6 pr-0">
                                          <label className="drawer_section_total mb-0">
                                            Total
                                          </label>
                                          <label className="drawer_section_total_value mb-0">
                                            {this.toLocaleString(
                                              Number(
                                                e.quantity_adjusted
                                              ).toFixed(3)
                                            )}
                                          </label>
                                        </div>
                                      </div>
                                      <div style={{ width: "98%" }}>
                                        <BorderLinearProgress
                                          variant="determinate"
                                          value={this.progressBarSize(e)}
                                          style={{ borderRadius: 5 }}
                                        />
                                      </div>
                                      <div className="row col-lg-12 p-0 mb-2">
                                        <div className="col-lg-4 pl-0">
                                          <label className="drawer_section_value mb-0">
                                            {this.toLocaleString(
                                              Number(
                                                e.quantity_allocated
                                              ).toFixed(3)
                                            )}
                                          </label>
                                          <label className="drawer_section_label mb-0">
                                            Allocated
                                          </label>
                                        </div>
                                        <div className="col-lg-4 pl-0">
                                          <label className="drawer_section_value mb-0">
                                            {this.toLocaleString(
                                              Number(
                                                e.pc_quantity_delivered
                                              ).toFixed(3)
                                            )}
                                          </label>
                                          <label className="drawer_section_label mb-0">
                                            Delivered
                                          </label>
                                        </div>
                                        <div className="col-lg-4 pl-0">
                                          <label className="drawer_section_value mb-0">
                                            {this.toLocaleString(
                                              this._toBeDelivered(e)
                                            )}
                                          </label>
                                          <label className="drawer_section_label mb-0">
                                            Remaining
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                            <label className="form_label mb-2 mt-2">
                              Select Purchase Type
                            </label>
                            <div className="pl-0">
                              {this.state.purchaseTypeData.map((e, index) => (
                                <div key={index}>
                                  {e.id === this.state.qualitySelection && (
                                    <table style={{ width: "100%" }}>
                                      <thead className="thead-light">
                                        <tr>
                                          <th></th>
                                          <th className="drawer_table_header_label">
                                            Mine Name
                                          </th>
                                          <th className="drawer_table_header_label">
                                            Purchase Type
                                          </th>
                                          <th className="drawer_table_header_label">
                                            Anchorage Name
                                          </th>
                                          <th className="drawer_table_header_label">
                                            Coal Price
                                          </th>
                                        </tr>
                                      </thead>
                                      {e.purchase_types.map((p, idx) => (
                                        <tbody key={idx}>
                                          <tr
                                            style={{
                                              border:
                                                p.id ===
                                                this.state.purchaseTypeSelection
                                                  ? "1px solid #ec7833"
                                                  : "1px solid #dee2e6",
                                            }}
                                          >
                                            <td
                                              style={{
                                                verticalAlign: "middle",
                                              }}
                                            >
                                              <NewRadio
                                                checked={
                                                  p.id ===
                                                  this.state
                                                    .purchaseTypeSelection
                                                }
                                                onChange={
                                                  this
                                                    .handlePurchaseTypesRadioButtonsEdit
                                                }
                                                value={p.id}
                                                name="purchaseTypeSelection"
                                                inputProps={{
                                                  "aria-label": "B",
                                                }}
                                              />
                                            </td>
                                            <td className="drawer_table_value">
                                              {p.mine_name}
                                            </td>
                                            <td className="drawer_table_value">
                                              {p.purchase_type}
                                            </td>
                                            <td className="drawer_table_value">
                                              {p.purchase_type ===
                                              "FAS Anchorage"
                                                ? p.anchorage_name
                                                : ""}
                                            </td>
                                            <td className="drawer_table_value">
                                              {p.price_pmt}{" "}
                                              {this.state.purchaseCurrency}
                                            </td>
                                          </tr>
                                        </tbody>
                                      ))}
                                    </table>
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                        {this.state.salesContractEnable && (
                          <div className="col-lg-12 mb-2 mt-2">
                            <div className="row">
                              <div className="col-lg-4 pl-0">
                                <label className="form_label mb-1">
                                  Sales Contract
                                </label>
                                <Autocomplete
                                  options={this.state.salesDataforMenu}
                                  getOptionLabel={(option) => option.name}
                                  onChange={(event, value) =>
                                    this.inlineHandleSalesContract(
                                      this.state.editingIndex,
                                      value
                                    )
                                  }
                                  name="purchaseContract"
                                  renderOption={(option) => (
                                    <React.Fragment>
                                      {option.name} ({option.customer_name})
                                    </React.Fragment>
                                  )}
                                  value={{
                                    name: this.state.quantityRows[
                                      this.state.editingIndex
                                    ].sales_contract,
                                    value:
                                      this.state.quantityRows[
                                        this.state.editingIndex
                                      ].sales_contract,
                                  }}
                                  size="small"
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      // error={this.state.quantityRows[index].sales_contractError}
                                      placeholder="Select Contract"
                                      // label="Sales Contract *"
                                      variant="outlined"
                                      fullWidth
                                    />
                                  )}
                                />
                              </div>
                              {this.state.salesQualityLoading && (
                                <div
                                  className="col-lg-1 pl-0"
                                  style={{ position: "relative", top: 25 }}
                                >
                                  <CircularProgress size={35} />
                                </div>
                              )}
                            </div>
                            <label className="form_label mb-2 mt-2">
                              Select Sales Quality
                            </label>
                            <div className="row col-lg-12 pl-0">
                              {this.state.salesTypeData.map((e, index) => (
                                // <div className="col-lg-4 rounded p-0 mb-2" style={{border: e.id === this.state.salesQualitySelection ? '1px solid #ec7833' : '1px solid #dee2e6'}} key={index}>
                                //   <div className="row col-lg-12 p-0">
                                //     <div className="col-lg-1 p-0">
                                //       <NewRadio
                                <div
                                  className="col col-lg-4 p-1 mb-2"
                                  key={index}
                                >
                                  <div
                                    className="row col-lg-12 rounded p-0"
                                    style={{
                                      border:
                                        e.id ===
                                        this.state.salesQualitySelection
                                          ? "1px solid #ec7833"
                                          : "1px solid #dee2e6",
                                    }}
                                  >
                                    <div className="col-lg-1 p-0">
                                      <NewRadio
                                        checked={
                                          e.id ===
                                          this.state.salesQualitySelection
                                        }
                                        onChange={
                                          this.handleSalesTypesRadioButtonsEdit
                                        }
                                        value={e.id}
                                        name="salesQualitySelection"
                                        inputProps={{ "aria-label": "B" }}
                                        className="collapsed"
                                        data-toggle="collapse"
                                        data-target={"#sales" + e.id}
                                        aria-expanded="false"
                                        aria-controls={"group-of-rows-1"}
                                      />
                                    </div>
                                    <div className="col-lg-11 pr-0">
                                      <div className="row col-lg-12 p-0">
                                        <div className="col-lg-6 pl-0">
                                          <label className="drawer_section_label mb-0">
                                            Quality
                                          </label>
                                          <label className="drawer_section_value mb-0">
                                            {e.quality}
                                          </label>
                                        </div>
                                        <div className="col-lg-6 pr-0">
                                          <label className="drawer_section_label mb-0">
                                            Tolerence
                                          </label>
                                          <label className="drawer_section_value mb-0">
                                            {e.tolerance_adjusted + "%"}
                                          </label>
                                        </div>
                                      </div>
                                      <div className="row col-lg-12 p-0">
                                        <div className="col-lg-6 pl-0">
                                          <label className="drawer_section_label mb-0">
                                            Quantity
                                          </label>
                                        </div>
                                        <div className="col-lg-6 pr-0">
                                          <label className="drawer_section_total mb-0">
                                            Total
                                          </label>
                                          <label className="drawer_section_total_value mb-0">
                                            {this.toLocaleString(
                                              Number(
                                                e.quantity_adjusted
                                              ).toFixed(3)
                                            )}
                                          </label>
                                        </div>
                                      </div>
                                      <div style={{ width: "98%" }}>
                                        <BorderLinearProgress
                                          variant="determinate"
                                          value={this.progressBarSize(e)}
                                          style={{ borderRadius: 5 }}
                                        />
                                      </div>
                                      <div className="row col-lg-12 p-0 mb-2">
                                        <div className="col-lg-4 pl-0">
                                          <label className="drawer_section_value mb-0">
                                            {this.toLocaleString(
                                              Number(
                                                e.quantity_allocated
                                              ).toFixed(3)
                                            )}
                                          </label>
                                          <label className="drawer_section_label mb-0">
                                            Allocated
                                          </label>
                                        </div>
                                        <div className="col-lg-4 pl-0">
                                          <label className="drawer_section_value mb-0">
                                            {this.toLocaleString(
                                              Number(
                                                e.sc_quantity_delivered
                                              ).toFixed(3)
                                            )}
                                          </label>
                                          <label className="drawer_section_label mb-0">
                                            Delivered
                                          </label>
                                        </div>
                                        <div className="col-lg-4 pl-0">
                                          <label className="drawer_section_value mb-0">
                                            {this.toLocaleString(
                                              this._toBeDelivered(e)
                                            )}
                                          </label>
                                          <label className="drawer_section_label mb-0">
                                            Remaining
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                            <label className="form_label mb-2 mt-2">
                              Select Sales Type
                            </label>
                            <div className="pl-0">
                              {this.state.salesTypeData.map((e, index) => (
                                <div key={index}>
                                  {e.id ===
                                    this.state.salesQualitySelection && (
                                    <table style={{ width: "100%" }}>
                                      <thead className="thead-light">
                                        <tr>
                                          <th></th>
                                          <th className="drawer_table_header_label">
                                            Mine Name
                                          </th>
                                          <th className="drawer_table_header_label">
                                            Purchase Type
                                          </th>
                                          <th className="drawer_table_header_label">
                                            Anchorage Name
                                          </th>
                                          <th className="drawer_table_header_label">
                                            Coal Price
                                          </th>
                                        </tr>
                                      </thead>
                                      {e.sales_types.map((s, idx) => (
                                        <tbody key={idx}>
                                          <tr
                                            style={{
                                              border:
                                                s.id ===
                                                this.state.salesTypeSelection
                                                  ? "1px solid #ec7833"
                                                  : "1px solid #dee2e6",
                                            }}
                                          >
                                            <td
                                              style={{
                                                verticalAlign: "middle",
                                              }}
                                            >
                                              <NewRadio
                                                checked={
                                                  s.id ===
                                                  this.state.salesTypeSelection
                                                }
                                                onChange={
                                                  this
                                                    .handleSalesTypesRadioButtonsEdit
                                                }
                                                value={s.id}
                                                name="salesTypeSelection"
                                                inputProps={{
                                                  "aria-label": "B",
                                                }}
                                              />
                                            </td>
                                            <td className="drawer_table_value">
                                              {s.mine_name}
                                            </td>
                                            <td className="drawer_table_value">
                                              {s.sales_type}
                                            </td>
                                            <td className="drawer_table_value">
                                              {s.sales_type === "FAS Anchorage"
                                                ? s.anchorage_name
                                                : ""}
                                            </td>
                                            <td className="drawer_table_value">
                                              {s.price_pmt}{" "}
                                              {this.state.purchaseCurrency}
                                            </td>
                                          </tr>
                                        </tbody>
                                      ))}
                                    </table>
                                  )}
                                </div>
                              ))}
                            </div>
                            <div className="col-lg-6 mb-4 mt-2 pl-0">
                              <label className="form_label mb-1">
                                Quantity Allocation
                              </label>
                              <TextField
                                name="purchase_quantityAllocating"
                                margin="dense"
                                variant="outlined"
                                // label='Quantity Allocation *'
                                fullWidth
                                placeholder="Value"
                                value={
                                  this.state.editingIndex !== null
                                    ? this.state.quantityRows[
                                        this.state.editingIndex
                                      ].purchase_quantityAllocating
                                    : ""
                                }
                                error={
                                  this.state.editingIndex !== null
                                    ? this.state.quantityRows[
                                        this.state.editingIndex
                                      ].quantityAllocationError
                                    : false
                                }
                                onChange={this.handleQuantityRowsTextHandler(
                                  this.state.editingIndex
                                )}
                                helperText={
                                  this.state.quantityAllocationHelperText
                                }
                                autoComplete="off"
                                InputProps={{
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                  },
                                }}
                              />
                            </div>
                          </div>
                        )}
                        <div
                          className="row col-lg-12 modal-footer justify-content-end"
                          style={{
                            position: "sticky",
                            bottom: 0,
                            background: "white",
                          }}
                        >
                          <button
                            type="button"
                            className="drawer_button drawer_text"
                            onClick={this.onSave}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </Drawer>
                  </div>
                )}

                {this.state.activeStep === 2 && (
                  <div className="section_block" style={{ width: "85%" }}>
                    {this.state.consignmentType === "Mother Vessel" && (
                      <div>
                        <div className="row mb-0">
                          <div className="col-lg-6 p-0">
                            <div className="row mb-2">
                              <label className="form_label mb-0">
                                Vessel Type
                              </label>
                              <TextField
                                name="vessel_Type"
                                placeholder="Mother Vessel Name"
                                margin="dense"
                                variant="outlined"
                                value={this.state.vessel_Type}
                                onChange={this.handleText}
                                fullWidth
                                select
                                InputProps={{
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                  },
                                }}
                              >
                                <MenuItem value="0" disabled>
                                  Please Select
                                </MenuItem>
                                <MenuItem value="Geared">Geared</MenuItem>
                                <MenuItem value="Gearless">Gearless</MenuItem>
                              </TextField>
                            </div>
                          </div>
                          <div className="col-lg-6 pr-0">
                            <div className="row mb-2">
                              <label className="form_label mb-0">
                                Floating Crane Name
                              </label>
                              <TextField
                                name="floatingCrane_name"
                                placeholder="Floating Crane Name"
                                margin="dense"
                                variant="outlined"
                                value={this.state.floatingCrane_name}
                                onChange={this.handleText}
                                inputProps={{
                                  style: { textTransform: "uppercase" },
                                }}
                                fullWidth
                                InputProps={{
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                  },
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        {this.state.shipmentType !== "FAS MV" && (
                          <span>
                            <div className="row mb-0">
                              <div className="col-lg-6 p-0">
                                <div className="row mb-2">
                                  <label className="form_label mb-0">
                                    Stevedore Vendor
                                  </label>
                                  <Autocomplete
                                    multiple
                                    options={this.state.vendorsAsOther}
                                    getOptionLabel={(option) => option.name}
                                    renderOption={(option, { selected }) => (
                                      <React.Fragment>
                                        <CheckBox
                                          style={{ marginRight: 8 }}
                                          checked={selected}
                                        />
                                        {option.name}
                                      </React.Fragment>
                                    )}
                                    onChange={(event, value) => {
                                      if (value) {
                                        var data = [];
                                        var values = [];
                                        for (var i = 0; i < value.length; i++) {
                                          data.push(value[i].value);
                                          values.push(value[i].name);
                                        }
                                        var stevedore_vendor = data.toString();
                                        this.setState({
                                          stevedore_vendor: stevedore_vendor,
                                          stevedore_vendor_value: value,
                                          stevedore_values: values,
                                        });
                                      } else {
                                        this.setState({
                                          stevedore_vendor: "",
                                          stevedore_vendor_value: "",
                                          stevedore_values: "",
                                        });
                                      }
                                    }}
                                    disableCloseOnSelect
                                    value={this.state.stevedore_vendor_value}
                                    name="stevedoreVendor"
                                    size="small"
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="Search Stevedore"
                                        variant="outlined"
                                        style={{ top: 8 }}
                                        fullWidth
                                      />
                                    )}
                                    style={{ display: "contents" }}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6 pr-0">
                                <div className="row mb-2">
                                  <label className="form_label mb-0">
                                    Floating Crane Vendor
                                  </label>
                                  <Autocomplete
                                    multiple
                                    options={this.state.vendorsAsOther}
                                    getOptionLabel={(option) => option.name}
                                    renderOption={(option, { selected }) => (
                                      <React.Fragment>
                                        <CheckBox
                                          style={{ marginRight: 8 }}
                                          checked={selected}
                                        />
                                        {option.name}
                                      </React.Fragment>
                                    )}
                                    onChange={(event, value) => {
                                      if (value) {
                                        var data = [];
                                        var values = [];
                                        for (var i = 0; i < value.length; i++) {
                                          data.push(value[i].value);
                                          values.push(value[i].name);
                                        }
                                        var floating_crane_vendor =
                                          data.toString();
                                        this.setState({
                                          floating_crane_vendor:
                                            floating_crane_vendor,
                                          floating_crane_vendor_value: value,
                                          floating_crane_vendor_values: values,
                                        });
                                      } else {
                                        this.setState({
                                          floating_crane_vendor: "",
                                          floating_crane_vendor_value: "",
                                          floating_crane_vendor_values: "",
                                        });
                                      }
                                    }}
                                    disableCloseOnSelect
                                    value={
                                      this.state.floating_crane_vendor_value
                                    }
                                    name="floatingCraneVendor"
                                    size="small"
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="Search Floating Crane Vendor"
                                        variant="outlined"
                                        style={{ top: 8 }}
                                        fullWidth
                                      />
                                    )}
                                    style={{ display: "contents" }}
                                  />
                                </div>
                              </div>
                            </div>
                          </span>
                        )}
                        <div className="row mb-0 mt-2">
                          <div className="col-lg-6 p-0">
                            <div className="row mb-2">
                              <label className="form_label mb-0">
                                Loading Anchorage
                              </label>
                              <TextField
                                name="loading_Anchorage"
                                placeholder="Loading Anchorage"
                                margin="dense"
                                variant="outlined"
                                value={this.state.loading_Anchorage}
                                onChange={this.handleText}
                                inputProps={{
                                  style: { textTransform: "uppercase" },
                                }}
                                fullWidth
                                InputProps={{
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                  },
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 pr-0">
                            <div className="row mb-2">
                              <label className="form_label mb-0">
                                Load Port Agent
                              </label>
                              <TextField
                                name="loadingPort_agent"
                                placeholder="Load Port Agent"
                                margin="dense"
                                variant="outlined"
                                value={this.state.loadingPort_agent}
                                onChange={this.handleText}
                                inputProps={{
                                  style: { textTransform: "uppercase" },
                                }}
                                fullWidth
                                InputProps={{
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                  },
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="row mb-0">
                      <div className="col-lg-6 p-0">
                        <div className="row mb-2">
                          <label className="form_label mb-0">
                            Load Rate per MT/Day
                          </label>
                          <TextField
                            name="loading_rate"
                            placeholder="Load Rate per MT/Day"
                            margin="dense"
                            variant="outlined"
                            value={this.state.loading_rate}
                            onChange={this.handleDecimalField}
                            fullWidth
                            InputProps={{
                              style: {
                                fontFamily: "Poppins",
                                fontSize: "14px",
                              },
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 pr-0">
                        <div className="row mb-2">
                          <label className="form_label mb-0">
                            Vessel Freight
                          </label>
                          <TextField
                            name="vessel_freight"
                            placeholder="Vessel Freight"
                            margin="dense"
                            variant="outlined"
                            value={this.state.vessel_freight}
                            onChange={this.handleDecimalField}
                            fullWidth
                            InputProps={{
                              style: {
                                fontFamily: "Poppins",
                                fontSize: "14px",
                              },
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    {(this.state.shipmentType === "CFR MV" ||
                      this.state.shipmentType === "CIF MV") && (
                      <div className="row mb-0">
                        <div className="col-lg-6 p-0">
                          <div className="mb-2">
                            <label className="form_label mb-2">
                              Vessel Owner
                            </label>
                            <Autocomplete
                              options={this.state.vesselOwner}
                              getOptionLabel={(option) => option.name}
                              onChange={(event, value) => {
                                value != null
                                  ? this.setState({
                                      vessel_owner: value.value,
                                      vessel_owner_value: value.name,
                                      vesselOwnerValue: value,
                                    })
                                  : this.setState({
                                      vessel_owner: "",
                                      vessel_owner_value: "",
                                      vesselOwnerValue: null,
                                    });
                              }}
                              name="VesselOwner"
                              size="small"
                              value={this.state.vesselOwnerValue}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Search Vessel Owner"
                                  // label="Search Vessel Owner"
                                  variant="outlined"
                                  fullWidth
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 pr-0">
                          <div className="row mb-2">
                            <label className="form_label mb-0">
                              Vessel Freight Price
                            </label>
                            <TextField
                              name="vessel_freight_price"
                              placeholder="Vessel Freight Price"
                              margin="dense"
                              variant="outlined"
                              value={this.state.vessel_freight_price}
                              onChange={(e) => {
                                const re = /^\d*(\.\d{0,4})?$/;
                                if (
                                  e.target.value === "" ||
                                  re.test(e.target.value)
                                ) {
                                  this.setState({
                                    vessel_freight_price: e.target.value,
                                  });
                                }
                              }}
                              fullWidth
                              InputProps={{
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="row mb-0">
                      <div className="col-lg-6 p-0">
                        <div className="row mb-2">
                          <label className="form_label mb-0">
                            Vessel Arrived
                          </label>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DateTimePicker
                              autoOk
                              hideTabs
                              inputVariant="outlined"
                              variant="inline"
                              margin="dense"
                              fullWidth
                              ampm={false}
                              // label="Vessel Arrived"
                              format="dd/MM/yyyy HH:mm"
                              value={this.state.vessel_arrived_on}
                              onChange={(date) => {
                                this.setState({
                                  vessel_arrived_on: dateFormateToDB(date),
                                });
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton>
                                      <EventIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                },
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                      </div>
                      <div className="col-lg-6 pr-0">
                        <div className="row mb-2">
                          <label className="form_label mb-0">ETA / NOR</label>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DateTimePicker
                              autoOk
                              hideTabs
                              inputVariant="outlined"
                              variant="inline"
                              margin="dense"
                              fullWidth
                              ampm={false}
                              // label="ETA / NOR"
                              format="dd/MM/yyyy HH:mm"
                              value={this.state.ETA}
                              onChange={(date) => this.ETADateHandler(date)}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton>
                                      <EventIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                },
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                      </div>
                    </div>

                    <div className="row mb-0">
                      <div className="col-lg-6 p-0">
                        <div className="row mb-2">
                          <label className="form_label mb-0">
                            NOR Acceptance
                          </label>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DateTimePicker
                              autoOk
                              hideTabs
                              inputVariant="outlined"
                              variant="inline"
                              margin="dense"
                              fullWidth
                              ampm={false}
                              // label="NOR Acceptance"
                              format="dd/MM/yyyy HH:mm"
                              value={this.state.nor_acceptance}
                              onChange={(date) => {
                                this.setState({
                                  nor_acceptance: dateFormateToDB(date),
                                });
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton>
                                      <EventIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                },
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                      </div>
                      <div className="col-lg-6 pr-0">
                        <div className="row mb-2">
                          <label className="form_label mb-0">
                            Documents on-board
                          </label>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DateTimePicker
                              autoOk
                              hideTabs
                              inputVariant="outlined"
                              variant="inline"
                              margin="dense"
                              fullWidth
                              ampm={false}
                              // label='Documents on-board'
                              format="dd/MM/yyyy HH:mm"
                              value={this.state.Documents_boardDate}
                              onChange={(date) =>
                                this.Documentson_boardDateHandler(date)
                              }
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton>
                                      <EventIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                },
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                      </div>
                    </div>

                    <div className="row mb-0">
                      <div className="col-lg-6 p-0">
                        <div className="row mb-2">
                          <label className="form_label mb-0">
                            Vessel Sailing
                          </label>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DateTimePicker
                              autoOk
                              hideTabs
                              inputVariant="outlined"
                              variant="inline"
                              margin="dense"
                              fullWidth
                              ampm={false}
                              // label='Vessel Sailing'
                              format="dd/MM/yyyy HH:mm"
                              value={this.state.vessel_sailingDate}
                              onChange={(date) =>
                                this.Vessel_SailingDateHandler(date)
                              }
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "14px",
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton>
                                      <EventIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                },
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                      </div>
                      <div className="col-lg-3 pr-0">
                        <div className="row mt-0">
                          <label className="form_label mb-0">
                            Demurrage Rate from Buyer
                          </label>
                          <TextField
                            name="demurrage_rate_from_buyer"
                            placeholder="Demurrage Rate from Buyer"
                            margin="dense"
                            variant="outlined"
                            value={this.state.demurrage_rate_from_buyer}
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                            onChange={(e) => {
                              this.setState({
                                demurrage_rate_from_buyer: e.target.value,
                              });
                            }}
                            fullWidth
                            InputProps={{
                              style: {
                                fontFamily: "Poppins",
                                fontSize: "14px",
                              },
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 pr-0 pl-1">
                        <div className="row mt-0">
                          <label className="form_label mb-0">
                            Demurrage Rate to Supplier
                          </label>
                          <TextField
                            name="demurrage_rate_to_supplier"
                            placeholder="Demurrage Rate to Supplier"
                            margin="dense"
                            variant="outlined"
                            value={this.state.demurrage_rate_to_supplier}
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                            onChange={(e) => {
                              this.setState({
                                demurrage_rate_to_supplier: e.target.value,
                              });
                            }}
                            fullWidth
                            InputProps={{
                              style: {
                                fontFamily: "Poppins",
                                fontSize: "14px",
                              },
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mb-0">
                      <div className="col-lg-6 p-0">
                        <div className="row mb-2">
                          <label className="form_label mb-0">
                            Stowage Plan in MT
                          </label>
                          <TextField
                            name="stowagePlan"
                            placeholder="Stowage Plan in MT"
                            margin="dense"
                            variant="outlined"
                            value={this.state.stowagePlan}
                            onChange={this.handleDecimalField}
                            fullWidth
                            InputProps={{
                              style: {
                                fontFamily: "Poppins",
                                fontSize: "14px",
                              },
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 pr-0">
                        <div className="row mb-2">
                          <label className="form_label mb-0">
                            Supply Tonnage in MT
                          </label>
                          <TextField
                            name="supplyTonnage"
                            placeholder="Supply Tonnage in MT"
                            margin="dense"
                            variant="outlined"
                            value={this.state.supplyTonnage}
                            onChange={this.handleDecimalField}
                            fullWidth
                            InputProps={{
                              style: {
                                fontFamily: "Poppins",
                                fontSize: "14px",
                              },
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mb-0">
                      <div className="col-lg-12 p-0 mb-2">
                        <div className="row">
                          <label className="form_label mb-0">Remarks</label>
                          <TextField
                            name="remarks"
                            placeholder="Remarks"
                            margin="dense"
                            multiline
                            rows={3}
                            variant="outlined"
                            value={this.state.remarks}
                            onChange={this.handleText}
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                            fullWidth
                            InputProps={{
                              style: {
                                fontFamily: "Poppins",
                                fontSize: "14px",
                              },
                            }}
                          />
                        </div>
                        <div>
                          <span
                            style={{
                              fontSize: 14,
                              color: "#93bce0",
                              float: "right",
                              border: "#93bce0 0.5px solid",
                              padding: "0 5px",
                            }}
                          >
                            {this.state.textAreaMaxCount -
                              this.state.remarks.length}
                          </span>
                          <span
                            style={{
                              fontSize: 14,
                              color: "#93bce0",
                              float: "right",
                              marginRight: 5,
                            }}
                          >
                            Remaining characters{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {this.state.activeStep === 3 && (
                  <div className="section_block">
                    <div className="shadow-sm mb-2 bg-white border">
                      <h5 className="mb-0 p-2 section_header">
                        Shipment Details
                      </h5>
                      <div className="row col-lg-12 p-2">
                        <div className="col-lg-4">
                          <span className="contract_display_header_label">
                            Business Type
                          </span>
                          <span className="contract_display_header_value">
                            {this.state.businessType}
                          </span>
                        </div>
                        <div className="col-lg-4">
                          <span className="contract_display_header_label">
                            Consignment Type
                          </span>
                          <span className="contract_display_header_value">
                            {this.state.consignmentType}
                          </span>
                        </div>
                        {this.state.consignmentType === "Barge" ? (
                          <div className="col-lg-4">
                            <span className="contract_display_header_label">
                              Barge Name
                            </span>
                            <span
                              className="contract_display_header_value"
                              style={{ textTransform: "uppercase" }}
                            >
                              {this.state.bargeName}
                            </span>
                          </div>
                        ) : (
                          <div className="col-lg-4">
                            <span className="contract_display_header_label">
                              Mother Vessel Name
                            </span>
                            <span
                              className="contract_display_header_value"
                              style={{ textTransform: "uppercase" }}
                            >
                              {this.state.motherVesselName}
                            </span>
                          </div>
                        )}

                        <div className="col-lg-4 mt-2">
                          <span className="contract_display_header_label">
                            Shipment Type
                          </span>
                          <span className="contract_display_header_value">
                            {this.state.shipmentType}
                          </span>
                        </div>
                        <div className="col-lg-4 mt-2">
                          <span className="contract_display_header_label">
                            Buy Back Involved
                          </span>
                          <span className="contract_display_header_value">
                            {this.state.buy_back_involved}
                          </span>
                        </div>
                        <div className="col-lg-4 mt-2">
                          <span className="contract_display_header_label">
                            Business Flow
                          </span>
                          <span className="contract_display_header_value">
                            {this.state.businessFlow}
                          </span>
                        </div>
                        <div className="col-lg-4 mt-2">
                          <span className="contract_display_header_label">
                            Supplier Reference No
                          </span>
                          <span className="contract_display_header_value">
                            {this.state.supplierReferenceNumber}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="shadow-sm mb-2 bg-white border">
                      <h5 className="mb-0 p-2 section_header">
                        Quantity Allocation
                      </h5>
                      <div>
                        <table className="table-responsive border-0 root p-2">
                          <thead className="thead-light">
                            <tr>
                              <th
                                className="table_header_label"
                                style={{ minWidth: 225 }}
                              >
                                Contract No
                              </th>
                              <th
                                className="table_header_label"
                                style={{ minWidth: 100 }}
                              >
                                Quality
                              </th>
                              <th
                                className="table_header_label"
                                style={{ minWidth: 100 }}
                              >
                                Tolerance
                              </th>
                              <th
                                className="table_header_label"
                                style={{ minWidth: 130 }}
                                nowrap="true"
                              >
                                Inco Terms
                              </th>
                              <th
                                className="table_header_label"
                                style={{ minWidth: 150 }}
                              >
                                Quality Standard
                              </th>
                              <th
                                className="table_header_label"
                                style={{ minWidth: 100 }}
                              >
                                Quantity
                              </th>
                              <th
                                className="table_header_label"
                                style={{ minWidth: 100 }}
                              >
                                Remaining
                              </th>
                            </tr>
                          </thead>
                          {this.state.quantityRows.map((e, index) => (
                            <tbody className="border" key={index}>
                              <tr>
                                <td className="table_value">
                                  <span
                                    className="quantity_allocation"
                                    style={{
                                      border: e.quantityAllocationError
                                        ? "1px solid red"
                                        : "none",
                                    }}
                                  >
                                    Quantity Allocation:{" "}
                                    {e.purchase_quantityAllocating}
                                  </span>
                                  <span className="purchase_contract">
                                    {e.purchase_contract}
                                  </span>
                                </td>
                                <td className="table_value">
                                  {e.purchase_quality[0].quality}
                                </td>
                                <td className="table_value">
                                  {e.purchase_quality[0].tolerance_adjusted +
                                    " %"}
                                </td>
                                <td className="table_value" nowrap="true">
                                  {e.purchase_types[0].purchase_type}
                                </td>
                                <td style={{ padding: "0px 10px" }}>
                                  {
                                    this.state.quantityRows[index]
                                      .purchase_procurement
                                  }
                                </td>
                                <td className="table_value">
                                  {this.toLocaleString(
                                    e.purchase_quality[0].quantity_adjusted
                                  )}
                                </td>
                                <td className="table_value">
                                  {this.toLocaleString(
                                    this._toBeDelivered(e.purchase_quality[0])
                                  )}
                                </td>
                              </tr>
                              <tr
                                style={{
                                  display: e.sales_contract ? "" : "none",
                                }}
                              >
                                <td className="table_value">
                                  {e.sales_contract}
                                </td>
                                <td className="table_value">
                                  {e.sales_quality[0].quality}
                                </td>
                                <td className="table_value">
                                  {e.sales_quality[0].tolerance_adjusted + " %"}
                                </td>
                                <td className="table_value" nowrap="true">
                                  {e.sales_types[0].sales_type}
                                </td>
                                <td style={{ padding: "0px 10px" }}>
                                  {
                                    this.state.quantityRows[index]
                                      .sales_sellingQuality
                                  }
                                </td>
                                <td className="table_value">
                                  {this.toLocaleString(
                                    e.sales_quality[0].quantity_adjusted
                                  )}
                                </td>
                                <td className="table_value">
                                  {this.toLocaleString(
                                    this._toBeDelivered(e.sales_quality[0])
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          ))}
                        </table>
                      </div>
                    </div>
                    <div className="shadow-sm mb-2 bg-white border">
                      <h5 className="mb-0 p-2 section_header">Load Details</h5>

                      {this.state.consignmentType === "Mother Vessel" && (
                        <div>
                          <div className="row mb-0 pl-2">
                            <div className="col-lg-4 p-0">
                              <span className="contract_display_header_label">
                                Vessel Type
                              </span>
                              <span className="contract_display_header_value">
                                {this.state.vessel_Type === "0"
                                  ? "-"
                                  : this.state.vessel_Type}
                              </span>
                            </div>
                            <div className="col-lg-4 pr-0">
                              <span className="contract_display_header_label">
                                Floating Crane Name
                              </span>
                              <span
                                className="contract_display_header_value"
                                style={{ textTransform: "uppercase" }}
                              >
                                {this.state.floatingCrane_name === ""
                                  ? "-"
                                  : this.state.floatingCrane_name}
                              </span>
                            </div>
                          </div>
                          {this.state.shipmentType !== "FAS MV" && (
                            <span>
                              <div className="row mb-0 mt-1 pl-2">
                                <div className="col-lg-4 p-0">
                                  <span className="contract_display_header_label">
                                    Stevedore Vendor
                                  </span>
                                  <span
                                    className="contract_display_header_value"
                                    style={{ textTransform: "uppercase" }}
                                  >
                                    {this.state.stevedore_values === ""
                                      ? "-"
                                      : this.state.stevedore_values.toString()}
                                  </span>
                                </div>
                                <div className="col-lg-4 pr-0">
                                  <span className="contract_display_header_label">
                                    Floating Crane Vendor
                                  </span>
                                  <span
                                    className="contract_display_header_value"
                                    style={{ textTransform: "uppercase" }}
                                  >
                                    {this.state.floating_crane_vendor_values ===
                                    ""
                                      ? "-"
                                      : this.state.floating_crane_vendor_values.toString()}
                                  </span>
                                </div>
                              </div>
                            </span>
                          )}
                          <div className="row mb-0 mt-1 pl-2">
                            <div className="col-lg-4 p-0">
                              <span className="contract_display_header_label">
                                Loading Anchorage
                              </span>
                              <span
                                className="contract_display_header_value"
                                style={{ textTransform: "uppercase" }}
                              >
                                {this.state.loading_Anchorage === ""
                                  ? "-"
                                  : this.state.loading_Anchorage}
                              </span>
                            </div>
                            <div className="col-lg-4 pr-0">
                              <span className="contract_display_header_label">
                                Load Port Agent
                              </span>
                              <span
                                className="contract_display_header_value"
                                style={{ textTransform: "uppercase" }}
                              >
                                {this.state.loadingPort_agent === ""
                                  ? "-"
                                  : this.state.loadingPort_agent}
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="row mb-0 mt-1 pl-2">
                        <div className="col-lg-4 p-0">
                          <span className="contract_display_header_label">
                            Load Rate per MT/Day
                          </span>
                          <span
                            className="contract_display_header_value"
                            style={{ textTransform: "uppercase" }}
                          >
                            {this.state.loading_rate === ""
                              ? "-"
                              : this.state.loading_rate}
                          </span>
                        </div>
                        <div className="col-lg-4 pr-0">
                          <span className="contract_display_header_label">
                            Vessel Freight
                          </span>
                          <span
                            className="contract_display_header_value"
                            style={{ textTransform: "uppercase" }}
                          >
                            {this.state.vessel_freight === ""
                              ? "-"
                              : this.state.vessel_freight}
                          </span>
                        </div>
                      </div>
                      {(this.state.shipmentType === "CFR MV" ||
                        this.state.shipmentType === "CIF MV") && (
                        <div className="row mb-0 mt-1 pl-2">
                          <div className="col-lg-4 p-0">
                            <span className="contract_display_header_label">
                              Vessel Owner
                            </span>
                            <span
                              className="contract_display_header_value"
                              style={{ textTransform: "uppercase" }}
                            >
                              {this.state.vessel_owner_value === ""
                                ? "-"
                                : this.state.vessel_owner_value}
                            </span>
                          </div>
                          <div className="col-lg-4 pr-0">
                            <span className="contract_display_header_label">
                              Vessel Freight Price
                            </span>
                            <span
                              className="contract_display_header_value"
                              style={{ textTransform: "uppercase" }}
                            >
                              {this.state.vessel_freight_price === ""
                                ? "-"
                                : this.state.vessel_freight_price}
                            </span>
                          </div>
                        </div>
                      )}

                      <div className="row mb-0 mt-1 pl-2">
                        <div className="col-lg-4 p-0">
                          <span className="contract_display_header_label">
                            Vessel Arrived
                          </span>
                          <span
                            className="contract_display_header_value"
                            style={{ textTransform: "uppercase" }}
                          >
                            {localDateFormate(this.state.vessel_arrived_on)}
                          </span>
                        </div>
                        <div className="col-lg-4 pr-0">
                          <span className="contract_display_header_label">
                            ETA / NOR
                          </span>
                          <span
                            className="contract_display_header_value"
                            style={{ textTransform: "uppercase" }}
                          >
                            {localDateFormate(this.state.ETA)}
                          </span>
                        </div>
                      </div>

                      <div className="row mb-0 mt-1 pl-2">
                        <div className="col-lg-4 p-0">
                          <span className="contract_display_header_label">
                            NOR Acceptance
                          </span>
                          <span
                            className="contract_display_header_value"
                            style={{ textTransform: "uppercase" }}
                          >
                            {localDateFormate(this.state.nor_acceptance)}
                          </span>
                        </div>
                        <div className="col-lg-4 pr-0">
                          <span className="contract_display_header_label">
                            Documents on-board
                          </span>
                          <span
                            className="contract_display_header_value"
                            style={{ textTransform: "uppercase" }}
                          >
                            {localDateFormate(this.state.Documents_boardDate)}
                          </span>
                        </div>
                      </div>

                      <div className="row mb-0 mt-1 pl-2">
                        <div className="col-lg-4 p-0">
                          <span className="contract_display_header_label">
                            Vessel Sailing
                          </span>
                          <span
                            className="contract_display_header_value"
                            style={{ textTransform: "uppercase" }}
                          >
                            {localDateFormate(this.state.vessel_sailingDate)}
                          </span>
                        </div>
                        <div className="col-lg-4 pr-0">
                          <span className="contract_display_header_label">
                            Demurrage Rate from Buyer
                          </span>
                          <span
                            className="contract_display_header_value"
                            style={{ textTransform: "uppercase" }}
                          >
                            {this.state.demurrage_rate_from_buyer === ""
                              ? "-"
                              : this.state.demurrage_rate_from_buyer}
                          </span>
                        </div>
                        <div className="col-lg-4 pr-0">
                          <span className="contract_display_header_label">
                            Demurrage Rate to Supplier
                          </span>
                          <span
                            className="contract_display_header_value"
                            style={{ textTransform: "uppercase" }}
                          >
                            {this.state.demurrage_rate_to_supplier === ""
                              ? "-"
                              : this.state.demurrage_rate_to_supplier}
                          </span>
                        </div>
                      </div>

                      <div className="row mb-0 mt-1 pl-2">
                        <div className="col-lg-4 p-0">
                          <span className="contract_display_header_label">
                            Stowage Plan in MT
                          </span>
                          <span
                            className="contract_display_header_value"
                            style={{ textTransform: "uppercase" }}
                          >
                            {this.state.stowagePlan === ""
                              ? "-"
                              : this.state.stowagePlan}
                          </span>
                        </div>
                        <div className="col-lg-4 pr-0">
                          <span className="contract_display_header_label">
                            Supply Tonnage in MT
                          </span>
                          <span
                            className="contract_display_header_value"
                            style={{ textTransform: "uppercase" }}
                          >
                            {this.state.supplyTonnage === ""
                              ? "-"
                              : this.state.supplyTonnage}
                          </span>
                        </div>
                      </div>

                      <div className="row mb-0 mt-1 pl-2">
                        <div className="col-lg-12 p-0">
                          <span className="contract_display_header_label">
                            Remarks
                          </span>
                          <span
                            className="contract_display_header_value"
                            style={{
                              textTransform: "uppercase",
                              whiteSpace: "pre-line",
                            }}
                          >
                            {this.state.remarks === ""
                              ? "-"
                              : this.state.remarks}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {this.state.activeStep === 0 && (
                <div className="row bottom_buttons_section">
                  <button
                    type="button"
                    className="btn previous_button"
                    name="submit"
                    disabled
                  >
                    PREVIOUS
                  </button>
                  <button
                    type="button"
                    className="btn next_button"
                    name="Back"
                    onClick={this.activeStepOne}
                  >
                    NEXT STEP
                  </button>
                </div>
              )}
              {this.state.activeStep === 1 && (
                <div className="row bottom_buttons_section">
                  <button
                    type="button"
                    className="btn previous_button"
                    name="submit"
                    onClick={() => this.setState({ activeStep: 0 })}
                  >
                    PREVIOUS
                  </button>
                  <button
                    type="button"
                    className="btn next_button"
                    name="Back"
                    onClick={this.onNextStep}
                  >
                    NEXT STEP
                  </button>
                </div>
              )}
              {this.state.activeStep === 2 && (
                <div className="row bottom_buttons_section">
                  <button
                    type="button"
                    className="btn previous_button"
                    name="submit"
                    onClick={() => this.setState({ activeStep: 1 })}
                  >
                    PREVIOUS
                  </button>
                  <button
                    type="button"
                    className="btn next_button"
                    name="Back"
                    onClick={() => this.setState({ activeStep: 3 })}
                  >
                    NEXT STEP
                  </button>
                </div>
              )}
              {this.state.activeStep === 3 && (
                <div className="row bottom_buttons_section">
                  <button
                    type="button"
                    className="btn previous_button"
                    name="submit"
                    onClick={() => this.setState({ activeStep: 2 })}
                  >
                    PREVIOUS
                  </button>
                  <button
                    type="button"
                    className="btn next_button"
                    name="Back"
                    onClick={this.submitHandler}
                  >
                    SUBMIT
                  </button>
                </div>
              )}
            </form>
          )}
        </div>
      </div>
    );
  }
}
