import React, { Component } from "react";
import SideBar from "../common/NewSidebar";
import Header from "../common/Header";
import CookieHandler from "../common/CookieHandler";
import "../../App.css";
import config from "../../config/config";
import { Alert } from "@material-ui/lab";
import api from "../../api/api";

import {
  TextField,
  Snackbar,
  Tabs,
  Tab,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import ChemicalSprayList from "../Barge-Purchase-Financials/Chemical-Spray/Chemical-Spray-List";
import ExportTaxList from "../Barge-Purchase-Financials/Export-Tax/Export-Tax-List";
import DocAdd from "../Barge-Purchase-Financials/Documentation-Charges/Documentation-Charges-List";
import SacList from "../Barge-Purchase-Financials/Sampling&Analysis-Charges/Sac-List";
import CoalProcurementChargesList from "../Barge-Purchase-Financials/Coal-Procurement-Charges/Coal-Procurement-Charges-List";
import CPSList from "../Barge-Purchase-Financials/Coal-Procurement-Services/CPS-List";
import PreShipmentInspectionList from "../Barge-Purchase-Financials/Pre-Shipmet-Inspection/Pre-Shipment-Inspection-List";
import BargeCostingList from "../Barge-Purchase-Financials/BargeCosting/BargeCostingList";

import CoalCostingPerformaList from "../Barge-Purchase-Financials/CoalCosting/CoalCostingPerformaList";
import CoalCostingCommercialList from "../Barge-Purchase-Financials/CoalCosting/CoalCostingCommercialList";
// Barge sales financials

import BargeCoalSalesChargesList from "../Barge-Sales-Financials/Coal-Sales-Charges/Barge_Coal_Sales_Charges_List";
// import Barge purchase financial demurrages

import BargeSupplierDemurrageList from "../Barge-Purchase-Financials-Demurrages/Supplier-Demurrages/Supplier-Demurrage-List";
import BargeVendorDemurrageList from "../Barge-Purchase-Financials-Demurrages/Vendor-Demurrages/Vendor-Demurrage-List";

import BargeSupplierToCompany from "../Barge-Purchase-Financials-Demurrages/Supplier-To-Company-Demurrages/Supplier-To-Company-Demurrage-List";

import BargeOtherExpenseList from "../Barge-Purchase-Financials/Other-Expenses/Other-Expense-List";

import InsuranceList from "../Barge-Purchase-Financials/Insurance/Insurance-List";

import ReturnCargoList from "../Barge-Purchase-Financials/Return-Cargo-Charges/ReturnCargoList";

import LaporanChargesList from "../Barge-Purchase-Financials/LaporanCharges/LaporanChargesList";
// import mother vessel stevedore costing
import MVStevedorCostingList from "../MvPurchaseFinancial/StevedoreCosting/StevedoreCostList";

// import mother vessel floatig crane
import MvFloatingCraneChargesList from "../MvPurchaseFinancial/FloatingCraneCharges/FloatingCraneChargesList";

// import mother vessel documentation charges
import MvDocList from "../MvPurchaseFinancial/Mv-Documentation-Charges/Mv-Doc-Charges-List";

//import mother vessel Laporan charges
import MVLaporanChargesList from "../MvPurchaseFinancial/MvLaporanCharges/LaporanChargesList";

// Import Mother vessel sampling & analaysis charges
import MvSacList from "../MvPurchaseFinancial/Mv-Pur-Fin-Sampling&Analysis_Charges/Mv-Sampling-Charges-List";

// import Mother vessel pre-shipment
import MvPreShipmentList from "../MvPurchaseFinancial/Mv-Pre-Shipment-Inspection/Mv-Pre-Shipment-Inspection-List";

// import freight costing
import FreightCostingView from "../MvPurchaseFinancial/Mv-Freight-Costing/Mv_Freight_Costing_View";

// import Mother vessel Export tax
import MvExportTaxList from "../MvPurchaseFinancial/Mv-Pur-Fin-Export-Tax/Export-Tax-List";

// import mother vessel coal procurement charges
import MVProcurementChargesList from "../MvPurchaseFinancial/Coal_Procurement_Charges/MVProcurementChargesList";

// import mother vessel coal sales charges
import MvCscList from "../MvPurchaseFinancial/CoalSalesCharges/MvCoalSalesChargesList";
//MV Demmurage Supplier
import MvSupplierDemurrageList from "../BusinessNoMVDemurrage/Mv_Demurrage_Supplier/Mv_supplier_DemurrageList";

//MV Demmurage Buyer
import MvBuyerDemurrageList from "../BusinessNoMVDemurrage/Mv_Demurrage_Buyer/Mv_Buyer_DemurrageList";
// import VesselInsuranceView from "../MvPurchaseFinancial/Vessel-Insurance/Vessel-Insurance-View";

//MV Other Income List
import OtherIncomeList from "../MvPurchaseFinancial/Other-Incomes/Other-Incomes-List";

//MV Other Expense List
import OtherExpenseList from "../MvPurchaseFinancial/Other-Expenses/Other-Expenses-List";

//MV Incoming Credit Note List
import IncomingCreditNoteList from "../MvPurchaseFinancial/Credit-Notes/Incoming-Credit-Note/Incoming-Credit-Note-List";

//MV Outgoing Credit Note List
import OutgoingCreditNoteList from "../MvPurchaseFinancial/Credit-Notes/Outgoing-Credit-Note/Outgoing-Credit-Note-List";

//MV Incoming Debit Note List
import IncomingDebitNoteList from "../MvPurchaseFinancial/Debit-Notes/Incoming-Debit-Notes/Incoming-Debit-Note-List";

//MV Outgoing Debit Note List
import OutgoingDebitNoteList from "../MvPurchaseFinancial/Debit-Notes/Outgoing-Debit-Notes/Outgoing-Debit-Note-List";
import Loader from "../common/Loader";

//MV Dead Freight List
import MvDeadFreightList from "../MvPurchaseFinancial/DeadFreight/MvDeadFreightList";

// MV Barge Sales PI
import SalesPIList from "../BusinessNoBargeSalesFinancials/Sales-Performa-Coal-Costing/PI-List";

// MV Barge Sales Commercisal PI
import BusinessNoSalesCoalCostingList from "../BusinessNoBargeSalesFinancials/Commercial-Sales-CoalCosting/BusinessNoSalesCoalCostingList";

export default class BusinessNumberFinancial extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();

    this.state = {
      isLoading: true,
      business_no_drop_value: [],
      financialActiveTabIndex: 1,
      fin_ActiveTabIndex: 0,
      activeTabIndex: 0,
      business_no_id: null,
      financialMVServicesActiveTabIndex: 1,
      financialOthersActiveTabIndex: 1,
      anchorEn: "",
      addOpen: false,
      openBargeMV: false,
      openDrawer: false,
      bargeMvCheck: false,
      open: false,
      costings: [
        "Coal Costing",
        "Barge Costing",
        "Export Tax",
        "Pre Shipment Inspection",
        "Sampling & Analysis Charges",
        "Documentation Charges",
        "Laparon Charges",
        "Chemical Spray",
        "Coal Procurement Charges",
        "Coal Procurement Service Fees",
        // "Demurrage",
        "Other Expenses",
        "Insurance",
        "Return Cargo Charges",

        "Incoming Debit Note",
        "Outgoing Credit Note",

        "Floating Crane Charges",
        "Freight Costing",
        "Stevedore Costing",
        "Dead Freight",

        "Barge Vendor Demurrage",
        "Barge Demurrage to Supplier",

        "MV Supplier Despatch",
        "MV Buyer Demurrage",

        "MV Coal Sales Charges",
      ],
      costingType: ["Performa Coal Costing", "Commercial Coal Costing"],
      costingFinancials: ["Barge", "Mother Vessel"],
      demurrageType: ["Barge Vendor", "Supplier"],
      costingName: "",
      selectedCosting: "",

      selectedCoalCosting: "",
      selectedCoalCostingType: "",

      selectedDemurrage: "",
      selectedDemurrageType: "",

      costingFinancialType: "",
      selectedCostingFinancialType: "",

      //Receivables

      receivableCosting: [
        "Outgoing Debit Note",
        "Incoming Credit Note",
        "Other Income",

        "Barge Sales Performa Coal Costing",
        "Barge Sales Commercial Coal Costing",
        "Barge Coal Sales Costing",

        "MV Supplier Demurrage",
        "MV Buyer Despatch",

        "Barge Demurrage from Supplier",
      ],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    var features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const get_business_no = await api.getBusinessNumbers(loginUserID, idToken);
    if (get_business_no.status >= 200 && get_business_no.status < 300) {
      get_business_no.json().then((res) => {
        if (res.code === "200") {
          if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
          if (res.business_numbers) {
            let business_no_drop_value = [];
            for (var i of res.business_numbers) {
              business_no_drop_value.push({
                name: i.business_no,
                value: i.id,
                mother_vessel_name: i.mother_vessel_name,
              });
            }
            this.setState({
              business_no_drop_value: business_no_drop_value,
              isLoading: false,
            });
          } else {
            alert("Unexpected error occured. Please contact administrator.");
          }
        } else if (res.code === "607") {
          window.location.href = "/logout";
        }
      });
    }
  }
  TabsHandler = (event, value) => {
    this.setState({
      activeTabIndex: value,
    });
  };

  Financial_TabsHandler = (event, value) => {
    this.setState({
      fin_ActiveTabIndex: value,
    });
  };

  coal_costing_tabs_handler = (event, value) => {
    this.setState({
      coal_costing_tabs_index: value,
    });
  };
  addButtonClose = () => {
    this.setState({
      addOpen: false,
      bargeMvCheck: false,
      openBargeMV: false,
    });
  };
  handleClose = () => {
    this.setState({
      open: false,
    });
  };
  addButton = (event) => {
    event.preventDefault();
    this.setState({
      addOpen: true,
      openBargeMV: false,
      anchorEn: event.currentTarget,
    });
  };
  handleSubmit = (event, value) => {
    if (this.state.selectedCosting === "") {
      alert("select Costing Type");
    } else if (
      this.state.selectedCosting === "Coal Costing" &&
      this.state.selectedCoalCosting === ""
    ) {
      alert("Select Coal Costing");
    } else if (
      this.state.selectedCosting === "Demurrage" &&
      this.state.selectedDemurrage === ""
    ) {
      alert("Select Demurrage Type");
    } else {
      this.setState({
        costingName: this.state.selectedCosting,
        selectedCoalCostingType: this.state.selectedCoalCosting,
        selectedDemurrageType: this.state.selectedDemurrage,
        selectedCostingFinancialType: this.state.costingFinancialType,
        open: false,
      });
    }
  };
  isShownDeadFreight = () => {
    const { stowage_plan_in_mt = 0, vessel_final_quantity_in_mt = 0 } =
      this.state;
    return (
      Number(stowage_plan_in_mt ? stowage_plan_in_mt : 0) -
        Number(vessel_final_quantity_in_mt ? vessel_final_quantity_in_mt : 0) >
      0
    );
  };

  MvDeadFreightValue = () => {
    const { stowage_plan_in_mt = 0, vessel_final_quantity_in_mt = 0 } =
      this.state;

    return (
      Number(stowage_plan_in_mt ? stowage_plan_in_mt : 0) -
      Number(vessel_final_quantity_in_mt ? vessel_final_quantity_in_mt : 0)
    );
  };
  // handleDrawerOpen = () => {
  //   this.setState({
  //     open: true
  //   })
  //   new CookieHandler().setCookie("drawerStatus", true);
  // };
  handleBarges = (event, value) => {
    this.setState({
      bargeMvCheck: false,
      addOpen: false,
      anchorEn: event.currentTarget,
      fin_ActiveTabIndex: event.target.value,
    });
  };

  render() {
    return (
      <div id="wraper">
        <SideBar />
        <div className="content">
          <Header />
          {/* <div className="content-header">
            <div className="col-sm text-left pl-0">
              <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                Business Number Financial
              </h4>
            </div>
          </div> */}
          <div className="row border-bottom">
            <Tabs
              value={this.state.activeTabIndex}
              onChange={this.TabsHandler}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
              style={{
                backgroundColor: "#ffffff",
                color: "#6c7293",
                padding: 0,
              }}
              TabIndicatorProps={{
                style: { background: config.themeColor },
              }}
            >
              <Tab
                label={
                  <h4
                    style={{
                      margin: "0px",
                      color:
                        this.state.activeTabIndex === 0
                          ? config.themeColor
                          : "#6c7293",
                      fontSize: "14px",
                      border: "0px",
                    }}
                  >
                    Account Payables
                  </h4>
                }
                value={0}
                style={{
                  fontFamily: "Poppins",
                  textTransform: "capitalize",
                  color: "#6c7293",
                }}
                onClick={() =>
                  this.setState({
                    open: true,
                    selectedCosting: "",
                    selectedCoalCosting: "",
                    selectedDemurrage: "",
                    costingFinancialType: "",
                  })
                }
              />
              <Tab
                label={
                  <h4
                    style={{
                      margin: "0px",
                      color:
                        this.state.activeTabIndex === 1
                          ? config.themeColor
                          : "#6c7293",
                      fontSize: "14px",
                      border: "0px",
                    }}
                  >
                    Account Receivables
                  </h4>
                }
                value={1}
                style={{
                  fontFamily: "Poppins",
                  textTransform: "capitalize",
                  color: "#6c7293",
                }}
                onClick={() =>
                  this.setState({
                    open: true,
                    selectedCosting: "",
                    selectedCoalCosting: "",
                    selectedDemurrage: "",
                    costingFinancialType: "",
                  })
                }
              />
            </Tabs>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <div className="container p-2">
            {this.state.isLoading && <Loader />}
            {!this.state.isLoading && (
              <div className="card p-0" style={{ overflowY: "scroll" }}>
                {/* <div className="card-body border"> */}
                {/* <div className="row mb-4"> */}
                {/* {this.state.activeTabIndex === 0
                      &&
                      <div className="col-lg-4">
                        <Autocomplete
                          options={this.state.business_no_drop_value}
                          getOptionLabel={(option) => option.name}
                          renderOption={(option) => (
                            <React.Fragment>
                              <div className="col-lg p-0 m-0">
                                <label className="form_label mb-0">
                                  {option.name}
                                </label>
                                <label
                                  className="contract_display_header_value m-0 pl-0"
                                  style={{ fontSize: 10, paddingLeft: 5 }}
                                >
                                  {"( " + option.mother_vesselName + " )"}
                                </label>
                              </div>
                            </React.Fragment>
                          )}
                          onChange={(event, value) => {
                            if (value) {
                              this.setState({
                                business_no_id: value.value,
                                selected_business_no: value,
                                financialMVServicesActiveTabIndex: null,
                                financialOthersActiveTabIndex: null,
                              });
                            } else {
                              this.setState({
                                business_no_id: null,
                                selected_business_no: { name: "", value: "" },
                              });
                            }
                          }}
                          // disableCloseOnSelect
                          value={this.state.selected_business_no}
                          name="Business_Number"
                          size="small"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Business No"
                              label="Business No"
                              variant="outlined"
                              style={{ top: 8 }}
                              fullWidth
                            />
                          )}
                          style={{ display: "contents" }}
                        />
                      </div>} */}
                {/* </div> */}
                {/* {this.state.activeTabIndex === 0
                    &&
                    (
                      <div className="row border-bottom pb-2 mb-4">
                        <ListItem
                          className="ml-3 specs_button"
                          onClick={() => {
                            this.setState({
                              financialActiveTabIndex: 1,
                            });
                          }}
                          style={{
                            textTransform: "capitalize",
                            borderRadius: 35,
                            height: 30,
                            color:
                              this.state.financialActiveTabIndex === 1
                                ? config.themeColor
                                : "#483b34b3",
                            backgroundColor:
                              this.state.financialActiveTabIndex === 1
                                ? "#fff7f2"
                                : "#fff",
                            fontSize: "14px",
                            border: "0px",
                          }}
                        >
                          Services
                        </ListItem>
                        <ListItem
                          className="ml-3 specs_button"
                          onClick={() => {
                            this.setState({
                              financialActiveTabIndex: 2,
                            });
                          }}
                          style={{
                            textTransform: "capitalize",
                            borderRadius: 35,
                            height: 30,
                            color:
                              this.state.financialActiveTabIndex === 2
                                ? config.themeColor
                                : "#483b34b3",
                            backgroundColor:
                              this.state.financialActiveTabIndex === 2
                                ? "#fff7f2"
                                : "#fff",
                            fontSize: "14px",
                            border: "0px",
                          }}
                        >
                          Others
                        </ListItem>
                      </div>
                    )} */}
                {/* {this.state.business_no_id && */}
                {/* <div className="header_add">
                   <button
                      onClick={this.addButton}
                      className="header_button header_button_text"
                      name="submit"
                    >
                      New
                    </button>
                  </div> */}
                {/* {this.state.activeTabIndex === 0 &&
                    <Popover
                      open={this.state.addOpen}
                      anchorEl={this.state.anchorEn}
                      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                      targetOrigin={{ horizontal: "left", vertical: "top" }}
                      onClose={this.addButtonClose}
                     >
                      <div className="card border p-0">
                        <MenuItem
                          className="drawer_links"
                          style={{
                            fontSize: "17px",
                            color:
                              Config.company === "SRPL" ||
                                Config.company === "PRPL" ||
                                Config.company === "AVS"
                                ? "#918176"
                                : "rgb(169, 126, 103)",
                          }}
                          onClick={(e, value) => {
                            this.setState({
                              fin_ActiveTabIndex: 0,
                            });
                          }}
                        >
                          {(this.state.features.includes("126") ||
                            this.state.features.includes("127") ||
                            this.state.features.includes("97")) && (
                              <>
                                Coal Costing
                              </>
                            )}
                        </MenuItem>
                        <MenuItem
                          className="drawer_links"
                          style={{
                            fontSize: "17px",
                            color:
                              Config.company === "SRPL" ||
                                Config.company === "PRPL" ||
                                Config.company === "AVS"
                                ? "#918176"
                                : "rgb(169, 126, 103)",
                          }}
                          onClick={(e, value) => {
                            this.setState({
                              fin_ActiveTabIndex: 1,
                            });
                          }}
                        >
                          {(this.state.features.includes("134") ||
                            this.state.features.includes("135") ||
                            this.state.features.includes("97")) && (
                              <>
                                Barge Costing
                              </>
                            )}
                        </MenuItem>
                        <MenuItem
                          className="drawer_links"
                          style={{
                            fontSize: "17px",
                            color:
                              Config.company === "SRPL" ||
                                Config.company === "PRPL" ||
                                Config.company === "AVS"
                                ? "#918176"
                                : "rgb(169, 126, 103)",
                          }}
                          onClick={(e, value) => {
                            this.setState({
                              fin_ActiveTabIndex: 7,
                            });
                          }}
                        >
                          {(this.state.features.includes("174") ||
                            this.state.features.includes("175") ||
                            this.state.features.includes("97")) && (
                              <>
                                Chemical Spray
                              </>
                            )}
                        </MenuItem>
                        <MenuItem
                          className="drawer_links"
                          style={{
                            fontSize: "17px",
                            color:
                              Config.company === "SRPL" ||
                                Config.company === "PRPL" ||
                                Config.company === "AVS"
                                ? "#918176"
                                : "rgb(169, 126, 103)",
                          }}
                          onClick={
                            (e, value) => (
                              e.target.value = 6,
                              this.setState({
                                // fin_ActiveTabIndex: 6,
                                openBargeMV: true,
                                bargeMvCheck: true,
                              })
                            )
                          }
                        >
                          {(this.state.features.includes("174") ||
                            this.state.features.includes("175") ||
                            this.state.features.includes("97")) &&
                            (
                              <>
                                Export Tax
                              </>
                            )}
                        </MenuItem>
                        <MenuItem
                          className="drawer_links"
                          style={{
                            fontSize: "17px",
                            color:
                              Config.company === "SRPL" ||
                                Config.company === "PRPL" ||
                                Config.company === "AVS"
                                ? "#918176"
                                : "rgb(169, 126, 103)",
                          }}
                          onClick={(e, value) => {
                            this.setState({
                              fin_ActiveTabIndex: 5,
                            });
                          }}
                        >
                          {(this.state.features.includes("274") ||
                            this.state.features.includes("275") ||
                            this.state.features.includes("97")) && (
                              (
                                <>
                                  Documentation Charges
                                </>
                              )
                            )}
                        </MenuItem>
                        <MenuItem
                          className="drawer_links"
                          style={{
                            fontSize: "17px",
                            color:
                              Config.company === "SRPL" ||
                                Config.company === "PRPL" ||
                                Config.company === "AVS"
                                ? "#918176"
                                : "rgb(169, 126, 103)",
                          }}
                          onClick={(e, value) => {
                            this.setState({
                              fin_ActiveTabIndex: 13,
                            });
                          }}
                        >
                          {(this.state.features.includes("274") ||
                            this.state.features.includes("275") ||
                            this.state.features.includes("97")) && (
                              <>
                                Laporan Charges
                              </>
                            )}
                        </MenuItem>
                        <MenuItem
                          className="drawer_links"
                          style={{
                            fontSize: "17px",
                            color:
                              Config.company === "SRPL" ||
                                Config.company === "PRPL" ||
                                Config.company === "AVS"
                                ? "#918176"
                                : "rgb(169, 126, 103)",
                          }}
                          onClick={(e, value) => {
                            this.setState({
                              fin_ActiveTabIndex: 3,
                            });
                          }}
                        >
                          {(this.state.features.includes("158") ||
                            this.state.features.includes("159") ||
                            this.state.features.includes("97")) &&
                            (
                              <>
                                Sampling & Analysis Charges
                              </>
                            )}
                        </MenuItem>
                        <MenuItem
                          className="drawer_links"
                          style={{
                            fontSize: "17px",
                            color:
                              Config.company === "SRPL" ||
                                Config.company === "PRPL" ||
                                Config.company === "AVS"
                                ? "#918176"
                                : "rgb(169, 126, 103)",
                          }}
                          onClick={(e, value) => {
                            this.setState({
                              fin_ActiveTabIndex: 4,
                            });
                          }}
                        >
                          {(this.state.features.includes("166") ||
                            this.state.features.includes("167") ||
                            this.state.features.includes("97")) && (
                              <>
                                Pre Shipment Inspection
                              </>
                            )}
                        </MenuItem>
                        <MenuItem
                          className="drawer_links"
                          style={{
                            fontSize: "17px",
                            color:
                              Config.company === "SRPL" ||
                                Config.company === "PRPL" ||
                                Config.company === "AVS"
                                ? "#918176"
                                : "rgb(169, 126, 103)",
                          }}
                          onClick={(e, value) => {
                            this.setState({
                              fin_ActiveTabIndex: 2,
                            });
                          }}
                        >
                          {(this.state.features.includes("142") ||
                            this.state.features.includes("143") ||
                            this.state.features.includes("97")) && (
                              <>
                                Coal Procurement Charges
                              </>
                            )}
                        </MenuItem>
                        <MenuItem
                          className="drawer_links"
                          style={{
                            fontSize: "17px",
                            color:
                              Config.company === "SRPL" ||
                                Config.company === "PRPL" ||
                                Config.company === "AVS"
                                ? "#918176"
                                : "rgb(169, 126, 103)",
                          }}
                          onClick={(e, value) => {
                            this.setState({
                              fin_ActiveTabIndex: 11,
                            });
                          }}
                        >
                          {(this.state.features.includes("142") ||
                            this.state.features.includes("143") ||
                            this.state.features.includes("97")) &&
                            (
                              <>
                                Coal Procurement Service Fees
                              </>
                            )}
                        </MenuItem>
                        <MenuItem
                          className="drawer_links"
                          style={{
                            fontSize: "17px",
                            color:
                              Config.company === "SRPL" ||
                                Config.company === "PRPL" ||
                                Config.company === "AVS"
                                ? "#918176"
                                : "rgb(169, 126, 103)",
                          }}
                          onClick={(e, value) => {
                            this.setState({
                              fin_ActiveTabIndex: 8,
                            });
                          }}
                        >
                          {(this.state.features.includes("174") ||
                            this.state.features.includes("175") ||
                            this.state.features.includes("97")) &&
                            (
                              <>
                                Demurrage
                              </>
                            )}
                        </MenuItem>
                        <MenuItem
                          className="drawer_links"
                          style={{
                            fontSize: "17px",
                            color:
                              Config.company === "SRPL" ||
                                Config.company === "PRPL" ||
                                Config.company === "AVS"
                                ? "#918176"
                                : "rgb(169, 126, 103)",
                          }}
                          onClick={(e, value) => {
                            this.setState({
                              fin_ActiveTabIndex: 9,
                            });
                          }}
                        >
                          {(this.state.features.includes("338") ||
                            this.state.features.includes("339") ||
                            this.state.features.includes("97")) &&
                            (
                              <>
                                Other Expenses
                              </>
                            )}
                        </MenuItem>
                        <MenuItem
                          className="drawer_links"
                          style={{
                            fontSize: "17px",
                            color:
                              Config.company === "SRPL" ||
                                Config.company === "PRPL" ||
                                Config.company === "AVS"
                                ? "#918176"
                                : "rgb(169, 126, 103)",
                          }}
                          onClick={(e, value) => {
                            this.setState({
                              fin_ActiveTabIndex: 10,
                            });
                          }}
                        >
                          {(this.state.features.includes("346") ||
                            this.state.features.includes("347") ||
                            this.state.features.includes("97")) &&
                            (
                              <>
                                Insurance
                              </>
                            )}
                        </MenuItem>
                        <MenuItem
                          className="drawer_links"
                          style={{
                            fontSize: "17px",
                            color:
                              Config.company === "SRPL" ||
                                Config.company === "PRPL" ||
                                Config.company === "AVS"
                                ? "#918176"
                                : "rgb(169, 126, 103)",
                          }}
                          onClick={(e, value) => {
                            this.setState({
                              fin_ActiveTabIndex: 12,
                            });
                          }}
                        >
                          {(this.state.features.includes("537") ||
                            this.state.features.includes("538") ||
                            this.state.features.includes("97")) &&
                            (
                              <>
                                Return Cargo Charges
                              </>
                            )}
                        </MenuItem>
                        <MenuItem
                          className="drawer_links"
                          style={{
                            fontSize: "17px",
                            color:
                              Config.company === "SRPL" ||
                                Config.company === "PRPL" ||
                                Config.company === "AVS"
                                ? "#918176"
                                : "rgb(169, 126, 103)",
                          }}
                          onClick={(e, value) => {
                            this.setState({
                              financialMVServicesActiveTabIndex: 2,
                            });
                          }}
                        >
                          {(this.state.features.includes("174") ||
                            this.state.features.includes("175") ||
                            this.state.features.includes("97")) &&
                            (
                              <>
                                Stevedore Costing
                              </>
                            )}
                        </MenuItem>
                        <MenuItem
                          className="drawer_links"
                          style={{
                            fontSize: "17px",
                            color:
                              Config.company === "SRPL" ||
                                Config.company === "PRPL" ||
                                Config.company === "AVS"
                                ? "#918176"
                                : "rgb(169, 126, 103)",
                          }}
                          onClick={(e, value) => {
                            this.setState({
                              financialMVServicesActiveTabIndex: 1,
                            });
                          }}
                        >
                          {(this.state.features.includes("174") ||
                            this.state.features.includes("175") ||
                            this.state.features.includes("97")) &&
                            (
                              <>
                                Floating Crane charges
                              </>
                            )}
                        </MenuItem>
                        <MenuItem
                          className="drawer_links"
                          style={{
                            fontSize: "17px",
                            color:
                              Config.company === "SRPL" ||
                                Config.company === "PRPL" ||
                                Config.company === "AVS"
                                ? "#918176"
                                : "rgb(169, 126, 103)",
                          }}
                          onClick={(e, value) => {
                            this.setState({
                              financialMVServicesActiveTabIndex: 3,
                            });
                          }}
                        >
                          {(this.state.features.includes("174") ||
                            this.state.features.includes("175") ||
                            this.state.features.includes("97")) &&
                            (
                              <>
                                Freight Costing
                              </>
                            )}
                        </MenuItem>
                        <MenuItem
                          className="drawer_links"
                          style={{
                            fontSize: "17px",
                            color:
                              Config.company === "SRPL" ||
                                Config.company === "PRPL" ||
                                Config.company === "AVS"
                                ? "#918176"
                                : "rgb(169, 126, 103)",
                          }}
                          onClick={(e, value) => {
                            this.setState({
                              financialMVServicesActiveTabIndex: 11,
                            });
                          }}
                        >
                          {(this.state.features.includes("174") ||
                            this.state.features.includes("175") ||
                            this.state.features.includes("97")) &&
                            (
                              <>
                                Coal Sales Charges
                              </>
                            )}
                        </MenuItem>
                        <MenuItem
                          className="drawer_links"
                          style={{
                            fontSize: "17px",
                            color:
                              Config.company === "SRPL" ||
                                Config.company === "PRPL" ||
                                Config.company === "AVS"
                                ? "#918176"
                                : "rgb(169, 126, 103)",
                          }}
                          onClick={(e, value) => {
                            this.setState({
                              financialMVServicesActiveTabIndex: 6,
                            });
                          }}
                        >
                          {(this.state.features.includes("174") ||
                            this.state.features.includes("175") ||
                            this.state.features.includes("97")) &&
                            (
                              <>
                                Dead Freight
                              </>
                            )}
                        </MenuItem>
                        <MenuItem
                          className="drawer_links"
                          style={{
                            fontSize: "17px",
                            color:
                              Config.company === "SRPL" ||
                                Config.company === "PRPL" ||
                                Config.company === "AVS"
                                ? "#918176"
                                : "rgb(169, 126, 103)",
                          }}
                          onClick={(e, value) => {
                            this.setState({
                              financialOthersActiveTabIndex: 1,
                            });
                          }}
                        >
                          {(this.state.features.includes("174") ||
                            this.state.features.includes("175") ||
                            this.state.features.includes("97")) &&
                            (
                              <>
                                Debit Note
                              </>
                            )}
                        </MenuItem>
                        <MenuItem
                          className="drawer_links"
                          style={{
                            fontSize: "17px",
                            color:
                              Config.company === "SRPL" ||
                                Config.company === "PRPL" ||
                                Config.company === "AVS"
                                ? "#918176"
                                : "rgb(169, 126, 103)",
                          }}
                          onClick={(e, value) => {
                            this.setState({
                              financialOthersActiveTabIndex: 2,
                            });
                          }}
                        >
                          {(this.state.features.includes("174") ||
                            this.state.features.includes("175") ||
                            this.state.features.includes("97")) &&
                            (
                              <>
                                Credit Note
                              </>
                            )}
                        </MenuItem>
                        <MenuItem
                          className="drawer_links"
                          style={{
                            fontSize: "17px",
                            color:
                              Config.company === "SRPL" ||
                                Config.company === "PRPL" ||
                                Config.company === "AVS"
                                ? "#918176"
                                : "rgb(169, 126, 103)",
                          }}
                          onClick={(e, value) => {
                            this.setState({
                              financialOthersActiveTabIndex: 3,
                            });
                          }}
                        >
                          {(this.state.features.includes("174") ||
                            this.state.features.includes("175") ||
                            this.state.features.includes("97")) &&
                            (
                              <>
                                Other Income
                              </>
                            )}
                        </MenuItem>
                      </div>
                    </Popover>} */}
                {this.state.activeTabIndex === 0 && (
                  <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    maxWidth="sm"
                    fullWidth={true}
                  >
                    <DialogContent>
                      <div>
                        <div className="col">
                          <div className="col-lg-12 p-1">
                            <div className="row mb-2">
                              <label className="form_label mb-0">
                                Costing Type{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <Autocomplete
                                options={this.state.costings}
                                getOptionLabel={(option) => option}
                                onChange={(event, value) => {
                                  this.setState({
                                    selectedCosting: value,
                                    // filteredVendor: value,
                                    // vendorTypeError: false,
                                  });
                                }}
                                name="vendorName"
                                value={this.state.filteredVendor}
                                size="small"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="Select Costing Type"
                                    variant="outlined"
                                    error={this.state.vendorTypeError}
                                    style={{ top: 8 }}
                                    fullWidth
                                  />
                                )}
                                style={{ display: "contents" }}
                              />
                            </div>
                          </div>
                          {this.state.selectedCosting === "Coal Costing" && (
                            <div className="col-lg-12 p-1">
                              <div className="row mb-2">
                                <label className="form_label mb-0">
                                  Coal Costing Type{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <Autocomplete
                                  options={this.state.costingType}
                                  getOptionLabel={(option) => option}
                                  onChange={(event, value) => {
                                    this.setState({
                                      selectedCoalCosting: value,
                                      // filteredVendor: value,
                                      // vendorTypeError: false,
                                    });
                                  }}
                                  name="vendorName"
                                  value={this.state.filteredVendor}
                                  size="small"
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder="Select Coal Costing Type"
                                      variant="outlined"
                                      error={this.state.vendorTypeError}
                                      style={{ top: 8 }}
                                      fullWidth
                                    />
                                  )}
                                  style={{ display: "contents" }}
                                />
                              </div>
                            </div>
                          )}
                          {/* {this.state.selectedCosting === "Demurrage" &&
                            <div className="col-lg-12 p-1">
                              <div className="row mb-2">
                                <label className="form_label mb-0">
                                  Demurrage <span style={{ color: "red" }}>*</span>
                                </label>
                                <Autocomplete
                                  options={this.state.demurrageType}
                                  getOptionLabel={(option) => option}
                                  onChange={(event, value) => {
                                    this.setState({
                                      selectedDemurrage: value,
                                      // filteredVendor: value,
                                      // vendorTypeError: false,
                                    })
                                  }}
                                  name="vendorName"
                                  value={this.state.filteredVendor}
                                  size="small"
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder="Select Demurrage Type"
                                      variant="outlined"
                                      error={this.state.vendorTypeError}
                                      style={{ top: 8 }}
                                      fullWidth
                                    />
                                  )}
                                  style={{ display: "contents" }}
                                />
                              </div>
                            </div>
                          } */}
                          {(this.state.selectedCosting ===
                            "Coal Procurement Charges" ||
                            this.state.selectedCosting ===
                              "Sampling & Analysis Charges" ||
                            this.state.selectedCosting ===
                              "Documentation Charges" ||
                            this.state.selectedCosting === "Laparon Charges" ||
                            // this.state.selectedCosting === "Demurrage" ||
                            this.state.selectedCosting === "Other Expenses" ||
                            this.state.selectedCosting === "Export Tax" ||
                            this.state.selectedCosting ===
                              "Pre Shipment Inspection") && (
                            <div className="col-lg-12 p-1">
                              <div className="row mb-2">
                                <label className="form_label mb-0">
                                  Consignment Type{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <Autocomplete
                                  options={this.state.costingFinancials}
                                  getOptionLabel={(option) => option}
                                  onChange={(event, value) => {
                                    this.setState({
                                      costingFinancialType: value,
                                      // filteredVendor: value,
                                      // vendorTypeError: false,
                                    });
                                  }}
                                  name="vendorName"
                                  value={this.state.filteredVendor}
                                  size="small"
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder="Select Consignment Type"
                                      variant="outlined"
                                      error={this.state.vendorTypeError}
                                      style={{ top: 8 }}
                                      fullWidth
                                    />
                                  )}
                                  style={{ display: "contents" }}
                                />
                              </div>
                            </div>
                          )}
                          {(this.state.costingFinancialType ===
                            "Mother Vessel" ||
                            this.state.selectedCosting ===
                              "Stevedore Costing" ||
                            this.state.selectedCosting ===
                              "Floating Crane Charges" ||
                            this.state.selectedCosting === "Freight Costing" ||
                            this.state.selectedCosting ===
                              "Incoming Debit Note" ||
                            this.state.selectedCosting ===
                              "Outgoing Credit Note" ||
                            this.state.selectedCosting === "Dead Freight" ||
                            this.state.selectedCosting ===
                              "MV Supplier Despatch" ||
                            this.state.selectedCosting ===
                              "MV Buyer Demurrage" ||
                            this.state.selectedCosting ===
                              "MV Coal Sales Charges") && (
                            <div className="p-1">
                              <Autocomplete
                                options={this.state.business_no_drop_value}
                                getOptionLabel={(option) =>
                                  option.mother_vessel_name
                                }
                                renderOption={(option) => (
                                  <React.Fragment>
                                    <div className="row p-1 m-0">
                                      <label className="form_label mb-0">
                                        {option.mother_vessel_name}
                                      </label>
                                      <label
                                        className="contract_display_header_value ml-1 pl-1"
                                        // style={{ fontSize: 10, paddingLeft: 5 }}
                                        style={{ fontSize: 13 }}
                                      >
                                        {"(" + option.name + ")"}
                                      </label>
                                    </div>
                                  </React.Fragment>
                                )}
                                onChange={(event, value) => {
                                  if (value) {
                                    this.setState({
                                      business_no_id: value.value,
                                      selected_business_no: value,
                                      financialMVServicesActiveTabIndex: null,
                                      financialOthersActiveTabIndex: null,
                                    });
                                  } else {
                                    this.setState({
                                      business_no_id: null,
                                      selected_business_no: {
                                        name: "",
                                        value: "",
                                      },
                                    });
                                  }
                                }}
                                // disableCloseOnSelect
                                value={this.state.selected_business_no}
                                name="Business_Number"
                                size="small"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="Business No"
                                    label="Business No"
                                    variant="outlined"
                                    style={{ top: 8 }}
                                    fullWidth
                                  />
                                )}
                                style={{ display: "contents" }}
                              />
                            </div>
                          )}
                        </div>
                        {/* <DialogActions> */}
                        <div className="row p-2">
                          <button
                            type="button"
                            className="btn previous_button"
                            name="submit"
                            data-dismiss="modal"
                            onClick={this.handleClose}
                          >
                            Close
                          </button>
                          <button
                            type="button"
                            className="btn  next_button"
                            onClick={this.handleSubmit}
                          >
                            Submit
                          </button>
                          &emsp;
                        </div>
                        {/* </DialogActions> */}
                      </div>
                    </DialogContent>
                  </Dialog>
                )}
                {/* RECEIVABLES */}
                {this.state.activeTabIndex === 1 && (
                  <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    maxWidth="sm"
                    fullWidth={true}
                  >
                    <DialogContent>
                      <div>
                        <div className="col">
                          <div className="col-lg-12 p-1">
                            <div className="row mb-2">
                              <label className="form_label mb-0">
                                Costing Type{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <Autocomplete
                                options={this.state.receivableCosting}
                                getOptionLabel={(option) => option}
                                onChange={(event, value) => {
                                  this.setState({
                                    selectedCosting: value,
                                    // filteredVendor: value,
                                    // vendorTypeError: false,
                                  });
                                }}
                                name="vendorName"
                                value={this.state.filteredVendor}
                                size="small"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="Select Costing Type"
                                    variant="outlined"
                                    error={this.state.vendorTypeError}
                                    style={{ top: 8 }}
                                    fullWidth
                                  />
                                )}
                                style={{ display: "contents" }}
                              />
                            </div>
                          </div>
                          {this.state.selectedCosting === "Demurrage" && (
                            <div className="col-lg-12 p-1">
                              <div className="row mb-2">
                                <label className="form_label mb-0">
                                  Demurrage{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <Autocomplete
                                  options={this.state.demurrageType}
                                  getOptionLabel={(option) => option}
                                  onChange={(event, value) => {
                                    this.setState({
                                      selectedDemurrage: value,
                                      // filteredVendor: value,
                                      // vendorTypeError: false,
                                    });
                                  }}
                                  name="vendorName"
                                  value={this.state.filteredVendor}
                                  size="small"
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder="Select Demurrage Type"
                                      variant="outlined"
                                      error={this.state.vendorTypeError}
                                      style={{ top: 8 }}
                                      fullWidth
                                    />
                                  )}
                                  style={{ display: "contents" }}
                                />
                              </div>
                            </div>
                          )}
                          {(this.state.selectedCosting ===
                            "Coal Procurement Charges" ||
                            this.state.selectedCosting ===
                              "Sampling & Analysis Charges" ||
                            this.state.selectedCosting ===
                              "Documentation Charges" ||
                            this.state.selectedCosting === "Laparon Charges" ||
                            this.state.selectedCosting === "Demurrage" ||
                            this.state.selectedCosting === "Other Expenses" ||
                            this.state.selectedCosting === "Export Tax" ||
                            this.state.selectedCosting ===
                              "Pre Shipment Inspection") && (
                            <div className="col-lg-12 p-1">
                              <div className="row mb-2">
                                <label className="form_label mb-0">
                                  Consignment Type{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <Autocomplete
                                  options={this.state.costingFinancials}
                                  getOptionLabel={(option) => option}
                                  onChange={(event, value) => {
                                    this.setState({
                                      costingFinancialType: value,
                                      // filteredVendor: value,
                                      // vendorTypeError: false,
                                    });
                                  }}
                                  name="vendorName"
                                  value={this.state.filteredVendor}
                                  size="small"
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder="Select Consignment Type"
                                      variant="outlined"
                                      error={this.state.vendorTypeError}
                                      style={{ top: 8 }}
                                      fullWidth
                                    />
                                  )}
                                  style={{ display: "contents" }}
                                />
                              </div>
                            </div>
                          )}
                          {(this.state.costingFinancialType ===
                            "Mother Vessel" ||
                            this.state.selectedCosting === "Other Income" ||
                            this.state.selectedCosting ===
                              "Outgoing Debit Note" ||
                            this.state.selectedCosting ===
                              "Incoming Credit Note" ||
                            this.state.selectedCosting ===
                              "Barge Sales Commercial Coal Costing" ||
                            this.state.selectedCosting ===
                              "Barge Sales Performa Coal Costing" ||
                            this.state.selectedCosting ===
                              "Barge Sales Performa Coal Costing" ||
                            this.state.selectedCosting ===
                              "MV Buyer Despatch" ||
                            this.state.selectedCosting ===
                              "MV Supplier Demurrage") && (
                            <div className="p-1">
                              <Autocomplete
                                options={this.state.business_no_drop_value}
                                getOptionLabel={(option) =>
                                  option.mother_vessel_name
                                }
                                renderOption={(option) => (
                                  <React.Fragment>
                                    <div className="row p-1 m-0">
                                      <label className="form_label mb-0">
                                        {option.mother_vessel_name}
                                      </label>
                                      <label
                                        className="contract_display_header_value ml-1 pl-1"
                                        // style={{ fontSize: 10, paddingLeft: 5 }}
                                        style={{ fontSize: 13 }}
                                      >
                                        {"(" + option.name + ")"}
                                      </label>
                                    </div>
                                  </React.Fragment>
                                )}
                                onChange={(event, value) => {
                                  if (value) {
                                    this.setState({
                                      business_no_id: value.value,
                                      selected_business_no: value,
                                      financialMVServicesActiveTabIndex: null,
                                      financialOthersActiveTabIndex: null,
                                    });
                                  } else {
                                    this.setState({
                                      business_no_id: null,
                                      selected_business_no: {
                                        name: "",
                                        value: "",
                                      },
                                    });
                                  }
                                }}
                                // disableCloseOnSelect
                                value={this.state.selected_business_no}
                                name="Business_Number"
                                size="small"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="Business No"
                                    label="Business No"
                                    variant="outlined"
                                    style={{ top: 8 }}
                                    fullWidth
                                  />
                                )}
                                style={{ display: "contents" }}
                              />
                            </div>
                          )}
                        </div>
                        {/* <DialogActions> */}
                        <div className="row p-2">
                          <button
                            type="button"
                            className="btn previous_button"
                            name="submit"
                            data-dismiss="modal"
                            onClick={this.handleClose}
                          >
                            Close
                          </button>
                          <button
                            type="button"
                            className="btn  next_button"
                            onClick={this.handleSubmit}
                          >
                            Submit
                          </button>
                          &emsp;
                        </div>
                        {/* </DialogActions> */}
                      </div>
                    </DialogContent>
                  </Dialog>
                )}

                {/* {this.state.fin_ActiveTabIndex === 0 && (
                    <div> */}
                {/* <div className="pl-3 pr-3"> */}
                {/* {(this.state.features.includes("126") ||
                          this.state.features.includes("127") ||
                          this.state.features.includes("97")) && (
                            <ListItem
                              className="tab_button"
                              onClick={(e, value) => {
                                this.setState({
                                  coal_costing_tabs_index: 0,
                                });
                              }}
                              style={{
                                textTransform: "capitalize",
                                margin: "5px 0px",
                                padding: "5px 20px",
                                // borderRadius: 35,
                                height: 30,
                                color:
                                  this.state.coal_costing_tabs_index === 0
                                    ? config.themeColor
                                    : "#483b34b3",
                                backgroundColor:
                                  this.state.coal_costing_tabs_index === 0
                                    ? "#fff7f2"
                                    : "#fff",
                                fontSize: "14px",
                                border: "0px",
                              }}
                            >
                              Performa
                            </ListItem>
                          )}
                        {(this.state.features.includes("126") ||
                          this.state.features.includes("127") ||
                          this.state.features.includes("97")) && (
                            <ListItem
                              className="tab_button"
                              onClick={(e, value) => {
                                this.setState({
                                  coal_costing_tabs_index: 1,
                                });
                              }}
                              style={{
                                textTransform: "capitalize",
                                margin: "5px 0px",
                                padding: "5px 20px",
                                // borderRadius: 35,
                                height: 30,
                                color:
                                  this.state.coal_costing_tabs_index === 1
                                    ? config.themeColor
                                    : "#483b34b3",
                                backgroundColor:
                                  this.state.coal_costing_tabs_index === 1
                                    ? "#fff7f2"
                                    : "#fff",
                                fontSize: "14px",
                                border: "0px",
                              }}
                            >
                              Commercial
                            </ListItem>
                          )} */}
                {/* </div> */}
                {/* </div>
                  )} */}
                {(this.state.features.includes("134") ||
                  this.state.features.includes("135") ||
                  this.state.features.includes("97")) &&
                  this.state.costingName === "Barge Costing" && (
                    <>
                      <div className="col-lg-12">
                        <div className="section_header p-1 ml-4 bg-white">
                          {this.state.costingName}
                        </div>
                        <BargeCostingList />
                      </div>
                    </>
                  )}
                {/* Coal Costing List Views */}
                {(this.state.features.includes("126") ||
                  this.state.features.includes("127") ||
                  this.state.features.includes("97")) &&
                  // this.state.coal_costing_tabs_index === 0
                  this.state.costingName === "Coal Costing" &&
                  this.state.selectedCoalCostingType ===
                    "Performa Coal Costing" && (
                    <>
                      <div className="section_header p-2 bg-white">
                        {this.state.selectedCoalCostingType}
                      </div>
                      <CoalCostingPerformaList />
                    </>
                  )}
                {(this.state.features.includes("126") ||
                  this.state.features.includes("127") ||
                  this.state.features.includes("97")) &&
                  // this.state.coal_costing_tabs_index === 1
                  this.state.costingName === "Coal Costing" &&
                  this.state.selectedCoalCostingType ===
                    "Commercial Coal Costing" && (
                    <>
                      <div className="section_header p-2 bg-white">
                        {this.state.selectedCoalCostingType}
                      </div>
                      <CoalCostingCommercialList />
                    </>
                  )}

                {(this.state.features.includes("142") ||
                  this.state.features.includes("143") ||
                  this.state.features.includes("97")) &&
                  // this.state.fin_ActiveTabIndex === 2 &&
                  this.state.costingName === "Coal Procurement Charges" &&
                  this.state.selectedCostingFinancialType === "Barge" && (
                    <div className="col-lg-12">
                      <div className="section_header p-1 ml-4 bg-white">
                        {this.state.costingName}
                      </div>
                      <CoalProcurementChargesList />
                    </div>
                  )}
                {(this.state.features.includes("142") ||
                  this.state.features.includes("143") ||
                  this.state.features.includes("97")) &&
                  // this.state.fin_ActiveTabIndex === 11
                  this.state.costingName ===
                    "Coal Procurement Service Fees" && (
                    <div className="col-lg-12 p-0">
                      <div className="section_header p-1 ml-4 bg-white">
                        {this.state.costingName}
                      </div>
                      <CPSList />
                    </div>
                  )}

                {(this.state.features.includes("158") ||
                  this.state.features.includes("159") ||
                  this.state.features.includes("97")) &&
                  // this.state.fin_ActiveTabIndex === 3
                  this.state.costingName === "Sampling & Analysis Charges" &&
                  this.state.selectedCostingFinancialType === "Barge" && (
                    <>
                      <div className="section_header p-1 ml-4 bg-white">
                        {this.state.costingName}
                      </div>
                      <SacList />
                    </>
                  )}
                {(this.state.features.includes("166") ||
                  this.state.features.includes("167") ||
                  this.state.features.includes("97")) &&
                  // this.state.fin_ActiveTabIndex === 4
                  this.state.costingName === "Pre Shipment Inspection" &&
                  this.state.selectedCostingFinancialType === "Barge" && (
                    <>
                      <div className="section_header p-1 ml-4 bg-white">
                        {this.state.costingName}
                      </div>
                      <PreShipmentInspectionList />
                    </>
                  )}
                {(this.state.features.includes("274") ||
                  this.state.features.includes("275") ||
                  this.state.features.includes("97")) &&
                  // this.state.fin_ActiveTabIndex === 5
                  this.state.costingName === "Documentation Charges" &&
                  this.state.selectedCostingFinancialType === "Barge" && (
                    <>
                      <div className="section_header p-1 ml-4 bg-white">
                        {this.state.costingName}
                      </div>
                      <DocAdd />
                    </>
                  )}

                {(this.state.features.includes("174") ||
                  this.state.features.includes("175") ||
                  this.state.features.includes("97")) &&
                  // this.state.fin_ActiveTabIndex === 6
                  this.state.costingName === "Export Tax" &&
                  this.state.selectedCostingFinancialType === "Barge" && (
                    <>
                      <div className="section_header p-1 ml-4 bg-white">
                        {this.state.costingName}
                      </div>
                      <ExportTaxList />
                    </>
                  )}

                {this.state.costingName === "Chemical Spray" && (
                  <>
                    <div className="section_header p-1 ml-4 bg-white">
                      {this.state.costingName}
                    </div>
                    <ChemicalSprayList />
                  </>
                )}
                {(this.state.features.includes("338") ||
                  this.state.features.includes("339") ||
                  this.state.features.includes("97")) &&
                  // this.state.fin_ActiveTabIndex === 9
                  this.state.costingName === "Other Expenses" &&
                  this.state.selectedCostingFinancialType === "Barge" && (
                    <>
                      <div className="section_header p-1 ml-4 bg-white">
                        {this.state.costingName}
                      </div>
                      <BargeOtherExpenseList />
                    </>
                  )}
                {(this.state.features.includes("346") ||
                  this.state.features.includes("347") ||
                  this.state.features.includes("97")) &&
                  // this.state.fin_ActiveTabIndex === 10
                  this.state.costingName === "Insurance" && (
                    <>
                      <div className="section_header p-1 ml-4 bg-white">
                        {this.state.costingName}
                      </div>
                      <InsuranceList />
                    </>
                  )}
                {this.state.costingName === "Return Cargo Charges" && (
                  <>
                    <div className="section_header p-1 ml-4 bg-white">
                      {this.state.costingName}
                    </div>
                    <ReturnCargoList />
                  </>
                )}

                {this.state.costingName === "Laparon Charges" &&
                  this.state.selectedCostingFinancialType === "Barge" && (
                    <>
                      <div className="section_header p-1 ml-4 bg-white">
                        {this.state.costingName}
                      </div>
                      <LaporanChargesList />
                    </>
                  )}
                {(this.state.features.includes("52") ||
                  this.state.features.includes("150") ||
                  this.state.features.includes("97")) &&
                  // this.state.barge_demurrage_tabs_index === 0
                  this.state.costingName === "Barge Vendor Demurrage" && (
                    // this.state.selectedDemurrageType === "Barge Vendor" &&
                    // this.state.selectedCostingFinancialType === "Barge" &&
                    <>
                      <div className="section_header p-1 ml-4 bg-white">
                        {this.state.costingName}
                      </div>
                      <BargeVendorDemurrageList />
                    </>
                  )}
                {(this.state.features.includes("52") ||
                  this.state.features.includes("150") ||
                  this.state.features.includes("97")) &&
                  // this.state.barge_demurrage_tabs_index === 1
                  this.state.costingName === "Barge Demurrage to Supplier" && (
                    // this.state.selectedDemurrageType === "Supplier" &&
                    // this.state.selectedCostingFinancialType === "Barge" &&
                    <>
                      <div className="section_header p-1 ml-4 bg-white">
                        {this.state.costingName}
                      </div>
                      <BargeSupplierDemurrageList />
                    </>
                  )}

                {/* MOTHER VESSEL FINANCIALS */}

                {this.state.costingName === "Export Tax" &&
                  this.state.selectedCostingFinancialType === "Mother Vessel" &&
                  (this.state.features.includes("266") ||
                    this.state.features.includes("267") ||
                    this.state.features.includes("97")) && (
                    <div className="col-lg-12">
                      <MvExportTaxList
                        businessNoID={this.state.business_no_id}
                      />
                    </div>
                  )}
                {this.state.costingName === "Stevedore Costing" &&
                  (this.state.features.includes("226") ||
                    this.state.features.includes("227") ||
                    this.state.features.includes("97")) && (
                    // <div className="col-lg-12">
                    <MVStevedorCostingList
                      businessNoID={this.state.business_no_id}
                      mother_vessel_name={
                        this.state.selected_business_no.mother_vessel_name
                      }
                      name={this.state.selected_business_no.name}
                    />
                    // </div>
                  )}
                {this.state.costingName === "Floating Crane Charges" &&
                  (this.state.features.includes("258") ||
                    this.state.features.includes("259") ||
                    this.state.features.includes("97")) && (
                    <div className="col-lg-12">
                      <MvFloatingCraneChargesList
                        businessNoID={this.state.business_no_id}
                        mother_vessel_name={
                          this.state.selected_business_no.mother_vessel_name
                        }
                        name={this.state.selected_business_no.name}
                      />
                    </div>
                  )}
                {this.state.costingName === "Documentation Charges" &&
                  this.state.selectedCostingFinancialType === "Mother Vessel" &&
                  (this.state.features.includes("274") ||
                    this.state.features.includes("275") ||
                    this.state.features.includes("97")) && (
                    <div className="col-lg-12">
                      <MvDocList businessNoID={this.state.business_no_id} />
                    </div>
                  )}
                {this.state.costingName === "Laparon Charges" &&
                  this.state.selectedCostingFinancialType === "Mother Vessel" &&
                  (this.state.features.includes("274") ||
                    this.state.features.includes("275") ||
                    this.state.features.includes("97")) && (
                    <div className="col-lg-12">
                      <MVLaporanChargesList
                        businessNoID={this.state.business_no_id}
                      />
                    </div>
                  )}
                {this.state.costingName === "Sampling & Analysis Charges" &&
                  this.state.selectedCostingFinancialType === "Mother Vessel" &&
                  (this.state.features.includes("242") ||
                    this.state.features.includes("243") ||
                    this.state.features.includes("97")) && (
                    <div className="col-lg-12">
                      <MvSacList businessNoID={this.state.business_no_id} />
                    </div>
                  )}
                {this.state.costingName === "Pre Shipment Inspection" &&
                  this.state.selectedCostingFinancialType === "Mother Vessel" &&
                  (this.state.features.includes("250") ||
                    this.state.features.includes("251") ||
                    this.state.features.includes("97")) && (
                    <div className="col-lg-12">
                      <MvPreShipmentList
                        businessNoID={this.state.business_no_id}
                      />
                    </div>
                  )}
                {this.state.costingName === "Freight Costing" &&
                  (this.state.features.includes("379") ||
                    this.state.features.includes("380") ||
                    this.state.features.includes("97")) && (
                    <div className="col-lg-12">
                      <FreightCostingView
                        businessNoID={this.state.business_no_id}
                      />
                    </div>
                  )}
                {this.state.costingName === "Coal Procurement Charges" &&
                  this.state.selectedCostingFinancialType === "Mother Vessel" &&
                  (this.state.features.includes("234") ||
                    this.state.features.includes("235") ||
                    this.state.features.includes("97")) && (
                    <div className="col-lg-12">
                      <MVProcurementChargesList
                        businessNoID={this.state.business_no_id}
                      />
                    </div>
                  )}

                {this.state.costingName === "MV Supplier Despatch" &&
                  // this.state.selectedDemurrageType === "Supplier" &&
                  // this.state.selectedCostingFinancialType === "Mother Vessel" &&
                  (this.state.features.includes("49") ||
                    this.state.features.includes("50") ||
                    this.state.features.includes("97") >= 0) && (
                    <div className="col-lg-12">
                      <MvSupplierDemurrageList
                        businessNoID={this.state.business_no_id}
                        mother_vessel_name={
                          this.state.selected_business_no.mother_vessel_name
                        }
                        name={this.state.selected_business_no.name}
                        demurrageType="Despatch"
                      />
                    </div>
                  )}
                {this.state.costingName === "MV Buyer Demurrage" &&
                  // this.state.selectedDemurrageType === "Barge Vendor" &&
                  // this.state.selectedCostingFinancialType === "Mother Vessel" &&
                  (this.state.features.includes("49") ||
                    this.state.features.includes("50") ||
                    this.state.features.includes("97") >= 0) && (
                    <div className="col-lg-12">
                      <MvBuyerDemurrageList
                        businessNoID={this.state.business_no_id}
                        mother_vessel_name={
                          this.state.selected_business_no.mother_vessel_name
                        }
                        name={this.state.selected_business_no.name}
                        demurrageType="Demurrage"
                      />
                    </div>
                  )}
                {this.state.costingName === "Incoming Debit Note" &&
                  (this.state.features.includes("82") ||
                    this.state.features.includes("83") ||
                    this.state.features.includes("97")) && (
                    <div className="col-lg-12 p-0">
                      <IncomingDebitNoteList
                        businessNoID={this.state.business_no_id}
                        mother_vessel_name={
                          this.state.selected_business_no.mother_vessel_name
                        }
                        name={this.state.selected_business_no.name}
                      />
                    </div>
                  )}
                {this.state.costingName === "Outgoing Credit Note" &&
                  (this.state.features.includes("82") ||
                    this.state.features.includes("83") ||
                    this.state.features.includes("97")) && (
                    <div className="col-lg-12 p-0">
                      <OutgoingCreditNoteList
                        businessNoID={this.state.business_no_id}
                      />
                    </div>
                  )}
                {this.state.costingName === "Other Expenses" &&
                  this.state.selectedCostingFinancialType === "Mother Vessel" &&
                  (this.state.features.includes("294") ||
                    this.state.features.includes("295") ||
                    this.state.features.includes("97")) && (
                    <div className="col-lg-12 p-0">
                      <OtherExpenseList
                        businessNoID={this.state.business_no_id}
                      />
                    </div>
                  )}

                {this.state.costingName === "Dead Freight" && (
                  <div className="col-lg-12">
                    <MvDeadFreightList
                      businessNoID={this.state.business_no_id}
                      deadFreightValue={this.MvDeadFreightValue()}
                      mother_vessel_name={
                        this.state.selected_business_no.mother_vessel_name
                      }
                      name={this.state.selected_business_no.name}
                    />
                  </div>
                )}

                {/* ACCOUNT RECEIVABLES */}
                {(this.state.features.includes("52") ||
                  this.state.features.includes("150") ||
                  this.state.features.includes("97")) &&
                  // this.state.barge_demurrage_tabs_index === 1
                  this.state.costingName ===
                    "Barge Demurrage from Supplier" && (
                    <>
                      <div className="section_header p-1 ml-4 bg-white">
                        {this.state.costingName}
                      </div>
                      <BargeSupplierToCompany />
                    </>
                  )}
                {this.state.costingName === "MV Supplier Demurrage" &&
                  // this.state.selectedDemurrageType === "Supplier" &&
                  // this.state.selectedCostingFinancialType === "Mother Vessel" &&
                  (this.state.features.includes("49") ||
                    this.state.features.includes("50") ||
                    this.state.features.includes("97") >= 0) && (
                    <div className="col-lg-12">
                      <MvSupplierDemurrageList
                        businessNoID={this.state.business_no_id}
                        mother_vessel_name={
                          this.state.selected_business_no.mother_vessel_name
                        }
                        name={this.state.selected_business_no.name}
                        demurrageType="Demurrage"
                      />
                    </div>
                  )}
                {this.state.costingName === "MV Buyer Despatch" &&
                  // this.state.selectedDemurrageType === "Barge Vendor" &&
                  // this.state.selectedCostingFinancialType === "Mother Vessel" &&
                  (this.state.features.includes("49") ||
                    this.state.features.includes("50") ||
                    this.state.features.includes("97") >= 0) && (
                    <div className="col-lg-12">
                      <MvBuyerDemurrageList
                        businessNoID={this.state.business_no_id}
                        mother_vessel_name={
                          this.state.selected_business_no.mother_vessel_name
                        }
                        name={this.state.selected_business_no.name}
                        demurrageType="Despatch"
                      />
                    </div>
                  )}
                {this.state.costingName === "MV Coal Sales Charges" &&
                  (this.state.features.includes("282") ||
                    this.state.features.includes("283") ||
                    this.state.features.includes("97")) && (
                    <div className="col-lg-12">
                      <MvCscList
                        businessNoID={this.state.business_no_id}
                        mother_vessel_name={
                          this.state.selected_business_no.mother_vessel_name
                        }
                        name={this.state.selected_business_no.name}
                      />
                    </div>
                  )}

                {this.state.costingName === "Other Income" &&
                  (this.state.features.includes("302") ||
                    this.state.features.includes("303") ||
                    this.state.features.includes("97")) && (
                    <div className="col-lg-12 p-0">
                      <OtherIncomeList
                        businessNoID={this.state.business_no_id}
                        mother_vessel_name={
                          this.state.selected_business_no.mother_vessel_name
                        }
                        name={this.state.selected_business_no.name}
                      />
                    </div>
                  )}

                {this.state.costingName === "Outgoing Debit Note" &&
                  (this.state.features.includes("82") ||
                    this.state.features.includes("83") ||
                    this.state.features.includes("97")) && (
                    <div className="col-lg-12 p-0">
                      <OutgoingDebitNoteList
                        businessNoID={this.state.business_no_id}
                        mother_vessel_name={
                          this.state.selected_business_no.mother_vessel_name
                        }
                        name={this.state.selected_business_no.name}
                      />
                    </div>
                  )}
                {this.state.costingName === "Incoming Credit Note" &&
                  (this.state.features.includes("82") ||
                    this.state.features.includes("83") ||
                    this.state.features.includes("97")) && (
                    <div className="col-lg-12 p-0">
                      <IncomingCreditNoteList
                        businessNoID={this.state.business_no_id}
                        mother_vessel_name={
                          this.state.selected_business_no.mother_vessel_name
                        }
                        name={this.state.selected_business_no.name}
                      />
                    </div>
                  )}
                {this.state.costingName ===
                  "Barge Sales Performa Coal Costing" && (
                  <div className="row col-lg">
                    <div className="row mt-2 mb-2 col-lg p-0">
                      <SalesPIList
                        businessNoID={this.state.business_no_id}
                        mother_vessel_name={
                          this.state.selected_business_no.mother_vessel_name
                        }
                        name={this.state.selected_business_no.name}
                      />
                    </div>
                  </div>
                )}

                {this.state.costingName ===
                  "Barge Sales Commercial Coal Costing" && (
                  <div className="row col-lg">
                    <div className="row mt-2 mb-2 col-lg p-0">
                      <BusinessNoSalesCoalCostingList
                        businessNoID={this.state.business_no_id}
                        mother_vessel_name={
                          this.state.selected_business_no.mother_vessel_name
                        }
                        name={this.state.selected_business_no.name}
                        costing_type="Commercial"
                      />
                    </div>
                  </div>
                )}

                {this.state.costingName === "Barge Coal Sales Costing" && (
                  <>
                    <div className="section_header p-1 bg-white">
                      {this.state.costingName}
                    </div>
                    <BargeCoalSalesChargesList />
                  </>
                )}
                {/* {this.state.business_no_id && */}
                {/* // this.state.financialActiveTabIndex === 2 && ( */}
                {/* // <div className="col mt-3"> */}
                {/* <div className="row mt-2 mb-2">
                        <ListItem
                          className="ml-3 specs_button"
                          onClick={() => {
                            this.setState({
                              financialOthersActiveTabIndex: 1,
                            });
                          }}
                          style={{
                            textTransform: "capitalize",
                            borderRadius: 35,
                            height: 30,
                            color:
                              this.state.financialOthersActiveTabIndex === 1
                                ? config.themeColor
                                : "#483b34b3",
                            backgroundColor:
                              this.state.financialOthersActiveTabIndex === 1
                                ? "#fff7f2"
                                : "#fff",
                            fontSize: "14px",
                            border: "0px",
                            cursor: 'pointer'
                          }}
                        >
                          Debit Note
                        </ListItem>
                        <ListItem
                          className="ml-3 specs_button"
                          onClick={() => {
                            this.setState({
                              financialOthersActiveTabIndex: 2,
                            });
                          }}
                          style={{
                            textTransform: "capitalize",
                            borderRadius: 35,
                            height: 30,
                            color:
                              this.state.financialOthersActiveTabIndex === 2
                                ? config.themeColor
                                : "#483b34b3",
                            backgroundColor:
                              this.state.financialOthersActiveTabIndex === 2
                                ? "#fff7f2"
                                : "#fff",
                            fontSize: "14px",
                            border: "0px",
                            cursor: 'pointer'
                          }}
                        >
                          Credit Note
                        </ListItem>
                        <ListItem
                          className="ml-3 specs_button"
                          onClick={() => {
                            this.setState({
                              financialOthersActiveTabIndex: 3,
                            });
                          }}
                          style={{
                            textTransform: "capitalize",
                            borderRadius: 35,
                            height: 30,
                            color:
                              this.state.financialOthersActiveTabIndex === 3
                                ? config.themeColor
                                : "#483b34b3",
                            backgroundColor:
                              this.state.financialOthersActiveTabIndex === 3
                                ? "#fff7f2"
                                : "#fff",
                            fontSize: "14px",
                            border: "0px",
                            cursor: 'pointer'
                          }}
                        >
                          Other Expenses
                        </ListItem>
                        <ListItem
                          className="ml-3 specs_button"
                          onClick={() => {
                            this.setState({
                              financialOthersActiveTabIndex: 4,
                            });
                          }}
                          style={{
                            textTransform: "capitalize",
                            borderRadius: 35,
                            height: 30,
                            color:
                              this.state.financialOthersActiveTabIndex === 4
                                ? config.themeColor
                                : "#483b34b3",
                            backgroundColor:
                              this.state.financialOthersActiveTabIndex === 4
                                ? "#fff7f2"
                                : "#fff",
                            fontSize: "14px",
                            border: "0px",
                            cursor: 'pointer'
                          }}
                        >
                          Other Income
                        </ListItem>
                      </div> */}

                {/* {this.state.financialOthersActiveTabIndex === 1 && ( */}
                {/* <div className="col-lg-12"> */}
                {/* <hr className="mb-1" /> */}
                {/* {(this.state.features.includes("82") ||
                            this.state.features.includes("83") ||
                            this.state.features.includes("97")) && (
                              <ListItem
                                className="tab_button"
                                onClick={() => {
                                  this.setState({
                                    financialMVDebitActiveTabIndex: 1,
                                  });
                                }}
                                style={{
                                  textTransform: "capitalize",
                                  height: 30,
                                  color:
                                    this.state
                                      .financialMVDebitActiveTabIndex === 1
                                      ? config.themeColor
                                      : "#483b34b3",
                                  backgroundColor:
                                    this.state
                                      .financialMVDebitActiveTabIndex === 1
                                      ? "#fff7f2"
                                      : "#fff",
                                  fontSize: "14px",
                                  border: "0px",
                                  cursor: 'pointer'
                                }}
                              >
                                Incoming
                              </ListItem>
                            )} */}
                {/* {(this.state.features.includes("82") ||
                            this.state.features.includes("83") ||
                            this.state.features.includes("97")) && (
                              <ListItem
                                className="tab_button"
                                onClick={() => {
                                  this.setState({
                                    financialMVDebitActiveTabIndex: 2,
                                  });
                                }}
                                style={{
                                  textTransform: "capitalize",
                                  height: 30,
                                  color:
                                    this.state
                                      .financialMVDebitActiveTabIndex === 2
                                      ? config.themeColor
                                      : "#483b34b3",
                                  backgroundColor:
                                    this.state
                                      .financialMVDebitActiveTabIndex === 2
                                      ? "#fff7f2"
                                      : "#fff",
                                  fontSize: "14px",
                                  border: "0px",
                                  cursor: 'pointer'
                                }}
                              >
                                Outgoing
                              </ListItem>
                            )} */}
                {/* </div> */}
                {/* )} */}
                {/* {this.state.financialOthersActiveTabIndex === 2 && ( */}
                {/* <div className="col-lg-12"> */}
                {/* <hr className="mb-1" /> */}
                {/* {(this.state.features.includes("82") ||
                            this.state.features.includes("83") ||
                            this.state.features.includes("97")) && (
                              <ListItem
                                className="tab_button"
                                onClick={() => {
                                  this.setState({
                                    financialMVCreditActiveTabIndex: 1,
                                  });
                                }}
                                style={{
                                  textTransform: "capitalize",
                                  height: 30,
                                  color:
                                    this.state
                                      .financialMVCreditActiveTabIndex === 1
                                      ? config.themeColor
                                      : "#483b34b3",
                                  backgroundColor:
                                    this.state
                                      .financialMVCreditActiveTabIndex === 1
                                      ? "#fff7f2"
                                      : "#fff",
                                  fontSize: "14px",
                                  border: "0px",
                                  cursor: 'pointer'
                                }}
                              >
                                Incoming
                              </ListItem>
                            )} */}
                {/* {(this.state.features.includes("82") ||
                            this.state.features.includes("83") ||
                            this.state.features.includes("97")) && (
                              <ListItem
                                className="tab_button"
                                onClick={() => {
                                  this.setState({
                                    financialMVCreditActiveTabIndex: 2,
                                  });
                                }}
                                style={{
                                  textTransform: "capitalize",
                                  height: 30,
                                  color:
                                    this.state
                                      .financialMVCreditActiveTabIndex === 2
                                      ? config.themeColor
                                      : "#483b34b3",
                                  backgroundColor:
                                    this.state
                                      .financialMVCreditActiveTabIndex === 2
                                      ? "#fff7f2"
                                      : "#fff",
                                  fontSize: "14px",
                                  border: "0px",
                                  cursor: 'pointer'
                                }}
                              >
                                Outgoing
                              </ListItem>
                            )} */}
                {/* </div> */}
                {/* )} */}
                {/* </div> */}
                {/* )} */}
              </div>
              // </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
