import React, { Component } from "react";
import CookieHandler from "../common/CookieHandler";
import config from "../../config/config";
import api from "../../api/api";

import { Alert } from "@material-ui/lab";
import {
  MenuItem,
  TextField,
  Tabs,
  Tab,
  InputAdornment,
  Radio,
  RadioGroup,
  FormControlLabel,
  Snackbar,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { withStyles } from "@material-ui/core/styles";

import AddCircle from "@material-ui/icons/AddCircle";
import { pad } from "../common/common";
import Loader from "../common/Loader";
const NewRadio = withStyles({
  root: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export default class UpdateService extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      service_type: "0",
      invoice_date: null,
      invoice_no: "",
      ppn: "",
      mother_vessel_name: "",
      barge_name: "",
      vendor_id: "",
      payable_type: "",
      payable_quantity_in_mt: "",
      payable_price_per_mt: "",
      payable_base_price: "",
      payable_currency: "0",
      payable_pph_percent: 2,
      payable_pph_value: "",
      payable_vat_percent: "",
      payable_vat_value: "",
      payable_total_value: "",
      payable_ctc: "",
      debit_note_no: "",
      payable_remarks: "",

      customer_id: "",
      receivable_type: "",
      receivable_quantity_in_mt: "",
      receivable_price_per_mt: "",
      receivable_base_price: "",
      receivable_currency: "0",
      receivable_pph_percent: 2,
      receivable_pph_value: "",
      receivable_vat_percent: "",
      receivable_vat_value: "",
      receivable_total_value: "",
      receivable_ctc: "",
      credit_note_no: "",
      receivable_remarks: "",
      add_service_type: null,
      activeTabIndex: 0,
      service_types_list: [],
      currencyData: [],
      vendors_list: [],
      customers_list: [],

      payable_files: [
        {
          file_description: "",
          file_name: "",
          file_extension: "",
          file_content: "",
          feature_name: "Services_Payable",
        },
      ],
      receivable_files: [
        {
          file_description: "",
          file_name: "",
          file_extension: "",
          file_content: "",
          feature_name: "Services_Receivable",
        },
      ],
      isLoading: true,
      slected_business_drop: {},
      slected_barge_drop: {},
      submitting: false,
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    let features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (!(features.includes("317") || features.includes("97"))) {
      window.location.href = "/dashboard";
    }
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    await api.get_service_types(loginUserID, idToken).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            if (res.service_types) {
              const service_types = [
                <MenuItem value="0" key={-1} disabled>
                  Please Select
                </MenuItem>,
              ];
              for (var i of res.service_types) {
                service_types.push(
                  <MenuItem key={i.id} value={i.service_type}>
                    {i.service_type}
                  </MenuItem>
                );
              }
              this.setState({
                service_types_list: service_types,
                //isLoading: false,
              });
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          } else if (res.code === "601") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });

    // for get_Currencies
    await api.getCurrencies(loginUserID, idToken).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
          if (res.code === "200") {
            if (res.Currency) {
              var currencies = [
                <MenuItem value="0" key={-1} disabled>
                  Select Currency
                </MenuItem>,
              ];
              for (var i of res.Currency) {
                currencies.push(
                  <MenuItem value={i.currency} key={[i.id]}>
                    {i.currency}
                  </MenuItem>
                );
              }
              this.setState({
                currencyData: currencies,
              });
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          } else if (res.code === "601") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
    // get vat value api
    const tax_type = "vat";
    await api
      .get_tax_percentages(loginUserID, idToken, tax_type)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.tax_percentages) {
                this.setState({
                  payable_vat_percent:
                    res.tax_percentages.length > 0
                      ? res.tax_percentages[0].tax_percentage
                      : 10,
                  receivable_vat_percent:
                    res.tax_percentages.length > 0
                      ? res.tax_percentages[0].tax_percentage
                      : 10,
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
    // Get Customers
    await api.getCustomers(loginUserID, idToken).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            if (res.customers) {
              const customerData = [];
              for (var i of res.customers) {
                customerData.push({
                  name: i.customer_name,
                  value: i.id,
                  key: i.id,
                });
              }
              this.setState({
                customers_list: customerData,
              });
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          } else if (res.code === "601") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
    //Get vendors
    await api.getVendors(loginUserID, idToken).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);

            if (res.vendors) {
              const vendors = [];
              for (var i of res.vendors) {
                vendors.push({ name: i.vendor_name, value: i.id, key: i.id });
              }
              this.setState({
                vendors_list: vendors,
                //isLoading: false
              });
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
    await api.getBusinessNumbers(loginUserID, idToken).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
            if (res.business_numbers) {
              let business_no_drop_value = [];
              for (var i of res.business_numbers) {
                business_no_drop_value.push({
                  name: i.business_no,
                  value: i.id,
                  mother_vessel_name: i.mother_vessel_name,
                });
              }
              this.setState({
                business_no_drop_value: business_no_drop_value,
                isLoading: false,
              });
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
    await api.get_barges(loginUserID, idToken).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            if (res.barges) {
              const total_barges = [];
              for (var i of res.barges) {
                total_barges.push({ name: i.id, value: i.id, key: i.id });
              }
              this.setState({
                barge_drop_value: total_barges,
              });
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });

    const serviceID = this.props.serviceRequestId;

    await api.get_service(loginUserID, idToken, serviceID).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            if (res.services) {
              if (res.services.length > 0) {
                this.setState({
                  invoice_no: res.services[0].invoice_no,
                  invoice_date: res.services[0].invoice_date,
                  ppn: res.services[0].ppn,
                  service_type: res.services[0].service_type,
                  service_id: res.services[0].service_id,
                  mother_vessel_name: res.services[0].mother_vessel_name,
                  barge_name: res.services[0].barge_name,
                  vendor_name: res.services[0].vendor_name,
                  vendor_id: res.services[0].vendor_id,
                  barge_id: res.services[0].barge_id,
                  business_no_id: res.services[0].business_no_id,
                  vendor_drop_value: {
                    name: res.services[0].vendor_name,
                    value: res.services[0].vendor_id,
                  },
                  slected_business_drop: {
                    name: res.services[0].business_no,
                    value: res.services[0].business_no_id,
                  },
                  selected_barge_drop: {
                    name: pad(res.services[0].barge_id),
                    value: res.services[0].barge_id,
                  },
                  payable_type: res.services[0].payable_type,
                  payable_quantity_in_mt:
                    res.services[0].payable_quantity_in_mt,
                  payable_price_per_mt: res.services[0].payable_price_per_mt,
                  payable_base_price: res.services[0].payable_base_price,
                  payable_currency: res.services[0].payable_currency
                    ? res.services[0].payable_currency
                    : "0",
                  payable_pph_percent: res.services[0].payable_pph_percent,
                  payable_pph_value: res.services[0].payable_pph_value,
                  payable_vat_percent: res.services[0].payable_vat_percent
                    ? res.services[0].payable_vat_percent
                    : this.state.payable_vat_percent,
                  payable_vat_value: res.services[0].payable_vat_value,
                  payable_total_value: res.services[0].payable_total_value,
                  payable_ctc: res.services[0].payable_ctc,
                  exchange_rate: res.services[0].payable_exchg_rate,
                  debit_note_no: res.services[0].debit_note_no,
                  payable_remarks: res.services[0].payable_remarks,

                  customer_name: res.services[0].customer_name,
                  customer_id: res.services[0].customer_id,
                  customer_drop_value: {
                    name: res.services[0].customer_name,
                    value: res.services[0].customer_id,
                  },
                  receivable_type: res.services[0].receivable_type,
                  receivable_quantity_in_mt:
                    res.services[0].receivable_quantity_in_mt,
                  receivable_price_per_mt:
                    res.services[0].receivable_price_per_mt,
                  receivable_base_price: res.services[0].receivable_base_price,
                  receivable_currency: res.services[0].receivable_currency
                    ? res.services[0].receivable_currency
                    : "0",
                  receivable_pph_percent:
                    res.services[0].receivable_pph_percent,
                  receivable_pph_value: res.services[0].receivable_pph_value,
                  receivable_vat_percent: res.services[0].receivable_vat_percent
                    ? res.services[0].receivable_vat_percent
                    : this.state.receivable_vat_percent,
                  receivable_vat_value: res.services[0].receivable_vat_value,
                  receivable_total_value:
                    res.services[0].receivable_total_value,
                  receivable_ctc: res.services[0].receivable_ctc,
                  receivable_exchange_rate:
                    res.services[0].receivable_exchg_rate,
                  credit_note_no: res.services[0].credit_note_no,
                  receivable_remarks: res.services[0].receivable_remarks,
                  payable_exchg_rat:
                    config.company === "SRPL" ||
                    config.company === "AVS" ||
                    config.company === "PRPL"
                      ? 1 /
                        Number(
                          res.services[0].payable_exchg_rate
                            ? res.services[0].payable_exchg_rate
                            : 1
                        )
                      : 1,
                  receivable_exchg_rate:
                    config.company === "SRPL" ||
                    config.company === "AVS" ||
                    config.company === "PRPL"
                      ? 1 /
                        Number(
                          res.services[0].receivable_exchg_rate
                            ? res.services[0].receivable_exchg_rate
                            : 1
                        )
                      : 1,
                  payable_files:
                    res.services[0].payable_files.length > 0
                      ? res.services[0].payable_files
                      : [
                          {
                            file_description: "",
                            file_name: "",
                            file_extension: "",
                            file_content: "",
                            feature_name: "Services_Payable",
                          },
                        ],
                  receivable_files:
                    res.services[0].receivable_files.length > 0
                      ? res.services[0].receivable_files
                      : [
                          {
                            file_description: "",
                            file_name: "",
                            file_extension: "",
                            file_content: "",
                            feature_name: "Services_Receivable",
                          },
                        ],
                  isLoading: false,
                });
              } else {
                this.setState({
                  isLoading: false,
                });
              }
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          } else if (res.code === "601") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
  }

  addNewServiceTypeHandler = () => {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const service_type = this.state.new_service_type;
    if (service_type) {
      api
        .add_service_type(loginUserID, idToken, service_type)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                this.setState(
                  {
                    snackBarSuccessOpen: true,
                    successMsg: res.message,
                    service_type: service_type,
                    new_service_type: null,
                  },
                  () => {
                    window.$("#myModal").modal("hide");
                    this.componentDidMount();
                  }
                );
              } else if (res.code === "601") {
                this.setState({
                  snackBarErrorOpen: true,
                  errorMsg: res.message,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              } else if (res.code === "624") {
                this.setState({
                  snackBarErrorOpen: true,
                  errorMsg: res.message,
                  new_service_type_error: true,
                });
              }
            });
          }
        });
    } else {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Plase enter service type.",
        new_service_type_error: true,
      });
    }
  };

  toLocaleString(value) {
    return value ? Number(value).toLocaleString() : 0;
  }

  submitHandler = () => {
    var {
      invoice_no,
      invoice_date,
      ppn,
      service_type,
      business_no_id,
      barge_id,
      vendor_id,
      payable_type,
      payable_quantity_in_mt = "",
      payable_price_per_mt = "",
      payable_base_price,
      payable_currency,
      payable_pph_percent,
      payable_pph_value,
      payable_vat_percent,
      payable_vat_value,
      payable_total_value,
      payable_ctc,
      debit_note_no,
      payable_remarks,

      customer_id,
      receivable_type,
      receivable_quantity_in_mt,
      receivable_price_per_mt,
      receivable_base_price,
      receivable_currency,
      receivable_pph_percent,
      receivable_pph_value,
      receivable_vat_percent,
      receivable_vat_value,
      receivable_total_value,
      receivable_ctc,
      credit_note_no,
      receivable_remarks,
      payable_files,
      receivable_files,
    } = this.state;
    this.setState({ submitting: true });
    if (service_type === "0") {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Plase fill mandatory fields",
        service_type_error: true,
      });
      return;
    }

    var payable_existing_file_ids = [];
    var receivable_existing_file_ids = [];
    const payable_existing_files = payable_files.filter((e) => e.id);
    for (var i of payable_existing_files) {
      payable_existing_file_ids.push(i.id);
    }
    const receivable_existing_files = receivable_files.filter((e) => e.id);
    for (var j of receivable_existing_files) {
      receivable_existing_file_ids.push(j.id);
    }
    const payable_new_files = payable_files.filter((e) => !e.id);
    const receivable_new_files = receivable_files.filter((e) => !e.id);
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    /**
     *  Checking Mandatory files
     */
    var missing_fields = 0;
    let files_missing = 0;
    var row = payable_files;
    for (var k of row) {
      if (k.file_type !== "" || k.file_content !== "") {
        if (k.file_content !== "") {
          if (k.file_description === "") {
            missing_fields++;
            k.file_description_error = true;
          } else {
            k.file_description = k.file_description.toUpperCase();
          }
        } else if (k.file_description !== "") {
          if (k.file_content === "") {
            files_missing++;
          }
        }
      }
    }
    this.setState({
      payable_files: row,
    });
    let receivables = receivable_files;
    for (var obj of receivables) {
      if (obj.file_type !== "" || obj.file_content !== "") {
        if (obj.file_content !== "") {
          if (obj.file_description === "") {
            missing_fields++;
            obj.file_description_error = true;
          } else {
            obj.file_description = obj.file_description.toUpperCase();
          }
        } else if (obj.file_description !== "") {
          if (obj.file_content === "") {
            files_missing++;
          }
        }
      }
    }
    this.setState({
      receivable_files: receivables,
    });

    if (missing_fields > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please fill mandatory fields.",
        submitting: false,
      });
      return;
    }
    if (files_missing > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please Select file.",
        submitting: false,
      });
      return;
    }
    try {
      var service_id = this.props.serviceRequestId;
    } catch (e) {
      window.location.href = "/Pagenotfound";
    }
    const payable_content_files = payable_new_files.filter(
      (e) => e.file_content !== ""
    );
    const receivable_content_files = receivable_new_files.filter(
      (e) => e.file_content !== ""
    );

    const receivable_exchg_rate = this.state.receivable_exchange_rate;
    const payable_exchg_rate = this.state.exchange_rate;
    api
      .update_service(
        loginUserID,
        idToken,
        service_id,
        invoice_no,
        invoice_date,
        ppn,
        service_type,
        business_no_id,
        barge_id,
        vendor_id,
        payable_type,
        payable_quantity_in_mt,
        payable_price_per_mt,
        payable_base_price,
        payable_currency,
        payable_pph_percent,
        payable_pph_value,
        payable_vat_percent,
        payable_vat_value,
        payable_total_value,
        payable_exchg_rate,
        payable_ctc,
        debit_note_no,
        payable_remarks,

        customer_id,
        receivable_type,
        receivable_quantity_in_mt,
        receivable_price_per_mt,
        receivable_base_price,
        receivable_currency,
        receivable_pph_percent,
        receivable_pph_value,
        receivable_vat_percent,
        receivable_vat_value,
        receivable_total_value,
        receivable_exchg_rate,
        receivable_ctc,
        credit_note_no,
        receivable_remarks,
        payable_content_files,
        receivable_content_files,
        payable_existing_file_ids,
        receivable_existing_file_ids
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState(
                {
                  snackBarSuccessOpen: true,
                  successMsg: res.message,
                  submitting: false,
                },
                () => {
                  this.props.onUpdateCosting(res.code);
                  // window.location.href = "/view-service/" + btoa(service_id);
                }
              );
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  };

  payableCalculations = (value) => {
    let payable_quantity_in_mt = this.state.payable_quantity_in_mt
      ? this.state.payable_quantity_in_mt
      : 0;
    let payable_price_per_mt = this.state.payable_price_per_mt
      ? this.state.payable_price_per_mt
      : 0;
    let currancy_exchg_rate = this.state.payable_exchg_rate
      ? this.state.payable_exchg_rate
      : 1;
    if (
      (config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL") &&
      this.state.payable_currency === "USD"
    ) {
      currancy_exchg_rate = 1;
    }
    if (
      (config.company === "SDAM" ||
        config.company === "SRK" ||
        config.company === "BTR") &&
      this.state.payable_currency === "IDR"
    ) {
      currancy_exchg_rate = 1;
    }

    let payable_base_price = (
      Number(payable_quantity_in_mt) * Number(payable_price_per_mt)
    ).toFixed(3);
    if (this.state.payable_type === "Lumpsum") {
      payable_base_price = this.state.payable_base_price
        ? this.state.payable_base_price
        : "";
    }
    let payable_vat_percent = this.state.payable_vat_percent
      ? this.state.payable_vat_percent
      : 10;
    let payable_vat_value = (
      (Number(payable_base_price) * Number(payable_vat_percent)) /
      100
    ).toFixed(2);
    if (value === "vat") {
      payable_vat_value = this.state.payable_vat_value;
    }
    let payable_pph_percent = this.state.payable_pph_percent
      ? this.state.payable_pph_percent
      : 2;
    let payable_pph_value = (
      (Number(payable_base_price) * Number(payable_pph_percent)) /
      100
    ).toFixed(2);
    if (
      config.company === "SRPL" ||
      config.company === "AVS" ||
      config.company === "PRPL"
    ) {
      payable_pph_value = 0;
    }
    if (value === "pph") {
      payable_pph_value = this.state.payable_pph_value;
    }

    let payable_total_value = (
      Number(payable_base_price) +
      Number(payable_vat_value) -
      Number(payable_pph_value)
    ).toFixed(2);
    let payable_ctc = (
      (Number(payable_base_price) + Number(payable_vat_value)) *
      Number(currancy_exchg_rate)
    ).toFixed(2);
    this.setState({
      payable_base_price: payable_base_price,
      payable_vat_percent: payable_vat_percent,
      payable_vat_value: payable_vat_value,
      payable_pph_percent: payable_pph_percent,
      payable_pph_value: payable_pph_value,
      payable_total_value: payable_total_value,
      payable_ctc: payable_ctc,
    });
  };

  receivableCalculations = (value) => {
    let receivable_quantity_in_mt = this.state.receivable_quantity_in_mt
      ? this.state.receivable_quantity_in_mt
      : 0;
    let receivable_price_per_mt = this.state.receivable_price_per_mt
      ? this.state.receivable_price_per_mt
      : 0;
    let currancy_exchg_rate = this.state.receivable_exchg_rate
      ? this.state.receivable_exchg_rate
      : 1;
    if (
      (config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL") &&
      this.state.receivable_currency === "USD"
    ) {
      currancy_exchg_rate = 1;
    }
    if (
      (config.company === "SDAM" ||
        config.company === "SRK" ||
        config.company === "BTR") &&
      this.state.receivable_currency === "IDR"
    ) {
      currancy_exchg_rate = 1;
    }
    let receivable_base_price = (
      Number(receivable_quantity_in_mt) * Number(receivable_price_per_mt)
    ).toFixed(3);
    if (this.state.receivable_type === "Lumpsum") {
      receivable_base_price = this.state.receivable_base_price
        ? this.state.receivable_base_price
        : "";
    }

    let receivable_vat_percent = this.state.receivable_vat_percent
      ? this.state.receivable_vat_percent
      : 10;
    let receivable_vat_value = (
      (Number(receivable_base_price) * Number(receivable_vat_percent)) /
      100
    ).toFixed(2);
    if (value === "vat") {
      receivable_vat_value = this.state.receivable_vat_value;
    }
    let receivable_pph_percent = this.state.receivable_pph_percent
      ? this.state.receivable_pph_percent
      : 2;
    let receivable_pph_value = (
      (Number(receivable_base_price) * Number(receivable_pph_percent)) /
      100
    ).toFixed(2);
    if (
      config.company === "SRPL" ||
      config.company === "AVS" ||
      config.company === "PRPL"
    ) {
      receivable_pph_value = 0;
    }
    if (value === "pph") {
      receivable_pph_value = this.state.receivable_pph_value;
    }
    let receivable_total_value = (
      Number(receivable_base_price) +
      Number(receivable_vat_value) -
      Number(receivable_pph_value)
    ).toFixed(2);
    let receivable_ctc = (
      (Number(receivable_base_price) + Number(receivable_vat_value)) *
      Number(currancy_exchg_rate)
    ).toFixed(2);
    this.setState({
      receivable_base_price: receivable_base_price,
      receivable_vat_percent: receivable_vat_percent,
      receivable_vat_value: receivable_vat_value,
      receivable_pph_percent: receivable_pph_percent,
      receivable_pph_value: receivable_pph_value,
      receivable_total_value: receivable_total_value,
      receivable_ctc: receivable_ctc,
    });
  };

  fileUploadHandler = (idx) => (event) => {
    let file = event.target.files[0];
    let file_data = "";
    let fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onloadend = () => {
      let file_content = fileReader.result;
      let file_extension = file.name.substr(file.name.lastIndexOf(".") + 1);
      let file_name = file.name;
      let file_type = file.type;
      file_data = file_content.replace("data:" + file_type + ";base64,", "");

      var row =
        this.state.activeTabIndex === 0
          ? this.state.payable_files
          : this.state.activeTabIndex === 1
          ? this.state.receivable_files
          : [];
      row[idx].file_name = file_name;
      row[idx].file_extension = file_extension;
      row[idx].file_type = file_type;
      row[idx].file_content = file_data;

      if (this.state.activeTabIndex === 0) {
        this.setState({
          payable_files: row,
        });
      } else if (this.state.activeTabIndex === 1) {
        this.setState({
          receivable_files: row,
        });
      }
    };
  };

  addMoreFileHandler = () => {
    const data = {
      file_description: "",
      file_name: "",
      file_extension: "",
      file_content: "",
      feature_name:
        this.state.activeTabIndex === 0
          ? "Services_Payable"
          : "Services_Receivable",
    };

    if (this.state.activeTabIndex === 0) {
      this.setState({
        payable_files: [...this.state.payable_files, data],
      });
    } else if (this.state.activeTabIndex === 1) {
      this.setState({
        receivable_files: [...this.state.receivable_files, data],
      });
    }
  };

  render() {
    const pr = /^\d*(\.\d{0,4})?$/;
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div className="container bg-white p-0" style={{ minHeight: "100vh" }}>
          <div className="clearfix content-header">
            <div className="row">
              <div className="col-sm text-left pl-0">
                <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                  Update Service Request
                </h4>
              </div>
            </div>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <div className="drawer-section">
            <div className="card">
              <div className="row mb-2 ">
                <div className="row col-lg-4 pl-0">
                  <label className="form_label mb-0">
                    Service Type <span className="text-danger">*</span>
                  </label>
                  <TextField
                    name="service_type"
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    onChange={(e) => {
                      this.setState({
                        service_type: e.target.value,
                        service_type_error: false,
                      });
                    }}
                    select
                    style={{ width: "80%" }}
                    value={this.state.service_type}
                    error={this.state.service_type_error}
                  >
                    {this.state.service_types_list}
                  </TextField>

                  <AddCircle
                    onClick={() =>
                      this.setState({ clickedCurrency: "Currency" })
                    }
                    style={{
                      marginTop: 15,
                      marginLeft: 5,
                      fontSize: 26,
                      color: config.themeColor,
                      cursor: "pointer",
                    }}
                    data-toggle="modal"
                    data-target="#myModal"
                  />
                </div>
                <div className="col-lg-4">
                  <label className="form_label mb-0">Business No</label>
                  {/* <TextField
                    name="mother_vessel_name"
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    onChange={(e) => {
                      this.setState({
                        mother_vessel_name: e.target.value,
                      });
                    }}
                    value={this.state.mother_vessel_name}
                  /> */}
                  <Autocomplete
                    options={this.state.business_no_drop_value}
                    getOptionLabel={(option) => option.mother_vessel_name}
                    onChange={(event, value) => {
                      if (value) {
                        this.setState({
                          business_no_id: value.value,
                          slected_business_drop: value,
                        });
                      } else {
                        this.setState({
                          business_no_id: "",
                          slected_business_drop: {},
                        });
                      }
                    }}
                    value={this.state.slected_business_drop}
                    InputProps={{
                      style: {
                        fontFamily: "Poppins",
                        fontSize: "14px",
                      },
                    }}
                    size="small"
                    name="purchaseContract"
                    renderOption={(option) => (
                      <React.Fragment>
                        <div className="col-lg p-0 m-0">
                          <label className="form_label mb-0">
                            {option.mother_vessel_name}
                          </label>
                          <label
                            className="contract_display_header_value m-0 pl-0"
                            style={{ fontSize: 10, paddingLeft: 5 }}
                          >
                            {"( " + option.name + " )"}
                          </label>
                        </div>
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Business No "
                        error={this.state.purchaseContractError}
                        variant="outlined"
                        fullWidth
                        style={{ top: 8 }}
                      />
                    )}
                    style={{ display: "contents" }}
                  />
                </div>
                <div className="col-lg-4">
                  <label className="form_label mb-0">Barge</label>
                  {/* <TextField
                    name="barge_name"
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    onChange={(e) => {
                      this.setState({
                        barge_name: e.target.value,
                      });
                    }}
                    value={this.state.barge_name}
                  /> */}
                  <Autocomplete
                    options={this.state.barge_drop_value}
                    getOptionLabel={(option) => pad(option.name)}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <div className="col-lg p-0 m-0">{pad(option.name)}</div>
                      </React.Fragment>
                    )}
                    onChange={(event, value) => {
                      if (value) {
                        this.setState({
                          barge_id: value.value,
                          selected_barge_drop: value,
                        });
                      } else {
                        this.setState({
                          barge_id: null,
                          selected_barge_drop: {},
                        });
                      }
                    }}
                    value={this.state.selected_barge_drop}
                    //disableCloseOnSelect
                    name="barge_id"
                    size="small"
                    //value={this.state.barge_value}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Search Barge"
                        variant="outlined"
                        style={{ top: 8 }}
                        fullWidth
                      />
                    )}
                    style={{ display: "contents" }}
                  />
                </div>
              </div>
              <div className="row mb-2">
                <Tabs
                  value={this.state.activeTabIndex}
                  onChange={(event, value) => {
                    this.setState({
                      activeTabIndex: value,
                    });
                  }}
                  indicatorColor="primary"
                  textColor="primary"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                  style={{
                    backgroundColor: "#ffffff",
                    color: "#6c7293",
                    padding: 0,
                  }}
                  TabIndicatorProps={{
                    style: { background: config.themeColor },
                  }}
                  className="border-bottom"
                >
                  <Tab
                    label={
                      <h4
                        style={{
                          color:
                            this.state.activeTabIndex === 0
                              ? config.themeColor
                              : "#6c7293",
                          fontSize: "16px",
                          border: "0px",
                        }}
                      >
                        Payable
                      </h4>
                    }
                    value={0}
                    style={{
                      fontFamily: "Poppins",
                      textTransform: "capitalize",
                      color: "#6c7293",
                    }}
                  />
                  <Tab
                    label={
                      <h4
                        style={{
                          color:
                            this.state.activeTabIndex === 1
                              ? config.themeColor
                              : "#6c7293",
                          fontSize: "16px",
                          border: "0px",
                        }}
                      >
                        Receivable
                      </h4>
                    }
                    value={1}
                    style={{
                      fontFamily: "Poppins",
                      textTransform: "capitalize",
                      color: "#6c7293",
                    }}
                  />
                </Tabs>
              </div>
              {this.state.activeTabIndex === 0 && (
                <div className="card border_dashed mb-3">
                  <div className="row mb-3">
                    <div className="col-lg p-2">
                      <div className="row">
                        <div className="col-lg-12 p-0 mb-3">
                          <label className="form_label mb-0">Vendor</label>
                          <Autocomplete
                            options={this.state.vendors_list}
                            getOptionLabel={(option) => option.name}
                            onChange={(event, value) => {
                              if (value) {
                                this.setState({
                                  vendor_id: value.value,
                                  vendor_drop_value: {
                                    name: value.name,
                                    value: value.value,
                                  },
                                });
                              } else {
                                this.setState({
                                  vendor_id: "",
                                  vendor_drop_value: { name: "", value: "" },
                                });
                              }
                            }}
                            InputProps={{
                              style: {
                                fontFamily: "Poppins",
                                fontSize: "14px",
                              },
                            }}
                            size="small"
                            name="vendor"
                            value={this.state.vendor_drop_value}
                            renderOption={(option) => (
                              <React.Fragment>{option.name}</React.Fragment>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Please Select"
                                variant="outlined"
                                fullWidth
                                style={{ top: 8 }}
                              />
                            )}
                            sryle={{ display: "contents" }}
                          />
                        </div>
                        <div className="col-lg-12 p-0">
                          <label className="form_label mb-0">Type</label>
                          <RadioGroup
                            aria-label="payable_type"
                            name="payable_type"
                            onChange={(e) => {
                              this.setState({
                                payable_type: e.target.value,
                              });
                            }}
                            style={{ flexDirection: "row" }}
                          >
                            <FormControlLabel
                              value="Per MT"
                              control={
                                <NewRadio
                                  checked={this.state.payable_type === "Per MT"}
                                />
                              }
                              label="Per MT"
                            />
                            <FormControlLabel
                              value="Lumpsum"
                              control={
                                <NewRadio
                                  checked={
                                    this.state.payable_type === "Lumpsum"
                                  }
                                />
                              }
                              label="Lumpsum"
                            />
                          </RadioGroup>
                        </div>
                        <div className="col-lg-12 p-0">
                          <label className="form_label mb-0">
                            Debit Note No
                          </label>
                          <TextField
                            name="debit_note_no"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                            onChange={(e) => {
                              this.setState({
                                debit_note_no: e.target.value,
                              });
                            }}
                            value={this.state.debit_note_no}
                          />
                        </div>
                        <div className="col-lg-12 p-0">
                          <label className="form_label mb-0">Remarks</label>
                          <TextField
                            name="payable_remarks"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                            multiline
                            rows={3}
                            onChange={(e) => {
                              this.setState({
                                payable_remarks: e.target.value,
                              });
                            }}
                            value={this.state.payable_remarks}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-lg p-2 rounded"
                      style={{ backgroundColor: "#f7f3f0" }}
                    >
                      <div className="row col-lg-12 p-1">
                        <h6>Payable</h6>
                      </div>
                      {this.state.payable_type === "Per MT" && (
                        <div className="row">
                          <div className="col-lg-12 mb-2">
                            <label className="form_label mb-0">
                              Quantity in MT
                            </label>
                            <TextField
                              name="payable_quantity_in_mt"
                              margin="dense"
                              variant="standard"
                              InputProps={{
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                },
                              }}
                              fullWidth
                              value={this.state.payable_quantity_in_mt}
                              onChange={(e) => {
                                if (
                                  e.target.value === "" ||
                                  pr.test(e.target.value)
                                ) {
                                  this.setState(
                                    {
                                      payable_quantity_in_mt: e.target.value,
                                    },
                                    () => this.payableCalculations()
                                  );
                                }
                              }}
                            />
                          </div>
                          <div className="col-lg-12 mb-2">
                            <label className="form_label mb-0">
                              Base Price per MT
                            </label>
                            <TextField
                              name="payable_price_per_mt"
                              margin="dense"
                              variant="standard"
                              fullWidth
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    className="adornment_background"
                                    // variant="outlined"
                                    position="end"
                                    style={{
                                      marginRight: "-11px",
                                      marginTop: "-2px",
                                      width: "50%",
                                    }}
                                  >
                                    <TextField
                                      name="payable_currency"
                                      margin="dense"
                                      // variant='outlined'
                                      fullWidth
                                      select
                                      InputProps={{
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                        },
                                        disableUnderline: true,
                                      }}
                                      value={this.state.payable_currency}
                                      onChange={(e) => {
                                        this.setState(
                                          {
                                            payable_currency: e.target.value,
                                          },
                                          () => this.payableCalculations()
                                        );
                                      }}
                                    >
                                      {this.state.currencyData}
                                    </TextField>
                                  </InputAdornment>
                                ),
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                  padding: 0,
                                },
                              }}
                              value={this.state.payable_price_per_mt}
                              onChange={(e) => {
                                if (
                                  e.target.value === "" ||
                                  pr.test(e.target.value)
                                ) {
                                  this.setState(
                                    {
                                      payable_price_per_mt: e.target.value,
                                    },
                                    () => this.payableCalculations()
                                  );
                                }
                              }}
                            />
                          </div>
                          <div className="col-lg-12 mb-2">
                            <label
                              className="contract_display_header_label"
                              style={{ color: "rgba(72, 59, 52, 0.7)" }}
                            >
                              Base Price
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ color: "#42352d" }}
                            >
                              {this.toLocaleString(
                                this.state.payable_base_price
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      {this.state.payable_type === "Lumpsum" && (
                        <div className="row">
                          <div className="col-lg-12 mb-2">
                            <label className="form_label mb-0">
                              Base Price
                            </label>
                            <TextField
                              name="payable_base_price"
                              margin="dense"
                              variant="standard"
                              fullWidth
                              value={this.state.payable_base_price}
                              onChange={(e) => {
                                if (
                                  e.target.value === "" ||
                                  pr.test(e.target.value)
                                ) {
                                  this.setState(
                                    {
                                      payable_base_price: e.target.value,
                                    },
                                    () => this.payableCalculations()
                                  );
                                }
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    className="adornment_background"
                                    position="end"
                                    style={{
                                      marginRight: "-11px",
                                      marginTop: "-2px",
                                      width: "50%",
                                    }}
                                  >
                                    <TextField
                                      name="payable_currency"
                                      margin="dense"
                                      // variant='outlined'
                                      fullWidth
                                      select
                                      InputProps={{
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                        },
                                        disableUnderline: true,
                                      }}
                                      value={this.state.payable_currency}
                                      onChange={(e) => {
                                        this.setState(
                                          {
                                            payable_currency: e.target.value,
                                          },
                                          () => this.payableCalculations()
                                        );
                                      }}
                                    >
                                      {this.state.currencyData}
                                    </TextField>
                                  </InputAdornment>
                                ),
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                  padding: 0,
                                },
                              }}
                            />
                          </div>
                        </div>
                      )}

                      <div className="row">
                        <div className="col-lg-6 mb-2">
                          <label
                            className="contract_display_header_label"
                            style={{ color: "rgba(72, 59, 52, 0.7)" }}
                          >
                            VAT %
                          </label>
                          <div
                            className="contract_display_header_value"
                            style={{ color: "#42352d" }}
                          >
                            {this.state.payable_vat_percent}
                          </div>
                        </div>
                        <div className="col-lg-6 mb-2">
                          <label className="form_label mb-0">VAT</label>
                          <TextField
                            name="payable_vat_value"
                            margin="dense"
                            variant="standard"
                            fullWidth
                            InputProps={{
                              style: {
                                fontFamily: "Poppins",
                                fontSize: "14px",
                              },
                            }}
                            value={this.state.payable_vat_value}
                            onChange={(e) => {
                              if (
                                e.target.value === "" ||
                                pr.test(e.target.value)
                              ) {
                                this.setState(
                                  {
                                    payable_vat_value: e.target.value,
                                  },
                                  () => this.payableCalculations("vat")
                                );
                              }
                            }}
                          />
                        </div>
                      </div>
                      {(config.company === "SDAM" ||
                        config.company === "SRK" ||
                        config.company === "BTR") && (
                        <div className="row">
                          <div className="col-lg-6 mb-2">
                            <label className="form_label mb-0">PPH %</label>
                            <TextField
                              name="payable_pph_percent"
                              margin="dense"
                              variant="standard"
                              fullWidth
                              InputProps={{
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                },
                              }}
                              value={this.state.payable_pph_percent}
                              onChange={(e) => {
                                if (
                                  e.target.value === "" ||
                                  pr.test(e.target.value)
                                ) {
                                  this.setState(
                                    {
                                      payable_pph_percent: e.target.value,
                                    },
                                    () => this.payableCalculations()
                                  );
                                }
                              }}
                            />
                          </div>
                          <div className="col-lg-6 mb-2">
                            <label className="form_label mb-0">PPH</label>
                            <TextField
                              name="payable_pph_value"
                              margin="dense"
                              variant="standard"
                              fullWidth
                              InputProps={{
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                },
                              }}
                              value={this.state.payable_pph_value}
                              onChange={(e) => {
                                if (
                                  e.target.value === "" ||
                                  pr.test(e.target.value)
                                ) {
                                  this.setState(
                                    {
                                      payable_pph_value: e.target.value,
                                    },
                                    () => this.payableCalculations("pph")
                                  );
                                }
                              }}
                            />
                          </div>
                        </div>
                      )}

                      <div className="row">
                        <div className="col-lg-12 mb-2">
                          <label
                            className="contract_display_header_label"
                            style={{ color: "rgba(72, 59, 52, 0.7)" }}
                          >
                            Total Payable
                          </label>
                          <div
                            className="contract_display_header_value"
                            style={{ color: "#42352d" }}
                          >
                            {this.toLocaleString(
                              this.state.payable_total_value
                            )}
                          </div>
                        </div>
                        {(config.company === "SDAM" ||
                          config.company === "SRK" ||
                          config.company === "BTR") &&
                          this.state.payable_currency !== "IDR" &&
                          this.state.payable_currency !== "0" && (
                            <div className="col-lg-6 mb-2">
                              <label className="form_label mb-0">
                                Exchange Rate {this.state.payable_currency} to
                                IDR
                              </label>
                              <TextField
                                name="exchange_rate"
                                margin="dense"
                                variant="standard"
                                InputProps={{
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                  },
                                }}
                                placeholder="Enter Value"
                                fullWidth
                                value={this.state.exchange_rate}
                                onChange={(e) => {
                                  const reg = /^\d*(\.\d{0,10})?$/;
                                  if (
                                    e.target.value === "" ||
                                    reg.test(e.target.value)
                                  ) {
                                    var value = e.target.value;
                                    if (value === null || value === "") {
                                      value = 1;
                                    }
                                    if (
                                      config.company === "SRPL" ||
                                      config.company === "AVS" ||
                                      config.company === "PRPL"
                                    ) {
                                      value = 1 / Number(value);
                                    }
                                    this.setState(
                                      {
                                        exchange_rate: e.target.value,
                                        payable_exchg_rate: value,
                                      },
                                      () => this.payableCalculations()
                                    );
                                  }
                                }}
                              />
                            </div>
                          )}
                        {(config.company === "SRPL" ||
                          config.company === "AVS" ||
                          config.company === "PRPL") &&
                          this.state.payable_currency !== "USD" &&
                          this.state.payable_currency !== "0" && (
                            <div className="col-lg-6 mb-2">
                              <label className="form_label mb-0">
                                Exchange Rate USD to{" "}
                                {this.state.payable_currency}
                              </label>
                              <TextField
                                name="exchange_rate"
                                margin="dense"
                                variant="standard"
                                InputProps={{
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                  },
                                }}
                                placeholder="Enter Value"
                                fullWidth
                                value={this.state.exchange_rate}
                                onChange={(e) => {
                                  const reg = /^\d*(\.\d{0,10})?$/;
                                  if (
                                    e.target.value === "" ||
                                    reg.test(e.target.value)
                                  ) {
                                    var value = e.target.value;
                                    if (value === null || value === "") {
                                      value = 1;
                                    }
                                    if (
                                      config.company === "SRPL" ||
                                      config.company === "AVS" ||
                                      config.company === "PRPL"
                                    ) {
                                      value = 1 / Number(value);
                                    }
                                    this.setState(
                                      {
                                        exchange_rate: e.target.value,
                                        payable_exchg_rate: value,
                                      },
                                      () => this.payableCalculations()
                                    );
                                  }
                                }}
                              />
                            </div>
                          )}
                        <div className="col-lg-6 mb-2">
                          <label
                            className="contract_display_header_label"
                            style={{ color: "rgba(72, 59, 52, 0.7)" }}
                          >
                            Cost to Company
                          </label>
                          <div
                            className="contract_display_header_value"
                            style={{ color: "#42352d" }}
                          >
                            {this.toLocaleString(this.state.payable_ctc)}{" "}
                            {this.state.payable_currency !== "0"
                              ? this.state.payable_currency
                              : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {(this.state.features.includes("323") ||
                    this.state.features.includes("97")) && (
                    <div className="card p-0 border mb-3">
                      <div className="card-header section_header">
                        <h5>File Upload Details</h5>
                      </div>
                      <div className="card-body">
                        {this.state.payable_files.map((e, index) => (
                          <div className="row mb-3" key={index}>
                            <div className="col-lg-4 p-0">
                              <label className="form_label mb-0">
                                File Description
                              </label>
                              <TextField
                                name="file_description"
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                inputProps={{
                                  style: { textTransform: "uppercase" },
                                }}
                                value={e.file_description}
                                error={e.file_description_error}
                                onChange={(e) => {
                                  var row = this.state.payable_files;
                                  row[index].file_description = e.target.value;
                                  row[index].file_description_error = false;
                                  this.setState({
                                    payable_files: row,
                                  });
                                }}
                              />
                            </div>
                            <div className="col-lg-6 pr-0">
                              <label className="form_label mb-2">
                                Select file to upload
                              </label>
                              <input
                                type="file"
                                name="fileToUpload"
                                id="fileToUpload"
                                className="form-control border-0 pl-0"
                                onChange={this.fileUploadHandler(index)}
                              />
                            </div>

                            <center className="col-lg-1 d-flex justify-content-center">
                              <i
                                className="fa fa-trash-o m-auto"
                                onClick={(e) => {
                                  const row = this.state.payable_files;
                                  row.splice(index, 1);
                                  this.setState({
                                    payable_files: row,
                                  });
                                }}
                                style={{
                                  fontSize: 24,
                                  color: config.themeColor,
                                  cursor: "pointer",
                                }}
                              />
                            </center>
                          </div>
                        ))}
                        <button
                          type="button"
                          style={{ color: config.themeColor, width: 110 }}
                          className="header_button header_button_text addrow_button_adjustment"
                          onClick={this.addMoreFileHandler}
                        >
                          Add More
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {this.state.activeTabIndex === 1 && (
                <div className="card border_dashed mb-3">
                  <div className="row mb-3">
                    <div className="col-lg p-2">
                      <div className="row">
                        <div className="col-lg-12 p-0 mb-3">
                          <label className="form_label mb-0">Customer</label>
                          <Autocomplete
                            options={this.state.customers_list}
                            getOptionLabel={(option) => option.name}
                            onChange={(event, value) => {
                              if (value) {
                                this.setState({
                                  customer_id: value.value,
                                  customer_drop_value: {
                                    name: value.name,
                                    value: value.value,
                                  },
                                });
                              } else {
                                this.setState({
                                  customer_id: "",
                                  customer_drop_value: { name: "", value: "" },
                                });
                              }
                            }}
                            InputProps={{
                              style: {
                                fontFamily: "Poppins",
                                fontSize: "14px",
                              },
                            }}
                            size="small"
                            name="vendor"
                            value={this.state.customer_drop_value}
                            renderOption={(option) => (
                              <React.Fragment>{option.name}</React.Fragment>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Please Select"
                                variant="outlined"
                                fullWidth
                                style={{ top: 8 }}
                              />
                            )}
                            sryle={{ display: "contents" }}
                          />
                        </div>
                        <div className="col-lg-12 p-0">
                          <label className="form_label mb-0">Type</label>
                          <RadioGroup
                            aria-label="receivable_type"
                            name="receivable_type"
                            onChange={(e) => {
                              this.setState({
                                receivable_type: e.target.value,
                              });
                            }}
                            style={{ flexDirection: "row" }}
                          >
                            <FormControlLabel
                              value="Per MT"
                              control={
                                <NewRadio
                                  checked={
                                    this.state.receivable_type === "Per MT"
                                  }
                                />
                              }
                              label="Per MT"
                            />
                            <FormControlLabel
                              value="Lumpsum"
                              control={
                                <NewRadio
                                  checked={
                                    this.state.receivable_type === "Lumpsum"
                                  }
                                />
                              }
                              label="Lumpsum"
                            />
                          </RadioGroup>
                        </div>
                        <div className="col-lg-12 p-0">
                          <label className="form_label mb-0">
                            Credit Note No
                          </label>
                          <TextField
                            name="credit_note_no"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                            onChange={(e) => {
                              this.setState({
                                credit_note_no: e.target.value,
                              });
                            }}
                            value={this.state.credit_note_no}
                          />
                        </div>
                        <div className="col-lg-12 p-0">
                          <label className="form_label mb-0">Remarks</label>
                          <TextField
                            name="receivable_remarks"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                            multiline
                            rows={3}
                            onChange={(e) => {
                              this.setState({
                                receivable_remarks: e.target.value,
                              });
                            }}
                            value={this.state.receivable_remarks}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-lg p-2 rounded"
                      style={{ backgroundColor: "#f7f3f0" }}
                    >
                      <div className="row col-lg-12 p-1">
                        <h6>Receivable</h6>
                      </div>
                      {this.state.receivable_type === "Per MT" && (
                        <div className="row">
                          <div className="col-lg-12 mb-2">
                            <label className="form_label mb-0">
                              Quantity in MT
                            </label>
                            <TextField
                              name="receivable_quantity_in_mt"
                              margin="dense"
                              variant="standard"
                              InputProps={{
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                },
                              }}
                              fullWidth
                              value={this.state.receivable_quantity_in_mt}
                              onChange={(e) => {
                                if (
                                  e.target.value === "" ||
                                  pr.test(e.target.value)
                                ) {
                                  this.setState(
                                    {
                                      receivable_quantity_in_mt: e.target.value,
                                    },
                                    () => this.receivableCalculations()
                                  );
                                }
                              }}
                            />
                          </div>
                          <div className="col-lg-12 mb-2">
                            <label className="form_label mb-0">
                              Base Price per MT
                            </label>
                            <TextField
                              name="receivable_price_per_mt"
                              margin="dense"
                              variant="standard"
                              fullWidth
                              value={this.state.receivable_price_per_mt}
                              onChange={(e) => {
                                if (
                                  e.target.value === "" ||
                                  pr.test(e.target.value)
                                ) {
                                  this.setState(
                                    {
                                      receivable_price_per_mt: e.target.value,
                                    },
                                    () => this.receivableCalculations()
                                  );
                                }
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    className="adornment_background"
                                    // variant="outlined"
                                    position="end"
                                    style={{
                                      marginRight: "-11px",
                                      marginTop: "-2px",
                                      width: "50%",
                                    }}
                                  >
                                    <TextField
                                      name="receivable_currency"
                                      margin="dense"
                                      // variant='outlined'
                                      fullWidth
                                      select
                                      InputProps={{
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                        },
                                        disableUnderline: true,
                                      }}
                                      value={this.state.receivable_currency}
                                      onChange={(e) => {
                                        this.setState(
                                          {
                                            receivable_currency: e.target.value,
                                          },
                                          () => this.receivableCalculations()
                                        );
                                      }}
                                    >
                                      {this.state.currencyData}
                                    </TextField>
                                  </InputAdornment>
                                ),
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                  padding: 0,
                                },
                              }}
                            />
                          </div>
                          <div className="col-lg-12 mb-2">
                            <label
                              className="contract_display_header_label"
                              style={{ color: "rgba(72, 59, 52, 0.7)" }}
                            >
                              Base Price
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ color: "#42352d" }}
                            >
                              {this.toLocaleString(
                                this.state.receivable_base_price
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      {this.state.receivable_type === "Lumpsum" && (
                        <div className="row">
                          <div className="col-lg-12 mb-2">
                            <label className="form_label mb-0">
                              Base Price
                            </label>
                            <TextField
                              name="receivable_base_price"
                              margin="dense"
                              variant="standard"
                              fullWidth
                              value={this.state.receivable_base_price}
                              onChange={(e) => {
                                if (
                                  e.target.value === "" ||
                                  pr.test(e.target.value)
                                ) {
                                  this.setState(
                                    {
                                      receivable_base_price: e.target.value,
                                    },
                                    () => this.receivableCalculations()
                                  );
                                }
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    className="adornment_background"
                                    // variant="outlined"
                                    position="end"
                                    style={{
                                      marginRight: "-11px",
                                      marginTop: "-2px",
                                      width: "50%",
                                    }}
                                  >
                                    <TextField
                                      name="receivable_currency"
                                      margin="dense"
                                      // variant='outlined'
                                      fullWidth
                                      select
                                      InputProps={{
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                        },
                                        disableUnderline: true,
                                      }}
                                      value={this.state.receivable_currency}
                                      onChange={(e) => {
                                        this.setState(
                                          {
                                            receivable_currency: e.target.value,
                                          },
                                          () => this.receivableCalculations()
                                        );
                                      }}
                                    >
                                      {this.state.currencyData}
                                    </TextField>
                                  </InputAdornment>
                                ),
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                  padding: 0,
                                },
                              }}
                            />
                          </div>
                        </div>
                      )}

                      <div className="row">
                        <div className="col-lg-6 mb-2">
                          <label
                            className="contract_display_header_label"
                            style={{ color: "rgba(72, 59, 52, 0.7)" }}
                          >
                            VAT %
                          </label>
                          <div
                            className="contract_display_header_value"
                            style={{ color: "#42352d" }}
                          >
                            {this.state.receivable_vat_percent}
                          </div>
                        </div>
                        <div className="col-lg-6 mb-2">
                          <label className="form_label mb-0">VAT</label>
                          <TextField
                            name="receivable_vat_value"
                            margin="dense"
                            variant="standard"
                            fullWidth
                            InputProps={{
                              style: {
                                fontFamily: "Poppins",
                                fontSize: "14px",
                              },
                            }}
                            value={this.state.receivable_vat_value}
                            onChange={(e) => {
                              if (
                                e.target.value === "" ||
                                pr.test(e.target.value)
                              ) {
                                this.setState(
                                  {
                                    receivable_vat_value: e.target.value,
                                  },
                                  () => this.receivableCalculations("vat")
                                );
                              }
                            }}
                          />
                        </div>
                      </div>
                      {(config.company === "SDAM" ||
                        config.company === "SRK" ||
                        config.company === "BTR") && (
                        <div className="row">
                          <div className="col-lg-6 mb-2">
                            <label className="form_label mb-0">PPH %</label>
                            <TextField
                              name="receivable_pph_percent"
                              margin="dense"
                              variant="standard"
                              fullWidth
                              InputProps={{
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                },
                              }}
                              value={this.state.receivable_pph_percent}
                              onChange={(e) => {
                                if (
                                  e.target.value === "" ||
                                  pr.test(e.target.value)
                                ) {
                                  this.setState(
                                    {
                                      receivable_pph_percent: e.target.value,
                                    },
                                    () => this.receivableCalculations()
                                  );
                                }
                              }}
                            />
                          </div>
                          <div className="col-lg-6 mb-2">
                            <label className="form_label mb-0">PPH</label>
                            <TextField
                              name="receivable_pph_value"
                              margin="dense"
                              variant="standard"
                              fullWidth
                              InputProps={{
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                },
                              }}
                              value={this.state.receivable_pph_value}
                              onChange={(e) => {
                                if (
                                  e.target.value === "" ||
                                  pr.test(e.target.value)
                                ) {
                                  this.setState(
                                    {
                                      receivable_pph_value: e.target.value,
                                    },
                                    () => this.receivableCalculations("pph")
                                  );
                                }
                              }}
                            />
                          </div>
                        </div>
                      )}

                      <div className="row">
                        <div className="col-lg-12 mb-2">
                          <label
                            className="contract_display_header_label"
                            style={{ color: "rgba(72, 59, 52, 0.7)" }}
                          >
                            Total Receivable
                          </label>
                          <div
                            className="contract_display_header_value"
                            style={{ color: "#42352d" }}
                          >
                            {this.toLocaleString(
                              this.state.receivable_total_value
                            )}
                          </div>
                        </div>
                        {(config.company === "SDAM" ||
                          config.company === "SRK" ||
                          config.company === "BTR") &&
                          this.state.receivable_currency !== "IDR" &&
                          this.state.receivable_currency !== "0" && (
                            <div className="col-lg-6 mb-2">
                              <label className="form_label mb-0">
                                Exchange Rate {this.state.receivable_currency}{" "}
                                to IDR
                              </label>
                              <TextField
                                name="receivable_exchange_rate"
                                margin="dense"
                                variant="standard"
                                InputProps={{
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                  },
                                }}
                                placeholder="Enter Value"
                                fullWidth
                                value={this.state.receivable_exchange_rate}
                                onChange={(e) => {
                                  const reg = /^\d*(\.\d{0,10})?$/;
                                  if (
                                    e.target.value === "" ||
                                    reg.test(e.target.value)
                                  ) {
                                    var value = e.target.value;
                                    if (value === null || value === "") {
                                      value = 1;
                                    }
                                    if (
                                      config.company === "SRPL" ||
                                      config.company === "AVS" ||
                                      config.company === "PRPL"
                                    ) {
                                      value = 1 / Number(value);
                                    }
                                    this.setState(
                                      {
                                        receivable_exchange_rate:
                                          e.target.value,
                                        receivable_exchg_rate: value,
                                      },
                                      () => this.receivableCalculations()
                                    );
                                  }
                                }}
                              />
                            </div>
                          )}
                        {(config.company === "SRPL" ||
                          config.company === "AVS" ||
                          config.company === "PRPL") &&
                          this.state.receivable_currency !== "USD" &&
                          this.state.receivable_currency !== "0" && (
                            <div className="col-lg-6 mb-2">
                              <label className="form_label mb-0">
                                Exchange Rate USD to{" "}
                                {this.state.receivable_currency}
                              </label>
                              <TextField
                                name="receivable_exchange_rate"
                                margin="dense"
                                variant="standard"
                                InputProps={{
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                  },
                                }}
                                placeholder="Enter Value"
                                fullWidth
                                value={this.state.receivable_exchange_rate}
                                onChange={(e) => {
                                  const reg = /^\d*(\.\d{0,10})?$/;
                                  if (
                                    e.target.value === "" ||
                                    reg.test(e.target.value)
                                  ) {
                                    var value = e.target.value;
                                    if (value === null || value === "") {
                                      value = 1;
                                    }
                                    if (
                                      config.company === "SRPL" ||
                                      config.company === "AVS" ||
                                      config.company === "PRPL"
                                    ) {
                                      value = 1 / Number(value);
                                    }
                                    this.setState(
                                      {
                                        receivable_exchange_rate:
                                          e.target.value,
                                        receivable_exchg_rate: value,
                                      },
                                      () => this.receivableCalculations()
                                    );
                                  }
                                }}
                              />
                            </div>
                          )}
                        <div className="col-lg-6 mb-2">
                          <label
                            className="contract_display_header_label"
                            style={{ color: "rgba(72, 59, 52, 0.7)" }}
                          >
                            Cost to Company
                          </label>
                          <div
                            className="contract_display_header_value"
                            style={{ color: "#42352d" }}
                          >
                            {this.toLocaleString(this.state.receivable_ctc)}{" "}
                            {this.state.receivable_currency !== "0"
                              ? this.state.receivable_currency
                              : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {(this.state.features.includes("323") ||
                    this.state.features.includes("97")) && (
                    <div className="card p-0 border mb-3">
                      <div className="card-header section_header">
                        <h5>File Upload Details</h5>
                      </div>
                      <div className="card-body">
                        {this.state.receivable_files.map((e, index) => (
                          <div className="row mb-3" key={index}>
                            <div className="col-lg-4 p-0">
                              <label className="form_label mb-0">
                                File Description
                              </label>
                              <TextField
                                name="file_description"
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                inputProps={{
                                  style: { textTransform: "uppercase" },
                                }}
                                value={e.file_description}
                                error={e.file_description_error}
                                onChange={(e) => {
                                  var row = this.state.receivable_files;
                                  row[index].file_description = e.target.value;
                                  row[index].file_description_error = false;
                                  this.setState({
                                    receivable_files: row,
                                  });
                                }}
                              />
                            </div>
                            <div className="col-lg-6 pr-0">
                              <label className="form_label mb-2">
                                Select file to upload
                              </label>
                              <input
                                type="file"
                                name="fileToUpload"
                                id="fileToUpload"
                                className="form-control border-0 pl-0"
                                onChange={this.fileUploadHandler(index)}
                              />
                            </div>

                            <center className="col-lg-1 d-flex justify-content-center">
                              <i
                                className="fa fa-trash-o m-auto"
                                onClick={(e) => {
                                  const row = this.state.receivable_files;
                                  row.splice(index, 1);
                                  this.setState({
                                    receivable_files: row,
                                  });
                                }}
                                style={{
                                  fontSize: 24,
                                  color: config.themeColor,
                                  cursor: "pointer",
                                }}
                              />
                            </center>
                          </div>
                        ))}
                        <button
                          type="button"
                          style={{ color: config.themeColor, width: 110 }}
                          className="header_button header_button_text addrow_button_adjustment"
                          onClick={this.addMoreFileHandler}
                        >
                          Add More
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button"
              name="previous"
              onClick={() => {
                this.props.onCancel(false);
              }}
            >
              Close
            </button>
            <button
              type="button"
              className="btn next_button"
              name="next"
              onClick={this.submitHandler}
              disabled={this.state.submitLoading}
            >
              Submit
              <span
                className={
                  this.state.submitLoading
                    ? "spinner-grow spinner-grow-sm mr-2"
                    : ""
                }
              ></span>
            </button>
          </div>
          <div className="modal fade" id="myModal" role="dialog">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5
                    className="modal-title"
                    style={{ border: "none", padding: 0 }}
                  >
                    Add Service Type
                  </h5>
                  <button type="button" className="close" data-dismiss="modal">
                    &times;
                  </button>
                </div>
                <div className="modal-body">
                  <TextField
                    name="new_service_type"
                    placeholder="Add Service Type *"
                    margin="dense"
                    variant="outlined"
                    onChange={(e) => {
                      this.setState({
                        new_service_type: e.target.value,
                        new_service_type_error: false,
                      });
                    }}
                    value={this.state.new_service_type}
                    error={this.state.new_service_type_error}
                  />
                </div>
                <div className="row bottom_buttons_section">
                  <button
                    type="button"
                    className="btn previous_button"
                    name="submit"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn  next_button"
                    onClick={this.addNewServiceTypeHandler}
                  >
                    Submit
                  </button>
                  &emsp;
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
