import React from "react";
import { Page, Document, StyleSheet, View } from "@react-pdf/renderer";
import InvoiceTitle from "./InvoiceTitle";
import InvoiceNo from "./InvoiceNo";
import InvoiceItemsTable from "./InvoiceItemsTable";
// import CoalIndexInvoice from "./CoalIndex";
import QualityResults from "./qualityResults";

const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 8,
    paddingTop: 30,
    paddingLeft: 20,
    paddingRight: 20,
    lineHeight: 1.5,
    flexDirection: "column",
  },
});

const Invoice = ({ invoice, demurrage_name, costing_name }) => {
  // const { coalIndexes: { show = false } = {} } = invoice || {};
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View
          style={{
            borderLeft: 0.65,
            borderRight: 0.65,
            borderBottom: 0.65,
            borderTop: 0.4,
            borderTopColor: "black",
            borderBottomColor: "black",
            borderLeftColor: "black",
            borderRightColor: "black",
          }}
        >
          <InvoiceNo invoice={invoice} />
          {invoice.bargeDetails && invoice.bargeDetails.length > 0 && (
            <InvoiceTitle
              invoice={invoice}
              demurrage_name={demurrage_name}
              costing_name={costing_name}
            />
          )}
          {/* {show && (
            <CoalIndexInvoice coalIndexes={invoice?.coalIndexes} />
          )} */}
          <InvoiceItemsTable
            invoice={invoice}
            demurrage_name={demurrage_name}
          />
          {invoice?.qualityResults && (
            <View style={{ marginTop: 5 }}>
              <QualityResults results={invoice?.qualityResults} />
            </View>
          )}
        </View>
      </Page>
    </Document>
  );
};

export default Invoice;
