import { SAMPLING_TYPES } from "../enums"
export const PURCHASE_SAMPLING_TABS = [
    {
        tabIndex: 1,
        label: 'Supplier',
        permissionCodes: '46,97',
        samplingType: SAMPLING_TYPES.SUPPLIER,
    },
    {
        tabIndex: 2,
        label: 'Company',
        permissionCodes: '46,97',
        samplingType: SAMPLING_TYPES.INTERNAL,
    },
    {
        tabIndex: 3,
        label: 'Actual',
        permissionCodes: '46,97',
        samplingType: SAMPLING_TYPES.ACTUAL,
    },
    {
        tabIndex: 4,
        label: 'Delayed Samples Report',
        permissionCodes: '46,97',
        samplingType: SAMPLING_TYPES.NONE,
    }
]