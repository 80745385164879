import React, { Component } from "react";
import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import api from "../../../api/api";

import { Alert } from "@material-ui/lab";
import {
  MenuItem,
  TextField,
  InputAdornment,
  Snackbar,
  Radio,
  FormControlLabel,
  IconButton,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import EventIcon from "@material-ui/icons/Event";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { localDateFormate, pad } from "../../common/common";
import FileUpload from "../../common/FileUpload";
import Loader from "../../common/Loader";

const NewRadio = withStyles({
  root: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);
export default class BargeVendorDemurrageAdd extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      activeStep: 0,
      selectedBargeId: null,
      invoice_no: "",
      invoice_date: null,
      ppn: "",
      dmr_to_bg_vend_laycan_commence: null,
      dmr_to_bg_vend_barge_demurrage: "",
      billing_currency: "0",
      dmr_to_bg_vend_demurrage_days: "",
      dmr_to_bg_vend_demurrage_value: "",
      dmr_to_bg_vend_currency_xchg_rate: "",
      dmr_to_bg_vend_pph23_account: "0",
      dmr_to_bg_vend_base_price: "",
      dmr_to_bg_vend_vat_percent: "10",
      dmr_to_bg_vend_vat_value: "",
      dmr_to_bg_vend_pph23_percent: "",
      dmr_to_bg_vend_pph23_value: "",
      dmr_to_bg_vend_total: "",
      dmr_to_bg_vend_ctc: "",
      dmr_to_bg_vend_ctc_in_local_currency: "",

      currencyData: [],
      totalBarges: [],
      vendor_drop_value: [],
      demurrage_barges: [],
      files: [
        {
          file_description: "",
          file_name: "",
          file_extension: "",
          file_content: "",
          feature_name: "Barge_Demurrage_To_Barge_Vendor",
        },
      ],
      features: [],
      isLoading: true,
      costing_files: [],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    let features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (!(features.includes("134") || features.includes("97"))) {
      window.location.href = "/dashboard";
    }

    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    //Get vendors
    let status = "Active";
    const vendors = await api.getVendors(loginUserID, idToken, status);
    if (vendors.status >= 200 && vendors.status < 300) {
      vendors.json().then((res) => {
        if (res.code === "200") {
          if (res.vendors) {
            const barge_vendors = [];
            const filter_barge_vendors = res.vendors.filter(
              (e) => e.vendor_type === "Barge"
            );
            for (var i of filter_barge_vendors) {
              barge_vendors.push({
                name: i.vendor_name,
                value: i.id,
                key: i.id,
              });
            }
            this.setState({
              //vendor_drop_value: barge_vendors,
            });
          } else {
            alert("Unexpected error occured. Please contact administrator.");
          }
        } else if (res.code === "601") {
          this.setState({
            snackBarErrorOpen: true,
            errorMsg: res.message,
          });
        } else if (res.code === "607") {
          window.location.href = "/logout";
        }
      });
    }

    let financial_type = "barges";
    const get_barge_list = await api.get_barges(
      loginUserID,
      idToken,
      financial_type
    );
    if (get_barge_list.status >= 200 && get_barge_list.status < 300) {
      get_barge_list.json().then((res) => {
        if (res.code === "200") {
          if (res.barges) {
            const total_barges = [];
            for (var i of res.barges) {
              total_barges.push({ name: i.id, value: i.id, key: i.id });
            }
            this.setState({
              vendor_drop_value: total_barges,
            });
          } else {
            alert("Unexpected error occured. Please contact administrator.");
          }
        } else if (res.code === "607") {
          window.location.href = "/logout";
        }
      });
    }

    let demurrage_type = this.props.demurrage_type;
    const get_dem_barges = await api.get_barges_for_barge_fin_demurrage(
      loginUserID,
      idToken,
      demurrage_type
    );

    if (get_dem_barges.status >= 200 && get_dem_barges.status < 300) {
      get_dem_barges.json().then((res) => {
        if (res.code === "200") {
          const barges = res.barges.filter(
            (e) => e.barge_vendor_demurrage_id === null
          );
          this.setState({
            totalBarges: barges,
            demurrage_barges: barges,
          });
        } else if (res.code === "601") {
          this.setState({
            snackBarErrorOpen: true,
            errorMsg: res.message,
            submitting: false,
          });
        } else if (res.code === "607") {
          window.location.href = "/logout";
        }
      });
    }

    const getCurrencies = await api.getCurrencies(loginUserID, idToken);
    if (getCurrencies.status >= 200 && getCurrencies.status < 300) {
      getCurrencies.json().then((res) => {
        if (res.code === "200") {
          if (res.Currency) {
            var currencies = [
              <MenuItem
                style={{ fontFamily: "Poppins", fontSize: "14px" }}
                value="0"
                key={-1}
                disabled
              >
                Please Select
              </MenuItem>,
            ];
            for (var i in res.Currency) {
              currencies.push(
                <MenuItem
                  style={{ fontFamily: "Poppins", fontSize: "14px" }}
                  value={res.Currency[i].currency}
                  key={[i]}
                >
                  {res.Currency[i].currency}
                </MenuItem>
              );
            }
            this.setState({
              currencyData: currencies,
              isLoading: false,
            });
          } else {
            alert("Unexpected error occured. Please contact administrator.");
          }
        } else if (res.code === "601") {
          this.setState({
            errorMsg: res.message,
            snackBarErrorOpen: true,
          });
        } else if (res.code === "607") {
          window.location.href = "/logout";
        }
      });
    }
  }

  searchBargesHandler() {
    // this.setState({ submitting: true })
    // const loginUserID = this.Cookie.getCookie('loginUserId');
    // const idToken = this.Cookie.getIdTokenCookie();
    // let demurrage_type = this.props.demurrage_type;
    // let vendor_id = this.state.vendor_id;
    // api.get_barges_for_barge_fin_demurrage(loginUserID, idToken, demurrage_type, vendor_id).then((response) => {

    //   if (response.status >= 200 && response.status < 300) {
    //     response.json().then((res) => {
    //       if (res.code === '200') {
    //         const barges = res.barges.filter(e => e.barge_vendor_demurrage_id === null);
    //         this.setState({
    //           totalBarges: barges,
    //           isLoading: false,
    //           submitting: false
    //         });
    //       } else if (res.code === '601') {
    //         this.setState({
    //           snackBarErrorOpen: true,
    //           errorMsg: res.message,
    //           submitting: false
    //         });
    //       } else if (res.code === '607') {
    //         window.location.href = '/logout';
    //       }
    //     })
    //   }
    // });
    if (this.state.barge_id) {
      const filter_barge = this.state.demurrage_barges.filter(
        (e) => e.id === this.state.barge_id
      );
      this.setState({
        totalBarges: filter_barge,
        snackBarErrorOpen: filter_barge.length === 0 ? true : false,
        errorMsg: "No records to display",
      });
    } else {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please select barge",
      });
    }
  }

  onBargesCheck = (bargeId, checked, barge) => {
    if (checked) {
      this.setState(
        {
          selectedBargeId: bargeId,
          //isLoading: true
        },
        () => {
          //this.callingAsync(bargeId);
          let days = "0";
          let hours = "0";
          let minutes = "0";
          if (barge.time_at_anchorage_days) {
            days = barge.time_at_anchorage_days;
          }
          if (barge.time_at_anchorage_hours) {
            hours = barge.time_at_anchorage_hours;
          }
          if (barge.time_at_anchorage_minutes) {
            minutes = barge.time_at_anchorage_minutes;
          }
          this.setState({
            // sales_type: res.business_no_barge.sales_type,
            purchase_type: barge.purchase_type,
            //dmr_to_buyer_barge_currency: res.business_no_barge.barging_base_price_currency === null ? '0' : res.business_no_barge.barging_base_price_currency,
            barge_vendor_name: barge.barge_vendor_name,
            loadingJetty:
              barge.loading_jetty === null ? "" : barge.loading_jetty,
            completionTime:
              barge.completion_time === "" ? null : barge.completion_time,
            NORAnchorageDate:
              barge.nor_anchorage === "" ? null : barge.nor_anchorage,
            completionDischargeDate:
              barge.completion_discharge === ""
                ? null
                : barge.completion_discharge,
            timeAtAnchorage:
              days + " days, " + hours + " hours, " + minutes + " minutes",
            timeAtAnchorageDays: days,
            timeAtAnchorageHours: hours,
            timeAtAnchorageMinutes: minutes,
            dmr_to_supplier_detention_in_days: days,
          });
        }
      );
    } else {
      this.setState({
        selectedBargeId: null,
      });
    }
  };

  dmr_to_bg_vendor_calculations = (value) => {
    var dmr_to_bg_vend_barge_demurrage = this.state
      .dmr_to_bg_vend_barge_demurrage
      ? this.state.dmr_to_bg_vend_barge_demurrage
      : 1;
    var dmr_to_bg_vend_demurrage_days = this.state.dmr_to_bg_vend_demurrage_days
      ? this.state.dmr_to_bg_vend_demurrage_days
      : 0;
    var dmr_to_bg_vend_currency_xchg_rate = this.state
      .dmr_to_bg_vend_currency_xchg_rate
      ? this.state.dmr_to_bg_vend_currency_xchg_rate
      : 1;
    if (
      ((config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL") &&
        this.state.billing_currency === "USD") ||
      ((config.company === "SDAM" ||
        config.company === "SRK" ||
        config.company === "BTR") &&
        this.state.billing_currency === "IDR")
    ) {
      dmr_to_bg_vend_currency_xchg_rate = 1;
    }
    var dmr_to_bg_vend_demurrage_value = (
      (Number(dmr_to_bg_vend_barge_demurrage) *
        Number(dmr_to_bg_vend_demurrage_days)) /
      dmr_to_bg_vend_currency_xchg_rate
    ).toFixed(2);
    if (
      (config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL") &&
      this.state.billing_currency !== "USD"
    ) {
      dmr_to_bg_vend_demurrage_value = (
        (Number(dmr_to_bg_vend_barge_demurrage) *
          Number(dmr_to_bg_vend_demurrage_days)) /
        dmr_to_bg_vend_currency_xchg_rate
      ).toFixed(2);
    } else if (
      (config.company === "SDAM" ||
        config.company === "SRK" ||
        config.company === "BTR") &&
      this.state.billing_currency !== "IDR"
    ) {
      dmr_to_bg_vend_demurrage_value = (
        Number(dmr_to_bg_vend_barge_demurrage) *
        Number(dmr_to_bg_vend_demurrage_days) *
        Number(dmr_to_bg_vend_currency_xchg_rate)
      ).toFixed(2);
    }
    if (value) {
      dmr_to_bg_vend_demurrage_value = this.state.dmr_to_bg_vend_demurrage_value
        ? dmr_to_bg_vend_demurrage_value
        : 1;
    }
    let dmr_to_bg_vend_base_price = Number(
      dmr_to_bg_vend_demurrage_value
    ).toFixed(2);
    let dmr_to_bg_vend_vat_percent = 10;
    let dmr_to_bg_vend_vat_value = (
      (Number(dmr_to_bg_vend_base_price) * 10) /
      100
    ).toFixed(2);
    let dmr_to_bg_vend_pph23_percent = 2;
    let dmr_to_bg_vend_pph23_value = Number(
      (dmr_to_bg_vend_base_price * 2) / 100
    ).toFixed(0);
    let dmr_to_bg_vend_total = (
      Number(dmr_to_bg_vend_base_price) +
      Number(dmr_to_bg_vend_vat_value) -
      Number(dmr_to_bg_vend_pph23_value)
    ).toFixed(2);
    if (config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL") {
      dmr_to_bg_vend_total = (
        Number(dmr_to_bg_vend_base_price) + Number(dmr_to_bg_vend_vat_value)
      ).toFixed(2);
    }
    let dmr_to_bg_vend_ctc = Number(
      Number(dmr_to_bg_vend_base_price) + Number(dmr_to_bg_vend_vat_value)
    ).toFixed(2);

    this.setState({
      dmr_to_bg_vend_demurrage_value: dmr_to_bg_vend_demurrage_value,
      dmr_to_bg_vend_base_price: dmr_to_bg_vend_base_price,
      dmr_to_bg_vend_vat_percent: dmr_to_bg_vend_vat_percent,
      dmr_to_bg_vend_vat_value: dmr_to_bg_vend_vat_value,
      dmr_to_bg_vend_pph23_percent: dmr_to_bg_vend_pph23_percent,
      dmr_to_bg_vend_pph23_value: dmr_to_bg_vend_pph23_value,
      dmr_to_bg_vend_total: dmr_to_bg_vend_total,
      dmr_to_bg_vend_ctc: dmr_to_bg_vend_ctc,
    });

    if (this.state.dmr_to_bg_vend_pph23_account === "SDAM") {
      let dmr_to_bg_vend_base_price = Number(
        (dmr_to_bg_vend_demurrage_value * 100) / 98
      ).toFixed(2);
      let dmr_to_bg_vend_pph23_percent = 2;
      let dmr_to_bg_vend_pph23_value = Number(
        ((dmr_to_bg_vend_base_price * 2) / 100).toFixed(2)
      );
      let dmr_to_bg_vend_total = Number(dmr_to_bg_vend_demurrage_value).toFixed(
        2
      );
      let dmr_to_bg_vend_ctc = Number(dmr_to_bg_vend_base_price).toFixed(2);

      this.setState({
        dmr_to_bg_vend_base_price: dmr_to_bg_vend_base_price,
        dmr_to_bg_vend_pph23_percent: dmr_to_bg_vend_pph23_percent,
        dmr_to_bg_vend_pph23_value: dmr_to_bg_vend_pph23_value,
        dmr_to_bg_vend_total: dmr_to_bg_vend_total,
        dmr_to_bg_vend_ctc: dmr_to_bg_vend_ctc,
        dmr_to_bg_vend_vat_percent: "",
        dmr_to_bg_vend_vat_value: "",
      });
    }
  };

  nextStepHandler = () => {
    if (this.state.activeStep === 0) {
      if (!this.state.selectedBargeId) {
        this.setState({
          errorMsg: "Please Select Barge",
          snackBarErrorOpen: true,
        });
        return;
      }
    }
    this.setState({
      activeStep: this.state.activeStep + 1,
    });
  };

  previousStepHandler = () => {
    this.setState({
      activeStep: this.state.activeStep - 1,
    });
  };

  toLocaleString(value) {
    return value ? Number(value).toLocaleString() : 0;
  }

  submitHandler = () => {
    var {
      invoice_no,
      invoice_date,
      ppn,
      dmr_to_bg_vend_laycan_commence,
      dmr_to_bg_vend_barge_demurrage,
      billing_currency,
      dmr_to_bg_vend_demurrage_days,
      dmr_to_bg_vend_demurrage_value,

      dmr_to_bg_vend_currency_xchg_rate,
      dmr_to_bg_vend_pph23_account,
      dmr_to_bg_vend_base_price,
      dmr_to_bg_vend_vat_percent,
      dmr_to_bg_vend_vat_value,
      dmr_to_bg_vend_pph23_percent,
      dmr_to_bg_vend_pph23_value,
      dmr_to_bg_vend_total,
      dmr_to_bg_vend_ctc,
      dmr_to_bg_vend_ctc_in_local_currency,
    } = this.state;
    /**
     *  Checking Mandatory files
     */
    var missing_fields = 0;
    let files_missing = 0;
    var row = this.state.files;
    for (var obj of row) {
      if (obj.file_type !== "" || obj.file_content !== "") {
        if (obj.file_content !== "") {
          if (obj.file_description === "") {
            missing_fields++;
            obj.file_description_error = true;
          } else {
            obj.file_description = obj.file_description.toUpperCase();
          }
        } else if (obj.file_description !== "") {
          if (obj.file_content === "") {
            files_missing++;
          }
        }
      }
    }
    this.setState({
      files: row,
    });

    if (missing_fields > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please fill mandatory fields.",
      });
      return;
    }
    if (files_missing > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please Select file.",
      });
      return;
    }

    const content_files = row.filter((e) => e.file_content !== "");
    const costing_file = this.state.costing_files?.filter(
      (e) => e.file_content !== "" && e.column === "invoice_file"
    );

    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();

    if (this.state.selectedBargeId) {
      const barge_id = this.state.selectedBargeId;

      api
        .add_barge_demurrage_to_barge_vendor(
          loginUserID,
          idToken,
          barge_id,
          invoice_no,
          invoice_date,
          ppn,
          dmr_to_bg_vend_laycan_commence,
          dmr_to_bg_vend_barge_demurrage,
          billing_currency,
          dmr_to_bg_vend_demurrage_days,
          dmr_to_bg_vend_demurrage_value,
          dmr_to_bg_vend_currency_xchg_rate,
          dmr_to_bg_vend_pph23_account,
          dmr_to_bg_vend_base_price,
          dmr_to_bg_vend_vat_percent,
          dmr_to_bg_vend_vat_value,
          dmr_to_bg_vend_pph23_percent,
          dmr_to_bg_vend_pph23_value,
          dmr_to_bg_vend_total,
          dmr_to_bg_vend_ctc,
          dmr_to_bg_vend_ctc_in_local_currency,
          content_files,
          costing_file
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                this.setState(
                  {
                    snackBarSuccessOpen: true,
                    successMsg: res.message,
                  },
                  () => {
                    this.props.onAddResponse(false);
                  }
                );
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  snackBarErrorOpen: true,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    } else {
      alert("Please select barge");
    }
  };

  render() {
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      const re = /^\d*(\.\d{0,10})?$/;
      const pr = /^\d*(\.\d{0,4})?$/;
      return (
        <div
          className="container bg-white p-0"
          style={{ minHeight: "100vh", width: 850 }}
        >
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <div className="row border-bottom">
            <div className="col-lg-12 pl-0">
              <h5 style={{ padding: "15px", marginBottom: 0 }}>
                Barge Purchase Financial Demurrage
              </h5>
            </div>
          </div>
          <div className="drawer-section">
            <div className="row mb-0 stepper">
              <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                <i
                  className={
                    this.state.activeStep > 0
                      ? "fa fa-check-circle"
                      : "fa fa-check-circle-o"
                  }
                  aria-hidden="true"
                  style={{
                    fontSize: 20,
                    color:
                      this.state.activeStep > 0
                        ? "#31c197"
                        : this.state.activeStep === 0
                        ? config.themeColor
                        : "#ccc",
                  }}
                />
                <span
                  className="stepper_text"
                  style={{
                    color:
                      this.state.activeStep > 0
                        ? "#31c197"
                        : this.state.activeStep === 0
                        ? config.themeColor
                        : "#ccc",
                  }}
                >
                  Barge Selection
                </span>
                <i
                  className="fa fa-chevron-right stepper_sub_icon stepper_arrow"
                  aria-hidden="true"
                  style={{
                    color:
                      this.state.activeStep > 0
                        ? "#31c197"
                        : this.state.activeStep === 0
                        ? config.themeColor
                        : "#ccc",
                  }}
                />
              </span>
              <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                <i
                  className={
                    this.state.activeStep > 1
                      ? "fa fa-check-circle"
                      : "fa fa-check-circle-o"
                  }
                  aria-hidden="true"
                  style={{
                    fontSize: 20,
                    color:
                      this.state.activeStep > 1
                        ? "#31c197"
                        : this.state.activeStep === 1
                        ? config.themeColor
                        : "#ccc",
                  }}
                />
                <span
                  className="stepper_text"
                  style={{
                    color:
                      this.state.activeStep > 1
                        ? "#31c197"
                        : this.state.activeStep === 1
                        ? config.themeColor
                        : "#ccc",
                  }}
                >
                  Costing
                </span>
                <i
                  className="fa fa-chevron-right stepper_sub_icon stepper_arrow"
                  aria-hidden="true"
                  style={{
                    color:
                      this.state.activeStep > 1
                        ? "#31c197"
                        : this.state.activeStep === 1
                        ? config.themeColor
                        : "#ccc",
                  }}
                />
              </span>
            </div>
            <div className="card p-3">
              <div
                className="card-body p-0"
                style={{ backgroundColor: "rgba(25,118,210,.01)" }}
                id="accordion"
              >
                {this.state.activeStep === 0 && (
                  <div className="row">
                    <div className="row shadow-sm mb-1 p-2 w-100 rounded border bg-white">
                      <div className="col-lg-4 p-0 mb-2">
                        {/* <Autocomplete
                        options={this.state.vendor_drop_value}
                        getOptionLabel={option => option.name}
                        renderOption={(option, { selected }) => (
                          <React.Fragment>
                            <span className='form_label mb-0'>{option.name}</span>
                          </React.Fragment>
                        )}
                        onChange={(event, value) => {
                          if (value) {
                            this.setState({ vendor_id: value.value, vendor_value: value });
                          }
                          else {
                            this.setState({ business_no_id: [], vendor_value: { name: "", value: '' } });
                          }
                        }}
                        disableCloseOnSelect
                        name='barge_vendor'
                        size="small"
                        value={this.state.vendor_value}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Search Barge Vendor"
                            variant="outlined"
                            style={{ top: 8 }}
                            fullWidth
                          />
                        )}
                        style={{ display: 'contents' }}
                      /> */}
                        <Autocomplete
                          options={this.state.vendor_drop_value}
                          getOptionLabel={(option) => pad(option.name)}
                          renderOption={(option, { selected }) => (
                            <React.Fragment>
                              <span className="form_label mb-0">
                                {pad(option.name)}
                              </span>
                            </React.Fragment>
                          )}
                          onChange={(event, value) => {
                            if (value) {
                              const filter_barge =
                                this.state.demurrage_barges.filter(
                                  (e) => e.id === value.value
                                );
                              this.setState({
                                totalBarges: filter_barge,
                                snackBarErrorOpen:
                                  filter_barge.length === 0 ? true : false,
                                errorMsg: "No records to display",
                                barge_id: value.value,
                                barge_value: value,
                              });
                              // this.setState({ barge_id: value.value, barge_value: value });
                            } else {
                              this.setState({
                                barge_value: { name: "", value: "" },
                                totalBarges: this.state.demurrage_barges,
                              });
                            }
                          }}
                          //disableCloseOnSelect
                          name="barge_id"
                          size="small"
                          value={this.state.barge_value}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Search Barge"
                              variant="outlined"
                              style={{ top: 8 }}
                              fullWidth
                            />
                          )}
                          style={{ display: "contents" }}
                        />
                      </div>
                      {/* <div className='col-lg-2 pr-0'>
                      <button className='header_button header_button_text add_button_adjustment' style={{ width: 'auto', marginTop: "25px" }} onClick={() => this.searchBargesHandler()}
                        disabled={this.state.submitting}><span className={this.state.submitting ? "spinner-grow spinner-grow-sm mr-2" : ""} />
                      Search
                    </button>
                    </div> */}
                    </div>
                    {this.state.totalBarges.length > 0 && (
                      <div className="row">
                        <div className="table-responsive">
                          <table className="table table-borderless border-0">
                            <thead className="border-0">
                              <tr>
                                <th
                                  style={{ fontSize: 16 }}
                                  className="table_header_barge_label text-uppercase border-0"
                                  nowrap="true"
                                >
                                  Action
                                </th>
                                <th
                                  style={{ fontSize: 16 }}
                                  className="table_header_barge_label text-uppercase border-0"
                                  nowrap="true"
                                >
                                  Barge ID
                                </th>
                                {/* <th nowrap='true'>Allocation ID</th> */}
                                <th
                                  style={{ fontSize: 16 }}
                                  className="table_header_barge_label text-uppercase border-0"
                                  nowrap="true"
                                >
                                  Barge Vendor Name
                                </th>
                                <th
                                  style={{ fontSize: 16 }}
                                  className="table_header_barge_label text-uppercase border-0"
                                  nowrap="true"
                                >
                                  Barge Nomination
                                </th>
                                <th
                                  style={{ fontSize: 16 }}
                                  className="table_header_barge_label text-uppercase border-0 text-right"
                                  nowrap="true"
                                >
                                  Barge Quantity
                                </th>
                                <th
                                  style={{ fontSize: 16 }}
                                  className="table_header_barge_label text-uppercase border-0 text-right"
                                  nowrap="true"
                                >
                                  Planned Quantity
                                </th>
                                {/*<th style={{ fontSize: 16 }} className="table_header_barge_label text-uppercase border-0 text-right" nowrap='true'>Redraft Quantity</th>*/}
                                <th
                                  style={{ fontSize: 16 }}
                                  className="table_header_barge_label text-uppercase border-0"
                                  nowrap="true"
                                >
                                  Supplier Name
                                </th>
                              </tr>
                            </thead>
                            {this.state.totalBarges.map((p, index) => (
                              <tbody key={index}>
                                <tr>
                                  <td>
                                    <FormControlLabel
                                      value={p.id}
                                      color="primary"
                                      control={
                                        <NewRadio
                                          checked={
                                            this.state.selectedBargeId === p.id
                                          }
                                          //disabled={p.demurrage_id !== null}
                                        />
                                      }
                                      onChange={(e) => {
                                        this.onBargesCheck(
                                          e.target.value,
                                          e.target.checked,
                                          p
                                        );
                                      }}
                                    />
                                    {/* <Checkbox
                                    value={p.business_no_barge_id}
                                    color='primary'
                                    checked={this.state.selectedBargeId === p.business_no_barge_id}
                                    disabled={p.demurrage_fin_id !== null}
                                    onChange={(e)=>{this.onBargesCheck(e.target.value, e.target.checked)}}
                                  /> */}
                                  </td>
                                  <td>{pad(p.id)}</td>
                                  {/* <td>{p.display_allocation_id}</td> */}
                                  <td nowrap="true">{p.barge_vendor_name}</td>
                                  <td nowrap="true">{p.barge_nomination}</td>
                                  <td nowrap="true" className="text-right">
                                    {this.toLocaleString(
                                      p.barge_quantity_in_mt
                                    )}
                                  </td>
                                  <td nowrap="true" className="text-right">
                                    {this.toLocaleString(
                                      p.barge_planned_quantity_in_mt
                                    )}
                                  </td>
                                  {/*<td nowrap='true' className='text-right'>{this.toLocaleString(p.redraft_quantity)}</td>*/}
                                  <td nowrap="true">{p.supplier_name}</td>
                                </tr>
                              </tbody>
                            ))}
                          </table>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {this.state.activeStep === 1 && (
                  <div>
                    <div className="row mb-3">
                      <div className="col-lg-4 pr-0 mb-1">
                        <label className="form_label mb-1">Vendor</label>
                        <div className="">{this.state.barge_vendor_name}</div>
                      </div>
                      <div className="col-lg-4 p-0 mb-1">
                        <label className="form_label mb-1">Loading Jetty</label>
                        <div className="">{this.state.loadingJetty}</div>
                      </div>
                      <div className="col-lg-4 p-0 mb-1">
                        <label className="form_label mb-1">
                          Time at Anchorage
                        </label>
                        <div className="">{this.state.timeAtAnchorage}</div>
                      </div>
                      {this.state.sales_type !== "FOB Barge" && (
                        <div className="col-lg-4 p-0 mb-1">
                          <label className="form_label mb-1">
                            Completion time
                          </label>
                          <div className="">
                            {this.state.completionTime
                              ? localDateFormate(this.state.completionTime)
                              : ""}
                          </div>
                        </div>
                      )}
                      {this.state.sales_type !== "FOB Barge" && (
                        <div className="col-lg-4 p-0 mb-1">
                          <label className="form_label mb-1">
                            NOR Anchorage
                          </label>
                          <div className="">
                            {this.state.NORAnchorageDate
                              ? localDateFormate(this.state.NORAnchorageDate)
                              : ""}
                          </div>
                        </div>
                      )}
                      {this.state.sales_type !== "FOB Barge" && (
                        <div className="col-lg-4 p-0 mb-1">
                          <label className="form_label mb-1">
                            Completion Discharge
                          </label>
                          <div className="">
                            {this.state.completionDischargeDate
                              ? localDateFormate(
                                  this.state.completionDischargeDate
                                )
                              : ""}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="row">
                      <div className="col-lg-5 pl-0">
                        <div className="row">
                          {this.state.sales_type !== "FOB Barge" && (
                            <div className="col-lg-12 p-0">
                              <label className="form_label mb-0">
                                Laycan Commencement
                              </label>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DateTimePicker
                                  autoOk
                                  hideTabs
                                  inputVariant="outlined"
                                  variant="inline"
                                  margin="dense"
                                  fullWidth
                                  ampm={false}
                                  //label="Laycan Commencement"
                                  format="dd/MM/yyyy HH:mm"
                                  value={
                                    this.state.dmr_to_bg_vend_laycan_commence
                                  }
                                  onChange={(date) => {
                                    this.setState({
                                      dmr_to_bg_vend_laycan_commence: date,
                                    });
                                  }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <IconButton>
                                          <EventIcon />
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                    className: "pl-0",
                                  }}
                                />
                              </MuiPickersUtilsProvider>
                            </div>
                          )}
                          <div className="col-lg-12 p-0">
                            <label className="form_label mb-0">
                              Barge Demurrage
                            </label>
                            <TextField
                              name="dmr_to_bg_vend_barge_demurrage"
                              variant="outlined"
                              margin="dense"
                              placeholder="Barge Demurrage"
                              value={this.state.dmr_to_bg_vend_barge_demurrage}
                              type="number"
                              onChange={(e) => {
                                if (
                                  e.target.value === "" ||
                                  pr.test(e.target.value)
                                ) {
                                  let value = e.target.value;
                                  let dmr_to_bg_vend_demurrage_value = (
                                    Number(value) *
                                    Number(
                                      this.state.dmr_to_bg_vend_demurrage_days
                                    )
                                  ).toFixed(2);
                                  this.setState(
                                    {
                                      dmr_to_bg_vend_barge_demurrage: value,
                                      dmr_to_bg_vend_demurrage_value:
                                        dmr_to_bg_vend_demurrage_value,
                                    },
                                    () => this.dmr_to_bg_vendor_calculations()
                                  );
                                }
                              }}
                              className="rounded-left"
                              style={{
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0,
                              }}
                              fullWidth
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    // variant="outlined"
                                    position="end"
                                    style={{
                                      marginRight: "-14px",
                                      marginTop: "-4px",
                                      width: "50%",
                                    }}
                                  >
                                    <TextField
                                      name="billing_currency"
                                      variant="outlined"
                                      margin="dense"
                                      value={this.state.billing_currency}
                                      fullWidth
                                      onChange={(e) => {
                                        this.setState(
                                          {
                                            billing_currency: e.target.value,
                                          },
                                          () => {
                                            this.dmr_to_bg_vendor_calculations();
                                          }
                                        );
                                      }}
                                      select
                                    >
                                      {this.state.currencyData}
                                    </TextField>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </div>
                          <div className="col-lg-12 p-0">
                            <label className="form_label mb-0">
                              Demurrage in days
                            </label>
                            <TextField
                              name="dmr_to_bg_vend_demurrage_days"
                              variant="outlined"
                              margin="dense"
                              placeholder="Demurrage in days"
                              fullWidth
                              type="number"
                              value={this.state.dmr_to_bg_vend_demurrage_days}
                              onChange={(e) => {
                                if (
                                  re.test(e.target.value) ||
                                  e.target.value === ""
                                ) {
                                  let value = e.target.value;
                                  let dmr_to_bg_vend_demurrage_value = (
                                    Number(e.target.value) *
                                    Number(
                                      this.state.dmr_to_bg_vend_barge_demurrage
                                    )
                                  ).toFixed(3);
                                  this.setState(
                                    {
                                      dmr_to_bg_vend_demurrage_days: value,
                                      dmr_to_bg_vend_demurrage_value:
                                        dmr_to_bg_vend_demurrage_value,
                                    },
                                    () => this.dmr_to_bg_vendor_calculations()
                                  );
                                }
                              }}
                            />
                          </div>

                          {(config.company === "SRPL" || config.company === "AVS"  ||
                            config.company === "PRPL") &&
                            this.state.billing_currency !== "USD" &&
                            this.state.billing_currency !== "0" && (
                              <div className="col-lg-12 p-0">
                                <label className="form_label mb-0">
                                  Exchange rate USD to{" "}
                                  {this.state.billing_currency}
                                </label>
                                <TextField
                                  name="dmr_to_bg_vend_currency_xchg_rate"
                                  margin="dense"
                                  variant="outlined"
                                  fullWidth
                                  value={
                                    this.state.dmr_to_bg_vend_currency_xchg_rate
                                  }
                                  type="number"
                                  inputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                    },
                                  }}
                                  placeholder="Exchange rate"
                                  onChange={(e) => {
                                    if (
                                      e.target.value === "" ||
                                      re.test(e.target.value)
                                    ) {
                                      this.setState(
                                        {
                                          dmr_to_bg_vend_currency_xchg_rate:
                                            e.target.value,
                                        },
                                        () =>
                                          this.dmr_to_bg_vendor_calculations()
                                      );
                                    }
                                  }}
                                />
                              </div>
                            )}

                          {!(
                            config.company === "SRPL" || config.company === "AVS"  ||
                            config.company === "PRPL"
                          ) &&
                            this.state.billing_currency !== "IDR" &&
                            this.state.billing_currency &&
                            this.state.billing_currency !== "0" && (
                              <div className="col-lg-12 p-0">
                                <label className="form_label mb-0">
                                  Exchange rate {this.state.billing_currency} to
                                  IDR
                                </label>
                                <TextField
                                  name="dmr_to_bg_vend_currency_xchg_rate"
                                  margin="dense"
                                  variant="outlined"
                                  fullWidth
                                  value={
                                    this.state.dmr_to_bg_vend_currency_xchg_rate
                                  }
                                  type="number"
                                  inputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                    },
                                  }}
                                  placeholder="Exchange rate"
                                  onChange={(e) => {
                                    if (
                                      e.target.value === "" ||
                                      re.test(e.target.value)
                                    ) {
                                      this.setState(
                                        {
                                          dmr_to_bg_vend_currency_xchg_rate:
                                            e.target.value,
                                        },
                                        () =>
                                          this.dmr_to_bg_vendor_calculations()
                                      );
                                    }
                                  }}
                                />
                              </div>
                            )}
                          <div className="col-lg-12 p-0">
                            <label className="form_label mb-0">
                              Demurrage incurred
                            </label>
                            <TextField
                              name="dmr_to_bg_vend_demurrage_value"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              inputProps={{
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: "12px",
                                },
                              }}
                              placeholder="Demurrage incurred"
                              value={this.state.dmr_to_bg_vend_demurrage_value}
                              onChange={(e) => {
                                if (
                                  e.target.value === "" ||
                                  pr.test(e.target.value)
                                ) {
                                  this.setState(
                                    {
                                      dmr_to_bg_vend_demurrage_value:
                                        e.target.value,
                                    },
                                    () =>
                                      this.dmr_to_bg_vendor_calculations(
                                        "dmr_to_bg_vend_demurrage_value"
                                      )
                                  );
                                }
                              }}
                            />
                          </div>
                          {!(
                            config.company === "SRPL" || config.company === "AVS"  ||
                            config.company === "PRPL"
                          ) && (
                            <div className="col-lg-12 p-0">
                              <label className="form_label mb-0">
                                PPH23 Account
                              </label>
                              <TextField
                                name="dmr_to_bg_vend_pph23_account"
                                margin="dense"
                                variant="outlined"
                                value={this.state.dmr_to_bg_vend_pph23_account}
                                select
                                inputProps={{
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                  },
                                }}
                                fullWidth
                                onChange={(e) => {
                                  this.setState(
                                    {
                                      dmr_to_bg_vend_pph23_account:
                                        e.target.value,
                                    },
                                    () => this.dmr_to_bg_vendor_calculations()
                                  );
                                }}
                              >
                                <MenuItem value="0">Please Select</MenuItem>
                                <MenuItem value="Barge Vendor">
                                  Barge Vendor
                                </MenuItem>
                                <MenuItem value="SDAM">SDAM</MenuItem>
                              </TextField>
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        className="col-lg-7 p-2 rounded"
                        style={{ backgroundColor: "#f7f3f0" }}
                      >
                        <div className="row">
                          <div className="row col-lg-12 p-0">
                            <h6>Actual Demurrage</h6>
                          </div>
                          {(config.company === "SRPL" || config.company === "AVS"  ||
                            config.company === "PRPL" ||
                            ((config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") &&
                              this.state.dmr_to_bg_vend_pph23_account ===
                                "Barge Vendor")) && (
                            <div className="row">
                              <div className="col-lg-6 mb-2">
                                <label
                                  className="form_label mb-0"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  Base price
                                </label>
                                <TextField
                                  name="dmr_to_bg_vend_base_price"
                                  margin="dense"
                                  type="number"
                                  inputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                    },
                                  }}
                                  variant="outlined"
                                  value={this.state.dmr_to_bg_vend_base_price}
                                  onChange={(e) => {
                                    if (
                                      e.target.value === "" ||
                                      pr.test(e.target.value)
                                    ) {
                                      var dmr_to_bg_vend_vat_value = (
                                        (Number(e.target.value) * 10) /
                                        100
                                      ).toFixed(2);
                                      let dmr_to_bg_vend_pph23_value = Number(
                                        (e.target.value * 2) / 100
                                      ).toFixed(2);
                                      let dmr_to_bg_vend_total = (
                                        Number(e.target.value) +
                                        Number(dmr_to_bg_vend_vat_value) -
                                        Number(dmr_to_bg_vend_pph23_value)
                                      ).toFixed(2);
                                      let dmr_to_bg_vend_ctc = (
                                        Number(e.target.value) +
                                        Number(dmr_to_bg_vend_vat_value)
                                      ).toFixed(2);
                                      this.setState({
                                        [e.target.name]: e.target.value,
                                        dmr_to_bg_vend_vat_value:
                                          dmr_to_bg_vend_vat_value,
                                        dmr_to_bg_vend_pph23_value:
                                          dmr_to_bg_vend_pph23_value,
                                        dmr_to_bg_vend_total:
                                          dmr_to_bg_vend_total,
                                        dmr_to_bg_vend_ctc: dmr_to_bg_vend_ctc,
                                      });
                                    }
                                  }}
                                  fullWidth
                                />
                              </div>
                              <div className="col-lg-6 mb-2">
                                <label
                                  className="form_label mb-2"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  VAT %
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {this.state.dmr_to_bg_vend_vat_percent
                                    ? this.state.dmr_to_bg_vend_vat_percent
                                    : "-"}
                                </div>
                              </div>

                              <div className="col-lg-6 mb-2">
                                <label
                                  className="form_label mb-0"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  VAT
                                </label>
                                <TextField
                                  name="dmr_to_bg_vend_vat_value"
                                  margin="dense"
                                  type="number"
                                  inputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                    },
                                  }}
                                  variant="outlined"
                                  value={this.state.dmr_to_bg_vend_vat_value}
                                  onChange={(e) => {
                                    if (
                                      e.target.value === "" ||
                                      pr.test(e.target.value)
                                    ) {
                                      let dmr_to_bg_vend_total = (
                                        Number(
                                          this.state.dmr_to_bg_vend_base_price
                                        ) +
                                        Number(e.target.value) -
                                        Number(
                                          this.state.dmr_to_bg_vend_pph23_value
                                        )
                                      ).toFixed(2);
                                      let dmr_to_bg_vend_ctc = (
                                        Number(this.state.int_dmr_base_price) +
                                        Number(e.target.value)
                                      ).toFixed(2);
                                      this.setState({
                                        [e.target.name]: e.target.value,
                                        dmr_to_bg_vend_total:
                                          dmr_to_bg_vend_total,
                                        dmr_to_bg_vend_ctc: dmr_to_bg_vend_ctc,
                                      });
                                    }
                                  }}
                                  fullWidth
                                />
                              </div>
                              {!(
                                config.company === "SRPL" || config.company === "AVS"  ||
                                config.company === "PRPL"
                              ) &&
                                this.state.dmr_to_bg_vend_pph23_account ===
                                  "Barge Vendor" && (
                                  <div className="col-lg-6 mb-2">
                                    <label
                                      className="form_label mb-0"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Includes PPH23 @{" "}
                                      {this.state.dmr_to_bg_vend_pph23_percent
                                        ? this.toLocaleString(
                                            this.state
                                              .dmr_to_bg_vend_pph23_percent
                                          )
                                        : 0}
                                      %
                                    </label>
                                    {/* <div className='contract_display_header_value' style={{ color: '#42352d' }}>{this.state.dmr_to_bg_vend_pph23_value ? this.toLocaleString(this.state.dmr_to_bg_vend_pph23_value) : null}</div> */}
                                    <TextField
                                      name="dmr_to_bg_vend_pph23_value"
                                      margin="dense"
                                      type="number"
                                      variant="outlined"
                                      inputProps={{
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                        },
                                      }}
                                      value={
                                        this.state.dmr_to_bg_vend_pph23_value
                                      }
                                      onChange={(e) => {
                                        if (
                                          e.target.value === "" ||
                                          pr.test(e.target.value)
                                        ) {
                                          let dmr_to_bg_vend_total = (
                                            Number(
                                              this.state
                                                .dmr_to_bg_vend_base_price
                                            ) +
                                            Number(
                                              this.state
                                                .dmr_to_bg_vend_vat_value
                                            ) -
                                            Number(e.target.value)
                                          ).toFixed(2);
                                          let dmr_to_bg_vend_ctc = (
                                            Number(
                                              this.state
                                                .dmr_to_bg_vend_base_price
                                            ) +
                                            Number(
                                              this.state
                                                .dmr_to_bg_vend_vat_value
                                            )
                                          ).toFixed(2);
                                          this.setState({
                                            [e.target.name]: e.target.value,
                                            dmr_to_bg_vend_total:
                                              dmr_to_bg_vend_total,
                                            dmr_to_bg_vend_ctc:
                                              dmr_to_bg_vend_ctc,
                                          });
                                        }
                                      }}
                                      fullWidth
                                    />
                                  </div>
                                )}
                              <div className="col-lg-6 ">
                                <label
                                  className="form_label mb-0"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  Payable
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {this.state.dmr_to_bg_vend_total
                                    ? this.toLocaleString(
                                        this.state.dmr_to_bg_vend_total
                                      )
                                    : 0}{" "}
                                  {config.company === "SRPL" || config.company === "AVS"  ||
                                  config.company === "PRPL"
                                    ? "USD"
                                    : "IDR"}
                                </div>
                              </div>
                              <div className="col-lg-6 ">
                                <label
                                  className="form_label mb-0"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  Cost to Company
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {this.state.dmr_to_bg_vend_ctc
                                    ? this.toLocaleString(
                                        this.state.dmr_to_bg_vend_ctc
                                      )
                                    : 0}{" "}
                                  {config.company === "SRPL" || config.company === "AVS"  ||
                                  config.company === "PRPL"
                                    ? "USD"
                                    : "IDR"}
                                </div>
                              </div>
                            </div>
                          )}

                          {this.state.dmr_to_bg_vend_pph23_account ===
                            "SDAM" && (
                            <div>
                              <div className="row">
                                <div className="col-lg-6 mb-2">
                                  <label
                                    className="form_label mb-0"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    Base price
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {this.state.dmr_to_bg_vend_base_price
                                      ? this.toLocaleString(
                                          this.state.dmr_to_bg_vend_base_price
                                        )
                                      : "-"}
                                  </div>
                                </div>

                                <div className="col-lg-6 mb-3">
                                  <label
                                    className="form_label mb-0"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    Includes PPH23 @{" "}
                                    {this.state.dmr_to_bg_vend_pph23_percent
                                      ? this.toLocaleString(
                                          this.state
                                            .dmr_to_bg_vend_pph23_percent
                                        )
                                      : 0}
                                    %
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {this.state.dmr_to_bg_vend_pph23_value
                                      ? this.toLocaleString(
                                          this.state.dmr_to_bg_vend_pph23_value
                                        )
                                      : null}
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-lg-6 ">
                                  <label
                                    className="form_label mb-0"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    Payable to Vendor
                                  </label>
                                  {/* <div className='contract_display_header_value' style={{ color: '#42352d' }}>{this.state.barge_vendor_total_value ? this.toLocaleString(this.state.barge_vendor_total_value) : 0} {(config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL") ? "USD" : "IDR"}</div> */}
                                  <TextField
                                    name="dmr_to_bg_vend_total"
                                    margin="dense"
                                    type="number"
                                    inputProps={{
                                      style: {
                                        fontFamily: "Poppins",
                                        fontSize: "14px",
                                      },
                                    }}
                                    variant="outlined"
                                    value={this.state.dmr_to_bg_vend_total}
                                    onChange={(e) => {
                                      if (
                                        e.target.value === "" ||
                                        pr.test(e.target.value)
                                      ) {
                                        let dmr_to_bg_vend_base_price = Number(
                                          (e.target.value * 100) / 98
                                        ).toFixed(2);
                                        let dmr_to_bg_vend_pph23_value = Number(
                                          (dmr_to_bg_vend_base_price * 2) / 100
                                        ).toFixed(2);
                                        let dmr_to_bg_vend_ctc =
                                          dmr_to_bg_vend_base_price;
                                        this.setState({
                                          [e.target.name]: e.target.value,
                                          dmr_to_bg_vend_base_price:
                                            dmr_to_bg_vend_base_price,
                                          dmr_to_bg_vend_pph23_value:
                                            dmr_to_bg_vend_pph23_value,
                                          SDAM_dmr_to_bg_vend_ctc:
                                            dmr_to_bg_vend_ctc,
                                        });
                                      }
                                    }}
                                    fullWidth
                                  />
                                </div>
                                <div className="col-lg-6 ">
                                  <label
                                    className="form_label mb-0"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    Cost to Company
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {this.state.dmr_to_bg_vend_ctc
                                      ? this.toLocaleString(
                                          this.state.dmr_to_bg_vend_ctc
                                        )
                                      : 0}{" "}
                                    {config.company === "SRPL" || config.company === "AVS"  ||
                                    config.company === "PRPL"
                                      ? "USD"
                                      : "IDR"}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {(this.state.features.indexOf("530") >= 0 ||
                      this.state.features.indexOf("97") >= 0) && (
                      <>
                        <div className="card p-0">
                          <FileUpload
                            files={this.state.files}
                            type="Costings"
                            feature_name="Barge_Demurrage_To_Barge_Vendor"
                            callbackFn={(data, mandatoryFiles) => {
                              this.setState({
                                files: data,
                                costing_files: mandatoryFiles,
                              });
                            }}
                          />
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <footer className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button btn-light"
              name="submit"
              onClick={
                this.state.activeStep === 0
                  ? () => this.props.returnCallbackDrawerClose(false)
                  : this.previousStepHandler
              }
            >
              {this.state.activeStep === 0 ? "CANCEL" : "PREVIOUS"}
            </button>
            <button
              type="button"
              className={
                this.state.activeStep === 1
                  ? "drawer_text drawer_btn_success"
                  : "btn next_button"
              }
              name="Back"
              onClick={
                this.state.activeStep === 1
                  ? this.submitHandler
                  : this.nextStepHandler
              }
            >
              {this.state.activeStep === 1 ? "SUBMIT" : "NEXT STEP"}
            </button>
          </footer>
        </div>
      );
    }
  }
}
