import React, { Component } from 'react';
import Header from '../common/Header';
import SideBar from '../common/SideBar';
import CookieHandler from '../common/CookieHandler';
import config from '../../config/config';
import api from '../../api/api';

import { Alert } from '@material-ui/lab';
import {
  Snackbar, TextField,
  IconButton, InputAdornment
} from '@material-ui/core';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import EventIcon from '@material-ui/icons/Event';
import Loader from '../common/Loader';
export default class VatImplementation extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();

    this.state = {
      tax_percentages_data: [
        {
          start_date: null,
          end_date: null,
          tax_percentage: '',
          tax_type: 'vat',
        },

      ],
      isLoading: true,
    }
  }

  componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace('/logout');
    }
    var features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (!(features.includes('97') )) {
      window.location.href = '/dashboard';
    }
    const loginUserID = this.Cookie.getCookie('loginUserId');
    const idToken = this.Cookie.getIdTokenCookie();
    api.get_tax_percentages_data(loginUserID, idToken).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === '200') {
            this.setState({
              isLoading: false,
              tax_percentages_data: res.tax_percentages_data
            })
          } else if (res.code === '601') {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
              isLoading: false
            })

          } else if (res.code === '607') {
            window.location.href = '/logout';
          }
        })
      }
    })
    document.title = config.documentTitle + "VAT Implementation";


  }

  submitHandler = () => {
    this.setState({ submitting: true });
    const loginUserID = this.Cookie.getCookie('loginUserId');
    const idToken = this.Cookie.getIdTokenCookie();
    const tax_percentages_data = this.state.tax_percentages_data;
    api.add_tax_percentages(loginUserID, idToken, tax_percentages_data).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === '200') {
            this.setState({
              submitting: false,
              snackBarSuccessOpen: true,
              successMsg: res.message
            }, () => this.componentDidMount())
          } else if (res.code === '601') {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message
            })
          } else if (res.code === '607') {
            window.location.href = '/logout';
          }
        })
      }
    })
  }

  addZero(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  }

  dateFormat(date) {
    let dateFormat = new Date(date);
    let day = this.addZero(dateFormat.getDate());
    let month = this.addZero(dateFormat.getMonth() + 1);
    let year = dateFormat.getFullYear();
    let newDate = year + '-' + month + '-' + day;
    let finalDate = newDate;
    return finalDate;
  }

  render() {
    return (
      <div id='wraper'>
        <SideBar />
        <div className='content'>
          <Header />
          {this.state.isLoading &&
           <Loader />
          }
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={this.state.snackBarErrorOpen} onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: '450px' }}
          >
            <Alert elevation={6} variant="filled" onClose={() => this.setState({ snackBarErrorOpen: false })} severity="error" style={{ width: '100%' }}>
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            style={{ width: '450px' }}
            open={this.state.snackBarSuccessOpen} autoHideDuration={100000} onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert elevation={6} variant="filled" onClose={() => this.setState({ snackBarSuccessOpen: false })} severity="success" style={{ width: '100%' }}>
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <div className='content-header'>
            <div className='col-sm pl-0'>
              <h4 style={{ padding: '10px 20px', margin: '0px' }}> <a
                href={'/reports'}
                className="fa fa-arrow-left left_arrow_adjst"
              >
              </a>
                VAT Implementation
                </h4>
            </div>
          </div>
          {!this.state.isLoading &&
            <div className='container p-2'>
              <div className='card' style={{ overflowY: 'auto' }}>
                <div className='row'>
                  <div className='col-lg-12 p-3'>

                    <div className="section_block">
                      {this.state.tax_percentages_data.map((e, idx) => (

                        <div className='row' key={idx}>
                          <div className={this.state.tax_percentages_data.length === 1 ? 'col-lg-12 p-3 border_dashed rounded-lg mb-2' : 'col-lg-11 p-3 border_dashed rounded-lg mb-2'}>

                            <div className='row'>
                              <div className='col-lg-4'>
                                <label className='form_label mb-0'>Start Date</label>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <DatePicker
                                    autoOk={true}
                                    inputVariant="outlined"
                                    variant="inline"
                                    margin="dense"
                                    fullWidth
                                    format="dd/MM/yyyy"
                                    value={e.start_date}
                                    error={this.state.date_of_paymentError}
                                    onChange={(date) => {
                                      var row = this.state.tax_percentages_data;
                                      row[idx].start_date = this.dateFormat(date);
                                      this.setState({
                                        tax_percentages_data: row
                                      });
                                    }}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <IconButton>
                                            <EventIcon />
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                      className: 'pl-0'
                                    }}
                                  />
                                </MuiPickersUtilsProvider>
                              </div>
                              <div className='col-lg-4'>
                                <label className='form_label mb-0'>End Date</label>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <DatePicker
                                    autoOk={true}
                                    inputVariant="outlined"
                                    variant="inline"
                                    margin="dense"
                                    fullWidth
                                    format="dd/MM/yyyy"
                                    value={e.end_date}
                                    error={this.state.date_of_paymentError}
                                    onChange={(date) => {
                                      var row = this.state.tax_percentages_data;
                                      row[idx].end_date = this.dateFormat(date);
                                      this.setState({
                                        tax_percentages_data: row
                                      });
                                    }}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <IconButton>
                                            <EventIcon />
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                      className: 'pl-0'
                                    }}
                                  />
                                </MuiPickersUtilsProvider>
                              </div>
                              <div className='col-lg-4'>
                                <label className='form_label mb-0'>VAT</label>
                                <TextField
                                  name='tax_percentage'
                                  margin='dense'
                                  variant='outlined'
                                  type='number'
                                  fullWidth
                                  inputProps={{
                                    style: {
                                      fontFamily: 'Poppins',
                                      fontSize: '14px',
                                      textTransform: 'uppercase'
                                    }
                                  }}
                                  placeholder='VAT'
                                  value={e.tax_percentage}
                                  onChange={(e) => {
                                    var row = this.state.tax_percentages_data;
                                    row[idx].tax_percentage = e.target.value;
                                    this.setState({
                                      tax_percentages_data: row,
                                    });
                                  }}
                                />
                              </div>
                            </div>

                          </div>
                          {this.state.tax_percentages_data.length > 1 &&
                            <center className="col-lg-1 d-flex m-auto justify-content-center">
                              <i className="fa fa-trash-o" onClick={() => {
                                const row = this.state.tax_percentages_data;
                                row.splice(idx, 1)
                                this.setState({
                                  tax_percentages_data: this.state.tax_percentages_data
                                });
                              }}
                                style={{ paddingLeft: 10, fontSize: 20, color: config.themeColor, cursor: 'pointer' }} />
                            </center>
                          }
                        </div>
                      ))}

                      <div className='row mt-2'>
                        <button type='button' onClick={() => {
                          const item = {
                            start_date: null,
                            end_date: null,
                            tax_percentage: '',
                            tax_type: 'vat',
                          };
                          let data = [...this.state.tax_percentages_data, item];
                          this.setState({
                            tax_percentages_data: data
                          });
                        }} className='header_button header_button_text addrow_button_adjustment' style={{ color: config.themeColor }}>ADD ROW</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <section className='row bottom_buttons_section'>
                <button type='button' className='btn previous_button' name='submit' onClick={() => window.location.href = '/reports'}>Cancel</button>
                <button type='button' className='btn next_button' name='Back' onClick={this.submitHandler}>Submit</button>
              </section>
            </div>
          }
        </div>

      </div>
    )
  }
}