export const PAYMENTS_INFO = [
  {
    costing_type: "Vendor Advance Payments",
    costing_table: "vendor_adv_invoices",
    payment_table_name: "vendor_adv_payments",
    billing_currency: "",
    costing_id: "",
    currency_xchg_rate: "",
    amount: "",
    payment_posting_type: "vendor_adv",
    payment_due_date: null,
    description: "",
    date_of_payment: null,
    invoice_no: "",
    status: "",
    payment_id: "",
    payment_approval_status: "Approved",
    payment_costing_type: "vendor_adv_payments_data",
  },
  {
    costing_type: "Purchase Contract Advance Payments",
    costing_table: "pc_adv_invoices",
    payment_table_name: "pc_adv_payments",
    billing_currency: "",
    costing_id: "",
    currency_xchg_rate: "",
    amount: "",
    payment_posting_type: "pc_vendor_adv",
    payment_due_date: null,
    description: "",
    date_of_payment: null,
    invoice_no: "",
    status: "",
    payment_id: "",
    payment_approval_status: "Approved",
    payment_costing_type: "pc_adv_payments_data",
  },
  {
    costing_type: "Barge Purchase performa Coal Costing",
    costing_table: "barge_purchase_financial_coal_costing",
    payment_table_name: "barge_purchase_financial_coal_payments",
    billing_currency: "",
    costing_id: "",
    currency_xchg_rate: "",
    amount: "",
    payment_posting_type: "Coal",
    payment_due_date: null,
    description: "",
    date_of_payment: null,
    invoice_no: "",
    status: "",
    payment_id: "",
    payment_approval_status: "Approved",
    payment_costing_type: "barge_purchase_financial_coal_payments_data",
  },
  {
    costing_type: "Barge Purchase Commercial Coal Costing",
    costing_table: "barge_purchase_financial_coal_costing",
    payment_table_name: "barge_purchase_financial_coal_payments",
    billing_currency: "",
    costing_id: "",
    payment_posting_type: "Coal",
    currency_xchg_rate: "",
    amount: "",
    payment_due_date: null,
    description: "",
    date_of_payment: null,
    invoice_no: "",
    status: "",
    payment_id: "",
    payment_approval_status: "Approved",
    payment_costing_type: "barge_purchase_financial_coal_payments_data",
  },
  {
    costing_type: "Barge Costing",
    costing_table: "barge_purchase_financial_barge_costing",
    payment_table_name: "barge_purchase_financial_barge_payments",
    billing_currency: "",
    costing_id: "",
    currency_xchg_rate: "",
    payment_posting_type: "payable",
    amount: "",
    payment_due_date: null,
    description: "",
    date_of_payment: null,
    invoice_no: "",
    status: "",
    payment_id: "",
    payment_approval_status: "Approved",
    payment_costing_type: "barge_purchase_financial_barge_payments_data",
  },
  {
    costing_type: "Chemical Spray",
    costing_table: "barge_chemical_spray",
    payment_table_name: "barge_chemical_spray_payment_postings",
    billing_currency: "",
    costing_id: "",
    currency_xchg_rate: "",
    payment_posting_type: "Chemical_spray",
    amount: "",
    payment_due_date: null,
    description: "",
    date_of_payment: null,
    invoice_no: "",
    status: "",
    payment_id: "",
    payment_approval_status: "Approved",
    payment_costing_type: "barge_chemical_spray_payment_postings_data",
  },
  {
    costing_type: "Export Tax",
    costing_table: "barge_export_tax",
    payment_table_name: "barge_export_tax_payment_postings",
    billing_currency: "",
    costing_id: "",
    currency_xchg_rate: "",
    payment_posting_type: "export_tax",
    amount: "",
    payment_due_date: null,
    description: "",
    date_of_payment: null,
    invoice_no: "",
    status: "",
    payment_id: "",
    payment_approval_status: "Approved",
    payment_costing_type: "barge_export_tax_payment_postings_data",
  },
  {
    costing_type: "Docuementation Charges",
    costing_table: "barge_doc_charges",
    payment_table_name: "barge_doc_charges_payment_postings",
    billing_currency: "",
    costing_id: "",
    currency_xchg_rate: "",
    payment_posting_type: "",
    amount: "",
    payment_due_date: null,
    description: "",
    date_of_payment: null,
    invoice_no: "",
    status: "",
    payment_id: "",
    payment_approval_status: "Approved",
    payment_costing_type: "barge_doc_charges_payment_postings_data",
  },
  {
    costing_type: "Laparon Survey Charges",
    costing_table: "barge_laparon_survey_charges",
    payment_table_name: "barge_laparon_survey_charges_payment_postings",
    billing_currency: "",
    costing_id: "",
    currency_xchg_rate: "",
    payment_posting_type: "",
    amount: "",
    payment_due_date: null,
    description: "",
    date_of_payment: null,
    invoice_no: "",
    status: "",
    payment_id: "",
    payment_approval_status: "Approved",
    payment_costing_type: "barge_laparon_survey_charges_payment_postings_data",
  },
  {
    costing_type: "Sampling & Analaysis Charges",
    costing_table: "barge_sac_charges",
    payment_table_name: "barge_sac_charges_payment_postings",
    billing_currency: "",
    costing_id: "",
    currency_xchg_rate: "",
    payment_posting_type: "Sampling & Analysis",
    amount: "",
    payment_due_date: null,
    description: "",
    date_of_payment: null,
    invoice_no: "",
    status: "",
    payment_id: "",
    payment_approval_status: "Approved",
    payment_costing_type: "barge_sac_charges_payment_postings_data",
  },
  {
    costing_type: "Pre Shipment Data",
    costing_table: "barge_pur_fin_pre_shipment",
    payment_table_name: "barge_pur_fin_pre_shipment_payments",
    billing_currency: "",
    costing_id: "",
    currency_xchg_rate: "",
    payment_posting_type: "Pre_Shipment",
    amount: "",
    payment_due_date: null,
    description: "",
    date_of_payment: null,
    invoice_no: "",
    status: "",
    payment_id: "",
    payment_approval_status: "Approved",
    payment_costing_type: "barge_pur_fin_pre_shipment_payments_data",
  },
  {
    costing_type: "Coal Procurement Charges",
    costing_table: "barge_purchase_financial_cpc",
    payment_table_name: "barge_purchase_financial_cpc_payments",
    billing_currency: "",
    costing_id: "",
    currency_xchg_rate: "",
    payment_posting_type: "coal_procurement",
    amount: "",
    payment_due_date: null,
    description: "",
    date_of_payment: null,
    invoice_no: "",
    status: "",
    payment_id: "",
    payment_approval_status: "Approved",
    payment_costing_type: "barge_purchase_financial_cpc_payments_data",
  },
  {
    costing_type: "Barge Coal Procurement Service",
    costing_table: "barge_cps_costing",
    payment_table_name: "barge_cps_costing_payments",
    billing_currency: "",
    costing_id: "",
    currency_xchg_rate: "",
    payment_posting_type: "coal_procurement",
    amount: "",
    payment_due_date: null,
    description: "",
    date_of_payment: null,
    invoice_no: "",
    status: "",
    payment_id: "",
    payment_approval_status: "Approved",
    payment_costing_type: "Barge Coal Procurement Service",
  },
  {
    costing_type: "Barge Demurrage to vendor",
    costing_table: "barge_dmr_to_barge_vendor_payment_postings",
    payment_table_name: "barge_demurrage_to_barge_vendor",
    billing_currency: "",
    costing_id: "",
    currency_xchg_rate: "",
    payment_posting_type: "dmr_to_bg_vend",
    amount: "",
    payment_due_date: null,
    description: "",
    date_of_payment: null,
    invoice_no: "",
    status: "",
    payment_id: "",
    payment_approval_status: "Approved",
    payment_costing_type: "barge_dmr_to_barge_vendor_payment_postings_data",
  },
  {
    costing_type: "Barge Demurrage To Supplier",
    costing_table: "barge_demurrage_to_supplier",
    payment_table_name: "barge_dmr_to_supplier_payment_postings",
    billing_currency: "",
    costing_id: "",
    currency_xchg_rate: "",
    payment_posting_type: "company_to_supplier",
    amount: "",
    payment_due_date: null,
    description: "",
    date_of_payment: null,
    invoice_no: "",
    status: "",
    payment_id: "",
    payment_approval_status: "Approved",
    payment_costing_type: "barge_dmr_to_supplier_payment_postings_data",
  },
  {
    costing_type: "Barge Demurrage From Supplier",
    costing_table: "barge_demurrage_from_supplier",
    payment_table_name: "barge_dmr_from_supplier_payment_postings",
    billing_currency: "",
    costing_id: "",
    currency_xchg_rate: "",
    payment_posting_type: "supplier_to_company",
    amount: "",
    payment_due_date: null,
    description: "",
    date_of_payment: null,
    invoice_no: "",
    status: "",
    payment_id: "",
    payment_approval_status: "Approved",
    payment_costing_type: "barge_dmr_from_supplier_payment_postings_data",
  },
  {
    costing_type: "Barge Demurrage To buyer",
    costing_table: "barge_demurrage_to_buyer",
    payment_table_name: "barge_dmr_to_buyer_payment_postings",
    billing_currency: "",
    costing_id: "",
    currency_xchg_rate: "",
    payment_posting_type: "",
    amount: "",
    payment_due_date: null,
    description: "",
    date_of_payment: null,
    invoice_no: "",
    status: "",
    payment_id: "",
    payment_approval_status: "Approved",
    payment_costing_type: "barge_dmr_to_buyer_payment_postings_data",
  },
  {
    costing_type: "Barge Demurrage From buyer",
    costing_table: "barge_demurrage_from_buyer",
    payment_table_name: "barge_dmr_from_buyer_payment_postings",
    billing_currency: "",
    costing_id: "",
    currency_xchg_rate: "",
    payment_posting_type: "",
    amount: "",
    payment_due_date: null,
    description: "",
    date_of_payment: null,
    invoice_no: "",
    status: "",
    payment_id: "",
    payment_approval_status: "Approved",
    payment_costing_type: "barge_dmr_from_buyer_payment_postings_data",
  },
  {
    costing_type: "Barge Other Expenses",
    costing_table: "barge_pur_fin_other_expenses",
    payment_table_name: "barge_pur_fin_other_expenses_payments",
    billing_currency: "",
    costing_id: "",
    currency_xchg_rate: "",
    payment_posting_type: "other_expenses",
    amount: "",
    payment_due_date: null,
    description: "",
    date_of_payment: null,
    invoice_no: "",
    status: "",
    payment_id: "",
    payment_approval_status: "Approved",
    payment_costing_type: "barge_pur_fin_other_expenses_payments_data",
  },
  {
    costing_type: "Barge Insurance",
    costing_table: "insurance_and_declaration",
    payment_table_name: "insurance_and_declaration_payments",
    billing_currency: "",
    costing_id: "",
    currency_xchg_rate: "",
    payment_posting_type: "",
    amount: "",
    payment_due_date: null,
    description: "",
    date_of_payment: null,
    invoice_no: "",
    status: "",
    payment_id: "",
    payment_approval_status: "Approved",
    payment_costing_type: "",
  },
  {
    costing_type: "Return Cargo Charges",
    costing_table: "return_cargo_charges",
    payment_table_name: "return_cargo_charges_payments",
    billing_currency: "",
    costing_id: "",
    currency_xchg_rate: "",
    payment_posting_type: "Return_Cargo",
    amount: "",
    payment_due_date: null,
    description: "",
    date_of_payment: null,
    invoice_no: "",
    status: "",
    payment_id: "",
    payment_approval_status: "Approved",
    payment_costing_type: "return_cargo_charges_payments_data",
  },
  {
    costing_type: "MV Sales Performa Coal Costing",
    costing_table: "mv_sales_performa_coal_costing",
    payment_table_name: "mv_sales_performa_coal_invoice_payments",
    billing_currency: "",
    costing_id: "",
    currency_xchg_rate: "",
    payment_posting_type: "performa",
    amount: "",
    payment_due_date: null,
    description: "",
    date_of_payment: null,
    invoice_no: "",
    status: "",
    payment_id: "",
    payment_approval_status: "Approved",
    payment_costing_type: "mv_sales_performa_coal_invoice_payments_data",
  },
  {
    costing_type: "Barge Sales Commercial Coal Costing",
    costing_table: "barge_sales_fin_coal_costing",
    payment_table_name: "barge_sales_fin_coal_payments",
    billing_currency: "",
    costing_id: "",
    currency_xchg_rate: "",
    payment_posting_type: "Sales Commercial",
    amount: "",
    payment_due_date: null,
    description: "",
    date_of_payment: null,
    invoice_no: "",
    status: "",
    payment_id: "",
    payment_approval_status: "Approved",
    payment_costing_type: "barge_sales_fin_coal_payments_data",
  },

  // MOTHER VESSEL
  {
    costing_type: "MV Floating Crane",
    costing_table: "mv_pur_fin_floating_crane_charges",
    payment_table_name: "mv_floating_crane_charges_payment_postings",
    billing_currency: "",
    costing_id: "",
    currency_xchg_rate: "",
    payment_posting_type: "Floating_Vendor",
    amount: "",
    payment_due_date: null,
    description: "",
    date_of_payment: null,
    invoice_no: "",
    status: "",
    payment_id: "",
    payment_approval_status: "Approved",
    payment_costing_type: "mv_floating_crane_charges_payment_postings_data",
  },
  {
    costing_type: "MV Stevedore Costing",
    costing_table: "mv_pur_fin_stevedore_costing",
    payment_table_name: "mv_stevedore_costing_payment_postings",
    billing_currency: "",
    costing_id: "",
    currency_xchg_rate: "",
    payment_posting_type: "Stevedore_Vendor",
    amount: "",
    payment_due_date: null,
    description: "",
    date_of_payment: null,
    invoice_no: "",
    status: "",
    payment_id: "",
    payment_approval_status: "Approved",
    payment_costing_type: "mv_stevedore_costing_payment_postings_data",
  },
  {
    costing_type: "MV Freight Costing",
    costing_table: "mv_pur_fin_freight_costing",
    payment_table_name: "mv_freight_costing_payment_postings",
    billing_currency: "",
    costing_id: "",
    currency_xchg_rate: "",
    payment_posting_type: "freight_costing",
    amount: "",
    payment_due_date: null,
    description: "",
    date_of_payment: null,
    invoice_no: "",
    status: "",
    payment_id: "",
    payment_approval_status: "Approved",
    payment_costing_type: "mv_freight_costing_payment_postings_data",
  },
  {
    costing_type: "MV Coal Procurement Charges",
    costing_table: "mv_pur_fin_cpc",
    payment_table_name: "mv_pur_fin_cpc_payments",
    billing_currency: "",
    costing_id: "",
    currency_xchg_rate: "",
    payment_posting_type: "cpc",
    amount: "",
    payment_due_date: null,
    description: "",
    date_of_payment: null,
    invoice_no: "",
    status: "",
    payment_id: "",
    payment_approval_status: "Approved",
    payment_costing_type: "mv_pur_fin_cpc_payments_data",
  },
  {
    costing_type: "MV Coal Sales Charges",
    costing_table: "mv_sales_fin_csc",
    payment_table_name: "mv_sales_fin_csc_payments",
    billing_currency: "",
    costing_id: "",
    currency_xchg_rate: "",
    payment_posting_type: "csc",
    amount: "",
    payment_due_date: null,
    description: "",
    date_of_payment: null,
    invoice_no: "",
    status: "",
    payment_id: "",
    payment_approval_status: "Approved",
    payment_costing_type: "mv_sales_fin_csc_payments_data",
  },
  {
    costing_type: "MV Sampling & Analaysis Charges",
    costing_table: "mv_pur_fin_sac_charges",
    payment_table_name: "mv_sac_charges_payment_postings",
    billing_currency: "",
    costing_id: "",
    currency_xchg_rate: "",
    payment_posting_type: "mv_sac",
    amount: "",
    payment_due_date: null,
    description: "",
    date_of_payment: null,
    invoice_no: "",
    status: "",
    payment_id: "",
    payment_approval_status: "Approved",
    payment_costing_type: "mv_sac_charges_payment_postings_data",
  },
  {
    costing_type: "MV Pre Shipment",
    costing_table: "mv_pre_shipment",
    payment_table_name: "mv_pre_shipment_payment_postings",
    billing_currency: "",
    costing_id: "",
    currency_xchg_rate: "",
    payment_posting_type: "Pre_Shipment",
    amount: "",
    payment_due_date: null,
    description: "",
    date_of_payment: null,
    invoice_no: "",
    status: "",
    payment_id: "",
    payment_approval_status: "Approved",
    payment_costing_type: "mv_pre_shipment_payment_postings_data",
  },
  {
    costing_type: "MV Export Tax",
    costing_table: "mv_pur_fin_export_tax",
    payment_table_name: "mv_export_tax_payment_postings",
    billing_currency: "",
    costing_id: "",
    currency_xchg_rate: "",
    payment_posting_type: "export_tax",
    amount: "",
    payment_due_date: null,
    description: "",
    date_of_payment: null,
    invoice_no: "",
    status: "",
    payment_id: "",
    payment_approval_status: "Approved",
    payment_costing_type: "mv_export_tax_payment_postings_data",
  },
  {
    costing_type: "MV Docuementation Charges",
    costing_table: "mv_pur_fin_doc_charges",
    payment_table_name: "mv_doc_charges_payment_postings",
    billing_currency: "",
    costing_id: "",
    currency_xchg_rate: "",
    payment_posting_type: "mv_doc_charges",
    amount: "",
    payment_due_date: null,
    description: "",
    date_of_payment: null,
    invoice_no: "",
    status: "",
    payment_id: "",
    payment_approval_status: "Approved",
    payment_costing_type: "mv_doc_charges_payment_postings_data",
  },

  {
    costing_type: "MV Laparon Survey Charges",
    costing_table: "mv_pur_fin_laparon_survey_charges",
    payment_table_name: "mv_laparon_charges_payment_postings",
    billing_currency: "",
    costing_id: "",
    currency_xchg_rate: "",
    payment_posting_type: "mv_laprn_charges",
    amount: "",
    payment_due_date: null,
    description: "",
    date_of_payment: null,
    invoice_no: "",
    status: "",
    payment_id: "",
    payment_approval_status: "Approved",
    payment_costing_type: "mv_laparon_charges_payment_postings_data",
  },
  {
    costing_type: "MV PNBP JASA PBM",
    costing_table: "mv_pnbp_jasa_pbm",
    payment_table_name: "mv_pnbp_jasa_pbm_payment_postings",
    billing_currency: "",
    costing_id: "",
    currency_xchg_rate: "",
    payment_posting_type: "mv_pnbp_jasa",
    amount: "",
    payment_due_date: null,
    description: "",
    date_of_payment: null,
    invoice_no: "",
    status: "",
    payment_id: "",
    payment_approval_status: "Approved",
    payment_costing_type: "mv_pnbp_charges_payment_postings_data",
  },
  {
    costing_type: "MV Dead Freight",
    costing_table: "mv_dead_freight",
    payment_table_name: "mv_dead_freight_payments",
    billing_currency: "",
    costing_id: "",
    currency_xchg_rate: "",
    payment_posting_type: "",
    amount: "",
    payment_due_date: null,
    description: "",
    date_of_payment: null,
    invoice_no: "",
    status: "",
    payment_id: "",
    payment_approval_status: "Approved",
    payment_costing_type: "mv_dead_freight_payments_data",
  },
  {
    costing_type: "MV Supplier Demurrage",
    costing_table: "mv_supplier_demurrage",
    payment_table_name: "mv_supplier_demurrage_payment_postings",
    billing_currency: "",
    costing_id: "",
    currency_xchg_rate: "",
    payment_posting_type: "",
    amount: "",
    payment_due_date: null,
    description: "",
    date_of_payment: null,
    invoice_no: "",
    status: "",
    payment_id: "",
    payment_approval_status: "Approved",
    payment_costing_type: "mv_supplier_demurrage_payment_postings_data",
  },
  {
    costing_type: "MV Buyer Demurrage",
    costing_table: "mv_buyer_demurrage",
    payment_table_name: "mv_buyer_demurrage_payment_postings",
    billing_currency: "",
    costing_id: "",
    currency_xchg_rate: "",
    payment_posting_type: "",
    amount: "",
    payment_due_date: null,
    description: "",
    date_of_payment: null,
    invoice_no: "",
    status: "",
    payment_id: "",
    payment_approval_status: "Approved",
    payment_costing_type: "mv_buyer_demurrage_payment_postings_data",
  },
  {
    costing_type: "MV Incoming Debit Note",
    costing_table: "business_no_debit_note",
    payment_table_name: "business_no_debit_note_payment_postings",
    billing_currency: "",
    costing_id: "",
    currency_xchg_rate: "",
    payment_posting_type: "incoming_debit_note",
    amount: "",
    payment_due_date: null,
    description: "",
    date_of_payment: null,
    invoice_no: "",
    status: "",
    payment_id: "",
    payment_approval_status: "Approved",
    payment_costing_type: "mv_debit_note_payment_data",
  },
  {
    costing_type: "MV Outgoing Debit Note",
    costing_table: "business_no_debit_note",
    payment_table_name: "business_no_debit_note_payment_postings",
    billing_currency: "",
    costing_id: "",
    currency_xchg_rate: "",
    payment_posting_type: "outgoing_debit_note",
    amount: "",
    payment_due_date: null,
    description: "",
    date_of_payment: null,
    invoice_no: "",
    status: "",
    payment_id: "",
    payment_approval_status: "Approved",
    payment_costing_type: "mv_debit_note_payment_data",
  },
  {
    costing_type: "MV Incoming Credit Note",
    costing_table: "business_no_credit_note",
    payment_table_name: "business_no_credit_note_payment_postings",
    billing_currency: "",
    costing_id: "",
    currency_xchg_rate: "",
    payment_posting_type: "incoming_credit_note",
    amount: "",
    payment_due_date: null,
    description: "",
    date_of_payment: null,
    invoice_no: "",
    status: "",
    payment_id: "",
    payment_approval_status: "Approved",
    payment_costing_type: "mv_credit_note_payment_data",
  },
  {
    costing_type: "MV Outgoing Credit Note",
    costing_table: "business_no_credit_note",
    payment_table_name: "business_no_credit_note_payment_postings",
    billing_currency: "",
    costing_id: "",
    currency_xchg_rate: "",
    payment_posting_type: "outgoing_credit_note",
    amount: "",
    payment_due_date: null,
    description: "",
    date_of_payment: null,
    invoice_no: "",
    status: "",
    payment_id: "",
    payment_approval_status: "Approved",
    payment_costing_type: "mv_credit_note_payment_data",
  },
  {
    costing_type: "MV Other Expenses",
    costing_table: "business_no_other_expenses",
    payment_table_name: "business_no_other_expenses_payment_postings",
    billing_currency: "",
    costing_id: "",
    currency_xchg_rate: "",
    payment_posting_type: "other_expenses",
    amount: "",
    payment_due_date: null,
    description: "",
    date_of_payment: null,
    invoice_no: "",
    status: "",
    payment_id: "",
    payment_approval_status: "Approved",
    payment_costing_type: "mv_other_expenses_data",
  },
  {
    costing_type: "MV Other Income",
    costing_table: "business_no_other_income",
    payment_table_name: "business_no_other_income_payment_postings",
    billing_currency: "",
    costing_id: "",
    currency_xchg_rate: "",
    payment_posting_type: "Other_Income",
    amount: "",
    payment_due_date: null,
    description: "",
    date_of_payment: null,
    invoice_no: "",
    status: "",
    payment_id: "",
    payment_type: "R",
    payment_approval_status: "Approved",
    payment_costing_type: "mv_other_income_data",
  },
  {
    costing_type: "Services Payments",
    costing_table: "services",
    payment_table_name: "services_payment_postings",
    billing_currency: "",
    costing_id: "",
    currency_xchg_rate: "",
    payment_posting_type: "Payable",
    amount: "",
    payment_due_date: null,
    description: "",
    date_of_payment: null,
    invoice_no: "",
    status: "",
    payment_id: "",
    payment_approval_status: "Approved",
    payment_costing_type: "payable_services_payment_data",
  },
];

export const COSTING_FILES = [
  {
    column: "invoice_file",
    file_extension: "",
    file_content: "",
    update_content: false,
  },
  {
    column: "dsr_file",
    file_extension: "",
    file_content: "",
    update_content: false,
  },
  {
    column: "coa_file",
    file_extension: "",
    file_content: "",
    update_content: false,
  },
  {
    column: "bl_file",
    file_extension: "",
    file_content: "",
    update_content: false,
  },
];
