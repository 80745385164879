import React, { Component } from 'react';

import {  TextField, } from '@material-ui/core';
import '../../App.css';
import api from '../../api/api';
import config from '../../config/config';
import SideBar from './SideBar';
import Header from './Header';
import CookieHandler from './CookieHandler';

export default class ChangePassword extends Component {
  constructor(props) {
    super();
    this.Cookie = new CookieHandler();
    this.state={
      pswd : '',
      newPswd : '',
      RetypePswd : '',
      pswdError : false,
      newPswdError : false,
      RetypepswdError : false,
      errorMsg : '',
      succesMsg : ''
    }
  }

  componentDidMount() {
    if(!this.Cookie.isLoggedIn()) {
      this.props.history.replace('/logout');
    }
    
    document.title = config.documentTitle +"Change Password";
  }

  textHandler = (e) =>{
    if(e.target.name === 'pswd') {
      this.setState({pswd : e.target.value, pswdError : false});
    }
    if(e.target.name === 'newPswd') {
      this.setState({
        newPswd : e.target.value, newPswdError : false
      });
    }
    if(e.target.name === 'RetypePswd') {
      this.setState({
        RetypePswd : e.target.value, RetypepswdError : false
      })
    }
  }

  submitHandler = (e) =>{
    e.preventDefault();
    var loginUserID = this.Cookie.getCookie('loginUserId');
    var userID = this.Cookie.getCookie('loginUserId');
    var idToken = this.Cookie.getIdTokenCookie();
    var newPswd = this.state.newPswd;
    var oldPswd = this.state.pswd;
    /* 
    !<!----------------------------------- Checking Mandatory Fields ---------------!>
    */
   if(this.state.pswd === '' && this.state.newPswd ==='' && this.state.RetypePswd === '') {
     this.setState({
       pswdError : true,
       newPswdError : true,
       RetypepswdError : true,
     });
     return;
   }
   if(this.state.pswd === '') {
     this.setState({pswdError : true});
     return;
   }
   if(newPswd === '') {
     this.setState({
       newPswdError : true
     });
     return;
   }
   if(this.state.RetypePswd === '') {
     this.setState({
       RetypepswdError : true
     });
     return;
   }
   if(this.state.newPswd !== this.state.RetypePswd) {
     this.setState({
       RetypepswdError : true,
       errorMsg : 'New Password and Retype Password are not matched!.'
     });
     return;
   }
    api.userChangePswd(loginUserID, userID, newPswd, oldPswd, idToken).then((response)=>{
      if(response.status >= 200 && response.status < 300) {
        response.json().then((res)=>{
          if(res.idtoken)
          this.Cookie.setIdTokenCookie(res.idtoken);
          if(res) {
            if(res.code === '200') {
              this.setState({
                succesMsg :"Password changed successfully",
                errorMsg : ''
              });
              alert('Password changed successfully. Please login again to continue.')
              window.location.href='/logout';
            }
            if(res.code === "626") {
              this.setState({
                errorMsg : "Current Password is incorrect",
                succesMsg :'',
                pswdError : true
              });
            }
          }
        })
      }
      else if(response.status === 601) {
        response.json().then((res)=>{
          this.setState({
            errorMsg : res.message,
            succesMsg : '',
          });
        })
      }
      else if(response.status === 607) {
        window.location.href ='/logout';
      }
    });
  }

  render() {
    return(
      <div id='wraper'>
        <SideBar />
        <div className='content'>
          <Header />
          <div className='content-header'>
            <div className='col'>
            <h4>Change Password</h4>
            <hr />
            </div>
          </div>
          <form className='container' onSubmit={this.submitHandler}>
            <div className='col-lg card'>
              {this.state.succesMsg &&
                <div className='row'>
                  <div className='col text-center'>
                    <p className='success-class'>{this.state.succesMsg}</p>
                  </div>
                </div>
              }
              {this.state.errorMsg &&
                <div className='row'>
                  <div className='col text-center'>
                    <p className='error-class'>{this.state.errorMsg}</p>
                  </div>
                </div>
              }
              <div className='row'>
                <div className='col-lg-4'>
                  <label className='label-text'>Current Password <span style={{color:'red'}}>*</span></label>
                </div>
                <div className='col-lg-5'>
                  <TextField
                    name='pswd'
                    margin='dense'
                    variant='outlined'
                    placeholder='Current Password'
                    required
                    type='password'
                    value={this.state.pswd}
                    error={this.state.pswdError}
                    onChange={this.textHandler}
                    fullWidth
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-4'>
                  <label className='label-text'>New Password <span style={{color:'red'}}>*</span></label>
                </div>
                <div className='col-lg-5'>
                  <TextField
                    name='newPswd'
                    margin='dense'
                    variant='outlined'
                    placeholder='New Password'
                    required
                    type='password'
                    value={this.state.newPswd}
                    error={this.state.newPswdError}
                    onChange={this.textHandler}
                    fullWidth
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-4'>
                  <label className='label-text'>Retype New Password <span style={{color:'red'}}>*</span></label>
                </div>
                <div className='col-lg-5'>
                  <TextField
                    name='RetypePswd'
                    margin='dense'
                    variant='outlined'
                    placeholder='Retype New Password'
                    required
                    type='password'
                    value={this.state.RetypePswd}
                    error={this.state.RetypepswdError}
                    onChange={this.textHandler}
                    fullWidth
                  />
                </div>
              </div>
              <br />
              <div className='row'>
                <button type='submit' className='btn btn-info btn-responsive'  name='submit'>Submit</button>&emsp;
                <button type='button' className='btn btn-secondary btn-responsive' onClick={()=>window.location.href='/dashboard'}>Cancel</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}