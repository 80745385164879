import React, { Component } from "react";
import CookieHandler from "../common/CookieHandler";
// import config from '../../config/config';
import api from "../../api/api";
import { Snackbar, Drawer } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import config from "../../config/config";
import BusinessNoVesselReportAdd from "./Vessel-Completion-Report-Add";
// import BusinessNoVesselReportView from './Vessel-Completion-Report-View';
import BusinessNoVesselReportUpdate from "./Vessel-Completion-Report-Update";
import Loader from "../common/Loader";
import { localDateFormate } from "../common/common";

export default class BusinessNoVesselReportList extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      showDrawer: false,
      isLoading: true,
      vesselReportAdd: false,
      vesselReportView: false,
      vesselReportUpdate: false,
      features: [],
      comboID: null,
      notify_party_data: [],
      management_remarks: "",
      business_no_vessel_com_bl_data: [
        {
          bl_no: "",
          bl_date: null,
        },
      ],
      business_no_vessel_com_coo_data: [
        {
          coo_no: "",
          coo_date: null,
        },
      ],
      files: [],
      vessel_completion_id: null,
      updateDocuments: false,
    };
  }

  componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    let features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (!(features.includes("330")  || features.includes("97") )) {
      window.location.href = "/dashboard";
    }
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const business_no_id = this.props.businessNo;
    api
      .get_business_no_vessel_completions(loginUserID, idToken, business_no_id)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.vessel_completion_data) {
                this.setState({
                  vessel_completion_id:
                    res.vessel_completion_data.length > 0
                      ? res.vessel_completion_data[0].id
                      : null,
                  management_remarks:
                    res.vessel_completion_data.length > 0
                      ? res.vessel_completion_data[0].management_remarks
                      : "",
                  business_no_vessel_com_bl_data:
                    res.vessel_completion_data.length > 0
                      ? res.vessel_completion_data[0].vessel_completion_bl_data
                      : [],
                  business_no_vessel_com_coo_data:
                    res.vessel_completion_data.length > 0
                      ? res.vessel_completion_data[0].vessel_completion_coo_data
                      : [],
                  files:
                    res.vessel_completion_data.length > 0
                      ? res.vessel_completion_data[0].files
                      : [],
                  isLoading: false,
                });
              } else {
              }
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  }

  onAddResponse = (value) => {
    this.setState({ vesselReportAdd: false, showDrawer: false }, () => {
      this.componentDidMount();
    });
  };

  editCallback = (childData) => {
    this.setState({
      showDrawer: true,
      vesselReportAdd: false,
      vesselReportUpdate: true,
      vesselReportView: false,
    });
  };

  onUpdateResponse = (value) => {
    if (value) {
      this.setState(
        {
          showDrawer: false,
          vesselReportAdd: false,
          vesselReportView: false,
          vesselReportUpdate: false,
        },
        () => this.componentDidMount()
      );
    }
  };

  CallbackViewPage = (childData) => {
    this.setState({
      showDrawer: false,
      vesselReportView: false,
      vesselReportUpdate: false,
    });
  };

  CallbackDrawerClose = (childData) => {
    this.setState({
      showDrawer: childData,

      vesselReportAdd: false,
      vesselReportView: false,
    });
  };

  toLocaleString(value) {
    return value ? Number(value).toLocaleString() : 0;
  }

  render() {
    let drawerComponent;
    if (this.state.vesselReportAdd && this.props.businessNo) {
      drawerComponent = (
        <BusinessNoVesselReportAdd
          businessNo={this.props.businessNo}
          onAddResponse={this.onAddResponse}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
        />
      );
    }
    // if (this.state.vesselReportView && this.props.businessNo && this.state.vessel_completion_id) {
    //   drawerComponent = <BusinessNoVesselReportView businessNo={this.props.businessNo} returnCallbackDrawerClose={this.CallbackDrawerClose}
    //     returnEditCallback={this.editCallback} />;
    // }
    if (
      this.state.vesselReportUpdate &&
      this.props.businessNo &&
      this.state.vessel_completion_id
    ) {
      drawerComponent = (
        <BusinessNoVesselReportUpdate
          businessNo={this.props.businessNo}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.CallbackViewPage}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div className="row w-100">
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>

          <Drawer
            anchor="right"
            open={this.state.showDrawer}
            variant="temporary"
            elevation={20}
            style={{ overflow: "initial" }}
          >
            <div className="row p-0" style={{ width: 800 }}>
              {drawerComponent}
            </div>
          </Drawer>
          <div className="card mt-2 p-2 w-100">
            <div className="card-body">
              <div className="row">
                <table>
                  <tbody>
                    <tr>
                      <td
                        className="table_td"
                        style={{
                          textAlign: "left",
                          minWidth: 300,
                          fontSize: 21,
                        }}
                      >
                        Vessel Completion Report
                      </td>

                      <td className="table_td">
                        {!this.state.vessel_completion_id ? (
                          <div>
                            {(this.state.features.includes("329")  ||
                              this.state.features.includes("97") ) && (
                              <i
                                className="fa fa-plus-circle mr-2"
                                aria-hidden="true"
                                style={{
                                  fontSize: 22,
                                  color: config.themeColor,
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  this.setState({
                                    showDrawer: true,
                                    vesselReportAdd: true,
                                    vesselReportView: false,
                                  })
                                }
                              />
                            )}
                          </div>
                        ) : (
                          <div>
                            {(this.state.features.includes("331")  ||
                              this.state.features.includes("97") ) && (
                              <i
                                className="fa fa-pencil edit_icon mr-2"
                                aria-hidden="true"
                                style={{
                                  fontSize: 22,
                                  color: config.themeColor,
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  this.setState({
                                    showDrawer: true,
                                    vesselReportUpdate: true,
                                    vesselReportAdd: false,
                                    vesselReportView: false,
                                  })
                                }
                              />
                            )}
                          </div>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {this.state.vessel_completion_id && (
                <div className="card p-0">
                  <div className="card-body">
                    <div className="p-3 border_dashed  rounded-lg">
                      {this.state.business_no_vessel_com_bl_data.map(
                        (e, idx) => (
                          <div className="row" key={idx}>
                            <div className="col-lg-11 p-0">
                              <div className="row mb-2">
                                <div className="col-lg-6 pl-0">
                                  <label className="form_label mb-1">
                                    BL No
                                  </label>
                                  <div>{e.bl_no}</div>
                                </div>
                                <div className="col-lg-6 pr-0">
                                  <label className="form_label mb-1">
                                    BL Date
                                  </label>
                                  <div>
                                    {e.bl_date
                                      ? localDateFormate(e.bl_date)
                                      : "-"}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                      <hr className="mt-3" />
                      {this.state.business_no_vessel_com_coo_data.map(
                        (e, idx) => (
                          <div className="row" key={idx}>
                            <div className="col-lg-11 p-0">
                              <div className="row mb-2">
                                <div className="col-lg-6 pl-0">
                                  <label className="form_label mb-1">
                                    COO No
                                  </label>
                                  <div>{e.coo_no}</div>
                                </div>
                                <div className="col-lg-6 pr-0">
                                  <label className="form_label mb-1">
                                    COO Date
                                  </label>
                                  <div>
                                    {e.coo_date
                                      ? localDateFormate(e.coo_date)
                                      : "-"}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                      <hr className="mt-3" />
                      <div className="row mt-3">
                        <div className="col-lg-12 pl-0">
                          <label className="form_label mb-1">
                            Management Remarks
                          </label>
                          <div
                            style={{
                              whiteSpace: "pre-line",
                              textTransform: "uppercase",
                            }}
                          >
                            {this.state.management_remarks
                              ? this.state.management_remarks
                              : "-"}
                          </div>
                        </div>
                      </div>
                      <hr />
                      {(this.state.features.includes("364")  ||
                        this.state.features.includes("97") ) && (
                        <div className="row">
                          <span
                            className="col-lg-12 contract_display_header_value pl-0 mb-2"
                            style={{ fontWeight: "bold" }}
                          >
                            Uploaded Documents
                          </span>
                          {this.state.files.length > 0 ? (
                            <div className="row">
                              {this.state.files.map((e, index) => (
                                <div
                                  className={
                                    e.file_path ? "col-lg-12 mb-3" : "col-lg-12"
                                  }
                                  key={index}
                                >
                                  {e.file_path && (
                                    <div className="col-lg-12 p-0">
                                      <a
                                        href={
                                          config.apiDomain + "/" + e.file_path
                                        }
                                        role="button"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ color: config.themeColor }}
                                      >
                                        {e.file_description}
                                      </a>
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div className="row col-lg-12">
                              <p>No files to show</p>
                            </div>
                          )}
                          <p className="row col-lg-12">
                            Add Documents &nbsp;
                            <i
                              className="fa fa-plus-circle"
                              style={{
                                color: config.themeColor,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                this.setState({
                                  showDrawer: true,
                                  vesselReportUpdate: true,
                                  updateDocuments: true,
                                });
                              }}
                            />
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
  }
}
