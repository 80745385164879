import React, { Component } from "react";
import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import api from "../../../api/api";

import { Alert } from "@material-ui/lab";
import {
  MenuItem,
  TextField,
  InputAdornment,
  Snackbar,
  IconButton,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import EventIcon from "@material-ui/icons/Event";
import { localDateFormate, pad } from "../../common/common";
import FileUpload from "../../common/FileUpload";
import Loader from "../../common/Loader";
export default class BargeVendorDemurrageUpdate extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      activeStep: 0,
      selectedBargeId: null,
      invoice_date: null,
      ppn: "",
      ppn_date: null,
      dmr_to_bg_vend_laycan_commence: null,
      dmr_to_bg_vend_barge_demurrage: "",
      billing_currency: "0",
      dmr_to_bg_vend_demurrage_days: "",
      dmr_to_bg_vend_demurrage_value: "",
      dmr_to_bg_vend_currency_xchg_rate: "",
      dmr_to_bg_vend_pph23_account: "0",
      dmr_to_bg_vend_base_price: "",
      dmr_to_bg_vend_vat_percent: "10",
      dmr_to_bg_vend_vat_value: "",
      dmr_to_bg_vend_pph23_percent: "",
      dmr_to_bg_vend_pph23_value: "",
      dmr_to_bg_vend_total: "",
      dmr_to_bg_vend_ctc: "",
      dmr_to_bg_vend_ctc_in_local_currency: "",

      currencyData: [],
      totalBarges: [],
      files: [
        {
          file_description: "",
          file_name: "",
          file_extension: "",
          file_content: "",
          feature_name: "Barge_Demurrage_To_Barge_Vendor",
        },
      ],
      features: [],
      isLoading: true,
      costing_files: [],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    let features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    // if (!(features.includes('134')  || features.includes('97') )) {
    //   window.location.href = '/dashboard';
    // }

    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const getCurrencies = await api.getCurrencies(loginUserID, idToken);
    if (getCurrencies.status >= 200 && getCurrencies.status < 300) {
      getCurrencies.json().then((res) => {
        if (res.code === "200") {
          if (res.Currency) {
            var currencies = [
              <MenuItem
                style={{ fontFamily: "Poppins", fontSize: "14px" }}
                value="0"
                key={-1}
                disabled
              >
                Please Select
              </MenuItem>,
            ];
            for (var i in res.Currency) {
              currencies.push(
                <MenuItem
                  style={{ fontFamily: "Poppins", fontSize: "14px" }}
                  value={res.Currency[i].currency}
                  key={[i]}
                >
                  {res.Currency[i].currency}
                </MenuItem>
              );
            }
            this.setState({
              currencyData: currencies,
            });
          } else {
            alert("Unexpected error occured. Please contact administrator.");
          }
        } else if (res.code === "601") {
          this.setState({
            errorMsg: res.message,
            snackBarErrorOpen: true,
          });
        } else if (res.code === "607") {
          window.location.href = "/logout";
        }
      });
    }
    let comboID = this.props.comboID;
    const barge_vendor_demurrage_id = comboID.split("$#")[0];
    const get_demurrage = await api.get_barge_demurrage_to_barge_vendor(
      loginUserID,
      idToken,
      barge_vendor_demurrage_id
    );
    if (get_demurrage.status >= 200 && get_demurrage.status < 300) {
      get_demurrage.json().then((res) => {
        if (res.code === "200") {
          if (res.barge_demurrage_to_barge_vendor.length > 0) {
            let days = "0";
            let hours = "0";
            let minutes = "0";
            if (res.barge_demurrage_to_barge_vendor[0].time_at_anchorage_days) {
              days =
                res.barge_demurrage_to_barge_vendor[0].time_at_anchorage_days;
            }
            if (
              res.barge_demurrage_to_barge_vendor[0].time_at_anchorage_hours
            ) {
              hours =
                res.barge_demurrage_to_barge_vendor[0].time_at_anchorage_hours;
            }
            if (
              res.barge_demurrage_to_barge_vendor[0].time_at_anchorage_minutes
            ) {
              minutes =
                res.barge_demurrage_to_barge_vendor[0]
                  .time_at_anchorage_minutes;
            }

            var dmr_to_bg_vend_total_paid = 0;
            var dmr_to_bg_vend_remaining_amount = 0;
            for (var i of res.barge_demurrage_to_barge_vendor[0]
              .barge_vendor_payments) {
              dmr_to_bg_vend_total_paid += Number(i.amount);
            }

            dmr_to_bg_vend_remaining_amount =
              Number(
                res.barge_demurrage_to_barge_vendor[0].dmr_to_bg_vend_total
                  ? res.barge_demurrage_to_barge_vendor[0].dmr_to_bg_vend_total
                  : 0
              ) - Number(dmr_to_bg_vend_total_paid);

            this.setState({
              dmr_to_bg_vend_total_paid: dmr_to_bg_vend_total_paid,
              dmr_to_bg_vend_remaining_amount: dmr_to_bg_vend_remaining_amount,
              remainingAmount: dmr_to_bg_vend_remaining_amount,

              selectedBargeId: res.barge_demurrage_to_barge_vendor[0].barge_id,
              costing_no: res.barge_demurrage_to_barge_vendor[0].costing_no,
              invoice_file: res.barge_demurrage_to_barge_vendor[0].invoice_file,
              invoice_no: res.barge_demurrage_to_barge_vendor[0].invoice_no,
              invoice_date: res.barge_demurrage_to_barge_vendor[0].invoice_date,
              ppn: res.barge_demurrage_to_barge_vendor[0].ppn,
              ppn_date: res.barge_demurrage_to_barge_vendor[0].ppn_date,
              billing_currency:
                res.barge_demurrage_to_barge_vendor[0].billing_currency,
              dmr_to_bg_vend_barge_demurrage:
                res.barge_demurrage_to_barge_vendor[0]
                  .dmr_to_bg_vend_barge_demurrage,
              dmr_to_bg_vend_base_price:
                res.barge_demurrage_to_barge_vendor[0]
                  .dmr_to_bg_vend_base_price,
              dmr_to_bg_vend_ctc:
                res.barge_demurrage_to_barge_vendor[0].dmr_to_bg_vend_ctc,
              dmr_to_bg_vend_ctc_in_local_currency:
                res.barge_demurrage_to_barge_vendor[0]
                  .dmr_to_bg_vend_ctc_in_local_currency,
              dmr_to_bg_vend_currency_xchg_rate:
                res.barge_demurrage_to_barge_vendor[0]
                  .dmr_to_bg_vend_currency_xchg_rate,
              dmr_to_bg_vend_demurrage_days:
                res.barge_demurrage_to_barge_vendor[0]
                  .dmr_to_bg_vend_demurrage_days,
              dmr_to_bg_vend_demurrage_value:
                res.barge_demurrage_to_barge_vendor[0]
                  .dmr_to_bg_vend_demurrage_value,
              dmr_to_bg_vend_laycan_commence:
                res.barge_demurrage_to_barge_vendor[0]
                  .dmr_to_bg_vend_laycan_commence,
              dmr_to_bg_vend_pph23_account:
                res.barge_demurrage_to_barge_vendor[0]
                  .dmr_to_bg_vend_pph23_account,
              dmr_to_bg_vend_pph23_percent:
                res.barge_demurrage_to_barge_vendor[0]
                  .dmr_to_bg_vend_pph23_percent,
              dmr_to_bg_vend_pph23_value:
                res.barge_demurrage_to_barge_vendor[0]
                  .dmr_to_bg_vend_pph23_value,
              dmr_to_bg_vend_total:
                res.barge_demurrage_to_barge_vendor[0].dmr_to_bg_vend_total,
              dmr_to_bg_vend_vat_percent:
                res.barge_demurrage_to_barge_vendor[0]
                  .dmr_to_bg_vend_vat_percent,
              dmr_to_bg_vend_vat_value:
                res.barge_demurrage_to_barge_vendor[0].dmr_to_bg_vend_vat_value,
              barge_vendor_name:
                res.barge_demurrage_to_barge_vendor[0].barge_vendor_name,
              loadingJetty:
                res.barge_demurrage_to_barge_vendor[0].loading_jetty === null
                  ? ""
                  : res.barge_demurrage_to_barge_vendor[0].loading_jetty,
              completionTime:
                res.barge_demurrage_to_barge_vendor[0].completion_time === ""
                  ? null
                  : res.barge_demurrage_to_barge_vendor[0].completion_time,
              NORAnchorageDate:
                res.barge_demurrage_to_barge_vendor[0].nor_anchorage === ""
                  ? null
                  : res.barge_demurrage_to_barge_vendor[0].nor_anchorage,
              completionDischargeDate:
                res.barge_demurrage_to_barge_vendor[0].completion_discharge ===
                ""
                  ? null
                  : res.barge_demurrage_to_barge_vendor[0].completion_discharge,
              files: res.barge_demurrage_to_barge_vendor[0].files
                ? res.barge_demurrage_to_barge_vendor[0].files
                : [],
              timeAtAnchorage:
                days + " days, " + hours + " hours, " + minutes + " minutes",
              timeAtAnchorageDays: days,
              timeAtAnchorageHours: hours,
              timeAtAnchorageMinutes: minutes,
              dmr_to_supplier_detention_in_days: days,
            });
          } else {
            this.setState({
              isLoading: false,
            });
          }
        } else if (res.code === "601") {
          this.setState({
            snackBarErrorOpen: true,
            successMsg: res.message,
          });
        } else if (res.code === "607") {
          window.location.href = "/logout";
        }
      });
    }

    const get_barges = await api.get_barges_for_barge_fin_demurrage(
      loginUserID,
      idToken
    );
    if (get_barges.status >= 200 && get_barges.status < 300) {
      get_barges.json().then((res) => {
        if (res.code === "200") {
          const barges = res.barges.filter(
            (e) =>
              e.barge_vendor_demurrage_id === null ||
              e.barge_vendor_demurrage_id === barge_vendor_demurrage_id
          );
          this.setState({
            totalBarges: barges,
            isLoading: false,
          });
        } else if (res.code === "601") {
          this.setState({
            snackBarErrorOpen: true,
            errorMsg: res.message,
          });
        } else if (res.code === "607") {
          window.location.href = "/logout";
        }
      });
    }
  }

  onBargesCheck = (bargeId, checked, barge) => {
    if (checked) {
      this.setState(
        {
          selectedBargeId: bargeId,
          //isLoading: true
        },
        () => {
          //this.callingAsync(bargeId);
          let days = "0";
          let hours = "0";
          let minutes = "0";
          if (barge.time_at_anchorage_days) {
            days = barge.time_at_anchorage_days;
          }
          if (barge.time_at_anchorage_hours) {
            hours = barge.time_at_anchorage_hours;
          }
          if (barge.time_at_anchorage_minutes) {
            minutes = barge.time_at_anchorage_minutes;
          }
          this.setState({
            // sales_type: res.business_no_barge.sales_type,
            purchase_type: barge.purchase_type,
            //dmr_to_buyer_barge_currency: res.business_no_barge.barging_base_price_currency === null ? '0' : res.business_no_barge.barging_base_price_currency,
            barge_vendor_name: barge.barge_vendor_name,
            loadingJetty:
              barge.loading_jetty === null ? "" : barge.loading_jetty,
            completionTime:
              barge.completion_time === "" ? null : barge.completion_time,
            NORAnchorageDate:
              barge.nor_anchorage === "" ? null : barge.nor_anchorage,
            completionDischargeDate:
              barge.completion_discharge === ""
                ? null
                : barge.completion_discharge,
            timeAtAnchorage:
              days + " days, " + hours + " hours, " + minutes + " minutes",
            timeAtAnchorageDays: days,
            timeAtAnchorageHours: hours,
            timeAtAnchorageMinutes: minutes,
            dmr_to_supplier_detention_in_days: days,
          });
        }
      );
    } else {
      this.setState({
        selectedBargeId: null,
      });
    }
  };

  dmr_to_bg_vendor_calculations = (value) => {
    var dmr_to_bg_vend_barge_demurrage = this.state
      .dmr_to_bg_vend_barge_demurrage
      ? this.state.dmr_to_bg_vend_barge_demurrage
      : 1;
    var dmr_to_bg_vend_demurrage_days = this.state.dmr_to_bg_vend_demurrage_days
      ? this.state.dmr_to_bg_vend_demurrage_days
      : 0;
    var dmr_to_bg_vend_currency_xchg_rate = this.state
      .dmr_to_bg_vend_currency_xchg_rate
      ? this.state.dmr_to_bg_vend_currency_xchg_rate
      : 1;
    if (
      ((config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL") &&
        this.state.billing_currency === "USD") ||
      ((config.company === "SDAM" ||
        config.company === "SRK" ||
        config.company === "BTR") &&
        this.state.billing_currency === "IDR")
    ) {
      dmr_to_bg_vend_currency_xchg_rate = 1;
    }
    var dmr_to_bg_vend_demurrage_value = (
      (Number(dmr_to_bg_vend_barge_demurrage) *
        Number(dmr_to_bg_vend_demurrage_days)) /
      dmr_to_bg_vend_currency_xchg_rate
    ).toFixed(2);
    if (
      (config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL") &&
      this.state.billing_currency !== "USD"
    ) {
      dmr_to_bg_vend_demurrage_value = (
        (Number(dmr_to_bg_vend_barge_demurrage) *
          Number(dmr_to_bg_vend_demurrage_days)) /
        dmr_to_bg_vend_currency_xchg_rate
      ).toFixed(2);
    } else if (
      (config.company === "SDAM" ||
        config.company === "SRK" ||
        config.company === "BTR") &&
      this.state.billing_currency !== "IDR"
    ) {
      dmr_to_bg_vend_demurrage_value = (
        Number(dmr_to_bg_vend_barge_demurrage) *
        Number(dmr_to_bg_vend_demurrage_days) *
        Number(dmr_to_bg_vend_currency_xchg_rate)
      ).toFixed(2);
    }
    if (value) {
      dmr_to_bg_vend_demurrage_value = this.state.dmr_to_bg_vend_demurrage_value
        ? dmr_to_bg_vend_demurrage_value
        : 1;
    }
    let dmr_to_bg_vend_base_price = Number(
      dmr_to_bg_vend_demurrage_value
    ).toFixed(2);
    let dmr_to_bg_vend_vat_percent = 10;
    let dmr_to_bg_vend_vat_value = (
      (Number(dmr_to_bg_vend_base_price) * 10) /
      100
    ).toFixed(2);
    let dmr_to_bg_vend_pph23_percent = 2;
    let dmr_to_bg_vend_pph23_value = Number(
      (dmr_to_bg_vend_base_price * 2) / 100
    ).toFixed(0);
    let dmr_to_bg_vend_total = (
      Number(dmr_to_bg_vend_base_price) +
      Number(dmr_to_bg_vend_vat_value) -
      Number(dmr_to_bg_vend_pph23_value)
    ).toFixed(2);
    if (config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL") {
      dmr_to_bg_vend_total = (
        Number(dmr_to_bg_vend_base_price) + Number(dmr_to_bg_vend_vat_value)
      ).toFixed(2);
    }
    let dmr_to_bg_vend_ctc = Number(
      Number(dmr_to_bg_vend_base_price) + Number(dmr_to_bg_vend_vat_value)
    ).toFixed(2);

    this.setState({
      dmr_to_bg_vend_demurrage_value: dmr_to_bg_vend_demurrage_value,
      dmr_to_bg_vend_base_price: dmr_to_bg_vend_base_price,
      dmr_to_bg_vend_vat_percent: dmr_to_bg_vend_vat_percent,
      dmr_to_bg_vend_vat_value: dmr_to_bg_vend_vat_value,
      dmr_to_bg_vend_pph23_percent: dmr_to_bg_vend_pph23_percent,
      dmr_to_bg_vend_pph23_value: dmr_to_bg_vend_pph23_value,
      dmr_to_bg_vend_total: dmr_to_bg_vend_total,
      dmr_to_bg_vend_ctc: dmr_to_bg_vend_ctc,
    });

    if (this.state.dmr_to_bg_vend_pph23_account === "SDAM") {
      let dmr_to_bg_vend_base_price = Number(
        (dmr_to_bg_vend_demurrage_value * 100) / 98
      ).toFixed(2);
      let dmr_to_bg_vend_pph23_percent = 2;
      let dmr_to_bg_vend_pph23_value = Number(
        ((dmr_to_bg_vend_base_price * 2) / 100).toFixed(2)
      );
      let dmr_to_bg_vend_total = Number(dmr_to_bg_vend_demurrage_value).toFixed(
        2
      );
      let dmr_to_bg_vend_ctc = Number(dmr_to_bg_vend_base_price).toFixed(2);

      this.setState({
        dmr_to_bg_vend_base_price: dmr_to_bg_vend_base_price,
        dmr_to_bg_vend_pph23_percent: dmr_to_bg_vend_pph23_percent,
        dmr_to_bg_vend_pph23_value: dmr_to_bg_vend_pph23_value,
        dmr_to_bg_vend_total: dmr_to_bg_vend_total,
        dmr_to_bg_vend_ctc: dmr_to_bg_vend_ctc,
        dmr_to_bg_vend_vat_percent: "",
        dmr_to_bg_vend_vat_value: "",
      });
    }
  };

  nextStepHandler = () => {
    if (this.state.activeStep === 0) {
      if (!this.state.selectedBargeId) {
        this.setState({
          errorMsg: "Please Select Barge",
          snackBarErrorOpen: true,
        });
        return;
      }
    }
    this.setState({
      activeStep: this.state.activeStep + 1,
    });
  };

  previousStepHandler = () => {
    this.setState({
      activeStep: this.state.activeStep - 1,
    });
  };

  toLocaleString(value) {
    return value ? Number(value).toLocaleString() : 0;
  }

  submitHandler = () => {
    var {
      invoice_no,
      invoice_date,
      ppn,
      ppn_date,
      dmr_to_bg_vend_laycan_commence,
      dmr_to_bg_vend_barge_demurrage,
      billing_currency,
      dmr_to_bg_vend_demurrage_days,
      dmr_to_bg_vend_demurrage_value,

      dmr_to_bg_vend_currency_xchg_rate,
      dmr_to_bg_vend_pph23_account,
      dmr_to_bg_vend_base_price,
      dmr_to_bg_vend_vat_percent,
      dmr_to_bg_vend_vat_value,
      dmr_to_bg_vend_pph23_percent,
      dmr_to_bg_vend_pph23_value,
      dmr_to_bg_vend_total,
      dmr_to_bg_vend_ctc,
      dmr_to_bg_vend_ctc_in_local_currency,
    } = this.state;

    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    /**
     *  Checking Mandatory files
     */
    var missing_fields = 0;
    let files_missing = 0;
    var files_data = this.state.files;
    for (var obj of files_data) {
      if (obj.file_type !== "" || obj.file_content !== "") {
        if (obj.file_content !== "") {
          if (obj.file_description === "") {
            missing_fields++;
            obj.file_description_error = true;
          } else {
            obj.file_description = obj.file_description.toUpperCase();
          }
        } else if (obj.file_description !== "") {
          if (obj.file_content === "") {
            files_missing++;
          }
        }
      }
    }
    this.setState({
      files: files_data,
    });

    if (missing_fields > 0) {
      this.setState({
        snackBarErrorOpen: true,
        isLoading: false,
        errorMsg: "Please fill mandatory fields.",
      });
      return;
    }
    if (files_missing > 0) {
      this.setState({
        snackBarErrorOpen: true,
        isLoading: false,
        errorMsg: "Please Select file.",
      });
      return;
    }
    var existing_file_ids = [];
    const existing_files = files_data.filter((e) => e.id);
    for (var j of existing_files) {
      existing_file_ids.push(j.id);
    }
    const new_files = files_data.filter((e) => !e.id);
    const content_files = new_files.filter((e) => e.file_content !== "");
    const costing_file = this.state.costing_files?.filter(
      (e) => e.file_content !== "" && e.column === "invoice_file"
    );
    if (this.state.selectedBargeId) {
      const barge_id = this.state.selectedBargeId;
      let comboID = this.props.comboID;
      const barge_vendor_demurrage_id = comboID.split("$#")[0];

      api
        .update_barge_demurrage_to_barge_vendor(
          loginUserID,
          idToken,
          barge_vendor_demurrage_id,
          barge_id,
          invoice_no,
          invoice_date,
          ppn,
          ppn_date,
          dmr_to_bg_vend_laycan_commence,
          dmr_to_bg_vend_barge_demurrage,
          billing_currency,
          dmr_to_bg_vend_demurrage_days,
          dmr_to_bg_vend_demurrage_value,
          dmr_to_bg_vend_currency_xchg_rate,
          dmr_to_bg_vend_pph23_account,
          dmr_to_bg_vend_base_price,
          dmr_to_bg_vend_vat_percent,
          dmr_to_bg_vend_vat_value,
          dmr_to_bg_vend_pph23_percent,
          dmr_to_bg_vend_pph23_value,
          dmr_to_bg_vend_total,
          dmr_to_bg_vend_ctc,
          dmr_to_bg_vend_ctc_in_local_currency,
          existing_file_ids,
          content_files,
          costing_file
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                this.setState(
                  {
                    snackBarSuccessOpen: true,
                    successMsg: res.message,
                  },
                  () => {
                    this.props.onUpdateCosting(true);
                  }
                );
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  snackBarErrorOpen: true,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    } else {
      alert("Please select barge");
    }
  };

  render() {
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      const re = /^\d*(\.\d{0,10})?$/;
      const pr = /^\d*(\.\d{0,4})?$/;
      return (
        <div
          className="container bg-white p-0"
          style={{ minHeight: "100vh", width: 850 }}
        >
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <div className="row border-bottom">
            <div className="col-lg-12 p-2">
              {/* <h5 style={{ padding: '15px', marginBottom: 0 }}>Barge Purchase Financial Demurrage</h5> */}
              {this.props.demurrageData && (
                <div className="table-responsive">
                  <table className="table">
                    <thead style={{ backgroundColor: "rgb(249, 249, 249)" }}>
                      <tr>
                        <th nowrap="true">Barge ID</th>
                        <th nowrap="true">Barge Nomination</th>
                        <th nowrap="true">Barge Vendor</th>
                        <th nowrap="true" className="text-right">
                          Total Payable
                        </th>
                        <th nowrap="true" className="text-right">
                          Paid
                        </th>
                        <th nowrap="true" className="text-right">
                          Remaining
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{pad(this.props.demurrageData.barge_id)}</td>
                        <td nowrap="true">
                          {this.props.demurrageData.barge_nomination}
                        </td>
                        <td nowrap="true">
                          {this.props.demurrageData.barge_vendor_name}
                        </td>
                        <td className="text-right" nowrap="true">
                          {this.toLocaleString(
                            this.props.demurrageData.total_payable
                          )}
                        </td>
                        <td className="text-right" nowrap="true">
                          {this.toLocaleString(this.props.demurrageData.paid)}
                        </td>
                        <td className="text-right" nowrap="true">
                          {this.toLocaleString(
                            this.props.demurrageData.Remaining_amount
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
          <div className="drawer-section">
            <div className="card p-3">
              <div
                className="card-body p-0"
                style={{ backgroundColor: "rgba(25,118,210,.01)" }}
                id="accordion"
              >
                {this.state.activeStep === 0 && (
                  <div>
                    {!this.props.updateDocuments && (
                      <div>
                        <div className="row mb-3">
                          <div className="col-lg-4 p-0 mb-1">
                            <label className="form_label mb-1">Vendor</label>
                            <div className="">
                              {this.state.barge_vendor_name}
                            </div>
                          </div>
                          <div className="col-lg-4 pr-0 mb-1">
                            <label className="form_label mb-1">
                              Loading Jetty
                            </label>
                            <div className="">{this.state.loadingJetty}</div>
                          </div>
                          <div className="col-lg-4 pr-0 mb-1">
                            <label className="form_label mb-1">
                              Time at Anchorage
                            </label>
                            <div className="">{this.state.timeAtAnchorage}</div>
                          </div>
                          {this.state.sales_type !== "FOB Barge" && (
                            <div className="col-lg-4 p-0 mb-1">
                              <label className="form_label mb-1">
                                Completion time
                              </label>
                              <div className="">
                                {this.state.completionTime
                                  ? localDateFormate(this.state.completionTime)
                                  : ""}
                              </div>
                            </div>
                          )}
                          {this.state.sales_type !== "FOB Barge" && (
                            <div className="col-lg-4 p-0 mb-1">
                              <label className="form_label mb-1">
                                NOR Anchorage
                              </label>
                              <div className="">
                                {this.state.NORAnchorageDate
                                  ? localDateFormate(
                                      this.state.NORAnchorageDate
                                    )
                                  : ""}
                              </div>
                            </div>
                          )}
                          {this.state.sales_type !== "FOB Barge" && (
                            <div className="col-lg-4 p-0 mb-1">
                              <label className="form_label mb-1">
                                Completion Discharge
                              </label>
                              <div className="">
                                {this.state.completionDischargeDate
                                  ? localDateFormate(
                                      this.state.completionDischargeDate
                                    )
                                  : ""}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="row">
                          <div className="col-lg-5 pl-0">
                            <div className="row">
                              {this.state.sales_type !== "FOB Barge" && (
                                <div className="col-lg-12 p-0">
                                  <label className="form_label mb-0">
                                    Laycan Commencement
                                  </label>
                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DateTimePicker
                                      autoOk
                                      hideTabs
                                      inputVariant="outlined"
                                      variant="inline"
                                      margin="dense"
                                      fullWidth
                                      ampm={false}
                                      //label="Laycan Commencement"
                                      format="dd/MM/yyyy HH:mm"
                                      value={
                                        this.state
                                          .dmr_to_bg_vend_laycan_commence
                                      }
                                      onChange={(date) => {
                                        this.setState({
                                          dmr_to_bg_vend_laycan_commence: date,
                                        });
                                      }}
                                      inputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <IconButton>
                                              <EventIcon />
                                            </IconButton>
                                          </InputAdornment>
                                        ),
                                        className: "pl-0",
                                      }}
                                    />
                                  </MuiPickersUtilsProvider>
                                </div>
                              )}
                              <div className="col-lg-12 p-0">
                                <label className="form_label mb-0">
                                  Barge Demurrage
                                </label>
                                <TextField
                                  name="dmr_to_bg_vend_barge_demurrage"
                                  variant="outlined"
                                  margin="dense"
                                  placeholder="Barge Demurrage"
                                  value={
                                    this.state.dmr_to_bg_vend_barge_demurrage
                                  }
                                  type="number"
                                  onChange={(e) => {
                                    if (
                                      e.target.value === "" ||
                                      pr.test(e.target.value)
                                    ) {
                                      let value = e.target.value;
                                      let dmr_to_bg_vend_demurrage_value = (
                                        Number(value) *
                                        Number(
                                          this.state
                                            .dmr_to_bg_vend_demurrage_days
                                        )
                                      ).toFixed(2);
                                      this.setState(
                                        {
                                          dmr_to_bg_vend_barge_demurrage: value,
                                          dmr_to_bg_vend_demurrage_value:
                                            dmr_to_bg_vend_demurrage_value,
                                        },
                                        () =>
                                          this.dmr_to_bg_vendor_calculations()
                                      );
                                    }
                                  }}
                                  className="rounded-left"
                                  style={{
                                    borderTopRightRadius: 0,
                                    borderBottomRightRadius: 0,
                                  }}
                                  fullWidth
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment
                                        // variant="outlined"
                                        position="end"
                                        style={{
                                          marginRight: "-14px",
                                          marginTop: "-4px",
                                          width: "50%",
                                        }}
                                      >
                                        <TextField
                                          name="billing_currency"
                                          variant="outlined"
                                          margin="dense"
                                          value={this.state.billing_currency}
                                          fullWidth
                                          onChange={(e) => {
                                            this.setState(
                                              {
                                                billing_currency:
                                                  e.target.value,
                                              },
                                              () => {
                                                this.dmr_to_bg_vendor_calculations();
                                              }
                                            );
                                          }}
                                          select
                                        >
                                          {this.state.currencyData}
                                        </TextField>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </div>
                              <div className="col-lg-12 p-0">
                                <label className="form_label mb-0">
                                  Demurrage in days
                                </label>
                                <TextField
                                  name="dmr_to_bg_vend_demurrage_days"
                                  variant="outlined"
                                  margin="dense"
                                  placeholder="Demurrage in days"
                                  fullWidth
                                  type="number"
                                  value={
                                    this.state.dmr_to_bg_vend_demurrage_days
                                  }
                                  onChange={(e) => {
                                    if (
                                      re.test(e.target.value) ||
                                      e.target.value === ""
                                    ) {
                                      let value = e.target.value;
                                      let dmr_to_bg_vend_demurrage_value = (
                                        Number(e.target.value) *
                                        Number(
                                          this.state
                                            .dmr_to_bg_vend_barge_demurrage
                                        )
                                      ).toFixed(3);
                                      this.setState(
                                        {
                                          dmr_to_bg_vend_demurrage_days: value,
                                          dmr_to_bg_vend_demurrage_value:
                                            dmr_to_bg_vend_demurrage_value,
                                        },
                                        () =>
                                          this.dmr_to_bg_vendor_calculations()
                                      );
                                    }
                                  }}
                                />
                              </div>

                              {(config.company === "SRPL" || config.company === "AVS"  ||
                                config.company === "PRPL") &&
                                this.state.billing_currency !== "USD" &&
                                this.state.billing_currency !== "0" && (
                                  <div className="col-lg-12 p-0">
                                    <label className="form_label mb-0">
                                      Exchange rate USD to{" "}
                                      {this.state.billing_currency}
                                    </label>
                                    <TextField
                                      name="dmr_to_bg_vend_currency_xchg_rate"
                                      margin="dense"
                                      variant="outlined"
                                      fullWidth
                                      value={
                                        this.state
                                          .dmr_to_bg_vend_currency_xchg_rate
                                      }
                                      type="number"
                                      inputProps={{
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                        },
                                      }}
                                      placeholder="Exchange rate"
                                      onChange={(e) => {
                                        if (
                                          e.target.value === "" ||
                                          re.test(e.target.value)
                                        ) {
                                          this.setState(
                                            {
                                              dmr_to_bg_vend_currency_xchg_rate:
                                                e.target.value,
                                            },
                                            () =>
                                              this.dmr_to_bg_vendor_calculations()
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                )}

                              {!(
                                config.company === "SRPL" || config.company === "AVS"  ||
                                config.company === "PRPL"
                              ) &&
                                this.state.billing_currency !== "IDR" &&
                                this.state.billing_currency &&
                                this.state.billing_currency !== "0" && (
                                  <div className="col-lg-12 p-0">
                                    <label className="form_label mb-0">
                                      Exchange rate{" "}
                                      {this.state.billing_currency} to IDR
                                    </label>
                                    <TextField
                                      name="dmr_to_bg_vend_currency_xchg_rate"
                                      margin="dense"
                                      variant="outlined"
                                      fullWidth
                                      value={
                                        this.state
                                          .dmr_to_bg_vend_currency_xchg_rate
                                      }
                                      type="number"
                                      inputProps={{
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                        },
                                      }}
                                      placeholder="Exchange rate"
                                      onChange={(e) => {
                                        if (
                                          e.target.value === "" ||
                                          re.test(e.target.value)
                                        ) {
                                          this.setState(
                                            {
                                              dmr_to_bg_vend_currency_xchg_rate:
                                                e.target.value,
                                            },
                                            () =>
                                              this.dmr_to_bg_vendor_calculations()
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                )}
                              <div className="col-lg-12 p-0">
                                <label className="form_label mb-0">
                                  Demurrage incurred
                                </label>
                                <TextField
                                  name="dmr_to_bg_vend_demurrage_value"
                                  margin="dense"
                                  variant="outlined"
                                  fullWidth
                                  inputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: "12px",
                                    },
                                  }}
                                  placeholder="Demurrage incurred"
                                  value={
                                    this.state.dmr_to_bg_vend_demurrage_value
                                  }
                                  onChange={(e) => {
                                    if (
                                      e.target.value === "" ||
                                      pr.test(e.target.value)
                                    ) {
                                      this.setState(
                                        {
                                          dmr_to_bg_vend_demurrage_value:
                                            e.target.value,
                                        },
                                        () =>
                                          this.dmr_to_bg_vendor_calculations(
                                            "dmr_to_bg_vend_demurrage_value"
                                          )
                                      );
                                    }
                                  }}
                                />
                              </div>
                              {!(
                                config.company === "SRPL" || config.company === "AVS"  ||
                                config.company === "PRPL"
                              ) && (
                                <div className="col-lg-12 p-0">
                                  <label className="form_label mb-0">
                                    PPH23 Account
                                  </label>
                                  <TextField
                                    name="dmr_to_bg_vend_pph23_account"
                                    margin="dense"
                                    variant="outlined"
                                    value={
                                      this.state.dmr_to_bg_vend_pph23_account
                                    }
                                    select
                                    inputProps={{
                                      style: {
                                        fontFamily: "Poppins",
                                        fontSize: "14px",
                                      },
                                    }}
                                    fullWidth
                                    onChange={(e) => {
                                      this.setState(
                                        {
                                          dmr_to_bg_vend_pph23_account:
                                            e.target.value,
                                        },
                                        () =>
                                          this.dmr_to_bg_vendor_calculations()
                                      );
                                    }}
                                  >
                                    <MenuItem value="0">Please Select</MenuItem>
                                    <MenuItem value="Barge Vendor">
                                      Barge Vendor
                                    </MenuItem>
                                    <MenuItem value="SDAM">SDAM</MenuItem>
                                  </TextField>
                                </div>
                              )}
                            </div>
                          </div>
                          <div
                            className="col-lg-7 p-2 rounded"
                            style={{ backgroundColor: "#f7f3f0" }}
                          >
                            <div className="row">
                              <div className="row col-lg-12 p-0">
                                <h6>Actual Demurrage</h6>
                              </div>
                              {(config.company === "SRPL" || config.company === "AVS"  ||
                                config.company === "PRPL" ||
                                ((config.company === "SDAM" ||
                                  config.company === "SRK" ||
                                  config.company === "BTR") &&
                                  this.state.dmr_to_bg_vend_pph23_account ===
                                    "Barge Vendor")) && (
                                <div className="row">
                                  <div className="col-lg-6 mb-2">
                                    <label
                                      className="form_label mb-0"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Base price
                                    </label>
                                    <TextField
                                      name="dmr_to_bg_vend_base_price"
                                      margin="dense"
                                      type="number"
                                      inputProps={{
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                        },
                                      }}
                                      variant="outlined"
                                      value={
                                        this.state.dmr_to_bg_vend_base_price
                                      }
                                      onChange={(e) => {
                                        if (
                                          e.target.value === "" ||
                                          pr.test(e.target.value)
                                        ) {
                                          var dmr_to_bg_vend_vat_value = (
                                            (Number(e.target.value) * 10) /
                                            100
                                          ).toFixed(2);
                                          let dmr_to_bg_vend_pph23_value =
                                            Number(
                                              (e.target.value * 2) / 100
                                            ).toFixed(2);
                                          let dmr_to_bg_vend_total = (
                                            Number(e.target.value) +
                                            Number(dmr_to_bg_vend_vat_value) -
                                            Number(dmr_to_bg_vend_pph23_value)
                                          ).toFixed(2);
                                          let dmr_to_bg_vend_ctc = (
                                            Number(e.target.value) +
                                            Number(dmr_to_bg_vend_vat_value)
                                          ).toFixed(2);
                                          this.setState({
                                            [e.target.name]: e.target.value,
                                            dmr_to_bg_vend_vat_value:
                                              dmr_to_bg_vend_vat_value,
                                            dmr_to_bg_vend_pph23_value:
                                              dmr_to_bg_vend_pph23_value,
                                            dmr_to_bg_vend_total:
                                              dmr_to_bg_vend_total,
                                            dmr_to_bg_vend_ctc:
                                              dmr_to_bg_vend_ctc,
                                          });
                                        }
                                      }}
                                      fullWidth
                                    />
                                  </div>
                                  <div className="col-lg-6 mb-2">
                                    <label
                                      className="form_label mb-2"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      VAT %
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {this.state.dmr_to_bg_vend_vat_percent
                                        ? this.state.dmr_to_bg_vend_vat_percent
                                        : "-"}
                                    </div>
                                  </div>

                                  <div className="col-lg-6 mb-2">
                                    <label
                                      className="form_label mb-0"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      VAT
                                    </label>
                                    <TextField
                                      name="dmr_to_bg_vend_vat_value"
                                      margin="dense"
                                      type="number"
                                      inputProps={{
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                        },
                                      }}
                                      variant="outlined"
                                      value={
                                        this.state.dmr_to_bg_vend_vat_value
                                      }
                                      onChange={(e) => {
                                        if (
                                          e.target.value === "" ||
                                          pr.test(e.target.value)
                                        ) {
                                          let dmr_to_bg_vend_total = (
                                            Number(
                                              this.state
                                                .dmr_to_bg_vend_base_price
                                            ) +
                                            Number(e.target.value) -
                                            Number(
                                              this.state
                                                .dmr_to_bg_vend_pph23_value
                                            )
                                          ).toFixed(2);
                                          let dmr_to_bg_vend_ctc = (
                                            Number(
                                              this.state.int_dmr_base_price
                                            ) + Number(e.target.value)
                                          ).toFixed(2);
                                          this.setState({
                                            [e.target.name]: e.target.value,
                                            dmr_to_bg_vend_total:
                                              dmr_to_bg_vend_total,
                                            dmr_to_bg_vend_ctc:
                                              dmr_to_bg_vend_ctc,
                                          });
                                        }
                                      }}
                                      fullWidth
                                    />
                                  </div>
                                  {!(
                                    config.company === "SRPL" || config.company === "AVS"  ||
                                    config.company === "PRPL"
                                  ) &&
                                    this.state.dmr_to_bg_vend_pph23_account ===
                                      "Barge Vendor" && (
                                      <div className="col-lg-6 mb-2">
                                        <label
                                          className="form_label mb-0"
                                          style={{
                                            color: "rgba(72, 59, 52, 0.7)",
                                          }}
                                        >
                                          Includes PPH23 @{" "}
                                          {this.state
                                            .dmr_to_bg_vend_pph23_percent
                                            ? this.toLocaleString(
                                                this.state
                                                  .dmr_to_bg_vend_pph23_percent
                                              )
                                            : 0}
                                          %
                                        </label>
                                        {/* <div className='contract_display_header_value' style={{ color: '#42352d' }}>{this.state.dmr_to_bg_vend_pph23_value ? this.toLocaleString(this.state.dmr_to_bg_vend_pph23_value) : null}</div> */}
                                        <TextField
                                          name="dmr_to_bg_vend_pph23_value"
                                          margin="dense"
                                          type="number"
                                          variant="outlined"
                                          inputProps={{
                                            style: {
                                              fontFamily: "Poppins",
                                              fontSize: "14px",
                                            },
                                          }}
                                          value={
                                            this.state
                                              .dmr_to_bg_vend_pph23_value
                                          }
                                          onChange={(e) => {
                                            if (
                                              e.target.value === "" ||
                                              pr.test(e.target.value)
                                            ) {
                                              let dmr_to_bg_vend_total = (
                                                Number(
                                                  this.state
                                                    .dmr_to_bg_vend_base_price
                                                ) +
                                                Number(
                                                  this.state
                                                    .dmr_to_bg_vend_vat_value
                                                ) -
                                                Number(e.target.value)
                                              ).toFixed(2);
                                              let dmr_to_bg_vend_ctc = (
                                                Number(
                                                  this.state
                                                    .dmr_to_bg_vend_base_price
                                                ) +
                                                Number(
                                                  this.state
                                                    .dmr_to_bg_vend_vat_value
                                                )
                                              ).toFixed(2);
                                              this.setState({
                                                [e.target.name]: e.target.value,
                                                dmr_to_bg_vend_total:
                                                  dmr_to_bg_vend_total,
                                                dmr_to_bg_vend_ctc:
                                                  dmr_to_bg_vend_ctc,
                                              });
                                            }
                                          }}
                                          fullWidth
                                        />
                                      </div>
                                    )}
                                  <div className="col-lg-6 ">
                                    <label
                                      className="form_label mb-0"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Payable
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {this.state.dmr_to_bg_vend_total
                                        ? this.toLocaleString(
                                            this.state.dmr_to_bg_vend_total
                                          )
                                        : 0}{" "}
                                      {config.company === "SRPL" || config.company === "AVS"  ||
                                      config.company === "PRPL"
                                        ? "USD"
                                        : "IDR"}
                                    </div>
                                  </div>
                                  <div className="col-lg-6 ">
                                    <label
                                      className="form_label mb-0"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Cost to Company
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {this.state.dmr_to_bg_vend_ctc
                                        ? this.toLocaleString(
                                            this.state.dmr_to_bg_vend_ctc
                                          )
                                        : 0}{" "}
                                      {config.company === "SRPL" || config.company === "AVS"  ||
                                      config.company === "PRPL"
                                        ? "USD"
                                        : "IDR"}
                                    </div>
                                  </div>
                                </div>
                              )}

                              {this.state.dmr_to_bg_vend_pph23_account ===
                                "SDAM" && (
                                <div>
                                  <div className="row">
                                    <div className="col-lg-6 mb-2">
                                      <label
                                        className="form_label mb-0"
                                        style={{
                                          color: "rgba(72, 59, 52, 0.7)",
                                        }}
                                      >
                                        Base price
                                      </label>
                                      <div
                                        className="contract_display_header_value"
                                        style={{ color: "#42352d" }}
                                      >
                                        {this.state.dmr_to_bg_vend_base_price
                                          ? this.toLocaleString(
                                              this.state
                                                .dmr_to_bg_vend_base_price
                                            )
                                          : "-"}
                                      </div>
                                    </div>

                                    <div className="col-lg-6 mb-3">
                                      <label
                                        className="form_label mb-0"
                                        style={{
                                          color: "rgba(72, 59, 52, 0.7)",
                                        }}
                                      >
                                        Includes PPH23 @{" "}
                                        {this.state.dmr_to_bg_vend_pph23_percent
                                          ? this.toLocaleString(
                                              this.state
                                                .dmr_to_bg_vend_pph23_percent
                                            )
                                          : 0}
                                        %
                                      </label>
                                      <div
                                        className="contract_display_header_value"
                                        style={{ color: "#42352d" }}
                                      >
                                        {this.state.dmr_to_bg_vend_pph23_value
                                          ? this.toLocaleString(
                                              this.state
                                                .dmr_to_bg_vend_pph23_value
                                            )
                                          : null}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-lg-6 ">
                                      <label
                                        className="form_label mb-0"
                                        style={{
                                          color: "rgba(72, 59, 52, 0.7)",
                                        }}
                                      >
                                        Payable to Vendor
                                      </label>
                                      {/* <div className='contract_display_header_value' style={{ color: '#42352d' }}>{this.state.barge_vendor_total_value ? this.toLocaleString(this.state.barge_vendor_total_value) : 0} {(config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL") ? "USD" : "IDR"}</div> */}
                                      <TextField
                                        name="dmr_to_bg_vend_total"
                                        margin="dense"
                                        type="number"
                                        inputProps={{
                                          style: {
                                            fontFamily: "Poppins",
                                            fontSize: "14px",
                                          },
                                        }}
                                        variant="outlined"
                                        value={this.state.dmr_to_bg_vend_total}
                                        onChange={(e) => {
                                          if (
                                            e.target.value === "" ||
                                            pr.test(e.target.value)
                                          ) {
                                            let dmr_to_bg_vend_base_price =
                                              Number(
                                                (e.target.value * 100) / 98
                                              ).toFixed(2);
                                            let dmr_to_bg_vend_pph23_value =
                                              Number(
                                                (dmr_to_bg_vend_base_price *
                                                  2) /
                                                  100
                                              ).toFixed(2);
                                            let dmr_to_bg_vend_ctc =
                                              dmr_to_bg_vend_base_price;
                                            this.setState({
                                              [e.target.name]: e.target.value,
                                              dmr_to_bg_vend_base_price:
                                                dmr_to_bg_vend_base_price,
                                              dmr_to_bg_vend_pph23_value:
                                                dmr_to_bg_vend_pph23_value,
                                              SDAM_dmr_to_bg_vend_ctc:
                                                dmr_to_bg_vend_ctc,
                                            });
                                          }
                                        }}
                                        fullWidth
                                      />
                                    </div>
                                    <div className="col-lg-6 ">
                                      <label
                                        className="form_label mb-0"
                                        style={{
                                          color: "rgba(72, 59, 52, 0.7)",
                                        }}
                                      >
                                        Cost to Company
                                      </label>
                                      <div
                                        className="contract_display_header_value"
                                        style={{ color: "#42352d" }}
                                      >
                                        {this.state.dmr_to_bg_vend_ctc
                                          ? this.toLocaleString(
                                              this.state.dmr_to_bg_vend_ctc
                                            )
                                          : 0}{" "}
                                        {config.company === "SRPL" || config.company === "AVS"  ||
                                        config.company === "PRPL"
                                          ? "USD"
                                          : "IDR"}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {(this.state.features.indexOf("530") >= 0 ||
                      this.state.features.indexOf("97") >= 0) && (
                      <>
                        <div className="card p-0">
                          <FileUpload
                            files={this.state.files}
                            type="Costings"
                            invoice_file={this.state.invoice_file}
                            feature_name="Barge_Demurrage_To_Barge_Vendor"
                            callbackFn={(data, mandatoryFiles) => {
                              this.setState({
                                files: data,
                                costing_files: mandatoryFiles,
                              });
                            }}
                          />
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <footer className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button btn-light"
              name="submit"
              onClick={
                this.state.activeStep === 0
                  ? () => this.props.returnCallbackViewPage(true)
                  : this.previousStepHandler
              }
            >
              {this.state.activeStep === 0 ? "CANCEL" : "PREVIOUS"}
            </button>
            <button
              type="button"
              className={
                this.state.activeStep === 0
                  ? "drawer_text drawer_btn_success"
                  : "btn next_button"
              }
              name="Back"
              onClick={
                this.state.activeStep === 0
                  ? this.submitHandler
                  : this.nextStepHandler
              }
            >
              {this.state.activeStep === 0 ? "UPDATE" : "NEXT STEP"}
            </button>
          </footer>
        </div>
      );
    }
  }
}
