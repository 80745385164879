import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Tooltip
} from "@material-ui/core";
import Slide from '@material-ui/core/Slide';
import config from '../../config/config';
import CookieHandler from './CookieHandler';
import api from '../../api/api';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const DeletePayments = ({ data, table, callbackFn }) => {
  // console.log(data, table);
  const [dialog, setDialog] = React.useState(false)

  const Cookie = new CookieHandler();

  const deletePaymentsHandler = () => {
    const { id } = data || {};
    let request = {
      login_user_id: Cookie.getCookie('loginUserId'),
      idtoken: Cookie.getIdTokenCookie(),
      payment_id: id,
      payment_table_name: table
    }
    if(table) {

      api.delete_payment_postings(request)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === '200') {
                setDialog(false);
                callbackFn()
              }
              else {
                alert(res);
              }
            })
          }
        })
    }
    else {alert("invalid data")}

  }
  return (
    <>
      <Tooltip title="Delete" arrow>
        <i
          className="fa fa-trash-o"
          style={{
            fontSize: 20,
            color: config.themeColor,
            cursor: "pointer",
          }}
          onClick={() => setDialog(true)}
        ></i>
      </Tooltip>
      <Dialog
        open={dialog}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="responsive-dialog-title" style={{ backgroundColor: "#f1f1f1", width: 400 }}>
          <h6
            className="modal-title"
          >
            {" "}
            <i
              className="fa fa-exclamation-triangle"
              aria-hidden="true"
              style={{ color: "#e0a51e", marginBottom: "5px" }}
            ></i>
            &emsp; Are you sure to delete ?
          </h6>
        </DialogTitle>
        <DialogActions>
          <button
            className="btn btn-danger mr-3"
            onClick={deletePaymentsHandler}
          >
            Delete
          </button>
          <button className="btn btn-secondary"
            onClick={() => setDialog(false)}
          >
            Cancel
          </button>
        </DialogActions>
      </Dialog>
    </>

  )
}

export default DeletePayments;