import React,{Component} from 'react';
import CookieHandler from './CookieHandler';

 export default class Logout extends Component
{
  constructor(props)
  {
    super(props);
    this.Cookie = new CookieHandler();
  }
  componentDidMount() {
    this.Cookie.removeIdTokenCookie();
    this.Cookie.removeCookie();
    this.props.history.replace('/');
  }

  render()
  {
    return(
      <div></div>
    );
  }
}