import React, { Component } from "react";

import { MenuItem, TextField, Snackbar } from "@material-ui/core";
import CookieHandler from "../../common/CookieHandler";
import AddCircle from "@material-ui/icons/AddCircle";

import config from "../../../config/config";
import Header from "../../common/Header";
import SideBar from "../../common/SideBar";
import api from "../../../api/api";
import { Alert } from "@material-ui/lab";
import FileUpload from "../../common/FileUpload";
import Loader from "../../common/Loader";

export default class Customer extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      companyName: "0",
      customerType: "0",
      otherCustomer: "",
      customerName: "",
      customerNo: "",
      customerAddress: "",
      customerCountry: "0",
      textAreaMaxCount: 2000,
      countries: [],
      // customerContactDetails :[{s_no:"1", contactPsn: "", phone: "", handPhone: "", email: "", emailError : false}],
      customerContactDetails: [],
      customerKycDetails: [
        {
          s_no: "1",
          bankAcHolderName: "",
          bankName: "",
          address: "",
          bankACNo: "",
          currency: "0",
          swiftCode: "",
          IBAN_Num: "",
          currencyError: false,
        },
      ],
      taxId: "",
      shareDocLink: "",
      customerTypeError: false,
      customerNameError: false,
      otherCustomerError: false,
      customerAddressError: false,
      customerCountryError: false,
      newCurrency: "",
      currencyData: [],
      clickedCurrency: "",
      errorMessage: "",
      successMessage: "",
      isLoading: true,
      activeStep: 0,
      files: [
        {
          file_description: "",
          file_name: "",
          file_extension: "",
          file_content: "",
          feature_name: "Customer",
        },
      ],
      features: [],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    this.setState({ features: features ? features : [] });
    // for get_cuurrencies list
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    await api.getCurrencies(loginUserID, idToken).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
          if (res.code === "200") {
            var currencies = [
              <MenuItem value="0" key={-1}>
                {" "}
                Please Select
              </MenuItem>,
            ];
            res.Currency.forEach((item) => {
              currencies.push(
                <MenuItem value={item.currency} key={[item.id]}>
                  {item.currency}
                </MenuItem>
              );
            });
            this.setState({
              currencyData: currencies,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });

    await api.getCountries(loginUserID, idToken).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.idtoken) this.Cookie.setCookie(res.idtoken);
          if (res.code === "200") {
            const Country = [
              <MenuItem value="0" disabled key={0}>
                Please Select
              </MenuItem>,
            ];
            res.countries.forEach((item) => {
              Country.push(
                <MenuItem value={item.country_name} key={item.id}>
                  {item.country_name}
                </MenuItem>
              );
            });
            this.setState({
              countries: Country,
              isLoading: false,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });

    document.title = config.documentTitle + "Add Customer";
  }

  handleText = (e) => {
    if (e.target.name === "customerType") {
      this.setState({
        [e.target.name]: e.target.value,
        customerTypeError: false,
      });
    } else if (e.target.name === "customerName") {
      this.setState({
        [e.target.name]: e.target.value,
        customerNameError: false,
      });
    } else if (e.target.name === "otherCustomer") {
      this.setState({
        [e.target.name]: e.target.value,
        otherCustomerError: false,
      });
    } else if (e.target.name === "customerAddress") {
      if (e.target.value.length <= this.state.textAreaMaxCount) {
        this.setState({
          [e.target.name]: e.target.value,
          customerAddressError: false,
        });
      }
    } else if (e.target.name === "customerCountry") {
      this.setState({
        [e.target.name]: e.target.value,
        customerCountryError: false,
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
        modalCurrencyError: false,
      });
    }
  };

  handleContactRows = () => {
    const items = {
      s_no: JSON.stringify(this.state.customerContactDetails.length + 1),
      contactPsn: "",
      phone: "",
      handPhone: "",
      email: "",
      emailError: false,
    };
    this.setState({
      customerContactDetails: [...this.state.customerContactDetails, items],
    });
  };

  CntDetailsHandler = (idx) => (e) => {
    var name = e.target.name;
    var rows = this.state.customerContactDetails;
    if (name === "contactPsn") {
      rows[idx].contactPsn = e.target.value;
    }
    if (name === "phone") {
      const re = /^\d*(\.\d{0,3})?$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        rows[idx].phone = e.target.value;
      }
    }
    if (name === "handPhone") {
      const re = /^\d*(\.\d{0,3})?$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        rows[idx].handPhone = e.target.value;
      }
    }
    if (name === "email") {
      rows[idx].email = e.target.value;
      rows[idx].emailError = false;
    }

    this.setState({
      customerContactDetails: rows,
    });
  };

  handleRemoveContactRow = (idx) => () => {
    const rows = this.state.customerContactDetails;
    rows.splice(idx, 1);
    this.setState({
      customerContactDetails: rows,
    });
  };

  handleKYCRows = () => {
    const items = {
      s_no: JSON.stringify(this.state.customerKycDetails.length + 1),
      bankAcHolderName: "",
      bankName: "",
      address: "",
      bankACNo: "",
      currency: "0",
      swiftCode: "",
      IBAN_Num: "",
      currencyError: false,
    };
    this.setState({
      customerKycDetails: [...this.state.customerKycDetails, items],
    });
  };

  handleCustomerKyc = (idx) => (e) => {
    var name = e.target.name;
    var rows = this.state.customerKycDetails;
    if (name === "bankAcHolderName") {
      rows[idx].bankAcHolderName = e.target.value;
    }
    if (name === "bankName") {
      rows[idx].bankName = e.target.value;
    }
    if (name === "address") {
      rows[idx].address = e.target.value;
    }
    if (name === "bankACNo") {
      rows[idx].bankACNo = e.target.value;
    }
    if (name === "currency") {
      rows[idx].currency = e.target.value;
      rows[idx].currencyError = false;
    }
    if (name === "swiftCode") {
      rows[idx].swiftCode = e.target.value;
    }
    if (name === "IBAN_Num") {
      rows[idx].IBAN_Num = e.target.value;
    }
    this.setState({
      customerKycDetails: rows,
    });
  };

  handleRemoveKycRows = (idx) => () => {
    const rows = this.state.customerKycDetails;
    rows.splice(idx, 1);
    this.setState({
      customerKycDetails: rows,
    });
  };

  handleAddCurrency = () => {
    var idToken = this.Cookie.getIdTokenCookie();
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var newCurrency = this.state.newCurrency;
    var status = "Active";
    if (newCurrency === "") {
      alert("Please enter Currency");
      this.setState({ modalCurrencyError: true });
      return;
    }
    api
      .addCurrency(loginUserID, idToken, newCurrency, status)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
            if (res.code === "200") {
              this.setState({ errorMessage: "" });
              window.$("#myModal").modal("hide");
              if (res.id) {
                api.getCurrencies(loginUserID, idToken).then((response) => {
                  if (response.status >= 200 && response.status < 300) {
                    response.json().then((res) => {
                      if (res.idtoken)
                        this.Cookie.setIdTokenCookie(res.idtoken);
                      if (res.Currency) {
                        var currencies = [
                          <MenuItem value="0" key={-1}>
                            please Select
                          </MenuItem>,
                        ];
                        res.Currency.forEach((item) => {
                          currencies.push(
                            <MenuItem value={item.currency} key={[item.id]}>
                              {item.currency}
                            </MenuItem>
                          );
                        });
                        this.setState({
                          currencyData: currencies,
                        });
                        let data = this.state.customerKycDetails;
                        data[this.state.clickedCurrency].currency =
                          newCurrency.toUpperCase();
                        this.setState({
                          clickedCurrency: "",
                          customerKycDetails: data,
                        });
                      } else {
                        alert(
                          "Unexpected error occured. Please contact administrator."
                        );
                      }
                    });
                  }
                });
              }
            } else if (res.code === "601") {
              this.setState({
                errorMessage: res.message,
                successMessage: "",
              });
            } else if (res.code === "602") {
              alert(res.message);
              this.setState({
                errorMessage: res.message,
                successMessage: "",
                modalCurrencyError: true,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            } else if (res.code === "624") {
              this.setState({
                modalCurrencyError: true,
              });
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          });
        }
      });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      isLoading: true,
    });
    var idToken = this.Cookie.getIdTokenCookie();
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var {
      customerType,
      otherCustomer,
      customerName,
      customerAddress,
      customerCountry,
      taxId,
      shareDocLink,
      files,
    } = this.state;

    // TODO:<!--------------------- Accessing the ContactDetails Array Object values --------------------------- !>
    var contactPsn1 = "",
      contactPsnPhone1 = "",
      contactPsnHandPhone1 = "",
      contactPsnEmail1 = "",
      contactPsn2 = "",
      contactPsnPhone2 = "",
      contactPsnHandPhone2 = "",
      contactPsnEmail2 = "",
      contactPsn3 = "",
      contactPsnPhone3 = "",
      contactPsnHandPhone3 = "",
      contactPsnEmail3 = "",
      contactPsn4 = "",
      contactPsnPhone4 = "",
      contactPsnHandPhone4 = "",
      contactPsnEmail4 = "",
      contactPsn5 = "",
      contactPsnPhone5 = "",
      contactPsnHandPhone5 = "",
      contactPsnEmail5 = "";
    for (var i in this.state.customerContactDetails) {
      if (i === "0") {
        contactPsn1 = this.state.customerContactDetails[i].contactPsn;
        contactPsnPhone1 = this.state.customerContactDetails[i].phone;
        contactPsnHandPhone1 = this.state.customerContactDetails[i].handPhone;
        contactPsnEmail1 = this.state.customerContactDetails[i].email;
      } else if (i === "1") {
        contactPsn2 = this.state.customerContactDetails[i].contactPsn;
        contactPsnPhone2 = this.state.customerContactDetails[i].phone;
        contactPsnHandPhone2 = this.state.customerContactDetails[i].handPhone;
        contactPsnEmail2 = this.state.customerContactDetails[i].email;
      } else if (i === "2") {
        contactPsn3 = this.state.customerContactDetails[i].contactPsn;
        contactPsnPhone3 = this.state.customerContactDetails[i].phone;
        contactPsnHandPhone3 = this.state.customerContactDetails[i].handPhone;
        contactPsnEmail3 = this.state.customerContactDetails[i].email;
      } else if (i === "3") {
        contactPsn4 = this.state.customerContactDetails[i].contactPsn;
        contactPsnPhone4 = this.state.customerContactDetails[i].phone;
        contactPsnHandPhone4 = this.state.customerContactDetails[i].handPhone;
        contactPsnEmail4 = this.state.customerContactDetails[i].email;
      } else if (i === "4") {
        contactPsn5 = this.state.customerContactDetails[i].contactPsn;
        contactPsnPhone5 = this.state.customerContactDetails[i].phone;
        contactPsnHandPhone5 = this.state.customerContactDetails[i].handPhone;
        contactPsnEmail5 = this.state.customerContactDetails[i].email;
      }
    }
    //TODO:<!--------------------- Accessing the VendorKYC Array Object values --------------------------- !>
    var bankAcHolderName1 = "",
      bankName1 = "",
      address1 = "",
      bankACNo1 = "",
      Currency1 = "",
      swiftCode1 = "",
      IBAN_NUM1 = "",
      bankAcHolderName2 = "",
      bankName2 = "",
      address2 = "",
      bankACNo2 = "",
      Currency2 = "",
      swiftCode2 = "",
      IBAN_NUM2 = "",
      bankAcHolderName3 = "",
      bankName3 = "",
      address3 = "",
      bankACNo3 = "",
      Currency3 = "",
      swiftCode3 = "",
      IBAN_NUM3 = "",
      bankAcHolderName4 = "",
      bankName4 = "",
      address4 = "",
      bankACNo4 = "",
      Currency4 = "",
      swiftCode4 = "",
      IBAN_NUM4 = "",
      bankAcHolderName5 = "",
      bankName5 = "",
      address5 = "",
      bankACNo5 = "",
      Currency5 = "",
      swiftCode5 = "",
      IBAN_NUM5 = "";

    for (var j in this.state.customerKycDetails) {
      if (j === "0") {
        bankAcHolderName1 = this.state.customerKycDetails[j].bankAcHolderName;
        bankName1 = this.state.customerKycDetails[j].bankName;
        address1 = this.state.customerKycDetails[j].address;
        bankACNo1 = this.state.customerKycDetails[j].bankACNo;
        Currency1 = this.state.customerKycDetails[j].currency;
        if (Currency1 === "0") {
          Currency1 = "";
        }
        swiftCode1 = this.state.customerKycDetails[j].swiftCode;
        IBAN_NUM1 = this.state.customerKycDetails[j].IBAN_Num;
      } else if (j === "1") {
        bankAcHolderName2 = this.state.customerKycDetails[j].bankAcHolderName;
        bankName2 = this.state.customerKycDetails[j].bankName;
        address2 = this.state.customerKycDetails[j].address;
        bankACNo2 = this.state.customerKycDetails[j].bankACNo;
        Currency2 = this.state.customerKycDetails[j].currency;
        if (Currency2 === "0") {
          Currency2 = "";
        }
        swiftCode2 = this.state.customerKycDetails[j].swiftCode;
        IBAN_NUM2 = this.state.customerKycDetails[j].IBAN_Num;
      } else if (j === "2") {
        bankAcHolderName3 = this.state.customerKycDetails[j].bankAcHolderName;
        bankName3 = this.state.customerKycDetails[j].bankName;
        address3 = this.state.customerKycDetails[j].address;
        bankACNo3 = this.state.customerKycDetails[j].bankACNo;
        Currency3 = this.state.customerKycDetails[j].currency;
        if (Currency3 === "0") {
          Currency3 = "";
        }
        swiftCode3 = this.state.customerKycDetails[j].swiftCode;
        IBAN_NUM3 = this.state.customerKycDetails[j].IBAN_Num;
      } else if (j === "3") {
        bankAcHolderName4 = this.state.customerKycDetails[j].bankAcHolderName;
        bankName4 = this.state.customerKycDetails[j].bankName;
        address4 = this.state.customerKycDetails[j].address;
        bankACNo4 = this.state.customerKycDetails[j].bankACNo;
        Currency4 = this.state.customerKycDetails[j].currency;
        if (Currency4 === "0") {
          Currency4 = "";
        }
        swiftCode4 = this.state.customerKycDetails[j].swiftCode;
        IBAN_NUM4 = this.state.customerKycDetails[j].IBAN_Num;
      } else if (j === "4") {
        bankAcHolderName5 = this.state.customerKycDetails[j].bankAcHolderName;
        bankName5 = this.state.customerKycDetails[j].bankName;
        address5 = this.state.customerKycDetails[j].address;
        bankACNo5 = this.state.customerKycDetails[j].bankACNo;
        Currency5 = this.state.customerKycDetails[j].currency;
        if (Currency5 === "0") {
          Currency5 = "";
        }
        swiftCode5 = this.state.customerKycDetails[j].swiftCode;
        IBAN_NUM5 = this.state.customerKycDetails[j].IBAN_Num;
      }
    }
    /*
    ! <!------------------------- Checking Mandatory Fileds ----------------------!>
    */

    if (customerType === "0") {
      this.setState({ customerTypeError: true });
    }
    if (customerType === "Others") {
      if (otherCustomer === "") {
        this.setState({ otherCustomerError: true });
      }
    }
    if (customerName === "") {
      this.setState({ customerNameError: true });
    }
    if (customerAddress === "") {
      this.setState({ customerAddressError: true });
    }
    if (customerCountry === "0") {
      this.setState({ customerCountryError: true });
    }

    const content_files = files.filter((e) => e.file_content !== "");

    var status = "Active";
    if (
      customerType !== "0" &&
      customerName !== "" &&
      customerAddress !== "" &&
      customerCountry !== "0"
    ) {
      // Creating Customer
      api
        .addCustomer(
          loginUserID,
          idToken,
          customerType,
          otherCustomer,
          customerName,
          customerAddress,
          customerCountry,
          contactPsn1,
          contactPsnPhone1,
          contactPsnHandPhone1,
          contactPsnEmail1,
          contactPsn2,
          contactPsnPhone2,
          contactPsnHandPhone2,
          contactPsnEmail2,
          contactPsn3,
          contactPsnPhone3,
          contactPsnHandPhone3,
          contactPsnEmail3,
          contactPsn4,
          contactPsnPhone4,
          contactPsnHandPhone4,
          contactPsnEmail4,
          contactPsn5,
          contactPsnPhone5,
          contactPsnHandPhone5,
          contactPsnEmail5,
          bankAcHolderName1,
          bankName1,
          address1,
          bankACNo1,
          Currency1,
          swiftCode1,
          IBAN_NUM1,
          bankAcHolderName2,
          bankName2,
          address2,
          bankACNo2,
          Currency2,
          swiftCode2,
          IBAN_NUM2,
          bankAcHolderName3,
          bankName3,
          address3,
          bankACNo3,
          Currency3,
          swiftCode3,
          IBAN_NUM3,
          bankAcHolderName4,
          bankName4,
          address4,
          bankACNo4,
          Currency4,
          swiftCode4,
          IBAN_NUM4,
          bankAcHolderName5,
          bankName5,
          address5,
          bankACNo5,
          Currency5,
          swiftCode5,
          IBAN_NUM5,
          taxId,
          shareDocLink,
          status,
          content_files
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.idtoken) {
                this.Cookie.setIdTokenCookie(res.idtoken);
              }
              if (res) {
                if (res.code === "200") {
                  this.setState({
                    successMessage: res.message,
                    errorMessage: "",
                  });
                  if (res.id) {
                    // window.location.href='/view-customer/'+ btoa(res.id);
                    window.location.href = "/business-partner";
                  }
                }
                if (res.code === "601") {
                  this.setState({
                    errorMessage: res.message,
                    successMessage: "",
                    isLoading: false,
                  });
                }
                if (res.code === "607") {
                  window.location.href = "/logout";
                }
                if (res.code === "643") {
                  alert(res.message);
                  this.setState({
                    customerNameError: true,
                    errorMessage: res.message,
                    successMessage: "",
                    isLoading: false,
                  });
                }
                if (res.code === "624") {
                  var rows = this.state.customerContactDetails;
                  var kycRows = this.state.customerKycDetails;
                  for (var i = 0; i < res.columns.length; i++) {
                    if (res.columns[i] === "contact_person_email_1") {
                      rows[0].emailError = true;
                    } else if (res.columns[i] === "contact_person_email_2") {
                      rows[1].emailError = true;
                    } else if (res.columns[i] === "contact_person_email_3") {
                      rows[2].emailError = true;
                    } else if (res.columns[i] === "contact_person_email_4") {
                      rows[3].emailError = true;
                    } else if (res.columns[i] === "contact_person_email_5") {
                      rows[4].emailError = true;
                    } else if (res.columns[i] === "currency_1") {
                      kycRows[0].currencyError = true;
                    } else if (res.columns[i] === "currency_2") {
                      kycRows[1].currencyError = true;
                    } else if (res.columns[i] === "currency_3") {
                      kycRows[2].currencyError = true;
                    } else if (res.columns[i] === "currency_4") {
                      kycRows[3].currencyError = true;
                    } else if (res.columns[i] === "currency_5") {
                      kycRows[4].currencyError = true;
                    }
                  }
                  this.setState({
                    customerContactDetails: rows,
                    customerKycDetails: kycRows,
                    errorMessage: res.message,
                    successMessage: "",
                    isLoading: false,
                  });
                  alert(res.message);
                  return;
                }
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            });
          }
        });
    } else {
      alert("Please fill all mandatory fields.");
    }
  };

  firstActiveSection = () => {
    var {
      customerType,
      otherCustomer,
      customerName,
      customerAddress,
      customerCountry,
    } = this.state;
    if (customerType === "0") {
      this.setState({ customerTypeError: true });
    }
    if (customerType === "Others") {
      if (otherCustomer === "") {
        this.setState({ otherCustomerError: true });
        return;
      }
    }
    if (customerName === "") {
      this.setState({ customerNameError: true });
    }
    if (customerAddress === "") {
      this.setState({ customerAddressError: true });
    }
    if (customerCountry === "0") {
      this.setState({ customerCountryError: true });
    }
    if (
      customerType !== "0" &&
      customerName !== "" &&
      customerAddress !== "" &&
      customerCountry !== "0"
    ) {
      this.setState({
        activeStep: 1,
      });
    } else {
      alert("Please fill mandatory fields.");
    }
  };

  previousStepHandler = () => {
    this.setState({
      activeStep: this.state.activeStep - 1,
    });
  };

  nextStepHandler = () => {
    if (this.state.activeStep === 0) {
      var {
        customerType,
        otherCustomer,
        customerName,
        customerAddress,
        customerCountry,
      } = this.state;
      if (customerType === "0") {
        this.setState({ customerTypeError: true });
      }
      if (customerType === "Others") {
        if (otherCustomer === "") {
          this.setState({ otherCustomerError: true });
          return;
        }
      }
      if (customerName === "") {
        this.setState({ customerNameError: true });
      }
      if (customerAddress === "") {
        this.setState({ customerAddressError: true });
      }
      if (customerCountry === "0") {
        this.setState({ customerCountryError: true });
      }
      if (
        customerType === "0" ||
        customerName === "" ||
        customerAddress === "" ||
        customerCountry === "0"
      ) {
        this.setState({
          snackBarErrorOpen: true,
          errorMsg: "Please fill mandatory fields.",
        });
        return;
      }
    }

    if (this.state.activeStep === 1) {
      // files validation
      let missing_fields = 0;
      let files_missing = 0;
      var files_data = this.state.files;
      for (var obj of files_data) {
        if (obj.file_type !== "" || obj.file_content !== "") {
          if (obj.file_content !== "") {
            if (obj.file_description === "") {
              missing_fields++;
              obj.file_description_error = true;
            } else {
              obj.file_description = obj.file_description.toUpperCase();
            }
          } else if (obj.file_description !== "") {
            if (obj.file_content === "") {
              files_missing++;
            }
          }
        }
      }
      this.setState({
        files: files_data,
      });

      if (missing_fields > 0) {
        this.setState({
          snackBarErrorOpen: true,
          errorMsg: "Please fill mandatory fields.",
          submitLoading: false,
        });
        return;
      }
      if (files_missing > 0) {
        this.setState({
          snackBarErrorOpen: true,
          errorMsg: "Please Select file.",
          submitLoading: false,
        });
        return;
      }
    }

    this.setState({
      activeStep: this.state.activeStep + 1,
    });
  };

  render() {
    return (
      <div id="wraper">
        <SideBar />
        <div className="content">
          <Header />
          <div className="content-header">
            <div className="col">
              <h4 style={{ padding: "10px 0px", margin: "0px" }}>
                Add Business Partner(Customer)
              </h4>
            </div>
          </div>
          {this.state.isLoading && (
            <Loader />
          )}
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          {!this.state.isLoading && (
            <form className="container p-3">
              {this.state.errorMessage && (
                <div className="row">
                  <div className="col text-center">
                    <p className="error-class">{this.state.errorMessage}</p>
                  </div>
                </div>
              )}
              {this.state.successMessage && (
                <div className="row">
                  <div className="col text-center">
                    <p className="success-class">{this.state.successMessage}</p>
                  </div>
                </div>
              )}

              <div className="row mb-0 stepper">
                <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                  <i
                    className={
                      this.state.activeStep > 0
                        ? "fa fa-check-circle"
                        : "fa fa-check-circle-o"
                    }
                    aria-hidden="true"
                    style={{
                      fontSize: 20,
                      color:
                        this.state.activeStep > 0
                          ? "#31c197"
                          : this.state.activeStep === 0
                          ? config.themeColor
                          : "#ccc",
                    }}
                  ></i>
                  <span
                    className="stepper_text"
                    style={{
                      color:
                        this.state.activeStep > 0
                          ? "#31c197"
                          : this.state.activeStep === 0
                          ? config.themeColor
                          : "#ccc",
                    }}
                  >
                    Add Business Partner
                  </span>
                  <i
                    className="fa fa-chevron-right stepper_sub_icon stepper_arrow"
                    aria-hidden="true"
                    style={{
                      color:
                        this.state.activeStep > 0
                          ? "#31c197"
                          : this.state.activeStep === 0
                          ? config.themeColor
                          : "#ccc",
                    }}
                  ></i>
                </span>
                <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                  <i
                    className={
                      this.state.activeStep > 1
                        ? "fa fa-check-circle"
                        : "fa fa-check-circle-o"
                    }
                    aria-hidden="true"
                    style={{
                      fontSize: 20,
                      color:
                        this.state.activeStep > 1
                          ? "#31c197"
                          : this.state.activeStep === 1
                          ? config.themeColor
                          : "#ccc",
                    }}
                  ></i>
                  <span
                    className="stepper_text"
                    style={{
                      color:
                        this.state.activeStep > 1
                          ? "#31c197"
                          : this.state.activeStep === 1
                          ? config.themeColor
                          : "#ccc",
                    }}
                  >
                    Contact and KYC
                  </span>
                  <i
                    className="fa fa-chevron-right stepper_sub_icon stepper_arrow"
                    aria-hidden="true"
                    style={{
                      color:
                        this.state.activeStep > 1
                          ? "#31c197"
                          : this.state.activeStep === 1
                          ? config.themeColor
                          : "#ccc",
                    }}
                  ></i>
                </span>
                <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                  <i
                    className={
                      this.state.activeStep > 2
                        ? "fa fa-check-circle"
                        : "fa fa-check-circle-o"
                    }
                    aria-hidden="true"
                    style={{
                      fontSize: 20,
                      color:
                        this.state.activeStep > 2
                          ? "#31c197"
                          : this.state.activeStep === 2
                          ? config.themeColor
                          : "#ccc",
                    }}
                  ></i>
                  <span
                    className="stepper_text"
                    style={{
                      color:
                        this.state.activeStep === 2
                          ? config.themeColor
                          : "#ccc",
                    }}
                  >
                    Preview and Submit
                  </span>
                </span>
              </div>

              <div className="col-lg card" style={{ overflowY: "scroll" }}>
                {this.state.activeStep === 0 && (
                  <div className="section_block">
                    <div style={{ width: "70%" }}>
                      <h4 style={{ padding: "10px 0px", margin: "0px" }}>
                        Add details to create new Customer
                      </h4>
                    </div>
                    <div className="row mb-2">
                      <div className="col-lg-5 pl-0">
                        <label className="form_label mb-0">
                          Customer Type <span style={{ color: "red" }}>*</span>
                        </label>
                        <TextField
                          name="customerType"
                          placeholder="Customer Type"
                          margin="dense"
                          variant="outlined"
                          select
                          required
                          error={this.state.customerTypeError}
                          value={this.state.customerType}
                          onChange={this.handleText}
                          fullWidth
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          InputProps={{
                            style: {
                              fontFamily: "Poppins",
                              fontSize: "14px",
                            },
                          }}
                          style={{ marginTop: 0 }}
                        >
                          <MenuItem value="0" disabled>
                            Please Select
                          </MenuItem>
                          <MenuItem value="Local Trader">Local Trader</MenuItem>
                          <MenuItem value="International Trader">
                            International Trader
                          </MenuItem>
                          <MenuItem value="End User"> End User</MenuItem>
                          <MenuItem value="Others">Others</MenuItem>
                        </TextField>
                      </div>
                      <div className="col-lg-7 p-0">
                        <label className="form_label mb-0">
                          Customer Name <span style={{ color: "red" }}>*</span>
                        </label>
                        <TextField
                          name="customerName"
                          placeholder="Customer Name"
                          margin="dense"
                          required
                          error={this.state.customerNameError}
                          variant="outlined"
                          value={this.state.customerName}
                          onChange={this.handleText}
                          fullWidth
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          InputProps={{
                            style: {
                              fontFamily: "Poppins",
                              fontSize: "14px",
                            },
                          }}
                          style={{ marginTop: 0 }}
                        />
                      </div>
                    </div>
                    {this.state.customerType === "Others" && (
                      <div className="row mb-2">
                        <label className="form_label mb-0">
                          Other Customer Type{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <TextField
                          name="otherCustomer"
                          placeholder="Other Customer Type"
                          margin="dense"
                          required
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          error={this.state.otherCustomerError}
                          variant="outlined"
                          value={this.state.otherCustomer}
                          onChange={this.handleText}
                          fullWidth
                          InputProps={{
                            style: {
                              fontFamily: "Poppins",
                              fontSize: "14px",
                            },
                          }}
                          style={{ marginTop: 0 }}
                        />
                      </div>
                    )}
                    <div className="row mb-2">
                      <div className="col-lg-5 pl-0">
                        <label className="form_label mb-0">
                          Customer Country{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <TextField
                          name="customerCountry"
                          placeholder="Customer Country"
                          margin="dense"
                          variant="outlined"
                          select
                          error={this.state.customerCountryError}
                          value={this.state.customerCountry}
                          onChange={this.handleText}
                          fullWidth
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          InputProps={{
                            style: {
                              fontFamily: "Poppins",
                              fontSize: "14px",
                            },
                          }}
                          style={{ marginTop: 0 }}
                        >
                          {this.state.countries}
                        </TextField>
                      </div>
                      <div className="col-lg-7 p-0">
                        <label className="form_label mb-0">
                          Customer Address{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <TextField
                          name="customerAddress"
                          placeholder="Customer Address"
                          margin="dense"
                          variant="outlined"
                          error={this.state.customerAddressError}
                          multiline
                          rows={3}
                          required
                          value={this.state.customerAddress}
                          onChange={this.handleText}
                          fullWidth
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          InputProps={{
                            style: {
                              fontFamily: "Poppins",
                              fontSize: "14px",
                            },
                          }}
                          style={{ marginTop: 0 }}
                        />
                        <span
                          style={{
                            fontSize: 14,
                            color: "#93bce0",
                            float: "right",
                            border: "#93bce0 0.5px solid",
                            padding: "0 5px",
                          }}
                        >
                          {this.state.textAreaMaxCount -
                            this.state.customerAddress.length}
                        </span>
                        <span
                          style={{
                            fontSize: 14,
                            color: "#93bce0",
                            float: "right",
                            marginRight: 5,
                          }}
                        >
                          Remaining characters{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {this.state.activeStep === 1 && (
                  <div className="section_block">
                    <div className="row">
                      <label className="form_heading_label mb-0">Contact</label>
                    </div>
                    {this.state.customerContactDetails.map((e, idx) => (
                      <div className="row" key={idx}>
                        <div className="row col-lg-11 pb-4 mb-2 section_background">
                          <div className="col-lg-6 mb-2 p-0">
                            <label className="form_label">Contact Person</label>
                            <TextField
                              name="contactPsn"
                              placeholder="Contact Person"
                              margin="dense"
                              // label='Contact Person'
                              variant="outlined"
                              value={
                                this.state.customerContactDetails[idx]
                                  .contactPsn
                              }
                              onChange={this.CntDetailsHandler(idx)}
                              fullWidth
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                              InputProps={{
                                style: {
                                  fontFamily: "Poppins",
                                  backgroundColor: "white",
                                  fontSize: "14px",
                                },
                              }}
                              style={{ marginTop: 0 }}
                            />
                          </div>
                          <div className="col-lg-6 mb-2 pr-0">
                            <label className="form_label">Phone Number</label>
                            <TextField
                              name="phone"
                              placeholder="Phone Number"
                              margin="dense"
                              // label='Phone Number'
                              variant="outlined"
                              type="tel"
                              value={
                                this.state.customerContactDetails[idx].phone
                              }
                              onChange={this.CntDetailsHandler(idx)}
                              fullWidth
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                              InputProps={{
                                style: {
                                  fontFamily: "Poppins",
                                  backgroundColor: "white",
                                  fontSize: "14px",
                                },
                              }}
                              style={{ marginTop: 0 }}
                            />
                          </div>
                          <div className="col-lg-6 mb-2 p-0">
                            <label className="form_label">
                              Hand Phone Number
                            </label>
                            <TextField
                              name="handPhone"
                              placeholder="Hand Phone Number"
                              margin="dense"
                              // label='Hand Phone Number'
                              variant="outlined"
                              value={
                                this.state.customerContactDetails[idx].handPhone
                              }
                              onChange={this.CntDetailsHandler(idx)}
                              fullWidth
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                              InputProps={{
                                style: {
                                  fontFamily: "Poppins",
                                  backgroundColor: "white",
                                  fontSize: "14px",
                                },
                              }}
                              style={{ marginTop: 0 }}
                            />
                          </div>
                          <div className="col-lg-6 mb-2 pr-0">
                            <label className="form_label">Email Address</label>
                            <TextField
                              name="email"
                              placeholder="Email Address"
                              margin="dense"
                              variant="outlined"
                              // label='Email Address'
                              value={
                                this.state.customerContactDetails[idx].email
                              }
                              error={
                                this.state.customerContactDetails[idx]
                                  .emailError
                              }
                              onChange={this.CntDetailsHandler(idx)}
                              fullWidth
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                              InputProps={{
                                style: {
                                  fontFamily: "Poppins",
                                  backgroundColor: "white",
                                  fontSize: "14px",
                                },
                              }}
                              style={{ marginTop: 0 }}
                            />
                          </div>
                        </div>
                        {this.state.customerContactDetails.length === 1 ? (
                          <div></div>
                        ) : (
                          <div style={{ margin: "auto" }}>
                            <div className="icon_hover text-align-center">
                              <i
                                className="fa fa-trash-o"
                                onClick={this.handleRemoveContactRow(idx)}
                                style={{
                                  fontSize: 24,
                                  color: config.themeColor,
                                }}
                                aria-hidden="true"
                              />
                            </div>
                            {/* <div className="icon_hover text-align-center">
                              <RemoveIcon onClick={this.handleRemoveContactRow(idx)} style={{fontSize:24, color: config.themeColor}}/>
                            </div> */}
                          </div>
                        )}
                      </div>
                    ))}

                    <div className="row mb-2">
                      <div className="col-lg pt-2 pl-0">
                        {
                          this.state.customerContactDetails.length < 5 && (
                            <div
                              onClick={this.handleContactRows}
                              className="section_add_contact"
                            >
                              <span>
                                <AddCircle
                                  style={{
                                    fontSize: 24,
                                    color: config.themeColor,
                                  }}
                                />
                              </span>
                              <span className="section_add_contact_name">
                                Add New Contact
                              </span>
                            </div>
                          )
                          // <button type='button' onClick={this.handleContactRows} className='btn btn-primary btn-responsive' style={{padding:4, backgroundColor:'#303F9F', border:'none'}}><AddCircle style={{fontSize:24, marginRight: 10}} />Add Row</button>
                        }
                      </div>
                    </div>

                    {/* <div className='row'>
                      <div className='col-lg pl-4 pt-2'>
                        {this.state.customerContactDetails.length < 5 &&
                          <button type='button' onClick={this.handleContactRows} className='btn btn-primary btn-responsive' style={{padding:4, backgroundColor:'#303F9F', border:'none'}}><AddCircle style={{fontSize:24, marginRight: 10}} />Add Row</button>                    
                        } 
                      </div>
                    </div>                  */}

                    <div className="row">
                      <div className="row">
                        <label className="form_heading_label mb-0">
                          Customer KYC
                        </label>
                      </div>
                    </div>
                    {this.state.customerKycDetails.map((e, idx) => (
                      <div className="row" key={idx}>
                        <div className="row col-lg-11 pb-4 mb-2 section_background">
                          <div className="col-lg-4 mb-2 p-0">
                            <label className="form_label">Bank Name</label>
                            <TextField
                              name="bankName"
                              placeholder="Bank Name"
                              margin="dense"
                              variant="outlined"
                              // label='Bank Name'
                              value={
                                this.state.customerKycDetails[idx].bankName
                              }
                              onChange={this.handleCustomerKyc(idx)}
                              error={this.state.bankName1Error}
                              fullWidth
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                              InputProps={{
                                style: {
                                  fontFamily: "Poppins",
                                  backgroundColor: "white",
                                  fontSize: "14px",
                                },
                              }}
                              style={{ marginTop: 0 }}
                            />
                          </div>
                          <div className="col-lg-4 mb-2 pr-0">
                            <label className="form_label">
                              Account Holder Name
                            </label>
                            <TextField
                              name="bankAcHolderName"
                              placeholder="Account Holder Name"
                              margin="dense"
                              variant="outlined"
                              // label='Bank A/c Holder Name'
                              value={
                                this.state.customerKycDetails[idx]
                                  .bankAcHolderName
                              }
                              onChange={this.handleCustomerKyc(idx)}
                              fullWidth
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                              InputProps={{
                                style: {
                                  fontFamily: "Poppins",
                                  backgroundColor: "white",
                                  fontSize: "14px",
                                },
                              }}
                              style={{ marginTop: 0 }}
                            />
                          </div>
                          <div className="col-lg-4 mb-2 pr-0">
                            <label className="form_label">Account Number</label>
                            <TextField
                              name="bankACNo"
                              placeholder="Account Number"
                              margin="dense"
                              // label ='Bank A/c No'
                              variant="outlined"
                              value={
                                this.state.customerKycDetails[idx].bankACNo
                              }
                              onChange={this.handleCustomerKyc(idx)}
                              error={this.state.bankACNo1Error}
                              fullWidth
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                              InputProps={{
                                style: {
                                  fontFamily: "Poppins",
                                  backgroundColor: "white",
                                  fontSize: "14px",
                                },
                              }}
                              style={{ marginTop: 0 }}
                            />
                          </div>
                          <div className="col-lg-4 mb-2 p-0">
                            <label className="form_label">Swift Code</label>
                            <TextField
                              name="swiftCode"
                              placeholder="Swift Code"
                              margin="dense"
                              // label ='Swift Code'
                              variant="outlined"
                              value={
                                this.state.customerKycDetails[idx].swiftCode
                              }
                              onChange={this.handleCustomerKyc(idx)}
                              error={this.state.swiftCode1Error}
                              fullWidth
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                              InputProps={{
                                style: {
                                  fontFamily: "Poppins",
                                  backgroundColor: "white",
                                  fontSize: "14px",
                                },
                              }}
                              style={{ marginTop: 0 }}
                            />
                          </div>
                          <div className="col-lg-4 mb-2 pr-0">
                            <label className="form_label">Address</label>
                            <TextField
                              name="address"
                              placeholder="Address"
                              margin="dense"
                              // label ='Address'
                              variant="outlined"
                              value={this.state.customerKycDetails[idx].address}
                              onChange={this.handleCustomerKyc(idx)}
                              error={this.state.address1Error}
                              fullWidth
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                              InputProps={{
                                style: {
                                  fontFamily: "Poppins",
                                  backgroundColor: "white",
                                  fontSize: "14px",
                                },
                              }}
                              style={{ marginTop: 0 }}
                            />
                          </div>
                          <div className="col-lg-4 mb-2 pr-0">
                            <label className="form_label">Currency</label>
                            <TextField
                              name="currency"
                              margin="dense"
                              // label ='Currency'
                              variant="outlined"
                              value={
                                this.state.customerKycDetails[idx].currency
                              }
                              error={
                                this.state.customerKycDetails[idx].currencyError
                              }
                              onChange={this.handleCustomerKyc(idx)}
                              style={{ width: "85%", marginTop: 0 }}
                              select
                              InputProps={{
                                style: {
                                  fontFamily: "Poppins",
                                  backgroundColor: "white",
                                  minWidth: "165px",
                                  fontSize: "14px",
                                },
                              }}
                            >
                              {this.state.currencyData}
                            </TextField>
                            <span>
                              <AddCircle
                                onClick={() =>
                                  this.setState({ clickedCurrency: idx })
                                }
                                data-toggle="modal"
                                data-target="#myModal"
                                style={{
                                  fontSize: 24,
                                  marginTop: 6,
                                  marginLeft: 3,
                                  color: config.themeColor,
                                }}
                              />
                            </span>
                          </div>
                          <div className="col-lg-4 mb-2 p-0">
                            <label className="form_label">IBAN No</label>
                            <TextField
                              name="IBAN_Num"
                              placeholder="IBAN No"
                              margin="dense"
                              // label ='IBAN No'
                              variant="outlined"
                              value={
                                this.state.customerKycDetails[idx].IBAN_Num
                              }
                              onChange={this.handleCustomerKyc(idx)}
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                              fullWidth
                              InputProps={{
                                style: {
                                  fontFamily: "Poppins",
                                  backgroundColor: "white",
                                  fontSize: "14px",
                                },
                              }}
                              style={{ marginTop: 0 }}
                            />
                          </div>
                        </div>
                        {this.state.customerKycDetails.length === 1 ? (
                          <span></span>
                        ) : (
                          <div style={{ margin: "auto" }}>
                            <div className="icon_hover text-align-center">
                              <i
                                className="fa fa-trash-o"
                                onClick={this.handleRemoveKycRows(idx)}
                                style={{
                                  fontSize: 24,
                                  color: config.themeColor,
                                }}
                                aria-hidden="true"
                              />
                            </div>
                            {/* <div className="icon_hover text-align-center">
                                  <RemoveIcon className="icon_hover" onClick={this.handleRemoveKycRows(idx)} style={{fontSize:24, color: config.themeColor}}/>
                              </div>   */}
                          </div>
                        )}
                      </div>
                    ))}

                    <div className="row">
                      <div className="col-lg pt-2 pl-0 mb-2">
                        {this.state.customerKycDetails.length < 5 && (
                          // <button type='button' onClick={this.handleKYCRows} className='btn btn-primary btn-responsive' style={{padding:4, backgroundColor:'#303F9F', border:'none'}}><AddCircle style={{fontSize:24, marginRight: 10}} />Add Row</button>
                          <button
                            type="button"
                            onClick={this.handleKYCRows}
                            className="header_button header_button_text addrow_button_adjustment"
                            style={{ color: config.themeColor }}
                          >
                            Add New KYC
                          </button>
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-4 pl-0 mb-2">
                        <label className="form_label">Tax ID</label>
                        <TextField
                          name="taxId"
                          margin="dense"
                          placeholder="Tax ID"
                          variant="outlined"
                          value={this.state.taxId}
                          onChange={this.handleText}
                          fullWidth
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          InputProps={{
                            style: {
                              fontFamily: "Poppins",
                              fontSize: "14px",
                            },
                          }}
                          style={{ marginTop: 0 }}
                        />
                      </div>
                      <div className="col-lg-8 pl-0 mb-2">
                        <label className="form_label">
                          Share Point Document Link
                        </label>
                        <TextField
                          name="shareDocLink"
                          margin="dense"
                          placeholder="Share Point Document Link"
                          variant="outlined"
                          value={this.state.shareDocLink}
                          onChange={this.handleText}
                          fullWidth
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          InputProps={{
                            style: {
                              fontFamily: "Poppins",
                              fontSize: "14px",
                            },
                          }}
                          style={{ marginTop: 0 }}
                        />
                      </div>
                    </div>
                    {(this.state.features.includes("325") ||
                      this.state.features.includes("97")) && (
                      <FileUpload
                        files={this.state.files}
                        feature_name="Customer"
                        callbackFn={(data) => this.setState({ files: data })}
                      />
                    )}
                  </div>
                )}
                {this.state.activeStep === 2 && (
                  <div className="section_block">
                    <div className="section_block_seperator p-0">
                      <h5 className="mb-0 p-2 section_header">
                        Customer Details
                      </h5>
                      <div className="row col-lg-12 mt-2">
                        <div className="col-lg-4 p-0">
                          <div
                            className="row mb-0"
                            style={{ display: "inline-block" }}
                          >
                            <label className="contract_display_header_label">
                              Name
                            </label>
                          </div>
                          <div>
                            <label className="contract_display_header_value">
                              {this.state.customerName === ""
                                ? "-"
                                : this.state.customerName}
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-4 pr-0">
                          <div
                            className="row mb-0"
                            style={{ display: "inline-block" }}
                          >
                            <label className="contract_display_header_label">
                              Type
                            </label>
                          </div>
                          <div>
                            <label className="contract_display_header_value">
                              {this.state.customerType === ""
                                ? "-"
                                : this.state.customerType}
                              {this.state.customerType === "Others"
                                ? " (" + this.state.otherCustomer + ")"
                                : null}
                            </label>
                          </div>
                          {/* {this.state.customerType === "others" ?
                          <label className="contract_display_header_value">{' ('+this.state.otherCustomer+')'}</label>
                          :
                          null
                          } */}
                        </div>
                        <div className="col-lg-4 pr-0">
                          <div
                            className="row mb-0"
                            style={{ display: "inline-block" }}
                          >
                            <label className="contract_display_header_label">
                              Country
                            </label>
                          </div>
                          <div>
                            <label className="contract_display_header_value">
                              {this.state.customerCountry === ""
                                ? "-"
                                : this.state.customerCountry}
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-4 mt-2 p-0">
                          <div
                            className="row mb-0"
                            style={{ display: "inline-block" }}
                          >
                            <label className="contract_display_header_label">
                              Address
                            </label>
                          </div>
                          <div>
                            <label className="contract_display_header_value">
                              {this.state.customerAddress === ""
                                ? "-"
                                : this.state.customerAddress}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row col-lg-12">
                        <div className="col-lg-4 mt-2 p-0">
                          <div
                            className="row mb-0"
                            style={{ display: "inline-block" }}
                          >
                            <label className="contract_display_header_label">
                              Tax ID
                            </label>
                          </div>
                          <div>
                            <label className="contract_display_header_value">
                              {this.state.taxId === "" ? "-" : this.state.taxId}
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-8 mt-2 pr-0">
                          <div
                            className="row mb-0"
                            style={{ display: "inline-block" }}
                          >
                            <label className="contract_display_header_label">
                              Share Point Document Link
                            </label>
                          </div>
                          <div>
                            <label
                              className="contract_display_header_value"
                              style={{ textTransform: "uppercase" }}
                            >
                              {this.state.shareDocLink === ""
                                ? "-"
                                : this.state.shareDocLink}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    {this.state.customerContactDetails.length > 0 && (
                      <div className="section_block_seperator p-0">
                        <h5 className="mb-0 p-2 section_header">Contact</h5>
                        <div className="row">
                          {this.state.customerContactDetails.map(
                            (row, index) => (
                              <div
                                key={index}
                                className="col-lg-5 m-3 section_block_seperator"
                                style={{
                                  flexDirection: "row",
                                  display: "inline-flex",
                                }}
                              >
                                <span className="contact_short_icon">
                                  {row.contactPsn.charAt(0)}
                                </span>
                                <span className="contact_details_name_view">
                                  {row.contactPsn}
                                  <br />
                                  <span className="contact_details_view">
                                    {row.email}
                                    <br />
                                    {/* {row.phone}<br /> */}
                                    {row.handPhone}
                                  </span>
                                </span>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    )}
                    {this.state.customerKycDetails.length > 0 && (
                      <div className="section_block_seperator p-0">
                        <h5 className="mb-0 p-2 section_header">
                          Customer KYC
                        </h5>
                        <div className="row">
                          {this.state.customerKycDetails.map((row, index) => (
                            <div
                              key={index}
                              className="col-lg-5 m-3 section_block_seperator"
                              style={{
                                flexDirection: "row",
                                display: "inline-flex",
                              }}
                            >
                              <span className="contact_details_name_view">
                                {row.bankAcHolderName}
                                <br />
                                <span
                                  className="contact_details_view"
                                  style={{
                                    fontSize: 12,
                                    display: "block",
                                    marginBottom: 10,
                                  }}
                                >
                                  {"A/c No: " + row.bankACNo}
                                </span>
                                <span className="contact_details_view">
                                  {row.bankName}
                                  <br />
                                  {row.IBAN_Num}
                                  <br />
                                  {row.address}
                                  <div className="row">
                                    <span style={{ float: "left" }}>
                                      Swift Code: {row.swiftCode}
                                    </span>
                                    <span
                                      style={{
                                        float: "right",
                                        marginLeft: "17px",
                                      }}
                                    >
                                      Currency: {row.currency}
                                    </span>
                                  </div>
                                </span>
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    {/* <br />
                    <div className='row'>
                      <button type='button' className='btn btn-info btn-responsive mr-3' name='submit' onClick={this.handleSubmit}>Submit</button>
                      <button type='button' className='btn btn-secondary btn-responsive' name='Back' onClick={()=>{window.location.href='/vendor-list'}}>Cancel</button>
                    </div> */}
                  </div>
                )}
              </div>
              {/* {this.state.activeStep === 0 &&
                <div className='row bottom_buttons_section'>
                  <button type='button' className='btn previous_button' onClick={() => window.location.href = '/business-partner'} name='submit'>Back</button>
                  <button type='button' className='btn next_button' name='Back' onClick={this.firstActiveSection}>NEXT STEP</button>
                  {/* <button type='button' className='btn next_button' name='Back' onClick={()=>this.setState({activeStep: 1})}>NEXT STEP</button> *
                </div>
              }
              {this.state.activeStep === 1 &&
                <div className='row bottom_buttons_section'>
                  <button type='button' className='btn previous_button' name='submit' onClick={() => this.setState({ activeStep: 0 })}>PREVIOUS</button>
                  <button type='button' className='btn next_button' name='Back' onClick={() => this.setState({ activeStep: 2 })}>NEXT STEP</button>
                </div>
              }
              {this.state.activeStep === 2 &&
                <div className='row bottom_buttons_section'>
                  <button type='button' className='btn previous_button' name='submit' onClick={() => this.setState({ activeStep: 1 })}>PREVIOUS</button>
                  <button type='button' className='btn next_button' name='Back' onClick={this.handleSubmit}>SUBMIT</button>
                </div>
              } */}
              <div className="row bottom_buttons_section">
                <button
                  type="button"
                  className="btn previous_button"
                  name="submit"
                  onClick={
                    this.state.activeStep === 0
                      ? () => (window.location.href = "/business-partner")
                      : this.previousStepHandler
                  }
                >
                  {this.state.activeStep === 0 ? "BACK" : "PREVIOUS"}
                </button>
                <button
                  type="button"
                  className="btn next_button"
                  name="Back"
                  onClick={
                    this.state.activeStep === 2
                      ? this.handleSubmit
                      : this.nextStepHandler
                  }
                >
                  {this.state.activeStep === 4 ? "SUBMIT" : "NEXT STEP"}
                </button>
              </div>
            </form>
          )}
        </div>
        <div className="modal fade" id="myModal" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4
                  className="modal-title"
                  style={{ border: "none", padding: 0 }}
                >
                  Add Currency
                </h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <TextField
                  name="newCurrency"
                  placeholder="Add New Currency *"
                  margin="dense"
                  variant="outlined"
                  inputProps={{ style: { textTransform: "uppercase" } }}
                  onChange={this.handleText}
                  error={this.state.modalCurrencyError}
                  value={this.state.newCurrency}
                  InputProps={{
                    style: {
                      fontFamily: "Poppins",
                      fontSize: "14px",
                    },
                  }}
                />
              </div>
              <div className="row bottom_buttons_section">
                <button
                  type="button"
                  className="btn previous_button"
                  name="submit"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn next_button"
                  name="Back"
                  onClick={this.handleAddCurrency}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
