import React, { Component } from "react";
import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import api from "../../../api/api";

import { Alert } from "@material-ui/lab";
import { Snackbar } from "@material-ui/core";
import { localDateFormate, pad, toLocaleString } from "../../common/common";
import ViewFiles from "../../common/ViewFiles";
import InvoiceLink from "../../InvoicePdf/InvoiceLink";
import InvoiceForm from "../../common/InvoiceForm";
import PaymentPostings from "../../common/PaymentPostings";
import Loader from "../../common/Loader";

export default class BargeVendorDemurrageView extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      activeStep: 0,
      selectedBargeId: null,
      invoice_date: null,
      ppn: "",
      dmr_to_bg_vend_laycan_commence: null,
      dmr_to_bg_vend_barge_demurrage: "",
      billing_currency: "0",
      dmr_to_bg_vend_demurrage_days: "",
      dmr_to_bg_vend_demurrage_value: "",
      dmr_to_bg_vend_currency_xchg_rate: "",
      dmr_to_bg_vend_pph23_account: "0",
      dmr_to_bg_vend_base_price: "",
      dmr_to_bg_vend_vat_percent: "10",
      dmr_to_bg_vend_vat_value: "",
      dmr_to_bg_vend_pph23_percent: "",
      dmr_to_bg_vend_pph23_value: "",
      dmr_to_bg_vend_total: "",
      dmr_to_bg_vend_ctc: "",
      dmr_to_bg_vend_ctc_in_local_currency: "",

      demurrage_or_despatch: "Demurrage",

      barge_id: "",
      currencyData: [],
      totalBarges: [],
      dmr_to_bg_vend_barges: [],
      dmr_to_bg_vend_payments: [],
      files: [],
      features: [],
      isLoading: true,
      invoiceDetails: {
        invoice_no: "",
        invoice_date: null,
        ppn: "",
        ppn_date: null,
      },
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (
      !(
        features.includes("134") ||
        features.includes("394") ||
        features.includes("445") ||
        features.includes("97")
      )
    ) {
      window.location.href = "/dashboard";
    }

    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const comboID = this.props.comboID;
    const barge_vendor_demurrage_id = comboID.split("$#")[0];
    await api
      .get_barge_demurrage_to_barge_vendor(
        loginUserID,
        idToken,
        barge_vendor_demurrage_id
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.barge_demurrage_to_barge_vendor.length > 0) {
                let days = "0";
                let hours = "0";
                let minutes = "0";
                if (
                  res.barge_demurrage_to_barge_vendor[0].time_at_anchorage_days
                ) {
                  days =
                    res.barge_demurrage_to_barge_vendor[0]
                      .time_at_anchorage_days;
                }
                if (
                  res.barge_demurrage_to_barge_vendor[0].time_at_anchorage_hours
                ) {
                  hours =
                    res.barge_demurrage_to_barge_vendor[0]
                      .time_at_anchorage_hours;
                }
                if (
                  res.barge_demurrage_to_barge_vendor[0]
                    .time_at_anchorage_minutes
                ) {
                  minutes =
                    res.barge_demurrage_to_barge_vendor[0]
                      .time_at_anchorage_minutes;
                }

                var dmr_to_bg_vend_total_paid = 0;
                var dmr_to_bg_vend_remaining_amount = 0;
                for (var i of res.barge_demurrage_to_barge_vendor[0]
                  .barge_vendor_payments) {
                  dmr_to_bg_vend_total_paid += Number(i.amount);
                }

                dmr_to_bg_vend_remaining_amount =
                  Number(
                    res.barge_demurrage_to_barge_vendor[0].dmr_to_bg_vend_total
                      ? res.barge_demurrage_to_barge_vendor[0]
                        .dmr_to_bg_vend_total
                      : 0
                  ) - Number(dmr_to_bg_vend_total_paid);

                let invoice_details = {
                  ...this.state.invoiceDetails,
                  invoice_no: res.barge_demurrage_to_barge_vendor[0].invoice_no,
                  invoice_date:
                    res.barge_demurrage_to_barge_vendor[0].invoice_date,
                  ppn: res.barge_demurrage_to_barge_vendor[0].ppn,
                  ppn_date: res.barge_demurrage_to_barge_vendor[0].ppn_date,
                };

                this.setState({
                  invoiceDetails: invoice_details,
                  dmr_to_bg_vend_total_paid: dmr_to_bg_vend_total_paid,
                  dmr_to_bg_vend_remaining_amount:
                    dmr_to_bg_vend_remaining_amount,
                  remaining_amount: dmr_to_bg_vend_remaining_amount,

                  id: res.barge_demurrage_to_barge_vendor[0].id,
                  costing_no: res.barge_demurrage_to_barge_vendor[0].costing_no,
                  barge_id: res.barge_demurrage_to_barge_vendor[0].barge_id,
                  invoice_file:
                    res.barge_demurrage_to_barge_vendor[0].invoice_file,
                  created_by: res.barge_demurrage_to_barge_vendor[0].created_by,
                  approval_status: res.barge_demurrage_to_barge_vendor[0]
                    .approval_status
                    ? res.barge_demurrage_to_barge_vendor[0].approval_status
                    : "Pending",
                  approved_by:
                    res.barge_demurrage_to_barge_vendor[0].approved_by,
                  approved_by_name:
                    res.barge_demurrage_to_barge_vendor[0].approved_by_name,
                  approved_date:
                    res.barge_demurrage_to_barge_vendor[0].approved_date,

                  billing_currency:
                    res.barge_demurrage_to_barge_vendor[0].billing_currency,
                  dmr_to_bg_vend_barge_demurrage:
                    res.barge_demurrage_to_barge_vendor[0]
                      .dmr_to_bg_vend_barge_demurrage,
                  dmr_to_bg_vend_base_price:
                    res.barge_demurrage_to_barge_vendor[0]
                      .dmr_to_bg_vend_base_price,
                  dmr_to_bg_vend_ctc:
                    res.barge_demurrage_to_barge_vendor[0].dmr_to_bg_vend_ctc,
                  dmr_to_bg_vend_ctc_in_local_currency:
                    res.barge_demurrage_to_barge_vendor[0]
                      .dmr_to_bg_vend_ctc_in_local_currency,
                  dmr_to_bg_vend_currency_xchg_rate:
                    res.barge_demurrage_to_barge_vendor[0]
                      .dmr_to_bg_vend_currency_xchg_rate,
                  dmr_to_bg_vend_demurrage_days:
                    res.barge_demurrage_to_barge_vendor[0]
                      .dmr_to_bg_vend_demurrage_days,
                  dmr_to_bg_vend_demurrage_value:
                    res.barge_demurrage_to_barge_vendor[0]
                      .dmr_to_bg_vend_demurrage_value,
                  dmr_to_bg_vend_laycan_commence:
                    res.barge_demurrage_to_barge_vendor[0]
                      .dmr_to_bg_vend_laycan_commence,
                  dmr_to_bg_vend_pph23_account:
                    res.barge_demurrage_to_barge_vendor[0]
                      .dmr_to_bg_vend_pph23_account,
                  dmr_to_bg_vend_pph23_percent:
                    res.barge_demurrage_to_barge_vendor[0]
                      .dmr_to_bg_vend_pph23_percent,
                  dmr_to_bg_vend_pph23_value:
                    res.barge_demurrage_to_barge_vendor[0]
                      .dmr_to_bg_vend_pph23_value,
                  dmr_to_bg_vend_total:
                    res.barge_demurrage_to_barge_vendor[0].dmr_to_bg_vend_total,
                  dmr_to_bg_vend_vat_percent:
                    res.barge_demurrage_to_barge_vendor[0]
                      .dmr_to_bg_vend_vat_percent,
                  dmr_to_bg_vend_vat_value:
                    res.barge_demurrage_to_barge_vendor[0]
                      .dmr_to_bg_vend_vat_value,
                  barge_vendor_name:
                    res.barge_demurrage_to_barge_vendor[0].barge_vendor_name,
                  loadingJetty:
                    res.barge_demurrage_to_barge_vendor[0].loading_jetty ===
                      null
                      ? ""
                      : res.barge_demurrage_to_barge_vendor[0].loading_jetty,
                  completionTime:
                    res.barge_demurrage_to_barge_vendor[0].completion_time ===
                      ""
                      ? null
                      : res.barge_demurrage_to_barge_vendor[0].completion_time,
                  NORAnchorageDate:
                    res.barge_demurrage_to_barge_vendor[0].nor_anchorage === ""
                      ? null
                      : res.barge_demurrage_to_barge_vendor[0].nor_anchorage,
                  completionDischargeDate:
                    res.barge_demurrage_to_barge_vendor[0]
                      .completion_discharge === ""
                      ? null
                      : res.barge_demurrage_to_barge_vendor[0]
                        .completion_discharge,
                  dmr_to_bg_vend_payments: res
                    .barge_demurrage_to_barge_vendor[0].barge_vendor_payments
                    ? res.barge_demurrage_to_barge_vendor[0]
                      .barge_vendor_payments
                    : [],
                  files: res.barge_demurrage_to_barge_vendor[0].files
                    ? res.barge_demurrage_to_barge_vendor[0].files
                    : [],
                  timeAtAnchorage:
                    days +
                    " days, " +
                    hours +
                    " hours, " +
                    minutes +
                    " minutes",
                  timeAtAnchorageDays: days,
                  timeAtAnchorageHours: hours,
                  timeAtAnchorageMinutes: minutes,
                  dmr_to_supplier_detention_in_days: days,
                  isLoading: false,
                });
                if (this.state.barge_id > 0) {
                  const barge_id = this.state.barge_id;

                  let request = {
                    login_user_id: this.Cookie.getCookie("loginUserId"),
                    idtoken: this.Cookie.getIdTokenCookie(),
                    barge_id: barge_id,
                    vendor_type: "Barge",
                  };
                  api
                    .get_business_no_info_for_barge(request)
                    .then((response) => {
                      if (response.status >= 200 && response.status < 300) {
                        response.json().then((res) => {
                          if (res.code === "200") {
                            this.setState({
                              vendor_name: res.vendor_name,
                              mv_name: res.mv_name,
                            });
                          }
                        });
                      }
                    });
                }
              } else {
                this.setState({
                  isLoading: false,
                });
              }
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                successMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });

    await api.get_barges(loginUserID, idToken).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            if (res.barges) {
              let newData = [];
              var result = res.barges.reduce(function (r, a) {
                r[a.id] = r[a.id] || [];
                r[a.id].push(a);
                return r;
              }, Object.create(null));
              for (let l = 0; l < Object.keys(result).length; l++) {
                newData.push({
                  new_barges: result[Object.keys(result)[l]],
                });
              }
              let purchase_contracts_barges = newData;
              if (newData.length === 0) {
                this.setState({
                  snackBarErrorOpen: true,
                  errorMsg: "No records to display",
                });
              }
              this.setState({
                dmr_to_bg_vend_barges: purchase_contracts_barges,
                id: res.barges[0].id
                //isLoading: false
              });
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          } else if (res.code === "601") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });

  }

  approveHandler = () => {
    this.setState({ isLoading: true });
    let comboID = this.props.comboID;
    const barge_vendor_demurrage_id = comboID.split("$#")[0];
    let request = {
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
      costing_id: barge_vendor_demurrage_id,
      costing_type: "Barge Demurrage to vendor",
      approval_status: "Approved",
    };
    api.approve_costing_api(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.setState(
              {
                snackBarSuccessOpen: true,
                successMsg: res.message,
              },
              () => this.componentDidMount()
            );
          } else if (res.code === "601") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
  };

  updateInvoice = (data) => {
    let request = {
      ...data,
      table_name: "barge_demurrage_to_barge_vendor",
    };
    api.update_invoice_details(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.componentDidMount();
            this.setState({
              invoiceDialog: false,
            });
          }
        });
      }
    });
  };

  renderPaymentPostings = (data) => {
    const {
      id,
      dmr_to_bg_vend_payments = [],
      remaining_amount = 0,
      dmr_to_bg_vend_currency_xchg_rate = 1,
      approval_status = "Pending",
      invoiceDetails: { invoice_no = null } = {},
    } = data;

    let payment_related_data = {
      paymentTitle: `${config.company} to Barge Vendor`,
      payment_posting_type: "dmr_to_bg_vend",
      invoice_no: invoice_no,
      outstanding_amount: remaining_amount,
      billing_currency:
        config.company === "SRPL" || config.company === "AVS" || config.company === "PRPL" ? "USD" : "IDR",
      currency_xchg_rate: dmr_to_bg_vend_currency_xchg_rate
        ? dmr_to_bg_vend_currency_xchg_rate
        : 1,
      costing_id: id,
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
    };

    let access_details = {
      costing_approval: approval_status,
      add_payment: "154",
      view_payment: "155",
      update_payment: "156",
      delete_payment: "157",
      approver: "396",
      approver2: "446",
    };

    return (
      <PaymentPostings
        payments={dmr_to_bg_vend_payments}
        costingType="Barge Demurrage to vendor"
        data={payment_related_data}
        access_details={access_details}
        callbackFn={() => {
          this.componentDidMount();
        }}
      />
    );
  };

  renderInvoiceLink = () => {
    const data = this.state.dmr_to_bg_vend_barges.map((e) =>
      e.new_barges.filter((obj) => obj.id === this.state.barge_id)
    );
    const { costing_no, mv_name, vendor_name } = this.state;

    const Grand_total = (
      parseFloat(
        this.state.dmr_to_bg_vend_base_price
          ? this.state.dmr_to_bg_vend_base_price
          : 0
      ) +
      parseFloat(
        this.state.dmr_to_bg_vend_vat_value
          ? this.state.dmr_to_bg_vend_vat_value
          : 0
      ) -
      parseFloat(
        this.state.dmr_to_bg_vend_pph23_value
          ? this.state.dmr_to_bg_vend_pph23_value
          : 0
      )
    ).toFixed(2);
    const invoiceObject = {
      costing_id: costing_no,
      to: vendor_name,
      business_no: mv_name,
      costing_name: "Vendor Demurrage",
      invoice_no: this.state.invoiceDetails.invoice_no,
      invoice_date: localDateFormate(this.state.invoiceDetails.invoice_date),
      bargeDetails: data ? data.flat() : [],
      billing_currency: this.state.billing_currency,
      total_payable_amount: toLocaleString(Grand_total),
      purchase_type: "FOB Barge",
      description: [
        {
          qty: toLocaleString(this.state.dmr_to_bg_vend_demurrage_days),
          adjustedPrice: toLocaleString(
            this.state.dmr_to_bg_vend_barge_demurrage
          ),
          desc: `${this.state.dmr_to_bg_vend_currency_xchg_rate > 1
            ? `Barge Vendor Demurrage @ ${parseFloat(
              this.state.dmr_to_bg_vend_currency_xchg_rate
            ).toFixed(2)}`
            : "Barge Vendor Demurrage"
            }`,

          amount: this.state.dmr_to_bg_vend_base_price,
        },
        ...(!(config.company === "SRPL" || config.company === "AVS" || config.company === "PRPL") && !!this.state.dmr_to_bg_vend_pph23_value
          ? [
            {
              qty: " ",
              adjustedPrice: " ",
              desc: "PPH",
              amount: `( ${toLocaleString(
                this.state.dmr_to_bg_vend_pph23_value
              )} )`,
            },
          ]
          : []),
        (!!this.state.dmr_to_bg_vend_vat_value && {
          qty: " ",
          adjustedPrice: " ",
          desc: "VAT",
          amount: `${toLocaleString(this.state.dmr_to_bg_vend_vat_value)}`,
        }),
      ],
    };
    return (
      <InvoiceLink
        data={invoiceObject}
        fileName={costing_no}
        demurrage_name={this.state.demurrage_or_despatch}
      />
    );
  };

  render() {
    const comboID = this.props.comboID;
    const barge_vendor_demurrage_id = comboID.split("$#")[0];
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div
          className="container bg-white p-0"
          style={{ minHeight: "100vh", width: 850 }}
        >
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <div className="clearfix content-header border-bottom">
            <div className="row">
              <div className="col-lg-10 text-left pl-0">
                <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                  View Barge Vendor Demurrage
                  <span
                    className={
                      this.state.approval_status === "Pending"
                        ? "type_2"
                        : "type_1"
                    }
                    style={{ padding: 8, marginLeft: 16 }}
                  >
                    {this.state.approval_status}
                  </span>
                </h4>
              </div>
              {this.state.approval_status === "Pending" &&
                this.state.invoice_file &&
                this.Cookie.getCookie("loginUserId") !==
                this.state.created_by &&
                this.state.approved_by !==
                this.Cookie.getCookie("loginUserId") &&
                (this.state.features.includes("394") ||
                  this.state.features.includes("445") ||
                  this.state.features.includes("97")) && (
                  <div
                    className="col text-right float-sm-left"
                    style={{ padding: "5px 20px" }}
                  >
                    <button
                      className="header_button header_button_text add_button_adjustment"
                      style={{ width: "auto", marginTop: "17px" }}
                      onClick={this.approveHandler}
                    >
                      Approve
                    </button>
                  </div>
                )}
              <div className="col-lg-1 pl-0 float-right">
                {this.renderInvoiceLink()}
              </div>
            </div>
          </div>
          <div className="drawer-section">
            <div className="card p-3">
              {this.props.demurrageData && (
                <div className="table-responsive">
                  <table className="table table-bordered table-sm">
                    <thead className="table-header">
                      <tr>
                        <th nowrap="true">Barge ID</th>
                        <th nowrap="true">Barge Nomination</th>
                        <th nowrap="true">Barge Vendor</th>
                        <th nowrap="true" className="text-right">
                          Total Payable
                        </th>
                        <th nowrap="true" className="text-right">
                          Paid
                        </th>
                        <th nowrap="true" className="text-right">
                          Remaining
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <a
                            href={"/view-barge/" + btoa(this.props.demurrageData.barge_id)}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: config.themeColor }}
                          >
                            {pad(this.props.demurrageData.barge_id)}
                          </a>
                        </td>
                        <td nowrap="true">
                          {this.props.demurrageData.barge_nomination}
                        </td>
                        <td nowrap="true">
                          {this.props.demurrageData.barge_vendor_name}
                        </td>
                        <td className="text-right" nowrap="true">
                          {toLocaleString(
                            this.props.demurrageData.total_payable
                          )}
                        </td>
                        <td className="text-right" nowrap="true">
                          {toLocaleString(this.props.demurrageData.paid)}
                        </td>
                        <td className="text-right" nowrap="true">
                          {toLocaleString(
                            this.props.demurrageData.Remaining_amount
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
              <hr />
              {/* Laycan */}
              <div className="col shadow-sm p-0 mb-2 bg-white border">
                <div className="table-responsive">
                  <table className="table table-sm table-bordered mb-0">
                    <thead className="table-header">
                      <tr>
                        <th className="text-center">Description</th>
                        <th className="text-center">Vendor Name</th>
                        {/* <th className="text-right">Price</th> */}
                        <th className="text-right">Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      <>
                        <tr>
                          <td>
                            Vendor
                          </td>
                          <td className="text-center">{this.state.barge_vendor_name}</td>
                          <td></td>
                        </tr>
                        {this.state.sales_type !== "FOB Barge"
                          &&
                          <tr>
                            <td> Laycan Commencement</td>
                            <td></td>
                            <td className="text-right">{this.state.dmr_to_bg_vend_laycan_commence
                              ? localDateFormate(
                                this.state.dmr_to_bg_vend_laycan_commence
                              )
                              : ""}</td>
                          </tr>}

                        {this.state.sales_type !== "FOB Barge"
                          &&
                          <tr>
                            <td> Completion Discharge</td>
                            <td></td>
                            <td className="text-right">{this.state.completionDischargeDate
                              ? localDateFormate(this.state.completionDischargeDate)
                              : ""}</td>
                          </tr>
                        }
                        {this.state.sales_type !== "FOB Barge"
                          &&
                          <tr>
                            <td> NOR Anchorage</td>
                            <td></td>
                            <td className="text-right">{this.state.NORAnchorageDate
                              ? localDateFormate(this.state.NORAnchorageDate)
                              : ""}</td>
                          </tr>}
                        {this.state.sales_type !== "FOB Barge"
                          &&
                          <tr>
                            <td> Loading Jetty</td>
                            <td></td>
                            <td className="text-right">{this.state.loadingJetty}</td>
                          </tr>}
                        {this.state.sales_type !== "FOB Barge"
                          &&
                          <tr>
                            <td> Time at Anchorage</td>
                            <td></td>
                            <td className="text-right">{this.state.timeAtAnchorage}</td>
                          </tr>}
                      </>
                    </tbody>
                  </table>
                </div>
              </div>
              {/* New Changes */}
              <div className="col shadow-sm p-0 mb-2 bg-white border">
                <div className="table-responsive">
                  <table className="table table-sm table-bordered mb-0">
                    <thead className="table-header">
                      <tr>
                        <th className="text-center">Description</th>
                        <th className="text-right">Demurrage in Days</th>
                        <th className="text-right">Unit Price</th>
                        <th className="text-right">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <>
                        <tr>
                          <td>
                            Barge Vendor Demurrage
                            {
                              this.state.dmr_to_bg_vend_currency_xchg_rate > 1 && (
                                <div>
                                  Exchange Rate @{parseFloat(this.state.dmr_to_bg_vend_currency_xchg_rate).toFixed(2)}
                                </div>
                              )}
                          </td>
                          <td className="text-right">{toLocaleString(this.state.dmr_to_bg_vend_demurrage_days)}</td>
                          <td className="text-right"> {toLocaleString(this.state.dmr_to_bg_vend_barge_demurrage)}</td>
                          <td className="text-right">{toLocaleString(this.state.dmr_to_bg_vend_base_price)}</td>
                        </tr>
                        {this.state.dmr_to_bg_vend_vat_value
                          &&
                          <tr>
                            <td>VAT @ {this.state.dmr_to_bg_vend_vat_percent} %</td>
                            <td></td>
                            <td></td>
                            <td className="text-right">{toLocaleString(this.state.dmr_to_bg_vend_vat_value)}</td>
                          </tr>}

                        {!(
                          config.company === "SRPL" ||
                          config.company === "AVS" ||
                          config.company === "PRPL"
                        ) && this.state.dmr_to_bg_vend_pph23_value
                          &&
                          <tr>
                            <td>PPH23 @ {this.state.dmr_to_bg_vend_pph23_percent}</td>
                            <td></td>
                            <td></td>
                            <td className="text-right">{parseFloat(this.state.dmr_to_bg_vend_pph23_value).toFixed(2)}</td>
                          </tr>
                        }
                        {this.state.dmr_to_bg_vend_total &&
                          <tr>
                            <td colSpan={3} className="text-right ">
                              {" "}
                              Payable to Vendor :{" "}
                            </td>
                            <td style={{ fontWeight: "bold" }} className="text-right">
                              {`${config.company === "SRPL" ||
                                config.company === "PRPL" ||
                                config.company === "AVS"
                                ? "USD"
                                : "IDR"
                                } ${this.state.dmr_to_bg_vend_total
                                  ? toLocaleString(this.state.dmr_to_bg_vend_total)
                                  : ""}`}
                            </td>
                          </tr>
                        }
                        <tr>
                          <td colSpan={3} className="text-right">
                            Total Paid
                          </td>
                          <td style={{ fontWeight: 'bold' }} className="text-right">
                            {config.company === "SRPL" || config.company === "AVS" || config.company === "PRPL"
                              ? "USD"
                              : "IDR"} {toLocaleString(this.state.dmr_to_bg_vend_total_paid)}
                          </td>
                        </tr>
                        <tr>
                          {this.state.remaining_amount ?
                            <>
                              <td colSpan={3} className="text-right">
                                Remaining Amount
                              </td>
                              <td style={{ fontWeight: 'bold' }} className="text-right">
                                {config.company === "SRPL" || config.company === "AVS" || config.company === "PRPL"
                                  ? "USD"
                                  : "IDR"} {toLocaleString(this.state.remaining_amount)}
                              </td>
                            </> : ""}
                        </tr>
                      </>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="card p-0 border mt-3">
                <div className="card-header section_header">
                  <h5>Payments</h5>
                </div>
                <div className="card-body">
                  <InvoiceForm
                    open={false}
                    costingID={barge_vendor_demurrage_id}
                    data={this.state.invoiceDetails}
                    callbackFn={(data) => this.updateInvoice(data)}
                  />
                  <div className="row">
                    <div className="col-lg-4 mb-3">
                      <label className="contract_display_header_label">
                        Total Payable Amount
                      </label>
                      <div className="contract_display_header_value">
                        {toLocaleString(this.state.dmr_to_bg_vend_total)}
                        {config.company === "SRPL" || config.company === "AVS" || config.company === "PRPL"
                          ? "USD"
                          : "IDR"}
                      </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label className="contract_display_header_label">
                        Amount Paid
                      </label>
                      <div className="contract_display_header_value">
                        {toLocaleString(this.state.dmr_to_bg_vend_total_paid)}
                        {config.company === "SRPL" || config.company === "AVS" || config.company === "PRPL"
                          ? "USD"
                          : "IDR"}
                      </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label className="contract_display_header_label">
                        Remaining Amount
                      </label>
                      <div className="contract_display_header_value">
                        {this.state.remaining_amount <= 0
                          ? 0
                          : toLocaleString(this.state.remaining_amount)}{" "}
                        {config.company === "SRPL" || config.company === "AVS" || config.company === "PRPL"
                          ? "USD"
                          : "IDR"}
                      </div>
                    </div>
                  </div>
                  {(this.state.features.includes("263") ||
                    this.state.features.includes("97")) && (
                      <div>{this.renderPaymentPostings(this.state)}</div>
                    )}
                </div>
              </div>
              <ViewFiles
                {...this.props}
                files_data={this.state.files}
                type="Costings"
                invoice_file={this.state.invoice_file}
              />
            </div>
            <div className="col shadow-sm p-0 mb-2 bg-white border">
              <div className="table-responsive">
                <table className="table table-sm table-bordered">
                  <thead className="table-header">
                    <tr>
                      <th className="text-center">Approval Type</th>
                      <th className="text-center">Approved by</th>
                      <th className="text-center">Approved On</th>
                    </tr>
                  </thead>
                  <tbody>
                    <>
                      <tr>
                        <td className="text-center">Business</td>
                        <td className="text-center">
                          <div> {this.state.approved_by_name}</div>
                        </td>
                        <td className="text-center">
                          {(this.state.approved_date ? this.state.approved_date : "_")}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center">Financial</td>
                        <td className="text-center">
                          {this.state.approved_by_name_2}
                        </td>
                        <td className="text-center">
                          {(this.state.approved_date_2 ? this.state.approved_date_2 : "_")}
                        </td>
                      </tr>
                    </>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <footer className="row  modal-footer justify-content-end bottom_buttons_section">
            <button
              type="button"
              className="drawer_button drawer_text drawer_calculate_button drawer_calculate_adjustment"
              name="submit"
              onClick={() => {
                this.props.returnCallbackDrawerClose(false);
              }}
            >
              Close
            </button>
            {(this.state.approval_status === "Pending"
              ? this.state.features.includes("134") ||
              this.state.features.includes("394") ||
              this.state.features.includes("97")
              : this.state.features.includes("97")) && (
                <button
                  type="button"
                  className="drawer_button drawer_text"
                  onClick={() => this.props.returnEditCallback(true)}
                >
                  Edit
                </button>
              )}
          </footer>
        </div >
      );
    }
  }
}
