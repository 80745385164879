import React, { Component } from 'react';
import CookieHandler from '../common/CookieHandler';
// import config from '../../config/config';
import api from '../../api/api';
import {
  Snackbar, Drawer,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import config from '../../config/config';
import BusinessNotifyPartyAdd from './Notify-Party-Add';
import BusinessNotifyPartyView from './Notify-Party-View';
import BusinessNotifyPartyUpdate from './Notify-Party-Update';
import Loader from '../common/Loader';

// import BuyerDemurrageUpdate from './Buyer-Demurrage-Update';
// import BuyerDemurrageView from './Buyer-Demurrage-View';

export default class BusinessNotifyPartyList extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      showDrawer: false,
      isLoading: true,
      notifyPartyAdd: false,
      notifyPartyView: false,
      notifyPartyUpdate: false,
      features: [],
      comboID: null,
      notify_party_data: [],
      demurrage_type: 'company_to_buyer'
    }
  }

  componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace('/logout');
    }
    const loginUserID = this.Cookie.getCookie('loginUserId');
    const idToken = this.Cookie.getIdTokenCookie();
    const business_no_id = this.props.businessNo;
    api.get_business_no_notify_partys(loginUserID, idToken, business_no_id).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === '200') {
            this.setState({
              notify_party_data: res.notify_party_data,
              isLoading: false
            })

          } else if (res.code === '601') {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message
            })

          } else if (res.code === '607') {
            window.location.href = '/logout';
          }
        })
      }
    })
  }

  deleteDemurrageHandler = (demurrage_id) => {
    const loginUserID = this.Cookie.getCookie('loginUserId');
    const idToken = this.Cookie.getIdTokenCookie();

    if (this.state.demurrage_type === "buyer_to_company") {
      api.delete_barge_demurrage_from_buyer(loginUserID, idToken, demurrage_id).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === '200') {
              this.setState({
                snackBarSuccessOpen: true,
                successMsg: res.message
              }, () => {
                window.$("#deleteCoalCostingModal").modal("hide");
                this.buyerToCompanyDemurrages();
              })
            } else if (res.code === '601') {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            }
            else if (res.code === '607') {
              window.location.href = '/logout';
            } else if (res.code === '650') {
              window.$("#deleteCoalCostingModal").modal("hide");
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message
              });
            }
          })
        }
      })

    } else if (this.state.demurrage_type === 'company_to_buyer') {
      api.delete_barge_demurrage_to_buyer(loginUserID, idToken, demurrage_id).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === '200') {
              this.setState({
                snackBarSuccessOpen: true,
                successMsg: res.message
              }, () => {
                window.$("#deleteCoalCostingModal").modal("hide");
                this.companyToBuyerDemurrages();
              });
            } else if (res.code === '601') {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            } else if (res.code === '607') {
              window.location.href = '/logout';
            } else if (res.code === '650') {
              window.$("#deleteCoalCostingModal").modal("hide");
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message
              });
            }
          })
        }
      })
    }

  }

  onAddResponse = (value) => {
    this.setState({ notifyPartyAdd: false, showDrawer: false, }, () => {
      this.componentDidMount()
    })
  }

  editCallback = (childData) => {
    this.setState({
      showDrawer: true,
      notifyPartyAdd: false,
      notifyPartyUpdate: true,
      notifyPartyView: false,
    });
  }

  onUpdateResponse = (value) => {
    if (value) {
      this.setState({
        showDrawer: true,
        notifyPartyAdd: false,
        notifyPartyView: true,
        notifyPartyUpdate: false,
      }, () => this.componentDidMount())
    }
  }

  CallbackViewPage = (childData) => {
    this.setState({
      showDrawer: true,
      notifyPartyView: true,
      notifyPartyUpdate: false,
    });
  }

  CallbackDrawerClose = (childData) => {
    this.setState({
      showDrawer: childData,

      notifyPartyAdd: false,
      supplierDemurrageView: false
    });
  }

  toLocaleString(value) {
    return value ? Number(value).toLocaleString() : 0;
  }
  
  render() {
    let drawerComponent;
    if (this.state.notifyPartyAdd && this.props.businessNo) {
      drawerComponent = <BusinessNotifyPartyAdd businessNo={this.props.businessNo}
        onAddResponse={this.onAddResponse}
        returnCallbackDrawerClose={this.CallbackDrawerClose} />;
    }
    if (this.state.notifyPartyView && this.props.businessNo) {
      drawerComponent = <BusinessNotifyPartyView businessNo={this.props.businessNo} demurrageData={this.state.demurrageData}
        comboID={this.state.comboID} returnCallbackDrawerClose={this.CallbackDrawerClose}
        returnEditCallback={this.editCallback} />;
    }
    if (this.state.notifyPartyUpdate && this.props.businessNo) {
      drawerComponent = <BusinessNotifyPartyUpdate businessNo={this.props.businessNo} demurrageData={this.state.demurrageData}
        comboID={this.state.comboID}
        onUpdateCosting={this.onUpdateResponse} returnCallbackViewPage={this.CallbackViewPage} />;
    }
    if (this.state.isLoading) {
      return <Loader />
    }
    else {

      return (
        <div className='row w-100'>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={this.state.snackBarErrorOpen} onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: '450px' }}
          >
            <Alert elevation={6} variant="filled" onClose={() => this.setState({ snackBarErrorOpen: false })} severity="error" style={{ width: '100%' }}>
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            style={{ width: '450px' }}
            open={this.state.snackBarSuccessOpen} autoHideDuration={100000} onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert elevation={6} variant="filled" onClose={() => this.setState({ snackBarSuccessOpen: false })} severity="success" style={{ width: '100%' }}>
              {this.state.successMsg}
            </Alert>
          </Snackbar>


          <Drawer anchor="right" open={this.state.showDrawer} variant="temporary" elevation={20} style={{ overflow: 'initial' }} >
            <div className='row p-0' style={{ width: 800 }} >
              {drawerComponent}
            </div>
          </Drawer>
          <div className='card mt-2 p-2 w-100'>
            <div className='card-body'>
              <div className='row'>
                {this.state.notify_party_data.length === 0 &&
                  <div className='col'>
                    <p>No records to display</p>
                  </div>
                }
                <div className='col text-right float-sm-left'>
                  <button className='header_button header_button_text add_button_adjustment' style={{ width: 'auto', marginTop: "25px" }}
                    onClick={(event) => this.setState({ showDrawer: true, notifyPartyAdd: true, anchorEl: event.currentTarget, })}
                  >Add Notify Party</button>
                </div>
              </div>
              {this.state.notify_party_data.length > 0 &&
                <div className='row border rounded p-3'>
                  <div className='table-responsive'>
                    <table className='table table-sm table-borderless table-hover border-0'>
                      <thead>
                        <tr>
                          <th nowrap='true' className="allocation_table_header_label">Name</th>
                          <th nowrap='true' className="allocation_table_header_label">Phone No</th>
                          <th nowrap='true' className="allocation_table_header_label">Address</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.notify_party_data.map((e, idx) => (
                          <tr key={idx}>
                            <td style={{ textTransform: 'uppercase', color: config.themeColor, cursor: 'pointer' }}
                              onClick={() => this.setState({
                                comboID: e.id,
                                notifyPartyView: true,
                                showDrawer: true
                              })}>{e.name}</td>
                            <td>{e.phone_no ? e.phone_no : '-'} </td>
                            <td style={{ textTransform: 'uppercase', whiteSpace: 'pre-line' }}>{e.address ? e.address : '-'}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              }

            </div>

          </div>

          {/**
               * 
               * Delete Payament Postings Conformation Modal
               * 
               */}

          <div className='modal fade' id='deleteCoalCostingModal' role='dialog' tabIndex='-1'>
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header border-0" style={{ backgroundColor: '#f1f1f1' }} >
                  <h6 className="modal-title" style={{ border: 'none', padding: 0, }}><i className="fa fa-exclamation-triangle" aria-hidden="true" style={{ color: '#e0a51e', marginBottom: '5px' }} ></i>&emsp; Are you sure to delete ?</h6>
                </div>
                <div className="modal-footer border-0">
                  <button className='btn btn-sm btn-warning mr-3' onClick={() => this.deleteDemurrageHandler(this.state.demurrage_id)}>Ok</button>
                  <button className='btn btn-sm btn-secondary' data-dismiss="modal">Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
}
