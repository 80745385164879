import React, { Component } from "react";

import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import api from "../../../api/api";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { localDateFormate, toLocaleString } from "../../common/common";
import ViewFiles from "../../common/ViewFiles";
import InvoiceForm from "../../common/InvoiceForm";
import PaymentPostings from "../../common/PaymentPostings";
import Loader from "../../common/Loader";
import InvoiceLink from "../../InvoicePdf/InvoiceLink";
import AuditInfo from "../../common/AuditInfo";
export default class MVStevedorCostingView extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      stevedore_costing: [
        {
          stevedor_on_mv: "",
          quantity_in_mt: "",
          stevedore_price_pmt: "",
          vat_applicability: "",
          stevedore_base_price: "",
          stevedore_vat_percent: 10,
          stevedore_vat_value: "",
          stevedore_total: "",
          stevedore_pph23_percent: 2,
          stevedore_pph23_value: "",
          stevedore_payable_to_vendor: "",
          stevedore_cost_to_company: "",

          stevedore_price_per_addl_day: "",
          stevedore_addl_days: "",
          stevedore_addl_base_price: "",
          stevedore_addl_vat_percent: "",
          stevedore_addl_vat_value: "",
          stevedore_addl_pph23_percent: "",
          stevedore_addl_pph23_value: "",
          stevedore_addl_total: "",
          stevedore_addl_payable_to_vendor: "",
          stevedore_addl_cost_to_company: "",
          pnbp_per_mt: "",
          pnbp: "",
          jasa_pbm_calculation: "",
          jasa_pbm_pmt: "",
          jasa_pbm_value: "",
          costing_no: "",
        },
      ],
      getBarges: [],
      status: "",
      // Payment Postings
      stevedor_price_payments: [],
      isLoading: true,
      features: [],
      invoiceDialog: false,
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (
      !(
        features.includes("227") ||
        features.includes("413") ||
        features.includes("464") ||
        features.includes("97")
      )
    ) {
      window.location.href = "/dashboard";
    }
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const businessNoID = this.props.businessNoID;
    //Get businessNumber API based on businessNumber
    await api
      .getBusinessNumber(businessNoID, loginUserID, idToken)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.business_number) {
                this.setState({
                  businessNo: res.business_number.business_no
                    ? res.business_number.business_no
                    : "",
                  mother_vessel_name: res.business_number.mother_vessel_name
                    ? res.business_number.mother_vessel_name
                    : "",
                  //isLoading: false
                });
              }
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                snackBarErrorOpen: true,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });

    const stevedoreID = this.props.stevedoreID;
    var data = [];
    //Get businessNumber API based on businessNumber
    await api
      .getStevedoreCosting(loginUserID, idToken, stevedoreID)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            data = res.mv_stevedore_costing;
          });
        }
      });

    await api
      .get_mv_purchase_financials_stevedore(loginUserID, idToken, businessNoID)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.mv_pur_fin_stevedore_costing) {
                // var row = res.mv_pur_fin_stevedore_costing;
                var row = data;
                for (var i = 0; i < row.length; i++) {
                  var total_payable_to_vendor = 0;
                  var total_paid_to_vendor = 0;
                  if (
                    ((config.company === "SRPL" ||
                      config.company === "AVS" ||
                      config.company === "PRPL") &&
                      row[i].billing_currency === "IDR") ||
                    ((config.company === "SDAM" ||
                      config.company === "SRK" ||
                      config.company === "BTR") &&
                      row[i].billing_currency === "USD")
                  ) {
                    row[i].pnbp_company_to_billing = (
                      Number(row[i].quantity) * Number(row[i].pnbp_per_mt)
                    ).toFixed(2);
                    row[i].jasa_pbm_company_to_billing = (
                      (Number(row[i].quantity) * Number(row[i].jasa_pbm_pmt)) /
                      100
                    ).toFixed(2);
                  } else {
                    row[i].pnbp_company_to_billing = "";
                    row[i].jasa_pbm_company_to_billing = "";
                  }
                  total_payable_to_vendor += Number(
                    row[i].stevedore_payable_to_vendor
                      ? row[i].stevedore_payable_to_vendor
                      : 0
                  );
                  total_payable_to_vendor += Number(
                    row[i].stevedore_addl_payable_to_vendor
                      ? row[i].stevedore_addl_payable_to_vendor
                      : 0
                  );
                  total_payable_to_vendor += Number(
                    row[i].pnbp ? row[i].pnbp : 0
                  );
                  total_payable_to_vendor += Number(
                    row[i].jasa_pbm_value ? row[i].jasa_pbm_value : 0
                  );
                  total_payable_to_vendor += Number(
                    row[i].wait_period_total_price
                      ? row[i].wait_period_total_price
                      : 0
                  );
                  for (var j of row[i].others) {
                    total_payable_to_vendor += Number(j.price);
                  }
                  for (var k of row[i].stevedore_costing_payments) {
                    total_paid_to_vendor += Number(k.amount);
                  }

                  row[i].total_payable_to_vendor = total_payable_to_vendor;
                  row[i].total_paid_to_vendor = total_paid_to_vendor;
                  row[i].remaining_amount =
                    Number(total_payable_to_vendor) -
                    Number(total_paid_to_vendor);
                }
                this.setState({
                  stevedore_costing: row,
                  costing_no: res.mv_pur_fin_stevedore_costing[0].costing_no,
                  isLoading: false,
                });
              }
              if (res.mv_pur_fin_stevedore_costing.length <= 0) {
                this.setState({
                  errorMsg: "No stecedore costing available.",
                  snackBarErrorOpen: true,
                  isLoading: false,
                });
                setTimeout(
                  () => this.props.returnCallbackDrawerClose(false),
                  2000
                );
              }
            }
          });
        }
      });
  }

  approveHandler = () => {
    this.setState({ isLoading: true });
    let request = {
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
      costing_id: this.props.stevedoreID,
      costing_type: "MV Stevedore Costing",
      approval_status: "Approved",
    };
    api.approve_costing_api(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.setState(
              {
                snackBarSuccessOpen: true,
                successMsg: res.message,
              },
              () => this.componentDidMount()
            );
          } else if (res.code === "601") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
  };

  updateInvoice = (data) => {
    let request = {
      ...data,
      table_name: "mv_pur_fin_stevedore_costing",
    };
    api.update_invoice_details(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.componentDidMount();
            this.setState({
              invoiceDialog: false,
            });
          }
        });
      }
    });
  };

  renderPaymentPostings = (data) => {
    const company = config.company;
    const {
      id,
      stevedore_costing_payments = [],
      invoice_no = null,
      remaining_amount = 0,
      currency_exchange_rate = 1,
      approval_status = "Pending",
      billing_currency = company === "SRPL" ||
        company === "AVS" ||
        company === "PRPL"
        ? "USD"
        : "IDR",
    } = data;
    let payment_related_data = {
      paymentTitle: "MV Stevedore Costing",
      payment_posting_type: "Stevedore_Vendor",
      invoice_no: invoice_no,
      outstanding_amount: remaining_amount,
      billing_currency: billing_currency,
      currency_xchg_rate: currency_exchange_rate ? currency_exchange_rate : 1,
      costing_id: id,
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
    };

    let access_details = {
      costing_approval: approval_status,
      add_payment: "230",
      view_payment: "231",
      update_payment: "232",
      delete_payment: "233",
      approver: "414",
      approver2: "465",
    };

    return (
      <PaymentPostings
        access_details={access_details}
        payments={stevedore_costing_payments}
        costingType="MV Stevedore Costing"
        data={payment_related_data}
        callbackFn={() => {
          this.componentDidMount();
        }}
      />
    );
  };

  renderInvoiceLink = () => {


    let invoiceObject = {};

    for (var i of this.state.stevedore_costing) {
      invoiceObject["costing_id"] = i.costing_no;
      invoiceObject["to"] = i.stevedore_vendor_name;
      invoiceObject["business_no"] = this.state.mother_vessel_name;
      invoiceObject["costing_name"] = "MV Stevedore Costing";
      invoiceObject["invoice_no"] = i.invoice_no;
      invoiceObject["invoice_date"] = localDateFormate(i.invoice_date);
      invoiceObject["bargeDetails"] = [];
      invoiceObject["billing_currency"] =
        config.company === "SRPL" ||
          config.company === "AVS" ||
          config.company === "PRPL"
          ? "USD"
          : "IDR";
      invoiceObject["total_payable_amount"] = i.total_payable_to_vendor;
      invoiceObject["purchase_type"] = "FOB Barge";
      invoiceObject["description"] = [
        {
          qty: toLocaleString(i.quantity),
          adjustedPrice: toLocaleString(i.stevedore_price_pmt),
          desc: `${i.currency_xchg_rate > 1
            ? `Exchange rate @ ${i.currency_xchg_rate}`
            : ""
            }`,
          // amount: i.stevedore_total,
          amount: i.stevedore_base_price,
        },

        // ...(this.state.pph15_account !== "SDAM"
        //   ? [
        //       {
        //         qty: " ",
        //         adjustedPrice: " ",
        //         desc: "VAT",
        //         amount: `${toLocaleString(
        //           this.state.stevedore_costing[0].stevedore_vat_value
        //         )}`,
        //       },
        //     ]
        //   : []),
        // ...(!(
        //   config.company === "SRPL" ||
        //   config.company === "AVS" ||
        //   config.company === "PRPL"
        // )
        //   ? [
        //       {
        //         qty: " ",
        //         adjustedPrice: " ",
        //         desc: "PPH",
        //         amount: `( ${toLocaleString(
        //           this.state.stevedore_costing[0].stevedore_pph23_value
        //         )} )`,
        //       },
        //     ]
        //   : []),

        {
          qty: toLocaleString(i.stevedore_addl_days),
          adjustedPrice: toLocaleString(i.stevedore_price_per_addl_day),
          desc: `Additional Charges`,
          amount: i.stevedore_addl_base_price,
        },

        {
          qty: toLocaleString(i.wait_period_people),
          adjustedPrice: toLocaleString(i.wait_period_price_per_hour),
          desc: `Waiting Period`,
          amount: i.wait_period_total_price,
        },
        ...(i.others.length > 0
          ?
          i.others?.map((e, idx) => ({
            qty: " ",
            adjustedPrice: " ",
            desc: `Other Charges -  ${e.description}`,
            amount: `${toLocaleString(e.price ? e.price : 0)}`,
          }))

          : []),
        {
          qty: " ",
          adjustedPrice: " ",
          desc: "VAT",
          amount: `${toLocaleString(
            parseFloat(i.stevedore_vat_value ? i.stevedore_vat_value : 0) +
            parseFloat(
              i.stevedore_addl_vat_value ? i.stevedore_addl_vat_value : 0
            )
          )}`,
        },
        ...(!(
          config.company === "SRPL" ||
          config.company === "AVS" ||
          config.company === "PRPL"
        )
          ? [
            {
              qty: " ",
              adjustedPrice: " ",
              desc: "PPH",
              amount: `( ${toLocaleString(
                parseFloat(
                  i.stevedore_addl_pph23_value
                    ? i.stevedore_addl_pph23_value
                    : 0
                ) +
                parseFloat(
                  i.stevedore_pph23_value ? i.stevedore_pph23_value : 0
                )
              )} )`,
            },
          ]
          : []),
      ];
    }

    return <InvoiceLink data={invoiceObject} fileName={invoiceObject?.costing_id} />;
  };

  render() {
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div
          className="container bg-white p-0"
          style={{ minHeight: "100vh", width: 850 }}
        >
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          {this.state.stevedore_costing.map((e, idx) => (
            <div className="clearfix content-header border-bottom">
              <div className="row">
                <div className="col-lg-8 text-left pl-0">
                  <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                    {`Stevedore Costing - ${e.costing_no}`}&nbsp;
                    <span
                      className={
                        e.approval_status === "Pending" ? "type_2" : "type_1"
                      }
                      style={{ padding: 8, marginLeft: 16 }}
                    >
                      {e.approval_status}
                    </span>
                  </h4>
                </div>
                {e.approval_status === "Pending" &&
                  e.invoice_file &&
                  this.Cookie.getCookie("loginUserId") !== e.created_by &&
                  e.approved_by !== this.Cookie.getCookie("loginUserId") &&
                  (this.state.features.includes("413") ||
                    this.state.features.includes("464") ||
                    this.state.features.includes("97")) && (
                    <div
                      className="col text-right float-sm-left"
                      style={{ padding: "5px 20px" }}
                    >
                      <button
                        className="header_button header_button_text add_button_adjustment"
                        style={{ width: "auto", marginTop: "17px" }}
                        onClick={this.approveHandler}
                      >
                        Approve
                      </button>
                    </div>
                  )}
                <div className="col-lg-1 pl-0 float-right">
                  {this.renderInvoiceLink()}
                </div>
              </div>
            </div>
          ))}
          <div className="drawer-section">
            <div className="card">
              <div
                className="card-body p-0"
                style={{ backgroundColor: "rgba(25,118,210,.01)" }}
                id="accordion"
              >
                {this.state.stevedore_costing.map((e, idx) => (
                  <div className="col-lg-12 mb-2 p-0" key={idx}>
                    <div className="row">
                      <div className="col-lg-12 border_dashed rounded-lg p-2 mb-2">
                        <div className="table-responsive">
                          <table className="table table-bordered table-sm">
                            <thead className="table-header">
                              <tr>
                                <th className="text-center">Description</th>
                                <th className="text-right">Qty</th>
                                <th className="text-right">Unit Price</th>
                                <th className="text-right">Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <div>{`VAT Applicability : ${e.vat_applicability}`}</div>
                                  <div>{`${e.currency_exchange_rate > 1
                                    ? `Exchange Rate @ ${toLocaleString(
                                      e.currency_exchange_rate
                                    )}`
                                    : ``
                                    }`}</div>
                                </td>
                                <td className="text-right">
                                  {" "}
                                  {toLocaleString(e.quantity)}
                                </td>
                                <td className="text-right">
                                  {" "}
                                  {`${toLocaleString(e.stevedore_price_pmt)} ${e.billing_currency
                                    }`}
                                </td>
                                <td className="text-right">
                                  {" "}
                                  {toLocaleString(e.stevedore_base_price)}
                                </td>
                              </tr>
                              {/* <tr>
                                <td>{`VAT @ ${e.stevedore_vat_percent} `}</td>
                                <td className="text-right"></td>
                                <td className="text-right"></td>
                                <td className="text-right">
                                  {toLocaleString(e.stevedore_vat_value)}
                                </td>
                              </tr>
                              {!(
                                config.company === "SRPL" ||
                                config.company === "PRPL" ||
                                config.company === "AVS"
                              ) && (
                                <tr>
                                  <td>{`PPH23 @ 2 %`}</td>
                                  <td className="text-right"></td>
                                  <td className="text-right"></td>
                                  <td className="text-right">{`( ${toLocaleString(
                                    e.stevedore_pph23_value
                                  )} )`}</td>
                                </tr>
                              )} */}
                              <tr>
                                <td>
                                  <div>{`Additional Charges`}</div>
                                  <div>{`VAT Applicability : ${e.addl_day_vat_applicability}`}</div>
                                </td>
                                <td className="text-right">
                                  {" "}
                                  {toLocaleString(e.stevedore_addl_days)}
                                </td>
                                <td className="text-right">
                                  {" "}
                                  {`${toLocaleString(
                                    e.stevedore_price_per_addl_day
                                  )} ${e.billing_currency}`}
                                </td>
                                <td className="text-right">
                                  {" "}
                                  {toLocaleString(e.stevedore_addl_base_price)}
                                </td>
                              </tr>
                              <tr>
                                <td>{`VAT @ ${e.stevedore_addl_vat_percent} `}</td>
                                <td className="text-right"></td>
                                <td className="text-right"></td>
                                <td className="text-right">
                                  {toLocaleString(
                                    parseFloat(
                                      e.stevedore_vat_value
                                        ? e.stevedore_vat_value
                                        : 0
                                    ) +
                                    parseFloat(
                                      e.stevedore_addl_vat_value
                                        ? e.stevedore_addl_vat_value
                                        : 0
                                    )
                                  )}
                                </td>
                              </tr>
                              {!(
                                config.company === "SRPL" ||
                                config.company === "PRPL" ||
                                config.company === "AVS"
                              ) && (
                                  <tr>
                                    <td>{`PPH23 @ 2 %`}</td>
                                    <td className="text-right"></td>
                                    <td className="text-right"></td>
                                    <td className="text-right">{`( ${toLocaleString(
                                      parseFloat(
                                        e.stevedore_pph23_value
                                          ? e.stevedore_pph23_value
                                          : 0
                                      ) +
                                      parseFloat(
                                        e.stevedore_addl_pph23_value
                                          ? e.stevedore_addl_pph23_value
                                          : 0
                                      )
                                    )} )`}</td>
                                  </tr>
                                )}
                              {e.wait_period_total_price && (
                                <tr>
                                  <td>
                                    <div>{`Waiting Period`}</div>
                                  </td>
                                  <td className="text-right">
                                    {toLocaleString(e.wait_period_hours)}
                                  </td>
                                  <td className="text-right">
                                    {" "}
                                    {`${toLocaleString(
                                      e.wait_period_price_per_hour
                                    )} ${e.billing_currency}`}
                                  </td>
                                  <td className="text-right">
                                    {" "}
                                    {toLocaleString(
                                      e.wait_period_total_price
                                    )}{" "}
                                  </td>
                                </tr>
                              )}
                              {e.other_charges?.length > 0 && (
                                <>
                                  <tr>
                                    <td>
                                      {e.other_charges?.map((obj) => (
                                        <div>
                                          Other Charges - {obj.description}
                                        </div>
                                      ))}
                                    </td>
                                    <td className="text-right"></td>
                                    <td className="text-right"></td>
                                    <td className="text-right">{`${toLocaleString(
                                      e.other_charges?.reduce(
                                        (acc, item) =>
                                          Number(acc ? acc : 0) +
                                          Number(item.price ? item.price : 0),
                                        0
                                      )
                                    )}`}</td>
                                  </tr>
                                </>
                              )}
                              <tr>
                                <td
                                  colSpan={3}
                                  className="text-right "
                                  style={{ fontWeight: "bold" }}
                                >
                                  {` Payable to ${e.stevedore_vendor_name}`}
                                </td>
                                <td
                                  className="text-right"
                                  style={{ fontWeight: "bold" }}
                                >{`${toLocaleString(
                                  e.total_payable_to_vendor
                                )} ${config.company === "SRPL" ||
                                  config.company === "PRPL" ||
                                  config.company === "AVS"
                                  ? "USD"
                                  : "IDR"
                                  }`}</td>
                              </tr>
                              <tr>
                                <td
                                  colSpan={3}
                                  className="text-right "
                                  style={{ fontWeight: "bold" }}
                                >
                                  {" "}
                                  Paid
                                </td>
                                <td
                                  className="text-right "
                                  style={{ fontWeight: "bold" }}
                                ></td>
                              </tr>
                              <tr>
                                <td
                                  colSpan={3}
                                  className="text-right "
                                  style={{ fontWeight: "bold" }}
                                >
                                  {" "}
                                  Remaing Amount
                                </td>
                                <td
                                  className="text-right "
                                  style={{ fontWeight: "bold" }}
                                ></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        {/* <div className="row mb-2 ">
                          <div className="row col-lg-12 p-0">
                            <div className="col-lg-4 mb-2">
                              <label className="contract_display_header_label">
                                Approval{" "}
                                {e.approval_status === "Pending"
                                  ? ""
                                  : "Approved By"}
                              </label>
                              <div className="contract_display_header_value">
                                {" "}
                                {e.approval_status === "Pending"
                                  ? e.approval_status
                                  : e.approved_by_name}
                              </div>
                            </div>
                            <div className="col-lg-4 mb-2">
                              <label className="contract_display_header_label">
                                Approved On
                              </label>
                              <div className="contract_display_header_value">
                                {" "}
                                {e.approved_date
                                  ? localDateFormate(e.approved_date)
                                  : "-"}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 pl-0">
                            <div className="row">
                              <div className="col-lg-6 mb-2">
                                <label className="contract_display_header_label">
                                  Stevedore on MV
                                </label>
                                <div className="contract_display_header_value">
                                  {e.stevedore_vendor_name
                                    ? e.stevedore_vendor_name
                                    : "-"}
                                </div>
                              </div>
                              <div className="col-lg-6 mb-2">
                                <label className="contract_display_header_label">
                                  Quantity in MT
                                </label>
                                <div className="contract_display_header_value">
                                  {e.quantity
                                    ? toLocaleString(e.quantity)
                                    : "-"}
                                </div>
                              </div>

                              <div className="col-lg-6 mb-2">
                                <label className="contract_display_header_label">
                                  Billing Currency
                                </label>
                                <div className="contract_display_header_value">
                                  {e.billing_currency
                                    ? e.billing_currency
                                    : "-"}
                                </div>
                              </div>

                              <div className="col-lg-6 mb-2 pr-0">
                                <label className="contract_display_header_label">
                                  Price per MT
                                </label>
                                <div className="contract_display_header_value">
                                  {e.stevedore_price_pmt
                                    ? toLocaleString(e.stevedore_price_pmt)
                                    : 0}{" "}
                                  {e.billing_currency !== "0"
                                    ? e.billing_currency
                                    : ""}
                                </div>
                              </div>
                              {(config.company === "SRPL" ||
                                config.company === "AVS" ||
                                config.company === "PRPL") &&
                                e.billing_currency !== "USD" &&
                                e.billing_currency !== null && (
                                  <div className="col-lg-6 mb-2">
                                    <label className="contract_display_header_label">
                                      Exchange Rate
                                    </label>
                                    <div className="contract_display_header_value">
                                      {e.currency_exchange_rate
                                        ? e.currency_exchange_rate
                                        : "-"}
                                    </div>
                                  </div>
                                )}

                              {(config.company === "SDAM" ||
                                config.company === "SRK" ||
                                config.company === "BTR") &&
                                e.billing_currency !== "IDR" &&
                                e.billing_currency !== null && (
                                  <div className="col-lg-6 mb-2">
                                    <label className="contract_display_header_label">
                                      Exchange Rate
                                    </label>
                                    <div className="contract_display_header_value">
                                      {e.currency_exchange_rate
                                        ? e.currency_exchange_rate
                                        : "-"}
                                    </div>
                                  </div>
                                )}
                              <div className="col-lg-6 mb-2">
                                <label className="contract_display_header_label">
                                  VAT Applicability
                                </label>
                                <div className="contract_display_header_value">
                                  {e.vat_applicability
                                    ? e.vat_applicability
                                    : "-"}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="col-lg-6 p-2 rounded"
                            style={{ backgroundColor: "#f7f3f0" }}
                          >
                            <div className="row col-lg-12 p-0">
                              <h6>Stevedore Price</h6>
                            </div>
                            <div className="row">
                              <div className="col-lg-4 p-0 mb-2">
                                <label
                                  className="contract_display_header_label"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  Base price
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {e.stevedore_base_price
                                    ? toLocaleString(e.stevedore_base_price)
                                    : "-"}
                                </div>
                              </div>
                              <div className="col-lg-4 pr-0 mb-2">
                                <label
                                  className="contract_display_header_label"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  VAT %
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {e.stevedore_vat_percent
                                    ? toLocaleString(e.stevedore_vat_percent)
                                    : "-"}
                                </div>
                              </div>
                              <div className="col-lg-4 p-0 mb-2">
                                <label
                                  className="contract_display_header_label"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  VAT
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {e.stevedore_vat_value
                                    ? toLocaleString(e.stevedore_vat_value)
                                    : "-"}
                                </div>
                              </div>
                            </div>
                            {!(
                              config.company === "SRPL" ||
                              config.company === "AVS" ||
                              config.company === "PRPL"
                            ) && (
                              <div className="row">
                                <div className="col-lg-4  mb-2 p-0">
                                  <label
                                    className="contract_display_header_label"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    Total
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {e.stevedore_total
                                      ? toLocaleString(e.stevedore_total)
                                      : "-"}
                                  </div>
                                </div>

                                <div className="col-lg mb-2">
                                  <label
                                    className="contract_display_header_label"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    Includes PPH23 @ 2%
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {e.stevedore_pph23_value
                                      ? toLocaleString(e.stevedore_pph23_value)
                                      : "-"}
                                  </div>
                                </div>
                              </div>
                            )}

                            <div className="row">
                              <div
                                className={
                                  config.company === "SRPL" ||
                                  config.company === "AVS" ||
                                  config.company === "PRPL"
                                    ? "col-lg-6 p-0"
                                    : "col-lg-6 p-0"
                                }
                              >
                                <label
                                  className="contract_display_header_label"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  Payable to Vendor
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {e.stevedore_payable_to_vendor
                                    ? toLocaleString(
                                        e.stevedore_payable_to_vendor
                                      )
                                    : 0}{" "}
                                  {config.company === "SRPL" ||
                                  config.company === "AVS" ||
                                  config.company === "PRPL"
                                    ? "USD"
                                    : "IDR"}
                                </div>
                              </div>
                              <div
                                className={
                                  config.company === "SRPL" ||
                                  config.company === "AVS" ||
                                  config.company === "PRPL"
                                    ? "col-lg-6 pr-0"
                                    : "col-lg-6 pr-0"
                                }
                              >
                                <label
                                  className="contract_display_header_label"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  Cost to Company
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {e.stevedore_cost_to_company
                                    ? toLocaleString(
                                        e.stevedore_cost_to_company
                                      )
                                    : 0}{" "}
                                  {config.company === "SRPL" ||
                                  config.company === "AVS" ||
                                  config.company === "PRPL"
                                    ? "USD"
                                    : "IDR"}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <hr />

                        <div className="row mb-2 ">
                          <div className="row col-lg-12 p-0">
                            <h5>Additional Charges</h5>
                          </div>
                          <div className="row col-lg-12 p-0">
                            <div className="col-lg-6 pl-0">
                              <div className="row">
                                <div className="col-lg-6 mb-2">
                                  <label className="contract_display_header_label">
                                    Additional Days
                                  </label>
                                  <div>
                                    {e.stevedore_addl_days
                                      ? toLocaleString(e.stevedore_addl_days)
                                      : "-"}
                                  </div>
                                </div>

                                <div className="col-lg-6  mb-2">
                                  <label className="contract_display_header_label">
                                    Price Per Day
                                  </label>
                                  <div className="contract_display_header_value">
                                    {e.stevedore_price_per_addl_day
                                      ? toLocaleString(
                                          e.stevedore_price_per_addl_day
                                        )
                                      : 0}{" "}
                                    {e.billing_currency !== "0"
                                      ? e.billing_currency
                                      : ""}
                                  </div>
                                </div>

                                <div className="col-lg-12  mb-2 ">
                                  <label className="contract_display_header_label">
                                    VAT Applicability
                                  </label>
                                  <div className="contract_display_header_value">
                                    {e.addl_day_vat_applicability
                                      ? e.addl_day_vat_applicability
                                      : "-"}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="col-lg-6 p-2 rounded"
                              style={{ backgroundColor: "#f7f3f0" }}
                            >
                              <div className="row col-lg-12 p-0">
                                <h6>Stevedore Additional Days Price</h6>
                              </div>
                              <div className="row">
                                <div className="col-lg-4 p-0 mb-2">
                                  <label
                                    className="contract_display_header_label"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    Base price
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {e.stevedore_addl_base_price
                                      ? toLocaleString(
                                          e.stevedore_addl_base_price
                                        )
                                      : "-"}
                                  </div>
                                </div>
                                <div className="col-lg-4 pr-0 mb-2">
                                  <label
                                    className="contract_display_header_label"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    VAT %
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {e.stevedore_addl_vat_percent
                                      ? toLocaleString(
                                          e.stevedore_addl_vat_percent
                                        )
                                      : "-"}
                                  </div>
                                </div>
                                <div className="col-lg-4 p-0 mb-2">
                                  <label
                                    className="contract_display_header_label"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    VAT
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {e.stevedore_addl_vat_value
                                      ? toLocaleString(
                                          e.stevedore_addl_vat_value
                                        )
                                      : "-"}
                                  </div>
                                </div>
                              </div>
                              {!(
                                config.company === "SRPL" ||
                                config.company === "AVS" ||
                                config.company === "PRPL"
                              ) && (
                                <div className="row">
                                  <div className="col-lg-4 mb-2 p-0">
                                    <label
                                      className="contract_display_header_label"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Total
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {e.stevedore_addl_total
                                        ? toLocaleString(e.stevedore_addl_total)
                                        : "-"}
                                    </div>
                                  </div>

                                  <div className="col-lg mb-2">
                                    <label
                                      className="contract_display_header_label"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Includes PPH23 @ 2%
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {e.stevedore_addl_pph23_value
                                        ? toLocaleString(
                                            e.stevedore_addl_pph23_value
                                          )
                                        : "-"}
                                    </div>
                                  </div>
                                </div>
                              )}

                              <div className="row">
                                <div
                                  className={
                                    config.company === "SRPL" ||
                                    config.company === "AVS" ||
                                    config.company === "PRPL"
                                      ? "col-lg-6 p-0"
                                      : "col-lg-6 p-0"
                                  }
                                >
                                  <label
                                    className="contract_display_header_label"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    Payable to Vendor
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {e.stevedore_addl_payable_to_vendor
                                      ? toLocaleString(
                                          e.stevedore_addl_payable_to_vendor
                                        )
                                      : 0}{" "}
                                    {config.company === "SRPL" ||
                                    config.company === "AVS" ||
                                    config.company === "PRPL"
                                      ? "USD"
                                      : "IDR"}
                                  </div>
                                </div>
                                <div
                                  className={
                                    config.company === "SRPL" ||
                                    config.company === "AVS" ||
                                    config.company === "PRPL"
                                      ? "col-lg-6 pr-0"
                                      : "col-lg-6 pr-0"
                                  }
                                >
                                  <label
                                    className="contract_display_header_label"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    Cost to Company
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {e.stevedore_addl_cost_to_company
                                      ? toLocaleString(
                                          e.stevedore_addl_cost_to_company
                                        )
                                      : 0}{" "}
                                    {config.company === "SRPL" ||
                                    config.company === "AVS" ||
                                    config.company === "PRPL"
                                      ? "USD"
                                      : "IDR"}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <hr />

                        <div className="row mb-2">
                          <div className="row col-lg-12 p-0">
                            <h5>Waiting Period</h5>
                          </div>

                          <div className="row col-lg-12 p-0">
                            <div className="col-lg-6 mb-2">
                              <label className="contract_display_header_label">
                                No Of People
                              </label>
                              <div className="contract_display_header_value">
                                {e.wait_period_people
                                  ? toLocaleString(e.wait_period_people)
                                  : "-"}
                              </div>
                            </div>

                            <div className="col-lg-6 mb-2">
                              <label className="contract_display_header_label">
                                No Of Hours
                              </label>
                              <div className="">
                                {e.wait_period_hours
                                  ? toLocaleString(e.wait_period_hours)
                                  : "-"}
                              </div>
                            </div>

                            <div className="col-lg-6 mb-2">
                              <label className="contract_display_header_label">
                                Price Per Hour
                              </label>
                              <div className="contract_display_header_value">
                                {e.wait_period_price_per_hour
                                  ? toLocaleString(e.wait_period_price_per_hour)
                                  : "-"}{" "}
                                {e.billing_currency}
                              </div>
                            </div>

                            <div className="col-lg-6 mb-2">
                              <label className="contract_display_header_label">
                                Total Price
                              </label>
                              <div className="contract_display_header_value">
                                {e.wait_period_total_price
                                  ? toLocaleString(e.wait_period_total_price)
                                  : 0}{" "}
                                {config.company === "SRPL" ||
                                config.company === "AVS" ||
                                config.company === "PRPL"
                                  ? "USD"
                                  : "IDR"}
                              </div>
                            </div>
                          </div>
                        </div>

                        <hr />

                        {e.others.length > 0 && (
                          <div className="row mb-2">
                            <div className="row col-lg-12 p-0">
                              <h5>Others</h5>
                            </div>

                            {e.others.map((o, idex) => (
                              <div
                                className="row p-2 col-lg-12 border rounded-lg"
                                key={idex}
                              >
                                <div className="col-lg-6 mb-2">
                                  <label className="contract_display_header_label">
                                    Description
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ textTransform: "uppercase" }}
                                  >
                                    {o.description ? o.description : "-"}
                                  </div>
                                </div>

                                <div className="col-lg-6 mb-2">
                                  <label className="contract_display_header_label">
                                    Price
                                  </label>
                                  <div className="contract_display_header_value">
                                    {o.price ? toLocaleString(o.price) : 0}
                                    {config.company === "SRPL" ||
                                    config.company === "AVS" ||
                                    config.company === "PRPL"
                                      ? "USD"
                                      : "IDR"}
                                  </div>
                                </div>

                                <div className="col-lg-12 mb-2">
                                  <label className="contract_display_header_label">
                                    Remarks
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{
                                      textTransform: "uppercase",
                                      whiteSpace: "pre-line",
                                    }}
                                  >
                                    {o.remarks ? o.remarks : "-"}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        )} */}

                        <div className="card col-lg-12 p-0">
                          <div className="card-body">
                            <InvoiceForm
                              open={this.state.invoiceDialog}
                              costingID={e.id}
                              data={e}
                              callbackFn={(data) => this.updateInvoice(data)}
                            />

                            {(this.state.features.includes("231") ||
                              this.state.features.includes("97")) && (
                                <div>{this.renderPaymentPostings(e)}</div>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <ViewFiles
                      {...this.props}
                      files_data={e.files}
                      type="Costings"
                      invoice_file={e.invoice_file}
                    />

                    <AuditInfo info={e} />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button btn-light"
              name="submit"
              onClick={() => {
                this.props.returnCallbackDrawerClose(false);
              }}
            >
              Close
            </button>
            <button
              type="button"
              className="drawer_text drawer_btn_success"
              name="Back"
              onClick={() => {
                this.props.returnEditCallback("MvStevedoreCosting");
              }}
            >
              Edit
            </button>
          </div>
        </div>
      );
    }
  }
}
