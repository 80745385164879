import React, { Component } from "react";

import { Snackbar, Drawer } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import api from "../../../api/api";

// import mother vessel floatig crane
import MVStevedorCostingAdd from "./StevedoreCostAdd";
import MVStevedorCostingView from "./StevedoreCostView";
import MVStevedorCostingUpdate from "./StevedoreCostUpdate";
import Loader from "../../common/Loader";
import { toLocaleString } from "../../common/common";
export default class MVStevedorCostingList extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      openDrawer: false,
      openUpdateDrawer: false,
      status: "",
      // Payment Postings
      stevedor_price_payments: [],
      stevedore_costing: [
        {
          floating_crane_vendor_id: "",
          floating_crane_vendor_name: "",
          floating_crane_charges: [
            {
              floating_crane_type: "Floating Crane",
              floating_crane_vendor_id: "",
              floating_crane_vendor_name: "",
              quantity: "",
              billing_currency: "0",
              floating_crane_price_pmt: "",
              currency_exchange_rate: "",
              local_currency: "",
              vat_applicability: "",
              floating_crane_base_price: "",
              floating_crane_vat_percent: 10,
              floating_crane_vat_value: "",
              floating_crane_total: "",
              floating_crane_pph23_percent: 2,
              floating_crane_pph23_value: "",
              floating_crane_payable_to_vendor: "",
              floating_crane_cost_to_company: "",

              floating_crane_price_per_addl_day: "",
              floating_crane_addl_days: "",
              floating_crane_addl_base_price: "",
              floating_crane_addl_vat_percent: 10,
              floating_crane_addl_vat_value: "",
              floating_crane_addl_pph23_percent: 2,
              floating_crane_addl_pph23_value: "",
              floating_crane_addl_total: "",
              floating_crane_addl_payable_to_vendor: "",
              floating_crane_addl_cost_to_company: "",
              pnbp_per_mt: "",
              pnbp: "",
              pnbp_company_to_billing: "",
              jasa_pbm_calculation: "",
              jasa_pbm_pmt: "",
              jasa_pbm_value: "",
              jasa_pbm_company_to_billing: "",
              status: "",
              wait_period_people: "",
              wait_period_hours: "",
              wait_period_price_per_hour: "",
              wait_period_total_price: "",
              others: [],
            },
          ],
        },
      ],
      isLoading: true,
      features: [],
      updateDocuments: false,
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (!(features.includes("227") || features.includes("97"))) {
      window.location.href = "/dashboard";
    }
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const businessNoID = this.props.businessNoID;
    await api
      .get_mv_purchase_financials_stevedore(loginUserID, idToken, businessNoID)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.mv_pur_fin_stevedore_costing) {
                // var row = res.stevedore_costing;
                // let finalData= [];
                // for(var i=0; i<res.stevedore_costing.length; i++) {
                //   for(var j=0; j<res.stevedore_costing[i].floating_crane_charges.length; j++){
                //     row[i].floating_crane_charges[j].floating_crane_vendor_name= row[i].floating_crane_vendor_name
                //   }
                //   finalData= finalData.concat(row[i].floating_crane_charges);
                // }
                // let newData= [{floating_crane_charges: []}];
                // newData[0].floating_crane_charges= finalData;
                this.setState({
                  stevedore_costing: res.mv_pur_fin_stevedore_costing,
                  isLoading: false,
                });
              }
              if (res.mv_pur_fin_stevedore_costing.length === 0) {
                this.setState({
                  errorMsg: "No floating crane charges available.",
                  // snackBarErrorOpen: true,
                  isLoading: false,
                });
                // setTimeout(
                //   ()=>this.props.returnCallbackDrawerClose(false),
                //   2000
                // );
              }
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                snackBarErrorOpen: true,
              });
            }
          });
        }
      });

    document.title =
      config.documentTitle +
      "Mother Vessel Purchase Financial Stevedore Costing";
  }

  onAddResponse = (value) => {
    if (value.code === "200") {
      this.setState(
        {
          MVStevedorCostingAdd: false,
          MVStevedorCostingView: false,
          MVStevedorCostingUpdate: false,
          openDrawer: false,
          updateDocuments: false,
        },
        () => this.componentDidMount()
      );
    } else {
      this.setState({
        MVStevedorCostingAdd: false,
        MVStevedorCostingView:
          value.screen === "MvFloatingCrane" &&
            value.message === "alreadyDataAvailable"
            ? true
            : false,
        MVStevedorCostingUpdate: false,
        openDrawer: true,
        updateDocuments: false,
      });
    }
  };

  CallbackDrawerClose = (childData) => {
    this.setState(
      {
        openDrawer: childData,
        MVStevedorCostingAdd: false,
        MVStevedorCostingView: false,
        MVStevedorCostingUpdate: false,
        updateDocuments: false,
      },
      () => this.componentDidMount()
    );
  };

  onUpdateResponse = (value) => {
    if (value) {
      this.setState(
        {
          MVStevedorCostingAdd: false,
          MVStevedorCostingView:
            value.code === "200" && value.screen === "MvStevedoreCosting"
              ? true
              : false,
          MVStevedorCostingUpdate: false,
        },
        () => this.componentDidMount()
      );
    }
  };

  CallbackViewPage = (childData) => {
    this.setState(
      {
        openDrawer: true,
        MVStevedorCostingUpdate: false,
        MVStevedorCostingAdd: false,
        MVStevedorCostingView:
          childData === "MvStevedoreCosting" ? true : false,
        updateDocuments: false,
      },
      () => this.componentDidMount()
    );
  };

  editCallback = (childData) => {
    this.setState(
      {
        openDrawer: true,
        MVStevedorCostingUpdate:
          childData === "MvStevedoreCosting" ? true : false,
        MVStevedorCostingAdd: false,
        MVStevedorCostingView: false,
        updateDocuments: false,
      },
      () => this.componentDidMount()
    );
  };

  deleteCharges = (data) => {
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    var finalData = data;
    finalData.mv_stevedore_id = data.id;
    finalData.login_user_id = loginUserID;
    finalData.idtoken = idToken;
    finalData.status = "Deleted";
    //Delete Stevedore costing charge
    api.update_mv_stevedore_costing(finalData).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.setState(
              {
                snackBarSuccessOpen: true,
                successMsg: res.message,
              },
              () => this.componentDidMount()
            );
          } else if (res.code === "601") {
            this.setState({ errorMsg: res.message, snackBarErrorOpen: true });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          } else if (res.code === "624") {
            this.setState({
              errorMsg: res.columns.length > 0 ? res.columns[0] : "",
              snackBarErrorOpen: true,
            });
          }
        });
      }
    });
  };

  render() {
    let drawerComponent;
    if (this.state.MVStevedorCostingAdd) {
      drawerComponent = (
        <MVStevedorCostingAdd
          businessNoID={this.props.businessNoID}
          onAddResponse={this.onAddResponse}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
        />
      );
    } else if (this.state.MVStevedorCostingView && this.state.businessNo) {
      drawerComponent = (
        <MVStevedorCostingView
          stevedoreID={this.state.stevedoreID}
          businessNoID={this.state.businessNo}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.editCallback}
          returnDocumentsHandler={() => {
            this.setState({
              showDrawer: true,
              MVStevedorCostingUpdate: true,
              MVStevedorCostingView: false,
              MVStevedorCostingAdd: false,
              updateDocuments: true,
            });
          }}
        />
      );
    } else if (this.state.MVStevedorCostingUpdate && this.state.businessNo) {
      drawerComponent = (
        <MVStevedorCostingUpdate
          stevedoreID={this.state.stevedoreID}
          businessNoID={this.state.businessNo}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.CallbackViewPage}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div>
          <div className="row clearfix content-header border-bottom">
            <div className="row clearfix content-header border-bottom">
              <h4 className="mt-2" style={{ padding: "4px" }}>
                {` Stevedore Costing  - ${this.props.mother_vessel_name ? this.props.mother_vessel_name : ''} ${this.props.name ? `( ${this.props.name} )` : ''}`}
              </h4>
              {(this.state.features.includes("226") ||
                this.state.features.includes("97")) && (
                  <i
                    className="fa fa-plus-circle ml-2 mt-3"
                    aria-hidden="true"
                    style={{
                      fontSize: 22,
                      color: config.themeColor,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      this.setState({
                        openDrawer: true,
                        MVStevedorCostingAdd: true,
                        MVStevedorCostingView: false,
                        MVStevedorCostingUpdate: false,
                        updateDocuments: false,
                      });
                    }}
                  />
                )}
            </div>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <Drawer
            anchor="right"
            open={this.state.openDrawer}
            variant="temporary"
            elevation={20}
            style={{ overflow: "initial" }}
          >
            <div className="row" style={{ width: 800 }}>
              {drawerComponent}
            </div>
          </Drawer>
          <div className="card pl-0 pr-0">
            <div className="col-lg-12 mb-2 p-0">
              <div className="row">
                <div className="table-responsive">
                  <table className="table table-bordered table-sm">
                    <thead className="table-header">
                      <tr>
                        <th nowrap="true">Costing ID</th>
                        <th nowrap="true">Stevedore on MV</th>
                        <th nowrap="true" className="text-right">
                          {" "}
                          Quantity in MT{" "}
                        </th>
                        <th nowrap="true" className="text-right">
                          {" "}
                          Stevedore Costing Price
                        </th>
                        <th nowrap="true" className="text-right">
                          {" "}
                          Waiting Period{" "}
                        </th>
                        <th nowrap="true" className="text-left pl-4">
                          {" "}
                          Action
                        </th>
                      </tr>
                    </thead>
                    {this.state.stevedore_costing.length > 0 ? (
                      <tbody>
                        {this.state.stevedore_costing.map((idr, indx) => (
                          <tr key={indx}>
                            <td className="allocation_table_value">
                              {(this.state.features.includes("227") ||
                                this.state.features.includes("97")) && (
                                  <i
                                    aria-hidden="true"
                                    style={{
                                      fontStyle: "normal",
                                      color: config.themeColor,
                                      cursor: "pointer",
                                      whiteSpace: "nowrap",
                                    }}
                                    onClick={() =>
                                      this.setState({
                                        openDrawer: true,
                                        MVStevedorCostingAdd: false,
                                        MVStevedorCostingView: true,
                                        MVStevedorCostingUpdate: false,
                                        businessNo: idr.business_no_id,
                                        stevedoreID: idr.id,
                                        updateDocuments: false,
                                      })
                                    }
                                  >
                                    {idr.costing_no}
                                  </i>
                                )}
                            </td>
                            <td
                              className="allocation_table_value"
                              nowrap="true"
                            >
                              {idr.stevedore_vendor_name}
                            </td>
                            <td
                              className="allocation_table_value text-right"
                              nowrap="true"
                            >
                              {idr.quantity ? toLocaleString(idr.quantity) : 0}
                            </td>
                            <td
                              className="allocation_table_value text-right"
                              nowrap="true"
                            >
                              {idr.stevedore_payable_to_vendor
                                ? toLocaleString(
                                  idr.stevedore_payable_to_vendor
                                )
                                : 0}{" "}
                              {config.company === "SRPL" ||
                                config.company === "AVS" ||
                                config.company === "PRPL"
                                ? "USD"
                                : "IDR"}
                            </td>
                            {/* <td
                              className="allocation_table_value text-right"
                              nowrap="true"
                            >
                              {idr.pnbp ? toLocaleString(idr.pnbp) : 0}{" "}
                              {config.company === "SRPL" ||
                              config.company === "AVS" ||
                              config.company === "PRPL"
                                ? "USD"
                                : "IDR"}{" "}
                              {idr.pnbp_company_to_billing
                                ? "(" +
                                  toLocaleString(idr.pnbp_company_to_billing) +
                                  " " +
                                  idr.billing_currency +
                                  ")"
                                : null}
                            </td>
                            <td
                              className="allocation_table_value text-right"
                              nowrap="true"
                            >
                              {idr.jasa_pbm_value
                                ? toLocaleString(idr.jasa_pbm_value)
                                : 0}{" "}
                              {idr.billing_currency !== "0"
                                ? idr.billing_currency
                                : ""}
                            </td> */}
                            <td
                              className="allocation_table_value text-right"
                              nowrap="true"
                            >
                              {idr.wait_period_total_price
                                ? toLocaleString(idr.wait_period_total_price)
                                : 0}{" "}
                              {config.company === "SRPL" ||
                                config.company === "AVS" ||
                                config.company === "PRPL"
                                ? "USD"
                                : "IDR"}
                            </td>
                            <td
                              nowrap="true"
                              className="table_td text-left pl-4"
                            >
                              {(this.state.features.includes("228") ||
                                this.state.features.includes("97")) && (
                                  <i
                                    className="fa fa-pencil"
                                    style={{
                                      fontSize: 14,
                                      color: config.themeColor,
                                      cursor: "pointer",
                                    }}
                                    data-toggle="modal"
                                    title="Edit floating crane charges"
                                    data-placement="bottom"
                                    onClick={() =>
                                      this.setState({
                                        businessNo: idr.business_no_id,
                                        stevedoreID: idr.id,
                                        openDrawer: true,
                                        MVStevedorCostingUpdate: true,
                                        updateDocuments: false,
                                      })
                                    }
                                  ></i>
                                )}
                              {idr.payment_postings_availability === 0 &&
                                (this.state.features.includes("229") ||
                                  this.state.features.includes("97")) &&
                                idr.approval_status === "Pending" && (
                                  <i
                                    className="fa fa-trash-o"
                                    style={{
                                      paddingLeft: 10,
                                      fontSize: 14,
                                      color: config.themeColor,
                                      cursor: "pointer",
                                    }}
                                    data-toggle="modal"
                                    title="Delete floating crane charges"
                                    data-placement="bottom"
                                    data-keyboard="false"
                                    data-target="#deleteConformationModal"
                                    data-backdrop="static"
                                    onClick={() => this.deleteCharges(idr)}
                                  ></i>
                                )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td colSpan="5" className="p-2">
                            No data available
                          </td>
                        </tr>{" "}
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
