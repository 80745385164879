import React, { Component } from "react";
import api from "../../../api/api";
import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import { Alert } from "@material-ui/lab";
import {
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import FileUpload from "../../common/FileUpload";
import Loader from "../../common/Loader";

const NewRadio = withStyles({
  root: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export default class Barge_Purchase_Fin_Export_Tax_Update extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      export_tax_applicable: "Yes",
      quantity_for_export_tax: "",
      export_tax_per_mt: "",
      export_tax: "",
      export_tax_in_local_currency: "",
      export_currency_xchg_rate: "",
      isLoading: true,
      billing_currency: "USD",
      activeStep: 0,
      files: [],
      features: [],
      costing_files: [],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    } else {
      var features = this.Cookie.getCookie("features");
      this.setState({ features: features });
      // if (!(features.includes('176')  || features.includes('97') )) {
      //   window.location.href = '/dashboard';
      // }
      var loginUserID = this.Cookie.getCookie("loginUserId");
      var idToken = this.Cookie.getIdTokenCookie();
      try {
        const export_tax_id = this.props.exportTaxID;
        const get_export_tax = await api.get_barge_export_tax(
          loginUserID,
          idToken,
          export_tax_id
        );
        if (get_export_tax.status >= 200 && get_export_tax.status < 300) {
          get_export_tax.json().then((res) => {
            if (res.code === "200") {
              if (res.barge_export_tax) {
                this.setState({
                  costing_no: res.barge_export_tax[0].costing_no
                    ? res.barge_export_tax[0].costing_no
                    : "",
                  barge_id: res.barge_export_tax[0].barge_id
                    ? res.barge_export_tax[0].barge_id
                    : "",
                  export_tax_applicable: res.barge_export_tax[0]
                    .export_tax_applicable
                    ? res.barge_export_tax[0].export_tax_applicable
                    : "",
                  barge_nomination: res.barge_export_tax[0].barge_nomination
                    ? res.barge_export_tax[0].barge_nomination
                    : "",
                  quantity_for_export_tax: res.barge_export_tax[0]
                    .quantity_for_export_tax
                    ? res.barge_export_tax[0].quantity_for_export_tax
                    : "",
                  export_tax_per_mt: res.barge_export_tax[0].export_tax_per_mt
                    ? res.barge_export_tax[0].export_tax_per_mt
                    : "",
                  export_tax: res.barge_export_tax[0].export_tax
                    ? res.barge_export_tax[0].export_tax
                    : "",
                  export_tax_in_local_currency: res.barge_export_tax[0]
                    .export_tax_in_local_currency
                    ? res.barge_export_tax[0].export_tax_in_local_currency
                    : "",
                  export_currency_xchg_rate: res.barge_export_tax[0]
                    .export_currency_xchg_rate
                    ? res.barge_export_tax[0].export_currency_xchg_rate
                    : "",
                  files: res.barge_export_tax[0].files
                    ? res.barge_export_tax[0].files
                    : [],
                  invoice_file: res.barge_export_tax[0].invoice_file,
                  isLoading: false,
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
                isLoading: false,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      } catch (e) {
        window.location.href = "/barge-list";
      }
    }

    document.title =
      config.documentTitle + "Barge Purchase Financial Export Tax";
  }

  toLocaleString(value) {
    return value ? Number(value).toLocaleString() : 0;
  }

  submitHandler = () => {
    this.setState({ isLoading: true });
    var {
      export_tax_applicable,
      quantity_for_export_tax,
      export_tax_per_mt,
      export_tax,
      billing_currency,
      export_currency_xchg_rate,
      export_tax_in_local_currency,
    } = this.state;
    if (config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL") {
      export_currency_xchg_rate = 1;
      export_tax_in_local_currency = export_tax;
    }
    /**
     *  Checking Mandatory files
     */
    var missing_fields = 0;
    let files_missing = 0;
    var row = this.state.files;
    for (var obj of row) {
      if (obj.file_type !== "" || obj.file_content !== "") {
        if (obj.file_content !== "") {
          if (obj.file_description === "") {
            missing_fields++;
            obj.file_description_error = true;
          } else {
            obj.file_description = obj.file_description.toUpperCase();
          }
        } else if (obj.file_description !== "") {
          if (obj.file_content === "") {
            files_missing++;
          }
        }
      }
    }
    this.setState({
      files: row,
    });

    if (missing_fields > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please fill mandatory fields.",
      });
      return;
    }
    if (files_missing > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please Select file.",
      });
      return;
    }
    var existing_file_ids = [];
    const existing_files = row.filter((e) => e.id);
    for (var i of existing_files) {
      existing_file_ids.push(i.id);
    }
    const new_files = row.filter((e) => !e.id);
    const content_files = new_files.filter((e) => e.file_content !== "");

    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const export_tax_id = this.props.exportTaxID;
    const costing = this.state.costing_files?.filter(
      (e) => e.file_content !== "" && e.column === "invoice_file"
    );

    api
      .update_barge_export_tax(
        loginUserID,
        idToken,
        export_tax_id,
        export_tax_applicable,
        quantity_for_export_tax,
        export_tax_per_mt,
        export_tax,
        billing_currency,
        export_currency_xchg_rate,
        export_tax_in_local_currency,
        existing_file_ids,
        content_files,
        costing
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            this.props.onUpdateCosting(res.code);
            if (res.code === "200") {
              this.setState(
                {
                  snackBarSuccessOpen: true,
                  successMessage: "Costing Updated sucessfully.",
                },
                () => this.props.onUpdateCosting(res.code)
              );
            } else if (res.code === "607") {
              window.location.href = "/logout";
            } else {
              this.setState({ isLoading: false });
            }
          });
        }
      });
  };

  render() {
    const quan = /^\d*(\.\d{0,3})?$/;
    const pr = /^\d*(\.\d{0,4})?$/;
    const re = /^\d*(\.\d{0,10})?$/;
    // const vat = /^\d*(\.\d{0,2})?$/;
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div className="container bg-white p-0" style={{ minHeight: "100vh" }}>
          <div className="clearfix content-header border-bottom">
            <div className="row">
              <div className="col-sm text-left pl-0">
                <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                  {`Export Tax - ${this.state.costing_no}`}{" "}
                </h4>
              </div>
            </div>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMessage}
            </Alert>
          </Snackbar>
          <div className="drawer-section">
            <div className="card">
              <div className="card-body border_dashed">
                {!this.props.updateDocuments && (
                  <div style={{ width: "70%" }}>
                    <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                      Add details to Update costing
                    </h5>
                  </div>
                )}
                {!this.props.updateDocuments && (
                  <div className="row mb-1 p-2 w-100">
                    <div className="col-lg-3 p-0">
                      <label className="form_label mb-0">
                        Export Tax Applicable
                      </label>
                      <div className="mt-1">
                        <RadioGroup
                          aria-label="export_tax_applicable"
                          name="export_tax_applicable"
                          onChange={(e) =>
                            this.setState({
                              export_tax_applicable: e.target.value,
                            })
                          }
                          style={{ flexDirection: "row" }}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={
                              <NewRadio
                                checked={
                                  this.state.export_tax_applicable === "Yes"
                                }
                              />
                            }
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={
                              <NewRadio
                                checked={
                                  this.state.export_tax_applicable === "No"
                                }
                              />
                            }
                            label="No"
                          />
                        </RadioGroup>
                      </div>
                    </div>
                    {this.state.export_tax_applicable === "Yes" && (
                      <div className="row mb-0">
                        <div className="col-lg-6 p-0">
                          <div className="row mb-2">
                            <label className="form_label mb-0">
                              Quantity for Export Tax
                            </label>
                            <TextField
                              name="quantity_for_export_tax"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              InputProps={{
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                },
                              }}
                              value={this.state.quantity_for_export_tax}
                              placeholder="Quantity for Export Tax"
                              onChange={(e) => {
                                if (
                                  e.target.value === "" ||
                                  quan.test(e.target.value)
                                ) {
                                  let value = e.target.value;
                                  if (value === "") {
                                    value = 1;
                                  }
                                  var export_tax =
                                    (Number(value) *
                                      Number(this.state.export_tax_per_mt) *
                                      1.5) /
                                    100;
                                  this.setState({
                                    quantity_for_export_tax: e.target.value,
                                    export_tax: export_tax,
                                    export_tax_in_local_currency:
                                      config.company === "SDAM" ||
                                      config.company === "SRK" ||
                                      config.company === "BTR"
                                        ? Number(export_tax) *
                                          Number(
                                            this.state.export_currency_xchg_rate
                                              ? this.state
                                                  .export_currency_xchg_rate
                                              : 1
                                          )
                                        : "",

                                    //export_tax: Number((Number(this.state.export_tax_per_mt) * Number(value) * 1.5) / 100).toFixed(2)
                                  });
                                }
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-lg-6 pr-0">
                          <div className="row mb-2">
                            <label className="form_label mb-0">
                              Export Tax per MT
                            </label>
                            <TextField
                              name="export_tax_per_mt"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              value={this.state.export_tax_per_mt}
                              placeholder="Export Tax per MT"
                              onChange={(e) => {
                                if (
                                  e.target.value === "" ||
                                  pr.test(e.target.value)
                                ) {
                                  var value = e.target.value;
                                  if (value === "") {
                                    value = "1";
                                  }
                                  var export_tax =
                                    (Number(
                                      this.state.quantity_for_export_tax
                                    ) *
                                      Number(value) *
                                      1.5) /
                                    100;
                                  this.setState({
                                    export_tax_per_mt: e.target.value,
                                    export_tax: export_tax,
                                    export_tax_in_local_currency:
                                      config.company === "SDAM" ||
                                      config.company === "SRK" ||
                                      config.company === "BTR"
                                        ? Number(export_tax) *
                                          Number(
                                            this.state.export_currency_xchg_rate
                                              ? this.state
                                                  .export_currency_xchg_rate
                                              : 1
                                          )
                                        : "",

                                    //export_tax: Number(( Number(this.state.dc_quantity_in_mt) * Number(this.state.quantity_for_export_tax) * Number(value) * 1.5) / 100).toFixed(2)
                                  });
                                }
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    position="end"
                                    className="adornment_background"
                                  >
                                    USD
                                  </InputAdornment>
                                ),
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                  padding: 0,
                                },
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-lg-6 p-0">
                          <div className="row mb-2">
                            <label className="form_label mb-0">
                              Export Tax
                            </label>
                            <TextField
                              name="export_tax"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              value={this.state.export_tax}
                              placeholder="Export Tax"
                              onChange={(e) => {
                                if (
                                  e.target.value === "" ||
                                  pr.test(e.target.value)
                                ) {
                                  this.setState({
                                    export_tax: e.target.value,
                                    export_tax_in_local_currency:
                                      config.company === "SDAM" ||
                                      config.company === "SRK" ||
                                      config.company === "BTR"
                                        ? (
                                            Number(e.target.value) *
                                            Number(
                                              this.state
                                                .export_currency_xchg_rate
                                                ? this.state
                                                    .export_currency_xchg_rate
                                                : 1
                                            )
                                          ).toFixed(3)
                                        : "",
                                  });
                                }
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    position="end"
                                    className="adornment_background"
                                  >
                                    USD
                                  </InputAdornment>
                                ),
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                  padding: 0,
                                },
                              }}
                            />
                          </div>
                        </div>

                        {!(
                          config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL"
                        ) && (
                          <div className="col-lg-6 pr-0">
                            <div className="row mb-2">
                              <label className="form_label mb-0">
                                Currency Exchange Rate
                              </label>
                              <TextField
                                name="export_currency_xchg_rate"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                  },
                                }}
                                value={this.state.export_currency_xchg_rate}
                                placeholder="Currency Exchange Rate USD to IDR"
                                onChange={(e) => {
                                  if (
                                    e.target.value === "" ||
                                    re.test(e.target.value)
                                  ) {
                                    var value = e.target.value;
                                    if (value === "") {
                                      value = 1;
                                    }
                                    this.setState({
                                      export_currency_xchg_rate: e.target.value,
                                      export_tax_in_local_currency: (
                                        Number(this.state.export_tax) *
                                        Number(value)
                                      ).toFixed(3),
                                    });
                                  }
                                }}
                              />
                            </div>
                          </div>
                        )}

                        {!(
                          config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL"
                        ) && (
                          <div className="col-lg-6 p-0">
                            <div className="mb-2">
                              <label className="form_label mb-0">
                                Export Tax in IDR
                              </label>
                              <div className="mt-2 contract_display_header_value">
                                {this.state.export_tax_in_local_currency}{" "}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
                {/* {(this.state.features.includes("369")  ||
                  this.state.features.includes("97") ) && ( */}
                <FileUpload
                  files={this.state.files}
                  type="Costings"
                  invoice_file={this.state.invoice_file}
                  feature_name="Barge_export_tax"
                  callbackFn={(data, mandatoryFiles) => {
                    this.setState({
                      files: data,
                      costing_files: mandatoryFiles,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button"
              name="submit"
              onClick={() => this.props.onCancel(false)}
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn next_button"
              name="Back"
              onClick={this.submitHandler}
            >
              Submit
            </button>
          </div>
        </div>
      );
    }
  }
}
