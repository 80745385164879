import React, { Component } from 'react';
import api from '../../../api/api';
import CookieHandler from '../../common/CookieHandler';
import { Alert } from '@material-ui/lab';
import {
  Snackbar, LinearProgress, Radio, TextField, FormControlLabel,
  Checkbox, RadioGroup, MenuItem, InputAdornment, IconButton
} from '@material-ui/core';
import config from '../../../config/config';
import { withStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import EventIcon from '@material-ui/icons/Event';
import { pad } from '../../common/common';
import Loader from '../../common/Loader';
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 6,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#42352d',
  },
}))(LinearProgress);
const CheckBox = withStyles({
  root: {
    color: "#ccc",
    '&$checked': {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const NewRadio = withStyles({
  root: {
    color: "#ccc",
    '&$checked': {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);
export default class BargeCoalSalesChargesAdd extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      activeStep: 0,
      barges: [],
      salesTypes: [],
      selected_barges: [],
      multiRows: [
        {
          payment_to: "0", invoice_date: null, invoice_no: "", ppn: "", payee_name: "", pph23_account: '0',
          barge_quantity: '', base_price_pmt: '', base_price: "",
          vat_percent: "10", vat_value: "", pph23_percent: "2",
          pph23_value: "", payable_to_vendor: "", cost_to_company: "",
          remarks: '',
        }
      ],
      vendorTypeAsOtherMenu: [<MenuItem style={{ fontFamily: 'Poppins', fontSize: '14px' }} value='0' key={-1}>Please Select</MenuItem>],
      sale_contract_id: null,
      sc_qualities_id: null,
      sc_sales_type_id: null,

      isLoading: true,
    }
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace('/logout');
    }
    const idToken = this.Cookie.getIdTokenCookie();
    const loginUserID = this.Cookie.getCookie('loginUserId');
    var status = '';
    const get_sales_contracts = await api.getSalesContractList(loginUserID, idToken, status);
    if (get_sales_contracts.status >= 200 && get_sales_contracts.status < 300) {
      get_sales_contracts.json().then((res) => {
        if (res.code === '200') {
          if (res.sale_contract) {
            const salesDataforMenu = [];
            const filteredSContracts = res.sale_contract.filter((e, indx) => e.status === "Active" || e.status === "Freezed");
            for (var i of filteredSContracts) {
              // salesDataforMenu.push(<MenuItem value={key.contract_no} key={key.id}>{key.contract_no}</MenuItem>)
              salesDataforMenu.push({ name: i.contract_no, value: i.id, key: i.id, customer_name: i.customer_name });
            }
            this.setState({
              salesDataforMenu: salesDataforMenu,
              salesContractsList: filteredSContracts,
              //isLoading: false,
            })
          }
          else {
            alert('Unexpected error occured. Please contact administrator.');
          }

        }
        else if (res.code === '601') {
          this.setState({
            snackBarErrorOpen: true,
            errorMsg: res.message
          });
        }
        else if (res.code === '607') {
          window.location.href = '/logout';
        }
      })
    }

    const vendorsDataResponse = await api.getVendors(loginUserID, idToken, 'Active');
    if (vendorsDataResponse.status >= 200 && vendorsDataResponse.status < 300) {
      vendorsDataResponse.json().then((res) => {
        if (res.code === '200') {
          if (res.vendors) {
            const vendorTypeAsOther = this.state.vendorTypeAsOtherMenu;
            for (var i of res.vendors) {
              if (i.vendor_type === "Others") {
                vendorTypeAsOther.push(<MenuItem style={{ fontFamily: 'Poppins', fontSize: '14px' }} value={i.id} key={[i.id]}>{i.vendor_name}</MenuItem>);
              }
            }
            this.setState({
              vendorTypeAsOtherMenu: vendorTypeAsOther,
            });

          }
          else {
            alert('Unexpected error occured. Please contact administrator.');
          }
        }
        else if (res.code === '601') {
          this.setState({
            snackBarErrorOpen: true,
            errorMsg: res.message
          })
        }
        else if (res.code === '607') {
          window.location.href = '/logout';
        }
      })
    }

    let financial_type = 'barges';
    const get_barges = await api.get_barges_list_for_sales_fin_csc(loginUserID, idToken, financial_type);
    if (get_barges.status >= 200 && get_barges.status < 300) {
      get_barges.json().then((res) => {
        if (res.code === '200') {
          if (res.barges) {
            this.setState({
              total_barges: res.barges,
              barges: res.barges.filter(e => e.status === "Active"),
              isLoading: false
            });
          }
          else {
            alert('Unexpected error occured. Please contact administrator.');
          }
        } else if (res.code === '607') {
          window.location.href = '/logout';
        }
      })
    }

  }

  handleSalesContract = (value) => {
    const loginUserID = this.Cookie.getCookie('loginUserId');
    const idToken = this.Cookie.getIdTokenCookie();
    if (value) {
      const salesContractId = value.value;
      const status = '';
      api.getSalesContract(loginUserID, salesContractId, idToken, status).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === '200') {
              if (res.sales_contract) {
                this.setState({
                  salesTypes: res.sales_contract.qualities,
                  salesCurrency: res.sales_contract.currency,
                  coalPriceCurrency: res.sales_contract.currency,
                  sales_index_linked: res.sales_contract.is_index_linked,
                });
                window.$("#salesContractModal").modal({
                  backdrop: 'static',
                  keyboard: true,
                  show: true
                });
              }
              else {
                alert('Unexpected error occured. Please contact administrator.')
              }
            }
            else if (res.code === '601') {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message
              });
            }
            else if (res.code === '607') {
              window.location.href = '/logout';
            }

          })
        }
      });
    }
    else {

    }

  }

  handleSalesTypesRadioButtons = (e) => {
    if (e.target.name === 'salesQualitySelection') {
      this.setState({
        salesQualitySelection: e.target.value
      });
    }
    if (e.target.name === 'salesTypeSelection') {
      this.setState({
        salesTypeSelection: e.target.value,
      });
    }

  }

  progressBarSize(data, type) {
    if (data) {
      if (type === 'NSC') {
        var remainingSalesQuality = Number(Number(data.quantity_allocated) / Number(data.quantity_adjusted)) * 100;
        return remainingSalesQuality;
      }
    }
  }

  _toBeDelivered(data) {
    if (data) {
      if (data.sale_contract_id) {
        var remainingSalesQuality = Number(Number(data.quantity_adjusted) - Number(data.quantity_allocated));
        return remainingSalesQuality;
      }
    }
  }

  toLocaleString(value) {
    return Number(value).toLocaleString();
  }

  nextStepHandler = () => {
    if (this.state.activeStep === 0) {
      const filter_selected_barges = this.state.barges.filter(e => e.barge_checked)
      if (filter_selected_barges.length <= 0) {
        this.setState({
          snackBarErrorOpen: true,
          errorMsg: 'Please select atleast one.'
        });
        return;
      }
      let barge_quantity = 0;
      for (var i of filter_selected_barges) {
        if (i.barge_allocation_type === "Full") {
          barge_quantity += Number(i.barge_quantity_in_mt ? i.barge_quantity_in_mt : i.barge_planned_quantity_in_mt);
        }
        else if (i.barge_allocation_type === "Partial") {
          barge_quantity += Number(i.partial_quantity ? i.partial_quantity : 0);
        }
        else {
          barge_quantity += Number(i.barge_quantity_in_mt ? i.barge_quantity_in_mt : i.barge_planned_quantity_in_mt);
        }
      }

      let row = this.state.multiRows;
      if (row.length > 0) {
        row[0].barge_quantity = barge_quantity;
      }
      else {
        row = [{
          payment_to: "0", payee_name: "", pph23_account: '0',
          barge_quantity: '', base_price_pmt: '', base_price: "",
          vat_percent: "10", vat_value: "", pph23_percent: "2",
          pph23_value: "", payable_to_vendor: "", cost_to_company: "",
          remarks: '',
        }]
      }
      this.setState({
        barge_quantity: barge_quantity,
        multiRows: row
      });
    }

    this.setState({
      activeStep: this.state.activeStep + 1
    });
  }

  previousStepHandler = () => {
    this.setState({
      activeStep: this.state.activeStep - 1
    });
  }

  onPriceCalculation = (id) => {
    var row = this.state.multiRows;
    var barge_quantity = row[id].barge_quantity;
    var base_price_pmt = row[id].base_price_pmt
    var totalBasePrice = (Number(barge_quantity) * Number(base_price_pmt));
    var base_price = (Number(totalBasePrice)).toFixed(4);
    var vat_percent = row[id].vat_percent;
    var vat_value = ((Number(base_price) * Number(vat_percent)) / 100).toFixed(2);
    var pph23_percent = row[id].pph23_percent;
    var pph23_value = ((Number(base_price) * Number(pph23_percent) / 100)).toFixed(2);
    var cost_to_company = 0;
    var payable_to_vendor = (Number(base_price) + Number(vat_value)).toFixed(2);
    if ((config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL")) {
      cost_to_company = (Number(base_price) + Number(vat_value)).toFixed(2);
    }
    else {
      if (row[id].pph23_account === "Supplier") {
        payable_to_vendor = (Number(base_price) + Number(vat_value) - Number(pph23_value)).toFixed(2);
        cost_to_company = (Number(base_price) + Number(vat_value)).toFixed(2);
      }
      else if (row[id].pph23_account === "SDAM") {
        payable_to_vendor = (Number(totalBasePrice)).toFixed(2);
        base_price = (Number(payable_to_vendor * 100) / 98).toFixed(2);
        pph23_value = ((Number(base_price) * Number(pph23_percent) / 100)).toFixed(2);
        cost_to_company = (Number(base_price) + Number(pph23_value)).toFixed(2);
      }
    }

    row[id].base_price = base_price;
    row[id].vat_percent = vat_percent;
    row[id].vat_value = vat_value;
    row[id].pph23_percent = pph23_percent;
    row[id].pph23_value = pph23_value;
    row[id].payable_to_vendor = payable_to_vendor;
    row[id].cost_to_company = cost_to_company;

    this.setState({
      multiRows: row
    });
  }

  addMoreProcurementRowsHandler = () => {
    const item =
    {
      payment_to: "0", invoice_date: null, invoice_no: "", ppn: "", payee_name: "", pph23_account: '0',
      barge_quantity: this.state.barge_quantity, base_price_pmt: '', base_price: "",
      vat_percent: "10", vat_value: "", pph23_percent: "2",
      pph23_value: "", payable_to_vendor: "", cost_to_company: "",
      remarks: "",
    };

    this.setState({
      multiRows: [...this.state.multiRows, item]
    })
  }

  removeProcurementRowsHandler = (idx) => () => {
    const rows = this.state.multiRows
    rows.splice(idx, 1);
    this.setState({
      multiRows: rows
    });
  }

  addZero(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  }

  dateFormat(date) {
    let dateFormat = new Date(date);
    let day = this.addZero(dateFormat.getDate());
    let month = this.addZero(dateFormat.getMonth() + 1);
    let year = dateFormat.getFullYear();
    let newDate = year + '-' + month + '-' + day;
    let finalDate = newDate;
    return finalDate;
  }

  submitHandler = (e) => {
    this.setState({ isLoading: true });
    var { multiRows, sale_contract_id, sc_qualities_id, sc_sales_type_id } = this.state;

    // for (var i in multiRows) {
    //   if (multiRows[i].payment_to === "Others") {
    //     multiRows[i].payment_to = null;
    //   }
    // }

    // Add barge purchase financial coal procurement charge api
    const loginUserID = this.Cookie.getCookie('loginUserId');
    const idToken = this.Cookie.getIdTokenCookie();
    const csc_data = multiRows;
    const barge_data = [];

    const filter_selected_barges = this.state.barges.filter(e => e.barge_checked);
    for (var obj of filter_selected_barges) {
      barge_data.push({ barge_id: obj.id, allocation_type: obj.barge_allocation_type, quantity: obj.barge_allocation_type === "Partial" ? obj.partial_quantity : obj.barge_quantity_in_mt ? obj.barge_quantity_in_mt : obj.barge_planned_quantity_in_mt })
    }
    api.add_barge_sales_fin_csc(loginUserID, idToken, sale_contract_id, sc_qualities_id, sc_sales_type_id, barge_data, csc_data).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === '200') {
            // window.location.href = '/mv-sales-financial-view-coal-sales-charges/' + btoa(businessNoID);
            this.setState({
              snackBarSuccessOpen: true,
              sucessMsg: res.message,
            }, () => {
              setTimeout(() => {
                this.props.onAddResponse({ screen: "MvCsc", code: res.code, message: '' });
              }, 2000)
            })
            //window.location.href = "/mv-purchase-view-floating-crane/" + btoa(businessNoID);
          }
          else if (res.code === '601') {
            this.setState({
              isLoading: false,
              errorMsg: res.message,
              snackBarErrorOpen: true
            });
          }
          else if (res.code === '607') {
            window.location.href = '/logout';
          }
          else if (res.code === '624') {
            this.setState({
              isLoading: false,
              errorMsg: res.message,
              snackBarErrorOpen: true
            });
          }
        })
      }
    })
  }

  render() {
    if (this.state.isLoading) {
      return<Loader />
    }
    else {
      const quan = /^\d*(\.\d{0,3})?$/;
      const pr = /^\d*(\.\d{0,4})?$/;
      return (
        <div className='container bg-white p-0' style={{ minHeight: '100vh', width: 850 }}>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={this.state.snackBarErrorOpen} onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: '450px' }}
          >
            <Alert elevation={6} variant="filled" onClose={() => this.setState({ snackBarErrorOpen: false })} severity="error" style={{ width: '100%' }}>
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            style={{ width: '450px' }}
            open={this.state.snackBarSuccessOpen} autoHideDuration={100000} onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert elevation={6} variant="filled" onClose={() => this.setState({ snackBarSuccessOpen: false })} severity="success" style={{ width: '100%' }}>
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <div className='row clearfix content-header border-bottom'>
            <div className='col-lg-12 pl-0'>
              <h4 style={{ padding: '10px 20px', }}>Coal Sales Charges</h4>
            </div>
          </div>
          <div className='drawer-section' style={{ minHeight: '85vh' }}>

            <div className="row mb-0 stepper">
              <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                <i className={this.state.activeStep > 0 ? "fa fa-check-circle" : "fa fa-check-circle-o"}
                  aria-hidden="true" style={{
                    fontSize: 20,
                    color: this.state.activeStep > 0 ? '#31c197' : this.state.activeStep === 0 ? config.themeColor : '#ccc'
                  }} />
                <span className="stepper_text"
                  style={{ color: this.state.activeStep > 0 ? '#31c197' : this.state.activeStep === 0 ? config.themeColor : '#ccc' }}>Barge Selection</span>
                <i className="fa fa-chevron-right stepper_sub_icon stepper_arrow" aria-hidden="true"
                  style={{ color: this.state.activeStep > 0 ? '#31c197' : this.state.activeStep === 0 ? config.themeColor : '#ccc' }} />
              </span>
              <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                <i className={this.state.activeStep > 1 ? "fa fa-check-circle" : "fa fa-check-circle-o"}
                  aria-hidden="true" style={{
                    fontSize: 20,
                    color: this.state.activeStep > 1 ? '#31c197' : this.state.activeStep === 1 ? config.themeColor : '#ccc'
                  }} />
                <span className="stepper_text"
                  style={{ color: this.state.activeStep > 1 ? '#31c197' : this.state.activeStep === 1 ? config.themeColor : '#ccc' }}>Costing</span>
                <i className="fa fa-chevron-right stepper_sub_icon stepper_arrow" aria-hidden="true"
                  style={{ color: this.state.activeStep > 1 ? '#31c197' : this.state.activeStep === 1 ? config.themeColor : '#ccc' }} />
              </span>
              {/* <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
              <i className={this.state.activeStep > 2 ? "fa fa-check-circle" : "fa fa-check-circle-o"}
                aria-hidden="true" style={{
                  fontSize: 20,
                  color: this.state.activeStep > 2 ? '#31c197' : this.state.activeStep === 2 ? config.themeColor : '#ccc'
                }} />
              <span className="stepper_text"
                style={{ color: this.state.activeStep > 2 ? '#31c197' : this.state.activeStep === 2 ? config.themeColor : '#ccc' }}>Preview & Submit</span>
              <i className="fa fa-chevron-right stepper_sub_icon stepper_arrow" aria-hidden="true"
                style={{ color: this.state.activeStep > 2 ? '#31c197' : this.state.activeStep === 2 ? config.themeColor : '#ccc' }} />
            </span> */}

            </div>
            <div className='card p-3'>
              <div className='card-body p-0'>
                {this.state.activeStep === 0 &&
                  <div>

                    <div className='row border-bottom pb-2'>
                      <div className='col-lg'>
                        <label className='form_label'>Sales Contract</label>
                        <Autocomplete
                          options={this.state.salesDataforMenu}
                          getOptionLabel={option => option.name}
                          onChange={(event, value) => {
                            if (value) {
                              this.setState({
                                sale_contract_id: value.value,
                                sales_contract_no: value.name,
                                selected_sc: value
                              }, () => {
                                this.handleSalesContract(value);
                              });
                            }
                            else {
                              this.setState({
                                sale_contract_id: '',
                                sales_quality: '',
                                sc_qualities_id: '',
                                sales_type: '',
                                sc_sales_type_id: '',
                                sales_price_pmt: 0,
                                coalPriceCurrency: '',
                                selected_sc: { name: '', value: '' }
                              });
                            }
                          }}
                          value={this.state.selected_sc}
                          renderOption={option => (
                            <React.Fragment>
                              {option.name} ({option.customer_name})
                            </React.Fragment>
                          )}
                          size='small'
                          name='sales_contract'
                          fullWidth
                          style={{ padding: '0px' }}
                          // value= {this.state.supplierSurveyorNameValue}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              // error={this.state.quantityRows[index].sales_contractError}
                              // inputProps={{ style:{padding: '2px'}}}
                              placeholder="Sales Contract *"
                              //label="Sales Contract *"
                              variant="outlined"
                              style={{ width: 230 }} />
                          )}
                        />
                      </div>
                      <div className='col-lg'>
                        <label className='form_label mb-3'>Sales Quality</label>
                        <div className='contract_display_header_value'>{this.state.sales_quality}</div>
                      </div>
                      <div className='col-lg'>
                        <label className='form_label mb-3'>Sales Type</label>
                        <div className='contract_display_header_value'>{this.state.sales_type}</div>
                      </div>
                    </div>

                    <div className='row'>
                      {(this.state.sale_contract_id && this.state.sc_qualities_id && this.state.sc_sales_type_id) && this.state.barges && this.state.barges.length > 0 &&

                        <div className='row '>
                          <div className={this.state.barges.length > 0 ? 'p-2 mb-2 bg-white  col-lg-12 mt-2' : " col-lg-12 p-0 mt-2"}>
                            <div className='table-responsive'>

                              <table className='border-0 root'>
                                <thead >
                                  <tr>
                                    <th nowrap='true' className="allocation_table_header_label pb-2"></th>
                                    <th nowrap='true' className="allocation_table_header_label pb-2" style={{ minWidth: 120 }}>Barge ID</th>
                                    <th className="allocation_table_header_label pb-2" nowrap='true' style={{ minWidth: 130 }}>Barge Nomination</th>
                                    {/* <th nowrap='true' style={{ fontSize: 12 }}>Barge Status</th> */}
                                    <th className="allocation_table_header_label pb-2 text-right" nowrap='true' style={{ minWidth: 140 }}>Barge Quantity</th>
                                    <th className="allocation_table_header_label pb-2 text-right" nowrap='true' style={{ minWidth: 150 }}>Planned Quantity</th>
                                    <th className="allocation_table_header_label pb-2 text-right" nowrap='true' style={{ minWidth: 160 }}>Redraft Quantity</th>
                                  </tr>
                                </thead>

                                {!this.state.barges ? "" : this.state.barges.map((b, index) => (
                                  <tbody key={index} className='allocation_box_shadow'>
                                    <tr>
                                      <td className='table_td'>
                                        <CheckBox
                                          color='secondary'
                                          value={b.id}
                                          checked={b.barge_checked === true ? true : false}
                                          className='collapsed'
                                          data-toggle="collapse"
                                          data-target={"#demo1" + b.id}
                                          aria-expanded="false"
                                          aria-controls={"group-of-rows-1"}
                                          onChange={(e) => {
                                            var rows = this.state.barges
                                            if (e.target.checked) {
                                              rows[index].barge_checked = true;
                                              rows[index].barge_id = b.id;
                                              this.setState({
                                                selected_barges: [...this.state.selected_barges, { id: b.id, allocated_type: rows[index].barge_allocation_type }]
                                              });
                                            }
                                            else {
                                              rows[index].barge_checked = false;
                                              rows[index].barge_id = "";
                                              let selected_barges = this.state.selected_barges;
                                              let idx = selected_barges.findIndex(x => x.id === b.id);
                                              selected_barges.splice(idx, 1);
                                              this.setState({
                                                selected_barges: selected_barges
                                              });
                                            }
                                          }}
                                        />
                                      </td>
                                      <td className='table_td'>{pad(b.id)}</td>
                                      <td className='table_td' nowrap='true'>{b.barge_nomination}</td>
                                      <td className='allocation_table_value p-0 text-right'>{b.barge_quantity_in_mt ? this.toLocaleString(b.barge_quantity_in_mt) : null}</td>
                                      <td className='allocation_table_value p-0 text-right'>{b.barge_planned_quantity_in_mt ? this.toLocaleString(b.barge_planned_quantity_in_mt) : null}</td>
                                      <td className='allocation_table_value p-0 text-right'>{b.redraft_quantity ? this.toLocaleString(b.redraft_quantity) : null}</td>
                                      {/* <td className='table_td'>{b.barge_quantity_in_mt ? this.toLocaleString(b.barge_quantity_in_mt) : null}</td> */}
                                    </tr>
                                    <tr id={"demo1" + b.id} className={b.barge_checked === true ? "collapse show" : "collapse"} >
                                      <td></td>
                                      <td colSpan={8}>
                                        <div className='card'>
                                          <div className='row'>
                                            <div className='col-lg-4'>
                                              <label className='form_label mb-0'>Allocation Type</label>
                                              <RadioGroup aria-label="gender" name="barge_allocation_type" onChange={(e) => {
                                                var rows = this.state.barges;
                                                rows[index].barge_allocation_type = e.target.value
                                                if (e.target.value === "Full") {
                                                  rows[index].barge_mapped_quantity = '';
                                                  rows[index].partial_quantity_error = false;
                                                  rows[index].partial_quantity_error_msg = '';
                                                  this.setState({
                                                    selected_barges: [...this.state.selected_barges, { "id": b.id, "allocated_type": e.target.value }],
                                                    barges: rows
                                                  });
                                                }
                                                else {
                                                  let selected_barges = this.state.selected_barges;
                                                  let idx = selected_barges.findIndex(x => x.id === b.id);
                                                  selected_barges.splice(idx, 1);
                                                  this.setState({
                                                    selected_barges: selected_barges
                                                  });
                                                }
                                              }} style={{ flexDirection: 'row' }}>
                                                <FormControlLabel value="Full" control={<NewRadio checked={b.barge_allocation_type === "Full"}
                                                // disabled={b.allocation_type === "Partial"}
                                                />} label="Full" />
                                                <FormControlLabel value="Partial" control={<NewRadio checked={b.barge_allocation_type === "Partial"} />} label="Partial" />
                                              </RadioGroup>
                                            </div>
                                            {b.barge_allocation_type === "Partial" &&
                                              <div className='col-lg-4'>
                                                <label className='form_label mb-0'>Quantity</label>
                                                <TextField
                                                  name='quantity'
                                                  margin='dense'
                                                  variant='outlined'
                                                  fullWidth
                                                  placeholder="Quantity"
                                                  value={b.partial_quantity}
                                                  onChange={(e) => {
                                                    const pr = /^\d*(\.\d{0,3})?$/;
                                                    if (e.target.value === '' || pr.test(e.target.value)) {
                                                      let rows = this.state.barges;
                                                      rows[index].partial_quantity = e.target.value;
                                                      rows[index].partial_quantity_error = false;
                                                      this.setState({
                                                        barges: rows
                                                      });
                                                    }
                                                  }}
                                                  error={b.partial_quantity_error ? b.partial_quantity_error : null}
                                                />
                                                {b.partial_quantity_error &&
                                                  <p className='text-danger'>{b.partial_quantity_error_msg}</p>
                                                }

                                              </div>
                                            }
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                ))}

                              </table>
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                }
                {this.state.activeStep === 1 &&
                  <div>
                    {this.state.multiRows.map((e, idx) => (
                      <div className='row' key={idx}>

                        <div className={this.state.multiRows.length > 1 ? "col-lg-11 border_dashed rounded-lg p-2 mb-3" : "col-lg-12 border_dashed rounded-lg p-2 mb-3"}>

                          <div className='row mb-3 '>
                            <div className='col-lg-5 p-0 mb-1'>
                              <label className='form_label mb-0'>Invoice No</label>
                              <TextField
                                name='invoice_no'
                                margin='dense'
                                variant='outlined'
                                type='text'
                                fullWidth
                                inputProps={{
                                  style: {
                                    fontFamily: 'Poppins',
                                    fontSize: '14px',
                                    textTransform: 'uppercase'
                                  }
                                }}
                                placeholder='Invoice No'
                                value={e.invoice_no}
                                onChange={(e) => {
                                  var row = this.state.multiRows;
                                  row[idx].invoice_no = e.target.value;
                                  this.setState({
                                    multiRows: row,
                                  });
                                }}
                              />
                            </div>
                            <div className='col-lg-5 pr-0 mb-1'>
                              <label className='form_label mb-0'>Invoice Date</label>

                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                  autoOk={true}
                                  inputVariant="outlined"
                                  variant="inline"
                                  margin="dense"
                                  fullWidth
                                  format="dd/MM/yyyy"
                                  value={e.invoice_date}
                                  error={this.state.date_of_paymentError}
                                  onChange={(date) => {
                                    var row = this.state.multiRows;
                                    row[idx].invoice_date = this.dateFormat(date);
                                    this.setState({
                                      multiRows: row
                                    });
                                  }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <IconButton>
                                          <EventIcon />
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                    className: 'pl-0'
                                  }}
                                />
                              </MuiPickersUtilsProvider>
                            </div>
                            <div className='col-lg-5 pl-0'>

                              <div className='row'>
                                <div className='col-lg-12 mb-1 p-0'>
                                  <label className='form_label mb-0'>Payment to</label>
                                  <TextField
                                    name='payment_to'
                                    margin='dense'
                                    variant='outlined'
                                    value={e.payment_to}
                                    onChange={(e) => {
                                      var row = this.state.multiRows;
                                      row[idx].payment_to = e.target.value
                                      this.setState({
                                        multiRows: row
                                      });
                                    }}
                                    select
                                    fullWidth
                                    InputProps={{
                                      style: {
                                        fontFamily: 'Poppins',
                                        fontSize: '14px'
                                      }
                                    }}
                                  >
                                    {this.state.vendorTypeAsOtherMenu}
                                    {/* <MenuItem value="Others">Others</MenuItem> */}
                                  </TextField>
                                </div>
                                {/* {e.payment_to === "Others" &&

                                  <div className='col-lg-12 mb-1 mt-1 p-0'>
                                    <label className='form_label mb-0'>Payee Name</label>
                                    <TextField
                                      name='payee_name'
                                      margin='dense'
                                      variant='outlined'
                                      fullWidth
                                      placeholder='Enter Name'
                                      value={e.payee_name}
                                      onChange={(e) => {
                                        var row = this.state.multiRows;
                                        row[idx].payee_name = e.target.value
                                        this.setState({
                                          multiRows: row
                                        });
                                      }}
                                      inputProps={{ style: { textTransform: 'uppercase' } }}

                                    />

                                  </div>
                                } */}

                                <div className='col-lg-6 mb-1 p-0'>
                                  <label className='form_label mb-0'>Quantity in MT</label>
                                  <TextField
                                    name='barge_quantity'
                                    margin='dense'
                                    type="number"
                                    variant='outlined'
                                    fullWidth
                                    InputProps={{
                                      style: {
                                        fontFamily: 'Poppins',
                                        fontSize: '14px'
                                      }
                                    }}
                                    placeholder='Enter Value'

                                    value={e.barge_quantity}
                                    onChange={(e) => {
                                      if (e.target.value === '' || quan.test(e.target.value)) {
                                        var row = this.state.multiRows;
                                        row[idx].barge_quantity = e.target.value;
                                        this.setState({
                                          multiRows: row,
                                        }, () => this.onPriceCalculation(idx));
                                      }
                                    }}

                                  />
                                </div>

                                <div className='col-lg-6 mb-1 pr-0'>
                                  <label className='form_label mb-0'>Base Price PMT</label>
                                  <TextField
                                    name='base_price_pmt'
                                    margin='dense'
                                    type="number"
                                    variant='outlined'
                                    fullWidth
                                    placeholder='Enter Value'
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end" className='adornment_background'>
                                          {(config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL") ? "USD" : "IDR"}
                                        </InputAdornment>
                                      ),
                                      style: {
                                        fontFamily: 'Poppins',
                                        fontSize: '14px',
                                        padding: 0
                                      }
                                    }}
                                    value={e.base_price_pmt}
                                    onChange={(e) => {
                                      if (e.target.value === '' || pr.test(e.target.value)) {
                                        var row = this.state.multiRows;
                                        row[idx].base_price_pmt = e.target.value;
                                        this.setState({
                                          multiRows: row,
                                        }, () => this.onPriceCalculation(idx));
                                      }
                                    }}

                                  />
                                </div>


                                {(config.company === "SDAM" || config.company === "SRK" || config.company === "BTR") &&
                                  <div className='col-lg-12 mb-1 p-0'>
                                    <label className='form_label mb-0'>PPH23 Account</label>
                                    <TextField
                                      name='pph23_account'
                                      margin='dense'
                                      variant='outlined'
                                      fullWidth
                                      select
                                      InputProps={{
                                        style: {
                                          fontFamily: 'Poppins',
                                          fontSize: '14px'
                                        }
                                      }}
                                      value={e.pph23_account}
                                      onChange={(e) => {
                                        var row = this.state.multiRows;
                                        row[idx].pph23_account = e.target.value;
                                        row[idx].pph23_percent = e.target.value === "SDAM" ? 2 : 2;
                                        this.setState({
                                          multiRows: row
                                        }, () => this.onPriceCalculation(idx));
                                      }}
                                    >
                                      <MenuItem value='0'>Please Select</MenuItem>
                                      <MenuItem value='Supplier'>Supplier</MenuItem>
                                      <MenuItem value='SDAM'>SDAM</MenuItem>
                                    </TextField>
                                  </div>
                                }

                              </div>
                            </div>

                            {((config.company === "SDAM" || config.company === "SRK" || config.company === "BTR") && e.pph23_account !== '0' && e.pph23_account === "Supplier") &&

                              <div className='col-lg-7 p-2 rounded' style={{ backgroundColor: "#f7f3f0" }}>
                                <div className='row col-lg-12 p-0'>
                                  <h6>Price</h6>
                                </div>
                                <div className='row'>
                                  <div className='col-4 p-0 mb-2'>
                                    <label className="form_label mb-0" style={{ color: 'rgba(72, 59, 52, 0.7)' }}>Base price</label>
                                    {/* <div className='contract_display_header_value' style={{ color: '#42352d' }}>{e.base_price ? this.toLocaleString(e.base_price) : "-"}</div> */}
                                    <TextField
                                      name='base_price'
                                      margin='dense'
                                      type="number"
                                      //variant='outlined'
                                      fullWidth
                                      InputProps={{
                                        style: {
                                          fontFamily: 'Poppins',
                                          fontSize: '14px'
                                        }
                                      }}
                                      value={e.base_price}
                                      onChange={(e) => {
                                        if (e.target.value === '' || pr.test(e.target.value)) {
                                          var row = this.state.multiRows;
                                          let vat_value = Number((Number(e.target.value) * Number(row[idx].vat_percent)) / 100).toFixed(2);
                                          let pph23_value = Number((Number(e.target.value) * Number(row[idx].pph23_percent) / 100).toFixed(2));
                                          let payable_to_vendor = (Number(e.target.value) + Number(vat_value) - Number(pph23_value)).toFixed(2);
                                          let cost_to_company = (Number(e.target.value) + Number(vat_value)).toFixed(2);
                                          row[idx].base_price = e.target.value;
                                          row[idx].vat_value = vat_value;
                                          row[idx].pph23_value = pph23_value;
                                          row[idx].payable_to_vendor = payable_to_vendor;
                                          row[idx].cost_to_company = cost_to_company;
                                          this.setState({
                                            multiRows: row
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                  <div className='col-4 pr-0 mb-2'>
                                    <label className="form_label mb-0" style={{ color: 'rgba(72, 59, 52, 0.7)' }}>VAT %</label>
                                    {/* <div className='contract_display_header_value' style={{ color: '#42352d' }}>{e.dc_vat_percent ? this.toLocaleString(e.dc_vat_percent) : "-"}</div> */}
                                    <TextField
                                      name='vat_percent'
                                      margin='dense'
                                      type="number"
                                      //variant='outlined'
                                      fullWidth
                                      InputProps={{
                                        style: {
                                          fontFamily: 'Poppins',
                                          fontSize: '14px'
                                        }
                                      }}
                                      value={e.vat_percent}
                                      onChange={(e) => {
                                        if (e.target.value === '' || pr.test(e.target.value)) {
                                          var row = this.state.multiRows;
                                          let vat_value = Number((Number(row[idx].base_price) * Number(e.target.value)) / 100).toFixed(2);
                                          let payable_to_vendor = (Number(row[idx].base_price) + Number(vat_value) - Number(row[idx].pph23_value)).toFixed(2);
                                          let cost_to_company = (Number(row[idx].base_price) + Number(vat_value)).toFixed(2);
                                          row[idx].vat_percent = e.target.value
                                          row[idx].vat_value = vat_value;
                                          row[idx].payable_to_vendor = payable_to_vendor;
                                          row[idx].cost_to_company = cost_to_company;
                                          this.setState({
                                            multiRows: row
                                          });
                                        }
                                      }}

                                    />
                                  </div>
                                  <div className='col-4 pr-0 mb-2'>
                                    <label className="form_label mb-2" style={{ color: 'rgba(72, 59, 52, 0.7)' }}>VAT</label>
                                    <div className='contract_display_header_value' style={{ color: '#42352d' }}>{e.vat_value ? this.toLocaleString(e.vat_value) : "-"}</div>
                                    {/* <TextField
                                name='dc_vat_value'
                                margin='dense'
                                variant='outlined'
                                fullWidth
                                InputProps={{
                                  style: {
                                    fontFamily: 'Poppins',
                                    fontSize: '14px'
                                  }
                                }}
                                value={e.dc_vat_value}
                                onChange={(e) => {
                                  if (e.target.value === '' || pr.test(e.target.value)) {
                                    var row = this.state.multiRows;

                                    let pph23_value = ((Number(row[idx].base_price) * Number(row[idx].dc_pph23_percent)) / 100).toFixed(0);
                                    let payable_to_vendor = (Number(row[idx].base_price) + Number(e.target.value) - Number(pph23_value)).toFixed(2);
                                    let cost_to_company = (Number(row[idx].base_price) + Number(e.target.value)).toFixed(2);

                                    row[idx].dc_vat_value = e.target.value;
                                    row[idx].pph23_value = pph23_value;
                                    row[idx].payable_to_vendor = payable_to_vendor;
                                    row[idx].cost_to_company = cost_to_company;
                                    this.setState({
                                      multiRows: row
                                    });
                                  }
                                }}
                              /> */}
                                  </div>
                                  <div className='col-lg-6 p-0 mb-3'>
                                    <label className="form_label mb-0" style={{ color: 'rgba(72, 59, 52, 0.7)' }}> PPH23 %</label>
                                    {/* <div className='contract_display_header_value' style={{ color: '#42352d' }}>{e.pph23_value ? this.toLocaleString(e.pph23_value) : "-"}</div> */}
                                    <TextField
                                      name='pph23_percent'
                                      margin='dense'
                                      type="number"
                                      //variant='outlined'
                                      fullWidth
                                      InputProps={{
                                        style: {
                                          fontFamily: 'Poppins',
                                          fontSize: '14px'
                                        }
                                      }}
                                      value={e.pph23_percent}
                                      onChange={(e) => {
                                        if (e.target.value === '' || pr.test(e.target.value)) {
                                          var row = this.state.multiRows;
                                          let pph23_value = Number((Number(row[idx].base_price) * Number(e.target.value) / 100).toFixed(2));
                                          let payable_to_vendor = (Number(row[idx].base_price) + Number(row[idx].vat_value) - Number(pph23_value)).toFixed(2);
                                          let cost_to_company = (Number(row[idx].base_price) + Number(row[idx].vat_value)).toFixed(2);
                                          row[idx].pph23_percent = e.target.value;
                                          row[idx].pph23_value = pph23_value;
                                          row[idx].cost_to_company = cost_to_company;
                                          row[idx].payable_to_vendor = payable_to_vendor;
                                          this.setState({
                                            multiRows: row
                                          });
                                        }
                                      }}

                                    />
                                  </div>
                                  <div className='col-lg-6 pr-0 mb-3'>
                                    <label className="form_label mb-0" style={{ color: 'rgba(72, 59, 52, 0.7)' }}>Includes PPH23 @ {e.pph23_percent ? (e.pph23_percent + '%') : null}</label>
                                    <div className='contract_display_header_value' style={{ color: '#42352d' }}>{e.pph23_value ? this.toLocaleString(e.pph23_value) : "-"}</div>
                                  </div>
                                </div>

                                <div className='row'>
                                  <div className='col-lg-6 p-0'>
                                    <label className="form_label mb-0" style={{ color: 'rgba(72, 59, 52, 0.7)' }}>Payable to Vendor</label>
                                    <div className='contract_display_header_value' style={{ color: '#42352d' }}>{e.payable_to_vendor ? this.toLocaleString(e.payable_to_vendor) : 0} {(config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL") ? "USD" : "IDR"}</div>
                                  </div>
                                  <div className='col-lg-6 pr-0'>
                                    <label className="form_label mb-0" style={{ color: 'rgba(72, 59, 52, 0.7)' }}>Cost to Company</label>
                                    <div className='contract_display_header_value' style={{ color: '#42352d' }}>{e.cost_to_company ? this.toLocaleString(e.cost_to_company) : 0} {(config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL") ? "USD" : "IDR"}</div>
                                  </div>
                                </div>

                              </div>
                            }
                            {e.pph23_account === "SDAM" &&

                              <div className='col-lg-7 p-2 rounded' style={{ backgroundColor: "#f7f3f0" }}>
                                <div className='row col-lg-12 p-0'>
                                  <h6>Price</h6>
                                </div>
                                <div className='row'>
                                  <div className='col-6 p-0 mb-2'>
                                    <label className="form_label mb-0" style={{ color: 'rgba(72, 59, 52, 0.7)' }}>Base price</label>
                                    <div className='contract_display_header_value' style={{ color: '#42352d' }}>{e.base_price ? this.toLocaleString(e.base_price) : "-"}</div>
                                  </div>
                                  {/* <div className='col-6 pr-0 mb-2'>
                                <label className="form_label mb-0" style={{ color: 'rgba(72, 59, 52, 0.7)' }}>VAT %</label>
                                <div className='contract_display_header_value' style={{ color: '#42352d' }}>{p.dc_vat_percent ? this.toLocaleString(p.dc_vat_percent) : "-"}</div>
                              </div>
                              <div className='col-6 p-0 mb-2'>
                                <label className="form_label mb-0" style={{ color: 'rgba(72, 59, 52, 0.7)' }}>VAT</label>
                                <div className='contract_display_header_value' style={{ color: '#42352d' }}>{e.vat_value ? this.toLocaleString(e.vat_value) : "-"}</div>
                              </div> */}
                                  <div className='col-lg-6 pr-0 mb-3'>
                                    <label className="form_label mb-0" style={{ color: 'rgba(72, 59, 52, 0.7)' }}>Includes PPH23 @ {e.pph23_percent ? (e.pph23_percent + ' %') : null}</label>
                                    <div className='contract_display_header_value' style={{ color: '#42352d' }}>{e.pph23_value ? this.toLocaleString(e.pph23_value) : "-"}</div>
                                  </div>
                                </div>

                                <div className='row'>
                                  <div className='col-lg-6 p-0'>
                                    <label className="form_label mb-0" style={{ color: 'rgba(72, 59, 52, 0.7)' }}>Payable to Vendor</label>
                                    {/* <div className='contract_display_header_value' style={{ color: '#42352d' }}>{e.payable_to_vendor ? this.toLocaleString(e.payable_to_vendor) : "-"}</div> */}
                                    <TextField
                                      name='payable_to_vendor'
                                      margin='dense'
                                      type="number"
                                      //variant='outlined'
                                      fullWidth
                                      InputProps={{
                                        style: {
                                          fontFamily: 'Poppins',
                                          fontSize: '14px'
                                        }
                                      }}
                                      value={e.payable_to_vendor}
                                      onChange={(e) => {
                                        if (e.target.value === '' || pr.test(e.target.value)) {
                                          var row = this.state.multiRows;
                                          let base_price = ((Number(e.target.value) * 100) / 98).toFixed(2);
                                          let pph23_value = (Number(base_price) * Number(row[idx].pph23_percent) / 100).toFixed(2)
                                          var cost_to_company = (Number(base_price) + Number(pph23_value)).toFixed(2);
                                          row[idx].payable_to_vendor = e.target.value;
                                          row[idx].base_price = base_price;
                                          row[idx].pph23_value = pph23_value;
                                          row[idx].cost_to_company = cost_to_company;
                                          this.setState({
                                            multiRows: row
                                          });
                                        }
                                      }}

                                    />
                                  </div>
                                  <div className='col-lg-6 pr-0'>
                                    <label className="form_label mb-0" style={{ color: 'rgba(72, 59, 52, 0.7)' }}>Cost to Company</label>
                                    <div className='contract_display_header_value' style={{ color: '#42352d' }}>{e.cost_to_company ? this.toLocaleString(e.cost_to_company) : 0} {(config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL") ? "USD" : "IDR"}</div>
                                  </div>
                                </div>

                              </div>
                            }

                            {(config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL") &&

                              <div className='col-lg-7 p-3 rounded' style={{ backgroundColor: "#f7f3f0" }}>
                                <div className='row col-lg-12 p-0'>
                                  <h6>Price</h6>
                                </div>
                                <div className='row'>
                                  <div className='col-4 p-0 mb-2'>
                                    <label className="form_label mb-0" style={{ color: 'rgba(72, 59, 52, 0.7)' }}>Base price</label>
                                    {/* <div className='contract_display_header_value' style={{ color: '#42352d' }}>{e.base_price ? this.toLocaleString(e.base_price) : "-"}</div> */}
                                    <TextField
                                      name='base_price'
                                      margin='dense'
                                      type="number"
                                      //variant='outlined'
                                      fullWidth
                                      InputProps={{
                                        style: {
                                          fontFamily: 'Poppins',
                                          fontSize: '14px'
                                        }
                                      }}
                                      value={e.base_price}
                                      onChange={(e) => {
                                        if (e.target.value === '' || pr.test(e.target.value)) {
                                          var row = this.state.multiRows;
                                          var vat_value = Number((Number(e.target.value) * Number(row[idx].vat_percent)) / 100).toFixed(2);
                                          var cost_to_company = (Number(e.target.value) + Number(vat_value)).toFixed(2);
                                          row[idx].base_price = e.target.value;
                                          row[idx].vat_value = vat_value;
                                          row[idx].cost_to_company = cost_to_company;
                                          row[idx].payable_to_vendor = cost_to_company
                                          this.setState({
                                            multiRows: row
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                  <div className='col-4 pr-0 mb-2'>
                                    <label className="form_label mb-0" style={{ color: 'rgba(72, 59, 52, 0.7)' }}>VAT %</label>
                                    {/* <div className='contract_display_header_value' style={{ color: '#42352d' }}>{p.dc_vat_percent ? this.toLocaleString(p.dc_vat_percent) : "-"}</div> */}
                                    <TextField
                                      name='vat_percent'
                                      margin='dense'
                                      type="number"
                                      //variant='outlined'
                                      fullWidth
                                      InputProps={{
                                        style: {
                                          fontFamily: 'Poppins',
                                          fontSize: '14px'
                                        }
                                      }}
                                      value={e.vat_percent}
                                      onChange={(e) => {
                                        if (e.target.value === '' || pr.test(e.target.value)) {
                                          var row = this.state.multiRows;
                                          var vat_value = Number((Number(row[idx].base_price) * Number(e.target.value)) / 100).toFixed(2);
                                          var cost_to_company = (Number(row[idx].base_price) + Number(vat_value)).toFixed(0);
                                          row[idx].vat_percent = e.target.value;
                                          row[idx].vat_value = vat_value;
                                          row[idx].cost_to_company = cost_to_company;
                                          row[idx].payable_to_vendor = cost_to_company;
                                          this.setState({
                                            multiRows: row
                                          });
                                        }
                                      }}

                                    />
                                  </div>
                                  <div className='col-4 pr-0 mb-2'>
                                    <label className="form_label mb-2" style={{ color: 'rgba(72, 59, 52, 0.7)' }}>VAT</label>
                                    <div className='contract_display_header_value' style={{ color: '#42352d' }}>{e.vat_value ? this.toLocaleString(e.vat_value) : "-"}</div>
                                  </div>
                                  {/* <div className='col-lg-6 mb-3'>
                              <label className="form_label mb-0" style={{ color: 'rgba(72, 59, 52, 0.7)' }}>Includes PPH23 @ {e.pph23_percent ? (e.pph23_percent + ' %') : null}</label>
                              <div className='contract_display_header_value' style={{ color: '#42352d' }}>{e.pph23_value ? this.toLocaleString(e.pph23_value) : "-"}</div>
                            </div> */}
                                </div>

                                <div className='row'>
                                  <div className='col-lg-6 p-0'>
                                    <label className="form_label mb-0" style={{ color: 'rgba(72, 59, 52, 0.7)' }}>Payable to Vendor</label>
                                    <div className='contract_display_header_value' style={{ color: '#42352d' }}>{e.payable_to_vendor ? this.toLocaleString(e.payable_to_vendor) : 0} {(config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL") ? "USD" : "IDR"}</div>
                                    {/* <TextField
                                name='payable_to_vendor'
                                margin='dense'
                                type="number"
                                variant='outlined'
                                fullWidth
                                InputProps={{
                                  style: {
                                    fontFamily: 'Poppins',
                                    fontSize: '14px'
                                  }
                                }}
                                value={e.payable_to_vendor}
                                onChange={(e) => {
                                  if (e.target.value === '' || pr.test(e.target.value)) {
                                    var row = this.state.multiRows;
                                    let base_price = ((Number(e.target.value) * 100) / 98).toFixed(2);
                                    let pph23_value = (Number(base_price) * Number(row[idx].pph23_percent) / 100).toFixed(2)
                                    var cost_to_company = (Number(base_price) + Number(pph23_value)).toFixed(2);
                                    row[idx].payable_to_vendor = e.target.value;
                                    row[idx].base_price = base_price;
                                    row[idx].pph23_value = pph23_value;
                                    row[idx].cost_to_company = cost_to_company;
                                    this.setState({
                                      multiRows: row
                                    });
                                  }
                                }}

                              /> */}
                                  </div>
                                  <div className='col-lg-6 pr-0'>
                                    <label className="form_label mb-0" style={{ color: 'rgba(72, 59, 52, 0.7)' }}>Cost to Company</label>
                                    <div className='contract_display_header_value' style={{ color: '#42352d' }}>{e.cost_to_company ? this.toLocaleString(e.cost_to_company) : 0} {(config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL") ? "USD" : "IDR"}</div>
                                  </div>
                                </div>

                              </div>
                            }

                          </div>

                          <div className='row'>
                            <div className='col-lg-12 p-0'>
                              <label className='form_label mb-0'>Remarks</label>
                              <TextField
                                name='remarks'
                                margin='dense'
                                variant='outlined'
                                fullWidth
                                placeholder='Remarks'
                                value={e.remarks}
                                multiline
                                rows={3}
                                onChange={(e) => {
                                  var row = this.state.multiRows;
                                  row[idx].remarks = e.target.value;
                                  this.setState({
                                    multiRows: row
                                  })
                                }}

                                inputProps={{ style: { textTransform: 'uppercase' } }}
                              />
                            </div>
                          </div>

                        </div>
                        {this.state.multiRows.length > 1 &&
                          <center className="col-lg-1 d-flex justify-content-center">
                            <i className="fa fa-trash-o" onClick={this.removeProcurementRowsHandler(idx)} style={{ fontSize: 24, color: config.themeColor, cursor: 'pointer' }} />
                          </center>
                        }
                      </div>
                    ))}
                    <div className='row'>
                      <button type='button' onClick={this.addMoreProcurementRowsHandler} className='header_button header_button_text addrow_button_adjustment w-auto' style={{ color: config.themeColor }}>ADD ROW</button>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
          <footer className='row bottom_buttons_section'>
            <button type='button' className='btn previous_button btn-light' name='submit'
              onClick={this.state.activeStep === 0 ? () => this.props.returnCallbackDrawerClose(false) : this.previousStepHandler}
            >{this.state.activeStep === 0 ? "CANCEL" : "PREVIOUS"}</button>
            <button type='button'
              className={this.state.activeStep === 1 ? 'drawer_text drawer_btn_success' : 'btn next_button'} name='Back'
              onClick={this.state.activeStep === 1 ? this.submitHandler : this.nextStepHandler}
            >{this.state.activeStep === 1 ? "SUBMIT" : "NEXT STEP"}</button>

          </footer>
          <div className="modal fade" id="salesContractModal" role="dialog">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
              <div className='modal-content allocation_modal'>
                <div className='modal-body'>
                  <label className="form_label mb-2 mt-2">Select Sales Quality</label>
                  <button type="button" className="close" data-dismiss="modal">&times;</button>
                  <div className="row col-lg-12 pl-0">
                    {this.state.salesTypes.map((e, index) => (
                      <div className="col col-lg-6 p-1 mb-2" key={index}>
                        <div className="row col-lg-12 rounded p-0" style={{ border: e.id === this.state.salesQualitySelection ? '1px solid #ec7833' : '1px solid #dee2e6' }} >
                          <div className="col-lg-1 p-0">
                            <NewRadio
                              checked={e.id === this.state.salesQualitySelection}
                              //onChange={this.handleSalesTypesRadioButtons}
                              onChange={() => {
                                this.setState({
                                  sales_quality: e.quality,
                                  sc_qualities_id: e.id,
                                  salesQualitySelection: e.id
                                })
                              }}
                              value={e.id}
                              name="salesQualitySelection"
                              inputProps={{ 'aria-label': 'B' }}
                              className="collapsed"
                              data-toggle="collapse"
                              data-target={"#demo" + e.id}
                              aria-expanded="false"
                              aria-controls={"group-of-rows-1"}
                            />
                          </div>
                          <div className="col-lg-11 pr-0">
                            <div className="row col-lg-12 p-0">
                              <div className="col-lg-6 pl-0">
                                <label className="drawer_section_label mb-0">Quality</label>
                                <label className="drawer_section_value mb-0">{e.quality}</label>
                              </div>
                              <div className="col-lg-6 pr-0">
                                <label className="drawer_section_label mb-0">Tolerence</label>
                                <label className="drawer_section_value mb-0">{e.tolerance_adjusted + '%'}</label>
                              </div>
                            </div>
                            <div className="row col-lg-12 p-0">
                              <div className="col-lg-6 pl-0">
                                <label className="drawer_section_label mb-0">Quantity</label>
                              </div>
                              <div className="col-lg-6 pr-0">
                                <label className="drawer_section_total mb-0">Total</label>
                                <label className="drawer_section_total_value mb-0">{this.toLocaleString(Number(e.quantity_adjusted).toFixed(3))}</label>
                              </div>
                            </div>
                            <div style={{ width: '98%' }}>
                              <BorderLinearProgress variant="determinate" value={this.progressBarSize(e, 'NSC')} style={{ borderRadius: 5 }} />
                            </div>
                            <div className="row col-lg-12 p-0 mb-2">
                              <div className="col-lg-4 pl-0">
                                <label className="drawer_section_value mb-0">{this.toLocaleString(Number(e.quantity_allocated).toFixed(3))}</label>
                                <label className="drawer_section_label mb-0">Allocated</label>
                              </div>
                              <div className="col-lg-4 pl-0">
                                <label className="drawer_section_value mb-0">{this.toLocaleString(Number(e.sc_quantity_delivered).toFixed(3))}</label>
                                <label className="drawer_section_label mb-0">Delivered</label>
                              </div>
                              <div className="col-lg-4 pl-0">
                                <label className="drawer_section_value mb-0">{this.toLocaleString(this._toBeDelivered(e))}</label>
                                <label className="drawer_section_label mb-0">Remaining</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <label className="form_label mb-2 mt-2">Select Sales Type</label>
                  <div className="pl-0">
                    {this.state.salesTypes.map((e, index) => (
                      <div key={index}>
                        {e.id === this.state.salesQualitySelection &&
                          <table style={{ width: '100%' }}>
                            <thead className='thead-light'>
                              <tr>
                                <th></th>
                                <th className="drawer_table_header_label">Mine Name</th>
                                <th className="drawer_table_header_label">Sales Type</th>
                                <th className="drawer_table_header_label">Anchorage Name</th>
                                <th className="drawer_table_header_label">Coal Price</th>
                              </tr>
                            </thead>
                            {e.sales_types.map((s, idx) => (
                              <tbody key={idx}>
                                <tr style={{ border: s.id === this.state.salesTypeSelection ? '1px solid #ec7833' : '1px solid #dee2e6' }}>
                                  <td style={{ verticalAlign: 'middle' }}>
                                    <NewRadio
                                      checked={s.id === this.state.salesTypeSelection}
                                      //onChange={this.handleSalesTypesRadioButtons}
                                      onChange={(e) => {
                                        this.setState({
                                          sales_type: s.sales_type,
                                          sc_sales_type_id: e.target.value,
                                          salesTypeSelection: e.target.value,
                                          coal_cost_coal_price_pmt: s.price_pmt,
                                          sales_price_pmt: s.price_pmt,

                                        }, () => {
                                          window.$("#salesContractModal").modal("hide");
                                        })
                                      }}
                                      value={s.id}
                                      name="salesTypeSelection"
                                      inputProps={{ 'aria-label': 'B' }}
                                    />
                                  </td>
                                  <td className="drawer_table_value">{s.mine_name}</td>
                                  <td className="drawer_table_value">{s.sales_type}</td>
                                  <td className="drawer_table_value">{s.sales_type === "FAS Anchorage" ? s.anchorage_name : ''}</td>
                                  <td className="drawer_table_value">{s.price_pmt} {this.state.salesCurrency}</td>
                                </tr>
                              </tbody>
                            ))}
                          </table>
                        }
                      </div>
                    ))}
                  </div>
                </div>

                <div className="row bottom_buttons_section">
                  {/* <button type='button' className='btn previous_button' onClick={() => this.salesContractModalClose(this.state.modalID)}>Cancel</button>
                <button type='button' className='btn next_button' onClick={() => this.salesTypeSelected(this.state.modalID)}>Close</button>&emsp; */}
                  <button type='button' className='btn next_button' data-dismiss="modal">Close</button>&emsp;
                </div>

              </div>
            </div>
          </div>
        </div>

      )
    }
  }
}
