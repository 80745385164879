import React, { Component } from "react";
import api from "../../../api/api";
import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import { Alert } from "@material-ui/lab";
import { localDateFormate, pad, toLocaleString } from "../../common/common";
import ViewFiles from "../../common/ViewFiles";
import InvoiceForm from "../../common/InvoiceForm";
import InvoiceLink from "../../InvoicePdf/InvoiceLink";
import PaymentPostings from "../../common/PaymentPostings";
import Loader from "../../common/Loader";
import BusinessApproveButton from "../../Approvals/BusinessApproveButton";
import { Snackbar } from "@material-ui/core";
import AuditInfo from "../../common/AuditInfo";

export default class Barge_Purchase_Fin_Chemical_Spray_View extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      payee_name: "",
      currency_exchange_rate: "",
      local_currency: "",
      remarks: "",
      isLoading: true,
      activeStep: 0,
      chemical_spray_payments: [],
      approval_status: "Pending",
      files: [],
      features: [],
      payment_due_date: null,
      paymentsDialog: false,
      paymentMode: "Add",
      invoiceDetails: {
        invoice_no: "",
        invoice_date: null,
        ppn: "",
        ppn_date: null,
      },
      bargeList: [],
      editExchg: false,
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (!(features.includes("576") || features.includes("97"))) {
      window.location.href = "/dashboard";
    }
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const business_no = [],
      barge_id = "",
      barge_name = "",
      jetty_name = "";
    const chemical_spray_id = this.props.chemicalSprayID;
    await api
      .get_barges_for_chemical_spray(
        loginUserID,
        idToken,
        business_no,
        barge_id,
        barge_name,
        jetty_name
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.barges) {
                let newData = [];
                var result = res.barges.reduce(function (r, a) {
                  r[a.id] = r[a.id] || [];
                  r[a.id].push(a);
                  return r;
                }, Object.create(null));
                for (let l = 0; l < Object.keys(result).length; l++) {
                  newData.push({ new_barges: result[Object.keys(result)[l]] });
                }
                let purchase_contracts_barges = newData;
                if (newData.length === 0) {
                  this.setState({
                    snackBarErrorOpen: true,
                    errorMsg: "No records to display",
                  });
                }
                this.setState({
                  chemical_spray_costing_barges: purchase_contracts_barges,
                  //isLoading: false
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
                // isLoading: false
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
    await api
      .get_barge_chemical_spray(loginUserID, idToken, chemical_spray_id)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.barge_chemical_spray) {
                if (res.barge_chemical_spray.length > 0) {
                  var paid_to_vendor = 0;
                  var remaining_amount = 0;
                  for (var i = 0; i < res.barge_chemical_spray.length; i++) {
                    if (
                      res.barge_chemical_spray[i].chemical_spray_payments
                        .length > 0
                    ) {
                      for (var j of res.barge_chemical_spray[i]
                        .chemical_spray_payments) {
                        if (j.date_of_payment) {
                          paid_to_vendor += Number(j.amount);
                        }
                        remaining_amount =
                          Number(
                            res.barge_chemical_spray[0].chemical_spray_price
                              ? res.barge_chemical_spray[0].chemical_spray_price
                              : 0
                          ) - Number(paid_to_vendor);
                        if (
                          config.company === "SRPL" ||
                          config.company === "PRPL" ||
                          config.company === "AVS"
                        ) {
                          remaining_amount =
                            Number(
                              res.barge_chemical_spray[0].local_currency
                                ? res.barge_chemical_spray[0].local_currency
                                : 0
                            ) - Number(paid_to_vendor);
                        }
                      }
                    } else {
                      remaining_amount = Number(
                        res.barge_chemical_spray[0].chemical_spray_price
                          ? res.barge_chemical_spray[0].chemical_spray_price
                          : 0
                      );
                      if (
                        config.company === "SRPL" ||
                        config.company === "PRPL" ||
                        config.company === "AVS"
                      ) {
                        remaining_amount = Number(
                          res.barge_chemical_spray[0].local_currency
                            ? res.barge_chemical_spray[0].local_currency
                            : 0
                        );
                      }
                    }
                  }
                }
                let invoice_details = {
                  ...this.state.invoiceDetails,
                  invoice_no: res.barge_chemical_spray[0]?.invoice_no,
                  invoice_date: res.barge_chemical_spray[0]?.invoice_date,
                  ppn: res.barge_chemical_spray[0]?.ppn,
                  ppn_date: res.barge_chemical_spray[0]?.ppn_date,
                };
                this.setState((prevState) => ({
                  invoiceDetails: invoice_details,
                  ...prevState,
                  ...res.barge_chemical_spray[0],
                  bargeList: res.barge_chemical_spray[0].barge_id
                    ? res.barge_chemical_spray[0].barge_id.split(",")
                    : [],

                  paid_to_vendor: paid_to_vendor,
                  remainingAmount: remaining_amount,
                  remaining_amount: remaining_amount,
                  isLoading: false,
                }));
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });

    document.title =
      config.documentTitle + "Barge Purchase Financial Chemical Spray";
  }

  saveHandler = () => {
    let {
      chemical_spray_price,
      payee_name,
      billing_currency,
      currency_exchange_rate,
      local_currency,
      remarks,
      vendor_id,
      files,
      bargeList,
    } = this.state;
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const chemical_spray_id = this.props.chemicalSprayID;
    const barge_id = bargeList.toString();
    if (
      config.company === "SDAM" ||
      config.company === "SRK" ||
      config.company === "BTR"
    ) {
      currency_exchange_rate = 1;
      local_currency = chemical_spray_price;
    }
    // vendor_id = vendor_id === "Others" ? null : vendor_id;
    // if (vendor_id && vendor_id !== "Others") {
    //   payee_name = "";
    // }

    var existing_file_ids = [];
    const existing_files = files.filter((e) => e.id);
    for (var i of existing_files) {
      existing_file_ids.push(i.id);
    }
    const new_files = files.filter((e) => !e.id);
    const content_files = new_files.filter((e) => e.file_content !== "");
    const costing_file = [];
    api
      .update_barge_chemical_spray(
        loginUserID,
        idToken,
        barge_id,
        chemical_spray_id,
        chemical_spray_price,
        vendor_id,
        payee_name,
        billing_currency,
        currency_exchange_rate,
        local_currency,
        remarks,
        existing_file_ids,
        content_files,
        costing_file
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState(
                {
                  editExchg: false,
                  snackBarSuccessOpen: true,
                  successMsg: "Costing Updated sucessfully.",
                },
                () => this.componentDidMount()
              );
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                isLoading: false,
                errorMsg: res.mesage,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  };

  updateInvoice = (data) => {
    let request = {
      ...data,
      table_name: "barge_chemical_spray",
    };
    api.update_invoice_details(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.componentDidMount();
            this.setState({
              invoiceDialog: false,
            });
          }
        });
      }
    });
  };

  renderPaymentPostings = (data) => {
    const {
      chemical_spray_payments = [],
      remaining_amount = 0,
      currency_exchange_rate = 1,
      approval_status = "Pending",
    } = data;
    const { invoiceDetails: { invoice_no } = {} } = data;
    let payment_related_data = {
      paymentTitle: "Chemical Spray",
      payment_posting_type: "Chemical_spray",
      invoice_no: invoice_no,
      outstanding_amount: remaining_amount,
      billing_currency: "IDR",
      currency_xchg_rate: currency_exchange_rate,
      costing_id: this.props.chemicalSprayID,
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
    };
    let access_details = {
      costing_approval: approval_status,
      add_payment: "582",
      view_payment: "583",
      update_payment: "584",
      delete_payment: "585",
      approver: "586",
      approver2: "587",
    };

    return (
      <PaymentPostings
        payments={chemical_spray_payments}
        costingType="Chemical Spray"
        data={payment_related_data}
        access_details={access_details}
        callbackFn={() => {
          this.componentDidMount();
        }}
      />
    );
  };

  renderInvoiceLink = () => {
    const data = this.state.chemical_spray_costing_barges.map((e) =>
      e.new_barges.filter((obj) => obj.id === this.props.chemicalSprayID)
    );
    const {
      invoiceDetails: { invoice_no, invoice_date } = {},
      costing_no,
      barge_quantity = 0,
      mv_name,
      currency_exchange_rate,
      chemical_spray_price,
      local_currency,
      payee_name,
    } = this.state;

    let Grand_total = (
      parseFloat(chemical_spray_price ? chemical_spray_price : 0) /
      parseFloat(currency_exchange_rate ? currency_exchange_rate : 1)
    ).toFixed(2);

    if (config.company !== "SRPL" && config.company !== "PRPL") {
      Grand_total = parseFloat(
        chemical_spray_price ? chemical_spray_price : 1
      ).toFixed(2);
    }
    const invoiceObject = {
      costing_id: costing_no,
      to: payee_name,
      business_no: mv_name,
      costing_name: "Chemical Spray",
      invoice_no: invoice_no,
      invoice_date: localDateFormate(invoice_date),
      bargeDetails: data ? data.flat() : [],
      billing_currency:
        config.company === "SRPL" ||
          config.company === "PRPL" ||
          config.company === "AVS"
          ? "USD"
          : "IDR",
      total_payable_amount: toLocaleString(Grand_total),
      purchase_type: "FOB Barge",
      description: [
        {
          qty: toLocaleString(barge_quantity > 1 ? barge_quantity : 1),
          adjustedPrice: toLocaleString(chemical_spray_price),
          desc:
            currency_exchange_rate > 1
              ? `Chemical Spray @ ${parseFloat(currency_exchange_rate).toFixed(
                2
              )}`
              : "Chemical Spray",
          amount: local_currency,
        },
      ],
    };

    return <InvoiceLink data={invoiceObject} fileName={costing_no} />;
  };

  render() {
    const chemicalSprayID = this.props.chemicalSprayID;
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div className="container bg-white p-0" style={{ minHeight: "100vh" }}>
          <div className="clearfix content-header border-bottom">
            <div className="row">
              <div className="col-lg-9 text-left pl-0">
                <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                  {`Chemical Spray - ${this.state?.costing_no}`}
                  <span
                    className={
                      this.state.approval_status === "Pending"
                        ? "type_2"
                        : "type_1"
                    }
                    style={{ padding: 8, marginLeft: 16 }}
                  >
                    {this.state.approval_status}
                  </span>
                </h4>
              </div>
              {this.state.approval_status === "Pending" &&
                this.state.invoice_file &&
                this.Cookie.getCookie("loginUserId") !==
                this.state.created_by &&
                this.state.approved_by !==
                this.Cookie.getCookie("loginUserId") &&
                (this.state.features.includes("579") ||
                  this.state.features.includes("97")) && (
                  <BusinessApproveButton
                    costingID={chemicalSprayID}
                    costingType="Chemical Spray"
                    accessInfo={{
                      ...this.state,
                      approver_1: "579",
                      approver_2: "97",
                    }}
                    callbackFn={() => this.componentDidMount()}
                  />
                )}
              <div className="col-lg-1 pl-0 float-right">
                {this.renderInvoiceLink()}
              </div>
            </div>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <div
            className="drawer-section"
            ref={(el) => (this.componentRef = el)}
          >
            <div className="card">
              {this.state.chemical_spray_costing_barges.length > 0 && (
                <div className="table-responsive">
                  <table className="table table-bordered table-sm">
                    <thead className="table-header">
                      <tr>
                        <th nowrap="true">Barge ID</th>
                        <th nowrap="true">Barge Nomination</th>
                        <th nowrap="true">Business No</th>
                        <th className="text-right" nowrap="true">
                          Total Payable
                        </th>
                        <th className="text-right" nowrap="true">
                          Paid
                        </th>
                        <th nowrap="true">Remaining</th>
                      </tr>
                    </thead>
                    {this.state.chemical_spray_costing_barges.map(
                      (p, index) => (
                        <tbody key={index}>
                          {p.new_barges.map((idr, indx) => (
                            <tr
                              key={indx}
                              style={{
                                display:
                                  chemicalSprayID === idr.id ? "" : "none",
                              }}
                            >
                              <td>
                                <a
                                  href={"/view-barge/" + btoa(idr.barge_id)}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{ color: config.themeColor }}
                                >
                                  {pad(idr.barge_id)}
                                </a>
                              </td>
                              <td nowrap="true">{idr.barge_nomination}</td>
                              <td nowrap="true">{idr.business_no}</td>
                              {indx === 0 && (
                                <td
                                  className="text-right"
                                  nowrap="true"
                                  rowSpan={p.new_barges.length}
                                >
                                  {toLocaleString(idr.total_payable)}
                                </td>
                              )}
                              {indx === 0 && (
                                <td
                                  className="text-right"
                                  nowrap="true"
                                  rowSpan={p.new_barges.length}
                                >
                                  {toLocaleString(idr.paid)}
                                </td>
                              )}
                              {indx === 0 && (
                                <td nowrap="true" rowSpan={p.new_barges.length}>
                                  {toLocaleString(idr.Remaining_amount)}
                                </td>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      )
                    )}
                  </table>
                </div>
              )}
              {/* New Changes */}
              <div className="col shadow-sm p-0 mb-2 bg-white border">
                <div className="table-responsive">
                  <table className="table table-sm table-bordered mb-0">
                    <thead className="table-header">
                      <tr>
                        <th className="text-center">Description</th>
                        <th className="text-right">Qty</th>
                        <th className="text-right">Unit Price</th>
                        <th className="text-right">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <>
                        <tr>
                          <td>
                            Chemical Spray
                            {(config.company === "SRPL" ||
                              config.company === "AVS" ||
                              config.company === "PRPL") &&
                              this.state.currency_exchange_rate > 1 && (
                                <div>
                                  Exchange Rate @{" "}
                                  {toLocaleString(
                                    this.state.currency_exchange_rate
                                  )}
                                </div>
                              )}
                          </td>
                          <td className="text-right"></td>
                          <td className="text-right"></td>
                          <td className="text-right">
                            {this.state.chemical_spray_price
                              ? toLocaleString(this.state.chemical_spray_price)
                              : "_"}
                          </td>
                        </tr>
                        <tr>
                          <td
                            colSpan={3}
                            className="text-right"
                            style={{ fontWeight: "bold" }}
                          >
                            Payable to{" "}
                            {this.state.vendor_name
                              ? this.state.vendor_name
                              : this.state.payee_name}{" "}
                            :
                          </td>
                          <td
                            className="text-right"
                            style={{ fontWeight: "bold" }}
                          >
                            {config.company === "SRPL" ||
                              config.company === "PRPL" ||
                              config.company === "AVS"
                              ? "USD"
                              : "IDR"}{" "}
                            {this.state.local_currency}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={3} className="text-right">
                            Paid
                          </td>
                          <td>{toLocaleString(this.state.paid_to_vendor)}</td>
                        </tr>
                        <tr>
                          <td colSpan={3} className="text-right">
                            Remaining
                          </td>
                          <td>{toLocaleString(this.state.remaining_amount)}</td>
                        </tr>
                      </>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="col-lg-12">
                {/* </div> */}
                <div className="col-lg-12 p-0 mb-2">
                  <label className="contract_display_header_label">
                    Remarks
                  </label>
                  <div className="text-uppercase">
                    {this.state.remarks ? this.state.remarks : "-"}
                  </div>
                </div>
                <div className="card p-0 border mb-3">
                  <InvoiceForm
                    open={false}
                    data={this.state.invoiceDetails}
                    costingID={this.props.chemicalSprayID}
                    callbackFn={(data) => this.updateInvoice(data)}
                  />
                  {/* <legend className="card-header section_header">
                    <h5>Chemical Spray Payment Postings</h5>
                  </legend> */}

                  {this.renderPaymentPostings(this.state)}
                </div>
                <ViewFiles
                  {...this.props}
                  type="Costings"
                  invoice_file={this.state.invoice_file}
                  files_data={this.state.files}
                />
              </div>

              <AuditInfo info={this.state} />
            </div>
          </div>
          <div
            className="row col-lg-12 modal-footer justify-content-end bottom_buttons_section"
            style={{ bottom: 0, background: "white" }}
          >
            <button
              type="button"
              className="drawer_button drawer_text drawer_calculate_button drawer_calculate_adjustment"
              onClick={() => this.props.onCancel(false)}
            >
              Cancel
            </button>
            <button
              type="button"
              className="drawer_button drawer_text"
              onClick={() => this.props.onEdit(true)}
            >
              Edit
            </button>
          </div>
        </div>
      );
    }
  }
}
