import React, { Component } from 'react';
import Header from '../common/Header';
import SideBar from '../common/SideBar';
import CookieHandler from "../common/CookieHandler";
import api from '../../api/api';
import Loader from '../common/Loader';
// import config from '../../config/config';
import {
  Snackbar,
  Tooltip
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { toLocaleString } from '../common/common';
import exportToExcel from "../Export_To_Excel";

export default class BudgetAndCostingView extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();

    this.state = {
      isLoading: true,
      laycan: '',
      vessel_name: '',
      budget_currency: '0',
      exchange_rate: '',
      currency_exchg_rate: '',
      total_cost_of_purchase: '',
      total_cost_of_purchase_pmt: '',
      total_cost_of_sales: '',
      total_cost_of_sales_pmt: '',
      realization_pmt: '',
      coal_sales_data: [],
      coal_purchase_data: [],

      csf_mapped_data: [],
      csf_manual_data: [],
      cpf_mapped_data: [],
      cpf_manual_data: [],
      barge_cost_data: [],
      misc_expenses_data: [],
      features: [],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    this.setState({ features: features });

    if (!(features.includes('641') || features.includes('97'))) {
      window.location.href = '/';
    }

    const request = {
      login_user_id: this.Cookie.getCookie('loginUserId'),
      idtoken: this.Cookie.getIdTokenCookie(),
      budget_costing_id: decodeURIComponent(
        window.atob(this.props.match.params.budgetID)
      ),
    }

    await api.get_budget_costing(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === '200') {
            if (res.budget_costing) {
              this.setState((prev) => ({
                ...prev,
                ...res.budget_costing,
                isLoading: false,
              }));
            }

          } else if (res.code === '601') {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
            });

          } else if (res.code === '607') {
            window.location.href = '/logout';
          }
        })
      }
    })

  }

  convertExcelHandler = (data, filename, ShowLabel) => {
    const { budget_costing_no, coal_sales_data, coal_purchase_data, csf_manual_data, csf_mapped_data, cpf_manual_data, cpf_mapped_data, barge_cost_data, misc_expenses_data } = this.state;
    const Modified = [];

    for (var i of coal_sales_data) {
      Modified.push({
        "DESCRIPTION": "Coal Sales ",
        "SUPPLIER / BUYER": i.customer_name,
        "CONTRACT": i.contract_no,
        "QUALITY": i.quality ? i.quality : i.quality_input,
        "PURCHASE / SALES TYPE": i.sales_type ? i.sales_type : i.sales_type_input,
        "PRICE TYPE": "Per MT",
        "QUANTITY": toLocaleString(Number(i.quantity ? i.quantity : 0)),
        "PRICE": toLocaleString(Number(i.price_pmt ? i.price_pmt : 0)),
        "CURRENCY": i.currency,
        "TOTAL": toLocaleString(Number(i.total ? i.total : 0))
      })
    }
    for (var j of coal_purchase_data) {
      Modified.push({
        "DESCRIPTION": "Coal Purchase ",
        "SUPPLIER / BUYER": j.vendor_name,
        "CONTRACT": j.contract_no,
        "QUALITY": j.quality ? j.quality : j.quality_input,
        "PURCHASE / SALES TYPE": j.purchase_type ? j.purchase_type : j.purchase_type_input,
        "PRICE TYPE": "Per MT",
        "QUANTITY": toLocaleString(Number(j.quantity ? j.quantity : 0)),
        "PRICE": toLocaleString(Number(j.price_pmt ? j.price_pmt : 0)),
        "CURRENCY": j.currency,
        "TOTAL": toLocaleString(Number(j.total ? j.total : 0))
      })
    }
    for (var k of csf_manual_data) {
      Modified.push({
        "DESCRIPTION": "Service Fee ",
        "SUPPLIER / BUYER": k.vendor_name,
        "CONTRACT": k.contract_no,
        "QUALITY": k.quality,
        "PURCHASE / SALES TYPE": k.purchase_type,
        "PRICE TYPE": k.price_type,
        "QUANTITY": toLocaleString(Number(k.quantity ? k.quantity : 0)),
        "PRICE": toLocaleString(Number(k.price ? k.price : 0)),
        "CURRENCY": j.currency,
        "TOTAL": toLocaleString(Number(k.total ? k.total : 0))
      })
    }
    for (var l of csf_mapped_data) {
      Modified.push({
        "DESCRIPTION": "Service Fee ",
        "SUPPLIER / BUYER": l.vendor_name,
        "CONTRACT": l.contract_no,
        "QUALITY": l.quality,
        "PURCHASE / SALES TYPE": l.purchase_type,
        "PRICE TYPE": l.price_type,
        "QUANTITY": toLocaleString(Number(l.quantity ? l.quantity : 0)),
        "PRICE": toLocaleString(Number(l.price ? l.price : 0)),
        "CURRENCY": j.currency,
        "TOTAL": toLocaleString(Number(l.total ? l.total : 0))
      })
    }
    for (var m of cpf_manual_data) {
      Modified.push({
        "DESCRIPTION": "Procurement Fee ",
        "SUPPLIER / BUYER": m.vendor_name,
        "CONTRACT": m.contract_no,
        "QUALITY": m.quality,
        "PURCHASE / SALES TYPE": m.purchase_type,
        "PRICE TYPE": m.price_type,
        "QUANTITY": toLocaleString(Number(m.quantity ? m.quantity : 0)),
        "PRICE": toLocaleString(Number(m.price ? m.price : 0)),
        "CURRENCY": m.currency,
        "TOTAL": toLocaleString(Number(m.total ? m.total : 0))
      })
    }
    for (var n of cpf_mapped_data) {
      Modified.push({
        "DESCRIPTION": "Procurement Fee ",
        "SUPPLIER / BUYER": n.vendor_name,
        "CONTRACT": n.contract_no,
        "QUALITY": n.quality,
        "PURCHASE / SALES TYPE": n.purchase_type,
        "PRICE TYPE": n.price_type,
        "QUANTITY": toLocaleString(Number(n.quantity ? n.quantity : 0)),
        "PRICE": toLocaleString(Number(n.price ? n.price : 0)),
        "CURRENCY": n.currency,
        "TOTAL": toLocaleString(Number(n.total ? n.total : 0))
      })
    }
    for (var o of barge_cost_data) {
      Modified.push({
        "DESCRIPTION": "Barge Cost",
        "SUPPLIER / BUYER": o.vendor_name,
        "CONTRACT": o.contract_no,
        "QUALITY": o.quality,
        "PURCHASE / SALES TYPE": o.purchase_type,
        "PRICE TYPE": o.price_type,
        "QUANTITY": toLocaleString(Number(o.quantity ? o.quantity : 0)),
        "PRICE": toLocaleString(Number(o.price ? o.price : 0)),
        "CURRENCY": o.currency,
        "TOTAL": toLocaleString(Number(o.total ? o.total : 0))
      })
    }
    for (var p of misc_expenses_data) {
      Modified.push({
        "DESCRIPTION": "Misc Expenses " + p.costing_type,
        "SUPPLIER / BUYER": '',
        "CONTRACT": '',
        "QUALITY": '',
        "PURCHASE / SALES TYPE": '',
        "PRICE TYPE": p.price_type,
        "QUANTITY": toLocaleString(Number(p.quantity ? p.quantity : 0)),
        "PRICE": toLocaleString(Number(p.price ? p.price : 0)),
        "CURRENCY": o.currency,
        "TOTAL": toLocaleString(Number(p.total ? p.total : 0))
      })
    }

    exportToExcel(Modified, budget_costing_no);
  };

  render() {
    const budgetID = decodeURIComponent(
      window.atob(this.props.match.params.budgetID)
    );
    return (
      <div id="wraper">
        <SideBar />
        <div className="content">
          <Header />
          <div className="content-header">
            <div className="clearfix content-header border-bottom">
              <div className="row">
                <div className="col-lg-11 pl-0">
                  <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                    {`Budget And Costing - ${this.state.budget_costing_no}`}
                  </h4>
                </div>
                <div className="col-lg-1 pl-0 float-right">
                  <Tooltip title='Download' arrow>
                    <i className="fa fa-file-excel-o ml-2 mt-2 " aria-hidden="true" style={{ color: 'green', cursor: 'pointer', fontSize: 22 }}
                      onClick={() => this.convertExcelHandler()}
                    />
                  </Tooltip>              </div>
              </div>
            </div>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          {this.state.isLoading && <Loader />}

          {!this.state.isLoading &&

            <form className="container p-3">
              <div className="card" style={{ overflowY: "scroll" }}>
                <div className="section_block w-100" >
                  <div className="row mb-2">
                    <div className="col-lg-3">
                      <label className="contract_display_header_label mb-0"> Laycan </label>
                      <label className='contract_display_header_value text-uppercase'>
                        {this.state.laycan}
                      </label>

                    </div>
                    <div className="col-lg-3">
                      <label className="contract_display_header_label mb-0">
                        Vessel Name
                      </label>
                      <label className='contract_display_header_value  text-uppercase'>
                        {this.state.vessel_name}
                      </label>
                    </div>

                    <div className="col-lg-3">
                      <label className="contract_display_header_label mb-0">
                        Currency
                      </label>
                      <label className='contract_display_header_value'>
                        {this.state.budget_currency}
                      </label>
                    </div>

                    <div className="col-lg-3">
                      <label className="contract_display_header_label mb-0">
                        Exchange Rate
                      </label>
                      <label className='contract_display_header_value'>
                        {this.state.exchange_rate}
                      </label>

                    </div>
                  </div>


                  <div className="table-responsive">
                    <table className='table table-sm table-bordered'>
                      <thead className='table-header'>
                        <tr>
                          <th nowrap="true">Customer / Vendor</th>
                          <th nowrap="true">Contract</th>
                          <th nowrap="true">Quality </th>
                          <th nowrap="true">Sales / Purchase Type</th>
                          <th nowrap="true">Price Type</th>
                          <th className='text-right' nowrap="true">Quantity </th>
                          <th className='text-right' nowrap="true">Price </th>
                          <th nowrap="true">Currency</th>
                          <th className='text-right' nowrap="true">Total </th>
                        </tr>

                      </thead>
                      <tbody>
                        <tr>
                          <th colSpan={9}>Coal Sales</th>
                        </tr>
                        {this.state.coal_sales_data?.map((e, idx) => (
                          <tr key={e.buyer}>
                            <td nowrap="true">{e.customer_name}</td>
                            <td nowrap="true">{e.contract_no}</td>

                            <td nowrap="true">
                              {e.sales_contract ?

                                <div>{e.quality}</div>

                                :
                                <div>{e.quality_input}</div>

                              }
                            </td>
                            <td nowrap="true">
                              {(e.quality_id && e.quality_id !== "0") ?
                                <div>{e.sales_type}</div>
                                :
                                <div>{e.sales_type_input}</div>
                              }
                            </td>
                            <td nowrap="true"> Per MT</td>
                            <td nowrap="true" className='text-right'>{toLocaleString(e.quantity)}</td>
                            <td nowrap="true" className='text-right'>{toLocaleString(e.price_pmt)}</td>
                            <td nowrap="true">{e.currency}</td>
                            <td nowrap="true" className='text-right'>{toLocaleString(e.total)}</td>
                          </tr>
                        ))}
                        <tr>
                          <td colSpan={5}></td>
                          <td className='text-right' style={{ fontWeight: 'bold' }}>{this.state.coal_sales_data?.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.quantity ? curr.quantity : 0), 0)}</td>
                          <td colSpan={2}></td>
                          <td className='text-right' style={{ fontWeight: 'bold' }} >{this.state.coal_sales_data?.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.total ? curr.total : 0), 0)}</td>
                        </tr>
                        <tr>
                          <th colSpan={9}>Coal Purchase</th>
                        </tr>
                        {this.state.coal_purchase_data?.map((e, idx) => (
                          <tr key={e.buyer}>
                            <td nowrap="true">{e.vendor_name} </td>
                            <td nowrap="true">{e.contract_no}</td>
                            <td nowrap="true">
                              {e.purchase_contract ?
                                <div className=''>{e.quality}</div>
                                :
                                <div className=''>{e.quality_input}</div>
                              }

                            </td>
                            <td nowrap="true">
                              {(e.quality_id && e.quality_id !== "0") ?
                                <div className=''>{e.purchase_type}</div>
                                :
                                <div className=''>{e.purchase_type_input}</div>
                              }
                            </td>
                            <td nowrap="true"> Per MT</td>
                            <td nowrap="true" className='text-right'>{toLocaleString(e.quantity)} </td>
                            <td nowrap="true" className='text-right'>{toLocaleString(e.price_pmt)} </td>
                            <td>{e.currency}</td>
                            <td nowrap="true" className='text-right'>{toLocaleString(e.total)}</td>
                          </tr>
                        ))}
                        <tr>
                          <td colSpan={5}></td>
                          <td className='text-right' style={{ fontWeight: 'bold' }}>{toLocaleString(this.state.coal_purchase_data?.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.quantity ? curr.quantity : 0), 0))}</td>
                          <td colSpan={2}></td>
                          <td className='text-right' style={{ fontWeight: 'bold' }}>{toLocaleString(this.state.coal_purchase_data?.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.total ? curr.total : 0), 0))}</td>
                        </tr>
                        <tr>
                          <th colSpan={9}>Service Fee</th>
                        </tr>

                        {this.state.csf_mapped_data?.map((e, idx) => (
                          <tr key={e.contract_no}>
                            <td nowrap="true"> {e.vendor_name}</td>
                            <td nowrap="true">{e.contract_no}</td>
                            <td nowrap="true">{e.quality}</td>
                            <td nowrap="true">{e.purchase_type}</td>
                            <td nowrap="true">{e.price_type}</td>
                            <td nowrap="true" className='text-right'>{toLocaleString(e.quantity)}</td>
                            <td nowrap="true" className='text-right'>{toLocaleString(e.price)}</td>
                            <td nowrap="true">{e.currency}</td>
                            <td className='text-right'>{toLocaleString(e.total)}</td>
                          </tr>
                        ))}

                        {this.state.csf_manual_data?.map((e, idx) => (
                          <tr key={idx}>
                            <td nowrap="true">{e.vendor_name} </td>
                            <td className='text-center'>-</td>
                            <td>{e.quality}</td>
                            <td className='text-center'>-</td>
                            <td nowrap="true">{e.price_type}</td>
                            <td nowrap="true" className='text-right'>{toLocaleString(e.quantity)}</td>
                            <td nowrap="true" className='text-right'>{toLocaleString(e.price)}</td>
                            <td nowrap="true">{e.currency}</td>
                            <td nowrap="true" className='text-right'>{toLocaleString(e.total)}</td>
                          </tr>
                        ))}
                        <tr>
                          <td colSpan={5}></td>
                          <td className='text-right' style={{ fontWeight: 'bold' }}>{toLocaleString(parseFloat(this.state.csf_mapped_data?.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.quantity ? curr.quantity : 0), 0)) + parseFloat(this.state.csf_manual_data?.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.quantity ? curr.quantity : 0), 0)))}</td>
                          <td colSpan={2}></td>
                          <td className='text-right' style={{ fontWeight: 'bold' }}>{toLocaleString(parseFloat(this.state.csf_mapped_data?.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.total ? curr.total : 0), 0)) + parseFloat(this.state.csf_manual_data?.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.total ? curr.total : 0), 0)))}</td>
                        </tr>
                        <tr>
                          <th colSpan={9}>Procurement Fee</th>
                        </tr>

                        {this.state.cpf_mapped_data?.map((e, idx) => (
                          <tr key={e.contract_no}>
                            <td nowrap="true">{e.vendor_name}</td>
                            <td nowrap="true">{e.contract_no}</td>
                            <td nowrap="true">{e.quality}</td>
                            <td nowrap="true">{e.purchase_type}</td>
                            <td nowrap="true">{e.price_type}</td>
                            <td nowrap="true" className='text-right'>{toLocaleString(e.quantity)}</td>
                            <td nowrap="true" className='text-right'>{toLocaleString(e.price_type === "Lumpsum" ? e.price_lumpsum : e.price_pmt)}</td>
                            <td nowrap="true">{e.currency}</td>
                            <td className='text-right'>{toLocaleString(e.total)}</td>
                          </tr>
                        ))}

                        {this.state.cpf_manual_data?.map((e, idx) => (
                          <tr key={idx}>
                            <td nowrap="true">{e.vendor_name}</td>
                            <td className='text-center'>-</td>
                            <td>{e.quality}</td>
                            <td className='text-center'>-</td>
                            <td nowrap="true">{e.price_type}</td>
                            <td nowrap="true" className='text-right'>{toLocaleString(e.quantity)}</td>
                            <td nowrap="true" className='text-right'>{toLocaleString(e.price)}</td>
                            <td nowrap="true">{e.currency} </td>
                            <td nowrap="true" className='text-right'>{toLocaleString(e.total)}</td>
                          </tr>
                        ))}
                        <tr>
                          <td colSpan={5}></td>
                          <td className='text-right' style={{ fontWeight: 'bold' }}>{toLocaleString(parseFloat(this.state.cpf_manual_data?.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.quantity ? curr.quantity : 0), 0)) + parseFloat(this.state.cpf_mapped_data?.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.quantity ? curr.quantity : 0), 0)))}</td>
                          <td colSpan={2}></td>
                          <td className='text-right' style={{ fontWeight: 'bold' }}>{toLocaleString(parseFloat(this.state.cpf_manual_data?.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.total ? curr.total : 0), 0)) + parseFloat(this.state.cpf_mapped_data?.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.total ? curr.total : 0), 0)))}</td>
                        </tr>

                        <tr>
                          <th >Barge Cost</th>
                        </tr>
                        {this.state.barge_cost_data?.map((e, idx) => (
                          <tr key={e.vendor_id}>
                            <td nowrap="true">{e.vendor_name} </td>
                            <td nowrap="true">{e.jetty}</td>
                            <td nowrap="true" colSpan={2} className='text-center'>-</td>
                            <td nowrap="true">{e.price_type} </td>
                            <td nowrap="true" className='text-right'>{toLocaleString(e.quantity)}</td>
                            <td nowrap="true" className='text-right'>{toLocaleString(e.price)}</td>
                            <td nowrap="true">{e.currency}</td>
                            <td nowrap="true" className='text-right'>{toLocaleString(e.total)}</td>

                          </tr>
                        ))}

                        <tr>
                          <td colSpan={5}></td>
                          <td style={{ fontWeight: 'bold' }} className='text-right'>{toLocaleString(this.state.barge_cost_data?.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.quantity ? curr.quantity : 0), 0))}</td>
                          <td colSpan={2}></td>
                          <td style={{ fontWeight: 'bold' }} className='text-right'>{toLocaleString(this.state.barge_cost_data?.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.total ? curr.total : 0), 0))}</td>
                        </tr>

                        <tr>
                          <th colSpan={9}>Misc Expenses</th>
                        </tr>
                        {this.state.misc_expenses_data?.map((e, idx) => (
                          <tr key={idx}>
                            <td>{e.costing_type}</td>
                            <td colSpan={3} className='text-center'>-</td>
                            <td>{e.price_type} </td>
                            <td className='text-right'>{toLocaleString(e.quantity)}</td>
                            <td className='text-right'>{toLocaleString(e.price)}</td>
                            <td>{e.currency}</td>
                            <td className='text-right'>{toLocaleString(e.total)}</td>
                          </tr>
                        ))}
                        <tr>
                          <td colSpan={5}></td>
                          <td style={{ fontWeight: 'bold' }} className='text-right'>{toLocaleString(this.state.misc_expenses_data?.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.quantity ? curr.quantity : 0), 0))}</td>
                          <td colSpan={2}></td>
                          <td style={{ fontWeight: 'bold' }} className='text-right'>{toLocaleString(this.state.misc_expenses_data?.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.total ? curr.total : 0), 0))}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className='row'>
                    <table className='table table-sm table-bordered'>
                      <thead className='table-header'>
                        <tr>
                          <th className='text-right'>Total Cost of Purchase</th>
                          <th className='text-right'>Total Cost of Purchase / MT</th>
                          <th className='text-right'>Total Cost of Sales</th>
                          <th className='text-right'>Total Cost of Sales / MT</th>
                          <th className='text-right'>Realization / MT</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td className='text-right' style={{ fontWeight: 'bold' }}>{toLocaleString(this.state.total_cost_of_purchase)}</td>
                          <td className='text-right' style={{ fontWeight: 'bold' }}>{toLocaleString(this.state.total_cost_of_purchase_pmt)}</td>
                          <td className='text-right' style={{ fontWeight: 'bold' }}>{toLocaleString(this.state.total_cost_of_sales)}</td>
                          <td className='text-right' style={{ fontWeight: 'bold' }}>{toLocaleString(this.state.total_cost_of_sales_pmt)}</td>
                          <td className='text-right' style={{ fontWeight: 'bold' }}>{toLocaleString(this.state.realization_pmt)}</td>
                        </tr>
                      </tbody>

                    </table>
                  </div>
                </div>
              </div>
              <div className="row bottom_buttons_section">
                <button
                  type="button"
                  className="btn previous_button"
                  name="submit"
                  onClick={() => (window.location.href = "/budget-costing")}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn next_button"
                  name="Back"
                  onClick={() => window.location.href = '/update-budget-costing/' + btoa(budgetID)}
                >
                  Edit
                </button>
              </div>
            </form>
          }

        </div>
      </div>
    );
  }
}
