import React, { useEffect, useState } from 'react'
import config from '../../../config/config';

import {
  Tabs,
  Tab,
  ButtonGroup,
  Button,
  Drawer,
  TextField,
  MenuItem,
  Checkbox,
  withStyles
} from "@material-ui/core";

const CheckBox = withStyles({
  root: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);
export default function QualitySpecs({ open, specs, specification_standard, closeDrawerHandler, saveSpecsHandler }) {

  const [specStandard, setSpecStandard] = useState("ASTM")
  const [specifications, setSpecifications] = useState({});
  const [specActiveTab, setSpecActiveTab] = useState(0);
  const [activeTabIndex, setActiveTab] = useState(0);
  const [activeSubTab, setActiveSubTab] = useState(0);
  const [dropDown] = useState([
    <MenuItem value="0" disabled key={-1}>Basis </MenuItem>,
    <MenuItem value="ARB" key={0}>ARB</MenuItem>,
    <MenuItem value="ADB" key={1}>ADB </MenuItem>,
    <MenuItem value="DB" key={2}> DB</MenuItem>,
    <MenuItem value="DAF" key={3}> DAF</MenuItem>,
  ]);

  const [astmClone, setAstmClone] = useState(false);

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);


  useEffect(() => {
    setSpecifications(specs)
  }, [specs]);

  useEffect(() => {
    if (specification_standard) {
      setSpecStandard(specification_standard)
    }

  }, [specification_standard])

  const handleDuplicateDropdown = (e, idx) => {
    var specsCopy = specifications;
    let handledValue = specsCopy[e.target.name];
    var value = e.target.value;
    if (handledValue === "ARB") {
      specsCopy[e.target.name] = value;
      specsCopy.ARBitem = false;
      setSpecifications(specsCopy);
    } else if (handledValue === "ADB") {
      specsCopy[e.target.name] = value;
      specsCopy.ADBitem = false;
      setSpecifications(specsCopy);
    } else if (handledValue === "DAF") {
      specsCopy[e.target.name] = value;
      specsCopy.DAFitem = false;
      setSpecifications(specsCopy);
    } else if (handledValue === "DB") {
      specsCopy[e.target.name] = value;
      specsCopy.DBitem = false;
      setSpecifications(specsCopy);
    } else if (handledValue === "NAR") {
      specsCopy[e.target.name] = value;
      specsCopy.NARitem = false;
      setSpecifications(specsCopy);
    }

    if (e.target.value === "ARB") {
      specsCopy[e.target.name] = value;
      specsCopy.ARBitem = true;
      setSpecifications(specsCopy);
    }
    if (e.target.value === "ADB") {
      specsCopy[e.target.name] = value;
      specsCopy.ADBitem = true;
      setSpecifications(specsCopy);
    }
    if (e.target.value === "DAF") {
      specsCopy[e.target.name] = value;
      specsCopy.DAFitem = true;
      setSpecifications(specsCopy);
    }
    if (e.target.value === "DB") {
      specsCopy[e.target.name] = value;
      specsCopy.DBitem = true;
      setSpecifications(specsCopy);
    }
    if (e.target.value === "NAR") {
      specsCopy[e.target.name] = value;
      specsCopy.NARitem = true;
      setSpecifications(specsCopy);
    }
    forceUpdate();

  };

  const Iso_handleDuplicateDropdown = (e, idx) => {
    var specsCopy = specifications;
    let handledValue = specsCopy[e.target.name];
    var value = e.target.value;
    if (handledValue === "ARB") {
      specsCopy[e.target.name] = value;
      specsCopy.Iso_ARBitem = false;
      setSpecifications(specsCopy);
    } else if (handledValue === "ADB") {
      specsCopy[e.target.name] = value;
      specsCopy.Iso_ADBitem = false;
      setSpecifications(specsCopy);
    } else if (handledValue === "DAF") {
      specsCopy[e.target.name] = value;
      specsCopy.Iso_DAFitem = false;
      setSpecifications(specsCopy);
    } else if (handledValue === "DB") {
      specsCopy[e.target.name] = value;
      specsCopy.Iso_DBitem = false;
      setSpecifications(specsCopy);
    } else if (handledValue === "NAR") {
      specsCopy[e.target.name] = value;
      specsCopy.Iso_NARitem = false;
      setSpecifications(specsCopy);
    }

    if (e.target.value === "ARB") {
      specsCopy[e.target.name] = value;
      specsCopy.Iso_ARBitem = true;
      setSpecifications(specsCopy);
    }
    if (e.target.value === "ADB") {
      specsCopy[e.target.name] = value;
      specsCopy.Iso_ADBitem = true;
      setSpecifications(specsCopy);
    }
    if (e.target.value === "DAF") {
      specsCopy[e.target.name] = value;
      specsCopy.Iso_DAFitem = true;
      setSpecifications(specsCopy);
    }
    if (e.target.value === "DB") {
      specsCopy[e.target.name] = value;
      specsCopy.Iso_DBitem = true;
      setSpecifications(specsCopy);
    }
    if (e.target.value === "NAR") {
      specsCopy[e.target.name] = value;
      specsCopy.Iso_NARitem = true;
      setSpecifications(specsCopy);
    }
    forceUpdate();

  };

  const handleText = (e) => {
    var specsCopy = specifications;
    specsCopy[e.target.name] = e.target.value;
    setSpecifications(specsCopy);
    forceUpdate();
  }

  const astmToIsoClone = (e, i) => {
    let stateCopy = specifications;
    //Specification(ISO)
    stateCopy.quality_calorific_value_basis_iso =
      stateCopy.quality_calorific_value_basis;
    stateCopy.quality_calorific_value_typical_iso =
      stateCopy.quality_calorific_value_typical;
    stateCopy.quality_calorific_value_rejection_iso =
      stateCopy.quality_calorific_value_rejection;
    stateCopy.quality_calorific_value_bonus_cap_iso =
      stateCopy.quality_calorific_value_bonus_cap;
    stateCopy.quality_calorific_value2_basis_iso =
      stateCopy.quality_calorific_value2_basis;
    stateCopy.quality_calorific_value2_typical_iso =
      stateCopy.quality_calorific_value2_typical;
    stateCopy.quality_calorific_value2_rejection_iso =
      stateCopy.quality_calorific_value2_rejection;
    stateCopy.quality_calorific_value2_bonus_cap_iso =
      stateCopy.quality_calorific_value2_bonus_cap;
    stateCopy.quality_calorific_value3_basis_iso =
      stateCopy.quality_calorific_value3_basis;
    stateCopy.quality_calorific_value3_typical_iso =
      stateCopy.quality_calorific_value3_typical;
    stateCopy.quality_calorific_value3_rejection_iso =
      stateCopy.quality_calorific_value3_rejection;
    stateCopy.quality_calorific_value3_bonus_cap_iso =
      stateCopy.quality_calorific_value3_bonus_cap;
    stateCopy.quality_total_moisture_basis_iso =
      stateCopy.quality_total_moisture_basis;
    stateCopy.quality_total_moisture_typical_iso =
      stateCopy.quality_total_moisture_typical;
    stateCopy.quality_total_moisture_rejection_iso =
      stateCopy.quality_total_moisture_rejection;
    stateCopy.quality_inherent_moisture_basis_iso =
      stateCopy.quality_inherent_moisture_basis;
    stateCopy.quality_inherent_moisture_typical_iso =
      stateCopy.quality_inherent_moisture_typical;
    stateCopy.quality_inherent_moisture_rejection_iso =
      stateCopy.quality_inherent_moisture_rejection;
    stateCopy.quality_ash_basis_iso =
      stateCopy.quality_ash_basis;
    stateCopy.quality_ash_typical_iso =
      stateCopy.quality_ash_typical;
    stateCopy.quality_ash_rejection_iso =
      stateCopy.quality_ash_rejection;
    stateCopy.quality_ash_penality_iso =
      stateCopy.quality_ash_penality;
    stateCopy.quality_volatile_matter_basis_iso =
      stateCopy.quality_volatile_matter_basis;
    stateCopy.quality_volatile_matter_typical_iso =
      stateCopy.quality_volatile_matter_typical;
    stateCopy.quality_volatile_matter_rejection_iso =
      stateCopy.quality_volatile_matter_rejection;
    stateCopy.quality_fixed_carbon_basis_iso =
      stateCopy.quality_fixed_carbon_basis;
    stateCopy.quality_fixed_carbon_typical_iso =
      stateCopy.quality_fixed_carbon_typical;
    stateCopy.quality_fixed_carbon_rejection_iso =
      stateCopy.quality_fixed_carbon_rejection;
    stateCopy.quality_fuel_ratio_basis_iso =
      stateCopy.quality_fuel_ratio_basis;
    stateCopy.quality_fuel_ratio_typical_iso =
      stateCopy.quality_fuel_ratio_typical;
    stateCopy.quality_fuel_ratio_rejection_iso =
      stateCopy.quality_fuel_ratio_rejection;
    stateCopy.quality_total_sulphur_basis_iso =
      stateCopy.quality_total_sulphur_basis;
    stateCopy.quality_total_sulphur_typical_iso =
      stateCopy.quality_total_sulphur_typical;
    stateCopy.quality_total_sulphur_rejection_iso =
      stateCopy.quality_total_sulphur_rejection;
    stateCopy.quality_total_sulphur_penality_iso =
      stateCopy.quality_total_sulphur_penality;
    stateCopy.quality_hgi_typical_iso =
      stateCopy.quality_hgi_typical;
    stateCopy.quality_hgi_rejection_iso =
      stateCopy.quality_hgi_rejection;

    stateCopy.ultimate_carbon_basis_iso =
      stateCopy.ultimate_carbon_basis;
    stateCopy.ultimate_carbon_typical_iso =
      stateCopy.ultimate_carbon_typical;
    stateCopy.ultimate_carbon_rejection_iso =
      stateCopy.ultimate_carbon_rejection;
    stateCopy.ultimate_hydrogen_basis_iso =
      stateCopy.ultimate_hydrogen_basis;
    stateCopy.ultimate_hydrogen_typical_iso =
      stateCopy.ultimate_hydrogen_typical;
    stateCopy.ultimate_hydrogen_rejection_iso =
      stateCopy.ultimate_hydrogen_rejection;
    stateCopy.ultimate_nitrogen_basis_iso =
      stateCopy.ultimate_nitrogen_basis;
    stateCopy.ultimate_nitrogen_typical_iso =
      stateCopy.ultimate_nitrogen_typical;
    stateCopy.ultimate_nitrogen_rejection_iso =
      stateCopy.ultimate_nitrogen_rejection;

    stateCopy.size_dist1_name_iso =
      stateCopy.size_dist1_name;
    stateCopy.size_dist1_typical_iso =
      stateCopy.size_dist1_typical;
    stateCopy.size_dist1_rejection_iso =
      stateCopy.size_dist1_rejection;
    stateCopy.size_dist2_name_iso =
      stateCopy.size_dist2_name;
    stateCopy.size_dist2_typical_iso =
      stateCopy.size_dist2_typical;
    stateCopy.size_dist2_rejection_iso =
      stateCopy.size_dist2_rejection;
    stateCopy.size_dist3_name_iso =
      stateCopy.size_dist3_name;
    stateCopy.size_dist3_typical_iso =
      stateCopy.size_dist3_typical;
    stateCopy.size_dist3_rejection_iso =
      stateCopy.size_dist3_rejection;
    stateCopy.size_dist4_name_iso =
      stateCopy.size_dist4_name;
    stateCopy.size_dist4_typical_iso =
      stateCopy.size_dist4_typical;
    stateCopy.size_dist4_rejection_iso =
      stateCopy.size_dist4_rejection;
    stateCopy.size_dist5_name_iso =
      stateCopy.size_dist5_name;
    stateCopy.size_dist5_typical_iso =
      stateCopy.size_dist5_typical;
    stateCopy.size_dist5_rejection_iso =
      stateCopy.size_dist5_rejection;

    stateCopy.ash_reducing_initial_typical_iso =
      stateCopy.ash_reducing_initial_typical;
    stateCopy.ash_reducing_initial_rejection_iso =
      stateCopy.ash_reducing_initial_rejection;
    stateCopy.ash_reducing_softening_typical_iso =
      stateCopy.ash_reducing_softening_typical;
    stateCopy.ash_reducing_softening_rejection_iso =
      stateCopy.ash_reducing_softening_rejection;
    stateCopy.ash_reducing_hemispherical_typical_iso =
      stateCopy.ash_reducing_hemispherical_typical;
    stateCopy.ash_reducing_hemispherical_rejection_iso =
      stateCopy.ash_reducing_hemispherical_rejection;
    stateCopy.ash_reducing_flow_typical_iso =
      stateCopy.ash_reducing_flow_typical;
    stateCopy.ash_reducing_flow_rejection_iso =
      stateCopy.ash_reducing_flow_rejection;
    stateCopy.ash_oxidizing_initial_typical_iso =
      stateCopy.ash_oxidizing_initial_typical;
    stateCopy.ash_oxidizing_initial_rejection_iso =
      stateCopy.ash_oxidizing_initial_rejection;
    stateCopy.ash_oxidizing_softening_typical_iso =
      stateCopy.ash_oxidizing_softening_typical;
    stateCopy.ash_oxidizing_softening_rejection_iso =
      stateCopy.ash_oxidizing_softening_rejection;
    stateCopy.ash_oxidizing_hemispherical_typical_iso =
      stateCopy.ash_oxidizing_hemispherical_typical;
    stateCopy.ash_oxidizing_hemispherical_rejection_iso =
      stateCopy.ash_oxidizing_hemispherical_rejection;
    stateCopy.ash_oxidizing_flow_typical_iso =
      stateCopy.ash_oxidizing_flow_typical;
    stateCopy.ash_oxidizing_flow_rejection_iso =
      stateCopy.ash_oxidizing_flow_rejection;

    stateCopy.ash_sio2_basis_iso =
      stateCopy.ash_sio2_basis;
    stateCopy.ash_sio2_typical_iso =
      stateCopy.ash_sio2_typical;
    stateCopy.ash_sio2_rejection_iso =
      stateCopy.ash_sio2_rejection;
    stateCopy.ash_ai203_basis_iso =
      stateCopy.ash_ai203_basis;
    stateCopy.ash_ai203_typical_iso =
      stateCopy.ash_ai203_typical;
    stateCopy.ash_ai203_rejection_iso =
      stateCopy.ash_ai203_rejection;
    stateCopy.ash_fe203_basis_iso =
      stateCopy.ash_fe203_basis;
    stateCopy.ash_fe203_typical_iso =
      stateCopy.ash_fe203_typical;
    stateCopy.ash_fe203_rejection_iso =
      stateCopy.ash_fe203_rejection;
    stateCopy.ash_cao_basis_iso =
      stateCopy.ash_cao_basis;
    stateCopy.ash_cao_typical_iso =
      stateCopy.ash_cao_typical;
    stateCopy.ash_cao_rejection_iso =
      stateCopy.ash_cao_rejection;
    stateCopy.ash_mgo_basis_iso =
      stateCopy.ash_mgo_basis;
    stateCopy.ash_mgo_typical_iso =
      stateCopy.ash_mgo_typical;
    stateCopy.ash_mgo_rejection_iso =
      stateCopy.ash_mgo_rejection;
    stateCopy.ash_na2o_basis_iso =
      stateCopy.ash_na2o_basis;
    stateCopy.ash_na2o_typical_iso =
      stateCopy.ash_na2o_typical;
    stateCopy.ash_na2o_rejection_iso =
      stateCopy.ash_na2o_rejection;
    stateCopy.ash_k2o_basis_iso =
      stateCopy.ash_k2o_basis;
    stateCopy.ash_k2o_typical_iso =
      stateCopy.ash_k2o_typical;
    stateCopy.ash_k2o_rejection_iso =
      stateCopy.ash_k2o_rejection;
    stateCopy.ash_tio2_basis_iso =
      stateCopy.ash_tio2_basis;
    stateCopy.ash_tio2_typical_iso =
      stateCopy.ash_tio2_typical;
    stateCopy.ash_tio2_rejection_iso =
      stateCopy.ash_tio2_rejection;
    stateCopy.ash_mn3o4_basis_iso =
      stateCopy.ash_mn3o4_basis;
    stateCopy.ash_mn3o4_typical_iso =
      stateCopy.ash_mn3o4_typical;
    stateCopy.ash_mn3o4_rejection_iso =
      stateCopy.ash_mn3o4_rejection;
    stateCopy.ash_so3_basis_iso =
      stateCopy.ash_so3_basis;
    stateCopy.ash_so3_typical_iso =
      stateCopy.ash_so3_typical;
    stateCopy.ash_so3_rejection_iso =
      stateCopy.ash_so3_rejection;
    stateCopy.ash_p2o5_basis_iso =
      stateCopy.ash_p2o5_basis;
    stateCopy.ash_p2o5_typical_iso =
      stateCopy.ash_p2o5_typical;
    stateCopy.ash_p2o5_rejection_iso =
      stateCopy.ash_p2o5_rejection;

    stateCopy.trace_arsenic_basis_iso =
      stateCopy.trace_arsenic_basis;
    stateCopy.trace_arsenic_typical_iso =
      stateCopy.trace_arsenic_typical;
    stateCopy.trace_arsenic_rejection_iso =
      stateCopy.trace_arsenic_rejection;
    stateCopy.trace_mercury_basis_iso =
      stateCopy.trace_mercury_basis;
    stateCopy.trace_mercury_typical_iso =
      stateCopy.trace_mercury_typical;
    stateCopy.trace_mercury_rejection_iso =
      stateCopy.trace_mercury_rejection;
    stateCopy.trace_selenium_basis_iso =
      stateCopy.trace_selenium_basis;
    stateCopy.trace_selenium_typical_iso =
      stateCopy.trace_selenium_typical;
    stateCopy.trace_selenium_rejection_iso =
      stateCopy.trace_selenium_rejection;
    stateCopy.trace_boron_basis_iso =
      stateCopy.trace_boron_basis;
    stateCopy.trace_boron_typical_iso =
      stateCopy.trace_boron_typical;
    stateCopy.trace_boron_rejection_iso =
      stateCopy.trace_boron_rejection;

    stateCopy.minor_ba_basis_iso =
      stateCopy.minor_ba_basis;
    stateCopy.minor_ba_typical_iso =
      stateCopy.minor_ba_typical;
    stateCopy.minor_ba_rejection_iso =
      stateCopy.minor_ba_rejection;
    stateCopy.minor_phosphorous_basis_iso =
      stateCopy.minor_phosphorous_basis;
    stateCopy.minor_phosphorous_typical_iso =
      stateCopy.minor_phosphorous_typical;
    stateCopy.minor_phosphorous_rejection_iso =
      stateCopy.minor_phosphorous_rejection;

    stateCopy.other_coal_bulk_density_typical_iso =
      stateCopy.other_coal_bulk_density_typical;
    stateCopy.other_stowage_factor_typical_iso =
      stateCopy.other_stowage_factor_typical;
    stateCopy.other_imsbc_group_typical_iso =
      stateCopy.other_imsbc_group_typical;
    stateCopy.other_GandY_index_typical_iso =
      stateCopy.other_GandY_index_typical;
    stateCopy.other_transmittance_value_typical_iso =
      stateCopy.other_transmittance_value_typical;
    stateCopy.other_max_lump_size_typical_iso =
      stateCopy.other_max_lump_size_typical;
    stateCopy.other_angle_maximum_typical_iso =
      stateCopy.other_angle_maximum_typical;
    stateCopy.other_angle_minimum_typical_iso =
      stateCopy.other_angle_minimum_typical;

    setAstmClone(!astmClone);
    setSpecifications(stateCopy)
    forceUpdate();
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      variant="temporary"
      elevation="20"
      onClose={() => closeDrawerHandler(false)}
    >
      <div className="container bg-white p-0" style={{ minHeight: "100vh", width: 800 }}>
        <div className="clearfix content-header">
          <div className="row border-bottom">
            <div className="col-sm text-left pl-0">
              <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                {`Specification Standard`}<span style={{ color: "red" }}>*</span>
                <ButtonGroup
                  size="small"
                  variant="contained"
                  aria-label="outlined primary button group"
                >
                  <Button
                    onClick={() => setSpecStandard("ASTM")}
                    style={{
                      textTransform: "capitalize",
                      backgroundColor: specStandard === "ASTM" ? config.themeColor : "#eae4e2",
                      color: specStandard !== "ASTM" ? "#483b34b3" : "white",
                    }}
                  >
                    ASTM
                  </Button>
                  <Button
                    onClick={() => setSpecStandard("ISO")}

                    style={{
                      textTransform: "capitalize",
                      backgroundColor: specStandard === "ISO" ? config.themeColor : "#eae4e2",
                      color: specStandard !== "ISO" ? "#483b34b3" : "white",
                    }}
                  >
                    ISO
                  </Button>
                  <Button
                    onClick={() => setSpecStandard("ASTM/ISO")}

                    style={{
                      textTransform: "capitalize",
                      backgroundColor: specStandard === "ASTM/ISO" ? config.themeColor : "#eae4e2",
                      color: specStandard !== "ASTM/ISO" ? "#483b34b3" : "white",
                    }}
                  >
                    ASTM/ISO
                  </Button>
                </ButtonGroup>
              </h4>
            </div>
          </div>
        </div>
        <div className='drawer-section'>

          <div className='card'>
            {specStandard === "ASTM/ISO" && (

              <div className='row'>
                <Tabs
                  value={specActiveTab}
                  onChange={(e, value) => {
                    setSpecActiveTab(value)
                  }}
                  indicatorColor="primary"
                  textColor="primary"
                  // variant="scrollable"
                  // scrollButtons="auto"
                  // aria-label="scrollable auto tabs example"
                  style={{
                    backgroundColor: "#ffffff",
                    color: "#6c7293",
                    padding: 0,
                  }}
                  TabIndicatorProps={{
                    style: { background: config.themeColor },
                  }}
                >
                  <Tab
                    label={
                      <h4
                        style={{
                          margin: "0px",
                          color: specActiveTab === 0 ? config.themeColor : "#6c7293",
                          fontSize: "14px", border: "0px",
                        }}
                      >
                        ASTM
                      </h4>
                    }
                    value={0}
                    style={{
                      fontFamily: "Poppins", textTransform: "capitalize", color: "#6c7293",
                    }}
                  />
                  <Tab
                    label={
                      <h4
                        style={{
                          margin: "0px", color: specActiveTab === 1 ? config.themeColor : "#6c7293", fontSize: "14px",
                          border: "0px",
                        }}
                      >
                        ISO
                      </h4>
                    }
                    value={1}
                    style={{
                      fontFamily: "Poppins", textTransform: "capitalize", color: "#6c7293",
                    }}
                  />
                </Tabs>
              </div>
            )}

            {specStandard === "ASTM/ISO" &&
              specActiveTab ===
              1 && (
                <span style={{ flexDirection: "row" }}>
                  <CheckBox
                    color={config.themeColor}
                    checked={astmClone}
                    onChange={(e) => astmToIsoClone()}
                  />
                  <span className="form_section_label">
                    Copy from ASTM
                  </span>
                </span>
              )}

            <div className="row">
              <div className="col-lg p-0 border">
                <h6
                  style={{
                    backgroundColor: "#dddd",
                    marginBottom: 0,
                  }}
                >
                  <Tabs
                    value={activeTabIndex}
                    onChange={(e, value) => setActiveTab(value)}
                    indicatorColor="primary"
                    textColor="primary"
                    // variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    style={{
                      backgroundColor: "#ffffff",
                      color: "#6c7293",
                      padding: 0,
                    }}
                    TabIndicatorProps={{
                      style: {
                        background: config.themeColor,
                      },
                    }}
                  >
                    <Tab
                      label={
                        <h4
                          style={{
                            margin: "0px",
                            color: activeTabIndex === 0 ? config.themeColor : "#6c7293",
                            fontSize: "14px",
                            border: "0px",
                          }}
                        >
                          Analysis
                        </h4>
                      }
                      value={0}
                      style={{
                        fontFamily: "Poppins",
                        textTransform: "capitalize",
                        color: "#6c7293",
                      }}
                    />
                    <Tab
                      label={
                        <h4
                          style={{
                            margin: "0px",
                            color: activeTabIndex === 1 ? config.themeColor : "#6c7293",
                            fontSize: "14px",
                            border: "0px",
                          }}
                        >
                          Size Distribution
                        </h4>
                      }
                      value={1}
                      style={{
                        fontFamily: "Poppins",
                        textTransform: "capitalize",
                        color: "#6c7293",
                      }}
                    />
                    <Tab
                      label={
                        <h4
                          style={{
                            margin: "0px",
                            color: activeTabIndex === 2 ? config.themeColor : "#6c7293",
                            fontSize: "14px",
                            border: "0px",
                          }}
                        >
                          Ash Fusion
                        </h4>
                      }
                      value={2}
                      style={{
                        fontFamily: "Poppins",
                        textTransform: "capitalize",
                        color: "#6c7293",
                      }}
                    />
                    <Tab
                      label={
                        <h4
                          style={{
                            margin: "0px",
                            color:
                              activeTabIndex === 3
                                ? config.themeColor
                                : "#6c7293",
                            fontSize: "14px",
                            border: "0px",
                          }}
                        >
                          Elements
                        </h4>
                      }
                      value={3}
                      style={{
                        fontFamily: "Poppins",
                        textTransform: "capitalize",
                        color: "#6c7293",
                      }}
                    />
                    <Tab
                      label={
                        <h4
                          style={{
                            margin: "0px",
                            color:
                              activeTabIndex === 4 ? config.themeColor : "#6c7293",
                            fontSize: "14px",
                            border: "0px",
                          }}
                        >
                          Other
                        </h4>
                      }
                      value={4}
                      style={{
                        fontFamily: "Poppins",
                        textTransform: "capitalize",
                        color: "#6c7293",
                      }}
                    />
                  </Tabs>
                </h6>
              </div>
            </div>

            <div className="col-lg-12 p-0 pt-2 pb-2 border">
              {activeTabIndex === 0 &&
                <>
                  <div className="row pl-3">
                    <Button
                      className="ml-5 mr-5 specs_button"
                      onClick={(e, value) => setActiveSubTab(0)}
                      style={{
                        textTransform: "capitalize",
                        borderRadius: 35,
                        height: 30,
                        color: activeSubTab === 0 ? config.themeColor : "#483b34b3",
                        backgroundColor: activeSubTab === 0 ? "#fff7f2" : "#fff",
                        fontSize: "14px",
                        border: "0px",
                      }}
                    >
                      Typical
                    </Button>
                    <Button
                      className="mr-5 specs_button"
                      onClick={(e, value) => setActiveSubTab(1)}
                      style={{
                        textTransform: "capitalize",
                        borderRadius: 35,
                        height: 30,
                        color:
                          activeSubTab === 1 ? config.themeColor : "#483b34b3",
                        backgroundColor: activeSubTab === 1 ? "#fff7f2" : "#fff",
                        fontSize: "14px",
                        border: "0px",
                      }}
                    >
                      Ultimate
                    </Button>
                    <Button
                      className="specs_button"
                      onClick={(e, value) => setActiveSubTab(2)}
                      style={{
                        textTransform: "capitalize",
                        borderRadius: 35,
                        height: 30,
                        color:
                          activeSubTab === 2 ? config.themeColor : "#483b34b3",
                        backgroundColor:
                          activeSubTab === 2 ? "#fff7f2" : "#fff",
                        fontSize: "14px",
                        border: "0px",
                      }}
                    >
                      Ash
                    </Button>
                  </div>
                </>
              }

              {(activeTabIndex === 0 && activeSubTab === 0) &&
                <>
                  <div className="row p-2">
                    <label className="form_section_label mb-0 mt-2">
                      Gross Calorific Value (Kcal/kg)
                    </label>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="quality_calorific_value_basis_iso"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={specifications
                              .quality_calorific_value_basis_iso
                            }
                            onChange={(e) => Iso_handleDuplicateDropdown(e)
                            }
                            select
                          >
                            <MenuItem value="0" disabled key={-1} >Basis</MenuItem>
                            <MenuItem value="ARB" key={0} disabled={specifications.Iso_ARBitem}> ARB</MenuItem>
                            <MenuItem value="ADB" key={1} disabled={specifications.Iso_ADBitem}>ADB</MenuItem>
                            <MenuItem value="DB" key={2} disabled={specifications.Iso_DBitem}>DB</MenuItem>
                            <MenuItem value="DAF" key={3} disabled={specifications.Iso_DAFitem}>DAF </MenuItem>
                            <MenuItem value="NAR" disabled={specifications.Iso_NARitem} >NAR</MenuItem>
                          </TextField>
                          :
                          <TextField
                            name="quality_calorific_value_basis"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={specifications
                              .quality_calorific_value_basis
                            }
                            onChange={(e) => handleDuplicateDropdown(e)
                            }
                            select
                          >
                            <MenuItem value="0" disabled key={-1} >Basis</MenuItem>
                            <MenuItem value="ARB" key={0} disabled={specifications.ARBitem}> ARB</MenuItem>
                            <MenuItem value="ADB" key={1} disabled={specifications.ADBitem}>ADB</MenuItem>
                            <MenuItem value="DB" key={2} disabled={specifications.DBitem}>DB</MenuItem>
                            <MenuItem value="DAF" key={3} disabled={specifications.DAFitem}>DAF </MenuItem>
                            <MenuItem value="NAR" disabled={specifications.NARitem} >NAR</MenuItem>
                          </TextField>
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?
                          <TextField
                            name="quality_calorific_value_typical_iso"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            type='number'
                            value={specifications.quality_calorific_value_typical_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}

                          />
                          :
                          <TextField
                            name="quality_calorific_value_typical"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            type='number'
                            value={specifications.quality_calorific_value_typical}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="quality_calorific_value_rejection_iso"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            type='number'
                            value={specifications.quality_calorific_value_rejection_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="quality_calorific_value_rejection"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            type='number'
                            value={specifications.quality_calorific_value_rejection}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="quality_calorific_value_bonus_cap_iso"
                            margin="dense"
                            variant="outlined"
                            label="Bonus Cap"
                            type='number'
                            value={specifications.quality_calorific_value_bonus_cap_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="quality_calorific_value_bonus_cap"
                            margin="dense"
                            variant="outlined"
                            label="Bonus Cap"
                            type='number'
                            value={specifications.quality_calorific_value_bonus_cap}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?
                          <TextField
                            name="quality_calorific_value_penality_cap_iso"
                            margin="dense"
                            variant="outlined"
                            label="Penality Cap"
                            type='number'
                            value={specifications.quality_calorific_value_penality_cap_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="quality_calorific_value_penality_cap"
                            margin="dense"
                            variant="outlined"
                            label="Penality Cap"
                            type='number'
                            value={specifications.quality_calorific_value_penality_cap}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>
                    </div>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="quality_calorific_value2_basis_iso"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={specifications
                              .quality_calorific_value2_basis_iso
                            }
                            onChange={(e) => Iso_handleDuplicateDropdown(e)
                            }
                            select
                          >
                            <MenuItem value="0" disabled key={-1} >Basis</MenuItem>
                            <MenuItem value="ARB" key={0} disabled={specifications.Iso_ARBitem}> ARB</MenuItem>
                            <MenuItem value="ADB" key={1} disabled={specifications.Iso_ADBitem}>ADB</MenuItem>
                            <MenuItem value="DB" key={2} disabled={specifications.Iso_DBitem}>DB</MenuItem>
                            <MenuItem value="DAF" key={3} disabled={specifications.Iso_DAFitem}>DAF </MenuItem>
                            <MenuItem value="NAR" disabled={specifications.Iso_NARitem} >NAR</MenuItem>
                          </TextField>
                          :
                          <TextField
                            name="quality_calorific_value2_basis"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={specifications
                              .quality_calorific_value2_basis
                            }
                            onChange={(e) => handleDuplicateDropdown(e)}
                            select
                          >
                            <MenuItem value="0" disabled key={-1} >Basis</MenuItem>
                            <MenuItem value="ARB" key={0} disabled={specifications.ARBitem}> ARB</MenuItem>
                            <MenuItem value="ADB" key={1} disabled={specifications.ADBitem}>ADB</MenuItem>
                            <MenuItem value="DB" key={2} disabled={specifications.DBitem}>DB</MenuItem>
                            <MenuItem value="DAF" key={3} disabled={specifications.DAFitem}>DAF </MenuItem>
                            <MenuItem value="NAR" disabled={specifications.NARitem} >NAR</MenuItem>
                          </TextField>
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?
                          <TextField
                            name="quality_calorific_value2_typical_iso"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            type='number'
                            value={specifications.quality_calorific_value2_typical_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}

                          />
                          :
                          <TextField
                            name="quality_calorific_value2_typical"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            type='number'
                            value={specifications.quality_calorific_value2_typical}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="quality_calorific_value2_rejection_iso"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            type='number'
                            value={specifications.quality_calorific_value2_rejection_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="quality_calorific_value2_rejection"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            type='number'
                            value={specifications.quality_calorific_value2_rejection}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="quality_calorific_value2_bonus_cap_iso"
                            margin="dense"
                            variant="outlined"
                            label="Bonus Cap"
                            type='number'
                            value={specifications.quality_calorific_value2_bonus_cap_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="quality_calorific_value2_bonus_cap"
                            margin="dense"
                            variant="outlined"
                            label="Bonus Cap"
                            type='number'
                            value={specifications.quality_calorific_value2_bonus_cap}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?
                          <TextField
                            name="quality_calorific_value2_penality_cap_iso"
                            margin="dense"
                            variant="outlined"
                            label="Penality Cap"
                            type='number'
                            value={specifications.quality_calorific_value2_penality_cap_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="quality_calorific_value2_penality_cap"
                            margin="dense"
                            variant="outlined"
                            label="Penality Cap"
                            type='number'
                            value={specifications.quality_calorific_value2_penality_cap}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>
                    </div>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="quality_calorific_value3_basis_iso"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={specifications
                              .quality_calorific_value3_basis_iso
                            }
                            onChange={(e) => Iso_handleDuplicateDropdown(e)
                            }
                            select
                          >
                            <MenuItem value="0" disabled key={-1} >Basis</MenuItem>
                            <MenuItem value="ARB" key={0} disabled={specifications.Iso_ARBitem}> ARB</MenuItem>
                            <MenuItem value="ADB" key={1} disabled={specifications.Iso_ADBitem}>ADB</MenuItem>
                            <MenuItem value="DB" key={2} disabled={specifications.Iso_DBitem}>DB</MenuItem>
                            <MenuItem value="DAF" key={3} disabled={specifications.Iso_DAFitem}>DAF </MenuItem>
                            <MenuItem value="NAR" disabled={specifications.Iso_NARitem} >NAR</MenuItem>
                          </TextField>
                          :
                          <TextField
                            name="quality_calorific_value3_basis"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={specifications
                              .quality_calorific_value3_basis
                            }
                            onChange={(e) => handleDuplicateDropdown(e)}
                            select
                          >
                            <MenuItem value="0" disabled key={-1} >Basis</MenuItem>
                            <MenuItem value="ARB" key={0} disabled={specifications.ARBitem}> ARB</MenuItem>
                            <MenuItem value="ADB" key={1} disabled={specifications.ADBitem}>ADB</MenuItem>
                            <MenuItem value="DB" key={2} disabled={specifications.DBitem}>DB</MenuItem>
                            <MenuItem value="DAF" key={3} disabled={specifications.DAFitem}>DAF </MenuItem>
                            <MenuItem value="NAR" disabled={specifications.NARitem} >NAR</MenuItem>
                          </TextField>
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?
                          <TextField
                            name="quality_calorific_value3_typical_iso"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            type='number'
                            value={specifications.quality_calorific_value3_typical_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}

                          />
                          :
                          <TextField
                            name="quality_calorific_value3_typical"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            type='number'
                            value={specifications.quality_calorific_value3_typical}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="quality_calorific_value3_rejection_iso"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            type='number'
                            value={specifications.quality_calorific_value3_rejection_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="quality_calorific_value3_rejection"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            type='number'
                            value={specifications.quality_calorific_value3_rejection}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="quality_calorific_value3_bonus_cap_iso"
                            margin="dense"
                            variant="outlined"
                            label="Bonus Cap"
                            type='number'
                            value={specifications.quality_calorific_value3_bonus_cap_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="quality_calorific_value3_bonus_cap"
                            margin="dense"
                            variant="outlined"
                            label="Bonus Cap"
                            type='number'
                            value={specifications.quality_calorific_value3_bonus_cap}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?
                          <TextField
                            name="quality_calorific_value3_penality_cap_iso"
                            margin="dense"
                            variant="outlined"
                            label="Penality Cap"
                            type='number'
                            value={specifications.quality_calorific_value3_penality_cap_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="quality_calorific_value3_penality_cap"
                            margin="dense"
                            variant="outlined"
                            label="Penality Cap"
                            type='number'
                            value={specifications.quality_calorific_value3_penality_cap}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>
                    </div>


                    <label className="form_section_label mb-0 mt-2">
                      Total Moisture (%)
                    </label>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="quality_total_moisture_basis_iso"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={specifications.quality_total_moisture_basis_iso}
                            onChange={(e) => handleText(e)}
                            select
                          >
                            <MenuItem value="ARB" key={0}> ARB</MenuItem>
                          </TextField>
                          :
                          <TextField
                            name="quality_total_moisture_basis"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={specifications.quality_total_moisture_basis}
                            onChange={(e) => handleText(e)}
                            select
                          >
                            <MenuItem value="ARB" key={0}> ARB</MenuItem>
                          </TextField>
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="quality_total_moisture_typical_iso"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            value={specifications.quality_total_moisture_typical_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :

                          <TextField
                            name="quality_total_moisture_typical"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            value={specifications.quality_total_moisture_typical}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="quality_total_moisture_rejection_iso"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            value={specifications.quality_total_moisture_rejection_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :

                          <TextField
                            name="quality_total_moisture_rejection"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            value={specifications.quality_total_moisture_rejection}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>
                    </div>

                    <label className="form_section_label mb-0 mt-2">
                      Inherent Moisture (%)
                    </label>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="quality_inherent_moisture_basis_iso"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={specifications.quality_inherent_moisture_basis_iso}
                            onChange={(e) => handleText(e)}
                            select
                          >
                            <MenuItem value="ADB" key={0}> ADB</MenuItem>
                          </TextField>
                          :
                          <TextField
                            name="quality_inherent_moisture_basis"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={specifications.quality_inherent_moisture_basis}
                            onChange={(e) => handleText(e)}
                            select
                          >
                            <MenuItem value="ADB" key={0}> ADB</MenuItem>
                          </TextField>
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="quality_inherent_moisture_typical_iso"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            value={specifications.quality_inherent_moisture_typical_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="quality_inherent_moisture_typical"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            value={specifications.quality_inherent_moisture_typical}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="quality_inherent_moisture_rejection_iso"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            value={specifications.quality_inherent_moisture_rejection_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="quality_inherent_moisture_rejection"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            value={specifications.quality_inherent_moisture_rejection}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>
                    </div>

                    <label className="form_section_label mb-0 mt-2">
                      Ash (%)
                    </label>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?
                          <TextField
                            name="quality_ash_basis_iso"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={specifications.quality_ash_basis_iso}
                            onChange={(e) => handleText(e)}
                            select
                          >
                            <MenuItem value="ADB" key={0}> ADB</MenuItem>
                          </TextField>
                          :
                          <TextField
                            name="quality_ash_basis"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={specifications.quality_ash_basis}
                            onChange={(e) => handleText(e)}
                            select
                          >
                            <MenuItem value="ADB" key={0}> ADB</MenuItem>
                          </TextField>
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?
                          <TextField
                            name="quality_ash_typical_iso"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            type='number'
                            value={specifications.quality_ash_typical_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="quality_ash_typical"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            type='number'
                            value={specifications.quality_ash_typical}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="quality_ash_rejection_iso"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            type='number'
                            value={specifications.quality_ash_rejection_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="quality_ash_rejection"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            type='number'
                            value={specifications.quality_ash_rejection}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="quality_ash_penality_iso"
                            margin="dense"
                            variant="outlined"
                            label="Penality"
                            type='number'
                            value={specifications.quality_ash_penality_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="quality_ash_penality"
                            margin="dense"
                            variant="outlined"
                            label="Penality"
                            type='number'
                            value={specifications.quality_ash_penality}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>
                    </div>

                    <label className="form_section_label mb-0 mt-2">
                      Volatile Matter (%)
                    </label>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="quality_volatile_matter_basis_iso"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={specifications.quality_volatile_matter_basis_iso}
                            onChange={(e) => handleText(e)}
                            select
                          >
                            <MenuItem value="ADB" key={0}> ADB</MenuItem>
                          </TextField>
                          :
                          <TextField
                            name="quality_volatile_matter_basis"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={specifications.quality_volatile_matter_basis}
                            onChange={(e) => handleText(e)}
                            select
                          >
                            <MenuItem value="ADB" key={0}> ADB</MenuItem>
                          </TextField>
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="quality_volatile_matter_typical_iso"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            value={specifications.quality_volatile_matter_typical_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="quality_volatile_matter_typical"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            value={specifications.quality_volatile_matter_typical}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="quality_volatile_matter_rejection_iso"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            value={specifications.quality_volatile_matter_rejection_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="quality_volatile_matter_rejection"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            value={specifications.quality_volatile_matter_rejection}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>
                    </div>

                    <label className="form_section_label mb-0 mt-2">
                      Fixed Carbon (%)
                    </label>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="quality_fixed_carbon_basis_iso"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={specifications.quality_fixed_carbon_basis_iso}
                            onChange={(e) => handleText(e)}
                            select
                          >
                            <MenuItem value="ADB" key={0}> ADB</MenuItem>
                          </TextField>
                          :
                          <TextField
                            name="quality_fixed_carbon_basis"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={specifications.quality_fixed_carbon_basis}
                            onChange={(e) => handleText(e)}
                            select
                          >
                            <MenuItem value="ADB" key={0}> ADB</MenuItem>
                          </TextField>
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="quality_fixed_carbon_typical_iso"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            value={specifications.quality_fixed_carbon_typical_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="quality_fixed_carbon_typical"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            value={specifications.quality_fixed_carbon_typical}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="quality_fixed_carbon_rejection_iso"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            value={specifications.quality_fixed_carbon_rejection_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="quality_fixed_carbon_rejection"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            value={specifications.quality_fixed_carbon_rejection}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>
                    </div>

                    <label className="form_section_label mb-0 mt-2">
                      Fuel Ratio(FC/VM)
                    </label>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="quality_fuel_ratio_basis_iso"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={specifications.quality_fuel_ratio_basis_iso}
                            onChange={(e) => handleText(e)}
                            select
                          >
                            <MenuItem value="ADB" key={0}> ADB</MenuItem>
                          </TextField>
                          :
                          <TextField
                            name="quality_fuel_ratio_basis"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={specifications.quality_fuel_ratio_basis}
                            onChange={(e) => handleText(e)}
                            select
                          >
                            <MenuItem value="ADB" key={0}> ADB</MenuItem>
                          </TextField>
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="quality_fuel_ratio_typical_iso"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            value={specifications.quality_fuel_ratio_typical_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="quality_fuel_ratio_typical"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            value={specifications.quality_fuel_ratio_typical}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="quality_fuel_ratio_rejection_iso"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            value={specifications.quality_fuel_ratio_rejection_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="quality_fuel_ratio_rejection"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            value={specifications.quality_fuel_ratio_rejection}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>
                    </div>

                    <label className="form_section_label mb-0 mt-2">
                      Total Sulphur (%)
                    </label>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="quality_total_sulphur_basis_iso"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={specifications.quality_total_sulphur_basis_iso}
                            onChange={(e) => handleText(e)}
                            select
                          >
                            <MenuItem value="ADB" key={0}> ADB</MenuItem>
                          </TextField>
                          :
                          <TextField
                            name="quality_total_sulphur_basis"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={specifications.quality_total_sulphur_basis}
                            onChange={(e) => handleText(e)}
                            select
                          >
                            <MenuItem value="ADB" key={0}> ADB</MenuItem>
                          </TextField>
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="quality_total_sulphur_typical_iso"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            type='number'
                            value={specifications.quality_total_sulphur_typical_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="quality_total_sulphur_typical"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            type='number'
                            value={specifications.quality_total_sulphur_typical}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="quality_total_sulphur_rejection_iso"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            type='number'
                            value={specifications.quality_total_sulphur_rejection_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="quality_total_sulphur_rejection"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            type='number'
                            value={specifications.quality_total_sulphur_rejection}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="quality_total_sulphur_penality_iso"
                            margin="dense"
                            variant="outlined"
                            label="Penality"
                            type='number'
                            value={specifications.quality_total_sulphur_penality_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :

                          <TextField
                            name="quality_total_sulphur_penality"
                            margin="dense"
                            variant="outlined"
                            label="Penality"
                            type='number'
                            value={specifications.quality_total_sulphur_penality}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>
                    </div>

                    <label className="form_section_label mb-0 mt-2">
                      HGI
                    </label>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="quality_hgi_typical_iso"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            value={specifications.quality_hgi_typical_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="quality_hgi_typical"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            value={specifications.quality_hgi_typical}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="quality_hgi_rejection_iso"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            value={specifications.quality_hgi_rejection_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="quality_hgi_rejection"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            value={specifications.quality_hgi_rejection}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>

                    </div>

                  </div>
                </>
              }

              {(activeTabIndex === 0 && activeSubTab === 1) &&
                <>
                  <div className="row p-2">
                    <label className="form_section_label mb-0 mt-2">
                      Carbon (%)
                    </label>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="ultimate_carbon_basis_iso"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={specifications.ultimate_carbon_basis_iso}
                            onChange={(e) => handleText(e)}
                            select
                          >
                            {dropDown}
                          </TextField>
                          :
                          <TextField
                            name="ultimate_carbon_basis"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={specifications.ultimate_carbon_basis}
                            onChange={(e) => handleText(e)}
                            select
                          >
                            {dropDown}
                          </TextField>
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="ultimate_carbon_typical_iso"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            value={specifications.ultimate_carbon_typical_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="ultimate_carbon_typical"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            value={specifications.ultimate_carbon_typical}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="ultimate_carbon_rejection_iso"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            value={specifications.ultimate_carbon_rejection_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="ultimate_carbon_rejection"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            value={specifications.ultimate_carbon_rejection}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>
                    </div>

                    <label className="form_section_label mb-0 mt-2">
                      Hydrogen (%)
                    </label>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="ultimate_hydrogen_basis_iso"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={specifications.ultimate_hydrogen_basis_iso}
                            onChange={(e) => handleText(e)}
                            select
                          >
                            {dropDown}
                          </TextField>
                          :
                          <TextField
                            name="ultimate_hydrogen_basis"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={specifications.ultimate_hydrogen_basis}
                            onChange={(e) => handleText(e)}
                            select
                          >
                            {dropDown}
                          </TextField>
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="ultimate_hydrogen_typical_iso"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            value={specifications.ultimate_hydrogen_typical_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="ultimate_hydrogen_typical"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            value={specifications.ultimate_hydrogen_typical}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="ultimate_hydrogen_rejection_iso"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            value={specifications.ultimate_hydrogen_rejection_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="ultimate_hydrogen_rejection"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            value={specifications.ultimate_hydrogen_rejection}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>
                    </div>

                    <label className="form_section_label mb-0 mt-2">
                      Nitrogen (%)
                    </label>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="ultimate_nitrogen_basis_iso"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={specifications.ultimate_nitrogen_basis_iso}
                            onChange={(e) => handleText(e)}
                            select
                          >
                            {dropDown}
                          </TextField>
                          :
                          <TextField
                            name="ultimate_nitrogen_basis"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={specifications.ultimate_nitrogen_basis}
                            onChange={(e) => handleText(e)}
                            select
                          >
                            {dropDown}
                          </TextField>
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="ultimate_nitrogen_typical_iso"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            value={specifications.ultimate_nitrogen_typical_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="ultimate_nitrogen_typical"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            value={specifications.ultimate_nitrogen_typical}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="ultimate_nitrogen_rejection_iso"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            value={specifications.ultimate_nitrogen_rejection_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="ultimate_nitrogen_rejection"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            value={specifications.ultimate_nitrogen_rejection}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>
                    </div>

                  </div>
                </>
              }

              {(activeTabIndex === 0 && activeSubTab === 2) &&
                <>
                  <div className="row p-2">
                    <label className="form_section_label mb-0 mt-2">
                      SiO2 (%)
                    </label>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="ash_sio2_basis_iso"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={specifications.ash_sio2_basis_iso}
                            onChange={(e) => handleText(e)}
                            select
                          >
                            {dropDown}
                          </TextField>
                          :
                          <TextField
                            name="ash_sio2_basis"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={specifications.ash_sio2_basis}
                            onChange={(e) => handleText(e)}
                            select
                          >
                            {dropDown}
                          </TextField>
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="ash_sio2_typical_iso"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            value={specifications.ash_sio2_typical_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="ash_sio2_typical"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            value={specifications.ash_sio2_typical}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="ash_sio2_rejection_iso"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            value={specifications.ash_sio2_rejection_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="ash_sio2_rejection"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            value={specifications.ash_sio2_rejection}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>
                    </div>

                    <label className="form_section_label mb-0 mt-2">
                      AI203 (%)
                    </label>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="ash_ai203_basis_iso"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={specifications.ash_ai203_basis_iso}
                            onChange={(e) => handleText(e)}
                            select
                          >
                            {dropDown}
                          </TextField>
                          :
                          <TextField
                            name="ash_ai203_basis"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={specifications.ash_ai203_basis}
                            onChange={(e) => handleText(e)}
                            select
                          >
                            {dropDown}
                          </TextField>
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="ash_ai203_typical_iso"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            value={specifications.ash_ai203_typical_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="ash_ai203_typical"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            value={specifications.ash_ai203_typical}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="ash_ai203_rejection_iso"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            value={specifications.ash_ai203_rejection_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="ash_ai203_rejection"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            value={specifications.ash_ai203_rejection}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>
                    </div>

                    <label className="form_section_label mb-0 mt-2">
                      Fe203 (%)
                    </label>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="ash_fe203_basis_iso"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={specifications.ash_fe203_basis_iso}
                            onChange={(e) => handleText(e)}
                            select
                          >
                            {dropDown}
                          </TextField>
                          :
                          <TextField
                            name="ash_fe203_basis"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={specifications.ash_fe203_basis}
                            onChange={(e) => handleText(e)}
                            select
                          >
                            {dropDown}
                          </TextField>
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="ash_fe203_typical_iso"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            value={specifications.ash_fe203_typical_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="ash_fe203_typical"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            value={specifications.ash_fe203_typical}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="ash_fe203_rejection_iso"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            value={specifications.ash_fe203_rejection_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="ash_fe203_rejection"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            value={specifications.ash_fe203_rejection}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>
                    </div>

                    <label className="form_section_label mb-0 mt-2">
                      CaO (%)
                    </label>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="ash_cao_basis_iso"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={specifications.ash_cao_basis_iso}
                            onChange={(e) => handleText(e)}
                            select
                          >
                            {dropDown}
                          </TextField>
                          :
                          <TextField
                            name="ash_cao_basis"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={specifications.ash_cao_basis}
                            onChange={(e) => handleText(e)}
                            select
                          >
                            {dropDown}
                          </TextField>
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="ash_cao_typical_iso"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            value={specifications.ash_cao_typical_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="ash_cao_typical"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            value={specifications.ash_cao_typical}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="ash_cao_rejection_iso"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            value={specifications.ash_cao_rejection_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="ash_cao_rejection"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            value={specifications.ash_cao_rejection}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>
                    </div>

                    <label className="form_section_label mb-0 mt-2">
                      MgO (%)
                    </label>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="ash_mgo_basis_iso"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={specifications.ash_mgo_basis_iso}
                            onChange={(e) => handleText(e)}
                            select
                          >
                            {dropDown}
                          </TextField>
                          :
                          <TextField
                            name="ash_mgo_basis"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={specifications.ash_mgo_basis}
                            onChange={(e) => handleText(e)}
                            select
                          >
                            {dropDown}
                          </TextField>
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="ash_mgo_typical_iso"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            value={specifications.ash_mgo_typical_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="ash_mgo_typical"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            value={specifications.ash_mgo_typical}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="ash_mgo_rejection_iso"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            value={specifications.ash_mgo_rejection_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="ash_mgo_rejection"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            value={specifications.ash_mgo_rejection}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>
                    </div>

                    <label className="form_section_label mb-0 mt-2">
                      Na2O (%)
                    </label>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="ash_na2o_basis_iso"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={specifications.ash_na2o_basis_iso}
                            onChange={(e) => handleText(e)}
                            select
                          >
                            {dropDown}
                          </TextField>
                          :
                          <TextField
                            name="ash_na2o_basis"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={specifications.ash_na2o_basis}
                            onChange={(e) => handleText(e)}
                            select
                          >
                            {dropDown}
                          </TextField>
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="ash_na2o_typical_iso"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            value={specifications.ash_na2o_typical_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="ash_na2o_typical"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            value={specifications.ash_na2o_typical}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="ash_na2o_rejection_iso"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            value={specifications.ash_na2o_rejection_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="ash_na2o_rejection"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            value={specifications.ash_na2o_rejection}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>
                    </div>

                    <label className="form_section_label mb-0 mt-2">
                      K2O (%)
                    </label>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="ash_k2o_basis_iso"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={specifications.ash_k2o_basis_iso}
                            onChange={(e) => handleText(e)}
                            select
                          >
                            {dropDown}
                          </TextField>
                          :
                          <TextField
                            name="ash_k2o_basis"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={specifications.ash_k2o_basis}
                            onChange={(e) => handleText(e)}
                            select
                          >
                            {dropDown}
                          </TextField>
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="ash_k2o_typical_iso"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            value={specifications.ash_k2o_typical_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="ash_k2o_typical"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            value={specifications.ash_k2o_typical}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="ash_k2o_rejection_iso"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            value={specifications.ash_k2o_rejection_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="ash_k2o_rejection"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            value={specifications.ash_k2o_rejection}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>
                    </div>

                    <label className="form_section_label mb-0 mt-2">
                      TiO2 (%)
                    </label>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="ash_tio2_basis_iso"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={specifications.ash_tio2_basis_iso}
                            onChange={(e) => handleText(e)}
                            select
                          >
                            {dropDown}
                          </TextField>
                          :
                          <TextField
                            name="ash_tio2_basis"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={specifications.ash_tio2_basis}
                            onChange={(e) => handleText(e)}
                            select
                          >
                            {dropDown}
                          </TextField>
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="ash_tio2_typical_iso"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            value={specifications.ash_tio2_typical_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="ash_tio2_typical"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            value={specifications.ash_tio2_typical}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="ash_tio2_rejection_iso"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            value={specifications.ash_tio2_rejection_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="ash_tio2_rejection"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            value={specifications.ash_tio2_rejection}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>
                    </div>

                    <label className="form_section_label mb-0 mt-2">
                      Mn3O4 (%)
                    </label>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="ash_mn3o4_basis_iso"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={specifications.ash_mn3o4_basis_iso}
                            onChange={(e) => handleText(e)}
                            select
                          >
                            {dropDown}
                          </TextField>
                          :
                          <TextField
                            name="ash_mn3o4_basis"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={specifications.ash_mn3o4_basis}
                            onChange={(e) => handleText(e)}
                            select
                          >
                            {dropDown}
                          </TextField>
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="ash_mn3o4_typical_iso"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            value={specifications.ash_mn3o4_typical_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="ash_mn3o4_typical"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            value={specifications.ash_mn3o4_typical}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="ash_mn3o4_rejection_iso"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            value={specifications.ash_mn3o4_rejection_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="ash_mn3o4_rejection"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            value={specifications.ash_mn3o4_rejection}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>
                    </div>

                    <label className="form_section_label mb-0 mt-2">
                      SO3 (%)
                    </label>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="ash_so3_basis_iso"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={specifications.ash_so3_basis_iso}
                            onChange={(e) => handleText(e)}
                            select
                          >
                            {dropDown}
                          </TextField>
                          :
                          <TextField
                            name="ash_so3_basis"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={specifications.ash_so3_basis}
                            onChange={(e) => handleText(e)}
                            select
                          >
                            {dropDown}
                          </TextField>
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="ash_so3_typical_iso"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            value={specifications.ash_so3_typical_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="ash_so3_typical"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            value={specifications.ash_so3_typical}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="ash_so3_rejection_iso"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            value={specifications.ash_so3_rejection_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="ash_so3_rejection"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            value={specifications.ash_so3_rejection}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>
                    </div>

                    <label className="form_section_label mb-0 mt-2">
                      P2O5 (%)
                    </label>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="ash_p2o5_basis_iso"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={specifications.ash_p2o5_basis_iso}
                            onChange={(e) => handleText(e)}
                            select
                          >
                            {dropDown}
                          </TextField>
                          :
                          <TextField
                            name="ash_p2o5_basis"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={specifications.ash_p2o5_basis}
                            onChange={(e) => handleText(e)}
                            select
                          >
                            {dropDown}
                          </TextField>
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="ash_p2o5_typical_iso"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            value={specifications.ash_p2o5_typical_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="ash_p2o5_typical"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            value={specifications.ash_p2o5_typical}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="ash_p2o5_rejection_iso"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            value={specifications.ash_p2o5_rejection_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="ash_p2o5_rejection"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            value={specifications.ash_p2o5_rejection}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>
                    </div>

                  </div>
                </>
              }

              {/*<!----------------------------- Size Distribution Table  ---------------->*/}
              {(activeTabIndex === 1) &&
                <>
                  <div className="row p-2">
                    <label className="form_section_label mb-0 mt-2">
                      Size Distribution (%)
                    </label>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="size_dist1_name_iso"
                            margin="dense"
                            variant="outlined"
                            label="Name"
                            value={specifications.size_dist1_name_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="size_dist1_name"
                            margin="dense"
                            variant="outlined"
                            label="Name"
                            value={specifications.size_dist1_name}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="size_dist1_typical_iso"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            value={specifications.size_dist1_typical_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="size_dist1_typical"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            value={specifications.size_dist1_typical}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="size_dist1_rejection_iso"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            value={specifications.size_dist1_rejection_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="size_dist1_rejection"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            value={specifications.size_dist1_rejection}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>
                    </div>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="size_dist2_name_iso"
                            margin="dense"
                            variant="outlined"
                            label="Name"
                            value={specifications.size_dist2_name_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="size_dist2_name"
                            margin="dense"
                            variant="outlined"
                            label="Name"
                            value={specifications.size_dist2_name}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="size_dist2_typical_iso"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            value={specifications.size_dist2_typical_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="size_dist2_typical"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            value={specifications.size_dist2_typical}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="size_dist2_rejection_iso"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            value={specifications.size_dist2_rejection_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="size_dist2_rejection"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            value={specifications.size_dist2_rejection}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>
                    </div>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="size_dist3_name_iso"
                            margin="dense"
                            variant="outlined"
                            label="Name"
                            value={specifications.size_dist3_name_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="size_dist3_name"
                            margin="dense"
                            variant="outlined"
                            label="Name"
                            value={specifications.size_dist3_name}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="size_dist3_typical_iso"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            value={specifications.size_dist3_typical_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="size_dist3_typical"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            value={specifications.size_dist3_typical}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="size_dist3_rejection_iso"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            value={specifications.size_dist3_rejection_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="size_dist3_rejection"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            value={specifications.size_dist3_rejection}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>
                    </div>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="size_dist4_name_iso"
                            margin="dense"
                            variant="outlined"
                            label="Name"
                            value={specifications.size_dist4_name_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="size_dist4_name"
                            margin="dense"
                            variant="outlined"
                            label="Name"
                            value={specifications.size_dist4_name}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="size_dist4_typical_iso"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            value={specifications.size_dist4_typical_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="size_dist4_typical"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            value={specifications.size_dist4_typical}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="size_dist4_rejection_iso"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            value={specifications.size_dist4_rejection_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="size_dist4_rejection"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            value={specifications.size_dist4_rejection}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>
                    </div>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="size_dist5_name_iso"
                            margin="dense"
                            variant="outlined"
                            label="Name"
                            value={specifications.size_dist5_name_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="size_dist5_name"
                            margin="dense"
                            variant="outlined"
                            label="Name"
                            value={specifications.size_dist5_name}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="size_dist5_typical_iso"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            value={specifications.size_dist5_typical_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="size_dist5_typical"
                            margin="dense"
                            variant="outlined"
                            label="Typical"
                            value={specifications.size_dist5_typical}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="size_dist5_rejection_iso"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            value={specifications.size_dist5_rejection_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="size_dist5_rejection"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            value={specifications.size_dist5_rejection}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>
                    </div>

                  </div>
                </>
              }

              {/* <!---------------------- Ash Fusion Temperature Reducing Atmosphere table ---------!>*/}
              {(activeTabIndex === 2) &&
                <>
                  <div className="row p-2">

                    <div className="col-lg-12 row">
                      <div className="col-lg-4">
                        <label className="form_label mb-0 mt-2" style={{ fontWeight: "bold" }}>
                          Reducing Atmosphere
                        </label>
                      </div>
                      <div className='col-lg-8'>
                        <label className="form_section_label mb-0 mt-2">
                          Initial Deformation (Deg.C)
                        </label>
                        <div className='row'>
                          <div className='col-lg-4'>
                            {(specStandard === "ISO" || specActiveTab === 1) ?

                              <TextField
                                name="ash_reducing_initial_typical_iso"
                                margin="dense"
                                variant="outlined"
                                label="Typical"
                                value={specifications.ash_reducing_initial_typical_iso}
                                onChange={(e) => handleText(e)}
                                inputProps={{ style: { textTransform: "uppercase", } }}
                              />
                              :
                              <TextField
                                name="ash_reducing_initial_typical"
                                margin="dense"
                                variant="outlined"
                                label="Typical"
                                value={specifications.ash_reducing_initial_typical}
                                onChange={(e) => handleText(e)}
                                inputProps={{ style: { textTransform: "uppercase", } }}
                              />
                            }
                          </div>

                          <div className='col-lg-4'>
                            {(specStandard === "ISO" || specActiveTab === 1) ?

                              <TextField
                                name="ash_reducing_initial_rejection_iso"
                                margin="dense"
                                variant="outlined"
                                label="Rejection"
                                value={specifications.ash_reducing_initial_rejection_iso}
                                onChange={(e) => handleText(e)}
                                inputProps={{ style: { textTransform: "uppercase", } }}
                              />
                              :
                              <TextField
                                name="ash_reducing_initial_rejection"
                                margin="dense"
                                variant="outlined"
                                label="Rejection"
                                value={specifications.ash_reducing_initial_rejection}
                                onChange={(e) => handleText(e)}
                                inputProps={{ style: { textTransform: "uppercase", } }}
                              />
                            }
                          </div>
                        </div>

                        <label className="form_section_label mb-0 mt-2">
                          Softening (Deg.C)
                        </label>
                        <div className='row'>
                          <div className='col-lg-4'>
                            {(specStandard === "ISO" || specActiveTab === 1) ?
                              <TextField
                                name="ash_reducing_softening_typical_iso"
                                margin="dense"
                                variant="outlined"
                                label="Typical"
                                value={specifications.ash_reducing_softening_typical_iso}
                                onChange={(e) => handleText(e)}
                                inputProps={{ style: { textTransform: "uppercase", } }}
                              />
                              :
                              <TextField
                                name="ash_reducing_softening_typical"
                                margin="dense"
                                variant="outlined"
                                label="Typical"
                                value={specifications.ash_reducing_softening_typical}
                                onChange={(e) => handleText(e)}
                                inputProps={{ style: { textTransform: "uppercase", } }}
                              />
                            }
                          </div>

                          <div className='col-lg-4'>
                            {(specStandard === "ISO" || specActiveTab === 1) ?

                              <TextField
                                name="ash_reducing_softening_rejection_iso"
                                margin="dense"
                                variant="outlined"
                                label="Rejection"
                                value={specifications.ash_reducing_softening_rejection_iso}
                                onChange={(e) => handleText(e)}
                                inputProps={{ style: { textTransform: "uppercase", } }}
                              />
                              :
                              <TextField
                                name="ash_reducing_softening_rejection"
                                margin="dense"
                                variant="outlined"
                                label="Rejection"
                                value={specifications.ash_reducing_softening_rejection}
                                onChange={(e) => handleText(e)}
                                inputProps={{ style: { textTransform: "uppercase", } }}
                              />
                            }
                          </div>
                        </div>

                        <label className="form_section_label mb-0 mt-2">
                          Hemispherical Deformation (Deg.C)
                        </label>
                        <div className='row'>
                          <div className='col-lg-4'>
                            {(specStandard === "ISO" || specActiveTab === 1) ?
                              <TextField
                                name="ash_reducing_hemispherical_typical_iso"
                                margin="dense"
                                variant="outlined"
                                label="Typical"
                                value={specifications.ash_reducing_hemispherical_typical_iso}
                                onChange={(e) => handleText(e)}
                                inputProps={{ style: { textTransform: "uppercase", } }}
                              />
                              :
                              <TextField
                                name="ash_reducing_hemispherical_typical"
                                margin="dense"
                                variant="outlined"
                                label="Typical"
                                value={specifications.ash_reducing_hemispherical_typical}
                                onChange={(e) => handleText(e)}
                                inputProps={{ style: { textTransform: "uppercase", } }}
                              />
                            }
                          </div>

                          <div className='col-lg-4'>
                            {(specStandard === "ISO" || specActiveTab === 1) ?

                              <TextField
                                name="ash_reducing_hemispherical_rejection_iso"
                                margin="dense"
                                variant="outlined"
                                label="Rejection"
                                value={specifications.ash_reducing_hemispherical_rejection_iso}
                                onChange={(e) => handleText(e)}
                                inputProps={{ style: { textTransform: "uppercase", } }}
                              />
                              :
                              <TextField
                                name="ash_reducing_hemispherical_rejection"
                                margin="dense"
                                variant="outlined"
                                label="Rejection"
                                value={specifications.ash_reducing_hemispherical_rejection}
                                onChange={(e) => handleText(e)}
                                inputProps={{ style: { textTransform: "uppercase", } }}
                              />
                            }
                          </div>
                        </div>

                        <label className="form_section_label mb-0 mt-2">
                          Flow (Deg.C)
                        </label>
                        <div className='row'>
                          <div className='col-lg-4'>
                            {(specStandard === "ISO" || specActiveTab === 1) ?

                              <TextField
                                name="ash_reducing_flow_typical_iso"
                                margin="dense"
                                variant="outlined"
                                label="Typical"
                                value={specifications.ash_reducing_flow_typical_iso}
                                onChange={(e) => handleText(e)}
                                inputProps={{ style: { textTransform: "uppercase", } }}
                              />
                              :
                              <TextField
                                name="ash_reducing_flow_typical"
                                margin="dense"
                                variant="outlined"
                                label="Typical"
                                value={specifications.ash_reducing_flow_typical}
                                onChange={(e) => handleText(e)}
                                inputProps={{ style: { textTransform: "uppercase", } }}
                              />
                            }
                          </div>

                          <div className='col-lg-4'>
                            {(specStandard === "ISO" || specActiveTab === 1) ?

                              <TextField
                                name="ash_reducing_flow_rejection_iso"
                                margin="dense"
                                variant="outlined"
                                label="Rejection"
                                value={specifications.ash_reducing_flow_rejection_iso}
                                onChange={(e) => handleText(e)}
                                inputProps={{ style: { textTransform: "uppercase", } }}
                              />
                              :
                              <TextField
                                name="ash_reducing_flow_rejection"
                                margin="dense"
                                variant="outlined"
                                label="Rejection"
                                value={specifications.ash_reducing_flow_rejection}
                                onChange={(e) => handleText(e)}
                                inputProps={{ style: { textTransform: "uppercase", } }}
                              />
                            }
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12 row">
                      <div className="col-lg-4">
                        <label className="form_label mb-0 mt-2" style={{ fontWeight: "bold" }}>
                          Oxidizing Atmosphere
                        </label>
                      </div>
                      <div className='col-lg-8'>
                        <label className="form_section_label mb-0 mt-2">
                          Initial Deformation (Deg.C)
                        </label>
                        <div className='row'>
                          <div className='col-lg-4'>
                            {(specStandard === "ISO" || specActiveTab === 1) ?

                              <TextField
                                name="ash_oxidizing_initial_typical_iso"
                                margin="dense"
                                variant="outlined"
                                label="Typical"
                                value={specifications.ash_oxidizing_initial_typical_iso}
                                onChange={(e) => handleText(e)}
                                inputProps={{ style: { textTransform: "uppercase", } }}
                              />
                              :
                              <TextField
                                name="ash_oxidizing_initial_typical"
                                margin="dense"
                                variant="outlined"
                                label="Typical"
                                value={specifications.ash_oxidizing_initial_typical}
                                onChange={(e) => handleText(e)}
                                inputProps={{ style: { textTransform: "uppercase", } }}
                              />
                            }
                          </div>

                          <div className='col-lg-4'>
                            {(specStandard === "ISO" || specActiveTab === 1) ?

                              <TextField
                                name="ash_oxidizing_initial_rejection_iso"
                                margin="dense"
                                variant="outlined"
                                label="Rejection"
                                value={specifications.ash_oxidizing_initial_rejection_iso}
                                onChange={(e) => handleText(e)}
                                inputProps={{ style: { textTransform: "uppercase", } }}
                              />
                              :
                              <TextField
                                name="ash_oxidizing_initial_rejection"
                                margin="dense"
                                variant="outlined"
                                label="Rejection"
                                value={specifications.ash_oxidizing_initial_rejection}
                                onChange={(e) => handleText(e)}
                                inputProps={{ style: { textTransform: "uppercase", } }}
                              />
                            }
                          </div>
                        </div>

                        <label className="form_section_label mb-0 mt-2">
                          Softening (Deg.C)
                        </label>
                        <div className='row'>
                          <div className='col-lg-4'>
                            {(specStandard === "ISO" || specActiveTab === 1) ?

                              <TextField
                                name="ash_oxidizing_softening_typical_iso"
                                margin="dense"
                                variant="outlined"
                                label="Typical"
                                value={specifications.ash_oxidizing_softening_typical_iso}
                                onChange={(e) => handleText(e)}
                                inputProps={{ style: { textTransform: "uppercase", } }}
                              />
                              :
                              <TextField
                                name="ash_oxidizing_softening_typical"
                                margin="dense"
                                variant="outlined"
                                label="Typical"
                                value={specifications.ash_oxidizing_softening_typical}
                                onChange={(e) => handleText(e)}
                                inputProps={{ style: { textTransform: "uppercase", } }}
                              />
                            }
                          </div>

                          <div className='col-lg-4'>
                            {(specStandard === "ISO" || specActiveTab === 1) ?

                              <TextField
                                name="ash_oxidizing_softening_rejection_iso"
                                margin="dense"
                                variant="outlined"
                                label="Rejection"
                                value={specifications.ash_oxidizing_softening_rejection_iso}
                                onChange={(e) => handleText(e)}
                                inputProps={{ style: { textTransform: "uppercase", } }}
                              />
                              :
                              <TextField
                                name="ash_oxidizing_softening_rejection"
                                margin="dense"
                                variant="outlined"
                                label="Rejection"
                                value={specifications.ash_oxidizing_softening_rejection}
                                onChange={(e) => handleText(e)}
                                inputProps={{ style: { textTransform: "uppercase", } }}
                              />
                            }
                          </div>
                        </div>

                        <label className="form_section_label mb-0 mt-2">
                          Hemispherical Deformation (Deg.C)
                        </label>
                        <div className='row'>
                          <div className='col-lg-4'>
                            {(specStandard === "ISO" || specActiveTab === 1) ?
                              <TextField
                                name="ash_oxidizing_hemispherical_typical_iso"
                                margin="dense"
                                variant="outlined"
                                label="Typical"
                                value={specifications.ash_oxidizing_hemispherical_typical_iso}
                                onChange={(e) => handleText(e)}
                                inputProps={{ style: { textTransform: "uppercase", } }}
                              />
                              :
                              <TextField
                                name="ash_oxidizing_hemispherical_typical"
                                margin="dense"
                                variant="outlined"
                                label="Typical"
                                value={specifications.ash_oxidizing_hemispherical_typical}
                                onChange={(e) => handleText(e)}
                                inputProps={{ style: { textTransform: "uppercase", } }}
                              />
                            }
                          </div>

                          <div className='col-lg-4'>
                            {(specStandard === "ISO" || specActiveTab === 1) ?

                              <TextField
                                name="ash_oxidizing_hemispherical_rejection_iso"
                                margin="dense"
                                variant="outlined"
                                label="Rejection"
                                value={specifications.ash_oxidizing_hemispherical_rejection_iso}
                                onChange={(e) => handleText(e)}
                                inputProps={{ style: { textTransform: "uppercase", } }}
                              />
                              :
                              <TextField
                                name="ash_oxidizing_hemispherical_rejection"
                                margin="dense"
                                variant="outlined"
                                label="Rejection"
                                value={specifications.ash_oxidizing_hemispherical_rejection}
                                onChange={(e) => handleText(e)}
                                inputProps={{ style: { textTransform: "uppercase", } }}
                              />
                            }
                          </div>
                        </div>

                        <label className="form_section_label mb-0 mt-2">
                          Flow (Deg.C)
                        </label>
                        <div className='row'>
                          <div className='col-lg-4'>
                            {(specStandard === "ISO" || specActiveTab === 1) ?

                              <TextField
                                name="ash_oxidizing_flow_typical_iso"
                                margin="dense"
                                variant="outlined"
                                label="Typical"
                                value={specifications.ash_oxidizing_flow_typical_iso}
                                onChange={(e) => handleText(e)}
                                inputProps={{ style: { textTransform: "uppercase", } }}
                              />
                              :
                              <TextField
                                name="ash_oxidizing_flow_typical"
                                margin="dense"
                                variant="outlined"
                                label="Typical"
                                value={specifications.ash_oxidizing_flow_typical}
                                onChange={(e) => handleText(e)}
                                inputProps={{ style: { textTransform: "uppercase", } }}
                              />
                            }
                          </div>

                          <div className='col-lg-4'>
                            {(specStandard === "ISO" || specActiveTab === 1) ?

                              <TextField
                                name="ash_oxidizing_flow_rejection_iso"
                                margin="dense"
                                variant="outlined"
                                label="Rejection"
                                value={specifications.ash_oxidizing_flow_rejection_iso}
                                onChange={(e) => handleText(e)}
                                inputProps={{ style: { textTransform: "uppercase", } }}
                              />
                              :
                              <TextField
                                name="ash_oxidizing_flow_rejection"
                                margin="dense"
                                variant="outlined"
                                label="Rejection"
                                value={specifications.ash_oxidizing_flow_rejection}
                                onChange={(e) => handleText(e)}
                                inputProps={{ style: { textTransform: "uppercase", } }}
                              />
                            }
                          </div>
                        </div>
                      </div>
                    </div>


                  </div>
                </>
              }
              {/* <!---------------------- Ash Fusion Temperature Reducing Atmosphere table ---------!>*/}
              {(activeTabIndex === 3) &&
                <>
                  <div className="row p-2">

                    <div className="col-lg-12 row">
                      <div className="col-lg-3">
                        <label className="form_label mb-0 mt-2" style={{ fontWeight: "bold" }}>
                          Trace Elements
                        </label>
                      </div>
                      <div className='col-lg-9'>
                        <label className="form_section_label mb-0 mt-2">
                          Arsenic (ppm)
                        </label>
                        <div className='row'>
                          <div className='col-lg-3'>
                            {(specStandard === "ISO" || specActiveTab === 1) ?

                              <TextField
                                name="trace_arsenic_basis_iso"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                value={specifications.trace_arsenic_basis_iso}
                                onChange={(e) => handleText(e)}
                                select
                              >
                                {dropDown}
                              </TextField>
                              :
                              <TextField
                                name="trace_arsenic_basis"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                value={specifications.trace_arsenic_basis}
                                onChange={(e) => handleText(e)}
                                select
                              >
                                {dropDown}
                              </TextField>
                            }
                          </div>

                          <div className='col-lg-3'>
                            {(specStandard === "ISO" || specActiveTab === 1) ?

                              <TextField
                                name="trace_arsenic_typical_iso"
                                margin="dense"
                                variant="outlined"
                                label="Typical"
                                value={specifications.trace_arsenic_typical_iso}
                                onChange={(e) => handleText(e)}
                                inputProps={{ style: { textTransform: "uppercase", } }}
                              />
                              :
                              <TextField
                                name="trace_arsenic_typical"
                                margin="dense"
                                variant="outlined"
                                label="Typical"
                                value={specifications.trace_arsenic_typical}
                                onChange={(e) => handleText(e)}
                                inputProps={{ style: { textTransform: "uppercase", } }}
                              />
                            }
                          </div>

                          <div className='col-lg-3'>
                            {(specStandard === "ISO" || specActiveTab === 1) ?

                              <TextField
                                name="trace_arsenic_rejection_iso"
                                margin="dense"
                                variant="outlined"
                                label="Rejection"
                                value={specifications.trace_arsenic_rejection_iso}
                                onChange={(e) => handleText(e)}
                                inputProps={{ style: { textTransform: "uppercase", } }}
                              />
                              :
                              <TextField
                                name="trace_arsenic_rejection"
                                margin="dense"
                                variant="outlined"
                                label="Rejection"
                                value={specifications.trace_arsenic_rejection}
                                onChange={(e) => handleText(e)}
                                inputProps={{ style: { textTransform: "uppercase", } }}
                              />
                            }
                          </div>
                        </div>

                        <label className="form_section_label mb-0 mt-2">
                          Mercury (ppm)
                        </label>
                        <div className='row'>
                          <div className='col-lg-3'>
                            {(specStandard === "ISO" || specActiveTab === 1) ?

                              <TextField
                                name="trace_mercury_basis_iso"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                value={specifications.trace_mercury_basis_iso}
                                onChange={(e) => handleText(e)}
                                select
                              >
                                {dropDown}
                              </TextField>
                              :
                              <TextField
                                name="trace_mercury_basis"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                value={specifications.trace_mercury_basis}
                                onChange={(e) => handleText(e)}
                                select
                              >
                                {dropDown}
                              </TextField>
                            }
                          </div>

                          <div className='col-lg-3'>
                            {(specStandard === "ISO" || specActiveTab === 1) ?

                              <TextField
                                name="trace_mercury_typical_iso"
                                margin="dense"
                                variant="outlined"
                                label="Typical"
                                value={specifications.trace_mercury_typical_iso}
                                onChange={(e) => handleText(e)}
                                inputProps={{ style: { textTransform: "uppercase", } }}
                              />
                              :
                              <TextField
                                name="trace_mercury_typical"
                                margin="dense"
                                variant="outlined"
                                label="Typical"
                                value={specifications.trace_mercury_typical}
                                onChange={(e) => handleText(e)}
                                inputProps={{ style: { textTransform: "uppercase", } }}
                              />
                            }
                          </div>

                          <div className='col-lg-3'>
                            {(specStandard === "ISO" || specActiveTab === 1) ?

                              <TextField
                                name="trace_mercury_rejection_iso"
                                margin="dense"
                                variant="outlined"
                                label="Rejection"
                                value={specifications.trace_mercury_rejection_iso}
                                onChange={(e) => handleText(e)}
                                inputProps={{ style: { textTransform: "uppercase", } }}
                              />
                              :
                              <TextField
                                name="trace_mercury_rejection"
                                margin="dense"
                                variant="outlined"
                                label="Rejection"
                                value={specifications.trace_mercury_rejection}
                                onChange={(e) => handleText(e)}
                                inputProps={{ style: { textTransform: "uppercase", } }}
                              />
                            }
                          </div>
                        </div>

                        <label className="form_section_label mb-0 mt-2">
                          Selenium (ppm)
                        </label>
                        <div className='row'>
                          <div className='col-lg-3'>
                            {(specStandard === "ISO" || specActiveTab === 1) ?


                              <TextField
                                name="trace_selenium_basis_iso"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                value={specifications.trace_selenium_basis_iso}
                                onChange={(e) => handleText(e)}
                                select
                              >
                                {dropDown}
                              </TextField>
                              :

                              <TextField
                                name="trace_selenium_basis"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                value={specifications.trace_selenium_basis}
                                onChange={(e) => handleText(e)}
                                select
                              >
                                {dropDown}
                              </TextField>
                            }
                          </div>

                          <div className='col-lg-3'>
                            {(specStandard === "ISO" || specActiveTab === 1) ?

                              <TextField
                                name="trace_selenium_typical_iso"
                                margin="dense"
                                variant="outlined"
                                label="Typical"
                                value={specifications.trace_selenium_typical_iso}
                                onChange={(e) => handleText(e)}
                                inputProps={{ style: { textTransform: "uppercase", } }}
                              />
                              :
                              <TextField
                                name="trace_selenium_typical"
                                margin="dense"
                                variant="outlined"
                                label="Typical"
                                value={specifications.trace_selenium_typical}
                                onChange={(e) => handleText(e)}
                                inputProps={{ style: { textTransform: "uppercase", } }}
                              />
                            }
                          </div>

                          <div className='col-lg-3'>
                            {(specStandard === "ISO" || specActiveTab === 1) ?

                              <TextField
                                name="trace_selenium_rejection_iso"
                                margin="dense"
                                variant="outlined"
                                label="Rejection"
                                value={specifications.trace_selenium_rejection_iso}
                                onChange={(e) => handleText(e)}
                                inputProps={{ style: { textTransform: "uppercase", } }}
                              />
                              :
                              <TextField
                                name="trace_selenium_rejection"
                                margin="dense"
                                variant="outlined"
                                label="Rejection"
                                value={specifications.trace_selenium_rejection}
                                onChange={(e) => handleText(e)}
                                inputProps={{ style: { textTransform: "uppercase", } }}
                              />
                            }
                          </div>
                        </div>

                        <label className="form_section_label mb-0 mt-2">
                          Boron (ppm)
                        </label>
                        <div className='row'>
                          <div className='col-lg-3'>
                            {(specStandard === "ISO" || specActiveTab === 1) ?

                              <TextField
                                name="trace_boron_basis_iso"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                value={specifications.trace_boron_basis_iso}
                                onChange={(e) => handleText(e)}
                                select
                              >
                                {dropDown}
                              </TextField>
                              :
                              <TextField
                                name="trace_boron_basis"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                value={specifications.trace_boron_basis}
                                onChange={(e) => handleText(e)}
                                select
                              >
                                {dropDown}
                              </TextField>
                            }
                          </div>

                          <div className='col-lg-3'>
                            {(specStandard === "ISO" || specActiveTab === 1) ?

                              <TextField
                                name="trace_boron_typical_iso"
                                margin="dense"
                                variant="outlined"
                                label="Typical"
                                value={specifications.trace_boron_typical_iso}
                                onChange={(e) => handleText(e)}
                                inputProps={{ style: { textTransform: "uppercase", } }}
                              />
                              :
                              <TextField
                                name="trace_boron_typical"
                                margin="dense"
                                variant="outlined"
                                label="Typical"
                                value={specifications.trace_boron_typical}
                                onChange={(e) => handleText(e)}
                                inputProps={{ style: { textTransform: "uppercase", } }}
                              />
                            }
                          </div>

                          <div className='col-lg-3'>
                            {(specStandard === "ISO" || specActiveTab === 1) ?

                              <TextField
                                name="trace_boron_rejection_iso"
                                margin="dense"
                                variant="outlined"
                                label="Rejection"
                                value={specifications.trace_boron_rejection_iso}
                                onChange={(e) => handleText(e)}
                                inputProps={{ style: { textTransform: "uppercase", } }}
                              />
                              :
                              <TextField
                                name="trace_boron_rejection"
                                margin="dense"
                                variant="outlined"
                                label="Rejection"
                                value={specifications.trace_boron_rejection}
                                onChange={(e) => handleText(e)}
                                inputProps={{ style: { textTransform: "uppercase", } }}
                              />
                            }
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3">
                        <label className="form_label mb-0 mt-2" style={{ fontWeight: "bold" }}>
                          Minor Elements
                        </label>
                      </div>
                      <div className='col-lg-9'>
                        <label className="form_section_label mb-0 mt-2">
                          Ba, Be, Cd, Cr, Cu, Fe, Mn, Ni, Pb, V,
                          Zn, Sr, Sb, Co, Mo (ppm)
                        </label>
                        <div className='row'>
                          <div className='col-lg-3'>
                            {(specStandard === "ISO" || specActiveTab === 1) ?

                              <TextField
                                name="minor_ba_basis_iso"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                value={specifications.minor_ba_basis_iso}
                                onChange={(e) => handleText(e)}
                                select
                              >
                                {dropDown}
                              </TextField>
                              :
                              <TextField
                                name="minor_ba_basis"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                value={specifications.minor_ba_basis}
                                onChange={(e) => handleText(e)}
                                select
                              >
                                {dropDown}
                              </TextField>
                            }
                          </div>
                          <div className='col-lg-3'>
                            {(specStandard === "ISO" || specActiveTab === 1) ?

                              <TextField
                                name="minor_ba_typical_iso"
                                margin="dense"
                                variant="outlined"
                                label="Typical"
                                value={specifications.minor_ba_typical_iso}
                                onChange={(e) => handleText(e)}
                                inputProps={{ style: { textTransform: "uppercase", } }}
                              />
                              :
                              <TextField
                                name="minor_ba_typical"
                                margin="dense"
                                variant="outlined"
                                label="Typical"
                                value={specifications.minor_ba_typical}
                                onChange={(e) => handleText(e)}
                                inputProps={{ style: { textTransform: "uppercase", } }}
                              />
                            }
                          </div>

                          <div className='col-lg-3'>
                            {(specStandard === "ISO" || specActiveTab === 1) ?

                              <TextField
                                name="minor_ba_rejection_iso"
                                margin="dense"
                                variant="outlined"
                                label="Rejection"
                                value={specifications.minor_ba_rejection_iso}
                                onChange={(e) => handleText(e)}
                                inputProps={{ style: { textTransform: "uppercase", } }}
                              />
                              :
                              <TextField
                                name="minor_ba_rejection"
                                margin="dense"
                                variant="outlined"
                                label="Rejection"
                                value={specifications.minor_ba_rejection}
                                onChange={(e) => handleText(e)}
                                inputProps={{ style: { textTransform: "uppercase", } }}
                              />
                            }
                          </div>
                        </div>

                        <label className="form_section_label mb-0 mt-2">
                          Phosphorous,Chlorine (Cl),Flourine (F)
                          (ppm)
                        </label>
                        <div className='row'>
                          <div className='col-lg-3'>
                            {(specStandard === "ISO" || specActiveTab === 1) ?

                              <TextField
                                name="minor_phosphorous_basis_iso"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                value={specifications.minor_phosphorous_basis_iso}
                                onChange={(e) => handleText(e)}
                                select
                              >
                                {dropDown}
                              </TextField>
                              :
                              <TextField
                                name="minor_phosphorous_basis"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                value={specifications.minor_phosphorous_basis}
                                onChange={(e) => handleText(e)}
                                select
                              >
                                {dropDown}
                              </TextField>
                            }
                          </div>
                          <div className='col-lg-3'>
                            {(specStandard === "ISO" || specActiveTab === 1) ?

                              <TextField
                                name="minor_phosphorous_typical_iso"
                                margin="dense"
                                variant="outlined"
                                label="Typical"
                                value={specifications.minor_phosphorous_typical_iso}
                                onChange={(e) => handleText(e)}
                                inputProps={{ style: { textTransform: "uppercase", } }}
                              />
                              :
                              <TextField
                                name="minor_phosphorous_typical"
                                margin="dense"
                                variant="outlined"
                                label="Typical"
                                value={specifications.minor_phosphorous_typical}
                                onChange={(e) => handleText(e)}
                                inputProps={{ style: { textTransform: "uppercase", } }}
                              />
                            }
                          </div>

                          <div className='col-lg-3'>
                            {(specStandard === "ISO" || specActiveTab === 1) ?

                              <TextField
                                name="minor_phosphorous_rejection_iso"
                                margin="dense"
                                variant="outlined"
                                label="Rejection"
                                value={specifications.minor_phosphorous_rejection_iso}
                                onChange={(e) => handleText(e)}
                                inputProps={{ style: { textTransform: "uppercase", } }}
                              />
                              :
                              <TextField
                                name="minor_phosphorous_rejection"
                                margin="dense"
                                variant="outlined"
                                label="Rejection"
                                value={specifications.minor_phosphorous_rejection}
                                onChange={(e) => handleText(e)}
                                inputProps={{ style: { textTransform: "uppercase", } }}
                              />
                            }
                          </div>
                        </div>
                      </div>
                    </div>


                  </div>
                </>
              }
              {/*<!----------------------------- Others Table  ---------------->*/}
              {(activeTabIndex === 4) &&
                <>
                  <div className="row p-2">

                    <div className="col-lg-12 row">

                      <div className="col-lg-4 pl-0">
                        <label className="contract_display_header_label mb-0"> Coal Bulk Density (t/cu.m) </label>
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="other_coal_bulk_density_typical_iso"
                            margin="dense"
                            variant="outlined"
                            value={specifications.other_coal_bulk_density_typical_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="other_coal_bulk_density_typical"
                            margin="dense"
                            variant="outlined"
                            value={specifications.other_coal_bulk_density_typical}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>
                      <div className="col-lg-4 pl-0">
                        <label className="contract_display_header_label mb-0"> Stowage Factor (cuf/t) </label>
                        {(specStandard === "ISO" || specActiveTab === 1) ?
                          <TextField
                            name="other_stowage_factor_typical_iso"
                            margin="dense"
                            variant="outlined"
                            value={specifications.other_stowage_factor_typical_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="other_stowage_factor_typical"
                            margin="dense"
                            variant="outlined"
                            value={specifications.other_stowage_factor_typical}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>

                      <div className="col-lg-4 pl-0">
                        <label className="contract_display_header_label mb-0">  IMSBC Group (A,B,C) </label>
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="other_imsbc_group_typical_iso"
                            margin="dense"
                            variant="outlined"
                            value={specifications.other_imsbc_group_typical_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="other_imsbc_group_typical"
                            margin="dense"
                            variant="outlined"
                            value={specifications.other_imsbc_group_typical}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>

                      <div className="col-lg-4 pl-0">
                        <label className="contract_display_header_label mb-0">G {"&"} Y Index </label>
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="other_GandY_index_typical_iso"
                            margin="dense"
                            variant="outlined"
                            label="Rejection"
                            value={specifications.other_GandY_index_typical_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="other_GandY_index_typical"
                            margin="dense"
                            variant="outlined"
                            value={specifications.other_GandY_index_typical}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>

                      <div className="col-lg-4 pl-0">
                        <label className="contract_display_header_label mb-0">Transmittance Value (Pm) </label>
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="other_transmittance_value_typical_iso"
                            margin="dense"
                            variant="outlined"
                            value={specifications.other_transmittance_value_typical_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="other_transmittance_value_typical"
                            margin="dense"
                            variant="outlined"
                            value={specifications.other_transmittance_value_typical}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>

                      <div className="col-lg-4 pl-0">
                        <label className="contract_display_header_label mb-0">Max lump size (mm)</label>
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="other_max_lump_size_typical_iso"
                            margin="dense"
                            variant="outlined"
                            value={specifications.other_max_lump_size_typical_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="other_max_lump_size_typical"
                            margin="dense"
                            variant="outlined"
                            value={specifications.other_max_lump_size_typical}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>

                      <div className="col-lg-4 pl-0">
                        <label className="contract_display_header_label mb-0">Angle of Repose</label>
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="other_angle_minimum_typical_iso"
                            margin="dense"
                            variant="outlined"
                            label="Minimum"
                            value={specifications.other_angle_minimum_typical_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="other_angle_minimum_typical"
                            margin="dense"
                            variant="outlined"
                            label="Minimum"
                            value={specifications.other_angle_minimum_typical}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>

                      <div className="col-lg-4 pl-0 mt-2">
                        {(specStandard === "ISO" || specActiveTab === 1) ?

                          <TextField
                            name="other_angle_maximum_typical_iso"
                            margin="dense"
                            variant="outlined"
                            label="Maximun"
                            value={specifications.other_angle_maximum_typical_iso}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                          :
                          <TextField
                            name="other_angle_maximum_typical"
                            margin="dense"
                            variant="outlined"
                            label="Maximun"
                            value={specifications.other_angle_maximum_typical}
                            onChange={(e) => handleText(e)}
                            inputProps={{ style: { textTransform: "uppercase", } }}
                          />
                        }
                      </div>

                    </div>

                  </div>
                </>
              }
            </div>
          </div>

        </div>

        <div
          className="row col-lg-12 modal-footer justify-content-end bottom_buttons_section"
          style={{ bottom: 0, background: "white" }}
        >
          <button
            type="button"
            className="drawer_button drawer_text drawer_calculate_button drawer_calculate_adjustment"
            onClick={() => closeDrawerHandler(false)}
          >
            Close
          </button>
          <button
            type="button"
            className="btn next_button"
            name="Back"
            onClick={() => saveSpecsHandler(specStandard, specifications)}
          >
            Save
          </button>
        </div>
      </div>

    </Drawer>
  )
}
