import React, { Component } from "react";
import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import api from "../../../api/api";

import { Popover, Snackbar, Drawer, Button } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import SupplierDemurrageAdd from "./Supplier-Demurrage-Add";
import SupplierDemurrageView from "./Supplier-Demurrage-View";
import BargeSupplierDemurrageUpdate from "./Supplier-Demurrage-Update";
import DeleteConfirmation from "../../common/DeleteConfirmation";
import { pad } from "../../common/common";
import Loader from "../../common/Loader";

export default class BargeSupplierDemurrageList extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      showDrawer: false,
      menuForDemurrage: false,
      supplierDemurrageAdd: false,
      supplierDemurrageView: false,
      supplierDemurrageUpdate: false,
      demurrage_type: "company_to_supplier",
      features: [],
      supplier_demurrages: [],
      demurrageData: null,
      isLoading: true,
      updateDocuments: false,
    };
  }

  componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (
      !(
        features.includes("53") ||
        features.includes("151") ||
        features.includes("97")
      )
    ) {
      window.location.href = "/dashboard";
    }
    this.setState(
      {
        demurrage_type: "company_to_supplier",
      },
      () => this.companyToSupplierDemurrages()
    );
  }

  companyToSupplierDemurrages = () => {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    api
      .get_barge_demurrage_to_suppliers(loginUserID, idToken)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState({
                supplier_demurrages: res.barge_demurrage_to_supplier,
                isLoading: false,
              });
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                successMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  };

  supplierToCompanyDemurrages = () => {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    api
      .get_barge_demurrage_from_suppliers(loginUserID, idToken)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState({
                supplier_demurrages: res.barge_demurrage_from_supplier,
                isLoading: false,
              });
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                successMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  };

  CallbackDrawerClose = (childData) => {
    this.setState({
      showDrawer: childData,

      supplierDemurrageAdd: false,
      supplierDemurrageView: false,
      updateDocuments: false,
    });
  };

  onAddResponse = (value) => {
    this.setState(
      {
        supplierDemurrageAdd: false,
        showDrawer: false,
        updateDocuments: false,
      },
      () => {
        if (this.state.demurrage_type === "company_to_supplier") {
          this.companyToSupplierDemurrages();
        }
        // else if (this.state.demurrage_type === "supplier_to_company") {
        //   this.supplierToCompanyDemurrages();
        // }
      }
    );
  };

  editCallback = (childData) => {
    this.setState({
      showDrawer: true,
      supplierDemurrageAdd: false,
      supplierDemurrageUpdate: true,
      supplierDemurrageView: false,
      updateDocuments: false,
    });
  };

  onUpdateResponse = (value) => {
    if (value) {
      this.setState({
        showDrawer: true,
        supplierDemurrageAdd: false,
        supplierDemurrageView: true,
        supplierDemurrageUpdate: false,
        updateDocuments: false,
      });
    }
  };

  CallbackViewPage = (childData) => {
    this.setState({
      showDrawer: true,
      supplierDemurrageView: true,
      supplierDemurrageUpdate: false,
      updateDocuments: false,
    });
  };

  toLocaleString(value) {
    return value ? Number(value).toLocaleString() : 0;
  }

  deleteDemurrageHandler = (demurrage_id) => {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();

    // if (this.state.demurrage_type === "supplier_to_company") {
    //   api
    //     .delete_barge_demurrage_from_supplier(
    //       loginUserID,
    //       idToken,
    //       demurrage_id
    //     )
    //     .then((response) => {
    //       if (response.status >= 200 && response.status < 300) {
    //         response.json().then((res) => {
    //           if (res.code === "200") {
    //             this.setState(
    //               {
    //                 deleteCosting: false,
    //                 snackBarSuccessOpen: true,
    //                 successMsg: res.message,
    //               },
    //               () => {
    //                 this.supplierToCompanyDemurrages();
    //               }
    //             );
    //           } else if (res.code === "601") {
    //             this.setState({
    //               snackBarErrorOpen: true,
    //               errorMsg: res.message,
    //             });
    //           } else if (res.code === "607") {
    //             window.location.href = "/logout";
    //           } else if (res.code === "650") {
    //             this.setState({
    //               deleteCosting: false,
    //               snackBarErrorOpen: true,
    //               errorMsg: res.message,
    //             });
    //           }
    //         });
    //       }
    //     });
    // }
    // else 
    if (this.state.demurrage_type === "company_to_supplier") {
      api
        .delete_barge_demurrage_to_supplier(loginUserID, idToken, demurrage_id)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                this.setState(
                  {
                    deleteCosting: false,
                    snackBarSuccessOpen: true,
                    successMsg: res.message,
                  },
                  () => {
                    this.companyToSupplierDemurrages();
                  }
                );
              } else if (res.code === "601") {
                this.setState({
                  snackBarErrorOpen: true,
                  errorMsg: res.message,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              } else if (res.code === "650") {
                this.setState({
                  deleteCosting: false,
                  snackBarErrorOpen: true,
                  errorMsg: res.message,
                });
              }
            });
          }
        });
    }
  };

  render() {
    let drawerComponent;
    if (this.state.supplierDemurrageAdd) {
      drawerComponent = (
        <SupplierDemurrageAdd
          demurrage_type={this.state.demurrage_type}
          onAddResponse={this.onAddResponse}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
        />
      );
    }
    if (this.state.supplierDemurrageView) {
      drawerComponent = (
        <SupplierDemurrageView
          demurrageData={this.state.demurrageData}
          demurrage_type={this.state.demurrage_type}
          comboID={this.state.comboID}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.editCallback}
          returnDocumentsHandler={() => {
            this.setState({
              showDrawer: true,
              supplierDemurrageUpdate: true,
              supplierDemurrageView: false,
              supplierDemurrageAdd: false,
              updateDocuments: true,
            });
          }}
        />
      );
    }
    if (this.state.supplierDemurrageUpdate) {
      drawerComponent = (
        <BargeSupplierDemurrageUpdate
          demurrageData={this.state.demurrageData}
          demurrage_type={this.state.demurrage_type}
          comboID={this.state.comboID}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.CallbackViewPage}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }
    return (
      <div id="wraper">
        <div className="content">
          {this.state.isLoading && <Loader />}
          {!this.state.isLoading && (
            <div className="content-header">
              <Drawer
                anchor="right"
                open={this.state.showDrawer}
                variant="temporary"
                elevation={20}
                style={{ overflow: "initial" }}
              >
                <div className="row" style={{ width: 800 }}>
                  {drawerComponent}
                </div>
              </Drawer>
              <Snackbar
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                open={this.state.snackBarErrorOpen}
                onClose={() => this.setState({ snackBarErrorOpen: false })}
                style={{ width: "450px" }}
              >
                <Alert
                  elevation={6}
                  variant="filled"
                  onClose={() => this.setState({ snackBarErrorOpen: false })}
                  severity="error"
                  style={{ width: "100%" }}
                >
                  {this.state.errorMsg}
                </Alert>
              </Snackbar>
              <Snackbar
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                style={{ width: "450px" }}
                open={this.state.snackBarSuccessOpen}
                autoHideDuration={100000}
                onClose={() => this.setState({ snackBarSuccessOpen: false })}
              >
                <Alert
                  elevation={6}
                  variant="filled"
                  onClose={() => this.setState({ snackBarSuccessOpen: false })}
                  severity="success"
                  style={{ width: "100%" }}
                >
                  {this.state.successMsg}
                </Alert>
              </Snackbar>
              <div className="card mt-2 p-2">
                <div className="row">
                  <div className="col-lg-12">
                    <Button
                      className="tab_button"
                      onClick={(e, value) => {
                        this.setState(
                          {
                            demurrage_type: "company_to_supplier",
                          },
                          () => this.companyToSupplierDemurrages()
                        );
                      }}
                      style={{
                        textTransform: "capitalize",
                        margin: "5px 0px",
                        padding: "5px 20px",
                        borderRadius: 35,
                        height: 30,
                        color:
                          this.state.demurrage_type === "company_to_supplier"
                            ? config.themeColor
                            : "#483b34b3",
                        backgroundColor:
                          this.state.demurrage_type === "company_to_supplier"
                            ? "#fff7f2"
                            : "#fff",
                        fontSize: "14px",
                        border: "0px",
                      }}
                    >
                      {config.company} To Supplier
                    </Button>
                    {/* <Button
                      className="tab_button"
                      onClick={(e, value) => {
                        this.setState(
                          {
                            demurrage_type: "supplier_to_company",
                          },
                          () => this.supplierToCompanyDemurrages()
                        );
                      }}
                      style={{
                        textTransform: "capitalize",
                        margin: "5px 0px",
                        padding: "5px 20px",
                        borderRadius: 35,
                        height: 30,
                        color:
                          this.state.demurrage_type === "supplier_to_company"
                            ? config.themeColor
                            : "#483b34b3",
                        backgroundColor:
                          this.state.demurrage_type === "supplier_to_company"
                            ? "#fff7f2"
                            : "#fff",
                        fontSize: "14px",
                        border: "0px",
                      }}
                    >
                      Supplier To {config.company}
                    </Button> */}
                  </div>
                  <div className="col text-right float-sm-left">
                    <button
                      className="header_button header_button_text add_button_adjustment"
                      style={{ width: "auto", marginTop: "25px" }}
                      onClick={(event) =>
                        this.setState({
                          menuForDemurrage: true,
                          anchorEl: event.currentTarget,
                        })
                      }
                    >
                      New Demurrage
                    </button>
                  </div>
                </div>
                {this.state.supplier_demurrages.length > 0 ? (
                  <div className="row">
                    <div className="table-responsive">
                      <table className="table table-sm table-bordered">
                        <thead className="table-header">
                          <tr>
                            <th nowrap="true"> Action </th>
                            <th nowrap="true"> Costing ID </th>
                            <th nowrap="true"> Barge ID </th>
                            <th nowrap="true"> Barge Nomination </th>
                            <th nowrap="true"> Supplier</th>
                            <th nowrap="true">
                              {/* {this.state.demurrage_type === */}
                              {/* "company_to_supplier" */}
                              {/* ? */}
                              Total Payable
                              {/* : "Receivable" */}
                              {/* } */}
                            </th>
                            <th nowrap="true">
                              {/* {this.state.demurrage_type ===
                              "company_to_supplier"
                                ?  */}
                              Paid
                              {/*  : "Received"} */}
                            </th>
                            <th nowrap="true"> Remaining </th>
                          </tr>
                        </thead>
                        {this.state.supplier_demurrages.map((id, idx) => (
                          <tbody key={idx}>
                            <tr>
                              <td className="text-center">
                                <i
                                  className="fa fa-eye mr-2"
                                  aria-hidden="true"
                                  style={{
                                    fontSize: 20,
                                    color: config.themeColor,
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    this.setState({
                                      supplierDemurrageView: true,
                                      comboID: id.id + "$#" + id.barge_id,
                                      showDrawer: true,
                                      demurrageData: id,
                                    })
                                  }
                                ></i>
                                {(this.state.features.includes("129") ||
                                  this.state.features.includes("97")) &&
                                  id.approval_status === "Pending" && (
                                    <i
                                      className="fa fa-trash-o"
                                      style={{
                                        fontSize: 20,
                                        color: config.themeColor,
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        this.setState({
                                          deleteCosting: true,
                                          demurrage_id: id.id,
                                          closeDialog: true,
                                        })
                                      }
                                    ></i>
                                  )}
                              </td>
                              <td nowrap="true">
                                {id.costing_no ? id.costing_no : "-"}
                              </td>
                              <td>{pad(id.barge_id)}</td>
                              <td nowrap="true">
                                {id.barge_nomination
                                  ? id.barge_nomination
                                  : "-"}
                              </td>
                              <td nowrap="true">
                                {id.supplier_name ? id.supplier_name : "-"}
                              </td>
                              <td className="text-right" nowrap="true">
                                {this.toLocaleString(id.total_payable)}
                              </td>
                              <td className="text-right" nowrap="true">
                                {this.toLocaleString(id.paid)}
                              </td>
                              <td className="text-right" nowrap="true">
                                {this.toLocaleString(id.Remaining_amount)}
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </table>
                    </div>
                  </div>
                ) : (
                  <div>No records to display</div>
                )}
              </div>
            </div>
          )}
        </div>
        <Popover
          open={this.state.menuForDemurrage}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          targetorigin={{ horizontal: "left", vertical: "top" }}
          onClose={() =>
            this.setState({ menuForDemurrage: false, showDrawer: false })
          }
        >
          <div className="card">
            {/* <Button
              className="mr-1 mb-3 specs_button"
              onClick={() => {
                this.setState({
                  supplierDemurrageAdd: true,
                  supplierDemurrageView: false,
                  supplierDemurrageUpdate: false,
                  showDrawer: true,
                  menuForDemurrage: false,
                  demurrage_type: "supplier_to_company",
                });
              }}
              //disabled={this.state.allocation_data.some(e => e.allocation_id === b.value)}
              style={{
                width: "100%",
                textTransform: "capitalize",
                borderRadius: 35,
                height: 30,
                color:
                  this.state.demurrage_type === "supplier_to_company"
                    ? config.themeColor
                    : "#483b34b3",
                backgroundColor: "#eee",
                fontSize: "14px",
                border: "0px",
              }}
            >
              Supplier to {config.company}
            </Button> */}
            <Button
              className="mr-1 specs_button"
              onClick={() => {
                this.setState({
                  supplierDemurrageAdd: true,
                  supplierDemurrageView: false,
                  supplierDemurrageUpdate: false,
                  showDrawer: true,
                  menuForDemurrage: false,
                  demurrage_type: "company_to_supplier",
                });
              }}
              style={{
                width: "100%",
                textTransform: "capitalize",
                borderRadius: 35,
                height: 30,
                color:
                  this.state.demurrage_type === "company_to_supplier"
                    ? config.themeColor
                    : "#483b34b3",
                backgroundColor: "#eee",
                fontSize: "14px",
                border: "0px",
              }}
            >
              {config.company} to Supplier
            </Button>
          </div>
        </Popover>
        {/**
         *
         * Delete Payament Postings Conformation Modal
         *
         */}

        {this.state.deleteCosting && (
          <DeleteConfirmation
            open={this.state.deleteCosting}
            close={() => this.setState({ deleteCosting: false })}
            callbackFn={() =>
              this.deleteDemurrageHandler(this.state.demurrage_id)
            }
          />
        )}
      </div>
    );
  }
}
