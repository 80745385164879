import React, { Component } from "react";
import api from "../../../api/api";
import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import { Alert } from "@material-ui/lab";
import {
  TextField,
  Snackbar,
  Checkbox,
  MenuItem,
  Accordion,
  AccordionSummary,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { withStyles } from "@material-ui/core/styles";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { localDateFormate, pad, toLocaleString } from "../../common/common";
import FileUpload from "../../common/FileUpload";
// import AdvanceAdjustments from "../../contracts/AdvanceAdjustments";
import Loader from "../../common/Loader";
import { CurrencyToCompanyCodes, CurrencyCodesEnum } from '../../../common/enums';

const CheckBox = withStyles({
  root: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);
export default class BargeCostingUpdate extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      pph15_account: null,
      barge_base_price: "",
      barge_vat_percent: "10",
      barge_vat_value: "",
      barge_pph15_percent: "1.2",
      barge_pph15_value: "",
      barge_vendor_total_value: "",
      barge_ctc: "",

      total_barge_quantity: 0,
      supplier_dead_freight_value: 0,
      internal_dead_freight_value: 0,
      sdf_base_price: "",
      sdf_vat_percent: "10",
      sdf_vat_value: "",
      sdf_pph15_percent: "1.2",
      sdf_pph15_value: "",
      sdf_vendor_total_value: "",
      sdf_ctc: "",

      idf_base_price: "",
      idf_vat_percent: "10",
      idf_vat_value: "",
      idf_pph15_percent: "1.2",
      idf_pph15_value: "",
      idf_vendor_total_value: "",
      idf_ctc: "",
      internalPPH23Account: "0",
      coalPriceCurrency: "",
      mother_vesselName: "",
      bargingBasePriceCurrency: "",
      bargePurchaseFinancialID: "",

      SDAM_idf_base_price: "",
      SDAM_idf_vat_percent: "",
      SDAM_idf_vat_value: "",
      SDAM_idf_pph15_percent: "",
      SDAM_idf_pph15_value: "",
      SDAM_idf_vendor_total_value: "",
      SDAM_idf_ctc: "",

      SDAM_buyer_dmr_vendor_total_value: "",

      barge_purchase_financial: true,
      barge_quantity: "",
      features: [],
      totalBarges: [],
      bargeList: [],
      selectedPurchaseContractId: "",
      activeStep: 0,
      isLoading: true,
      business_no_drop_value: [],
      barge_group_id: [],
      files: [],
      ppn_date: null,
      advance_adjustments: [
        {
          advance_adjustment: "Per MT",
          vendor_adv_contract_id: "",
          advance_adjustment_value: "",
          advance_adjustment_barge_quantity: "",
          adjustment_per_mt: "",
          adjustment_from: "",
          purchase_contract_id: "",
          pc_adv_remaining_amount: "",
          vendor_contract_no: "",
        },
      ],
      costing_files: [],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    var features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    // if (!(features.indexOf('136') >= 0 || features.indexOf('97') >= 0)) {
    //   window.location.href = '/dashboard';
    // }
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();

    await api.getBusinessNumbers(loginUserID, idToken).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
            if (res.business_numbers) {
              let business_no_drop_value = [];
              for (var i of res.business_numbers) {
                business_no_drop_value.push({
                  name: i.business_no,
                  value: i.id,
                  mother_vessel_name: i.mother_vessel_name,
                });
              }
              this.setState({
                business_no_drop_value: business_no_drop_value,
                //loading: false,
              });
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });

    //Get vendors
    await api.getVendors(loginUserID, idToken, "Active").then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            if (res.vendors) {
              const barge_vendors = [
                <MenuItem value={0} key={0} disabled>
                  Please Select
                </MenuItem>,
              ];
              for (var i of res.vendors) {
                if (i.vendor_type === "Barge") {
                  barge_vendors.push(
                    <MenuItem value={i.id} key={i.id}>
                      {i.vendor_name}
                    </MenuItem>
                  );
                }
              }
              this.setState({
                barge_surveyors: barge_vendors,
                //isLoading: false
              });
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });

    try {
      var barge_pur_fin_id = this.props.bargeCostingID;
    } catch (e) {
      window.location.href = "/barge-list";
    }

    await api
      .get_barge_pur_fin_barging(loginUserID, idToken, barge_pur_fin_id)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.barge_purchase_financial_barge_costing) {
                this.setState(
                  {
                    total_barge_quantity: res
                      .barge_purchase_financial_barge_costing[0].barge_quantity
                      ? Number(
                        res.barge_purchase_financial_barge_costing[0]
                          .barge_quantity
                      )
                      : 0,
                    supplier_dead_freight_value: res
                      .barge_purchase_financial_barge_costing[0].sdf_base_price
                      ? Number(
                        res.barge_purchase_financial_barge_costing[0]
                          .sdf_base_price
                      )
                      : 0,
                    internal_dead_freight_value: res
                      .barge_purchase_financial_barge_costing[0].idf_base_price
                      ? Number(
                        res.barge_purchase_financial_barge_costing[0]
                          .idf_base_price
                      )
                      : 0,
                    supplierDeadFreightValue: res
                      .barge_purchase_financial_barge_costing[0].sdf_base_price
                      ? Number(
                        res.barge_purchase_financial_barge_costing[0]
                          .sdf_base_price
                      )
                      : 0,
                    DeadFreightValue: res
                      .barge_purchase_financial_barge_costing[0].idf_base_price
                      ? Number(
                        res.barge_purchase_financial_barge_costing[0]
                          .idf_base_price
                      )
                      : 0,

                    costing_no:
                      res.barge_purchase_financial_barge_costing[0].costing_no,
                    barge_base_price:
                      res.barge_purchase_financial_barge_costing[0]
                        .barge_base_price,
                    barge_base_price_in_local_currency:
                      res.barge_purchase_financial_barge_costing[0]
                        .barge_base_price_in_local_currency,
                    barge_ctc:
                      res.barge_purchase_financial_barge_costing[0].barge_ctc,
                    barge_pph15_percent:
                      res.barge_purchase_financial_barge_costing[0]
                        .barge_pph15_percent,
                    barge_pph15_value:
                      res.barge_purchase_financial_barge_costing[0]
                        .barge_pph15_value,
                    barge_quantity:
                      res.barge_purchase_financial_barge_costing[0]
                        .barge_quantity,
                    barge_vat_percent:
                      res.barge_purchase_financial_barge_costing[0]
                        .barge_vat_percent,
                    barge_vat_value:
                      res.barge_purchase_financial_barge_costing[0]
                        .barge_vat_value,
                    barge_vendor_total_value:
                      res.barge_purchase_financial_barge_costing[0]
                        .barge_vendor_total_value,
                    currency_xchg_rate:
                      res.barge_purchase_financial_barge_costing[0]
                        .currency_xchg_rate,
                    billing_currency:
                      res.barge_purchase_financial_barge_costing[0]
                        .billing_currency,
                    idf_base_price:
                      res.barge_purchase_financial_barge_costing[0]
                        .idf_base_price,
                    idf_ctc:
                      res.barge_purchase_financial_barge_costing[0].idf_ctc,
                    idf_pph15_percent:
                      res.barge_purchase_financial_barge_costing[0]
                        .idf_pph15_percent,
                    idf_pph15_value:
                      res.barge_purchase_financial_barge_costing[0]
                        .idf_pph15_value,
                    idf_vat_percent:
                      res.barge_purchase_financial_barge_costing[0]
                        .idf_vat_percent,
                    idf_vat_value:
                      res.barge_purchase_financial_barge_costing[0]
                        .idf_vat_value,
                    idf_vendor_total_value:
                      res.barge_purchase_financial_barge_costing[0]
                        .idf_vendor_total_value,
                    pph15_account:
                      res.barge_purchase_financial_barge_costing[0]
                        .pph15_account,
                    sdf_base_price:
                      res.barge_purchase_financial_barge_costing[0]
                        .sdf_base_price,
                    sdf_ctc:
                      res.barge_purchase_financial_barge_costing[0].sdf_ctc,
                    sdf_pph15_percent:
                      res.barge_purchase_financial_barge_costing[0]
                        .sdf_pph15_percent,
                    sdf_pph15_value:
                      res.barge_purchase_financial_barge_costing[0]
                        .sdf_pph15_value,
                    sdf_vat_percent:
                      res.barge_purchase_financial_barge_costing[0]
                        .sdf_vat_percent,
                    sdf_vat_value:
                      res.barge_purchase_financial_barge_costing[0]
                        .sdf_vat_value,
                    sdf_vendor_total_value:
                      res.barge_purchase_financial_barge_costing[0]
                        .sdf_vendor_total_value,
                    bargeList: res.barge_purchase_financial_barge_costing[0]
                      .barge_id
                      ? res.barge_purchase_financial_barge_costing[0].barge_id.split(
                        ","
                      )
                      : [],
                    barge_group_id: res
                      .barge_purchase_financial_barge_costing[0].barge_id
                      ? res.barge_purchase_financial_barge_costing[0].barge_id.split(
                        ","
                      )
                      : [],
                    files: res.barge_purchase_financial_barge_costing[0].files
                      ? res.barge_purchase_financial_barge_costing[0].files
                      : [],
                    invoice_date: res.barge_purchase_financial_barge_costing[0]
                      .invoice_date
                      ? res.barge_purchase_financial_barge_costing[0]
                        .invoice_date
                      : null,
                    ppn: res.barge_purchase_financial_barge_costing[0].ppn
                      ? res.barge_purchase_financial_barge_costing[0].ppn
                      : "",
                    ppn_date: res.barge_purchase_financial_barge_costing[0]
                      .ppn_date
                      ? res.barge_purchase_financial_barge_costing[0].ppn_date
                      : null,
                    invoice_no: res.barge_purchase_financial_barge_costing[0]
                      .invoice_no
                      ? res.barge_purchase_financial_barge_costing[0].invoice_no
                      : "",
                    invoice_file: res.barge_purchase_financial_barge_costing[0]
                      .invoice_file
                      ? res.barge_purchase_financial_barge_costing[0]
                        .invoice_file
                      : "",
                    approval_status:
                      res.barge_purchase_financial_barge_costing[0]
                        .approval_status,
                    advance_adjustments:
                      res.barge_purchase_financial_barge_costing[0]
                        .advance_adjustments,

                    //isLoading: false
                  },
                  () => {
                    if (this.state.barge_group_id.length > 0) {
                      let barge_id = this.state.barge_group_id[0];
                      api
                        .get_barge(loginUserID, idToken, barge_id)
                        .then((response) => {
                          if (response.status >= 200 && response.status < 300) {
                            response.json().then((barge_res) => {
                              if (barge_res.code === "200") {
                                if (barge_res.barges) {
                                  let barging_base_price = 0;
                                  let barging_base_price_currency = null;
                                  if (
                                    barge_res.barges.barging_type === "Per MT"
                                  ) {
                                    barging_base_price =
                                      barge_res.barges.barging_base_price;
                                    barging_base_price_currency =
                                      barge_res.barges
                                        .barging_base_price_currency;
                                  } else if (
                                    barge_res.barges.barging_type === "Lumpsum"
                                  ) {
                                    barging_base_price =
                                      barge_res.barges.lumpsum_price;
                                    barging_base_price_currency =
                                      barge_res.barges.lumpsum_price_currency;
                                  }
                                  this.setState({
                                    barging_base_price: barging_base_price,
                                    bargeBasePrice: barging_base_price,
                                    barging_type: barge_res.barges.barging_type,
                                    barging_base_price_currency:
                                      barging_base_price_currency,
                                    vat_applicability: barge_res.barges
                                      .vat_applicability
                                      ? barge_res.barges.vat_applicability
                                      : "Inclusive",
                                    isLoading: false,
                                  });
                                }
                              } else if (barge_res.code === "601") {
                                this.setState({
                                  snackBarErrorOpen: true,
                                  errorMsg: res.message,
                                  isLoading: false,
                                });
                              } else if (barge_res.code === "607") {
                                window.location.href = "/logout";
                              }
                            });
                          }
                        });
                    } else {
                      this.setState({ isLoading: false });
                    }
                  }
                );
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });

    let purchase_contract_id = "";
    let barge_vendor =
      this.state.barge_vendor === "0" ? "" : this.state.barge_vendor;
    let financial_type = "barge_purchase_financial_barge_costing";
    let business_no = this.state.business_no_id;
    let barge_name = this.state.barge_nomination;
    await api
      .get_barges(
        loginUserID,
        idToken,
        financial_type,
        business_no,
        barge_vendor,
        purchase_contract_id,
        barge_name
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.barges) {
                const filter_not_costing_barges = res.barges.filter(
                  (e) =>
                    (e.barge_costing === null ||
                      e.barge_costing === barge_pur_fin_id) &&
                    e.status === "Active" &&
                    e.purchase_type === "FOB Barge"
                );
                if (filter_not_costing_barges.length === 0) {
                  this.setState({
                    snackBarErrorOpen: true,
                    errorMsg: "No records to display",
                  });
                }
                this.setState({
                  barges: res.barges,
                  totalBarges: filter_not_costing_barges,
                  isLoading: false,
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });

    document.title =
      config.documentTitle + "Barge Purchase Financial Barge Costing";
  }

  nextStepHandler = () => {
    if (this.state.activeStep === 0) {
      if (this.state.bargeList.length <= 0) {
        this.setState({
          errorMsg: "Please Select Barge",
          snackBarErrorOpen: true,
        });
        return;
      }
    }
    this.setState({
      activeStep: this.state.activeStep + 1,
    });
  };

  previousStepHandler = () => {
    this.setState({
      activeStep: this.state.activeStep - 1,
    });
  };

  onBargesCheck = (bargeId, checked, barge) => {
    var bargeQuantity = "";
    if (
      barge.barge_quantity_in_mt === null ||
      barge.barge_quantity_in_mt <= 0
    ) {
      bargeQuantity = barge.barge_planned_quantity_in_mt;
    } else {
      bargeQuantity = barge.barge_quantity_in_mt;
    }
    var supplierDeadFreightValue = barge.supplier_dead_freight_value
      ? Number(barge.supplier_dead_freight_value)
      : 0;
    var DeadFreightValue = barge.dead_freight ? Number(barge.dead_freight) : 0;
    if (!!checked) {
      let bargeList = [...this.state.bargeList, bargeId];
      this.setState(
        {
          bargeList: bargeList,
          total_barge_quantity: (
            Number(this.state.total_barge_quantity) + Number(bargeQuantity)
          ).toFixed(3),
          barge_quantity: (
            Number(this.state.total_barge_quantity) + Number(bargeQuantity)
          ).toFixed(3),
          supplier_dead_freight_value: (
            Number(this.state.supplier_dead_freight_value) +
            Number(supplierDeadFreightValue)
          ).toFixed(3),
          dead_freight: (
            Number(this.state.supplier_dead_freight_value) +
            Number(supplierDeadFreightValue)
          ).toFixed(3),
          supplierDeadFreightValue: (
            Number(this.state.supplier_dead_freight_value) +
            Number(supplierDeadFreightValue)
          ).toFixed(3),
          DeadFreightValue: (
            Number(this.state.dead_freight) + Number(DeadFreightValue)
          ).toFixed(3),
          isLoading: true,
        },
        () => {
          if (this.state.bargeList.length === 1) {
            this.callingAsync(barge);
          } else if (this.state.bargeList.length > 1) {
            this.setState({ isLoading: false });
            this.bargingCalculation();
          } else {
            this.setState({
              isLoading: false,
            });
          }
        }
      );
    } else {
      let data = this.state.bargeList;
      if (data.indexOf(bargeId) >= 0) {
        let idx = data.indexOf(bargeId);
        data.splice(idx, 1);
      }
      this.setState(
        {
          bargeList: data,
          total_barge_quantity: (
            Number(this.state.barge_quantity ? this.state.barge_quantity : 0) -
            Number(bargeQuantity)
          ).toFixed(3),
          barge_quantity: (
            Number(this.state.barge_quantity ? this.state.barge_quantity : 0) -
            Number(bargeQuantity)
          ).toFixed(3),
          supplier_dead_freight_value: (
            Number(
              this.state.supplierDeadFreightValue
                ? this.state.supplierDeadFreightValue
                : 0
            ) - Number(supplierDeadFreightValue)
          ).toFixed(3),
          dead_freight: (
            Number(
              this.state.supplierDeadFreightValue
                ? this.state.supplierDeadFreightValue
                : 0
            ) - Number(supplierDeadFreightValue)
          ).toFixed(3),
          supplierDeadFreightValue: (
            Number(
              this.state.supplierDeadFreightValue
                ? this.state.supplierDeadFreightValue
                : 0
            ) - Number(supplierDeadFreightValue)
          ).toFixed(3),
          DeadFreightValue: (
            Number(
              this.state.DeadFreightValue ? this.state.DeadFreightValue : 0
            ) - Number(DeadFreightValue)
          ).toFixed(3),
        },
        () => {
          this.bargingCalculation();
        }
      );
    }
  };

  async callingAsync(barge_data) {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();

    const {
      id,
      vat_incl_or_excl,
    } = barge_data || {};
    if (barge_data) {
      api
        .get_purchase_contract_data_by_barge_id(loginUserID, idToken, id)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                let barge_quantity = "";
                if (res.purchase_contract_data) {
                  barge_quantity = barge_data.barge_quantity_in_mt;
                  if (barge_quantity === null || Number(barge_quantity) <= 0) {
                    barge_quantity = barge_data.barge_planned_quantity_in_mt;
                  } else if (
                    barge_data.barge_planned_quantity_in_mt === null ||
                    Number(barge_data.barge_planned_quantity_in_mt) <= 0
                  ) {
                    barge_quantity = barge_data.barge_quantity_in_mt;
                  }

                  var bargeBasePrice = 0;
                  var barging_base_price_currency = null;

                  if (barge_data.barging_type === "Per MT") {
                    bargeBasePrice = barge_data.barging_base_price;
                    barging_base_price_currency =
                      barge_data.barging_base_price_currency;
                  } else if (barge_data.barging_type === "Lumpsum") {
                    bargeBasePrice = barge_data.lumpsum_price;
                    barging_base_price_currency =
                      barge_data.lumpsum_price_currency;
                  }
                  const vat_applicability = barge_data?.vat_applicability ? barge_data?.vat_applicability : "Inclusive";
                  this.setState(
                    {
                      vat_incl_or_excl: vat_incl_or_excl,
                      vat_applicability: vat_applicability,
                      pph15_account:
                        (!CurrencyToCompanyCodes[CurrencyCodesEnum.USD].includes(config.company))
                          ? vat_applicability === "Exclusive"
                            ? "Supplier"
                            : "SDAM"
                          : null,
                      barging_type: barge_data.barging_type,
                      operationalBargeQuantity: barge_quantity,
                      bargeBasePrice: bargeBasePrice,
                      barging_base_price_currency: barging_base_price_currency,

                      purchase_type: res.purchase_contract_data.purchase_type,
                      isLoading: false,
                    },
                    () => this.bargingCalculation()
                  );
                } else {
                  alert(
                    "Unexpected error occured. Please contact administrator."
                  );
                }
              }
            });
          }
        });
    }
  }

  bargingCalculation = () => {
    let {
      vat_applicability = "Inclusive",
      barging_type = "Lumpsum",
      barge_quantity = 1,
      bargeBasePrice = 1,
      currency_xchg_rate = 1,
      supplierDeadFreightValue = 1,
      DeadFreightValue = 1,
      barging_base_price_currency = CurrencyCodesEnum.USD,
      barge_vat_percent = 10,
      idf_vat_percent = 10,
      sdf_vat_percent = 10
    } = this.state;

    const { company } = config;
    const billing_currency = barging_base_price_currency;
    const exchange_rate = currency_xchg_rate
      ? parseFloat(currency_xchg_rate)
      : 1;

    let qty = parseFloat(barge_quantity).toFixed(2);
    let price = parseFloat(bargeBasePrice).toFixed(2);
    let deadFreightValue = DeadFreightValue
      ? parseFloat(DeadFreightValue) - qty
      : 0;
    if (deadFreightValue <= 0) {
      deadFreightValue = 0;
    }
    let supDeadFreightValue = supplierDeadFreightValue;

    if (
      (CurrencyToCompanyCodes[CurrencyCodesEnum.USD].includes(company)) &&
      billing_currency !== CurrencyCodesEnum.USD
    ) {
      price = (parseFloat(price) / parseFloat(exchange_rate)).toFixed(2);
      deadFreightValue = (
        parseFloat(DeadFreightValue) / parseFloat(exchange_rate)
      ).toFixed(2);
      supDeadFreightValue = (
        parseFloat(supplierDeadFreightValue) / parseFloat(exchange_rate)
      ).toFixed(2);
    }
    if (
      (!CurrencyToCompanyCodes[CurrencyCodesEnum.USD].includes(company)) &&
      billing_currency !== CurrencyCodesEnum.IDR
    ) {
      price = (parseFloat(price) * parseFloat(exchange_rate)).toFixed(2);
      deadFreightValue = (
        parseFloat(DeadFreightValue) * parseFloat(exchange_rate)
      ).toFixed(2);
      supDeadFreightValue = (
        parseFloat(supplierDeadFreightValue) * parseFloat(exchange_rate)
      ).toFixed(2);
    }
    let total_price = (parseFloat(qty) * parseFloat(price)).toFixed(2);
    let barge_base_price_in_local_currency = price;

    if (barging_type === "Lumpsum") {
      total_price = price;
      barge_base_price_in_local_currency = price;
    }

    let barge_base_price = total_price;
    let barge_vat_value = 0;
    let barge_pph15_percent = 1.2;
    let barge_pph15_value = 0;
    let barge_vendor_total_value = 0;
    let barge_ctc = 0;

    let idf_base_price = deadFreightValue;
    let idf_vat_value = 0;
    let idf_pph15_percent = 1.2;
    let idf_pph15_value = 0;
    let idf_vendor_total_value = 0;
    let idf_ctc = 0;

    let sdf_base_price = supDeadFreightValue;
    let sdf_vat_value = 0;
    let sdf_pph15_percent = 1.2;
    let sdf_pph15_value = 0;
    let sdf_vendor_total_value = 0;
    let sdf_ctc = 0;


    let inclusiveVatConstraint = 100 + Number(barge_vat_percent);
    let exclusiveVatConstraint = 100 - sdf_pph15_percent;

    if (vat_applicability === "Inclusive") {
      barge_base_price = parseFloat((total_price * 100) / inclusiveVatConstraint).toFixed(2);
      barge_vat_value = (
        (Number(barge_base_price) * barge_vat_percent) /
        100
      ).toFixed(2);
      barge_pph15_value = Number(
        (barge_base_price * barge_pph15_percent) / 100
      ).toFixed(2);
      if (CurrencyToCompanyCodes[CurrencyCodesEnum.USD].includes(config.company)) {
        barge_pph15_value = 0;
      }
      barge_vendor_total_value = (
        parseFloat(barge_base_price) +
        parseFloat(barge_vat_value) -
        parseFloat(barge_pph15_value)
      ).toFixed(2);
      barge_ctc = (
        parseFloat(barge_base_price) + parseFloat(barge_vat_value)
      ).toFixed(2);

      // Dead Freight
      idf_base_price = parseFloat((idf_base_price * 100) / inclusiveVatConstraint).toFixed(2);
      idf_vat_value = (
        (Number(idf_base_price) * idf_vat_percent) /
        100
      ).toFixed(2);
      idf_pph15_value = Number((idf_base_price * idf_base_price) / 100).toFixed(
        2
      );
      if (CurrencyToCompanyCodes[CurrencyCodesEnum.USD].includes(config.company)) {
        idf_pph15_value = 0;
      }
      idf_vendor_total_value = (
        parseFloat(idf_base_price) +
        parseFloat(idf_vat_value) -
        parseFloat(idf_pph15_value)
      ).toFixed(2);
      idf_ctc = (
        parseFloat(idf_base_price) + parseFloat(idf_vat_value)
      ).toFixed(2);

      // Supplier

      sdf_base_price = parseFloat((sdf_base_price * 100) / inclusiveVatConstraint).toFixed(2);
      sdf_vat_value = (
        (Number(sdf_base_price) * sdf_vat_percent) /
        100
      ).toFixed(2);
      sdf_pph15_value = Number((sdf_base_price * sdf_base_price) / 100).toFixed(
        2
      );
      if (CurrencyToCompanyCodes[CurrencyCodesEnum.USD].includes(config.company)) {
        sdf_pph15_value = 0;
      }
      sdf_vendor_total_value = (
        parseFloat(sdf_base_price) +
        parseFloat(sdf_vat_value) -
        parseFloat(sdf_pph15_value)
      ).toFixed(2);
      sdf_ctc = (
        parseFloat(sdf_base_price) + parseFloat(sdf_vat_value)
      ).toFixed(2);
    } else if (vat_applicability === "Exclusive") {
      barge_base_price = parseFloat((total_price * 100) / exclusiveVatConstraint).toFixed(2);
      if (CurrencyToCompanyCodes[CurrencyCodesEnum.USD].includes(config.company)) {
        barge_base_price = total_price;
      }
      barge_vat_percent = 0;
      barge_vat_value = 0;
      barge_pph15_value = Number(
        (barge_base_price * barge_pph15_percent) / 100
      ).toFixed(2);
      if (CurrencyToCompanyCodes[CurrencyCodesEnum.USD].includes(config.company)) {
        barge_pph15_value = 0;
      }
      barge_vendor_total_value = (
        parseFloat(barge_base_price) +
        parseFloat(barge_vat_value) -
        parseFloat(barge_pph15_value)
      ).toFixed(2);
      barge_ctc = (
        parseFloat(barge_base_price) + parseFloat(barge_vat_value)
      ).toFixed(2);

      // Dead Freight
      idf_base_price = parseFloat((idf_base_price * 100) / inclusiveVatConstraint).toFixed(2);
      idf_vat_percent = 0;
      idf_vat_value = 0;
      idf_pph15_value = Number((idf_base_price * idf_base_price) / 100).toFixed(
        2
      );
      if (CurrencyToCompanyCodes[CurrencyCodesEnum.USD].includes(config.company)) {
        idf_pph15_value = 0;
      }
      idf_vendor_total_value = (
        parseFloat(idf_base_price) +
        parseFloat(idf_vat_value) -
        parseFloat(idf_pph15_value)
      ).toFixed(2);
      idf_ctc = (
        parseFloat(idf_base_price) + parseFloat(idf_vat_value)
      ).toFixed(2);

      // Supplier

      sdf_base_price = parseFloat((sdf_base_price * 100) / inclusiveVatConstraint).toFixed(2);
      sdf_vat_percent = 0;
      sdf_vat_value = 0;
      sdf_pph15_value = Number((sdf_base_price * sdf_base_price) / 100).toFixed(
        2
      );
      if (CurrencyToCompanyCodes[CurrencyCodesEnum.USD].includes(config.company)) {
        sdf_pph15_value = 0;
      }
      sdf_vendor_total_value = (
        parseFloat(sdf_base_price) +
        parseFloat(sdf_vat_value) -
        parseFloat(sdf_pph15_value)
      ).toFixed(2);
      sdf_ctc = (
        parseFloat(sdf_base_price) + parseFloat(sdf_vat_value)
      ).toFixed(2);
    }

    this.setState({
      barge_base_price_in_local_currency: barge_base_price_in_local_currency,

      // barging
      barge_base_price: barge_base_price,
      barge_vat_percent: barge_vat_percent,
      barge_vat_value: barge_vat_value,
      barge_pph15_percent: barge_pph15_percent,
      barge_pph15_value: barge_pph15_value,
      barge_vendor_total_value: barge_vendor_total_value,
      barge_ctc: barge_ctc,

      //supplier

      sdf_base_price: sdf_base_price ? sdf_base_price : "",
      sdf_vat_percent: sdf_vat_percent,
      sdf_vat_value: sdf_vat_value,
      sdf_pph15_percent: sdf_pph15_percent,
      sdf_pph15_value: sdf_pph15_value,
      sdf_vendor_total_value: sdf_vendor_total_value,
      sdf_ctc: sdf_ctc ? sdf_ctc : "",

      // Internal Dead freight state values
      idf_base_price: idf_base_price,
      idf_vat_percent: idf_vat_percent,
      idf_vat_value: idf_vat_value,
      idf_pph15_percent: idf_pph15_percent,
      idf_pph15_value: idf_pph15_value,
      idf_vendor_total_value: idf_vendor_total_value,
      idf_ctc: idf_ctc,
    });
  };

  onSelectAllBarges = (checked) => {
    this.setState({ isLoading: true });
    let totalBarges = this.state.totalBarges;
    if (checked) {
      if (totalBarges.length > 0) {
        let bargeList = [];
        let all_barge_quantity = 0;
        let supplier_dead_freight_value = 0;
        let dead_freight = 0;
        for (var i of totalBarges) {
          bargeList.push(i.id);
          if (i.barge_quantity_in_mt === null) {
            all_barge_quantity += Number(
              i.barge_planned_quantity_in_mt
                ? i.barge_planned_quantity_in_mt
                : 0
            );
          } else {
            all_barge_quantity += Number(
              i.barge_quantity_in_mt ? i.barge_quantity_in_mt : 0
            );
          }
          supplier_dead_freight_value += Number(
            i.supplier_dead_freight_value ? i.supplier_dead_freight_value : 0
          ).toFixed(2);
          dead_freight += Number(i.dead_freight ? i.dead_freight : 0).toFixed(
            2
          );
        }

        let bargeBasePrice = 0;
        let barging_base_price_currency = null;
        if (totalBarges.length > 0) {
          if (totalBarges[0].barging_type === "Per MT") {
            bargeBasePrice = totalBarges[0].barging_base_price;
            barging_base_price_currency =
              totalBarges[0].barging_base_price_currency;
          } else if (totalBarges[0].barging_type === "Lumpsum") {
            bargeBasePrice = totalBarges[0].lumpsum_price;
            barging_base_price_currency = totalBarges[0].lumpsum_price_currency;
          }
        }
        this.setState(
          {
            bargeList: bargeList,
            barge_quantity: all_barge_quantity ? all_barge_quantity : "",
            operationalBargeQuantity: all_barge_quantity,
            total_barge_quantity: Number(all_barge_quantity).toFixed(2),
            supplier_dead_freight_value: supplier_dead_freight_value,
            dead_freight: dead_freight,

            bargeBasePrice: bargeBasePrice,
            barging_base_price_currency: barging_base_price_currency,
            supplierDeadFreightValue: supplier_dead_freight_value,
            DeadFreightValue: dead_freight,
            isLoading: false,
          },
          () => this.bargingCalculation()
        );
      }
    } else {
      this.setState(
        {
          bargeList: [],
          barge_quantity: "",
          bargeBasePrice: "",
          barging_base_price_currency: null,
          purchase_type: "",
          isLoading: false,
        },
        () => this.bargingCalculation()
      );
    }
  };

  submitHandler = (e) => {
    this.setState({ submitLoading: true });
    let {
      invoice_no,
      invoice_date,
      ppn,
      ppn_date,
      barge_quantity,
      pph15_account,
      barge_base_price,
      barge_vat_percent,
      barge_vat_value,
      barge_pph15_percent,
      barge_pph15_value,
      barge_vendor_total_value,
      barge_ctc,
      sdf_base_price,
      sdf_vat_percent,
      sdf_vat_value,
      sdf_vendor_total_value,
      sdf_pph15_percent,
      sdf_ctc,
      idf_base_price,
      idf_vat_percent,
      idf_vat_value,
      idf_pph15_percent,
      idf_pph15_value,
      idf_vendor_total_value,
      idf_ctc,
      billing_currency,
      advance_adjustments,
    } = this.state;

    let currency_xchg_rate = this.state.currency_xchg_rate;
    let barge_base_price_in_local_currency =
      this.state.barge_base_price_in_local_currency;

    if (
      ((CurrencyToCompanyCodes[CurrencyCodesEnum.USD].includes(config.company)) &&
        this.state.barge_base_price_in_local_currency === CurrencyCodesEnum.USD &&
        this.state.barging_base_price_currency === null) ||
      ((!CurrencyToCompanyCodes[CurrencyCodesEnum.USD].includes(config.company)) &&
        this.state.barge_base_price_in_local_currency === CurrencyCodesEnum.IDR &&
        this.state.barging_base_price_currency === null)
    ) {
      currency_xchg_rate = 1;
      barge_base_price_in_local_currency = 1;
    }

    /**
     *  Checking Mandatory files
     */
    var missing_fields = 0;
    let files_missing = 0;
    var files_data = this.state.files;
    for (var obj of files_data) {
      if (obj.file_type !== "" || obj.file_content !== "") {
        if (obj.file_content !== "") {
          if (obj.file_description === "") {
            missing_fields++;
            obj.file_description_error = true;
          } else {
            obj.file_description = obj.file_description.toUpperCase();
          }
        } else if (obj.file_description !== "") {
          if (obj.file_content === "") {
            files_missing++;
          }
        }
      }
    }
    this.setState({
      files: files_data,
    });

    if (missing_fields > 0) {
      this.setState({
        snackBarErrorOpen: true,
        submitLoading: false,
        errorMsg: "Please fill mandatory fields.",
      });
      return;
    }
    if (files_missing > 0) {
      this.setState({
        snackBarErrorOpen: true,
        submitLoading: false,
        errorMsg: "Please Select file.",
      });
      return;
    }

    var existing_file_ids = [];
    const existing_files = files_data.filter((e) => e.id);
    for (var j of existing_files) {
      existing_file_ids.push(j.id);
    }
    const new_files = files_data.filter((e) => !e.id);
    const content_files = new_files.filter((e) => e.file_content !== "");
    // Add barge purchase financial api
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    let barge_id = this.state.bargeList.toString();
    const costing_files = this.state.costing_files?.filter(
      (e) => e.file_content !== "" && e.column === "invoice_file"
    );

    const barge_pur_fin_id = this.props.bargeCostingID;
    if (this.state.bargeList.length > 0) {
      api
        .update_barge_pur_fin_barging(
          loginUserID,
          idToken,
          barge_id,
          barge_pur_fin_id,
          invoice_no,
          invoice_date,
          ppn,
          ppn_date,
          barge_quantity,
          pph15_account,
          barge_base_price,
          barge_vat_percent,
          barge_vat_value,
          barge_pph15_percent,
          barge_pph15_value,
          barge_vendor_total_value,
          billing_currency,
          currency_xchg_rate,
          barge_base_price_in_local_currency,
          barge_ctc,
          sdf_base_price,
          sdf_vat_percent,
          sdf_vat_value,
          sdf_vendor_total_value,
          sdf_pph15_percent,
          sdf_ctc,
          idf_base_price,
          idf_vat_percent,
          idf_vat_value,
          idf_pph15_percent,
          idf_pph15_value,
          idf_vendor_total_value,
          idf_ctc,
          advance_adjustments,
          existing_file_ids,
          content_files,
          costing_files
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              this.props.onUpdateCosting(res.code);
              if (res.code === "200") {
                this.setState(
                  {
                    snackBarSuccessOpen: true,
                    successMsg: "Costing updated successfully",
                    errorMsg: "",
                    submitLoading: false,
                  },
                  () => this.props.onUpdateResponse(res.code)
                );
              } else if (res.code === "601") {
                this.setState({
                  snackBarErrorOpen: true,
                  isLoading: false,
                  errorMsg: res.message,
                  successMsg: "",
                  submitLoading: false,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              } else if (res.code === "624") {
                this.setState({
                  isLoading: false,
                  errorMsg: res.message,
                  successMsg: "",
                  submitLoading: false,
                });
              }
            });
          }
        });
    } else {
      alert("Please select atleast one barge to submit");
      this.setState({
        isLoading: false,
        errorMsg: "Please select atleast one barge to submit",
        successMsg: "",
        snackBarErrorOpen: true,
        submitLoading: false,
      });
    }
  };

  searchBargesHandler() {
    // const barge_pur_fin_id = decodeURIComponent(window.atob(this.props.match.params.bargeCostingID));
    const barge_pur_fin_id = this.props.bargeCostingID;

    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    let purchase_contract_id = "";
    let barge_vendor =
      this.state.barge_vendor === "0" ? "" : this.state.barge_vendor;
    let financial_type = "barge_purchase_financial_barge_costing";
    let barge_name = "";
    let business_no = this.state.business_no_id;
    api
      .get_barges(
        loginUserID,
        idToken,
        financial_type,
        business_no,
        barge_vendor,
        purchase_contract_id,
        barge_name
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.barges) {
                const filter_not_costing_barges = res.barges.filter(
                  (e) =>
                    (e.barge_costing === null ||
                      e.barge_costing === barge_pur_fin_id) &&
                    e.status === "Active" &&
                    e.purchase_type === "FOB Barge"
                );
                if (filter_not_costing_barges.length === 0) {
                  this.setState({
                    snackBarErrorOpen: true,
                    errorMsg: "No records to display",
                  });
                }
                this.setState({
                  barges: res.barges,
                  totalBarges: filter_not_costing_barges,
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  }

  render() {
    const quan = /^\d*(\.\d{0,3})?$/;
    const re = /^\d*(\.\d{0,10})?$/;
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div className="container bg-white p-0" style={{ minHeight: "100vh" }}>
          <div className="clearfix content-header border-bottom">
            <div className="row">
              <div className="col-sm text-left pl-0">
                <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                  {`Barge Costing - ${this.state?.costing_no}`}

                  <span
                    className={
                      this.state.approval_status === "Pending"
                        ? "type_2"
                        : "type_1"
                    }
                    style={{ padding: 8, marginLeft: 16 }}
                  >
                    {this.state.approval_status}
                  </span>
                </h4>
              </div>
            </div>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <div className="drawer-section">
            <div className="card">
              {!this.props.updateDocuments && (
                <div className=" w-100 ">
                  <div style={{ width: "70%" }}>
                    <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                      Update Costing details
                    </h5>
                  </div>

                  <div className="row mb-1 p-2 w-100">
                    <div className="col-lg-6 p-0">
                      <Autocomplete
                        multiple
                        options={this.state.business_no_drop_value}
                        getOptionLabel={(option) => option.mother_vessel_name}
                        renderOption={(option, { selected }) => (
                          <React.Fragment>
                            <CheckBox
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            <div className="col-lg p-0 m-0">
                              <label className="form_label mb-0">
                                {option.mother_vessel_name}
                              </label>
                              <label
                                className="contract_display_header_value m-0 pl-0"
                                style={{ fontSize: 10, paddingLeft: 5 }}
                              >
                                {"( " + option.name + " )"}
                              </label>
                            </div>
                          </React.Fragment>
                        )}
                        onChange={(event, value) => {
                          if (value) {
                            var data = [];
                            for (var i of value) {
                              data.push(i.value);
                            }
                            //var floating_crane_vendor = data.toString();
                            this.setState({
                              business_no_id: data,
                              business_no_value: value,
                            });
                          } else {
                            this.setState({
                              business_no_id: [],
                              business_no_value: { name: "", value: "" },
                            });
                          }
                        }}
                        disableCloseOnSelect
                        name="Business_Number"
                        size="small"
                        value={this.state.business_no_value}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Business No"
                            variant="outlined"
                            style={{ top: 8 }}
                            fullWidth
                          />
                        )}
                        style={{ display: "contents" }}
                      />
                    </div>
                    <div className="col-lg-3 pr-0">
                      <TextField
                        name="barge_vendor"
                        margin="dense"
                        variant="outlined"
                        value={this.state.barge_vendor}
                        fullWidth
                        label="Barge Vendor"
                        InputProps={{
                          style: {
                            fontFamily: "Poppins",
                            fontSize: "14px",
                          },
                        }}
                        select
                        error={this.state.barge_vendor_error}
                        onChange={(e) => {
                          this.setState({
                            barge_vendor: e.target.value,
                            barge_vendor_error: false,
                          });
                        }}
                      >
                        {this.state.barge_surveyors}
                      </TextField>
                    </div>
                    <div className="col-lg-3 pr-0">
                      <TextField
                        name="barge_name"
                        margin="dense"
                        variant="outlined"
                        placeholder="Barge Name"
                        fullWidth
                        onChange={(e) => {
                          this.setState({
                            barge_nomination: e.target.value,
                            barge_name_error: false,
                          });
                        }}
                        error={this.state.barge_name_error}
                        inputProps={{
                          style: {
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            textTransform: "uppercase",
                          },
                        }}
                      />
                    </div>
                    {/* <div className='col-lg-3 pr-0'>
                  <TextField
                    name='jetty_name'
                    margin='dense'
                    variant='outlined'
                    placeholder='Jetty Name'
                    fullWidth
                    onChange={(e) => {
                      this.setState({
                        jetty_name: e.target.value
                      })

                    }}
                    inputProps={{
                      style: {
                        fontFamily: 'Poppins',
                        fontSize: '14px',
                        textTransform: 'uppercase'
                      }
                    }}
                  />
                </div> */}
                    <div className="col-lg-2 pr-0 pt-2">
                      <button
                        className="btn btn-sm btn-info"
                        style={{
                          width: "auto",
                          marginTop: "5px",
                          border: "none",
                          backgroundColor: config.themeColor,
                        }}
                        onClick={() => this.searchBargesHandler()}
                      >
                        <i className="fa fa-search mr-1" aria-hidden="true" />
                        Search
                      </button>
                    </div>
                  </div>
                  <Accordion
                    expanded={this.state.expanded}
                    className="mt-0 mb-2"
                    style={{
                      border: "#f7f3f0 1px solid",
                      borderTopLeftRadius: "4px",
                      borderTopRightRadius: "4px",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon style={{ color: "#483b34" }} />
                      }
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      style={{
                        height: 15,
                        backgroundColor: "#f7f3f0",
                        borderTopLeftRadius: "3px",
                        borderTopRightRadius: "3px",
                      }}
                      className="text-white pl-1"
                      onClick={() =>
                        this.setState({ expanded: !this.state.expanded })
                      }
                    >
                      <h5 className="mb-0 pl-1">Select Barges</h5>
                    </AccordionSummary>
                    <div className="row">
                      <div className="table-responsive">
                        <table className="table table-borderless border-0">
                          <thead className="border-0">
                            <tr>
                              <th
                                className="p-2 text-center border-0"
                                nowrap="true"
                              >
                                <CheckBox
                                  //value={this.state.totalBarges}
                                  color="primary"
                                  //checked={this.state.totalBarges.length !== 0 && (this.state.bargeList.length ===
                                  // this.state.totalBarges.filter(e => e.barge_pur_fin_id === null).length)}
                                  //disabled={this.state.selectedPurchaseContractId === "" || p.purchase_contract_id !== this.state.selectedPurchaseContractId}
                                  // onChange={this.onBargesCheck}
                                  className="p-0"
                                  onChange={(e) => {
                                    this.onSelectAllBarges(e.target.checked);
                                  }}
                                />
                              </th>
                              <th
                                nowrap="true"
                                style={{ fontSize: 16 }}
                                className="text-left table_header_barge_label text-uppercase border-0"
                              >
                                Barge ID
                              </th>
                              <th
                                nowrap="true"
                                style={{ fontSize: 16 }}
                                className="text-left table_header_barge_label text-uppercase border-0"
                              >
                                Barge Vendor Name
                              </th>
                              <th
                                nowrap="true"
                                style={{ fontSize: 16 }}
                                className="text-left table_header_barge_label text-uppercase border-0"
                              >
                                Barge Nomination
                              </th>
                              <th
                                nowrap="true"
                                style={{ fontSize: 16 }}
                                className="text-left table_header_barge_label text-uppercase border-0"
                              >
                                Jetty
                              </th>
                              <th
                                nowrap="true"
                                style={{ fontSize: 16 }}
                                className="text-left table_header_barge_label text-uppercase border-0"
                              >
                                Loading Date
                              </th>
                              <th
                                nowrap="true"
                                style={{ fontSize: 16 }}
                                className="text-left table_header_barge_label text-uppercase border-0"
                              >
                                Business No
                              </th>
                              <th
                                nowrap="true"
                                style={{ fontSize: 16 }}
                                className="text-right table_header_barge_label text-uppercase border-0"
                              >
                                Quantity
                              </th>
                            </tr>
                          </thead>
                          {this.state.totalBarges.map((p, index) => (
                            <tbody key={index}>
                              <tr className="rounded">
                                <td className="p-0 text-center">
                                  <CheckBox
                                    value={p.id}
                                    color="primary"
                                    checked={
                                      this.state.bargeList.indexOf(p.id) >= 0
                                    }
                                    //disabled={p.barge_pur_fin_id !== null}
                                    onChange={(e) => {
                                      this.onBargesCheck(
                                        e.target.value,
                                        e.target.checked,
                                        p
                                      );
                                    }}
                                  />
                                </td>
                                <td className="table_td text-left">
                                  {pad(p.id)}
                                </td>
                                <td nowrap="true">
                                  {p.barge_vendor_name
                                    ? p.barge_vendor_name
                                    : "-"}
                                </td>
                                <td nowrap="true">
                                  {p.barge_nomination
                                    ? p.barge_nomination
                                    : "-"}
                                </td>
                                <td nowrap="true">
                                  {p.loading_jetty ? p.loading_jetty : "-"}
                                </td>
                                <td nowrap="true">
                                  {p.loading_date
                                    ? localDateFormate(p.loading_date)
                                    : "-"}
                                </td>
                                <td nowrap="true">
                                  {p.business_no ? p.business_no : "-"}
                                </td>
                                <td className="text-right" nowrap="true">
                                  {toLocaleString(p.barge_quantity_in_mt)}
                                </td>
                              </tr>
                            </tbody>
                          ))}
                        </table>
                      </div>
                    </div>
                  </Accordion>
                  <div className="" style={{ width: "99%" }}>
                    <div style={{ width: "70%" }}>
                      <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                        Add details to update costing
                      </h5>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 pl-0">
                        <div className="row">
                          <div className="col-lg-12">
                            <label className="form_label mb-0">
                              Barge Quantity in MT
                            </label>
                            <TextField
                              name="barge_quantity"
                              margin="dense"
                              variant="outlined"
                              type="number"
                              value={
                                this.state.barge_quantity
                                  ? Number(this.state.barge_quantity)
                                  : ""
                              }
                              fullWidth
                              InputProps={{
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                },
                              }}
                              placeholder="Enter Value"
                              onChange={(e) => {
                                if (
                                  e.target.value === "" ||
                                  quan.test(e.target.value)
                                ) {
                                  this.setState(
                                    {
                                      barge_quantity: e.target.value,
                                    },
                                    () => this.bargingCalculation()
                                  );
                                }
                              }}
                            />
                          </div>
                          <div className="col-lg-12 mb-1">
                            <label className="form_label">
                              {this.state.barging_type === "Lumpsum"
                                ? "Lumpsum Price"
                                : "Barging Base Price PMT"}
                            </label>
                            <div>
                              {toLocaleString(this.state.bargeBasePrice)}
                              {this.state.barging_base_price_currency ? (
                                <span>
                                  {this.state.barging_base_price_currency}
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          {(CurrencyToCompanyCodes[CurrencyCodesEnum.USD].includes(config.company)) &&
                            this.state.barging_base_price_currency !== CurrencyCodesEnum.USD &&
                            this.state.barging_base_price_currency && (
                              <div className="col-lg-12">
                                <label className="form_label mb-0">
                                  Exchange Rate
                                </label>
                                <TextField
                                  name="currency_xchg_rate"
                                  margin="dense"
                                  variant="outlined"
                                  fullWidth
                                  placeholder="Enter Value"
                                  value={this.state.currency_xchg_rate}
                                  onChange={(e) => {
                                    if (
                                      e.target.value === "" ||
                                      re.test(e.target.value)
                                    ) {
                                      this.setState(
                                        {
                                          currency_xchg_rate: e.target.value,
                                        },
                                        () => this.bargingCalculation()
                                      );
                                    }
                                  }}
                                />
                              </div>
                            )}
                          {CurrencyToCompanyCodes[CurrencyCodesEnum.USD].includes(config.company) &&
                            this.state.barging_base_price_currency !== CurrencyCodesEnum.USD &&
                            this.state.barging_base_price_currency && (
                              <div className="col-lg-12 mb-1">
                                <label className="form_label">
                                  {this.state.barging_type === "Lumpsum"
                                    ? "Lumpsum Price in USD"
                                    : " Barging Base Price in USD"}
                                </label>
                                <div>
                                  {toLocaleString(
                                    this.state
                                      .barge_base_price_in_local_currency
                                  )}
                                  USD
                                </div>
                              </div>
                            )}
                          {(!CurrencyToCompanyCodes[CurrencyCodesEnum.USD].includes(config.company)) &&
                            this.state.barging_base_price_currency !== CurrencyCodesEnum.IDR &&
                            this.state.barging_base_price_currency && (
                              <div className="col-lg-12">
                                <label className="form_label mb-0">
                                  Exchange Rate
                                </label>
                                <TextField
                                  name="currency_xchg_rate"
                                  margin="dense"
                                  variant="outlined"
                                  fullWidth
                                  placeholder="Enter Value"
                                  value={this.state.currency_xchg_rate}
                                  onChange={(e) => {
                                    if (
                                      e.target.value === "" ||
                                      re.test(e.target.value)
                                    ) {
                                      this.setState(
                                        {
                                          currency_xchg_rate: e.target.value,
                                        },
                                        () => this.bargingCalculation()
                                      );
                                    }
                                  }}
                                />
                              </div>
                            )}
                          {(!CurrencyToCompanyCodes[CurrencyCodesEnum.USD].includes(config.company)) &&
                            this.state.barging_base_price_currency !== CurrencyCodesEnum.IDR &&
                            this.state.barging_base_price_currency && (
                              <div className="col-lg-12 mb-1">
                                <label className="form_label">
                                  {this.state.barging_type === "Lumpsum"
                                    ? "Lumpsum Price in IDR"
                                    : " Barging Base Price in IDR"}
                                </label>
                                <div>
                                  {toLocaleString(
                                    this.state
                                      .barge_base_price_in_local_currency
                                  )}
                                  IDR
                                </div>
                              </div>
                            )}
                          {!CurrencyToCompanyCodes[CurrencyCodesEnum.USD].includes(config.company) && (
                            <div className="col-lg-12">
                              <label className="form_label mb-0">
                                PPH15 Account
                              </label>

                              <div className="mt-1">
                                {this.state.pph15_account}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        className="col-lg p-2 rounded"
                        style={{ backgroundColor: "#f7f3f0" }}
                      >
                        <div className="row col-lg-12 p-0">
                          <h6>Actual Barging Price</h6>
                        </div>
                        <div>
                          <div className="row pl-2">
                            <div className="col-lg-6 p-0 mb-2">
                              <label
                                className="form_label mb-2"
                                style={{ color: "rgba(72, 59, 52, 0.7)" }}
                              >
                                Base price
                              </label>
                              <div
                                className="contract_display_header_value"
                                style={{ color: "#42352d" }}
                              >
                                {this.state.barge_base_price
                                  ? this.state.barge_base_price
                                  : "-"}
                              </div>
                            </div>
                            {(this.state.vat_applicability === "Inclusive" ||
                              config.company === "SRPL" ||
                              config.company === "PRPL" ||
                              config.company === "AVS") && (
                                <div className="col-lg-6 pr-0 mb-2">
                                  <label
                                    className="form_label mb-2"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    {`Includes VAT @ ${this.state.barge_vat_percent}%`}
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {this.state.barge_vat_value
                                      ? this.state.barge_vat_value
                                      : "-"}
                                  </div>
                                </div>
                              )}
                            {!CurrencyToCompanyCodes[CurrencyCodesEnum.USD].includes(config.company) && (
                              <div className="col-lg-6 p-0 mb-3">
                                <label
                                  className="form_label mb-1"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  Includes PPH15 @{" "}
                                  {toLocaleString(
                                    this.state.barge_pph15_percent
                                  )}
                                  %
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {toLocaleString(
                                    this.state.barge_pph15_value
                                  )}
                                </div>
                              </div>
                            )}
                          </div>

                          <div className="row pl-2">
                            <div className="col-lg-6 p-0 mb-3">
                              <label
                                className="form_label mb-2"
                                style={{ color: "rgba(72, 59, 52, 0.7)" }}
                              >
                                Payable to Vendor
                              </label>
                              <div
                                className="contract_display_header_value"
                                style={{ color: "#42352d" }}
                              >
                                {toLocaleString(
                                  this.state.barge_vendor_total_value
                                )}{" "}
                                {CurrencyToCompanyCodes[CurrencyCodesEnum.USD].includes(config.company) ? CurrencyCodesEnum.USD : CurrencyCodesEnum.IDR}
                              </div>
                            </div>
                            <div className="col-lg-6 pr-0 mb-3">
                              <label
                                className="form_label mb-2"
                                style={{ color: "rgba(72, 59, 52, 0.7)" }}
                              >
                                Barging Cost
                              </label>
                              <div
                                className="contract_display_header_value"
                                style={{ color: "#42352d" }}
                              >
                                {toLocaleString(this.state.barge_ctc)}{" "}
                                {CurrencyToCompanyCodes[CurrencyCodesEnum.USD].includes(config.company) ? CurrencyCodesEnum.USD : CurrencyCodesEnum.IDR}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div
                        className="col-lg pr-0  mr-1 p-2 rounded"
                        style={{ backgroundColor: "#f7f3f0" }}
                      >
                        <div className="row col-lg-12 p-0">
                          <h6>{config.company} Dead Freight Value</h6>
                        </div>
                        <div>
                          <div className="row pl-2">
                            <div className="col-lg-6 p-0 mb-2">
                              <label
                                className="form_label mb-2"
                                style={{ color: "rgba(72, 59, 52, 0.7)" }}
                              >
                                Base price
                              </label>
                              <div
                                className="contract_display_header_value"
                                style={{ color: "#42352d" }}
                              >
                                {this.state.idf_base_price
                                  ? this.state.idf_base_price
                                  : "-"}
                              </div>
                            </div>
                            {(this.state.vat_applicability === "Inclusive" ||
                              config.company === "SRPL" ||
                              config.company === "PRPL" ||
                              config.company === "AVS") && (
                                <div className="col-lg-6 pr-0 mb-2">
                                  <label
                                    className="form_label mb-2"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    {`Includes VAT @ ${toLocaleString(
                                      this.state.idf_vat_percent
                                    )}%`}
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {this.state.idf_vat_value
                                      ? this.state.idf_vat_value
                                      : "-"}
                                  </div>
                                </div>
                              )}

                            {!CurrencyToCompanyCodes[CurrencyCodesEnum.USD].includes(config.company) && (
                              <div className="col-lg-6 p-0 mb-3">
                                <label
                                  className="form_label mb-2"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  Includes PPH15 @{" "}
                                  {toLocaleString(
                                    this.state.idf_pph15_percent
                                  )}
                                  %
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {toLocaleString(this.state.idf_pph15_value)}
                                </div>
                              </div>
                            )}
                          </div>

                          <div className="row pl-2">
                            <div className="col-lg-6 p-0 mb-3">
                              <label
                                className="form_label mb-2"
                                style={{ color: "rgba(72, 59, 52, 0.7)" }}
                              >
                                Payable to Vendor
                              </label>
                              <div
                                className="contract_display_header_value"
                                style={{ color: "#42352d" }}
                              >
                                {toLocaleString(
                                  this.state.idf_vendor_total_value
                                )}
                                {CurrencyToCompanyCodes[CurrencyCodesEnum.USD].includes(config.company) ? CurrencyCodesEnum.USD : CurrencyCodesEnum.IDR}
                              </div>
                            </div>
                            <div className="col-lg-6 pr-0 mb-3">
                              <label
                                className="form_label mb-2"
                                style={{ color: "rgba(72, 59, 52, 0.7)" }}
                              >
                                Barging Cost
                              </label>
                              <div
                                className="contract_display_header_value"
                                style={{ color: "#42352d" }}
                              >
                                {toLocaleString(this.state.idf_ctc)}
                                {CurrencyToCompanyCodes[CurrencyCodesEnum.USD].includes(config.company) ? CurrencyCodesEnum.USD : CurrencyCodesEnum.IDR}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className="col-lg pr-0 p-2 rounded"
                        style={{ backgroundColor: "#f7f3f0" }}
                      >
                        <div className="row col-lg-12 p-0">
                          <h6>Supplier Dead Freight Value</h6>
                        </div>

                        <div className="row pl-2">
                          <div className="col-lg-6 p-0 mb-2">
                            <label
                              className="form_label mb-2"
                              style={{ color: "rgba(72, 59, 52, 0.7)" }}
                            >
                              Base price
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ color: "#42352d" }}
                            >
                              {this.state.sdf_base_price
                                ? this.state.sdf_base_price
                                : "-"}
                            </div>
                          </div>
                          {(this.state.vat_applicability === "Inclusive" ||
                            config.company === "SRPL" ||
                            config.company === "PRPL" ||
                            config.company === "AVS") && (
                              <div className="col-lg-6 pr-0 mb-2">
                                <label
                                  className="form_label mb-2"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  {`Includes VAT @ ${this.state.sdf_vat_percent}%`}
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {this.state.sdf_vat_value
                                    ? this.state.sdf_vat_value
                                    : "-"}
                                </div>
                              </div>
                            )}

                          <div className="col-lg-8 p-0 mb-3">
                            <label
                              className="form_label mb-2"
                              style={{ color: "rgba(72, 59, 52, 0.7)" }}
                            >
                              Receivable from Supplier
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ color: "#42352d" }}
                            >
                              {toLocaleString(this.state.sdf_ctc)}
                              {CurrencyToCompanyCodes[CurrencyCodesEnum.USD].includes(config.company) ? CurrencyCodesEnum.USD : CurrencyCodesEnum.IDR}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* <AdvanceAdjustments
                adjustments={this.state.advance_adjustments}
                vendorType="Barge"
                bargeID={this.state.bargeList[0]}
                callbackFn={(data) => {
                  this.setState({ advance_adjustments: data });
                }}
              /> */}
              {/* {(this.state.features.includes('373') || this.state.features.includes('97')) && */}
              <br />
              <FileUpload
                files={this.state.files}
                type="Costings"
                invoice_file={this.state.invoice_file}
                feature_name="Barge_pur_fin_barge_costing"
                callbackFn={(data, mandatoryFiles) => {
                  this.setState({
                    files: data,
                    costing_files: mandatoryFiles,
                  });
                }}
              />
            </div>
          </div>
          <div className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button"
              name="submit"
              onClick={() => this.props.onCancel(false)}
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn next_button"
              name="Back"
              disabled={this.state.submitLoading}
              onClick={this.submitHandler}
            >
              {" "}
              <span
                className={
                  this.state.submitLoading
                    ? "spinner-grow spinner-grow-sm mr-2"
                    : ""
                }
              ></span>
              Submit
            </button>
          </div>
        </div>
      );
    }
  }
}
