
import React, { useEffect, useState } from 'react';
import {
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import config from '../../config/config';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import EventIcon from "@material-ui/icons/Event";
import Slide from '@material-ui/core/Slide';
import { dateFormateToDB } from './common';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PaymentsModal = ({ open, close, callbackFn, data }) => {

  const [state, setState] = useState(data);
  const [amountErrMsg, setAmountErrMsg] = useState(false);


  useEffect(() => {
    if (data) {
      setState(data)
    }
  }, [data])

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="responsive-dialog-title" style={{ backgroundColor: "#f1f1f1" }}>
        <h6
          className="modal-title"
          style={{ border: "none", padding: 0 }}
        >{`${state.paymentTitle} Payment Postings`}
        </h6>
      </DialogTitle>
      <DialogContent>
        <div className='row'>
          <div className="col-lg-6 p-0 mb-1">
            <label className="form_label mb-0">
              Amount <span className='text-danger'>*</span>
            </label>
            <TextField
              name="amount"
              margin="dense"
              type="text"
              variant="outlined"
              fullWidth
              inputProps={{
                style: {
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  textTransform: "uppercase",
                },
              }}
              placeholder="Amount"
              value={state.amount}
              error={amountErrMsg}
              onChange={(e) => {

                let { outstanding_amount = 0, billing_currency = null } = state;
                if (!billing_currency) {
                  billing_currency = config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL" ? "USD" : "IDR";
                }
                if (billing_currency === "USD") {
                  outstanding_amount = outstanding_amount + 10;
                } else if (billing_currency === "IDR") {
                  outstanding_amount = outstanding_amount + 50000
                }

                const pr = /^\d*(\.\d{0,4})?$/;
                if (e.target.value === '' || pr.test(e.target.value)) {
                  if (Number(e.target.value) >
                    Math.ceil(Number(outstanding_amount))) {
                    setAmountErrMsg(true)
                  }
                  else setAmountErrMsg(false)
                  setState({ ...state, amount: e.target.value });
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {config.company === "SRPL" || config.company === "AVS"  ||
                      config.company === "PRPL"
                      ? "USD"
                      : "IDR"}
                  </InputAdornment>
                ),
              }}
            />
            {amountErrMsg && (
              <p className='text-danger'>Amount is exceeding the outstanding value</p>
            )}
          </div>

          <div className="col-lg-6 pr-0 mb-1">
            <label className="form_label mb-3">
              Invoice No <span className='text-danger'>*</span>
            </label>
            <div className='text-uppercase'>{state.invoice_no}</div>
          </div>

          <div className="col-lg-6 p-0 mb-1">
            <label className="form_label mb-0">
              Payment Due Date
            </label>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                autoOk={true}
                inputVariant="outlined"
                variant="inline"
                margin="dense"
                fullWidth
                format="dd/MM/yyyy"
                value={state.payment_due_date}
                // error={this.state.date_of_paymentError}
                onChange={(date) => {
                  setState({ ...state, payment_due_date: dateFormateToDB(date) })
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton>
                        <EventIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                  className: "pl-0",
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
          <div className="col-lg-6 pr-0 mb-3">
            <label className="form_label mb-0">
              Description
            </label>
            <TextField
              name="description"
              margin="dense"
              variant="outlined"
              placeholder="Description"
              multiline
              rows={3}
              inputProps={{ style: { textTransform: "uppercase" } }}
              value={state.description}
              onChange={(e) =>
                setState({ ...state, description: e.target.value })
              }
              fullWidth
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <button
          className="btn payment_button mr-4"
          onClick={() => {
            const { amount = 0, invoice_no = null } = state;
            let { outstanding_amount = 0, billing_currency = '' } = state;
            if (!billing_currency) {
              billing_currency = config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL" ? "USD" : "IDR";
            }
            if (billing_currency === "USD") {
              outstanding_amount = outstanding_amount + 10;
            } else if (billing_currency === "IDR") {
              outstanding_amount = outstanding_amount + 50000
            }
            if (amount === "") {
              alert('Please fill mandatory fields.');
              //setAmountErrMsg(true)
              return;
            }
            if (Number(amount) >
              Math.ceil(Number(outstanding_amount))) {
              setAmountErrMsg(true)
              return;
            }
            let stateCopy = { ...state };
            if (!invoice_no) {
              stateCopy['invoice_no'] = '';
            }
            callbackFn(state)
          }}
        >
          {state.paymentMode === "Upate" ? "Update" : "Submit"}

        </button>

        <button className="btn btn-secondary" type='button'

          onClick={() => close(false)}>
          Cancel
        </button>
      </DialogActions>
    </Dialog>
  )
}

export default PaymentsModal