import React, { useState } from 'react'
import config from '../../../config/config';

import {
  Tabs,
  Tab,
  Button,
  Drawer,
} from "@material-ui/core";

export default function QualitySpecsView({ open, specifications, specification_standard, adendumSpecs, closeDrawerHandler }) {

  const [specActiveTab, setSpecActiveTab] = useState(0);
  const [activeTabIndex, setActiveTab] = useState(0);
  const [activeSubTab, setActiveSubTab] = useState(0);

  const CheckQualityDiffHandler = (label, value) => {
    if (adendumSpecs) {
      const diff = adendumSpecs[label] !== value;
      if (diff) {
        return adendumSpecs[label];
      }
      else return false;
    }

    return false;
  }

  return (
    <Drawer
      anchor="right"
      open={open}
      variant="temporary"
      elevation="20"
      onClose={() => closeDrawerHandler(false)}
    >
      <div className="container bg-white p-0" style={{ minHeight: "100vh", width: 800 }}>
        <div className="clearfix content-header">
          <div className="row border-bottom">
            <div className="col-sm text-left pl-0">
              <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                {`Specification Standard - ${specification_standard}`}
              </h4>
            </div>
          </div>
        </div>
        <div className='drawer-section'>

          <div className='card'>
            {specification_standard === "ASTM/ISO" && (

              <div className='row'>
                <Tabs
                  value={specActiveTab}
                  // onClick={(e, value) => {
                  //   this.setState({ specsAstmClickedRow: idx });
                  // }}
                  onChange={(e, value) => {
                    setSpecActiveTab(value)
                  }}
                  indicatorColor="primary"
                  textColor="primary"
                  // variant="scrollable"
                  // scrollButtons="auto"
                  // aria-label="scrollable auto tabs example"
                  style={{
                    backgroundColor: "#ffffff",
                    color: "#6c7293",
                    padding: 0,
                  }}
                  TabIndicatorProps={{
                    style: { background: config.themeColor },
                  }}
                >
                  <Tab
                    label={
                      <h4
                        style={{
                          margin: "0px",
                          color: specActiveTab === 0 ? config.themeColor : "#6c7293",
                          fontSize: "14px",
                          border: "0px",
                        }}
                      >
                        ASTM
                      </h4>
                    }
                    value={0}
                    style={{
                      fontFamily: "Poppins",
                      textTransform: "capitalize",
                      color: "#6c7293",
                    }}
                  />
                  <Tab
                    label={
                      <h4
                        style={{
                          margin: "0px",
                          color: specActiveTab === 1 ? config.themeColor : "#6c7293",
                          fontSize: "14px",
                          border: "0px",
                        }}
                      >
                        ISO
                      </h4>
                    }
                    value={1}
                    style={{
                      fontFamily: "Poppins",
                      textTransform: "capitalize",
                      color: "#6c7293",
                    }}
                  />
                </Tabs>
              </div>
            )}

            <div className="row">
              <div className="col-lg p-0 border">
                <h6
                  style={{
                    backgroundColor: "#dddd",
                    marginBottom: 0,
                  }}
                >
                  <Tabs
                    value={activeTabIndex}
                    onChange={(e, value) => setActiveTab(value)}
                    indicatorColor="primary"
                    textColor="primary"
                    // variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    style={{
                      backgroundColor: "#ffffff",
                      color: "#6c7293",
                      padding: 0,
                    }}
                    TabIndicatorProps={{
                      style: {
                        background: config.themeColor,
                      },
                    }}
                  >
                    <Tab
                      label={
                        <h4
                          style={{
                            margin: "0px",
                            color: activeTabIndex === 0 ? config.themeColor : "#6c7293",
                            fontSize: "14px",
                            border: "0px",
                          }}
                        >
                          Analysis
                        </h4>
                      }
                      value={0}
                      style={{
                        fontFamily: "Poppins",
                        textTransform: "capitalize",
                        color: "#6c7293",
                      }}
                    />
                    <Tab
                      label={
                        <h4
                          style={{
                            margin: "0px",
                            color: activeTabIndex === 1 ? config.themeColor : "#6c7293",
                            fontSize: "14px",
                            border: "0px",
                          }}
                        >
                          Size Distribution
                        </h4>
                      }
                      value={1}
                      style={{
                        fontFamily: "Poppins",
                        textTransform: "capitalize",
                        color: "#6c7293",
                      }}
                    />
                    <Tab
                      label={
                        <h4
                          style={{
                            margin: "0px",
                            color: activeTabIndex === 2 ? config.themeColor : "#6c7293",
                            fontSize: "14px",
                            border: "0px",
                          }}
                        >
                          Ash Fusion
                        </h4>
                      }
                      value={2}
                      style={{
                        fontFamily: "Poppins",
                        textTransform: "capitalize",
                        color: "#6c7293",
                      }}
                    />
                    <Tab
                      label={
                        <h4
                          style={{
                            margin: "0px",
                            color:
                              activeTabIndex === 3
                                ? config.themeColor
                                : "#6c7293",
                            fontSize: "14px",
                            border: "0px",
                          }}
                        >
                          Elements
                        </h4>
                      }
                      value={3}
                      style={{
                        fontFamily: "Poppins",
                        textTransform: "capitalize",
                        color: "#6c7293",
                      }}
                    />
                    <Tab
                      label={
                        <h4
                          style={{
                            margin: "0px",
                            color:
                              activeTabIndex === 4 ? config.themeColor : "#6c7293",
                            fontSize: "14px",
                            border: "0px",
                          }}
                        >
                          Other
                        </h4>
                      }
                      value={4}
                      style={{
                        fontFamily: "Poppins",
                        textTransform: "capitalize",
                        color: "#6c7293",
                      }}
                    />
                  </Tabs>
                </h6>
              </div>
            </div>

            <div className="col-lg-12 p-0 pt-2 pb-2 border">
              {activeTabIndex === 0 &&
                <>
                  <div className="row pl-3">
                    <Button
                      className="ml-5 mr-5 specs_button"
                      onClick={(e, value) => setActiveSubTab(0)}
                      style={{
                        textTransform: "capitalize",
                        borderRadius: 35,
                        height: 30,
                        color: activeSubTab === 0 ? config.themeColor : "#483b34b3",
                        backgroundColor: activeSubTab === 0 ? "#fff7f2" : "#fff",
                        fontSize: "14px",
                        border: "0px",
                      }}
                    >
                      Typical
                    </Button>
                    <Button
                      className="mr-5 specs_button"
                      onClick={(e, value) => setActiveSubTab(1)}
                      style={{
                        textTransform: "capitalize",
                        borderRadius: 35,
                        height: 30,
                        color:
                          activeSubTab === 1 ? config.themeColor : "#483b34b3",
                        backgroundColor: activeSubTab === 1 ? "#fff7f2" : "#fff",
                        fontSize: "14px",
                        border: "0px",
                      }}
                    >
                      Ultimate
                    </Button>
                    <Button
                      className="specs_button"
                      onClick={(e, value) => setActiveSubTab(2)}
                      style={{
                        textTransform: "capitalize",
                        borderRadius: 35,
                        height: 30,
                        color:
                          activeSubTab === 2 ? config.themeColor : "#483b34b3",
                        backgroundColor:
                          activeSubTab === 2 ? "#fff7f2" : "#fff",
                        fontSize: "14px",
                        border: "0px",
                      }}
                    >
                      Ash
                    </Button>
                  </div>
                </>
              }

              {/* {(activeTabIndex === 0 && activeSubTab === 0) &&
                <>
                  <div className="row p-2">
                    <label className="form_section_label mb-0 mt-2">
                      Gross Calorific Value (Kcal/kg)
                    </label>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Basis </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_calorific_value_basis_iso', specifications.quality_calorific_value_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value_basis_iso', specifications.quality_calorific_value_basis_iso)} </> : specifications.quality_calorific_value_basis_iso ? specifications.quality_calorific_value_basis_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_calorific_value_basis', specifications.quality_calorific_value_basis) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value_basis}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value_basis', specifications.quality_calorific_value_basis)} </> : specifications.quality_calorific_value_basis ? specifications.quality_calorific_value_basis : "-"}

                          </span>
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Typical </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_calorific_value_typical_iso', specifications.quality_calorific_value_typical_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value_typical_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value_typical_iso', specifications.quality_calorific_value_typical_iso)} </> : specifications.quality_calorific_value_typical_iso ? specifications.quality_calorific_value_typical_iso : "-"}

                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_calorific_value_typical', specifications.quality_calorific_value_typical) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value_typical}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value_typical', specifications.quality_calorific_value_typical)} </> : specifications.quality_calorific_value_typical ? specifications.quality_calorific_value_typical : "-"}

                          </span>
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Rejection </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_calorific_value_rejection_iso', specifications.quality_calorific_value_rejection_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value_rejection_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value_rejection_iso', specifications.quality_calorific_value_rejection_iso)} </> : specifications.quality_calorific_value_rejection_iso ? specifications.quality_calorific_value_rejection_iso : "-"}

                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_calorific_value_rejection', specifications.quality_calorific_value_rejection) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value_rejection}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value_rejection', specifications.quality_calorific_value_rejection)} </> : specifications.quality_calorific_value_rejection ? specifications.quality_calorific_value_rejection : "-"}

                          </span>
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label">  Bonus Cap </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_calorific_value_bonus_cap_iso', specifications.quality_calorific_value_bonus_cap_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value_bonus_cap_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value_bonus_cap_iso', specifications.quality_calorific_value_bonus_cap_iso)} </> : specifications.quality_calorific_value_bonus_cap_iso ? specifications.quality_calorific_value_bonus_cap_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_calorific_value_bonus_cap', specifications.quality_calorific_value_bonus_cap) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value_bonus_cap}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value_bonus_cap', specifications.quality_calorific_value_bonus_cap)} </> : specifications.quality_calorific_value_bonus_cap ? specifications.quality_calorific_value_bonus_cap : "-"}
                          </span>
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label">Penality Cap</label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_calorific_value_penality_cap_iso', specifications.quality_calorific_value_penality_cap_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value_penality_cap_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value_penality_cap_iso', specifications.quality_calorific_value_penality_cap_iso)} </> : specifications.quality_calorific_value_penality_cap_iso ? specifications.quality_calorific_value_penality_cap_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_calorific_value_penality_cap', specifications.quality_calorific_value_penality_cap) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value_penality_cap}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value_penality_cap', specifications.quality_calorific_value_penality_cap)} </> : specifications.quality_calorific_value_penality_cap ? specifications.quality_calorific_value_penality_cap : "-"}
                          </span>
                        }
                      </div>
                    </div>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Basis </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_calorific_value2_basis_iso', specifications.quality_calorific_value2_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value2_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value2_basis_iso', specifications.quality_calorific_value2_basis_iso)} </> : specifications.quality_calorific_value2_basis_iso ? specifications.quality_calorific_value2_basis_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_calorific_value2_basis', specifications.quality_calorific_value2_basis) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value2_basis}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value2_basis', specifications.quality_calorific_value2_basis)} </> : specifications.quality_calorific_value2_basis ? specifications.quality_calorific_value2_basis : "-"}
                          </span>
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Typical </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_calorific_value2_typical_iso', specifications.quality_calorific_value2_typical_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value2_typical_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value2_typical_iso', specifications.quality_calorific_value2_typical_iso)} </> : specifications.quality_calorific_value2_typical_iso ? specifications.quality_calorific_value2_typical_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_calorific_value2_typical', specifications.quality_calorific_value2_typical) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value2_typical}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value2_typical', specifications.quality_calorific_value2_typical)} </> : specifications.quality_calorific_value2_typical ? specifications.quality_calorific_value2_typical : "-"}
                          </span>
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Rejection </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_calorific_value2_rejection_iso', specifications.quality_calorific_value2_rejection_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value2_rejection_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value2_rejection_iso', specifications.quality_calorific_value2_rejection_iso)} </> : specifications.quality_calorific_value2_rejection_iso ? specifications.quality_calorific_value2_rejection_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_calorific_value2_rejection', specifications.quality_calorific_value2_rejection) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value2_rejection}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value2_rejection', specifications.quality_calorific_value2_rejection)} </> : specifications.quality_calorific_value2_rejection ? specifications.quality_calorific_value2_rejection : "-"}
                          </span>
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label">  Bonus Cap </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_calorific_value2_bonus_cap_iso', specifications.quality_calorific_value2_bonus_cap_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value2_bonus_cap_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value2_bonus_cap_iso', specifications.quality_calorific_value2_bonus_cap_iso)} </> : specifications.quality_calorific_value2_bonus_cap_iso ? specifications.quality_calorific_value2_bonus_cap_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_calorific_value2_bonus_cap', specifications.quality_calorific_value2_bonus_cap) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value2_bonus_cap}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value2_bonus_cap', specifications.quality_calorific_value2_bonus_cap)} </> : specifications.quality_calorific_value2_bonus_cap ? specifications.quality_calorific_value2_bonus_cap : "-"}
                          </span>
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label">Penality Cap</label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_calorific_value2_penality_cap_iso', specifications.quality_calorific_value2_penality_cap_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value2_penality_cap_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value2_penality_cap_iso', specifications.quality_calorific_value2_penality_cap_iso)} </> : specifications.quality_calorific_value2_penality_cap_iso ? specifications.quality_calorific_value2_penality_cap_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_calorific_value2_penality_cap', specifications.quality_calorific_value2_penality_cap) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value2_penality_cap}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value2_penality_cap', specifications.quality_calorific_value2_penality_cap)} </> : specifications.quality_calorific_value2_penality_cap ? specifications.quality_calorific_value2_penality_cap : "-"}
                          </span>
                        }
                      </div>
                    </div>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Basis </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_calorific_value3_basis_iso', specifications.quality_calorific_value3_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value3_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value3_basis_iso', specifications.quality_calorific_value3_basis_iso)} </> : specifications.quality_calorific_value3_basis_iso ? specifications.quality_calorific_value3_basis_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_calorific_value3_basis', specifications.quality_calorific_value3_basis) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value3_basis}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value3_basis', specifications.quality_calorific_value3_basis)} </> : specifications.quality_calorific_value3_basis ? specifications.quality_calorific_value3_basis : "-"}
                          </span>
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Typical </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_calorific_value3_typical_iso', specifications.quality_calorific_value3_typical_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value3_typical_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value3_typical_iso', specifications.quality_calorific_value3_typical_iso)} </> : specifications.quality_calorific_value3_typical_iso ? specifications.quality_calorific_value3_typical_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_calorific_value3_typical', specifications.quality_calorific_value3_typical) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value3_typical}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value3_typical', specifications.quality_calorific_value3_typical)} </> : specifications.quality_calorific_value3_typical ? specifications.quality_calorific_value3_typical : "-"}
                          </span>
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Rejection </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_calorific_value3_rejection_iso', specifications.quality_calorific_value3_rejection_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value3_rejection_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value3_rejection_iso', specifications.quality_calorific_value3_rejection_iso)} </> : specifications.quality_calorific_value3_rejection_iso ? specifications.quality_calorific_value3_rejection_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_calorific_value3_rejection', specifications.quality_calorific_value3_rejection) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value3_rejection}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value3_rejection', specifications.quality_calorific_value3_rejection)} </> : specifications.quality_calorific_value3_rejection ? specifications.quality_calorific_value3_rejection : "-"}
                          </span>
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label">  Bonus Cap </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_calorific_value3_bonus_cap_iso', specifications.quality_calorific_value3_bonus_cap_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value3_bonus_cap_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value3_bonus_cap_iso', specifications.quality_calorific_value3_bonus_cap_iso)} </> : specifications.quality_calorific_value3_bonus_cap_iso ? specifications.quality_calorific_value3_bonus_cap_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_calorific_value3_bonus_cap', specifications.quality_calorific_value3_bonus_cap) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value3_bonus_cap}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value3_bonus_cap', specifications.quality_calorific_value3_bonus_cap)} </> : specifications.quality_calorific_value3_bonus_cap ? specifications.quality_calorific_value3_bonus_cap : "-"}
                          </span>
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label">Penality Cap</label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_calorific_value3_penality_cap_iso', specifications.quality_calorific_value3_penality_cap_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value3_penality_cap_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value3_penality_cap_iso', specifications.quality_calorific_value3_penality_cap_iso)} </> : specifications.quality_calorific_value3_penality_cap_iso ? specifications.quality_calorific_value3_penality_cap_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_calorific_value3_penality_cap', specifications.quality_calorific_value3_penality_cap) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value3_penality_cap}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value3_penality_cap', specifications.quality_calorific_value3_penality_cap)} </> : specifications.quality_calorific_value3_penality_cap ? specifications.quality_calorific_value3_penality_cap : "-"}
                          </span>
                        }
                      </div>
                    </div>

                    <label className="form_section_label mb-0 mt-2">
                      Total Moisture (%)
                    </label>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Basis </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_total_moisture_basis_iso', specifications.quality_total_moisture_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_total_moisture_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_total_moisture_basis_iso', specifications.quality_total_moisture_basis_iso)} </> : specifications.quality_total_moisture_basis_iso ? specifications.quality_total_moisture_basis_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_total_moisture_basis', specifications.quality_total_moisture_basis) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_total_moisture_basis}</s>&nbsp;{CheckQualityDiffHandler('quality_total_moisture_basis', specifications.quality_total_moisture_basis)} </> : specifications.quality_total_moisture_basis ? specifications.quality_total_moisture_basis : "-"}
                          </span>
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Typical </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_total_moisture_typical_iso', specifications.quality_total_moisture_typical_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_total_moisture_typical_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_total_moisture_typical_iso', specifications.quality_total_moisture_typical_iso)} </> : specifications.quality_total_moisture_typical_iso ? specifications.quality_total_moisture_typical_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_total_moisture_typical', specifications.quality_total_moisture_typical) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_total_moisture_typical}</s>&nbsp;{CheckQualityDiffHandler('quality_total_moisture_typical', specifications.quality_total_moisture_typical)} </> : specifications.quality_total_moisture_typical ? specifications.quality_total_moisture_typical : "-"}
                          </span>
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Rejection </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_total_moisture_rejection_iso', specifications.quality_total_moisture_rejection_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_total_moisture_rejection_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_total_moisture_rejection_iso', specifications.quality_total_moisture_rejection_iso)} </> : specifications.quality_total_moisture_rejection_iso ? specifications.quality_total_moisture_rejection_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_total_moisture_rejection', specifications.quality_total_moisture_rejection) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_total_moisture_rejection}</s>&nbsp;{CheckQualityDiffHandler('quality_total_moisture_rejection', specifications.quality_total_moisture_rejection)} </> : specifications.quality_total_moisture_rejection ? specifications.quality_total_moisture_rejection : "-"}
                          </span>
                        }
                      </div>
                    </div>

                    <label className="form_section_label mb-0 mt-2">
                      Inherent Moisture (%)
                    </label>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Basis </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_inherent_moisture_basis_iso', specifications.quality_inherent_moisture_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_inherent_moisture_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_inherent_moisture_basis_iso', specifications.quality_inherent_moisture_basis_iso)} </> : specifications.quality_inherent_moisture_basis_iso ? specifications.quality_inherent_moisture_basis_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_inherent_moisture_basis', specifications.quality_inherent_moisture_basis) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_inherent_moisture_basis}</s>&nbsp;{CheckQualityDiffHandler('quality_inherent_moisture_basis', specifications.quality_inherent_moisture_basis)} </> : specifications.quality_inherent_moisture_basis ? specifications.quality_inherent_moisture_basis : "-"}
                          </span>
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Typical </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_inherent_moisture_typical_iso', specifications.quality_inherent_moisture_typical_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_inherent_moisture_typical_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_inherent_moisture_typical_iso', specifications.quality_inherent_moisture_typical_iso)} </> : specifications.quality_inherent_moisture_typical_iso ? specifications.quality_inherent_moisture_typical_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_inherent_moisture_typical', specifications.quality_inherent_moisture_typical) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_inherent_moisture_typical}</s>&nbsp;{CheckQualityDiffHandler('quality_inherent_moisture_typical', specifications.quality_inherent_moisture_typical)} </> : specifications.quality_inherent_moisture_typical ? specifications.quality_inherent_moisture_typical : "-"}
                          </span>
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Rejection </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_inherent_moisture_rejection_iso', specifications.quality_calorific_value_typical) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value_typical}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value_typical', specifications.quality_calorific_value_typical)} </> : specifications.quality_calorific_value_typical ? specifications.quality_calorific_value_typical : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_inherent_moisture_rejection', specifications.quality_inherent_moisture_rejection) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_inherent_moisture_rejection}</s>&nbsp;{CheckQualityDiffHandler('quality_inherent_moisture_rejection', specifications.quality_inherent_moisture_rejection)} </> : specifications.quality_inherent_moisture_rejection ? specifications.quality_inherent_moisture_rejection : "-"}
                          </span>
                        }
                      </div>
                    </div>

                    <label className="form_section_label mb-0 mt-2">
                      Ash (%)
                    </label>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Basis </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_ash_basis_iso', specifications.quality_ash_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_ash_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_ash_basis_iso', specifications.quality_ash_basis_iso)} </> : specifications.quality_ash_basis_iso ? specifications.quality_ash_basis_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_ash_basis', specifications.quality_ash_basis) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_ash_basis}</s>&nbsp;{CheckQualityDiffHandler('quality_ash_basis', specifications.quality_ash_basis)} </> : specifications.quality_ash_basis ? specifications.quality_ash_basis : "-"}
                          </span>
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Typical </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_ash_typical_iso', specifications.quality_ash_typical_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_ash_typical_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_ash_typical_iso', specifications.quality_ash_typical_iso)} </> : specifications.quality_ash_typical_iso ? specifications.quality_ash_typical_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_ash_typical', specifications.quality_ash_typical) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_ash_typical}</s>&nbsp;{CheckQualityDiffHandler('quality_ash_typical', specifications.quality_ash_typical)} </> : specifications.quality_ash_typical ? specifications.quality_ash_typical : "-"}
                          </span>
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Rejection </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_ash_rejection_iso', specifications.quality_ash_rejection_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_ash_rejection_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_ash_rejection_iso', specifications.quality_ash_rejection_iso)} </> : specifications.quality_ash_rejection_iso ? specifications.quality_ash_rejection_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_ash_rejection', specifications.quality_ash_rejection) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_ash_rejection}</s>&nbsp;{CheckQualityDiffHandler('quality_ash_rejection', specifications.quality_ash_rejection)} </> : specifications.quality_ash_rejection ? specifications.quality_ash_rejection : "-"}
                          </span>
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Penality </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_ash_penality_iso', specifications.quality_ash_penality_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_ash_penality_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_ash_penality_iso', specifications.quality_ash_penality_iso)} </> : specifications.quality_ash_penality_iso ? specifications.quality_ash_penality_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_ash_penality', specifications.quality_ash_penality) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_ash_penality}</s>&nbsp;{CheckQualityDiffHandler('quality_ash_penality', specifications.quality_ash_penality)} </> : specifications.quality_ash_penality ? specifications.quality_ash_penality : "-"}
                          </span>
                        }
                      </div>
                    </div>

                    <label className="form_section_label mb-0 mt-2">
                      Volatile Matter (%)
                    </label>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Basis </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_volatile_matter_basis_iso', specifications.quality_volatile_matter_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_volatile_matter_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_volatile_matter_basis_iso', specifications.quality_volatile_matter_basis_iso)} </> : specifications.quality_volatile_matter_basis_iso ? specifications.quality_volatile_matter_basis_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_volatile_matter_basis', specifications.quality_volatile_matter_basis) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_volatile_matter_basis}</s>&nbsp;{CheckQualityDiffHandler('quality_volatile_matter_basis', specifications.quality_volatile_matter_basis)} </> : specifications.quality_volatile_matter_basis ? specifications.quality_volatile_matter_basis : "-"}
                          </span>
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Typical </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_volatile_matter_typical_iso', specifications.quality_volatile_matter_typical_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_volatile_matter_typical_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_volatile_matter_typical_iso', specifications.quality_volatile_matter_typical_iso)} </> : specifications.quality_volatile_matter_typical_iso ? specifications.quality_volatile_matter_typical_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_volatile_matter_typical', specifications.quality_volatile_matter_typical) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_volatile_matter_typical}</s>&nbsp;{CheckQualityDiffHandler('quality_volatile_matter_typical', specifications.quality_volatile_matter_typical)} </> : specifications.quality_volatile_matter_typical ? specifications.quality_volatile_matter_typical : "-"}
                          </span>
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Rejection </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_volatile_matter_rejection_iso', specifications.quality_volatile_matter_rejection_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_volatile_matter_rejection_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_volatile_matter_rejection_iso', specifications.quality_volatile_matter_rejection_iso)} </> : specifications.quality_volatile_matter_rejection_iso ? specifications.quality_volatile_matter_rejection_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_volatile_matter_rejection', specifications.quality_volatile_matter_rejection) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_volatile_matter_rejection}</s>&nbsp;{CheckQualityDiffHandler('quality_volatile_matter_rejection', specifications.quality_volatile_matter_rejection)} </> : specifications.quality_volatile_matter_rejection ? specifications.quality_volatile_matter_rejection : "-"}
                          </span>
                        }
                      </div>
                    </div>

                    <label className="form_section_label mb-0 mt-2">
                      Fixed Carbon (%)
                    </label>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Basis </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_fixed_carbon_basis_iso', specifications.quality_fixed_carbon_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_fixed_carbon_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_fixed_carbon_basis_iso', specifications.quality_fixed_carbon_basis_iso)} </> : specifications.quality_fixed_carbon_basis_iso ? specifications.quality_fixed_carbon_basis_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_fixed_carbon_basis', specifications.quality_fixed_carbon_basis) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_fixed_carbon_basis}</s>&nbsp;{CheckQualityDiffHandler('quality_fixed_carbon_basis', specifications.quality_fixed_carbon_basis)} </> : specifications.quality_fixed_carbon_basis ? specifications.quality_fixed_carbon_basis : "-"}
                          </span>
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Typical </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_fixed_carbon_typical_iso', specifications.quality_fixed_carbon_typical_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_fixed_carbon_typical_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_fixed_carbon_typical_iso', specifications.quality_fixed_carbon_typical_iso)} </> : specifications.quality_fixed_carbon_typical_iso ? specifications.quality_fixed_carbon_typical_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_fixed_carbon_typical', specifications.quality_fixed_carbon_typical) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_fixed_carbon_typical}</s>&nbsp;{CheckQualityDiffHandler('quality_fixed_carbon_typical', specifications.quality_fixed_carbon_typical)} </> : specifications.quality_fixed_carbon_typical ? specifications.quality_fixed_carbon_typical : "-"}
                          </span>
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Rejection </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_fixed_carbon_rejection_iso', specifications.quality_fixed_carbon_rejection_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_fixed_carbon_rejection_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_fixed_carbon_rejection_iso', specifications.quality_fixed_carbon_rejection_iso)} </> : specifications.quality_fixed_carbon_rejection_iso ? specifications.quality_fixed_carbon_rejection_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_fixed_carbon_rejection', specifications.quality_fixed_carbon_rejection) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_fixed_carbon_rejection}</s>&nbsp;{CheckQualityDiffHandler('quality_fixed_carbon_rejection', specifications.quality_fixed_carbon_rejection)} </> : specifications.quality_fixed_carbon_rejection ? specifications.quality_fixed_carbon_rejection : "-"}
                          </span>
                        }
                      </div>
                    </div>

                    <label className="form_section_label mb-0 mt-2">
                      Fuel Ratio(FC/VM)
                    </label>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Basis </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_fuel_ratio_basis_iso', specifications.quality_fuel_ratio_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_fuel_ratio_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_fuel_ratio_basis_iso', specifications.quality_fuel_ratio_basis_iso)} </> : specifications.quality_fuel_ratio_basis_iso ? specifications.quality_fuel_ratio_basis_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_fuel_ratio_basis', specifications.quality_fuel_ratio_basis) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_fuel_ratio_basis}</s>&nbsp;{CheckQualityDiffHandler('quality_fuel_ratio_basis', specifications.quality_fuel_ratio_basis)} </> : specifications.quality_fuel_ratio_basis ? specifications.quality_fuel_ratio_basis : "-"}
                          </span>
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Typical </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_fuel_ratio_typical_iso', specifications.quality_fuel_ratio_typical_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_fuel_ratio_typical_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_fuel_ratio_typical_iso', specifications.quality_fuel_ratio_typical_iso)} </> : specifications.quality_fuel_ratio_typical_iso ? specifications.quality_fuel_ratio_typical_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_fuel_ratio_typical', specifications.quality_fuel_ratio_typical) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_fuel_ratio_typical}</s>&nbsp;{CheckQualityDiffHandler('quality_fuel_ratio_typical', specifications.quality_fuel_ratio_typical)} </> : specifications.quality_fuel_ratio_typical ? specifications.quality_fuel_ratio_typical : "-"}
                          </span>
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Rejection </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_fuel_ratio_rejection_iso', specifications.quality_fuel_ratio_rejection_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_fuel_ratio_rejection_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_fuel_ratio_rejection_iso', specifications.quality_fuel_ratio_rejection_iso)} </> : specifications.quality_fuel_ratio_rejection_iso ? specifications.quality_fuel_ratio_rejection_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_fuel_ratio_rejection', specifications.quality_fuel_ratio_rejection) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_fuel_ratio_rejection}</s>&nbsp;{CheckQualityDiffHandler('quality_fuel_ratio_rejection', specifications.quality_fuel_ratio_rejection)} </> : specifications.quality_fuel_ratio_rejection ? specifications.quality_fuel_ratio_rejection : "-"}
                          </span>
                        }
                      </div>
                    </div>

                    <label className="form_section_label mb-0 mt-2">
                      Total Sulphur (%)
                    </label>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Basis </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_total_sulphur_basis_iso', specifications.quality_total_sulphur_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_total_sulphur_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_total_sulphur_basis_iso', specifications.quality_total_sulphur_basis_iso)} </> : specifications.quality_total_sulphur_basis_iso ? specifications.quality_total_sulphur_basis_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_total_sulphur_basis', specifications.quality_total_sulphur_basis) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_total_sulphur_basis}</s>&nbsp;{CheckQualityDiffHandler('quality_total_sulphur_basis', specifications.quality_total_sulphur_basis)} </> : specifications.quality_total_sulphur_basis ? specifications.quality_total_sulphur_basis : "-"}
                          </span>
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Typical </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_total_sulphur_typical_iso', specifications.quality_total_sulphur_typical_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_total_sulphur_typical_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_total_sulphur_typical_iso', specifications.quality_total_sulphur_typical_iso)} </> : specifications.quality_total_sulphur_typical_iso ? specifications.quality_total_sulphur_typical_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_total_sulphur_typical', specifications.quality_total_sulphur_typical) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_total_sulphur_typical}</s>&nbsp;{CheckQualityDiffHandler('quality_total_sulphur_typical', specifications.quality_total_sulphur_typical)} </> : specifications.quality_total_sulphur_typical ? specifications.quality_total_sulphur_typical : "-"}
                          </span>
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Rejection </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_total_sulphur_rejection_iso', specifications.quality_total_sulphur_rejection_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_total_sulphur_rejection_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_total_sulphur_rejection_iso', specifications.quality_total_sulphur_rejection_iso)} </> : specifications.quality_total_sulphur_rejection_iso ? specifications.quality_total_sulphur_rejection_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_total_sulphur_rejection', specifications.quality_total_sulphur_rejection) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_total_sulphur_rejection}</s>&nbsp;{CheckQualityDiffHandler('quality_total_sulphur_rejection', specifications.quality_total_sulphur_rejection)} </> : specifications.quality_total_sulphur_rejection ? specifications.quality_total_sulphur_rejection : "-"}
                          </span>
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Penality </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_total_sulphur_penality_iso', specifications.quality_total_sulphur_penality_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_total_sulphur_penality_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_total_sulphur_penality_iso', specifications.quality_total_sulphur_penality_iso)} </> : specifications.quality_total_sulphur_penality_iso ? specifications.quality_total_sulphur_penality_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_total_sulphur_penality', specifications.quality_total_sulphur_penality) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_total_sulphur_penality}</s>&nbsp;{CheckQualityDiffHandler('quality_total_sulphur_penality', specifications.quality_total_sulphur_penality)} </> : specifications.quality_total_sulphur_penality ? specifications.quality_total_sulphur_penality : "-"}

                          </span>
                        }
                      </div>
                    </div>

                    <label className="form_section_label mb-0 mt-2">
                      HGI
                    </label>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Typical </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_hgi_typical_iso', specifications.quality_hgi_typical_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_hgi_typical_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_hgi_typical_iso', specifications.quality_hgi_typical_iso)} </> : specifications.quality_hgi_typical_iso ? specifications.quality_hgi_typical_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_hgi_typical', specifications.quality_hgi_typical) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_hgi_typical}</s>&nbsp;{CheckQualityDiffHandler('quality_hgi_typical', specifications.quality_hgi_typical)} </> : specifications.quality_hgi_typical ? specifications.quality_hgi_typical : "-"}

                          </span>
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Rejection </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_hgi_rejection_iso', specifications.quality_hgi_rejection_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_hgi_rejection_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_hgi_rejection_iso', specifications.quality_hgi_rejection_iso)} </> : specifications.quality_hgi_rejection_iso ? specifications.quality_hgi_rejection_iso : "-"}

                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('quality_hgi_rejection', specifications.quality_hgi_rejection) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_hgi_rejection}</s>&nbsp;{CheckQualityDiffHandler('quality_hgi_rejection', specifications.quality_hgi_rejection)} </> : specifications.quality_hgi_rejection ? specifications.quality_hgi_rejection : "-"}
                          </span>
                        }
                      </div>

                    </div>

                  </div>
                </>
              } */}

              {(activeTabIndex === 0 && activeSubTab === 0) &&
                <>
                  <br />
                  <div className='table-responsive'>
                    <table className='table table-bordered table-sm'>
                      <thead className='table-header'>
                        <tr>
                          <th></th>
                          <th>Basis</th>
                          <th>Typical</th>
                          <th>Rejection</th>
                          <th>Bonus Cap</th>
                          <th>Penality Cap</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr >
                          <th rowSpan={3} style={{ verticalAlign: 'middle' }}>Gross Calorific Value (Kcal/kg)</th>
                          <td>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_calorific_value_basis_iso', specifications.quality_calorific_value_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value_basis_iso', specifications.quality_calorific_value_basis_iso)} </> : specifications.quality_calorific_value_basis_iso ? specifications.quality_calorific_value_basis_iso : "-"}
                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_calorific_value_basis', specifications.quality_calorific_value_basis) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value_basis}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value_basis', specifications.quality_calorific_value_basis)} </> : specifications.quality_calorific_value_basis ? specifications.quality_calorific_value_basis : "-"}

                              </span>
                            }
                          </td>
                          <td>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_calorific_value_typical_iso', specifications.quality_calorific_value_typical_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value_typical_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value_typical_iso', specifications.quality_calorific_value_typical_iso)} </> : specifications.quality_calorific_value_typical_iso ? specifications.quality_calorific_value_typical_iso : "-"}

                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_calorific_value_typical', specifications.quality_calorific_value_typical) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value_typical}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value_typical', specifications.quality_calorific_value_typical)} </> : specifications.quality_calorific_value_typical ? specifications.quality_calorific_value_typical : "-"}

                              </span>
                            }
                          </td>

                          <td>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_calorific_value_rejection_iso', specifications.quality_calorific_value_rejection_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value_rejection_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value_rejection_iso', specifications.quality_calorific_value_rejection_iso)} </> : specifications.quality_calorific_value_rejection_iso ? specifications.quality_calorific_value_rejection_iso : "-"}

                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_calorific_value_rejection', specifications.quality_calorific_value_rejection) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value_rejection}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value_rejection', specifications.quality_calorific_value_rejection)} </> : specifications.quality_calorific_value_rejection ? specifications.quality_calorific_value_rejection : "-"}

                              </span>
                            }
                          </td>

                          <td>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_calorific_value_bonus_cap_iso', specifications.quality_calorific_value_bonus_cap_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value_bonus_cap_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value_bonus_cap_iso', specifications.quality_calorific_value_bonus_cap_iso)} </> : specifications.quality_calorific_value_bonus_cap_iso ? specifications.quality_calorific_value_bonus_cap_iso : "-"}
                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_calorific_value_bonus_cap', specifications.quality_calorific_value_bonus_cap) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value_bonus_cap}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value_bonus_cap', specifications.quality_calorific_value_bonus_cap)} </> : specifications.quality_calorific_value_bonus_cap ? specifications.quality_calorific_value_bonus_cap : "-"}
                              </span>
                            }
                          </td>

                          <td>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_calorific_value_penality_cap_iso', specifications.quality_calorific_value_penality_cap_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value_penality_cap_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value_penality_cap_iso', specifications.quality_calorific_value_penality_cap_iso)} </> : specifications.quality_calorific_value_penality_cap_iso ? specifications.quality_calorific_value_penality_cap_iso : "-"}
                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_calorific_value_penality_cap', specifications.quality_calorific_value_penality_cap) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value_penality_cap}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value_penality_cap', specifications.quality_calorific_value_penality_cap)} </> : specifications.quality_calorific_value_penality_cap ? specifications.quality_calorific_value_penality_cap : "-"}
                              </span>
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_calorific_value2_basis_iso', specifications.quality_calorific_value2_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value2_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value2_basis_iso', specifications.quality_calorific_value2_basis_iso)} </> : specifications.quality_calorific_value2_basis_iso ? specifications.quality_calorific_value2_basis_iso : "-"}
                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_calorific_value2_basis', specifications.quality_calorific_value2_basis) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value2_basis}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value2_basis', specifications.quality_calorific_value2_basis)} </> : specifications.quality_calorific_value2_basis ? specifications.quality_calorific_value2_basis : "-"}
                              </span>
                            }
                          </td>
                          <td>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_calorific_value2_typical_iso', specifications.quality_calorific_value2_typical_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value2_typical_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value2_typical_iso', specifications.quality_calorific_value2_typical_iso)} </> : specifications.quality_calorific_value2_typical_iso ? specifications.quality_calorific_value2_typical_iso : "-"}
                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_calorific_value2_typical', specifications.quality_calorific_value2_typical) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value2_typical}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value2_typical', specifications.quality_calorific_value2_typical)} </> : specifications.quality_calorific_value2_typical ? specifications.quality_calorific_value2_typical : "-"}
                              </span>
                            }
                          </td>

                          <td>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_calorific_value2_rejection_iso', specifications.quality_calorific_value2_rejection_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value2_rejection_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value2_rejection_iso', specifications.quality_calorific_value2_rejection_iso)} </> : specifications.quality_calorific_value2_rejection_iso ? specifications.quality_calorific_value2_rejection_iso : "-"}
                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_calorific_value2_rejection', specifications.quality_calorific_value2_rejection) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value2_rejection}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value2_rejection', specifications.quality_calorific_value2_rejection)} </> : specifications.quality_calorific_value2_rejection ? specifications.quality_calorific_value2_rejection : "-"}
                              </span>
                            }
                          </td>

                          <td>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_calorific_value2_bonus_cap_iso', specifications.quality_calorific_value2_bonus_cap_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value2_bonus_cap_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value2_bonus_cap_iso', specifications.quality_calorific_value2_bonus_cap_iso)} </> : specifications.quality_calorific_value2_bonus_cap_iso ? specifications.quality_calorific_value2_bonus_cap_iso : "-"}
                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_calorific_value2_bonus_cap', specifications.quality_calorific_value2_bonus_cap) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value2_bonus_cap}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value2_bonus_cap', specifications.quality_calorific_value2_bonus_cap)} </> : specifications.quality_calorific_value2_bonus_cap ? specifications.quality_calorific_value2_bonus_cap : "-"}
                              </span>
                            }
                          </td>

                          <td>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_calorific_value2_penality_cap_iso', specifications.quality_calorific_value2_penality_cap_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value2_penality_cap_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value2_penality_cap_iso', specifications.quality_calorific_value2_penality_cap_iso)} </> : specifications.quality_calorific_value2_penality_cap_iso ? specifications.quality_calorific_value2_penality_cap_iso : "-"}
                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_calorific_value2_penality_cap', specifications.quality_calorific_value2_penality_cap) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value2_penality_cap}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value2_penality_cap', specifications.quality_calorific_value2_penality_cap)} </> : specifications.quality_calorific_value2_penality_cap ? specifications.quality_calorific_value2_penality_cap : "-"}
                              </span>
                            }
                          </td>
                        </tr>

                        <tr>

                          <td>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_calorific_value3_basis_iso', specifications.quality_calorific_value3_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value3_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value3_basis_iso', specifications.quality_calorific_value3_basis_iso)} </> : specifications.quality_calorific_value3_basis_iso ? specifications.quality_calorific_value3_basis_iso : "-"}
                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_calorific_value3_basis', specifications.quality_calorific_value3_basis) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value3_basis}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value3_basis', specifications.quality_calorific_value3_basis)} </> : specifications.quality_calorific_value3_basis ? specifications.quality_calorific_value3_basis : "-"}
                              </span>
                            }
                          </td>
                          <td>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_calorific_value3_typical_iso', specifications.quality_calorific_value3_typical_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value3_typical_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value3_typical_iso', specifications.quality_calorific_value3_typical_iso)} </> : specifications.quality_calorific_value3_typical_iso ? specifications.quality_calorific_value3_typical_iso : "-"}
                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_calorific_value3_typical', specifications.quality_calorific_value3_typical) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value3_typical}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value3_typical', specifications.quality_calorific_value3_typical)} </> : specifications.quality_calorific_value3_typical ? specifications.quality_calorific_value3_typical : "-"}
                              </span>
                            }
                          </td>

                          <td>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_calorific_value3_rejection_iso', specifications.quality_calorific_value3_rejection_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value3_rejection_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value3_rejection_iso', specifications.quality_calorific_value3_rejection_iso)} </> : specifications.quality_calorific_value3_rejection_iso ? specifications.quality_calorific_value3_rejection_iso : "-"}
                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_calorific_value3_rejection', specifications.quality_calorific_value3_rejection) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value3_rejection}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value3_rejection', specifications.quality_calorific_value3_rejection)} </> : specifications.quality_calorific_value3_rejection ? specifications.quality_calorific_value3_rejection : "-"}
                              </span>
                            }
                          </td>

                          <td>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_calorific_value3_bonus_cap_iso', specifications.quality_calorific_value3_bonus_cap_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value3_bonus_cap_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value3_bonus_cap_iso', specifications.quality_calorific_value3_bonus_cap_iso)} </> : specifications.quality_calorific_value3_bonus_cap_iso ? specifications.quality_calorific_value3_bonus_cap_iso : "-"}
                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_calorific_value3_bonus_cap', specifications.quality_calorific_value3_bonus_cap) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value3_bonus_cap}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value3_bonus_cap', specifications.quality_calorific_value3_bonus_cap)} </> : specifications.quality_calorific_value3_bonus_cap ? specifications.quality_calorific_value3_bonus_cap : "-"}
                              </span>
                            }
                          </td>

                          <td>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_calorific_value3_penality_cap_iso', specifications.quality_calorific_value3_penality_cap_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value3_penality_cap_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value3_penality_cap_iso', specifications.quality_calorific_value3_penality_cap_iso)} </> : specifications.quality_calorific_value3_penality_cap_iso ? specifications.quality_calorific_value3_penality_cap_iso : "-"}
                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_calorific_value3_penality_cap', specifications.quality_calorific_value3_penality_cap) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value3_penality_cap}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value3_penality_cap', specifications.quality_calorific_value3_penality_cap)} </> : specifications.quality_calorific_value3_penality_cap ? specifications.quality_calorific_value3_penality_cap : "-"}
                              </span>
                            }
                          </td>
                        </tr>
                        <tr>
                          <th>Total Moisture (%)</th>
                          <td>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_total_moisture_basis_iso', specifications.quality_total_moisture_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_total_moisture_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_total_moisture_basis_iso', specifications.quality_total_moisture_basis_iso)} </> : specifications.quality_total_moisture_basis_iso ? specifications.quality_total_moisture_basis_iso : "-"}
                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_total_moisture_basis', specifications.quality_total_moisture_basis) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_total_moisture_basis}</s>&nbsp;{CheckQualityDiffHandler('quality_total_moisture_basis', specifications.quality_total_moisture_basis)} </> : specifications.quality_total_moisture_basis ? specifications.quality_total_moisture_basis : "-"}
                              </span>
                            }
                          </td>
                          <td>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_total_moisture_typical_iso', specifications.quality_total_moisture_typical_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_total_moisture_typical_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_total_moisture_typical_iso', specifications.quality_total_moisture_typical_iso)} </> : specifications.quality_total_moisture_typical_iso ? specifications.quality_total_moisture_typical_iso : "-"}
                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_total_moisture_typical', specifications.quality_total_moisture_typical) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_total_moisture_typical}</s>&nbsp;{CheckQualityDiffHandler('quality_total_moisture_typical', specifications.quality_total_moisture_typical)} </> : specifications.quality_total_moisture_typical ? specifications.quality_total_moisture_typical : "-"}
                              </span>
                            }
                          </td>

                          <td>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_total_moisture_rejection_iso', specifications.quality_total_moisture_rejection_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_total_moisture_rejection_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_total_moisture_rejection_iso', specifications.quality_total_moisture_rejection_iso)} </> : specifications.quality_total_moisture_rejection_iso ? specifications.quality_total_moisture_rejection_iso : "-"}
                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_total_moisture_rejection', specifications.quality_total_moisture_rejection) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_total_moisture_rejection}</s>&nbsp;{CheckQualityDiffHandler('quality_total_moisture_rejection', specifications.quality_total_moisture_rejection)} </> : specifications.quality_total_moisture_rejection ? specifications.quality_total_moisture_rejection : "-"}
                              </span>
                            }
                          </td>
                          <td>-</td>
                          <td>-</td>
                        </tr>
                        <tr>
                          <th>Inherent Moisture (%)</th>
                          <td>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_inherent_moisture_basis_iso', specifications.quality_inherent_moisture_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_inherent_moisture_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_inherent_moisture_basis_iso', specifications.quality_inherent_moisture_basis_iso)} </> : specifications.quality_inherent_moisture_basis_iso ? specifications.quality_inherent_moisture_basis_iso : "-"}
                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_inherent_moisture_basis', specifications.quality_inherent_moisture_basis) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_inherent_moisture_basis}</s>&nbsp;{CheckQualityDiffHandler('quality_inherent_moisture_basis', specifications.quality_inherent_moisture_basis)} </> : specifications.quality_inherent_moisture_basis ? specifications.quality_inherent_moisture_basis : "-"}
                              </span>
                            }
                          </td>
                          <td>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_inherent_moisture_typical_iso', specifications.quality_inherent_moisture_typical_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_inherent_moisture_typical_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_inherent_moisture_typical_iso', specifications.quality_inherent_moisture_typical_iso)} </> : specifications.quality_inherent_moisture_typical_iso ? specifications.quality_inherent_moisture_typical_iso : "-"}
                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_inherent_moisture_typical', specifications.quality_inherent_moisture_typical) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_inherent_moisture_typical}</s>&nbsp;{CheckQualityDiffHandler('quality_inherent_moisture_typical', specifications.quality_inherent_moisture_typical)} </> : specifications.quality_inherent_moisture_typical ? specifications.quality_inherent_moisture_typical : "-"}
                              </span>
                            }
                          </td>

                          <td>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_inherent_moisture_rejection_iso', specifications.quality_calorific_value_typical) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_calorific_value_typical}</s>&nbsp;{CheckQualityDiffHandler('quality_calorific_value_typical', specifications.quality_calorific_value_typical)} </> : specifications.quality_calorific_value_typical ? specifications.quality_calorific_value_typical : "-"}
                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_inherent_moisture_rejection', specifications.quality_inherent_moisture_rejection) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_inherent_moisture_rejection}</s>&nbsp;{CheckQualityDiffHandler('quality_inherent_moisture_rejection', specifications.quality_inherent_moisture_rejection)} </> : specifications.quality_inherent_moisture_rejection ? specifications.quality_inherent_moisture_rejection : "-"}
                              </span>
                            }
                          </td>
                          <td>-</td>
                          <td>-</td>
                        </tr>
                        <tr>
                          <th>Ash (%)</th>
                          <td>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_ash_basis_iso', specifications.quality_ash_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_ash_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_ash_basis_iso', specifications.quality_ash_basis_iso)} </> : specifications.quality_ash_basis_iso ? specifications.quality_ash_basis_iso : "-"}
                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_ash_basis', specifications.quality_ash_basis) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_ash_basis}</s>&nbsp;{CheckQualityDiffHandler('quality_ash_basis', specifications.quality_ash_basis)} </> : specifications.quality_ash_basis ? specifications.quality_ash_basis : "-"}
                              </span>
                            }
                          </td>
                          <td>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_ash_typical_iso', specifications.quality_ash_typical_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_ash_typical_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_ash_typical_iso', specifications.quality_ash_typical_iso)} </> : specifications.quality_ash_typical_iso ? specifications.quality_ash_typical_iso : "-"}
                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_ash_typical', specifications.quality_ash_typical) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_ash_typical}</s>&nbsp;{CheckQualityDiffHandler('quality_ash_typical', specifications.quality_ash_typical)} </> : specifications.quality_ash_typical ? specifications.quality_ash_typical : "-"}
                              </span>
                            }
                          </td>

                          <td>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_ash_rejection_iso', specifications.quality_ash_rejection_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_ash_rejection_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_ash_rejection_iso', specifications.quality_ash_rejection_iso)} </> : specifications.quality_ash_rejection_iso ? specifications.quality_ash_rejection_iso : "-"}
                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_ash_rejection', specifications.quality_ash_rejection) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_ash_rejection}</s>&nbsp;{CheckQualityDiffHandler('quality_ash_rejection', specifications.quality_ash_rejection)} </> : specifications.quality_ash_rejection ? specifications.quality_ash_rejection : "-"}
                              </span>
                            }
                          </td>
                          <td>-</td>
                          <td>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_ash_penality_iso', specifications.quality_ash_penality_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_ash_penality_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_ash_penality_iso', specifications.quality_ash_penality_iso)} </> : specifications.quality_ash_penality_iso ? specifications.quality_ash_penality_iso : "-"}
                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_ash_penality', specifications.quality_ash_penality) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_ash_penality}</s>&nbsp;{CheckQualityDiffHandler('quality_ash_penality', specifications.quality_ash_penality)} </> : specifications.quality_ash_penality ? specifications.quality_ash_penality : "-"}
                              </span>
                            }
                          </td>
                        </tr>
                        <tr>
                          <th>Volatile Matter (%)</th>
                          <td>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_volatile_matter_basis_iso', specifications.quality_volatile_matter_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_volatile_matter_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_volatile_matter_basis_iso', specifications.quality_volatile_matter_basis_iso)} </> : specifications.quality_volatile_matter_basis_iso ? specifications.quality_volatile_matter_basis_iso : "-"}
                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_volatile_matter_basis', specifications.quality_volatile_matter_basis) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_volatile_matter_basis}</s>&nbsp;{CheckQualityDiffHandler('quality_volatile_matter_basis', specifications.quality_volatile_matter_basis)} </> : specifications.quality_volatile_matter_basis ? specifications.quality_volatile_matter_basis : "-"}
                              </span>
                            }
                          </td>
                          <td>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_volatile_matter_typical_iso', specifications.quality_volatile_matter_typical_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_volatile_matter_typical_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_volatile_matter_typical_iso', specifications.quality_volatile_matter_typical_iso)} </> : specifications.quality_volatile_matter_typical_iso ? specifications.quality_volatile_matter_typical_iso : "-"}
                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_volatile_matter_typical', specifications.quality_volatile_matter_typical) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_volatile_matter_typical}</s>&nbsp;{CheckQualityDiffHandler('quality_volatile_matter_typical', specifications.quality_volatile_matter_typical)} </> : specifications.quality_volatile_matter_typical ? specifications.quality_volatile_matter_typical : "-"}
                              </span>
                            }
                          </td>

                          <td>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_volatile_matter_rejection_iso', specifications.quality_volatile_matter_rejection_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_volatile_matter_rejection_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_volatile_matter_rejection_iso', specifications.quality_volatile_matter_rejection_iso)} </> : specifications.quality_volatile_matter_rejection_iso ? specifications.quality_volatile_matter_rejection_iso : "-"}
                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_volatile_matter_rejection', specifications.quality_volatile_matter_rejection) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_volatile_matter_rejection}</s>&nbsp;{CheckQualityDiffHandler('quality_volatile_matter_rejection', specifications.quality_volatile_matter_rejection)} </> : specifications.quality_volatile_matter_rejection ? specifications.quality_volatile_matter_rejection : "-"}
                              </span>
                            }
                          </td>
                          <td>-</td>
                          <td>-</td>
                        </tr>
                        <tr>
                          <th>Fixed Carbon (%)</th>
                          <td>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_fixed_carbon_basis_iso', specifications.quality_fixed_carbon_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_fixed_carbon_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_fixed_carbon_basis_iso', specifications.quality_fixed_carbon_basis_iso)} </> : specifications.quality_fixed_carbon_basis_iso ? specifications.quality_fixed_carbon_basis_iso : "-"}
                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_fixed_carbon_basis', specifications.quality_fixed_carbon_basis) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_fixed_carbon_basis}</s>&nbsp;{CheckQualityDiffHandler('quality_fixed_carbon_basis', specifications.quality_fixed_carbon_basis)} </> : specifications.quality_fixed_carbon_basis ? specifications.quality_fixed_carbon_basis : "-"}
                              </span>
                            }
                          </td>
                          <td>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_fixed_carbon_typical_iso', specifications.quality_fixed_carbon_typical_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_fixed_carbon_typical_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_fixed_carbon_typical_iso', specifications.quality_fixed_carbon_typical_iso)} </> : specifications.quality_fixed_carbon_typical_iso ? specifications.quality_fixed_carbon_typical_iso : "-"}
                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_fixed_carbon_typical', specifications.quality_fixed_carbon_typical) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_fixed_carbon_typical}</s>&nbsp;{CheckQualityDiffHandler('quality_fixed_carbon_typical', specifications.quality_fixed_carbon_typical)} </> : specifications.quality_fixed_carbon_typical ? specifications.quality_fixed_carbon_typical : "-"}
                              </span>
                            }
                          </td>

                          <td>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_fixed_carbon_rejection_iso', specifications.quality_fixed_carbon_rejection_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_fixed_carbon_rejection_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_fixed_carbon_rejection_iso', specifications.quality_fixed_carbon_rejection_iso)} </> : specifications.quality_fixed_carbon_rejection_iso ? specifications.quality_fixed_carbon_rejection_iso : "-"}
                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_fixed_carbon_rejection', specifications.quality_fixed_carbon_rejection) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_fixed_carbon_rejection}</s>&nbsp;{CheckQualityDiffHandler('quality_fixed_carbon_rejection', specifications.quality_fixed_carbon_rejection)} </> : specifications.quality_fixed_carbon_rejection ? specifications.quality_fixed_carbon_rejection : "-"}
                              </span>
                            }
                          </td>
                          <td>-</td>
                          <td>-</td>
                        </tr>
                        <tr>
                          <th>Fuel Ratio(FC/VM)</th>
                          <td >
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_fuel_ratio_basis_iso', specifications.quality_fuel_ratio_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_fuel_ratio_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_fuel_ratio_basis_iso', specifications.quality_fuel_ratio_basis_iso)} </> : specifications.quality_fuel_ratio_basis_iso ? specifications.quality_fuel_ratio_basis_iso : "-"}
                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_fuel_ratio_basis', specifications.quality_fuel_ratio_basis) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_fuel_ratio_basis}</s>&nbsp;{CheckQualityDiffHandler('quality_fuel_ratio_basis', specifications.quality_fuel_ratio_basis)} </> : specifications.quality_fuel_ratio_basis ? specifications.quality_fuel_ratio_basis : "-"}
                              </span>
                            }
                          </td>
                          <td >
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_fuel_ratio_typical_iso', specifications.quality_fuel_ratio_typical_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_fuel_ratio_typical_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_fuel_ratio_typical_iso', specifications.quality_fuel_ratio_typical_iso)} </> : specifications.quality_fuel_ratio_typical_iso ? specifications.quality_fuel_ratio_typical_iso : "-"}
                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_fuel_ratio_typical', specifications.quality_fuel_ratio_typical) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_fuel_ratio_typical}</s>&nbsp;{CheckQualityDiffHandler('quality_fuel_ratio_typical', specifications.quality_fuel_ratio_typical)} </> : specifications.quality_fuel_ratio_typical ? specifications.quality_fuel_ratio_typical : "-"}
                              </span>
                            }
                          </td>

                          <td>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_fuel_ratio_rejection_iso', specifications.quality_fuel_ratio_rejection_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_fuel_ratio_rejection_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_fuel_ratio_rejection_iso', specifications.quality_fuel_ratio_rejection_iso)} </> : specifications.quality_fuel_ratio_rejection_iso ? specifications.quality_fuel_ratio_rejection_iso : "-"}
                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_fuel_ratio_rejection', specifications.quality_fuel_ratio_rejection) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_fuel_ratio_rejection}</s>&nbsp;{CheckQualityDiffHandler('quality_fuel_ratio_rejection', specifications.quality_fuel_ratio_rejection)} </> : specifications.quality_fuel_ratio_rejection ? specifications.quality_fuel_ratio_rejection : "-"}
                              </span>
                            }
                          </td>
                          <td>-</td>
                          <td>-</td>

                        </tr>
                        <tr>
                          <th>Total Sulphur (%)</th>
                          <td>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_total_sulphur_basis_iso', specifications.quality_total_sulphur_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_total_sulphur_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_total_sulphur_basis_iso', specifications.quality_total_sulphur_basis_iso)} </> : specifications.quality_total_sulphur_basis_iso ? specifications.quality_total_sulphur_basis_iso : "-"}
                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_total_sulphur_basis', specifications.quality_total_sulphur_basis) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_total_sulphur_basis}</s>&nbsp;{CheckQualityDiffHandler('quality_total_sulphur_basis', specifications.quality_total_sulphur_basis)} </> : specifications.quality_total_sulphur_basis ? specifications.quality_total_sulphur_basis : "-"}
                              </span>
                            }
                          </td>
                          <td>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_total_sulphur_typical_iso', specifications.quality_total_sulphur_typical_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_total_sulphur_typical_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_total_sulphur_typical_iso', specifications.quality_total_sulphur_typical_iso)} </> : specifications.quality_total_sulphur_typical_iso ? specifications.quality_total_sulphur_typical_iso : "-"}
                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_total_sulphur_typical', specifications.quality_total_sulphur_typical) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_total_sulphur_typical}</s>&nbsp;{CheckQualityDiffHandler('quality_total_sulphur_typical', specifications.quality_total_sulphur_typical)} </> : specifications.quality_total_sulphur_typical ? specifications.quality_total_sulphur_typical : "-"}
                              </span>
                            }
                          </td>
                          <td>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_total_sulphur_rejection_iso', specifications.quality_total_sulphur_rejection_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_total_sulphur_rejection_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_total_sulphur_rejection_iso', specifications.quality_total_sulphur_rejection_iso)} </> : specifications.quality_total_sulphur_rejection_iso ? specifications.quality_total_sulphur_rejection_iso : "-"}
                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_total_sulphur_rejection', specifications.quality_total_sulphur_rejection) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_total_sulphur_rejection}</s>&nbsp;{CheckQualityDiffHandler('quality_total_sulphur_rejection', specifications.quality_total_sulphur_rejection)} </> : specifications.quality_total_sulphur_rejection ? specifications.quality_total_sulphur_rejection : "-"}
                              </span>
                            }
                          </td>
                          <td>-</td>
                          <td>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_total_sulphur_penality_iso', specifications.quality_total_sulphur_penality_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_total_sulphur_penality_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_total_sulphur_penality_iso', specifications.quality_total_sulphur_penality_iso)} </> : specifications.quality_total_sulphur_penality_iso ? specifications.quality_total_sulphur_penality_iso : "-"}
                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_total_sulphur_penality', specifications.quality_total_sulphur_penality) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_total_sulphur_penality}</s>&nbsp;{CheckQualityDiffHandler('quality_total_sulphur_penality', specifications.quality_total_sulphur_penality)} </> : specifications.quality_total_sulphur_penality ? specifications.quality_total_sulphur_penality : "-"}

                              </span>
                            }
                          </td>
                        </tr>
                        <tr>
                          <th>HGI</th>
                          <td>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_hgi_typical_iso', specifications.quality_hgi_typical_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_hgi_typical_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_hgi_typical_iso', specifications.quality_hgi_typical_iso)} </> : specifications.quality_hgi_typical_iso ? specifications.quality_hgi_typical_iso : "-"}
                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_hgi_typical', specifications.quality_hgi_typical) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_hgi_typical}</s>&nbsp;{CheckQualityDiffHandler('quality_hgi_typical', specifications.quality_hgi_typical)} </> : specifications.quality_hgi_typical ? specifications.quality_hgi_typical : "-"}

                              </span>
                            }
                          </td>
                          <td>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_hgi_rejection_iso', specifications.quality_hgi_rejection_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_hgi_rejection_iso}</s>&nbsp;{CheckQualityDiffHandler('quality_hgi_rejection_iso', specifications.quality_hgi_rejection_iso)} </> : specifications.quality_hgi_rejection_iso ? specifications.quality_hgi_rejection_iso : "-"}

                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('quality_hgi_rejection', specifications.quality_hgi_rejection) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.quality_hgi_rejection}</s>&nbsp;{CheckQualityDiffHandler('quality_hgi_rejection', specifications.quality_hgi_rejection)} </> : specifications.quality_hgi_rejection ? specifications.quality_hgi_rejection : "-"}
                              </span>
                            }
                          </td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </>

              }

              {(activeTabIndex === 0 && activeSubTab === 1) &&
                <>
                  <div className="row p-2">
                    <label className="form_section_label mb-0 mt-2">
                      Carbon (%)
                    </label>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Basis </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ultimate_carbon_basis_iso', specifications.ultimate_carbon_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ultimate_carbon_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('ultimate_carbon_basis_iso', specifications.ultimate_carbon_basis_iso)} </> : specifications.ultimate_carbon_basis_iso ? specifications.ultimate_carbon_basis_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ultimate_carbon_basis', specifications.ultimate_carbon_basis) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ultimate_carbon_basis}</s>&nbsp;{CheckQualityDiffHandler('ultimate_carbon_basis', specifications.ultimate_carbon_basis)} </> : specifications.ultimate_carbon_basis ? specifications.ultimate_carbon_basis : "-"}
                          </span>
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Typical </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ultimate_carbon_typical_iso', specifications.ultimate_carbon_typical_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ultimate_carbon_typical_iso}</s>&nbsp;{CheckQualityDiffHandler('ultimate_carbon_typical_iso', specifications.ultimate_carbon_typical_iso)} </> : specifications.ultimate_carbon_typical_iso ? specifications.ultimate_carbon_typical_iso : "-"}

                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ultimate_carbon_typical', specifications.ultimate_carbon_typical) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ultimate_carbon_typical}</s>&nbsp;{CheckQualityDiffHandler('ultimate_carbon_typical', specifications.ultimate_carbon_typical)} </> : specifications.ultimate_carbon_typical ? specifications.ultimate_carbon_typical : "-"}
                          </span>
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Rejection </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ultimate_carbon_rejection_iso', specifications.ultimate_carbon_rejection_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ultimate_carbon_rejection_iso}</s>&nbsp;{CheckQualityDiffHandler('ultimate_carbon_rejection_iso', specifications.ultimate_carbon_rejection_iso)} </> : specifications.ultimate_carbon_rejection_iso ? specifications.ultimate_carbon_rejection_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ultimate_carbon_rejection', specifications.ultimate_carbon_rejection) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ultimate_carbon_rejection}</s>&nbsp;{CheckQualityDiffHandler('ultimate_carbon_rejection', specifications.ultimate_carbon_rejection)} </> : specifications.ultimate_carbon_rejection ? specifications.ultimate_carbon_rejection : "-"}
                          </span>
                        }
                      </div>
                    </div>

                    <label className="form_section_label mb-0 mt-2">
                      Hydrogen (%)
                    </label>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Basis </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ultimate_hydrogen_basis_iso', specifications.ultimate_hydrogen_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ultimate_hydrogen_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('ultimate_hydrogen_basis_iso', specifications.ultimate_hydrogen_basis_iso)} </> : specifications.ultimate_hydrogen_basis_iso ? specifications.ultimate_hydrogen_basis_iso : "-"}
                          </span> :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ultimate_hydrogen_basis', specifications.ultimate_hydrogen_basis) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ultimate_hydrogen_basis}</s>&nbsp;{CheckQualityDiffHandler('ultimate_hydrogen_basis', specifications.ultimate_hydrogen_basis)} </> : specifications.ultimate_hydrogen_basis ? specifications.ultimate_hydrogen_basis : "-"}
                          </span>
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Typical </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ultimate_hydrogen_typical_iso', specifications.ultimate_hydrogen_typical_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ultimate_hydrogen_typical_iso}</s>&nbsp;{CheckQualityDiffHandler('ultimate_hydrogen_typical_iso', specifications.ultimate_hydrogen_typical_iso)} </> : specifications.ultimate_hydrogen_typical_iso ? specifications.ultimate_hydrogen_typical_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ultimate_hydrogen_typical', specifications.ultimate_hydrogen_typical) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ultimate_hydrogen_typical}</s>&nbsp;{CheckQualityDiffHandler('ultimate_hydrogen_typical', specifications.ultimate_hydrogen_typical)} </> : specifications.ultimate_hydrogen_typical ? specifications.ultimate_hydrogen_typical : "-"}
                          </span>
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Rejection </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ultimate_hydrogen_rejection_iso', specifications.ultimate_hydrogen_rejection_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ultimate_hydrogen_rejection_iso}</s>&nbsp;{CheckQualityDiffHandler('ultimate_hydrogen_rejection_iso', specifications.ultimate_hydrogen_rejection_iso)} </> : specifications.ultimate_hydrogen_rejection_iso ? specifications.ultimate_hydrogen_rejection_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ultimate_hydrogen_rejection', specifications.ultimate_hydrogen_rejection) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ultimate_hydrogen_rejection}</s>&nbsp;{CheckQualityDiffHandler('ultimate_hydrogen_rejection', specifications.ultimate_hydrogen_rejection)} </> : specifications.ultimate_hydrogen_rejection ? specifications.ultimate_hydrogen_rejection : "-"}
                          </span>
                        }
                      </div>
                    </div>

                    <label className="form_section_label mb-0 mt-2">
                      Nitrogen (%)
                    </label>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Basis </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ultimate_nitrogen_basis_iso', specifications.ultimate_nitrogen_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ultimate_nitrogen_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('ultimate_nitrogen_basis_iso', specifications.ultimate_nitrogen_basis_iso)} </> : specifications.ultimate_nitrogen_basis_iso ? specifications.ultimate_nitrogen_basis_iso : "-"}

                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ultimate_nitrogen_basis', specifications.ultimate_nitrogen_basis) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ultimate_nitrogen_basis}</s>&nbsp;{CheckQualityDiffHandler('ultimate_nitrogen_basis', specifications.ultimate_nitrogen_basis)} </> : specifications.ultimate_nitrogen_basis ? specifications.ultimate_nitrogen_basis : "-"}
                          </span>
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Typical </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ultimate_nitrogen_typical_iso', specifications.ultimate_nitrogen_typical_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ultimate_nitrogen_typical_iso}</s>&nbsp;{CheckQualityDiffHandler('ultimate_nitrogen_typical_iso', specifications.ultimate_nitrogen_typical_iso)} </> : specifications.ultimate_nitrogen_typical_iso ? specifications.ultimate_nitrogen_typical_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ultimate_nitrogen_typical', specifications.ultimate_nitrogen_typical) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ultimate_nitrogen_typical}</s>&nbsp;{CheckQualityDiffHandler('ultimate_nitrogen_typical', specifications.ultimate_nitrogen_typical)} </> : specifications.ultimate_nitrogen_typical ? specifications.ultimate_nitrogen_typical : "-"}
                          </span>
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Rejection </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ultimate_nitrogen_rejection_iso', specifications.ultimate_nitrogen_rejection_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ultimate_nitrogen_rejection_iso}</s>&nbsp;{CheckQualityDiffHandler('ultimate_nitrogen_rejection_iso', specifications.ultimate_nitrogen_rejection_iso)} </> : specifications.ultimate_nitrogen_rejection_iso ? specifications.ultimate_nitrogen_rejection_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ultimate_nitrogen_rejection', specifications.ultimate_nitrogen_rejection) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ultimate_nitrogen_rejection}</s>&nbsp;{CheckQualityDiffHandler('ultimate_nitrogen_rejection', specifications.ultimate_nitrogen_rejection)} </> : specifications.ultimate_nitrogen_rejection ? specifications.ultimate_nitrogen_rejection : "-"}
                          </span>
                        }
                      </div>
                    </div>

                  </div>
                </>
              }

              {(activeTabIndex === 0 && activeSubTab === 2) &&
                <>
                  <div className="row p-2">
                    <label className="form_section_label mb-0 mt-2">
                      SiO2 (%)
                    </label>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Basis </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_sio2_basis_iso', specifications.ash_sio2_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_sio2_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('ash_sio2_basis_iso', specifications.ash_sio2_basis_iso)} </> : specifications.ash_sio2_basis_iso ? specifications.ash_sio2_basis_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_sio2_basis', specifications.ash_sio2_basis) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_sio2_basis}</s>&nbsp;{CheckQualityDiffHandler('ash_sio2_basis', specifications.ash_sio2_basis)} </> : specifications.ash_sio2_basis ? specifications.ash_sio2_basis : "-"}
                          </span>
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Typical </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_sio2_typical_iso', specifications.ash_sio2_typical_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_sio2_typical_iso}</s>&nbsp;{CheckQualityDiffHandler('ash_sio2_typical_iso', specifications.ash_sio2_typical_iso)} </> : specifications.ash_sio2_typical_iso ? specifications.ash_sio2_typical_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_sio2_typical', specifications.ash_sio2_typical) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_sio2_typical}</s>&nbsp;{CheckQualityDiffHandler('ash_sio2_typical', specifications.ash_sio2_typical)} </> : specifications.ash_sio2_typical ? specifications.ash_sio2_typical : "-"}
                          </span>
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Rejection </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_sio2_rejection_iso', specifications.ash_sio2_rejection_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_sio2_rejection_iso}</s>&nbsp;{CheckQualityDiffHandler('ash_sio2_rejection_iso', specifications.ash_sio2_rejection_iso)} </> : specifications.ash_sio2_rejection_iso ? specifications.ash_sio2_rejection_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_sio2_rejection', specifications.ash_sio2_rejection) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_sio2_rejection}</s>&nbsp;{CheckQualityDiffHandler('ash_sio2_rejection', specifications.ash_sio2_rejection)} </> : specifications.ash_sio2_rejection ? specifications.ash_sio2_rejection : "-"}
                          </span>
                        }
                      </div>
                    </div>

                    <label className="form_section_label mb-0 mt-2">
                      AI203 (%)
                    </label>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Basis </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_ai203_basis_iso', specifications.ash_ai203_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_ai203_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('ash_ai203_basis_iso', specifications.ash_ai203_basis_iso)} </> : specifications.ash_ai203_basis_iso ? specifications.ash_ai203_basis_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_ai203_basis', specifications.ash_ai203_basis) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_ai203_basis}</s>&nbsp;{CheckQualityDiffHandler('ash_ai203_basis', specifications.ash_ai203_basis)} </> : specifications.ash_ai203_basis ? specifications.ash_ai203_basis : "-"}
                          </span>
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Typical </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_ai203_typical_iso', specifications.ash_ai203_typical_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_ai203_typical_iso}</s>&nbsp;{CheckQualityDiffHandler('ash_ai203_typical_iso', specifications.ash_ai203_typical_iso)} </> : specifications.ash_ai203_typical_iso ? specifications.ash_ai203_typical_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_ai203_typical', specifications.ash_ai203_typical) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_ai203_typical}</s>&nbsp;{CheckQualityDiffHandler('ash_ai203_typical', specifications.ash_ai203_typical)} </> : specifications.ash_ai203_typical ? specifications.ash_ai203_typical : "-"}
                          </span>
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Rejection </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_ai203_rejection_iso', specifications.ash_ai203_rejection_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_ai203_rejection_iso}</s>&nbsp;{CheckQualityDiffHandler('ash_ai203_rejection_iso', specifications.ash_ai203_rejection_iso)} </> : specifications.ash_ai203_rejection_iso ? specifications.ash_ai203_rejection_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_ai203_rejection', specifications.ash_ai203_rejection) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_ai203_rejection}</s>&nbsp;{CheckQualityDiffHandler('ash_ai203_rejection', specifications.ash_ai203_rejection)} </> : specifications.ash_ai203_rejection ? specifications.ash_ai203_rejection : "-"}
                          </span>
                        }
                      </div>
                    </div>

                    <label className="form_section_label mb-0 mt-2">
                      Fe203 (%)
                    </label>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Basis </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_fe203_basis_iso', specifications.ash_fe203_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_fe203_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('ash_fe203_basis_iso', specifications.ash_fe203_basis_iso)} </> : specifications.ash_fe203_basis_iso ? specifications.ash_fe203_basis_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_fe203_basis', specifications.ash_fe203_basis) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_fe203_basis}</s>&nbsp;{CheckQualityDiffHandler('ash_fe203_basis', specifications.ash_fe203_basis)} </> : specifications.ash_fe203_basis ? specifications.ash_fe203_basis : "-"}
                          </span>
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Typical </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_fe203_typical_iso', specifications.ash_fe203_typical_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_fe203_typical_iso}</s>&nbsp;{CheckQualityDiffHandler('ash_fe203_typical_iso', specifications.ash_fe203_typical_iso)} </> : specifications.ash_fe203_typical_iso ? specifications.ash_fe203_typical_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_fe203_typical', specifications.ash_fe203_typical) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_fe203_typical}</s>&nbsp;{CheckQualityDiffHandler('ash_fe203_typical', specifications.ash_fe203_typical)} </> : specifications.ash_fe203_typical ? specifications.ash_fe203_typical : "-"}
                          </span>
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Rejection </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_fe203_rejection_iso', specifications.ash_fe203_rejection_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_fe203_rejection_iso}</s>&nbsp;{CheckQualityDiffHandler('ash_fe203_rejection_iso', specifications.ash_fe203_rejection_iso)} </> : specifications.ash_fe203_rejection_iso ? specifications.ash_fe203_rejection_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_fe203_rejection', specifications.ash_fe203_rejection) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_fe203_rejection}</s>&nbsp;{CheckQualityDiffHandler('ash_fe203_rejection', specifications.ash_fe203_rejection)} </> : specifications.ash_fe203_rejection ? specifications.ash_fe203_rejection : "-"}
                          </span>
                        }
                      </div>
                    </div>

                    <label className="form_section_label mb-0 mt-2">
                      CaO (%)
                    </label>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Basis </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_cao_basis_iso', specifications.ash_cao_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_cao_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('ash_cao_basis_iso', specifications.ash_cao_basis_iso)} </> : specifications.ash_cao_basis_iso ? specifications.ash_cao_basis_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_cao_basis', specifications.ash_cao_basis) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_cao_basis}</s>&nbsp;{CheckQualityDiffHandler('ash_cao_basis', specifications.ash_cao_basis)} </> : specifications.ash_cao_basis ? specifications.ash_cao_basis : "-"}
                          </span>
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Typical </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_cao_typical_iso', specifications.ash_cao_typical_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_cao_typical_iso}</s>&nbsp;{CheckQualityDiffHandler('ash_cao_typical_iso', specifications.ash_cao_typical_iso)} </> : specifications.ash_cao_typical_iso ? specifications.ash_cao_typical_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_cao_typical', specifications.ash_cao_typical) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_cao_typical}</s>&nbsp;{CheckQualityDiffHandler('ash_cao_typical', specifications.ash_cao_typical)} </> : specifications.ash_cao_typical ? specifications.ash_cao_typical : "-"}
                          </span>
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Rejection </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_cao_rejection_iso', specifications.ash_cao_rejection_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_cao_rejection_iso}</s>&nbsp;{CheckQualityDiffHandler('ash_cao_rejection_iso', specifications.ash_cao_rejection_iso)} </> : specifications.ash_cao_rejection_iso ? specifications.ash_cao_rejection_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_cao_rejection', specifications.ash_cao_rejection) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_cao_rejection}</s>&nbsp;{CheckQualityDiffHandler('ash_cao_rejection', specifications.ash_cao_rejection)} </> : specifications.ash_cao_rejection ? specifications.ash_cao_rejection : "-"}
                          </span>
                        }
                      </div>
                    </div>

                    <label className="form_section_label mb-0 mt-2">
                      MgO (%)
                    </label>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Basis </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_mgo_basis_iso', specifications.ash_mgo_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_mgo_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('ash_mgo_basis_iso', specifications.ash_mgo_basis_iso)} </> : specifications.ash_mgo_basis_iso ? specifications.ash_mgo_basis_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_mgo_basis', specifications.ash_mgo_basis) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_mgo_basis}</s>&nbsp;{CheckQualityDiffHandler('ash_mgo_basis', specifications.ash_mgo_basis)} </> : specifications.ash_mgo_basis ? specifications.ash_mgo_basis : "-"}
                          </span>
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Typical </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_mgo_typical_iso', specifications.ash_mgo_typical_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_mgo_typical_iso}</s>&nbsp;{CheckQualityDiffHandler('ash_mgo_typical_iso', specifications.ash_mgo_typical_iso)} </> : specifications.ash_mgo_typical_iso ? specifications.ash_mgo_typical_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_mgo_typical', specifications.ash_mgo_typical) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_mgo_typical}</s>&nbsp;{CheckQualityDiffHandler('ash_mgo_typical', specifications.ash_mgo_typical)} </> : specifications.ash_mgo_typical ? specifications.ash_mgo_typical : "-"}
                          </span>
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Rejection </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_mgo_rejection_iso', specifications.ash_mgo_rejection_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_mgo_rejection_iso}</s>&nbsp;{CheckQualityDiffHandler('ash_mgo_rejection_iso', specifications.ash_mgo_rejection_iso)} </> : specifications.ash_mgo_rejection_iso ? specifications.ash_mgo_rejection_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_mgo_rejection', specifications.ash_mgo_rejection) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_mgo_rejection}</s>&nbsp;{CheckQualityDiffHandler('ash_mgo_rejection', specifications.ash_mgo_rejection)} </> : specifications.ash_mgo_rejection ? specifications.ash_mgo_rejection : "-"}
                          </span>
                        }
                      </div>
                    </div>

                    <label className="form_section_label mb-0 mt-2">
                      Na2O (%)
                    </label>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Basis </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_na2o_basis_iso', specifications.ash_na2o_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_na2o_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('ash_na2o_basis_iso', specifications.ash_na2o_basis_iso)} </> : specifications.ash_na2o_basis_iso ? specifications.ash_na2o_basis_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_na2o_basis', specifications.ash_na2o_basis) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_na2o_basis}</s>&nbsp;{CheckQualityDiffHandler('ash_na2o_basis', specifications.ash_na2o_basis)} </> : specifications.ash_na2o_basis ? specifications.ash_na2o_basis : "-"}
                          </span>
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Typical </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_na2o_typical_iso', specifications.ash_na2o_typical_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_na2o_typical_iso}</s>&nbsp;{CheckQualityDiffHandler('ash_na2o_typical_iso', specifications.ash_na2o_typical_iso)} </> : specifications.ash_na2o_typical_iso ? specifications.ash_na2o_typical_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_na2o_typical', specifications.ash_na2o_typical) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_na2o_typical}</s>&nbsp;{CheckQualityDiffHandler('ash_na2o_typical', specifications.ash_na2o_typical)} </> : specifications.ash_na2o_typical ? specifications.ash_na2o_typical : "-"}
                          </span>
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Rejection </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_na2o_rejection_iso', specifications.ash_na2o_rejection_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_na2o_rejection_iso}</s>&nbsp;{CheckQualityDiffHandler('ash_na2o_rejection_iso', specifications.ash_na2o_rejection_iso)} </> : specifications.ash_na2o_rejection_iso ? specifications.ash_na2o_rejection_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_na2o_rejection', specifications.ash_na2o_rejection) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_na2o_rejection}</s>&nbsp;{CheckQualityDiffHandler('ash_na2o_rejection', specifications.ash_na2o_rejection)} </> : specifications.ash_na2o_rejection ? specifications.ash_na2o_rejection : "-"}
                          </span>
                        }
                      </div>
                    </div>

                    <label className="form_section_label mb-0 mt-2">
                      K2O (%)
                    </label>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Basis </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_k2o_basis_iso', specifications.ash_k2o_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_k2o_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('ash_k2o_basis_iso', specifications.ash_k2o_basis_iso)} </> : specifications.ash_k2o_basis_iso ? specifications.ash_k2o_basis_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_k2o_basis', specifications.ash_k2o_basis) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_k2o_basis}</s>&nbsp;{CheckQualityDiffHandler('ash_k2o_basis', specifications.ash_k2o_basis)} </> : specifications.ash_k2o_basis ? specifications.ash_k2o_basis : "-"}
                          </span>
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Typical </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_k2o_typical_iso', specifications.ash_k2o_typical_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_k2o_typical_iso}</s>&nbsp;{CheckQualityDiffHandler('ash_k2o_typical_iso', specifications.ash_k2o_typical_iso)} </> : specifications.ash_k2o_typical_iso ? specifications.ash_k2o_typical_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_k2o_typical', specifications.ash_k2o_typical) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_k2o_typical}</s>&nbsp;{CheckQualityDiffHandler('ash_k2o_typical', specifications.ash_k2o_typical)} </> : specifications.ash_k2o_typical ? specifications.ash_k2o_typical : "-"}
                          </span>
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Rejection </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_k2o_rejection_iso', specifications.ash_k2o_rejection_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_k2o_rejection_iso}</s>&nbsp;{CheckQualityDiffHandler('ash_k2o_rejection_iso', specifications.ash_k2o_rejection_iso)} </> : specifications.ash_k2o_rejection_iso ? specifications.ash_k2o_rejection_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_k2o_rejection', specifications.ash_k2o_rejection) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_k2o_rejection}</s>&nbsp;{CheckQualityDiffHandler('ash_k2o_rejection', specifications.ash_k2o_rejection)} </> : specifications.ash_k2o_rejection ? specifications.ash_k2o_rejection : "-"}
                          </span>
                        }
                      </div>
                    </div>

                    <label className="form_section_label mb-0 mt-2">
                      TiO2 (%)
                    </label>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Basis </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_tio2_basis_iso', specifications.ash_tio2_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_tio2_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('ash_tio2_basis_iso', specifications.ash_tio2_basis_iso)} </> : specifications.ash_tio2_basis_iso ? specifications.ash_tio2_basis_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_tio2_basis', specifications.ash_tio2_basis) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_tio2_basis}</s>&nbsp;{CheckQualityDiffHandler('ash_tio2_basis', specifications.ash_tio2_basis)} </> : specifications.ash_tio2_basis ? specifications.ash_tio2_basis : "-"}
                          </span>
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Typical </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_tio2_typical_iso', specifications.ash_tio2_typical_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_tio2_typical_iso}</s>&nbsp;{CheckQualityDiffHandler('ash_tio2_typical_iso', specifications.ash_tio2_typical_iso)} </> : specifications.ash_tio2_typical ? specifications.ash_tio2_typical : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_tio2_typical', specifications.ash_tio2_typical) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_tio2_typical}</s>&nbsp;{CheckQualityDiffHandler('ash_tio2_typical', specifications.ash_tio2_typical)} </> : specifications.ash_tio2_typical ? specifications.ash_tio2_typical : "-"}
                          </span>
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Rejection </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_tio2_rejection_iso', specifications.ash_tio2_rejection_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_tio2_rejection_iso}</s>&nbsp;{CheckQualityDiffHandler('ash_tio2_rejection_iso', specifications.ash_tio2_rejection_iso)} </> : specifications.ash_tio2_rejection_iso ? specifications.ash_tio2_rejection_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_tio2_rejection', specifications.ash_tio2_rejection) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_tio2_rejection}</s>&nbsp;{CheckQualityDiffHandler('ash_tio2_rejection', specifications.ash_tio2_rejection)} </> : specifications.ash_tio2_rejection ? specifications.ash_tio2_rejection : "-"}
                          </span>
                        }
                      </div>
                    </div>

                    <label className="form_section_label mb-0 mt-2">
                      Mn3O4 (%)
                    </label>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Basis </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_mn3o4_basis_iso', specifications.ash_mn3o4_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_mn3o4_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('ash_mn3o4_basis_iso', specifications.ash_mn3o4_basis_iso)} </> : specifications.ash_mn3o4_basis_iso ? specifications.ash_mn3o4_basis_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_mn3o4_basis', specifications.ash_mn3o4_basis) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_mn3o4_basis}</s>&nbsp;{CheckQualityDiffHandler('ash_mn3o4_basis', specifications.ash_mn3o4_basis)} </> : specifications.ash_mn3o4_basis ? specifications.ash_mn3o4_basis : "-"}
                          </span>
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Typical </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_mn3o4_typical_iso', specifications.ash_mn3o4_typical_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_mn3o4_typical_iso}</s>&nbsp;{CheckQualityDiffHandler('ash_mn3o4_typical_iso', specifications.ash_mn3o4_typical_iso)} </> : specifications.ash_mn3o4_typical_iso ? specifications.ash_mn3o4_typical_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_mn3o4_typical', specifications.ash_mn3o4_typical) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_mn3o4_typical}</s>&nbsp;{CheckQualityDiffHandler('ash_mn3o4_typical', specifications.ash_mn3o4_typical)} </> : specifications.ash_mn3o4_typical ? specifications.ash_mn3o4_typical : "-"}
                          </span>
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Rejection </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_mn3o4_rejection_iso', specifications.ash_mn3o4_rejection_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_mn3o4_rejection_iso}</s>&nbsp;{CheckQualityDiffHandler('ash_mn3o4_rejection_iso', specifications.ash_mn3o4_rejection_iso)} </> : specifications.ash_mn3o4_rejection_iso ? specifications.ash_mn3o4_rejection_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_mn3o4_rejection', specifications.ash_mn3o4_rejection) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_mn3o4_rejection}</s>&nbsp;{CheckQualityDiffHandler('ash_mn3o4_rejection', specifications.ash_mn3o4_rejection)} </> : specifications.ash_mn3o4_rejection ? specifications.ash_mn3o4_rejection : "-"}
                          </span>
                        }
                      </div>
                    </div>

                    <label className="form_section_label mb-0 mt-2">
                      SO3 (%)
                    </label>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Basis </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_so3_basis_iso', specifications.ash_so3_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_so3_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('ash_so3_basis_iso', specifications.ash_so3_basis_iso)} </> : specifications.ash_so3_basis_iso ? specifications.ash_so3_basis_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_so3_basis', specifications.ash_so3_basis) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_so3_basis}</s>&nbsp;{CheckQualityDiffHandler('ash_so3_basis', specifications.ash_so3_basis)} </> : specifications.ash_so3_basis ? specifications.ash_so3_basis : "-"}
                          </span>
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Typical </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_so3_typical_iso', specifications.ash_so3_typical_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_so3_typical_iso}</s>&nbsp;{CheckQualityDiffHandler('ash_so3_typical_iso', specifications.ash_so3_typical_iso)} </> : specifications.ash_so3_typical_iso ? specifications.ash_so3_typical_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_so3_typical', specifications.ash_so3_typical) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_so3_typical}</s>&nbsp;{CheckQualityDiffHandler('ash_so3_typical', specifications.ash_so3_typical)} </> : specifications.ash_so3_typical ? specifications.ash_so3_typical : "-"}
                          </span>
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Rejection </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_so3_rejection_iso', specifications.ash_so3_rejection_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_so3_rejection_iso}</s>&nbsp;{CheckQualityDiffHandler('ash_so3_rejection_iso', specifications.ash_so3_rejection_iso)} </> : specifications.ash_so3_rejection_iso ? specifications.ash_so3_rejection_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_so3_rejection', specifications.ash_so3_rejection) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_so3_rejection}</s>&nbsp;{CheckQualityDiffHandler('ash_so3_rejection', specifications.ash_so3_rejection)} </> : specifications.ash_so3_rejection ? specifications.ash_so3_rejection : "-"}
                          </span>
                        }
                      </div>
                    </div>

                    <label className="form_section_label mb-0 mt-2">
                      P2O5 (%)
                    </label>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Basis </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_p2o5_basis_iso', specifications.ash_p2o5_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_p2o5_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('ash_p2o5_basis_iso', specifications.ash_p2o5_basis_iso)} </> : specifications.ash_p2o5_basis_iso ? specifications.ash_p2o5_basis_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_p2o5_basis', specifications.ash_p2o5_basis) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_p2o5_basis}</s>&nbsp;{CheckQualityDiffHandler('ash_p2o5_basis', specifications.ash_p2o5_basis)} </> : specifications.ash_p2o5_basis ? specifications.ash_p2o5_basis : "-"}
                          </span>
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Typical </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_p2o5_typical_iso', specifications.ash_p2o5_typical_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_p2o5_typical_iso}</s>&nbsp;{CheckQualityDiffHandler('ash_p2o5_typical_iso', specifications.ash_p2o5_typical_iso)} </> : specifications.ash_p2o5_typical_iso ? specifications.ash_p2o5_typical_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_p2o5_typical', specifications.ash_p2o5_typical) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_p2o5_typical}</s>&nbsp;{CheckQualityDiffHandler('ash_p2o5_typical', specifications.ash_p2o5_typical)} </> : specifications.ash_p2o5_typical ? specifications.ash_p2o5_typical : "-"}
                          </span>
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Rejection </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_p2o5_rejection_iso', specifications.ash_p2o5_rejection_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_p2o5_rejection_iso}</s>&nbsp;{CheckQualityDiffHandler('ash_p2o5_rejection_iso', specifications.ash_p2o5_rejection_iso)} </> : specifications.ash_p2o5_rejection_iso ? specifications.ash_p2o5_rejection_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('ash_p2o5_rejection', specifications.ash_p2o5_rejection) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.ash_p2o5_rejection}</s>&nbsp;{CheckQualityDiffHandler('ash_p2o5_rejection', specifications.ash_p2o5_rejection)} </> : specifications.ash_p2o5_rejection ? specifications.ash_p2o5_rejection : "-"}
                          </span>
                        }
                      </div>
                    </div>

                  </div>
                </>
              }

              {/*<!----------------------------- Size Distribution Table  ---------------->*/}
              {(activeTabIndex === 1) &&
                <>
                  <div className="row p-2">
                    <label className="form_section_label mb-0 mt-2">
                      Size Distribution (%)
                    </label>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Name </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('size_dist1_name_iso', specifications.size_dist1_name_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.size_dist1_name_iso}</s>&nbsp;{CheckQualityDiffHandler('size_dist1_name_iso', specifications.size_dist1_name_iso)} </> : specifications.size_dist1_name_iso ? specifications.size_dist1_name_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('size_dist1_name', specifications.size_dist1_name) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.size_dist1_name}</s>&nbsp;{CheckQualityDiffHandler('size_dist1_name', specifications.size_dist1_name)} </> : specifications.size_dist1_name ? specifications.size_dist1_name : "-"}
                          </span>
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Typical </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('size_dist1_typical_iso', specifications.size_dist1_typical_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.size_dist1_typical_iso}</s>&nbsp;{CheckQualityDiffHandler('size_dist1_typical_iso', specifications.size_dist1_typical_iso)} </> : specifications.size_dist1_typical_iso ? specifications.size_dist1_typical_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('size_dist1_typical', specifications.size_dist1_typical) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.size_dist1_typical}</s>&nbsp;{CheckQualityDiffHandler('size_dist1_typical', specifications.size_dist1_typical)} </> : specifications.size_dist1_typical ? specifications.size_dist1_typical : "-"}
                          </span>
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Rejection </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('size_dist1_rejection_iso', specifications.size_dist1_rejection_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.size_dist1_rejection_iso}</s>&nbsp;{CheckQualityDiffHandler('size_dist1_rejection_iso', specifications.size_dist1_rejection_iso)} </> : specifications.size_dist1_rejection_iso ? specifications.size_dist1_rejection_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('size_dist1_rejection', specifications.size_dist1_rejection) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.size_dist1_rejection}</s>&nbsp;{CheckQualityDiffHandler('size_dist1_rejection', specifications.size_dist1_rejection)} </> : specifications.size_dist1_rejection ? specifications.size_dist1_rejection : "-"}
                          </span>
                        }
                      </div>
                    </div>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Name </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('size_dist2_name_iso', specifications.size_dist2_name_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.size_dist2_name_iso}</s>&nbsp;{CheckQualityDiffHandler('size_dist2_name_iso', specifications.size_dist2_name_iso)} </> : specifications.size_dist2_name_iso ? specifications.size_dist2_name_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('size_dist2_name', specifications.size_dist2_name) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.size_dist2_name}</s>&nbsp;{CheckQualityDiffHandler('size_dist2_name', specifications.size_dist2_name)} </> : specifications.size_dist2_name ? specifications.size_dist2_name : "-"}
                          </span>
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Typical </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('size_dist2_typical_iso', specifications.size_dist2_typical_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.size_dist2_typical_iso}</s>&nbsp;{CheckQualityDiffHandler('size_dist2_typical_iso', specifications.size_dist2_typical_iso)} </> : specifications.size_dist2_typical_iso ? specifications.size_dist2_typical_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('size_dist2_typical', specifications.size_dist2_typical) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.size_dist2_typical}</s>&nbsp;{CheckQualityDiffHandler('size_dist2_typical', specifications.size_dist2_typical)} </> : specifications.size_dist2_typical ? specifications.size_dist2_typical : "-"}
                          </span>
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Rejection </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('size_dist2_rejection_iso', specifications.size_dist2_rejection_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.size_dist2_rejection_iso}</s>&nbsp;{CheckQualityDiffHandler('size_dist2_rejection_iso', specifications.size_dist2_rejection_iso)} </> : specifications.size_dist2_rejection_iso ? specifications.size_dist2_rejection_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('size_dist2_rejection', specifications.size_dist2_rejection) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.size_dist2_rejection}</s>&nbsp;{CheckQualityDiffHandler('size_dist2_rejection', specifications.size_dist2_rejection)} </> : specifications.size_dist2_rejection ? specifications.size_dist2_rejection : "-"}
                          </span>
                        }
                      </div>
                    </div>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Name </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('size_dist3_name_iso', specifications.size_dist3_name_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.size_dist3_name_iso}</s>&nbsp;{CheckQualityDiffHandler('size_dist3_name_iso', specifications.size_dist3_name_iso)} </> : specifications.size_dist3_name_iso ? specifications.size_dist3_name_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('size_dist3_name', specifications.size_dist3_name) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.size_dist3_name}</s>&nbsp;{CheckQualityDiffHandler('size_dist3_name', specifications.size_dist3_name)} </> : specifications.size_dist3_name ? specifications.size_dist3_name : "-"}
                          </span>
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Typical </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('size_dist3_typical_iso', specifications.size_dist3_typical_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.size_dist3_typical_iso}</s>&nbsp;{CheckQualityDiffHandler('size_dist3_typical_iso', specifications.size_dist3_typical_iso)} </> : specifications.size_dist3_typical_iso ? specifications.size_dist3_typical_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('size_dist3_typical', specifications.size_dist3_typical) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.size_dist3_typical}</s>&nbsp;{CheckQualityDiffHandler('size_dist3_typical', specifications.size_dist3_typical)} </> : specifications.size_dist3_typical ? specifications.size_dist3_typical : "-"}
                          </span>
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Rejection </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('size_dist3_rejection_iso', specifications.size_dist3_rejection_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.size_dist3_rejection_iso}</s>&nbsp;{CheckQualityDiffHandler('size_dist3_rejection_iso', specifications.size_dist3_rejection_iso)} </> : specifications.size_dist3_rejection_iso ? specifications.size_dist3_rejection_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('size_dist3_rejection', specifications.size_dist3_rejection) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.size_dist3_rejection}</s>&nbsp;{CheckQualityDiffHandler('size_dist3_rejection', specifications.size_dist3_rejection)} </> : specifications.size_dist3_rejection ? specifications.size_dist3_rejection : "-"}
                          </span>
                        }
                      </div>
                    </div>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Name </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('size_dist4_name_iso', specifications.size_dist4_name_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.size_dist4_name_iso}</s>&nbsp;{CheckQualityDiffHandler('size_dist4_name_iso', specifications.size_dist4_name_iso)} </> : specifications.size_dist4_name_iso ? specifications.size_dist4_name_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('size_dist4_name', specifications.size_dist4_name) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.size_dist4_name}</s>&nbsp;{CheckQualityDiffHandler('size_dist4_name', specifications.size_dist4_name)} </> : specifications.size_dist4_name ? specifications.size_dist4_name : "-"}
                          </span>
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Typical </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('size_dist4_typical_iso', specifications.size_dist4_typical_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.size_dist4_typical_iso}</s>&nbsp;{CheckQualityDiffHandler('size_dist4_typical_iso', specifications.size_dist4_typical_iso)} </> : specifications.size_dist4_typical_iso ? specifications.size_dist4_typical_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('size_dist4_typical', specifications.size_dist4_typical) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.size_dist4_typical}</s>&nbsp;{CheckQualityDiffHandler('size_dist4_typical', specifications.size_dist4_typical)} </> : specifications.size_dist4_typical ? specifications.size_dist4_typical : "-"}
                          </span>
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Rejection </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('size_dist4_rejection_iso', specifications.size_dist4_rejection_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.size_dist4_rejection_iso}</s>&nbsp;{CheckQualityDiffHandler('size_dist4_rejection_iso', specifications.size_dist4_rejection_iso)} </> : specifications.size_dist4_rejection_iso ? specifications.size_dist4_rejection_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('size_dist4_rejection', specifications.size_dist4_rejection) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.size_dist4_rejection}</s>&nbsp;{CheckQualityDiffHandler('size_dist4_rejection', specifications.size_dist4_rejection)} </> : specifications.size_dist4_rejection ? specifications.size_dist4_rejection : "-"}
                          </span>
                        }
                      </div>
                    </div>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Name </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('size_dist5_name_iso', specifications.size_dist5_name_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.size_dist5_name_iso}</s>&nbsp;{CheckQualityDiffHandler('size_dist5_name_iso', specifications.trace_arsenic_basis_iso)} </> : specifications.size_dist5_name_iso ? specifications.size_dist5_name_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('size_dist5_name', specifications.size_dist5_name) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.size_dist5_name}</s>&nbsp;{CheckQualityDiffHandler('size_dist5_name', specifications.size_dist5_name)} </> : specifications.size_dist5_name ? specifications.size_dist5_name : "-"}
                          </span>
                        }
                      </div>
                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Typical </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('size_dist5_typical_iso', specifications.size_dist5_typical_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.size_dist5_typical_iso}</s>&nbsp;{CheckQualityDiffHandler('size_dist5_typical_iso', specifications.size_dist5_typical_iso)} </> : specifications.size_dist5_typical_iso ? specifications.size_dist5_typical_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('size_dist5_typical', specifications.size_dist5_typical) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.size_dist5_typical}</s>&nbsp;{CheckQualityDiffHandler('size_dist5_typical', specifications.size_dist5_typical)} </> : specifications.size_dist5_typical ? specifications.size_dist5_typical : "-"}
                          </span>
                        }
                      </div>

                      <div className="col-lg-3 pl-0">
                        <label className="contract_display_header_label"> Rejection </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('size_dist5_rejection_iso', specifications.size_dist5_rejection_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.size_dist5_rejection_iso}</s>&nbsp;{CheckQualityDiffHandler('size_dist5_rejection_iso', specifications.size_dist5_rejection_iso)} </> : specifications.size_dist5_rejection_iso ? specifications.size_dist5_rejection_iso : "-"}
                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('size_dist5_rejection', specifications.size_dist5_rejection) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.size_dist5_rejection}</s>&nbsp;{CheckQualityDiffHandler('size_dist5_rejection', specifications.size_dist5_rejection)} </> : specifications.size_dist5_rejection ? specifications.size_dist5_rejection : "-"}
                          </span>
                        }
                      </div>
                    </div>

                  </div>
                </>
              }

              {/* <!---------------------- Ash Fusion Temperature Reducing Atmosphere table ---------!>*/}
              {(activeTabIndex === 2) &&
                <>
                  <div className="row p-2">

                    <div className="col-lg-12 row">
                      <div className="col-lg-4">
                        <label className="form_label mb-0 mt-2" style={{ fontWeight: "bold" }}>
                          Reducing Atmosphere
                        </label>
                      </div>
                      <div className='col-lg-8'>
                        <label className="form_section_label mb-0 mt-2">
                          Initial Deformation (Deg.C)
                        </label>
                        <div className='row'>
                          <div className='col-lg-4 p-0'>
                            <label className="contract_display_header_label">Ash Typical</label>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.trace_arsenic_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso)} </> : specifications.trace_arsenic_basis_iso ? specifications.trace_arsenic_basis_iso : "-"}

                                {specifications.ash_reducing_initial_typical_iso ? specifications.ash_reducing_initial_typical_iso : '-'}
                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.trace_arsenic_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso)} </> : specifications.trace_arsenic_basis_iso ? specifications.trace_arsenic_basis_iso : "-"}

                                {specifications.ash_reducing_initial_typical ? specifications.ash_reducing_initial_typical : '-'}
                              </span>
                            }
                          </div>

                          <div className='col-lg-4 p-0'>
                            <label className="contract_display_header_label">Ash Rejection</label>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.trace_arsenic_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso)} </> : specifications.trace_arsenic_basis_iso ? specifications.trace_arsenic_basis_iso : "-"}

                                {specifications.ash_reducing_initial_rejection_iso ? specifications.ash_reducing_initial_rejection_iso : '-'}
                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.trace_arsenic_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso)} </> : specifications.trace_arsenic_basis_iso ? specifications.trace_arsenic_basis_iso : "-"}

                                {specifications.ash_reducing_initial_rejection ? specifications.ash_reducing_initial_rejection : '-'}
                              </span>
                            }
                          </div>
                        </div>

                        <label className="form_section_label mb-0 mt-2">
                          Softening (Deg.C)
                        </label>
                        <div className='row'>
                          <div className='col-lg-4 p-0'>
                            <label className="contract_display_header_label">Ash Typical</label>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.trace_arsenic_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso)} </> : specifications.trace_arsenic_basis_iso ? specifications.trace_arsenic_basis_iso : "-"}

                                {specifications.ash_reducing_softening_typical_iso ? specifications.ash_reducing_softening_typical_iso : '-'}
                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.trace_arsenic_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso)} </> : specifications.trace_arsenic_basis_iso ? specifications.trace_arsenic_basis_iso : "-"}

                                {specifications.ash_reducing_softening_typical ? specifications.ash_reducing_softening_typical : '-'}
                              </span>
                            }
                          </div>

                          <div className='col-lg-4 p-0'>
                            <label className="contract_display_header_label">Ash Rejection</label>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.trace_arsenic_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso)} </> : specifications.trace_arsenic_basis_iso ? specifications.trace_arsenic_basis_iso : "-"}

                                {specifications.ash_reducing_softening_rejection_iso ? specifications.ash_reducing_softening_rejection_iso : '-'}
                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.trace_arsenic_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso)} </> : specifications.trace_arsenic_basis_iso ? specifications.trace_arsenic_basis_iso : "-"}

                                {specifications.ash_reducing_softening_rejection ? specifications.ash_reducing_softening_rejection : '-'}
                              </span>
                            }
                          </div>
                        </div>

                        <label className="form_section_label mb-0 mt-2">
                          Hemispherical Deformation (Deg.C)
                        </label>
                        <div className='row'>
                          <div className='col-lg-4 p-0'>
                            <label className="contract_display_header_label">Ash Typical</label>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.trace_arsenic_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso)} </> : specifications.trace_arsenic_basis_iso ? specifications.trace_arsenic_basis_iso : "-"}

                                {specifications.ash_reducing_hemispherical_typical_iso ? specifications.ash_reducing_hemispherical_typical_iso : '-'}
                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.trace_arsenic_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso)} </> : specifications.trace_arsenic_basis_iso ? specifications.trace_arsenic_basis_iso : "-"}

                                {specifications.ash_reducing_hemispherical_typical ? specifications.ash_reducing_hemispherical_typical : '-'}
                              </span>
                            }
                          </div>

                          <div className='col-lg-4 p-0'>
                            <label className="contract_display_header_label">Ash Rejection</label>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.trace_arsenic_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso)} </> : specifications.trace_arsenic_basis_iso ? specifications.trace_arsenic_basis_iso : "-"}

                                {specifications.ash_reducing_hemispherical_rejection_iso ? specifications.ash_reducing_hemispherical_rejection_iso : '-'}
                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.trace_arsenic_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso)} </> : specifications.trace_arsenic_basis_iso ? specifications.trace_arsenic_basis_iso : "-"}

                                {specifications.ash_reducing_hemispherical_rejection ? specifications.ash_reducing_hemispherical_rejection : '-'}
                              </span>
                            }
                          </div>
                        </div>

                        <label className="form_section_label mb-0 mt-2">
                          Flow (Deg.C)
                        </label>
                        <div className='row'>
                          <div className='col-lg-4 p-0'>
                            <label className="contract_display_header_label">Ash Typical</label>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.trace_arsenic_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso)} </> : specifications.trace_arsenic_basis_iso ? specifications.trace_arsenic_basis_iso : "-"}

                                {specifications.ash_reducing_flow_typical_iso ? specifications.ash_reducing_flow_typical_iso : '-'}
                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.trace_arsenic_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso)} </> : specifications.trace_arsenic_basis_iso ? specifications.trace_arsenic_basis_iso : "-"}

                                {specifications.ash_reducing_flow_typical ? specifications.ash_reducing_flow_typical : '-'}
                              </span>
                            }
                          </div>

                          <div className='col-lg-4 p-0'>
                            <label className="contract_display_header_label">Ash Rejection</label>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.trace_arsenic_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso)} </> : specifications.trace_arsenic_basis_iso ? specifications.trace_arsenic_basis_iso : "-"}

                                {specifications.ash_reducing_flow_rejection_iso ? specifications.ash_reducing_flow_rejection_iso : '-'}
                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.trace_arsenic_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso)} </> : specifications.trace_arsenic_basis_iso ? specifications.trace_arsenic_basis_iso : "-"}

                                {specifications.ash_reducing_flow_rejection ? specifications.ash_reducing_flow_rejection : '-'}
                              </span>
                            }
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12 row">
                      <div className="col-lg-4">
                        <label className="form_label mb-0 mt-2" style={{ fontWeight: "bold" }}>
                          Oxidizing Atmosphere
                        </label>
                      </div>
                      <div className='col-lg-8'>
                        <label className="form_section_label mb-0 mt-2">
                          Initial Deformation (Deg.C)
                        </label>
                        <div className='row'>
                          <div className='col-lg-4 p-0'>
                            <label className="contract_display_header_label">Ash Typical</label>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.trace_arsenic_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso)} </> : specifications.trace_arsenic_basis_iso ? specifications.trace_arsenic_basis_iso : "-"}

                                {specifications.ash_oxidizing_initial_typical_iso ? specifications.ash_oxidizing_initial_typical_iso : '-'}
                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.trace_arsenic_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso)} </> : specifications.trace_arsenic_basis_iso ? specifications.trace_arsenic_basis_iso : "-"}

                                {specifications.ash_oxidizing_initial_typical ? specifications.ash_oxidizing_initial_typical : '-'}
                              </span>
                            }
                          </div>

                          <div className='col-lg-4 p-0'>
                            <label className="contract_display_header_label">Ash Rejection</label>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.trace_arsenic_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso)} </> : specifications.trace_arsenic_basis_iso ? specifications.trace_arsenic_basis_iso : "-"}

                                {specifications.ash_oxidizing_initial_rejection_iso ? specifications.ash_oxidizing_initial_rejection_iso : '-'}
                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.trace_arsenic_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso)} </> : specifications.trace_arsenic_basis_iso ? specifications.trace_arsenic_basis_iso : "-"}

                                {specifications.ash_oxidizing_initial_rejection ? specifications.ash_oxidizing_initial_rejection : '-'}
                              </span>
                            }
                          </div>
                        </div>

                        <label className="form_section_label mb-0 mt-2">
                          Softening (Deg.C)
                        </label>
                        <div className='row'>
                          <div className='col-lg-4 p-0'>
                            <label className="contract_display_header_label">Ash Typical</label>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.trace_arsenic_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso)} </> : specifications.trace_arsenic_basis_iso ? specifications.trace_arsenic_basis_iso : "-"}

                                {specifications.ash_oxidizing_softening_typical_iso ? specifications.ash_oxidizing_softening_typical_iso : '-'}
                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.trace_arsenic_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso)} </> : specifications.trace_arsenic_basis_iso ? specifications.trace_arsenic_basis_iso : "-"}

                                {specifications.ash_oxidizing_softening_typical ? specifications.ash_oxidizing_softening_typical : '-'}
                              </span>
                            }
                          </div>

                          <div className='col-lg-4 p-0'>
                            <label className="contract_display_header_label">Ash Rejection</label>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.trace_arsenic_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso)} </> : specifications.trace_arsenic_basis_iso ? specifications.trace_arsenic_basis_iso : "-"}

                                {specifications.ash_oxidizing_softening_rejection_iso ? specifications.ash_oxidizing_softening_rejection_iso : '-'}
                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.trace_arsenic_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso)} </> : specifications.trace_arsenic_basis_iso ? specifications.trace_arsenic_basis_iso : "-"}

                                {specifications.ash_oxidizing_softening_rejection ? specifications.ash_oxidizing_softening_rejection : '-'}
                              </span>
                            }
                          </div>
                        </div>

                        <label className="form_section_label mb-0 mt-2">
                          Hemispherical Deformation (Deg.C)
                        </label>
                        <div className='row'>
                          <div className='col-lg-4 p-0'>
                            <label className="contract_display_header_label">Ash Typical</label>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.trace_arsenic_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso)} </> : specifications.trace_arsenic_basis_iso ? specifications.trace_arsenic_basis_iso : "-"}

                                {specifications.ash_oxidizing_hemispherical_typical_iso ? specifications.ash_oxidizing_hemispherical_typical_iso : '-'}
                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.trace_arsenic_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso)} </> : specifications.trace_arsenic_basis_iso ? specifications.trace_arsenic_basis_iso : "-"}

                                {specifications.ash_oxidizing_hemispherical_typical ? specifications.ash_oxidizing_hemispherical_typical : '-'}
                              </span>
                            }
                          </div>

                          <div className='col-lg-4 p-0'>
                            <label className="contract_display_header_label">Ash Rejection</label>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.trace_arsenic_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso)} </> : specifications.trace_arsenic_basis_iso ? specifications.trace_arsenic_basis_iso : "-"}

                                {specifications.ash_oxidizing_hemispherical_rejection_iso ? specifications.ash_oxidizing_hemispherical_rejection_iso : '-'}
                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.trace_arsenic_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso)} </> : specifications.trace_arsenic_basis_iso ? specifications.trace_arsenic_basis_iso : "-"}

                                {specifications.ash_oxidizing_hemispherical_rejection ? specifications.ash_oxidizing_hemispherical_rejection : '-'}
                              </span>
                            }
                          </div>
                        </div>

                        <label className="form_section_label mb-0 mt-2">
                          Flow (Deg.C)
                        </label>
                        <div className='row'>
                          <div className='col-lg-4 p-0'>
                            <label className="contract_display_header_label">Ash Typical</label>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.trace_arsenic_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso)} </> : specifications.trace_arsenic_basis_iso ? specifications.trace_arsenic_basis_iso : "-"}

                                {specifications.ash_oxidizing_flow_typical_iso ? specifications.ash_oxidizing_flow_typical_iso : '-'}
                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.trace_arsenic_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso)} </> : specifications.trace_arsenic_basis_iso ? specifications.trace_arsenic_basis_iso : "-"}

                                {specifications.ash_oxidizing_flow_typical ? specifications.ash_oxidizing_flow_typical : '-'}
                              </span>
                            }
                          </div>

                          <div className='col-lg-4 p-0'>
                            <label className="contract_display_header_label">Ash Rejection</label>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.trace_arsenic_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso)} </> : specifications.trace_arsenic_basis_iso ? specifications.trace_arsenic_basis_iso : "-"}

                                {specifications.ash_oxidizing_flow_rejection_iso ? specifications.ash_oxidizing_flow_rejection_sio : '-'}
                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.trace_arsenic_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso)} </> : specifications.trace_arsenic_basis_iso ? specifications.trace_arsenic_basis_iso : "-"}

                                {specifications.ash_oxidizing_flow_rejection ? specifications.ash_oxidizing_flow_rejection : '-'}
                              </span>
                            }
                          </div>
                        </div>
                      </div>
                    </div>


                  </div>
                </>
              }
              {/* <!---------------------- Ash Fusion Temperature Reducing Atmosphere table ---------!>*/}
              {(activeTabIndex === 3) &&
                <>
                  <div className="row p-2">

                    <div className="col-lg-12 row">
                      <div className="col-lg-3">
                        <label className="form_label mb-0 mt-2" style={{ fontWeight: "bold" }}>
                          Trace Elements
                        </label>
                      </div>
                      <div className='col-lg-9'>
                        <label className="form_section_label mb-0 mt-2">
                          Arsenic (ppm)
                        </label>
                        <div className='row'>
                          <div className='col-lg-3 p-0'>
                            <label className="contract_display_header_label">Basis</label>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.trace_arsenic_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('trace_arsenic_basis_iso', specifications.trace_arsenic_basis_iso)} </> : specifications.trace_arsenic_basis_iso ? specifications.trace_arsenic_basis_iso : "-"}

                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('trace_arsenic_basis', specifications.trace_arsenic_basis) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.trace_arsenic_basis}</s>&nbsp;{CheckQualityDiffHandler('trace_arsenic_basis', specifications.trace_arsenic_basis)} </> : specifications.trace_arsenic_basis ? specifications.trace_arsenic_basis : "-"}

                              </span>
                            }
                          </div>

                          <div className='col-lg-3 p-0'>
                            <label className="contract_display_header_label">Typical</label>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('trace_arsenic_typical_iso', specifications.trace_arsenic_typical_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.trace_arsenic_typical_iso}</s>&nbsp;{CheckQualityDiffHandler('trace_arsenic_typical_iso', specifications.trace_arsenic_typical_iso)} </> : specifications.trace_arsenic_typical_iso ? specifications.trace_arsenic_typical_iso : "-"}

                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('trace_arsenic_typical', specifications.trace_arsenic_typical) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.trace_arsenic_typical}</s>&nbsp;{CheckQualityDiffHandler('trace_arsenic_typical', specifications.trace_arsenic_typical)} </> : specifications.trace_arsenic_typical ? specifications.trace_arsenic_typical : "-"}

                              </span>
                            }
                          </div>

                          <div className='col-lg-3 p-0'>
                            <label className="contract_display_header_label">Rejection</label>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('trace_arsenic_rejection_iso', specifications.trace_arsenic_rejection_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.trace_arsenic_rejection_iso}</s>&nbsp;{CheckQualityDiffHandler('trace_arsenic_rejection_iso', specifications.trace_arsenic_rejection_iso)} </> : specifications.trace_arsenic_rejection_iso ? specifications.trace_arsenic_rejection_iso : "-"}

                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('trace_arsenic_rejection', specifications.trace_arsenic_rejection) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.trace_arsenic_rejection}</s>&nbsp;{CheckQualityDiffHandler('trace_arsenic_rejection', specifications.trace_arsenic_rejection)} </> : specifications.trace_arsenic_rejection ? specifications.trace_arsenic_rejection : "-"}

                              </span>
                            }
                          </div>
                        </div>

                        <label className="form_section_label mb-0 mt-2">
                          Mercury (ppm)
                        </label>
                        <div className='row'>
                          <div className='col-lg-3 p-0'>
                            <label className="contract_display_header_label">Basis</label>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('trace_mercury_basis_iso', specifications.trace_mercury_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.trace_mercury_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('trace_mercury_basis_iso', specifications.trace_mercury_basis_iso)} </> : specifications.trace_mercury_basis_iso ? specifications.trace_mercury_basis_iso : "-"}

                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('trace_mercury_basis', specifications.trace_mercury_basis) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.trace_mercury_basis}</s>&nbsp;{CheckQualityDiffHandler('trace_mercury_basis', specifications.trace_mercury_basis)} </> : specifications.trace_mercury_basis ? specifications.trace_mercury_basis : "-"}

                              </span>
                            }
                          </div>

                          <div className='col-lg-3 p-0'>
                            <label className="contract_display_header_label">Typical</label>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('trace_mercury_typical_iso', specifications.trace_mercury_typical_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.trace_mercury_typical_iso}</s>&nbsp;{CheckQualityDiffHandler('trace_mercury_typical_iso', specifications.trace_mercury_typical_iso)} </> : specifications.trace_mercury_typical_iso ? specifications.trace_mercury_typical_iso : "-"}

                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('trace_mercury_typical', specifications.trace_mercury_typical) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.trace_mercury_typical}</s>&nbsp;{CheckQualityDiffHandler('trace_mercury_typical', specifications.trace_mercury_typical)} </> : specifications.trace_mercury_typical ? specifications.trace_mercury_typical : "-"}

                              </span>
                            }
                          </div>

                          <div className='col-lg-3 p-0'>
                            <label className="contract_display_header_label">Rejection</label>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('trace_mercury_rejection_iso', specifications.trace_mercury_rejection_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.trace_mercury_rejection_iso}</s>&nbsp;{CheckQualityDiffHandler('trace_mercury_rejection_iso', specifications.trace_mercury_rejection_iso)} </> : specifications.trace_mercury_rejection_iso ? specifications.trace_mercury_rejection_iso : "-"}

                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('trace_mercury_rejection', specifications.trace_mercury_rejection) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.trace_mercury_rejection}</s>&nbsp;{CheckQualityDiffHandler('trace_mercury_rejection', specifications.trace_mercury_rejection)} </> : specifications.trace_mercury_rejection ? specifications.trace_mercury_rejection : "-"}

                              </span>
                            }
                          </div>
                        </div>

                        <label className="form_section_label mb-0 mt-2">
                          Selenium (ppm)
                        </label>
                        <div className='row'>
                          <div className='col-lg-3 p-0'>
                            <label className="contract_display_header_label">Basis</label>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('trace_selenium_basis_iso', specifications.trace_selenium_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.trace_selenium_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('trace_selenium_basis_iso', specifications.trace_selenium_basis_iso)} </> : specifications.trace_selenium_basis_iso ? specifications.trace_selenium_basis_iso : "-"}

                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('trace_selenium_basis', specifications.trace_selenium_basis) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.trace_selenium_basis}</s>&nbsp;{CheckQualityDiffHandler('trace_selenium_basis', specifications.trace_selenium_basis)} </> : specifications.trace_selenium_basis ? specifications.trace_selenium_basis : "-"}

                              </span>
                            }
                          </div>

                          <div className='col-lg-3 p-0'>
                            <label className="contract_display_header_label">Typical</label>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('trace_selenium_typical_iso', specifications.trace_selenium_typical_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.trace_selenium_typical_iso}</s>&nbsp;{CheckQualityDiffHandler('trace_selenium_typical_iso', specifications.trace_selenium_typical_iso)} </> : specifications.trace_selenium_typical_iso ? specifications.trace_selenium_typical_iso : "-"}

                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('trace_selenium_typical', specifications.trace_selenium_typical) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.trace_selenium_typical}</s>&nbsp;{CheckQualityDiffHandler('trace_selenium_typical', specifications.trace_selenium_typical)} </> : specifications.trace_selenium_typical ? specifications.trace_selenium_typical : "-"}

                              </span>
                            }
                          </div>

                          <div className='col-lg-3 p-0'>
                            <label className="contract_display_header_label">Rejection</label>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('trace_selenium_rejection_iso', specifications.trace_selenium_rejection_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.trace_selenium_rejection_iso}</s>&nbsp;{CheckQualityDiffHandler('trace_selenium_rejection_iso', specifications.trace_selenium_rejection_iso)} </> : specifications.trace_selenium_rejection_iso ? specifications.trace_selenium_rejection_iso : "-"}

                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('trace_selenium_rejection', specifications.trace_selenium_rejection) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.trace_selenium_rejection}</s>&nbsp;{CheckQualityDiffHandler('trace_selenium_rejection', specifications.trace_selenium_rejection)} </> : specifications.trace_selenium_rejection ? specifications.trace_selenium_rejection : "-"}

                              </span>
                            }
                          </div>
                        </div>

                        <label className="form_section_label mb-0 mt-2">
                          Boron (ppm)
                        </label>
                        <div className='row'>
                          <div className='col-lg-3 p-0'>
                            <label className="contract_display_header_label">Basis</label>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('trace_boron_basis_iso', specifications.trace_boron_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.trace_boron_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('trace_boron_basis_iso', specifications.trace_boron_basis_iso)} </> : specifications.trace_boron_basis_iso ? specifications.trace_boron_basis_iso : "-"}
                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('trace_boron_basis', specifications.trace_boron_basis) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.trace_boron_basis}</s>&nbsp;{CheckQualityDiffHandler('trace_boron_basis', specifications.trace_boron_basis)} </> : specifications.trace_boron_basis ? specifications.trace_boron_basis : "-"}

                              </span>
                            }
                          </div>

                          <div className='col-lg-3 p-0'>
                            <label className="contract_display_header_label"> Typical</label>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('trace_boron_typical_iso', specifications.trace_boron_typical_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.trace_boron_typical_iso}</s>&nbsp;{CheckQualityDiffHandler('trace_boron_typical_iso', specifications.trace_boron_typical_iso)} </> : specifications.trace_boron_typical_iso ? specifications.trace_boron_typical_iso : "-"}

                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('trace_boron_typical', specifications.trace_boron_typical) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.trace_boron_typical}</s>&nbsp;{CheckQualityDiffHandler('trace_boron_typical', specifications.trace_boron_typical)} </> : specifications.trace_boron_typical ? specifications.trace_boron_typical : "-"}

                              </span>
                            }
                          </div>

                          <div className='col-lg-3 p-0'>
                            <label className="contract_display_header_label"> Rejection</label>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('trace_boron_rejection_iso', specifications.trace_boron_rejection_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.trace_boron_rejection_iso}</s>&nbsp;{CheckQualityDiffHandler('trace_boron_rejection_iso', specifications.trace_boron_rejection_iso)} </> : specifications.trace_boron_rejection_iso ? specifications.trace_boron_rejection_iso : "-"}

                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('trace_boron_rejection', specifications.trace_boron_rejection) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.trace_boron_rejection}</s>&nbsp;{CheckQualityDiffHandler('trace_boron_rejection', specifications.trace_boron_rejection)} </> : specifications.trace_boron_rejection ? specifications.trace_boron_rejection : "-"}

                              </span>
                            }
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12 row">
                      <div className="col-lg-3">
                        <label className="form_label mb-0 mt-2" style={{ fontWeight: "bold" }}>
                          Minor Elements
                        </label>
                      </div>
                      <div className='col-lg-9'>
                        <label className="form_section_label mb-0 mt-2">
                          Ba, Be, Cd, Cr, Cu, Fe, Mn, Ni, Pb, V,
                          Zn, Sr, Sb, Co, Mo (ppm)
                        </label>
                        <div className='row'>
                          <div className='col-lg-3 p-0'>
                            <label className="contract_display_header_label">Basis</label>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('minor_ba_basis_iso', specifications.minor_ba_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.minor_ba_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('minor_ba_basis_iso', specifications.minor_ba_basis_iso)} </> : specifications.minor_ba_basis_iso ? specifications.minor_ba_basis_iso : "-"}

                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('minor_ba_basis', specifications.minor_ba_basis) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.minor_ba_basis}</s>&nbsp;{CheckQualityDiffHandler('minor_ba_basis', specifications.minor_ba_basis)} </> : specifications.minor_ba_basis ? specifications.minor_ba_basis : "-"}
                              </span>
                            }
                          </div>
                          <div className='col-lg-3 p-0'>
                            <label className="contract_display_header_label">Typical</label>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('minor_ba_typical_iso', specifications.minor_ba_typical_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.minor_ba_typical_iso}</s>&nbsp;{CheckQualityDiffHandler('minor_ba_typical_iso', specifications.minor_ba_typical_iso)} </> : specifications.minor_ba_typical_iso ? specifications.minor_ba_typical_iso : "-"}
                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('minor_ba_typical', specifications.minor_ba_typical) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.minor_ba_typical}</s>&nbsp;{CheckQualityDiffHandler('minor_ba_typical', specifications.minor_ba_typical)} </> : specifications.minor_ba_typical ? specifications.minor_ba_typical : "-"}
                              </span>
                            }
                          </div>

                          <div className='col-lg-3 p-0'>
                            <label className="contract_display_header_label"> Rejection</label>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('minor_ba_rejection_iso', specifications.minor_ba_rejection_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.minor_ba_rejection_iso}</s>&nbsp;{CheckQualityDiffHandler('minor_ba_rejection_iso', specifications.minor_ba_rejection_iso)} </> : specifications.minor_ba_rejection_iso ? specifications.minor_ba_rejection_iso : "-"}
                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('minor_ba_rejection', specifications.minor_ba_rejection) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.minor_ba_rejection}</s>&nbsp;{CheckQualityDiffHandler('minor_ba_rejection', specifications.minor_ba_rejection)} </> : specifications.minor_ba_rejection ? specifications.minor_ba_rejection : "-"}
                              </span>
                            }
                          </div>
                        </div>

                        <label className="form_section_label mb-0 mt-2">
                          Phosphorous,Chlorine (Cl),Flourine (F)
                          (ppm)
                        </label>
                        <div className='row'>
                          <div className='col-lg-3 p-0'>
                            <label className="contract_display_header_label">Basis</label>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('minor_phosphorous_basis_iso', specifications.minor_phosphorous_basis_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.minor_phosphorous_basis_iso}</s>&nbsp;{CheckQualityDiffHandler('minor_phosphorous_basis_iso', specifications.minor_phosphorous_basis_iso)} </> : specifications.minor_phosphorous_basis_iso ? specifications.minor_phosphorous_basis_iso : "-"}
                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('minor_phosphorous_basis', specifications.minor_phosphorous_basis) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.minor_phosphorous_basis}</s>&nbsp;{CheckQualityDiffHandler('minor_phosphorous_basis', specifications.minor_phosphorous_basis)} </> : specifications.minor_phosphorous_basis ? specifications.minor_phosphorous_basis : "-"}
                              </span>
                            }
                          </div>
                          <div className='col-lg-3 p-0'>
                            <label className="contract_display_header_label"> Typical</label>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('minor_phosphorous_typical_iso', specifications.minor_phosphorous_typical_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.minor_phosphorous_typical_iso}</s>&nbsp;{CheckQualityDiffHandler('minor_phosphorous_typical_iso', specifications.minor_phosphorous_typical_iso)} </> : specifications.minor_phosphorous_typical_iso ? specifications.minor_phosphorous_typical_iso : "-"}
                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('minor_phosphorous_typical', specifications.minor_phosphorous_typical) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.minor_phosphorous_typical}</s>&nbsp;{CheckQualityDiffHandler('minor_phosphorous_typical', specifications.minor_phosphorous_typical)} </> : specifications.minor_phosphorous_typical ? specifications.minor_phosphorous_typical : "-"}
                              </span>
                            }
                          </div>

                          <div className='col-lg-3 p-0'>
                            <label className="contract_display_header_label"> Rejection</label>
                            {(specification_standard === "ISO" || specActiveTab === 1) ?

                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('minor_phosphorous_rejection_iso', specifications.minor_phosphorous_rejection_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.minor_phosphorous_rejection_iso}</s>&nbsp;{CheckQualityDiffHandler('minor_phosphorous_rejection_iso', specifications.minor_phosphorous_rejection_iso)} </> : specifications.minor_phosphorous_rejection_iso ? specifications.minor_phosphorous_rejection_iso : "-"}
                              </span>
                              :
                              <span className="contract_display_header_value text-uppercase">
                                {CheckQualityDiffHandler('minor_phosphorous_rejection', specifications.minor_phosphorous_rejection) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.minor_phosphorous_rejection}</s>&nbsp;{CheckQualityDiffHandler('minor_phosphorous_rejection', specifications.minor_phosphorous_rejection)} </> : specifications.minor_phosphorous_rejection ? specifications.minor_phosphorous_rejection : "-"}
                              </span>
                            }
                          </div>
                        </div>
                      </div>
                    </div>


                  </div>
                </>
              }
              {/*<!----------------------------- Others Table  ---------------->*/}
              {(activeTabIndex === 4) &&
                <>
                  <div className="row p-2">

                    <div className="col-lg-12 row">

                      <div className="col-lg-4 pl-0">
                        <label className="contract_display_header_label"> Coal Bulk Density (t/cu.m) </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('other_coal_bulk_density_typical_iso', specifications.other_coal_bulk_density_typical_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.other_coal_bulk_density_typical_iso}</s>&nbsp;{CheckQualityDiffHandler('other_coal_bulk_density_typical_iso', specifications.other_coal_bulk_density_typical_iso)} </> : specifications.other_coal_bulk_density_typical_iso ? specifications.other_coal_bulk_density_typical_iso : "-"}

                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('other_coal_bulk_density_typical', specifications.other_coal_bulk_density_typical) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.other_coal_bulk_density_typical}</s>&nbsp;{CheckQualityDiffHandler('other_coal_bulk_density_typical', specifications.other_coal_bulk_density_typical)} </> : specifications.other_coal_bulk_density_typical ? specifications.other_coal_bulk_density_typical : "-"}

                          </span>
                        }
                      </div>
                      <div className="col-lg-4 pl-0">
                        <label className="contract_display_header_label"> Stowage Factor (cuf/t) </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('other_stowage_factor_typical_iso', specifications.other_stowage_factor_typical_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.other_stowage_factor_typical_iso}</s>&nbsp;{CheckQualityDiffHandler('other_stowage_factor_typical_iso', specifications.other_stowage_factor_typical_iso)} </> : specifications.other_stowage_factor_typical_iso ? specifications.other_stowage_factor_typical_iso : "-"}

                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('other_stowage_factor_typical', specifications.other_stowage_factor_typical) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.other_stowage_factor_typical}</s>&nbsp;{CheckQualityDiffHandler('other_stowage_factor_typical', specifications.other_stowage_factor_typical)} </> : specifications.other_stowage_factor_typical ? specifications.other_stowage_factor_typical : "-"}

                          </span>
                        }
                      </div>

                      <div className="col-lg-4 pl-0">
                        <label className="contract_display_header_label">  IMSBC Group (A,B,C) </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('other_imsbc_group_typical_iso', specifications.other_imsbc_group_typical_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.other_imsbc_group_typical_iso}</s>&nbsp;{CheckQualityDiffHandler('other_imsbc_group_typical_iso', specifications.other_imsbc_group_typical_iso)} </> : specifications.other_imsbc_group_typical_iso ? specifications.other_imsbc_group_typical_iso : "-"}

                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('other_imsbc_group_typical', specifications.other_imsbc_group_typical) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.other_imsbc_group_typical}</s>&nbsp;{CheckQualityDiffHandler('other_imsbc_group_typical', specifications.other_imsbc_group_typical)} </> : specifications.other_imsbc_group_typical ? specifications.other_imsbc_group_typical : "-"}

                          </span>
                        }
                      </div>

                      <div className="col-lg-4 pl-0">
                        <label className="contract_display_header_label">G {"&"} Y Index </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('other_GandY_index_typical_iso', specifications.other_GandY_index_typical_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.other_GandY_index_typical_iso}</s>&nbsp;{CheckQualityDiffHandler('other_GandY_index_typical_iso', specifications.other_GandY_index_typical_iso)} </> : specifications.other_GandY_index_typical_iso ? specifications.other_GandY_index_typical_iso : "-"}

                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('other_GandY_index_typical', specifications.other_GandY_index_typical) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.other_GandY_index_typical}</s>&nbsp;{CheckQualityDiffHandler('other_GandY_index_typical', specifications.other_GandY_index_typical)} </> : specifications.other_GandY_index_typical ? specifications.other_GandY_index_typical : "-"}

                          </span>
                        }
                      </div>

                      <div className="col-lg-4 pl-0">
                        <label className="contract_display_header_label">Transmittance Value (Pm) </label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('other_transmittance_value_typical_iso', specifications.other_transmittance_value_typical_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.other_transmittance_value_typical_iso}</s>&nbsp;{CheckQualityDiffHandler('other_transmittance_value_typical_iso', specifications.other_transmittance_value_typical_iso)} </> : specifications.other_transmittance_value_typical_iso ? specifications.other_transmittance_value_typical_iso : "-"}

                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('other_transmittance_value_typical', specifications.other_transmittance_value_typical) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.other_transmittance_value_typical}</s>&nbsp;{CheckQualityDiffHandler('other_transmittance_value_typical', specifications.other_transmittance_value_typical)} </> : specifications.other_transmittance_value_typical ? specifications.other_transmittance_value_typical : "-"}

                          </span>
                        }
                      </div>

                      <div className="col-lg-4 pl-0">
                        <label className="contract_display_header_label">Max lump size (mm)</label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('other_max_lump_size_typical_iso', specifications.other_max_lump_size_typical_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.other_max_lump_size_typical_iso}</s>&nbsp;{CheckQualityDiffHandler('other_max_lump_size_typical_iso', specifications.other_max_lump_size_typical_iso)} </> : specifications.other_max_lump_size_typical_iso ? specifications.other_max_lump_size_typical_iso : "-"}

                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('other_max_lump_size_typical', specifications.other_max_lump_size_typical) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.other_max_lump_size_typical}</s>&nbsp;{CheckQualityDiffHandler('other_max_lump_size_typical', specifications.other_max_lump_size_typical)} </> : specifications.other_max_lump_size_typical ? specifications.other_max_lump_size_typical : "-"}

                          </span>
                        }
                      </div>

                      <div className="col-lg-4 pl-0">
                        <label className="contract_display_header_label">Angle of Repose Minimum</label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('other_angle_minimum_typical_iso', specifications.other_angle_minimum_typical_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.other_angle_minimum_typical_iso}</s>&nbsp;{CheckQualityDiffHandler('other_angle_minimum_typical_iso', specifications.other_angle_minimum_typical_iso)} </> : specifications.other_angle_minimum_typical_iso ? specifications.other_angle_minimum_typical_iso : "-"}

                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('other_angle_minimum_typical', specifications.other_angle_minimum_typical) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.other_angle_minimum_typical}</s>&nbsp;{CheckQualityDiffHandler('other_angle_minimum_typical', specifications.other_angle_minimum_typical)} </> : specifications.other_angle_minimum_typical ? specifications.other_angle_minimum_typical : "-"}

                          </span>
                        }
                      </div>

                      <div className="col-lg-4 pl-0">
                        <label className="contract_display_header_label"> Angle of Repose Maximum</label>
                        {(specification_standard === "ISO" || specActiveTab === 1) ?

                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('other_angle_maximum_typical_iso', specifications.other_angle_maximum_typical_iso) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.other_angle_maximum_typical_iso}</s>&nbsp;{CheckQualityDiffHandler('other_angle_maximum_typical_iso', specifications.other_angle_maximum_typical_iso)} </> : specifications.other_angle_maximum_typical_iso ? specifications.other_angle_maximum_typical_iso : "-"}

                          </span>
                          :
                          <span className="contract_display_header_value text-uppercase">
                            {CheckQualityDiffHandler('other_angle_maximum_typical', specifications.other_angle_maximum_typical) ? <><s style={{ textDecorationColor: 'red' }}>{specifications.other_angle_maximum_typical}</s>&nbsp;{CheckQualityDiffHandler('other_angle_maximum_typical', specifications.other_angle_maximum_typical)} </> : specifications.other_angle_maximum_typical ? specifications.other_angle_maximum_typical : "-"}

                          </span>
                        }
                      </div>

                    </div>

                  </div>
                </>
              }
            </div>
          </div>

        </div>

        {/* <div className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button"
              name="submit"
              onClick={() => closeDrawerHandler(false)}
            >
              Close
            </button>
          </div> */}
        <div
          className="row col-lg-12 modal-footer justify-content-end bottom_buttons_section"
          style={{ bottom: 0, background: "white" }}
        >
          <button
            type="button"
            className="drawer_button drawer_text drawer_calculate_button drawer_calculate_adjustment"
            onClick={() => closeDrawerHandler(false)}
          >
            Close
          </button>
        </div>
      </div>

    </Drawer>
  )
}
