import React, { Component } from "react";
import api from "../../../api/api";
import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import { Alert } from "@material-ui/lab";
import {
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { withStyles } from "@material-ui/core/styles";
import FileUpload from "../../common/FileUpload";
import Loader from "../../common/Loader";
import { toLocaleString } from "../../common/common";

const NewRadio = withStyles({
  root: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export default class Barge_Purchase_Fin_Doc_Update extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      invoice_date: null,
      ppn: "",
      dc_applicable: "No",
      dc_quantity_in_mt: "",
      dc_documentation_party: "Third Party",
      dc_documentation_by: "",
      dc_price_per_mt: "",
      billing_currency:
        config.company === "SRPL" ||
          config.company === "AVS" ||
          config.company === "PRPL"
          ? "USD"
          : "0",
      dc_currency_xchg_rate: "",
      dc_ctc_in_local_currency: "",
      dc_base_price: "",
      dc_vat_percent: "10",
      dc_vat_value: "",
      dc_ctc: "",
      dc_total: "",
      dc_pph23_percent: "2",
      dc_pph23_value: "",

      isLoading: true,
      activeStep: 0,
      vendorTypeAsOthers: [],
      features: [],
      files: [],
      costing_files: [],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    // if (!(features.includes('184')  || features.includes('97') )) {
    //   window.location.href = '/dashboard';
    // }
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    // for get_Currencies
    await api.getCurrencies(loginUserID, idToken).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            if (res.Currency) {
              var currencies = [
                <MenuItem
                  style={{ fontFamily: "Poppins", fontSize: "14px" }}
                  value="0"
                  key={-1}
                  disabled
                >
                  Please Select
                </MenuItem>,
              ];
              for (var i in res.Currency) {
                currencies.push(
                  <MenuItem
                    style={{ fontFamily: "Poppins", fontSize: "14px" }}
                    value={res.Currency[i].currency}
                    key={[i]}
                  >
                    {res.Currency[i].currency}
                  </MenuItem>
                );
              }
              this.setState({
                currencyData: currencies,
              });
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          } else if (res.code === "601") {
            this.setState({
              errorMsg: res.message,
              successMsg: "",
              isLoading: false,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });

    // calling Vendors Api
    let status = "Active ";
    await api.getVendors(loginUserID, idToken, status).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            if (res.vendors) {
              const surveyors = [];
              const vendorAsOthers = [];
              for (var i of res.vendors) {
                if (i.vendor_type === "Surveyor") {
                  surveyors.push({ name: i.vendor_name, value: i.id, key: i });
                }
                if (i.vendor_type === "Others" || i.vendor_type === "Coal") {
                  vendorAsOthers.push({
                    name: i.vendor_name,
                    value: i.id,
                    key: i,
                  });
                }
              }
              this.setState({
                vendorTypeAsSurveyors: surveyors,
                vendorTypeAsOthers: vendorAsOthers,
              });
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });

    const doc_charges_id = this.props.docID;
    await api
      .get_barge_doc_charges(loginUserID, idToken, doc_charges_id)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.barge_doc_charges) {
                this.setState((prevState) => ({
                  ...prevState,
                  ...res.barge_doc_charges[0],
                  isLoading: false,
                }));
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "607") {
              //window.location.href = '/logout';
            }
          });
        }
      });

    document.title =
      config.documentTitle + "Barge Purchase Financial Documentation Charges";
  }

  documentation_charges_calculations_handler() {
    var dc_base_price = 0;
    if (this.state.dc_documentation_party === "Third Party") {
      dc_base_price = (
        Number(this.state.dc_quantity_in_mt) *
        Number(this.state.dc_price_per_mt)
      ).toFixed(4);
    } else {
      //dc_base_price = Number(this.state.dc_quantity_in_mt);
    }
    let dc_currency_xchg_rate = this.state.dc_currency_xchg_rate
      ? this.state.dc_currency_xchg_rate
      : 1;

    dc_base_price = (
      (Number(this.state.dc_quantity_in_mt) *
        Number(this.state.dc_price_per_mt)) /
      Number(dc_currency_xchg_rate)
    ).toFixed(4);

    if (this.state.dc_price_type === "Lumpsum") {
      dc_base_price = (
        Number(this.state.dc_price_per_mt) / Number(dc_currency_xchg_rate)
      ).toFixed(4);
    }

    let dc_vat_value = (
      (Number(dc_base_price) * Number(this.state.dc_vat_percent)) /
      100
    ).toFixed(2);
    let dc_pph23_value = (
      (Number(dc_base_price) * Number(this.state.dc_pph23_percent)) /
      100
    ).toFixed(2);
    if (
      config.company === "SRPL" ||
      config.company === "AVS" ||
      config.company === "PRPL"
    ) {
      dc_pph23_value = 0;
    }
    let dc_total = (
      Number(dc_base_price) +
      Number(dc_vat_value) -
      Number(dc_pph23_value)
    ).toFixed(2);
    let dc_ctc = (Number(dc_base_price) + Number(dc_vat_value)).toFixed(2);
    let dc_ctc_in_local_currency = dc_ctc;

    this.setState({
      dc_base_price: dc_base_price,
      dc_vat_value: dc_vat_value,
      dc_pph23_value: dc_pph23_value,
      dc_total: dc_total,
      dc_ctc: dc_ctc,
      dc_ctc_in_local_currency: dc_ctc_in_local_currency,
    });
  }

  submitHandler = () => {
    this.setState({ isLoading: true });
    let {
      invoice_no,
      invoice_date,
      ppn,
      ppn_date,
      dc_applicable,
      dc_documentation_party,
      dc_documentation_by,
      dc_quantity_in_mt,
      dc_price_per_mt,
      dc_base_price,
      dc_pph23_account,
      dc_vat_percent,
      dc_vat_value,
      dc_pph23_percent,
      dc_pph23_value,
      dc_total,
      dc_ctc,
      billing_currency,
      dc_currency_xchg_rate,
      dc_ctc_in_local_currency,
    } = this.state;

    if (
      billing_currency === "USD" &&
      (config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL")
    ) {
      dc_currency_xchg_rate = 1;
    }

    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    /**
     *  Checking Mandatory files
     */
    var missing_fields = 0;
    let files_missing = 0;
    var files_data = this.state.files;
    for (var obj of files_data) {
      if (obj.file_type !== "" || obj.file_content !== "") {
        if (obj.file_content !== "") {
          if (obj.file_description === "") {
            missing_fields++;
            obj.file_description_error = true;
          } else {
            obj.file_description = obj.file_description.toUpperCase();
          }
        } else if (obj.file_description !== "") {
          if (obj.file_content === "") {
            files_missing++;
          }
        }
      }
    }
    this.setState({
      files: files_data,
    });

    if (missing_fields > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please fill mandatory fields.",
      });
      return;
    }
    if (files_missing > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please Select file.",
      });
      return;
    }
    var existing_file_ids = [];
    const existing_files = files_data.filter((e) => e.id);
    for (var j of existing_files) {
      existing_file_ids.push(j.id);
    }
    const new_files = files_data.filter((e) => !e.id);
    const content_files = new_files.filter((e) => e.file_content !== "");
    const doc_charges_id = this.props.docID;
    const costing = this.state.costing_files?.filter(
      (e) => e.file_content !== "" && e.column === "invoice_file"
    );
    api
      .update_barge_doc_charges(
        loginUserID,
        idToken,
        doc_charges_id,
        invoice_no,
        invoice_date,
        ppn,
        ppn_date,
        dc_applicable,
        dc_documentation_party,
        dc_documentation_by,
        dc_quantity_in_mt,
        dc_price_per_mt,
        dc_base_price,
        dc_pph23_account,
        dc_vat_percent,
        dc_vat_value,
        dc_pph23_percent,
        dc_pph23_value,
        dc_total,
        dc_ctc,
        billing_currency,
        dc_currency_xchg_rate,
        dc_ctc_in_local_currency,
        existing_file_ids,
        content_files,
        costing
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            this.props.onUpdateCosting(res.code);
            if (res.code === "200") {
              this.setState({
                snackBarSuccessOpen: true,
                sucessMsg: "Costing Updated sucessfully.",
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  };

  render() {
    const pr = /^\d*(\.\d{0,4})?$/;
    const quan = /^\d*(\.\d{0,3})?$/;
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div className="container bg-white p-0" style={{ minHeight: "100vh" }}>
          <div className="clearfix content-header border-bottom">
            <div className="row">
              <div className="col-sm text-left pl-0">
                <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                  {`Documentation Charges - ${this.state.costing_no}`}{" "}
                </h4>
              </div>
            </div>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.sucessMsg}
            </Alert>
          </Snackbar>
          {this.props.updateDocuments ? (
            <div className="drawer-section">
              <div className="row">
                <div className="col-12">
                  <FileUpload
                    files={this.state.files}
                    type="Costings"
                    invoice_file={this.state.invoice_file}
                    feature_name="Barge_doc_charges"
                    callbackFn={(data, mandatoryFiles) => {
                      this.setState({
                        files: data,
                        costing_files: mandatoryFiles,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="drawer-section">
              <div className="card">
                <div className="" style={{ width: "100%" }}>
                  <div style={{ width: "100%" }}>
                    <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                      Add details to create Documentation Charges costing
                    </h5>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 rounded_lg p-2 mb-3">
                      <div className="row mb-3">
                        <div className="col-lg-5 pl-0">
                          <label className="form_label mb-0">
                            Documentation Charges Applicable
                          </label>
                          <RadioGroup
                            aria-label="dc_applicable"
                            name="dc_applicable"
                            onChange={(e) => {
                              this.setState({
                                dc_applicable: e.target.value,
                              });
                            }}
                            style={{ flexDirection: "row" }}
                          >
                            <FormControlLabel
                              value="Yes"
                              control={
                                <NewRadio
                                  checked={this.state.dc_applicable === "Yes"}
                                />
                              }
                              label="Yes"
                            />
                            <FormControlLabel
                              value="No"
                              control={
                                <NewRadio
                                  checked={this.state.dc_applicable === "No"}
                                />
                              }
                              label="No"
                            />
                          </RadioGroup>
                        </div>

                        {this.state.dc_applicable === "Yes" && (
                          <div className="col-lg-12 row border_dashed rounded_lg p-2 mb-3">
                            <div className="col-lg-6 pl-0 mb-3">
                              <div className="row">
                                <div className="col-lg-12 p-0">
                                  <label className="form_label mb-0">
                                    Documentation Party
                                  </label>
                                  <RadioGroup
                                    aria-label="dc_documentation_party"
                                    name="dc_documentation_party"
                                    onChange={(e) => {
                                      this.setState(
                                        {
                                          dc_documentation_party:
                                            e.target.value,
                                        },
                                        () => {
                                          this.documentation_charges_calculations_handler();
                                        }
                                      );
                                    }}
                                    style={{ flexDirection: "row" }}
                                  >
                                    <FormControlLabel
                                      value="Third Party"
                                      control={
                                        <NewRadio
                                          checked={
                                            this.state
                                              .dc_documentation_party ===
                                            "Third Party"
                                          }
                                        />
                                      }
                                      label="Third Party"
                                    />
                                    <FormControlLabel
                                      value="Internal"
                                      control={
                                        <NewRadio
                                          checked={
                                            this.state
                                              .dc_documentation_party ===
                                            "Internal"
                                          }
                                        />
                                      }
                                      label="Internal"
                                    />
                                  </RadioGroup>
                                </div>
                                <div className="col-lg-6 p-0 mb-2">
                                  <label className="form_label mb-2">
                                    Documentation by
                                  </label>
                                  <Autocomplete
                                    options={this.state.vendorTypeAsOthers}
                                    getOptionLabel={(option) => option.name}
                                    size="small"
                                    onChange={(event, value) => {
                                      if (value) {
                                        this.setState({
                                          dc_documentation_by: value.value,
                                          dc_documentation_name: value,
                                        });
                                      } else {
                                        this.setState({
                                          dc_documentation_by: "",
                                          dc_documentation_name: {
                                            name: "",
                                            value: "",
                                          },
                                        });
                                      }
                                    }}
                                    name="vendorName"
                                    value={this.state.dc_documentation_name}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="Search Vendor"
                                        variant="outlined"
                                        //error={this.state.vendorTypeError}
                                        fullWidth
                                      />
                                    )}
                                  />
                                </div>
                                <div className="col-lg-6 pr-0 mb-2">
                                  <label className="form_label mb-0">
                                    Quantity in MT
                                  </label>
                                  <TextField
                                    name="dc_quantity_in_mt"
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth
                                    type="number"
                                    inputProps={{
                                      style: {
                                        fontFamily: "Poppins",
                                        // fontSize: '14px'
                                      },
                                    }}
                                    value={this.state.dc_quantity_in_mt}
                                    placeholder="Quantity in MT"
                                    onChange={(e) => {
                                      if (
                                        e.target.value === "" ||
                                        quan.test(e.target.value)
                                      ) {
                                        this.setState(
                                          {
                                            dc_quantity_in_mt: e.target.value,
                                          },
                                          () => {
                                            this.documentation_charges_calculations_handler();
                                          }
                                        );
                                      }
                                    }}
                                  />
                                </div>
                                <div className="col-lg-12 p-0 mb-2">
                                  <label className="form_label mb-0">
                                    Price Type
                                  </label>
                                  <RadioGroup
                                    aria-label="dc_price_type"
                                    name="dc_price_type"
                                    onChange={(e) => {
                                      this.setState(
                                        {
                                          dc_price_type: e.target.value,
                                        },
                                        () =>
                                          this.documentation_charges_calculations_handler()
                                      );
                                    }}
                                    style={{ flexDirection: "row" }}
                                  >
                                    <FormControlLabel
                                      value="Per MT"
                                      control={
                                        <NewRadio
                                          checked={
                                            this.state.dc_price_type ===
                                            "Per MT"
                                          }
                                        />
                                      }
                                      label="Per MT"
                                    />
                                    <FormControlLabel
                                      value="Lumpsum"
                                      control={
                                        <NewRadio
                                          checked={
                                            this.state.dc_price_type ===
                                            "Lumpsum"
                                          }
                                        />
                                      }
                                      label="Lumpsum"
                                    />
                                  </RadioGroup>
                                </div>

                                {this.state.dc_documentation_party ===
                                  "Third Party" && (
                                    <div className="col-lg-12 p-0 mb-2">
                                      <label className="form_label mb-0">
                                        {this.state.dc_price_type === "Per MT"
                                          ? "Price"
                                          : "Lumpsum"}
                                      </label>
                                      <TextField
                                        name="dc_price_per_mt"
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth
                                        value={this.state.dc_price_per_mt}
                                        placeholder={
                                          this.state.dc_price_type === "Per MT"
                                            ? "Price"
                                            : "Lumpsum"
                                        }
                                        onChange={(e) => {
                                          if (
                                            e.target.value === "" ||
                                            pr.test(e.target.value)
                                          ) {
                                            this.setState(
                                              {
                                                dc_price_per_mt: e.target.value,
                                              },
                                              () => {
                                                this.documentation_charges_calculations_handler();
                                              }
                                            );
                                          }
                                        }}
                                        InputProps={{
                                          endAdornment: (config.company ===
                                            "SRPL" ||
                                            config.company === "PRPL" ||
                                            config.company === "AVS") && (
                                              <InputAdornment
                                                className="adornment_background"
                                                // variant="outlined"
                                                position="end"
                                                style={{
                                                  marginRight: "-11px",
                                                  marginTop: "-2px",
                                                  width: "50%",
                                                }}
                                              >
                                                <TextField
                                                  name="billing_currency"
                                                  margin="dense"
                                                  // variant='outlined'
                                                  fullWidth
                                                  select
                                                  InputProps={{
                                                    style: {
                                                      fontFamily: "Poppins",
                                                      fontSize: "14px",
                                                      margin: 0,
                                                    },
                                                    disableUnderline: true,
                                                  }}
                                                  value={
                                                    this.state.billing_currency
                                                  }
                                                  onChange={(e) => {
                                                    this.setState(
                                                      {
                                                        billing_currency:
                                                          e.target.value,
                                                      },
                                                      () => {
                                                        this.documentation_charges_calculations_handler();
                                                      }
                                                    );
                                                  }}
                                                >
                                                  {this.state.currencyData}
                                                </TextField>
                                              </InputAdornment>
                                            ),
                                          style: {
                                            fontFamily: "Poppins",
                                            fontSize: "14px",
                                          },
                                        }}
                                      />
                                    </div>
                                  )}
                                {this.state.dc_documentation_party ===
                                  "Internal" &&
                                  (config.company === "SRPL" ||
                                    config.company === "AVS" ||
                                    config.company === "PRPL") && (
                                    <div className="col-lg-12 p-0 mb-2">
                                      <label className="form_label mb-0">
                                        Currency
                                      </label>
                                      <TextField
                                        name="billing_currency"
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth
                                        placeholder="Currency"
                                        label="Currency"
                                        value={this.state.billing_currency}
                                        select
                                        inputProps={{
                                          style: {
                                            fontFamily: "Poppins",
                                            fontSize: "14px",
                                          },
                                        }}
                                        onChange={(e) => {
                                          this.setState(
                                            {
                                              billing_currency: e.target.value,
                                            },
                                            () => {
                                              this.documentation_charges_calculations_handler();
                                            }
                                          );
                                        }}
                                      >
                                        {this.state.currencyData}
                                      </TextField>
                                    </div>
                                  )}
                                {(config.company === "SRPL" ||
                                  config.company === "AVS" ||
                                  config.company === "PRPL") &&
                                  this.state.billing_currency !== "USD" &&
                                  this.state.billing_currency !== "0" && (
                                    <div className="col-lg-6 p-0 mb-2">
                                      <label className="form_label mb-0">
                                        Currency Exchange Rate
                                      </label>
                                      <TextField
                                        name="dc_currency_xchg_rate"
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth
                                        placeholder="Exchange rate to USD"
                                        value={this.state.dc_currency_xchg_rate}
                                        type="number"
                                        inputProps={{
                                          style: {
                                            fontFamily: "Poppins",
                                            fontSize: "14px",
                                          },
                                        }}
                                        onChange={(e) => {
                                          this.setState(
                                            {
                                              dc_currency_xchg_rate:
                                                e.target.value,
                                            },
                                            () => {
                                              this.documentation_charges_calculations_handler();
                                            }
                                          );
                                        }}
                                      />
                                    </div>
                                  )}
                              </div>
                            </div>
                            <div
                              className="col-lg-6 p-2 rounder"
                              style={{ backgroundColor: "#f7f3f0" }}
                            >
                              <div className="row col-lg-12 p-1">
                                <h6>Documentation Charges</h6>
                              </div>
                              <div className="row pl-2">
                                <div className="col-lg-4 p-0 mb-2">
                                  <label className="form_label mb-0">
                                    Base Price
                                  </label>
                                  <TextField
                                    name="dc_base_price"
                                    margin="dense"
                                    variant="standard"
                                    inputProps={{
                                      style: {
                                        fontFamily: "Poppins",
                                        fontSize: "14px",
                                      },
                                    }}
                                    //fullWidth
                                    value={this.state.dc_base_price}
                                    onChange={(e) => {
                                      if (
                                        e.target.value === "" ||
                                        pr.test(e.target.value)
                                      ) {
                                        var value = e.target.value;
                                        if (value === "") {
                                          value = "1";
                                        }
                                        var dc_vat_value = (
                                          (Number(value) *
                                            Number(this.state.dc_vat_percent)) /
                                          100
                                        ).toFixed(0);
                                        let dc_pph23_value = (
                                          (Number(value) *
                                            Number(
                                              this.state.dc_pph23_percent
                                            )) /
                                          100
                                        ).toFixed(0);
                                        if (
                                          config.company === "SRPL" ||
                                          config.company === "AVS" ||
                                          config.company === "PRPL"
                                        ) {
                                          dc_pph23_value = 0;
                                        }
                                        let dc_ctc = (
                                          Number(e.target.value) +
                                          Number(dc_vat_value)
                                        ).toFixed(2);
                                        let dc_ctc_in_local_currency = dc_ctc;
                                        this.setState({
                                          dc_base_price: e.target.value,
                                          dc_vat_value: dc_vat_value,
                                          dc_pph23_value: dc_pph23_value,
                                          dc_total: (
                                            Number(e.target.value) +
                                            Number(dc_vat_value) -
                                            Number(dc_pph23_value)
                                          ).toFixed(2),
                                          dc_ctc: dc_ctc,
                                          dc_ctc_in_local_currency:
                                            dc_ctc_in_local_currency,
                                        });
                                      }
                                    }}
                                  />
                                </div>
                                <div className="col-lg-4 mb-2 pr-0">
                                  <label
                                    className="form_label mb-0"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    VAT %
                                  </label>
                                  <TextField
                                    name="dc_vat_percent"
                                    margin="dense"
                                    variant="standard"
                                    fullWidth
                                    inputProps={{
                                      style: {
                                        fontFamily: "Poppins",
                                        fontSize: "14px",
                                      },
                                    }}
                                    value={this.state.dc_vat_percent}
                                    onChange={(e) => {
                                      if (
                                        e.target.value === "" ||
                                        pr.test(e.target.value)
                                      ) {
                                        this.setState(
                                          {
                                            dc_vat_percent: e.target.value,
                                          },
                                          () => {
                                            this.documentation_charges_calculations_handler();
                                          }
                                        );
                                      }
                                    }}
                                  />
                                   {/* <label
                                    className="contract_display_header_label"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    VAT %
                                  </label> 
                                  <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {this.state.dc_vat_percent}
                                    </div> */}
                                </div>
                                <div className="col-lg-4 mb-2 p-0">
                                  <label className="form_label mb-0">VAT</label>
                                  <TextField
                                    name="dc_vat_value"
                                    margin="dense"
                                    variant="standard"
                                    fullWidth
                                    inputProps={{
                                      style: {
                                        fontFamily: "Poppins",
                                        fontSize: "14px",
                                      },
                                    }}
                                    value={this.state.dc_vat_value}
                                    onChange={(e) => {
                                      if (
                                        e.target.value === "" ||
                                        pr.test(e.target.value)
                                      ) {
                                        let dc_pph23_value = (
                                          (Number(this.state.dc_base_price) *
                                            Number(
                                              this.state.dc_pph23_percent
                                            )) /
                                          100
                                        ).toFixed(0);
                                        if (
                                          config.company === "SRPL" ||
                                          config.company === "AVS" ||
                                          config.company === "PRPL"
                                        ) {
                                          dc_pph23_value = 0;
                                        }
                                        let dc_ctc = (
                                          Number(this.state.dc_base_price) +
                                          Number(e.target.value)
                                        ).toFixed(2);
                                        let dc_ctc_in_local_currency = "";
                                        if (
                                          (config.company === "SRPL" ||
                                            config.company === "AVS" ||
                                            config.company === "PRPL") &&
                                          this.state.billing_currency !==
                                          "USD" &&
                                          this.state.billing_currency !== "0"
                                        ) {
                                          dc_ctc_in_local_currency = (
                                            Number(
                                              this.state.dc_currency_xchg_rate
                                            ) * Number(dc_ctc)
                                          ).toFixed(0);
                                        }
                                        this.setState({
                                          dc_vat_value: e.target.value,
                                          dc_pph23_value: dc_pph23_value,
                                          dc_total:
                                            Number(this.state.dc_base_price) +
                                            Number(e.target.value) -
                                            Number(dc_pph23_value),
                                          dc_ctc: dc_ctc,
                                          dc_ctc_in_local_currency:
                                            dc_ctc_in_local_currency,
                                        });
                                      }
                                    }}
                                  />
                                </div>
                                {(config.company === "SDAM" ||
                                  config.company === "SRK" ||
                                  config.company === "BTR") && (
                                    <div className="col-lg-6 mb-2 p-0">
                                      <label
                                        className="contract_display_header_label"
                                        style={{
                                          color: "rgba(72, 59, 52, 0.7)",
                                        }}
                                      >
                                        PPH23 %
                                      </label>
                                      <div
                                        className="contract_display_header_value"
                                        style={{ color: "#42352d" }}
                                      >
                                        {this.state.dc_pph23_percent}
                                      </div>
                                    </div>
                                  )}
                                {(config.company === "SDAM" ||
                                  config.company === "SRK" ||
                                  config.company === "BTR") && (
                                    <div className="col-lg-6 mb-2 pr-0">
                                      <label
                                        className="contract_display_header_label"
                                        style={{
                                          color: "rgba(72, 59, 52, 0.7)",
                                        }}
                                      >
                                        PPH23
                                      </label>
                                      <div
                                        className="contract_display_header_value"
                                        style={{ color: "#42352d" }}
                                      >
                                        {toLocaleString(
                                          this.state.dc_pph23_value
                                        )}
                                      </div>
                                    </div>
                                  )}
                                <div className="col-lg-6 mb-2 p-0">
                                  <label
                                    className="contract_display_header_label"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    Payable to Vendor
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {toLocaleString(this.state.dc_total)}
                                  </div>
                                </div>
                                <div className="col-lg-6 mb-2 pr-0">
                                  <label
                                    className="contract_display_header_label"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    Cost to Company
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {toLocaleString(this.state.dc_ctc)}
                                    {config.company === "SRPL" ||
                                      config.company === "AVS" ||
                                      config.company === "PRPL"
                                      ? "USD"
                                      : "IDR"}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button"
              name="previous"
              onClick={() => this.props.onCancel(false)}
            >
              CANCEL
            </button>
            <button
              type="button"
              className="btn next_button"
              name="next"
              onClick={this.submitHandler}
            >
              SUBMIT
            </button>
          </div>
        </div>
      );
    }
  }
}
