

import React from 'react'
import config from '../../config/config';
const ViewFiles = (props) => {

  return (
    <>
      <div className="card border col-lg-12 mb-3">
        <span
          className="contract_display_header_value pl-0 mb-2"
          style={{ fontWeight: "bold" }}
        >
          Uploaded Documents
        </span>
        {props.type === "Costings" &&
          <>
            <div className='row mb-0'>
              {/* <div className='col-lg-4 p-0'>
                <label className='form_label mb-0'>{props.feature_name === "Business_no_credit_note" ? 'CREDIT NOTE' : props.feature_name === "Business_no_debit_note" ? "DEBIT NOTE" : "INVOICE"} </label>
              </div> */}

              {(props.invoice_file && props.invoice_file !== '' && props.invoice_file !== null) &&

                <div className='col-lg-4'>
                  <a href={config.apiDomain + "/" + props.invoice_file} role="button" target='_blank' rel="noopener noreferrer" style={{ color: config.themeColor }}>{props.feature_name === "Business_no_credit_note" ? 'CREDIT NOTE' : props.feature_name === "Business_no_debit_note" ? "DEBIT NOTE" : "INVOICE"}</a>

                </div>
              }
            </div>
            {(props.feature_name === "Purchase_fin_coal_costing" || props.feature_name === "Sales_fin_commercial_coal_costing") &&
              <>
                <div className='row mb-0' >
                  {/* <div className='col-lg-4 p-0'>
                    <label className='form_label mb-0'>DSR</label>
                  </div> */}
                  {(props.dsr_file && props.dsr_file !== '' && props.dsr_file !== null) &&

                    <div className='col-lg-4'>
                      <a href={config.apiDomain + "/" + props.dsr_file} role="button" target='_blank' rel="noopener noreferrer" style={{ color: config.themeColor }}>DSR</a>

                    </div>
                  }
                </div>
                <div className='row mb-0'>
                  {/* <div className='col-lg-4 p-0'>
                    <label className='form_label mb-0'>COA</label>
                  </div> */}
                  {(props.coa_file && props.coa_file !== '' && props.coa_file !== null) &&

                    <div className='col-lg-4'>
                      <a href={config.apiDomain + "/" + props.coa_file} role="button" target='_blank' rel="noopener noreferrer" style={{ color: config.themeColor }}>COA</a>

                    </div>
                  }
                </div>
                <div className='row mb-0'>
                  {/* <div className='col-lg-4 p-0'>
                    <label className='form_label mb-0'>BL</label>
                  </div> */}
                  {(props.bl_file && props.bl_file !== '' && props.bl_file !== null) &&
                    <div className='col-lg-4'>
                      <a href={config.apiDomain + "/" + props.bl_file} role="button" target='_blank' rel="noopener noreferrer" style={{ color: config.themeColor }}>BL</a>
                    </div>
                  }
                </div>
              </>
            }
            <br />
            <hr />
          </>
        }

        {props?.files_data?.length > 0 ? (
          <div className="row">
            {props.files_data?.map((e, index) => (
              <div
                className={
                  e.file_path ? "col-lg-12 mb-3" : "col-lg-12"
                }
                key={index}
              >
                {e.file_path && (
                  <div className="col-lg-12 p-0">
                    <a
                      href={config.apiDomain + "/" + e.file_path}
                      role="button"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: config.themeColor }}
                    >
                      {e.file_description}
                    </a>
                  </div>
                )}
              </div>
            ))}
          </div>
        ) : (
          <div className="row">
            <p>No files to show</p>
          </div>
        )}
        {props.returnDocumentsHandler && (

          <p className="row">
            Add Documents &nbsp;
            <i
              className="fa fa-plus-circle"
              style={{
                color: config.themeColor,
                cursor: "pointer",
              }}
              onClick={() => {
                props.returnDocumentsHandler(true);
              }}
            />
          </p>
        )}
      </div>
    </>
  )
}

export default ViewFiles;