import React, { Component } from "react";

import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import api from "../../../api/api";
import ViewFiles from "../../common/ViewFiles";
import { Snackbar } from "@material-ui/core";

import { Alert } from "@material-ui/lab";
import DeleteConfirmation from "../../common/DeleteConfirmation";
import PaymentPostings from "../../common/PaymentPostings";
import { pad, toLocaleString, localDateFormate } from "../../common/common";
import Loader from "../../common/Loader";
import InvoiceForm from "../../common/InvoiceForm";
import InvoiceLink from "../../InvoicePdf/InvoiceLink";
import BusinessApproveButton from "../../Approvals/BusinessApproveButton";

export default class OtherExpenseView extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      barge_id: "",
      item_description: "",
      price: "",
      remarks: "",
      // files:[],
      other_expenses_data: [
        {
          item_description: "",
          // price: "",
          remarks: "",
          currency_xchg_rate: "",
          costing_id: "",
        },
      ],
      invoiceDetails: {
        ppn: "",
        ppn_date: null,
      },
      invoice_no: "",
      invoice_date: null,
      bargeList: [],
      vendor_name: "",
      mv_name: "",
      barges: [],
      activeStep: 0,
      isLoading: true,
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (!(features.includes("339") || features.includes("97"))) {
      window.location.href = "/dashboard";
    }
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const costing_id = this.props.costing_id;

    await api
      .get_barge_pur_fin_other_expenses(loginUserID, idToken, costing_id)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.other_expenses_data) {
                var total_payment_amount = 0;
                var remaining_amount = 0;
                for (var i = 0; i < res.other_expenses_data.length; i++) {
                  if (
                    res.other_expenses_data[i].other_expenses_payments.length >
                    0
                  ) {
                    for (
                      var j = 0;
                      j <
                      res.other_expenses_data[i].other_expenses_payments.length;
                      j++
                    ) {
                      total_payment_amount += Number(
                        res.other_expenses_data[i].other_expenses_payments[j]
                          .amount
                      );
                      remaining_amount =
                        Number(res.other_expenses_data[i].payable_to_vendor) -
                        Number(total_payment_amount);
                    }
                    res.other_expenses_data[i].total_payment_amount =
                      total_payment_amount;
                    res.other_expenses_data[i].remaining_amount =
                      remaining_amount;
                  } else {
                    res.other_expenses_data[i].remaining_amount = Number(
                      res.other_expenses_data[i].price
                    );
                  }
                }
                this.setState(
                  {
                    other_expenses_data: res.other_expenses_data,
                    price: res.other_expenses_data[0].price,
                    barge_id: res.other_expenses_data[0].barge_group_id,
                    currency_xchg_rate:
                      res.other_expenses_data[0].currency_xchg_rate,
                    invoice_no: res.other_expenses_data[0].invoice_no,
                    invoice_date: res.other_expenses_data[0].invoice_date,
                    costing_no: res.other_expenses_data[0].costing_no,
                  },
                  () => {
                    const barge_id = this.state.barge_id?.split(",")[0];
                    let request = {
                      login_user_id: this.Cookie.getCookie("loginUserId"),
                      idtoken: this.Cookie.getIdTokenCookie(),
                      barge_id: barge_id,
                      vendor_type: "Barge",
                    };
                    api
                      .get_business_no_info_for_barge(request)
                      .then((response) => {
                        if (response.status >= 200 && response.status < 300) {
                          response.json().then((res) => {
                            if (res.code === "200") {
                              this.setState({
                                vendor_name: res.vendor_name,
                                mv_name: res.mv_name,
                              });
                            }
                          });
                        }
                      });
                  }
                );
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
    await api
      .get_barges_for_other_expenses(loginUserID, idToken)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.barges) {
                let newData = [];
                var result = res.barges.reduce(function (r, a) {
                  r[a.id] = r[a.id] || [];
                  r[a.id].push(a);
                  return r;
                }, Object.create(null));
                for (let l = 0; l < Object.keys(result).length; l++) {
                  newData.push({ new_barges: result[Object.keys(result)[l]] });
                }
                let purchase_contracts_barges = newData;
                if (newData.length === 0) {
                  this.setState({
                    snackBarErrorOpen: true,
                    errorMsg: "No records to display",
                  });
                }
                this.setState({
                  barges: purchase_contracts_barges,
                  isLoading: false,
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  }

  deletePaymentPostingHandler = (e) => {
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    var other_expenses_id = this.props.costing_id;
    var other_expenses_payment_id = e.id;
    var payment_posting_type = e.payment_posting_type;
    var amount = e.amount;
    var date_of_payment = e.date_of_payment;
    var invoice_no = e.invoice_no ? e.invoice_no : "";
    var description = e.description ? e.description : "";
    var status = "Deleted";
    api
      .update_barge_pur_fin_other_expenses_payments(
        loginUserID,
        idToken,
        other_expenses_id,
        other_expenses_payment_id,
        payment_posting_type,
        amount,
        date_of_payment,
        invoice_no,
        description,
        status
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState({ deletePayment: false });
              this.componentDidMount();
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                succesMsg: "",
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  };

  renderPaymentPostings = (data) => {
    const {
      id,
      other_expenses_payments = [],
      invoice_no = null,
      remaining_amount = 0,
      currency_xchg_rate = 1,
      approval_status = "Pending",
    } = data;

    let payment_related_data = {
      paymentTitle: "Barge Other Expenses",
      payment_posting_type: "other_expenses",
      invoice_no: invoice_no,
      outstanding_amount: remaining_amount,
      billing_currency:
        config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL"
          ? "USD"
          : "IDR",
      currency_xchg_rate: currency_xchg_rate,
      costing_id: id,
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
    };
    let access_details = {
      costing_approval: approval_status,
      add_payment: "342",
      view_payment: "343",
      update_payment: "344",
      delete_payment: "345",
      approver: "552",
      approver2: "553",
    };

    return (
      <PaymentPostings
        payments={other_expenses_payments}
        access_details={access_details}
        costingType="Barge Other Expenses"
        data={payment_related_data}
        callbackFn={() => {
          this.componentDidMount();
        }}
      />
    );
  };

  updateInvoice = (data) => {
    let request = {
      ...data,
      table_name: "barge_pur_fin_other_expenses",
    };
    api.update_invoice_details(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.componentDidMount();
            this.setState({
              invoiceDialog: false,
            });
          }
        });
      }
    });
  };

  renderInvoiceLink = () => {
    const data = this.state.barges.map((e) =>
      e.new_barges.filter((obj) => obj.id === this.props.costing_id)
    );
    const { costing_no, billing_currency } = this.state;

    const Grand_total = parseFloat(
      this.state.other_expenses_data[0].price
        ? this.state.other_expenses_data[0].price
        : 0
    ).toFixed(2);
    const invoiceObject = {
      costing_id: costing_no,
      to: this.state.vendor_name,
      business_no: this.state.mv_name,
      costing_name: "Other Expenses",
      invoice_no: this.state.invoice_no,
      invoice_date: localDateFormate(this.state.invoice_date),
      bargeDetails: data ? data.flat() : [],
      billing_currency: billing_currency,
      total_payable_amount: toLocaleString(Grand_total),
      purchase_type: "FOB Barge",
      description: [
        this.state.other_expenses_data
          ? {
              qty: "1",
              adjustedPrice: " ",
              desc: `${
                this.state.other_expenses_data[0].currency_xchg_rate > 1
                  ? `Other Expenses @ ${parseFloat(
                      this.state.other_expenses_data[0].currency_xchg_rate
                    ).toFixed(2)}`
                  : "Other Expenses"
              }`,
              amount: this.state.other_expenses_data[0].price,
            }
          : [],
      ],
    };
    return <InvoiceLink data={invoiceObject} fileName={costing_no} />;
  };

  render() {
    const costing_id = this.props.costing_id;
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div className="container bg-white p-0" style={{ minHeight: "100vh" }}>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <div className="row border-bottom">
            <div className="col-lg-11 p-0 ">
              <h6 style={{ padding: "15px", marginBottom: 0 }}>
                Other Expenses
              </h6>
            </div>
            <div className="col-lg-1 pl-1">{this.renderInvoiceLink()}</div>
          </div>

          <div className="drawer-section">
            <div className="card p-0">
              {this.state.barges.length > 0 && (
                <div className="row p-2 m-2">
                  <div className="table-responsive">
                    <table className="table table-bordered table-sm mb-0 ">
                      <thead className="table-header">
                        <tr>
                          <th nowrap="true"> Barge ID </th>
                          <th nowrap="true"> Barge Nomination</th>
                          <th nowrap="true"> Business No </th>
                          <th nowrap="true"> item Description </th>
                          <th nowrap="true" className="text-right">
                            {" "}
                            Price{" "}
                          </th>
                        </tr>
                      </thead>
                      {this.state.barges.map((p, index) => (
                        <tbody key={index}>
                          {p.new_barges.map((idr, indx) => (
                            <tr
                              key={indx}
                              style={{
                                display: costing_id === idr.id ? "" : "none",
                              }}
                            >
                              <td className="table_td text-left">
                                <a
                                  href={"/view-barge/" + btoa(idr.barge_id)}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{ color: config.themeColor }}
                                >
                                  {pad(idr.barge_id)}
                                </a>
                              </td>
                              <td className="table_td text-left" nowrap="true">
                                {idr.barge_nomination}
                              </td>
                              <td className="table_td text-left" nowrap="true">
                                {idr.business_no}
                              </td>
                              <td className="table_td text-left" nowrap="true">
                                {idr.item_description}
                              </td>
                              {indx === 0 && (
                                <td
                                  className="table_td text-right"
                                  nowrap="true"
                                  rowSpan={p.new_barges.length}
                                >
                                  {toLocaleString(idr.price)}
                                </td>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      ))}
                    </table>
                  </div>
                </div>
              )}
              <div className="card-body">
                {this.state.other_expenses_data.map((e, idx) => (
                  <>
                    <div
                      className="row p-3 border_dashed mb-3 rounded-lg"
                      key={idx}
                    >
                      <div className="row col-lg-12 p-0">
                        {e.approval_status === "Pending" &&
                          e.invoice_file &&
                          this.Cookie.getCookie("loginUserId") !==
                            e.created_by &&
                          e.approved_by !==
                            this.Cookie.getCookie("loginUserId") &&
                          (this.state.features.includes("550") ||
                            this.state.features.includes("551") ||
                            this.state.features.includes("97")) && (
                            <BusinessApproveButton
                              costingID={e.id}
                              costingType={"Barge Other Expenses"}
                              accessInfo={{
                                ...e,
                                approver_1: "550",
                                approver_2: "551",
                              }}
                              callbackFn={() => this.componentDidMount()}
                            />
                          )}
                      </div>
                      {/* New Changes */}
                      <div className="col shadow-sm p-0 mb-2 bg-white border">
                        <div className="table-responsive">
                          <table className="table table-sm table-bordered mb-0">
                            <thead className="table-header">
                              <tr>
                                <th className="text-center">Description</th>
                                <th className="text-right">Qty</th>
                                <th className="text-right">Unit Price</th>
                                <th className="text-right">Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              <>
                                <tr>
                                  <td>
                                    Other Expenses
                                    {!(
                                      config.company === "SRPL" ||
                                      config.company === "AVS" ||
                                      config.company === "PRPL"
                                    ) &&
                                      e.currency_xchg_rate > 1 && (
                                        <div>
                                          Exchange Rate @{" "}
                                          {this.toLocaleString(
                                            e.currency_xchg_rate
                                          )}
                                        </div>
                                      )}
                                  </td>
                                  <td className="text-right"></td>
                                  <td className="text-right"></td>
                                  <td className="text-right">{e.price}</td>
                                </tr>
                                <tr>
                                  <td colSpan={3} className="text-right ">
                                    {" "}
                                    Payable to Vendor :{" "}
                                  </td>
                                  <td
                                    style={{ fontWeight: "bold" }}
                                    className="text-right"
                                  >
                                    {`${
                                      config.company === "SRPL" ||
                                      config.company === "PRPL" ||
                                      config.company === "AVS"
                                        ? "USD"
                                        : "IDR"
                                    } ${parseFloat(e.price).toFixed(2)}`}
                                  </td>
                                </tr>
                              </>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="col-lg-11 p-0">
                        <div className="row">
                          <div className="col-lg-6 pl-0">
                            <label className="form_label mb-0">Vendor</label>
                            <div className="">
                              {e.vendor_name ? e.vendor_name : "-"}
                            </div>
                          </div>
                          <div className="col-lg-6 pl-0">
                            <label className="form_label mb-0">
                              Item Description
                            </label>
                            <div className="">{e.item_description}</div>
                          </div>
                          <div className="col-lg-12 pl-0">
                            <label className="form_label mb-0">Remarks</label>
                            <div
                              className=""
                              style={{ whiteSpace: "pre-line" }}
                            >
                              {e.remarks}
                            </div>
                          </div>
                        </div>
                      </div>

                      {(this.state.features.includes("343") ||
                        this.state.features.includes("97")) && (
                        <div className="card p-0 col-lg-12 border mt-3 mb-3">
                          <div className="card-header section_header">
                            <h5>Payment Postings</h5>
                          </div>
                          <div className="card-body">
                            <InvoiceForm
                              open={this.state.invoiceDialog}
                              data={{
                                invoice_no: e.invoice_no,
                                invoice_date: e.invoice_date,
                                ppn: e.ppn,
                                ppn_date: e.ppn_date,
                              }}
                              costingID={e.id}
                              costing_type={"Barge Other Expenses"}
                              callbackFn={(data) => this.updateInvoice(data)}
                            />
                            {this.renderPaymentPostings(e)}
                          </div>
                        </div>
                      )}
                      <ViewFiles {...this.props} files_data={e.files} />
                    </div>
                    <div className="col shadow-sm p-0 mb-2 bg-white border">
                      <div className="table-responsive">
                        <table className="table table-sm table-bordered">
                          <thead className="table-header">
                            <tr>
                              <th className="text-center">Approval Type</th>
                              <th className="text-center">Approved by</th>
                              <th className="text-center">Approved On</th>
                            </tr>
                          </thead>
                          <tbody>
                            <>
                              <tr>
                                <td className="text-center">Business</td>
                                <td className="text-center">
                                  <div> {e.approved_by_name}</div>
                                </td>
                                <td className="text-center">
                                  {localDateFormate(e.approved_date)}
                                </td>
                              </tr>
                              <tr>
                                <td className="text-center">Financial</td>
                                <td className="text-center">
                                  {e.approved_by_name_2}
                                </td>
                                <td className="text-center">
                                  {localDateFormate(e.approved_date_2)}
                                </td>
                              </tr>
                            </>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>

          <footer className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button btn-light"
              name="submit"
              onClick={() => {
                this.props.returnCallbackDrawerClose(false);
              }}
            >
              Close
            </button>
            <button
              type="button"
              className="drawer_text drawer_btn_success"
              name="Back"
              onClick={() => {
                this.props.returnEditCallback(true);
              }}
            >
              Edit
            </button>
          </footer>
          {/**
           *
           *  Delete Payment Postings Modal
           *
           */}

          {this.state.deletePayment && (
            <DeleteConfirmation
              open={this.state.deletePayment}
              close={() => this.setState({ deletePayment: false })}
              callbackFn={() =>
                this.deletePaymentPostingHandler(this.state.deleteData)
              }
            />
          )}
        </div>
      );
    }
  }
}
