import React, { Component } from 'react'

import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import api from "../../../api/api";
import {
  Snackbar,
  // Tooltip,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { localDateFormate, toLocaleString } from "../../common/common";
import ViewFiles from "../../common/ViewFiles";
// import DeletePayments from "../../common/DeletePayments";
import Loader from "../../common/Loader";
import InvoiceLink from "../../InvoicePdf/InvoiceLink";
import AuditInfo from '../../common/AuditInfo';
import PaymentPostings from '../../common/PaymentPostings';
import InvoiceForm from '../../common/InvoiceForm';
export default class LaporanChargesView extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      multiRows: [
        {

          dc_laprn_surv_party: "",
          dc_laprn_surv_by: "",
          dc_laprn_surv_currency: "0",
          dc_laprn_surv_currency_xchg_rate: "",
          dc_laprn_surv_ctc_in_local_currency: "",
          dc_laprn_surv_price_per_mt: "",
          dc_laprn_surv_base_price: "",
          dc_laprn_surv_vat_percent: "10",
          dc_laprn_surv_vat_value: "",
          dc_laprn_surv_pph23_percent: "2",
          dc_laprn_surv_pph23_value: "",
          dc_laprn_surv_total: "",
          dc_laprn_surv_ctc: "",
          dc_cek_fisic: "",
        },
      ],
      isLoading: true,
      features: [],
      invoiceDialog: false,
      laparon_charges_payments: [],
      invoiceDetails: {
        invoice_no: "",
        invoice_date: null,
        ppn: "",
        ppn_date: null,
        invoice_percentage: "",
      },
      paid: 0,
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (
      !(
        features.includes("275") ||
        features.includes("427") ||
        features.includes("478") ||
        features.includes("97")
      )
    ) {
      window.location.href = "/dashboard";
    }
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const mv_laparon_charges_id = this.props.costing_id;

    api.get_mv_laparon_charges(loginUserID, idToken, mv_laparon_charges_id).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === '200') {
            if (res.mv_pur_fin_laparon_charges) {
              let paid = 0;
              for (var i of res.mv_pur_fin_laparon_charges) {
                for (var j of i.laparon_charges_payments) {
                  if (j.date_of_payment) {
                    paid += Number(j.amount ? j.amount : 0)
                  }
                }
              }
              this.setState({
                multiRows: res.mv_pur_fin_laparon_charges,
                paid: paid,
                isLoading: false
              })
            }
          } else if (res.code === '601') {
            window.location.href = '/logout';
          }
        })
      }
    })
  }

  approveHandler = () => {
    this.setState({ isLoading: true });
    let request = {
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
      costing_id: this.props.costing_id,
      costing_type: "MV Laparon Charges",
      approval_status: "Approved",
    };
    api.approve_costing_api(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.setState(
              {
                snackBarSuccessOpen: true,
                successMsg: res.message,
              },
              () => this.componentDidMount()
            );
          } else if (res.code === "601") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
  };

  updateInvoice = (data) => {
    let request = {
      ...data,
      table_name: "mv_pur_fin_laparon_survey_charges",
    };
    api.update_invoice_details(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.componentDidMount();
            this.setState({
              invoiceDialog: false,
            });
          }
        });
      }
    });
  };

  renderInvoiceLink = () => {
    let invoiceObject = {};

    for (var i of this.state.multiRows) {
      invoiceObject["costing_id"] = i.costing_no;
      invoiceObject["to"] = i.dc_laprn_surv_name;
      invoiceObject["business_no"] = this.state.mother_vessel_name;
      invoiceObject["costing_name"] = "MV Laparon Survey Charges";
      invoiceObject["invoice_no"] = i.invoice_no;
      invoiceObject["invoice_date"] = localDateFormate(i.invoice_date);
      invoiceObject["bargeDetails"] = [];
      invoiceObject["billing_currency"] =
        config.company === "SRPL" ||
          config.company === "AVS" ||
          config.company === "PRPL"
          ? "USD"
          : "IDR";
      invoiceObject["total_payable_amount"] = toLocaleString(
        i.other_charges?.reduce((acc, item) => (Number(acc ? acc : 0) + Number(item.other_total ? item.other_total : 0)), 0) + parseFloat(i.dc_laprn_surv_total ? i.dc_laprn_surv_total : 0)
      );
      invoiceObject["purchase_type"] = "FOB Barge";
      invoiceObject["description"] = [
        {
          qty: toLocaleString(i.dc_laprn_quantity_in_mt),
          adjustedPrice: toLocaleString(i.dc_laprn_surv_price_per_mt),
          desc: `${i.dc_laprn_surv_currency_xchg_rate > 1
            ? `Exchange rate @ ${i.dc_laprn_surv_currency_xchg_rate}`
            : ""
            }`,
          // amount: i.stevedore_total,
          amount: i.dc_laprn_surv_base_price,
        },
        ...(i.other_charges.length > 0
          ? [
            i.other_charges?.map((e, idx) => ({
              qty: " ",
              adjustedPrice: " ",
              desc: `Other Charges -  ${e.description}`,
              amount: `${toLocaleString(e.price ? e.price : 0)}`,
            })),
          ]
          : []),
        {
          qty: " ",
          adjustedPrice: " ",
          desc: "VAT",
          amount: `${toLocaleString(
            parseFloat(
              i.dc_laprn_surv_vat_value ? i.dc_laprn_surv_vat_value : 0
            ))}`,
        },
        ...(!(
          config.company === "SRPL" ||
          config.company === "AVS" ||
          config.company === "PRPL"
        )
          ? [
            {
              qty: " ",
              adjustedPrice: " ",
              desc: "PPH",
              amount: `( ${toLocaleString(
                parseFloat(
                  i.dc_laprn_surv_pph23_value
                    ? i.dc_laprn_surv_pph23_value
                    : 0
                ))} )`,
            },
          ]
          : []),
      ];
    }
    return (
      <InvoiceLink data={invoiceObject} fileName={invoiceObject?.costing_id} />
    );
  };

  renderPaymentPostings = (data, type) => {
    const {
      laparon_charges_payments = [],
      currency_xchg_rate = 1,
      billing_currency = config.company === "SRPL" ||
        config.company === "PRPL" ||
        config.company === "AVS"
        ? "USD"
        : "IDR",
      approval_status = "Pending",
      invoice_no = null,
      other_charges = [],
      dc_laprn_surv_total = 0,
    } = data;
    let payment_related_data = {
      paymentTitle: type,
      payment_posting_type: "Mv Laporan",
      invoice_no: invoice_no,
      outstanding_amount: other_charges.reduce((acc, item) => (Number(acc ? acc : 0) + Number(item.other_total ? item.other_total : 0)), 0) + parseFloat(dc_laprn_surv_total ? dc_laprn_surv_total : 0) - parseFloat(this.state.paid ? this.state.paid : 0)
      ,
      billing_currency: billing_currency
        ? billing_currency
        : config.company === "SRPL" ||
          config.company === "PRPL" ||
          config.company === "AVS"
          ? "USD"
          : "IDR",
      currency_xchg_rate: currency_xchg_rate ? currency_xchg_rate : 1,
      costing_id: this.props.costing_id,
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
    };

    let access_details = {
      costing_approval: approval_status,
      add_payment: "621",
      view_payment: "622",
      update_payment: "623",
      delete_payment: "624",
      approver: "625",
      approver2: "626",
    };

    return (
      <PaymentPostings
        payments={laparon_charges_payments}
        costingType="MV Laparon Survey Charges"
        data={payment_related_data}
        access_details={access_details}
        callbackFn={() => {
          this.componentDidMount();
        }}
      />
    );
  };

  render() {
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div
          className="container bg-white p-0"
          style={{ minHeight: "100vh", width: 850 }}
        >
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          {this.state.multiRows.map((e, idx) => (
            <div className="clearfix content-header border-bottom">
              <div className="row">
                <div className="col-sm text-left pl-0">
                  <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                    {`Laporan Charges - ${e.costing_no}`}
                    <span
                      className={
                        e.approval_status_2 === "Pending" ? "type_2" : "type_1"
                      }
                      style={{ padding: 8, marginLeft: 16 }}
                    >
                      {e.approval_status_2}
                    </span>
                  </h4>
                </div>
                {e.approval_status === "Pending" &&
                  e.invoice_file &&
                  this.Cookie.getCookie("loginUserId") !== e.created_by &&
                  e.approved_by !== this.Cookie.getCookie("loginUserId") &&
                  (this.state.features.includes("427") ||
                    this.state.features.includes("478") ||
                    this.state.features.includes("97")) && (
                    <div
                      className="col text-right float-sm-left"
                      style={{ padding: "5px 20px" }}
                    >
                      <button
                        className="header_button header_button_text add_button_adjustment"
                        style={{ width: "auto", marginTop: "17px" }}
                        onClick={this.approveHandler}
                      >
                        Approve
                      </button>
                    </div>
                  )}
                <div className="col-lg-1 pl-0 float-right">
                  {this.renderInvoiceLink(e)}
                </div>
              </div>
            </div>
          ))}
          <div className="drawer-section">

            <div className="card p-3">
              <div className="card-body p-0">
                {this.state.multiRows.map((p, idx) => (
                  <div className="row" key={idx}>
                    <div className="col-lg-12 border_dashed rounded-lg p-2 mb-2">

                      <div className="table-responsive">
                        <table className="table table-bordered table-sm">
                          <thead className="table-header">
                            <tr>
                              <th className="text-center">Description</th>
                              <th className="text-right">Qty</th>
                              <th className="text-right">Unit Price</th>
                              <th className="text-right">Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <div>{`Price Type : ${p.dc_laprn_surv_price_type}`}</div>
                                <div>{`Laporan Survey Party : ${p.dc_laprn_surv_party}`}</div>
                                {`${p.dc_laprn_surv_currency_xchg_rate > 1
                                  ? `Exchange Rate @ ${toLocaleString(
                                    p.dc_laprn_surv_currency_xchg_rate
                                  )}`
                                  : ``
                                  }`}
                              </td>
                              <td className="text-right">
                                {toLocaleString(p.dc_laprn_quantity_in_mt)}
                              </td>
                              <td className="text-right">
                                {`${toLocaleString(p.dc_laprn_surv_price_per_mt)} ${p.billing_currency}`}
                              </td>
                              <td className="text-right">
                                {toLocaleString(p.dc_laprn_surv_base_price)}
                              </td>
                            </tr>
                            {p.other_charges.length > 0 &&
                              <>
                                <tr>
                                  <td>
                                    {p.other_charges?.map(obj => (
                                      <div>Other Charges - {obj.other_description}</div>
                                    ))}
                                  </td>
                                  <td className="text-right">

                                  </td>
                                  <td className="text-right"></td>
                                  <td className="text-right">{`${toLocaleString(
                                    p.other_charges.reduce((acc, item) => (Number(acc ? acc : 0) + Number(item.other_base_price ? item.other_base_price : 0)), 0)
                                  )}`}</td>
                                </tr>
                                {/* <tr>
                                  <td>{`VAT @ 10 `} </td>
                                  <td className="text-right"></td>
                                  <td className="text-right"></td>
                                  <td className="text-right">{`${toLocaleString(
                                    p.other_charges.reduce((acc, item) => (Number(acc ? acc : 0) + Number(item.other_vat_value ? item.other_vat_value : 0)), 0)
                                  )}`}</td>
                                </tr> */}
                              </>

                            }
                            <tr>
                              <td>{`VAT @ ${p.dc_laprn_surv_vat_percent} `}</td>
                              <td className="text-right"></td>
                              <td className="text-right"></td>
                              <td className="text-right">
                                {`${toLocaleString(
                                  p.other_charges.reduce((acc, item) => (Number(acc ? acc : 0) + Number(item.other_vat_value ? item.other_vat_value : 0)), 0) + Number(p.dc_laprn_surv_vat_value ? p.dc_laprn_surv_vat_value : 0)
                                )}`}
                              </td>
                            </tr>
                            {!(
                              config.company === "SRPL" ||
                              config.company === "PRPL" ||
                              config.company === "AVS"
                            ) && (
                                <tr>
                                  <td>{`PPH @ ${p.dc_laprn_surv_pph23_percent} `}</td>
                                  <td className="text-right"></td>
                                  <td className="text-right"></td>
                                  <td className="text-right">{`( ${toLocaleString(
                                    p.dc_laprn_surv_pph23_value
                                  )} )`}</td>
                                </tr>
                              )}


                            {!(
                              config.company === "SRPL" ||
                              config.company === "PRPL" ||
                              config.company === "AVS"
                            ) && p.dc_cek_fisic && (
                                <tr>
                                  <td>{`Cek Fisic`}</td>
                                  <td className="text-right"></td>
                                  <td className="text-right"></td>
                                  <td className="text-right">{`( ${toLocaleString(
                                    p.dc_cek_fisic
                                  )} )`}</td>
                                </tr>
                              )}

                            <tr>
                              <td colSpan={3} className="text-right " style={{ fontWeight: 'bold' }} >
                                {` Payable to ${p.dc_laprn_surv_name}`}
                              </td>
                              <td className="text-right" style={{ fontWeight: 'bold' }} >{`${toLocaleString(
                                p.other_charges.reduce((acc, item) => (Number(acc ? acc : 0) + Number(item.other_total ? item.other_total : 0)), 0) + parseFloat(p.dc_laprn_surv_total ? p.dc_laprn_surv_total : 0)
                              )} ${config.company === "SRPL" ||
                                config.company === "PRPL" ||
                                config.company === "AVS"
                                ? "USD"
                                : "IDR"
                                }`}</td>
                            </tr>
                            <tr>
                              <td colSpan={3} className="text-right " style={{ fontWeight: 'bold' }} > Paid</td>
                              <td className="text-right " style={{ fontWeight: 'bold' }} >{toLocaleString(this.state.paid ? this.state.paid : 0)}</td>
                            </tr>
                            <tr>
                              <td colSpan={3} className="text-right " style={{ fontWeight: 'bold' }} > Remaing Amount</td>
                              <td className="text-right " style={{ fontWeight: 'bold' }} >
                                {`${toLocaleString(
                                  p.other_charges.reduce((acc, item) => (Number(acc ? acc : 0) + Number(item.other_total ? item.other_total : 0)), 0) + parseFloat(p.dc_laprn_surv_total ? p.dc_laprn_surv_total : 0) - parseFloat(this.state.paid ? this.state.paid : 0)
                                )}`}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div className="card col-lg-12 p-0">
                        <InvoiceForm
                          open={this.state.invoiceDialog}
                          costingID={this.props.costing_id}
                          costing_type={"MV Laparon Survey Charges"}
                          data={p}
                          callbackFn={(data) => this.updateInvoice(data)}
                        />

                        {this.renderPaymentPostings(p)}

                      </div>

                      <ViewFiles
                        {...this.props}
                        files_data={p.files}
                        type="Costings"
                        invoice_file={p.invoice_file}
                      />

                      <AuditInfo
                        info={p}
                      />
                    </div>

                  </div>
                ))}
              </div>
            </div>
          </div>
          <footer className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button btn-light"
              name="submit"
              onClick={() => {
                this.props.returnCallbackDrawerClose(false);
              }}
            >
              Close
            </button>
            <button
              type="button"
              className="drawer_text drawer_btn_success"
              name="Back"
              onClick={() => {
                this.props.returnEditCallback("MvDoc");
              }}
            >
              Edit
            </button>
          </footer>
        </div>
      );
    }
  }
}
