import React, { useEffect, useState } from 'react';
import {
  Drawer,
  TextField
} from '@material-ui/core';
import CoalPriceIndexes from '../../CoalIndexPrices/coalPriceIndexes';

export default function IndexLinkCalculations({ open, coal_index, grade, callbackFn }) {

  const [state, setState] = useState({
    coal_index_type: 'Weekly',
    index_ids: [],
    average_coal_index_price: 0,
    prorata_gar: '0',
    prorata_coal_index_price: 0,
    bonus_coal_index_pmt: '',
    penality_coal_index_pmt: '',
    derivered_coal_index_pmt: '',
    offset: 0,
    limit: 5,
    final_coal_index_pmt: 0,
    loadMore: false,
    grade_input: '',
    average_coal_indexes: '',
    coal_index_remarks: ''
  })
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  useEffect(() => {
    if (coal_index) {
      setState(coal_index)
    }
  }, [coal_index])

  return (
    <div>

      <Drawer
        anchor="right"
        open={open}
        variant="temporary"
        elevation={20}
        style={{ overflow: "initial" }}
      >
        <div className="container bg-white p-0" style={{ minHeight: "100vh", width: 700 }}>


          <div className="drawer-section">
            <div className="card">
              <CoalPriceIndexes
                grade={grade}
                coalPrices={(data) => {
                  setState({ ...state, ...data });
                  forceUpdate();
                }}
                coal_index={state}
              />
              <div className='row'>
                <div className='col-lg-4 p-0'>
                  <label className='form_label pt-3'>Remarks</label>
                </div>
                <div className='col-lg-6 p-0'>
                  <TextField
                    name='coal_index_remarks'
                    margin='dense'
                    variant='outlined'
                    fullWidth
                    multiline
                    minRows={3}
                    value={state.coal_index_remarks}
                    placeholder='Remarks'
                    inputProps={{
                      style: {
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        textTransform: "uppercase",
                      },
                    }}
                    onChange={(e) => {
                      setState({ ...state, coal_index_remarks: e.target.value });
                      forceUpdate();
                    }}
                  >
                  </TextField>
                </div>
              </div>
            </div>
          </div>

          <div
            className="row col-lg-12 modal-footer justify-content-end bottom_buttons_section"
            style={{ bottom: 0, background: "white" }}
          >
            <button
              type="button"
              className="drawer_button drawer_text drawer_calculate_button drawer_calculate_adjustment"
              onClick={() => callbackFn(state)}
            >
              Cancel
            </button>
            <button
              type="button"
              className="drawer_button drawer_text"
              onClick={() => callbackFn(state)}
            >
              Save
            </button>
          </div>
        </div>

      </Drawer>


    </div>
  )
}
