import React, { Component } from "react";
import SideBar from "../common/SideBar";
import Header from "../common/Header";
import CookieHandler from "../common/CookieHandler";
import api from "../../api/api";
import config from "../../config/config";
import { TextField, Checkbox, Snackbar, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { withStyles } from "@material-ui/core/styles";
import { pad } from "../common/common";
import Loader from "../common/Loader";

const CheckBox = withStyles({
  root: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default class PurchaseQualityResultsUpdate extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      businessNoBargeID: "",
      quality_calorific_value_actual: "",
      quality_calorific_value2_actual: "",
      quality_calorific_value3_actual: "",
      quality_total_moisture_actual: "",
      quality_inherent_moisture_actual: "",
      quality_ash_actual: "",
      quality_volatile_matter_actual: "",
      quality_fixed_carbon_actual: "",
      quality_fuel_ratio_actual: "",
      quality_total_sulphur_actual: "",
      quality_hgi_actual: "",

      barge_pur_quality_id: "",
      bargePurchaseQualityResultsAvailable: true,

      businessNoID: "",
      errorMsg: "",
      successMsg: "",
      isLoading: true,
      features: [],
      totalBarges: [],
      bargeList: [],
      files: [
        {
          file_description: "",
          file_name: "",
          file_extension: "",
          file_content: "",
          feature_name: "Purchase_Fin_Quality_Results",
        },
      ],
      activeStep: 0,
    };
  }

  componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    } else {
      var features = this.Cookie.getCookie("features");
      this.setState({ features: features });
      if (
        !(
          features.indexOf("55") >= 0 ||
          features.indexOf("57") >= 0 ||
          features.indexOf("97") >= 0
        )
      ) {
        window.location.href = "/dashboard";
      }
      var idToken = this.Cookie.getIdTokenCookie();
      var loginUserID = this.Cookie.getCookie("loginUserId");
      //Get purchase contracts
      let status = "";
      api
        .getPurchaseContracts(loginUserID, status, idToken)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
                if (res.purchase_contract) {
                  let purchaseDataforMenu = [];
                  const filteredPContracts = res.purchase_contract.filter(
                    (e, indx) => (e.status !== "cancelled" && e.status !== "Closed")

                  );
                  for (let i = 0; i < filteredPContracts.length; i++) {
                    purchaseDataforMenu.push({
                      name: filteredPContracts[i].contract_no,
                      value: filteredPContracts[i].id,
                      key: i,
                      vendor_name: filteredPContracts[i].vendor_name,
                    });
                  }

                  this.setState({
                    purchaseDataForMenu: purchaseDataforMenu,
                    //purchaseContractList: res.purchase_contract,
                    purchaseContractList: filteredPContracts,
                    isLoading: false,
                  });
                } else {
                  alert(
                    "Unexpected error occured. Please contact administrator."
                  );
                }
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
      // let barge_name = '';
      // let jetty_name = '';
      let financial_type = "barge_pur_fin_quality_results";
      let purchase_contract_id = [];
      let business_no = [];
      let barge_vendor = "";
      //let costing_type = '';
      api
        .get_barges(
          loginUserID,
          idToken,
          financial_type,
          business_no,
          barge_vendor,
          purchase_contract_id
        )
        .then((response) => {
          // api.get_pc_barge_coal_list(loginUserID, idToken, businessNoID, costing_type).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                if (res.barges) {
                  //const filter_not_quality_barges = res.barges.filter(e => e.quality_results_costing === null);
                  this.setState({
                    totalBarges: res.barges,
                    isLoading: false,
                  });
                } else {
                  alert(
                    "Unexpected error occured. Please contact administrator."
                  );
                }
              }
            });
          } else {
            this.setState({
              isLoading: false,
            });
          }
        });
      try {
        let bargeID = decodeURIComponent(
          window.atob(this.props.match.params.bargeID)
        );
        this.setState({
          bargeList: [...this.state.bargeList, ...bargeID.split(',')],
        });
        // Get All Purchase Contract Specificatons By Barge ID.
        api
          .get_pcq_typical_specs(loginUserID, idToken, bargeID.split(',')[0])
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              response.json().then((res) => {
                if (res.code === "200") {
                  if (res.pcq_typical_specs) {
                    this.setState({
                      BTRDataAvailable: true,
                      quality_calorific_value_typical:
                        res.pcq_typical_specs.quality_calorific_value_typical,
                      quality_calorific_value_rejection:
                        res.pcq_typical_specs.quality_calorific_value_rejection,
                      quality_calorific_value_basis:
                        res.pcq_typical_specs.quality_calorific_value_basis,
                      quality_calorific_value2_typical:
                        res.pcq_typical_specs.quality_calorific_value2_typical,
                      quality_calorific_value2_rejection:
                        res.pcq_typical_specs
                          .quality_calorific_value2_rejection,
                      quality_calorific_value2_basis:
                        res.pcq_typical_specs.quality_calorific_value2_basis,
                      quality_calorific_value3_typical:
                        res.pcq_typical_specs.quality_calorific_value3_typical,
                      quality_calorific_value3_rejection:
                        res.pcq_typical_specs
                          .quality_calorific_value3_rejection,
                      quality_calorific_value3_basis:
                        res.pcq_typical_specs.quality_calorific_value3_basis,
                      quality_total_moisture_typical:
                        res.pcq_typical_specs.quality_total_moisture_typical,
                      quality_total_moisture_rejection:
                        res.pcq_typical_specs.quality_total_moisture_rejection,
                      quality_total_moisture_basis:
                        res.pcq_typical_specs.quality_total_moisture_basis,
                      quality_inherent_moisture_typical:
                        res.pcq_typical_specs.quality_inherent_moisture_typical,
                      quality_inherent_moisture_rejection:
                        res.pcq_typical_specs
                          .quality_inherent_moisture_rejection,
                      quality_inherent_moisture_basis:
                        res.pcq_typical_specs.quality_inherent_moisture_basis,
                      quality_ash_typical:
                        res.pcq_typical_specs.quality_ash_typical,
                      quality_ash_rejection:
                        res.pcq_typical_specs.quality_ash_rejection,
                      quality_ash_basis:
                        res.pcq_typical_specs.quality_ash_basis,
                      quality_volatile_matter_typical:
                        res.pcq_typical_specs.quality_volatile_matter_typical,
                      quality_volatile_matter_rejection:
                        res.pcq_typical_specs.quality_volatile_matter_rejection,
                      quality_volatile_matter_basis:
                        res.pcq_typical_specs.quality_volatile_matter_basis,
                      quality_fixed_carbon_typical:
                        res.pcq_typical_specs.quality_fixed_carbon_typical,
                      quality_fixed_carbon_rejection:
                        res.pcq_typical_specs.quality_fixed_carbon_rejection,
                      quality_fixed_carbon_basis:
                        res.pcq_typical_specs.quality_fixed_carbon_basis,
                      quality_fuel_ratio_typical:
                        res.pcq_typical_specs.quality_fuel_ratio_typical,
                      quality_fuel_ratio_rejection:
                        res.pcq_typical_specs.quality_fuel_ratio_rejection,
                      quality_fuel_ratio_basis:
                        res.pcq_typical_specs.quality_fuel_ratio_basis,
                      quality_total_sulphur_typical:
                        res.pcq_typical_specs.quality_total_sulphur_typical,
                      quality_total_sulphur_rejection:
                        res.pcq_typical_specs.quality_total_sulphur_rejection,
                      quality_total_sulphur_basis:
                        res.pcq_typical_specs.quality_total_sulphur_basis,
                      quality_hgi_typical:
                        res.pcq_typical_specs.quality_hgi_typical,
                      quality_hgi_rejection:
                        res.pcq_typical_specs.quality_hgi_rejection,
                      //isLoading: false
                    });
                  } else {
                    this.setState({
                      isLoading: false,
                    });
                  }
                } else if (res.code === "601") {
                  this.setState({
                    errorMsg: res.message,
                    succesMsg: "",
                    isLoading: false,
                  });
                } else if (res.code === "607") {
                  window.location.href = "/logout";
                }
              });
            }
          });

        // get Barge Purchase Quality Results By Barge ID
        api
          .get_quality_results_by_barge_id(loginUserID, idToken, bargeID.split(',')[0])
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              response.json().then((res) => {
                if (res.code === "200") {
                  if (res.barge_purchase_qualities) {
                    this.setState({
                      bargePurchaseQualityResultsAvailable: true,
                      barge_pur_quality_id: res.barge_purchase_qualities.id,
                      quality_calorific_value_actual:
                        res.barge_purchase_qualities
                          .quality_calorific_value_actual,
                      quality_calorific_value2_actual:
                        res.barge_purchase_qualities
                          .quality_calorific_value2_actual,
                      quality_calorific_value3_actual:
                        res.barge_purchase_qualities
                          .quality_calorific_value3_actual,
                      quality_total_moisture_actual:
                        res.barge_purchase_qualities
                          .quality_total_moisture_actual,
                      quality_inherent_moisture_actual:
                        res.barge_purchase_qualities
                          .quality_inherent_moisture_actual,
                      quality_ash_actual:
                        res.barge_purchase_qualities.quality_ash_actual,
                      quality_volatile_matter_actual:
                        res.barge_purchase_qualities
                          .quality_volatile_matter_actual,
                      quality_fixed_carbon_actual:
                        res.barge_purchase_qualities
                          .quality_fixed_carbon_actual,
                      quality_fuel_ratio_actual:
                        res.barge_purchase_qualities.quality_fuel_ratio_actual,
                      quality_total_sulphur_actual:
                        res.barge_purchase_qualities
                          .quality_total_sulphur_actual,
                      quality_hgi_actual:
                        res.barge_purchase_qualities.quality_hgi_actual,
                      files:
                        res.barge_purchase_qualities.files.length > 0
                          ? res.barge_purchase_qualities.files
                          : [
                            {
                              file_description: "",
                              file_name: "",
                              file_extension: "",
                              file_content: "",
                              feature_name: "Purchase_Fin_Quality_Results",
                            },
                          ],
                      isLoading: false,
                    });
                  } else {
                    this.setState({
                      isLoading: false,
                      bargePurchaseQualityResultsAvailable: false,
                    });
                  }
                } else if (res.code === "601") {
                  this.setState({
                    errorMsg: res.message,
                    succesMsg: "",
                  });
                } else if (res.code === "607") {
                  window.location.href = "/logout";
                }
              });
            }
          });
      } catch (e) {
        window.location.href = "/pagenotfound";
      }
    }
    document.title = config.documentTitle + "Purchase Quality Results";
  }

  TextHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  submitHandler = () => {
    var idToken = this.Cookie.getIdTokenCookie();
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var {
      quality_calorific_value_actual,
      quality_calorific_value2_actual,
      quality_calorific_value3_actual,
      quality_total_moisture_actual,
      quality_inherent_moisture_actual,
      quality_ash_actual,
      quality_volatile_matter_actual,
      quality_fixed_carbon_actual,
      quality_fuel_ratio_actual,
      quality_total_sulphur_actual,
      quality_hgi_actual,
      files,
    } = this.state;
    var status = "Active";
    var barge_id = this.state.bargeList.toString();
    /**
     *  Checking Mandatory files
     */
    var missing_fields = 0;
    let files_missing = 0;
    var row = this.state.files;
    for (var obj of row) {
      if (obj.file_type !== "" || obj.file_content !== "") {
        if (obj.file_content !== "") {
          if (obj.file_description === "") {
            missing_fields++;
            obj.file_description_error = true;
          } else {
            obj.file_description = obj.file_description.toUpperCase();
          }
        } else if (obj.file_description !== "") {
          if (obj.file_content === "") {
            files_missing++;
          }
        }
      }
    }
    this.setState({
      files: row,
    });

    if (missing_fields > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please fill mandatory fields.",
      });
      return;
    }
    if (files_missing > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please Select file.",
      });
      return;
    }

    var existing_file_ids = [];
    const existing_files = files.filter((e) => e.id);
    for (var i of existing_files) {
      existing_file_ids.push(i.id);
    }
    const new_files = files.filter((e) => !e.id);
    const content_files = new_files.filter((e) => e.file_content !== "");
    const samplingType = decodeURIComponent(
      window.atob(this.props.match.params.samplingType)
    );
    console.log(this.props.match.params);
    console.log(window.atob(this.props.match.params.groupID));
    const previousGroupID = window.atob(this.props.match.params.groupID);
    if (!this.state.bargePurchaseQualityResultsAvailable) {
      // Add Barge Purchase Quality Results
      api
        .savePurchaseSamplingData(
          loginUserID,
          idToken,
          barge_id,
          quality_calorific_value_actual,
          quality_calorific_value2_actual,
          quality_calorific_value3_actual,
          quality_total_moisture_actual,
          quality_inherent_moisture_actual,
          quality_ash_actual,
          quality_volatile_matter_actual,
          quality_fixed_carbon_actual,
          quality_fuel_ratio_actual,
          quality_total_sulphur_actual,
          quality_hgi_actual,
          status,
          content_files,
          samplingType,
          previousGroupID,
          existing_file_ids
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                this.setState({
                  succesMsg: res.message,
                  errorMsg: "",
                });
                window.location.href = "/purchase-quality";
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  succesMsg: "",
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    } else {
      // Update Barge Purchase Quality Results
      var barge_pur_quality_id = this.state.barge_pur_quality_id;
      //businessNoBargeID = this.state.bargeList.toString();
      api
        .savePurchaseSamplingData(
          loginUserID,
          idToken,
          barge_id,
          quality_calorific_value_actual,
          quality_calorific_value2_actual,
          quality_calorific_value3_actual,
          quality_total_moisture_actual,
          quality_inherent_moisture_actual,
          quality_ash_actual,
          quality_volatile_matter_actual,
          quality_fixed_carbon_actual,
          quality_fuel_ratio_actual,
          quality_total_sulphur_actual,
          quality_hgi_actual,
          status,
          content_files,
          samplingType,
          previousGroupID,
          existing_file_ids,
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                this.setState(
                  {
                    snackBarSuccessOpen: true,
                    succesMsg: res.message,
                    errorMsg: "",
                  },
                  () => (window.location.href = "/purchase-quality")
                );
              } else if (res.code === "601") {
                this.setState({
                  snackBarErrorOpen: true,
                  errorMsg: res.message,
                  succesMsg: "",
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    }
  };

  onBargesCheck = (value, display_allocation_id, checked) => {
    if (
      this.state.display_allocation_id === "" ||
      this.state.display_allocation_id === display_allocation_id
    ) {
      this.setState({
        BTRDataAvailable: true,
      });
    } else {
      this.setState({
        BTRDataAvailable: false,
      });
    }
    if (checked) {
      let bargeList = [...this.state.bargeList, value];
      if (bargeList.length === 1) {
        this.getSpecsData(bargeList[0]);
        this.setState({
          isLoading: true,
          bargeList: bargeList,
        });
      } else {
        this.setState({
          bargeList: bargeList,
        });
      }
    } else {
      var data = this.state.bargeList;
      if (data.indexOf(value) >= 0) {
        let idx = data.indexOf(value);
        data.splice(idx, 1);
      }
      if (data.length === 1) {
        this.getSpecsData(data[0]);
        this.setState({
          isLoading: true,
          bargeList: data,
        });
      } else {
        this.setState({
          bargeList: data,
        });
      }
    }
  };

  getSpecsData = (BargeID) => {
    var idToken = this.Cookie.getIdTokenCookie();
    var loginUserID = this.Cookie.getCookie("loginUserId");
    api
      .get_pcq_typical_specs(loginUserID, idToken, BargeID)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.pcq_typical_specs) {
                this.setState({
                  BTRDataAvailable: true,
                  quality_calorific_value_typical:
                    res.pcq_typical_specs.quality_calorific_value_typical,
                  quality_calorific_value_rejection:
                    res.pcq_typical_specs.quality_calorific_value_rejection,
                  quality_calorific_value_basis:
                    res.pcq_typical_specs.quality_calorific_value_basis,
                  quality_calorific_value2_typical:
                    res.pcq_typical_specs.quality_calorific_value2_typical,
                  quality_calorific_value2_rejection:
                    res.pcq_typical_specs.quality_calorific_value2_rejection,
                  quality_calorific_value2_basis:
                    res.pcq_typical_specs.quality_calorific_value2_basis,
                  quality_calorific_value3_typical:
                    res.pcq_typical_specs.quality_calorific_value3_typical,
                  quality_calorific_value3_rejection:
                    res.pcq_typical_specs.quality_calorific_value3_rejection,
                  quality_calorific_value3_basis:
                    res.pcq_typical_specs.quality_calorific_value3_basis,
                  quality_total_moisture_typical:
                    res.pcq_typical_specs.quality_total_moisture_typical,
                  quality_total_moisture_rejection:
                    res.pcq_typical_specs.quality_total_moisture_rejection,
                  quality_total_moisture_basis:
                    res.pcq_typical_specs.quality_total_moisture_basis,
                  quality_inherent_moisture_typical:
                    res.pcq_typical_specs.quality_inherent_moisture_typical,
                  quality_inherent_moisture_rejection:
                    res.pcq_typical_specs.quality_inherent_moisture_rejection,
                  quality_inherent_moisture_basis:
                    res.pcq_typical_specs.quality_inherent_moisture_basis,
                  quality_ash_typical:
                    res.pcq_typical_specs.quality_ash_typical,
                  quality_ash_rejection:
                    res.pcq_typical_specs.quality_ash_rejection,
                  quality_ash_basis: res.pcq_typical_specs.quality_ash_basis,
                  quality_volatile_matter_typical:
                    res.pcq_typical_specs.quality_volatile_matter_typical,
                  quality_volatile_matter_rejection:
                    res.pcq_typical_specs.quality_volatile_matter_rejection,
                  quality_volatile_matter_basis:
                    res.pcq_typical_specs.quality_volatile_matter_basis,
                  quality_fixed_carbon_typical:
                    res.pcq_typical_specs.quality_fixed_carbon_typical,
                  quality_fixed_carbon_rejection:
                    res.pcq_typical_specs.quality_fixed_carbon_rejection,
                  quality_fixed_carbon_basis:
                    res.pcq_typical_specs.quality_fixed_carbon_basis,
                  quality_fuel_ratio_typical:
                    res.pcq_typical_specs.quality_fuel_ratio_typical,
                  quality_fuel_ratio_rejection:
                    res.pcq_typical_specs.quality_fuel_ratio_rejection,
                  quality_fuel_ratio_basis:
                    res.pcq_typical_specs.quality_fuel_ratio_basis,
                  quality_total_sulphur_typical:
                    res.pcq_typical_specs.quality_total_sulphur_typical,
                  quality_total_sulphur_rejection:
                    res.pcq_typical_specs.quality_total_sulphur_rejection,
                  quality_total_sulphur_basis:
                    res.pcq_typical_specs.quality_total_sulphur_basis,
                  quality_hgi_typical:
                    res.pcq_typical_specs.quality_hgi_typical,
                  quality_hgi_rejection:
                    res.pcq_typical_specs.quality_hgi_rejection,
                  isLoading: false,
                });
              } else {
                this.setState({
                  isLoading: false,
                });
              }
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                succesMsg: "",
                isLoading: false,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  };

  onSelectAllBarges = (checked) => {
    this.setState({ isLoading: true });
    var totalBarges = this.state.totalBarges;
    if (checked) {
      if (totalBarges.length > 0) {
        var BargeID = totalBarges[0].id;
        var bargeList = [];
        for (var i of totalBarges) {
          bargeList.push(i.id);
        }
      } else {
        this.setState({
          isLoading: false,
          snackBarErrorOpen: true,
          errorMsg: "No Barges Available",
        });
        return;
      }
      this.setState(
        {
          bargeList: bargeList,
        },
        () => this.getSpecsData(BargeID)
      );
    } else {
      this.setState({
        bargeList: [],
        BTRDataAvailable: false,
        isLoading: false,
      });
    }
  };

  handlePurchaseContract = () => {
    this.setState({ isLoading: true });
    var purchase_contract_id = this.state.purchase_contract_id
      ? [this.state.purchase_contract_id]
      : "";
    if (
      purchase_contract_id === null ||
      purchase_contract_id === "" ||
      purchase_contract_id.length === 0
    ) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please fill mandatory fields",
        purchaseContractError: true,
        isLoading: false,
      });
      return;
    } else {
      var idToken = this.Cookie.getIdTokenCookie();
      var loginUserID = this.Cookie.getCookie("loginUserId");
      // Get Purchase contract based Barges by Business number ID
      // let barge_name = '';
      // let jetty_name = '';
      let business_no = [];
      let barge_vendor = "";
      //let costing_type = '';
      let financial_type = "barge_pur_fin_quality_results";
      api
        .get_barges(
          loginUserID,
          idToken,
          financial_type,
          business_no,
          barge_vendor,
          purchase_contract_id
        )
        .then((response) => {
          // api.get_pc_barge_coal_list(loginUserID, idToken, businessNoID, costing_type).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                if (res.barges) {
                  const filter_not_quality_barges = res.barges.filter(
                    (e) => e.quality_results_costing === null
                  );
                  this.setState({
                    totalBarges: filter_not_quality_barges,
                    isLoading: false,
                  });
                } else {
                  alert(
                    "Unexpected error occured. Please contact administrator."
                  );
                }
              }
            });
          } else {
            this.setState({
              isLoading: false,
            });
          }
        });
    }
  };

  nextStepHandler = () => {
    if (this.state.activeStep === 0) {
      if (this.state.bargeList.length === 0) {
        this.setState({
          snackBarErrorOpen: true,
          errorMsg: "Please Select Barge",
        });
        return;
      }
    }

    this.setState({
      activeStep: this.state.activeStep + 1,
    });
  };

  previousStepHandler = () => {
    this.setState({
      activeStep: this.state.activeStep - 1,
    });
  };

  toLocaleString(value) {
    return value ? Number(value).toLocaleString() : 0;
  }

  fileUploadHandler = (idx) => (event) => {
    let file = event.target.files[0];
    let file_data = "";
    let fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onloadend = () => {
      let file_content = fileReader.result;
      let file_extension = file.name.substr(file.name.lastIndexOf(".") + 1);
      let file_name = file.name;
      let file_type = file.type;
      file_data = file_content.replace("data:" + file_type + ";base64,", "");

      var row = this.state.files ? this.state.files : [];
      row[idx].file_name = file_name;
      row[idx].file_extension = file_extension;
      row[idx].file_type = file_type;
      row[idx].file_content = file_data;

      this.setState({
        files: row,
      });
    };
  };
  addMoreFileHandler = () => {
    const data = {
      file_description: "",
      file_name: "",
      file_extension: "",
      file_content: "",
      feature_name: "Purchase_Fin_Quality_Results",
    };

    this.setState({
      files: [...this.state.files, data],
    });
  };

  render() {
    const re = /^[0-9]*\.?[0-9]*$/;
    const bargeID = decodeURIComponent(window.atob(this.props?.match?.params?.bargeID));
    const groupID = decodeURIComponent(window.atob(this.props?.match?.params?.groupID));
    const samplingType = decodeURIComponent(window.atob(this.props?.match?.params?.samplingType));
    if (!bargeID && !groupID && !samplingType) {
      window.location.href = "/purchase-quality";
    }
    return (
      <div id="wraper">
        <SideBar />
        <div className="content">
          <Header />
          {!!this.state.isLoading && <Loader />}
          {!this.state.isLoading && (
            <div>
              <Typography>
                <div className="content-header">
                  <div className="col-sm text-left pl-0">
                    <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                      Barge Purchase Quality Results
                    </h4>
                  </div>
                </div>
                <section className="container p-3">
                  <div
                    className="row mb-0 stepper"
                    style={{ top: "-18px", zIndex: 50 }}
                  >
                    <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                      <i
                        className={
                          this.state.activeStep > 0
                            ? "fa fa-check-circle"
                            : "fa fa-check-circle-o"
                        }
                        aria-hidden="true"
                        style={{
                          fontSize: 20,
                          color:
                            this.state.activeStep > 0
                              ? "#31c197"
                              : this.state.activeStep === 0
                                ? config.themeColor
                                : "#ccc",
                        }}
                      ></i>
                      <span
                        className="stepper_text"
                        style={{
                          color:
                            this.state.activeStep > 0
                              ? "#31c197"
                              : this.state.activeStep === 0
                                ? config.themeColor
                                : "#ccc",
                        }}
                      >
                        Barge Selection
                      </span>
                      <i
                        className="fa fa-chevron-right stepper_sub_icon stepper_arrow"
                        aria-hidden="true"
                        style={{
                          color:
                            this.state.activeStep > 0
                              ? "#31c197"
                              : this.state.activeStep === 0
                                ? config.themeColor
                                : "#ccc",
                        }}
                      ></i>
                    </span>

                    <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                      <i
                        className={
                          this.state.activeStep > 1
                            ? "fa fa-check-circle"
                            : "fa fa-check-circle-o"
                        }
                        aria-hidden="true"
                        style={{
                          fontSize: 20,
                          color:
                            this.state.activeStep > 1
                              ? "#31c197"
                              : this.state.activeStep === 1
                                ? config.themeColor
                                : "#ccc",
                        }}
                      ></i>
                      <span
                        className="stepper_text"
                        style={{
                          color:
                            this.state.activeStep > 1
                              ? "#31c197"
                              : this.state.activeStep === 1
                                ? config.themeColor
                                : "#ccc",
                        }}
                      >
                        Quality Results
                      </span>
                      <i
                        className="fa fa-chevron-right stepper_sub_icon stepper_arrow"
                        aria-hidden="true"
                        style={{
                          color:
                            this.state.activeStep > 1
                              ? "#31c197"
                              : this.state.activeStep === 1
                                ? config.themeColor
                                : "#ccc",
                        }}
                      ></i>
                    </span>
                  </div>
                  <div className="col-lg card" style={{ overflowY: "scroll" }}>
                    <Snackbar
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      open={this.state.snackBarErrorOpen}
                      onClose={() => this.setState({ snackBarErrorOpen: false })}
                      style={{ width: "450px" }}
                    >
                      <Alert
                        elevation={6}
                        variant="filled"
                        onClose={() =>
                          this.setState({ snackBarErrorOpen: false })
                        }
                        severity="error"
                        style={{ width: "100%" }}
                      >
                        {this.state.errorMsg}
                      </Alert>
                    </Snackbar>
                    <Snackbar
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      style={{ width: "450px" }}
                      open={this.state.snackBarSuccessOpen}
                      autoHideDuration={100000}
                      onClose={() =>
                        this.setState({ snackBarSuccessOpen: false })
                      }
                    >
                      <Alert
                        elevation={6}
                        variant="filled"
                        onClose={() =>
                          this.setState({ snackBarSuccessOpen: false })
                        }
                        severity="success"
                        style={{ width: "100%" }}
                      >
                        {this.state.succesMsg}
                      </Alert>
                    </Snackbar>
                    <div className="section_block w-100 pl-4 pr-4">
                      {this.state.activeStep === 0 && (
                        <div className="section_block w-100">
                          <div style={{ width: "70%" }}>
                            <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                              Select Barge to create Quality
                            </h5>
                          </div>
                          <div className="row">
                            <div className="col-lg-4 pl-0">
                              <label className="form_label mb-0">
                                Purchase Contract *
                              </label>
                              <Autocomplete
                                options={this.state.purchaseDataForMenu}
                                getOptionLabel={(option) => option.name}
                                onChange={(event, value) => {
                                  if (value) {
                                    this.setState({
                                      purchaseContractSelected: value,
                                      purchase_contract_id: value.value,
                                      purchase_contract_no: value.name,
                                      purchaseContractError: false,
                                    });
                                  } else {
                                    this.setState({
                                      purchase_contract_id: "",
                                      purchase_contract_no: "",
                                      purchaseContractSelected: null,
                                      purchaseContractError: false,
                                    });
                                  }
                                }}
                                value={this.state.purchaseContractSelected}
                                InputProps={{
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                  },
                                }}
                                size="small"
                                name="purchaseContract"
                                renderOption={(option) => (
                                  <React.Fragment>
                                    {option.name} ({option.vendor_name})
                                  </React.Fragment>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="Purchase Contract"
                                    error={this.state.purchaseContractError}
                                    variant="outlined"
                                    fullWidth
                                    style={{ top: 8 }}
                                  />
                                )}
                                style={{ display: "contents" }}
                              />
                            </div>

                            <div className="col-lg-4">
                              <button
                                onClick={this.handlePurchaseContract}
                                className="header_button header_button_text add_button_adjustment"
                                style={{ width: "auto", marginTop: "48px" }}
                              >
                                Load Barges
                              </button>
                            </div>
                          </div>

                          {this.state.totalBarges.length > 0 && (
                            <div className="row">
                              <div className="table border-0">
                                <table className="table table-bordered table-sm mb-0">
                                  <thead className="border-0">
                                    <tr>
                                      <th
                                        className="p-2 text-center border-0"
                                        nowrap="true"
                                      >
                                        <CheckBox
                                          //value={this.state.totalBarges}
                                          color="primary"
                                          //checked={this.state.totalBarges.length !== 0 && (this.state.bargeList.length === this.state.totalBarges.filter(e => e.barge_pur_quality_results_id === null).length)}
                                          checked={
                                            this.state.totalBarges.length !== 0 &&
                                            this.state.bargeList.length ===
                                            this.state.totalBarges.filter(
                                              (e) =>
                                                e.barge_pur_quality_results_id !==
                                                null
                                            ).length
                                          }
                                          //disabled={this.state.selectedPurchaseContractId === "" || p.purchase_contract_id !== this.state.selectedPurchaseContractId}
                                          // onChange={this.onBargesCheck}
                                          onChange={(e) => {
                                            this.onSelectAllBarges(
                                              e.target.checked
                                            );
                                          }}
                                          className="p-0"
                                        />
                                      </th>
                                      <th
                                        style={{ fontSize: 16 }}
                                        className="table_header_barge_label text-uppercase "
                                        nowrap="true"
                                      >
                                        Barge ID
                                      </th>
                                      <th
                                        style={{ fontSize: 16 }}
                                        className="table_header_barge_label text-uppercase "
                                        nowrap="true"
                                      >
                                        Barge Nomination
                                      </th>
                                      <th
                                        style={{ fontSize: 16 }}
                                        className="table_header_barge_label text-uppercase "
                                        nowrap="true"
                                      >
                                        Supplier
                                      </th>
                                      <th
                                        style={{ fontSize: 16 }}
                                        className="table_header_barge_label text-uppercase "
                                        nowrap="true"
                                      >
                                        Purchase Quality
                                      </th>
                                      <th
                                        style={{ fontSize: 16 }}
                                        className="table_header_barge_label text-uppercase  text-right"
                                        nowrap="true"
                                      >
                                        Barge Quantity
                                      </th>
                                    </tr>
                                  </thead>
                                  {this.state.totalBarges.map((e, index) => (
                                    <tbody key={index}>
                                      <tr className="rounded">
                                        <td className="p-0 text-center pt-1 pb-1">
                                          <CheckBox
                                            value={e.id}
                                            color="primary"
                                            checked={
                                              this.state.bargeList.indexOf(
                                                e.id
                                              ) >= 0
                                            }
                                            onChange={(e) => {
                                              this.onBargesCheck(
                                                e.target.value,
                                                e.display_allocation_id,
                                                e.target.checked
                                              );
                                            }}
                                          />
                                        </td>
                                        <td>{pad(e.id)}</td>
                                        <td nowrap="true">
                                          {e.barge_nomination}
                                        </td>
                                        <td nowrap="true">{e.supplier_name}</td>
                                        <td nowrap="true">
                                          {e.purchase_quality}
                                        </td>
                                        <td nowrap="true" className="text-right">
                                          {this.toLocaleString(
                                            e.barge_quantity_in_mt
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  ))}
                                </table>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                      {this.state.activeStep === 1 && (
                        <div>
                          {this.state.bargeList.length > 0 && (
                            <div className="section_block w-100">
                              <div className="row">
                                <div className="table-responsive">
                                  <table className="table table-bordered table-sm mb-0">
                                    <thead >
                                      <tr>
                                        <th
                                          style={{ fontSize: 16 }}
                                          className="text-uppercase text-center"
                                        >
                                          Typical Analysis
                                        </th>
                                        <th
                                          style={{ fontSize: 16 }}
                                          className="text-uppercase text-center"
                                        >
                                          Units
                                        </th>
                                        <th
                                          style={{ fontSize: 16 }}
                                          className="text-uppercase text-center"
                                        >
                                          Basis
                                        </th>
                                        <th
                                          style={{ fontSize: 16 }}
                                          className="text-uppercase text-center"
                                        >
                                          Typical
                                        </th>
                                        <th
                                          style={{ fontSize: 16 }}
                                          className="text-uppercase text-center"
                                        >
                                          Rejection
                                        </th>
                                        <th
                                          style={{ fontSize: 16 }}
                                          className="text-uppercase text-center"
                                        >
                                          Actual
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td
                                          className="table_header_barge_label"
                                          style={{
                                            verticalAlign: "middle",
                                            fontSize: 14,
                                          }}
                                        >
                                          Gross Calorific Value
                                        </td>
                                        <td style={{ verticalAlign: "middle" }}>
                                          Kcal/kg
                                        </td>
                                        <td>
                                          {
                                            this.state
                                              .quality_calorific_value_basis
                                          }
                                        </td>
                                        <td>
                                          {
                                            this.state
                                              .quality_calorific_value_typical
                                          }
                                        </td>
                                        <td>
                                          {
                                            this.state
                                              .quality_calorific_value_rejection
                                          }
                                        </td>
                                        <td>
                                          <TextField
                                            name="quality_calorific_value_actual"
                                            margin="dense"
                                            variant="outlined"
                                            fullWidth
                                            value={
                                              this.state
                                                .quality_calorific_value_actual
                                            }
                                            //onChange={this.TextHandler}
                                            onChange={(e) => {
                                              if (
                                                e.target.value === "" ||
                                                re.test(e.target.value)
                                              ) {
                                                this.setState({
                                                  [e.target.name]: e.target.value,
                                                });
                                              }
                                            }}
                                            inputProps={{
                                              style: {
                                                textTransform: "uppercase",
                                              },
                                            }}
                                          />
                                        </td>
                                      </tr>

                                      <tr>
                                        <td
                                          className="table_header_barge_label"
                                          style={{
                                            verticalAlign: "middle",
                                            fontSize: 14,
                                          }}
                                        ></td>
                                        <td
                                          style={{
                                            verticalAlign: "middle",
                                            borderTop: "none",
                                          }}
                                        >
                                          Kcal/kg
                                        </td>
                                        <td style={{ borderTop: "none" }}>
                                          {
                                            this.state
                                              .quality_calorific_value2_basis
                                          }
                                        </td>
                                        <td style={{ borderTop: "none" }}>
                                          {
                                            this.state
                                              .quality_calorific_value2_typical
                                          }
                                        </td>
                                        <td style={{ borderTop: "none" }}>
                                          {
                                            this.state
                                              .quality_calorific_value2_rejection
                                          }
                                        </td>
                                        <td>
                                          <TextField
                                            name="quality_calorific_value2_actual"
                                            margin="dense"
                                            variant="outlined"
                                            fullWidth
                                            value={
                                              this.state
                                                .quality_calorific_value2_actual
                                            }
                                            //onChange={this.TextHandler}
                                            onChange={(e) => {
                                              if (
                                                e.target.value === "" ||
                                                re.test(e.target.value)
                                              ) {
                                                this.setState({
                                                  [e.target.name]: e.target.value,
                                                });
                                              }
                                            }}
                                            inputProps={{
                                              style: {
                                                textTransform: "uppercase",
                                              },
                                            }}
                                          />
                                        </td>
                                      </tr>

                                      <tr>
                                        <td
                                          className="table_header_barge_label"
                                          style={{
                                            verticalAlign: "middle",
                                            fontSize: 14,
                                          }}
                                        ></td>
                                        <td
                                          style={{
                                            verticalAlign: "middle",
                                            borderTop: "none",
                                          }}
                                        >
                                          Kcal/kg
                                        </td>
                                        <td style={{ borderTop: "none" }}>
                                          {
                                            this.state
                                              .quality_calorific_value3_basis
                                          }
                                        </td>
                                        <td style={{ borderTop: "none" }}>
                                          {
                                            this.state
                                              .quality_calorific_value3_typical
                                          }
                                        </td>
                                        <td style={{ borderTop: "none" }}>
                                          {
                                            this.state
                                              .quality_calorific_value3_rejection
                                          }
                                        </td>
                                        <td>
                                          <TextField
                                            name="quality_calorific_value3_actual"
                                            margin="dense"
                                            variant="outlined"
                                            fullWidth
                                            value={
                                              this.state
                                                .quality_calorific_value3_actual
                                            }
                                            //onChange={this.TextHandler}
                                            onChange={(e) => {
                                              if (
                                                e.target.value === "" ||
                                                re.test(e.target.value)
                                              ) {
                                                this.setState({
                                                  [e.target.name]: e.target.value,
                                                });
                                              }
                                            }}
                                            inputProps={{
                                              style: {
                                                textTransform: "uppercase",
                                              },
                                            }}
                                          />
                                        </td>
                                      </tr>

                                      <tr>
                                        <td
                                          className="table_header_barge_label"
                                          style={{
                                            verticalAlign: "middle",
                                            fontSize: 14,
                                          }}
                                        >
                                          Total Moisture
                                        </td>
                                        <td style={{ verticalAlign: "middle" }}>
                                          %
                                        </td>
                                        <td>
                                          {
                                            this.state
                                              .quality_total_moisture_basis
                                          }
                                        </td>
                                        <td>
                                          {
                                            this.state
                                              .quality_total_moisture_typical
                                          }
                                        </td>
                                        <td>
                                          {
                                            this.state
                                              .quality_total_moisture_rejection
                                          }
                                        </td>
                                        <td>
                                          <TextField
                                            name="quality_total_moisture_actual"
                                            margin="dense"
                                            variant="outlined"
                                            fullWidth
                                            value={
                                              this.state
                                                .quality_total_moisture_actual
                                            }
                                            onChange={this.TextHandler}
                                            inputProps={{
                                              style: {
                                                textTransform: "uppercase",
                                              },
                                            }}
                                          />
                                        </td>
                                      </tr>

                                      <tr>
                                        <td
                                          className="table_header_barge_label"
                                          style={{
                                            verticalAlign: "middle",
                                            fontSize: 14,
                                          }}
                                        >
                                          Inherent Moisture
                                        </td>
                                        <td style={{ verticalAlign: "middle" }}>
                                          %
                                        </td>
                                        <td>
                                          {
                                            this.state
                                              .quality_inherent_moisture_basis
                                          }
                                        </td>
                                        <td>
                                          {
                                            this.state
                                              .quality_inherent_moisture_typical
                                          }
                                        </td>
                                        <td>
                                          {
                                            this.state
                                              .quality_inherent_moisture_rejection
                                          }
                                        </td>
                                        <td>
                                          <TextField
                                            name="quality_inherent_moisture_actual"
                                            margin="dense"
                                            variant="outlined"
                                            fullWidth
                                            value={
                                              this.state
                                                .quality_inherent_moisture_actual
                                            }
                                            onChange={this.TextHandler}
                                            inputProps={{
                                              style: {
                                                textTransform: "uppercase",
                                              },
                                            }}
                                          />
                                        </td>
                                      </tr>

                                      <tr>
                                        <td
                                          className="table_header_barge_label"
                                          style={{
                                            verticalAlign: "middle",
                                            fontSize: 14,
                                          }}
                                        >
                                          Ash
                                        </td>
                                        <td style={{ verticalAlign: "middle" }}>
                                          %
                                        </td>
                                        <td>{this.state.quality_ash_basis}</td>
                                        <td>{this.state.quality_ash_typical}</td>
                                        <td>
                                          {this.state.quality_ash_rejection}
                                        </td>
                                        <td>
                                          <TextField
                                            name="quality_ash_actual"
                                            margin="dense"
                                            variant="outlined"
                                            fullWidth
                                            value={this.state.quality_ash_actual}
                                            //onChange={this.TextHandler}
                                            onChange={(e) => {
                                              if (
                                                e.target.value === "" ||
                                                re.test(e.target.value)
                                              ) {
                                                this.setState({
                                                  [e.target.name]: e.target.value,
                                                });
                                              }
                                            }}
                                            inputProps={{
                                              style: {
                                                textTransform: "uppercase",
                                              },
                                            }}
                                          />
                                        </td>
                                      </tr>

                                      <tr>
                                        <td
                                          className="table_header_barge_label"
                                          style={{
                                            verticalAlign: "middle",
                                            fontSize: 14,
                                          }}
                                        >
                                          Volatile Matter(VM)
                                        </td>
                                        <td style={{ verticalAlign: "middle" }}>
                                          %
                                        </td>
                                        <td>
                                          {
                                            this.state
                                              .quality_volatile_matter_basis
                                          }
                                        </td>
                                        <td>
                                          {
                                            this.state
                                              .quality_volatile_matter_typical
                                          }
                                        </td>
                                        <td>
                                          {
                                            this.state
                                              .quality_volatile_matter_rejection
                                          }
                                        </td>
                                        <td>
                                          <TextField
                                            name="quality_volatile_matter_actual"
                                            margin="dense"
                                            variant="outlined"
                                            fullWidth
                                            value={
                                              this.state
                                                .quality_volatile_matter_actual
                                            }
                                            onChange={this.TextHandler}
                                            inputProps={{
                                              style: {
                                                textTransform: "uppercase",
                                              },
                                            }}
                                          />
                                        </td>
                                      </tr>

                                      <tr>
                                        <td
                                          className="table_header_barge_label"
                                          style={{
                                            verticalAlign: "middle",
                                            fontSize: 14,
                                          }}
                                        >
                                          Fixed Carbon(FC)
                                        </td>
                                        <td style={{ verticalAlign: "middle" }}>
                                          %
                                        </td>
                                        <td>
                                          {this.state.quality_fixed_carbon_basis}
                                        </td>
                                        <td>
                                          {
                                            this.state
                                              .quality_fixed_carbon_typical
                                          }
                                        </td>
                                        <td>
                                          {
                                            this.state
                                              .quality_fixed_carbon_rejection
                                          }
                                        </td>
                                        <td>
                                          <TextField
                                            name="quality_fixed_carbon_actual"
                                            margin="dense"
                                            variant="outlined"
                                            fullWidth
                                            value={
                                              this.state
                                                .quality_fixed_carbon_actual
                                            }
                                            onChange={this.TextHandler}
                                            inputProps={{
                                              style: {
                                                textTransform: "uppercase",
                                              },
                                            }}
                                          />
                                        </td>
                                      </tr>

                                      <tr>
                                        <td
                                          className="table_header_barge_label"
                                          style={{
                                            verticalAlign: "middle",
                                            fontSize: 14,
                                          }}
                                        >
                                          Fuel Ratio(FC/VM)
                                        </td>
                                        <td style={{ verticalAlign: "middle" }}>
                                          %
                                        </td>
                                        <td>
                                          {this.state.quality_fuel_ratio_basis}
                                        </td>
                                        <td>
                                          {this.state.quality_fuel_ratio_typical}
                                        </td>
                                        <td>
                                          {
                                            this.state
                                              .quality_fuel_ratio_rejection
                                          }
                                        </td>
                                        <td>
                                          <TextField
                                            name="quality_fuel_ratio_actual"
                                            margin="dense"
                                            variant="outlined"
                                            fullWidth
                                            value={
                                              this.state.quality_fuel_ratio_actual
                                            }
                                            onChange={this.TextHandler}
                                            inputProps={{
                                              style: {
                                                textTransform: "uppercase",
                                              },
                                            }}
                                          />
                                        </td>
                                      </tr>

                                      <tr>
                                        <td
                                          className="table_header_barge_label"
                                          style={{
                                            verticalAlign: "middle",
                                            fontSize: 14,
                                          }}
                                        >
                                          Total Sulphur
                                        </td>
                                        <td style={{ verticalAlign: "middle" }}>
                                          %
                                        </td>
                                        <td>
                                          {this.state.quality_total_sulphur_basis}
                                        </td>
                                        <td>
                                          {
                                            this.state
                                              .quality_total_sulphur_typical
                                          }
                                        </td>
                                        <td>
                                          {
                                            this.state
                                              .quality_total_sulphur_rejection
                                          }
                                        </td>
                                        <td>
                                          <TextField
                                            name="quality_total_sulphur_actual"
                                            margin="dense"
                                            variant="outlined"
                                            fullWidth
                                            value={
                                              this.state
                                                .quality_total_sulphur_actual
                                            }
                                            //onChange={this.TextHandler}
                                            onChange={(e) => {
                                              if (
                                                e.target.value === "" ||
                                                re.test(e.target.value)
                                              ) {
                                                this.setState({
                                                  [e.target.name]: e.target.value,
                                                });
                                              }
                                            }}
                                            inputProps={{
                                              style: {
                                                textTransform: "uppercase",
                                              },
                                            }}
                                          />
                                        </td>
                                      </tr>

                                      <tr>
                                        <td
                                          className="table_header_barge_label"
                                          style={{
                                            verticalAlign: "middle",
                                            fontSize: 14,
                                          }}
                                        >
                                          HGI
                                        </td>
                                        <td
                                          style={{ verticalAlign: "middle" }}
                                        ></td>
                                        <td></td>
                                        <td>{this.state.quality_hgi_typical}</td>
                                        <td>
                                          {this.state.quality_hgi_rejection}
                                        </td>
                                        <td>
                                          <TextField
                                            name="quality_hgi_actual"
                                            margin="dense"
                                            variant="outlined"
                                            fullWidth
                                            value={this.state.quality_hgi_actual}
                                            onChange={this.TextHandler}
                                            inputProps={{
                                              style: {
                                                textTransform: "uppercase",
                                              },
                                            }}
                                          />
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          )}
                          {/* {(this.state.features.indexOf('323') >= 0 || this.state.features.indexOf('97') >= 0) && */}

                          <div className="card p-0 border mb-3">
                            <div className="card-header section_header">
                              <h5>File Upload Details</h5>
                            </div>
                            <div className="card-body">
                              {this.state.files.map((e, index) => (
                                <div className="" key={index}>
                                  {e.file_path ? (
                                    <div className="row mb-3" key={index}>
                                      <div className="col-lg-10 p-0">
                                        <a
                                          href={
                                            config.apiDomain + "/" + e.file_path
                                          }
                                          role="button"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          style={{ color: config.themeColor }}
                                        >
                                          {e.file_description}
                                        </a>
                                      </div>
                                      <center className="col-lg-1 d-flex justify-content-center">
                                        <i
                                          className="fa fa-trash-o m-auto"
                                          onClick={(e) => {
                                            const row = this.state.files;
                                            row.splice(index, 1);
                                            this.setState({
                                              files: row,
                                            });
                                          }}
                                          style={{
                                            fontSize: 24,
                                            color: config.themeColor,
                                            cursor: "pointer",
                                          }}
                                        />
                                      </center>
                                    </div>
                                  ) : (
                                    <div className="row mb-3" key={index}>
                                      <div className="col-lg-4 p-0">
                                        <label className="form_label mb-0">
                                          File Description
                                        </label>
                                        <TextField
                                          name="file_description"
                                          variant="outlined"
                                          margin="dense"
                                          fullWidth
                                          inputProps={{
                                            style: { textTransform: "uppercase" },
                                          }}
                                          value={e.file_description}
                                          error={e.file_description_error}
                                          onChange={(e) => {
                                            var row = this.state.files;
                                            row[index].file_description =
                                              e.target.value;
                                            row[
                                              index
                                            ].file_description_error = false;
                                            this.setState({
                                              files: row,
                                            });
                                          }}
                                        />
                                      </div>
                                      <div className="col-lg-6 pr-0">
                                        <label className="form_label mb-2">
                                          Select file to upload
                                        </label>
                                        <input
                                          type="file"
                                          name="fileToUpload"
                                          id="fileToUpload"
                                          className="form-control border-0 pl-0"
                                          onChange={this.fileUploadHandler(index)}
                                        />
                                      </div>

                                      <center className="col-lg-1 d-flex justify-content-center">
                                        <i
                                          className="fa fa-trash-o m-auto"
                                          onClick={(e) => {
                                            const row = this.state.files;
                                            row.splice(index, 1);
                                            this.setState({
                                              files: row,
                                            });
                                          }}
                                          style={{
                                            fontSize: 24,
                                            color: config.themeColor,
                                            cursor: "pointer",
                                          }}
                                        />
                                      </center>
                                    </div>
                                  )}
                                </div>
                              ))}
                              <button
                                type="button"
                                style={{ color: config.themeColor, width: 110 }}
                                className="header_button header_button_text addrow_button_adjustment"
                                onClick={this.addMoreFileHandler}
                              >
                                Add More
                              </button>
                            </div>
                          </div>
                          {/* } */}
                        </div>
                      )}
                    </div>

                    {/* <br />
                  <div className='row'>
                    <button type='button' className='btn btn-info btn-responsive mr-3 ' onClick={this.submitHandler}>Submit</button>
                    <button type='button' className='btn btn-secondary btn-responsive' onClick={() => window.location.href = '/view-business/' + btoa(this.state.businessNoID)}>Back</button>
                  </div> */}
                  </div>
                  <div className="row bottom_buttons_section">
                    <button
                      type="button"
                      className="btn previous_button"
                      name="back"
                      onClick={
                        this.state.activeStep === 0
                          ? () => {
                            window.location.href = `/purchase-quality/view/${btoa(samplingType)}/${btoa(bargeID)}/${btoa(groupID)}`
                          }
                          : this.previousStepHandler
                      }
                    >
                      {this.state.activeStep === 0 ? "CANCEL" : "PREVIOUS"}
                    </button>
                    <button
                      type="button"
                      className="btn next_button"
                      name="submit"
                      onClick={
                        this.state.activeStep === 1
                          ? this.submitHandler
                          : this.nextStepHandler
                      }
                    >
                      {this.state.activeStep === 1 ? "SUBMIT" : "NEXT STEP"}
                    </button>
                  </div>
                </section>
              </Typography>
            </div>
          )}
        </div>
      </div>
    );
  }
}
