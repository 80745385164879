import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import config from '../../config/config';

const borderColor = '#000000'
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        // borderTop:0.4,
        // borderTopColor:borderColor
        borderBottom: 0.4,
        // borderBottomColor: 'black'
    },
    description: {
        width: '70%',
        flexDirection: 'column',
        borderRight: 0.4,
        padding: 4,
        fontSize: 8,
        textAlign: 'right',
        fontWeight: 'bold',
        borderRightColor: borderColor,
        paddingRight: 8,
    },
    price: {
        width: '15%',
        flexDirection: 'column',
        borderRight: 0.4,
        padding: 4,
        fontSize: 8,
        fontStyle: 'bold',
        textAlign: 'right',
        borderRightColor: borderColor,
    },
    amount: {
        width: '15%',
        fontSize: 8,
        fontWeight: 'bold',
        textAlign: 'right',
        padding: 4,
    },
    paymentId: {
        width: '55%',
        fontSize: 8,
        textAlign: 'center',
        borderRight: 0.4,
        padding: 4,
        borderRightColor: "black",
    },
    paymentAmount: {
        width: '22.5%',
        fontSize: 8,
        textAlign: 'center',
        // borderRight: 0.4,
        padding: 4,
        borderRightColor: "black",
    },
    paymentDate: {
        width: '22.5%',
        fontSize: 8,
        textAlign: 'center',
        // borderRight: 0.4,
        padding: 4,
        borderRightColor: "black",
        // borderColor: '#000000'
        borderTop: 0,
        borderTopColor: 'black',
        borderLeftColor: 'black',
        borderLeft: 0.4,
    },
    row: {
        flexDirection: 'row',
        borderBottomWidth: 0.4,
        // borderBottomColor: "black",
        borderTopWidth: 0.4
    },
    paymentHead: {
        flexDirection: 'row',
    }
});




const InvoiceTableFooter = ({ items }) => {

    const { total_payable_amount = 0, billing_currency } = items;
    const { company } = config;

    return (
        <View style={styles.container}>
            <Text style={styles.description}>TOTAL</Text>
            <Text style={styles.price}>{billing_currency ? billing_currency : (company === "SRPL" || company === "PRPL" || company === "AVS") ? "USD" : "IDR"}</Text>
            <Text style={styles.amount}>{total_payable_amount}  </Text>
        </View>
    )
};

export default InvoiceTableFooter