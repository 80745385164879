import React, { useEffect, useState } from 'react';
import { dateFormateToDB, localDateFormate, toLocaleString } from '../common/common';

import config from '../../config/config';
import api from '../../api/api';

import {
  TextField,
  MenuItem,
  IconButton,
  InputAdornment,
  Tooltip
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import EventIcon from "@material-ui/icons/Event";
import DateFnsUtils from "@date-io/date-fns";
import CookieHandler from '../common/CookieHandler';
import BusinessApproveButton from '../Approvals/BusinessApproveButton';
const Cookie = new CookieHandler();

const AdvancePaymentPostings = ({ patmentsData, accessInfo, costingType, paymentApprType, paymentsHandler, invoicesHandler, callbackFn }) => {

  const [paymentaMode, setPaymentMode] = useState('ADD');
  const [invoiceMode, setinvoiceMode] = useState("ADD");
  const [invoiceState, setInvoiceState] = useState(
    {
      invoice_no: "",
      invoice_date: null,
      invoice_amount: "",
      invoice_percent: "",
      billing_currency: config.company === "SRPL" || config.company === "AVS" || config.company === "PRPL" ? "USD" : "IDR",
      remarks: "",
    }
  );
  const [currencies, setCurrencies] = useState([]);

  const [paymentState, setpaymentState] = useState({
    invoice_id: null,
    date_of_payment: null,
    amount_in_local_currency: '',
    billing_currency: config.company === "SRPL" || config.company === "AVS" || config.company === "PRPL" ? "USD" : "IDR",
    currency_xchg_rate: '',
    amount: '',
    invoice_no: "",
    description: ""
  })

  const [expand, setExpand] = useState(null);

  const [userRoles, setUserRoles] = useState(Cookie.getCookie("features") ? Cookie.getCookie("features") : []);

  const [invoiceOutStanding, setInvoiceOutStanding] = useState(0);

  useEffect(() => {
    setUserRoles(Cookie.getCookie("features"))
  }, [])

  useEffect(() => {
    const loginUserID = Cookie.getCookie('loginUserId');
    const idToken = Cookie.getIdTokenCookie();

    api.getCurrencies(loginUserID, idToken).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.Currency) {
            let currencies = [
              <MenuItem value="0" key={-1}>
                Please Select
              </MenuItem>,
            ];
            res.Currency.forEach((item) => {
              currencies.push(
                <MenuItem value={item.currency} key={[item.id]}>
                  {item.currency}
                </MenuItem>
              );
            });
            setCurrencies(currencies);

          }
        })
      }
    })
  }, [])


  const approvePaymentsHandler = (id) => {
    let request = {
      login_user_id: Cookie.getCookie("loginUserId"),
      idtoken: Cookie.getIdTokenCookie(),
      payment_id: id,
      costing_type: paymentApprType,
      approval_status: "Approved",
    };
    api.approve_payment_postings_api(request)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === '200') {
              callbackFn();
            }
          })
        }
      })
  }
  const {
    invoice_approver_2,
    payment_approver,
  } = accessInfo || {};

  return (
    <>
      <div className="row mt-3">
        <div className="col">
          <div className="clearfix">
            <div className="float-left p-2 pl-0">
              {patmentsData.length <= 0 && (
                <h6>No invoices made</h6>
              )}
            </div>
            <div className="float-right p-2">
              <button
                type="button"
                className='header_button header_button_text payment_button_adjustment'
                data-toggle="modal"
                // data-target="#addAdvancePayment"
                data-target="#invoicesPostingModal"
                data-backdrop="static"
                data-keyboard="false"
                onClick={() => {
                  setpaymentState({
                    date_of_payment: null,
                    amount_in_local_currency: '',
                    billing_currency: config.company === "SRPL" || config.company === "AVS" || config.company === "PRPL" ? "USD" : "IDR",
                    currency_xchg_rate: '',
                    amount: '',
                    invoice_no: "",
                    description: ""
                  })
                  // setPaymentMode("ADD");
                  setinvoiceMode("ADD");
                }}

              >
                Add Invoice
              </button>
            </div>
          </div>

          {patmentsData.length > 0 && (
            <div className="table-responsive">
              <table className="table table-borderless">
                <thead>
                  <tr>
                    <th
                      className="table_header_barge_label"
                      nowrap="true"
                    >
                      Invoice ID
                    </th>
                    <th
                      className="table_header_barge_label"
                      nowrap="true"
                    >
                      Invoice No
                    </th>
                    <th
                      className="table_header_barge_label "
                      nowrap="true"
                    >
                      Invoice Amount
                    </th>
                    <th
                      className="table_header_barge_label"
                      nowrap="true"
                    >
                      Invoice %
                    </th>
                    <th
                      className="table_header_barge_label"
                      nowrap="true"
                    >
                      Invoice Date
                    </th>
                    <th
                      className="table_header_barge_label"
                      nowrap="true"
                    >
                      Financial Approval
                    </th>
                    <th
                      className="table_header_barge_label"
                      nowrap="true"
                    >
                      Remarks
                    </th>
                    <th
                      className="table_header_barge_label"
                      nowrap="true"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                {patmentsData.map((e, idx) => (
                  <tbody key={e.id}>
                    <tr key={idx}>
                      <td className="text-uppercase "> {e.costing_no} </td>
                      <td className="text-uppercase "> {e.invoice_no} </td>
                      <td>{toLocaleString(e.invoice_amount)} </td>
                      <td> {e.invoice_percent ? e.invoice_percent : "-"} </td>
                      <td>{localDateFormate(e.invoice_date)} </td>
                      <td>
                        {e.approval_status_2 === 'Pending' && e.created_by !== Cookie.getCookie('loginUserId') &&
                          (userRoles.includes(invoice_approver_2) || userRoles.includes("97")) ?

                          <BusinessApproveButton
                            costingID={e.id}
                            costingType={costingType}
                            callbackFn={() => callbackFn()}
                          />
                          :
                          <>
                            {e.approved_by_2_name}
                          </>
                        }
                      </td>
                      <td className="text-uppercase" style={{ whiteSpace: 'pre-line' }}>
                        {e.remarks ? e.remarks : "-"}
                      </td>
                      {/* {(state.features.includes("6") ||
                                        state.features.includes("97")) && ( */}
                      <td nowrap="true">
                        <Tooltip title="Update Invoice" arrow>

                          <i
                            className="fa fa-pencil-square-o mr-2"
                            style={{
                              fontSize: 20,
                              color: config.themeColor,
                              cursor: "pointer",
                              marginRight: "10px",
                            }}
                            data-toggle="modal"
                            data-placement="bottom"
                            data-target="#invoicesPostingModal"
                            data-backdrop="static"
                            data-keyboard="false"
                            onClick={() => {
                              let data = { ...invoiceState, ...e }
                              setInvoiceState(data)
                              setinvoiceMode("UPADATE");
                            }}
                          />
                        </Tooltip>
                        <Tooltip title="Add Payment" arrow>
                          <i
                            className="fa fa-plus-circle mr-2"
                            style={{
                              fontSize: 20,
                              color: config.themeColor,
                              cursor: "pointer",
                            }}

                            data-toggle="modal"
                            data-placement="bottom"
                            data-keyboard="false"
                            data-target="#addAdvancePayment"
                            data-backdrop="static"
                            onClick={() => {

                              let data = {
                                // ...patmentsData,
                                date_of_payment: null,
                                amount_in_local_currency: '',
                                billing_currency: config.company === "SRPL" || config.company === "AVS" || config.company === "PRPL" ? "USD" : "IDR",
                                currency_xchg_rate: '',
                                amount: '',
                                description: "",
                                costing_id: e.id,
                                invoice_no: e.invoice_no,
                              }
                              setInvoiceOutStanding(e.invoice_amount);
                              setpaymentState(data)
                              setinvoiceMode("UPDATE");
                            }

                            }
                          ></i>
                        </Tooltip>
                        <Tooltip title="Expand" arrow>
                          {expand ===
                            idx ? (
                            <i
                              className="fa fa-chevron-up"
                              style={{
                                fontSize: 20,
                                color: "skyblue",
                                cursor: "pointer",
                                marginLeft: 5,
                                // float: "right",
                              }}
                              onClick={() =>
                                setExpand(null)
                              }
                            ></i>
                          ) : (
                            <i
                              className="fa fa-chevron-down "
                              style={{
                                fontSize: 20,
                                color: "skyblue",
                                cursor: "pointer",
                                marginLeft: 5,
                                // float: "right",
                                // display:
                                //   this.state.features.includes(
                                //     "80"
                                //   )  ||
                                //   this.state.features.includes("97")
                                //     ? "initial"
                                //     : "none",
                                visibility:
                                  e.payments.length > 0
                                    ? "initial"
                                    : "hidden",
                              }}
                              onClick={() =>
                                setExpand(idx)
                              }
                            ></i>
                          )}
                        </Tooltip>
                      </td>
                      {/* )} */}
                    </tr>


                    <tr

                      style={{
                        visibility:
                          expand ===
                            idx
                            ? "visible"
                            : "collapse",

                      }}>

                      <td colSpan={7}>
                        <div className="table-responsive">

                          <table className='table table-bordered table-sm'>
                            <thead className="table-header">
                              <tr>
                                <th className="table_header_barge_label text-center border"
                                  nowrap="true">Payment ID</th>
                                <th className="table_header_barge_label text-center border"
                                  nowrap="true">Date of Payment</th>
                                <th className="table_header_barge_label text-center border"
                                  nowrap="true">Amount</th>
                                <th className="table_header_barge_label text-center border"
                                  nowrap="true">Currency</th>
                                <th className="table_header_barge_label text-center  border"
                                  nowrap="true">Exchange Rate</th>
                                <th className="table_header_barge_label text-center border"
                                  nowrap="true">Amount in {config.company === "SRPL" || config.company === "AVS" || config.company === "PRPL" ? "USD" : "IDR"}</th>
                                <th className="table_header_barge_label text-center border" nowrap="true">Approval</th>
                                <th className="table_header_barge_label text-center border" nowrap="true">Approved ON</th>
                                <th className="table_header_barge_label text-center border"
                                  nowrap="true">Remarks</th>
                                <th className="table_header_barge_label text-center border"
                                  nowrap="true">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {e.payments.map((item, index) => (
                                <tr key={item.id}>
                                  <td className=" text-center border"
                                    nowrap="true">{item.payment_id}</td>
                                  <td className=" text-center border"
                                    nowrap="true">{localDateFormate(item.date_of_payment)}</td>
                                  <td className=" text-right border"
                                    nowrap="true">{toLocaleString(item.amount_in_local_currency)}</td>
                                  <td className=" text-center border"
                                    nowrap="true">{item.billing_currency}</td>
                                  <td className=" text-right border"
                                    nowrap="true">{toLocaleString(item.currency_xchg_rate)}</td>
                                  <td className=" text-right border"
                                    nowrap="true">{toLocaleString(item.amount)}</td>
                                  <td className="border" nowrap="true">
                                    {e.approval_status_2 === "Approved" && item.approval_status === "Pending" && item.created_by !== Cookie.getCookie('loginUserId') && (userRoles.includes(payment_approver) || userRoles.includes("97")) ?
                                      <button
                                        className="header_button header_button_text add_button_adjustment"
                                        style={{
                                          width: "auto",
                                          marginTop: "17px",
                                        }}
                                        onClick={() =>
                                          approvePaymentsHandler(
                                            item.id
                                          )
                                        }
                                      >
                                        Approve
                                      </button>
                                      :
                                      <>
                                        {item.approved_by_name}
                                      </>
                                    }
                                  </td>
                                  <td className="border" nowrap="true">
                                    {localDateFormate(item.approved_date)}
                                  </td>
                                  <td className=" text-center border text-uppercase"
                                    nowrap="true" style={{ whiteSpace: 'pre-line' }}>{item.description}</td>
                                  <td className=' text-center border'>
                                    <Tooltip title="Edit" arrow>
                                      <i
                                        className="fa fa-pencil"
                                        style={{
                                          fontSize: 20,
                                          color: config.themeColor,
                                          cursor: "pointer",
                                          marginRight: "10px",
                                        }}
                                        data-toggle="modal"
                                        data-placement="bottom"
                                        data-keyboard="false"
                                        data-target="#addAdvancePayment"
                                        data-backdrop="static"
                                        onClick={() => {

                                          let data = {
                                            ...patmentsData,
                                            ...item,
                                            invoice_id: e.id,
                                            invoice_no: e.invoice_no,
                                            vendor_adv_contract_id: e.vendor_adv_contract_id
                                          }
                                          setInvoiceOutStanding(e.invoice_amount);
                                          setpaymentState(data)
                                          setPaymentMode("UPDATE");
                                        }
                                        }
                                      ></i>
                                    </Tooltip>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </td>

                    </tr>
                  </tbody>
                ))}

              </table>
            </div>
          )}
        </div>
      </div>

      <div className="modal fade" id="addAdvancePayment" role="dialog">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content payment-modal">
            <div
              className="modal-header border-0"
              style={{ backgroundColor: "#f1f1f1" }}
            >
              <h6
                className="modal-title"
                style={{ border: "none", padding: 0 }}
              >
                Advance Payment Postings
              </h6>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="col">
                <div
                  style={{
                    fontSize: 14,
                    color: "red",
                    textTransform: "capitalize",
                    textAlign: "center",
                  }}
                >
                  {paymentState.paymentErrorMsg}
                </div>
                <div className="row">
                  <div className="col-lg-6 mb-2">
                    <label className="form_label mb-0"> Date of Payment
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        autoOk={true}
                        inputVariant="outlined"
                        variant="inline"
                        margin="dense"
                        fullWidth
                        format="dd/MM/yyyy"
                        value={paymentState.date_of_payment}
                        error={paymentState.payment_date_error}
                        onChange={(date) => {
                          let data = {
                            ...paymentState,
                            payment_date_error: false,
                            date_of_payment: dateFormateToDB(date)
                          }
                          setpaymentState(data);
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton>
                                <EventIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                          className: "pl-0",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>

                  <div className="col-lg-6 mb-2">
                    <label className="form_label mb-0"> Amount <span style={{ color: "red" }}>*</span> </label>
                    <TextField
                      name="amount"
                      margin="dense"
                      variant="outlined"
                      placeholder="Amount"
                      required
                      value={paymentState.amount}
                      error={paymentState.amount_error}
                      fullWidth
                      onChange={(e) => {
                        const pr = /^\d*(\.\d{0,4})?$/;
                        if (
                          e.target.value === "" ||
                          pr.test(e.target.value)
                        ) {
                          var amount_in_local_currency = 0;
                          if (
                            ((config.company === "SRPL" || config.company === "AVS" ||
                              config.company === "PRPL") &&
                              paymentState.billing_currency !== "USD") ||
                            ((config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") &&
                              paymentState.billing_currency !== "IDR")
                          ) {
                            amount_in_local_currency =
                              Number(e.target.value) *
                              Number(
                                paymentState.currency_xchg_rate
                                  ? paymentState.currency_xchg_rate
                                  : 1
                              );
                          } else {
                            amount_in_local_currency = e.target.value;
                          }
                          let ammount_err = false;
                          if (parseFloat(invoiceOutStanding) < parseFloat(amount_in_local_currency)) {
                            ammount_err = true;
                            // return;
                          }
                          let data = {
                            ...paymentState,
                            amount_error: ammount_err,
                            amount: e.target.value,
                            amount_in_local_currency: amount_in_local_currency,
                          }
                          setpaymentState(data);
                        }
                      }}
                    />
                  </div>
                  <div className="col-lg-6 mb-2">
                    <label className="form_label mb-0">
                      Currency <span style={{ color: "red" }}>*</span>
                    </label>
                    <TextField
                      name="billing_currency"
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      value={paymentState.billing_currency}
                      error={paymentState.payment_currency_error}
                      onChange={(e) => {
                        var amount_in_local_currency = 0;
                        if (
                          ((config.company === "SRPL" || config.company === "AVS" ||
                            config.company === "PRPL") &&
                            e.target.value !== "USD") ||
                          ((config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") &&
                            e.target.value !== "IDR")
                        ) {
                          amount_in_local_currency =
                            Number(
                              paymentState.amount
                            ) *
                            Number(
                              paymentState.currency_xchg_rate
                                ? paymentState.currency_xchg_rate
                                : 1
                            );
                        } else {
                          amount_in_local_currency =
                            paymentState.amount;
                        }
                        let data = {
                          ...paymentState,
                          billing_currency: e.target.value,
                          amount_in_local_currency: amount_in_local_currency,
                          currency_xchg_rate: '',
                          payment_currency_error: false,
                        }
                        setpaymentState(data);
                      }}
                      select
                    >
                      {currencies}
                    </TextField>
                  </div>
                  {(((config.company === "SRPL" || config.company === "AVS" ||
                    config.company === "PRPL") &&
                    paymentState.billing_currency !== "USD" &&
                    paymentState.billing_currency !== "0") ||
                    ((config.company === "SDAM" ||
                      config.company === "SRK" ||
                      config.company === "BTR") &&
                      paymentState.billing_currency !== "IDR" &&
                      paymentState.billing_currency !== "0")) && (
                      <div className="col-lg-6 mb-2">
                        <label className="form_label mb-0">
                          Exchange Rate from {paymentState.billing_currency}{" "} to
                          {config.company === "SRPL" || config.company === "AVS" ||
                            config.company === "PRPL"
                            ? "USD"
                            : "IDR"}
                        </label>
                        <TextField
                          name="currency_xchg_rate"
                          margin="dense"
                          variant="outlined"
                          placeholder="Exchange Rate"
                          required
                          value={paymentState.currency_xchg_rate}
                          // error={state.amountError}
                          fullWidth
                          onChange={(e) => {
                            const pr = /^\d*(\.\d{0,10})?$/;
                            if (
                              e.target.value === "" ||
                              pr.test(e.target.value)
                            ) {

                              let data = {
                                ...paymentState,
                                currency_xchg_rate: e.target.value,
                                amount_in_local_currency: Number(e.target.value) *
                                  Number(
                                    paymentState.amount
                                      ? paymentState
                                        .amount
                                      : 1
                                  )
                              }
                              setpaymentState(data)
                            }
                          }}
                        />
                      </div>
                    )}

                  <div className="col-lg-6 mb-2">
                    <label className="form_label mb-0"> Amount in
                      {config.company === "SRPL" || config.company === "AVS" ||
                        config.company === "PRPL"
                        ? " USD"
                        : " IDR"}
                    </label>
                    <div>
                      <label
                        className="col mt-2 pl-0"
                        style={{ color: "#483b34", fontSize: "14px" }}
                      >
                        {paymentState.amount_in_local_currency}
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-6 mb-2">
                    <label className="form_label mb-0">Invoice No</label>
                    <div className='mt-2 text-uppercase'>{paymentState.invoice_no}</div>
                    {/* <TextField
                      name="invoice_no"
                      margin="dense"
                      variant="outlined"
                      placeholder="invoice_no"
                      inputProps={{
                        style: { textTransform: "uppercase" },
                      }}
                      value={paymentState.invoice_no}
                      onChange={(e) => {
                        let data = {
                          ...paymentState,
                          invoice_no: e.target.value
                        }
                        setpaymentState(data);
                      }}
                      fullWidth
                    /> */}
                  </div>
                  <div className="col-lg-6 mb-2">
                    <label className="form_label mb-0">Remarks</label>
                    <TextField
                      name="description"
                      margin="dense"
                      variant="outlined"
                      placeholder="Remarks"
                      multiline
                      rows={3}
                      inputProps={{
                        style: { textTransform: "uppercase" },
                      }}
                      value={paymentState.description}
                      onChange={(e) => {
                        let data = {
                          ...paymentState,
                          description: e.target.value
                        }
                        setpaymentState(data);
                      }}
                      fullWidth
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row bottom_buttons_section">
              <button
                type="button"
                className="btn previous_button"
                name="submit"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn next_button"
                name="Back"
                onClick={() => {
                  let stateCopy = {
                    ...paymentState,
                    login_user_id: Cookie.getCookie('loginUserId'),
                    idtoken: Cookie.getIdTokenCookie(),
                    currency_xchg_rate: paymentState.currency_xchg_rate ? paymentState.currency_xchg_rate : 1,
                    invoice_no: (paymentState?.invoice_no && paymentState?.invoice_no?.length > 0) ? paymentState.invoice_no.toUpperCase() : null,
                    description: (paymentState?.description && paymentState?.description?.length > 0) ? paymentState.description.toUpperCase() : null
                  }
                  const { date_of_payment, amount_in_local_currency, billing_currency, } = stateCopy || {};
                  let errors = 0;
                  if (date_of_payment === null) {
                    stateCopy['payment_date_error'] = true;
                    errors++;
                  }
                  if (amount_in_local_currency === '' || amount_in_local_currency === null || parseFloat(invoiceOutStanding) < parseFloat(amount_in_local_currency)) {
                    stateCopy['amount_error'] = true;
                    errors++;
                  }
                  if (billing_currency === '0' || !billing_currency) {
                    stateCopy['payment_currency_error'] = true;
                    errors++;

                  }
                  if (errors > 0) {
                    setpaymentState(stateCopy);
                    return;
                  }

                  paymentsHandler(paymentaMode, stateCopy)
                }}
              >
                Submit
              </button>
            </div>
          </div>

        </div>
      </div>

      {
        /**
         * 
         *  CREATE INVOICE MODEL
         * 
         */
      }


      <div
        className="modal fade"
        id="invoicesPostingModal"
        role="dialog"
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content  payment-modal">
            <div
              className="modal-header border-0"
              style={{ backgroundColor: "#f1f1f1" }}
            >
              <h6
                className="modal-title"
                style={{ border: "none", padding: 0 }}
              >
                Invoices
              </h6>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-6 mb-2">
                  <label className="form_label mb-0">
                    Invoice No <span style={{ color: "red" }}>*</span>
                  </label>
                  <TextField
                    name="invoice_no"
                    margin="dense"
                    variant="outlined"
                    placeholder="Invoice No"
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    error={invoiceState.invoice_no_error}
                    value={invoiceState.invoice_no}
                    onChange={(e) => {
                      let data = {
                        ...invoiceState,
                        invoice_no: e.target.value,
                        invoice_no_error: false,
                      }
                      setInvoiceState(data);
                    }}
                    fullWidth
                  />
                </div>
                <div className="col-lg-6 mb-2">
                  <label className="form_label mb-0">
                    Invoice Amount <span style={{ color: "red" }}>*</span>
                  </label>
                  <TextField
                    name="invoice_amount"
                    margin="dense"
                    variant="outlined"
                    placeholder="Amount"
                    required
                    value={invoiceState.invoice_amount}
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    fullWidth
                    error={invoiceState.invoice_amount_error}
                    onChange={(e) => {
                      const pr = /^\d*(\.\d{0,4})?$/;
                      if (
                        e.target.value === "" ||
                        pr.test(e.target.value)
                      ) {
                        let data = {
                          ...invoiceState,
                          invoice_amount: e.target.value,
                          invoice_amount_error: false,
                        }
                        setInvoiceState(data);

                      }
                    }}
                  />
                </div>
                <div className="col-lg-6 mb-2">
                  <label className="form_label mb-0">Invoice %</label>
                  <TextField
                    name="invoice_percent"
                    margin="dense"
                    variant="outlined"
                    placeholder="Percent"
                    required
                    value={invoiceState.invoice_percent}
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    fullWidth
                    error={invoiceState.invoice_percentError}
                    onChange={(e) => {
                      let data = {
                        ...invoiceState,
                        invoice_percent: e.target.value,
                      }
                      setInvoiceState(data);
                    }
                    }
                  />
                </div>
                <div className="col-lg-6 mb-2">
                  <label className="form_label mb-0">
                    Invoice Date <span style={{ color: "red" }}>*</span>
                  </label>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      autoOk={true}
                      inputVariant="outlined"
                      variant="inline"
                      margin="dense"
                      fullWidth
                      format="dd/MM/yyyy"
                      value={invoiceState.invoice_date}
                      error={invoiceState.invoice_date_error}
                      onChange={(date) => {
                        let data = {
                          ...invoiceState,
                          invoice_date: dateFormateToDB(date),
                          invoice_date_error: false
                        }
                        setInvoiceState(data);
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton>
                              <EventIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                        className: "pl-0",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
                <div className="col-lg-12 mb-2">
                  <label className="form_label mb-0">Remarks</label>
                  <TextField
                    name="remarks"
                    margin="dense"
                    variant="outlined"
                    placeholder="Remarks"
                    multiline
                    rows={3}
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    value={invoiceState.remarks}
                    onChange={(e) => {
                      let data = {
                        ...invoiceState,
                        remarks: e.target.value,
                      }
                      setInvoiceState(data);
                    }}
                    fullWidth
                  />
                </div>
              </div>
            </div>
            <div className="row bottom_buttons_section">
              <button
                type="button"
                className="btn previous_button"
                data-dismiss="modal"
                onClick={() => {

                  setInvoiceState({
                    invoice_no: "",
                    invoice_date: null,
                    invoice_amount: "",
                    invoice_percent: "",
                    billing_currency: config.company === "SRPL" || config.company === "AVS" || config.company === "PRPL" ? "USD" : "IDR",
                    remarks: "",
                  })

                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn next_button"
                onClick={() => {
                  let stateCopy = {
                    ...invoiceState,
                    login_user_id: Cookie.getCookie('loginUserId'),
                    idtoken: Cookie.getIdTokenCookie(),
                    invoice: (invoiceState?.invoice_no && paymentState?.invoice_no?.length > 0) ? invoiceState.invoice_no.toUpperCase() : null,
                    remarks: (invoiceState?.remarks && invoiceState?.remarks?.length > 0) ? invoiceState.remarks.toUpperCase() : null
                  }
                  const { invoice_no, invoice_date, invoice_amount, } = stateCopy || {};
                  let errors = 0;
                  if (invoice_no === null || invoice_no === '') {
                    stateCopy['invoice_no_error'] = true;
                    errors++;
                  }
                  if (invoice_date === null) {
                    stateCopy['invoice_date_error'] = true;
                    errors++;
                  }
                  if (invoice_amount === '' || invoice_amount === null) {
                    stateCopy['invoice_amount_error'] = true;
                    errors++;
                  }

                  if (errors > 0) {
                    setInvoiceState(stateCopy);
                    return;
                  }
                  invoicesHandler(invoiceMode, stateCopy)
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AdvancePaymentPostings;