import React, { Component } from "react";
import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import {
  TextField,
  MenuItem,
  InputAdornment,
  Snackbar,
} from "@material-ui/core";
import api from "../../../api/api";
import { Alert } from "@material-ui/lab";
import FileUpload from "../../common/FileUpload";
import Loader from "../../common/Loader";
import { toLocaleString } from "../../common/common";
export default class MvOthersAdd extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      multiRows: [
        {
          sac_applicable: "No",
          invoice_date: null,
          invoice_no: "",
          ppn: "",

          mv_surveyor_charges: [
            {
              mv_sac_charges_vendor_id: "0",
              sac_quantity_in_mt: "",
              sac_price_per_mt: "",
              sac_surveyor_base_price: "",
              sac_surveyor_vat_percent: "10",
              sac_surveyor_vat_value: "",
              sac_surveyor_pph23_percent: "2",
              sac_surveyor_pph23_value: "",
              sac_surveyor_total: "",
              sac_surveyor_ctc: "",
              sac_surveyor_currency:
                config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL"
                  ? "USD"
                  : "0",
              sac_surveyor_currency_xchg_rate: "",
              sac_surveyor_ctc_in_local_currency: "",
            },
          ],

          sac_surveyor_price_per_addl_day: "",
          sac_surveyor_addl_days: "",
          sac_surveyor_addl_days_base_price: "",
          sac_surveyor_addl_days_vat_percent: "10",
          sac_surveyor_addl_days_vat_value: "",
          sac_surveyor_addl_days_pph23_percent: "2",
          sac_surveyor_addl_days_pph23_value: "",
          sac_surveyor_addl_days_total: "",
          sac_surveyor_addl_days_ctc: "",
          sac_surveyor_addl_days_currency: "0",
          sac_surveyor_addl_days_currency_xchg_rate: "",
          sac_surveyor_addl_days_ctc_in_local_currency: "",
          remarks: "",
          other_charges: [
            {
              mv_sac_charges_vendor_id: "0",
              sac_other_base_price: "",
              sac_other_description: "",
              sac_other_vat_percent: "10",
              sac_other_vat_value: "",
              sac_other_pph23_percent: "2",
              sac_other_pph23_value: "",
              sac_other_total: "",
              sac_other_ctc: "",
              sac_other_currency: "0",
              sac_other_currency_xchg_rate: "",
              sac_other_ctc_in_local_currency: "",
            },
          ],
        },
      ],

      currencyData: [],

      isLoading: true,
      features: [],
      FinancialDataAvailable: false,
      files: [
        {
          file_description: "",
          file_name: "",
          file_extension: "",
          file_content: "",
          feature_name: "Mv_Pur_Fin_Sac_Charges",
        },
      ],
      costing_files: [],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    var features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (!(features.includes("242") || features.includes("97"))) {
      window.location.href = "/dashboard";
    }
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    // for get_Currencies
    await api.getCurrencies(loginUserID, idToken).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            if (res.Currency) {
              var currencies = [
                <MenuItem
                  style={{ fontFamily: "Poppins", fontSize: "14px" }}
                  value="0"
                  key={-1}
                  disabled
                >
                  Please Select
                </MenuItem>,
              ];
              for (var i in res.Currency) {
                currencies.push(
                  <MenuItem
                    style={{ fontFamily: "Poppins", fontSize: "14px" }}
                    value={res.Currency[i].currency}
                    key={[i]}
                  >
                    {res.Currency[i].currency}
                  </MenuItem>
                );
              }
              this.setState({
                currencyData: currencies,
              });
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          } else if (res.code === "601") {
            this.setState({
              errorMsg: res.message,
              snackBarErrorOpen: true,
              isLoading: false,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });

    // Get Vendors
    var status = "Active";
    await api.getVendors(loginUserID, idToken, status).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            if (res.vendors) {
              const surveyors = [
                <MenuItem value="0" key="0" disabled>
                  Please Select
                </MenuItem>,
              ];
              for (var i of res.vendors) {
                if (i.vendor_type === "Surveyor") {
                  surveyors.push(
                    <MenuItem
                      style={{ fontFamily: "Poppins", fontSize: "14px" }}
                      value={i.id}
                      key={[i.id]}
                    >
                      {i.vendor_name}
                    </MenuItem>
                  );
                }
              }
              this.setState({
                surveyors: surveyors,
                vendorsData: res.vendors,
              });
            }
          }
        });
      }
    });
    try {
      //var businessNoID = decodeURIComponent(window.atob(this.props.match.params.businessNoID));
      var businessNoID = this.props.businessNoID;
      //Get businessNumber API based on businessNumber
      var row = this.state.multiRows;
      await api
        .getBusinessNumber(businessNoID, loginUserID, idToken)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                if (res.business_number) {
                  if (
                    res.business_number.consignment_type === "Mother Vessel"
                  ) {
                    // if consignment_type === Mother Vessel check for this businessNumber mother Vessel data is avilabe or not
                    //Get Business No Operational Mother Vessel list
                    api
                      .getBusinessNumberMotherVessel(
                        businessNoID,
                        loginUserID,
                        idToken
                      )
                      .then((response) => {
                        if (response.status >= 200 && response.status < 300) {
                          response.json().then((res) => {
                            if (res.idtoken)
                              this.Cookie.setIdTokenCookie(res.idtoken);
                            if (res.code === "200") {
                              if (res.business_no_MV.length > 0) {
                                var motherVesselID = res.business_no_MV[0].id;
                                //Get Business No Operational Mother Vessel Details
                                api
                                  .getBusinessNoOpMVDetails(
                                    motherVesselID,
                                    loginUserID,
                                    idToken
                                  )
                                  .then((response) => {
                                    if (
                                      response.status >= 200 &&
                                      response.status < 300
                                    ) {
                                      response.json().then((res) => {
                                        if (res.code === "200") {
                                          if (res.business_no_MV) {
                                            let sac_price_per_mt =
                                              res.business_no_MV
                                                .surveyor_base_price === null
                                                ? ""
                                                : res.business_no_MV
                                                    .surveyor_base_price;
                                            let sac_surveyor_price_per_addl_day =
                                              res.business_no_MV
                                                .surveyor_base_price === null
                                                ? ""
                                                : res.business_no_MV
                                                    .surveyor_base_price;
                                            let sac_surveyor_addl_days =
                                              res.business_no_MV
                                                .surveyor_additional_days ===
                                              null
                                                ? ""
                                                : res.business_no_MV
                                                    .surveyor_additional_days;

                                            row[0].sac_quantity_in_mt =
                                              res.business_no_MV.vessel_final_quantity_in_mt;
                                            row[0].sac_price_per_mt =
                                              sac_price_per_mt;
                                            row[0].sac_surveyor_price_per_addl_day =
                                              sac_surveyor_price_per_addl_day;
                                            row[0].sac_surveyor_addl_days =
                                              sac_surveyor_addl_days;
                                            this.setState(
                                              {
                                                motherVesselID: motherVesselID,
                                                vessel_type: res.business_no_MV
                                                  .vessel_type
                                                  ? res.business_no_MV
                                                      .vessel_type
                                                  : "",
                                                vessel_final_quantity_in_mt: res
                                                  .business_no_MV
                                                  .vessel_final_quantity_in_mt
                                                  ? res.business_no_MV
                                                      .vessel_final_quantity_in_mt
                                                  : "",
                                                quantity_in_mt: res
                                                  .business_no_MV
                                                  .vessel_final_quantity_in_mt
                                                  ? res.business_no_MV
                                                      .vessel_final_quantity_in_mt
                                                  : "",

                                                //sac_surveyor_name: res.business_no_MV.internal_surveyor_names === null ? '' : res.business_no_MV.internal_surveyor_names,
                                                sac_surveyor_addl_days:
                                                  res.business_no_MV
                                                    .surveyor_additional_days ===
                                                  null
                                                    ? ""
                                                    : res.business_no_MV
                                                        .surveyor_additional_days,
                                                sac_surveyor_price_per_addl_day:
                                                  res.business_no_MV
                                                    .surveyor_base_price ===
                                                  null
                                                    ? ""
                                                    : res.business_no_MV
                                                        .surveyor_base_price,

                                                internal_documentation_agent:
                                                  res.business_no_MV
                                                    .internal_documentation_agent ===
                                                  null
                                                    ? ""
                                                    : res.business_no_MV
                                                        .internal_documentation_agent,
                                                joint_documentation_agent:
                                                  res.business_no_MV
                                                    .internal_documentation_agent ===
                                                  null
                                                    ? ""
                                                    : res.business_no_MV
                                                        .joint_documentation_agent,
                                                supplier_documentation_agent:
                                                  res.business_no_MV
                                                    .supplier_documentation_agent ===
                                                  null
                                                    ? ""
                                                    : res.business_no_MV
                                                        .supplier_documentation_agent,
                                                doc_agent_on_mv:
                                                  res.business_no_MV
                                                    .supplier_documentation_agent ===
                                                  null
                                                    ? ""
                                                    : res.business_no_MV
                                                        .supplier_documentation_agent,
                                                sac_price_per_mt:
                                                  res.business_no_MV
                                                    .surveyor_base_price ===
                                                  null
                                                    ? ""
                                                    : res.business_no_MV
                                                        .surveyor_base_price,
                                                multiRows: row,
                                              },
                                              () => {
                                                this.surveyorChargesCalculatons(
                                                  0,
                                                  0
                                                );
                                                this.surveyorAddDaysPriceCalculations(
                                                  0
                                                );
                                              }
                                            );
                                          } else {
                                            alert(
                                              "Unexpected error occured. Please contact administrator."
                                            );
                                          }
                                        }
                                      });
                                    }
                                  });
                              }
                            }
                          });
                        }
                      });
                  }
                  this.setState({
                    businessNo: res.business_number.business_no
                      ? res.business_number.business_no
                      : "",
                    mother_vessel_name: res.business_number.mother_vessel_name
                      ? res.business_number.mother_vessel_name
                      : "",
                    multiRows: row,
                    isLoading: false,
                  });
                }
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  sucuessMsg: "",
                });
                window.$("#AlertMessageModal").modal({
                  backdrop: "static",
                  keyboard: true,
                  show: true,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });

      // get mother vessel Sampling & Analaysis api date
      await api
        .get_mv_pur_fin_sac_charges(loginUserID, idToken, businessNoID)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                if (res.mv_pur_fin_sac_charges.length > 0) {
                  this.setState(
                    {
                      // errorMsg: "financial data already available.",
                      // snackBarErrorOpen: true,
                      isLoading: false,
                      FinancialDataAvailable: true,
                    },
                    () => {
                      setTimeout(() => {
                        //this.props.onAddResponse({ screen: "MvSac", code: "", message: 'alreadyDataAvailable' });
                      }, 2000);
                    }
                  );
                }
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  snackBarErrorOpen: true,
                  isLoading: false,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    } catch (e) {
      // window.location.href = '/business-number-list';
    }
  }

  surveyorChargesCalculatons = (idx, index) => {
    var row = this.state.multiRows;
    let rows = row[idx].mv_surveyor_charges;
    let sac_surveyor_base_price = (
      Number(rows[index].sac_quantity_in_mt) *
      Number(rows[index].sac_price_per_mt)
    ).toFixed(2);

    let sac_surveyor_currency_xchg_rate = rows[index]
      .sac_surveyor_currency_xchg_rate
      ? rows[index].sac_surveyor_currency_xchg_rate
      : 1;
    let sac_surveyor_ctc_in_local_currency = "";

    if (
      (config.company === "SDAM" ||
        config.company === "SRK" ||
        config.company === "BTR") &&
      rows[index].sac_surveyor_currency !== "IDR" &&
      rows[index].sac_surveyor_currency !== "IDR"
    ) {
      sac_surveyor_ctc_in_local_currency = (
        Number(rows[idx].sac_price_per_mt ? rows[index].sac_price_per_mt : 1) *
        Number(sac_surveyor_currency_xchg_rate)
      ).toFixed(2);
      sac_surveyor_base_price =
        Number(rows[index].sac_quantity_in_mt) *
        Number(sac_surveyor_ctc_in_local_currency);
    }
    let sac_surveyor_vat_value = (
      (Number(sac_surveyor_base_price) * 10) /
      100
    ).toFixed(2);
    let sac_surveyor_pph23_value = (
      (Number(sac_surveyor_base_price) * 2) /
      100
    ).toFixed(2);
    if (config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL") {
      sac_surveyor_pph23_value = "";
    }
    let sac_surveyor_total = (
      Number(sac_surveyor_base_price) +
      Number(sac_surveyor_vat_value) -
      Number(sac_surveyor_pph23_value)
    ).toFixed(2);
    let sac_surveyor_ctc = (
      Number(sac_surveyor_base_price) + Number(sac_surveyor_vat_value)
    ).toFixed(2);

    rows[index].sac_surveyor_base_price = sac_surveyor_base_price;
    rows[index].sac_surveyor_vat_percent = 10;
    rows[index].sac_surveyor_ctc_in_local_currency =
      sac_surveyor_ctc_in_local_currency;
    rows[index].sac_surveyor_vat_value = sac_surveyor_vat_value;
    rows[index].sac_surveyor_pph23_percent = 2;
    rows[index].sac_surveyor_pph23_value = sac_surveyor_pph23_value;
    rows[index].sac_surveyor_total = sac_surveyor_total;
    rows[index].sac_surveyor_ctc = sac_surveyor_ctc;
    this.setState({
      multiRows: row,
    });
  };

  surveyorAddDaysPriceCalculations = (idx) => {
    var row = this.state.multiRows;
    var sac_surveyor_addl_days_base_price = (
      Number(row[idx].sac_surveyor_addl_days) *
      Number(row[idx].sac_surveyor_price_per_addl_day)
    ).toFixed(2);
    let sac_surveyor_addl_days_currency_xchg_rate = row[idx]
      .sac_surveyor_addl_days_currency_xchg_rate
      ? row[idx].sac_surveyor_addl_days_currency_xchg_rate
      : 1;
    let sac_surveyor_addl_days_ctc_in_local_currency = "";

    if (
      (config.company === "SDAM" ||
        config.company === "SRK" ||
        config.company === "BTR") &&
      row[idx].sac_surveyor_addl_days_currency !== "IDR" &&
      row[idx].sac_surveyor_addl_days_currency !== "0"
    ) {
      sac_surveyor_addl_days_ctc_in_local_currency = (
        Number(row[idx].sac_surveyor_price_per_addl_day) *
        Number(sac_surveyor_addl_days_currency_xchg_rate)
      ).toFixed(2);
      sac_surveyor_addl_days_base_price =
        Number(row[idx].sac_surveyor_addl_days) *
        Number(sac_surveyor_addl_days_ctc_in_local_currency);
    }
    let sac_surveyor_addl_days_vat_value = (
      (Number(sac_surveyor_addl_days_base_price) * 10) /
      100
    ).toFixed(2);
    let sac_surveyor_addl_days_pph23_value = (
      (Number(sac_surveyor_addl_days_base_price) * 2) /
      100
    ).toFixed(2);
    if (config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL") {
      sac_surveyor_addl_days_pph23_value = "";
    }
    let sac_surveyor_addl_days_total = (
      Number(sac_surveyor_addl_days_base_price) +
      Number(sac_surveyor_addl_days_vat_value) -
      Number(sac_surveyor_addl_days_pph23_value)
    ).toFixed(2);
    let sac_surveyor_addl_days_ctc = (
      Number(sac_surveyor_addl_days_base_price) +
      Number(sac_surveyor_addl_days_vat_value)
    ).toFixed(0);

    row[idx].sac_surveyor_addl_days_base_price =
      sac_surveyor_addl_days_base_price;
    row[idx].sac_surveyor_addl_days_vat_value =
      sac_surveyor_addl_days_vat_value;
    row[idx].sac_surveyor_addl_days_ctc_in_local_currency =
      sac_surveyor_addl_days_ctc_in_local_currency;
    row[idx].sac_surveyor_addl_days_pph23_value =
      sac_surveyor_addl_days_pph23_value;
    row[idx].sac_surveyor_addl_days_total = sac_surveyor_addl_days_total;
    row[idx].sac_surveyor_addl_days_ctc = sac_surveyor_addl_days_ctc;

    this.setState({
      multiRows: row,
    });
  };

  addMoreRowsHandler = () => {
    const item = {
      mv_sac_charges_vendor_id: this.state.mv_sac_charges_vendor_id,

      sac_applicable: "No",
      invoice_date: null,
      invoice_no: "",
      ppn: "",
      sac_quantity_in_mt: this.state.quantity_in_mt,
      sac_price_per_mt: "",
      sac_surveyor_base_price: "",
      sac_surveyor_vat_percent: "10",
      sac_surveyor_vat_value: "",
      sac_surveyor_pph23_percent: "2",
      sac_surveyor_pph23_value: "",
      sac_surveyor_total: "",
      sac_surveyor_ctc: "",
      sac_surveyor_currency:
        config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL" ? "USD" : "0",
      sac_surveyor_currency_xchg_rate: "",
      sac_surveyor_ctc_in_local_currency: "",

      sac_surveyor_price_per_addl_day:
        this.state.sac_surveyor_price_per_addl_day,
      sac_surveyor_addl_days: this.state.sac_surveyor_addl_days,
      sac_surveyor_addl_days_base_price: "",
      sac_surveyor_addl_days_vat_percent: "",
      sac_surveyor_addl_days_vat_value: "",
      sac_surveyor_addl_days_pph23_percent: "2",
      sac_surveyor_addl_days_pph23_value: "",
      sac_surveyor_addl_days_total: "",
      sac_surveyor_addl_days_ctc: "",
      sac_surveyor_addl_days_currency: "0",
      sac_surveyor_addl_days_currency_xchg_rate: "",
      sac_surveyor_addl_days_ctc_in_local_currency: "",
      remarks: "",

      other_charges: [
        {
          sac_other_base_price: "",
          sac_other_description: "",
          sac_other_vat_percent: "",
          sac_other_vat_value: "",
          sac_other_pph23_percent: "2",
          sac_other_pph23_value: "",
          sac_other_total: "",
          sac_other_ctc: "",
          sac_other_currency: "0",
          sac_other_currency_xchg_rate: "",
          sac_other_ctc_in_local_currency: "",
        },
      ],
    };

    this.setState({
      multiRows: [...this.state.multiRows, item],
    });
  };

  removeRowsHandler = (idx) => () => {
    const rows = this.state.multiRows;
    rows.splice(idx, 1);
    this.setState({
      multiRows: rows,
    });
  };

  removeOtherChargesRowsHandler = (idx, index) => () => {
    const row = this.state.multiRows[idx].other_charges;
    row.splice(index, 1);
    this.setState({
      multiRows: this.state.multiRows,
    });
  };

  addMoreOtherChargesRowsHandler = (idx) => {
    const item = {
      sac_other_base_price: "",
      sac_other_description: "",
      sac_other_vat_percent: "10",
      sac_other_vat_value: "",
      sac_other_pph23_percent: "2",
      sac_other_pph23_value: "",
      sac_other_total: "",
      sac_other_ctc: "",
      sac_other_currency: "0",
      sac_other_currency_xchg_rate: "",
      sac_other_ctc_in_local_currency: "",
    };
    var otherRows = this.state.multiRows[idx].other_charges;
    let data = [...otherRows, item];
    //this.state.purchaseTypeRows[idx].purchase_types = data;
    let newState = Object.assign({}, this.state);
    newState.multiRows[idx].other_charges = data;
    this.setState({
      newState,
    });
  };

  submitHandler = () => {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    var multiRows = this.state.multiRows;
    const costing_file = this.state.costing_files?.filter(
      (e) => e.file_content !== "" && e.column === "invoice_file"
    );

    for (var i of multiRows) {
      i.costing_files = costing_file;
      i["billing_currency"] =
        config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL" ? "USD" : "IDR";
      for (var j of i.mv_surveyor_charges) {
        if (
          j.mv_sac_charges_vendor_id === "" ||
          j.mv_sac_charges_vendor_id === null ||
          j.mv_sac_charges_vendor_id === "0"
        ) {
          j.vendor_error = true;
          this.setState({
            multiRows: multiRows,
          });
          alert("Please fill mandatory fields");
          return;
        }
      }
    }
    /**
     *  Checking Mandatory files
     */
    var missing_fields = 0;
    let files_missing = 0;
    var row = this.state.files;
    for (var obj of row) {
      if (obj.file_type !== "" || obj.file_content !== "") {
        if (obj.file_content !== "") {
          if (obj.file_description === "") {
            missing_fields++;
            obj.file_description_error = true;
          } else {
            obj.file_description = obj.file_description.toUpperCase();
          }
        } else if (obj.file_description !== "") {
          if (obj.file_content === "") {
            files_missing++;
          }
        }
      }
    }
    this.setState({
      files: row,
    });

    if (missing_fields > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please fill mandatory fields.",
      });
      return;
    }
    if (files_missing > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please Select file.",
      });
      return;
    }

    const content_files = row.filter((e) => e.file_content !== "");
    try {
      var businessNoID = this.props.businessNoID;
      //var businessNoID = decodeURIComponent(window.atob(this.props.match.params.businessNoID));
      api
        .add_mv_pur_fin_sac_charges(
          loginUserID,
          idToken,
          businessNoID,
          multiRows,
          content_files
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                this.setState(
                  {
                    snackBarSuccessOpen: true,
                    successMsg: res.message,
                  },
                  () => {
                    setTimeout(() => {
                      this.props.onAddResponse({
                        screen: "MvSac",
                        code: res.code,
                        message: "",
                      });
                    }, 2000);
                  }
                );
                //window.location.href = '/mv-purchase-financial-view-sac-charges/' + btoa(businessNoID)
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  snackBarErrorOpen: true,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    } catch (e) {
      //window.location.href = '/business-number-list';
    }
  };

  render() {
    const re = /^[0-9]*\.?[0-9]*$/;
    const quan = /^\d*(\.\d{0,3})?$/;
    const pr = /^\d*(\.\d{0,4})?$/;
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div
          className="container bg-white p-0"
          style={{ minHeight: "100vh", width: 850 }}
        >
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <div className="row clearfix content-header border-bottom">
            <div className="col-lg-12 pl-0">
              <h4 style={{ padding: "10px 20px" }}>
                Add Sampling & Analysis Charges
              </h4>
            </div>
          </div>
          <div className="drawer-section">
            <div className="card p-3">
              <div
                className="card-body p-0"
                style={{ backgroundColor: "rgba(25,118,210,.01)" }}
                id="accordion"
              >
                {this.state.multiRows.map((e, idx) => (
                  <div className="row" key={idx}>
                    <div
                      className={
                        this.state.multiRows.length > 1
                          ? "col-lg-11 border_dashed rounded-lg p-2 mb-3"
                          : "col-lg-12 border_dashed rounded-lg p-2 mb-3"
                      }
                    >
                      <div className="">
                        <div className="row mb-3">
                          <div className="row col-lg-12 p-0">
                            <h5>Surveyor Charges</h5>
                          </div>

                          {e.mv_surveyor_charges.map((s, index) => (
                            <div className="row" key={index}>
                              <div
                                className={
                                  e.mv_surveyor_charges.length > 1
                                    ? "col-lg-11 border p-2 mb-3"
                                    : "col-lg-12 border p-2 mb-3"
                                }
                              >
                                <div className="row">
                                  <div className="col-lg-6 pl-0">
                                    <div className="row">
                                      <div className="col-lg-12 mb-1 p-0">
                                        <label className="form_label mb-0">
                                          Surveyor Name{" "}
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </label>
                                        {index === 0 ? (
                                          <TextField
                                            name="payment_to"
                                            margin="dense"
                                            variant="outlined"
                                            value={s.mv_sac_charges_vendor_id}
                                            error={s.vendor_error}
                                            onChange={(e) => {
                                              var row = this.state.multiRows;
                                              let rows =
                                                row[idx].mv_surveyor_charges;
                                              rows[
                                                index
                                              ].mv_sac_charges_vendor_id =
                                                e.target.value;
                                              rows[index].vendor_error = false;
                                              var sac_surveyor_name = "";
                                              if (e.target.value !== "0") {
                                                const data =
                                                  this.state.vendorsData.filter(
                                                    (obj, idx) =>
                                                      obj.id === e.target.value
                                                  );
                                                if (data.length > 0) {
                                                  sac_surveyor_name =
                                                    data[0].vendor_name;
                                                }
                                              }

                                              this.setState({
                                                multiRows: row,
                                                sac_surveyor_name:
                                                  sac_surveyor_name,
                                                mv_sac_charges_vendor_id:
                                                  e.target.value,
                                              });
                                            }}
                                            select
                                            fullWidth
                                            InputProps={{
                                              style: {
                                                fontFamily: "Poppins",
                                                fontSize: "14px",
                                              },
                                            }}
                                          >
                                            {this.state.surveyors}
                                          </TextField>
                                        ) : (
                                          <div>
                                            {this.state.sac_surveyor_name && (
                                              <div className="contract_display_header_value">
                                                {this.state.sac_surveyor_name}
                                              </div>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                      <div className="col-lg-6 mb-1 p-0">
                                        <label className="form_label mb-0">
                                          Quantity in MT
                                        </label>
                                        <TextField
                                          name="sac_quantity_in_mt"
                                          margin="dense"
                                          variant="outlined"
                                          type="number"
                                          fullWidth
                                          InputProps={{
                                            style: {
                                              fontFamily: "Poppins",
                                              fontSize: "14px",
                                            },
                                          }}
                                          placeholder="Enter Value"
                                          value={s.sac_quantity_in_mt}
                                          onChange={(e) => {
                                            if (
                                              e.target.value === "" ||
                                              quan.test(e.target.value)
                                            ) {
                                              var row = this.state.multiRows;
                                              let rows =
                                                row[idx].mv_surveyor_charges;
                                              rows[index].sac_quantity_in_mt =
                                                e.target.value;
                                              this.setState(
                                                {
                                                  multiRows: row,
                                                },
                                                () => {
                                                  this.surveyorChargesCalculatons(
                                                    idx,
                                                    index
                                                  );
                                                }
                                              );
                                            }
                                          }}
                                        />
                                      </div>
                                      <div className="col-lg-6 mb-1 pr-0">
                                        <label className="form_label mb-0">
                                          Price Per MT
                                        </label>
                                        <TextField
                                          name="sac_price_per_mt"
                                          margin="dense"
                                          variant="outlined"
                                          type="number"
                                          fullWidth
                                          InputProps={{
                                            style: {
                                              fontFamily: "Poppins",
                                              fontSize: "14px",
                                            },
                                          }}
                                          placeholder="Enter Value"
                                          value={s.sac_price_per_mt}
                                          onChange={(e) => {
                                            if (
                                              e.target.value === "" ||
                                              pr.test(e.target.value)
                                            ) {
                                              var row = this.state.multiRows;
                                              let rows =
                                                row[idx].mv_surveyor_charges;
                                              rows[index].sac_price_per_mt =
                                                e.target.value;
                                              this.setState(
                                                {
                                                  multiRows: row,
                                                },
                                                () =>
                                                  this.surveyorChargesCalculatons(
                                                    idx,
                                                    index
                                                  )
                                              );
                                            }
                                          }}
                                        />
                                      </div>
                                      {(config.company === "SDAM" ||
                                        config.company === "SRK" ||
                                        config.company === "BTR") && (
                                        <div className="col-lg-6 mb-1 p-0">
                                          <label className="form_label mb-0">
                                            Currency
                                          </label>
                                          <TextField
                                            name="sac_surveyor_currency"
                                            margin="dense"
                                            variant="outlined"
                                            fullWidth
                                            InputProps={{
                                              style: {
                                                fontFamily: "Poppins",
                                                fontSize: "14px",
                                              },
                                            }}
                                            placeholder="Currency"
                                            //label="Currency"
                                            value={s.sac_surveyor_currency}
                                            select
                                            onChange={(e) => {
                                              var row = this.state.multiRows;
                                              let rows =
                                                row[idx].mv_surveyor_charges;
                                              rows[
                                                index
                                              ].sac_surveyor_currency =
                                                e.target.value;
                                              this.setState({
                                                multiRows: row,
                                              });
                                            }}
                                          >
                                            {this.state.currencyData}
                                          </TextField>
                                        </div>
                                      )}
                                      {(config.company === "SDAM" ||
                                        config.company === "SRK" ||
                                        config.company === "BTR") &&
                                        e.sac_surveyor_currency !== "IDR" &&
                                        e.sac_surveyor_currency !== "0" && (
                                          <div className="col-lg-6 mb-1 pr-0">
                                            <label className="form_label mb-0">
                                              Exchange Rate
                                            </label>
                                            <TextField
                                              name="sac_surveyor_currency_xchg_rate"
                                              margin="dense"
                                              variant="outlined"
                                              fullWidth
                                              InputProps={{
                                                style: {
                                                  fontFamily: "Poppins",
                                                  fontSize: "14px",
                                                },
                                              }}
                                              placeholder="Enter Value"
                                              value={
                                                s.sac_surveyor_currency_xchg_rate
                                              }
                                              onChange={(e) => {
                                                if (
                                                  e.target.value === "" ||
                                                  re.test(e.target.value)
                                                ) {
                                                  var row =
                                                    this.state.multiRows;
                                                  let rows =
                                                    row[idx]
                                                      .mv_surveyor_charges;
                                                  rows[
                                                    index
                                                  ].sac_surveyor_currency_xchg_rate =
                                                    e.target.value;
                                                  rows[
                                                    index
                                                  ].sac_surveyor_ctc_in_local_currency =
                                                    (
                                                      Number(
                                                        row[idx]
                                                          .sac_price_per_mt
                                                      ) * Number(e.target.value)
                                                    ).toFixed(2);
                                                  this.setState(
                                                    {
                                                      multiRows: row,
                                                    },
                                                    () =>
                                                      this.surveyorChargesCalculatons(
                                                        idx,
                                                        index
                                                      )
                                                  );
                                                }
                                              }}
                                            />
                                          </div>
                                        )}
                                      {(config.company === "SDAM" ||
                                        config.company === "SRK" ||
                                        config.company === "BTR") &&
                                        s.sac_surveyor_currency !== "IDR" &&
                                        s.sac_surveyor_currency !== "0" && (
                                          <div className="col-lg-6 mb-1 p-0">
                                            <label className="form_label mb-0">
                                              Price in IDR
                                            </label>
                                            <div className="">
                                              {s.sac_surveyor_ctc_in_local_currency
                                                ? toLocaleString(
                                                    s.sac_surveyor_ctc_in_local_currency
                                                  ) + " IDR"
                                                : "-"}
                                            </div>
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                  <div
                                    className="col-lg-6 p-2 rounded"
                                    style={{ backgroundColor: "#f7f3f0" }}
                                  >
                                    <div className="row col-lg-12 p-0">
                                      <h6>Surveyor Charges</h6>
                                    </div>
                                    <div className="row">
                                      <div className="col-4 p-0 mb-2">
                                        <label
                                          className="form_label mb-0"
                                          style={{
                                            color: "rgba(72, 59, 52, 0.7)",
                                          }}
                                        >
                                          Base price
                                        </label>
                                        <div
                                          className="contract_display_header_value"
                                          style={{ color: "#42352d" }}
                                        >
                                          {s.sac_surveyor_base_price
                                            ? toLocaleString(
                                                s.sac_surveyor_base_price
                                              )
                                            : "-"}
                                        </div>
                                      </div>
                                      <div className="col-4 pr-0 mb-2">
                                        <label
                                          className="form_label mb-0"
                                          style={{
                                            color: "rgba(72, 59, 52, 0.7)",
                                          }}
                                        >
                                          VAT %
                                        </label>
                                        {/* <div className='contract_display_header_value' style={{ color: '#42352d' }}>{e.dc_vat_percent ? toLocaleString(e.dc_vat_percent) : "-"}</div> */}
                                        <TextField
                                          name="sac_surveyor_vat_percent"
                                          margin="dense"
                                          //variant='outlined'
                                          fullWidth
                                          value={s.sac_surveyor_vat_percent}
                                          onChange={(e) => {
                                            if (
                                              e.target.value === "" ||
                                              pr.test(e.target.value)
                                            ) {
                                              var row = this.state.multiRows;
                                              let rows =
                                                row[idx].mv_surveyor_charges;
                                              let sac_surveyor_vat_value = (
                                                (Number(
                                                  rows[index]
                                                    .sac_surveyor_base_price
                                                ) *
                                                  Number(e.target.value)) /
                                                100
                                              ).toFixed(2);

                                              let sac_surveyor_pph23_value = (
                                                (Number(
                                                  rows[index]
                                                    .sac_surveyor_base_price
                                                ) *
                                                  Number(
                                                    rows[index]
                                                      .sac_surveyor_pph23_percent
                                                  )) /
                                                100
                                              ).toFixed(2);
                                              if (
                                                config.company === "SRPL" || config.company === "AVS"  ||
                                                config.company === "PRPL"
                                              ) {
                                                sac_surveyor_pph23_value = "";
                                              }
                                              let sac_surveyor_total = (
                                                Number(
                                                  rows[index]
                                                    .sac_surveyor_base_price
                                                ) +
                                                Number(sac_surveyor_vat_value) -
                                                Number(sac_surveyor_pph23_value)
                                              ).toFixed(2);
                                              let sac_surveyor_ctc = (
                                                Number(
                                                  rows[index]
                                                    .sac_surveyor_base_price
                                                ) +
                                                Number(sac_surveyor_vat_value)
                                              ).toFixed(2);

                                              rows[
                                                index
                                              ].sac_surveyor_vat_percent =
                                                e.target.value;
                                              rows[
                                                index
                                              ].sac_surveyor_vat_value = sac_surveyor_vat_value;
                                              rows[index].sac_surveyor_total =
                                                sac_surveyor_total;
                                              rows[index].sac_surveyor_ctc =
                                                sac_surveyor_ctc;
                                              this.setState({
                                                multiRows: row,
                                              });
                                            }
                                          }}
                                        />
                                      </div>
                                      <div className="col-4 pr-0 mb-2">
                                        <label
                                          className="form_label mb-0"
                                          style={{
                                            color: "rgba(72, 59, 52, 0.7)",
                                          }}
                                        >
                                          VAT
                                        </label>
                                        {/* <div className='contract_display_header_value' style={{ color: '#42352d' }}>{e.dc_vat_value ? toLocaleString(e.dc_vat_value) : "-"}</div> */}
                                        <TextField
                                          name="sac_surveyor_vat_value"
                                          margin="dense"
                                          //variant='outlined'
                                          fullWidth
                                          value={s.sac_surveyor_vat_value}
                                          onChange={(e) => {
                                            if (
                                              e.target.value === "" ||
                                              pr.test(e.target.value)
                                            ) {
                                              var row = this.state.multiRows;
                                              let rows =
                                                row[idx].mv_surveyor_charges;
                                              let sac_surveyor_pph23_value = (
                                                (Number(
                                                  rows[index]
                                                    .sac_surveyor_base_price
                                                ) *
                                                  Number(
                                                    rows[index]
                                                      .sac_surveyor_pph23_percent
                                                  )) /
                                                100
                                              ).toFixed(0);
                                              if (
                                                config.company === "SRPL" || config.company === "AVS"  ||
                                                config.company === "PRPL"
                                              ) {
                                                sac_surveyor_pph23_value = "";
                                              }
                                              let sac_surveyor_total = (
                                                Number(
                                                  rows[index]
                                                    .sac_surveyor_base_price
                                                ) +
                                                Number(e.target.value) -
                                                Number(sac_surveyor_pph23_value)
                                              ).toFixed(2);
                                              let sac_surveyor_ctc = (
                                                Number(
                                                  rows[index]
                                                    .sac_surveyor_base_price
                                                ) + Number(e.target.value)
                                              ).toFixed(2);

                                              rows[
                                                index
                                              ].sac_surveyor_vat_value =
                                                e.target.value;
                                              rows[index].sac_surveyor_total =
                                                sac_surveyor_total;
                                              rows[index].sac_surveyor_ctc =
                                                sac_surveyor_ctc;
                                              this.setState({
                                                multiRows: row,
                                              });
                                            }
                                          }}
                                        />
                                      </div>
                                      {(config.company === "SDAM" ||
                                        config.company === "SRK" ||
                                        config.company === "BTR") && (
                                        <div className="col-lg-6 p-0 mb-3">
                                          <label
                                            className="form_label mb-0"
                                            style={{
                                              color: "rgba(72, 59, 52, 0.7)",
                                            }}
                                          >
                                            Includes PPH23 @ 2%
                                          </label>
                                          <div
                                            className="contract_display_header_value"
                                            style={{ color: "#42352d" }}
                                          >
                                            {s.sac_surveyor_pph23_value
                                              ? toLocaleString(
                                                  s.sac_surveyor_pph23_value
                                                )
                                              : "-"}
                                          </div>
                                        </div>
                                      )}
                                    </div>

                                    <div className="row">
                                      <div
                                        className={
                                          config.company === "SRPL" || config.company === "AVS"  ||
                                          config.company === "PRPL"
                                            ? "col-lg-6 p-0"
                                            : "col-lg-6 p-0"
                                        }
                                      >
                                        <label
                                          className="form_label mb-0"
                                          style={{
                                            color: "rgba(72, 59, 52, 0.7)",
                                          }}
                                        >
                                          Payable to Vendor
                                        </label>
                                        <div
                                          className="contract_display_header_value"
                                          style={{ color: "#42352d" }}
                                        >
                                          {s.sac_surveyor_total
                                            ? toLocaleString(
                                                s.sac_surveyor_total
                                              )
                                            : 0}{" "}
                                          {config.company === "SRPL" || config.company === "AVS"  ||
                                          config.company === "PRPL"
                                            ? "USD"
                                            : "IDR"}
                                        </div>
                                      </div>
                                      <div
                                        className={
                                          config.company === "SRPL" || config.company === "AVS"  ||
                                          config.company === "PRPL"
                                            ? "col-lg-6 pr-0"
                                            : "col-lg-6 pr-0"
                                        }
                                      >
                                        <label
                                          className="form_label mb-0"
                                          style={{
                                            color: "rgba(72, 59, 52, 0.7)",
                                          }}
                                        >
                                          Cost to Company
                                        </label>
                                        <div
                                          className="contract_display_header_value"
                                          style={{ color: "#42352d" }}
                                        >
                                          {s.sac_surveyor_ctc
                                            ? toLocaleString(s.sac_surveyor_ctc)
                                            : "-"}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {e.mv_surveyor_charges.length > 1 && (
                                <center className="col-lg-1 d-flex justify-content-center">
                                  <i
                                    className="fa fa-trash-o"
                                    onClick={() => {
                                      const row =
                                        this.state.multiRows[idx]
                                          .mv_surveyor_charges;
                                      row.splice(index, 1);
                                      this.setState({
                                        multiRows: this.state.multiRows,
                                      });
                                    }}
                                    style={{
                                      paddingLeft: 10,
                                      fontSize: 20,
                                      color: config.themeColor,
                                      cursor: "pointer",
                                    }}
                                  />
                                </center>
                              )}
                            </div>
                          ))}
                          <div className="row">
                            <button
                              type="button"
                              onClick={() => {
                                const item = {
                                  mv_sac_charges_vendor_id:
                                    this.state.mv_sac_charges_vendor_id,
                                  sac_quantity_in_mt: "",
                                  sac_price_per_mt: "",
                                  sac_surveyor_base_price: "",
                                  sac_surveyor_vat_percent: "10",
                                  sac_surveyor_vat_value: "",
                                  sac_surveyor_pph23_percent: "2",
                                  sac_surveyor_pph23_value: "",
                                  sac_surveyor_total: "",
                                  sac_surveyor_ctc: "",
                                  sac_surveyor_currency:
                                    config.company === "SRPL" || config.company === "AVS"  ||
                                    config.company === "PRPL"
                                      ? "USD"
                                      : "0",
                                  sac_surveyor_currency_xchg_rate: "",
                                  sac_surveyor_ctc_in_local_currency: "",
                                };
                                var surveyor_charges =
                                  this.state.multiRows[idx].mv_surveyor_charges;
                                let data = [...surveyor_charges, item];
                                //this.state.purchaseTypeRows[idx].purchase_types = data;
                                let newState = Object.assign({}, this.state);
                                newState.multiRows[idx].mv_surveyor_charges =
                                  data;
                                this.setState({
                                  newState,
                                });
                              }}
                              className="header_button header_button_text addrow_button_adjustment w-auto"
                              style={{
                                border: "none",
                                color: config.themeColor,
                              }}
                            >
                              ADD ROW
                            </button>
                          </div>
                        </div>
                      </div>

                      <hr />

                      <div className="">
                        <div className="row mb-3">
                          <div className="row col-lg-12 p-0">
                            <h5>Others</h5>
                          </div>

                          {e.other_charges.map((p, index) => (
                            <div className="row">
                              <div
                                className={
                                  e.other_charges.length > 1
                                    ? "col-lg-11 border p-2 mb-3"
                                    : "col-lg-12 border p-2 mb-3"
                                }
                              >
                                <div className="row">
                                  <div className="col-lg-6 pl-0">
                                    <div className="row">
                                      <div className="col-lg-12 mb-1 p-0">
                                        <label className="form_label mb-0">
                                          Base Price{" "}
                                          {config.company === "SRPL" || config.company === "AVS"  ||
                                          config.company === "PRPL"
                                            ? "(USD)"
                                            : "(IDR)"}
                                        </label>
                                        <TextField
                                          name="sac_other_base_price"
                                          margin="dense"
                                          variant="outlined"
                                          //fullWidth
                                          value={p.sac_other_base_price}
                                          onChange={(e) => {
                                            if (
                                              e.target.value === "" ||
                                              pr.test(e.target.value)
                                            ) {
                                              var value = e.target.value;
                                              if (value === "") {
                                                value = "1";
                                              }
                                              var rows = this.state.multiRows;
                                              var row = rows[idx].other_charges;
                                              let sac_other_vat_value = (
                                                (Number(value) * 10) /
                                                100
                                              ).toFixed(0);
                                              let sac_other_pph23_value = (
                                                (Number(value) *
                                                  Number(
                                                    row[index]
                                                      .sac_other_pph23_percent
                                                  )) /
                                                100
                                              ).toFixed(2);
                                              if (
                                                config.company === "SRPL" || config.company === "AVS"  ||
                                                config.company === "PRPL"
                                              ) {
                                                sac_other_pph23_value = "";
                                              }
                                              let sac_other_total = (
                                                Number(value) +
                                                Number(sac_other_vat_value) -
                                                Number(sac_other_pph23_value)
                                              ).toFixed(2);
                                              let sac_other_ctc = (
                                                Number(value) +
                                                Number(sac_other_vat_value)
                                              ).toFixed(2);

                                              row[index].sac_other_base_price =
                                                e.target.value;
                                              row[index].sac_other_vat_value =
                                                sac_other_vat_value;
                                              row[index].sac_other_pph23_value =
                                                sac_other_pph23_value;
                                              row[index].sac_other_total =
                                                sac_other_total;
                                              row[index].sac_other_ctc =
                                                sac_other_ctc;
                                              this.setState({
                                                multiRows: rows,
                                              });
                                            }
                                          }}
                                          className="rounded-left"
                                          style={{
                                            borderTopRightRadius: 0,
                                            borderBottomRightRadius: 0,
                                          }}
                                          fullWidth
                                          InputProps={{
                                            endAdornment: (config.company ===
                                              "SDAM" ||
                                              config.company === "SRK" ||
                                              config.company === "BTR") && (
                                              <InputAdornment
                                                // variant="outlined"
                                                position="end"
                                                style={{
                                                  marginRight: "-14px",
                                                  marginTop: "-4px",
                                                  width: "50%",
                                                }}
                                              >
                                                <TextField
                                                  name="sac_other_currency"
                                                  margin="dense"
                                                  variant="outlined"
                                                  fullWidth
                                                  value={p.sac_other_currency}
                                                  select
                                                  InputProps={{
                                                    style: {
                                                      fontFamily: "Poppins",
                                                      fontSize: "14px",
                                                    },
                                                  }}
                                                  onChange={(e) => {
                                                    var rows =
                                                      this.state.multiRows;
                                                    var row =
                                                      rows[idx].other_charges;
                                                    row[
                                                      index
                                                    ].sac_other_currency =
                                                      e.target.value;
                                                    this.setState({
                                                      multiRows: rows,
                                                    });
                                                  }}
                                                >
                                                  {this.state.currencyData}
                                                </TextField>
                                              </InputAdornment>
                                            ),
                                            style: {
                                              fontFamily: "Poppins",
                                              fontSize: "14px",
                                            },
                                          }}
                                        />
                                      </div>
                                      {(config.company === "SDAM" ||
                                        config.company === "SRK" ||
                                        config.company === "BTR") &&
                                        p.sac_other_currency !== "IDR" &&
                                        p.sac_other_currency !== "0" && (
                                          <div className="col-lg-12 mb-1 p-0">
                                            <label className="form_label mb-0">
                                              Exchange Rate
                                            </label>
                                            <TextField
                                              name="sac_other_currency_xchg_rate"
                                              margin="dense"
                                              variant="outlined"
                                              fullWidth
                                              InputProps={{
                                                style: {
                                                  fontFamily: "Poppins",
                                                  fontSize: "14px",
                                                },
                                              }}
                                              placeholder="Enter Value"
                                              value={
                                                p.sac_other_currency_xchg_rate
                                              }
                                              onChange={(e) => {
                                                if (
                                                  e.target.value === "" ||
                                                  re.test(e.target.value)
                                                ) {
                                                  var value = e.target.value;
                                                  if (value === "") {
                                                    value = 1;
                                                  }
                                                  var rows =
                                                    this.state.multiRows;
                                                  var row =
                                                    rows[idx].other_charges;
                                                  row[
                                                    index
                                                  ].sac_other_currency_xchg_rate =
                                                    e.target.value;
                                                  row[
                                                    index
                                                  ].sac_other_ctc_in_local_currency =
                                                    (
                                                      Number(value) *
                                                      Number(
                                                        row[index].sac_other_ctc
                                                      )
                                                    ).toFixed(2);
                                                  this.setState({
                                                    multiRows: rows,
                                                  });
                                                }
                                              }}
                                            />
                                          </div>
                                        )}
                                      <div className="col-lg-6 mb-1 p-0">
                                        <label className="form_label mb-0">
                                          VAT %
                                        </label>
                                        <TextField
                                          name="sac_other_vat_percent"
                                          margin="dense"
                                          variant="outlined"
                                          InputProps={{
                                            style: {
                                              fontFamily: "Poppins",
                                              fontSize: "14px",
                                            },
                                          }}
                                          //fullWidth
                                          value={p.sac_other_vat_percent}
                                          onChange={(e) => {
                                            if (
                                              e.target.value === "" ||
                                              pr.test(e.target.value)
                                            ) {
                                              var rows = this.state.multiRows;
                                              var row = rows[idx].other_charges;
                                              let sac_other_vat_value = (
                                                (Number(
                                                  row[index]
                                                    .sac_other_base_price
                                                ) *
                                                  Number(e.target.value)) /
                                                100
                                              ).toFixed(2);
                                              let sac_other_pph23_value = (
                                                (Number(
                                                  row[index]
                                                    .sac_other_base_price
                                                ) *
                                                  Number(
                                                    row[index]
                                                      .sac_other_pph23_percent
                                                  )) /
                                                100
                                              ).toFixed(2);
                                              if (
                                                config.company === "SRPL" || config.company === "AVS"  ||
                                                config.company === "PRPL"
                                              ) {
                                                sac_other_pph23_value = "";
                                              }
                                              let sac_other_total = (
                                                Number(
                                                  row[index]
                                                    .sac_other_base_price
                                                ) +
                                                Number(sac_other_vat_value) -
                                                Number(sac_other_pph23_value)
                                              ).toFixed(2);
                                              let sac_other_ctc = (
                                                Number(
                                                  row[index]
                                                    .sac_other_base_price
                                                ) + Number(sac_other_vat_value)
                                              ).toFixed(2);
                                              row[index].sac_other_vat_percent =
                                                e.target.value;
                                              row[index].sac_other_vat_value =
                                                sac_other_vat_value;
                                              row[index].sac_other_total =
                                                sac_other_total;
                                              row[index].sac_other_ctc =
                                                sac_other_ctc;

                                              this.setState({
                                                multiRows: rows,
                                              });
                                            }
                                          }}
                                        />
                                      </div>
                                      <div className="col-lg-6 mb-1 pr-0">
                                        <label className="form_label mb-0">
                                          VAT
                                        </label>
                                        <TextField
                                          name="sac_other_vat_value"
                                          margin="dense"
                                          variant="outlined"
                                          InputProps={{
                                            style: {
                                              fontFamily: "Poppins",
                                              fontSize: "14px",
                                            },
                                          }}
                                          //fullWidth
                                          value={p.sac_other_vat_value}
                                          onChange={(e) => {
                                            if (
                                              e.target.value === "" ||
                                              pr.test(e.target.value)
                                            ) {
                                              var rows = this.state.multiRows;
                                              var row = rows[idx].other_charges;
                                              //let sac_other_vat_value = ((Number(value)*Number(this.state.sac_other_vat_percent))/100).toFixed(2);
                                              let sac_other_pph23_value = (
                                                (Number(
                                                  row[index]
                                                    .sac_other_base_price
                                                ) *
                                                  Number(
                                                    row[index]
                                                      .sac_other_pph23_percent
                                                  )) /
                                                100
                                              ).toFixed(2);
                                              if (
                                                config.company === "SRPL" || config.company === "AVS"  ||
                                                config.company === "PRPL"
                                              ) {
                                                sac_other_pph23_value = "";
                                              }
                                              let sac_other_total = (
                                                Number(
                                                  row[index]
                                                    .sac_other_base_price
                                                ) +
                                                Number(e.target.value) -
                                                Number(sac_other_pph23_value)
                                              ).toFixed(2);
                                              let sac_other_ctc = (
                                                Number(
                                                  row[index]
                                                    .sac_other_base_price
                                                ) + Number(e.target.value)
                                              ).toFixed(2);

                                              row[index].sac_other_vat_value =
                                                e.target.value;
                                              row[index].sac_other_total =
                                                sac_other_total;
                                              row[index].sac_other_ctc =
                                                sac_other_ctc;

                                              this.setState({
                                                multiRows: rows,
                                              });
                                            }
                                          }}
                                        />
                                      </div>

                                      <div className="col-lg-12 pl-0">
                                        <label className="form_label mb-0">
                                          Description
                                        </label>
                                        <TextField
                                          name="sac_other_description"
                                          margin="dense"
                                          variant="outlined"
                                          fullWidth
                                          InputProps={{
                                            style: {
                                              fontFamily: "Poppins",
                                              fontSize: "14px",
                                            },
                                          }}
                                          value={p.sac_other_description}
                                          inputProps={{
                                            style: {
                                              textTransform: "uppercase",
                                            },
                                          }}
                                          onChange={(e) => {
                                            var rows = this.state.multiRows;
                                            var row = rows[idx].other_charges;
                                            row[index].sac_other_description =
                                              e.target.value;
                                            this.setState({
                                              multiRows: rows,
                                            });
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="col-lg-6 p-2 rounded"
                                    style={{ backgroundColor: "#f7f3f0" }}
                                  >
                                    {/* <div className='row col-lg-12 p-0'>
                              <h6>OtherCharges</h6>
                            </div> */}
                                    <div className="row">
                                      {(config.company === "SDAM" ||
                                        config.company === "SRK" ||
                                        config.company === "BTR") && (
                                        <div className="col-lg-12 mb-3">
                                          <label
                                            className="form_label mb-0"
                                            style={{
                                              color: "rgba(72, 59, 52, 0.7)",
                                            }}
                                          >
                                            Includes PPH23 @ 2%
                                          </label>
                                          <div
                                            className="contract_display_header_value"
                                            style={{ color: "#42352d" }}
                                          >
                                            {p.sac_other_pph23_value
                                              ? toLocaleString(
                                                  p.sac_other_pph23_value
                                                )
                                              : "-"}
                                          </div>
                                        </div>
                                      )}

                                      <div className="col-lg-12 mb-3">
                                        <label
                                          className="form_label mb-0"
                                          style={{
                                            color: "rgba(72, 59, 52, 0.7)",
                                          }}
                                        >
                                          Payable to Vendor
                                        </label>
                                        <div
                                          className="contract_display_header_value"
                                          style={{ color: "#42352d" }}
                                        >
                                          {p.sac_other_total
                                            ? toLocaleString(p.sac_other_total)
                                            : 0}{" "}
                                          {config.company === "SRPL" || config.company === "AVS"  ||
                                          config.company === "PRPL"
                                            ? "USD"
                                            : "IDR"}
                                        </div>
                                      </div>
                                      <div className="col-lg-12 mb-3">
                                        <label
                                          className="form_label mb-0"
                                          style={{
                                            color: "rgba(72, 59, 52, 0.7)",
                                          }}
                                        >
                                          Cost to Company
                                        </label>
                                        <div
                                          className="contract_display_header_value"
                                          style={{ color: "#42352d" }}
                                        >
                                          {p.sac_other_ctc
                                            ? toLocaleString(p.sac_other_ctc)
                                            : 0}{" "}
                                          {config.company === "SRPL" || config.company === "AVS"  ||
                                          config.company === "PRPL"
                                            ? "USD"
                                            : "IDR"}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {e.other_charges.length > 1 && (
                                <center className="col-lg-1 d-flex justify-content-center">
                                  <i
                                    className="fa fa-trash-o"
                                    onClick={this.removeOtherChargesRowsHandler(
                                      idx,
                                      index
                                    )}
                                    style={{
                                      paddingLeft: 10,
                                      fontSize: 20,
                                      color: config.themeColor,
                                      cursor: "pointer",
                                    }}
                                  />
                                </center>
                              )}
                            </div>
                          ))}
                          <div className="row">
                            <button
                              type="button"
                              onClick={() =>
                                this.addMoreOtherChargesRowsHandler(idx)
                              }
                              className="header_button header_button_text addrow_button_adjustment w-auto"
                              style={{
                                border: "none",
                                color: config.themeColor,
                              }}
                            >
                              ADD ROW
                            </button>
                          </div>
                        </div>
                      </div>

                      <hr />

                      <div className="row mb-3 ">
                        <div className="row col-lg-12 p-0">
                          <h5>Additional Charges</h5>
                        </div>
                        <div className="row">
                          <div className="col-lg-6 pl-0">
                            <div className="row">
                              <div className="col-lg-6 mb-1 p-0">
                                <label className="form_label mb-0">
                                  Extra Days
                                </label>
                                <TextField
                                  name="sac_surveyor_addl_days"
                                  margin="dense"
                                  variant="outlined"
                                  fullWidth
                                  InputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                    },
                                  }}
                                  placeholder="Enter value"
                                  value={e.sac_surveyor_addl_days}
                                  onChange={(e) => {
                                    if (
                                      e.target.value === "" ||
                                      re.test(e.target.value)
                                    ) {
                                      var row = this.state.multiRows;
                                      row[idx].sac_surveyor_addl_days =
                                        e.target.value;
                                      this.setState(
                                        {
                                          multiRows: row,
                                        },
                                        () =>
                                          this.surveyorAddDaysPriceCalculations(
                                            idx
                                          )
                                      );
                                    }
                                  }}
                                />
                              </div>

                              <div className="col-lg-6 mb-1 pr-0">
                                <label className="form_label mb-0">
                                  Price Per Day
                                </label>
                                <TextField
                                  name="sac_surveyor_price_per_addl_day"
                                  margin="dense"
                                  variant="outlined"
                                  fullWidth
                                  InputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                    },
                                  }}
                                  placeholder="Enter Value"
                                  value={e.sac_surveyor_price_per_addl_day}
                                  onChange={(e) => {
                                    if (
                                      e.target.value === "" ||
                                      pr.test(e.target.value)
                                    ) {
                                      var row = this.state.multiRows;
                                      row[idx].sac_surveyor_price_per_addl_day =
                                        e.target.value;

                                      this.setState(
                                        {
                                          multiRows: row,
                                        },
                                        () =>
                                          this.surveyorAddDaysPriceCalculations(
                                            idx
                                          )
                                      );
                                    }
                                  }}
                                />
                              </div>
                              {(config.company === "SDAM" ||
                                config.company === "SRK" ||
                                config.company === "BTR") && (
                                <div className="col-lg-6 mb-1 p-0">
                                  <label className="form_label mb-0">
                                    Currency
                                  </label>
                                  <TextField
                                    name="sac_surveyor_addl_days_currency"
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth
                                    placeholder="Currency"
                                    //label="Currency"
                                    value={e.sac_surveyor_addl_days_currency}
                                    select
                                    onChange={(e) => {
                                      var row = this.state.multiRows;
                                      row[idx].sac_surveyor_addl_days_currency =
                                        e.target.value;
                                      this.setState({
                                        multiRows: row,
                                      });
                                    }}
                                  >
                                    {this.state.currencyData}
                                  </TextField>
                                </div>
                              )}
                              {(config.company === "SDAM" ||
                                config.company === "SRK" ||
                                config.company === "BTR") &&
                                e.sac_surveyor_addl_days_currency !== "IDR" &&
                                e.sac_surveyor_addl_days_currency !== "0" && (
                                  <div className="col-lg-6 mb-1 pr-0">
                                    <label className="form_label mb-0">
                                      Exchange Rate
                                    </label>
                                    <TextField
                                      name="sac_surveyor_addl_days_currency_xchg_rate"
                                      margin="dense"
                                      variant="outlined"
                                      fullWidth
                                      InputProps={{
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                        },
                                      }}
                                      placeholder="Enter Value"
                                      value={
                                        e.sac_surveyor_addl_days_currency_xchg_rate
                                      }
                                      onChange={(e) => {
                                        if (
                                          e.target.value === "" ||
                                          re.test(e.target.value)
                                        ) {
                                          var row = this.state.multiRows;
                                          row[
                                            idx
                                          ].sac_surveyor_addl_days_currency_xchg_rate =
                                            e.target.value;

                                          this.setState(
                                            {
                                              multiRows: row,
                                            },
                                            () =>
                                              this.surveyorAddDaysPriceCalculations(
                                                idx
                                              )
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                )}
                              {(config.company === "SDAM" ||
                                config.company === "SRK" ||
                                config.company === "BTR") &&
                                e.sac_surveyor_addl_days_currency !== "IDR" &&
                                e.sac_surveyor_addl_days_currency !== "0" && (
                                  <div className="col-lg-6 mb-1 p-0">
                                    <label className="form_label mb-0">
                                      Price in IDR
                                    </label>
                                    <div className="">
                                      {e.sac_surveyor_addl_days_ctc_in_local_currency
                                        ? toLocaleString(
                                            e.sac_surveyor_addl_days_ctc_in_local_currency
                                          ) + " IDR"
                                        : "-"}
                                    </div>
                                  </div>
                                )}
                            </div>
                          </div>

                          <div
                            className="col-lg-6 p-2 rounded"
                            style={{ backgroundColor: "#f7f3f0" }}
                          >
                            <div className="row col-lg-12 p-0">
                              <h6>Additional Charges</h6>
                            </div>
                            <div className="row">
                              <div className="col-lg-4 p-0 mb-2">
                                <label
                                  className="form_label mb-2"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  Base price
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {e.sac_surveyor_addl_days_base_price
                                    ? toLocaleString(
                                        e.sac_surveyor_addl_days_base_price
                                      )
                                    : "-"}
                                </div>
                              </div>
                              <div className="col-lg-4 pr-0 mb-2">
                                <label
                                  className="form_label mb-0"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  VAT %
                                </label>
                                {/* <div className='contract_display_header_value' style={{ color: '#42352d' }}>{e.dc_laprn_surv_vat_percent ? toLocaleString(e.dc_laprn_surv_vat_percent) : "-"}</div> */}
                                <TextField
                                  name="sac_surveyor_addl_days_vat_percent"
                                  margin="dense"
                                  // variant='outlined'
                                  fullWidth
                                  value={e.sac_surveyor_addl_days_vat_percent}
                                  onChange={(e) => {
                                    if (
                                      e.target.value === "" ||
                                      pr.test(e.target.value)
                                    ) {
                                      var row = this.state.multiRows;
                                      let sac_surveyor_addl_days_vat_value = (
                                        (Number(
                                          row[idx]
                                            .sac_surveyor_addl_days_base_price
                                        ) *
                                          Number(e.target.value)) /
                                        100
                                      ).toFixed(2);
                                      let sac_surveyor_addl_days_pph23_value = (
                                        (Number(
                                          row[idx]
                                            .sac_surveyor_addl_days_base_price
                                        ) *
                                          Number(
                                            row[idx]
                                              .sac_surveyor_addl_days_pph23_percent
                                          )) /
                                        100
                                      ).toFixed(2);
                                      if (
                                        config.company === "SRPL" || config.company === "AVS"  ||
                                        config.company === "PRPL"
                                      ) {
                                        sac_surveyor_addl_days_pph23_value = "";
                                      }
                                      let sac_surveyor_addl_days_total = (
                                        Number(
                                          row[idx]
                                            .sac_surveyor_addl_days_base_price
                                        ) +
                                        Number(
                                          sac_surveyor_addl_days_vat_value
                                        ) -
                                        Number(
                                          sac_surveyor_addl_days_pph23_value
                                        )
                                      ).toFixed(2);
                                      let sac_surveyor_addl_days_ctc = (
                                        Number(
                                          row[idx]
                                            .sac_surveyor_addl_days_base_price
                                        ) +
                                        Number(sac_surveyor_addl_days_vat_value)
                                      ).toFixed(2);

                                      row[
                                        idx
                                      ].sac_surveyor_addl_days_vat_percent =
                                        e.target.value;
                                      row[
                                        idx
                                      ].sac_surveyor_addl_days_vat_value = sac_surveyor_addl_days_vat_value;
                                      row[
                                        idx
                                      ].sac_surveyor_addl_days_pph23_value = sac_surveyor_addl_days_pph23_value;
                                      row[idx].sac_surveyor_addl_days_total =
                                        sac_surveyor_addl_days_total;
                                      row[idx].sac_surveyor_addl_days_ctc =
                                        sac_surveyor_addl_days_ctc;
                                      this.setState({
                                        multiRows: row,
                                      });
                                    }
                                  }}
                                />
                              </div>
                              <div className="col-lg-4 pr-0 mb-2">
                                <label
                                  className="form_label mb-0"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  VAT
                                </label>
                                {/* <div className='contract_display_header_value' style={{ color: '#42352d' }}>{e.dc_laprn_surv_vat_value ? toLocaleString(e.dc_laprn_surv_vat_value) : "-"}</div> */}
                                <TextField
                                  name="sac_surveyor_addl_days_vat_value"
                                  margin="dense"
                                  //variant='outlined'
                                  fullWidth
                                  value={e.sac_surveyor_addl_days_vat_value}
                                  onChange={(e) => {
                                    if (
                                      e.target.value === "" ||
                                      pr.test(e.target.value)
                                    ) {
                                      var row = this.state.multiRows;
                                      //let sac_surveyor_addl_days_vat_value = ((Number(sac_surveyor_addl_days_base_price)*Number(this.state.sac_surveyor_addl_days_vat_percent))/100).toFixed(2);
                                      let sac_surveyor_addl_days_pph23_value = (
                                        (Number(
                                          row[idx]
                                            .sac_surveyor_addl_days_base_price
                                        ) *
                                          Number(
                                            row[idx]
                                              .sac_surveyor_addl_days_pph23_percent
                                          )) /
                                        100
                                      ).toFixed(2);
                                      if (
                                        config.company === "SRPL" || config.company === "AVS"  ||
                                        config.company === "PRPL"
                                      ) {
                                        sac_surveyor_addl_days_pph23_value = "";
                                      }
                                      let sac_surveyor_addl_days_total = (
                                        Number(
                                          row[idx]
                                            .sac_surveyor_addl_days_base_price
                                        ) +
                                        Number(e.target.value) -
                                        Number(
                                          sac_surveyor_addl_days_pph23_value
                                        )
                                      ).toFixed(2);
                                      let sac_surveyor_addl_days_ctc = (
                                        Number(
                                          row[idx]
                                            .sac_surveyor_addl_days_base_price
                                        ) + Number(e.target.value)
                                      ).toFixed(0);

                                      row[
                                        idx
                                      ].sac_surveyor_addl_days_vat_value =
                                        e.target.value;
                                      row[
                                        idx
                                      ].sac_surveyor_addl_days_pph23_value = sac_surveyor_addl_days_pph23_value;
                                      row[idx].sac_surveyor_addl_days_total =
                                        sac_surveyor_addl_days_total;
                                      row[idx].sac_surveyor_addl_days_ctc =
                                        sac_surveyor_addl_days_ctc;
                                      this.setState({
                                        multiRows: row,
                                      });
                                    }
                                  }}
                                />
                              </div>
                              {(config.company === "SDAM" ||
                                config.company === "SRK" ||
                                config.company === "BTR") && (
                                <div className="col-lg-6 p-0 mb-3">
                                  <label
                                    className="form_label mb-2"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    Includes PPH23 @ 2%
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {e.sac_surveyor_addl_days_pph23_value
                                      ? toLocaleString(
                                          e.sac_surveyor_addl_days_pph23_value
                                        )
                                      : "-"}
                                  </div>
                                </div>
                              )}
                            </div>

                            <div className="row">
                              <div
                                className={
                                  config.company === "SRPL" || config.company === "AVS"  ||
                                  config.company === "PRPL"
                                    ? "col-lg-6 p-0"
                                    : "col-lg-6 p-0"
                                }
                              >
                                <label
                                  className="form_label mb-2"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  Payable to Vendor
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {e.sac_surveyor_addl_days_total
                                    ? toLocaleString(
                                        e.sac_surveyor_addl_days_total
                                      )
                                    : 0}{" "}
                                  {config.company === "SRPL" || config.company === "AVS"  ||
                                  config.company === "PRPL"
                                    ? "USD"
                                    : "IDR"}
                                </div>
                              </div>
                              <div
                                className={
                                  config.company === "SRPL" || config.company === "AVS"  ||
                                  config.company === "PRPL"
                                    ? "col-lg-6 pr-0"
                                    : "col-lg-6 pr-0"
                                }
                              >
                                <label
                                  className="form_label mb-2"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  Cost to Company
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {e.sac_surveyor_addl_days_ctc
                                    ? toLocaleString(
                                        e.sac_surveyor_addl_days_ctc
                                      )
                                    : 0}{" "}
                                  {config.company === "SRPL" || config.company === "AVS"  ||
                                  config.company === "PRPL"
                                    ? "USD"
                                    : "IDR"}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                {/* {(this.state.features.includes('520')  || this.state.features.includes('97') ) && */}

                <FileUpload
                  files={this.state.files}
                  type="Costings"
                  feature_name="Mv_Pur_Fin_Sac_Charges"
                  callbackFn={(data, mandatoryFiles) => {
                    this.setState({
                      files: data,
                      costing_files: mandatoryFiles,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <footer className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button btn-light"
              name="submit"
              onClick={() => {
                this.props.returnCallbackDrawerClose(false);
              }}
            >
              Close
            </button>
            <button
              type="button"
              className="drawer_text drawer_btn_success"
              name="Back"
              onClick={this.submitHandler}
            >
              Submit
            </button>
          </footer>
        </div>
      );
    }
  }
}
