import React, { Component } from "react";
import api from "../../../api/api";
import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import { Alert } from "@material-ui/lab";
import {
  InputAdornment,
  MenuItem,
  Snackbar,
  TextField,
  Checkbox,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { withStyles } from "@material-ui/core/styles";
import FileUpload from "../../common/FileUpload";
import { localDateFormate, pad, toLocaleString } from "../../common/common";
import Loader from "../../common/Loader";

const CheckBox = withStyles({
  root: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

var total_barge_quantity = 0;

export default class CoalProcurementChargesAdd extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      vendorTypeAsOtherMenu: [
        <MenuItem
          style={{ fontFamily: "Poppins", fontSize: "14px" }}
          value="0"
          key={-1}
        >
          Please Select
        </MenuItem>,
      ],

      multiRows: [
        {
          payment_to: "0",
          payee_name: "",
          invoice_no: "",
          invoice_date: null,
          billing_currency:
            config.company === "SRPL" ||
              config.company === "PRPL" ||
              config.company === "AVS"
              ? "USD"
              : "IDR",
          currency_xchg_rate: "1",
          ppn: "",
          pph23_account: "0",
          barge_quantity: "",
          base_price_pmt: "",
          base_price: "",
          vat_percent: "10",
          vat_value: "",
          pph23_percent: "2",
          pph23_value: "",
          payable_to_vendor: "",
          cost_to_company: "",
          remarks: "",
        },
      ],
      isLoading: true,
      activeStep: 0,
      barges: [],
      bargeList: [],
      totalBarges: [],
      barge_id: 1,
      barge_ids: [],
      purchaseDataForMenu: [],
      barge_nomination: "",
      jetty_name: "",
      business_no_drop_value: [],
      business_no_ids: [],
      coal_vendor_drop_value: [],
      coal_vendor_ids: [],
      pc_contrcts_drop_value: [],
      pc_contract_ids: [],
      features: [],
      files: [
        {
          file_description: "",
          file_name: "",
          file_extension: "",
          file_content: "",
          feature_name: "Barge_cpc_costing",
        },
      ],
      costing_files: [],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    var features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (!(features.indexOf("142") >= 0 || features.indexOf("97") >= 0)) {
      window.location.href = "/dashboard";
    }
    let loginUserID = this.Cookie.getCookie("loginUserId");
    let idToken = this.Cookie.getIdTokenCookie();
    var status = "Active";
    const get_business_no = await api.getBusinessNumbers(loginUserID, idToken);
    if (get_business_no.status >= 200 && get_business_no.status < 300) {
      get_business_no.json().then((res) => {
        if (res.code === "200") {
          if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
          if (res.business_numbers) {
            let business_no_drop_value = [];
            for (var i of res.business_numbers) {
              business_no_drop_value.push({
                name: i.business_no,
                value: i.id,
                mother_vessel_name: i.mother_vessel_name ? i.mother_vessel_name : i.business_no,
              });
            }
            this.setState({
              business_no_drop_value: business_no_drop_value,
              //loading: false,
            });
          } else {
            alert("Unexpected error occured. Please contact administrator.");
          }
        } else if (res.code === "607") {
          window.location.href = "/logout";
        }
      });
    }
    const get_pc_contracts = await api.getPurchaseContracts(
      loginUserID,
      "",
      idToken
    );
    if (get_pc_contracts.status >= 200 && get_pc_contracts.status < 300) {
      get_pc_contracts.json().then((res) => {
        if (res.code === "200") {
          if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
          if (res.purchase_contract) {
            let purchaseDataforMenu = [];
            const filteredPContracts = res.purchase_contract.filter(
              (e, indx) => e.status !== "Closed" || e.status === "cancelled"
            );
            for (var i of filteredPContracts) {
              purchaseDataforMenu.push({
                name: i.contract_no,
                value: i.id,
                key: i,
                vendor_name: i.vendor_name,
              });
            }

            this.setState({
              pc_contrcts_drop_value: purchaseDataforMenu,
              //purchaseContractList: res.purchase_contract,
              purchaseContractList: filteredPContracts,
              //isLoading: false
            });
          } else {
            alert("Unexpected error occured. Please contact administrator.");
          }
        } else if (res.code === "607") {
          window.location.href = "/logout";
        }
      });
    }
    const getVendors = await api.getVendors(loginUserID, idToken, status);
    if (getVendors.status >= 200 && getVendors.status < 300) {
      getVendors.json().then((res) => {
        if (res.code === "200") {
          if (res.vendors) {
            const coal_vendor_drop_value = [];
            const vendorTypeAsOther = this.state.vendorTypeAsOtherMenu;
            for (var i of res.vendors) {
              if (i.vendor_type === "Coal") {
                coal_vendor_drop_value.push({
                  name: i.vendor_name,
                  value: i.id,
                });
              }
              if (i.vendor_type === "Others") {
                vendorTypeAsOther.push(
                  <MenuItem
                    style={{ fontFamily: "Poppins", fontSize: "14px" }}
                    value={i.id}
                    key={[i.id]}
                  >
                    {i.vendor_name}
                  </MenuItem>
                );
              }
            }
            this.setState({
              coal_vendor_drop_value: coal_vendor_drop_value,
              vendorTypeAsOtherMenu: vendorTypeAsOther,
              vendors: res.vendors,
              isLoading: false,
            });
          }
        }
      });
    }

    document.title =
      config.documentTitle +
      "Barge Purchase Financial For Coal Procurement Charges";
  }

  nextStepHandler = () => {
    if (this.state.activeStep === 0) {
      if (this.state.bargeList.length <= 0) {
        this.setState({
          snackBarErrorOpen: true,
          errorMsg: "Please Select Barge.",
        });
        return;
      }
    }

    this.setState({
      activeStep: this.state.activeStep + 1,
    });
  };

  previousStepHandler = () => {
    this.setState({
      activeStep: this.state.activeStep - 1,
    });
  };

  onBargesCheck = (id, barge, checked) => {
    this.setState({ isLoading: true });
    var row = this.state.multiRows;
    if (checked) {
      let bargeList = this.state.bargeList;
      let all_barge_quantity = 0;
      bargeList.push(id);
      if (barge.barge_quantity_in_mt === null) {
        all_barge_quantity += Number(barge.barge_planned_quantity_in_mt);
      } else {
        all_barge_quantity += Number(barge.barge_quantity_in_mt);
      }
      total_barge_quantity = all_barge_quantity;
      if (row.length > 0) {
        row[0].barge_quantity = all_barge_quantity;
        row[0].base_price_pmt = "";
      }
      this.setState(
        {
          bargeList: bargeList,
          multiRows: row,
          barge_quantity: total_barge_quantity,
          isLoading: false,
        },
        () => this.onPriceCalculation(0)
      );
    } else {
      let data = this.state.bargeList;
      let bargeQuantity = 0;
      if (barge.barge_quantity_in_mt === null) {
        bargeQuantity += Number(barge.barge_planned_quantity_in_mt);
      } else {
        bargeQuantity += Number(barge.barge_quantity_in_mt);
      }
      total_barge_quantity =
        Number(this.state.barge_quantity) - Number(bargeQuantity);
      if (data.indexOf(id) >= 0) {
        let idx = data.indexOf(id);
        data.splice(idx, 1);
      }
      if (row.length > 0) {
        row[0].barge_quantity = total_barge_quantity;
        row[0].base_price_pmt = "";
      }
      this.setState(
        {
          multiRows: row,
          bargeList: data,
          barge_quantity: total_barge_quantity,
          purchase_price_pmt: "",
          base_price_pmt: "",
          sales_type: "",
          purchase_type: "",
          isLoading: false,
        },
        () => this.onPriceCalculation(0)
      );
    }
  };

  onPriceCalculation = (id) => {
    var row = this.state.multiRows;
    var barge_quantity = row[id].barge_quantity;
    var base_price_pmt = row[id].base_price_pmt;
    var totalBasePrice = Number(barge_quantity) * Number(base_price_pmt);
    var base_price = Number(totalBasePrice).toFixed(4);
    var vat_percent = row[id].vat_percent;
    var vat_value = ((Number(base_price) * Number(vat_percent)) / 100).toFixed(
      2
    );
    var pph23_percent = row[id].pph23_percent;
    var pph23_value = (
      (Number(base_price) * Number(pph23_percent)) /
      100
    ).toFixed(2);
    var cost_to_company = 0;
    var payable_to_vendor = (Number(base_price) + Number(vat_value)).toFixed(2);
    if (
      config.company === "SRPL" ||
      config.company === "PRPL" ||
      config.company === "AVS"
    ) {
      cost_to_company = (Number(base_price) + Number(vat_value)).toFixed(2);
    } else {
      if (row[id].pph23_account === "Supplier") {
        payable_to_vendor = (
          Number(base_price) +
          Number(vat_value) -
          Number(pph23_value)
        ).toFixed(2);
        cost_to_company = (Number(base_price) + Number(vat_value)).toFixed(2);
      } else if (row[id].pph23_account === "SDAM") {
        payable_to_vendor = Number(totalBasePrice).toFixed(2);
        base_price = (
          Number(payable_to_vendor * 100) /
          (100 - Number(row[id].pph23_percent ? row[id].pph23_percent : 2))
        ).toFixed(2);
        pph23_value = (
          (Number(base_price) * Number(pph23_percent)) /
          100
        ).toFixed(2);
        cost_to_company = (Number(base_price) + Number(pph23_value)).toFixed(2);
      }
    }

    row[id].base_price = base_price;
    row[id].vat_percent = vat_percent;
    row[id].vat_value = vat_value;
    row[id].pph23_percent = pph23_percent;
    row[id].pph23_value = pph23_value;
    row[id].payable_to_vendor = payable_to_vendor;
    row[id].cost_to_company = cost_to_company;

    this.setState({
      multiRows: row,
    });
  };

  onSelectAllBarges = (checked) => {
    this.setState({ isLoading: true });
    let rows = this.state.totalBarges;
    var row = this.state.multiRows;
    if (checked) {
      let bargeList = [];
      let all_barge_quantity = 0;
      for (var i of rows) {
        bargeList.push(i.id);
        if (i.barge_quantity_in_mt === null) {
          all_barge_quantity += Number(i.barge_planned_quantity_in_mt);
        } else {
          all_barge_quantity += Number(i.barge_quantity_in_mt);
        }
      }
      total_barge_quantity = all_barge_quantity;
      let row = this.state.multiRows;
      if (row.length > 0) {
        row[0].barge_quantity = all_barge_quantity;
        row[0].base_price_pmt = "";
      }
      this.setState(
        {
          bargeList: bargeList,
          multiRows: row,
          barge_quantity: total_barge_quantity,
          isLoading: false,
        },
        () => this.onPriceCalculation(0)
      );
    } else {
      row[0].barge_quantity = "";
      row[0].base_price_pmt = "";
      this.setState(
        {
          multiRows: row,
          bargeList: [],
          barge_quantity: "",
          purchase_price_pmt: "",
          base_price_pmt: "",
          sales_type: "",
          purchase_type: "",
          isLoading: false,
        },
        () => this.onPriceCalculation(0)
      );
    }
  };

  addMoreProcurementRowsHandler = () => {
    const item = {
      payment_to: "0",
      invoice_date: null,
      ppn: "",
      billing_currency:
        config.company === "SRPL" ||
          config.company === "PRPL" ||
          config.company === "AVS"
          ? "USD"
          : "IDR",
      currency_xchg_rate: "1",
      payee_name: "",
      pph23_account: "0",
      barge_quantity: "",
      base_price_pmt: "",
      base_price: "",
      vat_percent: "10",
      vat_value: "",
      pph23_percent: "2",
      pph23_value: "",
      payable_to_vendor: "",
      cost_to_company: "",
      remarks: "",
    };

    this.setState({
      multiRows: [...this.state.multiRows, item],
    });
  };

  removeProcurementRowsHandler = (idx) => () => {
    const rows = this.state.multiRows;
    rows.splice(idx, 1);
    this.setState({
      multiRows: rows,
    });
  };

  submitHandler = () => {
    this.setState({ isLoading: true });
    let loginUserID = this.Cookie.getCookie("loginUserId");
    let idToken = this.Cookie.getIdTokenCookie();
    let barge_id = this.state.bargeList.toString();
    let cpc_data = this.state.multiRows;

    let costing = this.state.costing_files.filter(
      (e) => e.file_content !== "" && e.column === "invoice_file"
    );

    for (var i in cpc_data) {
      // if (cpc_data[i].payment_to === "Others") {
      //   cpc_data[i].payment_to = null;
      //   cpc_data[i].payee_name = cpc_data[i].payee_name
      //     ? cpc_data[i].payee_name.toUpperCase()
      //     : null;
      // }

      if (cpc_data[i].invoice_no && cpc_data[i].invoice_no !== "") {
        cpc_data[i].invoice_no = cpc_data[0].invoice_no.toUpperCase();
      }
      if (cpc_data[i].remarks && cpc_data[i].remarks !== "") {
        cpc_data[i].remarks = cpc_data[0].remarks.toUpperCase();
      }
      cpc_data[i].costing_files = costing;
    }
    /**
     *  Checking Mandatory files
     */
    var missing_fields = 0;
    let files_missing = 0;
    var row = this.state.files;
    for (var obj of row) {
      if (obj.file_type !== "" || obj.file_content !== "") {
        if (obj.file_content !== "") {
          if (obj.file_description === "") {
            missing_fields++;
            obj.file_description_error = true;
          } else {
            obj.file_description = obj.file_description.toUpperCase();
          }
        } else if (obj.file_description !== "") {
          if (obj.file_content === "") {
            files_missing++;
          }
        }
      }
    }
    this.setState({
      files: row,
    });

    if (missing_fields > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please fill mandatory fields.",
      });
      return;
    }
    if (files_missing > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please Select file.",
      });
      return;
    }

    const content_files = row.filter((e) => e.file_content !== "");
    api
      .add_barge_pur_fin_cpc(
        loginUserID,
        idToken,
        barge_id,
        cpc_data,
        content_files
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.props.onAddResponse(res.code);
              this.setState({
                snackBarSuccessOpen: true,
                sucessMsg: "Costing created sucessfully.",
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            } else if (res.code === "624") {
              alert();
              this.setState({
                snackBarErrorOpen: true,
                isLoading: false,
                errorMsg: "Record already exist for given barge",
              });
            }
          });
        }
      });
  };

  async searchBargeHandler() {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    let financial_type = "barge_purchase_financial_cpc";
    let business_no = this.state.business_no_ids;
    let purchase_contract_id = this.state.pc_contract_ids;
    let surveyor = this.state.coal_vendor_ids;
    const get_barges = await api.get_barges_for_cpc(
      loginUserID,
      idToken,
      financial_type,
      business_no,
      purchase_contract_id,
      surveyor
    );
    if (get_barges.status >= 200 && get_barges.status < 300) {
      get_barges.json().then((res) => {
        if (res.code === "200") {
          if (res.barges) {
            const filter_not_costing_barges = res.barges.filter(
              (e) => e.cpc_costing === null && e.status === "Active"
            );
            if (filter_not_costing_barges.length === 0) {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: "No records to display",
              });
            }
            this.setState({
              totalBarges: filter_not_costing_barges,
              isLoading: false,
            });
          } else {
            alert("Unexpected error occured. Please contact administrator.");
          }
        } else if (res.code === "607") {
          window.location.href = "/logout";
        }
      });
    }
  }

  get_vendor_name(id) {
    if (id) {
      const filter_vendor_name = this.state.vendors.filter((e) => e.id === id);
      return filter_vendor_name.length > 0
        ? filter_vendor_name[0].vendor_name
        : null;
    } else {
      return null;
    }
  }

  render() {
    const pr = /^\d*(\.\d{0,4})?$/;
    const quan = /^\d*(\.\d{0,3})?$/;
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div className="container bg-white p-0" style={{ minHeight: "100vh" }}>
          <div className="clearfix content-header border-bottom">
            <div className="row">
              <div className="col-sm text-left pl-0">
                <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                  Barge Purchase Financial For Coal Procurement Charges
                </h4>
              </div>
            </div>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.sucessMsg}
            </Alert>
          </Snackbar>
          <div className="drawer-section">
            <div className="row mb-0 stepper">
              <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                <i
                  className={
                    this.state.activeStep > 0
                      ? "fa fa-check-circle"
                      : "fa fa-check-circle-o"
                  }
                  aria-hidden="true"
                  style={{
                    fontSize: 20,
                    color:
                      this.state.activeStep > 0
                        ? "#31c197"
                        : this.state.activeStep === 0
                          ? config.themeColor
                          : "#ccc",
                  }}
                />
                <span
                  className="stepper_text"
                  style={{
                    color:
                      this.state.activeStep > 0
                        ? "#31c197"
                        : this.state.activeStep === 0
                          ? config.themeColor
                          : "#ccc",
                  }}
                >
                  Barge Selection
                </span>
                <i
                  className="fa fa-chevron-right stepper_sub_icon stepper_arrow"
                  aria-hidden="true"
                  style={{
                    color:
                      this.state.activeStep > 0
                        ? "#31c197"
                        : this.state.activeStep === 0
                          ? config.themeColor
                          : "#ccc",
                  }}
                />
              </span>
              <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                <i
                  className={
                    this.state.activeStep > 1
                      ? "fa fa-check-circle"
                      : "fa fa-check-circle-o"
                  }
                  aria-hidden="true"
                  style={{
                    fontSize: 20,
                    color:
                      this.state.activeStep > 1
                        ? "#31c197"
                        : this.state.activeStep === 1
                          ? config.themeColor
                          : "#ccc",
                  }}
                />
                <span
                  className="stepper_text"
                  style={{
                    color:
                      this.state.activeStep > 1
                        ? "#31c197"
                        : this.state.activeStep === 1
                          ? config.themeColor
                          : "#ccc",
                  }}
                >
                  Costing
                </span>
                <i
                  className="fa fa-chevron-right stepper_sub_icon stepper_arrow"
                  aria-hidden="true"
                  style={{
                    color:
                      this.state.activeStep > 1
                        ? "#31c197"
                        : this.state.activeStep === 1
                          ? config.themeColor
                          : "#ccc",
                  }}
                />
              </span>
            </div>

            <div className="card">
              {this.state.activeStep === 0 && (
                <div className=" w-100 m-0">
                  <div style={{ width: "100%" }}>
                    <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                      Select Barge to create costing
                    </h5>
                  </div>
                  <div className="row p-2">
                    <div className="col-lg-6 p-0 mb-2">
                      <Autocomplete
                        multiple
                        // options={this.state.business_no_drop_value.map(
                        //   (option) =>
                        //     option.name + "," + option.mother_vessel_name
                        // )}
                        options={this.state.business_no_drop_value}
                        getOptionLabel={(option) => option.mother_vessel_name}
                        renderOption={(option, { selected }) => (
                          <React.Fragment>
                            <CheckBox
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            <div className="col-lg p-0 m-0">
                              <label className="form_label mb-0">
                                {option.mother_vessel_name}
                              </label>
                              <label
                                className="contract_display_header_value m-0 pl-0"
                                style={{ fontSize: 10, paddingLeft: 5 }}
                              >
                                {"( " + option.name + " )"}
                              </label>
                            </div>
                          </React.Fragment>
                        )}
                        onChange={(event, value) => {
                          if (value) {
                            var data = [];
                            for (var i of value) {
                              data.push(i.value);
                            }
                            //var floating_crane_vendor = data.toString();
                            this.setState({ business_no_ids: data });
                          } else {
                            this.setState({ business_no_ids: [] });
                          }
                        }}
                        disableCloseOnSelect
                        name="Business_Number"
                        size="small"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Business No"
                            label="Business No"
                            variant="outlined"
                            style={{ top: 8 }}
                            fullWidth
                          />
                        )}
                        style={{ display: "contents" }}
                      />
                    </div>
                    <div className="col-lg-6 pr-0 mb-2">
                      <Autocomplete
                        multiple
                        options={this.state.pc_contrcts_drop_value}
                        getOptionLabel={(option) => option.name}
                        renderOption={(option, { selected }) => (
                          <React.Fragment>
                            <CheckBox
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.name}{" "}
                            {option.vendor_name
                              ? "( " + option.vendor_name + " )"
                              : null}
                          </React.Fragment>
                        )}
                        onChange={(event, value) => {
                          if (value) {
                            var data = [];
                            for (var i of value) {
                              data.push(i.value);
                            }
                            //var floating_crane_vendor = data.toString();
                            this.setState({ pc_contract_ids: data });
                          } else {
                            this.setState({ pc_contract_ids: [] });
                          }
                        }}
                        disableCloseOnSelect
                        name="Purchase Contract"
                        size="small"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Purchase Contract"
                            label="Purchase Contract"
                            variant="outlined"
                            style={{ top: 8 }}
                            fullWidth
                          />
                        )}
                        style={{ display: "contents" }}
                      />
                    </div>
                    <div className="col-lg-6 p-0">
                      <Autocomplete
                        multiple
                        options={this.state.coal_vendor_drop_value}
                        getOptionLabel={(option) => option.name}
                        renderOption={(option, { selected }) => (
                          <React.Fragment>
                            <CheckBox
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.name}
                          </React.Fragment>
                        )}
                        onChange={(event, value) => {
                          if (value) {
                            var data = [];
                            for (var i of value) {
                              data.push(i.value);
                            }
                            //var floating_crane_vendor = data.toString();
                            this.setState({ coal_vendor_ids: data });
                          } else {
                            this.setState({ coal_vendor_ids: [] });
                          }
                        }}
                        disableCloseOnSelect
                        name="Coal Supplier"
                        size="small"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Coal Supplier"
                            label="Coal Supplier"
                            variant="outlined"
                            style={{ top: 8 }}
                            fullWidth
                          />
                        )}
                        style={{ display: "contents" }}
                      />
                    </div>
                    <div className="col-lg-6 pt-3 pr-0 mt-1">
                      <button
                        className="header_button header_button_text add_button_adjustment"
                        style={{ width: "auto", marginTop: "5px" }}
                        onClick={() => this.searchBargeHandler()}
                      >
                        <i className="fa fa-search mr-1" aria-hidden="true"></i>
                        Search
                      </button>
                    </div>
                  </div>

                  {this.state.totalBarges.length > 0 && (
                    <div className="table-responsive">
                      <div className="table border-0">
                        <table className="table table-borderless border-0">
                          <thead className="border-0">
                            <tr>
                              <th
                                nowrap="true"
                                className="p-0 text-center pt-1 border-0"
                              >
                                <CheckBox
                                  //value={this.state.totalBarges}
                                  color="primary"
                                  //checked={this.state.totalBarges.length !== 0 && (this.state.bargeList.length === this.state.totalBarges.filter(e => e.barge_pur_quality_results_id === null).length)}
                                  checked={
                                    this.state.totalBarges.length !== 0 &&
                                    this.state.bargeList.length ===
                                    this.state.totalBarges.filter(
                                      (e) =>
                                        e.barge_pur_quality_results_id !==
                                        null
                                    ).length
                                  }
                                  //disabled={this.state.selectedPurchaseContractId === "" || p.purchase_contract_id !== this.state.selectedPurchaseContractId}
                                  // onChange={this.onBargesCheck}
                                  onChange={(e) => {
                                    this.onSelectAllBarges(e.target.checked);
                                  }}
                                  className="p-0"
                                />
                              </th>
                              <th
                                style={{ fontSize: 16 }}
                                className="table_header_barge_label text-uppercase border-0"
                                nowrap="true"
                              >
                                Barge ID
                              </th>
                              <th
                                style={{ fontSize: 16 }}
                                className="table_header_barge_label text-uppercase border-0"
                                nowrap="true"
                              >
                                Barge Nomination
                              </th>
                              <th
                                style={{ fontSize: 16 }}
                                className="table_header_barge_label text-uppercase border-0"
                                nowrap="true"
                              >
                                Jetty
                              </th>
                              <th
                                style={{ fontSize: 16 }}
                                className="table_header_barge_label text-uppercase border-0"
                                nowrap="true"
                              >
                                Loading Date
                              </th>
                              <th
                                style={{ fontSize: 16 }}
                                className="table_header_barge_label text-uppercase border-0"
                                nowrap="true"
                              >
                                Business No
                              </th>
                              <th
                                style={{ fontSize: 16 }}
                                className="table_header_barge_label text-uppercase border-0"
                                nowrap="true"
                              >
                                Quantity
                              </th>
                            </tr>
                          </thead>
                          {this.state.totalBarges.map((e, index) => (
                            <tbody key={index}>
                              <tr className="rounded">
                                <td className="p-0 text-center">
                                  <CheckBox
                                    value={e.id}
                                    color="primary"
                                    checked={
                                      this.state.bargeList.indexOf(e.id) >= 0
                                    }
                                    onChange={(e) => {
                                      this.onBargesCheck(
                                        e.target.value,
                                        e,
                                        e.target.checked
                                      );
                                    }}
                                  />
                                </td>
                                <td>{pad(e.id)}</td>
                                <td nowrap="true">{e.barge_nomination}</td>
                                <td nowrap="true">{e.loading_jetty}</td>
                                <td nowrap="true">
                                  {localDateFormate(e.loading_date)}
                                </td>
                                <td nowrap="true">{e.business_no}</td>
                                <td nowrap="true" className="text-right">
                                  {toLocaleString(e.barge_quantity_in_mt)}
                                </td>
                              </tr>
                            </tbody>
                          ))}
                        </table>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {this.state.activeStep === 1 && (
                <div className="" style={{ width: "100%" }}>
                  <div style={{ width: "100%" }}>
                    <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                      Add details to create costing
                    </h5>
                  </div>
                  {this.state.multiRows.map((e, idx) => (
                    <div className="row" key={idx}>
                      <div className="col-lg-11 rounded-lg border_dashed p-2 mb-3">
                        <div className="row mb-3">
                          <div className="col-lg-6 pl-0">
                            <div className="row">
                              <div className="col-lg-12 p-0 mb-3">
                                <label className="form_label mb-0">
                                  Payment to
                                </label>
                                <TextField
                                  name="payment_to"
                                  margin="dense"
                                  variant="outlined"
                                  value={e.payment_to}
                                  onChange={(e) => {
                                    var row = this.state.multiRows;
                                    row[idx].payment_to = e.target.value;
                                    this.setState({
                                      multiRows: row,
                                    });
                                  }}
                                  select
                                  fullWidth
                                  inputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                    },
                                  }}
                                >
                                  {this.state.vendorTypeAsOtherMenu}
                                  {/* <MenuItem value="Others">Others</MenuItem> */}
                                </TextField>
                              </div>
                              {/* {e.payment_to === "Others" && (
                                <div className="col-lg-12 p-0 mb-3">
                                  <label className="form_label mb-0">
                                    Payee Name
                                  </label>
                                  <TextField
                                    name="payee_name"
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth
                                    placeholder="Payee Name"
                                    value={e.payee_name}
                                    onChange={(e) => {
                                      var row = this.state.multiRows;
                                      row[idx].payee_name = e.target.value;
                                      this.setState({
                                        multiRows: row,
                                      });
                                    }}
                                    inputProps={{
                                      style: { textTransform: "uppercase" },
                                    }}
                                  />
                                </div>
                              )} */}

                              <div className="col-lg-6 p-0 mb-3">
                                <label className="form_label mb-0">
                                  Quantity
                                </label>
                                <TextField
                                  name="barge_quantity"
                                  margin="dense"
                                  type="number"
                                  variant="outlined"
                                  fullWidth
                                  inputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                    },
                                  }}
                                  placeholder="Quantity in MT"
                                  value={e.barge_quantity}
                                  onChange={(e) => {
                                    if (
                                      e.target.value === "" ||
                                      quan.test(e.target.value)
                                    ) {
                                      var row = this.state.multiRows;
                                      row[idx].barge_quantity = e.target.value;
                                      this.setState(
                                        {
                                          multiRows: row,
                                        },
                                        () => this.onPriceCalculation(idx)
                                      );
                                    }
                                  }}
                                />
                              </div>

                              <div className="col-lg-6 pr-0 mb-3">
                                <label className="form_label mb-0">
                                  Base Price PMT
                                </label>
                                <TextField
                                  name="base_price_pmt"
                                  margin="dense"
                                  type="number"
                                  variant="outlined"
                                  fullWidth
                                  placeholder="Base Price PMT"
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment
                                        position="end"
                                        className="adornment_background"
                                      >
                                        {config.company === "SRPL" ||
                                          config.company === "PRPL" ||
                                          config.company === "AVS"
                                          ? "USD"
                                          : "IDR"}
                                      </InputAdornment>
                                    ),
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                      padding: 0,
                                    },
                                  }}
                                  value={e.base_price_pmt}
                                  onChange={(e) => {
                                    if (
                                      e.target.value === "" ||
                                      pr.test(e.target.value)
                                    ) {
                                      var row = this.state.multiRows;
                                      row[idx].base_price_pmt = e.target.value;
                                      this.setState(
                                        {
                                          multiRows: row,
                                        },
                                        () => this.onPriceCalculation(idx)
                                      );
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            className="col-lg-6 p-1 rounded"
                            style={{ backgroundColor: "#f7f3f0" }}
                          >
                            {(config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") && (
                                <div className="row pl-2">
                                  <div className="col-lg pl-0 mb-2">
                                    <label className="form_label mb-0">
                                      PPH23 Account
                                    </label>
                                    <TextField
                                      name="pph23_account"
                                      margin="dense"
                                      // variant='outlined'
                                      fullWidth
                                      select
                                      inputProps={{
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                        },
                                        disableUnderline: true,
                                      }}
                                      value={e.pph23_account}
                                      onChange={(e) => {
                                        var row = this.state.multiRows;
                                        row[idx].pph23_account = e.target.value;
                                        row[idx].pph23_percent =
                                          e.target.value === "SDAM" ? 2 : 2;
                                        this.setState(
                                          {
                                            multiRows: row,
                                          },
                                          () => this.onPriceCalculation(idx)
                                        );
                                      }}
                                    >
                                      <MenuItem value="0">Please Select</MenuItem>
                                      <MenuItem value="Supplier">
                                        Supplier
                                      </MenuItem>
                                      <MenuItem value="SDAM">SDAM</MenuItem>
                                    </TextField>
                                  </div>
                                </div>
                              )}
                            {(config.company === "SRPL" ||
                              config.company === "PRPL" ||
                              config.company === "AVS") && (
                                <div>
                                  <div className="row col-lg-12 p-1">
                                    <h6>Price</h6>
                                  </div>
                                  <div className="row pl-2">
                                    <div className="col-lg-4 p-0 mb-2">
                                      <label className="form_label mb-0">
                                        Base Price
                                      </label>
                                      <TextField
                                        name="base_price"
                                        margin="dense"
                                        type="number"
                                        variant="standard"
                                        fullWidth
                                        inputProps={{
                                          style: {
                                            fontFamily: "Poppins",
                                            fontSize: "14px",
                                          },
                                        }}
                                        value={e.base_price}
                                        onChange={(e) => {
                                          if (
                                            e.target.value === "" ||
                                            pr.test(e.target.value)
                                          ) {
                                            var row = this.state.multiRows;
                                            var vat_value = Number(
                                              (Number(e.target.value) *
                                                Number(row[idx].vat_percent)) /
                                              100
                                            ).toFixed(2);
                                            var cost_to_company = (
                                              Number(e.target.value) +
                                              Number(vat_value)
                                            ).toFixed(2);
                                            row[idx].base_price = e.target.value;
                                            row[idx].vat_value = vat_value;
                                            row[idx].cost_to_company =
                                              cost_to_company;
                                            row[idx].payable_to_vendor =
                                              cost_to_company;
                                            this.setState({
                                              multiRows: row,
                                            });
                                          }
                                        }}
                                      />
                                    </div>
                                    <div className="col-lg-4 pr-0 mb-2">
                                      <label className="form_label mb-0">
                                        VAT %
                                      </label>
                                      <TextField
                                        name="vat_percent"
                                        margin="dense"
                                        type="number"
                                        variant="standard"
                                        fullWidth
                                        inputProps={{
                                          style: {
                                            fontFamily: "Poppins",
                                            fontSize: "14px",
                                          },
                                        }}
                                        value={e.vat_percent}
                                        onChange={(e) => {
                                          if (
                                            e.target.value === "" ||
                                            pr.test(e.target.value)
                                          ) {
                                            var row = this.state.multiRows;
                                            var vat_value = Number(
                                              (Number(row[idx].base_price) *
                                                Number(e.target.value)) /
                                              100
                                            ).toFixed(2);
                                            var cost_to_company = (
                                              Number(row[idx].base_price) +
                                              Number(vat_value)
                                            ).toFixed(0);
                                            row[idx].vat_percent = e.target.value;
                                            row[idx].vat_value = vat_value;
                                            row[idx].cost_to_company =
                                              cost_to_company;
                                            row[idx].payable_to_vendor =
                                              cost_to_company;
                                            this.setState({
                                              multiRows: row,
                                            });
                                          }
                                        }}
                                      />
                                    </div>
                                    <div className="col-lg-4 mb-2 pr-0">
                                      <label
                                        className="contract_display_header_label"
                                        style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                      >
                                        VAT
                                      </label>
                                      <div
                                        className="contract_display_header_value"
                                        style={{ color: "#42352d" }}
                                      >
                                        {toLocaleString(e.vat_value)}
                                      </div>
                                    </div>
                                    <div className="col-lg-6 mb-2 pl-0">
                                      <label
                                        className="contract_display_header_label"
                                        style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                      >
                                        Payable to Vendor
                                      </label>
                                      <div
                                        className="contract_display_header_value"
                                        style={{ color: "#42352d" }}
                                      >
                                        {toLocaleString(e.payable_to_vendor)}
                                      </div>
                                    </div>
                                    <div className="col-lg-6 mb-2 p-0">
                                      <label
                                        className="contract_display_header_label"
                                        style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                      >
                                        Cost to Company
                                      </label>
                                      <div
                                        className="contract_display_header_value"
                                        style={{ color: "#42352d" }}
                                      >
                                        {toLocaleString(e.cost_to_company)}
                                        {config.company === "SRPL" ||
                                          config.company === "PRPL" ||
                                          config.company === "AVS"
                                          ? "USD"
                                          : "IDR"}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            {(config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") &&
                              e.pph23_account !== "0" && (
                                <div className="row mb-3">
                                  <div className="row col-lg-12 pl-1">
                                    <h6>Price</h6>
                                  </div>
                                  {e.pph23_account === "Supplier" && (
                                    <div className="row pl-2">
                                      <div className="col-lg-4 p-0 mb-2">
                                        <label className="form_label mb-0">
                                          Base Price
                                        </label>
                                        <TextField
                                          name="base_price"
                                          margin="dense"
                                          type="number"
                                          variant="standard"
                                          fullWidth
                                          inputProps={{
                                            style: {
                                              fontFamily: "Poppins",
                                              fontSize: "14px",
                                            },
                                          }}
                                          value={e.base_price}
                                          onChange={(e) => {
                                            if (
                                              e.target.value === "" ||
                                              pr.test(e.target.value)
                                            ) {
                                              var row = this.state.multiRows;
                                              let vat_value = Number(
                                                (Number(e.target.value) *
                                                  Number(
                                                    row[idx].vat_percent
                                                  )) /
                                                100
                                              ).toFixed(2);
                                              let pph23_value = Number(
                                                (
                                                  (Number(e.target.value) *
                                                    Number(
                                                      row[idx].pph23_percent
                                                    )) /
                                                  100
                                                ).toFixed(2)
                                              );
                                              let payable_to_vendor = (
                                                Number(e.target.value) +
                                                Number(vat_value) -
                                                Number(pph23_value)
                                              ).toFixed(2);
                                              let cost_to_company = (
                                                Number(e.target.value) +
                                                Number(vat_value)
                                              ).toFixed(2);
                                              row[idx].base_price =
                                                e.target.value;
                                              row[idx].vat_value = vat_value;
                                              row[idx].pph23_value =
                                                pph23_value;
                                              row[idx].payable_to_vendor =
                                                payable_to_vendor;
                                              row[idx].cost_to_company =
                                                cost_to_company;
                                              this.setState({
                                                multiRows: row,
                                              });
                                            }
                                          }}
                                        />
                                      </div>
                                      <div className="col-lg-4 pr-0 mb-2">
                                        <label className="form_label mb-0">
                                          VAT %
                                        </label>
                                        <TextField
                                          name="vat_percent"
                                          margin="dense"
                                          type="number"
                                          variant="standard"
                                          fullWidth
                                          inputProps={{
                                            style: {
                                              fontFamily: "Poppins",
                                              fontSize: "14px",
                                            },
                                          }}
                                          value={e.vat_percent}
                                          onChange={(e) => {
                                            if (
                                              e.target.value === "" ||
                                              pr.test(e.target.value)
                                            ) {
                                              var row = this.state.multiRows;
                                              let vat_value = Number(
                                                (Number(row[idx].base_price) *
                                                  Number(e.target.value)) /
                                                100
                                              ).toFixed(2);
                                              let payable_to_vendor = (
                                                Number(row[idx].base_price) +
                                                Number(vat_value) -
                                                Number(row[idx].pph23_value)
                                              ).toFixed(2);
                                              let cost_to_company = (
                                                Number(row[idx].base_price) +
                                                Number(vat_value)
                                              ).toFixed(2);
                                              row[idx].vat_percent =
                                                e.target.value;
                                              row[idx].vat_value = vat_value;
                                              row[idx].payable_to_vendor =
                                                payable_to_vendor;
                                              row[idx].cost_to_company =
                                                cost_to_company;
                                              this.setState({
                                                multiRows: row,
                                              });
                                            }
                                          }}
                                        />
                                      </div>
                                      <div className="col-lg-4 mb-2 pr-0">
                                        <label
                                          className="contract_display_header_label"
                                          style={{
                                            color: "rgba(72, 59, 52, 0.7)",
                                          }}
                                        >
                                          VAT
                                        </label>
                                        <div
                                          className="contract_display_header_value"
                                          style={{ color: "#42352d" }}
                                        >
                                          {toLocaleString(e.vat_value)}
                                        </div>
                                      </div>
                                      <div className="col-lg-4 p-0 mb-2">
                                        <label className="form_label mb-0">
                                          PPH23 %
                                        </label>
                                        <TextField
                                          name="pph23_percent"
                                          margin="dense"
                                          type="number"
                                          variant="standard"
                                          fullWidth
                                          inputProps={{
                                            style: {
                                              fontFamily: "Poppins",
                                              fontSize: "14px",
                                            },
                                          }}
                                          value={e.pph23_percent}
                                          onChange={(e) => {
                                            if (
                                              e.target.value === "" ||
                                              pr.test(e.target.value)
                                            ) {
                                              var row = this.state.multiRows;
                                              let pph23_value = Number(
                                                (
                                                  (Number(row[idx].base_price) *
                                                    Number(e.target.value)) /
                                                  100
                                                ).toFixed(2)
                                              );
                                              let payable_to_vendor = (
                                                Number(row[idx].base_price) +
                                                Number(row[idx].vat_value) -
                                                Number(pph23_value)
                                              ).toFixed(2);
                                              let cost_to_company = (
                                                Number(row[idx].base_price) +
                                                Number(row[idx].vat_value)
                                              ).toFixed(2);
                                              row[idx].pph23_percent =
                                                e.target.value;
                                              row[idx].pph23_value =
                                                pph23_value;
                                              row[idx].cost_to_company =
                                                cost_to_company;
                                              row[idx].payable_to_vendor =
                                                payable_to_vendor;
                                              this.setState({
                                                multiRows: row,
                                              });
                                            }
                                          }}
                                        />
                                      </div>
                                      <div className="col-lg-4 mb-2 pr-0">
                                        <label
                                          className="contract_display_header_label"
                                          style={{
                                            color: "rgba(72, 59, 52, 0.7)",
                                          }}
                                        >
                                          PPH23
                                        </label>
                                        <div
                                          className="contract_display_header_value"
                                          style={{ color: "#42352d" }}
                                        >
                                          {toLocaleString(e.pph23_value)}
                                        </div>
                                      </div>
                                      <div className="col-lg-6 mb-2 p-0">
                                        <label
                                          className="contract_display_header_label"
                                          style={{
                                            color: "rgba(72, 59, 52, 0.7)",
                                          }}
                                        >
                                          Payable to Vendor
                                        </label>
                                        <div
                                          className="contract_display_header_value"
                                          style={{ color: "#42352d" }}
                                        >
                                          {toLocaleString(e.payable_to_vendor)}
                                        </div>
                                      </div>
                                      <div className="col-lg-6 mb-2 pr-0">
                                        <label
                                          className="contract_display_header_label"
                                          style={{
                                            color: "rgba(72, 59, 52, 0.7)",
                                          }}
                                        >
                                          Cost to Company
                                        </label>
                                        <div
                                          className="contract_display_header_value"
                                          style={{ color: "#42352d" }}
                                        >
                                          {toLocaleString(e.cost_to_company)}
                                          {config.company === "SRPL" ||
                                            config.company === "PRPL" ||
                                            config.company === "AVS"
                                            ? "USD"
                                            : "IDR"}
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                  {e.pph23_account === "SDAM" && (
                                    <div className="row p-2">
                                      <div className="col-lg-4 mb-2 p-0">
                                        <label
                                          className="contract_display_header_label"
                                          style={{
                                            color: "rgba(72, 59, 52, 0.7)",
                                          }}
                                        >
                                          Base Price
                                        </label>
                                        <div
                                          className="contract_display_header_value"
                                          style={{ color: "#42352d" }}
                                        >
                                          {toLocaleString(e.base_price)}
                                        </div>
                                      </div>
                                      <div className="col-lg-4 mb-2 pr-0">
                                        <label
                                          className="contract_display_header_label"
                                          style={{
                                            color: "rgba(72, 59, 52, 0.7)",
                                          }}
                                        >
                                          PPH23 %
                                        </label>
                                        <div
                                          className="contract_display_header_value"
                                          style={{ color: "#42352d" }}
                                        >
                                          {e.pph23_percent}
                                        </div>
                                      </div>
                                      <div className="col-lg-4 mb-2 pr-0">
                                        <label
                                          className="contract_display_header_label"
                                          style={{
                                            color: "rgba(72, 59, 52, 0.7)",
                                          }}
                                        >
                                          PPH23
                                        </label>
                                        <div
                                          className="contract_display_header_value"
                                          style={{ color: "#42352d" }}
                                        >
                                          {toLocaleString(e.pph23_value)}
                                        </div>
                                      </div>
                                      <div className="col-lg-6 mb-2 p-0">
                                        <label className="form_label mb-0">
                                          Payable To Vendor
                                        </label>
                                        <TextField
                                          name="payable_to_vendor"
                                          margin="dense"
                                          type="number"
                                          // variant='outlined'
                                          fullWidth
                                          inputProps={{
                                            style: {
                                              fontFamily: "Poppins",
                                              fontSize: "14px",
                                            },
                                          }}
                                          value={e.payable_to_vendor}
                                          onChange={(e) => {
                                            if (
                                              e.target.value === "" ||
                                              pr.test(e.target.value)
                                            ) {
                                              var row = this.state.multiRows;
                                              let base_price = (
                                                (Number(e.target.value) * 100) /
                                                (100 -
                                                  Number(
                                                    row[idx].pph23_percent
                                                      ? row[idx].pph23_percent
                                                      : 2
                                                  ))
                                              ).toFixed(2);
                                              let pph23_value = (
                                                (Number(base_price) *
                                                  Number(
                                                    row[idx].pph23_percent
                                                  )) /
                                                100
                                              ).toFixed(2);
                                              var cost_to_company = (
                                                Number(base_price) +
                                                Number(pph23_value)
                                              ).toFixed(2);
                                              row[idx].payable_to_vendor =
                                                e.target.value;
                                              row[idx].base_price = base_price;
                                              row[idx].pph23_value =
                                                pph23_value;
                                              row[idx].cost_to_company =
                                                cost_to_company;
                                              this.setState({
                                                multiRows: row,
                                              });
                                            }
                                          }}
                                        />
                                      </div>
                                      <div className="col-lg-6 mb-2 pr-0">
                                        <label
                                          className="contract_display_header_label"
                                          style={{
                                            color: "rgba(72, 59, 52, 0.7)",
                                          }}
                                        >
                                          Cost to Company
                                        </label>
                                        <div
                                          className="contract_display_header_value"
                                          style={{ color: "#42352d" }}
                                        >
                                          {toLocaleString(e.cost_to_company)}
                                          {config.company === "SRPL" ||
                                            config.company === "PRPL" ||
                                            config.company === "AVS"
                                            ? "USD"
                                            : "IDR"}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                          </div>
                        </div>

                        <div className="row mb-0">
                          <div className="col-lg-12 p-0">
                            <label className="form_label mb-0">Remarks</label>
                            <TextField
                              name="remarks"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              placeholder="Remarks"
                              value={e.remarks}
                              multiline
                              rows={3}
                              onChange={(e) => {
                                var row = this.state.multiRows;
                                row[idx].remarks = e.target.value;
                                this.setState({
                                  multiRows: row,
                                });
                              }}
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      {this.state.multiRows.length > 1 && (
                        <div className="col-lg-1 d-flex justify-content-center m-auto">
                          <div className="icon_hover text-align-center">
                            <i
                              className="fa fa-trash-o m-auto"
                              onClick={this.removeProcurementRowsHandler(idx)}
                              style={{
                                fontSize: 24,
                                color: config.themeColor,
                                cursor: "pointer",
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                  <div className="row mt-2 mb-2">
                    <button
                      type="button"
                      onClick={() => this.addMoreProcurementRowsHandler()}
                      className="header_button header_button_text addrow_button_adjustment"
                      style={{ color: config.themeColor }}
                    >
                      Add More
                    </button>
                  </div>
                  {/* {(this.state.features.indexOf('371') >= 0 || this.state.features.indexOf('97') >= 0) && */}
                  <FileUpload
                    files={this.state.files}
                    type="Costings"
                    feature_name="Barge_cpc_costing"
                    callbackFn={(data, mandatoryFiles) => {
                      this.setState({
                        files: data,
                        costing_files: mandatoryFiles,
                      });
                    }}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button"
              name="previous"
              onClick={
                this.state.activeStep === 0
                  ? () => this.props.onCancel(false)
                  : this.previousStepHandler
              }
            >
              {this.state.activeStep === 0 ? "CANCEL" : "PREVIOUS"}
            </button>
            <button
              type="button"
              className="btn next_button"
              name="next"
              onClick={
                this.state.activeStep === 1
                  ? this.submitHandler
                  : this.nextStepHandler
              }
            >
              {this.state.activeStep === 1 ? "SUBMIT" : "NEXT STEP"}
            </button>
          </div>
        </div>
      );
    }
  }
}
