import React, { Component } from "react";
import SideBar from "../../common/SideBar";
import Header from "../../common/Header";
import CookieHandler from "../../common/CookieHandler";

import api from "../../../api/api";
import config from "../../../config/config";
import { MenuItem, Popover, Snackbar } from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/Dashboard";
import { Alert } from "@material-ui/lab";
import AdvancePaymentPostings from "../AdvancePaymentPostings";
import { toLocaleString, localDateFormate } from "../../common/common";
import Loader from "../../common/Loader";

export default class VendorAdvanceContractView extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      vendorAdvanceContractID: "",
      vendor_name: "",
      supplier_ref_no: "",
      advance_amount: "",
      contract_date: "",
      remarks: "",

      // advance adjustments
      advance_adjustments: [],
      cps_advance_adjustments: [],
      vendor_adv_invoices: [],
      isLoading: true,
      features: [],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    this.setState({ features: features ? features : [] }, () => {
      if (
        !(
          this.state.features.includes("2") ||
          this.state.features.includes("97")
        )
      ) {
        window.location.href = "/dashboard";
      }
    });
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const status = "Active";
    const vendorAdvanceContractID = decodeURIComponent(
      window.atob(this.props.match.params.vendorAdvanceContractID)
    );
    this.setState({ vendorAdvanceContractID: vendorAdvanceContractID });
    //get_adv_adjustments_by_adv_contract_id
    const vendor_adv_contract_id = vendorAdvanceContractID;
    await api
      .get_adv_adjustments_by_adv_contract_id(
        loginUserID,
        idToken,
        vendor_adv_contract_id
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.adv_adjustments) {
                let obj = {};

                res.adv_adjustments.forEach((item) => {
                  if (obj[item.business_number_id]) {
                    obj[item.business_number_id].value =
                      obj[item.business_number_id].advance_adjustment_value +
                      item.advance_adjustment_value;
                  } else {
                    obj[item.business_number_id] = item;
                  }
                });

                this.setState({
                  advance_adjustments: Object.values(obj),
                });
              }

              // if (res.vendor_adv_contract) {
              //   var sumOffAdvance_adjustment = 0;
              //   for (var i of res.vendor_adv_contract) {
              //     sumOffAdvance_adjustment += Number(i.advance_adjustment);
              //   }
              //   for (var j of res.cps_advance_adjustments) {
              //     sumOffAdvance_adjustment += Number(
              //       j.cps_advance_adjustment_value
              //     );
              //   }
              //   this.setState({
              //     advance_adjustments: res.vendor_adv_contract,
              //     cps_advance_adjustments: res.cps_advance_adjustments,
              //     sumOffAdvance_adjustment: sumOffAdvance_adjustment,
              //   });
              // }
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                snackBarErrorOpen: true,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            } else if (res.code === "647") {
              this.setState({
                errorMsg: res.message,
                snackBarErrorOpen: true,
              });
            }
          });
        }
      });
    //Get Vendor Advance Contract Details
    await api
      .getVendorAdvanceContractDetails(
        vendorAdvanceContractID,
        loginUserID,
        idToken
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState({
                vendor_adv_invoices: res.vendor_adv_invoices,
              });
              if (res.vendor_adv_contract) {
                this.setState({
                  contractNo: res.vendor_adv_contract[0].contract_no,
                  vendorAdvanceContractID: res.vendor_adv_contract[0].id,
                  vendor_name: res.vendor_adv_contract[0].vendor_contract_name,
                  vendorId: res.vendor_adv_contract[0].vendor_id,
                  supplier_ref_no: res.vendor_adv_contract[0].supplier_ref_no,
                  advance_amount: res.vendor_adv_contract[0].advance_amount,
                  contract_date: res.vendor_adv_contract[0].contract_date,
                  remarks: res.vendor_adv_contract[0].remarks,
                  status: res.vendor_adv_contract[0].status,
                  billing_currency: res.vendor_adv_contract[0].billing_currency,
                  contract_type: res.vendor_adv_contract[0].contract_type,

                  //isLoading: false
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });

    // Get Vendor Advance Payment Based on Advance Vendor Contract ID
    await api
      .getVendor_adv_paymentByContractID(
        loginUserID,
        idToken,
        vendorAdvanceContractID,
        status
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.vendor_adv_payments) {
                var sumOf_vendor_adv_payments = 0;
                var sumOf_vendor_adv_currency_payments = 0;
                for (var i of res.vendor_adv_payments) {
                  sumOf_vendor_adv_payments += Number(i.amount);
                  sumOf_vendor_adv_currency_payments += Number(
                    i.amount_in_payment_currency
                  );
                }
                this.setState({
                  advancePayments: res.vendor_adv_payments,
                  sumOf_vendor_adv_payments: sumOf_vendor_adv_payments,
                  sumOf_vendor_adv_currency_payments:
                    sumOf_vendor_adv_currency_payments,
                  isLoading: false,
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                snackBarErrorOpen: true,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            } else if (res.code === "647") {
              this.setState({
                advancePayments: [],
                isLoading: false,
              });
            }
          });
        }
      });

    document.title = config.documentTitle + "View Vendor Advance Contract";
  }

  addButtonOpen = (event) => {
    event.preventDefault();
    this.setState({
      addButtonOpen: true,
      openDrawer: false,
      anchorEn: event.currentTarget,
    });
  };

  addButtonClose = () => {
    this.setState({
      addButtonOpen: false,
    });
  };

  AddPaymentPostingHandler = (data) => {
    let request = {
      ...data,
      vendor_adv_contract_id: this.state.vendorAdvanceContractID,
    };
    api.add_vendor_adv_payment(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.componentDidMount();
            window.$("#addAdvancePayment").modal("hide");
          } else if (res.code === "601") {
            this.setState({
              errorMsg: res.message,
              snackBarErrorOpen: true,
            });
          } else if (res.code === "624") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.columns[0],
              paymentErrorMsg: res.columns[0],
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
  };

  UpdatePaymentPostingHandler = (data) => {
    let request = {
      ...data,
      vendor_adv_contract_id: this.state.vendorAdvanceContractID,
      vendor_adv_payment_id: data?.id,
    };
    api.update_vendor_adv_payment(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.componentDidMount();
            window.$("#addAdvancePayment").modal("hide");
          } else if (res.code === "601") {
            this.setState({
              errorMsg: res.message,
              snackBarErrorOpen: true,
            });
          } else if (res.code === "624") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.columns[0],
              paymentErrorMsg: res.columns[0],
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
  };

  deleteVendorAdvancePayment = (deleteData) => {
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    var advancePaymentID = deleteData.id;
    var vendorAdvanceContractID = deleteData.vendor_adv_contract_id;
    var paymentDate = deleteData.payment_date;
    var amount = deleteData.amount;
    var remarks = deleteData.remarks;
    var status = "Deleted";
    api
      .deleteVendorAdvancePayment(
        loginUserID,
        idToken,
        advancePaymentID,
        paymentDate,
        amount,
        vendorAdvanceContractID,
        remarks,
        status
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState({ deletePayment: false });
              // window.$("#deleteAdvancePayment").modal("hide");
              this.componentDidMount();
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                succesMsg: "",
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  };

  updateVendorAdvancePayment = (data) => {
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    var vendorAdvanceContractID = data.vendor_adv_contract_id;
    var paymentDate = this.state.payment_date;
    var amount = this.state.amount;
    var remarks = this.state.addPaymentRemarks
      ? this.state.addPaymentRemarks
      : "";

    var payment_currency = this.state.payment_currency;
    var currency_xchg_rate = this.state.currency_xchg_rate;
    var amount_in_payment_currency = this.state.amount_in_payment_currency;
    var advancePaymentID = data.id;
    var invoice = this.state.invoice ? this.state.invoice : "";
    if (paymentDate === null) {
      this.setState({
        paymentDateError: true,
      });
    }
    if (amount === "") {
      this.setState({
        amountError: true,
      });
    }
    if (payment_currency === "0") {
      this.setState({
        payment_currencyError: true,
      });
    }
    if (
      ((config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL") &&
        this.state.payment_currency === "USD") ||
      ((config.company === "SDAM" ||
        config.company === "SRK" ||
        config.company === "BTR") &&
        this.state.payment_currency === "IDR")
    ) {
      currency_xchg_rate = 1;
    }
    if (paymentDate !== null && amount !== "" && payment_currency !== "0") {
      api
        .updateVendorAdvancePayment(
          loginUserID,
          idToken,
          advancePaymentID,
          paymentDate,
          amount,
          invoice,
          vendorAdvanceContractID,
          payment_currency,
          currency_xchg_rate,
          amount_in_payment_currency,
          remarks
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                this.setState({
                  payment_date: null,
                  amount: "",
                  invoice: "",
                  addPaymentRemarks: "",
                  paymentErrorMsg: "",
                });
                window.$("#updateAdvancePayment").modal("hide");
                this.componentDidMount();
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  snackBarErrorOpen: true,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              } else if (res.code === "624") {
                this.setState({
                  paymentErrorMsg: res.columns[0],
                  succesMsg: "",
                });
              }
            });
          }
        });
    } else {
      alert("Please fill mandatory fields.");
    }
  };

  toLocalDecimalString(value) {
    return Number(value).toLocaleString(undefined, {
      minimumFractionDigits: 2,
    });
  }

  closeContractHandler = () => {
    try {
      var vendorAdvanceContractID = decodeURIComponent(
        window.atob(this.props.match.params.vendorAdvanceContractID)
      );
      var loginUserID = this.Cookie.getCookie("loginUserId");
      var idToken = this.Cookie.getIdTokenCookie();
      // var { vendorName, supplierReferenceNo, advanceAmount, contractDate, remarks } = this.state;
      var vendorName = this.state.vendorId;
      var supplierReferenceNo = this.state.supplier_ref_no
        ? this.state.supplier_ref_no
        : "";
      var advanceAmount = this.state.advance_amount;
      var contractDate = this.state.contract_date;
      var remarks = this.state.remarks ? this.state.remarks : "";
      var status = "Closed";
      api
        .updateVendorAdvanceContract(
          loginUserID,
          vendorAdvanceContractID,
          vendorName,
          supplierReferenceNo,
          advanceAmount,
          contractDate,
          remarks,
          idToken,
          status
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                if (vendorAdvanceContractID)
                  window.location.href =
                    "/vendor-advance-view-contract/" +
                    btoa(vendorAdvanceContractID);
                //window.location.href = '/advance-payments';
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: "Please fill manditory fields",
                  snackBarErrorOpen: true,
                });
                return;
              } else if (res.code === "624") {
                this.setState({
                  errorMsg: "Validation failed",
                  snackBarErrorOpen: true,
                });
                return;
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    } catch (e) {
      window.location.href = "/advance-payments";
    }
  };

  cancelContractHandler = () => {
    try {
      var vendorAdvanceContractID = decodeURIComponent(
        window.atob(this.props.match.params.vendorAdvanceContractID)
      );
      var loginUserID = this.Cookie.getCookie("loginUserId");
      var idToken = this.Cookie.getIdTokenCookie();
      // var { vendorName, supplierReferenceNo, advanceAmount, contractDate, remarks } = this.state;
      var vendorName = this.state.vendorId;
      var supplierReferenceNo = this.state.supplier_ref_no
        ? this.state.supplier_ref_no
        : "";
      var advanceAmount = this.state.advance_amount;
      var contractDate = this.state.contract_date;
      var remarks = this.state.remarks ? this.state.remarks : "";
      var status = "cancelled";
      api
        .updateVendorAdvanceContract(
          loginUserID,
          vendorAdvanceContractID,
          vendorName,
          supplierReferenceNo,
          advanceAmount,
          contractDate,
          remarks,
          idToken,
          status
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                if (vendorAdvanceContractID)
                  window.location.href =
                    "/vendor-advance-view-contract/" +
                    btoa(vendorAdvanceContractID);
                //window.location.href = '/advance-payments';
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: "Please fill manditory fields",
                  snackBarErrorOpen: true,
                });
                return;
              } else if (res.code === "624") {
                this.setState({
                  errorMsg: "Validation failed",
                  snackBarErrorOpen: true,
                });
                return;
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    } catch (e) {
      window.location.href = "/advance-payments";
    }
  };

  /**
   *
   * INVOICES
   *
   *
   */

  AddInvoicesHandler = (invoiceDetails) => {
    let request = {
      ...invoiceDetails,
      vendor_adv_contract_id: decodeURIComponent(
        window.atob(this.props.match.params.vendorAdvanceContractID)
      ),
    };
    api.add_vendor_adv_invoice(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.componentDidMount();
            window.$("#invoicesPostingModal").modal("hide");
          } else {
          }
        });
      }
    });
  };

  UpdateInvoicesHandler = (invoiceDetails) => {
    let request = {
      ...invoiceDetails,
      vendor_adv_invoice_id: invoiceDetails?.id,
      vendor_adv_contract_id: decodeURIComponent(
        window.atob(this.props.match.params.vendorAdvanceContractID)
      ),
    };

    api.update_vendor_adv_invoice(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.componentDidMount();
            window.$("#invoicesPostingModal").modal("hide");
          } else {
          }
        });
      }
    });
  };

  render() {
    return (
      <div id="wraper">
        <SideBar />
        <div className="content">
          <Header />
          <div classNaplme="clearfix content-header">
            <div className="row">
              <div className="col-sm text-left pl-0">
                <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                  <a href={"/advance-payments"}>
                    <i className="fa fa-arrow-left left_arrow_adjst" />
                  </a>
                  {`${
                    this.state.contract_type === "Down Payment"
                      ? `Down Payment`
                      : `Vendor Advance Contract`
                  } - ${this.state.contractNo} `}
                  <span
                    className={
                      this.state.status === "Active" ? "type_1" : "type_2"
                    }
                    style={{ padding: 8, marginLeft: 16 }}
                  >
                    {this.state.status}
                  </span>
                </h4>
              </div>
              <div className="col-lg-2 text-right float-sm-left">
                <span
                  className="mr-1"
                  data-toggle="tooltip"
                  title="Action"
                  data-placement="bottom"
                >
                  <DashboardIcon
                    style={{
                      fontSize: 30,
                      color: "#138496",
                      cursor: "pointer",
                    }}
                    onClick={this.addButtonOpen}
                  />
                </span>
              </div>
            </div>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          {!!this.state.isLoading && <Loader />}
          {!this.state.isLoading && (
            <div>
              <div className="container p-3">
                <div
                  className="col-lg card"
                  id="inVoice"
                  style={{ overflowY: "scroll" }}
                >
                  <div className="shadow-sm mb-3 bg-white border">
                    <h5 className="mb-0 p-2 section_header">
                      Contract Details
                    </h5>
                    <div className="row col-lg-12 p-2">
                      <div className="col-lg-6  mb-2">
                        <label className="contract_display_header_label m-0">
                          Vendor
                        </label>
                        <div className="contract_display_header_value">
                          <a
                            href={"/view-vendor/" + btoa(this.state.vendorId)}
                            target="_blank"
                            rel="noopener noreferrer"
                            role="button"
                            style={{ color: config.themeColor }}
                          >
                            {this.state.vendor_name}
                          </a>
                        </div>
                      </div>

                      {this.state.contract_type !== "Down Payment" && (
                        <div className="col-lg-6  mb-2">
                          <label className="contract_display_header_label m-0">
                            Supplier Reference No
                          </label>
                          <div className="contract_display_header_value">
                            {this.state.supplier_ref_no === null
                              ? "-"
                              : this.state.supplier_ref_no}
                          </div>
                        </div>
                      )}
                      <div className="col-lg-6 mb-2">
                        <label className="contract_display_header_label m-0">
                          {`${
                            this.state.contract_type === "Down Payment"
                              ? `Down Payment Aount`
                              : `Advance Amount`
                          }`}
                        </label>
                        <div className="contract_display_header_value">
                          {`${toLocaleString(this.state.advance_amount)} ${
                            this.state.billing_currency
                          }`}
                        </div>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <label className="contract_display_header_label m-0">
                          Contract Date
                        </label>
                        <div className="contract_display_header_value">
                          {localDateFormate(this.state.contract_date)}
                        </div>
                      </div>
                      <div className="col-lg-6  mb-2">
                        <label className="contract_display_header_label m-0">
                          Remarks
                        </label>
                        <div className="contract_display_header_value text-uppercase">
                          {this.state.remarks === null
                            ? "-"
                            : this.state.remarks}
                        </div>
                      </div>
                    </div>
                  </div>

                  {(this.state.features.includes("5") ||
                    this.state.features.includes("97")) && (
                    <div className="shadow-sm mb-3 bg-white border">
                      <h5 className="mb-0 p-2 section_header">
                        {`${
                          this.state.contract_type === "Down Payment"
                            ? `Down Payments`
                            : ` Vendor Advance Payments`
                        }`}
                      </h5>
                      <AdvancePaymentPostings
                        patmentsData={this.state.vendor_adv_invoices}
                        accessInfo={{
                          invoice_approver: "588",
                          invoice_approver_2: "589",
                          payment_approver: "590",
                          payment_approver_2: "591",
                        }}
                        costingType={"Vendor Advance Payments"}
                        paymentApprType={"vendor_adv_payments_data"}
                        paymentsHandler={(paymentMode, data) => {
                          if (paymentMode === "ADD") {
                            this.AddPaymentPostingHandler(data);
                          } else {
                            this.UpdatePaymentPostingHandler(data);
                          }
                        }}
                        invoicesHandler={(invoiceMode, data) => {
                          if (invoiceMode === "ADD") {
                            this.AddInvoicesHandler(data);
                          } else {
                            this.UpdateInvoicesHandler(data);
                          }
                        }}
                        callbackFn={() => this.componentDidMount()}
                      />
                    </div>
                  )}

                  {this.state.status === "Active" && (
                    <div className="shadow-sm mb-3 bg-white border">
                      <h5 className="mb-0 p-2 section_header">
                        {`${
                          this.state.contract_type === "Down Payment"
                            ? `Down Payment Adjustments`
                            : ` Vendor Advance Adjustments`
                        }`}
                      </h5>
                      <div className="row col-lg-12 p-2 pl-4">
                        {this.state.advance_adjustments.length === 0 &&
                          this.state.cps_advance_adjustments.length === 0 && (
                            <h6>No adjustments made.</h6>
                          )}
                        {(this.state.advance_adjustments.length > 0 ||
                          this.state.cps_advance_adjustments.length > 0) && (
                          <div className="table-responsive border-0">
                            <table className="table table-borderless border-0">
                              <thead>
                                <tr>
                                  <th classsName="table_header_barge_label">
                                    Business Number
                                  </th>
                                  <th
                                    classsName="table_header_barge_label text-right"
                                    style={{ textAlign: "end" }}
                                  >
                                    Adjusted Amount
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.advance_adjustments.map(
                                  (e, idx) => (
                                    <tr key={idx}>
                                      <td className="table_header_barge_value text-left">
                                        {e.business_number_id ? (
                                          <a
                                            href={
                                              "/view-business/" +
                                              btoa(e.business_number_id)
                                            }
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            role="button"
                                            style={{ color: config.themeColor }}
                                          >
                                            {e.business_no ? e.business_no : ""}
                                            {e.mother_vessel_name
                                              ? " (" +
                                                e.mother_vessel_name +
                                                ")"
                                              : ""}
                                          </a>
                                        ) : (
                                          <span>
                                            {e.barge_name
                                              ? " (" + e.barge_name + ")"
                                              : ""}{" "}
                                            {e.barge_nomination
                                              ? "- " + e.barge_nomination
                                              : ""}{" "}
                                          </span>
                                        )}
                                      </td>
                                      <td className="table_header_barge_value text-right">
                                        {e.advance_adjustment_value
                                          ? this.toLocalDecimalString(
                                              e.advance_adjustment_value
                                            )
                                          : 0}
                                      </td>
                                    </tr>
                                  )
                                )}
                                {this.state.cps_advance_adjustments.map(
                                  (e, idx) => (
                                    <tr key={idx}>
                                      <td className="table_header_barge_value text-left">
                                        {e.business_number_id ? (
                                          <a
                                            href={
                                              "/view-business/" +
                                              btoa(e.business_number_id)
                                            }
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            role="button"
                                            style={{ color: config.themeColor }}
                                          >
                                            {e.business_no ? e.business_no : ""}
                                            {e.mother_vessel_name
                                              ? " (" +
                                                e.mother_vessel_name +
                                                ")"
                                              : ""}
                                            {/* {e.business_no ? e.business_no : ""}
                                            {e.mother_vessel_name
                                              ? " (" +
                                                e.mother_vessel_name +
                                                ")"
                                              : e.barge_name
                                              ? " (" + e.barge_name + ")"
                                              : ""}{" "}
                                            {e.barge_nomination
                                              ? "- " + e.barge_nomination
                                              : ""}{" "} */}
                                          </a>
                                        ) : (
                                          <span>
                                            Coal Procurement Service Fee{" "}
                                            {e.barge_name
                                              ? " (" + e.barge_name + ")"
                                              : ""}{" "}
                                            {e.barge_nomination
                                              ? "- " + e.barge_nomination
                                              : ""}{" "}
                                          </span>
                                        )}
                                      </td>
                                      <td className="table_header_barge_value text-right">
                                        {e.cps_advance_adjustment_value
                                          ? this.toLocalDecimalString(
                                              e.cps_advance_adjustment_value
                                            )
                                          : ""}
                                      </td>
                                    </tr>
                                  )
                                )}
                                {(this.state.advance_adjustments.length > 0 ||
                                  this.state.cps_advance_adjustments.length >
                                    0) && (
                                  <tr>
                                    <td className="table_header_barge_value"></td>
                                    <td className="table_header_barge_value text-right">
                                      {`Total : ${this.state.advance_adjustments?.reduce(
                                        (acc, curr) =>
                                          parseFloat(acc) +
                                          parseFloat(
                                            curr.advance_adjustment_value
                                              ? curr.advance_adjustment_value
                                              : 0
                                          ),
                                        0
                                      )}`}
                                    </td>
                                  </tr>
                                )}
                                {(this.state.advance_adjustments.length > 0 ||
                                  this.state.cps_advance_adjustments.length >
                                    0) && (
                                  <tr>
                                    <td></td>
                                    <td
                                      className="table_header_barge_value text-success text-right"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      Remaining Advance :{" "}
                                      {this.toLocalDecimalString(
                                        Number(
                                          this.state.sumOf_vendor_adv_payments
                                            ? this.state
                                                .sumOf_vendor_adv_payments
                                            : 0
                                        ) -
                                          Number(
                                            this.state.advance_adjustments?.reduce(
                                              (acc, curr) =>
                                                parseFloat(acc) +
                                                parseFloat(
                                                  curr.advance_adjustment_value
                                                    ? curr.advance_adjustment_value
                                                    : 0
                                                ),
                                              0
                                            )
                                          )
                                      )}
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <div className="row bottom_buttons_section">
                  <button
                    type="button"
                    className="btn previous_button"
                    name="submit"
                    onClick={() => (window.location.href = "/advance-payments")}
                  >
                    Back
                  </button>
                  {(this.state.features.includes("3") ||
                    this.state.features.includes("97")) && (
                    <button
                      type="button"
                      className="btn next_button"
                      name="submit"
                      onClick={() =>
                        (window.location.href =
                          "/vendor-advance-contract/" +
                          btoa(this.state.vendorAdvanceContractID))
                      }
                    >
                      Edit
                    </button>
                  )}
                </div>
              </div>

              <Popover
                open={this.state.addButtonOpen}
                anchorEl={this.state.anchorEn}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                targetOrigin={{ horizontal: "left", vertical: "top" }}
                onClose={this.addButtonClose}
              >
                <div className="card border">
                  <MenuItem
                    style={{ fontFamily: "Poppins" }}
                    onClick={this.closeContractHandler}
                  >
                    <i
                      className="fa fa-times mr-4 ml-0"
                      style={{
                        fontSize: 26,
                        color: "#3256a8",
                        opacity: "0.5",
                        margin: "0px 6px",
                        width: "16px",
                      }}
                    ></i>
                    Close
                  </MenuItem>
                  {this.state.advance_adjustments.length === 0 && (
                    <MenuItem
                      style={{ fontFamily: "Poppins" }}
                      onClick={this.cancelContractHandler}
                    >
                      <i
                        className="fa fa-minus-square-o mr-3"
                        aria-hidden="true"
                        style={{
                          fontSize: 26,
                          color: "#3256a8",
                          opacity: "0.5",
                          margin: "0px 3px",
                          width: "20px",
                        }}
                      />
                      Cancel
                    </MenuItem>
                  )}
                </div>
              </Popover>

              {/* <div className="modal fade" id="addAdvancePayment" role="dialog">
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content payment-modal">
                    <div
                      className="modal-header border-0"
                      style={{ backgroundColor: "#f1f1f1" }}
                    >
                      <h6
                        className="modal-title"
                        style={{ border: "none", padding: 0 }}
                      >
                        Add Vendor Advance Payment
                      </h6>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                      >
                        &times;
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="col">
                        <div
                          style={{
                            fontSize: 14,
                            color: "red",
                            textTransform: "capitalize",
                            textAlign: "center",
                          }}
                        >
                          {this.state.paymentErrorMsg}
                        </div>
                        <div className="row">
                          <div className="col-lg-6 mb-2">
                            <label className="form_label mb-0">
                              Date of Payment{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <DatePicker
                                autoOk={true}
                                inputVariant="outlined"
                                variant="inline"
                                margin="dense"
                                fullWidth
                                format="dd/MM/yyyy"
                                value={this.state.payment_date}
                                error={this.state.paymentDateError}
                                onChange={(date) =>
                                  this.DateOfPaymentHandler(date)
                                }
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <IconButton>
                                        <EventIcon />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                  className: "pl-0",
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          </div>

                          <div className="col-lg-6 mb-2">
                            <label className="form_label mb-0">
                              Amount <span style={{ color: "red" }}>*</span>
                            </label>
                            <TextField
                              name="amount_in_payment_currency"
                              margin="dense"
                              variant="outlined"
                              placeholder="Amount"
                              required
                              value={this.state.amount_in_payment_currency}
                              error={this.state.amountError}
                              fullWidth
                              onChange={(e) => {
                                const pr = /^\d*(\.\d{0,4})?$/;
                                if (
                                  e.target.value === "" ||
                                  pr.test(e.target.value)
                                ) {
                                  var amount = 0;
                                  if (
                                    ((config.company === "SRPL" || config.company === "AVS"  ||
                                      config.company === "PRPL") &&
                                      this.state.payment_currency !== "USD") ||
                                    ((config.company === "SDAM" ||
                                      config.company === "SRK" ||
                                      config.company === "BTR") &&
                                      this.state.payment_currency !== "IDR")
                                  ) {
                                    amount =
                                      Number(e.target.value) *
                                      Number(
                                        this.state.currency_xchg_rate
                                          ? this.state.currency_xchg_rate
                                          : 1
                                      );
                                  } else {
                                    amount = e.target.value;
                                  }
                                  this.setState({
                                    amount_in_payment_currency: e.target.value,
                                    amount: amount,
                                    amountError: false,
                                    paymentErrorMsg: "",
                                  });
                                }
                              }}
                            />
                          </div>
                          <div className="col-lg-6 mb-2">
                            <label className="form_label mb-0">
                              Currency <span style={{ color: "red" }}>*</span>
                            </label>
                            <TextField
                              name="payment_currency"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              value={this.state.payment_currency}
                              error={this.state.payment_currencyError}
                              onChange={(e) => {
                                var amount = 0;
                                if (
                                  ((config.company === "SRPL" || config.company === "AVS"  ||
                                    config.company === "PRPL") &&
                                    e.target.value !== "USD") ||
                                  ((config.company === "SDAM" ||
                                    config.company === "SRK" ||
                                    config.company === "BTR") &&
                                    e.target.value !== "IDR")
                                ) {
                                  amount =
                                    Number(
                                      this.state.amount_in_payment_currency
                                    ) *
                                    Number(
                                      this.state.currency_xchg_rate
                                        ? this.state.currency_xchg_rate
                                        : 1
                                    );
                                } else {
                                  amount =
                                    this.state.amount_in_payment_currency;
                                }
                                this.setState({
                                  payment_currency: e.target.value,
                                  payment_currencyError: false,
                                  amount: amount,
                                  currency_xchg_rate: "",
                                });
                              }}
                              select
                            >
                              {this.state.currencyData}
                            </TextField>
                          </div>
                          {(((config.company === "SRPL" || config.company === "AVS"  ||
                            config.company === "PRPL") &&
                            this.state.payment_currency !== "USD" &&
                            this.state.payment_currency !== "0") ||
                            ((config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") &&
                              this.state.payment_currency !== "IDR" &&
                              this.state.payment_currency !== "0")) && (
                            <div className="col-lg-6 mb-2">
                              <label className="form_label mb-0">
                                Exchange Rate from {this.state.payment_currency}{" "}
                                to{" "}
                                {config.company === "SRPL" || config.company === "AVS"  ||
                                config.company === "PRPL"
                                  ? "USD"
                                  : "IDR"}
                              </label>
                              <TextField
                                name="currency_xchg_rate"
                                margin="dense"
                                variant="outlined"
                                placeholder="Exchange Rate"
                                required
                                value={this.state.currency_xchg_rate}
                                // error={this.state.amountError}
                                fullWidth
                                onChange={(e) => {
                                  const pr = /^\d*(\.\d{0,10})?$/;
                                  if (
                                    e.target.value === "" ||
                                    pr.test(e.target.value)
                                  ) {
                                    this.setState({
                                      currency_xchg_rate: e.target.value,
                                      amount:
                                        Number(e.target.value) *
                                        Number(
                                          this.state.amount_in_payment_currency
                                            ? this.state
                                                .amount_in_payment_currency
                                            : 1
                                        ),
                                      //amountError: false,
                                      //paymentErrorMsg: ""
                                    });
                                  }
                                }}
                              />
                            </div>
                          )}

                          <div className="col-lg-6 mb-2">
                            <label className="form_label mb-0">
                              Amount in{" "}
                              {config.company === "SRPL" || config.company === "AVS"  ||
                              config.company === "PRPL"
                                ? "USD"
                                : "IDR"}
                            </label>
                            <div>
                              <label
                                className="col mt-3 pl-0"
                                style={{ color: "#483b34", fontSize: "14px" }}
                              >
                                {this.state.amount}
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-2">
                            <label className="form_label mb-0">
                              Invoice No
                            </label>
                            <TextField
                              name="invoice"
                              margin="dense"
                              variant="outlined"
                              placeholder="invoice"
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                              value={this.state.invoice}
                              onChange={(e) =>
                                this.setState({ invoice: e.target.value })
                              }
                              fullWidth
                            />
                          </div>
                          <div className="col-lg-6 mb-2">
                            <label className="form_label mb-0">Remarks</label>
                            <TextField
                              name="addPaymentRemarks"
                              margin="dense"
                              variant="outlined"
                              placeholder="Remarks"
                              multiline
                              rows={3}
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                              value={this.state.addPaymentRemarks}
                              onChange={(e) =>
                                this.setState({
                                  addPaymentRemarks: e.target.value,
                                })
                              }
                              fullWidth
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row bottom_buttons_section">
                      <button
                        type="button"
                        className="btn previous_button"
                        name="submit"
                        data-dismiss="modal"
                        onClick={() =>
                          this.setState({
                            paymentDateError: false,
                            amountError: false,
                            payment_currencyError: false,
                            payment_date: null,
                            amount: "",
                            invoice: "",
                            payment_currency: "0",
                            currency_xchg_rate: "",
                            amount_in_payment_currency: "",
                            addPaymentRemarks: "",
                          })
                        }
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn next_button"
                        name="Back"
                        onClick={this.addAdvancePaymentHandler}
                      >
                        Submit
                      </button>
                    </div>
                    {/* <div className="modal-footer border-0">
                      <button type="button" className="btn btn-primary" onClick={this.addAdvancePaymentHandler}>Submit</button>&emsp;
                      <button type="button" className="btn btn-secondary" data-dismiss="modal"
                        onClick={() =>
                          this.setState({
                            paymentDateError: false,
                            amountError: false,
                            payment_currencyError: false,
                            payment_date: null,
                            amount: '',
                            invoice: '',
                            payment_currency: "0",
                            currency_xchg_rate: "",
                            amount_in_payment_currency: "",
                            addPaymentRemarks: ""
                          })
                        }
                      >Cancel</button>
                    </div> *
                  </div>
                </div>
              </div> */}

              {/* <div
                className="modal fade"
                id="updateAdvancePayment"
                role="dialog"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content payment-modal">
                    <div
                      className="modal-header border-0"
                      style={{ backgroundColor: "#f1f1f1" }}
                    >
                      <h6
                        className="modal-title"
                        style={{ border: "none", padding: 0 }}
                      >
                        Update Vendor Advance Payment
                      </h6>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                      >
                        &times;
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="col">
                        <div
                          style={{
                            fontSize: 14,
                            color: "red",
                            textTransform: "capitalize",
                            textAlign: "center",
                          }}
                        >
                          {this.state.paymentErrorMsg}
                        </div>
                        <div className="row">
                          <div className="col-lg-6 mb-2">
                            <label className="form_label mb-0">
                              Date of Payment{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <DatePicker
                                autoOk={true}
                                inputVariant="outlined"
                                variant="inline"
                                margin="dense"
                                fullWidth
                                format="dd/MM/yyyy"
                                value={this.state.payment_date}
                                error={this.state.paymentDateError}
                                onChange={(date) =>
                                  this.DateOfPaymentHandler(date)
                                }
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <IconButton>
                                        <EventIcon />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          </div>

                          <div className="col-lg-6 mb-2">
                            <label className="form_label mb-0">
                              Amount <span style={{ color: "red" }}>*</span>
                            </label>
                            <TextField
                              name="amount_in_payment_currency"
                              margin="dense"
                              variant="outlined"
                              placeholder="Amount"
                              required
                              value={this.state.amount_in_payment_currency}
                              error={this.state.amountError}
                              fullWidth
                              onChange={(e) => {
                                const pr = /^\d*(\.\d{0,4})?$/;
                                if (
                                  e.target.value === "" ||
                                  pr.test(e.target.value)
                                ) {
                                  var amount = 0;
                                  if (
                                    ((config.company === "SRPL" || config.company === "AVS"  ||
                                      config.company === "PRPL") &&
                                      this.state.payment_currency !== "USD") ||
                                    ((config.company === "SDAM" ||
                                      config.company === "SRK" ||
                                      config.company === "BTR") &&
                                      this.state.payment_currency !== "IDR")
                                  ) {
                                    amount =
                                      Number(e.target.value) *
                                      Number(
                                        this.state.currency_xchg_rate
                                          ? this.state.currency_xchg_rate
                                          : 1
                                      );
                                  } else {
                                    amount = e.target.value;
                                  }
                                  this.setState({
                                    amount_in_payment_currency: e.target.value,
                                    amount: amount,
                                    amountError: false,
                                    paymentErrorMsg: "",
                                  });
                                }
                              }}
                            />
                          </div>
                          <div className="col-lg-6 mb-2">
                            <label className="form_label mb-0">
                              Currency <span style={{ color: "red" }}>*</span>
                            </label>
                            <TextField
                              name="payment_currency"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              value={this.state.payment_currency}
                              error={this.state.payment_currencyError}
                              onChange={(e) => {
                                var amount = 0;
                                if (
                                  ((config.company === "SRPL" || config.company === "AVS"  ||
                                    config.company === "PRPL") &&
                                    e.target.value !== "USD") ||
                                  ((config.company === "SDAM" ||
                                    config.company === "SRK" ||
                                    config.company === "BTR") &&
                                    e.target.value !== "IDR")
                                ) {
                                  amount =
                                    Number(
                                      this.state.amount_in_payment_currency
                                    ) *
                                    Number(
                                      this.state.currency_xchg_rate
                                        ? this.state.currency_xchg_rate
                                        : 1
                                    );
                                } else {
                                  amount =
                                    this.state.amount_in_payment_currency;
                                }
                                this.setState({
                                  payment_currency: e.target.value,
                                  payment_currencyError: false,
                                  amount: amount,
                                  currency_xchg_rate: "",
                                });
                              }}
                              select
                            >
                              {this.state.currencyData}
                            </TextField>
                          </div>
                          {(((config.company === "SRPL" || config.company === "AVS"  ||
                            config.company === "PRPL") &&
                            this.state.payment_currency !== "USD" &&
                            this.state.payment_currency !== "0") ||
                            ((config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") &&
                              this.state.payment_currency !== "IDR" &&
                              this.state.payment_currency !== "0")) && (
                            <div className="col-lg-6 mb-2">
                              <label className="form_label mb-0">
                                Exchange Rate from {this.state.payment_currency}{" "}
                                to{" "}
                                {config.company === "SRPL" || config.company === "AVS"  ||
                                config.company === "PRPL"
                                  ? "USD"
                                  : "IDR"}
                              </label>
                              <TextField
                                name="currency_xchg_rate"
                                margin="dense"
                                variant="outlined"
                                placeholder="Exchange Rate"
                                required
                                value={this.state.currency_xchg_rate}
                                // error={this.state.amountError}
                                fullWidth
                                onChange={(e) => {
                                  const pr = /^\d*(\.\d{0,10})?$/;
                                  if (
                                    e.target.value === "" ||
                                    pr.test(e.target.value)
                                  ) {
                                    this.setState({
                                      currency_xchg_rate: e.target.value,
                                      amount:
                                        Number(e.target.value) *
                                        Number(
                                          this.state.amount_in_payment_currency
                                            ? this.state
                                                .amount_in_payment_currency
                                            : 1
                                        ),
                                      //amountError: false,
                                      //paymentErrorMsg: ""
                                    });
                                  }
                                }}
                              />
                            </div>
                          )}

                          <div className="col-lg-6 mb-2">
                            <label className="form_label mb-0">
                              Amount in{" "}
                              {config.company === "SRPL" || config.company === "AVS"  ||
                              config.company === "PRPL"
                                ? "USD"
                                : "IDR"}
                            </label>
                            <div>
                              <label
                                className="col mt-3 pl-0"
                                style={{ color: "#483b34", fontSize: "14px" }}
                              >
                                {this.state.amount}
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-2">
                            <label className="form_label mb-0">
                              Invoice No
                            </label>
                            <TextField
                              name="invoice"
                              margin="dense"
                              variant="outlined"
                              placeholder="invoice"
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                              value={this.state.invoice}
                              onChange={(e) =>
                                this.setState({ invoice: e.target.value })
                              }
                              fullWidth
                            />
                          </div>
                          <div className="col-lg-6 mb-2">
                            <label className="form_label mb-0">Remarks</label>
                            <TextField
                              name="addPaymentRemarks"
                              margin="dense"
                              variant="outlined"
                              placeholder="Remarks"
                              multiline
                              rows={3}
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                              value={this.state.addPaymentRemarks}
                              onChange={(e) =>
                                this.setState({
                                  addPaymentRemarks: e.target.value,
                                })
                              }
                              fullWidth
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row bottom_buttons_section">
                      <button
                        type="button"
                        className="btn previous_button"
                        name="submit"
                        data-dismiss="modal"
                        onClick={() =>
                          this.setState({
                            paymentDateError: false,
                            amountError: false,
                            payment_currencyError: false,
                            payment_date: null,
                            amount: "",
                            payment_currency: "0",
                            currency_xchg_rate: "",
                            amount_in_payment_currency: "",
                            invoice: "",
                            addPaymentRemarks: "",
                            paymentErrorMsg: "",
                          })
                        }
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn next_button"
                        name="Back"
                        onClick={() =>
                          this.updateVendorAdvancePayment(
                            this.state.updateAdvancePayment
                          )
                        }
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div> */}

              {/* <div
                className="modal fade"
                id="deleteAdvancePayment"
                role="dialog"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div
                      className="modal-header border-0"
                      style={{ backgroundColor: "#f1f1f1" }}
                    >
                      <h6
                        className="modal-title"
                        style={{ border: "none", padding: 0 }}
                      >
                        Are you sure to delete Vendor Advance Payment?
                      </h6>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                      >
                        &times;
                      </button>
                    </div>
                    <div className="modal-footer border-0">
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() =>
                          this.deleteVendorAdvancePayment(
                            this.state.deleteAdvancePayment
                          )
                        }
                      >
                        Delete
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          )}
        </div>
      </div>
    );
  }
}
