import React, { Component } from "react";
import api from "../../../api/api";

import CookieHandler from "../../common/CookieHandler";
import Drawer from "@material-ui/core/Drawer";

import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import config from "../../../config/config";

import AddSalesPI from "./PI-Add";
import ViewSalesPI from "./PI-View";
import UpdateSalesPI from "./PI-Update";
import DeleteConfirmation from "../../common/DeleteConfirmation";
import Loader from "../../common/Loader";

export default class SalesPIList extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      showDrawer: false,
      isLoading: true,
      coalCostingAdd: false,
      coalCostingView: false,
      coalCostingUpdate: false,
      selected_sc: { name: "", value: "" },
      performaCostingList: [],
      submitting: false,
      updateDocuments: false,
      features: [],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    var features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const business_no_id = this.props.businessNoID;
    api
      .get_mv_sales_performa_coal_costings(loginUserID, idToken, business_no_id)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.mv_sales_performa) {
                this.setState({
                  performaCostingList: res.mv_sales_performa,
                  submitting: false,
                  isLoading: false,
                });
              }
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  }

  CallbackDrawerClose = (childData) => {
    this.setState(
      {
        showDrawer: childData,

        coalCostingAdd: false,
        coalCostingView: false,
        updateDocuments: false,
      },
      () => {
        this.componentDidMount();
      }
    );
  };

  onEdit = (data) => {
    this.setState({
      showDrawer: true,
      coalCostingAdd: false,
      coalCostingUpdate: true,
      coalCostingView: false,
    });
  };

  onAddResponse = (value) => {
    this.setState(
      {
        coalCostingAdd: false,
        showDrawer: false,
      },
      () => {
        this.componentDidMount();
      }
    );
  };

  onUpdateResponse = (value) => {
    if (value === "200") {
      this.setState(
        {
          showDrawer: true,
          coalCostingAdd: false,
          coalCostingView: true,
          coalCostingUpdate: false,
          updateDocuments: false,
        },
        () => {
          this.componentDidMount();
        }
      );
    }
  };

  CallbackViewPage = (childData) => {
    this.setState({
      showDrawer: true,
      coalCostingView: true,
      coalCostingUpdate: false,
      updateDocuments: false,
    });
  };

  addDocuments = () => {
    this.setState({
      showDrawer: true,
      coalCostingUpdate: true,
      coalCostingView: false,
      coalCostingAdd: false,
      updateDocuments: true,
    });
  };

  toLocaleString(value) {
    return value ? Number(value).toLocaleString() : 0;
  }

  deletePerformaCoalCostingHandler = (data) => {
    data["login_user_id"] = this.Cookie.getCookie("loginUserId");
    data["idtoken"] = this.Cookie.getIdTokenCookie();
    data["status"] = "Delete";
    data["advance_adjustments"] = [];
    data["mv_sales_costing_id"] = data.id;

    api.delete_mv_sales_performa_coal_costing(data).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.setState(
              {
                deleteCosting: false,
                snackBarSuccessOpen: true,
                successMsg: res.message,
              },
              () => {
                this.componentDidMount();
              }
            );
          } else if (res.code === "601") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          } else if (res.code === "651") {
            this.setState({
              deleteCosting: false,
              snackBarErrorOpen: true,
              errorMsg: res.message,
            });
          }
        });
      }
    });
  };

  render() {
    let drawerComponent;
    if (this.state.coalCostingAdd) {
      drawerComponent = (
        <AddSalesPI
          businessNoID={this.props.businessNoID}
          onAddResponse={this.onAddResponse}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
        />
      );
    }
    if (this.state.coalCostingView && this.state.comboID) {
      drawerComponent = (
        <ViewSalesPI
          mv_sales_costing_id={this.state.comboID}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          onEdit={this.onEdit}
          returnDocumentsHandler={this.addDocuments}
        />
      );
    }
    if (this.state.coalCostingUpdate && this.state.comboID) {
      drawerComponent = (
        <UpdateSalesPI
          mv_sales_costing_id={this.state.comboID}
          businessNoID={this.props.businessNoID}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.CallbackViewPage}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }
    return (
      <div id="" className="col-lg row p-2">
        <div className="content">
          {this.state.isLoading && <Loader />}
          <div
            className="content-header"
            style={{ width: "-webkit-fill-available" }}
          >
            <div className="row col-sm text-left pl-0">
              <h6 className="p-2">{`Sales Performa Coal Costing - ${
                this.props.mother_vessel_name
                  ? this.props.mother_vessel_name
                  : ""
              } ${this.props.name ? `( ${this.props.name} )` : ""}`}</h6>
              {(this.state.features.includes("190") ||
                this.state.features.includes("97")) && (
                <i
                  onClick={() =>
                    this.setState({ showDrawer: true, coalCostingAdd: true })
                  }
                  className="fa fa-plus-circle mr-2 mt-2"
                  aria-hidden="true"
                  style={{
                    fontSize: 22,
                    color: config.themeColor,
                    cursor: "pointer",
                  }}
                ></i>
              )}
            </div>
          </div>
          <Drawer
            anchor="right"
            open={this.state.showDrawer}
            variant="temporary"
            elevation={20}
            style={{ overflow: "initial" }}
          >
            <div className="row" style={{ width: 800 }}>
              {drawerComponent}
            </div>
          </Drawer>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <div className="card p-2">
            {this.state.performaCostingList.length > 0 ? (
              <div>
                <div className="shadow-sm p-2 mb-2 bg-white border">
                  <div className="row">
                    <div className="table-responsive">
                      <table className="table table-bordered table-sm">
                        <thead className="table-header">
                          <tr>
                            <th
                              nowrap="true"
                              style={{ textAlign: "center", minWidth: 90 }}
                            ></th>
                            <th nowrap="true">Costing ID</th>
                            <th nowrap="true">Sales Contract</th>
                            <th nowrap="true">Business No</th>
                            <th nowrap="true" className="text-right">
                              Quantity
                            </th>
                            <th nowrap="true" className="text-right">
                              Price PMT
                            </th>
                            <th nowrap="true" className="text-right">
                              Coal Price
                            </th>
                            <th nowrap="true" className="text-right">
                              {" "}
                              VAT{" "}
                            </th>
                            <th nowrap="true" className="text-right">
                              PPH22{" "}
                            </th>
                            <th nowrap="true" className="text-right">
                              Advance Adjustment
                            </th>
                            <th nowrap="true" className="text-right">
                              Total Receivable
                            </th>
                            <th nowrap="true" className="text-right">
                              Received
                            </th>
                            <th nowrap="true" className="text-right">
                              Remaining
                            </th>
                          </tr>
                        </thead>
                        {this.state.performaCostingList.map((item, idx) => (
                          <tbody key={idx}>
                            <tr>
                              <td
                                className="table_td"
                                style={{ borderRight: "1px solid #dee2e6" }}
                              >
                                <i
                                  className="fa fa-eye mr-2"
                                  aria-hidden="true"
                                  style={{
                                    fontSize: 22,
                                    color: config.themeColor,
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    this.setState({
                                      coalCostingView: true,
                                      comboID: item.id,
                                      showDrawer: true,
                                    })
                                  }
                                ></i>
                                {(this.state.features.includes("129") ||
                                  this.state.features.includes("97")) && (
                                  <i
                                    className="fa fa-trash-o"
                                    style={{
                                      fontSize: 20,
                                      color: config.themeColor,
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      this.setState({
                                        deleteCosting: false,
                                        deleting_barge_sales_fin_id: item,
                                        closeDialog: true,
                                      })
                                    }
                                  ></i>
                                )}
                              </td>
                              <td nowrap="true">{item.costing_no}</td>

                              <td nowrap="true">
                                <a
                                  href={
                                    "/view-sales-contract/" +
                                    btoa(item.sale_contract_id)
                                  }
                                  style={{ color: config.themeColor }}
                                >
                                  {item.sales_contract_no}
                                </a>
                              </td>
                              <td nowrap="true">{item.business_no}</td>
                              <td className="text-right" nowrap="true">
                                {this.toLocaleString(item.barge_quantity)}
                              </td>
                              <td className="text-right" nowrap="true">
                                {this.toLocaleString(item.price_pmt) +
                                  " " +
                                  item.sales_currency}
                              </td>
                              <td className="text-right" nowrap="true">
                                {this.toLocaleString(item.coal_price) +
                                  " " +
                                  item.billing_currency}
                              </td>
                              <td className="text-right" nowrap="true">
                                {this.toLocaleString(item.vat_value)}
                              </td>
                              <td className="text-right" nowrap="true">
                                {this.toLocaleString(item.pph_value)}
                              </td>
                              <td className="text-right" nowrap="true">
                                {this.toLocaleString(item.advance_adjustment)}
                              </td>
                              <td className="text-right" nowrap="true">
                                {this.toLocaleString(
                                  item.receivable_from_supplier
                                )}
                              </td>
                              <td className="text-right" nowrap="true">
                                {this.toLocaleString(item.paid)}
                              </td>
                              <td className="text-right" nowrap="true">
                                {this.toLocaleString(item.remaining_amount)}
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <span className="contract_display_header_value pt-2">
                  No costings made
                </span>
              </div>
            )}
            <br />
          </div>
        </div>
        {/**
         *
         * Delete Payament Postings Conformation Modal
         *
         */}
        {this.state.deleteCosting && (
          <DeleteConfirmation
            open={this.state.deleteCosting}
            close={() => this.setState({ deleteCosting: false })}
            callbackFn={() =>
              this.deletePerformaCoalCostingHandler(
                this.state.deleting_barge_sales_fin_id
              )
            }
          />
        )}
      </div>
    );
  }
}
