import React, { Component } from "react";
import api from "../../../api/api";
import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import { Alert } from "@material-ui/lab";

import { Snackbar } from "@material-ui/core";
import { localDateFormate } from "../../common/common";
import ViewFiles from "../../common/ViewFiles";
import InvoiceLink from "../../InvoicePdf/InvoiceLink";
import InvoiceForm from "../../common/InvoiceForm";
import PaymentPostings from "../../common/PaymentPostings";
import { toLocaleString } from "../../common/common";
import Loader from "../../common/Loader";
import BusinessApproveButton from "../../Approvals/BusinessApproveButton";
export default class RetuenCargoView extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      activeStep: 0,
      isLoading: true,
      features: [],
      barges: [],
      barge_id: null,
      dc_vendor_id: "",
      dc_price_type: "",
      dc_quantity_in_mt: "",
      dc_price_per_mt: "",
      dc_base_price: "",
      dc_vat_percent: 10,
      dc_vat_value: "",
      dc_pph23_percent: 2,
      dc_pph23_value: "",
      dc_total: "",
      dc_ctc: "",
      billing_currency: "0",
      dc_currency_xchg_rate: "",
      dc_ctc_in_local_currency: "",
      et_vendor_id: "",
      et_price_type: "",
      et_quantity_in_mt: "",
      et_price_per_mt: "",
      et_base_price: "",
      et_vat_percent: 10,
      et_vat_value: "",
      et_pph23_percent: 2,
      et_pph23_value: "",
      et_total: "",
      et_ctc: "",
      et_currency: "0",
      et_currency_xchg_rate: "",
      et_ctc_in_local_currency: "",
      invoice_no: "",
      invoice_date: null,
      return_cargo_payments: [],
      files: [],
      total_payable_to_vendor: 0,
      paid_to_vendor: 0,
      remaining_amount: 0,
      invoiceDetails: {
        invoice_no: "",
        invoice_date: null,
        ppn: "",
        ppn_date: null,
      },
      getBarges: [],
      approved_by_name: "",
      approved_date: "",
      approved_date_2: "",
      approved_by_name_2: "",
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (!(features.includes("538") || features.includes("97"))) {
      window.location.href = "/dashboard";
    }
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();

    const return_cargo_charges_id = this.props.returnCargoID;

    await api
      .get_return_cargo_charges_payments(
        loginUserID,
        idToken,
        return_cargo_charges_id
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.payment_posting) {
                let paid_to_vendor = 0;
                for (var i of res.payment_posting) {
                  if (i.date_of_payment) {
                    paid_to_vendor += Number(i.amount);
                  }
                }
                this.setState({
                  paid_to_vendor: paid_to_vendor,
                  return_cargo_payments: res.payment_posting,
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
                isLoading: false,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });

    await api
      .get_return_cargo_charges(loginUserID, idToken, return_cargo_charges_id)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.return_cargo_charges_data) {
                let remaining_amount = 0;
                let total_payable_to_vendor = 0;

                total_payable_to_vendor =
                  Number(
                    res.return_cargo_charges_data.dc_total
                      ? res.return_cargo_charges_data.dc_total
                      : 0
                  ) +
                  Number(
                    res.return_cargo_charges_data.et_total
                      ? res.return_cargo_charges_data.et_total
                      : 0
                  ) +
                  Number(
                    res.return_cargo_charges_data.ls_total
                      ? res.return_cargo_charges_data.ls_total
                      : 0
                  );
                remaining_amount =
                  Number(total_payable_to_vendor) -
                  Number(this.state.paid_to_vendor);

                let invoice_details = {
                  ...this.state.invoiceDetails,
                  invoice_no: res.return_cargo_charges_data.invoice_no,
                  invoice_date: res.return_cargo_charges_data.invoice_date,
                  ppn: res.return_cargo_charges_data.ppn,
                  ppn_date: res.return_cargo_charges_data.ppn_date,
                };
                this.setState(
                  {
                    invoiceDetails: invoice_details,
                    total_payable_to_vendor: total_payable_to_vendor,
                    remaining_amount: remaining_amount,

                    vendor_name: res.return_cargo_charges_data.vendor_name,
                    barge_id: res.return_cargo_charges_data.barge_id,
                    invoice_file: res.return_cargo_charges_data.invoice_file,
                    costing_no: res.return_cargo_charges_data.costing_no,
                    dc_price_type: res.return_cargo_charges_data.dc_price_type,
                    dc_quantity_in_mt:
                      res.return_cargo_charges_data.dc_quantity_in_mt,
                    dc_price_per_mt:
                      res.return_cargo_charges_data.dc_price_per_mt,
                    dc_lumpsum_price:
                      res.return_cargo_charges_data.dc_lumpsum_price,
                    dc_base_price: res.return_cargo_charges_data.dc_base_price,
                    dc_vat_percent:
                      res.return_cargo_charges_data.dc_vat_percent,
                    dc_vat_value: res.return_cargo_charges_data.dc_vat_value,
                    dc_pph23_percent:
                      res.return_cargo_charges_data.dc_pph23_percent,
                    dc_pph23_value:
                      res.return_cargo_charges_data.dc_pph23_value,
                    dc_total: res.return_cargo_charges_data.dc_total,
                    dc_ctc: res.return_cargo_charges_data.dc_ctc,
                    billing_currency:
                      res.return_cargo_charges_data.billing_currency,
                    dc_currency_xchg_rate:
                      res.return_cargo_charges_data.dc_currency_xchg_rate,
                    dc_ctc_in_local_currency:
                      res.return_cargo_charges_data.dc_ctc_in_local_currency,

                    et_price_type: res.return_cargo_charges_data.et_price_type,
                    et_quantity_in_mt:
                      res.return_cargo_charges_data.et_quantity_in_mt,
                    et_price_per_mt:
                      res.return_cargo_charges_data.et_price_per_mt,
                    et_lumpsum_price:
                      res.return_cargo_charges_data.et_lumpsum_price,
                    et_base_price: res.return_cargo_charges_data.et_base_price,
                    et_vat_percent:
                      res.return_cargo_charges_data.et_vat_percent,
                    et_vat_value: res.return_cargo_charges_data.et_vat_value,
                    et_pph23_percent:
                      res.return_cargo_charges_data.et_pph23_percent,
                    et_pph23_value:
                      res.return_cargo_charges_data.et_pph23_value,
                    et_total: res.return_cargo_charges_data.et_total,
                    et_ctc: res.return_cargo_charges_data.et_ctc,
                    et_currency: res.return_cargo_charges_data.et_currency,
                    et_currency_xchg_rate:
                      res.return_cargo_charges_data.et_currency_xchg_rate,
                    et_ctc_in_local_currency:
                      res.return_cargo_charges_data.et_ctc_in_local_currency,

                    ls_price_type: res.return_cargo_charges_data.ls_price_type,
                    ls_quantity_in_mt:
                      res.return_cargo_charges_data.ls_quantity_in_mt,
                    ls_price_per_mt:
                      res.return_cargo_charges_data.ls_price_per_mt,
                    ls_lumpsum_price:
                      res.return_cargo_charges_data.ls_lumpsum_price,
                    ls_base_price: res.return_cargo_charges_data.ls_base_price,
                    ls_vat_percent:
                      res.return_cargo_charges_data.ls_vat_percent,
                    ls_vat_value: res.return_cargo_charges_data.ls_vat_value,
                    ls_pph23_percent:
                      res.return_cargo_charges_data.ls_pph23_percent,
                    ls_pph23_value:
                      res.return_cargo_charges_data.ls_pph23_value,
                    ls_total: res.return_cargo_charges_data.ls_total,
                    ls_ctc: res.return_cargo_charges_data.ls_ctc,
                    ls_currency: res.return_cargo_charges_data.ls_currency,
                    ls_currency_xchg_rate:
                      res.return_cargo_charges_data.ls_currency_xchg_rate,
                    ls_ctc_in_local_currency:
                      res.return_cargo_charges_data.ls_ctc_in_local_currency,

                    files: res.return_cargo_charges_data.files,
                    created_by: res.return_cargo_charges_data.created_by,
                    approved_by_name: res.return_cargo_charges_data.approved_by,
                    approved_by_name_2:
                      res.return_cargo_charges_data.approved_by_2,
                    approved_date: res.return_cargo_charges_data.approved_date,
                    approved_date_2: res.return_cargo_charges_data.approved_date_2,
                    approval_status:
                      res.return_cargo_charges_data.approval_status,
                    isLoading: false,
                  },
                  () => {
                    const barge_id = this.state.barge_id;
                    let request = {
                      login_user_id: this.Cookie.getCookie("loginUserId"),
                      idtoken: this.Cookie.getIdTokenCookie(),
                      barge_id: barge_id,
                      vendor_type: "Barge",
                    };
                    api
                      .get_business_no_info_for_barge(request)
                      .then((response) => {
                        if (response.status >= 200 && response.status < 300) {
                          response.json().then((res) => {
                            if (res.code === "200") {
                              this.setState({
                                vendor_name: res.vendor_name,
                                mv_name: res.mv_name,
                              });
                            }
                          });
                        }
                      });
                  }
                );
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                snackBarErrorOpen: true,
                isLoading: false,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
    await api.get_barges(loginUserID, idToken).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            if (res.barges) {
              let newData = [];
              var result = res.barges.reduce(function (r, a) {
                r[a.id] = r[a.id] || [];
                r[a.id].push(a);
                return r;
              }, Object.create(null));
              for (let l = 0; l < Object.keys(result).length; l++) {
                newData.push({
                  new_barges: result[Object.keys(result)[l]],
                });
              }
              let purchase_contracts_barges = newData;
              if (newData.length === 0) {
                this.setState({
                  snackBarErrorOpen: true,
                  errorMsg: "No records to display",
                });
              }
              this.setState({
                getBarges: purchase_contracts_barges,
                id: res.barges[0].id,
                //isLoading: false
              });
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          } else if (res.code === "601") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
  }

  toLocaleString(value) {
    return value ? Number(value).toLocaleString() : 0;
  }

  updateInvoice = (data) => {
    let request = {
      ...data,
      table_name: "return_cargo_charges",
    };
    api.update_invoice_details(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.componentDidMount();
            this.setState({
              invoiceDialog: false,
            });
          }
        });
      }
    });
  };

  renderPaymentPostings = (data) => {
    const {
      return_cargo_payments = [],
      remaining_amount = 0,
      sac_sampling_currency_xchg_rate = 1,
      approval_status = "Pending",
    } = data;
    const { invoiceDetails: { invoice_no = null } = {} } = data;
    let payment_related_data = {
      paymentTitle: "Return Cargo",
      payment_posting_type: "Return_Cargo",
      invoice_no: invoice_no,
      outstanding_amount: remaining_amount,
      billing_currency:
        config.company === "SRPL" || config.company === "AVS" || config.company === "PRPL" ? "USD" : "IDR",
      currency_xchg_rate: sac_sampling_currency_xchg_rate
        ? sac_sampling_currency_xchg_rate
        : 1,
      costing_id: this.props.returnCargoID,
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
    };
    let access_details = {
      costing_approval: approval_status,
      add_payment: "543",
      view_payment: "544",
      update_payment: "545",
      delete_payment: "546",
      approver: "547",
      approver2: "548",
    };

    return (
      <PaymentPostings
        payments={return_cargo_payments}
        access_details={access_details}
        costingType="Return Cargo Charges"
        data={payment_related_data}
        callbackFn={() => {
          this.componentDidMount();
        }}
      />
    );
  };

  renderInvoiceLink = () => {
    const data = this.state.getBarges.map((e) =>
      e.new_barges.filter((obj) => obj.id === this.state.barge_id)
    );
    const { costing_no, billing_currency } = this.state;

    const Grand_total =
      Number(this.state.dc_total ? this.state.dc_total : 0) +
      Number(this.state.et_total ? this.state.et_total : 0) +
      Number(this.state.ls_total ? this.state.ls_total : 0);
    const invoiceObject = {
      costing_id: costing_no,
      to: this.state.vendor_name,
      business_no: this.state.mv_name,
      costing_name: "Return Cargo Charges",
      invoice_no: this.state.invoiceDetails.invoice_no,
      invoice_date: localDateFormate(this.state.invoiceDetails.invoice_date),
      bargeDetails: data ? data.flat() : [],
      billing_currency: billing_currency,
      total_payable_amount: toLocaleString(Grand_total),
      purchase_type: "FOB Barge",
      description: [
        {
          qty: toLocaleString(this.state.dc_quantity_in_mt),
          adjustedPrice: toLocaleString(this.state.dc_price_per_mt),
          desc: `${this.state.dc_currency_xchg_rate > 1
            ? `documentation charges @ ${parseFloat(
              this.state.dc_currency_xchg_rate
            ).toFixed(2)}`
            : "documentation charges "
            }`,
          amount: this.state.dc_base_price,
        },
        {
          qty: " ",
          adjustedPrice: " ",
          desc: "VAT",
          amount: `${toLocaleString(this.state.dc_vat_value)}`,
        },
        ...(!(config.company === "SRPL" || config.company === "AVS" || config.company === "PRPL")
          ? [
            {
              qty: " ",
              adjustedPrice: " ",
              desc: "PPH",
              amount: `( ${toLocaleString(this.state.dc_pph23_value)} )`,
            },
          ]
          : []),
        //export charges
        {
          qty: toLocaleString(this.state.et_quantity_in_mt),
          adjustedPrice: toLocaleString(this.state.et_price_per_mt),
          desc: `${this.state.et_currency_xchg_rate > 0
            ? `Export Charges @ ${parseFloat(
              this.state.et_currency_xchg_rate
            ).toFixed(2)}`
            : "Export Charges"
            }`,
          amount: this.state.et_base_price,
        },
        {
          qty: " ",
          adjustedPrice: " ",
          desc: "VAT",
          amount: `${toLocaleString(this.state.et_vat_value)}`,
        },
        ...(!(config.company === "SRPL" || config.company === "AVS" || config.company === "PRPL")
          ? [
            {
              qty: " ",
              adjustedPrice: " ",
              desc: "PPH",
              amount: `( ${toLocaleString(this.state.et_pph23_value)} )`,
            },
          ]
          : []),
        //laporan charges
        {
          qty: toLocaleString(this.state.ls_quantity_in_mt),
          adjustedPrice: toLocaleString(this.state.ls_price_per_mt),
          desc: `${this.state.ls_currency_xchg_rate > 0
            ? `Laporan Survey Charges @ ${parseFloat(
              this.state.ls_currency_xchg_rate
            ).toFixed(2)}`
            : "Laporan Survey Charges"
            }`,
          amount: this.state.ls_base_price,
        },
        {
          qty: " ",
          adjustedPrice: " ",
          desc: "VAT",
          amount: `${toLocaleString(this.state.ls_vat_value)}`,
        },
        ...(!(config.company === "SRPL" || config.company === "AVS" || config.company === "PRPL")
          ? [
            {
              qty: " ",
              adjustedPrice: " ",
              desc: "PPH",
              amount: `( ${toLocaleString(this.state.ls_pph23_value)} )`,
            },
          ]
          : []),
      ],
    };
    return <InvoiceLink data={invoiceObject} fileName={costing_no} />;
  };

  render() {
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div className="container bg-white p-0" style={{ minHeight: "100vh" }}>
          <div className="clearfix content-header border-bottom">
            <div className="row">
              <div className="col-lg-10 text-left pl-0 border-bottom">
                <h4 style={{ padding: "8px 20px", margin: "0px" }}>
                  Return Cargo Charges
                  <span
                    className={
                      this.state.approval_status === "Pending"
                        ? "type_2"
                        : "type_1"
                    }
                    style={{ padding: 8, marginLeft: 16 }}
                  >
                    {this.state.approval_status}
                  </span>
                </h4>
              </div>
              {this.state.approval_status === "Pending" &&
                this.state.invoice_file &&
                this.Cookie.getCookie("loginUserId") !==
                this.state.created_by &&
                this.state.approved_by !==
                this.Cookie.getCookie("loginUserId") &&
                (this.state.features.includes("540") ||
                  this.state.features.includes("541") ||
                  this.state.features.includes("97")) && (
                  <BusinessApproveButton
                    costingID={this.props.returnCargoID}
                    costingType={"Return Cargo Charges"}
                    accessInfo={{
                      ...this.state,
                      approver_1: "540",
                      approver_2: "541",
                    }}
                  />
                )}
              <div className="col-lg-1 pl-0 float-right">
                {this.renderInvoiceLink()}
              </div>
            </div>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.sucessMsg}
            </Alert>
          </Snackbar>
          <div className="drawer-section">
            <div className="card">
              <div className="card-body border_dashed">
                <div className="col-lg-12 p-0">
                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      lineHeight: "0.1em",
                      margin: "10px 0 20px",
                      borderBottom: "1px solid #dddd",
                    }}
                  >
                    <span
                      style={{
                        backgroundColor: "#fff",
                        padding: "0 10px",
                        color: "#ec7833",
                      }}
                    >
                      Documentation Charges
                    </span>
                  </p>
                  {/* DOCUMENT CHARGES */}
                  <div className="col shadow-sm p-0 mb-2 bg-white border">
                    <div className="table-responsive">
                      <table className="table table-sm table-bordered mb-0">
                        <thead className="table-header">
                          <tr>
                            <th className="text-center">Description</th>
                            <th className="text-right">Qty</th>
                            <th className="text-right">Unit Price</th>
                            <th className="text-right">Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          <>
                            <tr>
                              <td>
                                Return Cargo Charges
                                {(
                                  config.company === "SRPL" ||
                                  config.company === "AVS" ||
                                  config.company === "PRPL"
                                ) &&
                                  this.state.billing_currency !== "USD" &&
                                  this.state.billing_currency !== "0" &&
                                  this.state.dc_currency_xchg_rate > 1 && this.state.dc_currency_xchg_rate !== null && (
                                    <div>
                                      Exchange Rate @ {this.toLocaleString(this.state.dc_currency_xchg_rate)}
                                    </div>
                                  )}
                              </td>
                              <td className="text-right"> {this.state.dc_quantity_in_mt
                                ? this.toLocaleString(this.state.dc_quantity_in_mt)
                                : null}</td>
                              {this.state.dc_price_type === "Per MT" &&
                                <td className="text-right">
                                  {this.state.dc_price_per_mt
                                    ? this.toLocaleString(this.state.dc_price_per_mt)
                                    : null}{" "}
                                </td>
                              }
                              {this.state.dc_price_type === "Lumpsum" ?
                                <td className="text-right">
                                  {toLocaleString(this.state.dc_lumpsum_price)}
                                </td> :
                                <td className="text-right">
                                  {this.state.dc_base_price
                                    ? this.toLocaleString(this.state.dc_base_price)
                                    : null}
                                </td>
                              }
                            </tr>
                            {(this.state.vat_applicability === "Inclusive" ||
                              config.company !== "SRPL" ||
                              config.company !== "PRPL" ||
                              config.company !== "AVS") &&
                              this.state.dc_vat_value && (
                                <tr>
                                  <td>VAT @ {this.state.dc_vat_percent
                                    ? this.toLocaleString(
                                      this.state.dc_vat_percent
                                    )
                                    : null} %</td>
                                  <td className="text-right"></td>
                                  <td className="text-right"></td>
                                  <td className="text-right">
                                    {this.state.dc_vat_value
                                      ? this.toLocaleString(
                                        this.state.dc_vat_value
                                      )
                                      : null}
                                  </td>
                                </tr>
                              )}
                            {!(
                              config.company === "SRPL" ||
                              config.company === "AVS" ||
                              config.company === "PRPL"
                            ) && this.state.dc_pph23_value && (
                                <tr>
                                  <td>PPH23 @  {this.state.dc_pph23_percent
                                    ? this.toLocaleString(this.state.dc_pph23_percent)
                                    : null} % </td>
                                  <td></td>
                                  <td></td>
                                  <td className="text-right">(  {this.state.dc_pph23_value
                                    ? this.toLocaleString(this.state.dc_pph23_value)
                                    : null})</td>
                                </tr>
                              )}
                            <tr>
                              <td colSpan={3} className="text-right ">
                                {" "}
                                Payable to Vendor :{" "}
                              </td>
                              <td style={{ fontWeight: "bold" }} className="text-right ">
                                {`${config.company === "SRPL" ||
                                  config.company === "PRPL" ||
                                  config.company === "AVS"
                                  ? "USD"
                                  : "IDR"
                                  } ${this.state.dc_total
                                    ? this.toLocaleString(this.state.dc_total)
                                    : null}`}
                              </td>
                            </tr>
                          </>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      lineHeight: "0.1em",
                      margin: "10px 0 20px",
                      borderBottom: "1px solid #dddd",
                    }}
                  >
                    <span
                      style={{
                        backgroundColor: "#fff",
                        padding: "0 10px",
                        color: "#ec7833",
                      }}
                    >
                      Export Tax
                    </span>
                  </p>
                  {/* Export Tax */}
                  <div className="col shadow-sm p-0 mb-2 bg-white border">
                    <div className="table-responsive">
                      <table className="table table-sm table-bordered mb-0">
                        <thead className="table-header">
                          <tr>
                            <th className="text-center">Description</th>
                            <th className="text-right">Qty</th>
                            <th className="text-right">Unit Price</th>
                            <th className="text-right">Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          <>
                            <tr>
                              <td>
                                Export Tax
                                {(
                                  config.company === "SRPL" ||
                                  config.company === "AVS" ||
                                  config.company === "PRPL"
                                ) &&
                                  this.state.et_currency !== "USD" &&
                                  this.state.et_currency !== "0" &&
                                  this.state.et_currency_xchg_rate > 1 && this.state.et_currency_xchg_rate !== null && (
                                    <div>
                                      Exchange Rate @ {this.toLocaleString(this.state.et_currency_xchg_rate)}
                                    </div>
                                  )}
                              </td>
                              <td className="text-right"> {this.state.et_quantity_in_mt
                                ? toLocaleString(this.state.et_quantity_in_mt)
                                : null
                              }
                              </td>
                              {this.state.et_price_type === "Per MT" &&
                                <td className="text-right">
                                  {this.state.et_price_per_mt
                                    ? this.toLocaleString(this.state.et_price_per_mt)
                                    : null}{" "}
                                </td>
                              }
                              {this.state.et_price_type === "Lumpsum" ?
                                <td className="text-right">
                                  {toLocaleString(this.state.et_lumpsum_price)}
                                </td> :
                                <td className="text-right">
                                  {this.state.et_base_price
                                    ? this.toLocaleString(this.state.et_base_price)
                                    : null}
                                </td>
                              }
                            </tr>
                            {(this.state.vat_applicability === "Inclusive" ||
                              config.company !== "SRPL" ||
                              config.company !== "PRPL" ||
                              config.company !== "AVS") &&
                              this.state.et_vat_value && (
                                <tr>
                                  <td>VAT @ {this.state.et_vat_percent
                                    ? this.toLocaleString(
                                      this.state.et_vat_percent
                                    )
                                    : null} %</td>
                                  <td className="text-right"></td>
                                  <td className="text-right"></td>
                                  <td className="text-right">
                                    {this.state.et_vat_value
                                      ? this.toLocaleString(
                                        this.state.et_vat_value
                                      )
                                      : null}
                                  </td>
                                </tr>
                              )}
                            {!(
                              config.company === "SRPL" ||
                              config.company === "AVS" ||
                              config.company === "PRPL"
                            ) && this.state.et_pph23_value && (
                                <tr>
                                  <td>PPH23 @  {this.state.et_pph23_percent
                                    ? this.toLocaleString(this.state.et_pph23_percent)
                                    : null} % </td>
                                  <td></td>
                                  <td></td>
                                  <td className="text-right">(  {this.state.et_pph23_value
                                    ? this.toLocaleString(this.state.et_pph23_value)
                                    : null})</td>
                                </tr>
                              )}
                            <tr>
                              <td colSpan={3} className="text-right ">
                                {" "}
                                Payable to Vendor :{" "}
                              </td>
                              <td style={{ fontWeight: "bold" }} className="text-right ">
                                {`${config.company === "SRPL" ||
                                  config.company === "PRPL" ||
                                  config.company === "AVS"
                                  ? "USD"
                                  : "IDR"
                                  } ${this.state.et_total
                                    ? this.toLocaleString(this.state.et_total)
                                    : null}`}
                              </td>
                            </tr>
                          </>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      lineHeight: "0.1em",
                      margin: "10px 0 20px",
                      borderBottom: "1px solid #dddd",
                    }}
                  >
                    <span
                      style={{
                        backgroundColor: "#fff",
                        padding: "0 10px",
                        color: "#ec7833",
                      }}
                    >
                      Lapran Survey Charges
                    </span>
                  </p>
                  {/* LAPRON SURVEY CHARGES */}
                  <div className="col shadow-sm p-0 mb-2 bg-white border">
                    <div className="table-responsive">
                      <table className="table table-sm table-bordered mb-0">
                        <thead className="table-header">
                          <tr>
                            <th className="text-center">Description</th>
                            <th className="text-right">Qty</th>
                            <th className="text-right">Unit Price</th>
                            <th className="text-right">Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          <>
                            <tr>
                              <td>
                                Lapron survey
                                {(
                                  config.company === "SRPL" ||
                                  config.company === "AVS" ||
                                  config.company === "PRPL"
                                ) &&
                                  this.state.ls_currency !== "USD" &&
                                  this.state.ls_currency !== "0" &&
                                  this.state.ls_currency_xchg_rate > 1 && this.state.ls_currency_xchg_rate !== null && (
                                    <div>
                                      Exchange Rate @ {this.toLocaleString(this.state.ls_currency_xchg_rate)}
                                    </div>
                                  )}
                              </td>
                              <td className="text-right"> {this.state.ls_quantity_in_mt
                                ? toLocaleString(this.state.ls_quantity_in_mt)
                                : null
                              }
                              </td>
                              {this.state.ls_price_type === "Per MT" &&
                                <td className="text-right">
                                  {this.state.ls_price_per_mt
                                    ? this.toLocaleString(this.state.ls_price_per_mt)
                                    : null}{" "}
                                </td>
                              }
                              {this.state.ls_price_type === "Lumpsum" ?
                                <td className="text-right">
                                  {toLocaleString(this.state.ls_lumpsum_price)}
                                </td> :
                                <td className="text-right">
                                  {this.state.et_base_price
                                    ? this.toLocaleString(this.state.ls_base_price)
                                    : null}
                                </td>
                              }
                            </tr>
                            {(this.state.vat_applicability === "Inclusive" ||
                              config.company !== "SRPL" ||
                              config.company !== "PRPL" ||
                              config.company !== "AVS") &&
                              this.state.ls_vat_value && (
                                <tr>
                                  <td>VAT @ {this.state.ls_vat_percent
                                    ? this.toLocaleString(
                                      this.state.ls_vat_percent
                                    )
                                    : null} %</td>
                                  <td className="text-right"></td>
                                  <td className="text-right"></td>
                                  <td className="text-right">
                                    {this.state.ls_vat_value
                                      ? this.toLocaleString(
                                        this.state.ls_vat_value
                                      )
                                      : null}
                                  </td>
                                </tr>
                              )}
                            {!(
                              config.company === "SRPL" ||
                              config.company === "AVS" ||
                              config.company === "PRPL"
                            ) && this.state.ls_pph23_value && (
                                <tr>
                                  <td>PPH23 @  {this.state.ls_pph23_percent
                                    ? this.toLocaleString(this.state.ls_pph23_percent)
                                    : null} % </td>
                                  <td></td>
                                  <td></td>
                                  <td className="text-right">(  {this.state.ls_pph23_value
                                    ? this.toLocaleString(this.state.ls_pph23_value)
                                    : null})</td>
                                </tr>
                              )}
                            <tr>
                              <td colSpan={3} className="text-right ">
                                {" "}
                                Payable to Vendor :{" "}
                              </td>
                              <td style={{ fontWeight: "bold" }} className="text-right ">
                                {`${config.company === "SRPL" ||
                                  config.company === "PRPL" ||
                                  config.company === "AVS"
                                  ? "USD"
                                  : "IDR"
                                  } ${this.state.ls_total
                                    ? this.toLocaleString(this.state.ls_total)
                                    : null}`}
                              </td>
                            </tr>
                          </>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                {(this.state.features.includes("544") ||
                  this.state.features.includes("97")) && (
                    <div className="card col-lg-12 p-0 border mb-3">
                      <legend className="card-header section_header">
                        <h5>Return Cargo Payment Postings</h5>
                      </legend>
                      <div className="card-body p-0">
                        <InvoiceForm
                          open={false}
                          costingID={this.props.returnCargoID}
                          data={this.state.invoiceDetails}
                          callbackFn={(data) => this.updateInvoice(data)}
                        />
                        <div className="row p-2">
                          <div className="col-lg-4 mb-3">
                            <label className="contract_display_header_label">
                              Total Payable to Vendor
                            </label>
                            <div className="contract_display_header_value">
                              {toLocaleString(this.state.total_payable_to_vendor)}
                            </div>
                          </div>
                          <div className="col-lg-4 mb-3">
                            <label className="contract_display_header_label">
                              Paid to Vendor
                            </label>
                            <div className="contract_display_header_value">
                              {toLocaleString(this.state.paid_to_vendor)}
                            </div>
                          </div>
                          <div className="col-lg-4 mb-3">
                            <label className="contract_display_header_label">
                              Remaining Amount
                            </label>
                            <div className="contract_display_header_value">
                              {toLocaleString(
                                Number(this.state.remaining_amount)
                              )}
                            </div>
                          </div>
                        </div>
                        {this.renderPaymentPostings(this.state)}
                      </div>
                    </div>
                  )}

                <ViewFiles
                  {...this.props}
                  files_data={this.state.files}
                  type="Costings"
                  invoice_file={this.state.invoice_file}
                />
              </div>
            </div>
          </div>
          <div className="col shadow-sm p-0 mb-2 bg-white border">
            <div className="table-responsive">
              <table className="table table-sm table-bordered">
                <thead className="table-header">
                  <tr>
                    <th className="text-center">Approval Type</th>
                    <th className="text-center">Approved by</th>
                    <th className="text-center">Approved On</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-center">Business</td>
                    <td className="text-center">
                      <div> {this.state.approved_by_name}</div>
                    </td>
                    <td className="text-center">
                      {localDateFormate(this.state.approved_date)}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center">Financial</td>
                    <td className="text-center">
                      {this.state.approved_by_name_2}
                    </td>
                    <td className="text-center">
                      {localDateFormate(this.state.approved_date_2)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button"
              name="previous"
              onClick={() => this.props.onCancel(false)}
            >
              CANCEL
            </button>
            <button
              type="button"
              className="btn next_button"
              name="next"
              onClick={() => this.props.onEdit(true)}
              disabled={this.state.submitLoading}
            >
              EDIT
            </button>
          </div>
        </div>
      );
    }
  }
}
