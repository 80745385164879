import React, { Component } from 'react';
import {
  IconButton,
  InputAdornment,
  Tooltip,
  Button,
} from "@material-ui/core";
import config from "../../config/config";

import {
  dateFormateToDB,
  toLocaleString,
  localTimeFormate,
  localDateFormate
} from "../common/common";
import exportToExcel from "../Export_To_Excel";
import api from '../../api/api';

import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import EventIcon from "@material-ui/icons/Event";
import DateFnsUtils from "@date-io/date-fns";
import CookieHandler from '../common/CookieHandler';
import { PAYMENTS_INFO } from "../constants/constants";

export default class AccountReceivables extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();

    this.state = {
      receivablesData: [],
      accountReceivables: [],
      features: [],
      activeTabIndex: 0,
      isLoading: false
    }
  }

  componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    this.setState({ features: features });

    const request = {
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
    }
    api.get_pending_receivable_approvals(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === '200') {
            if (res.costing_data) {

              this.setState({
                receivablesData: res.costing_data,
                accountReceivables: res.costing_data,
              }, () => {
                this.pendingFinancialApprovals();
              });
            }
          } else if (res.code === '607') {
            window.location.href = '/logout';
          }
        })
      }
    })
  }

  pendingFinancialApprovals = () => {
    if (this.state.activeTabIndex === 0) {
      const costingsCopy = JSON.parse(JSON.stringify(this.state.accountReceivables));

      const filter_pending_financial_approvals = costingsCopy.filter(e => e.approval_status_2 !== "Approved");
      this.setState({
        receivablesData: filter_pending_financial_approvals,
      })
    }
    else if (this.state.activeTabIndex === 1) {
      const costingsCopy = JSON.parse(JSON.stringify(this.state.accountReceivables));
      const pending_outstanding_costings = costingsCopy.filter(e => e.approval_status_2 === "Approved" && parseFloat(e.outstanding_amount) > 1);
      this.setState({
        receivablesData: pending_outstanding_costings
      })
    } else {
      const data = JSON.parse(JSON.stringify(this.state.accountReceivables));

      const approved_payment_costings = data
        .filter((e) => e.payments.length > 0)
        .map((element) => {
          element.payments = element.payments.filter((subElement) => {
            return subElement.date_of_payment;
          });
          return element;
        });

      const approved_payments = approved_payment_costings.filter(e => e.payments.length > 0)
      this.setState({
        receivablesData: approved_payments
      })

    }
  }

  GlobalSearch = (e) => {
    let input, filter, table, tr, td, i, j
    input = document.getElementById("search");
    filter = input.value.toUpperCase();
    table = document.getElementById("account-receivables");
    tr = table.getElementsByTagName("tr");
    for (i = 0; i < tr.length; i++) {
      td = tr[i].getElementsByTagName("td");
      for (j = 0; j < td.length; j++) {
        let tdata = td[j];
        if (tdata) {
          if (tdata.innerHTML.toUpperCase().indexOf(filter) > -1) {
            tr[i].style.display = "";
            break;
          } else {
            tr[i].style.display = "none";
          }
        }
      }
    }

  }

  update_payment_due_date = (id, type, date) => {
    const filted_data = PAYMENTS_INFO.find(
      (item) => item.costing_type === type
    );
    const { payment_table_name } = filted_data || {};
    // this.setState({ isLoading: true });
    let reqBody = {
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
      payment_table: payment_table_name,
      payment_id: id,
      payment_due_date: date,
    };
    api.update_payment_due_date(reqBody).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.setState(
              {
                isLoading: false,
                snackBarSuccessOpen: true,
                successMsg: res.message,
              },
              () => this.componentDidMount()
            );
          } else if (res.code === "601") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
              isLoading: false,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
  };

  convertExcelHandler = (data, filename, ShowLabel) => {
    var arrData = typeof data != "object" ? JSON.parse(data) : data;
    const arrayCopy = [];

    for (var i of arrData) {
      for (var j of i.payments) {
        i["amount"] = j.amount;
        i["date_of_payment"] = j.date_of_payment;
        i["payment_approval_status"] = j.payment_approval_status;
        // i["payment_approval_status_2"] = j.payment_approval_status_2;
        i["payment_approved_by"] = j.payment_approved_by;
        i["payment_approved_by_2"] = j.payment_approved_by_2;
        i["payment_approved_date"] = j.payment_approved_date;
        // i["payment_approved_date_2"] = j.payment_approved_date_2;
        i["payment_created_by"] = j.payment_created_by;
        i["payment_created_date"] = j.payment_created_date;
        i["payment_due_date"] = j.payment_due_date;
        i["payment_seq_id"] = j.payment_seq_id;
      }
      arrayCopy.push(i);
    }
    let Modified = arrayCopy.map((obj) => {
      return {
        "Costing ID": obj.costing_no,
        "Costing Type": obj.type,
        "Mother Vessel": obj.business_numbers.toString(),
        "Vendor Name": obj.vendor_name,
        "Costing Approved On": localDateFormate(obj.costing_approved_date),
        "Payable Amount": toLocaleString(obj.payable_to_supplier),
        "Outstanding Amount": toLocaleString(obj.outstanding_amount),
        "Invoice No": obj.costing_invoice_no,
        "Financial Approval": obj.costing_approved_by_2,
        "Receivable ID": obj.payment_seq_id,
        "Payment Due Date": localDateFormate(obj.payment_due_date),
        "Payment Amout": toLocaleString(obj.redraft_quantity),
        "1st level Approval":
          obj.payment_approval_status !== "Pending"
            ? obj.payment_approved_by
            : "Pending",
        "1st level Approved On": localDateFormate(obj.payment_approved_date),
        "2nd level Approval":
          obj.payment_approval_status_2 !== "Pending"
            ? obj.payment_approval_status_2
            : "Pending",
        "2nd level Approved On": localDateFormate(obj.payment_approved_date_2),
        "Time Taken for Approval": obj.payment_approved_date_2,
        "Time Taken for Payment": obj.payment_approved_date_2,
      };
    });

    exportToExcel(Modified, filename);
  };

  render() {
    return (
      <div>
        <div className='d-flex pr-3 pb-3 '>
          <div className='mr-auto pl-3'>
            <Button
              className="tab_button"
              onClick={(e, value) => {
                this.setState({
                  activeTabIndex: 0,
                }, () => this.pendingFinancialApprovals())

              }}
              style={{
                textTransform: "capitalize",
                margin: "5px 0px",
                padding: "5px 20px",
                borderRadius: 35,
                height: 30,
                color:
                  this.state
                    .activeTabIndex === 0
                    ? config.themeColor
                    : "#483b34b3",
                backgroundColor:
                  this.state
                    .activeTabIndex === 0
                    ? "#fff7f2"
                    : "#fff",
                fontSize: "14px",
                border: "0px",
              }}
            >
              Financial Approvals
            </Button>
            <Button
              className="tab_button"
              onClick={(e, value) => {
                this.setState({
                  activeTabIndex: 1,
                }, () => this.pendingFinancialApprovals())
              }}
              style={{
                textTransform: "capitalize",
                margin: "5px 0px",
                padding: "5px 20px",
                borderRadius: 35,
                height: 30,
                color:
                  this.state
                    .activeTabIndex === 1
                    ? config.themeColor
                    : "#483b34b3",
                backgroundColor:
                  this.state
                    .activeTabIndex === 1
                    ? "#fff7f2"
                    : "#fff",
                fontSize: "14px",
                border: "0px",
              }}
            >
              Approved
            </Button>
            <Button
              className="tab_button"
              onClick={(e, value) => {
                this.setState({
                  activeTabIndex: 2,
                }, () => this.pendingFinancialApprovals())
              }}
              style={{
                textTransform: "capitalize",
                margin: "5px 0px",
                padding: "5px 20px",
                borderRadius: 35,
                height: 30,
                color:
                  this.state
                    .activeTabIndex === 2
                    ? config.themeColor
                    : "#483b34b3",
                backgroundColor:
                  this.state
                    .activeTabIndex === 2
                    ? "#fff7f2"
                    : "#fff",
                fontSize: "14px",
                border: "0px",
              }}
            >
              Received
            </Button>
          </div>
          <div>
            <input type="text" className="form-control form-control-sm mt-1" id="search" placeholder="Search...." name="search" onChange={this.GlobalSearch} />
          </div>
          <div>
            <Tooltip title='Download' arrow>
              <i className="fa fa-file-excel-o ml-2 mt-2 " aria-hidden="true" style={{ color: 'green', cursor: 'pointer', fontSize: 22 }}
                onClick={() => this.convertExcelHandler(this.state.receivablesData, "Financial Approvals")}
              />
            </Tooltip>
          </div>
        </div>

        <div className='row col-lg-12 '>
          <div className='table-responsive' style={{ height: 550, overflow: 'auto' }}>
            <table className='table table-bordered table-sm' id='myTable'>
              <thead className='table-header '>
                <th nowrap="true" className='text-uppercase' style={{ position: 'sticky', top: 0, background: '#f7f3f0' }}>Costing ID</th>
                <th nowrap="true" className='text-uppercase ' style={{ position: 'sticky', top: 0, background: '#f7f3f0', minWidth: 150 }}>Costing Type</th>
                <th nowrap="true" className='text-uppercase ' style={{ position: 'sticky', top: 0, background: '#f7f3f0' }}>Mother Vessel</th>
                <th nowrap="true" className='text-uppercase ' style={{ position: 'sticky', top: 0, background: '#f7f3f0', minWidth: 150 }}>
                  Customer Name
                </th>
                <th nowrap="true" className='text-uppercase ' style={{ position: 'sticky', top: 0, background: '#f7f3f0' }}>Costing Approved On</th>
                <th nowrap="true" className='text-uppercase ' style={{ position: 'sticky', top: 0, background: '#f7f3f0' }}>Receivable</th>
                <th nowrap="true" className='text-uppercase ' style={{ position: 'sticky', top: 0, background: '#f7f3f0' }}>Outstanding</th>
                <th nowrap="true" className='text-uppercase ' style={{ position: 'sticky', top: 0, background: '#f7f3f0' }}>Financial Approve</th>
                <th nowrap="true" className='text-uppercase ' style={{ position: 'sticky', top: 0, background: '#f7f3f0' }}>Invoice No</th>
                <th nowrap="true" className='text-uppercase ' style={{ position: 'sticky', top: 0, background: '#f7f3f0' }}>Receivable Due Date</th>
                <th nowrap="true" className='text-uppercase ' style={{ position: 'sticky', top: 0, background: '#f7f3f0' }}>Receivable ID</th>
                <th nowrap="true" className='text-uppercase ' style={{ position: 'sticky', top: 0, background: '#f7f3f0' }}>Received Amount</th>

              </thead>

              <tbody >

                {this.state.receivablesData?.map((item, index) => item.payments.length > 0 ?
                  item.payments.map((p, idx) => (
                    <tr>
                      {idx === 0 &&
                        <td nowrap="true" className="freeze-pane-left" rowSpan={item.payments.length} style={{ color: config.themeColor, cursor: 'pointer', backgroundColor: "#fff7f2", }} >

                          <span
                            onClick={() => this.props.onClickCosting(item)} style={{ cursor: 'pointer' }}
                          >
                            {item.costing_no}
                          </span>
                        </td>
                      }
                      {idx === 0 &&
                        <td nowrap="true" rowSpan={item.payments.length}> {item.type}</td>
                      }
                      {idx === 0 &&
                        <td nowrap="true" rowSpan={item.payments.length}>
                          {item.business_numbers.toString()}
                        </td>
                      }
                      {idx === 0 &&

                        <td nowrap="true text-uppercase" rowSpan={item.payments.length}>{item.vendor_name}</td>
                      }{idx === 0 &&
                        <td nowrap="true" rowSpan={item.payments.length}>{localTimeFormate(item.costing_approved_date)}</td>
                      }
                      {idx === 0 &&
                        <td nowrap="true" className='text-right' rowSpan={item.payments.length}>{toLocaleString(item.payable_to_supplier)}</td>
                      }
                      {idx === 0 &&
                        <td nowrap="true" className='text-right' rowSpan={item.payments.length}>{`${toLocaleString(item.outstanding_amount)} ${config.company === "SRPL" || config.company === "PRPL" || config.company === "AVS" ? "USD" : "IDR"}`}</td>
                      }
                      {idx === 0 &&
                        <td nowrap='true' rowSpan={item.payments.length}>
                          {(item.approval_status_2 === "Pending") && this.Cookie.getCookie('loginUserId') !== item.costing_created_id && item.costing_approved_by_id !== this.Cookie.getCookie('loginUserId') && (this.state.features.includes(item.approval_access_id_2) || this.state.features.includes('97')) ?
                            <button className='header_button header_button_text add_button_adjustment' style={{ width: 'auto', top: 0, marginTop: 0 }}
                              onClick={() => this.props.approveHandler(item.id, item.type)}>Approve</button>
                            :
                            <span className='text-uppercase'>
                              {item.costing_approved_by_2 ? item.costing_approved_by_2 : "Pending"}
                            </span>
                          }
                        </td>
                      }
                      {idx === 0 &&
                        <td nowrap='true' className='text-uppercase' rowSpan={item.payments.length}>{item.costing_invoice_no}</td>
                      }

                      <td nowrap='true' className='text-right'>
                        {p.edit_due_date ? (
                          <div className="row">
                            <div className="col-lg-8 p-0">
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                  autoOk={true}
                                  inputVariant="outlined"
                                  variant="inline"
                                  margin="dense"
                                  fullWidth
                                  format="dd/MM/yyyy"
                                  value={p.payment_due_date1}
                                  onChange={(date) => {
                                    let date_value = dateFormateToDB(date);
                                    let costingsDataCopy =
                                      this.state.receivablesData;

                                    for (
                                      var i = 0;
                                      i < costingsDataCopy.length;
                                      i++
                                    ) {
                                      if (
                                        costingsDataCopy[i].costing_no ===
                                        item.costing_no
                                      ) {
                                        costingsDataCopy[i].payments[idx][
                                          "payment_due_date1"
                                        ] = date_value;
                                      }
                                    }
                                    this.setState({
                                      receivablesData: costingsDataCopy
                                    });
                                  }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <IconButton>
                                          <EventIcon />
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                    className: "p-0",
                                  }}
                                />
                              </MuiPickersUtilsProvider>
                            </div>
                            <p>
                              <Tooltip title="Save" arrow>
                                <i
                                  className="fa fa-check fa-3x edit_icon"
                                  style={{
                                    color: config.themeColor,
                                    cursor: "pointer",
                                    marginTop: 3,
                                  }}
                                  onClick={() => this.update_payment_due_date(
                                    p.payment_id,
                                    item.type,
                                    p.payment_due_date1
                                  )
                                  }
                                />
                              </Tooltip>
                            </p>
                            <p style={{ lineHeight: 3 }}>
                              <Tooltip title="Cancel" arrow>
                                <i
                                  className="fa fa-times fa-3x edit_icon"
                                  style={{
                                    color: config.themeColor,
                                    cursor: "pointer",
                                    marginTop: 3,
                                  }}
                                  onClick={() => {
                                    let costingsDataCopy =
                                      this.state.receivablesData;

                                    for (
                                      var i = 0;
                                      i < costingsDataCopy.length;
                                      i++
                                    ) {
                                      if (
                                        costingsDataCopy[i].costing_no ===
                                        item.costing_no
                                      ) {
                                        costingsDataCopy[i].payments[idx][
                                          "edit_due_date"
                                        ] = false;
                                      }
                                    }
                                    this.setState({
                                      receivablesData: costingsDataCopy
                                    })
                                  }}
                                />
                              </Tooltip>
                            </p>
                          </div>
                        ) : (
                          <p>
                            {localDateFormate(p.payment_due_date)}
                            <Tooltip title="Edit" arrow>
                              <i
                                className="fa fa-pencil edit_icon"
                                style={{
                                  color: config.themeColor,
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  let costingsDataCopy = this.state.receivablesData;
                                  for (
                                    var i = 0;
                                    i < costingsDataCopy.length;
                                    i++
                                  ) {
                                    if (
                                      costingsDataCopy[i].costing_no ===
                                      item.costing_no
                                    ) {

                                      costingsDataCopy[i].payments[idx][
                                        "edit_due_date"
                                      ] = true;
                                      costingsDataCopy[i].payments[idx][
                                        "payment_due_date1"
                                      ] =
                                        costingsDataCopy[i].payments[
                                          idx
                                        ].payment_due_date;
                                    }
                                  }

                                  this.setState({
                                    receivablesData: costingsDataCopy
                                  })
                                }}
                              />
                            </Tooltip>
                          </p>
                        )}
                      </td>
                      <td nowrap='true'>
                        {p.payment_seq_id}
                        {item.costing_approved_by_2 && (parseFloat(item.outstanding_amount) > 1) && item.costing_invoice_no && this.state.activeTabIndex === 1 &&
                          <p className='mb-0 text-center p-0'>
                            <Tooltip title="Add Payments" arrow>
                              <i
                                className="fa fa-plus-circle"
                                style={{
                                  color: config.themeColor,
                                  cursor: "pointer",
                                }}
                                onClick={() => this.props.addPayments(item)}
                              />
                            </Tooltip>
                          </p>
                        }
                      </td>
                      <td nowrap='true' className='text-right'>{`${toLocaleString(p.amount)} ${config.company === "SRPL" || config.company === "PRPL" || config.company === "AVS" ? "USD" : "IDR"}`}</td>
                    </tr>
                  ))
                  :
                  <tr>
                    <td nowrap="true" className="freeze-pane-left" style={{ color: config.themeColor, cursor: 'pointer', backgroundColor: "#fff7f2", }}
                    >
                      <span
                        style={{ color: config.themeColor }}
                        onClick={() => this.props.onClickCosting(item)}
                      >

                        {item.costing_no}
                      </span>
                    </td>
                    <td nowrap="true"> {item.type}</td>
                    <td nowrap="true">
                      {item.business_numbers.toString()}
                    </td>
                    <td nowrap="true text-uppercase">{item.vendor_name}</td>
                    <td nowrap="true">{localTimeFormate(item.costing_approved_date)}</td>
                    <td nowrap="true" className='text-right'>{toLocaleString(item.payable_to_supplier)}</td>
                    <td nowrap="true" className='text-right'>{`${toLocaleString(item.outstanding_amount)} ${config.company === "SRPL" || config.company === "PRPL" || config.company === "AVS" ? "USD" : "IDR"}`}</td>
                    <td nowrap='true'>
                      {(item.approval_status_2 === "Pending") && this.Cookie.getCookie('loginUserId') !== item.costing_created_id && item.costing_approved_by_id !== this.Cookie.getCookie('loginUserId') && (this.state.features.includes(item.approval_access_id_2) || this.state.features.includes('97')) ?
                        <button className='header_button header_button_text add_button_adjustment' style={{ width: 'auto', top: 0, marginTop: 0 }}
                          onClick={() => this.props.approveHandler(item.id, item.type)}>Approve</button>
                        :
                        <span className='text-uppercase'>
                          {item.costing_approved_by_2 ? item.costing_approved_by_2 : "Pending"}
                        </span>
                      }
                    </td>
                    <td nowrap='true' className='text-uppercase'>{item.costing_invoice_no}</td>
                    <td nowrap='true' className='text-uppercase'></td>
                    <td nowrap='true' className='text-uppercase'></td>
                    <td nowrap='true' className='text-uppercase'></td>
                  </tr>
                )}

              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}

