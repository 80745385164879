import React, { Component } from 'react';

import Autocomplete from '@material-ui/lab/Autocomplete';
import DateFnsUtils from '@date-io/date-fns';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import {
  IconButton,
  InputAdornment,
  TextField,
  Snackbar
} from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';

import api from '../../../api/api';
import config from '../../../config/config';
import SideBar from '../../common/SideBar';
import Header from '../../common/Header';
import CookieHandler from '../../common/CookieHandler';
import { Alert } from '@material-ui/lab';
import Loader from '../../common/Loader';

export default class DownPayment extends Component {
  constructor(props) {
    super();
    this.Cookie = new CookieHandler();
    this.state = {
      vendorsData: [],
      vendorName: '',
      advanceAmount: '',
      contractDate: null,
      supplierReferenceNo: '',
      remarks: '',
      vendorNameError: false,
      advanceAmountError: false,
      contractDateError: false,
      errorMsg: '',
      succesMsg: '',
      isLoading: true,
      features: [],
    }
  }

  componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace('/logout');
    }
    var features = this.Cookie.getCookie("features");
    this.setState({ features: features ? features : [] },
      () => {
        if (!(this.state.features.includes('1') || this.state.features.includes('97'))) {
          window.location.href = '/dashboard';
        }
      });
    const loginUserID = this.Cookie.getCookie('loginUserId');
    const idToken = this.Cookie.getIdTokenCookie();
    const status = 'Active';
    //Get Surveyor vendors
    api.getVendors(loginUserID, idToken, status).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.idtoken)
            this.Cookie.setIdTokenCookie(res.idtoken);
          if (res.code === '200') {
            if (res.vendors) {
              const vendorsData = [];
              res.vendors.forEach(item => {
                if (item.vendor_type === "Coal" || item.vendor_type === "Barge" || item.vendor_type === "Others") {
                  vendorsData.push({ name: item.vendor_name, value: item.id, key: item.id });
                }
              });
              this.setState({
                vendorsData: vendorsData,
                isLoading: false
              });

            } else {
              alert('Unexpected error occured. Please contact administrator.');
            }
          } else if (res.code === '601') {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
              isLoading: false
            })
          } else if (res.code === '607') {
            window.location.href = '/logout';
          }
        })
      }
    })

    document.title = config.documentTitle + "Vendor Down Payment";
  }

  textHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  submitHandler = (e) => {
    e.preventDefault();
    var loginUserID = this.Cookie.getCookie('loginUserId');
    var idToken = this.Cookie.getIdTokenCookie();
    var { vendorName, supplierReferenceNo, advanceAmount, contractDate, remarks } = this.state;

    const contract_type = "Down Payment";
    /* 
    !<!----------------------------------- Checking Mandatory Fields ---------------!>
    */

    if (this.state.vendorName === '') {
      this.setState({ vendorNameError: true });
    }
    if (this.state.advanceAmount === '') {
      this.setState({ advanceAmountError: true });
    }
    if (this.state.contractDate === null) {
      this.setState({ contractDateError: true });
    }

    const billing_currency = config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL" ? 'USD' : "IDR";

    if (vendorName !== '' && advanceAmount !== '' && contractDate !== null) {

      api.addVendorAdvanceContract(loginUserID, vendorName, supplierReferenceNo, advanceAmount, contractDate, remarks, idToken, contract_type, billing_currency).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === '200') {
              if (res.id)
                window.location.href = '/vendor-advance-view-contract/' + btoa(res.id);
            }
            else if (res.code === "601") {
              this.setState({
                errorMsg: "Please fill manditory fields",
                succesMsg: '',
                isLoading: false
              });
              return
            }
            else if (res.code === "624") {
              this.setState({
                errorMsg: "Validation failed",
                succesMsg: '',
                isLoading: false
              });
              return
            }
            else if (res.code === '607') {
              window.location.href = '/logout';
            }
          })
        }

      });
    }
    else {
      alert("Please fill all mandatory fields.");
    }
  }

  contractDateHandler = (date) => {
    this.setState({
      contractDate: date,
      contractDateError: false
    });
  }

  render() {
    return (
      <div id='wraper'>
        <SideBar />
        <div className='content'>
          <Header />
          <div className='content-header'>
            <div className='col pl-0'>
              <h4 style={{ padding: '10px 20px', margin: '0px' }}>New Vendor Down Payment Contract</h4>
            </div>
          </div>


          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={this.state.snackBarErrorOpen} onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: '450px' }}
          >
            <Alert elevation={6} variant="filled" onClose={() => this.setState({ snackBarErrorOpen: false })} severity="error" style={{ width: '100%' }}>
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            style={{ width: '450px' }}
            open={this.state.snackBarSuccessOpen} autoHideDuration={100000} onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert elevation={6} variant="filled" onClose={() => this.setState({ snackBarSuccessOpen: false })} severity="success" style={{ width: '100%' }}>
              {this.state.successMsg}
            </Alert>
          </Snackbar>

          {!!this.state.isLoading &&
            <Loader />
          }
          {!this.state.isLoading &&
            <div>
              <form className='container p-3'>
                <div className='card' style={{ overflowY: 'scroll' }}>
                  <div className="section_block">
                    <div style={{ width: "70%" }}>
                      <h5 style={{ padding: '10px 0px', margin: '0px' }}>Add details to create Down Payment</h5>
                    </div>
                    <div className="row mb-2">
                      <div className='col-lg-6 p-0'>
                        <label className='form_label mb-0'>Vendor <span style={{ color: 'red' }}>*</span></label>
                        <Autocomplete
                          options={this.state.vendorsData}
                          getOptionLabel={option => option.name}
                          onChange={(event, value) => { value != null ? this.setState({ vendorNameValue: value, vendorName: value.value, vendorNameError: false }) : this.setState({ vendorName: '', vendorNameError: true }) }}
                          name='vendorName'
                          size="small"
                          value={this.state.vendorNameValue}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Search Vendor*"
                              //label="Search Vendor"
                              variant="outlined"
                              style={{ top: 8 }}
                              error={this.state.vendorNameError}
                              fullWidth />
                          )}
                          style={{ display: 'contents' }}
                        />
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-lg-6 p-0">
                        <label className="form_label mb-0">Down Payment Amount <span style={{ color: 'red' }}>*</span></label>
                        <TextField
                          name='advanceAmount'
                          margin='dense'
                          variant='outlined'
                          placeholder='Down Payment Amount *'
                          //required
                          type='number'
                          value={this.state.advanceAmount}
                          error={this.state.advanceAmountError}
                          onChange={(e) => {
                            const pr = /^\d*(\.\d{0,4})?$/;
                            if (e.target.value === "" || pr.test(e.target.value)) {
                              this.setState({
                                [e.target.name]: e.target.value,
                                advanceAmountError: false
                              });
                            }
                          }}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {(config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL") ? "USD" : "IDR"}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                      <div className='col-lg-6 pr-0'>
                        <label className='form_label mb-0'>Contract Date  <span style={{ color: 'red' }}>*</span></label>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DatePicker
                            autoOk={true}
                            inputVariant="outlined"
                            variant="inline"
                            margin="dense"
                            fullWidth
                            format="dd/MM/yyyy"
                            value={this.state.contractDate}
                            onChange={date => this.contractDateHandler(date)}
                            error={this.state.contractDateError}
                            InputProps={{
                              startAdornment:
                                (<InputAdornment position="start">
                                  <IconButton>
                                    <EventIcon />
                                  </IconButton></InputAdornment>),
                              className: "pl-0"
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-lg-12 p-0">
                        <label className='form_label mb-0'>Remarks</label>
                        <TextField
                          name='remarks'
                          margin='dense'
                          variant='outlined'
                          multiline
                          rows={3}
                          placeholder='Remarks'
                          value={this.state.remarks}
                          onChange={this.textHandler}
                          fullWidth
                          inputProps={{ style: { textTransform: 'uppercase' } }}
                        />
                      </div>
                    </div>
                  </div>

                </div>
                <div className='row bottom_buttons_section'>
                  <button type='button' className='btn previous_button' name='submit' onClick={() => window.location.href = '/advance-payments'}>Cancel</button>
                  <button type='button' className='btn next_button' name='Back' onClick={this.submitHandler}>Submit</button>
                </div>
              </form>
            </div>
          }
        </div>
      </div>
    )
  }
}