import React, { Component } from "react";

import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import {
  TextField,
  InputAdornment,
  MenuItem,
  Snackbar,
} from "@material-ui/core";
import api from "../../../api/api";
import { Alert } from "@material-ui/lab";
import FileUpload from "../../common/FileUpload";
import Loader from "../../common/Loader";
import { toLocaleString } from "../../common/common";

export default class Mv_Freight_Costing_Update extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      vessel_owner: "",
      invoice_date: null,
      ppn: "",
      ppn_date: null,
      quantity: "",
      price_pmt: "",
      total: "",
      remarks: "",
      isLoading: true,
      vendors: [],
      features: [],
      vat_percent: 10,
      vat_value: "",
      cost_to_company: "",
      files: [
        {
          file_description: "",
          file_name: "",
          file_extension: "",
          file_content: "",
          feature_name: "Mv_Pur_Fin_Freight_Costing",
        },
      ],
      costing_files: [],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    } else {
      var features = this.Cookie.getCookie("features");
      this.setState({ features: features });
      if (
        !(
          features.includes("381") ||
          features.includes("417") ||
          features.includes("468") ||
          features.includes("97")
        )
      ) {
        window.location.href = "/dashboard";
      }
      const loginUserID = this.Cookie.getCookie("loginUserId");
      const idToken = this.Cookie.getIdTokenCookie();
      // get vat value api
      const tax_type = "vat";
      const get_vat = await api.get_tax_percentages(
        loginUserID,
        idToken,
        tax_type
      );
      if (get_vat.status >= 200 && get_vat.status < 300) {
        get_vat.json().then((res) => {
          if (res.code === "200") {
            if (res.tax_percentages) {
              this.setState({
                vat_percent:
                  res.tax_percentages.length > 0
                    ? res.tax_percentages[0].tax_percentage
                    : 10,
              });
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          } else if (res.code === "601") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
      var status = "Active";
      var vendors = [
        <MenuItem
          style={{ fontFamily: "Poppins", fontSize: "14px" }}
          value="0"
          key={-1}
        >
          Please Select
        </MenuItem>,
      ];
      const getVendors = await api.getVendors(loginUserID, idToken, status);
      if (getVendors.status >= 200 && getVendors.status < 300) {
        getVendors.json().then((res) => {
          if (res.code === "200") {
            if (res.vendors) {
              for (var i of res.vendors) {
                if (i.vendor_type === "Vessel Owner") {
                  vendors.push(
                    <MenuItem
                      style={{ fontFamily: "Poppins", fontSize: "14px" }}
                      value={i.id}
                      key={[i.id]}
                    >
                      {i.vendor_name}
                    </MenuItem>
                  );
                }
              }
              this.setState({
                vendors: vendors,
              });
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          } else if (res.code === "601") {
            this.setState({
              errorMsg: res.message,
              snackBarErrorOpen: true,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
      try {
        // var businessNoID = decodeURIComponent(window.atob(this.props.match.params.businessNoID));
        var businessNoID = this.props.businessNo;
        //Get businessNumber API based on businessNumber
        const getBusinessNumber = await api.getBusinessNumber(
          businessNoID,
          loginUserID,
          idToken
        );
        if (getBusinessNumber.status >= 200 && getBusinessNumber.status < 300) {
          getBusinessNumber.json().then((res) => {
            if (res.code === "200") {
              if (res.business_number) {
                this.setState({
                  businessNo: res.business_number.business_no
                    ? res.business_number.business_no
                    : "",
                  mother_vessel_name: res.business_number.mother_vessel_name
                    ? res.business_number.mother_vessel_name
                    : "",
                  //isLoading: false
                });
              }
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                snackBarErrorOpen: true,
                isLoading: false,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }

        // get Freight costing api
        const get_freight = await api.get_mv_pur_fin_freight_costing(
          loginUserID,
          idToken,
          businessNoID
        );
        if (get_freight.status >= 200 && get_freight.status < 300) {
          get_freight.json().then((res) => {
            if (res.code === "200") {
              if (res.mv_pur_fin_freight_costing) {
                this.setState({
                  costing: res.mv_pur_fin_freight_costing.costing_no,
                  invoice_file: res.mv_pur_fin_freight_costing.invoice_file,
                  freight_costing_id: res.mv_pur_fin_freight_costing.id,
                  invoice_no: res.mv_pur_fin_freight_costing.invoice_no
                    ? res.mv_pur_fin_freight_costing.invoice_no
                    : null,
                  invoice_date: res.mv_pur_fin_freight_costing.invoice_date
                    ? res.mv_pur_fin_freight_costing.invoice_date
                    : null,
                  ppn: res.mv_pur_fin_freight_costing.ppn
                    ? res.mv_pur_fin_freight_costing.ppn
                    : "",
                  ppn_date: res.mv_pur_fin_freight_costing.ppn_date
                    ? res.mv_pur_fin_freight_costing.ppn_date
                    : null,
                  vessel_owner: res.mv_pur_fin_freight_costing.vessel_owner
                    ? res.mv_pur_fin_freight_costing.vessel_owner
                    : "0",
                  quantity: res.mv_pur_fin_freight_costing.quantity
                    ? res.mv_pur_fin_freight_costing.quantity
                    : "",
                  price_pmt: res.mv_pur_fin_freight_costing.price_pmt
                    ? res.mv_pur_fin_freight_costing.price_pmt
                    : "",
                  total: res.mv_pur_fin_freight_costing.total
                    ? res.mv_pur_fin_freight_costing.total
                    : "",
                  vat_percent: res.mv_pur_fin_freight_costing.vat_percent
                    ? res.mv_pur_fin_freight_costing.vat_percent
                    : 10,
                  vat_value: res.mv_pur_fin_freight_costing.vat_value
                    ? res.mv_pur_fin_freight_costing.vat_value
                    : "",
                  cost_to_company: res.mv_pur_fin_freight_costing
                    .cost_to_company
                    ? res.mv_pur_fin_freight_costing.cost_to_company
                    : "",
                  remarks: res.mv_pur_fin_freight_costing.remarks,
                  files: res.mv_pur_fin_freight_costing.files
                    ? res.mv_pur_fin_freight_costing.files
                    : [],
                  isLoading: false,
                });
              }
            }
          });
        }
      } catch (e) {
        //window.location.href = '/business-number-list';
      }
    }

    //document.title = config.documentTitle + "Mother Vessel Purchase Financial Freigh Costing";
  }

  submitHandler = () => {
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    var {
      invoice_no,
      invoice_date,
      ppn,
      ppn_date,
      freight_costing_id,
      vessel_owner,
      quantity,
      price_pmt,
      total,
      vat_percent,
      vat_value,
      cost_to_company,
      remarks,
    } = this.state;
    if (vessel_owner === "0") {
      this.setState({
        vessel_owner_error: true,
      });
      alert("Please fill mandatory fields.");
      return;
    }
    var billing_currency =
      config.company === "SRPL" || config.company === "AVS" || config.company === "PRPL" ? "USD" : "IDR";
    var currency_xchg_rate = "1";
    /**
     *  Checking Mandatory files
     */
    var missing_fields = 0;
    let files_missing = 0;
    var files_data = this.state.files;
    for (var obj of files_data) {
      if (obj.file_type !== "" || obj.file_content !== "") {
        if (obj.file_content !== "") {
          if (obj.file_description === "") {
            missing_fields++;
            obj.file_description_error = true;
          } else {
            obj.file_description = obj.file_description.toUpperCase();
          }
        } else if (obj.file_description !== "") {
          if (obj.file_content === "") {
            files_missing++;
          }
        }
      }
    }
    this.setState({
      files: files_data,
    });

    if (missing_fields > 0) {
      this.setState({
        snackBarErrorOpen: true,
        isLoading: false,
        errorMsg: "Please fill mandatory fields.",
      });
      return;
    }
    if (files_missing > 0) {
      this.setState({
        snackBarErrorOpen: true,
        isLoading: false,
        errorMsg: "Please Select file.",
      });
      return;
    }
    var existing_file_ids = [];
    const existing_files = files_data.filter((e) => e.id);
    for (var j of existing_files) {
      existing_file_ids.push(j.id);
    }
    const new_files = files_data.filter((e) => !e.id);
    const content_files = new_files.filter((e) => e.file_content !== "");
    const costing_file = this.state.costing_files?.filter(
      (e) => e.file_content !== "" && e.column === "invoice_file"
    );

    try {
      // var businessNoID = decodeURIComponent(window.atob(this.props.match.params.businessNoID));
      var businessNoID = this.props.businessNo;
      api
        .update_mv_pur_fin_freight_costing(
          loginUserID,
          idToken,
          businessNoID,
          freight_costing_id,
          invoice_no,
          invoice_date,
          ppn,
          ppn_date,
          vessel_owner,
          quantity,
          price_pmt,
          total,
          vat_percent,
          vat_value,
          cost_to_company,
          billing_currency,
          currency_xchg_rate,
          remarks,
          existing_file_ids,
          content_files,
          costing_file
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              // if (res.code === '200') {
              //   this.props.onUpdateCosting(res.code)
              //   // window.location.href = "/mv-pur-fin-view-freight-costing/" + btoa(businessNoID);
              // }
              if (res.code === "200") {
                //window.location.href = '/mv-purchase-view-pre-shipment-inspection/' + btoa(businessNoID);
                this.setState(
                  {
                    successMsg: res.message,
                    snackBarSuccessOpen: true,
                  },
                  () => {
                    setTimeout(() => {
                      this.props.onUpdateCosting(true);
                    }, 2000);
                  }
                );
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  snackBarErrorOpen: true,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              } else if (res.code === "624") {
                this.setState({
                  errorMsg: res.columns.length > 0 ? res.columns[0] : "",
                  snackBarErrorOpen: true,
                });
              }
            });
          }
        });
    } catch (e) {
      window.location.href = "/business-number-list";
    }
  };

  vatCalculations = (vat) => {
    let base_price = (
      Number(this.state.quantity) * Number(this.state.price_pmt)
    ).toFixed(2);
    let vat_value = (Number(base_price) * Number(this.state.vat_percent)) / 100;
    if (vat) {
      vat_value = this.state.vat_value;
    }
    let cost_to_company = (Number(base_price) + Number(vat_value)).toFixed();
    this.setState({
      vat_value: vat_value,
      cost_to_company: cost_to_company,
    });
  };

  render() {
    const pr = /^\d*(\.\d{0,4})?$/;
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div
          className="container bg-white p-0"
          style={{ minHeight: "100vh", width: 850 }}
        >
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <div className="row clearfix content-header border-bottom">
            <div className="col-lg-12 pl-0">
              <h4 style={{ padding: "10px 20px" }}>Update Freight Costing</h4>
            </div>
          </div>
          <div className="drawer-section">
            <div className="card p-3">
              <div
                className="card-body p-0"
                style={{ backgroundColor: "rgba(25,118,210,.01)" }}
                id="accordion"
              >
                <div className="row">
                  <div className="col-lg-12 border_dashed rounded-lg p-2 mb-3">
                    <div className="row mb-3 ">
                      <div className="row">
                        <div className="col-lg-6 mb-1">
                          <label className="form_label mb-1">
                            Vessel Owner
                          </label>
                          <TextField
                            name="vessel_owner"
                            margin="dense"
                            variant="outlined"
                            type="number"
                            value={this.state.vessel_owner}
                            error={this.state.vessel_owner_error}
                            select
                            fullWidth
                            onChange={(e) => {
                              this.setState({
                                vessel_owner: e.target.value,
                                vessel_owner_error: false,
                              });
                            }}
                            inputProps={{
                              style: {
                                fontFamily: "Poppins",
                                fontSize: "14px",
                              },
                            }}
                          >
                            {this.state.vendors}
                          </TextField>
                        </div>

                        <div className="col-lg-3 mb-1">
                          <label className="form_label mb-0">Quantity</label>
                          <TextField
                            name="quantity"
                            margin="dense"
                            variant="outlined"
                            type="number"
                            value={this.state.quantity}
                            fullWidth
                            placeholder="Enter Value"
                            onChange={(e) => {
                              if (
                                e.target.value === "" ||
                                pr.test(e.target.value)
                              ) {
                                this.setState(
                                  {
                                    quantity: e.target.value,
                                    total: (
                                      Number(e.target.value) *
                                      Number(this.state.price_pmt)
                                    ).toFixed(2),
                                  },
                                  () => this.vatCalculations()
                                );
                              }
                            }}
                            inputProps={{
                              style: {
                                fontFamily: "Poppins",
                                fontSize: "14px",
                              },
                            }}
                          />
                        </div>

                        <div className="col-lg-3 mb-1">
                          <label className="form_label mb-0">
                            Price Price PMT
                          </label>
                          <TextField
                            name="price_pmt"
                            margin="dense"
                            variant="outlined"
                            type="number"
                            value={this.state.price_pmt}
                            fullWidth
                            placeholder="Enter Value"
                            onChange={(e) => {
                              if (
                                e.target.value === "" ||
                                pr.test(e.target.value)
                              ) {
                                this.setState(
                                  {
                                    price_pmt: e.target.value,
                                    total: (
                                      Number(this.state.quantity) *
                                      Number(e.target.value)
                                    ).toFixed(2),
                                  },
                                  () => this.vatCalculations()
                                );
                              }
                            }}
                            inputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                  className="adornment_background"
                                >
                                  {config.company === "SRPL" || config.company === "AVS" ||
                                    config.company === "PRPL"
                                    ? "USD"
                                    : "IDR"}
                                </InputAdornment>
                              ),
                              style: {
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                padding: 0,
                              },
                            }}
                          />
                          <p style={{ fontSize: "14px" }}>
                            Total: {toLocaleString(this.state.total)}
                            {config.company === "SRPL" || config.company === "AVS" ||
                              config.company === "PRPL"
                              ? "USD"
                              : "IDR"}
                          </p>
                        </div>

                        <div className="col-lg-3 mb-1">
                          <label className="form_label mb-2">VAT %</label>
                          <div>
                            {/* {this.state.vat_percent
                              ? this.state.vat_percent
                              : "-"} */}
                            <TextField
                              name="vat_percent"
                              margin="dense"
                              type="number"
                              variant="standard"
                              fullWidth
                              inputProps={{
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                },
                              }}
                              value={this.state.vat_percent}
                              onChange={(e) => {
                                if (
                                  e.target.value === "" ||
                                  pr.test(e.target.value)
                                ) {
                                  this.setState(
                                    {
                                      vat_percent: e.target.value,
                                    },
                                    () => this.vatCalculations()
                                  );
                                }
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-lg-3 mb-1">
                          <label className="form_label mb-0">VAT</label>
                          <TextField
                            name="vat_value"
                            margin="dense"
                            variant="outlined"
                            type="number"
                            value={this.state.vat_value}
                            fullWidth
                            placeholder="Enter Value"
                            onChange={(e) => {
                              if (
                                e.target.value === "" ||
                                pr.test(e.target.value)
                              ) {
                                this.setState(
                                  {
                                    vat_value: e.target.value,
                                  },
                                  () => this.vatCalculations("vat")
                                );
                              }
                            }}
                            inputProps={{
                              style: {
                                fontFamily: "Poppins",
                                fontSize: "14px",
                              },
                            }}
                          />
                        </div>

                        <div className="col-lg-3 mb-1">
                          <label className="form_label mb-2">
                            Cost to Comapany
                          </label>
                          <div>
                            {this.state.cost_to_company
                              ? toLocaleString(this.state.cost_to_company)
                              : "-"}
                          </div>
                        </div>

                        <div className="col-lg-12 mb-1">
                          <label className="form_label mb-0">Remarks</label>
                          <TextField
                            name="remarks"
                            margin="dense"
                            variant="outlined"
                            type="number"
                            value={this.state.remarks}
                            fullWidth
                            placeholder="Remarks"
                            multiline
                            rows={3}
                            onChange={(e) => {
                              this.setState({
                                remarks: e.target.value,
                              });
                            }}
                            inputProps={{
                              style: {
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                textTransform: "uppercase",
                              },
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    {/* {(this.state.features.includes('528')  || this.state.features.includes('97') ) && */}

                    <FileUpload
                      files={this.state.files}
                      type="Costings"
                      invoice_file={this.state.invoice_file}
                      feature_name="Mv_Pur_Fin_Freight_Costing"
                      callbackFn={(data, mandatoryFiles) => {
                        this.setState({
                          files: data,
                          costing_files: mandatoryFiles,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button btn-light"
              name="submit"
              onClick={() => {
                this.props.returnCallbackDrawerClose(false);
              }}
            >
              Close
            </button>
            <button
              type="button"
              className="drawer_text drawer_btn_success"
              name="Back"
              onClick={this.submitHandler}
            >
              Update
            </button>
          </footer>
        </div>
      );
    }
  }
}
