import React, { Component } from 'react';
import config from "../../../config/config";
import CookieHandler from "../../common/CookieHandler";
import {
    TableContainer, Table, TableHead, TableBody,
    Select, MenuItem, FormControl, Button, TextField
} from '@material-ui/core';
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";

import api from "../../../api/api";
import Loader from '../../common/Loader';
import { ContractTypesEnum } from '../../../common/enums';

export default class DelayedSampleReport extends Component {
    constructor(props) {
        super(props);
        this.Cookie = new CookieHandler();

        this.state = {
            features: [],
            delayedSamplingData: [],
            filteredDelayedSamplingData: [],
            isLoading: true,
            selectedPurchasingTerm: '',
            loadMore: 0,
            searchInput: ''
        }
    }
    componentDidMount() {
        if (!this.Cookie.isLoggedIn()) {
            this.props.history.replace("/logout");
        }
        var features = this.Cookie.getCookie("features");
        this.setState({ features: features });
        this.getDelayedSamplingReportData();
    }

    getDelayedSamplingReportData = async (loadMore) => {
        const loginUserID = this.Cookie.getCookie("loginUserId");
        const idToken = this.Cookie.getIdTokenCookie();
        if (this.props.type === ContractTypesEnum.PURCHASE_CONTRACT) {
            await api.getDelayedPurchaseSamplingReportData(loginUserID, idToken, loadMore).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    response.json().then((res) => {
                        if (res.code === "200") {
                            if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
                            if (res.data) {
                                this.setState({
                                    delayedSamplingData: res.data,
                                    isLoading: false,
                                    filteredDelayedSamplingData: res.data
                                });
                            } else {
                                alert("Unexpected error occured. Please contact administrator.");
                                this.setState({ isLoading: false });
                            }
                        } else if (res.code === "607") {
                            this.setState({ isLoading: false });
                            window.location.href = "/logout";
                        }
                    });
                }
            });
        } else {
            await api.getDelayedSalesSamplingReportData(loginUserID, idToken, loadMore).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    response.json().then((res) => {
                        if (res.code === "200") {
                            if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
                            if (res.data) {
                                this.setState({
                                    delayedSamplingData: res.data,
                                    isLoading: false,
                                    filteredDelayedSamplingData: res.data
                                });
                            } else {
                                alert("Unexpected error occured. Please contact administrator.");
                                this.setState({ isLoading: false });
                            }
                        } else if (res.code === "607") {
                            this.setState({ isLoading: false });
                            window.location.href = "/logout";
                        }
                    });
                }
            });
        }
    }
    handleChange = (event) => {
        const { delayedSamplingData, searchInput } = this.state;
        let filteredData = delayedSamplingData.filter(r => {
            if (event.target.value) {
                if (searchInput) {
                    return r.purchasingTerm === event.target.value && r.businessNo.toLocaleLowerCase().includes(searchInput.toLocaleLowerCase())
                } else {
                    return r.purchasingTerm === event.target.value;
                }
            } else {
                if (searchInput) {
                    return r.businessNo.toLocaleLowerCase().includes(searchInput.toLocaleLowerCase())
                } else {
                    return r
                }
            }
        })
        this.setState({ filteredDelayedSamplingData: filteredData, selectedPurchasingTerm: event.target.value })
    }
    handleSearch = () => {
        const { searchInput, delayedSamplingData, selectedPurchasingTerm, filteredDelayedSamplingData } = this.state;
        if (searchInput) {
            const filtered = delayedSamplingData.filter(r => {
                if (selectedPurchasingTerm)
                    return r.purchasingTerm === selectedPurchasingTerm && r.businessNo.toLocaleLowerCase().includes(searchInput.toLocaleLowerCase())
                else
                    return r.businessNo.toLocaleLowerCase().includes(searchInput.toLocaleLowerCase())
            })
            this.setState({ filteredDelayedSamplingData: filtered });
        } else {
            const filtered = delayedSamplingData.filter(r => {
                if (selectedPurchasingTerm)
                    return r.purchasingTerm === selectedPurchasingTerm 
                else
                    return r;
            })
            this.setState({ filteredDelayedSamplingData: filtered });
        }
    }
    render() {
        const { filteredDelayedSamplingData, selectedPurchasingTerm } = this.state;
        return (
            <div>
                <div>
                    {this.state.isLoading && <Loader />}
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button color="primary" variant="contained" onClick={() => {
                            this.setState(prevState => ({
                                loadMore: prevState.loadMore + 10
                            }), () => this.getDelayedSamplingReportData(this.state.loadMore))
                        }}>Load More</Button>
                        <TextField
                            label="search Business number"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment>
                                        <IconButton>
                                            <SearchIcon onClick={this.handleSearch} />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            value={
                                this.state
                                    .searchInput
                            }
                            onChange={(e) => this.setState({ searchInput: e.target.value })}
                        /></div>
                    <FormControl fullWidth>
                        <label value="Select Purchasing Term: ">Purchasing Term: </label>
                        <Select
                            style={{ width: "30%" }}
                            value={selectedPurchasingTerm}
                            onChange={this.handleChange}
                            name="purchasingTerm"
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            <MenuItem value="">All</MenuItem>
                            <MenuItem value="Specs">Specs</MenuItem>
                            <MenuItem value="Non Specs">Non Specs</MenuItem>
                        </Select></FormControl>

                    <TableContainer className="table-responsive" style={{ maxHeight: "80vh", width: "90vw" }}>
                        <Table stickyHeader aria-label="sticky table" className="table table-bordered table-sm mb-0">
                            <TableHead className="table-header sticky-header-table">
                                <tr>
                                    <th nowrap="true" className="text-uppercase text-center">
                                        Barge ID
                                    </th>
                                    <th nowrap="true" className="text-uppercase text-center">
                                        Barge Name
                                    </th>
                                    <th nowrap="true" className="text-uppercase text-center ">
                                        Business Number
                                    </th>
                                    <th nowrap="true" className="text-uppercase text-center ">
                                        Purchase Contract
                                    </th>
                                    <th nowrap="true" className="text-uppercase text-center ">
                                        Purchasing Term
                                    </th>
                                    <th nowrap="true" className="text-uppercase text-center ">
                                        Supplier
                                    </th>
                                    <th nowrap="true" className="text-uppercase text-center ">
                                        Method
                                    </th>
                                    <th nowrap="true" className="text-uppercase text-center ">
                                        IncoTerms
                                    </th>
                                    <th nowrap="true" className="text-uppercase text-center ">
                                        Mine Name
                                    </th>
                                    <th nowrap="true" className="text-uppercase text-center ">
                                        No of Days
                                    </th>
                                </tr>
                            </TableHead>
                            {!this.state.isLoading && <TableBody >
                                {filteredDelayedSamplingData.map((bargeData, bargeIndex) => {
                                    return <tr key={bargeData.bargeId}>
                                        <td><a
                                            style={{
                                                textDecoration: "none",
                                                color: config.themeColor,
                                            }}
                                            href={"/view-barge/" + btoa(bargeData.bargeId)}
                                        >
                                            {bargeData.bargeId}
                                        </a></td>
                                        <td>{bargeData.bargeName}</td>
                                        <td>{bargeData.businessNo}</td>

                                        <td><a
                                            style={{
                                                textDecoration: "none",
                                                color: config.themeColor,
                                            }}
                                            href={"/view-purchase-contract/" + btoa(bargeData.purchaseContractID)}
                                        >
                                            {bargeData.purchaseContractNo}
                                        </a></td>
                                        <td>{bargeData.purchasingTerm}</td>
                                        <td className="text-nowrap">{bargeData.supplierName}</td>
                                        <td>{bargeData.method}</td>
                                        <td>{bargeData.incoTerms}</td>
                                        <td>{bargeData.mineName}</td>
                                        <td>{bargeData.noOfDays}</td>
                                    </tr>
                                })
                                }
                            </TableBody>}
                        </Table>
                    </TableContainer>
                </div>
            </div>
        )
    }
}
