import React, { Component } from "react";

import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import api from "../../../api/api";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import EventIcon from "@material-ui/icons/Event";
import DateFnsUtils from "@date-io/date-fns";
import {
  TextField,
  Dialog,
  DialogTitle,
  IconButton,
  InputAdornment,
  Snackbar,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { localDateFormate } from "../../common/common";
import ViewFiles from "../../common/ViewFiles";
import InvoiceForm from "../../common/InvoiceForm";
import PaymentPostings from "../../common/PaymentPostings";
import Loader from "../../common/Loader";
import InvoiceLink from "../../InvoicePdf/InvoiceLink";
export default class Mv_Pre_Shipment_View extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      multiRows: [],
      features: [],
      isLoading: true,
      invoiceDialog: false,
      costing_no: "",
      invoice_date: "",
      invoice_no: "",

      sac_quantity_in_mt: "",
      sac_sampling_base_price: "",
      sac_sampling_ctc: "",
      sac_sampling_currency_xchg_rate: "",

      sac_sampling_pph23_value: "",
      sac_sampling_price_per_mt: "",
      sac_sampling_surveyor_name: "",

      sac_sampling_total: "",
      sac_sampling_vat_value: "",

      getBarges: [],
      mother_vessel_name: "",
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    } else {
      var features = this.Cookie.getCookie("features");
      this.setState({ features: features });
      if (
        !(
          features.includes("251") ||
          features.includes("421") ||
          features.includes("472") ||
          features.includes("97")
        )
      ) {
        window.location.href = "/dashboard";
      }
      var loginUserID = this.Cookie.getCookie("loginUserId");
      var idToken = this.Cookie.getIdTokenCookie();
      try {
        //var businessNoID = decodeURIComponent(window.atob(this.props.match.params.businessNoID));
        var businessNoID = this.props.businessNoID;
        //Get businessNumber API based on businessNumber
        await api
          .getBusinessNumber(businessNoID, loginUserID, idToken)
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              response.json().then((res) => {
                if (res.code === "200") {
                  if (res.business_number) {
                    this.setState({
                      businessNo: res.business_number.business_no
                        ? res.business_number.business_no
                        : "",
                      mother_vessel_name: res.business_number.mother_vessel_name
                        ? res.business_number.mother_vessel_name
                        : "",
                      //isLoading: false
                    });
                  }
                } else if (res.code === "601") {
                  this.setState({
                    errorMsg: res.message,
                    snackBarErrorOpen: true,
                  });
                } else if (res.code === "607") {
                  window.location.href = "/logout";
                }
              });
            }
          });

        var preShipmentID = this.props.preShipmentID;
        //Get businessNumber API based on businessNumber
        await api
          .get_mv_pur_fin_pre_shipment(loginUserID, idToken, preShipmentID)
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              response.json().then((res) => {
                if (res.code === "200") {
                  if (res.mv_pre_shipment) {
                    var paid_to_vendor = 0;
                    var remaining_amount = 0;
                    for (var i = 0; i < res.mv_pre_shipment.length; i++) {
                      if (
                        res.mv_pre_shipment[i].mv_pre_shipment_payments.length >
                        0
                      ) {
                        for (var j of res.mv_pre_shipment[i]
                          .mv_pre_shipment_payments) {
                          if (j.date_of_payment) {
                            paid_to_vendor += Number(j.amount);
                          }
                          remaining_amount =
                            Number(res.mv_pre_shipment[i].sac_sampling_total) -
                            Number(paid_to_vendor);
                        }
                        res.mv_pre_shipment[i].paid_to_vendor = paid_to_vendor;
                        res.mv_pre_shipment[i].remaining_amount =
                          remaining_amount;
                        paid_to_vendor = 0;
                        remaining_amount = 0;
                      } else {
                        res.mv_pre_shipment[i].remaining_amount = Number(
                          res.mv_pre_shipment[i].sac_sampling_total
                        );
                      }
                    }
                    this.setState({
                      multiRows: res.mv_pre_shipment,
                      invoice_date: res.mv_pre_shipment[0].invoice_date,
                      invoice_no: res.mv_pre_shipment[0].invoice_no,

                      sac_quantity_in_mt:
                        res.mv_pre_shipment[0].sac_quantity_in_mt,
                      sac_sampling_base_price:
                        res.mv_pre_shipment[0].sac_sampling_base_price,
                      sac_sampling_ctc: res.mv_pre_shipment[0].sac_sampling_ctc,

                      sac_sampling_currency_xchg_rate:
                        res.mv_pre_shipment[0].sac_sampling_currency_xchg_rate,
                      sac_sampling_pph23_value:
                        res.mv_pre_shipment[0].sac_sampling_pph23_value,
                      sac_sampling_price_per_mt:
                        res.mv_pre_shipment[0].sac_sampling_price_per_mt,

                      sac_sampling_surveyor_name:
                        res.mv_pre_shipment[0].sac_sampling_surveyor_name,
                      sac_sampling_total:
                        res.mv_pre_shipment[0].sac_sampling_total,
                      sac_sampling_vat_value:
                        res.mv_pre_shipment[0].sac_sampling_vat_value,

                      costing_no: res.mv_pre_shipment[0].costing_no,
                      isLoading: false,
                    });
                    if (res.mv_pre_shipment.length === 0) {
                      this.setState({
                        errorMsg: "No pre-shipment inspection data available",
                        snackBarErrorOpen: true,
                      });
                      setTimeout(
                        () => this.props.returnCallbackDrawerClose(false),
                        2000
                      );
                    }
                  }
                } else if (res.code === "601") {
                  this.setState({
                    errorMsg: res.message,
                    snackBarErrorOpen: true,
                  });
                } else if (res.code === "607") {
                  window.location.href = "/logout";
                }
              });
            }
          });

        // const get_mv_pre_shipment = await api.get_mv_pre_shipment(loginUserID, idToken, businessNoID);
        // if (get_mv_pre_shipment.status >= 200 && get_mv_pre_shipment.status < 300) {
        //   get_mv_pre_shipment.json().then((res) => {
        //     if (res.code === '200') {
        //       if (res.mv_pre_shipment) {
        //         var paid_to_vendor = 0;
        //         var remaining_amount = 0;
        //         for (var i = 0; i < res.mv_pre_shipment.length; i++) {
        //           if (res.mv_pre_shipment[i].mv_pre_shipment_payments.length > 0) {
        //             for (var j of res.mv_pre_shipment[i].mv_pre_shipment_payments) {
        //               paid_to_vendor += (Number(j.amount));
        //               remaining_amount = (Number(res.mv_pre_shipment[i].sac_sampling_total) - Number(paid_to_vendor));
        //             }
        //             res.mv_pre_shipment[i].paid_to_vendor = paid_to_vendor;
        //             res.mv_pre_shipment[i].remaining_amount = remaining_amount;
        //             paid_to_vendor = 0;
        //             remaining_amount = 0;
        //           }
        //           else {
        //             res.mv_pre_shipment[i].remaining_amount = (Number(res.mv_pre_shipment[i].sac_sampling_total));
        //           }
        //         }
        //         this.setState({
        //           multiRows: res.mv_pre_shipment,
        //           isLoading: false
        //         });
        //         if (res.mv_pre_shipment.length === 0){
        //           this.setState({
        //             errorMsg: "No pre-shipment inspection data available",
        //             snackBarErrorOpen: true
        //           });
        //           setTimeout(
        //             ()=>this.props.returnCallbackDrawerClose(false),
        //             2000
        //           );
        //         }
        //       }
        //     }
        //     else if (res.code === '601') {
        //       this.setState({
        //         errorMsg: res.message,
        //         snackBarErrorOpen: true
        //       });

        //     }
        //     else if (res.code === '607') {
        //       window.location.href = '/logout';
        //     }

        //   })
        // }
      } catch (e) {
        // window.location.href = '/business-number-list';
      }
    }
    //document.title = config.documentTitle + "Mv Pre Shipment Inspection";
  }

  AddPaymentPostingHandler = () => {
    this.setState({ submitting: true });
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    var {
      mv_pre_shipment_id,
      payment_posting_type,
      amount,
      date_of_payment,
      payment_due_date,
      invoice_no,
      description,
    } = this.state;
    if (date_of_payment === null) {
      this.setState({
        date_of_paymentError: true,
        submitting: false,
      });
    }
    if (amount === "") {
      this.setState({ amountError: true, submitting: false });
    }
    if (invoice_no === "") {
      this.setState({ invoice_noError: true, submitting: false });
    }

    var remainingAmount = 0;
    remainingAmount = Number(this.state.remainingAmount);
    if (this.state.paymentMode === "Update") {
      remainingAmount =
        Number(this.state.remainingAmount) + Number(this.state.existingAmount);
    }
    if (Number(amount) > Math.ceil(Number(remainingAmount))) {
      this.setState({
        amountError: true,
        submitting: false,
        paymentErrorMsg: "Amount is exceeding Pre Shipment value",
      });
      return;
    }

    if (mv_pre_shipment_id && amount !== "" && invoice_no !== "") {
      api
        .add_mv_pre_shipment_payment_postings(
          loginUserID,
          idToken,
          mv_pre_shipment_id,
          payment_posting_type,
          amount,
          date_of_payment,
          payment_due_date,
          invoice_no,
          description
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                this.setState(
                  {
                    submitting: false,
                    date_of_payment: null,
                    payment_due_date: null,
                    invoice_no: "",
                    description: "",
                    date_of_paymentError: false,
                    amountError: false,
                    invoice_noError: false,
                    paymentPostingsModal: false,
                    snackBarSuccessOpen: true,
                    successMsg: res.message,
                  },
                  () => {
                    this.componentDidMount();
                  }
                );
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  snackBarErrorOpen: true,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    } else {
      alert("Please fill mandatory fields.");
    }
  };

  UpdatePaymentPostingsHandler = () => {
    this.setState({ submitting: true });
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    var {
      pre_shipment_payment_id,
      mv_pre_shipment_id,
      payment_posting_type,
      amount,
      date_of_payment,
      payment_due_date,
      invoice_no,
      description,
    } = this.state;
    if (date_of_payment === null) {
      this.setState({
        date_of_paymentError: true,
        submitting: false,
      });
    }
    if (amount === "") {
      this.setState({ amountError: true, submitting: false });
    }
    if (invoice_no === "") {
      this.setState({ invoice_noError: true, submitting: false });
    }
    var remainingAmount = 0;
    remainingAmount = Number(this.state.remainingAmount);
    if (this.state.paymentMode === "Update") {
      remainingAmount =
        Number(this.state.remainingAmount) + Number(this.state.existingAmount);
    }
    if (Number(amount) > Math.ceil(Number(remainingAmount))) {
      this.setState({
        amountError: true,
        submitting: false,
        paymentErrorMsg: "Amount is exceeding Pre Shipment value",
      });
      return;
    }

    if (mv_pre_shipment_id && amount !== "" && invoice_no !== "") {
      let status = "Active";
      api
        .update_mv_pre_shipment_payment_postings(
          loginUserID,
          idToken,
          pre_shipment_payment_id,
          mv_pre_shipment_id,
          payment_posting_type,
          amount,
          date_of_payment,
          payment_due_date,
          invoice_no,
          description,
          status
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                this.setState(
                  {
                    submitting: false,
                    date_of_payment: null,
                    payment_due_date: null,
                    invoice_no: "",
                    description: "",
                    date_of_paymentError: false,
                    amountError: false,
                    invoice_noError: false,
                    paymentPostingsModal: false,
                    snackBarSuccessOpen: true,
                    successMsg: res.message,
                  },
                  () => {
                    this.componentDidMount();
                  }
                );
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  snackBarErrorOpen: true,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    } else {
      alert("Please fill mandatory fields.");
    }
  };

  getPaymentDetalis = (e) => {
    if (e) {
      this.setState({
        paymentMode: "Update",
        paymentTitle: "Update Pre Shipment",
        pre_shipment_payment_id: e.id,
        mv_pre_shipment_id: e.mv_pre_shipment_id,
        date_of_payment: e.date_of_payment,
        payment_due_date: e.payment_due_date,
        amount: e.amount,
        existingAmount: e.amount,
        invoice_no: e.invoice_no,
        payment_posting_type: e.payment_posting_type,
        description: e.description,
        paymentPostingsModal: true,
      });
    }
  };
  toLocaleString(value) {
    return Number(value).toLocaleString();
  }

  approveHandler = () => {
    this.setState({ isLoading: true });
    let request = {
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
      costing_id: this.props.preShipmentID,
      costing_type: "MV Pre Shipment",
      approval_status: "Approved",
    };
    api.approve_costing_api(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.setState(
              {
                snackBarSuccessOpen: true,
                successMsg: res.message,
              },
              () => this.componentDidMount()
            );
          } else if (res.code === "601") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
  };

  approvePaymentsHandler = (id) => {
    this.setState({ isLoading: true });
    let request = {
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
      payment_id: id,
      costing_type: "mv_pre_shipment_payment_postings_data",
      approval_status: "Approved",
    };
    api.approve_payment_postings_api(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.setState(
              {
                snackBarSuccessOpen: true,
                successMsg: res.message,
              },
              () => this.componentDidMount()
            );
          } else if (res.code === "601") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
  };

  updateInvoice = (data) => {
    let request = {
      ...data,
      table_name: "mv_pre_shipment",
    };
    api.update_invoice_details(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.componentDidMount();
            this.setState({
              invoiceDialog: false,
            });
          }
        });
      }
    });
  };
  renderPaymentPostings = (data) => {
    const {
      id,
      mv_pre_shipment_payments = [],
      invoice_no = null,
      remaining_amount = 0,
      sac_sampling_currency_xchg_rate = 1,
      approval_status = "Pending",
    } = data;
    let payment_related_data = {
      paymentTitle: "MV Pre Shipment",
      payment_posting_type: "Pre_Shipment",
      invoice_no: invoice_no,
      outstanding_amount: remaining_amount,
      billing_currency:
        config.company === "SRPL" || config.company === "AVS" || config.company === "PRPL" ? "USD" : "IDR",
      currency_xchg_rate: sac_sampling_currency_xchg_rate
        ? sac_sampling_currency_xchg_rate
        : 1,
      costing_id: id,
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
    };
    let access_details = {
      costing_approval: approval_status,
      add_payment: "254",
      view_payment: "255",
      update_payment: "256",
      delete_payment: "257",
      approver: "422",
      approver2: "473",
    };

    return (
      <PaymentPostings
        access_details={access_details}
        payments={mv_pre_shipment_payments}
        costingType="MV Pre Shipment"
        data={payment_related_data}
        callbackFn={() => {
          this.componentDidMount();
        }}
      />
    );
  };
  renderInvoiceLink = () => {
    const {
      costing_no,
      sac_sampling_surveyor_name = "",
      invoice_no = "",
      invoice_date = "",
    } = this.state;

    const Grand_total = parseFloat(
      this.state.sac_sampling_total ? this.state.sac_sampling_total : 0
    ).toFixed(2);

    const invoiceObject = {
      costing_id: costing_no,
      to: sac_sampling_surveyor_name,
      business_no: this.state.mother_vessel_name,
      costing_name: "MV Pre Shipment Inspection",
      invoice_no: invoice_no,
      invoice_date: localDateFormate(invoice_date),
      bargeDetails: [],
      billing_currency:
        config.company === "SRPL" || config.company === "AVS" || config.company === "PRPL" ? "USD" : "IDR",
      total_payable_amount: Grand_total,
      purchase_type: "FOB Barge",
      description: [
        (this.state.sac_sampling_base_price ? {
          qty: parseFloat(this.state.sac_quantity_in_mt).toFixed(2),
          adjustedPrice: parseFloat(this.state.sac_sampling_price_per_mt).toFixed(2),
          desc: `${this.state.sac_sampling_currency_xchg_rate > 1
            ? `MV Pre Shipment @ ${parseFloat(
              this.state.sac_sampling_currency_xchg_rate
            ).toFixed(2)}`
            : "MV Pre Shipment"
            }`,
          amount: parseFloat(this.state.sac_sampling_base_price).toFixed(2),
        } : []),
        ...(this.state.pph15_account !== "SDAM" && !!this.state.sac_sampling_vat_value
          ? [
            {
              qty: " ",
              adjustedPrice: " ",
              desc: "VAT",
              amount: `${parseFloat(this.state.sac_sampling_vat_value).toFixed(2)}`,
            },
          ]
          : []),
        ...(!(config.company === "SRPL" || config.company === "AVS" || config.company === "PRPL") && !!this.state.sac_sampling_pph23_value
          ? [
            {
              qty: " ",
              adjustedPrice: " ",
              desc: "PPH",
              amount: `( ${parseFloat(this.state.sac_sampling_pph23_value).toFixed(2)} )`,
            },
          ]
          : []),
      ],
    };
    return <InvoiceLink data={invoiceObject} fileName={costing_no} />;
  };
  render() {
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div
          className="container bg-white p-0"
          style={{ minHeight: "100vh", width: 850 }}
        >
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          {this.state.multiRows.map((e, idx) => (
            <div className="clearfix content-header border-bottom">
              <div className="row">
                <div className="col-sm text-left pl-0">
                  <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                    {`View Pre-Shipment Inspection - ${e.costing_no}`}
                    <span
                      className={
                        e.approval_status === "Pending" ? "type_2" : "type_1"
                      }
                      style={{ padding: 8, marginLeft: 16 }}
                    >
                      {e.approval_status}
                    </span>
                  </h4>
                </div>
                {e.approval_status === "Pending" &&
                  e.invoice_file &&
                  this.Cookie.getCookie("loginUserId") !== e.created_by &&
                  e.approved_by !== this.Cookie.getCookie("loginUserId") &&
                  (this.state.features.includes("421") ||
                    this.state.features.includes("472") ||
                    this.state.features.includes("97")) && (
                    <div
                      className="col text-right float-sm-left"
                      style={{ padding: "5px 20px" }}
                    >
                      <button
                        className="header_button header_button_text add_button_adjustment"
                        style={{ width: "auto", marginTop: "17px" }}
                        onClick={this.approveHandler}
                      >
                        Approve
                      </button>
                    </div>
                  )}
                <div className="col-lg-1 pl-0 float-right">
                  {this.renderInvoiceLink()}
                </div>
              </div>
            </div>
          ))}
          <div className="drawer-section">
            <div className="card p-3">
              <div
                className="card-body p-0"
                style={{ backgroundColor: "rgba(25,118,210,.01)" }}
                id="accordion"
              >
                {this.state.multiRows.map((e, idx) => (
                  <>
                    <div className="row" key={idx}>
                      <div className="col-lg-12 border_dashed rounded-lg p-2 mb-3">
                        <div className="col shadow-sm p-0 mb-2 bg-white border">
                          {/* New Changes */}
                          <div className="table-responsive">
                            <table className="table table-sm table-bordered mb-0">
                              <thead className="table-header">
                                <tr>
                                  <th className="text-center">Description</th>
                                  <th className="text-right">Qty</th>
                                  <th className="text-right">Unit Price</th>
                                  <th className="text-right">Amount</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.multiRows.map((e, index) => (
                                  <>
                                    <tr>
                                      <td>
                                        Pre Shipment Inspection
                                        {e.sac_sampling_currency_xchg_rate > 1 && (config.company === "SDAM" ||
                                          config.company === "SRK" ||
                                          config.company === "BTR") &&
                                          e.billing_currency !== "IDR" &&
                                          e.billing_currency !== "0" && (e.sac_sampling_currency_xchg_rate &&
                                            <div>
                                              Exchange Rate @
                                              {parseFloat(e.sac_sampling_currency_xchg_rate).toFixed(2)}
                                            </div>
                                          )}
                                        Sampling Surveyor : {e.sac_sampling_surveyor_name}
                                      </td>
                                      <td className="text-right">
                                        {this.toLocaleString(e.sac_quantity_in_mt)}
                                      </td>
                                      <td className="text-right">
                                        {this.toLocaleString(
                                          e.sac_sampling_price_per_mt
                                        )}
                                      </td>
                                      <td className="text-right">
                                        {this.toLocaleString(
                                          e.sac_sampling_base_price
                                        )}
                                      </td>

                                    </tr>
                                    {!!(e.sac_sampling_vat_value) &&
                                      (this.state.vat_applicability === "Inclusive" ||
                                        config.company !== "SRPL" ||
                                        config.company !== "PRPL" ||
                                        config.company !== "AVS") &&
                                      <tr>
                                        <td>VAT @ {this.toLocaleString(
                                          e.sac_sampling_vat_percent
                                        )} %</td>
                                        <td className="text-right"></td>
                                        <td className="text-right"></td>
                                        <td className="text-right">
                                          {parseFloat(e.sac_sampling_vat_value).toFixed(2)}
                                        </td>
                                      </tr>
                                    }
                                    {!(
                                      config.company === "SRPL" ||
                                      config.company === "AVS" ||
                                      config.company === "PRPL"
                                    ) && !!e.sac_sampling_pph23_value && (
                                        <tr>
                                          <td>PPH23 @  {this.toLocaleString(e.sac_sampling_pph23_percent)} % </td>
                                          <td></td>
                                          <td></td>
                                          <td className="text-right">({parseFloat(e.sac_sampling_pph23_value).toFixed(2)})</td>
                                        </tr>
                                      )}
                                    <tr>
                                      <td colSpan={3} className="text-right ">
                                        {" "}
                                        Payable to Vendor :{" "}
                                      </td>
                                      <td style={{ fontWeight: "bold" }} className="text-right ">{`${config.company === "SRPL" ||
                                        config.company === "PRPL" ||
                                        config.company === "AVS"
                                        ? "USD"
                                        : "IDR"
                                        } ${parseFloat(e.sac_sampling_total).toFixed(2)}`}</td>
                                    </tr>
                                    <tr>
                                      <td colSpan={3} className="text-right ">
                                        {" "}
                                        Paid to Vendor :{" "}
                                      </td>
                                      <td style={{ fontWeight: "bold" }} className="text-right ">{`${config.company === "SRPL" ||
                                        config.company === "PRPL" ||
                                        config.company === "AVS"
                                        ? "USD"
                                        : "IDR"
                                        } ${e.paid_to_vendor ? parseFloat(e.paid_to_vendor).toFixed(2) : 0}`}</td>

                                    </tr>
                                    <tr>
                                      {e.remaining_amount ? <> <td colSpan={3} className="text-right ">
                                        {" "}
                                        Remaining Amount{" "}
                                      </td>
                                        <td style={{ fontWeight: "bold" }} className="text-right ">{`${config.company === "SRPL" ||
                                          config.company === "PRPL" ||
                                          config.company === "AVS"
                                          ? "USD"
                                          : "IDR"
                                          } ${parseFloat(e.remaining_amount).toFixed(2)}`}</td>
                                      </> : ""}
                                    </tr>
                                  </>
                                ))
                                }
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div className="card col-lg-12 p-0 border">
                          <div className="card-header section_header">
                            <h5>Payment Postings</h5>
                          </div>
                          <div className="card-body">
                            <InvoiceForm
                              open={this.state.invoiceDialog}
                              data={e}
                              costingID={e.id}
                              callbackFn={(data) => this.updateInvoice(data)}
                            />
                            <div className="row">
                              <div className="col-lg-4 mb-2">
                                <label className="contract_display_header_label">
                                  Paid To vendor
                                </label>
                                <div className="contract_display_header_value">
                                  {" "}
                                  {e.paid_to_vendor
                                    ? this.toLocaleString(e.paid_to_vendor)
                                    : 0}{" "}
                                  {config.company === "SRPL" || config.company === "AVS" ||
                                    config.company === "PRPL"
                                    ? "USD"
                                    : "IDR"}
                                </div>
                              </div>
                              <div className="col-lg-4 mb-2">
                                <label className="contract_display_header_label">
                                  Remaining Amount
                                </label>
                                <div className="contract_display_header_value">
                                  {" "}
                                  {e.remaining_amount
                                    ? this.toLocaleString(e.remaining_amount)
                                    : 0}{" "}
                                  {config.company === "SRPL" || config.company === "AVS" ||
                                    config.company === "PRPL"
                                    ? "USD"
                                    : "IDR"}
                                </div>
                              </div>
                            </div>

                            {(this.state.features.includes("255") ||
                              this.state.features.includes("97")) && (
                                <div>
                                  {this.renderPaymentPostings(e)}
                                  {/* <div className="row">
                                <div className="col-lg-12">
                                  <div className="clearfix">
                                    <div className="float-left">
                                      {!e.mv_pre_shipment_payments
                                        ? ""
                                        : e.mv_pre_shipment_payments.length <=
                                            0 && (
                                            <p style={{ fontWeight: "bold" }}>
                                              No payments made
                                            </p>
                                          )}
                                    </div>
                                    <div className="float-right">
                                      {(this.state.features.includes("254") >=
                                        0 ||
                                        this.state.features.includes("97") >=
                                          0) &&
                                        e.invoice_no && (
                                          <button
                                            className="btn header_button header_button_text  w-auto"
                                            onClick={() => {
                                              let data = {
                                                ...this.state.paymentDetails,
                                                invoice_no: e.invoice_no,
                                                costing_id: e.id,
                                                paymentTitle:
                                                  "Add Pre Shipment",
                                                payment_posting_type:
                                                  "Pre_Shipment",
                                                currency_xchg_rate:
                                                  e.sac_sampling_currency_xchg_rate,
                                                remaining_amount:
                                                  e.remaining_amount,
                                              };
                                              this.setState({
                                                paymentDetails: data,
                                                paymentMode: "Add",
                                                paymentsModal: true,
                                              });
                                            }}
                                          >
                                            Add Payment
                                          </button>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {!e.mv_pre_shipment_payments
                                ? ""
                                : e.mv_pre_shipment_payments.length > 0 && (
                                    <div className="row mt-2">
                                      <div className="col-lg p-0">
                                        <div className="table-responsive border-0">
                                          <table className="table table-borderless border-0">
                                            <thead className=" border-0">
                                              <tr>
                                                <th
                                                  nowrap="true"
                                                  className="table_header_barge_label"
                                                  style={{ border: "none" }}
                                                >
                                                  Date of Payment
                                                </th>
                                                <th
                                                  nowrap="true"
                                                  className="table_header_barge_label text-right"
                                                  style={{ border: "none" }}
                                                >
                                                  Payment Due Date
                                                </th>
                                                <th
                                                  nowrap="true"
                                                  className="table_header_barge_label text-right"
                                                  style={{ border: "none" }}
                                                >
                                                  Amount
                                                </th>
                                                <th
                                                  nowrap="true"
                                                  className="table_header_barge_label"
                                                  style={{ border: "none" }}
                                                >
                                                  Invoice No
                                                </th>
                                                <th
                                                  nowrap="true"
                                                  className="table_header_barge_label"
                                                  style={{ border: "none" }}
                                                >
                                                  Description
                                                </th>
                                                <th
                                                  nowrap="true"
                                                  className="table_header_barge_label"
                                                  style={{ border: "none" }}
                                                >
                                                  Created Date
                                                </th>
                                                <th
                                                  nowrap="true"
                                                  className="table_header_barge_label"
                                                  style={{ border: "none" }}
                                                >
                                                  1st Level Approval
                                                </th>
                                                <th
                                                  nowrap="true"
                                                  className="table_header_barge_label"
                                                  style={{ border: "none" }}
                                                >
                                                  lst Level Approved on
                                                </th>
                                                <th
                                                  nowrap="true"
                                                  className="table_header_barge_label"
                                                  style={{ border: "none" }}
                                                >
                                                  2nd Level Approval
                                                </th>
                                                <th
                                                  nowrap="true"
                                                  className="table_header_barge_label"
                                                  style={{ border: "none" }}
                                                >
                                                  2nd Level Approved on
                                                </th>
                                                <th
                                                  nowrap="true"
                                                  className="table_header_barge_label text-center"
                                                  style={{ border: "none" }}
                                                >
                                                  Action
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {e.mv_pre_shipment_payments.map(
                                                (p, index) => (
                                                  <tr key={index}>
                                                    {/* <td nowrap='true' className='table_td border-0'>{p.date_of_payment ? localDateFormate(p.date_of_payment) : null}</td> *
                                                    <td
                                                      nowrap="true"
                                                      className="table_td"
                                                      style={{ minWidth: 170 }}
                                                    >
                                                      {e.approval_status ===
                                                        "Approved" &&
                                                        p.approval_status ===
                                                          "Approved" &&
                                                        (p.approval_status_2 ===
                                                          "Optional" ||
                                                          p.approval_status_2 ===
                                                            "Approved") && (
                                                          <MuiPickersUtilsProvider
                                                            utils={DateFnsUtils}
                                                          >
                                                            <DatePicker
                                                              autoOk={true}
                                                              inputVariant="outlined"
                                                              variant="inline"
                                                              margin="dense"
                                                              fullWidth
                                                              format="dd/MM/yyyy"
                                                              value={
                                                                p.date_of_payment
                                                              }
                                                              error={
                                                                this.state
                                                                  .date_of_paymentError
                                                              }
                                                              onChange={(
                                                                date
                                                              ) => {
                                                                let row =
                                                                  this.state
                                                                    .multiRows;
                                                                let rows =
                                                                  row[idx]
                                                                    .mv_pre_shipment_payments;
                                                                rows[
                                                                  index
                                                                ].date_of_payment =
                                                                  date;
                                                                this.setState(
                                                                  {
                                                                    multiRows:
                                                                      row,
                                                                    mv_pre_shipment_id:
                                                                      e.id,
                                                                    pre_shipment_payment_id:
                                                                      p.id,
                                                                    payment_posting_type:
                                                                      p.payment_posting_type,
                                                                    date_of_payment:
                                                                      date,
                                                                    amount:
                                                                      p.amount,
                                                                    invoice_no:
                                                                      e.invoice_no,
                                                                    description:
                                                                      p.description,
                                                                    date_of_paymentError: false,
                                                                  },
                                                                  () => {
                                                                    this.UpdatePaymentPostingsHandler();
                                                                  }
                                                                );
                                                              }}
                                                              InputProps={{
                                                                startAdornment:
                                                                  (
                                                                    <InputAdornment position="start">
                                                                      <IconButton>
                                                                        <EventIcon />
                                                                      </IconButton>
                                                                    </InputAdornment>
                                                                  ),
                                                                className:
                                                                  "pl-0",
                                                              }}
                                                            />
                                                          </MuiPickersUtilsProvider>
                                                        )}
                                                    </td>
                                                    <td
                                                      nowrap="true"
                                                      className="table_td border-0 text-right"
                                                    >
                                                      {localDateFormate(
                                                        p.payment_due_date
                                                      )}
                                                    </td>
                                                    <td
                                                      nowrap="true"
                                                      className="table_td border-0 text-right"
                                                    >
                                                      {p.amount
                                                        ? this.toLocaleString(
                                                            p.amount
                                                          )
                                                        : ""}
                                                    </td>
                                                    <td
                                                      nowrap="true"
                                                      className="table_td border-0"
                                                    >
                                                      {e.invoice_no}
                                                    </td>
                                                    <td
                                                      nowrap="true"
                                                      className="table_td border-0"
                                                    >
                                                      {p.description}
                                                    </td>
                                                    <td
                                                      nowrap="true"
                                                      className="table_td border-0"
                                                    >
                                                      {p.created_date
                                                        ? localDateFormate(
                                                            p.created_date
                                                          )
                                                        : null}
                                                    </td>
                                                    {e.approval_status ===
                                                      "Approved" &&
                                                    p.approval_status ===
                                                      "Pending" &&
                                                    this.Cookie.getCookie(
                                                      "loginUserId"
                                                    ) !== p.created_by &&
                                                    (this.state.features.includes(
                                                      "422"
                                                    )  ||
                                                      this.state.features.includes(
                                                        "97"
                                                      ) ) ? (
                                                      <td
                                                        nowrap="true"
                                                        className=" text-left"
                                                      >
                                                        <button
                                                          className="header_button header_button_text add_button_adjustment"
                                                          style={{
                                                            width: "auto",
                                                            marginTop: "17px",
                                                          }}
                                                          onClick={() =>
                                                            this.approvePaymentsHandler(
                                                              p.id
                                                            )
                                                          }
                                                        >
                                                          Approve
                                                        </button>
                                                      </td>
                                                    ) : (
                                                      <td
                                                        nowrap="true"
                                                        className=" text-left"
                                                      >
                                                        {p.approved_by_name
                                                          ? p.approved_by_name
                                                          : "-"}
                                                      </td>
                                                    )}
                                                    <td
                                                      nowrap="true"
                                                      className="table_td border-0"
                                                    >
                                                      {p.approved_date
                                                        ? localDateFormate(
                                                            p.approved_date
                                                          )
                                                        : "-"}
                                                    </td>
                                                    {e.approval_status ===
                                                      "Approved" &&
                                                    p.approval_status !==
                                                      "Pending" &&
                                                    p.approval_status_2 ===
                                                      "Pending" &&
                                                    this.Cookie.getCookie(
                                                      "loginUserId"
                                                    ) !== p.created_by &&
                                                    this.Cookie.getCookie(
                                                      "loginUserId"
                                                    ) !== p.approved_by &&
                                                    (this.state.features.includes(
                                                      "467"
                                                    )  ||
                                                      this.state.features.includes(
                                                        "97"
                                                      ) ) ? (
                                                      <td
                                                        nowrap="true"
                                                        className=" text-left"
                                                      >
                                                        <button
                                                          className="header_button header_button_text add_button_adjustment"
                                                          style={{
                                                            width: "auto",
                                                            marginTop: "17px",
                                                          }}
                                                          onClick={() =>
                                                            this.approvePaymentsHandler(
                                                              p.id
                                                            )
                                                          }
                                                        >
                                                          Approve
                                                        </button>
                                                      </td>
                                                    ) : (
                                                      <td
                                                        nowrap="true"
                                                        className=" text-left"
                                                      >
                                                        {p.approved_by_2_name
                                                          ? p.approved_by_2_name
                                                          : "-"}
                                                      </td>
                                                    )}
                                                    <td>
                                                      {p.approved_date_2
                                                        ? localDateFormate(
                                                            p.approved_date_2
                                                          )
                                                        : "-"}
                                                    </td>
                                                    <td
                                                      nowrap="true"
                                                      className="table_td border-0 text-center"
                                                    >
                                                      {(p.approval_status ===
                                                      "pending"
                                                        ? this.state.features.includes(
                                                            "256"
                                                          )  ||
                                                          this.state.features.includes(
                                                            "422"
                                                          )  ||
                                                          this.state.features.includes(
                                                            "97"
                                                          ) 
                                                        : p.approval_status_2 ===
                                                          "Pending"
                                                        ? this.Cookie.getCookie(
                                                            "loginUserId"
                                                          ) !== p.approved_by ||
                                                          this.state.features.includes(
                                                            "473"
                                                          )  ||
                                                          this.state.features.includes(
                                                            "97"
                                                          ) 
                                                        : this.state.features.includes(
                                                            "97"
                                                          ) ) && (
                                                        <Tooltip
                                                          title="Edit"
                                                          arrow
                                                        >
                                                          <i
                                                            className="fa fa-pencil"
                                                            style={{
                                                              fontSize: 14,
                                                              color:
                                                                config.themeColor,
                                                              cursor: "pointer",
                                                            }}
                                                            onClick={() => {
                                                              let data = {
                                                                ...this.state
                                                                  .paymentDetails,
                                                                invoice_no:
                                                                  e.invoice_no,
                                                                costing_id:
                                                                  e.id,
                                                                paymentTitle:
                                                                  "Update Pre Shipment",
                                                                payment_posting_type:
                                                                  "Pre_Shipment",
                                                                currency_xchg_rate:
                                                                  e.sac_sampling_currency_xchg_rate,
                                                                remaining_amount:
                                                                  e.remaining_amount,
                                                              };
                                                              this.setState({
                                                                paymentDetails:
                                                                  data,
                                                                paymentMode:
                                                                  "Add",
                                                                paymentsModal: true,
                                                              });
                                                            }}
                                                          ></i>
                                                        </Tooltip>
                                                      )}
                                                      {(this.state.features.includes(
                                                        "257"
                                                      )  ||
                                                        this.state.features.includes(
                                                          "97"
                                                        ) ) &&
                                                        p.approval_status ===
                                                          "Pending" && (
                                                          <DeletePayments
                                                            data={p}
                                                            table={
                                                              "mv_pre_shipment_payment_postings"
                                                            }
                                                            callbackFn={() =>
                                                              this.componentDidMount()
                                                            }
                                                          />
                                                        )}
                                                    </td>
                                                  </tr>
                                                )
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  )} */}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>

                      <ViewFiles
                        {...this.props}
                        files_data={e.files}
                        type="Costings"
                        invoice_file={e.invoice_file}
                      />
                    </div>
                    <div className="col shadow-sm p-0 mb-2 bg-white border">
                      <div className="table-responsive">
                        <table className="table table-sm table-bordered">
                          <thead className="table-header">
                            <tr>
                              <th className="text-center">Approval Type</th>
                              <th className="text-center">Approved by</th>
                              <th className="text-center">Approved On</th>
                            </tr>
                          </thead>
                          <tbody>
                            <>
                              <tr>
                                <td className="text-center">Business</td>
                                <td className="text-center">
                                  <div> {e.approved_by_name}</div>
                                </td>
                                <td className="text-center">
                                  {localDateFormate(e.approved_date)}
                                </td>
                              </tr>
                              <tr>
                                <td className="text-center">Financial</td>
                                <td className="text-center">
                                  {e.approved_by_name_2}
                                </td>
                                <td className="text-center">
                                  {localDateFormate(e.approved_date_2)}
                                </td>
                              </tr>
                            </>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
          <footer className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button btn-light"
              name="submit"
              onClick={() => {
                this.props.returnCallbackDrawerClose(false);
              }}
            >
              Close
            </button>
            <button
              type="button"
              className="drawer_text drawer_btn_success"
              name="Back"
              onClick={() => {
                this.props.returnEditCallback("MvPreShipment");
              }}
            >
              Edit
            </button>
          </footer>

          <Dialog
            open={this.state.paymentPostingsModal}
            aria-labelledby="responsive-dialog-title"
            className="payment-modal"
          >
            <DialogTitle id="responsive-dialog-title">
              {this.state.paymentTitle} Payment Posting
            </DialogTitle>
            <div className="row col-lg-12 card border">
              <div
                style={{
                  fontSize: 14,
                  color: "red",
                  textTransform: "capitalize",
                  textAlign: "center",
                }}
              >
                {this.state.paymentErrorMsg}
              </div>

              <div className="row">
                {/* <div className='col-lg-6 mb-2'>
                  <label className='contract_display_header_label'>Date of Payment <span style={{ color: 'red' }}>*</span></label>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      autoOk={true}
                      inputVariant="outlined"
                      variant="inline"
                      margin="dense"
                      fullWidth
                      format="dd/MM/yyyy"
                      value={this.state.date_of_payment}
                      error={this.state.date_of_paymentError}
                      onChange={(date) => {
                        this.setState({
                          date_of_payment: date,
                          date_of_paymentError: false
                        })
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton>
                              <EventIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div> */}

                <div className="col-lg-6 mb-2">
                  <label className="contract_display_header_label">
                    Amount <span style={{ color: "red" }}>*</span>
                  </label>
                  <TextField
                    name="amount"
                    margin="dense"
                    variant="outlined"
                    placeholder="Amount"
                    required
                    value={this.state.amount}
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    fullWidth
                    error={this.state.amountError}
                    onChange={(e) => {
                      const pr = /^\d*(\.\d{0,4})?$/;
                      if (e.target.value === "" || pr.test(e.target.value)) {
                        this.setState({
                          amount: e.target.value,
                          amountError: false,
                          paymentErrorMsg: "",
                        });
                        var remainingAmount = 0;
                        remainingAmount = Number(this.state.remainingAmount);
                        if (this.state.paymentMode === "Update") {
                          remainingAmount =
                            Number(this.state.remainingAmount) +
                            Number(this.state.existingAmount);
                        }
                        if (
                          Number(e.target.value) >
                          Math.ceil(Number(remainingAmount))
                        ) {
                          this.setState({
                            amountError: true,
                            submitting: false,
                            paymentErrorMsg:
                              "Amount is exceeding Pre Shipment value",
                          });
                          return;
                        }
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {config.company === "SRPL" || config.company === "AVS" ||
                            config.company === "PRPL"
                            ? "USD"
                            : "IDR"}
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>

                <div className="col-lg-6 mb-2">
                  <label className="contract_display_header_label">
                    Invoice No <span style={{ color: "red" }}>*</span>
                  </label>
                  <div className="mt-3 text-uppercase">
                    {this.state.invoice_no}
                  </div>

                  {/* <TextField
                    name="invoice_no"
                    margin="dense"
                    variant="outlined"
                    placeholder="Invoice No"
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    error={this.state.invoice_noError}
                    value={this.state.invoice_no}
                    onChange={(e) =>
                      this.setState({
                        invoice_no: e.target.value,
                        invoice_noError: false,
                      })
                    }
                    fullWidth
                  /> */}
                </div>

                <div className="col-lg-6 mb-2">
                  <label className="contract_display_header_label">
                    Payment Due Date{" "}
                  </label>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      autoOk={true}
                      inputVariant="outlined"
                      variant="inline"
                      margin="dense"
                      fullWidth
                      format="dd/MM/yyyy"
                      value={this.state.payment_due_date}
                      onChange={(date) => {
                        this.setState({
                          payment_due_date: date,
                        });
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton>
                              <EventIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>

                <div className="col-lg-6 mb-2">
                  <label className="contract_display_header_label">
                    Description
                  </label>

                  <TextField
                    name="description"
                    margin="dense"
                    variant="outlined"
                    placeholder="Description"
                    multiline
                    rows={3}
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    value={this.state.description}
                    onChange={(e) =>
                      this.setState({ description: e.target.value })
                    }
                    fullWidth
                  />
                </div>
              </div>
            </div>
            <div className="row bottom_buttons_section">
              <button
                type="button"
                className="btn previous_button"
                onClick={() => this.setState({ paymentPostingsModal: false })}
              >
                Cancel
              </button>
              {/* <button type="button" className="btn next_button" onClick={() => window.location.href = '/view-purchase-contract/' + btoa(this.state.clonedPC_contract_id)}>OK</button> */}
              {this.state.paymentMode === "Add" && (
                <button
                  type="button"
                  className="btn next_button"
                  onClick={this.AddPaymentPostingHandler}
                  disabled={this.state.submitting}
                >
                  <span
                    className={
                      this.state.submitting
                        ? "spinner-grow spinner-grow-sm mr-2"
                        : ""
                    }
                  ></span>
                  Submit
                </button>
              )}

              {this.state.paymentMode === "Update" && (
                <button
                  type="button"
                  className="btn next_button"
                  onClick={this.UpdatePaymentPostingsHandler}
                  disabled={this.state.submitting}
                >
                  <span
                    className={
                      this.state.submitting
                        ? "spinner-grow spinner-grow-sm mr-2"
                        : ""
                    }
                  ></span>
                  Update
                </button>
              )}
            </div>
          </Dialog>
        </div>
      );
    }
  }
}
