import React, { Component } from "react";

import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import api from "../../../api/api";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { localDateFormate, toLocaleString } from "../../common/common";
import ViewFiles from "../../common/ViewFiles";
import InvoiceForm from "../../common/InvoiceForm";
import PaymentPostings from "../../common/PaymentPostings";
import Loader from "../../common/Loader";
import InvoiceLink from "../../InvoicePdf/InvoiceLink";
import AuditInfo from "../../common/AuditInfo";
export default class MvDocChargesView extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      multiRows: [
        {
          dc_applicable: "No",
          dc_quantity_in_mt: "",
          dc_documentation_party: "",
          dc_documentation_by: "",
          dc_price_per_mt: "",
          billing_currency: "0",
          dc_currency_xchg_rate: "",
          dc_ctc_in_local_currency: "",
          dc_base_price: "",
          dc_vat_percent: "10",
          dc_vat_value: "",
          dc_ctc: "",
          dc_total: "",
          dc_pph23_percent: "2",
          dc_pph23_value: "",
          dc_laprn_surv_party: "",
          dc_laprn_surv_by: "",
          dc_laprn_surv_currency: "0",
          dc_laprn_surv_currency_xchg_rate: "",
          dc_laprn_surv_ctc_in_local_currency: "",
          dc_laprn_surv_price_per_mt: "",
          dc_laprn_surv_base_price: "",
          dc_laprn_surv_vat_percent: "10",
          dc_laprn_surv_vat_value: "",
          dc_laprn_surv_pph23_percent: "2",
          dc_laprn_surv_pph23_value: "",
          dc_laprn_surv_total: "",
          dc_laprn_surv_ctc: "",
          dc_cek_fisic: "",
        },
      ],
      other_ctc: "",
      other_vat_value: "",
      other_pph23_value: "",
      other_currency_xchg_rate: "",
      other_base_price: "",
      getBarges: [],
      isLoading: true,
      features: [],
      invoiceDialog: false,
      mother_vessel_name: "",
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (
      !(
        features.includes("275") ||
        features.includes("427") ||
        features.includes("478") ||
        features.includes("97")
      )
    ) {
      window.location.href = "/dashboard";
    }
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const businessNoID = this.props.businessNoID;
    //Get businessNumber API based on businessNumber
    await api
      .getBusinessNumber(businessNoID, loginUserID, idToken)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.business_number) {
                this.setState({
                  businessNo: res.business_number.business_no
                    ? res.business_number.business_no
                    : "",
                  mother_vessel_name: res.business_number.mother_vessel_name
                    ? res.business_number.mother_vessel_name
                    : "",
                  //isLoading: false
                });
              }
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                snackBarErrorOpen: true,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });

    const MVDocChargesID = this.props.MVDocChargesID;
    await api
      .get_mv_doc_charges(loginUserID, idToken, MVDocChargesID)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.mv_pur_fin_doc_charges) {
                var doc_total_paid_amount = 0;
                var laprn_total_paid_amount = 0;
                var others_total_paid_amount = 0;
                for (var i = 0; i < res.mv_pur_fin_doc_charges.length; i++) {
                  const filter_dc_payments = res.mv_pur_fin_doc_charges[
                    i
                  ].doc_charges_payments.filter(
                    (e, idx) => e.payment_posting_type === "doc"
                  );
                  res.mv_pur_fin_doc_charges[i].doc_payments =
                    filter_dc_payments;
                  const filter_laprn_payments = res.mv_pur_fin_doc_charges[
                    i
                  ].doc_charges_payments.filter(
                    (e, idx) => e.payment_posting_type === "laprn"
                  );
                  res.mv_pur_fin_doc_charges[i].laprn_payments =
                    filter_laprn_payments;
                  const filter_other_payments = res.mv_pur_fin_doc_charges[
                    i
                  ].doc_charges_payments.filter(
                    (e, idx) => e.payment_posting_type === "other"
                  );
                  res.mv_pur_fin_doc_charges[i].other_payments =
                    filter_other_payments;

                  res.mv_pur_fin_doc_charges[i].doc_remaining_amount = Number(
                    res.mv_pur_fin_doc_charges[i].dc_total
                  );
                  for (var j of filter_dc_payments) {
                    if (j.date_of_payment) {
                      doc_total_paid_amount += Number(j.amount);
                    }
                    res.mv_pur_fin_doc_charges[i].doc_remaining_amount =
                      Number(res.mv_pur_fin_doc_charges[i].dc_total) -
                      Number(doc_total_paid_amount);
                    res.mv_pur_fin_doc_charges[i].doc_total_paid_amount =
                      doc_total_paid_amount;
                  }

                  res.mv_pur_fin_doc_charges[i].laprn_remaining_amount = Number(
                    res.mv_pur_fin_doc_charges[i].dc_laprn_surv_total
                  );
                  for (var k of filter_laprn_payments) {
                    if (j.date_of_payment) {
                      laprn_total_paid_amount += Number(k.amount);
                    }
                    res.mv_pur_fin_doc_charges[i].laprn_remaining_amount =
                      Number(
                        res.mv_pur_fin_doc_charges[i].dc_laprn_surv_total
                      ) - Number(laprn_total_paid_amount);
                    res.mv_pur_fin_doc_charges[i].laprn_total_paid_amount =
                      laprn_total_paid_amount;
                  }
                  res.mv_pur_fin_doc_charges[i].others_remaining_amount = 0;
                  for (var key of filter_other_payments) {
                    if (j.date_of_payment) {
                      others_total_paid_amount += Number(key.amount);
                    }
                  }
                  for (var obj of res.mv_pur_fin_doc_charges[i].other_charges) {
                    res.mv_pur_fin_doc_charges[i].others_remaining_amount =
                      Number(obj.other_total) -
                      Number(others_total_paid_amount);
                    res.mv_pur_fin_doc_charges[i].others_total_paid_amount =
                      others_total_paid_amount;
                  }
                }
                this.setState({
                  multiRows: res.mv_pur_fin_doc_charges,
                  invoice_file: res.mv_pur_fin_doc_charges[0]?.invoice_file,

                  costing_no: res.mv_pur_fin_doc_charges[0]?.costing_no,
                  invoice_no: res.mv_pur_fin_doc_charges[0]?.invoice_no,
                  invoice_date: res.mv_pur_fin_doc_charges[0]?.invoice_date,
                  billing_currency:
                    res.mv_pur_fin_doc_charges[0]?.billing_currency,

                  other_ctc:
                    res.mv_pur_fin_doc_charges[0]?.other_charges[0].other_ctc,
                  other_vat_value:
                    res.mv_pur_fin_doc_charges[0]?.other_charges[0]
                      .other_vat_value,
                  other_pph23_value:
                    res.mv_pur_fin_doc_charges[0]?.other_charges[0]
                      .other_pph23_value,
                  other_currency_xchg_rate:
                    res.mv_pur_fin_doc_charges[0]?.other_charges[0]
                      .other_currency_xchg_rate,
                  isLoading: false,
                });

                if (res.mv_pur_fin_doc_charges.length <= 0) {
                  this.setState({
                    errorMsg: "No Documentation charges available.",
                    snackBarErrorOpen: true,
                  });
                  setTimeout(
                    () => this.props.returnCallbackDrawerClose(false),
                    2000
                  );
                }
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                snackBarErrorOpen: true,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  }

  approveHandler = () => {
    this.setState({ isLoading: true });
    let request = {
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
      costing_id: this.props.MVDocChargesID,
      costing_type: "MV Docuementation Charges",
      approval_status: "Approved",
    };
    api.approve_costing_api(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.setState(
              {
                snackBarSuccessOpen: true,
                successMsg: res.message,
              },
              () => this.componentDidMount()
            );
          } else if (res.code === "601") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
  };

  updateInvoice = (data) => {
    let request = {
      ...data,
      table_name: "mv_pur_fin_doc_charges",
    };
    api.update_invoice_details(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.componentDidMount();
            this.setState({
              invoiceDialog: false,
            });
          }
        });
      }
    });
  };

  renderPaymentPostings = (data) => {
    const {
      doc_charges_payments = [],
      doc_remaining_amount = 0,
      approval_status = "Pending",
      dc_currency_xchg_rate = 1,
    } = data;
    const { invoiceDetails: { invoice_no } = {} } = data;
    let payment_related_data = {
      paymentTitle: "MV Docuementation Charges",
      payment_posting_type: "Doc_Charges",
      invoice_no: invoice_no,
      outstanding_amount: doc_remaining_amount,
      billing_currency:
        config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL"
          ? "USD"
          : "IDR",
      currency_xchg_rate: dc_currency_xchg_rate,
      costing_id: this.props.docID,
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
    };
    let access_details = {
      costing_approval: approval_status,
      add_payment: "186",
      view_payment: "187",
      update_payment: "188",
      delete_payment: "189",
      approver: "403",
      approver2: "454",
    };
    return (
      <PaymentPostings
        access_details={access_details}
        payments={doc_charges_payments}
        costingType="Docuementation Charges"
        data={payment_related_data}
        callbackFn={() => {
          this.componentDidMount();
        }}
      />
    );
  };

  renderInvoiceLink = () => {
    const { costing_no, invoice_no = "", invoice_date = "" } = this.state;

    const Grand_total = this.state.multiRows[0].dc_total
      ? parseFloat(this.state.multiRows[0].dc_total).toFixed(2)
      : 0;

    const invoiceObject = {
      costing_id: this.state.multiRows[0].costing_no,
      to: this.state.multiRows[0].dc_documentation_name,
      business_no: this.state.mother_vessel_name,
      costing_name: "MV Documentation Charges",
      invoice_no: invoice_no,
      invoice_date: localDateFormate(invoice_date),
      bargeDetails: [],
      billing_currency: "IDR",
      total_payable_amount: Grand_total,
      purchase_type: "FOB Barge",
      description: [
        {
          qty: this.state.multiRows[0].dc_quantity_in_mt,
          adjustedPrice: this.state.multiRows[0].dc_price_per_mt,
          desc: `${
            (this,
            this.state.multiRows[0].dc_currency_xchg_rate > 1
              ? `Exchange rate ${parseFloat(
                  this.state.multiRows[0].dc_currency_xchg_rate
                ).toFixed(2)}`
              : " ")
          }`,
          amount: this.state.multiRows[0].dc_base_price,
        },
        ...(this.state.pph15_account !== "SDAM"
          ? [
              {
                qty: " ",
                adjustedPrice: " ",
                desc: "VAT",
                amount: `${this.state.multiRows[0].dc_vat_value}`,
              },
            ]
          : []),
        ...(!(
          config.company === "SRPL" ||
          config.company === "AVS" ||
          config.company === "PRPL"
        )
          ? [
              {
                qty: " ",
                adjustedPrice: " ",
                desc: "PPH",
                amount: `( ${this.state.multiRows[0].dc_pph23_value} )`,
              },
            ]
          : []),
        //lapran survey doc charges
        {
          qty: this.state.multiRows[0].dc_laprn_quantity_in_mt,
          adjustedPrice: this.state.multiRows[0].dc_laprn_surv_price_per_mt,
          desc: `Lapron Survey Charges @${
            this.state.multiRows[0].dc_laprn_surv_currency_xchg_rate
              ? parseFloat(
                  this.state.multiRows[0].dc_laprn_surv_currency_xchg_rate
                ).toFixed(2)
              : ""
          }`,
          amount: this.state.multiRows[0].dc_laprn_surv_total,
        },
        {
          qty: " ",
          adjustedPrice: " ",
          desc: "VAT",
          amount: `${this.state.multiRows[0].dc_laprn_surv_vat_value}`,
        },
        ...(!(
          config.company === "SRPL" ||
          config.company === "AVS" ||
          config.company === "PRPL"
        )
          ? [
              {
                qty: " ",
                adjustedPrice: " ",
                desc: "PPH",
                amount: `( ${
                  this.state.multiRows[0].dc_laprn_surv_pph23_value
                    ? this.state.multiRows[0].dc_laprn_surv_pph23_value
                    : 0
                } )`,
              },
            ]
          : []),
        //others survey doc charges
        {
          qty: " ",
          adjustedPrice: `${toLocaleString(this.state.other_base_price)}`,
          desc: `Others @ ${
            this.state.other_currency_xchg_rate
              ? parseFloat(this.state.other_currency_xchg_rate).toFixed(2)
              : ""
          }`,
          amount: `${toLocaleString(this.state.other_ctc)}`,
        },
        {
          qty: " ",
          adjustedPrice: " ",
          desc: "VAT",
          amount: `${toLocaleString(this.state.other_vat_value)}`,
        },
        ...(!(
          config.company === "SRPL" ||
          config.company === "AVS" ||
          config.company === "PRPL"
        )
          ? [
              {
                qty: " ",
                adjustedPrice: " ",
                desc: "PPH",
                amount: `( ${
                  this.state.other_pph23_value
                    ? this.state.other_pph23_value
                    : 0
                } )`,
              },
            ]
          : []),
      ],
    };
    return <InvoiceLink data={invoiceObject} fileName={costing_no} />;
  };

  render() {
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div className="container p-0" style={{ minHeight: "100vh" }}>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          {this.state.multiRows.map((e, idx) => (
            <div className="clearfix content-header border-bottom">
              <div className="row">
                <div className="col-sm text-left pl-0">
                  <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                    {`Documentation Charges - ${e.costing_no}`}
                    <span
                      className={
                        e.approval_status_2 === "Pending" ? "type_2" : "type_1"
                      }
                      style={{ padding: 8, marginLeft: 16 }}
                    >
                      {e.approval_status_2}
                    </span>
                  </h4>
                </div>
                {e.approval_status === "Pending" &&
                  e.invoice_file &&
                  this.Cookie.getCookie("loginUserId") !== e.created_by &&
                  e.approved_by !== this.Cookie.getCookie("loginUserId") &&
                  (this.state.features.includes("427") ||
                    this.state.features.includes("478") ||
                    this.state.features.includes("97")) && (
                    <div
                      className="col text-right float-sm-left"
                      style={{ padding: "5px 20px" }}
                    >
                      <button
                        className="header_button header_button_text add_button_adjustment"
                        style={{ width: "auto", marginTop: "17px" }}
                        onClick={this.approveHandler}
                      >
                        Approve
                      </button>
                    </div>
                  )}
                <div className="col-lg-1 pl-0 float-right">
                  {this.renderInvoiceLink()}
                </div>
              </div>
            </div>
          ))}
          <div className="drawer-section">
            <div className="card p-3">
              <div className="card-body p-0">
                {this.state.multiRows.map((p, idx) => (
                  <div className="row" key={idx}>
                    <div className="col-lg-12 border_dashed rounded-lg p-2 mb-2">
                      <div className="row mb-2 ">
                        <div className="table-responsive">
                          <table className="table table-bordered table-sm">
                            <thead className="table-header">
                              <tr>
                                <th className="text-center">Description</th>
                                <th className="text-right">Qty</th>
                                <th className="text-right">Unit Price</th>
                                <th className="text-right">Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  {`${
                                    p.dc_currency_xchg_rate > 1
                                      ? `Exchange Rate @ ${toLocaleString(
                                          p.dc_currency_xchg_rate
                                        )}`
                                      : ``
                                  }`}
                                  {`Price Type : ${p.dc_price_type}`}
                                </td>
                                <td className="text-right">
                                  {toLocaleString(p.dc_quantity_in_mt)}
                                </td>
                                <td className="text-right">
                                  {toLocaleString(p.dc_price_per_mt)}
                                </td>
                                <td className="text-right">
                                  {toLocaleString(p.dc_base_price)}
                                </td>
                              </tr>
                              <tr>
                                <td>{`VAT @ ${p.dc_vat_percent} `}</td>
                                <td className="text-right"></td>
                                <td className="text-right"></td>
                                <td className="text-right">
                                  {toLocaleString(p.dc_vat_value)}
                                </td>
                              </tr>
                              {!(
                                config.company === "SRPL" ||
                                config.company === "PRPL" ||
                                config.company === "AVS"
                              ) && (
                                <tr>
                                  <td>{`PPH @ ${p.dc_pph23_percent} `}</td>
                                  <td className="text-right"></td>
                                  <td className="text-right"></td>
                                  <td className="text-right">{`( ${toLocaleString(
                                    p.dc_pph23_value
                                  )} )`}</td>
                                </tr>
                              )}

                              {p.other_charges.length > 0 && (
                                <>
                                  <tr>
                                    <td>
                                      {p.other_charges?.map((obj) => (
                                        <div>
                                          Other Charges -{" "}
                                          {obj.other_description}
                                        </div>
                                      ))}
                                    </td>
                                    <td className="text-right"></td>
                                    <td className="text-right"></td>
                                    <td className="text-right">{`${toLocaleString(
                                      p.other_charges.reduce(
                                        (acc, item) =>
                                          Number(acc ? acc : 0) +
                                          Number(
                                            item.other_base_price
                                              ? item.other_base_price
                                              : 0
                                          ),
                                        0
                                      )
                                    )}`}</td>
                                  </tr>
                                  <tr>
                                    <td>{`VAT @ 10 `} </td>
                                    <td className="text-right"></td>
                                    <td className="text-right"></td>
                                    <td className="text-right">{`${toLocaleString(
                                      p.other_charges.reduce(
                                        (acc, item) =>
                                          Number(acc ? acc : 0) +
                                          Number(
                                            item.other_vat_value
                                              ? item.other_vat_value
                                              : 0
                                          ),
                                        0
                                      )
                                    )}`}</td>
                                  </tr>
                                </>
                              )}

                              <tr>
                                <td
                                  colSpan={3}
                                  className="text-right "
                                  style={{ fontWeight: "bold" }}
                                >
                                  {` Payable to ${p.dc_documentation_name}`}
                                </td>
                                <td
                                  className="text-right "
                                  style={{ fontWeight: "bold" }}
                                >{`${toLocaleString(p.dc_total)} ${
                                  config.company === "SRPL" ||
                                  config.company === "PRPL" ||
                                  config.company === "AVS"
                                    ? "USD"
                                    : "IDR"
                                }`}</td>
                              </tr>
                              <tr>
                                <td
                                  colSpan={3}
                                  className="text-right "
                                  style={{ fontWeight: "bold" }}
                                >
                                  {` Paid`}
                                </td>
                                <td
                                  className="text-right "
                                  style={{ fontWeight: "bold" }}
                                >{`${toLocaleString(p.paid)}`}</td>
                              </tr>
                              <tr>
                                <td
                                  colSpan={3}
                                  className="text-right "
                                  style={{ fontWeight: "bold" }}
                                >
                                  {` Remaining Amount`}
                                </td>
                                <td
                                  className="text-right "
                                  style={{ fontWeight: "bold" }}
                                >{`${toLocaleString(p.dc_total)}`}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        {/* <div className="col-lg-6 pl-0">
                        <div className="row">
                          <div className="col-lg-12 mb-2">
                            <label className="contract_display_header_label">
                              Documentation by
                            </label>
                            <div className="contract_display_header_value">
                              {p.dc_documentation_name
                                ? p.dc_documentation_name
                                : "-"}
                            </div>
                          </div>
                          <div className="col-lg-6 mb-2">
                            <label className="contract_display_header_label">
                              Quantity in MT
                            </label>
                            <div className="contract_display_header_value">
                              {p.dc_quantity_in_mt
                                ? toLocaleString(p.dc_quantity_in_mt)
                                : "-"}
                            </div>
                          </div>

                          <div className="col-lg-6 mb-2">
                            <label className="contract_display_header_label">
                              Documentation Party
                            </label>
                            <div className="contract_display_header_value">
                              {p.dc_documentation_party
                                ? p.dc_documentation_party
                                : "-"}
                            </div>
                          </div>
                          {p.dc_documentation_party === "Third Party" && (
                            <div className="col-lg-6 mb-2 pr-0">
                              <label className="contract_display_header_label">
                                Price per MT
                              </label>
                              <div className="contract_display_header_value">
                                {p.dc_price_per_mt
                                  ? toLocaleString(p.dc_price_per_mt)
                                  : 0}{" "}
                                {p.billing_currency !== "0"
                                  ? p.billing_currency
                                  : ""}
                              </div>
                            </div>
                          )}
                          {(config.company === "SRPL" ||
                            config.company === "AVS" ||
                            config.company === "PRPL") &&
                            p.dc_documentation_party === "Internal" && (
                              <div className="col-lg-6 mb-2">
                                <label className="contract_display_header_label">
                                  Currency
                                </label>
                                <div className="contract_display_header_value">
                                  {p.billing_currency
                                    ? p.billing_currency
                                    : "-"}
                                </div>
                              </div>
                            )}

                          {(config.company === "SRPL" ||
                            config.company === "AVS" ||
                            config.company === "PRPL") &&
                            p.billing_currency !== "USD" &&
                            p.billing_currency !== null && (
                              <div className="col-lg-6 mb-2">
                                <label className="contract_display_header_label">
                                  Exchange Rate USD to {p.billing_currency}
                                </label>
                                <div className="contract_display_header_value">
                                  {p.dc_currency_xchg_rate
                                    ? p.dc_currency_xchg_rate
                                    : "-"}
                                </div>
                              </div>
                            )}
                          {(config.company === "SDAM" ||
                            config.company === "SRK" ||
                            config.company === "BTR") && (
                            <div className="col-lg-6 mb-2">
                              <label className="contract_display_header_label">
                                PPH23 Account
                              </label>
                              <div className="contract_display_header_value">
                                {p.dc_pph23_account ? p.dc_pph23_account : "-"}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      {(config.company === "SRPL" ||
                        config.company === "AVS" ||
                        config.company === "PRPL" ||
                        ((config.company === "SDAM" ||
                          config.company === "SRK" ||
                          config.company === "BTR") &&
                          p.dc_pph23_account === "Vendor")) && (
                        <div
                          className="col-lg-6 p-2 rounded"
                          style={{ backgroundColor: "#f7f3f0" }}
                        >
                          <div className="row col-lg-12 p-0">
                            <h6>Documentation Charges</h6>
                          </div>
                          <div className="row">
                            <div className="col-4 mb-2">
                              <label
                                className="contract_display_header_label"
                                style={{ color: "rgba(72, 59, 52, 0.7)" }}
                              >
                                Base price
                              </label>
                              <div
                                className="contract_display_header_value"
                                style={{ color: "#42352d" }}
                              >
                                {p.dc_base_price
                                  ? toLocaleString(p.dc_base_price)
                                  : "-"}{" "}
                                {p.billing_currency}
                              </div>
                            </div>
                            <div className="col-4 mb-2">
                              <label
                                className="contract_display_header_label"
                                style={{ color: "rgba(72, 59, 52, 0.7)" }}
                              >
                                VAT %
                              </label>
                              <div
                                className="contract_display_header_value"
                                style={{ color: "#42352d" }}
                              >
                                {p.dc_vat_percent
                                  ? toLocaleString(p.dc_vat_percent)
                                  : "-"}
                              </div>
                            </div>
                            <div className="col-4mb-2">
                              <label
                                className="contract_display_header_label"
                                style={{ color: "rgba(72, 59, 52, 0.7)" }}
                              >
                                VAT
                              </label>
                              <div
                                className="contract_display_header_value"
                                style={{ color: "#42352d" }}
                              >
                                {p.dc_vat_value
                                  ? toLocaleString(p.dc_vat_value)
                                  : "-"}{" "}
                                {p.billing_currency}
                              </div>
                            </div>
                            {(config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") && (
                              <div className="col-lg-6 mb-2">
                                <label
                                  className="contract_display_header_label"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  Includes PPH23 @ 2%
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {p.dc_pph23_value
                                    ? toLocaleString(p.dc_pph23_value)
                                    : "-"}
                                </div>
                              </div>
                            )}
                          </div>

                          <div className="row">
                            <div className="col-lg-6">
                              <label
                                className="contract_display_header_label"
                                style={{ color: "rgba(72, 59, 52, 0.7)" }}
                              >
                                Payable to Vendor
                              </label>
                              <div
                                className="contract_display_header_value"
                                style={{ color: "#42352d" }}
                              >
                                {p.dc_total ? toLocaleString(p.dc_total) : 0}{" "}
                                {config.company === "SRPL" ||
                                config.company === "AVS" ||
                                config.company === "PRPL"
                                  ? "USD"
                                  : "IDR"}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <label
                                className="contract_display_header_label"
                                style={{ color: "rgba(72, 59, 52, 0.7)" }}
                              >
                                Cost to Company
                              </label>
                              <div
                                className="contract_display_header_value"
                                style={{ color: "#42352d" }}
                              >
                                {p.dc_ctc ? toLocaleString(p.dc_ctc) : 0}{" "}
                                {config.company === "SRPL" ||
                                config.company === "AVS" ||
                                config.company === "PRPL"
                                  ? "USD"
                                  : "IDR"}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {(config.company === "SDAM" ||
                        config.company === "SRK" ||
                        config.company === "BTR") &&
                        p.dc_pph23_account === "SDAM" && (
                          <div
                            className="col-lg-6 p-2 rounded"
                            style={{ backgroundColor: "#f7f3f0" }}
                          >
                            <div className="row col-lg-12 p-0">
                              <h6>Documentation Charges</h6>
                            </div>
                            <div className="row">
                              <div className="col-6 mb-2">
                                <label
                                  className="contract_display_header_label"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  Base price
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {p.dc_base_price
                                    ? toLocaleString(p.dc_base_price)
                                    : "-"}
                                </div>
                              </div>
                              <div className="col-lg-6 mb-2">
                                <label
                                  className="contract_display_header_label"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  Includes PPH23 @ 2%
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {p.dc_pph23_value
                                    ? toLocaleString(p.dc_pph23_value)
                                    : "-"}
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-lg-6">
                                <label
                                  className="contract_display_header_label"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  Payable to Vendor
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {p.dc_total ? toLocaleString(p.dc_total) : 0}{" "}
                                  {config.company === "SRPL" ||
                                  config.company === "AVS" ||
                                  config.company === "PRPL"
                                    ? "USD"
                                    : "IDR"}
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <label
                                  className="contract_display_header_label"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  Cost to Company
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {p.dc_ctc ? toLocaleString(p.dc_ctc) : 0}{" "}
                                  {config.company === "SRPL" ||
                                  config.company === "AVS" ||
                                  config.company === "PRPL"
                                    ? "USD"
                                    : "IDR"}
                                </div>
                              </div>
                            </div>
                          </div>
                        )} */}
                      </div>

                      <div className="card col-lg-12 p-0 mb-2">
                        <div className="card-body p-0">
                          <InvoiceForm
                            open={this.state.invoiceDialog}
                            data={p}
                            costingID={p.id}
                            callbackFn={(data) => this.updateInvoice(data)}
                          />
                          {this.renderPaymentPostings(p)}

                          {/* {(this.state.features.includes("279") ||
                            this.state.features.includes("97")) && (
                            <div>
                              <div className="row">
                                <div className="col-lg-12">
                                  <div className="clearfix">
                                    <div className="float-left">
                                      {!p.doc_payments
                                        ? ""
                                        : p.doc_payments.length <= 0 && (
                                            <p style={{ fontWeight: "bold" }}>
                                              No payments made
                                            </p>
                                          )}
                                    </div>
                                    <div className="float-right">
                                      {(this.state.features.includes("278") ||
                                        this.state.features.includes("97")) &&
                                        p.invoice_no && (
                                          <button
                                            className="header_button header_button_text  w-auto"
                                            onClick={() => {
                                              let data = {
                                                ...this.state.paymentDetails,
                                                invoice_no: p.invoice_no,
                                                costing_id: p.id,
                                                paymentTitle:
                                                  "Add Documentation Charges",
                                                payment_posting_type: "doc",
                                                currency_xchg_rate:
                                                  p.dc_currency_xchg_rate,
                                                remaining_amount:
                                                  p.doc_remaining_amount,
                                              };
                                              this.setState({
                                                paymentDetails: data,
                                                paymentMode: "Add",
                                                paymentsModal: true,
                                              });
                                            }}
                                          >
                                            Add Payment
                                          </button>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {p.doc_payments?.length > 0 && (
                                <div className="row mt-2">
                                  <div className="col-lg">
                                    <div className="table-responsive">
                                      <table className="table table-bordered table-sm">
                                        <thead className="table-header">
                                          <tr>
                                            <th
                                              className=" text-left"
                                              nowrap="true"
                                            >
                                              Date of Payment
                                            </th>
                                            <th
                                              className=" text-right"
                                              nowrap="true"
                                            >
                                              Payment Due Date
                                            </th>
                                            <th
                                              className=" text-right"
                                              nowrap="true"
                                            >
                                              Amount
                                            </th>
                                            <th
                                              className=" text-left"
                                              nowrap="true"
                                            >
                                              Invoice No
                                            </th>
                                            <th
                                              style={{
                                                width: 300,
                                                maxWidth: 250,
                                              }}
                                            >
                                              Description
                                            </th>
                                            <th nowrap="true">Created Date</th>
                                            <th
                                              nowrap="true"
                                              className=" text-left"
                                            >
                                              1st Level Approval
                                            </th>
                                            <th
                                              nowrap="true"
                                              className=" text-left"
                                            >
                                              1st Level Approved On
                                            </th>
                                            <th
                                              nowrap="true"
                                              className=" text-left"
                                            >
                                              2nd Level Approval
                                            </th>
                                            <th
                                              nowrap="true"
                                              className=" text-left"
                                            >
                                              2nd Level Approved On
                                            </th>
                                            <th nowrap="true">Action</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {p.doc_payments.map((v, index) => (
                                            <tr key={index}>
                                              <td
                                                nowrap="true"
                                                className="table_td"
                                                style={{ minWidth: 170 }}
                                              >
                                                {p.approval_status ===
                                                  "Approved" &&
                                                  v.approval_status ===
                                                    "Approved" &&
                                                  (v.approval_status_2 ===
                                                    "Optional" ||
                                                    v.approval_status_2 ===
                                                      "Approved") && (
                                                    <MuiPickersUtilsProvider
                                                      utils={DateFnsUtils}
                                                    >
                                                      <DatePicker
                                                        autoOk={true}
                                                        inputVariant="outlined"
                                                        variant="inline"
                                                        margin="dense"
                                                        fullWidth
                                                        format="dd/MM/yyyy"
                                                        value={
                                                          v.date_of_payment
                                                        }
                                                        error={
                                                          this.state
                                                            .date_of_paymentError
                                                        }
                                                        onChange={(date) => {
                                                          let row =
                                                            this.state
                                                              .multiRows;
                                                          let rows =
                                                            row[idx]
                                                              .doc_payments;
                                                          rows[
                                                            index
                                                          ].date_of_payment = date;

                                                          this.setState(
                                                            {
                                                              multiRows: row,
                                                              mv_doc_charges_id:
                                                                p.id,
                                                              doc_charges_payment_id:
                                                                v.id,
                                                              payment_posting_type:
                                                                v.payment_posting_type,
                                                              date_of_payment:
                                                                date,
                                                              amount: v.amount,
                                                              invoice_no:
                                                                p.invoice_no,
                                                              description:
                                                                v.description,
                                                              date_of_paymentError: false,
                                                            },
                                                            () => {
                                                              this.UpdatePaymentPostingsHandler();
                                                            }
                                                          );
                                                        }}
                                                        InputProps={{
                                                          startAdornment: (
                                                            <InputAdornment position="start">
                                                              <IconButton>
                                                                <EventIcon />
                                                              </IconButton>
                                                            </InputAdornment>
                                                          ),
                                                          className: "pl-0",
                                                        }}
                                                      />
                                                    </MuiPickersUtilsProvider>
                                                  )}
                                              </td>
                                              <td
                                                nowrap="true"
                                                className="text-right"
                                              >
                                                {localDateFormate(
                                                  v.payment_due_date
                                                )}
                                              </td>
                                              <td
                                                nowrap="true"
                                                className="text-right"
                                              >
                                                {v.amount
                                                  ? toLocaleString(v.amount)
                                                  : ""}
                                              </td>
                                              <td nowrap="true">
                                                {p.invoice_no}
                                              </td>
                                              <td
                                                style={{
                                                  whiteSpace: "pre-line",
                                                }}
                                              >
                                                {v.description}
                                              </td>
                                              <td nowrap="true">
                                                {v.created_date
                                                  ? localDateFormate(
                                                      v.created_date
                                                    )
                                                  : null}
                                              </td>
                                              {p.approval_status ===
                                                "Approved" &&
                                              v.approval_status === "Pending" &&
                                              this.Cookie.getCookie(
                                                "loginUserId"
                                              ) !== p.created_by &&
                                              (this.state.features.includes(
                                                "428"
                                              ) ||
                                                this.state.features.includes(
                                                  "97"
                                                )) ? (
                                                <td
                                                  nowrap="true"
                                                  className=" text-left"
                                                >
                                                  <button
                                                    className="header_button header_button_text add_button_adjustment"
                                                    style={{
                                                      width: "auto",
                                                      marginTop: "17px",
                                                    }}
                                                    onClick={() =>
                                                      this.approvePaymentsHandler(
                                                        v.id
                                                      )
                                                    }
                                                  >
                                                    Approve
                                                  </button>
                                                </td>
                                              ) : (
                                                <td
                                                  nowrap="true"
                                                  className=" text-left"
                                                >
                                                  {v.approved_by_name
                                                    ? v.approved_by_name
                                                    : "-"}
                                                </td>
                                              )}
                                              <td
                                                nowrap="true"
                                                className=" text-left"
                                              >
                                                {v.approved_date
                                                  ? localDateFormate(
                                                      v.approved_date
                                                    )
                                                  : "-"}
                                              </td>
                                              {p.approval_status ===
                                                "Approved" &&
                                              v.approval_status_2 ===
                                                "Pending" &&
                                              this.Cookie.getCookie(
                                                "loginUserId"
                                              ) !== v.created_by &&
                                              this.Cookie.getCookie(
                                                "loginUserId"
                                              ) !== v.approved_by &&
                                              (this.state.features.includes(
                                                "479"
                                              ) ||
                                                this.state.features.includes(
                                                  "97"
                                                )) ? (
                                                <td
                                                  nowrap="true"
                                                  className=" text-left"
                                                >
                                                  <button
                                                    className="header_button header_button_text add_button_adjustment"
                                                    style={{
                                                      width: "auto",
                                                      marginTop: "17px",
                                                    }}
                                                    onClick={() =>
                                                      this.approvePaymentsHandler(
                                                        v.id
                                                      )
                                                    }
                                                  >
                                                    Approve
                                                  </button>
                                                </td>
                                              ) : (
                                                <td
                                                  nowrap="true"
                                                  className=" text-left"
                                                >
                                                  {p.approved_by_2_name
                                                    ? p.approved_by_2_name
                                                    : "-"}
                                                </td>
                                              )}
                                              <td
                                                nowrap="true"
                                                className=" text-left"
                                              >
                                                {p.approved_date_2
                                                  ? localDateFormate(
                                                      p.approved_date_2
                                                    )
                                                  : "-"}
                                              </td>
                                              <td nowrap="true">
                                                {(p.approval_status ===
                                                "pending"
                                                  ? this.state.features.includes(
                                                      "280"
                                                    ) ||
                                                    this.state.features.includes(
                                                      "428"
                                                    ) ||
                                                    this.state.features.includes(
                                                      "97"
                                                    )
                                                  : p.approval_status_2 ===
                                                    "Pending"
                                                  ? this.Cookie.getCookie(
                                                      "loginUserId"
                                                    ) !== p.approved_by ||
                                                    this.state.features.includes(
                                                      "479"
                                                    ) ||
                                                    this.state.features.includes(
                                                      "97"
                                                    )
                                                  : this.state.features.includes(
                                                      "97"
                                                    )) && (
                                                  <Tooltip title="Edit" arrow>
                                                    <i
                                                      className="fa fa-pencil mr-2"
                                                      style={{
                                                        fontSize: 14,
                                                        color:
                                                          config.themeColor,
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() => {
                                                        let data = {
                                                          ...v,
                                                          ...this.state
                                                            .paymentDetails,
                                                          invoice_no:
                                                            p.invoice_no,
                                                          costing_id: p.id,
                                                          paymentTitle:
                                                            "Update Documentation Charges",
                                                          payment_posting_type:
                                                            "doc",
                                                          currency_xchg_rate:
                                                            p.dc_currency_xchg_rate,
                                                          remaining_amount:
                                                            p.doc_remaining_amount,
                                                        };
                                                        this.setState({
                                                          paymentDetails: data,
                                                          paymentMode: "Update",
                                                          paymentsModal: true,
                                                        });
                                                      }}
                                                    ></i>
                                                  </Tooltip>
                                                )}

                                                {(this.state.features.includes(
                                                  "281"
                                                ) ||
                                                  this.state.features.includes(
                                                    "97"
                                                  )) &&
                                                  v.approval_status ===
                                                    "Pending" && (
                                                    <DeletePayments
                                                      data={v}
                                                      table={
                                                        "mv_doc_charges_payment_postings"
                                                      }
                                                      callbackFn={() =>
                                                        this.componentDidMount()
                                                      }
                                                    />
                                                  )}
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          )} */}
                        </div>
                      </div>
                    </div>

                    <ViewFiles
                      {...this.props}
                      files_data={p.files}
                      type="Costings"
                      invoice_file={p.invoice_file}
                    />

                    <div className="col-lg-12 p-0">
                      <AuditInfo info={p} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <footer className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button btn-light"
              name="submit"
              onClick={() => this.props.returnCallbackDrawerClose(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="drawer_text drawer_btn_success"
              name="Back"
              onClick={() => this.props.returnEditCallback("MvDoc")}
            >
              Edit
            </button>
          </footer>
        </div>
      );
    }
  }
}
