import React, { Component } from 'react';

import CookieHandler from '../common/CookieHandler';
import config from '../../config/config';
import api from '../../api/api';
import { TextField, Snackbar } from '@material-ui/core';

import { Alert } from '@material-ui/lab';
import Loader from '../common/Loader';
export default class BusinessNotifyPartyUpdate extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      activeStep: 0,
      notify_party_data: [
        {
          name: '',
          phone_no: '',
          address: '',
        }
      ],

      isLoading: true,
    }
  }

  componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace('/logout');
    }
    const loginUserID = this.Cookie.getCookie('loginUserId');
    const idToken = this.Cookie.getIdTokenCookie();
    if (this.props.comboID) {
      const notify_party_id = this.props.comboID;
      api.get_business_no_notify_party(loginUserID, idToken, notify_party_id).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === '200') {
              this.setState({
                notify_party_data: res.notify_party_data,
                isLoading: false
              })
            } else if (res.code === '601') {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
                isLoading: false
              })
            } else if (res.code === '607') {
              window.location.href = '/logout';
            }
          })
        }

      })
    } else {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: 'Invalid id.',
        isLoading: false
      })
    }
  }

  addMoreRowsHandler = () => {
    const item = { name: '', phone_no: '', address: '' };
    this.setState({
      notify_party_data: [...this.state.notify_party_data, item]
    });
  }

  removeRowHandler = (idx) => () => {
    const row = this.state.notify_party_data;
    row.splice(idx, 1);
    this.setState({
      notify_party_data: row
    });
  }

  submitHandler = () => {
    const loginUserID = this.Cookie.getCookie('loginUserId');
    const idToken = this.Cookie.getIdTokenCookie();
    const business_no_id = this.props.businessNo;
    const notify_party_data = this.state.notify_party_data;
    let validation = false;
    for (var i of notify_party_data) {
      if (i.name === '') {
        i.name_error = true;
        validation = true;
      }
      if (i.name !== '' && i.name) {
        i.name.toUpperCase()
      }
      else if (i.address !== '' && i.address) {
        i.address.toUpperCase()
      }
    }
    if (validation) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please fill mandatory fields"
      });
      return;
    }

    if (this.props.businessNo) {
      api.update_business_no_notify_party(loginUserID, idToken, business_no_id, notify_party_data).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === '200') {
              this.setState({
                snackBarSuccessOpen: true,
                successMsg: res.message
              }, () => {
                this.props.onUpdateCosting(true);
              })

            } else if (res.code === '601') {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message
              });
            } else if (res.code === '607') {
              window.location.href = '/logout';
            }

          })
        }
      })

    }

  }

  render() {
    if (this.state.isLoading) {
      return <Loader />
    }
    else {
      return (
        <div className='container bg-white p-0' style={{ minHeight: '100vh' }}>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={this.state.snackBarErrorOpen} onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: '450px' }}
          >
            <Alert elevation={6} variant="filled" onClose={() => this.setState({ snackBarErrorOpen: false })} severity="error" style={{ width: '100%' }}>
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            style={{ width: '450px' }}
            open={this.state.snackBarSuccessOpen} autoHideDuration={100000} onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert elevation={6} variant="filled" onClose={() => this.setState({ snackBarSuccessOpen: false })} severity="success" style={{ width: '100%' }}>
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <div className='row'>
            <div className='col-lg-12 p-0 border-bottom'>
              <h6 style={{ padding: '15px', marginBottom: 0 }}>Business Nofity Party</h6>
            </div>
          </div>
          <div className='drawer-section'>
            <div className='card p-0' >
              <div className='card-body'>
                {this.state.notify_party_data.map((e, idx) => (
                  <div className='row' key={idx}>
                    <div className='col-lg-11 p-3 border_dashed mb-3 rounded-lg'>
                      <div className='row'>
                        <div className='col-lg-6 pl-0'>
                          <label className='form_label mb-0'>Name <span style={{ color: 'red' }}>*</span></label>
                          <TextField
                            name='name'
                            margin='dense'
                            variant='outlined'
                            inputProps={{ style: { textTransform: 'uppercase' } }}
                            value={e.name}
                            fullWidth
                            error={e.name_error}
                            onChange={(e) => {
                              let row = this.state.notify_party_data;
                              row[idx].name = e.target.value;
                              this.setState({
                                notify_party_data: row
                              });
                            }}
                          />

                        </div>
                        <div className='col-lg-6 pr-0'>
                          <label className='form_label mb-0'>Phone Number</label>
                          <TextField
                            name='phone_number'
                            margin='dense'
                            variant='outlined'
                            value={e.phone_no}
                            fullWidth
                            onChange={(e) => {
                              let row = this.state.notify_party_data;
                              row[idx].phone_no = e.target.value;
                              this.setState({
                                notify_party_data: row
                              });
                            }}
                          />
                        </div>
                        <div className='col-lg-12 pl-0'>
                          <label className='form_label mb-0'>Address</label>
                          <TextField
                            name='address'
                            margin='dense'
                            variant='outlined'
                            inputProps={{ style: { textTransform: 'uppercase' } }}
                            value={e.address}
                            fullWidth
                            multiline
                            rows={4}
                            onChange={(e) => {
                              let row = this.state.notify_party_data;
                              row[idx].address = e.target.value;
                              this.setState({
                                notify_party_data: row
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    {this.state.notify_party_data.length > 1 &&

                      <div className='col-lg-1 d-flex justify-content-center'>
                        <div className="text-align-center">
                          <i className="fa fa-trash-o m-auto" onClick={this.removeRowHandler(idx)} style={{ fontSize: 24, color: config.themeColor, cursor: 'pointer' }} />
                        </div>
                      </div>
                    }
                  </div>
                ))}
                <div className='row mt-2'>
                  <button type='button' style={{ color: config.themeColor, width: 110 }} className='header_button header_button_text addrow_button_adjustment' onClick={this.addMoreRowsHandler}>Add Row</button>
                </div>
              </div>
            </div>

          </div>

          <footer className='row bottom_buttons_section'>
            <button type='button' className='btn previous_button btn-light' name='submit' onClick={() => {
              this.props.returnCallbackViewPage(true);
            }}>Cancel</button>
            <button type='button' className='drawer_text drawer_btn_success' name='Back' onClick={this.submitHandler}>UPDATE</button>
          </footer>
        </div>

      )
    }
  }

}