import React from "react";
import CookieHandler from "../common/CookieHandler";
import api from "../../api/api";
import config from "../../config/config";
import SideBar from "../common/SideBar";
import Header from "../common/Header";
import { Drawer } from "@material-ui/core";
import MaterialTable from "material-table";
import exportToExcel from "../Export_To_Excel";

import ServiceRequestAdd from "./serviceRequestAdd";
import ServiceRequestView from "./serviceRequestView";
import ServiceRequestUpdate from "./serviceRequestUpdate";
import { localDateFormate, pad, toLocaleString } from "../common/common";
import Loader from "../common/Loader";

class ServicesList extends React.Component {
  constructor() {
    super();
    this.Cookie = new CookieHandler();
    this.state = {
      rawData: null,
      data: [],
      pages: null,
      loading: true,
      isLoading: true,
      errorMessage: "",
      successMessage: "",
      services: [],
      features: [],
      showDrawer: false,
      newServiceRequest: false,
      viewServiceRequest: false,
      updateServiceRequest: false,
      updateDocuments: false,
    };
  }

  componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    } else {
      let features = this.Cookie.getCookie("features");
      this.setState({ features: features });
      // if (!(features.includes('316')  || features.includes('97') )) {
      //   window.location.href = '/dashboard';
      // }
      const loginUserID = this.Cookie.getCookie("loginUserId");
      const idToken = this.Cookie.getIdTokenCookie();
      api.get_services(loginUserID, idToken).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
              if (res.services) {
                this.setState({
                  services: res.services,
                  loading: false,
                  isLoading: false,
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                isLoading: false,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
    }
    document.title = config.documentTitle + "Service Requests";
  }

  convertExcelHandler = (data, filename, ShowLabel) => {
    var arrData = typeof data != "object" ? JSON.parse(data) : data;
    let Modified = arrData.map((obj) => {
      return {
        "Service No": obj.costing_no,
        "Service Type": obj.service_type,
        "Invoice No": obj.invoice_no,
        "Invoice Date": localDateFormate(obj.invoice_date),
        "Business No": obj.business_no,
        "Barge ID": pad(obj.barge_id),
        "Vendor Name": obj.vendor_name,
        "Customer Name": obj.customer_name,
        "Total Payable": toLocaleString(obj.payable_total_value),
        "Total Receivable": toLocaleString(obj.receivable_total_value),
        "Approval Status": obj.approval_status,
      };
    });
    exportToExcel(Modified, filename);
  };

  onUpdateResponse = (value) => {
    if (value === "200") {
      this.setState({
        updateServiceRequest: false,
        newServiceRequest: false,
        viewServiceRequest: true,
      });
    }
  };

  onAddResponse = (value) => {
    if (value === "200") {
      this.setState({
        newServiceRequest: false,
        viewServiceRequest: false,
        showDrawer: false,
      });
    }
  };

  onCancel = (value) => {
    if (value === false) {
      this.setState({
        updateServiceRequest: false,
        newServiceRequest: false,
        viewServiceRequest: false,
        showDrawer: false,
        serviceRequestId: null,
      });
    }
  };

  addDocuments = () => {
    this.setState({
      showDrawer: true,
      updateServiceRequest: true,
      viewServiceRequest: false,
      newServiceRequest: false,
      updateDocuments: true,
    });
  };

  onEdit = (value) => {
    if (value === true) {
      this.setState({
        updateServiceRequest: true,
        showDrawer: true,
        newServiceRequest: false,
        viewServiceRequest: false,
        updateDocuments: false,
      });
    }
  };

  render() {
    let drawerComponent;
    if (this.state.newServiceRequest) {
      drawerComponent = (
        <ServiceRequestAdd
          onAddResponse={this.onAddResponse}
          onCancel={this.onCancel}
        />
      );
    } else if (
      this.state.viewServiceRequest &&
      this.state.serviceRequestId !== null
    ) {
      drawerComponent = (
        <ServiceRequestView
          serviceRequestId={this.state.serviceRequestId}
          returnDocumentsHandler={this.addDocuments}
          onCancel={this.onCancel}
          onEdit={this.onEdit}
        />
      );
    } else if (
      this.state.updateServiceRequest &&
      this.state.serviceRequestId !== null
    ) {
      drawerComponent = (
        <ServiceRequestUpdate
          serviceRequestId={this.state.serviceRequestId}
          onUpdateCosting={this.onUpdateResponse}
          onCancel={this.onCancel}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }

    return (
      <div id="wraper">
        <SideBar />
        <div className="content">
          <Header />
          <Drawer
            anchor="right"
            open={this.state.showDrawer}
            variant="temporary"
            elevation={20}
            style={{ overflow: "initial" }}
          >
            <div className="row" style={{ width: 800 }}>
              {drawerComponent}
            </div>
          </Drawer>
          <div className="clearfix content-header">
            <div className="row">
              <div className="col-sm text-left pl-0">
                <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                  Service Requests
                  <span style={{ color: "lightgrey", margin: "0px 5px" }}>
                    |
                  </span>
                  <span style={{ color: "#959cb6", fontSize: "15px" }}>
                    {this.state.services.length}
                  </span>
                </h4>
              </div>
              <div className="col text-right float-sm-left">
                <button
                  className="header_button header_button_text add_button_adjustment"
                  style={{ width: "auto", marginTop: 17 }}
                  onClick={() => {
                    this.setState({
                      showDrawer: true,
                      newServiceRequest: true,
                    });
                  }}
                >
                  New Service Request
                </button>
              </div>
            </div>
          </div>
          {this.state.isLoading && <Loader />}
          <div className="container p-3">
            {this.state.errorMessage && (
              <div className="col text-center">
                <p className="error-class">{this.state.errorMessage}</p>
              </div>
            )}
            {this.state.successMessage && (
              <div className="col text-center">
                <p className="success-class">{this.state.successMessage}</p>
              </div>
            )}
            {!this.state.isLoading && (
              <div className="col-md card p-0">
                <MaterialTable
                  columns={[
                    {
                      title: "Service NO",
                      field: "costing_no",
                      render: (row) => {
                        if (
                          this.state.features.includes("316") ||
                          this.state.features.includes("97")
                        ) {
                          return (
                            <span
                              style={{
                                textDecoration: "none",
                                color: config.themeColor,
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                this.setState({
                                  viewServiceRequest: true,
                                  showDrawer: true,
                                  serviceRequestId: row.id,
                                })
                              }
                            >
                              {row.costing_no}
                            </span>
                          );
                        } else {
                          return <span>{row.costing_no}</span>;
                        }
                      },
                    },
                    {
                      title: "Service Type",
                      field: "service_type",
                    },
                    {
                      title: "Business No",
                      field: "business_no",
                      // headerStyle: {
                      //   minWidth: 245,
                      // },
                    },
                    {
                      title: "Barge",
                      field: "barge_id",
                      render: (row) => {
                        return pad(row.barge_id);
                      },
                    },
                    {
                      title: "Vendor Name",
                      field: "vendor_name",
                      // headerStyle: {
                      //   minWidth: 245,
                      // },
                    },
                    {
                      title: "Customer Name",
                      field: "customer_name",
                      // headerStyle: {
                      //   minWidth: 245,
                      // },
                    },

                    {
                      title: "Status",
                      field: "status",
                      render: (row) => {
                        return (
                          <span>
                            {row.status === "Active" && (
                              <span className="active_status">
                                <i
                                  className="fa fa-circle dot_align"
                                  aria-hidden="true"
                                ></i>{" "}
                                {row.status}
                              </span>
                            )}
                          </span>
                        );
                      },
                      headerStyle: {
                        minWidth: 120,
                      },
                    },
                    {
                      title: "Action",
                      field: "id",
                      sortable: false,
                      filterable: false,
                      headerStyle: {
                        minWidth: 120,
                      },
                      render: (row) => {
                        return (
                          <div
                            style={{
                              textAlign: "left",
                              marginTop: "-10px",
                              marginBottom: "-10px",
                            }}
                          >
                            {row.status === "Active" &&
                              (this.state.features.includes("317") ||
                                this.state.features.includes("97")) && (
                                <i
                                  data-html="true"
                                  data-toggle="tooltip1"
                                  title="Update Service"
                                  data-placement="bottom"
                                  className="fa fa-pencil edit_icon"
                                  aria-hidden="true"
                                  style={{
                                    color: config.themeColor,
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    this.setState({
                                      viewServiceRequest: false,
                                      showDrawer: true,
                                      updateServiceRequest: true,
                                      serviceRequestId: row.id,
                                    })
                                  }
                                />
                              )}
                          </div>
                        );
                      },
                      hidden: !(
                        this.state.features.includes("317") ||
                        this.state.features.includes("97")
                      ),
                    },
                  ]}
                  title=""
                  data={this.state.services}
                  isLoading={this.state.loading}
                  style={{
                    minHeight: 200,
                    fontFamily: "Poppins",
                    overflowY: "auto",
                    boxShadow: "0 0 5px 0 rgba(0, 39, 77, 0.1)",
                  }}
                  options={{
                    paginationType: "stepped",
                    pageSize: 10,
                    pageSizeOptions: [5, 10, 20, 50, 100, 200],
                    headerStyle: {
                      backgroundColor: "#f9f9f9",
                      minWidth: 215,
                      fontFamily: "Poppins",
                      textTransform: "uppercase",
                      fontSize: "14px",
                      fontWeight: 500,
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "0.86",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "rgba(72, 59, 52, 0.7)",
                    },
                    cellStyle: {
                      fontFamily: "Poppins",
                    },
                    // exportAllData: true,
                    // exportButton: true,
                    draggable: false,
                    exportFileName: "purchase-contracts",
                    // tableLayout: 'fixed'
                  }}
                  actions={[
                    {
                      icon: "download",
                      tooltip: "Download",
                      isFreeAction: true,
                      onClick: (event) =>
                        this.convertExcelHandler(
                          this.state.services,
                          "Service Requests"
                        ),
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ServicesList;
