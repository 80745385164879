import React, { Component } from "react";

import CookieHandler from "../common/CookieHandler";
import config from "../../config/config";
import api from "../../api/api";
import {
  TextField,
  InputAdornment,
  MenuItem,
  Snackbar,
} from "@material-ui/core";

import { Alert } from "@material-ui/lab";
import { localDateFormate, pad } from "../common/common";
import Loader from "../common/Loader";
export default class BuyerDemurrageUpdate extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      selectedBargeId: "",
      invoice_no: "",
      invoice_date: null,
      ppn: "",
      ppn_date: null,
      dmr_to_buyer_allowed_for_discharge: "",
      dmr_to_buyer_barge_demurrage: "",
      dmr_to_buyer_barge_currency: "0",
      dmr_to_buyer_demurrage_in_days: "",
      dmr_to_buyer_demurrage_value: "",

      dmr_from_buyer_time_allowed_for_discharge: "",
      dmr_from_buyer_barge_detention: "",
      dmr_from_buyer_detention_in_days: "",
      dmr_from_buyer_detention_value: "",

      dmr_to_buyer_currency_xchg_rate: "",
      dmr_to_buyer_pph23_account: "0",
      dmr_to_buyer_base_price: "",
      dmr_to_buyer_vat_percent: "10",
      dmr_to_buyer_vat_value: "",
      dmr_to_buyer_pph23_percent: "",
      dmr_to_buyer_pph23_value: "",
      dmr_to_buyer_total: "",
      dmr_to_buyer_ctc: "",
      dmr_to_buyer_ctc_in_local_currency: "",

      dmr_from_buyer_currency_xchg_rate: "",
      dmr_from_buyer_pph23_account: "0",
      dmr_from_buyer_base_price: "",
      dmr_from_buyer_vat_percent: "10",
      dmr_from_buyer_vat_value: "",
      dmr_from_buyer_pph23_percent: "",
      dmr_from_buyer_pph23_value: "",
      dmr_from_buyer_total: "",
      dmr_from_buyer_ctc: "",
      dmr_from_buyer_ctc_in_local_currency: "",
      purchaseBargeList: [],
      isLoading: true,
      currencyData: [],
      totalBarges: [],
      activeStep: 0,
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    var features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    // if (!(features.includes('54')  || features.includes('152')  || features.includes('154')  || features.includes('97') )) {
    //   window.location.href = '/dashboard';
    // }
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();

    // for get_Currencies
    const getCurrencies = await api.getCurrencies(loginUserID, idToken);
    if (getCurrencies.status >= 200 && getCurrencies.status < 300) {
      getCurrencies.json().then((res) => {
        if (res.code === "200") {
          if (res.Currency) {
            var currencies = [
              <MenuItem
                style={{ fontFamily: "Poppins", fontSize: "14px" }}
                value="0"
                key={-1}
                disabled
              >
                Please Select
              </MenuItem>,
            ];
            for (var i in res.Currency) {
              currencies.push(
                <MenuItem
                  style={{ fontFamily: "Poppins", fontSize: "14px" }}
                  value={res.Currency[i].currency}
                  key={[i]}
                >
                  {res.Currency[i].currency}
                </MenuItem>
              );
            }
            this.setState({
              currencyData: currencies,
            });
          } else {
            alert("Unexpected error occured. Please contact administrator.");
          }
        } else if (res.code === "601") {
          this.setState({
            errorMsg: res.message,
            snackBarErrorOpen: true,
            isLoading: false,
          });
        } else if (res.code === "607") {
          window.location.href = "/logout";
        }
      });
    }
    const business_no_id = this.props.businessNo;
    //Get businessNumber API based on businessNumber
    const getBusinessNumber = await api.getBusinessNumber(
      business_no_id,
      loginUserID,
      idToken
    );
    if (getBusinessNumber.status >= 200 && getBusinessNumber.status < 300) {
      getBusinessNumber.json().then((res) => {
        if (res.code === "200") {
          if (res.business_number) {
            this.setState({
              businessNo: res.business_number.business_no
                ? res.business_number.business_no
                : "",
              mother_vessel_name: res.business_number.mother_vessel_name
                ? res.business_number.mother_vessel_name
                : "",
              //isLoading: false
            });
          } else {
            alert("Unexpected error occured. Please contact administrator.");
          }
        } else if (res.code === "607") {
          window.location.href = "/logout";
        }
      });
    }
    let demurrage_type = this.props.demurrage_type;
    let comboID = this.props.comboID;
    const demurrage_to_buyer_id = comboID.split("$#")[0];
    const demurrage_from_buyer_id = comboID.split("$#")[0];
    if (demurrage_type === "company_to_buyer") {
      api
        .get_barge_demurrage_to_buyer(
          loginUserID,
          idToken,
          demurrage_to_buyer_id
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                if (res.barge_demurrage_to_buyer.length > 0) {
                  let days = "0";
                  let hours = "0";
                  let minutes = "0";
                  if (res.barge_demurrage_to_buyer[0].time_at_anchorage_days) {
                    days =
                      res.barge_demurrage_to_buyer[0].time_at_anchorage_days;
                  }
                  if (res.barge_demurrage_to_buyer[0].time_at_anchorage_hours) {
                    hours =
                      res.barge_demurrage_to_buyer[0].time_at_anchorage_hours;
                  }
                  if (
                    res.barge_demurrage_to_buyer[0].time_at_anchorage_minutes
                  ) {
                    minutes =
                      res.barge_demurrage_to_buyer[0].time_at_anchorage_minutes;
                  }
                  var dmr_to_buyer_total_paid = 0;
                  var dmr_to_buyer_remaining_amount = 0;
                  for (var i of res.barge_demurrage_to_buyer[0]
                    .dmr_to_buyer_payment_postings) {
                    dmr_to_buyer_total_paid += Number(i.amount);
                  }

                  dmr_to_buyer_remaining_amount =
                    Number(
                      res.barge_demurrage_to_buyer[0].dmr_to_buyer_total
                        ? res.barge_demurrage_to_buyer[0].dmr_to_buyer_total
                        : 0
                    ) - Number(dmr_to_buyer_total_paid);

                  this.setState({
                    dmr_to_buyer_remaining_amount:
                      dmr_to_buyer_remaining_amount,
                    dmr_to_buyer_total_paid: dmr_to_buyer_total_paid,
                    remainingAmount: dmr_to_buyer_remaining_amount,

                    demurrage_to_buyer_id: res.barge_demurrage_to_buyer[0].id,
                    costing_no: res.barge_demurrage_to_buyer[0].costing_no,
                    invoice_no: res.barge_demurrage_to_buyer[0].invoice_no,
                    invoice_date: res.barge_demurrage_to_buyer[0].invoice_date,
                    ppn: res.barge_demurrage_to_buyer[0].ppn,
                    ppn_date: res.barge_demurrage_to_buyer[0].ppn_date,
                    selectedBargeId: res.barge_demurrage_to_buyer[0].barge_id,
                    dmr_to_buyer_allowed_for_discharge:
                      res.barge_demurrage_to_buyer[0]
                        .dmr_to_buyer_allowed_for_discharge,
                    dmr_to_buyer_barge_currency: res.barge_demurrage_to_buyer[0]
                      .dmr_to_buyer_barge_currency
                      ? res.barge_demurrage_to_buyer[0]
                          .dmr_to_buyer_barge_currency
                      : "0",
                    dmr_to_buyer_barge_demurrage:
                      res.barge_demurrage_to_buyer[0]
                        .dmr_to_buyer_barge_demurrage,
                    dmr_to_buyer_base_price:
                      res.barge_demurrage_to_buyer[0].dmr_to_buyer_base_price,
                    dmr_to_buyer_ctc:
                      res.barge_demurrage_to_buyer[0].dmr_to_buyer_ctc,
                    dmr_to_buyer_ctc_in_local_currency:
                      res.barge_demurrage_to_buyer[0]
                        .dmr_to_buyer_ctc_in_local_currency,
                    dmr_to_buyer_currency_xchg_rate:
                      res.barge_demurrage_to_buyer[0]
                        .dmr_to_buyer_currency_xchg_rate,
                    dmr_to_buyer_demurrage_in_days:
                      res.barge_demurrage_to_buyer[0]
                        .dmr_to_buyer_demurrage_in_days,
                    dmr_to_buyer_demurrage_value:
                      res.barge_demurrage_to_buyer[0]
                        .dmr_to_buyer_demurrage_value,
                    dmr_to_buyer_pph23_account:
                      res.barge_demurrage_to_buyer[0]
                        .dmr_to_buyer_pph23_account,
                    dmr_to_buyer_pph23_percent:
                      res.barge_demurrage_to_buyer[0]
                        .dmr_to_buyer_pph23_percent,
                    dmr_to_buyer_pph23_value:
                      res.barge_demurrage_to_buyer[0].dmr_to_buyer_pph23_value,
                    dmr_to_buyer_total:
                      res.barge_demurrage_to_buyer[0].dmr_to_buyer_total,
                    dmr_to_buyer_vat_percent:
                      res.barge_demurrage_to_buyer[0].dmr_to_buyer_vat_percent,
                    dmr_to_buyer_vat_value:
                      res.barge_demurrage_to_buyer[0].dmr_to_buyer_vat_value,
                    NORAnchorageDate:
                      res.barge_demurrage_to_buyer[0].nor_anchorage,
                    completionDischargeDate:
                      res.barge_demurrage_to_buyer[0].completion_discharge,
                    dmr_to_buyer_payment_postings:
                      res.barge_demurrage_to_buyer[0]
                        .dmr_to_buyer_payment_postings,
                    timeAtAnchorage:
                      days +
                      " days, " +
                      hours +
                      " hours, " +
                      minutes +
                      " minutes",
                    timeAtAnchorageDays: days,
                    timeAtAnchorageHours: hours,
                    timeAtAnchorageMinutes: minutes,
                    isLoading: false,
                  });
                } else {
                  this.setState({
                    isLoading: false,
                  });
                }
              } else if (res.code === "601") {
                this.setState({
                  snackBarErrorOpen: true,
                  errorMsg: res.message,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    } else if (demurrage_type === "buyer_to_company") {
      api
        .get_barge_demurrage_from_buyer(
          loginUserID,
          idToken,
          demurrage_from_buyer_id
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                if (res.barge_demurrage_from_buyer.length > 0) {
                  let days = "0";
                  let hours = "0";
                  let minutes = "0";
                  if (
                    res.barge_demurrage_from_buyer[0].time_at_anchorage_days
                  ) {
                    days =
                      res.barge_demurrage_from_buyer[0].time_at_anchorage_days;
                  }
                  if (
                    res.barge_demurrage_from_buyer[0].time_at_anchorage_hours
                  ) {
                    hours =
                      res.barge_demurrage_to_buyer[0].time_at_anchorage_hours;
                  }
                  if (
                    res.barge_demurrage_from_buyer[0].time_at_anchorage_minutes
                  ) {
                    minutes =
                      res.barge_demurrage_from_buyer[0]
                        .time_at_anchorage_minutes;
                  }
                  var dmr_from_buyer_total_paid = 0;
                  var dmr_from_buyer_remaining_amount = 0;
                  for (var i of res.barge_demurrage_from_buyer[0]
                    .dmr_from_buyer_payment_postings) {
                    dmr_from_buyer_total_paid += Number(i.amount);
                  }

                  dmr_from_buyer_remaining_amount =
                    Number(
                      res.barge_demurrage_from_buyer[0].dmr_to_buyer_total
                        ? res.barge_demurrage_from_buyer[0].dmr_to_buyer_total
                        : 0
                    ) - Number(dmr_from_buyer_total_paid);

                  this.setState({
                    dmr_from_buyer_remaining_amount:
                      dmr_from_buyer_remaining_amount,
                    dmr_from_buyer_total_paid: dmr_from_buyer_total_paid,
                    remainingAmount: dmr_from_buyer_remaining_amount,

                    demurrage_from_buyer_id:
                      res.barge_demurrage_from_buyer[0].id,
                    selectedBargeId: res.barge_demurrage_from_buyer[0].barge_id,
                    costing_no: res.barge_demurrage_from_buyer[0].costing_no,
                    invoice_no: res.barge_demurrage_from_buyer[0].invoice_no,
                    invoice_date:
                      res.barge_demurrage_from_buyer[0].invoice_date,
                    ppn: res.barge_demurrage_from_buyer[0].ppn,
                    ppn_date: res.barge_demurrage_from_buyer[0].ppn_date,
                    dmr_from_buyer_time_allowed_for_discharge:
                      res.barge_demurrage_from_buyer[0]
                        .dmr_from_buyer_time_allowed_for_discharge,
                    dmr_from_buyer_barge_detention:
                      res.barge_demurrage_from_buyer[0]
                        .dmr_from_buyer_barge_detention,
                    dmr_from_buyer_detention_in_days:
                      res.barge_demurrage_from_buyer[0]
                        .dmr_from_buyer_detention_in_days,
                    dmr_from_buyer_detention_value:
                      res.barge_demurrage_from_buyer[0]
                        .dmr_from_buyer_detention_value,
                    dmr_from_buyer_barge_currency:
                      res.barge_demurrage_from_buyer[0]
                        .dmr_from_buyer_barge_currency,
                    dmr_from_buyer_currency_xchg_rate:
                      res.barge_demurrage_from_buyer[0]
                        .dmr_to_buyer_ctc_in_local_currency,
                    dmr_to_buyer_currency_xchg_rate:
                      res.barge_demurrage_from_buyer[0]
                        .dmr_to_buyer_currency_xchg_rate,
                    dmr_from_buyer_pph23_account:
                      res.barge_demurrage_from_buyer[0]
                        .dmr_from_buyer_pph23_account,
                    dmr_from_buyer_base_price:
                      res.barge_demurrage_from_buyer[0]
                        .dmr_from_buyer_base_price,
                    dmr_from_buyer_vat_percent:
                      res.barge_demurrage_from_buyer[0]
                        .dmr_from_buyer_vat_percent,
                    dmr_from_buyer_vat_value:
                      res.barge_demurrage_from_buyer[0]
                        .dmr_from_buyer_vat_value,
                    dmr_from_buyer_pph23_percent:
                      res.barge_demurrage_from_buyer[0]
                        .dmr_from_buyer_pph23_percent,
                    dmr_from_buyer_pph23_value:
                      res.barge_demurrage_from_buyer[0]
                        .dmr_from_buyer_pph23_value,
                    dmr_from_buyer_total:
                      res.barge_demurrage_from_buyer[0].dmr_from_buyer_total,
                    dmr_from_buyer_ctc:
                      res.barge_demurrage_from_buyer[0].dmr_from_buyer_ctc,
                    dmr_from_buyer_ctc_in_local_currency:
                      res.barge_demurrage_from_buyer[0]
                        .dmr_from_buyer_ctc_in_local_currency,
                    NORAnchorageDate:
                      res.barge_demurrage_from_buyer[0].nor_anchorage,
                    completionDischargeDate:
                      res.barge_demurrage_from_buyer[0].completion_discharge,
                    dmr_from_buyer_payment_postings:
                      res.barge_demurrage_from_buyer[0]
                        .dmr_from_buyer_payment_postings,
                    timeAtAnchorage:
                      days +
                      " days, " +
                      hours +
                      " hours, " +
                      minutes +
                      " minutes",
                    timeAtAnchorageDays: days,
                    timeAtAnchorageHours: hours,
                    timeAtAnchorageMinutes: minutes,
                    isLoading: false,
                  });
                } else {
                  this.setState({
                    isLoading: false,
                  });
                }
              } else if (res.code === "601") {
                this.setState({
                  snackBarErrorOpen: true,
                  errorMsg: res.message,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    }
  }

  dmr_to_buyer_calculations = (value) => {
    var dmr_to_buyer_barge_demurrage = this.state.dmr_to_buyer_barge_demurrage
      ? this.state.dmr_to_buyer_barge_demurrage
      : 1;
    var dmr_to_buyer_demurrage_in_days = this.state
      .dmr_to_buyer_demurrage_in_days
      ? this.state.dmr_to_buyer_demurrage_in_days
      : 0;
    var dmr_to_buyer_currency_xchg_rate = this.state
      .dmr_to_buyer_currency_xchg_rate
      ? this.state.dmr_to_buyer_currency_xchg_rate
      : 1;
    if (
      ((config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL") &&
        this.state.dmr_to_buyer_barge_currency === "USD") ||
      ((config.company === "SDAM" ||
        config.company === "SRK" ||
        config.company === "BTR") &&
        this.state.dmr_to_buyer_barge_currency === "IDR")
    ) {
      dmr_to_buyer_currency_xchg_rate = 1;
    }
    let dmr_to_buyer_demurrage_value = (
      (Number(dmr_to_buyer_barge_demurrage) *
        Number(dmr_to_buyer_demurrage_in_days)) /
      dmr_to_buyer_currency_xchg_rate
    ).toFixed(2);
    if (
      (config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL") &&
      this.state.dmr_to_buyer_barge_currency !== "USD"
    ) {
      dmr_to_buyer_demurrage_value = (
        (Number(dmr_to_buyer_barge_demurrage) *
          Number(dmr_to_buyer_demurrage_in_days)) /
        dmr_to_buyer_currency_xchg_rate
      ).toFixed(2);
    } else if (
      (config.company === "SDAM" ||
        config.company === "SRK" ||
        config.company === "BTR") &&
      this.state.dmr_to_buyer_barge_currency !== "IDR"
    ) {
      dmr_to_buyer_demurrage_value = (
        Number(dmr_to_buyer_barge_demurrage) *
        Number(dmr_to_buyer_demurrage_in_days) *
        Number(dmr_to_buyer_currency_xchg_rate)
      ).toFixed(2);
    }
    if (value) {
      dmr_to_buyer_demurrage_value = this.state.dmr_to_buyer_demurrage_value
        ? this.state.dmr_to_buyer_demurrage_value
        : 1;
    }
    let dmr_to_buyer_base_price = Number(dmr_to_buyer_demurrage_value).toFixed(
      2
    );
    let dmr_to_buyer_vat_percent = 10;
    let dmr_to_buyer_vat_value = (
      (Number(dmr_to_buyer_base_price) * 10) /
      100
    ).toFixed(2);
    let dmr_to_buyer_pph23_percent = 2;
    let dmr_to_buyer_pph23_value = Number(
      (dmr_to_buyer_base_price * 2) / 100
    ).toFixed(2);
    let dmr_to_buyer_total = (
      Number(dmr_to_buyer_base_price) +
      Number(dmr_to_buyer_vat_value) -
      Number(dmr_to_buyer_pph23_value)
    ).toFixed(2);
    if (config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL") {
      dmr_to_buyer_total = (
        Number(dmr_to_buyer_base_price) + Number(dmr_to_buyer_vat_value)
      ).toFixed(2);
    }
    let dmr_to_buyer_ctc = Number(
      Number(dmr_to_buyer_base_price) + Number(dmr_to_buyer_vat_value)
    ).toFixed(2);

    this.setState({
      dmr_to_buyer_demurrage_value: dmr_to_buyer_demurrage_value,
      dmr_to_buyer_base_price: dmr_to_buyer_base_price,
      dmr_to_buyer_vat_percent: dmr_to_buyer_vat_percent,
      dmr_to_buyer_vat_value: dmr_to_buyer_vat_value,
      dmr_to_buyer_pph23_percent: dmr_to_buyer_pph23_percent,
      dmr_to_buyer_pph23_value: dmr_to_buyer_pph23_value,
      dmr_to_buyer_total: dmr_to_buyer_total,
      dmr_to_buyer_ctc: dmr_to_buyer_ctc,
    });

    if (this.state.dmr_to_buyer_pph23_account === "SDAM") {
      let dmr_to_buyer_base_price = Number(
        (dmr_to_buyer_demurrage_value * 100) / 98
      ).toFixed(2);
      let dmr_to_buyer_pph23_percent = 2;
      let dmr_to_buyer_pph23_value = Number(
        ((dmr_to_buyer_base_price * 2) / 100).toFixed(2)
      );
      let dmr_to_buyer_total = Number(dmr_to_buyer_demurrage_value).toFixed(2);
      let dmr_to_buyer_ctc = Number(dmr_to_buyer_base_price).toFixed(2);

      this.setState({
        dmr_to_buyer_base_price: dmr_to_buyer_base_price,
        dmr_to_buyer_pph23_percent: dmr_to_buyer_pph23_percent,
        dmr_to_buyer_pph23_value: dmr_to_buyer_pph23_value,
        dmr_to_buyer_total: dmr_to_buyer_total,
        dmr_to_buyer_ctc: dmr_to_buyer_ctc,
        dmr_to_buyer_vat_percent: "",
        dmr_to_buyer_vat_value: "",
      });
    }
  };

  dmr_from_buyer_calculations = (value) => {
    var dmr_from_buyer_barge_detention = this.state
      .dmr_from_buyer_barge_detention
      ? this.state.dmr_from_buyer_barge_detention
      : 1;
    var dmr_from_buyer_detention_in_days = this.state
      .dmr_from_buyer_detention_in_days
      ? this.state.dmr_from_buyer_detention_in_days
      : 0;
    var dmr_from_buyer_currency_xchg_rate = this.state
      .dmr_from_buyer_currency_xchg_rate
      ? this.state.dmr_from_buyer_currency_xchg_rate
      : 1;
    if (
      ((config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL") &&
        this.state.dmr_from_buyer_barge_currency === "USD") ||
      ((config.company === "SDAM" ||
        config.company === "SRK" ||
        config.company === "BTR") &&
        this.state.dmr_from_buyer_barge_currency === "IDR")
    ) {
      dmr_from_buyer_currency_xchg_rate = 1;
    }
    let dmr_from_buyer_detention_value = (
      (Number(dmr_from_buyer_barge_detention) *
        Number(dmr_from_buyer_detention_in_days)) /
      dmr_from_buyer_currency_xchg_rate
    ).toFixed(2);
    if (
      (config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL") &&
      this.state.dmr_from_buyer_barge_currency !== "USD"
    ) {
      dmr_from_buyer_detention_value = (
        (Number(dmr_from_buyer_barge_detention) *
          Number(dmr_from_buyer_detention_in_days)) /
        dmr_from_buyer_currency_xchg_rate
      ).toFixed(2);
    } else if (
      (config.company === "SDAM" ||
        config.company === "SRK" ||
        config.company === "BTR") &&
      this.state.dmr_from_buyer_barge_currency !== "IDR"
    ) {
      dmr_from_buyer_detention_value = (
        Number(dmr_from_buyer_barge_detention) *
        Number(dmr_from_buyer_detention_in_days) *
        Number(dmr_from_buyer_currency_xchg_rate)
      ).toFixed(2);
    }
    if (value) {
      dmr_from_buyer_detention_value = this.state.dmr_from_buyer_detention_value
        ? this.state.dmr_from_buyer_detention_value
        : 1;
    }
    let dmr_from_buyer_base_price = Number(
      dmr_from_buyer_detention_value
    ).toFixed(2);
    let dmr_from_buyer_vat_percent = 10;
    let dmr_from_buyer_vat_value = (
      (Number(dmr_from_buyer_base_price) * 10) /
      100
    ).toFixed(2);
    let dmr_from_buyer_pph23_percent = 2;
    let dmr_from_buyer_pph23_value = Number(
      (dmr_from_buyer_base_price * 2) / 100
    ).toFixed(0);
    let dmr_from_buyer_total = (
      Number(dmr_from_buyer_base_price) +
      Number(dmr_from_buyer_vat_value) -
      Number(dmr_from_buyer_pph23_value)
    ).toFixed(2);
    if (config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL") {
      dmr_from_buyer_total = (
        Number(dmr_from_buyer_base_price) + Number(dmr_from_buyer_vat_value)
      ).toFixed(2);
    }
    let dmr_from_buyer_ctc = Number(
      Number(dmr_from_buyer_base_price) + Number(dmr_from_buyer_vat_value)
    ).toFixed(2);

    this.setState({
      dmr_from_buyer_detention_value: dmr_from_buyer_detention_value,
      dmr_from_buyer_base_price: dmr_from_buyer_base_price,
      dmr_from_buyer_vat_percent: dmr_from_buyer_vat_percent,
      dmr_from_buyer_vat_value: dmr_from_buyer_vat_value,
      dmr_from_buyer_pph23_percent: dmr_from_buyer_pph23_percent,
      dmr_from_buyer_pph23_value: dmr_from_buyer_pph23_value,
      dmr_from_buyer_total: dmr_from_buyer_total,
      dmr_from_buyer_ctc: dmr_from_buyer_ctc,
    });

    if (this.state.dmr_from_buyer_pph23_account === "SDAM") {
      let dmr_from_buyer_base_price = Number(
        (dmr_from_buyer_detention_value * 100) / 98
      ).toFixed(2);
      let dmr_from_buyer_pph23_percent = 2;
      let dmr_from_buyer_pph23_value = Number(
        ((dmr_from_buyer_base_price * 2) / 100).toFixed(2)
      );
      let dmr_from_buyer_total = Number(dmr_from_buyer_detention_value).toFixed(
        2
      );
      let dmr_from_buyer_ctc = Number(dmr_from_buyer_base_price).toFixed(2);

      this.setState({
        dmr_from_buyer_base_price: dmr_from_buyer_base_price,
        dmr_from_buyer_pph23_percent: dmr_from_buyer_pph23_percent,
        dmr_from_buyer_pph23_value: dmr_from_buyer_pph23_value,
        dmr_from_buyer_total: dmr_from_buyer_total,
        dmr_from_buyer_ctc: dmr_from_buyer_ctc,
        dmr_from_buyer_vat_percent: "",
        dmr_from_buyer_vat_value: "",
      });
    }
  };

  toLocaleString(value) {
    return value ? Number(value).toLocaleString() : 0;
  }

  submitHandler = () => {
    var {
      invoice_no,
      invoice_date,
      ppn,
      ppn_date,
      dmr_to_buyer_allowed_for_discharge,
      dmr_to_buyer_barge_demurrage,
      dmr_to_buyer_barge_currency,
      dmr_to_buyer_demurrage_in_days,
      dmr_to_buyer_demurrage_value,

      dmr_from_buyer_time_allowed_for_discharge,
      dmr_from_buyer_barge_detention,
      dmr_from_buyer_detention_in_days,
      dmr_from_buyer_detention_value,
      dmr_from_buyer_barge_currency,

      dmr_to_buyer_currency_xchg_rate,
      dmr_to_buyer_pph23_account,
      dmr_to_buyer_base_price,
      dmr_to_buyer_vat_percent,
      dmr_to_buyer_vat_value,
      dmr_to_buyer_pph23_percent,
      dmr_to_buyer_pph23_value,
      dmr_to_buyer_total,
      dmr_to_buyer_ctc,
      dmr_to_buyer_ctc_in_local_currency,

      dmr_from_buyer_currency_xchg_rate,
      dmr_from_buyer_pph23_account,
      dmr_from_buyer_base_price,
      dmr_from_buyer_vat_percent,
      dmr_from_buyer_vat_value,
      dmr_from_buyer_pph23_percent,
      dmr_from_buyer_pph23_value,
      dmr_from_buyer_total,
      dmr_from_buyer_ctc,
      dmr_from_buyer_ctc_in_local_currency,
    } = this.state;

    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const barge_id = this.state.selectedBargeId;
    const demurrage_to_buyer_id = this.state.demurrage_to_buyer_id;
    const demurrage_from_buyer_id = this.state.demurrage_from_buyer_id;
    const business_no_id = this.props.businessNo;

    if (this.props.demurrage_type === "company_to_buyer") {
      api
        .update_barge_demurrage_to_buyer(
          loginUserID,
          idToken,
          business_no_id,
          demurrage_to_buyer_id,
          barge_id,
          invoice_no,
          invoice_date,
          ppn,
          ppn_date,
          dmr_to_buyer_allowed_for_discharge,
          dmr_to_buyer_barge_demurrage,
          dmr_to_buyer_barge_currency,
          dmr_to_buyer_demurrage_in_days,
          dmr_to_buyer_demurrage_value,
          dmr_to_buyer_currency_xchg_rate,
          dmr_to_buyer_pph23_account,
          dmr_to_buyer_base_price,
          dmr_to_buyer_vat_percent,
          dmr_to_buyer_vat_value,
          dmr_to_buyer_pph23_percent,
          dmr_to_buyer_pph23_value,
          dmr_to_buyer_total,
          dmr_to_buyer_ctc,
          dmr_to_buyer_ctc_in_local_currency
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                this.setState(
                  {
                    snackBarSuccessOpen: true,
                    successMsg: res.message,
                  },
                  () => {
                    this.props.onUpdateCosting(true);
                  }
                );
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  snackBarErrorOpen: true,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    } else if (this.props.demurrage_type === "buyer_to_company") {
      api
        .update_barge_demurrage_from_buyer(
          loginUserID,
          idToken,
          business_no_id,
          demurrage_from_buyer_id,
          barge_id,
          invoice_no,
          invoice_date,
          ppn,
          ppn_date,
          dmr_from_buyer_time_allowed_for_discharge,
          dmr_from_buyer_barge_detention,
          dmr_from_buyer_detention_in_days,
          dmr_from_buyer_detention_value,
          dmr_from_buyer_barge_currency,
          dmr_from_buyer_currency_xchg_rate,
          dmr_from_buyer_pph23_account,
          dmr_from_buyer_base_price,
          dmr_from_buyer_vat_percent,
          dmr_from_buyer_vat_value,
          dmr_from_buyer_pph23_percent,
          dmr_from_buyer_pph23_value,
          dmr_from_buyer_total,
          dmr_from_buyer_ctc,
          dmr_from_buyer_ctc_in_local_currency
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                this.setState(
                  {
                    snackBarSuccessOpen: true,
                    successMsg: res.message,
                  },
                  () => {
                    this.props.onUpdateCosting(true);
                  }
                );
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  snackBarErrorOpen: true,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    }
  };

  render() {
    if (this.state.isLoading) {
      return <Loader />
    } else {
      const re = /^\d*(\.\d{0,10})?$/;
      const pr = /^\d*(\.\d{0,4})?$/;
      return (
        <div
          className="container bg-white p-0"
          style={{ minHeight: "100vh", width: 850 }}
        >
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <div className="row border-bottom">
            <div className="col-lg-12 p-2">
              {/* <h5 style={{ padding: '15px', marginBottom: 0 }}>Barge Purchase Financial Demurrage</h5> */}
              {this.props.demurrageData && (
                <div className="row">
                  <div className="table-responsive">
                    <table className="table">
                      <thead style={{ backgroundColor: "rgb(249, 249, 249)" }}>
                        <tr>
                          <th nowrap="true">Barge ID</th>
                          <th nowrap="true">Barge Nomination</th>
                          <th nowrap="true">Buyer</th>
                          <th nowrap="true" className="text-right">
                            {this.props.demurrage_type === "company_to_buyer"
                              ? "Total Payable"
                              : "Receivable"}
                          </th>
                          <th nowrap="true" className="text-right">
                            {this.props.demurrage_type === "company_to_buyer"
                              ? "Paid"
                              : "Received"}
                          </th>
                          <th nowrap="true" className="text-right">
                            Remaining
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{pad(this.props.demurrageData.barge_id)}</td>
                          <td nowrap="true">
                            {this.props.demurrageData.barge_nomination}
                          </td>
                          <td nowrap="true">
                            {this.props.demurrageData.supplier_name}
                          </td>
                          <td className="text-right" nowrap="true">
                            {this.toLocaleString(
                              this.props.demurrageData.total_payable
                            )}
                          </td>
                          <td className="text-right" nowrap="true">
                            {this.toLocaleString(this.props.demurrageData.paid)}
                          </td>
                          <td className="text-right" nowrap="true">
                            {this.toLocaleString(
                              this.props.demurrageData.Remaining_amount
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="drawer-section">
            <div className="card p-3">
              <div
                className="card-body p-0"
                style={{ backgroundColor: "rgba(25,118,210,.01)" }}
                id="accordion"
              >
                {this.state.activeStep === 0 && (
                  <div>
                    {this.props.demurrage_type === "buyer_to_company" && (
                      <div>
                        <div className="row mb-3">
                          <div className="col-lg-4 mb-2">
                            <label className="form_label mb-1">
                              NOR Anchorage
                            </label>
                            <div className="">
                              {this.state.NORAnchorageDate
                                ? localDateFormate(this.state.NORAnchorageDate)
                                : "-"}
                            </div>
                          </div>
                          <div className="col-lg-4 mb-2">
                            <label className="form_label mb-1">
                              Completion Discharge
                            </label>
                            <div className="">
                              {this.state.completionDischargeDate
                                ? localDateFormate(
                                    this.state.completionDischargeDate
                                  )
                                : "-"}
                            </div>
                          </div>
                          <div className="col-lg-4 mb-2">
                            <label className="form_label mb-1">
                              Time at Anchorage
                            </label>
                            <div className="">{this.state.timeAtAnchorage}</div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-5 pl-0">
                            <div className="row">
                              <div className="col-lg-12 p-0">
                                <label className="form_label mb-0">
                                  Time allowed for Discharge
                                </label>
                                <TextField
                                  name="dmr_from_buyer_time_allowed_for_discharge"
                                  variant="outlined"
                                  margin="dense"
                                  type="number"
                                  placeholder="Time allowed for Discharge"
                                  value={
                                    this.state
                                      .dmr_from_buyer_time_allowed_for_discharge
                                  }
                                  onChange={(e) => {
                                    this.setState({
                                      from_buyer_time_allowed_for_dischargeError: false,
                                    });
                                    if (
                                      e.target.value === "" ||
                                      re.test(e.target.value)
                                    ) {
                                      var value = e.target.value;
                                      if (value === "") {
                                        value = "0";
                                      }
                                      let anchorageDays = Number(
                                        Number(this.state.timeAtAnchorageDays) +
                                          (Number(
                                            this.state.timeAtAnchorageHours
                                          ) +
                                            Number(
                                              this.state.timeAtAnchorageMinutes
                                            ) /
                                              60) /
                                            24
                                      ).toFixed(0);
                                      if (
                                        Number(e.target.value) >
                                        Number(anchorageDays)
                                      ) {
                                        this.setState({
                                          from_buyer_time_allowed_for_dischargeError: true,
                                        });
                                      }
                                      let dmr_from_buyer_detention_in_days =
                                        Number(anchorageDays) - Number(value);
                                      let dmr_from_buyer_detention_value = "";
                                      if (
                                        this.state
                                          .dmr_from_buyer_barge_detention !== ""
                                      ) {
                                        dmr_from_buyer_detention_value = (
                                          Number(
                                            this.state
                                              .dmr_from_buyer_barge_detention
                                          ) *
                                          Number(
                                            dmr_from_buyer_detention_in_days
                                          )
                                        ).toFixed(2);
                                      }
                                      this.setState(
                                        {
                                          [e.target.name]: e.target.value,
                                          dmr_from_buyer_detention_in_days:
                                            dmr_from_buyer_detention_in_days,
                                          dmr_from_buyer_detention_value:
                                            dmr_from_buyer_detention_value,
                                          from_buyer_time_allowed_for_dischargeError: false,
                                        },
                                        () => this.dmr_from_buyer_calculations()
                                      );
                                    }
                                  }}
                                  fullWidth
                                  error={
                                    this.state
                                      .from_buyer_time_allowed_for_dischargeError
                                  }
                                />
                                {this.state
                                  .from_buyer_time_allowed_for_dischargeError ? (
                                  <span
                                    style={{ fontSize: "10px", color: "red" }}
                                  >
                                    Time allowed for Discharge is more than Time
                                    at Anchorage
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="col-lg-12 p-0">
                                <label className="form_label mb-0">
                                  Barge Detention
                                </label>
                                <TextField
                                  name="dmr_from_buyer_barge_detention"
                                  variant="outlined"
                                  margin="dense"
                                  type="number"
                                  inputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                    },
                                  }}
                                  placeholder="Barge Detention"
                                  value={
                                    this.state.dmr_from_buyer_barge_detention
                                  }
                                  onChange={(e) => {
                                    if (
                                      e.target.value === "" ||
                                      pr.test(e.target.value)
                                    ) {
                                      var value = e.target.value;
                                      if (value === "") {
                                        value = "1";
                                      }
                                      let dmr_from_buyer_detention_value = "";
                                      if (
                                        this.state
                                          .dmr_from_buyer_detention_in_days !==
                                        ""
                                      ) {
                                        dmr_from_buyer_detention_value = (
                                          Number(
                                            this.state
                                              .dmr_from_buyer_detention_in_days
                                          ) * Number(value)
                                        ).toFixed(2);
                                      }
                                      this.setState(
                                        {
                                          dmr_from_buyer_barge_detention:
                                            e.target.value,
                                          dmr_from_buyer_detention_value:
                                            dmr_from_buyer_detention_value,
                                        },
                                        () => this.dmr_from_buyer_calculations()
                                      );
                                    }
                                  }}
                                  fullWidth
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment
                                        position="end"
                                        style={{
                                          marginRight: "-14px",
                                          marginTop: "-4px",
                                          width: "50%",
                                        }}
                                      >
                                        <TextField
                                          name="dmr_from_buyer_barge_currency"
                                          margin="dense"
                                          variant="outlined"
                                          onChange={(e) => {
                                            this.setState({
                                              dmr_from_buyer_barge_currency:
                                                e.target.value,
                                            });
                                          }}
                                          value={
                                            this.state
                                              .dmr_from_buyer_barge_currency
                                          }
                                          fullWidth
                                          select
                                        >
                                          {this.state.currencyData}
                                        </TextField>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </div>
                              <div className="col-lg-12 p-0">
                                <label className="form_label mb-0">
                                  Detention in days
                                </label>
                                <TextField
                                  name="dmr_from_buyer_detention_in_days"
                                  variant="outlined"
                                  margin="dense"
                                  type="number"
                                  placeholder="Barge Detention in Days"
                                  value={
                                    this.state.dmr_from_buyer_detention_in_days
                                  }
                                  onChange={(e) => {
                                    if (
                                      e.target.value === "" ||
                                      re.test(e.target.value)
                                    ) {
                                      this.setState(
                                        {
                                          dmr_from_buyer_detention_in_days:
                                            e.target.value,
                                        },
                                        () => this.dmr_from_buyer_calculations()
                                      );
                                    }
                                  }}
                                  fullWidth
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        USD
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </div>

                              {(config.company === "SRPL" || config.company === "AVS"  ||
                                config.company === "PRPL") &&
                                this.state.dmr_from_buyer_barge_currency !==
                                  "USD" &&
                                this.state.dmr_from_buyer_barge_currency !==
                                  "0" && (
                                  <div className="col-lg-12 p-0">
                                    <label className="form_label mb-0">
                                      Exchange rate USD to{" "}
                                      {this.state.dmr_from_buyer_barge_currency}
                                    </label>
                                    <TextField
                                      name="dmr_from_buyer_currency_xchg_rate"
                                      margin="dense"
                                      variant="outlined"
                                      fullWidth
                                      inputProps={{
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                        },
                                      }}
                                      value={
                                        this.state
                                          .dmr_from_buyer_currency_xchg_rate
                                      }
                                      type="number"
                                      placeholder="Enter Value"
                                      onChange={(e) => {
                                        if (
                                          e.target.value === "" ||
                                          re.test(e.target.value)
                                        ) {
                                          this.setState(
                                            {
                                              dmr_from_buyer_currency_xchg_rate:
                                                e.target.value,
                                            },
                                            () =>
                                              this.dmr_from_buyer_calculations()
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                )}
                              {!(
                                config.company === "SRPL" || config.company === "AVS"  ||
                                config.company === "PRPL"
                              ) &&
                                this.state.dmr_from_buyer_barge_currency !==
                                  "IDR" &&
                                this.state.dmr_from_buyer_barge_currency !==
                                  "0" && (
                                  <div className="col-lg-12 p-0">
                                    <label className="form_label mb-0">
                                      Exchange rate{" "}
                                      {this.state.dmr_from_buyer_barge_currency}{" "}
                                      to IDR
                                    </label>
                                    <TextField
                                      name="dmr_from_buyer_currency_xchg_rate"
                                      margin="dense"
                                      variant="outlined"
                                      fullWidth
                                      inputProps={{
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                        },
                                      }}
                                      value={
                                        this.state
                                          .dmr_from_buyer_currency_xchg_rate
                                      }
                                      type="number"
                                      placeholder="Enter Value"
                                      onChange={(e) => {
                                        if (
                                          e.target.value === "" ||
                                          re.test(e.target.value)
                                        ) {
                                          this.setState(
                                            {
                                              dmr_from_buyer_currency_xchg_rate:
                                                e.target.value,
                                            },
                                            () =>
                                              this.dmr_from_buyer_calculations()
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                )}
                              <div className="col-lg-12 p-0">
                                <label className="form_label mb-0">
                                  Detention incurred
                                </label>
                                <TextField
                                  name="dmr_from_buyer_detention_value"
                                  margin="dense"
                                  variant="outlined"
                                  fullWidth
                                  inputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: "12px",
                                    },
                                  }}
                                  placeholder="Demurrage incurred"
                                  value={
                                    this.state.dmr_from_buyer_detention_value
                                  }
                                  onChange={(e) => {
                                    if (
                                      e.target.value === "" ||
                                      pr.test(e.target.value)
                                    ) {
                                      // let dmr_to_supplier_base_price = Number(e.target.value).toFixed(2);
                                      // let dmr_to_buyer_vat_percent = 10;
                                      // let dmr_to_supplier_vat_value = (Number(dmr_to_supplier_base_price) * 10 / 100).toFixed(2);
                                      // let dmr_to_supplier_pph23_percent = 2;
                                      // let dmr_to_buyer_pph23_value = Number((dmr_to_supplier_base_price * 2 / 100)).toFixed(0);
                                      // let dmr_to_supplier_total = (Number(dmr_to_supplier_base_price) + Number(dmr_to_supplier_vat_value) - Number(dmr_to_buyer_pph23_value)).toFixed(2);
                                      // if ((config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL")) {
                                      //   dmr_to_supplier_total = (Number(dmr_to_supplier_base_price) + Number(dmr_to_supplier_vat_value)).toFixed(2);
                                      // }
                                      // let dmr_to_supplier_ctc = Number(Number(dmr_to_supplier_base_price) + Number(dmr_to_supplier_vat_value)).toFixed(2);

                                      this.setState(
                                        {
                                          dmr_from_buyer_detention_value:
                                            e.target.value,
                                          // dmr_to_supplier_base_price: dmr_to_supplier_base_price,
                                          // dmr_to_buyer_vat_percent: dmr_to_buyer_vat_percent,
                                          // dmr_to_supplier_vat_value: dmr_to_supplier_vat_value,
                                          // dmr_to_supplier_pph23_percent: dmr_to_supplier_pph23_percent,
                                          // dmr_to_buyer_pph23_value: dmr_to_buyer_pph23_value,
                                          // dmr_to_supplier_total: dmr_to_supplier_total,
                                          // dmr_to_supplier_ctc: dmr_to_supplier_ctc
                                        },
                                        () => {
                                          this.dmr_from_buyer_calculations(
                                            "dmr_from_buyer_detention_value"
                                          );
                                        }
                                      );
                                    }
                                  }}
                                />
                              </div>
                              {!(
                                config.company === "SRPL" || config.company === "AVS"  ||
                                config.company === "PRPL"
                              ) && (
                                <div className="col-lg-12 p-0">
                                  <label className="form_label mb-0">
                                    PPH23 Account
                                  </label>
                                  <TextField
                                    name="dmr_from_buyer_pph23_account"
                                    margin="dense"
                                    variant="outlined"
                                    value={
                                      this.state.dmr_from_buyer_pph23_account
                                    }
                                    select
                                    fullWidth
                                    inputProps={{
                                      style: {
                                        fontFamily: "Poppins",
                                        fontSize: "14px",
                                      },
                                    }}
                                    onChange={(e) => {
                                      this.setState(
                                        {
                                          dmr_from_buyer_pph23_account:
                                            e.target.value,
                                        },
                                        () => this.dmr_from_buyer_calculations()
                                      );
                                    }}
                                  >
                                    <MenuItem value="0">Please Select</MenuItem>
                                    <MenuItem value="Buyer">Buyer</MenuItem>
                                    <MenuItem value="SDAM">SDAM</MenuItem>
                                  </TextField>
                                </div>
                              )}
                            </div>
                          </div>
                          <div
                            className="col-lg-7 p-2 rounded"
                            style={{ backgroundColor: "#f7f3f0" }}
                          >
                            <div className="row">
                              <div className="row col-lg-12 p-0">
                                <h6>Actual Detention</h6>
                              </div>
                              {(config.company === "SRPL" || config.company === "AVS"  ||
                                config.company === "PRPL" ||
                                ((config.company === "SDAM" ||
                                  config.company === "SRK" ||
                                  config.company === "BTR") &&
                                  this.state.dmr_from_buyer_pph23_account ===
                                    "Buyer")) && (
                                <div className="row">
                                  <div className="col-lg-6 mb-2">
                                    <label
                                      className="form_label mb-0"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Base price
                                    </label>
                                    <TextField
                                      name="dmr_from_buyer_base_price"
                                      margin="dense"
                                      type="number"
                                      variant="outlined"
                                      inputProps={{
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                        },
                                      }}
                                      value={
                                        this.state.dmr_from_buyer_base_price
                                      }
                                      onChange={(e) => {
                                        if (
                                          e.target.value === "" ||
                                          pr.test(e.target.value)
                                        ) {
                                          let dmr_from_buyer_base_price =
                                            Number(e.target.value).toFixed(2);
                                          let dmr_from_buyer_vat_percent = 10;
                                          let dmr_from_buyer_vat_value = (
                                            (Number(e.target.value) * 10) /
                                            100
                                          ).toFixed(2);
                                          let dmr_from_buyer_pph23_percent = 2;
                                          let dmr_from_buyer_pph23_value =
                                            Number(
                                              (e.target.value * 2) / 100
                                            ).toFixed(0);
                                          let dmr_from_buyer_total = (
                                            Number(dmr_from_buyer_base_price) +
                                            Number(dmr_from_buyer_vat_value) -
                                            Number(dmr_from_buyer_pph23_value)
                                          ).toFixed(2);
                                          if (
                                            config.company === "SRPL" || config.company === "AVS"  ||
                                            config.company === "PRPL"
                                          ) {
                                            dmr_from_buyer_total = (
                                              Number(
                                                dmr_from_buyer_base_price
                                              ) +
                                              Number(dmr_from_buyer_vat_value)
                                            ).toFixed(2);
                                          }
                                          let dmr_from_buyer_ctc = Number(
                                            Number(dmr_from_buyer_base_price) +
                                              Number(dmr_from_buyer_vat_value)
                                          ).toFixed(2);
                                          this.setState({
                                            dmr_from_buyer_base_price:
                                              e.target.value,
                                            dmr_from_buyer_vat_percent:
                                              dmr_from_buyer_vat_percent,
                                            dmr_from_buyer_vat_value:
                                              dmr_from_buyer_vat_value,
                                            dmr_from_buyer_pph23_percent:
                                              dmr_from_buyer_pph23_percent,
                                            dmr_from_buyer_pph23_value:
                                              dmr_from_buyer_pph23_value,
                                            dmr_from_buyer_total:
                                              dmr_from_buyer_total,
                                            dmr_from_buyer_ctc:
                                              dmr_from_buyer_ctc,
                                            dmr_from_buyer_ctc_in_local_currency:
                                              dmr_from_buyer_ctc,
                                          });
                                        }
                                      }}
                                      fullWidth
                                    />
                                  </div>
                                  <div className="col-lg-6 mb-2">
                                    <label
                                      className="form_label mb-2"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      VAT %
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {this.state.dmr_from_buyer_vat_percent
                                        ? this.state.dmr_from_buyer_vat_percent
                                        : "-"}
                                    </div>
                                  </div>

                                  <div className="col-lg-6 mb-2">
                                    <label
                                      className="form_label mb-0"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      VAT
                                    </label>
                                    <TextField
                                      name="dmr_from_buyer_vat_value"
                                      margin="dense"
                                      type="number"
                                      variant="outlined"
                                      inputProps={{
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                        },
                                      }}
                                      value={
                                        this.state.dmr_from_buyer_vat_value
                                      }
                                      onChange={(e) => {
                                        if (
                                          e.target.value === "" ||
                                          pr.test(e.target.value)
                                        ) {
                                          let dmr_from_buyer_total = (
                                            Number(
                                              this.state
                                                .dmr_from_buyer_base_price
                                            ) +
                                            Number(e.target.value) -
                                            Number(
                                              this.state
                                                .dmr_from_buyer_pph23_value
                                            )
                                          ).toFixed(2);
                                          let dmr_from_buyer_ctc = (
                                            Number(
                                              this.state
                                                .dmr_from_buyer_base_price
                                            ) + Number(e.target.value)
                                          ).toFixed(2);
                                          this.setState({
                                            dmr_from_buyer_vat_value:
                                              e.target.value,
                                            dmr_from_buyer_total:
                                              dmr_from_buyer_total,
                                            dmr_from_buyer_ctc:
                                              dmr_from_buyer_ctc,
                                            dmr_from_buyer_ctc_in_local_currency:
                                              dmr_from_buyer_ctc,
                                          });
                                        }
                                      }}
                                      fullWidth
                                    />
                                  </div>
                                  {!(
                                    config.company === "SRPL" || config.company === "AVS"  ||
                                    config.company === "PRPL"
                                  ) &&
                                    this.state.dmr_from_buyer_pph23_account ===
                                      "Buyer" && (
                                      <div className="col-lg-6 mb-2">
                                        <label
                                          className="form_label mb-0"
                                          style={{
                                            color: "rgba(72, 59, 52, 0.7)",
                                          }}
                                        >
                                          Includes PPH23 @{" "}
                                          {this.state
                                            .dmr_from_buyer_pph23_percent
                                            ? this.toLocaleString(
                                                this.state
                                                  .dmr_from_buyer_pph23_percent
                                              )
                                            : 0}
                                          %
                                        </label>
                                        {/* <div className='contract_display_header_value' style={{ color: '#42352d' }}>{this.state.dmr_to_buyer_pph23_value ? this.toLocaleString(this.state.dmr_to_buyer_pph23_value) : null}</div> */}
                                        <TextField
                                          name="dmr_from_buyer_pph23_value"
                                          margin="dense"
                                          type="number"
                                          variant="outlined"
                                          inputProps={{
                                            style: {
                                              fontFamily: "Poppins",
                                              fontSize: "14px",
                                            },
                                          }}
                                          value={
                                            this.state
                                              .dmr_from_buyer_pph23_value
                                          }
                                          onChange={(e) => {
                                            if (
                                              e.target.value === "" ||
                                              pr.test(e.target.value)
                                            ) {
                                              let dmr_from_buyer_total = (
                                                Number(
                                                  this.state
                                                    .dmr_from_buyer_base_price
                                                ) +
                                                Number(
                                                  this.state
                                                    .dmr_from_buyer_vat_value
                                                ) -
                                                Number(e.target.value)
                                              ).toFixed(2);
                                              let dmr_from_buyer_ctc = (
                                                Number(
                                                  this.state
                                                    .dmr_from_buyer_base_price
                                                ) +
                                                Number(
                                                  this.state
                                                    .dmr_from_buyer_vat_value
                                                )
                                              ).toFixed(2);
                                              this.setState({
                                                [e.target.name]: e.target.value,
                                                dmr_from_buyer_total:
                                                  dmr_from_buyer_total,
                                                dmr_from_buyer_ctc:
                                                  dmr_from_buyer_ctc,
                                              });
                                            }
                                          }}
                                          fullWidth
                                        />
                                      </div>
                                    )}
                                  <div className="col-lg-6 ">
                                    <label
                                      className="form_label mb-2"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Receivable
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {this.state.dmr_from_buyer_total
                                        ? this.toLocaleString(
                                            this.state.dmr_from_buyer_total
                                          )
                                        : 0}{" "}
                                      {config.company === "SRPL" || config.company === "AVS"  ||
                                      config.company === "PRPL"
                                        ? "USD"
                                        : "IDR"}
                                    </div>
                                  </div>
                                  <div className="col-lg-6 ">
                                    <label
                                      className="form_label mb-0"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Cost to Company
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {this.state.dmr_from_buyer_ctc
                                        ? this.toLocaleString(
                                            this.state.dmr_from_buyer_ctc
                                          )
                                        : 0}{" "}
                                      {config.company === "SRPL" || config.company === "AVS"  ||
                                      config.company === "PRPL"
                                        ? "USD"
                                        : "IDR"}
                                    </div>
                                  </div>
                                </div>
                              )}

                              {this.state.dmr_from_buyer_pph23_account ===
                                "SDAM" && (
                                <div className="row">
                                  <div className="col-lg-6 mb-2">
                                    <label
                                      className="form_label mb-0"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Base price
                                    </label>
                                    {/* <div className='contract_display_header_value' style={{ color: '#42352d' }}>{this.state.dmr_to_buyer_base_price ? this.toLocaleString(this.state.dmr_to_buyer_base_price) : '-'}</div> */}
                                    <TextField
                                      name="dmr_from_buyer_base_price"
                                      margin="dense"
                                      type="number"
                                      variant="outlined"
                                      inputProps={{
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                        },
                                      }}
                                      value={
                                        this.state.dmr_from_buyer_base_price
                                      }
                                      onChange={(e) => {
                                        if (
                                          e.target.value === "" ||
                                          pr.test(e.target.value)
                                        ) {
                                          this.setState({
                                            dmr_from_buyer_base_price:
                                              e.target.value,
                                            dmr_from_buyer_ctc: e.target.value,
                                          });
                                        }
                                      }}
                                      fullWidth
                                    />
                                  </div>

                                  <div className="col-lg-6 mb-2">
                                    <label
                                      className="form_label mb-0"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Includes PPH23 @{" "}
                                      {this.state.dmr_from_buyer_pph23_percent
                                        ? this.toLocaleString(
                                            this.state
                                              .dmr_from_buyer_pph23_percent
                                          )
                                        : 0}
                                      %
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {this.state.dmr_from_buyer_pph23_value
                                        ? this.toLocaleString(
                                            this.state
                                              .dmr_from_buyer_pph23_value
                                          )
                                        : null}
                                    </div>
                                  </div>
                                  <div className="col-lg-6 ">
                                    <label
                                      className="form_label mb-0"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Receivable
                                    </label>
                                    {/* <div className='contract_display_header_value' style={{ color: '#42352d' }}>{this.state.barge_vendor_total_value ? this.toLocaleString(this.state.barge_vendor_total_value) : 0} {(config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL") ? "USD" : "IDR"}</div> */}
                                    <TextField
                                      name="dmr_from_buyer_total"
                                      margin="dense"
                                      type="number"
                                      variant="outlined"
                                      inputProps={{
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                        },
                                      }}
                                      value={this.state.dmr_from_buyer_total}
                                      onChange={(e) => {
                                        if (
                                          e.target.value === "" ||
                                          pr.test(e.target.value)
                                        ) {
                                          let dmr_from_buyer_base_price =
                                            Number(
                                              (e.target.value * 100) / 98
                                            ).toFixed(2);
                                          let dmr_from_buyer_pph23_value =
                                            Number(
                                              (dmr_from_buyer_base_price * 2) /
                                                100
                                            ).toFixed(2);
                                          let dmr_from_buyer_ctc =
                                            dmr_from_buyer_base_price;
                                          this.setState({
                                            [e.target.name]: e.target.value,
                                            dmr_from_buyer_base_price:
                                              dmr_from_buyer_base_price,
                                            dmr_from_buyer_pph23_value:
                                              dmr_from_buyer_pph23_value,
                                            dmr_from_buyer_ctc:
                                              dmr_from_buyer_ctc,
                                          });
                                        }
                                      }}
                                      fullWidth
                                    />
                                  </div>
                                  <div className="col-lg-6 ">
                                    <label
                                      className="form_label mb-2"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Cost to Company
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {this.state.dmr_from_buyer_ctc
                                        ? this.toLocaleString(
                                            this.state.dmr_from_buyer_ctc
                                          )
                                        : 0}{" "}
                                      {config.company === "SRPL" || config.company === "AVS"  ||
                                      config.company === "PRPL"
                                        ? "USD"
                                        : "IDR"}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {this.props.demurrage_type === "company_to_buyer" && (
                      <div>
                        <div className="row mb-3">
                          <div className="col-lg-4 mb-2">
                            <label className="form_label mb-1">
                              NOR Anchorage
                            </label>
                            <div className="">
                              {this.state.NORAnchorageDate
                                ? localDateFormate(this.state.NORAnchorageDate)
                                : "-"}
                            </div>
                          </div>
                          <div className="col-lg-4 mb-2">
                            <label className="form_label mb-1">
                              Completion Discharge
                            </label>
                            <div className="">
                              {this.state.completionDischargeDate
                                ? localDateFormate(
                                    this.state.completionDischargeDate
                                  )
                                : "-"}
                            </div>
                          </div>
                          <div className="col-lg-4 p-0 mb-2">
                            <label className="form_label mb-1">
                              Time at Anchorage
                            </label>
                            <div className="">{this.state.timeAtAnchorage}</div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-5 pl-0">
                            <div className="row">
                              <div className="col-lg-12 p-0">
                                <label className="form_label mb-0">
                                  Time allowed for Discharge
                                </label>
                                <TextField
                                  name="dmr_to_buyer_allowed_for_discharge"
                                  variant="outlined"
                                  margin="dense"
                                  type="number"
                                  placeholder="Time allowed for Discharge"
                                  value={
                                    this.state
                                      .dmr_to_buyer_allowed_for_discharge
                                  }
                                  onChange={(e) => {
                                    if (
                                      e.target.value === "" ||
                                      re.test(e.target.value)
                                    ) {
                                      var value = e.target.value;
                                      if (value === "") {
                                        value = "0";
                                      }
                                      let anchorageDays = Number(
                                        Number(this.state.timeAtAnchorageDays) +
                                          (Number(
                                            this.state.timeAtAnchorageHours
                                          ) +
                                            Number(
                                              this.state.timeAtAnchorageMinutes
                                            ) /
                                              60) /
                                            24
                                      ).toFixed(0);
                                      if (
                                        Number(e.target.value) >
                                        Number(anchorageDays)
                                      ) {
                                        this.setState({
                                          dmr_to_buyer_allowed_for_dischargeError: true,
                                        });
                                      }
                                      let dmr_to_buyer_demurrage_in_days = (
                                        Number(anchorageDays) -
                                        Number(e.target.value)
                                      ).toFixed(2);
                                      let dmr_to_buyer_demurrage_value = "";
                                      if (
                                        this.state
                                          .dmr_to_buyer_barge_demurrage !== ""
                                      ) {
                                        dmr_to_buyer_demurrage_value = (
                                          Number(
                                            this.state
                                              .dmr_to_buyer_barge_demurrage
                                          ) * dmr_to_buyer_demurrage_in_days
                                        ).toFixed(2);
                                      }
                                      this.setState(
                                        {
                                          [e.target.name]: e.target.value,
                                          dmr_to_buyer_demurrage_in_days:
                                            dmr_to_buyer_demurrage_in_days,
                                          dmr_to_buyer_demurrage_value:
                                            dmr_to_buyer_demurrage_value,
                                          to_buyer_allowed_for_dischargeError: false,
                                          dmr_to_buyer_allowed_for_dischargeError: false,
                                        },
                                        () => this.dmr_to_buyer_calculations()
                                      );
                                    }
                                  }}
                                  error={
                                    this.state
                                      .dmr_to_buyer_allowed_for_dischargeError
                                  }
                                  fullWidth
                                />
                                {this.state
                                  .dmr_to_buyer_allowed_for_dischargeError ? (
                                  <span
                                    style={{ fontSize: "10px", color: "red" }}
                                  >
                                    Time allowed for Discharge is more than Time
                                    at Anchorage
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="col-lg-12 p-0">
                                <label className="form_label mb-0">
                                  Barge Demurrage
                                </label>
                                <TextField
                                  name="dmr_to_buyer_barge_demurrage"
                                  variant="outlined"
                                  margin="dense"
                                  type="number"
                                  inputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                    },
                                  }}
                                  placeholder="Barge Demurrage"
                                  value={
                                    this.state.dmr_to_buyer_barge_demurrage
                                  }
                                  onChange={(e) => {
                                    if (
                                      e.target.value === "" ||
                                      pr.test(e.target.value)
                                    ) {
                                      this.setState(
                                        {
                                          dmr_to_buyer_barge_demurrage:
                                            e.target.value,
                                        },
                                        () => this.dmr_to_buyer_calculations()
                                      );
                                    }
                                  }}
                                  fullWidth
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment
                                        position="end"
                                        style={{
                                          marginRight: "-14px",
                                          marginTop: "-4px",
                                          width: "50%",
                                        }}
                                      >
                                        <TextField
                                          name="dmr_to_buyer_barge_currency"
                                          margin="dense"
                                          variant="outlined"
                                          onChange={(e) => {
                                            this.setState({
                                              dmr_to_buyer_barge_currency:
                                                e.target.value,
                                            });
                                          }}
                                          value={
                                            this.state
                                              .dmr_to_buyer_barge_currency
                                          }
                                          fullWidth
                                          select
                                        >
                                          {this.state.currencyData}
                                        </TextField>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </div>
                              {this.state.sales_type === "FOB Barge" ? (
                                <div className="col-lg-12 p-0">
                                  <label className="form_label mb-0">
                                    Demurrage in days
                                  </label>
                                  <TextField
                                    name="dmr_to_buyer_demurrage_in_days"
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth
                                    inputProps={{
                                      style: {
                                        fontFamily: "Poppins",
                                        fontSize: "14px",
                                      },
                                    }}
                                    placeholder="Demurrage in days"
                                    value={
                                      this.state.dmr_to_buyer_demurrage_in_days
                                    }
                                    onChange={(e) => {
                                      if (
                                        e.target.value === "" ||
                                        re.test(e.target.value)
                                      ) {
                                        this.setState(
                                          {
                                            dmr_to_buyer_demurrage_in_days:
                                              e.target.value,
                                          },
                                          () => this.dmr_to_buyer_calculations()
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              ) : (
                                <div className="col-lg-12 p-0 mb-1">
                                  <label className="form_label mb-2">
                                    Demurrage in days
                                  </label>
                                  <div>
                                    {this.state.dmr_to_buyer_demurrage_in_days}
                                  </div>
                                </div>
                              )}

                              {(config.company === "SRPL" || config.company === "AVS"  ||
                                config.company === "PRPL") &&
                                this.state.dmr_to_buyer_barge_currency !==
                                  "USD" &&
                                this.state.dmr_to_buyer_barge_currency !==
                                  "0" && (
                                  <div className="col-lg-12 p-0">
                                    <label className="form_label mb-0">
                                      Exchange rate USD to{" "}
                                      {this.state.dmr_to_buyer_barge_currency}
                                    </label>
                                    <TextField
                                      name="dmr_to_buyer_currency_xchg_rate"
                                      margin="dense"
                                      variant="outlined"
                                      fullWidth
                                      inputProps={{
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                        },
                                      }}
                                      value={
                                        this.state
                                          .dmr_to_buyer_currency_xchg_rate
                                      }
                                      type="number"
                                      placeholder="Enter Value"
                                      onChange={(e) => {
                                        if (
                                          e.target.value === "" ||
                                          re.test(e.target.value)
                                        ) {
                                          this.setState(
                                            {
                                              dmr_to_buyer_currency_xchg_rate:
                                                e.target.value,
                                            },
                                            () =>
                                              this.dmr_to_buyer_calculations()
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                )}
                              {!(
                                config.company === "SRPL" || config.company === "AVS"  ||
                                config.company === "PRPL"
                              ) &&
                                this.state.dmr_to_buyer_barge_currency !==
                                  "IDR" &&
                                this.state.dmr_to_buyer_barge_currency !==
                                  "0" && (
                                  <div className="col-lg-12 p-0">
                                    <label className="form_label mb-0">
                                      Exchange rate{" "}
                                      {this.state.dmr_to_buyer_barge_currency}{" "}
                                      to IDR
                                    </label>
                                    <TextField
                                      name="dmr_to_buyer_currency_xchg_rate"
                                      margin="dense"
                                      variant="outlined"
                                      fullWidth
                                      inputProps={{
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                        },
                                      }}
                                      value={
                                        this.state
                                          .dmr_to_buyer_currency_xchg_rate
                                      }
                                      type="number"
                                      placeholder="Enter Value"
                                      onChange={(e) => {
                                        if (
                                          e.target.value === "" ||
                                          re.test(e.target.value)
                                        ) {
                                          this.setState(
                                            {
                                              dmr_to_buyer_currency_xchg_rate:
                                                e.target.value,
                                            },
                                            () =>
                                              this.dmr_to_buyer_calculations()
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                )}
                              <div className="col-lg-12 p-0">
                                <label className="form_label mb-0">
                                  Demurrage incurred
                                </label>
                                <TextField
                                  name="dmr_to_buyer_demurrage_value"
                                  margin="dense"
                                  variant="outlined"
                                  fullWidth
                                  inputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: "12px",
                                    },
                                  }}
                                  placeholder="Demurrage incurred"
                                  value={
                                    this.state.dmr_to_buyer_demurrage_value
                                  }
                                  onChange={(e) => {
                                    if (
                                      e.target.value === "" ||
                                      pr.test(e.target.value)
                                    ) {
                                      // let dmr_to_supplier_base_price = Number(e.target.value).toFixed(2);
                                      // let dmr_to_buyer_vat_percent = 10;
                                      // let dmr_to_supplier_vat_value = (Number(dmr_to_supplier_base_price) * 10 / 100).toFixed(2);
                                      // let dmr_to_supplier_pph23_percent = 2;
                                      // let dmr_to_buyer_pph23_value = Number((dmr_to_supplier_base_price * 2 / 100)).toFixed(0);
                                      // let dmr_to_supplier_total = (Number(dmr_to_supplier_base_price) + Number(dmr_to_supplier_vat_value) - Number(dmr_to_buyer_pph23_value)).toFixed(2);
                                      // if ((config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL")) {
                                      //   dmr_to_supplier_total = (Number(dmr_to_supplier_base_price) + Number(dmr_to_supplier_vat_value)).toFixed(2);
                                      // }
                                      // let dmr_to_supplier_ctc = Number(Number(dmr_to_supplier_base_price) + Number(dmr_to_supplier_vat_value)).toFixed(2);

                                      this.setState(
                                        {
                                          dmr_to_buyer_demurrage_value:
                                            e.target.value,
                                          // dmr_to_supplier_base_price: dmr_to_supplier_base_price,
                                          // dmr_to_buyer_vat_percent: dmr_to_buyer_vat_percent,
                                          // dmr_to_supplier_vat_value: dmr_to_supplier_vat_value,
                                          // dmr_to_supplier_pph23_percent: dmr_to_supplier_pph23_percent,
                                          // dmr_to_buyer_pph23_value: dmr_to_buyer_pph23_value,
                                          // dmr_to_supplier_total: dmr_to_supplier_total,
                                          // dmr_to_supplier_ctc: dmr_to_supplier_ctc
                                        },
                                        () => {
                                          this.dmr_to_buyer_calculations(
                                            "dmr_to_buyer_demurrage_value"
                                          );
                                        }
                                      );
                                    }
                                  }}
                                />
                              </div>
                              {!(
                                config.company === "SRPL" || config.company === "AVS"  ||
                                config.company === "PRPL"
                              ) && (
                                <div className="col-lg-12 p-0">
                                  <label className="form_label mb-0">
                                    PPH23 Account
                                  </label>
                                  <TextField
                                    name="dmr_to_buyer_pph23_account"
                                    margin="dense"
                                    variant="outlined"
                                    value={
                                      this.state.dmr_to_buyer_pph23_account
                                    }
                                    select
                                    fullWidth
                                    inputProps={{
                                      style: {
                                        fontFamily: "Poppins",
                                        fontSize: "14px",
                                      },
                                    }}
                                    onChange={(e) => {
                                      this.setState(
                                        {
                                          dmr_to_buyer_pph23_account:
                                            e.target.value,
                                        },
                                        () => this.dmr_to_buyer_calculations()
                                      );
                                    }}
                                  >
                                    <MenuItem value="0">Please Select</MenuItem>
                                    <MenuItem value="Buyer">Buyer</MenuItem>
                                    <MenuItem value="SDAM">SDAM</MenuItem>
                                  </TextField>
                                </div>
                              )}
                            </div>
                          </div>
                          <div
                            className="col-lg-7 p-2 rounded"
                            style={{ backgroundColor: "#f7f3f0" }}
                          >
                            <div className="row">
                              <div className="row col-lg-12 p-0">
                                <h6>Actual Demurrage</h6>
                              </div>
                              {(config.company === "SRPL" || config.company === "AVS"  ||
                                config.company === "PRPL" ||
                                ((config.company === "SDAM" ||
                                  config.company === "SRK" ||
                                  config.company === "BTR") &&
                                  this.state.dmr_to_buyer_pph23_account ===
                                    "Buyer")) && (
                                <div className="row">
                                  <div className="col-lg-6 mb-2">
                                    <label
                                      className="form_label mb-0"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Base price
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {this.state.dmr_to_buyer_base_price
                                        ? this.state.dmr_to_buyer_base_price
                                        : "-"}
                                    </div>
                                    {/*<TextField
                                    name='dmr_to_supplier_base_price'
                                    margin='dense'
                                    type="number"
                                    inputProps={{
                                      style: {
                                        fontFamily: 'Poppins',
                                        fontSize: '14px'
                                      }
                                    }}
                                    variant='outlined'
                                    value={this.state.dmr_to_supplier_base_price}
                                    onChange={(e) => {
                                      if (e.target.value === '' || pr.test(e.target.value)) {
                                        var dmr_to_supplier_vat_value = ((Number(e.target.value) * 10) / 100).toFixed(2);
                                        let dmr_to_buyer_pph23_value = Number((e.target.value * 2) / 100).toFixed(2);
                                        let dmr_to_supplier_total = (Number(e.target.value) + Number(dmr_to_supplier_vat_value)).toFixed(2);
                                        let dmr_to_supplier_ctc = (Number(e.target.value) + Number(dmr_to_supplier_vat_value)).toFixed(2);
                                        this.setState({
                                          [e.target.name]: e.target.value,
                                          dmr_to_supplier_vat_value: dmr_to_supplier_vat_value,
                                          dmr_to_buyer_pph23_value: dmr_to_buyer_pph23_value,
                                          dmr_to_supplier_total: dmr_to_supplier_total,
                                          dmr_to_supplier_ctc: dmr_to_supplier_ctc
                                        });
                                      }
                                    }}
                                    fullWidth
                                  /> */}
                                  </div>
                                  <div className="col-lg-6 mb-2">
                                    <label
                                      className="form_label mb-2"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      VAT %
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {this.state.dmr_to_buyer_vat_percent
                                        ? this.state.dmr_to_buyer_vat_percent
                                        : "-"}
                                    </div>
                                  </div>

                                  <div className="col-lg-6 mb-2">
                                    <label
                                      className="form_label mb-0"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      VAT
                                    </label>
                                    <TextField
                                      name="dmr_to_buyer_vat_value"
                                      margin="dense"
                                      type="number"
                                      variant="outlined"
                                      inputProps={{
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                        },
                                      }}
                                      value={this.state.dmr_to_buyer_vat_value}
                                      onChange={(e) => {
                                        if (
                                          e.target.value === "" ||
                                          pr.test(e.target.value)
                                        ) {
                                          let dmr_to_buyer_total = (
                                            Number(
                                              this.state.dmr_to_buyer_base_price
                                            ) + Number(e.target.value)
                                          ).toFixed(2);
                                          let dmr_to_buyer_ctc = (
                                            Number(
                                              this.state.dmr_to_buyer_base_price
                                            ) + Number(e.target.value)
                                          ).toFixed(2);

                                          this.setState({
                                            dmr_to_buyer_vat_value:
                                              e.target.value,
                                            dmr_to_buyer_total:
                                              dmr_to_buyer_total,
                                            dmr_to_buyer_ctc: dmr_to_buyer_ctc,
                                            dmr_to_buyer_ctc_in_local_currency:
                                              dmr_to_buyer_ctc,
                                          });
                                        }
                                      }}
                                      fullWidth
                                    />
                                  </div>
                                  {!(
                                    config.company === "SRPL" || config.company === "AVS"  ||
                                    config.company === "PRPL"
                                  ) &&
                                    this.state.dmr_to_buyer_pph23_account ===
                                      "Buyer" && (
                                      <div className="col-lg-6 mb-2">
                                        <label
                                          className="form_label mb-0"
                                          style={{
                                            color: "rgba(72, 59, 52, 0.7)",
                                          }}
                                        >
                                          Includes PPH23 @{" "}
                                          {this.state.dmr_to_buyer_pph23_percent
                                            ? this.toLocaleString(
                                                this.state
                                                  .dmr_to_buyer_pph23_percent
                                              )
                                            : 0}
                                          %
                                        </label>
                                        {/* <div className='contract_display_header_value' style={{ color: '#42352d' }}>{this.state.dmr_to_buyer_pph23_value ? this.toLocaleString(this.state.dmr_to_buyer_pph23_value) : null}</div> */}
                                        <TextField
                                          name="dmr_to_buyer_pph23_value"
                                          margin="dense"
                                          type="number"
                                          variant="outlined"
                                          inputProps={{
                                            style: {
                                              fontFamily: "Poppins",
                                              fontSize: "14px",
                                            },
                                          }}
                                          value={
                                            this.state.dmr_to_buyer_pph23_value
                                          }
                                          onChange={(e) => {
                                            if (
                                              e.target.value === "" ||
                                              pr.test(e.target.value)
                                            ) {
                                              let dmr_to_buyer_total = (
                                                Number(
                                                  this.state
                                                    .dmr_to_buyer_base_price
                                                ) +
                                                Number(
                                                  this.state
                                                    .dmr_to_buyer_vat_value
                                                ) -
                                                Number(e.target.value)
                                              ).toFixed(2);
                                              let dmr_to_buyer_ctc = (
                                                Number(
                                                  this.state
                                                    .dmr_to_buyer_base_price
                                                ) +
                                                Number(
                                                  this.state
                                                    .dmr_to_buyer_vat_value
                                                )
                                              ).toFixed(2);
                                              this.setState({
                                                [e.target.name]: e.target.value,
                                                dmr_to_buyer_total:
                                                  dmr_to_buyer_total,
                                                dmr_to_buyer_ctc:
                                                  dmr_to_buyer_ctc,
                                              });
                                            }
                                          }}
                                          fullWidth
                                        />
                                      </div>
                                    )}
                                  <div className="col-lg-6 ">
                                    <label
                                      className="form_label mb-2"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Payable
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {this.state.dmr_to_buyer_total
                                        ? this.toLocaleString(
                                            this.state.dmr_to_buyer_total
                                          )
                                        : 0}{" "}
                                      {config.company === "SRPL" || config.company === "AVS"  ||
                                      config.company === "PRPL"
                                        ? "USD"
                                        : "IDR"}
                                    </div>
                                  </div>
                                  <div className="col-lg-6 ">
                                    <label
                                      className="form_label mb-0"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Cost to Company
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {this.state.dmr_to_buyer_ctc
                                        ? this.toLocaleString(
                                            this.state.dmr_to_buyer_ctc
                                          )
                                        : 0}{" "}
                                      {config.company === "SRPL" || config.company === "AVS"  ||
                                      config.company === "PRPL"
                                        ? "USD"
                                        : "IDR"}
                                    </div>
                                  </div>
                                </div>
                              )}

                              {this.state.dmr_to_buyer_pph23_account ===
                                "SDAM" && (
                                <div className="row">
                                  <div className="col-lg-6 mb-2">
                                    <label
                                      className="form_label mb-0"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Base price
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {this.state.dmr_to_buyer_base_price
                                        ? this.toLocaleString(
                                            this.state.dmr_to_buyer_base_price
                                          )
                                        : "-"}
                                    </div>
                                  </div>

                                  <div className="col-lg-6 mb-2">
                                    <label
                                      className="form_label mb-0"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Includes PPH23 @{" "}
                                      {this.state.dmr_to_buyer_pph23_percent
                                        ? this.toLocaleString(
                                            this.state
                                              .dmr_to_buyer_pph23_percent
                                          )
                                        : 0}
                                      %
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {this.state.dmr_to_buyer_pph23_value
                                        ? this.toLocaleString(
                                            this.state.dmr_to_buyer_pph23_value
                                          )
                                        : null}
                                    </div>
                                  </div>
                                  <div className="col-lg-6 ">
                                    <label
                                      className="form_label mb-0"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Payable to Vendor
                                    </label>
                                    {/* <div className='contract_display_header_value' style={{ color: '#42352d' }}>{this.state.barge_vendor_total_value ? this.toLocaleString(this.state.barge_vendor_total_value) : 0} {(config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL") ? "USD" : "IDR"}</div> */}
                                    <TextField
                                      name="dmr_to_buyer_total"
                                      margin="dense"
                                      type="number"
                                      variant="outlined"
                                      inputProps={{
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                        },
                                      }}
                                      value={this.state.dmr_to_buyer_total}
                                      onChange={(e) => {
                                        if (
                                          e.target.value === "" ||
                                          pr.test(e.target.value)
                                        ) {
                                          let dmr_to_buyer_base_price = Number(
                                            (e.target.value * 100) / 98
                                          ).toFixed(2);
                                          let dmr_to_buyer_pph23_value = Number(
                                            (dmr_to_buyer_base_price * 2) / 100
                                          ).toFixed(2);
                                          let dmr_to_buyer_ctc =
                                            dmr_to_buyer_base_price;
                                          this.setState({
                                            [e.target.name]: e.target.value,
                                            dmr_to_buyer_base_price:
                                              dmr_to_buyer_base_price,
                                            dmr_to_buyer_pph23_value:
                                              dmr_to_buyer_pph23_value,
                                            dmr_to_buyer_ctc: dmr_to_buyer_ctc,
                                          });
                                        }
                                      }}
                                      fullWidth
                                    />
                                  </div>
                                  <div className="col-lg-6 ">
                                    <label
                                      className="form_label mb-2"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Cost to Company
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {this.state.dmr_to_buyer_ctc
                                        ? this.toLocaleString(
                                            this.state.dmr_to_buyer_ctc
                                          )
                                        : 0}{" "}
                                      {config.company === "SRPL" || config.company === "AVS"  ||
                                      config.company === "PRPL"
                                        ? "USD"
                                        : "IDR"}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <footer className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button btn-light"
              name="submit"
              onClick={
                this.state.activeStep === 0
                  ? () => this.props.returnCallbackViewPage(true)
                  : this.previousStepHandler
              }
            >
              {this.state.activeStep === 0 ? "CANCEL" : "PREVIOUS"}
            </button>
            <button
              type="button"
              className={
                this.state.activeStep === 0
                  ? "drawer_text drawer_btn_success"
                  : "btn next_button"
              }
              name="Back"
              onClick={
                this.state.activeStep === 0
                  ? this.submitHandler
                  : this.nextStepHandler
              }
            >
              {this.state.activeStep === 0 ? "UPDATE" : "NEXT STEP"}
            </button>
          </footer>
        </div>
      );
    }
  }
}
