import React, { Component } from 'react';
import {
    TextField, Checkbox, Snackbar, MenuItem
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Alert } from "@material-ui/lab";
import { withStyles } from "@material-ui/core/styles";
import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import api from "../../../api/api";
import Loader from '../../common/Loader';
import { pad } from '../../common/common';
import { SAMPLING_TYPES, PRE_SHIPMENT_TYPES, BasisEnum } from '../../../common/enums';


const CheckBox = withStyles({
    root: {
        color: "#ccc",
        "&$checked": {
            color: config.themeColor,
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);
export default class PreShipmentWithoutBarge extends Component {
    constructor(props) {
        super(props);
        this.Cookie = new CookieHandler();
        this.state = {
            vendorsDropDownData: [],
            jettiesDropDownData: [],
            jetty: { name: '' },
            supplier: { name: '', value: '' },

            businessNoBargeID: "",
            quality_calorific_value_actual: "",
            quality_calorific_value2_actual: "",
            quality_calorific_value3_actual: "",
            quality_total_moisture_actual: "",
            quality_inherent_moisture_actual: "",
            quality_ash_actual: "",
            quality_volatile_matter_actual: "",
            quality_fixed_carbon_actual: "",
            quality_fuel_ratio_actual: "",
            quality_total_sulphur_actual: "",
            quality_hgi_actual: "",


            quality_calorific_value_basis: "0",
            quality_calorific_value2_basis: "0",
            quality_calorific_value3_basis: "0",
            quality_total_moisture_basis: "0",
            quality_inherent_moisture_basis: "0",
            quality_ash_basis: "0",
            quality_volatile_matter_basis: "0",
            quality_fixed_carbon_basis: "0",
            quality_fuel_ratio_basis: "0",
            quality_total_sulphur_basis: "0",


            totalBarges: [],
            bargeList: [],
            isLoading: false,
            activeStep: 0,
            succesMsg: '',
            errorMsg: '',

            files: [
                {
                    file_description: "",
                    file_name: "",
                    file_extension: "",
                    file_content: "",
                    feature_name: "Pre_Shipment_Fin_Quality_Results",
                },
            ],
        }
    }

    componentDidMount() {
        if (!this.Cookie.isLoggedIn()) {
            this.props.history.replace("/logout");
        }
        const features = this.Cookie.getCookie("features");
        this.setState({ features: features });
        if (
            !(
                features.indexOf("55") >= 0 ||
                features.indexOf("57") >= 0 ||
                features.indexOf("97") >= 0
            )
        ) {
            window.location.href = "/dashboard";
        }
        this.getVendorsData();
        this.getAllJettiesData();
    }

    async getVendorsData() {
        const status = "";
        const idToken = this.Cookie.getIdTokenCookie();
        const loginUserID = this.Cookie.getCookie("loginUserId");
        this.setState({
            isLoading: true,
        });
        //Get vendors
        const vendors = await api.getVendors(loginUserID, idToken, status);
        if (vendors.status >= 200 && vendors.status < 300) {
            vendors.json().then((res) => {
                if (res.code === "200") {
                    if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
                    if (res.vendors) {
                        const vendorsDropDownData = [];
                        for (var i in res.vendors) {
                            if (res.vendors[i].vendor_type === "Barge") {
                                vendorsDropDownData.push({
                                    name: res.vendors[i].vendor_name,
                                    value: res.vendors[i].id,
                                    key: i,
                                });
                            }
                        }
                        this.setState({
                            vendorsDropDownData: vendorsDropDownData,
                            isLoading: false,
                        });
                    } else {
                        alert("Unexpected error occurred. Please contact administrator.");
                    }
                } else if (res.code === "607") {
                    window.location.href = "/logout";
                }
            });
        }
    }

    async getAllJettiesData() {
        const status = "Active";
        const idToken = this.Cookie.getIdTokenCookie();
        const loginUserID = this.Cookie.getCookie("loginUserId");
        this.setState({
            isLoading: true,
        });
        //Get vendors
        const jetties = await api.getAllJettiesData(loginUserID, idToken, status);
        if (jetties.status >= 200 && jetties.status < 300) {
            jetties.json().then((res) => {
                if (res.code === "200") {
                    if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
                    if (res.jetties) {
                        const jettiesDropDownData = [];
                        for (var i in res.jetties) {
                            jettiesDropDownData.push({
                                name: res.jetties[i].jettyName,
                                key: i,
                            });
                        }
                        this.setState({
                            jettiesDropDownData: jettiesDropDownData,
                            isLoading: false,
                        });
                    } else {
                        alert("Unexpected error occurred. Please contact administrator.");
                    }
                } else if (res.code === "607") {
                    window.location.href = "/logout";
                }
            });
        }
    }

    vendorSelectionHandler = (selectedSupplier) => {
        this.setState({ supplier: selectedSupplier });
    }

    jettySelectionHandler = (selectedJetty) => {
        this.setState({ jetty: selectedJetty });
    }

    nextStepHandler = () => {
        if (this.state.activeStep === 0) {
            if (this.state.jetty.name || this.state.supplier.name) {

            } else {
                this.setState({
                    snackBarErrorOpen: true,
                    errorMsg: "Please Select Jetty or Supplier",
                });
                return;
            }
        }

        this.setState({
            activeStep: this.state.activeStep + 1,
        });
    };

    previousStepHandler = () => {
        this.setState({
            activeStep: this.state.activeStep - 1,
        });
    };
    toLocaleString(value) {
        return value ? Number(value).toLocaleString() : 0;
    }
    fileUploadHandler = (idx) => (event) => {
        let file = event.target.files[0];
        let file_data = "";
        let fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onloadend = () => {
            let file_content = fileReader.result;
            let file_extension = file.name.substr(file.name.lastIndexOf(".") + 1);
            let file_name = file.name;
            let file_type = file.type;
            file_data = file_content.replace("data:" + file_type + ";base64,", "");

            var row = this.state.files ? this.state.files : [];
            row[idx].file_name = file_name;
            row[idx].file_extension = file_extension;
            row[idx].file_type = file_type;
            row[idx].file_content = file_data;

            this.setState({
                files: row,
            });
        };
    };
    addMoreFileHandler = () => {
        const data = {
            file_description: "",
            file_name: "",
            file_extension: "",
            file_content: "",
            feature_name: "Pre_Shipment_Fin_Quality_Results",
        };

        this.setState({
            files: [...this.state.files, data],
        });
    };
    TextHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };
    submitHandler = () => {
        const idToken = this.Cookie.getIdTokenCookie();
        const loginUserID = this.Cookie.getCookie("loginUserId");

        const {
            quality_calorific_value_actual,
            quality_calorific_value2_actual,
            quality_calorific_value3_actual,
            quality_total_moisture_actual,
            quality_inherent_moisture_actual,
            quality_ash_actual,
            quality_volatile_matter_actual,
            quality_fixed_carbon_actual,
            quality_fuel_ratio_actual,
            quality_total_sulphur_actual,
            quality_hgi_actual,
            quality_calorific_value_basis,
            quality_calorific_value2_basis,
            quality_calorific_value3_basis,
            quality_total_moisture_basis,
            quality_inherent_moisture_basis,
            quality_ash_basis,
            quality_volatile_matter_basis,
            quality_fixed_carbon_basis,
            quality_fuel_ratio_basis,
            quality_total_sulphur_basis,
            files,
            jetty,
            supplier
        } = this.state;
        const status = "Active";
        const barge_id = this.state.bargeList.toString();
        /**
         *  Checking Mandatory files
         */
        const missing_fields = 0;
        let files_missing = 0;
        const row = this.state.files;
        for (const obj of row) {
            if (obj.file_type !== "" || obj.file_content !== "") {
                if (obj.file_content !== "") {
                    if (obj.file_description === "") {
                        missing_fields++;
                        obj.file_description_error = true;
                    } else {
                        obj.file_description = obj.file_description.toUpperCase();
                    }
                } else if (obj.file_description !== "") {
                    if (obj.file_content === "") {
                        files_missing++;
                    }
                }
            }
        }
        this.setState({
            files: row,
        });

        if (missing_fields > 0) {
            this.setState({
                snackBarErrorOpen: true,
                errorMsg: "Please fill mandatory fields.",
            });
            return;
        }
        if (files_missing > 0) {
            this.setState({
                snackBarErrorOpen: true,
                errorMsg: "Please Select file.",
            });
            return;
        }

        const existing_file_ids = [];
        const existing_files = files.filter((e) => e.id);
        for (const i of existing_files) {
            existing_file_ids.push(i.id);
        }
        const new_files = files.filter((e) => !e.id);
        const content_files = new_files.filter((e) => e.file_content !== "");
        const samplingType = SAMPLING_TYPES.PRE_SHIPMENT;
        const type = PRE_SHIPMENT_TYPES.WITH_OUT_BARGE;
        if (!this.state.bargePurchaseQualityResultsAvailable) {
            // Add Barge Purchase Quality Results
            api
                .savePreShipmentSamplingData(
                    loginUserID,
                    idToken,
                    barge_id,
                    quality_calorific_value_actual,
                    quality_calorific_value2_actual,
                    quality_calorific_value3_actual,
                    quality_total_moisture_actual,
                    quality_inherent_moisture_actual,
                    quality_ash_actual,
                    quality_volatile_matter_actual,
                    quality_fixed_carbon_actual,
                    quality_fuel_ratio_actual,
                    quality_total_sulphur_actual,
                    quality_hgi_actual,
                    quality_calorific_value_basis,
                    quality_calorific_value2_basis,
                    quality_calorific_value3_basis,
                    quality_total_moisture_basis,
                    quality_inherent_moisture_basis,
                    quality_ash_basis,
                    quality_volatile_matter_basis,
                    quality_fixed_carbon_basis,
                    quality_fuel_ratio_basis,
                    quality_total_sulphur_basis,
                    status,
                    content_files,
                    samplingType, jetty.name, supplier.name, type,
                    existing_file_ids
                )
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        response.json().then((res) => {
                            if (res.code === "200") {
                                this.setState({
                                    succesMsg: res.message,
                                    errorMsg: "",
                                });
                                window.location.href = "/purchase-quality";
                            } else if (res.code === "601") {
                                this.setState({
                                    errorMsg: res.message,
                                    succesMsg: "",
                                });
                            } else if (res.code === "607") {
                                window.location.href = "/logout";
                            }
                        });
                    }
                });
        } else {
            api
                .savePurchaseSamplingData(
                    loginUserID,
                    idToken,
                    barge_id,
                    quality_calorific_value_actual,
                    quality_calorific_value2_actual,
                    quality_calorific_value3_actual,
                    quality_total_moisture_actual,
                    quality_inherent_moisture_actual,
                    quality_ash_actual,
                    quality_volatile_matter_actual,
                    quality_fixed_carbon_actual,
                    quality_fuel_ratio_actual,
                    quality_total_sulphur_actual,
                    quality_hgi_actual,
                    quality_calorific_value_basis,
                    quality_calorific_value2_basis,
                    quality_calorific_value3_basis,
                    quality_total_moisture_basis,
                    quality_inherent_moisture_basis,
                    quality_ash_basis,
                    quality_volatile_matter_basis,
                    quality_fixed_carbon_basis,
                    quality_fuel_ratio_basis,
                    quality_total_sulphur_basis,
                    status,
                    content_files,
                    samplingType,
                    jetty.name, supplier.name,
                    existing_file_ids,
                )
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        response.json().then((res) => {
                            if (res.code === "200") {
                                this.setState(
                                    {
                                        snackBarSuccessOpen: true,
                                        succesMsg: res.message,
                                        errorMsg: "",
                                    },
                                    () => (window.location.href = "/purchase-quality")
                                );
                            } else if (res.code === "601") {
                                this.setState({
                                    snackBarErrorOpen: true,
                                    errorMsg: res.message,
                                    succesMsg: "",
                                });
                            } else if (res.code === "607") {
                                window.location.href = "/logout";
                            }
                        });
                    }
                });
        }
    };

    handleBasisOnChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }
    render() {
        const re = /^[0-9]*\.?[0-9]*$/;
        const { vendorsDropDownData, supplier, jettiesDropDownData, jetty } = this.state;
        return (
            <div id="wraper">
                {!!this.state.isLoading && <Loader />}
                {!this.state.isLoading && (<section className="container p-3">
                    <div
                        className="row mb-0 stepper"
                        style={{ top: "-18px", zIndex: 50 }}
                    >
                        <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                            <i
                                className={
                                    this.state.activeStep > 0
                                        ? "fa fa-check-circle"
                                        : "fa fa-check-circle-o"
                                }
                                aria-hidden="true"
                                style={{
                                    fontSize: 20,
                                    color:
                                        this.state.activeStep > 0
                                            ? "#31c197"
                                            : this.state.activeStep === 0
                                                ? config.themeColor
                                                : "#ccc",
                                }}
                            ></i>
                            <span
                                className="stepper_text"
                                style={{
                                    color:
                                        this.state.activeStep > 0
                                            ? "#31c197"
                                            : this.state.activeStep === 0
                                                ? config.themeColor
                                                : "#ccc",
                                }}
                            >
                                Jetty or Supplier
                            </span>
                            <i
                                className="fa fa-chevron-right stepper_sub_icon stepper_arrow"
                                aria-hidden="true"
                                style={{
                                    color:
                                        this.state.activeStep > 0
                                            ? "#31c197"
                                            : this.state.activeStep === 0
                                                ? config.themeColor
                                                : "#ccc",
                                }}
                            ></i>
                        </span>

                        <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                            <i
                                className={
                                    this.state.activeStep > 1
                                        ? "fa fa-check-circle"
                                        : "fa fa-check-circle-o"
                                }
                                aria-hidden="true"
                                style={{
                                    fontSize: 20,
                                    color:
                                        this.state.activeStep > 1
                                            ? "#31c197"
                                            : this.state.activeStep === 1
                                                ? config.themeColor
                                                : "#ccc",
                                }}
                            ></i>
                            <span
                                className="stepper_text"
                                style={{
                                    color:
                                        this.state.activeStep > 1
                                            ? "#31c197"
                                            : this.state.activeStep === 1
                                                ? config.themeColor
                                                : "#ccc",
                                }}
                            >
                                Quality Results
                            </span>
                            <i
                                className="fa fa-chevron-right stepper_sub_icon stepper_arrow"
                                aria-hidden="true"
                                style={{
                                    color:
                                        this.state.activeStep > 1
                                            ? "#31c197"
                                            : this.state.activeStep === 1
                                                ? config.themeColor
                                                : "#ccc",
                                }}
                            ></i>
                        </span>
                    </div>
                    <div className="col-lg card" style={{ overflowY: "scroll" }}>
                        <Snackbar
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "center",
                            }}
                            open={this.state.snackBarErrorOpen}
                            onClose={() => this.setState({ snackBarErrorOpen: false })}
                            style={{ width: "450px" }}
                        >
                            <Alert
                                elevation={6}
                                variant="filled"
                                onClose={() =>
                                    this.setState({ snackBarErrorOpen: false })
                                }
                                severity="error"
                                style={{ width: "100%" }}
                            >
                                {this.state.errorMsg}
                            </Alert>
                        </Snackbar>
                        <Snackbar
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "center",
                            }}
                            style={{ width: "450px" }}
                            open={this.state.snackBarSuccessOpen}
                            autoHideDuration={100000}
                            onClose={() =>
                                this.setState({ snackBarSuccessOpen: false })
                            }
                        >
                            <Alert
                                elevation={6}
                                variant="filled"
                                onClose={() =>
                                    this.setState({ snackBarSuccessOpen: false })
                                }
                                severity="success"
                                style={{ width: "100%" }}
                            >
                                {this.state.succesMsg}
                            </Alert>
                        </Snackbar>
                        <div className="section_block w-100 pl-4 pr-4">
                            {this.state.activeStep === 0 && (
                                <div className="section_block w-100">
                                    <div className="row">
                                        <div className="col">
                                            <label className="form_label mb-0">
                                                Jetty
                                            </label>
                                            <Autocomplete
                                                options={jettiesDropDownData}
                                                getOptionLabel={(option) => option.name}
                                                size="small"
                                                onChange={(event, value) => this.jettySelectionHandler(value)}
                                                name="jettyName"
                                                value={jetty}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        placeholder="Search Jetty"
                                                        variant="outlined"
                                                        fullWidth
                                                        style={{ top: 8 }}
                                                    />
                                                )}
                                                style={{ display: "contents" }}
                                            />
                                        </div>
                                        <div className="col">
                                            <label className="form_label mb-0">
                                                Supplier
                                            </label>
                                            <Autocomplete
                                                options={vendorsDropDownData}
                                                getOptionLabel={(option) => option.name}
                                                size="small"
                                                onChange={(event, value) => this.vendorSelectionHandler(value)}
                                                name="supplier"
                                                value={supplier}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        placeholder="Search Supplier"
                                                        variant="outlined"
                                                        fullWidth
                                                        style={{ top: 8 }}
                                                    />
                                                )}
                                                style={{ display: "contents" }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                            {this.state.activeStep === 1 && (
                                <div>
                                    <div className="section_block w-100">
                                        <div className="row">
                                            <div className="table-responsive">
                                                <table className="table table-bordered table-sm mb-0">
                                                    <thead >
                                                        <tr>
                                                            <th
                                                                style={{ fontSize: 16 }}
                                                                className="text-uppercase text-center"
                                                            >
                                                                Typical Analysis
                                                            </th>
                                                            <th
                                                                style={{ fontSize: 16 }}
                                                                className="text-uppercase text-center"
                                                            >
                                                                Units
                                                            </th>
                                                            <th
                                                                style={{ fontSize: 16 }}
                                                                className="text-uppercase text-center"
                                                            >
                                                                Basis
                                                            </th>
                                                            <th
                                                                style={{ fontSize: 16 }}
                                                                className="text-uppercase text-center"
                                                            >
                                                                Actual
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td
                                                                className="table_header_barge_label"
                                                                style={{
                                                                    verticalAlign: "middle",
                                                                    fontSize: 14,
                                                                }}
                                                            >
                                                                Gross Calorific Value
                                                            </td>
                                                            <td style={{ verticalAlign: "middle" }}>
                                                                Kcal/kg
                                                            </td>
                                                            <td>
                                                                <TextField
                                                                    name="quality_calorific_value_basis"
                                                                    margin="dense"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    value={
                                                                        this.state
                                                                            .quality_calorific_value_basis
                                                                    }
                                                                    onChange={(event) =>
                                                                        this.handleBasisOnChange(
                                                                            event
                                                                        )
                                                                    }
                                                                    select
                                                                >
                                                                    <MenuItem
                                                                        value="0"
                                                                        disabled
                                                                        key={-1}
                                                                    >
                                                                        Basis
                                                                    </MenuItem>
                                                                    {Object.keys(BasisEnum).map((basisType, index) => {
                                                                        return <MenuItem
                                                                            value={`${basisType}`}
                                                                            key={index}
                                                                        >
                                                                            {basisType}
                                                                        </MenuItem>
                                                                    })}
                                                                </TextField>
                                                            </td>
                                                            {/* <td>
                                                                {
                                                                    this.state
                                                                        .quality_calorific_value_typical
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    this.state
                                                                        .quality_calorific_value_rejection
                                                                }
                                                            </td> */}
                                                            <td>
                                                                <TextField
                                                                    name="quality_calorific_value_actual"
                                                                    margin="dense"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    value={
                                                                        this.state
                                                                            .quality_calorific_value_actual
                                                                    }
                                                                    //onChange={this.TextHandler}
                                                                    onChange={(e) => {
                                                                        if (
                                                                            e.target.value === "" ||
                                                                            re.test(e.target.value)
                                                                        ) {
                                                                            this.setState({
                                                                                [e.target.name]: e.target.value,
                                                                            });
                                                                        }
                                                                    }}
                                                                    inputProps={{
                                                                        style: {
                                                                            textTransform: "uppercase",
                                                                        },
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td
                                                                className="table_header_barge_label"
                                                                style={{
                                                                    verticalAlign: "middle",
                                                                    fontSize: 14,
                                                                }}
                                                            ></td>
                                                            <td
                                                                style={{
                                                                    verticalAlign: "middle",
                                                                    borderTop: "none",
                                                                }}
                                                            >
                                                                Kcal/kg
                                                            </td>
                                                            <td style={{ borderTop: "none" }}>
                                                                <TextField
                                                                    name="quality_calorific_value2_basis"
                                                                    margin="dense"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    value={
                                                                        this.state
                                                                            .quality_calorific_value2_basis
                                                                    }
                                                                    onChange={(event) =>
                                                                        this.handleBasisOnChange(
                                                                            event
                                                                        )
                                                                    }
                                                                    select
                                                                >
                                                                    <MenuItem
                                                                        value="0"
                                                                        disabled
                                                                        key={-1}
                                                                    >
                                                                        Basis
                                                                    </MenuItem>
                                                                    {Object.keys(BasisEnum).map((basisType, index) => {
                                                                        return <MenuItem
                                                                            value={`${basisType}`}
                                                                            key={index}
                                                                        >
                                                                            {basisType}
                                                                        </MenuItem>
                                                                    })}
                                                                </TextField>
                                                            </td>
                                                            {/* <td style={{ borderTop: "none" }}>
                                                                {
                                                                    this.state
                                                                        .quality_calorific_value2_typical
                                                                }
                                                            </td>
                                                            <td style={{ borderTop: "none" }}>
                                                                {
                                                                    this.state
                                                                        .quality_calorific_value2_rejection
                                                                }
                                                            </td> */}
                                                            <td>
                                                                <TextField
                                                                    name="quality_calorific_value2_actual"
                                                                    margin="dense"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    value={
                                                                        this.state
                                                                            .quality_calorific_value2_actual
                                                                    }
                                                                    //onChange={this.TextHandler}
                                                                    onChange={(e) => {
                                                                        if (
                                                                            e.target.value === "" ||
                                                                            re.test(e.target.value)
                                                                        ) {
                                                                            this.setState({
                                                                                [e.target.name]: e.target.value,
                                                                            });
                                                                        }
                                                                    }}
                                                                    inputProps={{
                                                                        style: {
                                                                            textTransform: "uppercase",
                                                                        },
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td
                                                                className="table_header_barge_label"
                                                                style={{
                                                                    verticalAlign: "middle",
                                                                    fontSize: 14,
                                                                }}
                                                            ></td>
                                                            <td
                                                                style={{
                                                                    verticalAlign: "middle",
                                                                    borderTop: "none",
                                                                }}
                                                            >
                                                                Kcal/kg
                                                            </td>
                                                            <td style={{ borderTop: "none" }}>
                                                                <TextField
                                                                    name="quality_calorific_value3_basis"
                                                                    margin="dense"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    value={
                                                                        this.state
                                                                            .quality_calorific_value3_basis
                                                                    }
                                                                    onChange={(event) =>
                                                                        this.handleBasisOnChange(
                                                                            event
                                                                        )
                                                                    }
                                                                    select
                                                                >
                                                                    <MenuItem
                                                                        value="0"
                                                                        disabled
                                                                        key={-1}
                                                                    >
                                                                        Basis
                                                                    </MenuItem>
                                                                    {Object.keys(BasisEnum).map((basisType, index) => {
                                                                        return <MenuItem
                                                                            value={`${basisType}`}
                                                                            key={index}
                                                                        >
                                                                            {basisType}
                                                                        </MenuItem>
                                                                    })}
                                                                </TextField>
                                                            </td>
                                                            {/* <td style={{ borderTop: "none" }}>
                                                                {
                                                                    this.state
                                                                        .quality_calorific_value3_typical
                                                                }
                                                            </td>
                                                            <td style={{ borderTop: "none" }}>
                                                                {
                                                                    this.state
                                                                        .quality_calorific_value3_rejection
                                                                }
                                                            </td> */}
                                                            <td>
                                                                <TextField
                                                                    name="quality_calorific_value3_actual"
                                                                    margin="dense"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    value={
                                                                        this.state
                                                                            .quality_calorific_value3_actual
                                                                    }
                                                                    //onChange={this.TextHandler}
                                                                    onChange={(e) => {
                                                                        if (
                                                                            e.target.value === "" ||
                                                                            re.test(e.target.value)
                                                                        ) {
                                                                            this.setState({
                                                                                [e.target.name]: e.target.value,
                                                                            });
                                                                        }
                                                                    }}
                                                                    inputProps={{
                                                                        style: {
                                                                            textTransform: "uppercase",
                                                                        },
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td
                                                                className="table_header_barge_label"
                                                                style={{
                                                                    verticalAlign: "middle",
                                                                    fontSize: 14,
                                                                }}
                                                            >
                                                                Total Moisture
                                                            </td>
                                                            <td style={{ verticalAlign: "middle" }}>
                                                                %
                                                            </td>
                                                            <td>
                                                                <TextField
                                                                    name="quality_total_moisture_basis"
                                                                    margin="dense"
                                                                    variant="outlined"
                                                                    value={
                                                                        this.state
                                                                            .quality_total_moisture_basis
                                                                    }
                                                                    onChange={(event) =>
                                                                        this.handleBasisOnChange(
                                                                            event
                                                                        )
                                                                    }
                                                                    fullWidth
                                                                    select
                                                                >
                                                                    <MenuItem value="0">
                                                                        Basis
                                                                    </MenuItem>
                                                                    <MenuItem value="ARB">
                                                                        ARB
                                                                    </MenuItem>
                                                                </TextField>
                                                            </td>
                                                            {/* <td>
                                                                {
                                                                    this.state
                                                                        .quality_total_moisture_typical
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    this.state
                                                                        .quality_total_moisture_rejection
                                                                }
                                                            </td> */}
                                                            <td>
                                                                <TextField
                                                                    name="quality_total_moisture_actual"
                                                                    margin="dense"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    value={
                                                                        this.state
                                                                            .quality_total_moisture_actual
                                                                    }
                                                                    onChange={this.TextHandler}
                                                                    inputProps={{
                                                                        style: {
                                                                            textTransform: "uppercase",
                                                                        },
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td
                                                                className="table_header_barge_label"
                                                                style={{
                                                                    verticalAlign: "middle",
                                                                    fontSize: 14,
                                                                }}
                                                            >
                                                                Inherent Moisture
                                                            </td>
                                                            <td style={{ verticalAlign: "middle" }}>
                                                                %
                                                            </td>
                                                            <td>
                                                                <TextField
                                                                    name="quality_inherent_moisture_basis"
                                                                    margin="dense"
                                                                    variant="outlined"
                                                                    value={
                                                                        this.state
                                                                            .quality_inherent_moisture_basis
                                                                    }
                                                                    onChange={(event) =>
                                                                        this.handleBasisOnChange(
                                                                            event
                                                                        )
                                                                    }
                                                                    fullWidth
                                                                    select
                                                                >
                                                                    <MenuItem value="0">
                                                                        Basis
                                                                    </MenuItem>
                                                                    <MenuItem value="ADB">
                                                                        ADB
                                                                    </MenuItem>
                                                                </TextField>
                                                            </td>
                                                            {/* <td>
                                                                {
                                                                    this.state
                                                                        .quality_inherent_moisture_typical
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    this.state
                                                                        .quality_inherent_moisture_rejection
                                                                }
                                                            </td> */}
                                                            <td>
                                                                <TextField
                                                                    name="quality_inherent_moisture_actual"
                                                                    margin="dense"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    value={
                                                                        this.state
                                                                            .quality_inherent_moisture_actual
                                                                    }
                                                                    onChange={this.TextHandler}
                                                                    inputProps={{
                                                                        style: {
                                                                            textTransform: "uppercase",
                                                                        },
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td
                                                                className="table_header_barge_label"
                                                                style={{
                                                                    verticalAlign: "middle",
                                                                    fontSize: 14,
                                                                }}
                                                            >
                                                                Ash
                                                            </td>
                                                            <td style={{ verticalAlign: "middle" }}>
                                                                %
                                                            </td>
                                                            <td>
                                                                <TextField
                                                                    name="quality_ash_basis"
                                                                    margin="dense"
                                                                    variant="outlined"
                                                                    value={
                                                                        this.state
                                                                            .quality_ash_basis
                                                                    }
                                                                    onChange={(event) =>
                                                                        this.handleBasisOnChange(
                                                                            event
                                                                        )
                                                                    }
                                                                    fullWidth
                                                                    select
                                                                >
                                                                    <MenuItem value="0">
                                                                        Basis
                                                                    </MenuItem>
                                                                    <MenuItem value="ADB">
                                                                        ADB
                                                                    </MenuItem>
                                                                </TextField></td>
                                                            {/* <td>{this.state.quality_ash_typical}</td>
                                                            <td>
                                                                {this.state.quality_ash_rejection}
                                                            </td> */}
                                                            <td>
                                                                <TextField
                                                                    name="quality_ash_actual"
                                                                    margin="dense"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    value={this.state.quality_ash_actual}
                                                                    //onChange={this.TextHandler}
                                                                    onChange={(e) => {
                                                                        if (
                                                                            e.target.value === "" ||
                                                                            re.test(e.target.value)
                                                                        ) {
                                                                            this.setState({
                                                                                [e.target.name]: e.target.value,
                                                                            });
                                                                        }
                                                                    }}
                                                                    inputProps={{
                                                                        style: {
                                                                            textTransform: "uppercase",
                                                                        },
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td
                                                                className="table_header_barge_label"
                                                                style={{
                                                                    verticalAlign: "middle",
                                                                    fontSize: 14,
                                                                }}
                                                            >
                                                                Volatile Matter(VM)
                                                            </td>
                                                            <td style={{ verticalAlign: "middle" }}>
                                                                %
                                                            </td>
                                                            <td>
                                                                <TextField
                                                                    name="quality_volatile_matter_basis"
                                                                    margin="dense"
                                                                    variant="outlined"
                                                                    value={
                                                                        this.state
                                                                            .quality_volatile_matter_basis
                                                                    }
                                                                    onChange={(event) =>
                                                                        this.handleBasisOnChange(
                                                                            event
                                                                        )
                                                                    }
                                                                    fullWidth
                                                                    select
                                                                >
                                                                    <MenuItem value="0">
                                                                        Basis
                                                                    </MenuItem>
                                                                    <MenuItem value="ADB">
                                                                        ADB
                                                                    </MenuItem>
                                                                </TextField>
                                                            </td>
                                                            {/* <td>
                                                                {
                                                                    this.state
                                                                        .quality_volatile_matter_typical
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    this.state
                                                                        .quality_volatile_matter_rejection
                                                                }
                                                            </td> */}
                                                            <td>
                                                                <TextField
                                                                    name="quality_volatile_matter_actual"
                                                                    margin="dense"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    value={
                                                                        this.state
                                                                            .quality_volatile_matter_actual
                                                                    }
                                                                    onChange={this.TextHandler}
                                                                    inputProps={{
                                                                        style: {
                                                                            textTransform: "uppercase",
                                                                        },
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td
                                                                className="table_header_barge_label"
                                                                style={{
                                                                    verticalAlign: "middle",
                                                                    fontSize: 14,
                                                                }}
                                                            >
                                                                Fixed Carbon(FC)
                                                            </td>
                                                            <td style={{ verticalAlign: "middle" }}>
                                                                %
                                                            </td>
                                                            <td>
                                                                <TextField
                                                                    name="quality_fixed_carbon_basis"
                                                                    margin="dense"
                                                                    variant="outlined"
                                                                    value={
                                                                        this.state
                                                                            .quality_fixed_carbon_basis
                                                                    }
                                                                    onChange={(event) =>
                                                                        this.handleBasisOnChange(
                                                                            event
                                                                        )
                                                                    }
                                                                    fullWidth
                                                                    select
                                                                >
                                                                    <MenuItem value="0">
                                                                        Basis
                                                                    </MenuItem>
                                                                    <MenuItem value="ADB">
                                                                        ADB
                                                                    </MenuItem>
                                                                </TextField>
                                                            </td>
                                                            {/* <td>
                                                                {
                                                                    this.state
                                                                        .quality_fixed_carbon_typical
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    this.state
                                                                        .quality_fixed_carbon_rejection
                                                                }
                                                            </td> */}
                                                            <td>
                                                                <TextField
                                                                    name="quality_fixed_carbon_actual"
                                                                    margin="dense"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    value={
                                                                        this.state
                                                                            .quality_fixed_carbon_actual
                                                                    }
                                                                    onChange={this.TextHandler}
                                                                    inputProps={{
                                                                        style: {
                                                                            textTransform: "uppercase",
                                                                        },
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td
                                                                className="table_header_barge_label"
                                                                style={{
                                                                    verticalAlign: "middle",
                                                                    fontSize: 14,
                                                                }}
                                                            >
                                                                Fuel Ratio(FC/VM)
                                                            </td>
                                                            <td style={{ verticalAlign: "middle" }}>
                                                                %
                                                            </td>
                                                            <td>
                                                                <TextField
                                                                    name="quality_fuel_ratio_basis"
                                                                    margin="dense"
                                                                    variant="outlined"
                                                                    value={
                                                                        this.state
                                                                            .quality_fuel_ratio_basis
                                                                    }
                                                                    onChange={(event) =>
                                                                        this.handleBasisOnChange(
                                                                            event
                                                                        )
                                                                    }
                                                                    fullWidth
                                                                    select
                                                                >
                                                                    <MenuItem value="0">
                                                                        Basis
                                                                    </MenuItem>
                                                                    <MenuItem value="ADB">
                                                                        ADB
                                                                    </MenuItem>
                                                                </TextField>
                                                            </td>
                                                            {/* <td>
                                                                {this.state.quality_fuel_ratio_typical}
                                                            </td>
                                                            <td>
                                                                {
                                                                    this.state
                                                                        .quality_fuel_ratio_rejection
                                                                }
                                                            </td> */}
                                                            <td>
                                                                <TextField
                                                                    name="quality_fuel_ratio_actual"
                                                                    margin="dense"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    value={
                                                                        this.state.quality_fuel_ratio_actual
                                                                    }
                                                                    onChange={this.TextHandler}
                                                                    inputProps={{
                                                                        style: {
                                                                            textTransform: "uppercase",
                                                                        },
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td
                                                                className="table_header_barge_label"
                                                                style={{
                                                                    verticalAlign: "middle",
                                                                    fontSize: 14,
                                                                }}
                                                            >
                                                                Total Sulphur
                                                            </td>
                                                            <td style={{ verticalAlign: "middle" }}>
                                                                %
                                                            </td>
                                                            <td>

                                                            <TextField
                                                                    name="quality_total_sulphur_basis"
                                                                    margin="dense"
                                                                    variant="outlined"
                                                                    value={
                                                                        this.state
                                                                            .quality_total_sulphur_basis
                                                                    }
                                                                    onChange={(event) =>
                                                                        this.handleBasisOnChange(
                                                                            event
                                                                        )
                                                                    }
                                                                    fullWidth
                                                                    select
                                                                >
                                                                    <MenuItem value="0">
                                                                        Basis
                                                                    </MenuItem>
                                                                    <MenuItem value="ADB">
                                                                        ADB
                                                                    </MenuItem>
                                                                </TextField>
                                                            </td>
                                                            {/* <td>
                                                                {
                                                                    this.state
                                                                        .quality_total_sulphur_typical
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    this.state
                                                                        .quality_total_sulphur_rejection
                                                                }
                                                            </td> */}
                                                            <td>
                                                                <TextField
                                                                    name="quality_total_sulphur_actual"
                                                                    margin="dense"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    value={
                                                                        this.state
                                                                            .quality_total_sulphur_actual
                                                                    }
                                                                    //onChange={this.TextHandler}
                                                                    onChange={(e) => {
                                                                        if (
                                                                            e.target.value === "" ||
                                                                            re.test(e.target.value)
                                                                        ) {
                                                                            this.setState({
                                                                                [e.target.name]: e.target.value,
                                                                            });
                                                                        }
                                                                    }}
                                                                    inputProps={{
                                                                        style: {
                                                                            textTransform: "uppercase",
                                                                        },
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td
                                                                className="table_header_barge_label"
                                                                style={{
                                                                    verticalAlign: "middle",
                                                                    fontSize: 14,
                                                                }}
                                                            >
                                                                HGI
                                                            </td>
                                                            <td
                                                                style={{ verticalAlign: "middle" }}
                                                            ></td>
                                                            <td></td>
                                                            {/* <td>{this.state.quality_hgi_typical}</td>
                                                            <td>
                                                                {this.state.quality_hgi_rejection}
                                                            </td> */}
                                                            <td>
                                                                <TextField
                                                                    name="quality_hgi_actual"
                                                                    margin="dense"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    value={this.state.quality_hgi_actual}
                                                                    onChange={this.TextHandler}
                                                                    inputProps={{
                                                                        style: {
                                                                            textTransform: "uppercase",
                                                                        },
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    {/* {(this.state.features.indexOf('323') >= 0 || this.state.features.indexOf('97') >= 0) && */}

                                    <div className="card p-0 border mb-3">
                                        <div className="card-header section_header">
                                            <h5>File Upload Details</h5>
                                        </div>
                                        <div className="card-body">
                                            {this.state.files.map((e, index) => (
                                                <div className="" key={index}>
                                                    {e.file_path ? (
                                                        <div className="row mb-3" key={index}>
                                                            <div className="col-lg-10 p-0">
                                                                <a
                                                                    href={
                                                                        config.apiDomain + "/" + e.file_path
                                                                    }
                                                                    role="button"
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    style={{ color: config.themeColor }}
                                                                >
                                                                    {e.file_description}
                                                                </a>
                                                            </div>
                                                            <center className="col-lg-1 d-flex justify-content-center">
                                                                <i
                                                                    className="fa fa-trash-o m-auto"
                                                                    onClick={(e) => {
                                                                        const row = this.state.files;
                                                                        row.splice(index, 1);
                                                                        this.setState({
                                                                            files: row,
                                                                        });
                                                                    }}
                                                                    style={{
                                                                        fontSize: 24,
                                                                        color: config.themeColor,
                                                                        cursor: "pointer",
                                                                    }}
                                                                />
                                                            </center>
                                                        </div>
                                                    ) : (
                                                        <div className="row mb-3" key={index}>
                                                            <div className="col-lg-4 p-0">
                                                                <label className="form_label mb-0">
                                                                    File Description
                                                                </label>
                                                                <TextField
                                                                    name="file_description"
                                                                    variant="outlined"
                                                                    margin="dense"
                                                                    fullWidth
                                                                    inputProps={{
                                                                        style: { textTransform: "uppercase" },
                                                                    }}
                                                                    value={e.file_description}
                                                                    error={e.file_description_error}
                                                                    onChange={(e) => {
                                                                        var row = this.state.files;
                                                                        row[index].file_description =
                                                                            e.target.value;
                                                                        row[
                                                                            index
                                                                        ].file_description_error = false;
                                                                        this.setState({
                                                                            files: row,
                                                                        });
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="col-lg-6 pr-0">
                                                                <label className="form_label mb-2">
                                                                    Select file to upload
                                                                </label>
                                                                <input
                                                                    type="file"
                                                                    name="fileToUpload"
                                                                    id="fileToUpload"
                                                                    className="form-control border-0 pl-0"
                                                                    onChange={this.fileUploadHandler(index)}
                                                                />
                                                            </div>

                                                            <center className="col-lg-1 d-flex justify-content-center">
                                                                <i
                                                                    className="fa fa-trash-o m-auto"
                                                                    onClick={(e) => {
                                                                        const row = this.state.files;
                                                                        row.splice(index, 1);
                                                                        this.setState({
                                                                            files: row,
                                                                        });
                                                                    }}
                                                                    style={{
                                                                        fontSize: 24,
                                                                        color: config.themeColor,
                                                                        cursor: "pointer",
                                                                    }}
                                                                />
                                                            </center>
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                            <button
                                                type="button"
                                                style={{ color: config.themeColor, width: 110 }}
                                                className="header_button header_button_text addrow_button_adjustment"
                                                onClick={this.addMoreFileHandler}
                                            >
                                                Add More
                                            </button>
                                        </div>
                                    </div>
                                    {/* } */}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="row bottom_buttons_section">
                        <button
                            type="button"
                            className="btn previous_button"
                            name="back"
                            onClick={
                                this.state.activeStep === 0
                                    ? () => {

                                    }
                                    : this.previousStepHandler
                            }
                        >
                            {this.state.activeStep === 0 ? "CANCEL" : "PREVIOUS"}
                        </button>
                        <button
                            type="button"
                            className="btn next_button"
                            name="submit"
                            onClick={
                                this.state.activeStep === 1
                                    ? this.submitHandler
                                    : this.nextStepHandler
                            }
                        >
                            {this.state.activeStep === 1 ? "SUBMIT" : "NEXT STEP"}
                        </button>
                    </div>
                </section>
                )}
            </div>
        )
    }
}
