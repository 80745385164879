import React, { Component } from "react";
import SideBar from "./SideBar";
import Header from "./Header";
import CookieHandler from "./CookieHandler";
import "../../App.css";
import api from "../../api/api";
import config from "../../config/config";
import {
  MenuItem,
  Checkbox,
  Drawer,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Snackbar,
  TextField,
  //  Radio,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Alert } from "@material-ui/lab";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Loader from "./Loader";

const NewSwitch = withStyles({
  switchBase: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
    "&$checked + $track": {
      backgroundColor: config.themeColor,
    },
  },
  checked: {},
  track: {},
})(Switch);

const summaryStyles = {
  root: {
    minHeight: 7 * 5,
    "&$expanded": {
      minHeight: 7 * 5,
    },
  },
  expanded: {},
};
const CompactExpansionPanelSummary =
  withStyles(summaryStyles)(AccordionSummary);
CompactExpansionPanelSummary.muiName = "AccordionSummary";

const CheckBox = withStyles({
  root: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default class UserAccessManagement extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      openDrawer: false,
      user_id: null,
      feature_ids: [],

      vendorAdvanceChecked: [],
      vendorAdvancePaymentChecked: [],
      View_Vendor_Contact_and_KYC: [],
      View_Customer_Contact_and_KYC: [],
      purchaseContractChecked: [],
      cps_contract: [],
      purchaseContractAdvPaymentChecked: [],
      salesContractChecked: [],
      salesContractAdvPaymentChecked: [],
      stevedoreContractChecked: [],
      floatingCranetChecked: [],
      businessNoChecked: [],
      BusinessNoOpMVChecked: [],
      BusinessNoOpBargeChecked: [],
      barge_agreements: [],
      View_Price_in_Barge_Checked: [],
      MVDemurrageChecked: [],
      MVDemurragePaymentChecked: [],
      bargeDemurrageChecked: [],
      qualityResultsChecked: [],
      MVPurchaseFinancialChecked: [],
      CoalProcurementChargesChecked: [],
      //barge pur fin
      barge_pur_coal_costing: [],
      barge_pur_coal_costing_payments: [],
      barge_pur_barge_costing: [],
      barge_pur_barge_costing_payments: [],
      chemical_spray: [],
      chemical_spray_payments: [],
      barge_pur_cpc: [],
      barge_pur_cps: [],
      barge_return_cargo: [],
      barge_return_cargo_payments: [],
      barge_pur_cpc_payments: [],
      barge_pur_cps_payments: [],
      barge_pur_demurrage_cost: [],
      barge_pur_demurrage_cost_payments: [],
      barge_pur_sac: [],
      barge_pur_sac_payments: [],
      barge_pur_pre_shipmet_inspection: [],
      barge_pur_pre_shipmet_inspection_payments: [],
      barge_pur_export_tax: [],
      barge_pur_export_tax_payments: [],
      barge_pur_doc: [],
      barge_laprn_charges: [],
      barge_pur_doc_payments: [],
      barge_laprn_payments: [],
      barge_pur_other_expense: [],
      barge_pur_other_expense_payments: [],

      // barge sales fin
      barge_sales_coal_costing: [],
      barge_sales_coal_costing_invoices: [],
      barge_sales_coal_costing_payments: [],
      barge_sales_coal_sales_charges: [],
      barge_sales_coal_sales_charges_invoices: [],
      barge_sales_coal_sales_charges_payments: [],
      barge_sales_demurrage: [],
      barge_sales_demurrage_invoices: [],
      barge_sales_demurrage_payments: [],

      // Mother vessel purchase fin
      mv_pur_fin_stevedore_costing: [],
      pnbp_charges: [],
      pnbp_payments: [],
      mv_pur_fin_stevedore_costing_payments: [],
      mv_pur_fin_freight_costing: [],
      mv_pur_fin_freight_costing_payments: [],

      mv_pur_fin_cpc: [],
      mv_pur_fin_cpc_payments: [],
      mv_pur_fin_sac: [],
      mv_pur_fin_sac_payments: [],
      mv_pur_fin_pre_shipment_inspection: [],
      mv_pur_fin_pre_shipment_inspection_payments: [],
      mv_pur_fin_floating_crane: [],
      mv_pur_fin_floating_crane_payments: [],
      mv_pur_fin_export_tax: [],
      mv_pur_fin_export_tax_payments: [],
      mv_pur_fin_doc: [],
      mv_laprn_charges: [],
      mv_pur_fin_doc_payments: [],
      mv_laprn_payments: [],
      mv_dead_freight: [],
      mv_dead_freight_payments: [],
      vessel_insurance: [],
      vessel_insurance_payments: [],
      // Mother vessel sales financial
      mv_sales_fin_coal_sales_charges: [],
      mv_sales_fin_coal_sales_charges_invoices: [],
      mv_sales_fin_coal_sales_charges_payments: [],

      // services
      services: [],
      services_payments: [],

      other_expenses: [],
      other_expenses_payments: [],
      barge_insurance: [],
      barge_insurance_payments: [],
      other_income: [],
      other_income_payments: [],

      MVPurchaseFinancialPaymentsChecked: [],
      bargePurchaseChecked: [],
      bargePurchaseFinancialChecked: [],
      MVSalesFinancialChecked: [],
      MVsalesFinancialPaymetsChecked: [],
      bargeSalesFinancialChecked: [],
      bargesSalesFinancialPaymentsChecked: [],
      credAndDebNotesChecked: [],
      credAndDebNotesPaymentsChecked: [],
      businessNoShipmentChecklistChecked: [],
      expenditureChecked: [],
      business_profit_and_loss: [],
      profit_and_loss_coal_vendor: [],
      vat_input_summary: [],
      pc_available_qunatity_report: [],
      coal_price_index: [],
      outgoing_approvals: [],

      minesChecked: [],
      budget_and_costing: [],
      userChecked: [],
      fixtureNoteChecked: [],
      vessel_completion_report: [],

      usersMenuData: [],
      usersData: [],

      errorMsg: "",
      succesMsg: "",
      userIdError: false,
      isLoading: true,
    };
  }

  componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    var features = this.Cookie.getCookie("features");
    if (!features.includes("97")) {
      window.location.href = "/dashboard";
    }
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const status = "Active";
    api.getUserList(loginUserID, idToken, status).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res) {
            if (res.users) {
              const usersMenuData = [];
              for (var i of res.users) {
                if (i.user_name !== "admin") {
                  usersMenuData.push(
                    <MenuItem
                      className="drop_down_list"
                      value={i.user_id}
                      key={i.user_id}
                    >
                      {i.user_name}
                    </MenuItem>
                  );
                }
              }
              this.setState({
                usersData: res.users,
                usersMenuData: usersMenuData,
              });
            }
          } else if (res.code === "601") {
            this.setState({
              errorMsg: res.message,
              snackBarErrorOpen: true,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
    this.setState({
      isLoading: false,
    });

    document.title = config.documentTitle + "User Access Management";
  }

  getUserFeatures = (userId) => {
    if (userId !== "0") {
      this.setState({
        //isLoading: true,
        drawerLoading: true,
      });
      const loginUserID = this.Cookie.getCookie("loginUserId");
      const idToken = this.Cookie.getIdTokenCookie();
      const userID = userId;
      api
        .getUserFeatureMaping(loginUserID, idToken, userID)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                if (res.user_feature_mapping) {
                  const vendorAdvanceChecked = res.user_feature_mapping.filter(
                    (e, idx) => {
                      return (
                        (0 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 3) ||
                        Number(e.feature_id) === 588 ||
                        Number(e.feature_id) === 589
                      );
                    }
                  );

                  const vendorAdvancePaymentChecked =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        (4 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 6) ||
                        Number(e.feature_id) === 590 ||
                        Number(e.feature_id) === 591
                      );
                    });
                  const purchaseContractChecked =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        (7 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 15) ||
                        Number(e.feature_id) === 326 ||
                        Number(e.feature_id) === 592 ||
                        Number(e.feature_id) === 593
                      );
                    });
                  const cps_contract = res.user_feature_mapping.filter(
                    (e, idx) => {
                      return (
                        500 <= Number(e.feature_id) &&
                        Number(e.feature_id) <= 505
                      );
                    }
                  );
                  const purchaseContractAdvPaymentChecked =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        (103 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 106) ||
                        Number(e.feature_id) === 594 ||
                        Number(e.feature_id) === 595
                      );
                    });

                  const salesContractChecked = res.user_feature_mapping.filter(
                    (e, idx) => {
                      return (
                        (16 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 24) ||
                        Number(e.feature_id) === 327
                      );
                    }
                  );

                  const salesContractAdvPaymentChecked =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        107 <= Number(e.feature_id) &&
                        Number(e.feature_id) <= 110
                      );
                    });
                  const stevedoreContractChecked =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        29 <= Number(e.feature_id) && Number(e.feature_id) <= 32
                      );
                    });
                  const floatingCranetChecked = res.user_feature_mapping.filter(
                    (e, idx) => {
                      return (
                        33 <= Number(e.feature_id) && Number(e.feature_id) <= 36
                      );
                    }
                  );
                  const businessNoChecked = res.user_feature_mapping.filter(
                    (e, idx) => {
                      return (
                        (37 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 41) ||
                        Number(e.feature_id) === 122 ||
                        Number(e.featured_id) === 115
                      );
                    }
                  );
                  const BusinessNoOpMVChecked = res.user_feature_mapping.filter(
                    (e, idx) => {
                      return (
                        42 <= Number(e.feature_id) && Number(e.feature_id) <= 44
                      );
                    }
                  );
                  const BusinessNoOpBargeChecked =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        (45 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 48) ||
                        Number(e.feature_id) === 102 ||
                        Number(e.feature_id) === 123 ||
                        Number(e.feature_id) === 102 ||
                        Number(e.feature_id) === 375 ||
                        Number(e.feature_id) === 612
                      );
                    });
                  const barge_agreements = res.user_feature_mapping.filter(
                    (e, idx) => {
                      return (
                        555 <= Number(e.feature_id) &&
                        Number(e.feature_id) <= 561
                      );
                    }
                  );
                  const MVDemurrageChecked = res.user_feature_mapping.filter(
                    (e, idx) => {
                      return (
                        (49 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 51) ||
                        Number(e.feature_id) === 432 ||
                        Number(e.feature_id) === 483 ||
                        Number(e.feature_id) === 526
                      );
                    }
                  );
                  const MVDemurragePaymentChecked =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        434 <= Number(e.feature_id) &&
                        Number(e.feature_id) <= 438
                      );
                    });
                  const bargeDemurrageChecked = res.user_feature_mapping.filter(
                    (e, idx) => {
                      return (
                        52 <= Number(e.feature_id) && Number(e.feature_id) <= 54
                      );
                    }
                  );
                  const qualityResultsChecked = res.user_feature_mapping.filter(
                    (e, idx) => {
                      return (
                        (55 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 57) ||
                        Number(e.feature_id) === 533
                      );
                    }
                  );
                  const MVPurchaseFinancialChecked =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        58 <= Number(e.feature_id) && Number(e.feature_id) <= 60
                      );
                    });
                  const MVPurchaseFinancialPaymentsChecked =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        61 <= Number(e.feature_id) && Number(e.feature_id) <= 63
                      );
                    });
                  const bargePurchaseChecked = res.user_feature_mapping.filter(
                    (e, idx) => {
                      return (
                        64 <= Number(e.feature_id) && Number(e.feature_id) <= 66
                      );
                    }
                  );
                  const bargePurchaseFinancialChecked =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        67 <= Number(e.feature_id) && Number(e.feature_id) <= 69
                      );
                    });
                  const MVSalesFinancialChecked =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        70 <= Number(e.feature_id) && Number(e.feature_id) <= 72
                      );
                    });
                  const MVsalesFinancialPaymetsChecked =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        73 <= Number(e.feature_id) && Number(e.feature_id) <= 75
                      );
                    });
                  const bargeSalesFinancialChecked =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        76 <= Number(e.feature_id) && Number(e.feature_id) <= 78
                      );
                    });
                  const bargesSalesFinancialPaymentsChecked =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        79 <= Number(e.feature_id) && Number(e.feature_id) <= 81
                      );
                    });
                  const credAndDebNotesChecked =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        (82 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 84) ||
                        Number(e.feature_id === 376)
                      );
                    });
                  const credAndDebNotesPaymentsChecked =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        85 <= Number(e.feature_id) && Number(e.feature_id) <= 87
                      );
                    });
                  const businessNoShipmentChecklistChecked =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        88 <= Number(e.feature_id) && Number(e.feature_id) <= 90
                      );
                    });
                  const minesChecked = res.user_feature_mapping.filter(
                    (e, idx) => {
                      return (
                        91 <= Number(e.feature_id) && Number(e.feature_id) <= 93
                      );
                    }
                  );
                  const budget_and_costing = res.user_feature_mapping.filter(
                    (e, idx) => {
                      return (
                        640 <= Number(e.feature_id) && Number(e.feature_id) <= 642
                      );
                    }
                  );
                  const userChecked = res.user_feature_mapping.filter(
                    (e, idx) => {
                      return (
                        94 <= Number(e.feature_id) && Number(e.feature_id) <= 97
                      );
                    }
                  );
                  const CoalProcurementChargesChecked =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        116 <= Number(e.feature_id) &&
                        Number(e.feature_id) <= 118
                      );
                    });
                  // const barge_sales_coal_sales_charges =
                  //   res.user_feature_mapping.filter((e, idx) => {
                  //     return (
                  //       (119 <= Number(e.feature_id) &&
                  //         Number(e.feature_id) <= 121) ||
                  //       Number(e.feature_id) === 374
                  //     );
                  //});
                  // const View_Price_in_Barge_Checked = res.user_feature_mapping.filter((e, idx) => {
                  //   return (123 <= Number(e.feature_id) && Number(e.feature_id) <= 123)
                  // });
                  const View_Vendor_Contact_and_KYC =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        Number(e.feature_id) === 124 ||
                        Number(e.feature_id) === 324
                      );
                    });

                  const View_Customer_Contact_and_KYC =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        Number(e.feature_id) === 125 ||
                        Number(e.feature_id) === 325
                      );
                    });

                  const barge_pur_coal_costing =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        (126 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 129) ||
                        Number(e.feature_id) === 365 ||
                        Number(e.feature_id) === 388 ||
                        Number(e.feature_id) === 439
                      );
                    });
                  const barge_pur_coal_costing_payments =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        (130 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 133) ||
                        Number(e.feature_id) === 389 ||
                        Number(e.feature_id) === 440
                      );
                    });

                  //barge pur fin
                  const barge_pur_barge_costing =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        (134 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 137) ||
                        Number(e.feature_id) === 373 ||
                        Number(e.feature_id) === 390 ||
                        Number(e.feature_id) === 441
                      );
                    });
                  const barge_pur_barge_costing_payments =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        (138 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 141) ||
                        Number(e.feature_id) === 391 ||
                        Number(e.feature_id) === 442
                      );
                    });
                  const chemical_spray = res.user_feature_mapping.filter(
                    (e) => {
                      return (
                        575 <= Number(e.feature_id) &&
                        Number(e.feature_id) <= 581
                      );
                    }
                  );
                  const chemical_spray_payments =
                    res.user_feature_mapping.filter((e) => {
                      return (
                        582 <= Number(e.feature_id) &&
                        Number(e.feature_id) <= 587
                      );
                    });
                  const barge_pur_cpc = res.user_feature_mapping.filter(
                    (e, idx) => {
                      return (
                        (142 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 145) ||
                        Number(e.feature_id) === 371 ||
                        Number(e.feature_id) === 392 ||
                        Number(e.feature_id) === 443
                      );
                    }
                  );
                  const barge_pur_cpc_payments =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        (146 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 149) ||
                        Number(e.feature_id) === 393 ||
                        Number(e.feature_id) === 444
                      );
                    });
                  const barge_pur_cps = res.user_feature_mapping.filter(
                    (e, idx) => {
                      return (
                        (506 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 509) ||
                        Number(e.feature_id) === 514 ||
                        Number(e.feature_id) === 516 ||
                        Number(e.feature_id) === 518
                      );
                    }
                  );

                  const barge_pur_cps_payments =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        (510 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 513) ||
                        Number(e.feature_id) === 515 ||
                        Number(e.feature_id) === 517
                      );
                    });
                  const barge_pur_demurrage_cost =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        (150 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 153) ||
                        Number(e.feature_id) === 394 ||
                        Number(e.feature_id) === 445 ||
                        Number(e.feature_id) === 530
                      );
                    });
                  const barge_pur_demurrage_cost_payments =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        (154 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 157) ||
                        Number(e.feature_id) === 395 ||
                        Number(e.feature_id) === 446
                      );
                    });
                  const barge_pur_sac = res.user_feature_mapping.filter(
                    (e, idx) => {
                      return (
                        (158 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 161) ||
                        Number(e.feature_id) === 370 ||
                        Number(e.feature_id) === 396 ||
                        Number(e.feature_id) === 447
                      );
                    }
                  );
                  const barge_pur_sac_payments =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        (162 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 165) ||
                        Number(e.feature_id) === 397 ||
                        Number(e.feature_id) === 448
                      );
                    });
                  const barge_pur_pre_shipmet_inspection =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        (166 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 169) ||
                        Number(e.feature_id) === 372 ||
                        Number(e.feature_id) === 398 ||
                        Number(e.feature_id) === 449
                      );
                    });
                  const barge_pur_pre_shipmet_inspection_payments =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        (170 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 173) ||
                        Number(e.feature_id) === 399 ||
                        Number(e.feature_id) === 450
                      );
                    });
                  const barge_pur_export_tax = res.user_feature_mapping.filter(
                    (e, idx) => {
                      return (
                        (174 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 177) ||
                        Number(e.feature_id) === 369 ||
                        Number(e.feature_id) === 400 ||
                        Number(e.feature_id) === 451
                      );
                    }
                  );
                  const barge_pur_export_tax_payments =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        (178 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 181) ||
                        Number(e.feature_id) === 401 ||
                        Number(e.feature_id) === 452
                      );
                    });
                  const barge_pur_doc = res.user_feature_mapping.filter(
                    (e, idx) => {
                      return (
                        (182 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 185) ||
                        Number(e.feature_id) === 368 ||
                        Number(e.feature_id) === 402 ||
                        Number(e.feature_id) === 453
                      );
                    }
                  );
                  const barge_laprn_charges = res.user_feature_mapping.filter(
                    (e, idx) => {
                      return (
                        (608 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 611) ||
                        Number(e.feature_id) === 601 ||
                        Number(e.feature_id) === 603 ||
                        Number(e.feature_id) === 453
                      );
                    }
                  );
                  const barge_pur_other_expense =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        (338 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 341) ||
                        Number(e.feature_id) === 378 ||
                        Number(e.feature_id) === 550 ||
                        Number(e.feature_id) === 551
                      );
                    });
                  const barge_pur_doc_payments =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        (186 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 189) ||
                        Number(e.feature_id) === 403 ||
                        Number(e.feature_id) === 454 ||
                        Number(e.feature_id) === 552 ||
                        Number(e.feature_id) === 553
                      );
                    });
                  const barge_laprn_payments = res.user_feature_mapping.filter(
                    (e, idx) => {
                      return (
                        600 <= Number(e.feature_id) &&
                        Number(e.feature_id) <= 607
                      );
                    }
                  );
                  const barge_pur_other_expense_payments =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        342 <= Number(e.feature_id) &&
                        Number(e.feature_id) <= 345
                      );
                    });

                  const barge_sales_coal_costing =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        (190 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 193) ||
                        Number(e.feature_id) === 366 ||
                        Number(e.feature_id) === 404 ||
                        Number(e.feature_id) === 405 ||
                        Number(e.feature_id) === 455
                      );
                    });
                  const barge_sales_coal_costing_invoices =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        (194 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 197) ||
                        Number(e.feature_id) === 405 ||
                        Number(e.feature_id) === 456
                      );
                    });
                  const barge_sales_coal_costing_payments =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        (198 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 201) ||
                        Number(e.feature_id) === 406 ||
                        Number(e.feature_id) === 457
                      );
                    });
                  const barge_sales_coal_sales_charges =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        (202 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 205) ||
                        Number(e.feature_id) === 407 ||
                        Number(e.feature_id) === 458
                      );
                    });
                  const barge_sales_coal_sales_charges_invoices =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        (206 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 209) ||
                        Number(e.feature_id) === 374 ||
                        Number(e.feature_id) === 408 ||
                        Number(e.feature_id) === 459
                      );
                    });
                  const barge_sales_coal_sales_charges_payments =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        (210 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 213) ||
                        Number(e.feature_id) === 409 ||
                        Number(e.feature_id) === 460
                      );
                    });
                  const barge_sales_demurrage = res.user_feature_mapping.filter(
                    (e, idx) => {
                      return (
                        (214 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 217) ||
                        Number(e.feature_id) === 410 ||
                        Number(e.feature_id) === 461
                      );
                    }
                  );
                  const barge_sales_demurrage_invoices =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        (218 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 221) ||
                        Number(e.feature_id) === 411 ||
                        Number(e.feature_id) === 462
                      );
                    });
                  const barge_sales_demurrage_payments =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        (222 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 225) ||
                        Number(e.feature_id) === 412 ||
                        Number(e.feature_id) === 463
                      );
                    });

                  // mv pur
                  const mv_pur_fin_stevedore_costing =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        (226 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 229) ||
                        Number(e.feature_id) === 413 ||
                        Number(e.feature_id) === 464 ||
                        Number(e.feature_id) === 532
                      );
                    });
                  const pnbp_charges = res.user_feature_mapping.filter(
                    (e, idx) => {
                      return (
                        (627 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 630) ||
                        Number(e.feature_id) === 635 ||
                        Number(e.feature_id) === 637 ||
                        Number(e.feature_id) === 639
                      );
                    }
                  );
                  const pnbp_payments = res.user_feature_mapping.filter(
                    (e, idx) => {
                      return (
                        (631 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 634) ||
                        Number(e.feature_id) === 636 ||
                        Number(e.feature_id) === 638
                      );
                    }
                  );
                  const mv_pur_fin_stevedore_costing_payments =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        (230 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 233) ||
                        Number(e.feature_id) === 414 ||
                        Number(e.feature_id) === 465
                      );
                    });
                  const mv_pur_fin_cpc = res.user_feature_mapping.filter(
                    (e, idx) => {
                      return (
                        (234 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 237) ||
                        Number(e.feature_id) === 415 ||
                        Number(e.feature_id) === 466 ||
                        Number(e.feature_id) === 519
                      );
                    }
                  );
                  const barge_return_cargo = res.user_feature_mapping.filter(
                    (e, idx) => {
                      return (
                        536 <= Number(e.feature_id) &&
                        Number(e.feature_id) <= 542
                      );
                    }
                  );
                  const barge_return_cargo_payments =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        543 <= Number(e.feature_id) &&
                        Number(e.feature_id) <= 548
                      );
                    });
                  const mv_pur_fin_cpc_payments =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        (238 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 241) ||
                        Number(e.feature_id) === 416 ||
                        Number(e.feature_id) === 467
                      );
                    });
                  const mv_pur_fin_freight_costing =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        (379 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 382) ||
                        Number(e.feature_id) === 417 ||
                        Number(e.feature_id) === 468 ||
                        Number(e.feature_id) === 528
                      );
                    });
                  const mv_pur_fin_freight_costing_payments =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        (383 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 386) ||
                        Number(e.feature_id) === 418 ||
                        Number(e.feature_id) === 469
                      );
                    });
                  const mv_pur_fin_sac = res.user_feature_mapping.filter(
                    (e, idx) => {
                      return (
                        (242 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 245) ||
                        Number(e.feature_id) === 419 ||
                        Number(e.feature_id) === 470 ||
                        Number(e.feature_id) === 520
                      );
                    }
                  );
                  const mv_pur_fin_sac_payments =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        (246 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 249) ||
                        Number(e.feature_id) === 420 ||
                        Number(e.feature_id) === 471
                      );
                    });
                  const mv_pur_fin_pre_shipment_inspection =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        (250 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 253) ||
                        Number(e.feature_id) === 421 ||
                        Number(e.feature_id) === 472 ||
                        Number(e.feature_id) === 521
                      );
                    });
                  const mv_pur_fin_pre_shipment_inspection_payments =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        (254 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 257) ||
                        Number(e.feature_id) === 422 ||
                        Number(e.feature_id) === 473
                      );
                    });
                  const mv_pur_fin_floating_crane =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        (258 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 261) ||
                        Number(e.feature_id) === 423 ||
                        Number(e.feature_id) === 474 ||
                        Number(e.feature_id) === 522
                      );
                    });
                  const mv_pur_fin_floating_crane_payments =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        (262 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 265) ||
                        Number(e.feature_id) === 424 ||
                        Number(e.feature_id) === 475
                      );
                    });
                  const mv_pur_fin_export_tax = res.user_feature_mapping.filter(
                    (e, idx) => {
                      return (
                        (266 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 269) ||
                        Number(e.feature_id) === 425 ||
                        Number(e.feature_id) === 476 ||
                        Number(e.feature_id) === 523
                      );
                    }
                  );
                  const mv_pur_fin_export_tax_payments =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        (270 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 273) ||
                        Number(e.feature_id) === 426 ||
                        Number(e.feature_id) === 477
                      );
                    });
                  const mv_pur_fin_doc = res.user_feature_mapping.filter(
                    (e, idx) => {
                      return (
                        (274 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 277) ||
                        Number(e.feature_id) === 427 ||
                        Number(e.feature_id) === 478 ||
                        Number(e.feature_id) === 524
                      );
                    }
                  );
                  const mv_laprn_charges = res.user_feature_mapping.filter(
                    (e, idx) => {
                      return (
                        614 <= Number(e.feature_id) &&
                        Number(e.feature_id) <= 620
                      );
                    }
                  );
                  const mv_pur_fin_doc_payments =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        (278 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 281) ||
                        Number(e.feature_id) === 428 ||
                        Number(e.feature_id) === 479
                      );
                    });
                  const mv_laprn_payments = res.user_feature_mapping.filter(
                    (e, idx) => {
                      return (
                        621 <= Number(e.feature_id) &&
                        Number(e.feature_id) <= 626
                      );
                    }
                  );
                  const mv_dead_freight = res.user_feature_mapping.filter(
                    (e, idx) => {
                      return (
                        562 <= Number(e.feature_id) &&
                        Number(e.feature_id) <= 567
                      );
                    }
                  );
                  const mv_dead_freight_payments =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        568 <= Number(e.feature_id) &&
                        Number(e.feature_id) <= 573
                      );
                    });
                  const vessel_insurance = res.user_feature_mapping.filter(
                    (e, idx) => {
                      return (
                        354 <= Number(e.feature_id) &&
                        (Number(e.feature_id) <= 357 ||
                          Number(e.feature_id) === 363)
                      );
                    }
                  );
                  const vessel_insurance_payments =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        358 <= Number(e.feature_id) &&
                        Number(e.feature_id) <= 361
                      );
                    });

                  const mv_sales_fin_coal_sales_charges =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        (282 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 285) ||
                        Number(e.feature_id) === 429 ||
                        Number(e.feature_id) === 480
                      );
                    });
                  const mv_sales_fin_coal_sales_charges_invoices =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        (286 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 289) ||
                        Number(e.feature_id) === 430 ||
                        Number(e.feature_id) === 481
                      );
                    });
                  const mv_sales_fin_coal_sales_charges_payments =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        (290 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 293) ||
                        Number(e.feature_id) === 431 ||
                        Number(e.feature_id) === 482
                      );
                    });
                  const other_expenses = res.user_feature_mapping.filter(
                    (e, idx) => {
                      return (
                        294 <= Number(e.feature_id) &&
                        (Number(e.feature_id) <= 297 ||
                          Number(e.feature_id) === 487 ||
                          Number(e.feature_id) === 493 ||
                          Number(e.feature_id) === 378)
                      );
                    }
                  );
                  const other_expenses_payments =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        298 <= Number(e.feature_id) &&
                        (Number(e.feature_id) <= 301 ||
                          Number(e.feature_id) === 488 ||
                          Number(e.feature_id) === 494)
                      );
                    });
                  const barge_insurance = res.user_feature_mapping.filter(
                    (e, idx) => {
                      return (
                        346 <= Number(e.feature_id) &&
                        (Number(e.feature_id) <= 349 ||
                          Number(e.feature_id) === 362)
                      );
                    }
                  );
                  const barge_insurance_payments =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        350 <= Number(e.feature_id) &&
                        Number(e.feature_id) <= 353
                      );
                    });
                  const other_income = res.user_feature_mapping.filter(
                    (e, idx) => {
                      return (
                        302 <= Number(e.feature_id) &&
                        (Number(e.feature_id) <= 305 ||
                          Number(e.feature_id) === 489 ||
                          Number(e.feature_id) === 495 ||
                          Number(e.feature_id) === 499)
                      );
                    }
                  );
                  const other_income_payments = res.user_feature_mapping.filter(
                    (e, idx) => {
                      return (
                        306 <= Number(e.feature_id) &&
                        (Number(e.feature_id) <= 309 ||
                          Number(e.feature_id) === 490 ||
                          Number(e.feature_id) === 496)
                      );
                    }
                  );
                  const expenditure = res.user_feature_mapping.filter(
                    (e, idx) => {
                      return (
                        310 <= Number(e.feature_id) &&
                        (Number(e.feature_id) <= 313 ||
                          Number(e.feature_id) === 328 ||
                          Number(e.feature_id) === 534)
                      );
                    }
                  );
                  const business_profit_and_loss =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        314 <= Number(e.feature_id) &&
                        Number(e.feature_id) <= 314
                      );
                    });
                  const profit_and_loss_coal_vendor =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        332 <= Number(e.feature_id) &&
                        Number(e.feature_id) <= 332
                      );
                    });
                  const services = res.user_feature_mapping.filter((e, idx) => {
                    return (
                      (315 <= Number(e.feature_id) &&
                        Number(e.feature_id) <= 318) ||
                      Number(e.feature_id) === 323 ||
                      Number(e.feature_id) === 596 ||
                      Number(e.feature_id) === 597
                    );
                  });
                  const services_payments = res.user_feature_mapping.filter(
                    (e, idx) => {
                      return (
                        (319 <= Number(e.feature_id) &&
                          Number(e.feature_id) <= 322) ||
                        Number(e.feature_id) === 598 ||
                        Number(e.feature_id) === 599
                      );
                    }
                  );
                  const fixture_notes = res.user_feature_mapping.filter(
                    (e, idx) => {
                      return (
                        333 <= Number(e.feature_id) &&
                        Number(e.feature_id) <= 337
                      );
                    }
                  );
                  const vessel_report = res.user_feature_mapping.filter(
                    (e, idx) => {
                      return (
                        329 <= Number(e.feature_id) &&
                        Number(e.feature_id) <= 331
                      );
                    }
                  );
                  const vat_input_summary = res.user_feature_mapping.filter(
                    (e, idx) => {
                      return (
                        387 <= Number(e.feature_id) &&
                        Number(e.feature_id) <= 387
                      );
                    }
                  );
                  const pc_available_qunatity_report =
                    res.user_feature_mapping.filter((e, idx) => {
                      return (
                        535 <= Number(e.feature_id) &&
                        Number(e.feature_id) <= 535
                      );
                    });
                  const coal_price_index = res.user_feature_mapping.filter(
                    (e, idx) => {
                      return (
                        549 <= Number(e.feature_id) &&
                        Number(e.feature_id) <= 549
                      );
                    }
                  );
                  const outgoing_approvals = res.user_feature_mapping.filter(
                    (e, idx) => {
                      return (
                        554 <= Number(e.feature_id) &&
                        Number(e.feature_id) <= 554
                      );
                    }
                  );

                  this.setState({
                    // [e.target.name]: e.target.value,
                    vendorAdvanceChecked: vendorAdvanceChecked.map(
                      (e, idx) => e.feature_id
                    ),
                    vendorAdvancePaymentChecked:
                      vendorAdvancePaymentChecked.map((e, idx) => e.feature_id),
                    purchaseContractChecked: purchaseContractChecked.map(
                      (e, idx) => e.feature_id
                    ),
                    cps_contract: cps_contract.map((e, idx) => e.feature_id),
                    purchaseContractAdvPaymentChecked:
                      purchaseContractAdvPaymentChecked.map(
                        (e, idx) => e.feature_id
                      ),
                    salesContractChecked: salesContractChecked.map(
                      (e, idx) => e.feature_id
                    ),
                    salesContractAdvPaymentChecked:
                      salesContractAdvPaymentChecked.map(
                        (e, idx) => e.feature_id
                      ),
                    stevedoreContractChecked: stevedoreContractChecked.map(
                      (e, idx) => e.feature_id
                    ),
                    floatingCranetChecked: floatingCranetChecked.map(
                      (e, idx) => e.feature_id
                    ),
                    businessNoChecked: businessNoChecked.map(
                      (e, idx) => e.feature_id
                    ),
                    BusinessNoOpMVChecked: BusinessNoOpMVChecked.map(
                      (e, idx) => e.feature_id
                    ),
                    BusinessNoOpBargeChecked: BusinessNoOpBargeChecked.map(
                      (e, idx) => e.feature_id
                    ),
                    barge_agreements: barge_agreements.map(
                      (e, idx) => e.feature_id
                    ),
                    MVDemurrageChecked: MVDemurrageChecked.map(
                      (e, idx) => e.feature_id
                    ),
                    MVDemurragePaymentChecked: MVDemurragePaymentChecked.map(
                      (e, idx) => e.feature_id
                    ),
                    bargeDemurrageChecked: bargeDemurrageChecked.map(
                      (e, idx) => e.feature_id
                    ),
                    qualityResultsChecked: qualityResultsChecked.map(
                      (e, idx) => e.feature_id
                    ),
                    MVPurchaseFinancialChecked: MVPurchaseFinancialChecked.map(
                      (e, idx) => e.feature_id
                    ),
                    MVPurchaseFinancialPaymentsChecked:
                      MVPurchaseFinancialPaymentsChecked.map(
                        (e, idx) => e.feature_id
                      ),
                    bargePurchaseChecked: bargePurchaseChecked.map(
                      (e, idx) => e.feature_id
                    ),
                    bargePurchaseFinancialChecked:
                      bargePurchaseFinancialChecked.map(
                        (e, idx) => e.feature_id
                      ),
                    MVSalesFinancialChecked: MVSalesFinancialChecked.map(
                      (e, idx) => e.feature_id
                    ),
                    MVsalesFinancialPaymetsChecked:
                      MVsalesFinancialPaymetsChecked.map(
                        (e, idx) => e.feature_id
                      ),
                    bargeSalesFinancialChecked: bargeSalesFinancialChecked.map(
                      (e, idx) => e.feature_id
                    ),
                    bargesSalesFinancialPaymentsChecked:
                      bargesSalesFinancialPaymentsChecked.map(
                        (e, idx) => e.feature_id
                      ),
                    credAndDebNotesChecked: credAndDebNotesChecked.map(
                      (e, idx) => e.feature_id
                    ),
                    credAndDebNotesPaymentsChecked:
                      credAndDebNotesPaymentsChecked.map(
                        (e, idx) => e.feature_id
                      ),
                    businessNoShipmentChecklistChecked:
                      businessNoShipmentChecklistChecked.map(
                        (e, idx) => e.feature_id
                      ),
                    minesChecked: minesChecked.map((e, idx) => e.feature_id),
                    budget_and_costing: budget_and_costing.map((e, idx) => e.feature_id),
                    userChecked: userChecked.map((e, idx) => e.feature_id),
                    CoalProcurementChargesChecked:
                      CoalProcurementChargesChecked.map(
                        (e, idx) => e.feature_id
                      ),
                    View_Vendor_Contact_and_KYC:
                      View_Vendor_Contact_and_KYC.map((e, idx) => e.feature_id),
                    View_Customer_Contact_and_KYC:
                      View_Customer_Contact_and_KYC.map(
                        (e, idx) => e.feature_id
                      ),
                    barge_pur_coal_costing: barge_pur_coal_costing.map(
                      (e, idx) => e.feature_id
                    ),
                    barge_pur_coal_costing_payments:
                      barge_pur_coal_costing_payments.map(
                        (e, idx) => e.feature_id
                      ),
                    barge_pur_barge_costing: barge_pur_barge_costing.map(
                      (e, idx) => e.feature_id
                    ),
                    barge_pur_barge_costing_payments:
                      barge_pur_barge_costing_payments.map(
                        (e, idx) => e.feature_id
                      ),
                    chemical_spray: chemical_spray.map((e) => e.feature_id),
                    chemical_spray_payments: chemical_spray_payments.map(
                      (e) => e.feature_id
                    ),
                    barge_pur_cpc: barge_pur_cpc.map((e, idx) => e.feature_id),
                    barge_pur_cpc_payments: barge_pur_cpc_payments.map(
                      (e, idx) => e.feature_id
                    ),
                    barge_pur_cps: barge_pur_cps.map((e, idx) => e.feature_id),
                    barge_pur_cps_payments: barge_pur_cps_payments.map(
                      (e, idx) => e.feature_id
                    ),
                    barge_pur_demurrage_cost: barge_pur_demurrage_cost.map(
                      (e, idx) => e.feature_id
                    ),
                    barge_pur_demurrage_cost_payments:
                      barge_pur_demurrage_cost_payments.map(
                        (e, idx) => e.feature_id
                      ),
                    barge_pur_sac: barge_pur_sac.map((e, idx) => e.feature_id),
                    barge_pur_sac_payments: barge_pur_sac_payments.map(
                      (e, idx) => e.feature_id
                    ),
                    barge_pur_pre_shipmet_inspection:
                      barge_pur_pre_shipmet_inspection.map(
                        (e, idx) => e.feature_id
                      ),
                    barge_pur_pre_shipmet_inspection_payments:
                      barge_pur_pre_shipmet_inspection_payments.map(
                        (e, idx) => e.feature_id
                      ),
                    barge_pur_export_tax: barge_pur_export_tax.map(
                      (e, idx) => e.feature_id
                    ),
                    barge_pur_export_tax_payments:
                      barge_pur_export_tax_payments.map(
                        (e, idx) => e.feature_id
                      ),
                    barge_pur_doc: barge_pur_doc.map((e, idx) => e.feature_id),
                    barge_laprn_charges: barge_laprn_charges.map(
                      (e, idx) => e.feature_id
                    ),
                    barge_pur_other_expense: barge_pur_other_expense.map(
                      (e, idx) => e.feature_id
                    ),
                    barge_pur_doc_payments: barge_pur_doc_payments.map(
                      (e, idx) => e.feature_id
                    ),
                    barge_laprn_payments: barge_laprn_payments.map(
                      (e, idx) => e.feature_id
                    ),
                    barge_pur_other_expense_payments:
                      barge_pur_other_expense_payments.map(
                        (e, idx) => e.feature_id
                      ),

                    // barge sales fin
                    barge_sales_coal_costing: barge_sales_coal_costing.map(
                      (e, idx) => e.feature_id
                    ),
                    barge_sales_coal_costing_invoices:
                      barge_sales_coal_costing_invoices.map(
                        (e, idx) => e.feature_id
                      ),
                    barge_sales_coal_costing_payments:
                      barge_sales_coal_costing_payments.map(
                        (e, idx) => e.feature_id
                      ),
                    barge_sales_coal_sales_charges:
                      barge_sales_coal_sales_charges.map(
                        (e, idx) => e.feature_id
                      ),
                    barge_sales_coal_sales_charges_invoices:
                      barge_sales_coal_sales_charges_invoices.map(
                        (e, idx) => e.feature_id
                      ),
                    barge_sales_coal_sales_charges_payments:
                      barge_sales_coal_sales_charges_payments.map(
                        (e, idx) => e.feature_id
                      ),
                    barge_sales_demurrage: barge_sales_demurrage.map(
                      (e, idx) => e.feature_id
                    ),
                    barge_sales_demurrage_invoices:
                      barge_sales_demurrage_invoices.map(
                        (e, idx) => e.feature_id
                      ),
                    barge_sales_demurrage_payments:
                      barge_sales_demurrage_payments.map(
                        (e, idx) => e.feature_id
                      ),

                    // Mother vessel purchase fin
                    mv_pur_fin_stevedore_costing:
                      mv_pur_fin_stevedore_costing.map(
                        (e, idx) => e.feature_id
                      ),
                    pnbp_charges: pnbp_charges.map((e, idx) => e.feature_id),
                    pnbp_payments: pnbp_payments.map((e, idx) => e.feature_id),
                    mv_pur_fin_stevedore_costing_payments:
                      mv_pur_fin_stevedore_costing_payments.map(
                        (e, idx) => e.feature_id
                      ),
                    mv_pur_fin_freight_costing: mv_pur_fin_freight_costing.map(
                      (e, idx) => e.feature_id
                    ),
                    mv_pur_fin_freight_costing_payments:
                      mv_pur_fin_freight_costing_payments.map(
                        (e, idx) => e.feature_id
                      ),
                    mv_pur_fin_cpc: mv_pur_fin_cpc.map(
                      (e, idx) => e.feature_id
                    ),
                    barge_return_cargo: barge_return_cargo.map(
                      (e, idx) => e.feature_id
                    ),
                    barge_return_cargo_payments:
                      barge_return_cargo_payments.map((e, idx) => e.feature_id),
                    mv_pur_fin_cpc_payments: mv_pur_fin_cpc_payments.map(
                      (e, idx) => e.feature_id
                    ),
                    mv_pur_fin_sac: mv_pur_fin_sac.map(
                      (e, idx) => e.feature_id
                    ),
                    mv_pur_fin_sac_payments: mv_pur_fin_sac_payments.map(
                      (e, idx) => e.feature_id
                    ),
                    mv_pur_fin_pre_shipment_inspection:
                      mv_pur_fin_pre_shipment_inspection.map(
                        (e, idx) => e.feature_id
                      ),
                    mv_pur_fin_pre_shipment_inspection_payments:
                      mv_pur_fin_pre_shipment_inspection_payments.map(
                        (e, idx) => e.feature_id
                      ),
                    mv_pur_fin_floating_crane: mv_pur_fin_floating_crane.map(
                      (e, idx) => e.feature_id
                    ),
                    mv_pur_fin_floating_crane_payments:
                      mv_pur_fin_floating_crane_payments.map(
                        (e, idx) => e.feature_id
                      ),
                    mv_pur_fin_export_tax: mv_pur_fin_export_tax.map(
                      (e, idx) => e.feature_id
                    ),
                    mv_pur_fin_export_tax_payments:
                      mv_pur_fin_export_tax_payments.map(
                        (e, idx) => e.feature_id
                      ),
                    mv_pur_fin_doc: mv_pur_fin_doc.map(
                      (e, idx) => e.feature_id
                    ),
                    mv_laprn_charges: mv_laprn_charges.map(
                      (e, idx) => e.feature_id
                    ),
                    mv_pur_fin_doc_payments: mv_pur_fin_doc_payments.map(
                      (e, idx) => e.feature_id
                    ),
                    mv_laprn_payments: mv_laprn_payments.map(
                      (e, idx) => e.feature_id
                    ),
                    mv_dead_freight: mv_dead_freight.map(
                      (e, idx) => e.feature_id
                    ),
                    mv_dead_freight_payments: mv_dead_freight_payments.map(
                      (e, idx) => e.feature_id
                    ),
                    vessel_insurance: vessel_insurance.map(
                      (e, idx) => e.feature_id
                    ),
                    vessel_insurance_payments: vessel_insurance_payments.map(
                      (e, idx) => e.feature_id
                    ),

                    // Mother vessel sales financial
                    mv_sales_fin_coal_sales_charges:
                      mv_sales_fin_coal_sales_charges.map(
                        (e, idx) => e.feature_id
                      ),
                    mv_sales_fin_coal_sales_charges_invoices:
                      mv_sales_fin_coal_sales_charges_invoices.map(
                        (e, idx) => e.feature_id
                      ),
                    mv_sales_fin_coal_sales_charges_payments:
                      mv_sales_fin_coal_sales_charges_payments.map(
                        (e, idx) => e.feature_id
                      ),
                    other_expenses: other_expenses.map(
                      (e, idx) => e.feature_id
                    ),
                    other_expenses_payments: other_expenses_payments.map(
                      (e, idx) => e.feature_id
                    ),
                    barge_insurance: barge_insurance.map(
                      (e, idx) => e.feature_id
                    ),
                    barge_insurance_payments: barge_insurance_payments.map(
                      (e, idx) => e.feature_id
                    ),
                    other_income: other_income.map((e, idx) => e.feature_id),
                    other_income_payments: other_income_payments.map(
                      (e, idx) => e.feature_id
                    ),
                    expenditureChecked: expenditure.map(
                      (e, idx) => e.feature_id
                    ),
                    business_profit_and_loss: business_profit_and_loss.map(
                      (e, idx) => e.feature_id
                    ),
                    profit_and_loss_coal_vendor:
                      profit_and_loss_coal_vendor.map((e, idx) => e.feature_id),
                    vat_input_summary: vat_input_summary.map(
                      (e, idx) => e.feature_id
                    ),
                    pc_available_qunatity_report:
                      pc_available_qunatity_report.map(
                        (e, idx) => e.feature_id
                      ),
                    coal_price_index: coal_price_index.map(
                      (e, idx) => e.feature_id
                    ),
                    outgoing_approvals: outgoing_approvals.map(
                      (e, idx) => e.feature_id
                    ),
                    services: services.map((e, idx) => e.feature_id),
                    services_payments: services_payments.map(
                      (e, idx) => e.feature_id
                    ),
                    fixtureNoteChecked: fixture_notes.map(
                      (e, idx) => e.feature_id
                    ),
                    vessel_completion_report: vessel_report.map(
                      (e, idx) => e.feature_id
                    ),
                    isLoading: false,
                    drawerLoading: false,
                    openDrawer: true,
                  });
                } else {
                  alert();
                }
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  isLoading: false,
                  snackBarErrorOpen: true,
                  drawerLoading: false,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    } else {
      this.setState({
        vendorAdvanceChecked: [],
        View_Vendor_Contact_and_KYC: [],
        View_Customer_Contact_and_KYC: [],
        vendorAdvancePaymentChecked: [],
        purchaseContractChecked: [],
        cps_contract: [],
        purchaseContractAdvPaymentChecked: [],
        salesContractChecked: [],
        salesContractAdvPaymentChecked: [],
        stevedoreContractChecked: [],
        floatingCranetChecked: [],
        businessNoChecked: [],
        BusinessNoOpMVChecked: [],
        BusinessNoOpBargeChecked: [],
        barge_agreements: [],
        View_Price_in_Barge_Checked: [],
        MVDemurrageChecked: [],
        MVDemurragePaymentChecked: [],
        bargeDemurrageChecked: [],
        qualityResultsChecked: [],
        MVPurchaseFinancialChecked: [],
        MVPurchaseFinancialPaymentsChecked: [],
        bargePurchaseChecked: [],
        bargePurchaseFinancialChecked: [],
        MVSalesFinancialChecked: [],
        MVsalesFinancialPaymetsChecked: [],
        bargeSalesFinancialChecked: [],
        bargesSalesFinancialPaymentsChecked: [],
        credAndDebNotesChecked: [],
        credAndDebNotesPaymentsChecked: [],
        businessNoShipmentChecklistChecked: [],
        minesChecked: [],
        budget_and_costing: [],
        userChecked: [],
        CoalProcurementChargesChecked: [],
        //barge pur fin
        barge_pur_coal_costing: [],
        barge_pur_coal_costing_payments: [],
        barge_pur_barge_costing: [],
        barge_pur_barge_costing_payments: [],
        chemical_spray: [],
        chemical_spray_payments: [],
        barge_pur_cpc: [],
        barge_pur_cpc_payments: [],
        barge_pur_cps: [],
        barge_pur_cps_payments: [],
        barge_pur_demurrage_cost: [],
        barge_pur_demurrage_cost_payments: [],
        barge_pur_sac: [],
        barge_pur_sac_payments: [],
        barge_pur_pre_shipmet_inspection: [],
        barge_pur_pre_shipmet_inspection_payments: [],
        barge_pur_export_tax: [],
        barge_pur_export_tax_payments: [],
        barge_pur_doc: [],
        barge_laprn_charges: [],
        barge_pur_doc_payments: [],
        barge_laprn_payments: [],
        barge_pur_other_expense: [],
        barge_pur_other_expense_payments: [],

        // barge sales fin
        barge_sales_coal_costing: [],
        barge_sales_coal_costing_invoices: [],
        barge_sales_coal_costing_payments: [],
        barge_sales_coal_sales_charges: [],
        barge_sales_coal_sales_charges_invoices: [],
        barge_sales_coal_sales_charges_payments: [],
        barge_sales_demurrage: [],
        barge_sales_demurrage_invoices: [],
        barge_sales_demurrage_payments: [],

        // Mother vessel purchase fin
        mv_pur_fin_stevedore_costing: [],
        pnbp_charges: [],
        pnbp_payments: [],
        mv_pur_fin_stevedore_costing_payments: [],
        mv_pur_fin_freight_costing: [],
        mv_pur_fin_freight_costing_payments: [],
        mv_pur_fin_cpc: [],
        barge_return_cargo: [],
        barge_return_cargo_payments: [],
        mv_pur_fin_cpc_payments: [],
        mv_pur_fin_sac: [],
        mv_pur_fin_sac_payments: [],
        mv_pur_fin_pre_shipment_inspection: [],
        mv_pur_fin_pre_shipment_inspection_payments: [],
        mv_pur_fin_floating_crane: [],
        mv_pur_fin_floating_crane_payments: [],
        mv_pur_fin_export_tax: [],
        mv_pur_fin_export_tax_payments: [],
        mv_pur_fin_doc: [],
        mv_laprn_charges: [],
        mv_pur_fin_doc_payments: [],
        mv_laprn_payments: [],
        mv_dead_freight: [],
        mv_dead_freight_payments: [],
        vessel_insurance: [],
        vessel_insurance_payments: [],

        // Mother vessel sales financial
        mv_sales_fin_coal_sales_charges: [],
        mv_sales_fin_coal_sales_charges_invoices: [],
        mv_sales_fin_coal_sales_charges_payments: [],

        other_expenses: [],
        other_expenses_payments: [],
        barge_insurance: [],
        barge_insurance_payments: [],
        other_income: [],
        other_income_payments: [],
        expenditureChecked: [],
        business_profit_and_loss: [],
        profit_and_loss_coal_vendor: [],
        services: [],
        services_payments: [],
        fixtureNoteChecked: [],
        vessel_completion_report: [],
        vat_input_summary: [],
        pc_available_qunatity_report: [],
        coal_price_index: [],
        outgoing_approvals: [],
        userIdError: false,
        isLoading: false,
      });
    }
  };

  submitHandler = () => {
    this.setState({
      isLoading: true,
    });
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    var userID = this.state.user_id;
    var feature_ids = [
      ...this.state.vendorAdvanceChecked,
      ...this.state.View_Vendor_Contact_and_KYC,
      ...this.state.View_Customer_Contact_and_KYC,
      ...this.state.vendorAdvancePaymentChecked,
      ...this.state.purchaseContractChecked,
      ...this.state.cps_contract,
      ...this.state.purchaseContractAdvPaymentChecked,
      ...this.state.salesContractChecked,
      ...this.state.salesContractAdvPaymentChecked,
      ...this.state.stevedoreContractChecked,
      ...this.state.floatingCranetChecked,
      ...this.state.businessNoChecked,
      ...this.state.View_Price_in_Barge_Checked,
      ...this.state.BusinessNoOpMVChecked,
      ...this.state.BusinessNoOpBargeChecked,
      ...this.state.barge_agreements,
      ...this.state.MVDemurrageChecked,
      ...this.state.MVDemurragePaymentChecked,
      ...this.state.bargeDemurrageChecked,
      ...this.state.qualityResultsChecked,
      ...this.state.MVPurchaseFinancialChecked,
      ...this.state.MVPurchaseFinancialPaymentsChecked,
      ...this.state.bargePurchaseChecked,
      ...this.state.bargePurchaseFinancialChecked,
      ...this.state.MVSalesFinancialChecked,
      ...this.state.MVsalesFinancialPaymetsChecked,
      ...this.state.bargeSalesFinancialChecked,
      ...this.state.bargesSalesFinancialPaymentsChecked,
      ...this.state.credAndDebNotesChecked,
      ...this.state.credAndDebNotesPaymentsChecked,
      ...this.state.businessNoShipmentChecklistChecked,
      ...this.state.minesChecked,
      ...this.state.budget_and_costing,
      ...this.state.userChecked,
      ...this.state.CoalProcurementChargesChecked,
      ...this.state.barge_sales_coal_sales_charges,
      //barge pur fin
      ...this.state.barge_pur_coal_costing,
      ...this.state.barge_pur_coal_costing_payments,
      ...this.state.barge_pur_barge_costing,
      ...this.state.barge_pur_barge_costing_payments,
      ...this.state.chemical_spray,
      ...this.state.chemical_spray_payments,
      ...this.state.barge_pur_cpc,
      ...this.state.barge_pur_cpc_payments,
      ...this.state.barge_pur_cps,
      ...this.state.barge_pur_cps_payments,
      ...this.state.barge_pur_demurrage_cost,
      ...this.state.barge_pur_demurrage_cost_payments,
      ...this.state.barge_pur_sac,
      ...this.state.barge_pur_sac_payments,
      ...this.state.barge_pur_pre_shipmet_inspection,
      ...this.state.barge_pur_pre_shipmet_inspection_payments,
      ...this.state.barge_pur_export_tax,
      ...this.state.barge_pur_export_tax_payments,
      ...this.state.barge_pur_doc,
      ...this.state.barge_laprn_charges,
      ...this.state.barge_pur_doc_payments,
      ...this.state.barge_laprn_payments,
      ...this.state.barge_pur_other_expense,
      ...this.state.barge_pur_other_expense_payments,

      // barge sales fin
      ...this.state.barge_sales_coal_costing,
      ...this.state.barge_sales_coal_costing_invoices,
      ...this.state.barge_sales_coal_costing_payments,
      ...this.state.barge_sales_coal_sales_charges,
      ...this.state.barge_sales_coal_sales_charges_invoices,
      ...this.state.barge_sales_coal_sales_charges_payments,
      ...this.state.barge_sales_demurrage,
      ...this.state.barge_sales_demurrage_invoices,
      ...this.state.barge_sales_demurrage_payments,

      // Mother vessel purchase fin
      ...this.state.mv_pur_fin_stevedore_costing,
      ...this.state.pnbp_charges,
      ...this.state.pnbp_payments,
      ...this.state.mv_pur_fin_stevedore_costing_payments,
      ...this.state.mv_pur_fin_freight_costing,
      ...this.state.mv_pur_fin_freight_costing_payments,
      ...this.state.mv_pur_fin_cpc,
      ...this.state.mv_pur_fin_cpc_payments,
      ...this.state.barge_return_cargo,
      ...this.state.barge_return_cargo_payments,
      ...this.state.mv_pur_fin_sac,
      ...this.state.mv_pur_fin_sac_payments,
      ...this.state.mv_pur_fin_pre_shipment_inspection,
      ...this.state.mv_pur_fin_pre_shipment_inspection_payments,
      ...this.state.mv_pur_fin_floating_crane,
      ...this.state.mv_pur_fin_floating_crane_payments,
      ...this.state.mv_pur_fin_export_tax,
      ...this.state.mv_pur_fin_export_tax_payments,
      ...this.state.mv_pur_fin_doc,
      ...this.state.mv_laprn_charges,
      ...this.state.mv_pur_fin_doc_payments,
      ...this.state.mv_laprn_payments,
      ...this.state.mv_dead_freight,
      ...this.state.mv_dead_freight_payments,
      ...this.state.vessel_insurance,
      ...this.state.vessel_insurance_payments,

      // Mother vessel sales financial
      ...this.state.mv_sales_fin_coal_sales_charges,
      ...this.state.mv_sales_fin_coal_sales_charges_invoices,
      ...this.state.mv_sales_fin_coal_sales_charges_payments,

      ...this.state.other_expenses,
      ...this.state.other_expenses_payments,
      ...this.state.barge_insurance,
      ...this.state.barge_insurance_payments,
      ...this.state.other_income,
      ...this.state.other_income_payments,

      // expenditure
      ...this.state.expenditureChecked,
      ...this.state.business_profit_and_loss,
      ...this.state.profit_and_loss_coal_vendor,
      ...this.state.vat_input_summary,
      ...this.state.pc_available_qunatity_report,
      ...this.state.coal_price_index,
      ...this.state.outgoing_approvals,
      // Services
      ...this.state.services,
      ...this.state.services_payments,
      ...this.state.fixtureNoteChecked,
      ...this.state.vessel_completion_report,
    ];

    if (userID) {
      api
        .addUserFeatureMaping(loginUserID, idToken, userID, feature_ids)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                // alert('User access management updated successfully')
                this.setState(
                  {
                    vendorAdvanceChecked: [],
                    View_Vendor_Contact_and_KYC: [],
                    View_Customer_Contact_and_KYC: [],
                    vendorAdvancePaymentChecked: [],
                    purchaseContractChecked: [],
                    cps_contract: [],
                    purchaseContractAdvPaymentChecked: [],
                    salesContractChecked: [],
                    salesContractAdvPaymentChecked: [],
                    stevedoreContractChecked: [],
                    floatingCranetChecked: [],
                    businessNoChecked: [],
                    BusinessNoOpMVChecked: [],
                    BusinessNoOpBargeChecked: [],
                    barge_agreements: [],
                    View_Price_in_Barge_Checked: [],
                    MVDemurrageChecked: [],
                    MVDemurragePaymentChecked: [],
                    bargeDemurrageChecked: [],
                    qualityResultsChecked: [],
                    MVPurchaseFinancialChecked: [],
                    MVPurchaseFinancialPaymentsChecked: [],
                    bargePurchaseChecked: [],
                    bargePurchaseFinancialChecked: [],
                    MVSalesFinancialChecked: [],
                    MVsalesFinancialPaymetsChecked: [],
                    bargeSalesFinancialChecked: [],
                    bargesSalesFinancialPaymentsChecked: [],
                    credAndDebNotesChecked: [],
                    credAndDebNotesPaymentsChecked: [],
                    businessNoShipmentChecklistChecked: [],
                    minesChecked: [],
                    budget_and_costing: [],
                    userChecked: [],
                    CoalProcurementChargesChecked: [],
                    //barge pur fin
                    barge_pur_coal_costing: [],
                    barge_pur_coal_costing_payments: [],
                    barge_pur_barge_costing: [],
                    barge_pur_barge_costing_payments: [],
                    chemical_spray: [],
                    chemical_spray_payments: [],
                    barge_pur_cpc: [],
                    barge_pur_cpc_payments: [],
                    barge_pur_cps: [],
                    barge_pur_cps_payments: [],
                    barge_pur_demurrage_cost: [],
                    barge_pur_demurrage_cost_payments: [],
                    barge_pur_sac: [],
                    barge_pur_sac_payments: [],
                    barge_pur_pre_shipmet_inspection: [],
                    barge_pur_pre_shipmet_inspection_payments: [],
                    barge_pur_export_tax: [],
                    barge_pur_export_tax_payments: [],
                    barge_pur_doc: [],
                    barge_laprn_charges: [],
                    barge_pur_doc_payments: [],
                    barge_laprn_payments: [],
                    barge_pur_other_expense: [],
                    barge_pur_other_expense_payments: [],

                    // barge sales fin
                    barge_sales_coal_costing: [],
                    barge_sales_coal_costing_invoices: [],
                    barge_sales_coal_costing_payments: [],
                    barge_sales_coal_sales_charges: [],
                    barge_sales_coal_sales_charges_invoices: [],
                    barge_sales_coal_sales_charges_payments: [],
                    barge_sales_demurrage: [],
                    barge_sales_demurrage_invoices: [],
                    barge_sales_demurrage_payments: [],

                    // Mother vessel purchase fin
                    mv_pur_fin_stevedore_costing: [],
                    pnbp_charges: [],
                    pnbp_payments: [],
                    mv_pur_fin_stevedore_costing_payments: [],
                    mv_pur_fin_freight_costing: [],
                    mv_pur_fin_freight_costing_payments: [],
                    mv_pur_fin_cpc: [],
                    mv_pur_fin_cpc_payments: [],
                    barge_return_cargo: [],
                    barge_return_cargo_payments: [],
                    mv_pur_fin_sac: [],
                    mv_pur_fin_sac_payments: [],
                    mv_pur_fin_pre_shipment_inspection: [],
                    mv_pur_fin_pre_shipment_inspection_payments: [],
                    mv_pur_fin_floating_crane: [],
                    mv_pur_fin_floating_crane_payments: [],
                    mv_pur_fin_export_tax: [],
                    mv_pur_fin_export_tax_payments: [],
                    mv_pur_fin_doc: [],
                    mv_laprn_charges: [],
                    mv_pur_fin_doc_payments: [],
                    mv_laprn_payments: [],
                    mv_dead_freight: [],
                    mv_dead_freight_payments: [],
                    vessel_insurance: [],
                    vessel_insurance_payments: [],

                    // Mother vessel sales financial
                    mv_sales_fin_coal_sales_charges: [],
                    mv_sales_fin_coal_sales_charges_invoices: [],
                    mv_sales_fin_coal_sales_charges_payments: [],

                    other_expenses: [],
                    other_expenses_payments: [],
                    barge_insurance: [],
                    barge_insurance_payments: [],
                    other_income: [],
                    other_income_payments: [],
                    // expenditure
                    expenditureChecked: [],
                    business_profit_and_loss: [],
                    profit_and_loss_coal_vendor: [],
                    vat_input_summary: [],
                    pc_available_qunatity_report: [],
                    coal_price_index: [],
                    outgoing_approvals: [],
                    services: [],
                    services_payments: [],
                    fixtureNoteChecked: [],
                    vessel_completion_report: [],
                    userIdError: false,
                    isLoading: false,
                    snackBarSuccessOpen: true,
                    successMsg: "User access management updated successfully",
                    user_id: userID,
                  },
                  () => this.getUserFeatures(userID)
                );
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  snackBarErrorOpen: true,
                  isLoading: false,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              } else if (res.code === "624") {
                this.setState({
                  errorMsg: res.columns[0],
                  snackBarErrorOpen: true,
                  isLoading: false,
                });
              }
            });
          }
        });
    } else {
      alert("Please select user");
    }
  };

  getModules = (data) => {
    var newData = "";
    if (data) {
      newData = data.split(", ").join(" | ");
    }
    return newData;
  };

  addUserHandler = (e) => {
    e.preventDefault();
    var mailformat =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    let { email, password, confirm_password, full_name } = this.state;
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    let user_name = "";
    if (mailformat.test(email)) {
      user_name = email.slice(0, email.includes("@"));
    } else {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please enter valid email",
      });
      return;
    }

    if (!full_name) {
      this.setState({
        passwordError: true,
      });
      return;
    }
    if (!password) {
      this.setState({
        passwordError: true,
      });
      return;
    }

    if (password !== confirm_password) {
      this.setState({
        confirm_password_Error: true,
      });
      return;
    }

    api
      .add_user(loginUserID, idToken, user_name, full_name, email, password)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.id) {
              this.setState({
                snackBarSuccessOpen: true,
                successMsg: res.message,
              });
              window.$("#bargePurchasePaymentPostingModal").modal("hide");
            }
            if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            } else if (res.code === "608") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            }
          });
        }
      });
  };

  render() {
    return (
      <div id="wraper">
        <SideBar />
        <div className="content">
          <Header />
          {!!this.state.isLoading && <Loader />}
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          {!this.state.isLoading && (
            <div>
              <div className="content-header">
                <div className="row">
                  <div className="col-sm text-left pl-0">
                    <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                      User Access Management
                    </h4>
                  </div>
                  <div className="col text-right float-sm-left">
                    {/* <a href="/add-user"><button className='header_button header_button_text add_button_adjustment' style={{ top: 10, width: 'auto' }}>Add User</button></a> */}
                    <button
                      className="header_button header_button_text add_button_adjustment"
                      style={{ top: 10, width: "auto" }}
                      data-toggle="modal"
                      data-keyboard="false"
                      data-target="#bargePurchasePaymentPostingModal"
                      data-backdrop="static"
                    >
                      Add User
                    </button>
                  </div>
                </div>
              </div>
              <section className="container p-3">
                <div className="col card p-0">
                  {this.state.usersData.map((e, user) => (
                    <div
                      className="rounded p-2"
                      key={e.user_id}
                      onClick={() => {
                        this.setState({ user_id: e.user_id });
                        this.getUserFeatures(e.user_id);
                      }}
                      style={{
                        border: "1px solid #dee2e6",
                        cursor: "pointer",
                        margin: "12px 12px 0px 12px",
                      }}
                    >
                      <div className="col-lg-12 p-0">
                        <label
                          className="access_title mb-0"
                          style={{ color: config.themeColor }}
                        >
                          {e.user_name}
                        </label>
                        <label className="access_sub_title mb-0">
                          {this.getModules(e.module_name)}
                        </label>
                      </div>
                    </div>
                  ))}

                  <Drawer
                    anchor="right"
                    open={this.state.openDrawer}
                    variant="temporary"
                    elevation={20}
                    style={{ overflow: "initial" }}
                  >
                    <div
                      className="container bg-white p-0"
                      style={{ minHeight: "100vh", width: 850 }}
                    >
                      <div className="row clearfix content-header border-bottom">
                        <div className="col-lg-10 pl-0">
                          <h4 style={{ padding: "10px 20px" }}>
                            Access Mangement
                          </h4>
                        </div>
                        <Typography component="div">
                          <Grid
                            component="label"
                            container
                            alignItems="center"
                            spacing={1}
                          >
                            <Grid
                              item
                              style={{
                                position: "relative",
                                top: "3px",
                                width: "50px",
                              }}
                            >
                              <FormControlLabel
                                control={
                                  <NewSwitch
                                    checked={this.state.userChecked.includes(
                                      "97"
                                    )}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        this.setState({
                                          userChecked: [
                                            ...this.state.userChecked,
                                            "97",
                                          ],
                                        });
                                      } else {
                                        var data = this.state.userChecked;
                                        if (data.includes("97")) {
                                          let index = data.includes("97");
                                          data.splice(index, 1);
                                        }
                                        this.setState({
                                          userChecked: data,
                                        });
                                      }
                                    }}
                                    name="admin"
                                  />
                                }
                              />
                            </Grid>
                            <Grid item>Admin</Grid>
                          </Grid>
                        </Typography>
                      </div>
                      {this.state.drawerLoading ? (
                        <div
                          className="loading"
                          style={{
                            overflowY: "scroll",
                            minHeight: "85vh",
                            position: "initial",
                          }}
                        >
                          <div
                            className="loader"
                            style={{ margin: "auto" }}
                          ></div>
                        </div>
                      ) : (
                        <div className="drawer-section">
                          <div className="card p-1">
                            <div
                              className="card-body p-0"
                              style={{
                                backgroundColor: "rgba(25,118,210,.01)",
                              }}
                              id="accordion"
                            >
                              <div
                                className="col-lg-12 p-1"
                                onClick={() =>
                                  this.setState({ mainSectionActiveIndex: 1 })
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <div
                                  className="row col-lg-12 rounded p-2"
                                  onClick={() =>
                                    this.setState({ mainSectionActiveIndex: 1 })
                                  }
                                  style={{
                                    border:
                                      this.state.mainSectionActiveIndex === 1
                                        ? "1px solid " + config.themeColor
                                        : "1px solid #dee2e6",
                                  }}
                                >
                                  <div className="col-lg-12 p-0">
                                    <div className="col-lg-12 p-0">
                                      <label className="access_title mb-0">
                                        Business Partner
                                      </label>
                                      {this.state.mainSectionActiveIndex ===
                                      1 ? (
                                        ""
                                      ) : (
                                        <label className="access_sub_title mb-0">
                                          Vendor | Customer
                                        </label>
                                      )}
                                    </div>
                                  </div>
                                  {this.state.mainSectionActiveIndex === 1 && (
                                    <div className="row col-lg-12 p-0 mb-0 mt-2">
                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Vendor
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="124,324"
                                                color="primary"
                                                checked={
                                                  this.state.View_Vendor_Contact_and_KYC.includes(
                                                    "124"
                                                  ) &&
                                                  this.state.View_Vendor_Contact_and_KYC.includes(
                                                    "324"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      View_Vendor_Contact_and_KYC:
                                                        ["124", "324"],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      View_Vendor_Contact_and_KYC:
                                                        [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="124"
                                                color="primary"
                                                checked={this.state.View_Vendor_Contact_and_KYC.includes(
                                                  "124"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      View_Vendor_Contact_and_KYC:
                                                        [
                                                          ...this.state
                                                            .View_Vendor_Contact_and_KYC,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .View_Vendor_Contact_and_KYC;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      View_Vendor_Contact_and_KYC:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                KYC
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="324"
                                                color="primary"
                                                checked={this.state.View_Vendor_Contact_and_KYC.includes(
                                                  "324"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      View_Vendor_Contact_and_KYC:
                                                        [
                                                          ...this.state
                                                            .View_Vendor_Contact_and_KYC,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .View_Vendor_Contact_and_KYC;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      View_Vendor_Contact_and_KYC:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Files
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>
                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Customer
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="125,325"
                                                color="primary"
                                                checked={
                                                  this.state.View_Customer_Contact_and_KYC.includes(
                                                    "125"
                                                  ) &&
                                                  this.state.View_Customer_Contact_and_KYC.includes(
                                                    "325"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      View_Customer_Contact_and_KYC:
                                                        ["125", "325"],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      View_Customer_Contact_and_KYC:
                                                        [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="125"
                                                color="primary"
                                                checked={this.state.View_Customer_Contact_and_KYC.includes(
                                                  "125"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      View_Customer_Contact_and_KYC:
                                                        [
                                                          ...this.state
                                                            .View_Customer_Contact_and_KYC,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .View_Customer_Contact_and_KYC;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      View_Customer_Contact_and_KYC:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                KYC
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="325"
                                                color="primary"
                                                checked={this.state.View_Customer_Contact_and_KYC.includes(
                                                  "325"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      View_Customer_Contact_and_KYC:
                                                        [
                                                          ...this.state
                                                            .View_Customer_Contact_and_KYC,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .View_Customer_Contact_and_KYC;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      View_Customer_Contact_and_KYC:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Files
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div
                                className="col-lg-12 p-1"
                                onClick={() =>
                                  this.setState({ mainSectionActiveIndex: 2 })
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <div
                                  className="row col-lg-12 rounded p-2"
                                  style={{
                                    border:
                                      this.state.mainSectionActiveIndex === 2
                                        ? "1px solid " + config.themeColor
                                        : "1px solid #dee2e6",
                                  }}
                                >
                                  <div className="col-lg-12 p-0">
                                    <div className="col-lg-12 p-0">
                                      <label className="access_title mb-0">
                                        Sales Contract
                                      </label>
                                      {this.state.mainSectionActiveIndex ===
                                      2 ? (
                                        ""
                                      ) : (
                                        <label className="access_sub_title mb-0">
                                          Sales Contract | Sales Contract
                                          Advance Payments
                                        </label>
                                      )}
                                    </div>
                                  </div>
                                  {this.state.mainSectionActiveIndex === 2 && (
                                    <div className="row col-lg-12 p-0 mb-0 mt-2">
                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Sales Contract
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="16,17,18,19,101,20,21,22,23,24,327"
                                                color="primary"
                                                checked={
                                                  this.state.salesContractChecked.includes(
                                                    "16"
                                                  ) &&
                                                  this.state.salesContractChecked.includes(
                                                    "17"
                                                  ) &&
                                                  this.state.salesContractChecked.includes(
                                                    "18"
                                                  ) &&
                                                  this.state.salesContractChecked.includes(
                                                    "19"
                                                  ) &&
                                                  this.state.salesContractChecked.includes(
                                                    "101"
                                                  ) &&
                                                  this.state.salesContractChecked.includes(
                                                    "20"
                                                  ) &&
                                                  this.state.salesContractChecked.includes(
                                                    "21"
                                                  ) &&
                                                  this.state.salesContractChecked.includes(
                                                    "22"
                                                  ) &&
                                                  this.state.salesContractChecked.includes(
                                                    "23"
                                                  ) &&
                                                  this.state.salesContractChecked.includes(
                                                    "24"
                                                  ) &&
                                                  this.state.salesContractChecked.includes(
                                                    "327"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      salesContractChecked: [
                                                        "16",
                                                        "17",
                                                        "18",
                                                        "19",
                                                        "101",
                                                        "20",
                                                        "21",
                                                        "22",
                                                        "23",
                                                        "24",
                                                        "327",
                                                      ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      salesContractChecked: [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="16"
                                                color="primary"
                                                checked={this.state.salesContractChecked.includes(
                                                  "16"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      salesContractChecked: [
                                                        ...this.state
                                                          .salesContractChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .salesContractChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      salesContractChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="17"
                                                color="primary"
                                                checked={this.state.salesContractChecked.includes(
                                                  "17"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      salesContractChecked: [
                                                        ...this.state
                                                          .salesContractChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .salesContractChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      salesContractChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="18"
                                                color="primary"
                                                checked={this.state.salesContractChecked.includes(
                                                  "18"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      salesContractChecked: [
                                                        ...this.state
                                                          .salesContractChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .salesContractChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      salesContractChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="19"
                                                color="primary"
                                                checked={this.state.salesContractChecked.includes(
                                                  "19"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      salesContractChecked: [
                                                        ...this.state
                                                          .salesContractChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .salesContractChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      salesContractChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Close
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="101"
                                                color="primary"
                                                checked={this.state.salesContractChecked.includes(
                                                  "101"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      salesContractChecked: [
                                                        ...this.state
                                                          .salesContractChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .salesContractChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      salesContractChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Cancel
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="20"
                                                color="primary"
                                                checked={this.state.salesContractChecked.includes(
                                                  "20"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      salesContractChecked: [
                                                        ...this.state
                                                          .salesContractChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .salesContractChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      salesContractChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View Price
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="21"
                                                color="primary"
                                                checked={this.state.salesContractChecked.includes(
                                                  "21"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      salesContractChecked: [
                                                        ...this.state
                                                          .salesContractChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .salesContractChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      salesContractChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="22"
                                                color="primary"
                                                checked={this.state.salesContractChecked.includes(
                                                  "22"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      salesContractChecked: [
                                                        ...this.state
                                                          .salesContractChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .salesContractChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      salesContractChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Freeze
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="23"
                                                color="primary"
                                                checked={this.state.salesContractChecked.includes(
                                                  "23"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      salesContractChecked: [
                                                        ...this.state
                                                          .salesContractChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .salesContractChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      salesContractChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Addendum
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="24"
                                                color="primary"
                                                checked={this.state.salesContractChecked.includes(
                                                  "24"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      salesContractChecked: [
                                                        ...this.state
                                                          .salesContractChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .salesContractChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      salesContractChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Clone
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="327"
                                                color="primary"
                                                checked={this.state.salesContractChecked.includes(
                                                  "327"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      salesContractChecked: [
                                                        ...this.state
                                                          .salesContractChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .salesContractChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      salesContractChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Files
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>

                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Sales Contract Advance Payments
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="107,108,109,110"
                                                color="primary"
                                                checked={
                                                  this.state.salesContractAdvPaymentChecked.includes(
                                                    "107"
                                                  ) &&
                                                  this.state.salesContractAdvPaymentChecked.includes(
                                                    "108"
                                                  ) &&
                                                  this.state.salesContractAdvPaymentChecked.includes(
                                                    "109"
                                                  ) &&
                                                  this.state.salesContractAdvPaymentChecked.includes(
                                                    "110"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      salesContractAdvPaymentChecked:
                                                        [
                                                          "107",
                                                          "108",
                                                          "109",
                                                          "110",
                                                        ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      salesContractAdvPaymentChecked:
                                                        [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="107"
                                                color="primary"
                                                checked={this.state.salesContractAdvPaymentChecked.includes(
                                                  "107"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      salesContractAdvPaymentChecked:
                                                        [
                                                          ...this.state
                                                            .salesContractAdvPaymentChecked,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .salesContractAdvPaymentChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      salesContractAdvPaymentChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="108"
                                                color="primary"
                                                checked={this.state.salesContractAdvPaymentChecked.includes(
                                                  "108"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      salesContractAdvPaymentChecked:
                                                        [
                                                          ...this.state
                                                            .salesContractAdvPaymentChecked,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .salesContractAdvPaymentChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      salesContractAdvPaymentChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="109"
                                                color="primary"
                                                checked={this.state.salesContractAdvPaymentChecked.includes(
                                                  "109"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      salesContractAdvPaymentChecked:
                                                        [
                                                          ...this.state
                                                            .salesContractAdvPaymentChecked,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .salesContractAdvPaymentChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      salesContractAdvPaymentChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="110"
                                                color="primary"
                                                checked={this.state.salesContractAdvPaymentChecked.includes(
                                                  "110"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      salesContractAdvPaymentChecked:
                                                        [
                                                          ...this.state
                                                            .salesContractAdvPaymentChecked,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .salesContractAdvPaymentChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      salesContractAdvPaymentChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div
                                className="col-lg-12 p-1"
                                onClick={() =>
                                  this.setState({ mainSectionActiveIndex: 3 })
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <div
                                  className="row col-lg-12 rounded p-2"
                                  style={{
                                    border:
                                      this.state.mainSectionActiveIndex === 3
                                        ? "1px solid " + config.themeColor
                                        : "1px solid #dee2e6",
                                  }}
                                >
                                  <div className="col-lg-12 p-0">
                                    <div className="col-lg-12 p-0">
                                      <label className="access_title mb-0">
                                        Purchase Contract
                                      </label>
                                      {this.state.mainSectionActiveIndex ===
                                      3 ? (
                                        ""
                                      ) : (
                                        <label className="access_sub_title mb-0">
                                          Purchase Contract | Purchase Contract
                                          Advance Payments
                                        </label>
                                      )}
                                    </div>
                                  </div>
                                  {this.state.mainSectionActiveIndex === 3 && (
                                    <div className="row col-lg-12 p-0 mb-0 mt-2">
                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Purchase Contract
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="7,8,9,10,100,11,12,13,14,15,326"
                                                color="primary"
                                                checked={
                                                  this.state.purchaseContractChecked.includes(
                                                    "7"
                                                  ) &&
                                                  this.state.purchaseContractChecked.includes(
                                                    "8"
                                                  ) &&
                                                  this.state.purchaseContractChecked.includes(
                                                    "9"
                                                  ) &&
                                                  this.state.purchaseContractChecked.includes(
                                                    "10"
                                                  ) &&
                                                  this.state.purchaseContractChecked.includes(
                                                    "100"
                                                  ) &&
                                                  this.state.purchaseContractChecked.includes(
                                                    "11"
                                                  ) &&
                                                  this.state.purchaseContractChecked.includes(
                                                    "12"
                                                  ) &&
                                                  this.state.purchaseContractChecked.includes(
                                                    "13"
                                                  ) &&
                                                  this.state.purchaseContractChecked.includes(
                                                    "14"
                                                  ) &&
                                                  this.state.purchaseContractChecked.includes(
                                                    "15"
                                                  ) &&
                                                  this.state.purchaseContractChecked.includes(
                                                    "326"
                                                  ) &&
                                                  this.state.purchaseContractChecked.includes(
                                                    "592"
                                                  ) &&
                                                  this.state.purchaseContractChecked.includes(
                                                    "593"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      purchaseContractChecked: [
                                                        "7",
                                                        "8",
                                                        "9",
                                                        "10",
                                                        "100",
                                                        "11",
                                                        "12",
                                                        "13",
                                                        "14",
                                                        "15",
                                                        "326",
                                                        "592",
                                                        "593",
                                                      ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      purchaseContractChecked:
                                                        [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="7"
                                                color="primary"
                                                checked={this.state.purchaseContractChecked.includes(
                                                  "7"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      purchaseContractChecked: [
                                                        ...this.state
                                                          .purchaseContractChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .purchaseContractChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      purchaseContractChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="8"
                                                color="primary"
                                                checked={this.state.purchaseContractChecked.includes(
                                                  "8"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      purchaseContractChecked: [
                                                        ...this.state
                                                          .purchaseContractChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .purchaseContractChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      purchaseContractChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="9"
                                                color="primary"
                                                checked={this.state.purchaseContractChecked.includes(
                                                  "9"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      purchaseContractChecked: [
                                                        ...this.state
                                                          .purchaseContractChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .purchaseContractChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      purchaseContractChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="10"
                                                color="primary"
                                                checked={this.state.purchaseContractChecked.includes(
                                                  "10"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      purchaseContractChecked: [
                                                        ...this.state
                                                          .purchaseContractChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .purchaseContractChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      purchaseContractChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Close
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="100"
                                                color="primary"
                                                checked={this.state.purchaseContractChecked.includes(
                                                  "100"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      purchaseContractChecked: [
                                                        ...this.state
                                                          .purchaseContractChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .purchaseContractChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      purchaseContractChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Cancel
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="11"
                                                color="primary"
                                                checked={this.state.purchaseContractChecked.includes(
                                                  "11"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      purchaseContractChecked: [
                                                        ...this.state
                                                          .purchaseContractChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .purchaseContractChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      purchaseContractChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View Price
                                              </span>
                                            </span>
                                          </div>

                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="13"
                                                color="primary"
                                                checked={this.state.purchaseContractChecked.includes(
                                                  "13"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      purchaseContractChecked: [
                                                        ...this.state
                                                          .purchaseContractChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .purchaseContractChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      purchaseContractChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Freeze
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="14"
                                                color="primary"
                                                checked={this.state.purchaseContractChecked.includes(
                                                  "14"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      purchaseContractChecked: [
                                                        ...this.state
                                                          .purchaseContractChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .purchaseContractChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      purchaseContractChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Addendum
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="15"
                                                color="primary"
                                                checked={this.state.purchaseContractChecked.includes(
                                                  "15"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      purchaseContractChecked: [
                                                        ...this.state
                                                          .purchaseContractChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .purchaseContractChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      purchaseContractChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Clone
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="12"
                                                color="primary"
                                                checked={this.state.purchaseContractChecked.includes(
                                                  "12"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      purchaseContractChecked: [
                                                        ...this.state
                                                          .purchaseContractChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .purchaseContractChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      purchaseContractChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approval
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="593"
                                                color="primary"
                                                checked={this.state.purchaseContractChecked.includes(
                                                  "593"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      purchaseContractChecked: [
                                                        ...this.state
                                                          .purchaseContractChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .purchaseContractChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      purchaseContractChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="326"
                                                color="primary"
                                                checked={this.state.purchaseContractChecked.includes(
                                                  "326"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      purchaseContractChecked: [
                                                        ...this.state
                                                          .purchaseContractChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .purchaseContractChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      purchaseContractChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Files
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>

                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Purchase Contract Advance Payments
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="103,104,105,106"
                                                color="primary"
                                                checked={
                                                  this.state.purchaseContractAdvPaymentChecked.includes(
                                                    "103"
                                                  ) &&
                                                  this.state.purchaseContractAdvPaymentChecked.includes(
                                                    "104"
                                                  ) &&
                                                  this.state.purchaseContractAdvPaymentChecked.includes(
                                                    "105"
                                                  ) &&
                                                  this.state.purchaseContractAdvPaymentChecked.includes(
                                                    "106"
                                                  ) &&
                                                  this.state.purchaseContractAdvPaymentChecked.includes(
                                                    "594"
                                                  ) &&
                                                  this.state.purchaseContractAdvPaymentChecked.includes(
                                                    "595"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      purchaseContractAdvPaymentChecked:
                                                        [
                                                          "103",
                                                          "104",
                                                          "105",
                                                          "106",
                                                          "594",
                                                          "595",
                                                        ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      purchaseContractAdvPaymentChecked:
                                                        [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="103"
                                                color="primary"
                                                checked={this.state.purchaseContractAdvPaymentChecked.includes(
                                                  "103"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      purchaseContractAdvPaymentChecked:
                                                        [
                                                          ...this.state
                                                            .purchaseContractAdvPaymentChecked,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .purchaseContractAdvPaymentChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      purchaseContractAdvPaymentChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="104"
                                                color="primary"
                                                checked={this.state.purchaseContractAdvPaymentChecked.includes(
                                                  "104"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      purchaseContractAdvPaymentChecked:
                                                        [
                                                          ...this.state
                                                            .purchaseContractAdvPaymentChecked,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .purchaseContractAdvPaymentChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      purchaseContractAdvPaymentChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="105"
                                                color="primary"
                                                checked={this.state.purchaseContractAdvPaymentChecked.includes(
                                                  "105"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      purchaseContractAdvPaymentChecked:
                                                        [
                                                          ...this.state
                                                            .purchaseContractAdvPaymentChecked,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .purchaseContractAdvPaymentChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      purchaseContractAdvPaymentChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="106"
                                                color="primary"
                                                checked={this.state.purchaseContractAdvPaymentChecked.includes(
                                                  "106"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      purchaseContractAdvPaymentChecked:
                                                        [
                                                          ...this.state
                                                            .purchaseContractAdvPaymentChecked,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .purchaseContractAdvPaymentChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      purchaseContractAdvPaymentChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="594"
                                                color="primary"
                                                checked={this.state.purchaseContractAdvPaymentChecked.includes(
                                                  "594"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      purchaseContractAdvPaymentChecked:
                                                        [
                                                          ...this.state
                                                            .purchaseContractAdvPaymentChecked,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .purchaseContractAdvPaymentChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      purchaseContractAdvPaymentChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approval
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="595"
                                                color="primary"
                                                checked={this.state.purchaseContractAdvPaymentChecked.includes(
                                                  "595"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      purchaseContractAdvPaymentChecked:
                                                        [
                                                          ...this.state
                                                            .purchaseContractAdvPaymentChecked,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .purchaseContractAdvPaymentChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      purchaseContractAdvPaymentChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div
                                className="col-lg-12 p-1"
                                onClick={() =>
                                  this.setState({ mainSectionActiveIndex: 9 })
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <div
                                  className="row col-lg-12 rounded p-2"
                                  style={{
                                    border:
                                      this.state.mainSectionActiveIndex === 9
                                        ? "1px solid " + config.themeColor
                                        : "1px solid #dee2e6",
                                  }}
                                >
                                  <div className="col-lg-12 p-0">
                                    <div className="col-lg-12 p-0">
                                      <label className="access_title mb-0">
                                        Coal Procurement Service Contract
                                      </label>
                                      {this.state.mainSectionActiveIndex ===
                                      9 ? (
                                        ""
                                      ) : (
                                        <label className="access_sub_title mb-0">
                                          Coal Procurement Contract
                                        </label>
                                      )}
                                    </div>
                                  </div>
                                  {this.state.mainSectionActiveIndex === 9 && (
                                    <div className="row col-lg-12 p-0 mb-0 mt-2">
                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Coal Procurement Contract
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="500,501,502,503,504,505,"
                                                color="primary"
                                                checked={
                                                  this.state.cps_contract.includes(
                                                    "500"
                                                  ) &&
                                                  this.state.cps_contract.includes(
                                                    "501"
                                                  ) &&
                                                  this.state.cps_contract.includes(
                                                    "502"
                                                  ) &&
                                                  this.state.cps_contract.includes(
                                                    "503"
                                                  ) &&
                                                  this.state.cps_contract.includes(
                                                    "504"
                                                  ) &&
                                                  this.state.cps_contract.includes(
                                                    "505"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      cps_contract: [
                                                        "500",
                                                        "501",
                                                        "502",
                                                        "503",
                                                        "504",
                                                        "505",
                                                      ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      cps_contract: [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="500"
                                                color="primary"
                                                checked={this.state.cps_contract.includes(
                                                  "500"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      cps_contract: [
                                                        ...this.state
                                                          .cps_contract,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.cps_contract;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      cps_contract: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="501"
                                                color="primary"
                                                checked={this.state.cps_contract.includes(
                                                  "501"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      cps_contract: [
                                                        ...this.state
                                                          .cps_contract,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.cps_contract;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      cps_contract: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="502"
                                                color="primary"
                                                checked={this.state.cps_contract.includes(
                                                  "502"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      cps_contract: [
                                                        ...this.state
                                                          .cps_contract,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.cps_contract;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      cps_contract: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="504"
                                                color="primary"
                                                checked={this.state.cps_contract.includes(
                                                  "504"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      cps_contract: [
                                                        ...this.state
                                                          .cps_contract,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.cps_contract;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      cps_contract: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Close
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="503"
                                                color="primary"
                                                checked={this.state.cps_contract.includes(
                                                  "503"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      cps_contract: [
                                                        ...this.state
                                                          .cps_contract,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.cps_contract;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      cps_contract: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Cancel
                                              </span>
                                            </span>
                                          </div>

                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="505"
                                                color="primary"
                                                checked={this.state.cps_contract.includes(
                                                  "505"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      cps_contract: [
                                                        ...this.state
                                                          .cps_contract,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.cps_contract;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      cps_contract: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Files
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div
                                className="col-lg-12 p-1"
                                onClick={() =>
                                  this.setState({ mainSectionActiveIndex: 4 })
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <div
                                  className="row col-lg-12 rounded p-2"
                                  style={{
                                    border:
                                      this.state.mainSectionActiveIndex === 4
                                        ? "1px solid " + config.themeColor
                                        : "1px solid #dee2e6",
                                  }}
                                >
                                  <div className="col-lg-12 p-0">
                                    <div className="col-lg-12 p-0">
                                      <label className="access_title mb-0">
                                        Advance Contract
                                      </label>
                                      {this.state.mainSectionActiveIndex ===
                                      4 ? (
                                        ""
                                      ) : (
                                        <label className="access_sub_title mb-0">
                                          Vendor Advance Contract | Vendor
                                          Advance Payment
                                        </label>
                                      )}
                                    </div>
                                  </div>
                                  {this.state.mainSectionActiveIndex === 4 && (
                                    <div className="row col-lg-12 p-0 mb-0 mt-2">
                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Vendor Advance Contract
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="1,2,3"
                                                color="primary"
                                                checked={
                                                  this.state.vendorAdvanceChecked.includes(
                                                    "1"
                                                  ) &&
                                                  this.state.vendorAdvanceChecked.includes(
                                                    "2"
                                                  ) &&
                                                  this.state.vendorAdvanceChecked.includes(
                                                    "3"
                                                  ) &&
                                                  this.state.vendorAdvanceChecked.includes(
                                                    "588"
                                                  ) &&
                                                  this.state.vendorAdvanceChecked.includes(
                                                    "589"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      vendorAdvanceChecked: [
                                                        "1",
                                                        "2",
                                                        "3",
                                                        "588",
                                                        "589",
                                                      ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      vendorAdvanceChecked: [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="1"
                                                color="primary"
                                                checked={this.state.vendorAdvanceChecked.includes(
                                                  "1"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      vendorAdvanceChecked: [
                                                        ...this.state
                                                          .vendorAdvanceChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .vendorAdvanceChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      vendorAdvanceChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="2"
                                                color="primary"
                                                checked={this.state.vendorAdvanceChecked.includes(
                                                  "2"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      vendorAdvanceChecked: [
                                                        ...this.state
                                                          .vendorAdvanceChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .vendorAdvanceChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      vendorAdvanceChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="3"
                                                color="primary"
                                                checked={this.state.vendorAdvanceChecked.includes(
                                                  "3"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      vendorAdvanceChecked: [
                                                        ...this.state
                                                          .vendorAdvanceChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .vendorAdvanceChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      vendorAdvanceChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="588"
                                                color="primary"
                                                checked={this.state.vendorAdvanceChecked.includes(
                                                  "588"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      vendorAdvanceChecked: [
                                                        ...this.state
                                                          .vendorAdvanceChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .vendorAdvanceChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      vendorAdvanceChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approval
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="589"
                                                color="primary"
                                                checked={this.state.vendorAdvanceChecked.includes(
                                                  "589"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      vendorAdvanceChecked: [
                                                        ...this.state
                                                          .vendorAdvanceChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .vendorAdvanceChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      vendorAdvanceChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>

                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Vendor Advance Payment
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="4,5,6"
                                                color="primary"
                                                checked={
                                                  this.state.vendorAdvancePaymentChecked.includes(
                                                    "4"
                                                  ) &&
                                                  this.state.vendorAdvancePaymentChecked.includes(
                                                    "5"
                                                  ) &&
                                                  this.state.vendorAdvancePaymentChecked.includes(
                                                    "6"
                                                  ) &&
                                                  this.state.vendorAdvancePaymentChecked.includes(
                                                    "590"
                                                  ) &&
                                                  this.state.vendorAdvancePaymentChecked.includes(
                                                    "591"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      vendorAdvancePaymentChecked:
                                                        [
                                                          "4",
                                                          "5",
                                                          "6",
                                                          "590",
                                                          "591",
                                                        ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      vendorAdvancePaymentChecked:
                                                        [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="4"
                                                color="primary"
                                                checked={this.state.vendorAdvancePaymentChecked.includes(
                                                  "4"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      vendorAdvancePaymentChecked:
                                                        [
                                                          ...this.state
                                                            .vendorAdvancePaymentChecked,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .vendorAdvancePaymentChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      vendorAdvancePaymentChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="5"
                                                color="primary"
                                                checked={this.state.vendorAdvancePaymentChecked.includes(
                                                  "5"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      vendorAdvancePaymentChecked:
                                                        [
                                                          ...this.state
                                                            .vendorAdvancePaymentChecked,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .vendorAdvancePaymentChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      vendorAdvancePaymentChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="6"
                                                color="primary"
                                                checked={this.state.vendorAdvancePaymentChecked.includes(
                                                  "6"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      vendorAdvancePaymentChecked:
                                                        [
                                                          ...this.state
                                                            .vendorAdvancePaymentChecked,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .vendorAdvancePaymentChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      vendorAdvancePaymentChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="590"
                                                color="primary"
                                                checked={this.state.vendorAdvancePaymentChecked.includes(
                                                  "590"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      vendorAdvancePaymentChecked:
                                                        [
                                                          ...this.state
                                                            .vendorAdvancePaymentChecked,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .vendorAdvancePaymentChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      vendorAdvancePaymentChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approval
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="591"
                                                color="primary"
                                                checked={this.state.vendorAdvancePaymentChecked.includes(
                                                  "591"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      vendorAdvancePaymentChecked:
                                                        [
                                                          ...this.state
                                                            .vendorAdvancePaymentChecked,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .vendorAdvancePaymentChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      vendorAdvancePaymentChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div
                                className="col-lg-12 p-1"
                                onClick={() =>
                                  this.setState({ mainSectionActiveIndex: 5 })
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <div
                                  className="row col-lg-12 rounded p-2"
                                  style={{
                                    border:
                                      this.state.mainSectionActiveIndex === 5
                                        ? "1px solid " + config.themeColor
                                        : "1px solid #dee2e6",
                                  }}
                                >
                                  <div className="col-lg-12 p-0">
                                    <div className="col-lg-12 p-0">
                                      <label className="access_title mb-0">
                                        Barges
                                      </label>
                                      {this.state.mainSectionActiveIndex ===
                                      5 ? (
                                        ""
                                      ) : (
                                        <label className="access_sub_title mb-0">
                                          Barge Operational Data | Quality
                                          Results
                                        </label>
                                      )}
                                    </div>
                                  </div>
                                  {this.state.mainSectionActiveIndex === 5 && (
                                    <div className="row col-lg-12 p-0 mb-0 mt-2">
                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Barge Operational Data
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="45,46,47,48,102,123,375"
                                                color="primary"
                                                checked={
                                                  this.state.BusinessNoOpBargeChecked.includes(
                                                    "45"
                                                  ) &&
                                                  this.state.BusinessNoOpBargeChecked.includes(
                                                    "46"
                                                  ) &&
                                                  this.state.BusinessNoOpBargeChecked.includes(
                                                    "47"
                                                  ) &&
                                                  this.state.BusinessNoOpBargeChecked.includes(
                                                    "48"
                                                  ) &&
                                                  this.state.BusinessNoOpBargeChecked.includes(
                                                    "102"
                                                  ) &&
                                                  this.state.BusinessNoOpBargeChecked.includes(
                                                    "123"
                                                  ) &&
                                                  this.state.BusinessNoOpBargeChecked.includes(
                                                    "375"
                                                  ) &&
                                                  this.state.BusinessNoOpBargeChecked.includes(
                                                    "612"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      BusinessNoOpBargeChecked:
                                                        [
                                                          "45",
                                                          "46",
                                                          "47",
                                                          "48",
                                                          "102",
                                                          "123",
                                                          "375",
                                                          "612",
                                                        ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      BusinessNoOpBargeChecked:
                                                        [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="45"
                                                color="primary"
                                                checked={this.state.BusinessNoOpBargeChecked.includes(
                                                  "45"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      BusinessNoOpBargeChecked:
                                                        [
                                                          ...this.state
                                                            .BusinessNoOpBargeChecked,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .BusinessNoOpBargeChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      BusinessNoOpBargeChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="46"
                                                color="primary"
                                                checked={this.state.BusinessNoOpBargeChecked.includes(
                                                  "46"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      BusinessNoOpBargeChecked:
                                                        [
                                                          ...this.state
                                                            .BusinessNoOpBargeChecked,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .BusinessNoOpBargeChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      BusinessNoOpBargeChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="47"
                                                color="primary"
                                                checked={this.state.BusinessNoOpBargeChecked.includes(
                                                  "47"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      BusinessNoOpBargeChecked:
                                                        [
                                                          ...this.state
                                                            .BusinessNoOpBargeChecked,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .BusinessNoOpBargeChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      BusinessNoOpBargeChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="48"
                                                color="primary"
                                                checked={this.state.BusinessNoOpBargeChecked.includes(
                                                  "48"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      BusinessNoOpBargeChecked:
                                                        [
                                                          ...this.state
                                                            .BusinessNoOpBargeChecked,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .BusinessNoOpBargeChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      BusinessNoOpBargeChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="102"
                                                color="primary"
                                                checked={this.state.BusinessNoOpBargeChecked.includes(
                                                  "102"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      BusinessNoOpBargeChecked:
                                                        [
                                                          ...this.state
                                                            .BusinessNoOpBargeChecked,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .BusinessNoOpBargeChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      BusinessNoOpBargeChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View Price
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="123"
                                                color="primary"
                                                checked={this.state.BusinessNoOpBargeChecked.includes(
                                                  "123"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      BusinessNoOpBargeChecked:
                                                        [
                                                          ...this.state
                                                            .BusinessNoOpBargeChecked,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .BusinessNoOpBargeChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      BusinessNoOpBargeChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Change Allocations
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="612"
                                                color="primary"
                                                checked={this.state.BusinessNoOpBargeChecked.includes(
                                                  "612"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      BusinessNoOpBargeChecked:
                                                        [
                                                          ...this.state
                                                            .BusinessNoOpBargeChecked,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .BusinessNoOpBargeChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      BusinessNoOpBargeChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View Financials
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="375"
                                                color="primary"
                                                checked={this.state.BusinessNoOpBargeChecked.includes(
                                                  "375"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      BusinessNoOpBargeChecked:
                                                        [
                                                          ...this.state
                                                            .BusinessNoOpBargeChecked,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .BusinessNoOpBargeChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      BusinessNoOpBargeChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Files
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>

                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Quality Results
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="55,56,57"
                                                color="primary"
                                                checked={
                                                  this.state.qualityResultsChecked.includes(
                                                    "55"
                                                  ) &&
                                                  this.state.qualityResultsChecked.includes(
                                                    "56"
                                                  ) &&
                                                  this.state.qualityResultsChecked.includes(
                                                    "57"
                                                  ) &&
                                                  this.state.qualityResultsChecked.includes(
                                                    "533"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      qualityResultsChecked: [
                                                        "55",
                                                        "56",
                                                        "57",
                                                        "533",
                                                      ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      qualityResultsChecked: [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="55"
                                                color="primary"
                                                checked={this.state.qualityResultsChecked.includes(
                                                  "55"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      qualityResultsChecked: [
                                                        ...this.state
                                                          .qualityResultsChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .qualityResultsChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      qualityResultsChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="56"
                                                color="primary"
                                                checked={this.state.qualityResultsChecked.includes(
                                                  "56"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      qualityResultsChecked: [
                                                        ...this.state
                                                          .qualityResultsChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .qualityResultsChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      qualityResultsChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="57"
                                                color="primary"
                                                checked={this.state.qualityResultsChecked.includes(
                                                  "57"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      qualityResultsChecked: [
                                                        ...this.state
                                                          .qualityResultsChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .qualityResultsChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      qualityResultsChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="533"
                                                color="primary"
                                                checked={this.state.qualityResultsChecked.includes(
                                                  "533"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      qualityResultsChecked: [
                                                        ...this.state
                                                          .qualityResultsChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .qualityResultsChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      qualityResultsChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Files
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div
                                className="col-lg-12 p-1"
                                onClick={() =>
                                  this.setState({ mainSectionActiveIndex: 6 })
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <div
                                  className="row col-lg-12 rounded p-2"
                                  style={{
                                    border:
                                      this.state.mainSectionActiveIndex === 6
                                        ? "1px solid " + config.themeColor
                                        : "1px solid #dee2e6",
                                  }}
                                >
                                  <div className="col-lg-12 p-0">
                                    <div className="col-lg-12 p-0">
                                      <label className="access_title mb-0">
                                        Barge Demurrage
                                      </label>
                                      {this.state.mainSectionActiveIndex ===
                                      6 ? (
                                        ""
                                      ) : (
                                        <label className="access_sub_title mb-0">
                                          Barge Demurrage | Demurrage Costing |
                                          Demurrage Payments
                                        </label>
                                      )}
                                    </div>
                                  </div>
                                  {this.state.mainSectionActiveIndex === 6 && (
                                    <div className="row col-lg-12 p-0 mb-0 mt-2">
                                      {/* <Accordion square={true} defaultExpanded={false} className="access_accordion_collapse col-lg-12 p-0" style={{ margin: '0px 0px 6px 0px', boxShadow: 'none', borderRadius: '5px' }}>
                                      <CompactExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        style={{ borderBottom: '1px solid #9c948f', height: 30, backgroundColor: '#f1efea', borderTopLeftRadius: '4px', borderTopRightRadius: '4px' }}
                                      >
                                        <span className="expansion_title mb-0">Barge Demurrage</span>
                                      </CompactExpansionPanelSummary>
                                      <AccordionDetails className="row col-lg p-2">
                                        <div className="col-lg-3 p-1">
                                          <span style={{ flexDirection: 'row' }}>
                                            <CheckBox
                                              value='52,53,54,394,445'
                                              color='primary'
                                              checked={this.state.bargeDemurrageChecked.includes('52')
                                                && this.state.bargeDemurrageChecked.includes('53')
                                                && this.state.bargeDemurrageChecked.includes('54')
                                                && this.state.bargeDemurrageChecked.includes('394')
                                                && this.state.bargeDemurrageChecked.includes('445')
                                              }
                                              onChange={(e) => {
                                                if (e.target.checked) {
                                                  this.setState({
                                                    bargeDemurrageChecked: ['52', '53', '54', '394', "445"]
                                                  });
                                                }
                                                else {
                                                  this.setState({
                                                    bargeDemurrageChecked: []
                                                  });
                                                }
                                              }}
                                            />
                                            <span className="checkbox_title">Select All</span>
                                          </span>
                                        </div>
                                        <div className="col-lg-3 p-1">
                                          <span style={{ flexDirection: 'row' }}>
                                            <CheckBox
                                              value='52'
                                              color='primary'
                                              checked={this.state.bargeDemurrageChecked.includes('52')}
                                              onChange={(e) => {
                                                if (e.target.checked) {
                                                  this.setState({
                                                    bargeDemurrageChecked: [...this.state.bargeDemurrageChecked, e.target.value]
                                                  })
                                                }
                                                else {
                                                  var data = this.state.bargeDemurrageChecked;
                                                  if (data.includes(e.target.value)) {
                                                    let index = data.includes(e.target.value);
                                                    data.splice(index, 1);
                                                  }
                                                  this.setState({
                                                    bargeDemurrageChecked: data
                                                  });
                                                }
                                              }}
                                            />
                                            <span className="checkbox_title">Add</span>
                                          </span>
                                        </div>
                                        <div className="col-lg-3 p-1">
                                          <span style={{ flexDirection: 'row' }}>
                                            <CheckBox
                                              value='53'
                                              color='primary'
                                              checked={this.state.bargeDemurrageChecked.includes('53')}
                                              onChange={(e) => {
                                                if (e.target.checked) {
                                                  this.setState({
                                                    bargeDemurrageChecked: [...this.state.bargeDemurrageChecked, e.target.value]
                                                  })
                                                }
                                                else {
                                                  var data = this.state.bargeDemurrageChecked;
                                                  if (data.includes(e.target.value)) {
                                                    let index = data.includes(e.target.value);
                                                    data.splice(index, 1);
                                                  }
                                                  this.setState({
                                                    bargeDemurrageChecked: data
                                                  });
                                                }
                                              }}
                                            />
                                            <span className="checkbox_title">View</span>
                                          </span>
                                        </div>
                                        <div className="col-lg-3 p-1">
                                          <span style={{ flexDirection: 'row' }}>
                                            <CheckBox
                                              value='54'
                                              color='primary'
                                              checked={this.state.bargeDemurrageChecked.includes('54')}
                                              onChange={(e) => {
                                                if (e.target.checked) {
                                                  this.setState({
                                                    bargeDemurrageChecked: [...this.state.bargeDemurrageChecked, e.target.value]
                                                  })
                                                }
                                                else {
                                                  var data = this.state.bargeDemurrageChecked;
                                                  if (data.includes(e.target.value)) {
                                                    let index = data.includes(e.target.value);
                                                    data.splice(index, 1);
                                                  }
                                                  this.setState({
                                                    bargeDemurrageChecked: data
                                                  });
                                                }
                                              }}
                                            />
                                            <span className="checkbox_title">Update</span>
                                          </span>
                                        </div>
                                        <div className="col-lg-3 p-1">
                                          <span style={{ flexDirection: 'row' }}>
                                            <CheckBox
                                              value='394'
                                              color='primary'
                                              checked={this.state.bargeDemurrageChecked.includes('394')}
                                              onChange={(e) => {
                                                if (e.target.checked) {
                                                  this.setState({
                                                    bargeDemurrageChecked: [...this.state.bargeDemurrageChecked, e.target.value]
                                                  })
                                                }
                                                else {
                                                  var data = this.state.bargeDemurrageChecked;
                                                  if (data.includes(e.target.value)) {
                                                    let index = data.includes(e.target.value);
                                                    data.splice(index, 1);
                                                  }
                                                  this.setState({
                                                    bargeDemurrageChecked: data
                                                  });
                                                }
                                              }}
                                            />
                                            <span className="checkbox_title">Approve</span>
                                          </span>
                                        </div>
                                        <div className="col-lg-3 p-1">
                                          <span style={{ flexDirection: 'row' }}>
                                            <CheckBox
                                              value='445'
                                              color='primary'
                                              checked={this.state.bargeDemurrageChecked.includes('445')}
                                              onChange={(e) => {
                                                if (e.target.checked) {
                                                  this.setState({
                                                    bargeDemurrageChecked: [...this.state.bargeDemurrageChecked, e.target.value]
                                                  })
                                                }
                                                else {
                                                  var data = this.state.bargeDemurrageChecked;
                                                  if (data.includes(e.target.value)) {
                                                    let index = data.includes(e.target.value);
                                                    data.splice(index, 1);
                                                  }
                                                  this.setState({
                                                    bargeDemurrageChecked: data
                                                  });
                                                }
                                              }}
                                            />
                                            <span className="checkbox_title">2nd Approval</span>
                                          </span>
                                        </div>
                                      </AccordionDetails>
                                    </Accordion> */}
                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Demurrage Costing
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="150,151,152,153,394,445"
                                                color="primary"
                                                checked={
                                                  this.state.barge_pur_demurrage_cost.includes(
                                                    "150"
                                                  ) &&
                                                  this.state.barge_pur_demurrage_cost.includes(
                                                    "151"
                                                  ) &&
                                                  this.state.barge_pur_demurrage_cost.includes(
                                                    "152"
                                                  ) &&
                                                  this.state.barge_pur_demurrage_cost.includes(
                                                    "153"
                                                  ) &&
                                                  this.state.barge_pur_demurrage_cost.includes(
                                                    "394"
                                                  ) &&
                                                  this.state.barge_pur_demurrage_cost.includes(
                                                    "445"
                                                  ) &&
                                                  this.state.barge_pur_demurrage_cost.includes(
                                                    "530"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_demurrage_cost:
                                                        [
                                                          "150",
                                                          "151",
                                                          "152",
                                                          "153",
                                                          "394",
                                                          "445",
                                                          "530",
                                                        ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      barge_pur_demurrage_cost:
                                                        [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="150"
                                                color="primary"
                                                checked={this.state.barge_pur_demurrage_cost.includes(
                                                  "150"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_demurrage_cost:
                                                        [
                                                          ...this.state
                                                            .barge_pur_demurrage_cost,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_demurrage_cost;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_demurrage_cost:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="151"
                                                color="primary"
                                                checked={this.state.barge_pur_demurrage_cost.includes(
                                                  "151"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_demurrage_cost:
                                                        [
                                                          ...this.state
                                                            .barge_pur_demurrage_cost,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_demurrage_cost;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_demurrage_cost:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="152"
                                                color="primary"
                                                checked={this.state.barge_pur_demurrage_cost.includes(
                                                  "152"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_demurrage_cost:
                                                        [
                                                          ...this.state
                                                            .barge_pur_demurrage_cost,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_demurrage_cost;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_demurrage_cost:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="153"
                                                color="primary"
                                                checked={this.state.barge_pur_demurrage_cost.includes(
                                                  "153"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_demurrage_cost:
                                                        [
                                                          ...this.state
                                                            .barge_pur_demurrage_cost,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_demurrage_cost;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_demurrage_cost:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="394"
                                                color="primary"
                                                checked={this.state.barge_pur_demurrage_cost.includes(
                                                  "394"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_demurrage_cost:
                                                        [
                                                          ...this.state
                                                            .barge_pur_demurrage_cost,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_demurrage_cost;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_demurrage_cost:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="445"
                                                color="primary"
                                                checked={this.state.barge_pur_demurrage_cost.includes(
                                                  "445"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_demurrage_cost:
                                                        [
                                                          ...this.state
                                                            .barge_pur_demurrage_cost,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_demurrage_cost;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_demurrage_cost:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="530"
                                                color="primary"
                                                checked={this.state.barge_pur_demurrage_cost.includes(
                                                  "530"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_demurrage_cost:
                                                        [
                                                          ...this.state
                                                            .barge_pur_demurrage_cost,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_demurrage_cost;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_demurrage_cost:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Files
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>

                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Demurrage Payments
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="154,155,156,157,395"
                                                color="primary"
                                                checked={
                                                  this.state.barge_pur_demurrage_cost_payments.includes(
                                                    "154"
                                                  ) &&
                                                  this.state.barge_pur_demurrage_cost_payments.includes(
                                                    "155"
                                                  ) &&
                                                  this.state.barge_pur_demurrage_cost_payments.includes(
                                                    "156"
                                                  ) &&
                                                  this.state.barge_pur_demurrage_cost_payments.includes(
                                                    "157"
                                                  ) &&
                                                  this.state.barge_pur_demurrage_cost_payments.includes(
                                                    "395"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_demurrage_cost_payments:
                                                        [
                                                          "154",
                                                          "155",
                                                          "156",
                                                          "157",
                                                          "395",
                                                        ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      barge_pur_demurrage_cost_payments:
                                                        [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="154"
                                                color="primary"
                                                checked={this.state.barge_pur_demurrage_cost_payments.includes(
                                                  "154"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_demurrage_cost_payments:
                                                        [
                                                          ...this.state
                                                            .barge_pur_demurrage_cost_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_demurrage_cost_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_demurrage_cost_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="155"
                                                color="primary"
                                                checked={this.state.barge_pur_demurrage_cost_payments.includes(
                                                  "155"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_demurrage_cost_payments:
                                                        [
                                                          ...this.state
                                                            .barge_pur_demurrage_cost_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_demurrage_cost_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_demurrage_cost_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="156"
                                                color="primary"
                                                checked={this.state.barge_pur_demurrage_cost_payments.includes(
                                                  "156"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_demurrage_cost_payments:
                                                        [
                                                          ...this.state
                                                            .barge_pur_demurrage_cost_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_demurrage_cost_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_demurrage_cost_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="157"
                                                color="primary"
                                                checked={this.state.barge_pur_demurrage_cost_payments.includes(
                                                  "157"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_demurrage_cost_payments:
                                                        [
                                                          ...this.state
                                                            .barge_pur_demurrage_cost_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_demurrage_cost_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_demurrage_cost_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="395"
                                                color="primary"
                                                checked={this.state.barge_pur_demurrage_cost_payments.includes(
                                                  "395"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_demurrage_cost_payments:
                                                        [
                                                          ...this.state
                                                            .barge_pur_demurrage_cost_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_demurrage_cost_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_demurrage_cost_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div
                                className="col-lg-12 p-1"
                                onClick={() =>
                                  this.setState({ mainSectionActiveIndex: 7 })
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <div
                                  className="row col-lg-12 rounded p-2"
                                  style={{
                                    border:
                                      this.state.mainSectionActiveIndex === 7
                                        ? "1px solid " + config.themeColor
                                        : "1px solid #dee2e6",
                                  }}
                                >
                                  <div className="col-lg-12 p-0">
                                    <div className="col-lg-12 p-0">
                                      <label className="access_title mb-0">
                                        Barge Purchase Financials
                                      </label>
                                      {this.state.mainSectionActiveIndex ===
                                      7 ? (
                                        ""
                                      ) : (
                                        <label className="access_sub_title mb-0">
                                          Coal Costing | Coal Costing Payments |
                                          Barge Costing | Barge Costing Payments
                                          | Chemical Spray | Chemical Spray
                                          Payments | Coal Procurement Charges |
                                          Coal Procurement Payments | Coal
                                          Procurement Service | Coal Procurement
                                          Service Payments | Sampling & Analysis
                                          Charges | Sampling & Analysis Payments
                                          | Pre-Shipment Inspection Charges |
                                          Pre-Shipment Inspection Payments |
                                          Export Tax Charges | Export Tax
                                          Payments | Documentation Charges |
                                          Documentation Payments | Laparon
                                          Charges | Laparon Payments | Other
                                          Expenses | Other Expense Payments |
                                          Barge Insurance | Insurance Payments |
                                          Return Cargo Charges | Return Cargo
                                          Charges Payments
                                        </label>
                                      )}
                                    </div>
                                  </div>
                                  {this.state.mainSectionActiveIndex === 7 && (
                                    <div className="row col-lg-12 p-0 mb-0 mt-2">
                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Coal Costing
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="126,127,128,129,365,388,439"
                                                color="primary"
                                                checked={
                                                  this.state.barge_pur_coal_costing.includes(
                                                    "126"
                                                  ) &&
                                                  this.state.barge_pur_coal_costing.includes(
                                                    "127"
                                                  ) &&
                                                  this.state.barge_pur_coal_costing.includes(
                                                    "128"
                                                  ) &&
                                                  this.state.barge_pur_coal_costing.includes(
                                                    "129"
                                                  ) &&
                                                  this.state.barge_pur_coal_costing.includes(
                                                    "365"
                                                  ) &&
                                                  this.state.barge_pur_coal_costing.includes(
                                                    "388"
                                                  ) &&
                                                  this.state.barge_pur_coal_costing.includes(
                                                    "439"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_coal_costing: [
                                                        "126",
                                                        "127",
                                                        "128",
                                                        "129",
                                                        "365",
                                                        "388",
                                                        "439",
                                                      ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      barge_pur_coal_costing:
                                                        [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="126"
                                                color="primary"
                                                checked={this.state.barge_pur_coal_costing.includes(
                                                  "126"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_coal_costing: [
                                                        ...this.state
                                                          .barge_pur_coal_costing,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_coal_costing;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_coal_costing:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="127"
                                                color="primary"
                                                checked={this.state.barge_pur_coal_costing.includes(
                                                  "127"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_coal_costing: [
                                                        ...this.state
                                                          .barge_pur_coal_costing,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_coal_costing;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_coal_costing:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="128"
                                                color="primary"
                                                checked={this.state.barge_pur_coal_costing.includes(
                                                  "128"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_coal_costing: [
                                                        ...this.state
                                                          .barge_pur_coal_costing,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_coal_costing;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_coal_costing:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="129"
                                                color="primary"
                                                checked={this.state.barge_pur_coal_costing.includes(
                                                  "129"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_coal_costing: [
                                                        ...this.state
                                                          .barge_pur_coal_costing,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_coal_costing;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_coal_costing:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="388"
                                                color="primary"
                                                checked={this.state.barge_pur_coal_costing.includes(
                                                  "388"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_coal_costing: [
                                                        ...this.state
                                                          .barge_pur_coal_costing,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_coal_costing;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_coal_costing:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="439"
                                                color="primary"
                                                checked={this.state.barge_pur_coal_costing.includes(
                                                  "439"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_coal_costing: [
                                                        ...this.state
                                                          .barge_pur_coal_costing,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_coal_costing;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_coal_costing:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="365"
                                                color="primary"
                                                checked={this.state.barge_pur_coal_costing.includes(
                                                  "365"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_coal_costing: [
                                                        ...this.state
                                                          .barge_pur_coal_costing,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_coal_costing;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_coal_costing:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Files
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>
                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Coal Costing Payments
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="130,131,132,133,389,440"
                                                color="primary"
                                                checked={
                                                  this.state.barge_pur_coal_costing_payments.includes(
                                                    "130"
                                                  ) &&
                                                  this.state.barge_pur_coal_costing_payments.includes(
                                                    "131"
                                                  ) &&
                                                  this.state.barge_pur_coal_costing_payments.includes(
                                                    "132"
                                                  ) &&
                                                  this.state.barge_pur_coal_costing_payments.includes(
                                                    "133"
                                                  ) &&
                                                  this.state.barge_pur_coal_costing_payments.includes(
                                                    "389"
                                                  ) &&
                                                  this.state.barge_pur_coal_costing_payments.includes(
                                                    "440"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_coal_costing_payments:
                                                        [
                                                          "130",
                                                          "131",
                                                          "132",
                                                          "133",
                                                          "389",
                                                          "440",
                                                        ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      barge_pur_coal_costing_payments:
                                                        [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="130"
                                                color="primary"
                                                checked={this.state.barge_pur_coal_costing_payments.includes(
                                                  "130"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_coal_costing_payments:
                                                        [
                                                          ...this.state
                                                            .barge_pur_coal_costing_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_coal_costing_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_coal_costing_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="131"
                                                color="primary"
                                                checked={this.state.barge_pur_coal_costing_payments.includes(
                                                  "131"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_coal_costing_payments:
                                                        [
                                                          ...this.state
                                                            .barge_pur_coal_costing_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_coal_costing_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_coal_costing_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="132"
                                                color="primary"
                                                checked={this.state.barge_pur_coal_costing_payments.includes(
                                                  "132"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_coal_costing_payments:
                                                        [
                                                          ...this.state
                                                            .barge_pur_coal_costing_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_coal_costing_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_coal_costing_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="133"
                                                color="primary"
                                                checked={this.state.barge_pur_coal_costing_payments.includes(
                                                  "133"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_coal_costing_payments:
                                                        [
                                                          ...this.state
                                                            .barge_pur_coal_costing_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_coal_costing_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_coal_costing_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="389"
                                                color="primary"
                                                checked={this.state.barge_pur_coal_costing_payments.includes(
                                                  "389"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_coal_costing_payments:
                                                        [
                                                          ...this.state
                                                            .barge_pur_coal_costing_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_coal_costing_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_coal_costing_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="440"
                                                color="primary"
                                                checked={this.state.barge_pur_coal_costing_payments.includes(
                                                  "440"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_coal_costing_payments:
                                                        [
                                                          ...this.state
                                                            .barge_pur_coal_costing_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_coal_costing_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_coal_costing_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>

                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Barge Costing
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="134,135,136,137,373,390,441"
                                                color="primary"
                                                checked={
                                                  this.state.barge_pur_barge_costing.includes(
                                                    "134"
                                                  ) &&
                                                  this.state.barge_pur_barge_costing.includes(
                                                    "135"
                                                  ) &&
                                                  this.state.barge_pur_barge_costing.includes(
                                                    "136"
                                                  ) &&
                                                  this.state.barge_pur_barge_costing.includes(
                                                    "137"
                                                  ) &&
                                                  this.state.barge_pur_barge_costing.includes(
                                                    "373"
                                                  ) &&
                                                  this.state.barge_pur_barge_costing.includes(
                                                    "390"
                                                  ) &&
                                                  this.state.barge_pur_barge_costing.includes(
                                                    "441"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_barge_costing: [
                                                        "134",
                                                        "135",
                                                        "136",
                                                        "137",
                                                        "373",
                                                        "390",
                                                        "441",
                                                      ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      barge_pur_barge_costing:
                                                        [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="134"
                                                color="primary"
                                                checked={this.state.barge_pur_barge_costing.includes(
                                                  "134"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_barge_costing: [
                                                        ...this.state
                                                          .barge_pur_barge_costing,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_barge_costing;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_barge_costing:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="135"
                                                color="primary"
                                                checked={this.state.barge_pur_barge_costing.includes(
                                                  "135"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_barge_costing: [
                                                        ...this.state
                                                          .barge_pur_barge_costing,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_barge_costing;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_barge_costing:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="136"
                                                color="primary"
                                                checked={this.state.barge_pur_barge_costing.includes(
                                                  "136"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_barge_costing: [
                                                        ...this.state
                                                          .barge_pur_barge_costing,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_barge_costing;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_barge_costing:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="137"
                                                color="primary"
                                                checked={this.state.barge_pur_barge_costing.includes(
                                                  "137"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_barge_costing: [
                                                        ...this.state
                                                          .barge_pur_barge_costing,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_barge_costing;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_barge_costing:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="390"
                                                color="primary"
                                                checked={this.state.barge_pur_barge_costing.includes(
                                                  "390"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_barge_costing: [
                                                        ...this.state
                                                          .barge_pur_barge_costing,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_barge_costing;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_barge_costing:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="441"
                                                color="primary"
                                                checked={this.state.barge_pur_barge_costing.includes(
                                                  "441"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_barge_costing: [
                                                        ...this.state
                                                          .barge_pur_barge_costing,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_barge_costing;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_barge_costing:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="373"
                                                color="primary"
                                                checked={this.state.barge_pur_barge_costing.includes(
                                                  "373"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_barge_costing: [
                                                        ...this.state
                                                          .barge_pur_barge_costing,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_barge_costing;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_barge_costing:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Files
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>

                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Barge Costing Payments
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="138,139,140,141"
                                                color="primary"
                                                checked={
                                                  this.state.barge_pur_barge_costing_payments.includes(
                                                    "138"
                                                  ) &&
                                                  this.state.barge_pur_barge_costing_payments.includes(
                                                    "139"
                                                  ) &&
                                                  this.state.barge_pur_barge_costing_payments.includes(
                                                    "140"
                                                  ) &&
                                                  this.state.barge_pur_barge_costing_payments.includes(
                                                    "141"
                                                  ) &&
                                                  this.state.barge_pur_barge_costing_payments.includes(
                                                    "391"
                                                  ) &&
                                                  this.state.barge_pur_barge_costing_payments.includes(
                                                    "442"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_barge_costing_payments:
                                                        [
                                                          "138",
                                                          "139",
                                                          "140",
                                                          "141",
                                                          "391",
                                                          "442",
                                                        ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      barge_pur_barge_costing_payments:
                                                        [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="138"
                                                color="primary"
                                                checked={this.state.barge_pur_barge_costing_payments.includes(
                                                  "138"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_barge_costing_payments:
                                                        [
                                                          ...this.state
                                                            .barge_pur_barge_costing_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_barge_costing_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_barge_costing_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="139"
                                                color="primary"
                                                checked={this.state.barge_pur_barge_costing_payments.includes(
                                                  "139"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_barge_costing_payments:
                                                        [
                                                          ...this.state
                                                            .barge_pur_barge_costing_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_barge_costing_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_barge_costing_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="140"
                                                color="primary"
                                                checked={this.state.barge_pur_barge_costing_payments.includes(
                                                  "140"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_barge_costing_payments:
                                                        [
                                                          ...this.state
                                                            .barge_pur_barge_costing_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_barge_costing_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_barge_costing_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="141"
                                                color="primary"
                                                checked={this.state.barge_pur_barge_costing.includes(
                                                  "141"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_barge_costing: [
                                                        ...this.state
                                                          .barge_pur_barge_costing,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_barge_costing;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_barge_costing:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="391"
                                                color="primary"
                                                checked={this.state.barge_pur_barge_costing_payments.includes(
                                                  "391"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_barge_costing_payments:
                                                        [
                                                          ...this.state
                                                            .barge_pur_barge_costing_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_barge_costing_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_barge_costing_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="442"
                                                color="primary"
                                                checked={this.state.barge_pur_barge_costing_payments.includes(
                                                  "442"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_barge_costing_payments:
                                                        [
                                                          ...this.state
                                                            .barge_pur_barge_costing_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_barge_costing_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_barge_costing_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>

                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Chemical Spray
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="575,576,577,578,579,580,581"
                                                color="primary"
                                                checked={
                                                  this.state.chemical_spray.includes(
                                                    "575"
                                                  ) &&
                                                  this.state.chemical_spray.includes(
                                                    "576"
                                                  ) &&
                                                  this.state.chemical_spray.includes(
                                                    "577"
                                                  ) &&
                                                  this.state.chemical_spray.includes(
                                                    "578"
                                                  ) &&
                                                  this.state.chemical_spray.includes(
                                                    "579"
                                                  ) &&
                                                  this.state.chemical_spray.includes(
                                                    "580"
                                                  ) &&
                                                  this.state.chemical_spray.includes(
                                                    "581"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      chemical_spray: [
                                                        "575",
                                                        "576",
                                                        "577",
                                                        "578",
                                                        "579",
                                                        "580",
                                                        "581",
                                                      ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      chemical_spray: [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="575"
                                                color="primary"
                                                checked={this.state.chemical_spray.includes(
                                                  "575"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      chemical_spray: [
                                                        ...this.state
                                                          .chemical_spray,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.chemical_spray;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      chemical_spray: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="576"
                                                color="primary"
                                                checked={this.state.chemical_spray.includes(
                                                  "576"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      chemical_spray: [
                                                        ...this.state
                                                          .chemical_spray,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.chemical_spray;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      chemical_spray: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="577"
                                                color="primary"
                                                checked={this.state.chemical_spray.includes(
                                                  "577"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      chemical_spray: [
                                                        ...this.state
                                                          .chemical_spray,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.chemical_spray;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      chemical_spray: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="578"
                                                color="primary"
                                                checked={this.state.chemical_spray.includes(
                                                  "578"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      chemical_spray: [
                                                        ...this.state
                                                          .chemical_spray,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.chemical_spray;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      chemical_spray: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="579"
                                                color="primary"
                                                checked={this.state.chemical_spray.includes(
                                                  "579"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      chemical_spray: [
                                                        ...this.state
                                                          .chemical_spray,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.chemical_spray;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      chemical_spray: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="580"
                                                color="primary"
                                                checked={this.state.chemical_spray.includes(
                                                  "580"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      chemical_spray: [
                                                        ...this.state
                                                          .chemical_spray,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.chemical_spray;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      chemical_spray: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="581"
                                                color="primary"
                                                checked={this.state.chemical_spray.includes(
                                                  "581"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      chemical_spray: [
                                                        ...this.state
                                                          .chemical_spray,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.chemical_spray;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      chemical_spray: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Files
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>

                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Chemical Spray Payments
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="582,583,584,585,586,587"
                                                color="primary"
                                                checked={
                                                  this.state.chemical_spray_payments.includes(
                                                    "582"
                                                  ) &&
                                                  this.state.chemical_spray_payments.includes(
                                                    "583"
                                                  ) &&
                                                  this.state.chemical_spray_payments.includes(
                                                    "584"
                                                  ) &&
                                                  this.state.chemical_spray_payments.includes(
                                                    "585"
                                                  ) &&
                                                  this.state.chemical_spray_payments.includes(
                                                    "586"
                                                  ) &&
                                                  this.state.chemical_spray_payments.includes(
                                                    "587"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      chemical_spray_payments: [
                                                        "582",
                                                        "583",
                                                        "584",
                                                        "585",
                                                        "586",
                                                        "587",
                                                      ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      chemical_spray_payments:
                                                        [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="582"
                                                color="primary"
                                                checked={this.state.chemical_spray_payments.includes(
                                                  "582"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      chemical_spray_payments: [
                                                        ...this.state
                                                          .chemical_spray_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .chemical_spray_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      chemical_spray_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="583"
                                                color="primary"
                                                checked={this.state.chemical_spray_payments.includes(
                                                  "583"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      chemical_spray_payments: [
                                                        ...this.state
                                                          .chemical_spray_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .chemical_spray_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      chemical_spray_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="584"
                                                color="primary"
                                                checked={this.state.chemical_spray_payments.includes(
                                                  "584"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      chemical_spray_payments: [
                                                        ...this.state
                                                          .chemical_spray_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .chemical_spray_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      chemical_spray_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="585"
                                                color="primary"
                                                checked={this.state.chemical_spray_payments.includes(
                                                  "585"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      chemical_spray_payments: [
                                                        ...this.state
                                                          .chemical_spray_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .chemical_spray_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      chemical_spray_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="586"
                                                color="primary"
                                                checked={this.state.chemical_spray_payments.includes(
                                                  "586"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      chemical_spray_payments: [
                                                        ...this.state
                                                          .chemical_spray_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .chemical_spray_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      chemical_spray_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="587"
                                                color="primary"
                                                checked={this.state.chemical_spray_payments.includes(
                                                  "587"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      chemical_spray_payments: [
                                                        ...this.state
                                                          .chemical_spray_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .chemical_spray_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      chemical_spray_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>

                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Coal Procurement Charges
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="142,143,144,145,371,392,443"
                                                color="primary"
                                                checked={
                                                  this.state.barge_pur_cpc.includes(
                                                    "142"
                                                  ) &&
                                                  this.state.barge_pur_cpc.includes(
                                                    "143"
                                                  ) &&
                                                  this.state.barge_pur_cpc.includes(
                                                    "144"
                                                  ) &&
                                                  this.state.barge_pur_cpc.includes(
                                                    "145"
                                                  ) &&
                                                  this.state.barge_pur_cpc.includes(
                                                    "371"
                                                  ) &&
                                                  this.state.barge_pur_cpc.includes(
                                                    "392"
                                                  ) &&
                                                  this.state.barge_pur_cpc.includes(
                                                    "443"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_cpc: [
                                                        "142",
                                                        "143",
                                                        "144",
                                                        "145",
                                                        "371",
                                                        "392",
                                                        "443",
                                                      ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      barge_pur_cpc: [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="142"
                                                color="primary"
                                                checked={this.state.barge_pur_cpc.includes(
                                                  "142"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_cpc: [
                                                        ...this.state
                                                          .barge_pur_cpc,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.barge_pur_cpc;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_cpc: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="143"
                                                color="primary"
                                                checked={this.state.barge_pur_cpc.includes(
                                                  "143"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_cpc: [
                                                        ...this.state
                                                          .barge_pur_cpc,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.barge_pur_cpc;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_cpc: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="144"
                                                color="primary"
                                                checked={this.state.barge_pur_cpc.includes(
                                                  "144"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_cpc: [
                                                        ...this.state
                                                          .barge_pur_cpc,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.barge_pur_cpc;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_cpc: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="145"
                                                color="primary"
                                                checked={this.state.barge_pur_cpc.includes(
                                                  "145"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_cpc: [
                                                        ...this.state
                                                          .barge_pur_cpc,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.barge_pur_cpc;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_cpc: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="392"
                                                color="primary"
                                                checked={this.state.barge_pur_cpc.includes(
                                                  "392"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_cpc: [
                                                        ...this.state
                                                          .barge_pur_cpc,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.barge_pur_cpc;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_cpc: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="443"
                                                color="primary"
                                                checked={this.state.barge_pur_cpc.includes(
                                                  "443"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_cpc: [
                                                        ...this.state
                                                          .barge_pur_cpc,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.barge_pur_cpc;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_cpc: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="371"
                                                color="primary"
                                                checked={this.state.barge_pur_cpc.includes(
                                                  "371"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_cpc: [
                                                        ...this.state
                                                          .barge_pur_cpc,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.barge_pur_cpc;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_cpc: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Files
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>

                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Coal Procurement Payments
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="146,147,148,149"
                                                color="primary"
                                                checked={
                                                  this.state.barge_pur_cpc_payments.includes(
                                                    "146"
                                                  ) &&
                                                  this.state.barge_pur_cpc_payments.includes(
                                                    "147"
                                                  ) &&
                                                  this.state.barge_pur_cpc_payments.includes(
                                                    "148"
                                                  ) &&
                                                  this.state.barge_pur_cpc_payments.includes(
                                                    "149"
                                                  ) &&
                                                  this.state.barge_pur_cpc_payments.includes(
                                                    "393"
                                                  ) &&
                                                  this.state.barge_pur_cpc_payments.includes(
                                                    "444"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_cpc_payments: [
                                                        "146",
                                                        "147",
                                                        "148",
                                                        "149",
                                                        "393",
                                                        "444",
                                                      ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      barge_pur_cpc_payments:
                                                        [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="146"
                                                color="primary"
                                                checked={this.state.barge_pur_cpc_payments.includes(
                                                  "146"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_cpc_payments: [
                                                        ...this.state
                                                          .barge_pur_cpc_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_cpc_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_cpc_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="147"
                                                color="primary"
                                                checked={this.state.barge_pur_cpc_payments.includes(
                                                  "147"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_cpc_payments: [
                                                        ...this.state
                                                          .barge_pur_cpc_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_cpc_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_cpc_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="148"
                                                color="primary"
                                                checked={this.state.barge_pur_cpc_payments.includes(
                                                  "148"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_cpc_payments: [
                                                        ...this.state
                                                          .barge_pur_cpc_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_cpc_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_cpc_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="149"
                                                color="primary"
                                                checked={this.state.barge_pur_cpc_payments.includes(
                                                  "149"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_cpc_payments: [
                                                        ...this.state
                                                          .barge_pur_cpc_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_cpc_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_cpc_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="393"
                                                color="primary"
                                                checked={this.state.barge_pur_cpc_payments.includes(
                                                  "393"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_cpc_payments: [
                                                        ...this.state
                                                          .barge_pur_cpc_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_cpc_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_cpc_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="444"
                                                color="primary"
                                                checked={this.state.barge_pur_cpc_payments.includes(
                                                  "444"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_cpc_payments: [
                                                        ...this.state
                                                          .barge_pur_cpc_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_cpc_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_cpc_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>

                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Coal Procurement Service
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="506,507,508,509,514,516,518"
                                                color="primary"
                                                checked={
                                                  this.state.barge_pur_cps.includes(
                                                    "506"
                                                  ) &&
                                                  this.state.barge_pur_cps.includes(
                                                    "507"
                                                  ) &&
                                                  this.state.barge_pur_cps.includes(
                                                    "508"
                                                  ) &&
                                                  this.state.barge_pur_cps.includes(
                                                    "509"
                                                  ) &&
                                                  this.state.barge_pur_cps.includes(
                                                    "514"
                                                  ) &&
                                                  this.state.barge_pur_cps.includes(
                                                    "516"
                                                  ) &&
                                                  this.state.barge_pur_cps.includes(
                                                    "518"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_cps: [
                                                        "506",
                                                        "507",
                                                        "508",
                                                        "509",
                                                        "514",
                                                        "516",
                                                        "518",
                                                      ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      barge_pur_cps: [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="506"
                                                color="primary"
                                                checked={this.state.barge_pur_cps.includes(
                                                  "506"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_cps: [
                                                        ...this.state
                                                          .barge_pur_cps,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.barge_pur_cps;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_cps: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="507"
                                                color="primary"
                                                checked={this.state.barge_pur_cps.includes(
                                                  "507"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_cps: [
                                                        ...this.state
                                                          .barge_pur_cps,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.barge_pur_cps;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_cps: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="508"
                                                color="primary"
                                                checked={this.state.barge_pur_cps.includes(
                                                  "508"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_cps: [
                                                        ...this.state
                                                          .barge_pur_cps,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.barge_pur_cps;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_cps: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="509"
                                                color="primary"
                                                checked={this.state.barge_pur_cps.includes(
                                                  "509"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_cps: [
                                                        ...this.state
                                                          .barge_pur_cps,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.barge_pur_cps;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_cps: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="514"
                                                color="primary"
                                                checked={this.state.barge_pur_cps.includes(
                                                  "514"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_cps: [
                                                        ...this.state
                                                          .barge_pur_cps,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.barge_pur_cps;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_cps: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="516"
                                                color="primary"
                                                checked={this.state.barge_pur_cps.includes(
                                                  "516"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_cps: [
                                                        ...this.state
                                                          .barge_pur_cps,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.barge_pur_cps;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_cps: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="518"
                                                color="primary"
                                                checked={this.state.barge_pur_cps.includes(
                                                  "518"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_cps: [
                                                        ...this.state
                                                          .barge_pur_cps,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.barge_pur_cps;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_cps: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Files
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>

                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Coal Procurement Service Payments
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="510,511,512,513"
                                                color="primary"
                                                checked={
                                                  this.state.barge_pur_cps_payments.includes(
                                                    "510"
                                                  ) &&
                                                  this.state.barge_pur_cps_payments.includes(
                                                    "511"
                                                  ) &&
                                                  this.state.barge_pur_cps_payments.includes(
                                                    "512"
                                                  ) &&
                                                  this.state.barge_pur_cps_payments.includes(
                                                    "513"
                                                  ) &&
                                                  this.state.barge_pur_cps_payments.includes(
                                                    "515"
                                                  ) &&
                                                  this.state.barge_pur_cps_payments.includes(
                                                    "517"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_cps_payments: [
                                                        "510",
                                                        "511",
                                                        "512",
                                                        "513",
                                                        "515",
                                                        "517",
                                                      ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      barge_pur_cps_payments:
                                                        [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="510"
                                                color="primary"
                                                checked={this.state.barge_pur_cps_payments.includes(
                                                  "510"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_cps_payments: [
                                                        ...this.state
                                                          .barge_pur_cps_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_cps_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_cps_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="511"
                                                color="primary"
                                                checked={this.state.barge_pur_cps_payments.includes(
                                                  "511"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_cps_payments: [
                                                        ...this.state
                                                          .barge_pur_cps_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_cps_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_cps_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="512"
                                                color="primary"
                                                checked={this.state.barge_pur_cps_payments.includes(
                                                  "512"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_cps_payments: [
                                                        ...this.state
                                                          .barge_pur_cps_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_cps_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_cps_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="513"
                                                color="primary"
                                                checked={this.state.barge_pur_cps_payments.includes(
                                                  "513"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_cps_payments: [
                                                        ...this.state
                                                          .barge_pur_cps_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_cps_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_cps_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="515"
                                                color="primary"
                                                checked={this.state.barge_pur_cps_payments.includes(
                                                  "515"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_cps_payments: [
                                                        ...this.state
                                                          .barge_pur_cps_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_cps_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_cps_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="517"
                                                color="primary"
                                                checked={this.state.barge_pur_cps_payments.includes(
                                                  "517"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_cps_payments: [
                                                        ...this.state
                                                          .barge_pur_cps_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_cps_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_cps_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>

                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Sampling & Analysis Charges
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="158,159,160,161"
                                                color="primary"
                                                checked={
                                                  this.state.barge_pur_sac.includes(
                                                    "158"
                                                  ) &&
                                                  this.state.barge_pur_sac.includes(
                                                    "159"
                                                  ) &&
                                                  this.state.barge_pur_sac.includes(
                                                    "160"
                                                  ) &&
                                                  this.state.barge_pur_sac.includes(
                                                    "161"
                                                  ) &&
                                                  this.state.barge_pur_sac.includes(
                                                    "370"
                                                  ) &&
                                                  this.state.barge_pur_sac.includes(
                                                    "396"
                                                  ) &&
                                                  this.state.barge_pur_sac.includes(
                                                    "447"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_sac: [
                                                        "158",
                                                        "159",
                                                        "160",
                                                        "161",
                                                        "370",
                                                        "396",
                                                        "447",
                                                      ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      barge_pur_sac: [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="158"
                                                color="primary"
                                                checked={this.state.barge_pur_sac.includes(
                                                  "158"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_sac: [
                                                        ...this.state
                                                          .barge_pur_sac,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.barge_pur_sac;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_sac: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="159"
                                                color="primary"
                                                checked={this.state.barge_pur_sac.includes(
                                                  "159"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_sac: [
                                                        ...this.state
                                                          .barge_pur_sac,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.barge_pur_sac;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_sac: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="160"
                                                color="primary"
                                                checked={this.state.barge_pur_sac.includes(
                                                  "160"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_sac: [
                                                        ...this.state
                                                          .barge_pur_sac,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.barge_pur_sac;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_sac: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="161"
                                                color="primary"
                                                checked={this.state.barge_pur_sac.includes(
                                                  "161"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_sac: [
                                                        ...this.state
                                                          .barge_pur_sac,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.barge_pur_sac;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_sac: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="396"
                                                color="primary"
                                                checked={this.state.barge_pur_sac.includes(
                                                  "396"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_sac: [
                                                        ...this.state
                                                          .barge_pur_sac,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.barge_pur_sac;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_sac: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="447"
                                                color="primary"
                                                checked={this.state.barge_pur_sac.includes(
                                                  "447"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_sac: [
                                                        ...this.state
                                                          .barge_pur_sac,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.barge_pur_sac;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_sac: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="370"
                                                color="primary"
                                                checked={this.state.barge_pur_sac.includes(
                                                  "370"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_sac: [
                                                        ...this.state
                                                          .barge_pur_sac,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.barge_pur_sac;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_sac: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Files
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>

                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Sampling & Analysis Payments
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="162,163,164,165"
                                                color="primary"
                                                checked={
                                                  this.state.barge_pur_sac_payments.includes(
                                                    "162"
                                                  ) &&
                                                  this.state.barge_pur_sac_payments.includes(
                                                    "163"
                                                  ) &&
                                                  this.state.barge_pur_sac_payments.includes(
                                                    "164"
                                                  ) &&
                                                  this.state.barge_pur_sac_payments.includes(
                                                    "165"
                                                  ) &&
                                                  this.state.barge_pur_sac_payments.includes(
                                                    "397"
                                                  ) &&
                                                  this.state.barge_pur_sac_payments.includes(
                                                    "448"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_sac_payments: [
                                                        "162",
                                                        "163",
                                                        "164",
                                                        "165",
                                                        "397",
                                                        "448",
                                                      ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      barge_pur_sac_payments:
                                                        [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="162"
                                                color="primary"
                                                checked={this.state.barge_pur_sac_payments.includes(
                                                  "162"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_sac_payments: [
                                                        ...this.state
                                                          .barge_pur_sac_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_sac_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_sac_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="163"
                                                color="primary"
                                                checked={this.state.barge_pur_sac_payments.includes(
                                                  "163"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_sac_payments: [
                                                        ...this.state
                                                          .barge_pur_sac_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_sac_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_sac_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="164"
                                                color="primary"
                                                checked={this.state.barge_pur_sac_payments.includes(
                                                  "164"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_sac_payments: [
                                                        ...this.state
                                                          .barge_pur_sac_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_sac_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_sac_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="165"
                                                color="primary"
                                                checked={this.state.barge_pur_sac_payments.includes(
                                                  "165"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_sac_payments: [
                                                        ...this.state
                                                          .barge_pur_sac_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_sac_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_sac_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="397"
                                                color="primary"
                                                checked={this.state.barge_pur_sac_payments.includes(
                                                  "397"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_sac_payments: [
                                                        ...this.state
                                                          .barge_pur_sac_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_sac_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_sac_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="448"
                                                color="primary"
                                                checked={this.state.barge_pur_sac_payments.includes(
                                                  "448"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_sac_payments: [
                                                        ...this.state
                                                          .barge_pur_sac_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_sac_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_sac_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>

                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Pre-Shipment Inspection Charges
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="166,167,168,169"
                                                color="primary"
                                                checked={
                                                  this.state.barge_pur_pre_shipmet_inspection.includes(
                                                    "166"
                                                  ) &&
                                                  this.state.barge_pur_pre_shipmet_inspection.includes(
                                                    "167"
                                                  ) &&
                                                  this.state.barge_pur_pre_shipmet_inspection.includes(
                                                    "168"
                                                  ) &&
                                                  this.state.barge_pur_pre_shipmet_inspection.includes(
                                                    "169"
                                                  ) &&
                                                  this.state.barge_pur_pre_shipmet_inspection.includes(
                                                    "372"
                                                  ) &&
                                                  this.state.barge_pur_pre_shipmet_inspection.includes(
                                                    "398"
                                                  ) &&
                                                  this.state.barge_pur_pre_shipmet_inspection.includes(
                                                    "449"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_pre_shipmet_inspection:
                                                        [
                                                          "166",
                                                          "167",
                                                          "168",
                                                          "169",
                                                          "372",
                                                          "398",
                                                          "449",
                                                        ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      barge_pur_pre_shipmet_inspection:
                                                        [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="166"
                                                color="primary"
                                                checked={this.state.barge_pur_pre_shipmet_inspection.includes(
                                                  "166"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_pre_shipmet_inspection:
                                                        [
                                                          ...this.state
                                                            .barge_pur_pre_shipmet_inspection,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_pre_shipmet_inspection;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_pre_shipmet_inspection:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="167"
                                                color="primary"
                                                checked={this.state.barge_pur_pre_shipmet_inspection.includes(
                                                  "167"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_pre_shipmet_inspection:
                                                        [
                                                          ...this.state
                                                            .barge_pur_pre_shipmet_inspection,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_pre_shipmet_inspection;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_pre_shipmet_inspection:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="168"
                                                color="primary"
                                                checked={this.state.barge_pur_pre_shipmet_inspection.includes(
                                                  "168"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_pre_shipmet_inspection:
                                                        [
                                                          ...this.state
                                                            .barge_pur_pre_shipmet_inspection,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_pre_shipmet_inspection;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_pre_shipmet_inspection:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="169"
                                                color="primary"
                                                checked={this.state.barge_pur_pre_shipmet_inspection.includes(
                                                  "169"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_pre_shipmet_inspection:
                                                        [
                                                          ...this.state
                                                            .barge_pur_pre_shipmet_inspection,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_pre_shipmet_inspection;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_pre_shipmet_inspection:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="398"
                                                color="primary"
                                                checked={this.state.barge_pur_pre_shipmet_inspection.includes(
                                                  "398"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_pre_shipmet_inspection:
                                                        [
                                                          ...this.state
                                                            .barge_pur_pre_shipmet_inspection,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_pre_shipmet_inspection;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_pre_shipmet_inspection:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="449"
                                                color="primary"
                                                checked={this.state.barge_pur_pre_shipmet_inspection.includes(
                                                  "449"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_pre_shipmet_inspection:
                                                        [
                                                          ...this.state
                                                            .barge_pur_pre_shipmet_inspection,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_pre_shipmet_inspection;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_pre_shipmet_inspection:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="372"
                                                color="primary"
                                                checked={this.state.barge_pur_pre_shipmet_inspection.includes(
                                                  "372"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_pre_shipmet_inspection:
                                                        [
                                                          ...this.state
                                                            .barge_pur_pre_shipmet_inspection,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_pre_shipmet_inspection;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_pre_shipmet_inspection:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Files
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>

                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Pre-Shipment Inspection Payments
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="170,171,172,173"
                                                color="primary"
                                                checked={
                                                  this.state.barge_pur_pre_shipmet_inspection_payments.includes(
                                                    "170"
                                                  ) &&
                                                  this.state.barge_pur_pre_shipmet_inspection_payments.includes(
                                                    "171"
                                                  ) &&
                                                  this.state.barge_pur_pre_shipmet_inspection_payments.includes(
                                                    "172"
                                                  ) &&
                                                  this.state.barge_pur_pre_shipmet_inspection_payments.includes(
                                                    "173"
                                                  ) &&
                                                  this.state.barge_pur_pre_shipmet_inspection_payments.includes(
                                                    "399"
                                                  ) &&
                                                  this.state.barge_pur_pre_shipmet_inspection_payments.includes(
                                                    "450"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_pre_shipmet_inspection_payments:
                                                        [
                                                          "170",
                                                          "171",
                                                          "172",
                                                          "173",
                                                          "399",
                                                          "450",
                                                        ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      barge_pur_pre_shipmet_inspection_payments:
                                                        [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="170"
                                                color="primary"
                                                checked={this.state.barge_pur_pre_shipmet_inspection_payments.includes(
                                                  "170"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_pre_shipmet_inspection_payments:
                                                        [
                                                          ...this.state
                                                            .barge_pur_pre_shipmet_inspection_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_pre_shipmet_inspection_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_pre_shipmet_inspection_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="171"
                                                color="primary"
                                                checked={this.state.barge_pur_pre_shipmet_inspection_payments.includes(
                                                  "171"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_pre_shipmet_inspection_payments:
                                                        [
                                                          ...this.state
                                                            .barge_pur_pre_shipmet_inspection_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_pre_shipmet_inspection_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_pre_shipmet_inspection_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="172"
                                                color="primary"
                                                checked={this.state.barge_pur_pre_shipmet_inspection_payments.includes(
                                                  "172"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_pre_shipmet_inspection_payments:
                                                        [
                                                          ...this.state
                                                            .barge_pur_pre_shipmet_inspection_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_pre_shipmet_inspection_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_pre_shipmet_inspection_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="173"
                                                color="primary"
                                                checked={this.state.barge_pur_pre_shipmet_inspection_payments.includes(
                                                  "173"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_pre_shipmet_inspection_payments:
                                                        [
                                                          ...this.state
                                                            .barge_pur_pre_shipmet_inspection_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_pre_shipmet_inspection_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_pre_shipmet_inspection_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="399"
                                                color="primary"
                                                checked={this.state.barge_pur_pre_shipmet_inspection_payments.includes(
                                                  "399"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_pre_shipmet_inspection_payments:
                                                        [
                                                          ...this.state
                                                            .barge_pur_pre_shipmet_inspection_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_pre_shipmet_inspection_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_pre_shipmet_inspection_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="450"
                                                color="primary"
                                                checked={this.state.barge_pur_pre_shipmet_inspection_payments.includes(
                                                  "450"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_pre_shipmet_inspection_payments:
                                                        [
                                                          ...this.state
                                                            .barge_pur_pre_shipmet_inspection_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_pre_shipmet_inspection_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_pre_shipmet_inspection_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>

                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Export Tax Costing
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="174,175,176,177"
                                                color="primary"
                                                checked={
                                                  this.state.barge_pur_export_tax.includes(
                                                    "174"
                                                  ) &&
                                                  this.state.barge_pur_export_tax.includes(
                                                    "175"
                                                  ) &&
                                                  this.state.barge_pur_export_tax.includes(
                                                    "176"
                                                  ) &&
                                                  this.state.barge_pur_export_tax.includes(
                                                    "177"
                                                  ) &&
                                                  this.state.barge_pur_export_tax.includes(
                                                    "369"
                                                  ) &&
                                                  this.state.barge_pur_export_tax.includes(
                                                    "400"
                                                  ) &&
                                                  this.state.barge_pur_export_tax.includes(
                                                    "451"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_export_tax: [
                                                        "174",
                                                        "175",
                                                        "176",
                                                        "177",
                                                        "369",
                                                        "400",
                                                        "451",
                                                      ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      barge_pur_export_tax: [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="174"
                                                color="primary"
                                                checked={this.state.barge_pur_export_tax.includes(
                                                  "174"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_export_tax: [
                                                        ...this.state
                                                          .barge_pur_export_tax,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_export_tax;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_export_tax:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="175"
                                                color="primary"
                                                checked={this.state.barge_pur_export_tax.includes(
                                                  "175"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_export_tax: [
                                                        ...this.state
                                                          .barge_pur_export_tax,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_export_tax;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_export_tax:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="176"
                                                color="primary"
                                                checked={this.state.barge_pur_export_tax.includes(
                                                  "176"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_export_tax: [
                                                        ...this.state
                                                          .barge_pur_export_tax,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_export_tax;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_export_tax:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="177"
                                                color="primary"
                                                checked={this.state.barge_pur_export_tax.includes(
                                                  "177"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_export_tax: [
                                                        ...this.state
                                                          .barge_pur_export_tax,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_export_tax;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_export_tax:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="400"
                                                color="primary"
                                                checked={this.state.barge_pur_export_tax.includes(
                                                  "400"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_export_tax: [
                                                        ...this.state
                                                          .barge_pur_export_tax,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_export_tax;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_export_tax:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="451"
                                                color="primary"
                                                checked={this.state.barge_pur_export_tax.includes(
                                                  "451"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_export_tax: [
                                                        ...this.state
                                                          .barge_pur_export_tax,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_export_tax;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_export_tax:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="369"
                                                color="primary"
                                                checked={this.state.barge_pur_export_tax.includes(
                                                  "369"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_export_tax: [
                                                        ...this.state
                                                          .barge_pur_export_tax,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_export_tax;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_export_tax:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Files
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>

                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Export Tax Payments
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="178,179,180,181"
                                                color="primary"
                                                checked={
                                                  this.state.barge_pur_export_tax_payments.includes(
                                                    "178"
                                                  ) &&
                                                  this.state.barge_pur_export_tax_payments.includes(
                                                    "179"
                                                  ) &&
                                                  this.state.barge_pur_export_tax_payments.includes(
                                                    "180"
                                                  ) &&
                                                  this.state.barge_pur_export_tax_payments.includes(
                                                    "181"
                                                  ) &&
                                                  this.state.barge_pur_export_tax_payments.includes(
                                                    "401"
                                                  ) &&
                                                  this.state.barge_pur_export_tax_payments.includes(
                                                    "452"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_export_tax_payments:
                                                        [
                                                          "178",
                                                          "179",
                                                          "180",
                                                          "181",
                                                          "401",
                                                          "452",
                                                        ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      barge_pur_export_tax_payments:
                                                        [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="178"
                                                color="primary"
                                                checked={this.state.barge_pur_export_tax_payments.includes(
                                                  "178"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_export_tax_payments:
                                                        [
                                                          ...this.state
                                                            .barge_pur_export_tax_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_export_tax_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_export_tax_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="179"
                                                color="primary"
                                                checked={this.state.barge_pur_export_tax_payments.includes(
                                                  "179"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_export_tax_payments:
                                                        [
                                                          ...this.state
                                                            .barge_pur_export_tax_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_export_tax_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_export_tax_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="180"
                                                color="primary"
                                                checked={this.state.barge_pur_export_tax_payments.includes(
                                                  "180"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_export_tax_payments:
                                                        [
                                                          ...this.state
                                                            .barge_pur_export_tax_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_export_tax_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_export_tax_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="181"
                                                color="primary"
                                                checked={this.state.barge_pur_export_tax_payments.includes(
                                                  "181"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_export_tax_payments:
                                                        [
                                                          ...this.state
                                                            .barge_pur_export_tax_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_export_tax_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_export_tax_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="401"
                                                color="primary"
                                                checked={this.state.barge_pur_export_tax_payments.includes(
                                                  "401"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_export_tax_payments:
                                                        [
                                                          ...this.state
                                                            .barge_pur_export_tax_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_export_tax_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_export_tax_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="452"
                                                color="primary"
                                                checked={this.state.barge_pur_export_tax_payments.includes(
                                                  "452"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_export_tax_payments:
                                                        [
                                                          ...this.state
                                                            .barge_pur_export_tax_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_export_tax_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_export_tax_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>

                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Documentation Charges
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="182,183,184,185"
                                                color="primary"
                                                checked={
                                                  this.state.barge_pur_doc.includes(
                                                    "182"
                                                  ) &&
                                                  this.state.barge_pur_doc.includes(
                                                    "183"
                                                  ) &&
                                                  this.state.barge_pur_doc.includes(
                                                    "184"
                                                  ) &&
                                                  this.state.barge_pur_doc.includes(
                                                    "185"
                                                  ) &&
                                                  this.state.barge_pur_doc.includes(
                                                    "368"
                                                  ) &&
                                                  this.state.barge_pur_doc.includes(
                                                    "402"
                                                  ) &&
                                                  this.state.barge_pur_doc.includes(
                                                    "453"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_doc: [
                                                        "182",
                                                        "183",
                                                        "184",
                                                        "185",
                                                        "368",
                                                        "402",
                                                        "453",
                                                      ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      barge_pur_doc: [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="182"
                                                color="primary"
                                                checked={this.state.barge_pur_doc.includes(
                                                  "182"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_doc: [
                                                        ...this.state
                                                          .barge_pur_doc,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.barge_pur_doc;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_doc: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="183"
                                                color="primary"
                                                checked={this.state.barge_pur_doc.includes(
                                                  "183"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_doc: [
                                                        ...this.state
                                                          .barge_pur_doc,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.barge_pur_doc;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_doc: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="184"
                                                color="primary"
                                                checked={this.state.barge_pur_doc.includes(
                                                  "184"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_doc: [
                                                        ...this.state
                                                          .barge_pur_doc,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.barge_pur_doc;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_doc: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="185"
                                                color="primary"
                                                checked={this.state.barge_pur_doc.includes(
                                                  "185"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_doc: [
                                                        ...this.state
                                                          .barge_pur_doc,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.barge_pur_doc;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_doc: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="402"
                                                color="primary"
                                                checked={this.state.barge_pur_doc.includes(
                                                  "402"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_doc: [
                                                        ...this.state
                                                          .barge_pur_doc,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.barge_pur_doc;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_doc: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="453"
                                                color="primary"
                                                checked={this.state.barge_pur_doc.includes(
                                                  "453"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_doc: [
                                                        ...this.state
                                                          .barge_pur_doc,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.barge_pur_doc;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_doc: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="368"
                                                color="primary"
                                                checked={this.state.barge_pur_doc.includes(
                                                  "368"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_doc: [
                                                        ...this.state
                                                          .barge_pur_doc,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.barge_pur_doc;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_doc: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Files
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>

                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Documentation Payments
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="186,187,188,189"
                                                color="primary"
                                                checked={
                                                  this.state.barge_pur_doc_payments.includes(
                                                    "186"
                                                  ) &&
                                                  this.state.barge_pur_doc_payments.includes(
                                                    "187"
                                                  ) &&
                                                  this.state.barge_pur_doc_payments.includes(
                                                    "188"
                                                  ) &&
                                                  this.state.barge_pur_doc_payments.includes(
                                                    "189"
                                                  ) &&
                                                  this.state.barge_pur_doc_payments.includes(
                                                    "403"
                                                  ) &&
                                                  this.state.barge_pur_doc_payments.includes(
                                                    "453"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_doc_payments: [
                                                        "186",
                                                        "187",
                                                        "188",
                                                        "189",
                                                        "403",
                                                        "453",
                                                      ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      barge_pur_doc_payments:
                                                        [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="186"
                                                color="primary"
                                                checked={this.state.barge_pur_doc_payments.includes(
                                                  "186"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_doc_payments: [
                                                        ...this.state
                                                          .barge_pur_doc_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_doc_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_doc_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="187"
                                                color="primary"
                                                checked={this.state.barge_pur_doc_payments.includes(
                                                  "187"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_doc_payments: [
                                                        ...this.state
                                                          .barge_pur_doc_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_doc_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_doc_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="188"
                                                color="primary"
                                                checked={this.state.barge_pur_doc_payments.includes(
                                                  "188"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_doc_payments: [
                                                        ...this.state
                                                          .barge_pur_doc_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_doc_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_doc_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="189"
                                                color="primary"
                                                checked={this.state.barge_pur_doc_payments.includes(
                                                  "189"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_doc_payments: [
                                                        ...this.state
                                                          .barge_pur_doc_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_doc_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_doc_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="403"
                                                color="primary"
                                                checked={this.state.barge_pur_doc_payments.includes(
                                                  "403"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_doc_payments: [
                                                        ...this.state
                                                          .barge_pur_doc_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_doc_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_doc_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="454"
                                                color="primary"
                                                checked={this.state.barge_pur_doc_payments.includes(
                                                  "454"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_doc_payments: [
                                                        ...this.state
                                                          .barge_pur_doc_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_doc_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_doc_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>

                                      {/**
                                       *
                                       *  Laparon Charges
                                       *
                                       */}

                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Laparon Charges
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="601,603,608,609,610,611"
                                                color="primary"
                                                checked={
                                                  this.state.barge_laprn_charges.includes(
                                                    "601"
                                                  ) &&
                                                  this.state.barge_laprn_charges.includes(
                                                    "603"
                                                  ) &&
                                                  this.state.barge_laprn_charges.includes(
                                                    "608"
                                                  ) &&
                                                  this.state.barge_laprn_charges.includes(
                                                    "609"
                                                  ) &&
                                                  this.state.barge_laprn_charges.includes(
                                                    "610"
                                                  ) &&
                                                  this.state.barge_laprn_charges.includes(
                                                    "611"
                                                  ) &&
                                                  this.state.barge_laprn_charges.includes(
                                                    "453"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_laprn_charges: [
                                                        "601",
                                                        "603",
                                                        "608",
                                                        "609",
                                                        "610",
                                                        "611",
                                                        "453",
                                                      ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      barge_laprn_charges: [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="611"
                                                color="primary"
                                                checked={this.state.barge_laprn_charges.includes(
                                                  "611"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_laprn_charges: [
                                                        ...this.state
                                                          .barge_laprn_charges,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_laprn_charges;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_laprn_charges: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="610"
                                                color="primary"
                                                checked={this.state.barge_laprn_charges.includes(
                                                  "183"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_laprn_charges: [
                                                        ...this.state
                                                          .barge_laprn_charges,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_laprn_charges;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_laprn_charges: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="609"
                                                color="primary"
                                                checked={this.state.barge_laprn_charges.includes(
                                                  "609"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_laprn_charges: [
                                                        ...this.state
                                                          .barge_laprn_charges,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_laprn_charges;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_laprn_charges: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="608"
                                                color="primary"
                                                checked={this.state.barge_laprn_charges.includes(
                                                  "608"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_laprn_charges: [
                                                        ...this.state
                                                          .barge_laprn_charges,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_laprn_charges;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_laprn_charges: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="603"
                                                color="primary"
                                                checked={this.state.barge_laprn_charges.includes(
                                                  "603"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_laprn_charges: [
                                                        ...this.state
                                                          .barge_laprn_charges,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_laprn_charges;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_laprn_charges: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="601"
                                                color="primary"
                                                checked={this.state.barge_laprn_charges.includes(
                                                  "601"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_laprn_charges: [
                                                        ...this.state
                                                          .barge_laprn_charges,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_laprn_charges;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_laprn_charges: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="368"
                                                color="primary"
                                                checked={this.state.barge_laprn_charges.includes(
                                                  "368"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_laprn_charges: [
                                                        ...this.state
                                                          .barge_laprn_charges,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_laprn_charges;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_laprn_charges: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Files
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>

                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Laporan Payments
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="607,606,605,604,602,600"
                                                color="primary"
                                                checked={
                                                  this.state.barge_laprn_payments.includes(
                                                    "600"
                                                  ) &&
                                                  this.state.barge_laprn_payments.includes(
                                                    "602"
                                                  ) &&
                                                  this.state.barge_laprn_payments.includes(
                                                    "604"
                                                  ) &&
                                                  this.state.barge_laprn_payments.includes(
                                                    "605"
                                                  ) &&
                                                  this.state.barge_laprn_payments.includes(
                                                    "606"
                                                  ) &&
                                                  this.state.barge_laprn_payments.includes(
                                                    "607"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_laprn_payments: [
                                                        "600",
                                                        "602",
                                                        "604",
                                                        "605",
                                                        "606",
                                                        "605",
                                                      ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      barge_laprn_payments: [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="607"
                                                color="primary"
                                                checked={this.state.barge_laprn_payments.includes(
                                                  "607"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_laprn_payments: [
                                                        ...this.state
                                                          .barge_laprn_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_laprn_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_laprn_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="606"
                                                color="primary"
                                                checked={this.state.barge_laprn_payments.includes(
                                                  "606"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_laprn_payments: [
                                                        ...this.state
                                                          .barge_laprn_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_laprn_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_laprn_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="605"
                                                color="primary"
                                                checked={this.state.barge_laprn_payments.includes(
                                                  "605"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_laprn_payments: [
                                                        ...this.state
                                                          .barge_laprn_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_laprn_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_laprn_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="604"
                                                color="primary"
                                                checked={this.state.barge_laprn_payments.includes(
                                                  "604"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_laprn_payments: [
                                                        ...this.state
                                                          .barge_laprn_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_laprn_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_laprn_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="602"
                                                color="primary"
                                                checked={this.state.barge_laprn_payments.includes(
                                                  "602"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_laprn_payments: [
                                                        ...this.state
                                                          .barge_laprn_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_laprn_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_laprn_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="600"
                                                color="primary"
                                                checked={this.state.barge_laprn_payments.includes(
                                                  "600"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_laprn_payments: [
                                                        ...this.state
                                                          .barge_laprn_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_laprn_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_laprn_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>

                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Other Expenses
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="338,339,340,341,378,550,551"
                                                color="primary"
                                                checked={
                                                  this.state.barge_pur_other_expense.includes(
                                                    "338"
                                                  ) &&
                                                  this.state.barge_pur_other_expense.includes(
                                                    "339"
                                                  ) &&
                                                  this.state.barge_pur_other_expense.includes(
                                                    "340"
                                                  ) &&
                                                  this.state.barge_pur_other_expense.includes(
                                                    "341"
                                                  ) &&
                                                  this.state.barge_pur_other_expense.includes(
                                                    "378"
                                                  ) &&
                                                  this.state.barge_pur_other_expense.includes(
                                                    "550"
                                                  ) &&
                                                  this.state.barge_pur_other_expense.includes(
                                                    "551"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_other_expense: [
                                                        "338",
                                                        "339",
                                                        "340",
                                                        "341",
                                                        "378",
                                                        "550",
                                                        "551",
                                                      ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      barge_pur_other_expense:
                                                        [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="338"
                                                color="primary"
                                                checked={this.state.barge_pur_other_expense.includes(
                                                  "338"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_other_expense: [
                                                        ...this.state
                                                          .barge_pur_other_expense,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_other_expense;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_other_expense:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="339"
                                                color="primary"
                                                checked={this.state.barge_pur_other_expense.includes(
                                                  "339"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_other_expense: [
                                                        ...this.state
                                                          .barge_pur_other_expense,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_other_expense;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_other_expense:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="340"
                                                color="primary"
                                                checked={this.state.barge_pur_other_expense.includes(
                                                  "340"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_other_expense: [
                                                        ...this.state
                                                          .barge_pur_other_expense,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_other_expense;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_other_expense:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>

                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="341"
                                                color="primary"
                                                checked={this.state.barge_pur_other_expense.includes(
                                                  "341"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_other_expense: [
                                                        ...this.state
                                                          .barge_pur_other_expense,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_other_expense;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_other_expense:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>

                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="550"
                                                color="primary"
                                                checked={this.state.barge_pur_other_expense.includes(
                                                  "550"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_other_expense: [
                                                        ...this.state
                                                          .barge_pur_other_expense,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_other_expense;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_other_expense:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approval
                                              </span>
                                            </span>
                                          </div>

                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="551"
                                                color="primary"
                                                checked={this.state.barge_pur_other_expense.includes(
                                                  "551"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_other_expense: [
                                                        ...this.state
                                                          .barge_pur_other_expense,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_other_expense;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_other_expense:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>

                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="378"
                                                color="primary"
                                                checked={this.state.barge_pur_other_expense.includes(
                                                  "378"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_other_expense: [
                                                        ...this.state
                                                          .barge_pur_other_expense,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_other_expense;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_other_expense:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Files
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>

                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Other Expense Payments
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="342,343,344,345"
                                                color="primary"
                                                checked={
                                                  this.state.barge_pur_other_expense_payments.includes(
                                                    "342"
                                                  ) &&
                                                  this.state.barge_pur_other_expense_payments.includes(
                                                    "343"
                                                  ) &&
                                                  this.state.barge_pur_other_expense_payments.includes(
                                                    "344"
                                                  ) &&
                                                  this.state.barge_pur_other_expense_payments.includes(
                                                    "345"
                                                  ) &&
                                                  this.state.barge_pur_other_expense_payments.includes(
                                                    "552"
                                                  ) &&
                                                  this.state.barge_pur_other_expense_payments.includes(
                                                    "553"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_other_expense_payments:
                                                        [
                                                          "342",
                                                          "343",
                                                          "344",
                                                          "345",
                                                          "552",
                                                          "553",
                                                        ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      barge_pur_other_expense_payments:
                                                        [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="342"
                                                color="primary"
                                                checked={this.state.barge_pur_other_expense_payments.includes(
                                                  "342"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_other_expense_payments:
                                                        [
                                                          ...this.state
                                                            .barge_pur_other_expense_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_other_expense_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_other_expense_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="343"
                                                color="primary"
                                                checked={this.state.barge_pur_other_expense_payments.includes(
                                                  "343"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_other_expense_payments:
                                                        [
                                                          ...this.state
                                                            .barge_pur_other_expense_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_other_expense_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_other_expense_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="344"
                                                color="primary"
                                                checked={this.state.barge_pur_other_expense_payments.includes(
                                                  "344"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_other_expense_payments:
                                                        [
                                                          ...this.state
                                                            .barge_pur_other_expense_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_other_expense_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_other_expense_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="345"
                                                color="primary"
                                                checked={this.state.barge_pur_other_expense_payments.includes(
                                                  "345"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_other_expense_payments:
                                                        [
                                                          ...this.state
                                                            .barge_pur_other_expense_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_other_expense_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_other_expense_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="552"
                                                color="primary"
                                                checked={this.state.barge_pur_other_expense_payments.includes(
                                                  "552"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_other_expense_payments:
                                                        [
                                                          ...this.state
                                                            .barge_pur_other_expense_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_other_expense_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_other_expense_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approval
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="553"
                                                color="primary"
                                                checked={this.state.barge_pur_other_expense_payments.includes(
                                                  "553"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_pur_other_expense_payments:
                                                        [
                                                          ...this.state
                                                            .barge_pur_other_expense_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_pur_other_expense_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_pur_other_expense_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>

                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Barge Insurance
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="346,347,348,349,362"
                                                color="primary"
                                                checked={
                                                  this.state.barge_insurance.includes(
                                                    "346"
                                                  ) &&
                                                  this.state.barge_insurance.includes(
                                                    "347"
                                                  ) &&
                                                  this.state.barge_insurance.includes(
                                                    "348"
                                                  ) &&
                                                  this.state.barge_insurance.includes(
                                                    "349"
                                                  ) &&
                                                  this.state.barge_insurance.includes(
                                                    "362"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_insurance: [
                                                        "346",
                                                        "347",
                                                        "348",
                                                        "349",
                                                        "362",
                                                      ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      barge_insurance: [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="346"
                                                color="primary"
                                                checked={this.state.barge_insurance.includes(
                                                  "346"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_insurance: [
                                                        ...this.state
                                                          .barge_insurance,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_insurance;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_insurance: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="347"
                                                color="primary"
                                                checked={this.state.barge_insurance.includes(
                                                  "347"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_insurance: [
                                                        ...this.state
                                                          .barge_insurance,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_insurance;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_insurance: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="348"
                                                color="primary"
                                                checked={this.state.barge_insurance.includes(
                                                  "348"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_insurance: [
                                                        ...this.state
                                                          .barge_insurance,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_insurance;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_insurance: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="349"
                                                color="primary"
                                                checked={this.state.barge_insurance.includes(
                                                  "349"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_insurance: [
                                                        ...this.state
                                                          .barge_insurance,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_insurance;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_insurance: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="362"
                                                color="primary"
                                                checked={this.state.barge_insurance.includes(
                                                  "362"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_insurance: [
                                                        ...this.state
                                                          .barge_insurance,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_insurance;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_insurance: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Files
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>

                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Insurance Payments
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="350,351,352,353"
                                                color="primary"
                                                checked={
                                                  this.state.barge_insurance_payments.includes(
                                                    "350"
                                                  ) &&
                                                  this.state.barge_insurance_payments.includes(
                                                    "351"
                                                  ) &&
                                                  this.state.barge_insurance_payments.includes(
                                                    "352"
                                                  ) &&
                                                  this.state.barge_insurance_payments.includes(
                                                    "353"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_insurance_payments:
                                                        [
                                                          "350",
                                                          "351",
                                                          "352",
                                                          "353",
                                                        ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      barge_insurance_payments:
                                                        [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="350"
                                                color="primary"
                                                checked={this.state.barge_insurance_payments.includes(
                                                  "350"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_insurance_payments:
                                                        [
                                                          ...this.state
                                                            .barge_insurance_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_insurance_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_insurance_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="351"
                                                color="primary"
                                                checked={this.state.barge_insurance_payments.includes(
                                                  "351"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_insurance_payments:
                                                        [
                                                          ...this.state
                                                            .barge_insurance_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_insurance_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_insurance_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="352"
                                                color="primary"
                                                checked={this.state.barge_insurance_payments.includes(
                                                  "352"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_insurance_payments:
                                                        [
                                                          ...this.state
                                                            .barge_insurance_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_insurance_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_insurance_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="353"
                                                color="primary"
                                                checked={this.state.barge_insurance_payments.includes(
                                                  "353"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_insurance_payments:
                                                        [
                                                          ...this.state
                                                            .barge_insurance_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_insurance_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_insurance_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>

                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Return Cargo Charges
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="536,537,538,539,540"
                                                color="primary"
                                                checked={
                                                  this.state.barge_return_cargo.includes(
                                                    "536"
                                                  ) &&
                                                  this.state.barge_return_cargo.includes(
                                                    "537"
                                                  ) &&
                                                  this.state.barge_return_cargo.includes(
                                                    "538"
                                                  ) &&
                                                  this.state.barge_return_cargo.includes(
                                                    "539"
                                                  ) &&
                                                  this.state.barge_return_cargo.includes(
                                                    "540"
                                                  ) &&
                                                  this.state.barge_return_cargo.includes(
                                                    "541"
                                                  ) &&
                                                  this.state.barge_return_cargo.includes(
                                                    "542"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_return_cargo: [
                                                        "536",
                                                        "537",
                                                        "538",
                                                        "539",
                                                        "540",
                                                        "541",
                                                        "542",
                                                      ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      barge_return_cargo: [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="536"
                                                color="primary"
                                                checked={this.state.barge_return_cargo.includes(
                                                  "536"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_return_cargo: [
                                                        ...this.state
                                                          .barge_return_cargo,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_return_cargo;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_return_cargo: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="538"
                                                color="primary"
                                                checked={this.state.barge_return_cargo.includes(
                                                  "538"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_return_cargo: [
                                                        ...this.state
                                                          .barge_return_cargo,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_return_cargo;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_return_cargo: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="537"
                                                color="primary"
                                                checked={this.state.barge_return_cargo.includes(
                                                  "537"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_return_cargo: [
                                                        ...this.state
                                                          .barge_return_cargo,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_return_cargo;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_return_cargo: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="539"
                                                color="primary"
                                                checked={this.state.barge_return_cargo.includes(
                                                  "539"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_return_cargo: [
                                                        ...this.state
                                                          .barge_return_cargo,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_return_cargo;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_return_cargo: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="540"
                                                color="primary"
                                                checked={this.state.barge_return_cargo.includes(
                                                  "540"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_return_cargo: [
                                                        ...this.state
                                                          .barge_return_cargo,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_return_cargo;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_return_cargo: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="541"
                                                color="primary"
                                                checked={this.state.barge_return_cargo.includes(
                                                  "541"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_return_cargo: [
                                                        ...this.state
                                                          .barge_return_cargo,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_return_cargo;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_return_cargo: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approve
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="542"
                                                color="primary"
                                                checked={this.state.barge_return_cargo.includes(
                                                  "542"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_return_cargo: [
                                                        ...this.state
                                                          .barge_return_cargo,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_return_cargo;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_return_cargo: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Files
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>

                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Return Cargo Payments
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="543,544,545,546, 547,548"
                                                color="primary"
                                                checked={
                                                  this.state.barge_return_cargo_payments.includes(
                                                    "543"
                                                  ) &&
                                                  this.state.barge_return_cargo_payments.includes(
                                                    "544"
                                                  ) &&
                                                  this.state.barge_return_cargo_payments.includes(
                                                    "545"
                                                  ) &&
                                                  this.state.barge_return_cargo_payments.includes(
                                                    "546"
                                                  ) &&
                                                  this.state.barge_return_cargo_payments.includes(
                                                    "547"
                                                  ) &&
                                                  this.state.barge_return_cargo_payments.includes(
                                                    "548"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_return_cargo_payments:
                                                        [
                                                          "543",
                                                          "544",
                                                          "545",
                                                          "546",
                                                          "547",
                                                          "548",
                                                        ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      barge_return_cargo_payments:
                                                        [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="543"
                                                color="primary"
                                                checked={this.state.barge_return_cargo_payments.includes(
                                                  "543"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_return_cargo_payments:
                                                        [
                                                          ...this.state
                                                            .barge_return_cargo_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_return_cargo_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_return_cargo_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="544"
                                                color="primary"
                                                checked={this.state.barge_return_cargo_payments.includes(
                                                  "544"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_return_cargo_payments:
                                                        [
                                                          ...this.state
                                                            .barge_return_cargo_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_return_cargo_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_return_cargo_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="545"
                                                color="primary"
                                                checked={this.state.barge_return_cargo_payments.includes(
                                                  "545"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_return_cargo_payments:
                                                        [
                                                          ...this.state
                                                            .barge_return_cargo_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_return_cargo_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_return_cargo_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="546"
                                                color="primary"
                                                checked={this.state.barge_return_cargo_payments.includes(
                                                  "546"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_return_cargo_payments:
                                                        [
                                                          ...this.state
                                                            .barge_return_cargo_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_return_cargo_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_return_cargo_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="547"
                                                color="primary"
                                                checked={this.state.barge_return_cargo_payments.includes(
                                                  "547"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_return_cargo_payments:
                                                        [
                                                          ...this.state
                                                            .barge_return_cargo_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_return_cargo_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_return_cargo_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approval
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="548"
                                                color="primary"
                                                checked={this.state.barge_return_cargo_payments.includes(
                                                  "548"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_return_cargo_payments:
                                                        [
                                                          ...this.state
                                                            .barge_return_cargo_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_return_cargo_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_return_cargo_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div
                                className="col-lg-12 p-1"
                                onClick={() =>
                                  this.setState({ mainSectionActiveIndex: 8 })
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <div
                                  className="row col-lg-12 rounded p-2"
                                  style={{
                                    border:
                                      this.state.mainSectionActiveIndex === 8
                                        ? "1px solid " + config.themeColor
                                        : "1px solid #dee2e6",
                                  }}
                                >
                                  <div className="col-lg-12 p-0">
                                    <div className="col-lg-12 p-0">
                                      <label className="access_title mb-0">
                                        Barge Sales Financials
                                      </label>
                                      {this.state.mainSectionActiveIndex ===
                                      8 ? (
                                        ""
                                      ) : (
                                        <label className="access_sub_title mb-0">
                                          Coal Costing | Coal Costing Invoices |
                                          Coal Costing Payments | Coal Sales
                                          Charges | Coal Sales Invoices | Coal
                                          Sales Payments
                                        </label>
                                      )}
                                    </div>
                                  </div>
                                  {this.state.mainSectionActiveIndex === 8 && (
                                    <div className="row col-lg-12 p-0 mb-0 mt-2">
                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Coal Costing
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="190,191,192,193"
                                                color="primary"
                                                checked={
                                                  this.state.barge_sales_coal_costing.includes(
                                                    "190"
                                                  ) &&
                                                  this.state.barge_sales_coal_costing.includes(
                                                    "191"
                                                  ) &&
                                                  this.state.barge_sales_coal_costing.includes(
                                                    "192"
                                                  ) &&
                                                  this.state.barge_sales_coal_costing.includes(
                                                    "193"
                                                  ) &&
                                                  this.state.barge_sales_coal_costing.includes(
                                                    "366"
                                                  ) &&
                                                  this.state.barge_sales_coal_costing.includes(
                                                    "404"
                                                  ) &&
                                                  this.state.barge_sales_coal_costing.includes(
                                                    "455"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_sales_coal_costing:
                                                        [
                                                          "190",
                                                          "191",
                                                          "192",
                                                          "193",
                                                          "366",
                                                          "404",
                                                          "455",
                                                        ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      barge_sales_coal_costing:
                                                        [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="190"
                                                color="primary"
                                                checked={this.state.barge_sales_coal_costing.includes(
                                                  "190"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_sales_coal_costing:
                                                        [
                                                          ...this.state
                                                            .barge_sales_coal_costing,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_sales_coal_costing;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_sales_coal_costing:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="191"
                                                color="primary"
                                                checked={this.state.barge_sales_coal_costing.includes(
                                                  "191"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_sales_coal_costing:
                                                        [
                                                          ...this.state
                                                            .barge_sales_coal_costing,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_sales_coal_costing;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_sales_coal_costing:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="192"
                                                color="primary"
                                                checked={this.state.barge_sales_coal_costing.includes(
                                                  "192"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_sales_coal_costing:
                                                        [
                                                          ...this.state
                                                            .barge_sales_coal_costing,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_sales_coal_costing;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_sales_coal_costing:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="193"
                                                color="primary"
                                                checked={this.state.barge_sales_coal_costing.includes(
                                                  "193"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_sales_coal_costing:
                                                        [
                                                          ...this.state
                                                            .barge_sales_coal_costing,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_sales_coal_costing;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_sales_coal_costing:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="404"
                                                color="primary"
                                                checked={this.state.barge_sales_coal_costing.includes(
                                                  "404"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_sales_coal_costing:
                                                        [
                                                          ...this.state
                                                            .barge_sales_coal_costing,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_sales_coal_costing;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_sales_coal_costing:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="455"
                                                color="primary"
                                                checked={this.state.barge_sales_coal_costing.includes(
                                                  "455"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_sales_coal_costing:
                                                        [
                                                          ...this.state
                                                            .barge_sales_coal_costing,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_sales_coal_costing;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_sales_coal_costing:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="366"
                                                color="primary"
                                                checked={this.state.barge_sales_coal_costing.includes(
                                                  "366"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_sales_coal_costing:
                                                        [
                                                          ...this.state
                                                            .barge_sales_coal_costing,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_sales_coal_costing;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_sales_coal_costing:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Files
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>

                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Coal Costing Invoices
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="194,195,196,197"
                                                color="primary"
                                                checked={
                                                  this.state.barge_sales_coal_costing_invoices.includes(
                                                    "194"
                                                  ) &&
                                                  this.state.barge_sales_coal_costing_invoices.includes(
                                                    "195"
                                                  ) &&
                                                  this.state.barge_sales_coal_costing_invoices.includes(
                                                    "196"
                                                  ) &&
                                                  this.state.barge_sales_coal_costing_invoices.includes(
                                                    "197"
                                                  ) &&
                                                  this.state.barge_sales_coal_costing_invoices.includes(
                                                    "405"
                                                  ) &&
                                                  this.state.barge_sales_coal_costing_invoices.includes(
                                                    "456"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_sales_coal_costing_invoices:
                                                        [
                                                          "194",
                                                          "195",
                                                          "196",
                                                          "197",
                                                          "405",
                                                          "456",
                                                        ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      barge_sales_coal_costing_invoices:
                                                        [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="194"
                                                color="primary"
                                                checked={this.state.barge_sales_coal_costing_invoices.includes(
                                                  "194"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_sales_coal_costing_invoices:
                                                        [
                                                          ...this.state
                                                            .barge_sales_coal_costing_invoices,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_sales_coal_costing_invoices;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_sales_coal_costing_invoices:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="195"
                                                color="primary"
                                                checked={this.state.barge_sales_coal_costing_invoices.includes(
                                                  "195"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_sales_coal_costing_invoices:
                                                        [
                                                          ...this.state
                                                            .barge_sales_coal_costing_invoices,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_sales_coal_costing_invoices;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_sales_coal_costing_invoices:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="196"
                                                color="primary"
                                                checked={this.state.barge_sales_coal_costing_invoices.includes(
                                                  "196"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_sales_coal_costing_invoices:
                                                        [
                                                          ...this.state
                                                            .barge_sales_coal_costing_invoices,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_sales_coal_costing_invoices;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_sales_coal_costing_invoices:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="197"
                                                color="primary"
                                                checked={this.state.barge_sales_coal_costing_invoices.includes(
                                                  "197"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_sales_coal_costing_invoices:
                                                        [
                                                          ...this.state
                                                            .barge_sales_coal_costing_invoices,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_sales_coal_costing_invoices;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_sales_coal_costing_invoices:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="405"
                                                color="primary"
                                                checked={this.state.barge_sales_coal_costing_invoices.includes(
                                                  "405"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_sales_coal_costing_invoices:
                                                        [
                                                          ...this.state
                                                            .barge_sales_coal_costing_invoices,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_sales_coal_costing_invoices;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_sales_coal_costing_invoices:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="456"
                                                color="primary"
                                                checked={this.state.barge_sales_coal_costing_invoices.includes(
                                                  "456"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_sales_coal_costing_invoices:
                                                        [
                                                          ...this.state
                                                            .barge_sales_coal_costing_invoices,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_sales_coal_costing_invoices;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_sales_coal_costing_invoices:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>

                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Coal Costing Payments
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="198,199,200,201"
                                                color="primary"
                                                checked={
                                                  this.state.barge_sales_coal_costing_payments.includes(
                                                    "198"
                                                  ) &&
                                                  this.state.barge_sales_coal_costing_payments.includes(
                                                    "199"
                                                  ) &&
                                                  this.state.barge_sales_coal_costing_payments.includes(
                                                    "200"
                                                  ) &&
                                                  this.state.barge_sales_coal_costing_payments.includes(
                                                    "201"
                                                  ) &&
                                                  this.state.barge_sales_coal_costing_payments.includes(
                                                    "406"
                                                  ) &&
                                                  this.state.barge_sales_coal_costing_payments.includes(
                                                    "457"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_sales_coal_costing_payments:
                                                        [
                                                          "198",
                                                          "199",
                                                          "200",
                                                          "201",
                                                          "406",
                                                          "457",
                                                        ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      barge_sales_coal_costing_payments:
                                                        [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="198"
                                                color="primary"
                                                checked={this.state.barge_sales_coal_costing_payments.includes(
                                                  "198"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_sales_coal_costing_payments:
                                                        [
                                                          ...this.state
                                                            .barge_sales_coal_costing_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_sales_coal_costing_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_sales_coal_costing_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="199"
                                                color="primary"
                                                checked={this.state.barge_sales_coal_costing_payments.includes(
                                                  "199"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_sales_coal_costing_payments:
                                                        [
                                                          ...this.state
                                                            .barge_sales_coal_costing_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_sales_coal_costing_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_sales_coal_costing_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="200"
                                                color="primary"
                                                checked={this.state.barge_sales_coal_costing_payments.includes(
                                                  "200"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_sales_coal_costing_payments:
                                                        [
                                                          ...this.state
                                                            .barge_sales_coal_costing_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_sales_coal_costing_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_sales_coal_costing_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="201"
                                                color="primary"
                                                checked={this.state.barge_sales_coal_costing_payments.includes(
                                                  "201"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_sales_coal_costing_payments:
                                                        [
                                                          ...this.state
                                                            .barge_sales_coal_costing_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_sales_coal_costing_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_sales_coal_costing_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="406"
                                                color="primary"
                                                checked={this.state.barge_sales_coal_costing_payments.includes(
                                                  "406"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_sales_coal_costing_payments:
                                                        [
                                                          ...this.state
                                                            .barge_sales_coal_costing_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_sales_coal_costing_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_sales_coal_costing_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="457"
                                                color="primary"
                                                checked={this.state.barge_sales_coal_costing_payments.includes(
                                                  "457"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_sales_coal_costing_payments:
                                                        [
                                                          ...this.state
                                                            .barge_sales_coal_costing_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_sales_coal_costing_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_sales_coal_costing_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>

                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Coal Sales Charges
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="202,203,204,205,407,458,374"
                                                color="primary"
                                                checked={
                                                  this.state.barge_sales_coal_sales_charges.includes(
                                                    "202"
                                                  ) &&
                                                  this.state.barge_sales_coal_sales_charges.includes(
                                                    "203"
                                                  ) &&
                                                  this.state.barge_sales_coal_sales_charges.includes(
                                                    "204"
                                                  ) &&
                                                  this.state.barge_sales_coal_sales_charges.includes(
                                                    "205"
                                                  ) &&
                                                  this.state.barge_sales_coal_sales_charges.includes(
                                                    "407"
                                                  ) &&
                                                  this.state.barge_sales_coal_sales_charges.includes(
                                                    "458"
                                                  ) &&
                                                  this.state.barge_sales_coal_sales_charges.includes(
                                                    "374"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_sales_coal_sales_charges:
                                                        [
                                                          "202",
                                                          "203",
                                                          "204",
                                                          "205",
                                                          "407",
                                                          "458",
                                                          "374",
                                                        ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      barge_sales_coal_sales_charges:
                                                        [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="202"
                                                color="primary"
                                                checked={this.state.barge_sales_coal_sales_charges.includes(
                                                  "202"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_sales_coal_sales_charges:
                                                        [
                                                          ...this.state
                                                            .barge_sales_coal_sales_charges,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_sales_coal_sales_charges;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_sales_coal_sales_charges:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="203"
                                                color="primary"
                                                checked={this.state.barge_sales_coal_sales_charges.includes(
                                                  "203"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_sales_coal_sales_charges:
                                                        [
                                                          ...this.state
                                                            .barge_sales_coal_sales_charges,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_sales_coal_sales_charges;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_sales_coal_sales_charges:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="204"
                                                color="primary"
                                                checked={this.state.barge_sales_coal_sales_charges.includes(
                                                  "204"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_sales_coal_sales_charges:
                                                        [
                                                          ...this.state
                                                            .barge_sales_coal_sales_charges,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_sales_coal_sales_charges;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_sales_coal_sales_charges:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="205"
                                                color="primary"
                                                checked={this.state.barge_sales_coal_sales_charges.includes(
                                                  "205"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_sales_coal_sales_charges:
                                                        [
                                                          ...this.state
                                                            .barge_sales_coal_sales_charges,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_sales_coal_sales_charges;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_sales_coal_sales_charges:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="407"
                                                color="primary"
                                                checked={this.state.barge_sales_coal_sales_charges.includes(
                                                  "407"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_sales_coal_sales_charges:
                                                        [
                                                          ...this.state
                                                            .barge_sales_coal_sales_charges,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_sales_coal_sales_charges;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_sales_coal_sales_charges:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="458"
                                                color="primary"
                                                checked={this.state.barge_sales_coal_sales_charges.includes(
                                                  "458"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_sales_coal_sales_charges:
                                                        [
                                                          ...this.state
                                                            .barge_sales_coal_sales_charges,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_sales_coal_sales_charges;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_sales_coal_sales_charges:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="374"
                                                color="primary"
                                                checked={this.state.barge_sales_coal_sales_charges.includes(
                                                  "374"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_sales_coal_sales_charges:
                                                        [
                                                          ...this.state
                                                            .barge_sales_coal_sales_charges,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_sales_coal_sales_charges;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_sales_coal_sales_charges:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Files
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>
                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Coal Sales Charges Invoices
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="206,207,208,209,408, 459"
                                                color="primary"
                                                checked={
                                                  this.state.barge_sales_coal_sales_charges_invoices.includes(
                                                    "206"
                                                  ) &&
                                                  this.state.barge_sales_coal_sales_charges_invoices.includes(
                                                    "207"
                                                  ) &&
                                                  this.state.barge_sales_coal_sales_charges_invoices.includes(
                                                    "208"
                                                  ) &&
                                                  this.state.barge_sales_coal_sales_charges_invoices.includes(
                                                    "209"
                                                  ) &&
                                                  this.state.barge_sales_coal_sales_charges_invoices.includes(
                                                    "209"
                                                  ) &&
                                                  this.state.barge_sales_coal_sales_charges_invoices.includes(
                                                    "459"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_sales_coal_sales_charges_invoices:
                                                        [
                                                          "206",
                                                          "207",
                                                          "208",
                                                          "209",
                                                          "408",
                                                          "459",
                                                        ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      barge_sales_coal_sales_charges_invoices:
                                                        [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="206"
                                                color="primary"
                                                checked={this.state.barge_sales_coal_sales_charges_invoices.includes(
                                                  "206"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_sales_coal_sales_charges_invoices:
                                                        [
                                                          ...this.state
                                                            .barge_sales_coal_sales_charges_invoices,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_sales_coal_sales_charges_invoices;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_sales_coal_sales_charges_invoices:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="207"
                                                color="primary"
                                                checked={this.state.barge_sales_coal_sales_charges_invoices.includes(
                                                  "207"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_sales_coal_sales_charges_invoices:
                                                        [
                                                          ...this.state
                                                            .barge_sales_coal_sales_charges_invoices,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_sales_coal_sales_charges_invoices;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_sales_coal_sales_charges_invoices:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="208"
                                                color="primary"
                                                checked={this.state.barge_sales_coal_sales_charges_invoices.includes(
                                                  "208"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_sales_coal_sales_charges_invoices:
                                                        [
                                                          ...this.state
                                                            .barge_sales_coal_sales_charges_invoices,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_sales_coal_sales_charges_invoices;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_sales_coal_sales_charges_invoices:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="209"
                                                color="primary"
                                                checked={this.state.barge_sales_coal_sales_charges_invoices.includes(
                                                  "209"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_sales_coal_sales_charges_invoices:
                                                        [
                                                          ...this.state
                                                            .barge_sales_coal_sales_charges_invoices,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_sales_coal_sales_charges_invoices;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_sales_coal_sales_charges_invoices:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="408"
                                                color="primary"
                                                checked={this.state.barge_sales_coal_sales_charges_invoices.includes(
                                                  "408"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_sales_coal_sales_charges_invoices:
                                                        [
                                                          ...this.state
                                                            .barge_sales_coal_sales_charges_invoices,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_sales_coal_sales_charges_invoices;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_sales_coal_sales_charges_invoices:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="459"
                                                color="primary"
                                                checked={this.state.barge_sales_coal_sales_charges_invoices.includes(
                                                  "459"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_sales_coal_sales_charges_invoices:
                                                        [
                                                          ...this.state
                                                            .barge_sales_coal_sales_charges_invoices,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_sales_coal_sales_charges_invoices;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_sales_coal_sales_charges_invoices:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>
                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Coal Sales Charges Payments
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="210,211,212,213, 409, 460"
                                                color="primary"
                                                checked={
                                                  this.state.barge_sales_coal_sales_charges_payments.includes(
                                                    "210"
                                                  ) &&
                                                  this.state.barge_sales_coal_sales_charges_payments.includes(
                                                    "211"
                                                  ) &&
                                                  this.state.barge_sales_coal_sales_charges_payments.includes(
                                                    "212"
                                                  ) &&
                                                  this.state.barge_sales_coal_sales_charges_payments.includes(
                                                    "213"
                                                  ) &&
                                                  this.state.barge_sales_coal_sales_charges_payments.includes(
                                                    "409"
                                                  ) &&
                                                  this.state.barge_sales_coal_sales_charges_payments.includes(
                                                    "460"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_sales_coal_sales_charges_payments:
                                                        [
                                                          "210",
                                                          "211",
                                                          "212",
                                                          "213",
                                                          "409",
                                                          "460",
                                                        ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      barge_sales_coal_sales_charges_payments:
                                                        [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="210"
                                                color="primary"
                                                checked={this.state.barge_sales_coal_sales_charges_payments.includes(
                                                  "210"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_sales_coal_sales_charges_payments:
                                                        [
                                                          ...this.state
                                                            .barge_sales_coal_sales_charges_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_sales_coal_sales_charges_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_sales_coal_sales_charges_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="211"
                                                color="primary"
                                                checked={this.state.barge_sales_coal_sales_charges_payments.includes(
                                                  "211"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_sales_coal_sales_charges_payments:
                                                        [
                                                          ...this.state
                                                            .barge_sales_coal_sales_charges_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_sales_coal_sales_charges_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_sales_coal_sales_charges_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="212"
                                                color="primary"
                                                checked={this.state.barge_sales_coal_sales_charges_payments.includes(
                                                  "212"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_sales_coal_sales_charges_payments:
                                                        [
                                                          ...this.state
                                                            .barge_sales_coal_sales_charges_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_sales_coal_sales_charges_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_sales_coal_sales_charges_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="213"
                                                color="primary"
                                                checked={this.state.barge_sales_coal_sales_charges_payments.includes(
                                                  "213"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_sales_coal_sales_charges_payments:
                                                        [
                                                          ...this.state
                                                            .barge_sales_coal_sales_charges_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_sales_coal_sales_charges_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_sales_coal_sales_charges_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="409"
                                                color="primary"
                                                checked={this.state.barge_sales_coal_sales_charges_payments.includes(
                                                  "409"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_sales_coal_sales_charges_payments:
                                                        [
                                                          ...this.state
                                                            .barge_sales_coal_sales_charges_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_sales_coal_sales_charges_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_sales_coal_sales_charges_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="460"
                                                color="primary"
                                                checked={this.state.barge_sales_coal_sales_charges_payments.includes(
                                                  "460"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_sales_coal_sales_charges_payments:
                                                        [
                                                          ...this.state
                                                            .barge_sales_coal_sales_charges_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_sales_coal_sales_charges_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_sales_coal_sales_charges_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div
                                className="col-lg-12 p-1"
                                onClick={() =>
                                  this.setState({ mainSectionActiveIndex: 10 })
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <div
                                  className="row col-lg-12 rounded p-2"
                                  style={{
                                    border:
                                      this.state.mainSectionActiveIndex === 10
                                        ? "1px solid " + config.themeColor
                                        : "1px solid #dee2e6",
                                  }}
                                >
                                  <div className="col-lg-12 p-0">
                                    <div className="col-lg-12 p-0">
                                      <label className="access_title mb-0">
                                        Business Number
                                      </label>
                                      {this.state.mainSectionActiveIndex ===
                                      10 ? (
                                        ""
                                      ) : (
                                        <label className="access_sub_title mb-0">
                                          Business Number | Business Profit &
                                          Loss | Mother Vessel Operational |
                                          Vessel Completion Report
                                        </label>
                                      )}
                                    </div>
                                  </div>
                                  {this.state.mainSectionActiveIndex === 10 && (
                                    <div className="row col-lg-12 p-0 mb-0 mt-2">
                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Business Number
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="37,38,39,40,41"
                                                color="primary"
                                                checked={
                                                  this.state.businessNoChecked.includes(
                                                    "37"
                                                  ) &&
                                                  this.state.businessNoChecked.includes(
                                                    "38"
                                                  ) &&
                                                  this.state.businessNoChecked.includes(
                                                    "39"
                                                  ) &&
                                                  this.state.businessNoChecked.includes(
                                                    "40"
                                                  ) &&
                                                  this.state.businessNoChecked.includes(
                                                    "41"
                                                  ) &&
                                                  this.state.businessNoChecked.includes(
                                                    "122"
                                                  ) &&
                                                  this.state.businessNoChecked.includes(
                                                    "115"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      businessNoChecked: [
                                                        "37",
                                                        "38",
                                                        "39",
                                                        "40",
                                                        "41",
                                                        "122",
                                                        "115",
                                                      ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      businessNoChecked: [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="37"
                                                color="primary"
                                                checked={this.state.businessNoChecked.includes(
                                                  "37"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      businessNoChecked: [
                                                        ...this.state
                                                          .businessNoChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .businessNoChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      businessNoChecked: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="38"
                                                color="primary"
                                                checked={this.state.businessNoChecked.includes(
                                                  "38"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      businessNoChecked: [
                                                        ...this.state
                                                          .businessNoChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .businessNoChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      businessNoChecked: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="39"
                                                color="primary"
                                                checked={this.state.businessNoChecked.includes(
                                                  "39"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      businessNoChecked: [
                                                        ...this.state
                                                          .businessNoChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .businessNoChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      businessNoChecked: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="40"
                                                color="primary"
                                                checked={this.state.businessNoChecked.includes(
                                                  "40"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      businessNoChecked: [
                                                        ...this.state
                                                          .businessNoChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .businessNoChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      businessNoChecked: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Close
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="115"
                                                color="primary"
                                                checked={this.state.businessNoChecked.includes(
                                                  "115"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      businessNoChecked: [
                                                        ...this.state
                                                          .businessNoChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .businessNoChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                      this.setState({
                                                        businessNoChecked: data,
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Cancel
                                              </span>
                                            </span>
                                          </div>

                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="112"
                                                color="primary"
                                                checked={this.state.businessNoChecked.includes(
                                                  "112"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      businessNoChecked: [
                                                        ...this.state
                                                          .businessNoChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .businessNoChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      businessNoChecked: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View Price
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="41"
                                                color="primary"
                                                checked={this.state.businessNoChecked.includes(
                                                  "41"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      businessNoChecked: [
                                                        ...this.state
                                                          .businessNoChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .businessNoChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      businessNoChecked: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Change Allocation
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>
                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Business Profit & Loss
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="314"
                                                color="primary"
                                                checked={this.state.business_profit_and_loss.includes(
                                                  "314"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      business_profit_and_loss:
                                                        [
                                                          ...this.state
                                                            .business_profit_and_loss,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .business_profit_and_loss;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      business_profit_and_loss:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>
                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Mother Vessel Operational Data
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="42,43,44,45"
                                                color="primary"
                                                checked={
                                                  this.state.BusinessNoOpMVChecked.includes(
                                                    "42"
                                                  ) &&
                                                  this.state.BusinessNoOpMVChecked.includes(
                                                    "43"
                                                  ) &&
                                                  this.state.BusinessNoOpMVChecked.includes(
                                                    "44"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      BusinessNoOpMVChecked: [
                                                        "42",
                                                        "43",
                                                        "44",
                                                        "45",
                                                      ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      BusinessNoOpMVChecked: [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="42"
                                                color="primary"
                                                checked={this.state.BusinessNoOpMVChecked.includes(
                                                  "42"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      BusinessNoOpMVChecked: [
                                                        ...this.state
                                                          .BusinessNoOpMVChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .BusinessNoOpMVChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      BusinessNoOpMVChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="43"
                                                color="primary"
                                                checked={this.state.BusinessNoOpMVChecked.includes(
                                                  "43"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      BusinessNoOpMVChecked: [
                                                        ...this.state
                                                          .BusinessNoOpMVChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .BusinessNoOpMVChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      BusinessNoOpMVChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="44"
                                                color="primary"
                                                checked={this.state.BusinessNoOpMVChecked.includes(
                                                  "44"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      BusinessNoOpMVChecked: [
                                                        ...this.state
                                                          .BusinessNoOpMVChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .BusinessNoOpMVChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      BusinessNoOpMVChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>

                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Mother Vessel Completion Report
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="319,320,321,322"
                                                color="primary"
                                                checked={
                                                  this.state.vessel_completion_report.includes(
                                                    "329"
                                                  ) &&
                                                  this.state.vessel_completion_report.includes(
                                                    "330"
                                                  ) &&
                                                  this.state.vessel_completion_report.includes(
                                                    "331"
                                                  ) &&
                                                  this.state.vessel_completion_report.includes(
                                                    "364"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      vessel_completion_report:
                                                        [
                                                          "329",
                                                          "330",
                                                          "331",
                                                          "364",
                                                        ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      vessel_completion_report:
                                                        [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="329"
                                                color="primary"
                                                checked={this.state.vessel_completion_report.includes(
                                                  "329"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      vessel_completion_report:
                                                        [
                                                          ...this.state
                                                            .vessel_completion_report,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .vessel_completion_report;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      vessel_completion_report:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="330"
                                                color="primary"
                                                checked={this.state.vessel_completion_report.includes(
                                                  "330"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      vessel_completion_report:
                                                        [
                                                          ...this.state
                                                            .vessel_completion_report,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .vessel_completion_report;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      vessel_completion_report:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="331"
                                                color="primary"
                                                checked={this.state.vessel_completion_report.includes(
                                                  "331"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      vessel_completion_report:
                                                        [
                                                          ...this.state
                                                            .vessel_completion_report,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .vessel_completion_report;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      vessel_completion_report:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="364"
                                                color="primary"
                                                checked={this.state.vessel_completion_report.includes(
                                                  "364"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      vessel_completion_report:
                                                        [
                                                          ...this.state
                                                            .vessel_completion_report,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .vessel_completion_report;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      vessel_completion_report:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Files
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div
                                className="col-lg-12 p-1"
                                onClick={() =>
                                  this.setState({ mainSectionActiveIndex: 11 })
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <div
                                  className="row col-lg-12 rounded p-2"
                                  style={{
                                    border:
                                      this.state.mainSectionActiveIndex === 11
                                        ? "1px solid " + config.themeColor
                                        : "1px solid #dee2e6",
                                  }}
                                >
                                  <div className="col-lg-12 p-0">
                                    <div className="col-lg-12 p-0">
                                      <label className="access_title mb-0">
                                        Mother Vessel Demurrage
                                      </label>
                                      {this.state.mainSectionActiveIndex ===
                                      11 ? (
                                        ""
                                      ) : (
                                        <label className="access_sub_title mb-0">
                                          Mother Vessel Demurrage | Mother
                                          Vessel Demurrage Payments{" "}
                                        </label>
                                      )}
                                    </div>
                                  </div>
                                  {this.state.mainSectionActiveIndex === 11 && (
                                    <div className="row col-lg-12 p-0 mb-0 mt-2">
                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Mother Vessel Demurrage
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="49,50,51, 432, 433, 483, 526"
                                                color="primary"
                                                checked={
                                                  this.state.MVDemurrageChecked.includes(
                                                    "49"
                                                  ) &&
                                                  this.state.MVDemurrageChecked.includes(
                                                    "50"
                                                  ) &&
                                                  this.state.MVDemurrageChecked.includes(
                                                    "51"
                                                  ) &&
                                                  this.state.MVDemurrageChecked.includes(
                                                    "432"
                                                  ) &&
                                                  this.state.MVDemurrageChecked.includes(
                                                    "433"
                                                  ) &&
                                                  this.state.MVDemurrageChecked.includes(
                                                    "483"
                                                  ) &&
                                                  this.state.MVDemurrageChecked.includes(
                                                    "526"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      MVDemurrageChecked: [
                                                        "49",
                                                        "50",
                                                        "51",
                                                        "432",
                                                        "433",
                                                        "483",
                                                        "526",
                                                      ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      MVDemurrageChecked: [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="49"
                                                color="primary"
                                                checked={this.state.MVDemurrageChecked.includes(
                                                  "49"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      MVDemurrageChecked: [
                                                        ...this.state
                                                          .MVDemurrageChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .MVDemurrageChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      MVDemurrageChecked: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="50"
                                                color="primary"
                                                checked={this.state.MVDemurrageChecked.includes(
                                                  "50"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      MVDemurrageChecked: [
                                                        ...this.state
                                                          .MVDemurrageChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .MVDemurrageChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      MVDemurrageChecked: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="51"
                                                color="primary"
                                                checked={this.state.MVDemurrageChecked.includes(
                                                  "51"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      MVDemurrageChecked: [
                                                        ...this.state
                                                          .MVDemurrageChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .MVDemurrageChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      MVDemurrageChecked: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="433"
                                                color="primary"
                                                checked={this.state.MVDemurrageChecked.includes(
                                                  "433"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      MVDemurrageChecked: [
                                                        ...this.state
                                                          .MVDemurrageChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .MVDemurrageChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      MVDemurrageChecked: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="432"
                                                color="primary"
                                                checked={this.state.MVDemurrageChecked.includes(
                                                  "432"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      MVDemurrageChecked: [
                                                        ...this.state
                                                          .MVDemurrageChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .MVDemurrageChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      MVDemurrageChecked: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>

                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="483"
                                                color="primary"
                                                checked={this.state.MVDemurrageChecked.includes(
                                                  "483"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      MVDemurrageChecked: [
                                                        ...this.state
                                                          .MVDemurrageChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .MVDemurrageChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      MVDemurrageChecked: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="526"
                                                color="primary"
                                                checked={this.state.MVDemurrageChecked.includes(
                                                  "526"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      MVDemurrageChecked: [
                                                        ...this.state
                                                          .MVDemurrageChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .MVDemurrageChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      MVDemurrageChecked: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Files
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>
                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Mother Vessel Demurrage Payments
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="49,50,51"
                                                color="primary"
                                                checked={
                                                  this.state.MVDemurragePaymentChecked.includes(
                                                    "434"
                                                  ) &&
                                                  this.state.MVDemurragePaymentChecked.includes(
                                                    "435"
                                                  ) &&
                                                  this.state.MVDemurragePaymentChecked.includes(
                                                    "436"
                                                  ) &&
                                                  this.state.MVDemurragePaymentChecked.includes(
                                                    "437"
                                                  ) &&
                                                  this.state.MVDemurragePaymentChecked.includes(
                                                    "438"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      MVDemurragePaymentChecked:
                                                        [
                                                          "434",
                                                          "435",
                                                          "436",
                                                          "437",
                                                          "438",
                                                        ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      MVDemurragePaymentChecked:
                                                        [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="434"
                                                color="primary"
                                                checked={this.state.MVDemurragePaymentChecked.includes(
                                                  "434"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      MVDemurragePaymentChecked:
                                                        [
                                                          ...this.state
                                                            .MVDemurragePaymentChecked,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .MVDemurragePaymentChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      MVDemurragePaymentChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="435"
                                                color="primary"
                                                checked={this.state.MVDemurragePaymentChecked.includes(
                                                  "435"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      MVDemurragePaymentChecked:
                                                        [
                                                          ...this.state
                                                            .MVDemurragePaymentChecked,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .MVDemurragePaymentChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      MVDemurragePaymentChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="436"
                                                color="primary"
                                                checked={this.state.MVDemurragePaymentChecked.includes(
                                                  "436"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      MVDemurragePaymentChecked:
                                                        [
                                                          ...this.state
                                                            .MVDemurragePaymentChecked,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .MVDemurragePaymentChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      MVDemurragePaymentChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="438"
                                                color="primary"
                                                checked={this.state.MVDemurragePaymentChecked.includes(
                                                  "438"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      MVDemurragePaymentChecked:
                                                        [
                                                          ...this.state
                                                            .MVDemurragePaymentChecked,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .MVDemurragePaymentChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      MVDemurragePaymentChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="437"
                                                color="primary"
                                                checked={this.state.MVDemurragePaymentChecked.includes(
                                                  "437"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      MVDemurragePaymentChecked:
                                                        [
                                                          ...this.state
                                                            .MVDemurragePaymentChecked,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .MVDemurragePaymentChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      MVDemurragePaymentChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="484"
                                                color="primary"
                                                checked={this.state.MVDemurragePaymentChecked.includes(
                                                  "484"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      MVDemurragePaymentChecked:
                                                        [
                                                          ...this.state
                                                            .MVDemurragePaymentChecked,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .MVDemurragePaymentChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      MVDemurragePaymentChecked:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div
                                className="col-lg-12 p-1"
                                onClick={() =>
                                  this.setState({ mainSectionActiveIndex: 12 })
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <div
                                  className="row col-lg-12 rounded p-2"
                                  style={{
                                    border:
                                      this.state.mainSectionActiveIndex === 12
                                        ? "1px solid " + config.themeColor
                                        : "1px solid #dee2e6",
                                  }}
                                >
                                  <div className="col-lg-12 p-0">
                                    <div className="col-lg-12 p-0">
                                      <label className="access_title mb-0">
                                        Mother Vessel Purchase Financials
                                      </label>
                                      {this.state.mainSectionActiveIndex ===
                                      12 ? (
                                        ""
                                      ) : (
                                        <label className="access_sub_title mb-0">
                                          Stevedore Costing | Stevedore Costing
                                          Payments | PNBP Charges | PNBP Charges
                                          Payments | Freight Costing | Freight
                                          Costing Payments | Coal Procurement
                                          Charges | Coal Procurement Charges
                                          Payments | Sampling & Analysis Charges
                                          | Sampling & Analysis Charges Paymenys
                                          | Pre Shipment Inspection | Pre
                                          Shipment Inspection Payments |
                                          Floating Crane Charges | Floating
                                          Crane Charges Payments | Export Tax |
                                          Export Tax Payments | Documentation
                                          Charges | Documentation Charges
                                          Payments | Laporan Charges | Laporan
                                          Charges Payments | Dead Freight | Dead
                                          Freight Payments | Vessel Insurance |
                                          Vessel Insurance Payments{" "}
                                        </label>
                                      )}
                                    </div>
                                  </div>
                                  {this.state.mainSectionActiveIndex === 12 && (
                                    <div className="row col-lg-12 p-0 mb-0 mt-2">
                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Stevedore Costing
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="226,227,228, 229, 413, 464, 532"
                                                color="primary"
                                                checked={
                                                  this.state.mv_pur_fin_stevedore_costing.includes(
                                                    "226"
                                                  ) &&
                                                  this.state.mv_pur_fin_stevedore_costing.includes(
                                                    "227"
                                                  ) &&
                                                  this.state.mv_pur_fin_stevedore_costing.includes(
                                                    "228"
                                                  ) &&
                                                  this.state.mv_pur_fin_stevedore_costing.includes(
                                                    "229"
                                                  ) &&
                                                  this.state.mv_pur_fin_stevedore_costing.includes(
                                                    "413"
                                                  ) &&
                                                  this.state.mv_pur_fin_stevedore_costing.includes(
                                                    "464"
                                                  ) &&
                                                  this.state.mv_pur_fin_stevedore_costing.includes(
                                                    "532"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_stevedore_costing:
                                                        [
                                                          "226",
                                                          "227",
                                                          "228",
                                                          "229",
                                                          "413",
                                                          "464",
                                                          "532",
                                                        ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      mv_pur_fin_stevedore_costing:
                                                        [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="226"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_stevedore_costing.includes(
                                                  "226"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_stevedore_costing:
                                                        [
                                                          ...this.state
                                                            .mv_pur_fin_stevedore_costing,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_stevedore_costing;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_stevedore_costing:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="227"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_stevedore_costing.includes(
                                                  "227"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_stevedore_costing:
                                                        [
                                                          ...this.state
                                                            .mv_pur_fin_stevedore_costing,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_stevedore_costing;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_stevedore_costing:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="228"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_stevedore_costing.includes(
                                                  "228"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_stevedore_costing:
                                                        [
                                                          ...this.state
                                                            .mv_pur_fin_stevedore_costing,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_stevedore_costing;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_stevedore_costing:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="229"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_stevedore_costing.includes(
                                                  "229"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_stevedore_costing:
                                                        [
                                                          ...this.state
                                                            .mv_pur_fin_stevedore_costing,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_stevedore_costing;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_stevedore_costing:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="413"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_stevedore_costing.includes(
                                                  "413"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_stevedore_costing:
                                                        [
                                                          ...this.state
                                                            .mv_pur_fin_stevedore_costing,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_stevedore_costing;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_stevedore_costing:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>

                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="464"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_stevedore_costing.includes(
                                                  "464"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_stevedore_costing:
                                                        [
                                                          ...this.state
                                                            .mv_pur_fin_stevedore_costing,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_stevedore_costing;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_stevedore_costing:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="532"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_stevedore_costing.includes(
                                                  "532"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_stevedore_costing:
                                                        [
                                                          ...this.state
                                                            .mv_pur_fin_stevedore_costing,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_stevedore_costing;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_stevedore_costing:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Files
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>
                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Stevedore Costing Payments
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="230,231,232,233"
                                                color="primary"
                                                checked={
                                                  this.state.mv_pur_fin_stevedore_costing_payments.includes(
                                                    "230"
                                                  ) &&
                                                  this.state.mv_pur_fin_stevedore_costing_payments.includes(
                                                    "231"
                                                  ) &&
                                                  this.state.mv_pur_fin_stevedore_costing_payments.includes(
                                                    "232"
                                                  ) &&
                                                  this.state.mv_pur_fin_stevedore_costing_payments.includes(
                                                    "233"
                                                  ) &&
                                                  this.state.mv_pur_fin_stevedore_costing_payments.includes(
                                                    "414"
                                                  ) &&
                                                  this.state.mv_pur_fin_stevedore_costing_payments.includes(
                                                    "465"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_stevedore_costing_payments:
                                                        [
                                                          "230",
                                                          "231",
                                                          "232",
                                                          "233",
                                                          "414",
                                                          "465",
                                                        ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      mv_pur_fin_stevedore_costing_payments:
                                                        [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="230"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_stevedore_costing_payments.includes(
                                                  "230"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_stevedore_costing_payments:
                                                        [
                                                          ...this.state
                                                            .mv_pur_fin_stevedore_costing_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_stevedore_costing_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_stevedore_costing_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="231"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_stevedore_costing_payments.includes(
                                                  "231"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_stevedore_costing_payments:
                                                        [
                                                          ...this.state
                                                            .mv_pur_fin_stevedore_costing_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_stevedore_costing_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_stevedore_costing_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="232"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_stevedore_costing_payments.includes(
                                                  "232"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_stevedore_costing_payments:
                                                        [
                                                          ...this.state
                                                            .mv_pur_fin_stevedore_costing_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_stevedore_costing_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_stevedore_costing_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="233"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_stevedore_costing_payments.includes(
                                                  "233"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_stevedore_costing_payments:
                                                        [
                                                          ...this.state
                                                            .mv_pur_fin_stevedore_costing_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_stevedore_costing_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_stevedore_costing_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="414"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_stevedore_costing_payments.includes(
                                                  "414"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_stevedore_costing_payments:
                                                        [
                                                          ...this.state
                                                            .mv_pur_fin_stevedore_costing_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_stevedore_costing_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_stevedore_costing_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="465"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_stevedore_costing_payments.includes(
                                                  "465"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_stevedore_costing_payments:
                                                        [
                                                          ...this.state
                                                            .mv_pur_fin_stevedore_costing_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_stevedore_costing_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_stevedore_costing_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>

                                      {/**
                                       *
                                       *  PNBP Charges
                                       *
                                       */}
                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            PNBP Charges
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="627,628,629, 630, 635, 637, 639"
                                                color="primary"
                                                checked={
                                                  this.state.pnbp_charges.includes(
                                                    "627"
                                                  ) &&
                                                  this.state.pnbp_charges.includes(
                                                    "628"
                                                  ) &&
                                                  this.state.pnbp_charges.includes(
                                                    "629"
                                                  ) &&
                                                  this.state.pnbp_charges.includes(
                                                    "630"
                                                  ) &&
                                                  this.state.pnbp_charges.includes(
                                                    "635"
                                                  ) &&
                                                  this.state.pnbp_charges.includes(
                                                    "637"
                                                  ) &&
                                                  this.state.pnbp_charges.includes(
                                                    "639"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      pnbp_charges: [
                                                        "627",
                                                        "628",
                                                        "629",
                                                        "630",
                                                        "635",
                                                        "637",
                                                        "639",
                                                      ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      pnbp_charges: [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="627"
                                                color="primary"
                                                checked={this.state.pnbp_charges.includes(
                                                  "627"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      pnbp_charges: [
                                                        ...this.state
                                                          .pnbp_charges,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.pnbp_charges;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      pnbp_charges: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="628"
                                                color="primary"
                                                checked={this.state.pnbp_charges.includes(
                                                  "628"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      pnbp_charges: [
                                                        ...this.state
                                                          .pnbp_charges,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.pnbp_charges;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      pnbp_charges: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="629"
                                                color="primary"
                                                checked={this.state.pnbp_charges.includes(
                                                  "629"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      pnbp_charges: [
                                                        ...this.state
                                                          .pnbp_charges,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.pnbp_charges;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      pnbp_charges: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="630"
                                                color="primary"
                                                checked={this.state.pnbp_charges.includes(
                                                  "630"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      pnbp_charges: [
                                                        ...this.state
                                                          .pnbp_charges,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.pnbp_charges;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      pnbp_charges: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="635"
                                                color="primary"
                                                checked={this.state.pnbp_charges.includes(
                                                  "635"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      pnbp_charges: [
                                                        ...this.state
                                                          .pnbp_charges,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.pnbp_charges;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      pnbp_charges: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>

                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="637"
                                                color="primary"
                                                checked={this.state.pnbp_charges.includes(
                                                  "637"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      pnbp_charges: [
                                                        ...this.state
                                                          .pnbp_charges,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.pnbp_charges;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      pnbp_charges: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="639"
                                                color="primary"
                                                checked={this.state.pnbp_charges.includes(
                                                  "639"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      pnbp_charges: [
                                                        ...this.state
                                                          .pnbp_charges,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.pnbp_charges;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      pnbp_charges: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Files
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>

                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            PNBP Charge Payments
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="631,632,633,634,636,638"
                                                color="primary"
                                                checked={
                                                  this.state.pnbp_payments.includes(
                                                    "631"
                                                  ) &&
                                                  this.state.pnbp_payments.includes(
                                                    "632"
                                                  ) &&
                                                  this.state.pnbp_payments.includes(
                                                    "633"
                                                  ) &&
                                                  this.state.pnbp_payments.includes(
                                                    "634"
                                                  ) &&
                                                  this.state.pnbp_payments.includes(
                                                    "636"
                                                  ) &&
                                                  this.state.pnbp_payments.includes(
                                                    "638"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      pnbp_payments: [
                                                        "631",
                                                        "632",
                                                        "633",
                                                        "634",
                                                        "636",
                                                        "638",
                                                      ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      pnbp_payments: [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="631"
                                                color="primary"
                                                checked={this.state.pnbp_payments.includes(
                                                  "631"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      pnbp_payments: [
                                                        ...this.state
                                                          .pnbp_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.pnbp_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      pnbp_payments: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="632"
                                                color="primary"
                                                checked={this.state.pnbp_payments.includes(
                                                  "632"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      pnbp_payments: [
                                                        ...this.state
                                                          .pnbp_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.pnbp_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      pnbp_payments: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="633"
                                                color="primary"
                                                checked={this.state.pnbp_payments.includes(
                                                  "633"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      pnbp_payments: [
                                                        ...this.state
                                                          .pnbp_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.pnbp_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      pnbp_payments: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="634"
                                                color="primary"
                                                checked={this.state.pnbp_payments.includes(
                                                  "634"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      pnbp_payments: [
                                                        ...this.state
                                                          .pnbp_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.pnbp_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      pnbp_payments: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="636"
                                                color="primary"
                                                checked={this.state.pnbp_payments.includes(
                                                  "636"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      pnbp_payments: [
                                                        ...this.state
                                                          .pnbp_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.pnbp_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      pnbp_payments: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="638"
                                                color="primary"
                                                checked={this.state.pnbp_payments.includes(
                                                  "638"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      pnbp_payments: [
                                                        ...this.state
                                                          .pnbp_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.pnbp_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      pnbp_payments: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>

                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Freight Costing
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="379,380,381,382,417,468,528"
                                                color="primary"
                                                checked={
                                                  this.state.mv_pur_fin_freight_costing.includes(
                                                    "379"
                                                  ) &&
                                                  this.state.mv_pur_fin_freight_costing.includes(
                                                    "380"
                                                  ) &&
                                                  this.state.mv_pur_fin_freight_costing.includes(
                                                    "381"
                                                  ) &&
                                                  this.state.mv_pur_fin_freight_costing.includes(
                                                    "382"
                                                  ) &&
                                                  this.state.mv_pur_fin_freight_costing.includes(
                                                    "417"
                                                  ) &&
                                                  this.state.mv_pur_fin_freight_costing.includes(
                                                    "468"
                                                  ) &&
                                                  this.state.mv_pur_fin_freight_costing.includes(
                                                    "528"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_freight_costing:
                                                        [
                                                          "379",
                                                          "380",
                                                          "381",
                                                          "382",
                                                          "417",
                                                          "468",
                                                          "528",
                                                        ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      mv_pur_fin_freight_costing:
                                                        [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="379"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_freight_costing.includes(
                                                  "379"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_freight_costing:
                                                        [
                                                          ...this.state
                                                            .mv_pur_fin_freight_costing,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_freight_costing;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_freight_costing:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="380"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_freight_costing.includes(
                                                  "380"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_freight_costing:
                                                        [
                                                          ...this.state
                                                            .mv_pur_fin_freight_costing,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_freight_costing;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_freight_costing:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="381"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_freight_costing.includes(
                                                  "381"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_freight_costing:
                                                        [
                                                          ...this.state
                                                            .mv_pur_fin_freight_costing,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_freight_costing;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_freight_costing:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="382"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_freight_costing.includes(
                                                  "382"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_freight_costing:
                                                        [
                                                          ...this.state
                                                            .mv_pur_fin_freight_costing,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_freight_costing;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_freight_costing:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="417"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_freight_costing.includes(
                                                  "417"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_freight_costing:
                                                        [
                                                          ...this.state
                                                            .mv_pur_fin_freight_costing,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_freight_costing;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_freight_costing:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>

                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="468"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_freight_costing.includes(
                                                  "468"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_freight_costing:
                                                        [
                                                          ...this.state
                                                            .mv_pur_fin_freight_costing,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_freight_costing;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_freight_costing:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="528"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_freight_costing.includes(
                                                  "528"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_freight_costing:
                                                        [
                                                          ...this.state
                                                            .mv_pur_fin_freight_costing,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_freight_costing;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_freight_costing:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Files
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>
                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Freight Costing Payments
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="383,384,385,386,418,469"
                                                color="primary"
                                                checked={
                                                  this.state.mv_pur_fin_freight_costing_payments.includes(
                                                    "383"
                                                  ) &&
                                                  this.state.mv_pur_fin_freight_costing_payments.includes(
                                                    "384"
                                                  ) &&
                                                  this.state.mv_pur_fin_freight_costing_payments.includes(
                                                    "385"
                                                  ) &&
                                                  this.state.mv_pur_fin_freight_costing_payments.includes(
                                                    "386"
                                                  ) &&
                                                  this.state.mv_pur_fin_freight_costing_payments.includes(
                                                    "418"
                                                  ) &&
                                                  this.state.mv_pur_fin_freight_costing_payments.includes(
                                                    "469"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_freight_costing_payments:
                                                        [
                                                          "383",
                                                          "384",
                                                          "385",
                                                          "386",
                                                          "418",
                                                          "469",
                                                        ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      mv_pur_fin_freight_costing_payments:
                                                        [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="383"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_freight_costing_payments.includes(
                                                  "383"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_freight_costing_payments:
                                                        [
                                                          ...this.state
                                                            .mv_pur_fin_freight_costing_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_freight_costing_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_freight_costing_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="384"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_freight_costing_payments.includes(
                                                  "384"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_freight_costing_payments:
                                                        [
                                                          ...this.state
                                                            .mv_pur_fin_freight_costing_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_freight_costing_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_freight_costing_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="385"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_freight_costing_payments.includes(
                                                  "385"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_freight_costing_payments:
                                                        [
                                                          ...this.state
                                                            .mv_pur_fin_freight_costing_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_freight_costing_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_freight_costing_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="386"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_freight_costing_payments.includes(
                                                  "386"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_freight_costing_payments:
                                                        [
                                                          ...this.state
                                                            .mv_pur_fin_freight_costing_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_freight_costing_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_freight_costing_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="418"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_freight_costing_payments.includes(
                                                  "418"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_freight_costing_payments:
                                                        [
                                                          ...this.state
                                                            .mv_pur_fin_freight_costing_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_freight_costing_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_freight_costing_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="469"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_freight_costing_payments.includes(
                                                  "469"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_freight_costing_payments:
                                                        [
                                                          ...this.state
                                                            .mv_pur_fin_freight_costing_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_freight_costing_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_freight_costing_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>

                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Coal Procurement Charges
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="234,235,236,237,415,416,519"
                                                color="primary"
                                                checked={
                                                  this.state.mv_pur_fin_cpc.includes(
                                                    "234"
                                                  ) &&
                                                  this.state.mv_pur_fin_cpc.includes(
                                                    "235"
                                                  ) &&
                                                  this.state.mv_pur_fin_cpc.includes(
                                                    "236"
                                                  ) &&
                                                  this.state.mv_pur_fin_cpc.includes(
                                                    "237"
                                                  ) &&
                                                  this.state.mv_pur_fin_cpc.includes(
                                                    "415"
                                                  ) &&
                                                  this.state.mv_pur_fin_cpc.includes(
                                                    "466"
                                                  ) &&
                                                  this.state.mv_pur_fin_cpc.includes(
                                                    "519"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_cpc: [
                                                        "234",
                                                        "235",
                                                        "236",
                                                        "237",
                                                        "415",
                                                        "466",
                                                        "519",
                                                      ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      mv_pur_fin_cpc: [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="234"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_cpc.includes(
                                                  "234"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_cpc: [
                                                        ...this.state
                                                          .mv_pur_fin_cpc,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.mv_pur_fin_cpc;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_cpc: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="235"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_cpc.includes(
                                                  "235"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_cpc: [
                                                        ...this.state
                                                          .mv_pur_fin_cpc,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.mv_pur_fin_cpc;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_cpc: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="236"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_cpc.includes(
                                                  "236"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_cpc: [
                                                        ...this.state
                                                          .mv_pur_fin_cpc,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.mv_pur_fin_cpc;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_cpc: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="237"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_cpc.includes(
                                                  "237"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_cpc: [
                                                        ...this.state
                                                          .mv_pur_fin_cpc,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.mv_pur_fin_cpc;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_cpc: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="415"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_cpc.includes(
                                                  "415"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_cpc: [
                                                        ...this.state
                                                          .mv_pur_fin_cpc,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.mv_pur_fin_cpc;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_cpc: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>

                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="466"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_cpc.includes(
                                                  "466"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_cpc: [
                                                        ...this.state
                                                          .mv_pur_fin_cpc,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.mv_pur_fin_cpc;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_cpc: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="519"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_cpc.includes(
                                                  "519"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_cpc: [
                                                        ...this.state
                                                          .mv_pur_fin_cpc,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.mv_pur_fin_cpc;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_cpc: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Files
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>
                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Coal Procurement Charges Payments
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="238,239,240,241"
                                                color="primary"
                                                checked={
                                                  this.state.mv_pur_fin_cpc_payments.includes(
                                                    "238"
                                                  ) &&
                                                  this.state.mv_pur_fin_cpc_payments.includes(
                                                    "239"
                                                  ) &&
                                                  this.state.mv_pur_fin_cpc_payments.includes(
                                                    "240"
                                                  ) &&
                                                  this.state.mv_pur_fin_cpc_payments.includes(
                                                    "241"
                                                  ) &&
                                                  this.state.mv_pur_fin_cpc_payments.includes(
                                                    "416"
                                                  ) &&
                                                  this.state.mv_pur_fin_cpc_payments.includes(
                                                    "467"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_cpc_payments: [
                                                        "238",
                                                        "239",
                                                        "240",
                                                        "241",
                                                        "416",
                                                        "467",
                                                      ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      mv_pur_fin_cpc_payments:
                                                        [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="238"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_cpc_payments.includes(
                                                  "238"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_cpc_payments: [
                                                        ...this.state
                                                          .mv_pur_fin_cpc_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_cpc_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_cpc_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="239"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_cpc_payments.includes(
                                                  "239"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_cpc_payments: [
                                                        ...this.state
                                                          .mv_pur_fin_cpc_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_cpc_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_cpc_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="240"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_cpc_payments.includes(
                                                  "240"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_cpc_payments: [
                                                        ...this.state
                                                          .mv_pur_fin_cpc_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_cpc_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_cpc_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="241"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_cpc_payments.includes(
                                                  "241"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_cpc_payments: [
                                                        ...this.state
                                                          .mv_pur_fin_cpc_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_cpc_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_cpc_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="416"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_cpc_payments.includes(
                                                  "416"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_cpc_payments: [
                                                        ...this.state
                                                          .mv_pur_fin_cpc_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_cpc_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_cpc_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="467"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_cpc_payments.includes(
                                                  "467"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_cpc_payments: [
                                                        ...this.state
                                                          .mv_pur_fin_cpc_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_cpc_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_cpc_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>

                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Sampling & Analysis Charges
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="242,243,244,245,419,470,520"
                                                color="primary"
                                                checked={
                                                  this.state.mv_pur_fin_sac.includes(
                                                    "242"
                                                  ) &&
                                                  this.state.mv_pur_fin_sac.includes(
                                                    "243"
                                                  ) &&
                                                  this.state.mv_pur_fin_sac.includes(
                                                    "244"
                                                  ) &&
                                                  this.state.mv_pur_fin_sac.includes(
                                                    "245"
                                                  ) &&
                                                  this.state.mv_pur_fin_sac.includes(
                                                    "419"
                                                  ) &&
                                                  this.state.mv_pur_fin_sac.includes(
                                                    "470"
                                                  ) &&
                                                  this.state.mv_pur_fin_sac.includes(
                                                    "520"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_sac: [
                                                        "242",
                                                        "243",
                                                        "244",
                                                        "245",
                                                        "419",
                                                        "470",
                                                        "520",
                                                      ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      mv_pur_fin_sac: [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="242"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_sac.includes(
                                                  "242"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_sac: [
                                                        ...this.state
                                                          .mv_pur_fin_sac,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.mv_pur_fin_sac;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_sac: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="243"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_sac.includes(
                                                  "243"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_sac: [
                                                        ...this.state
                                                          .mv_pur_fin_sac,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.mv_pur_fin_sac;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_sac: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="244"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_sac.includes(
                                                  "244"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_sac: [
                                                        ...this.state
                                                          .mv_pur_fin_sac,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.mv_pur_fin_sac;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_sac: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="245"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_sac.includes(
                                                  "245"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_sac: [
                                                        ...this.state
                                                          .mv_pur_fin_sac,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.mv_pur_fin_sac;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_sac: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="419"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_sac.includes(
                                                  "419"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_sac: [
                                                        ...this.state
                                                          .mv_pur_fin_sac,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.mv_pur_fin_sac;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_sac: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>

                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="470"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_sac.includes(
                                                  "470"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_sac: [
                                                        ...this.state
                                                          .mv_pur_fin_sac,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.mv_pur_fin_sac;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_sac: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="520"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_sac.includes(
                                                  "520"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_sac: [
                                                        ...this.state
                                                          .mv_pur_fin_sac,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.mv_pur_fin_sac;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_sac: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Files
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>
                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Sampling & Analysis Charges Payments
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="246,247,248,249,420,471"
                                                color="primary"
                                                checked={
                                                  this.state.mv_pur_fin_sac_payments.includes(
                                                    "246"
                                                  ) &&
                                                  this.state.mv_pur_fin_sac_payments.includes(
                                                    "247"
                                                  ) &&
                                                  this.state.mv_pur_fin_sac_payments.includes(
                                                    "248"
                                                  ) &&
                                                  this.state.mv_pur_fin_sac_payments.includes(
                                                    "249"
                                                  ) &&
                                                  this.state.mv_pur_fin_sac_payments.includes(
                                                    "420"
                                                  ) &&
                                                  this.state.mv_pur_fin_sac_payments.includes(
                                                    "471"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_sac_payments: [
                                                        "246",
                                                        "247",
                                                        "248",
                                                        "249",
                                                        "420",
                                                        "471",
                                                      ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      mv_pur_fin_sac_payments:
                                                        [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="246"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_sac_payments.includes(
                                                  "246"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_sac_payments: [
                                                        ...this.state
                                                          .mv_pur_fin_sac_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_sac_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_sac_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="247"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_sac_payments.includes(
                                                  "247"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_sac_payments: [
                                                        ...this.state
                                                          .mv_pur_fin_sac_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_sac_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_sac_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="248"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_sac_payments.includes(
                                                  "248"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_sac_payments: [
                                                        ...this.state
                                                          .mv_pur_fin_sac_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_sac_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_sac_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="249"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_sac_payments.includes(
                                                  "249"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_sac_payments: [
                                                        ...this.state
                                                          .mv_pur_fin_sac_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_sac_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_sac_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="420"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_sac_payments.includes(
                                                  "420"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_sac_payments: [
                                                        ...this.state
                                                          .mv_pur_fin_sac_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_sac_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_sac_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="471"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_sac_payments.includes(
                                                  "471"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_sac_payments: [
                                                        ...this.state
                                                          .mv_pur_fin_sac_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_sac_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_sac_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>

                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Pre Shipment Inspection
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="250,251,252,253,421,472,521"
                                                color="primary"
                                                checked={
                                                  this.state.mv_pur_fin_pre_shipment_inspection.includes(
                                                    "250"
                                                  ) &&
                                                  this.state.mv_pur_fin_pre_shipment_inspection.includes(
                                                    "251"
                                                  ) &&
                                                  this.state.mv_pur_fin_pre_shipment_inspection.includes(
                                                    "252"
                                                  ) &&
                                                  this.state.mv_pur_fin_pre_shipment_inspection.includes(
                                                    "253"
                                                  ) &&
                                                  this.state.mv_pur_fin_pre_shipment_inspection.includes(
                                                    "421"
                                                  ) &&
                                                  this.state.mv_pur_fin_pre_shipment_inspection.includes(
                                                    "472"
                                                  ) &&
                                                  this.state.mv_pur_fin_pre_shipment_inspection.includes(
                                                    "521"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_pre_shipment_inspection:
                                                        [
                                                          "250",
                                                          "251",
                                                          "252",
                                                          "253",
                                                          "421",
                                                          "472",
                                                          "521",
                                                        ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      mv_pur_fin_pre_shipment_inspection:
                                                        [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="250"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_pre_shipment_inspection.includes(
                                                  "250"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_pre_shipment_inspection:
                                                        [
                                                          ...this.state
                                                            .mv_pur_fin_pre_shipment_inspection,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_pre_shipment_inspection;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_pre_shipment_inspection:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="251"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_pre_shipment_inspection.includes(
                                                  "251"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_pre_shipment_inspection:
                                                        [
                                                          ...this.state
                                                            .mv_pur_fin_pre_shipment_inspection,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_pre_shipment_inspection;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_pre_shipment_inspection:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="252"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_pre_shipment_inspection.includes(
                                                  "252"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_pre_shipment_inspection:
                                                        [
                                                          ...this.state
                                                            .mv_pur_fin_pre_shipment_inspection,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_pre_shipment_inspection;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_pre_shipment_inspection:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="253"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_pre_shipment_inspection.includes(
                                                  "253"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_pre_shipment_inspection:
                                                        [
                                                          ...this.state
                                                            .mv_pur_fin_pre_shipment_inspection,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_pre_shipment_inspection;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_pre_shipment_inspection:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="421"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_pre_shipment_inspection.includes(
                                                  "421"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_pre_shipment_inspection:
                                                        [
                                                          ...this.state
                                                            .mv_pur_fin_pre_shipment_inspection,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_pre_shipment_inspection;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_pre_shipment_inspection:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>

                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="472"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_pre_shipment_inspection.includes(
                                                  "472"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_pre_shipment_inspection:
                                                        [
                                                          ...this.state
                                                            .mv_pur_fin_pre_shipment_inspection,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_pre_shipment_inspection;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_pre_shipment_inspection:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="521"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_pre_shipment_inspection.includes(
                                                  "521"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_pre_shipment_inspection:
                                                        [
                                                          ...this.state
                                                            .mv_pur_fin_pre_shipment_inspection,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_pre_shipment_inspection;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_pre_shipment_inspection:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Files
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>
                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Pre Shipment Inspection Payments
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="254,255,256,257"
                                                color="primary"
                                                checked={
                                                  this.state.mv_pur_fin_pre_shipment_inspection_payments.includes(
                                                    "254"
                                                  ) &&
                                                  this.state.mv_pur_fin_pre_shipment_inspection_payments.includes(
                                                    "255"
                                                  ) &&
                                                  this.state.mv_pur_fin_pre_shipment_inspection_payments.includes(
                                                    "256"
                                                  ) &&
                                                  this.state.mv_pur_fin_pre_shipment_inspection_payments.includes(
                                                    "257"
                                                  ) &&
                                                  this.state.mv_pur_fin_pre_shipment_inspection_payments.includes(
                                                    "422"
                                                  ) &&
                                                  this.state.mv_pur_fin_pre_shipment_inspection_payments.includes(
                                                    "473"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_pre_shipment_inspection_payments:
                                                        [
                                                          "254",
                                                          "255",
                                                          "256",
                                                          "257",
                                                          "422",
                                                          "473",
                                                        ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      mv_pur_fin_pre_shipment_inspection_payments:
                                                        [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="254"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_pre_shipment_inspection_payments.includes(
                                                  "254"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_pre_shipment_inspection_payments:
                                                        [
                                                          ...this.state
                                                            .mv_pur_fin_pre_shipment_inspection_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_pre_shipment_inspection_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_pre_shipment_inspection_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="255"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_pre_shipment_inspection_payments.includes(
                                                  "255"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_pre_shipment_inspection_payments:
                                                        [
                                                          ...this.state
                                                            .mv_pur_fin_pre_shipment_inspection_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_pre_shipment_inspection_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_pre_shipment_inspection_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="256"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_pre_shipment_inspection_payments.includes(
                                                  "256"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_pre_shipment_inspection_payments:
                                                        [
                                                          ...this.state
                                                            .mv_pur_fin_pre_shipment_inspection_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_pre_shipment_inspection_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_pre_shipment_inspection_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="257"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_pre_shipment_inspection_payments.includes(
                                                  "257"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_pre_shipment_inspection_payments:
                                                        [
                                                          ...this.state
                                                            .mv_pur_fin_pre_shipment_inspection_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_pre_shipment_inspection_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_pre_shipment_inspection_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="422"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_pre_shipment_inspection_payments.includes(
                                                  "422"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_pre_shipment_inspection_payments:
                                                        [
                                                          ...this.state
                                                            .mv_pur_fin_pre_shipment_inspection_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_pre_shipment_inspection_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_pre_shipment_inspection_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="473"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_pre_shipment_inspection_payments.includes(
                                                  "473"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_pre_shipment_inspection_payments:
                                                        [
                                                          ...this.state
                                                            .mv_pur_fin_pre_shipment_inspection_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_pre_shipment_inspection_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_pre_shipment_inspection_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>

                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Floating Crane Charges
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="258,259,260,261,423,474,522"
                                                color="primary"
                                                checked={
                                                  this.state.mv_pur_fin_floating_crane.includes(
                                                    "258"
                                                  ) &&
                                                  this.state.mv_pur_fin_floating_crane.includes(
                                                    "259"
                                                  ) &&
                                                  this.state.mv_pur_fin_floating_crane.includes(
                                                    "260"
                                                  ) &&
                                                  this.state.mv_pur_fin_floating_crane.includes(
                                                    "261"
                                                  ) &&
                                                  this.state.mv_pur_fin_floating_crane.includes(
                                                    "423"
                                                  ) &&
                                                  this.state.mv_pur_fin_floating_crane.includes(
                                                    "474"
                                                  ) &&
                                                  this.state.mv_pur_fin_floating_crane.includes(
                                                    "522"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_floating_crane:
                                                        [
                                                          "258",
                                                          "259",
                                                          "260",
                                                          "261",
                                                          "423",
                                                          "474",
                                                          "522",
                                                        ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      mv_pur_fin_floating_crane:
                                                        [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="258"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_floating_crane.includes(
                                                  "258"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_floating_crane:
                                                        [
                                                          ...this.state
                                                            .mv_pur_fin_floating_crane,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_floating_crane;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_floating_crane:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="259"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_floating_crane.includes(
                                                  "259"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_floating_crane:
                                                        [
                                                          ...this.state
                                                            .mv_pur_fin_floating_crane,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_floating_crane;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_floating_crane:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="260"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_floating_crane.includes(
                                                  "260"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_floating_crane:
                                                        [
                                                          ...this.state
                                                            .mv_pur_fin_floating_crane,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_floating_crane;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_floating_crane:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="261"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_floating_crane.includes(
                                                  "261"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_floating_crane:
                                                        [
                                                          ...this.state
                                                            .mv_pur_fin_floating_crane,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_floating_crane;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_floating_crane:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="423"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_floating_crane.includes(
                                                  "423"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_floating_crane:
                                                        [
                                                          ...this.state
                                                            .mv_pur_fin_floating_crane,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_floating_crane;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_floating_crane:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>

                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="474"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_floating_crane.includes(
                                                  "474"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_floating_crane:
                                                        [
                                                          ...this.state
                                                            .mv_pur_fin_floating_crane,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_floating_crane;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_floating_crane:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="522"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_floating_crane.includes(
                                                  "522"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_floating_crane:
                                                        [
                                                          ...this.state
                                                            .mv_pur_fin_floating_crane,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_floating_crane;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_floating_crane:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Files
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>
                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Floating Crane Charges Payments
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="262,263,264,265,424,475"
                                                color="primary"
                                                checked={
                                                  this.state.mv_pur_fin_floating_crane_payments.includes(
                                                    "262"
                                                  ) &&
                                                  this.state.mv_pur_fin_floating_crane_payments.includes(
                                                    "263"
                                                  ) &&
                                                  this.state.mv_pur_fin_floating_crane_payments.includes(
                                                    "264"
                                                  ) &&
                                                  this.state.mv_pur_fin_floating_crane_payments.includes(
                                                    "265"
                                                  ) &&
                                                  this.state.mv_pur_fin_floating_crane_payments.includes(
                                                    "424"
                                                  ) &&
                                                  this.state.mv_pur_fin_floating_crane_payments.includes(
                                                    "475"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_floating_crane_payments:
                                                        [
                                                          "262",
                                                          "263",
                                                          "264",
                                                          "265",
                                                          "424",
                                                          "475",
                                                        ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      mv_pur_fin_floating_crane_payments:
                                                        [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="262"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_floating_crane_payments.includes(
                                                  "262"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_floating_crane_payments:
                                                        [
                                                          ...this.state
                                                            .mv_pur_fin_floating_crane_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_floating_crane_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_floating_crane_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="263"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_floating_crane_payments.includes(
                                                  "263"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_floating_crane_payments:
                                                        [
                                                          ...this.state
                                                            .mv_pur_fin_floating_crane_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_floating_crane_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_floating_crane_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="264"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_floating_crane_payments.includes(
                                                  "264"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_floating_crane_payments:
                                                        [
                                                          ...this.state
                                                            .mv_pur_fin_floating_crane_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_floating_crane_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_floating_crane_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="265"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_floating_crane_payments.includes(
                                                  "265"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_floating_crane_payments:
                                                        [
                                                          ...this.state
                                                            .mv_pur_fin_floating_crane_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_floating_crane_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_floating_crane_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="424"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_floating_crane_payments.includes(
                                                  "424"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_floating_crane_payments:
                                                        [
                                                          ...this.state
                                                            .mv_pur_fin_floating_crane_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_floating_crane_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_floating_crane_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="475"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_floating_crane_payments.includes(
                                                  "475"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_floating_crane_payments:
                                                        [
                                                          ...this.state
                                                            .mv_pur_fin_floating_crane_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_floating_crane_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_floating_crane_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>

                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Export Tax
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="266,267,268,269,425,476,523"
                                                color="primary"
                                                checked={
                                                  this.state.mv_pur_fin_export_tax.includes(
                                                    "266"
                                                  ) &&
                                                  this.state.mv_pur_fin_export_tax.includes(
                                                    "267"
                                                  ) &&
                                                  this.state.mv_pur_fin_export_tax.includes(
                                                    "268"
                                                  ) &&
                                                  this.state.mv_pur_fin_export_tax.includes(
                                                    "269"
                                                  ) &&
                                                  this.state.mv_pur_fin_export_tax.includes(
                                                    "425"
                                                  ) &&
                                                  this.state.mv_pur_fin_export_tax.includes(
                                                    "476"
                                                  ) &&
                                                  this.state.mv_pur_fin_export_tax.includes(
                                                    "523"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_export_tax: [
                                                        "266",
                                                        "267",
                                                        "268",
                                                        "269",
                                                        "425",
                                                        "476",
                                                        "523",
                                                      ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      mv_pur_fin_export_tax: [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="266"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_export_tax.includes(
                                                  "266"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_export_tax: [
                                                        ...this.state
                                                          .mv_pur_fin_export_tax,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_export_tax;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_export_tax:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="267"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_export_tax.includes(
                                                  "267"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_export_tax: [
                                                        ...this.state
                                                          .mv_pur_fin_export_tax,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_export_tax;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_export_tax:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="268"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_export_tax.includes(
                                                  "268"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_export_tax: [
                                                        ...this.state
                                                          .mv_pur_fin_export_tax,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_export_tax;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_export_tax:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="269"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_export_tax.includes(
                                                  "269"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_export_tax: [
                                                        ...this.state
                                                          .mv_pur_fin_export_tax,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_export_tax;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_export_tax:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="425"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_export_tax.includes(
                                                  "425"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_export_tax: [
                                                        ...this.state
                                                          .mv_pur_fin_export_tax,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_export_tax;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_export_tax:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>

                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="476"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_export_tax.includes(
                                                  "476"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_export_tax: [
                                                        ...this.state
                                                          .mv_pur_fin_export_tax,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_export_tax;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_export_tax:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="523"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_export_tax.includes(
                                                  "523"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_export_tax: [
                                                        ...this.state
                                                          .mv_pur_fin_export_tax,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_export_tax;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_export_tax:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Files
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>
                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Export Tax Payments
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="270,271,272,273,426,477"
                                                color="primary"
                                                checked={
                                                  this.state.mv_pur_fin_export_tax_payments.includes(
                                                    "270"
                                                  ) &&
                                                  this.state.mv_pur_fin_export_tax_payments.includes(
                                                    "271"
                                                  ) &&
                                                  this.state.mv_pur_fin_export_tax_payments.includes(
                                                    "272"
                                                  ) &&
                                                  this.state.mv_pur_fin_export_tax_payments.includes(
                                                    "273"
                                                  ) &&
                                                  this.state.mv_pur_fin_export_tax_payments.includes(
                                                    "426"
                                                  ) &&
                                                  this.state.mv_pur_fin_export_tax_payments.includes(
                                                    "477"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_export_tax_payments:
                                                        [
                                                          "270",
                                                          "271",
                                                          "272",
                                                          "273",
                                                          "426",
                                                          "477",
                                                        ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      mv_pur_fin_export_tax_payments:
                                                        [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="270"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_export_tax_payments.includes(
                                                  "270"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_export_tax_payments:
                                                        [
                                                          ...this.state
                                                            .mv_pur_fin_export_tax_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_export_tax_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_export_tax_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="271"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_export_tax_payments.includes(
                                                  "271"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_export_tax_payments:
                                                        [
                                                          ...this.state
                                                            .mv_pur_fin_export_tax_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_export_tax_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_export_tax_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="272"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_export_tax_payments.includes(
                                                  "272"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_export_tax_payments:
                                                        [
                                                          ...this.state
                                                            .mv_pur_fin_export_tax_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_export_tax_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_export_tax_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="273"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_export_tax_payments.includes(
                                                  "273"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_export_tax_payments:
                                                        [
                                                          ...this.state
                                                            .mv_pur_fin_export_tax_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_export_tax_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_export_tax_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="426"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_export_tax_payments.includes(
                                                  "426"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_export_tax_payments:
                                                        [
                                                          ...this.state
                                                            .mv_pur_fin_export_tax_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_export_tax_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_export_tax_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="477"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_export_tax_payments.includes(
                                                  "477"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_export_tax_payments:
                                                        [
                                                          ...this.state
                                                            .mv_pur_fin_export_tax_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_export_tax_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_export_tax_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>

                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Documentation Charges
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="274,275,276,277,427,478,524"
                                                color="primary"
                                                checked={
                                                  this.state.mv_pur_fin_doc.includes(
                                                    "274"
                                                  ) &&
                                                  this.state.mv_pur_fin_doc.includes(
                                                    "275"
                                                  ) &&
                                                  this.state.mv_pur_fin_doc.includes(
                                                    "276"
                                                  ) &&
                                                  this.state.mv_pur_fin_doc.includes(
                                                    "277"
                                                  ) &&
                                                  this.state.mv_pur_fin_doc.includes(
                                                    "427"
                                                  ) &&
                                                  this.state.mv_pur_fin_doc.includes(
                                                    "478"
                                                  ) &&
                                                  this.state.mv_pur_fin_doc.includes(
                                                    "524"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_doc: [
                                                        "274",
                                                        "275",
                                                        "276",
                                                        "277",
                                                        "427",
                                                        "478",
                                                        "524",
                                                      ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      mv_pur_fin_doc: [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="274"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_doc.includes(
                                                  "274"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_doc: [
                                                        ...this.state
                                                          .mv_pur_fin_doc,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.mv_pur_fin_doc;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_doc: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="275"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_doc.includes(
                                                  "275"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_doc: [
                                                        ...this.state
                                                          .mv_pur_fin_doc,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.mv_pur_fin_doc;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_doc: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="276"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_doc.includes(
                                                  "276"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_doc: [
                                                        ...this.state
                                                          .mv_pur_fin_doc,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.mv_pur_fin_doc;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_doc: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="277"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_doc.includes(
                                                  "277"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_doc: [
                                                        ...this.state
                                                          .mv_pur_fin_doc,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.mv_pur_fin_doc;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_doc: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="427"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_doc.includes(
                                                  "427"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_doc: [
                                                        ...this.state
                                                          .mv_pur_fin_doc,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.mv_pur_fin_doc;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_doc: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>

                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="478"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_doc.includes(
                                                  "478"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_doc: [
                                                        ...this.state
                                                          .mv_pur_fin_doc,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.mv_pur_fin_doc;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_doc: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="524"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_doc.includes(
                                                  "524"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_doc: [
                                                        ...this.state
                                                          .mv_pur_fin_doc,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.mv_pur_fin_doc;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_doc: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Files
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>

                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Documentation Charges Payments
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="278,279,280,281"
                                                color="primary"
                                                checked={
                                                  this.state.mv_pur_fin_doc_payments.includes(
                                                    "278"
                                                  ) &&
                                                  this.state.mv_pur_fin_doc_payments.includes(
                                                    "279"
                                                  ) &&
                                                  this.state.mv_pur_fin_doc_payments.includes(
                                                    "280"
                                                  ) &&
                                                  this.state.mv_pur_fin_doc_payments.includes(
                                                    "281"
                                                  ) &&
                                                  this.state.mv_pur_fin_doc_payments.includes(
                                                    "428"
                                                  ) &&
                                                  this.state.mv_pur_fin_doc_payments.includes(
                                                    "479"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_doc_payments: [
                                                        "278",
                                                        "279",
                                                        "280",
                                                        "281",
                                                        "428",
                                                        "479",
                                                      ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      mv_pur_fin_doc_payments:
                                                        [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="278"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_doc_payments.includes(
                                                  "278"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_doc_payments: [
                                                        ...this.state
                                                          .mv_pur_fin_doc_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_doc_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_doc_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="279"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_doc_payments.includes(
                                                  "279"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_doc_payments: [
                                                        ...this.state
                                                          .mv_pur_fin_doc_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_doc_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_doc_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="280"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_doc_payments.includes(
                                                  "280"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_doc_payments: [
                                                        ...this.state
                                                          .mv_pur_fin_doc_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_doc_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_doc_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="281"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_doc_payments.includes(
                                                  "281"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_doc_payments: [
                                                        ...this.state
                                                          .mv_pur_fin_doc_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_doc_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_doc_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="428"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_doc_payments.includes(
                                                  "428"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_doc_payments: [
                                                        ...this.state
                                                          .mv_pur_fin_doc_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_doc_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_doc_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="479"
                                                color="primary"
                                                checked={this.state.mv_pur_fin_doc_payments.includes(
                                                  "479"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_pur_fin_doc_payments: [
                                                        ...this.state
                                                          .mv_pur_fin_doc_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_pur_fin_doc_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_pur_fin_doc_payments:
                                                        data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>

                                      {/**
                                       *
                                       *  LAPORAN CHARGES
                                       *
                                       */}

                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Laporan Charges
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="614,615,616,617,618,619,620"
                                                color="primary"
                                                checked={
                                                  this.state.mv_laprn_charges.includes(
                                                    "614"
                                                  ) &&
                                                  this.state.mv_laprn_charges.includes(
                                                    "615"
                                                  ) &&
                                                  this.state.mv_laprn_charges.includes(
                                                    "616"
                                                  ) &&
                                                  this.state.mv_laprn_charges.includes(
                                                    "617"
                                                  ) &&
                                                  this.state.mv_laprn_charges.includes(
                                                    "618"
                                                  ) &&
                                                  this.state.mv_laprn_charges.includes(
                                                    "619"
                                                  ) &&
                                                  this.state.mv_laprn_charges.includes(
                                                    "620"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_laprn_charges: [
                                                        "614",
                                                        "615",
                                                        "616",
                                                        "617",
                                                        "618",
                                                        "619",
                                                        "620",
                                                      ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      mv_laprn_charges: [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="614"
                                                color="primary"
                                                checked={this.state.mv_laprn_charges.includes(
                                                  "614"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_laprn_charges: [
                                                        ...this.state
                                                          .mv_laprn_charges,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_laprn_charges;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_laprn_charges: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="615"
                                                color="primary"
                                                checked={this.state.mv_laprn_charges.includes(
                                                  "615"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_laprn_charges: [
                                                        ...this.state
                                                          .mv_laprn_charges,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_laprn_charges;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_laprn_charges: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="616"
                                                color="primary"
                                                checked={this.state.mv_laprn_charges.includes(
                                                  "616"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_laprn_charges: [
                                                        ...this.state
                                                          .mv_laprn_charges,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_laprn_charges;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_laprn_charges: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="617"
                                                color="primary"
                                                checked={this.state.mv_laprn_charges.includes(
                                                  "617"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_laprn_charges: [
                                                        ...this.state
                                                          .mv_laprn_charges,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_laprn_charges;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_laprn_charges: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="618"
                                                color="primary"
                                                checked={this.state.mv_laprn_charges.includes(
                                                  "618"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_laprn_charges: [
                                                        ...this.state
                                                          .mv_laprn_charges,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_laprn_charges;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_laprn_charges: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>

                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="619"
                                                color="primary"
                                                checked={this.state.mv_laprn_charges.includes(
                                                  "619"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_laprn_charges: [
                                                        ...this.state
                                                          .mv_laprn_charges,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_laprn_charges;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_laprn_charges: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="620"
                                                color="primary"
                                                checked={this.state.mv_laprn_charges.includes(
                                                  "620"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_laprn_charges: [
                                                        ...this.state
                                                          .mv_laprn_charges,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_laprn_charges;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_laprn_charges: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Files
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>

                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Laporan Charges Payments
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="621,622,623,624,625,626"
                                                color="primary"
                                                checked={
                                                  this.state.mv_laprn_payments.includes(
                                                    "621"
                                                  ) &&
                                                  this.state.mv_laprn_payments.includes(
                                                    "622"
                                                  ) &&
                                                  this.state.mv_laprn_payments.includes(
                                                    "623"
                                                  ) &&
                                                  this.state.mv_laprn_payments.includes(
                                                    "624"
                                                  ) &&
                                                  this.state.mv_laprn_payments.includes(
                                                    "625"
                                                  ) &&
                                                  this.state.mv_laprn_payments.includes(
                                                    "626"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_laprn_payments: [
                                                        "621",
                                                        "622",
                                                        "623",
                                                        "624",
                                                        "625",
                                                        "626",
                                                      ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      mv_laprn_payments: [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="621"
                                                color="primary"
                                                checked={this.state.mv_laprn_payments.includes(
                                                  "621"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_laprn_payments: [
                                                        ...this.state
                                                          .mv_laprn_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_laprn_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_laprn_payments: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="622"
                                                color="primary"
                                                checked={this.state.mv_laprn_payments.includes(
                                                  "622"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_laprn_payments: [
                                                        ...this.state
                                                          .mv_laprn_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_laprn_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_laprn_payments: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="623"
                                                color="primary"
                                                checked={this.state.mv_laprn_payments.includes(
                                                  "623"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_laprn_payments: [
                                                        ...this.state
                                                          .mv_laprn_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_laprn_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_laprn_payments: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="624"
                                                color="primary"
                                                checked={this.state.mv_laprn_payments.includes(
                                                  "624"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_laprn_payments: [
                                                        ...this.state
                                                          .mv_laprn_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_laprn_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_laprn_payments: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="625"
                                                color="primary"
                                                checked={this.state.mv_laprn_payments.includes(
                                                  "625"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_laprn_payments: [
                                                        ...this.state
                                                          .mv_laprn_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_laprn_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_laprn_payments: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="626"
                                                color="primary"
                                                checked={this.state.mv_laprn_payments.includes(
                                                  "626"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_laprn_payments: [
                                                        ...this.state
                                                          .mv_laprn_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_laprn_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      mv_laprn_payments: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>

                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Dead Freight
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="562,563,564,565,566,567"
                                                color="primary"
                                                checked={
                                                  this.state.mv_dead_freight.includes(
                                                    "562"
                                                  ) &&
                                                  this.state.mv_dead_freight.includes(
                                                    "563"
                                                  ) &&
                                                  this.state.mv_dead_freight.includes(
                                                    "564"
                                                  ) &&
                                                  this.state.mv_dead_freight.includes(
                                                    "565"
                                                  ) &&
                                                  this.state.mv_dead_freight.includes(
                                                    "566"
                                                  ) &&
                                                  this.state.mv_dead_freight.includes(
                                                    "567"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_dead_freight: [
                                                        "562",
                                                        "563",
                                                        "564",
                                                        "565",
                                                        "566",
                                                        "567",
                                                        "574",
                                                      ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      mv_dead_freight: [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="562"
                                                color="primary"
                                                checked={this.state.mv_dead_freight.includes(
                                                  "562"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_dead_freight: [
                                                        ...this.state
                                                          .mv_dead_freight,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_dead_freight;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        mv_dead_freight:
                                                          data.filter(
                                                            (e) =>
                                                              e !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="563"
                                                color="primary"
                                                checked={this.state.mv_dead_freight.includes(
                                                  "563"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_dead_freight: [
                                                        ...this.state
                                                          .mv_dead_freight,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_dead_freight;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        mv_dead_freight:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="564"
                                                color="primary"
                                                checked={this.state.mv_dead_freight.includes(
                                                  "564"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_dead_freight: [
                                                        ...this.state
                                                          .mv_dead_freight,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_dead_freight;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        mv_dead_freight:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="565"
                                                color="primary"
                                                checked={this.state.mv_dead_freight.includes(
                                                  "565"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_dead_freight: [
                                                        ...this.state
                                                          .mv_dead_freight,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_dead_freight;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        mv_dead_freight:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>

                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="566"
                                                color="primary"
                                                checked={this.state.mv_dead_freight.includes(
                                                  "566"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_dead_freight: [
                                                        ...this.state
                                                          .mv_dead_freight,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_dead_freight;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        mv_dead_freight:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="574"
                                                color="primary"
                                                checked={this.state.mv_dead_freight.includes(
                                                  "574"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_dead_freight: [
                                                        ...this.state
                                                          .mv_dead_freight,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_dead_freight;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        mv_dead_freight:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="567"
                                                color="primary"
                                                checked={this.state.mv_dead_freight.includes(
                                                  "567"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_dead_freight: [
                                                        ...this.state
                                                          .mv_dead_freight,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_dead_freight;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        mv_dead_freight:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Files
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>
                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Dead Freight Payments
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="568,569,570,571,572,573"
                                                color="primary"
                                                checked={
                                                  this.state.mv_dead_freight_payments.includes(
                                                    "568"
                                                  ) &&
                                                  this.state.mv_dead_freight_payments.includes(
                                                    "569"
                                                  ) &&
                                                  this.state.mv_dead_freight_payments.includes(
                                                    "570"
                                                  ) &&
                                                  this.state.mv_dead_freight_payments.includes(
                                                    "571"
                                                  ) &&
                                                  this.state.mv_dead_freight_payments.includes(
                                                    "572"
                                                  ) &&
                                                  this.state.mv_dead_freight_payments.includes(
                                                    "573"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_dead_freight_payments:
                                                        [
                                                          "568",
                                                          "569",
                                                          "570",
                                                          "571",
                                                          "572",
                                                          "573",
                                                        ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      mv_dead_freight_payments:
                                                        [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="568"
                                                color="primary"
                                                checked={this.state.mv_dead_freight_payments.includes(
                                                  "568"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_dead_freight_payments:
                                                        [
                                                          ...this.state
                                                            .mv_dead_freight_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_dead_freight_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        mv_dead_freight_payments:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="569"
                                                color="primary"
                                                checked={this.state.mv_dead_freight_payments.includes(
                                                  "569"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_dead_freight_payments:
                                                        [
                                                          ...this.state
                                                            .mv_dead_freight_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_dead_freight_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        mv_dead_freight_payments:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="570"
                                                color="primary"
                                                checked={this.state.mv_dead_freight_payments.includes(
                                                  "570"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_dead_freight_payments:
                                                        [
                                                          ...this.state
                                                            .mv_dead_freight_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_dead_freight_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        mv_dead_freight_payments:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="571"
                                                color="primary"
                                                checked={this.state.mv_dead_freight_payments.includes(
                                                  "571"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_dead_freight_payments:
                                                        [
                                                          ...this.state
                                                            .mv_dead_freight_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_dead_freight_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        mv_dead_freight_payments:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>

                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="572"
                                                color="primary"
                                                checked={this.state.mv_dead_freight_payments.includes(
                                                  "572"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_dead_freight_payments:
                                                        [
                                                          ...this.state
                                                            .mv_dead_freight_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_dead_freight_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        mv_dead_freight_payments:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve 1
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="573"
                                                color="primary"
                                                checked={this.state.mv_dead_freight_payments.includes(
                                                  "573"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_dead_freight_payments:
                                                        [
                                                          ...this.state
                                                            .mv_dead_freight_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_dead_freight_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        mv_dead_freight_payments:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve 2
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>

                                      {/**
                                       *
                                       *
                                       * Vessel Insurance
                                       */}
                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Vessel Insurance
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="354,355,356,357,363"
                                                color="primary"
                                                checked={
                                                  this.state.vessel_insurance.includes(
                                                    "354"
                                                  ) &&
                                                  this.state.vessel_insurance.includes(
                                                    "355"
                                                  ) &&
                                                  this.state.vessel_insurance.includes(
                                                    "356"
                                                  ) &&
                                                  this.state.vessel_insurance.includes(
                                                    "357"
                                                  ) &&
                                                  this.state.vessel_insurance.includes(
                                                    "363"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      vessel_insurance: [
                                                        "354",
                                                        "355",
                                                        "356",
                                                        "357",
                                                        "363",
                                                      ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      vessel_insurance: [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="354"
                                                color="primary"
                                                checked={this.state.vessel_insurance.includes(
                                                  "354"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      vessel_insurance: [
                                                        ...this.state
                                                          .vessel_insurance,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .vessel_insurance;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        vessel_insurance:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="355"
                                                color="primary"
                                                checked={this.state.vessel_insurance.includes(
                                                  "355"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      vessel_insurance: [
                                                        ...this.state
                                                          .vessel_insurance,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .vessel_insurance;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        vessel_insurance:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="356"
                                                color="primary"
                                                checked={this.state.vessel_insurance.includes(
                                                  "356"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      vessel_insurance: [
                                                        ...this.state
                                                          .vessel_insurance,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .vessel_insurance;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        vessel_insurance:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="357"
                                                color="primary"
                                                checked={this.state.vessel_insurance.includes(
                                                  "357"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      vessel_insurance: [
                                                        ...this.state
                                                          .vessel_insurance,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .vessel_insurance;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        vessel_insurance:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>

                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="363"
                                                color="primary"
                                                checked={this.state.vessel_insurance.includes(
                                                  "363"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      vessel_insurance: [
                                                        ...this.state
                                                          .vessel_insurance,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .vessel_insurance;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        vessel_insurance:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Files
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>
                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Vessel Insurance Payments
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="358,359,360,361"
                                                color="primary"
                                                checked={
                                                  this.state.vessel_insurance_payments.includes(
                                                    "358"
                                                  ) &&
                                                  this.state.vessel_insurance_payments.includes(
                                                    "359"
                                                  ) &&
                                                  this.state.vessel_insurance_payments.includes(
                                                    "360"
                                                  ) &&
                                                  this.state.vessel_insurance_payments.includes(
                                                    "361"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      vessel_insurance_payments:
                                                        [
                                                          "358",
                                                          "359",
                                                          "360",
                                                          "361",
                                                        ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      vessel_insurance_payments:
                                                        [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="358"
                                                color="primary"
                                                checked={this.state.vessel_insurance_payments.includes(
                                                  "358"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      vessel_insurance_payments:
                                                        [
                                                          ...this.state
                                                            .vessel_insurance_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .vessel_insurance_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        vessel_insurance_payments:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="359"
                                                color="primary"
                                                checked={this.state.vessel_insurance_payments.includes(
                                                  "359"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      vessel_insurance_payments:
                                                        [
                                                          ...this.state
                                                            .vessel_insurance_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .vessel_insurance_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        vessel_insurance_payments:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="360"
                                                color="primary"
                                                checked={this.state.vessel_insurance_payments.includes(
                                                  "360"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      vessel_insurance_payments:
                                                        [
                                                          ...this.state
                                                            .vessel_insurance_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .vessel_insurance_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        vessel_insurance_payments:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="361"
                                                color="primary"
                                                checked={this.state.vessel_insurance_payments.includes(
                                                  "281"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      vessel_insurance_payments:
                                                        [
                                                          ...this.state
                                                            .vessel_insurance_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .vessel_insurance_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        vessel_insurance_payments:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div
                                className="col-lg-12 p-1"
                                onClick={() =>
                                  this.setState({ mainSectionActiveIndex: 13 })
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <div
                                  className="row col-lg-12 rounded p-2"
                                  style={{
                                    border:
                                      this.state.mainSectionActiveIndex === 13
                                        ? "1px solid " + config.themeColor
                                        : "1px solid #dee2e6",
                                  }}
                                >
                                  <div className="col-lg-12 p-0">
                                    <div className="col-lg-12 p-0">
                                      <label className="access_title mb-0">
                                        Mother Vessel Sales Financials
                                      </label>
                                      {this.state.mainSectionActiveIndex ===
                                      13 ? (
                                        ""
                                      ) : (
                                        <label className="access_sub_title mb-0">
                                          Coal Sales Charges | Coal Sales
                                          Invoices | Coal Sales Payments
                                        </label>
                                      )}
                                    </div>
                                  </div>
                                  {this.state.mainSectionActiveIndex === 13 && (
                                    <div className="row col-lg-12 p-0 mb-0 mt-2">
                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Coal Sales Charges
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="282,283,284,285,429,480,525"
                                                color="primary"
                                                checked={
                                                  this.state.mv_sales_fin_coal_sales_charges.includes(
                                                    "282"
                                                  ) &&
                                                  this.state.mv_sales_fin_coal_sales_charges.includes(
                                                    "283"
                                                  ) &&
                                                  this.state.mv_sales_fin_coal_sales_charges.includes(
                                                    "284"
                                                  ) &&
                                                  this.state.mv_sales_fin_coal_sales_charges.includes(
                                                    "285"
                                                  ) &&
                                                  this.state.mv_sales_fin_coal_sales_charges.includes(
                                                    "429"
                                                  ) &&
                                                  this.state.mv_sales_fin_coal_sales_charges.includes(
                                                    "480"
                                                  ) &&
                                                  this.state.mv_sales_fin_coal_sales_charges.includes(
                                                    "525"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_sales_fin_coal_sales_charges:
                                                        [
                                                          "282",
                                                          "283",
                                                          "284",
                                                          "285",
                                                          "429",
                                                          "480",
                                                          "525",
                                                        ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      mv_sales_fin_coal_sales_charges:
                                                        [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="282"
                                                color="primary"
                                                checked={this.state.mv_sales_fin_coal_sales_charges.includes(
                                                  "282"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_sales_fin_coal_sales_charges:
                                                        [
                                                          ...this.state
                                                            .mv_sales_fin_coal_sales_charges,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_sales_fin_coal_sales_charges;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        mv_sales_fin_coal_sales_charges:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="283"
                                                color="primary"
                                                checked={this.state.mv_sales_fin_coal_sales_charges.includes(
                                                  "283"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_sales_fin_coal_sales_charges:
                                                        [
                                                          ...this.state
                                                            .mv_sales_fin_coal_sales_charges,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_sales_fin_coal_sales_charges;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        mv_sales_fin_coal_sales_charges:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="284"
                                                color="primary"
                                                checked={this.state.mv_sales_fin_coal_sales_charges.includes(
                                                  "284"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_sales_fin_coal_sales_charges:
                                                        [
                                                          ...this.state
                                                            .mv_sales_fin_coal_sales_charges,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_sales_fin_coal_sales_charges;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        mv_sales_fin_coal_sales_charges:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="285"
                                                color="primary"
                                                checked={this.state.mv_sales_fin_coal_sales_charges.includes(
                                                  "285"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_sales_fin_coal_sales_charges:
                                                        [
                                                          ...this.state
                                                            .mv_sales_fin_coal_sales_charges,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_sales_fin_coal_sales_charges;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        mv_sales_fin_coal_sales_charges:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="429"
                                                color="primary"
                                                checked={this.state.mv_sales_fin_coal_sales_charges.includes(
                                                  "429"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_sales_fin_coal_sales_charges:
                                                        [
                                                          ...this.state
                                                            .mv_sales_fin_coal_sales_charges,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_sales_fin_coal_sales_charges;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        mv_sales_fin_coal_sales_charges:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="480"
                                                color="primary"
                                                checked={this.state.mv_sales_fin_coal_sales_charges.includes(
                                                  "480"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_sales_fin_coal_sales_charges:
                                                        [
                                                          ...this.state
                                                            .mv_sales_fin_coal_sales_charges,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_sales_fin_coal_sales_charges;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        mv_sales_fin_coal_sales_charges:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="525"
                                                color="primary"
                                                checked={this.state.mv_sales_fin_coal_sales_charges.includes(
                                                  "525"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_sales_fin_coal_sales_charges:
                                                        [
                                                          ...this.state
                                                            .mv_sales_fin_coal_sales_charges,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_sales_fin_coal_sales_charges;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        mv_sales_fin_coal_sales_charges:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Files
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>

                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Coal Sales Invoices
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="286,287,288,289,430,481"
                                                color="primary"
                                                checked={
                                                  this.state.mv_sales_fin_coal_sales_charges_invoices.includes(
                                                    "286"
                                                  ) &&
                                                  this.state.mv_sales_fin_coal_sales_charges_invoices.includes(
                                                    "287"
                                                  ) &&
                                                  this.state.mv_sales_fin_coal_sales_charges_invoices.includes(
                                                    "288"
                                                  ) &&
                                                  this.state.mv_sales_fin_coal_sales_charges_invoices.includes(
                                                    "289"
                                                  ) &&
                                                  this.state.mv_sales_fin_coal_sales_charges_invoices.includes(
                                                    "430"
                                                  ) &&
                                                  this.state.mv_sales_fin_coal_sales_charges_invoices.includes(
                                                    "481"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_sales_fin_coal_sales_charges_invoices:
                                                        [
                                                          "286",
                                                          "287",
                                                          "288",
                                                          "289",
                                                          "430",
                                                          "481",
                                                        ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      mv_sales_fin_coal_sales_charges_invoices:
                                                        [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="286"
                                                color="primary"
                                                checked={this.state.mv_sales_fin_coal_sales_charges_invoices.includes(
                                                  "286"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_sales_fin_coal_sales_charges_invoices:
                                                        [
                                                          ...this.state
                                                            .mv_sales_fin_coal_sales_charges_invoices,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_sales_fin_coal_sales_charges_invoices;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        mv_sales_fin_coal_sales_charges_invoices:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="287"
                                                color="primary"
                                                checked={this.state.mv_sales_fin_coal_sales_charges_invoices.includes(
                                                  "287"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_sales_fin_coal_sales_charges_invoices:
                                                        [
                                                          ...this.state
                                                            .mv_sales_fin_coal_sales_charges_invoices,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_sales_fin_coal_sales_charges_invoices;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        mv_sales_fin_coal_sales_charges_invoices:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="288"
                                                color="primary"
                                                checked={this.state.mv_sales_fin_coal_sales_charges_invoices.includes(
                                                  "288"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_sales_fin_coal_sales_charges_invoices:
                                                        [
                                                          ...this.state
                                                            .mv_sales_fin_coal_sales_charges_invoices,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_sales_fin_coal_sales_charges_invoices;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        mv_sales_fin_coal_sales_charges_invoices:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="289"
                                                color="primary"
                                                checked={this.state.mv_sales_fin_coal_sales_charges_invoices.includes(
                                                  "289"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_sales_fin_coal_sales_charges_invoices:
                                                        [
                                                          ...this.state
                                                            .mv_sales_fin_coal_sales_charges_invoices,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_sales_fin_coal_sales_charges_invoices;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        mv_sales_fin_coal_sales_charges_invoices:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="430"
                                                color="primary"
                                                checked={this.state.mv_sales_fin_coal_sales_charges_invoices.includes(
                                                  "430"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_sales_fin_coal_sales_charges_invoices:
                                                        [
                                                          ...this.state
                                                            .mv_sales_fin_coal_sales_charges_invoices,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_sales_fin_coal_sales_charges_invoices;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        mv_sales_fin_coal_sales_charges_invoices:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="481"
                                                color="primary"
                                                checked={this.state.mv_sales_fin_coal_sales_charges_invoices.includes(
                                                  "481"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_sales_fin_coal_sales_charges_invoices:
                                                        [
                                                          ...this.state
                                                            .mv_sales_fin_coal_sales_charges_invoices,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_sales_fin_coal_sales_charges_invoices;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        mv_sales_fin_coal_sales_charges_invoices:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>

                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Coal Sales Payments
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="290,291,292,293,431,482"
                                                color="primary"
                                                checked={
                                                  this.state.mv_sales_fin_coal_sales_charges_payments.includes(
                                                    "290"
                                                  ) &&
                                                  this.state.mv_sales_fin_coal_sales_charges_payments.includes(
                                                    "291"
                                                  ) &&
                                                  this.state.mv_sales_fin_coal_sales_charges_payments.includes(
                                                    "292"
                                                  ) &&
                                                  this.state.mv_sales_fin_coal_sales_charges_payments.includes(
                                                    "293"
                                                  ) &&
                                                  this.state.mv_sales_fin_coal_sales_charges_payments.includes(
                                                    "431"
                                                  ) &&
                                                  this.state.mv_sales_fin_coal_sales_charges_payments.includes(
                                                    "482"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_sales_fin_coal_sales_charges_payments:
                                                        [
                                                          "290",
                                                          "291",
                                                          "292",
                                                          "293",
                                                          "431",
                                                          "482",
                                                        ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      mv_sales_fin_coal_sales_charges_payments:
                                                        [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="290"
                                                color="primary"
                                                checked={this.state.mv_sales_fin_coal_sales_charges_payments.includes(
                                                  "290"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_sales_fin_coal_sales_charges_payments:
                                                        [
                                                          ...this.state
                                                            .mv_sales_fin_coal_sales_charges_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_sales_fin_coal_sales_charges_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        mv_sales_fin_coal_sales_charges_payments:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="291"
                                                color="primary"
                                                checked={this.state.mv_sales_fin_coal_sales_charges_payments.includes(
                                                  "291"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_sales_fin_coal_sales_charges_payments:
                                                        [
                                                          ...this.state
                                                            .mv_sales_fin_coal_sales_charges_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_sales_fin_coal_sales_charges_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        mv_sales_fin_coal_sales_charges_payments:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="292"
                                                color="primary"
                                                checked={this.state.mv_sales_fin_coal_sales_charges_payments.includes(
                                                  "292"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_sales_fin_coal_sales_charges_payments:
                                                        [
                                                          ...this.state
                                                            .mv_sales_fin_coal_sales_charges_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_sales_fin_coal_sales_charges_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        mv_sales_fin_coal_sales_charges_payments:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="293"
                                                color="primary"
                                                checked={this.state.mv_sales_fin_coal_sales_charges_payments.includes(
                                                  "293"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_sales_fin_coal_sales_charges_payments:
                                                        [
                                                          ...this.state
                                                            .mv_sales_fin_coal_sales_charges_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_sales_fin_coal_sales_charges_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        mv_sales_fin_coal_sales_charges_payments:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="431"
                                                color="primary"
                                                checked={this.state.mv_sales_fin_coal_sales_charges_payments.includes(
                                                  "431"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_sales_fin_coal_sales_charges_payments:
                                                        [
                                                          ...this.state
                                                            .mv_sales_fin_coal_sales_charges_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_sales_fin_coal_sales_charges_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        mv_sales_fin_coal_sales_charges_payments:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="482"
                                                color="primary"
                                                checked={this.state.mv_sales_fin_coal_sales_charges_payments.includes(
                                                  "482"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      mv_sales_fin_coal_sales_charges_payments:
                                                        [
                                                          ...this.state
                                                            .mv_sales_fin_coal_sales_charges_payments,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .mv_sales_fin_coal_sales_charges_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        mv_sales_fin_coal_sales_charges_payments:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div
                                className="col-lg-12 p-1"
                                onClick={() =>
                                  this.setState({ mainSectionActiveIndex: 14 })
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <div
                                  className="row col-lg-12 rounded p-2"
                                  style={{
                                    border:
                                      this.state.mainSectionActiveIndex === 14
                                        ? "1px solid " + config.themeColor
                                        : "1px solid #dee2e6",
                                  }}
                                >
                                  <div className="col-lg-12 p-0">
                                    <div className="col-lg-12 p-0">
                                      <label className="access_title mb-0">
                                        Other Expenses
                                      </label>
                                      {this.state.mainSectionActiveIndex ===
                                      14 ? (
                                        ""
                                      ) : (
                                        <label className="access_sub_title mb-0">
                                          Other Expenses | Other Expenses
                                          Payments{" "}
                                        </label>
                                      )}
                                    </div>
                                  </div>
                                  {this.state.mainSectionActiveIndex === 14 && (
                                    <div className="row col-lg-12 p-0 mb-0 mt-2">
                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Other Expenses
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="294,295,296,297,487,493,378"
                                                color="primary"
                                                checked={
                                                  this.state.other_expenses.includes(
                                                    "294"
                                                  ) &&
                                                  this.state.other_expenses.includes(
                                                    "295"
                                                  ) &&
                                                  this.state.other_expenses.includes(
                                                    "296"
                                                  ) &&
                                                  this.state.other_expenses.includes(
                                                    "297"
                                                  ) &&
                                                  this.state.other_expenses.includes(
                                                    "487"
                                                  ) &&
                                                  this.state.other_expenses.includes(
                                                    "493"
                                                  ) &&
                                                  this.state.other_expenses.includes(
                                                    "378"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      other_expenses: [
                                                        "294",
                                                        "295",
                                                        "296",
                                                        "297",
                                                        "487",
                                                        "493",
                                                        "378",
                                                      ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      other_expenses: [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="294"
                                                color="primary"
                                                checked={this.state.other_expenses.includes(
                                                  "294"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      other_expenses: [
                                                        ...this.state
                                                          .other_expenses,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.other_expenses;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        other_expenses:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="295"
                                                color="primary"
                                                checked={this.state.other_expenses.includes(
                                                  "295"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      other_expenses: [
                                                        ...this.state
                                                          .other_expenses,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.other_expenses;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        other_expenses:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="296"
                                                color="primary"
                                                checked={this.state.other_expenses.includes(
                                                  "296"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      other_expenses: [
                                                        ...this.state
                                                          .other_expenses,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.other_expenses;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                      this.setState({
                                                        other_expenses:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="297"
                                                color="primary"
                                                checked={this.state.other_expenses.includes(
                                                  "297"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      other_expenses: [
                                                        ...this.state
                                                          .other_expenses,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.other_expenses;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      other_expenses: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="487"
                                                color="primary"
                                                checked={this.state.other_expenses.includes(
                                                  "487"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      other_expenses: [
                                                        ...this.state
                                                          .other_expenses,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.other_expenses;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        other_expenses:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="493"
                                                color="primary"
                                                checked={this.state.other_expenses.includes(
                                                  "493"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      other_expenses: [
                                                        ...this.state
                                                          .other_expenses,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.other_expenses;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        other_expenses:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="378"
                                                color="primary"
                                                checked={this.state.other_expenses.includes(
                                                  "378"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      other_expenses: [
                                                        ...this.state
                                                          .other_expenses,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.other_expenses;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        other_expenses:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Files
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>

                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Other Expenses Payments
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="298,299,300,301,488,494"
                                                color="primary"
                                                checked={
                                                  this.state.other_expenses_payments.includes(
                                                    "298"
                                                  ) &&
                                                  this.state.other_expenses_payments.includes(
                                                    "299"
                                                  ) &&
                                                  this.state.other_expenses_payments.includes(
                                                    "300"
                                                  ) &&
                                                  this.state.other_expenses_payments.includes(
                                                    "301"
                                                  ) &&
                                                  this.state.other_expenses_payments.includes(
                                                    "488"
                                                  ) &&
                                                  this.state.other_expenses_payments.includes(
                                                    "494"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      other_expenses_payments: [
                                                        "298",
                                                        "299",
                                                        "300",
                                                        "301",
                                                        "488",
                                                        "494",
                                                      ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      other_expenses_payments:
                                                        [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="298"
                                                color="primary"
                                                checked={this.state.other_expenses_payments.includes(
                                                  "298"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      other_expenses_payments: [
                                                        ...this.state
                                                          .other_expenses_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .other_expenses_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        other_expenses_payments:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="299"
                                                color="primary"
                                                checked={this.state.other_expenses_payments.includes(
                                                  "299"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      other_expenses_payments: [
                                                        ...this.state
                                                          .other_expenses_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .other_expenses_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        other_expenses_payments:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="300"
                                                color="primary"
                                                checked={this.state.other_expenses_payments.includes(
                                                  "300"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      other_expenses_payments: [
                                                        ...this.state
                                                          .other_expenses_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .other_expenses_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        other_expenses_payments:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="301"
                                                color="primary"
                                                checked={this.state.other_expenses_payments.includes(
                                                  "301"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      other_expenses_payments: [
                                                        ...this.state
                                                          .other_expenses_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .other_expenses_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        other_expenses_payments:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="488"
                                                color="primary"
                                                checked={this.state.other_expenses_payments.includes(
                                                  "488"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      other_expenses_payments: [
                                                        ...this.state
                                                          .other_expenses_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .other_expenses_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        other_expenses_payments:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="494"
                                                color="primary"
                                                checked={this.state.other_expenses_payments.includes(
                                                  "494"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      other_expenses_payments: [
                                                        ...this.state
                                                          .other_expenses_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .other_expenses_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        other_expenses_payments:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div
                                className="col-lg-12 p-1"
                                onClick={() =>
                                  this.setState({ mainSectionActiveIndex: 15 })
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <div
                                  className="row col-lg-12 rounded p-2"
                                  style={{
                                    border:
                                      this.state.mainSectionActiveIndex === 15
                                        ? "1px solid " + config.themeColor
                                        : "1px solid #dee2e6",
                                  }}
                                >
                                  <div className="col-lg-12 p-0">
                                    <div className="col-lg-12 p-0">
                                      <label className="access_title mb-0">
                                        Other Income
                                      </label>
                                      {this.state.mainSectionActiveIndex ===
                                      15 ? (
                                        ""
                                      ) : (
                                        <label className="access_sub_title mb-0">
                                          Other Income | Other Income Payments{" "}
                                        </label>
                                      )}
                                    </div>
                                  </div>
                                  {this.state.mainSectionActiveIndex === 15 && (
                                    <div className="row col-lg-12 p-0 mb-0 mt-2">
                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Other Income
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="302,303,304,305,489,495,499"
                                                color="primary"
                                                checked={
                                                  this.state.other_income.includes(
                                                    "302"
                                                  ) &&
                                                  this.state.other_income.includes(
                                                    "303"
                                                  ) &&
                                                  this.state.other_income.includes(
                                                    "304"
                                                  ) &&
                                                  this.state.other_income.includes(
                                                    "305"
                                                  ) &&
                                                  this.state.other_income.includes(
                                                    "489"
                                                  ) &&
                                                  this.state.other_income.includes(
                                                    "495"
                                                  ) &&
                                                  this.state.other_income.includes(
                                                    "499"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      other_income: [
                                                        "302",
                                                        "303",
                                                        "304",
                                                        "305",
                                                        "489",
                                                        "495",
                                                        "499",
                                                      ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      other_income: [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="302"
                                                color="primary"
                                                checked={this.state.other_income.includes(
                                                  "302"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      other_income: [
                                                        ...this.state
                                                          .other_income,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.other_income;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        other_income:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="303"
                                                color="primary"
                                                checked={this.state.other_income.includes(
                                                  "303"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      other_income: [
                                                        ...this.state
                                                          .other_income,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.other_income;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        other_income:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="304"
                                                color="primary"
                                                checked={this.state.other_income.includes(
                                                  "304"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      other_income: [
                                                        ...this.state
                                                          .other_income,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.other_income;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        other_income:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="305"
                                                color="primary"
                                                checked={this.state.other_income.includes(
                                                  "305"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      other_income: [
                                                        ...this.state
                                                          .other_income,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.other_income;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        other_income:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="489"
                                                color="primary"
                                                checked={this.state.other_income.includes(
                                                  "489"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      other_income: [
                                                        ...this.state
                                                          .other_income,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.other_income;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        other_income:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="495"
                                                color="primary"
                                                checked={this.state.other_income.includes(
                                                  "495"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      other_income: [
                                                        ...this.state
                                                          .other_income,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.other_income;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        other_income:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="499"
                                                color="primary"
                                                checked={this.state.other_income.includes(
                                                  "499"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      other_income: [
                                                        ...this.state
                                                          .other_income,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.other_income;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        other_income:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Files
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>

                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Other Income Payments
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="306,307,308,309,490,496"
                                                color="primary"
                                                checked={
                                                  this.state.other_income_payments.includes(
                                                    "306"
                                                  ) &&
                                                  this.state.other_income_payments.includes(
                                                    "307"
                                                  ) &&
                                                  this.state.other_income_payments.includes(
                                                    "308"
                                                  ) &&
                                                  this.state.other_income_payments.includes(
                                                    "309"
                                                  ) &&
                                                  this.state.other_income_payments.includes(
                                                    "490"
                                                  ) &&
                                                  this.state.other_income_payments.includes(
                                                    "496"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      other_income_payments: [
                                                        "306",
                                                        "307",
                                                        "308",
                                                        "309",
                                                      ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      other_income_payments: [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="306"
                                                color="primary"
                                                checked={this.state.other_income_payments.includes(
                                                  "306"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      other_income_payments: [
                                                        ...this.state
                                                          .other_income_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .other_income_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        other_income_payments:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="307"
                                                color="primary"
                                                checked={this.state.other_income_payments.includes(
                                                  "307"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      other_income_payments: [
                                                        ...this.state
                                                          .other_income_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .other_income_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        other_income_payments:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="308"
                                                color="primary"
                                                checked={this.state.other_income_payments.includes(
                                                  "308"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      other_income_payments: [
                                                        ...this.state
                                                          .other_income_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .other_income_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        other_income_payments:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="309"
                                                color="primary"
                                                checked={this.state.other_income_payments.includes(
                                                  "309"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      other_income_payments: [
                                                        ...this.state
                                                          .other_income_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .other_income_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        other_income_payments:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="490"
                                                color="primary"
                                                checked={this.state.other_income_payments.includes(
                                                  "490"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      other_income_payments: [
                                                        ...this.state
                                                          .other_income_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .other_income_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        other_income_payments:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="496"
                                                color="primary"
                                                checked={this.state.other_income_payments.includes(
                                                  "496"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      other_income_payments: [
                                                        ...this.state
                                                          .other_income_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .other_income_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        other_income_payments:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div
                                className="col-lg-12 p-1"
                                onClick={() =>
                                  this.setState({ mainSectionActiveIndex: 16 })
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <div
                                  className="row col-lg-12 rounded p-2"
                                  style={{
                                    border:
                                      this.state.mainSectionActiveIndex === 16
                                        ? "1px solid " + config.themeColor
                                        : "1px solid #dee2e6",
                                  }}
                                >
                                  <div className="col-lg-12 p-0">
                                    <div className="col-lg-12 p-0">
                                      <label className="access_title mb-0">
                                        Credit and Debit Notes
                                      </label>
                                      {this.state.mainSectionActiveIndex ===
                                      16 ? (
                                        ""
                                      ) : (
                                        <label className="access_sub_title mb-0">
                                          Credit and Debit Notes | Credit and
                                          Debit Notes Payments{" "}
                                        </label>
                                      )}
                                    </div>
                                  </div>
                                  {this.state.mainSectionActiveIndex === 16 && (
                                    <div className="row col-lg-12 p-0 mb-0 mt-2">
                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Credit and Debit Notes
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="82,83,84,376,485,491,497"
                                                color="primary"
                                                checked={
                                                  this.state.credAndDebNotesChecked.includes(
                                                    "82"
                                                  ) &&
                                                  this.state.credAndDebNotesChecked.includes(
                                                    "83"
                                                  ) &&
                                                  this.state.credAndDebNotesChecked.includes(
                                                    "84"
                                                  ) &&
                                                  this.state.credAndDebNotesChecked.includes(
                                                    "485"
                                                  ) &&
                                                  this.state.credAndDebNotesChecked.includes(
                                                    "491"
                                                  ) &&
                                                  this.state.credAndDebNotesChecked.includes(
                                                    "497"
                                                  ) &&
                                                  this.state.credAndDebNotesChecked.includes(
                                                    "376"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      credAndDebNotesChecked: [
                                                        "82",
                                                        "83",
                                                        "84",
                                                        "485",
                                                        "491",
                                                        "497",
                                                        "376",
                                                      ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      credAndDebNotesChecked:
                                                        [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="82"
                                                color="primary"
                                                checked={this.state.credAndDebNotesChecked.includes(
                                                  "82"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      credAndDebNotesChecked: [
                                                        ...this.state
                                                          .credAndDebNotesChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .credAndDebNotesChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        credAndDebNotesChecked:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="83"
                                                color="primary"
                                                checked={this.state.credAndDebNotesChecked.includes(
                                                  "83"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      credAndDebNotesChecked: [
                                                        ...this.state
                                                          .credAndDebNotesChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .credAndDebNotesChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        credAndDebNotesChecked:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="84"
                                                color="primary"
                                                checked={this.state.credAndDebNotesChecked.includes(
                                                  "84"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      credAndDebNotesChecked: [
                                                        ...this.state
                                                          .credAndDebNotesChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .credAndDebNotesChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        credAndDebNotesChecked:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="497"
                                                color="primary"
                                                checked={this.state.credAndDebNotesChecked.includes(
                                                  "497"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      credAndDebNotesChecked: [
                                                        ...this.state
                                                          .credAndDebNotesChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .credAndDebNotesChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        credAndDebNotesChecked:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="485"
                                                color="primary"
                                                checked={this.state.credAndDebNotesChecked.includes(
                                                  "485"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      credAndDebNotesChecked: [
                                                        ...this.state
                                                          .credAndDebNotesChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .credAndDebNotesChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        credAndDebNotesChecked:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="491"
                                                color="primary"
                                                checked={this.state.credAndDebNotesChecked.includes(
                                                  "491"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      credAndDebNotesChecked: [
                                                        ...this.state
                                                          .credAndDebNotesChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .credAndDebNotesChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        credAndDebNotesChecked:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="376"
                                                color="primary"
                                                checked={this.state.credAndDebNotesChecked.includes(
                                                  "376"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      credAndDebNotesChecked: [
                                                        ...this.state
                                                          .credAndDebNotesChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .credAndDebNotesChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        credAndDebNotesChecked:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Files
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>

                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Credit and Debit Notes Payments
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="85,86,87"
                                                color="primary"
                                                checked={
                                                  this.state.credAndDebNotesPaymentsChecked.includes(
                                                    "85"
                                                  ) &&
                                                  this.state.credAndDebNotesPaymentsChecked.includes(
                                                    "86"
                                                  ) &&
                                                  this.state.credAndDebNotesPaymentsChecked.includes(
                                                    "87"
                                                  ) &&
                                                  this.state.credAndDebNotesPaymentsChecked.includes(
                                                    "486"
                                                  ) &&
                                                  this.state.credAndDebNotesPaymentsChecked.includes(
                                                    "492"
                                                  ) &&
                                                  this.state.credAndDebNotesPaymentsChecked.includes(
                                                    "498"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      credAndDebNotesPaymentsChecked:
                                                        [
                                                          "85",
                                                          "86",
                                                          "87",
                                                          "486",
                                                          "492",
                                                          "498",
                                                        ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      credAndDebNotesPaymentsChecked:
                                                        [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="85"
                                                color="primary"
                                                checked={this.state.credAndDebNotesPaymentsChecked.includes(
                                                  "85"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      credAndDebNotesPaymentsChecked:
                                                        [
                                                          ...this.state
                                                            .credAndDebNotesPaymentsChecked,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .credAndDebNotesPaymentsChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        credAndDebNotesPaymentsChecked:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="86"
                                                color="primary"
                                                checked={this.state.credAndDebNotesPaymentsChecked.includes(
                                                  "86"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      credAndDebNotesPaymentsChecked:
                                                        [
                                                          ...this.state
                                                            .credAndDebNotesPaymentsChecked,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .credAndDebNotesPaymentsChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        credAndDebNotesPaymentsChecked:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="87"
                                                color="primary"
                                                checked={this.state.credAndDebNotesPaymentsChecked.includes(
                                                  "87"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      credAndDebNotesPaymentsChecked:
                                                        [
                                                          ...this.state
                                                            .credAndDebNotesPaymentsChecked,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .credAndDebNotesPaymentsChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        credAndDebNotesPaymentsChecked:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="498"
                                                color="primary"
                                                checked={this.state.credAndDebNotesPaymentsChecked.includes(
                                                  "498"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      credAndDebNotesPaymentsChecked:
                                                        [
                                                          ...this.state
                                                            .credAndDebNotesPaymentsChecked,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .credAndDebNotesPaymentsChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        credAndDebNotesPaymentsChecked:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="486"
                                                color="primary"
                                                checked={this.state.credAndDebNotesPaymentsChecked.includes(
                                                  "486"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      credAndDebNotesPaymentsChecked:
                                                        [
                                                          ...this.state
                                                            .credAndDebNotesPaymentsChecked,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .credAndDebNotesPaymentsChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        credAndDebNotesPaymentsChecked:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="492"
                                                color="primary"
                                                checked={this.state.credAndDebNotesPaymentsChecked.includes(
                                                  "492"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      credAndDebNotesPaymentsChecked:
                                                        [
                                                          ...this.state
                                                            .credAndDebNotesPaymentsChecked,
                                                          e.target.value,
                                                        ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .credAndDebNotesPaymentsChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        credAndDebNotesPaymentsChecked:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div
                                className="col-lg-12 p-1"
                                onClick={() =>
                                  this.setState({ mainSectionActiveIndex: 17 })
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <div
                                  className="row col-lg-12 rounded p-2"
                                  style={{
                                    border:
                                      this.state.mainSectionActiveIndex === 17
                                        ? "1px solid " + config.themeColor
                                        : "1px solid #dee2e6",
                                  }}
                                >
                                  <div className="col-lg-12 p-0">
                                    <div className="col-lg-12 p-0">
                                      <label className="access_title mb-0">
                                        Services
                                      </label>
                                      {this.state.mainSectionActiveIndex ===
                                      17 ? (
                                        ""
                                      ) : (
                                        <label className="access_sub_title mb-0">
                                          Services | Service Payments
                                        </label>
                                      )}
                                    </div>
                                  </div>
                                  {this.state.mainSectionActiveIndex === 17 && (
                                    <div className="row col-lg-12 p-0 mb-0 mt-2">
                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Services
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="315,316,317, 318, 323"
                                                color="primary"
                                                checked={
                                                  this.state.services.includes(
                                                    "315"
                                                  ) &&
                                                  this.state.services.includes(
                                                    "316"
                                                  ) &&
                                                  this.state.services.includes(
                                                    "317"
                                                  ) &&
                                                  this.state.services.includes(
                                                    "318"
                                                  ) &&
                                                  this.state.services.includes(
                                                    "323"
                                                  ) &&
                                                  this.state.services.includes(
                                                    "596"
                                                  ) &&
                                                  this.state.services.includes(
                                                    "597"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      services: [
                                                        "315",
                                                        "316",
                                                        "317",
                                                        "318",
                                                        "323",
                                                        "596",
                                                        "597",
                                                      ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      services: [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="315"
                                                color="primary"
                                                checked={this.state.services.includes(
                                                  "315"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      services: [
                                                        ...this.state.services,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.services;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        services: data.filter(
                                                          (item) =>
                                                            item !==
                                                            e.target.value
                                                        ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="316"
                                                color="primary"
                                                checked={this.state.services.includes(
                                                  "316"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      services: [
                                                        ...this.state.services,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.services;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        services: data.filter(
                                                          (item) =>
                                                            item !==
                                                            e.target.value
                                                        ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="317"
                                                color="primary"
                                                checked={this.state.services.includes(
                                                  "317"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      services: [
                                                        ...this.state.services,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.services;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        services: data.filter(
                                                          (item) =>
                                                            item !==
                                                            e.target.value
                                                        ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="318"
                                                color="primary"
                                                checked={this.state.services.includes(
                                                  "318"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      services: [
                                                        ...this.state.services,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.services;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        services: data.filter(
                                                          (item) =>
                                                            item !==
                                                            e.target.value
                                                        ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="596"
                                                color="primary"
                                                checked={this.state.services.includes(
                                                  "596"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      services: [
                                                        ...this.state.services,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.services;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        services: data.filter(
                                                          (item) =>
                                                            item !==
                                                            e.target.value
                                                        ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approval
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="597"
                                                color="primary"
                                                checked={this.state.services.includes(
                                                  "597"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      services: [
                                                        ...this.state.services,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.services;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        services: data.filter(
                                                          (item) =>
                                                            item !==
                                                            e.target.value
                                                        ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="323"
                                                color="primary"
                                                checked={this.state.services.includes(
                                                  "323"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      services: [
                                                        ...this.state.services,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.services;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        services: data.filter(
                                                          (item) =>
                                                            item !==
                                                            e.target.value
                                                        ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Files
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>

                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Service Payments
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="319,320,321,322"
                                                color="primary"
                                                checked={
                                                  this.state.services_payments.includes(
                                                    "319"
                                                  ) &&
                                                  this.state.services_payments.includes(
                                                    "320"
                                                  ) &&
                                                  this.state.services_payments.includes(
                                                    "321"
                                                  ) &&
                                                  this.state.services_payments.includes(
                                                    "322"
                                                  ) &&
                                                  this.state.services_payments.includes(
                                                    "598"
                                                  ) &&
                                                  this.state.services_payments.includes(
                                                    "599"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      services_payments: [
                                                        "319",
                                                        "320",
                                                        "321",
                                                        "322",
                                                        "598",
                                                        "599",
                                                      ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      services_payments: [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="319"
                                                color="primary"
                                                checked={this.state.services_payments.includes(
                                                  "319"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      services_payments: [
                                                        ...this.state
                                                          .services_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .services_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        services_payments:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="320"
                                                color="primary"
                                                checked={this.state.services_payments.includes(
                                                  "320"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      services_payments: [
                                                        ...this.state
                                                          .services_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .services_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      this.setState({
                                                        services_payments:
                                                          data.filter(
                                                            (item) =>
                                                              item !==
                                                              e.target.value
                                                          ),
                                                      });
                                                    }
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="321"
                                                color="primary"
                                                checked={this.state.services_payments.includes(
                                                  "321"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      services_payments: [
                                                        ...this.state
                                                          .services_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .services_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      services_payments: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="322"
                                                color="primary"
                                                checked={this.state.services_payments.includes(
                                                  "322"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      services_payments: [
                                                        ...this.state
                                                          .services_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .services_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      services_payments: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="598"
                                                color="primary"
                                                checked={this.state.services_payments.includes(
                                                  "598"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      services_payments: [
                                                        ...this.state
                                                          .services_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .services_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      services_payments: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approval
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="599"
                                                color="primary"
                                                checked={this.state.services_payments.includes(
                                                  "599"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      services_payments: [
                                                        ...this.state
                                                          .services_payments,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .services_payments;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      services_payments: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Approval
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div
                                className="col-lg-12 p-1"
                                onClick={() =>
                                  this.setState({ mainSectionActiveIndex: 18 })
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <div
                                  className="row col-lg-12 rounded p-2"
                                  style={{
                                    border:
                                      this.state.mainSectionActiveIndex === 18
                                        ? "1px solid " + config.themeColor
                                        : "1px solid #dee2e6",
                                  }}
                                >
                                  <div className="col-lg-12 p-0">
                                    <div className="col-lg-12 p-0">
                                      <label className="access_title mb-0">
                                        Reports
                                      </label>
                                      {this.state.mainSectionActiveIndex ===
                                      18 ? (
                                        ""
                                      ) : (
                                        <label className="access_sub_title mb-0">
                                          Coal Vendor | VAT Input Report |
                                          Purchase Contract Available Quantity
                                          Report | Coal Indexes | Financial
                                          Approvals
                                        </label>
                                      )}
                                    </div>
                                  </div>
                                  {this.state.mainSectionActiveIndex === 18 && (
                                    <div className="row col-lg-12 p-0 mb-0 mt-2">
                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Reports
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="332"
                                                color="primary"
                                                checked={this.state.profit_and_loss_coal_vendor.includes(
                                                  "332"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      profit_and_loss_coal_vendor:
                                                        ["332"],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      profit_and_loss_coal_vendor:
                                                        [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                P/L Coal Vendor
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="387"
                                                color="primary"
                                                checked={this.state.vat_input_summary.includes(
                                                  "387"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      vat_input_summary: [
                                                        "387",
                                                      ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      vat_input_summary: [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                VAT Input Report
                                              </span>
                                            </span>
                                          </div>

                                          <div className="col-lg-5 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="535"
                                                color="primary"
                                                checked={this.state.pc_available_qunatity_report.includes(
                                                  "535"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      pc_available_qunatity_report:
                                                        ["535"],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      pc_available_qunatity_report:
                                                        [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Purchase Available Quantity
                                                Report
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="549"
                                                color="primary"
                                                checked={this.state.coal_price_index.includes(
                                                  "549"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      coal_price_index: ["549"],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      coal_price_index: [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Coal Price Index
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-5 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="549"
                                                color="primary"
                                                checked={this.state.outgoing_approvals.includes(
                                                  "554"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      outgoing_approvals: [
                                                        "554",
                                                      ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      outgoing_approvals: [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Financial Approvals
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div
                                className="col-lg-12 p-1"
                                onClick={() =>
                                  this.setState({ mainSectionActiveIndex: 19 })
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <div
                                  className="row col-lg-12 rounded p-2"
                                  style={{
                                    border:
                                      this.state.mainSectionActiveIndex === 19
                                        ? "1px solid " + config.themeColor
                                        : "1px solid #dee2e6",
                                  }}
                                >
                                  <div className="col-lg-12 p-0">
                                    <div className="col-lg-12 p-0">
                                      <label className="access_title mb-0">
                                        Indirect Expenditure
                                      </label>
                                      {this.state.mainSectionActiveIndex ===
                                      19 ? (
                                        ""
                                      ) : (
                                        <label className="access_sub_title mb-0">
                                          Indirect Expenditure
                                        </label>
                                      )}
                                    </div>
                                  </div>
                                  {this.state.mainSectionActiveIndex === 19 && (
                                    <div className="row col-lg-12 p-0 mb-0 mt-2">
                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Indirect Expenditure
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="310,311,312,313,534"
                                                color="primary"
                                                checked={
                                                  this.state.expenditureChecked.includes(
                                                    "310"
                                                  ) &&
                                                  this.state.expenditureChecked.includes(
                                                    "311"
                                                  ) &&
                                                  this.state.expenditureChecked.includes(
                                                    "312"
                                                  ) &&
                                                  this.state.expenditureChecked.includes(
                                                    "313"
                                                  ) &&
                                                  this.state.expenditureChecked.includes(
                                                    "328"
                                                  ) &&
                                                  this.state.expenditureChecked.includes(
                                                    "534"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      expenditureChecked: [
                                                        "310",
                                                        "311",
                                                        "312",
                                                        "313",
                                                        "328",
                                                        "534",
                                                      ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      expenditureChecked: [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="310"
                                                color="primary"
                                                checked={this.state.expenditureChecked.includes(
                                                  "310"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      expenditureChecked: [
                                                        ...this.state
                                                          .expenditureChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .expenditureChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      expenditureChecked: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="311"
                                                color="primary"
                                                checked={this.state.expenditureChecked.includes(
                                                  "311"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      expenditureChecked: [
                                                        ...this.state
                                                          .expenditureChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .expenditureChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      expenditureChecked: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="312"
                                                color="primary"
                                                checked={this.state.expenditureChecked.includes(
                                                  "312"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      expenditureChecked: [
                                                        ...this.state
                                                          .expenditureChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .expenditureChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      expenditureChecked: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="313"
                                                color="primary"
                                                checked={this.state.expenditureChecked.includes(
                                                  "313"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      expenditureChecked: [
                                                        ...this.state
                                                          .expenditureChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .expenditureChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      expenditureChecked: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="328"
                                                color="primary"
                                                checked={this.state.expenditureChecked.includes(
                                                  "328"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      expenditureChecked: [
                                                        ...this.state
                                                          .expenditureChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .expenditureChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      expenditureChecked: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="534"
                                                color="primary"
                                                checked={this.state.expenditureChecked.includes(
                                                  "534"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      expenditureChecked: [
                                                        ...this.state
                                                          .expenditureChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .expenditureChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      expenditureChecked: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Files
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div
                                className="col-lg-12 p-1"
                                onClick={() =>
                                  this.setState({ mainSectionActiveIndex: 20 })
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <div
                                  className="row col-lg-12 rounded p-2"
                                  style={{
                                    border:
                                      this.state.mainSectionActiveIndex === 20
                                        ? "1px solid " + config.themeColor
                                        : "1px solid #dee2e6",
                                  }}
                                >
                                  <div className="col-lg-12 p-0">
                                    <div className="col-lg-12 p-0">
                                      <label className="access_title mb-0">
                                        Fixture Notes
                                      </label>
                                      {this.state.mainSectionActiveIndex ===
                                      20 ? (
                                        ""
                                      ) : (
                                        <label className="access_sub_title mb-0">
                                          Fixture Notes | Barge Agreements
                                        </label>
                                      )}
                                    </div>
                                  </div>
                                  {this.state.mainSectionActiveIndex === 20 && (
                                    <div className="row col-lg-12 p-0 mb-0 mt-2">
                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Fixture Notes
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="319,320,321,322"
                                                color="primary"
                                                checked={
                                                  this.state.fixtureNoteChecked.includes(
                                                    "333"
                                                  ) &&
                                                  this.state.fixtureNoteChecked.includes(
                                                    "334"
                                                  ) &&
                                                  this.state.fixtureNoteChecked.includes(
                                                    "335"
                                                  ) &&
                                                  this.state.fixtureNoteChecked.includes(
                                                    "336"
                                                  ) &&
                                                  this.state.fixtureNoteChecked.includes(
                                                    "337"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      fixtureNoteChecked: [
                                                        "333",
                                                        "334",
                                                        "335",
                                                        "336",
                                                        "337",
                                                      ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      fixtureNoteChecked: [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="333"
                                                color="primary"
                                                checked={this.state.fixtureNoteChecked.includes(
                                                  "333"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      fixtureNoteChecked: [
                                                        ...this.state
                                                          .fixtureNoteChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .fixtureNoteChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      fixtureNoteChecked: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="334"
                                                color="primary"
                                                checked={this.state.fixtureNoteChecked.includes(
                                                  "334"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      fixtureNoteChecked: [
                                                        ...this.state
                                                          .fixtureNoteChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .fixtureNoteChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      fixtureNoteChecked: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="335"
                                                color="primary"
                                                checked={this.state.fixtureNoteChecked.includes(
                                                  "335"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      fixtureNoteChecked: [
                                                        ...this.state
                                                          .fixtureNoteChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .fixtureNoteChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      fixtureNoteChecked: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="336"
                                                color="primary"
                                                checked={this.state.fixtureNoteChecked.includes(
                                                  "336"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      fixtureNoteChecked: [
                                                        ...this.state
                                                          .fixtureNoteChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .fixtureNoteChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      fixtureNoteChecked: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          {/* <div className="col-lg-3 p-1">
                                        <span style={{ flexDirection: 'row' }}>
                                          <CheckBox
                                            value='328'
                                            color='primary'
                                            checked={this.state.expenditureChecked.includes('328')}
                                            onChange={(e) => {
                                              if (e.target.checked) {
                                                this.setState({
                                                  expenditureChecked: [...this.state.expenditureChecked, e.target.value]
                                                })
                                              }
                                              else {
                                                var data = this.state.expenditureChecked;
                                                if (data.includes(e.target.value)) {
                                                  let index = data.includes(e.target.value);
                                                  data.splice(index, 1);
                                                }
                                                this.setState({
                                                  expenditureChecked: data
                                                });
                                              }
                                            }}
                                          />
                                          <span className="checkbox_title">Approve</span>
                                        </span>
                                      </div> */}
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="337"
                                                color="primary"
                                                checked={this.state.fixtureNoteChecked.includes(
                                                  "337"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      fixtureNoteChecked: [
                                                        ...this.state
                                                          .fixtureNoteChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .fixtureNoteChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      fixtureNoteChecked: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Files
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>

                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Barge Agreements
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="555,556,557,558,559, 560"
                                                color="primary"
                                                checked={
                                                  this.state.barge_agreements.includes(
                                                    "555"
                                                  ) &&
                                                  this.state.barge_agreements.includes(
                                                    "556"
                                                  ) &&
                                                  this.state.barge_agreements.includes(
                                                    "557"
                                                  ) &&
                                                  this.state.barge_agreements.includes(
                                                    "558"
                                                  ) &&
                                                  this.state.barge_agreements.includes(
                                                    "559"
                                                  ) &&
                                                  this.state.barge_agreements.includes(
                                                    "560"
                                                  ) &&
                                                  this.state.barge_agreements.includes(
                                                    "561"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_agreements: [
                                                        "555",
                                                        "556",
                                                        "557",
                                                        "558",
                                                        "559",
                                                        "560",
                                                        "561",
                                                      ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      barge_agreements: [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="555"
                                                color="primary"
                                                checked={this.state.barge_agreements.includes(
                                                  "555"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_agreements: [
                                                        ...this.state
                                                          .barge_agreements,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_agreements;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_agreements: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="556"
                                                color="primary"
                                                checked={this.state.barge_agreements.includes(
                                                  "556"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_agreements: [
                                                        ...this.state
                                                          .barge_agreements,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_agreements;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_agreements: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="557"
                                                color="primary"
                                                checked={this.state.barge_agreements.includes(
                                                  "557"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_agreements: [
                                                        ...this.state
                                                          .barge_agreements,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_agreements;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_agreements: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="558"
                                                color="primary"
                                                checked={this.state.barge_agreements.includes(
                                                  "558"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_agreements: [
                                                        ...this.state
                                                          .barge_agreements,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_agreements;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_agreements: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Delete
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="559"
                                                color="primary"
                                                checked={this.state.barge_agreements.includes(
                                                  "559"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_agreements: [
                                                        ...this.state
                                                          .barge_agreements,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_agreements;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_agreements: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Approve
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="561"
                                                color="primary"
                                                checked={this.state.barge_agreements.includes(
                                                  "561"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_agreements: [
                                                        ...this.state
                                                          .barge_agreements,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_agreements;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_agreements: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                2nd Levele Approve
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="560"
                                                color="primary"
                                                checked={this.state.barge_agreements.includes(
                                                  "560"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      barge_agreements: [
                                                        ...this.state
                                                          .barge_agreements,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state
                                                        .barge_agreements;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      barge_agreements: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Files
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div
                                className="col-lg-12 p-1"
                                onClick={() =>
                                  this.setState({ mainSectionActiveIndex: 21 })
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <div
                                  className="row col-lg-12 rounded p-2"
                                  style={{
                                    border:
                                      this.state.mainSectionActiveIndex === 21
                                        ? "1px solid " + config.themeColor
                                        : "1px solid #dee2e6",
                                  }}
                                >
                                  <div className="col-lg-12 p-0">
                                    <div className="col-lg-12 p-0">
                                      <label className="access_title mb-0">
                                        Mines
                                      </label>
                                      {this.state.mainSectionActiveIndex ===
                                      21 ? (
                                        ""
                                      ) : (
                                        <label className="access_sub_title mb-0">
                                          Mine
                                        </label>
                                      )}
                                    </div>
                                  </div>
                                  {this.state.mainSectionActiveIndex === 21 && (
                                    <div className="row col-lg-12 p-0 mb-0 mt-2">
                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            Mines
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="91,92,93"
                                                color="primary"
                                                checked={
                                                  this.state.minesChecked.includes(
                                                    "91"
                                                  ) &&
                                                  this.state.minesChecked.includes(
                                                    "92"
                                                  ) &&
                                                  this.state.minesChecked.includes(
                                                    "93"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      minesChecked: [
                                                        "91",
                                                        "92",
                                                        "93",
                                                      ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      minesChecked: [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="91"
                                                color="primary"
                                                checked={this.state.minesChecked.includes(
                                                  "91"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      minesChecked: [
                                                        ...this.state
                                                          .minesChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.minesChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      minesChecked: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="92"
                                                color="primary"
                                                checked={this.state.minesChecked.includes(
                                                  "92"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      minesChecked: [
                                                        ...this.state
                                                          .minesChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.minesChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      minesChecked: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="93"
                                                color="primary"
                                                checked={this.state.minesChecked.includes(
                                                  "93"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      minesChecked: [
                                                        ...this.state
                                                          .minesChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.minesChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      minesChecked: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div
                                className="col-lg-12 p-1"
                                onClick={() =>
                                  this.setState({ mainSectionActiveIndex: 23 })
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <div
                                  className="row col-lg-12 rounded p-2"
                                  style={{
                                    border:
                                      this.state.mainSectionActiveIndex === 23
                                        ? "1px solid " + config.themeColor
                                        : "1px solid #dee2e6",
                                  }}
                                >
                                  <div className="col-lg-12 p-0">
                                    <div className="col-lg-12 p-0">
                                      <label className="access_title mb-0">
                                        Budget & Costing
                                      </label>
                                      {this.state.mainSectionActiveIndex ===
                                      23 ? (
                                        ""
                                      ) : (
                                        <label className="access_sub_title mb-0">
                                          Budget & Costing
                                        </label>
                                      )}
                                    </div>
                                  </div>
                                  {this.state.mainSectionActiveIndex === 23 && (
                                    <div className="row col-lg-12 p-0 mb-0 mt-2">
                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                          Budget & Costing
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="640,641,642"
                                                color="primary"
                                                checked={
                                                  this.state.budget_and_costing.includes(
                                                    "640"
                                                  ) &&
                                                  this.state.budget_and_costing.includes(
                                                    "641"
                                                  ) &&
                                                  this.state.budget_and_costing.includes(
                                                    "642"
                                                  )
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      budget_and_costing: [
                                                        "640",
                                                        "641",
                                                        "642",
                                                      ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      budget_and_costing: [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="640"
                                                color="primary"
                                                checked={this.state.budget_and_costing.includes(
                                                  "640"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      budget_and_costing: [
                                                        ...this.state
                                                          .budget_and_costing,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.budget_and_costing;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      budget_and_costing: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="641"
                                                color="primary"
                                                checked={this.state.budget_and_costing.includes(
                                                  "641"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      budget_and_costing: [
                                                        ...this.state
                                                          .budget_and_costing,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.budget_and_costing;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      budget_and_costing: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="642"
                                                color="primary"
                                                checked={this.state.budget_and_costing.includes(
                                                  "642"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      budget_and_costing: [
                                                        ...this.state
                                                          .budget_and_costing,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.budget_and_costing;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      budget_and_costing: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>
                                    </div>
                                  )}
                                </div>
                              </div>
                              
                              <div
                                className="col-lg-12 p-1"
                                onClick={() =>
                                  this.setState({ mainSectionActiveIndex: 22 })
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <div
                                  className="row col-lg-12 rounded p-2"
                                  style={{
                                    border:
                                      this.state.mainSectionActiveIndex === 22
                                        ? "1px solid " + config.themeColor
                                        : "1px solid #dee2e6",
                                  }}
                                >
                                  <div className="col-lg-12 p-0">
                                    <div className="col-lg-12 p-0">
                                      <label className="access_title mb-0">
                                        User
                                      </label>
                                      {this.state.mainSectionActiveIndex ===
                                      22 ? (
                                        ""
                                      ) : (
                                        <label className="access_sub_title mb-0">
                                          User
                                        </label>
                                      )}
                                    </div>
                                  </div>
                                  {this.state.mainSectionActiveIndex === 22 && (
                                    <div className="row col-lg-12 p-0 mb-0 mt-2">
                                      <Accordion
                                        square={true}
                                        defaultExpanded={false}
                                        className="access_accordion_collapse col-lg-12 p-0"
                                        style={{
                                          margin: "0px 0px 6px 0px",
                                          boxShadow: "none",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <CompactExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                          style={{
                                            borderBottom: "1px solid #9c948f",
                                            height: 30,
                                            backgroundColor: "#f1efea",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                          }}
                                        >
                                          <span className="expansion_title mb-0">
                                            User
                                          </span>
                                        </CompactExpansionPanelSummary>
                                        <AccordionDetails className="row col-lg p-2">
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="94,95,96,97"
                                                color="primary"
                                                checked={
                                                  this.state.userChecked.includes(
                                                    "94"
                                                  ) &&
                                                  this.state.userChecked.includes(
                                                    "95"
                                                  ) &&
                                                  this.state.userChecked.includes(
                                                    "96"
                                                  )
                                                  // && this.state.userChecked.includes('97')
                                                }
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      userChecked: [
                                                        "94",
                                                        "95",
                                                        "96",
                                                      ],
                                                    });
                                                  } else {
                                                    this.setState({
                                                      userChecked: [],
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Select All
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="94"
                                                color="primary"
                                                checked={this.state.userChecked.includes(
                                                  "94"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      userChecked: [
                                                        ...this.state
                                                          .userChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.userChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      userChecked: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Add
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="95"
                                                color="primary"
                                                checked={this.state.userChecked.includes(
                                                  "95"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      userChecked: [
                                                        ...this.state
                                                          .userChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.userChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      userChecked: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                View
                                              </span>
                                            </span>
                                          </div>
                                          <div className="col-lg-3 p-1">
                                            <span
                                              style={{ flexDirection: "row" }}
                                            >
                                              <CheckBox
                                                value="96"
                                                color="primary"
                                                checked={this.state.userChecked.includes(
                                                  "96"
                                                )}
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    this.setState({
                                                      userChecked: [
                                                        ...this.state
                                                          .userChecked,
                                                        e.target.value,
                                                      ],
                                                    });
                                                  } else {
                                                    var data =
                                                      this.state.userChecked;
                                                    if (
                                                      data.includes(
                                                        e.target.value
                                                      )
                                                    ) {
                                                      let index = data.indexOf(
                                                        e.target.value
                                                      );
                                                      data.splice(index, 1);
                                                    }
                                                    this.setState({
                                                      userChecked: data,
                                                    });
                                                  }
                                                }}
                                              />
                                              <span className="checkbox_title">
                                                Update
                                              </span>
                                            </span>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <footer className="row bottom_buttons_section">
                        <button
                          type="button"
                          className="btn previous_button btn-light"
                          name="close"
                          onClick={() =>
                            this.setState(
                              {
                                openDrawer: false,
                                mainSectionActiveIndex: null,
                                user_id: null,
                              },
                              () => this.componentDidMount()
                            )
                          }
                        >
                          Close
                        </button>
                        <button
                          type="button"
                          className="drawer_text drawer_btn_success"
                          name="Submit"
                          onClick={this.submitHandler}
                        >
                          Submit
                        </button>
                      </footer>
                    </div>
                  </Drawer>
                </div>
              </section>
            </div>
          )}
        </div>

        {/* Add User */}

        <div
          className="modal fade"
          id="bargePurchasePaymentPostingModal"
          role="dialog"
          tabIndex="-1"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content  payment-modal">
              <div
                className="modal-header border-0"
                style={{ backgroundColor: "#f1f1f1" }}
              >
                <h6
                  className="modal-title"
                  style={{ border: "none", padding: 0 }}
                >
                  {this.state.paymentTitle} Add User
                </h6>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body p-0 mt-2">
                <div className="col-lg-12">
                  <div
                    style={{
                      fontSize: 14,
                      color: "red",
                      textTransform: "capitalize",
                      textAlign: "center",
                    }}
                  >
                    {this.state.paymentErrorMsg}
                  </div>

                  <div className="row">
                    <div className="col-lg-6 mb-2">
                      <label className="form_label mb-0">
                        Full Name <span style={{ color: "red" }}>*</span>
                      </label>
                      <TextField
                        name="email"
                        placeholder="Full Name"
                        margin="dense"
                        variant="outlined"
                        value={this.state.full_name}
                        error={this.state.full_name_error}
                        inputProps={{ style: { textTransform: "uppercase" } }}
                        onChange={(e) => {
                          this.setState({
                            full_name: e.target.value,
                          });
                        }}
                        fullWidth
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 mb-2">
                      <label className="form_label mb-0">
                        Email <span style={{ color: "red" }}>*</span>
                      </label>
                      <TextField
                        name="email"
                        placeholder="Email"
                        margin="dense"
                        variant="outlined"
                        value={this.state.email}
                        error={this.state.emailError}
                        inputProps={{ style: { textTransform: "uppercase" } }}
                        onChange={(e) => {
                          this.setState({
                            email: e.target.value,
                          });
                        }}
                        fullWidth
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 mb-2">
                      <label className="form_label mb-0">
                        Password <span style={{ color: "red" }}>*</span>
                      </label>
                      <TextField
                        name="password"
                        placeholder="Password"
                        margin="dense"
                        type="password"
                        variant="outlined"
                        value={this.state.password}
                        error={this.state.passwordError}
                        onChange={(e) => {
                          this.setState({
                            password: e.target.value,
                          });
                        }}
                        fullWidth
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 mb-2">
                      <label className="form_label mb-0">
                        Confirm Password <span style={{ color: "red" }}>*</span>
                      </label>
                      <TextField
                        name="confirm_password"
                        placeholder="Password"
                        margin="dense"
                        type="password"
                        variant="outlined"
                        value={this.state.confirm_password}
                        error={this.state.confirm_password_Error}
                        onChange={(e) => {
                          this.setState({
                            confirm_password: e.target.value,
                          });
                        }}
                        fullWidth
                      />
                    </div>
                  </div>
                </div>

                <div className="row bottom_buttons_section">
                  <button
                    type="button"
                    className="btn previous_button"
                    name="submit"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn next_button"
                    onClick={this.addUserHandler}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
