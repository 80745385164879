import React, { Component } from "react";
import {
  MenuItem,
  TextField,
  IconButton,
  InputAdornment,
  Snackbar,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DateFnsUtils from "@date-io/date-fns";
import EventIcon from "@material-ui/icons/Event";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { FormControl, ListItemText, Select, Checkbox } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";

import CookieHandler from "../common/CookieHandler";
import api from "../../api/api";
import "../../App.css";
import Config from "../../config/config";
import { Alert } from "@material-ui/lab";
import Loader from "../common/Loader";

export default class BusinessNoOpMVAdd extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      loadingCommencement: null,
      loadingCompletion: null,
      documentsOnBoard: null,
      vesselSailing: null,
      dischargePort: "",
      loadingType: "0",
      loadingRatePerDay: "",
      deadFreightIncurred: "",
      deadFreightAccount: "0",
      supplierSurveyorName: "",
      supplierSurveyorAnalysisType: "0",
      SDAMSAIISurveyorName: "",
      SDAMSAIISurveyorAnalysisType: "0",
      buyerAdditionalSurveyorName: "",
      surveyorBasePrice: "",
      surveyorVAT: "",
      surveyorTotalPrice: "",
      surveyorCurrencyType: "0",
      surveyorAdditionalDays: "",
      stevedore_vendor: "",
      stevedoreAdditionalDays: "",
      floatingCraneName: "",
      floating_crane_additional_days: "",
      floatingCraneDemurrageDespatch: "",
      floatingCraneDemurrageDespatchCurrencyType: "0",
      documentationAgent: "",
      surveyorsData: [],
      stevedoreData: [],
      othersData: [],
      currencyData: [],
      businessNumber: "",
      motherVesselName: "",
      supplierDocumentationAgent: "",
      SDAM_DocumentationAgent: "",
      buyerDocumentationAgent: "",
      remarks: "",
      errorMsg: "",
      successMsg: "",

      sumOfBargeQuantities: "",
      vessel_final_quantity_in_mt: "",
      cargo_gain_loss_in_mt: "",

      // Multi checkboxes

      supplierServeyorNames: [],
      internalServeyorNames: [],
      jointServeyorNames: [],

      vessel_arrived_on: null,
      nor_acceptance: null,
      vessel_type: "0",
      load_port_agent: "",
      eta_nor: null,

      isLoading: true,
      features: [],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    } else {
      const features = this.Cookie.getCookie("features");
      this.setState({ features: features });
      if (!(features.includes("42") || features.includes("97"))) {
        window.location.href = "/dashboard";
      }
      const loginUserID = this.Cookie.getCookie("loginUserId");
      const idToken = this.Cookie.getIdTokenCookie();
      const status = "Active";
      try {
        // var businessNo = decodeURIComponent(window.atob(this.props.match.params.businessNumberID));
        var businessNo = this.props.businessNoID;
        this.setState({ businessNumberID: businessNo });
      } catch (e) {
        window.location.href = "/pagenotfound";
      }
      /**
       * Calling Get Total Barge quanatities API,   !Start
       *
       */
      var businessNumberID = businessNo;
      const get_total_barge_quantities = await api.getTotalBargeQuantities(
        loginUserID,
        idToken,
        businessNumberID
      );
      if (
        get_total_barge_quantities.status >= 200 &&
        get_total_barge_quantities.status < 300
      ) {
        get_total_barge_quantities.json().then((res) => {
          if (res.code === "200") {
            if (res.total_barges_in_mt) {
              this.setState({
                sumOfBargeQuantities: res.total_barges_in_mt,
              });
            } else {
              this.setState({
                sumOfBargeQuantities: "",
              });
            }
          } else if (res.code === "601") {
            this.setState({});
          }
        });
      }
      /**
       * Calling Get Total Barge quanatities API ! End
       *
       */
      const get_business_no = await api.getBusinessNumber(
        businessNo,
        loginUserID,
        idToken
      );
      if (get_business_no.status >= 200 && get_business_no.status < 300) {
        get_business_no.json().then((res) => {
          if (res.code === "200") {
            if (res.business_number) {
              this.setState({
                businessNumber: res.business_number.business_no,
                motherVesselName: res.business_number.mother_vessel_name,
              });
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          } else if (res.code === "601") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
      // for get_Currencies
      const get_currencies = await api.getCurrencies(loginUserID, idToken);
      if (get_currencies.status >= 200 && get_currencies.status < 300) {
        get_currencies.json().then((res) => {
          if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
          if (res.code === "200") {
            if (res.Currency) {
              var currencies = [
                <MenuItem value="0" key={-1}>
                  {" "}
                  Please Select
                </MenuItem>,
              ];
              for (var i in res.Currency) {
                currencies.push(
                  <MenuItem value={res.Currency[i].currency} key={[i]}>
                    {res.Currency[i].currency}
                  </MenuItem>
                );
              }
              this.setState({
                currencyData: currencies,
              });
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          } else if (res.code === "601") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }

      //Get Surveyor vendors
      const get_vendors = await api.getVendors(loginUserID, idToken, status);
      if (get_vendors.status >= 200 && get_vendors.status < 300) {
        get_vendors.json().then((res) => {
          if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
          if (res.code === "200") {
            if (res.vendors) {
              const surveyorsData = [];
              const stevedoreData = [];
              const othersData = [];
              // const surveyorsData = [<MenuItem value='0' key={-1} disabled> Please Select</MenuItem>];
              // const stevedoreData =[<MenuItem value='0' key={-1} disabled> Please Select</MenuItem>];
              // const othersData =[<MenuItem value='0' key={-1} disabled> Please Select</MenuItem>];
              for (let i = 0; i < res.vendors.length; i++) {
                if (res.vendors[i].vendor_type === "Surveyor") {
                  // surveyorsData.push(<MenuItem value={res.vendors[i].id} key={[i]}>{res.vendors[i].vendor_name}</MenuItem>)
                  surveyorsData.push({
                    name: res.vendors[i].vendor_name,
                    value: res.vendors[i].id,
                    key: i,
                  });
                }
                if (res.vendors[i].vendor_type === "Stevedore") {
                  // stevedoreData.push(<MenuItem value={res.vendors[i].id} key={[i]}>{res.vendors[i].vendor_name}</MenuItem>)
                  stevedoreData.push({
                    name: res.vendors[i].vendor_name,
                    value: res.vendors[i].id,
                    key: i,
                  });
                }
                if (
                  res.vendors[i].vendor_type === "Others" ||
                  res.vendors[i].vendor_type === "Stevedore" ||
                  res.vendors[i].vendor_type === "Floating Crane" ||
                  res.vendors[i].vendor_type === "Vessel Agent"
                ) {
                  // othersData.push(<MenuItem value={res.vendors[i].id} key={[i]}>{res.vendors[i].vendor_name}</MenuItem>)
                  othersData.push({
                    name: res.vendors[i].vendor_name,
                    value: res.vendors[i].id,
                    key: i,
                  });
                }
              }
              this.setState({
                surveyorsData: surveyorsData,
                stevedoreData: stevedoreData,
                othersData: othersData,
                isLoading: false,
              });
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          } else if (res.code === "601") {
            this.setState({
              errorMsg: res.message,
              snackBarErrorOpen: true,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    }
    document.title = Config.documentTitle + "Mother Vessel for New Business";
  }

  submitHandler = () => {
    this.setState({ isLoading: true });
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    // var businessNumber = decodeURIComponent(window.atob(this.props.match.params.businessNumber));
    // var businessNumberID = decodeURIComponent(window.atob(this.props.match.params.businessNumberID));
    var businessNumberID = this.props.businessNoID;

    var {
      loadingCommencement,
      loadingCompletion,
      documentsOnBoard,
      vesselSailing,
      vessel_final_quantity_in_mt,
      cargo_gain_loss_in_mt,
      dischargePort,
      loadingType,
      loadingRatePerDay,
      deadFreightAccount,
      deadFreightIncurred,
      supplierSurveyorName,
      supplierSurveyorAnalysisType,
      SDAMSAIISurveyorName,
      SDAMSAIISurveyorAnalysisType,
      buyerAdditionalSurveyorName,
      surveyorBasePrice,
      surveyorVAT,
      surveyorTotalPrice,
      surveyorCurrencyType,
      surveyorAdditionalDays,
      stevedore_vendor,
      stevedoreAdditionalDays,
      floating_crane_vendor,
      floatingCraneName,
      floating_crane_additional_days,
      floatingCraneDemurrageDespatch,
      floatingCraneDemurrageDespatchCurrencyType,
      documentationAgent,
      supplierDocumentationAgent,
      SDAM_DocumentationAgent,
      buyerDocumentationAgent,
      vessel_arrived_on,
      nor_acceptance,
      vessel_type,
      load_port_agent,
      eta_nor,
      remarks,
    } = this.state;
    if (loadingCommencement === null) {
      loadingCommencement = "";
    }
    if (loadingCompletion === null) {
      loadingCompletion = "";
    }
    if (documentsOnBoard === null) {
      documentsOnBoard = "";
    }
    if (vesselSailing === null) {
      vesselSailing = "";
    }
    if (loadingType === "0") {
      loadingType = "";
    }
    if (deadFreightAccount === "0") {
      deadFreightAccount = "";
    }
    if (deadFreightAccount === "0") {
      deadFreightAccount = "";
    }
    if (supplierSurveyorName === "0") {
      supplierSurveyorName = "";
    }
    if (supplierSurveyorAnalysisType === "0") {
      supplierSurveyorAnalysisType = "";
    }
    if (SDAMSAIISurveyorName === "0") {
      SDAMSAIISurveyorName = "";
    }
    if (SDAMSAIISurveyorAnalysisType === "0") {
      SDAMSAIISurveyorAnalysisType = "";
    }
    if (buyerAdditionalSurveyorName === "0") {
      buyerAdditionalSurveyorName = "";
    }
    if (surveyorCurrencyType === "0") {
      surveyorCurrencyType = "";
    }

    if (floatingCraneDemurrageDespatchCurrencyType === "0") {
      floatingCraneDemurrageDespatchCurrencyType = "";
    }
    if (loadingType === "Vessel grabs") {
      floatingCraneName = "";
      floatingCraneDemurrageDespatch = "";
      floatingCraneDemurrageDespatchCurrencyType = "";
    }
    if (loadingType === "Floating crane") {
      stevedore_vendor = "";
    }
    if (documentationAgent === "0") {
      documentationAgent = "";
    }

    var supplierSurveyorNames = "";
    for (let i = 0; i < this.state.supplierServeyorNames.length; i++) {
      for (let j = 0; j < this.state.surveyorsData.length; j++) {
        if (
          this.state.supplierServeyorNames[i] ===
          this.state.surveyorsData[j].name
        ) {
          supplierSurveyorNames += this.state.surveyorsData[j].value + ",";
        }
      }
    }
    var supplier_surveyor = supplierSurveyorNames.slice(0, -1);

    var internalSurveyorNames = "";
    for (let i = 0; i < this.state.internalServeyorNames.length; i++) {
      for (let j = 0; j < this.state.surveyorsData.length; j++) {
        if (
          this.state.internalServeyorNames[i] ===
          this.state.surveyorsData[j].name
        ) {
          internalSurveyorNames += this.state.surveyorsData[j].value + ",";
        }
      }
    }
    var internal_surveyor = internalSurveyorNames.slice(0, -1);

    var jointSurveyorNames = "";
    for (let i = 0; i < this.state.jointServeyorNames.length; i++) {
      for (let j = 0; j < this.state.surveyorsData.length; j++) {
        if (
          this.state.jointServeyorNames[i] === this.state.surveyorsData[j].name
        ) {
          jointSurveyorNames += this.state.surveyorsData[j].value + ",";
        }
      }
    }
    var joint_surveyor = jointSurveyorNames.slice(0, -1);

    if (vessel_type === "0") {
      vessel_type = "";
    }

    //New Business Number Operational MV
    api
      .addBusinessNoOpMV(
        loginUserID,
        idToken,
        businessNumberID,
        loadingCommencement,
        loadingCompletion,
        documentsOnBoard,
        vesselSailing,
        vessel_final_quantity_in_mt,
        cargo_gain_loss_in_mt,
        dischargePort,
        loadingType,
        loadingRatePerDay,
        deadFreightAccount,
        deadFreightIncurred,
        supplier_surveyor,
        supplierSurveyorAnalysisType,
        internal_surveyor,
        SDAMSAIISurveyorAnalysisType,
        joint_surveyor,
        surveyorBasePrice,
        surveyorVAT,
        surveyorTotalPrice,
        surveyorCurrencyType,
        surveyorAdditionalDays,
        stevedore_vendor,
        stevedoreAdditionalDays,
        floating_crane_vendor,
        floatingCraneName,
        floating_crane_additional_days,
        floatingCraneDemurrageDespatch,
        floatingCraneDemurrageDespatchCurrencyType,
        documentationAgent,
        supplierDocumentationAgent,
        SDAM_DocumentationAgent,
        buyerDocumentationAgent,
        vessel_arrived_on,
        nor_acceptance,
        vessel_type,
        load_port_agent,
        eta_nor,
        remarks
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState({
                successMsg: res.message,
              });
              this.props.onGetResponse(res.code);
              // window.location.href = '/view-business/' + btoa(businessNumberID);
            } else if (res.code === "601") {
              this.setState({
                isLoading: false,
                errorMsg: "Missing mandatory fields",
                successMsg: "",
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            } else {
              this.setState({
                isLoading: false,
              });
              alert("Unexpected error occured. Please contact administrator.");
            }
          });
        }
      });
  };

  loadingCommencementDateHandler = (date) => {
    this.setState({
      loadingCommencement: date,
    });
  };

  loadingCompletionDateHandler = (date) => {
    this.setState({
      loadingCompletion: date,
    });
  };

  documentsOnBoardDateHandler = (date) => {
    this.setState({
      documentsOnBoard: date,
    });
  };

  vesselSailingDateHandler = (date) => {
    this.setState({
      vesselSailing: date,
    });
  };

  TextHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleDecimalField = (e) => {
    // const re = /^[0-9]*\.?[0-9]*$/;
    const re = /^\d*(\.\d{0,3})?$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };

  MultiSelectHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  toLocaleString(value) {
    return Number(value).toLocaleString();
  }

  render() {
    // const re = /^[0-9]*\.?[0-9]*$/;
    const re = /^\d*(\.\d{0,2})?$/;
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div className="container p-0" style={{ minHeight: "100vh" }}>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            autoHideDuration={10000}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={10000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <div className="clearfix content-header border-bottom">
            <div className="row">
              <div className="col-sm text-left pl-0">
                <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                  Mother Vessel Data for {this.state.businessNumber}
                  {this.state.motherVesselName
                    ? " (" + this.state.motherVesselName + ")"
                    : ""}
                </h4>
              </div>
            </div>
          </div>
          {this.state.errorMsg && (
            <div className="row">
              <div className="col text-center">
                <p className="error-class">{this.state.errorMsg}</p>
              </div>
            </div>
          )}
          {this.state.successMsg && (
            <div className="row">
              <div className="col text-center">
                <p className="success-class">{this.state.successMsg}</p>
              </div>
            </div>
          )}
          <div className="col-lg card" style={{ overflowY: "scroll" }}>
            <div className="section_block w-100 pl-4 pr-4">
              <div style={{ width: "70%" }}>
                <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                  Mother Vessel Data
                </h5>
              </div>

              <div className="row mb-0">
                <div className="col-lg-6 p-0">
                  <div className="row mb-2">
                    <label className="form_label mb-0">
                      Loading Commencement
                    </label>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DateTimePicker
                        autoOk
                        hideTabs
                        variant="inline"
                        inputVariant="outlined"
                        margin="dense"
                        fullWidth
                        value={this.state.loadingCommencement}
                        onChange={(date) =>
                          this.loadingCommencementDateHandler(date)
                        }
                        allowKeyboardControl={false}
                        ampm={false}
                        placeholder="Loading Commencement"
                        format="dd/MM/yyyy HH:mm"
                        minDate={new Date("2018-01-01")}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton>
                                <EventIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                          className: "pl-0",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
                <div className="col-lg-6 pr-0">
                  <div className="row mb-2">
                    <label className="form_label mb-0">
                      Loading Completion
                    </label>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DateTimePicker
                        autoOk
                        hideTabs
                        variant="inline"
                        inputVariant="outlined"
                        margin="dense"
                        fullWidth
                        value={this.state.loadingCompletion}
                        onChange={(date) =>
                          this.loadingCompletionDateHandler(date)
                        }
                        allowKeyboardControl={false}
                        ampm={false}
                        placeholder="Loading Completion"
                        format="dd/MM/yyyy HH:mm"
                        minDate={new Date("2018-01-01")}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton>
                                <EventIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                          className: "pl-0",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
              </div>

              <div className="row mb-0">
                <div className="col-lg-6 p-0">
                  <div className="row mb-2">
                    <label className="form_label mb-0">
                      Documents on-board
                    </label>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DateTimePicker
                        autoOk
                        hideTabs
                        variant="inline"
                        inputVariant="outlined"
                        margin="dense"
                        fullWidth
                        value={this.state.documentsOnBoard}
                        onChange={(date) =>
                          this.documentsOnBoardDateHandler(date)
                        }
                        allowKeyboardControl={false}
                        ampm={false}
                        placeholder="Documents on-board"
                        format="dd/MM/yyyy HH:mm"
                        minDate={new Date("2018-01-01")}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton>
                                <EventIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                          className: "pl-0",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
                <div className="col-lg-6 pr-0">
                  <div className="row mb-2">
                    <label className="form_label mb-0">Vessel Sailing</label>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DateTimePicker
                        autoOk
                        hideTabs
                        variant="inline"
                        inputVariant="outlined"
                        margin="dense"
                        fullWidth
                        value={this.state.vesselSailing}
                        onChange={(date) => this.vesselSailingDateHandler(date)}
                        allowKeyboardControl={false}
                        ampm={false}
                        placeholder="Vessel Sailing"
                        format="dd/MM/yyyy HH:mm"
                        minDate={new Date("2018-01-01")}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton>
                                <EventIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                          className: "pl-0",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
              </div>

              <div className="row mb-0">
                <div className="col-lg-6 p-0">
                  <div className="row mb-2">
                    <label className="form_label mb-0">Vessel Arrived</label>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DateTimePicker
                        autoOk
                        hideTabs
                        inputVariant="outlined"
                        variant="inline"
                        margin="dense"
                        fullWidth
                        ampm={false}
                        placeholder="Vessel Arrived"
                        format="dd/MM/yyyy HH:mm"
                        value={this.state.vessel_arrived_on}
                        onChange={(date) => {
                          this.setState({
                            vessel_arrived_on: date,
                          });
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton>
                                <EventIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                          style: {
                            fontFamily: "Poppins",
                            fontSize: "14px",
                          },
                          className: "pl-0",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
                <div className="col-lg-6 pr-0">
                  <div className="row mb-2">
                    <label className="form_label mb-0">NOR Acceptance</label>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DateTimePicker
                        autoOk
                        hideTabs
                        inputVariant="outlined"
                        variant="inline"
                        margin="dense"
                        fullWidth
                        ampm={false}
                        placeholder="NOR Acceptance"
                        format="dd/MM/yyyy HH:mm"
                        value={this.state.nor_acceptance}
                        onChange={(date) => {
                          this.setState({
                            nor_acceptance: date,
                          });
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton>
                                <EventIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                          style: {
                            fontFamily: "Poppins",
                            fontSize: "14px",
                          },
                          className: "pl-0",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
              </div>

              <div className="row mb-0">
                <div className="col-lg-6 p-0">
                  <div className="row mb-2">
                    <label className="form_label mb-0">Vessel Type</label>
                    <TextField
                      name="vessel_type"
                      placeholder="Mother Vessel Name"
                      margin="dense"
                      variant="outlined"
                      value={this.state.vessel_type}
                      onChange={(e) => {
                        this.setState({
                          vessel_type: e.target.value,
                        });
                      }}
                      fullWidth
                      select
                      InputProps={{
                        style: {
                          fontFamily: "Poppins",
                          fontSize: "14px",
                        },
                      }}
                    >
                      <MenuItem value="0">Please Select</MenuItem>
                      <MenuItem value="Geared">Geared</MenuItem>
                      <MenuItem value="Gearless">Gearless</MenuItem>
                    </TextField>
                  </div>
                </div>
                <div className="col-lg-6 pr-0">
                  <div className="row mb-2">
                    <label className="form_label mb-0">Load Port Agent</label>
                    <TextField
                      name="load_port_agent"
                      placeholder="Load Port Agent"
                      margin="dense"
                      variant="outlined"
                      value={this.state.load_port_agent}
                      onChange={(e) => {
                        this.setState({
                          load_port_agent: e.target.value,
                        });
                      }}
                      inputProps={{ style: { textTransform: "uppercase" } }}
                      fullWidth
                      InputProps={{
                        style: {
                          fontFamily: "Poppins",
                          fontSize: "14px",
                        },
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="row mb-0">
                <div className="col-lg-6 p-0">
                  <div className="row mb-2">
                    <label className="form_label mb-0">ETA / NOR</label>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DateTimePicker
                        autoOk
                        hideTabs
                        inputVariant="outlined"
                        variant="inline"
                        margin="dense"
                        fullWidth
                        ampm={false}
                        placeholder="ETA / NOR"
                        format="dd/MM/yyyy HH:mm"
                        value={this.state.eta_nor}
                        onChange={(date) => {
                          this.setState({
                            eta_nor: date,
                          });
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton>
                                <EventIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                          className: "pl-0",
                          style: {
                            fontFamily: "Poppins",
                            fontSize: "14px",
                          },
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
                <div className="col-lg-6 pr-0">
                  <div className="row mb-2">
                    <label className="form_label mb-0">
                      Vessel Final Quantity in MT
                    </label>
                    <TextField
                      name="vessel_final_quantity_in_mt"
                      margin="dense"
                      variant="outlined"
                      placeholder="Vessel Final Quantity in MT"
                      value={this.state.vessel_final_quantity_in_mt}
                      fullWidth
                      //type='number'
                      onChange={(e) => {
                        const reg = /^\d*(\.\d{0,3})?$/;
                        if (e.target.value === "" || reg.test(e.target.value)) {
                          this.setState({
                            vessel_final_quantity_in_mt: e.target.value,
                            cargo_gain_loss_in_mt:
                              Number(e.target.value) -
                              Number(this.state.sumOfBargeQuantities),
                          });
                        }
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="row mb-0">
                <div className="col-lg-6 p-0">
                  <label className="form_label mb-0">
                    Cargo Gain/Loss in MT
                  </label>
                  {this.state.cargo_gain_loss_in_mt < 0 ? (
                    <div className="row mb-2">
                      <h4 className="col-lg-5 label-text text-danger pl-3">
                        {this.state.cargo_gain_loss_in_mt
                          ? "(" +
                            this.toLocaleString(
                              Math.abs(this.state.cargo_gain_loss_in_mt)
                            ) +
                            ")"
                          : null}
                      </h4>
                    </div>
                  ) : (
                    <div className="row mb-2">
                      <h4 className="col-lg-5 label-text pl-3">
                        {this.state.cargo_gain_loss_in_mt
                          ? this.state.cargo_gain_loss_in_mt
                          : null}
                      </h4>
                    </div>
                  )}
                </div>
                <div className="col-lg-6 pr-0">
                  <div className="row mb-2">
                    <label className="form_label mb-0">Discharge Port</label>
                    <TextField
                      name="dischargePort"
                      margin="dense"
                      variant="outlined"
                      placeholder="Discharge Port"
                      // onChange={(value)=>(this.setState({dischargePort: value}))}
                      onChange={this.TextHandler}
                      value={this.state.dischargePort.toUpperCase()}
                      fullWidth
                      inputProps={{ style: { textTransform: "uppercase" } }}
                    />
                  </div>
                </div>
              </div>

              <div className="row mb-0">
                <div className="col-lg-6 p-0">
                  <div className="row mb-2">
                    <label className="form_label mb-0">Loading Type</label>
                    <TextField
                      name="loadingType"
                      margin="dense"
                      variant="outlined"
                      onChange={this.TextHandler}
                      value={this.state.loadingType}
                      fullWidth
                      select
                    >
                      <MenuItem value="0">Please Select</MenuItem>
                      <MenuItem value="Floating crane">Floating crane</MenuItem>
                      <MenuItem value="Vessel grabs">Vessel grabs</MenuItem>
                    </TextField>
                  </div>
                </div>
                <div className="col-lg-6 pr-0">
                  <div className="row mb-2">
                    <label className="form_label mb-0">
                      Loading rate per day
                    </label>
                    <TextField
                      name="loadingRatePerDay"
                      placeholder="Loading rate per day"
                      // //label='Loading rate per day'
                      margin="dense"
                      variant="outlined"
                      value={this.state.loadingRatePerDay}
                      onChange={this.handleDecimalField}
                      fullWidth
                      // inputProps={{style:{textTransform:'uppercase'}}}
                    />
                  </div>
                </div>
              </div>

              <div className="row mb-0">
                <div className="col-lg-6 p-0">
                  <div className="row mb-2">
                    <label className="form_label mb-0">
                      Dead Freight Incurred
                    </label>
                    <TextField
                      name="deadFreightIncurred"
                      placeholder="Dead Freight Incurred"
                      // //label='Dead Freight Incurred'
                      margin="dense"
                      variant="outlined"
                      value={this.state.deadFreightIncurred}
                      onChange={this.handleDecimalField}
                      fullWidth
                      // inputProps={{style:{textTransform:'uppercase'}}}
                    />
                  </div>
                </div>
                <div className="col-lg-6 pr-0">
                  <div className="row mb-2">
                    <label className="form_label mb-0">
                      Dead Freight Account
                    </label>
                    <TextField
                      name="deadFreightAccount"
                      margin="dense"
                      variant="outlined"
                      onChange={this.TextHandler}
                      value={this.state.deadFreightAccount}
                      fullWidth
                      select
                    >
                      <MenuItem value="0">Please Select</MenuItem>
                      <MenuItem value="Supplier Account">
                        Supplier Account
                      </MenuItem>
                      <MenuItem value={Config.company + " Account"}>
                        {Config.company + " Account"}
                      </MenuItem>
                      <MenuItem value="Buyer">Buyer</MenuItem>
                    </TextField>
                  </div>
                </div>
              </div>

              <div className="row mb-0">
                <div className="col-lg-6 p-0">
                  <div className="row mb-2">
                    <label className="form_label mb-0">
                      Supplier Surveyor Name
                    </label>
                    <FormControl margin="dense" variant="outlined" fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Search Surveyor
                      </InputLabel>
                      <Select
                        id="demo2-mutiple-checkbox"
                        multiple
                        fullWidth
                        name="supplierServeyorNames"
                        value={this.state.supplierServeyorNames.sort()}
                        onChange={this.MultiSelectHandler}
                        // input={<Input />}
                        renderValue={(selected) => selected.join(", ")}
                        //MenuProps={MenuProps}
                      >
                        {this.state.surveyorsData.map((e, index) => (
                          <MenuItem value={e.name} key={index}>
                            <Checkbox
                              checked={this.state.supplierServeyorNames.includes(
                                e.name
                              )}
                            />
                            <ListItemText primary={e.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="col-lg-6 pr-0">
                  <div className="row mb-2">
                    <label className="form_label mb-0">
                      Supplier Surveyor Analysis Type
                    </label>
                    <TextField
                      name="supplierSurveyorAnalysisType"
                      margin="dense"
                      variant="outlined"
                      onChange={this.TextHandler}
                      value={this.state.supplierSurveyorAnalysisType}
                      fullWidth
                      select
                    >
                      <MenuItem value="0">Please Select</MenuItem>
                      <MenuItem value="ASTM">ASTM</MenuItem>
                      <MenuItem value="ISO">ISO</MenuItem>
                    </TextField>
                  </div>
                </div>
              </div>

              <div className="row mb-0">
                <div className="col-lg-6 p-0">
                  <div className="row mb-2">
                    <label className="form_label mb-0">
                      {Config.company} Surveyor Name
                    </label>
                    <FormControl margin="dense" variant="outlined" fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Search Surveyor
                      </InputLabel>
                      <Select
                        id="demo2-mutiple-checkbox"
                        multiple
                        fullWidth
                        name="internalServeyorNames"
                        value={this.state.internalServeyorNames.sort()}
                        onChange={this.MultiSelectHandler}
                        // input={<Input />}
                        renderValue={(selected) => selected.join(", ")}
                        //MenuProps={MenuProps}
                      >
                        {this.state.surveyorsData.map((e, index) => (
                          <MenuItem value={e.name} key={index}>
                            <Checkbox
                              checked={this.state.internalServeyorNames.includes(
                                e.name
                              )}
                            />
                            <ListItemText primary={e.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="col-lg-6 pr-0">
                  <div className="row mb-2">
                    <label className="form_label mb-0">
                      {Config.company} Surveyor Analysis Type
                    </label>
                    <TextField
                      name="SDAMSAIISurveyorAnalysisType"
                      margin="dense"
                      variant="outlined"
                      onChange={this.TextHandler}
                      value={this.state.SDAMSAIISurveyorAnalysisType}
                      fullWidth
                      select
                    >
                      <MenuItem value="0">Please Select</MenuItem>
                      <MenuItem value="ASTM">ASTM</MenuItem>
                      <MenuItem value="ISO">ISO</MenuItem>
                    </TextField>
                  </div>
                </div>
              </div>

              <div className="row mb-0">
                <div className="col-lg-6 p-0">
                  <div className="row mb-2">
                    <label className="form_label mb-0">
                      Buyer/Additional Surveyor Name
                    </label>
                    <FormControl margin="dense" variant="outlined" fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Search Surveyor
                      </InputLabel>
                      <Select
                        id="demo2-mutiple-checkbox"
                        multiple
                        fullWidth
                        name="jointServeyorNames"
                        value={this.state.jointServeyorNames.sort()}
                        onChange={this.MultiSelectHandler}
                        renderValue={(selected) => selected.join(", ")}
                      >
                        {this.state.surveyorsData.map((e, index) => (
                          <MenuItem value={e.name} key={index}>
                            <Checkbox
                              checked={this.state.jointServeyorNames.includes(
                                e.name
                              )}
                            />
                            <ListItemText primary={e.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="col-lg-6 pr-0"></div>
              </div>

              <div style={{ width: "70%" }}>
                <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                  Surveyor Price
                </h5>
              </div>
              <div className="row mb-0">
                <div className="col-lg-6 p-0">
                  <div className="row mb-2">
                    <label className="form_label mb-0">Base Price</label>
                    <TextField
                      id="outlined-full-width"
                      name="surveyorBasePrice"
                      margin="dense"
                      variant="outlined"
                      type="number"
                      //label='Base Price'
                      placeholder="Base Price"
                      onChange={(e) => {
                        const pr = /^\d*(\.\d{0,4})?$/;
                        if (e.target.value === "" || pr.test(e.target.value)) {
                          let value = e.target.value;
                          this.setState({
                            surveyorBasePrice: value,
                            surveyorTotalPrice:
                              this.state.surveyorVAT !== ""
                                ? (
                                    Number(value) +
                                    Number(this.state.surveyorVAT)
                                  ).toFixed(2)
                                : Number(value).toFixed(2),
                          });
                        }
                      }}
                      // InputLabelProps={{
                      //   shrink: true,
                      // }}
                      value={this.state.surveyorBasePrice}
                      fullWidth
                    />
                  </div>
                </div>
                <div className="col-lg-6 pr-0">
                  <div className="row mb-2">
                    <label className="form_label mb-0">VAT</label>
                    <TextField
                      id="outlined-full-width"
                      name="surveyorVAT"
                      margin="dense"
                      variant="outlined"
                      type="number"
                      //label='VAT'
                      placeholder="VAT"
                      onChange={(e) => {
                        if (e.target.value === "" || re.test(e.target.value)) {
                          let value = e.target.value;
                          this.setState({
                            surveyorVAT: value,
                            surveyorTotalPrice:
                              this.state.surveyorBasePrice !== ""
                                ? (
                                    Number(value) +
                                    Number(this.state.surveyorBasePrice)
                                  ).toFixed(2)
                                : Number(value).toFixed(2),
                          });
                        }
                      }}
                      // InputLabelProps={{
                      //   shrink: true,
                      // }}
                      value={this.state.surveyorVAT}
                      fullWidth
                    />
                  </div>
                </div>
              </div>

              <div className="row mb-0">
                <div className="col-lg-6 p-0">
                  <div className="row mb-2">
                    <label className="form_label mb-0">Total</label>
                    <TextField
                      id="outlined-full-width"
                      //label="Total"
                      placeholder="Total"
                      fullWidth
                      disabled
                      value={this.state.surveyorTotalPrice}
                      margin="dense"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </div>
                </div>
                <div className="col-lg-6 pr-0">
                  <div className="row mb-2">
                    <label className="form_label mb-0">Currency</label>
                    <TextField
                      name="surveyorCurrencyType"
                      margin="dense"
                      //label="Currency"
                      variant="outlined"
                      onChange={this.TextHandler}
                      value={this.state.surveyorCurrencyType}
                      fullWidth
                      select
                    >
                      {this.state.currencyData}
                    </TextField>
                  </div>
                </div>
              </div>

              <div className="row mb-0">
                <div className="col-lg-6 p-0">
                  <div className="row mb-2">
                    <label className="form_label mb-0">
                      Surveyor Additional days
                    </label>
                    <TextField
                      name="surveyorAdditionalDays"
                      placeholder="Surveyor Additional days "
                      // //label='Surveyor Additional days '
                      margin="dense"
                      variant="outlined"
                      value={this.state.surveyorAdditionalDays}
                      onChange={this.handleDecimalField}
                      fullWidth
                      // inputProps={{style:{textTransform:'uppercase'}}}
                    />
                  </div>
                </div>
                <div className="col-lg-6 pr-0"></div>
              </div>

              {this.state.loadingType === "Vessel grabs" && (
                <div style={{ width: "70%" }}>
                  <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                    Vessel grabs
                  </h5>
                </div>
              )}

              {this.state.loadingType === "Vessel grabs" && (
                <div className="row mb-0">
                  <div className="col-lg-6 p-0">
                    <div className="row mb-2">
                      <label className="form_label mb-2">Stevedore on MV</label>
                      <Autocomplete
                        multiple
                        size="small"
                        options={this.state.othersData}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) => {
                          if (value) {
                            var data = [];
                            for (var i = 0; i < value.length; i++) {
                              data.push(value[i].value);
                            }
                            var stevedore_vendor = data.toString();
                            this.setState({
                              stevedore_vendor: stevedore_vendor,
                              stevedoreOnMVValue: value,
                            });
                          } else {
                            this.setState({
                              stevedore_vendor: "",
                              stevedoreOnMVValue: "",
                            });
                          }
                        }}
                        name="stevedore_vendor"
                        disableCloseOnSelect
                        renderOption={(option, { selected }) => (
                          <React.Fragment>
                            <Checkbox
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.name}
                          </React.Fragment>
                        )}
                        //value={this.state.stevedoreOnMVValue}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Search Stevedore"
                            //label="Search Stevedore"
                            variant="outlined"
                            fullWidth
                          />
                        )}
                        style={{ display: "contents" }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 pr-0">
                    <div className="row mb-2">
                      <label className="form_label mb-0">
                        Stevedore Additional days
                      </label>
                      <TextField
                        name="stevedoreAdditionalDays"
                        placeholder="Stevedore Additional days "
                        margin="dense"
                        variant="outlined"
                        value={this.state.stevedoreAdditionalDays}
                        onChange={this.handleDecimalField}
                        fullWidth
                        // inputProps={{style:{textTransform:'uppercase'}}}
                      />
                    </div>
                  </div>
                </div>
              )}

              {this.state.loadingType === "Floating crane" && (
                <div style={{ width: "70%" }}>
                  <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                    Floating crane
                  </h5>
                </div>
              )}

              {this.state.loadingType === "Floating crane" && (
                <div className="row mb-0">
                  <div className="col-lg-6 p-0">
                    <div className="row mb-2">
                      <label className="form_label mb-2">
                        Floating Crane Vendor
                      </label>
                      <Autocomplete
                        multiple
                        size="small"
                        options={this.state.othersData}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) => {
                          if (value) {
                            var data = [];
                            for (var i = 0; i < value.length; i++) {
                              data.push(value[i].value);
                            }
                            var floating_crane_vendor = data.toString();
                            this.setState({
                              floating_crane_vendor: floating_crane_vendor,
                              floatingCrane_vendor_value: value,
                            });
                          } else {
                            this.setState({
                              floating_crane_vendor: "",
                              floatingCrane_vendor_value: "",
                            });
                          }
                        }}
                        name="vendorName"
                        disableCloseOnSelect
                        renderOption={(option, { selected }) => (
                          <React.Fragment>
                            <Checkbox
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.name}
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Search Floating Crane Vendor"
                            //label="Search Floating Crane Vendor"
                            variant="outlined"
                            error={this.state.vendorTypeError}
                            fullWidth
                          />
                        )}
                        style={{ display: "contents" }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 pr-0">
                    <div className="row mb-2">
                      <label className="form_label mb-0">
                        Floating Crane Name
                      </label>
                      <TextField
                        name="floatingCraneName"
                        margin="dense"
                        variant="outlined"
                        placeholder="Floating Crane Name"
                        onChange={this.TextHandler}
                        value={this.state.floatingCraneName}
                        fullWidth
                        inputProps={{ style: { textTransform: "uppercase" } }}
                      />
                    </div>
                  </div>
                </div>
              )}

              {this.state.loadingType === "Floating crane" && (
                <div className="row mb-0">
                  <div className="col-lg-6 p-0">
                    <div className="row mb-2">
                      <label className="form_label mb-0">
                        Floating Crane Additional days
                      </label>
                      <TextField
                        name="floating_crane_additional_days"
                        placeholder="Floating Crane Additional days "
                        margin="dense"
                        variant="outlined"
                        value={this.state.floating_crane_additional_days}
                        onChange={this.handleDecimalField}
                        fullWidth
                        // inputProps={{style:{textTransform:'uppercase'}}}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 pr-0">
                    <div className="row mb-2">
                      <label className="form_label mb-0">
                        Floating Crane Demurrage / Despatch
                      </label>
                      <div className="row col p-0">
                        <div className="col-lg-6 p-0">
                          <TextField
                            name="floatingCraneDemurrageDespatch"
                            placeholder="Demurrage / Despatch"
                            //label='Demurrage / Despatch'
                            margin="dense"
                            type="number"
                            variant="outlined"
                            value={this.state.floatingCraneDemurrageDespatch}
                            onChange={this.handleDecimalField}
                            fullWidth
                            // inputProps={{style:{textTransform:'uppercase'}}}
                          />
                        </div>
                        <div className="col-lg-6 pr-0">
                          <TextField
                            name="floatingCraneDemurrageDespatchCurrencyType"
                            margin="dense"
                            //label="Currency"
                            variant="outlined"
                            onChange={this.TextHandler}
                            value={
                              this.state
                                .floatingCraneDemurrageDespatchCurrencyType
                            }
                            fullWidth
                            select
                          >
                            {this.state.currencyData}
                          </TextField>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="row mb-0">
                <div className="col-lg-6 p-0">
                  <div className="row mb-2">
                    <label className="form_label mb-0">
                      Supplier Documentation Agent
                    </label>
                    <TextField
                      name="supplierDocumentationAgent"
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      // placeholder='Supplier Documentation Agent'
                      onChange={this.TextHandler}
                      inputProps={{ style: { textTransform: "uppercase" } }}
                    />
                  </div>
                </div>
                <div className="col-lg-6 pr-0">
                  <div className="row mb-2">
                    <label className="form_label mb-0">
                      {Config.company} Documentation Agent
                    </label>
                    <TextField
                      name="SDAM_DocumentationAgent"
                      margin="dense"
                      variant="outlined"
                      value={this.state.SDAM_DocumentationAgent}
                      placeholder={Config.company + " Documentation Agent"}
                      onChange={this.TextHandler}
                      fullWidth
                      inputProps={{ style: { textTransform: "uppercase" } }}
                    />
                  </div>
                </div>
              </div>

              <div className="row mb-0">
                <div className="col-lg-6 p-0">
                  <div className="row mb-2">
                    <label className="form_label mb-0">
                      Buyer Documentation Agent
                    </label>
                    <TextField
                      name="buyerDocumentationAgent"
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      value={this.state.buyerDocumentationAgent}
                      placeholder="Buyer Documentation Agent"
                      onChange={this.TextHandler}
                      inputProps={{ style: { textTransform: "uppercase" } }}
                    />
                  </div>
                </div>
                <div className="col-lg-6 pr-0">
                  <div className="row mb-2">
                    <label className="form_label mb-0">Remarks</label>
                    <TextField
                      name="remarks"
                      margin="dense"
                      variant="outlined"
                      value={this.state.remarks}
                      placeholder="Remarks"
                      inputProps={{ style: { textTransform: "uppercase" } }}
                      multiline
                      rows={3}
                      onChange={this.TextHandler}
                      fullWidth
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row bottom_buttons_section">
            {/* <button type='button' className='btn previous_button' onClick={() => { window.location.href = '/view-business/' + btoa(this.state.businessNumberID) }}>Back</button> */}
            <button
              type="button"
              className="btn previous_button"
              onClick={() => this.props.returnCallbackDrawerClose(false)}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn next_button"
              onClick={this.submitHandler}
            >
              Submit
            </button>
            &emsp;
          </div>
        </div>
      );
    }
  }
}
