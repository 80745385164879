import React, { Component } from "react";
import Header from "../../common/Header";
import SideBar from "../../common/SideBar";
import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";

import { Alert } from "@material-ui/lab";
import { Snackbar } from "@material-ui/core";
import Loader from "../../common/Loader";
import { SAMPLING_TYPES } from '../../../common/enums'

export default class SalesSamplingRoutes extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = { features: [] };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    let features = this.Cookie.getCookie("features");
    this.setState({ features: features });

    this.setState({ isLoading: false });
    document.title = config.documentTitle + "sales-quality/";
  }

  render() {
    return (
      <div id="wraper">
        <SideBar />
        <div className="content">
          <Header />
          {this.state.isLoading && <Loader />}
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          {!this.state.isLoading && (
            <div className="content-header">
              <div className="col-sm pl-0">
                <h4 style={{ padding: "10px 20px", margin: "0px" }}>Sales Sampling</h4>
              </div>
              <form className="container p-3">
                <div className="col-lg-12 card">
                  <div className="section_block" style={{ width: "80%" }}>
                    {this.state.features.includes("97") && (
                      <div>
                        <div style={{ width: "70%" }}>
                          <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                            Reports
                          </h5>
                        </div>
                        <div className="row">
                          <ul className="pl-0 mb-0">
                            <li className="list-group-item border-0">
                              <a
                                href={`/sales-quality/reports/${btoa(SAMPLING_TYPES.SUPPLIER)}`}
                                style={{ color: config.themeColor }}
                              >
                                <i
                                  className="fa fa-star-o"
                                  aria-hidden="true"
                                ></i>
                                &emsp;Supplier Report
                              </a>
                            </li>
                            <li className="list-group-item border-0">
                              <a
                                href={`/sales-quality/reports/${btoa(SAMPLING_TYPES.NONE)}`}
                                style={{ color: config.themeColor }}
                              >
                                <i
                                  className="fa fa-star-o"
                                  aria-hidden="true"
                                ></i>
                                &emsp;Aging Report
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    );
  }
}
