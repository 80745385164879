import React, { Component } from "react";

import { Snackbar, Drawer } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import api from "../../../api/api";

// import mother vessel Coasl Procurement Charges
import MVSamplingChargesAdd from "./Mv-Sampling-Charges-Add";
import MVSamplingChargesView from "./Mv-Sampling-Charges-View";
import MVSamplingChargesUpdate from "./Mv-Sampling-Charges-Update";
import Loader from "../../common/Loader";
import { toLocaleString } from "../../common/common";

export default class MvOthersList extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      MVSamplingCharges: [],
      isLoading: true,
      features: [],
      purchaseBargeList: [],
      updateDocuments: false,
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    } else {
      var features = this.Cookie.getCookie("features");
      this.setState({ features: features });
      if (!(features.includes("243") || features.includes("97"))) {
        window.location.href = "/dashboard";
      }
      const loginUserID = this.Cookie.getCookie("loginUserId");
      const idToken = this.Cookie.getIdTokenCookie();
      try {
        //var businessNoID = decodeURIComponent(window.atob(this.props.match.params.businessNoID));
        var businessNoID = this.props.businessNoID;
        //Get businessNumber API based on businessNumber
        const getBusinessNumber = await api.getBusinessNumber(
          businessNoID,
          loginUserID,
          idToken
        );
        if (getBusinessNumber.status >= 200 && getBusinessNumber.status < 300) {
          getBusinessNumber.json().then((res) => {
            if (res.code === "200") {
              if (res.business_number) {
                if (res.business_number.consignment_type === "Mother Vessel") {
                  // if consignment_type === Mother Vessel check for this businessNumber mother Vessel data is avilabe or not
                  //Get Business No Operational Mother Vessel list
                  api
                    .getBusinessNumberMotherVessel(
                      businessNoID,
                      loginUserID,
                      idToken
                    )
                    .then((response) => {
                      if (response.status >= 200 && response.status < 300) {
                        response.json().then((res) => {
                          if (res.idtoken)
                            this.Cookie.setIdTokenCookie(res.idtoken);
                          if (res) {
                            if (res.business_no_MV.length > 0) {
                              var motherVesselID = res.business_no_MV[0].id;
                              //Get Business No Operational Mother Vessel Details
                              api
                                .getBusinessNoOpMVDetails(
                                  motherVesselID,
                                  loginUserID,
                                  idToken
                                )
                                .then((response) => {
                                  if (
                                    response.status >= 200 &&
                                    response.status < 300
                                  ) {
                                    response.json().then((res) => {
                                      if (res.business_no_MV) {
                                        this.setState({
                                          motherVesselID: motherVesselID,
                                          vessel_final_quantity_in_mt: res
                                            .business_no_MV
                                            .vessel_final_quantity_in_mt
                                            ? res.business_no_MV
                                                .vessel_final_quantity_in_mt
                                            : "",
                                          sac_surveyor_name:
                                            res.business_no_MV
                                              .internal_surveyor_names === null
                                              ? ""
                                              : res.business_no_MV
                                                  .internal_surveyor_names,
                                        });
                                      } else {
                                        alert(
                                          "Unexpected error occured. Please contact administrator."
                                        );
                                      }
                                    });
                                  }
                                });
                            }
                          } else {
                            alert(
                              "Unexpected error occured. Please contact administrator."
                            );
                          }
                        });
                      }
                    });
                }
                this.setState({
                  businessNo: res.business_number.business_no
                    ? res.business_number.business_no
                    : "",
                  mother_vessel_name: res.business_number.mother_vessel_name
                    ? res.business_number.mother_vessel_name
                    : "",
                });
              }
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                snackBarErrorOpen: true,
                isLoading: false,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }

        // get mother vessel Sampling & Analaysis api date
        const getSacData = await api.get_mv_pur_fin_sac_charges(
          loginUserID,
          idToken,
          businessNoID
        );
        if (getSacData.status >= 200 && getSacData.status < 300) {
          getSacData.json().then((res) => {
            if (res.code === "200") {
              if (res.mv_pur_fin_sac_charges) {
                var purchase_contracts = res.mv_pur_fin_sac_charges;
                for (let i = 0; i < purchase_contracts.length; i++) {
                  let sac_other_total = 0;
                  for (var obj of purchase_contracts[i].other_charges) {
                    sac_other_total += Number(obj.sac_other_total);
                  }
                  purchase_contracts[i].sac_other_total_payable =
                    sac_other_total;
                  let surveyors = [];
                  let total = 0;
                  let purchase_contracts_barges =
                    purchase_contracts[i].sac_surveyor_charges;
                  let newData = [];
                  for (let k = 0; k < purchase_contracts_barges.length; k++) {
                    total += Number(
                      purchase_contracts_barges[k].sac_surveyor_total
                    );
                    let id = purchase_contracts_barges[k].mv_sac_charges_id;
                    if (id !== null) {
                      surveyors.push(purchase_contracts_barges[k]);
                    }
                  }
                  purchase_contracts[i].mv_surveyor_total_payable = total;
                  let total_payable =
                    Number(total) +
                    Number(sac_other_total) +
                    Number(
                      purchase_contracts[i].sac_surveyor_addl_days_total
                        ? purchase_contracts[i].sac_surveyor_addl_days_total
                        : 0
                    );
                  purchase_contracts[i].total_payable_vendor = total_payable;
                  var result = purchase_contracts_barges.reduce(function (
                    r,
                    a
                  ) {
                    r[a.mv_sac_charges_id] = r[a.mv_sac_charges_id] || [];
                    r[a.mv_sac_charges_id].push(a);
                    return r;
                  },
                  Object.create(null));
                  for (let l = 0; l < Object.keys(result).length; l++) {
                    newData.push({
                      new_barges: result[Object.keys(result)[l]],
                    });
                  }

                  purchase_contracts[i].new_barges = newData;
                  // listCount += newData.length;
                }
                // if (listCount === 0) {
                //   // window.location.href = '/view-business/' + btoa(businessNoID);
                // }
                this.setState({
                  purchaseBargeList: purchase_contracts,
                  //isLoading: false
                });

                // for (var i of res.mv_pur_fin_sac_charges) {
                //   var total_payable_vendor = 0;
                //   var total_paid_to_vendor = 0;
                //   var remaining_amount = 0;
                //   total_payable_vendor += (Number(i.sac_surveyor_total));
                //   total_payable_vendor += (Number(i.sac_surveyor_addl_days_total));
                //   for (var j of i.other_charges) {
                //     total_payable_vendor += (Number(j.sac_other_total));
                //   }

                //   for (var k of i.sac_charges_payments) {
                //     total_paid_to_vendor += (Number(k.amount));
                //   }
                //   remaining_amount = (Number(total_payable_vendor) - Number(total_paid_to_vendor));

                //   i.total_payable_vendor = total_payable_vendor;
                //   i.total_paid_to_vendor = total_paid_to_vendor;
                //   i.remaining_amount = remaining_amount;

                // }
                this.setState({
                  MVSamplingCharges: res.mv_pur_fin_sac_charges,
                  isLoading: false,
                });
              }
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                snackBarErrorOpen: true,
                isLoading: false,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      } catch (e) {
        // window.location.href = '/business-number-list';
      }
    }
  }

  onAddResponse = (value) => {
    if (value.code === "200") {
      this.setState(
        {
          MVSamplingChargesAdd: false,
          MVSamplingChargesView: false,
          MVSamplingChargesUpdate: false,
          openDrawer: false,
          updateDocuments: false,
        },
        () => this.componentDidMount()
      );
    } else {
      this.setState({
        MVSamplingChargesAdd: false,
        MVSamplingChargesView:
          value.screen === "MvSac" && value.message === "alreadyDataAvailable"
            ? true
            : false,
        MVSamplingChargesUpdate: false,
        openDrawer: true,
        updateDocuments: false,
      });
    }
  };

  CallbackDrawerClose = (childData) => {
    this.setState(
      {
        openDrawer: childData,
        MVSamplingChargesAdd: false,
        MVSamplingChargesView: false,
        MVSamplingChargesUpdate: false,
        updateDocuments: false,
      },
      () => this.componentDidMount()
    );
  };

  onUpdateResponse = (value) => {
    if (value) {
      this.setState(
        {
          MVSamplingChargesAdd: false,
          MVSamplingChargesView:
            value.code === "200" && value.screen === "MvSac" ? true : false,
          MVSamplingChargesUpdate: false,
          updateDocuments: false,
        },
        () => this.componentDidMount()
      );
    }
  };

  CallbackViewPage = (childData) => {
    this.setState(
      {
        openDrawer: true,
        MVSamplingChargesUpdate: false,
        MVSamplingChargesAdd: false,
        MVSamplingChargesView: childData === "MvSac" ? true : false,
        updateDocuments: false,
      },
      () => this.componentDidMount()
    );
  };

  editCallback = (childData) => {
    this.setState(
      {
        openDrawer: true,
        MVSamplingChargesUpdate: childData === "MvSac" ? true : false,
        MVSamplingChargesAdd: false,
        MVSamplingChargesView: false,
        updateDocuments: false,
      },
      () => this.componentDidMount()
    );
  };

  deleteCharges = (data) => {
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    var finalData = data;
    finalData.mv_sac_charges_id = data.id;
    finalData.login_user_id = loginUserID;
    finalData.idtoken = idToken;
    finalData.status = "Deleted";
    //Delete Stevedore costing charge
    api.update_mv_sac_charges(finalData).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.setState(
              {
                snackBarSuccessOpen: true,
                successMsg: res.message,
              },
              () => this.componentDidMount()
            );
          } else if (res.code === "601") {
            this.setState({ errorMsg: res.message, snackBarErrorOpen: true });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          } else if (res.code === "624") {
            this.setState({
              errorMsg: res.columns.length > 0 ? res.columns[0] : "",
              snackBarErrorOpen: true,
            });
          }
        });
      }
    });
  };

  render() {
    let drawerComponent;
    if (this.state.MVSamplingChargesAdd) {
      drawerComponent = (
        <MVSamplingChargesAdd
          businessNoID={this.props.businessNoID}
          onAddResponse={this.onAddResponse}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
        />
      );
    } else if (this.state.MVSamplingChargesView && this.state.businessNo) {
      drawerComponent = (
        <MVSamplingChargesView
          MVSamplingChargesID={this.state.MVSamplingChargesID}
          businessNoID={this.state.businessNo}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.editCallback}
          returnDocumentsHandler={() => {
            this.setState({
              showDrawer: true,
              MVSamplingChargesUpdate: true,
              MVSamplingChargesView: false,
              MVSamplingChargesAdd: false,
              updateDocuments: true,
            });
          }}
        />
      );
    } else if (this.state.MVSamplingChargesUpdate && this.state.businessNo) {
      drawerComponent = (
        <MVSamplingChargesUpdate
          MVSamplingChargesID={this.state.MVSamplingChargesID}
          businessNoID={this.state.businessNo}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.CallbackViewPage}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div>
          <div className="row clearfix content-header border-bottom">
            <div className="row clearfix content-header border-bottom">
              <h4 className="mt-2" style={{ padding: "4px" }}>
                Sampling & Analysis Charges- {this.state.mother_vessel_name} {"(" + this.state.businessNo + ")"}
              </h4>
              {(this.state.features.includes("242") ||
                this.state.features.includes("97")) && (
                <i
                  className="fa fa-plus-circle ml-2 mt-3"
                  aria-hidden="true"
                  style={{
                    fontSize: 22,
                    color: config.themeColor,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    this.setState({
                      openDrawer: true,
                      MVSamplingChargesAdd: true,
                      MVSamplingChargesView: false,
                      MVSamplingChargesUpdate: false,
                    });
                  }}
                />
              )}
            </div>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <Drawer
            anchor="right"
            open={this.state.openDrawer}
            variant="temporary"
            elevation={20}
            style={{ overflow: "initial" }}
          >
            <div className="row" style={{ width: 800 }}>
              {drawerComponent}
            </div>
          </Drawer>
          <div className="card pl-0 pr-0">
            <div
              className="card-body p-0"
              style={{ backgroundColor: "rgba(25,118,210,.01)" }}
              id="accordion"
            >
              <div className="col-lg-12 mb-2 p-0">
                <div className="row">
                  <div className="table-responsive">
                    {this.state.purchaseBargeList.length > 0 && (
                      <div className="table-responsive">
                        <table className="table table-bordered table-sm">
                          <thead className="table-header">
                            <tr>
                              <th
                                nowrap="true"
                                className="allocation_table_header_label "
                              >
                                Costing ID
                              </th>
                              <th
                                nowrap="true"
                                className="allocation_table_header_label "
                              >
                                Surveyor Name
                              </th>
                              <th
                                nowrap="true"
                                className="allocation_table_header_label  text-right"
                              >
                                Quantity in MT
                              </th>
                              <th
                                nowrap="true"
                                className="allocation_table_header_label  text-right"
                              >
                                Price Per MT
                              </th>
                              <th
                                nowrap="true"
                                className="allocation_table_header_label  text-right"
                              >
                                Payable to Vendor
                              </th>
                              <th
                                nowrap="true"
                                className="allocation_table_header_label  text-right"
                              >
                                Others Payable to Vendor
                              </th>
                              <th
                                nowrap="true"
                                className="allocation_table_header_label  text-right"
                              >
                                Additional Charges Payable to Vendor
                              </th>
                              <th
                                nowrap="true"
                                className="allocation_table_header_label  text-right"
                              >
                                Total Payable
                              </th>
                              <th
                                nowrap="true"
                                className="allocation_table_header_label  text-left pl-4"
                              >
                                Action
                              </th>
                            </tr>
                          </thead>
                          {this.state.purchaseBargeList.map((e, index) => (
                            <tbody key={index}>
                              {e.sac_surveyor_charges.map((item, idx) => (
                                <tr>
                                  {idx === 0 && (
                                    <td rowSpan={e.sac_surveyor_charges.length}>
                                      {(this.state.features.includes("243") >=
                                        0 ||
                                        this.state.features.includes("97") >=
                                          0) && (
                                        <i
                                          aria-hidden="true"
                                          style={{
                                            fontStyle: "normal",
                                            color: config.themeColor,
                                            cursor: "pointer",
                                            whiteSpace: "nowrap",
                                          }}
                                          onClick={() =>
                                            this.setState({
                                              openDrawer: true,
                                              MVSamplingChargesAdd: false,
                                              MVSamplingChargesView: true,
                                              MVSamplingChargesUpdate: false,
                                              businessNo: e.business_no_id,
                                              MVSamplingChargesID: e.id,
                                            })
                                          }
                                        >
                                          {e.costing_no}
                                        </i>
                                      )}
                                    </td>
                                  )}
                                  {idx === 0 && (
                                    <td rowSpan={e.sac_surveyor_charges.length}>
                                      {item.mv_sac_charges_vendor_name}
                                    </td>
                                  )}
                                  <td>
                                    {toLocaleString(item.sac_quantity_in_mt)}
                                  </td>
                                  <td>
                                    {toLocaleString(item.sac_price_per_mt)}
                                  </td>
                                  <td>
                                    {toLocaleString(item.sac_surveyor_total)}
                                  </td>
                                  {idx === 0 && (
                                    <td rowSpan={e.sac_surveyor_charges.length}>
                                      {toLocaleString(
                                        e.sac_other_total_payable
                                      )}
                                    </td>
                                  )}
                                  {idx === 0 && (
                                    <td rowSpan={e.sac_surveyor_charges.length}>
                                      {toLocaleString(
                                        e.sac_surveyor_addl_days_total
                                      )}
                                    </td>
                                  )}
                                  {idx === 0 && (
                                    <td rowSpan={e.sac_surveyor_charges.length}>
                                      {toLocaleString(e.total_payable_vendor)}
                                    </td>
                                  )}
                                  {idx === 0 && (
                                    <td
                                      nowrap="true"
                                      className="table_td text-left pl-4"
                                      rowSpan={e.sac_surveyor_charges.length}
                                    >
                                      {(this.state.features.includes("244") >=
                                        0 ||
                                        this.state.features.includes("97") >=
                                          0) && (
                                        <i
                                          className="fa fa-pencil"
                                          style={{
                                            fontSize: 14,
                                            color: config.themeColor,
                                            cursor: "pointer",
                                          }}
                                          data-toggle="modal"
                                          title="Edit floating crane charges"
                                          data-placement="bottom"
                                          onClick={() =>
                                            this.setState({
                                              businessNo: e.business_no_id,
                                              MVSamplingChargesID: e.id,
                                              openDrawer: true,
                                              MVSamplingChargesUpdate: true,
                                            })
                                          }
                                        ></i>
                                      )}
                                      {e.payment_postings_availability === 0 &&
                                        (this.state.features.includes("245") >=
                                          0 ||
                                          this.state.features.includes("97") >=
                                            0) &&
                                        e.approval_status === "Pending" && (
                                          <i
                                            className="fa fa-trash-o"
                                            style={{
                                              paddingLeft: 10,
                                              fontSize: 14,
                                              color: config.themeColor,
                                              cursor: "pointer",
                                            }}
                                            data-toggle="modal"
                                            title="Delete floating crane charges"
                                            data-placement="bottom"
                                            data-keyboard="false"
                                            data-target="#deleteConformationModal"
                                            data-backdrop="static"
                                            onClick={() =>
                                              this.deleteCharges(e)
                                            }
                                          ></i>
                                        )}
                                    </td>
                                  )}
                                </tr>
                              ))}
                            </tbody>
                          ))}
                        </table>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
