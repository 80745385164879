import React, { Component } from 'react';
import SideBar from '../common/SideBar';
import Header from '../common/Header';
import CookieHandler from '../common/CookieHandler';
import api from '../../api/api';
import config from '../../config/config';
import { pad } from '../common/common';
import Loader from '../common/Loader';
import { Typography } from '@material-ui/core';

export default class PurchaseQualityResultsView extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      businessNoBargeID: '',
      quality_calorific_value_actual: '',
      quality_calorific_value2_actual: '',
      quality_calorific_value3_actual: '',
      quality_total_moisture_actual: '',
      quality_inherent_moisture_actual: '',
      quality_ash_actual: '',
      quality_volatile_matter_actual: '',
      quality_fixed_carbon_actual: '',
      quality_fuel_ratio_actual: '',
      quality_total_sulphur_actual: '',
      quality_hgi_actual: '',

      businessNoID: "",
      isLoading: true,
      errorMsg: '',
      succesMsg: '',

      features: [],
      files: [],
    }
  }

  componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace('/logout');
    }
    else {
      var features = this.Cookie.getCookie("features");
      this.setState({ features: features });
      if (!(features.includes('56') || features.includes('97'))) {
        window.location.href = '/dashboard';
      }
      var idToken = this.Cookie.getIdTokenCookie();
      var loginUserID = this.Cookie.getCookie('loginUserId');
      try {
        let bargeID = decodeURIComponent(window.atob(this.props.match.params.bargeID));
        bargeID = bargeID.split(',')[0];

        // Get All Purchase Contract Specificatons By Barge ID.
        api.get_pcq_typical_specs(loginUserID, idToken, bargeID).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === '200') {
                if (res.pcq_typical_specs) {
                  this.setState({
                    BTRDataAvailable: true,
                    quality_calorific_value_typical: res.pcq_typical_specs.quality_calorific_value_typical,
                    quality_calorific_value_rejection: res.pcq_typical_specs.quality_calorific_value_rejection,
                    quality_calorific_value_basis: res.pcq_typical_specs.quality_calorific_value_basis,
                    quality_calorific_value2_typical: res.pcq_typical_specs.quality_calorific_value2_typical,
                    quality_calorific_value2_rejection: res.pcq_typical_specs.quality_calorific_value2_rejection,
                    quality_calorific_value2_basis: res.pcq_typical_specs.quality_calorific_value2_basis,
                    quality_calorific_value3_typical: res.pcq_typical_specs.quality_calorific_value3_typical,
                    quality_calorific_value3_rejection: res.pcq_typical_specs.quality_calorific_value3_rejection,
                    quality_calorific_value3_basis: res.pcq_typical_specs.quality_calorific_value3_basis,
                    quality_total_moisture_typical: res.pcq_typical_specs.quality_total_moisture_typical,
                    quality_total_moisture_rejection: res.pcq_typical_specs.quality_total_moisture_rejection,
                    quality_total_moisture_basis: res.pcq_typical_specs.quality_total_moisture_basis,
                    quality_inherent_moisture_typical: res.pcq_typical_specs.quality_inherent_moisture_typical,
                    quality_inherent_moisture_rejection: res.pcq_typical_specs.quality_inherent_moisture_rejection,
                    quality_inherent_moisture_basis: res.pcq_typical_specs.quality_inherent_moisture_basis,
                    quality_ash_typical: res.pcq_typical_specs.quality_ash_typical,
                    quality_ash_rejection: res.pcq_typical_specs.quality_ash_rejection,
                    quality_ash_basis: res.pcq_typical_specs.quality_ash_basis,
                    quality_volatile_matter_typical: res.pcq_typical_specs.quality_volatile_matter_typical,
                    quality_volatile_matter_rejection: res.pcq_typical_specs.quality_volatile_matter_rejection,
                    quality_volatile_matter_basis: res.pcq_typical_specs.quality_volatile_matter_basis,
                    quality_fixed_carbon_typical: res.pcq_typical_specs.quality_fixed_carbon_typical,
                    quality_fixed_carbon_rejection: res.pcq_typical_specs.quality_fixed_carbon_rejection,
                    quality_fixed_carbon_basis: res.pcq_typical_specs.quality_fixed_carbon_basis,
                    quality_fuel_ratio_typical: res.pcq_typical_specs.quality_fuel_ratio_typical,
                    quality_fuel_ratio_rejection: res.pcq_typical_specs.quality_fuel_ratio_rejection,
                    quality_fuel_ratio_basis: res.pcq_typical_specs.quality_fuel_ratio_basis,
                    quality_total_sulphur_typical: res.pcq_typical_specs.quality_total_sulphur_typical,
                    quality_total_sulphur_rejection: res.pcq_typical_specs.quality_total_sulphur_rejection,
                    quality_total_sulphur_basis: res.pcq_typical_specs.quality_total_sulphur_basis,
                    quality_hgi_typical: res.pcq_typical_specs.quality_hgi_typical,
                    quality_hgi_rejection: res.pcq_typical_specs.quality_hgi_rejection,
                    //isLoading: false
                  })
                }
                else {
                  this.setState({
                    isLoading: false
                  })
                }
              }
              else if (res.code === '601') {
                this.setState({
                  errorMsg: res.message,
                  succesMsg: '',
                  isLoading: false
                });
              }
              else if (res.code === '607') {
                window.location.href = '/logout';
              }
            })
          }
        });

        // get Barge Purchase Quality Results By Barge ID
        api.get_quality_results_by_barge_id(loginUserID, idToken, bargeID).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === '200') {
                if (res.barge_purchase_qualities) {
                  this.setState({
                    quality_calorific_value_actual: res.barge_purchase_qualities.quality_calorific_value_actual,
                    quality_calorific_value2_actual: res.barge_purchase_qualities.quality_calorific_value2_actual,
                    quality_calorific_value3_actual: res.barge_purchase_qualities.quality_calorific_value3_actual,
                    quality_total_moisture_actual: res.barge_purchase_qualities.quality_total_moisture_actual,
                    quality_inherent_moisture_actual: res.barge_purchase_qualities.quality_inherent_moisture_actual,
                    quality_ash_actual: res.barge_purchase_qualities.quality_ash_actual,
                    quality_volatile_matter_actual: res.barge_purchase_qualities.quality_volatile_matter_actual,
                    quality_fixed_carbon_actual: res.barge_purchase_qualities.quality_fixed_carbon_actual,
                    quality_fuel_ratio_actual: res.barge_purchase_qualities.quality_fuel_ratio_actual,
                    quality_total_sulphur_actual: res.barge_purchase_qualities.quality_total_sulphur_actual,
                    quality_hgi_actual: res.barge_purchase_qualities.quality_hgi_actual,
                    files: res.barge_purchase_qualities.files,
                    isLoading: false
                  })
                }
                else {
                  this.setState({
                    isLoading: false
                  });
                }
              } else if (res.code === '601') {
                this.setState({
                  errorMsg: res.message,
                  succesMsg: ''
                });
              } else if (res.code === '607') {
                window.location.href = '/logout';
              }
            })
          }
        });
      }
      catch (e) {
        window.location.href = '/pagenotfound';
      }
    }
    document.title = config.documentTitle + "View Purchase Quality Results";
  }

  pad(id) {
    return id ? id.padStart(4, 0) : null;
  }


  render() {
    const bargeID = decodeURIComponent(window.atob(this.props?.match?.params?.bargeID));
    const groupID = decodeURIComponent(window.atob(this.props?.match?.params?.groupID));
    const samplingType = decodeURIComponent(window.atob(this.props?.match?.params?.samplingType));
    if (!bargeID && !groupID && !samplingType) {
      window.location.href = "/purchase-quality";
    }
    return (
      <div id='wraper'>
        <SideBar />
        <div className='content'>
          <Header />
          {!!this.state.isLoading &&
            <Loader />
          }
          {!this.state.isLoading &&
            <div>

              <Typography>
                <div className='content-header'>
                  <div className='col-sm text-left pl-0'>
                    <h4 style={{ padding: '10px 20px', margin: '0px' }}>Barge Purchase Quality Results - {pad(bargeID)}</h4>
                  </div>
                </div>
                <section className='container p-3'>
                  <div className='col-lg card'>
                    {this.state.succesMsg &&
                      <div className='row'>
                        <div className='col text-center'>
                          <p className='success-class'>{this.state.succesMsg}</p>
                        </div>
                      </div>
                    }
                    {this.state.errorMsg &&
                      <div className='row'>
                        <div className='col text-center'>
                          <p className='error-class'>{this.state.errorMsg}</p>
                        </div>
                      </div>
                    }
                    <div className='row'>
                      <div className='col'>
                        <div className='table-responsive'>
                          <table className='table table-bordered table-sm mb-0'>
                            <thead className="border-0">
                              <tr>
                                <th className="text-uppercase text-center" style={{ fontSize: '16px' }}>Typical Analysis</th>
                                <th className="text-uppercase text-center" style={{ fontSize: '16px' }}>Units</th>
                                <th className="text-uppercase text-center" style={{ fontSize: '16px' }}>Basis</th>
                                <th className="text-uppercase text-center" style={{ fontSize: '16px' }}>Typical</th>
                                <th className="text-uppercase text-center" style={{ fontSize: '16px' }}>Rejection</th>
                                <th className="text-uppercase text-center" style={{ fontSize: '16px' }}>Actual</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="table_header_barge_label" style={{ verticalAlign: 'middle', fontSize: '14px' }} >Gross Calorific Value</td>
                                <td style={{ verticalAlign: 'middle' }}>Kcal/kg</td>
                                <td>{this.state.quality_calorific_value_basis}</td>
                                <td>{this.state.quality_calorific_value_typical}</td>
                                <td>{this.state.quality_calorific_value_rejection}</td>
                                <td>{this.state.quality_calorific_value_actual} </td>
                              </tr>

                              <tr>
                                <td className="table_header_barge_label" style={{ verticalAlign: 'middle', fontSize: '14px', borderTop: 'none' }} ></td>
                                <td style={{ verticalAlign: 'middle', borderTop: 'none' }}>Kcal/kg</td>
                                <td style={{ borderTop: 'none' }}>{this.state.quality_calorific_value2_basis}</td>
                                <td style={{ borderTop: 'none' }}>{this.state.quality_calorific_value2_typical}</td >
                                <td style={{ borderTop: 'none' }}>{this.state.quality_calorific_value2_rejection}</td>
                                <td>{this.state.quality_calorific_value2_actual}</td>
                              </tr>

                              <tr>
                                <td className="table_header_barge_label" style={{ verticalAlign: 'middle', fontSize: '14px', borderTop: 'none' }} ></td>
                                <td style={{ verticalAlign: 'middle', borderTop: 'none' }}>Kcal/kg</td>
                                <td style={{ borderTop: 'none' }}>{this.state.quality_calorific_value3_basis}</td>
                                <td style={{ borderTop: 'none' }}>{this.state.quality_calorific_value3_typical}</td >
                                <td style={{ borderTop: 'none' }}>{this.state.quality_calorific_value3_rejection}</td>
                                <td>{this.state.quality_calorific_value3_actual}</td>
                              </tr>

                              <tr>
                                <td className="table_header_barge_label" style={{ verticalAlign: 'middle', fontSize: '14px' }}>Total Moisture</td>
                                <td style={{ verticalAlign: 'middle' }}>%</td>
                                <td>{this.state.quality_total_moisture_basis}</td>
                                <td>{this.state.quality_total_moisture_typical}</td>
                                <td>{this.state.quality_total_moisture_rejection}</td>
                                <td>{this.state.quality_total_moisture_actual}</td>
                              </tr>

                              <tr>
                                <td className="table_header_barge_label" style={{ verticalAlign: 'middle', fontSize: '14px' }}>Inherent Moisture</td>
                                <td style={{ verticalAlign: 'middle' }}>%</td>
                                <td>{this.state.quality_inherent_moisture_basis}</td>
                                <td>{this.state.quality_inherent_moisture_typical}</td>
                                <td>{this.state.quality_inherent_moisture_rejection}</td>
                                <td>{this.state.quality_inherent_moisture_actual} </td>
                              </tr>

                              <tr>
                                <td className="table_header_barge_label" style={{ verticalAlign: 'middle', fontSize: '14px' }}>Ash</td>
                                <td style={{ verticalAlign: 'middle' }}>%</td>
                                <td>{this.state.quality_ash_basis}</td>
                                <td>{this.state.quality_ash_typical}</td>
                                <td>{this.state.quality_ash_rejection}</td>
                                <td>{this.state.quality_ash_actual} </td>
                              </tr>

                              <tr>
                                <td className="table_header_barge_label" style={{ verticalAlign: 'middle', fontSize: '14px' }}>Volatile Matter(VM)</td>
                                <td style={{ verticalAlign: 'middle' }}>%</td>
                                <td>{this.state.quality_volatile_matter_basis}</td>
                                <td>{this.state.quality_volatile_matter_typical}</td>
                                <td>{this.state.quality_volatile_matter_rejection}</td>
                                <td>{this.state.quality_volatile_matter_actual}</td>
                              </tr>

                              <tr>
                                <td className="table_header_barge_label" style={{ verticalAlign: 'middle', fontSize: '14px' }}>Fixed Carbon(FC)</td>
                                <td style={{ verticalAlign: 'middle' }}>%</td>
                                <td>{this.state.quality_fixed_carbon_basis}</td>
                                <td>{this.state.quality_fixed_carbon_typical}</td>
                                <td>{this.state.quality_fixed_carbon_rejection}</td>
                                <td>{this.state.quality_fixed_carbon_actual}</td>
                              </tr>

                              <tr>
                                <td className="table_header_barge_label" style={{ verticalAlign: 'middle', fontSize: '14px' }}>Fuel Ratio(FC/VM)</td>
                                <td style={{ verticalAlign: 'middle' }}>%</td>
                                <td>{this.state.quality_fuel_ratio_basis}</td>
                                <td>{this.state.quality_fuel_ratio_typical}</td>
                                <td>{this.state.quality_fuel_ratio_rejection}</td>
                                <td>{this.state.quality_fuel_ratio_actual}</td>
                              </tr>

                              <tr>
                                <td className="table_header_barge_label" style={{ verticalAlign: 'middle', fontSize: '14px' }}>Total Sulphur</td>
                                <td style={{ verticalAlign: 'middle' }}>%</td>
                                <td>{this.state.quality_total_sulphur_basis}</td>
                                <td>{this.state.quality_total_sulphur_typical}</td>
                                <td>{this.state.quality_total_sulphur_rejection}</td>
                                <td>{this.state.quality_total_sulphur_actual}</td>
                              </tr>

                              <tr>
                                <td className="table_header_barge_label" style={{ verticalAlign: 'middle', fontSize: '14px' }}>HGI</td>
                                <td style={{ verticalAlign: 'middle' }}></td>
                                <td></td>
                                <td>{this.state.quality_hgi_typical}</td>
                                <td>{this.state.quality_hgi_rejection}</td>
                                <td>{this.state.quality_hgi_actual}</td>
                              </tr>

                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {/* {(this.state.features.includes('323')  || this.state.features.includes('97') ) && */}

                    <div className='card p-0 border mb-3 mt-2'>
                      <div className='card-header section_header'>
                        <h5>Uploaded Files</h5>
                      </div>
                      {this.state.files.length > 0 ?

                        <div className='card-body'>

                          {this.state.files.map((e, index) => (

                            <div className='row mb-3' key={index}>
                              <div className='col-lg-4 p-0'>
                                {/* <label className='form_label mb-2'>File Description</label> */}
                                <div className=''>
                                  <a href={config.apiDomain + "/" + e.file_path} role="button" target='_blank' rel="noopener noreferrer" style={{ color: config.themeColor }}>{e.file_description}</a>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                        :
                        <div className='card-body'>
                          <p>No files to show</p>
                        </div>
                      }
                    </div>
                    {/* } */}

                    <br />
                  </div>
                  <div className='row bottom_buttons_section'>
                    <button type='button' className='btn previous_button' onClick={() => window.location.href = "/purchase-quality"}>Back</button>
                    {(this.state.features.includes('57') || this.state.features.includes('97')) &&
                      <button type='button' className='btn next_button'
                        onClick={() => {
                          window.location.href =
                            `/purchase-quality/update/${btoa(samplingType)}/${btoa(bargeID)}/${btoa(groupID)}`;
                        }}
                      >Edit</button>
                    }
                  </div>
                </section>

              </Typography>
            </div>
          }
        </div>
      </div>
    )
  }
}