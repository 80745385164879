import React, { Component } from 'react';
import Logo from '../../images/SDAM_Logo.png';
// import AccountCircle from '@material-ui/icons/AccountCircle';
import Popover from '@material-ui/core/Popover';
// import MenuIcon from '@material-ui/icons/Menu';
import WarningIcon from '@material-ui/icons/Warning';
import { TextField, MenuItem } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
// import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
// import GroupRoundedIcon from '@material-ui/icons/GroupRounded';
// import BusinessIcon from '@material-ui/icons/Business';
// import DescriptionIcon from '@material-ui/icons/Description';
// import PostAddIcon from '@material-ui/icons/PostAdd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDolly } from '@fortawesome/free-solid-svg-icons';

import '../../App.css';
// import { config, counter } from '@fortawesome/fontawesome-svg-core';
import SideBar from './SideBar';
import Config from '../../config/config';
import CookieHandler from './CookieHandler';
import CacheBuster from './CacheBuster';
import api from '../../api/api';
// import { backgroundClip } from 'html2canvas/dist/types/css/property-descriptors/background-clip';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      userName: '',
      menuOpen: false,
      anchorEl: null,
      anchorEn: null,
      addButtonOpen: false,
      crossIcon: false,
      menuIcon: true,
      openDrawer: false,
      open: false,
      dialogOpen: false,
      loginDialogOpen: false,
      password: '',
      sessionTimeStamp: '',
      sessionTime: '',
    }
  }

  componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      window.location.href = '/logout';
    }
    else {
      var loginUserName = this.Cookie.getCookie('loginUserName');
      var loginUserFullName = this.Cookie.getCookie('loginUserFullName');
      this.setState({
        userName: loginUserName,
        userFullName: loginUserFullName
      });
      var loginUserID = this.Cookie.getCookie('loginUserId');
      var idToken = this.Cookie.getIdTokenCookie();
      var sessionTime = new Date().getTime() + Config.sessionTime;
      //Refreshing Token
      api.refreshToken(loginUserID, idToken).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === '200') {
              this.Cookie.setIdTokenCookie(res.idtoken);
              this.Cookie.setCookie('sessionTime', sessionTime);
              this.setState({
                sessionTime: Config.sessionTime / 1000,
                sessionTimeStamp: sessionTime
              });
            }
            else if (res.code === '607') {
              window.location.href = '/logout'
            }
          })
        }
      });
      this.startTimer();
    }
  }

  startTimer() {
    this.myInterval = setInterval(() => {
      if (this.state.sessionTime !== '') {
        let counterStartTime = this.Cookie.getCookie('sessionTime');
        let counterPresentTime = new Date().getTime();
        var remainingTime = counterStartTime - counterPresentTime;
        var counterTime = parseInt(remainingTime / 1000);
        this.setState({
          dialogOpen: false,
          sessionTime: parseInt(counterTime)
        });
        if (counterTime <= 0) {
          // clearInterval(this.myInterval);
          this.setState({
            dialogOpen: false,
            loginDialogOpen: true
          });
        }
        else if (counterTime <= 300) {
          if (counterTime > 240 && counterTime <= 300) {
            this.setState({
              timeInMinutes: 5,
              timeType: 'minutes',
              dialogOpen: true,
              loginDialogOpen: false
            });
          }
          else if (counterTime > 180 && counterTime <= 240) {
            this.setState({
              timeInMinutes: 4,
              timeType: 'minutes',
              dialogOpen: true,
              loginDialogOpen: false
            });
          }
          else if (counterTime > 120 && counterTime <= 180) {
            this.setState({
              timeInMinutes: 3,
              timeType: 'minutes',
              dialogOpen: true,
              loginDialogOpen: false
            });
          }
          else if (counterTime > 60 && counterTime <= 120) {
            this.setState({
              timeInMinutes: 2,
              timeType: 'minutes',
              dialogOpen: true,
              loginDialogOpen: false
            });
          }
          else if (counterTime === 60) {
            this.setState({
              timeInMinutes: 1,
              timeType: 'minute',
              dialogOpen: true,
              loginDialogOpen: false
            });
          }
          else if (counterTime < 2) {
            this.setState({
              timeInMinutes: parseInt(counterTime),
              timeType: 'second',
              dialogOpen: true,
              loginDialogOpen: false
            });
          }
          else {
            this.setState({
              timeInMinutes: parseInt(counterTime),
              timeType: 'seconds',
              dialogOpen: true,
              loginDialogOpen: false
            });
          }
        }
        else {
          this.setState({
            dialogOpen: false,
            loginDialogOpen: false
          });
        }
      }
    }, 1000);
  }

  handleClose = () => {
    this.setState({
      menuOpen: false
    });
  }

  handleOpen = (event) => {
    event.preventDefault();
    this.setState({
      menuOpen: true,
      openDrawer: false,
      anchorEl: event.currentTarget
    });
  }

  addButtonClose = () => {
    this.setState({
      addButtonOpen: false
    });
  }

  addButtonOpen = (event) => {
    event.preventDefault();
    this.setState({
      addButtonOpen: true,
      openDrawer: false,
      anchorEn: event.currentTarget
    });
  }

  sidebarHandler = (e) => {
    e.preventDefault();
    this.setState({
      openDrawer: !this.state.openDrawer
    });
  }

  handleClickOk = () => {
    var sessionTime = new Date().getTime() + Config.sessionTime;
    this.Cookie.setCookie('sessionTime', sessionTime);
    this.setState({
      dialogOpen: false,
      sessionTime: Config.sessionTime / 1000,
      sessionTimeStamp: sessionTime
    });
    setInterval(this.myInterval, 1000);
    var loginUserID = this.Cookie.getCookie('loginUserId');
    var idToken = this.Cookie.getIdTokenCookie();
    //Refreshing Token
    api.refreshToken(loginUserID, idToken).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === '200') {
            this.Cookie.setIdTokenCookie(res.idtoken);
            // this.Cookie.setCookie('sessionTime', sessionTime);
          }
          else if (res.code === '607') {
            window.location.href = '/logout'
          }
        })
      }
    });
  }

  handleClickCancel = () => {
    window.location.href = '/logout';
  }

  onChange = (value) => {
    this.setState({
      openDrawer: value
    });
    return value;
  }

  handleText = (e) => {
    this.setState({
      errorMessage: '',
      [e.target.name]: e.target.value
    });
  }

  handleLogin = (e) => {
    e.preventDefault();
    var { userName, password } = this.state;
    if (password === '') {
      this.setState({
        errorMessage: 'Password cannot be empty!',
      });
      return;
    }
    var sessionTime = new Date().getTime() + Config.sessionTime;
    api.userLogin(userName, password).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.user_id) {
            this.Cookie.setCookie('sessionTime', sessionTime);
            setInterval(this.myInterval, 1000);
            this.startTimer();
            this.setState({
              sessionTime: Config.sessionTime / 1000,
              sessionTimeStamp: sessionTime,
              loginDialogOpen: false,
              password: ''
            });
            this.Cookie.setIdTokenCookie(res.idtoken);
          }
          if (res.code === '605') {
            this.setState({
              errorMessage: 'Invalid password',
            });
            return;
          }
        })
      }
    })
  }

  render() {
    return (
      <span>
        <CacheBuster>
          {({ loading, isLatestVersion, refreshCacheAndReload }) => {
            if (loading) return null;
            if (!loading && !isLatestVersion) {
              // You can decide how and when you want to force reload
              refreshCacheAndReload();
            }
            return null;
            // return (
            //   <div className="App">
            //     <header className="App-header">
            //       <h1>Cache Busting - Example</h1>
            //       <p>
            //         Bundle version - <code>v{global.appVersion}</code>
            //       </p>
            //     </header>
            //   </div>
            // );
          }}
        </CacheBuster>
        <header className='main_header'>
          <div className='header-MenuIcon'>
            <span>
              <i onClick={this.sidebarHandler.bind(this)} style={{ cursor: 'pointer' }} className="fa fa-bars" aria-hidden="true"></i>
              {/* <MenuIcon onClick={this.sidebarHandler.bind(this)} style={{cursor:'pointer'}} /> */}
            </span>
          </div>
          <div className='header-imageMobile'>
            {Config.company === 'SDAM' ?
              <span className="right_block_login_logo_container">
                <img src={Logo} alt="SDAM LOGO" className="mobile_header_logo" />
              </span>
              : Config.company === "AVS" ? <h1 style={{ fontSize: 18 }}>AVS Resources</h1>
                : <h1 style={{ fontSize: 18 }}>SAII Resources</h1>
            }
          </div>
          <div className='header_add'>
            {/* <AddCircle style={{color:'#3256a8', fontSize:30}} onClick={this.handleOpen}/> */}
            {/* <i className="fa fa-plus-circle" aria-hidden="true" style={{fontSize:30, color:'#3256a8', cursor:'pointer'}} onClick={this.addButtonOpen}></i> */}
            <button onClick={this.addButtonOpen} className='header_button header_button_text' name='submit'>New</button>
          </div>
          <div className='header-icon' style={{ float: 'right', position: 'absolute', top: 15, right: 10, cursor: 'pointer' }}>
            {/* <AccountCircle data-toggle="tooltip" title="Profile" data-html="true"  style={{color:'black', fontSize:30}} onClick={this.handleOpen}/> */}
            <i className="fa fa-user-circle login_short_icon" aria-hidden="true" style={{ color: 'black', fontSize: 26 }} onClick={this.handleOpen}></i>
          </div>
          <div className='header-name' style={{ position: 'absolute', top: 15, right: 10, cursor: 'pointer' }} onClick={this.handleOpen}>
            <i className="fa fa-user-circle login_short_icon" aria-hidden="true"></i>
            <span className="login_short_mes">Hi, </span>
            <span className="login_name">{this.state.userFullName}</span>
          </div>
        </header>
        <div style={{ display: 'none' }}>
          <SideBar handleToggle={this.state.openDrawer} onSidebarStatusChange={this.onChange} />
        </div>
        <Popover
          open={this.state.menuOpen}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          targetOrigin={{ horizontal: 'left', vertical: 'top' }}
          onClose={this.handleClose}
        >
          <div className='card border'>
            <MenuItem onClick={() => { window.location.href = '/change-pswd' }}><i className="fa fa-key" style={{ color: 'black', fontSize: 20 }}></i>&emsp;Change Password</MenuItem>
            <MenuItem onClick={() => { window.location.href = '/logout' }}> <i className="fa fa-sign-out" style={{ color: 'black', fontSize: 20 }}></i>&emsp;Logout</MenuItem>
          </div>
        </Popover>
        <Popover
          open={this.state.addButtonOpen}
          anchorEl={this.state.anchorEn}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          targetOrigin={{ horizontal: 'left', vertical: 'top' }}
          onClose={this.addButtonClose}
        >
          <div className='card border'>
            <MenuItem className="drawer_links" style={{ fontSize: '17px', color: (Config.company === "SRPL" || Config.company === "PRPL" || Config.company === "AVS") ? '#918176' : "rgb(169, 126, 103)" }} onClick={() => { window.location.href = '/add-vendor' }}><i className="fa fa-user mr-3" aria-hidden="true" style={{ fontSize: 28, color: "#8f7b70", opacity: '0.5', margin: '0px 3px', width: '20px' }}></i>Add Vendor</MenuItem>
            <MenuItem className="drawer_links" style={{ fontSize: '17px', color: (Config.company === "SRPL" || Config.company === "PRPL" || Config.company === "AVS") ? '#918176' : "rgb(169, 126, 103)" }} onClick={() => { window.location.href = '/new-vendor-advance-contract' }}><i className="fa fa-usd mr-3" style={{ fontSize: 26, color: "#8f7b70", opacity: '0.5', margin: '0px 6px', width: '16px' }}></i>New Vendor Advance Contract</MenuItem>
            <MenuItem className="drawer_links" style={{ fontSize: '17px', color: (Config.company === "SRPL" || Config.company === "PRPL" || Config.company === "AVS") ? '#918176' : "rgb(169, 126, 103)" }} onClick={() => { window.location.href = '/add-customer' }}><i className="fa fa-user-o mr-3" aria-hidden="true" style={{ fontSize: 26, color: "#8f7b70", opacity: '0.5', margin: '0px 3px', width: '20px' }}></i>Add Customer</MenuItem>
            <MenuItem className="drawer_links" style={{ fontSize: '17px', color: (Config.company === "SRPL" || Config.company === "PRPL" || Config.company === "AVS") ? '#918176' : "rgb(169, 126, 103)" }} onClick={() => { window.location.href = '/add-purchase' }}><i className="fa fa-file-text mr-3" aria-hidden="true" style={{ fontSize: 24, color: '#8f7b70', opacity: '0.5', margin: '0px 4px', width: '20px' }}></i>New Coal Purchase Contract</MenuItem>
            <MenuItem className="drawer_links" style={{ fontSize: '17px', color: (Config.company === "SRPL" || Config.company === "PRPL" || Config.company === "AVS") ? '#918176' : "rgb(169, 126, 103)" }} onClick={() => { window.location.href = '/add-sales-contract' }}><i className="fa fa-file-text-o mr-3" aria-hidden="true" style={{ fontSize: 24, color: '#8f7b70', opacity: '0.5', margin: '0px 4px', width: '20px' }}></i>New Coal Sales Contract</MenuItem>
            <MenuItem className="drawer_links" style={{ fontSize: '17px', color: (Config.company === "SRPL" || Config.company === "PRPL" || Config.company === "AVS") ? '#918176' : "rgb(169, 126, 103)" }} onClick={() => { window.location.href = '/add-barge' }}><i className="fa fa-ship mr-3" aria-hidden="true" style={{ fontSize: 24, color: '#8f7b70', opacity: '0.5', margin: '0px 4px', width: '20px' }}></i>New Barge</MenuItem>
            <MenuItem className="drawer_links" style={{ fontSize: '17px', color: (Config.company === "SRPL" || Config.company === "PRPL" || Config.company === "AVS") ? '#918176' : "rgb(169, 126, 103)" }} onClick={() => { window.location.href = '/new-business' }}><i className="fa fa-building-o mr-3" aria-hidden="true" style={{ fontSize: 24, color: '#8f7b70', opacity: '0.5', margin: '0px 4px', width: '20px' }}></i>New Business Number</MenuItem>
            <MenuItem className="drawer_links" style={{ fontSize: '17px', color: (Config.company === "SRPL" || Config.company === "PRPL" || Config.company === "AVS") ? '#918176' : "rgb(169, 126, 103)" }} onClick={() => { window.location.href = '/add-mine' }}><FontAwesomeIcon style={{ color: '#8f7b70', fontSize: 22 }} icon={faDolly} className='listIcons mr-3' />Add Mine</MenuItem>
          </div>
        </Popover>

        <Dialog
          open={this.state.dialogOpen}
          TransitionComponent={Transition}
          keepMounted
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle style={{ backgroundColor: '#f1f1f1' }} id="alert-dialog-slide-title">
            <WarningIcon style={{ color: '#e0a51e', marginBottom: '5px' }} /> Session Expiry Alert
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Your session will expire in {this.state.timeInMinutes} {this.state.timeType}. Would you like to extend?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" size="small" onClick={this.handleClickOk} color="primary">
              Extend
            </Button>
            <Button variant="contained" size="small" onClick={this.handleClickCancel} color="secondary">
              Logout
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.loginDialogOpen}
          TransitionComponent={Transition}
          keepMounted
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle style={{ backgroundColor: '#f1f1f1', width: '450px' }} id="alert-dialog-slide-title">
            Login to continue
          </DialogTitle>
          <DialogContent>
            {/* <DialogContentText style={{color: 'red'}} id="alert-dialog-slide-description">
              {this.state.errorMessage}
            </DialogContentText> */}
            <form onSubmit={this.handleLogin}>
              <div className='col'>
                <p className='text-center mb-0' style={{ color: 'red' }}> {this.state.errorMessage}</p>
                <div className='row'>
                  <TextField
                    name='userName'
                    margin='dense'
                    variant='outlined'
                    placeholder="User Name"
                    disabled
                    fullWidth
                    type='text'
                    value={this.state.userName}
                    required
                    InputProps={{
                      style: {
                        fontFamily: 'Poppins'
                      }
                    }}
                  />
                </div>
                <div className='row'>
                  <TextField
                    name='password'
                    margin='dense'
                    variant='outlined'
                    placeholder='Password'
                    autoFocus={true}
                    fullWidth
                    type='password'
                    value={this.state.password}
                    InputProps={{
                      style: {
                        fontFamily: 'Poppins'
                      }
                    }}
                    required
                    onChange={this.handleText}
                  />
                </div>
              </div>
              <br />
              <div className='row'>
                <div className='col text-center'>
                  <button type='submit' className='signin_button signin_text' style={{ height: 40 }}>Login</button>
                </div>
              </div>
            </form>
            {/* <div className='row' style={{marginTop:10}}>
              <TextField
                name='userName'
                margin='dense'
                variant='outlined'
                placeholder="User Name"
                disabled
                fullWidth
                type='text'
                value={this.state.userName}
                required
              />
            </div>
            <div className='row'>
              <TextField
                name='password'
                margin='dense'
                variant='outlined'
                placeholder='Password'
                fullWidth
                type='password'
                value={this.state.password}
                required
                onChange={this.handleText}
              />
            </div> */}
          </DialogContent>
          {/* <DialogActions>
            <Button variant="contained" size="small" onClick={this.handleLogin} color="primary">
              Login
            </Button>
          </DialogActions> */}
        </Dialog>
      </span>
    )
  }
}