import React, { Component } from "react";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Logout from "@material-ui/icons/PowerSettingsNew";
import Drawer from "@material-ui/core/Drawer";

import CookieHandler from "./CookieHandler";
import NewSidebar from "./NewSidebar";
import Config from "../../config/config";
import Logo from "../../images/SDAM_Logo.png";
import NavIcon3 from "../../images/nav_icon/Angle-double-left@3x.png";
import VendorIcon from "../../images/icons/sidebar_vendor_icon.svg";
import DashboardIcon from "../../images/icons/dashboard_icon.svg";
import SalesIcon from "../../images/icons/sales_icon.svg";
import PurchaseIcon from "../../images/icons/purchase_icon.svg";
import BusinessIcon from "../../images/icons/business_icon.svg";
// import { config } from '@fortawesome/fontawesome-svg-core';

export default class SideBar extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      DrawerClosed: "",
      mobileOpen: false,
      open: false,
      loginUserId: "",
      customerDetails: false,
      vendorDetails: false,
      userDetails: false,
      purchaseDetails: false,
      salesPurchaseDetails: false,
      businessDetails: false,
      minesDetails: false,
      userRole: "",

      features: [],
    };
  }

  componentDidMount() {
    var loginUserId = this.Cookie.getIdTokenCookie();
    var features = this.Cookie.getCookie("features");
    var path = window.location.pathname;
    var p = path.substr(1, path.length);
    this.setState({
      features: features,
    });
    if (p === "add-customer" || p === "customer-list") {
      this.setState({
        customerDetails: true,
      });
    } else if (
      p === "add-vendor" ||
      p === "vendor-list" ||
      p === "new-vendor-advance-contract" ||
      p === "advance-payments"
    ) {
      this.setState({
        vendorDetails: true,
      });
    } else if (p === "add-user" || p === "user-list") {
      this.setState({
        userDetails: true,
      });
    } else if (p === "add-purchase" || p === "purchase-contract-list") {
      this.setState({
        purchaseDetails: true,
      });
    } else if (p === "add-sales-contract" || p === "sales-contract-list") {
      this.setState({
        salesPurchaseDetails: true,
      });
    } else if (p === "new-business" || p === "business-number-list") {
      this.setState({
        businessDetails: true,
      });
    } else if (p === "add-mine" || p === "mines-list") {
      this.setState({
        minesDetails: true,
      });
    }
    this.setState({
      loginUserId: loginUserId,
    });
  }

  handleDrawerToggle = () => {
    this.setState({
      DrawerClosed: "DrawerClosed",
      mobileOpen: false,
      drawerOpen: false,
    });
    this.props.onSidebarStatusChange(false);
  };

  static getDerivedStateFromProps(props, state) {
    if (props.handleToggle !== state.mobileOpen) {
      return { mobileOpen: props.handleToggle };
    } else if (state.DrawerClosed) {
      return { mobileOpen: false };
    }
    return null;
  }

  render() {
    var path = window.location.pathname;
    var pa = path.substr(1, path.length);
    const sidebar = (
      <div>
        <a href="/dashboard">
          <List component="div" disablePadding style={{ margin: "16px 0px" }}>
            <ListItem
              button
              className="drawer_links"
              style={{
                backgroundColor:
                  pa === "dashboard" ? Config.themeColor : "#80808000",
              }}
            >
              <ListItemIcon style={{ minWidth: "32px" }}>
                <img src={DashboardIcon} alt="back" className="sidebar_icons" />
              </ListItemIcon>
              <ListItemText
                style={{
                  color:
                    pa === "dashboard"
                      ? "rgb(244, 244, 244)"
                      : Config.company === "SRPL" ||
                        Config.company === "AVS" ||
                        Config.company === "PRPL"
                      ? "#918176"
                      : "rgba(238, 238, 238, 0.7)",
                }}
              >
                <span
                  className={
                    Config.company === "SRPL" ||
                    Config.company === "AVS" ||
                    Config.company === "PRPL"
                      ? "list_text"
                      : "list-text"
                  }
                  style={{ fontSize: "17px" }}
                >
                  Dashboard
                </span>
              </ListItemText>
            </ListItem>
          </List>
        </a>
        <a href="/business-partner">
          <List component="div" disablePadding style={{ marginBottom: 16 }}>
            <ListItem
              button
              className="drawer_links"
              style={{
                backgroundColor:
                  pa === "business-partner" ? Config.themeColor : "#80808000",
              }}
            >
              <ListItemIcon style={{ minWidth: "32px" }}>
                <img
                  src={VendorIcon}
                  alt="back"
                  className={
                    Config.company === "SRPL" ||
                    Config.company === "AVS" ||
                    Config.company === "PRPL"
                      ? "sidebar_icons saii_icon"
                      : "sidebar_icons"
                  }
                />
              </ListItemIcon>
              <ListItemText
                style={{
                  color:
                    pa === "business-partner"
                      ? "rgb(244, 244, 244)"
                      : Config.company === "SRPL" ||
                        Config.company === "AVS" ||
                        Config.company === "PRPL"
                      ? "#918176"
                      : "rgba(238, 238, 238, 0.7)",
                }}
              >
                <span
                  className={
                    Config.company === "SRPL" ||
                    Config.company === "AVS" ||
                    Config.company === "PRPL"
                      ? "list_text"
                      : "list-text"
                  }
                  style={{ fontSize: "17px" }}
                >
                  Business Partner
                </span>
              </ListItemText>
            </ListItem>
          </List>
        </a>
        <div className="sidebar_sub_heading">Contracts</div>
        <a href="/sales-contract-list">
          <List component="div" disablePadding>
            <ListItem
              button
              className="drawer_links"
              style={{
                backgroundColor:
                  pa === "sales-contract-list"
                    ? Config.themeColor
                    : "#80808000",
              }}
            >
              <ListItemIcon style={{ minWidth: "32px" }}>
                <img src={SalesIcon} alt="back" className="sidebar_icons" />
              </ListItemIcon>
              <ListItemText
                style={{
                  color:
                    pa === "sales-contract-list"
                      ? "rgb(244, 244, 244)"
                      : Config.company === "SRPL" ||
                        Config.company === "AVS" ||
                        Config.company === "PRPL"
                      ? "#918176"
                      : "rgba(238, 238, 238, 0.7)",
                }}
              >
                <span
                  className={
                    Config.company === "SRPL" ||
                    Config.company === "AVS" ||
                    Config.company === "PRPL"
                      ? "list_text"
                      : "list-text"
                  }
                  style={{ fontSize: "17px" }}
                >
                  Sales
                </span>
              </ListItemText>
            </ListItem>
          </List>
        </a>
        <a href="/purchase-contract-list">
          <List component="div" disablePadding>
            <ListItem
              button
              className="drawer_links"
              style={{
                backgroundColor:
                  pa === "purchase-contract-list"
                    ? Config.themeColor
                    : "#80808000",
              }}
            >
              <ListItemIcon style={{ minWidth: "32px" }}>
                <img src={PurchaseIcon} alt="back" className="sidebar_icons" />
              </ListItemIcon>
              <ListItemText
                style={{
                  color:
                    pa === "purchase-contract-list"
                      ? "rgb(244, 244, 244)"
                      : Config.company === "SRPL" ||
                        Config.company === "AVS" ||
                        Config.company === "PRPL"
                      ? "#918176"
                      : "rgba(238, 238, 238, 0.7)",
                }}
              >
                <span
                  className={
                    Config.company === "SRPL" ||
                    Config.company === "AVS" ||
                    Config.company === "PRPL"
                      ? "list_text"
                      : "list-text"
                  }
                  style={{ fontSize: "17px" }}
                >
                  Purchase
                </span>
              </ListItemText>
            </ListItem>
          </List>
        </a>
        <a href="/advance-payments">
          <List component="div" disablePadding style={{ marginBottom: 16 }}>
            <ListItem
              button
              className="drawer_links"
              style={{
                backgroundColor:
                  pa === "advance-payments" ? Config.themeColor : "#80808000",
              }}
            >
              <ListItemIcon style={{ minWidth: "32px" }}>
                <i
                  className="fa fa-user sidebar_icons"
                  aria-hidden="true"
                  style={{
                    fontSize: 24,
                    color: "rgb(143, 123, 112)",
                    paddingLeft: 5,
                  }}
                ></i>
                {/* <img src={PurchaseIcon} alt="back" className="sidebar_icons" /> */}
              </ListItemIcon>
              <ListItemText
                style={{
                  color:
                    pa === "advance-payments"
                      ? "rgb(244, 244, 244)"
                      : Config.company === "SRPL" ||
                        Config.company === "AVS" ||
                        Config.company === "PRPL"
                      ? "#918176"
                      : "rgba(238, 238, 238, 0.7)",
                }}
              >
                <span
                  className={
                    Config.company === "SRPL" ||
                    Config.company === "AVS" ||
                    Config.company === "PRPL"
                      ? "list_text"
                      : "list-text"
                  }
                  style={{ fontSize: "17px" }}
                >
                  Advance
                </span>
              </ListItemText>
            </ListItem>
          </List>
        </a>
        <div className="sidebar_sub_heading">Others</div>
        <a href="/business-number-list">
          <List component="div" disablePadding>
            <ListItem
              button
              className="drawer_links"
              style={{
                backgroundColor:
                  pa === "business-number-list"
                    ? Config.themeColor
                    : "#80808000",
              }}
            >
              <ListItemIcon style={{ minWidth: "32px" }}>
                <img src={BusinessIcon} alt="back" className="sidebar_icons" />
              </ListItemIcon>
              <ListItemText
                style={{
                  color:
                    pa === "business-number-list"
                      ? "rgb(244, 244, 244)"
                      : Config.company === "SRPL" ||
                        Config.company === "AVS" ||
                        Config.company === "PRPL"
                      ? "#918176"
                      : "rgba(238, 238, 238, 0.7)",
                }}
              >
                <span
                  className={
                    Config.company === "SRPL" ||
                    Config.company === "AVS" ||
                    Config.company === "PRPL"
                      ? "list_text"
                      : "list-text"
                  }
                  style={{ fontSize: "17px" }}
                >
                  Business Numbers
                </span>
              </ListItemText>
            </ListItem>
          </List>
        </a>
        <a href="/user-access" style={{ textDecoration: "none" }}>
          <ListItem
            button
            className="drawer_links"
            style={{
              backgroundColor:
                pa === "user-access" ? Config.themeColor : "#80808000",
            }}
          >
            <ListItemIcon style={{ minWidth: "32px" }}>
              <i
                className="fa fa-universal-access sidebar_icons"
                aria-hidden="true"
                style={{
                  fontSize: 24,
                  color: "rgb(143, 123, 112)",
                  paddingLeft: 5,
                }}
              ></i>
            </ListItemIcon>
            <ListItemText
              style={{
                color:
                  pa === "user-access"
                    ? "rgb(244, 244, 244)"
                    : Config.company === "SRPL" ||
                      Config.company === "AVS" ||
                      Config.company === "PRPL"
                    ? "#918176"
                    : "rgba(238, 238, 238, 0.7)",
              }}
            >
              <span
                className={
                  Config.company === "SRPL" ||
                  Config.company === "AVS" ||
                  Config.company === "PRPL"
                    ? "list_text"
                    : "list-text"
                }
                style={{ fontSize: "17px" }}
              >
                Access Management
              </span>
            </ListItemText>
          </ListItem>
        </a>
      </div>
    );
    return (
      <div id="root" style={{ fontFamily: "Poppins" }}>
        {this.state.mobileOpen ? (
          <Drawer
            variant="temporary"
            open={this.state.mobileOpen}
            onClose={this.handleDrawerToggle}
          >
            <nav
              className={
                Config.company === "SRPL" ||
                Config.company === "AVS" ||
                Config.company === "PRPL"
                  ? "sidebar_drawer_saii"
                  : "sidebar_drawer_sdam"
              }
            >
              <div
                className={
                  Config.company === "SRPL" ||
                  Config.company === "AVS" ||
                  Config.company === "PRPL"
                    ? "sidebar-header-saii"
                    : "sidebar-header-sdam"
                }
                style={{ padding: 10 }}
              >
                {/* <img src={logo} width="200px" alt=""/> */}
                {/* <a href='/dashboard'><h1>{Config.company === 'SDAM' ? "PT SDAM" : "SAII Resources"}</h1></a> */}
                {Config.company === "SDAM" ? (
                  <a href="/dashboard">
                    <span className="sidebar_logo_container">
                      <img
                        src={Logo}
                        alt="SDAM LOGO"
                        className="sidebar_header_logo"
                      />
                    </span>
                  </a>
                ) : (
                  <a className="sidebar_logo_container" href="/dashboard">
                    <h1 style={{ fontSize: 18 }}>
                      {Config.company === "SRPL" || Config.company === "AVS"
                        ? "SAII RESOURCES"
                        : Config.company === "PRPL"
                        ? "PRAKRUTHI RESOURCES"
                        : Config.company === "BTR"
                        ? "BUMI TAMBANG RESOURCES"
                        : "SUMBER REZEKI KALTIM"}
                    </h1>
                  </a>
                )}
                <img
                  src={NavIcon3}
                  alt="back"
                  className="Stockholm-icons--Navigation--Angle-double-left"
                />
              </div>
              <hr style={{ marginTop: 5 }} />
              <ul>
                {this.state.loginUserId && <nav>{sidebar}</nav>}
                {!this.state.loginUserId && (
                  <ListItem
                    button
                    onClick={() => {
                      window.location.href = "/logout";
                    }}
                    style={{ paddingLeft: "14px" }}
                  >
                    <ListItemIcon style={{ minWidth: "32px" }}>
                      <Logout className="listIcons" />
                    </ListItemIcon>
                    <ListItemText>
                      <span
                        className={
                          Config.company === "SRPL" ||
                          Config.company === "AVS" ||
                          Config.company === "PRPL"
                            ? "list_text"
                            : "list-text"
                        }
                        style={{ fontSize: "17px" }}
                      >
                        Login
                      </span>
                    </ListItemText>
                  </ListItem>
                )}
              </ul>
            </nav>
          </Drawer>
        ) : (
          <NewSidebar />
        )}
      </div>
    );
  }
}
