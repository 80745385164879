import React, { Component } from "react";

import { Snackbar, Drawer } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import CookieHandler from "../../../common/CookieHandler";
import config from "../../../../config/config";
import api from "../../../../api/api";

// import mother vessel Coasl Procurement Charges
import OutgoingDebitNoteAdd from "./Outgoing-Debit-Note-Add";
import OutgoingDebitNoteView from "./Outgoing-Debit-Note-View";
import OutgoingDebitNoteUpdate from "./Outgoing-Debit-Note-Update";
import { localDateFormate, toLocaleString } from "../../../common/common";
import Loader from "../../../common/Loader";

export default class Outgoing_Debit_Note_List extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      debitNotesData: [],
      isLoading: true,
      stevedor_price_payments: [],
      features: [],
      closeDialog: false,
      deletePaymentDialog: false,
      updateDocuments: false,
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (
      !(
        features.includes("82") ||
        features.includes("83") ||
        features.includes("84") ||
        features.includes("97")
      )
    ) {
      window.location.href = "/dashboard";
    }
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const businessNoID = this.props.businessNoID;
    await api
      .get_business_no_debit_note(loginUserID, idToken, businessNoID)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.business_no_debit_note) {
                const filter_incoming_debit_notes =
                  res.business_no_debit_note.filter(
                    (e, idx) => e.debit_type === "Outgoing"
                  );
                for (var i in filter_incoming_debit_notes) {
                  var total_paid_amout = 0;
                  for (var j of filter_incoming_debit_notes[i]
                    .Incoming_payments) {
                    total_paid_amout += Number(j.amount);
                  }
                  filter_incoming_debit_notes[i].total_paid_amout =
                    total_paid_amout;
                  filter_incoming_debit_notes[i].remaining_amount =
                    Number(filter_incoming_debit_notes[i].amount) -
                    Number(total_paid_amout);
                }
                this.setState({
                  debitNotesData: filter_incoming_debit_notes,
                  isLoading: false,
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  }

  onAddResponse = (value) => {
    if (value.code === "200") {
      this.setState(
        {
          OutgoingDebitNoteAdd: false,
          OutgoingDebitNoteView: false,
          OutgoingDebitNoteUpdate: false,
          openDrawer: false,
          updateDocuments: false,
        },
        () => this.componentDidMount()
      );
    } else {
      this.setState({
        OutgoingDebitNoteAdd: false,
        OutgoingDebitNoteView:
          value.screen === "DebitNote" &&
          value.message === "alreadyDataAvailable"
            ? true
            : false,
        OutgoingDebitNoteUpdate: false,
        openDrawer: true,
        updateDocuments: false,
      });
    }
  };

  CallbackDrawerClose = (childData) => {
    this.setState(
      {
        openDrawer: childData,
        OutgoingDebitNoteAdd: false,
        OutgoingDebitNoteView: false,
        OutgoingDebitNoteUpdate: false,
        updateDocuments: false,
      },
      () => this.componentDidMount()
    );
  };

  onUpdateResponse = (value) => {
    if (value) {
      this.setState(
        {
          OutgoingDebitNoteAdd: false,
          OutgoingDebitNoteView:
            value.code === "200" && value.screen === "DebitNote" ? true : false,
          OutgoingDebitNoteUpdate: false,
          updateDocuments: false,
        },
        () => this.componentDidMount()
      );
    }
  };

  CallbackViewPage = (childData) => {
    this.setState(
      {
        openDrawer: true,
        OutgoingDebitNoteUpdate: false,
        OutgoingDebitNoteAdd: false,
        OutgoingDebitNoteView: childData === "DebitNote" ? true : false,
        updateDocuments: false,
      },
      () => this.componentDidMount()
    );
  };

  editCallback = (childData) => {
    this.setState(
      {
        openDrawer: true,
        OutgoingDebitNoteUpdate: childData === "DebitNote" ? true : false,
        OutgoingDebitNoteAdd: false,
        OutgoingDebitNoteView: false,
        updateDocuments: false,
      },
      () => this.componentDidMount()
    );
  };

  deleteCharges = (data) => {
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    var finalData = data;
    finalData.debit_note_id = data.id;
    finalData.login_user_id = loginUserID;
    finalData.idtoken = idToken;
    finalData.status = "Deleted";
    //Delete Stevedore costing charge
    api.update_buss_no_debit_note(finalData).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.setState(
              {
                snackBarSuccessOpen: true,
                successMsg: res.message,
              },
              () => this.componentDidMount()
            );
          } else if (res.code === "601") {
            this.setState({ errorMsg: res.message, snackBarErrorOpen: true });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          } else if (res.code === "624") {
            this.setState({
              errorMsg: res.columns.length > 0 ? res.columns[0] : "",
              snackBarErrorOpen: true,
            });
          }
        });
      }
    });
  };

  render() {
    let drawerComponent;
    if (this.state.OutgoingDebitNoteAdd) {
      drawerComponent = (
        <OutgoingDebitNoteAdd
          businessNoID={this.props.businessNoID}
          onAddResponse={this.onAddResponse}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
        />
      );
    } else if (this.state.OutgoingDebitNoteView && this.state.businessNo) {
      drawerComponent = (
        <OutgoingDebitNoteView
          debitNoteID={this.state.debitNoteID}
          businessNoID={this.state.businessNo}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.editCallback}
          returnDocumentsHandler={() => {
            this.setState({
              showDrawer: true,
              OutgoingDebitNoteUpdate: true,
              OutgoingDebitNoteView: false,
              OutgoingDebitNoteAdd: false,
              updateDocuments: true,
            });
          }}
        />
      );
    } else if (this.state.OutgoingDebitNoteUpdate && this.state.businessNo) {
      drawerComponent = (
        <OutgoingDebitNoteUpdate
          debitNoteID={this.state.debitNoteID}
          businessNoID={this.state.businessNo}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.CallbackViewPage}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div>
          <div className="row clearfix content-header border-bottom">
            <div className="row clearfix content-header border-bottom">
              <h4 className="mt-2" style={{ padding: "4px" }}>
                {` Outgoing Debit Note - ${
                  this.props.mother_vessel_name
                    ? this.props.mother_vessel_name
                    : ""
                } ${this.props.name ? `( ${this.props.name} )` : ""}`}
              </h4>
              {(this.state.features.includes("294") ||
                this.state.features.includes("97")) && (
                <i
                  className="fa fa-plus-circle ml-2 mt-3"
                  aria-hidden="true"
                  style={{
                    fontSize: 22,
                    color: config.themeColor,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    this.setState({
                      openDrawer: true,
                      OutgoingDebitNoteAdd: true,
                      OutgoingDebitNoteView: false,
                      OutgoingDebitNoteUpdate: false,
                    });
                  }}
                />
              )}
            </div>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <Drawer
            anchor="right"
            open={this.state.openDrawer}
            variant="temporary"
            elevation={20}
            style={{ overflow: "initial" }}
          >
            <div className="row" style={{ width: 800 }}>
              {drawerComponent}
            </div>
          </Drawer>
          <div className="card pl-0 pr-0">
            <div className="col-lg-12 mb-2 p-0">
              <div className="row">
                <div className="table-responsive">
                  <table className="table table-bordered table-sm">
                    <thead className="table-header">
                      <tr>
                        <th nowrap="true"> Costing ID</th>
                        <th nowrap="true"> Payee Name</th>
                        <th nowrap="true">To</th>
                        <th nowrap="true"> Date</th>
                        <th nowrap="true" className="text-right">
                          {" "}
                          Amount{" "}
                        </th>
                        <th nowrap="true" className="pl-4">
                          Action{" "}
                        </th>
                      </tr>
                    </thead>
                    {this.state.debitNotesData.length > 0 ? (
                      <tbody>
                        {this.state.debitNotesData.map((idr, indx) => (
                          <tr key={indx}>
                            <td className="allocation_table_value">
                              {(this.state.features.includes("295") ||
                                this.state.features.includes("97")) && (
                                <i
                                  aria-hidden="true"
                                  style={{
                                    fontStyle: "normal",
                                    color: config.themeColor,
                                    cursor: "pointer",
                                    whiteSpace: "nowrap",
                                  }}
                                  onClick={() =>
                                    this.setState({
                                      openDrawer: true,
                                      OutgoingDebitNoteAdd: false,
                                      OutgoingDebitNoteView: true,
                                      OutgoingDebitNoteUpdate: false,
                                      businessNo: idr.business_no_id,
                                      debitNoteID: idr.id,
                                    })
                                  }
                                >
                                  {idr.costing_no}
                                </i>
                              )}
                            </td>

                            <td
                              className="allocation_table_value"
                              nowrap="true"
                            >
                              {idr.vendor_or_customer === "Vendor"
                                ? idr.vendor_name
                                : idr.customer_id === null &&
                                  idr.other_customer_name !== null
                                ? "Others"
                                : idr.customer_name}
                            </td>
                            <td
                              className="allocation_table_value"
                              nowrap="true"
                            >
                              {idr.vendor_or_customer}
                            </td>
                            <td
                              className="allocation_table_value"
                              nowrap="true"
                            >
                              {localDateFormate(idr.date_of_creation)}
                            </td>
                            <td
                              className="allocation_table_value text-right"
                              nowrap="true"
                            >
                              {toLocaleString(idr.amount)}
                              {config.company === "SRPL" ||
                              config.company === "AVS" ||
                              config.company === "PRPL"
                                ? "USD"
                                : "IDR"}
                            </td>
                            <td
                              nowrap="true"
                              className="table_td text-left pl-4"
                            >
                              {(this.state.features.includes("236") ||
                                this.state.features.includes("97")) && (
                                <i
                                  className="fa fa-pencil"
                                  style={{
                                    fontSize: 14,
                                    color: config.themeColor,
                                    cursor: "pointer",
                                  }}
                                  data-toggle="modal"
                                  title="Edit floating crane charges"
                                  data-placement="bottom"
                                  onClick={() =>
                                    this.setState({
                                      businessNo: idr.business_no_id,
                                      debitNoteID: idr.id,
                                      openDrawer: true,
                                      OutgoingDebitNoteUpdate: true,
                                    })
                                  }
                                ></i>
                              )}
                              {idr.payment_postings_availability === 0 &&
                                (this.state.features.includes("497") ||
                                  this.state.features.includes("97")) && (
                                  <i
                                    className="fa fa-trash-o"
                                    style={{
                                      paddingLeft: 10,
                                      fontSize: 14,
                                      color: config.themeColor,
                                      cursor: "pointer",
                                    }}
                                    onClick={() => this.deleteCharges(idr)}
                                  ></i>
                                )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td colSpan="5" className="p-2">
                            No data available
                          </td>
                        </tr>{" "}
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
