import React, { Component } from "react";
import api from "../../../api/api";
import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";

import {
  TextField,
  InputAdornment,
  MenuItem,
  IconButton,
  Snackbar,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import EventIcon from "@material-ui/icons/Event";
import { Alert } from "@material-ui/lab";
import { dateFormateToDB } from "../../common/common";
import FileUpload from "../../common/FileUpload";
import Loader from "../../common/Loader";
export default class Other_Expenses_Update extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      credit_type: "Incoming",

      multiRows: [
        {
          billing_currency:
            config.company === "SRPL" ||
            config.company === "AVS" ||
            config.company === "PRPL"
              ? "USD"
              : "IDR",
          exchange_rate: "1",
          vendor_id: "-1",
          payee_name: "",
          expense_date: null,
          amount: "",
          remarks: "",
        },
      ],

      customers: [],
      vendors: [],
      currencyData: [],
      isLoading: true,

      features: [],
      files: [
        {
          file_description: "",
          file_name: "",
          file_extension: "",
          file_content: "",
          feature_name: "Business_no_other_expenses",
        },
      ],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    if (!(features.indexOf("296") >= 0 || features.indexOf("97") >= 0)) {
      window.location.href = "/dashboard";
    }
    this.setState({ features: features });
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const businessNoID = this.props.businessNoID;
    const status = "Active";
    const vendors = [
      <MenuItem
        style={{ fontFamily: "Poppins", fontSize: "14px" }}
        value="-1"
        key={-1}
        disabled
      >
        {" "}
        Please Select
      </MenuItem>,
    ];
    await api.getVendors(loginUserID, idToken, status).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            if (res.vendors) {
              for (var i of res.vendors) {
                vendors.push(
                  <MenuItem
                    style={{ fontFamily: "Poppins", fontSize: "14px" }}
                    value={i.id}
                    key={[i.id]}
                  >
                    {i.vendor_name}
                  </MenuItem>
                );
              }
              // vendors.push(
              //   <MenuItem
              //     style={{ fontFamily: "Poppins", fontSize: "14px" }}
              //     value={"0"}
              //     key={0}
              //   >
              //     Others
              //   </MenuItem>
              // );
              this.setState({
                vendors: vendors,
              });
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          } else if (res.code === "601") {
            this.setState({
              errorMsg: res.message,
              snackBarErrorOpen: true,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });

    // Get Currency data
    await api.getCurrencies(loginUserID, idToken).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
            if (res.Currency) {
              var currencies = [
                <MenuItem value="0" key={-1}>
                  {" "}
                  Please Select
                </MenuItem>,
              ];
              for (var i in res.Currency) {
                currencies.push(
                  <MenuItem value={res.Currency[i].currency} key={[i]}>
                    {res.Currency[i].currency}
                  </MenuItem>
                );
              }
              this.setState({
                currencyData: currencies,
              });
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });

    //Get businessNumber API based on businessNumber
    await api
      .getBusinessNumber(businessNoID, loginUserID, idToken)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.business_number) {
                this.setState({
                  businessNo: res.business_number.business_no
                    ? res.business_number.business_no
                    : "",
                  mother_vessel_name: res.business_number.mother_vessel_name
                    ? res.business_number.mother_vessel_name
                    : "",
                  //isLoading: false
                });
              }
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                snackBarErrorOpen: true,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
    const OtherExpenseID = this.props.OtherExpenseID;
    await api
      .get_buss_no_other_expenses(loginUserID, idToken, OtherExpenseID)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.business_no_other_expenses) {
                this.setState({
                  multiRows: res.business_no_other_expenses,
                  files:
                    res.business_no_other_expenses.length > 0
                      ? res.business_no_other_expenses[0].files
                      : [
                          {
                            file_description: "",
                            file_name: "",
                            file_extension: "",
                            file_content: "",
                            feature_name: "Business_no_other_expenses",
                          },
                        ],
                  isLoading: false,
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                snackBarErrorOpen: true,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
    document.title = config.documentTitle + "Update Other Expenses";
  }

  addMoreRowsHandler = () => {
    const item = {
      billing_currency:
        config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL"
          ? "USD"
          : "IDR",
      exchange_rate: "1",
      vendor_id: "-1",
      payee_name: "",
      expense_date: null,
      amount: "",
      remarks: "",
    };
    this.setState({
      multiRows: [...this.state.multiRows, item],
    });
  };

  removeMultiRowsHandler = (idx) => () => {
    const rows = this.state.multiRows;
    rows.splice(idx, 1);
    this.setState({
      multiRows: rows,
    });
  };

  submitHandler = () => {
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    var multiRows = this.state.multiRows;
    // Checking mandatory fields.
    var all_fields_filled = true;

    for (var i = 0; i < multiRows.length; i++) {
      if (multiRows[i].vendor_id === "-1") {
        multiRows[i].vendorError = true;
        all_fields_filled = false;
      } else if (
        multiRows[i].vendor_id === "0" ||
        multiRows[i].vendor_id === null
      ) {
        if (
          multiRows[i].payee_name === "" ||
          multiRows[i].payee_name === null
        ) {
          multiRows[i].payee_name_error = true;
          all_fields_filled = false;
        } else {
          multiRows[i].vendor_id = null;
        }
      }
      if (multiRows[i].expense_date === null) {
        multiRows[i].expense_date_error = true;
        all_fields_filled = false;
      }
      if (multiRows[i].amount === "") {
        multiRows[i].amount_error = true;
        all_fields_filled = false;
      }
    }
    this.setState({ multiRows: multiRows });
    /**
     *  Checking Mandatory files
     */
    var missing_fields = 0;
    let files_missing = 0;
    var files_data = this.state.files;
    for (var obj of files_data) {
      if (obj.file_type !== "" || obj.file_content !== "") {
        if (obj.file_content !== "") {
          if (obj.file_description === "") {
            missing_fields++;
            obj.file_description_error = true;
          } else {
            obj.file_description = obj.file_description.toUpperCase();
          }
        } else if (obj.file_description !== "") {
          if (obj.file_content === "") {
            files_missing++;
          }
        }
      }
    }
    this.setState({
      files: files_data,
    });

    if (missing_fields > 0) {
      this.setState({
        snackBarErrorOpen: true,
        submitLoading: false,
        errorMsg: "Please fill mandatory fields.",
      });
      return;
    }
    if (files_missing > 0) {
      this.setState({
        snackBarErrorOpen: true,
        submitLoading: false,
        errorMsg: "Please Select file.",
      });
      return;
    }
    var existing_file_ids = [];
    const existing_files = files_data.filter((e) => e.id);
    for (var j of existing_files) {
      existing_file_ids.push(j.id);
    }
    const new_files = files_data.filter((e) => !e.id);
    const content_files = new_files.filter((e) => e.file_content !== "");

    var finalData = multiRows[0];
    finalData.other_expense_id = this.props.OtherExpenseID;
    finalData.login_user_id = loginUserID;
    finalData.idtoken = idToken;
    finalData.existing_file_ids = existing_file_ids;
    finalData.files = content_files;

    if (all_fields_filled) {
      api.update_buss_no_other_expenses(finalData).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              setTimeout(() => {
                this.props.onUpdateCosting({
                  screen: "OtherExpense",
                  code: res.code,
                  message: "",
                });
              }, 2000);
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                snackBarErrorOpen: true,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
    } else {
      alert("Please fill mandatory fields.");
    }
  };

  render() {
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div
          className="container bg-white p-0"
          style={{ minHeight: "100vh", width: 850 }}
        >
          <div className="row clearfix content-header border-bottom">
            <div className="col-lg-12 pl-0">
              <h4 style={{ padding: "10px 20px" }}>
                Other Expenses{" "}
                {this.state.bargeNomination
                  ? "(" + this.state.bargeNomination + ")"
                  : null}{" "}
                - {this.state.businessNo ? this.state.businessNo : ""}
                {this.state.mother_vessel_name
                  ? " (" + this.state.mother_vessel_name + ")"
                  : ""}
              </h4>
            </div>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <div className="drawer-section" style={{ minHeight: "86vh" }}>
            <div className="card p-3">
              <div
                className="card-body p-0"
                style={{ backgroundColor: "rgba(25,118,210,.01)" }}
                id="accordion"
              >
                {!this.props.updateDocuments && (
                  <div>
                    {this.state.multiRows.map((e, idx) => (
                      <div className="row mb-3" key={idx}>
                        <div className="row col-lg border_dashed rounded-lg pb-2">
                          <div className="col-lg-6 mb-1 pl-0">
                            <label className="form_label mb-0">
                              To <span style={{ color: "red" }}>*</span>
                            </label>
                            <TextField
                              name="vendor"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              inputProps={{
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                  textTransform: "uppercase",
                                },
                              }}
                              error={e.vendorError}
                              placeholder="Search Vendor"
                              value={e.vendor_id === null ? "0" : e.vendor_id}
                              onChange={(e) => {
                                var row = this.state.multiRows;
                                row[idx].vendor_id = e.target.value;
                                row[idx].vendorError = false;
                                this.setState({
                                  multiRows: row,
                                });
                              }}
                              select
                            >
                              {this.state.vendors}
                            </TextField>
                          </div>

                          {(e.vendor_id === "0" || e.vendor_id === null) && (
                            <div className="col-lg-6 mb-1 pl-0">
                              <label className="form_label mb-0">
                                Payee Name{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <TextField
                                name="payee_name"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                inputProps={{
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                    textTransform: "uppercase",
                                  },
                                }}
                                error={e.payee_name_error}
                                placeholder="Payee Name"
                                value={e.payee_name}
                                onChange={(e) => {
                                  var row = this.state.multiRows;
                                  row[idx].payee_name = e.target.value;
                                  row[idx].payee_name_error = false;
                                  this.setState({
                                    multiRows: row,
                                  });
                                }}
                              />
                            </div>
                          )}

                          <div className="col-lg-6 mb-1 pl-0">
                            <label className="form_label mb-0">
                              Date <span style={{ color: "red" }}>*</span>
                            </label>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <DatePicker
                                autoOk={true}
                                inputVariant="outlined"
                                variant="inline"
                                margin="dense"
                                fullWidth
                                format="dd/MM/yyyy"
                                value={e.expense_date}
                                onChange={(date) => {
                                  var row = this.state.multiRows;
                                  row[idx].expense_date = dateFormateToDB(date);
                                  row[idx].expense_date_error = false;
                                  this.setState({
                                    multiRows: row,
                                  });
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="start">
                                      <IconButton>
                                        <EventIcon />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                  className: "pl-0",
                                }}
                                error={e.expense_date_error}
                              />
                            </MuiPickersUtilsProvider>
                          </div>

                          <div className="col-lg-6 mb-1 pl-0">
                            <label className="form_label mb-0">
                              Amount <span style={{ color: "red" }}>*</span>
                            </label>
                            <TextField
                              name="amount"
                              margin="dense"
                              type="number"
                              variant="outlined"
                              fullWidth
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    position="end"
                                    style={{
                                      marginRight: "0px",
                                      marginTop: "-4px",
                                      width: "50%",
                                    }}
                                  >
                                    <TextField
                                      name="billing_currency"
                                      variant="outlined"
                                      margin="dense"
                                      value={e.billing_currency}
                                      fullWidth
                                      onChange={(e) => {
                                        var row = this.state.multiRows;
                                        row[idx].billing_currency =
                                          e.target.value;
                                        if (
                                          ((config.company === "SRPL" ||
                                            config.company === "AVS" ||
                                            config.company === "PRPL") &&
                                            e.target.value === "USD") ||
                                          ((config.company === "SDAM" ||
                                            config.company === "SRK" ||
                                            config.company === "BTR") &&
                                            e.target.value === "IDR")
                                        ) {
                                          row[idx].exchange_rate = "";
                                          row[idx].local_currency_amount = "";
                                        }
                                        this.setState({
                                          multiRows: row,
                                        });
                                      }}
                                      select
                                    >
                                      {this.state.currencyData}
                                    </TextField>
                                  </InputAdornment>
                                ),
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                  padding: 0,
                                },
                              }}
                              error={e.amount_error}
                              placeholder="Amount"
                              value={e.amount}
                              onChange={(e) => {
                                const reg = /^\d*(\.\d{0,4})?$/;
                                if (
                                  e.target.value === "" ||
                                  reg.test(e.target.value)
                                ) {
                                  var row = this.state.multiRows;
                                  row[idx].amount = e.target.value;
                                  row[idx].amount_error = false;
                                  if (
                                    config.company === "SRPL" ||
                                    config.company === "AVS" ||
                                    config.company === "PRPL"
                                  ) {
                                    row[idx].local_currency_amount = (
                                      Number(
                                        row[idx].exchange_rate
                                          ? row[idx].exchange_rate
                                          : 1
                                      ) /
                                      Number(
                                        e.target.value ? e.target.value : 1
                                      )
                                    ).toFixed(2);
                                  }
                                  if (
                                    config.company === "SDAM" ||
                                    config.company === "SRK" ||
                                    config.company === "BTR"
                                  ) {
                                    row[idx].local_currency_amount = (
                                      Number(
                                        row[idx].exchange_rate
                                          ? row[idx].exchange_rate
                                          : 1
                                      ) *
                                      Number(
                                        e.target.value ? e.target.value : 1
                                      )
                                    ).toFixed(2);
                                  }
                                  this.setState({
                                    multiRows: row,
                                  });
                                }
                              }}
                            />
                          </div>

                          {(((config.company === "SRPL" ||
                            config.company === "AVS" ||
                            config.company === "PRPL") &&
                            e.billing_currency !== "USD" &&
                            e.billing_currency &&
                            e.billing_currency !== "0") ||
                            ((config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") &&
                              e.billing_currency !== "IDR" &&
                              e.billing_currency &&
                              e.billing_currency !== "0")) && (
                            <div className="col-lg-6 mb-1 pl-0">
                              <label className="form_label mb-0">
                                Echange Rate{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <TextField
                                name="exchange_rate"
                                margin="dense"
                                type="number"
                                variant="outlined"
                                fullWidth
                                placeholder="Echange Rate"
                                value={e.exchange_rate}
                                onChange={(e) => {
                                  const reg = /^\d*(\.\d{0,10})?$/;
                                  if (
                                    e.target.value === "" ||
                                    reg.test(e.target.value)
                                  ) {
                                    var row = this.state.multiRows;
                                    row[idx].exchange_rate = e.target.value;
                                    if (
                                      config.company === "SRPL" ||
                                      config.company === "AVS" ||
                                      config.company === "PRPL"
                                    ) {
                                      row[idx].local_currency_amount = (
                                        Number(
                                          row[idx].amount ? row[idx].amount : 1
                                        ) /
                                        Number(
                                          e.target.value ? e.target.value : 1
                                        )
                                      ).toFixed(2);
                                    }
                                    if (
                                      config.company === "SDAM" ||
                                      config.company === "SRK" ||
                                      config.company === "BTR"
                                    ) {
                                      row[idx].local_currency_amount = (
                                        Number(
                                          row[idx].amount ? row[idx].amount : 1
                                        ) *
                                        Number(
                                          e.target.value ? e.target.value : 1
                                        )
                                      ).toFixed(2);
                                    }
                                    this.setState({
                                      multiRows: row,
                                    });
                                  }
                                }}
                              />
                            </div>
                          )}

                          {(((config.company === "SRPL" ||
                            config.company === "AVS" ||
                            config.company === "PRPL") &&
                            e.billing_currency !== "USD" &&
                            e.billing_currency &&
                            e.billing_currency !== "0") ||
                            ((config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") &&
                              e.billing_currency !== "IDR" &&
                              e.billing_currency &&
                              e.billing_currency !== "0")) && (
                            <div className="col-lg-6">
                              <label className="form_label mb-0">
                                Amount in{" "}
                                {config.company === "SRPL" ||
                                config.company === "AVS" ||
                                config.company === "PRPL"
                                  ? "USD"
                                  : "IDR"}
                              </label>

                              <p>
                                {e.local_currency_amount}{" "}
                                {config.company === "SRPL" ||
                                config.company === "AVS" ||
                                config.company === "PRPL"
                                  ? "USD"
                                  : "IDR"}
                              </p>
                            </div>
                          )}

                          <div className="col-lg-6 mb-1 pl-0">
                            <label className="form_label mb-0">Remarks</label>
                            <TextField
                              name="remarks"
                              margin="dense"
                              variant="outlined"
                              multiline
                              rows={3}
                              placeholder="Remarks"
                              value={e.remarks}
                              onChange={(e) => {
                                var row = this.state.multiRows;
                                row[idx].remarks = e.target.value;
                                this.setState({
                                  multiRows: row,
                                });
                              }}
                              fullWidth
                              inputProps={{
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                  textTransform: "uppercase",
                                },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                {/* {(this.state.features.indexOf('378') >= 0 || this.state.features.indexOf('97') >= 0) && */}

                <FileUpload
                  files={this.state.files}
                  feature_name="Business_no_other_expenses"
                  callbackFn={(data) => this.setState({ files: data })}
                />
              </div>
            </div>
          </div>
          <footer className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button btn-light"
              name="submit"
              onClick={() => {
                this.props.returnCallbackViewPage("OtherExpense");
              }}
            >
              Back
            </button>
            <button
              type="button"
              className="drawer_text drawer_btn_success"
              name="Back"
              onClick={this.submitHandler}
            >
              Update
            </button>
          </footer>
        </div>
      );
    }
  }
}
