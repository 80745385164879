import React, { Component } from "react";

import CookieHandler from "../../common/CookieHandler";
import SideBar from "../../common/NewSidebar";
import Header from "../../common/Header";
import api from "../../../api/api";
import config from "../../../config/config";
import { Alert } from "@material-ui/lab";
import { Snackbar, Drawer, MenuItem, Popover } from "@material-ui/core";
import DocumentsUpload from "../../DocumentsUpload";

import DashboardIcon from "@material-ui/icons/Dashboard";
import { localDateFormate, pad, toLocaleString } from "../../common/common";
import Loader from "../../common/Loader";

export default class ViewCpsContract extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      contract_date: null,
      purchase_contract_id: "",
      cps_contract_pc_mapping_data: [],
      cps_contract_mappping_data: [
        {
          pc_qualities_id: "0",
          pc_purchase_type_id: "0",
          price_type: "Lumpsum",
          quantity_in_mt: "",
          price_pmt: "",
          total_price: "",
        },
      ],
      purchaseContractList: [],
      purchaseDataForMenu: [],
      purchase_qualities: [],
      pc_qualities: [],
      pc_quality_types: [],
      pc_types_menu: [],
      isLoading: true,
      features: [],
      barge_cps_costing: [],
      snapshot_data: null,
      files: [
        {
          file_description: "",
          file_name: "",
          file_extension: "",
          file_content: "",
          feature_name: "Cps_contract",
        },
      ],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }

    const features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (!(features.includes("501") || features.includes("97"))) {
      window.location.href = "/dashboard";
    }
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const cps_contract_id = decodeURIComponent(
      window.atob(this.props.match.params.cps_contract_id)
    );
    let request = {
      login_user_id: loginUserID,
      idtoken: idToken,
      cps_contract_id: cps_contract_id,
    };
    await api.get_barge_cps_costing_details(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            if (res.barge_cps_costing) {
              this.setState({
                barge_cps_costing: [
                  ...this.state.barge_cps_costing,
                  ...res.barge_cps_costing,
                ],
              });
            }
          } else if (res.code === "601") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
              isLoading: false,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });

    await api
      .get_cps_contract(loginUserID, idToken, cps_contract_id)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.cps_contract_data) {
                this.setState(
                  (prevState) => ({
                    ...prevState,
                    ...res.cps_contract_data,
                    isLoading: false,
                  }),
                  () => {
                    this.callSnapshot(this.state.snapshot_contract_id);
                  }
                );
              }
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
                isLoading: false,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
    document.title = config.documentTitle + "View Coal Procurement Contract";
  }

  onAddResponse = (value) => {
    this.setState({ addDocuments: false, openDocumentsDrawer: false }, () => {
      this.componentDidMount();
    });
  };

  CallbackDrawerClose = (childData) => {
    this.setState({
      openDocumentsDrawer: false,
      addDocuments: false,
    });
  };

  addButtonOpen = (event) => {
    event.preventDefault();
    this.setState({
      addButtonOpen: true,
      openDrawer: false,
      anchorEn: event.currentTarget,
    });
  };

  addButtonClose = () => {
    this.setState({
      addButtonOpen: false,
    });
  };

  // closeContractHandler = () => {
  //   const loginUserID = this.Cookie.getCookie("loginUserId");
  //   const idToken = this.Cookie.getIdTokenCookie();
  //   const cps_contract_id = decodeURIComponent(
  //     window.atob(this.props.match.params.cps_contract_id)
  //   );
  //   api
  //     .close_cps_contract(loginUserID, idToken, cps_contract_id)
  //     .then((response) => {
  //       if (response.status >= 200 && response.status < 300) {
  //         response.json().then((res) => {
  //           if (res.code === "200") {
  //             this.setState(
  //               {
  //                 snackBarSuccessOpen: true,
  //                 successMsg: res.message,
  //                 addButtonOpen: false,
  //               },
  //               () => {
  //                 this.componentDidMount();
  //               }
  //             );
  //           } else if (res.code === "601") {
  //             this.setState({
  //               snackBarErrorOpen: true,
  //               errorMsg: res.message,
  //             });
  //           } else if (res.code === "607") {
  //             window.location.href = "/logout";
  //           }
  //         });
  //       }
  //     });
  // };

  // cancelContractHandler = () => {
  //   const loginUserID = this.Cookie.getCookie("loginUserId");
  //   const idToken = this.Cookie.getIdTokenCookie();
  //   const cps_contract_id = decodeURIComponent(
  //     window.atob(this.props.match.params.cps_contract_id)
  //   );
  //   api
  //     .cancel_cps_contract(loginUserID, idToken, cps_contract_id)
  //     .then((response) => {
  //       if (response.status >= 200 && response.status < 300) {
  //         response.json().then((res) => {
  //           if (res.code === "200") {
  //             this.setState(
  //               {
  //                 snackBarSuccessOpen: true,
  //                 successMsg: res.message,
  //                 addButtonOpen: false,
  //               },
  //               () => {
  //                 this.componentDidMount();
  //               }
  //             );
  //           } else if (res.code === "601") {
  //             this.setState({
  //               snackBarErrorOpen: true,
  //               errorMsg: res.message,
  //             });
  //           } else if (res.code === "607") {
  //             window.location.href = "/logout";
  //           }
  //         });
  //       }
  //     });
  // };

  ContractApproveHandler = (status) => {
    const request = {
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
      cps_contract_id: decodeURIComponent(
        window.atob(this.props.match.params.cps_contract_id)
      ),
      status: status,
    };

    if (status === "Approved") {
      api.approve_cps_contract(request).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState(
                {
                  snackBarSuccessOpen: true,
                  successMsg: res.message,
                  addButtonOpen: false,
                },
                () => {
                  this.componentDidMount();
                }
              );
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
    } else {
      api.update_cps_contract_status(request).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState(
                {
                  snackBarSuccessOpen: true,
                  successMsg: res.message,
                  addButtonOpen: false,
                },
                () => {
                  this.componentDidMount();
                }
              );
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
    }
  };

  callSnapshot = (snapID) => {
    if (snapID) {
      const idToken = this.Cookie.getIdTokenCookie();
      const loginUserID = this.Cookie.getCookie("loginUserId");
      api.get_cps_contract(loginUserID, idToken, snapID).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState(
                {
                  snapshot_data: { ...res.cps_contract_data },
                  isLoading: false,
                },
                () => {
                  this.QualitiesDiff();
                }
              );
            }
          });
        }
      });
    } else {
      this.setState({ isLoading: false });
    }
  };

  QualitiesDiff = () => {
    const snap_qualities =
      this.state?.snapshot_data?.cps_contract_mappping_data;
    const addendum_qualites = this.state.cps_contract_mappping_data;
    const new_qualities = [];

    // console.log("console", snap_qualities.filter(({ quality_seq: id1 }) => !addendum_qualites.some(({ quality_seq: id2 }) => id2 === id1)));

    const removed_records = snap_qualities.filter(
      ({ quality_seq: id1 }) =>
        !addendum_qualites.some(({ quality_seq: id2 }) => id2 === id1)
    );
    for (var obj of removed_records) {
      obj.color = "#f65c8d1f";
      new_qualities.push(obj);
    }
    const new_records = addendum_qualites.filter(
      ({ quality_seq: id1 }) =>
        !snap_qualities.some(({ quality_seq: id2 }) => id2 === id1)
    );

    for (var el of new_records) {
      el.color = "#41c1971f";
      new_qualities.push(el);
    }

    const common_records = snap_qualities.filter((item) =>
      addendum_qualites.filter(
        (e) =>
          JSON.stringify(item.quality_seq) === JSON.stringify(e.quality_seq)
      )
    );
    for (var el1 of common_records) {
      new_qualities.push(el1);
    }

    let copySnapData = {
      ...this.state.snapshot_data,
      cps_contract_mappping_data: new_qualities,
    };

    this.setState({
      snapshot_data: copySnapData,
    });
  };

  CheckQualityDiffHandler = (identifier, label, value) => {
    if (this.state.cps_contract_mappping_data) {
      const filter_selected_quality =
        this.state.cps_contract_mappping_data?.find(
          (e) => e.quality_seq === identifier
        );
      if (filter_selected_quality) {
        const diff = filter_selected_quality[label] !== value;
        if (diff) {
          return filter_selected_quality[label];
        } else return false;
      }
    }

    return false;
  };

  CheckDiffHandler = (lable) => {
    const snap_data = this.state.snapshot_data || {};
    const addendum_data = this.state;
    const diff = snap_data[lable] !== addendum_data[lable];
    if (diff) {
      return addendum_data[lable];
    }
    return false;
  };

  render() {
    const cps_contract_id = decodeURIComponent(
      window.atob(this.props.match.params.cps_contract_id)
    );
    let drawerComponent;
    if (this.state.addDocuments && cps_contract_id) {
      drawerComponent = (
        <DocumentsUpload
          feature_name={"Cps_contract"}
          feature_seq_id={cps_contract_id}
          onAddResponse={this.onAddResponse}
          documents={this.state.files}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
        />
      );
    }

    return (
      <div id="wraper">
        <SideBar />
        <div className="content">
          <Header />
          <div className="clearfix content-header">
            <div className="row">
              <div className="col-sm text-left pl-0">
                <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                  {" "}
                  <a href={"/coal-procurement-contracts"}>
                    <i className="fa fa-arrow-left left_arrow_adjst" />
                  </a>
                  Coal Procurment Service Contract -{this.state.cps_contract_no}
                  &nbsp;
                  <span
                    className={
                      this.state.status === "Active" ? "type_1" : "type_2"
                    }
                    style={{ padding: 8, marginLeft: 16 }}
                  >
                    {this.state.status}
                  </span>
                </h4>
              </div>
              {this.state.status === "Draft" && (
                <div
                  className="col-lg-3 text-right float-sm-left"
                  style={{ padding: "5px 20px" }}
                >
                  <button
                    className="header_button header_button_text add_button_adjustment"
                    style={{ width: "auto", marginTop: "17px" }}
                    onClick={() =>
                      this.ContractApproveHandler("Pending Approval")
                    }
                  >
                    Send for Approval
                  </button>
                </div>
              )}
              {this.state.status === "Pending Approval" &&
                (this.state.features?.includes("21") ||
                  this.state.features?.includes("97")) &&
                this.state.created_by !==
                  this.Cookie.getCookie("loginUserId") && (
                  <div
                    className="col-lg-3 text-right float-sm-left"
                    style={{ padding: "5px 20px" }}
                  >
                    <button
                      className="header_button header_button_text add_button_adjustment"
                      style={{ width: "auto", marginTop: "17px" }}
                      onClick={() => this.ContractApproveHandler("Approved")}
                    >
                      Approve
                    </button>
                  </div>
                )}
              <div className="col-lg-2 text-right float-sm-left">
                <span
                  className="mr-1"
                  data-toggle="tooltip"
                  title="Action"
                  data-placement="bottom"
                >
                  <DashboardIcon
                    style={{
                      fontSize: 30,
                      marginTop: 2,
                      color: "#138496",
                      cursor: "pointer",
                    }}
                    onClick={this.addButtonOpen}
                  />
                </span>
              </div>
            </div>
          </div>
          {this.state.isLoading && <Loader />}
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          {!this.state.isLoading && (
            <section className="container p-3">
              <div className="card" style={{ overflowY: "scroll" }}>
                <div className="section_block" style={{ width: "85%" }}>
                  <div className="row">
                    <div className="table-responsive">
                      <table className="table table-bordered table-sm">
                        <tr className="table-header">
                          <th>Contract Type</th>
                          <th>Contract Date</th>
                          <th>Purchase Contract</th>
                          <th>Vendor</th>
                          <th>Currency</th>
                          {this.state.addendums.length > 0 && (
                            <th>Addendums</th>
                          )}
                        </tr>
                        <tr>
                          <td rowSpan={this.state.addendums.length}>
                            {this.state.contract_type
                              ? this.state.contract_type
                              : "-"}
                          </td>
                          <td rowSpan={this.state.addendums.length}>
                            {localDateFormate(this.state.contract_date)}
                          </td>
                          <td rowSpan={this.state.addendums.length}>
                            {this.state.cps_contract_pc_mapping_data.map(
                              (pc) => (
                                <a
                                  href={
                                    "/view-purchase-contract/" +
                                    btoa(pc.purchase_contract_id)
                                  }
                                  rel="noopener noreferrer"
                                  role="button"
                                  style={{ color: config.themeColor }}
                                >
                                  {pc.contract_no}
                                </a>
                              )
                            )}
                          </td>
                          <td rowSpan={this.state.addendums.length}>
                            {this.state.vendor !== null ? (
                              <a
                                href={"/view-vendor/" + btoa(this.state.vendor)}
                                rel="noopener noreferrer"
                                role="button"
                                style={{ color: config.themeColor }}
                              >
                                {this.state.vendor_name}
                              </a>
                            ) : (
                              <>
                                {this.CheckDiffHandler("other_vendor_name") ? (
                                  <>
                                    <s style={{ textDecorationColor: "red" }}>
                                      {
                                        this.state.snapshot_data
                                          ?.other_vendor_name
                                      }
                                    </s>
                                    &nbsp;
                                    {this.CheckDiffHandler(
                                      "other_vendor_name"
                                    )}{" "}
                                  </>
                                ) : this.state.other_vendor_name ? (
                                  this.state.other_vendor_name
                                ) : (
                                  "-"
                                )}
                              </>
                            )}
                          </td>
                          <td rowSpan={this.state.addendums.length}>
                            {this.CheckDiffHandler("currency") ? (
                              <>
                                <s style={{ textDecorationColor: "red" }}>
                                  {this.state.snapshot_data?.currency}
                                </s>
                                &nbsp;{this.CheckDiffHandler("currency")}{" "}
                              </>
                            ) : this.state.currency ? (
                              this.state.currency
                            ) : (
                              "-"
                            )}
                          </td>

                          {this.state.addendums.length > 0 && (
                            <td>
                              {this.state.addendums?.map((e, idx) => (
                                <div>
                                  <a
                                    rel="noopener noreferrer"
                                    href={
                                      "/view-cps-contract/" +
                                      btoa(e.addendum_contract_id)
                                    }
                                    target="_blank"
                                    style={{ color: config.themeColor }}
                                  >
                                    {e.addendum_contract_no}
                                  </a>
                                </div>
                              ))}
                            </td>
                          )}
                        </tr>
                      </table>
                    </div>
                  </div>

                  <div className="row">
                    <div className="table-responsive">
                      <table className="table table-bordered table-sm">
                        <thead className="table-header">
                          {this.state.cps_contract_pc_mapping_data.length <=
                            1 && (
                            <th className="text-center" nowrap="true">
                              Quality
                            </th>
                          )}
                          {this.state.cps_contract_pc_mapping_data.length <=
                            1 && (
                            <th className="text-center" nowrap="true">
                              Type
                            </th>
                          )}
                          <th className="text-center" nowrap="true">
                            Price Type
                          </th>
                          <th className="text-center" nowrap="true">
                            Quantity
                          </th>
                          <th className="text-center" nowrap="true">
                            Price
                          </th>
                        </thead>
                        {this.state.contract_kind !== "Original" ? (
                          <tbody>
                            {this.state.snapshot_data?.cps_contract_mappping_data?.map(
                              (e, index) => (
                                <tr
                                  key={e.id}
                                  style={{
                                    backgroundColor: e.color ? e.color : "#fff",
                                  }}
                                >
                                  {this.state.cps_contract_pc_mapping_data
                                    .length <= 1 && (
                                    <td
                                      className="text-uppercase text-center font-bold"
                                      nowrap="true"
                                    >
                                      {this.CheckQualityDiffHandler(
                                        e.quality_seq,
                                        "quality",
                                        e.quality
                                      ) ? (
                                        <>
                                          <s
                                            style={{
                                              textDecorationColor: "red",
                                              fontWeight: "normal",
                                            }}
                                          >
                                            {e.quality}
                                          </s>
                                          &nbsp;
                                          {this.CheckQualityDiffHandler(
                                            e.quality_seq,
                                            "quality",
                                            e.quality
                                          )}{" "}
                                        </>
                                      ) : (
                                        e.quality
                                      )}
                                    </td>
                                  )}
                                  {this.state.cps_contract_pc_mapping_data
                                    .length <= 1 && (
                                    <td
                                      className="text-uppercase text-center"
                                      nowrap="true"
                                    >
                                      {this.CheckQualityDiffHandler(
                                        e.quality_seq,
                                        "purchase_type",
                                        e.purchase_type
                                      ) ? (
                                        <>
                                          <s
                                            style={{
                                              textDecorationColor: "red",
                                            }}
                                          >
                                            {e.purchase_type}
                                          </s>
                                          &nbsp;
                                          {this.CheckQualityDiffHandler(
                                            e.quality_seq,
                                            "purchase_type",
                                            e.purchase_type
                                          )}
                                        </>
                                      ) : (
                                        e.purchase_type
                                      )}
                                    </td>
                                  )}
                                  <td
                                    className="text-uppercase text-center"
                                    nowrap="true"
                                  >
                                    {this.CheckQualityDiffHandler(
                                      e.quality_seq,
                                      "price_type",
                                      e.price_type
                                    ) ? (
                                      <>
                                        <s
                                          style={{ textDecorationColor: "red" }}
                                        >
                                          {e.price_type}
                                        </s>
                                        &nbsp;
                                        {this.CheckQualityDiffHandler(
                                          e.quality_seq,
                                          "price_type",
                                          e.price_type
                                        )}
                                      </>
                                    ) : (
                                      e.price_type
                                    )}
                                  </td>
                                  <td
                                    className="text-uppercase text-center"
                                    nowrap="true"
                                  >
                                    {this.CheckQualityDiffHandler(
                                      e.quality_seq,
                                      "quantity_in_mt",
                                      e.quantity_in_mt
                                    ) ? (
                                      <>
                                        <s
                                          style={{ textDecorationColor: "red" }}
                                        >
                                          {toLocaleString(e.quantity_in_mt)}
                                        </s>
                                        &nbsp;
                                        {toLocaleString(
                                          this.CheckQualityDiffHandler(
                                            e.quality_seq,
                                            "quantity_in_mt",
                                            e.quantity_in_mt
                                          )
                                        )}
                                      </>
                                    ) : (
                                      toLocaleString(e.quantity_in_mt)
                                    )}
                                  </td>
                                  {e.price_type === "Per MT" ? (
                                    <td
                                      className="text-uppercase text-center"
                                      nowrap="true"
                                    >
                                      {this.CheckQualityDiffHandler(
                                        e.quality_seq,
                                        "price_pmt",
                                        e.price_pmt
                                      ) ? (
                                        <>
                                          <s
                                            style={{
                                              textDecorationColor: "red",
                                            }}
                                          >
                                            {toLocaleString(e.price_pmt)}
                                          </s>
                                          &nbsp;
                                          {toLocaleString(
                                            this.CheckQualityDiffHandler(
                                              e.quality_seq,
                                              "price_pmt",
                                              e.price_pmt
                                            )
                                          )}{" "}
                                        </>
                                      ) : (
                                        toLocaleString(e.price_pmt)
                                      )}
                                    </td>
                                  ) : (
                                    <td
                                      className="text-uppercase text-center"
                                      nowrap="true"
                                    >
                                      {this.CheckQualityDiffHandler(
                                        e.quality_seq,
                                        "total_price",
                                        e.total_price
                                      ) ? (
                                        <>
                                          <s
                                            style={{
                                              textDecorationColor: "red",
                                            }}
                                          >
                                            {toLocaleString(e.total_price)}
                                          </s>
                                          &nbsp;
                                          {toLocaleString(
                                            this.CheckQualityDiffHandler(
                                              e.quality_seq,
                                              "total_price",
                                              e.total_price
                                            )
                                          )}{" "}
                                        </>
                                      ) : (
                                        toLocaleString(e.total_price)
                                      )}
                                    </td>
                                  )}
                                </tr>
                              )
                            )}
                          </tbody>
                        ) : (
                          <tbody>
                            {this.state.cps_contract_mappping_data?.map(
                              (e, index) => (
                                <tr
                                  key={e.id}
                                  style={{
                                    backgroundColor: e.color ? e.color : "#fff",
                                  }}
                                >
                                  {this.state.cps_contract_pc_mapping_data
                                    .length <= 1 && (
                                    <td
                                      className="text-uppercase text-center font-bold"
                                      nowrap="true"
                                    >
                                      {e.quality}
                                    </td>
                                  )}
                                  {this.state.cps_contract_pc_mapping_data
                                    .length <= 1 && (
                                    <td
                                      className="text-uppercase text-center"
                                      nowrap="true"
                                    >
                                      {e.purchase_type}
                                    </td>
                                  )}
                                  <td
                                    className="text-uppercase text-center"
                                    nowrap="true"
                                  >
                                    {e.price_type}
                                  </td>
                                  <td
                                    className="text-uppercase text-center"
                                    nowrap="true"
                                  >
                                    {toLocaleString(e.quantity_in_mt)}
                                  </td>
                                  {e.price_type === "Per MT" ? (
                                    <td
                                      className="text-uppercase text-center"
                                      nowrap="true"
                                    >
                                      {toLocaleString(e.price_pmt)}
                                    </td>
                                  ) : (
                                    <td
                                      className="text-uppercase text-center"
                                      nowrap="true"
                                    >
                                      {toLocaleString(e.total_price)}
                                    </td>
                                  )}
                                </tr>
                              )
                            )}
                          </tbody>
                        )}
                      </table>
                    </div>
                  </div>

                  {/* {this.state.cps_contract_mappping_data.map((e, idx) => (
                    <div className="row mb-3" key={e.purchase_contract_id}>
                      <div className="col-lg-12 p-0">
                        <div className="card-body border_dashed">
                          <div className="row">
                            {this.state.cps_contract_pc_mapping_data.length <=
                              1 && (
                              <div className="col-lg-4  mb-2 pr-0">
                                <label className="contract_display_header_label">
                                  Purchase Quality
                                </label>
                                <div className="contract_display_header_value">
                                  {e.quality ? e.quality : "-"}{" "}
                                </div>
                              </div>
                            )}
                            {this.state.cps_contract_pc_mapping_data.length <=
                              1 && (
                              <div className="col-lg-4  mb-2 pr-0">
                                <label className="contract_display_header_label">
                                  Purchase Type
                                </label>
                                <div className="contract_display_header_value">
                                  {e.purchase_type ? e.purchase_type : "-"}{" "}
                                </div>
                              </div>
                            )}
                            <div className="col-lg-4  mb-2 pr-0">
                              <label className="contract_display_header_label">
                                Price Type
                              </label>
                              <div className="contract_display_header_value">
                                {e.price_type ? e.price_type : "-"}{" "}
                              </div>
                            </div>
                            <div className="col-lg-4  mb-2 pr-0">
                              <label className="contract_display_header_label">
                                Quantity
                              </label>
                              <div className="contract_display_header_value">
                                {toLocaleString(e.quantity_in_mt)}
                              </div>
                            </div>
                            {e.price_type === "Per MT" && (
                              <div className="col-lg-4  mb-2 pr-0">
                                <label className="contract_display_header_label">
                                  Price PMT
                                </label>
                                <div className="contract_display_header_value">
                                  {toLocaleString(e.price_pmt)}
                                  {this.state.currency
                                    ? this.state.currency
                                    : ""}
                                </div>
                              </div>
                            )}
                            <div className="col-lg-4  mb-2">
                              <label className="contract_display_header_label">
                                Total Price
                              </label>
                              <div className="contract_display_header_value">
                                {toLocaleString(e.total_price)}
                                {this.state.currency ? this.state.currency : ""}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))} */}

                  {this.state.contract_kind === "Original" &&
                    this.state.barge_cps_costing.length > 0 && (
                      <div className="card p-0 border mb-3">
                        <div className="card-header section_header">
                          <h5>Deductions</h5>
                        </div>
                        <div className="card-body">
                          {this.state.barge_cps_costing.length > 0 ? (
                            <div className="row">
                              <div className="table-responsive">
                                <table className="table table-bordered table-sm">
                                  <thead className="table-header">
                                    <tr>
                                      <th className="table_header_barge_label">
                                        Costing ID
                                      </th>
                                      <th className="table_header_barge_label">
                                        Business No
                                      </th>
                                      <th className="table_header_barge_label">
                                        Barge ID
                                      </th>
                                      <th className="table_header_barge_label">
                                        Barge Name
                                      </th>
                                      <th className="table_header_barge_label">
                                        Jetty
                                      </th>
                                      <th className="table_header_barge_label">
                                        Quantity
                                      </th>
                                      <th className="table_header_barge_label">
                                        Payable
                                      </th>
                                    </tr>
                                  </thead>
                                  {this.state.barge_cps_costing.map(
                                    (e, idx) => (
                                      <tbody>
                                        {e.barges.map((item, index) => (
                                          <tr>
                                            {index === 0 && (
                                              <td rowSpan={e.barges.length}>
                                                {e.costing_no}
                                              </td>
                                            )}
                                            <td>
                                              {item.business_no
                                                ? item.business_no
                                                : "-"}
                                            </td>
                                            <td>{pad(item.barge_id)}</td>
                                            <td>{item.barge_nomination}</td>
                                            <td>{item.loading_jetty}</td>
                                            {index === 0 && (
                                              <td rowSpan={e.barges.length}>
                                                {toLocaleString(
                                                  e.barge_quantity
                                                )}
                                              </td>
                                            )}
                                            {index === 0 && (
                                              <td rowSpan={e.barges.length}>
                                                {toLocaleString(
                                                  e.payable_to_vendor
                                                )}
                                              </td>
                                            )}
                                          </tr>
                                        ))}
                                      </tbody>
                                    )
                                  )}
                                </table>
                              </div>
                            </div>
                          ) : (
                            <p>No data available</p>
                          )}
                        </div>
                      </div>
                    )}

                  <div className="row">
                    <table className="table table-bordered table-sm">
                      <tr>
                        <th className="table-header" nowrap="true">
                          Remarks
                        </th>
                        <th className="table-header" nowrap="true">
                          Payment Terms
                        </th>
                      </tr>
                      <tr>
                        <td
                          className="text-uppercase"
                          style={{ whiteSpace: "pre-line" }}
                        >
                          {this.state.remarks ? this.state.remarks : "-"}
                        </td>
                        <td
                          className="text-uppercase"
                          style={{ whiteSpace: "pre-line" }}
                        >
                          {this.CheckDiffHandler("payment_terms") ? (
                            <>
                              <s style={{ textDecorationColor: "red" }}>
                                {this.state.snapshot_data?.payment_terms}
                              </s>
                              &nbsp;{this.CheckDiffHandler("payment_terms")}{" "}
                            </>
                          ) : this.state.payment_terms ? (
                            this.state.payment_terms
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                    </table>
                  </div>

                  {(this.state.features.includes("505") ||
                    this.state.features.includes("97")) && (
                    <div className="row">
                      <span
                        className="col-lg-12 contract_display_header_value pl-0 mb-2"
                        style={{ fontWeight: "bold" }}
                      >
                        Uploaded Documents
                      </span>
                      {this.state.files.map((e, index) => (
                        <div
                          className={
                            e.file_path ? "row col-lg-12 mb-3" : "row col-lg-12"
                          }
                          key={index}
                        >
                          {e.file_path ? (
                            <div className="col-lg-4 p-0">
                              <div className="">
                                <a
                                  href={config.apiDomain + "/" + e.file_path}
                                  role="button"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{ color: config.themeColor }}
                                >
                                  {e.file_description}
                                </a>
                              </div>
                            </div>
                          ) : (
                            <p>No files to show</p>
                          )}
                        </div>
                      ))}

                      <p>
                        Add Documents{" "}
                        <i
                          className="fa fa-plus-circle"
                          style={{
                            color: config.themeColor,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            this.setState({
                              openDocumentsDrawer: true,
                              addDocuments: true,
                            });
                          }}
                        />
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <footer className="row bottom_buttons_section">
                <button
                  type="button"
                  className="btn previous_button"
                  name="submit"
                  onClick={() =>
                    (window.location.href = "/coal-procurement-contracts")
                  }
                >
                  CANCEL
                </button>
                {this.state.status !== "Active" && (
                  <button
                    type="button"
                    className="btn next_button"
                    name="Back"
                    onClick={() =>
                      (window.location.href =
                        "/update-cps-contract/" + btoa(cps_contract_id))
                    }
                  >
                    Edit
                  </button>
                )}
              </footer>
            </section>
          )}
        </div>
        <Drawer
          anchor="right"
          open={this.state.openDocumentsDrawer}
          variant="temporary"
          elevation={20}
          style={{ overflow: "initial" }}
        >
          <div className="row p-0" style={{ width: 800 }}>
            {drawerComponent}
          </div>
        </Drawer>
        <Popover
          open={this.state.addButtonOpen}
          anchorEl={this.state.anchorEn}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          targetOrigin={{ horizontal: "left", vertical: "top" }}
          onClose={this.addButtonClose}
        >
          <div className="card border">
            {this.state.status === "Active" && (
              <MenuItem style={{ fontFamily: "Poppins" }}>
                <i
                  className="fa fa-plus-circle mr-4 ml-0"
                  style={{
                    fontSize: 26,
                    color: "#3256a8",
                    opacity: "0.5",
                    margin: "0px 6px",
                    width: "16px",
                  }}
                />
                <a href={"/cpsf-contract-addendum/" + btoa(cps_contract_id)}>
                  Create Addendum
                </a>
              </MenuItem>
            )}
            {(this.state.features.includes("504") ||
              this.state.features.includes("97")) && (
              <MenuItem
                style={{ fontFamily: "Poppins" }}
                onClick={() => this.ContractApproveHandler("Close")}
              >
                <i
                  className="fa fa-times mr-3"
                  aria-hidden="true"
                  style={{
                    fontSize: 25,
                    color: "#3256a8",
                    opacity: "0.5",
                    margin: "0px 3px",
                    width: "20px",
                  }}
                />
                Close
              </MenuItem>
            )}
            {(this.state.features.includes("503") ||
              this.state.features.includes("97")) && (
              <MenuItem
                style={{ fontFamily: "Poppins" }}
                onClick={() => this.ContractApproveHandler("cancelled")}
              >
                <i
                  className="fa fa-minus-square-o mr-3"
                  aria-hidden="true"
                  style={{
                    fontSize: 25,
                    color: "#3256a8",
                    opacity: "0.5",
                    margin: "0px 3px",
                    width: "20px",
                  }}
                />
                Cancel
              </MenuItem>
            )}
          </div>
        </Popover>
      </div>
    );
  }
}
