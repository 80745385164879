import React, { Component } from "react";
import api from "../../../api/api";
import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";

import {
  TextField,
  InputAdornment,
  Dialog,
  DialogTitle,
} from "@material-ui/core";
import { localDateFormate, toLocaleString } from "../../common/common";
import ViewFiles from "../../common/ViewFiles";
import Loader from "../../common/Loader";
import InvoiceForm from "../../common/InvoiceForm";
import PaymentPostings from "../../common/PaymentPostings";
import InvoiceLink from "../../InvoicePdf/InvoiceLink";
export default class MvSupplierDemurrageView extends Component {
  constructor(props) {
    super();
    this.Cookie = new CookieHandler();
    this.state = {
      supplierData: [
        {
          supplier: "0",
          demurrage_per_day: "",
          demurrage_currency: "0",
          despatch_per_day: "",
          despatch_currency: "0",
          demurrage_or_despatch: "Demurrage",
          demurrage_in_days: "",
          demurrage_incurred: "",
          despatch_in_days: "",
          despatch_incurred: "",
          supplier_ctc: "",
        },
      ],
      getBarges: [],
      invoiceDetails: {
        invoice_no: "",
        invoice_date: null,
        ppn: "",
        ppn_date: null,
      },
      features: [],
      isLoading: true,
      mother_vesselName: "",
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    } else {
      var features = this.Cookie.getCookie("features");
      this.setState({ features: features });
      if (
        !(
          features.includes("50") ||
          features.includes("430") ||
          features.includes("483") ||
          features.includes("97")
        )
      ) {
        window.location.href = "/dashboard";
      }
      try {
        var loginUserID = this.Cookie.getCookie("loginUserId");
        var idToken = this.Cookie.getIdTokenCookie();
        // var businessNoID = decodeURIComponent(window.atob(this.props.match.params.businessNoID));
        var businessNoID = this.props.businessNoID;

        await api
          .getBusinessNumber(businessNoID, loginUserID, idToken)
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              response.json().then((res) => {
                if (res.code === "200") {
                  if (res.business_number) {
                    this.setState({
                      businessNo: res.business_number.business_no
                        ? res.business_number.business_no
                        : "",
                      mother_vesselName: res.business_number.mother_vessel_name
                        ? res.business_number.mother_vessel_name
                        : "",
                    });
                  } else {
                    alert(
                      "Unexpected error occured. Please contact administrator."
                    );
                  }
                } else if (res.code === "601") {
                  this.setState({
                    errorMsg: res.message,
                    successMsg: "",
                    isLoading: false,
                  });
                } else if (res.code === "607") {
                  window.location.href = "/logout";
                }
              });
            }
          });

        var MvSupplierDemurrageID = this.props.MvSupplierDemurrageID;
        await api
          .get_mv_pur_fin_supplier_demurrage(
            loginUserID,
            idToken,
            MvSupplierDemurrageID
          )
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              response.json().then((res) => {
                if (res.code === "200") {
                  if (res.mv_supplier_demurrage) {
                    for (var i in res.mv_supplier_demurrage) {
                      var total_paid_amout = 0;
                      for (var j of res.mv_supplier_demurrage[i]
                        .supplier_demurrage_payments) {
                        total_paid_amout += Number(j.amount);
                      }
                      res.mv_supplier_demurrage[i].total_paid_amout =
                        total_paid_amout;
                      res.mv_supplier_demurrage[i].remaining_amount =
                        Number(
                          res.mv_supplier_demurrage[i].supplier_total
                            ? res.mv_supplier_demurrage[i].supplier_total
                            : 0
                        ) - Number(total_paid_amout);
                    }
                    let invoice_details = {
                      ...this.state.invoiceDetails,
                      invoice_no: res.mv_supplier_demurrage[0]?.invoice_no,
                      invoice_date: res.mv_supplier_demurrage[0]?.invoice_date,
                      ppn: res.mv_supplier_demurrage[0]?.ppn,
                      ppn_date: res.mv_supplier_demurrage[0]?.ppn_date,
                    };

                    this.setState({
                      invoiceDetails: invoice_details,
                      supplierData: res.mv_supplier_demurrage,
                      demurrage_or_despatch: res.mv_supplier_demurrage[0]?.demurrage_or_despatch,
                      costing_no: res.mv_supplier_demurrage[0]?.costing_no,
                      supplier_name: res.mv_supplier_demurrage[0]?.supplier_name,

                      demurrage_incurred: res.mv_supplier_demurrage[0]?.demurrage_incurred,
                      demurrage_per_day: res.mv_supplier_demurrage[0]?.demurrage_per_day,
                      demurrage_currency: res.mv_supplier_demurrage[0]?.demurrage_currency,

                      despatch_currency: res.mv_supplier_demurrage[0]?.despatch_currency,
                      despatch_in_days: res.mv_supplier_demurrage[0]?.despatch_in_days,
                      despatch_per_day: res.mv_supplier_demurrage[0]?.despatch_per_day,
                      despatch_incurred: res.mv_supplier_demurrage[0]?.despatch_incurred,

                      supplier_pph23_value: res.mv_supplier_demurrage[0]?.supplier_pph23_value,
                      supplier_currency_xchg_rate: res.mv_supplier_demurrage[0]?.supplier_currency_xchg_rate,
                      supplier_vat_value: res.mv_supplier_demurrage[0]?.supplier_vat_value,
                      supplier_ctc: res.mv_supplier_demurrage[0]?.supplier_ctc,
                      isLoading: false,
                    });
                    if (res.mv_supplier_demurrage.length === 0) {
                      this.setState({
                        errorMsg: "No Supplier Demurrage Data Available.",
                      });
                      window.$("#AlertMessageModal").modal({
                        backdrop: "static",
                        keyboard: true,
                        show: true,
                      });
                    }
                  } else {
                    alert(
                      "Unexpected error occured. Please contact administrator."
                    );
                  }
                } else if (res.code === "601") {
                  this.setState({
                    errorMsg: res.message,
                    successMsg: "",
                    isLoading: false,
                  });
                } else if (res.code === "607") {
                  window.location.href = "/logout";
                }
              });
            }
          });
      } catch (e) {
        window.location.href = "/pagenotfound";
      }
    }
    document.title = config.documentTitle + "Mother Vessel Supplier Demurrage";
  }

  AddPaymentPostingHandler = () => {
    this.setState({ submitting: true });
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    var {
      supplier_demurrage_id,
      payment_posting_type,
      amount,
      date_of_payment,
      invoice_no,
      description,
    } = this.state;
    if (date_of_payment === null) {
      this.setState({
        date_of_paymentError: true,
        submitting: false,
      });
    }
    if (amount === "") {
      this.setState({ amountError: true, submitting: false });
    }
    if (invoice_no === "") {
      this.setState({ invoice_noError: true, submitting: false });
    }

    var remainingAmount = 0;
    remainingAmount = Number(this.state.remainingAmount);
    if (this.state.paymentMode === "Update") {
      remainingAmount =
        Number(this.state.remainingAmount) + Number(this.state.existingAmount);
    }
    if (Number(amount) > Math.ceil(Number(remainingAmount))) {
      this.setState({
        amountError: true,
        submitting: false,
        paymentErrorMsg: "Amount is exceeding Payable to Vendorvalue",
      });
      return;
    }

    if (supplier_demurrage_id && amount !== "" && invoice_no !== "") {
      // var business_no_id = decodeURIComponent(window.atob(this.props.match.params.businessNoID));
      var business_no_id = this.props.businessNoID;
      api
        .add_mv_supplier_demurrage_payments(
          loginUserID,
          idToken,
          business_no_id,
          supplier_demurrage_id,
          payment_posting_type,
          amount,
          date_of_payment,
          invoice_no,
          description
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                this.setState({
                  submitting: false,
                  date_of_payment: null,
                  invoice_no: "",
                  description: "",
                  date_of_paymentError: false,
                  amountError: false,
                  invoice_noError: false,
                  paymentPostingsModal: false,
                });
                window.$("#paymentPostingsModal").modal("hide");
                this.componentDidMount();
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: "",
                  successMsg: "",
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    } else {
      alert("Please fill mandatory fields.");
    }
  };

  UpdatePaymentPostingsHandler = () => {
    this.setState({ submitting: true });
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    var {
      supplier_demurrage_payment_id,
      supplier_demurrage_id,
      payment_posting_type,
      amount,
      date_of_payment,
      invoice_no,
      description,
    } = this.state;
    if (date_of_payment === null) {
      this.setState({
        date_of_paymentError: true,
        submitting: false,
      });
    }
    if (amount === "") {
      this.setState({ amountError: true, submitting: false });
    }
    if (invoice_no === "") {
      this.setState({ invoice_noError: true, submitting: false });
    }
    var remainingAmount = 0;
    remainingAmount = Number(this.state.remainingAmount);
    if (this.state.paymentMode === "Update") {
      remainingAmount =
        Number(this.state.remainingAmount) + Number(this.state.existingAmount);
    }
    if (Number(amount) > Math.ceil(Number(remainingAmount))) {
      this.setState({
        amountError: true,
        submitting: false,
        paymentErrorMsg: "Amount is exceeding Payable to Vendor value",
      });
      return;
    }

    if (supplier_demurrage_id && amount !== "" && invoice_no !== "") {
      let status = "Active";
      // var business_no_id = decodeURIComponent(window.atob(this.props.match.params.businessNoID));
      var business_no_id = this.props.businessNoID;

      api
        .update_mv_supplier_demurrage_payments(
          loginUserID,
          idToken,
          business_no_id,
          supplier_demurrage_id,
          supplier_demurrage_payment_id,
          payment_posting_type,
          amount,
          date_of_payment,
          invoice_no,
          description,
          status
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                this.setState({
                  submitting: false,
                  date_of_payment: null,
                  invoice_no: "",
                  description: "",
                  date_of_paymentError: false,
                  amountError: false,
                  invoice_noError: false,
                  paymentPostingsModal: false,
                });
                window.$("#paymentPostingsModal").modal("hide");
                this.componentDidMount();
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: "",
                  successMsg: "",
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    } else {
      alert("Please fill mandatory fields.");
    }
  };

  getPaymentDetalis = (e) => {
    if (e) {
      this.setState({
        paymentMode: "Update",
        paymentTitle: "Update Supplier Demurrage",
        supplier_demurrage_payment_id: e.id,
        supplier_demurrage_id: e.supplier_demurrage_id,
        date_of_payment: e.date_of_payment,
        amount: e.amount,
        existingAmount: e.amount,
        invoice_no: e.invoice_no,
        payment_posting_type: e.payment_posting_type,
        description: e.description,
        paymentPostingsModal: true,
      });
      window.$("#paymentPostingsModal").modal({
        backdrop: "static",
        keyboard: true,
        show: true,
      });
    }
  };

  deletePaymentsHandler = (e) => {
    if (e) {
      this.setState({ submitting: true });
      var loginUserID = this.Cookie.getCookie("loginUserId");
      var idToken = this.Cookie.getIdTokenCookie();
      var supplier_demurrage_payment_id = e.id;
      var supplier_demurrage_id = e.supplier_demurrage_id;
      var payment_posting_type = e.payment_posting_type;
      var amount = e.amount;
      var date_of_payment = e.date_of_payment;
      var invoice_no = e.invoice_no;
      var description = e.description;

      if (supplier_demurrage_id && amount !== "" && invoice_no !== "") {
        let status = "Deleted";
        // var business_no_id = decodeURIComponent(window.atob(this.props.match.params.businessNoID));
        var business_no_id = this.props.businessNoID;
        api
          .update_mv_supplier_demurrage_payments(
            loginUserID,
            idToken,
            business_no_id,
            supplier_demurrage_id,
            supplier_demurrage_payment_id,
            payment_posting_type,
            amount,
            date_of_payment,
            invoice_no,
            description,
            status
          )
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              response.json().then((res) => {
                if (res.code === "200") {
                  this.setState({
                    submitting: false,
                    date_of_payment: null,
                    invoice_no: "",
                    description: "",
                    date_of_paymentError: false,
                    amountError: false,
                    invoice_noError: false,
                    paymentPostingsModal: false,
                  });
                  window.$("#deleteConformationModal").modal("hide");
                  this.componentDidMount();
                } else if (res.code === "601") {
                  this.setState({
                    errorMsg: "",
                    successMsg: "",
                  });
                } else if (res.code === "607") {
                  window.location.href = "/logout";
                }
              });
            }
          });
      } else {
        alert("Please fill mandatory fields.");
      }
    }
  };

  approveHandler = () => {
    this.setState({ isLoading: true });
    let request = {
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
      costing_id: this.props.MvSupplierDemurrageID,
      costing_type: "MV Supplier Demurrage",
      approval_status: "Approved",
    };
    api.approve_costing_api(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.setState(
              {
                snackBarSuccessOpen: true,
                successMsg: res.message,
              },
              () => this.componentDidMount()
            );
          } else if (res.code === "601") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
  };

  approvePaymentsHandler = (id) => {
    this.setState({ isLoading: true });
    let request = {
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
      payment_id: id,
      costing_type: "mv_supplier_demurrage_payment_postings_data",
      approval_status: "Approved",
    };
    api.approve_payment_postings_api(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.setState(
              {
                snackBarSuccessOpen: true,
                successMsg: res.message,
              },
              () => this.componentDidMount()
            );
          } else if (res.code === "601") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
  };

  updateInvoice = (data) => {
    let request = {
      ...data,
      table_name: "mv_supplier_demurrage",
    };
    api.update_invoice_details(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.componentDidMount();
            this.setState({
              invoiceDialog: false,
            });
          }
        });
      }
    });
  };

  renderPaymentPostings = (data) => {
    const {
      id,
      supplier_demurrage_payments = [],
      invoice_no = null,
      remaining_amount = 0,
      billing_currency,
      currency_xchg_rate = 1,
      approval_status = "Pending",
    } = data;
    let payment_related_data = {
      paymentTitle: "Supplier Demurrage",
      payment_posting_type: "mv_supplier_demurrage",
      invoice_no: invoice_no,
      outstanding_amount: remaining_amount,
      billing_currency: billing_currency
        ? billing_currency
        : config.company === "SRPL" || config.company === "AVS" || config.company === "PRPL"
          ? "USD"
          : "IDR",
      currency_xchg_rate: currency_xchg_rate ? currency_xchg_rate : 1,
      costing_id: id,
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
    };

    let access_details = {
      costing_approval: approval_status,
      add_payment: "389",
      view_payment: "390",
      update_payment: "391",
      delete_payment: "392",
      approver: "430",
      approver2: "483",
    };

    return (
      <PaymentPostings
        access_details={access_details}
        payments={supplier_demurrage_payments}
        costingType="MV Supplier Demurrage"
        data={payment_related_data}
        callbackFn={() => {
          this.componentDidMount();
        }}
      />
    );
  };

  renderInvoiceLink = () => {
    const data = this.state.getBarges.map((e) =>
      e.filter((obj) => obj.id === this.props.MvSupplierDemurrageID)
    );
    const {
      costing_no,
    } = this.state;

    let total_adjustment = 0;

    const Grand_total = (
      parseFloat(this.state.supplierData[0].supplier_ctc ? this.state.supplierData[0].supplier_ctc : 0) +

      (total_adjustment ? total_adjustment : 0)
    ).toFixed(2);

    const invoiceObject = {
      costing_id: costing_no,
      to: this.state.supplier_name,
      business_no: this.state.mother_vesselName,
      costing_name: "MV Supplier Demurrage",
      invoice_no: this.state.invoiceDetails.invoice_no,
      invoice_date: localDateFormate(this.state.invoiceDetails.invoice_date),
      bargeDetails: data,
      billing_currency: this.state.billing_currency,
      total_payable_amount: Grand_total,
      purchase_type: "FOB Barge",
      description: [
        {
          qty:
            this.state.supplierData[0].demurrage_or_despatch === "Demurrage"
              ? this.state.supplierData[0].demurrage_in_days
              : this.state.supplierData[0].despatch_in_days,
          adjustedPrice:
            this.state.supplierData[0].demurrage_or_despatch === "Demurrage"
              ? this.state.supplierData[0].demurrage_per_day
              : this.state.supplierData[0].despatch_per_day,
          desc: `${this.state.supplier_currency_xchg_rate > 1
            ? `Demurrage /  Despatch @ ${parseFloat(
              this.state.supplier_currency_xchg_rate
            ).toFixed(2)}`
            : "Demurrage /  Despatch"
            }`,
          amount: parseFloat(this.state.demurrage_incurred).toFixed(2),
        },
        ...(this.state.pph15_account !== "SDAM" && !!this.state.supplier_vat_value
          ? [
            {
              qty: " ",
              adjustedPrice: " ",
              desc: "VAT",
              amount: `${parseFloat(this.state.supplier_vat_value).toFixed(2)}`,
            },
          ]
          : []),
        ...(!(config.company === "SRPL" || config.company === "AVS" || config.company === "PRPL") && !!this.state.supplier_pph23_value
          ? [
            {
              qty: " ",
              adjustedPrice: " ",
              desc: "PPH",
              amount: `( ${parseFloat(this.state.supplier_pph23_value).toFixed(2)} )`,
            },
          ]
          : []),
      ],
    };
    return (
      <InvoiceLink
        data={invoiceObject}
        fileName={costing_no}
        demurrage_name={this.state.supplierData[0].demurrage_or_despatch}
      />
    );
  };

  render() {
    try {
      var businessNoID = this.props.businessNoID;
    } catch (e) {
      window.location.href = "/business-number-list";
    }
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div
          className="container bg-white p-0"
          style={{ minHeight: "100vh", width: 850 }}
        >
          {this.state.supplierData.map((e, idx) => (
            <div className="clearfix content-header border-bottom">
              <div className="row">
                <div className="col-lg-10 text-left pl-0">
                  <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                    Supplier Demurrage
                    {e.costing_no}
                    <span
                      className={
                        e.approval_status === "Pending" ? "type_2" : "type_1"
                      }
                      style={{ padding: 8, marginLeft: 16 }}
                    >
                      {e.approval_status}
                    </span>
                  </h4>
                </div>
                {e.approval_status === "Pending" &&
                  e.invoice_file &&
                  this.Cookie.getCookie("loginUserId") !== e.created_by &&
                  e.approved_by !== this.Cookie.getCookie("loginUserId") &&
                  (this.state.features.includes("430") ||
                    this.state.features.includes("483") ||
                    this.state.features.includes("97")) && (
                    <div
                      className="col text-right float-sm-left"
                      style={{ padding: "5px 20px" }}
                    >
                      <button
                        className="header_button header_button_text add_button_adjustment"
                        style={{ width: "auto", marginTop: "17px" }}
                        onClick={this.approveHandler}
                      >
                        Approve
                      </button>
                    </div>
                  )}
                <div className="col-lg-1 pl-0 float-right">
                  {this.renderInvoiceLink()}
                </div>
              </div>
            </div>
          ))}
          <div
            className="card p-3"
            style={{ overflowY: "scroll", minHeight: "86vh" }}
          >
            <div
              className="card-body p-0"
              style={{ backgroundColor: "rgba(25,118,210,.01)" }}
              id="accordion"
            >
              {this.state.supplierData.map((e, idx) => (
                <>
                  <div className="row" key={idx}>
                    {e.demurrage_or_despatch === "Demurrage" &&
                      <div className="col shadow-sm p-0 mb-2 bg-white border">
                        <div className="table-responsive">
                          <table className="table table-sm table-bordered mb-0">
                            <thead className="table-header">
                              <tr>
                                <th className="text-center">Description</th>
                                <th className="text-right">Demurrage in Days
                                  {/* {e.demurrage_or_despatch === "Despatch" && "Despatch In Days"} */}
                                </th>
                                <th className="text-right">Demurrage Per Day
                                  {/* {e.demurrage_or_despatch === "Despatch" && "Despatch Per Day"} */}
                                </th>
                                <th className="text-right">Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              <>
                                <tr>
                                  <td>Demurrage
                                    {/* {e.demurrage_or_despatch === "Despatch" && "Demurrage"} */}
                                    {!!e.supplier_currency_xchg_rate && !(
                                      config.company === "SRPL" || config.company === "AVS" ||
                                      config.company === "PRPL"
                                    ) &&
                                      e.billing_currency !== "IDR" &&
                                      e.billing_currency !== "0" && (
                                        <div>
                                          Exchange Rate @{parseFloat(e.supplier_currency_xchg_rate).toFixed(2)}
                                        </div>
                                      )}
                                  </td>
                                  <td className="text-right">
                                    {toLocaleString(e.demurrage_in_days)}
                                    {/* {e.demurrage_or_despatch === "Despatch" && toLocaleString(e.despatch_in_days)} */}
                                  </td>
                                  <td className="text-right">
                                    {toLocaleString(e.demurrage_per_day)}
                                    {/* {e.demurrage_or_despatch === "Despatch" && toLocaleString(e.despatch_per_day)} */}
                                  </td>
                                  <td className="text-right">
                                    {e.demurrage_incurred}
                                    {/* {e.demurrage_or_despatch === "Despatch" && e.despatch_incurred} */}
                                  </td>

                                </tr>
                                {(this.state.vat_applicability === "Inclusive" ||
                                  config.company !== "SRPL" ||
                                  config.company !== "PRPL" ||
                                  config.company !== "AVS") &&
                                  !!e.supplier_vat_value && (
                                    <tr>
                                      <td>VAT @ {toLocaleString(e.supplier_vat_percent)} %</td>
                                      <td className="text-right"></td>
                                      <td className="text-right"></td>
                                      <td className="text-right">
                                        {toLocaleString(e.supplier_vat_value)}
                                      </td>
                                    </tr>
                                  )}
                                {(config.company === "SDAM" ||
                                  config.company === "SRK" ||
                                  config.company === "BTR") && !!e.supplier_pph23_value && (
                                    <tr>
                                      <td>PPH23 @ 2% </td>
                                      <td></td>
                                      <td></td>
                                      <td className="text-right">({toLocaleString(e.supplier_pph23_value)})</td>
                                    </tr>
                                  )}
                                <tr>
                                  <td colSpan={3} style={{ fontWeight: 'bold' }} className="text-right ">
                                    {" "}
                                    Payable to Vendor :{" "}
                                  </td>
                                  <td style={{ fontWeight: 'bold' }} className="text-right ">
                                    {`${config.company === "SRPL" ||
                                      config.company === "PRPL" ||
                                      config.company === "AVS"
                                      ? "USD"
                                      : "IDR"
                                      }
                                ${e.supplier_ctc}`}
                                  </td>
                                </tr>
                              </>
                            </tbody>
                          </table>
                        </div>
                      </div>}
                    {e.demurrage_or_despatch === "Despatch" &&
                      <div className="col shadow-sm p-0 mb-2 bg-white border">
                        <div className="table-responsive">
                          <table className="table table-sm table-bordered mb-0">
                            <thead className="table-header">
                              <tr>
                                <th className="text-center">Description</th>
                                <th className="text-right">Despatch In Days
                                </th>
                                <th className="text-right">Despatch Per Day
                                </th>
                                <th className="text-right">Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              <>
                                <tr>
                                  <td>Despatch
                                    {!!e.supplier_currency_xchg_rate && !(
                                      config.company === "SRPL" || config.company === "AVS" ||
                                      config.company === "PRPL"
                                    ) &&
                                      e.billing_currency !== "IDR" &&
                                      e.billing_currency !== "0" && (
                                        <div>
                                          Exchange Rate @{parseFloat(e.supplier_currency_xchg_rate).toFixed(2)}
                                        </div>
                                      )}
                                  </td>
                                  <td className="text-right">
                                    {toLocaleString(e.despatch_in_days)}
                                  </td>
                                  <td className="text-right">
                                    {toLocaleString(e.despatch_per_day)}
                                  </td>
                                  <td className="text-right">
                                    {e.despatch_incurred}
                                  </td>

                                </tr>
                                {(this.state.vat_applicability === "Inclusive" ||
                                  config.company !== "SRPL" ||
                                  config.company !== "PRPL" ||
                                  config.company !== "AVS") &&
                                  !!e.supplier_vat_value && (
                                    <tr>
                                      <td>VAT @ {toLocaleString(e.supplier_vat_percent)} %</td>
                                      <td className="text-right"></td>
                                      <td className="text-right"></td>
                                      <td className="text-right">
                                        {toLocaleString(e.supplier_vat_value)}
                                      </td>
                                    </tr>
                                  )}
                                {(config.company === "SDAM" ||
                                  config.company === "SRK" ||
                                  config.company === "BTR") && !!e.supplier_pph23_value && (
                                    <tr>
                                      <td>PPH23 @ 2% </td>
                                      <td></td>
                                      <td></td>
                                      <td className="text-right">({toLocaleString(e.supplier_pph23_value)})</td>
                                    </tr>
                                  )}
                                <tr>
                                  <td colSpan={3} style={{ fontWeight: 'bold' }} className="text-right ">
                                    {" "}
                                    Payable to Vendor :{" "}
                                  </td>
                                  <td style={{ fontWeight: 'bold' }} className="text-right ">
                                    {`${config.company === "SRPL" ||
                                      config.company === "PRPL" ||
                                      config.company === "AVS"
                                      ? "USD"
                                      : "IDR"
                                      }
                                ${e.supplier_ctc}`}
                                  </td>
                                </tr>
                              </>
                            </tbody>
                          </table>
                        </div>
                      </div>}
                    <div className="row col-lg-12 border_dashed rounded-lg p-2 mb-3">
                      <div className="row col-lg-12 mb-2">
                      </div>
                      <div className="row col-lg-6 pl-2 mb-2 pr-2">
                        <div className="col-lg-12 mb-2 p-0">
                          <label className="contract_display_header_label">
                            Supplier
                          </label>
                          <div className="contract_display_header_value">
                            {e.supplier_name ? e.supplier_name : "-"}
                          </div>
                        </div>
                      </div>
                      <div className="card col-lg-12 p-0 border mb-3">
                        <div className="card-header section_header">
                          <h5>Payment Postings</h5>
                        </div>
                        <div className="card-body">
                          <InvoiceForm
                            open={this.state.invoiceDialog}
                            costingID={this.props.MvSupplierDemurrageID}
                            costing_type={"MV Supplier Demurrage"}
                            data={this.state.invoiceDetails}
                            callbackFn={(data) => this.updateInvoice(data)}
                          />
                          {(this.state.features.includes("390") ||
                            this.state.features.includes("97")) && (
                              <>{this.renderPaymentPostings(e)}</>
                              // <div>
                              //   <div className="row">
                              //     <div className="col-lg-12">
                              //       <div className="clearfix">
                              //         <div className="float-left">
                              //           {!e.supplier_demurrage_payments
                              //             ? ""
                              //             : e.supplier_demurrage_payments.length <=
                              //                 0 && (
                              //                 <p style={{ fontWeight: "bold" }}>
                              //                   No payments made
                              //                 </p>
                              //               )}
                              //         </div>
                              //         <div className="float-right">
                              //           {(this.state.features.includes("389")  ||
                              //             this.state.features.includes("97") >=
                              //               0) && (
                              //             <button
                              //               className="header_button header_button_text  w-auto"
                              //               data-toggle="modal"
                              //               data-placement="bottom"
                              //               data-keyboard="false"
                              //               data-target="#paymentPostingsModal"
                              //               data-backdrop="static"
                              //               onClick={() => {
                              //                 this.setState({
                              //                   paymentTitle:
                              //                     "Add Supplier Demurrage",
                              //                   payment_posting_type:
                              //                     "outgoing_credit_note",
                              //                   remainingAmount: e.remaining_amount,
                              //                   supplier_demurrage_id: e.id,
                              //                   date_of_payment: null,
                              //                   amount: "",
                              //                   invoice_no: "",
                              //                   description: "",
                              //                   paymentErrorMsg: "",
                              //                   date_of_paymentError: false,
                              //                   paymentPostingsModal: true,
                              //                   amountError: false,
                              //                   paymentMode: "Add",
                              //                   invoice_noError: false,
                              //                 });
                              //               }}
                              //             >
                              //               Add Payment
                              //             </button>
                              //           )}
                              //         </div>
                              //       </div>
                              //     </div>
                              //   </div>

                              //   {!e.supplier_demurrage_payments
                              //     ? ""
                              //     : e.supplier_demurrage_payments.length > 0 && (
                              //         <div className="row mt-2">
                              //           <div className="col-lg">
                              //             <div className="table-responsive">
                              //               <table className="table table-borderless border-0">
                              //                 <thead>
                              //                   <tr>
                              //                     <th nowrap="true">
                              //                       Date of Payment
                              //                     </th>
                              //                     <th
                              //                       nowrap="true"
                              //                       className="text-right"
                              //                     >
                              //                       Amount
                              //                     </th>
                              //                     <th nowrap="true">Invoice No</th>
                              //                     <th
                              //                       style={{
                              //                         width: 300,
                              //                         maxWidth: 250,
                              //                       }}
                              //                     >
                              //                       Description
                              //                     </th>
                              //                     <th nowrap="true">
                              //                       Created Date
                              //                     </th>
                              //                     <th
                              //                       nowrap="true"
                              //                       className="table_header_barge_label text-left border-0"
                              //                     >
                              //                       1st Level Approval
                              //                     </th>
                              //                     <th
                              //                       nowrap="true"
                              //                       className="table_header_barge_label text-left border-0"
                              //                     >
                              //                       1st Level Approved On
                              //                     </th>
                              //                     <th
                              //                       nowrap="true"
                              //                       className="table_header_barge_label text-left border-0"
                              //                     >
                              //                       2nd Level Approval
                              //                     </th>
                              //                     <th
                              //                       nowrap="true"
                              //                       className="table_header_barge_label text-left border-0"
                              //                     >
                              //                       2nd Level Approved On
                              //                     </th>
                              //                     <th nowrap="true">Action</th>
                              //                   </tr>
                              //                 </thead>
                              //                 <tbody>
                              //                   {e.supplier_demurrage_payments.map(
                              //                     (v, index) => (
                              //                       <tr key={index}>
                              //                         {/* <td nowrap='true' >{v.date_of_payment ? localDateFormate(v.date_of_payment) : null}</td> */}
                              //                         <td
                              //                           nowrap="true"
                              //                           className="table_td"
                              //                           style={{ minWidth: 170 }}
                              //                         >
                              //                           {e.approval_status ===
                              //                             "Approved" &&
                              //                             v.approval_status ===
                              //                               "Approved" &&
                              //                             (v.approval_status_2 ===
                              //                               "Optional" ||
                              //                               v.approval_status_2 ===
                              //                                 "Approved") && (
                              //                               <MuiPickersUtilsProvider
                              //                                 utils={DateFnsUtils}
                              //                               >
                              //                                 <DatePicker
                              //                                   autoOk={true}
                              //                                   inputVariant="outlined"
                              //                                   variant="inline"
                              //                                   margin="dense"
                              //                                   fullWidth
                              //                                   format="dd/MM/yyyy"
                              //                                   value={
                              //                                     v.date_of_payment
                              //                                   }
                              //                                   error={
                              //                                     this.state
                              //                                       .date_of_paymentError
                              //                                   }
                              //                                   onChange={(
                              //                                     date
                              //                                   ) => {
                              //                                     let row =
                              //                                       this.state
                              //                                         .supplierData;
                              //                                     let rows =
                              //                                       row[idx]
                              //                                         .supplier_demurrage_payments;
                              //                                     rows[
                              //                                       index
                              //                                     ].date_of_payment =
                              //                                       date;
                              //                                     this.setState(
                              //                                       {
                              //                                         supplierData:
                              //                                           row,
                              //                                         supplier_demurrage_id:
                              //                                           e.id,
                              //                                         supplier_demurrage_payment_id:
                              //                                           v.id,
                              //                                         payment_posting_type:
                              //                                           v.payment_posting_type,
                              //                                         date_of_payment:
                              //                                           date,
                              //                                         amount:
                              //                                           v.amount,
                              //                                         invoice_no:
                              //                                           v.invoice_no,
                              //                                         description:
                              //                                           v.description,
                              //                                         date_of_paymentError: false,
                              //                                       },
                              //                                       () => {
                              //                                         this.UpdatePaymentPostingsHandler();
                              //                                       }
                              //                                     );
                              //                                   }}
                              //                                   InputProps={{
                              //                                     startAdornment: (
                              //                                       <InputAdornment position="start">
                              //                                         <IconButton>
                              //                                           <EventIcon />
                              //                                         </IconButton>
                              //                                       </InputAdornment>
                              //                                     ),
                              //                                     className: "pl-0",
                              //                                   }}
                              //                                 />
                              //                               </MuiPickersUtilsProvider>
                              //                             )}
                              //                         </td>
                              //                         <td
                              //                           nowrap="true"
                              //                           className="text-right"
                              //                         >
                              //                           {v.amount
                              //                             ? toLocaleString(
                              //                                 v.amount
                              //                               )
                              //                             : ""}
                              //                         </td>
                              //                         <td nowrap="true">
                              //                           {v.invoice_no}
                              //                         </td>
                              //                         <td
                              //                           style={{
                              //                             whiteSpace: "pre-line",
                              //                           }}
                              //                         >
                              //                           {v.description}
                              //                         </td>
                              //                         <td nowrap="true">
                              //                           {v.created_date
                              //                             ? localDateFormate(
                              //                                 v.created_date
                              //                               )
                              //                             : null}
                              //                         </td>
                              //                         {e.approval_status ===
                              //                           "Approved" &&
                              //                         v.approval_status ===
                              //                           "Pending" &&
                              //                         this.Cookie.getCookie(
                              //                           "loginUserId"
                              //                         ) !== v.created_by &&
                              //                         (this.state.features.includes(
                              //                           "437"
                              //                         )  ||
                              //                           this.state.features.includes(
                              //                             "97"
                              //                           ) ) ? (
                              //                           <td
                              //                             nowrap="true"
                              //                             className=" text-left"
                              //                           >
                              //                             <button
                              //                               className="header_button header_button_text add_button_adjustment"
                              //                               style={{
                              //                                 width: "auto",
                              //                                 marginTop: "17px",
                              //                               }}
                              //                               onClick={() =>
                              //                                 this.approvePaymentsHandler(
                              //                                   v.id
                              //                                 )
                              //                               }
                              //                             >
                              //                               Approve
                              //                             </button>
                              //                           </td>
                              //                         ) : (
                              //                           <td
                              //                             nowrap="true"
                              //                             className=" text-left"
                              //                           >
                              //                             {v.approved_by_name
                              //                               ? v.approved_by_name
                              //                               : "-"}
                              //                           </td>
                              //                         )}
                              //                         <td
                              //                           nowrap="true"
                              //                           className=" text-left"
                              //                         >
                              //                           {v.approved_date
                              //                             ? localDateFormate(
                              //                                 v.approved_date
                              //                               )
                              //                             : "-"}
                              //                         </td>
                              //                         {e.approval_status ===
                              //                           "Approved" &&
                              //                         v.approval_status_2 ===
                              //                           "Pending" &&
                              //                         this.Cookie.getCookie(
                              //                           "loginUserId"
                              //                         ) !== v.created_by &&
                              //                         this.Cookie.getCookie(
                              //                           "loginUserId"
                              //                         ) !== v.approved_by &&
                              //                         (this.state.features.includes(
                              //                           "484"
                              //                         )  ||
                              //                           this.state.features.includes(
                              //                             "97"
                              //                           ) ) ? (
                              //                           <td
                              //                             nowrap="true"
                              //                             className=" text-left"
                              //                           >
                              //                             <button
                              //                               className="header_button header_button_text add_button_adjustment"
                              //                               style={{
                              //                                 width: "auto",
                              //                                 marginTop: "17px",
                              //                               }}
                              //                               onClick={() =>
                              //                                 this.approvePaymentsHandler(
                              //                                   v.id
                              //                                 )
                              //                               }
                              //                             >
                              //                               Approve
                              //                             </button>
                              //                           </td>
                              //                         ) : (
                              //                           <td
                              //                             nowrap="true"
                              //                             className=" text-left"
                              //                           >
                              //                             {v.approved_by_2_name
                              //                               ? v.approved_by_2_name
                              //                               : "-"}
                              //                           </td>
                              //                         )}
                              //                         <td
                              //                           nowrap="true"
                              //                           className=" text-left"
                              //                         >
                              //                           {v.approved_date_2
                              //                             ? localDateFormate(
                              //                                 v.approved_date_2
                              //                               )
                              //                             : "-"}
                              //                         </td>
                              //                         <td nowrap="true">
                              //                           {/* {(e.approval_status !== "Pending" && this.Cookie.getCookie('loginUserId') !== v.created_by && (this.state.features.includes('437')  || this.state.features.includes('97') )) &&

                              //                       <Tooltip title="Approve" arrow>

                              //                         <i className="fa fa-check-circle mr-2"
                              //                           style={{ fontSize: 20, color: config.themeColor, cursor: 'pointer' }}
                              //                           onClick={() => this.approvePaymentsHandler(v.id)}>

                              //                         </i>
                              //                       </Tooltip>
                              //                     } */}
                              //                           {(this.state.features.includes(
                              //                             "391"
                              //                           )  ||
                              //                             this.state.features.includes(
                              //                               "97"
                              //                             ) ) && (
                              //                             <Tooltip
                              //                               title="Edit"
                              //                               arrow
                              //                             >
                              //                               <i
                              //                                 className="fa fa-pencil mr-2"
                              //                                 style={{
                              //                                   fontSize: 20,
                              //                                   color:
                              //                                     config.themeColor,
                              //                                   cursor: "pointer",
                              //                                 }}
                              //                                 data-toggle="modal"
                              //                                 title="Edit Coal Payment Posting"
                              //                                 data-placement="bottom"
                              //                                 onClick={() => {
                              //                                   this.setState({
                              //                                     remainingAmount:
                              //                                       e.remaining_amount,
                              //                                   });
                              //                                   this.getPaymentDetalis(
                              //                                     v
                              //                                   );
                              //                                 }}
                              //                               ></i>
                              //                             </Tooltip>
                              //                           )}
                              //                           {(this.state.features.includes(
                              //                             "392"
                              //                           )  ||
                              //                             this.state.features.includes(
                              //                               "97"
                              //                             ) ) && (
                              //                             <Tooltip
                              //                               title="Delete"
                              //                               arrow
                              //                             >
                              //                               <i
                              //                                 className="fa fa-trash-o"
                              //                                 style={{
                              //                                   fontSize: 20,
                              //                                   color: "red",
                              //                                   cursor: "pointer",
                              //                                 }}
                              //                                 data-toggle="modal"
                              //                                 title="Delete Payment Posting"
                              //                                 data-placement="bottom"
                              //                                 onClick={() =>
                              //                                   this.setState({
                              //                                     deletePaymentData:
                              //                                       v,
                              //                                   })
                              //                                 }
                              //                               />
                              //                             </Tooltip>
                              //                           )}
                              //                         </td>
                              //                       </tr>
                              //                     )
                              //                   )}
                              //                 </tbody>
                              //               </table>
                              //             </div>
                              //           </div>
                              //         </div>
                              //       )}
                              // </div>
                            )}
                        </div>
                      </div>
                      <ViewFiles
                        {...this.props}
                        files_data={e.files}
                        type="Costings"
                        invoice_file={e.invoice_file}
                      />
                    </div>
                  </div>
                  <div className="col shadow-sm p-0 mb-2 bg-white border">
                    <div className="table-responsive">
                      <table className="table table-sm table-bordered">
                        <thead className="table-header">
                          <tr>
                            <th className="text-center">Approval Type</th>
                            <th className="text-center">Approved by</th>
                            <th className="text-center">Approved On</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="text-center">Business</td>
                            <td className="text-center">
                              <div> {e.approved_by_name}</div>
                            </td>
                            <td className="text-center">
                              {localDateFormate(e.approved_date)}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-center">Financial</td>
                            <td className="text-center">
                              {e.approved_by_name_2}
                            </td>
                            <td className="text-center">
                              {localDateFormate(e.approved_date_2)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
          <footer className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button btn-light"
              name="submit"
              onClick={() => {
                this.props.returnCallbackDrawerClose(false);
              }}
            >
              Close
            </button>
            <button
              type="button"
              className="drawer_text drawer_btn_success"
              name="Back"
              onClick={() => {
                this.props.returnEditCallback("MvSupplierDemurrage");
              }}
            >
              Edit
            </button>
          </footer>
          {/**
           *
           * TODO // Alert Message Modal
           *
           */}

          <div
            className="modal fade"
            id="AlertMessageModal"
            role="dialog"
            tabIndex="-1"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div
                  className="modal-header border-0"
                  style={{ backgroundColor: "#f1f1f1" }}
                >
                  <h6
                    className="modal-title"
                    style={{ border: "none", padding: 0 }}
                  >
                    <i
                      className="fa fa-exclamation-triangle"
                      aria-hidden="true"
                      style={{ color: "#e0a51e", marginBottom: "5px" }}
                    ></i>
                    &nbsp; {this.state.errorMsg}
                  </h6>
                </div>
                <div className="modal-footer border-0">
                  <button
                    className="btn btn-secondary"
                    onClick={() =>
                    (window.location.href =
                      "/view-business/" + btoa(businessNoID))
                    }
                  >
                    Ok
                  </button>
                  {/* <button className='btn btn-secondary' data-dismiss="modal">Cancel</button> */}
                </div>
              </div>
            </div>
          </div>
          {/*
           *
           * Barge Payements Posting Modals
           *
           */}
          <Dialog
            open={this.state.paymentPostingsModal}
            aria-labelledby="responsive-dialog-title"
            className="payment-modal"
          >
            <DialogTitle id="responsive-dialog-title">
              {this.state.paymentTitle} Payment Posting
            </DialogTitle>
            <div className="row col-lg-12 card border">
              <div
                style={{
                  fontSize: 14,
                  color: "red",
                  textTransform: "capitalize",
                  textAlign: "center",
                }}
              >
                {this.state.paymentErrorMsg}
              </div>

              <div className="row">
                <div className="col-lg-6 mb-2">
                  <label className="contract_display_header_label">
                    Amount <span style={{ color: "red" }}>*</span>
                  </label>
                  <TextField
                    name="amount"
                    margin="dense"
                    variant="outlined"
                    placeholder="Amount"
                    required
                    value={this.state.amount}
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    fullWidth
                    error={this.state.amountError}
                    onChange={(e) => {
                      const pr = /^\d*(\.\d{0,4})?$/;
                      if (e.target.value === "" || pr.test(e.target.value)) {
                        this.setState({
                          amount: e.target.value,
                          amountError: false,
                          paymentErrorMsg: "",
                        });
                        var remainingAmount = 0;
                        remainingAmount = Number(this.state.remainingAmount);
                        if (this.state.paymentMode === "Update") {
                          remainingAmount =
                            Number(this.state.remainingAmount) +
                            Number(this.state.existingAmount);
                        }
                        if (
                          Number(e.target.value) >
                          Math.ceil(Number(remainingAmount))
                        ) {
                          this.setState({
                            amountError: true,
                            submitting: false,
                            paymentErrorMsg:
                              "Amount is exceeding Payable to Vendor value",
                          });
                          return;
                        }
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {config.company === "SRPL" || config.company === "AVS" ||
                            config.company === "PRPL"
                            ? "USD"
                            : "IDR"}
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>

                <div className="col-lg-6 mb-2">
                  <label className="contract_display_header_label">
                    Invoice No <span style={{ color: "red" }}>*</span>
                  </label>
                  <TextField
                    name="invoice_no"
                    margin="dense"
                    variant="outlined"
                    placeholder="Invoice No"
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    error={this.state.invoice_noError}
                    value={this.state.invoice_no}
                    onChange={(e) =>
                      this.setState({
                        invoice_no: e.target.value,
                        invoice_noError: false,
                      })
                    }
                    fullWidth
                  />
                </div>

                <div className="col-lg-6 mb-2">
                  <label className="contract_display_header_label">
                    Description
                  </label>
                  <TextField
                    name="description"
                    margin="dense"
                    variant="outlined"
                    placeholder="Description"
                    multiline
                    rows={3}
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    value={this.state.description}
                    onChange={(e) =>
                      this.setState({ description: e.target.value })
                    }
                    fullWidth
                  />
                </div>
              </div>
            </div>
            <div className="row bottom_buttons_section">
              <button
                type="button"
                className="btn previous_button"
                onClick={() => this.setState({ paymentPostingsModal: false })}
              >
                Cancel
              </button>
              {/* <button type="button" className="btn next_button" onClick={() => window.location.href = '/view-purchase-contract/' + btoa(this.state.clonedPC_contract_id)}>OK</button> */}
              {this.state.paymentMode === "Add" && (
                <button
                  type="button"
                  className="btn next_button"
                  onClick={this.AddPaymentPostingHandler}
                  disabled={this.state.submitting}
                >
                  <span
                    className={
                      this.state.submitting
                        ? "spinner-grow spinner-grow-sm mr-2"
                        : ""
                    }
                  ></span>
                  Submit
                </button>
              )}

              {this.state.paymentMode === "Update" && (
                <button
                  type="button"
                  className="btn next_button"
                  onClick={this.UpdatePaymentPostingsHandler}
                  disabled={this.state.submitting}
                >
                  <span
                    className={
                      this.state.submitting
                        ? "spinner-grow spinner-grow-sm mr-2"
                        : ""
                    }
                  ></span>
                  Update
                </button>
              )}
            </div>
          </Dialog>

          {/**
           *
           * Delete Payament Postings Conformation Modal
           *
           */}

          <div
            className="modal fade"
            id="deleteConformationModal"
            role="dialog"
            tabIndex="-1"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div
                  className="modal-header border-0"
                  style={{ backgroundColor: "#f1f1f1" }}
                >
                  <h6
                    className="modal-title"
                    style={{ border: "none", padding: 0 }}
                  >
                    <i
                      className="fa fa-exclamation-triangle"
                      aria-hidden="true"
                      style={{ color: "#e0a51e", marginBottom: "5px" }}
                    ></i>
                    &emsp; Are you sure to delete ?
                  </h6>
                </div>
                <div className="modal-footer border-0">
                  <button
                    className="btn btn-sm btn-warning mr-3"
                    onClick={() =>
                      this.deletePaymentsHandler(this.state.deletePaymentData)
                    }
                  >
                    Ok
                  </button>
                  <button
                    className="btn btn-sm btn-secondary"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
