import React, { Component } from "react";
import api from "../../../../api/api";
import CookieHandler from "../../../common/CookieHandler";
import config from "../../../../config/config";

import {
  TextField,
  InputAdornment,
  RadioGroup,
  FormControlLabel,
  Radio,
  MenuItem,
  IconButton,
  Snackbar,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import EventIcon from "@material-ui/icons/Event";
import { withStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import FileUpload from "../../../common/FileUpload";
import { dateFormateToDB } from "../../../common/common";
import Loader from "../../../common/Loader";
const NewRadio = withStyles({
  root: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export default class Incoming_Credit_Note_Add extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      credit_type: "Incoming",

      multiRows: [
        {
          billing_currency:
            config.company === "SRPL" ||
            config.company === "AVS" ||
            config.company === "PRPL"
              ? "USD"
              : "IDR",
          currency_xchg_rate: 1,
          credit_type: "Incoming",
          vendor_or_customer: "Vendor",
          vendor_id: "0",
          customer_id: "-1",
          other_customer_name: "",
          date_of_creation: null,
          amount: "",
          remarks: "",
        },
      ],

      customers: [],
      vendors: [],

      isLoading: true,
      files: [
        {
          file_description: "",
          file_name: "",
          file_extension: "",
          file_content: "",
          feature_name: "Business_no_credit_note",
        },
      ],
      features: [],
      costing_files: [],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    var features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    if (this.props.businessNoID) {
      var businessNoID = this.props.businessNoID;
      var status = "Active";
      var vendors = [
        <MenuItem
          style={{ fontFamily: "Poppins", fontSize: "14px" }}
          value="0"
          key={-1}
          disabled
        >
          {" "}
          Please Select
        </MenuItem>,
      ];
      const getVendors = await api.getVendors(loginUserID, idToken, status);
      if (getVendors.status >= 200 && getVendors.status < 300) {
        getVendors.json().then((res) => {
          if (res.code === "200") {
            if (res.vendors) {
              for (var i of res.vendors) {
                vendors.push(
                  <MenuItem
                    style={{ fontFamily: "Poppins", fontSize: "14px" }}
                    value={i.id}
                    key={[i.id]}
                  >
                    {i.vendor_name}
                  </MenuItem>
                );
              }
              this.setState({
                vendors: vendors,
              });
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          } else if (res.code === "601") {
            this.setState({
              errorMsg: res.message,
              snackBarErrorOpen: true,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
      var customers = [
        <MenuItem
          style={{ fontFamily: "Poppins", fontSize: "14px" }}
          value="-1"
          key={-1}
          disabled
        >
          {" "}
          Please Select
        </MenuItem>,
      ];
      const getCusomters = await api.getCustomers(loginUserID, idToken, status);
      if (getCusomters.status >= 200 && getCusomters.status < 300) {
        getCusomters.json().then((res) => {
          if (res.code === "200") {
            if (res.customers) {
              for (var i of res.customers) {
                customers.push(
                  <MenuItem
                    style={{ fontFamily: "Poppins", fontSize: "14px" }}
                    value={i.id}
                    key={[i.id]}
                  >
                    {i.customer_name}
                  </MenuItem>
                );
              }
              // customers.push(
              //   <MenuItem
              //     style={{ fontFamily: "Poppins", fontSize: "14px" }}
              //     value="0"
              //     key="0"
              //   >
              //     Others
              //   </MenuItem>
              // );
              this.setState({
                customers: customers,
              });
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          } else if (res.code === "601") {
            this.setState({
              errorMsg: res.message,
              successMsg: "",
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }

      //Get businessNumber API based on businessNumber
      const getBusinessNumber = await api.getBusinessNumber(
        businessNoID,
        loginUserID,
        idToken
      );
      if (getBusinessNumber.status >= 200 && getBusinessNumber.status < 300) {
        getBusinessNumber.json().then((res) => {
          if (res.code === "200") {
            if (res.business_number) {
              this.setState({
                businessNo: res.business_number.business_no
                  ? res.business_number.business_no
                  : "",
                mother_vessel_name: res.business_number.mother_vessel_name
                  ? res.business_number.mother_vessel_name
                  : "",
                isLoading: false,
              });
            }
          } else if (res.code === "601") {
            this.setState({
              errorMsg: res.message,
              snackBarErrorOpen: true,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    } else {
      window.location.href = "/business-number-list";
    }
    document.title = config.documentTitle + "Add Incoming Credit Note";
  }

  addMoreRowsHandler = () => {
    const item = {
      billing_currency:
        config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL"
          ? "USD"
          : "IDR",
      currency_xchg_rate: 1,
      credit_type: "Incoming",
      vendor_or_customer: "Vendor",
      vendor_id: "0",
      customer_id: "-1",
      other_customer_name: "",
      date_of_creation: null,
      amount: "",
      remarks: "",
    };
    this.setState({
      multiRows: [...this.state.multiRows, item],
    });
  };

  removeMultiRowsHandler = (idx) => () => {
    const rows = this.state.multiRows;
    rows.splice(idx, 1);
    this.setState({
      multiRows: rows,
    });
  };

  submitHandler = () => {
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    var multiRows = this.state.multiRows;
    var credit_type = "Incoming";
    // Checking mandatory fields.
    var all_fields_filled = true;

    let costing_file = this.state.costing_files?.filter(
      (e) => e.file_content !== "" && e.column === "invoice_file"
    );

    for (var i = 0; i < multiRows.length; i++) {
      multiRows[i].costing_files = costing_file;

      if (multiRows[i].vendor_or_customer === "Vendor") {
        multiRows[i].customer_id = "";
        if (multiRows[i].vendor_id === "0") {
          multiRows[i].vendorError = true;
          all_fields_filled = false;
        }
      }
      if (multiRows[i].vendor_or_customer === "Customer") {
        multiRows[i].vendor_id = "";
        if (multiRows[i].customer_id === "-1") {
          multiRows[i].customerError = true;
          all_fields_filled = false;
        }
        if (multiRows[i].customer_id === "0") {
          if (multiRows[i].other_customer_name === "") {
            multiRows[i].other_customer_name_error = true;
            all_fields_filled = false;
          }
          multiRows[i].customer_id = null;
        } else {
          multiRows[i].other_customer_name = "";
        }
      }
      if (multiRows[i].date_of_creation === null) {
        multiRows[i].date_of_creation_error = true;
        all_fields_filled = false;
      }
      if (multiRows[i].amount === "") {
        multiRows[i].amount_error = true;
        all_fields_filled = false;
      }
    }
    this.setState({ multiRows: multiRows });
    /**
     *  Checking Mandatory files
     */
    var missing_fields = 0;
    let files_missing = 0;
    var row = this.state.files;
    for (var obj of row) {
      if (obj.file_type !== "" || obj.file_content !== "") {
        if (obj.file_content !== "") {
          if (obj.file_description === "") {
            missing_fields++;
            obj.file_description_error = true;
          } else {
            obj.file_description = obj.file_description.toUpperCase();
          }
        } else if (obj.file_description !== "") {
          if (obj.file_content === "") {
            files_missing++;
          }
        }
      }
    }
    this.setState({
      files: row,
    });

    if (missing_fields > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please fill mandatory fields.",
      });
      return;
    }
    if (files_missing > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please Select file.",
      });
      return;
    }

    const content_files = row.filter((e) => e.file_content !== "");
    var businessNoID = this.props.businessNoID;

    if (all_fields_filled) {
      api
        .add_business_no_credit_note(
          loginUserID,
          idToken,
          businessNoID,
          credit_type,
          multiRows,
          content_files
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                setTimeout(() => {
                  this.props.onAddResponse({
                    screen: "CreditNote",
                    code: res.code,
                    message: "",
                  });
                }, 2000);
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    } else {
      alert("Please fill mandatory fields.");
    }
  };

  render() {
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div
          className="container bg-white p-0"
          style={{ minHeight: "100vh", width: 850 }}
        >
          <div className="row clearfix content-header border-bottom">
            <div className="col-lg-12 pl-0">
              <h4 style={{ padding: "10px 20px" }}>
                Incoming Credit Notes{" "}
                {this.state.bargeNomination
                  ? "(" + this.state.bargeNomination + ")"
                  : null}{" "}
                - {this.state.businessNo ? this.state.businessNo : ""}
                {this.state.mother_vessel_name
                  ? " (" + this.state.mother_vessel_name + ")"
                  : ""}
              </h4>
            </div>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <div className="drawer-section" style={{ minHeight: "86vh" }}>
            <div className="card p-3">
              <div
                className="card-body p-0"
                style={{ backgroundColor: "rgba(25,118,210,.01)" }}
                id="accordion"
              >
                {this.state.multiRows.map((e, idx) => (
                  <div className="row mb-3" key={idx}>
                    <div className="row col-lg border_dashed rounded-lg pb-2 pt-2">
                      <div className="col-lg-6 mb-1 pl-0">
                        <label className="form_label mb-0">From</label>
                        <RadioGroup
                          aria-label="vendor_or_customer"
                          name="vendor_or_customer"
                          onChange={(e) => {
                            var row = this.state.multiRows;
                            row[idx].vendor_or_customer = e.target.value;
                            if (e.target.value === "Vendor") {
                              row[idx].customer_id = "-1";
                              row[idx].other_customer_name = "";
                            } else if (e.target.value === "Customer") {
                              row[idx].vendor_id = "0";
                            }
                            this.setState({
                              multiRows: row,
                            });
                          }}
                          style={{ flexDirection: "row" }}
                        >
                          <FormControlLabel
                            value="Vendor"
                            control={
                              <NewRadio
                                checked={e.vendor_or_customer === "Vendor"}
                              />
                            }
                            label="Vendor"
                          />
                          <FormControlLabel
                            value="Customer"
                            control={
                              <NewRadio
                                checked={e.vendor_or_customer === "Customer"}
                              />
                            }
                            label="Customer"
                          />
                        </RadioGroup>
                      </div>

                      {e.vendor_or_customer === "Vendor" && (
                        <div className="col-lg-6 mb-1 pl-0">
                          <label className="form_label mb-0">
                            Payee Name <span style={{ color: "red" }}>*</span>
                          </label>
                          <TextField
                            name="vendor"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            inputProps={{
                              style: {
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                textTransform: "uppercase",
                              },
                            }}
                            error={e.vendorError}
                            placeholder="Vendor"
                            value={e.vendor_id}
                            onChange={(e) => {
                              var row = this.state.multiRows;
                              row[idx].vendor_id = e.target.value;
                              row[idx].vendorError = false;
                              this.setState({
                                multiRows: row,
                              });
                            }}
                            select
                          >
                            {this.state.vendors}
                          </TextField>
                        </div>
                      )}

                      {e.vendor_or_customer === "Customer" && (
                        <div className="col-lg-6 mb-1 pl-0">
                          <label className="form_label mb-0">
                            Payee Name <span style={{ color: "red" }}>*</span>
                          </label>
                          <TextField
                            name="Customer"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            inputProps={{
                              style: {
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                textTransform: "uppercase",
                              },
                            }}
                            error={e.customerError}
                            placeholder="Customer"
                            value={e.customer_id}
                            onChange={(e) => {
                              var row = this.state.multiRows;
                              row[idx].customer_id = e.target.value;
                              row[idx].customerError = false;
                              this.setState({
                                multiRows: row,
                              });
                            }}
                            select
                          >
                            {this.state.customers}
                          </TextField>
                        </div>
                      )}

                      {e.customer_id === "0" && (
                        <div className="col-lg-6 mb-1 pl-0">
                          <label className="form_label mb-0">
                            Payee Name <span style={{ color: "red" }}>*</span>
                          </label>
                          <TextField
                            name="other_customer_name"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            inputProps={{
                              style: {
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                textTransform: "uppercase",
                              },
                            }}
                            error={e.other_customer_name_error}
                            placeholder="Name"
                            value={e.other_customer_name}
                            onChange={(e) => {
                              var row = this.state.multiRows;
                              row[idx].other_customer_name = e.target.value;
                              row[idx].other_customer_name_error = false;
                              this.setState({
                                multiRows: row,
                              });
                            }}
                          />
                        </div>
                      )}

                      <div className="col-lg-6 mb-1 pl-0">
                        <label className="form_label mb-0">
                          Date <span style={{ color: "red" }}>*</span>
                        </label>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DatePicker
                            autoOk={true}
                            inputVariant="outlined"
                            variant="inline"
                            margin="dense"
                            fullWidth
                            format="dd/MM/yyyy"
                            value={e.date_of_creation}
                            onChange={(date) => {
                              var row = this.state.multiRows;
                              row[idx].date_of_creation = dateFormateToDB(date);
                              row[idx].date_of_creation_error = false;
                              this.setState({
                                multiRows: row,
                              });
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton>
                                    <EventIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            error={e.date_of_creation_error}
                          />
                        </MuiPickersUtilsProvider>
                      </div>

                      <div className="col-lg-6 mb-1 pl-0">
                        <label className="form_label mb-0">
                          Amount <span style={{ color: "red" }}>*</span>
                        </label>
                        <TextField
                          name="amount"
                          margin="dense"
                          type="number"
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {config.company === "SRPL" ||
                                config.company === "AVS" ||
                                config.company === "PRPL"
                                  ? "USD"
                                  : "IDR"}
                              </InputAdornment>
                            ),
                            style: {
                              fontFamily: "Poppins",
                              fontSize: "14px",
                            },
                          }}
                          error={e.amount_error}
                          placeholder="Amount"
                          value={e.amount}
                          onChange={(e) => {
                            const reg = /^\d*(\.\d{0,4})?$/;
                            if (
                              e.target.value === "" ||
                              reg.test(e.target.value)
                            ) {
                              var row = this.state.multiRows;
                              row[idx].amount = e.target.value;
                              row[idx].amount_error = false;
                              this.setState({
                                multiRows: row,
                              });
                            }
                          }}
                        />
                      </div>

                      <div className="col-lg-12 mb-1 pl-0">
                        <label className="form_label mb-0">Remarks</label>
                        <TextField
                          name="remarks"
                          margin="dense"
                          variant="outlined"
                          multiline
                          rows={3}
                          placeholder="Remarks"
                          value={e.remarks}
                          onChange={(e) => {
                            var row = this.state.multiRows;
                            row[idx].remarks = e.target.value;
                            this.setState({
                              multiRows: row,
                            });
                          }}
                          fullWidth
                          inputProps={{
                            style: {
                              fontFamily: "Poppins",
                              fontSize: "14px",
                              textTransform: "uppercase",
                            },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ))}
                {/* {(this.state.features.includes("376")  ||
                  this.state.features.includes("97") ) && ( */}
                <FileUpload
                  files={this.state.files}
                  type="Costings"
                  feature_name="Business_no_credit_note"
                  callbackFn={(data, mandatoryFiles) => {
                    this.setState({
                      files: data,
                      costing_files: mandatoryFiles,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <footer className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button btn-light"
              name="submit"
              onClick={() => {
                this.props.returnCallbackDrawerClose(false);
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              className="drawer_text drawer_btn_success"
              name="Back"
              onClick={this.submitHandler}
            >
              Submit
            </button>
          </footer>
        </div>
      );
    }
  }
}
